import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
    selector: 'menu-header-comments',
    templateUrl: './menu-header-comments.component.html'
})
export class MenuHeaderCommentsComponent implements OnInit, AfterViewInit {
  menuHeaderComments:any = {};

  constructor(private loader:LoaderFactory,
      private route: ActivatedRoute,
      private router: Router,
      private apiService: ApiServiceService,
      private commonUtilsService: CommonUtilsService) { }

  @Input() option:string = '';
  @Input() listItems:any;
  @Output() closeMenu = new EventEmitter();
  @Output() goToEmit = new EventEmitter();

  ngOnInit(){
      this.getComments();
  }

  ngAfterViewInit(){
      this.loader.closeLoader(['open-list-organizations']);
  }

  getComments() {
      this.apiService.get('company/dashboard', 'comments')
          .then((data:any) => {
              this.menuHeaderComments.comments = data.slice(0,10);
          },(errorData:any)=>{console.log(errorData)});
  }

  goTo(comment: any){
    this.closeDropdown();
    let url: string = 'evaluation/'+comment.evaluationId+'/folder/'+comment.folderId+'/concept/'+comment.conceptId+'/concept-evaluated/'+comment.conceptObjectId;
    this.goToEmit.emit({url: url, evaluationId: comment.evaluationId, loader: 'folder-open-concept'});
  }

  closeDropdown(){
      this.closeMenu.emit();
  }
}
