import moment from 'moment';
import { Component, OnInit, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'expiration-period',
  templateUrl: './expiration-period.component.html'
})
export class ExpirationPeriodComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  expirationPeriod: any = {
    intervalRepeat: {
      startInterval: null,
      selectInterval: 'no-end-date',
      finishIntervalDate: null,
      date:null,
    },
    repeatIntervalDate:{
      id: "repeat-inteval-date",
      noEditable: true,
      label: this.translate.instant('expiration-period.init-survey')
    },
    numeralList: [{
        id:1,
        text: this.translate.instant("expiration-period.days"),
        value: 'days'
    },{
        id:2,
        text: this.translate.instant("expiration-period.weeks"),
        value: 'weeks'
    },{
        id:3,
        text: this.translate.instant("expiration-period.months"),
        value: 'months'
    }]
  }


  constructor(private globalCfg: GlobalCfgFactory,
    private dateConverterService: DateConverterService,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }

  @Input() concept:any = null;

  ngOnInit(): void {
    let that: any = this.expirationPeriod;
    if(this.concept?.pollBeginDate){
      that.intervalRepeat.startInterval = this.dateConverterService.toDate(this.concept.pollBeginDate);
    }
    if(this.concept?.pollExpirationDate){
        that.intervalRepeat.finishIntervalDate = this.dateConverterService.toDate(this.concept.pollExpirationDate);
        that.intervalRepeat.selectInterval ='end-interval-day';
    }
  }

  selectOption(type:string, e:any){
      this.expirationPeriod[type] = e.newValue;
  }

  selectIntervalDate(e:any, type:string){
      this.expirationPeriod.intervalRepeat[type] = e.date;
  }

  validateRepetition(){
      let that: any = this.expirationPeriod;
      that.hasError = false;
      that.errorText ='';
      let initInterval: any = that.intervalRepeat.startInterval?that.intervalRepeat.startInterval : new Date();
      if(moment(initInterval).valueOf() > moment(that.intervalRepeat.finishIntervalDate).valueOf()){
          that.hasError = true;
          that.textError = this.translate.instant('expiration-period.expiration-error');
      }else if(that.intervalRepeat.selectInterval === 'end-interval-day' && !that.intervalRepeat.finishIntervalDate){
          that.hasError = true;
          that.textError = this.translate.instant('expiration-period.null-date');
      }
      if(!that.hasError){
          this.handleAccept();
      }
  }

  extractDate(){
      let that: any = this.expirationPeriod;
      if(that.intervalRepeat.startInterval){
          that.intervalRepeat.startInterval = this.dateConverterService.toIso(that.intervalRepeat.startInterval);
      }
      if(that.intervalRepeat.selectInterval === 'end-interval-day'){
          that.intervalRepeat.date = this.dateConverterService.toIso(that.intervalRepeat.finishIntervalDate);
          that.intervalRepeat.finishIntervalDate = this.dateConverterService.toIso(that.intervalRepeat.finishIntervalDate);
      }
      return that.intervalRepeat;
  }

  closeAdvice(type:any){
      this.expirationPeriod[type] = false;
  }

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        interval: this.extractDate()
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }


}
