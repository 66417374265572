
import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';

import { FieldTypeListParentService } from './../utils/field-type-list-parent.service';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'field-type-list-simple',
  templateUrl: './field-type-list-simple.component.html'
})
export class FieldTypeListSimpleComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = this.customLanding.isLanding();
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']

  constructor(
    private customLanding: CustomLandingFactory,
    private fieldTypeListParent: FieldTypeListParentService,
    private route: ActivatedRoute) {
  }

  @Input() field:any = {};
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeListParent.clickMenu(this);
    this.fieldTypeListParent.openWatchers(this)
  }

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
  }

  catchEvent(e:any){
    e.stopPropagation();
  }

  createNewValue(e:any){
    this.fieldTypeListParent.createNewValue(e, this)
  }

  insertNewValue(e:any){
    this.fieldTypeListParent.insertNewValue(e, this);
  }

  editListValueElement(e: any, listValue:any){
    this.fieldTypeListParent.editListValueElement(e, listValue, this);
  }

  deleteListValue(e:any, listValue:any){
   this.fieldTypeListParent.deleteListValue(e, listValue, this)
  }

  aceptNotDelete(e:any, listValue:any){
    e.stopPropagation();
    listValue.deleteError = false;
  }

  resetListValue(e:any){
    this.fieldTypeListParent.resetListValue(e, this);
  }

  selectItem(listValue: any, e:any){
    if(listValue.disabled){
        e.stopPropagation();
        e.preventDefault();
    }else if(this.field.uniqueLastSelect && listValue.children?.length){
        this.showChildren(e, listValue);
    }else{
        this.closeAllChildrens(this.fieldTypeList.filterList);
        this.fieldTypeListParent.selectItem(listValue, this, e)
    }
  }

  closeAllChildrens(list: any){
    list.forEach((listValue: any) => {
        listValue.show = false;
      if(listValue.children?.length){
        this.closeAllChildrens(listValue.children);
      }
    });
  }

  closeAllElements(list: any, listValueSelected: any){
    list.forEach((listValue: any) => {
      if(listValueSelected.parentId !== listValue.listValueId && listValueSelected.listValueId !== listValue.listValueId){
        listValue.show = false;
      }else if(listValue.parentId){
        this.openParent(this.fieldTypeList.filterList, listValue);
      }
      if(listValue.children?.length){
        this.closeAllElements(listValue.children, listValueSelected);
      }
    });
  }

  openParent(list: any, listValueSelected: any){
    list.forEach((listValue: any) => {
      if(listValueSelected.parentId === listValue.listValueId){
        listValue.show = true;
      }
      if(listValue.children?.length){
        this.openParent(listValue.children, listValueSelected);
      }
    });
  }

  showChildren(e:any, listValue:any){
    e.preventDefault();
    e.stopPropagation();
    this.closeAllElements(this.fieldTypeList.filterList, listValue);
    listValue.show = !listValue.show;
  }

  changeList(){
    this.fieldTypeListParent.changeList(this);
  }

  showReference(e:any){
    this.fieldTypeListParent.showReference(e, this);
  }

  resetSearch(e:any){
    this.fieldTypeListParent.resetSearch(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeListParent.openModalRelatedInfo(fieldRelatedInfo, e, this);
  }

  resetItem(){
    this.fieldTypeListParent.resetItem(this);
  }

  treeSimpleOn(event:any){
    switch(event.type){
      case 'selectItem':
        this.selectItem(event.listValue, event.e);
        break;
      case 'showChildren':
        this.showChildren(event.e, event.listValue);
        break;
      case 'editListValue':
        this.fieldTypeListParent.editListValueElement(event.e, event.listValue, this);
        break;
      case 'deleteListValue':
        this.fieldTypeListParent.deleteListValue(event.e, event.listValue, this)
        break;
      default:
        this.aceptNotDelete(event.e, event.listValue)
        break;
    }
  }

  checkListValue(listValue:any){
    return listValue?.type?.toLowerCase()==='back' && !listValue.show
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
