import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';


@Component({
  selector: 'field-type-template',
  templateUrl: './field-type-template.component.html'
})
export class FieldTypeTemplateComponent implements OnChanges {
  custom: any = this.customLanding.getCustom();
  isLanding:boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  fieldTypeTemplate:any = {};

  constructor(private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private documentActions: DocumentActionsService) { }

  @Input() field: any = null;
  @Input() concept: any = null;
  @Input() preSelectItem: any = null;
  @Input() public: boolean = false;
  @Output() itemSelected = new EventEmitter();
  @Output() changeConcept = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['custom'] && changes['custom'] !== null){
      //vm.templateHtml="./app/components/field-type-template/templates/field-type-template-custom.template.html";
      this.skin = 'landing-custom';
    }
    if(changes['field']) {
      this.fieldTypeTemplate.field = changes['field'].currentValue
      if(!this.fieldTypeTemplate.field.id){
          this.fieldTypeTemplate.field.id = this.commonUtilsService.createId();
      }
      this.fieldTypeTemplate.field.description = this.commonUtilsService.checkAndDeletePreTag(this.fieldTypeTemplate.field.description)

    }
    if(changes['preSelectItem'].currentValue === null ){
      this.fieldTypeTemplate.preSelectItem = false
    }
  }

  generateDocument(){
      if(!this.isLanding && this.commonUtilsService.isIE()){
          // Hacemos esta comprobación porque el Office Online no funciona en IE, desde la landing no abriremos nunca Office Online
          this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
      }else{
        let field: any = this.fieldTypeTemplate.field;
        let template:any = {
            conceptDataId: field.evaluatedValue.conceptDataId,
            extension: field.documentTemplateName.substr(field.documentTemplateName.lastIndexOf('.')+1, field.documentTemplateName.length).toUpperCase(),
            fieldId: field.fieldId,
            from: 'fieldTemplate',
            id: field.documentTemplateId,
            name: field.documentTemplateName,
            predefinedName: field.documentTemplateName.substr(0, field.documentTemplateName.lastIndexOf('.')),
            title: field.documentTemplateName
        }
        template.typeOption = this.isLanding && this.public? "generateTemplateFromFieldPublic" : "generateTemplateFromField";
        this.changeConcept.emit({template: template});
      }
  }

  downloadDocument(documentSelected:any){
      this.documentActions.doAction('download', documentSelected);
  }

  removeDocument(documentSelected:any){
      if(this.isLanding && documentSelected.documentStatusId && (documentSelected.documentStatusId === 3 || documentSelected.documentStatusId === 4 || documentSelected.documentStatusId === 6)){
        this.modalService.adviceModal(this.translate.instant('documentation-list.error-delete-document-signed'), 'error:accept:warning');
      }else if(!this.isLanding && documentSelected.documentStatusId && documentSelected.documentStatusId === 3){
        this.modalService.adviceModal(this.translate.instant('documentation-list.pending-signature-error'), 'error:accept:warning');
      }else{
        this.openQuestionDeletedItem(documentSelected);
      }
  }


  openQuestionDeletedItem(documentSelected:any){
      let text: string = this.configureTextDeleteItem(documentSelected);
      let type = this.isLanding?'info:question:question-full':'info:deleteOption:question';
      this.modalService.adviceModal(text, type).subscribe((result:any)=>{
        if(result.result !== 'cancel'){
          this.loader.openLoader('field-type-template-delete-document');
          if(this.isLanding || result.result === 'permanently-delete'){
              this.deleteDocumentSelected(documentSelected, 'delete');
          }else if(result.result === 'send-to-trash'){
              this.getDeletedDocuments(documentSelected);
          }
        }
      })
  }

  configureTextDeleteItem(documentSelected: any){
      let text: string = this.isLanding?this.translate.instant('documentation-list.question-remove-document-landing').replace('{document}', documentSelected.name):this.translate.instant('documentation-list.question-remove-document-definitely').replace('{document}', documentSelected.name);
      let signatureOrCertification = documentSelected.documentStatusId === 3 || documentSelected.documentStatusId === 4 || documentSelected.documentStatusId === 6 || documentSelected.sendedAsCertifiedEmail || documentSelected.sendedAsCertifiedSMS;
      if(signatureOrCertification){
          text = this.isLanding?this.translate.instant('documentation-list.delete-signature-or-certifcation-landing'):this.translate.instant('documentation-list.delete-signature-or-certifcation');
      }else if(documentSelected.publishedForApplicant){
          text = this.isLanding?this.translate.instant('documentation-list.delete-published-for-landing'):this.translate.instant('documentation-list.delete-published-for-applicant');
      }
      return text;
  }

  getDeletedDocuments(documentSelected:any){
      this.apiService.get('documentobjects/'+ this.concept.conceptObjectId +'/recyclebin')
          .then((data:any)=>{
              if(this.checkedName(data.documents, documentSelected, 'documentObjectId')){
                  this.deleteDocumentSelected(documentSelected, 'torecyclebin');
              }else{
                  this.loader.closeLoader('field-type-template-delete-document');
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-document').replace('{name}', documentSelected.name), 'error:accept:warning');
              }
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('common.delete-document-error').replace('{document}',  documentSelected.name);
              this.modalService.adviceModal(text, 'error:accept:warning');
      })
  }

  checkedName(list:any, item:any, type:any){
      for(let i = 0; i< list.length; i++){
          if(list[i][type] && list[i].name === item.name){
              return false;
          }
      }
      return true;
  }

  deleteDocumentSelected(documentSelected:any, type:string){
    let field: any = this.fieldTypeTemplate.field;
      let recycle:string = type === 'torecyclebin'? type : '' ;
      this.apiService.delete('documentobjects', documentSelected.documentObjectId, recycle)
          .then((data:any)=>{
              let text: string = this.translate.instant('documentation-list.definited-deleted-ok').replace('definited-deleted-ok',documentSelected.name)
              if(type === 'torecyclebin'){
                  text = this.translate.instant('documentation-list.document-trash').replace('{document}',documentSelected.name);
              }else if(this.isLanding){
                  this.broadcastService.broadcast('deleteAttachmentsDocumentLanding', {
                      document: documentSelected
                  })
              }
              documentSelected.id = documentSelected.documentObjectId;
              field.evaluatedValue.documents = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.documents, 'documentObjectId');
              field.evaluatedValue.displayData = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.displayData, 'id');
              let folder:any = this.getFolder(this.concept.documentation.subFolders, documentSelected.documentObjectId);
              if(folder){
                  folder.documents = this.commonUtilsService.deleteElement(documentSelected, folder.documents, 'documentObjectId');
              }else{
                  this.concept.documentation.documents = this.commonUtilsService.deleteElement( documentSelected, this.concept.documentation.documents, 'documentObjectId');
              }
              this.concept.documentation.totalDocuments--;
              let documentDeleted:any = documentSelected;
              documentDeleted.deleted = true;
              this.itemSelected.emit({newValue:documentDeleted});
              this.loader.closeLoader('field-type-template-delete-document');
              this.modalService.adviceModal(text, 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('common.delete-document-error').replace('{document}',  documentSelected.name);
              this.modalService.adviceModal(text, 'error:accept:warning');
          })
  }

  getFolder(subFolders:any, documentObjectIdSelected:any){
      for(let i = 0; i< subFolders.length; i++){
          let folder:any = subFolders[i];
          for(let j = 0; j< folder.documents.length; j++){
              let documentFolder:any = folder.documents[j];
              if(documentFolder.documentObjectId === documentObjectIdSelected){
                  return folder;
              }
          }
          if(folder.subFolders){
              this.getFolder(folder.subFolders, documentObjectIdSelected);
          }
      }
  }
}
