<div class="share-instance">
  <div class="common__modal-header-block">
      <i class="lf-icon-share common__icon-header"></i>
      <span class="common__text-header">{{shareInstance.title}}</span>
      <i class="lf-icon-close share-instance__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="share-instance__block-body"
      *ngIf="shareInstance.step === 1">
      <div class="share-instance__block-title">
          <p class="common__title-block-modal"
              [afTranslate]="'share-instance.question-title'">_¿Con qué finalidad quieres compartir el concepto?</p>
      </div>
      <div class="common__option-box common__option-box--two-elements"
          [ngClass]="{'common__option-box--disabled': existingTask}">
          <div [ngClass]="{'share-instance__header-box--disabled': existingTask}">
              <button class="common__button-box-option"
                  (click)="shareInstance.optionSelect='evaluation'"
                  [disabled]="existingTask">
                  <i class="common__button-box-icon"
                      [ngClass]="shareInstance.optionSelect==='evaluation'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                  <span class="common__button-box-text"
                      [afTranslate]="'common.evaluation'">_Evaluación</span>
              </button>
              <p class="common__box-text-info"
                  [afTranslate]="'share-instance.option-evaluation'">_Crearás una tarea de evaluación asignada a los usuarios o contactos que selecciones.</p>
          </div>
          <p class="common__contacts-import-icon"
              [ngClass]="{'common__contacts-import-icon--disabled': existingTask}">
              <i class="lf-icon-task-validate"></i>
          </p>
          <div class="share-instance__block-task-disabled"
              [ngShow]="existingTask">
              <span class="share-instance__text-task-disabled">
                {{shareInstance.evaluationErrorMessage}}
              </span>
          </div>
      </div>
      <div class="common__option-box common__option-box--two-elements float-end">
          <button class="common__button-box-option"
              (click)="shareInstance.optionSelect='query'">
              <i class="common__button-box-icon"
                  [ngClass]="shareInstance.optionSelect==='query'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
              <span class="common__button-box-text"
                  [afTranslate]="'share-instance.query'">_Consulta</span>
          </button>
          <p class="common__box-text-info"
              [afTranslate]="'share-instance.option-query'">_Los contactos seleccionados podrán acceder al concepto en modo lectura.</p>
          <p class="common__contacts-import-icon">
              <i class="lf-icon-transparency-report"></i>
          </p>
      </div>
  </div>
  <div class="share-instance__block-body share-instance__block-body--top"
      [ngClass]="{'share-instance__block-body--query-product': shareInstance.queryProduct}"
      *ngIf="shareInstance.step === 2">
      <wizard-steps *ngIf="!shareInstance.queryProduct"
        [steps]="shareInstance.steps"
        [state]="shareInstance.wizardState">
      </wizard-steps>
      <div class="share-instance__block-title share-instance__block-title--top">
          <p class="common__title-block-modal">{{shareInstance.shareTitle}}</p>
      </div>
      <form class="share-instance__form-share"
        [ngSwitch]="shareInstance.wizardState">
        <div *ngSwitchCase="1">
          <field-type-contacts-multiple [field]="shareInstance.shareInstanceContact"
              [concept]="concept"
              (sendContacts)="receiveContacts($event.contacts)"
              (contactsClosed)="contactsClosed()">
          </field-type-contacts-multiple>
          <rich-editor *ngIf="shareInstance.showText"
              class="common__input-textarea-special"
              [tinyMceOptions]="shareInstance.tinyMceOptions"
              [modelText]="shareInstance.message"
              (emitValue)="shareInstance.message = $event.model">
          </rich-editor>
        </div>
        <div *ngSwitchDefault>
          <field-type-list-extended [field]="shareInstance.shareTypeDocumentField"
              [listValues]="shareInstance.typeShareDocument"
              [preSelectItem]="shareInstance.typeShareDocumentSelected"
              (itemSelected)="shareInstance.typeShareDocumentSelected = $event.newValue">
          </field-type-list-extended>
          <field-type-list-multiple *ngIf="shareInstance.typeShareDocumentSelected==='2'"
            [field]="shareInstance.shareDocumentationField"
            [listValues]="shareInstance.shareDocumentationList"
            [preSelectItem]="shareInstance.shareDocumentationListSelected"
            (itemSelected)="selectSharedDocumentation($event)">
          </field-type-list-multiple>
        </div>
      </form>
  </div>
  <div class="common__buttons-block common__buttons-block--modal"
    *ngIf="shareInstance.step === 1">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="continueOptions()">{{shareInstance.buttonAccept}}</button>
  </div>
  <div class="common__buttons-block common__buttons-block--add-alert"
      [ngShow]="shareInstance.step === 2">
      <div *ngIf="!shareInstance.queryProduct; else itsQueryProduct">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="resetItems()"
            [afTranslate]="'common.clean'">_limpiar
        </button>
        <button *ngIf="shareInstance.wizardState === 2"
            class="common__button common__button--clean common__button--separating-margin"
            (click)="backStep()"
            [afTranslate]="'common.previous'">
        </button>
        <button *ngIf="shareInstance.wizardState === 1"
            class="common__button common__button--active common__button--separating-margin"
            (click)="nextStep()"
            [afTranslate]="'common.next'">
        </button>
        <button *ngIf="shareInstance.wizardState === 2"
            class="common__button common__button--active common__button--separating-margin"
            (click)="checkOptions()"
            [afTranslate]="'common.share'">
        </button>
      </div>
      <ng-template #itsQueryProduct>
        <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">Cancelar</button>
        <button class="common__button common__button--active common__button--separating-margin"
          (click)="handleAccept()"
          [afTranslate]="'common.share'">
          _Compartir
        </button>

      </ng-template>
  </div>
</div>
