<div class="users-admin common__panel">
    <header-nav></header-nav>
    <div class="common__block-window-view">
        <div class="common__block-button-close-window">
            <button (click)="changeView()"
                class="common__back-link">
                <span [afTranslate]="'users-admin.main.close-management'">Volver</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="users-admin__container">
            <div class="common__block-list-new-version common__block-list-new-version--height-auto">
                <div class="common__header-new-version">
                    <i class="lf-icon-label-settings common__header-icon-new-version"
                        [ngShow]="skin !== 'uk-steel-blue'"></i>
                    <span class="common__header-title-new-version">{{usersAdmin.headerText}}</span>
                </div>
                <div class="users-admin__main">
                  <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
