import $ from 'jquery';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { HiddenElementsFactory } from '@shared/factories/hidden-elements/hidden-elements.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import { MenuIconsService } from 'src/app/shared/services/menu-icons/menu-icons.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';

@Component({
  selector: 'evaluation',
  templateUrl: './evaluation.component.html'
})
export class EvaluationComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  changeTree: boolean = false;
  evaluatedClientScore: boolean = false;
  evaluationId: string = this.route.snapshot.params['evaluationId'];
  fromExit: any;
  subscribers: any = {};
  evaluation: any = {
    userPermisions: this.route.snapshot.data['userPermissions'],
    menuIcons: {
      icons: []
    },
    changeBreadcrumb: false,
    heightBlock: '100%',
    evaluation: {},
    evaluatedConceptInfo: {},
    breadcrumbList: [],
    fullMenu: false,
    folderSelect: null,
    listAllFolder: [],
    evaluationTree:[],
    listFolders: [],
    modifiedFolder: null,
    size: null,
    draftEvaluations: false
  }
  constructor( private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private loader: LoaderFactory,
    private hiddenElements: HiddenElementsFactory,
    private router: Router,
    private context: ContextFactory,
    private convertNumbToName: ConvertNumToNameFactory,
    private registerService: RegisterMixtapeService,
    private translate: AfTranslateFactory,
    private readonly modalService: ModalServiceService,
    private menuIcons: MenuIconsService,
    private commonUtilsService: CommonUtilsService,
    private stateManagement: StateManagementFactory) {}

  ngOnInit(): void {
    if(location.href.indexOf('?preview') === -1){
      this.rolesDesassignedResolve();
    }
    this.clickMenu();
    this.launchWatchers();
    this.calcWidth();
    this.configureEvaluation();
    if(this.context.notCallAnonymatumService && this.globalCfg.skin==='dark-blue'){
      this.getAnonimatumPermissions();
    }
  }

  launchWatchers(){
    this.subscribers.saveConceptToEvaluationBroadcast = this.broadcastService.subscribe('saveConceptToEvaluation', (data:any)=>{
        this.fromExit = data;
        this.broadcastService.broadcast('saveConcept');
    });
    this.subscribers.savedConceptExitBroadcast = this.broadcastService.subscribe('savedConceptExit', (data:any)=>{
        this.exitConcept();
    });
    this.subscribers.lockedOperationToEvaluationBroadcast = this.broadcastService.subscribe('lockedOperationToEvaluation', (data:any)=>{
        this.broadcastService.broadcast('lockedOperationToEvaluationMenu');
    });
    this.subscribers.changeConceptBroadcast = this.broadcastService.subscribe('changeConcept', (data:any) => {
      this.findItemInTree(data.changeConcept)
    });
    this.subscribers.changeFolderBroadcast = this.broadcastService.subscribe('changeFolderEvaluation', (data:any) => {
      this.findFolderList(this.evaluation.listAllFolder, data.folderId);
    });
    this.subscribers.launchActionBroadcast = this.broadcastService.subscribe('launchAction', (data:any) => {
      this.launchAction(data.action)
    });

    this.subscribers.hasDraftBroadcast = this.broadcastService.subscribe('hasDraft', (data:any)=>{
      this.getEvaluationInfo();
      if(this.skin !== 'icam-red'){
        this.getDraftEvaluations(data.evaluationId);
      }
    });

    this.subscribers.changeFullMenuBroadcast = this.broadcastService.subscribe('changeFullMenu',(data:any)=>{
      this.evaluation.fullMenu = data.fullMenu;
      if(this.evaluation.openMenu === undefined){
           this.evaluation.openMenu = data.fullMenu;
      }
    });

    this.subscribers.breadCrumbEditBroadcast = this.broadcastService.subscribe('breadCrumbEdit', (data:any)=>{
      this.editConceptInstanciable(data);
    });

    this.subscribers.changeConceptFromEvaluationBroadcast = this.broadcastService.subscribe('changeConceptFromEvaluation', (data:any)=>{
      this.searchItem(this.evaluation.evaluationTree.children, data.concept, data.action, data.type);
    });

    this.subscribers.changeLockedEvaluationBroadcast = this.broadcastService.subscribe('changeLockedEvaluation', (data:any)=>{
      this.changeList(this.evaluation.evaluationTree.children);
      this.broadcastService.broadcast('evaluationInfo', {
          evaluationTree: this.evaluation.evaluationTree,
          evaluation: this.evaluation.evaluation
      })
    });

    this.subscribers.changeReportListEvaluationBroadcast = this.broadcastService.subscribe('changeReportListEvaluation', (data:any)=>{
      this.broadcastService.broadcast('changeReportList', {report: data.report});
    });

    this.subscribers.getHeaderNotificationsEvaluationBroadcast = this.broadcastService.subscribe('getHeaderNotificationsEvaluation', (data:any)=>{
      this.broadcastService.broadcast('getHeaderNotifications');
    });

    this.watchRefreshEvaluatedConceptInfo();
    this.watcherEvaluateConcept();
    this.loader.closeLoader(['front-login-login']);
  }

  exitConcept(){
    switch(this.fromExit.from){
        case 'evaluationHeader':
            if(this.fromExit.item.folderId === 'dashboard-breadcrumb'){
                this.router.navigate(['/dashboard']);
            }else{
                this.launchAction({
                    action:{
                        type: this.fromExit.option,
                        folder: this.fromExit.item,
                        goBack: this.fromExit.goBack
                    }
                })
            }
            break;
        case 'evaluationMenu':
            this.broadcastService.broadcast('exitConceptEvaluationMenu', {configuration: this.fromExit});
            break;
        case 'menuHeaderUser':
            this.broadcastService.broadcast('exitConceptMenuHeaderUser', {option: this.fromExit.option});
            break;
        case 'fieldTypeAccess':
            this.loader.openLoader('folder-open-concept');
            this.router.navigate(['evaluation', this.evaluationId, 'folder', this.fromExit.folder.folderId]);
            break;
        default:
            this.broadcastService.broadcast('exitConceptHeaderNav', {configuration: this.fromExit});
            break;
    }
}

  changeList(children:any){
    for(let folder of children){

        if(this.evaluation.evaluation.productId === 'blanqueo'){
            this.configureListBlanqueo(folder);
        }
        if(folder.children && folder.children.length){
            this.changeList(folder.children);
        }
    }
  }

  findItemInTree(changeConcept:any){
    for(let i = 0; i < this.evaluation.listAllFolder.length; i++){
        let folder :any = this.evaluation.listAllFolder[i];
        if(folder.folderId === changeConcept.concept.folderId){
            switch(changeConcept.change){
                case 'noEvaluated':
                    folder.evaluated = changeConcept.evaluated;
                    break;
                case 'next':
                    this.openFolder(this.checkNextLevel(i+1));
                    break;
                default:
                    this.openFolder(this.checkPrevLevel(i-1));
                    break;
            }
        }
    }
  }

  checkNextLevel(index:number){
    for(let i = index; i < this.evaluation.listAllFolder.length; i++){
      let folderOpen : any= this.evaluation.listAllFolder[i];
      let multipleInstancesInAuto:any =(folderOpen.conceptObjectId && folderOpen.concepts && folderOpen.concepts.length)? folderOpen.concepts[0].multipleInstancesInAuto : false;
      if((!folderOpen.hidden || this.hiddenElements.show) && !folderOpen.children.length && !multipleInstancesInAuto){
          return folderOpen;
      }
    }
  }

  checkPrevLevel(index:number){
    for(let i = index; i < this.evaluation.listAllFolder.length; i--){
        let folderOpen = this.evaluation.listAllFolder[i];
        let multipleInstancesInAuto:any = folderOpen.conceptObjectId && folderOpen.concepts && folderOpen.concepts.length? folderOpen.concepts[0].multipleInstancesInAuto : false;
        if((!folderOpen.hidden || this.hiddenElements.show) && !folderOpen.children.length && !multipleInstancesInAuto){
            return folderOpen;
        }
    }
  }


  openFolder(folder:any, type:string = ''){
    this.evaluation.evaluatedConceptInfo = null;
    this.evaluation.folderSelect = folder;
    if(this.evaluation.folderSelect.folderId==='reports' || this.evaluation.folderSelect.folderId==='favorites'){
      this.router.navigate(['evaluation', this.evaluationId, this.evaluation.folderSelect.folderId]);
    }else{
        this.loader.openLoader('folder-on-init');
        if(this.evaluation.evaluation.productTypeId === 2 && type === 'searchChildren' && folder.children.length){
            this.evaluation.folderSelect = folder.children[0];
        }
        
        this.router.navigate(['evaluation', this.evaluationId, 'folder', this.evaluation.folderSelect.folderId]);
        
    }
  }

  watchRefreshEvaluatedConceptInfo(){
    this.evaluation.refreshEvaluatedConceptInfoBroadcast = this.broadcastService.subscribe('refreshEvaluatedConceptInfo', (data:any)=>{
        this.evaluation.evaluatedConceptInfo = data.evaluatedConcept;
        if(!(this.route.snapshot.params['reportObjectId'] || data.type === 'reports') && data.type !== 'favorites'){
            this.evaluation.evaluatedConceptInfo.type = 'concept';
            this.evaluation.evaluatedConceptInfo.id = this.route.snapshot.params['conceptId'];
        }else if(data.type === 'favorites'){
          this.evaluation.evaluatedConceptInfo.folderId = data.type;
        }
        if(this.evaluation.folderSelect && ((this.route.snapshot?.firstChild?.data['componentName'] !== 'reports' && this.evaluation.folderSelect.folderId === 'reports') || (this.route.snapshot?.firstChild?.data['componentName'] !== 'favorites' && this.evaluation.folderSelect.folderId === 'favorites') || (this.route.snapshot?.firstChild?.data['componentName'] === 'reportInfo' && this.evaluation.folderSelect.folderId !== 'reports'))){
          let folderId = this.route.snapshot?.firstChild?.data['componentName'] === 'reportInfo'?'reports':parseInt(this.route.snapshot.firstChild?.params['folderId']);
          this.findFolderList(this.evaluation.listAllFolder, folderId);
        }
        this.evaluation.changeBreadcrumb = true;
        let folderIdSelected = data.type  === 'reports' || data.type === 'favorites'?this.route.snapshot.firstChild?.params['folderId']:parseInt(this.route.snapshot.firstChild?.params['folderId']);
        this.changeActiveMenu(folderIdSelected);
    })
  }

  changeActiveMenu(folderId:any=this.evaluation.folderSelect?.folderId){
    this.broadcastService.broadcast('changeActiveMenu', {folderId: folderId});
  }

  findFolderList(tree:any, folderId:any){
    for(let item of tree){
      if(item.folderId === folderId){
        this.evaluation.folderSelect =  item;
        break;
      }
    }
  }

  rolesDesassignedResolve(){
    this.apiService.get('productroles/evaluation/'+this.evaluationId+'/not-assigned')
      .then((data:any)=> {
        this.evaluation.rolesDesassigned = data;
        if(this.evaluation.rolesDesassigned){
          this.showModalRoleError();
        }
      }, (errorData:any)=>{})
  }

  clickMenu(){
    $(document).off('click', (event)=>{this.closeMenu(event)});
    $(document).on('click', (event)=>{this.closeMenu(event)});
  }

  closeMenu(event:any){
    if(window.innerWidth < 1367 && (event.target.className.indexOf === undefined || event.target.className.indexOf('lf-icon-meatballs-menu') !== -1)){
      event.stopPropagation();
      event.preventDefault();
    }else if ((event.target.className.indexOf === undefined || event.target.className.indexOf('evaluation-menu') === -1) && window.innerWidth < 1367 && this.evaluation.openMenu){
      this.evaluation.openMenu = false;
      this.broadcastService.broadcast('openMenu', {closeMenu: !this.evaluation.openMenu});
    }
  }


  watcherEvaluateConcept(){
    this.evaluation.openEvaluatedConceptBroadcast = this.broadcastService.subscribe('openEvaluatedConcept', (data:any)=>{
        if(data.conceptInfo.evaluatedConcept.reportId){
          this.openReport(data);
        }else{
          this.openConcept(data);
        }
        this.loader.closeLoader(['dashboard-panel-create-evaluation']);
    });
  }

  openReport(data:any){
    let resgisterItem:any ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':'open report',
      'Report Type': this.convertNumbToName.reportNumbToName(String(data.conceptInfo.report.reportId)),
      'Source': 'other',
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem)
    let conceptInfoArray: any= [
      'evaluation', this.evaluationId,
      'reports', data.conceptInfo.report.reportId,
      'report-related', data.conceptInfo.evaluatedConcept.reportObjectId
    ]
    if(data.conceptInfo.archived){
      conceptInfoArray.push('archived')
    }
    this.router.navigate(conceptInfoArray);
  }

  openConcept(data:any){
    let resgisterItem:any ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':'open instance',
      'Concept Name': sessionStorage.getItem('breadcrumbConcept') + data.conceptInfo.concept.title,
      'Centinela product': this.context.settings.defaultProduct
      }
    this.registerService.register(resgisterItem);
    let folderId = parseInt(this.route.snapshot.firstChild?.params['folderId']);
    this.router.navigate(['evaluation', this.evaluationId, 'folder', folderId, 'concept', data.conceptInfo.concept.conceptId, 'concept-evaluated', data.conceptInfo.evaluatedConcept.conceptObjectId]);
  }

  convertBreadcrumb(){
    let text:string = ''
    for(let breadCrumb of this.evaluation.breadcrumbList){
        text += breadCrumb.name+'>'
    }
    return text;
  }



  hasResize(e:any){
    this.evaluation.openMenu = e.resize;
    this.minimizeMenu(!e.resize);
  }

  minimizeMenu(resizeWidth:boolean = true){
    this.evaluation.openMenu = !this.evaluation.openMenu;
    this.broadcastService.broadcast('openMenu', {closeMenu: !this.evaluation.openMenu});
    this.evaluation.heightBlock = '100%';
    if(window.innerWidth >= 1367 && !resizeWidth){
        this.evaluation.changeBreadcrumb = true;
    }
    if(window.innerWidth < 1367){
        if(this.evaluation.openMenu){
            setTimeout(()=>{
                let blockRight:any = $('.evaluation-menu__info-block').height();
                let blockMenu:any = $('.evaluation__evaluation-menu').height();
                if(blockMenu > blockRight){
                  this.evaluation.heightBlock = blockMenu + 20 + 'px';
                }
            });
        }
    }
  }

  launchActionOn(e: any){
    this.launchAction(e.action);
  }

  launchAction(action:any){

    switch(action.type){
        case 'open':
            this.evaluation.heightBlock = '100%';
            this.openFolder(action.folder);
            break;
        case 'new':
            this.createFolder(action.folder);
            break;
        case 'delete':
            this.confirmDeleteFolder(action.folder, action.goBack);
            break;
        case 'newConcept':
              this.addNewConcept(action.folder);
            break;
        case 'edit-concept-folder':
            this.configureConceptInstanciable(action.folder);
            break;
        case 'rename':
            this.renameFolder(action.folder);
            break;
        case 'edit':
            this.editImplantation();
            break;
        case 'configuration':
            this.configureKnowledge();
            break;
        case 'hidden':
        case 'restore':
        case 'hiddenConcept':
        case 'restoreConcept':
            this.changeVisibility(action);
            break;
        case 'changeHidden':
            this.hiddenElements.changeShow();
            if(this.evaluation.folderSelect && this.evaluation.folderSelect.hidden){
                for(let folder of  this.evaluation.evaluationTree.children){
                    if(folder.folderId === this.evaluation.folderSelect.folderId && !this.evaluation.folderSelect.parentId){
                        let indexOrder : any = this.evaluation.folderSelect.indexOrder;
                        if(indexOrder >= this.evaluation.evaluationTree.children.length-1){
                            indexOrder = 0;
                        }
                        let newFolder : any = this.nextParentFolder(indexOrder);
                        this.openFolder(newFolder);
                        break;
                    }
                }
            }
            this.broadcastService.broadcast('changeHiddenElements',{})
            break;
        case 'duplicate':
            this.openDuplicate(action.folder);
            break;
        case 'confidential':
            this.openModalConfidential(action.folder);
            break;
        case 'changeBreadcrumb':
            this.evaluation.changeBreadcrumb = action.change;
            break;
        default:
            break;
    }
  }

  createFolder(folder:any){
    this.evaluation.listFolders = [];
    if(folder.folderId){
      this.getTree(this.evaluation.evaluationTree.children, folder.folderId)
    }else{
      this.evaluation.listFolders = folder.children
    }

    let newItem:any = {
        title:  this.translate.instant('rename-modal.name-folder-new'),
        type: 'folder',
        titleConcept: '',
        icon: 'lf-icon-folder-user',
        conceptId: false,
        conceptObjectId: false,
        listFolders: this.evaluation.listFolders,
        rename: false
    }

    this.modalService.renameModal(newItem).subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('evaluation-change-name')
          this.customAndCreate(folder, result.name);
        }
      })
  }

  getTree(children:any, folderId:any){
    for(let folder of children){
        if(folder.folderId === folderId){
          this.evaluation.listFolders = folder.children;
        }else if(folder.children){
            this.getTree(folder.children,folderId);
        }
        if(this.evaluation.listFolders.length !== 0){
          break;
        }
    }
  }

  customAndCreate(folder:any, name:any){
    if(!folder.folderId){
        folder.folderId = null;
    }
    let newItem = {
        parentId: folder.folderId,
        name: name,
        isFront: true
    };
    this.apiService.add('folders/'+this.evaluationId, newItem)
        .then((data:any)=>{
            this.changeTree = false;
            data.children = [];
            data.concepts = [];
            data.level = folder.level+1;
            data.isFront = true;
            data.evaluated = true;
            data.showTreeIcon = folder.folderId === null?data.folderId !== 'reports' && (this.skin === 'dark-blue' || this.skin === 'gm-law'):folder.showTreeIcon;
            if(!data.parentId){
                data.icon = "lf-icon-folder-user";
                this.evaluation.evaluationTree.children.splice(this.evaluation.evaluationTree.children.length-1, 0, data);
            }
            this.changeInTree(this.evaluation.evaluationTree.children, folder, 'add', data, this.evaluation.evaluationTree);
            this.evaluation.folderSelect = data;
            this.evaluation.listAllFolder = [];
            this.generateListAllFolder(this.evaluation.evaluationTree.children);
            this.loader.closeLoader(['evaluation-change-name', 'evaluation-menu-menu-action']);
            this.loader.openLoader('folder-on-init');
            this.router.navigate(['evaluation', this.evaluationId, 'folder', data.folderId]);
        }, (errorData:any)=>{
            this.loader.closeError()
        })
  }

  changeInTree(children:any, folderSelect:any, action:string, toAdd:any, parent:any = null){
    for(let i = children.length-1; i >= 0; i--){
        if(children[i].folderId === folderSelect.folderId){
            if(action === 'delete'){
                children.splice(i, 1);
                if(folderSelect && !folderSelect.parentId){
                    this.evaluation.folderSelect = children[0];
                }else if(folderSelect && folderSelect.parentId && parent !== null){
                    this.evaluation.folderSelect = parent;
                }
            }else{
                children[i].children.splice(children[i].children.length, 0, toAdd);
            }
            this.changeTree = true;
        }else if(children[i].children.length){
            this.changeInTree(children[i].children, folderSelect, action, toAdd, children[i])
        }
        if(this.changeTree){
            this.evaluation.listAllFolder = [];
            this.generateListAllFolder(this.evaluation.evaluationTree.children);
            break;
        }
    }
  }

  generateListAllFolder(children:any){
    for(let i = 0; i < children.length; i++){
        let folder = children[i];
        if(!folder.parentId){
            folder.indexOrder = i;
        }
        folder.showTreeIcon = folder.folderId !== 'reports' && (this.skin === 'dark-blue' || this.skin === 'gm-law');
        folder.showLinkButtonMeasures = (this.evaluation.evaluation.productId === 'igualdad' && (folder.iD_Version0 === 559226 || folder.iD_Version1 === 559226)) || (this.evaluation.evaluation.productId === 'igualdad02' && (folder.iD_Version0 === 848596 || folder.iD_Version1 === 848596)) || (this.evaluation.evaluation.productId === 'antifraude' && (folder.iD_Version0 === 967493 || folder.iD_Version1 === 967493));
        if(folder.showLinkButtonMeasures){
            folder.tooltipButtonMeasures = this.evaluation.evaluation.productId === 'antifraude'?'evaluation-menu.pdf-link-tooltip-antifraude':'evaluation-menu.pdf-link-tooltip';
        }
        if(this.evaluation.evaluation.productId === 'blanqueo'){
            this.configureListBlanqueo(folder);
        }
        this.evaluation.listAllFolder.push(folder);
        if(folder.children && folder.children.length){
            this.generateListAllFolder(folder.children);
        }
    }
  }

  configureListBlanqueo(folder: any){
      folder.disabledFolder = false;
      folder.lockedFolder = false;
      if((this.skin === 'dark-blue' && folder.iD_Version0 === 348975) || (this.skin === 'steel-blue' && folder.iD_Version0 === 2078)){
          folder.folderNamedKey = 'SujetoObligado';
      }else if((this.skin === 'dark-blue' && folder.iD_Version0 === 1058302) || (this.skin === 'steel-blue' && folder.iD_Version0 === 2103)){
          folder.folderNamedKey = 'Cliente';
      }else if((this.skin === 'dark-blue' && folder.iD_Version0 === 750298) || (this.skin === 'steel-blue' && folder.iD_Version0 === 2110)){
          folder.folderNamedKey = 'Operacion';
      }else if((this.skin === 'dark-blue' && folder.iD_Version0 === 793943) || (this.skin === 'steel-blue' && folder.iD_Version0 === 2111)){
          folder.folderNamedKey = 'DiligenciasReforzadas';
      }else if((this.skin === 'dark-blue' && folder.iD_Version0 === 289707) || (this.skin === 'steel-blue' && folder.iD_Version0 === 2117)){
        folder.folderNamedKey = 'ExamenEspecial';
      }else if(folder.folderId === 'reports'){
          folder.folderNamedKey = 'reports';
      }
      let folderDifferent = folder.folderNamedKey !== 'SujetoObligado' && folder.folderNamedKey !== 'Cliente' && folder.folderNamedKey !== 'Operacion';
      folder.lockedFolder = this.evaluation.evaluation.isFinished?!folderDifferent:false;
      folder.disabledFolder = this.skin === 'steel-blue'?false:this.configureDisabledTotalScore(folder);
  }

  configureDisabledTotalScore(folder: any){
    let operationEnabled : any = folder.folderNamedKey === 'Operacion' && this.evaluation.evaluation.operationEverEnabled?true:false;
    let reportEnabled : any = folder.folderNamedKey === 'reports' && this.evaluation.evaluation.totalScore && this.evaluation.evaluation.totalScore.message?true:false;
    if(folder.folderNamedKey === 'SujetoObligado' || folder.folderNamedKey === 'Cliente' || operationEnabled || reportEnabled){
        return false;
    }else{
        let enabledHightRiskCentinela : any = this.evaluation.evaluation.totalScore && (this.evaluation.evaluation.totalScore.riskCode === 3 || this.evaluation.evaluation.totalScore.riskCode === 4)?true:false;
        return !(enabledHightRiskCentinela);
    }
  }

  confirmDeleteFolder(folder:any, goBack:any){
    this.modalService.adviceModal(this.translate.instant('evaluation.message-delete')+folder.name+' ?', 'info:question:question')
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('evaluation-delete-folder')
          this.deleteFolder(folder, goBack);
        }
      });
  }

  deleteFolder(folder:any, goBack:any){
    this.apiService.delete('folders/'+this.evaluationId , folder.folderId)
      .then((data:any)=>{
          this.changeTree = false;
          let oldFolderSelect : any = cloneDeep(this.evaluation.folderSelect);
          this.changeInTree(this.evaluation.evaluationTree.children, folder, 'delete', this.evaluation.evaluationTree);
          if(goBack || (oldFolderSelect && folder.folderId === oldFolderSelect.folderId) || !folder.parentId){

            this.loader.openLoader('folder-on-init');
            this.router.navigate(['evaluation', this.evaluationId, 'folder', this.evaluation.folderSelect.folderId]);
          }else if(oldFolderSelect && folder.parentId === oldFolderSelect.folderId){
              if(this.route.snapshot?.firstChild?.data['componentName'] !== 'concept'){
                  this.loader.openLoader('folder-on-init');
                  this.broadcastService.broadcast('deletechildrenFolder', {folder:data});
              }
          }
          this.modalService.adviceModal(this.translate.instant('evaluation.folder') +"'"+ folder.name +"'"+ this.translate.instant('evaluation.folder-remove'), 'info:accept:check-round');
          this.loader.closeLoader(['evaluation-delete-folder', 'evaluation-menu-menu-action']);

      }, (errorData:any)=>{
          this.loader.closeError();
          if(errorData.status === 403){
              this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');
          }
      })
  }

  addNewConcept(folder:any){
    let option = {
        title: this.translate.instant('create-concept.new-custom-concept-template'),
        type: 'create'
    }
    this.modalService.basicDataModal(folder, option)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.closeCreateConcept(result.concept, folder);
        }
      });
  }

  closeCreateConcept(concept:any, folder:any){
      if(concept !== null){
          this.evaluation.modifiedFolder = null;
          this.modifySelectFolder(this.evaluation.listAllFolder, folder);
          if(this.evaluation.evaluation.productTypeId === 2){
            this.evaluation.modifiedFolder.evaluated = false;
          }
          this.evaluation.modifiedFolder.concepts.push(concept);
          this.evaluation.folderSelect = folder;
          this.editConceptInstanciable({concept: concept});
      }
  }

  modifySelectFolder(children:any, folder:any){
    for(let item of children){
        if(folder.folderId === item.folderId){
            this.evaluation.modifiedFolder = item;
            break;
        }
    }
  }

  editConceptInstanciable(data:any){
    let to = data.to?data.to:'form';
    let url: any = 'evaluation/' + this.evaluationId + '/folder/' + this.evaluation.folderSelect.folderId + '/concept/' + data.concept.conceptId + '/' + to;
    if(this.route.snapshot?.firstChild?.data['componentName'] === 'editConcept'){
      this.commonUtilsService.reloadNavigateUrlEvaluation(url, this.evaluationId);
    }else{
      this.router.navigateByUrl(url);
    }
  }

  configureConceptInstanciable(folder:any){
    this.loader.openLoader('folder-edit-concept');
    let concept: any = folder.concepts[0];
    let data : any = {
        concept: concept,
        to: !concept.isFront? 'form-process' : 'form'
    }
    this.evaluation.folderSelect = folder;
    this.editConceptInstanciable(data);
  }

  renameFolder(folder:any){
    if(folder.parentId){
        this.getTree(this.evaluation.evaluationTree.children, folder.parentId);
    }else{
        this.evaluation.listFolders = this.evaluation.evaluationTree.children;
    }
    this.getTree(this.evaluation.evaluationTree.children, folder.parentId);
    let newItem : any = {
        title:  this.translate.instant('rename-modal.name-folder-rename'),
        type: 'folder',
        titleConcept: folder.name,
        conceptId: false,
        conceptObjectId: false,
        listFolders: this.evaluation.listFolders,
        rename: true
    }
    this.modalService.renameModal(newItem).subscribe((result:any) => {
        if(result.result === 'ok'){
          this.changeNameList(folder, result.name)
          this.changeName(folder, result.name);
          this.evaluation.evaluationTree.children = this.commonUtilsService.replaceElementTree(folder, this.evaluation.evaluationTree.children, 'folderId');
        }
      })

  }

  changeNameList(folder:any, name:any){
    this.evaluation.evaluationTree.children.forEach((evaluation:any) => {
      let child: any = evaluation.children;
      for (let item of child){
        if(folder.folderId === item.folderId){
          item.name = name;
          break;
        }
      }
    });

  }

  changeName(folder:any, name:any){
    this.loader.openLoader('evaluation-change-name');
    this.apiService.patch('folders/'+this.evaluationId+'/rename', folder.folderId, {name: name})
        .then((data:any)=>{
            folder.name = name;
            this.loader.closeLoader(['evaluation-change-name','evaluation-menu-menu-action']);
            this.modalService.adviceModal(this.translate.instant('evaluation.change-name-ok'), 'info:accept:check-round')
        }, (errorData:any)=>{
            this.loader.closeError()
        })
  }

  editImplantation(){
    let configuration : any = {
      type: 'edit',
      evaluationId: this.evaluationId
    }
    this.modalService.createNewEvaluation(configuration)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.adviseSendChanges(result, this.translate.instant('evaluation.modify-visibility'));
        }
      })
  }

  adviseSendChanges(evaluationProperties:any, text:string){
    this.modalService.adviceModal(text, 'error:question:question')
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('evaluation-modify-evaluation')
          result.roles && result.roles.length?this.saveRoles(evaluationProperties) : this.modifyEvaluation(evaluationProperties);
        }
      })
  }

  saveRoles(result:any){
    this.apiService.add('productroles/assign', result.roles)
        .then((data:any)=>{
            this.modifyEvaluation(result);
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('dashboard.error-assign-roles'), 'error:accept:warning');
        });
  }

  modifyEvaluation(result:any){
    this.apiService.patch('evaluations/properties', this.evaluationId, result.createEvaluation.propertyValues)
        .then((data:any)=>{
            window.location.reload();
        }, (errorModal:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('evaluation.error-implantation-modify'), 'error:accept:warning');
        })
  }

  configureEvaluation(){
    this.evaluation.folderSelect = null;
    this.evaluation.evaluationTree = {
        children:[]
    };
    this.evaluation.reports = {
        folderId:'reports',
        active: false,
        conceptObjectId: 0,
        concepts: [],
        children: [],
        description: null,
        documents: null,
        hidden: false,
        isFront: false,
        level: 1,
        name: this.translate.instant('common.reports'),
        propertyVisibleValues: [],
        published: false,
        showFolders: false,
        totalConcepts: 0,
        icon:'lf-icon-analysis'
    };
    this.evaluation.size = null;
    this.evaluation.draftEvaluations = false;
    this.configureEvaluationInfo(this.route.snapshot.data['evaluationInfo'], true);
    if(this.skin !== 'icam-red'){
      this.getDraftEvaluations(this.evaluationId);
    }
  }

  getEvaluationInfo(){
    this.apiService.get('evaluations',this.evaluationId)
        .then((data:any)=> {
            this.configureEvaluationInfo(data, false);
        }, (errorData:any)=>{
            this.loader.closeError()
        })
  }

  configureEvaluationInfo(data: any, getEvaluationTree:any = false){
    this.context.setProductId(data.productTypeId);
    this.configureOptionsBlanqueo(data);
    this.evaluation.evaluation = data;
    this.context.settings.defaultProduct = data.productId;
    this.context.settings.defaultProductName = data.productName;
    this.context.settings.productWithSnippets = data.snippets;
    this.context.storeSettings();
    if(this.evaluation.evaluation.productTypeId === 4){
        this.evaluation.reports.folderId = 'favorites';
        this.evaluation.reports.name = this.translate.instant('common.favorites');
        this.evaluation.reports.icon = 'lf-icon-favorite';
    }
    if(this.evaluation.evaluation.productId === 'blanqueo'){
      this.getTotalScore(getEvaluationTree);
    }else if(getEvaluationTree){
        this.getEvaluationTree();
    }
  }

  getAnonimatumPermissions(){
    this.apiService.get('anonymize','hasproduct')
      .then((data:any)=>{
          this.context.setAnonymatumService(data)
      }, (errorData:any)=>{})
  }

  configureOptionsBlanqueo(data:any){
    if(this.evaluation.evaluation && this.evaluation.evaluation.productId === 'blanqueo'){
      if(this.evaluation.evaluation.totalScore){
          data.totalScore = this.evaluation.evaluation.totalScore;
      }
      if(this.evaluation.evaluation.clientScore){
          data.clientScore = this.evaluation.evaluation.clientScore;
      }
      if(this.evaluation.evaluation.disabledManageEvaluation){
          data.disabledManageEvaluation = this.evaluation.evaluation.disabledManageEvaluation;
      }
      if(this.evaluation.evaluation.disabledCheckOperation){
          data.disabledCheckOperation = this.evaluation.evaluation.disabledCheckOperation;
      }
    }
}

  getTotalScore(getEvaluationTree: any){
    this.apiService.get(this.evaluation.evaluation.productId + '/totalscore', this.evaluationId)
      .then((data:any)=> {
        let totalScore : any;
        if(data){
            totalScore = {
                riskCode: data.acceptedresult===1?data.realRiskCode:data.modifiedRiskCode,
                color: data.acceptedresult===1?data.realColor:data.modifiedColor,
                message: data.acceptedresult===1?data.realMessage:data.modifiedMessage,
                risk: data.acceptedresult===1?data.realRisk:data.modifiedRisk,
                hightRisk: data.realRiskCode === 4 || data.modifiedRiskCode === 4?true:false
            }
        }
        this.evaluation.evaluation.totalScore = totalScore;
        if(this.skin === 'steel-blue'){
          this.getReviewFrequency();
        }
        if(!(this.evaluation.evaluation.totalScore && this.evaluation.evaluation.totalScore.message)){
          this.getEvaluatedClientScore(getEvaluationTree);
        }else{
          this.configureChangesList(getEvaluationTree);
        }
    }, (errorData:any)=>{
        this.loader.closeError();
    })
  }

  getReviewFrequency(){
    this.apiService.get(this.evaluation.evaluation.productId + '/reviewfrequency', this.evaluationId)
      .then((data:any)=> {
        this.evaluation.evaluation.reviewFrequency = data;
    }, (errorData:any)=>{
        this.loader.closeError();
    })
  }

  getEvaluatedClientScore(getEvaluationTree: any){
    this.apiService.get(this.evaluation.evaluation.productId + '/partialclientscore', this.evaluationId)
      .then((data:any)=> {
        this.evaluation.evaluation.clientScore = data;
        this.configureChangesList(getEvaluationTree);
    }, (errorData:any)=>{
        this.loader.closeError();
    })
  }

  configureChangesList(getEvaluationTree: any){
    this.evaluation.evaluation.disabledManageEvaluation = this.evaluation.userPermisions === 'none' || !this.evaluation.evaluation.totalScore || (this.evaluation.evaluation.totalScore && !this.evaluation.evaluation.totalScore.message);
    this.evaluation.evaluation.disabledCheckOperation = this.evaluation.userPermisions === 'none' || !this.evaluation.evaluation.totalScore || (this.evaluation.evaluation.totalScore && !this.evaluation.evaluation.totalScore.message) || (!this.evaluation.evaluation.configureRolesLevel?.enabledButtonManageBlock && this.evaluation.evaluation.operationLocked);
    if(getEvaluationTree){
        this.getEvaluationTree();
    }else{
      this.changeList(this.evaluation.evaluationTree.children);
      this.broadcastService.broadcast('evaluationInfo', {
          evaluationTree: this.evaluation.evaluationTree,
          evaluation: this.evaluation.evaluation
      })
    }
  }

  getEvaluationTree() {
      let data : any = cloneDeep(this.route.snapshot.data['evaluationTree']);
      if(data.length){
          for (let item of data) {
              if (item.confidential) {
                  this.setConfidential(item);
              }
              this.configureConfidential(item.children)
          }
          this.evaluation.evaluationTree.children = data;
      }
      if(!(this.evaluation.evaluation.productTypeId === 1 && this.globalCfg.dashboardVersion === '2' && this.skin !== 'icam-red')){
          this.evaluation.evaluationTree.children.splice(this.evaluation.evaluationTree.children.length, 0, this.evaluation.reports);
      }
      this.evaluation.listAllFolder = [];
      this.generateListAllFolder(this.evaluation.evaluationTree.children);
      this.getEvaluationMenuIcons();
      this.configureInit(data);
      sessionStorage.setItem('evaluationTree', JSON.stringify(this.evaluation.evaluationTree));
  }

  configureConfidential(children: any) {
      for (let childFolder of children) {
          if (childFolder.confidential) {
              this.setConfidential(childFolder);
          }
          if (childFolder.children.length) {
              this.configureConfidential(childFolder.children);
          }
      }
  }

  setConfidential(folder: any) {
      this.apiService.get('folders/confidentialaccess', folder.folderId)
      .then((data:any)=>{
          folder.confidentialList = data;
      }, (errorData:any)=>{ })
  }

  getEvaluationMenuIcons(){
    let menu: any = this.menuIcons.getProductIcons(this.evaluation.evaluation.productId);
    this.evaluation.menuIcons = menu;
    this.evaluation.product = this.evaluation.evaluation.productId

    this.configureIcons();
    this.broadcastService.broadcast('evaluationInfo', {
        evaluationTree: this.evaluation.evaluationTree,
        evaluation: this.evaluation.evaluation
    })
  }

  configureIcons(){
    this.evaluation.evaluationTree.children.forEach((children:any) => {
      if(children.folderId !== 'favorites' && children.folderId !== 'reports'){
            children.icon = this.getIcon(children);
        }
    });
  }

  getIcon(children:any){
    let iconFolder = children.isFront? 'lf-icon-folder-user' : 'lf-icon-folder';
    this.evaluation.menuIcons.forEach((icon:any) => {
      let folderBlanqueo : any = this.evaluation.evaluation.productId === 'blanqueo' && children.folderNamedKey && icon.folderNamedKey && icon.folderNamedKey === children.folderNamedKey;
      if(icon['iD_Version0'+this.context.environment] === children['iD_Version0'] || folderBlanqueo){
        iconFolder = icon.icon;
      }
    });
    return iconFolder;
  }

  configureInit(originTree:any){
    let folderId = parseInt(this.route.snapshot.firstChild?.params['folderId']);
    if(folderId){
        let finish:number = 0;
        let continueConfiguration:boolean = true;
        for(let folder of this.evaluation.evaluationTree.children){
            finish++;
            if(folder.folderId === folderId){
                continueConfiguration = false;
                this.configureSelectedFolder();
                break;
            }
        }
        if(finish === this.evaluation.evaluationTree.children.length && continueConfiguration){
            this.configureSelectedFolder();
        }

    }else if(this.route.snapshot?.firstChild?.data['componentName'] ==='reports' || this.route.snapshot?.firstChild?.data['componentName'] === "reportInfo" || this.route.snapshot?.firstChild?.data['componentName'] === "favorites" ){
        this.evaluation.folderSelect = this.evaluation.evaluationTree.children[this.evaluation.evaluationTree.children.length-1];
        this.changeActiveMenu();
    }else if(this.evaluation.evaluation.productTypeId === 4){
        if(originTree && originTree.length){
            this.evaluation.findConcept = null;
            this.findConceptToOpen(this.evaluation.evaluationTree.children);
            this.evaluation.folderSelect = this.evaluation.findFolder;
            this.changeActiveMenu();
            this.router.navigate(['evaluation', this.evaluationId, 'folder', this.evaluation.findFolder.folderId]);

        }else{
            this.loader.closeError();
        }
    }else{
        let folder: any;
        for(let item of this.evaluation.evaluationTree.children){
            if(!item.hidden){
                folder = item;
                break;
            }
        }
        this.evaluation.folderSelect = folder;
        if(this.route.snapshot?.firstChild?.data['componentName'] !=='concept'){
            if(originTree && originTree.length){
              this.loader.openLoader('folder-on-init');
              this.router.navigate(['evaluation', this.evaluationId, 'folder', folder.folderId]);
            }else{
                this.loader.closeError();
            }
        }
    }
    this.evaluation.showHidden = this.hiddenElements.show;
    if( this.route.snapshot?.firstChild?.data['componentName'] !=='concept'){
        this.loader.closeLoader(['evaluation-info-tree']);
    }
  }

  configureSelectedFolder(){
    this.findFolder(this.evaluation.evaluationTree.children);
    this.changeActiveMenu();
  }

  findFolder(tree:any){
    let folderId = parseInt(this.route.snapshot.firstChild?.params['folderId']);
    for(let item of tree){
        if(item.folderId === folderId){
            if(item.hidden && !this.hiddenElements.show){
                this.hiddenElements.changeShow();
                this.broadcastService.broadcast('changeHiddenElements',{}) ;
            }
            this.evaluation.folderSelect = item;
        }else if(item.children && item.children.length){
            this.findFolder(item.children);
        }
        if (this.evaluation.folderSelect !== null) {
            break;
        }
    }
  }

  findConceptToOpen(children:any){
    for(let child of children){
        if(child.concepts.length){
            this.evaluation.findConcept = child.concepts[0];
            this.evaluation.findFolder = child;
        }else if(child.children.length){
            this.findConceptToOpen(child.children);
        }
        if(this.evaluation.findConcept !== null){
            break;
        }
    }
  }

  showModalRoleError(){
    this.loader.closeError();
    let configuration : any = {
      list: this.evaluation.rolesDesassigned,
      clientName: this.evaluation.evaluation.clientName,
      type: 'notAssignedRoles'
    }
    this.modalService.roleErrorModal(configuration)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.stateManagement.setStateUrl(window.location.pathname);
          this.loader.openLoader('open-roles-window');
          sessionStorage.setItem('productId', this.evaluation.evaluation.productId);
          sessionStorage.setItem('productName', this.evaluation.evaluation.productName);
          this.router.navigate(['roles']);
        }else{
          this.router.navigate(['dashboard']);
        }
      })
  }

  getDraftEvaluations(evaluationId:any){
    this.apiService.get('conceptobjects/draftscount', evaluationId)
        .then((data:any)=>{
            this.evaluation.draftEvaluations = !!(data)
        }, (errorData:any)=>{ })
  }

  configureKnowledge(){
    let configuration : any = {
      type: 'knowledge',
      knowledge: this.evaluation.evaluation,
      evaluationId: this.evaluationId
    }
    this.modalService.createNewEvaluation(configuration)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.adviseSendChanges(result, this.translate.instant('evaluation.modify-product'));
        }
      });
  }

  changeVisibility(action:any){
    this.modalService.adviceModal(this.translate.instant('evaluation.change-visibility'), 'info:question:question')
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          switch(action.type){
            case 'hidden':
                this.hideRestoreFolder(action.folder, 1);
                break;
            case 'restore':
                this.loader.openLoader('evaluation-restore-item')
                this.hideRestoreFolder(action.folder, 0);
                break;
            case 'hiddenConcept':
                this.hiddeRestoreConcept(action.folder, 1);
                break;
            case 'restoreConcept':
                this.hiddeRestoreConcept(action.folder, 0);
                break;
          }
        }
      })
  }

  hideRestoreFolder(folder:any, hide:number){
      this.apiService.patch('folders/'+ this.evaluationId+'/showhide', folder.folderId+'/'+hide, {})
        .then((data:any)=>{
            this.changeHiddenFolder(folder, this.evaluation.evaluationTree.children, hide);

            this.configureIcons();
            let folderId = parseInt(this.route.snapshot.firstChild?.params['folderId']);
            if(hide === 1 && folder.folderId === folderId && !this.hiddenElements.show){
                if(folder.parentId){
                    this.evaluation.folderSelect = this.selectParent(folder);
                }else{
                    let indexOrder = folder.indexOrder;
                    if(indexOrder >= this.evaluation.evaluationTree.children.length-1){
                        indexOrder = 0;
                    }
                    this.evaluation.folderSelect = this.nextParentFolder(indexOrder);
                }
                if(this.evaluation.folderSelect.folderId==='reports' || this.evaluation.folderSelect.folderId==='favorites'){
                    this.router.navigate(['evaluation', this.evaluationId, this.evaluation.folderSelect.folderId]);
                }else{
                    this.loader.openLoader('folder-on-init');
                    this.router.navigate(['evaluation', this.evaluationId, 'folder', this.evaluation.folderSelect.folderId]);
                }
            }else{
                folder.openOption = false;
            }
            this.broadcastService.broadcast('changeVisibilityMenu', {folder: folder});
            this.loader.closeLoader(['evaluation-restore-item']);
            this.changePropertyList(this.evaluation.evaluationTree.children, folder, 'hidden', hide);
            this.broadcastService.broadcast('changePropertyFolder',{ folder: folder, property: 'hidden', newValue: hide});
        }, (errorModal:any)=>{

        })
  }

  changePropertyList(list: any, folderSelected: any, type: any, newValue: any){
      for(let [index, folder] of list.entries()){
          if(folder.folderId === folderSelected.folderId){
              list[index][type] = newValue;
              list[index].confidentialList = type==='confidential'?folderSelected.confidentialList:folder.confidentialList;
              break;
          }
          if(folder.children && folder.children.length){
              this.changePropertyList(folder.children, folderSelected, type, newValue);
          }
      }
  }

  changeHiddenFolder(folder:any, list:any, hide:number){
    for(let [index, item] of list.entries()){
        if(item.folderId === folder.folderId){
            list[index].hidden = !!(hide);
            folder.hidden = !!(hide);
            break;
        }
        if(item.children){
            this.changeHiddenFolder(folder, item.children, hide);
        }
    }
  }

  selectParent(folder:any){
    for(let newFolder of this.evaluation.listAllFolder){
        if(folder.parentId === newFolder.folderId){
            return newFolder;
        }
    }
  }

  nextParentFolder(indexOrder:number){
    for(let i = indexOrder; i < this.evaluation.evaluationTree.children.length; i++){
      let newFolder : any = this.evaluation.evaluationTree.children[i];
      if(!newFolder.hidden){
          return newFolder;
      }
      if(i === this.evaluation.evaluationTree.children.length-1){
          this.nextParentFolder(0);
      }
    }
  }

  hiddeRestoreConcept(concept:any, hide:number){
    this.apiService.patch('concepts/'+ this.evaluationId+'/showhide', concept.conceptId+'/'+ hide, {})
        .then((data:any)=>{
            concept.hidden = !concept.hidden;
            concept.open = !(concept.hidden)
            this.loader.closeLoader(['evaluation-menu-menu-action'])
        }, (errorModal:any)=>{
            this.loader.closeError();
        })
  }

  openDuplicate(duplicatedItem:any){
    let list = duplicatedItem.conceptId? this.evaluation.folderSelect.concepts : this.evaluation.folderSelect.children;
    this.modalService.duplicateModal(this.evaluation.evaluationTree.children, duplicatedItem, this.evaluation.evaluation, list)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('evaluation-duplicate-item');
          this.duplicateItem(duplicatedItem, result);
        }else{
          this.loader.closeError();
        }
      })
  }

  duplicateItem(duplicatedItem:any, result:any){
    let selectFolder = result.selectFolder;
    let copydata: number = Number(result.copydata);
    let copyreference: number = Number(result.copyreference);
    let selectedFolderId:any;
    let url: string = 'concepts/'+this.evaluationId+'/'+duplicatedItem.conceptId+'/duplicate/'+selectFolder.folderId+'/'+copydata+'/'+copyreference;

    let object:any = {
        title: result.name
    };

    if(duplicatedItem.folderId){
        selectedFolderId = selectFolder.folderId;
        if(selectFolder.folderId === null || selectFolder.folderId === undefined){
            selectedFolderId = 0;
        }
        url='folders/'+this.evaluationId+'/'+duplicatedItem.folderId+'/duplicate/'+selectedFolderId+'/'+copydata+'/'+copyreference;
        delete object.title
        object = {
            name: result.name
        };
    }

    this.apiService.add(url, object)
        .then((data:any)=>{
            if(selectedFolderId === 0){
                data[0].icon = "lf-icon-folder-user";
                this.evaluation.evaluationTree.children.splice(this.evaluation.evaluationTree.children.length-1, 0, data[0]);
            }else if(duplicatedItem.folderId){
                data[0].level = selectFolder.level+1;
                selectFolder.children.push(data[0])
                this.broadcastService.broadcast('duplicateItem',{
                    type:'folder',
                    item: selectFolder
                })
            }else{
                data.list = [];
                data.archived = [];
                data.toShow = duplicatedItem.toShow;
                selectFolder.concepts.push(data);
                this.broadcastService.broadcast('duplicateItem',{
                    type:'concept',
                    item: data
                })
            }

            this.modalService.adviceModal(this.translate.instant('evaluation.duplicated') +"'"+ result.name +"'"+ this.translate.instant('evaluation.correctly') +"'"+ selectFolder.name+"'", 'info:accept:check-round');
            this.loader.closeLoader(['evaluation-duplicate-item']);
        }, (errorData:any)=>{
            let text = this.translate.instant('evaluation.error-duplicate') + ' "' + result.name + '"' + this.translate.instant('evaluation.in') + selectFolder.name
            this.loader.closeError();
            this.modalService.adviceModal(text, 'error:accept:warning');

        })
  }

  openModalConfidential(folder: any) {
      if (!folder.confidentialList) {
          folder.confidentialList = [];
      }

      this.modalService.manageConfidentialModal(folder).subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('evaluation-confidential-document')
          this.confidentFolder(folder, result);
        }
      })

  }

  confidentFolder(folder: any, resultModal: any) {
      let list : any = resultModal.share;
      let confidential : any = resultModal.confidential;
      let confidentialOfUserConnected : any = resultModal.confidentialOfUserConnected;
      let url : any = 'setconfidential/' + folder.folderId + '/' + confidential;
      this.apiService.patch('folders', url, list)
      .then((data:any)=>{
          let text : string = this.translate.instant('manage-confidential-modal.confident-uncheck');
          this.changePropertyList(this.evaluation.evaluationTree.children, folder, 'confidential', confidential);
          this.broadcastService.broadcast('changePropertyFolder',{ folder: folder, property: 'confidential', newValue: confidential});
          if (confidential) {
              text = this.translate.instant('manage-confidential-modal.confident-ok');
          } else {
              folder.confidentialList = [];
          }
          this.loader.closeLoader(['evaluation-confidential-document']);
          this.modalService.adviceModal(text, 'info:accept:check-round')
            .subscribe((result:any) => {
              if(result.result === 'ok'){
                if(confidential && !confidentialOfUserConnected){
                    let folderId : any = folder.parentId;
                    this.changeTree = false;
                    this.changeInTree(this.evaluation.evaluationTree.children, folder, 'delete', this.evaluation.evaluationTree);
                    if(!folderId){
                        if(this.evaluation.evaluationTree.children && this.evaluation.evaluationTree.children.length){
                            folderId = this.evaluation.evaluationTree.children[0].folderId;
                        }else{
                            this.router.navigate(['dashboard']);
                        }
                    }else{
                      this.broadcastService.broadcast('changeTreeFolder',{ folder: folder, action: 'delete'});
                    }
                    if(folderId === 'reports'){
                        this.router.navigate(['evaluation', this.evaluationId, 'reports']);
                    }else if(folderId==='favorites'){
                        this.router.navigate(['evaluation', this.evaluationId, 'favorites']);
                    }else{
                        this.router.navigate(['evaluation', this.evaluationId, 'folder', folderId]);
                    }
                }
              }
            })
      }, (errorData:any)=>{
          this.loader.closeError();
          this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning');
      })
  }

  searchItem(children:any, item:any, action:string, type:any){
    for(let [index, childrenSelected] of children.entries()){
        if(item.conceptId && childrenSelected.folderId === item.folderId){
            if(action === 'deleteElement'){
              children[index].concepts = this.commonUtilsService.deleteElement(item, childrenSelected.concepts, type);
            }else if(action === 'change'){
              children[index].concepts = this.commonUtilsService.replaceElement(item, childrenSelected.concepts, type, item);
            }
            break;
        }else if(childrenSelected.children.length){
            this.searchItem(childrenSelected.children, item, action, type)
        }
    }
  }

  calcWidth(){
    if(this.route.snapshot?.firstChild?.data['componentName'] === 'reportInfo'){
      let width : any = $('.evaluation__evaluation-menu').innerWidth()
        return (window.innerWidth - width) + 'px';
    }
    return '';
  }

  getBreadcrumbList(e:any){
    this.evaluation.breadcrumbList = e.list;
    sessionStorage.setItem('breadcrumbConcept',  this.convertBreadcrumb())

  }
  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
