<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-process attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'box-query-state.query-state'">_Estado actual de la consulta</p>
    </div>
    <div>
        <div class="box-query-state__progress-bar-custom">
            <div class="box-query-state__progress-selected-bar-custom"></div>
        </div>
        <div class="box-query-state__block-status">
            <i class="lf-icon-radio-button-active box-query-state__icon-status-progress"></i>
            <span>{{boxQueryState.actualState.name}}</span>
        </div>
    </div>
</div>