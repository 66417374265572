<div class="export-modal">
    <div class="common__modal-header-block">
        <i class="lf-icon-send common__icon-header"></i>
        <span class="common__text-header">{{exportModal.title}}</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss('close')"></i>
    </div>
    <div class="export-modal__body-container">
        <field-type-text-simple [field]="exportModal.filenameInput"
                         (itemSelected)="setFilename($event)">
        </field-type-text-simple>
        <span class="export-modal__error-message">{{exportModal.errorMessage}}</span>
        <div class="export-modal__switch-container">
            <field-type-switch [label]="'export-modal.open-file'| afTranslate"
                [checked]="exportModal.openFile"
                (callback)="exportModal.openFile = $event.value">
            </field-type-switch>
            <field-type-switch class="export-modal__switch"
                [label]="'export-modal.download-file'| afTranslate"
                [checked]="exportModal.downloadFile"
                (callback)="exportModal.downloadFile = $event.value">
            </field-type-switch>
        </div>
    </div>
    <div class="common__buttons-block common__buttons-block--modal">
        <button class="common__button common__button--clean"
                (click)="handleDismiss()"
                [afTranslate]="'common.cancel'"></button>
        <button class="common__button common__button--clean"
                [ngShow]="configuration.hasPrevious"
                (click)="handleDismiss('previous')"
                [afTranslate]="'common.previous'"></button>
        <button class="common__button common__button--active"
                (click)="validateForm()"
                [afTranslate]="'common.export'"></button>
    </div>
</div>
