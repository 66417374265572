import { Component, OnInit } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsListParentService } from '../utils/clients-list-parent.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'clients-list',
  templateUrl: './clients-list.component.html'
})

export class ClientsListComponent implements OnInit {
  skin:string =  this.globalCfg.skin;
  permissions:any = this.route.snapshot.data['companyPermissions'];
  clientList:any = this.route.snapshot.data['organizations'];
  clientsList:any = {
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50
    },
    currentPage: 1,
    searchClient: '',
    order: false,
    search: false,
    filter: []
  };

  constructor(private route: ActivatedRoute,
    private router: Router,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private context: ContextFactory,
    private clientsListParent: ClientsListParentService
  ) {}

  ngOnInit(): void {
    this.clientsListParent.OnInit(this);
  }

  editClient(client:any){
    this.clientsListParent.ShowModalClient(client, this);
  }

  changeView(){
    this.loader.openLoader('front-login-login');
    this.router.navigate([this.context.view.dashboard]);
  }

  newClient(){
    this.clientsListParent.NewClient(this);
  }

  openMigrationModal(){
    this.clientsListParent.OpenMigrationModal(this);
  }

  deleteClient(client:any){
    this.clientsListParent.DeleteClientModal(client, this);
  }

  toggleSearch() {
    this.clientsListParent.ToggleSearch(this);
  }
}
