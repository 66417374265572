import * as d3 from 'd3'

import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'stacked-bar-plot',
  templateUrl: './stacked-bar-plot.component.html'
})
export class StackedBarPlotComponent implements OnChanges {

  stackedBarPlot:any={}

  constructor( private container: ElementRef,
    private translate: AfTranslateFactory) { 
    }
  @Input() graphData: any = null;

  ngOnChanges(): void {
    let that: any = this.stackedBarPlot;
    that.graphData = this.graphData;
   
    this.drawBarGraph();
    
}

drawBarGraph(){
    let that: any = this.stackedBarPlot;
    that.width = 369;
    that.height =380;
    this.drawSvg()
    this.drawXaxxis();
    this.drawYaxxis();
    this.drawBars();
}

drawSvg(){
  this.stackedBarPlot.graphData.svg = d3.select(this.container.nativeElement.firstChild)
    .append('svg')
    .attr("viewBox", `0 0 ${this.stackedBarPlot.width} ${this.stackedBarPlot.height}`)
    .append('g')
}

drawXaxxis(){
    this.stackedBarPlot.graphData.x = d3.scaleBand()
        .domain(['0', '4'])
        .range([0, this.stackedBarPlot.width])
        .padding(0.15);
    this.stackedBarPlot.graphData.x.domain(this.stackedBarPlot.graphData.original.map((d:any) => {
        return d.label;
    }));

    this.stackedBarPlot.graphData.svg.append("g")
        .attr("transform", "translate(0,360)")
        .attr('class', 'bar-graph__x-axxis')
        .call(d3.axisBottom(this.stackedBarPlot.graphData.x).tickSize(0))
        .selectAll("text")
        .style("text-anchor",() => {
             "end";
        })
        .attr("transform",() => {
            return  "";
        });
}

drawYaxxis(){
    this.stackedBarPlot.graphData.y = d3.scaleLinear()
        .range([100, 0]);
    this.stackedBarPlot.graphData.y.domain([0, 280]);
}

drawBars(){
    let max:number = 0;
    this.stackedBarPlot.graphData.original.forEach((element:any) => {
      if(element.count > max){
        max = element.count;
      }
    });

    this.stackedBarPlot.graphData.bar = this.stackedBarPlot.graphData.svg.selectAll()
        .data(this.stackedBarPlot.graphData.original)
        .enter()
        .append("g")
        .attr("class", "stacked-bar__stacked-bar-group");

    this.stackedBarPlot.graphData.bar.append('foreignObject')
      .attr("x", (d:any) => { 
        return this.stackedBarPlot.graphData.x(d.label); 
      })
      .attr("width", this.stackedBarPlot.graphData.x.bandwidth())
      .attr("y", '10')
      .attr("height", "300")
      .append('xhtml:div')
      .attr('class', "stacked-bar__stacked-bar-container")
      
      .html((d:any)=>{
        return `<p class="stacked-bar__bar-value">${d.count}</p>
        <div id="${d.label.split(" ").join("").toLowerCase()}"></div>`;
      })
      for(let i = 0; i < this.stackedBarPlot.graphData.original.length; i++){
        let d:any = this.stackedBarPlot.graphData.original[i];
        for(let j:number = 0; j < d.complaints.length; j++){
          let complaints:any = d.complaints[j]
          complaints.label = d.label.split(" ").join("").toLowerCase()
          d3.select(`#${d.label.split(" ").join("").toLowerCase()}`)
          .append('div')
          .attr('class', 'stacked-bar__inner-container')
          .on('mouseover', (d:any) => this.showOrHideTooltip('show', complaints))
          .on('mouseout', (d:any) => this.showOrHideTooltip('hide', complaints))
          .html((d:any)=>{
            return  `<div 
            style="height:${this.calculateHeight(complaints, max)}px; 
            width:${this.stackedBarPlot.graphData.x.bandwidth()}px;
            background:${complaints.color}">
            <p class="stacked-bar__stacked-bar-text"
              style="height:${this.calculateHeight(complaints, max)}px">
            ${complaints.complaintsPerc}%
            </p>
          </div>
          <div class="stacked-bar__stacked-tooltip-box"
            id="stacked-${complaints.label}-${complaints.state}"
            style="top:${this.calculateHeight(complaints, max) / 2 - 33}px; 
              width:${this.stackedBarPlot.graphData.x.bandwidth()}px">
            <div class="stacked-bar__stacked-tooltip-container">
              <p class="stacked-bar__stacked-tooltip-text">
              ${complaints.state}
              </p>
            </div>
          </div>`
          })

          
          }
      }
       
    this.stackedBarPlot.graphData.bar.append('rect')
      .attr("x",  (d:any)=> { return this.stackedBarPlot.graphData.x(d.label); })
      .attr("width", this.stackedBarPlot.graphData.x.bandwidth())
      .attr("y", "340")
      .attr("height", "4")
      .attr('fill', "#001978");

}

showOrHideTooltip(type:string, complaints: any){
  let element:any = $(`#stacked-${complaints.label}-${complaints.state}`);
  type === 'show'? element.addClass('stacked-bar__stacked-tooltip-box--show'): element.removeClass('stacked-bar__stacked-tooltip-box--show');
}

calculateHeight(complaints:any, max:any){
  let height: number = complaints.complaints * 280 / max;
  if(height > 248){
    return 248;
  }
  return height < 36? 36 : height;
}


  
}
