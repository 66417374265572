<div class="report-table__header-icam">
    <div class="report-table__header-left-icam"></div>
    <div class="report-table__header-center-icam"></div>
    <div class="report-table__header-right-icam">
        <div class="report-table__header-button-icam"
            (click)="refreshTable()">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.refresh'"></span>
            <i class="lf-icon-refresh report-table__button-icon-icam"></i>
        </div>
        <div class="report-table__header-button-icam"
            (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam"
                [ngClass]="{'report-table__tcell-icam--last': last}"
                *ngFor="let column of reportTable.report.columns; let first = first; let last = last"
                [ngShow]="!first">
                    {{column.name}}
                </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--actions report-table__tcell-icam--actions-header report-table__tcell-icam--last"
                [afTranslate]="'common.actions'"></div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam report-table__trow-icam--hover"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let row of reportTable.reportsListFilter; let last = last">
            <div class="report-table__tcell-icam"
                [ngClass]="{'report-table__tcell-icam--last': last}"
                *ngFor="let column of reportTable.report.columns; let first = first; let last = last"
                [ngShow]="!first">
                {{row[column.id]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--actions report-table__tcell-icam--last">
                <i class="lf-icon-kebab-menu"></i>
                <i class="lf-icon-open-window report-table__tcell-icam-icon-open"
                  (click)="relatedConcept(row, 'concept')"></i>
            </div>
        </div>
    </div>
</div>
