<div class="schedule-next-event">
  <!-- <div class="schedule-next-event__block-panel"> -->
    <div class="schedule-next-event__block-header-panel">
        <i class="lf-icon-label-calendar schedule-next-event__icon-header"></i>
        <h6 class="schedule-next-event__title-header-panel"
          [afTranslate]="'anti-money-laundering-panel.diary-next-event'">_Agenda: próximo evento</h6>
        <button class="lf-icon-open-window schedule-next-event__button-open-window"
          (click)="goToSchedule()"></button>
    </div>
    <div class="schedule-next-event__block-body-panel">
        <div class="schedule-next-event__block-clients-repeat">
            <div [ngShow]="scheduleNextEvent.lastEvent">
                <p class="schedule-next-event__title-info-schedule">
                    <span>{{scheduleNextEvent.lastEvent?.title}}</span>
                    <i [class]="scheduleNextEvent.lastEvent?.icon + ' schedule-next-event__icon-info-schedule'"></i>
                </p>
                <p class="schedule-next-event__text-info-schedule">
                    <span class="clients-tracking-dashboard__text-ubication-info-schedule">{{scheduleNextEvent.lastEvent?.typeTitle}}:</span>
                    <span>{{scheduleNextEvent.lastEvent?.detailMeeting}}</span>
                </p>
            </div>
            <div [ngShow]="!scheduleNextEvent.lastEvent">
                <p class="schedule-next-event__text-info-schedule"
                  [afTranslate]="'anti-money-laundering-panel.no-events'">_No hay eventos</p>
            </div>
        </div>
    </div>
  <!-- </div> -->
</div>

