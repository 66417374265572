<div class="process-ops-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-process-settings common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'process.ops-modal.title'">Operaciones añadidas para gestionar el proceso</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="process-ops-modal__body">
      <div class="process-ops-modal__body-inner"
          [ngClass]="{'process-ops-modal__body-inner--automatic-process': processOpsModal.concept.allowPublicShare}">
          <!--Proceso-->
          <div class="process-ops-modal__wrapper">
              <div class="process-ops-modal__inside">
                  <div class="process-ops-modal__title-block">
                      <i class="lf-icon-process process-ops-modal__header-icon"></i>
                      <span class="process-ops-modal__header-title"
                          [afTranslate]="'process.ops-modal.process-title'">_Proceso del concepto</span>
                  </div>
                  <div class="field-type-txchk__option-box field-type-txchk__option-box--required field-type-txchk__option-box--process-ops"
                      [ngShow]="processOpsModal.concept.isFront && !processOpsModal.concept.processId">
                      <span class="field-type-txchk__asterisk">*</span>
                      <span [afTranslate]="'customize.required'"></span>
                  </div>
              </div>
              <button [class]="' '+processOpsModal.processButtonIcon+' common__header-button-add-new-version common__header-button-add-new-version--process'"
                    [ngClass]="{'process-ops-modal__button-icon-visible': processOpsModal.processButtonIcon === 'lf-icon-visible'}"
                    [ngShow]="processOpsModal.configuredButtonIcon"
                  (click)="processButtonClick()">
              </button>
              <div class="process-ops-modal__process-box-title">{{processOpsModal.processTitle}}</div>
          </div>
          <!--Tarea-->
          <div class="process-ops-modal__wrapper"
              [ngClass]="{'process-ops-modal__wrapper--task': processOpsModal.task}">
              <div class="process-ops-modal__inside">
                  <div class="process-ops-modal__title-block">
                      <i class="lf-icon-task process-ops-modal__header-icon"></i>
                      <span class="process-ops-modal__header-title"
                          [afTranslate]="'process.ops-modal.task-table-title'">Tarea de proceso</span>
                  </div>
                  <div class="field-type-txchk__option-box field-type-txchk__option-box--required field-type-txchk__option-box--process-ops"
                      [ngShow]="!processOpsModal.task">
                      <span class="field-type-txchk__asterisk">*</span>
                      <span [afTranslate]="'customize.required'"></span>
                  </div>
              </div>
              <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                  [disabled]="!processOpsModal.processId || processOpsModal.task"
                  (click)="addTask()">
              </button>
              <div class="process-ops-modal__table"
                  *ngIf="processOpsModal.task">
                  <div class="common__table-header process-ops-modal__table-header">
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--title">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.title-column'">Titulo</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--creator">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.creator-column'">Creador</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--destination">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.recipient-column'">Destinatario</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--date-creation">
                          <span-tooltip class="common__table-header-text"
                            [text]="'process.ops-modal.creation-date-column' | afTranslate"></span-tooltip>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--actions"></div>
                  </div>
                  <div class="process-ops-modal__table-body-task">
                      <div class="common__table-row process-ops-modal__table-row">
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--title process-ops-modal__table-col--tooltip">
                                <parragraph-tooltip [text]="processOpsModal.task.title"></parragraph-tooltip>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--creator process-ops-modal__table-col--tooltip">
                                <parragraph-tooltip [text]="(processOpsModal.task.creator?.fullName + (processOpsModal.task.creator?.isDeleted ? ' ('+ ('common.inactive-user' | afTranslate)+')' : ''))"></parragraph-tooltip>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--destination process-ops-modal__table-col--tooltip">
                              <participants-list *ngIf="processOpsModal.task.responsiblesList?.length"
                                  [participants]="processOpsModal.task.responsiblesList"
                                  [notificated]="false"
                                  [type]="'task-process'">
                              </participants-list>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--date-creation process-ops-modal__table-col--tooltip">
                                <parragraph-tooltip [text]="processOpsModal.task.creationDate | convertDate:'without-time'"></parragraph-tooltip>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--actions">
                              <ul class="common__options-row">
                                  <li class="common__menu-options-row"
                                      (click)="editTask()"
                                      matTooltip="{{'common.edit' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below">
                                      <i class="lf-icon-editable-form" ></i>
                                  </li>
                                  <li class="common__menu-options-row"
                                      (click)="deleteTask()"
                                      matTooltip="{{'common.remove' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below">
                                      <i class="lf-icon-trash"></i>
                                  </li>
                              </ul>
                              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--Alertas-->
          <div class="process-ops-modal__wrapper"
              [ngClass]="{'process-ops-modal__wrapper--three-elements': processOpsModal.alerts.length}">
              <div class="process-ops-modal__inside">
                  <div class="process-ops-modal__title-block">
                      <i class="lf-icon-notification process-ops-modal__header-icon"></i>
                      <span class="process-ops-modal__header-title"
                          [afTranslate]="'process.ops-modal.alerts-table-title'">Alertas generales de proceso</span>
                  </div>
              </div>
              <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                  [disabled]="!processOpsModal.processId"
                  (click)="addAlert()">
              </button>
              <div class="process-ops-modal__table"
                  *ngIf="processOpsModal.alerts && processOpsModal.alerts.length">
                  <div class="common__table-header process-ops-modal__table-header">
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--title-alert">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.title-column'">Titulo</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--step">
                          <p class="common__table-header-text"
                          [afTranslate]="'process.step'">Paso</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--creator-alert">
                          <p class="common__table-header-text"
                          [afTranslate]="'process.ops-modal.creator-column'">Creador</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--destination-alert">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.recipient-column'">Destinatario</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--date-alert process-ops-modal__table-col--tooltip">
                          <span-tooltip class="common__table-header-text"
                            [text]="'process.ops-modal.alert-date-column' | afTranslate"></span-tooltip>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--actions"></div>
                  </div>
                  <div class="process-ops-modal__table-body"
                      [ngClass]="{'process-ops-modal__table-body--more-elements': processOpsModal.alerts.length > 3}">
                      <div class="common__table-row process-ops-modal__table-row"
                          *ngFor="let alert of processOpsModal.alerts">
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--title-alert process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                  <parragraph-tooltip [text]="alert.title"></parragraph-tooltip>
                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--step process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                  <parragraph-tooltip [text]="alert.stateName"></parragraph-tooltip>
                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--creator-alert process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                <parragraph-tooltip [text]="(alert.creator.fullName + (alert.creator.isDeleted ? ' (' + ('common.inactive-user' | afTranslate) + ')' : ''))"></parragraph-tooltip>
                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--destination-alert process-ops-modal__table-col--tooltip">
                              <participants-list *ngIf="alert.destination.length"
                                  [participants]="alert.destination"
                                  [notificated]="alert.notifyToIdentifying"
                                  [type]="'alert'">
                              </participants-list>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--date-alert process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                <parragraph-tooltip [text]="alert.date"></parragraph-tooltip>
                              </div>
                          </div>

                          <div class="process-ops-modal__table-col process-ops-modal__table-col--actions">
                              <div [ngShow]="processOpsModal.userPermissions === 'all'">
                                  <ul class="common__options-row">
                                      <li class="common__menu-options-row"
                                          (click)="editAlert(alert)"
                                          matTooltip="{{'common.edit' | afTranslate}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below">
                                          <i class="lf-icon-editable-form" ></i></li>
                                      <li class="common__menu-options-row"
                                          (click)="deleteAlert(alert);"
                                          matTooltip="{{'common.remove' | afTranslate}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below">
                                          <i class="lf-icon-trash"></i>
                                      </li>
                                  </ul>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Publicaciones automaticas del proceso -->
          <div class="process-ops-modal__wrapper"
              [ngClass]="{'process-ops-modal__wrapper--three-elements': processOpsModal.automaticProcess.length}"
              *ngIf="skin === 'icam-red' && processOpsModal.concept.allowPublicShare">
              <div class="process-ops-modal__inside">
                  <div class="process-ops-modal__title-block">
                      <i class="lf-icon-transparency-report process-ops-modal__header-icon"></i>
                      <span class="process-ops-modal__header-title"
                          [afTranslate]="'process.ops-modal.automatic-post-process'">Publicaciones automáticas en función del proceso</span>
                  </div>
              </div>
              <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                  [disabled]="!processOpsModal.processId || processOpsModal.userPermissions !== 'all'"
                  (click)="addOrEditAutomaticProcess()">
              </button>
              <div class="process-ops-modal__table"
                  [ngShow]="processOpsModal.automaticProcess && processOpsModal.automaticProcess.length">
                  <div class="common__table-header process-ops-modal__table-header">
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--step-process">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.step'">Paso</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--creator-process">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.creator-column'">Creador</p>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--date-process process-ops-modal__table-col--tooltip">
                          <span-tooltip class="common__table-header-text"
                            [text]="'process.ops-modal.publication-date' | afTranslate"></span-tooltip>
                      </div>
                      <div class="process-ops-modal__table-col process-ops-modal__table-col--actions"></div>
                  </div>
                  <div class="process-ops-modal__table-body"
                      [ngClass]="{'process-ops-modal__table-body--more-elements': processOpsModal.automaticProcess.length > 3}">
                      <div class="common__table-row process-ops-modal__table-row"
                          *ngFor="let process of processOpsModal.automaticProcess">
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--step-process process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                <parragraph-tooltip [text]="process.stateName"></parragraph-tooltip>
                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--creator-process process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                <parragraph-tooltip [text]="(process.creator.fullName + (process.creator.isDeleted ? ' (' + ('common.inactive-user' | afTranslate) + ')' : ''))"></parragraph-tooltip>

                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--date-process process-ops-modal__table-col--tooltip">
                              <div class="common__cell-block">
                                <parragraph-tooltip [text]="process.date"></parragraph-tooltip>
                              </div>
                          </div>
                          <div class="process-ops-modal__table-col process-ops-modal__table-col--actions">
                              <ul class="common__options-row">
                                  <li class="common__menu-options-row"
                                      (click)="addOrEditAutomaticProcess(process)"
                                      matTooltip="{{'common.edit' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below">
                                      <i class="lf-icon-editable-form" ></i></li>
                                  <li class="common__menu-options-row"
                                      (click)="deleteAutomaticProcess(process);"
                                      matTooltip="{{'common.remove' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below">
                                      <i class="lf-icon-trash"></i>
                                  </li>
                              </ul>
                              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--Edición bloqueada en función del proceso-->
          <div class="process-ops-modal__wrapper"
              *ngIf="processOpsModal.concept.allowPublicShare && !(!processOpsModal.concept.isFront && processOpsModal.concept.lockedEdit)">
              <div class="process-ops-modal__inside">
                  <div class="process-ops-modal__title-block">
                      <i class="lf-icon-not-editable process-ops-modal__header-icon"></i>
                      <span class="process-ops-modal__header-title"
                          [afTranslate]="'process.ops-modal.blocked-edition'">Edición bloqueada en función del proceso</span>
                  </div>
              </div>
              <button class="lf-icon-configuration common__header-button-add-new-version common__header-button-add-new-version--process"
                  [disabled]="!processOpsModal.processId"
                  (click)="configureBlockedEdition()">
              </button>
              <div class="process-ops-modal__table"
                  [ngShow]="processOpsModal.processBlockedList?.length">
                  <div class="common__table-header process-ops-modal__table-header">
                      <div class="process-ops-modal__table-col">
                          <p class="common__table-header-text"
                              [afTranslate]="'process.ops-modal.blocked-edition-header-info'">No se permitirá modificar los datos del solicitante en los siguientes estados:</p>
                      </div>
                  </div>
                  <div class="process-ops-modal__table-body-blocked-states">
                      <div class="process-ops-modal__blocked-list"
                          *ngFor="let processBlocked of processOpsModal.processBlockedList">
                          <i class="lf-icon-check-full process-ops-modal__icon-blocked-list"></i>
                          <p class="process-ops-modal__text-blocked-list">{{processBlocked.stateName}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="common__buttons-block common__buttons-block--modal float-end">
          <button type="submit"
              class="common__button common__button--active"
              (click)="handleAccept()"
              [afTranslate]="'common.finish'">__Finalizar</button>
      </div>
  </div>
</div>
