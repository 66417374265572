
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { TaskFormParentService } from '../utils/task-form-parent.service';

@Component({
  selector: 'task-form-custom',
  templateUrl: './task-form-custom.component.html'
})
export class TaskFormCustomComponent implements OnChanges, OnDestroy{
  currentComponent = this.route.snapshot.data['componentName'];
  isLanding: boolean = true;
  custom:any = this.customLanding.getCustom();
  originalSkin:any = this.globalCfg.skin;
  skin:string = 'landing-custom';
  userPermissions:any = this.permissions.getPermissions();
  canCreate:boolean = this.userPermissions !== 'none';
  taskForm:any = this.taskFormParent.configureTaskForm(this);
  taskInit: boolean = false;

  constructor(private route: ActivatedRoute,
    private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private taskFormParent: TaskFormParentService) {}

  @Input() concept: any = null;
  @Input() task: any = null;
  @Input() isNew: any = null;
  @Input() shared: any = null;
  @Input() hasOpenEvaluationTask: any = null;
  @Input() field: any = null;
  @Input() disabledTaskEvaluation: any = null;

  @Output() cancelTask = new EventEmitter();
  @Output() createAlert = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnChanges(): void {
    let that: any = this.taskForm
    that.task = this.task;
    that.field = this.field;
    that.shared = this.shared;
    that.concept = this.concept;
    if(that.task && !this.taskInit){
      if(!this.context.user.userId){
        this.context.user.userId = this.context.user.contactId;
      }
      this.taskFormParent.configureTaskInit(this);
      this.taskFormParent.getPatterns(this);
      this.taskFormParent.initWatchers(this);
      this.taskInit = true;
    }
    this.taskForm.saveTask = null;
  }

  cancelCreateTask(){
    this.cancelTask.emit();
  }

  receiveContacts(e:any) {
    this.taskForm.task.participants = e.contacts;
  }

  itemSelected(e:any, type:any ) {
    this.taskFormParent.itemSelected(e.newValue, type, this);
  }

  addOrEditTask(){
    this.taskFormParent.addOrEditTask(this)
  }

  changeValueOption(e:any){
    this.taskFormParent.changeValueOption(e.option, this)
  }

  createTaskAlert(e:any){
    e.alert.nameField = this.taskForm.taskExpirationDate.label;
    this.createAlert.emit({alert:e.alert});
  }

  setPattern(newValue:boolean){
    this.taskFormParent.setPattern(this, newValue);
  }

  modifyPattern(){
    this.taskFormParent.modifyPattern(this)
  }

  cancelMeeting(){
    this.taskFormParent.cancelMeeting(this)
  }

  copyText(type:any){
    this.taskFormParent.copyText(type, this)
  }

  openUrl(url:string){
    window.open(url, '_blank');
  }

  sendNotify(){
    this.taskFormParent.sendNotify(this)
  }

  showTooltip(id:any){
    return this.commonUtilsService.showTooltip(id);
  }

  getEditorValue(event:any){
    this.taskForm.task.description = event.model;
  }

  ngOnDestroy(){
    this.taskFormParent.ngOnDestroy();
  }
}
