import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'input-time',
  templateUrl: './input-time.component.html'
})
export class InputTimeComponent implements OnChanges {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService
  ) { }

  @Input() input:any;
  @Input() disabled:any;

  @Output() output = new EventEmitter();

  skin:string = this.globalCfg.skin;
  inputTime:any = {
    inputId: this.commonUtilsService.createId(),
    dotSeparation: true
  };

  ngOnChanges(){
    let that = this.inputTime;
    if (this.input.indexOf(':') !== -1) {
      let time = this.input.split(':')
      that.hours = time[0];
      that.minutes = time[1];
      that.error = false;
    } else {
      that.hours = '00';
      that.minutes = '00';
    }
  }

  validateDate(type:string, e:any){
    e.stopPropagation();
    let that = this.inputTime;
    if(that[type].length === 2){
      if(type === 'hours' && parseInt(that.hours) > 23){
          that.hours = '23';
      }else if(type === 'minutes' && parseInt(that.minutes) > 59){
          that.minutes = '59';
      }else if(that.hours.length === 2 && that.minutes.length === 2){
        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
        if(that.error){
            this.output.emit({newValue: null});
        }else{
            let newDate = that.hours + ':' + that.minutes;
            this.output.emit({newValue: newDate});
        }
      }else{
          this.output.emit({newValue: null});
      }
      if(type === 'hours'){
        setTimeout(() => {
            $('#' + that.inputId + '-minutes').trigger('select').trigger('focus');
        });
      }
    }
  }

  select(type:string) {
    setTimeout(() => {
        $('#' + this.inputTime.inputId + '-' + type).trigger('select').trigger('focus');
    });
  }
}
