import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-number',
  templateUrl: './field-type-number.component.html'
})
export class FieldTypeNumberComponent implements OnChanges {
  fieldTypeNumber:any = {};
  isLanding: any = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  references:boolean = false;
  icon:string;
  titleField:string;
  dotSeparation:boolean = false;
  inputNumber:string = '';
  type:string;

  constructor(private globalCfg:GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService,
    private customLanding: CustomLandingFactory) { }

  @Input() field:any;
  @Input() preSelectItem:any;
  @Input() concept:any;
  @Input() decimals:any;
  @Output() itemSelected = new EventEmitter;
  @Output() showFieldReference = new EventEmitter;
  @Output() openRelatedInfo = new EventEmitter;

  ngOnChanges(changes: SimpleChanges): void {
    let field = this.field;
    if(field){
      this.titleField = field.label;
      if(field.extendedLabel && field.extendedLabel !== ''){
          this.titleField = field.extendedLabel;
      }
      if(!field.references){
          field.references = [];
      }
      if(field.id === 'during-days-number' || field.id ==='every-days-number' || field.id === 'days-number'){
        this.icon = "lf-icon-hashtag";
      }

      this.dotSeparation = (field.id === 'port-server-imap' || field.id === 'port-server-imap-customization');

      if(!field.id || field.generateId){
          field.id = this.commonUtilsService.createId();
      }else if(field.fieldId){
          field.id = field.fieldId;
      }
      if(field.fieldTypeId === 'percent'){
          this.icon = "lf-icon-percent";
          this.decimals = 2 ;
      }else if(field.fieldTypeId === 'num'){
        this.icon = "lf-icon-hashtag";
      }else if(field.fieldTypeId === 'money'){
        this.decimals = 2 ;
        this.icon = "lf-icon-coin";
      }
      if(field.origen === 'formula'){
        this.decimals = 10;
        field.decimals = 10;
      }
      this.type = field.fieldTypeId;
      if(this.preSelectItem && this.preSelectItem !== "" && this.preSelectItem !== null && !this.dotSeparation ){
          this.inputNumber = this.commonUtilsService.setLocaleNumberFormat(this.preSelectItem);
      }else{
          this.inputNumber = this.preSelectItem;
      }
      // this.fieldTypeNumber.from = field.minValue?field.minValue:field.from;
      // this.fieldTypeNumber.until = field.maxValue?field.maxValue:field.until;
      if(field.inReport !== null && field.inReport !== undefined){
        this.fieldTypeNumber.inReport = field.inReport.indexOf('|') === -1? [field.inReport] : field.inReport.split('|')
      }
    }
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    e.stopPropagation();
    e.preventDefault();
    this.openRelatedInfo.emit({relatedInfo: fieldRelatedInfo});
  }

  resetNumber(){
    this.inputNumber = '';
    this.field.errorInForm = false;
    this.field.error = false;
    this.itemSelected.emit({newValue: null});
    if(this.concept){
      this.commonUtilsService.configureConceptErrorForm(this.field, this.concept);
    }
  }

  showReference(e:any){
    e.stopPropagation();
    this.references = !this.references;
    this.showFieldReference.emit();
  }

  changeInNumber(varDecimal:string) {
    if(this.inputNumber != null && this.inputNumber != ''){
      this.checkMinAndMaxValues();
    }
    let inputNumber: any = this.inputNumber;
    if(this.dotSeparation || varDecimal === 'decimal'){
        this.itemSelected.emit({newValue: inputNumber});
    }else if(inputNumber != null && inputNumber != ''){
        let floatNumber: number = parseFloat(this.commonUtilsService.replaceDecimals(this.inputNumber));
        this.itemSelected.emit({newValue: floatNumber});
    }else{
        this.itemSelected.emit({newValue: null});
    }
    if(this.field.origen && this.field.origen === 'formula'){
        if(this.field.clean){
            inputNumber = '';
        }
    }
  }

  checkMinAndMaxValues(){
    this.field.errorInForm = false;
    this.field.error = false;
    let floatNumber: number = parseFloat(this.commonUtilsService.replaceDecimals(this.inputNumber));
    if(((this.field.minValue || this.field.minValue === 0) && floatNumber < this.field.minValue) || ((this.field.maxValue || this.field.maxValue === 0) && floatNumber > this.field.maxValue)){
      this.field.errorInForm = true;
    }
    if(this.concept){
      this.commonUtilsService.configureConceptErrorForm(this.field, this.concept);
    }
  }


}
