import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'duplicate-documentation-publication',
  templateUrl: './duplicate-documentation-publication.component.html'
})
export class DuplicateDocumentationPublicationComponent{
  pageSize:any = 10;
  @Input() filterConceptList: any = [];
  @Input() duplicateDocumentation: any;
  @Input() currentPage: any;
  @Output() sendAction =  new EventEmitter();

  selectInstance(instance:any){
    this.sendAction.emit({
          action: 'selectInstance',
          instance: instance
    })
  }

  orderBySearch(e:any, type:any){
    this.sendAction.emit({
          action: 'orderBySearch',
          type: type,
          event: e
    })
  }

  orderDate(type:any){
    this.sendAction.emit({
          action: 'orderDate',
          type: type
    })
  }

  selectTypeOperation(e:any, type:any){
    this.sendAction.emit({
          action: 'selectTypeOperation',
          type: type,
          event: e
    })
  }

}
