import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { IcamPriceFormulaFactory } from '@shared/factories/icam-price-formula/icam-price-formula.factory';
import { LcoFactory } from '@shared/factories/lco/lco.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { RelatedVisibilityFieldsFactory } from '@shared/factories/related-visibility-fields/related-visibility-fields.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'field-type-other',
  templateUrl: './field-type-other.component.html',
})
export class FieldTypeOtherComponent implements OnChanges {
  userPermissions:any = this.userPermisions.getPermissions();
  isLanding: any = this.customLanding.isLanding();
  // custom: any = this.customLanding.getCustom();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  currentComponent: string = this.route.snapshot.data['componentName'];
  clientParameters =["address", "cif", "city", "country", "email", "name", "phoneNumber", "postalCode", "province", "logo", "parentId", "percentage" ];
  fieldTypeOther: any = {
    chargedClientFieldValues: false,
    chargedListValuesLco: false,
    chargedList: false,
    markAll: false,
    findDropdown: '',
    conceptSelected: {},
    selectRelated: [],
    relatedList: [],
    listElementSelect: [],
    relationshipList: []
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private userPermisions: UserPermisionsFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private route: ActivatedRoute,
    private apiService: ApiServiceService,
    private relatedVisibilityFields: RelatedVisibilityFieldsFactory,
    private icamPriceFormula: IcamPriceFormulaFactory,
    private lco: LcoFactory,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private broadcastService: BroadcastService,
    private customLanding: CustomLandingFactory) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() concept: any = null;
  @Input() listValues: any = [];

  @Output() itemSelected = new EventEmitter();
  @Output() itemSelectedAdditional = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  ngOnChanges(): void {
    let that: any = this.fieldTypeOther;
    if(this.field){
      that.tooltipText = this.field.isClientField? this.translate.instant('new-client.create-new-client') : this.translate.instant('concept.new-concept');
      that.tooltipList = this.field.isClientField? this.translate.instant('new-client.go-client') : this.translate.instant('concept.go-concept');
      that.prop = this.field.fieldTypeId === 'l-co'? 'conceptObjectId': 'conceptDataId';
      that.titleField = this.field.extendedLabel && this.field.extendedLabel !== ''? this.field.extendedLabel : this.field.label;
      this.field.id = !this.field.id? this.commonUtilsService.createId(): this.field.fieldId;
      if(this.field.isClientField && !that.chargedClientFieldValues){
          this.getClientFieldValues()
          that.chargedClientFieldValues = true;
      }
      if(this.field.relatedFieldId && !that.chargedListValuesLco){
          this.getListValuesLco();
          that.chargedListValuesLco = true;
      }
      that.sameConcept = this.field.sourceConcept && this.field.sourceConcept.conceptId === this.concept?.conceptId;
      if(this.field.evaluatedValue && this.field.evaluatedValue.referenceComments != null){
        let referenceComments:any = this.field.evaluatedValue.referenceComments;
        referenceComments.indexOf('¬') !== -1? that.relationshipList = referenceComments.split('¬') : that.relationshipList.push(referenceComments);
      }
      that.icamUser = (this.field.iD_Version0 === 46 && this.skin === 'icam-red');
      this.field.noEditable = that.icamUser ? that.icamUser : this.field.noEditable;
      if(this.field.inReport !== null && this.field.inReport !== undefined){
        this.fieldTypeOther.inReport = this.field.inReport.indexOf('|') === -1? [this.field.inReport] : this.field.inReport.split('|')
      }
    }
    if(this.concept && this.field){
      let digitized: boolean = this.skin === 'icam-red' && this.concept && this.concept.conceptNamedKey === 'Consulta';
      that.fieldDigitized = digitized && this.field.iD_Version0 === 348 ;
      that.fieldUserDigitized = digitized && this.field.iD_Version0 === 46;
    }
    // that.listValues = this.listValues;
  }

  getClientFieldValues(){
    let url: string = 'clients/fromevaluation/';
    let id: any = this.field.evaluationId;
      if(this.currentComponent === 'conceptWindow' && this.route.snapshot.params['from'] === 'client'){
        url = 'clients/fromsupraclient';
        id = this.route.snapshot.params['clientId']
      }else if (this.field.evaluationId) {
        url = 'clients/fromevaluation/';
        id = this.field.evaluationId
      } else {
        url = 'clients/fromsupraclient';
        id = this.concept.clientId
      }

      this.apiService.get(url, id)
        .then((data:any)=> {
          this.configureEvaluationClients(data);
          this.listValues = data;
          this.fieldTypeOther.chargedList = true;
          if(this.preSelectItem !== null){
              this.getLcoValues();
          }
      });
  }

  configureEvaluationClients(data:any, client:any = null){
      data.forEach((item:any) => {
        item.data = item.name;
        item.objectid = item.clientId;
        item.additionaldata=[];
        for(var key in item){
            if(key === 'country' || key === 'cif' || key === 'province' || key === 'postalCode' || key ==='city' || key === 'address' || key === 'email'){
                item.additionaldata.push({label:this.translate.instant('new-client.client-'+key.toLowerCase()), data: item[key]});
            }
        }
        if(client && client.clientId === item.clientId){
            this.fieldTypeOther.selectRelated = item.additionaldata;
        }
        this.listValueNoName(item)
      });

  }

  toggleAll(event:any) {
    let that = this.fieldTypeOther;
    that.markAll = !that.markAll;

    for (var i = 0; i < this.listValues.length; i++) {
        let listElement = this.listValues[i];
        if (listElement.selected != that.markAll) {
            this.addOrDeleteSelectedValue(event, listElement);
        }
    }
  }

  listValueNoName(data:any){
      if(!data.data || data.data === ''){
          data.data = this.translate.instant('field-type-other.pending-value');
          data.noName = true;
      }
  }

  getLcoValues(){
      let that: any = this.fieldTypeOther
      for(var i = 0; i < this.listValues.length; i++){
        var list: any = this.listValues[i]
          if(list.objectid === this.preSelectItem){
              that.conceptSelected = list;
              that.parentConcept = [that.conceptSelected];
              that.itemObjectId = list.objectid;
              if(this.field.isClientField){
                  that.relatedList.push(list.additionaldata);
                  that.selectRelated = list.additionaldata;
              }
              break;
          }
      }
  }

  getListValuesLco() {
      //Cargamos el valor/es seleccionados
      let field: any = this.field;
      if (field.evaluatedValue?.floatvalue !== null || field.evaluatedValue?.nvarcharValue !== null) {
          this.getEspecificListValue();
      }else if (!field.noEditable && !(field.private && this.isLanding)) {
          //Si es editable y "visible" en la landing, cargamos también todos los valores
          this.getAllListValues();
      }
  }

  getEspecificListValue(){
    let field: any = this.field;
    let conceptObjectsIds:any = field.evaluatedValue?.floatvalue === null? field.evaluatedValue?.nvarcharValue.split('|').join(',') : field.evaluatedValue?.floatvalue;
    this.apiService.get('conceptdata/getrelateddata', field.evaluationId + '/'+field.fieldId+'?conceptObjectIds='+conceptObjectsIds)
      .then((data:any)=>{
          this.configureListValue(data);
          this.listValues = data;
          for(let i = 0; i < data.length; i++){
            if(this.fieldTypeOther.fieldUserDigitized){
                this.concept.usersDigitizedList = data;
                this.isDigitizedField(data[i].additionaldata);
            }
          }
          this.finishLcoConfiguration();
      }, function(errorData){})
  }

  getAllListValues(){
     let that: any = this.fieldTypeOther
      this.apiService.get('conceptdata/getrelateddata', this.field.evaluationId + '/'+ this.field.fieldId)
        .then((data:any)=>{
            if(this.field.multiple && this.field.evaluatedValue?.floatvalue !== null || this.field.evaluatedValue?.nvarcharValue !== null) {
                data.forEach((item:any, index:any) => {
                  for(var i = 0; i < this.listValues.length; i++){
                      if(item.conceptObjectId === this.listValues[i].conceptObjectId){
                          data.splice(index, 1, this.listValues[i]);
                          break;
                      }
                  }
                });
            }
            this.listValues = data;

            this.configureListValue(data);
            if(that.fieldUserDigitized){
              this.concept.usersDigitizedList = data;
            }
            data.forEach((item:any) => {
              if(that.fieldUserDigitized){
                this.isDigitizedField(item.additionaldata);
            }
            });
            that.chargedList = true;
        }, function(errorData){})
  }

  configureListValue(data:any){
    data.forEach((item:any, index:any) => {
      this.listValueNoName(item);
      if(index === data.length-1){
        this.fieldTypeOther.lastItem = item.objectid;
      }
    });
  }

  isDigitizedField(data:any){
    let concept: any = this.concept;
    for(var i = 0; i < concept.usersDigitizedList.length; i++){
        var user = concept.usersDigitizedList[i];
        if(user.objectid === this.preSelectItem){
            concept.nameUsersDigitized += ' ' + user.data;
            break;
        }
    }
  }

  finishLcoConfiguration(){
      let preSelectItem: any = this.preSelectItem;
      let field: any = this.field;
      if(preSelectItem !== null && preSelectItem !== ''){
          this.getLcoValues();
          if(this.field.multiple){
              this.extractMultiple();
          } else {
              this.fieldTypeOther.selectRelated = this.findRelated(preSelectItem).item.additionaldata;
              var numSubContainer = field.evaluatedValue.numSubContainer === null? 0: field.evaluatedValue.numSubContainer
              this.relatedVisibilityFields.add(this.fieldTypeOther.selectRelated, field.fieldId, numSubContainer);
              this.itemSelected.emit({newValue:preSelectItem});
              if (this.itemSelectedAdditional) {
                  this.itemSelectedAdditional.emit({ additionalData: this.fieldTypeOther.selectRelated });
              }
              if(field && field.evaluatedValue){
                  this.addDisplayData(this.fieldTypeOther.conceptSelected);
              }
              if(this.fieldTypeOther.fieldDigitized){
                  this.setDigitizedBaseValue(this.fieldTypeOther.selectRelated);
              }
          }
      }
      if(!field.noEditable && !(field.private && this.isLanding)){
          //Si es editable y "visible" en la landing, cargamos también todos los valores
          this.getAllListValues();
      }
  }

  setDigitizedBaseValue(additionalData:any){
      var value = additionalData[2].data === null || additionalData[2].data === ''? 0 : this.convertToFloat(additionalData[2].data)
      this.icamPriceFormula.set(this.field.fieldContainerId, value);
      this.itemSelected.emit({newValue:'icam', withOutSaves: true})
  }

  findRelated(objectid:any) {
      for(var i = 0; i < this.listValues.length; i++){
          var item = this.listValues[i];
          if(item.objectid === objectid){
              return {
                  item: item,
                  index: i
              }
          }
      }
      return {}
  }

  extractMultiple(){
      let items:any = [this.preSelectItem];
      items = !Number.isInteger(this.preSelectItem) && this.preSelectItem.indexOf('|')!== -1 ? this.preSelectItem.split('|') : [this.preSelectItem];
      items = items.filter((item:any, i:any) => {
        return items.indexOf(item) === i;
      })
      items.forEach((item:any) => {
        let listElement:any = this.findRelated(parseInt(item)).item;
        listElement.selected = true;
        this.fieldTypeOther.listElementSelect.push(listElement);
        if(this.field.lcoIsUngroupedView){
          this.fieldTypeOther.relatedList.push(listElement.additionaldata)
        }
        if(this.field && this.field.evaluatedValue){
            this.addDisplayData(listElement);
        }
      });

      this.calculeWidthElements();
      this.configureListVarchar();
      this.extractSelected(items);
  }

  extractSelected(items:any){
      var selected = this.lco.get(this.field.fieldId)
      if(!selected){
          selected = this.findRelated(parseInt(items[items.length-1])).item;
      }
      this.fieldTypeOther.listOtherMultipleIndex = this.findIndex(selected.objectid);
      this.fieldTypeOther.conceptSelected = selected;
      this.fieldTypeOther.selectRelated = this.fieldTypeOther.conceptSelected.additionaldata;
      let numSubContainer:any = this.field.evaluatedValue.numSubContainer === null? 0: this.field.evaluatedValue.numSubContainer
      this.relatedVisibilityFields.add(this.fieldTypeOther.selectRelated, this.field.fieldId, numSubContainer);
  }

  findIndex(objectid:any){
    return this.fieldTypeOther.listElementSelect.findIndex((item:any)=>{return item.objectid === objectid});
  }

  convertToFloat(data:any){
      return data.indexOf('€') !== -1? parseFloat(data.substring(0, data.indexOf('€'))) : parseFloat(data);
  }

  resetItemSelect(){
      this.fieldTypeOther.conceptSelected={
          data:''
      }
      this.fieldTypeOther.conceptSelected = false;
      this.fieldTypeOther.listElementSelect = [];
      this.fieldTypeOther.relatedList = [];
      this.fieldTypeOther.selectRelated = [];
      let numSubContainer:any = this.field.evaluatedValue.numSubContainer === null? 0: this.field.evaluatedValue.numSubContainer
      this.relatedVisibilityFields.clear(this.field, numSubContainer);
      this.listValues.forEach((valueItem:any) => {
        valueItem.selected = false;
      });

      this.itemSelected.emit({newValue:null});
  }

  catchEvent(e:any){
      e.stopPropagation();
  }

  generateNew(){
    this.dropdown.close();
    this.field.isClientField? this.newClient() : this.newEvaluatedConcept();
  }

  newClient(){
      this.listValues = this.commonUtilsService.editList(this.listValues, 'clientId', 'name');
      this.showModalClient('create', {}, true, true);
  }

  createNewClient(client:any){
      var itemSelected = {
          type: 'create-client',
          item: client.client
      }
      this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
        if(result.result ==='ok'){
          result.item.canAdmin = true;
          this.configureClient(result.item, client.client);
          this.setClient('created', client, result.item);
        }else{
          this.modalService.adviceModal(this.translate.instant('dashboard.error-create-client')+' "'+client.client.name+'"', 'error:accept:warning');
        }
      });
  }

  setClient(type:any, client:any, newClient:any){
      if(client.logo){
          this.addLogoToClient(type, client, newClient);
      } else {
        this.loader.closeLoader(this.fieldTypeOther.typeAction);
        this.confirmModal(newClient.name, type);
        this.addClientToList(type, newClient);
      }
  }

  confirmModal(name:any, type:any){
      var text = this.translate.instant('dashboard.client') +"'"+ name +"'"+ this.translate.instant('dashboard.ok-'+type);
      this.modalService.adviceModal(text, 'info:accept:check-round')
  }

  addLogoToClient(type:any, client:any, newClient:any){
      this.apiService.attachFilePatch('clients/'+newClient.clientId+'/logos', client.logo)
          .then((data:any)=>{
              newClient.logo = client.logoImage;
              this.loader.closeLoader(this.fieldTypeOther.typeAction);
              this.confirmModal(newClient.name, type);
              this.addClientToList(type, newClient);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('clients-list.error-logo'), 'error:accept:warning');

          });
  }

  addClientToList(action:any, client:any){
      if(action === 'modify'){
        this.listValues = cloneDeep(this.commonUtilsService.replaceElement(client, this.listValues, 'clientId', client));
      }else if(action === 'created'){
        this.listValues.push(client);
      }
      this.configureEvaluationClients(this.listValues, client);
  }

  newEvaluatedConcept(){
    let field: any = this.field;
    let newItem = {
        title:  this.translate.instant('rename-modal.name-concept-new'),
        type: 'concept',
        titleConcept:  field.sourceConcept.title,
        conceptId:  field.sourceConcept.conceptId,
        conceptObjectId: field.sourceConcept.conceptObjectId,
        listFolders: this.listValues,
        icon:'lf-icon-compliance',
        rename: false
    }
    this.modalService.renameModal(newItem).subscribe((result:any)=>{
      if(result.result === 'ok'){
        this.loader.openLoader('folder-open-concept')
        this.newConcept(result.name);
      }
    });
  }

  newConcept(name:any){
      if(this.field.relatedFieldId === null){
          this.loader.closeError();
          this.modalService.adviceModal(this.translate.instant('concept-form.error-add-concept'), 'error:accept:warning');
      }else{
          this.apiService.get('fields/getconceptbyfieldid', this.field.evaluationId + '/' + this.field.relatedFieldId)
          .then( (data:any)=> {
              this.createNewEvaluatedConcept(data, name);
          }, (errorData:any)=>{
              this.loader.closeError();
          });
      }

  }

  createNewEvaluatedConcept(concept:any, name:any){
      this.apiService.add('conceptobjects/'+concept.conceptId, {title:name})
      .then((data:any)=>{
          data.objectid = data.conceptObjectId
          if(this.field.multiple){
            this.fieldTypeOther.conceptSelected = data;
            this.fieldTypeOther.listElementSelect.push(data);
            this.configureListVarchar();
          }else{
            this.preSelectItem = data.conceptObjectId;
            this.itemSelected.emit({newValue:this.preSelectItem});
          }
          this.broadcastService.broadcast('openRelatedConcept', {
            relatedConcept: data.conceptObjectId,
            active:true,
            evaluatedValue: this.field.evaluatedValue
        })
      }, (errorData:any)=>{
          this.loader.closeError();
      });
  }

  selectConcept(item:any){
      if(this.fieldTypeOther.fieldDigitized){
          loopFieldDigitized:
          for(var i = 0; i < this.concept.fieldContainers.length; i++){
              var container = this.concept.fieldContainers[i];
              for(var j = 0; j < container.fields.length; j++){
                  var fieldContainer = container.fields[j];
                  if(fieldContainer.iD_Version0 === 46){
                      this.checkDigitizedField(item, fieldContainer);
                      break loopFieldDigitized;
                  }
              }
          }
      }else{
          this.selectOption(item);
      }
  }

  checkDigitizedField(item:any, field:any){
      // vm.field.evaluatedValue.floatvalue
      this.apiService.get('icam/check-max-scan/usuario/' + field.evaluatedValue.floatvalue + '/fragmento' , item.objectid)
          .then((data:any)=> {
              if(data.currentPercentage > data.maxPercentage){
                  var text = this.translate.instant('field-type-other.error-digitized-max-percentage').replace('{user}',  this.concept.nameUsersDigitized);
                  var text = text.replace('{publication}',  data.publicationTitle);
                  this.modalService.adviceModal(text, 'info:question:question').subscribe((r: any) => {
                    if (r.result === "ok") {
                      this.selectOption(item);
                    }
                  });
              }else{
                  this.selectOption(item);
              }
          }, (errorData:any)=>{});
  }

  selectOption(item:any){
      let field: any = this.field;
      let numSubContainer:any = field.evaluatedValue.numSubContainer === null? 0: field.evaluatedValue.numSubContainer
      this.relatedVisibilityFields.clear(field, numSubContainer);
      this.fieldTypeOther.conceptSelected = item;
      this.preSelectItem = item.objectid;
      if(field && field.evaluatedValue){
          field.evaluatedValue.displayData = [];
          this.addDisplayData(item);
          numSubContainer = field.evaluatedValue.numSubContainer === null? 0: field.evaluatedValue.numSubContainer
          this.relatedVisibilityFields.add(item.additionaldata, field.fieldId, numSubContainer);
      }
      if(field.isClientField){
          this.fieldTypeOther.relatedList.push(item.additionaldata);
          this.fieldTypeOther.selectRelated = item.additionaldata;
          this.itemSelected.emit({newValue:this.preSelectItem})
      }else{
          //_getRelatedFields(item.objectid, true);
          this.fieldTypeOther.selectRelated = this.fieldTypeOther.conceptSelected.additionaldata;
          this.itemSelected.emit({newValue:this.preSelectItem});
          if(this.fieldTypeOther.fieldDigitized){
              this.setDigitizedBaseValue(this.fieldTypeOther.selectRelated);
          }
      }
      this.dropdown.close();
  }

  addDisplayData(item:any){
    let selectedValue:any = {
        id: item.conceptObjectId,
        keyValue: {
            id: item.fieldId,
            value: item.data,
            keyValue: true
        },
        value:[]
    };
    item?.additionaldata.forEach((aditional: any) => {
      let newValueAdditional:any = {
        id: aditional.fieldId,
        label: aditional.label,
        value: aditional.data?aditional.data.replace(/&nbsp;/g, ' '):aditional.data
      }
      selectedValue.value.push(newValueAdditional);
    });
    this.field.evaluatedValue.displayData.push(selectedValue);
  }

  viewRelatedConcept(e:any, listValue:any){
    if(!this.field.noEditable || (this.concept.conceptNamedKey === 'Encuesta' && this.skin === 'icam-red' && this.isLanding) || this.fieldTypeOther.icamUser){
        if(listValue.clientId){
            listValue.canAdmin = true;
            this.showModalClient('edit', listValue, false, true);
        }else{
            this.lco.set(this.field.fieldId, this.fieldTypeOther.conceptSelected);
            this.loader.openLoader('folder-open-concept');
            e.stopPropagation();
            this.broadcastService.broadcast('openRelatedConcept', {
                relatedConcept: listValue.conceptObjectId,
                active: true,
                evaluatedValue: undefined
            })
        }
    }
  }

  showModalClient(type:any, client:any, onlyAffiliated:any, disabledField:any){
      let list:any = this.listValues;
      let createClients: boolean = true;
      if(this.fieldTypeOther.parentConcept && type === 'create'){
          list = this.fieldTypeOther.parentConcept
      }else if(type === 'create'){
          list = [list[0]];
      }
      let configuration = {
        type: type,
        organization: client,
        listOrganization: list,
        onlyAffiliated: onlyAffiliated,
        disabledField: disabledField,
        createClients: createClients
      }
      this.modalService.organizationModal(configuration)
        .subscribe((result:any)=>{
          if(result.result ==='ok'){
            switch(type){
              case 'create':
                  this.fieldTypeOther.typeAction = 'field-type-other-new-client';
                  this.createNewClient(result);
                  break;
              case 'edit':
                this.fieldTypeOther.typeAction = 'clients-list-edit-client';
                this.loader.openLoader(this.fieldTypeOther.typeAction);
                this.saveClient(result, client);
                break;
            }
          }
        })


  }

  saveClient(result:any, client:any){
      this.apiService.patch('clients', result.client.clientId, result.client)
          .then((data:any)=>{
              this.configureClient(result.client, client);
              this.setClient('modify', result, client);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('clients-list.error-edit'), 'error:accept:warning');
          });
  }

  configureClient(fromClient:any, toClient:any) {
      this.clientParameters.forEach((type:any)=> {
        toClient[type] = fromClient[type];
      });
  }

  addOrDeleteSelectedValue(e:any, listElement:any){
      let that: any = this.fieldTypeOther
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      // let listElementSelect: any = this.fieldTypeOther.listElementSelect;
      let field: any = this.field;
      var exist = true;
      for(var i = 0; i < that.listElementSelect.length; i++){
          let elementSelected:any = that.listElementSelect[i];
          if(listElement.objectid === elementSelected.objectid){
              // var numSubContainer = field.evaluatedValue.numSubContainer === null? 0: field.evaluatedValue.numSubContainer;
              this.relatedVisibilityFields.remove(listElement.objectid) //, field.fieldId, numSubContainer
              that.markAll = false;
              elementSelected.selected = false;
              that.listElementSelect.splice(i, 1);
              that.relationshipList.splice(i, 1);
              if(field.lcoIsUngroupedView){
                that.relatedList.splice(i, 1);
              }
              that.selectRelated = that.listElementSelect.length? that.listElementSelect[that.listElementSelect.length-1].additionaldata : [];
              that.conceptSelected = that.listElementSelect.length? that.listElementSelect[that.listElementSelect.length-1] : null;
              that.listOtherMultipleIndex = that.listElementSelect.length? that.relatedList.length : 0;
              exist = false;
              if(field && field.evaluatedValue){
                  listElement.id = listElement.fieldId;
                  field.evaluatedValue.displayData = this.commonUtilsService.deleteElement(listElement, field.evaluatedValue.displayData, 'id');
              }
              break;
          }
      }
      if(exist){
          listElement.selected = true;
          that.listElementSelect.push(listElement);
          that.conceptSelected = listElement;
          that.selectRelated = listElement.additionaldata;
          that.listOtherMultipleIndex = that.listElementSelect.length - 1;
          let numSubContainer:any = field.evaluatedValue.numSubContainer === null? 0: field.evaluatedValue.numSubContainer
          this.relatedVisibilityFields.add(listElement.additionaldata, field.fieldId, numSubContainer);
          if(field.lcoIsUngroupedView){
            that.relatedList.push(listElement.additionaldata)
          }
          if(field && field.evaluatedValue){
              this.addDisplayData(listElement);
          }
      }
      this.calculeWidthElements();
      this.configureListVarchar();
  }

  calculeWidthElements(){
      this.fieldTypeOther.widthElement = this.fieldTypeOther.listElementSelect.length >= 4?'calc('+(100/4) + '% - 2px)' : 'auto';
  }

  configureListVarchar(){
      let nvarcharValue:any ='';
      let listElementSelect: any = this.fieldTypeOther.listElementSelect;

      for(var i = 0; i < listElementSelect.length; i++){
        let element: any = listElementSelect[i]
        nvarcharValue += listElementSelect.length-1 === i? element.objectid : element.objectid + '|';
      }
      this.itemSelected.emit({newValue:nvarcharValue})
  }

  goTo(to:string){
     let that: any = this.fieldTypeOther
      to==='next'? that.listOtherMultipleIndex++ : that.listOtherMultipleIndex--;
      that.conceptSelected = that.listElementSelect[that.listOtherMultipleIndex];
      that.selectRelated = that.conceptSelected.additionaldata;
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  resetSearch(e:any){
      this.fieldTypeOther.findDropdown = '';
  }

  closeListItem(){
    this.dropdown.close();
  }

  openRelationshipModal(index:number){
      this.modalService.relationshipReasonModal(this.fieldTypeOther.relationshipList[index]).subscribe((result:any)=>{
        if(result.result==='ok'){
          this.fieldTypeOther.relationshipList[index] = result.relationshipReason;
          this.field.evaluatedValue.referenceComments = this.fieldTypeOther.relationshipList.join('¬')
        }
      })
  }
}
