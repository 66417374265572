<div class="establish-pattern">
    <div class="common__modal-header-block">
        <i class="lf-icon-calendar-cross common__icon-header"></i>
        <span class="common__text-header"
        [afTranslate]="'expanded-menu.expiration-period'">Plazo de expiración</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="establish-pattern__body-expiration">
        <p class="common__title-block-modal"
            [afTranslate]="'expiration-period.expiration-info'">
            La encuesta será accesible desde el momento de difusión hasta la fecha de expiración. Este plazo podrá ser reducido o ampliado en cualquier momento.
        </p>
        <div class="establish-pattern__repeat-interval">
            <p class="establish-pattern__periodicity-title"
                [afTranslate]="'expiration-period.survey-timing'"></p>
            <field-type-date class="establish-pattern__periodicity-list"
                [field]="expirationPeriod.repeatIntervalDate"
                [preSelectItem]="expirationPeriod.intervalRepeat.startInterval"
                (itemSelected)="selectIntervalDate('startInterval', $event)"></field-type-date>
            <div class="establish-pattern__periodicity-block establish-pattern__periodicity-block--repeat-interval">
                <p class="establish-pattern__periodicity-block-title">
                    <span [afTranslate]="'repeat-pattern.end-interval'"></span>
                    <span class="establish-pattern__periodicity-asterisk">*</span>
                </p>
                <i class="lf-icon-radio-buttons-list establish-pattern__periodicity-list-icon"></i>
                <div class="establish-pattern__block-list-interval">
                    <label class="establish-pattern__list-option-interval"
                        for="no-end-date">
                        <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                            [ngClass]="{'lf-icon-radio-button-active': expirationPeriod.intervalRepeat.selectInterval === 'no-end-date',
                            'lf-icon-step-first': expirationPeriod.intervalRepeat.selectInterval !== 'no-end-date'}">
                        </i>
                        <input class="task-edit__text-description-input"
                            id="no-end-date"
                            type="radio"
                            name="select-interval"
                            value="no-end-date"
                            [(ngModel)]="expirationPeriod.intervalRepeat.selectInterval">
                        <span class="establish-pattern__option-text"
                            [afTranslate]="'expiration-period.no-end-date'">_Sin fecha de finalización</span>
                    </label>
                </div>
                <div class="establish-pattern__block-list-interval">
                    <label class="establish-pattern__list-option-interval"
                        for="end-interval-day">
                        <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                            [ngClass]="{'lf-icon-radio-button-active': expirationPeriod.intervalRepeat.selectInterval === 'end-interval-day',
                            'lf-icon-step-first': expirationPeriod.intervalRepeat.selectInterval !== 'end-interval-day'}">
                        </i>
                        <input class="task-edit__text-description-input"
                            id="end-interval-day"
                            type="radio"
                            name="select-interval"
                            value="end-interval-day"
                            [(ngModel)]="expirationPeriod.intervalRepeat.selectInterval">
                        <span class="establish-pattern__option-text"
                            [afTranslate]="'expiration-period.expirated-day'">_expirar encuesta el día</span>
                        <picker-data-simple
                            [finishIntervalDate]="expirationPeriod.intervalRepeat.finishIntervalDate"
                            (selectDate)="selectIntervalDate($event, 'finishIntervalDate')"
                            [disabled]="expirationPeriod.intervalRepeat.selectInterval !== 'end-interval-day'">
                        </picker-data-simple>
                    </label>
                </div>
            </div>

        </div>
        <advice-message [ngShow]="expirationPeriod.hasError"
            [icon]="'warning'"
            [type]="'error'"
            [text]="expirationPeriod.textError"
            (closeAdvice)="closeAdvice('hasError')">
        </advice-message>
        <div class="common__buttons-block common__buttons-block--modal">
            <button type="reset"
                class="common__button common__button--clean"
                (click)="handleDismiss()"
                [afTranslate]="'common.cancel'">cancelar</button>
            <button type="submit"
                class="common__button common__button--active"
                (click)="validateRepetition()"
                [afTranslate]="'common.save'">guardar</button>
        </div>
    </div>
</div>
