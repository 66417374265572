<div class="common__panel concept-window">
    <header-nav></header-nav>
    <div class="common__block-window-view">
        <div class="common__block-button-close-window">
            <button (click)="changeView()"
                class="common__back-link">
                <span>{{conceptWindow.configurationView?.closeText}}</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="common__container-window-view">
            <div class="common__block-list-new-version common__block-list-new-version--open-task">
                <div class="common__header-new-version">
                    <i [class]="'common__header-icon-new-version '+conceptWindow.configurationView?.icon"></i>
                    <span class="common__header-title-new-version">{{conceptWindow.configurationView?.title}}</span>
                    <span class="concept-window__recurrent-tag"
                        [ngShow]="skin !== 'steel-blue' && conceptWindow.concept?.isClientRecurrent"
                        [afTranslate]="'common.recurrent-client'">_Cliente recurrente</span>
                    <button class="lf-icon-add concept-window__icon-clip"
                        [ngShow]="from === 'client' && skin === 'dark-blue'"
                        [disabled]="conceptWindow.disabledLink"
                        (click)="openPdf($event)"
                        matTooltip="{{'concept-window.title-pdf-risk-jurisdictions-list' | afTranslate}}"
                        matTooltipPosition="left"
                        matTooltipClass="left">
                    </button>
                </div>
                <concept-window-tab
                    [activeConcept]="conceptWindow.concept"
                    (launchAction)="launchAction($event)">
                </concept-window-tab>
                <div [ngSwitch]="conceptWindow.concept?.subTab"
                    class="concept__content">
                    <div *ngSwitchCase="'general'">
                      <concept-form-default *ngIf="conceptWindow.finishLoader"
                          [concept]="conceptWindow.concept"
                          [hasLinksToForms]="conceptWindow.concept?.hasLinksToForms"
                          [public]="conceptWindow.public"
                          (launchAction)="launchAction($event)">
                      </concept-form-default>
                    </div>
                    <documentation-list *ngSwitchCase="'documentation'"
                        [concept]="conceptWindow.concept"
                        (modifyConceptTab)="modifyConceptTab()"
                        [public]="conceptWindow.public">
                    </documentation-list>
                    <task-concept *ngSwitchCase="'task'"
                        [concept]="conceptWindow.concept"
                        [taskTypeIdSelect]="conceptWindow.taskTypeIdSelect"
                        [hasOpenEvaluationTask]="conceptWindow.hasOpenEvaluationTask"
                        [to]="conceptWindow.to"
                        (modifyConceptTab)="modifyConceptTab()"
                        (launchAction)="launchAction($event)"></task-concept>
                    <alert-concept *ngSwitchCase="'alerts'"
                        [concept]="conceptWindow.concept"
                        (modifyConceptTab)="modifyConceptTab()"></alert-concept>
                    <historic-concept *ngSwitchCase="'historic'"
                        [concept]="conceptWindow.concept"
                        (launchAction)="modifyConceptTab()"></historic-concept>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer [ngShow]="conceptWindow.finishLoader"></compliance-footer>
  </div>

