import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentParentService {

  subscribers: any = {};
  constructor(private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private validateDocumentService: ValidateDocumentService,
    private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private globalCfg: GlobalCfgFactory) {
  }

   configureUploadDocuments(child:any){
      return {
        upDocuments: [],
        documentsToFieldInNewConcept: [],
        filesUpdate: [],
        viewOption: 'upload',
        canClose: true,
        validateDocument: false,
        stateId: null,
        updating: false,
        textInfo: this.translate.instant('up-document.text-info'),
        comments: '',
        document: {
            properties:{},
            name:''
        },
        processSteps: {
            label: this.translate.instant('common.associate-to-process'),
            required:  false
        },
        productFieldInfo: {
            fieldId:'format',
            label: this.translate.instant('common.format'),
            description: null,
            required: true
        },
        nameFieldInfo: {
            label: this.translate.instant('common.document-name'),
            required:  true,
            description: null
        },
        templateFieldInfo: {
            fieldId:'template',
            label: this.translate.instant('new-document.template'),
            description: null,
            required: true
        },
        uploadDefered: null,
        failedUploads: null,
        uploadedFiles: null

      }
    }


    dropNewDocument(files:any = [], child:any){
      this.loadFiles(files, child);
    }

    loadFiles(files:any, child:any){
      for(let i = 0; i < files.length; i++){
        this.loadSelectFile(files[i], child);
      }
    }

    loadSelectFile(file:any, child:any){
      let fd = new FormData();
      fd.append('file', file);
      let nameSplit = file.name.split('.');
      let fileObject: any = {
          document: fd,
          name: file.name,
          size: file.size,
          source: nameSplit[nameSplit.length-1],
          type: 'upload',
          progress: 0,
          originalFile: file,
          id: this.commonUtilsService.createId()
      }

      let validateText:any =  this.validateUploadFiles(fileObject,  child.uploadDocuments.documentationList, child);
      let validateTextUpdated = this.validateUploadwithUpload(fileObject.name, child.uploadDocuments.upDocuments, child);
      fileObject.statusText = '';
      child.uploadDocuments.validateDocument = true;
      if(validateText !== ''){
        fileObject.statusText = this.translate.instant(validateText);
        fileObject.status = 'validate-error'
      }else if(validateTextUpdated !==''){
        fileObject.statusText = this.translate.instant(validateTextUpdated);
        fileObject.status = 'validate-error'
      }
      if(child.type === 'addNewDocumentsToFieldsInNewInstance'){
          child.uploadDocuments.documentsToFieldInNewConcept.push(fileObject);
      }
      child.uploadDocuments.upDocuments.push(fileObject);
    }

    validateUploadFiles(file:any, list:any, child:any){
      file.status = '';
      if(!(this.validateDocumentService.correctCharacters(file.name))){
          return 'documentation-list.invalid-name';
      }
      if(!(this.validateDocumentService.correctDocumentFormat(file.name))){
          return 'documentation-list.format-not-accepted'
      }
      if(this.validateDocumentService.duplicatedDocuments(file.name, list)){
          return 'documentation-list.duplicated-name-document'
      }
      if(!(this.validateDocumentService.maxSize(file.size))){
          return 'documentation-list.max-size'
      }
      if(!(this.validateDocumentService.nameLength(file.name))){
          return 'up-document.error-name'
      }
      return '';
    }

    validateUploadwithUpload(document:any, list:any, child:any){
      return this.validateDocumentService.duplicatedDocuments(document, list)? 'documentation-list.duplicated-name-inner-list' : '';
    }

    uploadFiles(child:any) {
      let that = child.uploadDocuments;
      if(that.validateDocument){
        if(child.type === 'addNewDocumentsFromProcessModal' && !that.stateId){
            that.processSteps.error = true;
        }else{
            let failedDocs = this.validateFiles(child);
            if (failedDocs.length) {
                this.openQuestionModal(this.translate.instant('task-edit.add-correct-format'), child);
            } else {
                this.uploadFilesComponent(child);
            }
        }
      }
    }

    validateFiles(child:any){
      let that = child.uploadDocuments;
      if(that.upDocuments.length >0){
        that.canClose = false;
      }
      that.validateDocument = false;
      let failedDocs = [];
      for(let i = 0; i < that.upDocuments.length; i++){
          let document = that.upDocuments[i];
          if (document.statusText !== '') {
              if (document.status === 'validate-error') {
                  failedDocs.push({
                      name: document.name,
                      status: document.statusText
                  });
              } else {
                  document.status = '';
                  document.statusText = '';
              }
          }else{
              that.validateDocument = true;
          }
      }
      return failedDocs;
    }

    openQuestionModal(text:any, child:any) {
      let type = child.isLanding? 'error:question:exclamation-full' : 'error:question:warning';
      this.modalService.adviceModal(text, type).subscribe((result:any) => {
          if(result.result === 'ok'){
                let contNoError:number = 0;
                let validDocuments:any=[];
                child.uploadDocuments.upDocuments.forEach((doc:any) => {
                  if(doc!=='validate-error'){
                    contNoError++;
                    validDocuments.push(doc);
                  }
                });

                child.uploadDocuments.upDocuments = validDocuments;
                this.uploadFiles(child);
                if(contNoError===0){
                    child.uploadDocuments.canClose = true;
                }
          }
        });
    }


    uploadFilesComponent(child:any) {
        let that = child.uploadDocuments;
        that.failedUploads = [];
        that.uploadedFiles = [];
        that.updating = true;
        that.updateAmount = 0;
        that.updateAccomplish = 0;
        if(child.itemSelected && child.itemSelected.conceptObjectId && that.upDocuments.length > 1){
            this.createFolderToDocuments(child);
        }else{
            this.checkDocuments(child);
        }
    }

    createFolderToDocuments(child:any){
      this.apiService.add('documentobjects/premultiupload/' + child.itemSelected.conceptObjectId)
        .then((data:any) => {
              this.checkDocuments(child);
          },(error:any) => {}
        );
    }

    checkDocuments(child:any){
      if(this.globalCfg.skin ==='icam-red'){
        child.uploadDocuments.sequentialIndex = 0;
        this.addDocument(child.uploadDocuments.upDocuments[child.uploadDocuments.sequentialIndex], true, child, true);
      }else{
        child.uploadDocuments.upDocuments.forEach((document:any) => {
          if(document.statusText === ''){
            child.uploadDocuments.updateAmount ++;
            this.addDocument(document, true, child, false);
          }
        });
      }

    }

    addDocument(document:any, firstLoad:any, child:any, itsSequential:boolean) {
      document.status = '';
      if (firstLoad) {
        this.subscribers.uploadFileBroadcast = this.broadcastService.subscribe('uploadFile' + document.name, (data:any) => {
            document.progress = Math.floor(data.percent);
            if (data.percent === 100) {
                document.status = 'upload'
            }
        })
      }
      this.addDocumentGeneral(document, firstLoad, this.getUrl(child), child, itsSequential);
    }

    getUrl(child:any){
      let that = child.uploadDocuments;
      let itemSelected = child.itemSelected;
      let url = '';
      switch(child.type){
        case 'evaluationShare':
            url =  'upload';
            break;
        case 'addNewDocumentsFromTasksNew':
            url = 'upload';
            break;
        case 'addNewDocumentsFromConceptsNew':
            url = 'upload';
            break;
        case 'addNewDocumentsFromTasks':
            url = 'documentobjects/upload/' + itemSelected.conceptObjectId;
            if (itemSelected && itemSelected.taskId) {
                url = 'tasks/upload/' + itemSelected.taskId;
            }
            break;
        case 'addNewDocumentsConcept':
            url = 'publicconceptdoc/concept/' + itemSelected.conceptId;
            break;
        case 'addNewDocumentsFromLanding':
            url = 'documentobjects/upload/' + itemSelected.conceptObjectId;
            break;
        case 'addNewDocumentsToFields':
            url = 'conceptdata/upload/' + itemSelected.fieldId + '/' + itemSelected.evaluatedValue.conceptObjectId;
            if(that.stateId){
                url = 'conceptdata/upload/' + itemSelected.fieldId + '/' + itemSelected.evaluatedValue.conceptObjectId + '/state/' + that.stateId;
            }
            if (itemSelected.evaluatedValue.numSubContainer) {
                url = 'conceptdata/uploadtomultiplesubgroup/' + itemSelected.fieldId + '/' + itemSelected.evaluatedValue.conceptObjectId + '/' + itemSelected.evaluatedValue.numSubContainer;
            }
            break;
        case 'addNewDocumentsToFieldsInNewInstance':
            url = 'upload';
            break;
        case 'addNewDocuments':
        case 'addNewDocumentsFromProcessModal':
        case 'dropFiles':
            url = 'documentobjects/upload/' + itemSelected.conceptObjectId;
            if(child.documentationList.parentId){                      // JRSJ 13/05/2020      Se añade para enviar documentos nuevos a una carpeta
                url = url + '/' + child.documentationList.parentId;
            }
            if(that.stateId){
                url = 'documentobjects/upload/' + itemSelected.conceptObjectId + '/state/' + that.stateId;
            }
            break;
        default:
            url = 'documentobjects/upload/' + child.route.snapshot.params['conceptObjectId'];
      }
      return url;
    }

    addDocumentGeneral(document:any, firstLoad:any, url:string, child:any, isSequential:boolean){
      let that = child.uploadDocuments;
      this.apiService.attachFileWithProgress(url, document).then(
        (data:any) => {
          setTimeout(() => {
              document.status = 'complete';
          });
          that.updateAccomplish++;
          if(child.type === 'addNewDocumentsFromTasksNew' || child.type === 'addNewDocumentsFromConceptsNew'){
              that.filesUpdate = that.filesUpdate.concat(data);
              that.uploadedFiles.push(data[0]);
          }else if(child.type === 'addNewDocumentsToFieldsInNewInstance'){
              that.filesUpdate = that.documentsToFieldInNewConcept;
              that.uploadedFiles.push(data[0]);
          }else{
              that.filesUpdate.push(data);
              that.uploadedFiles.push(data[0]);
          }
          if(firstLoad){
              if(that.updateAccomplish === that.updateAmount){
                  that.complete = true;
                  that.canClose = true;
              }
          }
          if(isSequential){
            child.uploadDocuments.sequentialIndex++;
            if(child.uploadDocuments.sequentialIndex < child.uploadDocuments.upDocuments.length){
              this.addDocument(child.uploadDocuments.upDocuments[child.uploadDocuments.sequentialIndex], true, child, true);
            }else{
              that.complete = true;
              that.canClose = true;
            }
          }
        },
        (error:any) => {
            document.status = 'error';
            that.updateAccomplish++;
            document.statusText = this.translate.instant('common.error');
            that.failedUploads.push(document);
            if (that.updateAccomplish === that.updateAmount) {
                that.complete = true;
                that.canClose = true;
            }
        }
      );
    }

    deleteDocument(index:any, child:any){
        child.uploadDocuments.upDocuments.splice(index, 1);
        if(child.type === 'addNewDocumentsToFieldsInNewInstance'){
            child.uploadDocuments.documentsToFieldInNewConcept.splice(index, 1);
        }
        this.validateFiles(child);
    }


    itemSelectedList(item:any, newValue:any, child:any){
      child.uploadDocuments[item] = newValue;
        if(item === 'stateId'){
          child.uploadDocuments.processSteps.error = false;
            for(let i = 0; i < child.listSelect.length; i++){
              let itemSelected: any = child.listSelect[i]
                if(itemSelected.stateId === newValue){
                  child.uploadDocuments.nameState = itemSelected.name;
                  break;
                }else{
                  child.uploadDocuments.nameState = '';
                }
            }
        }
    }

    openFileSearch(){
      setTimeout(function(){
          let documentInput: any = $('#fileDropRef');
          documentInput.click();
      })
    }


    openTextareaModal(child:any) {
      this.modalService.expandTextarea(child.uploadDocuments.comments, { label: this.translate.instant('attach-document.add-comment') })
        .subscribe((result:any)=>{
          if(result.result ==='ok'){
            child.uploadDocuments.comments = result.inputText;
          }
        })
    }

    renameDocument(document:any, child:any){
      let uploadDocuments = child.uploadDocuments;
      document.titleConcept = document.name;
      let upDocuments: any = this.getDocumentsSelectedToUp(document, uploadDocuments);
      this.modalService.renameModal(document, uploadDocuments.documentationList, upDocuments)
        .subscribe((result:any) => {
          if(result.result === 'ok'){
            let newDocument:any = this.changeName(document.originalFile, result.name + document.name.substring(document.name.lastIndexOf('.'), document.name.length));

            let fd = new FormData();
            fd.append('file', newDocument);
            let nameSplit = newDocument.name.split('.');
            let file:any = {
                document: fd,
                name: newDocument.name,
                size: newDocument.size,
                source: nameSplit[nameSplit.length-1],
                type: 'upload',
                progress: 0,
                originalFile: newDocument,
                id: newDocument.id
            }
            let validateText:any =  this.validateUploadFiles(file, uploadDocuments.documentationList, child);
            let validateTextUpdated = this.validateUploadwithUpload(file.name, upDocuments, child);
            document.statusText = '';
            uploadDocuments.validateDocument = true;
            if(validateText !== ''){
                document.statusText = this.translate.instant(validateText);
                document.status = 'validate-error'
            }else if(validateTextUpdated !==''){
                file.statusText = this.translate.instant(validateTextUpdated);
                file.status = 'validate-error'
            }
            if(child.type === 'addNewDocumentsToFieldsInNewInstance'){
                for(let i = 0; i < uploadDocuments.documentsToFieldInNewConcept.length;i++){
                  let documentField: any =  uploadDocuments.documentsToFieldInNewConcept[i]
                    if(document.name === documentField.name && document.size === documentField.size){
                        uploadDocuments.documentsToFieldInNewConcept.splice(i, 1, file);
                    }
                }
            }
            for(let i = 0; i < uploadDocuments.upDocuments.length;i++){
                if(document.id === uploadDocuments.upDocuments[i].id){
                    uploadDocuments.upDocuments.splice(i, 1, file);
                }
            }
          }
      })
    }

    getDocumentsSelectedToUp(document: any, uploadDocuments: any){
      let upDocuments: any = [];
      uploadDocuments.upDocuments.forEach((documentUp: any) => {
        if(documentUp.id !== document.id){
          upDocuments.push(documentUp);
        }
      });
      return upDocuments;
    }

    changeName(originalFile:any, newName:any) {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    }
    ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
