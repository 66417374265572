import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { FieldTypeListParentService } from './../utils/field-type-list-parent.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'field-type-list-multiple',
  templateUrl: './field-type-list-multiple.component.html'
})

export class FieldTypeListMultipleComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = this.customLanding.isLanding();
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']

  constructor(
    private customLanding: CustomLandingFactory,
    private fieldTypeListParent: FieldTypeListParentService,
    private route: ActivatedRoute) {}

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeListParent.clickMenu(this);
    this.fieldTypeListParent.openWatchers(this)
  }

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
    let allDisabled:number = 0
    for(let i = 0; i < this.listValues?.length; i++){
       allDisabled += this.listValues[i].disabled? 1 : 0;
    }
    this.fieldTypeList.allDisabled = allDisabled === this.listValues?.length;
  }

  toggleAll(e:any){
    this.fieldTypeListParent.toggleAll(e, this);
  }

  catchEvent(e:any){
    e.stopPropagation();
  }

  createNewValue(e:any){
    this.fieldTypeListParent.createNewValue(e, this)
  }

  insertNewValue(e:any){
    this.fieldTypeListParent.insertNewValue(e, this);
  }

  editListValueElement(e: any, listValue:any){
    this.fieldTypeListParent.editListValueElement(e, listValue, this);
  }

  deleteListValue(e:any, listValue:any){
   this.fieldTypeListParent.deleteListValue(e, listValue, this)
  }

  aceptNotDelete(e:any, listValue:any){
    e.stopPropagation();
    listValue.deleteError = false;
  }

  resetListValue(e:any){
    this.fieldTypeListParent.resetListValue(e, this);
  }

  addOrDeleteSelectedValue(e:any, listValue:any){
    if(listValue.disabled){
        e.stopPropagation();
        e.preventDefault();
    }else if(this.field.uniqueLastSelect && listValue.children?.length){
        this.showChildren(e, listValue);
    }else{
        this.fieldTypeListParent.addOrDeleteSelectedValue(e, listValue, this);
    }
  }

  showChildren(e:any, listValue:any){
    e.preventDefault();
    e.stopPropagation();
    listValue.show = !listValue.show
  }

  changeList(){
    this.fieldTypeListParent.changeList(this);
  }

  showReference(e:any){
   this.fieldTypeListParent.showReference(e, this);
  }

  resetSearch(e:any){
    this.fieldTypeListParent.resetSearch(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeListParent.openModalRelatedInfo(fieldRelatedInfo, e, this);
  }

  treeMultipleOn(event: any){
    switch(event.type){
      case 'showChildren':
        this.showChildren(event.e, event.listValue)
        break;
      case 'addOrDeleteSelectedValue':
        this.addOrDeleteSelectedValue(event.e, event.listValue);
        break;
      case 'editListValue':
        this.fieldTypeListParent.editListValueElement(event.e, event.listValue, this);
        break;
      case 'deleteListValue':
        this.fieldTypeListParent.deleteListValue(event.e, event.listValue, this);
        break;
      case 'aceptNotDelete':
        this.aceptNotDelete(event.e, event.listValue);
        break;
    }
  }

  checkListValue(listValue:any){
    return listValue?.type?.toLowerCase()==='back' && !listValue.show
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
