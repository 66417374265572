<div class="report-table__table-row" >
  <button class="report-table__button-task"
    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
    (click)="relatedConcept()"
    [disabled]="row.OFAFMVJfield0.value === 0">
    <i class="lf-icon-task report-table__task"
        [ngShow]="row.OFAFMVJfield0.key==='tareas'"
        [ngClass]="{' report-table__task--with-task': rowType.value > 0}"></i>
    <i class="report-table__icon-numeric report-table__number"
        [ngClass]="{' report-table__number--zero': rowType.value > 0}">{{rowType.value}}</i>
  </button>
  <div *ngIf="rowType.fieldTypeId !== 'check'"
    class="report-table__hightlight">
    <span class="report-table__table-body-header report-table__table-body-header--report"
      *ngIf="rowType.key!=='tareas' && rowType.value !== 'check'"
      [ngStyle]="{'background-color': rowType.backgroundColor}"
      [ngClass]="{'report-table__colors': rowType.hasColor}">
      <span>
        {{rowType.fieldTypeId !== 'date'? rowType.originalValue : rowType.originalValue | reportExtractIso:column}}
      </span>
      <!-- <span *ngIf="rowType.fieldTypeId === 'date'">
          {{rowType.value | reportExtractIso:column}}
      </span>
      <div *ngIf="rowType.fieldTypeId !== 'date'">
        <div [id]="rowType.idUnique + '-' + rowType.key"
          class="report-table__col-text"
          [ngClass]="{'report-table__col-text--more-info': rowType.showMoreText}">
          <span [id]="'span-' + rowType.idUnique + '-' + rowType.key">{{rowType.value}}</span>
        </div>
        <div *ngIf="rowType.isHTMLDependencies && rowType.showDependencies">
          <span class="report-table__text-dependencies"
            [afTranslate]="'field-items.dependencies'">_Dependencias</span>
          <ul class="report-table__list-bullet">
            <li *ngFor="let dependency of rowType.elementsDependencies">{{dependency}}</li>
          </ul>
        </div>
        <span class="report-table__buttons-dependencies"
          *ngIf="rowType.isHTMLDependencies && (!rowType.showMoreInfo || rowType.showMoreText)"
          (click)="rowType.showDependencies = !rowType.showDependencies"
          [afTranslate]="rowType.showDependencies?'common.hide-dependencies':'common.see-dependencies'">Ver dependencias</span>
        <span class="report-table__buttons-dependencies"
          *ngIf="showTooltipHeight()"
          (click)="showMoreOrLess()"
          [afTranslate]="'report-table.show-more'">Ver mas</span>
        <span class="report-table__buttons-dependencies"
          *ngIf="rowType.showMoreText"
          (click)="showMoreOrLess()"
          [afTranslate]="'report-table.show-less'">Ver menos</span>
      </div> -->
    </span>
  </div>
  <i class="lf-icon-check-round-full report-table__icon-check"
    [ngShow]="rowType.value === 1 && (rowType.fieldTypeId === 'check' && rowType.hasOpacity)"
    [ngStyle]="{'color':rowType.backgroundIconColor}"></i>
</div>
