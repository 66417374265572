import { Component } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'dashboard-view',
  templateUrl: './dashboard-view.component.html'
})
export class DashboardViewComponent {
  skin: string = this.globalCfg.skin;
  constructor(private globalCfg: GlobalCfgFactory) { }

}
