<div class="legal-document"
  *ngIf="!isLanding">
  <div class="legal-document__list-container common__min-scroll">
      <ul class="legal-document__list">
          <li class="legal-document__list-item"
              *ngFor="let reference of references  | afOrderBy: ['numOrder']:false; let index = index"
              [ngClass]="{'selected': reference == legalDocument.selected }"
              (click)="selectReference(reference)">
              <i [class]="(reference.type | taskTypeIcons:'reference')"></i>
              <span [id]="'legal-document-list-'+index"
                class="legal-document__col-text"
                matTooltip="{{reference.text}}"
                matTooltipPosition="right"
                matTooltipClass="right"
                [matTooltipDisabled]="showTooltip('legal-document-list-'+index)">
                <span [id]="'span-legal-document-list-'+index">{{reference.text}}</span>
            </span>
          </li>
      </ul>
  </div>
  <div class="legal-document__container-right"
      id="legal-document-print">
      <div [class]="'legal-document__block-document-container legal-document__document-container common__min-scroll ' + legalDocument.referenceClass">
          <h2 class="common__underlined-title legal-document__no-uppercase"
              [ngShow]="legalDocument.selected.text">
              <i class="legal-document__icon-bookmarks lf-icon-bookmarks"></i>
              {{legalDocument.selected.text}}
          </h2>
          <div class="legal-document__body" [ngShow]="!legalDocument.error"
            [innerHTML]="legalDocument.selected?.document"></div>
          <div class="legal-document__error" [ngShow]="legalDocument.error">
              <i class="lf-icon-information"></i>
              <span class="process-modal__error-text">{{legalDocument.error}}</span>
          </div>
      </div>
  </div>
</div>

<div class="legal-document"
  *ngIf="isLanding">
  <div class="legal-document__list-container-custom">
      <ul class="legal-document__list-custom">
          <li class="legal-document__list-item legal-document__list-item--custom"
              *ngFor="let reference of references  | afOrderBy: ['numOrder']:false; let index = index"
              [ngClass]="{selected: reference == legalDocument.selected }"
              (click)="selectReference(reference)">
              <i [class]="(reference.type | taskTypeIcons:'reference')"></i>
              <span [id]="'legal-document-list-'+index"
                  class="legal-document__col-text"
                  matTooltip="{{reference.text}}"
                  matTooltipPosition="right"
                  matTooltipClass="right"
                  [matTooltipDisabled]="showTooltip('legal-document-list-'+index)">
                  <span [id]="'span-legal-document-list-'+index">{{reference.text}}</span>
              </span>
          </li>
      </ul>
  </div>
  <div class="legal-document__container-right-custom"
      id="legal-document-print">
      <div [class]="'legal-document__document-container legal-document__document-container-custom ' + legalDocument.referenceClass">
          <h2 class="legal-document__title-document-custom legal-document__no-uppercase"
              [ngShow]="legalDocument.selected.text">
              {{legalDocument.selected.text}}
          </h2>
          <div class="legal-document__body legal-document__body--custom"
            [ngShow]="!legalDocument.error"
            [innerHTML]="legalDocument.selected?.document"></div>
          <div class="legal-document__error" [ngShow]="legalDocument.error">
              <i class="lf-icon-information"></i>
              <span class="process-modal__error-text">{{legalDocument.error}}</span>
          </div>
      </div>
  </div>
</div>
