<div *ngIf="!isLanding">
  <label class="common__input-block"
    [for]="'field-type-other-'+field.id"
    [ngClass]="{'common__show-alert': field.error,
        'common__input-no-editable':field.noEditable || userPermissions ==='none',
        'common__input-block-open': fieldTypeOther.showList,
        'field-type-other__list-type':field.lcoIsUngroupedView}"
    [attr.disabled]="(field.noEditable || userPermissions ==='none')?true:null">
    <div class="common__label-block"
        [ngClass]="field.evaluatedValue?.hasReferences && !field.poll?'common__label-block--two-icon':'common__label-block--one-icon'">
        <span class="common__input-text"
            [ngClass]="{'common__input-text--required': field.error,
                    'common__input-text--no-editable':field.noEditable || userPermissions ==='none'}">{{fieldTypeOther.titleField}}</span>
        <span class="common__text-required"
            [ngShow]="field.required">*</span>
        <div class="common__info-icon-block"
            [ngHide]="field.noEditable || userPermissions ==='none' || !field.description">
            <tooltip-info [description]="field.description"
                [field]="field"></tooltip-info>
        </div>
        <div class="common__tooltip-law-block"
            [ngShow]="field.references?.length && !field.noEditable && userPermissions !=='none'">
            <tooltip-law [references]="field.references"
                [field]="field">
            </tooltip-law>
        </div>
        <div class="common__tooltip-law-block"
            [ngShow]="field.relatedInfo?.length && !field.poll && !field.noEditable && userPermissions !=='none'">
            <i class="lf-icon-search-text field-item__icon-related-info"
                (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                matTooltip="{{'common.related-info' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
        </div>
        <div class="common__info-icon-block common__info-icon-block--in-report"
            *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
            <tooltip-in-report [inReport]="fieldTypeOther.inReport"></tooltip-in-report>
        </div>
    </div>
    <div *ngIf="!field.multiple">
      <div class="common__dropdown-initial"
          ngbDropdown
          [open]="fieldTypeOther.showList"
          [autoClose]="'outside'">
          <button class="lf-icon-connected-data common__input-icon common__input-icon-active"
              [ngClass]="{'common__input-icon--active-no-editable':field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList}"
              [disabled]="field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList"
              ngbDropdownToggle></button>
          <input [id]="'field-type-other-'+field.id"
              class="common__input-content field-type-other__input-class"
              [ngClass]="{'common__input-content--no-name': fieldTypeOther.conceptSelected?.noName,
                'common__input-content-no-editable':field.noEditable || userPermissions ==='none',
                'common__input-content-alert': field.error}"
              type="text"
              [(ngModel)]="fieldTypeOther.conceptSelected.data"
              placeholder="{{'field-type-other.select-value' | afTranslate}}"
              readonly>
          <i class="lf-icon-close common__delete-content-icon"
              [ngShow]="fieldTypeOther.conceptSelected?.data"
              (click)="resetItemSelect()"></i>
          <ul class="dropdown-menu common__select-list common__select-list--clickable"
              ngbDropdownMenu>
              <li class="common__find-dropdown-block">
                  <div class="field-type-other__block-input-search"
                      [ngClass]="{'field-type-other__block-input-search--block-100':!field.sourceConcept.canAddNewInstance}">
                      <input class="common__find-in-dropdown"
                          [(ngModel)]="fieldTypeOther.findDropdown"
                          placeholder="{{'common.search-value'| afTranslate}}"
                          (click)="catchEvent($event)">
                      <i class="common__find-dropdown-icon-find"
                          [ngClass]="{'lf-icon-search':fieldTypeOther.findDropdown === '',
                            'lf-icon-close-search': fieldTypeOther.findDropdown !==''}"
                          (click)="resetSearch($event)"></i>
                  </div>
                  <i class="lf-icon-sum common__button-checked"
                      [ngShow]="field.sourceConcept.canAddNewInstance"
                      (click)="generateNew()"
                      matTooltip="{{fieldTypeOther.tooltipText}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </i>
              </li>
              <li class="field-type-other__list-block">
                  <div class="common__select-item common__select-item--whitout-padding"
                      *ngFor="let listValue of listValues | byWord:fieldTypeOther.findDropdown:['data'] | afOrderBy:['data']:false"
                      [ngHide]="listValue.objectid === concept?.conceptObjectId">
                      <div class="common__select-list-item-text"
                          [ngClass]="{'common__select-list-item-text--no-name': listValue.noName}"
                          (click)="selectConcept(listValue)">
                              {{listValue.data}}
                      </div>
                      <i class="lf-icon-check-round-full common__select-icon common__select-icon--select-list-check"
                          (click)="selectConcept(listValue)"></i>
                      <i class="lf-icon-visible common__select-icon common__select-icon--select-list-view"
                          [ngHide]="field.isClientField"
                          matTooltip="{{fieldTypeOther.tooltipList}}"
                          matTooltipPosition="left"
                          matTooltipClass="left"
                          (click)="viewRelatedConcept($event, listValue)"></i>
                  </div>
                  <div class="common__select-item-result-empty"
                      [ngHide]="listValues?.length">
                      <div class="common__select-item-empty">
                          <span [afTranslate]="'field-type-other.no-instances'"
                              class="field-type-other__text-no-instances">
                              No hay instancias creadas de este concepto</span>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
    </div>
    <div *ngIf="field.multiple">
      <div class="common__dropdown-initial common__dropdown-initial--flex"
          ngbDropdown
          [open]="fieldTypeOther.showList"
          [autoClose]="'outside'">
          <button class="lf-icon-connected-data common__input-icon common__input-icon-active"
              [ngClass]="{'common__input-icon--active-no-editable':field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList}"
              [disabled]="field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList"
              ngbDropdownToggle></button>
          <ul class="common__multiselect-block">
              <li class="common__multiselect-item"
                  [ngClass]="{'common__multiselect-item--no-name': listElement.noName}"
                  [ngStyle]="{width: fieldTypeOther.widthElement }"
                  *ngFor="let listElement of fieldTypeOther.listElementSelect">
                  <p class="common__multiselect-text field-type-list__block-text"
                      [ngClass]="{'field-type-list__block-text--no-name': listElement.noName}">{{listElement.data}}</p>
                  <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple"
                      [ngHide]="field.noEditable || userPermissions ==='none'"
                      (click)="addOrDeleteSelectedValue($event, listElement)"></i>
              </li>
          </ul>
          <ul class="dropdown-menu common__select-list common__select-list--clickable"
              ngbDropdownMenu>
              <li class="common__find-dropdown-block">
                  <div class="field-type-other__block-input-search"
                      [ngClass]="{'field-type-other__block-input-search--multiple': !field.isClientField && field.fieldTypeId === 'l-co'}">
                      <input class="common__find-in-dropdown"
                          [(ngModel)]="fieldTypeOther.findDropdown"
                          placeholder="{{'common.search-value'| afTranslate}}"
                          (click)="catchEvent($event)">
                      <i class="common__find-dropdown-icon-find"
                          [ngClass]="{'lf-icon-search':fieldTypeOther.findDropdown === '',
                              'lf-icon-close-search': fieldTypeOther.findDropdown !==''}"
                          (click)="resetSearch($event)"></i>
                  </div>
                  <i class="lf-icon-check common__button-checked"
                      [ngHide]="field.isClientField"
                      (click)="closeListItem()"></i>
                  <i class="lf-icon-sum common__button-checked"
                      [ngShow]="field.fieldTypeId === 'l-co'"
                      (click)="generateNew()"
                      matTooltip="{{fieldTypeOther.tooltipText}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </i>
              </li>
              <li class="field-type-other__list-block">
                  <div class="common__select-all common__select-all--other common__select-item--whitout-padding"
                      *ngIf="listValues.length > 10">
                      <div class="common__select-list-item-text"
                          (click)="toggleAll($event)">
                          <i class="field-item__icon-check"
                            [ngClass]="fieldTypeOther.markAll ? 'lf-icon-check-full' : 'lf-icon-box-inactive'"></i>
                          {{'common.toggle-all' | afTranslate}}
                      </div>
                      <i class="lf-icon-check-round-full common__select-icon common__select-icon--select-list-check"
                        (click)="toggleAll($event)"></i>
                  </div>
                  <div class="common__select-item common__select-item--whitout-padding"
                      *ngFor="let listValue of listValues | byWord:fieldTypeOther.findDropdown:['data']"
                      [ngHide]="listValue.objectid === concept?.conceptObjectId">
                      <div class="common__select-list-item-text"
                          [ngClass]="{'common__select-list-item-text--no-name': listValue.noName}"
                          (click)="addOrDeleteSelectedValue($event, listValue)">
                          <i class="field-item__icon-check"
                              [ngClass]="listValue.selected?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                          {{listValue.data}}
                      </div>
                      <i class="lf-icon-check-round-full common__select-icon common__select-icon--select-list-check"
                          (click)="addOrDeleteSelectedValue($event, listValue)"></i>
                      <i class="lf-icon-visible common__select-icon common__select-icon--select-list-view"
                          [ngHide]="field.isClientField"
                          matTooltip="{{fieldTypeOther.tooltipList}}"
                          matTooltipPosition="left"
                          matTooltipClass="left"
                          (click)="viewRelatedConcept($event, listValue)"></i>
                  </div>
              </li>
          </ul>
      </div>
    </div>
    <div class="field-item__blocker-box"
      *ngIf="field.error && field.blocker">
          <div class="field-item__blocker-triangle"></div>
          <i class="lf-icon-warning field-item__blocker-icon"></i>
          <p class="field-item__blocker-text"
            [afTranslate]="'field-items.blocker-field'">campo bloqueante
          </p>
      </div>
  </label>
  <div [ngClass]="{'field-type-other__label-block': fieldTypeOther.conceptSelected?.data}"
    [ngShow]="(fieldTypeOther.selectRelated?.length || fieldTypeOther.conceptSelected?.data) && !field.lcoIsUngroupedView">
    <div class="field-type-other__block-related-fields">
        <div class="field-type-other__related-list-block"
            [ngClass]="{'field-type-other__related-list-block--no-editable':field.noEditable || userPermissions ==='none'}">
            <div class="field-type-other__title-related-info">
                <div class="field-type-other__triangle-related-field"></div>
                <div class="field-type-other__triangle-related-field-border"
                    [ngClass]="{'field-type-other__triangle-related-field-border--no-editable':field.noEditable || userPermissions ==='none'}"></div>
                <div class="field-type-other__information"
                    [ngClass]="{'field-type-other__information--cursor-default':fieldTypeOther.icamUser}">
                    <p class="field-type-other__title-list field-type-other__title-list--multiple field-type-other__title-list">
                        <span [afTranslate]="'field-type-other.information'">INFORMACIÓN</span>
                        <i class="lf-icon-editable-form field-type-other__edit-icon"
                            (click)="viewRelatedConcept($event, fieldTypeOther.conceptSelected)"
                            [ngHide]="(field.noEditable || userPermissions ==='none')&& !fieldTypeOther.icamUser "></i>
                    </p>
                    <span-tooltip  class="field-type-other__name-related-field"
                      [ngClass]="{'field-type-other__name-related-field--no-name': fieldTypeOther.conceptSelected?.noName,
                          'common__input-content-no-editable':(field.noEditable || userPermissions ==='none') && !(fieldTypeOther.concept?.conceptNamedKey === 'Encuesta' && skin === 'icam-red') && !fieldTypeOther.icamUser}"
                      (click)="viewRelatedConcept($event, fieldTypeOther.conceptSelected)"
                      [text]="fieldTypeOther.conceptSelected?.data"></span-tooltip>
                </div>
            </div>
            <ul class="field-type-other__related-fields-block-list">
                <li class="common__related-field-item field-type-other__list-info"
                    *ngFor="let related of fieldTypeOther.selectRelated">
                    <p class="field-type-other__related-title">
                        <span >{{related.label}}</span>:</p>
                    <p class="field-type-other__related-text">{{related.data}}</p>
                </li>
                <li class="field-type-other__detail-info field-type-other__relationship-block"
                    [ngShow]="fieldTypeOther.sameConcept">
                    <p class="field-type-other__relationship-text">
                        <span [afTranslate]="'field-type-other.relationship-reason'"></span>:
                    </p>
                    <i class="lf-icon-marker field-type-other__relationship-icon"
                        (click)="openRelationshipModal(fieldTypeOther.listOtherMultipleIndex)"></i>
                    <p class="field-type-other__relationship-observation">
                        {{fieldTypeOther.relationshipList[fieldTypeOther.listOtherMultipleIndex]}}
                    </p>
                </li>
            </ul>
        </div>
        <div class="field-type-other__options-block"
            [ngShow]="field.multiple">
            <i class="lf-icon-angle-right field-type-other__icon-nav field-type-other__icon-nav--next"
                (click)="goTo('next')"
                [ngClass]="fieldTypeOther.listElementSelect?.length - 1 > fieldTypeOther.listOtherMultipleIndex?'field-type-other__icon-nav--enabled':'field-type-other__icon-nav--disabled'"></i>

            <div class="field-type-other__number-icon">{{fieldTypeOther.listOtherMultipleIndex + 1}} </div>
            <i class="lf-icon-angle-left field-type-other__icon-nav field-type-other__icon-nav--prev"
                (click)="goTo('prev')"
                [ngClass]="fieldTypeOther.listOtherMultipleIndex !== 0?'field-type-other__icon-nav--enabled':'field-type-other__icon-nav--disabled'"></i>
        </div>
    </div>
  </div>

  <div class="field-type-other__block-related-fields"
    *ngFor="let related of fieldTypeOther.relatedList; let index = index"
    [ngShow]="field.lcoIsUngroupedView">
    <div class="field-type-other__related-list-block"
        [ngClass]="{'field-type-other__related-list-block--no-editable':field.noEditable || userPermissions ==='none'}">
        <div class="field-type-other__title-related-info ">
            <div class="field-type-other__triangle-related-field"></div>
            <div class="field-type-other__triangle-related-field-border"
                [ngClass]="{'field-type-other__triangle-related-field-border--no-editable':field.noEditable || userPermissions ==='none'}"></div>
            <div class="field-type-other__information">
                <p class="field-type-other__title-list field-type-other__title-list--multiple">
                    <span [afTranslate]="'field-type-other.information'">INFORMACIÓN</span>
                    <i class="lf-icon-editable-form field-type-other__edit-icon"
                        (click)="viewRelatedConcept($event, fieldTypeOther.listElementSelect[index])"
                        [ngShow]="!field.noEditable || userPermissions ==='none'"></i>
                </p>
                <span-tooltip  class="field-type-other__name-related-field"
                    [ngClass]="{'field-type-other__name-related-field--no-name': related.noName,
                        'common__input-content-no-editable':field.noEditable || userPermissions ==='none'}"
                    (click)="viewRelatedConcept($event, fieldTypeOther.listElementSelect[index])"
                    [text]="fieldTypeOther.listElementSelect[index]?.data"></span-tooltip>
            </div>
        </div>
        <ul class="field-type-other__related-fields-block-list">
            <li class="common__related-field-item field-type-other__list-info"
                *ngFor="let selectRelated of related">
                <p class="field-type-other__related-title">
                    <span >{{selectRelated.label}}</span>:</p>
                <p class="field-type-other__related-text">{{selectRelated.data}}</p>
            </li>
            <li class="field-type-other__detail-info field-type-other__relationship-block"
                [ngShow]="fieldTypeOther.sameConcept">
                <p class="field-type-other__relationship-text">
                    <span [afTranslate]="'field-type-other.relationship-reason'"></span>:
                </p>
                <i class="lf-icon-marker field-type-other__relationship-icon"
                    (click)="openRelationshipModal(index)"></i>
                <p class="field-type-other__relationship-observation">
                    {{fieldTypeOther.relationshipList[index]}}
                </p>
            </li>
        </ul>
    </div>
  </div>
</div>
<div *ngIf="isLanding"
  class="field-type-other__custom">
  <label class="common__input-block common__input-block--custom-list"
      [for]="'field-type-other-'+field.id"
      [ngClass]="{'common__input-block--open-custom': fieldTypeOther.showList,
        'common__input-no-editable':field.noEditable,
        'common__show-alert': field.error,
        'field-type-other__list-type':field.lcoIsUngroupedView}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__label-block"
          [ngClass]="field.evaluatedValue.hasReferences && !field.poll?'common__label-block--two-icon':'common__label-block--one-icon'">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                      'common__input-text--no-editable':field.noEditable || userPermissions ==='none'}">{{fieldTypeOther.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__info-icon-block"
              [ngHide]="field.noEditable || !field.description">
              <tooltip-info [description]="field.description"
                  [field]="field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="field.references.length && !field.noEditable && userPermissions !=='none'">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="field.relatedInfo.length && !field.poll && !field.noEditable && userPermissions !=='none'">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate }}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
      </div>
      <div *ngIf="!field.multiple">
        <div class="common__dropdown-initial"
            ngbDropdown
            [open]="fieldTypeOther.showList"
            [autoClose]="'outside'">
            <button class="lf-icon-connected-data common__input-icon common__input-icon-active"
                [ngClass]="{'common__input-icon--active-no-editable':field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList}"
                [disabled]="field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList"
                ngbDropdownToggle></button>
            <input [id]="'field-type-other-'+field.id"
                [name]="'field-type-other-'+field.id"
                class="common__input-content common__select-readonly field-type-other__input-class"
                [ngClass]="{'common__input-content--no-name': fieldTypeOther.conceptSelected?.noName,
                  'common__input-content-no-editable':field.noEditable || userPermissions ==='none',
                  'common__input-content-alert': field.error}"
                type="text"
                [(ngModel)]="fieldTypeOther.conceptSelected.data"
                placeholder="{{'field-type-other.select-value' | afTranslate}}"
                readonly>
            <i class="lf-icon-close-round common__delete-content-icon"
                [ngShow]="fieldTypeOther.conceptSelected.data"
                (click)="resetItemSelect()"></i>
            <ul class="dropdown-menu common__select-list common__select-list--clickable"
                ngbDropdownMenu>
                <li class="common__block-input-custom common__block-input-custom--black">
                    <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                        <input class="common__find-in-dropdown common__find-in-dropdown--custom-list"
                            [(ngModel)]="fieldTypeOther.findDropdown"
                            placeholder="{{'common.search-value'| afTranslate}}"
                            (click)="catchEvent($event)">
                        <i class="common__find-dropdown-icon-find"
                            [ngClass]="{'lf-icon-search':fieldTypeOther.findDropdown === '',
                                'lf-icon-close-search': fieldTypeOther.findDropdown !==''}"
                            (click)="resetSearch($event)"></i>
                    </div>
                </li>
                <li class="field-type-other__list-block">
                    <div class="common__select-item-custom"
                        *ngFor="let listValue of listValues | byWord:fieldTypeOther.findDropdown:['data']"
                        (click)="selectConcept(listValue)">
                        <div class="common__select-list-item-text-custom"
                            [ngClass]="{'common__select-list-item-text-custom--no-name': listValue.noName}">
                              {{listValue.data}}
                        </div>
                        <i class="lf-icon-check-round common__select-icon common__select-icon--select-list-check"></i>
                    </div>
                    <div class="common__select-item-result-empty"
                        [ngHide]="listValues.length">
                        <div class="common__select-item-empty">
                            <span class="field-type-other__text-no-instances"
                              [afTranslate]="'field-type-other.no-instances'">_No hay instancias creadas de este concepto</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      </div>
      <div *ngIf="field.multiple">
        <div class="common__dropdown-initial common__dropdown-initial--flex"
            ngbDropdown
            [open]="fieldTypeOther.showList"
            [autoClose]="'outside'">
            <button class="lf-icon-connected-data common__input-icon common__input-icon-active"
                [ngClass]="{'common__input-icon--active-no-editable':field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList}"
                [disabled]="field.noEditable || userPermissions ==='none' || !fieldTypeOther.chargedList"
                ngbDropdownToggle></button>
            <ul class="common__multiselect-block">
                <li class="field-type-list-extended__item-selected-multi-custom"
                    [ngClass]="{'field-type-list-extended__item-selected-multi-custom--error': listElement.noName}"
                    [ngStyle]="{width: fieldTypeOther.widthElement }"
                    *ngFor="let listElement of fieldTypeOther.listElementSelect">
                    <span-tooltip class="common__multiselect-text field-type-list__block-text"
                        [ngClass]="{'field-type-list__block-text--error': listElement.noName}"
                        [text]="listElement.data"></span-tooltip>
                    <i class="lf-icon-close-round common__multiselect-delete-icon field-type-list-extended__icon-close-custom"
                        (click)="addOrDeleteSelectedValue($event, listElement)"></i>
                </li>
            </ul>
            <ul class="dropdown-menu common__select-list common__select-list--clickable"
                ngbDropdownMenu>
                <li class="common__block-input-custom common__block-input-custom--black">
                    <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                        <input class="common__find-in-dropdown common__find-in-dropdown--custom-list"
                            [(ngModel)]="fieldTypeOther.findDropdown"
                            placeholder="{{'common.search-value'| afTranslate}}"
                            (click)="catchEvent($event)">
                        <i class="common__find-dropdown-icon-find"
                            [ngClass]="{'lf-icon-search':fieldTypeOther.findDropdown === '',
                                'lf-icon-close-search': fieldTypeOther.findDropdown !==''}"
                            (click)="resetSearch($event)"></i>
                    </div>
                </li>
                <li class="field-type-other__list-block">
                    <div class="common__select-all-custom"
                        *ngIf="listValues.length > 10"
                        (click)="toggleAll($event)">
                        <div class="common__select-list-item-text-custom">
                            <i class="field-item__icon-check"
                              [ngClass]="fieldTypeOther.markAll ? 'lf-icon-check-full' : 'lf-icon-box-inactive'"></i>
                              {{'common.toggle-all' | afTranslate}}
                        </div>
                        <i class="lf-icon-check-round common__select-icon common__select-icon--select-list-check"></i>
                    </div>
                    <div class="common__select-item-custom"
                        *ngFor="let listValue of listValues | byWord:fieldTypeOther.findDropdown:['data'] | afOrderBy:['data']:false"
                        (click)="addOrDeleteSelectedValue($event, listValue)">
                        <div class="common__select-list-item-text-custom"
                            [ngClass]="{'common__select-list-item-text-custom--no-name': listValue.noName}">
                            <i class="field-item__icon-check"
                              [ngClass]="listValue.selected?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                              {{listValue.data}}
                        </div>
                        <i class="lf-icon-check-round common__select-icon common__select-icon--select-list-check"></i>
                    </div>
                </li>
            </ul>
        </div>
      </div>
  </label>
  <div [ngClass]="{'field-type-other__label-block-custom': fieldTypeOther.conceptSelected.data}"
      [ngShow]="(fieldTypeOther.selectRelated.length || fieldTypeOther.conceptSelected.data) && !field.lcoIsUngroupedView">
      <div class="field-type-other__info-block-custom">
          <div class="field-type-other__related-list-block"
              [ngClass]="{'field-type-other__related-list-block--no-editable':field.noEditable || userPermissions ==='none'}">
              <div class="field-type-other__title-related-info field-type-other__title-related-info--custom">
                  <div class="field-type-other__information">
                          <p class="field-type-other__title-list-custom"
                              [afTranslate]="'field-type-other.information'">INFORMACIÓN</p>
                          <span-tooltip class="field-type-other__name-related-field-custom"
                              [ngClass]="{'field-type-other__name-related-field-custom--no-name': fieldTypeOther.conceptSelected?.noName,
                                  'common__input-content-no-editable':field.noEditable || userPermissions ==='none'}"
                              [text]="fieldTypeOther.conceptSelected?.data"></span-tooltip>
                  </div>
              </div>
              <div class="field-type-other__block-list-custom">
                  <ul class="field-type-other__related-fields-block-list-custom">
                      <li class="common__related-field-item field-type-other__list-info-custom"
                          *ngFor="let related of fieldTypeOther.selectRelated">
                          <p class="field-type-other__related-title-custom" >
                              <span >{{related.label}}</span>:</p>
                          <p class="field-type-other__related-text-custom">{{related.data}}</p>
                      </li>
                      <li class="field-type-other__detail-info field-type-other__relationship-block"
                          [ngShow]="fieldTypeOther.sameConcept">
                          <p class="field-type-other__relationship-text">
                              <span [afTranslate]="'field-type-other.relationship-reason'"></span>:
                          </p>
                          <i class="lf-icon-marker field-type-other__relationship-icon"
                              (click)="openRelationshipModal(fieldTypeOther.listOtherMultipleIndex)"></i>
                          <p class="field-type-other__relationship-observation">
                              {{fieldTypeOther.relationshipList[fieldTypeOther.listOtherMultipleIndex]}}
                          </p>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="field-type-other__options-block-custom"
              [ngShow]="field.multiple">
              <i class="lf-icon-angle-right field-type-other__icon-nav-custom"
                  (click)="goTo('next')"
                  [ngClass]="fieldTypeOther.listElementSelect.length - 1 > fieldTypeOther.listOtherMultipleIndex?'field-type-other__icon-nav-custom--enabled':'field-type-other__icon-nav-custom--disabled'"></i>
              <div class="common__bubble-count-custom">
                  {{fieldTypeOther.listOtherMultipleIndex + 1}}
              </div>
              <i class="lf-icon-angle-left field-type-other__icon-nav-custom"
                  (click)="goTo('prev')"
                  [ngClass]="fieldTypeOther.listOtherMultipleIndex !== 0?'field-type-other__icon-nav-custom--enabled':'field-type-other__icon-nav-custom--disabled'"></i>
          </div>
      </div>
  </div>
  <div *ngFor="let related of fieldTypeOther.relatedList; let index = index"
      [ngShow]="field.lcoIsUngroupedView">
      <div class="field-type-other__info-block-custom">
          <div class="field-type-other__related-list-block"
              [ngClass]="{'field-type-other__related-list-block--no-editable':field.noEditable || userPermissions ==='none'}">
              <div class="field-type-other__title-related-info field-type-other__title-related-info--custom">
                  <div class="field-type-other__information">
                          <p class="field-type-other__title-list-custom"
                              [afTranslate]="'field-type-other.information'">INFORMACIÓN</p>
                          <span-tooltip class="field-type-other__name-related-field-custom"
                              [ngClass]="{'field-type-other__name-related-field-custom--no-name': fieldTypeOther.conceptSelected?.noName,
                                  'common__input-content-no-editable':field.noEditable || userPermissions ==='none'}"
                              [text]="fieldTypeOther.listElementSelect[index]?.data"></span-tooltip>
                  </div>
              </div>
              <div class="field-type-other__block-list-custom">
                  <ul class="field-type-other__related-fields-block-list-custom">
                      <li class="common__related-field-item field-type-other__list-info-custom"
                          *ngFor="let selectRelated of related">
                          <p class="field-type-other__related-title-custom" >
                              <span>{{selectRelated.label}}</span>:</p>
                          <p class="field-type-other__related-text-custom">{{selectRelated.data}}</p>
                      </li>
                      <li class="field-type-other__detail-info field-type-other__relationship-block"
                          [ngShow]="fieldTypeOther.sameConcept">
                          <p class="field-type-other__relationship-text">
                              <span [afTranslate]="'field-type-other.relationship-reason'"></span>:
                          </p>
                          <i class="lf-icon-marker field-type-other__relationship-icon"
                              (click)="openRelationshipModal(index)"></i>
                          <p class="field-type-other__relationship-observation">
                              {{fieldTypeOther.relationshipList[index]}}
                          </p>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
