import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'automatic-post-process-modal',
  templateUrl: './automatic-post-process-modal.component.html'
})
export class AutomaticPostProcessModalComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private translate:AfTranslateFactory
  ) { }

  @Input() concept:any;
  @Input() item:any;

  skin:string = this.globalCfg.skin;
  automaticPostProcessModal:any = {
    processSteps: {
      id: 'add-alert-states',
      label: this.translate.instant('add-alert.process-state'),
      required: true
    }
  };

  ngOnInit(){
    this.getStates();
  }

  getStates() {
    this.apiService.get('processes', this.concept.conceptId + '/states').then(
      (data:any) => {
        this.configureList(this.commonUtilsService.editList(data, 'stateId', 'name'));
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  configureList(processStates:any){
    for(let automaticProcess of this.concept.automaticProcess){
        for(let processList of processStates){
            if(automaticProcess.stateId === processList.stateId && this.item.stateId !== processList.stateId){
                processList.disabled = true;
                break;
            }
        }
    }
    this.automaticPostProcessModal.processStates = processStates;
  }

  itemSelected(type:string, e:any){
    this.item[type] = e.newValue;
    if(type === 'stateId'){
        this.automaticPostProcessModal.processSteps.error = false;
    }
  }

  validateProcess(){
      if(this.item.stateId){
          this.handleAccept();
      }else{
          this.automaticPostProcessModal.processSteps.error = true;
      }
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      stateId: this.item.stateId
    });
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
