<div class="record-audio-modal">
  <div class="common__block-icon-close-modal-custom">
    <button *ngIf="!recordAudioModal.applyChanges && (!recordAudioModal.isRecording || recordAudioModal.secondsRecord >= 10)"
        class="lf-icon-close-round common__icon-close-modal-custom"
        (click)="handleDismiss()"></button>
  </div>
  <div class="common__modal-header-block common__modal-header-block--custom-margin"
      [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}">
      <i class="lf-icon-microphone common__icon-header common__icon-header--custom" ></i>
      <span class="common__text-header common__text-header--custom"
          [afTranslate]="'record-audio-modal.title'"></span>
  </div>
  <div class="record-audio-modal__block-body">
    <div class="share-instance__block-title share-instance__block-title--landing">
      <p class="record-audio-modal__description-top-landing"
          [afTranslate]="'record-audio-modal.description'">_El mensaje grabado se transcribirá en el editor de texto y el archivo de audio quedará adjunto.
          Ten en cuenta que solo se podrá adjuntar un único audio. Una grabación nueva sustituirá a la anterior.</p>
    </div>
    <div class="record-audio-modal__record-box record-audio-modal__record-box--landing"
      [ngClass]="{'record-audio-modal__record-box--apply-distorsion':recordAudioModal.applyChanges,
      'record-audio-modal__record-box--show-disclaimer':recordAudioModal.exceededTime && recordAudioModal.applyChanges,
      'record-audio-modal__record-box--no-discard-landing':!recordAudioModal.showDiscard}">
       <div *ngIf="!recordAudioModal.applyChanges">
        <div class="record-audio-modal__distortion-block">
          <label class="record-audio-modal__label-check-landing">
            <i class="record-audio-modal__accept-terms-icon record-audio-modal__accept-terms-icon--landing"
              [ngClass]="{'record-audio-modal__accept-terms-icon--landing-no-editable':recordAudioModal.isRecording || recordAudioModal.showPlay,
                'lf-icon-check-full': recordAudioModal.acceptDistorsion,
                'lf-icon-box-inactive': !recordAudioModal.acceptDistorsion}"
                (click)="acceptDistorsionTerms()"></i>
            <p class="record-audio-modal__accept-terms-text record-audio-modal__accept-terms-text--landing"
              [ngClass]="{'record-audio-modal__accept-terms-text--landing-no-editable':recordAudioModal.isRecording || recordAudioModal.showPlay}"
              [afTranslate]="'record-audio-modal.voice-distorsion'">
              _Distorsionar voz
            </p>
          </label>
        </div>
        <div class="record-audio-modal__record-button-block">
          <button *ngIf="!recordAudioModal.showPlay"
            class="record-audio-modal__record-button"
            [ngStyle]="{'background-color': recordAudioModal.isRecording && !recordAudioModal.showPlay && recordAudioModal.secondsRecord < 10? '#AAAEB0' : custom.color}"
            [ngClass]="{'record-audio-modal__record-button--disabled-landing': recordAudioModal.isRecording && !recordAudioModal.showPlay && recordAudioModal.secondsRecord < 10}"
            (click)="recVoice()">
            <i class=" record-audio-modal__record-button-icon"
              [ngClass]="{'lf-icon-microphone': !recordAudioModal.isRecording && !recordAudioModal.showPlay,
                'lf-icon-stop':recordAudioModal.isRecording && !recordAudioModal.showPlay}"
              [ngStyle]="{'color':custom.textColor}"></i>
          </button>

          <button *ngIf="recordAudioModal.showPlay"
            class="record-audio-modal__record-button"
            [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}"
            (click)="playAudio()">
              <i class=" record-audio-modal__record-button-icon"
                [ngClass]="recordAudioModal.showPlay && !recordAudioModal.play?'lf-icon-play' : 'lf-icon-stop'"></i>
          </button>
        </div>
        <p class="record-audio-modal__record-button-text record-audio-modal__record-button-text--landing"
          *ngIf="recordAudioModal.blobName ===''">
          {{!recordAudioModal.isRecording && !recordAudioModal.showPlay?('record-audio-modal.press-to-talk' | afTranslate) : ('record-audio-modal.stop-record' | afTranslate)}}
        </p>

        <p class="record-audio-modal__record-button-text record-audio-modal__record-button-text--landing"
          *ngIf="recordAudioModal.blobName !==''">
          {{recordAudioModal.blobName}}
        </p>

        <div class="record-audio-modal__time-record-box">
          <p class="record-audio-modal__record-count record-audio-modal__record-count--landing"
            [ngClass]="{'record-audio-modal__record-count--landing-active':recordAudioModal.isRecording}">{{recordAudioModal.recordedTime}}</p>
          <p class="record-audio-modal__record-max-time record-audio-modal__record-max-time--landing">{{recordAudioModal.maxTime}}</p>
        </div>
        <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--landing">
          <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--landing-active"
            [progressBar]="recordAudioModal.percentTime">
          </div>
        </div>
        <button *ngIf="recordAudioModal.showDiscard"
          class="record-audio-modal__discard-voice-button record-audio-modal__discard-voice-button--landing"
          [afTranslate]="'record-audio-modal.discard-record'"
          (click)="discardVoiceRecord()">
          _Descartar Grabacion
        </button>
      </div>
      <div *ngIf="recordAudioModal.applyChanges">
        <div class="record-audio-modal__animation record-audio-modal__animation--landing">
          <i class="lf-icon-refresh record-audio-modal__apply-changes-icon"
          [ngStyle]="{'color':custom.color}"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="record-audio-modal__warning-box record-audio-modal__warning-box--landing"
    *ngIf="recordAudioModal.exceededTime && recordAudioModal.applyChanges">
    <i class="lf-icon-warning record-audio-modal__warning-icon record-audio-modal__warning-icon--landing"></i>
    <p class="record-audio-modal__warning-text"
      [afTranslate]="'record-audio-modal.excceded-time'">
    </p>
  </div>
  <div class="record-audio-modal__buttons-block"
    [ngClass]="{'record-audio-modal__buttons-block--warning-box': recordAudioModal.exceededTime && recordAudioModal.applyChanges}">
    <button type="reset"
      *ngIf="!recordAudioModal.applyChanges && (!recordAudioModal.isRecording || recordAudioModal.secondsRecord >= 10)"
      class="common__button-landing-custom common__button-landing-custom--cancel"
      [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
      (click)="handleDismiss()"
      [afTranslate]="'common.cancel'">_Cancelar</button>
    <button type="submit"
      *ngIf="recordAudioModal.showPlay"
      class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
      [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
      (click)="handleAccept()"
      [afTranslate]="'common.confirm'">_Confirmar</button>
  </div>
</div>
