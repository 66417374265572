import $ from 'jquery';

import { Injectable } from '@angular/core';
import { GlobalCfgFactory } from '../global-cfg/global-cfg.factory';
import { ContextFactory } from '../context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class OpenChatFactory {
  hasScript:boolean = false;
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory) { }

  launchScript(){
      if(!this.hasScript && (this.globalCfg.skin === 'dark-blue') && sessionStorage.getItem('landing') !== 'true' ){
          let src = window.location.href.indexOf('affin') !== -1? "https://led-dev-chatattcliente-dev.eu.els.local/js/lefebvrechatservice.js": "https://chat-attcliente.lefebvre.es/js/lefebvrechatservice.js"
          $('body').append( '<script id="lf-chatservice-lefebvre" data-bt="0" data-jq="1" data-o="centinela" data-u="'+this.context.user.externalId+'" data-s="Chat_SAC_CENTINELA" src="'+src+'"></script>');
          this.hasScript = true;
      }else if(this.hasScript){
          $('#chat-att-cliente-lefebvre').show();
      }
  };
}
