<div class="users-admin-new-wizard">
  <div>
      <h2 class="users-admin-new-wizard__title">
          <i class="{{usersAdminNewWizard.icon}} users-admin-new-wizard__icon-title"
              [ngClass]="{'users-admin-new-wizard__group-icon': type==='group'}"></i>
          <span>{{usersAdminNewWizard.title}}</span>
          <div class="users-admin-new-wizard__subtitle"
              [ngShow]="operation==='convert'"
              [afTranslate]="'users-admin.convert.title2'"></div>
      </h2>
      <wizard-steps class="users-admin-new-wizard__block-steps"
          [steps]="usersAdminNewWizard.wizardSteps"
          [state]="usersAdminNewWizard.stepState"></wizard-steps>
      <button class="lf-icon-close users-admin-new-wizard__close-link"
          [routerLink]="['/users']"
          routerLinkActive="active">
      </button>

  </div>
   <div class="users-admin-new-wizard__content"
      *ngIf="type !== 'group'"
      [ngShow]="usersAdminNewWizard.stepState === 1">
      <users-admin-person-form [person]="usersAdminNewWizard.model"
          [operation]="operation"
          (validateForm)="changeStep(2)">
      </users-admin-person-form>
  </div>
  <div class="users-admin-new-wizard__content"
      *ngIf="type === 'group'"
      [ngShow]="usersAdminNewWizard.stepState === 1">
      <users-admin-group-form [group]="usersAdminNewWizard.model"
          [operation]="operation"
          (validateForm)="changeStep(2)">
      </users-admin-group-form>
  </div>
  <div class="users-admin-new-wizard__content"
      [ngShow]="usersAdminNewWizard.stepState === 2">
      <users-admin-security-form [operation]="operation"
          (saveSecurity)="save($event.security)">
      </users-admin-security-form>
  </div>
  <div class="users-admin-new-wizard__buttons">
      <button class="common__button common__button--clean"
          [routerLink]="['/users']"
          routerLinkActive="active"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button class="common__button common__button--clean"
          (click)="changeStep(1)"
          [ngShow]="usersAdminNewWizard.stepState === 2"
          [afTranslate]="'common.previous'">Anterior</button>
      <button class="common__button common__button--clean"
          (click)="reset()"
          [afTranslate]="'common.clean'">Limpiar</button>
      <button class="common__button common__button--active"
          (click)="next()"
          [ngShow]="usersAdminNewWizard.stepState === 1"
          [afTranslate]="'common.next'">Siguiente</button>
      <button class="common__button common__button--active"
          (click)="saveController()"
          [ngShow]="usersAdminNewWizard.stepState === 2"
          [afTranslate]="'common.save'">Guardar</button>
  </div>
</div>
