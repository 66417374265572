<div class="common__field-type">
    <label class="common__input-block field-type-onefile__input-block"
        (click)="labelClicked($event)"
        [ngClass]="{'common__input-block--show-references': fieldTypeOnefile.references && field.evaluatedValue.conceptDataId !== null,
            'common__show-alert': field.error,
            'common__input-no-editable':field.noEditable}"
        [for]="fieldTypeOnefile.fieldId"
        [attr.disabled]="field.noEditable?true:null">
        <div [class]="'common__label-block '+fieldTypeOnefile.classWidthField">
            <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error,
                        'common__input-text--no-editable':field.noEditable}">{{fieldTypeOnefile.titleField}}</span>
            <span class="common__text-required"
                [ngShow]="field.required">*</span>
            <div class="common__info-icon-block"
                [ngHide]="field.noEditable || !field.description">
                <tooltip-info [description]="field.description"
                    [field]="field">
                </tooltip-info>
            </div>
            <div class="common__tooltip-law-block"
                [ngShow]="field.references && field.references.length && !field.noEditable">
                <tooltip-law [references]="field.references"
                    [field]="field">
                </tooltip-law>
            </div>
        </div>
        <div class="float-end">
            <i class="lf-icon-connected-data field-type-text__icon-references"
               *ngIf="field.evaluatedValue && field.evaluatedValue.hasReferences"
               matTooltip="{{'common.see-dependencies' | afTranslate }}"
               matTooltipPosition="left"
               matTooltipClass="left">
            </i>
            <i class="lf-icon-rename common__input-icon common__input-icon-active field-item__rename"
               [ngClass]="{'common__input-icon-active--disabled':field.noEditable}"
               *ngIf="field.allowRename"
               (click)="rename($event)"
               matTooltip="{{'common.rename' | afTranslate }}"
               matTooltipPosition="left"
               matTooltipClass="left">
            </i>
            <i class="lf-icon-up-file common__input-icon common__input-icon-active field-item__up-file"
               [ngClass]="{'common__input-icon-active--disabled':field.noEditable}"></i>
        </div>
        <input type="file"
                class="field-type-onefile__input-file"
                [id]="fieldTypeOnefile.fieldId"
                [name]="fieldTypeOnefile.fieldId"
                [accept]="field.accept"
                (change)="fileChanged($event)"
                [disabled]="field.noEditable">
        <input type="text"
            readonly="readonly"
            class="common__input-content"
            [(ngModel)]="fileInfo.name">

        <div class="field-item__blocker-box"
            *ngIf="field.error && field.blocker">
            <div class="field-item__blocker-triangle "></div>
            <i class="lf-icon-warning field-item__blocker-icon"></i>
            <p class="field-item__blocker-text "
                [afTranslate]="'field-items.blocker-field'">campo bloqueante</p>
        </div>
    </label>
  </div>
