<div class="report-table__header-icam">
    <div class="report-table__header-left-icam"></div>
    <div class="report-table__header-center-icam"></div>
    <div class="report-table__header-right-icam">
        <div class="report-table__header-button-icam"
            (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngHide]="reportTable.optionSelect === 'graph'">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-upper-icam">
            <div class="report-table__tcell-icam report-table__tcell-icam--first report-table__tcell-icam--year"
                ngbDropdown>
                <span>{{reportTable.currentIcamYear}}</span>
                <i class="lf-icon-angle-down report-table__tcell-icon-icam"
                    ngbDropdownToggle>
                </i>
                <ul class="report-table__dropdown-menu-icam"
                    ngbDropdownMenu>
                    <li class="report-table__dropdown-item-icam"
                        *ngFor="let year of reportTable.listYears"
                        (click)="selectIcamYear(year)">
                        <span>{{year}}</span>
                        <i class="lf-icon-check-round-full report-table__dropdown-icon-icam"></i>
                    </li>
                </ul>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['consultas']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['ijd']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['bajoDemanda']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['formacion']}}
            </div>
        </div>
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                <filter-by-list class="report-table__filter-list-class"
                                [type]="'list'"
                                [item]="reportTable.monthNameFilter"
                                [list]="reportTable.monthNameList"
                                (filterElementEmit)="selectTypeOperation($event, {id:'monthName'})">
                </filter-by-list>
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.categoria-servicio.consultas'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.categoria-servicio.generalista'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.categoria-servicio.demanda'">
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last"
                [afTranslate]="'report-table.icam.categoria-servicio.formacion'">
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let row of reportTable.reportsListFilter | afOrderBy: 'month':false; let last = last">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                {{row.monthName}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.consultas}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.ijd}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.bajoDemanda}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                {{row.formacion}}
            </div>
        </div>
    </div>
</div>
