import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'states-task-help-modal',
  templateUrl: './states-task-help-modal.component.html'
})
export class StatesTaskHelpModalComponent implements OnInit {
  constructor(private customLanding: CustomLandingFactory,
    private router:ActivatedRoute,
    private activeModal:NgbActiveModal) { }
  custom: any = this.customLanding.getCustom();
  isLanding: boolean = this.customLanding.isLanding();
  language: any = sessionStorage.getItem('selectLanguage');
  statesTaskHelpModal:any = {
    image: '../../assets/img/' + (this.isLanding ? 'custom' : 'flow') + '-states-task-' + this.language?.substring(0, this.language.indexOf('-')) + '.png'
  };

  ngOnInit(){
    // let language = sessionStorage.getItem('selectLanguage');
    // this.statesTaskHelpModal.image = '../../assets/img/' + (this.isLanding ? 'custom' : 'flow') + '-states-task-' + language?.substring(0, language.indexOf('-')) + '.png';
  }

  handleDismiss(){
    this.activeModal.close({result: 'cancel'});
  }
}
