<div class="icam-access-landing">
  <h1>Entrada ICAM-Centinela</h1>
  <div class="icam-access-landing__form">
      <p>
          <label>Token
            <input id="token"
                type="text"
                required
                [(ngModel)]="icamAccessLanding.token">
          </label>
      </p>
      <p>
          <label>Id Usuario
            <input id="username"
              type="text"
              required
              [(ngModel)]="icamAccessLanding.username">
          </label>
      </p>
      <p>
          <label>Timestamp
            <input id="timestamp"
              type="text"
              required
              [(ngModel)]="icamAccessLanding.timestamp">
          </label>
      </p>
      <p>
          <label>Secret
            <input id="secret"
              type="text"
              required
              [(ngModel)]="icamAccessLanding.secret">
          </label>
          <span class="advice">Must match web.config </span>
      </p>
      <p>
          <label>
              Perfil
              <select id="perfil" size="1">
                  <option *ngFor="let perfil of icamAccessLanding.perfilList"
                    (click)="icamAccessLanding.perfil = perfil">{{perfil}}</option>
              </select>
          </label>
      </p>
      <p>
          <label>
              Estado
              <select id="estado" size="1">
                <option *ngFor="let estado of icamAccessLanding.estadoList"
                  (click)="icamAccessLanding.estado = estado.value">{{estado.text}}</option>
              </select>
          </label>
      </p>
      <p>
          <label>
              Situación
              <select id="situacion" size="1">
                <option *ngFor="let situacion of icamAccessLanding.situacionList"
                  (click)="icamAccessLanding.situacion = situacion.value">{{situacion.text}}</option>
              </select>
          </label>
      </p>
      <p>
          <input type="button"
            value="Generar"
            (click)="generateUrls()" />
      </p>
  </div>
  <div>
      <label>
          <span>Resultado</span>
          <textarea id="result"
            type="text"
            rows="3"
            [value]="icamAccessLanding.result"
            readonly
            (click)="copyInput($event)"
            placeholder="Rellena todos los campos y haz clic en el botón &quot;Generar&quot;"></textarea>
      </label>
      <div id="advice"
          [ngShow]="icamAccessLanding.showAdvice">
          <span>Click to copy!</span>
          <div>
            <a [ngShow]="icamAccessLanding.showLink"
              id="resultLink"
              target="_self"
              [href]="icamAccessLanding.resultLink">{{icamAccessLanding.resultLink}}</a>
          </div>
      </div>
  </div>
</div>
