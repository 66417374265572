<div class="states-process__custom"
  [ngClass]="{'states-process--task': type === 'normalTask'}">
  <div class="states-process__custom-block-container">
      <i class="states-process__custom-icon-header"
          [ngClass]="type === 'normalTask'?'lf-icon-information':'lf-icon-open-window'"
          (click)="showProcessModal()"
          [ngShow]="!(type === 'processConcept')">
      </i>
      <div class="states-process__custom-container"
          [ngClass]="{'states-process__custom-container--two-steps': !statesProcess.summary?.previous,
              'states-process__custom-container--task': type === 'normalTask'}"
          [ngShow]="!statesProcess.showQuestion">

          <div class="states-process__block-body states-process__block-body--custom"
              [ngShow]="type !== 'normalTask' && statesProcess.showStates">
              <div class="states-process__block-state"
                  [ngShow]="statesProcess.summary && statesProcess.summary?.previous">
                  <div class="states-process__block-line-state">
                      <button class="states-process__custom-block-repeat-states"
                          [ngClass]="{'states-process__block-repeat-states--disabled': !statesProcess.canChangeState}"
                          [disabled]="!statesProcess.canChangeState"
                          (click)="changeProcessQuestion(statesProcess.summary?.previous, 'previous')">
                          <i class="lf-icon-arrow-left states-process__custom-arrow-process states-process__custom-arrow-process--left"></i>
                          <!-- <i class="lf-icon-radio-button-active states-process__icon-button-active-custom"></i> -->
                          <span class="states-process__custom-text-process">{{statesProcess.summary?.previous?.stateName}}</span>
                      </button>
                      <i class="lf-icon-documentation states-process__icon-briefcase"
                          [ngShow]="statesProcess.summary?.previous?.hasDocuments"></i>
                  </div>
                  <div class="states-process__bar-progress states-process__bar-progress-custom states-process__bar-progress--prev"
                      [ngClass]="{'states-process__bar-progress--first':statesProcess.summary && statesProcess.summary?.previous}"></div>
                  <div class="states-process__title-process-state states-process__title-process-state--custom">
                      <!-- <span>&lt&lt</span> -->
                      <span>{{statesProcess.arrowsPrev}}</span>
                      <span [afTranslate]="'task-concept-list.process-task.prev-state'"> Estado anterior</span>
                  </div>

              </div>
              <div class="states-process__block-state states-process__block-state--principal">
                  <i class="lf-icon-radio-button-active states-process__icon-button-active-custom"></i>
                  <span class="states-process__text-process-active-custom">{{statesProcess.summary?.current.name}}</span>
                  <i class="lf-icon-documentation states-process__icon-briefcase"
                      [ngShow]="statesProcess.summary?.current.hasDocuments"></i>
                  <div class="states-process__bar-progress states-process__bar-progress-custom states-process__bar-progress--active"
                      [ngClass]="{'states-process__bar-progress--first':!(statesProcess.summary && statesProcess.summary?.previous)}"></div>
                  <div class="states-process__title-process-state states-process__title-process-state--custom states-process__title-process-state--actual ">
                      <span [afTranslate]="'task-concept-list.process-task.current-state'">_Estado actual</span>
                  </div>
              </div>
              <div class="states-process__block-state"
                  [ngShow]="statesProcess.summary?.next?.length">
                  <div class="states-process__block-line-state"
                      *ngFor="let nextItem of statesProcess.summary?.next">
                      <button class="states-process__custom-block-repeat-states"
                              [ngClass]="{'states-process__block-repeat-states--disabled': !statesProcess.canChangeState}"
                              [disabled]="!statesProcess.canChangeState"
                              (click)="changeProcessQuestion(nextItem, 'next')">
                          <!-- <i class="lf-icon-radio-button-active states-process__custom-icon-radio-next"></i> -->
                          <span class="states-process__custom-text-process">{{nextItem.stateName}}</span>
                          <i class="lf-icon-arrow-right states-process__custom-arrow-process states-process__custom-arrow-process--right"></i>
                      </button>
                      <i class="lf-icon-documentation states-process__icon-briefcase"
                          [ngShow]="nextItem.hasDocuments"></i>
                  </div>
                  <div class="states-process__bar-progress states-process__bar-progress-custom states-process__bar-progress--last"></div>
                  <div class="states-process__title-process-state states-process__title-process-state--custom ">
                      <span [afTranslate]="'task-concept-list.process-task.next-state'">_Estado siguiente </span>
                      <!-- <span>&gt&gt</span> -->
                      <span>{{statesProcess.arrowsAfter}}</span>

                  </div>
              </div>
          </div>
          <div class="states-process__block-body-task"
              [ngShow]="type === 'normalTask'">
              <div class="states-process__block-all-progress-custom"></div>
              <div class="states-process__custom-block-state-task"
                  [ngShow]="statesProcess.statesList.length"
                  *ngFor="let state of statesProcess.statesList; let first = first; let last = last">
                  <div class="states-process__block-line-state states-process__block-line-state--task">
                      <div class="states-process__block-state-active-custom"
                          [ngShow]="state.stateActive">
                          <i class="lf-icon-radio-button-active states-process__icon-button-active-custom"
                              [ngStyle]="{'color': state.color}"></i>
                          <span class="states-process__text-process-active-custom">{{state.state}}</span>
                      </div>
                      <button class="states-process__block-repeat-states states-process__block-repeat-states--special"
                          [ngClass]="{'states-process__block-repeat-states--disabled': state.disabledState}"
                          [disabled]="state.disabledState"
                          (click)="changeProcessQuestion(state, 'task')"
                          [ngShow]="!state.stateActive">
                          <span [ngClass]="state.stateActive?'states-process__text-process-active-custom':'states-process__text-process-inactive-custom'">{{state.state}}</span>
                          <i class="lf-icon-arrow-right states-process__icon-arrow-right states-process__icon-arrow-right--landing-custom"></i>
                      </button>
                  </div>
                  <div class="states-process__bar-progress states-process__bar-progress--task"
                      [ngStyle]="{'background-color': state.color}"></div>
                  <p class="states-process__custom-actual-step"
                      [ngShow]="state.stateActive"
                      [afTranslate]="'task-concept-list.process-task.current-state'">_ESTADO ACTUAL</p>
              </div>

          </div>
      </div>
      <div [ngShow]="statesProcess.showQuestion"
          class="states-process__block-question-custom">
          <div class="states-process__block-table-question-custom">
              <div class="states-process__table-question-custom">
                  <i class="lf-icon-question-full states-process__icon-help-custom"></i>
                  <p class="states-process__text-question-custom">{{statesProcess.questionChangeProcess}}</p>
              </div>
          </div>
          <div class="states-process__buttons-block-custom">
              <button type="reset"
                  class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': custom?.color, 'color': custom?.color}"
                  (click)="statesProcess.showQuestion = !statesProcess.showQuestion"
                  [afTranslate]="'common.no'">_No</button>
              <button type="submit"
                  class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': custom?.color, 'color': custom?.textColor }"
                  (click)="confirmChange()"
                  [afTranslate]="'common.yes'">_Si</button>
          </div>
      </div>
  </div>
</div>
