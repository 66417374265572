<div class="common__pdf-block-view"
    id="pdf-report-consult-list">
    <pdf-header
        [datePdf]="configuration.infoModal.exportDate"
        [pdfInfo]="configuration.infoModal"></pdf-header>
    <div class="report-consult-lists-pdf__body-pdf">
        <div [class]="'report-consult-lists-pdf__block-alert-head-pdf report-consult-lists-pdf__block-alert-head-pdf--' + configuration.infoModal.resultColor">
            <p class="report-consult-lists-pdf__name-alert-head-pdf">
                <span>{{configuration.typePerson === 'natural'?configuration.personData.name:configuration.personData.socialReason}}</span>
                <span [ngShow]="configuration.typePerson === 'natural'">{{configuration.personData.surname1}}</span>
                <span [ngShow]="configuration.typePerson === 'natural'"
                  class="span__auto-ajust">{{configuration.personData.surname2}}</span>
            </p>
            <p class="report-consult-lists-pdf__documentnumber-alert-head-pdf">{{configuration.personData.documentNumber}}</p>
            <i [class]="configuration.infoModal.iconAnswer + ' report-consult-lists-pdf__icon-alert-head-pdf report-consult-lists-pdf__icon-alert-head-pdf--' + configuration.infoModal.resultColor"></i>
            <p class="report-consult-lists-pdf__message-alert-head-pdf">{{configuration.infoModal.messageAnswer}}</p>
            <p class="report-consult-lists-pdf__more-info-alert-head-pdf">
                <span [afTranslate]="'consult-lists-modal.information-provider'">_Proveedor de la información</span>:
                <span>{{configuration.infoModal.provider}}</span>
            </p>
            <p class="report-consult-lists-pdf__more-info-alert-head-pdf">
                <span [afTranslate]="'consult-lists-modal.detections'">_Nº de detecciones</span>:
                <span>{{configuration.infoModal.detections.length}}</span>
            </p>
        </div>
        <div class="report-consult-lists-pdf__block-alert-body-pdf"
            *ngIf="configuration.infoModal.detections.length">
            <div *ngFor="let detection of configuration.infoModal.detections"
                class="report-consult-lists-pdf__body-person-array-pdf">
                <div class="report-consult-lists-pdf__title-person-array-pdf">
                    <span>{{detection.name}}</span>
                </div>
                <div class="report-consult-lists-pdf__datainfo-array-pdf">
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.confidenceLevel">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.confidence-level'">_Nivel de confianza</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.confidenceLevel}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.name">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.name'">_Nombre</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.name}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.listType">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.list-type'">_Tipo de lista</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.listType}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.id">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.list-id'">_Id en lista</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.id}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.lastUpdateInlist">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.list-last-update'">_Última actualización de la lista</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.lastUpdateInlist | convertDate: 'without-time'}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.personType">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.person-type'">_Tipo de persona</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf"
                            [afTranslate]="detection.personType === 'NATURAL'?'consult-lists-modal.person-natural':'consult-lists-modal.person-artifitial'"></div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.program'">_Programa</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.program}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.post">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.post'">_Post</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.post}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.state">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.state'">_Estado</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf"
                            [afTranslate]="detection.state === 'ACTIVE'?'consult-lists-modal.state-active':'consult-lists-modal.state-inactive'"></div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.creationDate">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.creation-date'">_Fecha de creación</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.creationDate | convertDate: 'without-time'}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.comments">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.comments'">_Comentarios</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.comments}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.aliases?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.alias'">_Alias</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let alias of detection.aliases"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{alias.name}} {{alias.aliasType}}</div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.birthDates?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.born-dates'">_Fechas de nacimiento</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let birthDate of detection.birthDates"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{birthDate.bornDate | convertDate: 'without-time'}}</div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.addresses?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.addresses'">_Direcciones</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let address of detection.addresses"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">
                                <div [ngShow]="address.comments">{{address.comments}} {{address.country}}</div>
                                <div [ngShow]="!address.comments">{{address.address1}} {{address.city}} {{address.country}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.identificationDocuments?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.identification'">_Documentos de identidad</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let identificator of detection.identificationDocuments"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{identificator.documentNumber}} <span *ngIf="identificator.type">(<span [afTranslate]="'consult-lists-modal.'+identificator.type.toLowerCase()"></span>)</span></div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.profileNotes">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.profile-notes'">_Notas de perfil</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">
                            <span [innerHtml]="detection.profileNotes"></span>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.prpType">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.prp-type'">_Tipo de detección</div>
                        <div class="report-consult-lists-pdf__datainfo-text-pdf">{{detection.prpType}}</div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.prpCategories?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.prp-categories'">_Categorías de detección</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let categorie of detection.prpCategories"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{categorie}}</div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.sources?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.information-sources'">_Fuentes de información</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let source of detection.sources"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{source.name}}</div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.sanctions?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.sanctions'">_Lista de sanciones</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let sanction of detection.sanctions"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{sanction.name}}: {{sanction.startingDate | convertDate: 'without-time'}}</div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.activities?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.activities'">_Ocupaciones</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let activity of detection.activities | orderBy:'startingDateInPost'"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{activity.description}} ({{activity.type}} - {{activity.post}})
                                <span [ngShow]="activity.startingDateInPostSpecified"><span [afTranslate]="'consult-lists-modal.from'">_Desde</span>: {{activity.startingDateInPost | convertDate: 'without-time'}}</span>
                                <span [ngShow]="activity.endingDateInPostSpecified"><span [afTranslate]="'consult-lists-modal.until'">_Hasta</span>: {{activity.endingDateInPost | convertDate: 'without-time'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="report-consult-lists-pdf__datainfo-col-pdf"
                        *ngIf="detection.relations?.length">
                        <div class="report-consult-lists-pdf__datainfo-type-pdf"
                            [afTranslate]="'consult-lists-modal.relations'">_Relaciones conocidas</div>
                        <div class="report-consult-lists-pdf__repeat-text-pdf">
                            <div *ngFor="let relation of detection.relations"
                                class="report-consult-lists-pdf__datainfo-text-pdf report-consult-lists-pdf__datainfo-text-pdf--list">{{relation.name}}.
                                <span [afTranslate]="'consult-lists-modal.relation'">_Relación</span>: {{relation.type}} <span [ngShow]="!relation.previous">(<span [afTranslate]="'consult-lists-modal.relation-current'">_relación actual</span>)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
