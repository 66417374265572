<div class="report-table__header-icam">
    <div class="report-table__header-left-icam">
        <div class="report-table__filter-header-icam"
            ngbDropdown
            ngbDropdownToggle>
            <i class="lf-icon-filter-2 report-table__fheader-icon-icam"></i>
            <span class="report-table__fheader-text-icam">
                {{reportTable.selectedManagerName ===''? ('report-table.icam.resueltas-gestor.filtrar-gestor' | afTranslate) : reportTable.selectedManagerName}}
            </span>
            <i class="lf-icon-close report-table__fheader-close-icam"
                [ngHide]="reportTable.selectedManagerName ==''"
                (click)="$event.stopPropagation();selectManager('')"></i>
            <div class="dropdown-menu report-table__fheader-dropdown-icam"
                ngbDropdownMenu>
                <div class="report-table__fdropdown-search-icam">
                    <input placeholder="{{'report-table.icam.resueltas-gestor.buscar-gestor' | afTranslate}}"
                        (click)="$event.stopPropagation()"
                        [formControl]="reportTable.managersFilter">
                    <i class="lf-icon-search"></i>
                </div>
                <ul class="report-table__fdropdown-list-icam">
                    <li class="report-table__fdropdown-item-icam"
                        *ngFor="let manager of reportTable.managers"
                        (click)="selectManager(manager)"
                        [ngShow]="reportTable.managersFilter">
                        <span>{{manager}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="report-table__header-center-icam"></div>
    <div class="report-table__header-right-icam">
        <div class="report-table__header-button-icam"
            (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngHide]="reportTable.optionSelect === 'graph'">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-upper-icam">
            <div class="report-table__tcell-icam report-table__tcell-icam--first report-table__tcell-icam--year"
                ngbDropdown>
                <span>{{reportTable.currentIcamYear}}</span>
                <i class="lf-icon-angle-down report-table__tcell-icon-icam"
                    ngbDropdownToggle></i>
                <ul class="report-table__dropdown-menu-icam"
                    ngbDropdownMenu>
                    <li class="report-table__dropdown-item-icam"
                        *ngFor="let year of reportTable.listYears"
                        (click)="selectIcamYear(year)">
                        <span>{{year}}</span>
                        <i class="lf-icon-check-round-full report-table__dropdown-icon-icam"></i>
                    </li>
                </ul>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['consultasResueltas']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['jurisprudencia']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['legislacion']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['formularios']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['referenciaBibliografia']}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last report-table__tcell-icam--header-upper">
                {{'report-table.icam.total' | afTranslate}}: {{reportTable.icamTotals['referenciaDoctrina']}}
            </div>
        </div>
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                <filter-by-list class="report-table__filter-list-class"
                    [type]="'list'"
                    [item]="reportTable.monthNameFilter"
                    [list]="reportTable.monthNameList"
                    (filterElementEmit)="selectTypeOperation($event, {id:'monthName'})">
                </filter-by-list>
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.resueltas-gestor.resueltas'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.resueltas-gestor.jurisprudencia'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.resueltas-gestor.legislacion'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.resueltas-gestor.formularios'">
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.resueltas-gestor.bibliografia'">
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last"
                [afTranslate]="'report-table.icam.resueltas-gestor.doctrina'">
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let row of reportTable.reportsListFilter | afOrderBy: 'month':false; let last = last"
            [ngShow]="reportTable.selectedManagerName === row.gestor || reportTable.selectedManagerName ===''">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                {{row.monthName}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.consultasResueltas}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.jurisprudencia}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.legislacion}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.formularios}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.referenciaBibliografia}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                {{row.referenciaDoctrina}}
            </div>
        </div>
    </div>
</div>
<div class="report-table__footer-icam"
    [afTranslate]="'report-table.icam.resueltas-gestor.pie'">
</div>
