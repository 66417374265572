import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afLimitTo',
  pure: false
})
export class AfLimitToPipe implements PipeTransform {
  transform(list: any=[],  limitTo:any, begin:number=0 ): any{
    return limitTo?list.slice(begin, limitTo+begin):list;
  }

}
