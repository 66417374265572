import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable({providedIn: 'root'})

export class OrganizationsResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }
    resolve(route: ActivatedRouteSnapshot) {
      return this.apiService.get('clients/all').then(
        (data:any) => {
          return data;
        },
        (error:any) => {
          console.error(error);
        }
      )
    }
}
