<div class="qr-modal">
    <div class="common__modal-header-block">
        <i class="lf-icon-qr common__icon-header"></i>
        <span class="common__text-header" [afTranslate]="'channel-configuration.qr-code'"></span>
        <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
    </div>
    <p class="qr-modal__related-form-info" [afTranslate]="'channel-configuration.qr-info'"></p>
        <div class="qr-modal__body">
            <div class="qr-modal__body-inner">
                <qrcode
                    [qrdata]="url"
                    [cssClass]="'center'"
                    [colorDark]="'#000000'"
                    [colorLight]="'#ffffff'"
                    [elementType]="'svg'"
                    [errorCorrectionLevel]="'M'"
                    [margin]="4"
                    [scale]="1"
                    [width]="256"
                ></qrcode>
            </div>
        </div>
        <div class="common__buttons-block qr-modal__buttons-block">
            <button type="submit" class="common__button common__button--active"
                (click)="handleAccept()"
                [afTranslate]="'expanded-menu.download'"></button>
        </div>
</div>
