import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptFormParentService } from '../utils/concept-form-parent.service';

@Component({
  selector: 'concept-form-sequential',
  templateUrl: './concept-form-sequential.component.html'
})
export class ConceptFormSequentialComponent implements OnInit, OnDestroy {

  skin: string = this.globalCfg.skin;
  isLanding: boolean = true;
  params: any = this.route.snapshot.params
  currentComponent: string = this.route.snapshot.data['componentName'];
  viewTo: any = this.route.snapshot.params['to'];
  locationName: any = this.route.snapshot.data['name'];
  fieldContainerId:any = this.route.snapshot.params['fieldContainerId'];
  conceptObjectId:any = this.route.snapshot.params['conceptObjectId'];
  conceptForm: any = this.conceptFormParent.configureConceptFormObject(this);

  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private conceptFormParent: ConceptFormParentService) {
  }

  @Input() concept: any = null;
  @Input() conceptList: any = null;
  @Input() public: boolean = false;
  @Input() lockedDataEvaluation: any = null;
  @Input() evaluationId: any = null;
  @Input() footerConceptPublic: any = null;
  @Input() parentConcept: any = null;
  @Input() hasLinksToForms: any = null;
  @Input() disabledSave: any = null;
  @Input() formalitiesList: any = null;

  @Output() launchAction = new EventEmitter();

  ngOnDestroy(){
    this.conceptFormParent.ngOnDestroy();
  }

  ngOnInit(){
    this.conceptFormParent.onInit(this)
  }

  ngOnChanges(): void {
    this.conceptFormParent.onChanges(this)
  }

  hasFullScreen(box:any){
    this.conceptFormParent.hasFullScreen(box, this)
  }

  changesInEvaluatedConcept(e:any){
    this.conceptFormParent.changesInEvaluatedConcept(e.field, this);
  }

  clear(){
    this.conceptFormParent.clear(this)
  }

  saveCreateConcept(type:any){
    this.conceptFormParent.saveCreateConcept(type, this)
  }

  saveConcept(type:any ='') {
    this.conceptFormParent.saveConcept(type, this)
  }

  closeConceptUserProfile(){
    this.conceptFormParent.closeConceptUserProfile(this)
  }

  savePoll(){
    this.conceptFormParent.checkSavePoll(this);
  }

  generateDocument(template:any){
    this.conceptFormParent.generateDocument(template, this)
  }

  launchActionGeneralConcept(action:any){
    this.conceptFormParent.launchActionGeneralConcept(action, this)
  }

  goBack(){
    this.conceptFormParent.goBack(this)
  }

  openRelatedInfo(relatedInfo:any){
    this.conceptFormParent.openRelatedInfo(relatedInfo, this)
  }

  nextQuestionPoll(){
    this.conceptFormParent.nextQuestionPoll(this)
  }

  previousQuestionPoll(){
    this.conceptFormParent.previousQuestionPoll(this)
  }

  changeResponsible(responsible:any) {
    this.conceptFormParent.changeResponsible(responsible, this)
  }

  changeLockedEdit(lockedEdit:any){
    this.conceptFormParent.changeLockedEdit(lockedEdit, this)
  }

  changeConceptFromField(template:any){
    this.conceptFormParent.changeConceptFromField(template, this)
  }

  clearSaveConcept(){
    this.conceptFormParent.clearSaveConcept(this)
  }

  shareFieldContainer(fieldContainer:any){
    this.conceptFormParent.shareFieldContainer(fieldContainer, this)
  }

  createNewForm(form:any){
    this.conceptFormParent.createNewForm(form, this)
  }

  launchActionBlockedConcept(type:any){
    this.conceptFormParent.launchActionBlockedConcept(type, this)
  }

  launchActionOn(e:any){
    this.launchAction.emit({action:e.action})
  }
}
