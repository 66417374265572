import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'blocked-edition-modal',
  templateUrl: './blocked-edition-modal.component.html'
})
export class BlockedEditionModalComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private translate:AfTranslateFactory
  ) { }

  @Input() concept:any;
  @Input() processStatesSelected:any='';

  skin:string = this.globalCfg.skin;
  blockedEditionModal:any = {
    processStates: {
      multiple: true,
      required: false,
      type: 'blockedEdition',
      label: this.translate.instant('blocked-edition-modal.label-field-states')
    }
  };

  ngOnInit(){
    this.getStates();
  }

  getStates() {
    this.apiService.get('processes', this.concept.conceptId + '/states').then(
      (data:any) => {
        this.blockedEditionModal.processStatesList = this.commonUtilsService.editList(data, 'stateId', 'name');
      },
      (error:any) =>{
        console.error(error);
      }
    );
  }

  processSelected(e: any){
    this.processStatesSelected = e.newValue;
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      processStatesSelected: this.processStatesSelected,
      processStatesList: this.blockedEditionModal.processStatesList
    });
  }

  handleDismiss() {
    this.activeModal.close({result: 'cancel'});
  }
}
