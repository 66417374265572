import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'filter-header',
  templateUrl: './filter-header.component.html'
})
export class FilterHeaderComponent implements OnChanges {
  skin: string = this.globalCfg.skin
  filterHeader: any = {
      searchClient: '',
      byword:['name'],
      search: '',
      filterClientList: []
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService) { }

  @Input() clientList: any = [];
  @Output() filterClient = new EventEmitter();
  @ViewChild(NgbDropdown)
  dropdowns: NgbDropdown;

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['clientList'] && this.clientList.length && !this.filterHeader.filterClientList.length){
          this.filterHeader.filterClientList = this.commonUtilsService.configureFilials(this.clientList);
          this.filterHeader.list = cloneDeep(this.filterHeader.filterClientList);
      }
  }

  showClientFilter(){
    this.filterHeader.showClient = !this.filterHeader.showClient;
  }

  openChild(e:any, client:any){
    e.stopPropagation();
    client.open = !client.open;
  }

  selectClient(client:any){
    this.filterHeader.selectedClientName = client.name;
    this.filterHeader.selectedClient = client;
    this.filterClient.emit({client: client});
    this.dropdowns.close();
  }

  resetFilterClient(){
    delete this.filterHeader.selectedClient;
    delete this.filterHeader.selectedClientName;
    this.filterClient.emit({client: null});
  }

  resetSearch(){
    this.filterHeader.searchClient = '';
  }

  openOrSelectOn(e:any, type: string){
    type === 'open'? this.openChild(e.e, e.child) : this.selectClient(e.child);
  }

}

