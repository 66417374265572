
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'help-tooltip',
  templateUrl: './help-tooltip.component.html'
})
export class HelpTooltipComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  helpTooltip: any = {
    title:'',
    textInfo: ''
  }
  constructor(private translate: AfTranslateFactory,
    private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory) { }
  @Input() key: any = null;
  @Output() closeHelpTooltip = new EventEmitter();

  ngOnInit(): void {
      this.configureText();
  }

  configureText(){
      let title:string = '';
      let textInfo: string = '';
      switch(this.key){
          case 'SujetoObligado'://Sujeto obligado
              title = 'help-tooltip.title-subject'
              textInfo = 'help-tooltip.text-info-subject'
              break;
          case 'Cliente'://Cliente
              title = 'help-tooltip.title-client'
              textInfo = 'help-tooltip.text-info-client'
              break;
          case 'Operacion': //Operación
              title = 'help-tooltip.title-operation'
              textInfo = 'help-tooltip.text-info-operation'
              break;
          case 'ExamenEspecial'://Examen especial
              title = 'help-tooltip.title-special-test'
              textInfo = 'help-tooltip.text-info-special-test'
              break;
          case 'DiligenciasReforzadas'://Seguimiento de la relación
              title = 'help-tooltip.title-errands'
              textInfo = 'help-tooltip.text-info-errands'
              break;
          case 'client-onboarding'://Cliente bloqueado en onboarding
              title = 'help-tooltip.title-locked-clients'
              textInfo = 'help-tooltip.text-info-locked-clients'
              break;
          default:
              break;
      }
      this.helpTooltip.title = this.translate.instant(title);
    this.helpTooltip.textInfo = this.translate.instant(textInfo);
  }

  closeTooltip(){
      this.closeHelpTooltip.emit();
  }

  hideHelpTooltip(){
      localStorage.setItem('hideHelpTooltip', 'true');
      this.closeHelpTooltip.emit();
  }

  hideHelpTooltipToFolder(){
      localStorage.setItem('hideHelpTooltipToFolder-'+this.route.snapshot.params['evaluationId']+'-'+this.key, 'true');
      this.closeHelpTooltip.emit();
  }

}
