<div class="common__panel roles-window">
  <header-nav></header-nav>
  <div class="common__block-window-view"
      [ngShow]="rolesWindow.finishLoader">
      <div class="common__block-button-close-window">
          <button (click)="changeView()"
              class="common__back-link">
              <span [afTranslate]="'roles-window.close-roles'">_Cerrar Roles</span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="common__container-window-view">
          <div class="common__block-list-new-version roles-window__container">
              <div class="common__header-new-version">
                  <i class="lf-icon-add-points common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'common.roles'">_Roles</span>
                  <div class="common__options-block">
                      <span class="common__selected-option evaluations-list__select-language">{{rolesWindow.productName}}</span>
                  </div>
              </div>
              <form class="roles-window__form-body">
                  <field-type-rich-text
                    [field]="rolesWindow.associatedPermissions">
                  </field-type-rich-text>
                  <div class="roles-window__block-roles-repeat">
                      <field-type-role *ngFor="let role of rolesWindow.rolesProduct"
                          [field]="role"
                          [productId]="rolesWindow.productId">
                      </field-type-role>
                  </div>
              </form>
          </div>
      </div>
  </div>
  <compliance-footer [ngShow]="rolesWindow.finishLoader"></compliance-footer>
</div>
