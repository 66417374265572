<div class="field-type-template">
  <div class="common__input-block common__input-block--fixed"
        [ngClass]="{'common__show-alert': fieldTypeReport.field.error,
          'common__input-no-editable':fieldTypeReport.field.noEditable || fieldTypeReport.confidentialLocked,
          'common__input-block--custom-field':fieldTypeReport.custom && fieldTypeReport.custom !== null && !fieldTypeReport.field.sequentialView}"
        [attr.disabled]="fieldTypeReport.field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
                [ngClass]="{'common__input-text--required': fieldTypeReport.field.error,
                      'common__input-text--no-editable':fieldTypeReport.field.noEditable || fieldTypeReport.confidentialLocked}">{{fieldTypeReport.field.label}}</span>
          <span class="common__text-required"
                [ngShow]="fieldTypeReport.field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
                [ngShow]="fieldTypeReport.field.references.length && !fieldTypeReport.field.noEditable && !fieldTypeReport.confidentialLocked">
              <tooltip-law [references]="fieldTypeReport.field.references"
                  [field]="fieldTypeReport.field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__container">
          <p class="field-type-template__text-description"
              [innerHTML]="fieldTypeReport.field.description"></p>
          <div class="field-type-template__block-button">
              <button class="common__button-border-none"
                      [ngShow]="fieldTypeReport.lastReport"
                      [disabled]="fieldTypeReport.field.noEditable || fieldTypeReport.confidentialLocked"
                      (click)="launchActionReport('goToFolder')">
                  <i class="lf-icon-analysis common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                    [afTranslate]="'field-type-report.go-button'"></span>
              </button>
              <button class="common__button-border-none span__auto-ajust"
                      [disabled]="fieldTypeReport.field.noEditable || fieldTypeReport.confidentialLocked"
                      (click)="launchActionReport('generateReport')">
                  <i class="lf-icon-report common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                    [afTranslate]="'field-type-report.generate-button'"></span>
              </button>
          </div>
          <div class="field-type-report__report-container"
              [ngShow]="fieldTypeReport.lastReport">
              <div class="field-type-task__task-item">
                  <div class="field-type-task__task-item-menu"
                      [ngShow]="!fieldTypeReport.field.noEditable">
                      <i class="lf-icon-visible field-type-task__task-item-menu-icon"
                          matTooltip="{{'field-type-report.view-option' | afTranslate}}"
                          matTooltipPosition="right"
                          matTooltipClass="right"
                        (click)="launchActionReport('viewReport')"></i>
                  </div>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'field-type-report.label-1'"></span>: <span class="field-type-task__task-item-text-info">{{fieldTypeReport.lastReport?.title}}</span>
                  </p>
              </div>
          </div>
      </div>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeReport.field.error && fieldTypeReport.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </div>
</div>
