import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'icam-access-landing',
  templateUrl: './icam-access-landing.component.html',
  styleUrls: ['./icam-access-landing.component.scss']
})
export class IcamAccessLandingComponent {
  icamAccessLanding: any = {
    token: 'victorblazquez',
    username: 'A41R8EH7VB',
    timestamp: new Date().getTime(),
    secret: 'secreto',
    access: this.route.snapshot.queryParams['access'],
    conceptId: this.route.snapshot.queryParams['conceptId'],
    perfil: 'Colegiado',
    estado: 'S',
    situacion: 'E',
    result: '',
    resultLink: '',
    showAdvice: false,
    showLink: false,
    perfilList: ['Colegiado', 'Empleado', 'Otro'],
    estadoList: [
      {
        text: 'Activo',
        value: 'S'
      },
      {
        text: 'No activo',
        value: 'N'
      }
    ],
    situacionList: [
      {
        text: 'Ejerciente',
        value: 'E'
      },
      {
        text: 'No ejerciente',
        value: 'NE'
      },
      {
        text: 'No aplica',
        value: 'NA'
      }
    ]
  }

  constructor(private route: ActivatedRoute) { }

  generateUrls() {
      let token = this.icamAccessLanding.token;
      let timestamp = this.icamAccessLanding.timestamp;
      let secret = this.icamAccessLanding.secret;
      let perfil = this.icamAccessLanding.perfil;
      let estado = this.icamAccessLanding.estado;
      let situacion = this.icamAccessLanding.situacion;
      let username = this.icamAccessLanding.username;

      if (token && timestamp && secret && perfil) {
          let cleanhash = token + timestamp + perfil + situacion + estado + username + secret;
          let hash = Md5.hashStr(cleanhash);

          let query = "sso_token=" + encodeURIComponent(token)
              + "&sso_timestamp=" + encodeURIComponent(timestamp)
              + "&sso_perfil=" + encodeURIComponent(perfil)
              + "&sso_situacion=" + encodeURIComponent(situacion)
              + "&sso_estado=" + encodeURIComponent(estado)
              + "&sso_username=" + encodeURIComponent(username)
              + "&sso_hash=" + encodeURIComponent(hash);
          if (this.icamAccessLanding.access && this.icamAccessLanding.conceptId) {
              this.icamAccessLanding.result = "/public/concept/" + this.icamAccessLanding.conceptId + "?access=" + encodeURIComponent(this.icamAccessLanding.access) + "&" + query;
              this.icamAccessLanding.showLink = true;
          } else if (this.icamAccessLanding.access) {
              this.icamAccessLanding.result = "/public/monitoring?access=" + encodeURIComponent(this.icamAccessLanding.access) + "&" + query;
              this.icamAccessLanding.showLink = true;
          } else {
              this.icamAccessLanding.result = "&" + query;
          };
          this.icamAccessLanding.resultLink = window.location.protocol + "//" + window.location.host + this.icamAccessLanding.result;
          this.icamAccessLanding.showAdvice = true;

      } else {
          alert("Rellena todos los campos");
      }
  }

  copyInput(e: any) {
      if (e.currentTarget?.value) {
          navigator.clipboard.writeText(e.currentTarget.value)
          .then(() => {
            setTimeout(() => {
              alert('El valor ha sido copiado al portapapeles.')
            });
            }, () => {});
      }
  }
}
