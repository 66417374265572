import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'clients-tracking',
  templateUrl: './clients-tracking.component.html'
})
export class ClientsTrackingComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  productId: string = 'blanqueo';//this.context.settings.defaultProduct
  clientsTracking: any = {
    configPagination: {
      pageSize: 10,
    },
    currentPage: 1,
    searchClient: '',
    typeFilter: 'clientName',
    orderOperationList: false,
    filters: ['author', 'riesgo', 'recurrenteText', 'stateName', 'legalForm'],
    textFilters: ['clientName'],
    clientList: [],
    filterClientList: [],
    clientNameFilter: {
        name: this.translate.instant('common.organization'),
        id: this.commonUtilsService.createId(),
        orderCol: true,
        filterOrder: 'clientName',
        width: '200px'
    },
    authorFilter: {
        name: this.translate.instant('common.author'),
        id: this.commonUtilsService.createId()
    },
    riesgoFilter: {
        name: this.translate.instant('common.risk'),
        id: this.commonUtilsService.createId()
    },
    recurrenteTextFilter: {
        name: this.translate.instant('common.recurrent'),
        id: this.commonUtilsService.createId()
    },
    stateNameFilter: {
        name: this.translate.instant('common.state'),
        id: this.commonUtilsService.createId()
    },
    legalFormFilter: {
        name: this.translate.instant('common.legal-form'),
        id: this.commonUtilsService.createId()
    },
    userPermissions: this.userPermissions.getPermissions()
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private filterColumnService: FilterColumnService,
    private router: Router,
    private modalService: ModalServiceService,
    private exportService: ExportServiceService,
    private downloadService: DownloadService,
    private dateConverterService: DateConverterService,
    private userPermissions: UserPermisionsFactory) { }

  ngOnInit(): void {
    this.loader.openLoader('open-clients-tracking-window');
    this.getClients();
  }

  getClients(){
    this.apiService.get(this.productId+ '/clients')
      .then((data:any)=>{
          this.configureList(data);
      });
  }

  configureList(data:any){
    data.forEach((client:any) => {
      if(client.color){
          client.colorRGBARiesgo = 'rgba('+this.commonUtilsService.convertHexToRGB(client.color)+',0.36)';
      }
      client.stateName = this.configureStateName(client);
      client.legalForm = this.configureLegalForm(client);
      client.recurrenteText = client.recurrente?this.translate.instant('common.yes'):this.translate.instant('common.no');
    });
    this.clientsTracking.clientList = data;
    this.clientsTracking.filterClientList = data;
    this.getFilters();
  }

  configureStateName(client: any){
    switch(client.idEstado){
        case 2:
            return this.translate.instant('common.locked-up');//'Bloqueado'
        case 3:
            return this.translate.instant('common.not-supported');//'No admitido'
        case 4:
            return this.translate.instant('common.no-active');//'No activo'
        default:
            return this.translate.instant('common.operational');//'Operativo'
    }
  }

  configureLegalForm(client: any){
    switch(client.idFormaJuridica){
        case 1:
            return this.translate.instant('common.person-physical');//'Persona física'
        case 2:
            return this.translate.instant('common.person-corporate-legal');//'Persona jurídica societaria'
        case 3:
            return this.translate.instant('common.person-other-legal-entities');//'Otras personas jurídicas: Asociaciones,Fundaciones'
        case 4:
            return this.translate.instant('common.person-without-legal-personality');//'Entidades sin personalidad jurídica'
        default:
            return '';
    }
  }

  getFilters(){
    this.clientsTracking.textFilters.forEach((text:any) => {
      this.clientsTracking[text+'WordFilter'] = '';
    });
    this.clientsTracking.filters.forEach((filter:any) => {
      this.clientsTracking[filter+'List'] = this.filterColumnService.extractList(filter, this.clientsTracking.clientList);
      this.clientsTracking[filter+'ListFilter'] = [];
    });
    this.closeInitLoader();
  }

  closeInitLoader(){
    this.loader.closeLoader(['open-clients-tracking-window','front-login-login','folder-open-concept']);
  }

  newClient(){
    this.loader.openLoader('new-client-evaluation');
    localStorage.setItem('productId', this.productId);
    this.router.navigate(['client-evaluation']);
  }

  editClient(client: any){
    if(this.clientsTracking.userPermissions ==='all'){
      this.loader.openLoader('open-new-concept-window');
      this.router.navigate(['client', client.clientId]);
    }
  }

  openInNewTab(client: any){
      let url = '/client/' +  client.clientId;
      window.open(url, '_blank');
  }


  deleteOption(client:any){
      let text = this.translate.instant('clients-tracking.question-delete-client').replace('{client}', client.clientName);
      this.modalService.adviceModal(text, 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.confirmDeleteClient(client);
          }
        })
  }

  confirmDeleteClient(client:any){
    this.loader.openLoader('delete-client-tracking');
    this.apiService.delete(this.productId, client.clientId)
      .then((data:any)=>{
          this.clientsTracking.clientList = this.commonUtilsService.deleteElement(client,  this.clientsTracking.clientList, 'clientId');
          this.clientsTracking.filterClientList = this.clientsTracking.clientList;
          this.getFilters();
          this.loader.closeLoader('delete-client-tracking');
          let text = this.translate.instant('clients-tracking.confirmation-deleted-client').replace('{client}', client.clientName);
          this.modalService.adviceModal(text, 'info:accept:check-round')
      }, (error:any)=>{
        this.loader.closeError();
        let text = this.translate.instant('clients-tracking.error-delete-client').replace('{client}', client.clientName);
        this.modalService.adviceModal(text, 'error:accept:warning')
      })
  }

  searchBy(type: any){
      if(this.clientsTracking.typeFilter === type){
          this.clientsTracking.orderOperationList = !this.clientsTracking.orderOperationList;
      }else{
          this.clientsTracking.typeFilter = type;
          this.clientsTracking.orderOperationList = !(type === 'clientName');
      }
  }

  selectTypeOperation(item: any, type: any){
    let listFilter = this.clientsTracking[type+'ListFilter'];
    this.clientsTracking[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    this.clientsTracking[type+'Filter'].hasFilter = this.clientsTracking[type+'ListFilter'].length;
    item.selected = !item.selected;
    this.filterClient();
  }

  filterClient(){
    let clientFilter = [];
    clientFilter = this.filterColumnService.filterByWord(this.clientsTracking.clientNameWordFilter, this.clientsTracking.clientList, 'clientName');
    for(let filter of this.clientsTracking.filters){
        let filteredList = this.clientsTracking[filter+'ListFilter'];
        clientFilter = this.filterColumnService.filterBycolumn(clientFilter, filteredList, filter);
    }
    this.clientsTracking.filterClientList = clientFilter;
  }

  // orderBySearch(newValue: any, type: any, action: any){
  orderBySearch(e:any, type:any){
    switch(e.action){
        case 'filter':
            this.clientsTracking[type+'WordFilter'] = e.newValue;
            this.filterClient();
            break;
        case 'order':
            this.searchBy(type);
            break;
        default:
            break;
    }
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id);
  }

  changeView(){
      this.router.navigate(['dashboard']);
  }

  toggleSearch() {
      this.clientsTracking.search = !this.clientsTracking.search;
      if (!this.clientsTracking.search) {
          this.clientsTracking.searchReport = '';
      }
  }

  exportList() {
    this.modalService.exportFormatModal()
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          let config: any = {
              idPrint: 'pdf-clients-tracking',
              list: cloneDeep(this.clientsTracking.filterClientList),
              typeColumns: this.configureTypeColumns(),
              title: this.translate.instant('common.clients-tracking'),
              nameDocument: this.translate.instant('common.clients-tracking')+(result.selectedFormat==='xls'?'.xlsx':'.pdf'),
              columns: this.configureColumns(),
              uploadFile: false,
              landscape: true,
              successCallback:(uploadResponse:any, blob:any) => {
                delete this.clientsTracking.configurationPdf;
                this.downloadService.triggerDownload(blob, config.nameDocument);
                this.modalService.adviceModal(this.translate.instant('common.export-excel-ok'), 'info:accept:check-round');
              }
          }
          config.list.forEach((client:any) => {
              client.creationDateIso = this.dateConverterService.convertDate(client.creationDate);
              client.modificationDateIso = this.dateConverterService.convertDate(client.modificationDate);
              client.proximoSeguimientoIso = this.dateConverterService.convertDate(client.proximoSeguimiento);
              client.finRelacionIso = this.dateConverterService.convertDate(client.finRelacion, 'without-time');
          })
          if(result.selectedFormat === 'xls'){
              this.exportService.toXls(config);
          }else{
              config.exportDate = this.dateConverterService.setTimezone(new Date());
              config.orderType = this.clientsTracking.typeFilter;
              config.orderAsc = this.clientsTracking.orderOperationList;
              this.clientsTracking.configurationPdf = config;
              let that = this;
              setTimeout(function () {
                  let htmlPrint = $('#pdf-clients-tracking').html();
                  config.htmlPrint = htmlPrint;
                  that.exportService.toPdf(config);
              });
          }
        }
      })
  }

  configureTypeColumns(){
      if(this.skin === 'steel-blue'){
          return ['clientName', 'author', 'creationDateIso', 'modificationDateIso', 'riesgo', 'proximoSeguimientoIso', 'finRelacionIso', 'stateName', 'recurrenteText', 'legalForm', 'cif', 'pais', 'actividad'];
      }else{
          return ['clientName', 'author', 'creationDateIso', 'modificationDateIso', 'riesgo', 'proximoSeguimientoIso', 'finRelacionIso', 'stateName', 'recurrenteText', 'legalForm'];
      }
  }

  configureColumns(){
      let columns = {
          clientName: this.translate.instant('common.organization'),
          author: this.translate.instant('common.author'),
          creationDateIso: this.translate.instant('common.creation'),
          modificationDateIso: this.translate.instant('common.modification'),
          riesgo: this.translate.instant('common.risk'),
          proximoSeguimientoIso: this.translate.instant('clients-tracking.next-tracing'),
          finRelacionIso: this.translate.instant('clients-tracking.end-relationship'),
          stateName: this.translate.instant('common.state'),
          recurrenteText: this.translate.instant('common.recurrent'),
          legalForm: this.translate.instant('common.legal-form')
      };
      if(this.skin === 'steel-blue'){
          let specialColumns = {
              cif: this.translate.instant('clients-list.cif'),
              pais: this.translate.instant('users-admin.person-form.country'),
              actividad: this.translate.instant('clients-tracking.activity')
          }
          columns = {...columns, ...specialColumns};
      }
      return columns;
  }

}
