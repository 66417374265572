import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class reportResolve implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
      private globalCfg: GlobalCfgFactory) { }

    resolve(route: ActivatedRouteSnapshot) {
        let url = this.globalCfg.skin === 'icam-red'?'reportobjects/byreportid':'reportobjects';
        let id = this.globalCfg.skin === 'icam-red'?route.params['reportId']:route.params['reportObjectId'];
      return this.apiService.get(url, id)
        .then((data:any)=>{
          return this.globalCfg.skin === 'icam-red'?data.reports:data;
        })
    }

}


