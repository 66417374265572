<div class="report-record-detail__table-block">
    <div class="report-record-detail__table">
        <div class="report-record-detail__table-header">
            <div class="report-record-detail__col report-record-detail__col--title-documentation report-record-detail__col--first report-record-detail__col--header-border">
                <filter-by-search
                    [item]="{name: 'common.title' | afTranslate}"
                    [filterText]="reportRecordDetail.searchName"
                    (launchActionSearch)="orderBySearch($event, 'Name')">
                </filter-by-search>
            </div>
            <div class="report-record-detail__col report-record-detail__col--exportation-date-header report-record-detail__col--header-border">
                <span-tooltip class="common__col-text common__table-header-text common__table-header-text--order common__cursor-pointer"
                    [text]="'documentation-list.exportation-date' | afTranslate"
                    (click)="orderDate('modified')"></span-tooltip>
                <i class="common__order-by"
                    [ngClass]="{'lf-icon-arrow-down':(reportRecordDetail.dateOrder==='modified' && reportRecordDetail.orderOperationDates) || reportRecordDetail.dateOrder!=='modified',
                        'lf-icon-arrow-up': reportRecordDetail.dateOrder==='modified' && !reportRecordDetail.orderOperationDates,
                        'common__order-by--active': reportRecordDetail.dateOrder==='modified'}"
                    (click)="orderDate('modified')">
                </i>
            </div>
            <div class="report-record-detail__col report-record-detail__col--author report-record-detail__col--header-border">
                <filter-by-list [type]="'listFind'"
                    [widthClass]="'250px'"
                    [item]="reportRecordDetail.modifiedByFilter"
                    [list]="reportRecordDetail.modifiedByList"
                    [topStyle]="'29'"
                    (filterElementEmit)="selectTypeOperation($event, 'modifiedBy')">
                </filter-by-list>
            </div>
            <div class="report-record-detail__col report-record-detail__col--actions-documentation report-record-detail__col--header-border"></div>
        </div>
        <div id="report-record-table-body-documentation" class="report-record-detail__table-body"
            [ngClass]="{'report-record-detail__table-body--scrollbar': reportRecordDetail.showScrollBar}">
            <div class="report-record-detail__table-row report-record-detail__table-row--documentation"
                *ngFor="let document of reportRecordDetail.filterDocumentationList | afOrderBy:['name']: reportRecordDetail.orderOperationDates"
                [ngClass]="{'concept-list__row-anonimyzed':document.anonymized}">
                <div class="report-record-detail__col report-record-detail__col--title-documentation report-record-detail__col--first report-record-detail__col--body report-record-detail__col--body-border">
                    <span class="report-record-detail__col-text">{{document.name}}</span>
                </div>
                <div class="report-record-detail__col report-record-detail__col--exportation-date report-record-detail__col--body report-record-detail__col--body-border">
                    <span class="report-record-detail__col-text">{{document.modified | convertDate: 'without-time'}}</span>
                </div>
                <div class="report-record-detail__col report-record-detail__col--author report-record-detail__col--body report-record-detail__col--body-border">
                    <span class="report-record-detail__col-text">{{document.modifiedBy}}</span>
                </div>
                <div ngbDropdown class="report-record-detail__col report-record-detail__col--actions-documentation report-record-detail__col--body-border"
                    [open]="document.openOption">
                    <i class="lf-icon-anonymous report-record-detail__anonymized-concept-icon" [ngShow]="document.anonymized"></i>
                    <button ngbDropdownToggle class="lf-icon-kebab-menu report-record-detail__icon-action-body-documentation"></button>
                    <ul ngbDropdownMenu class="report-record-detail__list-menu-expanded dropdown-menu">
                        <li class="common__list-dropdown-option"
                            *ngFor="let optionMenu of reportRecordDetail.menuOptionsDocument; let last = last"
                            [ngHide]="document.anonymized && optionMenu.action ==='anonymatum-document'">
                            <button class="common__button-option-list-dropdown"
                                [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                (click)="launchActionOption(optionMenu.action, document)">
                                <i [class]="'common__icon-option-list-dropdown '+optionMenu.icon"></i>
                                <span class="common__text-menu-option-dropdown" [afTranslate]="optionMenu.name"></span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
