import moment from 'moment';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { DateLockFactory } from '@shared/factories/date-lock/date-lock.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'field-type-date',
  templateUrl: './field-type-date.component.html'
})
export class FieldTypeDateComponent implements OnInit, OnChanges  {
  isLanding: boolean = this.customLanding.isLanding();
  fieldTypeDate:any = {
    type: 'date',
    showButton: false,
    references: false,
    hours: '00',
    minutes: '00'
  };
  @Input() field:any;
  @Input() preSelectItem:any;
  @Input() alertHour:any;
  @Input() concept:any;
  @Input() public:any;

  @Output() itemSelected = new EventEmitter();
  @Output() createAlert = new EventEmitter();
  @Output() showFieldReference = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  constructor(private customLanding:CustomLandingFactory,
    private commonUtilsService:CommonUtilsService,
    private broadcast:BroadcastService,
    private modalService:ModalServiceService,
    private loader:LoaderFactory,
    private registerService:RegisterMixtapeService,
    private context:ContextFactory,
    private translate:AfTranslateFactory,
    private dateLock:DateLockFactory
  ){}

  ngOnChanges(): void {
    let that = this.fieldTypeDate;

    let field = this.field;
    if (field) {
      field.public = this.public;
      that.titleField = field.label;

      if(field.extendedLabel && field.extendedLabel !== ''){
          that.titleField = field.extendedLabel;
      }

      if(!field.id || field.generateId){
          field.id = this.commonUtilsService.createId();
      }else if(field.fieldId){
          field.id = field.fieldId;
      }

      if (field.datePickerOptions) {
        for (let [key, value] of Object.entries(field.datePickerOptions)) {
          this.fieldTypeDate.datePickerOptions[key] = value;
        }
      }

      let preSelectItem = this.preSelectItem;
      if(preSelectItem instanceof Date){
          that.hours = preSelectItem.getHours();
          that.minutes = preSelectItem.getMinutes() < 9 ? '0' + preSelectItem.getMinutes() : preSelectItem.getMinutes();
      }else if(preSelectItem === undefined){
          preSelectItem = null;
      }

      this.configureWidthField();

      if(field.fromTo){
          if(preSelectItem && preSelectItem.indexOf('-') !== -1){
              let dates : any = preSelectItem.split(' - ');
              that.creationDateFromDate = this.commonUtilsService.convertShortDate(dates[0]);
              that.creationDateToDate = this.commonUtilsService.convertShortDate(dates[1]);
          }else{
              that.creationDateFromDate = null;
              that.creationDateToDate = null;
          }
      }
      if(field.inReport !== null && field.inReport !== undefined){
        this.fieldTypeDate.inReport = field.inReport.indexOf('|') === -1? [field.inReport] : field.inReport.split('|')
      }
    }
  }

  ngOnInit(): void {
    this.resetFieldWatcher();
  }

  configureWidthField(){
    let that = this.fieldTypeDate;
    that.classWidthField = 'common__label-block--two-icon';

    let icons = 2;
    if(this.field.showTime){
        icons++;
        that.classWidthField = 'common__label-block--three-icon';
    }

    if(this.field.evaluatedValue?.hasReferences && !this.field.poll){
        icons++;
        that.classWidthField = (icons === 4 ? 'common__label-block--four-icon' : 'common__label-block--three-icon');
    }
  }

  resetFieldWatcher() {
    this.broadcast.subscribe('resetFieldTypeDate', () => {
        this.preSelectItem = '';
        this.changeItemSelected('');
    });
  }

  changeItemSelected(newValue:string){
    this.itemSelected.emit({ newValue: newValue });
  }

  openModalRelatedInfo(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo.emit({relatedInfo: this.field.relatedInfo});
  }

  showReference(e:any){
      e.stopPropagation();
      this.fieldTypeDate.references = !this.fieldTypeDate.references;
      this.showFieldReference.emit();
  }

  changeOpenDataPicker(){
    this.field.fromTo ? this.fieldTypeDate.openFromToPicker = !this.fieldTypeDate.openFromToPicker : this.fieldTypeDate.openDatePicker = !this.fieldTypeDate.openDatePicker;
  }

  openAddAlert(event:any){
    let that = this.fieldTypeDate;
    if(!this.field.noEditable){
        event.preventDefault();
        let configuration = {
            from: 'fieldTypeDate',
            type: that.type,
            defaultHour: this.alertHour,
            concept: this.concept
        };
        this.modalService.addAlert(configuration).subscribe((result:any) => {
            if(result.result === 'ok'){
                this.loader.openLoader('create-alert-field-item');
                this.createAlert.emit({alert: result});
                this.registerService.register({
                  'Distinct ID': this.context.user.externalId,
                  'Client ID': this.context.user.companyExternalId,
                  'event': 'create alert',
                  'Alert Type': that.type,
                  'Concept Name': sessionStorage.getItem('breadcrumbConcept') + this.concept.title,
                  'Centinela product': this.context.settings.defaultProduct
                });
            }
        });
    }
  }

  changeInTimePicker(event:any){
    let that = this.fieldTypeDate;
    let changeTime = event.hours !== '00' || event.hours !== that.hours || event.minutes !== '00' || event.minutes !== that.minutes;
    that.hours = event.hours;
    that.minutes = event.minutes;
    if(changeTime){
      this.changeInField('time');
    }
  }

  changeDatePicker(newValue:any){
    this.preSelectItem = newValue;
    this.changeInField('date-picker');
  }

  changeInField(type:string='') {
    let that = this.fieldTypeDate;
    this.field.errorInDateLock = false;

    if(this.preSelectItem === undefined){
        that.selectInput = 'textUndefined';
    }else{
        that.selectInput = 'text';
    }

    if(this.preSelectItem){
        let dateSelected = cloneDeep(this.preSelectItem);
        delete this.preSelectItem;
        if(type === 'time'){
            if(that.hours && that.minutes){
              dateSelected.setHours(that.hours, that.minutes);
            }else{
              dateSelected.setHours(0, 0);
            }
        }else if(type ==='date-picker'){
            if(this.field.showTime){
              dateSelected.setHours(that.hours, that.minutes);
            }else{
              dateSelected.setHours(0, 0, 0, 0);
            }
            this.dropdown.close();
        }else{
            that.hours = dateSelected.getHours();
            if(dateSelected.getMinutes() < 9){
                that.minutes = '0' + dateSelected.getMinutes();
            }else{
                that.minutes = dateSelected.getMinutes();
            }
        }
        this.preSelectItem = dateSelected;
        this.checkDateLock();
    }else if(type === 'time'){
        this.preSelectItem = new Date(new Date().setHours(that.hours, that.minutes, 0, 0));
        this.checkDateLock();
    }

    this.changeItemSelected(this.preSelectItem);
  }

  changeCalendar(event:any){
    let dates: any = event.dates;
      if(dates.type === 'apply'){
        this.preSelectItem = dates.from.format('DD/MM/YYYY')+' - '+dates.to.format('DD/MM/YYYY');
        this.fieldTypeDate.creationDateFromDate = dates.from;
        this.fieldTypeDate.creationDateToDate = dates.to;
      }else if(dates.type === 'restore'){
        this.preSelectItem = '';
      }
      setTimeout(()=>{
          if(dates.type !== 'cancel'){
              this.changeItemSelected(this.preSelectItem);
          }
         // this.fieldTypeDate.openFromToPicker = !this.fieldTypeDate.openFromToPicker;
          this.dropdown.close();
      })

  }

  outputDate(e:any){
    let that = this.fieldTypeDate;
    let dateValue: any = e.value;
    let isValid: boolean = e.isValid;
    let keyCode: any = e.e?.keyCode;
    if(keyCode !== 9){
        this.field.errorInDateLock = false;
        that.errorText ='';
        this.preSelectItem = dateValue;
        if(dateValue){
            this.preSelectItem = new Date(dateValue);
            that.time = moment(dateValue);
        }else if(!isValid){
            that.errorText = this.translate.instant('field-type-date.invalid-date');
            this.field.errorInDateLock = true;
            this.configureErrorInDateLock();
        }
        if(isValid || dateValue){
            this.changeInField();
        }
    }
  }

  configureErrorInDateLock(){
    this.field.errorInDateLock ? this.dateLock.addErrors(this.field) : this.dateLock.deleteError(this.field);
    if(this.concept){
        this.configureErrorConcept();
    }
  }

  configureErrorConcept(){
    this.field.errorInForm = this.field.errorInDateLock;
    this.commonUtilsService.configureConceptErrorForm(this.field, this.concept);
  }

  checkDateLock(){
    let that = this.fieldTypeDate;
    let today : any = moment().format('MM-DD-YYYY');
    let preselect : any = moment(this.preSelectItem).format('MM-DD-YYYY');
    switch(this.field.dateLockType){
        case 1:
            this.field.errorInDateLock = moment(preselect).isBefore(today);
            that.errorText = this.translate.instant('field-type-date.error-date-before');
            break;
        case 2:
            this.field.errorInDateLock = (moment(preselect).isBefore(today) || (moment(preselect).isSame(today)));
            that.errorText = this.translate.instant('field-type-date.error-date-before-today');
            break;
        case 3:
            this.field.errorInDateLock = moment(preselect).isAfter(today);
            that.errorText = this.translate.instant('field-type-date.error-date-after');
            break;
        case 4:
            this.field.errorInDateLock = (moment(preselect).isAfter(today) || (moment(preselect).isSame(today)));
            that.errorText = this.translate.instant('field-type-date.error-date-after-today');
            break;
    }
    this.configureErrorInDateLock();
  }

  deleteValueField(event:any){
    event.stopPropagation();
    event.preventDefault();

    this.preSelectItem = null;
    this.fieldTypeDate.hours ='00';
    this.fieldTypeDate.minutes = '00';
    this.fieldTypeDate.selectInput = 'text';
    this.field.error = false;
    this.field.errorInDateLock = false;
    this.configureErrorInDateLock();
    this.changeItemSelected(this.preSelectItem);
    this.broadcast.broadcast('resetDate' + this.field.id, {});
  }
}
