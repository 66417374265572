import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-template',
  templateUrl: './customize-field-template.component.html'
})
export class CustomizeFieldTemplateComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldTemplate: any = {
    tinyMceOptions: {},
    titleDescription: this.translate.instant('field-list.introduction') + ' ' + this.translate.instant('field-list.button-generate-document'),
    fieldTemplates: {
        label: this.translate.instant('new-document.template'),
        noEditable: false,
        required: true
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate:AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() templatesList: any = null;
  @Input() concept: any = {};
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that= this.customizeFieldTemplate;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      if(!that.field.fieldAttributes){
        that.field.fieldAttributes = {};
      }
      that.field.fieldAttributes.documentId = that.field.documentTemplateId;
      that.fieldCopy = cloneDeep(that.field);
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  getEditorValue(event:any){
    this.customizeFieldTemplate.field.description = event.model;
  }

  itemSelected(e:any, item:any){
      if(e.newValue){
          this.customizeFieldTemplate.fieldTemplates.error = false;
          this.customizeFieldTemplate.showError = false;
      }
      this.customizeFieldTemplate.field.fieldAttributes[item] = e.newValue;
      this.modifyField();
  }

  cancelFieldText(){
      this.customizeFieldTemplate.field = cloneDeep(this.customizeFieldTemplate.fieldCopy);
      this.customizeFieldTemplate.color = false;
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldTemplate.field
          }
      })
  }

  saveFieldText(){
      if(this.customizeFieldTemplate.field.fieldAttributes.documentId){
          this.customizeFieldTemplate.fieldTemplates.error = false;
          this.customizeFieldTemplate.showError = false;
          this.customizeFieldTemplate.fieldCopy = cloneDeep(this.customizeFieldTemplate.field);
          this.changeInField.emit({
              change:{
                  type: 'save',
                  field: this.customizeFieldTemplate.field
              }
          })
      }else{
          this.customizeFieldTemplate.fieldTemplates.error = true;
          this.customizeFieldTemplate.showError = true;
      }
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldTemplate.field);
  }

  changeOptionOn(e:any, type:string){
    this.customizeFieldTemplate.field[type] = e.model
    this.modifyField();
  }
}
