<div class="calendar-datepicker">
  <ngb-datepicker [ngClass]="type === 'picker-data'?'calendar-datepicker__datepicker-big':'calendar-datepicker__datepicker'"
    [ngShow]="calendarDatePicker.openDatePicker"
    #calendarDatepicker
    [displayMonths]="1"
    outsideDays="hidden"
    navigation="none"
    [weekdays]="false"
    [(ngModel)]="calendarDatePicker.selectedDate"
    (dateSelect)="changeDate($event)"
    [dayTemplate]="fromDay">
    <ng-template ngbDatepickerContent>
      <div *ngIf="!calendarDatePicker.showMonths">
        <div *ngFor="let month of calendarDatepicker.state.months"
            class="calendar-datepicker__block-month-days">
            <div class="calendar-datepicker__header-date-picker">
                <div class="calendar-datepicker__block-datepicker-title">
                  <button class="calendar-datepicker__datepicker-title"
                    (click)="calendarDatePicker.showMonths = !calendarDatePicker.showMonths">
                    {{ month.month+"-01-2023" | convertDate:'only-month' }} {{ month.year }}
                  </button>
                </div>
                <div class="calendar-datepicker__block-arrows-calendar">
                    <button type="button" class="lf-icon-angle-left calendar-datepicker__button-angle" (click)="navigate(-1);"></button>
                    <button type="button" class="lf-icon-angle-right calendar-datepicker__button-angle" (click)="navigate(1)"></button>
                </div>
            </div>
            <div class="ngb-dp-week ngb-dp-weekdays" >
                <div class="ngb-dp-weekday small"
                    *ngFor="let day of calendarDatePicker.days">
                    {{day | convertDate: calendarDatePicker.convertDateType}}
                </div>
            </div>
            <ngb-datepicker-month [month]="month"></ngb-datepicker-month>
        </div>
      </div>
      <div *ngIf="calendarDatePicker.showMonths">
          <calendar-month-year [activedMonth]="calendarDatePicker.selectedMonth"
            [activedYear]="calendarDatePicker.selectedYear"
            (navigateSelection)="navigateSelection($event)"></calendar-month-year>
      </div>
    </ng-template>
    <ng-template #fromDay
        let-date
        let-focused="focused">
        <button class="btn-light"
          [ngClass]="{'btn-light--selected': isSelectedDate(date)}"
          [disabled]="isDisabled(date)">{{ date.day  | addZero }}</button>
    </ng-template>
  </ngb-datepicker>
</div>
