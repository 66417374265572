import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentPageFactory {
  current:any = new Map();
  set(current: any){
    let id = current.conceptId?'con-'+current.conceptId : 'rep-'+current.reportId;
    this.current.set(id, current);
  }
  get(conceptId: string):any{
    return this.current.get(conceptId)
  }
}
