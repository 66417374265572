import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { ConceptStepsParentsService } from '../utils/concept-steps-parents.service';


@Component({
  selector: 'concept-steps-custom',
  templateUrl: './concept-steps-custom.component.html'
})
export class ConceptStepsCustomComponent implements OnChanges, OnInit {
  custom: any = this.customLanding.getCustom();
  conceptSteps: any = {
    catchTemplate: true,
    initialStep: 0,
    filterContainers: []
  }
  constructor(private customLanding: CustomLandingFactory,
    private conceptStepsParent: ConceptStepsParentsService) {
  }

  @Input() concept: any = null;
  @Input() filterContainers: any = null;
  @Input() changeStepsConcept: any = null;

  ngOnInit(): void {
    let that: any = this.conceptSteps;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.conceptStepsParent.onChanges(changes, this)
  }

  selectStep(fieldContainer:any){
    this.conceptStepsParent.selectStep(fieldContainer, this)
  }
}
