import { FieldTypeRichParentService } from './../utils/field-type-rich-parent.service';
import { Component, OnChanges, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';


import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'field-type-rich-text',
  templateUrl: './field-type-rich-text.component.html'
})
export class FieldTypeRichTextComponent implements OnChanges, AfterViewInit {
  isLanding: boolean = this.customLanding.isLanding();

  productTypeId: any = '';
  fieldTypeRichText: any = {
    titleField: '',
    descriptionPadding: '',
    noEditable: false,
    elementLinkList: []
  }
  constructor(private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private fieldTypeRichParent: FieldTypeRichParentService) {

  }

  @Input() field : any = {};
  @Input() concept : any = {};
  @Output() openRelatedInfo = new EventEmitter();

  ngOnChanges(): void {
    this.fieldTypeRichParent.configureChanges(this);
    this.extractRichDescription();
  }

  ngAfterViewInit(): void {
    this.clickLink();
  }

  extractRichDescription(){
    if(this.field.description){
        let typeClass:string = this.isLanding? 'field-type-rich-text__rich-text-custom' : 'field-type-rich-text__rich-text';
        let descriptionPadding = this.field.description;
        descriptionPadding = descriptionPadding.split('style="padding-left: 30px;"').join('class="'+typeClass+'--indent-rich"');
        descriptionPadding = descriptionPadding.split('style="list-style-type: disc;"').join('class="'+typeClass+'--disc"');
        descriptionPadding = descriptionPadding.split('style="list-style-type: circle;"').join('class="'+typeClass+'--circle"');
        descriptionPadding = descriptionPadding.split('style="list-style-type: square;"').join('class="'+typeClass+'--square"');
        if(descriptionPadding.indexOf('<li>')!== -1){
            let elementListOpen='<li><span>';
            descriptionPadding = descriptionPadding.replace(/<li>/gi , elementListOpen);
        }
        let description = this.fieldTypeRichParent.extractRelatedOrReferences(this, 'data-related="',  descriptionPadding);
        this.fieldTypeRichText.descriptionPadding = this.fieldTypeRichParent.extractRelatedOrReferences(this, 'data-references="', description);
        if(this.field.evaluatedValue){
          // vm.fieldInfo.evaluatedValue.displayData = this.fieldTypeRichText.descriptionPadding;//.replace( /(<([^>]+)>)/ig, '');
          this.field.evaluatedValue.displayData = this.fieldTypeRichText.descriptionPadding.replace(/&nbsp;/g, ' ');
        }
    }
  }

  shareField(field:any){
    this.modalService.shareInstance(this.concept, false, 'knowledge').subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loader.openLoader('field-item-shared-field')
        this.apiService.add('fields/share/'+field.fieldId+'/evaluation/'+field.evaluationId, result.shared)
            .then((data:any)=>{
              this.loader.closeLoader(['field-item-shared-field']);
              this.modalService.adviceModal(this.translate.instant('field-items.shared-field'), 'info:accept:check-round');
            }, (errorData:any)=>{
                this.loader.closeError();
                this.modalService.adviceModal(this.translate.instant('field-items.shared-error'), 'error:accept:warning');
            })
      }
    })
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeRichParent.openModalRelatedInfo(e, fieldRelatedInfo, this)
  }

  analyzeWithSibila(){
    this.fieldTypeRichParent.analyzeWithSibila(this);
  }

  clickLink(){
    let elementField: any = document.getElementById('field-type-rich-'+this.field.fieldId);
    let elementLinkCollection: any = elementField.getElementsByClassName('common__tiny-link');
    this.fieldTypeRichText.elementLinkList = [].slice.call(elementLinkCollection)
    this.fieldTypeRichText.elementLinkList.forEach((element:any, index:any) => {
      element.addEventListener('click', (event: any)=>{
        this.fieldTypeRichText.elementLinkList.forEach((elementClick:any) => {
            if(event.currentTarget.id === elementClick.id){
                let type = '(click)="openRelated(';
                let position = elementClick.outerHTML.indexOf(type)+type.length;
                let position2 = elementClick.outerHTML.indexOf('}')-position+1;
                let item = elementClick.outerHTML.substr(position, position2);
                item = JSON.parse(item.replaceAll("'", '"'));
                this.fieldTypeRichParent.openRelated(this, item);
            }
        })
      })
    });
  }

}
