<div class="related-information-modal">
  <div class="related-information-modal__block-context"
      *ngIf="!isLanding">
      <div class="common__modal-header-block">
          <i class="lf-icon-search-text common__icon-header"></i>
          <span class="common__text-header"
              [afTranslate]="'common.related-info'">_Información relacionada</span>
          <i class="lf-icon-close common__icon-close-modal"
              (click)="handleDismiss()"></i>
          <span class="related-information-modal__product-name"
              [ngShow]="infoRelated?.length === 1">{{relatedInformationModal.infoRelatedSelected?.evaluationName}}</span>
      </div>
      <div class="related-information-modal__block-body-left"
          [ngShow]="infoRelated?.length > 1">
          <div *ngFor="let related of infoRelated"
              class="related-information-modal__element-left"
              (click)="selectRelatedInfo(related)">
              <i class="lf-icon-subject related-information-modal__icon-subject"></i>
              <span class="related-information-modal__text-related-left"
                  [ngClass]="{'related-information-modal__text-related-left--selected': relatedInformationModal.infoRelatedSelected?.fieldId === related.relatedInfoFieldId || relatedInformationModal.infoRelatedSelected?.fieldId === related.relatedFieldId}">{{related.text}}</span>
          </div>
      </div>
      <div class="related-information-modal__block-body"
          [ngClass]="{'related-information-modal__block-body--big': infoRelated?.length > 1}">
          <div [ngShow]="infoRelated?.length > 1"
              class="related-information-modal__block-title-selected">
              <p class="related-information-modal__title-selected">{{relatedInformationModal.infoRelatedSelected?.title}}</p>
              <p class="related-information-modal__subtitle-selected">{{relatedInformationModal.infoRelatedSelected?.evaluationName}}</p>
          </div>
          <!-- Migas de pan -->
          <div class="print-block">
              <div class="related-information-modal__block-breadcrumbs">
                  <i class="lf-icon-map-marker related-information-modal__icon-map"></i>
                  <div class="related-information-modal__block-breadcrumbs-text">
                      <div *ngFor="let breadcrumb of relatedInformationModal.relatedModalList[0].route; let first = first; let index = index"
                          class="related-information-modal__title-breadcrumb">
                          <div class="common__cell-block">
                              <span class="related-information-modal__symbol-minus"
                                  [ngShow]="!first"> > </span>
                              <span-tooltip [text]="breadcrumb.name"></span-tooltip>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="related-information-modal__block-rich-text">
                  <p [ngShow]="relatedInformationModal.infoRelatedSelected?.label"
                      class="related-information-modal__title-text-rich field-item__rich-text">{{relatedInformationModal.infoRelatedSelected?.label}}</p>
                  <div [innerHTML]="relatedInformationModal.description"></div>
              </div>
          </div>
          <div class="common__buttons-block common__buttons-block--modal">
              <button type="submit"
                  class="common__button common__button--active"
                  (click)="print()"
                  [afTranslate]="'common.print'">_Imprimir</button>
              <button type="submit"
                  class="common__button common__button--active"
                  (click)="openNewWindow()"
                  [ngShow]="relatedInformationModal.infoRelatedSelected?.frontIds?.conceptObjectId"
                  [afTranslate]="'related-information-modal.new-label'">_Ver en pestaña nueva</button>
          </div>
      </div>
  </div>

  <div class="related-information-modal__block-context"
    *ngIf="isLanding">
      <div *ngIf="infoRelated?.length === 1">
          <i class="lf-icon-close-round related-information-modal__icon-close-custom"></i>
          <span class="related-information-modal__product-name">{{relatedInformationModal.infoRelatedSelected?.evaluationName}}</span>
      </div>
      <div class="common__modal-header-block common__modal-header-block--custom-margin"
          [ngStyle]="{'background-color': custom.color}">
          <i class="lf-icon-search-text common__icon-header common__icon-header--custom" ></i>
          <span class="common__text-header common__text-header--custom"
              [afTranslate]="'common.related-info'">_Información relacionada</span>
      </div>
      <div class="related-information-modal__block-body-left"
          *ngIf="infoRelated?.length > 1">
          <div *ngFor="let related of infoRelated"
              class="related-information-modal__element-left"
              (click)="selectRelatedInfo(related)">
              <i class="lf-icon-subject related-information-modal__icon-subject"></i>
              <span class="related-information-modal__text-related-left"
                  [ngClass]="{'related-information-modal__text-related-left--selected': relatedInformationModal.infoRelatedSelected?.fieldId === related.relatedInfoFieldId || relatedInformationModal.infoRelatedSelected?.fieldId === related.relatedFieldId}">{{related.text}}</span>
          </div>
      </div>
      <div class="related-information-modal__block-body"
          [ngClass]="{'related-information-modal__block-body--big': infoRelated?.length > 1}">
          <div *ngIf="infoRelated?.length > 1"
              class="related-information-modal__block-title-selected">
              <p class="related-information-modal__title-selected">{{relatedInformationModal.infoRelatedSelected?.title}}</p>
              <p class="related-information-modal__subtitle-selected">{{relatedInformationModal.infoRelatedSelected?.evaluationName}}</p>
          </div>
          <!-- Migas de pan -->
          <div class="print-block">
              <div class="related-information-modal__block-breadcrumbs">
                  <i class="lf-icon-map-marker related-information-modal__icon-map"></i>
                  <div class="related-information-modal__block-breadcrumbs-text">
                      <div *ngFor="let breadcrumb of relatedInformationModal.relatedModalList[0].route; let first = first; let index = index"
                          class="related-information-modal__title-breadcrumb">
                          <div class="common__cell-block">
                              <span class="related-information-modal__symbol-minus"
                                  [ngShow]="!first"> > </span>
                              <span-tooltip [text]="breadcrumb.name"></span-tooltip>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="related-information-modal__block-rich-text">
                  <p [ngShow]="relatedInformationModal.infoRelatedSelected?.label"
                      class="related-information-modal__title-text-rich field-item__rich-text">{{relatedInformationModal.infoRelatedSelected?.label}}</p>
                  <div [innerHTML]="relatedInformationModal.description"></div>
              </div>
          </div>
          <div class="common__buttons-block common__buttons-block--modal">
              <button type="submit"
                  class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                  (click)="print()"
                  [afTranslate]="'common.print'">_Imprimir</button>
              <button type="submit"
                  class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                  (click)="openNewWindow()"
                  *ngIf="relatedInformationModal.infoRelatedSelected?.frontIds.conceptObjectId"
                  [afTranslate]="'related-information-modal.new-label'">_Ver en pestaña nueva</button>
          </div>
      </div>
  </div>









</div>
