<div class="consult-lists-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-simulator-rrhh common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'field-type-consult-lists.consult-list'">_Consultar listas</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="consult-lists-modal__body"
      *ngIf="consultListsModal.configuration.infoModal.configurated">
      <p class="common__title-block-modal consult-lists-modal__title-block-modal"
          [afTranslate]="'consult-lists-modal.introduction-modal'">_Este es un resumen de tu consulta. Genera el informe para obtener un documento con todos los datos disponibles y asociarlo a la evaluación del cliente.</p>
      <div class="consult-lists-modal__form-block">
          <div [class]="'consult-lists-modal__left-block-form consult-lists-modal__left-block-form--' + consultListsModal.configuration.infoModal.resultColor">
              <div class="consult-lists-modal__header-name">
                  <p class="consult-lists-modal__name-text">{{consultListsModal.configuration.typePerson === 'natural'?consultListsModal.configuration.personData.name:consultListsModal.configuration.personData.socialReason}}</p>
                  <p class="consult-lists-modal__name-text"
                      [ngShow]="consultListsModal.configuration.typePerson === 'natural'">{{consultListsModal.configuration.personData.surname1}}</p>
                  <p class="consult-lists-modal__name-text"
                      [ngShow]="consultListsModal.configuration.typePerson === 'natural'">{{consultListsModal.configuration.personData.surname2}}</p>
                  <p class="consult-lists-modal__identity-text">{{consultListsModal.configuration.personData.documentNumber}}</p>
              </div>
              <div class="consult-lists-modal__block-icon-answer">
                  <i [class]="consultListsModal.configuration.infoModal.iconAnswer +' consult-lists-modal__icon-answer consult-lists-modal__icon-answer--' + consultListsModal.configuration.infoModal.resultColor"></i>
                  <p class="consult-lists-modal__mesasge-answer">{{consultListsModal.configuration.infoModal.messageAnswer}}</p>
              </div>
          </div>
          <div class="consult-lists-modal__right-block-form">
              <div class="consult-lists-modal__body-detail-answer">
                  <div class="consult-lists-modal__body-detail-header">
                      <p class="consult-lists-modal__detail-name-text">
                          <span [afTranslate]="'consult-lists-modal.proceedings'">_Expediente</span>:
                          <span>{{consultListsModal.configuration.infoModal.dossierId}}</span>
                      </p>
                      <p class="consult-lists-modal__detail-name-text">
                          <span [afTranslate]="'all-documentation.consult-date'">_Fecha de consulta</span>:
                          <span>{{consultListsModal.configuration.infoModal.exportDate | convertDate}}</span>
                      </p>
                      <p class="consult-lists-modal__detail-name-text">
                          <span [afTranslate]="'consult-lists-modal.information-provider'">_Proveedor de la información</span>:
                          <span>{{consultListsModal.configuration.infoModal.provider}}</span>
                      </p>
                      <p class="consult-lists-modal__detail-name-text">
                          <span [afTranslate]="'consult-lists-modal.detections'">_Nº de detecciones</span>:
                          <span>{{consultListsModal.configuration.infoModal.detections?.length}}</span>
                      </p>
                  </div>
                  <div class="consult-lists-modal__body-detail-form"
                      *ngIf="consultListsModal.configuration.infoModal.detections?.length">
                      <div *ngFor="let detection of consultListsModal.configuration.infoModal.detections"
                          class="consult-lists-modal__body-person-array">
                          <div class="consult-lists-modal__title-person-array">
                              <button class="consult-lists-modal__icon-info-detail"
                                  [ngClass]="detection.showMore? 'lf-icon-less-empty':'lf-icon-add-empty'"
                                  (click)="detection.showMore = !detection.showMore"></button>
                              <span>{{detection.name}}</span>
                          </div>
                          <div class="consult-lists-modal__datainfo-array"
                              [ngShow]="detection.showMore">
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.confidenceLevel">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.confidence-level'">_Nivel de confianza</div>
                                  <div class="consult-lists-modal__datainfo-text">{{detection.confidenceLevel}}</div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.personType">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.person-type'">_Tipo de persona</div>
                                  <div class="consult-lists-modal__datainfo-text"
                                      [afTranslate]="detection.personType === 'NATURAL'?'consult-lists-modal.person-natural':'consult-lists-modal.person-artifitial'"></div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.post">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.post'">_Post</div>
                                  <div class="consult-lists-modal__datainfo-text">{{detection.post}}</div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.state">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.state'">_Estado</div>
                                  <div class="consult-lists-modal__datainfo-text"
                                      [afTranslate]="detection.state === 'ACTIVE'?'consult-lists-modal.state-active':'consult-lists-modal.state-inactive'"></div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.creationDate">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.creation-date'">_Fecha de creación</div>
                                  <div class="consult-lists-modal__datainfo-text">{{detection.creationDate | convertDate: 'without-time'}}</div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.aliases?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.alias'">_Alias</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let alias of detection.aliases"
                                          class="consult-lists-modal__datainfo-list-text">{{alias.name}} {{alias.aliasType}}</div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.birthDates?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.born-dates'">_Fechas de nacimiento</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let birthDate of detection.birthDates"
                                          class="consult-lists-modal__datainfo-list-text">{{birthDate.bornDate | convertDate: 'without-time'}}</div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.addresses?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.addresses'">_Direcciones</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let address of detection.addresses"
                                          class="consult-lists-modal__datainfo-list-text">
                                          <div [ngShow]="address.comments">{{address.comments}} {{address.country}}</div>
                                          <div [ngShow]="!address.comments">{{address.address1}} {{address.city}} {{address.country}}</div>
                                      </div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.identificationDocuments?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.identification'">_Documentos de identidad</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let identificator of detection.identificationDocuments"
                                          class="consult-lists-modal__datainfo-list-text">{{identificator.documentNumber}} <span *ngIf="identificator.type">(<span [afTranslate]="'consult-lists-modal.'+identificator.type.toLowerCase()"></span>)</span></div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.prpType">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.prp-type'">_Tipo de detección</div>
                                  <div class="consult-lists-modal__datainfo-text">{{detection.prpType}}</div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.prpCategories?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.prp-categories'">_Categorías de detección</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let categorie of detection.prpCategories"
                                          class="consult-lists-modal__datainfo-list-text">{{categorie}}</div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.sanctions?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.sanctions'">_Lista de sanciones</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let sanction of detection.sanctions"
                                          class="consult-lists-modal__datainfo-list-text">{{sanction.name}}: {{sanction.startingDate | convertDate: 'without-time'}}</div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.activities?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.activities'">_Ocupaciones</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let activity of detection.activities | orderBy:'startingDateInPost'"
                                          class="consult-lists-modal__datainfo-list-text">{{activity.description}} ({{activity.type}} - {{activity.post}})
                                          <span [ngShow]="activity.startingDateInPostSpecified"><span [afTranslate]="'consult-lists-modal.from'">_Desde</span>: {{activity.startingDateInPost | convertDate: 'without-time'}}</span>
                                          <span [ngShow]="activity.endingDateInPostSpecified"><span [afTranslate]="'consult-lists-modal.until'">_Hasta</span>: {{activity.endingDateInPost | convertDate: 'without-time'}}</span>
                                      </div>
                                  </div>
                              </div>
                              <div class="consult-lists-modal__datainfo-col"
                                  *ngIf="detection.relations?.length">
                                  <div class="consult-lists-modal__datainfo-type"
                                      [afTranslate]="'consult-lists-modal.relations'">_Relaciones conocidas</div>
                                  <div class="consult-lists-modal__datainfo-text">
                                      <div *ngFor="let relation of detection.relations"
                                          class="consult-lists-modal__datainfo-list-text">{{relation.name}}.
                                          <span [afTranslate]="'consult-lists-modal.relation'">_Relación</span>: {{relation.type}} <span [ngShow]="!relation.previous">(<span [afTranslate]="'consult-lists-modal.relation-current'">_relación actual</span>)</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="submit"
          class="common__button common__button--active"
          (click)="generateReport()"
          [afTranslate]="'finish-block-modal.generate-report'">Generar informe</button>
  </div>
</div>
<report-consult-lists-pdf class="common__pdf-hidde-element"
  [configuration]="consultListsModal.configuration"></report-consult-lists-pdf>
