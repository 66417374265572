<div class="report-record-detail__table-block">
    <div class="report-record-detail__table">
        <div class="report-table__table-container">
        <!-- Cabecera -->
            <div class="report-record-detail__table-header" >
                <div *ngFor="let column of columns; let index = index">
                    <div class="report-table__table-col report-record-detail__table-col" [ngStyle]="{'width':column.displayWidth, 'margin':column.margin}">
                        <div class="report-table__title-column">
                            <div [ngSwitch]="column.columnType">
                              <div *ngSwitchCase="'1'">
                                <filter-by-search
                                    [item]="column"
                                    [adjustClass]="index === columns.length -1 || index === columns.length -2?'common__select-list-with-input--left':''"
                                    [textColumn]="'textColumn'"
                                    [filterText]="reportRecordDetail['search'+column.id]"
                                    (launchActionSearch)="orderBySearch($event, column.id)">
                                </filter-by-search>
                              </div>
                              <div class="concept-list__table-col concept-list__table-col--modification-query concept-list__table-col--top-2"
                                  *ngSwitchCase="'2'"
                                  ngbDropdown
                                  [id]="column.id"
                                  [autoClose]="'outside'">
                                  <p class="common__table-header-text common__cursor-pointer report-record-detail__date-text"
                                      ngbDropdownToggle
                                      matTooltip="{{column.textColumn}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below">{{column.textColumn}}</p>
                                  <i class="common__filter-icon lf-icon-filter-1"
                                      [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': column[column.id+'DateFilter']}"
                                      ngbDropdownToggle></i>
                                  <div class="concept-list__calendar-dropdown common__block-option-list"
                                        ngbDropdownMenu role="menu">
                                      <from-to-calendar [fromSave]="column[column.id+'DateFromDate']"
                                          [toSave]="column[column.id+'DateToDate']"
                                          (changeCalendar)="changeCalendar($event, column)"
                                          [type]="'report-filter-as-icam'">
                                      </from-to-calendar>
                                  </div>
                              </div>
                              <filter-by-list *ngSwitchCase="'3'"
                                  [type]="'list'"
                                  [item]="column"
                                  [textColumn]="'textColumn'"
                                  [adjustClass]="index === columns.length -1 || index === columns.length -2?'common__select-list-with-input--left':''"
                                  [list]="reportRecordDetail[column.id+'List']"
                                  (filterElementEmit)="selectTypeOperation($event, column)">
                              </filter-by-list>
                              <filter-by-list *ngSwitchCase="'4'"
                                  [type]="'listFind'"
                                  [item]="column"
                                  [textColumn]="'textColumn'"
                                  [adjustClass]="index === columns.length -1 || index === columns.length -2?'common__select-list-with-input--left':''"
                                  [list]="reportRecordDetail[column.id+'List']"
                                  (filterElementEmit)="selectTypeOperation($event, column)">
                              </filter-by-list>
                              <div *ngSwitchDefault>
                                <span-tooltip class="common__col-text common__table-header-text"
                                    [ngClass]="{'common__table-header-text--right': column.fieldTypeId === 'options',
                                      'common__table-header-text--order': column.canOrder}"
                                    [text]="column.textColumn"></span-tooltip>
                                <i class="common__order-by"
                                    [ngShow]="column.canOrder"
                                    [ngClass]="{'lf-icon-arrow-down':(reportRecordDetail.dateOrder===column.id && reportRecordDetail.orderOperationDates) || reportRecordDetail.dateOrder!==column.id,
                                        'lf-icon-arrow-up': reportRecordDetail.dateOrder===column.id && !reportRecordDetail.orderOperationDates,
                                        'common__order-by--active': reportRecordDetail.dateOrder===column.id}"
                                    (click)="orderDate(column.id)">
                                </i>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="report-record-detail__col report-record-detail__col--actions">
                    <div class="report-record-detail__col-actions">
                        <p class="common__table-header-text common__table-header-text--center report-record-detail__table-header-action" [afTranslate]="'common.actions'"></p>
                    </div>
                </div>
            </div>
            <div class="report-table__table-body">
                <div [id]="'row'+ index" class="report-record-detail__rows"
                    *ngFor="let row of reportRecordDetail.filterReportRecordDetailList | afOrderBy:reportRecordDetail.dateOrder:!reportRecordDetail.orderOperationDates; let index = index; let last = last">
                    <div class="report-table__rows-container report-record-detail__rows-block"
                        [ngClass]="{'report-table__rows-container--disabled': reportRecordDetail.archived}">
                        <report-table-column
                          class="report-table__table-col report-record-detail__table-col"
                          *ngIf="row.OFAFMVJfield0.key !== 'OFAFMVJ-42' && row.OFAFMVJfield0.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield0.displayWidth, 'margin': row.OFAFMVJfield0.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield0"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield1.key !== 'OFAFMVJ-42' && row.OFAFMVJfield1.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield1.displayWidth, 'margin': row.OFAFMVJfield1.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield1"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield2.key !== 'OFAFMVJ-42' && row.OFAFMVJfield2.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield2.displayWidth, 'margin': row.OFAFMVJfield2.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield2"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield3.key !== 'OFAFMVJ-42' && row.OFAFMVJfield3.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield3.displayWidth, 'margin': row.OFAFMVJfield3.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield3"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield4.key !== 'OFAFMVJ-42' && row.OFAFMVJfield4.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield4.displayWidth, 'margin': row.OFAFMVJfield4.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield4"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield5.key !== 'OFAFMVJ-42' && row.OFAFMVJfield5.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield5.displayWidth, 'margin': row.OFAFMVJfield5.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield5"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield6.key !== 'OFAFMVJ-42' && row.OFAFMVJfield6.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield6.displayWidth, 'margin': row.OFAFMVJfield6.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield6"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield7.key !== 'OFAFMVJ-42' && row.OFAFMVJfield7.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield7.displayWidth, 'margin': row.OFAFMVJfield7.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield7"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield8.key !== 'OFAFMVJ-42' && row.OFAFMVJfield8.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield8.displayWidth, 'margin': row.OFAFMVJfield8.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield8"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield9.key !== 'OFAFMVJ-42' && row.OFAFMVJfield9.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield9.displayWidth, 'margin': row.OFAFMVJfield9.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield9"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield10.key !== 'OFAFMVJ-42' && row.OFAFMVJfield10.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield10.displayWidth, 'margin': row.OFAFMVJfield10.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield10"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield11.key !== 'OFAFMVJ-42' && row.OFAFMVJfield11.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield11.displayWidth, 'margin': row.OFAFMVJfield11.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield11"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield12.key !== 'OFAFMVJ-42' && row.OFAFMVJfield12.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield12.displayWidth, 'margin': row.OFAFMVJfield12.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield12"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield13.key !== 'OFAFMVJ-42' && row.OFAFMVJfield13.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield13.displayWidth, 'margin': row.OFAFMVJfield13.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield13"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield14.key !== 'OFAFMVJ-42' && row.OFAFMVJfield14.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield14.displayWidth, 'margin': row.OFAFMVJfield14.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield14"
                          [column]="columns"></report-table-column>
                        <report-table-column class="report-table__table-col"
                          *ngIf="row.OFAFMVJfield15.key !== 'OFAFMVJ-42' && row.OFAFMVJfield15.value !== 'OFAFMVJ-42'"
                          [ngStyle]="{'width': row.OFAFMVJfield15.displayWidth, 'margin': row.OFAFMVJfield15.margin}"
                          [row]="row"
                          [rowType]="row.OFAFMVJfield15"
                          [column]="columns"></report-table-column>
                        <div class="report-record-detail__col report-record-detail__col--actions">
                          <div class="report-record-detail__col-actions-body-shadow"
                            [ngClass]="{'report-record-detail__col-actions-body-shadow--more': report.reportId === reportRecordDetail.context.folderReportVersion}">
                            <div class="report-record-detail__col-actions-body">
                              <i class="lf-icon-kebab-menu report-record-detail__icon-action-body report-record-detail__icon-action-body"
                                matTooltip="{{'report-table.icon-tooltip' | afTranslate}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                (click)="relatedConcept(row)"></i>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
