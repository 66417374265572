<div class="organization-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-label-user common__icon-header"></i>
      <span class="common__text-header">{{organizationModal.title}}</span>
      <div class="organization-modal__edit-block"
          [ngShow]="organizationModal.type === 'edit' && skin !== 'uk-steel-blue'">
          <div class="organization-modal__edit-text"
              (click)="seeStructurHolding()"
              *ngIf="organizationModal.filialsData?.children">
              <i class="lf-icon-information organization-modal__alert-icon"></i>
              <span [afTranslate]="'new-client.structure-holding'"></span>
          </div>
      </div>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="organization-modal__block-body"
      [ngHide]="organizationModal.seeHolding">
      <form class="organization-modal__form-block"
            [ngClass]="{'organization-modal__form-block--error': organizationModal.showError}"
            [ngShow]="!organizationModal.seeHolding"
            autocomplete="off">
          <div class="organization-modal__block-business-group"
               *ngIf="skin !== 'uk-steel-blue'">
              <field-type-switch [label]="'new-client.register-as-a-affiliated-company'|afTranslate"
                                   [checked]="organizationModal.businessGroup"
                                   [disabled]="(!organizationModal.createFilials || organizationModal.disabledField) || (organizationModal.type ==='edit' && organizationModal.onlyAffiliated)"
                                   (callback)="checkAffiliated()">
              </field-type-switch>
          </div>
          <div class="organization-modal__input organization-modal__input--half-left"
              *ngIf="organizationModal.businessGroup">
              <field-type-list-simple [field]="organizationModal.clientParentId"
                               [preSelectItem]="organizationModal.client.parentId"
                               [listValues]="organizationModal.clientListModify"
                               (itemSelected)="itemSelected('parentId', $event.newValue)">
              </field-type-list-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--half-right"
              *ngIf="organizationModal.businessGroup">
              <field-type-number [field]="organizationModal.clientPercent"
                                 [preSelectItem]="organizationModal.client.percentage"
                                 (itemSelected)="itemSelected('percentage', $event.newValue)">
              </field-type-number>
          </div>
          <div class="organization-modal__input organization-modal__input--all"
              *ngIf="skin != 'uk-steel-blue'">
              <field-type-logo [field]="organizationModal.clientLogo"
                               [type]="organizationModal.type"
                               [preSelectItem]="organizationModal.logos"
                               [logoInfo]="organizationModal.logoInfo"
                               (itemSelected)="itemSelected('logo', $event.newValue)"
                               [from]="'organization-modal'">
              </field-type-logo>
          </div>
          <div class="organization-modal__input"
              [ngClass]="skin === 'uk-steel-blue' ? 'organization-modal__input--input-center' : 'organization-modal__input--input-left'">
              <field-type-text-simple [field]="organizationModal.clientName"
                               [preSelectItem]="organizationModal.client.name"
                               (itemSelected)="itemSelected('name', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--center-block"
              *ngIf="skin === 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientReference2"
                               [preSelectItem]="organizationModal.client.reference2"
                               (itemSelected)="itemSelected('reference2', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-center"
              *ngIf="skin === 'uk-steel-blue' && organizationModal.clientTypeId">
              <field-type-list-simple [field]="organizationModal.clientTypeId"
                               [preSelectItem]="organizationModal.client.clientTypeId"
                               [listValues]="organizationModal.clientTypes"
                               (itemSelected)="itemSelected('clientTypeId', $event.newValue)">
              </field-type-list-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-center">
              <field-type-text-simple [field]="organizationModal.clientCif"
                               [preSelectItem]="organizationModal.client.cif"
                               (itemSelected)="itemSelected('cif', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--center-block"
              *ngIf="skin === 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientNiNumber"
                               [preSelectItem]="organizationModal.client.nI_Number"
                               (itemSelected)="itemSelected('nI_Number', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-center"
              *ngIf="skin === 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientVatNumber"
                               [preSelectItem]="organizationModal.client.vaT_Number"
                               (itemSelected)="itemSelected('vaT_Number', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-left">
              <field-type-text-simple [field]="organizationModal.clientAddress"
                               [preSelectItem]="organizationModal.client.address"
                               (itemSelected)="itemSelected('address', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-right"
              *ngIf="skin === 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientCompanyNumber"
                               [preSelectItem]="organizationModal.client.company_Number"
                               (itemSelected)="itemSelected('company_Number', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-right"
            *ngIf="skin != 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientPostalCode"
                               [preSelectItem]="organizationModal.client.postalCode"
                               (itemSelected)="itemSelected('postalCode', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-center">
              <field-type-text-simple [field]="organizationModal.clientCity"
                               [preSelectItem]="organizationModal.client.city"
                               (itemSelected)="itemSelected('city', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--center-block"
              *ngIf="skin === 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientPostalCode"
                               [preSelectItem]="organizationModal.client.postalCode"
                               (itemSelected)="itemSelected('postalCode', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--center-block"
              *ngIf="skin !== 'uk-steel-blue'">
              <field-type-text-simple [field]="organizationModal.clientProvince"
                               [preSelectItem]="organizationModal.client.province"
                               (itemSelected)="itemSelected('province', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-center">
              <field-type-text-simple [field]="organizationModal.clientCountry"
                               [preSelectItem]="organizationModal.client.country"
                               (itemSelected)="itemSelected('country', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-left">
              <field-type-text-simple [field]="organizationModal.clientEmail"
                               [preSelectItem]="organizationModal.client.email"
                               (itemSelected)="itemSelected('email', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div class="organization-modal__input organization-modal__input--input-right">
              <field-type-text-simple [field]="organizationModal.clientPhoneNumber"
                               [preSelectItem]="organizationModal.client.phoneNumber"
                               (itemSelected)="itemSelected('phoneNumber', $event.newValue)">
              </field-type-text-simple>
          </div>
          <div *ngIf="skin === 'uk-steel-blue'">
              <div class="organization-modal__input organization-modal__input--input-left">
                  <field-type-text-simple [field]="organizationModal.clientEmail2"
                                   [preSelectItem]="organizationModal.client.email2"
                                   (itemSelected)="itemSelected('email2', $event.newValue)">
                  </field-type-text-simple>
              </div>
              <div class="organization-modal__input organization-modal__input--input-right">
                  <field-type-text-simple [field]="organizationModal.clientPhoneNumber2"
                                   [preSelectItem]="organizationModal.client.phoneNumber2"
                                   (itemSelected)="itemSelected('phoneNumber2', $event.newValue)">
                  </field-type-text-simple>
              </div>
              <div class="organization-modal__input organization-modal__input--input-center">
                  <field-type-text-simple [field]="organizationModal.clientContactName1"
                                   [preSelectItem]="organizationModal.client.contactName1"
                                   (itemSelected)="itemSelected('contactName1', $event.newValue)">
                  </field-type-text-simple>
              </div>
              <div class="organization-modal__input organization-modal__input--center-block">
                  <field-type-text-simple [field]="organizationModal.clientContactName2"
                                   [preSelectItem]="organizationModal.client.contactName2"
                                   (itemSelected)="itemSelected('contactName2', $event.newValue)">
                  </field-type-text-simple>
              </div>
              <div class="organization-modal__input organization-modal__input--input-center">
                  <field-type-text-simple [field]="organizationModal.clientContactName3"
                                   [preSelectItem]="organizationModal.client.contactName3"
                                   (itemSelected)="itemSelected('contactName3', $event.newValue)">
                  </field-type-text-simple>
              </div>
          </div>
          <div class="organization-modal__input organization-modal__input--all">
              <field-type-text-multiline *ngIf="skin !== 'uk-steel-blue'"
                               [field]="organizationModal.clientObservations"
                               [preSelectItem]="organizationModal.client.observations"
                               (itemSelected)="itemSelected('observations', $event.newValue)">
              </field-type-text-multiline>
          </div>

          <!-- Banda inferior de información -->
          <advice-message [ngShow]="organizationModal.showError && skin === 'uk-steel-blue'"
                          [icon]="'warning'"
                          [type]="'error'"
                          [text]="organizationModal.errorReference"
                          (closeAdvice)="closeAdvice('showError')">
          </advice-message>
          <div class="organization-modal__buttons-block">
              <div class="common__buttons-block common__buttons-block--modal-2" [ngShow]="organizationModal.type === 'new'">
                  <button class="common__button common__button--clean"
                          (click)="clearForm()"
                          [afTranslate]="'common.clean'"></button>
                  <button type="submit" class="common__button common__button--active"
                          (click)="createOrSaveClient()"
                          [afTranslate]="'common.create'"></button>
              </div>
              <div class="common__buttons-block common__buttons-block--modal-2" [ngHide]="organizationModal.type === 'new' || !organizationModal.organization.canAdmin">
                  <button type="reset" class="common__button common__button--clean organization-modal__cancel-button"
                          (click)="handleDismiss()"
                          [afTranslate]="'common.cancel'"></button>
                  <button type="submit" class="common__button common__button--active"
                          (click)="createOrSaveClient()"
                          [afTranslate]="'common.save'"></button>
              </div>
          </div>
      </form>
  </div>
  <div class="organization-modal__holding-block" *ngIf="organizationModal.seeHolding">
      <organization-chart [client]="organizationModal.client"
          [filials]="organizationModal.filialsData"
          (goBack)="seeStructurHolding()"></organization-chart>
  </div>
</div>
