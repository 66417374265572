import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'new-instant-videoconference',
  templateUrl: './new-instant-videoconference.component.html'
})
export class NewInstantVideoconferenceComponent {
  newInstantVideoconference: any = {
    optionSelect: 'open',
    stepState: 1,
    showText: true,
    buttonNext: this.translate.instant('common.next'),
    message: this.translate.instant('instant-videoconferences.message-meeting'),
    tinyMceOptions: {
      noEditable: false,
      placeholder: this.translate.instant('instant-videoconferences.message-meeting'),
      toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | bullist numlist outdent indent | removeformat | link',
      height: 189
    },
    titleVideoconference: {
        label: this.translate.instant('instant-videoconferences.meeting-title'),
        required: true
    },
    locationVideoconference: {
      label: this.translate.instant('task-edit.place')
    },
    videoconference: {
        participants: [],
        title: '',
        location: '',
        description: '',
        files: [],
        password: '',
        url: ''
    },
    instantVideoconferenceContact: {
        typeConfiguration: 'instant-videoconference',
        fieldType: 'multiple',
        addContacts: true,
        required: true,
        typeCall: 'contacts-users-only',
        selectContacts: [],
        id: 'instant-videoconference-contact'
    },
    steps: [
        {
            name: this.translate.instant('instant-videoconferences.assistants')
        }, {
            name: this.translate.instant('task-form.connection-data')
        }
    ]
  }
  constructor(private activeModal: NgbActiveModal,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService) { }

  itemSelected(newValue: any, type: any){
      this.newInstantVideoconference.videoconference[type] = newValue;
      if(newValue){
          this.newInstantVideoconference[type + 'Videoconference'].error = false;
      }
  }

  receiveContacts(contacts: any){
      this.newInstantVideoconference.instantVideoconferenceContact.selectContacts = contacts;
      if(this.newInstantVideoconference.instantVideoconferenceContact.selectContacts.length){
          this.newInstantVideoconference.instantVideoconferenceContact.error = false;
      }
  }

  contactsClosed() {
      this.newInstantVideoconference.showText = !this.newInstantVideoconference.showText;
  }

  copyText(copy: any) {
      this.commonUtilsService.clipboardText(copy);
  }

  continueOptions(){
    switch(this.newInstantVideoconference.stepState){
        case 1:
            if(this.newInstantVideoconference.setInvitation){
                this.checkFields();
            }else{
                if(this.newInstantVideoconference.instantVideoconferenceContact.selectContacts.length){
                    this.newInstantVideoconference.instantVideoconferenceContact.error = false;
                    this.getParticipants();
                    this.createNewVideoconference();
                }else{
                    this.newInstantVideoconference.instantVideoconferenceContact.error = true;
                }
            }
            break;
        case 2:
            this.handleAccept();
            break;
        default:
            break;
    }
  }

  checkFields(){
    let errorFields = 0;

    if(this.newInstantVideoconference.videoconference.title !== '' && this.newInstantVideoconference.videoconference.title !== null){
        this.newInstantVideoconference.titleVideoconference.error = false;
    }else{
        errorFields++;
        this.newInstantVideoconference.titleVideoconference.error = true;
    }
    if(this.newInstantVideoconference.instantVideoconferenceContact.selectContacts.length){
        this.getParticipants();
        this.newInstantVideoconference.instantVideoconferenceContact.error = false;
    }else{
        errorFields++;
        this.newInstantVideoconference.instantVideoconferenceContact.error = true;
    }
    if(this.newInstantVideoconference.message === this.translate.instant('instant-videoconferences.message-meeting')){
        this.newInstantVideoconference.videoconference.description = "";
    }else{
        this.newInstantVideoconference.videoconference.description = this.newInstantVideoconference.message;
    }
    if(!errorFields){
        this.createNewVideoconference();
    }
  }

  getParticipants(){
      this.newInstantVideoconference.videoconference.destinations = {
          userIds: '',
          users: '',
          contactIds: '',
          contacts: '',
          productRoleIds: '',
          productRoles: ''
      }
      this.newInstantVideoconference.instantVideoconferenceContact.selectContacts.forEach((participant:any) => {
        if(participant.userId){
            if(this.newInstantVideoconference.videoconference.destinations.userIds === ''){
                this.newInstantVideoconference.videoconference.destinations.userIds = participant.userId.toString();
                this.newInstantVideoconference.videoconference.destinations.users = participant.name;
            }else{
                this.newInstantVideoconference.videoconference.destinations.userIds = this.newInstantVideoconference.videoconference.destinations.userIds.concat(';'+participant.userId);
                this.newInstantVideoconference.videoconference.destinations.users = this.newInstantVideoconference.videoconference.destinations.users.concat(';'+participant.name);
            }
        }else if(participant.contactId){
            if(this.newInstantVideoconference.videoconference.destinations.contactIds === ''){
                this.newInstantVideoconference.videoconference.destinations.contactIds = participant.contactId.toString();
                this.newInstantVideoconference.videoconference.destinations.contacts = participant.name;
            }else{
                this.newInstantVideoconference.videoconference.destinations.contactIds = this.newInstantVideoconference.videoconference.destinations.contactIds.concat(';'+participant.contactId);
                this.newInstantVideoconference.videoconference.destinations.contacts = this.newInstantVideoconference.videoconference.destinations.contacts.concat(';'+participant.name);
            }
        }else if(participant.productRoleId){
            if(this.newInstantVideoconference.videoconference.destinations.productRoleIds === ''){
                this.newInstantVideoconference.videoconference.destinations.productRoleIds = participant.productRoleId.toString();
                this.newInstantVideoconference.videoconference.destinations.productRoles = participant.name;
            }else{
                this.newInstantVideoconference.videoconference.destinations.productRoleIds = this.newInstantVideoconference.videoconference.destinations.productRoleIds.concat(';'+participant.contactId);
                this.newInstantVideoconference.videoconference.destinations.productRoles = this.newInstantVideoconference.videoconference.destinations.productRoles.concat(';'+participant.name);
            }
        }
      });
  }

  createNewVideoconference(){
      this.newInstantVideoconference.loadinCreation = true;
      let optionSelected = 0;
      if(this.newInstantVideoconference.optionSelect === 'password'){
          optionSelected = 1;
      }
      let newVideoconference = {
          userIds : this.newInstantVideoconference.videoconference.destinations.userIds,
          contactIds : this.newInstantVideoconference.videoconference.destinations.contactIds,
          productRoleIds : this.newInstantVideoconference.videoconference.destinations.productRoleIds,
          title: this.newInstantVideoconference.videoconference.title,
          location: this.newInstantVideoconference.videoconference.location,
          description: this.newInstantVideoconference.videoconference.description,
      }
      this.apiService.add('tasks/jitsimeet/new/'+ optionSelected, newVideoconference)
        .then((data:any)=>{
          this.newInstantVideoconference.stepState++;
          this.newInstantVideoconference.buttonNext = this.translate.instant('common.ok');
          this.newInstantVideoconference.videoconference.password = data.password;
          this.newInstantVideoconference.videoconference.url = data.url;
          this.newInstantVideoconference.videoconference.meetingDate = new Date();
          this.newInstantVideoconference.loadinCreation = false;
        }, (error:any)=>{
          this.modalService.adviceModal(this.translate.instant('instant-videoconferences.error-create-videoconference'), 'error:accept:warning')
          this.newInstantVideoconference.loadinCreation = false;
        })

  }

  handleAccept() {
    this.newInstantVideoconference.videoconference.participants = this.newInstantVideoconference.instantVideoconferenceContact.selectContacts;
    this.activeModal.close({
        result: 'ok',
        videoconference: this.newInstantVideoconference.videoconference,
        typeMeeting: this.newInstantVideoconference.optionSelect
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
