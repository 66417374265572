<div class="login-be-access">
  <h1 class="login-be-access__h1">Pruebas de login Bélgica</h1>
  <div id="pre">
    <h2 class="login-be-access__h2">Entorno de preproducción</h2>
    <p>
      <a href="/dashboard?access_token=761962b2cdd3d44e36a117f20e23e49ce1e180d0"
         target="_blank"
         class="login-be-access__link">
        usertest-centinela-no-access@larcier.com
      </a>
    </p>
    <p>
      <a href="/dashboard?access_token=334f7af44d21f1d6edf1063526a5d1cad8885fc6"
         target="_blank"
         class="login-be-access__link">
        usertest-centinela-normal-one@larcier.com
      </a>
    </p>
    <p>
      <a href="/dashboard?access_token=ba84d3b959a3e8718bd9265b4ff98ddf532d57ca"
         target="_blank"
         class="login-be-access__link">
        usertest-centinela-admin-plus@larcier.com
      </a>
    </p>
    <p style="margin-top:2em;">
      <label>Access Token:</label>&nbsp;<input type="text" [(ngModel)]="loginBE.accessToken" style="width:325px;margin-right:2em;"/>
      <a href="{{loginBE.redirect + loginBE.accessToken}}"
         target="_blank"
         class="login-be-access__link">
        Acceder
      </a>
    </p>
    <p style="margin-top:2em;">
      <a href="{{loginBE.loginUrl}}"
         target="_blank"
         class="login-be-access__link">
        Login normal
      </a>
    </p>
  </div>
</div>


