import { Component, OnChanges, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptListParentService } from '../utils/concept-list-parent.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'concept-list-global-report',
  templateUrl: './concept-list-global-report.component.html'
})
export class ConceptListGlobalReportComponent implements OnInit, OnChanges, OnDestroy {
  skin:string = this.globalCfg.skin;
  userPermissions: any = this.permissions.getPermissions();
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  folderId:any = this.route.snapshot.params['folderId'];
  conceptList:any = {
    textFilters: ['title'],
    titleWordFilter: '',
    order: true,
    openFocusTitle: false,
    openFocusAuthor: false,
    statePoll: false,
    showResultsReports: false,
    anySelect: false,
    dateFilter: 'creationDate',
    configuredTotal:{
      first: 1,
      last: 20
    },
    configPagination: {
      pageSize: 20,
      maxSize: 5
    },
    currentPage: 1,
    conceptList: [],
    countConceptSelect: [],
    conceptListFilter: [],
    creationDateFromDate: null,
    creationDateToDate: null,
    modificationDateFromDate: null,
    modificationDateToDate: null,
    selectedFilters: null,
    textSelectedFilters: null,
    authorFilter: { name: this.translate.instant('common.author') },
    nameVersionFilter: { name: this.translate.instant('concept-list.name-version') },
    extensionFilter: { name: this.translate.instant('common.format') },
    statusFilter: { name: this.translate.instant('common.state') },
    titleFilter: {
      name: this.translate.instant('common.title'),
      orderCol: true,
      filterOrder: 'title',
      width: '200px'
    },
    typeObjectId: 'conceptObjectId'
  };

  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private conceptListParent: ConceptListParentService,
    private permissions: UserPermisionsFactory
  ) {}

  @Input() conceptInfo:any;
  @Input() evaluation:any;
  @Input() toShow:any;
  @Input() modify:any;
  @Input() archivedAmount:any;

  @Output() evaluateConcept = new EventEmitter();
  @Output() launchAction = new EventEmitter();


  ngOnChanges(): void {
    this.conceptListParent.OnChanges(this);
  }

  ngOnInit(): void {
    this.conceptListParent.OnInit(this);
  }

  filterComponentList(){
    this.conceptListParent.FilterComponentList(this)
  }

  selectTypeOperation(item:any, type:any){
    this.conceptListParent.SelectTypeOperation(item.source, type, this)
  }

  selectMultipleConcept(type:any, evaluatedConcept:any){
    this.conceptListParent.SelectMultipleConcept(type, evaluatedConcept, this)
  }

  launchActionOption(option:any, item:any){
    this.conceptListParent.LaunchActionOption(option, item, this)
  }

  openInNewTab(item:any){
    this.conceptListParent.OpenInNewTab(item, this);
  }

  openAssociatedEvaluation(concept:any){
    this.conceptListParent.OpenAssociatedEvaluation(concept, this);
  }

  orderBySearch(e:any, type:any){
    this.conceptListParent.OrderBySearch(e, type, this);
  }

  orderDate(type:any){
    this.conceptListParent.OrderDate(type, this);
  }

  openFocus(type:any){
    this.conceptList['openFocus'+type] = !this.conceptList['openFocus'+type];
  }

  archiveConcept(concept:any, archived:any){
    this.conceptListParent.ArchiveConcept(concept, archived, this)
  }

  viewArchived(){
    this.launchAction.emit({toShow:'archived', type: 'showElements'});
  }

  changeToAnonymatum(item:any, type:any = ''){
    this.conceptListParent.ChangeToAnonymatum(item, type, this);
  }

  openChangeSignature(event:boolean, evaluateConcept:any){
    evaluateConcept.openOption = event;
    this.launchActionOption('signature-certification', evaluateConcept);
  }

  ngOnDestroy(){
    this.conceptListParent.ngOnDestroy();
  }
}
