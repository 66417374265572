<div class="common__field-type">
  <label class="common__input-block field-type-time__input-block-time"
      [ngClass]="{'common__input-no-editable': field.noEditable,
         'common__show-alert': field.error,
         'common__input-block--custom-field': isLanding}"
      [attr.disabled]="field.noEditable?true:null"
      (click)="showHour($event)"
      readonly>
      <div class="common__label-block common__label-block--one-icon">
          <span class="common__input-text"
              [ngClass]="{
                'common__input-text--required': field.error,
                'common__input-text--no-editable': field.noEditable
              }">{{fieldTypeTime.titleField}}</span>
          <span class="common__text-required" [ngShow]="field.required">*</span>
          <div class="common__info-icon-block" [ngHide]="field.noEditable || !field.description">
              <tooltip-info [description]="field.description"
                  [field]="field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block" [ngShow]="field.references?.length && !field.noEditable">
              <tooltip-law [references]="field.references"
                  [field]="field"></tooltip-law>
          </div>
          <div class="common__tooltip-law-block" [ngShow]="field.relatedInfo?.length && !field.poll && !field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
          <div class="common__info-icon-block common__info-icon-block--in-report"
            *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
            <tooltip-in-report [inReport]="fieldTypeTime.inReport"></tooltip-in-report>
          </div>
      </div>
      <i class="lf-icon-clock text-button common__input-icon common__input-icon-active"
          [ngClass]="{'field-type-time__alert-disabled': field.noEditable}"></i>
      <div class="field-type-time__block-time-whitouthover"
          [ngShow]="(fieldTypeTime.inputHour !== '00' || fieldTypeTime.inputMinute !== '00') && !fieldTypeTime.showBlockHour">
          <input [ngShow]="!field.multiline"
              type="text"
              [id]="'field-item-time-down-hour' + fieldTypeTime.id"
              class="common__input-content field-type-time__input-content-time-hour"
              [ngClass]="{'common__input-content-alert': field.error,
                 'common__input-content-no-editable': field.noEditable}"
              [(ngModel)]="fieldTypeTime.inputHour"
              (blur)="changeInField()"
              maxlength="2"
              autocomplete="off"
              validateNumber
              [noNegatives]="true"
              placeholder="00"
              [readonly]="field.noEditable">
          <span class="field-type-time__separated-time-whitouthover">:</span>
          <input class="common__input-content field-type-time__input-content-time-minute"
              [ngShow]="!field.multiline"
              type="text"
              [id]="'field-item-time-down-minute' + fieldTypeTime.id"
              [ngClass]="{'common__input-content-alert': field.error,
                 'common__input-content-no-editable': field.noEditable}"
              [(ngModel)]="fieldTypeTime.inputMinute"
              (blur)="changeInField()"
              maxlength="2"
              autocomplete="off"
              validateNumber
              [noNegatives]="true"
              placeholder="00"
              [readonly]="field.noEditable">
      </div>
      <div class="field-type-time__block-time-hover" [ngShow]="!field.noEditable && fieldTypeTime.showBlockHour"
        [id]="'field-item-time-minute' + fieldTypeTime.id">
          <div class="field-type-time__time-hover"
            [id]="'field-item-time-hour' + fieldTypeTime.id"
            ngMouseWheel
            (mouseWheelEvent)="wheelMouse($event, 'hour')">
              <i class="lf-icon-angle-up field-type-time__time-hover-position field-type-time__icon-arrow" (click)="upHour($event)"></i>
              <input class="field-type-time__input-content field-type-time__time-hover-position field-type-time__time-hover-position--text"
                  [ngShow]="!field.multiline"
                  type="text"
                  [id]="'field-item-time-down-hour-2' + fieldTypeTime.id"
                  [(ngModel)]="fieldTypeTime.inputHour"
                  (blur)="changeInField()"
                  maxlength="2"
                  autocomplete="off"
                  validateNumber
                  [noNegatives]="true"
                  value="{{fieldTypeTime.inputHour}}"
                  (click)="catchEvent($event)">
              <i class="lf-icon-angle-down field-type-time__time-hover-position field-type-time__icon-arrow" (click)="downHour($event)"></i>
          </div>
          <div class="field-type-time__time-hover">
              <span class="field-type-time__separated-time">:</span>
          </div>
          <div class="field-type-time__time-hover"
              [id]="'field-item-time-minute' + fieldTypeTime.id"
              ngMouseWheel
              (mouseWheelEvent)="wheelMouse($event, 'minute')">
              <i class="lf-icon-angle-up field-type-time__time-hover-position field-type-time__icon-arrow" (click)="upMinute($event)"></i>
              <input class="field-type-time__input-content field-type-time__time-hover-position field-type-time__time-hover-position--text"
                  [ngShow]="!field.multiline"
                  type="text"
                  [id]="'field-item-time-down-minute-2' + fieldTypeTime.id"
                  [(ngModel)]="fieldTypeTime.inputMinute"
                  (blur)="changeInField()"
                  maxlength="2"
                  autocomplete="off"
                  validateNumber
                  [noNegatives]="true"
                  (click)="catchEvent($event)">
              <i class="lf-icon-angle-down field-type-time__time-hover-position field-type-time__icon-arrow" (click)="downMinute($event)"></i>
          </div>
      </div>
      <i class="lf-icon-close common__delete-content-icon field-type-time__delete-content-icon"
          [ngShow]="fieldTypeTime.inputMinute > 0 || fieldTypeTime.inputHour > 0"
          (click)="resetInput()">
      </i>
      <div class="field-item__blocker-box" *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text" [afTranslate]="'field-items.blocker-field'"></p>
    </div>
  </label>
</div>
