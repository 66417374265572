import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'move-modal',
  templateUrl: './move-modal.component.html'
})
export class MoveModalComponent implements OnInit {
  moveModal:any = {
    selectFolder: null,
    selected: null,
    addedFolder: false,
    treeFolder: [],
    breadcrumbList: [],
    listDocumentChecked: [],
    headerTree: {
        documentationCheked: false,
        name: this.translate.instant('common.documentation')
    },
    nameFolder: this.translate.instant('expanded-menu.new-folder')
  }

  constructor(private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private context: ContextFactory,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal) { }

  @Input() itemSelected: any = null;
  @Input() listDocument: any = null;
  @Input() concept: any = null;

  ngOnInit(): void {
    let that: any = this.moveModal;
    that.itemSelected = this.itemSelected
    that.concept = this.concept;
    this.selectedFolder(that.concept.documentation.subFolders, 'init');
    if(!this.itemSelected.sharepointFolderId && !this.itemSelected.parentId){
        that.headerTree.disabled = true;
    }
  }

  selectedFolder(treeFolder:any, from:string =''){
    treeFolder.forEach((tree:any) => {
      tree.selected = false;
      if(from === 'init'){
          tree.disabledChildren = false;
          tree.show = false;
          tree.disabled = false;
      }
      if(tree.subFolders && tree.subFolders.length){
          this.selectedFolder(tree.subFolders);
      }
    });
    this.moveModal.treeFolder = this.moveModal.concept.documentation.subFolders;
    if(from === 'init'){
        this.openFolders(this.moveModal.treeFolder);
    }
  }

  closeFolders(treeFolder:any){
    treeFolder.forEach((tree:any) => {
      tree.selected = false;
      tree.disabledChildren = false;
      tree.show = false;
      tree.disabled = false;
      if(tree.subFolders && tree.subFolders.length){
          this.closeFolders(tree.subFolders);
      }
    })
  }

  openFolders(treeFolder:any){
      for(let i = 0; i < treeFolder.length; i++){
          let folder: any = treeFolder[i]
          if(folder.folderId === this.itemSelected.parentId){
              for(let k = 0; k < folder.subFolders.length; k++){
                  let subfolder: any = folder.subFolders[k]
                  if(subfolder.folderId === this.itemSelected.folderId){
                      this.moveModal.selected = folder;
                      subfolder.disabled = true;
                      subfolder.disabledChildren = true;
                      break;
                  }
              }
          }else if(!this.itemSelected.parentId && folder.folderId === this.itemSelected.folderId){
              folder.disabled = true;
              folder.disabledChildren = true;
          }else{
              if(this.itemSelected.documentObjectId){
                  for(let j = 0; j < folder.documents.length;j++){
                    let document: any = folder.documents[j]
                      if(document.documentObjectId === this.itemSelected.documentObjectId){
                        this.moveModal.selected = folder;
                          break;
                      }
                  }
              }
              if(this.moveModal.selected === null && folder.subFolders.length){
                  this.openFolders(folder.subFolders);
              }
          }

          if(this.moveModal.selected !== null){
              folder.show = true;
              if(this.moveModal.selected.folderId === folder.folderId){
                  folder.disabled = true;
                  folder.show = folder.disabledChildren;
              }
              break;
          }
      }
  }

        newFolder(){
          let that: any = this.moveModal
            this.loader.openLoader('create-folder')
            let parentId: any = null;
            if(!that.headerTree.documentationCheked){
                if(that.selectFolder){
                    parentId = that.selectFolder.folderId;
                }else if(that.selected){
                    parentId = that.selected.folderId;
                }
            }
            that.errorNameFolder = false;
            this.checkedName(that.treeFolder, parentId);
            if(!that.errorNameFolder){
                that.showNewFolder = !that.showNewFolder;
                this.apiService.add('sharepointfolders/'+that.concept.conceptObjectId, {name: that.nameFolder, parentId: parentId})
                    .then((data:any)=> {
                        data.createdBy = this.context.user.fullName;
                        data.created = data.creationDate;
                        data.folderId = data.sharepointFolderId;
                        data.subFolders = [];
                        data.documents = [];
                        data.extension = 'folder';
                        if(parentId){
                            this.addNewFolder(that.treeFolder, data, parentId);
                        }else{
                            data.selected = true;
                            data.level = 1;
                            that.treeFolder.push(data);
                            that.concept.documentation.subFolders = that.treeFolder;
                            this.loader.closeLoader(['create-folder']);
                        }
                        that.selectFolder = data;
                        that.addedFolder = true;
                        that.nameFolder = this.translate.instant('expanded-menu.new-folder');
                    }, (errorData:any)=>{
                        this.loader.closeError();
                        that.showBlockError = !that.showBlockError
                    })
            }else{
                this.loader.closeError();
            }

        }

        checkedName(treeFolder:any, parentId:any){
            for(let i = 0; i < treeFolder.length; i++){
                let folder: any = treeFolder[i]
                let parentIdFolder: any = folder.parentId
                if((!parentIdFolder || parentIdFolder === parentId) && folder.name === this.moveModal.nameFolder){
                    this.moveModal.errorNameFolder = true;
                    break ;
                }
                if(folder.subFolders.length && parentId){
                    this.checkedName(folder.subFolders, parentId);
                }
            }
        }

        addNewFolder(treeFolder:any, newFolder:any, parentId:any){
            for(let i = 0; i < treeFolder.length; i++){
                let folder: any = treeFolder[i]
                if(folder.folderId === parentId){
                    folder.selected = false;
                    folder.show = true;
                    newFolder.selected = true;
                    newFolder.level = this.moveModal.selectFolder? this.moveModal.selectFolder.level + 1 : this.moveModal.selected.level + 1;
                    treeFolder[i].subFolders.push(newFolder);
                    this.moveModal.concept.documentation.subFolders = this.moveModal.treeFolder;
                    this.loader.closeLoader(['create-folder']);
                    break;
                }
                if(folder.subFolders.length){
                    this.addNewFolder(folder.subFolders, newFolder, parentId);
                }
            }
        }

        documentationSelected(){
            if(!this.moveModal.headerTree.disabled){
                this.selectedFolder(this.moveModal.concept.documentation.subFolders, 'title');
                this.moveModal.selectFolder = null;
                this.moveModal.headerTree.documentationCheked = true;
            }
        }

        selectChildrenOn(e:any){
            this.selectChildren(e.children)
        }

        selectChildren(children:any){
            if(!children.disabled){
                this.moveModal.headerTree.documentationCheked = false;
                if(this.moveModal.selectFolder !== null && children.folderId === this.moveModal.selectFolder.folderId){
                    this.moveModal.selectFolder = null;
                }else{
                    if(this.moveModal.selectFolder !== null){
                        this.deselectedFolder(this.moveModal.treeFolder);
                        this.moveModal.selectFolder.selected = false;
                    }
                    this.moveModal.selectFolder = children;
                }
                children.selected = !children.selected;
            }

        }

        deselectedFolder(children:any){
            for(let i = 0; i < children.length; i++){
                let child: any = children[i]
                if(child){
                    child.selected = false;
                }
                if(child.subFolders.length){
                    this.deselectedFolder(child.subFolders);
                }
            }
        }

        moveItem(){
          let that: any = this.moveModal
            this.loader.openLoader('move-item')
            if(this.listDocument?.length > 1){
                that.errors = [];
                let quantity:any = 0;
                this.listDocument.forEach((documentSelected:any) => {
                  quantity++;
                  let list = that.selectFolder? that.selectFolder.documents : that.concept.documentation.documents;
                  for(let j:number = 0; j < list.length; j++){
                      let document:any = list[j];
                      if(document.name === documentSelected.name){
                          that.errors.push(documentSelected);
                          documentSelected.errorMove = true;
                          break;
                      }
                  }
                  if(!documentSelected.errorMove){
                      that.listDocumentChecked.push(documentSelected);
                  }
                  if(quantity === this.listDocument.length){
                      that.listDocumentChecked.length? this.callMoveItemChecked() : this.handleAccept('error-all-name');
                  }
                });

            }else{
                if(this.checkName()){
                    this.callMoveItem(that.itemSelected);
                }else{
                    if(that.itemSelected.documentObjectId){
                        this.handleAccept('error-name');
                    }else if(that.itemSelected.folderId){
                        this.handleAccept('error-folder');
                    }
                }
            }
        }

        callMoveItemChecked(){
            this.moveModal.quantityCall = 0;
            this.moveModal.listDocumentChecked.forEach((documentToMove:any) => {
              this.callMoveItem(documentToMove);
            });
        }

        checkName(){
            if(this.itemSelected.documentObjectId){
                let list:any = this.moveModal.selectFolder? this.moveModal.selectFolder.documents : this.moveModal.concept.documentation.documents;
                return !Boolean(list.filter((item:any)=>{return item.name === this.itemSelected.name}).length);
            }else if(this.itemSelected.folderId){
                let list:any = this.moveModal.selectFolder? this.moveModal.selectFolder.subFolders : this.moveModal.concept.documentation.subFolders;
                return !Boolean(list.filter((item:any)=>{return item.name === this.itemSelected.name}).length);
            }
            return false
        }

        callMoveItem(documentSelected:any){
            let sharepointfolderid:any = this.moveModal.selectFolder? this.moveModal.selectFolder.folderId : 0;
            let url: string = documentSelected.folderId? 'sharepointfolders/move/'+documentSelected.folderId+'/'+sharepointfolderid : 'documentobjects/movedoctosubfolder/'+documentSelected.documentObjectId+'/'+sharepointfolderid;

            this.apiService.patch(url)
                .then((data:any)=>{
                    this.moveModal.breadcrumbList = [];
                    this.moveModal.quantityCall++;
                    if (documentSelected.documentObjectId) {
                        documentSelected.editPath = data.editPath;
                        this.rewriteDocumentTree(documentSelected);
                    }else if(documentSelected.folderId){
                        this.rewriteFolderTree();
                    }
                }, (errorData:any)=>{
                    this.loader.closeError();
                    this.errorModal();
                })
        }

        errorModal(){
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning').subscribe((result:any)=>{
              this.handleDismiss()
            })
        }

        rewriteDocumentTree(documentSelected:any){
            if(documentSelected.sharepointFolderId === null || documentSelected.sharepointFolderId === undefined){
                this.moveModal.concept.documentation.documents = this.commonUtilsService.deleteElement(documentSelected, this.moveModal.concept.documentation.documents, 'documentObjectId');
                this.addDocumentToFolder(this.moveModal.concept.documentation, this.moveModal.selectFolder, documentSelected);
            }else{
                this.deleteItem(this.moveModal.concept.documentation, documentSelected);
            }
        }

        rewriteFolderTree(){
            if(this.itemSelected.parentId === null || this.itemSelected.parentId === undefined){
                this.moveModal.concept.documentation.subFolders = this.commonUtilsService.deleteElement(this.itemSelected, this.moveModal.concept.documentation.subFolders, 'folderId');
                this.addDocumentToFolder(this.moveModal.concept.documentation, this.moveModal.selectFolder, this.itemSelected);
            }else{
                this.deleteItem(this.moveModal.concept.documentation, this.itemSelected);
            }
        }

        deleteItem(documentation:any, document:any){
            for(let i: number = 0; i < documentation.subFolders.length; i++){
              let subFolders: any = documentation.subFolders[i]
              if(document.sharepointFolderId && document.sharepointFolderId === subFolders.folderId){
                subFolders.documents = this.commonUtilsService.deleteElement(document, subFolders.documents, 'documentObjectId');
                this.addDocumentToFolder(this.moveModal.concept.documentation, this.moveModal.selectFolder, document);
                break;
              }else if(document.parentId && document.parentId === subFolders.folderId){
                subFolders.subFolders = this.commonUtilsService.deleteElement(document, subFolders.subFolders, 'folderId');
                this.addDocumentToFolder(this.moveModal.concept.documentation, this.moveModal.selectFolder, this.itemSelected);
                break;
              }
              if(subFolders.subFolders && subFolders.subFolders.length){
                this.deleteItem(subFolders, document);
              }
            }

        }

        configureBreadcrumb(folders:any, folderId:any){
            for(let i:number = 0; i < folders.length; i++){
                let subFolders:any = folders[i];
                if(subFolders.folderId === folderId){
                    if(folderId !== this.moveModal.selectFolder.folderId){
                        this.moveModal.breadcrumbList.unshift(subFolders);
                    }
                    if(subFolders.parentId){
                        this.configureBreadcrumb(this.moveModal.concept.documentation.subFolders, subFolders.parentId);
                    }
                    break;
                }
                if(subFolders.subFolders.length){
                    this.configureBreadcrumb(subFolders.subFolders, folderId);
                }
            }

        }

        addDocumentToFolder(documentation:any, folderSelected:any, document:any){
            if(!folderSelected){
                document.parentId = null;
                document.folderId? this.moveModal.concept.documentation.subFolders.push(document) : this.moveModal.concept.documentation.documents.push(document);
                if(!this.moveModal.listDocumentChecked.length || this.moveModal.listDocumentChecked.length === this.moveModal.quantityCall){
                    let message:string = this.moveModal.errors && this.moveModal.errors.length?'ok-with-errors' : 'ok';
                    this.moveModal.breadcrumbList = [];
                    this.moveModal.breadcrumbList.unshift({
                      name: this.translate.instant('common.documentation'),
                      subFolders: [...this.moveModal.concept.documentation.documents, ...this.moveModal.concept.documentation.subFolders]
                    });
                    this.handleAccept(message);
                }
            }else{
                document.parentId = folderSelected.folderId;
                for(let i:number = 0; i < documentation.subFolders.length; i++){
                    let subFolders:any = documentation.subFolders[i];
                    if(folderSelected && subFolders.folderId === folderSelected.folderId){
                        if(document.folderId){
                            subFolders.subFolders.push(document);
                        }else if(document.documentObjectId){
                            document.sharepointFolderId = subFolders.folderId;
                            subFolders.documents.push(document);
                        }
                        if(!this.moveModal.listDocumentChecked.length || this.moveModal.listDocumentChecked.length === this.moveModal.quantityCall){
                            let message:string = this.moveModal.errors && this.moveModal.errors.length?'ok-with-errors' : 'ok';
                            if(this.moveModal.selectFolder){
                                this.configureBreadcrumb(this.moveModal.concept.documentation.subFolders, this.moveModal.selectFolder.folderId);
                            }
                            this.moveModal.breadcrumbList.unshift({
                              name: this.translate.instant('common.documentation'),
                              subFolders: [...this.moveModal.concept.documentation.documents, ...this.moveModal.concept.documentation.subFolders]
                            });
                            this.handleAccept(message);
                            break;
                        }
                    }else if((!folderSelected && !subFolders.parentId && document.sharepointFolderId === subFolders.folderId)){
                        if(document.folderId){
                            documentation.subFolders.push(document);
                        }else if(document.documentObjectId){
                            document.sharepointFolderId = subFolders.parentId;
                            documentation.documents.push(document);
                        }

                        if(!this.moveModal.listDocumentChecked.length || this.moveModal.listDocumentChecked.length === this.moveModal.quantityCall){
                          let message:string = this.moveModal.errors && this.moveModal.errors.length?'ok-with-errors' : 'ok';
                          this.moveModal.breadcrumbList.unshift({
                            name: this.translate.instant('common.documentation'),
                            subFolders: [...this.moveModal.concept.documentation.documents, ...this.moveModal.concept.documentation.subFolders]
                          });
                          this.handleAccept(message);
                          break;
                        }
                    }
                    if(subFolders.subFolders && subFolders.subFolders.length){
                        this.addDocumentToFolder(subFolders, folderSelected, document);
                    }
                }
            }
        }

        handleDismiss() {
          this.closeFolders(this.moveModal.concept.documentation.subFolders);
          this.activeModal.close({
            reason:'cancel',
            addedFolder: this.moveModal.addedFolder
          });
        };

        handleAccept(resultMove:any) {
          this.activeModal.close({
            result: resultMove,
            folderSelected: this.moveModal.selectFolder,
            breadcrumbList: this.moveModal.breadcrumbList,
            documentErrors: this.moveModal.errors
          });
        };

    }
