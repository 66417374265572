import html2canvas from 'html2canvas';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ReportTableParentService } from '../utils/report-table-parent.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { MatTooltip } from '@angular/material/tooltip';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';


@Component({
  selector: 'report-table-dynamic',
  templateUrl: './report-table-dynamic.component.html'
})
export class ReportTableDynamicComponent implements OnInit, OnChanges, OnDestroy {

  userPermisions: any = this.permissions.getPermissions();
  skin: string = this.globalCfg.skin;
  archived: boolean = this.route.snapshot.data['archived'] === 'archived'
  evaluationId: any = this.route.snapshot.params['evaluationId'];
  url = this.skin === 'icam-red' ? 'icam/' : ''
  configureInit: boolean = false;
  hasPermision: boolean = this.userPermisions !== 'none';
  subscribers: any = {};
  reportTable: any = this.reportTableParent.configureReportTable(this);
  constructor(private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private reportTableParent: ReportTableParentService,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory) { }

  @Input() report: any = null;
  @Input() configurateReport: any = {};

  @Output() updateListEmit = new EventEmitter();
  @Output() exportReportEmit = new EventEmitter();
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnChanges(): void {
    this.reportTableParent.onChanges(this);
  }

  ngOnInit(): void {
    this.reportTable.showResidualOption = this.report.reportId === 180
    this.subscribers.openRiskBroadcast = this.broadcastService.subscribe('openRisk', (data: any) => {
      this.reportTableParent.openConcept(data.risk, this)
    });
  }

  refreshTable() {
    if (!this.archived) {
      this.updateListEmit.emit();
      this.reportTable.optionText = this.translate.instant('report-table.view-graph');
      this.reportTable.optionSelect = 'list';
    }
  }

  relatedConcept(row: any, type: any) {
    this.reportTableParent.relatedConcept(row, type, this)
  }

  changeView() {
    this.reportTableParent.changeView(this)
  }

  filterByDate(e: any, column: any) {
    this.reportTableParent.filterByDate(e.dateFilter, e.reset, column, this)
  }

  selectTypeOperation(e: any, column: any) {
    this.reportTableParent.selectTypeOperation(e.source, column, this)
  }

  filterReports(filterList: any = null) {
    this.reportTableParent.filterReports(filterList, this)
  }

  // addNewFilter(column:any){
  //   this.reportTableParent.addNewFilter(column, this)
  // }

  deleteFilter(filter: any) {
    this.reportTableParent.deleteFilter(filter, this)
  }

  clearAllFilters(event: any) {
    event.preventDefault();
    this.reportTableParent.clearAllFilters(this)
  }

  orderTable(column: any) {
    this.reportTableParent.orderTable(column, this)
  }

  initHeightColumn(object: any) {
    return this.reportTableParent.initHeightColumn(object, null);
  }

  moveScroll(scrollX: any) {
    this.reportTableParent.moveScroll(scrollX, this)
  }

  exportReport(onlyRename: Boolean = false) {
    this.exportReportEmit.emit({ onlyRename: onlyRename, list: this.reportTable.reportsListFilter });
  }

  orderBySearch(e: any, column: any) {
    this.reportTable[column.id + 'ElementValue'] = e.newValue;
    this.reportTableParent.filterReports(null, this);
  }

  changeCalendar(e: any, column: any) {
    column[column.id + 'DateFilter'] = Boolean(e.dates.type === 'apply')
    this.reportTableParent.changeCalendar(e.dates, column.id, this)
    this.closeDropdown(column.id)
  }

  closeDropdown(type: any) {
    let dropdown: any = this.dropdowns.find((x: any) => {
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  saveAsPng(tooltip: MatTooltip) {
    let documentObject: any = document;
    let that = this;
    that.reportTable.disabledSaveAsPng = true;
    tooltip.hide();
    setTimeout(() => {
      html2canvas(documentObject.querySelector(".report-graphs"))
      .then(function (canvas) {
        canvas.toBlob((blob: any) => {
          let data = [new ClipboardItem({ [blob.type]: blob })];
          navigator.clipboard.write(data)
            .then(() => {}, (err) => console.log(err));
        });
      }).then(() => {
        that.reportTable.disabledSaveAsPng = false;
        tooltip.message = this.translate.instant('results-report.image-captured');
        tooltip.show();
        setTimeout(() => {
          tooltip.message = this.translate.instant('results-report.capture-image');
          tooltip.hide();
        }, 2000);
      });
    }, 100);
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
