import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'general-report',
  templateUrl: './general-report.component.html'
})
export class GeneralReportComponent implements OnInit {
  reportObjectId:any = '';
  generalReport: any ={
    reportObjects: this.route.snapshot.data['reportObjects'],
    filters: [],
    reportDocuments: [],
    tab: 'detail',
    monthNameFilter: {
      name: this.translate.instant('general-report.month'),
      orderBy: 'index'
    }
  }
  constructor(private route: ActivatedRoute,
    private translate:AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private modalService:ModalServiceService,
    private filterColumnService: FilterColumnService,
    private documentActions: DocumentActionsService) { }

  ngOnInit(): void {
    this.reportObjectId = this.generalReport.reportObjects[0].reportObjectId
    this.getRows();
    this.getReportDocumentation();
  }

  getReportDocumentation() {
      this.apiService.get('reportdocuments', this.reportObjectId)
        .then((data:any)=>{
          this.generalReport.reportDocuments = data;
        }, (errorData:any)=>{})
  }

  getRows() {
      this.loader.openLoader('open-list-reports');
      this.apiService.get('icam/reports', this.reportObjectId)
        .then((data:any)=> {
          this.commonUtilsService.setMomentLanguage();
          this.generalReport.months = moment.months();
          this.generalReport.years = {};
          this.generalReport.currentYear = 0;
          data.forEach((row:any) => {
            let year = row.year;
            if (!this.generalReport.years[year]) {
                this.generalReport.years[year] = [];
                if (year > this.generalReport.currentYear) {
                    this.generalReport.currentYear = year;
                }
            }
            row.monthName = this.generalReport.months[row.month - 1];
            this.generalReport.years[year].push(row);
          });

          this.generalReport.filteredRows = this.generalReport.years[this.generalReport.currentYear];
          this.extractFilterType({ canFilter: true, fieldTypeId: 'list', id: 'monthName' });
          this.loader.closeLoader(['open-list-reports']);
        }, (errorData:any)=>{
          this.loader.closeError()
        });
  }

  generateReportExcel(document:any) {
      this.loader.openLoader('export-report');
      let newDocument = {
          name: document.name
      }
      this.apiService.add('reportobjects/excel/' + this.reportObjectId, newDocument)
        .then((data:any)=>{
          this.modalService.adviceModal(this.translate.instant('general-report.export-report-ok') + '"' + document.name + '". ' + this.translate.instant('general-report.info-export-documentation'), 'info:accept:check-round');
          if (document.download && document.openFile) {
              this.documentActions.doAction('download', data);
              setTimeout(()=>{
                this.documentActions.doAction('open', data);
              }, 500)
          } else {
              if (document.download) {
                  this.documentActions.doAction('download', data);
              }
              if (document.openFile) {
                  this.documentActions.doAction('open', data);
              }
          }
          this.generalReport.reportDocuments.push(data);
          this.loader.closeLoader(['export-report']);
      }, (errorData:any)=>{
        this.loader.closeError();
      })
  }

  deleteDocumentReport(document:any) {
    this.loader.openLoader('delete-report-document');
    this.apiService.delete('reportdocuments', document.reportDocumentId)
      .then(()=>{
        this.generalReport.reportDocuments = this.commonUtilsService.deleteElement(document, this.generalReport.reportDocuments, 'reportDocumentId');
        this.modalService.adviceModal(this.translate.instant('general-report.delete-document').replace('{document}', document.name), 'info:accept:check-round');
        this.loader.closeLoader(['delete-report-document']);
      }, ()=>{
        this.loader.closeError();
      });
  }

  shareDocument(document:any, share:any) {
    this.loader.openLoader('share-document');
    this.apiService.add('reportdocuments/share/' + document.reportDocumentId, share)
    .then(()=>{
      this.modalService.adviceModal(this.translate.instant('common.the-document') + " '" + document.name + "' " + this.translate.instant('documentation-list.shared-document'), 'info:accept:check-round');
      this.loader.closeLoader(['share-document']);
    }, ()=>{
      this.loader.closeError();
    });
  }

  extractFilterType(column:any) {
    switch (column.fieldTypeId) {
      case 'list':
        this.generalReport.filters.push(column.id);
        this.generalReport[column.id + 'List'] = this.filterColumnService.extractList(column.id, this.generalReport.filteredRows);
        this.generalReport[column.id + 'ListFilter'] = [];
        break;
    }
  }

  selectTypeOperation(e:any, column:any) {
      let item: any = e.source;
      this.generalReport[column.id + 'ListFilter'] = this.filterColumnService.configureFilterList(this.generalReport[column.id + 'ListFilter'], item);
      item.selected = !item.selected;
      if (this.generalReport[column.id + 'Filter']) {
          this.generalReport[column.id + 'Filter'].hasFilter = this.generalReport[column.id + 'ListFilter'].length;
      }
      this.filterReports();
  }

  selectYear(year:any) {
      if (year != this.generalReport.currentYear) {
          this.generalReport.filteredRows = this.generalReport.years[this.generalReport.currentYear = year];
          this.extractFilterType({ canFilter: true, fieldTypeId: 'list', id: 'monthName' });
      }
  }

  filterReports() {
      let filterList = this.generalReport.years[this.generalReport.currentYear];
      this.generalReport.filters.forEach((filter:any) => {
          let filteredList = this.generalReport[filter + 'ListFilter'];
          filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      })
      this.generalReport.filteredRows = filterList;
  }

  changeView() {
      this.commonUtilsService.goToStateUrl();
  }

  exportReport(reportId:any) {
      this.modalService.reportExport(reportId).subscribe((result:any)=>{
        if(result.result==='ok'){
          if (!this.beforeCreated(result.document)) {
            this.generateReportExcel(result.document);
          } else {
            this.modalService.adviceModal(this.translate.instant('report-info.name-exist') + '' + this.translate.instant('report-info.in-the-report') + this.generalReport.report.title + '', 'error:accept:warning')
          }
        }
      })
  }

  beforeCreated(document:any) {
    let index:any = this.generalReport.reportDocuments.findIndex((reportObject:any)=>{return reportObject.name === document.name + '.xlsx'})
    return index !== -1;
  }

  launchAction(action:any) {
      switch (action.type) {
          case 'view':
              this.documentActions.doAction('open', action.document);
              break;
          case 'download':
              this.documentActions.doAction('download', action.document);
              break;
          case 'share':
              let configuration:any = {
                  type: action.type,
                  addContacts: false,
                  document: action.document
              }
              this.modalService.documentShare(configuration).subscribe((result:any)=> {
                if(result.result ==='ok'){
                  this.shareDocument(action.document, result.share);
                }
              })
              break;
          case 'delete':
              this.modalService.adviceModal(this.translate.instant('general-report.delete-document-question') + action.document.name + '?', 'info:question:question')
                .subscribe((result:any) => {
                  if(result.result ==='ok'){
                    this.deleteDocumentReport(action.document);
                  }
                });
              break;
      }
  }
}
