<div class="new-snippet-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-model common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="configuration.type === 'new'?'snippets-reusable.new-snippet':'snippets-reusable.edit-snippet'">_Nuevo fragmento</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="new-snippet-modal__block-body"
    [ngClass]="{'new-snippet-modal__block-body--new-fragment': configuration.type === 'new-fragment'}">
      <div class="new-snippet-modal__snippet-form">
          <field-type-text-simple class="new-snippet-modal__short-id"
              [field]="newSnippetModal.shortIdField"
              [preSelectItem]="newSnippetModal.snippet.shortId"
              (itemSelected)="itemSelected($event.newValue, 'shortId')">
          </field-type-text-simple>
          <field-type-text-simple [field]="newSnippetModal.titleField"
              [preSelectItem]="newSnippetModal.snippet.title"
              (itemSelected)="itemSelected($event.newValue, 'title')">
          </field-type-text-simple>
          <field-type-rich-text-editable *ngIf="configuration.type != 'new-fragment'"
            [field]="newSnippetModal.textField"
            (itemSelected)="itemSelected('text', $event.newValue)">
          </field-type-rich-text-editable>
          <!-- <advice-message ng-show="newSnippetModal.hasError"
              icon="warning"
              type="error"
              type-class="no-margin"
              text="newSnippetModal.warningText"
              close-advice="newSnippetModal.closeAdvice('showInfoMessage')">
          </advice-message> -->
        </div>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'">_Cancelar</button>
          <button type="submit"
              class="common__button common__button--active common__button--separating-margin"
              (click)="saveSnippet()"
              [afTranslate]="'common.save'">_Guardar</button>
      </div>
  </div>

</div>

