import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oneMore'
})
export class OneMorePipe implements PipeTransform {

  transform(item: string): any {
    if(item && item.indexOf(',')){
      let items = item.split(',');
      let text = items[0] ;
      if(items.length){
        text += ' (+'+ (items.length-1)+')';
      }
      return text;
    }
  }

}
