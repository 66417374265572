import { Component, Input } from '@angular/core';

@Component({
  selector: 'tree-selected-multiple',
  templateUrl: './tree-selected-multiple.component.html'
})
export class TreeSelectedMultipleComponent{
  @Input() level: number = 1;
  @Input() list: any = [];
  nextLevel: number = this.level + 1;
}
