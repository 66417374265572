<div class="menu-header-comments">
    <div class="menu-header-user__header-nav">
        <i class="menu-header-comments__close-icon lf-icon-close"
             (click)="closeDropdown()"></i>
        <div class="menu-header-comments__title-header menu-header-comments__title-header--notification">
            <span [afTranslate]="'common.alerts'"></span>
        </div>
    </div>
    <div>
        <div class="menu-header-comments__menu-header">
          <span [afTranslate]="'menu-header.consult-last-notices'">Estas son las notificaciones recibidas en los últimos 7 días</span>
        </div>
        <div class="menu-header-comments__block-body-comments">
            <div class="menu-header-comments__block-line">
                <div class="menu-header-comments__block-list"
                    [ngShow]="notification.showNotification"
                    *ngFor="let notification of menuHeaderNotifications.notifications; let first = first; let last = last;">
                    <div class="lf-icon-step-final menu-header-comments__circle-icon-comment"
                        [ngClass]="{'menu-header-comments__circle-icon-comment--first':first}">
                    </div>
                    <div class="menu-header-comments__group-text">
                        <p class="menu-header-comments__text-name">{{notification.client}}</p>
                        <p class="menu-header-comments__text-type">{{notification.evaluation}}</p>
                        <div class="menu-header-comments__breadcrumb-block">
                            <p class="menu-header-comments__text-breadcrumb">{{notification.breadcrumb}}</p>
                        </div>
                        <i [class]="notification.icon+ 'menu-header-comments__icon-type-notification'"></i>
                        <p class="menu-header-comments__text-date">{{notification.date | relativeTime}}</p>
                    </div>
                    <div class="menu-header-comments__group-list">
                        <i class="lf-icon-map-marker menu-header-comments__icon-notifications"
                            matTooltip="{{'expanded-menu.browse-to-the-origin' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="goToNotification(notification)"></i>
                        <i class="lf-icon-check-round menu-header-comments__icon-notifications"
                            matTooltip="{{'menu-header.mark-read' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="deleteNotification(notification, $event)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


