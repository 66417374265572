<ul class="concept-steps__steps-block wizard-steps__top"
    [ngHide]="steps.length === 1">
    <li class="concept-steps__step-item"
        [ngStyle]="{'width': last? '3.5%':(100 - 3.5) / (steps.length - 1) +'%'}"
        *ngFor="let step of steps | onlyToShow:'wizardSteps'; let last = last"
        [ngClass]="{'concept-steps__step-item--last': last}">
        <div class="concept-steps__step-icon-block concept-steps__step-icon-block--disabled"
            [ngClass]="{'concept-steps__step-icon-block--active': step.originalStep === state}"
            matTooltip="{{step.name}}"
            matTooltipPosition="below"
            matTooltipClass="below">
            <p class="concept-steps__number">{{step.index}}</p>
        </div>
        <hr class="concept-steps__line-step"
            [ngHide]="last">
            <div class="wizard-steps__wizard-text wizard-steps__wizard-text--active"
                *ngIf="step.originalStep === state">
            <p class="wizard-steps__col-text">
                {{step.name}}
            </p>
        </div>
    </li>
</ul>
