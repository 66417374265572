<div class="input-date">
  <input type="text"
        [id]="inputDate.inputId+'-day'"
         class="input-date__date input-date__date--day"
         [ngClass]="{'input-date__date--error': inputDate.error,
          'input-date__date--month-sequential': inputDate.sequentialView,
          'input-date__date--day-one-digit': inputDate.dayOneDigit,
          'input-date__date--day-two-digits': inputDate.dayTwoDigits}"
         [(ngModel)]="inputDate.day"
         validateNumber
         [noDot]="inputDate.dotSeparation"
         maxlength="2"
         [value]="inputDate.day"
         (keyup)="validateDate('day', $event)"
         (click)="select('day')"
         [disabled]="disabled">
  <span class="input-date__date" [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error}">/</span>
  <input type="text"
         [id]="inputDate.inputId+'-month'"
         class="input-date__date input-date__date--month"
         [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error,
          'input-date__date--month-sequential': inputDate.sequentialView,
          'input-date__date--month-one-digit': inputDate.monthOneDigit,
          'input-date__date--month-two-digits': inputDate.monthTwoDigits}"
         [(ngModel)]="inputDate.month"
         maxlength="2"
         validateNumber
         [noDot]="inputDate.dotSeparation"
         [value]="inputDate.month"
         (keyup)="validateDate('month', $event)"
         (click)="select('month')"
         [disabled]="disabled">
  <span class="input-date__date" [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error}">/</span>
  <input type="text"
         [id]="inputDate.inputId+'-year'"
         class="input-date__date input-date__date--year"
         [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error,
            'input-date__date--year-sequential': inputDate.sequentialView}"
         [(ngModel)]="inputDate.year"
         maxlength="4"
         validateNumber
         [value]="inputDate.year"
         [noDot]="inputDate.dotSeparation"
         (keyup)="validateDate('year', $event)"
         (click)="select('year')"
         [disabled]="disabled">
  <div class="input-date__time-block" [ngClass]="{'input-date__time-block--sequential': inputDate.sequentialView}" [ngShow]="showTime">
    <input type="text"
           [id]="inputDate.inputId+'-hours'"
           class="input-date__date input-date__date--hours"
           [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error}"
           [(ngModel)]="inputDate.hours"
           maxlength="2"
           validateNumber
           [noDot]="inputDate.dotSeparation"
           [value]="inputDate.hours"
           (keyup)="validateDate('hours', $event)"
           (click)="select('hours')"
           [disabled]="disabled">
    <span class="input-date__date input-date__date--separator" [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error}">:</span>
    <input type="text"
           [id]="inputDate.inputId+'-minutes'"
           class="input-date__date input-date__date--minutes"
           [ngClass]="{'input-date__date--error input-date__date--error-color': inputDate.error}"
           [(ngModel)]="inputDate.minutes"
           maxlength="2"
           validateNumber
           [value]="inputDate.minutes"
           [noDot]="inputDate.dotSeparation"
           (keyup)="validateDate('minutes', $event)"
           (click)="select('minutes')"
           [disabled]="disabled">
  </div>
  <i class="input-date__erase-button-icon lf-icon-close"
    *ngIf="inputDate.eraseButtonShow && active"
    (click)="eraseDate()"></i>
</div>
