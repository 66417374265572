<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-conversation attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'comments.comments'"></p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            [ngClass]="boxComments.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"
            (click)="maximizeScreen()"
            matTooltip="{{'common.deploy' | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
        <div class="common__bubble-count-custom attachments-template__bubble-count-custom"
            [ngShow]="boxComments.commentList.length">{{boxComments.commentList.length}}</div>
        <div class="pull-righ">
            <i class="lf-icon-add-round box-comments__add-icon box-comments__add-icon--custom "
                [ngShow]="(boxComments.hasPermissions && (boxComments.taskId || boxComments.conceptObjectId)) && !boxComments.taskStateNoEditable"
                (click)="newComment()"
                matTooltip="{{'comments.new-comment' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
        </div>
    </div>
    <div class="attachments-template__custom-no-documents"
        [ngShow]="!boxComments.commentList.length && !boxComments.showCommentBox">
        <p [ngShow]="!boxComments.commentList.length && !boxComments.showCommentBox"
            [afTranslate]="'comments.no-comments'">No hay comentarios</p>
    </div>

    <div class="box-comments__custom-block-edit-comment"
        [ngClass]="{'box-comments__custom-block-edit-comment--open': boxComments.fullScreen}"
        *ngIf="boxComments.showCommentBox && (boxComments.hasPermissions && (boxComments.taskId || boxComments.conceptObjectId)) && !boxComments.taskStateNoEditable">
        <p class="box-comments__add-comment-title box-comments__add-comment-title--custom"
            [afTranslate]="'comments.new-comment'">Nuevo comentario</p>
        <textarea [id]="'edit-comment-new-comment'"
            class="box-comments__custom-add-comment-text"
            [(ngModel)]="boxComments.commentText">
        </textarea>
        <button class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last float-end"
                [ngStyle]="{'background-color': custom?.color, 'color': custom?.textColor}"
                (click)="createNewComment(boxComments.commentText)"
                [afTranslate]="'common.add'">
        </button>
        <button class="common__button-landing-custom common__button-landing-custom--cancel float-end"
                [ngStyle]="{'border-color': custom?.color, 'color': custom?.color}"
                (click)="closeComment()"
                [afTranslate]="'common.cancel'">
        </button>
    </div>

    <ul class="box-comments__comment-list box-comments__comment-list--custom"
        [ngShow]="boxComments.showCommentList && boxComments.commentListFilter.length"
        [ngClass]="{'box-comments__comment-list--full-add-comment': boxComments.fullScreen && boxComments.showCommentBox,
            'box-comments__comment-list--full-no-comment': boxComments.fullScreen && !boxComments.showCommentBox }">
        <li *ngFor="let comments of boxComments.commentListFilter | afOrderBy:'creationDate':true | afLimitTo:boxComments.fullScreen?boxComments.commentListFilter.length:1"
            class="box-comments__comment-item">
            <div class="box-comments__custom-block-edit-comment box-comments__custom-block-edit-comment--edit"
                [ngShow]="comments.edit">
                <textarea [id]="'edit-comment-'+comments.commentId"
                    class="box-comments__custom-add-comment-text"
                    [(ngModel)]="comments.commentTextarea">
                </textarea>
                <button class="common__button-landing-custom common__button-landing-custom--send float-end"
                        [ngStyle]="{'background-color': custom?.color, 'color': custom?.textColor}"
                        (click)="changeComment(comments)"
                        [afTranslate]="'common.save'">
                </button>
                <button class="common__button-landing-custom common__button-landing-custom--cancel float-end"
                        [ngStyle]="{'border-color': custom?.color, 'color': custom?.color}"
                        (click)="cancelComment(comments)"
                        [afTranslate]="'common.cancel'">
                </button>

            </div>
            <div class="box-comments__list-item box-comments__list-item--custom"
                [ngStyle]="{'border-left-color': custom?.color}"
                [ngShow]="!(comments.edit || boxComments.edit)">
                <div class="box-comments__custom-header-block">
                    <div class="box-comments__custom-author-data">
                        <p class="box-comments__custom-author">{{comments.author}}</p>
                        <p class="box-comments__custom-date">{{comments.creationDate | convertDate: 'without-time' }}
                            <span class="box-comments__custom-date box-comments__custom-date--hour">{{comments.creationDate | convertDate: 'only-time' }}</span>
                        </p>
                    </div>
                    <div class="box-comments__custom-author-menu"
                        [ngShow]="comments.showOptions">
                        <i class="lf-icon-trash box-comments__custom-trash-comment"
                            matTooltip="{{'common.remove' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="deleteComment(comments)">
                        </i>
                        <i class="lf-icon-edit box-comments__custom-edit-comment"
                            matTooltip="{{'common.edit' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="editComment(comments)">
                        </i>
                    </div>
                </div>
                <p class="box-comments__custom-comment-text"
                    [ngClass]="{'box-comments__custom-comment-text--only-last': !boxComments.fullScreen}"
                    [innerHTML]="comments.commentText">
                </p>
                <i class="lf-icon-step-final box-comments__comment-bubble box-comments__comment-bubble--custom"
                    [ngStyle]="{'color': custom?.color}"></i>
            </div>
        </li>
    </ul>
</div>
