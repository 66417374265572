import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'custom-report-field-list',
  templateUrl: './custom-report-field-list.component.html'
})
export class CustomReportFieldListComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private filterColumnService:FilterColumnService,
    private commonUtilsService:CommonUtilsService,
    private modalService:ModalServiceService,
    private loader:LoaderFactory,
    private apiService:ApiServiceService
  ) { }

  @Input() reportFieldList:any;

  @Output() changeView = new EventEmitter();
  @Output() viewPredefinedReportField = new EventEmitter();
  @Output() editCustomReportField = new EventEmitter();

  skin:string =  this.globalCfg.skin;
  customReportFieldList:any = {
    reportFieldNameWordFilter: '',
    reportWordFilter: '',
    productNameOrder: 'reportFieldName',
    orderOperationProductName: false,
    filters: ['isCustom'],
    currentPage: 1,
    configPagination: {
      pageSize: 10
    },

    newCustomReportFieldView: { code: 'newCustomReportField' },
    isCustomFilter: {
        name: this.translate.instant('custom-report-field-list.header-table.source'),
        id: this.commonUtilsService.createId()
    }
  };

  ngOnInit() {
    this.customReportFieldList.filterReportFieldList = this.reportFieldList;
    this.getFilters();
  }

  getFilters() {
    let that = this.customReportFieldList;
    for (let filter of that.filters) {
        that[filter + 'List'] = this.filterColumnService.extractList(filter, this.reportFieldList)
        that[filter + 'ListFilter'] = [];
        if (filter === 'isCustom') {
            for (let j = 0; j < that.isCustomList.length; j++) {
                if (that.isCustomList[j].name === 0) {
                    that.isCustomList[j].nameTranslate = this.translate.instant('custom-report-field-list.predefined-report-fields');
                } else if (that.isCustomList[j].name === 1) {
                    that.isCustomList[j].nameTranslate = this.translate.instant('custom-report-field-list.custom-report-fields');
                }
            }
        }
    }
  }

  selectTypeOperation(event:any, type:string) {
    let item = event.source;
    let that = this.customReportFieldList;
    let listFilter = that[type + 'ListFilter'];
    that[type + 'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if (that[type + 'Filter']) {
        that[type + 'Filter'].hasFilter = that[type + 'ListFilter'].length;
    }
    this.filter();
  }

  filter(filterType?:string) {
    let that = this.customReportFieldList;
    let filterList = this.reportFieldList;
    if (that.reportFieldNameWordFilter || filterType === 'reportFieldName') {
        filterList = this.filterColumnService.filterByWord(that.reportFieldNameWordFilter, filterList, 'reportFieldName');
    }
    if (that.reportWordFilter || filterType === 'reportsText') {
        filterList = this.filterColumnService.filterByWord(that.reportWordFilter, filterList, 'reportsText');
    }
    for (let filter of that.filters) {
        let filteredList : any = that[filter + 'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    }
    that.filterReportFieldList = filterList;
  }

  orderProductName(type:string) {
    let that = this.customReportFieldList;
    if (that.productNameOrder === type) {
        that.orderOperationProductName = !that.orderOperationProductName ;
    } else {
        that.productNameOrder = type;
        that.orderOperationProductName  = false;
    }
  }

  resetFilter(e:any, filterType:string) {
    e.stopPropagation();
    e.preventDefault();
    let that = this.customReportFieldList;
    if (filterType === 'reportFieldName') {
        that.reportFieldNameWordFilter = '';
    } else if (filterType === 'reportsText') {
        that.reportWordFilter = '';
    }
    this.filter(filterType);
  }

  goToDelete(e:any, customReportField:any) {
    let text : string = this.translate.instant('custom-report-field-list.question-delete-custom-report-field');
    this.modalService.adviceModal(text, 'error:question:question')
      .subscribe((result:any) => {
          if(result.result !== 'cancel'){
            this.deleteCustomReportField(e, customReportField);
          }
      });
  }

  deleteCustomReportField(e:any, customReportField:any) {
    this.loader.openLoader('custom-report-field-list-delete');
    this.apiService.delete('customreportfields', customReportField.reportFieldId)
    .then(() => {
            this.reportFieldList = this.commonUtilsService.deleteElement(customReportField, this.customReportFieldList.reportFieldList, 'reportFieldId');
            this.resetFilter(e, 'reportFieldName');
            this.resetFilter(e, 'reportsText')
            this.loader.closeLoader('custom-report-field-list-delete');
            this.modalService.adviceModal(this.translate.instant('custom-report-field-list.custom-report-field-deleted'), 'info:accept:check-round');
        }, () => {
          this.loader.closeError()
          this.modalService.adviceModal(this.translate.instant('custom-report-field-list.deleted-error'), 'error:accept:warning');
        }
    );
  }
}
