// import * as Tone from 'tone'
import { FieldTypeRichParentService } from './../utils/field-type-rich-parent.service';
import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'field-type-rich-text-editable',
  templateUrl: './field-type-rich-text-editable.component.html'
})
export class FieldTypeRichTextEditableComponent implements OnChanges, OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  isLanding: boolean = this.customLanding.isLanding();
  id: any = this.commonUtilsService.createId();
  productTypeId: any = '';
  interval: any;
  initInterval: number = 0;
  percentTime:number = 0;
  startAudio: boolean = false;
  subscribers: any = {}
  fieldTypeRichText: any = {
    titleField: '',
    descriptionPadding: '',
    noEditable: false,
    hasVoiceRecord: false,
    voiceRecord:{},
    acceptAceptation: false,
    tinyMceOptions: {
      noEditable: false,
      typeView: 'rich-text',
    }
  }
  constructor(private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory,
    private fieldTypeRichParent: FieldTypeRichParentService,
    private broadcastService: BroadcastService,
    private globalCfg: GlobalCfgFactory) {

  }

  @Input() field : any = {};
  @Input() concept : any = {};
  @Output() changeInField = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();
  @Output() saveVoiceRecord = new EventEmitter();
  @Output() launchKeyPressAction = new EventEmitter();
  

  ngOnInit(): void {
    this.subscribers.saveConceptToEvaluationBroadcast = this.broadcastService.subscribe('closePlay', (data:any)=>{
      if(this.startAudio){
        this.stopInterval()
      }
    });
  }

  ngOnChanges(): void {
    this.fieldTypeRichParent.configureChanges(this);
    if (this.field && this.field.fieldTypeId === 'rich' && this.field.isEditable) {
        this.fieldTypeRichText.tinyMceOptions.id = this.field.richId? this.field.richId: this.commonUtilsService.createId();
        this.fieldTypeRichText.tinyMceOptions.noEditable = this.field.noEditable;
        if(this.field.height){
          this.fieldTypeRichText.tinyMceOptions.height = this.field.height;
        }
        this.commonUtilsService.configureCustomButtonsTiny(this.field, this.fieldTypeRichText.tinyMceOptions);
        let evaluatedValue = this.field.evaluatedValue;
        if (evaluatedValue) {
            if (evaluatedValue.nvarcharValue != null && $.trim(evaluatedValue.nvarcharValue) != '') {
                this.field.description = evaluatedValue.nvarcharValue;
            }
        }
        if(this.field.evaluatedValue?.documents?.length){
          this.fieldTypeRichText.voiceRecord.name = this.field.evaluatedValue.documents[0].name
          this.fieldTypeRichText.acceptAceptation = true;
          this.fieldTypeRichText.hasVoiceRecord = true;
          this.fieldTypeRichText.loadFromBack = true;

        }
    }
    if(this.concept){
      this.fieldTypeRichText.lockedSavedConcept = Boolean((this.field.lockedEdit || this.concept.lockedEdit) && (this.concept?.saved))
    }
  }

  getDuration(src:any) {
    return new Promise(function(resolve) {
        var audio = new Audio();
        $(audio).on("loadedmetadata", function(){
            resolve(audio.duration);
        });
        audio.src = src;
    });
  }

  modifyField(e: any) {
      this.field.description = e.model;
      if(e.model===''){
        this.resetInput()
      }
      this.changeInField.emit({newValue: e.model});
  }

  shareField(field:any){
    this.modalService.shareInstance(this.concept, false, 'knowledge').subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loader.openLoader('field-item-shared-field')
        this.apiService.add('fields/share/'+field.fieldId+'/evaluation/'+field.evaluationId, result.shared)
            .then((data:any)=>{
              this.loader.closeLoader(['field-item-shared-field']);
              this.modalService.adviceModal(this.translate.instant('field-items.shared-field'), 'info:accept:check-round');
            }, (errorData:any)=>{
                this.loader.closeError();
                this.modalService.adviceModal(this.translate.instant('field-items.shared-error'), 'error:accept:warning');
            })
      }
    })
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeRichParent.openModalRelatedInfo(e, fieldRelatedInfo, this)
  }

  openTextareaModal(){
    this.modalService.expandTextarea(this.field.description, this.field).subscribe((result:any)=>{
      if(result.result ==='ok'){
        this.field.description = result.inputText;
        if(this.field.description && this.field.error){
            this.field.error = false;
        }
        this.changeInField.emit({});
      }
    })
  }

  resetInput(){
    if(this.startAudio){
      this.stopInterval()
    }
    this.field.description = '';
    this.fieldTypeRichText.acceptAceptation = false;
    this.fieldTypeRichText.hasVoiceRecord = false
    this.fieldTypeRichText.voiceRecord = {};
    this.saveVoiceRecord.emit({
      acceptAceptation: this.fieldTypeRichText.acceptAceptation,
      voiceRecordFile: null,
      name: ''
    })
  }

  analyzeWithSibila(){
    this.fieldTypeRichParent.analyzeWithSibila(this);
  }
  openRecordVoice(){
    if(!this.field.noEditable){
      this.fieldTypeRichParent.openRecordVoice(this);
    }

  }

  playRecordVoice(){
    this.startAudio = !this.startAudio;
    if(!this.fieldTypeRichText.audioLoaded){
      this.getAudio()
    }else{
      if(this.startAudio){
        this.initInterval = 0;
        this.initStartAudio()
      }else{
        this.stopInterval();
      }
    }

  }

  getAudio(){
    let url:string = 'file='+ this.field.evaluatedValue.documents[0].editPath;
    this.apiService.getBlob('utils/file/download?' + url)
      .then((data:any) => {
        this.fieldTypeRichText.voiceRecord.recordingFile = data.blob;
        this.fieldTypeRichText.voiceRecord.player = new Audio();
        $(this.fieldTypeRichText.voiceRecord.player).on("loadedmetadata", ()=>{
          this.fieldTypeRichText.voiceRecord.secondsRecord = this.fieldTypeRichText.voiceRecord.player.duration.toFixed(2);
          this.fieldTypeRichText.audioLoaded = true;
          this.fieldTypeRichText.hasVoiceRecord = true;
          this.fieldTypeRichText.loadFromBack = true;
          this.initInterval = 0;
          this.initStartAudio();
        });
        this.fieldTypeRichText.voiceRecord.player.src = URL.createObjectURL(data.blob);
      }, (error:any) => {})
  }


  initStartAudio(){
    this.fieldTypeRichText.voiceRecord.player.play();
    this.interval = setInterval(() => {
      this.percentTime = this.initInterval * 100 / this.fieldTypeRichText.voiceRecord.secondsRecord;
      if(this.percentTime >= 100){
        this.stopInterval()
      }else{
        this.initInterval++
      }

    }, 1000);
    this.startAudio = true;
  }

  stopInterval(){
    this.startAudio = false;
    this.initInterval = 0;
    this.fieldTypeRichText.voiceRecord.player.pause();
    this.fieldTypeRichText.voiceRecord.currentTime = 0;
    clearInterval(this.interval)
  }

  audioDownLoad(){
    const url = URL.createObjectURL(this.fieldTypeRichText.voiceRecord.recordingFile);
	  const anchor = document.createElement("a");
	  anchor.download = this.fieldTypeRichText.voiceRecord.name;
	  anchor.href = url;
	  anchor.click();
  }

  acceptAceptationTerms(){
    if(!this.field.noEditable){
      this.fieldTypeRichText.acceptAceptation = !this.fieldTypeRichText.acceptAceptation;
      if(this.fieldTypeRichText.acceptAceptation){
        this.field.errorInAcceptAceptation = false;
      }
      this.saveVoiceRecord.emit({
        acceptAceptation: this.fieldTypeRichText.acceptAceptation,
        voiceRecordFile: this.fieldTypeRichText.voiceRecord.file,
        name: this.fieldTypeRichText.voiceRecord.name
      })
    }
  }
  keyPressAction(){
    this.launchKeyPressAction.emit()
  }
  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
