import { Injectable } from '@angular/core';
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class StateManagementFactory {
  state:any = {
    url: ''
  }

  setStateUrl(url: any){
    this.state.url = this.state.url ===''? url : `${this.state.url}|${url}` 
  }

  getLastState(){
    let urlArray:any = this.state.url.indexOf('|') !== -1? this.state.url.split('|') : [this.state.url];
    return urlArray[urlArray.length - 1];
  }
  removeLastStateUrl(){
    let url: any = ''
    if(this.state.url.indexOf('|') !== -1){
      url = this.state.url.substring(0, this.state.url.lastIndexOf('|'))
    }
    this.state.url = url;
  }
}
