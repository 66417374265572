import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'add-assignment-modal',
  templateUrl: './add-assignment-modal.component.html'
})
export class AddAssignmentModalComponent{
  skin:string = this.globalCfg.skin;
  addAssignmentModal:any = {
    stepShow: 0,
    showInfoMessage: true,
    documentChecked: 0,
    searchText: '',
    copySearchText: '',
    fieldNameValue: '',
    conceptNameValue: '',
    fieldTypeIdValue: '',
    dateOrder: 'fieldName',
    orderOperationDates: true,
    searchingEvaluations: true,
    noElements: false,
    loaderImg: this.commonUtilsService.configureLoaderImg(),
    infoMessage: this.translate.instant('add-assignment.info-message'),
    infoErrorMessage: this.translate.instant('add-assignment.info-message-error'),
    menuIcons: {
        icons: []
    },
    configPagination: {
      pageSize: 10
    },
    fieldTypeIdFilter: {
        name: this.translate.instant('common.type'),
        id: this.commonUtilsService.createId()
    },
    breadcrumbList: [],
    fieldsAdded: [],
    filterProductTreeList: [],
    filterFieldList: [],
    filters: ['fieldName', 'conceptName', 'fieldTypeId']
  };

  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private apiService:ApiServiceService,
    private context:ContextFactory,
    private commonUtilsService:CommonUtilsService,
    private translate:AfTranslateFactory,
    private filterColumnService:FilterColumnService
  ) { }

  @Input() newCustomReportField:any;

  goToSearch(){
    let that = this.addAssignmentModal;
    if(that.searchText !== '' && that.searchText !== that.copySearchText){
      this.search()
    }else{
      that.searchText = '';
    }
  }

  goToPressEnter(event:any){
    if(event.which === 13 && this.addAssignmentModal.searchText !== ''){
        this.search();
    }
  }

  search(){
    let that = this.addAssignmentModal;
    that.errorFields = false;
    that.showInfoMessage = true;
    that.searchingFields = true;
    that.stepShow = 1;
    that.copySearchText = that.searchText;
    that.orderOperationDates = false;
    that.fieldNameValue = '';
    that.conceptNameValue = '';
    that.openFocusName = false;
    that.openFocusLocalization = false;
    this.apiService.get('customreportfields/conceptfields?searchText=' + that.searchText + '&productId=' + this.newCustomReportField.productId)
    .then((data:any) => {
        if(this.newCustomReportField && this.newCustomReportField.selectedReportFields && this.newCustomReportField.selectedReportFields){
          this.checkPrevSelection(data);
        }
        that.documentList = data;
        that.currentPage = 1;
        that.filterFieldList = that.documentList;
        that.searchingFields = false;
      }, (error:any) => {
        that.searchingFields = false;
      }
    );
  }

  checkPrevSelection(list:any){
    for(let selectedField of this.newCustomReportField.selectedReportFields){
        for(let field of list){
            if(selectedField.fieldId === field.fieldId){
                field.previousSelected = true;
                break;
            }
        }
    }
  }

  selectProduct(){
    let that = this.addAssignmentModal;
    that.stepShow = 2;
    that.fieldNameValue = '';
    that.openFocusFieldName = false;
    that.searchingTreeEvaluation = true;
    that.nameProduct = this.newCustomReportField.productName.toUpperCase();
    this.apiService.get('tree/backgeneric?productId=' + this.newCustomReportField.productId)
    .then((data:any) => {
        if(data.length){
          this.getProductMenuIcons(this.newCustomReportField.productId, data);
        }
        that.productTreeList = data;
        that.filterProductTreeList = data;
        that.totalItemsPagination = that.filterProductTreeList.length;
        that.searchingTreeEvaluation = false;
      }, (error:any) => {
        that.searchingTreeEvaluation = false;
      }
    );
  }

  getProductMenuIcons(productId:any, list:any){
    let that = this.addAssignmentModal;
    this.apiService.getMenuIcons()
    .then((data:any) => {
      let selectItem: any = data.filter((item:any)=>{ return item.productId === productId})[0]
      that.menuIcons = selectItem;
      that.product = selectItem.productId;
      this.configureIcons(list);
      }, (error:any) => {}
    );
  }

  configureIcons(list:any){
    list.forEach((children:any) => {
      children.icon = this.getIcon(children);
    });
  }

  getIcon(children:any){
    let that = this.addAssignmentModal;
    that.searchingTreeEvaluation = false;
    let selectIcon: any = that.menuIcons.icons.filter((icon:any)=>{
      return icon['iD_Version0' + this.context.environment] === children['iD_Version0']
    })
    if(selectIcon.length){
      return selectIcon[0].icon;
    }else if(children.isFront){
        return "lf-icon-folder-user";
    } else{
        return  "lf-icon-folder";
    }
  }

  selectAllDocumentCheck(){
    let that = this.addAssignmentModal;
    that.selectAllDocument = !that.selectAllDocument;
    that.filterFieldList.forEach((document:any) => {
      if(document.editable && !document.previousSelected && !document.folderId && document.selectedField !== that.selectAllDocument){
        document.selectedField = that.selectAllDocument;
        if(document.selectedField){
            that.documentChecked++;
        }else{
            if(that.documentChecked > 0){
                that.documentChecked--;
            }
        }
      }
    });
  }

  orderDate(type:any){
    let that = this.addAssignmentModal;
    if(that.dateOrder === type){
        that.orderOperationDates = !that.orderOperationDates;
    }else{
        that.dateOrder = type;
        that.orderOperationDates = true;
    }
  }

  filterField(type:any){
    let that = this.addAssignmentModal;
    let filterList = [];
    let list = that.documentList;
    if(that.stepShow === 3){
        list = that.fieldsConceptList;
    }
    filterList = this.filterColumnService.filterByWord(that[type +'Value'],  list, type);
    for(let filter of that.filters){
        let filteredList = that[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    }
    let propertyType: any = that.stepShow === 3? 'filterConceptList' : 'filterFieldList';
    that[propertyType] = filterList;

  }

  selectMultipleField(field:any){
    let that = this.addAssignmentModal;
    if(field.editable && !field.previousSelected){
        field.selectedField = !field.selectedField;
        that.selectedFromOption = false;
        if(field.selectedField){
            that.documentChecked++;
        }else{
            that.selectAllDocument = false;
            that.documentChecked--;
        }
    }
  }

  selectFolderFromBreadcrumb(folder:any, type:any){
    let that = this.addAssignmentModal;
    that.stepShow = 2;
    if(type === 'menu'){
        if(that.breadcrumbList.length){
            that.breadcrumbList = [];
            that.filterProductTreeList = that.productTreeList;
        }
    }else{
        let breadcrumbList = cloneDeep(that.breadcrumbList);
        that.breadcrumbList = [];
        for(let breadcrumbItem of breadcrumbList){
            folder.nameBreadcrumb = breadcrumbItem.name;
            that.breadcrumbList.push(breadcrumbItem);
            if(breadcrumbItem.id === folder.id){
                break;
            }
        }
        this.configureTreeFolder(folder);
    }
    this.animationBreadcrumbs();
  }

  configureTreeFolder(folder:any){
    let that = this.addAssignmentModal;
    that.currentPage = 1;
    that.noElements = false;
    if(folder.children.length || folder.concepts.length){
        that.filterProductTreeList = folder.children;
        if(folder.concepts){
            this.checkConceptsPrevSelection(folder.concepts);
            that.filterProductTreeList = that.filterProductTreeList.concat(folder.concepts);
        }

    }else{
        that.filterProductTreeList = [];
        that.noElements = true;
    }
    that.totalItemsPagination = that.filterProductTreeList.length;
  }

  animationBreadcrumbs(){
    setTimeout(() => {
        let height = $('.duplicate-documentation__block-breadcrumbs-text').height();
        $('.duplicate-documentation__block-breadcrumbs').animate({ scrollTop: height }, 'slow');
    })
  }

  checkConceptsPrevSelection(conceptsList:any){

    for(let selectedField of this.newCustomReportField.selectedReportFields){
        for(let concept of conceptsList){
            if(selectedField.conceptId === concept.conceptId){
                concept.previousSelected = true;
                break;
            }
        }
    }
  }

  selectFolder(folder:any){
    if(!folder.previousSelected){
      if(folder.conceptId){
          this.selectConcept(folder);
      }else if(folder.folderId){
          folder.nameBreadcrumb = folder.name;
          folder.id = folder.folderId;
          this.addAssignmentModal.breadcrumbList.push(folder);
          this.configureTreeFolder(folder);
      }
      this.animationBreadcrumbs();
    }

  }

  selectConcept(concept:any){
    let that = this.addAssignmentModal;
    if(!concept.previousSelected){
        that.currentPage = 1;
        that.noElements = false;
        concept.nameBreadcrumb = concept.title;
        that.stepShow = 3;
        that.searchingFields = true;
        this.getConceptFields(concept);
    }
  }

  getConceptFields(concept:any){
    let that = this.addAssignmentModal;
    that.noElements = false;
    that.showInfoMessage = true;
    that.apiService.get('customreportfields/conceptfields', concept.conceptId)
    .then((data:any) => {
        if(that.newCustomReportField && that.newCustomReportField.selectedReportFields && that.newCustomReportField.selectedReportFields){
            this.checkPrevSelection(data);
        }
        if(data.length === 0){
            that.noElements = true;
        }
        that.fieldsConceptList = data;
        that.filterConceptList = data;
        that.searchingFields = false;
        that.totalItemsPagination = that.filterConceptList.length;
        that.breadcrumbList.push(concept);
        this.getFilters();
        this.animationBreadcrumbs();
      }, (error:any) => {
        that.searchingFields = false;
      }
    );
  }

  getFilters(){
    let that = this.addAssignmentModal;
    for(let filter of that.filters){
        that[filter+'List'] = this.filterColumnService.extractList(filter,  that.filterConceptList);
        that[filter+'ListFilter'] = [];
        if(filter === 'fieldTypeId'){
            for(let fieldType of that.fieldTypeIdList){
                switch(fieldType.name){
                    case 'text':
                    case 'rich':
                    case 'list':
                    case 'listp':
                    case 'l-co':
                    case 'l-fo':
                    case 'num':
                    case 'date':
                    case 'bool':
                    case 'boolp':
                    case 'formu':
                    case 'formp':
                    case 'file':
                    case 'money':
                    case 'user':
                    case 'txchk':
                    case 'agreg':
                    case 'templ':
                    case 'task':
                    case 'alert':
                        fieldType.nameTranslate = this.translate.instant('field-list.'+fieldType.name);
                        break;
                    default:
                        fieldType.nameTranslate = fieldType.name;
                        break;
                }
            }
        }
    }
  }

  selectTypeOperation(e:any, type:string){
    let item: any = e.source;
    let that = this.addAssignmentModal;
    let listFilter = that[type+'ListFilter'];
    that[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    that[type+'Filter'].hasFilter = that[type+'ListFilter'].length;
    this.filterField(type);
  }

  selectSimpleField(field:any){
    if(field.editable){
        this.extractSimpleValue(field);
    }
  }

  extractSimpleValue(fieldSelected:any){
    let that = this.addAssignmentModal;
    for(let filterConceptItem of that.filterConceptList){
        if(filterConceptItem.fieldId === fieldSelected.fieldId){
            that.documentChecked = 1;
            that.simpleFieldSelected = filterConceptItem;
            filterConceptItem.selectedField = true;
        }else{
            filterConceptItem.selectedField = false;
        }
    }
  }

  backInit(){
    let that = this.addAssignmentModal;
    that.searchText = '';
    that.copySearchText = '';
    that.stepShow = 0;
    that.filterEvaluationList = that.evaluationList;
    that.selectedProduct = false;
    that.breadcrumbList = [];
    that.selectAllDocument = true;
    this.selectAllDocumentCheck();
  }

  assignFields(){
    let that = this.addAssignmentModal;
    that.fieldsAdded = [];
    if(that.stepShow === 1){
        let count = 0;
        for(let field of that.filterFieldList){
            field.invalidateSelection = false;
            if(field.selectedField){
                count++;
                this.checkedConceptId(that.filterFieldList, field);
                this.checkedPreviousSelectionList(field);
                if(!that.errorFields){
                    that.fieldsAdded.push(field);
                    if(count === that.documentChecked){
                        this.handleAccept('ok');
                        break;
                    }
                }
            }
        }
    }else{
        that.fieldsAdded.push(that.simpleFieldSelected);
        this.handleAccept('ok');
    }
  }

  checkedConceptId(list:any, fieldSelected:any){
    let that = this.addAssignmentModal;
    let errorCount = 0;
    list.forEach((field:any) => {
      if(field.selectedField && field.conceptId === fieldSelected.conceptId){
        errorCount++;
        fieldSelected.invalidateSelection = true;
      }
    });
    that.errorFields = Boolean(errorCount);
  }

  checkedPreviousSelectionList(fieldSelected:any){
    let that = this.addAssignmentModal;
    let errorCount = 0;
    this.newCustomReportField.selectedReportFields.forEach((selectedField:any) => {
      if(selectedField.conceptId === fieldSelected.conceptId){
        errorCount++;
        fieldSelected.invalidateSelection = true;
      }
    });
    that.errorFields = Boolean(errorCount);
  }

  resetFilter(e:any, filterType:string) {
    e.stopPropagation();
    e.preventDefault();
    this.addAssignmentModal[filterType+'Value'] = '';
    this.filterField(filterType);
  }

  closeAdvice(type:any){
    this.addAssignmentModal[type] = false;
  }

  handleAccept(message:string) {
    this.activeModal.close({
      result: message,
      fieldsAdded: this.addAssignmentModal.fieldsAdded
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
