import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';

@Component({
  selector: 'users-admin-roles-list',
  templateUrl: './users-admin-roles-list.component.html'
})
export class UsersAdminRolesListComponent implements OnInit {
  @Input() model: any = {};
  userId: any = this.route.snapshot.params['id'];
  filters: any = ['name', 'productName'];
  usersAdminRolesList: any = {
    filterOrder: 'name',
    order: false,
    filterRolesList: [],
      nameFilter: {
          name: this.translate.instant('roles-list.assigned-role'),
          orderCol: true,
          filterOrder: 'name'
      },
      productFilter: {
          name: this.translate.instant('common.product')
      },
      organizationsFilter: {
          name: this.translate.instant('common.organizations'),
          width: '200px'
      }
  }
  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private filterColumnService: FilterColumnService) { }

  ngOnInit(): void {
    this.configureRoleList();
  }

  configureRoleList(){
      this.apiService.get('productroles/user', this.userId + '/assignments')
        .then((data:any)=>{
            data.forEach((role:any) => {
              role.organizations = [];
              if(role.assignedToProduct){
                  let allClients = {
                      clientId: 'all',
                      name: this.translate.instant('roles-list.all-organizations')
                  }
                  role.organizations.push(allClients);
              }else if(role.assignedClients && role.assignedClients.length){
                  role.organizations = role.assignedClients;
              }
            })
            this.usersAdminRolesList.roleList = data;
            this.model.rolesCount = data.length;
            this.usersAdminRolesList.filterRolesList = this.usersAdminRolesList.roleList;
            this.getFilters();
        }, (error:any)=>{})
  }

  getFilters(){
      this.filters.forEach((filter:any) => {
        this.usersAdminRolesList[filter+'List'] = this.filterColumnService.extractList(filter, this.usersAdminRolesList.roleList);
        this.usersAdminRolesList[filter+'ListFilter'] = [];
      })
  }

  searchBy(type: any){
      if(this.usersAdminRolesList.filterOrder === type){
          this.usersAdminRolesList.order = !this.usersAdminRolesList.order;
      }else{
          this.usersAdminRolesList.filterOrder = type;
          this.usersAdminRolesList.order = false;
      }
  }

  orderBySearch(newValue: any, type: any){
      this.usersAdminRolesList[type+'WordFilter'] = newValue;
      this.filterRoles(type);
  }


  selectTypeOperation(item: any, type: any){
      let listFilter = this.usersAdminRolesList[type+'ListFilter'];
      this.usersAdminRolesList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      if(this.usersAdminRolesList[type+'Filter']){
          this.usersAdminRolesList[type+'Filter'].hasFilter = this.usersAdminRolesList[type+'ListFilter'].length;
      }
      this.filterRoles(type);
  }

  filterRoles(type: any){
      let searchFilter = '';
      let searchWord = 'name';
      let filterList: any = [];
      if(type === 'organizations'){
          searchFilter = this.usersAdminRolesList[type + 'WordFilter'];
          searchWord = type;
          filterList = this.filterColumnService.filterByWordChildren(searchFilter, this.usersAdminRolesList.roleList, 'fullName', type);
      }else{
          filterList = this.filterColumnService.filterByWord(searchFilter, this.usersAdminRolesList.roleList, searchWord);
          if(this.usersAdminRolesList.organizationsWordFilter){
              filterList = this.filterColumnService.filterByWordChildren(this.usersAdminRolesList.organizationsWordFilter, filterList, 'fullName', 'organizations');
          }
      }
      this.filters.forEach((filter:any) => {
        let filteredList = this.usersAdminRolesList[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      })
      this.usersAdminRolesList.filterRolesList  = filterList;
  }
}
