import moment from 'moment';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'picker-visibility',
  templateUrl: './picker-visibility.component.html'
})
export class PickerVisibilityComponent implements OnChanges, OnInit {
  skin: string = this.globalCfg.skin;
  pickerVisibility: any = {
    configPagination:{
      pageSize: 6,
    },
    currentPage: 1,
    findDropdown: '',
    filteredItems: null,
    tableItems: null,
    view: null,
    editingItem: null,
    conditionTitle: null,
    filters: ['fieldLabel'],
    fieldLabelListFilter: [],
    searchText: '',
    operatorsAll: [
        {
            listValueId:'=',
            text:'='
        }, {
            listValueId:'<',
            text:'<'
        }, {
            listValueId:'>',
            text:'>'
        }, {
            listValueId:'<=',
            text:'<='
        }, {
            listValueId:'>=',
            text:'>='
        }, {
            listValueId:'<>',
            text:'<>'
        }
    ],
    operatorsOnly: [
        {
            listValueId:'=',
            text:'='
        },{
            listValueId:'<>',
            text:'<>'
        }
    ],
    datePickerOptions: {
        showWeeks: false,
        startingDay: 1,
        showButtonBar: false,
        skin: this.skin
    },
    fieldFilter: {
      name: this.translate.instant('common.field'),
      id: this.commonUtilsService.createId()
    },
    operatorOne: {
      label: this.translate.instant('customize-field-formula.encoding-operator'),
      xs: true
    },
    operatorTwo: {
      label: this.translate.instant('customize-field-formula.encoding-operator'),
      xs: true
    },
    valueOne: {
      label: this.translate.instant('customize-field-formula.encoding-value'),
      fieldTypeId: 'num'
    },
    valueTwo: {
      label: this.translate.instant('customize-field-formula.encoding-value'),
      fieldTypeId: 'num'
    }

  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private filterColumnService: FilterColumnService) { }

  @Input() view: any = null;
  @Input() items: any = null;
  @Input() orCondition: boolean = false;
  @Input() selectedItems: any = null;
  @Input() selectedCount: any = null;
  @Input() columns: any = null;
  @Input() readonly: any = null;
  @Input() type: any = null;

  @Output() onClose = new EventEmitter;

  ngOnInit(){
    if(!this.view){
      this.view = this.readonly ? 'selected' : 'full';
    }
    if(this.view !== 'full'){
      this.changeView(this.view);
    }
    this.getFilters(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.view !== 'selected' && (changes['items'] && changes['items'].currentValue)) {
          this.pickerVisibility.itemsList = this.items;
          this.pickerVisibility.filteredItems = this.items;
    } else if ((changes['selectedItems'] || changes['items']) && changes['selectedItems'].currentValue && changes['items'].currentValue) {
          this.changeView(this.view);
    }

    if (changes['selectedItems'] && changes['selectedItems'].currentValue) {
      this.pickerVisibility.selectedItems = changes['selectedItems'].currentValue;
    }
    this.pickerVisibility.previousSelectedItems = cloneDeep(this.pickerVisibility.selectedItems);
    this.pickerVisibility.previousOrCondition = this.orCondition?true:false;
    if (changes['columns'] && changes['columns'].currentValue) {
      this.pickerVisibility.columns = changes['columns'].currentValue;
    }
  }

  getFilters(items:any){
    this.pickerVisibility.filters.forEach((filter:any) => {
      this.pickerVisibility[filter+'List'] = this.filterColumnService.extractList(filter, items)
    });
  }

  changeView(newView:any) {
    let that: any = this.pickerVisibility;
    that.view = newView;
    that.filteredItems = that.view.view === 'selected'? this.items.filter((item:any)=>{ return that.selectedItems[item.key] !== undefined}) : this.items;
    that.itemsList = that.filteredItems;
  }

  toggleColumnFilter(column:any, value:any) {
      if (this.pickerVisibility.columns.filterCount === undefined) {
        this.pickerVisibility.columns.filterCount = 0;
      }
      if (column.filters === undefined) {
          column.filters = {};
          column.filterCount = 0;
      }
      if (column.filters[value] === undefined) {
          column.filters[value] = null;
          this.pickerVisibility.columns.filterCount++;
          column.filterCount++;
      } else {
          delete column.filters[value];
          this.pickerVisibility.columns.filterCount--;
          column.filterCount--;
      }
  }

  toggleItem(item:any) {
    let that: any = this.pickerVisibility;
    let selected: boolean = !!that.selectedItems[item.key];
    let keyTokens:string = item.key.split('-');
    if (keyTokens[0] === 'f' || keyTokens[0] === 'w' || keyTokens[0] === 'le') {
        that.conditionTitle = keyTokens[0] === 'f'? this.translate.instant('visibility.field-visibility-condition').replace(/\{field\}/, item.fieldLabel) : this.translate.instant('visibility.weighing-visibility-condition');
        if (selected) {
            delete that.selectedItems[item.key];
            this.selectedCount--;
            item.valueText = this.translate.instant('visibility.field-item-advise');
        } else {
            if (!item.condition) {
                item.condition = {};
            }
            that.valueOne.noEditable = Boolean(!this.pickerVisibility.editingItem?.condition?.operator1 || item.dataType === 'text' || keyTokens[0]=== 'le');
            that.valueTwo.noEditable = Boolean(!this.pickerVisibility.editingItem?.condition?.operator2 || item.dataType === 'text' || keyTokens[0]=== 'le');
            that.operators = item.dataType === 'text' || keyTokens[0]=== 'le'? that.operatorsOnly : that.operatorsAll;
            that.editingItem = item;
        }
    } else {
      if (selected) {
          delete that.selectedItems[item.key];
          this.selectedCount--;
      } else {
          that.selectedItems[item.key] = item.valueText;
          this.selectedCount++;
      }
    }
  }

  editOk() {
    let that: any = this.pickerVisibility;
    let condition:any = that.editingItem.condition;
    if (condition.operator1 && condition.value1) {
        that.editingItem.valueText = that.editingItem.key[0]==='l'? condition.operator1 + "(vacio)" : condition.operator1 + this.getValueString(condition.value1);
        if (condition.operator2 && condition.value2) {
            that.editingItem.valueText += ' ' + this.translate.instant('common.and') + ' ' + condition.operator2 + this.getValueString(condition.value2);
        }
        that.selectedItems[that.editingItem.key] = that.editingItem.valueText;
        this.selectedCount++;
    }else if(that.editingItem.key[0]==='l'){
        that.editingItem.valueText = condition.operator1 + "(vacio)";
        condition.value1 = "\"\"";
        that.selectedItems[that.editingItem.key] = that.editingItem.valueText;
        this.selectedCount++;
    }
    that.editingItem = null;
  }

  getValueString(value:any) {
    if (moment.isDate(value)) {
        value = value.toLocaleDateString();
    }
    return value;
  }

  close(selectedItems:any, orCondition: any) {
      // if (this.onClose) {
          this.onClose.emit({selectedItems:selectedItems, orCondition:orCondition});
      // }
  }

  filterVisibility(){
      let filterList:any = [];
      filterList = this.filterColumnService.filterByWord(this.pickerVisibility.findDropdown, this.pickerVisibility.itemsList, 'fieldLabel');
      this.pickerVisibility.filters.forEach((filter:any) => {
        let filteredList:any = this.pickerVisibility[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
      this.pickerVisibility.filteredItems = filterList;
  }

  selectTypeOperation(e:any, type:any){
      let item:any = e.source;
      let listFilter:any = this.pickerVisibility[type+'ListFilter'];
      this.pickerVisibility[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      if(this.pickerVisibility[type+'Filter']){
        this.pickerVisibility[type+'Filter'].hasFilter = this.pickerVisibility[type+'ListFilter'].length;
      }
      this.filterVisibility();
  }

  itemSelected(e:any, item:any){
      this.pickerVisibility.editingItem.condition[item] = e.newValue;
      if(item === 'operator1' || item === 'operator2'){
        let keyTokens:string = this.pickerVisibility.editingItem.key.split('-');
        this.pickerVisibility.valueOne.noEditable = Boolean(!this.pickerVisibility.editingItem?.condition?.operator1 || this.pickerVisibility.editingItem.dataType === 'text' || keyTokens[0]=== 'le');
        this.pickerVisibility.valueTwo.noEditable = Boolean(!this.pickerVisibility.editingItem?.condition?.operator2 || this.pickerVisibility.editingItem.dataType === 'text' || keyTokens[0]=== 'le');
      }
  }

  resetFilter(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.pickerVisibility.findDropdown = '';
      this.filterVisibility();
  }




}
