<div class="generate-document">
  <div class="common__modal-header-block">
      <i class="lf-icon-track common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'common.historic-action'">Histórico de acciones sobre el documento</span>
      <i class="lf-icon-close document-historic__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="common__modal-body">
      <div class="common__table-header">
          <div class="document-historic__historic-col document-historic__historic-col--user">
              <filter-by-list [type]="'specialListFind'"
                  [item]="documentHistoric.userNameFilter"
                  [list]="documentHistoric.userNameList"
                  [showMyOption]="documentHistoric.showMyOption"
                  [topStyle]="'28'"
                  (filterElementEmit)="selectTypeOperation($event.source, 'userName')">
              </filter-by-list>
          </div>
          <div class="document-historic__historic-col document-historic__historic-col--header-date">
              <div (click)="orderDate()">
                  <p class="common__table-header-text"
                      [afTranslate]="'common.date'">Fecha</p>
                  <i class="lf-icon-arrow-down common__order-by common__order-by--active"
                      [ngClass]="{'lf-icon-arrow-up': !documentHistoric.orderOperationDates}"></i>
              </div>
          </div>
          <div class="document-historic__historic-col document-historic__historic-col--action">
              <filter-by-list [type]="'listFind'"
                  [item]="documentHistoric.actionFilter"
                  [list]="documentHistoric.actionList"
                  [topStyle]="'28'"
                  (filterElementEmit)="selectTypeOperation($event.source, 'action')">
              </filter-by-list>
          </div>
      </div>
      <div class="document-historic__historic-table-body"
          [ngClass]="{'document-historic__historic-table-body--more-elements': documentHistoric.filterHistoricList?.length > 6}">
          <div class="common__table-row"
              *ngFor="let historic of documentHistoric.filterHistoricList | afOrderBy:'actionDate': documentHistoric.orderOperationDates; let index = index">
              <div class="document-historic__historic-col document-historic__historic-col--user">
                  <p class="common__col-text">{{historic.userName}}</p>
              </div>
              <div class="document-historic__historic-col document-historic__historic-col--date">
                  <p class="common__col-text">{{historic.actionDate | convertDate: 'without-time'}}</p>
              </div>
              <div class="document-historic__historic-col document-historic__historic-col--action">
                <div class="document-historic__action-text-block"
                  ngbDropdown
                  [autoClose]="'outside'">
                  <parragraph-tooltip [text]="historic.action"
                    [ngClass]="{'document-historic__action-text-block--with-participants':historic.participants}"></parragraph-tooltip>
                  <i class="lf-icon-visible document-historic__icon-visible"
                    *ngIf="historic.participants"
                    ngbDropdownToggle></i>
                  <list-in-dropdown
                    *ngIf="historic.participants"
                    ngbDropdownMenu role="menu"
                    class="dropdown-menu document-historic__box-select-list"
                    [list]="historic.participantsList"
                    [type]="documentHistoric.type"
                    (closeDropdownEmit)="closeDropdown()">
                  </list-in-dropdown>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
