<div class="send-reminder">
    <div class="common__modal-header-block">
        <i class="lf-icon-notification common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'send-reminder.title'">_Enviar recordatorio</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="send-reminder__block-body">
        <div class="share-instance__block-title">
            <p class="common__title-block-modal"
                [afTranslate]="'send-reminder.subtitle'">_¿A qué destinatarios quieres enviar un recordatorio de la reunión?</p>
        </div>
        <div class="common__option-box common__option-box--two-elements">
            <button class="common__button-box-option"
                (click)="sendReminder.optionSelect='0'">
                <i class="common__button-box-icon"
                    [ngClass]="sendReminder.optionSelect==='0'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                <span class="common__button-box-text"
                    [afTranslate]="'send-reminder.pending-confirm'">_Pendientes de confirmación</span>
            </button>
            <p class="common__box-text-info"
                [afTranslate]="'send-reminder.info-pending'">_Se enviará el recordatorio a las personas que todavía no han aceptado o rechazado la convocatoria de reunión.</p>
            <p class="common__contacts-import-icon">
                <i class="lf-icon-question"></i>
            </p>
        </div>

        <div class="common__option-box common__option-box--two-elements float-end">
            <button class="common__button-box-option"
                (click)="sendReminder.optionSelect='1'">
                <i class="common__button-box-icon"
                    [ngClass]="sendReminder.optionSelect==='1'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                <span class="common__button-box-text"
                    [afTranslate]="'send-reminder.all-participants'">_Todos los asistentes</span>
            </button>
            <p class="common__box-text-info"
                [afTranslate]="'send-reminder.info-all-participants'">_Se enviará el recordatorio a todas las personas invitadas, excepto las que ya han rechazado la convocatoria.</p>
            <p class="common__contacts-import-icon">
                <i class="lf-icon-meeting"></i>
            </p>
        </div>
    </div>

    <div class="common__buttons-block common__buttons-block--modal">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            [afTranslate]="'common.send'"
            (click)="send()">Enviar</button>
    </div>
</div>
