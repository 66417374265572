import { Component, Input, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'new-custom-report-field-assignment',
  templateUrl: './new-custom-report-field-assignment.component.html'
})
export class NewCustomReportFieldAssignmentComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private translate:AfTranslateFactory,
    private modalService:ModalServiceService,
    private loader:LoaderFactory,
    private apiService:ApiServiceService
  ) { }

  @Input() newCustomReportField:any;
  @Input() productList:any;
  @Input() validationErrors:any;
  @Input() selectedProductId:any;
  @Input() editedCustomReportField:any;
  @Input() predefinedReportField:any;

  skin: string = this.globalCfg.skin;
  newCustomReportFieldAssignment:any = {
    infoError: this.translate.instant('new-custom-report-field-assignment.field-alert-text'),
    identityInfo: {
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true,
      label: this.translate.instant('new-custom-report-field-assignment.concept-fields-assignment'),
      description: this.translate.instant('new-custom-report-field-assignment.concept-fields-assignment-text')
    }
  };

  ngOnInit(): void {
    this.newCustomReportFieldAssignment.productId = this.selectedProductId;
  }

  showFieldAssignmentModal() {
    if (!this.predefinedReportField) {
      this.modalService.addAssignment(this.newCustomReportField).subscribe((result:any) => {
        if(result.result === 'ok'){
          this.getPathFields(result.fieldsAdded);
        }
      });
    }
  }

  getPathFields(selectedReportFields:any){
    let urlData = "";
    let count = 0;
    selectedReportFields.forEach((element:any) => {
        if (element.fieldId) {
            if (urlData != "") {
                urlData += "&";
            }
            urlData += 'l[' + count + ']='+ element.fieldId;
            count++;
        }
    });
    this.apiService.get('customreportfields/fieldswithpath?' + urlData).then(
      (data:any) => {
        this.newCustomReportField.selectedReportFields = this.newCustomReportField.selectedReportFields.concat(data);
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
      this.loader.closeLoader('add-new-fields');
    });
  }

  deleteOption(e:any, field:any) {
    let text = this.translate.instant('new-custom-report-field-assignment.question-delete-assignment');
    if (this.editedCustomReportField) {
        text = this.translate.instant('new-custom-report-field-assignment.question-delete-assignment-in-edition');
    }
    this.modalService.adviceModal(text, 'info:question:question').subscribe(() => {
        this.deleteAssignment(field);
        this.modalService.adviceModal(this.translate.instant('new-custom-report-field-assignment.assignment-deleted'), 'info:accept:check-round');
    });
  }

  deleteAssignment(field:any){
    for(let i = 0; i < this.newCustomReportField.selectedReportFields.length; i++){
        let fieldList = this.newCustomReportField.selectedReportFields[i];
        if(fieldList.fieldId === field.fieldId){
            this.newCustomReportField.selectedReportFields.splice(i, 1);
            break;
        }
    }
  }
}
