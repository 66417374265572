import { Component, Input, OnInit, ViewChild, ElementRef} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'configure-image',
  templateUrl: './configure-image.component.html'
})
export class ConfigureImageComponent implements OnInit {
  configureImage: any = {
    editor: false,
    listLogos:[]
  }
  constructor(
    private translate:AfTranslateFactory,
    private activeModal:NgbActiveModal
  ){}

  @Input() logo:any;
  @Input() from:any;
  @Input() preSelectLogos:any;

  @ViewChild('canvas') canvas: ElementRef;

  ngOnInit(): void {
    let that: any = this.configureImage;


    that.logo = this.logo.indexOf('data:image') === -1?"data:image/png;base64," + this.logo : this.logo


    that.listLogos=[{
      html: this.drawCanvas(),
      to: '',
      use: '',
      size: '',
      width: '',
      height: ''
    },{
        html: this.drawCanvas(290, 50),
        to: this.translate.instant('configure-image.login'),
        use: this.translate.instant('configure-image.image-use-login'),
        size: '290x50 px.',
        width: 290,
        height: 50
    },{
        html: this.drawCanvas(320, 55),
        to: this.translate.instant('configure-image.footer'),
        use: this.translate.instant('configure-image.image-use-header-footer'),
        size: '320x55 px.',
        width: 320,
        height: 55
    },{
        html: this.drawCanvas(260, 45),
        to: this.translate.instant('configure-image.header'),
        use: this.translate.instant('configure-image.image-use-header-footer'),
        size: '260x45 px.',
        width: 260,
        height: 45
    }]

    if(this.from==='organization-modal'){
        that.listLogos.splice(1,0,{
          html: this.drawCanvas(50, 50),
          to: this.translate.instant('configure-image.image-corporative'),
          use: this.translate.instant('configure-image.image-use-corporative'),
          size: '50x50 px.',
          width: 50,
          height: 50
        });
    }

  }

  drawCanvas(width:any= 0, height:any = 0) {
    let src: any = this.configureSrc(width, height)
    let canvas:any = document.createElement("canvas");
    let ctx:any = canvas.getContext("2d");
    let img:any = new Image;
    img.onload = function() {
        if((!width && !height)){
            width = img.width;
            height = img.height;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toDataURL('image/png');
    }
    img.src = src;
    return canvas;
  }

  configureSrc(width:any, height:any){
    if(this.preSelectLogos && this.preSelectLogos['logo'+width+'X'+height]){
      return this.preSelectLogos['logo'+width+'X'+height].indexOf('data:image') === -1? "data:image/png;base64," + this.preSelectLogos['logo'+width+'X'+height] : this.preSelectLogos['logo'+width+'X'+height];
    }
    return this.configureImage.logo
  }

  showEditor(logo:any){
    this.configureImage.editor = true;
    this.configureImage.selectedSize = logo;
  }

  canvasSelect(canvas:any){
    this.configureImage.editor = false;
      if(canvas !== null){
        this.configureImage.selectedSize.html = canvas;
      }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      listLogos: this.configureImage.listLogos
    });
  }

}

