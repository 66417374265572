import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'custom-reports',
  templateUrl: './custom-reports.component.html'
})
export class CustomReportsComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private loader:LoaderFactory,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private router:Router,
    private context:ContextFactory,
    private modalService:ModalServiceService
  ) { }

  skin:string =  this.globalCfg.skin;
  customReports:any = {
    views: [
      {
          code: 'reports',
          label: this.translate.instant('custom-reports.view-buttons.reports'),
          subtitleFormat: '',
          css: 'agenda-day',
      },
      {
          code: 'reportFields',
          label: this.translate.instant('custom-reports.view-buttons.reportFields'),
          subtitleFormat: '',
          css: 'agenda-week'
      }
    ],
    searchProduct: '',
    reportList: [],
    reportFieldList: [],
    filterReportList: [],
    filterReportFieldList: [],
    selectedProductId: ''
  };

  ngOnInit() {
    let that = this.customReports;
    that.view = that.views[0];
    that.selectedView = that.views[0];
    this.resetReportObjects()
    this.resetReportFieldObjects()
    this.loader.openLoader('init-requests');
    forkJoin([
      this.apiService.get('products'),
      this.apiService.get('customreports'),
      this.apiService.get('customreportfields')
    ]).subscribe({
      next: (data:any[]) => {
        //Products
        that.productList = this.commonUtilsService.editList(data[0], 'productId', 'name');
        that.currentPage = 1;

        //Reports
        that.reportList = data[1];
        that.filterReportList = that.reportList;

        //Report fields
        that.reportFieldList = data[2];
        that.filterReportFieldList = that.reportFieldList;
      },
      error: (error:any) => {

      },
      complete: () => {
        let productIdSelected = that.productList[0].productId;
        if(this.context?.settings?.defaultProduct){
            productIdSelected = this.context.settings.defaultProduct;
        }
        this.selectProduct(productIdSelected);
        this.loader.closeLoader(['init-requests', 'front-login-login']);
      }
    });
  }

  selectProduct(productId: any) {
      let that = this.customReports;

      that.filterReportList = that.reportList;
      that.filterReportList = this.filterByProduct(that.filterReportList, productId);

      that.filterReportFieldList = that.reportFieldList;
      that.filterReportFieldList = this.filterByProduct(that.filterReportFieldList, productId);
      that.selectedProductId = productId;
  }

  filterByProduct(arrayToFilter: any, productId: any) {
      let filterArray = [];
      for (let itemFilter of arrayToFilter) {
        if (itemFilter.productId === productId) {
          filterArray.push(itemFilter);
        }
      }
      return filterArray;
  }

  resetReportObjects() {
    let that = this.customReports;
    that.predefinedReport = null;
    that.duplicatedPredefinedReport = null;
    that.editedCustomReport = null;
  }

  resetReportFieldObjects() {
    let that = this.customReports;
    that.predefinedReportField = null;
    that.editedCustomReportField = null;
  }

  goToDashboard(){
    this.router.navigate(['dashboard']);
  }

  changeView(view:any) {
    let that = this.customReports;
    that.view = view;
    switch(view.code){
        case 'reports':
        case 'newCustomReport':
            that.selectedView = that.views[0];
            break;
        case 'reportFields':
        case 'newCustomReportField':
            that.selectedView = that.views[1];
            break;
    }
  }

  viewPredefinedReport(predefinedReport:any) {
    this.customReports.predefinedReport = {
        customReportId: null,
        customReportName: predefinedReport.reportName,
        description: predefinedReport.description,
        productId: predefinedReport.productId,
        selectedReportFields: predefinedReport.reportFields,
        conceptsInFirstColumn: false,
        specialFields: false,
        conceptHeaderLabel: this.translate.instant('common.concepts')
    };
    this.changeView({ code: 'newCustomReport' });
  }

  duplicatePredefinedReport(predefinedReport:any) {
    if (predefinedReport.isDuplicable) {
        let conceptsInFirstColumn = predefinedReport.reportFields.filter((p:any) => p.conceptName === true && !p.reportFieldId);
        let conceptsInFirstColumnBoolean = conceptsInFirstColumn.length > 0;
        let conceptHeaderLabel = this.translate.instant('common.concepts');
        if (conceptsInFirstColumnBoolean) {
            conceptHeaderLabel = conceptsInFirstColumn[0].reportFieldName;
        }
        this.customReports.duplicatedPredefinedReport = {
            customReportId: null,
            customReportName: predefinedReport.reportName + ' (copia)',
            description: predefinedReport.description,
            productId: predefinedReport.productId,
            selectedReportFields: predefinedReport.reportFields,
            conceptsInFirstColumn: conceptsInFirstColumnBoolean,
            specialFields: false,
            conceptHeaderLabel: conceptHeaderLabel
        };
        this.changeView({ code: 'newCustomReport' });
    }
  }

  editCustomReport(customReport:any) {
    if (customReport.isEditable) {
        let conceptsInFirstColumn = customReport.reportFields.filter((p:any) => p.conceptName === true && !p.reportFieldId);
        let conceptsInFirstColumnBoolean = conceptsInFirstColumn.length > 0;
        let conceptHeaderLabel = this.translate.instant('common.concepts');
        if (conceptsInFirstColumnBoolean) {
            conceptHeaderLabel = conceptsInFirstColumn[0].reportFieldName;
        }
        this.customReports.editedCustomReport = {
            customReportId: customReport.reportId,
            customReportName: customReport.reportName,
            description: customReport.description,
            productId: customReport.productId,
            selectedReportFields: customReport.reportFields,
            conceptsInFirstColumn: conceptsInFirstColumnBoolean,
            specialFields: false, //specialFields,
            conceptHeaderLabel: conceptHeaderLabel
        };
        this.changeView({ code: 'newCustomReport' });
    } else {
        this.modalService.adviceModal(this.translate.instant('custom-reports.can-not-edit-report'), 'error:accept:warning');
    }
  }

  viewPredefinedReportField(predefinedReportField:any) {
    if (predefinedReportField.selectedReportFields && predefinedReportField.selectedReportFields.length > 0) {
      this.customReports.predefinedReportField = {
            customReportFieldId: null,
            customReportFieldName: predefinedReportField.reportFieldName,
            productId: predefinedReportField.productId,
            selectedReportFields: [],
        };
        this.changeView({ code: 'newCustomReportField' });
    } else {
        this.getPathFieldsForPredefined(predefinedReportField);
    }
  }

  getPathFieldsForPredefined(predefinedReportField:any) {
    this.loader.openLoader('get-path-fields-predefined');
    this.apiService.get('customreportfields/predefinedreportfieldswithpath', predefinedReportField.reportFieldId)
      .then((data:any) => {
        this.customReports.predefinedReportField = {
            customReportFieldId: null,
            customReportFieldName: predefinedReportField.reportFieldName,
            productId: predefinedReportField.productId,
            selectedReportFields: data,
        };
        this.loader.closeLoader('get-path-fields-predefined');
        this.changeView({ code: 'newCustomReportField' });
      }, () => {
         this.loader.closeError()
      });
  }

  editCustomReportField(customReportField:any) {
    if (customReportField.selectedReportFields && customReportField.selectedReportFields.length > 0) {
      this.customReports.editedCustomReportField = {
          customReportFieldId: null,
          customReportFieldName: customReportField.reportFieldName,
          productId: customReportField.productId,
          selectedReportFields: [],
      };
      this.changeView({ code: 'newCustomReportField' });
    } else {
        this.getPathFieldsForCustom(customReportField);
    }
  }

  getPathFieldsForCustom(customReportField:any) {
    this.loader.openLoader('get-path-fields-custom');
    this.apiService.get('customreportfields/customreportfieldswithpath', customReportField.reportFieldId)
    .then((data:any) => {
          this.customReports.editedCustomReportField = {
              customReportFieldId: customReportField.reportFieldId,
              customReportFieldName: customReportField.reportFieldName,
              productId: customReportField.productId,
              selectedReportFields: data,
          };
          this.loader.closeLoader('get-path-fields-custom');
          this.changeView({ code: 'newCustomReportField' });
      }, () => {
          this.loader.closeError()
      });
  }

  changeViewCode(code:string) {
    this.resetReportObjects();
    this.resetReportFieldObjects();

    let view = this.customReports.views.filter((element:any) => { return element.code === code })[0];
    this.changeView(view);
  }

  customReportCreated(customReport:any) {
    let that = this.customReports;
    that.reportList.push(customReport);
    that.filterReportList.push(customReport);
    let customReportName = customReport.reportName;
    let customReportProduct = this.getProductName(customReport);
    let text = this.translate.instant('custom-reports.confirmation-of-creation-one') + customReportName + this.translate.instant('custom-reports.confirmation-of-creation-two') + customReportProduct + ".";
    this.modalService.adviceModal(text, 'info:accept:check-round');
    this.changeView(that.views[0]);
    this.resetReportObjects();
  }

  getProductName(customReport:any) {
    let product = this.customReports.productList.filter((p:any) => p.productId === customReport.productId);
    if (product.length > 0) {
        return product[0].name
    }
    return "";
  }

  customReportEdited(customReport:any) {
    this.replaceCustomReportInList(customReport);
    let customReportProduct = this.getProductName(customReport);
    let text = this.translate.instant('custom-reports.confirmation-of-edition') + customReportProduct + ".";
    this.modalService.adviceModal(text, 'info:accept:check-round');
    this.changeView(this.customReports.views[0]);
    this.resetReportObjects();
  }

  replaceCustomReportInList(customReport:any) {
    let that = this.customReports;
    for(let [index, listElement] of that.reportList.entries()){
        if (customReport.reportId === listElement.reportId && customReport.isCustom === listElement.isCustom) {
            that.reportList[index] = customReport;
            break;
        }
    }
  }

  customReportFieldCreated(customReportField:any) {
    let that = this.customReports;
    that.reportFieldList.push(customReportField);
    that.filterReportFieldList.push(customReportField);
    let customReportFieldName = customReportField.reportFieldName;
    let customReportFieldProduct = this.getProductName(customReportField);
    let text = this.translate.instant('custom-reports.confirmation-of-creation-field-one') + customReportFieldName + this.translate.instant('custom-reports.confirmation-of-creation-field-two') + customReportFieldProduct + ".";
    this.modalService.adviceModal(text, 'info:accept:check-round');
    this.changeView(that.views[1]);
    this.resetReportFieldObjects();
  }

  customReportFieldEdited(customReportField:any) {
    this.replaceCustomReportFieldInList(customReportField);
    let text = this.translate.instant('custom-reports.confirmation-of-edition-report-field');
    this.modalService.adviceModal(text, 'info:accept:check-round');
    this.changeView(this.customReports.views[1]);
    this.resetReportFieldObjects();
  }

  replaceCustomReportFieldInList(customReportField:any) {
    let that = this.customReports;
    for(let [index, listElement] of that.reportFieldList.entries()){
        if (customReportField.reportFieldId === listElement.reportFieldId) {
            that.reportFieldList[index] = customReportField;
            break;
        }
    }
  }
}
