import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'evaluation-blanqueo-row',
  templateUrl: './evaluation-blanqueo-row.component.html'
})
export class EvaluationBlanqueoRowComponent {


  @Input() evaluationsListFilter: any = [];
  @Input() canCreate: boolean = false;
  @Input() view: any = '';
  @Input() client: any;
  @Input() configurationPdf: any;
  @Input() searchEvaluation: any;
  @Input() searchWord: any;
  @Input() dateFilter: any;
  @Input() orderOperationDates: any;
  @Output() emitEventRow = new EventEmitter();

  emitEvent(type: any='', evaluation: any, e:any=undefined){
    this.emitEventRow.emit({
        type: type,
        evaluation: evaluation,
        event: e
    });
  }

}
