<div class="rename-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-simulator-area common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'configure-image.adjust-image'"></span>
      <i class="lf-icon-close common__icon-close-modal "
          (click)="handleDismiss()"></i>
  </div>
  <div class="configure-image__container"
      *ngIf="!configureImage.editor">
      <p class="common__title-block-modal configure-image__title-block-modal"
          [afTranslate]="'configure-image.automatic-redimension'"></p>
      <ul class="configure-image__block-items">
          <li class="configure-image__image-item"
              *ngFor="let logo of configureImage.listLogos; let first = first"
              [ngHide]="first">
              <p class="configure-image__image-text">
                  <span>{{logo.to}}</span>
                  <span [afTranslate]="'configure-image.max-size'"></span>
                  <span>{{logo.size}}</span>
              </p>
              <p class="configure-image__image-text-use">
                {{logo.use}}
              </p>
              <i class="lf-icon-edit configure-image__image-edit-icon"
                  (click)="showEditor(logo)"></i>
              <div class="img-responsive configure-image__inner-canvas"
                  [ngClass]="{'configure-image__inner-canvas--50x50': logo.width===50}"
                  [id]="'canvas-'+logo.width"
                  [renderCanvas]="logo">
              </div>
          </li>
      </ul>
      <div class="img-responsive configure-image__inner-canvas"
          [innerHTML]="configureImage.new">
      </div>
      <div class="common__buttons-block rename-modal__buttons-block">
          <button type="submit"
              class="common__button common__button--active"
              (click)="handleAccept()"
              [afTranslate]="'configure-image.finish'">_Finalizar</button>
      </div>
  </div>

  <image-editor *ngIf="configureImage.editor"
      [selectedSize]="configureImage.selectedSize"
      [imageUrl]="configureImage.logo"
      (canvasSelect)="canvasSelect($event.canvas)"></image-editor>

</div>
