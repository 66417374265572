import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskTypeIcons'
})
export class TaskTypeIconsPipe implements PipeTransform {

  transform(item: any, type:string, repetition:any = {}): any {

      if (type === 'task'){
          switch(item){
              case 2:
                  return "lf-icon-signature";
              case 1:
                  if(repetition && repetition.frecuencyId){
                      return 'lf-icon-periodic-documentation';
                  }else{
                      return "lf-icon-document-validate";
                  }
              case 3:
                  if(repetition && repetition.frecuencyId){
                      return 'lf-icon-periodic-evaluation';
                  }else{
                      return "lf-icon-step-done";
                  }
              case 4:
                  return "lf-icon-meeting";
              case 5:
                  if(repetition && repetition.frecuencyId){
                      return 'lf-icon-periodic-communication';
                  }else{
                      return 'lf-icon-communication';
                  }
              case 7:
                  return 'lf-icon-process-settings';
              case 6:
                  if(repetition && repetition.frecuencyId){
                      return 'lf-icon-periodic-task';
                  }else{
                      return 'lf-icon-task';
                  }

              default:
                  return "number-zero";
          }
      }else if (type === 'alert'){
          switch(item){
              case 'Process':
                  return "lf-icon-process";
              case 'Document':
                  return "lf-icon-documentation";
              case 'Date':
                  return "lf-icon-calendar";
              case 'System':
                  return "lf-icon-configuration";
              default:
                  return "lf-icon-task";
          }
      }else if (type === 'access'){
          switch(item){
              case 1:
                  return "lf-icon-user-special";
              case 2:
                  return "lf-icon-user";
              case 3:
                  return "lf-icon-mail";
              default:
                  return "lf-icon-not-visible";
          }
      }else if(type === 'reference'){
          switch(item){
              case 'memo':
              case 'meme':
                  return "lf-icon-ref-memento";
              case 'mexp':
                  return "lf-icon-ref-doctrine";
              case 'form':
                  return "lf-icon-qmemento";
              case 'legi':
                  return "lf-icon-ref-legis";
              case 'juri':
                  return "lf-icon-ref-juris";
              case 'comm':
                  return "lf-icon-ref-comments";
              case 'actu':
                  return "lf-icon-ref-actum";
              case 'prac':
                  return "lf-icon-practical-cases";
          }
      }else if(type === 'clientState'){
          switch(item){
              case 2:
                  return 'lf-icon-alert';
              case 3:
                  return 'lf-icon-close-round';
              case 4:
                  return 'lf-icon-calendar-cross';
              default:
                  return 'lf-icon-check-round';
          }
      }else{
          switch(item){
              case 'Template':
                  return 'lf-icon-textbooks';
              case 'User':
              case 'Process':
                  return 'lf-icon-document-user';
              case 'Task':
                  return 'lf-icon-task';
              default:
                  return '';
          }
      }

  }

}
