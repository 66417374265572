import { Injectable } from '@angular/core';
import { DateConverterService } from '../date-converter/date-converter.service';

@Injectable({
  providedIn: 'root'
})
export class ReportUtilsService {
  constructor(private dateConverterService: DateConverterService) { }

  generateFrontReport(result: any){
    let config: any = {
        idPrint: result.idPdf,
        nameDocument: result.filename+(result.format==='xls'?'.xlsx':'.pdf'),
        fileName: result.filename,
        title: result.reportTitle,
        list: this.getColList(result.list),
        typeColumns: this.getTypeColumns(result.reportColumns),
        columns: this.getColumns(result.reportColumns),
        apiUrl: 'reportdocuments/upload/'+result.reportObjectId,
        uploadFile: true,
        landscape: true,
    };
    return config;
  }

  getTypeColumns(reportColumns: any){
    let typeColumns : any = [];
      reportColumns.forEach((col: any) => {
        if(col.fieldTypeId !== 'conceptObjectId'){
          typeColumns.push(col.id);
        }
    });
    return typeColumns;
  }

  getColList(filterList: any){
    let listRows : any = [];
    filterList.forEach((row: any) => {
        let rowPrint: any = {};
        for(let i = 0; i < 29; i++){
          if(row['OFAFMVJfield'+i].fieldTypeId !== 'conceptObjectId'){
            rowPrint[row['OFAFMVJfield'+i].key] = row['OFAFMVJfield'+i].fieldTypeId === 'date'?this.dateConverterService.convertDate(row['OFAFMVJfield'+i].value, 'without-time'):row['OFAFMVJfield'+i].value;
          }
        }
        listRows.push(rowPrint);
    });
    return listRows;
  }

  getColumns(reportColumns: any){
    let columns: any = {};
    reportColumns.forEach((col: any) => {
      if(col.fieldTypeId !== 'conceptObjectId'){
        columns[col.id] = col.name;
      }
    });
    return columns;
  }
}
