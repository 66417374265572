<div class="common__table-display participants-list__cell-block"
    [ngClass]="{'participants-list__cell-block--more-elements': participantsList.participants?.length > 1}">
    <div class="common__table-cell-tooltip">
        <div class="concept-list__cell-block">
            <p [id]="'participant-first-destination-'+participantsList.idFirstParticipant"
                class="common__col-text participants-list__text-participant"
                [ngClass]="{'participants-list__text-participant--little': participantsList.notificated || participantsList.participants.length > 1,
                    'participants-list__text-participant--more-little': participantsList.notificated && participantsList.participants.length > 1,
                    'participants-list__text-participant--inherit-color': type === 'task-process-concept-list',
                    'common__line-through':participantsList.participants[0]?.isDeleted}"
                [ngbTooltip]="participantsList.participants[0]?.fullName + (participantsList.participants[0]?.isDeleted?' ('+ ('common.inactive-user' | afTranslate)+')' : '')"
                placement="bottom"
                container="body"
                tooltipClass="compliance__tooltip"
                triggers="manual"
                #tooltip="ngbTooltip"
                (mouseenter)="showTooltip('participant-first-destination-'+participantsList.idFirstParticipant) && tooltip.open()"
                (mouseleave)="tooltip.close()">
                <span [id]="'span-participant-first-destination-'+participantsList.idFirstParticipant">{{participantsList.participants[0]?.fullName}}</span>
            </p>
            <!-- <span-tooltip class="common__col-text participants-list__text-participant"
              [text]="participantsList.participants[0]?.fullName"
              [isDeleted]="participantsList.participants[0]?.isDeleted"></span-tooltip> -->
            <div ngbDropdown
                [autoClose]="'outside'">
                <i class="lf-icon-visible participants-list__icon-eye"
                    [ngShow]="participantsList.participants?.length > 1"
                    ngbDropdownToggle></i>
                <i class="lf-icon-communication participants-list__icon-eye participants-list__icon-eye--communication"
                    [ngShow]="type === 'alert' && participantsList.notificated"></i>
                <list-in-dropdown ngbDropdownMenu role="menu"
                    class="dropdown-menu participants-list__box-select-list"
                    [list]="participantsList.participants"
                    [type]="type"
                    (closeDropdownEmit)="closeDropdown()"></list-in-dropdown>
            </div>
        </div>
    </div>
</div>
