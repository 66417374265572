<div class="personalized-notification-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-communication common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'personalized-notification-modal.custom-notification'">_Notificación personalizada</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="cancelTransition()"></i>
  </div>
  <div class="personalized-notification-modal__block-body">
      <div class="personalized-notification-modal__block-title">
          <p class="common__title-block-modal"
              [afTranslate]="'personalized-notification-modal.title-body'">_Se ha configurado en este cambio de estado el envío de la siguiente notificación:</p>
      </div>
      <form>
          <field-type-text-simple [field]="personalizedNotification.subjectNotification"
                      [preSelectItem]="personalizedNotification.customEmail?.customSubject"
                      (itemSelected)="itemSelected($event, 'customSubject')">
          </field-type-text-simple>
          <field-type-rich-text-editable *ngIf="personalizedNotification.customNotificationField"
              class="personalized-notification-modal__text-rich"
              [field]="personalizedNotification.customNotificationField"></field-type-rich-text-editable>
      </form>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset"
                  class="common__button common__button--clean"
                  (click)="cancelTransition()"
                  [afTranslate]="'common.cancel'">_Cancelar</button>
          <button type="submit"
                  class="common__button common__button--active"
                  (click)="handleAccept()"
                  [afTranslate]="'common.finish'">_Finalizar</button>
      </div>
  </div>
</div>
