import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  constructor(private translate:AfTranslateFactory){}
  transform(toDate: string): string {
    let now = new Date();
    let diffDays = moment().diff(moment(toDate.substring(0, toDate.indexOf('T'))).valueOf(), 'days');
    let diffMonths = Math.floor(moment(now).diff(moment(toDate), 'months', true));
    let diffWeeks = Math.floor(moment(now).diff(moment(toDate), 'weeks', true));
    let diffYears = Math.floor(moment(now).diff(moment(toDate), 'years', true));

    if (diffDays === 0) {//Hoy
        return this.translate.instant('relative-time.today') + moment(this.convertUTCDateToLocalDate(toDate)).format('HH:mm');
    } else if (diffDays === 1) {//Ayer
        return (this.translate.instant('relative-time.yesterday') + (moment(this.convertUTCDateToLocalDate(toDate)).add(Math.abs(new Date().getTimezoneOffset()), 'minutes').format('HH:mm') + 'h'));
    } else if (diffWeeks === 0) { //Hace X días
        let days: any = Math.floor(diffDays);
        return (this.translate.instant('relative-time.days-ago').replace('{days}', days));
    } else if (diffWeeks === 1) { //Hace 1 semana
        return (this.translate.instant('relative-time.week-ago'));
    } else if (diffWeeks > 1 && diffMonths < 1) {//Hace X semanas
        let weeks: any = Math.floor(diffWeeks);
        return (this.translate.instant('relative-time.weeks-ago').replace('{weeks}', weeks));
    } else if (diffMonths === 1) {//Hace 1 mes
        return (this.translate.instant('relative-time.month-ago'));
    } else if (diffMonths >= 2 && diffYears < 1) {//Hace X meses
        let months: any = Math.floor(diffMonths);
        return (this.translate.instant('relative-time.months-ago').replace('{months}', months));
    } else if (diffYears === 1) {//Hace 1 año
        return (this.translate.instant('relative-time.year-ago'));
    } else {//Hace X años
        let years: any = Math.floor(diffYears);
        return (this.translate.instant('relative-time.years-ago').replace('{years}', years));
    }
  }

  convertUTCDateToLocalDate(toDate:string) {
    let hoursToSubtract = this.isSummerTime()? 2 : 1;
    let date = new Date(moment(toDate).subtract(hoursToSubtract, 'hour').format('YYYY-MM-DDTHH:mm:ss'));
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  isSummerTime(){
      let date = new Date();
      let january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
      let july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(january, july) !== date.getTimezoneOffset();
  }
}
