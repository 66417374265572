<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i [class]="boxProtection.icon + ' attachments-template__custom-add-icon'"></i>
        <p class="attachments-template__custom-header-title box-protection-data__custom-header-title"
          [afTranslate]="boxProtection.title">
        </p>
    </div>
    <div class="box-protection-data__block-form-info">
        <div class="box-protection-data__header-info">
            <span [afTranslate]="boxProtection.subtitle"></span>
        </div>
        <div [innerHTML]="data"
            class="box-protection-data__text-info">
        </div>
    </div>
  </div>
