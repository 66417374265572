
import { Component, Input, OnInit, OnChanges, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FieldTypeContactsParentService } from './../utils/field-type-contacts-parent.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';



@Component({
  selector: 'field-type-contacts-simple',
  templateUrl: './field-type-contacts-simple.component.html'
})
export class FieldTypeContactsSimpleComponent implements OnInit, OnChanges, OnDestroy {
  isLanding = false;
  id: any = this.commonUtilsService.createId();
  fieldTypeContacts: any = this.fieldTypeContactsParent.configureField(this);

  constructor(private route: ActivatedRoute,
    private userPermisions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private fieldTypeContactsParent: FieldTypeContactsParentService) {}

    @Input() field : any ={};
    @Input() concept : any ={};
    @Input() valueId : any = null;
    @Output() openRelatedInfo = new EventEmitter();
    @Output() contactsClosed = new EventEmitter();
    @Output() sendContacts = new EventEmitter();
    @Output() showFieldReference = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeContactsParent.configureInit(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldTypeContactsParent.configureChanges(changes, this);
  }

  openContactList(){
    this.fieldTypeContactsParent.openContactList(this);
  }

  showReference(e: any){
    this.fieldTypeContactsParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeContactsParent.openModalRelatedInfo(e, fieldRelatedInfo, this);
  }

  resetSearch(e: any){
    this.fieldTypeContactsParent.resetSearch(e, this);
  }

  changeState(e: any, state: any){
    this.fieldTypeContactsParent.changeState(e, state, this);
  }

  changeView(){
    this.fieldTypeContactsParent.changeView(this);
  }

  addContact(participant: any, type: any) {
    this.fieldTypeContactsParent.addContact(participant, this);
    this.addSimpleItem(participant);
  }

  deleteContact(e: any, index: any){
    this.fieldTypeContactsParent.deleteContact(e, index, this);
  }

  resetContacts(){
    this.fieldTypeContactsParent.resetContacts(this);
  }

  showOnly(type: any, e: any){
    this.fieldTypeContactsParent.showOnly(this, type, e);
  }

  addSimpleItem(participant: any){
    if(!participant.disabled){
      let type : string = 'user';
      if(participant.contactId){
        type = 'contact';
      }else if(participant.productRoleId){
        type = 'productRole';
      }
      participant.active = true;
      participant.listValueId = participant[type+'Id'];
      this.fieldTypeContacts.contactListSelected = [participant];
      this.fieldTypeContacts.openUserList = !this.fieldTypeContacts.openUserList;
      if(this.field.typeConfiguration === "field-user"){
        let typeId = type === 'productRole'?'role':type;
        let newContact = this.fieldTypeContacts.contactListSelected[0].listValueId + '-'+ typeId;
        this.field.evaluatedValue.displayData = [];
        this.fieldTypeContactsParent.configureDisplayDataList(participant, this);
        this.sendContacts.emit({newValue: newContact});
      }else{
        this.sendContacts.emit({contacts:this.fieldTypeContacts.contactListSelected});
      }
      if(this.field){
          this.field.error = false;
      }
    }
  }

  showTooltip(elementId: any, participant: any){
    return this.fieldTypeContactsParent.showTooltip(this, elementId, participant);
  }

  catchEvent(e: any){
      e.stopPropagation();
  }

  ngOnDestroy(){
    this.fieldTypeContactsParent.ngOnDestroy();
  }
}
