import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChildren, QueryList  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ReportTableParentService } from '../utils/report-table-parent.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';

@Component({
  selector: 'report-table-por-usuario',
  templateUrl: './report-table-por-usuario.component.html'
})

export class ReportTablePorUsuarioComponent implements OnInit, OnChanges  {
  skin: string = this.globalCfg.skin;
  archived: boolean = this.route.snapshot.params['archived'] === 'archived'
  evaluationId: any = this.route.snapshot.params['evaluationId'];
  url = this.skin === 'icam-red'? 'icam/':''
  configureInit: boolean = false;

  reportTable: any = this.reportTableParent.configureReportTable(this);

  constructor(
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private router: Router,
    private reportTableParent: ReportTableParentService,
    private dateConverterService: DateConverterService) {

  }

  @Input() report:any = null;
  @Input() configurateReport:any = null;

  @Output() updateListEmit = new EventEmitter();
  @Output() exportReportEmit = new EventEmitter();

  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>


  ngOnInit(): void {
  }


  ngOnChanges(): void {
    this.reportTableParent.onChanges(this)
  }


  exportReport(onlyRename:Boolean = false){
    this.exportReportEmit.emit({onlyRename:onlyRename})
  }

  selectIcamYear(year:any){
    this.reportTableParent.selectIcamYear(year, this)
  }


  orderBySearch(e:any, type: any){
    this.reportTableParent.orderBySearch(e.newValue, type, this)
  }

  selectTypeOperation(e:any, column:any){
    this.reportTableParent.selectTypeOperation(e.source, column, this)
  }

  orderTable(column:any){
    this.reportTableParent.orderTable(column, this)
  }


  showUserQueries(userRow:any){
    this.reportTableParent.showUserQueries(userRow, this)
  }


  convertDate(date:any, formatType:any = '') {
    return this.dateConverterService.convertDate(date, formatType);
  }

  goToDetail(detail:any) {
    this.router.navigateByUrl('evaluation/'+detail.evaluationId+'/folder/'+detail.folderId+'/concept/'+detail.conceptId+'/concept-evaluated/'+detail.conceptObjectId);
  }

  changeCalendar(e:any, type:any){
    this.reportTableParent.changeCalendar(e.dates, type, this)
    this.closeDropdown('creationDate')

  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

}
