<div class="customize-field-num">
  <div [ngClass]="{'customize-fields__body-field-container': typeView === 'channelViewEditable'}">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldNum.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': customizeFieldNum.disabledFormConfiguration}"
        [(ngModel)]="customizeFieldNum.field.extendedLabel"
        [disabled]="customizeFieldNum.disabledFormConfiguration"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldNum.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldNum.tinyMceOptions"
      [modelText]="customizeFieldNum.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text"
      *ngIf="!customizeFieldNum.channelViewConfiguration">
        <field-type-list-simple [field]="customizeFieldNum.contentReportInfo"
            [preSelectItem]="customizeFieldNum.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldNum.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldNum.field.listVisibleValues"
            [selectedFields]="customizeFieldNum.field.fieldVisibleValues"
            [orCondition]="customizeFieldNum.field.visibilityOr"
            [readonly]="customizeFieldNum.field.noEditable || customizeFieldNum.disabledFormConfiguration"
            [itemId]="customizeFieldNum.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-number [field]="customizeFieldNum.decimalsInfo"
            [preSelectItem]="customizeFieldNum.field.decimals"
            (itemSelected)="itemSelected($event, 'decimals')">
        </field-type-number>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-number [field]="customizeFieldNum.defaultValueInfo"
            [preSelectItem]="customizeFieldNum.field.defaultValue"
            [decimals]="customizeFieldNum.field.decimals"
            (itemSelected)="itemSelected($event, 'defaultValue')">
        </field-type-number>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare && typeView !== 'channelViewEditable'"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="customizeFieldNum.disabledFormConfiguration || isPrivate || customizeFieldNum.field.isIdentifier"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldNum.field.required"
        [disabled]="customizeFieldNum.field.noEditable || customizeFieldNum.disabledFormConfiguration || customizeFieldNum.field.isIdentifier"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
  </div>
  <div class="common__buttons-block common__buttons-block--customize"
    *ngIf="typeView !== 'channelViewNoEditable'">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="cancelFieldText()"
          [disabled]="concept.disabled && typeView !== 'channelViewEditable'"
          [afTranslate]="'common.cancel'">Cancelar </button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="saveFieldText()"
          [disabled]="concept.disabled"
          [afTranslate]="'common.save'">Guardar </button>
  </div>
</div>
