import { Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html'
})
export class VideoPlayerComponent implements OnInit {
  videoPlayer: any = {}
  constructor(private activeModal: NgbActiveModal) { }
  @Input() configuration: any;

  ngOnInit(): void {
    this.videoPlayer.videoSelected = this.configuration.videoSelected;
    this.videoPlayer.videos = cloneDeep(this.configuration.videos);
    this.selectVideoUrl();

  }

  selectVideoUrl(){
    if(this.videoPlayer.videoSelected.videoType ==='vimeo'){
        this.videoPlayer.videoSelected.videoUrl = 'https://player.vimeo.com/video/'+this.videoPlayer.videoSelected.videoCode;
    }else{
        this.videoPlayer.videoSelected.videoUrl = 'https://www.youtube.com/embed/'+this.videoPlayer.videoSelected.videoCode;
    }
  }

  videoChange(video:any){
    this.videoPlayer.videos.forEach((video:any) => {
        video.active = false;
    });
    this.videoPlayer.videoSelected = video;
    this.videoPlayer.videoSelected.active = true;
    this.selectVideoUrl();
  }

  handleAccept() {
    this.activeModal.close({
        result:'ok'
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
