<div class="concept-instances-list">
  <div class="common__header-new-version">
      <span class="common__header-title-new-version"
          [ngShow]="!conceptInstancesList.search"
          [afTranslate]="'concept-instances-list.processes-in-progress'">_Procesos en curso</span>
      <div class="clients-list__block-search-v3"
          [ngClass]="{'clients-list__block-search-v3--100' : conceptInstancesList.search}">
          <input type="text"
              [ngShow]="conceptInstancesList.search"
              class="common__search-input"
              [(ngModel)]="conceptInstancesList.searchInstances"
              placeholder="{{'common.search' | translate}}"
              [focusWhen]="conceptInstancesList.search">
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !conceptInstancesList.search,
                  'lf-icon-close-search': conceptInstancesList.search,
                  'common__icon-button-search--open': conceptInstancesList.search}"
              (click)="toggleSearch()">
          </button>
      </div>
  </div>
  <div class="common__table-block">
      <div class="common__table-new-version">
          <div class="common__table-header">
              <div class="concept-instances-list__col concept-instances-list__col--client-uk concept-instances-list__col--first">
                  <filter-by-list [type]="'listFind'"
                      [widthClass]="'200px'"
                      [item]="conceptInstancesList.clientNameFilter"
                      [list]="conceptInstancesList.clientNameList"
                      [filterOrder]="conceptInstancesList.filterOrder"
                      [order]="conceptInstancesList.order"
                      (orderCol)="searchBy($event.type)"
                      (filterElementEmit)="filterElementOn($event, 'clientName')">
                  </filter-by-list>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--external-id-uk">
                  <p class="common__table-header-text"
                      [afTranslate]="'clients-list.our-reference'">_Our reference</p>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--process-uk">
                  <filter-by-list [type]="'listFind'"
                      [widthClass]="'200px'"
                      [item]="conceptInstancesList.conceptTitleFilter"
                      [list]="conceptInstancesList.conceptTitleList"
                      (filterElementEmit)="filterElementOn($event, 'conceptTitle')">
                  </filter-by-list>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--confidential"></div>
              <div class="concept-instances-list__col concept-instances-list__col--actions-uk">
                  <p class="common__table-header-text common__table-header-text--right"
                      [afTranslate]="'common.actions'">Acciones</p>
              </div>
          </div>
          <div class="common__table-body common__table-body--8-elements"
              [ngClass]="{'common__table-body--more-elements': conceptInstancesList.instancesListFilter?.length > 5}">
              <div class="common__table-row"
                  *ngFor="let instance of conceptInstancesList.instancesListFilter | byWord:conceptInstancesList.searchInstances:['clientName', 'clientCIF', 'reference2', 'conceptTitle', 'conceptObjectTitle'] | afOrderBy:conceptInstancesList.filterOrder:conceptInstancesList.order | unique: 'conceptObjectId'; let i = index">
                  <div class="concept-instances-list__col concept-instances-list__col--client-uk concept-instances-list__col--first concept-instances-list__col--tooltip"
                      (click)="launchActionInstance(instance, 'open')">
                      <parragraph-tooltip [text]="instance.clientName"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--external-id-uk concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.reference2"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--process-uk concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.conceptTitle"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--confidential">
                      <div *ngIf="instance.confidential"
                          ngbDropdown
                          [id]="'concept-instances-list-confidential-'+instance.conceptObjectId"
                          autoClose="'outside'">
                          <i class="lf-icon-lock concept-list__icon-confidential"
                              ngbDropdownToggle
                              matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                          <list-in-dropdown ngbDropdownMenu
                              class="dropdown-menu concept-list__box-select-list"
                              (closeDropdownEmit)="closeDropdown('concept-instances-list-confidential-'+instance.conceptObjectId)"
                              [list]="instance.confidentialList"
                              [type]="'confidential'">
                          </list-in-dropdown>
                      </div>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--actions-uk">
                      <ul class="common__options-row">
                          <li class="common__menu-options-row"
                              matTooltip="{{(instance.canModify || instance.canManage?'concept-list.description-edit':'concept-list.description-view') | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i [ngClass]="{'lf-icon-editable-form': instance.canModify || instance.canManage,
                                  'lf-icon-visible': !instance.canModify && !instance.canManage}"
                                  (click)="launchActionInstance(instance, 'open')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              matTooltip="{{'concept-list.description-open' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-open-window"
                                  (click)="launchActionInstance(instance, 'openNewTab')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="userPermissions !== 'none'"
                              matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-lock"
                                  (click)="openModalConfidential(instance)"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="instance.canModify && instance.canManage && instance.conceptId && !instance.hasAutoArchive"
                              matTooltip="{{'concept-list.archive-concept' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-archive"
                                  (click)="launchActionInstance(instance, 'archive')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="instance.canModify && instance.canManage && !instance.restrictedDelete"
                              matTooltip="{{'concept-list.description-delete' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-trash"
                                  (click)="launchActionInstance(instance, 'delete')"></i>
                          </li>
                      </ul>
                      <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__block-footer-table">
      <div class="common__block-footer-total">
          <span [afTranslate]="'common.total'">_Total</span>
          <span class="common__total-number-element notranslate">{{conceptInstancesList.instancesListFilter?.length}}</span>
      </div>
  </div>
</div>
