import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ReportsListParentService } from '../utils/reports-list-parent.service';
@Component({
  selector: 'report-list-be',
  templateUrl: './report-list-be.component.html'
})
export class ReportListBeComponent implements OnInit{
  skin: string = this.globalCfg.skin;
  adviceModalSubject: any;
  documentShareModalSubject: any;
  reportsList: any = this.reportListParent.configureItem();
  filterReportsList: any = this.route.snapshot.data['filterReportsList']
  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private commonUtilsService: CommonUtilsService,
    private router: Router,
    private documentActions: DocumentActionsService,
    private reportListParent: ReportsListParentService) {}

    @Input() productSelected: any = null;
    @Input() noHasTemplatesReports:boolean = false;
    @Input() userPermissions: string = 'none';
    @Output() launchAction = new EventEmitter;

  ngOnInit(): void {
      this.reportListParent.onChanges(this);
      this.reportListParent.selectLastReport(this);
      this.loader.closeLoader(['open-list-reports', 'front-login-login']);
  }

  filterReport(type:string = ''){
      this.reportListParent.filterReport(type, this);
  }

  orderDate(type:string){
      if(this.reportsList.dateOrder === type){
          this.reportsList.orderOperationDates = !this.reportsList.orderOperationDates;
      }else{
        this.reportsList.dateOrder = type;
          if(type === 'title'){
            this.reportsList.orderOperationDates = (type !== 'title');
          }
      }
  }

  toggleSearch() {
      this.reportListParent.toggleSearch(this)
  }

  createNewReport(){
      this.reportListParent.createNewReport(this);
  }

  openReport(report:any){
      this.documentActions.doAction('edit', report);
  }

  downloadReport(report:any){
      this.documentActions.doAction('download', report);
  }

  deleteReport(report:any, last: boolean = false){
      this.reportListParent.deleteReport(report, this, last)
  }

  changeView(){
      this.commonUtilsService.goToStateUrl();
  }

  openFocus(type:string){
      this.reportsList['openFocus'+type] = !this.reportsList['openFocus'+type];
  }

  resetFilter(e:any, type:any){
      this.reportListParent.resetFilter(e, type, this);
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id)
  }
}
