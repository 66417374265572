<div class="menu-header-products">
  <div class="menu-header-products__header">
      <img class="menu-header-products__logo"
        src="../assets/img/logo-ecosistema.svg"
        alt="logo-ecosistema"/>
      <span class="lf-icon-close menu-header-products__close-button"
        (click)="closeDropdown()"></span>
  </div>
  <span class="menu-header-products__title"
    [afTranslate]="'menu-header-products.title'"></span>
  <ul class="menu-header-products__products-list">
      <div *ngFor="let product of menuHeaderProducts.listProduct"
        [ngShow]="product.id !== 11">
        <li class="menu-header-products__product">
          <span [class]="product.icon + ' menu-header-products__product-icon'"
              (click)="openService(product)"></span>
          <span class="menu-header-products__product-label">{{product.name}}</span>
        </li>
      </div>
      <li class="menu-header-products__product">
          <span class="lf-icon-mail menu-header-products__product-icon"
            (click)="openMail()"></span>
          <span class="menu-header-products__product-label"
            [afTranslate]="'common.e-mail'"></span>
      </li>
  </ul>
</div>
