<div class="landing-page"
    *ngIf="landingPage.custom">
    <div class="preview-customization__header">
        <img class="preview-customization__logo-img"
                alt="Logo"
                [src]="landingPage.custom.logoHeader"
                [ngShow]="landingPage.custom.logoHeader" />
        <div class="header-nav__custom-translates-list-block">
                <button class="landing-page__button-logout"
                    [ngShow]="skin === 'icam-red'"
                    (click)="logOut()"
                    matTooltip="{{'common.log-out' | afTranslate}}"
                    matTooltipPosition="below"
                    matTooltipClass="below">
                    <i class="lf-icon-close-session header-nav__icon-header"></i>
                </button>
            <div [ngShow]="landingPage.languages.length > 1"
                class="landing-page__block-languages"
                ngbDropdown
                [open]="landingPage.showList">
                <button class="header-nav__custom-select-language-button"
                        ngbDropdownToggle>
                    <span class="header-nav__select-language">
                        {{landingPage.selectedLanguage.text}}
                    </span>
                    <span class="header-nav__select-language-short">
                        {{landingPage.selectedLanguage.appLanguage}}
                    </span>
                    <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                </button>
                <ul class="header-nav__custom-language-list header-nav__custom-language-list--block"
                    ngbDropdownMenu
                    role="menu">
                    <li class="common__language-item common__language-item--custom"
                        *ngFor="let language of landingPage.languages"
                        (click)="changeAppLanguage(language)">
                        <span class="header-nav__custom-language-option">
                            {{language.text}}
                        </span>
                        <span class="header-nav__custom-language-option header-nav__custom-language-option--specific">
                            ({{language.specific}})
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="preview-customization__title-concept landing-page__custom-title"
            [ngStyle]="{'background-color': landingPage.custom.color, 'color': landingPage.custom.textColor}">
        <p class="landing-page__custom-text-title common__ellipsis, landing-page__custom-text-title--ellipsis"
            [ngClass]="{'landing-page__custom-text-title--ellipsis': landingPage.task?.taskId || landingPage.showButtonProcedures,
                    'landing-page__custom-text-title--ellipsis-special': skin === 'icam-red'}">
            <span [ngShow]="landingPage.concept?.titlePrefix && skin === 'icam-red' && !landingPage.showProcedure && !landingPage.showConceptUserProfile">{{landingPage.concept?.titlePrefix}} / </span>
            <span>{{landingPage.titleShared}}</span>
        </p>
        <div class="landing-page__block-right-procedures"
             [ngShow]="landingPage.showButtonsHeader">
            <button class="landing-page__button-procedures-custom landing-page__button-procedures-custom--applicant-profile"
                    [ngShow]="skin === 'icam-red' && (landingPage.concept?.conceptObjectId && landingPage.showButtonNewQuery)"
                    [disabled]="landingPage.disabledButtonsHeader"
                    [ngStyle]="{'color': landingPage.custom.textColor, 'border-color': landingPage.custom.textColor}"
                    (click)="createNewQuery()">
                <i class="report-table__graph-button-icon lf-icon-add-round"></i>
                <span class="landing-page__text-button-procedures"
                    [ngClass]="{'landing-page__text-button-procedures--hidden-mobile': skin === 'icam-red'}"
                    [afTranslate]="'landing-page.create-new-query'"></span>
            </button>
            <button class="landing-page__button-procedures-custom landing-page__button-procedures-custom--applicant-profile"
                    [ngShow]="landingPage.showButtonUserProfile"
                    [disabled]="landingPage.disabledButtonsHeader"
                    [ngStyle]="{'color': landingPage.custom.textColor, 'border-color': landingPage.custom.textColor}"
                    (click)="showUserProfile()">
                <i class="report-table__graph-button-icon lf-icon-user-settings"></i>
                <span class="landing-page__text-button-procedures"
                      [ngClass]="{'landing-page__text-button-procedures--hidden-mobile': skin === 'icam-red'}"
                      [afTranslate]="'landing-page.see-applicant-profile'">Ver perfil de usuario</span>
            </button>
            <!--/*WEB-40464 (ng-show="landingPage.showButtonProcedures && !landingPage.comingSoon")*/-->
            <button class="landing-page__button-procedures-custom"
                    [ngShow]="landingPage.showButtonProcedures"
                    [disabled]="landingPage.disabledButtonsHeader"
                    [ngStyle]="{'color': landingPage.custom.textColor, 'border-color': landingPage.custom.textColor}"
                    (click)="showProcedures()">
                <i class="report-table__graph-button-icon lf-icon-collection"></i>
                <span class="landing-page__text-button-procedures"
                    [ngClass]="{'landing-page__text-button-procedures--hidden-mobile': skin === 'icam-red'}">{{landingPage.proceduresText}}</span>
            </button>
            <button *ngIf="!task && (!isPublic || isMonitoring)"
                    class="lf-icon-print landing-page__icons-header-custom"
                    (click)="printConcept()"
                    [disabled]="!landingPage.conceptSelected?.conceptObjectId"
                    [ngStyle]="{'color': landingPage.custom.textColor, 'border-color': landingPage.custom.textColor}"></button>
            <button class="lf-icon-information landing-page__icons-header-custom landing-page__icons-header-custom--border-none"
                    [ngShow]="landingPage.conceptSelected && landingPage.conceptSelected?.description !== '' && landingPage.conceptSelected?.description !== null && !landingPage.conceptSelected?.poll"
                    (click)="showConceptInfo()"></button>
        </div>
        <div class="float-end"
                [ngShow]="landingPage.task?.taskId">
            <p class="landing-page__custom-creation"
                [ngStyle]="{'color': landingPage.custom.textColor}">
                <span [afTranslate]="'landing-page.created-by'">Creada por  </span>
                <span class="landing-page__custom-creation--created-and-date"> {{landingPage.userLogin}}</span>
                <span class="landing-page__custom-creation--created-and-date"> {{landingPage.creationDate | convertDate:'without-time'}} </span>
            </p>
            <button class="landing-page__custom-button-to-concept"
                    [ngStyle]="{'color': landingPage.custom.textColor, 'border-color': landingPage.custom.textColor}"
                    [ngShow]="landingPage.task?.taskTypeId === 3"
                    (click)="editConcept()">
                <i class="lf-icon-forms landing-page__custom-button-to-concept-icon"></i>
                <span class="landing-page__button-to-concept-text"
                        [afTranslate]="'landing-page.concept-access'">Acceder al concepto</span>
            </button>
        </div>
    </div>
    <div [ngShow]="(landingPage.conceptSelected?.poll && landingPage.conceptSelected?.sequentialView) && !landingPage.showProcedure && ((landingPage.conceptSelected?.sequentialView && landingPage.conceptSelected?.startedPoll) || !landingPage.conceptSelected?.sequentialView) && !landingPage.conceptSelected?.finishedPoll"
            class="landing-page__container-progress-poll-custom">
        <div class="landing-page__inside-block-progress-poll-custom">
            <div class="landing-page__block-progress-poll-custom"
                [ngStyle]="{'border-color': landingPage.custom.color, 'color': landingPage.custom.color }">
                <span class="landing-page__progress-poll-progress-custom">{{landingPage.conceptSelected?.progress}}</span>
                <span class="landing-page__progress-poll-progress-custom span__auto-ajust">%</span>
                <span class="landing-page__text-poll-progress-custom"
                    [afTranslate]="'common.completed'">completado</span>
            </div>

            <div class="landing-page__block-arc-progress">
                <div class="landing-page__elements"
                    [ngStyle]="{'color': landingPage.custom.color}">
                    <span class="landing-page__progress-poll-progress-custom">{{landingPage.conceptSelected?.progress}}</span>
                    <span class="landing-page__progress-poll-progress-custom span__auto-ajust">%</span>
                    <span class="landing-page__text-poll-progress-custom"
                            [afTranslate]="'common.completed'">completado</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="-1 -1 34 34" class="landing-page__svg-arc">
                    <circle cx="16" cy="16" r="15.9155"
                        class="landing-page__background-arc" />
                    <!-- {'stroke-dashoffset': 'var('+100-landingPage.conceptSelected?.progress+')', stroke:landingPage.custom?.color} -->
                    <circle cx="16" cy="16" r="15.9155"
                        [style]="'--dashoffset:'+(100-landingPage.conceptSelected?.progress)+'; --color:'+landingPage.custom.color"
                        class="landing-page__progress-arc" />
                </svg>
            </div>
            <div class="landing-page__block-100-progress-poll">
                <span>100%</span>
            </div>
            <div class="landing-page__progress-bar-custom">
                <div class="landing-page__progress-complete"></div>
                <div class="landing-page__progress-percent"
                    [ngStyle]="{width:landingPage.conceptSelected?.progress+'%', 'background-color': landingPage.custom.color}"></div>
            </div>
        </div>

    </div>
    <div class="preview-customization__body preview-customization__body--custom"
        [ngClass]="landingPage.conceptSelected?.sequentialView && !landingPage.conceptSelected?.finishedPoll?'preview-customization__body--custom-sequential':'preview-customization__body--custom-sequential-finish'"
        [ngShow]="!landingPage.showProcedure">
        <div class="preview-customization__breadcrumb-concept"
                [ngShow]="landingPage.dataHeader?.customBreadcrumbs && !landingPage.concept?.poll">
            <div class="landing-page__custom-breadcrumb"
                    *ngFor="let breadcrumb of landingPage.dataHeader?.customBreadcrumbs; let last = last">
                <span class="landing-page__custom-breadcrumb-text"
                        [ngClass]="{'preview-customization__text-breadcrumb--last':last}">{{breadcrumb}}</span>
                <i class="lf-icon-important landing-page__custom-breadcrumb-icon"
                    [ngHide]="last"></i>
            </div>
        </div>
        <div [ngShow]="landingPage.conceptSelected?.poll && !landingPage.showProcedure && !landingPage.conceptSelected?.sequentialView"
                class="landing-page__block-expiration-date-poll">
            <span class="landing-page__custom-description-poll"
                    [innerHTML]="landingPage.conceptSelected?.description"></span>
            <div class="landing-page__block-expiration-date-poll-init-custom">
                <i class="lf-icon-calendar-cross landing-page__icon-date-poll"></i>
                <span class="landing-page__text-date-pull">
                  <span class="landing-page__expiration-period-text"
                    [afTranslate]="'expanded-menu.expiration-period'"></span>
                  <span [ngShow]="landingPage.conceptSelected?.pollExpirationDate">{{landingPage.conceptSelected?.pollExpirationDate | convertDate:'without-time'}}</span>
                  <span [ngShow]="!landingPage.conceptSelected?.pollExpirationDate"
                    [afTranslate]="'common.no-deadline'"></span>
                </span>

            </div>
        </div>
        <div class="landing-page__container landing-page__container--custom"
                [ngClass]="{'landing-page__container--public':public,
            'landing-page__container--sequential':landingPage.conceptSelected?.sequentialView,
            'landing-page__container--custom-task': landingPage.task}">
            <div class="landing-page__block-body landing-page__block-body--custom landing-page__block-body--custom-task"
                    [ngClass]="{'landing-page__block-body--poll-no-public': landingPage.concept?.poll && !public}"
                    *ngIf="landingPage.showTask">
                <task-edit-custom *ngIf="landingPage.task?.taskId"
                            [taskSelect]="landingPage.task"
                            [concept]="landingPage.conceptTask">
                </task-edit-custom>
                <div *ngIf="landingPage.conceptSelected">
                    <div [ngShow]="!landingPage.showConceptUserProfile">
                      <concept-form-custom *ngIf="!landingPage.conceptSelected.sequentialView"
                                      class="concept-form__block-concept"
                                      [concept]="landingPage.conceptSelected"
                                      [public]="public"
                                      [published]="concept.publicShared"
                                      [evaluationId]="landingPage.dataHeader?.evaluationId"
                                      [footerConceptPublic]="landingPage.footerConceptPublic"
                                      [formalitiesList]="landingPage.concept?.list"
                                      [disabledSave]="landingPage.disabledButtonsHeader"
                                      [isMonitoring]="isMonitoring"
                                      (launchAction)="launchAction($event)">
                      </concept-form-custom>
                      <concept-form-sequential *ngIf="landingPage.conceptSelected.sequentialView"
                                    class="concept-form__block-concept"
                                    [concept]="landingPage.conceptSelected"
                                    [public]="public"
                                    [evaluationId]="landingPage.dataHeader?.evaluationId"
                                    [footerConceptPublic]="landingPage.footerConceptPublic"
                                    [formalitiesList]="landingPage.concept?.list"
                                    [disabledSave]="landingPage.disabledButtonsHeader"
                                    (launchAction)="launchAction($event)">
                    </concept-form-sequential>
                    </div>

                    <concept-form-custom *ngIf="landingPage.conceptUserProfile && landingPage.showConceptUserProfile"
                                    class="concept-form__block-concept"
                                    [concept]="landingPage.conceptUserProfile"
                                    [public]="public"
                                    [published]="concept.publicShared"
                                    [evaluationId]="landingPage.dataHeader?.evaluationId"
                                    [footerConceptPublic]="landingPage.footerConceptPublic"
                                    (launchAction)="launchAction($event)">
                    </concept-form-custom>
                </div>
            </div>
        </div>
    </div>
    <div class="preview-customization__body preview-customization__body--custom-procedure"
            *ngIf="landingPage.showProcedure && !landingPage.conceptSelected?.sequentialView">
        <div class="landing-page__block-body landing-page__block-body--procedure">
            <div class="landing-page__header-block-custom"
                [ngClass]="{'landing-page__header-block-custom--open': landingPage.search}">
                <i class="lf-icon-collection landing-page__icon-procedures-custom"></i>
                <span class="common__ellipsis landing-page__title-procedures-custom"
                    [ngClass]="{'landing-page__title-procedures-custom--open': landingPage.search,
                        'landing-page__title-procedures-custom--icam': landingPage.icamConceptConsult}">{{landingPage.proceduresTextTittle}}</span>
                <div class="landing-page__block-header-right"
                    [ngClass]="{'landing-page__block-header-right--open': landingPage.search}">
                    <div class="landing-page__block-search"
                        [ngClass]="landingPage.search?'landing-page__block-search--open':'landing-page__block-search--close'">
                        <input type="text"
                            [ngShow]="landingPage.search"
                            class="landing-page__search-input"
                            [(ngModel)]="landingPage.searchText"
                            placeholder="{{'common.search' | afTranslate}}"
                            [focusWhen]="landingPage.search">
                        <span class="landing-page__text-search"
                            [ngShow]="!landingPage.search"
                            (click)="toggleSearch()"
                            [afTranslate]="'common.search'">_Buscar</span>
                        <button class="lf-icon-search landing-page__icon-button-search"
                            [ngClass]="{'lf-icon-search': !landingPage.search,
                                'lf-icon-close-search': landingPage.search,
                                'landing-page__icon-button-search--open': landingPage.search}"
                            (click)="toggleSearch()">
                        </button>
                    </div>
                    <button class="lf-icon-add-round common__header-button-add-new-version landing-page__button-add-list"
                            [ngShow]="!landingPage.search && landingPage.icamConceptConsult"
                            (click)="newConcept()"
                            matTooltip="{{'landing-page.new-query' | afTranslate}}"
                            matTooltipPosition="left"
                            matTooltipClass="left"></button>
                </div>
            </div>
            <div class="landing-page__table-config-custom">
                <concept-list-landing *ngIf="landingPage.concept?.list.length"
                    [conceptList]="landingPage.concept?.list"
                    [searchText]="landingPage.searchText"
                    [showList]="landingPage.showProcedure">
                </concept-list-landing>
                <div class="folder__no-concept-block"
                        [ngShow]="!landingPage.concept?.list.length && !landingPage.concept?.archived.length">
                    <p class="folder__no-concept-text"
                        [afTranslate]="landingPage.icamConceptConsult?'landing-page.no-registered-queries':'folder.no-evaluated-concepts'">
                        No hay conceptos evaluados
                    </p>
                </div>
            </div>
        </div>
        <div class="landing-page__block-button-exit-custom"
                [ngShow]="!landingPage.hiddenButtonBackProcedure">
            <button type="submit"
                class="common__button-landing-custom common__button-landing-custom--send landing-page__custom-buttons-behind"
                [ngStyle]="{'background-color': landingPage.custom.color, 'color': landingPage.custom.textColor}"
                (click)="goBack()"
                [afTranslate]="'common.behind'">
                Atrás
            </button>
        </div>
    </div>
    <customize-footer [configureOptions]="landingPage.custom">
    </customize-footer>
</div>
