import { Component } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'login-be-access',
  templateUrl: './login-be-access.component.html'
})
export class LoginBeAccessComponent{
  loginBE: any = {
    accessToken: '',
    redirect: window.location.href.replace('/BE_EZAccess', '/dashboard?access_token='),
    loginUrl: this.globalCfg.ssoLoginUrl + '&redirect=' + encodeURIComponent(window.location.href.replace('/BE_EZAccess', '/dashboard'))
  };
  constructor(private globalCfg: GlobalCfgFactory) { }
  
}
