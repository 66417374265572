import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { map } from 'lodash';

@Component({
  selector: 'share-document-modal',
  templateUrl: './share-document-modal.component.html'
})
export class ShareDocumentModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  type: any;
  signatureCertificate: any;
  shareDocument: any = {
    createText: false,
    showText: true,
    wizardState: 1,
    typeShareDocumentSelected: '0',
    // showTiny: false,
    showAnswerSurvey: false,
    showInfoError: false,
    userLogin: this.context.user,
    shareDocumentationListSelected: '',
    showGroup: '',
    infoShare: '',
    phoneNumberSMS: '',
    isReportFinished: false,
    icon: "share",
    buttonSend: this.translate.instant('common.send'),
    buttonCancel: this.translate.instant('common.clean'),
    textAnswerSurvey: this.translate.instant('common.answer-survey'),
    title: '',
    valueId:null,
    tinyMceOptions: {
      noEditable: false,
      toolbar: ' undo redo | blocks | bold italic underline  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link',
      height: '256px',
      placeholder: this.translate.instant('common.add-comment')
    },
    shareTypeDocumentField: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('share-document.type-share-label')
    },
    shareDocumentationField: {
      label: this.translate.instant('share-document.restricted-select'),
      multiple: true,
      required: true,

    }
  }
  constructor(private activeModal: NgbActiveModal,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private broadcastService: BroadcastService) { }

  @Input() configuration: any = {};

  ngOnInit(): void {
    this.shareDocument.type = this.configuration.type;
    this.shareDocument.configuration = this.configuration;
    this.shareDocument.sharedContacts = {
      typeConfiguration: this.configuration.type,
      fieldType: 'multiple',
      addContacts: this.configuration.addContacts,
      required: this.shareDocument.type === 'shareFieldContainer',
      typeCall: this.configureCallContacts(),
      selectContacts: [],
      label: '',
      parentId: this.shareDocument.configuration.fieldContainer?.parentId?this.shareDocument.configuration.fieldContainer?.parentId:null,
      callEvaluationTaskShares: this.shareDocument.type === 'shareFieldContainer'?true:false,
    }
    this.configuration.document?.reportObjectId? this.getEvaluation() : this.shareDocument.isReportFinished = true;
    this.signatureCertificate = this.shareDocument.type=== 'signature' || this.shareDocument.type=== 'send-mail-certificate' || this.shareDocument.type=== 'send-sms-certificate';
    this.shareDocument.showAnswerSurvey =(this.shareDocument.type=== 'sharePoll' || this.signatureCertificate);
    // if(!this.signatureCertificate){
    //     this.initTinyMce();
    // }
    this.configureType();
  }

  configureType(){
    switch(this.shareDocument.type){
        case 'share':
        case 'share-some':
            this.shareDocument.title = this.translate.instant('share-document.share-document');
            break;
        case 'shareReport':
        case 'shareGlobalReport':
            this.shareDocument.title = this.translate.instant('share-document.share-report');
            break;
        case 'concept':
            this.shareDocument.title = this.translate.instant('common.share-concept');
            break;
        case 'sharePoll':
            this.shareDocument.title = this.translate.instant('common.share-poll');
            break;
        case 'signature':
            this.shareDocument.title = this.translate.instant('share-document.send-to-signature');
            this.shareDocument.icon="signature";
            this.shareDocument.textAnswerSurvey = this.translate.instant('share-document.sign-documentation');
            this.shareDocument.infoShare = this.translate.instant('share-document.share-info');
            this.shareDocument.buttonSend = this.translate.instant('common.continue');
            this.shareDocument.buttonCancel = this.translate.instant('common.cancel');
            break;
        case 'send-mail-certificate':
            this.shareDocument.title = this.translate.instant('expanded-menu.send-mail-certificate');
            this.shareDocument.icon="certified-mail";
            this.shareDocument.textAnswerSurvey = this.translate.instant('share-document.receive-mail');
            this.shareDocument.infoShare = this.translate.instant('share-document.share-info-mail');
            this.shareDocument.buttonSend = this.translate.instant('common.continue');
            this.shareDocument.buttonCancel = this.translate.instant('common.cancel');
            break;
        case 'send-sms-certificate':
            this.shareDocument.title = this.translate.instant('expanded-menu.send-sms-certificate');
            this.shareDocument.icon="certified-sms";
            this.shareDocument.textAnswerSurvey = this.translate.instant('share-document.receive-sms');
            this.shareDocument.infoShare = this.translate.instant('share-document.share-info-sms');
            this.shareDocument.buttonSend = this.translate.instant('common.continue');
            this.shareDocument.buttonCancel = this.translate.instant('common.cancel');
            break;
        case 'shareFieldContainer':
            this.shareDocument.title = this.translate.instant('share-document.share-fieldcontainer');
            this.shareDocument.infoShare = this.translate.instant('share-document.share-fieldcontainer-info');
            this.configureShareFieldContainer()
            break;
        default:
            this.shareDocument.title = this.translate.instant('share-document.send-to-signature');
            break;
    }
  }

  configureCallContacts(){
    switch(this.shareDocument.type){
        case 'signature':
        case 'send-sms-certificate':
            return 'contacts';
        default:
        // case 'concept':
        // case 'share':
        // case 'shareReport':
        // case 'sharePoll':
        // case 'shareFieldContainer':
        // case 'send-mail-certificate':
        // case 'share-some':
        // case 'shareGlobalReport':
            return 'groups';
    }
  }

  getEvaluation(){
    this.apiService.get('reportobjects/getevaluationid', this.configuration.document.reportObjectId)
        .then((data:any)=>{
            this.shareDocument.valueId = data;
            this.shareDocument.isReportFinished = true;
        }, (errorData:any)=>{

        })
  }

  receiveContacts(e:any){
    this.shareDocument.sharedContacts.selectContacts = e.contacts;
    this.shareDocument.sharedContacts.error = false;
  }

  contactsClosed() {
    this.shareDocument.showText = !this.shareDocument.showText;
  }

  resetItems(){
    if(this.shareDocument.type === 'signature' || this.shareDocument.type === 'send-mail-certificate' || this.shareDocument.type === 'send-sms-certificate'){
        this.handleDismiss();
    }else{
      this.shareDocument.message = '';
      this.broadcastService.broadcast('resetFieldContact', {});
    }
  }

  changeSurvey(newValue:boolean){
    this.shareDocument.answerSurvey = newValue;
    this.shareDocument.errorAnswerSurvey = false;
    this.shareDocument.showInfoError = false;
  }

  checkOptions(){
    if(this.shareDocument.type === 'shareFieldContainer'){
      this.shareDocument.shareDocumentationField.error = false;
      if(this.shareDocument.typeShareDocumentSelected !== '2' || (this.shareDocument.typeShareDocumentSelected === '2' && this.shareDocument.shareDocumentationListSelected !== '')){
        return true;
      }else{
        if(this.shareDocument.shareDocumentationListSelected === ''){
          this.shareDocument.shareDocumentationField.error = true;
        }
        return false;
      }
    }else if(this.shareDocument.type === 'send-sms-certificate' && this.shareDocument.answerSurvey){
        this.shareDocument.phoneNumberSMS = this.commonUtilsService.checkMobilePhone(this.shareDocument.userLogin, 'phoneNumber1', 'phoneNumber2');
        if(this.shareDocument.sharedContacts.selectContacts.length && this.shareDocument.phoneNumberSMS !== ''){
            return true;
        }
        if(this.shareDocument.phoneNumberSMS === ''){
          this.shareDocument.showInfoError = true;
          this.shareDocument.errorAnswerSurvey = true;
        }
    }else if(this.shareDocument.sharedContacts.selectContacts.length){
        return true;
    }
    if(!this.shareDocument.sharedContacts.selectContacts.length){
        this.shareDocument.sharedContacts.error = true;
    }
    return false;
  }

  getdestinations(){
    if(this.signatureCertificate && this.shareDocument.answerSurvey){
        let userOwner = {
            email: this.shareDocument.userLogin.email,
            phoneNumberSMS: this.shareDocument.phoneNumberSMS
        }
        this.shareDocument.sharedContacts.selectContacts.push(userOwner);
    }
    this.shareDocument.sharedContacts.selectContacts.forEach((destination:any) => {
      destination.comments = this.shareDocument.message
    });

    return  this.shareDocument.sharedContacts.selectContacts;
  }

  getConceptShares() {
    let shares:any = {
        comments:  this.shareDocument.message,
        persons: map( this.shareDocument.sharedContacts.selectContacts, this.mapDestination)
    };
    if(this.shareDocument.type ==='shareFieldContainer'){
      shares.documentsShareType = parseInt(this.shareDocument.typeShareDocumentSelected);
      if(shares.documentsShareType === 2){
        shares.documentObjects = this.extractDocumentObjects()
      }
    }
    return shares;
  }

  mapDestination(destination:any) {
    let mapped:any = {};
    if (destination.groupId) {
      mapped.groupId = destination.groupId;
    } else if (destination.userId){
      mapped.userId = destination.userId;
    } else if (destination.contactId){
      mapped.contactId = destination.contactId;
    } else if (destination.productRoleId){
      mapped.productRoleId = destination.productRoleId;
    }
    return mapped;
  }

  getEditorValue(event:any){
    this.shareDocument.message = event.model;
  }

  configureShareFieldContainer(){
    this.shareDocument.wizardState = 1;
    this.shareDocument.steps = [{
        name:this.translate.instant('task-list.addressee'),
        noEditable:false
      },{
        name:this.translate.instant('common.documentation'),
        noEditable:false
    }]
    this.shareDocument.typeShareDocument = [
      {
        listValueId:'0',
        text: this.translate.instant('share-document.no-share-documents')
      },{
        listValueId:'1',
        text: this.translate.instant('share-document.share-all-documents')
      },{
        listValueId:'2',
        text: this.translate.instant('share-document.share-selected-documents')
      }
    ]
    this.apiService.get('documentobjects/allfilesids', this.configuration.concept.conceptObjectId)
      .then((data:any)=>{
        this.shareDocument.shareDocumentationList = this.commonUtilsService.editList(data, 'documentObjectId', 'name');
      })
  }
  nextStep(){
    if(this.shareDocument.sharedContacts.selectContacts.length){
      this.shareDocument.wizardState = 2;
      this.shareDocument.infoShare = this.translate.instant('share-document.share-info-document')
    }else{
      this.shareDocument.sharedContacts.error = true;
    }

  }

  backStep(){
     this.shareDocument.wizardState = 1;
     this.shareDocument.infoShare = this.translate.instant('share-document.share-fieldcontainer-info');
  }

  selectSharedDocumentation(e:any){
    this.shareDocument.shareDocumentationListSelected = e.newValue;
  }

  extractDocumentObjects(){
    let arrayDocuments:any = [];
    let documentsSelected: any = this.shareDocument.shareDocumentationListSelected.indexOf('|') === -1? [this.shareDocument.shareDocumentationListSelected] : this.shareDocument.shareDocumentationListSelected.split('|');
    documentsSelected.forEach((document:any)=>{
      arrayDocuments.push(parseInt(document))
    })
    return arrayDocuments;
  }

  handleAccept() {
    if(this.checkOptions()){
        this.activeModal.close({
          result: 'ok',
          share: this.shareDocument.type !== 'concept' && this.shareDocument.type !=='sharePoll' && this.shareDocument.type !=='shareFieldContainer' ? this.getdestinations() : this.getConceptShares(),
          answerSurvey: this.shareDocument.answerSurvey
        })
    }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
