import { Injectable } from '@angular/core';
import { ActivatedRoute, Resolve } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { OpenChatFactory } from '@shared/factories/open-chat/open-chat.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class OpenChatResolver implements Resolve<any> {
    constructor(private openChat:OpenChatFactory) { }
    resolve() {
     return this.openChat.launchScript()
    }
}
