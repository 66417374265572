
<div class="clients-list">
  <div class="clients-list__block-list-v3">
      <div class="common__header-new-version">
          <span class="common__header-title-new-version"
              [ngShow]="!clientsList.search"
              [afTranslate]="'common.clients'">_Clientes</span>
          <div class="clients-list__block-search-v3"
              [ngClass]="{'clients-list__block-search-v3--100' : clientsList.search}">
              <input type="text"
                  [ngShow]="clientsList.search"
                  class="common__search-input"
                  [(ngModel)]="clientsList.searchClient"
                  placeholder="{{'common.search' | afTranslate}}"
                  [focusWhen]="clientsList.search">
              <button class="lf-icon-search common__icon-button common__icon-button-search "
                  [ngClass]="{'lf-icon-search': !clientsList.search,
                      'lf-icon-close-search': clientsList.search,
                      'common__icon-button-search--open': clientsList.search}"
                  (click)="toggleSearch()">
              </button>
          </div>
          <button class="lf-icon-add-round common__header-button-add-new-version"
              [ngShow]="permissions?.canCreateClients && !clientsList.search"
              (click)="newClient()"
              [disabled]="!permissions?.canCreateClients"
              matTooltip="{{'new-client.new-organization' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </button>
          <button class="lf-icon-upload-download-folder common__header-button-add-new-version common__header-button-add-new-version--upload"
              [ngShow]="permissions?.canCreateClients && !clientsList.search"
              (click)="openMigrationModal()"
              [disabled]="!permissions?.canCreateClients"
              matTooltip="{{'expanded-menu.migration' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </button>
      </div>

      <div class="common__table-block">
          <div class="common__table-new-version">
              <div class="common__table-header">
                  <div class="clients-list__col-v2 clients-list__col-v2--name-v3 clients-list__col-v2--first clients-list__col-v2--top-2">
                      <p class="common__table-header-text common__cursor-pointer"
                          [afTranslate]="'common.name'"
                          (click)="orderTable()">_Nombre</p>
                      <i class="common__order-by"
                          [ngClass]="{'lf-icon-arrow-down':(clientsList.colFilter === 'name' && clientsList.order) || clientsList.colFilter !== 'name',
                              'lf-icon-arrow-up':clientsList.colFilter === 'name' && !clientsList.order,
                              'common__order-by--active': clientsList.colFilter === 'name'}"
                          (click)="orderTable()"></i>
                  </div>
                  <div class="clients-list__col-v2 clients-list__col-v2--external-id-v3">
                      <p class="common__table-header-text"
                          [afTranslate]="'clients-list.our-reference'">_Our reference</p>
                  </div>
                  <div class="clients-list__col-v2 clients-list__col-v2--cif-v3">
                      <p class="common__table-header-text"
                          [afTranslate]="'client-details.tax-references'">_Tax reference</p>
                  </div>
                  <div class="clients-list__col-v2 clients-list__col-v2--actions">
                      <p class="common__table-header-text common__table-header-text--right"
                          [afTranslate]="'common.actions'">Acciones</p>
                  </div>
              </div>
              <div class="common__table-body common__table-body--8-elements"
                  [ngClass]="{'common__table-body--more-elements': clientList?.length > 8}">
                  <div class="common__table-row common__cursor-pointer"
                      [ngClass]="{'common__table-row--selected':client.selectedClient}"
                      *ngFor="let client of clientList | byWord:clientsList.searchClient:['name', 'cif'] | afOrderBy:clientsList.colFilter:clientsList.order"
                      (click)="selectClient(client)">
                      <div class="clients-list__col-v2 clients-list__col-v2--name-v3 clients-list__col-v2--first clients-list__col-v2--tooltip">
                          <parragraph-tooltip [text]="client.name"></parragraph-tooltip>
                      </div>
                      <div class="clients-list__col-v2 clients-list__col-v2--external-id-v3 clients-list__col-v2--tooltip">
                          <parragraph-tooltip [text]="client.reference2"></parragraph-tooltip>
                      </div>
                      <div class="clients-list__col-v2 clients-list__col-v2--cif-v3 clients-list__col-v2--tooltip">
                          <parragraph-tooltip [text]="client.cif"></parragraph-tooltip>
                      </div>
                      <div class="clients-list__col-v2 clients-list__col-v2--actions-body">
                          <ul class="common__options-row">
                              <li class="clients-list__menu-options-row-v3"
                                  (click)="goToProcesses(client, $event)">
                                  <span class="clients-list__text-to-options"
                                    [afTranslate]="client.evaluation?.evaluationId?'clients-list.go-to-processes':'clients-list.generate-pocess-database'">Go to processes</span>
                                  <i class="lf-icon-play"></i>
                              </li>
                          </ul>
                          <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="common__block-footer-table">
          <div class="common__block-footer-total">
              <span [afTranslate]="'common.total'">_Total</span>
              <span class="common__total-number-element notranslate">{{clientList?.length}}</span>
          </div>
      </div>
  </div>
</div>
