import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-money',
  templateUrl: './customize-field-money.component.html'
})
export class CustomizeFieldMoneyComponent implements OnChanges, OnInit  {
  skin: string = this.globalCfg.skin;
  customizeFieldMoney: any ={
    tinyMceOptions: {},
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    },
    moneyTypeInfo: {
      label: this.translate.instant('customize.coin')
    },
    defaultValueInfo: {
        id:"default-value",
        fieldTypeId:"money",
        label: this.translate.instant('customize.default-value')
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;
  @Output() changeInField = new EventEmitter();

  ngOnInit(){
    let that: any = this.customizeFieldMoney;
    if(this.concept?.poll && this.concept?.disabled){
      that.contentReportInfo.noEditable = this.concept.disabled;
      that.moneyTypeInfo.noEditable = this.concept.disabled;
      that.defaultValueInfo.noEditable = this.concept.disabled;
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
    this.getCurrencies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldMoney;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      that.fieldCopy = cloneDeep(that.field);
    }
  }

  getCurrencies(){
    this.apiService.get('currencies')
        .then((data:any)=>{
            this.customizeFieldMoney.moneyTypeList = this.commonUtilsService.editList(data, 'currencyId', 'name')
        }, (errorData:any)=>{})
  }

  getEditorValue(event:any){
    this.customizeFieldMoney.field.description = event.model;
  }

  itemSelected(e:any, item:any){
      this.customizeFieldMoney.field[item] = e.newValue;
      this.modifyField()
  }

  cancelFieldMoney(){
    this.customizeFieldMoney.field = cloneDeep(this.customizeFieldMoney.fieldCopy);
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldMoney.field
          }
      })
  }

  saveFieldMoney(){
    this.customizeFieldMoney.fieldCopy = cloneDeep(this.customizeFieldMoney.field);
    this.changeInField.emit({
        change:{
            type: 'save',
            field: this.customizeFieldMoney.field
        }
    })
  }

  openVisibility(e:any){
    this.customizeFieldMoney.field.visibilityOr = e.visibilityOr;
    this.customizeFieldMoney.field.openBlock = e.openBlock;
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldMoney.field);
  }
  changeOptionOn(e:any, type:string){
    switch(type){
      case 'private':
        this.customizeFieldMoney.field.private = e.model;
        break;
      case 'required':
        this.customizeFieldMoney.field.required = e.model;
        break;
      default:
        this.customizeFieldMoney.field[type] = e.model;
        break;
    }
    this.modifyField();
  }
}
