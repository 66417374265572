
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { SubcontainerMultipleParentService } from '../utils/subcontainer-multiple-parent.service';


@Component({
  selector: 'subcontainer-multiple-default',
  templateUrl: './subcontainer-multiple-default.component.html'
})
export class SubcontainerMultipleDefaultComponent implements OnInit, OnChanges, OnDestroy {
  isLanding: boolean = false;
  to: string = this.route.snapshot.params['to'];
  userPermissions: any = this.permissions.getPermissions();
  subcontainerMultiple: any = {
    toFront: this.to !== 'form-process' && this.to !== 'template' && this.to !== 'process-back',
    newEvaluatedFields: [],
    evaluated: [],
    numOfCopies: [{
            id: 1,
            value:'01'
        },{
            id: 2,
            value:'02'
        },{
            id: 3,
            value:'03'
        },{
            id: 4,
            value:'04'
        },{
            id: 5,
            value:'05'
        },{
            id: 6,
            value:'06'
        },{
            id: 7,
            value:'07'
        },{
            id: 8,
            value:'08'
        },{
            id: 9,
            value:'09'
        },{
            id: 10,
            value:'10'
        }],
    selectCopies: {
        id: 1,
        value:'01'
    }
  }
  constructor(private route: ActivatedRoute,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private permissions: UserPermisionsFactory,
    private subcontainerMultipleParent: SubcontainerMultipleParentService) {}

  @Input() subContainer: any = null;
  @Input() subContainerMultiple: any = null;
  @Input() subcontainerDuplicated: any = null;
  @Input() concept: any = null;
  @Input() conceptLocked: any = null;
  @Input() evaluationProperties: any = null;
  @Input() isPrivate: any = null;
  @Input() itsSaveConcept: any = null;
  @Input() evaluatedMap: any = null;
  @Input() viewMode: boolean = false;
  @Output() evaluatedConceptChange = new EventEmitter();
  @Output() saveBeforeCreate = new EventEmitter();
  @Output() clearSaveConcept = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.subcontainerMultipleParent.onInit(this)
  }

  ngOnChanges(): void {
    this.subcontainerMultipleParent.onChanges(this);
  }

  duplicateSubcontainer(){
    this.subcontainerMultipleParent.duplicateSubcontainer(this)
  }

  deleteDuplicated(subContainer:any) {
    this.subcontainerMultipleParent.deleteDuplicated(subContainer, this)
  }

  changesInEvaluatedSubContainer(field:any){
    this.subcontainerMultipleParent.changesInEvaluatedSubContainer(field, this)
  }

  shareFieldContainer(subContainer:any){
    this.launchAction.emit({action: 'shareFieldContainer', fieldContainer: subContainer});
  }

  openRelatedInfo(relatedInfo:any){
    this.modalService.relatedInformationModal(relatedInfo)
  }

  ShowTooltip(id:any){
    return this.commonUtilsService.showTooltip(id)
  }

  ngOnDestroy(){
    this.subcontainerMultipleParent.ngOnDestroy();
  }
}
