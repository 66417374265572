import { Injectable } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  constructor(private context: ContextFactory){
  }
  datesDay: any = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
  }

  toIso(newDate: any, milliseconds: any=null){
    let milliSecondsDate = milliseconds?milliseconds.toString():(newDate.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
    return newDate.getFullYear() +
        '-' + this.pad(newDate.getMonth() + 1) +
        '-' + this.pad(newDate.getDate()) +
        'T' + this.pad(newDate.getHours()) +
        ':' + this.pad(newDate.getMinutes()) +
        ':' + this.pad(newDate.getSeconds()) +
        '.' + milliSecondsDate +
        'Z';
  }

  pad(number:number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  toDate(newDate: string): any{
    if(newDate){
        return new Date(newDate.replace('T', ' '))
    }
  }

  resetDateLastSecond(newDate: any){
    return newDate.getFullYear() +
        '-' + this.pad(newDate.getMonth() + 1) +
        '-' + this.pad(newDate.getDate()) +
        'T' + '23' +
        ':' + '59' +
        ':' + '59' +
        '.' + (newDate.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z';
  }

  resetDateFirstSecond(newDate: any){
    return newDate.getFullYear() +
        '-' + this.pad(newDate.getMonth() + 1) +
        '-' + this.pad(newDate.getDate()) +
        'T00:00:00.000Z';
  }

  compareDates(oldDate: any){
    return moment().diff(moment(oldDate.substring(0, oldDate.indexOf('T'))).valueOf(), 'days');
  }

  compareFutureDates(futureDate: any){
    return moment(futureDate.substring(0, futureDate.indexOf('T'))).diff(moment(), 'days');
  }

  compareHours(oldDate: any){
    let diffTime = moment.duration(moment().diff(oldDate));
    return diffTime.days() * 24 + diffTime.hours();
  }

  compareDatesNoIso(oldDate: any){
    return moment().diff(moment(oldDate).valueOf(), 'days');
  }

  todayDate(selectedDate: any): boolean{
    let diff = Object.prototype.toString.call(selectedDate) !== '[object Date]'? this.compareDates(selectedDate) : this.compareDatesNoIso(selectedDate);
    return diff > 0? false : true;
  }

  setTimezone(selectedDate:any){
    return selectedDate.getTimezoneOffset() < 0? moment(selectedDate.setMinutes(Math.abs(selectedDate.getTimezoneOffset()) + selectedDate.getMinutes())).toISOString() : moment(selectedDate).toISOString();
  }

  compareValidateDates(dateSelected: any, hourSelected: any){
    let dateNew = new Date();
    let hourTime = '23:59:00';
    if(hourSelected){
        hourTime = hourSelected;
    }
    let newDateSelected = dateSelected.substr(0,dateSelected.indexOf('T')) + 'T' + hourTime +'.000Z';
    let dateToday = dateNew.getTimezoneOffset() < 0? moment(dateNew.setMinutes(Math.abs(dateNew.getTimezoneOffset()) + dateNew.getMinutes())).toISOString() : moment(dateNew).toISOString();
    return newDateSelected > dateToday;
  }

  convertDate(item:any, toReturn:any=null): any{
    let language: any = sessionStorage.getItem('selectLanguage');
    if(moment.locale() !== language){
      moment.updateLocale(language.split('-')[0], {
          week: {
              dow: 1
          }
      });
    }

    if(item){
      switch (toReturn){
        case 'day-month':
          return moment.utc(item).format('DD MMM').replace('.','').replace(/\b\w/g, function(l){ return l.toUpperCase() }) //23 Dic
        case 'all-month-short':
          return moment.utc(item).format('DD MMM, YYYY').replace('.','').replace(/\b\w/g, function(l){ return l.toUpperCase() }) //23 Dic, 2016
        case 'calendar':
          return moment(item).format('DD [ de ]') + moment.utc(item).format('MMMM').replace(/\b\w/g, function (l) { return l.toUpperCase() }) + moment.utc(item).format('[ a las ] HH:mm'); //23 de Diciembre a las 00:00
        case 'calendar-year':
          return moment(item).format('LL');
        case 'calendar-all':
          return moment(item).format('DD [ de ]') + moment.utc(item).format('MMMM').replace(/\b\w/g, function (l) { return l.toUpperCase() }) + moment.utc(item).format('[ de ] YYYY') + moment.utc(item).format('[ a las ] HH:mm'); //23 de Diciembre de 2016 a las 00:00
        case 'without-time':
          return moment.utc(item).format('DD/MM/YYYY') // 23/12/2016
        case 'only-time':
          return moment.utc(item).format('HH:mm:ss') // 13:23:41
        case 'full-date':
          return moment.utc(item+1).format('MMMM YYYY') ;
        case 'month-year':
          return moment.utc(item).format('MMMM YYYY') ;
        case 'only-month':
          return moment(item).format('MMMM');
        case 'only-month-short':
          return moment(item+'/01/2022').format('MMM');
        case 'be-special':
            return moment(item).format('DD/MM/YYYY');
        case 'be-calendar':
            return moment(item).format('MMM. DD, YYYY');
        case 'all-with-second':
            return moment.utc(item).format('DD/MM/YYYY HH:mm:ss');
        case 'all-with-second-db':
            return moment.utc(item).format('YYYYMMDD_HHmmss');
        default:
          return moment.utc(item).format('DD/MM/YYYY HH:mm') // 23/12/2016 00:00
      }
    }
  }

}

