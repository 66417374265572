import { Injectable } from '@angular/core';


import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';


@Injectable({
  providedIn: 'root'
})

export class GenerateDocumentParentService {

  constructor(private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private validateDocumentService: ValidateDocumentService) { }

  onInit(child:any){
    let that: any = child.generateDocument;
    if(child.predefinedTemplate){
      that.document.properties = child.predefinedTemplate;
      that.document.templateName = child.predefinedTemplate.name;
      that.document.name = child.predefinedTemplate.predefinedName;
      let text = child.predefinedTemplate.from === 'fieldTemplate'? 'generate-document.export-form-data-from-field' : 'generate-document.export-form-data';
      that.textInfoTemplate =  this.translate.instant(text).replace('{template}',  that.document.templateName);
    }
  }

  checkNameOther(child:any){
      this.apiService.add('documentobjects/existsdocinassociatedeval/'+child.concept.conceptObjectId, {name: child.generateDocument.document.name})
          .then((data:any)=>{
              if(!data){
                this.validateName(child)
              }else{
                child.generateDocument.errorText = this.translate.instant("generate-document.exist-in-other");
                child.generateDocument.documentName.error = true;
                child.generateDocument.errorDocument = true;
              }
          }, (errorData:any)=>{})
  }

  validateName(child:any){
      let textValidate = this.validateUploadFiles(child.generateDocument.document.name +'.'+ child.predefinedTemplate.extension.toLowerCase(), child.concept.documentation.documents, child);
      if(textValidate === ''){
          child.generateDocument.documentName.error = false;
          child.generateDocument.errorDocument = false;
          this.close(child)
      }else{
          child.generateDocument.errorText = this.translate.instant(textValidate);
          child.generateDocument.documentName.error = true;
          child.generateDocument.errorDocument = true;
      }
  }

  validateUploadFiles(document:any, list:any, child:any){
      if(!(this.validateDocumentService.correctCharacters(document))){
          return 'rename-modal.invalid-document';
      }
      if(!(this.validateDocumentService.correctDocumentFormat(document))){
          return 'documentation-list.format-not-accepted';
      }
      if(this.validateDocumentService.duplicatedDocuments(document, list)){
          return 'documentation-list.duplicated-name-document';
      }
      if(!(this.validateDocumentService.nameLength(document))){
          return 'up-document.error-name';
      }
      return '';
  }

  close(child:any){
      child.activeModal.close({
          result: 'ok',
          name:  child.generateDocument.document.name+'.'+(child.generateDocument.document.properties.extension.toLowerCase()),
          url: 'documentobjects/fromComplianceDocumentTemplate/'+child.concept.conceptObjectId+'/'+child.generateDocument.document.properties.id
      })
  }

  handleDismiss(child:any) {
      child.activeModal.close({result: 'cancel'})
  };

  handleAccept(child:any) {
      if(child.generateDocument.document.name !== ''){
          child.concept.productId === 'forms'? this.checkNameOther(child) : this.validateName(child);
      }else{
          child.generateDocument.documentName.error = true;
      }
  };
}
