import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class OfficeOnlineResolver implements Resolve<any> {
    user: any = this.context.user?this.context.user.login:null;
    constructor(private apiService: ApiServiceService,
        private context: ContextFactory) { }
    resolve(route: ActivatedRouteSnapshot) {
        let id: any = route.params['id'];
        let type: any = route.params['type'];
        let typeUrl: any = this.typeUrl(type);
        let url = 'utils/oos/url?'+typeUrl+'='+ id;
        return this.apiService.get(url)
          .then((data:any) => {
              return data;
          }, (error:any) => {
          });
    }

    typeUrl(type: any){
      switch(type){
        case 'report-document':
          return 'reportDocumentId';
        case 'template':
          return 'documentId';
        default:
          return 'documentObjectId';
      }
    }

}
