<div class="'evaluation-share'">
  <div class="common__modal-header-block">
      <i class="lf-icon-notification-new common__icon-header"></i>
      <span class="common__text-header"
        [afTranslate]="'evaluation-share.dialog-title'"></span>
      <i class="lf-icon-close common__icon-close-modal"
        (click)="cancel()"></i>
  </div>
  <div class="evaluation-share__wizard-steps">
      <wizard-steps [steps]="evaluationShare.steps"
        [state]="evaluationShare.stepState"></wizard-steps>
  </div>
  <div class="evaluation-share__content"
    [ngShow]="evaluationShare.stepState === 1">
          <div class="evaluation-share__contacts">
            <field-type-contacts-multiple
                *ngIf="evaluationShare.contactsField"
                [field]="evaluationShare.contactsField"
                [valueId]="evaluation.evaluationId"
                (sendContacts)="getSelectContacts($event)"
                (contactsClosed)="evaluationShare.contactsOpen = !evaluationShare.contactsOpen">
            </field-type-contacts-multiple>
          </div>
          <div [ngHide]="evaluationShare.contactsOpen">
              <rich-editor class="common__input-textarea-special"
                  [tinyMceOptions]="evaluationShare.tinyMceOptions"
                  [modelText]="evaluationShare.comments"
                  (emitValue)="evaluationShare.comments = $event.model">
              </rich-editor>
          </div>
          <div class="evaluation-share__switch-documents">
              <field-type-switch [label]="'evaluation-share.share-documents'|afTranslate"
                                  [checked]="evaluationShare.shareDocuments"
                                  [disabled]="evaluationShare.hasConfidential"
                                  (callback)="evaluationShare.shareDocuments = $event.value"></field-type-switch>
          </div>
          <advice-message [ngShow]="evaluationShare.hasConfidential && evaluationShare.showDocumentsWarning"
              [icon]="'warning'"
              [type]="'warning'"
              [text]="evaluationShare.confidentialWarning"
              (closeAdvice)="evaluationShare.showDocumentsWarning = false"></advice-message>

  </div>
  <div [ngShow]="evaluationShare.stepState === 2"
       class="evaluation-share__content-documents">
      <div class="evaluation-share__documents">
          <upload-documents
              [type]="'evaluationShare'"
              [files]="evaluationShare.documents"
              [documentationList]="evaluationShare.documentationList"
              [component]="evaluationShare.uploadComponent"
              (uploadDocumentsEmit)="uploadDocumentsOn($event)">
          </upload-documents>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal"
    [ngHide]="evaluationShare.contactsOpen">
      <button type="reset" class="common__button common__button--clean"
              [afTranslate]="'common.cancel'"
              (click)="cancel()"></button>
      <button type="reset" class="common__button common__button--clean"
              [ngShow]="evaluationShare.stepState === 2"
              [afTranslate]="'common.previous'"
              (click)="evaluationShare.stepState = 1"></button>
      <button type="reset" class="common__button common__button--active"
              [afTranslate]="'common.finish'"
              (click)="finishOption()"></button>
      <button type="submit" class="common__button common__button--active"
              [ngShow]="evaluationShare.stepState === 1"
              (click)="goNext()"
              [afTranslate]="'common.attach'"></button>
  </div>
</div>
