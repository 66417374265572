import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'field-type-file',
  templateUrl: './field-type-file.component.html'
})
export class FieldTypeFileComponent implements OnInit, OnChanges, OnDestroy {
  subscribers: any = {};
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  fieldTypeFile: any = {
    references: false
  }
  constructor(private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private documentActions: DocumentActionsService) { }
  @Input() field: any = null;
  @Input() concept: any = {};
  @Input() preSelectItem: any = null;
  @Input() conceptListStates: any = null;

  @Output() itemSelected = new EventEmitter();
  @Output() showFieldReference = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
    this.watchFieldDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['field'] && changes['field'].currentValue){
      let field:any = changes['field'].currentValue;

      this.fieldTypeFile.titleField = field.extendedLabel && field.extendedLabel !== ''? field.extendedLabel : field.label

      if(!field.id || field.generateId){
          field.id = this.commonUtilsService.createId();
      }else if(field.fieldId){
          field.id = field.fieldId;
      }
      this.fieldTypeFile.field = field;
      if(field.inReport !== null && field.inReport !== undefined){
        this.fieldTypeFile.inReport = field.inReport.indexOf('|') === -1? [field.inReport] : field.inReport.split('|')
      }
    }
    if(changes['preSelectItem'] && changes['preSelectItem'].currentValue){
      let preSelectItem: any = changes['preSelectItem'].currentValue;
      this.fieldTypeFile.fileName = preSelectItem === null? '' : preSelectItem;
    }
    if(changes['conceptListStates'] && changes['conceptListStates'].currentValue){
      this.fieldTypeFile.conceptListStates = changes['conceptListStates'].currentValue
    }
    if(this.concept){
      this.fieldTypeFile.lockedSavedConcept = Boolean((this.field.lockedEdit || this.concept.lockedEdit) && (this.concept?.saved))
    }
  }

  watchFieldDocuments(){
    this.subscribers.changeListFieldDocumentsBroadcast = this.broadcastService.subscribe('changeListFieldDocuments', (data:any)=>{
      let field: any = this.fieldTypeFile.field;
      let concept: any = this.concept;
        if(data.fieldId === field.fieldId){
            for (let i = 0; i < field.evaluatedValue.documents.length; i++) {
                if(field.evaluatedValue.documents[i].name === data.document.name){
                    field.evaluatedValue.documents[i] = data.document;
                }
            }
            for (let j = 0; j < concept.documentation.documents.length; j++) {
                if(concept.documentation.documents[j].name === data.document.name){
                    concept.documentation.documents[j] = data.document;
                }
            }
        }
    })
  }

  addDocuments(){
      let field:any = this.fieldTypeFile.field;
      let concept:any = this.concept;
      let type:string  = !this.concept.conceptObjectId?'addNewDocumentsToFieldsInNewInstance' :'addNewDocumentsToFields'
      field.newAddFiles = [];
      field.deletedFile = {};
      this.modalService.uploadDocuments(type, [], concept.documentation.documents, field, this.fieldTypeFile.conceptListStates).subscribe((result:any)=>{
        if(result.result==='ok'){
          if(result.failed.length){
            this.filesNoUploaded(result.failed);
          }
          if(type === 'addNewDocumentsToFields'){
              result.files.forEach((file:any) => {
                file.sourceName = this.translate.instant('field-type-file.new-file-general');
                if(result.stateId){
                    file.stateProcess = result.nameState;
                    file.state = {
                        name: result.nameState,
                        stateId: result.stateId
                    }
                }
                let documentValue : any = {
                    id: file.documentObjectId,
                    value: file.name
                }
                field.evaluatedValue.displayData.push(documentValue);
              });

          }else{
              let newDocumentsInField : any = {
                  documents: result.files,
                  fieldId: field.fieldId
              }
              result.files.forEach((file:any) => {
                file.status = '';
                file.documentObjectId = this.commonUtilsService.createId();
                file.canBeDeleted = true;
                file.modified = new Date();
                file.fileSize = file.size;
              })

              if(concept.fieldsWidthDocuments && concept.fieldsWidthDocuments.length){
                  let countDocuments : any = 0;
                  for(let j = 0; j < concept.fieldsWidthDocuments.length; j++){
                      let fieldDocument : any = concept.fieldsWidthDocuments[j];
                      countDocuments++;
                      if(fieldDocument.fieldId === field.fieldId){
                          fieldDocument.documents = fieldDocument.documents.concat(result.files);
                          break;
                      }else if(countDocuments === concept.fieldsWidthDocuments.length){
                          concept.fieldsWidthDocuments.push(newDocumentsInField);
                          break;
                      }
                  }
              }else{
                  if(!concept.fieldsWidthDocuments){
                      concept.fieldsWidthDocuments = [];
                  }
                  concept.fieldsWidthDocuments = concept.fieldsWidthDocuments.concat(newDocumentsInField);
              }
          }
          concept.documentation.documents = concept.documentation.documents.concat(result.files);
          concept.documentation.totalDocuments += result.files.length;
          result.files.forEach((file:any) => {
            field.newAddDocument = file;
              this.itemSelected.emit({newValue:file});
          });
        }
      })
  }


  filesNoUploaded(failed:any){
      this.modalService.adviceModal( this.translate.instant('common.upload-documents-error'), 'info:question:question','file', failed).subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.addDocuments();
        }
      });
  }

  removeDocument(documentSelected:any){
      let correctdocumentStatusId: any =(documentSelected.documentStatusId === 3 || documentSelected.documentStatusId === 4 || documentSelected.documentStatusId === 6)
      if(this.isLanding && documentSelected.documentStatusId && correctdocumentStatusId){
          this.errorModal(this.translate.instant('documentation-list.error-delete-document-signed'));
      }else if(!this.isLanding && documentSelected.documentStatusId && documentSelected.documentStatusId === 3){
          this.errorModal(this.translate.instant('documentation-list.pending-signature-error'));
      }else{
          this.openQuestionDeletedItem(documentSelected);
      }
  }

  openQuestionDeletedItem(documentSelected:any){
      let correctdocumentStatusId: any =(documentSelected.documentStatusId === 3 || documentSelected.documentStatusId === 4 || documentSelected.documentStatusId === 6)
      let type : any = this.isLanding?'info:question:question-full':'info:deleteOption:question';
      let signatureOrCertification : any = correctdocumentStatusId || documentSelected.sendedAsCertifiedEmail || documentSelected.sendedAsCertifiedSMS;
      let text : any;
      if(signatureOrCertification){
          text = this.isLanding?this.translate.instant('documentation-list.delete-signature-or-certifcation-landing'):this.translate.instant('documentation-list.delete-signature-or-certifcation');
      }else if(documentSelected.publishedForApplicant){
          text = this.isLanding?this.translate.instant('documentation-list.delete-published-for-landing'):this.translate.instant('documentation-list.delete-published-for-applicant');
      }else{
          text = this.isLanding?this.translate.instant('documentation-list.question-remove-document-landing').replace('{document}', documentSelected.name):this.translate.instant('documentation-list.question-remove-document-definitely').replace('{document}', documentSelected.name);
      }

      this.modalService.adviceModal(text, type).subscribe((result: any)=>{
        if(result.result !== 'cancel'){
          this.loader.openLoader('field-type-file-delete-file');
          if(!this.concept.conceptObjectId){
              this.deleteFileFromFieldInNewConcept(documentSelected);
          }else if(this.isLanding || result.result === 'permanently-delete'){
              this.deleteDocumentSelected(documentSelected, 'delete');
          }else if(result.result === 'send-to-trash'){
              this.getDeletedDocuments(documentSelected);
          }
        }
      });
  }

  deleteFileFromFieldInNewConcept(documentDeleted:any){
    let field: any = this.fieldTypeFile.field;
    let concept: any = this.concept;
      field.evaluatedValue.documents = this.commonUtilsService.deleteElement(documentDeleted, field.evaluatedValue.documents, 'documentObjectId');
      concept.documentation.documents = this.commonUtilsService.deleteElement(documentDeleted, concept.documentation.documents, 'documentObjectId');
      concept.fieldsWidthDocuments.forEach((fieldDocument: any) => {
        fieldDocument.documents = this.commonUtilsService.deleteElement(documentDeleted, fieldDocument.documents, 'documentObjectId');
      });
      this.deletedDocument(documentDeleted);
  }

  getDeletedDocuments(documentSelected:any){
      this.apiService.get('documentobjects/'+ this.concept.conceptObjectId +'/recyclebin')
          .then((data:any)=>{
              if(this.checkedName(data.documents, documentSelected, 'documentObjectId')){
                  this.deleteDocumentSelected(documentSelected, 'torecyclebin');
              }else{
                  this.loader.closeLoader(['field-type-file-delete-file']);
                  let text : string = this.translate.instant('documentation-list.error-move-document').replace('{name}', documentSelected.name);
                  this.modalService.adviceModal(text, 'error:accept:warning');
              }
          },(errorData:any)=>{
          })
  }

  checkedName(list:any, item:any, type:any){
      return !(!!(list.filter((listItem:any)=>{ return listItem[type] && listItem.name === item.name}).length));
  }

  deleteDocumentSelected(documentSelected:any, type:any){
    let field: any = this.fieldTypeFile.field;
    let concept: any = this.concept;
    let recycle: string = type==='torecyclebin'?type:'';
    this.apiService.delete('documentobjects', documentSelected.documentObjectId, recycle)
        .then((data:any)=>{
            let text : any =   this.translate.instant('documentation-list.definited-deleted-ok').replace('definited-deleted-ok',documentSelected.name);
            if(type === 'torecyclebin'){
                text = this.translate.instant('documentation-list.document-trash').replace('{document}',documentSelected.name);
            }else if(this.isLanding){
                this.broadcastService.broadcast('deleteAttachmentsDocumentLanding', {
                    document: documentSelected
                })
            }
            documentSelected.id = documentSelected.documentObjectId;
            field.evaluatedValue.documents = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.documents, 'documentObjectId');
            field.evaluatedValue.displayData = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.displayData, 'id');
            let folder:any = this.getFolder(concept.documentation.subFolders, documentSelected.documentObjectId);
            if(folder){
                folder.documents = this.commonUtilsService.deleteElement(documentSelected, folder.documents, 'documentObjectId');
            }else{
                concept.documentation.documents = this.commonUtilsService.deleteElement( documentSelected, concept.documentation.documents, 'documentObjectId');
            }
            this.deletedDocument(document);
            this.openInfoModal(text);
        }, (errorData:any)=>{
            this.loader.closeError();
            let text:string = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('common.delete-document-error').replace('{document}',  documentSelected.name);
            this.errorModal(text);
        })
  }

  getFolder(subFolders:any, documentObjectIdSelected:any){
    subFolders.forEach((folder:any) => {
      for(let j = 0; j< folder.documents.length; j++){
        let documentFolder:any = folder.documents[j];
        if(documentFolder.documentObjectId === documentObjectIdSelected){
            return folder;
        }
      }
      if(folder.subFolders){
          this.getFolder(folder.subFolders, documentObjectIdSelected);
      }
    });
  }

  deletedDocument(documentDeleted:any){
      documentDeleted.deleted = true;
      this.concept.documentation.totalDocuments--;
      this.fieldTypeFile.field.deletedFile = documentDeleted;
      this.itemSelected.emit({newValue:documentDeleted});
      this.loader.closeLoader(['field-type-file-delete-file']);
  }

  openInfoModal(text:any){
      this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  errorModal(text:any){
      this.modalService.adviceModal(text, 'error:accept:warning');
  }

  downloadFile(documentSelected:any){
      this.documentActions.doAction('download', documentSelected);
  }

  showReference(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.fieldTypeFile.references = !this.fieldTypeFile.references;
      this.showFieldReference.emit();
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
  ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
