import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'personalized-notification-modal',
  templateUrl: './personalized-notification-modal.component.html'
})
export class PersonalizedNotificationModalComponent implements OnInit {
  productId: string = this.context.settings.defaultProduct;
  personalizedNotification: any = {
    // customNotificationField:{},
    subjectNotification: {
        id: 'subject-notification-personalized',
        required: true,
        label: this.translate.instant('personalized-notification-modal.mail-subject')
    },
    customEmail: null,
  }
  constructor(private context: ContextFactory,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal) { }
  @Input() concept: any = null;
  @Input() state: any = null;

  ngOnInit(): void {
    this.getCustomMail();
  }

  configureProcessTags(){
    let customEmail: any = this.personalizedNotification.customEmail;
    let customNotificationField:any = {
          fieldId: 'custom-notification-field',
          fieldTypeId: 'rich',
          required: customEmail && customEmail.customTemplateEditable?true:false,
          label: this.translate.instant('add-alert.template-text'),
          isEditable: true,
          noEditable: customEmail && customEmail.customTemplateEditable?false:true,
          bookmarksTag: customEmail.resolvedTags,
          snippetsTag: customEmail.snippetsTag,
          height: 202
    }
    if(customEmail && customEmail.customBody){
        customNotificationField.description = customEmail.customBody;
    }
    this.personalizedNotification.customNotificationField = cloneDeep(customNotificationField)
  }

  getCustomMail(){
    this.apiService.get('processesdata/' + this.concept.conceptObjectId +'/state', this.state.stateId +'/custom-email')
      .then((data:any)=>{
          this.personalizedNotification.customEmail = data;
          let customSubject = this.personalizedNotification.customEmail.customSubject?this.personalizedNotification.customEmail.customSubject:'';
          this.personalizedNotification.customEmail.customSubject = this.concept.titlePrefix? this.concept.titlePrefix+' / '+customSubject:customSubject;
          this.getSnippetsList();
      }, (errorData:any)=> {});
  }

  getSnippetsList(){
      this.apiService.get('snippets/product', this.productId)
          .then((data:any)=> {
              this.personalizedNotification.customEmail.snippetsTag = data;
              this.configureProcessTags();
          }, (errordata:any)=>{});
  }

  itemSelected(e: any, type:any){
      this.personalizedNotification.customEmail[type] = e.newValue;
  }

  cancelTransition(){
      this.modalService.adviceModal(this.translate.instant('personalized-notification-modal.cancel-cuestion'), 'info:question:question')
        .subscribe((result: any)=>{
            if(result.result === 'ok'){
              this.handleDismiss();
            }
        })
  }

  handleAccept() {
      let customEmail = {
          customSubject: this.personalizedNotification.customEmail.customSubject,
          customBody: this.personalizedNotification.customNotificationField.description
      }
      this.activeModal.close({
          result: 'ok',
          customEmail: customEmail
      })
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
