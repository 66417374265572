import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  getVimeoThumb(id:any) {
    return axios({
        method: 'GET',
        url: 'http://vimeo.com/api/v2/video/' + id + '.json'
    }).then((response:any)=>{
      return response.data;
    }, (response:any)=>{
      return response.error;
    });
  }
}
