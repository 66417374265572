<div class="informative-loading-modal">
  <div class="informative-loading-modal__block">
      <div class="informative-loading-modal__new-block"
        [ngHide]="timeoutExceed">
          <p class="informative-loading-modal__text"
              [afTranslate]="'loader-modal.generate-'+typeText+'-primary'">
              El informe se está generando. Esta operación puede tardar un poco.
          </p>
          <p class="informative-loading-modal__text"
              [afTranslate]="'loader-modal.generate-new-secondary'"
              [ngHide]="hideSecondaryText">
              Por favor, no abandones la página.
          </p>
      </div>
      <div class="informative-loading-modal__new-block"
        [ngShow]="timeoutExceed">
        <p class="informative-loading-modal__text"
          [afTranslate]="'loader-modal.timeout-exceed-text'"></p>
      </div>
      <i [class]="'lf-'+iconLoader+' common__preloader-icon common__preloader-icon--modal'"></i>
  </div>
  <button class="common__button common__button--white informative-loading-modal__tab-button"
    [afTranslate]="'loader-modal.open-new-tab'"
    [ngShow]="timeoutExceed"
    (click)="openNewTab(itemSelected.url)"></button>
</div>
