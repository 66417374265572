import * as d3 from 'd3'

import { Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'bar-graph',
  templateUrl: './bar-graph.component.html'
})

export class BarGraphComponent implements OnChanges {
  barGraph: any = {}
  constructor(
    private container: ElementRef
  ) { }
  @Input() graphData:any = null;
  @Input() configurateReport:any = null;
  @Input() id:any = '';

  ngOnChanges(): void {
      let that: any = this.barGraph;
      that.configurateReport = this.configurateReport;
      that.graphData = this.graphData;
      if(this.graphData && this.graphData.colors.length){
        this.drawBarGraph();
      }
  }

  drawBarGraph(){
      let that: any = this.barGraph;
      that.width = 340;
      that.height = that.configurateReport.type===4? 280 : 160;
      this.drawSvg()
      this.drawXaxxis();
      this.drawYaxxis();
      this.drawBars();
  }

  drawSvg(){
    this.barGraph.graphData.svg = d3.select(this.container.nativeElement.firstChild)
      .append('svg')
      .attr('id', 'bar-graph__svg-block-'+this.id)
      .attr("viewBox", `0 0 ${this.barGraph.width} ${this.barGraph.height}`)
      .append('g')
  }

  drawXaxxis(){
      this.barGraph.graphData.x = d3.scaleBand()
          .domain(['0', '4'])
          .range([0, this.barGraph.width])
          .padding(0.15);
      this.barGraph.graphData.x.domain(this.barGraph.graphData.original.map((d:any) => {
          return d.label;
      }));

      this.barGraph.graphData.svg.append("g")
          .attr("transform", "translate(0," + 140 + ")")
          .attr('class', 'bar-graph__x-axxis')
          .call(d3.axisBottom(this.barGraph.graphData.x).tickSize(0))
          .selectAll("text")
          .style("text-anchor",() => {
              return this.barGraph.configurateReport.type === 4? "end" : "";
          })
          .attr("transform",() => {
              return this.barGraph.configurateReport.type===4? "rotate(-60)": "translate(0,0)";
          });
  }

  drawYaxxis(){
      this.barGraph.graphData.y = d3.scaleLinear()
          .range([100, 0]);
      this.barGraph.graphData.y.domain([0, 60]);
  }


  drawBars(){
      let max:number = 0;
      this.barGraph.graphData.data.forEach((element:any) => {
        if(element > max){
          max = element;
        }
      });

      this.barGraph.graphData.bar = this.barGraph.graphData.svg.selectAll()
          .data(this.barGraph.graphData.original)
          .enter()
          .append("g")
          .attr("class", "bar");
      this.barGraph.graphData.bar.append('rect')

          .attr("x", (d:any) => { return this.barGraph.graphData.x(d.label); })
          .attr("width", this.barGraph.graphData.x.bandwidth())
          .attr("y", (d:any)=> { return 100 - ((d.count * 80)/ max) })
          .attr("height", (d:any)=>{ return( d.count * 80 / max) })
          .style('opacity', '0.4')
          .attr('fill', (d:any)=>{return d.color});

      this.barGraph.graphData.bar.append('rect')
          .attr("x",  (d:any)=> { return this.barGraph.graphData.x(d.label); })
          .attr("width", this.barGraph.graphData.x.bandwidth())
          .attr("y", "130")
          .attr("height", "4")
          .attr('fill', (d:any)=>{return d.color});

      this.barGraph.graphData.bar.append('foreignObject')
          .attr("x", (d:any)=> { return this.barGraph.graphData.x(d.label); })
          .attr("width", this.barGraph.graphData.x.bandwidth())
          .attr("y", (d:any) => {return 83 - ((d.count * 80)/ max)})
          .attr("height", "20")
          .attr('class', "bar-graph__bar-value")

          .append('xhtml:div')
          .html((d:any)=>{
              return  '<div><p class="bar-graph__bar-value">'+
                          d.count +
                      '</p></div>';
          });

      this.barGraph.graphData.bar.append("text")
          .attr("class","heat-map-graph__axis-title")
          .attr("transform", "translate(12,120)")
          .text(this.barGraph.graphData.legendCaption);

  }
}
