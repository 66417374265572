import { Injectable } from '@angular/core';

// import $ from 'jquery';
// import moment from 'moment';
import { cloneDeep } from "lodash";

import { ContextFactory } from '@shared/factories/context/context.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '../api-service/api-service.service';


@Injectable({
  providedIn: 'root'
})
export class RolesUtilsService {

  constructor(public context: ContextFactory,
        private commonUtilsService: CommonUtilsService,
        private apiService: ApiServiceService) { }

  getProductRoles(productId: any, clientId: any){
    return this.apiService.get('productroles/product/' + productId+'/assignments')
        .then((data:any)=>{
          data.forEach((item:any) => {
            item.users.forEach((user:any) => {
                user.typeSelection = 'all';
                return user;
            });
            item.typeConfiguration = 'roles';
            item.fieldType = 'multiple';
            item.typeCall = 'users-only';
            item.selectContacts = item.users?cloneDeep(item.users):[];
            item.label = item.name;
            item.fieldId = this.commonUtilsService.createId();
            item.id = item.fieldId;
            item.required = true;
            item.clientId = clientId;
            this.searchRolesClients(item, clientId);
            item.preAssignedUsers = cloneDeep(item.selectContacts);
          });
          return data;
        }, (errorData:any)=>{})
  }

  searchRolesClients(field: any, clientId: any){
      field.clientsUsers.forEach((clientUser:any) => {
          for(let i = 0; i < clientUser.clients.length; i ++){
              let client =  clientUser.clients[i];
              if(client.clientId === clientId){
                  field.selectContacts.push(clientUser);
                  break;
              }
          }
      })
  }

  getRolesConfigurated(rolesList: any){
    let list:any = [];
    rolesList.forEach((role:any) => {
      this.getUsersRoleIdSelected(role);
      list.push(this.getProductRole(role));
    });
    return list;
  }

  getUsersRoleIdSelected(role: any){
      role.allOrgsUserIds = [];
      role.userIds = [];
      role.selectContacts.forEach((user:any) => {
          if(user.typeSelection === 'all'){
              role.allOrgsUserIds.push(user.userId);
          }else{
              role.userIds.push(user.userId);
          }
      })
  }

  getProductRole(role:any){
    return {
      productRoleId: role.productRoleId,
      allOrgsUserIds: role.allOrgsUserIds,
      userIds: role.userIds
    }
  }

  saveRoles(roles: any, productId: any, clientId: any){
    return this.apiService.add('productroles/assign/product/'+productId+'/client/'+clientId, roles)
      .then((data:any)=>{
        return data;
      }, (errorData:any)=>{
        return errorData;
      })
  }

  openManageOptionBlock(menuList: any, rolesLevel: any, enabledButtonManageBlock: any, locked: any){
    menuList.forEach((option:any) => {
        option.disabled = false;
        if(enabledButtonManageBlock && locked){
            option.disabled = true;
            if(rolesLevel.length === 1){
                let roleLevel = rolesLevel[0];
                if(((roleLevel === 3 && (option.action === 'unlock' || option.action === 'permanently-block'))
                    || (roleLevel === 2 && (option.action === 'unlock' || option.action === 'reassign')))){
                    option.disabled = false;
                }
            }else{
              rolesLevel.forEach((role:any) => {
                  if(((role === 3 && (option.action === 'unlock' || option.action === 'permanently-block'))
                      || (role === 2 && (option.action === 'unlock' || option.action === 'reassign')))){
                      option.disabled = false;
                  }
              })
            }
        }
    })
  }

  configureRolesLevel(data: any){
      let roleLevelConfiguration: any = {
          enabledButtonManageBlock: false,
          rolesLevel:[]
      }
      data.forEach((role:any) => {
          if(role.accessLevel === 2 || role.accessLevel === 3){
              if(role.accessLevel === 2){
                  roleLevelConfiguration.roleLevel2 = role;
              }else if(role.accessLevel === 3){
                  roleLevelConfiguration.roleLevel3 = role;
              }
              let usersList =  role.users.concat(role.clientsUsers).sort();
              usersList.forEach((user:any) => {
                  if(user.userId === this.context.user.userId){
                      roleLevelConfiguration.enabledButtonManageBlock = true;
                      roleLevelConfiguration.rolesLevel.push(role.accessLevel);
                  }
              })
          }
      })
      return roleLevelConfiguration;
  }
}
