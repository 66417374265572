import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import moment from 'moment';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'organization-modal',
  templateUrl: './organization-modal.component.html',
})
export class OrganizationModalComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  organizationModal:any = {
    epigraph: '',
    searchText: '',
    showImageError: false,
    alert: false,
    validateCod: true,
    validateCif: true,
    seeHolding: false,
    openList: false,
    openFilial: false,
    subFilial: false,
    versionDesign: 1,
    client: {
      name: '',
      cif:'',
      address:'',
      postalCode:'',
      city:'',
      logo:'',
      province:'',
      country:'',
      email:'',
      phoneNumber:'',
      parentId: null
    },
    advancedInfo: {
      activitySectors: '',
      activityStartDate: '',
      client: '',
      cnae: '',
      cnaeCode: '',
      mainActivity: '',
      markets: '',
      numberOfEmployees: '',
      size: '',
      structure: '',
      workCenters: '',
      epigrafesIAE: []
    },
    logoInfo: {
      logo: '',
      name: '',
      canAdmin: ''
    },
    clientProperties: ['Name','Cif','Address','PostalCode','City','Province','Country','ParentId','Observations','Logo','PhoneNumber','Email','Percent']
  };
  constructor(
    private globalCfg:GlobalCfgFactory,
    private translate:AfTranslateFactory,
    private loader:LoaderFactory,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private activeModal:NgbActiveModal
  ) { }

  @Input() configuration:any;

  ngOnInit(): void {
    this.organizationModal.type = this.configuration.type;
    this.organizationModal.organization = this.configuration.organization;
    this.organizationModal.listOrganization = this.configuration.listOrganization;
    this.organizationModal.onlyAffiliated = this.configuration.onlyAffiliated;
    this.organizationModal.disabledField = this.configuration.disabledField;
    this.organizationModal.createClients = this.configuration.createClients;
    let that = this.organizationModal;
    that.createFilials = (that.createClients || that.onlyAffiliated) && (that.organization && (that.organization.canAdmin || (that.type === 'new' && !that.organization.canAdmin)));
    if(this.skin === 'uk-steel-blue'){
      this.getClientTypes();
    }
    this.translation();
    this.getParent();
    if(that.organization && that.organization.clientId){
        if(this.skin != 'uk-steel-blue' && this.organizationModal.organization.hasLogo){
            this.getClientLogos();
        }
        this.getFilials();
    }else{
        if(this.skin === 'uk-steel-blue'){
            this.configureClient();
        }
    }
    this.configureModalClient();
  }

  getClientTypes(){
    this.apiService.get('clients/types').then(
      (data:any) => {
          this.organizationModal.clientTypes = this.commonUtilsService.editList(data, 'clientTypeId', 'type');
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  getClientLogos(){
    this.apiService.get('clients/logos', this.organizationModal.organization.clientId).then(
      (data:any) => {
          this.organizationModal.logos = data;
      },
      (error:any) => {
        console.error(error);
        this.loader.closeError();
      }
    );
  }

  getFilials(){
    this.apiService.get('clients/companytree', this.organizationModal.organization.clientId).then(
      (data:any) => {
          this.organizationModal.filialsData = data[0];
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  getParent(){
    let that = this.organizationModal;
    that.clientListModify=[];
    for(var i = 0; i < this.organizationModal.listOrganization.length; i++){
        var client = this.organizationModal.listOrganization[i];
        client.text = client.name;
        client.listValueId = client.clientId;
        if(that.client && that.client.parentId === client.parentId){
            that.parentName = client.name;
        }
        if(client.clientId !== this.organizationModal.organization.clientId){
            that.clientListModify.push(client);
        }
    }
  }

  translation(){
    let that = this.organizationModal;
    if(this.skin === 'uk-steel-blue'){
        that.clientProperties = ['Name', 'TypeId', 'Reference2', 'Cif', 'NiNumber', 'VatNumber', 'Address', 'CompanyNumber', 'PostalCode', 'City', 'Country', 'Logo', 'PhoneNumber', 'Email','PhoneNumber2', 'Email2', 'ContactName1', 'ContactName2', 'ContactName3'];
    }
    that.clientProperties.forEach((clientProperty: any) => {
      that['client' + clientProperty] = {
          label: this.translate.instant('new-client.client-' + clientProperty.toLowerCase()),
          // id: clientProperty.toLowerCase() + '-' + i,
          // id: this.commonUtilsService.createId(),
          required: (clientProperty === 'Name' || (clientProperty === 'Cif' && this.skin != 'uk-steel-blue') || clientProperty === 'ParentId' || clientProperty === 'Reference2' || clientProperty === 'TypeId'),
          description: null,
          multiline: clientProperty === 'Observations'
      }
      this.configureSpecificProperty(that['client' + clientProperty], clientProperty);
    });
    if(this.skin === 'uk-steel-blue'){
        that.title = this.translate.instant('new-client.new-client');
    }else{
        that.title = this.translate.instant('new-client.new-organization');
        that.clientName.description = this.translate.instant('new-client.info-to-fill');
    }
    that.errorReference = this.translate.instant('new-client.error-our-reference');
  }

  configureSpecificProperty(property: any, clientProperty: any){
      switch(clientProperty){
          case 'Address':
              if(this.skin === 'uk-steel-blue'){
                  property.label = this.translate.instant('new-client.client-streetaddress');
              }
              break;
          case 'City':
              if(this.skin === 'uk-steel-blue'){
                  property.label = this.translate.instant('new-client.client-town');
              }
              break;
          case 'PhoneNumber':
              if(this.skin === 'uk-steel-blue'){
                  property.label = this.translate.instant('new-client.client-phonenumber1');
              }
              property.maxLength = 20;
              break;
          case 'PhoneNumber2':
              property.maxLength = 20;
              break;
          case 'Email':
              if(this.skin === 'uk-steel-blue'){
                  property.label = this.translate.instant('new-client.client-email1');
              }
              property.maxLength = 100;
              break;
          case 'Email2':
              property.maxLength = 100;
              break;
          case 'ParentId':
              property.fieldId = 'parentClient';
              break;
          case 'Observations':
              property.minHeight = '63px';
              break;
          case 'Percent':
              property.fieldTypeId = 'percent';
              delete property.multiline;
              property.minValue = 0;
              property.maxValue = 100;
              property.positive = true;
              break;
          default:
              break;
      }
      if(this.organizationModal.type === 'edit' && !this.organizationModal.organization.canAdmin){
          property.noEditable = true;
      }
  }

  configureModalClient(){
    let that = this.organizationModal;
    if(that.organization == null) {
      that.organization = {};
    }
    if (that.type === 'edit') {
        that.client = cloneDeep(that.organization);
        if(this.skin === 'uk-steel-blue'){
            that.title = this.translate.instant('new-client.edit-client');
            if(that.client.observations){
                // that.client.observationsContact = that.client.observations.split('-|-');
                let observationsContact: any = that.client.observations.split('-|-');
                that.client.contactName1 = observationsContact[0];
                that.client.contactName2 = observationsContact[1];
                that.client.contactName3 = observationsContact[2];
            }
        }else{
            that.title = this.translate.instant('new-client.edit-organization');
        }
        if (that.organization.logo != null) {
            this.commonUtilsService.setLogo(that.organization.logo, that.organization.logo);
            that.showLogo = true;
            that.logoInfo.name = that.client.name;
        }
    }
    that.logoInfo.canAdmin = that.organization.canAdmin;
    if(this.skin === 'uk-steel-blue'){
        if(!that.client.observationsContact){
            that.client.observationsContact = new Array(3);
        }
    }
    that.businessGroup = that.type === 'edit' ? that.client.parentId != null : ((that.client.parentId && that.client.parentId != null) || that.onlyAffiliated);
    if(that.listOrganization.length === 1 && that.onlyAffiliated){
        that.client.parentId = that.listOrganization[0].clientId;
        that.clientParentId.disabled = true;
    }
    if(that.disabledField){
        that.clientParentId.noEditable = true;
    }
  }

  configureClient(){
    let that = this.organizationModal;
    that.clientProperties = ['Name', 'TypeId', 'Reference2', 'Cif', 'NiNumber', 'VatNumber', 'Address', 'CompanyNumber', 'PostalCode', 'City', 'Country', 'Logo', 'PhoneNumber', 'Email','PhoneNumber2', 'Email2', 'ContactName1', 'ContactName2', 'ContactName3'];
    that.client = {
        name: '',
        cif:'',
        address:'',
        postalCode:'',
        city:'',
        province:'',
        country:'',
        email:'',
        email2:'',
        phoneNumber:'',
        phoneNumber2: '',
        reference2: '',
        nI_Number: '',
        vatNumber: '',
        companyNumber: '',
        clientTypeId: null
    };
  }

  seeStructurHolding(){
    let that = this.organizationModal;
    that.seeHolding = !that.seeHolding;
  }

  checkAffiliated(){
    let that = this.organizationModal;
    if(that.createFilials){
        if(that.onlyAffiliated){
            that.businessGroup = true;
        }else{
            that.businessGroup = !that.businessGroup;
        }
    }
  }

  itemSelected(type:string, newValue:any){
    let that = this.organizationModal;
    if (type === 'activitySectors') {
        that.epigraph = newValue;
        this.setEpigraphs();
    } else if (type === 'advanced-activityStartDate') {
        this.configureTimeSelect(newValue)
    } else if ((type).indexOf('advanced-') !== -1) {
        var variableNamed = type.substring(type.indexOf('-') + 1, type.length);
        that.advancedInfo[variableNamed] = newValue;
    } else if (type === 'logo') {
        that.logoInfo.logo = newValue.img;
        that.logoInfo.name = newValue.name;
        that.files = newValue.files;
        that.logos = {
            logo: newValue.listLogos[0].html.toDataURL('image/png'),
            logo320X55: newValue.listLogos[3].html.toDataURL('image/png'),
            logo260X45: newValue.listLogos[4].html.toDataURL('image/png'),
            logo290X50: newValue.listLogos[2].html.toDataURL('image/png'),
            logo50X50: newValue.listLogos[1].html.toDataURL('image/png'),
        }
    } else if (type === 'contactName1' || type === 'contactName2' || type === 'contactName3') {
        let orderContact = parseInt(type.substring(type.length - 1, type.length)) - 1;
        that.client.observationsContact[orderContact] = newValue;
    }else{
        that.client[type] = newValue;
        if (type === 'clientTypeId') {
            var clientTypeSelected = this.commonUtilsService.extractToArray(that.client.clientTypeId, that.clientTypes, 'clientTypeId');
            that.client.type = clientTypeSelected ? clientTypeSelected.type : clientTypeSelected;
        }
    }
  }

  setEpigraphs(){
    let that = this.organizationModal;
    var epigraphList = cloneDeep(that.epigraph);

    if(that.epigraph.indexOf('|')){
        epigraphList = that.epigraph.split('|')
    }

    for(var i = 0; i < epigraphList.length; i++){
        this.addOrDeleteEpigraph(epigraphList[i]);
    }

    for(var j = that.advancedInfo.epigrafesIAE.length-1; j >= 0; j--){
        if(that.advancedInfo.epigrafesIAE[j].hasInEpigraphs){
            delete that.advancedInfo.epigrafesIAE[j].hasInEpigraphs;
        }else{
            that.advancedInfo.epigrafesIAE.splice(j, 1)
        }
    }
  }

  addOrDeleteEpigraph(epigraph:any){
    let that = this.organizationModal;
    var isNewEpigraph = true;
    for(var j = 0; j < that.advancedInfo.epigrafesIAE.length; j++){
        if(parseInt(epigraph) === parseInt(that.advancedInfo.epigrafesIAE[j]['iaE_ID'])){
            isNewEpigraph = false;
            that.advancedInfo.epigrafesIAE[j].hasInEpigraphs = true;
            break;
        }
    }
    if(isNewEpigraph){
        that.advancedInfo.epigrafesIAE.push({
            hasInEpigraphs: true,
            'iaE_ID': epigraph
        });
    }
  }

  configureTimeSelect(newValue:any) {
    let that = this.organizationModal;
    that.advancedInfo.activityStartDate = newValue;
    if (newValue !== null) {
        if (newValue.getTimezoneOffset() < 0) {
            that.advancedInfo.activityStartDate = moment(newValue).minutes(Math.abs(new Date().getTimezoneOffset())).toISOString();
        } else {
            that.advancedInfo.activityStartDate = moment(newValue).toISOString();
        }
    }
  }

  closeAdvice(type:string) {
    this.organizationModal[type] = false;
    $('.organization-modal__window').removeClass('organization-modal__window--band-error');
  }

  clearForm() {
    let that = this.organizationModal;
    that.logoInfo.logo = "";
    that.logoInfo.name= "";
    that.file = null;
    that.client = {
        name: '',
        address: '',
        cif: '',
        city: '',
        province: '',
        country:'',
        postalCode: ''
    }

    if(that.listOrganization.length === 1 && that.onlyAffiliated){
        that.client.parentId = that.listOrganization[0].clientId;
        that.clientParentId.noEditable = true;
    }
  }

  createOrSaveClient() {
    let that = this.organizationModal;
    that.errorsValidation = 0;
    this.validateFieldsForm();
    if(!that.errorsValidation){
      this.handleAccept();
    }
  }

  validateFieldsForm() {
    let that = this.organizationModal;
    if(that.businessGroup){
        if(!that.client.parentId){
            that.clientParentId.error = true;
            that.errorsValidation ++;
        }else{
            that.clientParentId.error = false;
        }
        if(this.organizationModal.clientPercent.errorInForm){
            that.errorsValidation ++;
        }
    }else{
        that.client.parentId = null;
    }

    if(that.client && that.client.logo){
        if(that.organization.logo.indexOf('data:image/png;base64,') !== -1){
            that.client.logo = that.client.logo.substr(that.client.logo.indexOf(',') + 1, that.client.logo.length);
        }
    }

    Object.keys(that.client).forEach((key:any) => {
        if(key === 'name' || (key === 'cif' && this.skin !== 'uk-steel-blue') || (this.skin === 'uk-steel-blue' && (key === 'clientTypeId' || key === 'reference2'))){
            var valid = !(that.client[key] === '' || that.client[key] === undefined || that.client[key] === null);
            if(key === 'clientTypeId'){
                that[key].error = !valid;
            }else if(key === 'reference2'){
                valid = this.checkOurReference(that.client);
                that['client' + key.charAt(0).toUpperCase() + key.slice(1)].error = !valid;
            }else{
                that['client' + key.charAt(0).toUpperCase() + key.slice(1)].error = !valid;
            }

            if(!valid){
                that.errorsValidation++;
            }
        }else if((key === 'email' && that.client.email !== '' && that.client.email !== null) || (key === 'email2' && that.client.email2 !== '' && that.client.email2 !== null)){
            var valid = this.commonUtilsService.validateMail(that.client.email);
            that['client' + key.charAt(0).toUpperCase() + key.slice(1)].error = !valid;
            if(!valid){
                that.errorsValidation++;
            }
        }
    });
  }

  checkOurReference(client:any){
    let that = this.organizationModal;
    if(client.reference2){
        for(var i = 0; i < that.listOrganization.length; i++){
            var clientList = that.listOrganization[i];
            if(client.reference2 === clientList.reference2 && client.clientId !== clientList.clientId){
                that.showError = true;
                $('.organization-modal__window').addClass('organization-modal__window--band-error');
                return false;
            }
        }
    }
    that.showError = false;
    $('.organization-modal__window').removeClass('organization-modal__window--band-error');
    if(!client.reference2){
        return false;
    }
    return true;
  }

  handleAccept() {
    let that = this.organizationModal;
    delete that.client.text;
    delete that.client.listValueId;
    this.activeModal.close({
        result: 'ok',
        client: that.client,
        logo: that.files,
        logoImage: that.logoInfo.logo
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
