<div class="predefined-templates"
    [ngShow]="videoSlider.videos?.length">
    <div class="predefined-templates__container">
        <div class="video-slider__breadcrumb-block">
            <div class="concept-steps__steps-map-icon concept-steps__steps-map-icon--video"
                *ngFor="let video of videoSlider.videos">
                <i [ngClass]="video.active?'lf-icon-step-final':'lf-icon-step-first'"
                    (click)="videoChange(video)"></i>
            </div>
        </div>
        <div class="predefined-templates__template-header">
            <div class="predefined-templates__circle-text">
                <i class="lf-icon-video predefined-templates__icon-templates"></i>
                <p class="predefined-templates__title-name"
                    [afTranslate]="'common.videos'">Videos</p></div>
        </div>
        <ul class="video-slider__video-block">
            <li class="video-slider__video-item"
                [ngShow]="videoSlider.videoSelected?.videoCode">
                <div class="video-slider__video-img"
                    (click)="seeVideo(videoSlider.videoSelected)">
                    <img class="video-slider__video-img-photo"
                        [src]="videoSlider.imgUrl"
                        alt="video image"/>
                    <button class="video-slider__button-play lf-icon-play" >
                    </button>
                </div>
                <div class="video-slider__video-info">
                    <p class="video-slider__info-text"
                        (click)="seeVideo(videoSlider.videoSelected)">{{videoSlider.videoSelected?.videoTitle}}</p>
                </div>

            </li>
        </ul>
    </div>
</div>
