import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Injectable()
export class EvaluationsCompanyResolverResolver implements Resolve<any> {
  constructor(private apiService: ApiServiceService){}
  resolve(){
    return this.apiService.get('company', 'dashboard/evaluations')
            .then((data:any)=> {
              return data;
            });
  }
}
