<div class="exit-login">
    <div class="exit-login__container"
        [ngClass]="{'exit-login__container--public' : exitLogin.public}">
        <div class="exit-login__custom-container-block">
            <div class="exit-login__custom-invitation-block"
                    [ngClass]="{'exit-login__custom-invitation-block--share': exitLogin.showShareBlock,
                        'exit-login__custom-invitation-block--meeting': exitLogin.show === 'confirm-meeting' || exitLogin.show === 'dismiss-meeting'}">
                <div class="exit-login__custom-block-no-share"
                    [ngHide]="exitLogin.showShareBlock">
                    <img class="exit-login__invitation-block-img"
                        [src]="custom.logoOther"
                        alt="Logo"/>
                    <div class="exit-login__custom-result-invitation"
                            [ngClass]="{'exit-login__custom-result-invitation--shared': exitLogin.show === 'poll-shared',
                                'exit-login__custom-result-invitation--bottom-64': exitLogin.show === 'concept-shared' || exitLogin.show === 'save-task'}">
                        <i [class]="exitLogin.meeting.icon"></i>
                        <div class="exit-login__information-contact exit-login__information-contact--custom"
                                [ngClass]="{'exit-login__information-contact--custom-meeting': exitLogin.show === 'dismiss-meeting' ||  exitLogin.show === 'confirm-meeting'}">
                                <p [ngShow]="exitLogin.show !== 'concept-public-locked'"
                                    class="exit-login__text-information">{{exitLogin.prymaryText}}</p>
                                <p class="exit-login__text-information exit-login__text-information--second"
                                    [ngShow]="exitLogin.show === 'concept-public' || exitLogin.show === 'concept-public-locked' || exitLogin.show === 'poll-public' || exitLogin.show === 'poll-expired-shared' || exitLogin.show==='poll-expired-public'">{{exitLogin.secondaryText}}</p>
                        </div>
                    </div>
                    <div class="exit-login__custom-information-block"
                        [ngClass]="{'exit-login__custom-information-block--shared': exitLogin.show === 'concept-shared'
                            || exitLogin.show === 'poll-shared' || exitLogin.show === 'save-task' || exitLogin.show ==='poll-public',
                            'exit-login__custom-information-block--meeting': exitLogin.show === 'confirm-meeting' || exitLogin.show === 'dismiss-meeting'}"
                        [ngShow]="exitLogin.show !== 'concept-public' && exitLogin.show !== 'concept-public-locked'">
                        <p [ngHide]="exitLogin.show === 'dismiss-meeting' || exitLogin.show === 'confirm-meeting'"
                            class="exit-login__title-invitation"
                            [ngClass]="{'exit-login__poll-expired-info-custom':exitLogin.show === 'poll-expired-shared' || exitLogin.show === 'poll-expired-public'}">{{exitLogin.meeting.message}}</p>
                        <div class="exit-login__information-contact exit-login__information-contact--custom"
                            [ngShow]="exitLogin.show === 'dismiss-meeting' || exitLogin.show === 'confirm-meeting'">
                            <div class="exit-login__footer-title-custom">
                                <p class="exit-login__footer-text-custom"> {{exitLogin.meeting.footer.title}}</p>
                            </div>
                            <div class="exit-login__info">
                                {{exitLogin.meeting.footer.date | convertDate:'without-time'}}
                                <span [afTranslate]="'exit-login.from'"></span>
                                {{exitLogin.meeting.footer.from}}
                                <span [afTranslate]="'exit-login.to'"></span>
                                {{exitLogin.meeting.footer.to}}
                            </div>
                            <div class="exit-login__info">{{exitLogin.meeting.footer.location}} </div>
                        </div>
                        <div class="exit-login__information-contact exit-login__information-contact--custom"
                                [ngClass]="{'exit-login__information-contact--custom-expired-poll': exitLogin.show === 'poll-expired-public' || exitLogin.show === 'poll-expired-shared'}"
                                [ngShow]="exitLogin.show === 'poll-expired-public' ||  exitLogin.show === 'poll-expired-shared'">
                                <div class="exit-login__info">
                                    <span [afTranslate]="'exit-login.poll-temporaly'"></span>:
                                    <span>{{exitLogin.meeting.beginDate | convertDate:'without-time'}}</span>
                                    <span [ngShow]="exitLogin.meeting.beginDate">-</span>
                                    <span>{{exitLogin.meeting.expirationDate | convertDate:'without-time'}}</span>
                                </div>
                        </div>
                    </div>
                    <div class="exit-login__custom-information-block"
                        [ngClass]="exitLogin.public?'exit-login__custom-information-block--public':'exit-login__custom-information-block--shared'"
                        [ngShow]="exitLogin.show === 'concept-public' || exitLogin.show === 'concept-public-locked'">
                        <p class="exit-login__title-invitation exit-login__title-invitation--public exit-login__title-invitation--custom"
                            [ngClass]="{'exit-login__title-invitation--blank':exitLogin.meeting.message===''}">{{exitLogin.meeting.message}}</p>
                        <button class="exit-login__button-copy"
                            [ngClass]="{'common__button--disabled':exitLogin.disabledNext}"
                            (click)="copyText(thisTooltip)"
                            [ngbTooltip]="'common.copied' | afTranslate"
                            triggers="manual"
                            #thisTooltip="ngbTooltip"
                            container="body"
                            [ngStyle]="{'background-color':custom.color,'border-color':custom.color, 'color':custom.textColor}"
                            [afTranslate]="'exit-login.copy-link'">Copiar enlace de seguimiento</button>
                        <button class="lf-icon-send-pdf exit-login__button-send-custom"
                            [ngClass]="{'exit-login__button-send-custom--user-mail':exitLogin.userEmail}"
                            (click)="download()"></button>
                        <button class="lf-icon-share exit-login__button-share"
                            [ngClass]="{'exit-login__button-share--user-mail':exitLogin.userEmail}"
                            (click)="exitLogin.showShareBlock = !exitLogin.showShareBlock"></button>
                    </div>
                </div>
                <div [ngShow]="exitLogin.showShareBlock"
                    class="exit-login__custom-share-block">
                    <div class="exit-login__custom-share-content">
                        <button class="exit-login__custom-share-return-button"
                            (click)="backToMessage()">
                            <i class="lf-icon-angle-left exit-login__custom-share-return-icon"></i>
                            <span class="exit-login__custom-share-return-text"
                                [afTranslate]="'common.return'">_Volver</span>
                        </button>
                        <img class="exit-login__invitation-block-img"
                                [src]="custom.logoOther"
                                [ngShow]="custom.logoOther"
                                alt="Logo"/>
                        <h4 class="exit-login__custom-share-title"
                                [ngClass]="{'exit-login__custom-share-title--without-img': !custom.logoOther}"
                                [afTranslate]="'exit-login.tracking-share'"></h4>
                        <p class="exit-login__custom-share-text"
                            [ngClass]="{'exit-login__custom-share-text--user-mail':exitLogin.userEmail}">{{exitLogin.shareInfoText}}</p>
                        <div class="exit-login__custom-share-emails-block">
                            <div class="exit-login__custom-share-emails-icon">
                                <i class="lf-icon-mail"></i>
                            </div>
                            <div class="exit-login__custom-share-emails-content">
                                <div class="exit-login__email-item-block"
                                    [ngClass]="{'exit-login__email-item-block--others': !first}"
                                    *ngFor="let email of exitLogin.emailList; let first = first; let index = index">
                                    <span class="exit-login__email-item"
                                        (mouseenter)="email.icon = 'lf-icon-close-round-full'"
                                        (mouseleave)="email.icon = 'lf-icon-close-round'">{{email.text}}
                                        <i [class]="'exit-login__email-item-icon '+email.icon"
                                            (click)="exitLogin.emailList.splice(index, 1)"></i>
                                    </span>
                                </div>
                                <textarea class="exit-login__custom-share-emails-textarea"
                                    [ngClass]="{'exit-login__custom-share-emails-textarea--one-item':exitLogin.emailList.length >= 1}"
                                    placeholder="{{'exit-login.emails' | afTranslate}}"
                                    [(ngModel)]="exitLogin.emailRaw"
                                    (keyup)="insertEmail($event)">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="exit-login__custom-information-block exit-login__custom-information-block--user-mail">
                        <button class="exit-login__button-copy"
                            (click)="sendShare()"
                            [ngStyle]="{'background-color':custom.color,'border-color':custom.color, 'color':custom.textColor}"
                            [afTranslate]="'exit-login.send-share'">_Enviar referencia y enlace</button>
                        <p class="exit-login__custom-share-info-text">{{exitLogin.sharebottomText}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
