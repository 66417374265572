import moment from "moment";
import { cloneDeep } from "lodash";

import { Injectable } from '@angular/core';
import { FormControl } from "@angular/forms";

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

import ExpandMenu from '../../../../../assets/data/expanded-menu.json';



@Injectable({
  providedIn: 'root'
})
export class ReportTableParentService {


  constructor(private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private filterColumnService: FilterColumnService,
    private dateConverterService: DateConverterService,
    private apiService: ApiServiceService) { }

  configureReportTable(child:any){
    let expandMenu: any = ExpandMenu;

    return {
        url: (child.skin === 'icam-red' ? 'icam/' : ''),
        amount: -1,
        scrollX: 0,
        userPermision: false,
        elementValue: '',
        optionSelect: 'list',
        selectedManagerName: '',
        listYears: [],
        reportsListFilter: [],
        detailsFilter: [],
        orderByColumn: {id: ''},
        dateFormat: "dd/MM/yyyy",
        showFrom: true,
        managersFilter: new FormControl(),
        actions: {
            fieldTypeId: 'options',
            isHighLight: false,
            isTasksField: false,
            name: this.translate.instant('common.actions')
        },
        filters: [],
        findFilters: [],
        dateFilters: [],
        listFilter: [],
        calendarFilter: [],
        creationDateFromDate: moment().startOf('quarter'),
        creationDateToDate: moment().endOf('quarter'),
        modificationDateFromDate: moment().startOf('quarter'),
        modificationDateToDate: moment().endOf('quarter'),
        type: 'dynamic',
        optionText: this.translate.instant('report-table.view-graph'),
        openTooltip: this.translate.instant('report-table.icon-tooltip'),
        riskMapMenu: expandMenu['menuDocumentReport'].menu,
        monthNameFilter: {
            name: this.translate.instant('report-table.icam.mes'),
            orderBy: 'index'
        },
        perfilUsuarioFilter: {
            name: this.translate.instant('report-table.icam.perfil'),
            orderBy: 'index'
        },
        nombreUsuarioFilter: {
            name: this.translate.instant('common.user'),
            orderBy: 'index'
        },
        tituloFilter: {
            name: this.translate.instant('common.title'),
            orderBy: 'index'
        },
        isbnFilter: {
            name: this.translate.instant('report-table.icam.todas-publicaciones.isbn'),
            orderBy: 'index'
        },
        referenciaFilter: {
            name: this.translate.instant('common.reference'),
            orderBy: 'index'
        },
        tipoFilter: {
            name: this.translate.instant('common.type'),
            orderBy: 'index'
        },
        stepFilter: {
            name: this.translate.instant('common.state'),
            orderBy: 'index'
        },
        materiaFilter: {
            name: this.translate.instant('report-table.icam.por-usuario.materia'),
            orderBy: 'index'
        },
        consultaFilter: {
            name: this.translate.instant('common.affair'),
            orderBy: 'index'
        },
        tituloFragmentoFilter: {
            name: this.translate.instant('report-table.icam.todas-publicaciones.titulo'),
            orderBy: 'index'
        },
        referenciaFragmentoFilter: {
            name: this.translate.instant('report-table.icam.todas-publicaciones.referencia'),
            orderBy: 'index'
        },
    }
  }

  onChanges(child:any){
      let that: any = child.reportTable;
      if(child.report && !child.configureInit){
        for(let column of child.report.columns){
          column.columnType = String(column.filterType)
        }
        that.report = child.report;
        that.configureInit = true;
        this.configureColumns(child);
          if (child.skin === 'icam-red') {
              this.configureIcamInfo(false, child);
              this.updateIcamTotals(child);
          }else{
              that.leftMenu = this.initPosMenu(that.scrollX)+'px'
              that.leftMenuCabecera = this.initPosMenu(0)+'px'
              that.reportsList = that.report.rows;
              that.reportsListFilter = that.reportsList;
              if(that.report.columns[that.report.columns.length-1].fieldTypeId === 'options'){
                  that.report.columns[that.report.columns.length-1].name = this.translate.instant('common.actions');
              }
          }
      }
  }

  openConcept(risk:any, child:any){
      for(let i = 0; i < child.reportTable.reportsList.length; i++){
          let report: any = child.reportTable.reportsList[i]
          let conceptObjectId : any = report.conceptObjectId;
          if( child.configurateReport.type === 2){
              conceptObjectId = parseInt(report.conceptObjectId.split('/', 2));
          }
          if(conceptObjectId === risk.conceptObjectId){
              child.reportTable.selectConceptObject = null;
              if(risk.fieldContainerId){
                  localStorage.setItem('anchorID', risk.fieldContainerId);
              }
              this.getConceptObjectInfo(report, 'concept', child);
              break;
          }
      }
  }

// JRSJ 31/03/2020
  moveScroll(scrollX:any, child:any){
      let element:any = $(".report-table");
      let ancho : any =  element.width() - 30;
      child.reportTable.scrollX =  scrollX;
      child.reportTable.leftMenu = ancho + scrollX;
    // $scope.$apply();
  }

  initPosMenu(scrollX:any){
      let element: any = $(".report-table");
      return element.width() - 30 + scrollX;
  }


  initHeightColumn(object:any, child:any){
      let element:any = document.getElementById(object);
      return element.clientHeight;
  }



  configureRows(child:any){
      let columns: any = child.reportTable.report.columns
      let rows: any = child.reportTable.report.rows;
      let reportColor: any = child.reportTable.report.reportColors;
      for(let i = 0; i < rows.length; i++){
          this.commonUtilsService.configureReportRow(rows[i], columns, reportColor[i])
      }
  }


  configureColumns(child:any) {
      let that: any = child.reportTable
      let element: any = $('.report-table')
      that.hasOverflow = false;
      let columnsWidth:any = 0;
      let tableWidth = element.width();
      let newTableWidth = 0;
      that.filters = [];
      that.report.columns.forEach((column:any) => {
          if(column.name && column.name !=="conceptObjectId"){
              column.id = this.configureId(column.name);
              that.report.rows = this.replaceInRow(column, that.report.rows, child);
              that.report.reportColors = this.replaceInRow(column, that.report.reportColors, child);
          }
          if(column.filterType && column.filterType !==null){
              this.extractFilterType(column, child);
          }
          if(column.fieldTypeId === 'date'){
              this.configureDateRows(that.report.rows, column, child);
          }
          this.calculateColumnWidth(column, child);
          columnsWidth += column.percent;
      });

      if (child.skin != 'icam-red') {
          for (let i = 0; i < that.report.columns.length; i++) {
              let column:any = that.report.columns[i];
              let columnPercent:any = (column.percent * 100) / columnsWidth;
              if ((columnPercent / 100) * tableWidth >= column.min) {
                  column.displayWidth = columnPercent - 1 + '%';
                  column.margin = '0px 0.5%';
              } else {
                  let columnWidth:any = column.min - (that.report.columns.length * 2);
                  if(columnWidth < column.min){
                      columnWidth = column.min;
                  }
                  column.displayWidth = columnWidth + 'px';
                  column.margin = '0px 7px';
                  newTableWidth += columnWidth + 14;
                  that.hasOverflow = true;
              }

              if (i === that.report.columns.length - 1) {
                  that.showEllipsis = true;
              }
          }

          if (newTableWidth) {
              newTableWidth += 70;//Para contar columna de opciones del final
              that.newTableWidth = newTableWidth.toString() + 'px';
              let widthBlock:any = element.width();
              if (widthBlock > newTableWidth) {
                  that.newTableWidth = 'auto';
                  that.minimSize = widthBlock - newTableWidth + 'px';
              }
          }
          this.configureRows(child);
          this.loader.closeLoader(['report-open-report',
              'report-open-concept',
              'folder-open-concept',
              'folder-on-init',
              'evaluation-menu-menu-action']);
      }
  }

  extractFilterType(column:any, child:any){
      let that: any = child.reportTable
      column.fieldTypeId = typeof column.filterType === 'number' && !column.fieldTypeId ? column.filterType : column.fieldTypeId;
      switch (column.fieldTypeId) {
          case 3:
          case 4:
          case 'list':
          case 'listp':
              if (child.skin === 'icam-red') {
                  column.filterType = "list";
                  column.rows = that.detailsView? that.detailsFilter : that.reportsListFilter;
              } else {
                  column.filterType = "listFind";
                  that[column.id + 'InputFilter'] = '';
                  column.rows = that.report.rows;
              }
              that.filters.push(column.id);
              that[column.id+'List'] = this.filterColumnService.extractList(column.id, column.rows);
              that[column.id+'ListFilter'] = [];
              break;
          case 2:
          case 'date':
              column.filterType = "date";
              if(child.skin !=='icam-red'){
                that.dateFilters.push(column.id)
              }

              break;
          default:
              column.filterType = "find";
              that[column.id+'ElementValue'] = '';
              that.findFilters.push(column.id)
              break;
      }
  }

  configureDateRows(rows:any, column:any, child:any){
      rows.forEach((row:any) => {
          let columnId: any = row[column.id];
          if(columnId && columnId !== null && columnId.indexOf('/') !== -1){
              let extractDate : any = columnId.split('/');
              row[column.id] = this.dateConverterService.toIso(new Date(extractDate[1]+'/'+extractDate[0]+'/'+extractDate[2]));
          }
      });
  }


  replaceInRow(column:any, rows:any, child:any){
      rows.forEach((row:any) => {
        for( let [key, value] of Object.entries(row)){
          if(column.name.toLowerCase() === key.toLowerCase()){
            row[column.id] = value;
          }
        }
      })
      return rows;
  }

  calculateColumnWidth(column:any, child:any) {
      switch (column.fieldTypeId) {
          case 'text':
          case 'l-co':
              column.percent = (100 / (child.reportTable.report.columns.length - 2));
              column.min = 300;
              if(column.isHighLight){
                  column.percent = 100 / ((child.reportTable.report.columns.length - 2) /0.5);
                  column.min = 150;
              }
              break;
          case 'conceptObjectId':
              column.percent = 0;
              column.min = 0;
              break;
          case 'options':
              column.percent = 0;
              column.min = 50;
              break;
          case 'check':
              column.percent = 100 / ((child.reportTable.report.columns.length - 2) /(50/300));
              column.min = 50;
              break;
          default:
              column.percent = 100 / ((child.reportTable.report.columns.length - 2) /0.5);
              column.min = 150;
              break;
      }
  }

  relatedConcept(row:any, type:any, child:any) {
      child.reportTable.selectConceptObject = null;
      if (!Number.isInteger(row.conceptObjectId) && row.conceptObjectId.indexOf('/') !== -1) {
          let mapPath:any = row.conceptObjectId.split('/');
          if (type === 'concept' && mapPath.length === 3) {
              localStorage.setItem('anchorID', mapPath[1]);
          }
      }
      this.getConceptObjectInfo(row, type, child);
  }

  getConceptObjectInfo(row:any, type:any, child:any) {
      let conceptObjectId:any = row.conceptObjectId.toString().indexOf('/') !== -1? row.conceptObjectId.split('/')[0] : row.conceptObjectId;
      this.apiService.get(child.reportTable.url+'conceptobjects', conceptObjectId)
          .then((data:any) => {
              child.reportTable.selectConceptObject = data;
              let to : any = '/evaluation/' + child.evaluationId +
                  '/folder/' + child.reportTable.selectConceptObject.folderId +
                  '/concept/' + child.reportTable.selectConceptObject.conceptId +
                  '/concept-evaluated/' + child.reportTable.selectConceptObject.conceptObjectId;
              if (type === 'task') {
                  to += '/task';
              }
              this.loader.closeLoader(['report-open-concept']);
              this.openConceptOrTask(to);

          },(errorData:any)=>{});
  }

  openConceptOrTask(to:string) {
      window.open(to, '_blank');
  }

  changeView(child:any){
    if(child.reportTable.optionSelect === 'list'){
        child.reportTable.optionSelect = 'graph';
        child.reportTable.optionText = this.translate.instant('report-table.view-list');

    }else{
        child.reportTable.optionText = this.translate.instant('report-table.view-graph');
        child.reportTable.optionSelect = 'list';
    }
  }

  filterByDate(dateFilter:any, reset:any, column:any, child:any){
      let i: number = child.reportTable.dateFilters.findIndex((date:any)=>{return dateFilter.id === date.id});
      if(i !== -1){
          column.hasFilter = false;
          child.reportTable.dateFilters.splice(i, 1)
      }
      if(dateFilter.apply !== null){
          child.reportTable.dateFilters.push(dateFilter);
          column.hasFilter = true;
      }
      child.reportTable[column.id+'resetFilter'] = reset;
      this.addorRemoveFilter(dateFilter.text, column, {}, child)
  }

  updateIcamTotals(child:any) {
      for (let index in child.reportTable.icamTotals) {
        child.reportTable.icamTotals[index] = 0;
      }
      for (let i in child.reportTable.reportsListFilter) {
          let item:any = child.reportTable.reportsListFilter[i];
          for (let j in child.reportTable.icamTotals) {
              child.reportTable.icamTotals[j] += item[j];
          }
      }
  }

  orderBySearch(newValue:any, type:string, child:any) {
      let that:any = child.reportTable;
      that[type + 'WordFilter'] = newValue;
      if (that.detailsView) {
          that.detailsFilter = this.filterColumnService.filterByWord(newValue, that.details, type);
      } else {
          that.reportsListFilter = this.filterColumnService.filterByWord(newValue, that.icamYears[that.currentIcamYear], type);
      }
  }

  selectTypeOperation(item:any, column:any, child:any) {
      let that:any = child.reportTable;
      that[column.id + 'ListFilter'] = this.filterColumnService.configureFilterList(that[column.id + 'ListFilter'], item);
      if (child.skin === 'icam-red') {
          item.selected = !item.selected;
          if (that[column.id + 'Filter']) {
              that[column.id + 'Filter'].hasFilter = that[column.id + 'ListFilter'].length;
          }
          this.filterReports(null, child);
          this.updateIcamTotals(child);
      } else {
          column.hasFilter = that[column.id + 'ListFilter'].length;
          this.addorRemoveFilter(item.name, column, item, child);
      }
  }

  filterReports(filterList:any, child:any) {
      let that:any = child.reportTable;
      if (child.skin === 'icam-red') {
          if (that.detailsView) {
              if (filterList == null) {
                  filterList = that.details;
              }
          } else {
              filterList = that.icamYears[that.currentIcamYear];
          }
      } else {
          filterList = that.report.rows;
      }
      that.findFilters.forEach((findFilter:any) => {
          filterList = this.filterColumnService.filterByWord(that[findFilter+'ElementValue'],  filterList, findFilter);
      });
      that.filters.forEach((filter:any) => {
          let filteredList:any = that[filter+'ListFilter'];
          filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
      that.dateFilters.forEach((dateFilter:any) => {
          if(child.skin ==='icam-red'){
            filterList = this.filterColumnService.filterByCalendar(filterList, dateFilter);
          }else{
            let dates: any = {
              from: child.reportTable[dateFilter+'FromDate'],
              to: child.reportTable[dateFilter+'ToDate'],
            }
            filterList = this.filterColumnService.filterByRangeDates(filterList, dateFilter, dates);
          }

      });
      that.detailsView? that.detailsFilter = filterList : that.reportsListFilter = filterList;
  }

  addorRemoveFilter(text:any, column:any, source:any, child:any){
      let that:any = child.reportTable;
      let hasInArray : any = false;
      for(let i = 0; i < that.listFilter.length; i++){
          let filter : any = that.listFilter[i]
          if(column.id === filter.column.id ){
              if(filter.text === text && column.filterType === 'listFind'){
                  this.deleteFromListFilter(column, text, child);
                  that.listFilter.splice(i, 1);
                  hasInArray = true;
                  source.selected = !source.selected;
                  column.hasFilter = that[column.id+'ListFilter'].length;
                  break;
              }else if( column.filterType === 'find' ){
                  column.hasFilter = that[column.id+'ElementValue'] !== '';
                  that.listFilter.splice(i, 1);
                  hasInArray = that[column.id+'ElementValue'] === '';
                  break;
              }else if( column.filterType === 'date' ){
                  that.listFilter.splice(i, 1);
                  hasInArray = true;
              }
          }
      }

      if(!hasInArray){
          that.listFilter.push({
              column: column,
              text: text,
              source: source
          });
          if(source){
              source.selected = !source.selected;
          }
      }
      this.filterReports(that.listFilter, child);
  }

  configureId(name:string){
    if(name !== 'conceptObjectId'){
        let from : any = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇçºª?¿";
        let to : any = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunnccoa--";
        let newName : any = "";
        let nameWithoutSpace : any = name.replace(/ /g, "").toLowerCase();
        for(let i = 0; i < nameWithoutSpace.length; i++){
          newName += from.indexOf(nameWithoutSpace.charAt(i)) === -1 ? nameWithoutSpace.charAt(i) : to[from.indexOf(nameWithoutSpace.charAt(i))];
        }
        return newName;
    }else{
        return name;
    }
  }

  deleteFromListFilter(column:any, text:any, child:any){
      let index: number = child.reportTable[column.id+'ListFilter'].findIndex((columnListFilter:any)=>{return this.configureId(columnListFilter.name) === this.configureId(text)});
      if(index !== -1){
          child.reportTable[column.id+'ListFilter'].splice(index, 1)
      }
  }

  addNewFilter(column:any, child:any){
      let that: any = child.reportTable
      if(that[column.id+'ElementValue'] !== '' && that[column.id+'ElementValueOld'] !== that[column.id+'ElementValue']){
          column.hasFilter = that[column.id+'ElementValue'] !== '';
          this.addorRemoveFilter(that[column.id+'ElementValue'], column, {}, child);
          that[column.id+'ElementValueOld'] = that[column.id+'ElementValue'];
      }
  }

  deleteFilter(filter:any, child:any){
      switch(filter.column.filterType){
          case 'listFind':
              this.addorRemoveFilter(filter.text, filter.column, filter.source, child);
              break;
          case 'date':
              child.reportTable[filter.column.id+'resetFilter'] = true;
              break;
          default:
              child.reportTable[filter.column.id+'ElementValue'] = '';
              child.reportTable[filter.column.id+'ElementValueOld'] = '';
              this.addorRemoveFilter(child.reportTable[filter.column.id+'ElementValue'], filter.column, {}, child );
              break;
      }

  }

  clearAllFilters(child:any){
    child.reportTable.listFilter.forEach((filter:any) => {
      this.deleteFilter(filter, child)
    });
  }

  selectManager(managerName:any, child:any) {
      child.reportTable.selectedManagerName = managerName;
      this.updateIcamTotals(child);
  }

  orderTable(column:any, child:any){
      if(child.reportTable.orderByColumn.id===column.id){
          child.reportTable.orderAsc = !child.reportTable.orderAsc;
      }else{
          child.reportTable.orderByColumn = column;
          child.reportTable.orderAsc = false;
      }
  }

  changeCalendar(dates:any, type:any, child:any) {
      let that:any = child.reportTable
      let filterList : any;
      that[type + 'FromDate'] = dates.from;
      that[type + 'ToDate'] = dates.to;
      that[type + 'Open'] = false;
      that[type + 'Filter'] = dates.type === 'apply';
      if(child.skin==="icam-red"){
        if (dates.type === 'apply' && ((type === 'creationDate' && that.modificationDateFilter) || (type === 'modificationDate' && that.creationDateFilter))) {
          filterList = this.filterColumnService.filterByRangeDates(that.detailsFilter, type, dates);
        } else if (dates.type === 'cancel' && ((type === 'creationDate' && that.modificationDateFilter) || (type === 'modificationDate' && that.creationDateFilter))) {
            let dateModification:any = {
                from: type === 'modificationDate' ? that.creationDateFromDate : that.modificationDateFromDate,
                to: type === 'modificationDate' ? that.creationDateToDate : that.modificationDateToDate
            }
            filterList = this.filterColumnService.filterByRangeDates(that.details, type, dateModification);
        } else {
            filterList = this.filterColumnService.filterByRangeDates(that.details, type, dates);
        }
      }

      this.filterReports(filterList, child);
  }



  selectIcamYear(year:any, child:any) {
      let that:any = child.reportTable
      if (year != that.currentIcamYear) {
          that.reportsListFilter = that.report.reportId === 11?that.icamYearsFilter[year] : that.icamYears[year];
          that.currentIcamYear = year;
          this.extractFilterType(that.report.columns[0], child);
          this.updateIcamTotals(child);
      }
  }



  showFragments(publicationRow:any, child:any) {
      let that:any = child.reportTable
      publicationRow != null? this.getDetails('icam/reports/' + that.report.reportId + '/' + publicationRow.idPublicacion, publicationRow.titulo, child) :  that.detailsView = false;

  }

  showUserQueries(userRow:any, child:any) {
      if ( userRow?.idUsuario != null && userRow?.idUsuario > 0 && child.reportTable.totalConsultasUsuario[userRow.nombreUsuario] > 0) {
          this.getDetails('icam/conceptobjects/consultas/byusuarioconceptobjectId/' + userRow.idUsuario, userRow.nombreUsuario, child);
      } else {
          child.reportTable.detailsView = false;
      }
  }

  getDetails(url:string, detailsName:any, child:any) {
      this.loader.openLoader('report-table-get-details')
      this.apiService.get(url)
          .then((data:any) => {
              child.reportTable.detailsFilter = child.reportTable.details = data;
              child.reportTable.detailsName = detailsName;
              child.reportTable.detailsView = true;
              this.configureColumns(child);
              this.loader.closeLoader(['report-table-get-details']);
          }, ()=>{});
  }


  convertMinutes(minutes:any, child:any) {
      let hours : any = Math.floor(minutes / 60);
      let days : any = Math.floor(hours / 24);
      minutes = minutes - (hours * 60);
      hours = hours - (days * 24);
      return days + 'd ' + hours + 'h ' + Math.round(minutes) + 'm';
  }

  configureIcamInfo(hasManager:any, child:any) {
      let that: any = child.reportTable;
      this.configureIcamTotals(child);
      this.commonUtilsService.setMomentLanguage();
      that.icamYears = {};

      switch (that.report.reportId) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 7:
          case 8:
          case 9:
          case 10:
          case 12:
              that.months = moment.months();
              that.report.rows.forEach((row:any) => {
                  let year = row.year;
                  that.icamYears[year] = that.icamYears[year]? that.icamYears[year] : [];
                  row.monthName = that.months[row.month - 1];
                  that.icamYears[year].push(row);

              });
              that.report.columns[0] = { canFilter: true, fieldTypeId: 'list', id: 'monthName' };
              break;
          case 11:
              that.managers = [];
              that.months = moment.months();
              that.report.rows.forEach((row:any) => {
                  let year = row.year;
                  that.icamYears[year] = that.icamYears[year]? that.icamYears[year] : [];
                  row.monthName = that.months[row.month - 1];
                  let index:number = that.managers.findIndex((manager:any)=>{return manager === row.gestor});
                  if (index === -1) {
                      that.managers.push(row.gestor);
                  }
                  let copyRow : any = cloneDeep(row)
                  if (!(row.gestor === that.selectedManagerName || row.gestor === null || that.selectedManagerName === '')){
                      Object.keys(copyRow).forEach(key => {
                          if(key !== 'gestor' && key !== 'monthName'&& key !== 'month'&& key !== 'year'){
                              copyRow[key] = 0;
                          }
                      })
                      copyRow.gestor = null;
                  }
                  that.icamYears[year].push(copyRow);
              })

              that.report.columns[0] = { canFilter: true, fieldTypeId: 'list', id: 'monthName' };
              that.report.columns[1] = { canFilter: true, fieldTypeId: 'list', id: 'gestor' };
              that.icamYearsFilter = this.configureIcamYears(child)
              break;
          case 5:
              that.months = moment.months();
              that.report.rows.forEach((row:any) => {
                  let year = row.year;
                  that.icamYears[year] = that.icamYears[year]? that.icamYears[year] : [];
                  let tableRowObject = this.configureUsersInfo(row, 'perfilUsuario', child);
                  if (!tableRowObject) {
                      that.icamYears[year].push(tableRowObject = {
                          perfilUsuario: row.perfilUsuario,
                          meses: [],
                          totalConsultas: 0,
                          totalUsuarios: row.totalUsuariosYear
                      });
                  }
                  tableRowObject.meses[row.month - 1] = {
                      totalConsultas: row.totalConsultas,
                      totalUsuarios: row.totalUsuarios
                  };
                  tableRowObject.totalConsultas += row.totalConsultas;
              })
              that.report.columns[0] = { canFilter: true, fieldTypeId: 'list', id: 'perfilUsuario' };
              break;
          case 6:
              that.months = moment.monthsShort();
              that.totalConsultasUsuario = {};
              that.report.rows.forEach((row:any) => {
                  let year = row.year;
                  that.icamYears[year] = that.icamYears[year]? that.icamYears[year] : [];
                  let tableRowObject = this.configureUsersInfo(row, 'nombreUsuario', child);
                  if (!tableRowObject) {
                      that.icamYears[year].push(tableRowObject = {
                          idUsuario: row.idUsuario,
                          nombreUsuario: row.nombreUsuario,
                          meses: [],
                          totalConsultasAnyo: 0,
                          year: year
                      });
                  }
                  tableRowObject.meses[row.month - 1] = row.totalConsultas;
                  tableRowObject.totalConsultasAnyo += row.totalConsultas;
                  if (that.totalConsultasUsuario[row.nombreUsuario] == null) {
                      that.totalConsultasUsuario[row.nombreUsuario] = 0;
                  }
                  that.totalConsultasUsuario[row.nombreUsuario] += row.totalConsultas;
              })
              let columns:any = that.report.columns;
              columns[0] = { canFilter: true, fieldTypeId: 'list', id: 'nombreUsuario', filterType: 'list' };
              columns[1] = { canFilter: true, fieldTypeId: 'find', id: 'referencia' };
              columns[2] = { canFilter: true, fieldTypeId: 'list', id: 'tipo', filterType: 'list' };
              columns[3] = { canFilter: true, fieldTypeId: 'date', id: 'creationDate' };
              columns[4] = { canFilter: true, fieldTypeId: 'list', id: 'step', filterType: 'list' };
              columns[5] = { canFilter: true, fieldTypeId: 'list', id: 'materia', filterType: 'list' };
              columns[6] = { canFilter: true, fieldTypeId: 'date', id: 'modificationDate' };
              columns[7] = { canFilter: true, fieldTypeId: 'find', id: 'consulta' };
              break;
          case 13:
              that.report.columns[0] = { canFilter: true, fieldTypeId: 'find', id: 'titulo' };
              that.currentIcamYear = 0;
              that.icamYears[that.currentIcamYear] = that.report.rows;
              break;
          default:
              that.currentIcamYear = 0;
              that.icamYears[that.currentIcamYear] = that.report.rows;
              break;
      }
      that.listYears = [];
      Object.keys(that.icamYears).forEach(key => {
          that.listYears.push(key);
      })
      that.currentIcamYear = hasManager? that.currentIcamYear : that.listYears[that.listYears.length - 1]
      that.reportsListFilter = that.report.reportId === 11? that.icamYearsFilter[that.currentIcamYear] : that.icamYears[that.currentIcamYear];
      that.filters = [];
      this.extractFilterType(that.report.columns[0], child);
  }

  configureIcamTotals(child:any){
      let that: any = child.reportTable
      switch (that.report.reportId) {
          case 1:
              that.icamTotals = { finalizadaPendienteDePago: 0, pagada: 0, finalizadaPagoNoAceptado: 0 };
              break;
          case 2:
              that.icamTotals = { numeroConsultas: 0, totalFacturas: 0 };
              break;
          case 3:
          case 4:
              that.icamTotals = { numeroConsultas: 0, totalMinutos: 0 };
              break;
          case 7:
              that.icamTotals = { consultas: 0, telefonico: 0, web: 0, correoElectronico: 0, presencial: 0, otros: 0 };
              break;
          case 8:
              that.icamTotals = { consultas: 0, ijd: 0, bajoDemanda: 0, formacion: 0 };
              break;
          case 9:
              that.icamTotals = { consultas: 0, jurisprudencia: 0, legislacion: 0, formularios: 0, referenciaBibliografia: 0, referenciaDoctrina: 0 };
              break;
          case 10:
              that.icamTotals = { consultas: 0, civil: 0, constitucional: 0, contenciosoAdministrativo: 0, laboral: 0, mercantil: 0, militar: 0, penal: 0, otros: 0 };
              break;
          case 11:
              that.icamTotals = { consultasResueltas: 0, jurisprudencia: 0, legislacion: 0, formularios: 0, referenciaBibliografia: 0, referenciaDoctrina: 0 };
              break;
          case 12:
              that.icamTotals = { fragmentos: 0, libro: 0, revista: 0, otro: 0 };
              break;
      }
  }


  configureUsersInfo (row:any, property:any, child:any) {
    let icamYear : any = child.reportTable.icamYears[row.year];
    let index: number = icamYear.findIndex((tableRowObject:any)=>{
      return tableRowObject[property] === row[property]
    });
    return index === -1?null : icamYear[index]
  };

  configureIcamYears(child:any){
      let icamYearsCopy = cloneDeep(child.reportTable.icamYears);
      child.reportTable.listYears.forEach((year:any) => {
          for(let j = icamYearsCopy[year].length - 1; j > 0; j--){
              let actual : any = icamYearsCopy[year][j];
              let prev : any = icamYearsCopy[year][j-1];
              if(actual.year === prev.year && actual.monthName === prev.monthName){
                  Object.keys(actual).forEach(key => {
                      if(key !== 'gestor' && key !== 'monthName'&& key !== 'month'&& key !== 'year'){
                          prev[key] += actual[key]
                      }
                  })
                  icamYearsCopy[year].splice(j, 1)
              }
          }
      });
      return icamYearsCopy;
  }


}
