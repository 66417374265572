import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'process-edit-modal',
  templateUrl: './process-edit-modal.component.html'
})
export class ProcessEditModalComponent {
  constructor(private globalCfg:GlobalCfgFactory, private activeModal:NgbActiveModal) { }

  @Input() evaluationId:any;
  @Input() concept:any;

  skin:string = this.globalCfg.skin;
  processEditModal:any = {
    showInfo: true,
    modal: null
  };

  processUpdated(e:any) {
    let data: any = e.data;
    this.activeModal.close({
      result: 'ok',
      data: data
    })
  }

  handleDismiss() {
    this.activeModal.close({result: 'cancel'});
  }
}
