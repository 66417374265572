<div class="advice-modal">
  <div [class]="'advice-modal__modal-body advice-modal__modal-body--'+adviceModal.typeModal">
      <div class="advice-modal__modal-icon-block">
          <i [class]="'advice-modal__modal-body-icon '+adviceModal.confirmQuestionIcon"></i>
      </div>
      <div class="advice-modal__modal-text-block"
        [ngClass]="{'advice-modal__modal-text-block--expand': expand}">
          <p class="advice-modal__modal-body-text"
            [ngClass]="{'advice-modal__modal-body-text--expand': expand}">
            <span *ngIf="adviceModal.typeText === 'normal'" [innerHTML]="questionText"></span>
            <span *ngIf="adviceModal.typeText === 'link'">
                <span [innerHTML]="adviceModal.firstQuestionText"></span>
                <span class="advice-modal__modal-body-text-link"
                  (click)="openInfoModal()">{{adviceModal.buttonLink}}</span>
                <span [innerHTML]="adviceModal.lastQuestionText"></span>
            </span>
          </p>
      </div>
      <div class="advice-modal__buttons-block">
          <button class="common__button common__button--white"
                  [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm',
                    'common__button--white-red common__button--white-red':adviceModal.typeModal==='error'}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.ok'">
                  Aceptar
          </button>
      </div>
  </div>
  <div class="advice-modal__review-pending-block">
    <div class="advice-modal__review-pending-header">
      <p class="advice-modal__review-pending-text-header"
        [afTranslate]="'advise-list-modal.review-pending'"></p>
    </div>
    <div class="advice-modal__review-pending-item-block"
      *ngFor="let item of list">
        <p class="advice-modal__review-pending-item-title">
          {{item.title}}
          <span class="advice-modal__review-pending-item-email">({{item.oauthUser}})</span>
        </p>
        <i [ngShow]="item.showIcon"
          class="lf-icon-map-marker advice-modal__review-pending-breadcrumb-icon"></i>
        <p class="advice-modal__review-pending-breadcrumb-text">{{item.breadcrumb}}</p>
    </div>
  </div>
</div>
