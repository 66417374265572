import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class EvaluationTreeResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
      private loader: LoaderFactory) { }

    resolve(route: ActivatedRouteSnapshot) {
      // if(this.route.snapshot.params['to'] !== 'template'){
          this.loader.openLoader('evaluation-info-tree');
      // }
      return this.apiService.getWithTranslate('tree/translated/'+route.params['evaluationId'])
        .then((data:any)=>{
          return data;
        })
    }
}
