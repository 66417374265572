<div class="users-admin-security-form">
  <!-- <div class="common__table-block"> -->
    <div class="common__table-new-version users-admin-security-form__table-permissions">
      <div class="common__table-header">
        <div class="users-admin-security-form__col users-admin-security-form__col--product">
            <span-tooltip class="common__table-header-text"
              [text]="'users-admin.security-form.columns.product-access' | afTranslate"></span-tooltip>
        </div>
        <div class="users-admin-security-form__col users-admin-security-form__col--client">
            <span-tooltip class="common__table-header-text"
              [text]="'users-admin.security-form.columns.client-access' | afTranslate"></span-tooltip>
        </div>
        <div class="users-admin-security-form__col users-admin-security-form__col--permission"
            *ngFor="let permission of usersAdminSecurityForm.permissions">
            <div class="common__table-header-text users-admin-security-form__table-header-text">
              <span-tooltip class="users-admin-security-form__info-text"
                [text]="permission.caption"></span-tooltip>
              <div class="users-admin-security-form__info">
                  <i class="lf-icon-information users-admin-security-form__info-icon"></i>
                  <div [class]="'common__tooltip-info users-admin-security-form__tooltip-info users-admin-security-form__tooltip-info--'+permission.id">
                      <div class="users-admin-security-form__tooltip-inner-text">{{permission.info}}</div>
                  </div>
              </div>
            </div>
        </div>
        <div class="users-admin-security-form__col users-admin-security-form__col--permission-read">
            <span-tooltip class="common__table-header-text users-admin-security-form__table-header-text"
              [text]="'users-admin.security-form.columns.read' | afTranslate"></span-tooltip>
        </div>
      </div>
      <div class="users-admin-main__table-body">
        <div *ngFor="let item of usersAdminSecurityForm.items">
            <div class="users-admin-security-form__table-row">
              <div class="users-admin-security-form__col users-admin-security-form__col--product">
                  <field-type-switch [label]="item.product.name"
                                      [checked]="item.selected"
                                      [disabled]="item.group || item.disabled || item.disabledCheckProduct"
                                      (callback)="itemSelected(item, $event.value)">
                  </field-type-switch>
              </div>
              <div class="users-admin-security-form__col users-admin-security-form__col--client">
                  <div class="users-admin-security-form__clients-list"
                      [ngClass]="{'users-admin-security-form__clients-list--enabled': !(!item.selected || item.disabled || item.disabledAllClients),
                          'users-admin-security-form__clients-list--open': item.clientsList}">
                      <div class="users-admin-security-form__clients-list-drop"
                          [ngClass]="{'users-admin-security-form__clients-list-drop--enabled-close': !(!item.selected || item.disabled || item.disabledAllClients) && !item.clientsList}"
                          (click)="openClientsList(item)">
                          <span class="users-admin-security-form__clients-list-default-text"
                                [afTranslate]="'users-admin.security-form.select-clients'"
                                [ngShow]="(!item.selected || (item.clientsList && !item.clients.length)) && !item.productAccessMask">Seleccionar clientes</span>
                          <span class="users-admin-security-form__clients-list-selection-text common__ellipsis"
                                [ngShow]="item.clients.length && !item.productAccessMask">
                              <span [afTranslate]="'users-admin.security-form.clients-selection'">Selección de clientes</span>
                          </span>
                          <span [afTranslate]="'users-admin.security-form.all-clients'"
                              class="users-admin-security-form__all-client-dropdown"
                              [ngShow]="(item.selected && !item.clients.length && !item.clientsList) || item.productAccessMask">Todos los clientes presentes y futuros</span>
                          <div class="users-admin-security-form__buttons-right">
                              <div class="users-admin-security-form__clients-list-buttons">
                                  <span class="users-admin-security-form__pillow"
                                      [ngClass]="{'users-admin-security-form__pillow--open': item.clientsList}"
                                      [ngShow]="item.clientsCount && !item.productAccessMask">{{item.clientsCount}}</span>
                                  <span class="users-admin-security-form__pillow group"
                                      [ngClass]="{'users-admin-security-form__pillow--open': item.clientsList}"
                                      [ngShow]="item.groupsCoun && !item.productAccessMaskt">{{item.groupsCount}}</span>
                                  <button class="lf-icon-close common__button-close"
                                      (click)="closeClientsList($event)"
                                      [ngShow]="item.clientsList"></button>
                                  <button class="lf-icon-check common__button-checked"
                                      (click)="selectClientsOk(item, $event)"
                                      [ngShow]="item.clientsList"></button>
                              </div>
                              <button class="lf-icon-angle-down common__icon-button users-admin-security-form__angle-down-icon"
                                      [ngHide]="item.clientsList || item.productAccessMask"
                                      [disabled]="!item.selected || item.disabled || item.disabledAllClients">
                              </button>
                          </div>
                      </div>
                      <div class="users-admin-security-form__clients-list-down"
                          *ngIf="item.clientsList">
                          <div class="users-admin-security-form__clients-list-search">
                              <div class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input common__multiselect-dropdown-item--margin-0">
                                  <input type="text"
                                      class="common__dropdown-inner-input"
                                      [placeholder]="'common.search' | afTranslate"
                                      [(ngModel)]="usersAdminSecurityForm.clientsList.filter.name">
                                  <i class="lf-icon-search common__search-icon"
                                      [ngClass]="usersAdminSecurityForm.clientsList.filter.name?'lf-icon-close-search common__search-icon--reset':'lf-icon-search'"
                                      (click)="toggleSearch()"></i>
                              </div>
                          </div>
                          <ul class="users-admin-security-form__clients-list-ul">
                              <li class="users-admin-security-form__clients-list-li"
                                  [attr.disabled]="item.disabledSelectAll?true:null">
                                  <label [attr.disabled]="item.disabledSelectAll?true:null">
                                      <input type="checkbox"
                                            [(ngModel)]="usersAdminSecurityForm.clientsList.none"
                                            (change)="clearClients(item)"
                                            [disabled]="item.disabledSelectAll"
                                            class="common__hide">
                                            <!-- (ngModelChange)="clearClients(item)" -->
                                      <i class="users-admin-security-form__check-icon-list"
                                        [ngClass]="usersAdminSecurityForm.clientsList.none?'lf-icon-check-full':'lf-icon-box-inactive'"
                                        [attr.disabled]="item.disabledSelectAll?true:null"></i>
                                      <div class="common__table-display users-admin-security-form__cell-block">
                                          <div class="common__table-cell-tooltip">
                                              <div class="common__cell-block">
                                                <span-tooltip [text]="usersAdminSecurityForm.checkAll"></span-tooltip>
                                              </div>
                                          </div>
                                      </div>
                                  </label>
                              </li>
                              <li class="users-admin-security-form__clients-list-li-all">
                                  <label>
                                      <input type="checkbox"
                                        [(ngModel)]="usersAdminSecurityForm.clientsList.all"
                                        (click)="selectAllClients(item)"
                                        class="common__hide">
                                      <i class="users-admin-security-form__check-icon-list"
                                        [ngClass]="usersAdminSecurityForm.clientsList.all?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                      <div class="common__table-display users-admin-security-form__cell-block">
                                          <div class="common__table-cell-tooltip common__table-cell-tooltip--second">
                                              <div class="common__cell-block">
                                                  <span-tooltip [text]="usersAdminSecurityForm.checkUncheck"></span-tooltip>
                                              </div>
                                          </div>
                                      </div>
                                  </label>
                                  <span class="users-admin-security-form__clients-list-count">{{item.clientsList.length}}</span>
                              </li>
                              <!-- <div class="users-admin-security-form__clients-list-li"
                                *ngFor="let client of usersAdminSecurityForm.clientsList.items | byWord:usersAdminSecurityForm.clientsList.filter.name:['name']">
                                <button [disabled]="client.group || item.disabled || client.disabled"
                                  (click)="selectClient(client, item)">
                                  <i class="users-admin-security-form__check-icon-list"
                                    [ngClass]="client.selected?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                  <span class="users-admin-security-form__cell-block-security ">
                                      <span-tooltip [text]="client.name"></span-tooltip>
                                  </span>
                                  <i class="lf-icon-check-round-full users-admin-security-form__icon-hover-check-list"></i>
                                </button>
                              </div> -->
                              <li class="users-admin-security-form__clients-list-li"
                                  *ngFor="let client of usersAdminSecurityForm.clientsList.items | byWord:usersAdminSecurityForm.clientsList.filter.name:['name']"
                                  [attr.disabled]="(client.group || item.disabled || client.disabled)?true:null">
                                  <label [attr.disabled]="(client.group || item.disabled || client.disabled)?true:null">
                                      <input type="checkbox"
                                          [disabled]="client.group || item.disabled || client.disabled"
                                          [(ngModel)]="client.selected"
                                          (change)="selectClient(client, item)"
                                          class="common__hide">
                                      <i class="users-admin-security-form__check-icon-list"
                                        [ngClass]="client.selected?'lf-icon-check-full':'lf-icon-box-inactive'"
                                        [attr.disabled]="(client.group || item.disabled || client.disabled)?true:null"></i>
                                      <div class="common__table-display users-admin-security-form__cell-block users-admin-security-form__cell-block--repeat">
                                          <div class="common__table-cell-tooltip">
                                              <div class="users-admin-security-form__cell-block-security">
                                                  <span-tooltip [text]="client.name"></span-tooltip>
                                              </div>
                                          </div>
                                          <i class="lf-icon-check-round-full users-admin-security-form__icon-hover-check-list"></i>
                                      </div>
                                  </label>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="users-admin-security-form__col users-admin-security-form__col--permission"
                *ngFor="let permission of usersAdminSecurityForm.permissions; let index = index">
                  <button *ngIf="operation !== 'see-security' && !item.groupsPermissions[index]"
                      class="common__checkbox users-admin-security-form__checkbox"
                      [ngClass]="item.permissions[index] ? 'lf-icon-check-full':'lf-icon-box-inactive'"
                      [disabled]="!item.selected || item.disabled"
                      (click)="permissionChange(index, item)"></button>

                  <i class="lf-icon-group users-admin-security-form__icon-groups"
                      *ngIf="item.groupsPermissions[index]"
                      matTooltip="{{'users-admin.security-form.tooltip-locked-permission' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"></i>
                  <i class="lf-icon-check users-admin-security-form__table-check-icon"
                    *ngIf="operation === 'see-security' && (item.permissions[index] || item.groupsPermissions[index])"></i>
              </div>
              <div class="users-admin-security-form__col users-admin-security-form__col--permission-read">
                  <i class="lf-icon-check users-admin-security-form__table-check-icon"></i>
              </div>
            </div>
            <div *ngIf="item.clients && item.clients.length && !item.productAccessMask">
              <div *ngFor="let client of item.clients | afOrderBy:['name']; let last = last">
                <div [ngClass]="last?'users-admin-security-form__table-row':'users-admin-security-form__table-row-client'">
                  <div class="users-admin-security-form__col users-admin-security-form__col--product"></div>
                  <div class="users-admin-security-form__col users-admin-security-form__col--client">
                    <div class="users-admin-security-form__client-name">
                      <span-tooltip [text]="client.name"></span-tooltip>
                      <button class="lf-icon-close common__icon-button users-admin-security-form__icon-button"
                          (click)="removeClient(item, client)"
                          [ngHide]="client.group || item.disabled || client.disabledRemove">
                      </button>
                    </div>
                  </div>
                  <div class="users-admin-security-form__col users-admin-security-form__col--permission"
                    *ngFor="let permission of usersAdminSecurityForm.permissions; let index = index">
                    <button *ngIf="operation !== 'see-security' && !client.groupsPermissions[index]"
                        class="common__checkbox users-admin-security-form__checkbox"
                        [ngClass]="client.permissions[index]? 'lf-icon-check-full':'lf-icon-box-inactive'"
                        [disabled]="!item.selected || item.disabled"
                        (click)="permissionChange(index, item, client)"></button>
                    <i class="lf-icon-group users-admin-security-form__icon-groups"
                        *ngIf="client.groupsPermissions[index]"
                        matTooltip="{{'users-admin.security-form.tooltip-locked-permission' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above"
                        ></i>
                    <i class="lf-icon-check users-admin-security-form__table-check-icon"
                        *ngIf="operation === 'see-security' && (item.permissions[index] || item.groupsPermissions[index])"></i>
                  </div>
                  <div class="users-admin-security-form__col users-admin-security-form__col--permission-read">
                      <i class="lf-icon-check users-admin-security-form__table-check-icon"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
  <div class="common__pagination-count">
      <span [afTranslate]="'common.total'">_Total</span>
      <span class="common__total-number-element notranslate">{{usersAdminSecurityForm.items.length}}</span>
  </div>
</div>
