<div class="process-modal process-modal--normal"
    [ngClass]="{'process-modal--preview': configuration.preview}">
    <header class="common__modal-header-block common__modal-header-block--margin-bottom">
        <i class="lf-icon-process common__icon-header"></i>
        <span class="common__text-header">{{processModal.process.title}}</span>
        <div class="process-modal__toolbar">
            <button class="process-modal__button-header"
                [ngShow]="processModal.process.description"
                (click)="processDescriptionView()">
                <i class="lf-icon-information process-modal__icon-button-header"></i>
                <span [afTranslate]="'process.modal.information'"></span>
            </button>
            <button class="process-modal__button-header"
                [ngShow]="processModal.process.references.length"
                (click)="processReferencesView()">
                <i class="lf-icon-legal-reference process-modal__icon-button-header"></i>
                <span [afTranslate]="'process.modal.legal-reference'"></span>
            </button>
            <div class="process-modal__vline"></div>
            <button class="process-modal__button-header process-modal__close" (click)="handleDismiss()">
                <span *ngIf="configuration.preview" [afTranslate]="'notification-preview.close'"></span>
                <i class="lf-icon-close common__icon-close-modal"></i>
            </button>
        </div>
    </header>
    <main class="process-modal__main"
        [ngClass]="{'process-modal__main--full': processModal.view === 'next-success'}">
        <div class="process-modal__docs-toolbar"
            *ngIf="processModal.isConceptObject && !processModal.view">
            <button class="lf-icon-send common__button-checked"
                (click)="exportProcess()"
                matTooltip="{{'common.export-process' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
            </button>
            <button class="lf-icon-notification-new common__button-checked"
                (click)="addAlertModal()"
                matTooltip="{{'expanded-menu.add-alert' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
            </button>
            <button class="lf-icon-up-file common__button-checked"
                (click)="uploadDocument()"
                matTooltip="{{'documentation-list.upload-button' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
            </button>
            <button class="lf-icon-sum common__button-checked"
                (click)="addDocument()"
                matTooltip="{{'documentation-list.create-button' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
            </button>
        </div>
        <rappid [ngHide]="processModal.view"
            class="common__height-100"
            [component]="processModal.rappid"
            [model]="processModal.model"
            [canAddAlerts]="processModal.canAddAlerts"
            [from]="'view'"
            (onAction)="onCellAction($event)"
            id="rappid-process-modal">
        </rappid>
        <div *ngIf="processModal.view === 'next'"
            class="process-modal__next__layer">
            <h2 class="common__underlined-title">
                <i class="lf-icon-arrow-round-right"></i>
                <span [afTranslate]="'process.modal.forward-title1'"></span>
                <span class="common__underlined-title--smaller" [afTranslate]="'process.modal.forward-title2'"></span>
            </h2>
            <div class="process-modal__next__fields">
                <field-type-list-simple [field]="processModal.nextStateFieldInfo"
                    [listValues]="processModal.nextStateFieldList"
                    [preSelectItem]="processModal.nextStateSelected"
                    (itemSelected)="processModal.nextStateSelected = $event.newValue;">
                </field-type-list-simple>
            </div>
        </div>
        <div class="common__height-100 common__min-scroll process-modal__description__layer" *ngIf="processModal.view === 'description'">
            <h2 class="common__underlined-title">
                <i class="lf-icon-information"></i>
                <span>{{processModal.title}}</span>
            </h2>
            <div class="process-modal__description__text" [innerHTML]="processModal.description"></div>
        </div>
        <div class="container-fluid process-modal__references" *ngIf="processModal.view === 'references'">
            <legal-document [references]="processModal.references"
              [selected]="processModal.selectedReference"></legal-document>
        </div>
    </main>
    <div class="process-modal__footer">
        <div class="process-modal__footer__legend" [ngHide]="processModal.view || processModal.errorText">
            <field-type-switch [label]="'process.modal.show-legend'|afTranslate"
                [checked]="processModal.showLegend"
                (callback)="processModal.showLegend = $event.value">
            </field-type-switch>
            <ul class="process-modal__footer__legend__list"
                [ngShow]="processModal.showLegend"
                *ngIf="processModal.isConceptObject">
                <li>
                    <i class="lf-icon-step-done process-modal__state-icon--green"></i>
                    <span [afTranslate]="'process.modal.legend.transition-date'"></span>
                </li>
                <li [ngShow]="skin !== 'icam-red'">
                    <i class="lf-icon-legal-reference process-modal__state-icon--blue"></i>
                    <span [afTranslate]="'process.modal.legend.legal-reference'"></span>
                </li>
                <li>
                    <i class="lf-icon-information process-modal__state-icon--blue"></i>
                    <span [afTranslate]="'process.modal.legend.information'"></span>
                </li>
                <li>
                    <i class="lf-icon-documentation process-modal__state-icon--blue"></i>
                    <span [afTranslate]="'process.modal.legend.document'"></span>
                </li>
                <li>
                    <i class="process-modal__state-icon--gray" [ngClass]="skin === 'dark-blue' ? 'lf-icon-notification-new' : 'lf-icon-notification'"></i>
                    <span [afTranslate]="'process.modal.legend.alert'"></span>
                </li>
            </ul>
            <ul class="process-modal__footer__legend__list"
                [ngShow]="processModal.showLegend"
                *ngIf="!processModal.isConceptObject">
                <li [ngShow]="skin !== 'icam-red'">
                    <i class="lf-icon-legal-reference process-modal__state-icon--blue"></i>
                    <span [afTranslate]="'process.modal.legend.legal-reference'"></span>
                </li>
                <li>
                    <i class="lf-icon-information process-modal__state-icon--blue"></i>
                    <span [afTranslate]="'process.modal.legend.information'"></span>
                </li>
                <li>
                    <i class="process-modal__state-icon--gray" [ngClass]="skin === 'dark-blue' ? 'lf-icon-notification-new' : 'lf-icon-notification'"></i>
                    <span [afTranslate]="'process.modal.legend.alert'"></span>
                </li>
            </ul>
        </div>
        <div class="process-modal__error pull-left" *ngIf="processModal.errorText" (click)="processModal.errorText = null">
            <i class="lf-icon-information process-modal__error-icon"></i>
            <span class="process-modal__error-text" [textContent]="processModal.errorText"></span>
        </div>
        <div *ngIf="processModal.isConceptObject" [ngHide]="processModal.view" class="process-modal__footer__buttons">
            <button type="reset" class="common__button common__button--clean"
                (click)="undoTransition()"
                [disabled]="!processModal.lastTransition || !configuration.canChangeState"
                [afTranslate]="'process.modal.go-back'"></button>
            <button type="submit" class="common__button common__button--active"
                (click)="next()"
                [disabled]="!processModal.nextTransitions || !configuration.canChangeState"
                [afTranslate]="'process.modal.forward'"></button>
        </div>
        <div *ngIf="configuration.canAddTask" class="process-modal__footer__buttons">
            <button type="submit" class="common__button common__button--active"
                *ngIf="!processModal.process.hasTask"
                (click)="addTask()"
                [afTranslate]="'process.modal.add-task'"></button>
            <div class="process-modal__has-task" *ngIf="processModal.process.hasTask" >
                <i class="lf-icon-task"></i>
                <span [afTranslate]="'process.modal.has-task'"></span>
            </div>
        </div>
        <div [ngShow]="processModal.view === 'next'" class="process-modal__footer__buttons">
            <button type="reset" class="common__button common__button--clean"
                (click)="processModal.view = null"
                [afTranslate]="'common.cancel'"></button>
            <button type="submit" class="common__button common__button--active"
                (click)="addTransition()"
                [afTranslate]="'common.ok'"></button>
        </div>
        <div [ngShow]="processModal.view === 'description' || processModal.view === 'references'" class="process-modal__footer__buttons">
            <button type="reset" class="common__button common__button--clean"
                *ngIf="processModal.view === 'description'"
                (click)="printAction()"
                [afTranslate]="'common.print'"></button>
            <button type="reset" class="common__button common__button--clean"
                [ngShow]="processModal.selectedReference"
                *ngIf="processModal.view === 'references'"
                (click)="printAction()"
                [afTranslate]="'common.print'"></button>
            <button type="reset" class="common__button common__button--clean"
                (click)="processModal.view = null"
                [afTranslate]="'common.return'"></button>
        </div>
    </div>
</div>
<div class="common__pdf-hidde-element"
  *ngIf="processModal.exportDate">
  <div class="common__pdf-block-view"
    id="pdf-process-modal">
    <pdf-header
        [datePdf]="processModal.exportDate">
    </pdf-header>
    <div class="common__pdf-body">
        <div class="common__pdf-title-block">
            <span [ngShow]="processModal.config.concept.titlePrefix !== null">{{processModal.config.concept.titlePrefix}} / </span>
            <span>{{processModal.config.concept.title}}</span>
        </div>
        <div class="common__pdf-subtitle-block">
            <span [afTranslate]="'process.modal.process-management'">_Gestión del proceso</span>
        </div>
        <div class="process-modal__pdf-body">
            <div class="process-modal__pdf-inner-body">
                <div id="rappid-pdf"></div>
            </div>
        </div>
    </div>
  </div>
</div>

