import { Directive, OnChanges, Input} from '@angular/core';

@Directive({
  selector: '[renderCanvas]'
})
export class RenderCanvasDirective implements OnChanges {

  @Input() renderCanvas: any;

  ngOnChanges(): void {
    $('#canvas-'+this.renderCanvas.width).append(this.renderCanvas.html);
  }

}
