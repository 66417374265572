import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EvaluationInfoFactory {
  evaluationInfo: any;
  evaluationId: any;

  setEvaluationInfo(evaluationInf: any){
    this.evaluationInfo = evaluationInf;
    this.evaluationId = this.evaluationInfo.evaluationId;
  }

  getEvaluationInfo(){
    return this.evaluationInfo;
  }

  getEvaluationId(){
    return this.evaluationId;
  }

  delete(){
    delete this.evaluationInfo;
    delete this.evaluationId;
  }
}
