import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'link-modal',
  templateUrl: './link-modal.component.html'
})
export class LinkModalComponent implements OnInit {
  linkModal: any = {
    url: '',
    textDisplay: '',
    title: '',
    target: '',
    link: '<a',
    prevLink: ''
  }
  constructor(private activeModal:NgbActiveModal) { }

  @Input() node:any;
  @Input() selectedContent:any;

  ngOnInit(): void {
    let that = this.linkModal;
    if (this.node) {
      that.url = $(this.node).attr('href');
      that.textDisplay = $(this.node).text();
      that.title = $(this.node).attr('title');
      that.target = $(this.node).attr('target')?$(this.node).attr('target'):'_blank';
    }else if(this.selectedContent){
      that.textDisplay = this.selectedContent;
    }
  }

  addLink(){
    let that = this.linkModal;
    that.urlError = (that.url.indexOf('https://') === -1);
    if( !that.urlError){
      if(that.textDisplay.trim() === ''){
          that.textDisplay = that.url;
      }
      if (that.url && $.trim(that.url) !== '') {
          that.link += ' href="' + that.url + '"';
      }
      if (that.title && $.trim(that.title) !== '') {
          that.link += ' title="' + that.title + '"';
      }
      that.link += ' target="' + that.target + '">' + that.textDisplay + '</a>';
      this.handleAccept();
    }
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      link: this.linkModal.link,
    });
  };

  handleDismiss() {
    this.activeModal.close({
      result: 'cancel'
    });
  };
}
