<div [ngSwitch]="taskConcept.state">
    <task-concept-list *ngSwitchCase="'list'"
        [concept]="taskConcept.concept"
        [taskList]="taskConcept.concept.taskList"
        [processTask]="taskConcept.concept.processTask"
        (changeView)="openOrCreatedTask($event)"
        (modifyTaskList)="modifyTaskListOn()">
    </task-concept-list>
    <task-edit-default *ngSwitchDefault
        [concept]="taskConcept.concept"
        [taskSelect]="taskConcept.taskSelect"
        [hasOpenEvaluationTask]="hasOpenEvaluationTask"
        [shared]="shared"
        [field]="field"
        [taskTypeIdSelect]="taskTypeIdSelect"
        (changeView)="changeView($event)"
        (modifyTaskList)="modifyTaskList($event)">
    </task-edit-default>
</div>
