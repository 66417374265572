<div class="common__field-type">
  <label class="common__input-block common__input-block--field"
    [ngClass]="{'common__input-block--show-references': fieldTypeText.references && field.evaluatedValue.conceptDataId !== null,
        'common__show-alert': field.error && !field.sequentialView,
        'common__input-no-editable':field.noEditable,
        'common__input-block--poll-view':field.poll && !field.noEditable && field.sequentialView,
        'common__input-block--custom-field': isLanding && !field.sequentialView}"
    [for]="'field-item-text-'+id"
    [attr.disabled]="field.noEditable?true:null">
    <div class="common__input-text-poll"
        *ngIf="field.sequentialView && !field.noEditable"
        [ngClass]="{'common__input-text-poll--error': field.error}">
        <p class="field-type-text__custom-sequential-label">{{fieldTypeText.titleField}}
            <span class="common__text-required common__text-required--sequential-view"
                [ngShow]="field.required">*</span>
            <tooltip-info [description]="field.description"
                [field]="field"
                [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView"></tooltip-info>
            <tooltip-law [references]="field.references"
                [field]="field"
                [ngShow]="field.references?.length"
                [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
            </tooltip-law>
        </p>
    </div>
    <div class="common__label-block"
        [ngClass]="field.type !== 'link' && field.evaluatedValue?.hasReferences && !field.poll?'common__label-block--two-icon':'common__label-block--one-icon'"
        *ngIf="!(field.sequentialView && !field.noEditable)">
        <span class="common__input-text"
            [ngClass]="{'common__input-text--required': field.error,
                    'common__input-text--no-editable':field.noEditable}">{{fieldTypeText.titleField}}</span>
        <span class="common__text-required"
            [ngShow]="field.required">*</span>
        <div class="common__info-icon-block common__info-icon-block"
            [ngHide]="field.noEditable || !field.description">
            <tooltip-info [description]="field.description"
                [field]="field"></tooltip-info>
        </div>
        <div class="common__tooltip-law-block"
            [ngShow]="field.references && field.references.length && !field.noEditable">
            <tooltip-law [references]="field.references"
                [field]="field"></tooltip-law>
        </div>
        <div class="common__tooltip-law-block"
            [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
            <i class="lf-icon-search-text field-item__icon-related-info"
                (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                matTooltip="{{'common.related-info'| afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
        </div>
        <div class="common__info-icon-block common__info-icon-block--in-report"
            *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
            <tooltip-in-report [inReport]="fieldTypeText.inReport"></tooltip-in-report>
        </div>
    </div>
    <div [ngClass]="{'common__input-poll':field.poll && !field.noEditable && field.sequentialView,
        'common__show-alert': field.error && field.sequentialView}">
        <div class="float-end">
            <div class="field-type-text__icon-references field-type-text__icon-references--border"
                *ngIf="skin != 'icam-red' && field.type !== 'link' && field.evaluatedValue?.hasReferences && !field.poll"
                (click)="showReference($event)"
                matTooltip="{{'common.see-dependencies' | afTranslate}}"
                matTooltipPosition="left"
                matTooltipClass="left">
                <i class="field-type-text__icon-references-icon lf-icon-connected-data"></i>
            </div>
            <div class="field-type-text__icon-references field-type-text__icon-references--less-top field-type-text__icon-references"
                *ngIf="skin === 'icam-red' && field.type !== 'link' && field.evaluatedValue?.hasReferences && !field.poll">
                <i class="lf-icon-connected-data "></i>
            </div>
            <i class="lf-icon-text-field common__input-icon common__input-icon--color"
                *ngIf="field.type !== 'link'"
                [ngClass]="{'field-type-text__custom-font-icon': isLanding && field.sequentialView}"></i>
            <i class="lf-icon-{{fieldTypeText.titleField.toLowerCase()}}-round field-type-text__icon-link-rrss field-type-text__icon-link-rrss"
                *ngIf="field.type === 'link'"></i>
        </div>

        <input [id]="'field-item-text-'+id"
            class="common__input-content"
            [ngClass]="{'common__input-content-alert': field.error ,
                'common__input-content-no-editable': field.noEditable,
                'common__input-content--poll':field.poll && !field.noEditable && field.sequentialView}"
            type="text"
            [(ngModel)]="fieldTypeText.inputText"
            (blur)="changeInField($event)"
            (keypress)="keyChangeInField($event)"
            [placeholder]="placeholder"
            autocomplete="off"
            [name]="field.from"
            [readonly] ="field.readonly || field.noEditable">
        <i class="common__delete-content-icon"
            [ngClass]="{'field-type-text__delete-content-icon-textarea':field.multiline,
            'lf-icon-close-round':  isLanding,
            'lf-icon-close': !isLanding }"
            [ngHide]="fieldTypeText.inputText === '' || field.readonly || field.noEditable"
            (click)="resetInput()"></i>
    </div>
    <div class="field-item__blocker-box"
      *ngIf="fieldTypeText.field && fieldTypeText.field.error && fieldTypeText.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </label>
</div>
