import { Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'certify-document',
  templateUrl: './certify-document.component.html'
})
export class CertifyDocumentComponent {

  constructor(private activeModal: NgbActiveModal) { }

  handleAccept() {
    this.activeModal.close({result: 'ok'});
  };
  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
