<div class="float-start"
    ngbDropdown
    [autoClose]="'outside'"
    (openChange)="close()">
    <div ngbDropdownToggle>
        <button class="common__tooltip-alert-icon"
          [ngClass]="tooltipInReport.showInfo? 'lf-icon-close-round' : 'lf-icon-document'"></button>
    </div>
    <div class="common__tooltip-dropdown"
        ngbDropdownMenu role="menu">
        <div class="common__tooltip-report-box">
          <p class="common__tooltip-report-text"
            [afTranslate]="'field-items.show-in-report'">_Se mostrará en los informes:</p>
          <ul class="common__tooltip-report-inner-box">
            <li class="common__tooltip-report-text"
            *ngFor="let fieldInReport of inReport">
              {{fieldInReport}}
            </li>
          </ul>
        </div>
    </div>
</div>
