<div class="link-modal">
  <div class="link-modal__header">
      <span class="link-modal__header-title"
        [afTranslate]="'link-modal.insert-link'"></span>
      <i class="link-modal__header-close-button lf-icon-close"
        (click)="handleDismiss()"></i>
  </div>
  <div class="link-modal__fields-container">
      <div class="link-modal__field-container">
          <label class="link-modal__field-label"
            [afTranslate]="'link-modal.url'"></label>
          <input class="link-modal__field-input" autocomplete="off"
              [ngClass]="{'link-modal__field-input--error': linkModal.urlError}"
              [(ngModel)]="linkModal.url"/>
          <span class="link-modal__alert-message"
              [ngShow]="linkModal.urlError"
              [afTranslate]="'link-modal.http-error'">
          </span>
      </div>
      <div class="link-modal__field-container">
          <label class="link-modal__field-label"
            [afTranslate]="'link-modal.text-display'"></label>
          <input class="link-modal__field-input" autocomplete="off"
            [(ngModel)]="linkModal.textDisplay"/>
      </div>
      <div class="link-modal__field-container">
          <label class="link-modal__field-label"
            [afTranslate]="'link-modal.title'"></label>
          <input class="link-modal__field-input" autocomplete="off"
            [(ngModel)]="linkModal.title"/>
      </div>
      <div class="link-modal__field-container">
          <label class="link-modal__field-label"
            [afTranslate]="'link-modal.target'"></label>
          <select class="link-modal__field-dropdown" [(ngModel)]="linkModal.target">
              <option value="_self"
                [afTranslate]="'link-modal.same-window'"></option>
              <option value="_blank"
                [afTranslate]="'link-modal.other-window'"></option>
          </select>
      </div>
  </div>
  <div class="link-modal__buttons-container">
      <button class="common__button common__button--active"
          (click)="addLink()"
          [afTranslate]="'common.ok'">
      </button>
      <button class="link-modal__button--dismiss common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">
      </button>
  </div>
</div>
