  <div class="up-document">
      <div class="common__modal-header-block">
          <i class="lf-icon-up-file common__icon-header"></i>
          <span class="common__text-header">{{upDocument.titleHeader}}</span>
          <i class="lf-icon-close common__icon-close-modal"
              (click)="handleDismiss()"></i>
      </div>
      <form class="up-document__form-block">
          <div class="form-group up-document__form-up-file up-document__form-up-file"
              [ngClass]="{'up-document__form-up-file--alert up-document__show-alert ': upDocument.errorName || upDocument.errorField}">
              <label for="up-document"
                  class="field-type-logo__input-text">
                  <div class="common__label-block common__label-block--one-icon">
                      <span class="common__input-text"
                          [ngClass]="{'common__input-text--required':  upDocument.errorName || upDocument.errorField}">{{upDocument.titleLabel}}</span>
                      <span class="common__text-required">*</span>
                  </div>
              </label>
              <i class="lf-icon-up-file common__input-icon common__input-icon-active"
                  (click)="openFileSearch()"> </i>
              <input class="up-document__file-upload"
                  type="file"
                  id="up-document"
                  (change)="uploadLogo($event)">
              <div class="up-document__parent-name-box"
                  id="up-document-input-file">
                  <p class="up-document__name-img"
                      [ngClass]="{'up-document__name-img--ellipsis': upDocument.errorName}">{{upDocument.fileName}}</p>
              </div>

          </div>
          <span class="rename-modal__error-name"
              [ngShow]="upDocument.errorName"
              [afTranslate]="'up-document.error-name'"></span>
      </form>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="clearForm()"
              [afTranslate]="'common.clean'">Limpiar</button>
          <button type="submit"
              class="common__button common__button--active"
              (click)="upFile()"
              [afTranslate]="'common.upload'">Subir</button>
      </div>
  </div>
