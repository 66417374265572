
import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { FieldTypeListParentService } from './../utils/field-type-list-parent.service';


@Component({
  selector: 'sequential-list-highlight',
  templateUrl: './sequential-list-highlight.component.html'
})

export class SequentialListHighlightComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = true;
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);

  constructor(private fieldTypeListParent: FieldTypeListParentService) {}

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
     this.fieldTypeListParent.openWatchers(this)
  }
  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
    if(this.field){
      this.listValues.forEach((value: any) => {
        let color = value.color;
        value.setColor = value.color.substring(1, value.color.length).toUpperCase();
        if(this.isLanding){
            value.customBackgroundColor = this.fieldTypeListParent.customBackground(color);
        }
      });
    }
  }

  selectedField(listValue:any) {
    this.fieldTypeListParent.selectedField(listValue, this)
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
