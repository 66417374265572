<div class="reset-password">
    <div class="common__modal-header-block">
        <i class="lf-icon-lock common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'reset-password.reset-password'">Resetear contraseña</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <field-type-rich-text class="reset-password__info-block"
        [field]="resetPassword.passwordInfo">
    </field-type-rich-text>
    <div class="reset-password__body-form">
        <form class="reset-password__reset-password-form"
                autocomplete="off">
            <field-type-password [field]="resetPassword.oldPassword"
                [preSelectItem]="resetPassword.oldPass"
                (itemSelected)="itemSelected('oldPass', $event.newValue)">
            </field-type-password>
            <field-type-password [field]="resetPassword.newPassword"
                [preSelectItem]="resetPassword.newPass"
                (itemSelected)="itemSelected('newPass', $event.newValue)">
            </field-type-password>
            <field-type-password [field]="resetPassword.repeatPassword"
                [preSelectItem]="resetPassword.repeatPass"
                (itemSelected)="itemSelected('repeatPass', $event.newValue)">
            </field-type-password>
            <p class="reset-password__text-no-security"
                [ngShow]="resetPassword.notSecurity"
                [afTranslate]="'reset-password.not-secure-password'">La nueva contraseña no es segura.</p>
        </form>
    </div>
    <div class="common__buttons-block common__buttons-block--modal">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancel()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="savePassword()"
            [afTranslate]="'common.save'">Guardar</button>
    </div>
</div>
