<div class="concept-list">
  <div class="concept-list__table" [id]="'concept_' + conceptInfo.conceptId">
      <div class="common__table-header" [ngShow]="conceptList.conceptList.length">
           <div class="concept-list__table-col concept-list__table-col--extension">
              <filter-by-list [type]="'list'"
                              [item]="conceptList.extensionFilter"
                              [list]="conceptList.extensionList"
                              (filterElement)="selectTypeOperation($event, 'extension')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-title">
               <filter-by-search
                  [item]="conceptList.titleFilter"
                  [filterOrder]="conceptList.dateFilter"
                  [order]="conceptList.order"
                  [filterText]="conceptList.titleWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'title')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--author">
              <filter-by-list [type]="'listFind'"
                              [item]="conceptList.authorFilter"
                              [list]="conceptList.authorList"
                              (filterElement)="selectTypeOperation($event, 'author')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
              <div (click)="orderDate('creationDate')">
                  <p class="common__table-header-text" [afTranslate]="'common.creation'"></p>
                  <i class="common__order-by"
                     [ngClass]="{
                        'lf-icon-arrow-down': (conceptList.dateFilter === 'creationDate' && conceptList.order) || conceptList.dateFilter !== 'creationDate',
                        'lf-icon-arrow-up': conceptList.dateFilter === 'creationDate' && !conceptList.order,
                        'common__order-by--active': conceptList.dateFilter === 'creationDate'
                      }"></i>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
              <div (click)="orderDate('modificationDate')">
                  <p class="common__table-header-text" [afTranslate]="'common.modification'"></p>
                  <i class="common__order-by"
                     [ngClass]="{
                        'lf-icon-arrow-down': (conceptList.dateFilter === 'modificationDate' && conceptList.order) || conceptList.dateFilter !== 'modificationDate',
                        'lf-icon-arrow-up': conceptList.dateFilter === 'modificationDate' && !conceptList.order,
                        'common__order-by--active': conceptList.dateFilter === 'modificationDate'
                      }"></i>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-title" [ngShow]="conceptList.toShow === 'archived'">
              <filter-by-list [type]="'listFind'"
                              [item]="conceptList.nameVersionFilter"
                              [list]="conceptList.nameVersionList"
                              (filterElement)="selectTypeOperation($event, 'nameVersion')">
              </filter-by-list>
         </div>
          <div class="concept-list__table-col concept-list__table-col--state-global-report">
              <filter-by-list [type]="'list'"
                              [widthClass]="'200px'"
                              [item]="conceptList.statusFilter"
                              [list]="conceptList.statusList"
                              (filterElement)="selectTypeOperation($event, 'status')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential"></div>
          <div class="concept-list__table-col concept-list__table-col--actions-header">
              <p class="common__table-header-text common__table-header-text--right" [afTranslate]="'common.actions'"></p>
          </div>
      </div>
      <div class="common__table-row"
          [ngClass]="{'common__table-row--selected-item': evaluateConcept.openOption,
            'concept-list__row-anonimyzed': evaluateConcept.anonymized}"
          *ngFor="let evaluateConcept of conceptList.conceptListFilter | afOrderBy:[conceptList.dateFilter, 'title']:conceptList.order">
          <div class="concept-list__table-col concept-list__table-col--extension concept-list__center-text">
              <i [class]="'concept-list__table-icon ' + (evaluateConcept.extension | extensionDocument)"></i>
          </div>
          <div class="concept-list__table-col concept-list__table-col--title common__cursor-pointer" (click)="launchActionOption('open', evaluateConcept)">
              <parragraph-tooltip [text]="evaluateConcept.title"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--author">
              <parragraph-tooltip [text]="evaluateConcept.authorIsDeleted ? evaluateConcept.author + ' (' + ('common.inactive-user' | afTranslate) + ')' : evaluateConcept.author"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--creation-date">
              <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--creation-date">
              <parragraph-tooltip [text]="evaluateConcept.modificationDate | convertDate"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--state" [ngShow]="conceptList.toShow === 'archived'">
              <parragraph-tooltip [text]="evaluateConcept.nameVersion"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--state-global-report">
              <parragraph-tooltip [text]="evaluateConcept.status"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential">
              <i class="lf-icon-certificate concept-list__icon-certified" *ngIf="evaluateConcept.sendedAsCertifiedEmail || evaluateConcept.sendedAsCertifiedSMS"></i>
              <i class="lf-icon-anonymous concept-list__icon-certified" [ngShow]="evaluateConcept.anonymized"></i>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions">
              <ul class="common__options-row concept-list__options-row">
                  <li class="common__menu-options-row"
                    *ngIf="conceptList.toShow !== 'archived'">
                    <i [ngClass]="userPermissions === 'none' || conceptInfo.poll || evaluateConcept.documentStatusId !== 1 ? 'lf-icon-visible' : 'lf-icon-editable-form'"
                        (click)="launchActionOption('open', evaluateConcept)"
                        matTooltip="{{(userPermissions === 'none' || conceptInfo.poll || evaluateConcept.documentStatusId !== 1 ? 'common.view' : 'expanded-menu.edit') | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </i>
                  </li>
                  <li class="common__menu-options-row"
                      *ngIf="userPermissions !== 'none' && !evaluateConcept.anonymized && skin === 'dark-blue'">
                      <i class="lf-icon-anonymous"
                          (click)="changeToAnonymatum(evaluateConcept, 'report')"
                          matTooltip="{{'expanded-menu.anonymatum-documents' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                      </li>
                  <li class="common__menu-options-row"
                    *ngIf="conceptInfo.type === 0 && conceptList.toShow !== 'archived' && userPermissions !== 'none'">
                      <i class="lf-icon-download"
                          (click)="launchActionOption('download', evaluateConcept)"
                          matTooltip="{{'concept-list.download-report' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      *ngIf="conceptInfo.type === 0 && conceptList.toShow !== 'archived' && userPermissions !== 'none'">
                      <i class="lf-icon-share"
                          (click)="launchActionOption('share', evaluateConcept)"
                          matTooltip="{{'share-document.share-report' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                  </li>
                  <li class="common__menu-options-row" *ngIf="userPermissions === 'all' && evaluateConcept.documentStatusId === 1">
                      <i class="lf-icon-document-validate"
                          (click)="launchActionOption('publish', evaluateConcept)"
                          matTooltip="{{'common.publish' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                  </li>
                  <li ngbDropdown ngbDropdownToggle class="common__menu-options-row" *ngIf="conceptInfo.canSign && userPermissions === 'all'"
                      (openChange)="openChangeSignature($event, evaluateConcept)">
                      <i class="lf-icon-signature-certificate"
                          matTooltip="{{'expanded-menu.signature-certification' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                      <div ngbDropdownMenu class="common__menu-expanded-little common__menu-expanded-little--list" [ngShow]="evaluateConcept.showMenu">
                          <div ngbDropdownItem class="common__menu-expanded-little-option"
                              *ngFor="let option of evaluateConcept.menuOption; let $last = last"
                              (click)="launchActionOption(option.action, evaluateConcept)">
                              <div class="common__menu-expanded-little-option-item"
                                  [ngClass]="{'common__menu-expanded-little-option-item--last': $last}"
                                  [afTranslate]="option.name"></div>
                          </div>
                      </div>
                  </li>
                  <li class="common__menu-options-row" *ngIf="conceptInfo.canDelete && userPermissions !== 'none' && !conceptInfo.poll">
                      <i class="lf-icon-trash"
                          (click)="launchActionOption('delete', evaluateConcept)"
                          matTooltip="{{'concept-list.delete-report' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                  </li>
              </ul>
              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
          </div>
      </div>
      <div class="concept-list__table-foot"
           [ngClass]="{'concept-list__table-foot--no-items': !conceptList.conceptList.length}"
           [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize || (conceptInfo.archivedAmount && conceptList.toShow !== 'archived') || conceptList.showResultsReports ">
           <div *ngIf="!conceptInfo.poll"
               [ngShow]="conceptInfo.archivedAmount && conceptList.toShow !== 'archived'"
               class="concept-list__block-info-archive"
               [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none concept-list__archive-button" (click)="viewArchived()">
                  <i class="lf-icon-archive common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined" [afTranslate]="'concept-list.view-archive'"></span>
              </button>
              <p class="concept-list__archived-evaluated-amount">
                  <span [ngShow]="conceptInfo.conceptId">
                      <span [afTranslate]="conceptInfo.archivedAmount === 1 ? 'concept-list.there-is' : 'concept-list.there-are'"></span>
                      {{conceptInfo.archivedAmount}}
                      <span [afTranslate]="conceptInfo.archivedAmount === 1 ? 'concept-list.concept-archive' : 'concept-list.concepts-archives'"></span>
                  </span>
                  <span [ngShow]="conceptInfo.reportId">
                      {{conceptInfo.archivedAmount}}
                      <span [afTranslate]="conceptInfo.archivedAmount === 1 ? 'concept-list.report-archive' : 'concept-list.reports-archives'"></span>
                  </span>
              </p>
          </div>
          <div class="concept-list__pagination-block" [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize">
              <pagination-control
                [config]="conceptList.configPagination"
                [collectionSize]="conceptList.conceptListFilter.length"
                [(currentPage)]="conceptList.currentPage"
                (configuredTotalChange)="conceptList.configuredTotal = $event.configuredTotal"></pagination-control>
              <div class="common__pagination-count" [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize">
                <span class="notranslate">{{conceptList.configuredTotal.first}}-{{conceptList.configuredTotal.last}}</span>
                <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                <span class="notranslate">{{conceptList.conceptListFilter?.length}}</span>
              </div>
              <div class="common__pagination-count"
                [ngShow]="conceptList.configPagination.pageSize >= conceptList.conceptListFilter?.length">
                <span>
                  <span [afTranslate]="'common.total'">_Total</span>
                  <span class="common__total-number-element notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </span>
              </div>
          </div>
      </div>
  </div>
</div>
