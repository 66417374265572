import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldTypeIcons'
})
export class FieldTypeIconsPipe implements PipeTransform {

  transform(fieldId: string):string {
    switch(fieldId){
      case 'text':
          return 'lf-icon-text-field';
      case 'rich':
          return 'lf-icon-text-edit';
      case 'l-fo': 
      case 'list':
      case 'listp':
          return 'lf-icon-dropdown';
      case 'l-co':
          return 'lf-icon-connected-data';
      case 'num':
          return 'lf-icon-hashtag';
      case 'date':
          return 'lf-icon-calendar';
      case 'bool':
      case 'boolp':
          return 'lf-icon-switch-right';
      case 'formu':
      case 'formp':
      case 'agreg':
          return 'lf-icon-calculator';
      case 'file':
          return 'lf-icon-up-file';
      case 'money':
          return 'lf-icon-coin';
      case 'user':
          return 'lf-icon-user';
      case 'txchk':
          return 'lf-icon-check-empty';
      case 'templ':
          return 'lf-icon-textbooks';
      default: 
        return '';
      // default:
      //     return 'lf-icon-text-field';
    }
  }

}
