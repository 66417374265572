import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';


@Directive({
  selector: '[ngMouseWheel]'
})
export class NgMouseWheelDirective implements OnInit {
  constructor(private elementRef: ElementRef) { }
  @Output() mouseWheelEvent = new EventEmitter();

  ngOnInit(): void {
    let mouseWhell: any = document.getElementById(this.elementRef.nativeElement.id);
    mouseWhell.addEventListener('wheel', (event: any)=>{
        if(event.deltaY < 0){//up
          this.mouseWheelEvent.emit({action: 'up', event:event});
        // }else if(event.deltaY > 0){//down
        }else{
          this.mouseWheelEvent.emit({action: 'down', event:event});
        }
    })
  }
}
