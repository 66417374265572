<div class="concept-list-landing">
  <div class="concept-list-landing__table">
      <div class="concept-list-landing__table-header">
          <div class="concept-list-landing__table-col concept-list-landing__table-col--title concept-list-landing__table-col--bottom-2">
              <filter-by-search [item]="conceptListLanding.titleFilter"
                  [filterOrder]="conceptListLanding.dateFilter"
                  [order]="conceptListLanding.order"
                  [filterText]="conceptListLanding.titleWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'title')">
              </filter-by-search>
          </div>
          <div class="concept-list-landing__table-col concept-list-landing__table-col--reference concept-list-landing__table-col--bottom-2">
              <filter-by-search [item]="conceptListLanding.referenceFilter"
                  [filterText]="conceptListLanding.referenceWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'referenceId')">
              </filter-by-search>
          </div>
          <div class="concept-list-landing__table-col concept-list-landing__table-col--creation-date concept-list-landing__table-col--top-1"
              ngbDropdown
              [autoClose]="'outside'">
              <p class="common__table-header-text common__table-header-text--custom common__cursor-pointer"
                  ngbDropdownToggle
                  [afTranslate]="'common.creation'">Creación</p>
              <i class="common__filter-icon lf-icon-filter-1"
                  [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': conceptListLanding.creationDateFilter}"
                  ngbDropdownToggle></i>
              <i class="lf-icon-arrow-down common__order-by"
                  [ngClass]="{'lf-icon-arrow-up': !conceptListLanding.order && conceptListLanding.dateFilter === 'creationDate',
                      'common__order-by--active': conceptListLanding.dateFilter === 'creationDate'}"
                  (click)="orderDate('creationDate')"></i>
              <div class="concept-list-landing__calendar-dropdown common__block-option-list"
                      [ngClass]="{'common__block-option-list--landing-custom': true}"
                      ngbDropdownMenu>
                  <from-to-calendar [fromSave]="conceptListLanding.creationDateFromDate"
                      [toSave]="conceptListLanding.creationDateToDate"
                      [type]="'concept-list-landing'"
                      (changeCalendar)="changeCalendar($event, 'creationDate')">
                  </from-to-calendar>
              </div>
          </div>
          <div class="concept-list-landing__table-col concept-list-landing__table-col--modification-date concept-list-landing__table-col--top-1">
              <div (click)="orderDate('modificationDate')">
                  <p class="common__table-header-text common__table-header-text--custom"
                      [afTranslate]="'common.modification'">Modificación</p>
                  <i class="common__order-by"
                     [ngClass]="{'lf-icon-arrow-down':(conceptListLanding.dateFilter === 'modificationDate' && conceptListLanding.order) || conceptListLanding.dateFilter !== 'modificationDate',
                         'lf-icon-arrow-up':conceptListLanding.dateFilter === 'modificationDate' && !conceptListLanding.order,
                         'common__order-by--active': conceptListLanding.dateFilter === 'modificationDate'}"></i>
              </div>
          </div>
          <div class="concept-list-landing__table-col concept-list-landing__table-col--step concept-list-landing__table-col--bottom-2">
              <filter-by-list *ngIf="conceptList[0]?.step !== undefined"
                  [type]="'listFind'"
                  [item]="conceptListLanding.stepFilter"
                  [list]="conceptListLanding.stepList"
                  [widthClass]="'200px'"
                  (filterElementEmit)="selectTypeOperation($event.source, 'step')">
              </filter-by-list>
          </div>
          <div class="concept-list-landing__table-col concept-list-landing__table-col--actions">
              <p class="common__table-header-text common__table-header-text--right common__table-header-text--custom common__table-header-text--top-0"
                  [afTranslate]="'common.actions'">Acciones</p>
          </div>
      </div>
      <div class="concept-list-landing__table-body">
          <div class="concept-list-landing__table-row"
              *ngFor="let evaluateConcept of conceptListLanding.conceptListFilter | byWord:searchText:conceptListLanding.searchHeader | unique: 'referenceId' | afOrderBy:conceptListLanding.dateFilter:conceptListLanding.order | afLimitTo:conceptListLanding.configPagination.pageSize:conceptListLanding.configPagination.pageSize*(conceptListLanding.currentPage - 1)"
              [ngClass]="{'concept-list-landing__row-anonimyzed':evaluateConcept.anonymized}">
              <div class="concept-list-landing__table-col concept-list-landing__table-col--title"
                  (click)="openConcept(evaluateConcept)">
                  <parragraph-tooltip [text]="evaluateConcept.title"
                    [cursor]="true"
                    [ngClass]="{'concept-list-landing__table-title':evaluateConcept.anonymized}"></parragraph-tooltip>
                  <i class="lf-icon-anonymous concept-list-landing__anonymatum-icon"
                      [ngShow]="evaluateConcept.anonymized"></i>
              </div>
              <div class="concept-list-landing__table-col concept-list-landing__table-col--reference">
                  <parragraph-tooltip [text]="evaluateConcept.referenceId"></parragraph-tooltip>
              </div>
              <div class="concept-list-landing__table-col concept-list-landing__table-col--creation-date">
                  <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate"></parragraph-tooltip>
              </div>
              <div class="concept-list-landing__table-col concept-list-landing__table-col--modification-date">
                  <parragraph-tooltip [text]="evaluateConcept.modificationDate | convertDate"></parragraph-tooltip>
              </div>
              <div class="concept-list-landing__table-col concept-list-landing__table-col--step">
                  <parragraph-tooltip [text]="evaluateConcept.step"></parragraph-tooltip>
              </div>
              <div class="concept-list-landing__table-col concept-list-landing__table-col--actions">
                  <div class="concept-list-landing__options">
                      <i class="lf-icon-editable-form concept-list-landing__menu-options concept-list-landing__menu-options--edit"
                          (click)="openConcept(evaluateConcept)"
                          matTooltip="{{'concept-list.description-edit' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                      <i class="lf-icon-open-window concept-list-landing__menu-options"
                          (click)="openConcept(evaluateConcept, true)"
                          matTooltip="{{'concept-list.description-open' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </div>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
              </div>
          </div>
      </div>
      <div class="concept-list-landing__table-foot">
          <div class="concept-list-landing__pagination-block">
              <pagination-control
                  [ngShow]="conceptListLanding.conceptListFilter?.length > conceptListLanding.configPagination.pageSize"
                  [config]="conceptListLanding.configPagination"
                  [collectionSize]="conceptListLanding.conceptListFilter?.length"
                  [(currentPage)]="conceptListLanding.currentPage">
              </pagination-control>
              <div class="common__pagination-count">
                  <span [afTranslate]="'common.total'">Total:</span>
                  <span class="span__auto-ajust">{{conceptListLanding.conceptListFilter?.length}}</span>
              </div>
          </div>
      </div>
  </div>
</div>
