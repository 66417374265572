<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-track attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'task-historic.historic-process'"></p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            (click)="maximizeScreen()"
            matTooltip="{{(taskHistoric.fullScreen? 'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="below"
            matTooltipClass="below"
            [ngClass]="taskHistoric.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"></i>
    </div>

    <div class="attachments-template__custom-no-documents"
        [ngShow]="!historicList.length">
        <p [afTranslate] ="'task-historic.task-no-change'">
            La tarea no ha sufrido cambios
        </p>
    </div>
    <div class="predefined-templates__historic-table predefined-templates__historic-table--custom"
        [ngClass]="{'predefined-templates__historic-table--expand': taskHistoric.fullScreen}"
        [ngShow]="historicList.length">
        <div class="task-historic__historic-table-header task-historic__historic-table-header--custom task-historic__historic-table-header--custom-header">
            <div class="task-historic__header-col task-historic__header-col--2">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.type'">Tipo</p>
            </div>
            <div class="task-historic__header-col task-historic__header-col--4">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.user'">Usuario</p>
            </div>
            <div class="task-historic__header-col task-historic__header-col--2">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.date'">Fecha</p>
            </div>
            <div class="task-historic__header-col task-historic__header-col--2"></div>
        </div>
        <div class="task-historic__historic-block task-historic__historic-block--custom"
            [ngClass]="{'task-historic__historic-block--expand': taskHistoric.fullScreen,
                'task-historic__historic-block--more-elements': historicList.length > 3}">
            <div class="task-historic__documents-table"
                *ngFor="let historic of historicList | afOrderBy: 'date':true">
                <div class="task-historic__header-col task-historic__header-col--2">
                    <span class="task-historic__documents-table-text">
                        {{historic.operationType}}
                    </span>
                </div>
                <div class="task-historic__header-col task-historic__header-col--4">
                    <span class="task-historic__documents-table-text">
                            {{historic.user}}
                    </span>
                </div>
                <div class="task-historic__header-col task-historic__header-col--2">
                    <span class="task-historic__documents-table-text">
                        {{historic.date | convertDate: 'without-time'}}
                    </span>
                </div>
                <div class="task-historic__header-col task-historic__header-col--2">
                    <div class="task-historic__options-block"
                        [ngShow]="historic.operationTypeId === 2">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form action="historic.aspx" target="_blank" method="post" auto-data="taskHistoric.compareVersions" submit="taskHistoric.openCompareVersions"></form>
</div>
