import { Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { ConceptInstancesListParentService } from '../utils/concept-instances-list-parent.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'concept-instances-list-icam',
  templateUrl: './concept-instances-list-icam.component.html'
})
export class ConceptInstancesListIcamComponent implements OnInit, OnChanges {
  userPermissions:any = this.permissions.getPermissions();
  adviceModal: any;
  conceptInstancesList: any = {
    search: false,
    order: true,
    filterOrder: 'creationDate',
    searchInstances:  '',
    channelFilter: '',
    view:  'last15',
    headerTitle: this.translate.instant('concept-instances-list.documentary-consultations'),
    selectedView: null,
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50,
      maxSize: 5
    },
    currentPage: 1,
    tipoFilter: {
      name: this.translate.instant('common.type')
    },
    stepFilter: {
      name: this.translate.instant('common.state')
    },
    alertStateFilter: {
      name: this.translate.instant('common.alerts'),
      iconCircle: true
    },
    referenciaQueryFilter: {
      name: this.translate.instant('common.reference'),
      top: 27,
      width: '200px'
    },
    instancesList: [],
    instancesListFilter: [],
    instancesListOriginFilter: [],
    filters:  ['tipo', 'step', 'alertState'],
    words: ['title', 'evaluationName', 'author', 'state'],
    conceptInstancesView: [
      {
          name: this.translate.instant('dashboard.last-week'),
          viewOption: 'lastWeek'
      },
      {
          name: this.translate.instant('dashboard.last-month'),
          viewOption: 'lastMonth'
      },
      {
          name: this.translate.instant('dashboard.last-15'),
          viewOption: 'last15'
      },
      {
          name: this.translate.instant('dashboard.last-30'),
          viewOption: 'last30'
      },
      {
          name: this.translate.instant('concept-instances-list.all-inquiries'),
          viewOption: 'all'
      },
      {
          name: this.translate.instant('concept-instances-list.top-queries'),
          viewOption: 'esTop'
      }
    ]
  }

  constructor(private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private conceptInstancesListParent: ConceptInstancesListParentService) {

    }

  @Input() filterInstancesList:any = null;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.conceptInstancesList.selectedView = this.conceptInstancesList.conceptInstancesView[2];
  }

  ngOnChanges(): void {
    if(this.filterInstancesList !== null){
      this.conceptInstancesList.instancesList = this.filterInstancesList;
      this.conceptInstancesListParent.changeView(this.conceptInstancesList.view, this);
    }
  }

  filterElementOn(e:any, type:string){
    this.conceptInstancesListParent.selectTypeOperation(e.source, type, this);
  }

  toggleSearch(){
    this.conceptInstancesListParent.toggleSearch(this);
  }

  searchBy(type: any){
    this.conceptInstancesListParent.searchBy(type, this);
  }

  launchActionInstance(item: any, option: any){
    this.conceptInstancesListParent.launchActionInstance(item, option, this);
  }

  openModalConfidential(instance: any){
    this.conceptInstancesListParent.openModalConfidential(instance, this);
  }

  changeView(type: any){
    this.conceptInstancesListParent.changeView(type, this);
  }

  resetFilter(e: any, type: any){
    this.conceptInstancesListParent.resetFilter(e, type, this);
  }

  orderBySearch(e: any, type: any){
    this.conceptInstancesList[type+'Filter'] = e.newValue;
    this.conceptInstancesListParent.filterConcept(type, this);
  }

  closeDropdown(type: any){
    this.conceptInstancesListParent.closeDropdown(type, this.dropdowns);
  }

}
