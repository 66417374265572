import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { find } from 'lodash';

@Component({
  selector: 'duplicate-document',
  templateUrl: './duplicate-document.component.html'
})
export class DuplicateDocumentComponent implements AfterViewInit {
  duplicateDocument:any = {
    fieldInfo: {
      id:"new-duplicate-document-text",
      required: true,
      label: this.translate.instant('documentation-list.name-new-document')
    },
    newDocument: {},
  }
  constructor(private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }
  @Input() document: any = null;
  @Input() list: any = null;

  ngAfterViewInit(): void {
    this.duplicateDocument.document = this.document;
    this.cleanModal();
    setTimeout(function(){
      let element: any = $('#field-item-text-new-version-text');
        element.focus().select();
    });
  }

  cleanModal(){
      this.resetError();
      this.duplicateDocument.newDocument.name = this.getNameDuplicateDocument();
  }

  getNameDuplicateDocument(){
      let that: any = this.duplicateDocument;
      let name = that.document.name.substr(0, that.document.name.lastIndexOf('.'));
      let nameDuplicated : any = that.document.name.substr(0, that.document.name.lastIndexOf('.'));
      if (this.list?.length) {
          let nameIdx = 0;
          let existingFolder: any;
          do {
              if(nameIdx === 1){
                  name = nameDuplicated + ' (' + this.translate.instant('duplicate-evaluation.copy').toLowerCase() + ')';
              }else if(nameIdx && nameIdx > 1){
                  name = nameDuplicated + ' (' + this.translate.instant('duplicate-evaluation.copy').toLowerCase() + ' ' + nameIdx + ')';
              }
              existingFolder = find(this.list, (item:any, index: number)=> {
                let nameItem : any = item.name.substr(0, item.name.lastIndexOf('.'));
                return nameItem.toLowerCase() === name.toLowerCase();
              });
              nameIdx++;
          } while (existingFolder);
      }

      return name;
  }

  resetError(){
      this.duplicateDocument.fieldInfo.error = false;
      this.duplicateDocument.errorName = false;
      this.duplicateDocument.errorType = '';
  }

  itemSelected(e:any, item:any){
      this.duplicateDocument.newDocument[item] = e.newValue;
      if(item === 'name' && e.newValue){
          this.resetError();
      }
  }

  validateForm(){
      this.resetError();
      if(this.duplicateDocument.newDocument.name === null || this.duplicateDocument.newDocument.name === ''){
        this.duplicateDocument.fieldInfo.error = true
      }else{
        this.validateName();
      }
  }

  validateName(){
      for(let documentList of this.list){
          let documentName:string = documentList.name.substr(0, documentList.name.lastIndexOf('.'));
          if(documentName === this.duplicateDocument.newDocument.name && documentList.extension === this.duplicateDocument.document.extension){
            this.duplicateDocument.fieldInfo.error = true;
            this.duplicateDocument.errorName = true;
            this.duplicateDocument.errorType = this.translate.instant('documentation-list.document-name-exists');
            break;
          }
      }
      if(!this.duplicateDocument.fieldInfo.error){
          this.handleAccept();
      }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        name: this.duplicateDocument.newDocument.name
    });
  };
}
