import { Component, Input } from '@angular/core';

@Component({
  selector: 'pdf-table',
  templateUrl: './pdf-table.component.html'
})
export class PdfTableComponent {
  pdfTable: any = {};
  @Input() configuration: any = {};
}
