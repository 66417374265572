<div class="front-login" >
    <div class="front-login__custom-login-container">
        <div class="front-login__custom-login-block"
            [ngClass]="{'front-login__custom-login-block--no-public': frontLogin.isLandingLogin && !frontLogin.public,
                'front-login__custom-login-block--no-public-monitoring': frontLogin.isLandingLogin && frontLogin.public,
                'front-login__login-block--info': frontLogin.unexpectedError}">
            <div *ngIf="!frontLogin.showNoPermissionsWarning">
                <img class="front-login__login-block-img front-login__login-block-img--custom"
                    [ngClass]="{'front-login__login-block-img--with-error':frontLogin.error,
                    'front-login__login-block-img--reset-pass':frontLogin.resetPass,
                    'front-login__login-block-img--custom-no-public':!frontLogin.public,
                    'front-login__login-block-img--contact-login': frontLogin.isLandingLogin,
                    'front-login__login-block-img--unexpected-error': frontLogin.unexpectedError}"
                    [src]="frontLogin.logo"
                    alt="Logo"/>
                <p class="front-login__title-restricted front-login__title-restricted--custom"
                    [ngClass]="{'front-login__title-restricted--custom-no-public': !frontLogin.public}"
                        *ngIf="frontLogin.isLandingLogin && !frontLogin.errorConect"
                        [afTranslate]="'login-block.acces-content'">
                    Acceso a contenido restringido
                </p>
                <p class="front-login__input-error-text front-login__input-error-text--error-all"
                        [ngShow]="!frontLogin.isLandingLogin && frontLogin.errorConect">
                    {{frontLogin.errorLoginMessage}}
                </p>
                <p class="front-login__input-error-text front-login__input-error-text--error-all"
                        [ngShow]="frontLogin.error && frontLogin.isLandingLogin && !frontLogin.errorConect"
                        [afTranslate]="'front-login.error-email'">
                    El email introducido no es correcto.
                </p>

                <form class="front-login__contact-login front-login__contact-login--custom"
                        [ngClass]="{'front-login__contact-login--custom-no-public': !frontLogin.public}"
                        name="landingLoginForm"
                        novalidate
                        *ngIf="frontLogin.isLandingLogin"
                        [ngHide]="frontLogin.errorConect">
                        <div class="front-login__text front-login__text--custom"
                            [ngHide]="frontLogin.public">{{frontLogin.inputLabel}}</div><!-- Por favor, introduce tu correo electrónico -->
                    <div class="front-login__input-block front-login__input-block--custom">
                        <div class="front-login__input-block-icon front-login__input-block-icon--custom">
                            <i class="front-login__icon" [ngClass]="frontLogin.inputIcon"></i>
                        </div>
                        <input type="text"
                            name="login"
                            class="front-login__input-block-text front-login__input-block-text--custom"
                            autocomplete="off"
                            required
                            [(ngModel)]="frontLogin.credentials.login"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="frontLogin.working"
                            [placeholder]="frontLogin.inputPlaceholder">
                        <i class="lf-icon-close-round-full front-login__input-error-icon"
                            [ngShow]="frontLogin.errorLogin"></i>
                    </div>
                    <button class="front-login__custom-login-button"
                            [disabled]="frontLogin.working"
                            [ngStyle]="{'background-color': frontLogin.custom?.color, 'color': frontLogin.custom?.textColor}"
                            (click)="doLogin(frontLogin.landingLoginForm)">{{frontLogin.buttonText}}
                    </button>
                    <div class="front-login__input-error-text"
                            [ngShow]="frontLogin.errorLogin"
                            [afTranslate]="'front-login.email-required'">Debe introducir un email</div>
                </form>
                <div [ngShow]="!frontLogin.isLandingLogin  && !frontLogin.unexpectedError"
                        class="front-login__forgot-password">
                    <form class="front-login__login-form"
                            [ngHide]="frontLogin.resetPass"
                            name="loginForm"
                            novalidate>
                        <div class="front-login__input-block">
                            <div class="front-login__input-block-icon">
                                <i class="lf-icon-user front-login__icon"></i>
                            </div>
                            <input type="text"
                                name="login"
                                class="front-login__input-block-text"
                                autocomplete="off"
                                required
                                [(ngModel)]="frontLogin.credentials.login"
                                [ngModelOptions]="{standalone: true}"
                                [disabled]="frontLogin.working"
                                [placeholder]="'common.user'| afTranslate">
                        </div>
                        <div class="front-login__input-block">
                            <div class="front-login__input-block-icon">
                                <i class="lf-icon-lock front-login__icon"></i>
                            </div>
                            <input type="password"
                                name="password"
                                class="front-login__input-block-text"
                                [ngModelOptions]="{standalone: true}"
                                autocomplete="off"
                                required
                                [(ngModel)]="frontLogin.credentials.password"
                                [disabled]="frontLogin.working"
                                [placeholder]="'login-block.password'| afTranslate">
                        </div>
                        <div class="front-login__remember-block">
                            <div class="pull-left">
                                <label for="front-login-remember"
                                        class="front-login__remember-check-box">
                                    <input id="front-login-remember"
                                        type="checkbox"
                                        class="front-login__checkbox-hidden"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="frontLogin.rememberLogin">
                                    <i class="lf-icon-check front-login__checkbox-icon"
                                        [ngShow]="frontLogin.rememberLogin"></i>
                                </label>
                                <p class="front-login__remember-text"
                                    [afTranslate]="'front-login.remember'">Recordar</p>
                            </div>
                            <a class="front-login__remember-pass"
                                (click)="resetPassword()"
                                [afTranslate]="'front-login.forgot-password'">¿Olvidaste tu contraseña?</a>
                        </div>
                        <button class="front-login__login-button"
                                [disabled]="frontLogin.working"
                                (click)="doLogin(frontLogin.loginForm)"
                                [afTranslate]="'front-login.init-session'">Iniciar sesión</button>
                    </form>
                    <form class="front-login__login-form"
                            [ngShow]="frontLogin.resetPass"
                            name="changePass"
                            novalidate>
                        <p class="front-login__reset-pass-title"
                            [afTranslate]="'front-login.remember-password'">Recordar contraseña</p>
                        <div class="front-login__input-block">
                            <div class="front-login__input-block-icon">
                                <i class="lf-icon-user front-login__icon"></i>
                            </div>
                            <input type="text"
                              name="user"
                              class="front-login__input-block-text"
                              [ngModelOptions]="{standalone: true}"
                              autocomplete="off"
                              required
                              [(ngModel)]="frontLogin.credentials.passworforgotUser"
                              [disabled]="frontLogin.working"
                              [placeholder]="frontLogin.userText">
                        </div>
                        <p class="front-login__reset-pass-info"
                            [afTranslate]="'front-login.info-access-keys'">Te enviaremos un correo electrónico para poder resetear tus claves de acceso.</p>
                        <button class="front-login__custom-login-button"
                                [ngStyle]="{'background-color': frontLogin.custom?.color, 'color': frontLogin.custom?.textColor}"
                                (click)="sendReset()"
                                [afTranslate]="'common.continue'">Continuar</button>
                        <div [ngShow]="frontLogin.errorType"
                                class="front-login__user-error">{{frontLogin.errorType}}</div>
                    </form>
                </div>
                <div [ngShow]="frontLogin.unexpectedError"
                    class="front-login__block-info-reset-pass">
                    <i [class]="frontLogin.icon +' front-login__input-icon'"></i>
                    <p class="front-login__unexpected-text">{{frontLogin.resetPassTitle}}</p>
                    <p class="front-login__unexpected-message">{{frontLogin.resetPassText}}</p>
                </div>
            </div>
            <no-permissions *ngIf="frontLogin.showNoPermissionsWarning"
                [jwtToken]="frontLogin.jwtToken"
                [continueText]="frontLogin.noPermissionsContinueText"
                (launchAction)="clickAction($event)"></no-permissions>
            <div class="front-login__information-block"
                    [ngClass]="{'front-login__information-block--reset-pass':frontLogin.resetPass, 'front-login__information-block--reset-error': frontLogin.unexpectedError}"
                    [ngShow]="!frontLogin.isLandingLogin">
                <p class="front-login__information-question"
                    [afTranslate]="'front-login.information-question'">¿Necesitas ayuda?</p>
                <div class="front-login__information-contact">
                    <span>{{frontLogin.phoneContact}}</span>
                    <span>|</span>
                    <a class="front-login__information-contact-email">{{frontLogin.mailContact}}</a>
                </div>
            </div>
            <div class="front-login__error-message front-login__error-message--custom"
                [ngClass]="{'front-login__error-message--error-login':frontLogin.showbackButton}"
                [ngShow]="frontLogin.errorConect && frontLogin.isLandingLogin">
                <i class="{{frontLogin.icon}} front-login__error-message-icon-custom"></i>
                <div class="front-login__message-custom"
                    [ngClass]="{'front-login__message-custom--with-button':frontLogin.showbackButton}">
                    <p class="front-login__first-text">{{frontLogin.errorText}}</p>
                    <span>{{frontLogin.error}}</span>
                </div>
                <button class="front-login__custom-login-button front-login__custom-login-button--error"
                    [ngStyle]="{'background-color': frontLogin.custom?.color, 'color': frontLogin.custom?.textColor}"
                    [ngShow]="frontLogin.showbackButton"
                    (click)="tryAgain()"
                    [afTranslate]="'front-login.try-again'">_Volver a intentar</button>
            </div>
            <div class="front-login__custom-information-block"
                    [ngShow]="frontLogin.isLandingLogin && !frontLogin.public">
                <p class="front-login__information-question front-login__information-question--custom"
                    [afTranslate]="'front-login.information-question'">¿Necesitas ayuda?</p>
                <p class="front-login__information-contact front-login__information-contact--custom"
                    [afTranslate]="'front-login.contact-admin'">Contacta con el administrador de la tarea </p>
            </div>
        </div>
    </div>
</div>
