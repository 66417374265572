<div class="evaluation-header__block-new-version"
  [ngClass]="{'evaluation-header__block-new-version--concept': evaluationHeader.evaluatedConceptInfo.title,
            'evaluation-header__block-new-version--blanqueo': evaluationInfo.productId === 'blanqueo',
            'evaluation-header__block-new-version--consulta': evaluationInfo.productId === 'aml002'}">
  <div class="evaluation-header__block-container-new-version">
    <div *ngIf="evaluationInfo">
      <div [ngSwitch]="evaluationInfo.productId">
        <div *ngSwitchCase="'blanqueo'"
          class="evaluation-header__block-info-blanqueo">
          <div class="evaluation-header__block-left-blanqueo">
              <div [ngShow]="evaluationInfo.operationLocked && !evaluationInfo.isFinished"
                  class="evaluation-header__block-icon-alert">
                  <i class="lf-icon-alert evaluation-header__icon-alert"
                      [ngShow]="evaluationInfo.operationLocked && !evaluationInfo.isFinished"></i>
              </div>
              <div class="evaluation-header__button-blanqueo"
                  [ngShow]="evaluationInfo.operationNotSupported">
                  <i class="lf-icon-close-round evaluation-header__icon-button-blanqueo"></i>
                  <span class="evaluation-header__text-button-blanqueo"
                    [afTranslate]="'evaluation-header.not-supported'">_No admitida</span>
              </div>
              <div class="evaluation-header__result-block-blanqueo"
                  [ngClass]="{'evaluation-header__result-block-blanqueo--locked':evaluationInfo.operationLocked && !evaluationInfo.isFinished,
                          'evaluation-header__result-block-blanqueo--unadmitted':evaluationInfo.operationNotSupported}"
                  [ngStyle]="{'border-color': evaluationInfo?.totalScore?.color}"
                  [ngShow]="evaluationInfo?.totalScore?.message">
                  <div class="evaluation-header__button-blanqueo evaluation-header__button-blanqueo--result"
                      [ngStyle]="{'background-color':evaluationInfo?.totalScore?.color}">
                      <i class="lf-icon-warning evaluation-header__icon-button-blanqueo"
                          matTooltip="{{evaluationInfo?.totalScore?.risk}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                        </i>
                      <span class="evaluation-header__text-button-blanqueo"
                        [afTranslate]="'customize.result'">_Resultado</span>
                  </div>
                  <div class="evaluation-header__block-text-blanqueo">
                    <span-tooltip class="evaluation-header__result-text-blanqueo common__ellipsis"
                      [text]="evaluationInfo?.totalScore?.message"></span-tooltip>
                  </div>
              </div>
          </div>
          <div class="evaluation-header__block-right-blanqueo">
              <div class="common__table-display">
                  <div class="common__input-text common__table-cell-tooltip">
                      <h2 id="evaluation-name-header"
                          class="common__ellipsis evaluation-header__evaluation-title evaluation-header__evaluation-title--blanqueo evaluation-header__evaluation-title--{{skin}}"
                          ngbTooltip="{{evaluationHeader.title}}"
                          placement="bottom"
                          container="body"
                          tooltipClass="compliance__tooltip-{{skin}}"
                          triggers="manual"
                          #tooltip="ngbTooltip"
                          (mouseenter)="showTooltip('evaluation-name-header') && tooltip.open()"
                          (mouseleave)="tooltip.close()">
                          <span id="span-evaluation-name-header">
                              <button class="evaluation-header__block-icon-box evaluation-header__block-icon-box--blanqueo"
                                  [ngShow]="draftEvaluations"
                                  (click)="showDraftEvaluations()">
                                  <i class="lf-icon-mandatory-field evaluation-header__alert-icon"></i>
                              </button>
                              <span>{{evaluationHeader.title}}</span>
                          </span>
                      </h2>
                  </div>
              </div>
              <div class="common__table-display">
                  <div class="common__input-text common__table-cell-tooltip evaluation-header__block-client">
                      <div class="evaluation-header__client-title-block-new-version">
                          <h3 id="evaluation-client-name-header"
                              class="common__ellipsis evaluation-header__client-title"
                              ngbTooltip="{{evaluationInfo.clientName}}"
                              placement="bottom"
                              container="body"
                              tooltipClass="compliance__tooltip-{{skin}}"
                              triggers="manual"
                              #tooltip="ngbTooltip"
                              (mouseenter)="showTooltip('evaluation-client-name-header') && tooltip.open()"
                              (mouseleave)="tooltip.close()">
                              <span id="span-evaluation-client-name-header">
                                  <span class="evaluation-header__recurrent-tag"
                                      [ngShow]="skin !== 'steel-blue' && evaluationInfo.isClientRecurrent"
                                      [afTranslate]="'common.recurrent'">_Recurrente</span>
                                  <span>{{evaluationInfo.clientName}}</span>
                              </span>
                          </h3>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="evaluation-header__block-info-new-version"
          *ngSwitchDefault>
          <div class="common__table-display">
              <div class="common__input-text common__table-cell-tooltip">
                  <h2 class="common__ellipsis evaluation-header__evaluation-title"
                      [ngClass]="{'evaluation-header__evaluation-title--consult':evaluationInfo.productTypeId === 4,
                        'evaluation-header__evaluation-title--blanqueo':evaluationInfo.productId === 'blanqueo'}">
                      <span-tooltip [text]="evaluationHeader.title"></span-tooltip>
                  </h2>
                  <div [ngShow]="evaluationInfo.productTypeId !== 4">
                      <div class="evaluation-header__progress-bar-block"
                          [ngShow]="esBBDD">
                          <div class="evaluation-header__progress-bar">
                              <div [class]="'evaluation-header__progress-complete evaluation-header__progress-complete--status-'+evaluationInfo.status"></div>
                              <div [class]="'evaluation-header__progress-percent evaluation-header__progress-percent--status-'+evaluationInfo.status"
                                  [ngStyle]="{width:evaluationInfo.progress+'%'}"></div>
                          </div>
                          <p [class]="'evaluation-header__progress-percent-number evaluation-header__progress-percent-number--status-'+evaluationInfo.status">
                              {{evaluationInfo.progress}}%
                              <span [ngShow]="skin === 'gm-law'"
                                  class="evaluation-header__progress-percent-text"
                                  [afTranslate]="'common.completed'">Completado</span>
                          </p>
                      </div>
                      <button class="evaluation-header__block-icon-box"
                          [ngShow]="draftEvaluations"
                          (click)="showDraftEvaluations()">
                          <i class="lf-icon-mandatory-field evaluation-header__alert-icon"></i>
                      </button>
                  </div>
              </div>
          </div>
          <div class="common__table-display"
              [ngShow]="evaluationInfo.productTypeId !== 4 && skin !== 'uk-steel-blue'">
              <div class="common__input-text common__table-cell-tooltip evaluation-header__block-client">
                  <div class="evaluation-header__client-title-block-new-version">
                      <h3 class="common__ellipsis evaluation-header__client-title">
                          <span-tooltip [text]="evaluationInfo.clientName"></span-tooltip>
                      </h3>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="evaluation-header__breadcrumb-block-new-version"
        *ngIf="skin !== 'uk-steel-blue'">
        <i class="lf-icon-breadcrumbs evaluation-header__breadcrumb-icon-new-version"></i>
        <div class="evaluation-header__breadcrumb-list-block-new-version">
            <ul class="evaluation-header__breadcrumb-list-new-version">
                <li class="evaluation-header__breadcrumb-item-new-new-version"
                    *ngFor="let breadcrumb of evaluationHeader.breadcrumbList | afOrderBy:['level']:false; let last = last; let index = index"
                    [ngClass]="{'evaluation-header__breadcrumb-item-new-new-version--active':last && !evaluationHeader.evaluatedConceptInfo.title,
                        'evaluation-header__breadcrumb-item-new-new-version--hidden': breadcrumb.hidden}"
                    [ngStyle]="{'max-width': evaluationHeader.breadcrumbMaxWidth}">
                    <div class="evaluation-header__breadcrumb-text-new-version"
                        [ngClass]="{'evaluation-header__breadcrumb-text-new-version--last':last && !evaluationHeader.evaluatedConceptInfo.title && evaluationHeader.showMenuOption && breadcrumb.folderId !== 'reports',
                                'evaluation-header__breadcrumb-text-new-version--with-icon': breadcrumb.hidden || breadcrumb.confidential}"
                        (click)="launchAction($event, 'open', breadcrumb, index)">
                        <i class="evaluation-header__icon-invisible-new-version lf-icon-not-visible"
                            [ngShow]="breadcrumb.hidden"></i>
                        <div class="evaluation-header__block-confident"
                            ngbDropdown
                            [id]="'evaluation-header-confidential'+ breadcrumb.folderId"
                            [autoClose]="'outside'"
                            [ngShow]="breadcrumb.confidential">
                            <button class="evaluation-menu__confident-subfolder"
                                [ngClass]="{'evaluation-menu__confident-subfolder--basic': !(last && !evaluationHeader.evaluatedConceptInfo.title) && !breadcrumb.hidden,
                                    'evaluation-menu__confident-subfolder--hidden': breadcrumb.hidden,
                                    'evaluation-menu__confident-subfolder--active': last && !evaluationHeader.evaluatedConceptInfo.title && !breadcrumb.hidden}"
                                ngbDropdownToggle
                                matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="$event.stopPropagation()">
                                <i class="lf-icon-lock"></i>
                            </button>
                            <list-in-dropdown *ngIf="breadcrumb.confidentialList"
                                ngbDropdownMenu
                                class="dropdown-menu evaluation-menu__box-select-list dropdown-menu evaluation-menu__box-select-list--little"
                                (closeDropdownEmit)="closeDropdown('evaluation-header-confidential'+ breadcrumb.folderId)"
                                [list]="breadcrumb.confidentialList"
                                [type]="'confidential'"></list-in-dropdown>
                        </div>
                        <div class="evaluation-header__breadcrumb-text-span"
                          [ngClass]="{'evaluation-header__breadcrumb-text-span--one-icon': (breadcrumb.hidden && !breadcrumb.confidential) || (!breadcrumb.hidden && breadcrumb.confidential),
                              'evaluation-header__breadcrumb-text-span--two-icon': breadcrumb.hidden && breadcrumb.confidential}"
                          ngbTooltip="{{breadcrumb.name}}"
                          placement="bottom"
                          container="body"
                          tooltipClass="compliance__tooltip-{{skin}}"
                          triggers="manual"
                          #tooltip="ngbTooltip"
                          (mouseenter)="showTooltip('evaluation-breadcrumb-'+breadcrumb.folderId) && tooltip.open()"
                          (mouseleave)="tooltip.close()"
                          [id]="'evaluation-breadcrumb-'+breadcrumb.folderId">
                          <span [id]="'span-evaluation-breadcrumb-'+breadcrumb.folderId">{{breadcrumb.name}}</span>
                        </div>
                    </div>
                    <div class="evaluation-header__dropdown-menu-new-version"
                        [ngShow]="last && evaluationHeader.showMenuOption && evaluationHeader.userPermissions === 'all'"
                        ngbDropdown
                        (openChange)="openFolderMenu(breadcrumb)">
                        <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--25 evaluation-menu__list-menu-button--folder lf-icon-meatballs-menu"
                            [ngShow]="!evaluationHeader.evaluatedConceptInfo.title"
                            ngbDropdownToggle>
                        </button>
                        <ul class="evaluation-menu__list-menu-block"
                            ngbDropdownMenu
                            [ngShow]="breadcrumb.showMenuHeader">
                            <li class="common__list-dropdown-option"
                                *ngFor="let option of breadcrumb.menuListHeader; let lastOption = last">
                                <button class="common__button-option-list-dropdown "
                                    [ngClass]="{'common__button-option-list-dropdown--last': lastOption}"
                                    (click)="launchAction($event, option.action, breadcrumb)">
                                    <i [class]="' '+option.icon+' common__icon-option-list-dropdown'"></i>
                                    <span class="common__text-menu-option-dropdown"
                                      [afTranslate]="option.name"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <i class="lf-icon-angle-right evaluation-header__icon-angle-right-new-version"
                        [ngShow]="!last || evaluationHeader.evaluatedConceptInfo.title"></i>

                </li>

                <li class="evaluation-header__breadcrumb-item-new-new-version evaluation-header__breadcrumb-item-new-new-version--active"
                    [ngShow]="evaluationHeader.evaluatedConceptInfo.title"
                    [ngStyle]="{'max-width': evaluationHeader.breadcrumbMaxWidth}">
                    <span-tooltip class="evaluation-header__breadcrumb-text-new-version evaluation-header__breadcrumb-text-span"
                      [text]="evaluationHeader.evaluatedConceptInfo.title"></span-tooltip>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="evaluation-header__block-show-route"
    [ngShow]="esBBDD">
    <div [ngShow]="evaluationHeader.breadcrumbList.length > 5"
        class="evaluation-header__show-route-new-version"
        [ngClass]="{'evaluation-header__show-route--concept': evaluationHeader.evaluatedConceptInfo.title}"
        (click)="evaluationHeader.showRoutes = !evaluationHeader.showRoutes">
        <span [afTranslate]="'evaluation-header.show-route'">_Ver ruta completa</span>
        <i class="lf-icon-double-arrow-right evaluation-header__double-arrow-route"></i>
    </div>
    <div class="evaluation-header__block-route-new-version"
        [ngClass]="{'evaluation-header__block-route--concept':evaluationHeader.evaluatedConceptInfo.title}">
        <div class="evaluation-header__modal-route"
            [ngShow]="evaluationHeader.showRoutes">
            <h5 class="concept-form__title-form-subgroup"
                [afTranslate]="'evaluation.navegatión-route'">
                _Ruta de navegación</h5>
            <i class="concept-form__close-icon lf-icon-close"
                (click)="evaluationHeader.showRoutes = !evaluationHeader.showRoutes"></i>
            <div class="evaluation-header__block-list-route">
                <ul class="evaluation-header__route-list">
                    <li *ngFor="let route of evaluationHeader.breadcrumbList; let index = index; let first = first; let last = last"
                        class="evaluation-header__element-route-list"
                        [ngClass]="{'evaluation-header__element-route-list--last-without-concept' : last && !evaluationHeader.evaluatedConceptInfo.title}"
                        (click)="launchAction($event, 'open', route, index)">
                        <i class="lf-icon-arrow-round-down evaluation-header__icon-arrow-element"
                            [ngClass]="{'evaluation-header__icon-arrow-element--first':first,
                                'evaluation-header__icon-arrow-element--last': last && !evaluationHeader.evaluatedConceptInfo.title}"></i>
                        <span class="evaluation-header__name-element-route"
                            [ngClass]="{'evaluation-header__name-element-route--first' : first}">
                            {{route.name}}
                        </span>
                        <i class="lf-icon-arrow-back evaluation-header__icon-directions"
                            [ngShow]="!last || evaluationHeader.evaluatedConceptInfo.title"></i>
                    </li>
                    <li class="evaluation-header__concept-route-list"
                        [ngShow]="evaluationHeader.evaluatedConceptInfo && evaluationHeader.evaluatedConceptInfo.title">
                        <i class="lf-icon-map-marker evaluation-header__icon-map-marker-route"></i>
                        <span class="evaluation-header__name-element-route">
                          {{evaluationHeader.evaluatedConceptInfo.title}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>
