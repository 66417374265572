import moment from 'moment';

import { Component, HostListener } from '@angular/core';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import $ from 'jquery';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  // isLanding: any = this.route.snapshot.data['isLanding'];
  isLanding: any = location.pathname.indexOf('public/') !== -1 || location.pathname.indexOf('shared/') !== -1 || location.pathname.indexOf('contact-login') !== -1;
  skin: string;
  app:any ={};
  loadAllSources: boolean = false;
  constructor(private translate: AfTranslateFactory,
    private globalCfg: GlobalCfgFactory,
    private apiProperties: ApiPropertiesFactory,
    private context: ContextFactory,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private registerMixtape: RegisterMixtapeService) {
      this.registerMixtape.init();
      if(window.location.href.indexOf('login?target=root.dashboard') !== -1 || window.location.href.indexOf('login?target=%2Fdashboard') !== -1){
          window.open(window.location.origin, '_self');
      }else{
          this.getProperties();
      }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    this.modalService.close();
    let hasFolder : boolean = window.location.pathname.indexOf('folder') !== -1;
    let hasFavorites : boolean = window.location.pathname.indexOf('favorites') !== -1;
    let hasReports : boolean = window.location.pathname.indexOf('reports') !== -1;
    let hasEvaluation : boolean = window.location.pathname.indexOf('evaluation') !== -1;
    if(this.route.firstChild?.snapshot.data['componentName'] === 'evaluation' && hasEvaluation && !hasFolder && !hasFavorites && !hasReports){
        window.history.back();
    }
  }

  getDefaultLanguage(){
    let browserLanguage: any = sessionStorage.getItem('selectLanguage');
    if (!browserLanguage || browserLanguage === "null") {
      let browserLanguageSupported: boolean = false;
      browserLanguage = this.configureNavigatorLanguage();
      browserLanguageSupported = Boolean(browserLanguage);
      if(!browserLanguageSupported){
        browserLanguage = this.apiProperties.defaultLanguage;
      }
      browserLanguage = this.setDefaultUk(browserLanguage);
    }
    this.translate.setDefaultLang(browserLanguage)
      .then((data)=>{
        this.translate.setSiteTerms().then( () => {
          this.getGlobalCfg();
        })
      }, (errorData:any)=>{});
  }

  configureNavigatorLanguage(){
      let navigatorLanguage: string = navigator.language;
      let browserLanguageSupported: boolean = this.apiProperties.availableLanguages.indexOf(navigatorLanguage) !== -1;
      if (!browserLanguageSupported) {
          let browserLanguage = navigatorLanguage.split('-')[0];
          navigatorLanguage = this.apiProperties.availableLanguages.find((item:any)=> item.indexOf(browserLanguage) === 0);
      }
      return navigatorLanguage;
  }

  setDefaultUk(browserLanguage: any){
    if(this.apiProperties.countryApi === 'UK' && browserLanguage === 'en-GB'){
      return 'taxwin_en-GB';
    }
    return browserLanguage;
  }

  getProperties(){
    this.apiService.getConfig().then((data)=>{
        this.apiProperties.setApiProperties(data);
        this.getDefaultLanguage();
    })
  }

  getGlobalCfg(){
    if(this.globalCfg.loaded){
      this.configureGlobalCfg();
    }else{
      this.apiService.getGlobal()
      .then((data:any)=>{
          this.globalCfg.setGlobalCfg(data.data);
          this.configureGlobalCfg();
      })
    }
  }

  configureGlobalCfg(){
    this.skin = this.isLanding?'landing-custom':this.globalCfg.skin;
    this.initWithProperties();
    document.body.classList.add('compliance__'+this.skin);
  }

  initWithProperties(){
    let browserLanguageSupported : boolean = false;
    let browserLanguage:any = sessionStorage.getItem('selectLanguage');
    if (!browserLanguage || browserLanguage === "null") {
        browserLanguage = this.configureNavigatorLanguage();
        browserLanguageSupported = Boolean(browserLanguage);
    } else {
      browserLanguageSupported = true;
    }

    if (!browserLanguageSupported) {
        console.log('Browser language ' + (navigator.language) + ' not supported. Defaulting to ' +this.apiProperties.defaultLanguage);
        browserLanguage = this.apiProperties.defaultLanguage;
    }
    let languageKey = browserLanguage;
    this.translate.languagesUseBase(languageKey);
    // sessionStorage.setItem('selectLanguage', languageKey);
    let availableJsonLanguages:any = this.apiProperties.availableLanguages;
    if (this.globalCfg.skin === 'uk-steel-blue' && languageKey === 'en-GB') {
        languageKey = 'taxwin_en-GB';
        availableJsonLanguages = this.apiProperties.availableLanguages.concat([languageKey]); //Para no cambiar el availableLanguages "global"
    } else if (this.globalCfg.skin === 'steel-blue') {
      if (this.context.settings?.defaultProduct === 'bedrijfsst' || this.context.settings?.defaultProduct === 'blanqueo' || this.context.settings?.defaultProduct === 'whistle') {
          languageKey = this.context.settings?.defaultProduct + '_' + languageKey;
      } else {
          // Por defecto, AML
          let languageKeyConfigurated = languageKey === 'es-ES'?'en-US':languageKey;
          languageKey = 'blanqueo_' + languageKeyConfigurated;
      }
      availableJsonLanguages = this.apiProperties.availableLanguages.concat(['bedrijfsst_en-US', 'bedrijfsst_fr-BE', 'bedrijfsst_nl-BE', 'blanqueo_en-US', 'blanqueo_fr-BE', 'blanqueo_nl-BE', 'whistle_en-US', 'whistle_fr-BE', 'whistle_nl-BE']); //Para no cambiar el availableLanguages "global"
    }else if(this.globalCfg.skin === 'dark-blue'){
      if(this.context.settings?.defaultProduct === 'denunc' && languageKey === 'fr-FR'){
        languageKey = 'denunc_fr-FR';
      }
      availableJsonLanguages = this.apiProperties.availableLanguages.concat([languageKey]);
    }else if(this.globalCfg.skin === 'gm-law'){
      if(this.context.settings?.defaultProduct === 'denunc' && languageKey === 'it-IT'){
        languageKey = 'denunc_it-IT';
      }
      availableJsonLanguages = this.apiProperties.availableLanguages.concat([languageKey]);
    }
    moment.updateLocale(browserLanguage.split('-')[0], {
      week: {
          dow: 1
      }
    });
    this.translate.registerAvailableLanguageKeys(availableJsonLanguages)
    this.translate.preferredLanguage(languageKey)

    if(this.translate.hasLanguage(languageKey)){
      this.translate.setLanguageToTranslate(languageKey)
      this.loadAllSources = true;
      this.configureFavicon();
    }else{
      this.translate.getTranslates(languageKey).then((data:any)=>{
        this.loadAllSources = true;
        this.configureFavicon();
      }, (errorData:any)=>{})
    }
  }

  configureFavicon(){
    if (this.globalCfg.skin === 'uk-steel-blue') {
            this.app.pagePreferences = {
                title: 'Indicator FLM - TaxWin',
                fav: '../assets/img/favicon-Taxwin.ico'
            }
        setTimeout(()=> {
            document.title = this.app.pagePreferences.title;
            this.setFavicon(this.app.pagePreferences.fav);
        });
    }else{
        this.app.pagePreferences = {
            title: 'Lefebvre-Centinela',
            fav: this.isLanding? null : '../assets/img/favicon-lefebvre.ico'
        }
        if(this.globalCfg.skin === 'icam-red'){
            this.app.pagePreferences = {
                title: this.isLanding?'Consultas documentales - UGC':'Gestión del Conocimiento',
                fav: this.isLanding?null:'../assets/img/favicon-icam.ico'
            }
        }else if (this.globalCfg.skin === 'steel-blue') {
            this.app.pagePreferences = {
                title: this.isLanding?'Whistleblowing Channel':'GoComply',
                fav: this.isLanding?null:'../assets/img/favicon-gocomply.ico'
            }
        }else if(this.globalCfg.skin === 'gm-law'){
            this.app.pagePreferences = {
              title: 'Segnala',
              fav: '../assets/img/favicon-segnala.ico'
            }
        }
        document.title = this.app.pagePreferences.title;
        this.isLanding?this.getGlobalIdentity():this.setFavicon(this.app.pagePreferences.fav);
    }
  }

  getGlobalIdentity(){
      let targetSplit;
      let href:any = window.location.href;
      let loginInfoSession:any = sessionStorage.getItem('loginInfo');
      let access:any = this.route.snapshot.queryParams['access'];
      if(href.indexOf('/public/concept/')!== -1){
          targetSplit = href.substring(href.indexOf('/public/concept/')+('/public/concept/').length, href.indexOf('?'));
          this.getGlobalCustom('concepts/publicconfigurationfavicon/concept', targetSplit);
      }else if(href.indexOf('taskId') !== -1){
          targetSplit = href.split('taskId%3D');
          if(targetSplit.length < 2){
              targetSplit = href.split('taskId%253D');
          }
          this.getGlobalCustom('company/globalcustomization/bytask', targetSplit[1]);
      }else if(href.indexOf('conceptObjectId') !== -1){
          targetSplit = href.split('conceptObjectId%3D');
          if(targetSplit.length < 2){
              targetSplit = href.split('conceptObjectId%253D');
          }
          let fieldContainerIdIdx = targetSplit[1].indexOf('%26fieldContainerId%3D')
          let conceptObjectId:any = fieldContainerIdIdx == -1?targetSplit[1] : targetSplit[1].substring(0, fieldContainerIdIdx)
          this.getGlobalCustom('company/globalcustomization/byconceptobject', conceptObjectId);
      }else if(href.indexOf('/shared/concept-evaluated/')!== -1){
          let position1 = href.indexOf('concept-evaluated');
          let position = href.indexOf('/', position1)+1;
          let positionEnd = href.indexOf('/', position) !== -1?href.indexOf('/', position):href.length;
          let conceptObjectId = href.substring(position, positionEnd);
          this.getGlobalCustom('company/globalcustomization/byconceptobject', conceptObjectId);
      }else if(href.indexOf('/shared/task/')!== -1){
          targetSplit = href.substring(href.indexOf('/shared/task/')+('/shared/task/').length,href.length);
          this.getGlobalCustom('company/globalcustomization/bytask', targetSplit);
      } else if(href.indexOf('meetingId') !== -1){
          targetSplit = href.split('meetingId%3D');
          let meetingSplit = targetSplit[1].split('%');
          this.getGlobalCustom('company/globalcustomization/bytask', meetingSplit[0]);
      } else if((href.indexOf('/contact-login') !== -1 || href.indexOf('/public/monitoring') !== -1) && access){
          this.getGlobalCustom('concepts/publicconfigurationfavicon?access='+ encodeURIComponent(access), null);
      }else if(href.indexOf('/public/monitoring') !== -1 && loginInfoSession){
          let conceptObjectId = this.route.snapshot.queryParams['conceptObjectId'];
          if(!conceptObjectId){
              let loginInfo = JSON.parse(loginInfoSession.split('/'));
              if(loginInfo?.target?.targetType === 'ConceptObject'){
                  conceptObjectId = loginInfo.target.targetId;
              }
          }
          this.getGlobalCustom('company/globalcustomization/byconceptobject', conceptObjectId);
      }else{
          this.getGlobalCustom('company/globalcustomization', 'default');
      }
  }

  getGlobalCustom(url:string, id:any){
      let that = this;
      this.apiService.getNoBearer(url, id)
          .then((data:any)=>{
              let favicon = data?.favIcon?data.favIcon:null;
              this.configureCustomFavicon(favicon);
          }, function(errorData){
              that.configureCustomFavicon(null);
          })
  }

  configureCustomFavicon(favicon:any){
      let that = this;
      let pagePreferences: any = this.app.pagePreferences;
      pagePreferences.fav = !favicon || favicon === null?pagePreferences.fav: URL.createObjectURL(this.commonUtilsService.baseToFormdata(this.commonUtilsService.setLogo(favicon,favicon), 'ico'));
      setTimeout(function(){
          that.setFavicon(pagePreferences.fav);
      })
  }

  setFavicon(favicon:any){
      let faviconElement: any = document.getElementById('favicon');
      faviconElement.href = favicon;
      // $('head').append('<link rel="shortcut icon" type="image/vnd.microsoft.icon" href="'+favicon+'"/>');
  }
}
