<div class="result-search common__panel">
  <header-nav></header-nav>
  <!-- cerrar resultados (Documentos) -->
  <div class="common__block-button-close-window">
      <button (click)="changeView()"
          class="common__back-link">
          <span [afTranslate]="'all-documentation.close-documents'">CERRAR DOCUMENTOS</span>
          <i class="lf-icon-close common__back-link-icon"></i>
      </button>
  </div>
  <div class="global-customization__block-container">
  <!-- FIN cerrar resultados (Documentos) -->
      <!-- texto resultados (Documentos) -->
      <div class="common__block-list-new-version common__block-list-new-version--height-auto">
          <div class="common__header-new-version result-search__header-v2">
              <i class="lf-icon-hamburger-menu common__header-icon-new-version"
                  [ngShow]="skin !== 'uk-steel-blue'"></i>
              <span class="common__header-title-new-version"
                  [afTranslate]="'all-documentation.all-documents'">Resultados de la búsqueda</span>
              <div class="result-search__totals-block"
                  *ngIf="allDocumentation.filterDocumentationList">
                  <p class="result-search__totals-text"
                      [afTranslate]="'common.total'"></p>
                  <i class="common__rectangle-info all-documentation__rectangle-info">{{allDocumentation.filterDocumentationList.length}}</i>
              </div>
          </div>
          <!-- FIN texto resultados (Documentos) -->
          <!-- no results -->
          <div class="result-search__none-results-content-block"
            *ngIf="!allDocumentation.filterDocumentationList">
            <i class="lf-icon-search result-search__icon-none-result"></i>
            <p class="result-search__none-primary-text"
                [afTranslate]="'result-search.title-not-found'">¡Lo sentimos!</p>
            <p class="result-search__none-secondary-text"
              [afTranslate]="'all-documentation.documents-not-found'">No se han encontrado documentos</p>
          </div>
          <!-- FIN no results -->
          <!-- SOLO Documentos -->
          <div class="result-search__block-results"
            *ngIf="allDocumentation.filterDocumentationList">
              <div class="result-search__settings-block">
                  <div class="result-search__body-setting-header">
                      <div class="result-search__enable-deleted-container">
                          <field-type-switch [label]="'result-search.include-deleted' | afTranslate"
                                            [checked]="allDocumentation.includeDeletedDocuments"
                                            (callback)="changeDeleted($event.value)">
                          </field-type-switch>
                      </div>
                      <div class="result-search__block-icon-filter"
                          [ngClass]="allDocumentation.someSelected? 'result-search__block-icon-filter--delete':'result-search__block-icon-filter--clear'">
                          <button class="result-search__button-filter"
                              [ngClass]="allDocumentation.someSelected?'result-search__button-filter--delete lf-icon-filter-delete':'result-search__button-filter--clear lf-icon-filter-1'"
                              matTooltip="{{'result-search.remove-filters' | afTranslate}}"
                              matTooltipPosition="left"
                              matTooltipClass="left"
                              [matTooltipDisabled]="!allDocumentation.someSelected"
                              (click)="deleteFilters()"
                              [disabled]="!allDocumentation.someSelected">
                          </button>
                      </div>
                  </div>
                  <div class="result-search__body-setting">
                      <!-- ajustes (Documentos) -->
                      <div class="result-search__block-title-search">
                          <i class="lf-icon-review result-search__icon-search"></i>
                          <span class="result-search__title-text"
                              [afTranslate]="'all-documentation.find-documents'">Buscar en los resultados</span>
                      </div>
                      <!-- FIN ajustes (Documentos) -->
                      <!-- filtro (Documentos) -->
                      <div class="result-search__block-subtitle-search">
                          <span [afTranslate]="'all-documentation.filter-results-text'">Escribe una o varias palabras para refinar la búsqueda e indica si quieres buscarlas también en el contenido de los documentos.</span>
                      </div>
                      <div class="result-search__block-lists">
                          <div class="header-nav__block-search header-nav__block-search--documentation result-search__block-search"
                              [ngClass]="{'header-nav__block-search--without-language': allDocumentation.hiddenLanguage}">
                              <input type="text"
                                class="header-nav__search-input"
                                [ngClass]="{'header-nav__search-input--with-text': allDocumentation.searchText}"
                                [placeholder]="'common.search' | afTranslate"
                                [(ngModel)]="allDocumentation.searchText"
                                (keypress)="filterOrResetEnter($event)"
                                [focusWhen]="allDocumentation.search">
                              <button class="header-nav__icon-button header-nav__icon-button--documentation"
                                      (click)="filterOrReset()">
                                  <i class="header-nav__icon-search"
                                      [ngClass]="allDocumentation.searchText === allDocumentation.searchedText && allDocumentation.searching ?'lf-icon-close-search': 'lf-icon-search'"></i>
                              </button>
                          </div>
                          <div class="result-search__enable-deleted-container"
                              [ngShow]="allDocumentation.indexedSearch">
                              <field-type-switch [label]="'all-documentation.include-content' | afTranslate"
                                                [checked]="allDocumentation.includeContent"
                                                (callback)="changeContent($event.value)">
                              </field-type-switch>
                          </div>
                      </div>
                      <!-- ajustes (Documentos) -->
                      <div class="result-search__block-title-search">
                          <i class="lf-icon-settings result-search__icon-search"></i>
                          <span class="result-search__title-text"
                              [afTranslate]="'result-search.search-settings'">Ajustes de la búsqueda</span>
                      </div>
                      <!-- FIN ajustes (Documentos) -->
                      <!-- filtro (Documentos) -->
                      <div class="result-search__block-subtitle-search">
                          <span [afTranslate]="'result-search.filter-results-text'">Filtra los resultados por uno o más criterios:</span>
                      </div>
                      <div class="result-search__block-lists"
                        *ngIf="allDocumentation.chargedDocumentation">
                          <div *ngIf="skin !== 'icam-red'">
                            <field-type-list-multiple *ngIf="skin !== 'uk-steel-blue'"
                                [field]="allDocumentation.evaluationNameField"
                                [listValues]="allDocumentation.evaluationNameList"
                                [preSelectItem]="allDocumentation.evaluationName"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'evaluationName')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.clientNameField"
                                [listValues]="allDocumentation.clientNameList"
                                [preSelectItem]="allDocumentation.clientName"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'clientName')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                          </div>
                          <field-type-list-multiple [field]="allDocumentation.creadorField"
                              [listValues]="allDocumentation.creadorList"
                              [preSelectItem]="allDocumentation.creador"
                              [fieldToFind]="'text'"
                              (itemSelected)="itemSelected($event.newValue, 'creador')"
                              [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [field]="allDocumentation.contentTypeField"
                              [listValues]="allDocumentation.contentTypeList"
                              [preSelectItem]="allDocumentation.contentType"
                              [fieldToFind]="'text'"
                              (itemSelected)="itemSelected($event.newValue, 'contentType')"
                              [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [field]="allDocumentation.estadoField"
                              [listValues]="allDocumentation.estadoList"
                              [preSelectItem]="allDocumentation.estado"
                              [fieldToFind]="'text'"
                              (itemSelected)="itemSelected($event.newValue, 'estado')"
                              [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [field]="allDocumentation.origenField"
                              [listValues]="allDocumentation.origenList"
                              [preSelectItem]="allDocumentation.origen"
                              [fieldToFind]="'text'"
                              (itemSelected)="itemSelected($event.newValue, 'origen')"
                              [orderBy]="'text'">
                          </field-type-list-multiple>
                          <div *ngIf="skin === 'icam-red'">
                            <div class="result-search__other-rules">
                                <span [afTranslate]="'all-documentation.other-rules'"> otros criterios asociados a la consulta:</span>
                            </div>
                            <field-type-list-multiple [field]="allDocumentation.tipoConsultaField"
                                [listValues]="allDocumentation.tipoConsultaList"
                                [preSelectItem]="allDocumentation.tipoConsulta"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'tipoConsulta')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.usuarioField"
                                [listValues]="allDocumentation.usuarioList"
                                [preSelectItem]="allDocumentation.usuario"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'usuario')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.gestorField"
                                [listValues]="allDocumentation.gestorList"
                                [preSelectItem]="allDocumentation.gestor"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'gestor')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.relevanciaField"
                                [listValues]="allDocumentation.relevanciaList"
                                [preSelectItem]="allDocumentation.relevancia"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'relevancia')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.etiquetasField"
                                [listValues]="allDocumentation.etiquetasList"
                                [preSelectItem]="allDocumentation.etiquetas"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'etiquetas')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-list-multiple [field]="allDocumentation.materiaField"
                                [listValues]="allDocumentation.materiaList"
                                [preSelectItem]="allDocumentation.materia"
                                [fieldToFind]="'text'"
                                (itemSelected)="itemSelected($event.newValue, 'materia')"
                                [orderBy]="'text'">
                            </field-type-list-multiple>
                            <field-type-date [field]="allDocumentation.consultDateField"
                                [preSelectItem]="allDocumentation.consultDate"
                                (itemSelected)="itemSelected($event.newValue, 'consultDate')">
                            </field-type-date>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="result-search__results-content-block">
                  <div class="result-search__block-header-search result-search__block-header-search--documentation">
                      <div class="result-search__block-select-order  result-search__block-select-order--documentation">
                          <div class="result-search__block-orderby">
                              <div class="result-search__block-text-orderby">
                                  <i class="lf-icon-arrow-down result-search__icon-arrow-order"
                                      [ngClass]="allDocumentation.orderDirection?'lf-icon-arrow-up':'lf-icon-arrow-down'"
                                      (click)="allDocumentation.orderDirection = !allDocumentation.orderDirection"></i>
                                  <span class="result-search__text-orderby"
                                      [afTranslate]="'result-search.order-by'">Ordenado por:</span>
                                  <button class="result-search__button-orderby"
                                      *ngIf="allDocumentation.includeContent && skin !== 'icam-red'"
                                      [ngClass]="{'result-search__button-orderby--selected' : allDocumentation.orderType === 'Relevance'}"
                                      (click)="orderBy('Relevance')"
                                      [afTranslate]="'result-search.relevance'">RELEVANCIA</button>
                                  <button class="result-search__button-orderby"
                                      [ngClass]="{'result-search__button-orderby--selected' : allDocumentation.orderType === 'creacion'}"
                                      (click)="orderBy('creacion')"
                                      [afTranslate]="'result-search.most-recent'">MÁS RECIENTES</button>
                                  <button class="result-search__button-orderby"
                                      [ngClass]="{'result-search__button-orderby--selected' : allDocumentation.orderType === 'name'}"
                                      (click)="orderBy('name')"
                                      [afTranslate]="'all-documentation.alphabetical'">ALFABETICAMENTE</button>
                              </div>
                          </div>
                          <div class="result-search__block-collapse">
                              <button class="result-search__button-collapse"
                                      (click)="collapseResult()">
                                  <i class="result-search__icon-arrow-order"
                                      [ngClass]="allDocumentation.showContent?'lf-icon-collapse':'lf-icon-expand-vertical'"></i>
                                  <span class="result-search__text-order-collapse">{{allDocumentation.textCollapse}}</span>
                              </button>
                          </div>
                      </div>
                  </div>

                  <!-- resultados (Documentos) -->
                  <div class="result-search__block-search-results">
                      <div [ngShow]="! allDocumentation.filterDocumentationList.length"
                          class="result-search__none-results-element">
                          <i class="lf-icon-step-final result-search__icon-circle"></i>
                          <span class="result-search__none-results-element-span"
                              [afTranslate]="'result-search.subtitle-not-found-category'">No se han encontrado resultados en esta categoría.</span>
                      </div>
                      <div class="result-search__results-repeat"
                          [ngShow]=" allDocumentation.filterDocumentationList.length > 0"
                          [ngClass]="{'result-search__results-repeat--collapsed': !allDocumentation.showContent,
                              'result-search__results-repeat--first': index === 0}"
                          *ngFor="let result of allDocumentation.filterDocumentationList | afOrderBy:allDocumentation.orderType: allDocumentation.orderDirection | afLimitTo:allDocumentation.configPagination.pageSize:allDocumentation.configPagination.pageSize*(allDocumentation.currentPage - 1); let index = index">
                          <div class="result-search__line-circle"
                              [ngClass]="{'result-search__line-circle--collapsed': !allDocumentation.showContent}"></div>
                          <i class="lf-icon-step-final result-search__icon-circle"></i>
                          <div class="result-search__result-title">
                              <span class="result-search__name-document"
                                  [innerHTML]="replaceToColor(result.name)"></span>
                              <span *ngIf="result.isDeleted" class="result-search__is-deleted" [afTranslate]="'result-search.deleted'"></span>
                          </div>
                          <dl class="result-search__highlight-block" *ngIf="result.highlights">
                              <dt class="result-search__highlight-qoute-block">
                                  <i class="lf-icon-quote-open result-search__highlights-quote"></i>
                              </dt>
                              <dd class="result-search__highlights-text"
                                  [innerHTML]="replaceToColor(result.highlights, true)"></dd>
                          </dl>

                          <div class="result-search__result-content box-comments__vertical-block--last"
                              [ngShow]="allDocumentation.showContent">
                              <div class="result-search__result-content-element"
                                [ngShow]="skin !== 'icam-red'">
                                  <span class="result-search__title-header"
                                      [afTranslate]="'common.implantation'">IMPLANTACION</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.evaluationName}}</span>
                              </div>
                              <div class="result-search__result-content-element"
                                [ngShow]="skin !== 'icam-red'">
                                  <span class="result-search__title-header"
                                      [afTranslate]="'common.client'">CLIENTE</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.clientName}}</span>
                              </div>
                              <div class="result-search__result-content-element">
                                  <span class="result-search__title-header"
                                      [afTranslate]="skin==='icam-red'?'common.creator':'common.author'">AUTOR</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.creador}}</span>
                              </div>
                              <div class="result-search__result-content-element">
                                  <span class="result-search__title-header"
                                      [afTranslate]="'common.creation'">CREACIÓN</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.creacion | convertDate: 'without-time' }}</span>
                              </div>
                              <div class="result-search__result-content-element">
                                  <span class="result-search__title-header"
                                      [afTranslate]="'result-search.state'">ESTADO</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.estado}}</span>
                              </div>
                              <div class="result-search__result-content-element">
                                  <span class="result-search__title-header"
                                      [afTranslate]="'result-search.origin'">ORIGEN</span><span>:</span>
                                  <span class="result-search__title-text-search span__auto-ajust">{{result.origen}}</span>
                              </div>
                              <div [ngShow]="skin === 'icam-red'">
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'all-documentation.tipoConsulta'">tipo consulta</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.tipoConsulta}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'all-documentation.usuario'">Usuario</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.usuario}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'box-comunication-channel.manager'">Gestor</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.gestor}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'result-search.relevance'">Relevancia</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.relevancia}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'all-documentation.etiquetas'">Etiquetas</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.etiquetas | oneMore}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'concept-list.matter'">Materia</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.materia | oneMore}}</span>
                                </div>
                                <div class="result-search__result-content-element">
                                    <span class="result-search__title-header"
                                        [afTranslate]="'all-documentation.consult-date'">Fecha de consulta</span><span>:</span>
                                    <span class="result-search__title-text-search span__auto-ajust">{{result.fechaConsulta | convertDate:'without-time'}}</span>
                                </div>
                              </div>
                          </div>
                          <div class="result-search__block-icons-result">
                              <div class="result-search__table"
                                  ngbDropdown
                                  [autoClose]="'outside'"
                                  (openChange)="changeOpen(result)">
                                  <button class="lf-icon-kebab-menu documentation-list__options-button"
                                          ngbDropdownToggle></button>
                                  <ul class="result-search__list-menu-block dropdown-menu"
                                      ngbDropdownMenu>
                                      <li class="common__list-dropdown-option"
                                          *ngFor="let optionMenu of result.menuDocuments; let last = last">
                                          <button class="common__button-option-list-dropdown"
                                              [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                              (click)="buttonMenu(optionMenu.action, result)">
                                              <i [class]="optionMenu.icon + ' common__icon-option-list-dropdown'"></i>
                                              <span class="common__text-menu-option-dropdown"
                                                  [afTranslate]="optionMenu.name"></span>
                                          </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- PAGINACION -->
              <pagination-control class="result-search__pagination"
                  [ngShow]="allDocumentation.filterDocumentationList.length > allDocumentation.configPagination.pageSize"
                  [config]="allDocumentation.configPagination"
                  [collectionSize]="allDocumentation.filterDocumentationList.length"
                  [(currentPage)]="allDocumentation.currentPage">
              </pagination-control>
              <!-- FIN paginación (Documentos) -->
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
