import { Component, Input, OnInit } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'box-protection',
  templateUrl: './box-protection.component.html'
})
export class BoxProtectionComponent implements OnInit{

  boxProtection:any = {
    icon: '',
    title: '',
  }

  constructor(){}

  @Input() data: any = null;
  @Input() type: any = null;

  ngOnInit(): void {
    this.configureModalWindow();
  }

  configureModalWindow(){
    let boxProtection = this.boxProtection;
    switch(this.type){
      case 'additional':
        boxProtection.icon = 'lf-icon-information';
        boxProtection.title = 'channel-configuration.additional-info';
        boxProtection.subTitle = 'protection-data-modal.processing-personal-data';
        break;
      case 'protection':
        boxProtection.icon = 'lf-icon-shield';
        boxProtection.title = 'protection-data-modal.data-protection-information';
        boxProtection.subTitle = 'protection-data-modal.want-know';
        break;
      default:
        boxProtection.icon = 'lf-icon-legal-reference';
        boxProtection.title = 'channel-configuration.legal-info';
        boxProtection.subTitle = 'protection-data-modal.apply-regulations';
        break;
    }
  }

}
