
import { Component, OnInit, Input } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep, keyBy } from 'lodash';


@Component({
  selector: 'task-modal',
  templateUrl: './task-modal.component.html'
})
export class TaskModalComponent implements OnInit {
  taskModal: any = {
    chargeManagersClassificator: false,
    tinyMceOptions: {
      height: 161,
      typeView: 'rich-text',
      toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | bullist numlist outdent indent | removeformat | link'
    },
    titleField:  {
        required: true,
        maxLength: 250,
        label: this.translate.instant('common.title')
    },
    responsibleField: {
        label: this.translate.instant('process.general-task.recipient-label'),
        typeConfiguration: 'evaluationUsers',
        fieldType: 'multiple',
        addContacts: false,
        required: true,
        typeCall: 'users',
        selectContacts: []
    },

    responsibleFieldAssign: {
        label: this.translate.instant('process.general-task.recipient-label'),
        typeConfiguration: 'evaluationUsers',
        fieldType: 'multiple',
        addContacts: false,
        required: true,
        typeCall: 'users',
        setDisabled: true,
        selectContacts: []
    },
    frecuencyList: [{
        listValueId: 'D',
        text: this.translate.instant('repeat-pattern.days')
      },{
        listValueId: 'W',
        text: this.translate.instant('repeat-pattern.week-s')
      },{
        listValueId: 'M',
        text: this.translate.instant('repeat-pattern.month')
      },{
        listValueId: 'Y',
        text: this.translate.instant('repeat-pattern.year-s')
    }],
    managerClassificationField: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('process.general-task.assign-task')
    },
    managerClassificationOptions: [
      {
          listValueId:'0',
          text: this.translate.instant('process.general-task.fixed')
      },{
          listValueId:'1',
          text: this.translate.instant('process.general-task.state')
      }
    ],
    states:  [],
    usersAssign:  [],
    assignList: [],
    fieldListData:  [],
    task:  null,
    lockedSubcontainer: false

  }

  constructor(private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal,
    private loader: LoaderFactory,
    private modalService: ModalServiceService) { }

    @Input()  originalTask: any =  null;
    @Input()  concept: any = null;

  ngOnInit(): void {
    let that: any = this.taskModal;
    that.task = cloneDeep(this.originalTask);
    that.edit = !!that.task.processGeneralTaskId;
    if (that.task.responsibles?.length) {
        that.responsibleField.selectContacts = that.task.responsibles;
    }

    this.getStates();
    that.title = that.edit ? this.translate.instant('process.general-task.edit-dialog-title') : this.translate.instant('process.general-task.add-dialog-title');
    that.okButtonLabel = that.edit ? this.translate.instant('common.save') : this.translate.instant('common.create');
    that.expirationTask = Boolean(that.task.expirationUnits && that.task.expirationTimeUnit);
    that.task.expirationUnits = that.task.expirationUnits?that.task.expirationUnits:1;
    that.task.expirationTimeUnit = that.task.expirationTimeUnit?this.commonUtilsService.configureSelectedTimeUnit(that.task.expirationTimeUnit, that.frecuencyList):that.frecuencyList[0];
    that.task.dailyRepeatIdleTaskNotification = that.task.dailyRepeatIdleTaskNotification?that.task.dailyRepeatIdleTaskNotification:Boolean(!that.edit && this.concept.allowPublicShare && this.concept.communicationChannel);
    this.getManagerClassificator();
  }

  getManagerClassificator(){
    let that: any = this.taskModal;
    this.apiService.get('processgeneraltasks/process/'+that.task.processId, '/classificator-list')
        .then((data:any)=>{
            if(data !== null){
                data.multiple = true;
                that.fieldListData = data;
                that.task.isManagerClassification = true;
                that.managerClassificationOptions.push({
                    listValueId:'2',
                    text: this.translate.instant('process.general-task.type')
                })
                this.configureClassificatorList();
            }else{
                that.managerClassificationSelected = that.task.states?.length? '1' : '0';
            }
            that.chargeManagersClassificator = true;
        }, function(errorData){
          that.chargeManagersClassificator = true;
        })
  }

  configureClassificatorList(){
    let that: any = this.taskModal;
    if(that.task.classificatorResponsibles?.length){
        let assignList:any = [];
        for (let i:number = 0; i < that.task.classificatorResponsibles.length; i++) {
            let taskResponsible:any = that.task.classificatorResponsibles[i];
            let hasInArray:Boolean = false;
            for (let m:number = 0; m < taskResponsible.responsibles.length; m++) {
                let taskResponsibleList: any = taskResponsible.responsibles[m]
                for (let j:number = 0; j < assignList.length; j++) {
                    let list: any = assignList[j]
                    for (var k = 0; k < list.responsibles.length; k++) {
                        var responsible = list.responsibles[k];
                        if (responsible.uniqueValueId === taskResponsibleList.uniqueValueId && !this.findOutListValue(list.listValueId, taskResponsible.listValueId)) {
                            list.listValueId += '|' + taskResponsible.listValueId;
                            hasInArray = true;
                            break;
                        }
                    }
                }
            }
            if (!hasInArray) {
                let assign:any = this.addAssignItem();
                assign.responsibles = taskResponsible.responsibles;
                assign.listValueId = String(taskResponsible.listValueId);
                assign.responsibleField.selectContacts = taskResponsible.responsibles;
                this.addUsersAssign(assign.responsibles);
                assignList.push(assign);
            }
        }
        this.disabledInitElementList(assignList);
        that.assignList = assignList;
        that.managerClassificationSelected = '2';
    }else{
        that.assignList.push(this.addAssignItem());
        that.managerClassificationSelected = that.task.states && that.task.states.length? '1' : '0';
    }
  }

  findOutListValue(listValues:any, listValueId:number){
    let assignSelectValues:any = listValues.indexOf('|') === -1? [listValues] : listValues.split('|');
    return Boolean(assignSelectValues.filter((assign:any)=>{ return parseInt(assign) === listValueId}).length)
  }

  disabledInitElementList(assignList:any){
    for(var k = 0; k < assignList.length; k++){
        let assign:any = assignList[k];
        let assignSelectValues:any = assign.listValueId.indexOf('|') === -1? [assign.listValueId] : assign.listValueId.split('|');
        assign.listValues.forEach((item:any) => {
            item.disabled = !Boolean(assignSelectValues.filter((assignSelect:any)=>{ return parseInt(assignSelect) === item.listValueId}).length);
        });
    }
  }

  getStates() {
    this.apiService.get('processes', this.taskModal.task.processId + '/states')
        .then((data:any)=>{
            let responsiblesByStateId = keyBy(this.taskModal.task.states, 'stateId');
            data.forEach((state:any) => {
                let responsibleItem:any = responsiblesByStateId[state.stateId];
                state.responsibleField = {
                    required: true,
                    label: this.translate.instant('process.general-task.recipient-label'),
                    label2: ' "' + state.name + '"',
                    typeConfiguration: 'evaluationUsers',
                    fieldType: 'multiple',
                    addContacts: false,
                    typeCall: 'users',
                    selectContacts: responsibleItem?.responsibles?responsibleItem.responsibles:[]
                };
                state.responsibles = responsibleItem?.responsibles?responsibleItem.responsibles:[];
            });
        this.taskModal.states = data;
    });
  }


  responsibleAssignSelected(e:any, item:any, ){
    this.responsibleSelected(e, item);
    this.setUsersAssign();
  }

  responsibleSelected(e:any, item:any) {
    item.responsibles = e.contacts;
  }

  setUsersAssign(){
    this.taskModal.usersAssign = [];
    this.taskModal.assignList.forEach((assign:any) => {
        this.addUsersAssign(assign.responsibles);
    });
 }

  addUsersAssign(responsibles:any){
    if(responsibles?.length){
        // this.taskModal.usersAssign = [...this.taskModal.usersAssign, ...responsibles];
        this.taskModal.usersAssign = this.taskModal.usersAssign.concat(responsibles);
    }
  }

  itemSelected(e:any, type:string){
    this.taskModal.task[type] = e.newValue;
  }

  saveTask() {
    if (!this.checkForm()) {
        return;
    }

    this.loader.openLoader('process-general-task-create-alert');
    let that: any = this.taskModal;
    if(that.expirationTask){
        that.task.expirationTimeUnit = that.task.expirationTimeUnit.listValueId? that.task.expirationTimeUnit.listValueId : that.task.expirationTimeUnit;
    }else{
        that.task.expirationUnits = null;
        that.task.expirationTimeUnit = null;
    }
    var model = cloneDeep(that.task);
    model.classificatorResponsible = [];
    model.states = [];
    if (that.managerClassificationSelected === '1') {
        model.responsibles = [];
        model.states = that.states;
    } else if(that.managerClassificationSelected === '2'){
        model.responsibles = [];
        model.classificatorResponsible = this.setClassificatorResponsibles()
    }

    let promise:any = that.edit? this.apiService.update('processgeneraltasks', undefined, model) : this.apiService.add('processgeneraltasks', model);

    promise.then((data:any)=>{
        that.task = model;
        if (data) {
            that.task.processGeneralTaskId = data.processGeneralTaskId;
            that.task.createdBy = data.createdBy;
            that.task.creationDate = data.creationDate;
            that.task.modifiedBy = data.modifiedBy;
            that.task.modificationDate = data.modificationDate;
            that.task.creator = data.creator;
        }

        if(that.managerClassificationSelected === '2'){
            delete that.task.classificatorResponsible;
            that.task.classificatorResponsibles =  this.setClassificatorResponsibles()
        }

        if(!that.expirationTask){
            that.task.expirationUnits = null;
            that.task.expirationTimeUnit = null;
        }

        this.loader.closeLoader(['process-general-task-create-alert']);
        var text = that.edit ? this.translate.instant('process.general-task.edited-success') : this.translate.instant('process.general-task.created-success');
        this.modalService.adviceModal(text, 'info:accept:check-round');
        this.activeModal.close({
            result: 'ok',
            task: that.task
        });

    }, (errorData:any)=>{
        this.loader.closeLoader(['process-general-task-create-alert']);
        this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
    });
  }

  setClassificatorResponsibles(){
    let classificatorResposibles:any = [];
    this.taskModal.assignList.forEach((assign:any) => {
        let listValues:any = assign.listValueId.indexOf('|') === -1? [assign.listValueId]: assign.listValueId.split('|');
        listValues.forEach((value:any) => {
            classificatorResposibles.push({
                responsibles: assign.responsibles,
                listValueId:parseInt(value)
            })
        });
    });

    return classificatorResposibles;
  }

  checkForm() {
    let that: any = this.taskModal;
    let error:boolean = false;
    error = (that.titleField.error = !that.task.title) || error;
    if (that.managerClassificationSelected === '1') {
        if (!error) {
            for(var i = 0; i < that.states.length; i++){
                let state: any = that.states[i];
                if(!state.responsibles?.length){
                    state.responsibleField.error = true;
                    error = true;
                }
            }
        }
    } else if(that.managerClassificationSelected === '2'){
        let errors = 0;
        if (!error) {
          that.assignList.forEach((assign:any) => {
              assign.responsibleField.error = !assign.responsibles?.length;
              assign.fieldListData.error = !assign.listValueId
              errors += assign.responsibleField.error || assign.fieldListData.error? 1: 0
          });

          if(!errors){
              this.disabledListValuesItems();
              let allAssigned = that.classificatorList?.size === that.fieldListData.listValues.length?true:false;
              if(allAssigned){
                  return true;
              }else{
                  this.modalService.adviceModal(this.translate.instant('process.general-task.not-all-assign'), 'error:accept:warning')
                  return false;
              }

          }else{
              return false;
          }
        }
    }else {
        that.responsibleField.error = !that.task.responsibles?.length;
        error = that.responsibleField.error || error;
    }
    return !error;
   }


  selectManagerClassificationType(e:any){
    this.taskModal.managerClassificationSelected = e.newValue;
    if(e.newValue !=='2'){
        this.taskModal.assignList.push(this.addAssignItem());
    }
    this.taskModal.task.addResponsibleToConfidentiality = Boolean(e.newValue ==='2');
  }

  addAssignItem(){
    return {
        fieldListData: cloneDeep(this.taskModal.fieldListData),
        id: this.commonUtilsService.createId(),
        responsibleField: cloneDeep(this.taskModal.responsibleFieldAssign),
        name: this.translate.instant('process.general-task.assign'),
        listValues: cloneDeep(this.taskModal.fieldListData.listValues)
    }
  }

  duplicateSubcontainer(){
    this.taskModal.assignList.push(this.addAssignItem());
    this.disabledListValuesItems();
  }

  deleteDuplicated(assign:any, index:any){
    this.modalService.adviceModal(this.translate.instant('process.general-task.delete-copy')+' '+index+' '+this.translate.instant('process.general-task.delete-assign'), 'info:question:question')
    .subscribe((result:any)=>{
        if(result.result === 'ok'){
            this.taskModal.assignList.splice(index, 1);
            this.disabledListValuesItems();
            if(assign.listValueId !==''){
               this.setUsersAssign();
            }
        }
    })

  }

  managerOptionsList(e:any, assign:any){
    assign.listValueId = e.newValue;
    this.disabledListValuesItems();
  }

  disabledListValuesItems(){
    let that: any = this.taskModal;
    that.classificatorList = new Map();
    that.assignList.forEach((assign:any) => {
        assign.listValues.forEach((value:any) => {
            if(value.selected){
                that.classificatorList.set(value.listValueId, assign.id);
            }
        });
    });
    that.assignList.forEach((assign:any) => {
        assign.listValues.forEach((value:any) => {
            value.disabled = false;
            let assignIdMap = that.classificatorList.get(value.listValueId);
            if(assignIdMap && assignIdMap !== assign.id){
                value.disabled = true;
            }
        });
    });
  }

  showTooltip(id:string):boolean{
    return !this.commonUtilsService?.showTooltip(id);
  }

  handleDismiss() {
    this.activeModal.close({
        result: 'cancel'
    });
  }
}
