<div class="video-player">
    <div class="common__modal-header-block">
        <i [class]="'lf-icon-'+videoPlayer.videoSelected.videoType.toLowerCase()+' common__icon-header'"></i>
        <span class="common__text-header">{{videoPlayer.videoSelected.videoTitle}}</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="video-player__video-container">
          <iframe class="video-player__iframe"
            [src]="videoPlayer.videoSelected.videoUrl | trustUrl"
            frameborder="0"
            title="Reproductor de video"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
          <div class="video-player__breadcrumb-block">
              <div class="concept-steps__steps-map-icon concept-steps__steps-map-icon--video"
                  *ngFor="let video of videoPlayer.videos">
                  <i [ngClass]="video.active?'lf-icon-step-final':'lf-icon-step-first'"
                      (click)="videoChange(video)"></i>
              </div>
          </div>
    </div>
    <div class="video-player__description-block">
        <div class="field-item__rich-text"
            [innerHTML]="videoPlayer.videoSelected.description">
        </div>
    </div>
</div>
