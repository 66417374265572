import { Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { EvaluatedConceptFactory } from '@shared/factories/evaluated-concept/evaluated-concept.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptUtilsService } from '@shared/services/concept-utils/concept-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { SnapShotsService } from '@shared/services/snap-shots/snap-shots.service';
import { cloneDeep } from 'lodash';
import $ from 'jquery';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';


@Component({
  selector: 'concept',
  templateUrl: './concept.component.html'
})
export class ConceptComponent implements OnInit, OnDestroy {
  fullMenu:boolean = window.innerWidth >= 1920;
  skin: string = this.globalCfg.skin;
  userPublic:boolean = this.context.user && !this.context.user.email;
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  conceptObjectId:any = this.route.snapshot.params['conceptObjectId'];
  conceptId: any = this.route.snapshot.params['conceptId'];
  folderId: any = this.route.snapshot.params['folderId'];
  fieldContainerId:any = this.route.snapshot.params['fieldContainerId']
  viewMode:boolean = this.conceptObjectId === 'view';
  userPermissions = this.permissions.getPermissions();
  subscribers : any = {};
  to: any = {
    action: this.extractParams(),
    edit: false
  };
  concept:any = {
    action: '',
    public: false,
    conceptList: [],
    productTypeId: '',
    evaluationTree: this.route.snapshot.data['evaluationTree'],
    evaluation: this.route.snapshot.data['evaluationInfo'],
    conceptInfo: this.route.snapshot.data['conceptInfo'],
    fieldContainerInfo: this.route.snapshot.data['fieldContainerInfo']
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private conceptState: ConceptStateFactory,
    private broadcastService: BroadcastService,
    private route: ActivatedRoute,
    private permissions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private conceptUtilsService: ConceptUtilsService,
    private modalService: ModalServiceService,
    private router: Router,
    private registerService: RegisterMixtapeService,
    private convertNumbToName: ConvertNumToNameFactory,
    private snapshotsService: SnapShotsService,
    private evaluatedConcept: EvaluatedConceptFactory,
    private documentActions: DocumentActionsService) { }


  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
      if(this.skin ==='icam-red' && sessionStorage.getItem('icamUser')){
          sessionStorage.removeItem('icamUser');
      }
  }

  ngOnInit(): void {
    this.concept.to = this.route.snapshot.params['taskId']? {action: 'task', edit: true, taskId:this.route.snapshot.params['taskId']} : this.to;
    this.concept.productTypeId = this.concept.conceptInfo.productTypeId;
    this.conceptState.reset();
    this.watchEvaluationInfo();
    this.watchChangeFieldsFromConceptTab();
    this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: this.concept.conceptInfo});

    if(this.concept.conceptInfo.productId === 'blanqueo' && !this.concept.conceptInfo.folderId){
        this.concept.conceptInfo.folderId = parseInt(this.route.snapshot.params['folderId']);
    }

    this.getFolderInfo();
    this.viewMode? this.getConceptViewInfo(this.conceptId) : this.getConceptInfo(this.conceptObjectId, true)
    this.openRelatedConceptWatcher();
    this.emitFullMenu();

    if(this.concept.conceptInfo){
      if(this.concept.conceptInfo.productTypeId === 1){
          this.concept.productType = "default";
      }
      this.concept.public = this.concept.conceptInfo.allowPublicShare;
    }

    this.openWatcherAddAlert();
    this.openWatcherCreateTask();
    this.openWatcherOpenEvaluationTask();
    this.loader.openLoader('concept-init-concept')
  }

  extractParams(){
    if(this.route.snapshot.params['to']){
      return this.route.snapshot.params['to'] === 'tasks'? 'task' : this.route.snapshot.params['to']
    }else{
      return 'general';
    }
  }

  watchEvaluationInfo(){
      this.subscribers.evaluationInfoBroadcast = this.broadcastService.subscribe('evaluationInfo', (data:any)=>{
          this.concept.evaluation = data.evaluation;
          this.concept.evaluationTree = data.evaluationTree;
          if(this.concept.activeConcept){
              this.concept.activeConcept.evaluationTree = data.evaluationTree;
              if(this.concept.conceptInfo.productTypeId !== 1 && !this.concept.conceptInfo.multipleInstancesInAuto){
                  this.concept.activeConcept.showButtons = true;
                  this.buttonsToShow(this.concept.activeConcept, data.evaluationTree.children);
              }
          }
      })
  }

  watchChangeFieldsFromConceptTab(){
      this.subscribers.changeFieldFromConceptTabBroadcast = this.broadcastService.subscribe('changeFieldFromConceptTab', (data:any)=>{
          this.broadcastService.broadcast('changeFieldFromConcept', {field: data.field, newValue: data.newValue});
      })
  }

  getProcess(){
      let activeConcept = this.concept.activeConcept
      if(this.concept.evaluationTree){
        activeConcept.evaluationTree = this.concept.evaluationTree;
      }
      let url:string = !this.viewMode? 'processesdata/'+ activeConcept.conceptObjectId + '/summary/'+ this.commonUtilsService.getLanguage() : 'processes/'+ this.conceptId + '/summary';
      this.apiService.get(url)
          .then((data:any) => {
              activeConcept.processStates = data;
              if(data){
                  if(activeConcept.conceptNamedKey === 'Consulta'){

                      if(activeConcept.processStates.current.stateId0 > 3){
                          activeConcept.tooltipAlertHour = this.translate.instant('concept-tab.concept-finished');
                          activeConcept.textAlertHour = this.translate.instant('concept-instances-list.finished');
                          activeConcept.stepIsFinal = true;
                      }else{
                          activeConcept.tooltipAlertHour = this.translate.instant('concept-tab.hours').replace('{hours}', activeConcept.processingHours);
                          activeConcept.textAlertHour = activeConcept.processingHours +' '+ this.translate.instant('add-alert.hours');
                          activeConcept.stepIsFinal = false;
                      }
                      this.commonUtilsService.configureAlertState(activeConcept);
                  }
                  if(!this.viewMode){
                    this.processStates();
                  }else{
                    activeConcept.listStates = this.commonUtilsService.editList([], 'stateId', 'name');
                  }
              }
          }, ()=>{});
  }

  processStates(){
    let activeConcept:any = this.concept.activeConcept;
      this.apiService.get('processes/'+ activeConcept.conceptId, 'states')
      .then((data:any)=>{
          activeConcept.listStates = this.commonUtilsService.editList(data, 'stateId', 'name');
      }, (errorData:any)=>{
          this.loader.closeError();
      });
  }

  buttonsToShow(concept:any, tree:any){
      this.concept.conceptsToFind = [];
      concept.disabledNextButton = false;
      this.setConceptList(tree, concept);
      concept.showBackButton = this.concept.conceptsToFind[0].conceptId !== concept.conceptId;
      concept.showNextButton = this.concept.conceptsToFind[this.concept.conceptsToFind.length-1].conceptId !== concept.conceptId;
  }

  setConceptList(children:any, activeConcept:any){
    for(let [index, child] of children.entries()){
      if(child.folderId !== 'reports'){
        child.concepts.forEach((concept:any) => {
          if(!concept.multipleInstancesInAuto){
            this.concept.conceptsToFind.push(concept);
          }
          if (concept.conceptId === activeConcept.conceptId && children[index + 1] && children[index + 1].disabledFolder) {
            activeConcept.disabledNextButton = true;
          }
        });
        this.setConceptList(child.children, activeConcept);
      }
    }
  }

  changeEvaluatedConcept(change:any) {
      this.goTo(change, 'changeEvaluated');
  }

  changeEvaluated(change:any){
      if(change==='noEvaluated'){
          this.modalService.adviceModal(this.translate.instant('concept.message-state-change')+ this.concept.activeConcept.title+' ?', 'info:question:question')
            .subscribe((result:any)=>{
              if(result.result === 'ok'){
                this.changeEvaluatedState(change);
              }
            })
      }else{
        this.broadcastService.broadcast('changeConcept',{changeConcept:{
            change: change,
            concept:this.concept.conceptInfo
        }})
      }
  }

  changeEvaluatedState(change:any){
      let activeConcept: any = this.concept.activeConcept;
      let state: number = this.concept.activeConcept.evaluated? 1 : 0;
      this.apiService.patch('conceptobjects/evaluated', activeConcept.conceptObjectId + '/' + state)
          .then((data:any)=>{
              activeConcept.evaluated = !activeConcept.evaluated;
              this.broadcastService.broadcast('changeConcept',{changeConcept:{
                  change: change,
                  concept:this.concept.conceptInfo,
                  evaluated: activeConcept.evaluated
              }})
          }, function(errorData){})
  }

  checkChanges(folderId:any) {
      let hasChanges:boolean = false;
      if (!this.viewMode) {
          for (let i = 0; i < this.concept.conceptList.length; i++) {
              if (!this.noModifyEvaluated()) {
                  hasChanges = !hasChanges;
                  break;
              }
          }
      }
      if (hasChanges) {
        let text = this.concept.conceptList.length > 1?'concept.advertence-exit-all' : 'concept.advertence-exit';
        this.closeAll(folderId, this.translate.instant(text), 'info:exitAll:question');
      } else {
        this.exitToFolder(folderId);
      }
  }

  closeAll(folderId:any, text:string, action:string){
      this.modalService.adviceModal(text, action).subscribe((result:any)=>{
        if(result.result !== 'cancel'){
          if(result.result === 'save-all' || result.result === 'save-exit'){
            this.concept.errorInAnyForm = [];
            for(let [index, element] of this.concept.conceptList.entries()){
                let errors:any = this.commonUtilsService.checkRequiredFields(element, this.userPublic)
                if( errors.commonErrors){
                    element.title = element.conceptTitle
                    this.concept.errorInAnyForm.push(element);
                }
                this.saveConceptFields(element)
                  .then((data:any)=>{
                    if(index === 0){
                        this.saveSnapshot();
                    }
                  });
            }

            if(this.concept.errorInAnyForm.length){
                if(action === 'info:exitAll:question'){
                    this.showDraftEvaluations();
                    this.exitToFolder(folderId);
                } else {
                    this.validateFieldsModal()
                      .then(()=> {
                        this.loader.openLoader('folder-on-init')
                        this.acceptChanges()
                          .then(()=>{
                            this.navigateRoute({ folderId: folderId });
                          });
                    });
                }
            }else{
                this.exitToFolder(folderId);
            }
          }else{
              this.exitToFolder(folderId);
          }
        }
      })
  }

  exitToFolder(folderId:any){
      this.loader.openLoader('folder-on-init');
      if(folderId === 'reports' || folderId==='favorites'){
        this.router.navigateByUrl('evaluation/'+this.evaluationId+'/'+folderId);
      } else {
          this.navigateRoute({ folderId: folderId });
      }
  }

  showDraftEvaluations(){
      this.modalService.draftEvaluationsModal(true, this.concept.errorInAnyForm)
  }

  openWatcherAddAlert(){
      this.subscribers.addNewBroadcast = this.broadcastService.subscribe('addNew',(data)=>{
          this.modifyConceptTab();
      });
  }
  openWatcherCreateTask(){
      this.subscribers.actionFromFieldBroadcast = this.broadcastService.subscribe('actionFromField', ( data:any)=>{
          if(data.action ==='delete'){
              this.launchActionTask(data);
          }else{
              let configuration : any = {
                  concept: this.concept.activeConcept,
                  textModal: this.translate.instant('concept-form.save-from-create-task-field'),
                  typeModal: 'info:onlySaveContinueAndCancel:question',
                  noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
                  blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
                  textValidateField: this.translate.instant('concept-form.error-validate-fields')
              }
              this.conceptUtilsService.exitConcept(configuration)
                  .then(()=>{
                      this.launchActionTask(data);
                  }, ()=>{})
          }
      });
  }

  launchActionTask(data:any){
    let that: any = this.concept
      if(data.action ==='create'){
          that.oldTab = cloneDeep(that.activeConcept.subTab);
          that.activeConcept.subTab = 'task';
          that.to.edit = true;
          that.shared = true;
          that.field = data.field;
          that.taskTypeIdSelect = null;
      }else{
          let task = data.field.selectTask;
          let text:string = !task.repeatPatternReadonly && task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-concept-list.the-task')+' "'+task.title+'" '+this.translate.instant('task-concept-list.pattern-settings') : this.translate.instant('dashboard.question-delete-task') +' "'+ task.title+'" ?';

          this.modalService.adviceModal(text, 'info:question:question')
            .subscribe((result:any)=>{
              if(result.result === 'ok'){
                this.deleteTask(task, data.field);
              }
            })
      }
  }


  deleteTask(task:any, field:any){
      this.loader.openLoader('concept-delete-task')
      this.apiService.delete('tasks', task.taskId)
          .then((data:any)=>{
              if(task.taskTypeId === 3){
                  this.concept.hasOpenEvaluationTask = false;
                  this.concept.to.edit = true;
                  this.concept.shared = false;
              }
              this.loader.closeLoader(['concept-delete-task']);


              this.modalService.adviceModal(this.translate.instant('task-edit.the-task')+"'"+ task.title+"'"+this.translate.instant('task-concept-list.removed-task'), 'info:accept:check-round');
              this.modifyConceptTab();
              this.commonUtilsService.deleteElement(task, this.concept.activeConcept.taskList, 'taskId');
              this.broadcastService.broadcast('deleteTaskField', {task: task, field: field})
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('task-concept-list.removed-error') +" '"+ task.title + "'", 'error:accept:warning');
          })
  }


  openWatcherOpenEvaluationTask(){
      this.subscribers.hasOpenEvaluationTaskBroadcast = this.broadcastService.subscribe('hasOpenEvaluationTask', (data:any)=>{
          this.concept.hasOpenEvaluationTask = data.evaluationTask;
          this.concept.to.edit = true;
          this.concept.shared = false;
      })
  }

  hasDraft(item:any){
      if(item.fieldOrigin !== null && item.fieldOrigin !== undefined){
          this.sendFieldToOrigin(item)
      }
      this.broadcastService.broadcast('hasDraft',{evaluationId: this.concept.evaluation.evaluationId})
  }

  sendFieldToOrigin(concept:any){
    let that: any = this.concept
    for(let conceptList of that.conceptList){
        if(concept.fieldOrigin.conceptObjectId === conceptList.conceptObjectId){
            for(let fieldContainers of conceptList.fieldContainers){
                for(let field of fieldContainers.fields){
                    if(field.fieldId === concept.fieldOrigin.fieldId){
                        this.setFieldEvaluated(field, concept);
                    }
                }
                for(let subContainer of fieldContainers.subContainers){
                    for(let subField of subContainer.fields){
                        if(subField.fieldId === concept.fieldOrigin.fieldId){
                            this.setFieldEvaluated(subField, concept);
                        }
                    }
                }
            }
        }
    }
  }

  setFieldEvaluated(field:any, concept:any){
      if(field.multiple && (field.evaluatedValue.nvarcharValue=== null || field.evaluatedValue.nvarcharValue==='')){
          field.evaluatedValue.nvarcharValue = ""+concept.conceptObjectId;
      }else if(field.multiple && field.evaluatedValue.nvarcharValue !==''){
            field.evaluatedValue.nvarcharValue += "|"+ concept.conceptObjectId;
      }else{
            field.evaluatedValue.floatvalue = concept.conceptObjectId;
      }
  }

  modifyConceptTab(){
      let activeConcept: any = this.concept.activeConcept;
      this.getConceptDocumentation(activeConcept);
      this.getConceptTasks(activeConcept);
      this.getConceptAlert(activeConcept);
  }

  launchAction(action:any){
      switch (action.type) {
          case 'editFile':
              this.documentActions.doAction('open', action.file);
              this.apiService.add('documentobjects/logedit/' + action.file.documentObjectId).then(() => { }, () => { });
              break;
          case 'generateReport':
              this.goTo(action.report, 'newReport');
              break;
          case 'viewReport':
              this.documentActions.doAction('open', action.report);
              break;
          case 'goToFolder':
              this.goTo(action.folderId, 'folder');
              break;
          case 'goToQuery':
              this.goTo(action.item, 'query');
              break;
          case 'closeConcept':
              this.closeConcept(action.item);
              break;
          case 'selectTab':
              this.changeTab(action);
              break;
          case 'newTask':
              this.goTo('', 'saveBeforeNewTask');
              break;
          case 'oldTab':
              this.concept.activeConcept.subTab = this.concept.oldTab;
              this.concept.shared = false;
              this.concept.to.edit = false;
              this.concept.taskTypeIdSelect = null;
              if(this.concept.oldTab === 'general' && this.concept.field && this.concept.field.fieldId && !action.cancel){
                  this.launchModalAdvice(action.action);
                  this.modifyConceptTab();
              }
              this.concept.field = null;
              break;
          case 'generateTemplate':
              if(action.resolve === 'ok'){
                  this.documentActions.doAction('open', action.template);
                  this.modalService.adviceModal(this.translate.instant('concept-form.add-ok').replace('{document}',  action.template.name), 'info:accept:check-round');
              }else{
                  this.modalService.adviceModal(this.translate.instant('concept-form.error-export-template').replace('{document}',  action.template.name), 'error:accept:warning');
              }
              break;
          case 'selectConcept':
              this.keepDataConcept(true);
              this.concept.activeConcept = action.item;
              break;
          case 'changeEvaluatedConcept':
              this.changeEvaluatedConcept(action.change)
              break;
          case 'print':
            this.loader.openLoader('print-consult-concept')
              this.printSnapshot();
              this.saveSnapshot();
              break;
          case 'favorite':
              this.addToFavorite(action.item);
              break;
          case 'modifyConceptTab':
            this.modifyConceptTab()
            break
          case 'hasDraft':
            this.hasDraft(action.item)
            break;
          default:
              this.concept.activeConcept = action.item;
              break;
      }
  }

  changeTab(action:any) {
      let configuration : any = {
          concept: this.concept.activeConcept,
          textModal: this.translate.instant('concept.advertence-change'),
          noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
          blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
          typeModal: 'info:saveContinue:question',
          textValidateField: this.translate.instant('concept-form.error-validate-fields'),
          loader: this.conceptUtilsService.configureLoader(action.item)
      }
      this.conceptUtilsService.exitConcept(configuration)
          .then((result:any)=>{
              if(result === 'without-save'){
                  this.conceptState.removeAll();
              }
              let configurationLocked : any = {
                  concept: this.concept.activeConcept,
                  literalQuestion: this.translate.instant('concept-form.block-client-concept'),
                  loader: configuration.loader,
                  saveConcept: result === 'save-navegate'
              }
              this.conceptUtilsService.checkModalLocked(configurationLocked)
                .then((result:any)=>{
                  this.concept.activeConcept.subTab = action.item;
                  this.concept.taskTypeIdSelect = null;
                }, ()=>{})

          }, ()=>{})
      this.broadcastService.broadcast('closePlay');
  }

  acceptChanges() {
      return new Promise((resolve:any)=>{
          this.saveConceptFields(this.concept.activeConcept).then(()=>{
              this.saveSnapshot();
              resolve();
          });
      });
  }

  goTo(item:any, type:string){
    let configuration : any = {
        concept: this.concept.activeConcept,
        textModal: this.translate.instant('concept.advertence-exit'),
        typeModal: 'info:exit:question',
        noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
        blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
        textValidateField: this.translate.instant('concept-form.error-validate-fields'),
        loader: 'folder-on-init'
    }
    this.conceptUtilsService.exitConcept(configuration)
        .then((result:any)=>{
          switch(type){
            case 'folder':
              this.exitToFolder(item)
              break;
            case 'query':
              this.navigateRoute(item, type)
              break;
            case 'exitFolder':
              this.exitToFolder(this.getFolderId());
              break;
            case 'closeTabConcept':
              this.closeTabConcept(item);
              break;
            case 'saveBeforeNewTask':
              this.createNewTask();
              break;
            case 'newReport':
              this.newReport(item);
              break;
            case 'goBack':
              this.navigateRoute(item);
              break;
            case 'changeEvaluated':
                var configurationLocked = {
                    concept: this.concept.activeConcept,
                    literalQuestion: this.translate.instant('concept-form.block-client-concept'),
                    loader: configuration.loader,
                    saveConcept: result === 'save-navegate'
                }
                this.conceptUtilsService.checkModalLocked(configurationLocked)
                  .then((result:any)=>{
                    this.changeEvaluated(item);
                  }, ()=>{})
              break;
          }

        }, ()=>{})
  }

  navigateRoute(target:any, from:string = '') {
      let evaluationId : any = target.evaluationId != null ? target.evaluationId : this.evaluationId;
      let url : any = '/evaluation/'+evaluationId;
      if (target.folderId != null) {
          url += '/folder/'+target.folderId;
          if (target.conceptId != null) {
              url += '/concept/'+target.conceptId;
              if (target.conceptObjectId != null) {
                  url += '/concept-evaluated/'+target.conceptObjectId;
              }
          }
      }
      if(this.skin ==='icam-red' && sessionStorage.getItem('icamUser') && from !==  'query'){
          let users:any = sessionStorage.getItem('icamUser');
          let icamUsers : any = JSON.parse(users);
          icamUsers.forEach((user:any) => {
            let concept = user.to;
            if(this.evaluationId === concept.evaluationId && this.conceptId === concept.conceptId && this.conceptObjectId === concept.conceptObjectId){
              sessionStorage.removeItem('icamUser');
              let urlUser: any = 'evaluation/'+user.from.evaluationId+'/folder/'+user.from.folderId+'/concept/'+user.from.conceptId+'/concept-evaluated/'+user.from.conceptObjectId;
              this.commonUtilsService.reloadNavigateUrlEvaluation(urlUser, user.from.evaluationId);
            }
          });
      }else{
        this.commonUtilsService.reloadNavigateUrlEvaluation(url, evaluationId);
      }
  }

  closeConcept(concept:any){
      let index: number = 0;
      let that: any = this.concept;
      for(let i = 0; i < that.conceptList.length; i++){
          if(concept.conceptObjectId === that.conceptList[i].conceptObjectId){
              index = i;
              break;
          }
      }
      if(index === 0 && that.conceptList.length > 1){
          this.checkChanges(this.folderId)
      } else if (index === 0) {
        this.goTo('', 'exitFolder');
      } else {
        this.goTo(index, 'closeTabConcept');
      }

      if(!this.concept.conceptList.length){
          window.history.back();
      }
  }

  closeTabConcept(index:number){
      this.concept.conceptList.splice(index, 1);
      this.concept.activeConcept = this.concept.conceptList[this.concept.conceptList.length-1];
      this.desactivConcept();
      this.concept.activeConcept.active = true;
  }

  launchModalAdvice(action:any){
      this.loader.closeLoader(['task-edit-save-task']);
      let specificText : string = action === 'create'? this.translate.instant('dashboard.ok-created') : this.translate.instant('dashboard.ok-modify');
      let text : string = this.translate.instant('task-edit.the-task') +"'"+ this.concept.field.selectTask.title +"'"+ specificText;
      this.modalService.adviceModal(text, 'info:accept:check-round')
  }

  createNewTask(){
      this.concept.oldTab = cloneDeep(this.concept.activeConcept.subTab);
      this.concept.activeConcept.subTab = 'task';
      this.concept.to.edit = true;
      this.concept.shared = true;
      this.concept.taskTypeIdSelect = 3
  }

  newReport(report:any) {
      let isGeneratingReport:any = sessionStorage.getItem('isGeneratingReport');
      if (isGeneratingReport !== 'true') {
          this.modalService.renameModal({
              title: this.translate.instant('new-report.new-report'),
              type: 'report',
              from: 'generateReport',
              titleConcept: report.reportTitle,
              conceptId: report.reportId,
              conceptObjectId: report.reportObjectId,
              listFolders: report.reportObjects,
              icon: 'lf-icon-analysis',
              rename: false
          }).subscribe((result:any) => {
              if (result.result === 'ok') {
                  this.createNewReport(report, result.name);
              }
          });
      } else {
          this.modalService.adviceModal(this.translate.instant('reports.still-generating'), 'error:accept:warning');
      }
  }

  createNewReport(report:any, text:any) {
      this.modalService.informativeLoadingModal({
          type: 'new-report',
          item: report,
          name: text
      }).subscribe((result:any) => {
        if(result.result ==='ok'){
          this.registerService.register({
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event': 'create report',
            'Report Type': this.convertNumbToName.reportNumbToName(String(report.reportId)),
            'Source': 'other',
            'Centinela product': this.context.settings.defaultProduct
        });
        this.broadcastService.broadcast('refreshReports', {report: report});
        this.modalService.adviceModal(this.translate.instant('reports-list.report-create') + " '" + text + "'" + this.translate.instant('dashboard.ok-created'), 'info:accept:check-round');
        }else{
          this.modalService.adviceModal(this.translate.instant('dashboard.error-report-create'), 'error:accept:warning');
        }

      })
  }

  validateFieldsModal() {
      return new Promise((resolve:any, reject:any)=>{
          this.modalService.adviceModal(this.translate.instant('concept-form.error-validate-fields'), 'error:question:mandatory-field')
          .subscribe((result:any)=>{
            result.result==='ok'? resolve() : reject();
          })
      });
  }



  noModifyEvaluated(){
      return !((this.conceptState.getAmount() || this.conceptState.getProcess()))
  }

  saveConceptFields(concept:any){
      let url : string = 'conceptdata';
      let id : any = concept.conceptObjectId
      if(this.fieldContainerId){
          url = 'conceptdata/'+concept.conceptObjectId;
          id = this.fieldContainerId
      }
      return this.apiService.patch(url, id,  concept.evaluatedFields)
          .then((data:any)=> {
              this.conceptState.removeAll();
              return data;
          }, function(errorData){

          })
  }

  saveSnapshot() {
      if (!this.fieldContainerId) {
          let historicJson = this.snapshotsService.getConceptJson(this.concept.activeConcept);
          this.snapshotsService.saveForConcept(this.concept.activeConcept.conceptObjectId, historicJson) //, historicHtml
      }
  }

  printSnapshot(){
      let historicJson : any = this.snapshotsService.getConceptJson(this.concept.activeConcept);
      sessionStorage.removeItem('historyViewerData');
      sessionStorage.setItem('historyViewerDataJson', historicJson);
      window.open('/history-viewer', '_blank');
      this.loader.closeLoader(['print-consult-concept']);
  }

  desactivConcept(){
    this.concept.conceptList.forEach((item:any) => {
      item.active = false;
    });
  }

  openRelatedConceptWatcher(){
      let that: any = this.concept
      this.subscribers.openRelatedConceptBroadcast = this.broadcastService.subscribe('openRelatedConcept', (data:any) => {
          let find = false;
          this.keepDataConcept(false);
          for(let i = 0; i < that.conceptList.length;i++){
              let concept:any = that.conceptList[i];
              if(concept.conceptObjectId == data.relatedConcept){
                  $('#compliance-view-body').scrollTop(0);
                  this.desactivConcept();
                  concept.active = true;
                  that.activeConcept = concept;
                  find = true;
                  if(that.activeConcept && that.activeConcept.conceptObjectId){
                      this.getProcess();

                  }
                  break;
              }
          }
          if(!find){
              $('#compliance-view-body').scrollTop(0);
              this.getConceptInfo(data.relatedConcept, true, undefined, 'openWindow');
          }
      });
  }

  keepDataConcept(changeActive:boolean){
      let that: any = this.concept
      for(let i = 0; i < that.conceptList.length;i++){
          let concept : any = that.conceptList[i];
          if(that.activeConcept.conceptObjectId === concept.conceptObjectId){
              if(changeActive){
                  that.activeConcept.active = false;
              }
              that.conceptList.splice(i, 1, that.activeConcept);
          }
      }
  }

  getFolderInfo(){
      this.apiService.get('folders/'+this.evaluationId, this.concept.conceptInfo.folderId)
      .then((data:any)=>{
          this.concept.parentFolderName = this.translate.instant('common.return-to') + data.name;
          this.concept.parentConcept = data;
      }, (errorData:any)=>{
          this.loader.closeError()
      })
  }

  emitFullMenu(){
      this.broadcastService.broadcast('changeFullMenu', {
          fullMenu: this.fullMenu
      });
  }

  getConceptInfo(conceptObjectId:any, active:any, evaluatedValue:any=null, from:any='') {
      this.apiService.get('conceptobjects', conceptObjectId)
          .then((data:any)=> {
              data.fieldOrigin = evaluatedValue;
              data.active = active;
              data.finishAllCalls = 0;
              if(this.concept.conceptInfo && this.concept.conceptInfo.relatedInformation){
                  data.relatedInformation = this.concept.conceptInfo.relatedInformation;
              }
              let fromTo = from;
              if (from === 'openWindow' || this.concept.to.action === 'task' || this.concept.to.action === 'documentation') {
                  fromTo = 'openWindow';
                  this.getConceptFieldcontainers(data, fromTo);
              }
              this.getConceptDocumentation(data, fromTo);
              this.getConceptTasks(data, fromTo);
              this.getConceptAlert(data, fromTo);
          }, (errorData:any) => {
              this.loader.closeError()
          })
  }

  getConceptViewInfo(conceptId:any){
      this.apiService.getWithTranslate('concepts', 'byid/' + conceptId)
          .then((data:any) => {
              let conceptMockup : any = {
                  folderId: data.folderId,
                  conceptId: data.conceptId,
                  conceptObjectId: 'view',
                  finishAllCalls: 0,
                  references: data.references,
                  title: this.concept.conceptInfo.title + ' (' + this.translate.instant('common.preview') + ')',
                  active: true,
                  groupsAsList: data.groupsAsList
              };
              this.getConceptFieldcontainers(conceptMockup, 'view');
          }, (errorData:any) => {
              this.loader.closeError();
          });
  }

  getConceptFieldcontainers(concept: any, from: any=''){
    let url : string = 'fieldcontainers/'+this.evaluationId+'/'+concept.conceptId;
    url+= this.fieldContainerId? '/'+this.fieldContainerId+'/front': '/front';

    this.apiService.getWithTranslate(url)
      .then((data:any) => {
          concept.fieldContainers = this.commonUtilsService.searchFormu(data, concept);
          concept.finishAllCalls++;
          this.closeLoader(concept, from);
        }, (errorData:any)=>{
            this.loader.closeError();
            concept.finishAllCalls++;
            this.closeLoader(concept, from);
        });
  }

  setLockedEdit(fieldContainers:any, concept:any){
      fieldContainers.forEach((fieldContainer:any) => {
        fieldContainer.lockedEdit = (concept.lockedEdit || this.concept.conceptInfo.poll);
        fieldContainer.fields.forEach((field:any) => {
          field.lockedEdit = (concept.lockedEdit || this.concept.conceptInfo.poll);
        });
        fieldContainer.subContainers.forEach((subcontainer:any) => {
          subcontainer.lockedEdit = (concept.lockedEdit || this.concept.conceptInfo.poll);
          subcontainer.fields.forEach((subField:any) => {
            subField.lockedEdit = (concept.lockedEdit || this.concept.conceptInfo.poll);
          });
        });
      });
  }

  getEvaluatedConceptData(concept:any, conceptObjectId:any) {
      if (!this.viewMode) {
          let url:string = 'conceptdata';
          let id:any = conceptObjectId;
          if(this.fieldContainerId){
              url = 'conceptdata/'+conceptObjectId;
              id = this.fieldContainerId;
          }
          this.apiService.get(url, id)
              .then((data:any) =>{
                  concept.evaluatedFields = data;
                  this.configureConcept(concept);
              }, (errorData:any) => {
                  this.loader.closeError();
              });
      } else {
          concept.evaluatedFields = [];
          concept.evaluatedFields = this.getViewEvaluatedFieds(concept);
          this.configureConcept(concept);
          this.loader.closeLoader(['concept-init-concept', 'evaluation-info-tree']);
      }
  }

  getViewEvaluatedFieds(concept:any) {
      let evaluatedFields:any = [];
      this.addContainersValue(concept, evaluatedFields);
      return evaluatedFields;
  }

  addContainersValue(parent:any, evaluatedFields:any) {
    if (parent.fieldContainers) {
      parent.fieldContainers.forEach((container:any) => {
        this.addContainerValue(container, evaluatedFields);
            for(let subContainer of container.subContainers){
                this.addContainerValue(subContainer, evaluatedFields);
            }
      });
    }
}

addContainerValue(container:any, evaluatedFields:any) {
    for (let field of container.fields) {
        let conceptData : any = this.createConceptDataMockup(container, field, evaluatedFields);
        evaluatedFields.push(conceptData);
        this.addContainersValue(container, evaluatedFields);
    }
}

createConceptDataMockup(container:any, field:any, evaluatedFields:any) {
      let conceptData:any = {
        bitValue: null,
        conceptDataId: -(evaluatedFields.length + 1),
        conceptObjectId: 'view',
        datetimeValue: null,
        documents: [],
        fieldId: field.fieldId,
        fieldTypeId: field.fieldTypeId,
        fileValue: null,
        floatvalue: null,
        hasReferences: false,
        label: field.label,
        listValues: field.listValues,
        numSubContainer: null,
        nvarcharValue: null,
        subContainerId: null
    }
    if (container.multiple) {
        conceptData.subContainerId = container.fieldContainerId;
        conceptData.numSubContainer = 1;
    }
    return conceptData;
}

  configureConcept(concept:any){
      let that: any = this.concept;
      concept.productType = that.productType;
      concept.productTypeId = that.conceptInfo.productTypeId;
      concept.conceptTypeId = that.conceptInfo.conceptTypeId;
      concept.videos = that.conceptInfo.videos;
      let evaluationTree: any = sessionStorage.getItem('evaluationTree');
      that.evaluationTree = evaluationTree?JSON.parse(evaluationTree):{};
      if(that.conceptInfo.productTypeId !== 1 && !that.conceptInfo.multipleInstancesInAuto && parseInt(this.conceptId) === concept.conceptId && (that.evaluationTree?.children) && !concept.evaluationTree){
          concept.showButtons = true;
          let children:any = that.evaluationTree.children;
          this.buttonsToShow(concept, children);
      }
      if(concept.productId === 'bpt002'){
          concept.subTab = 'general'
      }else{
          concept.subTab = that.to.action;
          that.taskTypeIdSelect = null;
      }

      that.conceptListState = false;
      if(concept.active && that.conceptList.length){
        that.conceptList.forEach((item:any) => {
          item.active = false;
        });

      }
      if(!that.conceptList || that.conceptList.length === 0){
          concept.new = this.evaluatedConcept.isNew;
          if(this.evaluatedConcept.isNew){
              this.evaluatedConcept.changeState(!this.evaluatedConcept.isNew);
          }
          that.conceptList = [concept];
          that.activeConcept = concept;
          that.hasOpenEvaluationTask = concept.hasOpenEvaluationTask;
      }else{
        for(let [index, item] of that.conceptList.entries() ){
          if(item.conceptObjectId === concept.conceptObjectId){
            that.conceptListState = true;
            that.conceptList[index] = concept;
          }
        }

        if(that.conceptListState === false){
            that.conceptList.push(concept);
            this.conceptState.setConceptList(that.conceptList);
            that.activeConcept = concept;
            that.hasOpenEvaluationTask = concept.hasOpenEvaluationTask;
        }
      }
      if(that.activeConcept && that.activeConcept.conceptObjectId){
          this.getProcess();
      }
      if(this.skin === 'icam-red' && that.activeConcept.windowTitle && (that.conceptInfo.conceptNamedKey === 'Consulta' || that.conceptInfo.conceptNamedKey === 'Usuario')){
          document.title = that.activeConcept.windowTitle;
      }
  }

  getConceptDocumentation(concept:any, from:any=''){
    this.apiService.get('documentobjects/'+concept.conceptObjectId)
      .then((data:any)=>{
        concept.documentation = data;
        concept.finishAllCalls++;
        this.closeLoader(concept, from);
      }, (errorData:any)=>{
          // this.loader.closeError();
          concept.finishAllCalls++;
          this.closeLoader(concept, from);
      })
  }

  getConceptTasks(concept:any, from:any=''){
    this.apiService.get('tasks/'+concept.conceptObjectId+'/count')
      .then((data:any)=>{
        concept.tasks = data;
        concept.finishAllCalls++;
        this.closeLoader(concept, from);
      }, (errorData:any)=>{
          this.loader.closeError();
          concept.finishAllCalls++;
          this.closeLoader(concept, from);
      })
  }

  getConceptAlert(concept: any, from: any = ''){
    let url = 'conceptobjects/alerts/'+concept.conceptObjectId+'/count';
    if(concept.conceptNamedKey === 'Cliente' && this.concept.evaluation && !this.concept.evaluation.isFinished){
        url = 'blanqueo/datealertscountbyclient/'+concept.clientId;
    }
    this.apiService.get(url)
      .then((data:any)=>{
        concept.alerts = data;
        concept.finishAllCalls++;
        this.closeLoader(concept, from);
      }, (errorData:any)=>{
          this.loader.closeError();
          concept.finishAllCalls++;
          this.closeLoader(concept, from);
      })
  }

  closeLoader(concept:any, from:any){
      if ((concept.finishAllCalls === 3 && from !== 'openWindow')
          || (from === 'openWindow' && concept.finishAllCalls === 4)
          || (from === 'view' && concept.finishAllCalls === 1)) {
          concept.lockedEdit = concept.stateLockedEdit?concept.stateLockedEdit:concept.lockedEdit;
          if(!this.evaluatedConcept.isNew && this.concept.fieldContainerInfo){
              this.setLockedEdit(this.concept.fieldContainerInfo, concept);
          }
          if(from !== 'openWindow'){
               concept.fieldContainers = this.commonUtilsService.searchFormu(this.concept.fieldContainerInfo, concept);
          }
          this.getEvaluatedConceptData(concept, concept.conceptObjectId);
      }
  }

    getFolderId() {
      return this.concept.conceptInfo.productTypeId === 2? this.concept.parentConcept.parentId :  this.folderId
  }

  goBack() {
    this.goTo({folderId: this.getFolderId()}, 'goBack')
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id)
  }



  addToFavorite(concept:any){
      let favorite : any = concept.isFavorite? 1 : 0;
      this.apiService.patch('conceptobjects/favorite/'+concept.conceptObjectId, favorite)
          .then((data:any)=>{},()=>{})
  }
  showTooltipTabButton(buttonMod:string){
    return !$('.concept__tab-button--' + buttonMod + ' .concept__tab-button-text').is(':visible');

  }
  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
