<div class="report-table">
    <div [ngSwitch]="reportTable.type">
        <report-table-dynamic *ngSwitchCase="'dynamic'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-dynamic>
        <report-table-canal-entrada *ngSwitchCase="'canalentrada'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-canal-entrada>
        <report-table-categoria-servicio *ngSwitchCase="'categoriaservicio'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-categoria-servicio>
        <report-table-consultas-pago *ngSwitchCase="'consultasconpago'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-consultas-pago>
        <report-table-fragmentos-digitalizados *ngSwitchCase="'fragmentosdigitalizados'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-fragmentos-digitalizados>
        <report-table-materias-submateria *ngSwitchCase="'materiasubmateria'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-materias-submateria>
        <report-table-personalizado *ngSwitchCase="'personalizado'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-personalizado>
        <report-table-por-usuario *ngSwitchCase="'porusuario'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-por-usuario>
        <report-table-perfil-usuario *ngSwitchCase="'perfildeusuario'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-perfil-usuario>
        <report-table-precio-medio-transaccion *ngSwitchCase="'preciomediotransaccion'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-precio-medio-transaccion>
        <report-table-resueltas-por-gestor *ngSwitchCase="'resueltasporgestor'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-resueltas-por-gestor>
        <report-table-tiempo-medio-gestion *ngSwitchCase="'tiempomediogestion'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-tiempo-medio-gestion>
        <report-table-tiempo-medio-resolucion *ngSwitchCase="'tiempomedioresolucion'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-tiempo-medio-resolucion>
        <report-table-tipo-documento *ngSwitchCase="'tipodocumento'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-tipo-documento>
        <report-table-todas-publicaciones *ngSwitchCase="'todaspublicaciones'"
            [report]="reportTable.report"
            [configurateReport]="reportTable.configurateReport"
            (updateListEmit)="updateListOn()"
            (exportReportEmit)="exportReportOn($event)">
        </report-table-todas-publicaciones>
    </div>
</div>
