<div class="evaluation-import">
  <header-nav></header-nav>
  <div class="row evaluation-import__main">
      <!-- Producto sin suscripción -->
      <div class="evaluation-import__dialog"
        [ngShow]="evaluationImport.ready">
          <div *ngIf="!evaluationImport.showNoPermissionsWarning">
              <header>
                  <h1 class="evaluation-import__dialog__title">{{evaluationImport.dialog.heading}}</h1>
                  <div class="evaluation-import__dialog__icon">
                      <i [class]="evaluationImport.dialog.icon"></i>
                  </div>
              </header>
              <main class="evaluation-import__dialog__text">
                  <div>
                      <span>{{evaluationImport.dialog.text}}</span>
                      <div class="evaluation-import__dialog__buttons"
                           *ngIf="evaluationImport.dialog.buttons?.length"
                           [class]="'evaluation-import__dialog__buttons--b' + evaluationImport.dialog.buttons?.length">
                          <button *ngFor="let button of evaluationImport.dialog.buttons"
                                  class="evaluation-import__dialog__button"
                                  [ngClass]="{'evaluation-import__dialog__button--active': button.active}"
                                  (click)="clickAction(button.clickAction)">{{button.text}}</button>
                      </div>
                  </div>
              </main>
          </div>
          <no-permissions *ngIf="evaluationImport.showNoPermissionsWarning"
                [continueText]="evaluationImport.noPermissionsContinueText"
                (launchAction)="clickAction('toDashboard')"></no-permissions>
          <footer class="evaluation-import__dialog__footer">
              <div [afTranslate]="'evaluation-import.help-title'">¿Necesitas ayuda?</div>
              <div class="evaluation-import__dialog__contact">
                  <span class="evaluation-import__dialog__phone" [afSiteTerms]="'tel'">91 210 80 00 - 902 44 33 55</span>
                  <span> | </span>
                  <span [afSiteTerms]="'mail'">clientes@lefebvre.es</span>
              </div>
          </footer>
      </div>
  </div>
  <compliance-footer *ngIf="evaluationImport.ready"></compliance-footer>
</div>

