import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import $ from 'jquery';

@Component({
  selector: 'attach-document-modal',
  templateUrl: './attach-document-modal.component.html'
})
export class AttachDocumentModalComponent implements OnInit {
  skin: string = this.globalCfg.skin
  conceptObjectId: any = this.route.snapshot.params['conceptObjectId'];
  currentComponent: string = this.route.snapshot.firstChild?.data['componentName'];
  isLanding: boolean = this.customLanding.isLanding();
  attachDocumentModal:any = {
    originAttach: {
      label: this.translate.instant('attach-document.origin'),
      required:  true
    },
    typeFormat: {
        fieldId:'format',
        label: this.translate.instant('common.format'),
        required: true,
    },
    documentName: {
        label: this.translate.instant('common.document-name'),
        required:  true
    },
    evaluationDocument: {
        label: this.translate.instant('attach-document.implementation-document'),
        required:  true
    },
    originList: [],
    evaluationDocumentList: [],
    textareaLabel: this.translate.instant('attach-document.add-comment'),
    title: this.translate.instant('attach-document.attach-document')
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal) { }
    @Input() item: any = null;
    @Input() list: any = null;

  ngOnInit(): void {
    this.clearForm();
    if(this.item?.conceptObjectId && this.item?.taskId){
        this.getConceptDocumentation();
    }
    this.getFormats();
  }

  getFormats(){
      this.apiService.getDocumentsFormats()
        .then((data:any)=>{
          data.forEach((item:any) => {
            item.text = this.translate.instant(item.translate)
          });
          this.attachDocumentModal.format = data;
          this.configureMap();
          this.originList();
        })

  }


  configureMap(){
    this.attachDocumentModal.templateIndex = new Map();
    this.attachDocumentModal.format.forEach((formatProduct:any) => {
      this.attachDocumentModal.templateIndex.set(formatProduct.listValueId, formatProduct.extension)
    });
  }

  resetItem(index:any){
    this.attachDocumentModal.fileName.splice(index, 1);
      if(!this.attachDocumentModal.fileName.length){
          this.attachDocumentModal.addAttachError = false;
      }else{
          for(let [index, filename] of this.attachDocumentModal.fileName.entries()){
            this.attachDocumentModal.addAttachError = false;
              if(this.attachDocumentModal.fileName[index].name.length > 99){
                this.attachDocumentModal.addAttachError = true;
                  break;
              }
          }
      }
      this.attachDocumentModal.attachDocumentSelectedList.splice(index, 1);
  }

  clearForm(){
    let that: any = this.attachDocumentModal;
      that.idValue = 1;
      that.origin = 0;
      that.comments = '';
      that.fileName = [];
      that.associatedDocuments=''
      that.attachDocumentSelectedList = [];
      that.addAttachError = false;
      that.typeFormat.error = false;
      that.evaluationDocument.error = false;
      that.documentName.error = false;
      that.document = {
          extension:'',
          name:''
      };
  }

  originList() {
    let that: any = this.attachDocumentModal;
    if(this.currentComponent === 'genericTask' || this.currentComponent === 'conceptWindow'){
      that.originList = [
        {
            text: this.translate.instant('attach-document.create-new-document'),
            listValueId: 3
        }
      ];
    }else if(this.currentComponent !== 'landingPage'){
      that.originList = [
        {
            text: this.translate.instant('attach-document.associate-documentation-files'),
            listValueId: 2
        }, {
            text: this.translate.instant('attach-document.create-new-document'),
            listValueId: 3
        }
      ];
    }
  }

  getConceptDocumentation(){
      this.apiService.get('documentobjects/onlyDB/notintask/'+this.item?.conceptObjectId, this.item.taskId)
      .then((data:any)=>{
        this.attachDocumentModal.evaluationDocumentList = this.commonUtilsService.editList(data, 'documentObjectId', 'name');
      })
  }

  itemSelected(e:any, type:any){
      let newValue: any = e.newValue;
      let that: any = this.attachDocumentModal;
      switch(type){
          case 'name':
              that.document.name = newValue;
              break;
          case 'format':
              that.selectFormat = newValue
              that.document.extension = that.templateIndex.get(newValue);
              break;
          case 'associatedDocument':
              that.associatedDocuments = newValue;
              break;
          default:
            if(newValue === 3 && this.commonUtilsService.isIE()){
                this.clearForm();
                this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
            }else{
                that.idValue = newValue;
                that.originAttach.error = false;
            }
            break;
      }
  }

  uploadDocument(files:any){
    files.forEach((file:any) => {
      let hasError = false;
      if(file.name.length > 99){
          hasError = !hasError;
          this.attachDocumentModal.addAttachError = true;
      }
      this.attachDocumentModal.fileName.push({name: file.name, hasError: hasError});
      let fd = new FormData();
      fd.append('file', file);
      this.attachDocumentModal.attachDocumentSelectedList.push(fd);
    });

  }

  createNewFile(){
    let that: any = this.attachDocumentModal;
      that.typeFormat.error = that.document.extension === '';
      that.documentName.error = that.document.name === '';
      that.documentName.errorMaxlength = that.document.name.length > 99;
      if(that.documentName.errorMaxlength || this.checkNameDocumentationList()){
          that.documentName.error = true;
      }
      if(!that.typeFormat.error && !that.documentName.error && !that.documentName.errorMaxlength){
          that.attachDocumentSelectedList = [{
              name: that.document.name +'.'+ that.document.extension,
              taskId: this.item.taskId,
              url: 'documentobjects/fromOfficeDocumentTemplate/'+this.item?.conceptObjectId+'/'+that.selectFormat,
              fileId: that.selectFormat
          }];
          this.handleAccept();
      }
  }

  associateDocuments(){
    let that: any = this.attachDocumentModal;
      if(!that.associatedDocuments || that.associatedDocuments === ''){
          that.evaluationDocument.error = true;
      }else{
          that.evaluationDocument.error = false;
          that.attachDocumentSelectedList=[{
              documentObjectId:   that.associatedDocuments
          }]
          this.handleAccept();
      }
  }

  checkNameDocumentationList(){
    let that: any = this.attachDocumentModal;
      let listDocument = this.list.concat(that.evaluationDocumentList);
      for(let document of listDocument){
          let name = document.name.substr(0, document.name.lastIndexOf('.'));
          if(that.document.name.toLowerCase() === name.toLowerCase() && that.document.extension.toLowerCase() === document.extension.toLowerCase()){
              that.duplicateNameList = true;
              return true;
          }
      }
      return false;
  }

  upFile(){
      this.attachDocumentModal.duplicateNameList = false;
      if(!this.attachDocumentModal.idValue  || this.attachDocumentModal.idValue === null){
          this.attachDocumentModal.originAttach.error = true;
      }else{
          switch(this.attachDocumentModal.idValue){
              case 2:
                  this.associateDocuments()
                  break;
              case 3:
                  this.createNewFile();
                  break;
              default:
                  break;
          }
      }
  }

  openFileSearch(){
      setTimeout(function(){
        let item: any = $('#upload-attach-document')
        item.click();
      })
  }

  openTextareaModal() {
      this.modalService.expandTextarea(this.attachDocumentModal.comments, { label: this.attachDocumentModal.textareaLabel }).subscribe((result:any) => {
        if(result.resul === 'ok'){
          this.attachDocumentModal.comments = result.inputText;
        }
      });
  }

  resetInput() {
    this.attachDocumentModal.comments = "";
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      documents: this.attachDocumentModal.attachDocumentSelectedList,
      action: this.attachDocumentModal.idValue,
      comments: this.attachDocumentModal.comments,
      names: this.attachDocumentModal.fileName
    })
  };

}
