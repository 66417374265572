import { Injectable } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';

import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { cloneDeep, remove } from 'lodash';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { Router } from '@angular/router';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';

@Injectable({
  providedIn: 'root'
})
export class DashboardPanelParentService {

  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private userPermisions: UserPermisionsFactory,
    private router: Router,
    private broadcastService: BroadcastService,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private rolesUtilsService: RolesUtilsService,
    private reportVersion: ReportVersionFactory) { }
  configureDashboardPanel(child:any){
    return {
        chargedClients: false,
        chargedUserPermissions: false,
        concursalQueryProduct: null,
        filterProducts: null,
        filterClients: null,
        warningCircle:false,
        showTask: true,
        taskState: 'all',
        typeDashboard:  this.context.settings.defaultProduct==='blanqueo'?'':'normal',
        evaluations: [],
        filterInstancesList: [],
        allLoad: false,
        clientList:[],
        productSelected:{},
        clientAdded: false,
        instances: [],
        deletedEvaluations: [],
        reports: [],
        formsProduct: null,
        noHasTemplatesReports: false,
        tasksLists: [],
        processTasksList: [],
        myTaksList:[],
        selectTaskList: [],
        knowledgeImplantations: [],
        filterKnowledgeList: [],
        filteredEvaluationList: [],
        filteredDeletedEvaluations: []
    }
  }

  init(child:any){
      child.forUk = this.globalCfg.dashboardVersion === '3';
      child.noBeAndUk = this.globalCfg.skin !== 'steel-blue' && !child.forUk;
      child.noAmlBE = this.context.settings.defaultProduct && this.context.settings.defaultProduct !== 'blanqueo' && this.context.settings.defaultProduct !== 'aml002' && this.globalCfg.skin === 'steel-blue';
      child.noMenuProducts = child.noAmlBE || this.globalCfg.skin === 'icam-red' || child.forUk;
      let params = new URLSearchParams(window.location.href);
      params.delete('sso')
      this.loader.openLoader('dashboard-panel-on-init');
      this.configInit(child);
  }

  getReportsVersion(){
    this.apiService.get('denunc/dashboard/reports/', 'ids')
      .then((data:any)=>{
        this.reportVersion.setReportVersion(data);
      }, (errorData:any)=>{})
  }

  configInit(child:any){
      this.configureUrlSkin(child);
      this.getClients(child);
      this.getProducts(child);
      child.forUk? this.getProcesses(child) : this.getTask(child);
      if(child.noMenuProducts){
        child.finishAllCalls += 1;
      }else{
        this.getKnowledgeImplantations(child);
      }
  }

  configureUrlSkin(child:any){
    switch(this.globalCfg.skin){
      case 'steel-blue':
          child.wsController = 'be/wh';
          child.urlPart = 'be/wh/dashboard';
          child.urlSecondPartInstances = 'complaintsTable';
          break;
      case 'uk-steel-blue':
          child.urlPart = 'uk/taxwin/dashboard';
          child.urlSecondPartInstances = 'processesTable';
          break;
      case 'icam-red':
          child.urlPart = 'icam/dashboard';
          child.urlSecondPartInstances = 'consultastable';
          break;
      default:
          child.urlPart = 'denunc/dashboard';
          child.urlSecondPartInstances = 'complaintsTable';
          break;
    }
  }

  getClients(child:any){
      this.apiService.get('clients', 'all')
      .then((data: any)=> {
          if(child.forUk && data.length){
              data[0].selectedClient = true;
              child.dashboardPanel.selectedClient = data[0];
          }
          child.dashboardPanel.clientList = data;
          child.dashboardPanel.chargedClients = true;
          child.finishAllCalls++;
          this.closeLoader(child);
      }, (errorData:any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      });
  }

  closeLoader(child:any){
      if(child.finishAllCalls === 5){
          if(child.forUk){
              this.configureListClients(child);
          }
          this.loader.closeLoader(['front-login-login','dashboard-panel-on-init']);
          child.finishAllCalls = 0;
          child.dashboardPanel.allLoad = true;
          this.addFilterProduct(child.dashboardPanel.productSelected, child, false);
          this.configureTypeDashboard(child.dashboardPanel.productSelected, child);
      }
      if(child.dashboardPanel.clientAdded){
        child.dashboardPanel.clientAdded = false;
        this.configureListClients(child);
      }
  }

  configureListClients(child:any){
      child.dashboardPanel.clientList.forEach((client: any) => {
        delete client.evaluation;
        client.evaluation = child.dashboardPanel.evaluationList.filter((evaluation:any)=>{ return evaluation.clientId === client.clientId})[0];
      });
  }

  configureTypeDashboard(product: any, child:any){
    if(product && product.productId === 'blanqueo' && this.checkEvaluationListBlanqueo(child))  {
      child.dashboardPanel.typeDashboard = 'onboarding';
    }else{
      child.dashboardPanel.typeDashboard = 'normal';
    }
  }

  checkEvaluationListBlanqueo(child: any){
    let list : any = this.commonUtilsService.filterEvaluationByProduct(child.dashboardPanel.evaluationList, child.dashboardPanel.filterProducts);
    return !list.length;
  }

  getProducts(child:any){
    this.apiService.get('products', '')
      .then((data: any) => {
          this.setProductColor(data);
          child.hasDenuncProp = false;
          if(this.hasDenunc(data)){
              child.hasDenuncProp = true;
          }
          this.getEvaluations(child);
          if(data.length){
              child.dashboardPanel.productList = this.commonUtilsService.setProductList(data);
              child.dashboardPanel.productList.length === 1 || !this.context.settings.defaultProduct? this.selectFirstProduct(child) : this.configureProductList(child);
              this.getProductPermissions(child.dashboardPanel.productSelected.productId, child);
              this.configureViewReportGraphic(child);
          }
          if((this.globalCfg.skin === 'dark-blue' || this.globalCfg.skin === 'icam-red') && child.dashboardPanel.productSelected?.productId){
              this.getTemplatesReports(true, child);
          }else{
              child.finishAllCalls++;
              child.dashboardPanel.chargedTemplatesReports = true;
              this.closeLoader(child);
          }
          if(child.dashboardPanel.productSelected?.productId === 'denunc' && !sessionStorage.hasOwnProperty('report-version')){
            this.getReportsVersion();
          }
      }, (errorData: any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      });
  }

  selectFirstProduct(child:any){
    let that: any = child.dashboardPanel
    that.productList[0].selected = true;
    that.productSelected = that.productList[0];
    this.context.settings.defaultProduct = that.productList[0].productId;
    this.context.settings.defaultProductName = that.productList[0].name;
    this.context.settings.productWithSnippets = that.productList[0].snippets;
    this.context.storeSettings();
  }

  configureViewReportGraphic(child:any){
    switch(child.dashboardPanel.productSelected.productId){
      case 'denunc':
      // case 'blanqueo':
      case 'icam':
      case 'whistle':
          child.dashboardPanel.viewReportGraphic = true;
          break;
      default:
          child.dashboardPanel.viewReportGraphic = false
          break;
    }
  }

  setProductColor(products: any){
    products.forEach((product: any) => {
      switch (product.productId){
          case 'bpt':
          case 'blanqueo':
          case 'bpt002':
              product.color = 'fiscal';
              break
          case 'centF':
          case 'cpda':
          case 'one':
          case 'gdpr002':
          case 'gdpr003':
          case 'gdpr004':
          case 'adaptalia':
              product.color = 'administrativo';
              break;
          case 'penal':
          case 'penal002':
              product.color = 'penal';
              break;
          case 'cm':
              product.color = 'mercantil';
              break;
          case 'igualdad':
              product.color = 'igualdad';
              break;
          default:
              product.color = 'n-a';
              break;
      }
    });
  }

  hasDenunc(products: any){
    return !!products.filter((product: any)=>product.productId === 'denunc').length
  }

  getEvaluations(child:any){
      this.apiService.get(child.wsController, 'dashboard/evaluations')
          .then((data: any)=> {
              child.dashboardPanel.evaluationList = data;
              child.forUk? this.getInstancies(child): this.getDeletedEvaluations(child);
          }, (errordata: any)=>{
              this.loader.closeError();
              child.dashboardPanel.allLoad = true;
          });
  }

  getInstancies(child:any){
      this.apiService.get(child.urlPart, child.urlSecondPartInstances)
          .then((data: any)=>{
              child.dashboardPanel.instances = data;
              child.dashboardPanel.filterInstancesList = data;
              if(child.dashboardPanel.productSelected=== null || (child.dashboardPanel.productSelected !== null && child.dashboardPanel.productSelected.productId !=='denunc')){
                  child.finishAllCalls++;
              }
              if(child.hasDenuncProp){
                child.dashboardPanel.instances.forEach((instance: any) => {
                    if(instance.confidential){
                    this.setConfidential(instance, child)
                    }
                });
              }
              this.closeLoader(child);
          }, (errorData:any)=>{
              this.loader.closeError();
              child.dashboardPanel.allLoad = true;
          })
  }

  setConfidential(instance: any, child:any){
      this.apiService.get('conceptobjects/confidentialaccess', instance.conceptObjectId)
      .then((data:any)=>{
          instance.confidentialList = data;
      },(errorData:any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      })
  }

  getDeletedEvaluations(child:any){
      this.apiService.get(child.wsController, 'dashboard/evaluations/deleted')
      .then((data: any)=>{
          child.dashboardPanel.deletedEvaluations = data;
          if(this.globalCfg.skin === 'steel-blue' || this.globalCfg.skin === 'icam-red' || child.hasDenuncProp){
              this.getInstancies(child);
          }
          if(this.globalCfg.skin !== 'steel-blue'){
              this.getReports(child);
          }
          if(child.hasDenuncProp){
              child.finishAllCalls--;
              this.getDenuncEvaluations(child);
          }
      }, (errordata: any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      });
  }

  getReports(child:any){
      this.apiService.get('company/dashboard','reports' ).then((data:any)=>{
          child.dashboardPanel.reports = data;
          child.finishAllCalls++;
          this.closeLoader(child);
      }, (errorData:any)=>{
          child.dashboardPanel.allLoad = true;
          this.loader.closeError();
      });
  }

  getDenuncEvaluations(child:any){
      this.apiService.get(child.urlPart, 'evaluations')
          .then((data:any)=>{
              data.forEach((item:any) => {
                  this.replaceInEvaluation(item, child.dashboardPanel.evaluationList);
              });
              this.getDenuncDeletedEvaluations(child);
          }, (errordata:any)=>{});
  }

  replaceInEvaluation(evaluation:any, list:any){
      let index = list.findIndex((item:any)=>{return item.evaluationId === evaluation.evaluationId})
      if(typeof index === 'number'){
          list.splice(index, 1,evaluation);
      }
  }

  getDenuncDeletedEvaluations(child:any){
      this.apiService.get( child.urlPart, 'evaluations/deleted')
          .then((data:any)=>{
              data.forEach((item:any) => {
                  this.replaceInEvaluation(item, child.dashboardPanel.deletedEvaluations);
              });
              child.finishAllCalls++;
              this.closeLoader(child);
          }, (errordata:any)=>{});
  }

  configureProductList(child:any){
      let that: any = child.dashboardPanel
      for(let i = that.productList.length-1; i >= 0; i--){
          let product = that.productList[i];
          if (product.productId === 'forms') {
          that.formsProduct = product;
          that.productList.splice(i, 1);
          }
          if(product.productId === this.context.settings.defaultProduct){
              product.selected = true;
              that.productSelected = product;
              break;
          }
      }
      if(!that.productSelected){
        this.selectFirstProduct(child);
      }
  }

  getTemplatesReports(closeLoaders:boolean, child:any){
      this.apiService.get('products/'+child.dashboardPanel.productSelected.productId, 'reports').then((data:any)=> {
          let templatesReports = Array.from(data);
          let hasPredefinedReports = Boolean(Number(templatesReports[0]));
          let hasCustomReports = Boolean(Number(templatesReports[1]));
          child.dashboardPanel.noHasTemplatesReports = !(hasPredefinedReports || hasCustomReports);
          child.dashboardPanel.chargedTemplatesReports = true;
          child.finishAllCalls++;
          this.closeLoader(child);
      }, (errorData: any)=>{});
  }

  getTask(child:any){
      this.apiService.get('tasks', '')
      .then((data:any)=>{
          child.dashboardPanel.tasksLists = data;
          child.dashboardPanel.processTasksList = remove(child.dashboardPanel.tasksLists, { taskTypeId : 7 });
          this.getMyTasks(child);
      }, (errorData:any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      })
  }

  getMyTasks(child:any){
    this.apiService.get('tasks', 'mytasks')
        .then((data:any)=>{
            child.dashboardPanel.myTaksList = data;
            this.changeTaskList(child.dashboardPanel.taskState, child)
            child.finishAllCalls++;
            this.closeLoader(child);
        }, (errorData: any)=>{
            this.loader.closeError();
            child.dashboardPanel.allLoad = true;
        });
  }

  changeTaskList(view:string, child:any){
      let that: any = child.dashboardPanel
      that.taskState = view;
      that.selectTaskList = that.tasksLists;
      if (view === 'mytask') {
          that.selectTaskList = that.myTaksList;
      } else if (view === 'process') {
          that.selectTaskList = that.processTasksList;
      }

      if(that.filterClients === null){
          that.filteredTaskList = that.filterProducts === null?
          that.selectTaskList:
          this.commonUtilsService.filterEvaluationByProduct(that.selectTaskList, that.filterProducts, true);

      }else{
          that.filteredTaskList =  this.commonUtilsService.filterEvaluationByClient(that.selectTaskList, that.filterClients);
          if(that.filterProducts !== null){
              that.filteredTaskList = this.commonUtilsService.filterEvaluationByProduct(that.filteredTaskList, that.filterProducts, true);
          }
      }
  }

  getKnowledgeImplantations(child:any){
    this.apiService.get('company/dashboard','queryevaluations' )
        .then((data:any)=>{
            if (data && data.length > 0) {
              data.forEach((query:any) => {
                if(query.productId === 'aml002'){
                    child.dashboardPanel.amlQuery = query;
                }
              });
            }
            child.dashboardPanel.knowledgeImplantations = data;
            child.finishAllCalls++;
            this.closeLoader(child);
        }, (errorData:any)=>{
            this.loader.closeError();
            child.dashboardPanel.allLoad = true;
        })
  }

  getProcesses(child: any) {
    this.apiService.get('uk/taxwin/dashboard/processesAvailableTable')
      .then((data:any)=>{
        child.dashboardPanel.processesList = data;
        child.finishAllCalls++;
        this.closeLoader(child);
      }, (errorData:any)=>{
          this.loader.closeError();
          child.dashboardPanel.allLoad = true;
      })
  }

  addFilterProduct(product:any, child:any, changeProduct:boolean = false){
    let that: any = child.dashboardPanel;
    let commonUtilsService: any = this.commonUtilsService
    let filterEvaluationByProduct: any = this.commonUtilsService.filterEvaluationByProduct
    child.instaceBox = this.globalCfg.skin === 'steel-blue' || this.globalCfg.skin === 'uk-steel-blue' || (that.productSelected && that.productSelected.productId ==='denunc');
    that.filteredEvaluationList = [];
    that.filteredDeletedEvaluations = [];
    that.filteredTaskList = [];
    that.filterProducts = product;
    if(that.filterClients === null){
        that.filteredEvaluationList = filterEvaluationByProduct(that.evaluationList, that.filterProducts);
        if(!child.forUk){
            that.filteredDeletedEvaluations = filterEvaluationByProduct(that.deletedEvaluations, that.filterProducts);
            that.filteredTaskList = filterEvaluationByProduct(that.selectTaskList, that.filterProducts, true);
        }
        if(child.instaceBox){
            that.filterInstancesList = filterEvaluationByProduct(that.instances, that.filterProducts);
        }
        if(child.noBeAndUk){
            that.filterReportsList = commonUtilsService.filterReportsByEvaluation(that.reports, that.filteredEvaluationList);
        }
    }else{
        that.filteredEvaluationList = commonUtilsService.filterEvaluationByClient(that.evaluationList,  that.filterClients);
        that.filteredEvaluationList = filterEvaluationByProduct(that.filteredEvaluationList, that.filterProducts);
        if(!child.forUk){
            that.filteredDeletedEvaluations = commonUtilsService.filterEvaluationByClient(that.deletedEvaluations,  that.filterClients);
            that.filteredDeletedEvaluations = filterEvaluationByProduct(that.filteredDeletedEvaluations, that.filterProducts);
            that.filteredTaskList = commonUtilsService.filterEvaluationByClient(that.selectTaskList,  that.filterClients);
            that.filteredTaskList = filterEvaluationByProduct(that.filteredTaskList, that.filterProducts, true);
        }
        if(child.instaceBox){
            that.filterInstancesList = commonUtilsService.filterEvaluationByClient(that.instances, that.filterClients);
            that.filterInstancesList =  filterEvaluationByProduct(that.filterInstancesList, that.filterProducts);
        }
        if(child.noBeAndUk){
            that.filterReportsList = commonUtilsService.filterReportsByEvaluation(that.reports, that.filteredEvaluationList);
        }
    }
    if(changeProduct){
      this.configureTypeDashboard(product, child);
    }
  }

  addFilterClient(client:any, child:any){
    let that: any = child.dashboardPanel;
    let commonUtilsService: any = this.commonUtilsService
    let filterEvaluationByProduct: any = this.commonUtilsService.filterEvaluationByProduct;
    that.instaceBox = this.globalCfg.skin === 'steel-blue' || this.globalCfg.skin === 'uk-steel-blue' || (that.productSelected && that.productSelected.productId ==='denunc');
    that.filteredTaskList = [];
    that.filteredEvaluationList = [];
    that.filteredDeletedEvaluations = [];
    that.filterClients = client;
    if(client === null){
        that.filteredEvaluationList = filterEvaluationByProduct(that.evaluationList, that.filterProducts);
        if(!child.forUk){
            that.filteredDeletedEvaluations = filterEvaluationByProduct(that.deletedEvaluations, that.filterProducts);
            that.filteredTaskList = filterEvaluationByProduct(that.selectTaskList, that.filterProducts, true);
        }
        if(child.instaceBox){
            that.filterInstancesList = filterEvaluationByProduct(that.instances, that.filterProducts);
        }
        if(child.noBeAndUk){
            that.filterReportsList = commonUtilsService.filterReportsByEvaluation(that.reports, that.filteredEvaluationList);
        }
    }else{
        that.filteredEvaluationList = commonUtilsService.filterEvaluationByClient(that.evaluationList,  that.filterClients);
        if(!child.forUk){
            that.filteredDeletedEvaluations = commonUtilsService.filterEvaluationByClient(that.deletedEvaluations,  that.filterClients);
            that.filteredTaskList =  commonUtilsService.filterEvaluationByClient(that.selectTaskList, that.filterClients);
        }
        if(child.instaceBox){
            that.filterInstancesList = commonUtilsService.filterEvaluationByClient(that.instances, that.filterClients);
        }
        if(child.noBeAndUk){
            that.filterReportsList = commonUtilsService.filterReportsByEvaluation(that.reports, that.filteredEvaluationList);
        }

        if(that.filterProducts !== null){
            that.filteredEvaluationList = filterEvaluationByProduct(that.filteredEvaluationList, that.filterProducts);
            if(!child.forUk){
                that.filteredDeletedEvaluations = filterEvaluationByProduct(that.filteredDeletedEvaluations, that.filterProducts);
                that.filteredTaskList =  filterEvaluationByProduct(that.filteredTaskList, that.filterProducts, true);
            }
            if(child.instaceBox){
                that.filterInstancesList = filterEvaluationByProduct(that.filterInstancesList, that.filterProducts);
            }
            if(child.noBeAndUk){
                that.filterReportsList = commonUtilsService.filterReportsByEvaluation(that.reports, that.filteredEvaluationList);
            }
        }
    }
  }

  changeProduct(productSelected: any, child:any){
      if(productSelected.productId !== this.context.settings.defaultProduct){
          child.dashboardPanel.chargedUserPermissions = false;
          this.getProductPermissions(productSelected.productId, child);
          let language: any = sessionStorage.getItem('selectLanguage');
          let languageModificable: boolean = Boolean((this.globalCfg.skin === 'dark-blue' && language === 'fr-FR') || (this.globalCfg.skin === 'gm-law' && language === 'it-IT'));
          if(this.globalCfg.skin === 'steel-blue'){
            let currentLanguageKey = this.context.getLanguageKey(language, this.context.settings.defaultProduct);
            let newLanguageKey = this.context.getLanguageKey(language, productSelected.productId);
            if(currentLanguageKey !== newLanguageKey) {
                this.setSettingsContext(productSelected, child);
                window.location.reload();
            }
          }else if(languageModificable && (this.context.settings.defaultProduct === 'denunc' || productSelected.productId === 'denunc')){
            let languageKey: any = this.context.getLanguageKey(language, productSelected.productId);
            if(this.translate.hasLanguage(languageKey)){
              this.translate.setLanguageToTranslate(languageKey)
              this.changeProductSelected(productSelected, child);
            }else{
              this.translate.getTranslates(languageKey).then((data:any)=>{
                this.changeProductSelected(productSelected, child);
              }, (errorData:any)=>{})
            }
          }else{
              this.changeProductSelected(productSelected, child);
          }
      }
  }

  changeProductSelected(productSelected: any, child:any){
    child.dashboardPanel.chargedTemplatesReports = false;
    child.dashboardPanel.productList.forEach((product: any) => {
        product.selected = false;
        if(product.productId === productSelected.productId){
            product.selected = true;
            child.dashboardPanel.productSelected = product;
            this.setSettingsContext(productSelected, child);
            this.getTemplatesReports(false, child);
            this.addFilterProduct(child.dashboardPanel.productSelected, child, true);
        }
    });
    this.configureViewReportGraphic(child);
    $('#compliance-view-body').scrollTop(0);
    if(child.dashboardPanel.productSelected?.productId === 'denunc' && !sessionStorage.hasOwnProperty('report-version')){
        this.getReportsVersion();
    } 
  }

  getProductPermissions(productId: any, child:any){
    this.commonUtilsService.getProductPermissions(productId, this.apiService)
      .then((data:any) => {
        child.dashboardPanel.userPermissions = this.userPermisions.getPermissions();
        child.dashboardPanel.productPermissions = data;
        child.dashboardPanel.managePermissions = data.allowManage;
        child.dashboardPanel.modifyEvaluation = (this.userPermisions.getTypePermissions() !=='read');
        child.dashboardPanel.chargedUserPermissions = true;
      }, (errorData:any)=>{
          this.loader.closeError()
      })
  }

  setSettingsContext(productSelected: any, child:any){
      this.context.settings.defaultProduct = productSelected.productId;
      this.context.settings.defaultProductName = productSelected.name;
      this.context.settings.productWithSnippets = productSelected.snippets;
      this.context.storeSettings();
  }

  viewKnowledge(evaluation: any, child:any) {
    this.router.navigate(['evaluation', evaluation.evaluationId])
  }

  launchAction(action: any, child:any){
    let textInfo:any = {}
    switch(action.action){
        case 'newClient':
            if(child.forUk){
                child.dashboardPanel.clientAdded = true;
            }
            this.getEvaluations(child);
            break;
        case 'editClientDetail':
            child.clientsListUk.editClientDetail(child.dashboardPanel.selectedClient);
            break;
        case 'deleteClient':
            textInfo = {
                text: this.translate.instant('dashboard.delete-client-message') + action.client.name+' '+this.translate.instant('dashboard.question-delete'),
                icon: 'info:question:question',
                option: 'goDeleteClient',
                item: action.client
            }
            this.modalInfo(textInfo, child);

            break;
        case 'deleteClientDetail':
            textInfo = {
                text: this.translate.instant('dashboard.question-delete-client-uk'),
                icon: 'info:questionDeleteClient:question',
                option: 'goDeleteClientUk',
                item: action.client
            }
            this.modalInfo(textInfo, child);
            break;
        case 'selectClient':
            child.dashboardPanel.selectedClient = action.client;
            break;
        case 'changeInSelectedClient':
            if(child.forUk){
                let filterInstancesList = cloneDeep(child.dashboardPanel.filterInstancesList);
                let modifiedInstancesList = this.modifyInstancesList(action.client, filterInstancesList);
                child.dashboardPanel.filterInstancesList = modifiedInstancesList;
            }
            this.broadcastService.broadcast('changeInSelectedClient', {});
            break;
        case 'newEvaluation':
            this.modalNewEvaluation(child, action.product);
            break;
        case 'deleteEvaluation':
            textInfo = {
                text: this.translate.instant('dashboard.question-delete-implantation').replace('{implantation}', action.evaluation.name),
                icon: 'info:deleteOption:question',
                option: 'goDeleteEvaluation',
                item: action.evaluation
            }
            this.modalInfo(textInfo, child);
            break;
        case 'deleteEvaluationDefinitely':
            textInfo = {
                text: this.translate.instant('dashboard.remove-definitely-implantation').replace('{0}', action.evaluation.name),
                icon: 'info:question:question',
                option: 'goDeleteEvaluationDefinitely',
                item: action.evaluation
            }
            this.modalInfo(textInfo, child);
            break;
        case 'deleteTask':
            textInfo = {
                text: this.translate.instant('dashboard.question-delete-task') +"'"+ action.task.title + "'?",
                icon: 'info:question:question',
                option: 'goDeleteTask',
                item: action.task
            }
            this.modalInfo(textInfo, child);
            break;
        case 'openTask':
            let task = action.task;
            if(task.taskTypeId){
              task.conceptObjectId? this.getConcept(task, 'open task', child) : this.registerServiceEmit(task, 'dashboard', 'open task', child);
            }
            if(task.conceptObjectId){
               task.productId === 'blanqueo' && !task.evaluationId? this.goToConceptWindow(task, child) : this.goToConcept(task, child);
            }else{
                this.goToTask(task, child);
            }
            break;
        case 'newReport':
            this.modalNewReport(child);
            break;
        case 'restoreEvaluation':
            this.restoreEvaluation(action.evaluation, child);
            break;
        case 'concursalForms':
            this.concursalForms(child);
            break;
        case 'openKnowledge':
            this.viewKnowledge(action.evaluation, child);
            break;
        case 'goToProcesses':
                this.isUpgradeable(action.client.evaluation, child);
            break;
        case 'generateProcessDatabase':
            let itemSelected = {
                type: 'create-evaluation',
                item: {
                  clientId: action.client.clientId,
                  productId: child.dashboardPanel.productList[0].productId
                }
            }

            this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
              if(result.result === 'ok'){
                this.commonUtilsService.registerEvent('create implantation', this.context.settings.defaultProduct);
                this.router.navigate(['evaluation', result.item.evaluationId])
              }else{
                this.modalService.adviceModal(this.translate.instant('dashboard.error-implantation-create')+'"'+action.client.name+'"', 'error:accept:warning')
              }
            });
            break;
        case 'createEvaluation':
          this.saveNewEvaluation(action.result, child);
          break;
        case 'deleteReport':
          this.deleteSelectReport(action.report, child);
          break;
    }
  }


  modalInfo(textInfo:any, child:any){
    this.modalService.adviceModal(textInfo.text, textInfo.icon)
      .subscribe((result:any) => {
        if(result.result !== 'cancel'){
          switch(textInfo.option){
            case 'goDeleteEvaluation':
            case 'goDeleteEvaluationDefinitely':
                this.loader.openLoader('dashboard-panel-delete-evaluation');
                let optionTextInfo: any = result.result === 'permanently-delete'? 'goDeleteEvaluationActiveDefinitely':textInfo.option;
                this.commonUtilsService.registerEvent('delete implantation', this.context.settings.defaultProduct);
                this.deleteEvaluationSelected(textInfo.item, optionTextInfo, child);
                break;
            case 'goDeleteTask':
                this.loader.openLoader('dashboard-panel-delete-task');
                this.deleteTaskSelected(textInfo.item, child);
                break;
            case 'goDeleteClient':
                this.loader.openLoader('dashboard-panel-delete-client');
                this.deleteClientSelected(textInfo.item, child);
                break;
            case 'goDeleteClientUk':
                if(result.result === 'discard-processes'){
                    if(textInfo.item.evaluation){
                        this.loader.openLoader('dashboard-panel-delete-evaluation');
                        this.deleteEvaluationSelected(textInfo.item.evaluation, 'goDeleteToUk', child);
                    }else{
                      this.modalService.adviceModal(this.translate.instant('dashboard.no-associated-process'), 'info:accept:check-round');
                    }
                }else{
                    this.loader.openLoader('dashboard-panel-delete-client');
                    this.deleteClientSelected(textInfo.item, child);
                }
                break;
          }
        }
      });
  }

  modifyInstancesList(client:any, list:any){
    list.forEach((item:any)=>{
        if(client.clientId ===  item.clientId){
            item.reference2 = client.reference2;
            item.clientCIF = client.cif;
            item.clientName = client.name;
        }
    })
    return list;
  }

  modalNewEvaluation(child:any, product:any = null) {
    let configuration = {
      type: 'new',
      selectedProduct: product ? product : child.dashboardPanel.productSelected
    }

    this.modalService.createNewEvaluation(configuration)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('dashboard-panel-create-evaluation')
          this.saveNewEvaluation(result, child);
        }
      });
  }

  goToConceptWindow(task:any, child:any){
    this.loader.openLoader('open-new-concept-window');
    localStorage.setItem('taskId', task.taskId);
    if(task.clientId){
        this.router.navigateByUrl('client/'+task.clientId);
    }else{
        this.router.navigateByUrl('obligated-subject');
    }
  }

  goToConcept(task:any, child:any){
      this.loader.openLoader('dashboard-panel-concept-task')
      this.apiService.get('conceptObjects', task.conceptObjectId)
          .then((data:any)=>{
              this.router.navigateByUrl('evaluation/'+task.evaluationId+'/folder/'+data.folderId+'/concept/'+data.conceptId+'/concept-evaluated/'+task.conceptObjectId+'/tasks/'+task.taskId)
          }, (errorData:any)=>{
              this.loader.closeError()
          })
  }

  goToTask(task: any, child:any){
      this.loader.openLoader('dashboard-panel-generic-task');
      let taskId = task.taskId?task.taskId : '';
      this.router.navigate(['task', taskId]);
  }

  modalNewReport(child:any){
      let that: any = child.dashboardPanel
      let filteredEvaluationList = this.commonUtilsService.filterEvaluationByProduct(that.evaluationList, that.filterProducts);
      this.modalService.createNewReport(filteredEvaluationList, that.filterProducts)
        .subscribe((result:any) => {
          if(result.result === 'ok'){
              this.loader.openLoader('dashboard-panel-new-report');
              this.createReport(result.newReport, child);
          }
        });
  }

  restoreEvaluation(evaluation:any, child:any){
    let that: any = child.dashboardPanel
    this.apiService.patch('evaluations/restore', evaluation.evaluationId, null)
        .then((data:any)=>{
            evaluation.progress = 0;
            evaluation.status = 0;
            that.evaluationList.splice(0,0, evaluation);
            that.deletedEvaluations = this.commonUtilsService.deleteElement(evaluation, that.deletedEvaluations, 'evaluationId');
            this.addFilterProduct(that.filterProducts, child, false);
            if (evaluation.productId === 'forms') {
                this.configureProductForm(child);
            }

            this.loader.closeLoader(['restore-evaluation']);

            let textInfo = {
                text: this.translate.instant('dashboard.restore-ok-implantation').replace('{0}', evaluation.name),
                icon: 'info:accept:check-round',
                option: 'restoreEvaluation'
            }
            this.modalInfo(textInfo, child);
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('dashboard.error-restore'), 'error:accept:warning');
        })
  }

  configureProductForm(child:any){
      let that: any = child.dashboardPanel;
      that.formsProduct.evaluations = this.commonUtilsService.filterEvaluationByProduct(that.evaluationList, that.formsProduct);
      that.formsProduct.deletedEvaluations = this.commonUtilsService.filterEvaluationByProduct(that.deletedEvaluations, that.formsProduct);
  }

  concursalForms(child:any) {
      this.configureProductForm(child);
      this.modalService.concursalForms({product: child.dashboardPanel.formsProduct})
        .subscribe((result:any) => {
          if(result.result!=='cancel'){
            this.launchAction(result.action, child)
          }
        });
  }

  isUpgradeable(evaluation:any, child:any){
      this.apiService.get('evaluations/needupdateversion', evaluation.evaluationId)
        .then((data:any)=>{
            if(data === 1){
                this.loader.closeLoader(['dashboard-panel-create-evaluation']);
                this.modalService.adviceModal(this.translate.instant('evaluations-list.update-question'), 'info:question:question')
                  .subscribe((result:any) => {
                    result.result === 'ok'? this.updateEvaluation(evaluation, child) : this.router.navigate(['evaluation', evaluation.evaluationId])
                  })
            }else{
                this.router.navigate(['evaluation', evaluation.evaluationId ])
            }
        }, (errorData:any)=>{
            this.router.navigate(['evaluation', evaluation.evaluationId ])
        })
  }

  updateEvaluation(evaluation:any, child:any){
      let itemSelected = {
          type: 'update-evaluation',
          item: evaluation
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('dashboard-panel-create-evaluation');
            this.router.navigate(['evaluation', evaluation.evaluationId])
          }else{
            this.modalService.adviceModal(this.translate.instant('evaluations-list.error-update'), 'error:accept:warning')
              .subscribe((result:any) => {
                if(result.result === 'ok'){
                  this.loader.openLoader('dashboard-panel-create-evaluation');
                  this.router.navigate(['evaluation', evaluation.evaluationId ])
                }
              })
          }
        });
  }

  duplicateEvaluation(newEvaluation:any, child:any){
      let itemSelected = {
          type: 'duplicate-evaluation',
          item: newEvaluation
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          result.result === 'ok'? this.router.navigate(['evaluation', result.item.evaluationId ]) : this.modalService.adviceModal(this.translate.instant('dashboard.error-duplicate-evaluation') +"'"+ newEvaluation.evaluation.name + "'", 'error:accept:warning')
        });
  }

  createReport(newReport:any, child:any){
    let report:any ={
        title: newReport.name,
        customReportId: newReport.typeReport
    }
    if(newReport.predefined){
        delete report.customReportId;
        report.reportId = newReport.typeReport
    }
    this.apiService.add('reportobjects/'+newReport.typeReport+'/'+newReport.evaluation, report)
        .then((data:any)=>{
            this.commonUtilsService.registerEvent('create report', this.context.settings.defaultProduct, data.reportId, 'report')
            newReport && newReport.productId === 'denunc' && newReport.predefined && data.reportId === 128? this.router.navigate(["report-detail",data.reportObjectId ]) : this.openReport(newReport, data, child);
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('dashboard.error-report-create'), 'error:accept:warning');
        });
  }

  openReport(report:any, data:any, child:any){
    if(!data.type){
        this.router.navigate(["evaluation", report.evaluation, "reports"])
    }else{
      this.commonUtilsService.registerEvent('open report', this.context.settings.defaultProduct, data.reportId, 'report')
      this.router.navigate(["evaluation",report.evaluation, "reports", report.typeReport, "report-related",data.reportObjectId])
    }
  }

  saveNewEvaluation(result:any, child:any){
    result.roles && result.roles.length? this.saveRoles(result, child) : this.createEvaluation(result, child)
  }

  saveRoles(result:any, child:any){
    this.rolesUtilsService.saveRoles(result.roles, result.createEvaluation.productId, result.createEvaluation.clientId)
      .then((data:any)=>{
        this.createEvaluation(result, child);
      }, (errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('dashboard.error-assign-roles'), 'error:accept:warning');
      })
  }

  createEvaluation(result:any, child:any){
      let createEvaluation = result.createEvaluation;
      let url = 'evaluations/'+createEvaluation.productId+'/'+ createEvaluation.clientId;
      if(result.configuration.legalForm){
          let legalFormId = result.configuration.noEditable?0:createEvaluation.legalFormId;
          url += '/'+legalFormId;
          delete createEvaluation.legalFormId;
      }
      this.apiService.add(url, createEvaluation)
          .then((data:any)=>{
              this.commonUtilsService.registerEvent('new implantation', this.context.settings.defaultProduct)
              this.router.navigate(['evaluation',data.evaluationId])
          }, (errorData:any)=>{
              this.loader.closeError();
              let text = result.roles && result.roles.length? this.translate.instant('dashboard.error-implantation-create-roles') : this.translate.instant('dashboard.error-implantation-create')+'"'+createEvaluation.name+'"';
              this.modalService.adviceModal(text, 'error:accept:warning');
          });
  }

  deleteEvaluationSelected(evaluation:any, option:string, child:any){
    let that: any = child.dashboardPanel;
    let commonUtilsService: any = this.commonUtilsService;
    let deleteDefinitely = option === 'goDeleteEvaluationDefinitely' || option === 'goDeleteEvaluationActiveDefinitely' || option === 'goDeleteToUk'?  1 :  null;

    this.apiService.delete('evaluations/'+evaluation.productId+'/'+evaluation.clientId, evaluation.evaluationId, deleteDefinitely)
        .then((data:any)=>{
            if(option === 'goDeleteEvaluationDefinitely' || option === 'goDeleteEvaluationActiveDefinitely'){
                if(option === 'goDeleteEvaluationDefinitely'){
                    that.deletedEvaluations = commonUtilsService.deleteElement(evaluation,  that.deletedEvaluations, 'evaluationId');
                }else if(option === 'goDeleteEvaluationActiveDefinitely'){
                    that.evaluationList = commonUtilsService.deleteElement(evaluation,  that.evaluationList, 'evaluationId');
                }
                this.addFilterProduct(that.filterProducts, child, false);
                this.modalService.adviceModal(this.translate.instant('dashboard.delete-implantation-ok').replace('{implantation}', evaluation.name), 'info:accept:check-round');
            }else{
                that.evaluationList = commonUtilsService.deleteElement(evaluation,  that.evaluationList, 'evaluationId');
                evaluation.deletionDate = new Date();
                let textInfo = {
                    text: this.translate.instant('dashboard.remove-ok-implantation').replace('{implantation}', evaluation.name),
                    icon: 'info:accept:check-round',
                    option: 'deletedEvaluation'
                }
                if(child.forUk){
                    textInfo.text = this.translate.instant('dashboard.deleted-processes-uk');
                    that.clientAdded = true;
                    this.configureListClients(child);
                }else{
                    that.deletedEvaluations.splice(0,0, evaluation);
                    this.getTask(child);
                }
                this.addFilterProduct(that.filterProducts, child, false);
                this.modalInfo(textInfo, child);
            }
            if (evaluation.productId === 'forms') {
                this.configureProductForm(child);
            }
            this.loader.closeLoader(['dashboard-panel-delete-evaluation']);

    }, (errorData:any)=>{
        this.loader.closeError()
        this.modalService.adviceModal(this.translate.instant('dashboard.error-delete-implantation')+"'"+evaluation.name+"'", 'error:accept:warning');
    });
  }

  deleteTaskSelected(task:any, child:any){
      task.conceptObjectId? this.getConcept(task, 'delete task', child) : this.registerServiceEmit(task, 'dashboard', 'delete task', child);
      this.apiService.delete('tasks', task.taskId)
          .then((data:any)=>{
              this.deleteTaskFromArray(task, child);
              this.loader.closeLoader(['dashboard-panel-delete-task']);
              this.modalService.adviceModal(this.translate.instant('dashboard.delete-task-ok'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError()
              this.modalService.adviceModal(this.translate.instant('dashboard.error-delete-task')+"'"+ task.title + "'", 'error:accept:warning');
          });
  }

  deleteTaskFromArray(task:any, child:any){
      child.dashboardPanel.evaluationList = this.commonUtilsService.deleteElement( task,  child.dashboardPanel.myTaksList, 'taskId')
      child.dashboardPanel.evaluationList = this.commonUtilsService.deleteElement( task,  child.dashboardPanel.tasksLists, 'taskId')
      this.changeTaskList(child.dashboardPanel.taskState, child);
  }

  deleteClientSelected(client: any, child:any){
      this.apiService.delete('clients', client.clientId)
          .then((data:any)=> {
              child.dashboardPanel.clientList = this.commonUtilsService.deleteElement(client,  child.dashboardPanel.clientList, 'clientId');
              let text = this.translate.instant('dashboard.client')+"'"+ client.name +"'"+ this.translate.instant('dashboard.delete-ok');
              if(child.forUk){
                  text = this.translate.instant('dashboard.deleted-client-uk');
                  let selectedClient = null;
                  if(child.dashboardPanel.clientList && child.dashboardPanel.clientList.length){
                      child.dashboardPanel.clientList[0].selectedClient = true;
                      selectedClient = child.dashboardPanel.clientList[0];
                  }
                  child.dashboardPanel.selectedClient = selectedClient;
              }else{
                  this.getTask(child);
              }
              this.addFilterClient(child.dashboardPanel.filterClients, child);
              this.getEvaluations(child);
              this.modalService.adviceModal(text, 'info:accept:check-round');
              this.loader.closeLoader(['dashboard-panel-delete-client']);
          }, (errorData:any)=>{
              this.loader.closeError()
              this.modalService.adviceModal(this.translate.instant('dashboard.error-delete-client')+'"'+client.name+'"', 'error:accept:warning');
          });
  }

  getConcept(task:any, type:string, child:any){
    this.apiService.get('conceptobjects', task.conceptObjectId)
    .then((data:any)=>{
      this.commonUtilsService.registerEvent(type, this.context.settings.defaultProduct, task.taskTypeId, data, 'task' )
    }, (errorData:any)=>{})
  }

  registerServiceEmit(task:any, concept:any, type:string, child:any){
      this.commonUtilsService.registerEvent(type, this.context.settings.defaultProduct, task.taskTypeId, concept, 'task' )
  }

  reloadAll(child:any){
    this.loader.openLoader('front-login-login');
    child.dashboardPanel.clientList = [];
    child.dashboardPanel.productList = [];
    child.dashboardPanel.tasksLists = [];
    child.dashboardPanel.myTaksList = [];
    setTimeout(()=>{
        this.getClients(child);
        this.getProducts(child);
        this.getTask(child);
        this.broadcastService.broadcast('reloadDashboard');
        if(child.noMenuProducts){
          child.finishAllCalls += 1
        }else{
          this.getKnowledgeImplantations(child)
        }
    })
  }

  deleteSelectReport(report:any, child:any){
      let that: any = child.dashboardPanel;
      this.apiService.delete('reportobjects', report.reportObjectId)
          .then((data:any)=>{
              that.filterReportsList = this.commonUtilsService.deleteElement( report, that.filterReportsList, 'reportObjectId');
              this.loader.closeLoader(['report-list-delete-report']);
              this.modalService.adviceModal(this.translate.instant('reports-list.delete-ok')+ "'"+report.title+"'", 'info:accept:check-round');
          }, (errorData:any)=>{})
  }

  checkOauth(){
    this.apiService.get('publicconceptcfg/check-oauth-reconfig')
      .then((data:any)=>{
        for(let item of data){
          if(item.companyId !== null){
            item.showIcon = false;
            item.title = this.translate.instant('advise-list-modal.global-custom');
            item.breadcrumb = this.translate.instant('advise-list-modal.all-channels-error');
          }else{
            item.showIcon = true;
            item.title = item.concept.title;
            item.breadcrumb = item.concept.breadcrumb;
          }
        }
        if(data.length){
          this.modalService.adviceListModal(this.translate.instant('advise-list-modal.review-pending-message'), 'error:accept:warning', data)
        }
      }, (errorData:any)=>{})
  }
}
