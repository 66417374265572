import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';

@Component({
  selector: 'customize-field-txchk',
  templateUrl: './customize-field-txchk.component.html'
})
export class CustomizeFieldTxchkComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldTxchk: any = {
    disabled: 0,
    tinyMceOptions: {},
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;

  @Output() changeInField= new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldTxchk
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      that.contentReportInfo.noEditable = that.field.noEditable;
      that.fieldCopy = cloneDeep(that.field);
    }
    if(this.concept?.poll && this.concept?.disabled){
      that.contentReportInfo.noEditable = this.concept.disabled;
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  itemSelected(e:any, item:any){
    this.customizeFieldTxchk.field[item] = e.newValue;
    this.modifyField();
  }

  getEditorValue(event:any){
    this.customizeFieldTxchk.field.description = event.model;
  }

  cancelFieldText(){
    this.customizeFieldTxchk.field = cloneDeep(this.customizeFieldTxchk.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldTxchk.field
        }
    })
  }

  saveFieldText(){
      if(this.customizeFieldTxchk.field.description && this.customizeFieldTxchk.field.description !==''){
          this.customizeFieldTxchk.field.error = false;
          this.customizeFieldTxchk.showError = false;
          this.customizeFieldTxchk.fieldCopy = cloneDeep(this.customizeFieldTxchk.field);
          this.changeInField.emit({
              change:{
                  type: 'save',
                  field: this.customizeFieldTxchk.field
              }
          })
      }else{
        this.customizeFieldTxchk.field.error = true;
        this.customizeFieldTxchk.showError = true;
      }

  }


  openVisibility(e:any){
    this.customizeFieldTxchk.field.visibilityOr = e.visibilityOr;
    this.customizeFieldTxchk.field.openBlock = e.openBlock;
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldTxchk.field);
  }
  changeOptionOn(e:any, type:string){
    this.customizeFieldTxchk.field[type] = e.model
    this.modifyField();
  }

}
