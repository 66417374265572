<div class="customize-field-list">
  <div [ngClass]="{'customize-fields__body-field-container': typeView === 'channelViewEditable'}">

    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldList.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': customizeFieldList.disabledFormConfiguration}"
        [(ngModel)]="customizeFieldList.field.extendedLabel"
        [disabled]="customizeFieldList.disabledFormConfiguration"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldList.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldList.tinyMceOptions"
      [modelText]="customizeFieldList.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text"
        *ngIf="!customizeFieldList.channelViewConfiguration">
        <field-type-list-simple [field]="customizeFieldList.contentReportInfo"
            [preSelectItem]="customizeFieldList.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldList.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldList.field.listVisibleValues"
            [selectedFields]="customizeFieldList.field.fieldVisibleValues"
            [orCondition]="customizeFieldList.field.visibilityOr"
            [readonly]="customizeFieldList.field.noEditable || customizeFieldList.disabledFormConfiguration"
            [itemId]="customizeFieldList.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text customize-field-list__block-list">
        <div class="customize-field-list__add-list-value">
            <div class="customize-field-list__list-value-input-block">
                <p class="customize-field-list__list-value-text">Valores de la lista</p>
                <span class="common__text-required">*</span>
            </div>
            <div class="customize-field-list__default-value-block  text-center">
                <p class="customize-field-list__list-value-text customize-field-list__list-value-text--right"
                [afTranslate]="'customize.default-value'">Valor por defecto</p>
            </div>
        </div>
        <ul class="customize-field-list__list-values-block"
            [ngShow]="customizeFieldList.field.listValues.length"
            [dragula]="'customizeFieldListValue'"
            [dragulaModel]="customizeFieldList.field.listValues"
            (dragulaModelChange)="customizeFieldList.field.listValues = $event">
            <li class="customize-field-list__add-list-value customize-field-list__add-list-value--list"
                *ngFor="let listValue of customizeFieldList.field.listValues; let index = index">
                <div class="customize-field-list__list-value-input-block">
                    <div class="customize-field-list__block-inputs"
                        [ngClass]="{'customize-field-list__block-inputs--hightlight': customizeFieldList.field.isHighlight}">
                        <div class="customize-field-list__list-value-input"
                            [ngClass]="{'common__show-alert':listValue.error,
                                'customize-field-list__list-input-disabled':(customizeFieldList.field.noEditable && listValue.listValueId) || customizeFieldList.disabledFormConfiguration,
                                'customize-field-list__list-value-input--weight': customizeFieldList.field.fieldTypeId === 'listp'}">
                            <div class="common__table-display"
                                [ngShow]="!listValue.edit">
                                <div class="common__input-text customize-field-list__input-text common__table-cell-tooltip">
                                    <div class="customize-field-list__cell-block">
                                      <span-tooltip class="customize-field-list__text-list"
                                        [ngClass]="{'customize-field-list__text-list--required-list': listValue.error}"
                                        [text]="listValue.text"></span-tooltip>
                                    </div>
                                </div>
                            </div>
                            <input type="text"
                                [id]="'customize-field-list-'+customizeFieldList.field.fieldId+'-list-value-'+index"
                                class="customize-field-list__input-value"
                                [ngShow]="listValue.edit"
                                [(ngModel)]="listValue.text"
                                [disabled]="!listValue.edit"
                                (keypress)="addDataKey($event, listValue)"
                                (blur)="checkValue(listValue)"
                                [maxLength]="customizeFieldList.maxlength">
                            <div class="customize-field-list__list-value-icons"
                                [ngShow]="!((customizeFieldList.field.noEditable && listValue.listValueId) || customizeFieldList.disabledFormConfiguration)">
                                <i class="lf-icon-arrows customize-field-list__input-icon-arrow handleFieldList"></i>
                                <i class="lf-icon-kebab-menu customize-field-list__input-icon-menu"></i>
                            </div>
                            <div class="customize-field-list__option-menu">
                                <i class="lf-icon-editable-form customize-field-list__input-icon"
                                    (click)="editListValue(listValue, index, 'value')"></i>
                                <i class="lf-icon-trash customize-field-list__input-icon"
                                    (click)="deleteListValue(listValue)"></i>
                            </div>
                        </div>
                        <div class="customize-field-list__list-weight-input"
                            [ngShow]="customizeFieldList.field.fieldTypeId === 'listp'"
                            [ngClass]="{'common__show-alert':listValue.weightError,
                                'customize-field-list__list-input-disabled':(customizeFieldList.field.noEditable && listValue.listValueId) || customizeFieldList.disabledFormConfiguration}">
                            <input type="text"
                                [id]="'customize-field-list-'+customizeFieldList.field.fieldId+'-list-weight-'+index"
                                class="customize-field-list__input-weight"
                                [(ngModel)]="listValue.weight"
                                (keypress)="addDataKey($event, listValue)"
                                (blur)="checkValue(listValue)"
                                validateNumber
                                [noDot]="true"
                                [disabled]="!listValue.editWeight">
                            <div class="customize-field-list__list-value-icons"
                                [ngShow]="!((customizeFieldList.field.noEditable && listValue.listValueId) || customizeFieldList.disabledFormConfiguration)">
                                <i class="lf-icon-kebab-menu customize-field-list__input-icon-menu"></i>
                            </div>
                            <div class="customize-field-list__option-menu">
                                <i class="lf-icon-editable-form customize-field-list__input-icon"
                                    (click)="editListValue(listValue, index, 'weight')"></i>
                            </div>
                        </div>
                    </div>
                    <div class="customize-field-list__block-highlight-button"
                        ngbDropdown
                        [autoClose]="'outside'">
                        <button class="customize-field-list__color-button"
                            [ngShow]="customizeFieldList.field.isHighlight"
                            [ngStyle]="{'background-color': listValue.color}"
                            ngbDropdownToggle>
                            <i class="lf-icon-step-final customize-field-list__circle-selected-button"></i>
                        </button>
                        <div class="customize-field-list__color-block"
                            ngbDropdownMenu role="menu">
                            <button class="customize-field-list__select-color-button"
                                *ngFor="let colourExist of customizeFieldList.listColors"
                                [ngStyle]="{'background-color': colourExist.color}"
                                (click)="selectColor(colourExist, listValue)">
                                <i class="lf-icon-step-final customize-field-list__circle-button"
                                    [ngClass]="{'customize-field-list__circle-button--selected': listValue.color === colourExist.color}"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="customize-field-list__default-value-block text-center">
                    <button class="customize-field-list__icon-button"
                        [disabled]="customizeFieldList.disabledFormConfiguration"
                        [ngClass]="{'lf-icon-check-full':listValue.isDefaultValue && customizeFieldList.field.multiple,
                            'lf-icon-box-inactive': !listValue.isDefaultValue && customizeFieldList.field.multiple,
                            'lf-icon-radio-button-active':listValue.isDefaultValue && !customizeFieldList.field.multiple,
                            'lf-icon-step-first': !listValue.isDefaultValue && !customizeFieldList.field.multiple,
                            'customize-field-list__icon-button--radio': !customizeFieldList.field.multiple}"
                        (click)="selectDefaultValue(listValue)">
                    </button>
                </div>
            </li>
        </ul>
        <div class="customize-field-list__add-list-value"
            *ngIf="!customizeFieldList.disabledFormConfiguration">
            <div class="customize-field-list__list-value-input-block">
                <div class="customize-field-list__block-inputs"
                    [ngClass]="{'customize-field-list__block-inputs--hightlight': customizeFieldList.field.isHighlight}">
                    <div class="customize-field-list__list-value-input"
                        [ngClass]="{'common__show-alert':customizeFieldList.listError,
                            'customize-field-list__list-input-disabled': customizeFieldList.disabledFormConfiguration,
                            'customize-field-list__list-value-input--weight': customizeFieldList.field.fieldTypeId === 'listp'}">
                        <input type="text"
                            [id]="'customize-field-list-new-list-value-'+customizeFieldList.field.fieldId"
                            class="customize-field-list__input-value"
                            [ngClass]="{'customize-field-formula__result-text--required-list': customizeFieldList.listError}"
                            [(ngModel)]="customizeFieldList.newListValue.text"
                            (keypress)="addDataKey($event, customizeFieldList.newListValue)"
                            (click)="resetText()"
                            [disabled]="customizeFieldList.disabledFormConfiguration"
                            [maxLength]="customizeFieldList.maxlength">
                        <div class="customize-field-list__list-value-icons"
                            [ngShow]="!customizeFieldList.disabledFormConfiguration">
                            <i class="lf-icon-kebab-menu customize-field-list__input-icon-menu"></i>
                        </div>
                    </div>
                    <div class="customize-field-list__list-weight-input"
                        [ngShow]="customizeFieldList.field.fieldTypeId === 'listp'"
                        [ngClass]="{'common__show-alert':customizeFieldList.newListValue.weightError,
                            'customize-field-list__list-input-disabled': customizeFieldList.disabledFormConfiguration}">
                        <input type="text"
                            [id]="'customize-field-list-new-weight-value-'+customizeFieldList.field.fieldId"
                            class="customize-field-list__input-weight"
                            [ngClass]="{'customize-field-formula__result-text--required-list': customizeFieldList.newListValue.weightError}"
                            [(ngModel)]="customizeFieldList.newListValue.weight"
                            (keypress)="addDataKey($event, customizeFieldList.newListValue)"
                            (click)="resetText()"
                            validateNumber
                            [noDot]="true"
                            [disabled]="customizeFieldList.disabledFormConfiguration">
                        <div class="customize-field-list__list-value-icons"
                            [ngShow]="!customizeFieldList.disabledFormConfiguration">
                            <i class="lf-icon-kebab-menu customize-field-list__input-icon-menu"></i>
                        </div>
                    </div>

                </div>
                <div class="customize-field-list__block-highlight-button"
                    ngbDropdown
                    [autoClose]="'outside'">
                    <button class="customize-field-list__color-button"
                        [ngShow]="customizeFieldList.field.isHighlight"
                        [ngStyle]="{'background-color': customizeFieldList.colorSelected.color}"
                        ngbDropdownToggle>
                        <i class="lf-icon-step-final customize-field-list__circle-selected-button"></i>
                    </button>
                    <div class="customize-field-list__color-block"
                        ngbDropdownMenu role="menu">
                        <button class="customize-field-list__select-color-button"
                            *ngFor="let colour of customizeFieldList.listColors"
                            [ngStyle]="{'background-color': colour.color}"
                            (click)="selectColor(colour)">
                            <i class="lf-icon-step-final customize-field-list__circle-button"
                                [ngClass]="{'customize-field-list__circle-button--selected': colour.color === customizeFieldList.colorSelected.color}"></i>
                        </button>
                    </div>
                </div>
                <button class="lf-icon-check common__button-checked"
                    [ngClass]="{'common__button-checked--30': skin === 'dark-blue',
                                'common__button-checked--32': skin !== 'dark-blue'}"
                    (click)="addNewListvalue(customizeFieldList.newListValue)"
                    [disabled]="customizeFieldList.disabledFormConfiguration"></button>
            </div>
            <div class="customize-field-list__default-value-block text-center">
                <button class="customize-field-list__icon-button"
                    [ngClass]="{'lf-icon-check-full':customizeFieldList.newListValue.isDefaultValue && customizeFieldList.field.multiple,
                        'lf-icon-box-inactive': !customizeFieldList.newListValue.isDefaultValue && customizeFieldList.field.multiple,
                        'lf-icon-radio-button-active':customizeFieldList.newListValue.isDefaultValue && !customizeFieldList.field.multiple,
                        'lf-icon-step-first': !customizeFieldList.newListValue.isDefaultValue && !customizeFieldList.field.multiple,
                        'customize-field-list__icon-button--radio': !customizeFieldList.field.multiple}"
                    (click)="selectDefaultValue(customizeFieldList.newListValue)"
                    [disabled]="customizeFieldList.disabledFormConfiguration">
                </button>
            </div>
        </div>
    </div>
    <div class="customize-field-list__switch-fields"
      [ngClass]="{'customize-field-list__switch-fields--no-error': !customizeFieldList.showError}">
      <switch-option
          [ngShow]="concept.allowPublicShare && typeView !== 'channelViewEditable'"
          [tooltipText]="'customize.private-help'"
          [model]="isPrivate?isPrivate:field.private"
          [disabled]="customizeFieldList.disabledFormConfiguration || isPrivate"
          [fieldText]="'customize.private'"
          (changeOptionEmit)="changeOptionOn($event, 'private')">
      </switch-option>
      <switch-option [ngHide]="concept.allowPublicShare"
          [tooltipText]="'customize.hidden-landing-help'"
          [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
          [disabled]="hiddenInLandings"
          [fieldText]="'customize.hidden-landing'"
          (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
      </switch-option>
      <switch-option
          [model]="customizeFieldList.field.extendedList"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.field.numericalScale || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.isOpen || customizeFieldList.field.isHighlight"
          [fieldText]="'customize.extend-list'"
          (changeOptionEmit)="changeOptionOn($event, 'extendedList')">
      </switch-option>
      <switch-option
          [model]="customizeFieldList.field.numericalScale"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.field.extendedList || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.multiple || customizeFieldList.field.isOpen || customizeFieldList.field.isHighlight"
          [fieldText]="'customize.numerical-scale'"
          (changeOptionEmit)="changeOptionOn($event, 'numericalScale')">
      </switch-option>
      <switch-option [ngShow]="customizeFieldList.field.fieldTypeId === 'list' && typeView !== 'channelViewEditable'"
          [model]="customizeFieldList.field.isOpen"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.field.isHighlight || customizeFieldList.field.extendedList || customizeFieldList.field.numericalScale || concept.poll || customizeFieldList.disabledFormConfiguration"
          [fieldText]="'customize.open'"
          (changeOptionEmit)="changeOptionOn($event, 'isOpen')">
      </switch-option>
      <switch-option
          [model]="customizeFieldList.field.multiple"
          [disabled]="customizeFieldList.field.isHighlight || customizeFieldList.field.numericalScale || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.isManagerClassificator"
          [fieldText]="'customize.multiple'"
          (changeOptionEmit)="changeOptionOn($event, 'multiple')">
      </switch-option>
      <switch-option
          [model]="customizeFieldList.field.required"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.isManagerClassificator"
          [fieldText]="'customize.required'"
          (changeOptionEmit)="changeOptionOn($event, 'required')">
      </switch-option>
      <switch-option [ngShow]="concept.poll && customizeFieldList.field.required"
          [model]="customizeFieldList.field.isDisaggregated"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.disabledFormConfiguration"
          [fieldText]="'customize-field-list.dissgregated-data'"
          (changeOptionEmit)="changeOptionOn($event, 'isDisaggregated')">
      </switch-option>
      <switch-option [ngHide]="concept.poll || customizeFieldList.field.fieldTypeId === 'listp' || typeView === 'channelViewEditable'"
          [model]="customizeFieldList.field.isManagerClassificator"
          [disabled]="customizeFieldList.field.noEditable || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.multiple"
          [fieldText]="'customize-field-list.manager-classificator'"
          (changeOptionEmit)="changeOptionOn($event, 'isManagerClassificator')">
      </switch-option>
      <switch-option
          [model]="customizeFieldList.field.isHighlight"
          [disabled]="customizeFieldList.field.extendedList || customizeFieldList.field.numericalScale || customizeFieldList.disabledFormConfiguration || customizeFieldList.field.isOpen || customizeFieldList.field.multiple"
          [fieldText]="'customize.highlight'"
          (changeOptionEmit)="changeOptionOn($event, 'isHighlight')">
      </switch-option>
    </div>

    <!-- Banda inferior de información -->
    <advice-message class="customize-field-list__error-block-advice"
        [ngShow]="customizeFieldList.showError"
        [icon]="'warning'"
        [type]="'error'"
        [text]="customizeFieldList.textError"
        (closeAdvice)="closeAdvice()">
    </advice-message>
  </div>
  <div class="common__buttons-block common__buttons-block--customize"
    *ngIf="typeView !== 'channelViewNoEditable'">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="cancelFieldText()"
          [disabled]="concept.disabled && typeView !== 'channelViewEditable'"
          [afTranslate]="'common.cancel'">Cancelar </button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="saveFieldText()"
          [disabled]="concept.disabled"
          [afTranslate]="'common.save'">Guardar </button>
  </div>
</div>
