import { Component, Input} from '@angular/core';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';


@Component({
  selector: 'span-tooltip',
  templateUrl: './span-tooltip.component.html'
})

export class SpanTooltipComponent{
  skin:string = this.globalCfg.skin;
  id:string = this.commonUtilsService.createId();


  constructor(private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory) { }

  @Input() text:string = '';
  @Input() placement:any = 'below';
  @Input() isDeleted:boolean = false;

  showTooltip():boolean{
    if(this.isDeleted){
      return true;
    }
    return this.commonUtilsService.showTooltip(this.id);
  }

}
