<div class="add-assignment">
  <div class="add-assignment__block-context">
      <div class="common__modal-header-block">
          <i class="lf-icon-import-doc common__icon-header"></i>
          <span class="common__text-header" [afTranslate]="'add-assignment.add-concept-field'"></span>
          <i class="lf-icon-close duplicate-documentation__icon-close-modal" (click)="handleDismiss()"></i>
      </div>
      <div class="add-assignment__block-body">
          <!-- Pantalla de buscador -->
          <div class="duplicate-documentation__block-search-init" *ngIf="addAssignmentModal.stepShow === 0">
              <p class="duplicate-documentation__text-search" [afTranslate]="'add-assignment.text-init-1'"></p>
              <div class="duplicate-documentation__block-input-search">
                  <input class="header-nav__search-input header-nav__search-input--duplicate"
                      [ngClass]="{'header-nav__search-input--with-text': addAssignmentModal.searchText}"
                      [(ngModel)]="addAssignmentModal.searchText"
                      (keypress)="goToPressEnter($event)"
                      [placeholder]="'common.search' | afTranslate">
                  <button class="header-nav__icon-button header-nav__icon-button--duplicate" (click)="goToSearch()">
                      <i class="lf-icon-search header-nav__icon-search"></i>
                  </button>
              </div>
              <p class="duplicate-documentation__text-search"
                [afTranslate]="'add-assignment.text-init-2'"></p>
              <button class="common__button-border-none" (click)="selectProduct()">
                  <i class="lf-icon-review common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined" [afTranslate]="'duplicate-documentation.start-scan'"></span>
              </button>
          </div>
          <!-- Pantalla con resultados del buscador -->
          <div class="duplicate-documentation__block-search-result" *ngIf="addAssignmentModal.stepShow === 1">
              <p class="duplicate-documentation__text-search-header" [afTranslate]="'result-search.search-result'"></p>
              <div class="duplicate-documentation__block-table-results">
                  <div class="duplicate-documentation__block-input-search-step-1">
                      <input class="header-nav__search-input header-nav__search-input--duplicate"
                          [ngClass]="{'header-nav__search-input--with-text': addAssignmentModal.searchText}"
                          [(ngModel)]="addAssignmentModal.searchText"
                          (keypress)="goToPressEnter($event)"
                          [placeholder]="'common.search' | afTranslate">
                      <button class="header-nav__icon-button header-nav__icon-button--duplicate" (click)="goToSearch()">
                          <i class="header-nav__icon-search"
                              [ngClass]="addAssignmentModal.searchText !== '' && addAssignmentModal.copySearchText === addAssignmentModal.searchText ? 'lf-icon-close-search' : 'lf-icon-search'"></i>
                      </button>
                  </div>
                  <div class="duplicate-documentation__table-container">
                      <!-- Cabecera -->
                      <div class="common__table-header">
                          <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                              <label for="multiple-selection-all-select-assignment" class="documentation-list__label-multiple">
                                  <input class="documentation-list__input-multiple" type="checkbox" id="multiple-selection-all-select-assignment"
                                      (click)="selectAllDocumentCheck()"
                                      [checked]="addAssignmentModal.selectAllDocument">
                                  <i class="documentation-list__icon-multiple"
                                      [ngClass]="addAssignmentModal.selectAllDocument ? 'lf-icon-check-full' : 'lf-icon-box-inactive'"></i>
                              </label>
                          </div>
                          <div class="duplicate-documentation__table add-assignment__table-header--field-name">
                              <div ngbDropdown [open]="addAssignmentModal.openFocusName">
                                      <p ngbDropdownToggle class="common__table-header-text common__cursor-pointer"
                                          [afTranslate]="'common.field-name'"></p>
                                      <i ngbDropdownToggle class="common__filter-icon lf-icon-filter-1"
                                          [ngClass]="{'lf-icon-filter-applied duplicate-documentation__filter-icon--active': addAssignmentModal.fieldNameValue.length}"></i>
                                      <i class="lf-icon-arrow-down common__order-by"
                                         [ngClass]="{'lf-icon-arrow-up': addAssignmentModal.dateOrder === 'fieldName' && !addAssignmentModal.orderOperationDates,
                                            'common__order-by--active': addAssignmentModal.dateOrder === 'fieldName'}"
                                          (click)="orderDate('fieldName')"></i>
                                  <ul ngbDropdownMenu class="dropdown-menu common__select-list-with-input add-assignment__select-list-with-input" role="menu">
                                      <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                          <input class="common__dropdown-inner-input"
                                                 (keyup)="filterField('fieldName')"
                                                 [placeholder]="'common.search-name'|afTranslate"
                                                 [(ngModel)]="addAssignmentModal.fieldNameValue"
                                                 [focusWhen]="addAssignmentModal.openFocusName">
                                          <i class="common__search-icon common__search-icon--reset"
                                            [ngClass]="addAssignmentModal.fieldNameValue === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                                            (click)="resetFilter($event, 'fieldName')"></i>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div class="duplicate-documentation__table add-assignment__table-header--localization">
                              <div ngbDropdown [open]="addAssignmentModal.openFocusLocalization">
                                  <div ngbDropdownToggle>
                                      <p class="common__table-header-text common__cursor-pointer" [afTranslate]="'common.localization'"></p>
                                      <i class="common__filter-icon lf-icon-filter-1"
                                         [ngClass]="{'lf-icon-filter-applied duplicate-documentation__filter-icon--active': addAssignmentModal.conceptNameValue.length}"></i>
                                  </div>
                                  <ul ngbDropdownMenu class="dropdown-menu common__select-list-with-input add-assignment__select-list-with-input" role="menu">
                                      <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                          <input class="common__dropdown-inner-input"
                                                 (keyup)="filterField('conceptName')"
                                                 [placeholder]="'common.search-localization'|afTranslate"
                                                 [(ngModel)]="addAssignmentModal.conceptNameValue"
                                                 [focusWhen]="addAssignmentModal.openFocusLocalization">
                                          <i class="common__search-icon common__search-icon--reset"
                                            [ngClass]="addAssignmentModal.conceptNameValue === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                                            (click)="resetFilter($event, 'conceptName')"></i>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- Contenido -->
                      <div class="duplicate-documentation__table-body"
                          [ngClass]="{'duplicate-documentation__table-body--more-elements' : addAssignmentModal.filterFieldList.length >= (addAssignmentModal.configPagination.pageSize * addAssignmentModal.currentPage) - 3}">
                          <img [src]="addAssignmentModal.loaderImg" class="duplicate-documentation__spinner-loader"
                              [ngShow]="addAssignmentModal.searchingFields"
                              alt="Loader">
                          <div class="duplicate-documentation__table-row"
                              [attr.disabled]="(!field.editable || field.previousSelected)?true:null"
                              [ngClass]="{'duplicate-documentation__table-row--selected': field.selectedField}"
                              [ngShow]="!addAssignmentModal.searchingFields"
                              (click)="selectMultipleField(field)"
                              *ngFor="let field of addAssignmentModal.filterFieldList | afOrderBy:addAssignmentModal.dateOrder:addAssignmentModal.orderOperationDates | afLimitTo:addAssignmentModal.configPagination.pageSize:addAssignmentModal.configPagination.pageSize*(addAssignmentModal.currentPage - 1); let $index = index">
                              <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                  <label for="multiple-selection-duplicate-{{$index}}-select" class="documentation-list__label-multiple">
                                      <input class="documentation-list__input-multiple"
                                          type="checkbox"
                                          id="multiple-selection-duplicate-{{$index}}-select"
                                          (click)="selectMultipleField(field)"
                                          [checked]="field.selectedField"
                                          [disabled]="!field.editable || field.previousSelected">
                                      <i class="documentation-list__icon-multiple"
                                         [ngClass]="{'lf-icon-check-full': field.selectedField,
                                            'lf-icon-box-inactive': !field.selectedField,
                                            'documentation-list__icon-multiple--disabled': !field.editable || field.previousSelected,
                                            'documentation-list__icon-multiple--error': field.invalidateSelection}"></i>
                                  </label>
                              </div>
                              <div class="duplicate-documentation__table add-assignment__table--field-name">
                                <span-tooltip [text]="field.fieldName"
                                  class="common__col-text"
                                  [ngClass]="{'common__col-text--disabled': !field.editable || field.previousSelected}"></span-tooltip>
                              </div>
                              <div class="duplicate-documentation__table add-assignment__table--localization-name">
                                  <span-tooltip [text]="field.conceptName"
                                    class="common__col-text"
                                    [ngClass]="{'common__col-text--disabled': !field.editable || field.previousSelected}"></span-tooltip>
                              </div>
                              <div class="duplicate-documentation__table add-assignment__table--icon-noeditable">
                                    <i class="lf-icon-arrow-exchange add-assignment__icon-exange-noeditable"
                                      [ngShow]="!field.editable || field.previousSelected"
                                      matTooltip="{{(field.previousSelected ? 'add-assignment.report-this-field-assigned' : 'add-assignment.report-field-assigned') | afTranslate}} {{field.previousSelected ? '' : field.assignedFieldName}}"
                                      matTooltipPosition="left"
                                      matTooltipClass="left">
                                    </i>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- Paginación -->
                  <div class="duplicate-documentation__pagination">
                      <pagination-control [ngShow]="addAssignmentModal.filterFieldList.length > addAssignmentModal.configPagination.pageSize && !addAssignmentModal.searchingFields"
                        [config]="addAssignmentModal.configPagination"
                        [collectionSize]="addAssignmentModal.filterFieldList.length"
                        [(currentPage)]="addAssignmentModal.currentPage"></pagination-control>
                      <div class="duplicate-documentation__count-documentation">
                          <span [afTranslate]="'common.total'"></span>
                          <span>{{addAssignmentModal.filterFieldList.length}}</span>
                      </div>
                  </div>
              </div>
              <!-- Banda inferior de información -->
              <advice-message [ngShow]="addAssignmentModal.showInfoMessage && addAssignmentModal.stepShow !== 0 && !addAssignmentModal.errorFields"
                  icon="information"
                  type="info"
                  [text]="addAssignmentModal.infoMessage"
                  (closeAdvice)="closeAdvice('showInfoMessage')">
              </advice-message>
              <advice-message [ngShow]="addAssignmentModal.showInfoMessage && addAssignmentModal.stepShow !== 0 && addAssignmentModal.errorFields"
                  icon="warning"
                  type="error"
                  [text]="addAssignmentModal.infoErrorMessage"
                  (closeAdvice)="closeAdvice('showInfoMessage')">
              </advice-message>
          </div>
          <!-- Pantalla de exploración de la evaluación con migas de pan-->
          <div class="duplicate-documentation__block-search-result" *ngIf="addAssignmentModal.stepShow === 2 || addAssignmentModal.stepShow === 3">
              <p class="duplicate-documentation__text-search-header"
                  *ngIf="addAssignmentModal.stepShow === 2"
                  [afTranslate]="'add-assignment.explore-folders-concepts'"></p>
              <p class="duplicate-documentation__text-search-header"
                  *ngIf="addAssignmentModal.stepShow === 3"
                  [afTranslate]="'add-assignment.select-fields-concept'"></p>
              <div class="duplicate-documentation__block-table-results">
                  <!-- Migas de pan -->
                  <div class="duplicate-documentation__block-breadcrumbs">
                      <i class="lf-icon-map-marker duplicate-documentation__icon-map"></i>
                      <div class="duplicate-documentation__block-breadcrumbs-text">
                          <div class="duplicate-documentation__title-breadcrumb duplicate-documentation__title-breadcrumb--title"
                              (click)="selectFolderFromBreadcrumb(null, 'menu')">
                              <span class="duplicate-documentation__text-breadcrumb"
                                  [ngClass]="addAssignmentModal.breadcrumbList.length && skin ==='steel-blue' ? 'duplicate-documentation__title-breadcrumb--no-cursor' : 'duplicate-documentation__underlined-text'">{{addAssignmentModal.nameProduct}}</span>
                          </div>
                          <button *ngFor="let breadcrumb of addAssignmentModal.breadcrumbList; let $last = last, let $index = index"
                              class="duplicate-documentation__title-breadcrumb"
                              (click)="selectFolderFromBreadcrumb(breadcrumb, 'breadcrumb')"
                              [ngClass]="{'duplicate-documentation__title-breadcrumb--no-cursor': $last}"
                              [disabled]="$last">
                              <div class="common__cell-block">
                                  <span class="duplicate-documentation__symbol-minus"> > </span>
                                  <span-tooltip class="duplicate-documentation__text-breadcrumb"
                                      [ngClass]="{'duplicate-documentation__underlined-text': !$last && skin !== 'steel-blue' && skin !== 'uk-steel-blue',
                                          'duplicate-documentation__last':$last}"
                                      [text]="breadcrumb.nameBreadcrumb"></span-tooltip>
                              </div>
                          </button>
                          <span id="finalBreadcrumbs"></span>
                      </div>
                  </div>
                  <div class="duplicate-documentation__table-container"
                      [ngClass]="{'duplicate-documentation__table-container--evaluation': addAssignmentModal.stepShow === 2}">
                      <!-- Contenido carpetas-->
                      <div class="duplicate-documentation__table-body"
                          *ngIf="addAssignmentModal.stepShow === 2"
                          [ngClass]="{'duplicate-documentation__table-body--more-elements': addAssignmentModal.filterProductTreeList.length > 7}">
                          <img [src]="addAssignmentModal.loaderImg"
                              class="duplicate-documentation__spinner-loader"
                              [ngShow]="addAssignmentModal.searchingTreeEvaluation"
                              alt="Loader">
                          <div class="duplicate-documentation__table-row"
                              [ngShow]="!addAssignmentModal.searchingTreeEvaluation"
                              *ngFor="let item of addAssignmentModal.filterProductTreeList"
                              [attr.disabled]="item.previousSelected?true:null"
                              (click)="selectFolder(item)">
                              <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
                                  <i class="lf-icon-folder duplicate-documentation__icon-product-scan"
                                      [ngClass]="{'lf-icon-folder':item.folderId, 'lf-icon-template':item.conceptId}"></i>
                              </div>
                              <div class="duplicate-documentation__table duplicate-documentation__table--implantation-explore">
                                  <div class="duplicate-documentation__cell-block">
                                      <span-tooltip *ngIf="item.folderId" [text]="item.name"></span-tooltip>
                                      <span-tooltip *ngIf="item.conceptId" [text]="item.title"></span-tooltip>
                                  </div>
                                  <span class="duplicate-documentation__text-explore"
                                      [ngShow]="!item.previousSelected"
                                      [afTranslate]="'duplicate-documentation.explore'"></span>
                                  <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right" [ngShow]="!item.previousSelected"></i>
                                  <i class="lf-icon-arrow-exchange add-assignment__icon-exange-noeditable" [ngShow]="item.conceptId && item.previousSelected"
                                    matTooltip="{{'add-assignment.report-this-field-assigned' | afTranslate}}"
                                    matTooltipPosition="left"
                                    matTooltipClass="left">
                                  </i>
                              </div>
                          </div>
                      </div>
                      <!-- Tabla campos del concepto -->
                      <!-- Cabecera -->
                      <div class="common__table-header"
                          *ngIf="addAssignmentModal.stepShow === 3">
                          <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection"></div>
                          <div class="duplicate-documentation__table duplicate-documentation__table--format">
                              <filter-by-list type="list"
                                  [item]="addAssignmentModal.fieldTypeIdFilter"
                                  [list]="addAssignmentModal.fieldTypeIdList"
                                  [topStyle]="'21'"
                                  (filterElementEmit)="selectTypeOperation($event, 'fieldTypeId')">
                              </filter-by-list>
                          </div>
                          <div class="duplicate-documentation__table add-assignment__table-header--field-name-tree" >
                              <div ngbDropdown [open]="addAssignmentModal.openFocusFieldName">
                                  <div ngbDropdownToggle>
                                      <p class="common__table-header-text common__cursor-pointer" [afTranslate]="'common.field-name'"></p>
                                      <i class="common__filter-icon lf-icon-filter-1"
                                          [ngClass]="{'lf-icon-filter-applied duplicate-documentation__filter-icon--active': addAssignmentModal.fieldNameValue.length}"></i>
                                  </div>
                                  <ul ngbDropdownMenu class="dropdown-menu common__select-list-with-input add-assignment__select-list-with-input" role="menu">
                                      <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                          <input class="common__dropdown-inner-input"
                                              (keyup)="filterField('fieldName')"
                                              [placeholder]="'common.search-name'|afTranslate"
                                              [(ngModel)]="addAssignmentModal.fieldNameValue"
                                              [focusWhen]="addAssignmentModal.openFocusFieldName">
                                          <i class="common__search-icon common__search-icon--reset"
                                            [ngClass]="addAssignmentModal.fieldNameValue === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                                            (click)="resetFilter($event, 'fieldName')"></i>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- Contenido -->
                      <div class="duplicate-documentation__table-body"
                          *ngIf="addAssignmentModal.stepShow === 3"
                          [ngClass]="{'duplicate-documentation__table-body--more-elements': addAssignmentModal.filterConceptList?.length >= (addAssignmentModal.configPagination.pageSize*addAssignmentModal.currentPage)-3}">
                          <img [src]="addAssignmentModal.loaderImg"
                              class="duplicate-documentation__spinner-loader"
                              [ngShow]="addAssignmentModal.searchingFields"
                              alt="Loader">
                          <div class="duplicate-documentation__table-row"
                              [attr.disabled]="!field.editable?true:null"
                              [ngClass]="{'duplicate-documentation__table-row--selected': field.selectedField}"
                              [ngShow]="!addAssignmentModal.searchingFields"
                              (click)="selectSimpleField(field)"
                              *ngFor="let field of addAssignmentModal.filterConceptList | afLimitTo:addAssignmentModal.configPagination.pageSize:addAssignmentModal.configPagination.pageSize*(addAssignmentModal.currentPage - 1); let $index = index">
                              <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                  <label [for]="'multiple-selection-duplicate-'+field.fieldId+'-select'"
                                      class="documentation-list__label-multiple"
                                      [ngShow]="!field.folderId">
                                      <input class="documentation-list__input-multiple"
                                          type="radio"
                                          name="field-simple-select"
                                          [value]="field.fieldId"
                                          id="'multiple-selection-duplicate-'+field.fieldId+'-select'"
                                          [value]="field.selectedField"
                                          (change)="selectSimpleField(field)"
                                          [disabled]="!field.editable">
                                      <i class="documentation-list__icon-multiple"
                                          [ngClass]="{
                                            'lf-icon-radio-button-active': field.selectedField,
                                            'lf-icon-step-first': !field.selectedField,
                                            'documentation-list__icon-multiple--disabled': !field.editable
                                          }"></i>
                                  </label>
                              </div>
                              <div class="duplicate-documentation__table duplicate-documentation__table--format">
                                  <i class="{{field.fieldTypeId | fieldTypeIcons}} duplicate-documentation__table-icon"
                                      [ngClass]="{'common__col-text--disabled': !field.editable}"></i>
                              </div>
                              <div class="duplicate-documentation__table add-assignment__table--field-name-tree">
                                  <parragraph-tooltip [text]="field.fieldName"></parragraph-tooltip>
                              </div>
                              <div class="duplicate-documentation__table add-assignment__table--icon-noeditable">
                                  <i class="lf-icon-arrow-exchange add-assignment__icon-exange-noeditable"
                                      [ngShow]="!field.editable"
                                      matTooltip="{{('add-assignment.report-field-assigned' | afTranslate) + ' ' + field.previousSelected?'':field.assignedFieldName}}"
                                      matTooltipPosition="left"
                                      matTooltipClass="left">
                                  </i>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- Mensaje de que no hay elementos que mostrar -->
                  <p class="duplicate-documentation__text-search-header duplicate-documentation__text-search-header--no-elements"
                      *ngIf="addAssignmentModal.noElements"
                      [afTranslate]="'duplicate-documentation.not-found-elements'">
                  </p>
                   <!-- Paginación -->
                  <div class="duplicate-documentation__pagination"
                      [ngShow]="!addAssignmentModal.searchingTreeEvaluation">
                      <pagination-control [ngShow]="addAssignmentModal.totalItemsPagination > addAssignmentModal.configPagination.pageSize && !addAssignmentModal.searchingFields"
                        [config]="addAssignmentModal.configPagination"
                        [collectionSize]="addAssignmentModal.totalItemsPagination"
                        [(currentPage)]="addAssignmentModal.currentPage "></pagination-control>
                      <div class="duplicate-documentation__count-documentation">
                        <span [afTranslate]="'common.total'"></span>
                        <span>{{addAssignmentModal.totalItemsPagination}}</span>
                      </div>
                  </div>
              </div>
              <!-- Banda inferior de información -->
              <advice-message [ngShow]="addAssignmentModal.showInfoMessage && addAssignmentModal.stepShow === 3"
                  icon="information"
                  type="info"
                  [text]="addAssignmentModal.infoMessage"
                  (closeAdvice)="closeAdvice('showInfoMessage')">
              </advice-message>
          </div>
          <div class="common__buttons-block common__buttons-block--modal" *ngIf="addAssignmentModal.stepShow !== 0">
              <button type="reset" class="common__button common__button--clean"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
              <button type="reset" class="common__button common__button--clean"
                  (click)="backInit()"
                  [afTranslate]="'duplicate-documentation.back'"></button>
              <button type="submit" class="common__button common__button--active"
                  (click)="assignFields()"
                  [disabled]="addAssignmentModal.documentChecked === 0"
                  [afTranslate]="'add-assignment.assign'"></button>
          </div>
      </div>
  </div>
</div>
