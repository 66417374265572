<div *ngFor="let listValue of treeListSimple.filterList | byWord:treeListSimple.findDropdown:treeListSimple.fieldToFind"
    class="field-type-list__level">
    <div class="common__select-item common__ellipsis"
        [ngClass]="{'common__select-item--tree':treeListSimple.field.isTree,
          'common__select-item--tree-with-children':  treeListSimple.field.isTree && listValue.children?.length}"
        [ngHide]="listValue.deleteError"
        matTooltip="{{listValue.text}}"
        matTooltipPosition="above"
        matTooltipClass="above"
        [matTooltipDisabled]="listValue.ellipsis"
        (click)="launchAction(listValue, $event, 'selectItem')">
        <i [ngShow]="listValue.children.length"
            [ngClass]="{'lf-icon-angle-down':listValue.show , 'lf-icon-angle-right':!listValue.show}"
            (click)="launchAction(listValue, $event, 'showChildren')"></i>
        <i [class]="(listValue.type | taskTypeIcons:'reference') + ' common__select-info-icon'"></i>
        <!-- lfb-{{listValue.type}} -->
        <i class="field-item__icon-check"
          [ngShow]="!listValue.children.length && treeListSimple.field.isTree"
          [ngClass]="treeListSimple.field?.evaluatedValue?.floatvalue === listValue.listValueId?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
        {{listValue.text}}
        <i class="lf-icon-check-round-full common__select-icon common__select-icon--select"
            [ngClass]="{'common__select-icon--select': !listValue.isUserValue}"></i>
        <i class="lf-icon-edit common__select-icon"
            [ngShow]="listValue.isUserValue"
            (click)="launchAction(listValue, $event, 'editListValue')"></i>
        <i class="lf-icon-trash common__select-icon"
            [ngShow]="listValue.isUserValue"
            (click)="launchAction(listValue, $event, 'deleteListValue')"></i>
    </div>
    <div class="common__select-item"
        [ngShow]="listValue.deleteError"
        [ngClass]="{'common__select-item--error':listValue.deleteError}">
        <i class="lf-icon-lock common__select-info-icon"
            [ngShow]="listValue.deleteError"></i>
            No es posible eliminar este valor porque ya se ha utilizado en otros formularios.
        <button class="common__error-button-list"
            [ngShow]="listValue.deleteError"
            (click)="launchAction(listValue, $event, 'aceptNotDelete')">
            Aceptar
        </button>
    </div>
    <div class="field-type-list__tree-list"
        *ngIf="listValue.children.length && listValue.show">
        <tree-list-simple [field]="treeListSimple.field"
          [findDropdown]="treeListSimple.findDropdown"
          [fieldToFind]="treeListSimple.fieldToFind"
          [filterList]="listValue.children"
          (treeSimpleEmit)="treeSimpleOn($event)">
        </tree-list-simple>
      </div>
</div>
