<div class="dashboard-roles-obligated-subject"
    [ngShow]="dashboardRolesObligatedSubject.callsCharged === 2">
    <div class="dashboard-roles-obligated-subject__block-line">
        <div class="dashboard-roles-obligated-subject__subblock-line"
            [ngShow]="dashboardRolesObligatedSubject.productId === 'blanqueo'">
            <i class="lf-icon-step-final dashboard-roles-obligated-subject__icon-risk"
                [ngStyle]="{'color': dashboardRolesObligatedSubject.obligatedSubject.riskColor}"></i>
            <span class="dashboard-roles-obligated-subject__block-text">
                <span class="dashboard-roles-obligated-subject__title-text span__auto-ajust"
                    [afTranslate]="'dashboard-roles-obligated-subject.office-evaluation'">_Evaluación de la oficina</span>:
                <span class="dashboard-roles-obligated-subject__subtitle-text"
                    [ngShow]="dashboardRolesObligatedSubject.obligatedSubject.risk">{{dashboardRolesObligatedSubject.obligatedSubject.risk}}</span>
            </span>

        </div>
        <div class="dashboard-roles-obligated-subject__subblock-line span__auto-ajust"
            *ngIf="dashboardRolesObligatedSubject.productId === 'blanqueo' && skin !== 'steel-blue' && dashboardRolesObligatedSubject.obligatedSubject.reviewDate">
            <i class="lf-icon-notification dashboard-roles-obligated-subject__icon-risk"></i>
            <span class="dashboard-roles-obligated-subject__block-text">
                <span class="dashboard-roles-obligated-subject__title-text span__auto-ajust"
                    [afTranslate]="'dashboard-roles-obligated-subject.revision'">_Revisión</span>:
                <span class="dashboard-roles-obligated-subject__subtitle-text"
                    [ngShow]="dashboardRolesObligatedSubject.obligatedSubject.risk">{{dashboardRolesObligatedSubject.obligatedSubject.reviewDate | convertDate:'without-time'}}</span>
            </span>
        </div>

        <div class="dashboard-roles-obligated-subject__subblock-line-right">
            <button class="dashboard-roles-obligated-subject__button-edit"
                (click)="goTo('obligated-subject')"
                [ngShow]="dashboardRolesObligatedSubject.productId === 'blanqueo'">
                <span class="dashboard-roles-obligated-subject__text-button"
                    [afTranslate]="'common.obligated-subject'">_Sujeto obligado</span>
                <i class="lf-icon-editable-form dashboard-roles-obligated-subject__icon-button span__auto-ajust"></i>
            </button>
            <button class="dashboard-roles-obligated-subject__button-edit span__auto-ajust"
                (click)="goTo('roles')">
                <span class="dashboard-roles-obligated-subject__text-button"
                    [afTranslate]="'common.roles-edit'">_Editar roles</span>
                <i class="lf-icon-editable-form dashboard-roles-obligated-subject__icon-button span__auto-ajust"></i>
            </button>
        </div>
    </div>
    <div class="dashboard-roles-obligated-subject__block-line">
        <div class="dashboard-roles-obligated-subject__subblock-line"
            *ngFor="let role of dashboardRolesObligatedSubject.roles">
            <i class="lf-icon-lead dashboard-roles-obligated-subject__icon-risk"></i>
            <span class="dashboard-roles-obligated-subject__block-text">
                <span class="dashboard-roles-obligated-subject__title-text span__auto-ajust">{{role.name}}:</span>
                <span class="dashboard-roles-obligated-subject__subtitle-text span__auto-ajust">{{role.usersList[0]}}</span>
                <span class="dashboard-roles-obligated-subject__bubble-count span__auto-ajust"
                    [ngShow]="role.usersList.length > 1"
                    matTooltip="{{role.usersList.join(', ')}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                    +{{role.usersList.length - 1}}
                  </span>
                <span class="dashboard-roles-obligated-subject__subtitle-text"
                    [ngShow]="!role.usersList.length"
                    [afTranslate]="'dashboard-roles-obligated-subject.not-assigned'">_Sin asignar</span>
            </span>
        </div>
    </div>
</div>
