import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { AdviceModalParentService } from '../utils/advice-modal-parent.service';

@Component({
  selector: 'advice-modal',
  templateUrl: './advice-modal.component.html'
})
export class AdviceModalComponent implements OnInit {
  constructor(
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private adviceModalParent: AdviceModalParentService) { }

  @Input() from:string = '';
  @Input() adviceType:string = '';
  @Input() questionText:string = '';
  @Input() list:any = [];
  @Input() expand: boolean = false;

  modalMoreInfoSubject:any;
  isLanding: any = false;
  adviceModal:any ={
    confirmQuestionIcon: 'lf-icon-question',
    errorTranslate: '',
    typeModal: '',
    modalQuestion: '',
    typeText: 'normal',
    firstQuestionText: '',
    buttonLink: '',
    lastQuestionText: '',
    list: []
  }

  ngOnInit(): void {
    this.adviceModal.errorTranslate = this.translate.instant('users-admin.import.error-'+this.from)
    this.adviceModalParent.configureModal(this);
  }

  openInfoModal(){
    this.adviceModalParent.openInfoModal(this);
  }

  handleAccept(optionSelect:string='ok') {
    this.activeModal.close({result: optionSelect})
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
