<div class="evaluation-menu">
  <ul class="evaluation-menu__list-block">
      <li class="evaluation-menu__list-item evaluation-menu__list-item--home">
          <div class="evaluation-menu__list-item-block-menu" >
              <button class="evaluation-menu__list-item-button  evaluation-menu__list-item-button--burger"
                  (click)="expandOrCloseMenu()">
                  <i class="lf-icon-hamburger-menu evaluation-menu__list-item-icon evaluation-menu__list-item-icon--home"></i>
              </button>
              <div class="evaluation-menu__parent-box evaluation-menu__parent-box--home"
                  [ngShow]="skin !== 'uk-steel-blue'">
                  <p class="evaluation-menu__list-item-text"
                      [ngShow]="evaluationMenu.openFloatMenu">{{evaluationInfo.productName}}</p>
                      <button [ngShow]="evaluationInfo.productId === 'bpt' && fullMenu"
                          class="lf-icon-une evaluation-menu__icon-une"
                          matTooltip="UNE_19602_2019"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          (click)="evaluationMenu.openLinkUNE()"
                          target="_blank">
                      </button>
              </div>
              <button class="evaluation-menu__hide-no-concept"
                  [ngClass]="{'evaluation-menu__hide-no-concept--active':evaluationMenu.hideWithoutProccesses,
                        'evaluation-menu__hide-no-concept--close-menu': !evaluationMenu.openFloatMenu}"
                  matTooltip="{{'evaluation-menu.show-hide-proccesses' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above"
                  (click)="evaluationMenu.hideWithoutProccesses = !evaluationMenu.hideWithoutProccesses"
                  [ngShow]="skin === 'uk-steel-blue'">
                  <i class="lf-icon-not-visible evaluation-menu__hide-no-concept-icon"
                    [ngClass]="{'evaluation-menu__hide-no-concept-icon--close-menu': !evaluationMenu.openFloatMenu}"></i>
              </button>
          </div>
      </li>
      <li class="evaluation-menu__list-item"
          *ngFor="let folder of evaluationTree.children; let index = index; let last = last"
          [ngHide]="(showHidden === false && folder.hidden)
                      || (folder.folderId === 'reports' && !evaluationMenu.showReportsFolder)
                      || (evaluationMenu.hideWithoutProccesses && skin ==='uk-steel-blue' && !folder.totalInstances)"
          matTooltip="{{folder.name.toUpperCase()}}"
          matTooltipPosition="right"
          matTooltipClass="right"
          [matTooltipDisabled]="fullMenu">
          <div class="evaluation-menu__list-item-block"
              [ngClass]="{'evaluation-menu__list-item-block--active-short': !evaluationMenu.openFloatMenu && folder.active,
                          'evaluation-menu__list-item-block--active': folder.active,
                          'evaluation-menu__list-item-block--disabled': folder.disabledFolder,
                          'evaluation-menu__list-item-block--no-clickable': (evaluationMenu.stateFolder || evaluationMenu.folderAutoinstanciable) && evaluationMenu.folderSelect.folderId === folder.folderId}">
              <button class="evaluation-menu__list-item-button  evaluation-menu__list-item-button--tree"
                  (click)="launchAction('open', folder)">
                  <i class="evaluation-menu__list-item-icon evaluation-menu__list-item-icon--other"
                      [ngClass]="folder.hidden?'evaluation-menu__list-item-icon--invisible lf-icon-not-visible':folder.icon"></i>
                      <div [ngShow]="folder.confidential"
                        class="evaluation-menu__block-confident-folder"
                        ngbDropdown
                        [id]="'evaluation-menu-confidential-'+folder.folderId"
                        [autoClose]="'outside'">
                      <button class="evaluation-menu__confident-folder evaluation-menu__confident-folder"
                          [ngClass]="{'evaluation-menu__confident-folder--active-short': !evaluationMenu.openFloatMenu && folder.active,
                                  'evaluation-menu__confident-folder--hidden': folder.hidden}"
                          ngbDropdownToggle
                          matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [matTooltipDisabled]="!evaluationMenu.openFloatMenu"
                          (click)="$event.stopPropagation()"
                          [disabled]="!evaluationMenu.openFloatMenu">
                          <i class="lf-icon-lock"></i>
                      </button>
                      <list-in-dropdown *ngIf="folder.confidentialList"
                          ngbDropdownMenu
                          role="menu"
                          class="dropdown-menu evaluation-menu__box-select-list"
                          (closeDropdownEmit)="closeDropdown('evaluation-menu-confidential-'+folder.folderId)"
                          [list]="folder.confidentialList"
                          [type]="'confidential'"></list-in-dropdown>
                  </div>
              </button>
              <div class="evaluation-menu__parent-box"
                  [ngShow]="evaluationMenu.openFloatMenu"
                  [ngClass]="{'evaluation-menu__parent-box--active':folder.active,
                    'evaluation-menu__parent-box--no-menu': userPermissions !== 'all'}"
                  (click)="launchAction('open', folder)">
                  <div class="evaluation-menu__list-item-triangle"
                      [ngShow]="evaluationMenu.openFloatMenu && skin ==='dark-blue'"
                      [ngClass]="{'evaluation-menu__list-item-triangle--active':folder.active}"></div>
                  <div class="evaluation-menu__list-item-block-text"
                      [ngClass]="{'evaluation-menu__list-item-block-text--show-block-icons': evaluationMenu.openFloatMenu && (folder.showTreeIcon || folder.lockedFolder)}"
                      [ngShow]="evaluationMenu.openFloatMenu">
                          <div class="evaluation-menu__cell-block">
                            <p [id]="'evaluation-menu-name-' + index"
                                class="evaluation-menu__col-text"
                                [ngClass]="{'concept__col-text--hidden': folder.hidden}"
                                matTooltip="{{folder.name}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                [matTooltipDisabled]="showTooltipHeight('evaluation-menu-name', index)">
                                <span [id]="'span-evaluation-menu-name-' + index">{{folder.name}}</span>
                            </p>

                          </div>

                  </div>
                  <button class="evaluation-menu__icon-link lf-icon-add"
                      [ngShow]="folder.showLinkButtonMeasures"
                      [disabled]="evaluationMenu.disabledLink"
                      (click)="openPdf($event,evaluationInfo.productId)"
                      matTooltip="{{folder.tooltipButtonMeasures | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below" >
                  </button>
              </div>
              <div class="evaluation-menu__list-item-open-tree"
                  [ngShow]="evaluationMenu.openFloatMenu && (folder.showTreeIcon || folder.lockedFolder)"
                  [ngClass]="{'evaluation-menu__list-item-open-tree--active':folder.active}">
                  <i class="lf-icon-double-lock evaluation-menu__icon-double-lock"
                    [ngClass]="{'evaluation-menu__icon-double-lock--active':folder.active}"
                    *ngIf="folder.lockedFolder"></i>
                  <div *ngIf="folder.showTreeIcon">
                    <i class="evaluation-menu__list-item-open-tree-icon"
                      [ngShow]="folder.children.length"
                      [ngClass]="{'lf-icon-angle-right':!folder.showFolders,
                        'lf-icon-angle-down':folder.showFolders,
                        'evaluation-menu__list-item-open-tree-icon--open':folder.active}"
                      (click)="showChildren(folder)"></i>
                  <div class="evaluation-menu__dropdown-menu"
                      [ngShow]="userPermissions !== 'none' && folder.active && (evaluationInfo.customizable || folder.supportsConfidential) && !viewMode"
                      ngbDropdown
                      [id]="'evaluation-menu-dropdown-menu-'+folder.folderId"
                      (openChange)="openEvaluationTreeMenu(folder)">
                      <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--primary lf-icon-meatballs-menu"
                        ngbDropdownToggle>
                      </button>
                      <ul class="evaluation-menu__list-menu-block"
                          ngbDropdownMenu
                          [ngShow]="folder.showMenu">
                          <li class="common__list-dropdown-option"
                              *ngFor="let option of folder.menuList; let lastOption = last">
                              <button class="common__button-option-list-dropdown"
                                  [ngClass]="{'common__button-option-list-dropdown--last': lastOption}"
                                  (click)="menuLaunchAction(option.action, folder, 'evaluation-menu-dropdown-menu-'+folder.folderId)">
                                  <i [class]="''+option.icon+' common__icon-option-list-dropdown'"></i>
                                  <span class="common__text-menu-option-dropdown"
                                    [afTranslate]="option.name"></span>
                              </button>
                          </li>
                      </ul>
                  </div>
                  </div>
              </div>
              <div class="evaluation-menu__list-item-open-tree"
                [ngShow]="skin === 'icam-red'"
                [ngClass]="{'evaluation-menu__list-item-open-tree--active':folder.active}">
                <button class="lf-icon-open-window evaluation-menu__icon-open-window"
                    [ngClass]="{'evaluation-menu__icon-open-window--active':folder.active,
                      'evaluation-menu__icon-open-window--close-menu': !evaluationMenu.openFloatMenu}"
                    (click)="openFolderInNewWindow($event, folder)"
                    matTooltip="{{'evaluation-menu.open-folder-new-window' | afTranslate}}"
                    [matTooltipPosition]="evaluationMenu.openFloatMenu?'above':'right'"
                    [matTooltipClass]="evaluationMenu.openFloatMenu?'above':'right'"
                    [matTooltipDisabled]="showTooltipHeight('evaluation-menu-name', index)">
                </button>
            </div>
          </div>
          <help-tooltip class="evaluation-menu__help-tooltip"
            *ngIf="folder.showHelpTooltip"
            [key]="folder.folderNamedKey"
            (closeHelpTooltip)="closeHelpTooltip(folder)">
          </help-tooltip>
          <ul class="evaluation-menu__folder-list"
              [ngShow]="folder.showFolders && folder.children.length && evaluationMenu.openFloatMenu">
              <li class="evaluation-menu__folder-item"
                  [ngClass]="{'evaluation-menu__folder-item--consulta': evaluationMenu.openTreeDesign,
                      'evaluation-menu__folder-item--consulta-last': evaluationMenu.openTreeDesign && last,
                      'evaluation-menu__folder-item--normal': !evaluationMenu.openTreeDesign}"
                  *ngFor="let folderChildren of folder.children | hidden: showHidden; let last = last"
                  [ngHide]="!showHidden && folderChildren.hidden || (folderChildren.folderId === 'reports' && !folderChildren.children.length)">
                  <div class="evaluation-menu__folder-item-icon"
                      [ngClass]="{'evaluation-menu__folder-item-icon--active':folderChildren.active,
                          'evaluation-menu__folder-item-icon--hidden':folderChildren.hidden,
                          'evaluation-menu__folder-item-icon--consulta':evaluationMenu.openTreeDesign}">
                      <i [ngClass]="{'lf-icon-not-visible evaluation-menu__hidden-icon':folderChildren.hidden,
                          'lf-icon-close-full evaluation-menu__no-evaluated-icon': !folderChildren.evaluated,
                          'lf-icon-step-final': folderChildren.evaluated && !folderChildren.hidden && !evaluationMenu.openTreeDesign,
                          'lf-icon-not-evaluable':!folderChildren.evaluated && !folderChildren.hidden,
                          'lf-icon-arrow-round-right': evaluationMenu.openTreeDesign && !folderChildren.openTree,
                          'lf-icon-arrow-round-down': evaluationMenu.openTreeDesign && folderChildren.openTree,
                          'evaluation-menu__product4': evaluationMenu.openTreeDesign}"
                          (click)="openTree(folderChildren)"></i>
                      <div class="evaluation-menu__folder-item-icon-line"
                          [ngClass]="{'evaluation-menu__folder-item-icon-line--no-evaluated':!folderChildren.evaluated && folderChildren.conceptObjectId !==0,
                            'evaluation-menu__folder-item-icon-line--evaluated':!folderChildren.evaluated && !folderChildren.hidden}"
                          [ngShow]="!evaluationMenu.openTreeDesign && !last">
                      </div>
                      <div class="evaluation-menu__block-confident-subfolder"
                          ngbDropdown
                          [id]="'evaluation-menu-confidential-'+folderChildren.folderId"
                          [autoClose]="'outside'">
                          <button class="evaluation-menu__confident-subfolder"
                              [ngClass]="{'evaluation-menu__confident-subfolder--basic': folderChildren.active && !folderChildren.hidden,
                                  'evaluation-menu__confident-subfolder--hidden': folderChildren.hidden,
                                  'evaluation-menu__confident-subfolder--active': !folderChildren.active && !folderChildren.hidden}"
                              ngbDropdownToggle
                              matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above"
                              [matTooltipDisabled]="!evaluationMenu.openFloatMenu"
                              [ngShow]="folderChildren.confidential">
                              <i class="lf-icon-lock"></i>
                          </button>
                          <list-in-dropdown *ngIf="folderChildren.confidentialList"
                              ngbDropdownMenu
                              class="dropdown-menu evaluation-menu__box-select-list dropdown-menu evaluation-menu__box-select-list--little"
                              (closeDropdownEmit)="closeDropdown('evaluation-menu-confidential-'+folderChildren.folderId)"
                              [list]="folderChildren.confidentialList"
                              [type]="'confidential'"></list-in-dropdown>
                      </div>
                  </div>
                  <p class="evaluation-menu__folder-item-name"
                      [ngClass]="{'evaluation-menu__folder-item-name--hidden':folderChildren.hidden,
                          'evaluation-menu__folder-item-name--no-evaluated': !folderChildren.evaluated,
                          'evaluation-menu__folder-item-name--active': folderChildren.active,
                          'evaluation-menu__folder-item-name--consulta': evaluationMenu.openTreeDesign,
                          'evaluation-menu__folder-item-name--no-clickable': (evaluationMenu.stateFolder || evaluationMenu.folderAutoinstanciable) && evaluationMenu.folderSelect.folderId === folderChildren.folderId}"
                      (click)="launchAction('open', folderChildren)">{{folderChildren.name}}</p>
                  <div class="evaluation-menu__folder-item-menu"
                      [ngShow]="userPermissions !== 'none' && (evaluationInfo.customizable || folderChildren.supportsConfidential) && !viewMode"
                      ngbDropdown
                      [id]="'evaluation-menu-dropdown-menu-'+folderChildren.folderId"
                      (openChange)="openFolderMenu(folderChildren)" >
                      <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--primary lf-icon-meatballs-menu"
                        ngbDropdownToggle>
                      </button>
                      <ul class="evaluation-menu__list-menu-block dropdown-menu"
                          ngbDropdownMenu
                          role="menu"
                          [ngShow]="folderChildren.showMenu">
                          <li class="common__list-dropdown-option"
                              *ngFor="let option of folderChildren.menuList; let last = last">
                              <button class="common__button-option-list-dropdown"
                                  [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                  (click)="menuLaunchAction(option.action, folderChildren, 'evaluation-menu-dropdown-menu-'+folderChildren.folderId)">
                                  <i [class]="' '+option.icon+' common__icon-option-list-dropdown'"></i>
                                  <span class="common__text-menu-option-dropdown"
                                    [afTranslate]="option.name"></span>
                              </button>
                          </li>
                      </ul>
                  </div>
                  <evaluation-tree *ngIf="evaluationMenu.openTreeDesign && folderChildren.openTree"
                      [childrenList]="folderChildren.children"
                      [level]="1"
                      [evaluatedConcept]="evaluatedConcept"
                      (launchActionTreeEmit)="launchActionTreeOn($event)">
                  </evaluation-tree>
              </li>
          </ul>
      </li>
      <li class="evaluation-menu__list-item evaluation-menu__list-item--last"
          [ngShow]="userPermissions === 'all' || evaluationMenu.preview">
          <div class="evaluation-menu__dropdown-menu"
              [ngHide]="(!evaluationInfo.customizable && !evaluationInfo.hasProperties) || extBBDD"
              ngbDropdown
              [id]="'evaluation-menu-dropdown-evaluation-'+evaluationInfo.evaluationId"
              (openChange)="openOptionsEvaluationMenu(evaluationInfo)">
              <button class="evaluation-menu__list-menu-button lf-icon-meatballs-menu"
                ngbDropdownToggle>
              </button>
              <ul class="evaluation-menu__list-menu-block dropdown-menu"
                  ngbDropdownMenu
                  role="menu"
                  [ngShow]="evaluationInfo.showMenu">
                  <li class="common__list-dropdown-option"
                      *ngFor="let option of evaluationInfo.menuList; let last = last">
                      <button class="common__button-option-list-dropdown "
                          [ngClass]="{'common__button-option-list-dropdown--last': last}"
                          (click)="menuLaunchAction(option.action, evaluationTree, 'evaluation-menu-dropdown-evaluation-'+evaluationInfo.evaluationId)">
                          <i [class]="' '+option.icon+' common__icon-option-list-dropdown'"></i>
                          <span class="common__text-menu-option-dropdown"
                            [afTranslate]="option.name"></span>
                      </button>
                  </li>
              </ul>
          </div>
          <div class="evaluation-menu__show-hide-elements"
              *ngIf="evaluationMenu.openFloatMenu && evaluationInfo.customizable && !evaluationMenu.preview && skin !== 'uk-steel-blue' && skin != 'icam-red'">
              <field-type-switch [negativeColor]="true"
                                  [label]="'evaluation-menu.show-hidden' | afTranslate"
                                  [checked]="showHidden"
                                  [disabled]="!evaluationMenu.folderSelect"
                                  (callback)="modifyHidden($event.value)">
              </field-type-switch>
          </div>
      </li>
  </ul>
  <div [ngShow]="evaluationInfo.productId === 'blanqueo' && evaluationMenu.chargedRoles">
      <div class="evaluation-menu__block-tooltip-buttons"
        matTooltip="{{'evaluation-menu.lock-check-operation' | afTranslate}}"
        matTooltipPosition="right"
        matTooltipClass="right"
        [matTooltipDisabled]="fullMenu || evaluationInfo.disabledCheckOperation"
        [ngShow]="!evaluationInfo.operationLocked && !evaluationInfo.isFinished && !evaluationInfo.operationNotSupported">
        <button class="evaluation-menu__button-blanqueo"
            [disabled]="evaluationInfo.disabledCheckOperation"
            (click)="configureManageLock('lock-operation')">
            <i class="lf-icon-alert evaluation-menu__icon-button-blanqueo"></i>
            <span class="evaluation-menu__text-button-blanqueo"
                [ngShow]="evaluationMenu.openFloatMenu"
                [afTranslate]="'evaluation-menu.lock-check-operation'">_Bloquear y revisar operación</span>
        </button>
      </div>
      <div class="evaluation-menu__block-button-lock"
          ngbDropdown
          [autoClose]="'outside'"
          [open]="evaluationMenu.openMenuOperationLocked"
          (openChange)="openManageOptionBlock(evaluationMenu.openMenuOperationLocked)"
          id="manage-lock-dropdown"
          [ngShow]="evaluationInfo.operationLocked && !evaluationInfo.isFinished && !evaluationInfo.operationNotSupported">
          <div class="evaluation-menu__block-tooltip-buttons"
              matTooltip="{{'concept-form.manage-lock' | afTranslate}}"
              matTooltipPosition="right"
              matTooltipClass="right"
              [matTooltipDisabled]="fullMenu || evaluationInfo.disabledCheckOperation">
              <button class="evaluation-menu__button-blanqueo"
                  [ngClass]="{'evaluation-menu__button-blanqueo--open': evaluationMenu.openMenuOperationLocked}"
                  [disabled]="evaluationInfo.disabledCheckOperation"
                  ngbDropdownToggle>
                  <i class="lf-icon-alert evaluation-menu__icon-button-blanqueo"></i>
                  <span class="evaluation-menu__text-button-blanqueo"
                      [ngShow]="evaluationMenu.openFloatMenu"
                      [afTranslate]="'concept-form.manage-lock'">_Gestionar bloqueo</span>
                  <i class="evaluation-menu__button-open-manage-lock lf-icon-angle-down"
                      [ngShow]="evaluationMenu.openFloatMenu">
                  </i>
              </button>
          </div>
          <ul class="evaluation-menu__list-menu-manage dropdown-menu"
              [ngClass]="{'evaluation-menu__list-menu-manage--tree-closed': !evaluationMenu.openFloatMenu}"
              ngbDropdownMenu>
              <li class="common__list-dropdown-option concept-form__block-button-option"
                  [ngClass]="{'concept-form__block-button-option--disabled': optionMenu.disabled}"
                  *ngFor="let optionMenu of evaluationMenu.menuOperationLocked; let last=last">
                  <button class="common__button-option-list-dropdown concept-form__text-button-block"
                      [ngClass]="{'common__button-option-list-dropdown--last': last,
                          'concept-form__text-button-block--disabled': optionMenu.disabled}"
                      [disabled]="optionMenu.disabled"
                      (click)="configureManageLock(optionMenu.action)">
                      <i [class]="optionMenu.icon + ' common__icon-option-list-dropdown concept-form__text-button-block'"
                          [ngClass]="{'concept-form__text-button-block--disabled': optionMenu.disabled}"></i>
                      <span class="common__text-menu-option-dropdown"
                          [afTranslate]="optionMenu.name"></span>
                  </button>
              </li>
          </ul>
      </div>
      <div class="evaluation-menu__block-task-supervision"
          [ngClass]="{'evaluation-menu__block-task-supervision--close-menu':!evaluationMenu.openFloatMenu}"
          [ngShow]="evaluationMenu.dateAlert">
          <div [ngShow]="evaluationMenu.openFloatMenu">
              <p class="evaluation-menu__title-task-supervision">
                  <span class="common__cursor-pointer"
                    (click)="openAlertList()"
                    [afTranslate]="'alert-list-window.block-review-alert'">_Alertas de revisión de bloqueo</span>
                  <span class="evaluation-menu__info-number-alerts-open"
                      [ngShow]="evaluationMenu.dateAlertsCount > 1">{{evaluationMenu.dateAlertsCount}}</span>
              </p>
              <p><span [afTranslate]="'alert-concept.alert-date'">_Fecha de alerta</span>: {{evaluationMenu.dateAlert?.alertDate | convertDate:'without-time'}}</p>
              <p><span [afTranslate]="'common.participant'">_Destinatario</span>: {{evaluationMenu.dateAlert?.recipient}}</p>
          </div>
          <div [ngShow]="!evaluationMenu.openFloatMenu">
              <button class="lf-icon-notification evaluation-menu__icon-notification-supervsion"
                  matTooltip="{{'alert-list-window.block-review-alert' | afTranslate}}"
                  matTooltipPosition="right"
                  matTooltipClass="right"
                  (click)="openAlertList()"></button>
              <span class="evaluation-menu__icon-notification-meatballs">...</span>
              <span class="evaluation-menu__info-number-alerts">{{evaluationMenu.dateAlertsCount}}</span>
          </div>
      </div>
      <div class="evaluation-menu__block-tooltip-buttons"
          matTooltip="{{'evaluation-menu.manage-evaluation' | afTranslate}}"
          matTooltipPosition="right"
          matTooltipClass="right"
          [matTooltipDisabled]="fullMenu || evaluationInfo.disabledManageEvaluation">
        <button class="evaluation-menu__button-blanqueo evaluation-menu__button-blanqueo--finish"
            [disabled]="evaluationInfo.disabledManageEvaluation"
            (click)="manageEvaluation()">
            <i class="lf-icon-certificate evaluation-menu__icon-button-blanqueo"></i>
            <span class="evaluation-menu__text-button-blanqueo"
                [ngShow]="evaluationMenu.openFloatMenu"
                [afTranslate]="'evaluation-menu.manage-evaluation'">_Gestionar evaluación</span>
        </button>
      </div>
  </div>
</div>
