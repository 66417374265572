import { Component, Input, Output, EventEmitter} from '@angular/core';

import { ContextFactory } from '@shared/factories/context/context.factory';

import { TaskHistoricParentService } from '../utils/task-historic-parent.service';

@Component({
  selector: 'task-historic-default',
  templateUrl: './task-historic-default.component.html'
})

export class TaskHistoricDefaultComponent{
  isLanding: boolean = false;

  taskHistoric: any = {
    showCommentList: true,
    readMore: true,
    readMoreText:false,
    noComments: true,
    fullScreen: false,
    compareVersions: {
      jwtToken: this.context.jwtToken,
      logID: null,
      taskId: null
    }
  }

  constructor(private taskHistoricParent: TaskHistoricParentService,
    private context: ContextFactory) {

  }

  @Input() historicList:any = [];
  @Input() task:any = null
  @Input() fullScreenBox:any = null

  @Output() catchFullScreen = new EventEmitter();

  comparatorInfo(historic:any){
    this.taskHistoricParent.comparatorInfo(historic, this)
  }

  maximizeScreen(keepClassBox:any = false){
    this.taskHistoricParent.maximizeScreen(keepClassBox, this)
  }
}
