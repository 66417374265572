<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--expand': attachmentsTemplate.fullScreen,
        'predefined-templates__template-box--attachment-templates':!attachmentsTemplate.itemSelected.conceptObjectId && !attachmentsTemplate.itemSelected.taskId,
        'predefined-templates__template-box--min-height':!attachmentsTemplate.attachmentList.length,
        'predefined-templates__template-box--max-height':attachmentsTemplate.attachmentList.length}">
    <div class="concept-legal-documentation__border-box"></div>
    <div class="box-comments__container-icons">
        <i class="predefined-templates__change-view-icon"
            (click)="maximizeScreen()"
            matTooltip="{{(attachmentsTemplate.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [ngClass]="attachmentsTemplate.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'">
        </i>
        <i class="predefined-templates__pillow"
            [ngShow]="attachmentsTemplate.attachmentList.length || typeFrom === 'documentReference' ">
            {{attachmentsTemplate.attachmentList.length}}
        </i>
        <div [ngShow]="!attachmentsTemplate.hideAddDocuments && userPermissions !=='none'">
            <i class="lf-icon-add-round box-comments__add-icon"
                (click)="launchActionDocuments('addDocument')"
                [ngShow]="(attachmentsTemplate.itemSelected.conceptObjectId || attachmentsTemplate.itemSelected.taskId)"
                matTooltip="{{'attach-document.attach-document' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
            <i class="lf-icon-up-file box-comments__add-icon"
                (click)="launchActionDocuments('upDocument')"
                [ngShow]="itemSelected.conceptId || typeFrom === 'task'"
                matTooltip="{{'up-document.file' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
            </i>
        </div>
    </div>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-add box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text"
        [afTranslate]="attachmentsTemplate.itemSelected.taskTypeId === 2?'attach-document.documents-to-sign':'attachments.documents'">Documentos adjuntos</p>
    <div class="predefined-templates__block-empty"
        [ngShow]="!attachmentsTemplate.attachmentList.length && attachmentsTemplate.closeSpinner && typeFrom !== 'documentReference' && (attachmentsTemplate.itemSelected.conceptId || typeFrom === 'task')">
        <p [afTranslate]="'attachments.without-documents'">No hay documentos adjuntos</p>
    </div>

    <ul class="attachments-template__documents-block"
        [ngShow]="attachmentsTemplate.attachmentList.length || typeFrom === 'documentReference'"
        [ngClass]="{'attachments-template__documents-block--expand': attachmentsTemplate.fullScreen}">
        <li class="attachments-template__documents-table"
            *ngFor="let document of attachmentsTemplate.attachmentList">
            <div class="attachments-template__documents-table--col-6">
                <i [class]="'attachments-template__documents-table-icon ' + (document.extension | extensionDocument)"></i>
                <div class="common__table-display attachments-template__name-document">
                    <div class="common__table-cell-tooltip">
                        <div class="concept-list__cell-block">
                            <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="attachments-template__documents-table--col-2">
                <span class="attachments-template__documents-table--col-2--document">
                    {{document.modified | convertDate:'without-time'}}
                </span>
            </div>
            <div class="attachments-template__documents-table--col-2">
                <span class="attachments-template__documents-table--col-2--document">
                    {{document.fileSize | convertBytes}}
                </span>
                <div class="attachments-template__options-block"
                    [ngShow]="(attachmentsTemplate.itemSelected.taskId || attachmentsTemplate.concept.conceptObjectId || attachmentsTemplate.concept.conceptId)">
                    <ul class="attachments-template__documents-table-options"
                      [ngShow]="userPermissions !=='none'">
                        <li class="attachments-template__menu-options"
                            [ngHide]="document.disabledDelete">
                            <i class="lf-icon-trash"
                                matTooltip="{{'common.remove-document' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="launchActionDocuments('delete' , document)">
                            </i>
                        </li>
                        <li class="attachments-template__menu-options attachments-template__menu-options--edit "
                            [ngShow]="(attachmentsTemplate.itemSelected.conceptObjectId || attachmentsTemplate.itemSelected.taskId)">
                            <i class="lf-icon-download"
                                matTooltip="{{'expanded-menu.download' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="launchActionDocuments('download' , document)">
                            </i>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</div>
