import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutSnippetsGuard implements CanActivate {
  constructor(private context: ContextFactory,
    private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.context?.settings?.productWithSnippets && this.context?.jwtToken){
        this.router.navigate(['dashboard']);
      }
      return this.context.settings.productWithSnippets;
  }

}
