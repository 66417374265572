<div class="attachments-template attachments-template--custom"
    [ngClass]="{'icam-queries__custom-expand':icamQueries.fullScreen}"
    *ngIf="icamQueries.showTemplate">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-mail attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title">{{icamQueries.customHeaderTitle}}</p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            (click)="maximizeScreen()"
            matTooltip="{{(icamQueries.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [ngClass]="icamQueries.fullScreen? 'lf-icon-collapse-panel' : 'lf-icon-open-window'">
        </i>
        <span class="common__bubble-count-custom attachments-template__bubble-count-custom"
            [ngShow]="icamQueries.notificationList.length">
            {{icamQueries.notificationList.length}}
      </span>
    </div>

    <div class="predefined-templates__historic-table predefined-templates__historic-table--custom-legal"
        [ngClass]="{'predefined-templates__historic-table--expand': icamQueries.fullScreen}"
        [ngShow]="icamQueries.notificationList.length">
        <div class="task-historic__historic-table-header task-historic__historic-table-header--custom">{{icamQueries.historicTableHeaderCustom}}</div>
        <div class="task-historic__historic-block task-historic__historic-block--custom"
            [ngClass]="{'icam-queries__notifications-block--expand': icamQueries.fullScreen,
                'task-historic__historic-block--more-elements': icamQueries.notificationList.length > 3}">
            <div class="task-historic__documents-table task-historic__documents-table--custom"
                *ngFor="let notification of icamQueries.notificationList | afOrderBy:['scheduledTime', 'name']:true">
                <div class="common__table-cell-tooltip concept-legal-documentation__legal-item-custom--title-custom">
                    <i class="lf-icon-news concept-legal-documentation__icons-reference-custom"></i>
                    <div class="concept-legal-documentation__cell-block">
                        <span-tooltip [text]="notification.name"></span-tooltip>
                    </div>
                    <div class="concept-legal-documentation__cell-block concept-legal-documentation__cell-block--right">
                        <span-tooltip [text]="notification.scheduledTime | convertDate: 'without-time'"></span-tooltip>
                    </div>
                </div>
                <div class="task-historic__header-col task-historic__header-col--2">
                    <div class="task-historic__options-block"
                        [ngHide]="icamQueries.noPermissions">
                        <ul class="attachments-template__documents-table-options-custom">
                            <li class="task-historic__menu-options">
                                <button class="historic-concept__button-eye historic-concept__button-eye--custom"
                                    (click)="showNotification(notification)">
                                    <i class="lf-icon-visible task-historic__icon-eye"
                                        matTooltip="{{'common.view' | afTranslate}}"
                                        matTooltipPosition="left"
                                        matTooltipClass="left">
                                      </i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
