<div [id]="'field-item-time-picker-' + field.id"
  class="time-picker__time-picker"
  [ngClass]="{'time-picker__time-picker--sequential': isLanding && field.poll && !field.noEditable && field.sequentialView}">
  <div class="time-picker__time-picker-container">
      <div class="time-picker__time-picker-block">
          <div (click)="increment('hours',$event)"
              class="time-picker__increment-decrement-buttons">
              <span class="lf-icon-angle-up time-picker__icon"></span>
          </div>
          <hr class="time-picker__separator-top"
              [ngHide]="isLanding">
          <input type="text"
              class="time-picker__time-input"
              [(ngModel)]="timePicker.hoursPicker"
              maxlength="2"
              [value]="timePicker.hoursPicker"
              (keyup)="changeInField('hours', $event)">
          <hr class="time-picker__separator-bottom"
              [ngHide]="isLanding">
          <div (click)="decrement('hours',$event)"
              class="time-picker__increment-decrement-buttons"
              [ngClass]="{'time-picker__increment-decrement-buttons--custom-bottom':isLanding}" >
              <span class="lf-icon-angle-down time-picker__icon"></span>
          </div>
      </div>
      <div class="time-picker__time-separator">:</div>
      <div class="time-picker__time-picker-block time-picker__time-picker-block--right">
          <div (click)="increment('minutes',$event)"
              class="time-picker__increment-decrement-buttons">
              <span class="lf-icon-angle-up time-picker__icon"></span>
          </div>
          <hr class="time-picker__separator-top"
              [ngHide]="isLanding">
          <input type="text"
              class="time-picker__time-input"
              [(ngModel)]="timePicker.minutesPicker"
              maxlength="2"
              [value]="timePicker.minutesPicker"
              (keyup)="changeInField('minutes', $event)">
          <hr class="time-picker__separator-bottom"
              [ngHide]="isLanding">
          <div (click)="decrement('minutes',$event)"
              [ngClass]="{'time-picker__increment-decrement-buttons--custom-bottom':isLanding}"
              class="time-picker__increment-decrement-buttons">
              <span class="lf-icon-angle-down time-picker__icon"></span>
          </div>
      </div>
  </div>
</div>
