import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptUtilsService } from '@shared/services/concept-utils/concept-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'channel-configuration',
  templateUrl: './channel-configuration.component.html'
})

export class ChannelConfigurationComponent implements OnInit {
  userPermissions:any = this.permissions.getPermissions();
  skin: string = this.globalCfg.skin;
  urlPublic = "";
  channelConfiguration: any = {
    dataProtectionView: this.skin !== 'icam-red'//this.skin === 'dark-blue' || this.skin === 'steel-blue'
  }
  constructor(private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private conceptUtilsService: ConceptUtilsService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory) { }

  @Input() concept: any = null;
  @Input() evaluationInfo: any = null;

  @Output() conceptAction = new EventEmitter();
  ngOnInit(): void {
    this.channelConfiguration.concept = this.concept;
    this.getUrlPublic();
    this.getCorporativeInformation();
    this.getReferenceDocuments();
    if(this.channelConfiguration.dataProtectionView){
        this.getProtectionData();
        this.getAdditionalData()
    }
    if(this.concept?.hasProcessOps){
      this.setConfiguration()
    }
  }

  getUrlPublic(){
      this.apiService.get('concepts/publicurl', this.channelConfiguration.concept.conceptId)
          .then((data:any)=> {
              this.channelConfiguration.urlPublic = data;
          },(errorData:any)=>{})
  }

  getReferenceDocuments(){
    this.apiService.get('publicconceptdoc/concept', this.channelConfiguration.concept.conceptId)
          .then((data:any) => {
            this.channelConfiguration.concept.referenceDocuments = data;
            this.getFormatDocument();
          }, (errorData:any)=>{
            this.channelConfiguration.concept.referenceDocuments = [];
          })
  }

  getFormatDocument(){
    this.channelConfiguration.concept.referenceDocuments.forEach((document:any) => {
      document.extension = document.name.substr(document.name.lastIndexOf('.')+1, document.name.length);
    });
  }

  getProtectionData(){
    this.apiService.get('publicconceptcfg/concept', this.channelConfiguration.concept.conceptId+ '/data-treatments')
      .then((data:any)=>{
        this.channelConfiguration.concept.protectionData = this.conceptUtilsService.addProtectionData(data);
      }, (errorData:any)=>{})
  }

  getAdditionalData(){
    this.apiService.get('publicconceptcfg/concept', this.channelConfiguration.concept.conceptId+ '/extra-info')
        .then((data:any)=>{
          this.channelConfiguration.concept.additionalData = this.conceptUtilsService.addProtectionData(data);
        }, (errorData:any)=>{})
  }

  setConfiguration(){
    this.loadTask();
    this.loadAlerts();
  }

  loadTask() {
    this.apiService.get('processgeneraltasks/process', this.channelConfiguration.concept.processId)
      .then((data:any) => {
          if(data){
            if(data.states?.length){
              data.responsiblesUniqueList = this.commonUtilsService.configureTaskResponsibleList(data.states);
            }else if(data.classificatorResponsibles?.length){
              data.responsiblesUniqueList = this.commonUtilsService.configureTaskResponsibleList(data.classificatorResponsibles)
            }
          }
          this.channelConfiguration.concept.taskProcess = data;
      }, (errorData:any) => {
          this.channelConfiguration.concept.taskProcess = null;
      })
  }



  loadAlerts() {
    this.apiService.get('processgeneralalerts/process',this.channelConfiguration.concept.processId)
      .then((data:any) => {
          this.channelConfiguration.concept.alertsListProcess = data;
      }, (errorData:any) => {
          this.channelConfiguration.concept.alertsListProcess = [];
      })
  }

  getCorporativeInformation(){
    let concept: any = this.channelConfiguration.concept
    concept.channelCorporativeInformation = {
        configurated : false,
        global: false
    }
    this.apiService.get('publicconceptcfg', 'concept/' + concept.conceptId)
      .then((data:any) => {
          if(data && data !== null){
              let global : any = Boolean(data.companyId);
              concept.channelCorporativeInformation.color = data.color;
              if(data.textColor){
                  concept.channelCorporativeInformation.textColor = data.textColor;
              }else{
                  concept.channelCorporativeInformation.textColor = '#ffffff';
                  if(concept.channelCorporativeInformation.color.toLowerCase() === '#fff201' || concept.channelCorporativeInformation.color.toLowerCase() === '#ffb001'){
                      concept.channelCorporativeInformation.textColor = '#000000';
                  }
              }
              concept.channelCorporativeInformation.global = global;
              concept.channelCorporativeInformation.configurated = true;
              concept.channelCorporativeInformation.logo = this.commonUtilsService.setLogo(data.logoOther, data.logo);
          }
      }, (errorData) => {});
  }

  publishConcept() {
      this.conceptAction.emit({action: 'publishConcept', concept: this.channelConfiguration.concept});
  }

  copyText(tooltip:NgbTooltip) {
      tooltip.open();
      navigator.clipboard.writeText(this.channelConfiguration.urlPublic)
      .then(() => {
          setTimeout(() => {
            tooltip.close();
          },1000)
        }, () => {});
  }



  generateQr(){
    this.modalService.qrModal(this.channelConfiguration.urlPublic)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.modalService.adviceModal(this.translate.instant('channel-configuration.qr-succes'),'info:accept:check-round')
        }
      })
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id);
  }
}
