import { Component, Input, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'duplicate-evaluation',
  templateUrl: './duplicate-evaluation.component.html'
})
export class DuplicateEvaluationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  duplicateEvaluation:any ={
    clientInfo: {
        id: 'client-info-duplicate',
        fieldId: 'clientInfoDuplicate',
        required:  true,
        label: this.translate.instant('duplicate-evaluation.associated-client')
    },
    evaluationInfo: {
      fieldId: 'evaluation-info-duplicate',
      label: this.translate.instant('duplicate-evaluation.implantation-name'),
      required:  true,
    },
    newEvaluation: {},
    clientList: []
  }
  constructor( private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal) { }

  @Input() evaluation: any = null;

  ngOnInit(): void {
    this.duplicateEvaluation.specialModal = this.evaluation.specialModal?this.evaluation.specialModal:false;
    if(!this.duplicateEvaluation.specialModal){
      this.getClients();
      this.duplicateEvaluation.newEvaluation = {
          name: this.evaluation.name + ' (' + this.translate.instant('duplicate-evaluation.copy') + ')',
          preserveContent: false,
          client: this.evaluation.clientId
      };
    }
  }

  getClients(){
    this.apiService.get('clients/all')
      .then((data:any)=>{
          this.duplicateEvaluation.clientList = this.commonUtilsService.editList(data, 'clientId', 'name');
      });
  }

  itemSelected(item:string, value: any){
    this.duplicateEvaluation.newEvaluation[item] = value;
  }

  validateInfo(){
      this.duplicateEvaluation.evaluationInfo.error = (this.duplicateEvaluation.newEvaluation.name === '' || this.duplicateEvaluation.newEvaluation.name === undefined);
      if(!this.duplicateEvaluation.evaluationInfo.error){
          this.handleAccept();
      }
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      evaluationInfo: this.duplicateEvaluation.newEvaluation
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
