<div class="finish-block-modal"
    *ngIf="finishBlockModal.configuratedOptions">
    <div class="common__modal-header-block">
        <i [class]="'common__icon-header '+finishBlockModal.configurationModal.iconTitle"></i>
        <span class="common__text-header"
            [afTranslate]="finishBlockModal.configurationModal.title">Finalizar evaluación de oportunidad</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="finish-block-modal__body">
        <h2 class="finish-block-modal__contacts-import-title"
            [afTranslate]="finishBlockModal.configurationModal.info">Esta acción implicará la ejecución de las siguientes operaciones y solo podrán autorizarla los usuarios asignados al rol de mayor autoridad.</h2>
        <div class="common__option-box finish-block-modal__option-box"
            [ngClass]="{'finish-block-modal__option-box--center': option.center,
                        'finish-block-modal__option-box--open-calendar': option.optionShow === 'calendar'}"
            *ngFor="let option of finishBlockModal.configurationModal.options">
            <div class="common__button-box-option finish-block-modal__button-box-option"
                [ngClass]="{'finish-block-modal__button-box-option--open': option.showCalendar}"
                [ngShow]="!option.showInfoCalendar">
                <span class="finish-block-modal__button-box-text"
                    [afTranslate]="option.title">_Bloquear edición</span>
            </div>
            <div class="finish-block-modal__block-info-calendar"
                [ngShow]="option.showInfoCalendar">
                <p [afTranslate]="'finish-block-modal.schedule-info-title'">Plazos de revisión recomendados:</p>
                <p [afTranslate]="'finish-block-modal.schedule-info-line-1'"
                    [ngShow]="!configuration.evaluationInfo?.isClientRecurrent">1 año, si el riesgo es Alto o Muy Alto</p>
                <p [afTranslate]="'finish-block-modal.schedule-info-line-2'">3 años, si el riesgo es Medio</p>
                <p [afTranslate]="'finish-block-modal.schedule-info-line-3'">7 años, si el riesgo es Bajo</p>
            </div>
            <div *ngIf="option.optionShow !== 'calendar'">
                <div class="finish-block-modal__block-info"
                    [ngShow]="finishBlockModal.configurationModal.from === 'innerEvaluation'">
                    <div class="finish-block-modal__button-bool field-item__button-bool field-item__button-bool--left field-item__button-bool--finish-left"
                        (click)="selectOption(true, option)"
                        [ngClass]="{'field-item__button-bool--selected':option.selected === true && !option.noEditable,
                            'field-item__button-bool--selected-no-editable field-item__button-bool--finish-left-no-editable':option.selected === true && option.noEditable,
                            'field-item__button-bool--no-editable field-item__button-bool--finish-left-no-editable':option.noEditable}">
                        <p class="field-item__bool-text field-item__bool-text--finish-modal"
                            [afTranslate]="'common.yes'">Sí</p>
                    </div>
                    <div class="finish-block-modal__button-bool field-item__button-bool field-item__button-bool--right field-item__button-bool--finish-right"
                        (click)="selectOption(false, option)"
                        [ngClass]="{'field-item__button-bool--selected':option.selected === false && !option.noEditable,
                            'field-item__button-bool--selected-no-editable field-item__button-bool--finish-right-no-editable':option.selected === false && option.noEditable,
                            'field-item__button-bool--no-editable field-item__button-bool--finish-right-no-editable':option.noEditable}">
                        <p class="field-item__bool-text field-item__bool-text--finish-modal"
                            [afTranslate]="'common.no'">No</p>
                    </div>
                </div>
                <p class="common__box-text-info finish-block-modal__box-text-info"
                    [ngClass]="{'finish-block-modal__box-text-info--buttons': finishBlockModal.configurationModal.from === 'innerEvaluation'}">{{option.selected?option.infoSelected:option.infoNoSelected}}
                </p>
                <i [class]="'common__contacts-import-icon '+option.icon+' finish-block-modal__option-box-icon'"></i>
                <!-- <div ng-show="option.type === 'schedule' && !option.disabled && finishBlockModal.selectedOption.schedule"> -->
                <div [ngShow]="option.showOpenCalendar">
                    <button class="finish-block-modal__button-open-schedule"
                        [ngClass]="{'finish-block-modal__button-open-schedule--error': option.error}"
                        (click)="openCalendar(option)">
                        <i class="lf-icon-redesign finish-block-modal__icon-open-schedule"></i>
                        <span class="span__auto-ajust"
                          [afTranslate]="finishBlockModal.buttonDate">Seleccionar fecha</span>
                    </button>
                    <div class="field-type-txchk__option-box field-type-txchk__option-box--required field-type-txchk__option-box--process-ops"
                        [ngShow]="option.error">
                        <span class="field-type-txchk__asterisk">*</span>
                        <span [afTranslate]="'customize.required'">Obligatorio</span>
                    </div>
                </div>
                <p [ngShow]="option.showAdditionalInfo"
                    class="finish-block-modal__text-additional-info"
                    [afTranslate]="option.textAdditionalInfo"></p>
            </div>
            <div *ngIf="option.showCalendar">
                <calendar-datepicker class="finish-block-modal__block-calendar-datepicker"
                    [selectedDate]="finishBlockModal.dateAlert"
                    [openDatePicker]="true"
                    (selectDatepicker)="selectDate($event)"></calendar-datepicker>
                <div class="finish-block-modal__block-buttons-calendar">
                    <button class="common__button common__button--clean finish-block-modal__button-litle finish-block-modal__button-litle--first"
                        (click)="closeCalendar(option)"
                        [afTranslate]="'common.cancel'"> Cancelar</button>
                    <button class="common__button common__button--active finish-block-modal__button-litle"
                        (click)="applyCalendarDate(option)"
                        [afTranslate]="'common.apply'"> Aplicar</button>
                </div>

            </div>
        </div>
    </div>
    <div class="finish-block-modal__footer">
        <button class="common__button common__button--clean"
            (click)="handleDismiss()"
            [disabled]="finishBlockModal.showCalendar"
            [afTranslate]="'common.cancel'"> Cancelar</button>
        <button class="common__button common__button--active"
            (click)="validateConfiguration()"
            [disabled]="finishBlockModal.showCalendar"
            [afTranslate]="'common.execute'">Ejecutar</button>
    </div>
</div>
