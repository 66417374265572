<div class="filials">
  <ul class="filials__afiliated-list">
    <li *ngFor="let child of filials.child.children | orderBy: 'name'">
        <div
          (click)="selectChild($event, child)"
          class="common__select-item filter-header__list-items-v2 filter-header__list-items-v2--filials"
          [ngStyle]="{
            'padding-left': padding
        }">
            <i
              class="filter-header__angle-icon"
              [ngShow]="child.children && child.children.length"
              [ngClass]="child.open?'lf-icon-angle-down':'lf-icon-angle-right'"
              (click)="openChild($event, child)">
            </i>
            <p
              class="filials__afiliated-name"
              [ngClass]="{
                'filials__afiliated-name--disabled':child.disabled,
                'filials__afiliated-name--disabled-red':child.disabled && (child.clientStateId === 2 || child.clientStateId === 3)
              }">
                <span-tooltip
                  [text]="child.name">
                </span-tooltip>
            </p>
            <i class="lf-icon-check-round-full common__select-icon">
            </i>
        </div>
        <filials
          class="filter-header__child-padding"
          *ngIf="filials.child.children && filials.child.children.length && child.open"
          [level]="level + 1"
          [child]="child"
          (openChildEmit)="openChildOn($event)"
          (selectChildEmit)="selectChildOn($event)" >
        </filials>
      </li>
  </ul>
</div>
