import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
    selector: 'report-table-column',
    templateUrl: './report-table-column.component.html',
})
export class ReportTableColumnComponent {
  constructor(private commonUtilsService: CommonUtilsService) { }
  @Input() rowType:any;
  @Input() row:any;
  @Input() column:any;
  @Output() launchActionColumn = new EventEmitter();

  showTooltipHeight(){
    if(!this.rowType.showMoreText){
      let showTooltip: boolean = this.commonUtilsService.showTooltipHeight(this.rowType.idUnique, this.rowType.key);
      this.rowType.showMoreInfo = showTooltip;
      return showTooltip;
    }
    return false;
  }

  showMoreOrLess(){
    this.rowType.showMoreText = !this.rowType.showMoreText;
    if(this.rowType.isHTMLDependencies){
      this.rowType.showDependencies = this.rowType.showMoreText;
    }
  }

  relatedConcept() {
    this.launchActionColumn.emit();
  }
}
