import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'users-admin-group-form',
  templateUrl: './users-admin-group-form.component.html'
})
export class UsersAdminGroupFormComponent implements OnInit, OnDestroy {
  subscribers : any = {};
  usersAdminGroupForm: any = {
    name: {
        label: this.translate.instant('users-admin.group-form.name'),
        fieldId: '',
        required: true,
        description: null,
        multiline: false,
        maxLength: 250
    },
    description: {
        label: this.translate.instant('users-admin.group-form.description'),
        fieldId: '',
        required: false,
        description: null,
        multiline: true
    },
    members: {
        label: this.translate.instant('users-admin.group-form.members'),
        fieldId: '',
        required: false,
        description: null,
        multiple: true
    },
    contactsField: {
        label: this.translate.instant('contacts.members'),
        typeConfiguration: 'manage-group',
        fieldType: 'multiple',
        addContacts: false,
        required: false,
        typeCall: 'contacts-users-only'
    }
  }
  constructor(private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService) { }
  @Input() group: any = {};
  @Input() operation: any = '';
  @Output() validateForm = new EventEmitter();

  ngOnInit(): void {
    this.usersAdminGroupForm.contactsField.selectContacts = this.group.members?this.group.members:[];
    this.openWatchers();
  }

  openWatchers(){
    this.subscribers.checkGroupFormBroadcast = this.broadcastService.subscribe('checkGroupForm', (data:any)=>{
      if(this.checkFields()){
          this.validateForm.emit();
      }
    });
    this.subscribers.resetGroupFormBroadcast = this.broadcastService.subscribe('resetGroupForm', (data:any)=>{
        this.reset();
    });
  }

  memberSelected(members: any) {
      this.group.members = members;
  }

  checkFields() {
      let errors = (this.usersAdminGroupForm.name.error = !this.group.name);
      return !errors;
  }

  reset() {
      this.group.name = '';
      this.group.description = '';
      this.broadcastService.broadcast('resetFieldContact', {});
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
