import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'users-admin',
  templateUrl: './users-admin.component.html'
})
export class UsersAdminComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  subscribers : any = {};
  usersAdmin: any = {
    headerText: this.translate.instant('common.management-users')
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private broadcastService: BroadcastService) { }

  ngOnInit(): void {
    if (this.skin === 'uk-steel-blue') {
        this.setSelfHeaderText();
        this.subscribers.personLoadedBroadcast = this.broadcastService.subscribe('person-loaded', (data:any)=>{
          this.setSelfHeaderText(data.person.userId === this.context.user.userId);
        })
    }
  }

  setSelfHeaderText(isSelf:boolean=false) {
      if(isSelf){
          this.usersAdmin.headerText = this.translate.instant('common.edit-user');
      }else{
          this.usersAdmin.headerText = this.translate.instant('common.management-user');
      }
  }

  changeView(){
      this.commonUtilsService.goToStateUrl();
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }

}
