import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConceptStateFactory {
  fields: any = [];
  conceptList: any = [];
  process:boolean = false;

  add(field: any){
    let addTo = this.fields.filter((item: any)=>item.fieldId === field.fieldId).length;
    if(!addTo){
      this.fields.push(field);
    }
  }

  remove(field: any){
    let index = this.fields.findIndex((item:any)=>{return item.fieldId === field.fieldId})
    this.fields.splice(index, 1);
  }

  removeFieldContainerFields(fieldContainerId:string){
    for(let i = this.fields.length - 1; i >= 0; i--){
      if(this.fields[i].fieldContainerId === fieldContainerId){
          this.fields.splice(i, 1);
      }
    }
  }

  getAmount(): number{
    return this.fields.length;
  }

  setProcess(open: any){
    this.process = open
  }

  getProcess(): any{
    return this.process;
  }

  setConceptList(list: any){
    this.conceptList = list;
  }

  getConceptList(): any{
    return this.conceptList;
  }

  removeAll(){
   this.fields = [];
   this.conceptList = [];
  }

  reset(){
    this.fields = [];
    this.conceptList = [];
    this.process = false;
  }

}
