<div class="common__field-type">
  <label class="common__input-block common__input-block--field"
      [ngClass]="{'common__input-block--show-references': fieldTypeLink.references && fieldTypeLink.field.evaluatedValue.conceptDataId !== null,
      'common__show-alert': fieldTypeLink.field.error && !fieldTypeLink.field.sequentialView,
      'common__show-alert-email': fieldTypeLink.field.error && fieldTypeLink.inputText,
      'common__input-no-editable':fieldTypeLink.field.noEditable,
      'common__input-block--poll-view':fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable && fieldTypeLink.field.sequentialView,
      'common__input-block--custom-field': isLanding && !fieldTypeLink.field.sequentialView}"
      [for]="'field-item-link-'+fieldTypeLink.field.id"
      [attr.disabled]="fieldTypeLink.field.noEditable?true:null">
      <div class="common__input-text-poll"
          [ngShow]="fieldTypeLink.field.sequentialView && !fieldTypeLink.field.noEditable "
          [ngClass]="{'common__input-text-poll--error': fieldTypeLink.field.error}">
          <p class="field-type-text__custom-sequential-label">{{fieldTypeLink.titleField}}
              <span class="common__text-required common__text-required--bool"
                  [ngShow]="fieldTypeLink.field.required">*</span>
              <tooltip-info [description]="fieldTypeLink.field.description"
                  [field]="fieldTypeLink.field"
                  [isSequentialPoll]="fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable && fieldTypeLink.field.sequentialView">
              </tooltip-info>
              <tooltip-law [references]="fieldTypeLink.field.references"
                  [field]="fieldTypeLink.field"
                  [ngShow]="fieldTypeLink.field.references.length"
                  [isSequentialPoll]="fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable && fieldTypeLink.field.sequentialView">
              </tooltip-law>
          </p>
      </div>
      <div class="common__label-block"
          [ngClass]="fieldTypeLink.field.evaluatedValue?.hasReferences && !fieldTypeLink.field.poll?'common__label-block--two-icon':'common__label-block--one-icon'"
          [ngHide]="fieldTypeLink.field.sequentialView && !fieldTypeLink.field.noEditable">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeLink.field.error,
                      'common__input-text--no-editable':fieldTypeLink.field.noEditable}">{{fieldTypeLink.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeLink.field.required">*</span>
          <div class="common__info-icon-block"
              [ngHide]="fieldTypeLink.field.noEditable || !fieldTypeLink.field.description">
              <tooltip-info [description]="fieldTypeLink.field.description"
                  [field]="fieldTypeLink.field">
              </tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngClass]="{'common__tooltip-law-block--landing-custom': isLanding}"
              [ngShow]="fieldTypeLink.field.references.length && !fieldTypeLink.field.noEditable">
              <tooltip-law [references]="fieldTypeLink.field.references"
                  [field]="fieldTypeLink.field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeLink.field.relatedInfo.length && !fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(fieldTypeLink.field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
          <div class="common__info-icon-block common__info-icon-block--in-report"
            *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
            <tooltip-in-report [inReport]="fieldTypeLink.inReport"></tooltip-in-report>
            </div>
      </div>
      <div [ngClass]="{'common__input-poll':fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable && fieldTypeLink.field.sequentialView,
          'common__show-alert': fieldTypeLink.field.error && fieldTypeLink.field.sequentialView}">
          <div class="float-end">
              <div class="field-type-text__icon-references field-type-text__icon-references--less-top"
                  [ngShow]="fieldTypeLink.field.evaluatedValue?.hasReferences && !fieldTypeLink.field.poll"
                  (click)="showReference($event)"
                  matTooltip="{{'common.see-dependencies' | afTranslate }}"
                  matTooltipPosition="left"
                  matTooltipClass="left">
                  <i class="lf-icon-connected-data "></i>
              </div>
              <i class="lf-icon-open-window common__input-icon"
                  [ngClass]="{'field-type-text__custom-font-icon': isLanding && fieldTypeLink.field.sequentialView,
                      'common__input-icon-active': fieldTypeLink.canBrowse}"
                  (click)="browseLink()"></i>
          </div>

          <input [id]="'field-item-link-'+fieldTypeLink.field.id"
              class="common__input-content"
              [ngClass]="{'common__input-content-alert': fieldTypeLink.field.error ,
                  'common__input-content-no-editable': fieldTypeLink.field.noEditable,
                  'common__input-content--poll':fieldTypeLink.field.poll && !fieldTypeLink.field.noEditable && fieldTypeLink.field.sequentialView}"
              type="text"
              [(ngModel)]="fieldTypeLink.inputText"
              (blur)="changeInField()"
              [maxLength]="fieldTypeLink.field.maxLength?fieldTypeLink.field.maxLength:'524288'"
              autocomplete="off"
              [readonly] ="fieldTypeLink.field.readonly || fieldTypeLink.field.noEditable">
          <i class="common__delete-content-icon"
              [ngClass]="{'lf-icon-close-round': isLanding,
                  'lf-icon-close': !isLanding } "
              [ngHide]="fieldTypeLink.inputText === '' || fieldTypeLink.field.readonly || fieldTypeLink.field.noEditable"
              (click)="resetInput()"></i>
      </div>
      <p [ngShow]="fieldTypeLink.field.error && fieldTypeLink.inputText && !fieldTypeLink.field.poll"
          class="field-type-email__alert-message"
          [afTranslate]="'field-items.invalid-link'">El email introducido no es válido
      </p>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeLink.field.error && fieldTypeLink.field.blocker && !fieldTypeLink.concept?.errorInForm?.length">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
      </div>


  </label>
</div>
