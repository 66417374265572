import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DashboardPanelParentService } from '../utils/dashboard-panel-parent.service';
import { ClientsListUkComponent } from '@core/components/clients-list/clients-list-uk/clients-list-uk.component';

@Component({
  selector: 'dashboard-panel-uk',
  templateUrl: './dashboard-panel-uk.component.html'
})

export class DashboardPanelUkComponent implements OnInit{

  forUk: boolean = false;
  noBeAndUk: boolean = false;
  noAmlBE: boolean = false;
  noMenuProducts: boolean = false;
  // oneProduct: boolean = false;
  finishAllCalls: number = 0;
  wsController: string = 'company';
  hasDenuncProp: boolean = false;
  urlPart: string = '';
  urlSecondPartInstances:string ='';
  instaceBox: boolean = false;
  adviceModalSubject: any;
  informativeModalSubject: any;
  dashboardPanel:any = this.dashboardPanelParent.configureDashboardPanel(this);

  constructor(
      private context: ContextFactory,
      private loader: LoaderFactory,
      private router: Router,
      private commonUtilsService: CommonUtilsService,
      private dashboardPanelParent: DashboardPanelParentService) {}

  @ViewChild(ClientsListUkComponent)
  clientsListUk: ClientsListUkComponent;

  ngOnInit(): void {
    this.dashboardPanelParent.init(this)
  }

  launchAction(action: any){
    this.dashboardPanelParent.launchAction(action, this)
  }

  gotToAllDocuments(id:string){
      this.commonUtilsService.registerEvent('show all documents', this.context.settings.defaultProduct)
      this.loader.openLoader('front-login-login');
      this.router.navigate(['documentation/', id ]);
  }
}
