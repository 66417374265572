import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'new-custom-report-field',
  templateUrl: './new-custom-report-field.component.html'
})
export class NewCustomReportFieldComponent implements OnInit {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private loader:LoaderFactory,
    private modalService:ModalServiceService,
    private commonUtilsService:CommonUtilsService
  ) { }

  @Input() selectedProductId:any;
  @Input() productList:any;
  @Input() predefinedReportField:any;
  @Input() editedCustomReportField:any;

  @Output() closeWindow = new EventEmitter();
  @Output() customReportFieldCreated = new EventEmitter();
  @Output() customReportFieldEdited = new EventEmitter();

  skin: string = this.globalCfg.skin;
  newCustomReportField:any = {
    stepState: 1,
    productFieldInfo: {
        fieldId: 'productId',
        description: null,
        required: true
    },
    customReportFieldName: {
        id: 'customReportFieldName',
        required: true
    },
    formErrors: {
        assignment: false
    },
    steps: [
      {
          name: this.translate.instant('new-custom-report-field.identification'),
          noEditable: false
      },
      {
          name: this.translate.instant('new-custom-report-field.assignment'),
          noEditable: false
      }
    ]
  };

  ngOnInit(): void {
    let that = this.newCustomReportField;
    if (this.editedCustomReportField) {
        that.report = this.editedCustomReportField;
    } else if (this.predefinedReportField) {
        that.report = this.predefinedReportField;
        that.stepState = 2;
    } else {
        that.report = {
            customReportFieldId: null,
            customReportFieldName: '',
            productId: this.selectedProductId,
            selectedReportFields: [],
        };
    }

    that.customReportFieldName.label = this.translate.instant('new-custom-report-field.report-field-name');
    that.productFieldInfo.label = this.translate.instant('new-custom-report.compliance-product');
    that.productFieldInfo.noEditable = this.editedCustomReportField || !(!this.selectedProductId);
  }

  previousPage() {
    this.newCustomReportField.stepState--;
  }

  clearForm() {
    let that = this.newCustomReportField;
    if(that.stepState === 1){
      that.report.customReportFieldName = '';
      if (!this.selectedProductId && !this.editedCustomReportField) {
          that.report.productId = '';
      }
    }
  }

  nextPage() {
    if (this.validateForm()) {
        this.newCustomReportField.stepState++;
    }
  }

  validateForm() {
    let that = this.newCustomReportField;
    that.formErrors.assignment  = false;
    switch (that.stepState) {
        case 1:
            if (!that.report.customReportFieldName || !that.report.productId || that.report.productId < 0) {
                if(!that.report.customReportFieldName){
                    that.customReportFieldName.error = true;
                }
                if(!that.report.productId || that.report.productId < 0){
                    that.productFieldInfo.error = true;
                }
                return false;
            }
            break;
        case 2:
            if (that.report.selectedReportFields.length < 1) {
                that.formErrors.assignment = true;
                return false;
            }
            break;
    }
    return true;
  }

  editOrCreateCustomReportField() {
    if (this.validateForm()) {
        if (this.editedCustomReportField) {
            this.editCustomReportField();
        } else {
            this.createCustomReportField();
        }
    }
  }

  editCustomReportField() {
    let that = this.newCustomReportField;
    let editCustomR = cloneDeep(that.report);
    editCustomR.selectedReportFields = editCustomR.selectedReportFields.map((el:any) => { return el.fieldId; })
    delete editCustomR.customReportFieldId;

    this.loader.openLoader('new-custom-report-field');
    this.apiService.update('customreportfields', that.report.customReportFieldId, editCustomR).then(
      (data:any) => {
          if (data) {
              this.customReportFieldEdited.emit({ customReportField: data });
          } else {
              throw data;
          }
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('new-custom-report-field.error-in-report-field-edition'), 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('new-custom-report-field');
    });
  }

  createCustomReportField() {
    let that = this.newCustomReportField;
    let newCustomR = cloneDeep(that.report);
    newCustomR.selectedReportFields = newCustomR.selectedReportFields.map((el:any) => { return el.fieldId; })

    this.loader.openLoader('new-custom-report-field');
    this.apiService.add('customreportfields', newCustomR).then(
      (data:any) => {
          if (data) {
              this.customReportFieldCreated.emit({ customReportField: data })
          } else {
              throw data;
          }
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('new-custom-report-field.error-in-report-field-creation'), 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('new-custom-report-field');
    });
  }
}
