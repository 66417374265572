import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Pipe({
  name: 'byWord'
})
export class ByWordPipe implements PipeTransform {
  constructor(private commonUtilsService: CommonUtilsService){}
  transform(items: any, find: any, words: any): any {
    return this.commonUtilsService.findByWord(items, find, words);
  }
}
