import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiPropertiesFactory {
  frontUrl: string = 'http://localhost:60703/';
  allowedAddinUrlHosts: string;
  apiURL: string;
  apiURLInternal: string;
  availableLanguages: any;
  backofficeUrl: string;
  companyDocsLibrary: string;
  countryApi: string;
  defaultLanguage: string;
  development: string;
  documentDepotUri: string;
  documentLibraryName: string;
  juridicaURL: string;
  juridicaNumPages: string;
  onlineSibilaUrl: string;
  onlineFormUrl: string;
  sharepointLogoutUri: string;
  siteTitle: string;
  siteFavIcon: string;
  supportCenterUrl: string;
  loaded: boolean = false;

  setApiProperties(data:any){
    this.allowedAddinUrlHosts = data.allowedAddinUrlHosts;
    this.apiURL = data.apiUrl;
    this.apiURLInternal = data.apiURLInternal;
    this.availableLanguages = data.availableLanguages;
    this.backofficeUrl = data.backofficeUrl;
    this.companyDocsLibrary = data.companyDocsLibrary;
    this.countryApi = data.countryApi;
    this.defaultLanguage = data.defaultLanguage;
    this.development = data.development;
    this.documentDepotUri = data.documentDepotUri;
    this.documentLibraryName = data.documentLibraryName;
    this.juridicaURL = data.juridicaURL;
    this.juridicaNumPages = data.juridicaNumPages;
    this.onlineSibilaUrl = data.onlineSibilaUrl;
    this.onlineFormUrl = data.onlineFormUrl;
    this.sharepointLogoutUri = data.sharepointLogoutUri;
    this.siteTitle = data.siteTitle;
    this.siteFavIcon = data.siteFavIcon;
    this.supportCenterUrl = data.supportCenterUrl;
    this.loaded = true;
  }
}
