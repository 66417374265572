<div class="rename-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-marker common__icon-header"></i>
      <span class="common__text-header"
      [afTranslate]="'field-type-other.relationship-reason'"></span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <form class="rename-modal__form rename-modal__form--relationship">
      <div class="rename-modal__block-body">
          <field-type-text-multiline [ngClass]="{'rename-modal__name-text': relationshipReasonModal.suffix}"
              [field]="relationshipReasonModal.reasonInfo"
              [preSelectItem]="relationshipReasonModal.reason"
              (itemSelected)="itemSelected($event, 'reason')"
              (itemSelectedEnter)="itemSelected($event, 'reason')">
          </field-type-text-multiline>
      </div>
  </form>
  <div class="common__buttons-block common__buttons-block--modal-2">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="handleAccept()"
          [afTranslate]="'common.ok'">Aceptar</button>
  </div>
</div>
