import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'roles-window',
  templateUrl: './roles-window.component.html'
})
export class RolesWindowComponent implements OnInit, OnDestroy {
  skin: string =  this.globalCfg.skin;
  rolesWindow: any ={
    finishLoader: false,
    associatedPermissions:{
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true,
      label: this.translate.instant('roles-list.mandatory-assignment'),
      description: this.translate.instant('roles-window.roles-info-description')
    }
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private userPermisions: UserPermisionsFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService) { }

  ngOnInit(): void {
    let productId = sessionStorage.getItem('productId');
    let productName = sessionStorage.getItem('productName');
    this.rolesWindow.productId = productId?productId:this.context.settings.defaultProduct;
    this.rolesWindow.productName = productName?productName:this.context.settings.defaultProductName;
    if(this.rolesWindow.productId){
      this.loader.openLoader('open-roles-window');
      this.getProductPermissions();
    }else{
      this.router.navigate(['dashboard']);
    }
  }

  getProductPermissions(){
    this.apiService.get('company/dashboard/security/product', this.rolesWindow.productId)
      .then((data:any)=>{
        this.userPermisions.setUserPermisions(data);
        this.rolesWindow.userPermissions = this.userPermisions.getPermissions();
        this.getProductRoles();
      }, (errorData:any)=>{})
  }

  getProductRoles(){
      let rolesProduct: any = this.route.snapshot.data['rolesProduct'];
      rolesProduct.forEach((role:any) => {
          role.users = role.users?role.users:[];
          role.clientsUsers = role.clientsUsers?role.clientsUsers:[];
          role.selectContacts = role.users.concat(role.clientsUsers);
          role.id = this.commonUtilsService.createId();
          role.label = role.name;
          role.required = true;
          role.selectContacts.forEach((userSelected:any) => {
              userSelected.allClients = false;
              if(!(userSelected.clients && userSelected.clients.length)){
                  userSelected.allClients = true;
                  let client = {
                      clientId: 'all',
                      name: this.translate.instant('field-type-role.all-present-future')
                  }
                  userSelected.clients = [client];
              }
        });
      });
      this.rolesWindow.rolesProduct = rolesProduct;
      this.closeLoader();
  }

  closeLoader(){
      this.loader.closeLoader(['open-roles-window', 'front-login-login']);
      this.rolesWindow.finishLoader = true;
  }

  changeView(){
    this.commonUtilsService.goToStateUrl();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('productId');
    sessionStorage.removeItem('productName');
  }

}


