<div class="customize-field-text">
  <div [ngClass]="{'customize-fields__body-field-container': typeView === 'channelViewEditable'}">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldText.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': customizeFieldText.disabledFormConfiguration}"
        [(ngModel)]="customizeFieldText.field.extendedLabel"
        [disabled]="customizeFieldText.disabledFormConfiguration"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldText.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldText.tinyMceOptions"
      [modelText]="customizeFieldText.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text"
      *ngIf="!customizeFieldText.channelViewConfiguration">
        <field-type-list-simple [field]="customizeFieldText.contentReportInfo"
            [preSelectItem]="customizeFieldText.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept?.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldText.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldText.field.listVisibleValues"
            [selectedFields]="customizeFieldText.field.fieldVisibleValues"
            [orCondition]="customizeFieldText.field.visibilityOr"
            [readonly]="customizeFieldText.field.noEditable || customizeFieldText.disabledFormConfiguration"
            [itemId]="customizeFieldText.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-text-simple *ngIf="customizeFieldText.defaultValueInfo.id && !customizeFieldText.field.multiline"
            [field]="customizeFieldText.defaultValueInfo"
            [preSelectItem]="customizeFieldText.field.defaultValue"
            (itemSelected)="itemSelected($event, 'defaultValue')">
        </field-type-text-simple>
        <field-type-text-multiline *ngIf="customizeFieldText.defaultValueInfo.id && customizeFieldText.field.multiline"
            [field]="customizeFieldText.defaultValueInfo"
            [preSelectItem]="customizeFieldText.field.defaultValue"
            (itemSelected)="itemSelected($event, 'defaultValue')">
        </field-type-text-multiline>
    </div>
    <field-type-list-simple [field]="customizeFieldText.contentTypeInfo"
        [preSelectItem]="customizeFieldText.field.fieldContentTypeId"
        [listValues]="contentTypeList"
        (itemSelected)="itemSelected($event, 'fieldContentTypeId')">
    </field-type-list-simple>
    <switch-option [ngShow]="concept?.allowPublicShare && typeView !== 'channelViewEditable'"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="customizeFieldText.disabledFormConfiguration || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngShow]="concept?.allowPublicShare && customizeFieldText.field.fieldContentTypeId === 'emai' && typeView !== 'channelViewEditable'"
        [model]="customizeFieldText.field.isIdentifier"
        [disabled]="customizeFieldText.field.noEditable || isPrivate || customizeFieldText.field.private"
        [disabledClass]="customizeFieldText.disabledFormConfiguration || isPrivate || customizeFieldText.field.private"
        [fieldText]="'customize.identification-mail'"
        (changeOptionEmit)="changeOptionOn($event, 'isIdentifier')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldText.field.required"
        [disabled]="customizeFieldText.field.noEditable || customizeFieldText.disabledFormConfiguration"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <div class="customize-field-text__switch-block">
      <switch-option class="customize-field-text__switch-button-inline"
        [model]="customizeFieldText.field.multiline"
        [disabled]="customizeFieldText.field.fieldContentTypeId == 'addr' || customizeFieldText.disabledFormConfiguration"
        [fieldText]="'customize.multiline'"
        [checked]="customizeFieldText.field.fieldContentTypeId != 'addr' && customizeFieldText.field.multiline"
        (changeOptionEmit)="changeOptionOn($event, 'multiline')">
      </switch-option>
      <switch-option class="customize-field-text__switch-button-inline"
          [ngShow]="customizeFieldText.field.multiline && concept.communicationChannel"
          [disabled]="customizeFieldText.disabledFormConfiguration"
          [model]="customizeFieldText.field.isVoiceRecord"
          [fieldText]="'customize.voice-record'"
          (changeOptionEmit)="changeOptionOn($event, 'isVoiceRecord')">
      </switch-option>
      <switch-option class="customize-field-text__switch-button-inline"
        [ngShow]="customizeFieldText.field.multiline && concept.communicationChannel && customizeFieldText.field.isVoiceRecord"
        [model]="customizeFieldText.field.voiceRecordOk"
        [disabled]="customizeFieldText.disabledFormConfiguration"
        [fieldText]="'customize.voice-record-to-text'"
        (changeOptionEmit)="changeOptionOn($event, 'voiceRecordOk')">
      </switch-option>
    </div>
    <div class="customize-field-text__field-text">
      <field-type-text-simple *ngIf="customizeFieldText.field.multiline && customizeFieldText.field.isVoiceRecord && customizeFieldText.field.voiceRecordOk"
          [field]="customizeFieldText.voiceRecordInput"
          [preSelectItem]="customizeFieldText.field.voiceRecordOkText"
          (itemSelected)="changeOptionOn($event, 'voiceRecordOkText')">
      </field-type-text-simple>
    </div>
    <div class="customize-field-list__error-block"
        [ngShow]="customizeFieldText.showError">
        <i class="lf-icon-warning establish-pattern__icon-error"></i>
        <p class="establish-pattern__text-error"
            [afTranslate]="customizeFieldText.field.voiceRecordOkTextError? 'record-audio-modal.voice-record-text-ok-error' : 'customize-field.error-identification'"></p>
        <i class="lf-icon-close customize-field-list__icon-close-header"
            (click)="customizeFieldText.showError = !customizeFieldText.showError"></i>
    </div>
  </div>
  <div class="common__buttons-block common__buttons-block--customize"
      *ngIf="typeView !== 'channelViewNoEditable'">
      <button type="reset"
              class="common__button common__button--clean"
              (click)="cancelFieldText()"
              [disabled]="concept?.disabled && typeView !== 'channelViewEditable'"
              [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
              class="common__button common__button--active"
              (click)="saveFieldText()"
              [disabled]="concept?.disabled"
              [afTranslate]="'common.save'">Guardar</button>
  </div>
</div>
