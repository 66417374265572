<div class="common__panel">
  <header-nav></header-nav>
  <div class="common__block-window-view">
      <div class="common__block-button-close-window">
          <button (click)="changeView()"
              class="common__back-link">
              <span [afTranslate]="'open-task.close-task'">_Cerrar tareas</span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="common__container-window-view">
          <div class="common__block-list-new-version common__block-list-new-version--open-task">
              <div class="common__header-new-version">
                  <i class="lf-icon-label-task common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'open-task.general-task'">Tareas genéricas</span>
              </div>
              <task-edit-default [taskSelect]="taskGeneric.task"
                  (changeView)="changeView()">
              </task-edit-default>
          </div>
      </div>
  </div>
  <compliance-footer>
  </compliance-footer>
</div>
