import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptListParentService } from '../utils/concept-list-parent.service';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'concept-list-publication',
  templateUrl: './concept-list-publication.component.html'
})
export class ConceptListPublicationComponent implements OnInit, OnChanges, OnDestroy {
  skin:string = this.globalCfg.skin;
  userPermissions:any;
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  folderId:any = this.route.snapshot.params['folderId'];
  conceptList:any = {
    textFilters: ['titulo', 'isbn', 'urlCatalogo', 'author'],
    titleWordFilter: '',
    order: false,
    openFocusTitle: false,
    openFocusAuthor: false,
    statePoll: false,
    showResultsReports: false,
    anySelect: false,
    dateFilter: 'titulo',
    configuredTotal:{
      first: 1,
      last: 20
    },
    configPagination: {
      pageSize: 20,
      maxSize: 5
    },
    currentPage: 1,
    conceptList: [],
    countConceptSelect: [],
    conceptListFilter: [],
    creationDateFromDate: null,
    creationDateToDate: null,
    modificationDateFromDate: null,
    modificationDateToDate: null,
    selectedFilters: null,
    textSelectedFilters: null,
    tituloPublicationFilter: {
        name: this.translate.instant('common.title'),
        top: 26,
        orderCol: true,
        filterOrder: 'titulo',
        width: '200px'
    },
    isbnPublicationFilter: {
        name: this.translate.instant('concept-list.isbn-signature'),
        top: 26,
        width: '200px'
    },
    urlCatalogoPublicationFilter: {
        name: this.translate.instant('concept-list.icam-catalog'),
        top: 26,
        width: '200px'
    },
    authorPublicationFilter: {
        name: this.translate.instant('common.author'),
        top: 26,
        width: '200px'
    },
    tipoFilter: {
        name: this.translate.instant('common.type'),
    },
    typeObjectId: 'conceptObjectId'
  };

  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private conceptListParent: ConceptListParentService
  ) {}

  @Input() conceptInfo:any;
  @Input() evaluation:any;
  @Input() toShow:any;
  @Input() modify:any;
  @Input() archivedAmount:any;
  @Output() evaluateConcept = new EventEmitter();
  @Output() launchAction = new EventEmitter();
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.conceptListParent.OnInit(this);
  }

  ngOnChanges(): void {
    this.conceptListParent.OnChanges(this);
  }

  filterComponentList(){
    this.conceptListParent.FilterComponentList(this)
  }

  selectTypeOperation(item:any, type:any){
    this.conceptListParent.SelectTypeOperation(item.source, type, this)
  }

  selectMultipleConcept(type:any, evaluatedConcept:any){
    this.conceptListParent.SelectMultipleConcept(type, evaluatedConcept, this)
  }

  launchActionOption(option:any, item:any){
    this.conceptListParent.LaunchActionOption(option, item, this)
  }

  openInNewTab(item:any){
    this.conceptListParent.OpenInNewTab(item, this);
  }

  openAssociatedEvaluation(concept:any){
    this.conceptListParent.OpenAssociatedEvaluation(concept, this);
  }

  openModalConfidential(instance:any){
    this.conceptListParent.OpenModalConfidential(instance, this)
  }

  orderBySearch(e:any, type:any){
    this.conceptListParent.OrderBySearch(e, type, this);
  }

  orderDate(type:any){
    this.conceptListParent.OrderDate(type, this);
  }

  openFocus(type:any){
    this.conceptList['openFocus'+type] = !this.conceptList['openFocus'+type];
  }

  archiveConcept(concept:any, archived:any){
    this.conceptListParent.ArchiveConcept(concept, archived, this)
  }

  viewArchived(){
    this.launchAction.emit({toShow:'archived', type: 'showElements'});
  }

  showReports(){
    this.launchAction.emit({toShow:'report', type: 'changeToShow'});
  }

  showTooltip(id:string):boolean|undefined{
    return this.commonUtilsService.showTooltip(id);
  }

  closeDropdown(type: any){
    this.conceptListParent.closeDropdown(type, this.dropdowns);
  }

  changeCalendar(dates:any, type:any){
    this.conceptListParent.ChangeCalendar(dates.dates, type, this);
    this.closeDropdown(type);
  }

  ngOnDestroy(){
    this.conceptListParent.ngOnDestroy();
  }
}

