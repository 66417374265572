<div class="new-custom-report-identification__block-body">
  <field-type-rich-text class="new-custom-report__block-info"
      [field]="newCustomReportIdentification.identityInfo"
      *ngIf="newCustomReportIdentification.identityInfo">
  </field-type-rich-text>
  <div class="new-custom-report-identification__product-selection">
      <field-type-list-simple [field]="productFieldInfo"
                          [preSelectItem]="newCustomReport.productId"
                          (itemSelected)="itemSelected('productId', $event)"
                          [listValues]="productList">
      </field-type-list-simple>
      <field-type-text-simple [field]="customReportNameField"
                          [preSelectItem]="newCustomReport.customReportName"
                          (itemSelected)="itemSelected('customReportName', $event)">
      </field-type-text-simple>
      <rich-editor class="common__input-textarea-special"
        [tinyMceOptions]="newCustomReportIdentification.tinyMceOptions"
        [modelText]="newCustomReport.description"
        (emitValue)="getEditorValue($event)">
      </rich-editor>
  </div>
</div>
