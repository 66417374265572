<div class="up-document">
  <div class="row">
      <div class="col-md-12">
          <div class="common__modal-header-block">
              <i class="lf-icon-template-user common__icon-header"></i>
              <span class="common__text-header">{{option.title}}</span>
              <i class="lf-icon-close common__icon-close-modal"
                  (click)="handleDismiss()"></i>
          </div>
          <div class="basic-data-modal__block-body">
              <form class="basic-data-modal__form">
                  <div class="basic-data-modal__label">
                      <field-type-text-simple [field]="basicDataModal.nameInfo"
                          [preSelectItem]="basicDataModal.conceptData.title"
                          (itemSelected)="itemSelected($event, 'title')">
                      </field-type-text-simple>
                  </div>
                  <rich-editor [tinyMceOptions]="basicDataModal.tinyMceOptions"
                      [modelText]="basicDataModal.message"
                      (emitValue)="getEditorValue($event)">
                  </rich-editor>
                  <div class="basic-data-modal__check-block">
                      <field-type-switch [label]="'common.public-dissemination'|afTranslate"
                                          [checked]="basicDataModal.conceptData.allowPublicShare"
                                          [disabled]="basicDataModal.disabledConcept || concept.hasInstances || concept.publicShared"
                                          (callback)="restore('allowPublicShare', $event.value)">
                      </field-type-switch>
                      <field-type-switch class="basic-data-modal__switch-container"
                                          [ngShow]="basicDataModal.conceptData.allowPublicShare"
                                          [label]="'common.communication-channel'|afTranslate"
                                          [checked]="basicDataModal.conceptData.communicationChannel"
                                          [disabled]="concept.publicShared || concept.hasArchivedInstances"
                                          (callback)="communicationChannel()">
                      </field-type-switch>
                      <field-type-switch class="basic-data-modal__switch-container"
                                          [ngShow]="basicDataModal.conceptData.allowPublicShare"
                                          [label]="'common.locked-edit'|afTranslate"
                                          [checked]="basicDataModal.conceptData.lockedEdit"
                                          [disabled]="basicDataModal.conceptData.communicationChannel || concept.publicShared || concept.hasArchivedInstances"
                                          (callback)="basicDataModal.conceptData.lockedEdit = $event.value">
                      </field-type-switch>
                      <field-type-switch class="basic-data-modal__switch-container"
                                          [ngShow]="basicDataModal.conceptData.allowPublicShare"
                                          [label]="'common.restricted-delete-switch'|afTranslate"
                                          [checked]="basicDataModal.conceptData.restrictedDelete"
                                          [disabled]="basicDataModal.conceptData.communicationChannel || concept.publicShared || concept.hasArchivedInstances"
                                          (callback)="basicDataModal.conceptData.restrictedDelete = $event.value">
                      </field-type-switch>
                  </div>
                  <div class="basic-data-modal__check-block">
                      <field-type-switch [label]="'common.group-as-list'|afTranslate"
                                          [checked]="basicDataModal.conceptData.groupsAsList"
                                          [disabled]="basicDataModal.conceptData.sequentialView || basicDataModal.disabledPoll || concept.hasInstances || concept.publicShared"
                                          (callback)="restore('groupsAsList', $event.value)">
                      </field-type-switch>
                  </div>
                  <div class="basic-data-modal__check-block">
                      <field-type-switch [label]="'basic-data-modal.survey-design'|afTranslate"
                                          [checked]="basicDataModal.conceptData.poll"
                                          [disabled]="basicDataModal.disabledPoll || basicDataModal.sharedPoll || basicDataModal.noEditablePoll || concept.hasInstances || concept.publicShared"
                                          (callback)="restore('poll', $event.value)">
                      </field-type-switch>
                      <field-type-switch class="basic-data-modal__switch-container"
                                          [ngShow]="basicDataModal.conceptData.poll"
                                          [label]="'basic-data-modal.public-access'|afTranslate"
                                          [checked]="basicDataModal.conceptData.anonymousAccess"
                                          [disabled]="basicDataModal.disabledPoll || concept.publicShared"
                                          (callback)="basicDataModal.conceptData.anonymousAccess = $event.value">
                      </field-type-switch>
                      <field-type-switch class="basic-data-modal__switch-container"
                                          [ngShow]="basicDataModal.conceptData.poll"
                                          [label]="'basic-data-modal.view-fields'|afTranslate"
                                          [checked]="basicDataModal.conceptData.sequentialView"
                                          [disabled]="basicDataModal.disabledPoll || concept.publicShared"
                                          (callback)="restore('sequentialView', $event.value)">
                      </field-type-switch>
                  </div>
              </form>
              <div class="common__buttons-block common__buttons-block--modal basic-data-modal__buttons-block">
                  <button type="reset"
                      class="common__button common__button--clean common__button--clean"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancelar</button>
                  <button type="submit"
                      class="common__button common__button--active common__button--active"
                      [ngShow]="!basicDataModal.disabledAcceptButton"
                      (click)="validateFields()">{{basicDataModal.confirm}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
