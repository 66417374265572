<div class="list-comparator-in-dropdown__block-participant">
  <i class="lf-icon-close list-comparator-in-dropdown__icon-close"
      (click)="closeDropdown($event)"></i>
  <div class="list-comparator-in-dropdown__block-comparator">
      <div class="list-comparator-in-dropdown__block-list-repeat">
          <div class="list-comparator-in-dropdown__title-list"
              [afTranslate]="'list-comparator-in-dropdown.previous-participant'">_Destinatario anterior</div>
          <div class="list-comparator-in-dropdown__list-elements">
              <div *ngFor="let participant of list">
                  <span-tooltip [text]="participant"
                    class="list-comparator-in-dropdown__select-item"></span-tooltip>
              </div>
          </div>
      </div>
      <div class="list-comparator-in-dropdown__block-list-repeat list-comparator-in-dropdown__block-list-repeat--no-border-left">
          <div class="list-comparator-in-dropdown__title-list"
              [afTranslate]="'list-comparator-in-dropdown.actual-participant'">_Destinatario actual</div>
          <div class="list-comparator-in-dropdown__list-elements">
              <div *ngFor="let participantComparator of listComparator">
                  <span-tooltip [text]="participantComparator"
                      class="list-comparator-in-dropdown__select-item"></span-tooltip>
              </div>
          </div>
      </div>
  </div>
</div>
