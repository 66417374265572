<div class="custom-report-field-list">
  <div class="custom-reports__add-report-button">
      <button class="lf-icon-sum common__button-checked"
              (click)="changeView.emit({view: customReportFieldList.newCustomReportFieldView})"
              matTooltip="{{'custom-report-field-list.new-report-field' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left">
      </button>
  </div>
  <div class="custom-reports__table">
      <div class="common__table-header-flex">
          <div class="custom-report-field-list__table-col custom-report-field-list__table-col--is-custom-report-header custom-report-list__table-col--top-1">
              <filter-by-list [type]="'list'"
                              [item]="customReportFieldList.isCustomFilter"
                              [list]="customReportFieldList.isCustomList"
                              (filterElementEmit)="selectTypeOperation($event, 'isCustom')">
              </filter-by-list>
          </div>
          <div ngbDropdown class="custom-report-field-list__table-col custom-report-field-list__table-col--report-name-report-header custom-report-list__table-col--top-2"
              [open]="customReportFieldList.openFocusReportFieldName">
              <p ngbDropdownToggle class="common__table-header-text common__table-header-text--without-cursor"
                  [afTranslate]="'custom-report-field-list.header-table.report-field-name'"></p>
              <i ngbDropdownToggle class="lf-icon-filter-1 common__filter-icon"
                  [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': customReportFieldList.reportFieldNameWordFilter.length}"></i>
              <i class="lf-icon-arrow-down common__order-by"
                  [ngClass]="{'lf-icon-arrow-up': customReportFieldList.productNameOrder === 'reportFieldName' && customReportFieldList.orderOperationProductName === false,
                    'common__order-by--active': customReportFieldList.productNameOrder==='reportFieldName'}"
                  (click)="orderProductName('reportFieldName')"></i>
              <ul ngbDropdownMenu class="common__select-list-with-input common__select-list-with-input--top-26">
                  <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                      <input class="common__dropdown-inner-input"
                              (keyup)="filter('reportFieldName')"
                              placeholder="{{'custom-report-field-list.search.report-field-name'|afTranslate}}"
                              [(ngModel)]="customReportFieldList.reportFieldNameWordFilter"
                              [focusWhen]="customReportFieldList.openFocusReportFieldName">
                      <i class="common__search-icon common__search-icon--reset"
                          [ngClass]="customReportFieldList.reportFieldNameWordFilter ==='' ? 'lf-icon-search' : 'lf-icon-close-search'"
                          (click)="resetFilter($event, 'reportFieldName')"></i>
                  </li>
              </ul>
          </div>
          <div class="custom-report-field-list__table-col custom-report-field-list__table-col--report-fields-header">
              <div ngbDropdown [open]="customReportFieldList.openFocusReports">
                  <div ngbDropdownToggle>
                      <p class="common__table-header-text common__table-header-text--without-cursor"
                         [afTranslate]="'custom-report-field-list.header-table.reports'"></p>
                      <i class="lf-icon-filter-1 common__filter-icon"
                         [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': customReportFieldList.reportWordFilter.length}"></i>
                  </div>
                  <ul ngbDropdownMenu class="common__select-list-with-input common__select-list-with-input--top-26">
                      <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                          <input class="common__dropdown-inner-input"
                                 (keyup)="filter('reportsText')"
                                 placeholder="{{'custom-report-field-list.search.report'|afTranslate}}"
                                 [(ngModel)]="customReportFieldList.reportWordFilter"
                                 [focusWhen]="customReportFieldList.openFocusReports">
                          <i class="common__search-icon common__search-icon--reset"
                             [ngClass]="customReportFieldList.reportWordFilter === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                             (click)="resetFilter($event, 'reportsText')"></i>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="custom-report-field-list__table-col custom-report-field-list__table-col--action-report-header">
              <p class="common__table-header-text common__table-header-text--right" [afTranslate]="'common.actions'"></p>
          </div>
      </div>
      <div class="custom-reports__table-body">
          <div class="common__table-row-flex"
               *ngFor="let reportField of customReportFieldList.filterReportFieldList | afOrderBy:customReportFieldList.productNameOrder:customReportFieldList.orderOperationProductName | afLimitTo:customReportFieldList.configPagination.pageSize:customReportFieldList.configPagination.pageSize*(customReportFieldList.currentPage - 1); let $index = index">
              <div class="custom-report-field-list__table-col custom-report-field-list__table-col--is-custom-report">
                  <div class="common__cell-block">
                      <i *ngIf="!reportField.isCustom"
                         class="lf-icon-template task-list__icon-type"></i>
                      <i *ngIf="reportField.isCustom"
                         class="lf-icon-template-user task-list__icon-type"></i>
                  </div>
              </div>
              <div class="custom-report-field-list__table-col custom-report-field-list__table-col--report-name-report">
                <parragraph-tooltip [text]="reportField.reportFieldName"></parragraph-tooltip>
              </div>
              <div class="custom-report-field-list__table-col  custom-report-field-list__table-col--report-fields">
                  <window-list [headerIcon]="'lf-icon-analysis'"
                               [titleText]="'common.reports' | afTranslate"
                               [clickText]="reportField.reports.length"
                               [items]="reportField.reports"
                               [type]="'customReport'"></window-list>
              </div>
              <div class="custom-report-field-list__table-col custom-report-field-list__table-col--action-report">
                  <ul class="common__options-row-flex">
                      <li class="common__menu-options-row"
                          *ngIf="!reportField.isCustom"
                          (click)="viewPredefinedReportField.emit({ predefinedReportField: reportField })">
                          <i class="lf-icon-visible"
                              matTooltip="{{'custom-report-field-list.new-report-field' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="reportField.isCustom"
                          (click)="editCustomReportField.emit({ customReportField: reportField })">
                          <i class="lf-icon-editable-form"
                              matTooltip="{{'common.edit' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row">
                          <i class="lf-icon-trash"
                              *ngIf="reportField.isCustom"
                              (click)="goToDelete($event, reportField)"
                              matTooltip="{{'common.remove' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                  </ul>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
              </div>
          </div>
      </div>
      <div class="custom-reports__pagination">
        <pagination-control [ngShow]="customReportFieldList.filterReportFieldList.length > customReportFieldList.configPagination.pageSize"
          [config]="customReportFieldList.configPagination"
          [collectionSize]="customReportFieldList.filterReportFieldList.length"
          [(currentPage)]="customReportFieldList.currentPage"></pagination-control>
        <div class="common__block-footer-total common__block-footer-total--bottom-2">
          <span [afTranslate]="'common.total'"></span>
          <span class="common__total-number-element notranslate">{{customReportFieldList.filterReportFieldList.length}}</span>
        </div>
      </div>
  </div>
</div>
