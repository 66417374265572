<div class="channel-configuration__block">
    <div class="channel-configuration__header">
        <div class="channel-configuration__header-title">
            <i class="lf-icon-configuration channel-configuration__icon-title"></i>
            <h4 class="channel-configuration__name-title"
                [afTranslate]="'channel-configuration.channel-configuration'">_Channel configuration</h4>
        </div>
        <div class="channel-configuration__header-options">
            <div class="channel-configuration__header-option-list"
                [ngShow]="channelConfiguration.concept?.publicShared && skin !=='icam-red'"
                (click)="generateQr()">
                <span [afTranslate]="'channel-configuration.generate-qr'">_Generar Código QR</span>
                <i class="lf-icon-qr channel-configuration__header-options-icon"></i>
            </div>
            <div class="channel-configuration__header-option-list channel-configuration__header-option-list--margin-left"
                id="channel-configuration-tooltip-copy"
                [ngShow]="channelConfiguration.concept?.publicShared"
                (click)="copyText(thisTooltip)"

                [ngbTooltip]="'common.copied' | afTranslate"
                triggers="manual"
                #thisTooltip="ngbTooltip"
                container="body">
                <span [afTranslate]="'common.copy-link'">_Copiar enlace</span>
                <i class="lf-icon-paste channel-configuration__header-options-icon"></i>
            </div>
            <div class="channel-configuration__header-option-list  channel-configuration__header-option-list--margin-left"
                [ngShow]="channelConfiguration.concept?.publicShared && userPermissions==='all'"
                (click)="publishConcept()">
                <span [afTranslate]="'expanded-menu.edit-configuration'">_Editar configuración</span>
                <i class="lf-icon-editable-form channel-configuration__header-options-icon"></i>
            </div>
            <div class="channel-configuration__header-option-list"
                [ngShow]="!channelConfiguration.concept?.publicShared && userPermissions==='all'"
                (click)="publishConcept()">
                <span [afTranslate]="'channel-configuration.configure'">_Configure and publish</span>
                <i class="lf-icon-link channel-configuration__header-options-icon"></i>
            </div>
        </div>
    </div>
    <div [ngShow]="channelConfiguration.dataProtectionView"
        class="channel-configuration__block-info-corporative-header">
        <div class="channel-configuration__border-box"></div>
        <p class="channel-configuration__text-no-configurate"
            [afTranslate]="'channel-configuration.description-info-corporative'"
            [ngShow]="!channelConfiguration.concept?.channelCorporativeInformation?.configurated">_No se ha aplicado una configuración corporativa al canal</p>
        <div [ngShow]="channelConfiguration.concept?.channelCorporativeInformation?.configurated">
            <p class="channel-configuration__text-info-corporative-header"
                [ngShow]="!channelConfiguration.concept?.channelCorporativeInformation?.global"
                [afTranslate]="'public-link.configure-specific-applied'">_Personalización especifica aplicada</p>
            <p class="channel-configuration__text-info-corporative-header"
                [ngShow]="channelConfiguration.concept?.channelCorporativeInformation?.global"
                [afTranslate]="'public-link.configure-global-applied'">_Personalización global aplicada</p>
            <div>
                <div class="channel-configuration__box-color-corporative channel-configuration__box-color-corporative--header"
                    [ngStyle]="{'background-color': channelConfiguration.concept?.channelCorporativeInformation?.color}"></div>
                <div class="channel-configuration__box-color-corporative channel-configuration__box-color-corporative--header"
                    [ngStyle]="{'background-color': channelConfiguration.concept?.channelCorporativeInformation?.textColor}"></div>
                <img class="channel-configuration__img-logo channel-configuration__img-logo--header"
                    [src]="channelConfiguration.concept?.channelCorporativeInformation?.logo" alt="logo">
            </div>
        </div>
    </div>
    <div class="channel-configuration__body">
        <div class="channel-configuration__body-option"
            [ngShow]="channelConfiguration.dataProtectionView">
            <div class="channel-configuration__border-box"></div>
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'protection-data-modal.data-protection-information'">_Información de protección de datos</h5>
                <p class="channel-configuration__text-no-configurate"
                    [ngShow]="!channelConfiguration.concept?.protectionData"
                    [afTranslate]="'channel-configuration.description-info-data-protection'">_No se ha definido el tratamiento de los datos personales</p>
            <div [innerHTML]="channelConfiguration.concept?.protectionData?.text"
                [ngShow]="channelConfiguration.concept?.protectionData"
                class="channel-configuration__protection-data-text"></div>
        </div>
        <div class="channel-configuration__body-option"
            [ngShow]="channelConfiguration.dataProtectionView">
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'channel-configuration.additional-info'">_Información adicional</h5>
                <p class="channel-configuration__text-no-configurate"
                    [ngShow]="!channelConfiguration.concept?.additionalData"
                    [afTranslate]="'channel-configuration.description-info-additional-protection'">_No hay información adicional</p>
            <div [innerHTML]="channelConfiguration.concept?.additionalData?.text"
                [ngShow]="channelConfiguration.concept?.additionalData"
                class="channel-configuration__protection-data-text"></div>
        </div>
        <div class="channel-configuration__body-option"
            [ngShow]="!channelConfiguration.dataProtectionView">
            <div class="channel-configuration__border-box"></div>
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'public-link.corporative-information'">_Información corporativa</h5>
            <p class="channel-configuration__text-no-configurate"
                [afTranslate]="'channel-configuration.description-info-corporative'"
                [ngShow]="!channelConfiguration.concept?.channelCorporativeInformation?.configurated">_No corporate customization applied to the channel</p>
            <div [ngShow]="channelConfiguration.concept?.channelCorporativeInformation?.configurated">
                <p class="channel-configuration__text-configurate-information"
                    [ngShow]="!channelConfiguration.concept?.channelCorporativeInformation?.global"
                    [afTranslate]="'public-link.configure-specific-applied'">_Personalización especifica aplicada</p>
                <p class="channel-configuration__text-configurate-information"
                    [ngShow]="channelConfiguration.concept?.channelCorporativeInformation?.global"
                    [afTranslate]="'public-link.configure-global-applied'">_Personalización global aplicada</p>
                <div>
                    <div class="channel-configuration__box-color-corporative"
                        [ngStyle]="{'background-color': channelConfiguration.concept?.channelCorporativeInformation?.color}"></div>
                    <div class="channel-configuration__box-color-corporative"
                        [ngStyle]="{'background-color': channelConfiguration.concept?.channelCorporativeInformation?.textColor}"></div>
                    <div class="channel-configuration__block-logo">
                        <img class="channel-configuration__img-logo"
                            [src]="channelConfiguration.concept?.channelCorporativeInformation?.logo" alt="logo">
                    </div>
                </div>
            </div>
        </div>
        <div class="channel-configuration__body-option">
            <div class="channel-configuration__border-box"></div>
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'public-link.reference-documents'">_Documentos de referencia</h5>
            <p class="channel-configuration__text-no-configurate"
                [ngShow]="!channelConfiguration.concept?.referenceDocuments?.length"
                [afTranslate]="'channel-configuration.description-info-documents'">_No user help documents</p>
            <div [ngShow]="channelConfiguration.concept?.referenceDocuments?.length"
                class="channel-configuration__block-configurate">
                <div *ngFor="let document of channelConfiguration.concept?.referenceDocuments"
                    class="channel-configuration__col-list"
                    [ngClass]="{'channel-configuration__col-list--more-elements': channelConfiguration.concept?.referenceDocuments?.length > 3}">
                    <i [class]="'channel-configuration__icon-list '+ (document.extension | extensionDocument)"></i>
                    <div class="common__cell-block channel-configuration__cell-block">
                        <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="channel-configuration__body-option">
            <div class="channel-configuration__border-box"></div>
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'process.ops-modal.task-table-title'">Tarea de proceso</h5>
            <p class="channel-configuration__text-no-configurate"
                [ngShow]="!channelConfiguration.concept?.taskProcess"
                [afTranslate]="'channel-configuration.description-info-tasks'">_A general management task has not been created</p>
            <div [ngShow]="channelConfiguration.concept?.taskProcess"
                class="channel-configuration__block-configurate">
                <p class="channel-configuration__text-task-paragraph">
                    <span [afTranslate]="'common.title'">Title</span>:
                    <span class="channel-configuration__text-task-configuration">{{channelConfiguration.concept?.taskProcess?.title}}</span>
                </p>
                <p class="channel-configuration__text-task-paragraph">
                    <span [afTranslate]="'contacts.recipients'">Recipients</span>:
                    <span [ngShow]="channelConfiguration.concept?.taskProcess?.responsibles"
                        *ngFor="let responsible of channelConfiguration.concept?.taskProcess?.responsibles | unique: 'id'; let last = last">
                        <span class="channel-configuration__text-task-configuration"
                            [ngClass]="{'common__line-through':responsible?.isDeleted}"
                            matTooltip="{{responsible?.isDeleted? responsible?.fullName +' ('+ ('common.inactive-user' | afTranslate)+')':responsible?.fullName}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            [matTooltipDisabled]="!responsible?.isDeleted">
                            {{responsible?.fullName}}
                        </span>
                        <span [ngHide]="last">, </span>
                    </span>
                   
                    <span class="channel-configuration__text-task-configuration"
                        [ngShow]="channelConfiguration.concept?.taskProcess?.responsiblesUniqueList?.length"
                        *ngFor="let responsible of channelConfiguration.concept?.taskProcess?.responsiblesUniqueList | unique: 'uniqueId'; let last = last">
                        <span [ngClass]="{'common__line-through':responsible.isDeleted}"
                            matTooltip="{{responsible.isDeleted?responsible.fullName +' ('+ ('common.inactive-user' | afTranslate)+')':responsible.fullName}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            [matTooltipDisabled]="!responsible.isDeleted">
                            {{responsible.fullName.trim()}}
                        </span>
                        <span [ngHide]="last">, </span>
                    </span>
                </p>
                <p class="channel-configuration__text-task-paragraph"
                    [ngShow]="channelConfiguration.concept?.taskProcess?.expirationUnits">
                    <span [afTranslate]="'task-edit.expiration-date'">_Fecha de vencimiento</span>:
                    <span class="channel-configuration__text-task-configuration">
                        <span>{{channelConfiguration.concept?.taskProcess?.expirationUnits}}</span>
                        <span [ngShow]="channelConfiguration.concept?.taskProcess?.expirationTimeUnit === 'D'"
                            [afTranslate]="'relative-time.days'">_días</span>
                        <span [ngShow]="channelConfiguration.concept?.taskProcess?.expirationTimeUnit === 'W'"
                            [afTranslate]="'relative-time.weeks'">_semanas</span>
                        <span [ngShow]="channelConfiguration.concept?.taskProcess?.expirationTimeUnit === 'M'"
                            [afTranslate]="'relative-time.months'">_meses</span>
                    </span>
                </p>
            </div>
        </div>
        <div class="channel-configuration__body-option">
            <div class="channel-configuration__border-box"></div>
            <h5 class="channel-configuration__title-box"
                [afTranslate]="'channel-configuration.general-tracking-alerts'">_General tracking alerts</h5>
            <p class="channel-configuration__text-no-configurate"
                [ngShow]="!channelConfiguration.concept?.alertsListProcess?.length"
                [afTranslate]="'channel-configuration.description-info-alerts'">_No alerts for management monitoring</p>
            <div [ngShow]="channelConfiguration.concept?.alertsListProcess?.length"
                class="channel-configuration__block-configurate">
                <div *ngFor="let alert of channelConfiguration.concept?.alertsListProcess"
                    class="channel-configuration__col-list"
                    [ngClass]="{'channel-configuration__col-list--more-elements': channelConfiguration.concept?.alertsListProcess?.length > 3}">
                    <i class="lf-icon-notification channel-configuration__icon-list"></i>
                    <div class="common__cell-block channel-configuration__cell-block">
                        <parragraph-tooltip [text]="alert.title"></parragraph-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
