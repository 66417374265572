import { Component, Input, OnChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'new-custom-report-field-identification',
  templateUrl: './new-custom-report-field-identification.component.html'
})
export class NewCustomReportFieldIdentificationComponent implements OnChanges {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory
  ) { }

  @Input() newCustomReportField:any;
  @Input() productList:any;
  @Input() customReportFieldName:any;
  @Input() productFieldInfo:any;

  skin: string = this.globalCfg.skin;
  newCustomReportFieldIdentification:any = {
    identityInfo: {
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true,
      label: this.translate.instant('new-custom-report.product-association'),
      description: this.translate.instant('new-custom-report-field.product-association-text')
    }
  };

  ngOnChanges() {
    if (this.newCustomReportField && this.newCustomReportField.productId) {
        this.itemSelected('productId', this.newCustomReportField.productId);
    }
  }

  itemSelected(item:string, newValue:any) {
    if(item === 'productId'){
        if(newValue !== this.newCustomReportField[item]){
            this.newCustomReportField.selectedReportFields = [];
        }
        if(newValue){
            this.getNameProduct(newValue);
        }else{
            this.newCustomReportField.productName = '';
        }
    }
    this.newCustomReportField[item] = newValue;
    if(newValue){
        switch(item){
            case 'productId':
                this.productFieldInfo.error = false;
                break;
            case 'customReportFieldName':
                this.customReportFieldName.error = false;
                break;
        }
    }
  }

  getNameProduct(newValue:any){
    for(let i = 0; i < this.productList.length; i++){
        let product = this.productList[i];
        if(product.productId === newValue){
            this.newCustomReportField.productName = product.name;
        }
    }
  }
}
