<div class="custom-reports common__panel">
  <header-nav></header-nav>
  <div class="common__block-window-view">
      <div class="common__block-button-close-window">
          <button class="common__back-link" (click)="goToDashboard()">
              <span [afTranslate]="'custom-reports.close-reports'"></span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="common__container-window-view">
          <div class="common__block-list-new-version custom-reports__block-container">
              <div class="common__header-new-version">
                  <i class="lf-icon-label-analysis common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'custom-reports.reports-configuration'"></span>
                  <div ngbDropdown class="common__options-block">
                      <button ngbDropdownToggle class="common__selected-option">
                          <span class="evaluations-list__select-language">{{customReports.selectedView.label}}</span>
                          <i class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
                      </button>
                      <div ngbDropdownMenu class="common__menu-block-dropdown">
                          <div class="common__block-option-list common__block-option-list--center">
                              <div class="common__language-item"
                                  *ngFor="let view of customReports.views"
                                  (click)="changeView(view)">
                                  <span-tooltip
                                    [placement]="'above'"
                                    [text]="view.label">
                                    </span-tooltip>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="custom-reports__body"
                  [ngSwitch]="customReports.view.code">
                  <div class="custom-reports__inside-body">
                      <custom-report-list *ngSwitchCase="'reports'"
                          (changeView)="changeView($event.view)"
                          [reportList]="customReports.filterReportList"
                          (viewPredefinedReport)="viewPredefinedReport($event.predefinedReport)"
                          (duplicatePredefinedReport)="duplicatePredefinedReport($event.predefinedReport)"
                          (editCustomReport)="editCustomReport($event.customReport)"></custom-report-list>
                      <custom-report-field-list *ngSwitchCase="'reportFields'"
                          (changeView)="changeView($event.view)"
                          [reportFieldList]="customReports.filterReportFieldList"
                          (viewPredefinedReportField)="viewPredefinedReportField($event.predefinedReportField)"
                          (editCustomReportField)="editCustomReportField($event.customReportField)"></custom-report-field-list>
                      <new-custom-report *ngSwitchCase="'newCustomReport'"
                          [productList]="customReports.productList"
                          [selectedProductId]="customReports.selectedProductId"
                          (closeWindow)="changeViewCode('reports')"
                          [predefinedReport]="customReports.predefinedReport"
                          [duplicatedPredefinedReport]="customReports.duplicatedPredefinedReport"
                          [editedCustomReport]="customReports.editedCustomReport"
                          (customReportCreated)="customReportCreated($event.customReport)"
                          (customReportEdited)="customReportEdited($event.customReport)"></new-custom-report>
                      <new-custom-report-field *ngSwitchCase="'newCustomReportField'"
                          [productList]="customReports.productList"
                          [selectedProductId]="customReports.selectedProductId"
                          (closeWindow)="changeViewCode('reportFields')"
                          [predefinedReportField]="customReports.predefinedReportField"
                          [editedCustomReportField]="customReports.editedCustomReportField"
                          (customReportFieldCreated)="customReportFieldCreated($event.customReportField)"
                          (customReportFieldEdited)="customReportFieldEdited($event.customReportField)"></new-custom-report-field>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
