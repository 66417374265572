
<div class="reports-list">
  <header-nav></header-nav>
  <div class="common__block-window-view">
      <div class="common__block-button-close-window">
          <button (click)="changeView()"
              class="common__back-link">
              <span [afTranslate]="'reports-list.close-reports'">_Cerrar informes generales</span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="common__container-window-view">
          <div class="common__block-list-new-version reports-list__block-list-new-version">
              <div class="common__header-new-version">
                  <i class="lf-icon-label-analysis common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'dashboard-reports.general-report'">Informe general</span>
              </div>

              <div class="reports-list__principal-report reports__principal-report"
                  [ngShow]="reportsList.lastReport">
                  <div class="reports-list__principal-report-info">
                      <i class="reports__icon-document lf-icon-template"></i>
                      <p class="reports-list__principal-report-title">{{reportsList.lastReport.title}}</p>
                      <p class="reports-list__principal-report-name">[{{reportsList.lastReport.author}}, {{reportsList.lastReport.creationDate | convertDate:'without-time' }}] </p>
                  </div>
                  <div class="reports__principal-report-options">
                      <div class="reports__item"
                          (click)="openReport(reportsList.lastReport)">
                          <i class="reports__item-icon lf-icon-editable-form"></i>
                          <span class="reports__item-text"
                              [afTranslate]="'expanded-menu.edit'">_Editar</span>
                      </div>
                      <div class="reports__item"
                          (click)="downloadReport(reportsList.lastReport)">
                          <i class="reports__item-icon lf-icon-download"></i>
                          <span class="reports__item-text"
                              [afTranslate]="'expanded-menu.download'">_Descargar</span>
                      </div>

                      <div class="reports__item"
                          (click)="deleteReport(reportsList.lastReport, true)">
                          <i class="reports__item-icon lf-icon-trash"></i>
                          <span class="reports__item-text"
                              [afTranslate]="'expanded-menu.delete'">_Eliminar</span>
                      </div>
                  </div>
              </div>
              <span class="reports-list__title-table-v2"
                  [afTranslate]="'reports-list.previous-versions'">_Versiones previas</span>
              <div class="common__table-block common__table-block--top-15">
                  <div class="common__table-new-version">
                      <div class="common__table-header">
                          <div class="reports-list__col-new-version reports-list__col-new-version--header-title-v2 reports-list__col-new-version--first"
                              ngbDropdown
                              [autoClose]="'outside'">
                              <p class="common__table-header-text"
                                  [afTranslate]="'common.title'"
                                  ngbDropdownToggle>Título</p>
                              <i class="common__filter-icon lf-icon-filter-1"
                                  [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': reportsList.titleFilter.length}"
                                  (click)="openFocus('Title')"
                                  ngbDropdownToggle></i>
                              <i class="common__order-by lf-icon-arrow-down"
                                  [ngClass]="{'lf-icon-arrow-up':reportsList.dateOrder === 'title' && !reportsList.orderOperationDates,
                                      'common__order-by--active': reportsList.dateOrder === 'title'}"
                                  (click)="orderDate('title')"></i>
                              <ul class="dropdown-menu common__select-list-with-input"
                              ngbDropdownMenu>
                                  <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                      <input type="text"
                                          class="common__dropdown-inner-input"
                                          (keyup)="filterReport('title')"
                                          placeholder="{{'concept-list.search' | afTranslate}}"
                                          [(ngModel)]="reportsList.titleFilter"
                                          [ngModelOptions]="{standalone: true}">
                                      <i class="lf-icon-search common__search-icon"
                                          [ngClass]="reportsList.titleFilter ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
                                          (click)="resetFilter($event, 'title')"></i>
                                  </li>
                              </ul>
                          </div>
                          <div class="reports-list__col-new-version reports-list__col-new-version--author">
                              <p class="common__table-header-text"
                                  [afTranslate]="'common.author'">_Autor</p>
                          </div>
                          <div class="reports-list__col-new-version reports-list__col-new-version--header-creation">
                              <div class="common__cell-block common__cell-block--padding-right">
                                  <p id="report-list-creation-0-header"
                                      class="common__table-header-text common__table-header-text--ellipsis common__cursor-pointer"
                                      matTooltip="{{'common.creation' | translate}}"
                                      matTooltipPosition="above"
                                      matTooltipClass="above"
                                      [matTooltipDisabled]="showTooltip('report-list-creation-0-header')"
                                      (click)="orderDate('creationDate')">
                                      <span id="span-report-list-creation-0-header"
                                          [afTranslate]="'common.creation'">Creación</span>
                                  </p>
                                  <i class="common__order-by"

                                  [ngClass]="{'lf-icon-arrow-down':(reportsList.dateOrder === 'creationDate' && reportsList.orderOperationDates) || reportsList.dateOrder !== 'creationDate',
                                      'lf-icon-arrow-up':reportsList.dateOrder === 'creationDate' && !reportsList.orderOperationDates,
                                      'common__order-by--active': reportsList.dateOrder === 'creationDate'}"
                                  (click)="orderDate('creationDate')"></i>
                              </div>
                          </div>
                          <div class="reports-list__col-new-version reports-list__col-new-version--header-modification">
                              <div class="common__cell-block common__cell-block--padding-right">
                                  <p id="report-list-modification-0-header"
                                      class="common__table-header-text common__table-header-text--{{reportsList.skin}} common__table-header-text--ellipsis common__cursor-pointer"
                                      matTooltip="{{'common.modification' | translate}}"
                                      matTooltipPosition="above"
                                      matTooltipClass="above"
                                      [matTooltipDisabled]="showTooltip('report-list-modification-0-header')"
                                      (click)="orderDate('modificationDate')">
                                      <span id="span-report-list-modification-0-header"
                                          [afTranslate]="'common.modification'">_Modificación</span>
                                  </p>
                                  <i class="common__order-by"
                                      [ngClass]="{'lf-icon-arrow-down':(reportsList.dateOrder === 'modificationDate' && reportsList.orderOperationDates) || reportsList.dateOrder !== 'modificationDate',
                                        'lf-icon-arrow-up':reportsList.dateOrder === 'modificationDate' && !reportsList.orderOperationDates,
                                        'common__order-by--active': reportsList.dateOrder === 'modificationDate'}"
                                      (click)="orderDate('modificationDate')"></i>
                              </div>
                          </div>

                          <div class="reports-list__col-new-version reports-list__col-new-version--actions">
                              <p class="common__table-header-text common__table-header-text--right"
                                  [afTranslate]="'common.actions'">Acciones</p>
                          </div>
                      </div>
                      <div class="common__table-body common__table-body--10-elements"
                          [ngClass]="{'common__table-body--more-elements': reportsList.filterReportsList.length > 10}">
                          <div class="common__table-row"
                              *ngFor="let report of reportsList.filterReportsList | byWord:reportsList.searchReport:['title', 'name', 'evaluationName'] | afOrderBy:reportsList.dateOrder:reportsList.orderOperationDates | afLimitTo:reportsList.pageSize:reportsList.pageSize*(reportsList.currentPage - 1); let index = index">
                              <div class="reports-list__col-new-version reports-list__col-new-version--title-v2 reports-list__col-new-version--first reports-list__col-new-version--tooltip"
                                  (click)="openReport(report)">
                                  <parragraph-tooltip [text]="report.title"></parragraph-tooltip>
                              </div>
                              <div class="reports-list__col-new-version reports-list__col-new-version--author reports-list__col-new-version--tooltip">
                                  <parragraph-tooltip [text]="report.author"></parragraph-tooltip>
                              </div>
                              <div class="reports-list__col-new-version reports-list__col-new-version--creation reports-list__col-new-version--tooltip">
                                  <parragraph-tooltip [text]="report.creationDate | convertDate:'without-time'"></parragraph-tooltip>
                              </div>
                              <div class="reports-list__col-new-version reports-list__col-new-version--modification reports-list__col-new-version--tooltip">
                                  <parragraph-tooltip [text]="report.modificationDate | convertDate:'without-time'"></parragraph-tooltip>
                              </div>
                              <div class="reports-list__col-new-version reports-list__col-new-version--actions-body">
                                  <ul class="common__options-row">
                                      <li class="common__menu-options-row"
                                          (click)="openReport(report)"
                                          matTooltip="{{(reportsList.userPermissions !== 'none'?'common.edit':'common.view') | afTranslate}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above">
                                          <i [ngClass]="reportsList.userPermissions !== 'none'?'lf-icon-editable-form':'lf-icon-visible'"></i>
                                      </li>
                                      <li class="common__menu-options-row"
                                          *ngIf="reportsList.userPermissions !== 'none'"
                                          (click)="downloadReport(report)"
                                          matTooltip="{{'expanded-menu.download' | afTranslate}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above">
                                          <i class="lf-icon-download"></i>
                                      </li>
                                      <li class="common__menu-options-row"
                                          *ngIf="reportsList.userPermissions !== 'none' && (reportsList.skin != 'icam-red' || report.customReportId)"
                                          (click)="deleteReport(report)"
                                          matTooltip="{{'expanded-menu.delete' | afTranslate}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above">
                                          <i class="lf-icon-trash"></i>
                                      </li>
                                  </ul>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="common__block-footer-table">
                  <div class="common__block-search">
                      <input type="text"
                          [ngShow]="reportsList.search"
                          class="common__search-input"
                          [(ngModel)]="reportsList.searchReport"
                          [ngModelOptions]="{standalone: true}"
                          placeholder="{{'common.search' | afTranslate}}">
                      <span class="common__text-search"
                          [ngHide]="reportsList.search"
                          (click)="toggleSearch()"
                          [afTranslate]="'common.search'">_Buscar</span>
                      <button class="lf-icon-search common__icon-button common__icon-button-search"
                          [ngClass]="{'lf-icon-search': !reportsList.search,
                              'lf-icon-close-search': reportsList.search,
                              'common__icon-button-search--open': reportsList.search}"
                          (click)="toggleSearch()">
                      </button>
                  </div>
                  <pagination-control class="common__pagination-block"
                    [ngShow]="reportsList.amountElements > reportsList.configPagination.pageSize"
                    [config]="reportsList.configPagination"
                    [collectionSize]="reportsList.amountElements"
                    [(currentPage)]="reportsList.currentPage"
                    (configuredTotalChange)="reportsList.configuredTotal = $event.configuredTotal">
                  </pagination-control>
                  <div class="common__block-footer-total">
                      <span [ngShow]="reportsList.amountElements > reportsList.configPagination.pageSize">
                        <span class="notranslate">{{reportsList.configuredTotal.first}}-{{reportsList.configuredTotal.last}}</span>
                        <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                        <span class="notranslate">{{reportsList.amountElements}}</span>
                      </span>
                      <span [ngShow]="!(reportsList.amountElements > reportsList.configPagination.pageSize)">
                        <span [afTranslate]="'common.total'">_Total</span>
                        <span class="common__total-number-element notranslate">{{reportsList.amountElements}}</span>
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
