<div class="evaluation"
  resizeWindow
  (hasResize)="hasResize($event)">
  <div [ngShow]="evaluation.folderSelect && evaluation.folderSelect.showHelpTooltip && !evaluation.rolesDesassigned"
      class="evaluation__background-blocked">
  </div>
  <header-nav></header-nav>
  <div class="evaluation__evaluation-block">
      <div class="evaluation__evaluation-menu"
          [ngClass]="{'evaluation__evaluation-menu--short-menu':!evaluation.openMenu,
                  'evaluation__evaluation-menu--expand-menu':evaluation.openMenu}">
          <evaluation-menu [evaluationTree]="evaluation.evaluationTree"
              [evaluationInfo]="evaluation.evaluation"
              [fullMenu]="evaluation.openMenu"
              [evaluatedConcept]="evaluation.evaluatedConceptInfo"
              [folderSelect]="evaluation.folderSelect"
              [showHidden]="evaluation.showHidden"
              (sendAction)="launchActionOn($event)"
              (minimizeMenu)="minimizeMenu()"></evaluation-menu>
      </div>
      <div class="evaluation__info-block"
          [ngClass]="{'evaluation__info-block--large-block':evaluation.openMenu,
              'evaluation__info-block--float-block':!evaluation.openMenu}"
          [ngStyle]="{'height': evaluation.heightBlock}">
          <evaluation-header
              [evaluationInfo]="evaluation.evaluation"
              [evaluationTree]="evaluation.evaluationTree"
              [draftEvaluations]="evaluation.draftEvaluations"
              [evaluatedConceptInfo]="evaluation.evaluatedConceptInfo"
              [folderSelect]="evaluation.folderSelect"
              [changeBreadcrumb]="evaluation.changeBreadcrumb"
              (sendAction)="launchActionOn($event)"
              (returnBreadcrumbList)="getBreadcrumbList($event)">
          </evaluation-header>
          <div class="evaluation__window"
              id="concept-to-show"
              *ngIf="evaluation.evaluationTree">
              <router-outlet #evaluationRouter="outlet"></router-outlet>
          </div>
      </div>
  </div>
  <compliance-footer from="evaluation.product"></compliance-footer>
</div>

