<div class="instant-videoconferences common__panel">
    <header-nav></header-nav>
    <div class="common__block-button-close-window">
        <button (click)="changeViewWindow()"
            class="common__back-link">
            <span [afTranslate]="'instant-videoconferences.close-videoconferences'">_CERRAR VIDEOCONFERENCIAS</span>
            <i class="lf-icon-close common__back-link-icon"></i>
        </button>
    </div>

    <div class="global-customization__block-container">
        <div class="common__block-list-new-version common__block-list-new-version--height-auto">
            <div class="common__header-new-version">
                <i class="lf-icon-videoconference common__header-icon-new-version"></i>
                <span class="common__header-title-new-version"
                    [afTranslate]="'header-nav.instant-videoconferences'">_Videoconferencias instantáneas</span>
            </div>
            <div class="instant-videoconferences__form-body">
                <button class="lf-icon-sum common__button-checked instant-videoconferences__button-add"
                    (click)="addVideoconference($event)"></button>
                <div class="instant-videoconferences__block-table">
                    <div class="common__table-header">
                        <div class="instant-videoconferences__col instant-videoconferences__col--title instant-videoconferences__col--top-1">
                            <filter-by-search
                                [item]="instantVideoconferences.titleFilter"
                                (launchActionSearch)="orderBySearch($event, 'title')">
                            </filter-by-search>
                        </div>
                        <div class="instant-videoconferences__col instant-videoconferences__col--participant instant-videoconferences__col--top-1">
                            <filter-by-list [type]="'listFind'"
                                [item]="instantVideoconferences.participantsFilter"
                                [widthClass]="'200px'"
                                [list]="instantVideoconferences.participantsList"
                                (filterElementEmit)="selectTypeOperation($event.source, 'participants')">
                            </filter-by-list>
                        </div>
                        <div class="instant-videoconferences__col instant-videoconferences__col--date instant-videoconferences__col--top-1">
                            <div (click)="orderDate()">
                                <p class="common__table-header-text"
                                    [afTranslate]="'task-edit.meeting-date'">_Fecha de reunión</p>
                                <i class="lf-icon-arrow-down common__order-by common__order-by--active"
                                    [ngClass]="{'lf-icon-arrow-up': !instantVideoconferences.orderOperationDate}"></i>
                            </div>
                        </div>
                        <div class="instant-videoconferences__col instant-videoconferences__col--location instant-videoconferences__col--top-1">
                            <filter-by-search
                                [item]="instantVideoconferences.locationFilter"
                                (launchActionSearch)="orderBySearch($event, 'location')">
                            </filter-by-search>
                        </div>
                        <div class="instant-videoconferences__col instant-videoconferences__col--url">
                            <p class="common__table-header-text"
                                [afTranslate]="'instant-videoconferences.url'">_URL de sala</p>
                        </div>
                    </div>
                    <div class="instant-videoconferences__block-body-table">
                        <div class="instant-videoconferences__block-img"
                            [ngShow]="instantVideoconferences.searchingList">
                            <img [src]="instantVideoconferences.loaderImg"
                                alt="spinner"
                                class="instant-videoconferences__spinner-loader"/>
                        </div>
                        <div class="common__table-row"
                            [ngClass]="{'instant-videoconferences__row-body-hidden': instantVideoconferences.searchingList}"
                            *ngFor="let videoconference of instantVideoconferences.filterListVideoconferences | afOrderBy:instantVideoconferences.typeFilter:instantVideoconferences.orderOperationDate | afLimitTo:instantVideoconferences.configPagination.pageSize:instantVideoconferences.configPagination.pageSize*(instantVideoconferences.currentPage - 1);">
                            <div class="instant-videoconferences__col instant-videoconferences__col--title instant-videoconferences__col--body">
                                <div class="common__cell-block">
                                    <parragraph-tooltip [text]="videoconference.title"></parragraph-tooltip>
                                </div>
                            </div>
                            <div class="instant-videoconferences__col instant-videoconferences__col--participant">
                                <participants-list *ngIf="videoconference.participants.length"
                                                    [participants]="videoconference.participants"
                                                    [type]="'videoconferences'">
                                </participants-list>
                            </div>
                            <div class="instant-videoconferences__col instant-videoconferences__col--date instant-videoconferences__col--body">
                                <parragraph-tooltip [text]="videoconference.dateMeeting | convertDate:''"></parragraph-tooltip>
                            </div>
                            <div class="instant-videoconferences__col instant-videoconferences__col--location instant-videoconferences__col--body">
                                <div class="common__cell-block">
                                    <parragraph-tooltip [text]="videoconference.location"></parragraph-tooltip>
                                </div>
                            </div>
                            <div class="instant-videoconferences__col instant-videoconferences__col--url instant-videoconferences__col--body">
                                <div class="common__cell-block">
                                  <parragraph-tooltip [ngClass]="{'instant-videoconferences__col-text-link': videoconference.copy}"
                                      (click)="openUrl(videoconference.url, videoconference.copy)"
                                      [text]="videoconference.url"></parragraph-tooltip>
                                    <i class="lf-icon-paste instant-videoconferences__icon-copy"
                                        [ngShow]="videoconference.copy"
                                        (click)="copyText(videoconference.url)"
                                        matTooltip="{{'concept-share.copy' | afTranslate}}"
                                        matTooltipPosition="above"
                                        matTooltipClass="above"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="instant-videoconferences__pagination-block">
                    <pagination-control class="clients-tracking__pagination-block"
                        [ngShow]="instantVideoconferences.filterListVideoconferences.length > instantVideoconferences.configPagination.pageSize"
                        [config]="instantVideoconferences.configPagination"
                        [collectionSize]="instantVideoconferences.filterListVideoconferences.length"
                        [(currentPage)]="instantVideoconferences.currentPage">
                    </pagination-control>
                    <div class="common__block-footer-total common__block-footer-total--bottom-2">
                        <span [afTranslate]="'common.total'">_Total</span>
                        <span class="common__total-number-element notranslate">{{instantVideoconferences.filterListVideoconferences.length}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
