<div class="documentation-list common__block-concept"
    id="documentation-list"
    [ngClass]="{'common__block-concept--border': currentComponent === 'concept',
            'documentation-list--no-border': documentationList.showDropZone}"
    dropZone
    (fileDropped)="dropNewDocument($event)"
    (dragOverElement)="dragInABox($event)">
    <div class="documentation-list__dropzone documentation-list__dropzone"
        resizeWindow
        [ngShow]="documentationList.showDropZone">
        <i class="lf-icon-drag-drop documentation-list__dropzone-icon"></i>
        <p class="documentation-list__dropzone-text "
            [afTranslate]="'documentation-list.dropzone-info'">__Arrastra aquí nuevos documentos para añadirlos al listado </p>
    </div>
    <div [ngShow]="documentationList.showTrash"
        class="documentation-list__trash-panel">
        <button class="documentation-list__trash-panel-back-button pull-left"
            (click)="viewArchived()">
            <i class="lf-icon-arrow-back folder__arrow-back-icon"></i>
            <span class="documentation-list__arrow-back-text"
                [afTranslate]="'documentation-list.exit-trash'">__Salir de la papelera</span>
        </button>
        <p class="documentation-list__trash-panel-text"
            [afTranslate]="'documentation-list.info-trash'">__Los documentos que lleven más de 30 dias en la papelera de reciclaje se eliminarán automáticamente</p>
        <div class="float-end documentation-list__trash-panel-delete-icon"
            (click)="fullDelete()"
            [ngShow]="userPermissions !== 'none'"
            matTooltip="{{documentationList.deleteBin}}"
            matTooltipPosition="above"
            matTooltipClass="above">
            <i class="lf-icon-empty-trash"></i>
        </div>
        <p class="documentation-list__trash-panel-deleted-amount float-end" >
            <span [afTranslate]="documentationList.archivedCount === 1?'documentation-list.there-is':'documentation-list.there-are'">__Hay</span>
            {{documentationList.archivedCount}}
            <span [afTranslate]="documentationList.archivedCount === 1?'documentation-list.document-deleted-pl':'documentation-list.document-deleted-sn'">__documentos archivados</span>
        </p>
    </div>
    <div class="documentation-list__block-body"
        [ngClass]="{'documentation-list__block-body--big-window': currentComponent === 'conceptWindow'}">
        <div class="documentation-list__documentation-header"
            [ngHide]="documentationList.showTrash && userPermissions === 'none'">
            <div class="documentation-list__menu-block">
                <field-type-switch class="documentation-list__multiple-switch"
                    [label]="documentationList.checkMultiple"
                    [checked]="documentationList.multipleSelection"
                    (callback)="toggleSwitchMultiple($event)">
                </field-type-switch>
            </div>
            <div class="documentation-list__menu-block-center">
                <div class="documentation-list__block-multiple-options"
                    [ngClass]="{'documentation-list__block-multiple-options--trash': documentationList.showTrash}"
                    [ngShow]="documentationList.multipleSelection && documentationList.documentChecked > 1"
                    ngbDropdown
                    [autoClose]="'outside'">
                    <button class="lf-icon-rotate documentation-list__button-box-none"
                        [ngShow]="documentationList.showTrash && userPermissions !== 'none'"
                        (click)="launchAction($event, 'restore-some')"
                        matTooltip="{{'common.restore' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <button class="lf-icon-share documentation-list__button-box-none"
                        [ngShow]="!documentationList.showTrash"
                        (click)="launchAction($event, 'share-some')"
                        matTooltip="{{'common.share' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <button class="lf-icon-download documentation-list__button-box-none"
                        [ngShow]="!documentationList.showTrash"
                        (click)="launchAction($event, 'download-some')"
                        matTooltip="{{'expanded-menu.download' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <button class="lf-icon-arrows documentation-list__button-box-none"
                        [ngShow]="!documentationList.showTrash && userPermissions !== 'none'"
                        (click)="launchAction($event, 'move-some')"
                        matTooltip="{{'common.move' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <button class="lf-icon-trash documentation-list__button-box-none "
                        (click)="launchAction($event, 'delete-some')"
                        [ngShow]="!documentationList.showTrash && userPermissions !== 'none'"
                        matTooltip="{{'expanded-menu.delete' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <button class="lf-icon-trash  documentation-list__button-box-none "
                        [ngShow]="documentationList.showTrash && userPermissions !== 'none'"
                        (click)="launchAction($event, 'definitive-delete-some')"
                        matTooltip="{{'expanded-menu.delete' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </button>
                    <div class="documentation-list__menu-tooltip-multiple"
                        ngbDropdownMenu role="menu">
                        <div class="documentation-list__option-menu-multiple"
                            *ngFor="let optionTooltip of documentationList.menuMultipleSignature; let last = last">
                            <button class="documentation-list__list-menu-option-multiple"
                                [ngClass]="{'documentation-list__list-menu-option-multiple--last': last}"
                                (click)="launchAction($event, optionTooltip.action)">
                                <span [afTranslate]="optionTooltip.name"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="documentation-list__menu-block documentation-list__menu-block--right"
                [ngShow]="!documentationList.showTrash">
                <button class="common__button-border-none"
                    [ngShow]="documentationList.showButtonTrash"
                    (click)="viewArchived()">
                    <i class="lf-icon-trash common__icon-button-underlined"></i>
                    <span class="common__text-button-underlined"
                        [afTranslate]="'documentation-list.view-trash'">_Ver papelera</span>
                </button>
                <button class="lf-icon-send common__button-checked"
                    (click)="exportList()"
                    matTooltip="{{'documentation-list.export-documentation' | afTranslate}}"
                    matTooltipPosition="below"
                    matTooltipClass="below">
                </button>
                <button class="lf-icon-refresh common__button-checked"
                    (click)="updateDocumentationList()"
                    matTooltip="{{'documentation-list.update-documentation' | afTranslate}}"
                    matTooltipPosition="below"
                    matTooltipClass="below">
                </button>
                <div class="documentation-list__block-header-buttons"
                    [ngHide]="userPermissions ==='none'">
                    <button class="lf-icon-import-doc common__button-checked"
                        (click)="duplicateDocument()"
                        matTooltip="{{'documentation-list.add-documents' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </button>
                    <button class="lf-icon-up-file common__button-checked"
                        (click)="upDocument()"
                        matTooltip="{{'documentation-list.upload-button' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </button>
                    <button class="lf-icon-sum common__button-checked"
                        (click)="newDocument()"
                        matTooltip="{{'documentation-list.create-button' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </button>
                    <button class="lf-icon-folder-new common__button-checked"
                        (click)="newFolder()"
                        matTooltip="{{'expanded-menu.new-folder' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </button>
                </div>
            </div>
        </div>
        <div class="documentation-list__bloque-miga">
            <div class="documentation-list__miga">
                <i class="lf-icon-map-marker documentation-list__icon-mark"></i>
                <div class="documentation-list__block-breadcrumbs">
                    <div *ngFor="let breadcrumb of documentationList.breadcrumbList; let first = first; let last = last; let index = index"
                        class="documentation-list__element-breadcrumb"
                        (click)="selectFolder(breadcrumb)"
                        [ngClass]="{'duplicate-documentation__title-breadcrumb--no-cursor': last}">
                        <div class="common__cell-block">
                            <span [ngShow]="!first"
                                class="documentation-list__symbol-minus"> > </span>
                            <p class="documentation-list__text-breadcrumb">
                                <span [ngClass]="{'duplicate-documentation__underlined-text': !last}">
                                    {{breadcrumb.name}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="documentation-list__fixed-aux"></div>
        </div>
        <div class="documentation-list__block"
            id="documentation-list-dropzone"
            [ngClass]="{'documentation-list__block--opacity': documentationList.showDropZone}">
            <!-- tabla -->
            <div class="documentation-list__table-container"
                [ngClass]="{'documentation-list__table-container--close': documentationList.concept.closeMenu && !documentationList.multipleSelection,
                    'documentation-list__table-container--close-multiple': documentationList.concept.closeMenu && documentationList.multipleSelection,
                    'documentation-list__table-container--multiple': !documentationList.concept.closeMenu && documentationList.multipleSelection,
                    'documentation-list__table-container--no-has-processes': !documentationList.hasProcesses}">
                <!-- Cabecera -->
                <div class="common__table-header">
                    <div class="documentation-list__table-header documentation-list__table-header--multiple-selection"
                        [ngShow]="documentationList.multipleSelection">
                        <label for="multiple-selection-all-select"
                            class="documentation-list__label-multiple">
                            <input class="documentation-list__input-multiple"
                                type="checkbox"
                                id="multiple-selection-all-select"
                                (click)="selectAllDocumentCheck()"
                                [checked]="documentationList.selectAllDocument">
                            <i class="documentation-list__icon-multiple"
                                [ngClass]="documentationList.selectAllDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                        </label>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--format"
                        [ngClass]="{'documentation-list__table-header--format-close': documentationList.concept.closeMenu}">
                        <filter-by-list [type]="'list'"
                            [item]="documentationList.extensionFilter"
                            [list]="documentationList.extensionList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation( $event, 'extension')">
                        </filter-by-list>
                    </div>
                    <div class=" documentation-list__table-header documentation-list__table-header--title">
                        <filter-by-search
                            [item]="documentationList.nameFilter"
                            [filterOrder]="documentationList.dateOrder"
                            [order]="documentationList.orderOperationDates"
                            [filterText]="documentationList.nameWordFilter"
                            (launchActionSearch)="orderBySearch($event, 'name')">
                        </filter-by-search>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--source"
                        [ngClass]="{'documentation-list__table-header--source-close': documentationList.concept.closeMenu}">
                        <filter-by-list [type]="'list'"
                            [item]="documentationList.sourceFilter"
                            [list]="documentationList.sourceList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'source')">
                        </filter-by-list>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--creation-date"
                        [ngClass]="{'documentation-list__table-header--creation-date-close': documentationList.concept.closeMenu}">
                        <span-tooltip [text]="'common.creation' | afTranslate"
                          class="common__table-header-text common__cursor-pointer common__table-header-text--order"
                          (click)="orderDate('created')"></span-tooltip>
                        <i class="common__order-by"
                            [ngClass]="{'lf-icon-arrow-down':(documentationList.dateOrder==='created' && documentationList.orderOperationDates) || documentationList.dateOrder!=='created',
                                'lf-icon-arrow-up': documentationList.dateOrder==='created' && !documentationList.orderOperationDates,
                                'common__order-by--active': documentationList.dateOrder==='created'}"
                            (click)="orderDate('created')"></i>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--author"
                        [ngClass]="{'documentation-list__table-header--author-close': documentationList.concept.closeMenu}">
                        <filter-by-list [type]="'listFind'"
                            [item]="documentationList.createdByFilter"
                            [list]="documentationList.createdByList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'createdBy')">
                        </filter-by-list>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--modification-date"
                        [ngClass]="{'documentation-list__table-header--modification-date-close': documentationList.concept.closeMenu}">
                        <span-tooltip [text]="(documentationList.showTrash?'documentation-list.deleted': 'common.modification') | afTranslate"
                          class="common__table-header-text common__cursor-pointer common__table-header-text--order"
                          (click)="orderDate('modified')"></span-tooltip>
                        <i class="common__order-by"
                            [ngClass]="{'lf-icon-arrow-down':(documentationList.dateOrder==='modified' && documentationList.orderOperationDates) || documentationList.dateOrder!=='modified',
                                'lf-icon-arrow-up': documentationList.dateOrder==='modified' && !documentationList.orderOperationDates,
                                'common__order-by--active': documentationList.dateOrder==='modified'}"
                            (click)="orderDate('modified')"></i>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--author"
                        [ngClass]="{'documentation-list__table-header--author-close': documentationList.concept.closeMenu}">
                        <filter-by-list [type]="'listFind'"
                            [ngShow]="!documentationList.showTrash"
                            [item]="documentationList.modifiedByFilter"
                            [list]="documentationList.modifiedByList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'modifiedBy')">
                        </filter-by-list>
                        <filter-by-list [type]="'listFind'"
                            [ngShow]="documentationList.showTrash"
                            [item]="documentationList.deletedByFilter"
                            [list]="documentationList.modifiedByList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'modifiedBy')">
                        </filter-by-list>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--state"
                        [ngClass]="{'documentation-list__table-header--state-close': documentationList.concept.closeMenu}">
                        <filter-by-list [type]="'list'"
                            [item]="documentationList.statusFilter"
                            [list]="documentationList.statusList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'status')">
                        </filter-by-list>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--state-process"
                        [ngClass]="{'documentation-list__table-header--state-process-close': documentationList.concept.closeMenu}"
                        *ngIf="documentationList.hasProcesses">
                        <filter-by-list [type]="'list'"
                            [item]="documentationList.stateProcessFilter"
                            [list]="documentationList.stateProcessList"
                            [topStyle]="'29'"
                            (filterElementEmit)="selectTypeOperation($event, 'stateProcess')">
                        </filter-by-list>
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--confidential">
                    </div>
                    <div class="documentation-list__table-header documentation-list__table-header--certified">
                    </div>
                </div>
                <!-- Contenido -->
                <div class="documentation-list__table-body">
                    <div id="row-{{index}}"
                        class="documentation-list__height-tab "
                        [ngClass]="{'documentation-list__height-tab--folder':document.extension==='folder',
                            'documentation-list__height-tab--selected':(document.selectedDocument || document.openOption || document.hoverRow || document.showDropdownConfidential) && document.extension !=='folder',
                            'documentation-list__height-tab--folder-selected': (document.openOption || document.hoverRow || document.showDropdownConfidential) && document.extension==='folder',
                            'documentation-list__height-tab--anonymatum': document.anonymized}"
                        *ngFor="let document of documentationList.filterDocumentationList | afOrderBy:documentationList.dateOrder:documentationList.orderOperationDates; let index = index">
                        <div class="documentation-list__table documentation-list__table--multiple-selection"
                            *ngIf="documentationList.multipleSelection">
                            <label [for]="'multiple-selection-'+index+'-select'"
                                [ngShow]="document.extension !== 'folder'"
                                class="documentation-list__label-multiple">
                                <input class="documentation-list__input-multiple"
                                    type="checkbox"
                                    [id]="'multiple-selection-'+index+'-select'"
                                    (click)="selectMultipleDocument(document)"
                                    [checked]="document.selectedDocument">
                                <i class="documentation-list__icon-multiple"
                                    [ngClass]="document.selectedDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                            </label>
                        </div>
                        <div class="documentation-list__table documentation-list__table--format"
                            [ngClass]="{'documentation-list__table--format-close': documentationList.concept.closeMenu}">
                            <i [ngShow]="document.extension !== 'folder'"
                                [class]="'documentation-list__table-icon '+(document.extension | extensionDocument)"></i>
                            <i [ngShow]="document.extension === 'folder'"
                                class="lf-icon-folder documentation-list__table-icon documentation-list__table-icon--folder"></i>
                            <i [ngShow]="document.extension === 'folder'"
                                class="lf-icon-angle-right documentation-list__table-icon documentation-list__table-icon--angle"></i>
                        </div>
                        <div class="documentation-list__table documentation-list__table--title">
                            <div class="common__cell-block"
                                [ngClass]="{'common__cursor-pointer':document.extension==='folder'}"
                                (click)="openFolder(document)">
                                <parragraph-tooltip [text]="document.name">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div class="documentation-list__table documentation-list__table--source"
                            [ngClass]="{'documentation-list__table--source-close': documentationList.concept.closeMenu}">
                            <div class="documentation-list__first-column-icon">
                                <i [class]="'documentation-list__table-icon '+(document.source | taskTypeIcons:'document')"></i>
                            </div>
                            <div class="documentation-list__first-column-text">
                                <div class="common__cell-block">
                                    <parragraph-tooltip [text]="document.sourceName">
                                    </parragraph-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="documentation-list__table documentation-list__table--creation-date"
                            [ngClass]="{'documentation-list__table--creation-date-close': documentationList.concept.closeMenu}">
                            <span class="common__col-text">{{document.created | convertDate:'without-time'}}</span>
                        </div>
                        <div class="documentation-list__table documentation-list__table--author"
                            [ngClass]="{'documentation-list__table--author-close': documentationList.concept.closeMenu}">
                            <div class="common__cell-block">
                                <parragraph-tooltip [text]="document.createdBy">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div [ngShow]="!documentationList.showTrash"
                            class="documentation-list__table documentation-list__table--modification-date"
                            [ngClass]="{'documentation-list__table--modification-date-close': documentationList.concept.closeMenu}">
                            {{document.modified | convertDate:'without-time'}}
                        </div>
                        <div [ngShow]="documentationList.showTrash"
                            class="documentation-list__table documentation-list__table--modification-date"
                            [ngClass]="{'documentation-list__table--modification-date-close': documentationList.concept.closeMenu}">
                            {{document.deleted | convertDate:''}}
                        </div>
                        <div [ngShow]="!documentationList.showTrash"
                            class="documentation-list__table documentation-list__table--author"
                            [ngClass]="{'documentation-list__table--author-close': documentationList.concept.closeMenu}">
                            <div class="common__cell-block">
                                <parragraph-tooltip [text]="document.modifiedBy">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div [ngShow]="documentationList.showTrash"
                            class="documentation-list__table documentation-list__table--author"
                            [ngClass]="{'documentation-list__table--author-close': documentationList.concept.closeMenu}">
                            <div class="common__cell-block">
                                <parragraph-tooltip [text]="document.deletedBy">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div class="documentation-list__table documentation-list__table--state"
                            [ngClass]="{'documentation-list__table--state-close': documentationList.concept.closeMenu}">
                            <div class="common__cell-block">
                                <parragraph-tooltip [text]="document.status">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div class="documentation-list__table documentation-list__table--state-process"
                            [ngClass]="{'documentation-list__table--state-process-close': documentationList.concept.closeMenu}"
                            *ngIf="documentationList.hasProcesses">
                            <div class="common__cell-block">
                                <parragraph-tooltip [text]="document.state?.name">
                                </parragraph-tooltip>
                            </div>
                        </div>
                        <div class="documentation-list__table documentation-list__table--confidential">
                            <div *ngIf="document.confidential"
                                ngbDropdown
                                [id]="'documentation-list-confidential-'+index"
                                [autoClose]="'outside'">
                                <i class="lf-icon-lock documentation-list__icon-confidential"
                                    ngbDropdownToggle
                                    matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above"
                                    (click)="openDropdownConfidential(index, document)"></i>
                                <list-in-dropdown
                                    ngbDropdownMenu role="menu"
                                    class="dropdown-menu documentation-list__box-select-list"
                                    [ngClass]="document.classUpDropdown?'documentation-list__box-select-list--up-dropdown':'documentation-list__box-select-list--normal'"
                                    [list]="document.confidentialList"
                                    [type]="'confidential'"
                                    (closeDropdownEmit)="closeDropdown('documentation-list-confidential-'+index)">
                                </list-in-dropdown>
                            </div>
                        </div>

                        <div class="documentation-list__table documentation-list__table--certified">
                            <i class="lf-icon-certificate documentation-list__icon-certified"
                                [ngShow]="skin !== 'icam-red' && (document.sendedAsCertifiedEmail || document.sendedAsCertifiedSMS)"
                                matTooltip="{{'documentation-list.certified-shipping' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above">
                            </i>
                            <i class="lf-icon-transparency-report documentation-list__icon-certified"
                                [ngShow]="document.publishedForApplicant || document.publishedAuto"
                                matTooltip="{{'documentation-list.visible-in-monitoring' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above">
                            </i>
                            <i class="lf-icon-anonymous documentation-list__icon-certified"
                                [ngShow]="document.anonymized"
                                matTooltip="{{'documentation-list.document-anonymatum' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above">
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="documentation-list__block-action"
            [ngClass]="{'documentation-list__block-action--litle-margin': currentComponent === 'conceptWindow' || skin === 'dark-blue'}">
            <div class="documentation-list__action-fixed documentation-list__action-fixed--header">
                <p class="common__table-header-text common__table-header-text--center common__ellipsis"
                    [afTranslate]="'common.actions'">Acciones</p>
            </div>
            <div class="documentation-list__block-repeat-action">
                <div *ngFor="let documentActions of documentationList.filterDocumentationList | afOrderBy:documentationList.dateOrder:documentationList.orderOperationDates">
                    <div class="documentation-list__action-fixed"
                        ngbDropdown
                        (openChange)="openDocumentationMenu($event, documentActions)"
                        [open]="documentActions.openOption">
                        <button class="documentation-list__menu-action"
                                [ngClass]="{'lf-icon-kebab-menu': !(documentationList.showTrash && userPermissions === 'none' && documentActions.extension !== 'folder'),
                                  'documentation-list__menu-action--disabled': userPermissions === 'none' && documentActions.notForReadOnly
                                }"
                                ngbDropdownToggle
                                [disabled]="(documentationList.showTrash && userPermissions === 'none' && documentActions.extension !== 'folder') || (userPermissions === 'none' && documentActions.notForReadOnly)"
                                (mouseover)="hoverRowAction(documentActions, true)"
                                (mouseout)="hoverRowAction(documentActions, false)"></button>
                        <ul class="documentation-list__list-menu-expanded"
                            ngbDropdownMenu
                            role="menu"
                            [ngShow]="documentActions.showMenu"
                            [ngClass]="{'documentation-list__list-menu-expanded--no-border': documentActions.menu?.length === 0}">
                            <li class="documentation-list__title-menu-option"
                                [ngShow]="documentActions.selectedDocument && documentationList.documentChecked > 1">
                                <span>{{documentationList.textDocumentSelected}}</span>
                            </li>
                            <li [class]="'common__list-dropdown-option common__list-dropdown-option--'+optionMenu.action"
                                *ngFor="let optionMenu of documentActions.menu; let last = last; let index = index"
                                [ngClass]="{'common__list-dropdown-option--selected': optionMenu.selected}">
                                <button class="common__button-option-list-dropdown"
                                    [ngClass]="{'common__button-option-list-dropdown--last': last,
                                        'common__button-option-list-dropdown--expanded': optionMenu.expanded}"
                                    (mouseover)="showSubMenu($event, optionMenu, documentActions, index)"
                                    (click)="launchAction($event, optionMenu.action, documentActions)">
                                    <i [class]="'common__icon-option-list-dropdown '+optionMenu.icon"></i>
                                    <span class="common__text-menu-option-dropdown"
                                        [afTranslate]="optionMenu.name"></span>
                                    <i class="lf-icon-angle-right documentation-list__icon-more-expanded"
                                        [ngShow]="optionMenu.expanded"></i>
                                </button>
                            </li>
                        </ul>
                        <ul class="documentation-list__list-menu-expanded documentation-list__list-menu-expanded--submenu"
                            [ngShow]="documentActions.showMoreMenu"
                            [style.top]="documentationList.topSubmenu + 'px !important'">
                            <li class="common__list-dropdown-option"
                                *ngFor="let optionMenuExpanded of documentActions.menuExpanded; let last = last">
                                <button class="common__button-option-list-dropdown"
                                    [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                    (click)="launchAction($event, optionMenuExpanded.action, documentActions)">
                                    <i [class]="'common__icon-option-list-dropdown '+optionMenuExpanded.icon "></i>
                                    <span class="common__text-menu-option-dropdown"
                                        [afTranslate]="optionMenuExpanded.name"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="documentationList.configurationPdf"></pdf-table>
