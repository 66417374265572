<div class="customize-field-formula">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [(ngModel)]="customizeFieldFormula.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldFormula.tinyMceOptions"
      [modelText]="customizeFieldFormula.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldFormula.contentReportInfo"
            [preSelectItem]="customizeFieldFormula.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-number [field]="customizeFieldFormula.decimalsInfo"
            [preSelectItem]="customizeFieldFormula.field.decimals"
            (itemSelected)="itemSelected($event, 'decimals')">
        </field-type-number>
    </div>
    <div class="customize-concept__group-body customize-concept__group-body--properties">
        <h3 class="common__label-block common__label-block--one-icon customize-field-formula__label-block">
            <span class="common__input-text"
                [afTranslate]="'customize-field-formula.configuration-group'">Configuración de la fórmula</span>
            <span class="common__text-required">*</span>
        </h3>
        <i class="lf-icon-configuration customize-field-formula__icon-configure"
            [ngClass]="{'customize-field-formula__icon-configure--disabled': customizeFieldFormula.field.noEditable}"
            (click)="launchFormulaSettings()"></i>
        <div class="customize-field-formula__formula-block">
            <div class="customize-field-formula__formula-block__formula-config">
                <p class="customize-concept__help"
                    [afTranslate]="'customize-field-formula.user-help-config'">
                    Pulsa el botón para acceder al configurador de fórmulas. Desde ahí podrás crear y editar una operación matemática manejando los valores de diferentes campos del concepto.___
                </p>
            </div>
            <div class="customize-field-formula__result-block"
                [ngClass]="{'common__show-alert':customizeFieldFormula.formulaError}">
                <span class="customize-field-formula__result-text-title"
                    [ngClass]="{'customize-field-formula__result-text-title--required':customizeFieldFormula.formulaError}"
                    [afTranslate]="'customize.result'">Resultado:</span>
                <div class="customize-field-formula__block-result-text">
                    <span class="customize-field-formula__result-text"
                        [ngShow]="!customizeFieldFormula.formulaError"
                        [ngClass]="{'customize-field-formula__result-text-symbol':item.type ==='symbol'} "
                        *ngFor="let item of customizeFieldFormula.formulaItems">
                        <i [ngShow]="item.type !== 'fields'"
                            [class]="'formula-settings-modal__icon formula-settings-modal__icon--operator-simple '+ item.icon"></i>
                        <span [ngShow]="item.type === 'fields'">
                            {{item.text}}
                        </span>
                    </span>
                    <span class="customize-field-formula__result-text customize-field-formula__result-text--required"
                        [ngShow]="customizeFieldFormula.formulaError"
                        [afTranslate]="'customize-field-formula.message-required'">__Es obligatorio insertar una fórmula para poder guardar</span>
                </div>
            </div>
        </div>
    </div>
    <div class="customize-concept__group-body customize-concept__group-body--properties clearfix"
          [ngShow]="customizeFieldFormula.listValueColors.length">
        <button class="lf-icon-add-round common__icon-button customize-field-formula__button-add"
                [disabled]="customizeFieldFormula.field.noEditable || customizeFieldFormula.showNewEncoding"
                (click)="addEncoding()">
        </button>
        <h3 class="common__label-block common__label-block--one-icon customize-field-formula__label-block">
            <span class="common__input-text"
                [afTranslate]="'customize-field-formula.encoding-group'">Valores de codificación</span>
        </h3>
        <div class="customize-field-formula__group-body-help">
            <p class="customize-concept__help"
                [afTranslate]="'customize-field-formula.encoding-help1'">
                Define el mensaje y color que se mostrará al usuario en función de los valores obtenidos en la fórmula.
            </p>
            <p class="customize-concept__help"
                [afTranslate]="'customize-field-formula.encoding-help2'">
                Edita los siguientes campos y continua añadiendo tantos rangos de valor como necesites.
            </p>
        </div>
        <div [ngShow]="!customizeFieldFormula.showNewEncoding">
            <ul class="customize-field-formula__list-enconding-selected"
                [dragula]="'customizeEncodingItem'"
                [dragulaModel]="customizeFieldFormula.field.fieldEncodings"
                (dragulaModelChange)="encodingItemInserted($event)">
                <li *ngFor="let encodingItem of customizeFieldFormula.field.fieldEncodings; let index = index"
                    class="customize-field-formula__element-list-encoding">
                    <div class="customize-field-formula__table-display">
                        <div class="common__table-cell-tooltip">
                            <div class="customize-field-formula__cell-block">
                                <parragraph-tooltip [text]="encodingItem.displayText"></parragraph-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="customize-field-formula__block-right-element">
                        <span class="customize-field-formula__encoding-color-pill customize-field-formula__encoding-color-pill--list"
                              [ngStyle]="{'background-color': encodingItem.color}"></span>
                        <button class="lf-icon-arrows customize-field-formula__button-item-drag handleEncodingItem"></button>
                        <div class="customize-field-formula__selected-list-item-actions"
                            [ngShow]="!customizeFieldFormula.field.noEditable">
                            <i class="lf-icon-kebab-menu customize-field-formula__input-icon-menu"></i>
                            <div class="customize-field-formula__option-menu">
                                <i class="lf-icon-editable-form customize-field-list__input-icon"
                                    (click)="editEncodingItem(encodingItem)"></i>
                                <i class="lf-icon-trash customize-field-list__input-icon"
                                    (click)="deleteEncodingItem(encodingItem)"></i>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div [ngShow]="customizeFieldFormula.showNewEncoding">
            <div class="customize-field-formula__encoding-block-left">
                <div class="customize-field-formula__encoding-operator-field">
                    <field-type-list-simple [field]="customizeFieldFormula.operator1Field"
                        [preSelectItem]="customizeFieldFormula.editingEncodingItem.operator1"
                        (itemSelected)="encodingFieldChanged($event, 'operator1')"
                        [listValues]="customizeFieldFormula.operators1" >
                    </field-type-list-simple>
                </div>
                <div class="customize-field-formula__encoding-value-field">
                    <field-type-number [field]="customizeFieldFormula.value1Field"
                        [preSelectItem]="customizeFieldFormula.editingEncodingItem.value1"
                        (itemSelected)="encodingFieldChanged($event, 'value1')">
                    </field-type-number>
                </div>
                <div class="customize-field-formula__encoding-operator-field">
                    <field-type-list-simple [field]="customizeFieldFormula.operator2Field"
                        [preSelectItem]="customizeFieldFormula.editingEncodingItem.operator2"
                        [listValues]="customizeFieldFormula.operators2"
                        (itemSelected)="encodingFieldChanged($event, 'operator2')">
                    </field-type-list-simple>
                </div>
                <div class="customize-field-formula__encoding-value-field">
                    <field-type-number [field]="customizeFieldFormula.value2Field"
                        [preSelectItem]="customizeFieldFormula.editingEncodingItem.value2"
                        (itemSelected)="encodingFieldChanged($event, 'value2')"></field-type-number>
                </div>
                <div class="customize-field-formula__encoding-text-field">
                    <field-type-text-simple [field]="customizeFieldFormula.textField"
                        [preSelectItem]="customizeFieldFormula.editingEncodingItem.text"
                        (itemSelected)="encodingFieldChanged($event, 'text')">
                    </field-type-text-simple>
                </div>
            </div>
            <ul class="customize-field-formula__encoding-colors-palette">
                <li class="customize-field-formula__encoding-element-color-list customize-field-formula__encoding-color-pill"
                    *ngFor="let color of customizeFieldFormula.listValueColors"
                    (click)="customizeFieldFormula.editingEncodingItem.color = color.color"
                    [ngStyle]="{'background-color': color.color}"
                    [ngClass]="{'selected':  customizeFieldFormula.editingEncodingItem.color === color.color}"></li>
            </ul>
            <div class="customize-field-formula__econding-block-buttons">
                <button class="lf-icon-close common__button-close"
                    (click)="customizeFieldFormula.showNewEncoding = !customizeFieldFormula.showNewEncoding"></button>
                <button class="lf-icon-check common__button-checked"
                        [disabled]="customizeFieldFormula.editEncodingOkDisabled"
                        (click)="editEncodingOk()">
                </button>
            </div>
        </div>
    </div>
    <div class="common__buttons-block common__buttons-block--customize">
        <switch-option [ngShow]="concept.allowPublicShare"
            [tooltipText]="'customize.private-help'"
            [model]="isPrivate?isPrivate:field.private"
            [disabled]="concept.disabled || isPrivate"
            [fieldText]="'customize.private'"
            (changeOptionEmit)="changeOptionOn($event, 'private')">
        </switch-option>
        <switch-option [ngHide]="concept.allowPublicShare"
            [tooltipText]="'customize.hidden-landing-help'"
            [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
            [disabled]="hiddenInLandings"
            [fieldText]="'customize.hidden-landing'"
            (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
        </switch-option>
        <switch-option [model]="customizeFieldFormula.field.hidden"
            [fieldText]="'customize-field-formula.hide'"
            (changeOptionEmit)="changeOptionOn($event, 'hidden')">
        </switch-option>
        <switch-option [model]="customizeFieldFormula.field.nullsAsZero"
            [fieldText]="'customize-field-formula.null-as-zero'"
            (changeOptionEmit)="changeOptionOn($event, 'nullsAsZero')">
        </switch-option>
        <button type="reset"
                class="common__button common__button--clean"
                (click)="cancelFieldText()"
                [afTranslate]="'common.cancel'">
            Cancelar
        </button>
        <button type="submit"
                class="common__button common__button--active"
                (click)="saveFieldText()"
                [afTranslate]="'common.save'">
            Guardar
        </button>
    </div>
</div>
