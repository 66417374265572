import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'field-type-access',
  templateUrl: './field-type-access.component.html'
})
export class FieldTypeAccessComponent implements OnInit {
  @Input() field: any = {};
  @Input() concept: any = {};
  skin: any = this.globalCfg.skin;
  evaluationId: string = this.route.snapshot.params['evaluationId'];
  fieldTypeAccess: any = {
    evaluationTree: {},
    field: {
      label: this.translate.instant('field-type-access.go-client-label'),
      description: this.translate.instant('field-type-access.go-client-description'),
      noEditable: true
    }
  }
  constructor(private router: Router,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private globalCfg: GlobalCfgFactory,
    private conceptState: ConceptStateFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService) { }

  ngOnInit(): void {
    this.getDiligencesGroupId();
  }

  getDiligencesGroupId(){
    this.apiService.get('blanqueo/getdiligencesgroupid', this.evaluationId)
      .then((data:any)=> {
          this.fieldTypeAccess.idVersionGroup = data;
          this.getClientFolder();
      },(errorData:any)=>{})
  }

  getClientFolder(){
    let evaluationTree: any = sessionStorage.getItem('evaluationTree')
    this.fieldTypeAccess.evaluationTree = JSON.parse(evaluationTree);
    for(let i = 0; i < this.fieldTypeAccess.evaluationTree.children.length; i++){
        let folder = this.fieldTypeAccess.evaluationTree.children[i];
        if(folder.folderNamedKey === 'Cliente'){
            this.fieldTypeAccess.clientFolder = folder;
            this.fieldTypeAccess.clientConcept = folder.concepts[0];
            this.fieldTypeAccess.field.noEditable = false;
            break;
        }
    }
  }

  accessRoute(){
    if(this.conceptState.getAmount() || this.conceptState.getProcess()){
      this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'),  'info:exit:question')
        .subscribe((result:any)=>{
          if(result.result !== 'cancel'){
              if(result.result === 'save-exit'){
                  sessionStorage.setItem('idVersionGroup', this.fieldTypeAccess.idVersionGroup);
                  this.conceptState.removeAll();
                  this.conceptState.setProcess(false);
                  this.broadcastService.broadcast('saveConceptToEvaluation', {from:'fieldTypeAccess', option: 'open', folder: this.fieldTypeAccess.clientFolder, concept: this.fieldTypeAccess.clientConcept});
              }else{
                  this.conceptState.removeAll();
                  this.conceptState.setProcess(false);
                  this.goToClientConcept();
              }
          }
        })
    }else{
        this.goToClientConcept();
    }
  }

  goToClientConcept(){
      sessionStorage.setItem('idVersionGroup', this.fieldTypeAccess.idVersionGroup);
      this.loader.openLoader('folder-open-concept');
      this.router.navigate(['evaluation', this.evaluationId, 'folder', this.fieldTypeAccess.clientFolder.folderId]);
      this.broadcastService.broadcast('changeFolderEvaluation', {folderId: this.fieldTypeAccess.clientFolder.folderId});
      $('#compliance-view-body').scrollTop(0);
  }
}
