import moment from 'moment';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'compliance-footer',
  templateUrl: './compliance-footer.component.html'
})
export class ComplianceFooterComponent implements OnInit, OnChanges {
  skin:string = this.globalCfg.skin;
  showOtherRRSS:boolean = (this.skin === 'icam-red' || this.skin ==='gm-law');
  rrss:any=['linkedin','facebook','twitter', 'youtube'];
  isFrontLogin: boolean = this.route.snapshot.data['name'] === 'login'
  complianceFooter:any = {
    imgLogo: '',
    configured: false,
    year: moment().format('YYYY'),
    lastLogin: sessionStorage.getItem('lastLogin')
  }

  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private context: ContextFactory,
    private route: ActivatedRoute) { }

  @Input() fullMenu:boolean = false;
  @Input() from: string = '';
  @Input() configureOptions: any = null;
  @Input() public: boolean = false;

  ngOnInit(): void {
    let that: any = this.complianceFooter
    // this.selectTemplate();
    this.configureVersion();
    that.hasLastConnection = Boolean(!this.isFrontLogin && that.lastLogin && that.lastLogin !== '' && that.lastLogin !=='null')
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.configureOptions){
      if(this.configureOptions.footer){
        this.configureOptions.footer = this.configureOptions.footer.replace("\n","");
        if(this.configureOptions.footer.indexOf("<a")!== -1){
            this.configureOptions.footer = this.configureOptions.footer.replace(/<a/g,"<a target='_blank' class='compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link compliance-footer__disclaimer-text--public ng-scope' ");

        }
        if(this.configureOptions.footer.indexOf("<p") !== -1 && this.configureOptions.footer.indexOf('p class="compliance-footer__disclaimer-text"') === -1){
            this.configureOptions.footer = this.configureOptions.footer.replace(/p>\n<p/g,"p><span> | </span><p")
            this.configureOptions.footer = this.configureOptions.footer.replace(/p><p/g,"p><span> | </span><p")
            this.configureOptions.footer = this.configureOptions.footer.replace(/<p/g,"<p class='compliance-footer__link-page-item'")
        }
      }

      this.rrss.forEach((rrss:any) => {
        if(this.configureOptions[rrss] && this.configureOptions[rrss] !== "" && this.configureOptions[rrss].indexOf('https://') === -1){
          this.configureOptions[rrss]= 'https://' + this.configureOptions[rrss];
        }
      });
    }
  }

  configureVersion(){
    let footerDescription: any;
    let logoFooter: any;
    let locationHost = window.location?.origin?.indexOf('localhost') !== -1?'https://centinelappd.lefebvre.es':window.location.origin;
    let translates: any;
    switch(this.skin){
        case 'steel-blue':
            translates = {
                generalTerms: this.translate.instant('compliance-footer.general-terms'),
                privacy: this.translate.instant('compliance-footer.privacy'),
                cookies: this.translate.instant('compliance-footer.cookies'),
                processingPersonalData: this.translate.instant('compliance-footer.processing-personal-data')
            }
            footerDescription = this.commonUtilsService.getFooterDescription(translates);
            logoFooter = locationHost+'/assets/img/larcier-intersentia.png';

            this.configureOptions = {
                footer: footerDescription,
                logo: logoFooter,
                showLogoInFooter: true,
            };
            break;
        case 'uk-steel-blue':
            logoFooter = locationHost+'/assets/img/logo-indicator.png';
            this.configureOptions = {
                logo: logoFooter,
                showLogoInFooter: true,
                terms: 'https://www.indicator-flm.co.uk/en/terms/',
                privacy: 'https://www.indicator-flm.co.uk/en/privacy/',
            };
            break;
        case 'icam-red':
            this.complianceFooter.imgLogo = locationHost+'/assets/img/logo-icam-blanco-footer.png';
            if(!this.configureOptions){
              this.configureOptions = {
                    linkedin: 'https://es.linkedin.com/company/ilustrecolegiodeabogadosdemadrid',
                    facebook: 'https://www.facebook.com/IlustreColegioAbogadosMadrid',
                    twitter: 'https://twitter.com/icam_es',
                    instagram: 'https://www.instagram.com/icam_es/'
                }
            }
            translates = {
                legalWarning: this.translate.instant('compliance-footer.legal-warning'),
                cookiesPolicy: this.translate.instant('compliance-footer.cookies-policy'),
            }
            footerDescription = this.commonUtilsService.getFooterDescription(translates);
            this.configureOptions.footer = footerDescription;
            break;
        case 'gm-law':
            this.complianceFooter.imgLogo = '../assets/img/logo-giuffre-footer.png';
            if(!this.configureOptions){
              this.configureOptions = {
                    linkedin: 'https://www.linkedin.com/company/giuffr%C3%A8francislefebvre/',
                    facebook: 'https://www.facebook.com/GiuffreFrancisLefebvre/',
                    twitter: 'https://twitter.com/giuffrefrancisl',
                    instagram: 'https://www.instagram.com/giuffre.francis.lefebvre'
                }
            }
            translates = {
                legalWarning: this.translate.instant('compliance-footer.legal-warning'),
                cookiesPolicy: this.translate.instant('compliance-footer.cookies-policy'),
            }
            footerDescription = this.commonUtilsService.getFooterDescription(translates);
            this.configureOptions.footer = footerDescription;
            break;
        /*case 'com-de':
              this.complianceFooter.imgLogo = '../assets/img/logo-stollfub-white-172x40.png';
              if(!this.configureOptions){
                this.configureOptions = {
                      terms: 'https://www.stollfuss.de/Rechtliches/Alllgemeine-Geschaeftsbedingungen/',
                      privacy: 'https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf',
                      linkedin: 'https://www.linkedin.com/company/stollfuss/',
                      twitter: '',
                      impressum:'https://www.stollfuss.de/Rechtliches/Impressum/',
                      facebook: '',
                      youtube: ''
                }
              }
              translates = {
                  generalTerms: this.translate.instant('compliance-footer.general-terms'),
                  privacyPolicy: this.translate.instant('compliance-footer.privacy-policy'),
              }
              footerDescription = this.commonUtilsService.getFooterDescription(translates);
              this.configureOptions.footer = footerDescription;
              break;*/
              case 'com-de':
                  this.complianceFooter.imgLogo = '../assets/img/logo-stollfub-white-172x40.png';
                  this.configureOptions = {
                      logo: logoFooter,
                      showLogoInFooter: true,
                      terms: 'https://www.stollfuss.de/Rechtliches/Alllgemeine-Geschaeftsbedingungen/',
                      privacy: 'https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf',
                      linkedin: 'https://www.linkedin.com/company/stollfuss/',
                      twitter: '',
                      impressum:'https://www.stollfuss.de/Rechtliches/Impressum/',
                      facebook: '',
                      youtube: ''
                  };
                  translates = {
                    generalTerms: this.translate.instant('compliance-footer.terms-conditions'),
                    privacyPolicy: this.translate.instant('compliance-footer.privacy-policy'),
                    impressum : this.translate.instant('compliance-footer.impressum'),
                }
                footerDescription = this.commonUtilsService.getFooterDescription(translates);
                this.configureOptions.footer = footerDescription;
              break;
        case 'com-fr':
              this.complianceFooter.imgLogo = '../assets/img/lefebvre_dalloz-white.png';
              if(!this.configureOptions){
                this.configureOptions = {
                      linkedin: 'https://www.linkedin.com/company/lefebvre-dalloz/',
                      twitter: 'https://x.com/LefebvreSarrut',
                      facebook: '',
                      youtube: ''
                  }
              }
              translates = {
                  generalTerms: this.translate.instant('compliance-footer.general-terms'),
                  privacyPolicy: this.translate.instant('compliance-footer.privacy-policy'),
              }
              footerDescription = this.commonUtilsService.getFooterDescription(translates);
              this.configureOptions.footer = footerDescription;
              break;
        default:
          this.complianceFooter.imgLogo = "https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-ij-bl-160x57.png";
          if(!this.configureOptions){
            this.configureOptions = {
              linkedin: 'https://www.linkedin.com/company/lefebvre_es',
              facebook: 'https://www.facebook.com/Lefebvre.ES/',
              twitter: 'https://twitter.com/Lefebvre_ES',
              youtube: 'https://www.youtube.com/c/lefebvre_es'
            }
          }
          let translatesDarkBlue = {
              reservedRights: this.translate.instant('compliance-footer.reserved-rights'),
              legalWarning: this.translate.instant('compliance-footer.legal-warning'),
              privacyPolicy: this.translate.instant('compliance-footer.privacy-policy'),
              cookiesPolicy: this.translate.instant('compliance-footer.cookies-policy'),
          }
          footerDescription = this.commonUtilsService.getFooterDescription(translatesDarkBlue);
          this.configureOptions.footer = footerDescription;
          break;
    }
    this.complianceFooter.configured = true;
  }

  goTo(type:any){
    window.open(this.configureOptions[type], '_blank');
  }

  openDoc(type:any){
    window.open(this.configureOptions[type], '_blank');
  }
}
