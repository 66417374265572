<div class="new-version">
    <div class="common__modal-header-block">
        <i class="lf-icon-version common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'new-version.new-version'">Nueva versión</span>
        <i class="lf-icon-close common__icon-close-modal"
            click="handleDismiss()"></i>
    </div>
    <form class="new-version__form-version">
        <field-type-text-simple
            [field]="newVersion.fieldInfo"
            [preSelectItem]="newVersion.version.name"
            (itemSelected)="itemSelected('name', $event)">
        </field-type-text-simple>
        <div class="common__buttons-block common__buttons-block--modal-2">
            <button type="reset"
                class="common__button common__button--clean"
                (click)="reset()"
                [afTranslate]="'common.clean'">Limpiar</button>
            <button type="submit"
                class="common__button common__button--active"
                (click)="validateForm()"
                [afTranslate]="'common.create'">Crear</button>
        </div>
    </form>
</div>
