import { Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'list-in-dropdown',
  templateUrl: './list-in-dropdown.component.html'
})
export class ListInDropdownComponent implements OnChanges, OnInit {
  skin: string = this.globalCfg.skin;
  listInDropdown:any = {
    listId: 'list-dropdown-'+this.commonUtilsService.createId()
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory) { }
  @Input() list: any = [];
  @Input() type: any = null;
  @Output() closeDropdownEmit = new EventEmitter();

  ngOnInit(){
    this.configureContainer();
  }

  ngOnChanges(): void {
    if(this.list){
      this.configureList(this.list);
    }
  }

  configureContainer(){
    this.listInDropdown.iconHeader = 'lf-icon-group';
    switch(this.type){
        case 'evaluation':
            this.listInDropdown.title = this.translate.instant('participants-list.share-evaluation');
            break;
        case 'task':
        case 'task-process-concept-list':
        case 'manager-concept-list':
            this.listInDropdown.title = this.translate.instant('participants-list.share-task');
            break;
        case 'alert':
            this.listInDropdown.title = this.translate.instant('participants-list.share-alert');
            break;
        case 'videoconferences':
            this.listInDropdown.title = this.translate.instant('contacts.invitations');
            break;
        case 'confidential':
        case 'confidential-historic':
            this.listInDropdown.title = this.translate.instant('manage-confidential-modal.auth-users');
            break;
        case 'share-document':
            this.listInDropdown.title = this.translate.instant('documentation-list.share-document');
            break;
        case 'roles':
            this.listInDropdown.title = this.translate.instant('common.assigned-roles');
            this.listInDropdown.iconHeader = 'lf-icon-add-points list-in-dropdown__icon-group--little';
            break;
        case 'organizations':
          this.listInDropdown.title = this.translate.instant('common.organizations');
          this.listInDropdown.iconHeader = 'lf-icon-label-user list-in-dropdown__icon-group--little';
          break;
        default:
            // 'task-process'
            this.listInDropdown.title = this.translate.instant('contacts.recipients');
            break;
    }
  }

  configureList(list:any){
    list.forEach((item:any) => {
      item.textToTooltip = this.textToTooltip(item)
    });

  }

  closeDropdown(e:any){
    e.stopPropagation();
    this.closeDropdownEmit.emit();
  }

  textToTooltip(participant:any){
    return participant && participant.isDeleted? participant.fullName+' ('+this.translate.instant('common.inactive-user')+')' : participant.fullName;
  }

}
