<div [class]="'rich-editor '+classType">
  <editor *ngIf="initEditor"
    apiKey="no-api-key"
    cloudChannel="5"
    [disabled]="noEditable"
    [id]="elementId"
    [init]="richEditor"
    [initialValue]="modelText"
    [inline]="false"
    plugins="lists advlist"
    tagName="div"
    [toolbar]="toolbar"
    (onKeyPress)="keyPressAction()"></editor>
</div>
<button class="rich-editor__button-modal"
  (click)="addLink($event)"
  [id]="'addLink-'+elementId"></button>
<button class="rich-editor__button-modal"
  (click)="openSnippetModal($event)"
  [id]="'addNewFragment-'+elementId"></button>
