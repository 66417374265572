<div class="concept-list">
  <div class="concept-list__table"
      [id]="'concept_'+conceptInfo.conceptId">
      <div class="common__table-header"
          [ngShow]="conceptList.conceptList.length">
          <div class="concept-list__table-col concept-list__table-col--reference-query concept-list__table-col--top-1">
              <label for="multiple-selection-select"
                      class="documentation-list__label-multiple documentation-list__label-multiple--icam-query">
                  <input class="documentation-list__input-multiple"
                          type="checkbox"
                          id="multiple-selection-select"
                          (click)="selectMultipleConcept('all')"
                          [checked]="conceptList.selectAll">
                  <i class="documentation-list__icon-multiple"
                      [ngClass]="conceptList.selectAll?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
              </label>
              <filter-by-search class="concept-list__reference-title"
                  [item]="conceptList.referenciaQueryFilter"
                  [filterText]="conceptList.referenciaWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'referencia')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--type-query concept-list__table-col--top-1">
              <filter-by-list [type]="'list'"
                  [item]="conceptList.tipoFilter"
                  [widthClass]="'200px'"
                  [list]="conceptList.tipoList"
                  (filterElementEmit)="selectTypeOperation($event, 'tipo')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--entry-query concept-list__table-col--top-2"
                ngbDropdown
                id="creationDate"
                [autoClose]="'outside'">
              <p class="common__table-header-text common__cursor-pointer"
                  [afTranslate]="'concept-instances-list.entry'"
                  ngbDropdownToggle>_Entrada</p>
              <i class="common__filter-icon lf-icon-filter-1"
                  [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': conceptList.creationDateFilter}"
                  ngbDropdownToggle></i>
              <i class="lf-icon-arrow-down common__order-by"
                  [ngClass]="{'lf-icon-arrow-up': !conceptList.order && conceptList.dateFilter === 'creationDate',
                      'common__order-by--active': conceptList.dateFilter === 'creationDate'}"
                  (click)="orderDate('creationDate')"></i>
              <div class="concept-list__calendar-dropdown common__block-option-list"
                  ngbDropdownMenu role="menu">
                  <from-to-calendar [fromSave]="conceptList.creationDateFromDate"
                      [toSave]="conceptList.creationDateToDate"
                      (changeCalendar)="changeCalendar($event, 'creationDate')"
                      [type]="'concept-list'">
                  </from-to-calendar>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--state-query concept-list__table-col--top-1">
              <filter-by-list [type]="'listFind'"
                  [item]="conceptList.stepFilter"
                  [widthClass]="'200px'"
                  [list]="conceptList.stepList"
                  (filterElementEmit)="selectTypeOperation($event, 'step')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--manager-query concept-list__table-col--top-1">
              <filter-by-list [type]="'listFind'"
                  [item]="conceptList.managersFilter"
                  [widthClass]="'200px'"
                  [list]="conceptList.managersList"
                  (filterElementEmit)="selectTypeOperation($event, 'managers')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential"></div>
          <div class="concept-list__table-col concept-list__table-col--user-query concept-list__table-col--top-1">
              <filter-by-search [item]="conceptList.userQueryFilter"
                                [filterText]="conceptList.numColegiadoWordFilter"
                                (launchActionSearch)="orderBySearch($event, 'numColegiado')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--name-query concept-list__table-col--top-1">
              <filter-by-search [item]="conceptList.nameQueryFilter"
                  [filterText]="conceptList.nombreColegiadoWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'nombreColegiado')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--matter-query concept-list__table-col--top-1">
              <filter-by-list [type]="'listFind'"
                  [item]="conceptList.materiaFilter"
                  [widthClass]="'200px'"
                  [list]="conceptList.materiaList"
                  (filterElementEmit)="selectTypeOperation($event, 'materia')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--modification-query concept-list__table-col--top-2"
              ngbDropdown
              id="modificationDate"
              [autoClose]="'outside'">
              <p class="common__table-header-text common__cursor-pointer"
                  [afTranslate]="'common.modification'"
                  ngbDropdownToggle>_Modificación</p>
              <i class="common__filter-icon lf-icon-filter-1"
                  [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': conceptList.modificationDateFilter}"
                  ngbDropdownToggle></i>
              <i class="lf-icon-arrow-down common__order-by"
                  [ngClass]="{'lf-icon-arrow-up': !conceptList.order && conceptList.dateFilter === 'modificationDate',
                      'common__order-by--active': conceptList.dateFilter === 'modificationDate'}"
                (click)="orderDate('modificationDate')"></i>
              <div class="concept-list__calendar-dropdown concept-list__calendar-dropdown--modification common__block-option-list"
                    ngbDropdownMenu role="menu">
                  <from-to-calendar [fromSave]="conceptList.modificationDateFromDate"
                      [toSave]="conceptList.modificationDateToDate"
                      (changeCalendar)="changeCalendar($event, 'modificationDate')"
                      [type]="'concept-list'">
                  </from-to-calendar>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--priority-query concept-list__table-col--top-1">
              <filter-by-list [type]="'list'"
                  [item]="conceptList.esPrioritariaFilter"
                  [widthClass]="'50px'"
                  [list]="conceptList.esPrioritariaList"
                  (filterElementEmit)="selectTypeOperation($event, 'esPrioritaria')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--alerts-query concept-list__table-col--top-1">
              <filter-by-list [type]="'list'"
                  [item]="conceptList.alertStateFilter"
                  [widthClass]="'150px'"
                  [list]="conceptList.alertStateList"
                  (filterElementEmit)="selectTypeOperation($event, 'alertState')">
              </filter-by-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions-query">
              <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">Acciones</p>
          </div>
      </div>
      <div class="common__table-row"
            *ngFor="let evaluateConcept of conceptList.conceptListFilter | afOrderBy:[conceptList.dateFilter, 'referencia']:conceptList.order | afLimitTo:conceptList.configPagination.pageSize:conceptList.configPagination.pageSize*(conceptList.currentPage - 1); let index = index"
            [ngClass]="{'common__table-row--priority': evaluateConcept.esPrioritaria}">
          <div class="concept-list__priority-mark"
            [ngShow]="evaluateConcept.esPrioritaria"></div>
          <div class="concept-list__table-col concept-list__table-col--reference-query"
                [ngClass]="{'common__cursor-pointer':!conceptList.anySelect}">
              <label [for]="'multiple-selection-'+index+'-select'"
                  class="documentation-list__label-multiple documentation-list__label-multiple--icam-query">
                  <input class="documentation-list__input-multiple"
                          type="checkbox"
                          [id]="'multiple-selection-'+index+'-select'"
                          (click)="selectMultipleConcept('one', evaluateConcept)"
                          [checked]="evaluateConcept.selectedConcept">
                  <i class="documentation-list__icon-multiple"
                      [ngClass]="evaluateConcept.selectedConcept?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
              </label>
              <div [id]="'evaluate-concept-referencia-'+evaluateConcept.conceptObjectId"
                    class="common__col-text"
                    [ngbTooltip]="conferencePriorityTooltip"
                    placement="bottom"
                    container="body"
                    [tooltipClass]="'concept-list__tooltip-reference'"
                    (click)="launchActionOption('open', evaluateConcept)">
                  <span [id]="'span-evaluate-concept-referencia-'+evaluateConcept.conceptObjectId">{{evaluateConcept.referencia}}</span>
                  <ng-template #conferencePriorityTooltip>
                    <p [innerHTML]="evaluateConcept.conferencePriorityTooltip"></p>
                  </ng-template>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--type-query">
            <parragraph-tooltip [text]="evaluateConcept.tipo"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--entry-query">
              <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate:''"></parragraph-tooltip>

          </div>
          <div class="concept-list__table-col concept-list__table-col--state-query">
            <parragraph-tooltip [text]="evaluateConcept.step"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--manager-query">
            <participants-list *ngIf="evaluateConcept.managers?.length"
                    [participants]="evaluateConcept.managers"
                    [type]="'manager-concept-list'">
            </participants-list>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential">
              <div [ngShow]="evaluateConcept.confidential"
                    ngbDropdown
                    [id]="'concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId]"
                    [autoClose]="'outside'">
                  <i class="lf-icon-lock concept-list__icon-confidential"
                      ngbDropdownToggle
                      matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </i>
                  <list-in-dropdown ngbDropdownMenu role="menu"
                      class="dropdown-menu concept-list__box-select-list"
                      [list]="evaluateConcept.confidentialList"
                      [type]="'confidential'"
                      (closeDropdownEmit)="closeDropdown('concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId])">
                  </list-in-dropdown>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--user-query">
            <parragraph-tooltip [text]="evaluateConcept.numColegiado"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--name-query">
            <parragraph-tooltip [text]="evaluateConcept.nombreColegiado"></parragraph-tooltip>

          </div>
          <div class="concept-list__table-col concept-list__table-col--matter-query">
              <parragraph-tooltip [text]="evaluateConcept.materia"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--modification-query">
              <parragraph-tooltip [text]="evaluateConcept.modificationDate | convertDate:''"></parragraph-tooltip>

          </div>
          <div class="concept-list__table-col concept-list__table-col--priority-query">
              <span class="common__col-text"
                [afTranslate]="evaluateConcept.esPrioritaria?'common.yes':'common.no'">Si/No</span>
          </div>
          <div class="concept-list__table-col concept-list__table-col--alerts-query">
              <span>
                  <i [class]="'lf-icon-radio-button-active concept-instances-list__icon-alert concept-instances-list__icon-alert--'+evaluateConcept.colorAlert"></i>
                  <span class="common__col-text concept-instances-list__col-text-alert">{{evaluateConcept.processingHours != null ? evaluateConcept.processingHours : '&nbsp;'}}</span>
              </span>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions-query">
              <ul class="common__options-row"
                  [ngHide]="conceptList.anySelect && !evaluateConcept.selectedConcept">
                  <li class="common__menu-options-row"
                      [ngShow]="toShow !== 'archived' && !conceptList.anySelect">
                      <i [ngClass]="userPermissions === 'none' || conceptInfo.poll?'lf-icon-visible':'lf-icon-editable-form'"
                          (click)="launchActionOption('open', evaluateConcept)"
                          matTooltip="{{conceptList.descriptionEdit}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="toShow !== 'archived' && (!conceptList.anySelect || (evaluateConcept.selectedConcept && conceptList.anySelect))">
                      <i class="lf-icon-consulting"
                          (click)="assignManager(evaluateConcept)"
                          matTooltip="{{'concept-list.assign-manager'| afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="toShow !== 'archived' && !conceptList.anySelect && conceptInfo.publicShared && conceptInfo.allowPublicShare">
                      <i class="lf-icon-process"
                          (click)="changeState(evaluateConcept)"
                          matTooltip="{{'concept-list.manage-proccess'| afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && conceptInfo.type !== 0 && !conceptList.anySelect">
                      <i class="lf-icon-open-window"
                          (click)="openInNewTab(evaluateConcept)"
                          ngbTooltip="{{conceptList.description}}"
                          placement="bottom"
                          container="body"></i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && !conceptInfo.reportId && toShow !== 'archived' && !conceptList.anySelect">
                      <i class="lf-icon-lock"
                          (click)="openModalConfidential(evaluateConcept)"
                          matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions === 'all' && !conceptInfo.poll && toShow !== 'archived' && conceptInfo.conceptId && !conceptList.anySelect && !conceptInfo.hasAutoArchive">
                      <i class="lf-icon-archive"
                          (click)="archiveConcept(evaluateConcept, '1')"
                          ngbTooltip="{{'concept-list.archive-concept' | afTranslate}}"
                          placement="bottom"
                          container="body"></i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="toShow === 'archived' && !conceptList.anySelect">
                      <i class="lf-icon-rotate"
                          (click)="archiveConcept(evaluateConcept, '0')"
                          matTooltip="{{'concept-list.restore-concept' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && !conceptInfo.poll && !conceptList.anySelect">
                      <i class="lf-icon-trash"
                          (click)="launchActionOption('delete', evaluateConcept)"
                          matTooltip="{{conceptList.deleteConcept}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
              </ul>
              <i class="lf-icon-kebab-menu common__icon-ellipsis"
                  [ngClass]="{'concept-list__ellipsis-disabled': conceptList.anySelect && !evaluateConcept.selectedConcept}"></i>
          </div>
      </div>
      <div class="concept-list__table-foot"
            [ngClass]="{'concept-list__table-foot--no-items': !conceptList.conceptList.length}">
          <div [ngShow]="conceptList.archivedAmount && toShow !== 'archived' && (!conceptInfo.poll && (conceptList.archivedAmount && toShow !== 'archived'))"
                class="concept-list__block-info-archive"
                [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none concept-list__archive-button"
                      (click)="viewArchived()">
                  <i class="lf-icon-archive common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                        [afTranslate]="'concept-list.view-archive'">_Ver archivo</span>
              </button>
              <p class="concept-list__archived-evaluated-amount">{{conceptList.archivedParragraph}}</p>
          </div>
          <div [ngShow]="conceptInfo.poll && conceptList.showResultsReports"
                class="concept-list__block-info-archive"
                [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none common__button-border-none--left"
                      (click)="showReports()">
                  <i class="lf-icon-analysis common____icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                        [afTranslate]="'concept-list.view-report-results'">_Ver informe de resultados</span>
              </button>
          </div>
          <div class="concept-list__pagination-block">
              <pagination-control *ngIf="conceptList.conceptListFilter?.length"
                  [config]="conceptList.configPagination"
                  [collectionSize]="conceptList.conceptListFilter?.length"
                  [(currentPage)]="conceptList.currentPage"
                  (configuredTotalChange)="conceptList.configuredTotal = $event.configuredTotal">
              </pagination-control>
              <div class="common__pagination-count" [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize">
                <span class="notranslate">{{conceptList.configuredTotal.first}}-{{conceptList.configuredTotal.last}}</span>
                <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                <span class="notranslate">{{conceptList.conceptListFilter?.length}}</span>
              </div>
              <div class="common__pagination-count"
                [ngShow]="conceptList.configPagination.pageSize >= conceptList.conceptListFilter?.length">
                <span>
                  <span [afTranslate]="'common.total'">_Total</span>
                  <span class="common__total-number-element notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </span>
              </div>
          </div>
      </div>
  </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="conceptList.configurationPdf"></pdf-table>
