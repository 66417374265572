<div class="advice-modal">
  <div class="advice-modal__custom-modal-icon-block"
      [ngClass]="{'advice-modal__custom-modal-icon-block--rotate':adviceModal.confirmQuestionIcon === 'lf-icon-advice-full'}">
      <i [class]="adviceModal.confirmQuestionIcon"
          [ngClass]="{'advice-modal__custom-modal-icon-confirm': adviceModal.confirmQuestionIcon === 'lf-icon-check-round',
            'advice-modal__custom-modal-icon-advice': adviceModal.confirmQuestionIcon === 'lf-icon-info-full'}">
      </i>
  </div>
  <p class="advice-modal__custom-modal-body-text">{{questionText}}</p>

  <div [ngSwitch]="adviceModal.modalQuestion">
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'question'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">_Cancelar</button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.ok-button'">_Ok</button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'continue'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.exit'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.continue'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
              *ngSwitchCase="'only-continue'">
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.continue'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'remain'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.remain'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.exit'"></button>

      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'finish'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.return'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.finish'"></button>

      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'exitAll'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleAccept('without-save')"
                  [afTranslate]="'common.discard-all-and-exit'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept('save-all')"
                  [afTranslate]="'common.save-all-and-exit'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'exitUniqueConcept'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleAccept('discard-navegate')"
                  [afTranslate]="'common.discard-all-and-exit'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept('save-navegate')"
                  [afTranslate]="'common.save-all-and-exit'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'accept'">
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.ok'"></button>

      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'cancelContinue'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept('continue')"
                  [afTranslate]="'common.continue'"></button>

      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'questionRecord'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'record-audio-modal.new-record'">_nueva grabación</button>

      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'acceptContinue'">
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.ok'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'saveContinue'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleAccept('without-save')"
                  [afTranslate]="'common.continue-without-save'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept('save-and-continue')"
                  [afTranslate]="'common.save-and-continue'"></button>
      </div>
      <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
           *ngSwitchCase="'seeAll'">
          <button class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'landing-page.create-new-query'"></button>
          <button class="common__button-landing-custom common__button-landing-custom--send"
                  [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                  (click)="handleAccept()"
                  [afTranslate]="'landing-page.see-all-queries'"></button>
      </div>
      <div *ngSwitchDefault>
          <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
               *ngIf="adviceModal.modalQuestion === 'exit' || adviceModal.modalQuestion === 'exitTab'">
              <button class="common__button-landing-custom common__button-landing-custom--cancel"
                      [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--cancel"
                      [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                      (click)="handleAccept('discard-exit')"
                      [afTranslate]="'common.discard-exit'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--send"
                      [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                      [ngShow]="adviceModal.modalQuestion === 'exit'"
                      (click)="handleAccept('save-exit')"
                      [afTranslate]="'common.save-exit'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--send"
                      [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                      [ngShow]="adviceModal.modalQuestion === 'exitTab'"
                      (click)="handleAccept('save-exit-tab')"
                      [afTranslate]="'common.save-exit'"></button>
          </div>
          <div class="advice-modal__buttons-block advice-modal__buttons-block--custom"
               *ngIf="adviceModal.modalQuestion === 'exitTemplate' || adviceModal.modalQuestion === 'exitTask'">
              <button class="common__button-landing-custom common__button-landing-custom--cancel"
                      [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--cancel"
                      [ngStyle]="{'border-color': adviceModal.custom?.color, 'color': adviceModal.custom?.color}"
                      (click)="handleAccept('without-save')"
                      [afTranslate]="'common.continue-without-save'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--send"
                      [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                      [ngShow]="adviceModal.modalQuestion === 'exitTemplate'"
                      (click)="handleAccept('generate-and-save')"
                      [afTranslate]="'common.save-and-generate'"></button>
              <button class="common__button-landing-custom common__button-landing-custom--send"
                      [ngStyle]="{'background-color': adviceModal.custom?.color, 'color': adviceModal.custom?.textColor}"
                      [ngShow]="adviceModal.modalQuestion === 'exitTask'"
                      (click)="handleAccept('save-and-share')"
                      [afTranslate]="'common.save-and-share'"></button>
          </div>
      </div>
  </div>
<!-- </div> -->
  <div class="advice-modal__document-block"
    *ngIf="from==='file'">
    <p class="advice-modal__document-block-title"
        [afTranslate]="'common.fail-files-info'"></p>
        <ul class="upload-document__documentation-list upload-document__documentation-list--advice-modal">
            <li *ngFor="let document of list"
                class="upload-document__documentation-item upload-document__documentation-item--advice">
                <i class="{{document.source | extensionDocument}} upload-document__documentation-icon-type"></i>
                <dl class="upload-document__documentation-document-info">
                    <dt class="upload-document__documentation-document-name">{{document.name}}</dt>
                    <dd class="upload-document__documentation-document-size">{{document.size | convertBytes}}</dd>
                    <dd></dd>
                </dl>
                <p class="upload-document__progress-upload-text upload-document__progress-upload-text--error"
                    [ngShow]="document.status==='validate-error'">{{document.statusText}}</p>
                <div class="upload-document__progress-upload-block upload-document__progress-upload-block--error"
                    [ngStyle]="{'width': '100%'}">
                    <div class="upload-document__progress-upload-line upload-document__progress-upload-line upload-document__progress-upload-line--error"></div>
                    <p class="upload-document__progress-upload-text upload-document__progress-upload-text--error">
                        <span>{{document.statusText}}</span>
                    </p>
                </div>
            </li>
        </ul>
  </div>
  <div class="users-admin-import__errors-block"
    *ngIf="(from==='contacts' || from==='concepts') && list?.length">
    <div class="users-admin-import__errors-header">
        <p class="users-admin-import__errors-title">{{adviceModal.errorTranslate}}</p>
        <span class="header-nav__icon-numeric-nav">{{list?.length}}</span>
    </div>
    <ul class="users-admin-import__error-list users-admin-import__error-list--modal">
        <li *ngFor="let contact of list; let index=index"
            class="users-admin-import__error-item">
            <p class="users-admin-import__contact-error-name">
                <i class="users-admin-import__contact-icon users-admin-import__contact-icon--list-error"
                    [ngClass]="adviceModal.from === 'contacts'?'lf-icon-user':'lf-icon-compliance'"></i>
                <span-tooltip [text]="contact.nameComplete"></span-tooltip>
            </p>
            <ul class="users-admin-import__contact-error-list">
                <li *ngFor="let error in contact.errorList"
                    class="users-admin-import__contact-error-item">
                    <span class="users-admin-import__contact-error-item users-admin-import__contact-error-item--error"> {{error.errorType}}: </span>{{error.errorDescription}}
                </li>
            </ul>
        </li>
    </ul>
  </div>
</div>

