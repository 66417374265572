import { Component } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'schedule-big',
  templateUrl: './schedule-big.component.html'
})
export class ScheduleBigComponent {
  constructor(private commonUtilsService: CommonUtilsService) { }

  changeViewWindow(){
    this.commonUtilsService.goToStateUrl();
  }
}
