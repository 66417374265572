import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IcamAccessGuard implements CanActivate {
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private apiProperties: ApiPropertiesFactory){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(window.location.hostname === 'localhost'){
      return this.getProperties();
    }else{
      return false;
    }
  }

  getProperties(){
    return this.apiService.getConfig().then((data)=>{
        this.apiProperties.setApiProperties(data);
        return this.getGlobalCfg();
    },(errorData:any)=>{
        return false;
    })
  }

  getGlobalCfg(){
    return this.apiService.getGlobal()
        .then((data:any)=>{
            this.globalCfg.setGlobalCfg(data.data);
            return this.globalCfg.skin === 'icam-red' && (location.hostname === 'localhost' || location.hostname === 'centinelaicam.affin.es');
        },(errorData:any)=>{
            return false;
        })
  }

}
