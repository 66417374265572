import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-switch',
  templateUrl: './field-type-switch.component.html'
})
export class FieldTypeSwitchComponent implements OnChanges  {
  constructor(private globalCfg:GlobalCfgFactory, private commonUtilsService:CommonUtilsService) { }

  //INICIO Provisionales
  // @Input() field:any;
  // @Input() preSelectItem:any;
  // @Output() preSelectItemChange:any = new EventEmitter<any>();
  // @Output() itemSelectEmit = new EventEmitter();
  //FIN Provisionales

  @Input() label:string;
  @Input() checked:boolean;
  @Input() disabled:boolean;
  @Input() negativeColor:boolean;
  @Input() description:any;
  @Output() callback = new EventEmitter();

  skin:string = this.globalCfg.skin;
  id:string = this.commonUtilsService.createId();
  value:boolean = false;

  ngOnChanges() {
    if (this.value !== this.checked) {
      this.value = this.checked;
    }
  }

  toggleSwitch() {
    this.callback.emit({value: this.value});
  }
}
