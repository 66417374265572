import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import {firstBy} from "thenby";

@Pipe({
  name: 'afOrderBy',
  pure: false

})
export class AfOrderByPipe implements PipeTransform {
  constructor(private commonUtilsService: CommonUtilsService){}
  transform(items: any, props: any, asc:boolean = true): any {
    if(items && items.length && props){
      let sortArray;
      let sortTypes: any = [];
      if(Array.isArray(props)){
        sortTypes = props;
      }else{
        sortTypes.push([props]);
      }
      switch(sortTypes.length){
        case 2:
          sortArray = items.sort(
            firstBy(sortTypes[0], {ignoreCase:true})
            .thenBy(sortTypes[1], {ignoreCase:true})
          )
          break;
        case 3:
          sortArray = items.sort(
            firstBy(sortTypes[0], {ignoreCase:true})
            .thenBy(sortTypes[1], {ignoreCase:true})
            .thenBy(sortTypes[2], {ignoreCase:true})
          )
          break;
        case 4:
          sortArray = items.sort(
            firstBy(sortTypes[0], {ignoreCase:true})
            .thenBy(sortTypes[1], {ignoreCase:true})
            .thenBy(sortTypes[2], {ignoreCase:true})
            .thenBy(sortTypes[3], {ignoreCase:true})
          )
          break;
        default:
          items.forEach((element: any, index: any) => {
            if(!element.idUniqueOrderPipe){
              element.idUniqueOrderPipe = index + '-' + this.commonUtilsService.createId();
            }
          });
          sortArray = items.sort(
            firstBy(sortTypes[0], {ignoreCase:true})
            .thenBy('idUniqueOrderPipe', {ignoreCase:true})
          )
          break;
      }
      return asc? sortArray.reverse():sortArray;
    }else{
      return items;
    }
  }
}
