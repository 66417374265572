import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'new-report',
  templateUrl: './new-report.component.html'
})
export class NewReportComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  newReport: any = {
    clientListModify: {},
    report: {},
    typeReportList: [],
    associatedClient:{
        fieldId:'associatedClient',
        label: this.translate.instant('common.organization'),
        description: null,
        required: true
    },
    associatedImplantation:{
        fieldId:'associatedImplantation',
        label: 'Implantación',
        description: null,
        required: true,
        noEditable: true
    },
    typeReport:{
        fieldId:'typeReport',
        label: this.translate.instant('new-report.type-report'),
        required:  true,
        description: null,
        noEditable: true
    },
    nameReport:{
        fieldId:'nameReport',
        label: this.translate.instant('new-report.new-name-report'),
        required:  true,
        description: null
    }
  }
  constructor(private activeModal: NgbActiveModal,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService) { }

  @Input() evaluationList: any = [];
  @Input() filteredProduct: any = {};

  ngOnInit(): void {
    this.getClients();
    this.newReport.report.productId = this.filteredProduct.productId;
    this.newReport.associatedImplantation.label = this.newReport.report.productId === 'denunc'?this.translate.instant('common.channel'):this.translate.instant('common.implantation');
    if(this.skin === 'icam-red'){
        this.newReport.report.client = this.evaluationList[0].clientId;
        this.itemSelected('evaluation', this.evaluationList[0].evaluationId);
    }
  }

  getClients(){
    this.apiService.get('clients/all')
      .then((data:any)=>{
          let clientListModify = this.filterClientsList(data);
          this.newReport.clientListModify =  this.commonUtilsService.editList(clientListModify, 'clientId', 'name');
      });
  }

  filterClientsList(list:any){
    let clientList = [];
    for(let i = 0; i < list.length; i++){
        let client = list[i];
        for(let j = 0; j < this.evaluationList.length; j++){
            let evaluation = this.evaluationList[j];
            if(client.clientId === evaluation.clientId){
                clientList.push(client);
                break;
            }
        }
    }
    return clientList;
  }

  itemSelected(e: any, type:any){
    let newValue = e.newValue;
    if(type !== 'name' && type !=='typeReport' && this.newReport.report[type] !== newValue){
      this.newReport.typeReportList = [];
    }
    this.newReport.report[type] = newValue;
    switch(type){
        case 'client':
            this.newReport.evaluationListModify = [];
            if(!newValue){
                this.newReport.associatedImplantation.noEditable = true;
            }else{
                this.newReport.associatedClient.error = false;
                this.getClientsReports(newValue);
            }
            break;
        case 'evaluation':
            if(!newValue){
                this.newReport.typeReport.noEditable = true;
                this.newReport.report.typeReport = null;
            }else{
                this.newReport.associatedImplantation.error = false;
                this.getImplantationReports(newValue);
            }
            break;
        case 'name':
            if(newValue){
                this.newReport.nameReport.error = false;
            }
            break;
        default:
            break;
    }
  }

  getClientsReports(newValue: any){
    this.apiService.get('evaluations/client', newValue)
      .then((data:any)=>{
          let evaluationListModify = this.commonUtilsService.editList(data, 'evaluationId', 'name');
          this.newReport.evaluationListModify = this.commonUtilsService.filterEvaluationByProduct(evaluationListModify, this.filteredProduct);
          this.newReport.associatedImplantation.disabled = false;
          this.newReport.associatedImplantation.noEditable = false;
          this.newReport.typeReportList = [];
          this.newReport.report.typeReport = null;
      });
  }

  getImplantationReports(newValue: any){
    this.apiService.get('reportobjects/byevaluation', newValue)
      .then((data:any)=>{
          this.newReport.typeReport ={
              label: this.translate.instant('new-report.type-report'),
              fieldId:'typeReport',
              required:  true,
              description: null,
              error: false,
              disabled: false,
              noEditable: false
          }
          this.newReport.report.typeReport = null;

          let tempList = null;
          if (this.skin === 'icam-red') {
              tempList = data.filter((typeReport: any)=>{
                return !typeReport.predefined;
              });
          } else {
              tempList = data;
          }
          this.newReport.typeReportList = this.commonUtilsService.editList(tempList, 'reportId', 'name');
      });
  }

  generateReport(){
      if(!this.hasErrors()){
        this.newReport.report.predefined = this.getPredefinedReport();
        this.handleAccept();
      }
  }

  hasErrors(){
    let error = 0;
    if(!this.newReport.report.client){
      this.newReport.associatedClient.error = true;
      error++;
    }else{
      this.newReport.associatedClient.error = false;
    }
    if(!this.newReport.report.name){
      this.newReport.nameReport.error = true;
      error++;
    }else{
      this.newReport.nameReport.error = false;
    }
    if(!this.newReport.report.typeReport){
      this.newReport.typeReport.error = true;
      error++;
    }else{
      this.newReport.typeReport.error = false;
    }
    if(!this.newReport.report.evaluation){
      this.newReport.associatedImplantation.error = true;
      this.newReport.typeReport.error = true;
      error++;
    }else{
      this.newReport.associatedImplantation.error = false;
      this.newReport.typeReport.error = false;
    }
    return error;
  }

  getPredefinedReport(){
      for(let i = 0; i <  this.newReport.typeReportList.length; i++){
          if(this.newReport.report.typeReport === this.newReport.typeReportList[i].reportId){
              return this.newReport.typeReportList[i].predefined;
          }
      }
  }

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        newReport : this.newReport.report
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
