<div class="history-viewer common__window-footer-bottom"
    id="history-viewer">
    <div class="history-viewer__block-header">
        <div class="history-viewer__block-logo">
            <img class="history-viewer__logo-img"
                [src]="historyViewer.logoHeader"
                alt="logo header"/>
            <p class="history-viewer__block-text"
                *ngIf="skin==='gm-law'"
                [afTranslate]="'front-login.powered-by'"></p>
        </div>
        <div class="history-viewer__block-header-modified">
            <span [afTranslate]="'history-viewer.modified-the'">Modificado el </span>
            <span class="history-viewer__text-modified-big">{{historyViewer.historyDataViewer?.modifiedBy.modificationDate | convertDate:'all-with-second'}}</span>
            <span class="history-viewer__text-modified-by"
                [ngShow]="historyViewer.historyDataViewer?.productTypeId !== 4 && historyViewer.historyDataViewer?.modifiedBy.name"
                [afTranslate]="'history-viewer.by'">por </span>
            <span class="history-viewer__text-modified-big"
                [ngShow]="historyViewer.historyDataViewer?.productTypeId !== 4 && historyViewer.historyDataViewer?.modifiedBy.name">{{historyViewer.historyDataViewer?.modifiedBy.name}}</span>
        </div>
    </div>
    <div class="history-viewer__block-breadcrumb"
        *ngIf="!historyViewer.historyDataViewer?.landingPage">
        <div class="history-viewer__breadcrumb-block">
            <div class="history-viewer__breadcrumb-item"
                *ngFor="let breadcrumb of historyViewer.historyDataViewer?.breadCrumb; let last = last"
                [ngClass]="{'history-viewer__breadcrumb-item--active':last}">
                <div class="history-viewer__breadcrumb-text">{{breadcrumb}}</div>
                <i class="lf-icon-angle-right history-viewer__icon-angle-right"
                    [ngShow]="!last"></i>
            </div>
        </div>
    </div>
    <div class="history-viewer__block-all-body">
        <div class="history-viewer__block-body">
            <div class="history-viewer__block-header-body"
                id="history-viewer-block-header-body">
                <div class="common__block-search history-viewer__block-search"
                    [ngClass]="{'history-viewer__block-search--open': historyViewer.search}"
                    id="history-viewer-block-search">
                    <input [ngShow]="historyViewer.search"
                      class="common__search-input history-viewer__search-input"
                      (keyup)="searchTextfunction($event)"
                      [(ngModel)]="historyViewer.textSearch"
                      placeholder="{{'common.search' | afTranslate}}"
                      [focusWhen]="historyViewer.search">
                    <span class="common__text-search"
                        [ngShow]="!historyViewer.search"
                        (click)="toggleSearch()"
                        [afTranslate]="'common.search'">_Buscar</span>
                    <div [ngShow]="historyViewer.search"
                        class="history-viewer__block-info-search">
                        <div [ngShow]="historyViewer.textSearch"
                            class="history-viewer__block-count-search">
                            <span>{{historyViewer.elementSelected}}</span><span>/</span><span>{{historyViewer.countElementSearch}}</span>
                        </div>
                        <div class="history-viewer__block-arrow-buttons">
                            <button class="lf-icon-angle-up history-viewer__button-arrow-search"
                                (click)="changeElementSelected('up')"
                                [disabled]="!historyViewer.countElementSearch">
                            </button>
                            <button class="lf-icon-angle-down history-viewer__button-arrow-search history-viewer__button-arrow-search--down"
                                (click)="changeElementSelected('down')"
                                [disabled]="!historyViewer.countElementSearch">
                            </button>
                        </div>
                    </div>
                    <button class="common__icon-button common__icon-button-search"
                        [ngClass]="{'lf-icon-search': !historyViewer.search,
                            'lf-icon-close-search common__icon-button-search--open': historyViewer.search}"
                        (click)="toggleSearch()">
                    </button>
                </div>
                <div class="history-viewer__block-buttons-header-body">
                    <button class="history-viewer__header-button"
                            (click)="collapseOrExpandAll(true)">
                        <span class="history-viewer__header-button-text"
                              [afTranslate]="'history-viewer.collapse-everything'">_Colapsar todo</span>
                        <i class="lf-icon-collapse history-viewer__header-button-icon"></i>
                    </button>
                    <button class="history-viewer__header-button"
                            (click)="collapseOrExpandAll(false)">
                        <span class="history-viewer__header-button-text"
                              [afTranslate]="'history-viewer.expand-all'">_Expandir todo</span>
                        <i class="lf-icon-expand-vertical history-viewer__header-button-icon"></i>
                    </button>
                    <button class="history-viewer__header-button"
                            (click)="printDocument()">
                        <span class="history-viewer__header-button-text"
                              [afTranslate]="'common.print'">_Imprimir</span>
                        <i class="lf-icon-print history-viewer__header-button-icon"></i>
                    </button>
                    <button class="history-viewer__header-button"
                            (click)="exportEdition()">
                        <span class="history-viewer__header-button-text"
                              [afTranslate]="'common.export'"></span>
                        <i class="lf-icon-send history-viewer__header-button-icon"></i>
                    </button>
                </div>
            </div>
            <div class="history-viewer__block-form"
                id="history-viewer__block-form">
                <div *ngFor="let container of historyViewer.historyDataViewer?.fieldContainers">
                    <div class="history-viewer__block-group"
                      *ngIf="!container.hiddenContainer">
                      <i class="history-viewer__icon-arrow-round"
                          [ngClass]="container.hiddenView?'lf-icon-arrow-round-right':'lf-icon-arrow-round-down'"
                          [ngStyle]="{'color': historyViewer.historyDataViewer?.custom?.color}"
                          (click)="changeShow(container)"></i>
                      <div class="history-viewer__block-header-group">
                          <span class="history-viewer__text-header-group">
                              <span class="history-viewer__search-text">{{container.name}}</span>
                          </span>
                      </div>
                      <div class="history-viewer__block-form-group"
                          [ngShow]="!container.hiddenView">
                          <history-viewer-fields [listField]="container.fields"
                            [historyDataViewer]="historyViewer.historyDataViewer"></history-viewer-fields>
                          <div *ngFor="let subContainer of container.subContainers">
                              <div class="history-viewer__block-group history-viewer__block-group--subgroup"
                                *ngIf="!subContainer.hiddenContainer">
                                <i class="history-viewer__icon-arrow-round history-viewer__icon-arrow-round--subgroup"
                                    [ngClass]="subContainer.hiddenView?'lf-icon-arrow-round-right':'lf-icon-arrow-round-down'"
                                    [ngStyle]="{'color': historyViewer.historyDataViewer?.custom?.color}"
                                    (click)="changeShow(subContainer)"></i>
                                <div class="history-viewer__block-header-group history-viewer__block-header-group--subgroup">
                                    <span class="history-viewer__text-header-group">
                                        <span class="history-viewer__search-text">{{subContainer.name}}</span>
                                    </span>
                                </div>
                                <div class="history-viewer__block-form-group"
                                    [ngShow]="!subContainer.hiddenView">
                                    <history-viewer-fields [listField]="subContainer.fields"
                                      [historyDataViewer]="historyViewer.historyDataViewer"></history-viewer-fields>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer class="common__footer-bottom"
        *ngIf="!historyViewer.historyDataViewer?.landingPage"></compliance-footer>
    <customize-footer class="common__footer-bottom"
        *ngIf="historyViewer.historyDataViewer?.landingPage"
        [configureOptions]="historyViewer.historyDataViewer?.custom"></customize-footer>
</div>
