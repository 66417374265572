import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'upload-report',
  templateUrl: './upload-report.component.html'
})
export class UploadReportComponent {
  constructor(
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal
  ) { }

  reportExtension: string = '.docx,.pdf';
  uploadReport:any = {
    fieldInfo: {
      label: this.translate.instant('upload-report.upload-field-label') + ' (.docx ' + this.translate.instant('common.or') + ' .pdf)',
      fieldId: 'file-info',
      accept: '.docx,.pdf',
      required: true,
      renameHandler: this.renameFile
    },
    fileInfo: {},
    reportExtensionsList: ['.docx', '.pdf']
  }

  @Input() reportVersions: any = null;

  renameOn(e:any){
    this.renameFile(e.event);
  }

  renameFile(event:any) {
      event.preventDefault();
      let config = {
          title: this.translate.instant('rename-modal.name-report-rename'),
          type: 'document',
          titleConcept: this.uploadReport.fileInfo.name.substr(0, this.uploadReport.fileInfo.name.lastIndexOf('.')),
          extension: this.uploadReport.fileInfo.name.substr(this.uploadReport.fileInfo.name.lastIndexOf('.')).toLowerCase().trim()
      };

      this.modalService.renameModal(config, [], []).subscribe((result:any)=>{
        if(result.result==='ok'){

          this.uploadReport.fileInfo.name = result.name + config.extension;
          this.validate(this.uploadReport.fileInfo.name);
        }
      })
  }

  validate(fileName:any) {
      let allowRename:boolean = true;
      this.uploadReport.error = '';
      let extensionFileName:any = fileName.substr(fileName.lastIndexOf('.')).toLowerCase().trim();
      if( this.uploadReport.reportExtensionsList.length){
          if(!this.checkExtensionsList(extensionFileName)){
            this.uploadReport.error = this.translate.instant('upload-report.format-errors') + ' ' + this.uploadReport.reportExtensionsList.join(' '+this.translate.instant('common.and')+' ');
            allowRename = false;
          }
      }else if (extensionFileName != this.reportExtension) {
        this.uploadReport.error = this.translate.instant('upload-report.format-error') + ' ' + this.reportExtension;
        allowRename = false;
      }
      if (this.uploadReport.error === '') {
          let found:boolean = false;

          for (let index in this.uploadReport.reportVersions) {
              let version = this.uploadReport.reportVersions[index];
              let versionFileName = version.editPath.substr(version.editPath.lastIndexOf('/') + 1);
              if (fileName.toLowerCase().trim() === versionFileName.toLowerCase().trim()) {
                  found = true;
                  break;
              }
          }
          if (found) {
            this.uploadReport.error = this.translate.instant('upload-report.exist-error');
          }
      }

      this.uploadReport.fieldInfo.allowRename = allowRename;
      this.uploadReport.fieldInfo.error = (this.uploadReport.error != '');
  }

  checkExtensionsList(extensionFileName:any){
    return Boolean(this.uploadReport.reportExtensionsList.filter((reportExtension:any)=>extensionFileName === reportExtension).length)
  }

  itemSelected(e:any) {
      this.uploadReport.file = e.newValue;
      this.uploadReport.fileInfo.name = e.newValue.name;
      this.validate(this.uploadReport.fileInfo.name);
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      file: this.uploadReport.file,
      fileName: this.uploadReport.fileInfo.name
    });
  };

  onDismiss() {
    this.activeModal.close({
      result: 'cancel',
    });
  };

}
