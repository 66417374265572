import { Injectable } from '@angular/core';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Injectable({
  providedIn: 'root'
})
export class IcamQueriesParentService {

  constructor(private apiService: ApiServiceService,
    private modalService: ModalServiceService) { }

   onChanges(changes:any, child:any){
    if(child.isLanding && child.icamQueries.fullScreen && child.fullScreenBox !== '' && child.fullScreenBox !== 'icam-queries'){
      this.maximizeScreen(true, child);
    }
  }

  maximizeScreen(keepClassBox:boolean = false, child:any){
    child.icamQueries.fullScreen = !child.icamQueries.fullScreen;
    if(!keepClassBox){
        child.catchFullScreen.emit({box:'icam-queries'});
    }
  }

  getAnsweredQueries(child:any) {
    let that:any = child.icamQueries;
    if (child.concept?.conceptObjectId) {
        this.apiService.get('notifications/public-alert-process/conceptobject', child.concept?.conceptObjectId)
          .then((data:any)=>{
            that.notificationList = data;
            if(data.length){
              that.showTemplate = true;
            }
        }, (error:any)=>{})

    }
  }

  showNotification(notification:any, child:any){
      this.modalService.notificationModal(notification)
  }

  reSendNotification(notification:any, child:any){
      this.apiService.add('notifications/public-alert-process/notification/'+notification.alertNotificationId+'/resend', {})
        .then((data:any)=>{
            child.icamQueries.notificationList.push(data);
            this.modalService.adviceModal(child.translate.instant('icam-queries.confirm-resend'), 'info:accept:check-round')
        })

  }
}
