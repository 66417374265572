import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomLandingFactory {
  custom:any = null;
  constructor(private route: ActivatedRoute) { }
  setCustom(custom: any){
    this.custom = custom;
  }
  getCustom():any{
    return this.custom;
  }
  isLanding(){
    let isLanding = this.custom!==null;
    if(!this.custom){
      isLanding = this.route.snapshot.data['isLanding'] || this.route.snapshot.firstChild?.data['isLanding']
    }
    return isLanding;
  }
}
