<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--edit-comment': boxComments.edit && !boxComments.fullScreen,
    'predefined-templates__template-box--min-height':!boxComments.commentListFilter.length && !boxComments.showCommentBox,
    'predefined-templates__template-box--max-height':boxComments.commentListFilter.length && !boxComments.showCommentBox,
    'predefined-templates__template-box--expand': boxComments.fullScreen}">
    <div class="concept-legal-documentation__border-box"></div>
    <div class="box-comments__container-icons">
        <i class="lf-icon-open-window predefined-templates__change-view-icon"
            [ngClass]="boxComments.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"
            (click)="maximizeScreen()"
            matTooltip="{{(boxComments.fullScreen? 'common.fold' : 'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
        <i class="predefined-templates__pillow"
            [ngShow]="boxComments.commentList.length">{{boxComments.commentList.length}}</i>
        <i class="lf-icon-add-round box-comments__add-icon"
            [ngShow]="(boxComments.hasPermissions && (boxComments.taskId || boxComments.conceptObjectId)) && !boxComments.taskStateNoEditable"
            (click)="newComment()"
            matTooltip="{{'comments.new-comment' | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
    </div>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-conversation box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text box-comunication-channel__block-text--comment"
        [afTranslate]="'comments.comments'">_Comentarios</p>
    <p class="predefined-templates__block-empty"
        [ngShow]="!boxComments.commentList.length && !boxComments.showCommentBox"
        [afTranslate]="'comments.no-comments'">_No hay comentarios</p>

    <div class="box-comments__add-comment-block"
        [ngShow]="boxComments.showCommentBox &&
            (boxComments.hasPermissions && (boxComments.taskId || boxComments.conceptObjectId)) && !boxComments.taskStateNoEditable">
        <i class="lf-icon-step-final box-comments__add-comment-icon"
          [ngShow]="skin === 'dark-blue'"></i>
        <p class="box-comments__add-comment-title"
            [afTranslate]="'comments.new-comment'">Nuevo comentario</p>
        <textarea
            class="box-comments__add-comment-text"
            [(ngModel)]="boxComments.commentText"></textarea>
        <div class="box-comments__buttons-block">
            <button class="common__button common__button--clean common__button--litle"
                [ngClass]="{'box-comments__button-cancel': skin ==='icam-red'}"
                (click)="closeComment()"
                [afTranslate]="'common.cancel'">Cancelar</button>
            <button class="common__button common__button--active common__button--litle"
                [ngClass]="{'box-comments__button-save': skin ==='icam-red'}"
                (click)="createNewComment(boxComments.commentText)"
                [afTranslate]="'common.add'">Añadir</button>
        </div>
    </div>

    <ul class="box-comments__comment-list"
        [ngShow]="boxComments.showCommentList && boxComments.commentListFilter.length"
        [ngClass]="{'box-comments__comment-list--full-add-comment': boxComments.fullScreen && boxComments.showCommentBox,
            'box-comments__comment-list--full-no-comment': boxComments.fullScreen && !boxComments.showCommentBox }">
        <li class="box-comments__list-item"
            *ngFor="let comments of boxComments.commentListFilter | afOrderBy:'creationDate':true  | afLimitTo:boxComments.fullScreen?boxComments.commentListFilter.length:1; let first = first; let index = index">
            <i class="lf-icon-step-final box-comments__comment-bubble"
                [ngClass]="{'box-comments__comment-bubble--first':first}"></i>
            <p class="box-comments__paragraph">{{comments.creationDate | convertDate: 'without-time' }}
                <span class="box-comments__paragraph box-comments__paragraph--time">{{comments.creationDate | convertDate: 'only-time' }}</span>
            </p>
            <p class="box-comments__author"
                [ngClass]="{'common__line-through':comments.authorIsDeleted}"
                matTooltip="{{comments.author +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                matTooltipPosition="below"
                matTooltipClass="below"
                [matTooltipDisabled]="comments.authorIsDeleted">{{comments.author}}
            </p>
            <p class="box-comments__comment-text"
                [ngClass]="{'box-comments__comment-text--only-last': !boxComments.fullScreen,
                    'box-comments__comment-text--full-screen': boxComments.fullScreen}"
                [ngShow]="!comments.edit"
                [innerHTML]="comments.commentText"></p>
            <div class="box-comments__edit-comment"
                [ngShow]="comments.edit">
                <textarea [id]="'edit-comment-'+comments.commentId"
                    class="box-comments__add-comment-text"
                    [(ngModel)]="comments.commentTextarea">
                </textarea>
                <div class="box-comments__buttons-block">
                    <button class="common__button common__button--clean common__button--litle"
                        [ngClass]="{'box-comments__button-cancel': skin ==='icam-red'}"
                        (click)="closeComment(comments)"
                        [afTranslate]="'common.cancel'">Cancelar</button>
                    <button class="common__button common__button--active common__button--litle"
                        [ngClass]="{'box-comments__button-save': skin ==='icam-red'}"
                        (click)="changeComment(comments)"
                        [afTranslate]="'common.save'">Guardar</button>
                </div>
            </div>
            <button class="box-comments__read-more-block"
                (click)="maximizeScreen()"
                [ngShow]="boxComments.commentList.length > 1 && !boxComments.fullScreen">
                <i class="lf-icon-visible box-comments__read-more-icon"></i>
                <span class="box-comments__read-more-text"
                    [afTranslate]="'comments.read-more'">Leer más</span>
            </button>

            <div class="box-comments__options-block"
                [ngShow]="comments.showOptions">
                <ul class="box-comments__menu">
                    <li class="attachments-template__menu-options">
                        <i class="lf-icon-trash"
                            matTooltip="{{'common.remove' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="deleteComment(comments)">
                        </i>
                    </li>
                    <li class="attachments-template__menu-options">
                        <i class="lf-icon-editable-form"
                            matTooltip="{{'common.edit' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="editComment(comments)">
                        </i>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>
