<div class="concept-list">
  <div class="concept-list__table"
      [id]="'concept_'+conceptInfo.conceptId">
      <div class="common__table-header"
            [ngShow]="conceptList.conceptList.length">
          <div class="concept-list__table-col concept-list__table-col--title-poll concept-list__table-col--top-1">
              <filter-by-search
                  [item]="conceptList.titleFilter"
                  [filterOrder]="conceptList.dateFilter"
                  [order]="conceptList.order"
                  [filterText]="conceptList.titleWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'title')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential"></div>
          <div class="concept-list__table-col concept-list__table-col--reference-poll concept-list__table-col--top-1">
            <filter-by-search
                  [item]="conceptList.referenciaPollFilter"
                  [filterText]="conceptList.referenciaWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'referencia')">
            </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--reference-poll concept-list__table-col--top-1">
              <filter-by-search
                [item]="conceptList.userPollFilter"
                [filterText]="conceptList.numColegiadoWordFilter"
                (launchActionSearch)="orderBySearch($event, 'numColegiado')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--colegiado-poll concept-list__table-col--top-1">
              <filter-by-search
                  [item]="conceptList.namePollFilter"
                  [filterText]="conceptList.nombreColegiadoWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'nombreColegiado')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
              <div (click)="orderDate('creationDate')">
                  <p class="common__table-header-text"
                      [afTranslate]="'common.creation'">Creación</p>
                  <i class="common__order-by"
                      [ngClass]="{'lf-icon-arrow-down':(conceptList.dateFilter === 'creationDate' && conceptList.order) || conceptList.dateFilter !== 'creationDate',
                          'lf-icon-arrow-up':conceptList.dateFilter === 'creationDate' && !conceptList.order,
                          'common__order-by--active': conceptList.dateFilter === 'creationDate'}"></i>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
              <div (click)="orderDate('modificationDate')">
                  <p class="common__table-header-text"
                      [afTranslate]="'common.modification'">Modificación</p>
                  <i class="common__order-by"
                      [ngClass]="{'lf-icon-arrow-down':(conceptList.dateFilter === 'modificationDate' && conceptList.order) || conceptList.dateFilter !== 'modificationDate',
                          'lf-icon-arrow-up':conceptList.dateFilter === 'modificationDate' && !conceptList.order,
                          'common__order-by--active': conceptList.dateFilter === 'modificationDate'}"></i>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions-header">
              <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">Acciones</p>
          </div>
      </div>
      <div class="common__table-row"
          *ngFor="let evaluateConcept of conceptList.conceptListFilter | afOrderBy:[conceptList.dateFilter, 'title']:conceptList.order | afLimitTo:conceptList.configPagination.pageSize:conceptList.configPagination.pageSize*(conceptList.currentPage - 1)">
          <div class="concept-list__table-col concept-list__table-col--title-poll common__cursor-pointer"
                (click)="launchActionOption('open', evaluateConcept)">
                <parragraph-tooltip [text]="evaluateConcept.title"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential">
              <div [ngShow]="evaluateConcept.confidential"
                    ngbDropdown
                    [autoClose]="'outside'"
                    [id]="'concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId]">
                  <i class="lf-icon-lock concept-list__icon-confidential"
                      ngbDropdownToggle
                      matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </i>
                  <list-in-dropdown ngbDropdownMenu role="menu"
                      class="dropdown-menu concept-list__box-select-list"
                      [list]="evaluateConcept.confidentialList"
                      [type]="'confidential'"
                      (closeDropdownEmit)="closeDropdown('concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId])"></list-in-dropdown>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--reference-poll">
                <parragraph-tooltip [text]="evaluateConcept.referencia"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--reference-poll">
              <parragraph-tooltip [text]="evaluateConcept.numColegiado"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--colegiado-poll">
              <parragraph-tooltip [text]="evaluateConcept.nombreColegiado"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--creation-date">
            <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate:''"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--creation-date">
              <parragraph-tooltip [text]="evaluateConcept.modificationDate | convertDate:''"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions">
              <ul class="common__options-row">
                  <li class="common__menu-options-row">
                      <i class="lf-icon-visible"
                          (click)="launchActionOption('open', evaluateConcept)"
                          matTooltip="{{conceptList.descriptionEdit}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && conceptInfo.type !== 0">
                      <i class="lf-icon-open-window"
                          (click)="openInNewTab(evaluateConcept)"
                          matTooltip="{{conceptList.description}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none'">
                      <i class="lf-icon-lock"
                          (click)="openModalConfidential(evaluateConcept)"
                          matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
              </ul>
              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
          </div>
      </div>
      <div class="concept-list__table-foot"
            [ngClass]="{'concept-list__table-foot--no-items': !conceptList.conceptList.length}"
            [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize || conceptList.showResultsReports">
          <div [ngShow]="conceptInfo.poll && conceptList.showResultsReports"
                class="concept-list__block-info-archive"
                [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none common__button-border-none--left"
                      (click)="showReports()">
                  <i class="lf-icon-analysis common____icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                        [afTranslate]="'concept-list.view-report-results'">_Ver informe de resultados</span>
              </button>
          </div>
          <div class="concept-list__pagination-block"
                [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize">
                <pagination-control *ngIf="conceptList.conceptListFilter?.length"
                    [config]="conceptList.configPagination"
                    [collectionSize]="conceptList.conceptListFilter?.length"
                    [(currentPage)]="conceptList.currentPage"
                    (configuredTotalChange)="conceptList.configuredTotal = $event.configuredTotal">
                </pagination-control>
                <div class="common__pagination-count" [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize">
                  <span class="notranslate">{{conceptList.configuredTotal.first}}-{{conceptList.configuredTotal.last}}</span>
                  <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                  <span class="notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </div>
                <div class="common__pagination-count"
                  [ngShow]="conceptList.configPagination.pageSize >= conceptList.conceptListFilter?.length">
                  <span>
                    <span [afTranslate]="'common.total'">_Total</span>
                    <span class="common__total-number-element notranslate">{{conceptList.conceptListFilter?.length}}</span>
                  </span>
                </div>
          </div>
      </div>
  </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="conceptList.configurationPdf"></pdf-table>
