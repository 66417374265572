<div class="users-admin-main" >
  <div class="users-admin-main__toolbar">
      <div class="users-admin-main__toolbar-left-options">
          <div>
              <span [afTranslate]="'users-admin.main.user-type-filter'">Filtrar por tipo de usuario</span>
              <ul class="users-admin-main__rounded-toolbar">
                  <li [ngClass]="{'common__selected': usersAdminMain.typeOptionFilter=='group'}">
                      <button class="users-admin-main__icon-button"
                            (click)="filterByType('group')"
                            matTooltip="{{'users-admin.main.group' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above">
                          <i class="lf-icon-group users-admin-main__rounded-toolbar-icons users-admin-main__rounded-toolbar-icons--group"></i>
                      </button>
                  </li>
                  <li [ngClass]="{'common__selected': usersAdminMain.typeOptionFilter=='user'}">
                      <button class="users-admin-main__icon-button"
                          (click)="filterByType('user')"
                          matTooltip="{{'users-admin.main.user' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                          <i class="lf-icon-favorite users-admin-main__rounded-toolbar-icons"></i>
                      </button>
                  </li>
                  <li [ngClass]="{'common__selected': usersAdminMain.typeOptionFilter=='contact'}">
                      <button class="users-admin-main__icon-button"
                          (click)="filterByType('contact')"
                          matTooltip="{{'users-admin.main.contact' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                          <i class="lf-icon-user users-admin-main__rounded-toolbar-icons"></i>
                      </button>
                  </li>
              </ul>
          </div>
          <div class="users-admin-main__contacts-options"
              [ngShow]="usersAdminMain.typeOptionFilter=='contact'">
              <div class="users-admin-main__contacts-options-clients"
                  ngbDropdown
                  [autoClose]="'outside'">
                  <div class="users-admin-main__contacts-options-client-name"
                    ngbDropdownToggle>
                      <i class="users-admin-main__contacts-options-icon-header lf-icon-filter-2"></i>
                      <span [afTranslate]="'users-admin.main.clients-filter'"
                        [ngHide]="usersAdminMain.selectedClient.clientId">Filtrar por organización asociada</span>
                      <span [ngShow]="usersAdminMain.selectedClient">{{usersAdminMain.selectedClient.name}}</span>
                      <i class="lf-icon-close users-admin-main__icon-selected"
                          (click)="resetFilterClient($event)"
                          [ngShow]="usersAdminMain.selectedClient.clientId"></i>
                  </div>
                  <div class="users-admin-main__contacts-options-clients-list users-admin-main__column-filter"
                    ngbDropdownMenu>
                      <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100"
                          (click)="stopPropagation($event)">
                          <input class="common__find-in-dropdown"
                              type="text"
                              [(ngModel)]="usersAdminMain.clientSearch"
                              [placeholder]="'users-admin.main.search-company' | afTranslate">
                          <i class="common__find-dropdown-icon-find"
                              [ngClass]="{'lf-icon-search':usersAdminMain.clientSearch === '',
                                  'lf-icon-close-search': usersAdminMain.clientSearch !==''}"
                              (click)="resetSearch($event)"></i>
                      </div>

                      <ul class="users-admin-main__column-filter-list">
                          <li class="common__select-item filter-header__list-items-v2"
                              *ngFor="let client of usersAdminMain.clients | afOrderBy:['name']:false | byWord:usersAdminMain.clientSearch:['name']"
                              (click)="filterByClient($event, client)"
                              ngbDropdownItem>
                              <div class="filter-header__block-name-child">
                                  <span class="filter-header__name-child users-admin-main__name-child">{{client.name}}</span>
                              </div>
                              <i class="common__select-icon lf-icon-check-round-full"></i>
                          </li>
                      </ul>
                  </div>
              </div>
              <field-type-switch [label]="'users-admin.main.select-contacts' | afTranslate"
                                  [checked]="usersAdminMain.selectionEnabled"
                                  (callback)="selectAll(false, $event.value)">
              </field-type-switch>
          </div>
      </div>
      <div class="users-admin-main__toolbar-right-options">
          <div class="users-admin-main__add"
              ngbDropdown
              [autoClose]="'outside'">
              <div class="users-admin-main__block-buttons"
                  [ngShow]="usersAdminMain.typeOptionFilter=='contact'">
                  <button class="lf-icon-contacts common__button-checked"
                      matTooltip="{{'users-admin.main.export-template-tooltip' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="exportContacts(false)"></button>
                  <button class="lf-icon-download common__button-checked"
                      [disabled]="usersAdminMain.filterPersonsList.length === 0 || usersAdminMain.selectionEnabled && usersAdminMain.selectionCount === 0"
                      matTooltip="{{'users-admin.main.export-tooltip' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="exportContacts(true)"></button>
                  <button class="lf-icon-up-file common__button-checked"
                      matTooltip="{{'users-admin.main.import-tooltip' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="importContacts()"></button>
              </div>
              <div class="users-admin-main__new-inline-button"
                ngbDropdownToggle>
                <button class="lf-icon-sum common__button-checked"></button>
              </div>
              <ul class="users-admin-main__list-menu-block dropdown-menu"
                  ngbDropdownMenu>
                  <li class="common__list-dropdown-option">
                      <button class="common__button-option-list-dropdown"
                          [routerLink]="['new/user']"
                          routerLinkActive="active">
                          <i class="lf-icon-user-special common__icon-option-list-dropdown"></i>
                          <span class="common__text-menu-option-dropdown"
                              [afTranslate]="'users-admin.main.user'">Usuario del sistema</span>
                      </button>
                  </li>
                  <li class="common__list-dropdown-option">
                      <button class="common__button-option-list-dropdown"
                          [routerLink]="['new/contact']"
                          routerLinkActive="active">
                          <i class="lf-icon-user common__icon-option-list-dropdown"></i>
                          <span class="common__text-menu-option-dropdown"
                              [afTranslate]="'users-admin.main.contact'">Contacto</span>
                      </button>
                  </li>
                  <li class="common__list-dropdown-option">
                      <button class="common__button-option-list-dropdown common__button-option-list-dropdown--last"
                          [routerLink]="['new/group']"
                          routerLinkActive="active">
                          <i class="lf-icon-group common__icon-option-list-dropdown"></i>
                          <span class="common__text-menu-option-dropdown"
                              [afTranslate]="'users-admin.main.group'">Grupo</span>
                      </button>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="common__table-block">
    <div class="common__table-new-version">
      <div class="common__table-header">
        <div class="users-admin-main__col users-admin-main__col--type">
            <filter-by-list [ngShow]="!usersAdminMain.selectionEnabled"
                [type]="'list'"
                [item]="usersAdminMain.typeFilter"
                [list]="usersAdminMain.typeList"
                [topStyle]="'29'"
                (filterElementEmit)="selectTypeOperation($event.source, 'type')">
            </filter-by-list>
            <i [ngShow]="usersAdminMain.selectionEnabled"
                class="users-admin-main__icon-check users-admin-main__icon-check--header"
                [ngClass]="usersAdminMain.selectionCount === usersAdminMain.filterPersonsList.length?'lf-icon-check-full':'lf-icon-box-inactive'"
                (click)="selectAll()"></i>
        </div>
        <div class="users-admin-main__col users-admin-main__col--name">
          <filter-by-search
              [item]="usersAdminMain.fullNameFilter"
              (launchActionSearch)="orderBySearch($event, 'fullName')">
          </filter-by-search>
        </div>
        <div class="users-admin-main__col users-admin-main__col--position">
          <p class="common__table-header-text">
              <span-tooltip [text]="'common.position' | afTranslate"></span-tooltip>
          </p>
        </div>
        <div class="users-admin-main__col users-admin-main__col--company-name">
          <filter-by-list [type]="'listFind'"
              [item]="usersAdminMain.companyNameFilter"
              [list]="usersAdminMain.companyNameList"
              [topStyle]="'29'"
              (filterElementEmit)="selectTypeOperation($event.source, 'companyName')">
          </filter-by-list>
        </div>
        <div class="users-admin-main__col users-admin-main__col--email">
          <filter-by-search
              [item]="usersAdminMain.emailFilter"
              (launchActionSearch)="orderBySearch($event, 'email')">
          </filter-by-search>
        </div>
        <div class="users-admin-main__col users-admin-main__col--phone1">
          <p class="common__table-header-text">
              <span-tooltip [text]="'new-contact.phonenumber1' | afTranslate"></span-tooltip>
          </p>
        </div>
        <div class="users-admin-main__col users-admin-main__col--role">
          <filter-by-list [type]="'listFind'"
              [widthClass]="'200px'"
              [item]="usersAdminMain.rolesFilter"
              [list]="usersAdminMain.rolesList"
              (filterElementEmit)="selectTypeOperation($event.source, 'roles')">
          </filter-by-list>
        </div>
        <div class="users-admin-main__col users-admin-main__col--actions">
          <p class="common__table-header-text common__table-header-text--right">
              <span-tooltip [text]="'common.actions' | afTranslate"></span-tooltip>
          </p>
        </div>
      </div>
      <div class="common__table-body common__table-body--10-elements"
          [ngClass]="{'common__table-body--more-elements': usersAdminMain.filterPersonsList.length > 10 || usersAdminMain.filterPersonsList.length+usersAdminMain.countGroupsOpen > 10}">
        <div *ngFor="let person of usersAdminMain.filterPersonsList | afOrderBy:['name']:false | byWord:usersAdminMain.searchPerson:['fullName', 'position', 'companyName', usersAdminMain.searchGroupPersons] | afLimitTo:usersAdminMain.configPagination.pageSize:usersAdminMain.configPagination.pageSize*(usersAdminMain.currentPage - 1)">
            <users-admin-person-row *ngIf="person.type!=='group'"
              class="common__table-row"
              [ngClass]="{'users-admin-main__col-table-selected': person.selected,
                'common__table-row--disabled': usersAdminMain.selectionEnabled}"
              [person]="person"
              [selectionEnabled]="usersAdminMain.selectionEnabled"
              [selectionCount]="usersAdminMain.selectionCount"
              (deletePersonRow)="deletePerson(person)"
              (selectToExportPerson)="selectToExport(person)"></users-admin-person-row>
            <div *ngIf="person.type==='group'"
                class="common__table-row users-admin-main__table-row-group">
                <div class="users-admin-main__col users-admin-main__col--type users-admin-main__table-icon">
                    <button class="common__icon-button"
                        [ngClass]="person.expanded?'lf-icon-angle-up':'lf-icon-angle-down'"
                        (click)="expandedPerson(person)">
                    </button>
                </div>
                <div class="users-admin-main__group-col users-admin-main__group-col--name common--strong">
                    <parragraph-tooltip [text]="person.fullName"></parragraph-tooltip>
                </div>
                <div class="users-admin-main__group-col users-admin-main__group-col--members">
                    <i class="lf-icon-group users-admin-main__user-group-info" ></i>
                    <span class="common__rectangle-info users-admin-main__rectangle-info">{{person.members.length}}</span>
                </div>
                <div class="users-admin-main__group-col users-admin-main__group-col--actions">
                    <ul class="common__options-row">
                      <li class="common__menu-options-row"
                          [routerLink]="['edit', person.type, person.id]"
                          routerLinkActive="active">
                          <i class="lf-icon-editable-form"
                              matTooltip="{{'common.edit' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below"></i>
                      </li>
                      <li class="common__menu-options-row"
                          [routerLink]="['security', person.type, person.id]"
                          routerLinkActive="active">
                          <i class="lf-icon-key"
                              matTooltip="{{'users-admin.permissions' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above"></i>
                      </li>
                      <li class="common__menu-options-row"
                          (click)="deletePerson(person)">
                          <i class="lf-icon-trash"
                              matTooltip="{{'common.remove' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above"></i>
                      </li>
                  </ul>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"
                    [ngClass]="{'common__icon-ellipsis--disabled': usersAdminMain.selectionEnabled}"></i>
                </div>
            </div>
            <div *ngIf="person.type==='group' && person.expanded"
              class="users-admin-main__table-members">
              <users-admin-person-row
                class="common__table-row"
                *ngFor="let personGroup of person.members"
                [person]="personGroup"
                [selectionEnabled]="usersAdminMain.selectionEnabled"
                [selectionCount]="usersAdminMain.selectionCount"
                (deletePersonRow)="deletePerson(personGroup)"></users-admin-person-row>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="users-admin-main__pagination">
        <div class="common__block-search">
          <input class="common__search-input"
              [ngShow]="usersAdminMain.search"
              [(ngModel)]="usersAdminMain.searchPerson"
              placeholder="{{'common.search' | afTranslate}}"
              [focusWhen]="usersAdminMain.search">
          <span class="common__text-search"
              [ngShow]="!usersAdminMain.search"
              (click)="toggleSearch()"
              [afTranslate]="'common.search'"></span>
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !usersAdminMain.search,
                'lf-icon-close-search common__icon-button-search--open': usersAdminMain.search}"
              (click)="toggleSearch()">
          </button>
      </div>
      <pagination-control class="common__pagination-block"
          [ngShow]="usersAdminMain.filterPersonsList.length > usersAdminMain.configPagination.pageSize"
          [config]="usersAdminMain.configPagination"
          [collectionSize]="usersAdminMain.filterPersonsList.length"
          [(currentPage)]="usersAdminMain.currentPage"
          (configuredTotalChange)="usersAdminMain.configuredTotal = $event.configuredTotal">
      </pagination-control>
      <div class="common__block-footer-total common__block-footer-total--bottom-2">
          <span [ngShow]="usersAdminMain.filterPersonsList.length > usersAdminMain.configPagination.pageSize">
            <span class="notranslate">{{usersAdminMain.configuredTotal.first}}-{{usersAdminMain.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{usersAdminMain.filterPersonsList.length}}</span>
          </span>
          <span [ngShow]="!(usersAdminMain.filterPersonsList.length > usersAdminMain.configPagination.pageSize)">
            <span [afTranslate]="'common.total'">_Total</span>
            <span class="common__total-number-element notranslate">{{usersAdminMain.filterPersonsList.length}}</span>
          </span>
      </div>
  </div>
</div>
