import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-user',
  templateUrl: './customize-field-user.component.html'
})
export class CustomizeFieldUserComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldUser: any = {
    tinyMceOptions: {},
    contentReportInfo:{
      label: this.translate.instant('common.report-field')
    },
    disabled: 0
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() reportList: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() concept: any = {};
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldUser
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue
      that.fieldCopy = cloneDeep(that.field);
      that.contentReportInfo.noEditable = that.field.noEditable
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  itemSelected(e: any, item:any){
      this.customizeFieldUser.field[item] = e.newValue;
      if (item === 'fieldContentTypeId' && e.newValue !== 'emai') {
          this.customizeFieldUser.isIdentifier = false;
      }
      this.modifyField();
  }

  getEditorValue(event:any){
    this.customizeFieldUser.field.description = event.model;
  }

  cancelFieldText(){
    this.customizeFieldUser.field = cloneDeep(this.customizeFieldUser.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldUser.field
        }
    })
  }

  saveFieldText(){
    this.customizeFieldUser.fieldCopy = cloneDeep(this.customizeFieldUser.field);
    this.changeInField.emit({
          change:{
              type: 'save',
              field: this.customizeFieldUser.field
          }
      })
  }

  changeMultiline(){
      this.customizeFieldUser.defaultValueInfo.multiline = this.customizeFieldUser.field.multiline;
      this.modifyField();
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldUser.field);
  }
  changeOptionOn(e:any, type:string){
    this.customizeFieldUser.field[type] = e.model
    this.modifyField();
  }
}
