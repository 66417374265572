import { Directive, OnInit, ElementRef  } from '@angular/core';

@Directive({
  selector: '[scrollConcept]'
})
export class ScrollConceptDirective implements OnInit {
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    // let scrollable: any = document.querySelector('.common__view');
    let scrollable: any = document.getElementById('compliance-view-body');
    scrollable.addEventListener('scroll', ()=>{
      this.onScroll();
    })
  }

  onScroll() {
    let elementConceptForm: any = document.getElementById('block-concept-form');
    if(elementConceptForm){
      let elementConceptFormClientRect = elementConceptForm.getBoundingClientRect();
      if(elementConceptFormClientRect.top < 0){
        this.elementRef.nativeElement.classList.add('common__concept-menu-fixed-scroll--first');
      }else{
        this.elementRef.nativeElement.classList.remove('common__concept-menu-fixed-scroll--first');
      }
    }
  }
}
