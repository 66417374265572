import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {
  otherDatesDay: any = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
  }
  enDatesDay: any = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6

  }
  returnDate: string ='';
  constructor(private commonUtils: CommonUtilsService){
    this.commonUtils.setMomentLanguage()
  }
  transform(item: any,toReturn: string='', otherItem:any = moment()): any {

    if(item){
      switch (toReturn){
          case 'day-month':
              return moment.utc(item).format('DD MMM').replace('.','').replace(/\b\w/g, function(l){ return l.toUpperCase() }) //23 Dic
          case 'all-month-short':
              return moment.utc(item).format('DD MMM, YYYY').replace('.','').replace(/\b\w/g, function(l){ return l.toUpperCase() }) //23 Dic, 2016
          case 'calendar':
              return moment(item).format('DD [ de ]') + moment.utc(item).format('MMMM').replace(/\b\w/g, function (l) { return l.toUpperCase() }) + moment.utc(item).format('[ a las ] HH:mm'); //23 de Diciembre a las 00:00
          case 'calendar-year':
              return moment(item).format('LL');
              // return moment(item).format('DD [ de ]') + moment.utc(item).format('MMMM').replace(/\b\w/g, function (l) { return l.toUpperCase() }) + moment.utc(item).format('[ de ] YYYY'); //23 de Diciembre de 2016
          case 'calendar-all':
              return moment(item).format('DD [ de ]') + moment.utc(item).format('MMMM').replace(/\b\w/g, function (l) { return l.toUpperCase() }) + moment.utc(item).format('[ de ] YYYY') + moment.utc(item).format('[ a las ] HH:mm'); //23 de Diciembre de 2016 a las 00:00
          case 'calendar-week':
              return moment(item).format('DD') +' - '+ moment.utc(otherItem).format('DD MMM');
          case 'without-time':
              return moment.utc(item).format('DD/MM/YYYY') // 23/12/2016
          case 'without-time-and-hour':
            return moment.utc(item).format('DD/MM/YYYY - HH:mm') // 23/12/2016
          case 'only-time':
              return moment.utc(item).format('HH:mm:ss') // 13:23:41
          case 'full-date':
              //var time = moment(item).set({hour:23,minute:00,second:00}).format('MMMM YYYY')
              //return time;
              return moment.utc(item+1).format('MMMM YYYY');
          case 'month-year':
              return moment.utc(item).format('MMMM YYYY');
          case 'only-month':
              return moment(item).format('MMMM');
          case 'only-month-short':
            return moment(item+'/01/2022').format('MMM');
          case 'day-week':
              return moment(item).format('dddd');
          // case 'week-day':
          //     var datesDay = this.otherDatesDay;
          //     // if(globalCfg.skin === 'uk-steel-blue'){
          //     //     datesDay = enDatesDay
          //     // }
          //     return moment().weekday(datesDay[item]).format('ddd') ;
          // case 'week-day-min':
          //     var datesDay = this.otherDatesDay;
          //     // if(globalCfg.skin === 'uk-steel-blue'){
          //     //     datesDay = enDatesDay
          //     // }
          //     return moment().weekday(datesDay[item]).format('dd') ;
          case 'be-special':
              return moment(item).format('DD/MM/YYYY');
          case 'be-calendar':
              return moment(item).format('MMM. DD, YYYY');
          case 'all-with-second':
              return moment.utc(item).format('DD/MM/YYYY HH:mm:ss')
          case 'month-calendar':
                return moment().month(item-1).format('MMMM');
          case 'month-calendar-min':
            return moment().month(item-1).format('MMM');
          case 'day-calendar':
                return moment().weekday(item-1).format('ddd');
          case 'day-calendar-min':
            return moment().weekday(item-1).format('dd');
          default:
              return moment.utc(item).format('DD/MM/YYYY HH:mm') // 23/12/2016 00:00
      }
    }
  }

}


