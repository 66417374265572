import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';

@Component({
  selector: 'consult-lists-modal',
  templateUrl: './consult-lists-modal.component.html'
})
export class ConsultListsModalComponent implements OnInit {
  consultListsModal: any = {
    configuration: {}
  }
  constructor(private dateConverterService: DateConverterService,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }
  @Input() configuration: any;

  ngOnInit(): void {
    this.configureData();
  }

  configureData(){
    this.consultListsModal.configuration = this.configuration;
    if(this.consultListsModal.configuration.infoModal.detected){
        this.consultListsModal.configuration.infoModal.resultColor = 'yellow';
        for(let i = 0; i< this.consultListsModal.configuration.infoModal.detections.length; i++){
            let detection = this.consultListsModal.configuration.infoModal.detections[i];
            detection.profileNotes = detection.profileNotes? detection.profileNotes.split('\n').join('<br>'):detection.profileNotes;
            if (detection.prpCategories?.includes('Sanctions Lists') && (detection.prpType === 'SIE' || detection.prpType === 'SIP')){
                this.consultListsModal.configuration.infoModal.resultColor = 'red';
                break;
            }
        }
        this.consultListsModal.configuration.infoModal.iconAnswer = this.consultListsModal.configuration.infoModal.resultColor === 'red'?'lf-icon-unsolved':'lf-icon-warning';
    }else{
        this.consultListsModal.configuration.infoModal.resultColor = 'green';
        this.consultListsModal.configuration.infoModal.iconAnswer = 'lf-icon-check-round';
    }
    this.consultListsModal.configuration.infoModal.messageAnswer = this.translate.instant('consult-lists-modal.message-answer-'+this.consultListsModal.configuration.infoModal.resultColor);
    this.consultListsModal.configuration.infoModal.typeCol = ['confidenceLevel', 'name', 'personType', 'state', 'creationDate', 'aliases']
    this.consultListsModal.configuration.infoModal.exportDate = this.dateConverterService.toIso(this.dateConverterService.toDate(this.consultListsModal.configuration.infoModal.dossierTimestamp));
    this.consultListsModal.configuration.infoModal.typePdf = 'report-consult-list';
    this.consultListsModal.configuration.infoModal.configurated = true;
  }

  generateReport(){
    this.consultListsModal.configuration.htmlPrint = $('#pdf-report-consult-list').html();
    this.handleAccept();
}

  handleAccept() {
    this.activeModal.close({
      result: 'ok'
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
