<div class="subcontainer-multiple" >
    <div class="subcontainer-multiple__table-duplicated"
        *ngFor="let subcontainer of subcontainerMultiple.subcontainerDuplicated; let index = index;let first = first">
        <div class="subcontainer-multiple__duplicated">
            <div class="concept-form__subcontainer-block subcontainer-multiple__subcontainer-block"
                [ngClass]="{'subcontainer-multiple__subcontainer-block--hovered': first && subcontainerMultiple.concept?.productTypeId !== 4 && !subcontainerMultiple.concept?.poll && userPermissions !== 'none'}"
                [id]="'group-subcontainer-'+index">
                <div class="concept-form__block-icons"
                    [ngShow]="(subcontainer.references.length && first) || subContainer.relatedInfo.length">
                    <tooltip-law
                        [concept]="subcontainerMultiple.concept"
                        class="concept-form__icon-subgroup concept-form__icon-subgroup--laws"
                        [references]="subcontainer.references">
                    </tooltip-law>
                      <i class="lf-icon-search-text concept-form__icons concept-form__icons--info-subcontainer"
                        (click)="openRelatedInfo(subContainer.relatsedInfo)"
                        [ngShow]="subContainer.relatedInfo.length"
                        matTooltip="{{'common.related-info' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </i>
                </div>
                <div class="concept-form__tooltip-info concept-form__tooltip-info--subgroup"
                    [ngShow]="subcontainer.showInfo && !subcontainerMultiple.openLaw && first">
                    <p class="concept-form__tooltip-inner-text"
                        *ngFor="let subReference of subcontainer.references">
                        {{subReference.text}}
                    </p>
                </div>
                    <div class="concept-form__subcontainer-text concept-form__subcontainer-text--ellipsis"
                      [ngClass]="{'concept-form__subcontainer-text--highlight': subcontainerMultiple.subContainer?.isHighlight && first}">
                      <i class="lf-icon-important concept-form__icon-arrow"
                          [ngShow]="subcontainerMultiple.subContainer?.isHighlight && first"></i>
                      <span-tooltip [ngClass]="{'concept-form__text-title-group--highlight': subcontainerMultiple.subContainer.isHighlight}"
                        [text]="subcontainer.name"></span-tooltip>
                    </div>
                <div class="concept-form__subcontainer-icons-block"
                    [ngShow]="!subcontainerMultiple.lockedSubcontainer">
                    <i class="lf-icon-add-round concept-form__icon-subgroup"
                        [ngClass]="{'concept-form__icon-subgroup--modified': subcontainerMultiple.concept?.productTypeId === 4 || subcontainerMultiple.concept?.poll}"
                        [ngShow]="first && subcontainerMultiple.concept.conceptObjectId && userPermissions !=='none'"
                        (click)="duplicateSubcontainer()"
                        matTooltip="{{'subcontainer-multiple.new-entry' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above"></i>
                    <i class="lf-icon-trash concept-form__icon-subgroup concept-form__icon-subgroup--modified"
                        [ngHide]="first"
                        (click)="deleteDuplicated(subcontainer)"
                        matTooltip="{{'subcontainer-multiple.delete-entry' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above"></i>
                    <div [ngHide]="!first || subcontainerMultiple.concept?.productTypeId === 4 || subcontainerMultiple.concept?.poll || userPermissions === 'none'"
                        class="concept-form__more-options-multiple">
                        <i class="lf-icon-kebab-menu concept-form__icon-share-menu-multiple"></i>
                        <div class="concept-form__submenu-share">
                            <i class="lf-icon-share concept-form__icon-share-submenu"
                                (click)="shareFieldContainer(subcontainer)"
                                [matTooltip]="'share-document.share-fieldcontainer' | afTranslate"
                                matTooltipPosition="below"
                                matTooltipClass="below"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="concept-form__duplicate-subcontainer-block"
                [ngShow]="subcontainerMultiple.showDuplicateSubcontainer && first">
                <i class="lf-icon-close concept-form__close-duplicate-subcontainer"
                    (click)="subcontainerMultiple.showDuplicateSubcontainer = !subcontainerMultiple.showDuplicateSubcontainer"></i>
                <p class="concept-form__duplicate-subcontainer-title"
                    [afTranslate]="'subcontainer-multiple.clone'">
                    clonar subgrupo</p>
                <div class="concept-form__copys-block">
                    <p class="concept-form__copy-text"
                    [afTranslate]="'subcontainer-multiple.num-of-copies'">
                    número de copias</p>
                    <div class="concept-form__copy-number">
                        <span [ngShow]="subcontainerMultiple.subContainer.numberOfCopys < 10">0</span>
                        <span [ngClass]="{'concept-form__copy-bind':subcontainerMultiple.subContainer.numberOfCopys < 10}">
                            {{subcontainerMultiple.subContainer.numberOfCopys}}
                        </span>
                    </div>
                    <!-- <button class="concept-form__number-copy-button concept-form__number-copy-button--top"
                        (click)="changeDuplicateNumber('add')">
                    </button>
                    <button class="concept-form__number-copy-button concept-form__number-copy-button--bottom"
                        ng-click="changeDuplicateNumber('minus')"></button> -->
                </div>
                  <!--<div class="common__create common__create--subcontainer float-end">
                    <button class="subcontainer-multiple__duplicate-button"
                        (click)="saveDuplicate(subcontainer)"
                        [afTranslate]="'common.save'">Guardar </button>
                    <div class="new-client__button-line-create new-client__button-line-create--duplicate"></div>
                </div> -->
            </div>
            <div class="concept-form__form-subcontainer-concept concept-form__form-subcontainer-concept--multiple subcontainer-multiple__correct-margin">
                <div *ngFor="let subField of subcontainer.fields">
                    <field-item *ngIf="subField.show && subField.showWithProperty"
                        [ngHide]="subField.hidden"
                        [fieldInfo]="subField"
                        [fieldContainer]="subcontainer"
                        [evaluatedConcepts]="subcontainer.evaluatedFields"
                        [evaluatedFields]="subcontainerMultiple.concept.evaluatedFields"
                        [isPrivate]="subcontainerMultiple.isPrivate"
                        [viewMode]="viewMode"
                        (evaluatedConceptChange)="changesInEvaluatedSubContainer(subField)"
                        [concept]="subcontainerMultiple.concept"
                        [conceptLocked]="subcontainerMultiple.conceptLocked">
                    </field-item>
                </div>
            </div>
        </div>
    </div>
</div>
