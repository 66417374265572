import { Injectable } from '@angular/core';
import $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  triggerDownload(blobData: any, fileName: any) {
    let urlApi: any = (window.URL || window.webkitURL);
    let nav: any = (window.navigator as any);
    if (nav?.msSaveOrOpenBlob) {
      //IE 10,11, edge //DEPRECATED
      nav.msSaveOrOpenBlob(blobData, fileName);
    }else if (urlApi?.createObjectURL) {
      let anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = urlApi.createObjectURL(blobData);
      anchor.click();
    }else {
        throw ('the browser does not support downloading');
    }
  }
}
