import { Component,  Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'documentation-report-list',
  templateUrl: './documentation-report-list.component.html'
})
export class DocumentationReportListComponent implements OnChanges, OnInit {
  skin: string = this.globalCfg.skin;
  userPermissions:any = this.permissions.getPermissions();
  expandMenu: any = ExpandMenu;
  documentationReportList:any = {
    titleFilter: {
      name: this.translate.instant('common.title'),
      orderCol: true,
      filterOrder: 'title',
      width: '200px'
  },
  authorFilter: {
      name: this.translate.instant('common.author')
  },
  order: true,
  dateFilter: 'dateExpiration',
  filters: ['author'],
  }
  constructor(private globalCfg:GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private expandedMenuService: ExpandedMenuService,
    private filterColumnService: FilterColumnService,
    private commonUtilsService:CommonUtilsService) { }
  @Input() documentationReport: any = null;
  @Output() launchAction = new EventEmitter();

  ngOnChanges(): void {
    if(this.documentationReport){
      this.documentationReportList.documentationReport = this.documentationReport
      this.configureDocuments();
    }
  }

  ngOnInit(): void {
    this.documentationReportList.menuDocument = this.expandedMenuService.menuDocumentationReports(this.expandMenu['menuDocumentReport'].menu);
3  }

  configureInit(){
      if(this.skin === 'icam-red'){
        this.documentationReportList.authorFilter.name = this.translate.instant('common.creator');
      }
  }

    configureDocuments(){
        this.documentationReportList.documentationReport.forEach((document:any) => {
          document.title = document.name;
          document.author = document.createdBy;
          document.dateExpiration = document.created;
        });

        this.documentationReportList.documentationList = this.documentationReportList.documentationReport;
        this.documentationReportList.filterDocumentsList = this.documentationReportList.documentationList;
        this.getFilters();
    }

      getFilters(){
        this.documentationReportList.filters.forEach((filter:any) => {
          this.documentationReportList[filter+'List'] = this.filterColumnService.extractList(filter, this.documentationReportList.documentationList);
          this.documentationReportList[filter+'ListFilter'] = [];
        });
      }

      orderBySearch(e:any, type:string){
          switch(e.action){
              case 'filter':
                  this.documentationReportList[type+'Value'] = e.newValue;
                  this.filterDocument(type);
                  break;
              case 'order':
                  this.orderDate(type);
                  break;
              default:
                  break;
          }
      }

      filterDocument(type:any = ''){
          let filterList:any = [];
          let searchFilter:any = this.documentationReportList.titleValue;
          let searchWord = 'title';
          if(type !== ''){
              searchFilter = this.documentationReportList[type+'Value'] ;
              searchWord = type;
          }
          filterList = this.filterColumnService.filterByWord(searchFilter, this.documentationReportList.documentationList, searchWord);
          this.documentationReportList.filters.forEach((filter:any) => {
              let filteredList = this.documentationReportList[filter+'ListFilter'];
              filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
          })
          this.documentationReportList.filterDocumentsList = filterList;
      }

      selectTypeOperation(item:any, type:any){
          let listFilter:any = this.documentationReportList[type+'ListFilter'];
          this.documentationReportList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
          item.selected = !item.selected;
          this.documentationReportList[type+'Filter'].hasFilter = this.documentationReportList[type+'ListFilter'].length;
          this.filterDocument();
      }

      orderDate(type:any){
          if(this.documentationReportList.dateFilter === type){
              this.documentationReportList.order = !this.documentationReportList.order;
          }else{
              this.documentationReportList.dateFilter = type;
              this.documentationReportList.order = type !== 'title';
          }
      }

      showTooltip(id:any){
          return this.commonUtilsService.showTooltip(id);
      }

      sendAction(type:any, document:any){
        this.launchAction.emit({
          type: type,
          document: document
        });
      }
  }
