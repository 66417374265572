<div class="field-type-task">
  <div *ngIf="!isLanding"
      class="common__input-block"
      [ngClass]="{'common__show-alert common__show-alert': fieldTypeTask.field.error,
          'common__input-no-editable':fieldTypeTask.field.noEditable,
          'common__input-block--custom-field': !fieldTypeTask.field.sequentialView}"
      [attr.disabled]="fieldTypeTask.field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeTask.field.error,
                      'common__input-text--no-editable':fieldTypeTask.field.noEditable}">{{fieldTypeTask.field.label}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeTask.field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="fieldTypeTask.field.references.length && !fieldTypeTask.field.noEditable">
              <tooltip-law [references]="fieldTypeTask.field.references"
                  [field]="fieldTypeTask.field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__container">
          <p class="field-type-template__text-description"
            [innerHTML]="fieldTypeTask.field.description"></p>
          <div class="field-type-template__block-button">
              <button class="common__button-border-none"
                  (click)="createTask()"
                  [disabled]="fieldTypeTask.field.noEditable">
                  <i class="lf-icon-task common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'field-list.create-task'">_Crear tarea</span>
              </button>
          </div>
          <ul class="field-type-task__task-container"
              [ngShow]="fieldTypeTask.taskList?.length">
              <li *ngFor="let task of fieldTypeTask.taskList; let last = last"
                  class="field-type-task__task-item "
                  [ngClass]="{'field-type-task__task-item--border': !last}">
                  <div class="field-type-task__task-item-menu">
                      <i class="field-type-task__task-item-menu-icon field-type-task__task-item-menu-icon--left"
                          [ngClass]="(fieldTypeTask.canCreate && (task.taskStateId !== 5 && task.taskStateId !== 6)) || (task.taskStateId === 5 && !task.repeatPatternReadonly && task.repeatPattern !== null && task.repeatPattern.nextRepeat !== null)?'lf-icon-editable-form':'lf-icon-visible'"
                          matTooltip="{{editOrView(task)}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          (click)="editTask(task)"></i>
                      <i class="lf-icon-trash field-type-task__task-item-menu-icon"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [ngHide]="fieldTypeTask.field.noEditable || !fieldTypeTask.canCreate"
                          (click)="deleteTask(task)"></i>
                  </div>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'dashboard.Task'"></span>:
                      <span class="field-type-task__task-item-text-info">{{task.title}}</span>
                  </p>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'common.task-type'"></span>:
                      <span class="field-type-task__task-item-text-info">{{task.type}}</span>
                  </p>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'common.users-contacts'"
                          [ngHide]="task.taskTypeId === 4"></span>
                      <span [afTranslate]="'result-search.participants'"
                          [ngShow]="task.taskTypeId === 4"></span>:
                      <span class="field-type-task__task-item-text-info"
                          *ngFor="let participant of task.participants; let lastparticipant = last">
                          <span [ngClass]="{'common__line-through':participant.isDeleted}"
                              matTooltip="{{participant.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                              matTooltipPosition="below"
                              matTooltipClass="below"
                              [matTooltipDisabled]="!participant.isDeleted">
                              {{participant.fullName}}
                          </span>
                            <span [ngHide]="lastparticipant">,&nbsp</span>
                      </span>
                  </p>
                  <p class="field-type-task__task-item-text field-type-task__task-item-text--clear"
                      [ngShow]="task.taskTypeId !== 4 || task.expirationDate">
                      <span [afTranslate]="'task-edit.expiration-date'"></span>:
                      <span class="field-type-task__task-item-text-info">{{task.expirationDate | convertDate:'without-time'}}</span>
                  </p>
                  <p class="field-type-task__task-item-text"
                      [ngShow]="task.taskTypeId !== 4 || task.repeat">
                      <span class="field-type-task__task-item-text field-type-task__task-item-text--capitalize"
                          [afTranslate]="'task-list.repeat'"></span>:
                        <span class="field-type-task__task-item-text-info">{{task.repeat}}</span>
                  </p>
                  <p class="field-type-task__task-item-text"
                      [ngShow]="task.taskTypeId === 4">
                      <span [afTranslate]="'task-list.date-and-hour'"></span>:
                      <span class="field-type-task__task-item-text-info">{{task.expirationDate | convertDate:'without-time'}}</span>
                      <span class="field-type-task__task-item-text-info">{{task.beginHour}}</span> -
                      <span class="field-type-task__task-item-text-info">{{task.endHour}}</span>
                  </p>
                  <p class="field-type-task__task-item-text"
                      [ngShow]="task.taskTypeId === 4">
                      <span [afTranslate]="'task-list.modality-and-location'"></span>:
                      <span class="field-type-task__task-item-text-info"
                          [ngHide]="task.videoconference"
                          [afTranslate]="'task-form.face-to-face'"></span>
                      <span class="field-type-task__task-item-text-info"
                          [ngShow]="task.videoconference"
                          [afTranslate]="'task-form.videoconference'"></span> /
                      <span class="field-type-task__task-item-text-info">{{task.location}}</span>
                  </p>

              </li>
          </ul>
      </div>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeTask.field.error && fieldTypeTask.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </div>
  <div *ngIf="isLanding"
    class="common__input-block field-type-template__custom-input-block-field"
      [ngClass]="{'field-type-template__custom-input-block-field--required': fieldTypeTask.field.error,
          'field-type-template__custom-input-block-field--no-editable':fieldTypeTask.field.noEditable}">
      <div class="common__label-block">
          <i class="lf-icon-task field-type-template__custom-icon-type-header"
              [ngClass]="{'field-type-template__custom-icon-type-header--required': fieldTypeTask.field.error,
                  'field-type-template__custom-icon-type-header--no-editable':fieldTypeTask.field.noEditable}"></i>
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeTask.field.error,
                      'common__input-text--no-editable':fieldTypeTask.field.noEditable}">{{fieldTypeTask.field.label}}</span>
          <span class="common__text-required common__text-required"
              [ngShow]="fieldTypeTask.field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="fieldTypeTask.field.references.length && !fieldTypeTask.field.noEditable">
              <tooltip-law [references]="fieldTypeTask.field.references"
                  [field]="fieldTypeTask.field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__custom-container">
          <p class="field-type-template__text-description field-type-template__text-description--custom"
              [ngClass]="{'field-type-template__text-description--custom-required': fieldTypeTask.field.error,
                  'field-type-template__text-description--custom-no-editable':fieldTypeTask.field.noEditable}"
              [innerHTML]="fieldTypeTask.field.description"></p>
          <div class="field-type-template__block-button">
              <button type="submit"
                      class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last common__button-landing-custom--disabled"
                      disabled
                      (click)="createTask()"
                      [afTranslate]="'field-list.create-task'">_Crear tarea</button>
          </div>
          <ul class="field-type-template__block-list-document"
              [ngShow]="fieldTypeTask.taskList?.length">
              <li *ngFor="let task of fieldTypeTask.taskList; let last = last"
                  class="field-type-task__task-item"
                  [ngClass]="{'field-type-task__task-item--border': !last}">
                  <p class="field-type-task__task-item-text--custom">
                      <span [afTranslate]="'dashboard.Task'"></span>: <span class="field-type-task__task-item-text-info-custom">{{task.title}}</span>
                  </p>
                  <p class="field-type-task__task-item-text--custom">
                      <span [afTranslate]="'common.task-type'"></span>: <span class="field-type-task__task-item-text-info-custom">{{task.type}}</span>
                  </p>
                  <p class="field-type-task__task-item-text--custom">
                      <span [afTranslate]="'common.users-contacts'"
                          [ngHide]="task.taskTypeId === 4"></span>
                      <span [afTranslate]="'result-search.participants'"
                          [ngShow]="task.taskTypeId === 4"></span>:
                      <span class="field-type-task__task-item-text-info-custom"
                          *ngFor="let participant of task.participants">
                          <span>{{participant.fullName}}</span><span [ngHide]="last">,&nbsp</span>
                      </span>
                  </p>
                  <p class="field-type-task__task-item-text--custom field-type-task__task-item-text--clear"
                      [ngHide]="task.taskTypeId === 4 "
                      *ngIf="task.expirationDate">
                      <span [afTranslate]="'task-edit.expiration-date'"></span>: <span class="field-type-task__task-item-text-info-custom">{{task.expirationDate | convertDate:'without-time'}}</span>
                  </p>
                  <p class="field-type-task__task-item-text--custom"
                      [ngHide]="task.taskTypeId === 4"
                      *ngIf="task.repeat">
                      <span class="field-type-task__task-item-text--custom field-type-task__task-item-text--capitalize"
                          [afTranslate]="'task-list.repeat'"></span>: <span class="field-type-task__task-item-text-info-custom">{{task.repeat}}</span>
                  </p>
                  <p class="field-type-task__task-item-text--custom"
                      [ngShow]="task.taskTypeId === 4">
                      <span [afTranslate]="'task-list.date-and-hour'"></span>:
                      <span class="field-type-task__task-item-text-info-custom">{{task.expirationDate | convertDate:'without-time'}}</span>
                      <span class="field-type-task__task-item-text-info-custom">{{task.beginHour}}</span> -
                      <span class="field-type-task__task-item-text-info-custom">{{task.endHour}}</span>
                  </p>
                  <p class="field-type-task__task-item-text--custom"
                      [ngShow]="task.taskTypeId === 4">
                      <span [afTranslate]="'task-list.modality-and-location'"></span>:
                      <span class="field-type-task__task-item-text-info-custom"
                          [ngHide]="task.videoconference"
                          [afTranslate]="'task-form.face-to-face'"></span>
                      <span class="field-type-task__task-item-text-info-custom"
                          [ngShow]="task.videoconference"
                          [afTranslate]="'task-form.videoconference'"></span> /
                      <span class="field-type-task__task-item-text-info-custom">{{task.location}}</span>
                  </p>

              </li>
          </ul>
      </div>
  </div>
</div>
