import { Component, Input } from '@angular/core';

@Component({
  selector: 'history-viewer-fields',
  templateUrl: './history-viewer-fields.component.html'
})
export class HistoryViewerFieldsComponent{

  @Input() listField:any = [];
  @Input() historyDataViewer:any;

  changeShow(item:any){
    item.hiddenView = !item.hiddenView;
  }
}
