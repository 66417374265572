<div class="task-edit task-edit--custom">
    <states-process-custom
        *ngIf="(concept.processStates && taskEdit.task.taskTypeId === 7) || !(taskEdit.task.taskTypeId === 4 || taskEdit.task.taskTypeId === 5 || taskEdit.isNew)"
        [type]="taskEdit.typeStatesProcess"
        [typeView]="'normal'"
        [concept]="taskEdit.concept"
        [item]="taskEdit.task"
        (changeState)="changeState($event)"
        (changeResponsible)="changeResponsible($event)">
    </states-process-custom>
    <div class="task-edit__custom-container-task">
        <div class="task-edit__custom-task-block">
            <div class="task-edit__custom-properties">
                <attachments-template-custom [typeFrom]="'task'"
                    [itemSelected]="taskEdit.task"
                    [concept]="taskEdit.concept"
                    [fullScreenBox]="taskEdit.fullScreenBox"
                    [attachmentList]="taskEdit.attachmentListDocument"
                    (catchFullScreen)="hasFullScreen($event)"
                    (launchAction)="launchAction($event)">
                </attachments-template-custom>
                <box-comments-custom [taskId]="taskEdit.task.taskId"
                    [fullScreenBox]="taskEdit.fullScreenBox"
                    [taskState]="taskEdit.task.taskStateId"
                    (launchAction)="launchAction($event)"
                    (catchFullScreen)="hasFullScreen($event)">
                </box-comments-custom>
                <task-historic-custom [historicList]="taskEdit.historicList"
                                [fullScreenBox]="taskEdit.fullScreenBox"
                                (catchFullScreen)="hasFullScreen($event)"
                                [task]="taskEdit.task">
                </task-historic-custom>
            </div>
            <task-form-custom [concept]="taskEdit.concept"
                [task]="taskEdit.task"
                [isNew]="taskEdit.isNew"
                [shared]="taskEdit.shared"
                [field]="field"
                [hasOpenEvaluationTask]="taskEdit.hasOpenEvaluationTask"
                [disabledTaskEvaluation]="taskEdit.disabledTaskEvaluation"
                (cancelTask)="cancelCreateTask()"
                (createAlert)="createAlert($event.alert)"
                (launchAction)="launchAction($event.action)">
            </task-form-custom>
        </div>
    </div>
</div>
