import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';

@Component({
  selector: 'informative-loading-modal',
  templateUrl: './informative-loading-modal.component.html'
})
export class InformativeLoadingModalComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private changeDetector:ChangeDetectorRef,
    private exportService: ExportServiceService
  ) { }

  @Input() itemSelected: any = null;
  iconLoader: string = this.globalCfg.skin === 'dark-blue'? 'icon-lefebvre' : 'icon-spinner';
  timeoutExceed: boolean = false;
  url: string = '';
  calls: number = 0;
  manageEvaluationData: any = {};
  hideSecondaryText: boolean = false;
  typeText:string = '';
  evaluationId:string = this.route.snapshot.params['evaluationId'] || this.route.snapshot.firstChild?.params['evaluationId'];

  ngOnInit(): void {
    this.typeText = this.itemSelected.type;
    switch(this.itemSelected.type){
        case 'new-report':
          this.generateNewReport();
          break;
        case 'new-report-be':
          this.typeText = 'new-report';
          this.url = 'be/wh/dashboard/report/'+this.itemSelected.item.reportId+'/'+this.itemSelected.item.clientId+'/'+this.itemSelected.item.evaluationId+'/'+this.itemSelected.fromDate+'/'+this.itemSelected.toDate+'/'+this.itemSelected.interval
          this.add(this.url, {title: this.itemSelected.name});
          break;
        case 'update-evaluation':
          this.url = 'evaluations/updateevaluationversion/'+this.itemSelected.item.evaluationId
          this.patch(this.url)
          break;
        case 'create-client':
          this.url = 'clients';
          this.add(this.url, this.itemSelected.item)
          break;
        case 'duplicate-evaluation':
            let evaluationDuplicated:any = {
              name: this.itemSelected.item.copyInfo.name
          }
          let duplicate:number = Number(this.itemSelected.item.copyInfo.preserveContent);
          this.url = 'evaluations/clone/'+this.itemSelected.item.copyInfo.client+'/'+this.itemSelected.item.evaluation.evaluationId+'/'+duplicate
          this.add(this.url, evaluationDuplicated)
          break;
        case 'create-evaluation':
          this.hideSecondaryText = true;
          this.url = 'evaluations/'+this.itemSelected.item.productId+'/'+ this.itemSelected.item.clientId;
          this.add(this.url, this.itemSelected.item)
          break;
        case 'manage-evaluation':
          this.calls = 0;
          this.configureManageEvaluation();
          break;
        case 'anonymatum-document':
          this.setAnonimatum()
          break;
        case 'anonymatum-report':
          this.setReportAnonimatum();
          break;
        case 'generate-global-customer-report':
          this.generateGlobalCustomerIdentificationReport();
          break;
        case 'generate-consult-list-pdf':
          this.typeText = 'new-report';
          this.generatePdf();
          break;
        case 'getIcamReport':
          this.typeText = 'new-report';
          this.getIcamReport();
          break;
        default:
          this.url = 'reportobjects/refresh/'+this.itemSelected.item.reportObjectId;
          this.patch(this.url);
          break;
    }
  }

  generateNewReport(){
    let itemSelected: any = this.itemSelected;
    let report:any = {
        title: itemSelected.name
    }
    let type: string = itemSelected.item.predefined?'reportId':'customReportId';
    report[type]= itemSelected.item.reportId;
    itemSelected.url = location.href;
    let timeout = setTimeout(()=> { this.timeoutExceed = true; }, 15000);

    sessionStorage.setItem('isGeneratingReport', 'true');
    let url = itemSelected.itsCompany? 'companyReport/'+ itemSelected.item.reportId + '/'+ itemSelected.item.clientId+'/'+ itemSelected.item.evaluationId : itemSelected.item.reportId + '/' + this.evaluationId ;
    this.apiService.add('reportobjects/' +url,  report)
      .then((data:any) => {
        this.handleAccept(data);
    }).catch(() => {
        this.handleDismiss();
    }).finally(() => {
        clearTimeout(timeout);
        sessionStorage.setItem('isGeneratingReport', 'false');
    });
  }

  configureManageEvaluation(){
    if(this.itemSelected.configuration.selectedOption.lockedData && !this.itemSelected.item.isFinished){
      this.url = 'blanqueo/lockdata/'+this.itemSelected.item.evaluationId;
      this.add(this.url, this.itemSelected.item, 'lockData');
    }else{
        this.calls++;
    }
    if(this.itemSelected.configuration.selectedOption.generateReport){
      this.getReport();
    }else{
        this.calls++;
    }
    if(this.itemSelected.configuration.selectedOption.schedule && this.itemSelected.configuration.actionAlert === 'create'){
      this.url = 'blanqueo/datealert/'+this.itemSelected.item.evaluationId;
      this.add(this.url, this.itemSelected.configuration.alert, 'schedule');
    }else if(this.itemSelected.configuration.selectedOption.schedule && this.itemSelected.configuration.modifiedAlert){
      this.url = 'blanqueo/datealert/'+this.itemSelected.configuration.dateAlertId;
      this.patch(this.url, this.itemSelected.configuration.alert);
    }else if(this.itemSelected.configuration.actionAlert === 'delete'){
      this.url = 'datealerts';
      this.delete(this.url, this.itemSelected.configuration.dateAlertId);
    }else{
        this.calls++;
    }
    this.finishedCalls();
  }

  getReport(){
    this.apiService.get('blanqueo/auditreportid')
      .then((data:any)=>{
          if(data > 0){
            this.getReportList(data);
          }else{
            this.calls++;
            this.finishedCalls();
          }
      }, (errorData:any)=>{})
  }

  getReportList(reportId:any){
    this.apiService.get('reportobjects/byreportid/'+reportId, this.itemSelected.item.evaluationId)
      .then((data:any)=>{
          this.itemSelected.item.name = this.itemSelected.item.name.replaceAll('/', '-');
          let report = {
            title: data.reports.length?this.getNameReport(data.reports):this.itemSelected.item.name,
            reportId: reportId
          }
          this.url = 'reportobjects/' + reportId + '/' + this.itemSelected.item.evaluationId;
          this.add(this.url, report, 'generateReport');
      }, (errorData:any)=>{})
  }

  getNameReport(list: any){
    let name = this.itemSelected.item.name;
    let nameOrigin = this.itemSelected.item.name;
    let nameIdx = 0;
    let existingReport: any;
    do {
        if (nameIdx) {
            name = nameOrigin+' (' + nameIdx + ')';
        }
        existingReport = list.find((item:any)=> item.title.toLowerCase() === name.toLowerCase());
        nameIdx++;
    } while (existingReport);

    return name;
  }

  add(url:string, data:any, type: string = ''){
    this.apiService.add(url, data)
      .then((data:any)=>{
          if(this.itemSelected.type === 'manage-evaluation'){
            this.manageEvaluationData[type] = data;
            if(type === 'generateReport'){
              this.manageEvaluationData.reportId = data.reportId;
            }
            this.calls++;
            this.finishedCalls();
          }else{
            this.handleAccept(data);
          }
      },(errorData:any)=>{
          this.handleDismiss();
      })
  }

  finishedCalls(){
    if(this.calls === 3){
      this.handleAccept(this.manageEvaluationData);
    }
  }

  patch(url:string, data:any=null){
    this.apiService.patch(url, '', data)
    .then((data:any)=>{
        if(this.itemSelected.type === 'manage-evaluation'){
          this.manageEvaluationData.schedule = data;
          this.calls++;
          this.finishedCalls();
        }else{
          this.handleAccept(data);
        }
    }, (errorData:any)=>{
        this.handleDismiss();
    })
  }

  delete(url:string, id:any){
      this.apiService.delete(url, id)
        .then((data:any)=>{
          if(this.itemSelected.type === 'manage-evaluation'){
            this.manageEvaluationData.schedule = data;
            this.calls++;
            this.finishedCalls();
          }else{
            this.handleAccept(data);
          }
      }, (errorData:any)=>{
          this.handleDismiss();
      })
  }

  openNewTab(url:any) {
    open(url);
  }

  setAnonimatum(){
    let url = this.itemSelected.item.documentObjectId?'anonymize/doc/'+this.itemSelected.item.documentObjectId : 'anonymize/conceptobject/'+this.itemSelected.item.conceptObjectId;
    this.apiService.add(url)
      .then((data:any)=>{
          this.handleAccept(data);
      }, (errorData:any)=>{
          this.handleDismiss(errorData)
      })
  }

  setReportAnonimatum(){
    this.apiService.add('anonymize/report/'+this.itemSelected.item.reportDocumentId)
      .then((data:any)=>{
          this.handleAccept(data);
      }, (errorData:any)=>{
          this.handleDismiss(errorData)
      })
  }

  generateGlobalCustomerIdentificationReport(){
      let documentId:any;
      for(let i = 0; i < this.itemSelected.item.templateList.length; i++){
          let template = this.itemSelected.item.templateList[i];
          if(template.predefinedName === 'Informe global de la Identificación del cliente'){
                  documentId = template.id;
          }
      }
    this.apiService.add('documentobjects/fromComplianceDocumentTemplate/'+this.itemSelected.item.conceptObjectId +'/235711', this.itemSelected.document)
      .then((data:any)=>{
          this.handleAccept(data);
      }, (errorData:any)=>{
          this.handleDismiss(errorData)
      })
  }

  generatePdf(){
      let that = this;
      setTimeout(function () {
          let config = {
              htmlPrint: that.itemSelected.item.htmlPrint,
              nameDocument: that.itemSelected.item.infoModal.dossierId + '.pdf',
              uploadFile: false,
              landscape: false,
              noOpenLoader: true,
              successCallback: (uploadResponse:any, blob:any) => {
                that.uploadFile(blob, config.nameDocument);
              }
          }
          that.exportService.toPdf(config);
      })
  }

  uploadFile(blob: any, fileName: any) {
      let formData = new FormData();
      formData.append('file', blob, fileName);
      let url = 'conceptdata/upload/'+ this.itemSelected.fieldId + '/' + this.itemSelected.conceptObjectId;
      if (this.itemSelected.item.fieldContainer?.multiple && this.itemSelected.item.field?.evaluatedValue?.numSubContainer) {
          url = 'conceptdata/uploadtomultiplesubgroup/'+this.itemSelected.fieldId +'/' + this.itemSelected.conceptObjectId + '/' + this.itemSelected.item.field.evaluatedValue.numSubContainer;
      }
      this.apiService.attachFile(url, formData)
        .then((data:any)=>{
            this.addColorToDocument(data);
        }, (errorData:any)=>{
            this.handleDismiss(errorData)
        })
  }

  addColorToDocument(document: any){
    this.apiService.patch('documentobjects/'+ document.documentObjectId + '/color', this.itemSelected.item.infoModal.resultColor)
      .then((data:any)=>{
          document.color = this.itemSelected.item.infoModal.resultColor;
          this.handleAccept(document);
      }, (errorData:any)=>{
          this.handleDismiss(errorData)
      })
  }

  getIcamReport(){
    let timeout = setTimeout(()=> { this.timeoutExceed = true; }, 15000);
    this.apiService.get('icam/reports', this.itemSelected.item.reportObjectId)
    .then((data:any)=>{
        if(this.timeoutExceed){
          this.handleAccept(data);
        }else{
          setTimeout(()=> { this.handleAccept(data); }, 3000);
        }
    }).catch(() => {
        this.handleDismiss();
    }).finally(() => {
        clearTimeout(timeout);
    });
  }

  handleAccept(data: any=null) {
    this.activeModal.close({
      result:'ok',
      item: data
    });
  }

  handleDismiss(error:any = null) {
    this.activeModal.close({
      result:'cancel',
      error:error
    });
  }
}
