<div class="organization-chart">
    <div class="organization-chart__block"></div>
    <div class="organization-chart__buttons-block">
        <div class="organization-chart__box-buttons">
            <button class="organization-chart__button organization-chart__button--increment"
                (click)="zoomWithButton('increment')"
                matTooltip="{{'organization-chart.zoom-in' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-zoom-in"></i>
            </button>
            <button class="organization-chart__button"
                (click)="zoomWithButton('decrement')"
                matTooltip="{{'organization-chart.zoom-out' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-zoom-out"></i>
            </button>
        </div>
        <div class="organization-chart__box-buttons">
            <button class="organization-chart__button organization-chart__button--center-graph"
                (click)="centerGraph()"
                matTooltip="{{'organization-chart.center-graph' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-map-marker"></i>
            </button>
            <button class="organization-chart__button"
                (click)="showMySelf()"
                matTooltip="{{'organization-chart.establish-graph' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-visible"></i>
            </button>
        </div>
        <div class="organization-chart__box-buttons organization-chart__box-buttons--expand">
            <button class="organization-chart__button organization-chart__button--expand"
                (click)="expandAll()"
                matTooltip="{{'organization-chart.expand-all' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-reorganize"></i>
            </button>
        </div>
        <div class="organization-chart__box-buttons organization-chart__box-buttons--print">
            <button class="organization-chart__button organization-chart__button--print"
                (click)="print()"
                matTooltip="{{'common.print' | afTranslate}}"
                matTooltipPosition="above"
                matTooltipClass="above">
                <i class="lf-icon-print"></i>
            </button>
        </div>
    </div>
    <div class="common__buttons-block organization-chart__go-back-button">
        <button type="submit"
            class="common__button common__button--active"
            (click)="goBack.emit()"
            [afTranslate]="'common.return'">Volver</button>
    </div>
</div>
