<div class="field-type-contacts">
    <div class="field-type-contacts__label-block">
        <label class="common__input-block common__input-block--custom-list"
            [ngClass]="{'common__input-block--open-custom': fieldTypeContacts.userList,
              'common__input-block--show-references': fieldTypeContacts.references && field.evaluatedValue.conceptDataId !== null,
              'common__show-alert': field.error,
              'common__input-no-editable': field.noEditable}"
            [attr.disabled]="field.noEditable?true:null">
            <div class="common__label-block common__label-block--one-icon"
                [ngClass]="{'common__label-block--one-icon-bubble': fieldTypeContacts.contactListSelected.length}">
                <span class="common__input-text common__input-text"
                    [ngClass]="{'common__input-text--required': field.error,
                            'common__input-text--no-editable': field.noEditable}">
                            <span>{{fieldTypeContacts.titleField}}</span>
                            <span *ngIf="field.label2"
                                class="common__input-text--secondary">{{field.label2}}</span>
                        </span>
                <span class="common__text-required"
                    [ngShow]="field.required">*</span>
                <div class="common__info-icon-block common__info-icon-block"
                    [ngHide]="field.noEditable || !field.description">
                    <tooltip-info description="field.description"
                        field="field"></tooltip-info>
                </div>
                <div class="common__tooltip-law-block"
                    [ngShow]="field.references && field.references.length && !field.noEditable">
                    <tooltip-law [references]="field.references"
                        field="field">
                    </tooltip-law>
                </div>
                <div class="common__tooltip-law-block"
                    [ngShow]="field.relatedInfo && field.relatedInfo.length">
                    <i class="lf-icon-search-text field-item__icon-related-info"
                        (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                        [matTooltip]="'common.related-info' | afTranslate"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </i>
                </div>
                <div class="common__info-icon-block common__info-icon-block--in-report"
                *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
                <tooltip-in-report [inReport]="fieldTypeContacts.inReport"></tooltip-in-report>
              </div>
            </div>
            <div class="field-type-contacts__block-contacts">
                <div class="float-end">
                    <i class="lf-icon-connected-data field-type-text__icon-references"
                        [ngShow]="field.evaluatedValue && field.evaluatedValue.hasReferences"
                        (click)="showReference($event)"
                        [matTooltip]="'common.see-dependencies' | afTranslate"
                        matTooltipPosition="left"
                        matTooltipClass="left">
                    </i>
                    <i class="lf-icon-user field-type-contacts__icon-label"
                        [ngClass]="{'field-type-contacts__icon-label--disabled': field.noEditable}"
                        [ngShow]="!fieldTypeContacts.userList"
                        (click)="openContactList()"></i>
                    <div class="common__bubble-count common__bubble-count--top-7"
                        *ngIf="fieldTypeContacts.contactListSelected.length"
                        [ngClass]="{'common__bubble-count--disabled': field.noEditable}">{{fieldTypeContacts.contactListSelected.length}}</div>
                </div>
                <div class="field-type-contacts__block-elements">
                    <ul class="field-type-contacts__multiselect-block">
                        <li class="common__multiselect-item"
                            [ngClass]="{'common__multiselect-item--contacts': field.typeConfiguration === 'field-user'}"
                            *ngFor="let contact of fieldTypeContacts.contactListSelected; let index = index">
                            <div *ngIf="field.typeConfiguration !== 'field-user'">
                                <p class="field-type-contacts__item-selected field-type-contacts__item-selected--multiple"
                                    [ngClass]="{'common__line-through': contact.isDeleted}"
                                    [matTooltip]="contact.fullName + (contact.isDeleted ? ' ('+ ('common.inactive-user' | afTranslate)+')' : '')"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    [matTooltipDisabled]="showTooltip('field-type-contacts-multiple-'+index, contact)"
                                    id="{{'field-type-contacts-multiple-'+ index }}">
                                    <span id="{{'span-field-type-contacts-multiple-'+index}}">{{contact.fullName}}</span>
                                </p>
                                <i class="lf-icon-close-round common__delete-content-icon common__delete-content-icon--contacts"
                                    (click)="deleteContact($event, index)"
                                    [ngShow]="!field.noEditable"></i>
                            </div>
                            <div *ngIf="field.typeConfiguration === 'field-user'">
                                <div class="field-type-contacts__block-contact-name">
                                  <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--no-padding-left"
                                      [ngClass]="{'common__line-through':contact.isDeleted}"
                                      [matTooltip]="contact.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="!contact.isDeleted">
                                      {{contact.fullName}}
                                  </p>
                                </div>
                                <div class="field-type-contacts__block-contact-name">
                                    <i class="lf-icon-mail field-type-contacts__icon-selected-custom"
                                        [ngShow]="contact.email"></i>
                                    <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--icon-custom">{{contact.email}}</p>
                                </div>
                                <div class="field-type-contacts__block-contact-phone">
                                    <i class="lf-icon-call field-type-contacts__icon-selected-custom"
                                        [ngShow]="contact.phoneNumber1"></i>
                                    <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--icon-custom">{{contact.phoneNumber1}}</p>
                                </div>
                                <i class="common__delete-content-icon lf-icon-close-round common__delete-content-icon--contacts common__delete-content-icon--contact-visibility"
                                    (click)="deleteContact($event, index)"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common__select-list common__select-list--list-block"
                [ngShow]="fieldTypeContacts.userList">
                <div class="common__block-input-custom common__block-input-custom--black common__block-input-custom--contact-multiple">
                    <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                        <input class="common__find-in-dropdown common__find-in-dropdown--custom-list"
                            [(ngModel)]="fieldTypeContacts.searchContact"
                            placeholder="{{'common.search' | translate}}"
                            (click)="catchEvent($event)">
                        <i class="common__find-dropdown-icon-find field-type-list-extended__icon-find-custom"
                            [ngClass]="{'lf-icon-search':fieldTypeContacts.findDropdown === '',
                                'lf-icon-close-search': fieldTypeContacts.findDropdown !==''}"
                            (click)="resetSearch($event)"></i>
                    </div>
                </div>
                <div class="field-type-contacts__block-buttons-show-custom field-type-contacts__block-buttons-show-custom--bubble"
                    [ngClass]="{'field-type-contacts__block-buttons-show-custom--bubble-selected': fieldTypeContacts.state === 'selection'}"
                    (click)="changeStateCustom($event)">
                    <div class="common__bubble-count-custom">{{fieldTypeContacts.contactListSelected.length}}</div>
                </div>
                <div class="field-type-contacts__block-buttons-show-custom">
                    <button class="lf-icon-favorite field-type-contacts__icon-show-custom"
                        [ngClass]="{'field-type-contacts__icon-show-custom--selected': fieldTypeContacts.viewItems.id === 2}"
                        [ngShow]="fieldTypeContacts.usersList.length"
                        [matTooltip]="'users-admin.main.user' | afTranslate"
                        matTooltipPosition="above"
                        matTooltipClass="above"
                        (click)="showOnly(2, $event)">
                    </button>
                    <button class="lf-icon-user field-type-contacts__icon-show-custom"
                        [ngClass]="{'field-type-contacts__icon-show-custom--selected': fieldTypeContacts.viewItems.id === 3}"
                        [ngShow]="fieldTypeContacts.contactsList.length"
                        [matTooltip]="'users-admin.main.contact' | afTranslate"
                        matTooltipPosition="above"
                        matTooltipClass="above"
                        (click)="showOnly(3, $event)"></button>
                </div>
                <div class="field-type-contacts__block-buttons-show-custom field-type-contacts__block-buttons-show-custom--exit-contact-extend">
                    <button class="lf-icon-check-round field-type-contacts__icon-show-custom field-type-contacts__icon-show-custom--confirm"
                        [matTooltip]="'common.ok' | afTranslate"
                        matTooltipPosition="above"
                        matTooltipClass="above"
                        (click)="selectedContacts()"></button>
                    <button class="lf-icon-close-round field-type-contacts__icon-show-custom field-type-contacts__icon-show-custom--close"
                        [matTooltip]="'common.close' | afTranslate"
                        matTooltipPosition="above"
                        matTooltipClass="above"
                        (click)="resetContacts()">
                    </button>
                </div>
                <div class="field-type-contacts__body-contacts-custom">
                    <div class="field-type-contacts__contacts"
                        [ngShow]="fieldTypeContacts.state !=='addNew'">
                        <p class="field-type-contacts__title-contact-list-custom">
                            <span [ngShow]="fieldTypeContacts.searchContact !==''"
                                [afTranslate]="'contacts.results-of-search'">Resultados de la busqueda en</span>
                            {{fieldTypeContacts.viewItems.label}}
                        </p>
                    </div>
                    <div class="field-type-contacts__inner-box"
                        [ngClass]="{'field-type-contacts__inner-box--custom-sms': fieldTypeContacts.showInfoSMS,
                                'field-type-contacts__inner-box--roles':field.typeConfiguration === 'roles'}"
                        [ngSwitch]="fieldTypeContacts.state">
                        <div *ngSwitchCase="'list'">
                            <div [ngShow]="fieldTypeContacts.usersAndContactsFilList.length">
                                <ul class="field-type-contacts__contact-book">
                                    <li class="field-type-contacts__toggle-all"
                                        *ngIf="fieldTypeContacts.listContact.length > 10"
                                        (click)="toggleAll($event)">
                                        <i class="field-type-contacts__checkbox-icon-all"
                                          [ngClass]="fieldTypeContacts.markAll ? 'lf-icon-check-full' : 'lf-icon-box-inactive'"></i>
                                        <span class="field-type-contacts__text-all" [afTranslate]="'common.toggle-all'"></span>
                                    </li>
                                    <li *ngFor="let user of fieldTypeContacts.usersAndContactsFilList | byWord:fieldTypeContacts.searchContact:fieldTypeContacts.byWord | unique: 'uniqueValueId' | afOrderBy:'fullName'"
                                        class="field-type-contacts__contact-list-to-share-custom"
                                        [ngShow]="(user.contactId && fieldTypeContacts.viewItems.id === 3) || (user.userId && fieldTypeContacts.viewItems.id===2) || fieldTypeContacts.viewItems.id === 0"
                                        (click)="addOrDeleteMultipleItem( user,user.userId ? 'user' : 'contact', $event)">
                                        <i class="field-type-contacts__checkbox-size-icon"
                                          [ngClass]="user.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                        <i [ngClass]="{'lf-icon-user-special':user.userId, 'lf-icon-user':user.contactId}"
                                          class="lf-icon-user-special field-type-contacts__icon-contact"></i>
                                        <span class="field-type-contacts__text-contact"
                                              [ngClass]="{'common__line-through':user.isDeleted}"
                                              [matTooltip]="user.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'"
                                              matTooltipPosition="below"
                                              matTooltipClass="below"
                                              [matTooltipDisabled]="!user.isDeleted">
                                              {{user.fullName}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div [ngHide]="fieldTypeContacts.usersAndContactsFilList.length">
                                <p class="field-type-contacts__error-text-custom"
                                    [afTranslate]="'common.no-results-found'">No se encuentran resultados</p>
                            </div>
                        </div>
                        <div *ngSwitchCase="'selection'">
                            <ul class="field-type-contacts__contact-book">
                                <li *ngFor="let contact of fieldTypeContacts.contactListSelected | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: 'fullName':false"
                                    class="field-type-contacts__contact-list-to-share-custom"
                                    (click)="whoIs(contact,$event)">
                                    <i class="field-type-contacts__checkbox-size-icon"
                                        [ngClass]="contact.active?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                    <i class="field-type-contacts__icon-contact"
                                        [ngClass]="{'lf-icon-group':contact.groupId, 'lf-icon-user-special':contact.userId, 'lf-icon-user':contact.contactId}"></i>
                                    <span class="field-type-contacts__text-contact"
                                        [ngClass]="{'common__line-through':contact.isDeleted}"
                                        [matTooltip]="contact.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'"
                                        matTooltipPosition="below"
                                        matTooltipClass="below"
                                        [matTooltipDisabled]="!contact.isDeleted">
                                        {{contact.fullName}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="field-type-contacts__block-info-message"
                        *ngIf="fieldTypeContacts.showInfoSMS">
                        <i class="lf-icon-information field-type-contacts__icon-alert"></i>
                        <span class="field-type-contacts__text-alert"
                            [afTranslate]="'field-type-contacts.info-contacts-sms'">_Por requisitos del trámite, solo se muestran los usuarios y contactos que tienen asociado un teléfono móvil. </span>
                        <i class="lf-icon-close field-type-contacts__icon-close-header"
                            (click)="fieldTypeContacts.showInfoSMS = !fieldTypeContacts.showInfoSMS"></i>
                    </div>
                </div>
              </div>
        </label>
    </div>
</div>
