import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

import { IcamPriceFormulaFactory } from '@shared/factories/icam-price-formula/icam-price-formula.factory';

@Injectable({
  providedIn: 'root'
})
export class FieldTypeFormuParentService {
  subscribers: any = {};
  constructor(private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private icamPriceFormula: IcamPriceFormulaFactory) { }

   configureInit(child:any){
      this.subscribers['modifyFormuBroadcast'+child.field.fieldId] = this.broadcastService.subscribe('modifyFormu', () => {
          this.resolveFormu(true, child);
      });
  }

  configureChanges(child:any){
    let that: any = child.fieldTypeFormu;
    if(child.field) {
      that.field = child.field;
      that.field.noEditable = true;
      that.titleField = that.field.label;
      if(that.field.extendedLabel && that.field.extendedLabel !== ''){
          that.titleField = that.field.extendedLabel;
      }
      that.decimals = that.field.decimals;
      this.resolveFormu(false, child);
      that.fieldEncodings = false;
      if(that.field.fieldEncodings && that.field.fieldEncodings.length>0){
          that.fieldEncodings = true;
      }
      that.decimals = that.field.decimals;
      if(child.field.inReport !== null && child.field.inReport !== undefined){
        child.fieldTypeFormu.inReport = child.field.inReport.indexOf('|') === -1? [child.field.inReport] : child.field.inReport.split('|')
      }
    }
  }

  resolveFormu(noFirst:boolean, child:any){
    let that: any = child.fieldTypeFormu;
    let field: any = that.field;
    if(field.formula !== null){
        that.formula = cloneDeep(field.formula);
        if (that.formula.startsWith("|*") && that.formula.endsWith("*|")) {
            this.personalizedFormu(child);
        } else {
            if (field.fieldTypeId === 'agreg' || field.fieldTypeId === 'agrep') {
              this.resolveAgre(child)
            }
            if (field.evaluatedValue) {
                if (!(field.fieldTypeId === 'agreg' || field.fieldTypeId === 'agrep')) {
                   that.empty = true;
                    if (Boolean(field.evaluatedValue.numSubContainer)) {
                      child.concept.evaluatedFields.forEach((evaluatedField:any) => {
                          if (!evaluatedField.numSubContainer) {
                            this.changeIdsToValues(evaluatedField, child);
                          }
                        });

                    }
                    for(var i = 0; i < child.evaluatedConcepts.length; i++){
                      this.changeIdsToValues(child.evaluatedConcepts[i], child);
                    }
                    if (!that.empty) {
                       that.formula =that.formula.split('#').join('');
                        try {
                            if (isNaN(eval(that.formula))) {
                                that.fieldEvaluatedValue = null;
                                field.evaluatedValue.floatvalue = null;
                                if (field.fieldEncodings.length > 0) {
                                    this.resetCodeFormu(field);
                                }
                                if(child.concept.productId === 'blanqueo' && field.addToScoring){
                                    delete child.concept.addToScoring;
                                }
                                child.itemSelected.emit({ newValue: field.evaluatedValue.floatvalue});
                            } else {
                                if(field.fieldEncodings.length > 0){
                                    this.resolveCodeFormu(child);
                                    this.configureEvalFormu(child);
                                    // field.evaluatedValue.floatvalue = eval(that.formula);
                                    if(field.evaluatedValue.floatvalue && field.evaluatedValue.floatvalue != "" ){
                                      this.configureFloatValue(child);
                                    }else if(field.evaluatedValue.floatvalue === 0){
                                        that.fieldEvaluatedValue = cloneDeep(field.evaluatedValue.floatvalue);
                                    }
                                    if (noFirst || (field.evaluatedValue.floatvalue !== null && field.evaluatedValue.floatvalue !== undefined && field.evaluatedValue.floatvalue !== "")) {
                                        child.itemSelected.emit({ newValue: field.evaluatedValue.floatvalue});
                                    }
                                }else{
                                    this.configureEvalFormu(child);
                                    // field.evaluatedValue.floatvalue = eval(that.formula);
                                    if(field.evaluatedValue.floatvalue && field.evaluatedValue.floatvalue != "" ){
                                        this.configureFloatValue(child);
                                    }else if(field.evaluatedValue.floatvalue === 0){
                                        that.fieldEvaluatedValue = cloneDeep(field.evaluatedValue.floatvalue);
                                    }
                                    if (noFirst || (field.evaluatedValue.floatvalue !== null && field.evaluatedValue.floatvalue !== undefined && field.evaluatedValue.floatvalue !== "")) {
                                        child.itemSelected.emit({ newValue: field.evaluatedValue.floatvalue });
                                    }
                                }
                            }
                        } catch (e) {
                          this.resetValue(field, child);
                        }
                    } else {
                        this.resetValue(field, child);
                    }
                } else {
                    that.formula = field.evaluatedValue.floatvalue;
                    this.resolveCodeFormu(child);
                    if (noFirst) {
                        child.itemSelected.emit({ newValue: field.evaluatedValue.floatvalue });
                    }
                }
            }
        }
    }
  }

  resetValue(field: any, child: any){
    field.evaluatedValue.floatvalue = null;
    field.evaluatedValue.nvarcharValue = null;
    child.fieldTypeFormu.fieldEvaluatedValue = null;
    child.itemSelected.emit({ newValue: field.evaluatedValue.floatvalue});
  }

  configureEvalFormu(child: any){
    let that: any = child.fieldTypeFormu;
    let value: number = 0;
    if(that.decimals){
      value = parseFloat(eval(that.formula).toFixed(that.decimals));
    }else if(that.decimals === 0){
      value = Math.round(eval(that.formula));
    }else{
      value = eval(that.formula);
    }
    if(typeof that.field.minValue === 'number' && value < that.field.minValue){
      value = that.field.minValue;
    }else if(typeof that.field.maxValue === 'number' && value > that.field.maxValue){
      value = that.field.maxValue;
    }
    that.field.evaluatedValue.floatvalue = value;
  }

  personalizedFormu(child: any){
    let formula: any = child.fieldTypeFormu.formula
    if(formula.indexOf("listp-sum") !== -1){
      let splitListValue : any = formula.replace('|*', '').replace('*|', '').split('-');
      this.calculateSumAll(splitListValue[2], child)
    }else if(formula.indexOf("ad-zone-code") !== -1){
      let adZoneCodeSplit : any = formula.replace('|*', '').replace('*|', '').split('-');
      this.calculateAdZone(adZoneCodeSplit[adZoneCodeSplit.length-2], adZoneCodeSplit[adZoneCodeSplit.length-1], child)
    }else if(formula.indexOf("icam-%-digi") !== -1){
        this.calculateIcamBookPercentage(child);
    }else{
        this.extractIcamAdHoc(child);
    }
  }

  calculateSumAll(type:any, child:any){
    let evaluatedFieldsList : any = child.concept.evaluatedFields;
    let sum: number = 0;
    evaluatedFieldsList.forEach((evaluatedField:any)=>{
      if(evaluatedField.nvarcharValue !== null){
        sum += this.checkTypeInListValue(type, evaluatedField)
      }
    })
    child.field.evaluatedValue.floatvalue = sum;
    child.fieldTypeFormu.fieldEvaluatedValue = cloneDeep(child.field.evaluatedValue.floatvalue);
    child.itemSelected.emit({ newValue: child.fieldTypeFormu.fieldEvaluatedValue});
  }

  checkTypeInListValue(type:any, evaluatedField:any){
    let listValue: any = evaluatedField.listValues.filter((listValue: any)=>{ return listValue.text === type})[0];
    if(listValue){
      let listValuesSelected: any = evaluatedField.nvarcharValue.indexOf('|') !== -1? evaluatedField.nvarcharValue.split("|") : [evaluatedField.nvarcharValue];
      if(listValuesSelected.filter((itemSelected:any) => { return itemSelected == listValue.listValueId}).length){
        return listValue.weight;
      }
    }
    return 0;
  }

  calculateAdZone(probabilityId:any, impactId:any, child:any){
    let evaluatedFieldsList : any = child.concept.evaluatedFields;
    let probabilityValue : any;
    let impactValue : any;
    for(let i = 0; i < evaluatedFieldsList.length;i++){
        let evaluatedFields : any = evaluatedFieldsList[i]
        if(evaluatedFields.fieldId0 === parseInt(probabilityId)){
            probabilityValue = child.field.nullsAsZero && evaluatedFields.floatvalue === null? 0: evaluatedFields.floatvalue
        }else if(evaluatedFields.fieldId0 === parseInt(impactId)){
            impactValue = child.field.nullsAsZero && evaluatedFields.floatvalue === null? 0: evaluatedFields.floatvalue
        }
    }
    if(impactValue !== null && probabilityValue !== null){
        if(probabilityValue <= 3.5 && impactValue <= 3.5){
            child.field.evaluatedValue.floatvalue = 1
        }else if(probabilityValue > 3.5 && impactValue <= 3.5){
            child.field.evaluatedValue.floatvalue = 2
        }else if(probabilityValue <= 3.5 && impactValue > 3.5){
            child.field.evaluatedValue.floatvalue = 3
        }else{
            child.field.evaluatedValue.floatvalue = 4
        }
        child.field.calculateFormula = child.field.evaluatedValue.floatvalue
        this.resolveCodeFormu(child);
        if(child.field.evaluatedValue.floatvalue && child.field.evaluatedValue.floatvalue != ""){
            child.itemSelected.emit({ newValue: child.field.evaluatedValue.floatvalue});
        }
    }
  }

  calculateIcamBookPercentage(child: any){
    if(this.icamPriceFormula.mapped.size){
        let fragmentsPages : any = this.icamPriceFormula.mapped.get(child.field.fieldContainerId+'-'+child.field.evaluatedValue.numSubContainer);
        if (fragmentsPages) {
            let bookTotalPages:any = this.getBookTotalPages(child);
            let percentage;
            if (bookTotalPages) {
                percentage = (fragmentsPages / bookTotalPages) * 100;
            }
            child.field.evaluatedValue.floatvalue = percentage;
            child.fieldTypeFormu.fieldEvaluatedValue = percentage;
            child.itemSelected.emit({ newValue: percentage });
        }
    }
  }

  extractIcamAdHoc(child:any){
    let acum:number = 0;
    let fieldContainer = child.concept.fieldContainers[3].subContainers[0]
    let taxes: number = 0;
    let total: number = 0;
    if(this.icamPriceFormula.mapped.size && fieldContainer.fields[3].evaluatedValue){
        let fieldEvaluated = fieldContainer.fields[1].evaluatedValue;
        taxes =  fieldContainer.fields[3].evaluatedValue.floatvalue;
        if(fieldEvaluated.floatvalue === null || fieldEvaluated.floatvalue === ''){
            if(this.icamPriceFormula.mapped.get(fieldContainer.fields[1].fieldContainerId)){
                acum += this.icamPriceFormula.mapped.get(fieldContainer.fields[1].fieldContainerId)
            }else{
                acum += 0
            }
        }else{
            acum = parseFloat(fieldEvaluated.floatvalue)
        }
        let preTotal: any = acum * parseFloat('1.'+ taxes)
        total= parseFloat(preTotal.toFixed(2));
    }

    child.field.evaluatedValue.floatvalue = total;
    child.fieldTypeFormu.fieldEvaluatedValue = this.commonUtilsService.setLocaleNumberFormat(total);
    child.itemSelected.emit({ newValue: child.field.evaluatedValue.floatvalue });
  }

  resolveAgre(child: any){
    let val:any = child.fieldTypeFormu.formula.split('#');
    for (let i = 0; i < val.length; i++) {
      if (val[i].indexOf('[') === 0) {
          child.fieldTypeFormu.formula = val[i];
          val[i] = this.getAgregFunctions(val[i], child);
      }
    }
    if (!isNaN(eval(val.join('')))) {
      child.field.evaluatedValue.floatvalue = eval(val.join(''));
      this.configureFloatValue(child);
    }
  }

  getAgregFunctions(val:any, child:any){
    let valReplace : any = parseInt(val.replace('[', '').replace(']', ''))
    return this.calcOperation(valReplace, child);
  }

  changeIdsToValues(evaluatedValue: any, child:any){
    let field: any = child.fieldTypeFormu.field
    let fieldTypeId: any = child.fieldTypeFormu.field.fieldTypeId;
    let evaluatedFieldTypeId: string = evaluatedValue.fieldTypeId;
    let value: any = field.nullsAsZero? 0 : NaN;
    let formuWeight:any = (fieldTypeId === 'formp' || fieldTypeId === 'score' || fieldTypeId === 'agrep') && evaluatedValue.fieldId !== field.fieldId && (evaluatedFieldTypeId === 'formp' || evaluatedFieldTypeId === 'agrep' || evaluatedFieldTypeId === 'score');
    let formuNoWeight:any = (fieldTypeId === 'formu' || fieldTypeId === 'agreg') && evaluatedValue.fieldId !== field.fieldId && (evaluatedFieldTypeId === 'formu' || evaluatedFieldTypeId === 'agreg');
    switch(evaluatedFieldTypeId){
      case 'text':
        child.fieldTypeFormu.empty = !(evaluatedValue.nvarcharValue === null || evaluatedValue.nvarcharValue === '');
        value = !child.fieldTypeFormu.empty? "' " + evaluatedValue.nvarcharValue + "'" : '';
        break;
      case 'money':
      case 'num':
        if(evaluatedValue.floatvalue === null || evaluatedValue.floatvalue === ''){
            value = field.nullsAsZero? 0 : NaN;
        }else{
            value = parseFloat(evaluatedValue.floatvalue);
            child.fieldTypeFormu.empty = false;
        }
        break;
      case 'boolp':
        if(evaluatedValue.bitValue !== null){
          let num: number = evaluatedValue.bitValue? 0 : 1;
          value = evaluatedValue.listValues[num].weight;
          child.fieldTypeFormu.empty = false;
        }else{
          value = field.nullsAsZero? 0 : NaN;
        }
        break;
      case 'listp':
        if(evaluatedValue.multiple && evaluatedValue.nvarcharValue !== null && evaluatedValue.nvarcharValue !== ''){
            let valuesSelectedList:any = evaluatedValue.nvarcharValue.toString().indexOf('|') !==-1? evaluatedValue.nvarcharValue.split('|') : [evaluatedValue.nvarcharValue];
            value = 0;
            valuesSelectedList.forEach((valueSelected:any) => {
              evaluatedValue.listValues.forEach((listValue:any) => {
                if(parseInt(valueSelected) === listValue.listValueId){
                  value += listValue.weight;
                  child.fieldTypeFormu.empty = false;
                }
              });
            });
        }else if(evaluatedValue.floatvalue !== null){
            for(let i = 0; i < evaluatedValue.listValues.length; i++){
                let listValue: any =  evaluatedValue.listValues[i]
                if(evaluatedValue.floatvalue === listValue.listValueId){
                    value = listValue.weight;
                    child.fieldTypeFormu.empty = false;
                    break;
                }
            }
        }else{
            value = field.nullsAsZero? 0 : NaN;
        }
        break;
    }

    if((evaluatedValue.floatvalue || evaluatedValue.floatvalue === 0) && (formuWeight || formuNoWeight)){
        value = typeof evaluatedValue.floatvalue === 'string'?this.commonUtilsService.replaceDecimals(evaluatedValue.floatvalue):evaluatedValue.floatvalue;
    }

    if(child.fieldTypeFormu.formula){
        let indexPosition: any = child.fieldTypeFormu.formula.indexOf('['+evaluatedValue.fieldId+']');
        if(indexPosition !== -1 &&!this.getVisibilityEvaluatedField(child, evaluatedValue)){
          if(indexPosition === 0){
            child.fieldTypeFormu.formula = child.fieldTypeFormu.formula.replace('['+evaluatedValue.fieldId+']', '');
          }else{
            let previousOperator: any = child.fieldTypeFormu.formula.substr(indexPosition-2,1);
            if(previousOperator !== '+' && previousOperator !== '-' && previousOperator !== '*' && previousOperator !== '/'){
              previousOperator = ''
            }
            child.fieldTypeFormu.formula = child.fieldTypeFormu.formula.replace(previousOperator+'#['+evaluatedValue.fieldId+']', '');
          }
        }
        child.fieldTypeFormu.formula = child.fieldTypeFormu.formula.split('['+evaluatedValue.fieldId+']').join(value);
        child.fieldTypeFormu.empty  = false;
    }
  }

  getVisibilityEvaluatedField(child: any, evaluatedValue: any){
    let fieldVisible: boolean = true;
    child.concept.fieldContainers.forEach((container: any) => {
        for(let i:number = 0; i < container.fields.length; i++){
            let field = container.fields[i];
            if(evaluatedValue.fieldId === field.fieldId){
              if(!field.show || !field.showWithProperty){
                fieldVisible = false;
              }
              break;
            }
        }
        if(container.subContainers?.length){
          container.subContainers.forEach((subContainer: any) => {
            for(let i:number = 0; i < subContainer.fields.length; i++){
              let subField = subContainer.fields[i];
                if(evaluatedValue.fieldId === subField.fieldId){
                  if(!subField.show || !subField.showWithProperty){
                    fieldVisible = false;
                  }
                  break;
                }
              }
          });
        }
    });
    return fieldVisible;
  }

  resetCodeFormu(field:any){
    field.evaluatedValue.nvarcharValue = '';
    field.color =  '';
    if(field.fieldTypeId === 'formp' && field.isHtml){
        field.backgroundColorHtml =  '';
    }
  }

  configureFloatValue(child:any){
    child.field.evaluatedValue.floatvalue = this.commonUtilsService.setLocaleNumberFormat(child.field.evaluatedValue.floatvalue);
    child.fieldTypeFormu.fieldEvaluatedValue = cloneDeep(child.field.evaluatedValue.floatvalue);
  }

  calcOperation(fieldId:any, child:any){
    let minSubcontainer:any = 0;
    child.evaluatedConcepts.forEach((evaluated:any) => {
      if(evaluated.numSubContainer && evaluated.fieldId === fieldId && evaluated.numSubContainer > minSubcontainer){
        minSubcontainer = evaluated.numSubContainer;
      }
    });

    let formulas = [];
    for(var i = 0 ; i<minSubcontainer; i++){
        formulas[i] = child.fieldTypeFormu.formula;
    }
    for(let i = 0 ; i<minSubcontainer; i++){
        child.fieldTypeFormu.formula = formulas[i];
        for(let j = 0; j < child.evaluatedConcepts.length; j++){
          let evaluated: any = child.evaluatedConcepts[j];
          if(evaluated.numSubContainer == (i+1)){
            this.changeIdsToValues(evaluated, child);
          }
        }
        formulas[i] = child.fieldTypeFormu.formula;
    }
    child.fieldTypeFormu.formula = 0;
    switch (child.fieldTypeFormu.field.aggregateFormulaTypeId) {
      case 1:
        return this.calcSum(minSubcontainer, formulas, child);
      case 2:
        return this.calcAmount(child, fieldId);
      case 3:
        return this.calcMin(minSubcontainer, formulas);
      case 4:
        return this.calcMax(minSubcontainer, formulas);
      default:
        return this.calcMedia(minSubcontainer, formulas, child);
    }
  }

  calcMin(minSubcontainer: any, formulas: any){
    let min;
    for(let i = 0; i< minSubcontainer; i++){
      let evalJoin: any = eval(formulas[i].split('#').join(''));
        if(!isNaN(evalJoin) && (min === undefined || evalJoin < min)){
          min = evalJoin;
        }
    }
    if(min === undefined){
        min = 0;
    }
    return min;
  }

  calcMax(minSubcontainer: any, formulas: any){
    let max:any;
    for(let i = 0; i< minSubcontainer; i++){
      let evalJoin: any = eval(formulas[i].split('#').join(''));
      if(!isNaN(evalJoin) && (max === undefined || evalJoin > max)){
        max = evalJoin;
      }
    }
    if(max === undefined){
      max = 0;
    }
    return max;
  }

  calcSum(minSubcontainer: any, formulas: any, child:any){
    for(let i = 0; i< minSubcontainer; i++){
        let evalJoin: any = eval(formulas[i].split('#').join(''));
        if(!isNaN(evalJoin)){
            child.fieldTypeFormu.formula += evalJoin;
        }
    }
    return child.fieldTypeFormu.formula;
  }

  calcMedia(minSubcontainer: any, formulas: any, child:any){
    for(let i = 0; i< minSubcontainer; i++){
      let evalJoin: any = eval(formulas[i].split('#').join(''));
        if(!isNaN(evalJoin)){
            child.fieldTypeFormu.formula += evalJoin;
        }
    }
    return child.fieldTypeFormu.formula === 0 ? child.fieldTypeFormu.formula : child.fieldTypeFormu.formula/minSubcontainer;
  }

  calcAmount(child:any, fieldId:any){
    let minSubcontainer : any = 0;
    child.evaluatedConcepts.forEach((evaluated:any) => {
      if(evaluated.numSubContainer && evaluated.fieldId === fieldId && evaluated.numSubContainer > minSubcontainer){
        minSubcontainer = evaluated.numSubContainer;
      }
    });
    return minSubcontainer;
  }

  resolveCodeFormu(child: any){
    let fieldInfo: any = child.field;
    let valueFormu: any = fieldInfo.calculateFormula? fieldInfo.calculateFormula : child.fieldTypeFormu.formula;
    let conditionOk: boolean = false;
    for(let i = 0 ; i < fieldInfo.fieldEncodings.length; i++){
      let fieldEncoding : any = fieldInfo.fieldEncodings[i];
      let operator1:any = fieldEncoding.operator1 === '='?'===' : fieldEncoding.operator1;
      let conditionResolve:any = valueFormu + " " + operator1 + " "+ fieldEncoding.value1;
      if(fieldEncoding.value2){
        let operator2:any = fieldEncoding.operator2 === '='?'===': fieldEncoding.operator2;
        conditionResolve += " && " + valueFormu + " "+ operator2 + " "+ fieldEncoding.value2;
      }
      conditionResolve = conditionResolve.replaceAll('#','').replaceAll(',', '.')
      if(eval(conditionResolve)){
          fieldInfo.evaluatedValue.nvarcharValue = fieldEncoding.text;
          fieldInfo.evaluatedValue.orderEncodinSelected = fieldEncoding.numOrder;
          fieldInfo.color =  fieldEncoding.color;
          if(fieldInfo.fieldTypeId === 'formp' && fieldInfo.isHtml){
            fieldInfo.backgroundColorHtml =  'rgba('+this.commonUtilsService.convertHexToRGB(fieldEncoding.color)+',0.2)';
          }
          let i : any = fieldInfo.fieldEncodings.length;
          conditionOk = true;
      }
    };

    if(!conditionOk){
      this.resetCodeFormu(fieldInfo);
    }
  }

  getBookTotalPages(child: any){
    child.concept.fieldContainers.forEach((container:any) => {
      container.fields.forEach((field:any) => {
        if(field.iD_Version0 === 64){
          return field.evaluatedValue.floatvalue;
        }
      });
      container.subContainers.forEach((subContainer:any) => {
        subContainer.fields.forEach((subField:any) => {
          if(subField.iD_Version0 === 64){
            return subField.evaluatedValue.floatvalue;
          }
        });
      });
    });
  }

  openModalRelatedInfo(child:any, fieldRelatedInfo:any, e:any){
    e.stopPropagation();
    e.preventDefault();
    child.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  ngOnDestroy(child: any){
    this.subscribers['modifyFormuBroadcast'+child.field.fieldId].unsubscribe();
  }
}
