import { Injectable } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ExpandedMenuService {
  imageExtensions: any = ['JPG', 'PNG', 'GIF', 'BMP', 'DIB', 'JPEG', 'TGA', 'TIF', 'TIFF', 'PCX', 'PIC', 'EMF', 'ICO', 'TXT', 'MDB', 'WRI', 'LOG', 'XPS', 'HTM', 'HTML', 'CSS', 'URL', 'XML', 'MSG', 'TIFF', 'TIF'];//EML
  videoExtensions: any = ['AVI', 'FLV', 'MP4', 'MKV', 'MOV', 'MPEG', 'MPG', 'DIVX', 'WMV', 'RAR', 'ZIP', '7Z'];
  musicExtensions: any = ['EML', 'WAV', 'MP3'];
  excelExtensions: any = ['XLSX', 'XLM', 'XLSM', 'XLT', 'XLTX', 'XLTM', 'XLSB', 'XLAM', 'XLV', 'CSV', 'ODS'];
  wordExtensions: any = ['DOCX', 'DOCM', 'DOT', 'DOTX', 'DOTM', 'ODT', 'RTF'];
  powerpointExtensions: any = ['PPTX', 'PPTM', 'POT', 'POTX', 'POTM', 'PPA', 'PPAM', 'PPS', 'PPSX', 'PPSM', 'SLDX', 'SLDM', 'THMX', 'ODP'];
  officeOldExtensions: any = ['DOC', 'XLS', 'PPT'];
  signatureExtensions: any = ['DOC', 'DOCX', 'PDF'];
  certifyExtensions: any = ['DOC', 'DOCX', 'ODT', 'TXT', 'XLS', 'XLSX', 'ODS', 'PPT', 'PPTX', 'ODP', 'PDF'];
  publishExtensions: any = ['DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'PDF'];
  officeOnline: any = ['DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX'];

  constructor(private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory) {
    }

  documentationPrincipal(list: any, document: any, optionDocument: any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption: any = [];
    let image = this.compareExtension(document, this.imageExtensions);
    let video = this.compareExtension(document, this.videoExtensions);
    let music = this.compareExtension(document, this.musicExtensions);
    let excel = this.compareExtension(document, this.excelExtensions);
    let word = this.compareExtension(document, this.wordExtensions);
    let powerpoint = this.compareExtension(document, this.powerpointExtensions);
    let officeOld = this.compareExtension(document, this.officeOldExtensions);
    let signature = this.compareExtension(document, this.signatureExtensions);
    let certify = this.compareExtension(document, this.certifyExtensions);
    let publish = this.compareExtension(document, this.publishExtensions);
    let signedOrCertifiedDocument = document.documentStatusId === 3 || document.documentStatusId === 4 || document.documentStatusId === 6;
    list.forEach((option:any)=>{
      switch(option.action){
        case 'edit-view':
            // Sale siempre que tenga permisos, o sin ellos con las opciones de visualizar
            if((userPermissions !== 'none' || document.documentStatusId !== 1 || image || document.extension === 'PDF' || (userPermissions === 'none' && this.officeOnline.includes(document.extension)))){
                menuOption.push(option);
            }
            break;
        case 'anonymatum-document':
            let documentToAnonymatum = !(document.sendedAsCertifiedSMS || document.sendedAsCertifiedEmail || document.documentStatusId === 3 || document.documentStatusId === 4 || document.documentStatusId === 6 || document.anonymized)
            let documentToAnonymatumExtension = document.extension === 'PDF' || document.extension ==='DOCX' || document.extension ==='DOC' || document.extension ==='XLSX' || document.extension ==='XLS';
            let documentToAnonymatumExtensionImage = document.extension ==='TXT' || document.extension ==='JPG' || document.extension ==='JPEG' || document.extension ==='PNG' || document.extension ==='RTF' || document.extension ==='TIFF';
            if((documentToAnonymatumExtension || documentToAnonymatumExtensionImage) && documentToAnonymatum && userPermissions !== 'none' && this.globalCfg.skin === 'dark-blue'){
                menuOption.push(option);
            }
            break;
        case 'sibila':
            // Sale si es sibila y la extensión admitida para ello
            if(optionDocument.sibila && (signature || document.extension === 'RTF')){
                menuOption.push(option);
            }
            break;
        case 'publish':
            if(userPermissions !== 'none' && document.documentStatusId === 1 && publish && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }
            break;
        case 'post-requester':
            // Sale siempre que no sea de acceso anonimo y que el concepto sea publico
            if(userPermissions !== 'none' && !document.anonymous && optionDocument.public){
                let optionSelected = cloneDeep(option);
                if(document.publishedForApplicant){
                    optionSelected.icon = 'lf-icon-invisible-document';
                    optionSelected.name = 'expanded-menu.un-post-requester'
                }
                menuOption.push(optionSelected);
            }
            break;
        case 'signature-certification':
            if (this.globalCfg.skin !== 'icam-red' && optionDocument.canSignDocuments && (signature || certify) && userPermissions !== 'none' && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }
            break;
        // Se comenta de momento hasta que se realice nuevo jira
        // case 'new-version':
        //     if((document.documentStatusId === 2 || document.documentStatusId === 4) && this.userPermissions !== 'none'){
        //         menuOption.push(option);
        //     }
        //     break;
        case 'historic':
            if(!image && !video && !music){
                menuOption.push(option);
            }
            break;
        case 'share-options':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'configuration':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'download':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'move':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'delete':
            // Sale siempre que tenga permisos, y que no seao subido desde un concepto público bloqueado
            if(userPermissions !== 'none' && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }
            break;
        // OPCIONES DE VER/EDITAR
        case 'view':
            // Se muestra siempre para imagenes o pdf y para el resto de documentos que no estén en borrador y no sean subidos desde la landing con el concepto bloqueado
            if((document.documentStatusId !== 1 && !(optionDocument.lockEdit && document.anonymous)) || image || document.extension === 'PDF'){
                menuOption.push(option);
            }
            break;
        case 'edit':
            // Solo en documentos de tipo Office (sin los antiguos) con usuario con permisos y que el documento no sea del solicitante
            if(userPermissions !== 'none' && (excel || word || powerpoint) && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }else if((userPermissions === 'none' && this.officeOnline.includes(document.extension))){
                let optionSelected = cloneDeep(option);
                optionSelected.icon = 'lf-icon-visible';
                optionSelected.name = 'expanded-menu.view';
                menuOption.push(optionSelected);
            }
            break;
        case 'edit-local':
            // Solo en documentos de tipo Office con usuario con permisos
            if (this.globalCfg.skin === 'dark-blue' && userPermissions !== 'none' && (excel || word || powerpoint || officeOld) && !(optionDocument.lockEdit && document.anonymous)) {
                if(document.extension === 'XLS' || excel){
                    option.icon = 'lf-icon-excel-software';
                }else if(document.extension === 'PPT' || powerpoint){
                    option.icon = 'lf-icon-powerpoint-software';
                }
                menuOption.push(option);
            }
            break;
        case 'convert':
            // Solo en documentos de tipo Office antiguos con usuario con permisos
            if(userPermissions !== 'none' && officeOld && !(optionDocument.lockEdit && document.anonymous)){
                option.name = 'expanded-menu.convert-to-' + document.extension.toLowerCase()+'x';
                menuOption.push(option);
            }
            break;
        case 'rename':
            // Sale siempre que tenga permisos, y que no seao subido desde un concepto público bloqueado
            if(userPermissions !== 'none' && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }
            break;
        case 'duplicate':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        // OPCIONES FIRMA Y CERTIFICACIÓN
        case 'signature':
            if(optionDocument.canSignDocuments && !signedOrCertifiedDocument && signature && userPermissions !== 'none' && !(optionDocument.lockEdit && document.anonymous)){
                menuOption.push(option);
            }
            break;
        case 'certify-document':
            if(optionDocument.canSignDocuments && certify && !signedOrCertifiedDocument && userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'send-mail-certificate':
        case 'send-sms-certificate':
            if(optionDocument.canSignDocuments && signature && userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        case 'request-tracking':
            if(((optionDocument.canSignDocuments && (signature || certify) && (signedOrCertifiedDocument)) || (document.sendedAsCertifiedEmail || document.sendedAsCertifiedSMS)) && userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        // OPCIONES DE COMPARTIR
        case 'share':
            // Sale siempre
            menuOption.push(option);
            break;
        // OPCIONES DE CONFIGURACIÓN
        case 'add-alert':
            // Sale SIEMPRE que tiene permisos y no es imagen ni video ni musica
            if(userPermissions !== 'none' && !image && !video && !music){
                menuOption.push(option);
            }
            break;
        case 'associate-process':
            // Sale siempre que tengamos procesos y permisos (si tiene capability de procesos)
            if(userPermissions !== 'none' && optionDocument.processStates && this.globalCfg.caps.processes){
                menuOption.push(option);
            }
            break;
        case 'confidential':
            // Sale siempre que tenga permisos
            if(userPermissions !== 'none'){
                menuOption.push(option);
            }
            break;
        // case 'post-requester':
        //     // Sale siempre que no sea de acceso anonimo
        //     if(document.publishedForApplicant){
        //         option.icon = 'lf-icon-invisible-document';
        //         option.name = 'expanded-menu.un-post-requester'
        //     }else{
        //         option.icon= "lf-icon-transparency-report";
        //         option.name= "expanded-menu.post-requester";
        //     }
        //     if(!document.anonymous){
        //         menuOption.push(option);
        //     }
        //     break;
        default:
            // menuOption.push(option);
            break;
      }
    })
    return menuOption;
  }
  compareExtension(document: any, listExtension: any){
    return !!(listExtension.filter((extension: any)=> document.extension === extension).length);
  }
  documentationDelete(list: any, document: any, optionDocument: any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption: any = [];
    list.forEach((option: any)=>{
      switch(option.action){
        case 'restore':
        case 'definitive-delete':
            if(userPermissions !== 'none'){
                let optionSelected = cloneDeep(option);
                if(optionDocument.multiple){
                    optionSelected.action = option.action+'-some';
                }
                menuOption.push(optionSelected);
            }
            break;
        case 'open-folder':
            if(document.extension === 'folder'){
                menuOption.push(option);
            }
            break;
        default:
            break;
      }
    })
    return menuOption;
  }

  folderDocumentation(list: any, document: any, optionDocument: any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption: any = [];
    list.forEach((option: any)=>{
        switch(option.action){
            case 'move':
            case 'delete':
            case 'rename':
            case 'confidential':
            case 'new-subfolder':
                if(userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            default:
                menuOption.push(option);
                break;
        }
    })
    return menuOption;
  }

  someDocumentation(list: any, optionDocument: any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption: any = [];
    list.forEach((option: any)=>{
        switch(option.action){
            case 'move-some':
            case 'delete-some':
                if(userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            case 'send-mail':
                break;
            default:
                // 'share-some'
                // 'download-some'
                menuOption.push(option);
                break;
        }
    })
    return menuOption;
  }

  menuFolderEvaluationHeader(list: any, folder: any, evaluationInfo: any){
    let menuOption: any = [];
    if(folder.folderId !== 'reports'){
      list.forEach((option:any) => {
        let multipleInstancesInAuto = false;
        if(folder.conceptObjectId && folder.concepts && folder.concepts.length){
            multipleInstancesInAuto = folder.concepts[0].multipleInstancesInAuto;
        }
        switch(option.action){
            case 'rename':
              if(folder.isFront){
                  menuOption.push(option);
              }
              break;
            case 'delete':
                if(!folder.hidden && folder.isFront){
                    menuOption.push(option);
                }
                break;
            case 'duplicate':
                if(!folder.hidden && (evaluationInfo.productTypeId !== 2 || (evaluationInfo.productTypeId === 2 && !folder.children.length))){
                    menuOption.push(option);
                }
                break;
            case 'new':
                if(!folder.hidden && !multipleInstancesInAuto){
                    menuOption.push(option);
                }
                break;
            case 'newConcept':
                if (!folder.hidden && !(evaluationInfo.productTypeId === 2 && (folder.concepts.length || folder.children.length))) {
                    menuOption.push(option);
                }
                break;
            case 'hidden':
                if (!folder.hidden){
                    menuOption.push(option);
                }
                break;
            case 'restore':
                if(folder.hidden){
                    menuOption.push(option);
                }
                break;
            case 'confidential':
                if (folder.supportsConfidential) {
                    menuOption.push(option);
                }
                break;
            default:
                break;
        }
      });
    }
    return menuOption;
  }

  menuFolder(list: any, folder: any, evaluationInfo: any, selectedFolderId: any){
    let menuOption: any = [];
    list.forEach((option:any)=>{
      if(evaluationInfo.productTypeId !== 4){
        if(folder.hidden){
            switch(option.action){
                case 'open':
                    if(folder.folderId !== selectedFolderId){
                        menuOption.push(option);
                    }
                    break;
                case 'restore':
                        menuOption.push(option);
                    break;
                case 'confidential':
                    if (folder.supportsConfidential) {
                        menuOption.push(option);
                    }
                    break;
                default:
                    break;
            }
        }else{
          switch(option.action){
              case 'open':
                  if(folder.folderId !== selectedFolderId){
                      menuOption.push(option);
                  }
                  break;
              case 'confidential':
                if (folder.supportsConfidential) {
                    menuOption.push(option);
                }
                break;
              case 'hidden':
                  menuOption.push(option);
                  break;
              case 'rename':
              case 'delete':
                  if(folder.isFront){
                      menuOption.push(option);
                  }
                  break;
              case 'duplicate':
                  if(!(evaluationInfo.productTypeId === 2 && folder.children.length)){
                      menuOption.push(option);
                  }
                  break;
              case 'new':
                  if(!(evaluationInfo.productTypeId === 2 && folder.concepts.length)){
                      menuOption.push(option);
                  }
                  break;
              case 'newConcept':
                  let optionSelected = cloneDeep(option);
                  if (evaluationInfo.productTypeId === 2 && folder.concepts.length && !folder.children.length) {
                      optionSelected = {
                          icon: "lf-icon-editable-form",
                          name: "rename-modal.edit-concept",
                          action: "edit-concept-folder"
                      }
                  }
                  if (!(evaluationInfo.productTypeId === 2 && folder.concepts.length && folder.children.length)) {
                      menuOption.push(optionSelected);
                  }
                  break;
              default:
                  break;
          }
        }
      }else if(evaluationInfo.productTypeId === 4 && option.action === 'open'){
          //Para evaluaciones de tipo consulta
          menuOption.push(option);
      }
    })

    return menuOption;
  }
  menuConcept(list: any, concept: any, evaluationInfo: any){
    let menuOption: any = [];
    list.forEach((option:any)=>{
      if(!concept.hidden){
        let optionSelected = cloneDeep(option);
        switch(option.action){
            case 'edit':
                menuOption.push(option);
                break;
            case 'duplicate':
            case 'hiddenConcept':
                if (this.globalCfg.skin != 'icam-red' && evaluationInfo && evaluationInfo.customizable && evaluationInfo.productTypeId !== 2 && !concept.channelView && this.globalCfg.skin !== 'uk-steel-blue') {
                    menuOption.push(option);
                }
                break;
            case 'manageProcess':
                let showProcess = this.globalCfg.caps.processes;
                if(!concept.poll && concept.processId === null){
                    optionSelected = {
                        icon: "lf-icon-process-add",
                        name: "common.add-process",
                        action: "addProcess"
                    }
                    showProcess = this.globalCfg.skin === 'icam-red'?false:this.globalCfg.caps.processes;
                }
                if (!concept.poll && showProcess) {
                    menuOption.push(optionSelected);
                }
                break;
            case 'publicLinkPoll':
                if(concept.poll && concept.anonymousAccess){
                    menuOption.push(option);
                }
                break;
            case 'sharePoll':
                if(concept.poll && !concept.anonymousAccess){
                    menuOption.push(option);
                }
                break;
            case 'expirationPeriod':
                if(concept.poll){
                    menuOption.push(option);
                }
                break;
            case 'sendReminder':
                if(concept.poll && !concept.anonymousAccess && concept.pollBeginDate){
                    menuOption.push(option);
                }
                break;
            case 'templates':
                if(!concept.poll){
                    menuOption.push(option);
                }
                break;
            case 'shareConcept':
                if(!concept.poll && !concept.allowPublicShare && !concept.singleInstance){
                    menuOption.push(option);
                }
                break;
            case 'patternInstance':
                if(this.globalCfg.skin === 'uk-steel-blue'){
                    optionSelected.name = "expanded-menu.add-periodic-process";
                }
                if((evaluationInfo.productTypeId === 1  || (evaluationInfo.productTypeId === 2 && concept.multipleInstancesInAuto))&& !concept.poll && !concept.singleInstance){
                    menuOption.push(optionSelected);
                }
                break;
            case 'publishConcept':
                if (!concept.poll && concept.allowPublicShare && !concept.singleInstance && this.globalCfg.skin !== 'uk-steel-blue' && !(this.globalCfg.skin === 'icam-red' && concept.namedKey === 'Usuario')){
                    menuOption.push(option);
                }
                break;
            case 'exportExcel':
                if(!concept.singleInstance){
                    menuOption.push(option);
                }
                break;
            case 'resetPoll':
                if(concept.poll){
                    menuOption.push(option);
                }
                break;
            case 'migration':
                if((concept.conceptObjectId !==0 || !concept.singleInstance) && !concept.poll){
                    menuOption.push(option);
                }
                break;
            case 'delete':
                if (this.globalCfg.skin != 'icam-red' && concept.isFront){
                    menuOption.push(option);
                }
                break;
            default:
                // menuOption.push(option);
                break;
        }
      }else if(concept.hidden && option.action === 'restoreConcept'){
          menuOption.push(option);
      }
    })

    return menuOption;
  }
  menuOptionsEvaluation(list: any, evaluation: any, preview: any){
    let menuOption: any = [];
    list.forEach((option:any)=>{
        switch(option.action){
            case 'new':
                if(evaluation.productTypeId !== 4 && evaluation.customizable && !preview){
                    menuOption.push(option);
                }
                break;
            case 'edit':
                if(evaluation.productTypeId !== 4 && evaluation.hasProperties){
                    menuOption.push(option);
                }
                break;
            default:
                break;
        }
      })
    return menuOption;
  }
  menuSideEvaluation(list: any, evaluation: any, evaluationInfo: any, userPermissions: any){
    let menuOption: any = [];
    list.forEach((option:any) => {
        if(!evaluation.hidden){
            switch(option.action){
                case 'rename':
                  if(evaluation.isFront){
                      menuOption.push(option);
                  }
                  break;
                case 'duplicate':
                    if(!(evaluationInfo.productTypeId === 2 && evaluation.children.length)){
                        menuOption.push(option);
                    }
                    break;
                case 'new':
                // case 'newConcept':
                    if(!(evaluationInfo.productTypeId === 2 && evaluation.concepts.length && !evaluation.children.length)){
                        menuOption.push(option);
                    }
                    break;
                case 'newConcept':
                    let optionSelected = cloneDeep(option);
                    if(evaluationInfo.productTypeId === 2 && evaluation.concepts.length && !evaluation.children.length){
                        optionSelected = {
                            icon: "lf-icon-editable-form",
                            name: "rename-modal.edit-concept",
                            action: "edit-concept-folder"
                        }
                    }
                    if(!(evaluationInfo.productTypeId === 2 && evaluation.children.length)){
                        menuOption.push(optionSelected);
                    }
                    break;
                case 'delete':
                    if(evaluation.isFront){
                        menuOption.push(option);
                    }
                    break;
                case 'hidden':
                    menuOption.push(option);
                    break;
                case 'confidential':
                    if (evaluation.supportsConfidential) {
                        menuOption.push(option);
                    }
                    break;
                default:
                    break;
            }
        }else{
            switch(option.action){
                case 'restore':
                    if(userPermissions === 'all')
                        menuOption.push(option);
                    break;
                case 'confidential':
                    if (evaluation.supportsConfidential) {
                        menuOption.push(option);
                    }
                    break;
                default:
                    break;
                }
        }
    });
    return menuOption;
  }
  menuReportSignDocuments(list: any, document: any, optionDocument: any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption: any = [];
    let signature = this.compareExtension(document, this.signatureExtensions);
    let certify = this.compareExtension(document, this.certifyExtensions);
    let signedOrCertifiedDocument = document.documentStatusId === 3 || document.documentStatusId === 4 || document.documentStatusId === 6;
    list.forEach((option: any)=>{
        switch(option.action){
            // OPCIONES FIRMA Y CERTIFICACIÓN
            case 'signature':
                if(optionDocument.canSignDocuments && !signedOrCertifiedDocument && signature && userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            case 'certify-document':
                if(optionDocument.canSignDocuments && certify && !signedOrCertifiedDocument && userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            case 'send-mail-certificate':
            case 'send-sms-certificate':
                if(optionDocument.canSignDocuments && signature && userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            case 'request-tracking':
                if(((optionDocument.canSignDocuments && (signature || certify) && (signedOrCertifiedDocument)) || (document.sendedAsCertifiedEmail || document.sendedAsCertifiedSMS)) && userPermissions !== 'none'){
                    menuOption.push(option);
                }
                break;
            default:
                break;
        }
    })
    return menuOption;
  }

  menuDocumentationReports(list:any){
    let userPermissions: any = this.permissions.getPermissions();
    let menuOption = [];
    for(let i = 0; i < list.length; i++){
        let option = list[i];
        switch(option.action){
          case 'anonymatum-document':
              if(userPermissions !== 'none' && this.globalCfg.skin === 'dark-blue'){
                  menuOption.push(option);
              }
              break;
          case 'delete':
              if(userPermissions !== 'none'){
                  menuOption.push(option);
              }
              break;
          default:
              menuOption.push(option);
              break;
        }
    }
    return menuOption;
  }

    isOfficceExtension(extension: string){
        return !this.imageExtensions.includes(extension.toUpperCase()) && !this.videoExtensions.includes(extension.toUpperCase()) && !this.musicExtensions.includes(extension.toUpperCase()) as boolean;
    }  

}
