import { Component, OnChanges, Input, Output, EventEmitter} from '@angular/core';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { TaskHistoricParentService } from '../utils/task-historic-parent.service';



@Component({
  selector: 'task-historic-custom',
  templateUrl: './task-historic-custom.component.html'
})
export class TaskHistoricCustomComponent implements OnChanges {

  isLanding: boolean = true;
  custom:any = this.customLanding.getCustom();

  taskHistoric: any = {
    showCommentList: true,
    readMore: true,
    readMoreText:false,
    noComments: true,
    fullScreen: false,
    compareVersions: {
      jwtToken: this.context.jwtToken,
      logID: null,
      taskId: null
    }
  }

  constructor(private customLanding: CustomLandingFactory,
    private context: ContextFactory,
    private taskHistoricParent: TaskHistoricParentService) {
  }

  @Input() historicList:any = [];
  @Input() task:any = null
  @Input() fullScreenBox:any = null

  @Output() catchFullScreen = new EventEmitter();

  ngOnChanges(): void {
    if(this.taskHistoric.fullScreen && this.fullScreenBox !== '' && this.fullScreenBox !== 'historic-templates'){
        this.taskHistoricParent.maximizeScreen(true, this);
    }
  }

  comparatorInfo(historic:any){
    this.taskHistoricParent.comparatorInfo(historic, this)
  }

  maximizeScreen(keepClassBox:any = false){
    this.taskHistoricParent.maximizeScreen(keepClassBox, this)
  }
}
