<div class="concept-list">
  <div class="concept-list__table"
      [id]="'concept_'+conceptInfo.conceptId">
      <div class="common__table-header"
          [ngShow]="conceptList.conceptList.length">
          <div class="concept-list__table-col concept-list__table-col--title-digitization concept-list__table-col--top-1">
              <filter-by-search
                  [item]="conceptList.tituloDigizationFilter"
                  [filterOrder]="conceptList.dateFilter"
                  [order]="conceptList.order"
                  [filterText]="conceptList.tituloWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'titulo')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential"></div>
          <div class="concept-list__table-col concept-list__table-col--reference-digitization concept-list__table-col--top-1">
              <filter-by-search
                  [item]="conceptList.referenciaDigizationFilter"
                  [filterText]="conceptList.referenciaWordFilter"
                  (launchActionSearch)="orderBySearch($event, 'referencia')">
              </filter-by-search>
          </div>
          <div class="concept-list__table-col concept-list__table-col--pages-digitization">
              <p class="common__table-header-text"
                  [afTranslate]="'concept-list.pages-range'">_Rango de páginas</p>
          </div>
          <div class="concept-list__table-col concept-list__table-col--date-digitization concept-list__table-col--top-2"
            ngbDropdown
            id="creationDate"
            [autoClose]="'outside'">
            <p class="common__table-header-text common__cursor-pointer"
              ngbDropdownToggle
              [afTranslate]="'concept-list.registration-date'">
              _Fecha de registro
            </p>
            <i class="common__filter-icon lf-icon-filter-1"
              [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': conceptList.creationDateFilter}"
              ngbDropdownToggle></i>
            <i class="lf-icon-arrow-down common__order-by"
              [ngClass]="{'lf-icon-arrow-up': !conceptList.order && conceptList.dateFilter === 'creationDate',
                  'common__order-by--active': conceptList.dateFilter === 'creationDate'}"
              (click)="orderDate('creationDate')"></i>
            <div class="concept-list__calendar-dropdown common__block-option-list"
              ngbDropdownMenu role="menu">
              <from-to-calendar [fromSave]="conceptList.creationDateFromDate"
                  [toSave]="conceptList.creationDateToDate"
                  (changeCalendar)="changeCalendar($event, 'creationDate')"
                  [type]="'concept-list'">
              </from-to-calendar>
            </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions-query">
              <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">Acciones</p>
          </div>
      </div>
      <div class="common__table-row"
          *ngFor="let evaluateConcept of conceptList.conceptListFilter | afOrderBy:[conceptList.dateFilter, 'titulo']:conceptList.order | afLimitTo:conceptList.configPagination.pageSize:conceptList.configPagination.pageSize*(conceptList.currentPage - 1)">
          <div class="concept-list__table-col concept-list__table-col--title-digitization common__cursor-pointer"
              (click)="launchActionOption('open', evaluateConcept)">
              <parragraph-tooltip [text]="evaluateConcept.titulo"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--confidential">
              <div [ngShow]="evaluateConcept.confidential"
                    ngbDropdown
                    [autoClose]="'outside'"
                    [id]="'concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId]">
                  <i class="lf-icon-lock concept-list__icon-confidential"
                      ngbDropdownToggle
                      matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </i>
                  <list-in-dropdown ngbDropdownMenu role="menu"
                                    class="dropdown-menu concept-list__box-select-list"
                                    [list]="evaluateConcept.confidentialList"
                                    [type]="'confidential'"
                                    (closeDropdownEmit)="closeDropdown('concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId])">
                  </list-in-dropdown>
              </div>
          </div>
          <div class="concept-list__table-col concept-list__table-col--reference-digitization">
              <parragraph-tooltip [text]="evaluateConcept.referencia"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--pages-digitization">
              <parragraph-tooltip [text]="evaluateConcept.pages"></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--date-digitization">
              <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate:'' "></parragraph-tooltip>
          </div>
          <div class="concept-list__table-col concept-list__table-col--actions-query">
              <ul class="common__options-row">
                  <li class="common__menu-options-row"
                      [ngShow]="toShow !== 'archived'">
                      <i [ngClass]="userPermissions === 'none' || conceptInfo.poll?'lf-icon-visible':'lf-icon-editable-form'"
                          (click)="launchActionOption('open', evaluateConcept)"
                          matTooltip="{{conceptList.descriptionEdit}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && conceptInfo.type !== 0 && toShow !== 'archived'">
                      <i class="lf-icon-open-window"
                          (click)="openInNewTab(evaluateConcept)"
                          matTooltip="{{conceptList.description}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && !conceptInfo.reportId && toShow !== 'archived'">
                      <i class="lf-icon-lock"
                          (click)="openModalConfidential(evaluateConcept)"
                          matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions === 'all' && !conceptInfo.poll && toShow !== 'archived' && conceptInfo.conceptId && !conceptInfo.hasAutoArchive">
                      <i class="lf-icon-archive"
                          (click)="archiveConcept(evaluateConcept, '1')"
                          matTooltip="{{'concept-list.archive-concept' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="toShow === 'archived'">
                      <i class="lf-icon-rotate"
                          (click)="archiveConcept(evaluateConcept, '0')"
                          matTooltip="{{'concept-list.restore-concept' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
                  <li class="common__menu-options-row"
                      [ngShow]="userPermissions !== 'none' && !conceptInfo.poll">
                      <i class="lf-icon-trash"
                          (click)="launchActionOption('delete', evaluateConcept)"
                          matTooltip="{{conceptList.deleteConcept}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </li>
              </ul>
              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
          </div>
      </div>
      <div
        class="concept-list__table-foot"
        [ngClass]="{'concept-list__table-foot--no-items': !conceptList.conceptList.length}">
            <!-- [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize || (conceptList.archivedAmount && toShow !== 'archived') || conceptList.showResultsReports " -->
          <div [ngShow]="conceptList.archivedAmount && toShow !== 'archived' && !conceptInfo.poll"
                class="concept-list__block-info-archive"
                [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none concept-list__archive-button"
                      (click)="viewArchived()">
                  <i class="lf-icon-archive common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                        [afTranslate]="'concept-list.view-archive'">_Ver archivo</span>
              </button>
              <p class="concept-list__archived-evaluated-amount">{{conceptList.archivedParragraph}}</p>
          </div>
          <div [ngShow]="conceptInfo.poll && conceptList.showResultsReports"
                class="concept-list__block-info-archive"
                [ngClass]="{'concept-list__block-info-archive--absolute': conceptList.conceptListFilter.length > conceptList.configPagination.pageSize}">
              <button class="common__button-border-none common__button-border-none--left"
                  (click)="showReports()">
                  <i class="lf-icon-analysis common____icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'concept-list.view-report-results'">_Ver informe de resultados</span>
              </button>
          </div>
          <div class="concept-list__pagination-block">
              <pagination-control
                [ngShow]="conceptList.conceptListFilter.length > conceptList.configPagination.pageSize"
                *ngIf="conceptList.conceptListFilter?.length"
                [config]="conceptList.configPagination"
                [collectionSize]="conceptList.conceptListFilter?.length"
                [(currentPage)]="conceptList.currentPage"
                (configuredTotalChange)="conceptList.configuredTotal = $event.configuredTotal">
              </pagination-control>
              <div class="common__block-footer-total">
                <span [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize">
                  <span class="notranslate">{{conceptList.configuredTotal.first}}-{{conceptList.configuredTotal.last}}</span>
                  <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                  <span class="notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </span>
                <span [ngShow]="conceptList.configPagination.pageSize >= conceptList.conceptListFilter?.length">
                  <span [afTranslate]="'common.total'">_Total</span>
                  <span class="common__total-number-element notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </span>
            </div>
          </div>
      </div>
  </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="conceptList.configurationPdf"></pdf-table>
