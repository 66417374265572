import moment from 'moment';

import { Component, OnChanges, Input } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'schedule-event',
  templateUrl: './schedule-event.component.html'
})
export class ScheduleEventComponent implements OnChanges {
  scheduleEvent: any = {};
  @Input() originalEvent:any = null;
  @Input() searchText:any = null;
  ngOnChanges(): void {
    if (this.originalEvent) {
      this.scheduleEvent.originalEvent = cloneDeep(this.originalEvent);
      let whenFormat = this.searchText || this.scheduleEvent.originalEvent.filtered ? 'DD/MM/YYYY, HH:mm' : 'HH:mm';
      this.scheduleEvent.originalEvent.when = moment(this.scheduleEvent.originalEvent.start).format(whenFormat);
      if (this.scheduleEvent.originalEvent.end) {
          this.scheduleEvent.originalEvent.when += ' - ' + moment(this.scheduleEvent.originalEvent.end).format('HH:mm');
      }
    }
  }
}
