import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'new-custom-report',
  templateUrl: './new-custom-report.component.html'
})
export class NewCustomReportComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  newCustomReport:any = {
    stepState: 1,
    textHeaderTitle: this.translate.instant('new-custom-report.new-report'),
    customReportNameField: {
        label: this.translate.instant('new-custom-report.report-name'),
        id: 'customReportName',
        required: true
    },
    productFieldInfo: {
        label: this.translate.instant('new-custom-report.compliance-product'),
        fieldId: 'productId',
        description: null,
        required: true
    },
    formErrors: {
        configuration: false,
        preview: false
    },
    steps: [
      {
          name: this.translate.instant('new-custom-report.identification'),
          noEditable: false
      },
      {
          name: this.translate.instant('new-custom-report.configuration'),
          noEditable: false
      },
      {
          name: this.translate.instant('new-custom-report.preview'),
          noEditable: false
      }
    ]
  };
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private broadcast:BroadcastService,
    private loader:LoaderFactory,
    private modalService:ModalServiceService
  ) { }

  @Input() selectedProductId:any;
  @Input() productList:any;
  @Input() predefinedReport:any;
  @Input() duplicatedPredefinedReport:any;
  @Input() editedCustomReport:any;

  @Output() closeWindow = new EventEmitter();
  @Output() customReportCreated = new EventEmitter();
  @Output() customReportEdited = new EventEmitter();

  ngOnInit(): void {
    let that = this.newCustomReport;
    if (this.duplicatedPredefinedReport) {
        that.report = this.duplicatedPredefinedReport;
        that.textHeaderTitle = this.translate.instant('new-custom-report.duplicate-report');
    } else if (this.editedCustomReport) {
        that.report = this.editedCustomReport;
        that.textHeaderTitle = this.translate.instant('new-custom-report.edit-report');
    } else if (this.predefinedReport) {
        that.report = this.predefinedReport;
        that.stepState = 3;
    } else {
        that.report = {
            customReportId: null,
            customReportName: '',
            description: '',
            productId: this.selectedProductId,
            selectedReportFields: [],
            conceptsInFirstColumn: false,
            specialFields: false,
            conceptHeaderLabel: this.translate.instant('common.concepts')
        };
    }

    that.productFieldInfo.noEditable = this.duplicatedPredefinedReport || this.editedCustomReport || !(!this.selectedProductId)
  }

  previousPage() {
    this.newCustomReport.stepState--;
  }

  clearForm() {
    let that = this.newCustomReport;
    switch (that.stepState) {
        case 1:
            that.report.customReportName = '';
            that.report.description = '';
            if (!this.selectedProductId && !this.editedCustomReport && !this.duplicatedPredefinedReport) {
                that.report.productId = '';
            }
            break;
        case 2:
            that.report.conceptsInFirstColumn = false;
            this.broadcast.broadcast('clearForm', {});
            break;
        default:
    }
  }

  nextPage() {
    if (this.validateForm()) {
        this.newCustomReport.stepState++;
    }
  }

  validateForm() {
    let that = this.newCustomReport;
    that.formErrors.configuration  = false;
    that.formErrors.preview        = false;
    switch (that.stepState) {
        case 1:
            if (!that.report.customReportName || !that.report.productId || that.report.productId < 0) {
                if(!that.report.customReportFieldName){
                    that.customReportNameField.error = true;
                }
                if(!that.report.productId || that.report.productId < 0){
                    that.productFieldInfo.error = true;
                }
                return false;
            }
            break;
        case 2:
            if (that.report.selectedReportFields.length < 1 || (that.report.selectedReportFields.length === 1 && !that.report.conceptsInFirstColumn)) {
                that.formErrors.configuration = true;
                return false;
            }
            break;
        default:
    }
    return true;
  }

  editOrCreateCustomReport() {
    if (this.editedCustomReport) {
        this.editCustomReport();
    } else {
        this.createCustomReport();
    }
  }

  editCustomReport() {
    let that = this.newCustomReport;
    this.loader.openLoader('new-custom-report');
    this.apiService.update('customreports', that.report.customReportId, that.report).then(
      (data:any) => {
          if (data) {
              this.customReportEdited.emit({ customReport: data });
          } else {
              throw data;
          }
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('new-custom-report.error-in-report-edition'), 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('new-custom-report');
    });
  }

  createCustomReport() {
    let that = this.newCustomReport;
    this.loader.openLoader('new-custom-report');
    this.apiService.add('customreports', that.report).then(
      (data:any) => {
          if (data) {
              this.customReportCreated.emit({ customReport: data });
          } else {
              throw data;
          }
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('new-custom-report.error-in-report-creation'), 'error:accept:warning');
      }
    ).finally(() => {
      this.loader.closeLoader('new-custom-report');
    });
  }

  close(){
    this.closeWindow.emit({});
  }
}
