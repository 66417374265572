<div class="common__field-type">
    <label class="common__input-block common__input-block--field"
        [ngClass]="{'common__input-block--show-references': fieldTypePassword.references && field.evaluatedValue.conceptDataId !== null,
            'common__show-alert': field.error,
            'common__input-no-editable':field.noEditable,
            'common__input-block--custom-field': isLanding}"
        attr.for="field-item-password-{{field.id}}"
        [attr.disabled]="field.noEditable?true:null">
        <div class="common__label-block"
            [ngClass]="field.evaluatedValue && field.evaluatedValue.hasReferences?'common__label-block--two-icon':'common__label-block--one-icon'">
            <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error}">{{fieldTypePassword.titleField}}</span>
            <span class="common__text-required"
                [ngShow]="field.required">*</span>
            <div class="common__info-icon-block"
                [ngHide]="field.noEditable || !field.description">
                <tooltip-info [description]="field.description"
                  [field]="field"></tooltip-info>
            </div>
            <div class="common__tooltip-law-block"
                [ngShow]="field.references && field.references.length && !field.noEditable">
                <tooltip-law [references]="field.references"
                  [field]="field"></tooltip-law>
            </div>
        </div>
        <div class="float-end">
            <i class="lf-icon-connected-data field-type-text__icon-references"
                [ngShow]="field.evaluatedValue && field.evaluatedValue.hasReferences"
                (click)="showReference($event)"></i>
            <button class="lf-icon-not-visible field-item__input-icon-password common__input-icon-active"
                [ngClass]="{'lf-icon-visible': field.visible,
                    'lf-icon-not-visible': !field.visible,
                    'common__input-icon-active--disabled': field.noEditable}"
                (click)="changeVisible()"
                [disabled]="field.noEditable"></button>

        </div>

        <input attr.id="field-item-password-{{field.id}}" attr.name="field-item-password-{{field.id}}"
            class="common__input-content"
            autocomplete="off"
            [ngClass]="{'common__input-content-alert': field.error,
            'common__input-content-no-editable': field.noEditable}"
            type="text"
            [(ngModel)]="fieldTypePassword.inputText"
            (keyup)="deleteCharacters($event)"
            (keypress)="changeCharacters($event)"
            (blur)="changeInField()"
            [maxLength]="field.maxLength?field.maxLength:'524288'"
            [readonly]="field.noEditable"
            (paste)="pasteTextInField($event)"
            [attr.value]="fieldTypePassword.inputText">
        <i class="lf-icon-close common__delete-content-icon"
            [ngHide]="fieldTypePassword.inputText === '' || field.readonly || field.noEditable"
            (click)="resetInput()"></i>
      <div class="field-item__blocker-box"
        *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
    </label>
</div>
