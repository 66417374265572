<div [class]="'compliance'+skin">
  <div class="manage-confidential-modal">
      <div class="common__modal-header-block 6">
          <i class="lf-icon-lock common__icon-header"></i>
          <span class="common__text-header"
            [afTranslate]="'documentation-list.manage-confident'"></span>
          <i class="lf-icon-close common__icon-close-modal"
              (click)="handleDismiss()"></i>
      </div>
      <form class="manage-confidential-modal__form-block">
        <p class="common__title-block-modal manage-confidential-modal__title-block-modal"
          [afTranslate]="'manage-confidential-modal.info-header'">_La etiqueta de Confidencial marca aquellos contenidos con acceso restringido. Por defecto, solo el Responsable puede ver el elemento clasificado como confidencial, pero tiene la posibilidad de autorizar el acceso a otros usuarios.</p>
          <div [ngShow]="manageConfidentialModal.showDeleteConfidential">
            <field-type-switch [label]="'manage-confidential-modal.delete-confident'|afTranslate"
                               [checked]="manageConfidentialModal.check"
                               (callback)="manageConfidentialModal.check = $event.value">
            </field-type-switch>
          </div>
          <div [ngShow]="!manageConfidentialModal.check"
            class="manage-confidential-modal__block-field-users">
            <field-type-text-simple
                [field]="manageConfidentialModal.responsibleConfidential"
                [preSelectItem]="manageConfidentialModal.confidentContacts?.preSelectedResponsible?.fullName">
            </field-type-text-simple>
            <field-type-contacts-multiple [field]="manageConfidentialModal.confidentContacts"
                [valueId]="manageConfidentialModal.valueIdContacts"
                (sendContacts)="receiveContacts($event)"
                (contactsClosed)="contactsClosed()">
            </field-type-contacts-multiple>
          </div>
      </form>
      <div class="common__buttons-block common__buttons-block--add-alert"
          [ngShow]=" manageConfidentialModal.showText || manageConfidentialModal.check">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'">Cancelar</button>
          <button type="submit"
              class="common__button common__button--active"
              (click)="handleAccept()"
              [afTranslate]="'common.save'">Guardar</button>
      </div>
  </div>
</div>
