import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class FolderResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }

    resolve(route: ActivatedRouteSnapshot) {
      return this.apiService.getWithTranslate('tree/translated/'+route.params['evaluationId'], route.params['folderId'])
        .then((data:any)=>{
          return data[0]
        })
    }
}
