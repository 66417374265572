<div class="customize-field-task">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title">{{customizeFieldTask.titleDescription}}</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldTask.tinyMceOptions"
      [modelText]="customizeFieldTask.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-task__group-switch">
      <switch-option [ngShow]="concept.allowPublicShare"
          [tooltipText]="'customize.private-help'"
          [model]="isPrivate?isPrivate:field.private"
          [disabled]="concept.allowPublicShare || isPrivate"
          [fieldText]="'customize.private'"
          (changeOptionEmit)="changeOptionOn($event, 'private')">
      </switch-option>
      <switch-option [ngHide]="concept.allowPublicShare"
          [tooltipText]="'customize.hidden-landing-help'"
          [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
          [disabled]="hiddenInLandings"
          [fieldText]="'customize.hidden-landing'"
          (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
      </switch-option>
    </div>

    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.cancel'">Cancelar </button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.save'">Guardar </button>
    </div>
</div>
