
<div class="client-evaluation">
  <header-nav></header-nav>
  <div class="client-evaluation__body">
      <div class="common__block-button-close-window">
          <button (click)="changeView()"
              class="common__back-link">
              <span [afTranslate]="'client-evaluation.close-client'">_Cerrar cliente</span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="client-evaluation__container">
          <div class="common__block-list-new-version client-evaluation__block-list"
              [ngClass]="{'client-evaluation__block-list--with-legalform': clientEvaluation.showLegalForm}">
              <div class="common__header-new-version">
                  <i class="lf-icon-build common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version"
                      [afTranslate]="'client-evaluation.new-client-evaluation'">_Nueva evaluación de cliente</span>
              </div>
              <wizard-steps [steps]="clientEvaluation.steps"
                  [state]="clientEvaluation.stepState"></wizard-steps>
              <form [ngSwitch]="clientEvaluation.stepState">
                  <div class="client-evaluation__body-container"
                      *ngSwitchCase="1">
                      <field-type-rich-text
                          [field]="clientEvaluation.determinationInfo">
                      </field-type-rich-text>
                      <field-type-clients-selector
                          [field]="clientEvaluation.clientSelector"
                          [productId]="clientEvaluation.productId"
                          [preSelectItem]="clientEvaluation.client.clientId"
                          (itemSelected)="clientSelected($event.value)">
                      </field-type-clients-selector>
                      <field-type-list-simple *ngIf="clientEvaluation.showLegalForm"
                          [field]="clientEvaluation.legalForm"
                          [preSelectItem]="clientEvaluation.client.legalFormId"
                          [listValues]="clientEvaluation.legalFormsList"
                          (itemSelected)="itemSelected('legalFormId', $event.newValue)">
                      </field-type-list-simple>
                      <advice-message [ngShow]="clientEvaluation.showWarningMessage"
                          [icon]="'warning'"
                          [type]="'error'"
                          [text]="clientEvaluation.warningText"
                          [typeClass]="'no-margin'"
                          (closeAdvice)="closeAdvice('showWarningMessage')">
                      </advice-message>
                  </div>
                  <div class="client-evaluation__body-container"
                      *ngSwitchCase="2">
                      <field-type-rich-text
                          [field]="clientEvaluation.rolesAssignationInfo">
                      </field-type-rich-text>
                      <field-type-contacts-multiple *ngFor="let role of clientEvaluation.roles"
                          [field]="role"
                          [valueId]="clientEvaluation.productId"
                          (sendContacts)="receiveContacts($event.contacts, role)">
                      </field-type-contacts-multiple>
                  </div>
                  <div class="client-evaluation__buttons-block"
                      [ngClass]="{'client-evaluation__buttons-block--advice-message': clientEvaluation.showWarningMessage}">
                      <button type="reset"
                          class="common__button common__button--clean"
                          [ngClass]="{'common__button--separating-margin': clientEvaluation.previousCustomization}"
                          (click)="changeView()"
                          [afTranslate]="'common.cancel'">_Cancelar</button>
                      <button type="reset"
                          [ngShow]="clientEvaluation.stepState>=2 && clientEvaluation.hasRoles"
                          class="common__button common__button--clean common__button--separating-margin"
                          [ngClass]="{'common__button--separating-margin': clientEvaluation.previousCustomization}"
                          (click)="goBack()"
                          [afTranslate]="'common.previous'">_Anterior</button>
                      <button type="submit"
                          [ngShow]="clientEvaluation.stepState===1 && clientEvaluation.hasRoles"
                          class="common__button common__button--active common__button--separating-margin"
                          (click)="validateOrganizationConfiguration()"
                          [disabled]="clientEvaluation.client.existingEvaluation"
                          [afTranslate]="'common.next'">_Siguiente</button>
                      <button type="submit"
                          [ngShow]="clientEvaluation.stepState>=2 || !clientEvaluation.hasRoles"
                          class="common__button common__button--active common__button--separating-margin"
                          (click)="createClientRolesEvaluation()"
                          [afTranslate]="'client-evaluation.start-evaluation'">_Iniciar evaluación</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
