<div class="concept-legal-documentation__template-box"
    [ngClass]="{'concept-legal-documentation__template-box--expand': conceptLegalDocumentation.fullScreen,
        'concept-legal-documentation__template-box--min-height':!referencesList.length,
        'concept-legal-documentation__template-box--max-height':referencesList.length}">
    <div class="concept-legal-documentation__border-box"></div>
    <i class="predefined-templates__change-view-icon"
        (click)="maximizeScreen()"
        matTooltip="{{(conceptLegalDocumentation.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
        matTooltipPosition="above"
        matTooltipClass="above"
        [ngClass]="conceptLegalDocumentation.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'">
    </i>
    <div class="box-comunication-channel__template-header box-comunication-channel__template-header--concept-legal-documentation">
        <i class="lf-icon-legal-reference box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text box-comunication-channel__block-text--comment"
        [afTranslate]="'law-info.legal-references'">Documentación jurídica</p>
    <p class="concept-legal-documentation__title-template"
        [afTranslate]="'concept-form.title-legal-concept-documentation'">Consulta la información relacionada con el concepto</p>
    <ul class="predefined-templates__template-list"
        [ngClass]="{'predefined-templates__template-list--expand': conceptLegalDocumentation.fullScreen,
            'predefined-templates__template-list--more-3-elements': (referencesList.length > 3 && !conceptLegalDocumentation.fullScreen) || (referencesList.length > 11 && conceptLegalDocumentation.fullScreen)}">
        <li class="predefined-templates__template-list-items"
            *ngFor="let reference of referencesList"
            [ngClass]="{'predefined-templates__template-list-items--none-hover': conceptLegalDocumentation.userPermissions==='none'}" >
            <i [class]="'predefined-templates__icon-col '+ (reference.type | taskTypeIcons:'reference')"></i>
            <span-tooltip class="predefined-templates__text-list"
              [text]="reference.text"></span-tooltip>
            <div class="predefined-templates__template-menu"
                [ngHide]="conceptLegalDocumentation.userPermissions==='none'">
                <button class="historic-concept__button-eye"
                    (click)="showLegalDocumentation($event, reference)">
                    <i class="lf-icon-visible concept-legal-documentation__icon-eye"
                        matTooltip="{{'common.view' | afTranslate}}"
                        matTooltipPosition="left"
                        matTooltipClass="left">
                    </i>
                </button>
            </div>
        </li>
    </ul>
</div>
