<div class="new-instant-videoconference">
  <div class="common__modal-header-block ">
      <i class="lf-icon-videoconference common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'instant-videoconferences.new-videoconference'">_Nueva videoconferencia instantánea</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div>
      <wizard-steps [steps]="newInstantVideoconference.steps"
          [state]="newInstantVideoconference.stepState"></wizard-steps>
  </div>
  <div class="new-instant-videoconference__block-body"
      [ngShow]="newInstantVideoconference.stepState === 1">
      <div class="new-instant-videoconference__block-title">
          <p class="new-instant-videoconference__title-option"
              [afTranslate]="'instant-videoconferences.contact-question'">_¿Con quién quieres reunirte? Marca si deseas enviar invitaciones con los datos de acceso a la sala. </p>
      </div>
      <form class="new-instant-videoconference__form">
          <field-type-contacts-multiple [field]="newInstantVideoconference.instantVideoconferenceContact"
              (sendContacts)="receiveContacts($event.contacts)"
              (contactsClosed)="contactsClosed()">
          </field-type-contacts-multiple>
          <field-type-switch class="new-instant-videoconference__switch-invitation"
              [label]="'instant-videoconferences.set-invitation' | afTranslate"
              [checked]="newInstantVideoconference.setInvitation"
              (callback)="newInstantVideoconference.setInvitation = $event.value">
          </field-type-switch>
          <div [ngShow]="newInstantVideoconference.setInvitation">
              <field-type-text-simple [field]="newInstantVideoconference.titleVideoconference"
                  [preSelectItem]="newInstantVideoconference.videoconference.title"
                  (itemSelected)="itemSelected($event.newValue, 'title')">
              </field-type-text-simple>
              <field-type-text-simple [field]="newInstantVideoconference.locationVideoconference"
                  [preSelectItem]="newInstantVideoconference.videoconference.location"
                  (itemSelected)="itemSelected($event.newValue, 'location')">
              </field-type-text-simple>
              <div class="new-instant-videoconference__text-rich"
                  [ngShow]="newInstantVideoconference.showText">
                  <rich-editor class="common__input-textarea-special"
                    [tinyMceOptions]="newInstantVideoconference.tinyMceOptions"
                    [modelText]="newInstantVideoconference.message"
                    (emitValue)="newInstantVideoconference.message = $event.model">
                </rich-editor>
              </div>
          </div>

      </form>
  </div>
  <div class="new-instant-videoconference__block-body"
      [ngShow]="newInstantVideoconference.stepState === 2">
      <div class="new-instant-videoconference__block-title">
          <p class="new-instant-videoconference__title-option"
              [afTranslate]="'instant-videoconferences.finish-videoconferencia-question'">_La videoconferencia se ha iniciado correctamente en pestaña nueva. Estos son los datos de acceso a la sala virtual. Si así lo has configurado, los participantes recibirán en breve las invitaciones.</p>
      </div>
      <div class="new-instant-videoconference__connection-data">
          <i class="lf-icon-connect new-instant-videoconference__icon-connect"></i>
          <div class="new-instant-videoconference__info-connection-data">
              <p class="new-instant-videoconference__title-connection-data"
                  [afTranslate]="'task-form.connection-data'">_Datos de conexión</p>
              <p class="new-instant-videoconference__credentials-connection-data">
                  <span >URL</span>
                  <span> :</span>
                  <span class="new-instant-videoconference__credentials-information">{{newInstantVideoconference.videoconference.url}}</span>
                  <i class="lf-icon-paste new-instant-videoconference__icon-copy"
                      (click)="copyText(newInstantVideoconference.videoconference.url)"
                      matTooltip="{{'concept-share.copy' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </i>
              </p>
              <p class="new-instant-videoconference__credentials-connection-data"
                  *ngIf="newInstantVideoconference.optionSelect==='password'">
                  <span [afTranslate]="'login-block.password'">_Contraseña</span>
                  <span> :</span>
                  <span class="new-instant-videoconference__credentials-information">{{newInstantVideoconference.videoconference.password}}</span>
                  <i class="lf-icon-paste new-instant-videoconference__icon-copy"
                      (click)="copyText(newInstantVideoconference.videoconference.password)"
                      matTooltip="{{'concept-share.copy' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                </i>
              </p>
              <p class="new-instant-videoconference__credentials-connection-data"
                  *ngIf="newInstantVideoconference.optionSelect==='open'">
                  <span [afTranslate]="'instant-videoconferences.open-access'">_Acceso abierto</span>
              </p>
          </div>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal"
      [ngHide]="newInstantVideoconference.loadinCreation">
      <button type="reset"
          class="common__button common__button--clean common__button--separating-margin"
          [ngShow]="2 > newInstantVideoconference.stepState"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">_Cancelar</button>
      <button type="submit"
          class="common__button common__button--active common__button--separating-margin"
          (click)="continueOptions()">{{newInstantVideoconference.buttonNext}}</button>
  </div>
</div>
