<div class="evaluation-tree__block-tree"
    *ngFor="let children of childrenList; let last = last">
    <div class="evaluation-tree__block-name-children"
        *ngIf="!children.hidden"
        [ngClass]="{'evaluation-tree__block-name-children--open': !last}">
        <i class="evaluation-tree__icon-arrow"
            [ngClass]="{'lf-icon-arrow-round-right': !children.openTree,
                'lf-icon-arrow-round-down': children.openTree,
                'evaluation-tree__icon-arrow--open': children.children?.length || children.concepts?.length,
                'evaluation-tree__icon-arrow--active': children.active && ((!children.concepts?.length || children.concepts?.length > 1) && !evaluatedConcept?.conceptId)}"
            (click)="openTree($event, children)"></i>
        <p class="evaluation-tree__title-children"
            [ngClass]="{'evaluation-tree__title-children--active': children.active && ((!children.concepts?.length || children.concepts?.length > 1) && !evaluatedConcept?.conceptId),
              'evaluation-tree__title-children--default': children.active}"
            (click)="goTo(children)">
            <span-tooltip [text]="children.name"
                [placement]="'right'"></span-tooltip>
        </p>
        <evaluation-tree *ngIf="children.openTree && evaluationTree.level < 9"
            [childrenList]="children.children"
            [level]="evaluationTree.level"
            [evaluatedConcept]="evaluatedConcept"
            (launchActionTreeEmit)="launchActionTreeOn($event)" ></evaluation-tree>
        <div class="evaluation-tree__block-tree"
            *ngFor="let concept of children.concepts">
            <div class="evaluation-tree__block-name-children"
              *ngIf="!concept.hidden && children.openTree"
                (click)="goTo(children)">
                <i class="evaluation-tree__icon-arrow lf-icon-step-final"
                    [ngClass]="{'evaluation-tree__icon-arrow--active': concept.conceptId == evaluatedConcept?.conceptId,
                      'evaluation-tree__icon-arrow--open': concept.conceptId != evaluatedConcept?.conceptId}"></i>
                <span-tooltip class="evaluation-tree__title-children"
                    [ngClass]="{'evaluation-tree__title-children--active': concept.conceptId == evaluatedConcept?.conceptId}"
                    [text]="concept.title"
                    [placement]="'right'"></span-tooltip>
            </div>
        </div>
    </div>
</div>
