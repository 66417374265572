<div class="automatic-post-process-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-transparency-report common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="'automatic-post-process-modal.automatic-post-process-title'"></span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <form class="automatic-post-process-modal__form">
      <div class="automatic-post-process-modal__block-body">
          <div class="automatic-post-process-modal__block-title">
              <p class="common__title-block-modal" [afTranslate]="'automatic-post-process-modal.info-modal'"></p>
          </div>
          <field-type-list-simple [field]="automaticPostProcessModal.processSteps"
                      [listValues]="automaticPostProcessModal.processStates"
                      [preSelectItem]="item.stateId"
                      (itemSelected)="itemSelected('stateId', $event)">
          </field-type-list-simple>
      </div>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset" class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'"></button>
          <button type="submit" class="common__button common__button--active"
              (click)="validateProcess()"
              [afTranslate]="'common.finish'"></button>
      </div>
  </form>
</div>
