<div class="alert-concept common__block-concept "
    [ngClass]="{'common__block-concept--border': currentComponent === 'concept'}">
    <div class="alert-concept__table">
        <div class="common__table-header">
            <div class="alert-concept__table-col alert-concept__table-col--header alert-concept__table-col--top-1"
                [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--origin-window':'alert-concept__table-col--origin'">
                <filter-by-list [type]="'list'"
                    [item]="alertConcept.sourceFilter"
                    [list]="alertConcept.sourceNameList"
                    (filterElementEmit)="selectTypeOperation($event.source, 'sourceName')">
                </filter-by-list>
            </div>
            <div class="alert-concept__table-col alert-concept__table-col--header alert-concept__table-col--top-1"
                [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--title-window':'alert-concept__table-col--title'">
                <filter-by-search
                    [item]="alertConcept.titleFilter"
                    (launchActionSearch)="orderBySearch($event, 'title')">
                </filter-by-search>
            </div>
            <div class="alert-concept__table-col alert-concept__table-col--header alert-concept__table-col--creator"
                [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--creator-window':'alert-concept__table-col--creator'">
                <filter-by-list [type]="'specialListFind'"
                    [item]="alertConcept.creatorFilter"
                    [list]="alertConcept.creatorList"
                    [showMyOption]="alertConcept.creatorFilter.showMyOption"
                    (filterElementEmit)="selectTypeOperation($event.source, 'creator')">
                </filter-by-list>
            </div>
            <div class="alert-concept__table-col alert-concept__table-col--destin"
                [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--destin-window':'alert-concept__table-col--destin'">
                <p class="common__table-header-text"
                    [afTranslate]="'alert-concept.addressee'">Destinatario</p>
            </div>
            <div class="alert-concept__table-col alert-concept__table-col--header alert-concept__table-col--date-alert alert-concept__table-col--top-1"
                [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--date-alert-window':'alert-concept__table-col--date-alert'">
                <filter-by-list [type]="'list'"
                    [item]="alertConcept.alertDateFilter"
                    [list]="alertConcept.alertDateList"
                    (filterElementEmit)="selectTypeOperation($event.source, 'alertDate')">
                </filter-by-list>
            </div>
            <div class="alert-concept__table-col alert-concept__table-col--action-header">
                <p class="common__table-header-text common__table-header-text--right"
                    [afTranslate]="'common.actions'">Acciones</p>
            </div>
        </div>
        <div class="alert-concept__table-body">
            <div class="common__table-row"
                *ngFor="let alert of alertConcept.filterAlertList; let index = index">
                <div class="alert-concept__table-col alert-concept__table-col--top-3"
                    [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--origin-window':'alert-concept__table-col--origin'">
                    <div class="alert-concept__block-icon-source">
                        <i [class]="'alert-concept__icon-source ' + (alert.source | taskTypeIcons:'alert')"></i>
                    </div>
                    <div class="common__cell-block alert-concept__cell-block-source">
                        <parragraph-tooltip [text]="alert.sourceName"></parragraph-tooltip>
                    </div>
                </div>
                <div class="alert-concept__table-col"
                    [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--title-window':'alert-concept__table-col--title'">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="alert.title"></parragraph-tooltip>
                    </div>
                </div>
                <div class="alert-concept__table-col"
                    [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--creator-window':'alert-concept__table-col--creator'">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="alert.creator"></parragraph-tooltip>
                    </div>
                </div>
                <div class="alert-concept__table-col"
                    [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--destin-window':'alert-concept__table-col--destin'">
                    <participants-list *ngIf="alert.destination.length"
                        [participants]="alert.destination"
                        [notificated]="alert.notifyToIdentifying"
                        [type]="'alert'">
                    </participants-list>
                </div>
                <div class="alert-concept__table-col alert-concept__table-col--date-alert"
                    [ngClass]="currentComponent === 'conceptWindow'?'alert-concept__table-col--date-alert-window':'alert-concept__table-col--date-alert'">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="alert.alertDate"></parragraph-tooltip>
                    </div>
                </div>
                <div class="alert-concept__table-col alert-concept__table-col--action-table">
                    <div [ngShow]="!(skin === 'icam-red' && alert.source === 'Process') && !(userPermissions === 'none' || alert.noPermissions)">
                        <ul class="common__options-row">
                            <li class="common__menu-options-row"
                                [ngShow]="alert.active"
                                (click)="editAlert(alert)">
                                <i class="lf-icon-editable-form"
                                    matTooltip="{{'alert-concept.alert-edit-tooltip' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above">
                                </i>
                            </li>
                            <li class="common__menu-options-row"
                                [ngHide]="alert.noEditable"
                                (click)="deleteAlert(alert)">
                                <i class="lf-icon-trash"
                                    matTooltip="{{'alert-concept.delete-alert' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above">
                                </i>
                            </li>
                        </ul>
                        <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
