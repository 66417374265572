import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class FieldContainerResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }

    resolve(route: ActivatedRouteSnapshot) {
      let extend:any = route.params['fieldContainerId']?route.params['fieldContainerId']+'/front' : 'front';
      let url:any = 'fieldcontainers/'+route.params['evaluationId']+'/'+route.params['conceptId']+'/'+ extend;
      return this.apiService.getWithTranslate(url)
        .then((data:any)=>{
          return data
        })
    }
}
