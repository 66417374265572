<div class="customize-field-text">
    <rich-editor [tinyMceOptions]="customizeFieldTxchk.tinyMceOptions"
      [modelText]="customizeFieldTxchk.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldTxchk.contentReportInfo"
            [preSelectItem]="customizeFieldTxchk.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldTxchk.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldTxchk.field.listVisibleValues"
            [selectedFields]="customizeFieldTxchk.field.fieldVisibleValues"
            [orCondition]="customizeFieldTxchk.field.visibilityOr"
            [readonly]="customizeFieldTxchk.field.noEditable || concept.disabled"
            [itemId]="customizeFieldTxchk.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept?.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldTxchk.field.required"
        [disabled]="customizeFieldTxchk.field.noEditable || concept.disabled"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <div class="customize-field-list__error-block"
        [ngShow]="customizeFieldTxchk.showError">
        <i class="lf-icon-warning establish-pattern__icon-error"></i>
        <p class="establish-pattern__text-error"
            [afTranslate]="'customize-field-list.error-description'"></p>
        <i class="lf-icon-close customize-field-list__icon-close-header"
            (click)="customizeFieldTxchk.showError = !customizeFieldTxchk.showError"></i>
    </div>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
                class="common__button common__button--clean"
                (click)="cancelFieldText()"
                [disabled]="concept.disabled"
                [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
                class="common__button common__button--active"
                (click)="saveFieldText()"
                [disabled]="concept.disabled"
                [afTranslate]="'common.save'">Guardar</button>
    </div>
</div>
