import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HiddenElementsFactory {
  show: boolean = false;
  changeShow(){
    this.show = !this.show;
  }
}
