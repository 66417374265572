<div class="record-audio-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-microphone common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="'record-audio-modal.title'">_Grabación de voz</span>
      <i *ngIf="!recordAudioModal.applyChanges"
        class="lf-icon-close common__icon-close-modal"
        (click)="handleDismiss()"></i>
  </div>
  <div class="record-audio-modal__block-body">
    <div class="record-audio-modal__title">
      <p class="common__title-block-modal record-audio-modal__description-top"
          [afTranslate]="'record-audio-modal.description'">_El mensaje grabado se transcribirá en el editor de texto y el archivo de audio quedará adjunto.
          Ten en cuenta que solo se podrá adjuntar un único audio. Una grabación nueva sustituirá a la anterior.</p>
    </div>
    <div class="record-audio-modal__record-box"
    [ngClass]="{'record-audio-modal__record-box--apply-distorsion':recordAudioModal.applyChanges,
      'record-audio-modal__record-box--show-disclaimer':recordAudioModal.exceededTime && recordAudioModal.applyChanges,
      'record-audio-modal__record-box--no-discard':!recordAudioModal.showDiscard}">
      <div *ngIf="!recordAudioModal.applyChanges">
        <div class="record-audio-modal__distortion-block">
          <label [class]="'record-audio-modal__check-distorsion-box'">
            <i class="record-audio-modal__accept-terms-icon"
              [ngClass]="{'record-audio-modal__accept-terms-icon--no-editable':recordAudioModal.isRecording || recordAudioModal.showPlay,
                'lf-icon-check-full': recordAudioModal.acceptDistorsion,
                'lf-icon-box-inactive': !recordAudioModal.acceptDistorsion}"
                (click)="acceptDistorsionTerms()"></i>
            <p class="record-audio-modal__accept-terms-text"
              [ngClass]="{'record-audio-modal__accept-terms-text--no-editable':recordAudioModal.isRecording || recordAudioModal.showPlay}"
              [afTranslate]="'record-audio-modal.voice-distorsion'">
              _Distorsionar voz
            </p>
          </label>
        </div>
        <div class="record-audio-modal__record-button-block">
          <button *ngIf="!recordAudioModal.showPlay"
            class="record-audio-modal__record-button"
            [ngClass]="{'record-audio-modal__record-button--disabled': recordAudioModal.isRecording && !recordAudioModal.showPlay && recordAudioModal.secondsRecord < 10}"
            (click)="recVoice()">
            <i class=" record-audio-modal__record-button-icon"
              [ngClass]="{'lf-icon-microphone': !recordAudioModal.isRecording && !recordAudioModal.showPlay,
                'lf-icon-stop':recordAudioModal.isRecording && !recordAudioModal.showPlay}"></i>
          </button>
          <button *ngIf="recordAudioModal.showPlay"
            class="record-audio-modal__record-button"
            (click)="playAudio()">
            <i class=" record-audio-modal__record-button-icon"
            [ngClass]="recordAudioModal.showPlay && !recordAudioModal.play?'lf-icon-play' : 'lf-icon-stop'"></i>
          </button>

          <button *ngIf="!recordAudioModal.showPlay && recordAudioModal.applyChanges">
            <i class="lf-icon-refresh record-audio-modal__apply-changes-icon"></i>
          </button>
        </div>
        <p class="record-audio-modal__record-button-text"
          *ngIf="recordAudioModal.blobName === ''">
          {{!recordAudioModal.isRecording && !recordAudioModal.showPlay?('record-audio-modal.press-to-talk' | afTranslate) : ('record-audio-modal.stop-record' | afTranslate)}}
        </p>
        <p *ngIf="recordAudioModal.blobName !== ''"
          class="record-audio-modal__record-button-text">
          {{recordAudioModal.blobName}}
        </p>
        <div class="record-audio-modal__time-record-box">
          <p class="record-audio-modal__record-count"
            [ngClass]="{'record-audio-modal__record-count--active':recordAudioModal.isRecording || recordAudioModal.play}">{{recordAudioModal.recordedTime}}</p>
          <p class="record-audio-modal__record-max-time">{{recordAudioModal.maxTime}}</p>
        </div>
        <div class="record-audio-modal__record-background-line">
          <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--active"
            [progressBar]="recordAudioModal.percentTime">
          </div>
        </div>
        <button *ngIf="recordAudioModal.showDiscard"
          class="record-audio-modal__discard-voice-button"
          [afTranslate]="'record-audio-modal.discard-record'"
          (click)="discardVoiceRecord()">
          _Descartar Grabacion
        </button>
      </div>
      <div *ngIf="recordAudioModal.applyChanges">
        <div class="record-audio-modal__animation">
          <i class="lf-icon-refresh record-audio-modal__apply-changes-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="record-audio-modal__warning-box"
    *ngIf="recordAudioModal.exceededTime && recordAudioModal.applyChanges">
    <i class="lf-icon-warning record-audio-modal__warning-icon"></i>
    <p class="record-audio-modal__warning-text"
      [afTranslate]="'record-audio-modal.excceded-time'">
    </p>
    <i class="lf-icon-close record-audio-modal__warning-close"
      (click)="recordAudioModal.exceededTime = !recordAudioModal.exceededTime"></i>
  </div>
  <div class="record-audio-modal__buttons-block"
    [ngClass]="{'record-audio-modal__buttons-block--warning-box': recordAudioModal.exceededTime && recordAudioModal.applyChanges}">
    <button *ngIf="!recordAudioModal.applyChanges"
      type="reset"
      class="common__button common__button--clean"
      (click)="handleDismiss()"
      [afTranslate]="'common.cancel'">_Cancelar</button>
    <button type="submit"
      *ngIf="recordAudioModal.showPlay"
      class="common__button common__button--active"
      (click)="handleAccept()"
      [afTranslate]="'common.confirm'">_Confirmar</button>
  </div>
</div>
