import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { IcamPriceFormulaFactory } from '@shared/factories/icam-price-formula/icam-price-formula.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-references',
  templateUrl: './field-references.component.html'
})
export class FieldReferencesComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  fieldReferences:any = {
    numberDependencies: [],
    dependencies: []
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService,
    private loader: LoaderFactory,
    private icamPriceFormula: IcamPriceFormulaFactory) { }
  @Input() field: any = null;


  ngOnChanges(){
    if(this.field && this.field.evaluatedValue && this.field.evaluatedValue.hasReferences){
      this.getReferences();
    }
  }

  getReferences(){
    this.apiService.get('conceptdata/dependencies/'+this.field.evaluatedValue.conceptDataId)
        .then((data:any)=>{
            this.fieldReferences.numberDependencies = data.length;
            let icamFragmentsPages: any = 0;
            for(let item of data){
                if(this.field.iD_Version0 === 59 && this.skin ==='icam-red'){
                    icamFragmentsPages += item.icamFragmentPages
                }
                let newDependencies:any = item.path.split('/');
                let route:any = this.extractRoute(newDependencies);
                let isInDependencies:boolean = false;
                for(let dependency of this.fieldReferences.dependencies){
                    if(dependency.route === route){
                        dependency.relatedConcept.push({
                            relatedConceptId: this.commonUtilsService.createId(),
                            relatedConceptName: newDependencies[newDependencies.length-1],
                            conceptId: item.conceptId,
                            conceptObjectId: item.conceptObjectId,
                            folderId: item.folderId
                        })
                        isInDependencies = true;
                        break;
                    }
                }
                if(!isInDependencies){
                    this.configureDependencies(newDependencies, route, item);
                }
            }
            if(this.field.iD_Version0 === 59 && this.skin ==='icam-red'){
                this.icamPriceFormula.set(this.field.fieldContainerId+'-'+this.field.evaluatedValue.numSubContainer, icamFragmentsPages);
                this.broadcastService.broadcast('updateRefereceValue', {field: null})
            }
        }, (errorData:any)=>{})
  }

  configureDependencies(dependencies:any, route:any, data:any){
    this.fieldReferences.dependencies.push({
          idRoute: this.commonUtilsService.createId(),
          route: route,
          parentName: dependencies[0],
          folders: this.extractFoldersConcept(dependencies),
          relatedConcept:[{
              relatedConceptId: this.commonUtilsService.createId(),
              relatedConceptName: dependencies[dependencies.length-1],
              conceptId: data.conceptId,
              conceptObjectId: data.conceptObjectId,
              folderId: data.folderId
          }]
      })
  }

  extractRoute(dependencies:any){
      let route : any = ''
      for(let i = 0; i < dependencies.length; i++){
          if(i === dependencies.length-2){
              route += dependencies[i];
              break;
          }else{
              route += dependencies[i]+'/';
          }
      }
      return route;
  }

  extractFoldersConcept(dependencies:any){
      let relatedFolders : any = [];
      for(let i = 1; i < dependencies.length; i++){
          let folder : any ={
              idFolder: this.commonUtilsService.createId(),
              folderName: dependencies[i]
          }
          relatedFolders.push(folder);
          if(i === dependencies.length-2){
              break;
          }
      }
      return relatedFolders;
  }

  openConcept(concept:any){
      this.loader.openLoader('folder-open-concept');
      this.broadcastService.broadcast('openRelatedConcept', {
          relatedConcept: concept.conceptObjectId,
          active: true,
          evaluatedValue: null
      })
  }
}
