


import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { FieldTypeTextParentService } from './../utils/field-type-text-parent.service';

@Component({
  selector: 'field-type-text-simple',
  templateUrl: './field-type-text-simple.component.html'
})
export class FieldTypeTextSimpleComponent implements OnChanges {
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  id: any = this.commonUtilsService.createId();

  fieldTypeText: any = {
    openLaw: false,
    references: false,
    inputText: '',
    titleField: '',
    minHeight:''
  }

  constructor(private globalCfg:GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService,
    private customLanding: CustomLandingFactory,
    private fieldTypeTextParent: FieldTypeTextParentService) {}

  @Input() field : any ={};
  @Input() preSelectItem : any = '';
  @Input() placeholder: string = '';
  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();
  @Output() itemSelectedEnter = new EventEmitter();


  ngOnChanges(changes: SimpleChanges): void {
     this.fieldTypeTextParent.configureChanges(changes, this)
  }

  keyChangeInField(e: any){
    this.fieldTypeTextParent.keyChangeInField(e, this);
  }

  changeInField(e:any={}){
    this.fieldTypeTextParent.changeInField(e, this)
  }

  resetInput(){
    this.fieldTypeTextParent.resetInput(this);
  }

  showReference(e: any){
    this.fieldTypeTextParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeTextParent.openModalRelatedInfo(e, fieldRelatedInfo, this)
  }

}

