<div class="users-admin-edit">
  <div class="users-admin-edit__header-block">
      <h2 class="users-admin-edit__title">
          <i class="{{usersAdminEdit.typeIcon}} users-admin-edit__icon-title"></i>
          <span>{{usersAdminEdit.title}}</span>
          <span [ngShow]="usersAdminEdit.isAdmin">(<span [afTranslate]="'common.admin'"></span>)</span>
      </h2>
      <h3 class="users-admin-edit__title2"
          *ngIf="operation === 'edit' || operation === 'edit-basic'">
          <span [afTranslate]="'users-admin.edit.title2'">Datos básicos</span>
      </h3>
      <button class="lf-icon-close users-admin-edit__close-link"
          [routerLink]="['/users']"
          routerLinkActive="active"
          [ngHide]="operation === 'edit-basic' || operation === 'see-security'">
      </button>
  </div>
  <div class="users-admin-edit__content"
      [ngClass]="{'users-admin-edit__content--margin-50': (type === 'user' && operation === 'edit-basic') || type ==='contact'}"
      *ngIf="usersAdminEdit.model && (operation === 'edit' || operation === 'edit-basic' || operation === 'new') && type !== 'group'">
      <users-admin-person-form [person]="usersAdminEdit.model"
          [operation]="operation"
          (validateForm)="savePerson()">
      </users-admin-person-form>
  </div>
  <div *ngIf="usersAdminEdit.model && operation === 'edit' && type === 'group'">
      <users-admin-group-form [group]="usersAdminEdit.model"
          [operation]="usersAdminEdit.operation"
          (validateForm)="savePerson()">
      </users-admin-group-form>
  </div>
  <div class="users-admin-edit__content users-admin-edit__content--user-management"
    *ngIf="usersAdminEdit.model && (operation==='security' || operation==='see-security')">
      <div class="users-admin-edit__block-buttons-management"
          *ngIf="type === 'user'">
          <button class="users-admin-edit__button-user-management"
              [ngClass]="{'users-admin-edit__button-user-management--selected': usersAdminEdit.viewManage === 'permissions'}"
              (click)="changeViewManage('permissions')">
              <span [afTranslate]="'users-admin.permissions'">Permisos</span>
              <svg class="users-admin-edit__triangle-block"
                  [ngClass]="{'users-admin-edit__triangle-block--selected': usersAdminEdit.viewManage === 'permissions'}">
                  <polygon points="0,0 21,30 0,30" />
              </svg>
          </button>
          <button class="users-admin-edit__button-user-management"
              [ngClass]="{'users-admin-edit__button-user-management--selected': usersAdminEdit.viewManage === 'roles'}"
              (click)="changeViewManage('roles')"
              [ngShow]="usersAdminEdit.model && usersAdminEdit.model.rolesCount">
              <span [afTranslate]="'common.roles'">_Roles</span>
              <svg class="users-admin-edit__triangle-block"
                  [ngClass]="{'users-admin-edit__triangle-block--selected': usersAdminEdit.viewManage === 'roles'}">
                  <polygon points="0,0 21,30 0,30" />
              </svg>
          </button>
      </div>
      <div [ngClass]="{'users-admin-edit__block-user-management': type === 'user'}">
          <users-admin-security-form [ngShow]="usersAdminEdit.viewManage === 'permissions'"
              [operation]="operation"
              [model]="usersAdminEdit.model"
              (saveSecurity)="saveSecurity($event.security)"></users-admin-security-form>
          <users-admin-roles-list *ngIf="type === 'user'"
              [ngShow]="usersAdminEdit.viewManage === 'roles'"
              [model]="usersAdminEdit.model"></users-admin-roles-list>
      </div>
  </div>
  <div class="users-admin-edit__buttons">
    <button type="submit"
        class="common__button common__button--clean"
        *ngIf="operation !== 'see-security'"
        (click)="cancel()"
        [afTranslate]="'common.cancel'" >_Cancelar</button>
    <button type="submit"
        class="common__button common__button--clean"
        *ngIf="!usersAdminEdit.readonly"
        (click)="reset()"
        [afTranslate]="'common.clean'">_Limpiar</button>
    <button type="submit"
        class="common__button common__button--active"
        *ngIf="!usersAdminEdit.readonly"
        (click)="save()"
        [afTranslate]="'common.save'">_Guardar</button>
  </div>
</div>
