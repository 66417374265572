import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[progressBar]'
})
export class ProgressBarDirective implements OnChanges {

  constructor(private elementRef: ElementRef,
    private renderer: Renderer2) { }
  @Input() progressBar: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', changes['progressBar'].currentValue+'%');
  }
}
