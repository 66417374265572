import { Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-text',
  templateUrl: './customize-field-text.component.html'
})
export class CustomizeFieldTextComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldText: any = {
    disabled: 0,
    tinyMceOptions: {
    },
    voiceRecordInput:{
      label: this.translate.instant('customize.voice-record-accept-text')
    },
    defaultValueInfo: {
      id:this.commonUtilsService.createId(),
      label: this.translate.instant('customize.default-value'), //'Valor por defecto',
      maxLength: 500
    },
    contentTypeInfo: {
      id:this.commonUtilsService.createId(),
      label: this.translate.instant('customize.type-content'), //'Tipo de contenido',
    },
    contentReportInfo: {
      id: this.commonUtilsService.createId(),
      label: this.translate.instant('common.report-field'), //'Campo de Informe',

    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private conceptState: ConceptStateFactory) { }
  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = {};
  @Input() typeView: string = 'normal';
  @Input() contentTypeList: any = null;
  @Input() isMultiple: any = null;

  @Output() changeInField = new EventEmitter();

  ngOnChanges(): void {
    let that: any = this.customizeFieldText;
    that.disabledFormConfiguration = this.typeView === 'channelViewNoEditable' || this.concept?.disabled;
    that.channelViewConfiguration = this.typeView === 'channelViewNoEditable' || this.typeView === 'channelViewEditable';
    if(this.field){
      that.field = this.field;
      this.configureFields();
      that.fieldCopy = cloneDeep(that.field);
      that.defaultValueInfo.multiline = that.field.multiline;
      that.field.voiceRecordOk = that.field.isVoiceRecord && that.field.voiceRecordOkText;
    }
    if(this.concept){
      if(this.concept?.poll && this.concept?.disabled){
        that.contentReportInfo.noEditable = true;
        that.contentTypeInfo.noEditable = true;
        that.defaultValueInfo.noEditable = true;
      }
      if(!this.concept.communicationChannel){
        this.customizeFieldText.field.voiceRecordOk = false;
        this.customizeFieldText.field.voiceRecordOkText = '';
        this.customizeFieldText.field.isVoiceRecord = false;
      }
    }
    if(this.typeView === 'channelViewNoEditable'){
      that.contentTypeInfo.noEditable = true;
      that.defaultValueInfo.noEditable = true;
    }
    that.tinyMceOptions.noEditable = (this.concept.poll && this.concept.disabled) || that.disabledFormConfiguration?true:false;
  }

  configureFields(){
    let that: any = this.customizeFieldText;
    that.contentReportInfo.noEditable =  that.field.noEditable
    that.contentTypeInfo.noEditable = that.field.noEditable
  }

  getEditorValue(event:any){
    this.customizeFieldText.field.description = event.model;
  }

  itemSelected(e: any, item:any){
      let newValue:any = e.newValue;
      this.customizeFieldText.field[item] = newValue;
      this.customizeFieldText.field.isIdentifier = newValue === 'emai';
      if (item === 'fieldContentTypeId' && newValue !== 'emai') {
          this.customizeFieldText.field.isIdentifier = false;
          if (newValue === 'addr') {
              this.customizeFieldText.field.multiline = false;
              this.changeMultiline();
          }
      }
      this.modifyField();
  }

  cancelFieldText(){
    this.customizeFieldText.field = cloneDeep(this.customizeFieldText.fieldCopy);
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldText.field
          }
      })
  }

  validateSave() {
    let that: any = this.customizeFieldText;
    that.field.voiceRecordOkTextError = false;
    if (that.field.isIdentifier && this.isMultiple) {
        that.showError = true;
        return false;
    } else if (that.field.fieldContentTypeId === 'addr' && (that.field.defaultValue != null && that.field.defaultValue != '' && ! this.commonUtilsService.validateURL(that.field.defaultValue))) {
      that.defaultValueInfo.error = true;
      this.modalService.adviceModal(this.translate.instant('customize-field.error-validate'), 'error:accept:warning')
      return false;
    } else if(that.field.voiceRecordOk && (that.field.voiceRecordOkText === null || that.field.voiceRecordOkText === '')){
      that.field.voiceRecordOkTextError = true;
      that.showError = true;
      that.voiceRecordInput.error = true;
      return false;
    }
    that.voiceRecordInput.error = false;
    that.showError = false;
    that.defaultValueInfo.error = false;
    that.field.error = false;
    return true;
  }

  saveFieldText() {
    if (this.validateSave()) {
      this.customizeFieldText.fieldCopy = cloneDeep(this.customizeFieldText.field);
      this.changeInField.emit({
          change: {
              type: 'save',
              field: this.customizeFieldText.field
          }
      });
    }
  }

  changeMultiline(){
      this.customizeFieldText.defaultValueInfo.multiline = this.customizeFieldText.field.multiline;
      this.modifyField();
  }


  openVisibility(e:any){
    this.customizeFieldText.field.visibilityOr = e.visibilityOr;
    this.customizeFieldText.field.openBlock = e.openBlock;
  }

  modifyField(){
      if(this.customizeFieldText.field.isIdentifier){
          if(this.isMultiple){
            this.customizeFieldText.showError = true;
          }
      }

      this.conceptState.add(this.customizeFieldText.field);
  }

  changePrivate(){
      if(this.customizeFieldText.field.private){
        this.customizeFieldText.field.isIdentifier = false;
      }
  }

  changeOptionOn(e:any, type:string){
    switch(type){
      case 'private':
        this.customizeFieldText.field.private = e.model;
        this.changePrivate();
        break;
      case 'isIdentifier':
        this.customizeFieldText.field.isIdentifier = e.model;
        this.modifyField();
        break;
      case 'required':
        this.customizeFieldText.field.required = e.model;
        this.modifyField();
        break;
      case 'multiline':
        this.customizeFieldText.field.multiline = e.model;
        this.changeMultiline();
        break;
      case 'isVoiceRecord':
        this.customizeFieldText.field.voiceRecordOk = e.model;
        this.customizeFieldText.field.voiceRecordOkText = e.model? this.translate.instant('record-audio-modal.check-transcription'): '';
        this.customizeFieldText.field[type] = e.model
        this.modifyField();
        break;
      case 'voiceRecordOk':
        this.customizeFieldText.field.voiceRecordOkText = e.model? this.translate.instant('record-audio-modal.check-transcription'): '';
        this.customizeFieldText.field[type] = e.model
        this.modifyField();
        break;
      case 'voiceRecordOkText':
        this.customizeFieldText.field.voiceRecordOkText = e.newValue ===''? null: e.newValue;
        this.modifyField();
        break;
      default:
        this.customizeFieldText.field[type] = e.model;
        this.modifyField();
        break;
    }
  }
}

