import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionDocument'
})
export class ExtensionDocumentPipe implements PipeTransform {

  transform(item:any): string {
    if(item){
      switch(item.toLowerCase()){
          case 'doc':
          case 'docx':
          case 'docm':
          case 'dot':
          case 'dotx':
          case 'dotm':
          case 'odt':
          case 'rtf':
              return 'lf-icon-word';
          case 'xls':
          case 'xlsx':
          case 'xlm':
          case 'xlsm':
          case 'xlt':
          case 'xltx':
          case 'xltm':
          case 'xlsb':
          case 'xlam':
          case 'xlv':
          case 'csv':
          case 'ods':
              return 'lf-icon-excel';
          case 'ppt':
          case 'pptx':
          case 'pptm':
          case 'pot':
          case 'potx':
          case 'potm':
          case 'ppa':
          case 'ppam':
          case 'pps':
          case 'ppsx':
          case 'ppsm':
          case 'sldx':
          case 'sldm':
          case 'thmx':
          case 'odp':
              return 'lf-icon-ppt';
          case 'pdf':
              return 'lf-icon-pdf';
          case 'bmp':
          case 'gif':
          case 'dib':
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tga':
          case 'tif':
          case 'tiff':
          case 'pcx':
          case 'pic':
          case 'emf':
          case 'ico':
              return 'lf-icon-img';
          case 'avi':
          case 'mp4':
          case 'mov':
          case 'mpeg':
          case 'mpg':
          case 'divx':
          case 'wmv':
          case 'flv':
          case 'mkv':
              return 'lf-icon-video';
          case 'msg':
          case 'eml':
              return 'lf-icon-mail-text';
          case 'rar':
          case 'zip':
          case '7z':
              return 'lf-icon-zip';
          case 'wav':
          case 'mp3':
              return 'lf-icon-sound';
          case 'htm':
          case 'html':
          case 'css':
          case 'url':
          case 'xml':
              return 'lf-icon-html';
          case 'mdb':
              return 'lf-icon-access-document';
          default:
              return 'lf-icon-document';
      }
    }else{
      return 'lf-icon-document'
    }
  }

}
