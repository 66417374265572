<div class="new-custom-report-preview__block-body">
  <div class="new-custom-report-preview__table-container">
      <div class="new-custom-report-preview__table">
          <div class="new-custom-report-preview__table-header-info" [ngShow]="!newCustomReportPreview.loading" *ngIf="!predefinedReport">
              <div class="new-custom-report-preview__table-header-info-fields"
                   *ngIf="newCustomReport.conceptsInFirstColumn">
                  <p class="new-custom-report-preview__table-header-info-field-p"></p>
              </div>
              <div class="new-custom-report-preview__table-header-info-fields" [style]="newCustomReportPreview.reportFieldStyle">
                  <p class="new-custom-report-preview__table-header-info-field-p" [afTranslate]="'new-custom-report-preview.concept-field-value'"></p>
              </div>
              <div class="new-custom-report-preview__table-header-info-fields" [style]="newCustomReportPreview.specialFieldStyle"
                   *ngIf="newCustomReport.specialFields && newCustomReportPreview.numberOfSpecialFields > 0">
                  <p class="new-custom-report-preview__table-header-info-field-p" [afTranslate]="'new-custom-report-preview.other-data'"></p>
              </div>
          </div>
          <div class="new-custom-report-preview__table-header"
               [ngShow]="!newCustomReportPreview.loading"
               *ngIf="!predefinedReport">
              <div class="new-custom-report-preview__table-header-element"
                   *ngIf="newCustomReport.conceptsInFirstColumn">
                  <i class="lf-icon-edit new-custom-report-preview__table-header-icon"
                     (click)="changeHeaderLabel(-1)"></i>
                  <span-tooltip class="new-custom-report-preview__table-header-p"
                    [text]="newCustomReport.conceptHeaderLabel"></span-tooltip>
              </div>
              <div class="new-custom-report-preview__table-header-element"
                   *ngFor="let field of newCustomReport.selectedReportFields; let $index = index"
                   [ngShow]="newCustomReportPreview.reportConcepts.length && (field.reportFieldId || newCustomReport.specialFields)">
                  <i class="lf-icon-edit new-custom-report-preview__table-header-icon"
                     (click)="changeHeaderLabel($index)"></i>
                  <span-tooltip class="new-custom-report-preview__table-header-p"
                    [text]="field.reportFieldName"></span-tooltip>
              </div>
              <div class="new-custom-report-preview__origins-header">
                  <p class="new-custom-report-preview__origins-header-p" [afTranslate]="'common.origin'"></p>
              </div>
          </div>
          <div class="new-custom-report-preview__table-header"
               [ngShow]="!newCustomReportPreview.loading"
               *ngIf="predefinedReport">
              <div class="new-custom-report-preview__table-header-element"
                   *ngFor="let field of predefinedReport.selectedReportFields"
                   [ngShow]="newCustomReportPreview.reportConcepts.length">
                  <i class="lf-icon-map-marker new-custom-report-preview__table-header-icon"
                     *ngIf="field.conceptName"
                      matTooltip="{{'new-custom-report.concept-tooltip' | afTranslate}}"
                      matTooltipPosition="right"
                      matTooltipClass="right">
                  </i>
                  <i class="lf-icon-localization new-custom-report-preview__table-header-icon"
                     *ngIf="!field.conceptName && field.reportFieldId"
                     matTooltip="{{'new-custom-report.report-tooltip' | afTranslate}}"
                     matTooltipPosition="right"
                     matTooltipClass="right">
                  </i>
                  <i class="lf-icon-warning new-custom-report-preview__table-header-icon"
                     *ngIf="!field.conceptName && !field.reportFieldId"
                     matTooltip="{{'new-custom-report.special-tooltip' | afTranslate}}"
                     matTooltipPosition="right"
                     matTooltipClass="right"></i>
                  <span-tooltip class="new-custom-report-preview__table-header-p"
                    [text]="field.reportFieldName"></span-tooltip>
              </div>
              <div class="new-custom-report-preview__origins-header">
                  <p class="new-custom-report-preview__origins-header-p" [afTranslate]="'common.origin'"></p>
              </div>
          </div>
          <div class="new-custom-report-preview__table-body-container"
               [ngShow]="!newCustomReportPreview.loading">
              <div class="new-custom-report-preview__col-body"
                   *ngFor="let concept of newCustomReportPreview.reportConcepts; let $index = index">
                  <div class="new-custom-report-preview__table-element"
                       *ngIf="newCustomReport.conceptsInFirstColumn && !predefinedReport">
                    <span-tooltip class="new-custom-report-preview__table-p"
                      [text]="concept.conceptName"></span-tooltip>
                  </div>
                  <div class="new-custom-report-preview__table-element" *ngFor="let field of concept.fields">
                      <i class="new-custom-report-preview__table-icon"
                         [ngClass]="getIcon(field)"></i>
                      <span-tooltip class="new-custom-report-preview__table-p"
                        [text]="field.fieldName"></span-tooltip>
                  </div>
                  <div class="new-custom-report-preview__origins-icon-container">
                      <i class="lf-icon-map-marker new-custom-report-preview__origins-icon"
                         [ngbTooltip]="concept.conceptPath"
                         placement="left"
                         container="body"
                         [tooltipClass]="'new-custom-report-preview__tooltip-left'"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
