import { Component, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'select-simple',
  templateUrl: './select-simple.component.html'
})
export class SelectSimpleComponent implements OnChanges {
  constructor(private globalCfg: GlobalCfgFactory) { }

  @Input() selectItem:any;
  @Input() listValues:any[];
  @Input() disabled:boolean;
  @Input() widthUl:number = 150;

  @Output() itemSelectedEmit = new EventEmitter();

  skin:string = this.globalCfg.skin;
  selectSimple:any = {
    showList: false
  }

  ngOnChanges(): void{
    let that = this.selectSimple;
    if(!this.selectItem && this.listValues && this.listValues.length){
      that.selectOption = this.listValues[0].text;
      this.itemSelectedEmit.emit(this.listValues[0]);
    }else if(!this.isListValue()){
      that.selectOption = this.selectItem.text;
    }
  }

  isListValue(){
    return Boolean(this.listValues.filter((item:any)=>{ return item.listValueId === this.selectItem}).length)
  }

  openDrop(){
    let that = this.selectSimple;
    if(!this.disabled){
      that.openDropdown = !that.openDropdown
    }
  }

  selectValue(option:any){
    this.selectSimple.selectOption = option.text;
    this.itemSelectedEmit.emit(option);
  }
}
