<div class="duplicate-evaluation">
  <div class="common__modal-header-block">
      <i class="lf-icon-paste common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'duplicate-evaluation.duplicate-implantation'">Duplicar implantación</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <form class="duplicate-evaluation__form"
    [ngClass]="{'duplicate-evaluation__form--special': duplicateEvaluation.specialModal}">
    <p class="common__title-block-modal common__title-block-modal duplicate-evaluation__title-block-modal"
      [afTranslate]="'duplicate-evaluation.info-duplication'"
      *ngIf="duplicateEvaluation.specialModal">_Solo se permite duplicar, con objeto de retomar su revisión, las evaluaciones finalizadas (excepto las No admitidas). Los formularios volverán a ser editables y la información del Sujeto Obligado y del Cliente se actualizará con los datos centralizados.</p>
    <div class="duplicate-evaluation__block-fields">
        <field-type-list-simple *ngIf="!duplicateEvaluation.specialModal"
            [field]="duplicateEvaluation.clientInfo"
            [preSelectItem]="duplicateEvaluation.newEvaluation.client"
            [listValues]="duplicateEvaluation.clientList"
            [fieldToFind]="'text'"
            (itemSelected)="itemSelected('client', $event.newValue)"></field-type-list-simple>
        <field-type-text-simple
            [field]="duplicateEvaluation.evaluationInfo"
            [preSelectItem]="duplicateEvaluation.newEvaluation.name"
            (itemSelected)="itemSelected('name', $event.newValue)">
        </field-type-text-simple>
    </div>
    <div class="duplicate-evaluation__radio-button-block"
      *ngIf="!duplicateEvaluation.specialModal">
        <field-type-switch [label]="'duplicate-evaluation.keep-content'|afTranslate"
                            [checked]="duplicateEvaluation.newEvaluation.preserveContent"
                            (callback)="duplicateEvaluation.newEvaluation.preserveContent = $event.value">
        </field-type-switch>
    </div>
  </form>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="validateInfo()"
          [afTranslate]="'common.duplicate'">Duplicar</button>
  </div>
</div>
