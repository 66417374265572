<div class="snippets-reusable common__panel">
    <header-nav></header-nav>
    <div class="common__block-button-close-window">
        <button (click)="changeViewWindow()"
            class="common__back-link">
            <span [afTranslate]="'snippets-reusable.close-snippets'">_CERRAR FRAGMENTOS</span>
            <i class="lf-icon-close common__back-link-icon"></i>
        </button>
    </div>
    <div class="snippets-reusable__block-container">
        <div class="common__block-list-new-version snippets-reusable__body">
            <div class="common__header-new-version">
                <i class="lf-icon-model common__header-icon-new-version"
                    [ngShow]="skin !== 'uk-steel-blue'"></i>
                <span class="common__header-title-new-version"
                    [afTranslate]="'menu-header.snippets-reusable'">_Fragmentos reutilizables</span>
            </div>
            <form class="snippets-reusable__form-body">
                <field-type-rich-text
                    [field]="snippetsReusable.utilityInfo">
                </field-type-rich-text>
                <div class="snippets-reusable__block-snippet">
                    <div [ngShow]="!snippetsReusable.snippetsList.length && snippetsReusable.charged">
                        <div class="snippets-reusable__title-new-snippet">
                            <span class="snippets-reusable__title-new-snippet-span"
                                [afTranslate]="'snippets-reusable.new-snippet'">_Nuevo fragmento</span>
                        </div>
                        <field-type-text-simple class="snippets-reusable__short-id"
                            [field]="snippetsReusable.shortIdField"
                            [preSelectItem]="snippetsReusable.snippet.shortId"
                            (itemSelected)="itemSelected('shortId', $event.newValue)">
                        </field-type-text-simple>
                        <field-type-text-simple [field]="snippetsReusable.titleField"
                            [preSelectItem]="snippetsReusable.snippet.title"
                            (itemSelected)="itemSelected('title', $event.newValue)">
                        </field-type-text-simple>
                        <field-type-rich-text-editable
                            [field]="snippetsReusable.textField"
                            (itemSelected)="itemSelected('text', $event.newValue)">
                        </field-type-rich-text-editable>
                    </div>
                    <div [ngShow]="snippetsReusable.snippetsList.length && snippetsReusable.charged">
                        <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                            (click)="createNewSnippet()"
                            matTooltip="{{'snippets-reusable.new-snippet' | afTranslate}}"
                            matTooltipPosition="left"
                            matTooltipClass="left">
                        </button>
                        <div class="snippets-reusable__snippets-container">
                            <div class="snippets-reusable__snippets-inner-container">
                                <div *ngFor="let snippet of snippetsReusable.snippetsList; let index = index"
                                    [id]="index"
                                    class="snippets-reusable__snippet-list-element"
                                    [ngHide]="snippet.hideSnippet">
                                    <h5 class="snippets-reusable__title-snippet-list snippets-reusable__search-text">{{snippet.title}}</h5>
                                    <h6 class="snippets-reusable__short-id-snippet-list snippets-reusable__search-text">#{{snippet.shortId}}</h6>
                                    <p class="snippets-reusable__text-snippet-list snippets-reusable__search-text snippets-reusable__value-bind-html"
                                        [innerHTML]="snippet.text"></p>
                                    <ul class="common__options-row">
                                        <li class="common__menu-options-row"
                                            (click)="editSnippet(snippet)"
                                            matTooltip="{{'common.edit' | afTranslate}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above">
                                            <i class="lf-icon-editable-form"></i>
                                        </li>
                                        <li class="common__menu-options-row"
                                            (click)="copySnippet(snippet)"
                                            matTooltip="{{'concept-share.copy' | afTranslate}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above">
                                            <i class="lf-icon-copy"></i>
                                        </li>
                                        <li class="common__menu-options-row"
                                            (click)="deleteSnippet(snippet)"
                                            matTooltip="{{'common.remove' | afTranslate}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above">
                                            <i class="lf-icon-trash"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="common__block-footer-table">
                            <div class="common__block-search snippets-reusable__block-search">
                              <input class="common__search-input"
                                [ngShow]="snippetsReusable.search"
                                type="text"
                                (keyup)="searchTextfunction($event)"
                                [(ngModel)]="snippetsReusable.searchSnippets"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="{{'common.search' | afTranslate}}"
                                [focusWhen]="snippetsReusable.search">
                                <!-- <input type="text"
                                    [ngShow]="snippetsReusable.search"
                                    class="common__search-input"
                                    ng-keyup="snippetsReusable.searchTextfunction($event)"
                                    ng-model="snippetsReusable.searchSnippets"
                                    placeholder="{{'common.search' |translate}}"
                                    [focusWhen]="snippetsReusable.search"> -->
                                <span class="common__text-search"
                                    [ngShow]="!snippetsReusable.search"
                                    (click)="toggleSearch()"
                                    [afTranslate]="'common.search'">_Buscar</span>
                                <button class="lf-icon-search common__icon-button common__icon-button-search"
                                    [ngClass]="{'lf-icon-search': !snippetsReusable.search,
                                        'lf-icon-close-search': snippetsReusable.search,
                                        'common__icon-button-search--open': snippetsReusable.search}"
                                    (click)="toggleSearch()">
                                </button>
                            </div>
                            <div class="common__block-footer-total">
                                <span [afTranslate]="'common.total'">_Total</span>
                                <span class="common__total-number-element notranslate">{{snippetsReusable.countSnippetsList}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="snippets-reusable__buttons-block"
                [ngShow]="!snippetsReusable.snippetsList.length && snippetsReusable.charged">
                <button type="reset"
                    class="common__button common__button--clean"
                    [ngClass]="{'common__button--separating-margin': snippetsReusable.previousCustomization}"
                    (click)="changeViewWindow()"
                    [afTranslate]="'common.cancel'">_Cancelar</button>
                <button type="submit"
                    class="common__button common__button--active common__button--separating-margin"
                    (click)="saveNewSnippet()"
                    [afTranslate]="'common.save'">_Guardar</button>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
