<ngb-pagination class="pagination-control"
  [boundaryLinks]="paginationControl.boundaryLinks"
  [collectionSize]="paginationControl.collectionSize"
  [directionLinks]="paginationControl.directionLinks"
  [disabled]="paginationControl.disabled"
  [ellipses]="paginationControl.ellipses"
  [maxSize]="paginationControl.maxSize"
  [(page)]="currentPage"
  [pageSize]="paginationControl.pageSize"
  [rotate]="paginationControl.rotate"
  [size]="paginationControl.size"
  (pageChange)="pageChange()">
  <ng-template ngbPaginationFirst><i class="lf-icon-double-arrow-left pagination-control__page-arrow"></i></ng-template>
  <ng-template ngbPaginationPrevious>
    <i class="lf-icon-angle-left pagination-control__page-arrow"></i>
    <button class="pagination-control__prev-next-button"
      (click)="prevTo()"
      [ngShow]="paginationControl.showEllipsisPages && (currentPage > paginationControl.elementPrevEllipsis)">...</button>
  </ng-template>
  <ng-template ngbPaginationNumber let-page><span class="page-number">{{page}}</span></ng-template>
  <ng-template ngbPaginationNext>
    <button class="pagination-control__prev-next-button"
      (click)="nextTo()"
      [ngShow]="paginationControl.showEllipsisPages && (paginationControl.pages >= (currentPage + paginationControl.elementEllipsis))">...</button>
    <i class="lf-icon-angle-right pagination-control__page-arrow"></i>
  </ng-template>
  <ng-template ngbPaginationLast><i class="lf-icon-double-arrow-right pagination-control__page-arrow"></i></ng-template>
  <!-- <ng-template ngbPaginationEllipsis><span>pepe</span></ng-template> -->
</ngb-pagination>
