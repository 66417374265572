<div class="field-type-role">
  <div class="field-type-role__container">
      <div class="field-type-role__block-header">
          <i class="lf-icon-lead field-type-role__icon-head"></i>
          <span class="field-type-role__title-rol">{{field.name}}</span>
          <button class="lf-icon-add-round common__header-button-add-new-version"
              (click)="assignRole()"
              *ngIf="userPermissions !== 'none'"
              matTooltip="{{'field-type-role.new-assignment' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </button>
      </div>
      <p class="field-type-role__info-no-users"
          [ngShow]="!field.selectContacts.length"
              [afTranslate]="'field-type-role.no-users-assigned'">_No hay usuarios asignados al rol</p>
      <div class="common__table-new-version"
          [ngShow]="field.selectContacts.length">
          <div class="field-type-role__table-header common__table-header">
              <div class="field-type-role__col field-type-role__col--user field-type-role__col--first field-type-role__col--top-1">
                  <filter-by-search
                      [item]="fieldTypeRole.fullNameFilter"
                      [filterText]="fieldTypeRole.fullNameWordFilter"
                      (launchActionSearch)="orderBySearch($event, 'fullName')">
                  </filter-by-search>
              </div>
              <div class="field-type-role__col field-type-role__col--mail">
                  <p class="common__table-header-text"
                      [afTranslate]="'common.mail'">_Correo</p>
              </div>
              <div class="field-type-role__col field-type-role__col--phone">
                  <p class="common__table-header-text"
                      [afTranslate]="'common.phone'">_Teléfono</p>
              </div>
              <div class="field-type-role__col field-type-role__col--organizations field-type-role__col--top-1">
                  <filter-by-search
                      [item]="fieldTypeRole.organizationsFilter"
                      [filterText]="fieldTypeRole.organizationsWordFilter"
                      (launchActionSearch)="orderBySearch($event, 'organizations')">
                  </filter-by-search>
              </div>
              <div class="field-type-role__col field-type-role__col--actions">
                  <p class="common__table-header-text common__table-header-text--right"
                      [afTranslate]="'common.actions'">_Acciones</p>
              </div>
          </div>
          <div class="field-type-role__table-body"
              [ngClass]="{'field-type-role__table-body--more-elements': fieldTypeRole.filterOrganizationList.length > 5}">
              <div class="field-type-role__table-row common__table-row"
                  *ngFor="let user of fieldTypeRole.filterOrganizationList | afOrderBy:['numOrder']:true; let i = index">
                  <div class="field-type-role__col field-type-role__col--user field-type-role__col--first">
                      <parragraph-tooltip [text]="user.fullName"></parragraph-tooltip>
                  </div>
                  <div class="field-type-role__col field-type-role__col--mail">
                      <parragraph-tooltip [text]="user.email"></parragraph-tooltip>
                  </div>
                  <div class="field-type-role__col field-type-role__col--phone">
                      <parragraph-tooltip [text]="user.phoneNumber1"></parragraph-tooltip>
                  </div>
                  <div class="field-type-role__col field-type-role__col--organizations">
                      <participants-list *ngIf="user.clients && user.clients.length"
                                          class="field-type-role__block-participants"
                                          [participants]="user.clients"
                                          [type]="'organizations'">
                      </participants-list>
                  </div>
                  <div class="field-type-role__col field-type-role__col--actions">
                      <div *ngIf="userPermissions !== 'none'">
                          <ul class="field-type-role__options-row common__options-row">
                              <li class="common__menu-options-row">
                                  <i class="lf-icon-editable-form"
                                      matTooltip="{{'field-type-role.edit-assignment' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      (click)="assignRole(user)"></i>
                              </li>
                              <li class="common__menu-options-row">
                                  <i class="lf-icon-trash"
                                      matTooltip="{{'field-type-role.delete-assignment' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      (click)="deleteUserRole(user)"></i>
                              </li>
                          </ul>
                          <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
