<div class="favorites">
  <div [ngShow]="favorites.favoritesList?.length">
      <div class="favorites__list-order-block">
          <i class="favorites__list-order-icon"
              [ngClass]="favorites.orderAsc ? 'lf-icon-arrow-up' : 'lf-icon-arrow-down'"
              (click)="favorites.orderAsc = !favorites.orderAsc"></i>
          <p class="favorites__list-order-text" [afTranslate]="'favorites.order-by'"></p>
          <button class="favorites__list-order-button"
              [ngClass]="{'favorites__list-order-button--active': favorites.order === 'accessDate'}"
              [afTranslate]="'favorites.last-access'"
              (click)="orderBy('accessDate')">
          </button>
          <button [afTranslate]="'common.name'" class="favorites__list-order-button span__auto-ajust"
              [ngClass]="{'favorites__list-order-button--active': favorites.order === 'title'}"
              (click)="orderBy('title')">
          </button>
      </div>
      <ul class="favorites__favorites-list-block">
          <li class="favorites__favorite-item"
              *ngFor="let favorite of favorites.favoritesList | afOrderBy:[favorites.order]:favorites.orderAsc | afLimitTo:favorites.configPagination.pageSize:favorites.configPagination.pageSize*(favorites.currentPage - 1); let $lastFavorite = last">
              <i class="lf-icon-favorite-full favorites__favorite-icon"></i>
              <div class="favorites__favorite-item-block" [ngClass]="{'favorites__favorite-item-block--last': $lastFavorite}">
                  <p class="favorites__item-title">
                      <span class="favorites__item-title-span"
                          [ngClass]="{'favorites__item-title-span--not-visible': !favorite.isVisible}"
                          (click)="openConcept(favorite)">{{favorite.title}}</span>
                  </p>
                  <ul class="favorites__route-list">
                      <i class="lf-icon-map-marker favorites__route-icon"></i>
                      <li class="favorites__route-item"
                        *ngFor="let route of favorite.route; let $lastRoute = last">
                          {{route.name}}
                          <span [ngHide]="$lastRoute">> </span>
                      </li>
                  </ul>
                  <p class="favorites__last-access">
                      <span [afTranslate]="'favorites.last-access'"></span>:
                      <span class="favorites__last-access--date">{{favorite.accessDate | convertDate: 'without-time'}}</span>
                  </p>
              </div>
              <div class="favorites__item-menu" ngbDropdown>
                  <i class="lf-icon-kebab-menu favorites__kebab-icon" ngbDropdownToggle></i>
                  <ul class="favorites__options-menu" ngbDropdownMenu>
                      <li class="common__list-dropdown-option" *ngFor="let optionMenu of favorite.menuOption; let $lastMenuOption = last">
                          <button class="common__button-option-list-dropdown"
                              [ngClass]="{
                                'common__button-option-list-dropdown--last': $lastMenuOption,
                                'common__button-option-list-dropdown--not-visible': !favorite.isVisible && optionMenu.action === 'none'
                              }"
                              (click)="launchAction(optionMenu.action, favorite)">
                              <i class="{{optionMenu.icon}} common__icon-option-list-dropdown"></i>
                              <span class="common__text-menu-option-dropdown"
                                  [afTranslate]="optionMenu.name"
                                  [ngClass]="{'favorites__not-visible-color': !favorite.isVisible && optionMenu.action === 'none'}"></span>
                          </button>
                      </li>
                  </ul>
              </div>
          </li>
      </ul>
      <pagination-control [ngShow]="favorites.favoritesList?.length > 10"
        [config]="favorites.configPagination"
        [collectionSize]="favorites.favoritesList?.length"
        [(currentPage)]="favorites.currentPage"></pagination-control>
  </div>
  <div [ngShow]="favorites.chargedCall &&!favorites.favoritesList?.length" class="favorites__block-not-favorites">
      <i class="lf-icon-favorite-full result-search__icon-none-result"></i>
      <p class="result-search__none-primary-text" [afTranslate]="'favorites.not-favorites'"></p>
      <p class="result-search__none-secondary-text" [afTranslate]="'favorites.text-not-favorites'"></p>
  </div>
</div>
