import { Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { TargetService } from '@shared/services/target/target.service';

@Component({
    selector: 'menu-header-user',
    templateUrl: './menu-header-user.component.html'
})
export class MenuHeaderUserComponent implements OnInit, OnDestroy {
  subscribers : any = {};
  skin: string =  this.globalCfg.skin;
  targetState:any;
  menuHeaderUser:any = {
    userId: null,
    goToMyAccount: this.skin === 'dark-blue' || this.skin === 'gm-law',
    showResetPassword: false
  };

  contextProperties = this.context;
  frontUser =  this.context?.user?.companyId;
  administrationPermits  = this.context?.user?.isAdmin && this.frontUser;
  canCustomize = this.administrationPermits && this.skin !== 'uk-steel-blue';

  constructor(
      private translate: AfTranslateFactory,
      private loader:LoaderFactory,
      private modalService: ModalServiceService,
      private route: ActivatedRoute,
      private router: Router,
      private context: ContextFactory,
      private stateManagement: StateManagementFactory,
      private conceptState: ConceptStateFactory,
      private commonUtilsService: CommonUtilsService,
      private globalCfg:GlobalCfgFactory,
      private broadcast: BroadcastService,
      private targetService: TargetService,
      private apiService: ApiServiceService,
      private broadcastService: BroadcastService) { }

  @Output() closeMenu = new EventEmitter();

  ngOnInit(){
    this.targetState = this.targetService.parseString(this.route.snapshot.url);
    this.menuHeaderUser.text = this.translate.instant('menu-header.change-password-message');
    this.menuHeaderUser.userId = this.context.user.userId;
    this.menuHeaderUser.name = this.context.user.fullName;
    if(this.frontUser && !(this.context.user.isAdmin && this.skin === 'uk-steel-blue')){
        this.menuHeaderUser.showResetPassword = true;
    }
    this.subscribers.exitConceptMenuHeaderUserBroadcast = this.broadcastService.subscribe('exitConceptMenuHeaderUser', (data:any)=>{
      this.goTo(data.option);
    });
    this.getCompany();
  }

  getCompany(){
    if(this.context.user.companyId){
      this.apiService.get('company', this.context.user.companyId)
          .then((data:any) => {
              this.menuHeaderUser.company = data;
          },(errorData:any)=>{console.log(errorData)});
        }
  }

  resetPassword() {
      this.closeDropdown(true);
      if(this.skin === 'steel-blue' && this.menuHeaderUser.urlPassword){
          let language = this.commonUtilsService.getLanguage().substring(0,2);
          let languageOption = language;
          let url = this.menuHeaderUser.urlPassword;
          if(language === 'es'){
              languageOption = 'en';
          }
          url = url.replace('{language}', languageOption);
          window.open(url, '_blank');
      }else{
        this.modalService.resetPassword()
          .subscribe((result:any) => {
            if(result.result === 'ok'){
              this.openInfoModal(this.menuHeaderUser.text);
            }
          });
      }
  }
  closeDropdown(resetPass:any){
      if(this.checkCurrentComponent() !== 'usersAdminEdit' || resetPass === 'closeMenu'){
        if(!resetPass)
            this.loader.openLoader('menu-header-user-edit-user');
      }
      this.closeMenu.emit();
  }

  goToOption(type:any){
      if(this.conceptState.getAmount() || this.conceptState.getProcess()){
        if(this.checkConceptComponent()){
            this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question').subscribe((result:any) => {
                  if(result.result !== 'cancel'){
                      if(result.result === 'save-exit'){
                        if(this.checkCurrentComponent() === 'conceptWindow'){
                            this.broadcast.broadcast('saveConceptWindow', {from:'menuHeaderUser', option: type});
                        }else{
                            this.broadcast.broadcast('saveConceptToEvaluation', {from:'menuHeaderUser', option: type});
                        }
                      }
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(false)
                      this.goTo(type);
                  }
              });
        }else{
          this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
              .subscribe((result:any) => {
                if(result.result !== 'cancel'){
                  this.conceptState.removeAll();
                  this.conceptState.setProcess(false)
                  this.goTo(type);
                }
              }),(errorData:any)=>{
                this.loader.closeError();
              };
        }
      }else{
          this.goTo(type);
      }
  }

  goTo(param:any){
      this.stateManagement.setStateUrl(window.location.pathname);
      switch(param){
          case 'edit-basic':
          case 'see-security':
            this.router.navigate(['users/'+param, 'user', this.menuHeaderUser.userId]);
            break;
          case 'main':
            this.router.navigate(['users']);
            break;
          case 'snippets':
              if(this.targetState.name !== 'root.snippets'){
                this.loader.openLoader('open-snippets-window-loader');
                this.router.navigate(['snippets']);
              }
              break;
          case 'global-customization':
              if(this.targetState.name !== 'root.customization'){
                  this.loader.openLoader('open-global-customization-window-loader');
                  this.router.navigate(['customization']);
              }
              break;
          default:
              this.router.navigate(['users']);
      }
      this.closeMenu.emit();
  }

  goMyAccount(){
    let url = 'https://areapersonal.lefebvre.es/login/dashboard/'+ this.context.user.externalAccessId;
    window.open(url, '_blank');
  }

  openInfoModal(text:string){
    this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  checkCurrentComponent() {
    let currentComponent: any = this.route.snapshot.firstChild?.data['componentName']?this.route.snapshot.firstChild?.data['componentName']:this.route.snapshot.data['componentName'];
    return currentComponent;
  }

  checkConceptComponent() {
    let currentComponent: any = this.checkCurrentComponent();
    return currentComponent === 'concept' || currentComponent === 'conceptWindow';
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
