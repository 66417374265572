import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'app-new-snippet-modal',
  templateUrl: './new-snippet-modal.component.html',
  styleUrls: ['./new-snippet-modal.component.scss']
})
export class NewSnippetModalComponent implements OnInit {
  newSnippetModal: any = {
    snippet: {},
    shortIdField: {
      id: 'short-id',
      required: true,
      maxLength: 20,
      label: this.translate.instant('snippets-reusable.short-id')
    },
    titleField: {
      id: 'descriptive-title',
      required: true,
      maxLength: 100,
      label: this.translate.instant('snippets-reusable.descriptive-title')
    },
    textField: {
      label: this.translate.instant('snippets-reusable.text-snippet'),
      fieldId: 'text-snippets',
      fieldTypeId: 'rich',
      required: true,
      isEditable: true,
      noEditable: false
    },
    warningText: this.translate.instant('snippets-reusable.error-id-snippet')
  }

  constructor(private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }

  @Input() list: any = null;
  @Input() configuration: any = null;

  ngOnInit(): void {
    this.newSnippetModal.snippet = this.configuration.snippet;
    if ((this.configuration.type === 'edit' || this.configuration.type === 'new-fragment') && this.configuration.snippet.text){
      this.newSnippetModal.textField.description = this.configuration.snippet.text;
    }
  }

  itemSelected(newValue:any, type:string){
      this.newSnippetModal.snippet[type] = newValue;
      this.newSnippetModal[type+'Field'].error = false;
      this.newSnippetModal.hasError = false;
  }

  closeAdvice(type:string){
    this.newSnippetModal[type] = false;
  }

  saveSnippet(){
    if(this.checkFields()){
        let snippet:any = {
            shortId: this.newSnippetModal.snippet.shortId,
            title: this.newSnippetModal.snippet.title,
            text: this.newSnippetModal.textField.description
        }
        if(this.newSnippetModal.snippet.snippetId){
            snippet.snippetId = this.newSnippetModal.snippet.snippetId;
        }
        this.handleAccept(snippet);
    }
  }

  checkFields(){
      let validateFields:any = true;
      let snippet: any = this.newSnippetModal.snippet
      if(!this.newSnippetModal.snippet.shortId){
        this.newSnippetModal.shortIdField.error = true;
        validateFields = false;
      }else{
          for(let i = 0; i < this.list.length; i++){
            let item: any = this.list[i];
              if(item.shortId === snippet.shortId && (!snippet.snippetId || snippet.snippetId !== item.snippetId)){
                this.newSnippetModal.shortIdField.error = true;
                  validateFields = false;
                  break;
              }
          }
          this.newSnippetModal.hasError = this.newSnippetModal.shortIdField.error;
      }
      if(!snippet.title){
          this.newSnippetModal.titleField.error = true;
          validateFields = false;
      }
      if(!this.newSnippetModal.textField.description){
          this.newSnippetModal.textField.error = true;
          validateFields = false;
      }
      return validateFields;
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept(snippet:any) {
    this.activeModal.close({
      result : 'ok',
      snippet : snippet
    })
  };

    }
