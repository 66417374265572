<div class="new-custom-report-identification__block-body">
  <field-type-rich-text class="new-custom-report__block-info"
      [field]="newCustomReportFieldIdentification.identityInfo"
      *ngIf="newCustomReportFieldIdentification.identityInfo">
  </field-type-rich-text>
  <div class="new-custom-report-identification__product-selection">
      <field-type-list-simple [field]="productFieldInfo"
                          [preSelectItem]="newCustomReportField.productId"
                          (itemSelected)="itemSelected('productId', $event.newValue)"
                          [listValues]="productList">
      </field-type-list-simple>
      <field-type-text-simple [field]="customReportFieldName"
                          [preSelectItem]="newCustomReportField.customReportFieldName"
                          (itemSelected)="itemSelected('customReportFieldName', $event.newValue)">
      </field-type-text-simple>
  </div>
</div>
