import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class TaskResolve implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }
    resolve(route: ActivatedRouteSnapshot) {
      if(route.params['taskId'] !=='' && route.params['taskId'] !== undefined){
        return this.apiService.get('tasks', route.params['taskId'])
            .then((data:any)=>{
              return data;
            })
      }else{
          return null;
      }
    }

}
