import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-control',
  templateUrl: './pagination-control.component.html'
})
export class PaginationControlComponent implements OnChanges {
  @Input() config:any = {};
  @Input() currentPage:number = 1;
  @Input() collectionSize:number = 0;
  @Output() currentPageChange = new EventEmitter<number>();
  @Output() configuredTotalChange = new EventEmitter();

  paginationControl:any = {
    boundaryLinks: true,
    collectionSize: 0,
    directionLinks: true,
    disabled: false,
    ellipses: false,
    maxSize: 10,
    pageSize: 2,
    rotate: true,
    size: 'sm',
    pages: 0,
    elementEllipsis: 5,
    elementPrevEllipsis: 6
  };

  ngOnChanges() {
    if(this.collectionSize){
      this.paginationControl.collectionSize = this.collectionSize;
      for(let element in this.config){
        if(this.paginationControl[element] !== undefined){
          this.paginationControl[element] = this.config[element];
        }else{
          console.error('Property "' + element + '" is not valid.');
        }
      }
      this.paginationControl.elementEllipsis = (this.paginationControl.maxSize/2);
      this.paginationControl.elementPrevEllipsis = this.paginationControl.elementEllipsis+1;
      this.paginationControl.pages = Math.ceil(this.paginationControl.collectionSize / this.config.pageSize);
      this.paginationControl.showEllipsisPages = this.paginationControl.pages > this.paginationControl.maxSize;
    }
  }

  pageChange() {
    this.currentPageChange.emit(this.currentPage);
    let configuredTotal = {
      first: this.currentPage === 1? 1 : this.config.pageSize * (this.currentPage - 1) + 1,
      last: this.currentPage === this.paginationControl.pages? this.paginationControl.collectionSize : this.config.pageSize * this.currentPage,
    }
    this.configuredTotalChange.emit({configuredTotal:configuredTotal});
  }

  nextTo(){
    this.currentPage += this.paginationControl.maxSize-1;
    this.currentPageChange.emit(this.currentPage);
  }

  prevTo(){
    this.currentPage -= this.paginationControl.maxSize-1;
    this.currentPageChange.emit(this.currentPage);
  }
}
