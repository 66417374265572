import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'client-evaluation',
  templateUrl: './client-evaluation.component.html'
})
export class ClientEvaluationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  clientEvaluation: any = {
    hasRoles: false,
    productId: 'blanqueo',
    showLegalForm: this.skin !== 'steel-blue',
    stepState: 1,
    warningText: this.translate.instant('client-evaluation.warning-existing-evaluation'),
    client: {},
    determinationInfo: {
        type: 'no-margin-height-135',
        fieldTypeId: 'rich',
        isHighlight: true,
        label: this.translate.instant('client-evaluation.determination-label'),
        description: this.translate.instant('client-evaluation.determination-info')
    },
    clientSelector: {
        label: this.translate.instant('common.organization'),
        id: 'client-evaluation',
        from: 'client-evaluation',
        required: true,
        withLabel: true
    },
    legalForm: {
        label: this.translate.instant('new-client.client-legalform'),
        id: 'legalform-client-evaluation',
        required: true
    },
    rolesAssignationInfo: {
        type: 'no-margin-height-135',
        fieldTypeId: 'rich',
        isHighlight: true,
        label: this.translate.instant('client-evaluation.mandatory-assignment'),
        description: this.translate.instant('client-evaluation.mandatory-assignment-info')
    },
    steps: [
        {
            name: this.translate.instant('common.organization'),
            noEditable:false
        },{
            name: this.translate.instant('new-evaluation.role-assignment'),
            type: 'roles'
        }
    ],
    legalFormsList: []
  }

  constructor(private router: Router,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private rolesUtilsService: RolesUtilsService) { }

  ngOnInit(): void {
    this.loader.openLoader('new-client-evaluation');
    this.checkProductRoles();
    if(this.clientEvaluation.showLegalForm){
        this.getLegalForms();
    }
  }

  checkProductRoles(){
    this.apiService.get('productroles/product/' + this.clientEvaluation.productId+'/has-any')
      .then((data:any)=>{
          if(data.hasAny){
              this.clientEvaluation.hasRoles = true;
          }
      }, (error:any)=>{})
  }

  getLegalForms() {
    this.apiService.get('clients/legalforms')
      .then((data:any)=>{
        this.clientEvaluation.legalFormsList = this.commonUtilsService.editList(data, 'legalFormId', 'legalForm');
      }, (error:any)=>{})
  }

  goBack(){
    this.clientEvaluation.stepState--;
  }

  closeAdvice(type:any){
    this.clientEvaluation[type] = false;
  }

  clientSelected(newValue: any){
      this.clientEvaluation.client.existingEvaluation = newValue.existingEvaluation;
      this.clientEvaluation.showWarningMessage = false;
      this.clientEvaluation.legalForm.noEditable = false;
      this.clientEvaluation.client.legalFormId = newValue.legalFormId;
      this.clientEvaluation.client.clientName = newValue.name;
      if(newValue.legalFormId){
          this.clientEvaluation.legalForm.noEditable = this.clientEvaluation.client.existingEvaluation;
          this.clientEvaluation.legalForm.error = false;
      }
      if(newValue.clientId){
          this.clientEvaluation.clientSelector.error = false;
      }
      if(this.clientEvaluation.client.existingEvaluation){
          this.clientEvaluation.showWarningMessage = true;
      }
      this.itemSelected('clientId', newValue.clientId);
  }

  itemSelected(type: any, newValue: any){
    this.clientEvaluation.client[type] = newValue;
  }

  changeView(){
    this.router.navigate(['clients-tracking']);
  }

  receiveContacts(contacts: any, role: any){
      role.selectContacts = contacts;
  }

  validateOrganizationConfiguration(){
    if(this.validateFields()){
        if(this.clientEvaluation.hasRoles){
            if(!this.clientEvaluation.roles){
              this.rolesUtilsService.getProductRoles(this.clientEvaluation.productId, this.clientEvaluation.client.clientId)
                .then((data:any)=>{
                  this.clientEvaluation.roles = data;
                }, (error:any)=>{})
            }
            this.clientEvaluation.stepState++;
        }else{
            this.createInstance();
        }
    }
  }

  validateFields(){
    let errors = false;
    if(this.clientEvaluation.showLegalForm && !this.clientEvaluation.client.legalFormId){
        errors = true;
        this.clientEvaluation.legalForm.error = true;
    }
    if(!this.clientEvaluation.client.clientId || this.clientEvaluation.client.existingEvaluation){
        errors = true;
        this.clientEvaluation.clientSelector.error = true;
    }
    return !errors;
  }

  createInstance(){
    this.loader.openLoader('open-new-concept-window');
    let legalFormId = this.clientEvaluation.legalForm.noEditable || !this.clientEvaluation.showLegalForm?0:this.clientEvaluation.client.legalFormId;
    this.apiService.add('blanqueo/newclientglobalinstance/'+this.clientEvaluation.client.clientId+'/'+ legalFormId)
      .then((data:any)=>{
        localStorage.setItem('productId', this.clientEvaluation.productId);
        this.router.navigate(['client', data.clientId]);
      }, (error:any)=>{
        this.loader.closeError();
        this.errorModal(this.translate.instant('client-evaluation.error-create-client'));
      })
  }

  errorModal(text:any){
    this.modalService.adviceModal(text, 'error:accept:warning');
  }

  createClientRolesEvaluation(){
    if(this.checkValidateRoles()){
      let text = this.translate.instant('new-evaluation.question-create-with-role').replace('{clientName}', this.clientEvaluation.client.clientName);
      this.modalService.adviceModal(text, 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('open-new-concept-window');
            let roles = this.rolesUtilsService.getRolesConfigurated(this.clientEvaluation.roles);
            this.rolesUtilsService.saveRoles(roles, this.clientEvaluation.productId, this.clientEvaluation.client.clientId)
              .then((data:any)=>{
                this.createInstance();
              }, (error:any)=>{
                this.loader.closeError();
                this.errorModal(this.translate.instant('dashboard.error-assign-roles'));
              })
          }else{
            this.resetRoles(true);
          }
      })
    }
  }

  checkValidateRoles(){
    let validate = true;
    this.clientEvaluation.roles.forEach((role: any) => {
      role.error = false;
      if(!role.selectContacts || (role.selectContacts && !role.selectContacts.length)){
          role.error = true;
          validate = false;
      }
    });
    return validate;
  }

  resetRoles(resetRoles: any){
    this.clientEvaluation.roles.forEach((role: any) => {
      delete role.selectContacts;
      role.selectContacts = cloneDeep(role.preAssignedUsers);
      if(resetRoles){
        this.broadcastService.broadcast('resetFieldRole', {field:role});
      }
    });
  }

}
