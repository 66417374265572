<div class="field-type-rich-text__container">
  <div class="common__input-block field-type-rich-text__rich-block"
        [ngClass]="{'field-type-rich-text__rich-block--ishighlight': fieldTypeFormu.field.isHighlight}"
      [ngStyle]="{'background-color': fieldTypeFormu.field.backgroundColorHtml}">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeFormu.field.error}">
                  {{fieldTypeFormu.titleField}}
          </span>
          <i class="lf-icon-bookmarks-active field-type-rich-text__rich-text-icon"
              [ngShow]="fieldTypeFormu.field.evaluatedValue?.nvarcharValue"></i>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeFormu.field.relatedInfo.length && !fieldTypeFormu.field.poll && !fieldTypeFormu.field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(fieldTypeFormu.field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
            <div class="common__info-icon-block common__info-icon-block--in-report"
                *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
                <tooltip-in-report [inReport]="fieldTypeFormu.inReport"></tooltip-in-report>
            </div>
      </div>
      <div class="field-type-rich-text__rich-text field-type-rich-text__rich-text--ishighlight">
        {{fieldTypeFormu.field.evaluatedValue?.nvarcharValue}}
      </div>
  </div>
</div>
