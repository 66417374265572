import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit } from '@angular/core';
import { ClientsManagement } from '@shared/factories/clients-management/clients-management.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'field-type-clients-selector',
  templateUrl: './field-type-clients-selector.component.html'
})
export class FieldTypeClientsSelectorComponent implements OnInit, OnChanges {
  skin: string = this.globalCfg.skin;
  selectedClient: any = null;
  clientList: any = [];
  allClients: any = [];
  clientProductList: any = [];
  fieldTypeClientsSelector: any ={
    byword: ['name'],
    finishLoader: false,
    fieldTypeClientsSelector: false,
    id: this.commonUtilsService.createId(),
    license: true,
    preSelectItem:'',
    searchClient: '',
    field: {},
    filterList: [],
    alphabetList: [],
    selectedLetter: {
      results:[]
    }
  }
  constructor(private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private clientsManagement: ClientsManagement,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory) { }

  @Input() preSelectItem:any = '';
  @Input() field:any = null;
  @Input() productId:any = null;
  @Input() chargedList:any;

  @Output() itemSelected = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeClientsSelector.field = this.field;
    this.field.from === 'client-evaluation'? this.getProductClients() : this.getClients();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['preSelectItem']){
      if(!this.preSelectItem && this.selectedClient){
        this.reset();
      }
    }
  }

  configureClient(){
    this.selectedClient = this.clientsManagement.getClientId();
    for(let i = 0; i < this.allClients.length; i++){
        if(this.selectedClient === this.allClients[i].clientId){
            this.selectClient(this.allClients[i])
            break;
        }
    }
    this.fieldTypeClientsSelector.noOrganizations = !(this.allClients.length);
    if(!this.preSelectItem){
      this.reset();
    }
    this.closeLoaders();
  }

  getProductClients(){
    this.apiService.get(this.productId + '/clients')
      .then((data: any)=> {
        this.clientProductList = data;
        this.getClients();
      }, (errorData:any)=>{
      });
  }

  getClients(more: boolean= false){
    let urlExpand:string = more? 'skip='+ this.fieldTypeClientsSelector.alphabetList[0].skip+'&':'';
    this.apiService.get('clients/root/true/manage/product/' + this.productId, '?recents=true&'+urlExpand+'take=100')
      .then((data: any)=> {
        if(more){
                this.fieldTypeClientsSelector.alphabetList[0].results = this.fieldTypeClientsSelector.alphabetList[0].results.concat(data);
                this.fieldTypeClientsSelector.alphabetList[0].skip+= 100
                this.chargedList? this.filtredClientsList(this.fieldTypeClientsSelector.alphabetList[0].results) : this.configureClientsList(this.fieldTypeClientsSelector.alphabetList[0].results);
            }else{
                this.getAlphabetList(data);
            }
      }, (errorData:any)=>{
      });
  }

  getAlphabetList(clients:any){
    this.apiService.get('clients/root/true/manage/product/'+this.productId+'/initials')
    .then((data:any)=>{
        let count = 0;
        for(let i = 0; i < data.length; i++){
            data[i].skip = 0;
            data[i].results = [];
            count += data[i].itemsCount,
            data[i].text = data[i].initial
        }
        data.splice(0, 0, {
            initial: 'recent',
            hasItems: true,
            results: clients,
            skip: 100,
            selected: true,
            itemsCount: count,
            text: this.translate.instant('common.recents')
        })
        this.fieldTypeClientsSelector.selectedLetter = data[0]
        this.fieldTypeClientsSelector.alphabetList = data;
        this.chargedList? this.filtredClientsList(clients) : this.configureClientsList(clients);
        if(this.clientsManagement.getClientId()){
            this.configureClient();
        }
    }, (errorData:any)=>{})
}


  filtredClientsList(data: any){
    let clientList = [];
    for(let i=0; i < data.length; i ++){
        let client = data[i];
        for(let j=0; j < this.chargedList.length; j ++){
            let chargedClient = this.chargedList[j];
            if(client.clientId === chargedClient.clientId){
                if(this.chargedList.length === 1){
                    client.selected = true;
                }
                clientList.push(client);
                break;
            }
        }
    }
    this.configureClientsList(clientList);
  }

  configureClientsList(data: any){
    this.allClients = [];
    this.clientList = [];
    let existFilialClientId: any;
    if(this.fieldTypeClientsSelector.filteredBySearch){
      existFilialClientId = data.filter((client:any)=> this.fieldTypeClientsSelector.selectedClientId === client.clientId)[0];
    }
    for(let i=0; i < data.length; i ++){
      let client: any = data[i];
      client.selected = false;
      client.nameSelected = client.name;
      client.parentName = '';
      client.parent = {};
      client.children = [];
      client.disabled = this.productId === 'blanqueo' && client.clientStateId && client.clientStateId > 1?true:client.disabled;
      this.configureCorporative(client);
      if(this.fieldTypeClientsSelector.selectedClientId === client.clientId && this.fieldTypeClientsSelector.filteredBySearch){
          client.selected = true;
      }else if(this.fieldTypeClientsSelector.selectedParentClientId === client.clientId && !existFilialClientId){
          client.selected = true;
          if (this.fieldTypeClientsSelector.selectedNameFilial) {
              client.nameSelected = this.fieldTypeClientsSelector.selectedNameFilial;
              client.parentName = client.name;
          }
      }
      if(client.hasSubsidiaries){
        this.getFilials(client, i === data.length-1)
      }else if(i === data.length - 1){
        this.saveClient(client)
        this.setWhenLast()
      }else{
        this.saveClient(client)
      }
    }
    if(!data.length){
      this.closeLoaders();
    }
    this.fieldTypeClientsSelector.noOrganizations = !(data.length);
  }

  getFilials(client:any, last:boolean){
    this.apiService.get('clients/affiliatedcompaniestree', client.clientId)
      .then((data:any)=>{
        client.children = data[0].children;
        this.addFilials(client, data[0].children);
        this.saveClient(client)
        if(last){
            this.setWhenLast()
        }
      })
  }

  saveClient(client:any){
    this.allClients.push(cloneDeep(client));
    this.clientList.push(cloneDeep(client));
  }

  setWhenLast(){
    if(this.field.from === 'client-evaluation'){
        this.configureProductList(this.clientList);
        this.configureProductList(this.allClients);
    }
    this.closeLoaders();
  }

  addFilials(client:any, childrenList:any){
    if(this.field.from === 'client-evaluation'){
      this.configureProductList(childrenList);
    }
    childrenList.forEach((children:any) => {
      children.affiliated = true;
      children.nameSelected = children.name;
      children.parent = client;
      children.disabled = this.productId === 'blanqueo' && children.clientStateId && children.clientStateId > 1?true:children.disabled;
      this.configureCorporative(children);
      if(children.children.length){
        children.parentName = '';
        this.addFilials(client, children.children)
      }else{
        children.parentName = client.name;
      }
      this.allClients.push(cloneDeep(children));
    });
  }

  configureCorporative(client:any){
    if(client.logo && client.logo !== null && client.logo !==''){
      client.logo = client.logo.indexOf('base64') === -1?"data:image/png;base64," + client.logo : client.logo;
    }else{
      client.initials = this.getInitials(client.name);
    }
  }

  getInitials(clientName: any){
    let initials = clientName.split(" ");
    if(initials.length===1){
        return initials[0].substr(0,2);
    }else{
        return initials[0].substr(0,1) + initials[1].substr(0,1);
    }
  }

  configureProductList(list: any){
    for(let i = 0; i < this.clientProductList.length; i++){
        let clientProduct = this.clientProductList[i];
        for(let j = 0; j < list.length; j++){
            let client = list[j];
            if(clientProduct.clientId === client.clientId){
                // client.disabled = true;
                client.existingEvaluation = true;
                break;
            }
        }
    }
  }

  closeLoaders(){
    this.fieldTypeClientsSelector.filterList = this.clientList;
    this.fieldTypeClientsSelector.finishLoader = true;
    this.loader.closeLoader(['new-implantation-from-dashboard','front-login-login','new-client-evaluation']);
  }

  changeList(event: any){
    if(event.keyCode === 13){
      this.fieldTypeClientsSelector.filterList = [];
      this.fieldTypeClientsSelector.findOrganizations = true;
      this.fieldTypeClientsSelector.errorInSearch = false;
      this.fieldTypeClientsSelector.searchClient = this.fieldTypeClientsSelector.searchClient.trim();
      if(this.fieldTypeClientsSelector.searchClient.length >= 3){
          this.fieldTypeClientsSelector.selectedLetter = {
              skip : 0,
              results:[],
          }
          this.fieldTypeClientsSelector.filteredBySearch = true;
          this.getLetterMoreClients(this.fieldTypeClientsSelector.selectedLetter);
      }else{
        this.fieldTypeClientsSelector.errorInSearch = true;
      }
    }
  }

  deleteSearch(){
    if(this.fieldTypeClientsSelector.findOrganizations){
        this.fieldTypeClientsSelector.filteredBySearch = false;
        this.fieldTypeClientsSelector.searchClient = '';
        this.fieldTypeClientsSelector.findOrganizations = false;
        this.fieldTypeClientsSelector.errorInSearch = false;
        for(let i = 0; i < this.fieldTypeClientsSelector.alphabetList.length; i++){
            if(this.fieldTypeClientsSelector.alphabetList[i].selected){
                this.chooseLetter(this.fieldTypeClientsSelector.alphabetList[i]);
                break;
            }
        }
    }
  }

  selectClient(client: any) {
    if(client.selected){
        for(let i=0; i < this.allClients.length; i++){
            this.allClients[i].selected=false;
        }
        for(let i = 0; i < this.clientList.length; i++){
            this.clientList[i].selected=false;
            this.clientList[i].nameSelected = this.clientList[i].name;
            this.clientList[i].parentName = '';
        }
        this.resetSelected(client.selected);
    } else {
      if(!client.disabled){
        this.getLicensePermissions(client);
      }
    }
  }

  selectChild(e: any, child: any){
      e.stopPropagation();
      if(!child.disabled){
        this.getLicensePermissions(child);
      }
  }

  getLicensePermissions(client: any){
    let clientId = client.clientId;
    this.fieldTypeClientsSelector.selectedNameFilial = '';
    if(client.parent.name){
        clientId = client.parent.clientId;
        this.fieldTypeClientsSelector.selectedNameFilial = client.name;
    }
    this.fieldTypeClientsSelector.selectedParentClientId = clientId;
    this.fieldTypeClientsSelector.selectedClientId = client.clientId;
    this.apiService.get('evaluations/allownew', this.productId + '/' + clientId)
      .then((data: any)=> {
        this.fieldTypeClientsSelector.license = data;
        this.fieldTypeClientsSelector.selectedByFilterSearch = false;
        if(this.fieldTypeClientsSelector.filteredBySearch){
            this.searchSelectedClient(client, client.clientId, true);
        }
        if(!this.fieldTypeClientsSelector.selectedByFilterSearch){
            this.searchSelectedClient(client, clientId, false);
        }

        for (let i = 0; i < this.allClients.length; i++) {
            if (this.allClients[i].clientId === client.clientId) {
                this.allClients[i].selected = true;
            } else {
                this.allClients[i].selected = false;
            }
        }

        if (this.fieldTypeClientsSelector.license) {
          this.sendClient(client);
        }
      }, (errorData:any)=>{
      });
  }

  searchSelectedClient(client: any, clientId: any, filteredBySearch: any){
    for (let i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].clientId === clientId) {
          this.clientList[i].selected = true;
          this.clientList[i].nameSelected = client.name;
          if (client.parent.name && !filteredBySearch) {
              this.clientList[i].parentName = client.parent.name;
          }
          if(filteredBySearch){
              this.fieldTypeClientsSelector.selectedByFilterSearch = true;
          }
      } else {
          this.clientList[i].selected = false;
          this.clientList[i].nameSelected = this.clientList[i].name;
          this.clientList[i].parentName = '';
      }
    }
  }

  resetSelected(client: any) {
    this.sendClient(client);
    if(!client){
      this.fieldTypeClientsSelector.license = true;
    }
  }

  sendClient(client: any){
    this.selectedClient = client.clientId;
    this.itemSelected.emit({value: client});
    this.clientsManagement.setClientList(this.clientList);
    this.clientsManagement.setAllClients(this.allClients);
    this.clientsManagement.setClientId(this.selectedClient);
  }

  reset(){
    for(let i=0; i < this.clientList.length; i++){
        this.clientList[i].selected = false;
    }
    for(let i=0; i < this.allClients.length; i++){
        this.allClients[i].selected = false;
    }
    this.selectedClient = null;
    this.changeList({keyCode:13});
    this.clientsManagement.setClientList(this.clientList);
    this.clientsManagement.setAllClients(this.allClients);
    this.clientsManagement.setClientId(this.selectedClient);
  }

  openChild(e: any, child: any){
    e.stopPropagation();
    child.open = !child.open;
  }

  openChildOn(eventE: any){
    this.openChild(eventE.e, eventE.child);
  }

  selectChildOn(eventE: any){
    this.openChild(eventE.e, eventE.child);
  }

  chooseLetter(letter:any){
    if(letter.itemsCount !== 0){
        for(let i = 0; i < this.fieldTypeClientsSelector.alphabetList.length; i++){
          this.fieldTypeClientsSelector.alphabetList[i].selected = false;
        }
        this.fieldTypeClientsSelector.selectedLetter = letter;
        letter.selected = true;
        this.fieldTypeClientsSelector.filteredBySearch = false;
        if(!letter.results.length){
            this.getLetterMoreClients(letter);
        }else{
            this.chargedList? this.filtredClientsList(letter.results) : this.configureClientsList(letter.results);
        }
    }
  }

  getLetterMoreClients(letter:any){
    let urlExpand = this.fieldTypeClientsSelector.filteredBySearch?'nameLike='+this.fieldTypeClientsSelector.searchClient : 'startsWith='+letter.initial;
    let root = !this.fieldTypeClientsSelector.filteredBySearch;

    this.apiService.get('clients/root/'+root+'/manage/product/' + this.productId, '?'+urlExpand+'&skip='+letter.skip+'&take=100')
      .then((data:any)=> {
          letter.results = this.fieldTypeClientsSelector.filteredBySearch? letter.results.concat(data.clients) : letter.results.concat(data);
          letter.itemsCount = this.fieldTypeClientsSelector.filteredBySearch? data.totalCount : letter.itemsCount;
          letter.skip += 100;
          this.chargedList? this.filtredClientsList(letter.results) : this.configureClientsList(letter.results);
      });
  }

  getMore(){
    if(!this.fieldTypeClientsSelector.filteredBySearch && this.fieldTypeClientsSelector.selectedLetter.initial === 'recent'){
        this.getClients(true);
    }else{
        let selected = this.fieldTypeClientsSelector.filteredBySearch? this.fieldTypeClientsSelector.findOrganizationsList : this.fieldTypeClientsSelector.selectedLetter
        this.getLetterMoreClients(selected);
    }
  }

  findOrDelete(){
    !this.fieldTypeClientsSelector.findOrganizations? this.changeList({keyCode:13}) : this.deleteSearch();
  }

}
