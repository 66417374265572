import { FieldTypeListParentService } from './../utils/field-type-list-parent.service';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'field-type-list-tree',
  templateUrl: './field-type-list-tree.component.html'
})

export class FieldTypeListTreeComponent implements OnChanges {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = this.customLanding.isLanding();
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId'];

  constructor(private customLanding: CustomLandingFactory,
    private fieldTypeListParent: FieldTypeListParentService,
    private route: ActivatedRoute) {
  }

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
  }

  catchEvent(e:any){
    e.stopPropagation();
  }

  createNewValue(e:any){
    this.fieldTypeListParent.createNewValue(e, this)
  }

  insertNewValue(e:any){
    this.fieldTypeListParent.insertNewValue(e, this);
  }

  editListValueElement(e: any, listValue:any){
    this.fieldTypeListParent.editListValueElement(e, listValue, this);
  }

  deleteListValue(e:any, listValue:any){
   this.fieldTypeListParent.deleteListValue(e, listValue, this)
  }

  acceptNotDelete(e:any, listValue:any){
    e.stopPropagation();
    listValue.deleteError = false;
  }

  resetListValue(e:any){
    this.fieldTypeListParent.resetListValue(e, this);
  }

  selectItem(listValue: any, e:any){
    this.fieldTypeListParent.selectItem(listValue, this, e)
  }

  addOrDeleteSelectedValue(e:any, listValue:any){
    this.fieldTypeListParent.addOrDeleteSelectedValue(e, listValue, this);
  }

  showChildren(e:any, listValue:any){
    e.preventDefault();
    e.stopPropagation();
    listValue.show = !listValue.show;
  }

  changeList(){
    this.fieldTypeListParent.changeList(this);
  }

  resetSearch(e:any){
    this.fieldTypeListParent.resetSearch(e, this);
  }

  treeMultipleOn(event: any){
    switch(event.type){
      case 'showChildren':
        this.showChildren(event.e, event.listValue);
        break;
      case 'addOrDeleteSelectedValue':
        this.fieldTypeListParent.addOrDeleteSelectedValue(event.e, event.listValue, this);
        break;
      case 'editListValue':
        this.fieldTypeListParent.editListValueElement(event.e, event.listValue, this);
        break;
      case 'deleteListValue':
        this.fieldTypeListParent.deleteListValue(event.e, event.listValue, this);
        break;
      case 'aceptNotDelete':
        this.acceptNotDelete(event.e, event.listValue);
        break;
    }
  }
}
