<div class="field-type-consult-lists">
  <div class="common__input-block common__input-block--fixed"
      [ngClass]="{'common__show-alert--fixed': field.error,
          'common__input-no-editable':field.noEditable}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                      'common__input-text--no-editable':field.noEditable}">{{field.label}}</span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="field.references.length && !field.noEditable">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-consult-lists__container">
          <p class="field-type-consult-lists__text-description"
            [innerHTML]="field.description"></p>
          <div class="field-type-consult-lists__block-button">
              <button class="common__button-border-none"
                  [ngClass]="{'common__button-border-none--error': field.error}"
                  ng-disabled="field.noEditable"
                  (click)="generateReportPdf()">
                  <i class="lf-icon-simulator-rrhh common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'field-type-consult-lists.consult-list'">_Consultar listas</span>
              </button>
          </div>
          <div [class]="'field-type-consult-lists__element-list-document field-type-consult-lists__element-list-document--' + document.color"
              *ngFor="let document of field.evaluatedValue.documents | afOrderBy:'documentObjectId':true | afLimitTo:1"
              [ngShow]="field.evaluatedValue.documents.length">
              <div class="field-type-consult-lists__table-cell-document">
                  <span class="field-type-consult-lists__element-list-title">
                      <span [afTranslate]="'field-type-consult-lists.report'">_Informe</span>:
                  </span>
                  <span class="field-type-consult-lists__element-list-filename">{{document.name}}</span>
              </div>
              <div class="field-type-consult-lists__table-cell-options"
                  [ngHide]="field.noEditable">
                  <button class="lf-icon-download field-type-consult-lists__icon-list field-type-consult-lists__icon-list--first"
                      matTooltip="{{'expanded-menu.download' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="downloadDocument(document)">
                  </button>
                  <button class="lf-icon-trash field-type-consult-lists__icon-list"
                      matTooltip="{{'common.remove' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="removeDocument(document)">
                  </button>
              </div>
          </div>
      </div>
      <div class="field-item__blocker-box"
          *ngIf="field.error && field.blocker">
          <div class="field-item__blocker-triangle"></div>
          <i class="lf-icon-warning field-item__blocker-icon"></i>
          <p class="field-item__blocker-text" [afTranslate]="'field-items.blocker-field'">campo bloqueante</p>
      </div>
  </div>
</div>
