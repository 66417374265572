import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { keyBy } from 'lodash';

@Component({
  selector: 'visibility',
  templateUrl: './visibility.component.html'
})
export class VisibilityComponent implements OnChanges {
  skin: string = this.globalCfg.skin;

  visibility: any = {
    pickerVisible: false,
    selectedItems: {},
    selectedElements: [],
    selectedItemsCount: 0,
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory) { }

  @Input() items: any = null;
  @Input() selectedProperties: any = null;
  @Input() selectedListValues: any = null;
  @Input() selectedFields: any = null;
  @Input() orCondition: boolean = false;
  @Input() readonly: any = null;
  @Input() itemId: any = null;
  @Input() concept: any = null;
  @Input() type: string = '';

  @Output() visibilityOpen = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void{
    if(changes['concept'] && changes['concept'].currentValue){
      this.visibility.noEditable = changes['concept'].currentValue.disabled;
    }
    if(changes['selectedProperties'] && changes['selectedProperties'].currentValue){
      this.visibility.selectedProperties = changes['selectedProperties'].currentValue;
    }
    if(changes['selectedListValues'] && changes['selectedListValues'].currentValue){
      this.visibility.selectedListValues = changes['selectedListValues'].currentValue;
    }
    if(changes['selectedFields'] && changes['selectedFields'].currentValue){
      this.visibility.selectedFields = changes['selectedFields'].currentValue;
    }
    if(this.items && this.selectedProperties && this.selectedListValues && this.selectedFields){
      this.fillSelectedItems();
    }
  }

  fillSelectedItems() {
    let that: any = this.visibility;
    if(this.items.length){
      that.selectedProperties.forEach((prop:any)=>{
        this.addItem(prop, 'p', 'propertyValueId');
      })
      that.selectedItemsCount += that.selectedProperties.length;

      that.selectedListValues.forEach((listValue:any)=>{
        this.addItem(listValue, 'l', 'listValueId');
      })
      that.selectedItemsCount += that.selectedListValues.length;

      that.selectedFields.forEach((field:any)=>{
        let prefix:any = this.findPrefix(field.checkFieldId)
        this.addItem(field, prefix, 'checkFieldId');
      })
      that.selectedItemsCount += that.selectedFields.length;

    }
  }

  addItem(selectedItem:any, prefix:any, idField:any) {
      let id:any = selectedItem[idField];
      let key:string = prefix + '-' + id;
      if (prefix === 'f' && id === null) {
          prefix = key = 'w';
      }
      let item:any = keyBy(this.items, 'key')[key]
      if(item){
          if (prefix === 'f' || prefix === 'w' || prefix==='le') {
              let condition = {
                  operator1: selectedItem.operator1,
                  value1: selectedItem.value1,
                  operator2: selectedItem.operator2,
                  value2: selectedItem.value2
              };
              item.condition = condition;
              item.valueText = prefix==='le'? condition.operator1 +'(vacio)' : condition.operator1 + this.getValue(item, condition.value1);
              if (condition.operator2 && condition.value2) {
                  item.valueText += ' ' + this.translate.instant('common.and') + ' ' + condition.operator2 + this.getValue(item, condition.value2);
              }
          }
          this.visibility.selectedElements.push({
              id: id,
              text: item.valueText,
          })
          this.visibility.selectedItems[key] = item.valueText;
      }
  }

  findPrefix(checkFieldId:any){
    for(let i:number = 0; i < this.items.length; i++){
      let item: any = this.items[i];
      if('le-'+checkFieldId === item.key){
        return 'le'
      }
    }
    return 'f';
  }

  getValue(item:any, value:any) {
      if (item.dataType === 'date') {
          value = new Date(value).toLocaleDateString();
      }
      return value;
  }

  showPicker() {
      this.visibility.pickerVisible = true;
      this.visibilityOpen.emit({openBlock:true, visibilityOr:this.orCondition});
  }

  closePicker(e:any) {
      let selectedItems:any = e.selectedItems
      let that:any = this.visibility;
      that.pickerVisible = false;
      this.orCondition = e.orCondition;
      if (selectedItems) {
        that.selectedElements = [];
        that.selectedItems = selectedItems;
        that.selectedItemsCount = 0;
        if (that.selectedItems) {
          that.selectedProperties? that.selectedProperties.length = 0 : that.selectedProperties = [];
          that.selectedListValues? that.selectedListValues.length = 0 : that.selectedListValues = [];
          that.selectedFields? that.selectedFields.length = 0 : that.selectedFields = [];
          for (let k in that.selectedItems) {
            if (that.selectedItems.hasOwnProperty(k)) {
                let keyTokens:any = k.split('-');
                let selectedItem:any = that.selectedItems[k];
                if (keyTokens[0] === 'p') {
                    that.selectedProperties.push({ propertyValueId: keyTokens[1] });
                } else if (keyTokens[0] === 'f' || keyTokens[0] === 'w' || keyTokens[0] === 'le') {
                    let item:any = keyBy(this.items, 'key')[k];
                    let condition:any = item.condition;
                    that.selectedFields.push({
                        fieldVisibleValueId: item.fieldVisibleValueId,
                        checkFieldId: keyTokens[0] === 'f' || keyTokens[0] === 'le'? keyTokens[1] : null,
                        operator1: condition.operator1,
                        value1: condition.value1,
                        operator2: condition.operator2,
                        value2: condition.value2
                    });

                } else {
                    that.selectedListValues.push({ listValueId: keyTokens[1] });
                }
                that.selectedElements.push({
                    id: keyTokens[1],
                    text: selectedItem,
                })
                that.selectedItemsCount++;
            }
          }
        }
      }
      this.visibilityOpen.emit({openBlock:false, visibilityOr:this.orCondition});
  }

  onKeyPress(e:any) {
      if (e.keyCode === 13) {
          this.showPicker();
      }
  }

  deleteSelected(item:any){
    let that: any = this.visibility;
    for(let i = 0; i < that.selectedElements.length; i++){
        if(item.id === that.selectedElements[i].id){
            that.selectedElements.splice(i, 1);
        }
    }
    for (let [value] of Object.entries(that.selectedItems)) {
      if(value.indexOf(item.id) !== -1){
        let itemNew:any = value.split('-');
        this.deletefrom(itemNew[0], itemNew[1]);
        delete that.selectedItems[value];
      }
    }
    that.selectedItemsCount--;
    this.visibilityOpen.emit({openBlock:false, visibilityOr:this.orCondition});
  }

  deletefrom(type:any, id:any){
    let that: any = this.visibility;
    let list:any = null
    let idProperty:string ='';
    if(type === 'p'){
        list = that.selectedProperties
        idProperty ='propertyValueId';
    }else if(type === 'l'){
        list = that.selectedListValues
        idProperty ='listValueId';
    }else{
        list = that.selectedFields;
        idProperty ='checkFieldId';
    }
    for(let i = 0; i < list.length; i++){
        if(id === list[i][idProperty]){
            list.splice(i, 0);
        }
    }
  }
}

