 <div [ngClass]="{'field-item__global-block': !fieldTypeBool.field.poll}">
    <div class="field-item__field-type-bool"
        [ngClass]="{'field-item__field-type-bool--is-highlight': fieldTypeBool.field.isHighlight }">
      <div class="field-type-bool__input-text-bool">
          <div class="field-item__text-label"
              [ngClass]="{'field-item__text-label--is-highlight': fieldTypeBool.field.isHighlight}">
              <span [ngClass]="{'common__input-content-alert':fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}">
                {{fieldTypeBool.titleField}}
              </span>
              <span class="common__text-required common__text-required--bool"
                  [ngShow]="fieldTypeBool.field.required">*</span>
              <div class="common__info-icon-block common__info-icon-block--visible"
                  [ngHide]="fieldTypeBool.field.noEditable || !fieldTypeBool.field.description">
                  <tooltip-info [description]="fieldTypeBool.field.description"
                      [field]="fieldTypeBool.field"></tooltip-info>
              </div>
              <div class="common__tooltip-law common__tooltip-law-show"
                  [ngShow]="fieldTypeBool.field.references && fieldTypeBool.field.references.length && !fieldTypeBool.field.noEditable">
                  <tooltip-law [references]="fieldTypeBool.field.references"
                      [field]="fieldTypeBool.field">
                  </tooltip-law>
              </div>
              <div class="common__tooltip-law-show">
                  <i class="lf-icon-search-text field-item__icon-related-info"
                      (click)="openModalRelatedInfo(fieldTypeBool.field.relatedInfo, $event)"
                      [ngShow]="fieldTypeBool.field.relatedInfo && fieldTypeBool.field.relatedInfo.length  && !fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable"
                      matTooltip="{{'common.related-info' | afTranslate }}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </i>
              </div>
              <div class="common__info-icon-block common__info-icon-block--in-report"
                *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
                <tooltip-in-report [inReport]="fieldTypeBool.inReport"></tooltip-in-report>
              </div>
          </div>
      </div>
      <div class="field-type-bool__field-bool-answer"
          [ngClass]="{'field-type-bool__field-bool-answer--color': fieldTypeBool.field.withColor}">
          <div class="field-item__options-block field-item__bool-box "
              [ngClass]="{'field-item__bool-box--alert': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null,
                  'field-item__bool-box--no-editable':fieldTypeBool.field.noEditable}"
              [ngShow]="!fieldTypeBool.field.withColor">
              <div class="field-item__answer-block">
                  <div class="field-item__button-bool field-item__button-bool--left"
                      (click)="selectOption(true)"
                      [ngClass]="{'field-item__button-bool--selected':fieldTypeBool.preSelectItem === true && !fieldTypeBool.field.noEditable,
                          'field-item__button-bool--selected-no-editable':fieldTypeBool.preSelectItem === true && fieldTypeBool.field.noEditable,
                          'field-item__button-bool--no-editable':fieldTypeBool.field.noEditable,
                          'field-item__button-bool--alert': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}">
                      <p class="field-item__bool-text"
                          [afTranslate]="'common.yes'"> Sí </p>
                  </div>
                  <div class="field-item__button-bool field-item__button-bool--right"
                      (click)="selectOption(false)"
                      [ngClass]="{'field-item__button-bool--selected':fieldTypeBool.preSelectItem === false && !fieldTypeBool.field.noEditable,
                          'field-item__button-bool--selected-no-editable':fieldTypeBool.preSelectItem === false && fieldTypeBool.field.noEditable,
                          'field-item__button-bool--no-editable':fieldTypeBool.field.noEditable,
                          'field-item__button-bool--alert': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}">
                      <p class="field-item__bool-text"
                          [afTranslate]="'common.no'"> No </p>
                  </div>
              </div>
          </div>

          <div class="field-item__answer-block"
              [ngHide]="!fieldTypeBool.field.withColor">
              <div class="common__pill-button"
                  [ngClass]="{'common__pill-button--green common__pill-button--green':fieldTypeBool.field.withColor === 1,
                      'common__pill-button--red':fieldTypeBool.field.withColor === 2,
                      'common__pill-button--selected': fieldTypeBool.preSelectItem,
                      'common__pill-button--disable' : fieldTypeBool.field.noEditable,
                      'common__pill-button--disable-select' : fieldTypeBool.field.noEditable && fieldTypeBool.preSelectItem}"
                  (click)="selectOption(true)">
                  <p [afTranslate]="'common.yes'"> Sí </p>
              </div>
              <div class="common__pill-button common__pill-button-right"
                  [ngClass]="{'common__pill-button--green common__pill-button--green':fieldTypeBool.field.withColor === 2,
                      'common__pill-button--red':fieldTypeBool.field.withColor === 1,
                      'common__pill-button--selected': fieldTypeBool.preSelectItem===false,
                      'common__pill-button--disable' : fieldTypeBool.field.noEditable,
                      'common__pill-button--disable-select' : fieldTypeBool.field.noEditable && !fieldTypeBool.preSelectItem}"
                  (click)="selectOption(false)">
                  <p [afTranslate]="'common.no'"> No </p>
              </div>
          </div>
        <div class="field-item__blocker-box"
          *ngIf="fieldTypeBool.field.error && fieldTypeBool.field.blocker">
          <div class="field-item__blocker-triangle"></div>
          <i class="lf-icon-warning field-item__blocker-icon"></i>
          <p class="field-item__blocker-text"
            [afTranslate]="'field-items.blocker-field'">campo bloqueante
          </p>
        </div>
      </div>
    </div>
</div>
