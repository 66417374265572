
import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { FieldTypeListParentService } from './../utils/field-type-list-parent.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'sequential-list-simple',
  templateUrl: './sequential-list-simple.component.html'
})

export class SequentialListSimpleComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = true;
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']

  custom: any = this.customLanding.getCustom();
  constructor( private fieldTypeListParent: FieldTypeListParentService,
    private customLanding: CustomLandingFactory,
    private route: ActivatedRoute) {}

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();
  @ViewChild(NgbDropdown)
  dropdowns: NgbDropdown;

  ngOnInit(): void {
    this.fieldTypeListParent.openWatchers(this)
  }

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
  }

  catchEvent(e:any){
    e.stopPropagation();
  }

  editListValueElement(e: any, listValue:any){
    this.fieldTypeListParent.editListValueElement(e, listValue, this);
  }

  deleteListValue(e:any, listValue:any){
   this.fieldTypeListParent.deleteListValue(e, listValue, this)
  }

  acceptNotDelete(e:any, listValue:any){
    e.stopPropagation();
    listValue.deleteError = false;
  }

  selectItem(listValue: any, e:any){
    this.fieldTypeListParent.selectItem(listValue, this, e)
  }

  showChildren(e:any, listValue:any){
    e.preventDefault();
    e.stopPropagation();
    listValue.show = !listValue.show
  }

  changeList(){
    this.fieldTypeListParent.changeList(this);
  }

  resetSearch(e:any){
    this.fieldTypeListParent.resetSearch(e, this);
  }

  resetItem(){
    this.fieldTypeListParent.resetItem(this);
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
