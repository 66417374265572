<div class="up-document"
  [ngClass]="{'states-task-help__custom-container': isLanding}">
    <div class="common__modal-header-block" [ngShow]="!isLanding">
        <i class="lf-icon-process common__icon-header"></i>
        <span class="common__text-header"
          [afTranslate]="'states-task-help.task-states'"></span>
        <i class="lf-icon-close states-task-help__icon-close-modal"
          (click)="handleDismiss()"></i>
    </div>
    <div class="common__block-icon-close-modal-custom"
        [ngShow]="isLanding">
        <button class="lf-icon-close-round common__icon-close-modal-custom"
          (click)="handleDismiss()"></button>
    </div>
    <div class="common__modal-header-block common__modal-header-block--custom-margin"
        [ngShow]="isLanding"
        [style]="{'background-color':custom?.color, 'color':custom?.textColor}">
        <i class="lf-icon-process common__icon-header common__icon-header--custom"></i>
        <span class="common__text-header common__text-header--custom"
          [afTranslate]="'states-task-help.task-states'"></span>
    </div>
    <div class="states-task-help__block-image">
        <img class="states-task-help__image"
          [src]="statesTaskHelpModal.image"
          [alt]="'states-task-help.tasks-states-flow' | afTranslate">
    </div>
</div>
