import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';

@Component({
  selector: 'alert-concept',
  templateUrl: './alert-concept.component.html'
})
export class AlertConceptComponent implements OnInit {
  subscribers: any = {};
  skin: string = this.globalCfg.skin;
  from: any = this.route.snapshot.data['from'];
  currentComponent: string = this.route.snapshot.data['componentName'];
  userPermissions: any = this.permissions.getPermissions();
  alertConcept: any = {
    titleWordFilter: "",
    filterCreator: "",
    selectFilterElement: false,
    openFocusTitle: false,
    openFocusCreator: false,
    search: true,
    open: false,
    userLogin: this.context.user,
    filters: ['creator', 'source', 'alertDate', 'sourceName'],
    sourceFilter: {
      name: this.translate.instant('common.origin')
    },
    alertDateFilter: {
      name: this.translate.instant('alert-concept.alert-date')
    },
    titleFilter: {
      name: this.translate.instant('common.title'),
      width: '200px'
    },
    creatorFilter: {
      name: this.translate.instant('common.creator'),
      myOptionsTitle: 'alert-concept.my-alert'
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private filterColumnService: FilterColumnService,
    private loader: LoaderFactory,
    private permissions: UserPermisionsFactory,
    private modalService: ModalServiceService,
    private registerService: RegisterMixtapeService,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService) { }
  @Input() concept: any = null;
  @Input() evaluation: any = null;

  @Output() modifyConceptTab = new EventEmitter();

  ngOnInit(): void {
    this.alertConcept.concept = this.concept;
    this.getAlerts();
  }

  getAlerts(){
      let url:any = 'conceptobjects/alerts';
      let id:any = this.alertConcept.concept.conceptObjectId;
      if((this.currentComponent === 'conceptWindow' && this.from === 'client')
          || (this.currentComponent === 'concept' && this.concept.conceptNamedKey === 'Cliente' && this.evaluation && !this.evaluation.isFinished)){
          url = 'blanqueo/datealertsbyclient';
          id = this.concept.clientId;
      }
      this.apiService.get(url, id)
          .then((data:any) => {
            if((this.currentComponent === 'conceptWindow' && this.from === 'client')
              || (this.currentComponent === 'concept' && this.concept.conceptNamedKey === 'Cliente' && this.evaluation && !this.evaluation.isFinished)){
                  this.configureClientList(data);
            }else{
              this.alertConcept.concept.alertList = data;
              this.alertConcept.filterAlertList = this.alertConcept.concept.alertList;
              this.getFilters();
            }
          })
  }

  configureClientList(data:any){
    data.forEach((alert:any) => {
      if(alert.title === 'relationship-tracking'){
        alert.noPermissions = (this.alertConcept.concept.isClientRecurrent && this.skin === 'dark-blue' && alert.risk >= 3);// || !alert.active;//!alert.editable;
        alert.noEditable = this.alertConcept.concept.isClientRecurrent && this.skin === 'dark-blue';
        alert.title = this.skin === 'steel-blue'?this.translate.instant('common.tracking') + ' ' + alert.evaluationName:this.translate.instant('common.relationship-tracking');
      }
      alert.sourceName = alert.source === 'System'?this.translate.instant('alert-concept.system-automation'):alert.sourceName;
      alert.creator = alert.source === 'System'?this.translate.instant('common.system'):alert.creator;
    });
    this.alertConcept.concept.alertList = data;
    this.alertConcept.filterAlertList = this.alertConcept.concept.alertList;
    this.getFilters();
  }

  getFilters(){
    let that: any = this.alertConcept;
      for(let filter of that.filters){
          that[filter+'List'] = this.filterColumnService.extractList(filter, this.alertConcept.concept.alertList)
          if(filter === 'creator'){
              for(let filterList of that[filter+'List']){
                  if(filterList.name.trim().toLowerCase() === that.userLogin.fullName.trim().toLowerCase()){
                      that[filter+'Filter'].showMyOption = {
                          visible: true,
                          name: filterList.name,
                          selected: false
                      };
                      break;
                  }
              }
          }
          that[filter+'ListFilter'] = [];
      }
      this.loader.closeLoader(['charge-alerts-concept-list']);
  }

  orderBySearch(e:any, type:string){
      this.alertConcept[type+'WordFilter'] = e.newValue;
      this.filterAlerts(type);
  }

  selectTypeOperation(item:any, type:string){
      let listFilter:any = this.alertConcept[type+'ListFilter'];
      this.alertConcept[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      if(this.alertConcept[type+'Filter']){
          this.alertConcept[type+'Filter'].hasFilter = this.alertConcept[type+'ListFilter'].length;
      }
      this.filterAlerts();
  }

  filterAlerts(type=''){
      let searchFilter:any = this.alertConcept.titleWordFilter;
      let searchWord:any = 'title';
      if (type!=='') {
          searchFilter = this.alertConcept[type + 'WordFilter'];
          searchWord = type;
      }
      let filterList:any = this.filterColumnService.filterByWord(searchFilter, this.alertConcept.concept.alertList, searchWord);
      this.alertConcept.filters.forEach((filter:any) => {
        let filteredList:any = this.alertConcept[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
      this.alertConcept.filterAlertList  = filterList;
  }

  editAlert(alert:any){
      if(this.userPermissions === 'none' || alert.noEditable){
          this.viewAlert(alert);
      }else{
          let source:any = alert.source;
          if(source === 'Task'){
              source = 'Date';
          }
          let configuration:any = {
              from: 'alertConcept',
              type: source,
              alert: alert,
              concept: this.alertConcept.concept
          }
          this.modalService.addAlert(configuration).subscribe((result:any) => {
            if(result.result === 'ok'){
              this.loader.openLoader('alert-condept-edit-alert')
              this.sendAlert(source, alert, result);
            }
          })
      }
  }

  viewAlert(alert:any){
      this.modalService.alertDetail(alert.source, alert);
  }

  sendAlert(source:any, alert:any, result:any){
    this.registerEvent('edit alert', source);

      this.apiService.patch(source.toLowerCase() + 'alerts', alert.id, result.alert)
          .then((data:any)=>{
              this.changeAlertData(alert, result);
              if(result.share){
                  this.sendShare(source, alert, result.share);
              }else{
                  this.loader.closeLoader(['alert-condept-edit-alert']);
                  this.modalService.adviceModal(this.translate.instant('alert-concept.the-alert')+"'"+ alert.title+"'"+ this.translate.instant('alert-concept.alert-edit'), 'info:accept:check-round');
              }
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }
  registerEvent(type:string, source:any){
    let resgisterItem:any ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event': type,
      'Alert Type':source,
      'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.alertConcept.concept.title,
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem);
  }

  changeAlertData(alert:any, result:any) {
      alert.title = result.alert.title;
      alert.repeatDays = result.alert.repeatDays;
      alert.documentAlertTypeId = result.alert.documentAlertTypeId;
      alert.repeatOccurrences = result.alert.repeatOccurrences;
      alert.repeat = result.alert.repeat;
      alert.repeatType = result.alert.repeatType;
      alert.numDays = result.alert.numDays;
      alert.hour = result.alert.hour;
      alert.omitNotifyToCreator = result.alert.omitNotifyToCreator
      if (alert.source === 'Date') {
          alert.alertTypeId = result.alert.dateAlertTypeId;
          alert.dateAlertTypeId = result.alert.dateAlertTypeId;
      } else if (alert.source === 'Document') {
          alert.alertTypeId = result.alert.documentAlertTypeId;
      } else if (alert.source === 'Process') {
          alert.alertTypeId = result.alert.processAlertTypeId;
          alert.delayUnits = result.alert.delayUnits;
          alert.delayTimeUnit = result.alert.delayTimeUnit;
      }
  }

  sendShare(source:any, alert:any, share:any){
      this.apiService.add(source.toLowerCase() + 'alerts/sharealert/' + alert.id + '/omit-notify-creator/' + alert.omitNotifyToCreator, share)
          .then((data:any)=>{
              alert.notified = true;
              this.loader.closeLoader(['alert-condept-edit-alert']);
              this.modalService.adviceModal(this.translate.instant('alert-concept.the-alert') +"'"+ alert.title +"'"+ this.translate.instant('alert-concept.alert-edit'), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  deleteAlert(alert:any){
      this.modalService.adviceModal( this.translate.instant('alert-concept.question-delete-alert') +"'"+ alert.title+"'?", 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result === 'ok'){
              this.loader.openLoader('alert-concept-delete-alert');
              this.deleteDocumentAlert(alert);
          }else{
              this.loader.closeLoader('alert-concept-delete-alert');
          }
      })
  }

  deleteDocumentAlert(alert:any){
      let source:any = alert.source;
      if(source === 'Task'){
          source = 'Date';
      }
      this.registerEvent('delete alert', source.toLowerCase());
      this.apiService.delete(source.toLowerCase()+'alerts', alert.id)
          .then((data:any)=>{
              this.alertConcept.concept.alertList = this.commonUtilsService.deleteElement( alert, this.alertConcept.concept.alertList, 'id');
              this.filterAlerts();
              this.modifyConceptTab.emit();
              this.broadcastService.broadcast('getHeaderNotifications');
              this.loader.closeLoader('alert-concept-delete-alert');
              this.modalService.adviceModal(this.translate.instant('alert-concept.the-alert')+"'"+ alert.title +"'"+ this.translate.instant('alert-concept.alert-has-delete'), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('alert-concept.error-delete-alert'), 'error:accept:warning')
          })
  }
  preventClick(e:any){
      e.stopPropagation();
  }

  openFocus(type:any){
      if(type === 'Title'){
          this.alertConcept.openFocusCreator = false;
          this.alertConcept.openFocusTitle = true;
      }else if(type === 'Creator'){
          this.alertConcept.openFocusCreator = true;
          this.alertConcept.openFocusTitle = false;
      }
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id);
  }
}
