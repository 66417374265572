import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tree-list-multiple',
  templateUrl: './tree-list-multiple.component.html'
})
export class TreeListMultipleComponent implements OnChanges {
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']
  constructor(private route: ActivatedRoute){}
  treeListMultiple: any ={
    field: null,
    findDropdown: '',
    fieldToFind: '',
    filterList: []
  }

  @Input() field: any = null;
  @Input() findDropdown: any = '';
  @Input() fieldToFind: any = '';
  @Input() filterList: any = [];

  @Output() treeMultipleEmit = new EventEmitter();

  ngOnChanges(): void {
      this.treeListMultiple.field = this.field;
      this.treeListMultiple.findDropdown = this.findDropdown;
      this.treeListMultiple.fieldToFind = this.fieldToFind;
      this.treeListMultiple.filterList = this.filterList;
  }

  launchAction(listValue:any, e:any, type:string){
    let selectedItem={
      listValue: listValue,
      e: e,
      type: type
    }
    this.treeMultipleEmit.emit(selectedItem);
  }

  treeMultipleOn(event:any){
    let selectedItem={
      listValue: event.listValue,
      e: event.e,
      type: event.type
    }
    this.treeMultipleEmit.emit(selectedItem);
  }

}
