<div class="advice-message">
  <div [class]="'advice-message__box advice-message__box--'+type"
      [ngClass]="{'advice-message__box--no-margin': typeClass === 'no-margin',
          'advice-message__box--margin-bottom': typeClass === 'margin-bottom'}">
      <i [class]="'advice-message__icon-type lf-icon-'+icon"></i>
      <p [class]="'advice-message__text advice-message__text--'+type">{{text | afTranslate}}</p>
      <i class="lf-icon-close advice-message__icon-close"
          [ngShow]="skin !== 'landing-custom' && !hideIconClose"
          (click)="closeBox()"></i>
  </div>
</div>
