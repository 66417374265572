import { Component, Input, OnInit } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'pdf-header',
  templateUrl: './pdf-header.component.html'
})
export class PdfHeaderComponent implements OnInit {
  @Input() datePdf: any = null;
  @Input() pdfInfo: any = {};
  @Input() newDesign: boolean = false;

  pdfHeader: any = {
    logoHeader: this.commonUtilsService.configureLogoHeader()
  }
  constructor(private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory) { }

  ngOnInit(): void {
    this.pdfHeader.headerModifiedText = this.selectHeaderText();
  }

  selectHeaderText(){
    switch(this.pdfInfo?.typePdf){
      case 'report-consult-list':
        return this.translate.instant('all-documentation.consult-date');
      case 'communication-channel':
        return this.translate.instant('box-comunication-channel.message-system');
      default:
        return this.translate.instant('common.registration-date');
    }
  }

}
