import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Component({
  selector: 'associated-documentation',
  templateUrl: './associated-documentation.component.html'
})
export class AssociatedDocumentationComponent implements OnInit {

  skin: string = this.globalCfg.skin;
  expandMenu:any = ExpandMenu;
  associatedDocument: any = {
    showChangeAssociated: false,
    orderCreation: false,
    previewAssociated: false,
    desassociateDocument: false,
    disabledSave: false,
    searchingDocuments: false,
    order: 'name',
    nameState: '',
    statesChanged: [],
    documentationList: [],
    documentSelected:{},
    processSteps: {
        label: this.translate.instant('common.associate-to-process')
    },
    loaderImg: this.commonUtilsService.configureLoaderImg(),
    acceptButton: this.translate.instant('common.associate'),
    titleHeader: this.translate.instant('associated-documentation.associated-documentation'),
    iconHeader: "lf-icon-documentation",
    stateId: null
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal,
    private documentActions: DocumentActionsService) { }

  @Input() process: any = null;
  @Input() concept: any = null;
  @Input() listSelect: any = null;
  @Input() from: any = null;
  @Input() document: any = null;


  ngOnInit(): void {
    this.configureDocumentation();
  }

  configureDocumentation(){
    this.associatedDocument.listSelectShow = cloneDeep(this.listSelect);
    if(this.process?.stateId && this.from === 'process-modal'){
        this.associatedDocument.listSelectShow = this.commonUtilsService.deleteElement(this.process, this.associatedDocument.listSelectShow, 'stateId')
        this.associatedDocument.expandedMenu = this.expandMenu['menuAssociatedDocument'].menu;
        this.getDocumentOfState();
    }else if(this.from === 'documentation'){
        this.associatedDocument.titleHeader = this.translate.instant('associated-documentation.association-process');
        this.associatedDocument.acceptButton = this.translate.instant('common.save');
        this.associatedDocument.showChangeAssociated = true;
        this.associatedDocument.iconHeader = "lf-icon-process";
        this.associatedDocument.documentSelected = this.document;
        this.associatedDocument.disabledSave = true;
        if(this.process && this.process.stateId){
          this.associatedDocument.stateId = this.process.stateId;
          this.associatedDocument.previewAssociated = true;
        }
    }

  }

  getDocumentOfState(){
    this.associatedDocument.searchingDocuments = true;
    this.apiService.get('documentobjects/'+this.concept.conceptObjectId+'/state/'+this.process.stateId)
        .then((data:any)=>{
          this.associatedDocument.documentationList = data.documents;
            this.getListDocument(data);
        },(errorData:any)=>{
          this.associatedDocument.searchingDocuments = false;
        })
  }

  getListDocument(listDocument:any){
    for (let subFolder of listDocument.subFolders) {
        if(subFolder.documents.length){
          this.associatedDocument.documentationList = this.associatedDocument.documentationList.concat(subFolder.documents);
        }
        if(subFolder.subFolders.length){
            this.getListDocument(subFolder);
        }
    }
    this.associatedDocument.searchingDocuments = false;
  }



  launchAction(action:any, document:any){
    switch(action){
        case 'view':
            this.document = document;
            // this.associatedDocument.action = 'open';
            this.documentActions.doAction('open', document);
            break;
        case 'download':
            this.document = document;
            // this.associatedDocument.action = 'download';
            this.documentActions.doAction('download', document);
            break;
        case 'change':
            this.associatedDocument.documentSelected = document;
            this.associatedDocument.showChangeAssociated = true;
            break;
        case 'unlink':
            this.associatedDocument.documentSelected = document;
            this.loader.openLoader('associate-document-change');
            this.associateDocument('documentobjects/'+ document.documentObjectId + '/state/'+ 0);
            break;
        default:
            break;
    }
  }


  changeAssociate(){
    let stateId: any = this.associatedDocument.stateId && !this.associatedDocument.desassociateDocument? this.associatedDocument.stateId : 0
    this.loader.openLoader('associate-document-change')
    this.associateDocument('documentobjects/'+ this.associatedDocument.documentSelected.documentObjectId + '/state/'+ stateId);
}

  associateDocument(url:string){
    this.apiService.patch(url)
      .then((data:any)=>{
        this.loader.closeLoader(['associate-document-change']);
        if(this.from === 'process-modal'){
            let text = this.translate.instant('associated-documentation.message-change-associated');
            if(!this.associatedDocument.stateId){
                let textReplace = this.translate.instant('associated-documentation.desassociate-document').replace('{titleName}', this.associatedDocument.documentSelected.name);
                text = textReplace.replace('{titleStep}', this.process.name);
            }
            this.openInfoModal(text);
        }else{
            this.document.stateProcess = this.associatedDocument.nameState;
            this.document.state = {
                name: this.associatedDocument.nameState,
                stateId: this.associatedDocument.stateId
            }
            if(this.associatedDocument.desassociateDocument){
                this.document.stateProcess = '';
                this.document.state = null;
            }
            this.changeState(this.concept.documentation);
            this.handleAccept();
        }
    }, (errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('process.modal.error-change-document'), 'error:accept:warning');
    });
}

  openInfoModal(text:any){
    let that: any = this.associatedDocument;
    this.modalService.adviceModal(text, 'info:accept:check-round')
      .subscribe((result:any)=>{
        that.showChangeAssociated = false;
        this.changeState(this.concept.documentation);
        if(that.stateId && that.statesChanged.indexOf(that.stateId) === -1 ){
            that.statesChanged.push(that.stateId);
        }
        that.stateId = null;
        that.documentationList = this.commonUtilsService.deleteElement(that.documentSelected, that.documentationList, 'documentObjectId')
      });
  }

  changeState(list:any){
    for (let [index,document] of list.documents.entries()) {

        if(this.associatedDocument.documentSelected.documentObjectId === document.documentObjectId){
            if(this.associatedDocument.desassociateDocument){
              list.documents[index].stateProcess = "";
              list.documents[index].state = null;
            }else{
              list.documents[index].stateProcess = this.associatedDocument.nameState;
              list.documents[index].state = {
                    name: this.associatedDocument.nameState,
                    stateId: this.associatedDocument.stateId
                }
            }
            break;
        }
        if(list.subFolder && list.subFolder.length){
            this.changeState(list);
        }
    }
  }

  selectProcess(e:any){
    let newValue: any = e.newValue
    this.associatedDocument.stateId = newValue;
    if(newValue){
        for(let item of this.listSelect){
            if(item.stateId === newValue){
                this.associatedDocument.nameState = item.name;
                break;
            }else{
              this.associatedDocument.nameState = '';
            }
        }
        this.associatedDocument.processSteps.error = false;
    }else{
      this.associatedDocument.nameState = '';
    }

    this.associatedDocument.disabledSave = (this.from === 'documentation' && ((!this.associatedDocument.stateId) || (this.associatedDocument.previewAssociated && this.process.stateId === this.associatedDocument.stateId)))
}

  orderDate(type:any){
    if(this.associatedDocument.order === type){
        this.associatedDocument.orderCreation = !this.associatedDocument.orderCreation;
    }else{
        this.associatedDocument.order = type;
        this.associatedDocument.orderCreation = type === 'name'
    }
  }


  handleDismiss() {
    if(this.from === 'process-modal'){
        this.handleAccept();
    }else{
      this.activeModal.close({result:'cancel'});
    }
  }

  handleAccept() {
    this.activeModal.close({
        result : 'ok',
        statesChanged: this.associatedDocument.statesChanged,
        documents: this.associatedDocument.documentationList.length
    })

};

}

