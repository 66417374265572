<div class="customize-field-file">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [(ngModel)]="customizeFieldFile.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldFile.tinyMceOptions"
      [modelText]="customizeFieldFile.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldFile.contentReportInfo"
            [preSelectItem]="customizeFieldFile.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option [model]="customizeFieldFile.field.rename"
        [disabled]="customizeFieldFile.field.noEditable"
        [fieldText]="'customize.rename'"
        (changeOptionEmit)="changeOptionOn($event, 'rename')">
    </switch-option>
    <switch-option
        [model]="customizeFieldFile.field.required"
        [disabled]="customizeFieldFile.field.noEditable"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldText()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldText()"
            [afTranslate]="'common.save'">Guardar</button>
    </div>
</div>
