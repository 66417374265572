<div class="span__auto-ajust"
  [ngClass]="{'tooltip-law__block-icon': !isSequentialPoll}">
    <button class="tooltip-law__button" *ngFor="let icon of tooltipLaw.referencesList | distinctProperty:'type'"
      [ngbTooltip]="tooltipHtml">
        <i [class]="(icon.type | taskTypeIcons:'reference') + ' tooltip-law__icons'"
        [ngClass]="{'concept-tab__menu-law': visibility && !isSequentialPoll,
                    'tooltip-law__icons--button-field': type === 'custom-button-field',
                    'tooltip-law__icons--container': type === 'container',
                    'common__references-block tooltip-law__icons--sequential-poll': isSequentialPoll}"
        [ngShow]="nameReference"
        (click)="openModalLaw($event, icon.type)">
      </i>
      <ng-template #tooltipHtml><div [innerHTML]="showTooltipInfo(icon.type)"></div></ng-template>
    </button>
</div>