import { Component, OnInit, Input, Output, OnChanges, EventEmitter, OnDestroy} from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { BoxCommentsParentService } from '../utils/box-comments-parent.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'box-comments-custom',
  templateUrl: './box-comments-custom.component.html'
})
export class BoxCommentsCustomComponent implements OnInit, OnChanges, OnDestroy {
  custom: any = this.customLanding.getCustom();
  subscribers: any = {};
  isLanding: any = true;
  userPermissions: any = this.permissions.getPermissions();
  boxComments: any = {
      showCommentBox: false,
      showCommentList: true,
      readMoreText: false,
      noComments: true,
      taskStateNoEditable: false,
      commentList: [],
      commentListFilter: [],
      fullScreen: false,
      hasPermissions: this.userPermissions !== 'none'
  }

  constructor(private customLanding: CustomLandingFactory,
    private permissions: UserPermisionsFactory,
    private broadcastService: BroadcastService,
    private boxCommentsParent: BoxCommentsParentService,
    private commonUtilsService: CommonUtilsService) {}

  @Input() conceptObjectId: any = null;
  @Input() taskId: any = null;
  @Input() taskState: any = null;
  @Input() fullScreenBox: any = null;
  @Input() blockClass: any = null;

  @Output() catchFullScreen = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.subscribers.addNewCommentBroadcast = this.broadcastService.subscribe('addNewComment', (data:any)=>{
      this.boxCommentsParent.createNewComment(data.commentText, this);
    })
  }

  ngOnChanges(){
    this.boxCommentsParent.onChanges(this)
  }

  newComment(){
    this.boxCommentsParent.newComment(this);
  }

  cancelComment(comment:any){
    this.boxCommentsParent.cancelComment(comment, this)
  }
  createNewComment(text:any){
    this.boxCommentsParent.createNewComment(text, this)
  }

  closeComment(){
    this.boxComments.showCommentBox = false;
    this.boxComments.showCommentList = true;
  }
  maximizeScreen(keepClassBox:boolean = false){
    this.boxCommentsParent.maximizeScreen(keepClassBox, this)
  }

  deleteComment(comment:any){
    this.boxCommentsParent.deleteComment(comment, this)
  }

  editComment(comment:any){
    this.boxCommentsParent.editComment(comment, this)
  }

  changeComment(comment:any){
    this.boxCommentsParent.changeComment(comment, this)
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
