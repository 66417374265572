<div class="onboarding"
    [ngShow]="onboarding.charged">
    <h3 class="onboarding__title-product"
        [afTranslate]="'anti-money-laundering-onboarding.title-product'">Introducción a Centinela Anti-blanqueo</h3>
    <h4 class="onboarding__subtitle-product">
        <span [afTranslate]="'anti-money-laundering-onboarding.subtitle-product-1'">¡Bienvenido! Estamos aquí para ayudarte a poner en marcha tu producto.</span><br/>
        <span [afTranslate]="'anti-money-laundering-onboarding.subtitle-product-2'">Sigue los siguientes pasos para realizar las configuraciones básicas y crear tu primera evaluación de riesgos.</span>
    </h4>
    <div class="onboarding__block-body">
        <h5 class="onboarding__title-block"
            [afTranslate]="'anti-money-laundering-onboarding.basic-settings'">Configuraciones básicas</h5>
        <div class="onboarding__block-options-repeat">
            <div *ngFor="let basicOption of onboarding.basicConfigurationList"
                class="onboarding__block-option"
                [ngClass]="{'onboarding__block-option--active': basicOption.active}">
                <h6 class="onboarding__title-option">{{basicOption.title}}</h6>
                <i [class]="basicOption.icon + ' onboarding__icon-option'"
                    [ngClass]="{'onboarding__icon-option--active': basicOption.active}"></i>
                <p class="onboarding__description-option">{{basicOption.description}}</p>
                <button class="common__button-border-none onboarding__button-configuration"
                    (click)="configureOption(basicOption)"
                    [disabled]="basicOption.disabled">
                    <i [class]="basicOption.iconOrder + ' common__icon-button-underlined common__icon-button-underlined--top-4'"></i>
                    <span class="common__text-button-underlined"
                        [afTranslate]="'anti-money-laundering-onboarding.configure'">Configurar</span>
                </button>
            </div>
        </div>
        <h5 class="onboarding__title-block"
            [afTranslate]="'anti-money-laundering-onboarding.my-first-implantation'">Mi primera implantación</h5>
        <div class="onboarding__block-options-repeat">
            <div *ngFor="let optionImplantation of onboarding.firstImplantationList"
                class="onboarding__block-option"
                [ngClass]="{'onboarding__block-option--active': optionImplantation.active}">
                <h6 class="onboarding__title-option">{{optionImplantation.title}}</h6>
                <i [class]="optionImplantation.icon +' onboarding__icon-option'"
                    [ngClass]="{'onboarding__icon-option--active': optionImplantation.active}"></i>
                <p class="onboarding__description-option">{{optionImplantation.description}}</p>
                <div class="onboarding__block-alert-info">
                    <i class="lf-icon-alert onboarding__icon-block-alert"
                        [ngShow]="optionImplantation.alertIcon"></i>
                    <help-tooltip class="onboarding__help-tooltip"
                        [ngShow]="optionImplantation.showHelpTooltip"
                        [key]="optionImplantation.keyTooltip"
                        (closeHelpTooltip)="closeHelpTooltip(optionImplantation)"></help-tooltip>
                </div>
                <button class="common__button-border-none onboarding__button-configuration"
                    (click)="configureOption(optionImplantation)"
                    [disabled]="optionImplantation.disabled">
                    <i [class]="optionImplantation.iconOrder + ' common__icon-button-underlined common__icon-button-underlined--top-4'"></i>
                    <span class="common__text-button-underlined"
                        [afTranslate]="'anti-money-laundering-onboarding.evaluate'">_Evaluar</span>
                </button>
            </div>
        </div>
    </div>
</div>
