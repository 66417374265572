import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'input-number',
  templateUrl: './input-number.component.html'
})
export class InputNumberComponent implements  OnChanges {
  skin: string = this.globalCfg.skin;
  id: string = this.commonUtilsService.createId();
  inputNumber: any = {
    value: null,
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService) { }
  @Input() preSelectValue: any = null;
  @Input() disabled: any = null;
  @Input() from: any = null;
  @Input() until: any = null;
  @Output() selectedValue = new EventEmitter();


  ngOnChanges(): void {
    this.inputNumber.until = this.until && this.until !== null? parseInt(this.until) : 999;
    this.inputNumber.from = this.from && this.from !== null? parseInt(this.from) : 1;
    if(this.preSelectValue && this.preSelectValue !== null){
      this.inputNumber.value = this.preSelectValue;
    }else{
      this.inputNumber.value = this.inputNumber.from;
      this.selectedValue.emit({newValue:this.inputNumber.value});
    }
  }

  addOrRemoveAmount(type:any){
    if(!this.disabled){
      if(type === 'remove'){
            if(parseInt(this.inputNumber.value) > this.inputNumber.from){
              this.inputNumber.value --;
            }
        }else{
            if(parseInt(this.inputNumber.value) < this.inputNumber.until){
              this.inputNumber.value++;
            }
        }
        this.selectedValue.emit({newValue:this.inputNumber.value})
    }
  }

  changeNumber(){
      this.selectedValue.emit({newValue:this.inputNumber.value})
  }


}


