import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
    selector: 'new-contact',
    templateUrl: './new-contact.component.html'
})
export class NewContactComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  contactProperties: any = ['name','surname','nif','position','companyname','address','postalCode','city', 'province', 'country','email','phonenumber1','phonenumber2'];
  newContact:any = {
    new:{}
  };

  constructor(private route: ActivatedRoute,
      private translate: AfTranslateFactory,
      private commonUtilsService:CommonUtilsService,
      private apiService: ApiServiceService,
      private modalService: ModalServiceService,
      private globalCfg: GlobalCfgFactory,
      private loader: LoaderFactory,
      private context: ContextFactory) { }

  @Input() option : any ={};
  @Input() valueId : any;
  @Output() changeView = new EventEmitter();
  @Output() addContact = new EventEmitter();

  ngOnInit(){
    this.configureContactProperties();
    this.resetForm();
  }

  configureContactProperties(){
    this.contactProperties.forEach((property: any)=>{
      let labelTranslate = this.getLabelTranslate(property)
      let propertyTranslate = property === 'companyname'?'company':property;
      this.newContact[property] = {
          label: this.translate.instant(labelTranslate+propertyTranslate),
          fieldId: property+'-'+this.commonUtilsService.createId(),
          required: (property === 'name' || property === 'surname' || property === 'email'),
          description: null,
          fieldTypeId: (property === 'phonenumber1' || property === 'phonenumber2' || property === 'postalCode')?'num':'text'
      }
      if(property === 'phonenumber1' || property === 'phonenumber2' || property === 'postalCode'){
          this.newContact[property].positive = true;
      }
    })
  }

  getLabelTranslate(property: any){
    if(property === 'nif'){
      return 'users-admin.person-form.';
    }else{
      return (property === 'phonenumber1' || property === 'phonenumber2' || property === 'postalCode')?'new-contact.':'common.';
    }
  }

  resetForm(){
    this.newContact.new = {
          name: '',
          surname:'',
          position:'',
          companyname:'',
          email:'',
          phonenumber1:'',
          phonenumber2:'',
          address:'',
          postalCode:'',
          city:'',
          province:'',
          country:''
    };
  }

  itemSelected(type: any, newValue: any){
    this.newContact.new[type] = newValue;
  }

  changeState(e: any){
      e.stopPropagation();
      e.preventDefault();
      this.resetFields();
      this.changeView.emit();
  }

  resetFields(){
      this.resetForm();
      this.newContact.name.error = false;
      this.newContact.surname.error = false;
      this.newContact.email.error = false;
      this.newContact.phonenumber1.error = false;
  }

  createNew(){
    if(this.validateFieldsForm()){
        if(this.newContact.new.contactId){
            delete this.newContact.new.contactId;
        }
        let evaluationId = this.route.snapshot.params['evaluationId'] || this.route.snapshot.firstChild?.params['evaluationId'];
        if(this.valueId && this.option !== 'Client' && !evaluationId){
            evaluationId = this.valueId;
        }
        if(this.option === 'Client'){
          this.newContact.new.clients = [{
            clientId: this.valueId
          }]
        }
        let url = 'contacts';
        if(evaluationId){
            url = 'contacts/toevaluation/' + evaluationId;
        }
        this.loader.openLoader('add-new-contact');
        this.apiService.add(url, this.newContact.new)
          .then((data:any)=>{
              data.fullName = this.newContact.new.name + ' ' + this.newContact.new.surname
              let newContact = {
                  address:      data.address,
                  city:         data.city,
                  companyName:  data.companyName,
                  contactId:    data.contactId,
                  country:      data.country,
                  email:        data.email,
                  fullName:     data.fullName,
                  listValueId:  'C-'+data.contactId,
                  name:         data.name ,
                  phoneNumber1: data.phoneNumber1,
                  phoneNumber2: data.phoneNumber2,
                  position:     data.position,
                  postalCode:   data.postalCode,
                  province:     data.province,
                  surname:      data.surname,
                  type:         'C'
              }
              this.changeView.emit();
              this.addContact.emit({ participant: newContact });
              this.loader.closeLoader('add-new-contact');
              let text = this.translate.instant('new-contact.the-contact')+' '+ this.newContact.new.name +' '+ this.newContact.new.surname +' ' + this.translate.instant('new-contact.created');
              this.modalService.adviceModal(text, 'info:accept:check-round');
              this.resetFields();
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
          });
    }
  }

  validateFieldsForm() {
    this.newContact.name.error = (this.newContact.new.name === '');
    this.newContact.surname.error = (this.newContact.new.surname === '');
    this.newContact.email.error = (this.newContact.new.email === '' || !this.commonUtilsService.validateMail(this.newContact.new.email));
    return (!this.newContact.name.error && !this.newContact.surname.error && !this.newContact.email.error);
  }
}
