import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
@Injectable()

export class  OpenLoaderResolver implements Resolve<any> {
    constructor(private loader: LoaderFactory) { }
    resolve() {
        this.loader.openLoader('concept-precharged-info')
    }
}
