<div class="concursal-forms">
  <div class="common__modal-header-block">
      <i class="{{concursalFormsModal.headerIcon}} common__icon-header"></i>
      <span class="common__text-header">{{concursalFormsModal.title}}</span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="concursal-forms__content">
      <p class="concursal-forms__description">{{concursalFormsModal.description}}</p>
      <div class="concursal-forms__evaluations-list-container">
          <evaluations-list [filteredEvaluations]="configuration.product.evaluations"
                            [deletedEvaluations]="configuration.product.deletedEvaluations"
                            (launchAction)="launchActionFormsModal($event)"
                            [canCreate]="concursalFormsModal.managePermissions"
                            [isConcursalFormsModal]="true">
          </evaluations-list>
      </div>
  </div>
</div>
