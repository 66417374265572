import * as d3 from 'd3';
import moment from 'moment';

import { ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'line-graph',
  templateUrl: './line-graph.component.html'
})
export class LineGraphComponent implements OnChanges  {
  skin: string = this.globalCfg.skin;
  lineGraph: any ={
    graph: {
      marginTop:30,
      marginRight:50,
      marginBottom:120,
      marginLeft:55,
      tooltip: null
    },
    hasPaintedGraph: false,
    linePoint: null,
    data: [],
    dotClass:'',
    legendBlock: '',
    graphWidth: 0,
    maxValueY: 0,
    ticksRange: [],
    tooltipText: this.skin === 'icam-red'? this.translate.instant('dashboard-reports.query') : this.translate.instant('dashboard-reports.complaints'),
  }
  constructor( private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private ref: ChangeDetectorRef) { }

  @Input() graphData: any;

  ngOnChanges(): void {
    if(this.graphData){
      this.lineGraph.data = this.graphData.data;
      this.lineGraph.type =  this.graphData.type;
      if(this.lineGraph.hasPaintedGraph){
          this.lineGraph.graph.svg.remove();
      }else{
          this.clickMenu();
          this.calculateWidth();
          this.lineGraph.graph.width =  this.lineGraph.graphWidth - this.lineGraph.graph.marginLeft - this.lineGraph.graph.marginRight;
          this.lineGraph.graph.height = 390 - this.lineGraph.graph.marginTop - this.lineGraph.graph.marginBottom;
          this.commonUtilsService.setMomentLanguage();
      }
      this.createGraph()
    }
  }
  createGraph(){
    this.configureData();
    this.drawSvg();
    this.generateAsisX();
    this.generateAsisY();
    this.drawLineGraph();
  }

  clickMenu(){
    $(document).off('click', (event)=>{this.closeMenu(event)});
    $(document).on('click', (event)=>{this.closeMenu(event)});
  }

  closeMenu(event: any){
    if((typeof event.target.className === 'string' && event.target.className !== undefined && event.target.className.indexOf('line-graph') === -1 &&  d3.select(".line-graph__tooltip-box") !== null) || (event.target.className.animVal === '' && event.target.className.baseVal === '' &&  d3.select(".line-graph__tooltip-box") !== null)){
        this.closeTooltip();
    }
  }

  closeTooltip(){
     d3.select(".line-graph__tooltip-box").style("display", 'none')
    this.lineGraph.linePoint = null;
    this.lineGraph.data.forEach((data: any) => {
      this.selectDot(data.date.format('DDMMYYYY'))
    });
  }

  calculateWidth(){
    if(window.outerWidth >= 1400){
      this.lineGraph.graphWidth = 1210;
    }else if(window.outerWidth >= 1200 && window.outerWidth < 1400){
      this.lineGraph.graphWidth = 950;
    }else if( window.outerWidth >= 900 && window.outerWidth < 1200){
      this.lineGraph.graphWidth = 800
    }
  }

  configureData(){
    this.lineGraph.data.forEach((d:any, i:any)=> {

        d['numberOf'+this.graphData.configuration] = +d['numberOf'+this.graphData.configuration];

        d.monthConvert = this.lineGraph.data[i].date.format('DD/MM/YYYY');
        if(this.lineGraph.type !== 1){
            d.monthConvert = this.lineGraph.data[i].date.format('DD/MM/YYYY') +' - '+ d.toDate.format('DD/MM/YYYY')
        }
        d.detailsToShow = cloneDeep(d.details[0]);
        delete d.detailsToShow.month;
        delete d.detailsToShow['numberOf'+this.graphData.configuration];
    });

    this.lineGraph.graph.valueline = d3.line()
        .x((d:any, i:any)=>{ return this.lineGraph.graph.x(i); })
        .y((d:any)=> { return  this.lineGraph.graph.y(d['numberOf'+this.graphData.configuration]); });
}

drawSvg(){
    this.lineGraph.graph.svg = d3.select(".line-graph").append("svg")
        .attr("width", this.lineGraph.graph.width + this.lineGraph.graph.marginLeft + this.lineGraph.graph.marginRight)
        .attr("height", this.lineGraph.graph.height + this.lineGraph.graph.marginTop + this.lineGraph.graph.marginBottom)
        .attr("class", "line-graph__line-graph-container");

    this.lineGraph.graph.pannelGraph = this.lineGraph.graph.svg.append("g")
        .attr('class', 'line-graph__pannel-graph')
        .attr("transform",
            "translate(" + this.lineGraph.graph.marginLeft + "," + (this.lineGraph.graph.marginTop + 10) + ")");

    this.lineGraph.graph.lineGraph = this.lineGraph.graph.svg.append("g")
        .attr('class', 'line-graph__line-graph')
        .attr("transform",
            "translate(" + (this.lineGraph.graph.marginLeft + 30) + ", "+(this.lineGraph.graph.marginTop - 5)+")");
}

generateAsisX(){
    this.lineGraph.graph.x = d3.scaleLinear()
        .domain([0, this.lineGraph.data.length-1])
        .range([0, this.lineGraph.graph.width]);

    this.lineGraph.graph.pannelGraph.append("g")
        .attr("class", "heat-map-graph__axis-x")
        .attr("transform", "translate(-3," + (this.lineGraph.graph.height + this.lineGraph.graph.marginTop - 9) + ")")
        .call(d3.axisBottom(this.lineGraph.graph.x)
            .ticks( this.lineGraph.data.length-1)
            .tickSize(0));

    d3.selectAll('.heat-map-graph__axis-x path')
        .attr("stroke-width", 4)
        .attr("stroke", ()=>{
            if(this.skin==='steel-blue'){
                return '#008187'
            }else if(this.skin==='icam-red' || this.skin==='gm-law'){
                return '#000000';
            }
            return "#E5E8F1"
        })
        .attr("transform", "translate(30,0)");

    d3.selectAll('.heat-map-graph__axis-x .tick text')
        .attr("transform", "translate(30,10)")
        .attr('class', (d:any, i:any)=>{
            let text = 'line-graph__axis-tick-text';
            if(this.lineGraph.data.length > 31){
                 if( i !== 0 && i !== this.lineGraph.data.lenght - 1 && (i % Math.floor(this.lineGraph.data.length / 30)) !== 0){
                     text += ' line-graph__axis-tick-text--hidden';
                 }
            }
            return text;
        })
        .text('')
        .append("tspan")
        .attr('class', 'line-graph__axis-tick-text-month')
        .text((d:any, i:any)=>{
            let format  = this.lineGraph.type === 4? 'YYYY' : 'MMM';
            return moment(this.lineGraph.data[i].date).format(format)
        })
        .attr("dy", 8)
        .attr("dx", 2);


    d3.selectAll('.heat-map-graph__axis-x .tick text')
        .append("tspan")
        .attr('class', 'line-graph__axis-tick-text-day')
        .text((d:any, i:any)=>{
            let format = 'DD';
            if(this.lineGraph.type === 4){
                return '';
            }else if(this.lineGraph.type === 3){
                format = 'YYYY';
            }
            return moment(this.lineGraph.data[i].date).format(format)
        })
        .attr("dx", (d:any, i:any)=>{
            return this.lineGraph.type === 3? -20 : -15;
        })
        .attr("dy", 12);

    d3.selectAll('.heat-map-graph__axis-x')
        .append("text")
        .attr("text-anchor", "end")
        .attr("class", "line-graph__axis-title")
        .attr("transform", "translate(182, -11)")
        .text(()=>{
            return this.translate.instant('dashboard-reports.by-creation-date')
        });
}

  generateAsisY(){
    this.calculateRange();
    this.generateArrayTicks();
    this.lineGraph.graph.y = d3.scaleLinear().range([this.lineGraph.graph.height, 0])
        .domain([0, this.lineGraph.maxValueY]);

    this.lineGraph.graph.pannelGraph.append("g")
        .attr("class", "heat-map-graph__axis-y")
        .attr("transform", "translate(-10, 15)")
        .call(d3.axisLeft(this.lineGraph.graph.y).tickSize(0).tickValues(this.lineGraph.ticksRange));

    d3.selectAll('.heat-map-graph__axis-y path')
        .attr("stroke-width", 4)
        .attr("stroke", ()=>{
            if(this.skin==='steel-blue'){
                return '#008187';
            }else if(this.skin==='icam-red'){
                return '#000000';
            }else if(this.skin==='gm-law') {
              return '#133371';
            }
            return "#E5E8F1"
        })
        .attr("transform", "translate(0,-30)");

    d3.selectAll('.heat-map-graph__axis-y .tick text')
        .attr("transform", "translate(-10,-25)")
        .attr('class', 'line-graph__axis-tick-text')

    d3.selectAll('.heat-map-graph__axis-y')
        .append("text")
        .attr("text-anchor", "end")
        .attr("class","line-graph__axis-title")
        .attr("transform",()=>{
            let y = 100;
            if(this.commonUtilsService.getLanguage().indexOf('fr')!== -1){
                y = 70
            }else if(this.commonUtilsService.getLanguage().indexOf('ES')!== -1){
                y = 85
            }
            return  "translate(21,"+y+") rotate(-90)"
        })
        .text(()=>{
            let text = 'dashboard-reports.number-complaints';
            if(this.skin === 'icam-red'){
                text = 'dashboard-reports.number-queries';
            }else if(this.graphData.configuration === 'Operations'){
                text = 'dashboard-reports.numberOfOperations';
            }
            return this.translate.instant(text);
        });
  }

  calculateRange(){
    this.lineGraph.maxValueY = 100;
    this.lineGraph.data.forEach((data:any) => {
      if(data['numberOf'+this.graphData.configuration] > this.lineGraph.maxValueY){
        this.lineGraph.maxValueY = Math.ceil(data['numberOf'+this.graphData.configuration]/100)*100;
      }
    });
  }




  drawLineGraph(){
    this.lineGraph.graph.lineGraph.append("g")
        .attr("transform", "translate(0," + this.lineGraph.graph.height + ")")
        .attr('class', 'line-graph__x-ticks')
        .call(d3.axisBottom(this.lineGraph.graph.x)
            .ticks(this.lineGraph.data.length-1)
            .tickSize(-this.lineGraph.graph.height ));

    d3.selectAll('.line-graph__x-ticks .tick')
        .attr('id', (d, i)=>{
            return 'tick-'+ moment(this.lineGraph.data[i].date).format('DDMMYYYY')
        })

  // add the y Axis

    this.lineGraph.graph.lineGraph.append("g")
            .attr('class', 'line-graph__y-ticks')
            .call(d3.axisLeft(this.lineGraph.graph.y).tickSize(-this.lineGraph.graph.width).tickValues(this.lineGraph.ticksRange))


    this.lineGraph.graph.lineGraph.append("path")
        .data([this.lineGraph.data])
        .attr("class", "line-graph__line ")
        .attr("d", this.lineGraph.graph.valueline);

    this.lineGraph.graph.lineGraph.selectAll("line-graph__dot")
        .data(this.lineGraph.data)
        .enter().append("circle") // Uses the enter().append() method
        .attr("class", (d:any, i:any)=>{
             this.lineGraph.dotClass = "line-graph__dot line-graph__dot-";
            if(this.lineGraph.data.length > 30){
                 this.lineGraph.dotClass = "line-graph__dot line-graph__dot--more-than line-graph__dot-";
            }

            return  this.lineGraph.dotClass + this.lineGraph.data[i].date.format('DDMMYYYY');
        }) // Assign a class for styling
        .attr("cx", (d:any, i:any)=> { return this.lineGraph.graph.x(i) })
        .attr("cy", (d:any)=> { return this.lineGraph.graph.y(d['numberOf'+this.graphData.configuration]) })
        .attr("r", 4)
        .on("mouseover", (d:any, i:any)=>this.dotClick(d, i))
        .on("mouseout",  ()=>this.removeTooltip());
        this.drawLegendBlock();

        this.lineGraph.hasPaintedGraph = true;
  }

  drawLegendBlock(){
    this.lineGraph.graph.lineGraph.selectAll(".line-graph__legend-block")
        .data(this.lineGraph.data)
        .enter()
        .append("g") // Uses the enter().append() method
        .attr("class", (d:any, i:any)=>{
          this.lineGraph.legendBlock = "line-graph__legend-block line-graph__legend-block-"
            if(this.lineGraph.data.length > 30){
                 this.lineGraph.legendBlock = 'line-graph__legend-block line-graph__legend-block--more-than line-graph__legend-block-'
            }
            return this.lineGraph.legendBlock + this.lineGraph.data[i].date.format('DDMMYYYY');
        }) // Assign a class for styling
        // .attr("width", "50px")
        // .attr("height", "30px")
        .attr("transform",(d:any, i:any)=>{
            return "translate(" + (this.lineGraph.graph.x(i) - 25)  + "," +(this.lineGraph.graph.y(d['numberOf'+this.graphData.configuration]) - 35) + ")"
        })
        .append('foreignObject')
        .attr("width", 50)
        .attr("height", 30)
        .append('xhtml:div')
        .attr("class", (d:any, i:any)=>{

            return "line-graph__legend-box line-graph__legend-box-" + this.lineGraph.data[i].date.format('DDMMYYYY')
        }) // Assign a class for styling
        .html((d:any)=>{
            return d['numberOf'+this.graphData.configuration]
        })
        .on("mouseover", (d:any, i:any)=>this.dotClick(d, i))
        .on("mouseout", ()=>this.removeTooltip());

}

  generateArrayTicks(){
    let value = 0;
    this.lineGraph.ticksRange = [0];
    for(let i = 1; i < 11; i++){
        value += this.lineGraph.maxValueY / 10;
        this.lineGraph.ticksRange[i] = value;
    }
  }

  dotClick(d:any, i:any) {
    let selectDot = this.lineGraph.data[i];
    let selectDotFormat = selectDot.date.format('DDMMYYYY');
    this.lineGraph.data.forEach((data:any) => {
      this.selectDot(data.date.format('DDMMYYYY'));
    });
    $(".line-graph__dot-" + selectDotFormat).attr('class', this.lineGraph.dotClass + selectDotFormat+ " active");
    $("#tick-" + selectDotFormat).attr('class', 'tick tick-active');
    $(".line-graph__legend-box-" + selectDotFormat).addClass('active');
    $(".line-graph__legend-block-" + selectDotFormat).attr('class', this.lineGraph.legendBlock + selectDotFormat +' line-graph__legend-block--active');
         d3.select(".line-graph__tooltip-box").style("display", "block")
        .style("left", ((this.lineGraph.graph.x(i))-23) +"px")
        .style("top", (this.lineGraph.graph.y(d['numberOf'+this.graphData.configuration])+85) + "px")
        this.lineGraph.linePoint = selectDot;
        this.ref.detectChanges()
  }

  removeTooltip(){
    this.lineGraph.data.forEach((data:any) => {
      this.selectDot(data.date.format('DDMMYYYY'))
    });

     d3.select(".line-graph__tooltip-box").style("display", "none")
  }

  selectDot(selectDot:string){
    $(".line-graph__dot-" + selectDot).attr('class', this.lineGraph.dotClass + selectDot);
    $(".line-graph__legend-box-" + selectDot).removeClass('active');
    $(".line-graph__legend-block-" + selectDot).attr('class', this.lineGraph.legendBlock + selectDot);
    $("#tick-" + selectDot).attr('class', 'tick');
  }

  numberComplaintsPercent(value: any){
   return this.lineGraph.linePoint['numberOf'+this.graphData.configuration]? ((parseInt(value) * 100) / this.lineGraph.linePoint['numberOf'+this.graphData.configuration]).toFixed(0)+'%' : '0%'
  }
}
