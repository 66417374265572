import { dia, ui, shapes, linkTools, layout} from '@clientio/rappid';
import * as d3 from 'd3'


import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { merge } from 'lodash';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'rappid',
  templateUrl: './rappid.component.html'
})
export class RappidComponent implements AfterViewInit, OnInit, OnChanges {
	skin: string = this.globalCfg.skin;
	ZOOM_STEP: any = 0.2;
  ZOOM_GRID: any = this.ZOOM_STEP;
  MIN_ZOOM: any = this.ZOOM_STEP;
  MAX_ZOOM: any = 5;
  PADDING: any = 25;
  MARGIN_LEFT: any = 50;
  STATE_SIZE: any = {
      width: 100,
      height:120
  };
  GRID: any = 10;


  rappid: any = {
    graph: null,
    STATE_SIZE: {
      width: 100,
      height:120
    },
    tbh: 6,
    radius: 6,
    paper: null,
    paperScroller: null,
    selection: null,
    keyboard: null,
    clipboard: null,
    width: 858,
    height: 445,
    handles: this.defineHandles(),
    markup: [{
      tagName: 'g',
      selector: 'container',
      children: [{
          tagName: 'rect',
          selector: 'rectContainer',
          attributes: {
            'rx': 6,
            'ry': 6,
            'strokeWidth': 1,
            'fill': this.skin==='dark-blue'?'#F4F4F4':'#FFFFFF',
            'width': this.STATE_SIZE.width,
            'height': this.STATE_SIZE.height,
            'class': 'rappid__box-container'
          }
      },{
        tagName: 'path',
        selector: 'header',
        attributes: {
          "class": 'rappid__box-container-header',
          "d": ['M', 0, 6, 'A', 6, 6, 0, 0, 1, 6, 0, 'L', this.STATE_SIZE.width - 6, 0, 'A', 6, 6, 45, 0, 1, this.STATE_SIZE.width, 6].join(' ')
        }
      }]
    }]

  }


  constructor(private globalCfg: GlobalCfgFactory,
    private conceptState: ConceptStateFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService) {

  }

  @Input() component:any = null;
  @Input() canAddAlerts: any = null;
  @Input() designing:boolean = false;
  @Input() defaultStepName:any = null;
  @Input() model:any = null;
  @Input() from: string = 'edit';

  @Output() onAction = new EventEmitter();
  @Output() addStateEmit = new EventEmitter();

	@ViewChild('canvas') canvas: ElementRef;

  ngOnInit(): void {
    if(this.from==='view'){
      this.rappid.height = 657
      this.rappid.width = 1490
    }

    this.rappid.defaultStepName = this.translate.instant(this.defaultStepName)
    this.defineRect()
    this.designPaperInit();
    this.selectionInit();
    if(this.designing){
      this.keyboardInit();
      this.cellToolsInit();
    }
  }

  ngOnChanges(): void {
    if(this.model && this.model !== null){
      this.fromJSON(this.model)
    }
  }

  ngAfterViewInit(): void {
    this.canvas.nativeElement.appendChild(this.rappid.scroller.el);
    this.rappid.scroller.center();
    this.rappid.paper.unfreeze();
  }

  addState(){
    this.rappid.lastItemRect = this.commonUtilsService.createId()
    let rect: any = new this.rappid.rect();
    rect.position(50 + (this.rappid.graph.getElements().length * 5) , 70 + (this.rappid.graph.getElements().length * 5))
    rect.attr('container/id', this.rappid.lastItemRect)
    this.rappid.graph.addCell(rect);
    this.configureCell(this.rappid.lastItemRect, this.rappid.defaultStepName + ' ' + this.rappid.graph.getElements().length);
		this.scrollToElement(rect);
    this.addStateEmit.emit()
  }

  zoomMore(){
    this.zoom(1);
  }

  zoomMinus(){
    this.zoom(-1);
  }

  centerSelected(){
    this.rappid.scroller.zoom(1, { absolute: true });
    if (this.rappid.selection.collection.length === 1) {
        this.rappid.scroller.centerElement(this.rappid.selection.collection.first());
    } else if (this.rappid.selection.collection.length > 1) {
        let selectionBBox:any = null;
        this.rappid.selection.collection.forEach((element:any)=> {
            var bbox = element.getBBox();
            if (selectionBBox) {
                selectionBBox = selectionBBox.union(bbox);
            } else {
                selectionBBox = bbox;
            }
        });
        this.rappid.scroller.positionRect(selectionBBox, 'center');
    }
  }

  layout(){
    layout.DirectedGraph.layout(this.rappid.graph, {
        setLinkVertices: true,
        rankDir: 'LR',
        marginX: this.PADDING,
        marginY: this.PADDING,
        nodeSep: this.STATE_SIZE.width * 2,
        edgeSep: this.STATE_SIZE.width * 2,
        rankSep: this.STATE_SIZE.width * 2
    });
    this.rappid.scroller.centerContent();
  }

  defineRect(){
    this.rappid.rect  =  dia.Element.define('app.State', {
      markup: this.rappid.markup
    });

  }

  defineHandles(){
    return [
      {
          name: 'remove',
          position: 'nw',
          events: { pointerdown: 'removeElement' },
          attrs: {
              '.handle': {
                  'data-tooltip-class-name': 'small',
                  'data-tooltip': 'Clic para eliminar el estado',
                  'data-tooltip-position': 'right',
                  'data-tooltip-padding': 15
              }
          }
      },
      {
          name: 'fork',
          position: 'ne',
          events: { pointerdown: 'startForking', pointermove: 'doFork', pointerup: 'stopForking' },
          attrs: {
              '.handle': {
                  'data-tooltip-class-name': 'small',
                  'data-tooltip': 'Clic y arrastrar para copiar y enlazar el estado',
                  'data-tooltip-position': 'left',
                  'data-tooltip-padding': 15
              }
          }
      },
      {
          name: 'clone',
          position: 'se',
          events: { pointerdown: 'startCloning', pointermove: 'doClone', pointerup: 'stopCloning' },
          attrs: {
              '.handle': {
                  'data-tooltip-class-name': 'small',
                  'data-tooltip': 'Clic y arrastrar para copiar el estado',
                  'data-tooltip-position': 'left',
                  'data-tooltip-padding': 15
              }
          }
      },
      {
          name: 'unlink',
          position: 'w',
          events: { pointerdown: 'unlinkElement' },
          attrs: {
              '.handle': {
                  'data-tooltip-class-name': 'small',
                  'data-tooltip': 'Clic para eliminar todas las transiciones del estado',
                  'data-tooltip-position': 'right',
                  'data-tooltip-padding': 15
              }
          }
      },
      {
          name: 'link',
          position: 'e',
          events: { pointerdown: 'startLinking', pointermove: 'doLink', pointerup: 'stopLinking' },
          attrs: {
            path: {
                stroke: this.getColorStroke(),
                strokeDasharray: this.skin ==='dark-blue'?'1, 3':'1, 2',
                strokeWidth: 1,
                fill: 'none',
                sourceMarker: {
                    stroke: 'none'
                },
                targetMarker: {
                    type: 'path',
                    d: 'M 4 -4 0 0 4 4',
                    fill: 'none',
                    // strokeWidth: 1.5,
                    "stroke-width": 1.5
                }
            },
            text: {
                text: 'Transición', // default label text
            }
          }
      },
      {
          name: 'rotate',
          position: 'sw',
          events: { pointerdown: 'startRotating', pointermove: 'doRotate', pointerup: 'stopBatch' },
          attrs: {
              '.handle': {
                  'data-tooltip-class-name': 'small',
                  'data-tooltip': 'Clic y arrastrar para rotar el objeto',
                  'data-tooltip-position': 'right',
                  'data-tooltip-padding': 15
              }
          }
      }
    ]
  }


  keyboardInit() {
    this.rappid.keyboard = new ui.Keyboard();
    this.rappid.keyboard.on({

        'ctrl+c': function () {
            // Copy all selected elements and their associated links.
            this.rappid.clipboard.copyElements(this.rappid.selection.collection, this.rappid.graph);
        },

        'ctrl+v': function () {

            var pastedCells = this.rappid.clipboard.pasteCells(this.rappid.graph, {
                translate: { dx: 20, dy: 20 },
                useLocalStorage: true
            });

            var elements = pastedCells.forEach((cell:any) => {
              return cell.isElement();
            });

            // Make sure pasted elements get selected immediately. This makes the UX better as
            // the user can immediately manipulate the pasted elements.
            this.rappid.selection.collection.reset(elements);
        },

        'ctrl+x shift+delete': function () {
            this.rappid.clipboard.cutElements(this.rappid.selection.collection, this.rappid.graph);
        },

        'delete backspace': (evt:any)=> {
            evt.preventDefault();
            var published = this.rappid.selection.collection.filter((cell:any)=>{
                var data = cell.get('compliance');
                return data && data.published;
            });
            if (published.length === 0) {
                this.rappid.graph.removeCells(this.rappid.selection.collection.toArray());
            } else {

            }
        },

        'ctrl+z': function () {
            this.rappid.commandManager.undo();
            this.rappid.selection.cancelSelection();
        },

        'ctrl+y': function () {
            this.rappid.commandManager.redo();
            this.rappid.selection.cancelSelection();
        },

        'ctrl+a': function () {
            this.rappid.selection.collection.reset(this.rappid.graph.getElements());
        },

        'ctrl+plus': (evt:any) => {
            evt.preventDefault();
            this.zoom(1);
        },

        'ctrl+minus': (evt:any)=> {
            evt.preventDefault();
            this.zoom(-1);
        },

        'keydown:shift': (evt:any) => {
            this.rappid.scroller.setCursor('crosshair');
        },

        'keyup:shift': function () {
            this.rappid.scroller.setCursor('grab');
        }

    }, this);
  }

  selectionInit() {
    this.rappid.clipboard = new ui.Clipboard();
    this.rappid.selection = new ui.Selection({
        paper: this.rappid.paper
    });


    this.rappid.paper.on('blank:pointerdown', (evt:any, x:any, y:any) => {
      if(this.designing){
        if (this.rappid.keyboard.isActive('shift', evt)) {
            this.rappid.selection.startSelecting(evt);
        } else {
            this.rappid.selection.cancelSelection();
            this.rappid.scroller.startPanning(evt, x, y);
            this.rappid.paper.removeTools();
        }
        this.onAction.emit({cell:null, action: 'selection'})
      }
    });

    this.rappid.paper.on('element:pointerdown', (elementView:any, evt:any)=> {
      this.rappid.selectElement = elementView.model;
      if(this.designing){
        if (this.rappid.keyboard.isActive('ctrl meta', evt)) {
          this.rappid.selection.collection.add(elementView.model);
          this.onAction.emit({cell:this.rappid.selection.collection.models, action:'selection'})
          this.rappid.elementView.removeTools();
        }
      }

    });

    this.rappid.selection.on('selection-box:pointerdown', (elementView:any, evt: any)=> {
      if(this.designing){
        if (this.rappid.keyboard.isActive('ctrl meta', evt)) {
          this.rappid.selection.collection.remove(elementView.model);
          this.onAction.emit({cell:this.rappid.selection.collection.models, action:'selection'})
        }
      }
        // Unselect an element if the CTRL/Meta key is pressed while a selected element is clicked.
    });

    this.rappid.paper.on('link:mouseenter', (elementView:any, evt: any)=> {
     this.rappid.elmentHovered = elementView.model;
    });

  }

  designPaperInit() {
    this.rappid.graph = new dia.Graph({}, { cellNamespace: shapes });
    this.rappid.graph.on('add', (cell:any)=>{this.cellAdded(cell)});
    this.rappid.graph.on('remove', (cell:any)=>{this.cellRemoved(cell)});
    this.rappid.commandManager = new dia.CommandManager({ graph: this.rappid.graph });

    this.rappid.paper = new dia.Paper({
        model: this.rappid.graph,
        width: this.rappid.width,
        height: this.rappid.height,
        gridSize: this.GRID,
        drawGrid: false,
        defaultLink: (elementView, magnet) => {
					return this.setDefaultLink()
        },
        elementView: dia.ElementView,
        linkView: dia.LinkView,
        interactive: this.designing?{ linkMove: false }: false,
    });

    this.rappid.paper.on('cell:mousewheel', (cellView:any, evt:any, x:any, y:any, delta:any)=>{ this.onMousewheel(evt, x, y, delta)});
    //paper.on('blank:mousewheel', (cellView:any, evt:any, x:any, y:any, delta:any)=>{ this.onMousewheel(evt, x, y, delta)});
    this.rappid.paper.on('link:connect', (linkView:any, evt:any, elementViewConnected:any, magnet:any, arrowhead:any)=>{this.onLinkConnect((elementViewConnected))});
    this.rappid.paper.on('link:disconnect', (linkView:any, evt:any, elementViewConnected:any, magnet:any, arrowhead:any)=>{this.onLinkDisconnect((elementViewConnected))});

    this.rappid.scroller = this.rappid.scroller = new ui.PaperScroller({
        paper: this.rappid.paper,
        autoResizePaper: true,
        cursor: 'grab',
    });

  }

	setDefaultLink(){
		return new shapes.standard.Link({
      type:'app.Link',
			router: {
					name: 'normal'
			},
			connector: {
					name: 'smooth'
			},
			attrs: {
					line: {
              stroke: this.getColorStroke(),
							strokeDasharray: this.skin ==='dark-blue'?'1, 3':'1, 2',
							strokeWidth: 1,
							fill: 'none',
							sourceMarker: {
									d: 'M 4 0 a 4 4 0 1 0 0 1',
									stroke: 'none'
							},
							targetMarker: {
									type: 'path',
									d: 'M 4 -4 0 0 4 4',
									fill: 'none',
									"stroke-width": 1.5
							}
					},
					text: {
							text: 'Transición', // default label text
					}
			}
		});
	}


  cellAdded(cell:any) {
    var compliance = cell.get('compliance');
    if (compliance) {
        delete compliance.stateId;
        delete compliance.transitionId;
    } else {
        compliance = {};
    }
    if (cell.isElement()) {
        var elementsCount = this.rappid.graph.getElements().length;
        var text = this.rappid.defaultStepName + ' ' + elementsCount;
        cell.attr('text/tspan', { text: text });
        this.checkSource(cell);
    } else {
        this.checkLinkSources(cell);
    }
    cell.set('compliance', compliance);
  }

  cellRemoved(cell:any) {
      if (cell.isLink()) {
          this.checkLinkSources(cell);
      }
  }

  checkLinkSources(cell:any) {
    var source = cell.get('source');
    if (source && source.id) {
        this.checkSource(this.rappid.graph.getCell(source.id));
    }
    var target = cell.get('target');
    if (target && target.id) {
        this.checkSource(this.rappid.graph.getCell(target.id));
    }
  }

  onMousewheel(evt:any, x:any, y:any, delta:any) {
    if (this.rappid.keyboard.isActive('alt', evt)) {
        evt.preventDefault();
        this.zoom(delta, { ox: x, oy: y });
    }
  }

  zoom(steps:any, opt:any={}) {
    opt = merge({ min: this.MIN_ZOOM, max: this.MAX_ZOOM, grid: this.ZOOM_GRID }, opt);
    this.rappid.scroller.zoom(steps * this.ZOOM_STEP, opt);
  }

  onLinkConnect(elementViewConnected:any) {
    this.checkSource(elementViewConnected.model);
  }

  onLinkDisconnect(elementViewDisconnected:any) {
    this.checkSource(elementViewDisconnected.model);
  }

  checkSource(element:any) {
    return element.attr('./source', this.rappid.graph.isSource(element));
  }

  cellToolsInit() {

    this.rappid.paper.on('element:pointerup', (cellView:any, evt:any)=> {

      if (cellView.model instanceof dia.Link) return;
        var element = cellView.model;
        var halo = new ui.Halo({ cellView: cellView,
          boxContent: false});

        halo.removeHandles();
        halo.addHandles(this.rappid.handles)
        halo.render();

      if (element.prop('cantRemove')) {
        halo.removeHandle('remove');
      }
      var links = this.rappid.graph.getConnectedLinks(element);
      for(var i = 0; i < links.length; i++){
        let link:any = links[i];
        if (link.prop('cantRemove')) {
            halo.removeHandle('remove');
            halo.removeHandle('unlink');

        }
      }

      this.rappid.selection.collection.reset([]);
      this.rappid.selection.collection.add(element, { silent: true });

      this.rappid.paper.removeTools();
      this.onAction.emit({cell:element, action:'selection'})
    });

    this.rappid.paper.on('link:pointerup', (linkView:any, evt:any)=> {
      var link = linkView.model;


      var tools = [
          new linkTools.Vertices(),
          new linkTools.SourceAnchor(),
          new linkTools.TargetAnchor(),
          new linkTools.Segments,
          new linkTools.Boundary({ padding: 15 }),
      ];
      if (!link.prop('cantRemove')) {
          tools.push(new linkTools.Remove({ offset: -20, distance: 40 }));
      }
      if (!link.prop('cantRelink')) {
          tools.push(new linkTools.SourceArrowhead());
          tools.push(new linkTools.TargetArrowhead());
      }

      var toolsView = new dia.ToolsView({
          name: 'link-pointerdown',
          tools: tools
      });

      this.rappid.selection.collection.reset([]);
      this.rappid.selection.collection.add(link, { silent: true });

      ui.Halo.clear(this.rappid.paper);
      ui.FreeTransform.clear(this.rappid.paper);
      this.rappid.paper.removeTools();

      linkView.addTools(toolsView);
      this.onAction.emit({cell:link, action: 'selection'})

    })

    this.rappid.paper.on('link:mouseenter', (linkView:any)=>{

      if (linkView.hasTools()) return;
      var toolsView = new dia.ToolsView({
          name: 'link-hover',
          tools: [
              new linkTools.Vertices({ vertexAdding: false }),
              new linkTools.SourceArrowhead(),
              new linkTools.TargetArrowhead()
          ]
      });

      linkView.addTools(toolsView);

    })

    this.rappid.paper.on('link:mouseleave', (linkView:any)=>{
          if (linkView.hasTools('link-hover')) {
            linkView.removeTools();
          }

    });

    this.rappid.graph.on('change', (cell:any, opt:any)=> {
        this.conceptState.setProcess(true);
        if (!cell.isLink() || !opt.inspector) return;

        var ns = linkTools;
        var toolsView = new dia.ToolsView({
            name: 'link-inspected',
            tools: [
                new ns.Boundary({ padding: 15 })
            ]
        });

        cell.findView(this.rappid.paper).addTools(toolsView);

    });
  }




  fromJSON(model:any) {
    var currentId = null;
    if (model && model.cells) {
        var topLeft = { x: 0, y: 0 };
        model.cells.forEach((cell:any) => {
          cell.compliance.cellId = this.commonUtilsService.createId();
          if (cell.type === 'app.State') {

            topLeft.x = Math.min(topLeft.x, cell.position.x);
            topLeft.y = Math.min(topLeft.y, cell.position.y);
            cell.markup = this.rappid.markup;
            if (cell.attrs['.'] && cell.attrs['.'].current) {
                currentId = cell.id;
            }

          }
        });
        if (topLeft.x < 0 || topLeft.y < 0) {
            model.cells.forEach((cell:any) => {
                if (cell.type === 'app.State') {
                    cell.position.x -= topLeft.x;
                    cell.position.y -= topLeft.y;
                } else if (cell.type === 'app.Link' && cell.vertices?.length) {
                    cell.vertices.forEach((vertex:any) => {
                      vertex.x -= topLeft.x;
                      vertex.y -= topLeft.y;
                    });
                }
            });
        }
        model = this.setToStandardLink(model)
        this.rappid.graph.fromJSON(model);
        this.changeAppearanceOfNodes();
        this.rappid.paper.fitToContent({
            gridWidth: this.rappid.GRID,
            gridHeight: this.rappid.GRID,
            padding: 25
        });
        if (this.designing) {
            this.fit();
            this.rappid.paper.scale(1, 1);
            this.rappid.scroller.centerContent();
        } else {
            this.checkPaperScroll();
            if (currentId) {
                var current = this.rappid.graph.getCell(currentId);
                if (current) {
                    if (!this.rappid.scroller.isElementVisible(current)) {
                        this.scrollToElement(current, false);
                    }
                }
            }
        }
    }
    this.rappid.scroller.scrollToContent();
  }

  setToStandardLink(model:any){
    model.cells.forEach((cell:any) => {
      if(cell.type === 'app.Link'){
        cell.type = 'standard.Link'
      }
    });
    return model;
  }

  toJSON() {
    return this.rappid.graph.toJSON();
  }

  scrollToElement(cell:any, animate: boolean = true) {
    var options = null;
    if (animate) {
        options = {
            animation: {
                time: 250
            }
        };
    }
    this.rappid.scroller.scrollToElement(cell, options);
  }

  fit() {
    this.rappid.scroller.zoomToFit({ minScale: this.MIN_ZOOM, maxScale: 1, grid: this.ZOOM_GRID, padding: this.PADDING * 2 })
  }

  configureCell(id:any, text:any){
    let container:any = d3.select(`#${id}`)
    d3.select(`#${id} rect`).attr('stroke',()=>{
      if(this.skin ==='dark-blue'){
        return '#E9E9E9';
      }else if(this.skin === 'icam-red'){
        return '#B9BFC2'
      }else{
        return '#CDD2E1'
      }
    })
    d3.select(`#${id} path`).attr( "fill", ()=>{
      if(this.skin ==='dark-blue'){
        return '#001978';
      }else if(this.skin === 'icam-red'){
        return '#B9BFC2'
      }else{
        return '#27344E'
      }
    })
    container.append('text')
      .attr("font-size", 10)
      .attr("class", 'rappid__box-container-text')
      .attr("fill",   ()=>{
        if(this.skin ==='dark-blue'){
          return '#001978';
        }else if(this.skin === 'icam-red'){
          return '#B9BFC2'
        }else{
          return '#27344E'
        }
      })
    container.append('foreignObject')
      .attr("x", 0)
      .attr("width", "100")
      .attr("y", 0)
      .attr("height", "70")
      .append('xhtml:div')
      .attr('class', 'rappid__state-box-text')
      .text(text)
  }

	getCellsBfs() {
		let states:any = null;
		let sources:any = this.rappid.graph.getSources();
		if (sources.length === 1) {
				states = [];
				this.rappid.graph.bfs(sources[0], (element:any, distance:any) => { states.push(element); });
		} else {
				console.error("Invalid model. Only one source is allowed");
		}
		return states;
	}

	isElementVisible(element:any) {
		return this.rappid.scroller.isElementVisible(element);
	}

  checkPaperScroll() {
		var visibleArea = this.rappid.scroller.getVisibleArea();
		var contentArea = this.rappid.paper.getContentArea();
		this.rappid.scroller.locked = visibleArea.width >= contentArea.width && visibleArea.height >= contentArea.height;
		if (this.rappid.scroller.locked) {
				this.rappid.scroller.lock();
				this.rappid.scroller.setCursor('default');
		} else {
				this.rappid.scroller.unlock();
				this.rappid.scroller.setCursor('grab');
		}
	}

  getCell(id:any) {
    return this.rappid.graph.getCell(id);
  }

  changeAppearanceOfNodes(){
    let children: any = this.rappid.graph.getCells();
    for(var i = 0; i < children.length; i++){
      let child: any = children[i];
      let complianceAttributes: any = child.attributes.compliance;
      $(`g[model-id='${child.id}'] g`).attr('id', complianceAttributes.cellId)
      this.configureCell(complianceAttributes.cellId, complianceAttributes.name);
      if(complianceAttributes.onlyView){
        this.configureIcons(complianceAttributes);
      }
      if(child.attributes.type === 'app.State'){
        this.changeColor(complianceAttributes);
      }else if(child.attributes.type === 'standard.Link'){
        let path: any = d3.selectAll(`g[model-id='${child.id}'] path`)
          .attr('stroke', this.skin ==='dark-blue'?'#001978':'#27344E')
          .attr('stroke-width', '1')
          .attr('stroke-dasharray', this.skin ==='dark-blue'?'1, 3':'1, 2')
          // .attr('marker-end', 'url(#v-2-759887114)')
        if(complianceAttributes?.creationDate){
          this.addStepDone(child.id, 'add', complianceAttributes.creationDate)
        }
      }
    }
  }

  addStepDone(id:any, type: string = 'add', date:any=undefined){
    let color: string = type==='add'? 'white' : 'url(#v-2951523203)'
    let allPath: any = d3.selectAll(`g[model-id='${id}'] path`)
      .attr('marker-start', color);
    if(type==='add'){
      let initPath:any = allPath.attr('d').substring(2, allPath.attr('d').indexOf('C')-1).split(' ')
      let link: any = d3.select(`g[model-id='${id}']`);
      link.append('foreignObject')
        .attr("x", initPath[0])
        .attr("width", "152")
        .attr("y", parseInt(initPath[1])-12)
        .attr("height", "50")
        .append('xhtml:div')
        .attr('class', 'rappid__state-box-link')
        .append('xhtml:div')
        .attr('class', 'rappid__state-box-date');

      d3.select(`g[model-id='${id}'] .rappid__state-box-link`)
        .append('i')
        .attr('id', id)
        .attr('class', 'rappid__state-box-icon rappid__state-box-icon--green lf-icon-step-done')
      d3.select(`g[model-id='${id}'] .rappid__state-box-date`)
        .append('div')
        .attr('class', 'rappid__tooltip-date-text')
        .text(date)
    }else{
      d3.select(`g[model-id='${id}'] .rappid__state-box-link`).remove();
    }
  }

  configureIcons(complianceAttributes:any){
    let rect:any = d3.select(`#${complianceAttributes.cellId}`);
    rect.append('foreignObject')
      .attr("x", "0")
      .attr("width", "100")
      .attr("y", "90")
      .attr("height", "30")
      .append('xhtml:div')
      .attr('class', 'rappid__state-box');

      if(Boolean(complianceAttributes.hasPublishAuto)){
        this.appendButton(complianceAttributes.cellId, 'gray', 'transparency-report');
      }
      if(Boolean(complianceAttributes.alerts)){
        this.appendButton(complianceAttributes.cellId, 'gray', 'notification-new');
      }
      if(Boolean(complianceAttributes.archiveConceptObject)){
        this.appendButton(complianceAttributes.cellId, 'gray', 'archive');
      }

      if(Boolean(complianceAttributes.description)){
        this.appendButton(complianceAttributes.cellId, 'blue', 'information', 'description');
      }

      if(complianceAttributes.references && complianceAttributes.references.length){
        this.appendButton(complianceAttributes.cellId, 'blue', 'legal-reference', 'references');
      }

      if(Boolean(complianceAttributes.hasDocuments)){
        this.appendButton(complianceAttributes.cellId, 'blue', 'documentation', 'documents');
      }
  }

  addIcon(cell:any, type: string){
    let complianceAttributes: any = cell.attributes.compliance
    let color: string = 'blue';
    let icon: string = 'documentation'
    if(type === 'alert'){
      color = 'gray';
      icon = 'notification-new'
    }
    let iElement: any = $(`#${complianceAttributes.cellId} .rappid__state-box i`)
    if(Boolean( !iElement?.hasClass('lf-icon-notification-new')) && type=='alert'){
      this.appendButton(complianceAttributes.cellId, color, icon);
    }else if(Boolean(!iElement?.hasClass('lf-icon-documentation')) && type==='documents'){
      this.appendButton(complianceAttributes.cellId, color, icon, 'documents');
    }
  }

  appendButton(id: any, color: string, icon: string, action:string = ''){
    d3.select(`#${id} .rappid__state-box`)
      .append('i')
      .attr('class', `rappid__state-box-icon rappid__state-box-icon--${color} lf-icon-${icon}`);
    if(action !== ''){
      d3.select(`#${id} .rappid__state-box .lf-icon-${icon}`)
        .on('click', ()=>{
          this.onAction.emit({cell:this.rappid.selectElement, action: action})
        })
    }
  }

  changeColor(complianceAttributes:any){
    let path: any = d3.select(`#${complianceAttributes.cellId} path`);
    let rect: any = d3.select(`#${complianceAttributes.cellId} rect`);
      path.attr('fill', ()=>{
        if(Boolean(complianceAttributes.selected)){
         if(this.skin ==='dark-blue'){
            return '#77D166';
          }else if(this.skin === 'icam-red'){
            return Boolean(complianceAttributes.current)?'#E2D1D6':'#701B31';
          }else{
            return '#27344E';
          }
        }else{
          if(this.skin ==='dark-blue'){
            return '#001978';
          }else if(this.skin === 'icam-red'){
            return '#B9BFC2';
          }else{
            return '#27344E';
          }
        }
      })
      if(Boolean(complianceAttributes.current)){
       rect.attr('stroke', ()=>{
          if(this.skin ==='dark-blue'){
              return '#77D166';
            }else if(this.skin === 'icam-red'){
              return '#E2D1D6';
            }else{
              return '#27344E';
            }
          }).attr('stroke-width', '2')
      }else{
        rect.attr('stroke', ()=>{
          if(this.skin ==='dark-blue'){
            return '#E9E9E9';
          }else if(this.skin === 'icam-red'){
            return '#B9BFC2';
          }else{
            return '#CDD2E1';
          }
        })
        .attr('stroke-width', '1')
      }
  }

  deleteDocumentIcon(cell: any){
    let complianceAttributes: any = cell.attributes.compliance;
    d3.select(`#${complianceAttributes.cellId}
      .rappid__state-box .lf-icon-documentation`).remove();
  }

  getColorStroke(){
    if(this.skin ==='dark-blue'){
      return '#001978';
    }else if(this.skin ==='steel-blue' || this.skin ==='uk-steel-blue' ){
      return '#27344E';
    } else {
      return '#000000';
    }
  }
}
