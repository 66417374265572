import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientsManagement {
  clientList: any = [];
  allClients: any = [];
  clientId: any = null;

  setClientList(list: any){
    this.clientList = list;
  }

  getClientList(): any{
      return this.clientList;
  }

  setAllClients(list: any){
      this.allClients = list;
  }

  getAllClients(): any{
      return this.allClients;
  }

  setClientId(clientId: any){
      this.clientId = clientId;
  }

  getClientId(): any{
      return this.clientId;
  }

  reset(){
    this.clientList = [];
    this.allClients = [];
    this.clientId = null;
  }

}
