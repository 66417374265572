import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-file',
  templateUrl: './customize-field-file.component.html'
})
export class CustomizeFieldFileComponent implements OnChanges {
  skin: string = this.globalCfg.skin
  customizeFieldFile: any={
    tinyMceOptions: {},
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }
  @Input() field: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldFile;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue
      that.fieldCopy = cloneDeep(that.field);
      that.contentReportInfo.noEditable = that.field.noEditable
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  getEditorValue(event:any){
    this.customizeFieldFile.field.description = event.model;
  }

  itemSelected(e:any, item:any){
      this.customizeFieldFile.field[item] = e.newValue;
      this.modifyField();
  }

  cancelFieldText(){
    this.customizeFieldFile.field = cloneDeep(this.customizeFieldFile.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldFile.field
        }
    })
  }

  saveFieldText(){
      this.customizeFieldFile.fieldCopy = cloneDeep(this.customizeFieldFile.field);
      this.changeInField.emit({
          change:{
              type: 'save',
              field: this.customizeFieldFile.field
          }
      })
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldFile.field);
  }
  changeOptionOn(e:any, type:string){
    this.customizeFieldFile.field[type] = e.model
    this.modifyField();
  }
}


