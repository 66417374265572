import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class ConceptObjectResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }
    resolve(route: ActivatedRouteSnapshot) {
      let conceptObjectId = route.params['conceptObjectId'];
      if(!conceptObjectId){
          let logInfoStorage: any = sessionStorage.getItem('loginInfo');
          let loginInfo = JSON.parse(logInfoStorage.split('/'));
          if(loginInfo?.target?.targetType === 'ConceptObject'){
              conceptObjectId = loginInfo.target.targetId;
          }
      }
      return this.apiService.get('conceptobjects', conceptObjectId)
        .then((data:any)=>{
          return data
        })
    }

}
