<div ngbDropdown>
    <div class="from-to-calendar__custom-select-label"
        ngbDropdownToggle>
        <span class="from-to-calendar__header-complaints-text">{{customSelect.selection?.text}}</span>
        <i class="lf-icon-angle-down from-to-calendar__header-complaints-icon"></i>
    </div>
    <ul class="from-to-calendar__select-ops"
        ngbDropdownMenu>
        <li class="common__language-item"
            *ngFor="let option of customSelect.options"
            (click)="selectOpt(option)">
            <span-tooltip
                [placement]="'above'"
                [text]="option.text">
                </span-tooltip>
        </li>
    </ul>
</div>

