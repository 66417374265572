<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--expand': icamQueries.fullScreen,
        'predefined-templates__template-box--min-height':!icamQueries.notificationList?.length,
        'predefined-templates__template-box--max-height':icamQueries.notificationList?.length}"
    *ngIf="icamQueries.showTemplate">
    <div class="concept-legal-documentation__border-box"></div>
    <i class="predefined-templates__change-view-icon"
        (click)="maximizeScreen()"
        matTooltip="{{(icamQueries.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
        matTooltipPosition="above"
        matTooltipClass="above"
        [ngClass]="icamQueries.fullScreen? 'lf-icon-collapse-panel' : 'lf-icon-open-window'"></i>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-news box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text box-comunication-channel__block-text--comment">{{icamQueries.customHeaderTitle}}</p>
    <p class="concept-legal-documentation__title-template">{{icamQueries.historicTableHeaderCustom}}</p>
    <ul class="predefined-templates__template-list"
        [ngClass]="{'predefined-templates__template-list--expand': icamQueries.fullScreen,
            'predefined-templates__template-list--more-3-elements': (icamQueries.notificationList?.length > 3 && !icamQueries.fullScreen) || (icamQueries.notificationList?.length > 11 && icamQueries.fullScreen)}">
        <li class="predefined-templates__template-list-items"
            *ngFor="let notification of icamQueries.notificationList | afOrderBy:['scheduledTime', 'name']:true"
            [ngClass]="{'predefined-templates__template-list-items--none-hover': icamQueries.noPermissions}" >
                <i class="lf-icon-news predefined-templates__icon-col"></i>
                <div class="predefined-templates__text-list">
                    <span-tooltip [text]="notification.name"></span-tooltip>
                </div>
                <div class="concept-legal-documentation__cell-block icam-queries__date-col">
                    <span-tooltip [text]="notification.scheduledTime | convertDate: 'without-time'"></span-tooltip>
                </div>
                <div class="predefined-templates__template-menu icam-queries__template-menu"
                    [ngHide]="icamQueries.noPermissions">
                    <button class="icam-queries__button"
                        (click)="showNotification(notification)">
                        <i class="lf-icon-visible concept-legal-documentation__icon-eye"
                            matTooltip="{{'common.view' | afTranslate}}"
                            matTooltipPosition="left"
                            matTooltipClass="left">
                        </i>
                    </button>
                    <button class="icam-queries__button icam-queries__button-send"
                        (click)="reSendNotification(notification)">
                        <i class="lf-icon-send concept-legal-documentation__icon-eye"
                            matTooltip="{{'icam-queries.resend' | afTranslate}}"
                            matTooltipPosition="left"
                            matTooltipClass="left">
                        </i>
                    </button>
                </div>
        </li>
    </ul>
</div>
