<div class="public-link">
  <div class="public-link__block-info-concept public-link__block-info-concept"
      [ngClass]="publicLink.showInfo ? 'public-link__block-info-concept--show' : 'public-link__block-info-concept--noshow'">
      <i class="lf-icon-link public-link__icon-link" [ngClass]="{'public-link__icon-link--compiled': !publicLink.showInfo}"></i>
      <div class="public-link__text-info-block" [ngShow]="publicLink.showInfo">
          <h5 class="public-link__title-info" [afTranslate]="'public-link.what-is'"></h5>
          <p class="public-link__text-info" [afTranslate]="'public-link.info-one'"></p>
          <h5 class="public-link__title-info" [afTranslate]="'public-link.what-is-for'"></h5>
          <ul class="public-link__text-info">
              <span [afTranslate]="'public-link.uses'"></span>
              <li class="public-link__list-info-messy">
                  <span [afTranslate]="'public-link.use-one'"></span>
              </li>
              <li class="public-link__list-info-messy">
                  <span [afTranslate]="'public-link.use-two'"></span>
              </li>
          </ul>
          <h5 class="public-link__title-info" [afTranslate]="'public-link.configuration'"></h5>
          <ol class="public-link__text-info">
              <span [afTranslate]="'public-link.steps-recomendation'"></span>
              <li class="public-link__list-info" [afTranslate]="'public-link.recomendation-one'"></li>
              <li class="public-link__list-info" [afTranslate]="'public-link.recomendation-two'"></li>
              <li class="public-link__list-info" [afTranslate]="'public-link.recomendation-three'"></li>
          </ol>
          <h5 class="public-link__title-info" [afTranslate]="'public-link.link-activate'"></h5>
          <p  class="public-link__text-info" [afTranslate]="'public-link.info-two'"></p>
      </div>
  </div>
  <div class="public-link__block-total-steps"
    *ngIf="publicLink.step === 1"
      [ngClass]="{'public-link__block-total-steps--no-show': !publicLink.showInfo}">
      <div class="public-link__copy-link-block public-link__copy-link-block--init" [ngShow]="publicLink.published">
          <p class="common__ellipsis public-link__link-text">{{publicLink.url}}</p>
          <button class="common__button-border-none public-link__button-copy-link-init"
            [disabled]="!publicLink.url"
            ngbTooltip="{{'common.copied' | afTranslate}}"
            triggers="manual"
            #thisTooltip="ngbTooltip"
            (click)="copyText(thisTooltip, publicLink.url)">
            <i class="lf-icon-paste common__icon-button-underlined"></i>
            <span class="common__ellipsis common__text-button-underlined" [afTranslate]="'common.copy-link'"></span>
          </button>
          <button [ngShow]="skin !== 'icam-red'"
            class="common__button-border-none public-link__button-copy-link-init"
            [disabled]="!publicLink.url"
            (click)="generateQr(publicLink.url)">
            <i class="lf-icon-qr common__icon-button-underlined"></i>
            <span class="common__ellipsis common__text-button-underlined" [afTranslate]="'channel-configuration.generate-qr'"></span>
          </button>
      </div>
      <p class="public-link__title-block-steps" *ngIf="!publicLink.published"
          [afTranslate]="'public-link.title-steps'"></p>
      <i class="lf-icon-close public-link__icon-close" (click)="handleDismiss()"></i>
      <div class="public-link__block-steps-scroll" [ngClass]="{'public-link__block-steps-scroll--published': publicLink.published}">
          <div class="public-link__block-all-steps">
              <div class="public-link__block-step public-link__block-step--first"
                  [ngClass]="{
                    'public-link__block-step--error': publicLink.corporativeInformation.error,
                    'public-link__block-step--save': publicLink.corporativeInformation.configurated
                  }">
                  <div class="public-link__block-header-step"
                      [ngClass]="{'public-link__block-header-step--error': publicLink.corporativeInformation.error}">
                      <span [afTranslate]="'public-link.corporative-information'"></span>
                  </div>
                  <i class="lf-icon-colour public-link__icon-check"
                      [ngClass]="publicLink.corporativeInformation.configurated ? 'public-link__icon-check--saved' : 'public-link__icon-check--required'"></i>
                  <div class="public-link__block-body-step">
                      <p class="public-link__text-step" [afTranslate]="'public-link.corporative-information-text'"></p>
                      <p class="public-link__text-step-required" [afTranslate]="'customize.required'"></p>
                      <div class="public-link__text-step-info" [ngShow]="publicLink.corporativeInformation.publishConcept.global">
                          <i class="lf-icon-check public-link__margin-icon"></i>
                          <p [afTranslate]="'public-link.configure-global-applied'"></p>
                      </div>
                      <div class="public-link__text-step-info public-link__tooltip"
                          [ngShow]="!publicLink.corporativeInformation.publishConcept.global && publicLink.corporativeInformation.configurated">
                          <i class="lf-icon-check public-link__margin-icon"></i>
                          <p [afTranslate]="'public-link.configure-specific-applied'"></p>
                      </div>
                  </div>
                  <button class="common__button-border-none public-link__button-box-reports-edit" (click)="editCorporativeInformation()">
                      <i class="common__icon-button-underlined lf-icon-editable-form"></i>
                      <span class="common__text-button-underlined" [afTranslate]="'common.edit'"></span>
                  </button>
              </div>
              <div class="public-link__block-step"
                  [ngClass]="{
                    'public-link__block-step--save': concept.processId && concept.taskProcess,
                    'public-link__block-step--error': publicLink.errorHasProcessOps
                  }">
                  <div class="public-link__block-header-step" [ngClass]="{'public-link__block-header-step--error': publicLink.errorHasProcessOps}">
                      <span [afTranslate]="'public-link.process-management'"></span>
                  </div>
                  <i class="lf-icon-process-settings public-link__icon-check"
                      [ngClass]="concept.processId && concept.taskProcess ? 'public-link__icon-check--saved' : 'public-link__icon-check--required'"></i>
                  <div class="public-link__block-body-step">
                      <p class="public-link__text-step" [afTranslate]="'public-link.process-management-text'"></p>
                  </div>
                  <p class="public-link__text-step-required public-link__text-step-required--process" *ngIf="!concept.publicLite"
                      [afTranslate]="'customize.required'"></p>
                  <button class="common__button-border-none public-link__button-box-reports-edit" (click)="processManagement()">
                      <i class="common__icon-button-underlined lf-icon-editable-form"></i>
                      <span class="common__text-button-underlined" [afTranslate]="'common.edit'"></span>
                  </button>
              </div>
              <div class="public-link__block-step"
                  [ngClass]="{'public-link__block-step--save': publicLink.listDocument.length || publicLink.protectionData.length}">
                  <div class="public-link__block-header-step">
                      <span [afTranslate]="dataProtectionView ? 'public-link.data-protection-documentation' : 'public-link.reference-documents'"></span>
                  </div>
                  <i class="lf-icon-document public-link__icon-check"
                     [ngClass]="{'public-link__icon-check--saved': publicLink.listDocument.length || publicLink.protectionData.length}"></i>
                  <div class="public-link__block-body-step">
                      <p class="public-link__text-step"
                          [afTranslate]="dataProtectionView ? 'public-link.data-protection-documentation-text' : 'public-link.reference-documents-text'"></p>
                  </div>
                  <button class="common__button-border-none public-link__button-box-reports-edit"
                      (click)="editReferenceDocument()">
                      <i class="common__icon-button-underlined lf-icon-editable-form"></i>
                      <span class="common__text-button-underlined" [afTranslate]="'common.edit'"></span>
                  </button>
              </div>
          </div>
      </div>
      <div class="public-link__check-block">
          <field-type-switch [label]="'public-link.show-info'|afTranslate"
                               [checked]="publicLink.showInfo"
                               (callback)="changeShowInfo()">
          </field-type-switch>
      </div>
      <div class="public-link__buttons-block pull-right">
          <button type="reset" class="common__button common__button--clean" (click)="handleDismiss()" [afTranslate]="'common.cancel'"></button>
          <button type="submit" class="common__button common__button--active" (click)="publishConcept()">{{publicLink.publishButton}}</button>
      </div>
  </div>
  <div class="public-link__block-total-steps"
    *ngIf="publicLink.step === 2"
    [ngClass]="{'public-link__block-total-steps--no-show': !publicLink.showInfo}">
      <i class="lf-icon-close public-link__icon-close" (click)="handleDismiss()"></i>
      <div class="public-link__block-finish-steps">
          <div class="corporative-information-modal__steps-block">
              <div class ="public-link__block-link-description">
                  <i class="lf-icon-check-round public-link__icon-step-done"></i>
                  <p class="public-link__link-description">{{publicLink.finishDescription}}</p>
              </div>
              <div class="public-link__copy-link-block">
                  <p class="public-link__link-text public-link__link-text--other">{{publicLink.url}}</p>
              </div>
              <button class="common__button-border-none public-link__button-copy-link public-link__button-copy-link--no-first"
                  ngbTooltip="{{'common.copied' | afTranslate}}"
                  triggers="manual"
                  #thisTooltip="ngbTooltip"
                  (click)="copyText(thisTooltip, publicLink.url)">
                  <i class="common__icon-button-underlined lf-icon-paste"></i>
                  <span class="common__text-button-underlined" [afTranslate]="'common.copy-link'"></span>
              </button>
              <button class="common__button-border-none public-link__button-copy-link"
                  [ngShow]="skin !== 'icam-red'"
                  (click)="generateQr(publicLink.url)">
                  <i class="common__icon-button-underlined lf-icon-qr"></i>
                  <span class="common__text-button-underlined" [afTranslate]="'channel-configuration.generate-qr'"></span>
              </button>
              <div class="public-link__block-share-info">
                  <field-type-contacts-multiple *ngIf="publicLink.contactsField"
                      class="public-link__contacts-block"
                      [field]="publicLink.contactsField"
                      (sendContacts)="receiveContacts($event)">
                  </field-type-contacts-multiple>
                  <rich-editor class="public-link__textarea-link-block"
                      [tinyMceOptions]="publicLink.tinyMceOptions"
                      [modelText]="publicLink.messageLink"
                      (emitValue)="publicLink.messageLink = $event.model">
                  </rich-editor>
              </div>
              <div class="public-link__block-check-notification">
                  <field-type-switch [label]="'public-link.no-copy'|afTranslate"
                                       [checked]="publicLink.checkCopyNotification"
                                       (callback)="publicLink.checkCopyNotification = $event.value">
                  </field-type-switch>
              </div>
          </div>
      </div>
      <div class="public-link__buttons-block pull-right">
          <button type="submit" class="common__button common__button--active"
              (click)="sharePublicConcept()"
              [afTranslate]="'common.finish'"></button>
      </div>
  </div>
</div>
