<div class="concept">
    <concept-tab [list]="concept.conceptList"
        [activeConcept]="concept.activeConcept"
        [parentConcept]="concept.parentConcept"
        [hasOpenEvaluationTask]="concept.hasOpenEvaluationTask"
        [subMenu]="'general'"
        [fullMenu]="concept.fullMenu"
        (launchAction)="launchAction($event)"
        [task]="'concept'">
    </concept-tab>
    <div class="concept-tab__line">
        <div class="concept__content"
            [ngSwitch]="concept.activeConcept?.subTab">
            <concept-form-default *ngSwitchCase="'general'"
                [concept]="concept.activeConcept"
                [conceptList]="concept.conceptList"
                [lockedDataEvaluation]="concept.evaluation.lockedData"
                [parentConcept]="concept.parentConcept"
                [hasLinksToForms]="concept.conceptInfo.hasLinksToForms"
                [public]="concept.public"
                [published]="concept.conceptInfo.publicShared"
                (launchAction)="launchAction($event)">
            </concept-form-default>
            <documentation-list *ngSwitchCase="'documentation'"
                [concept]="concept.activeConcept"
                [public]="concept.public"
                (modifyConceptTab)="modifyConceptTab()"></documentation-list>
            <task-concept *ngSwitchCase="'task'"
                [concept]="concept.activeConcept"
                [taskTypeIdSelect]="concept.taskTypeIdSelect"
                [hasOpenEvaluationTask]="concept.hasOpenEvaluationTask"
                (modifyConceptTab)="modifyConceptTab()"
                [to]="concept.to"
                [shared]="concept.shared"
                [field]="concept.field"
                (launchAction)="launchAction($event)"></task-concept>
            <alert-concept *ngSwitchCase="'alerts'"
                [concept]="concept.activeConcept"
                [evaluation]="concept.evaluation"
                (modifyConceptTab)="modifyConceptTab()"></alert-concept>
            <historic-concept *ngSwitchCase="'historic'"
                [concept]="concept.activeConcept"
                (launchAction)="launchAction($event)"></historic-concept>
        </div>
        <div class="concept__go-back"
            [ngShow]="(concept.conceptList[0]?.active || concept.conceptList?.length === 1) && !(concept.conceptInfo?.productTypeId === 2 && !concept.parentConcept?.parentId)">
            <button class="concept__go-back-button">
                <div class="common__table-display">
                    <div class="common__table-cell-tooltip">
                        <i class="lf-icon-arrow-back concept__go-back-icon"
                            (click)="goBack()"></i>
                        <div class="concept__cell-block">
                            <p [id]="'return-concept-name-'+concept.conceptObjectId"
                                class="common__ellipsis"
                                matTooltip="{{concept.parentFolderName}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                [matTooltipDisabled]="showTooltip('concept-name'+ concept.conceptObjectId)"
                                (click)="goBack()">
                                <span [id]="'span-return-concept-name-'+concept.conceptObjectId">{{concept.parentFolderName}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </button>
        </div>
        <div class="concept__other-buttons"
            [ngShow]="concept.conceptList[0]?.active && concept.activeConcept?.showButtons"
            [ngClass]="{'float-end':concept.productTypeId === 4}">
            <button class="concept__no-evaluated"
                (click)="changeEvaluatedConcept('noEvaluated')"
                [ngHide]="concept.productTypeId === 4  || concept.conceptInfo?.productId === 'blanqueo'">
                <i class="concept__no-evaluated-icon"
                    [ngClass]="!concept.activeConcept?.evaluated?'lf-icon-check-empty':'lf-icon-box-inactive'"></i>
                <span class="concept__no-evaluated-text"
                    [afTranslate]="'common.not-evaluated'"></span>
            </button>
            <button class="concept__tab-button concept__tab-button--back"
                [ngShow]="concept.activeConcept?.showBackButton"
                (click)="changeEvaluatedConcept('back')"
                matTooltip="{{'concept.back-button-tooltip' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below"
                [matTooltipDisabled]="showTooltipTabButton('back')">
                <i class="lf-icon-double-arrow-left concept__tab-button-icon"></i>
                <span class="concept__tab-button-text" [afTranslate]="'common.behind'"></span>
            </button>
            <button class="concept__tab-button concept__tab-button--next"
                [ngShow]="concept.activeConcept?.showNextButton"
                (click)="concept.changeEvaluatedConcept('next')"
                matTooltip="{{'concept.next-button-tooltip' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below"
                [matTooltipDisabled]="showTooltipTabButton('next')">
                <span class="concept__tab-button-text"
                    [afTranslate]="'common.next'"></span>
                <i class="lf-icon-double-arrow-right concept__tab-button-icon"></i>
            </button>
        </div>
    </div>
</div>
