<div class="process-general-task">
    <div class="common__modal-header-block common__modal-header-block--margin-bottom ">
        <i class="lf-icon-task common__icon-header"></i>
        <span class="common__text-header">{{taskModal.title}}</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="process-general-task__body"
        *ngIf="taskModal.chargeManagersClassificator">
        <div class="process-general-task__field-wrapper">
            <field-type-text-simple [field]="taskModal.titleField"
                [preSelectItem]="taskModal.task.title"
                (itemSelected)="taskModal.task.title = $event.newValue">
            </field-type-text-simple>
        </div>
        <field-type-list-extended [field]="taskModal.managerClassificationField"
            [listValues]="taskModal.managerClassificationOptions"
            [preSelectItem]="taskModal.managerClassificationSelected"
            (itemSelected)="selectManagerClassificationType($event)">
        </field-type-list-extended>
        <div [ngSwitch]="taskModal.managerClassificationSelected">
            <div *ngSwitchCase="1">
                <div class="process-general-task__field-wrapper"
                    *ngFor="let state of taskModal.states">
                    <field-type-contacts-multiple [field]="state.responsibleField"
                        (sendContacts)="responsibleSelected($event, state)">
                    </field-type-contacts-multiple>
                </div>
            </div>
            <div *ngSwitchCase="2">
                <div class="process-general-task__separate-block">
                    <field-type-switch [label]="'process.general-task.add-confidential'| afTranslate"
                        [checked]="taskModal.task.addResponsibleToConfidentiality"
                        (callback)="taskModal.task.addResponsibleToConfidentiality = $event.value">
                    </field-type-switch>
                </div>
                <div class="subcontainer-multiple__table-duplicated process-general-task__container-border"
                    *ngFor="let assign of taskModal.assignList; let index = index; let first = first">
                    <div class="subcontainer-multiple__duplicated">
                        <div class="concept-form__subcontainer-block subcontainer-multiple__subcontainer-block process-general-task__container-top"
                            [id]="'group-subcontainer-'+index">
                            <div class="concept-list__cell-block">
                               <p [id]="'group-multiple-subcontainer-'+ index+'-'+assign.id"
                                  class="concept-form__subcontainer-text concept-form__subcontainer-text--ellipsis"
                                  matTooltip="{{assign.name}}"
                                  matTooltipPosition="below"
                                  matTooltipClass="below"
                                  [matTooltipDisabled]="showTooltip('group-multiple-subcontainer-'+ index+'-'+assign.id)">
                                <span [id]="'span-group-multiple-subcontainer-'+ index+'-'+assign.id">{{assign.name}}</span>
                              <p>
                            </div>
                            <div class="process-general-task__subcontainer-icons-block">
                                <i class="lf-icon-add-round concept-form__icon-subgroup"
                                    [ngShow]="first"
                                    (click)="duplicateSubcontainer()"
                                    matTooltip="{{'subcontainer-multiple.new-entry' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above"></i>
                                <i class="lf-icon-trash concept-form__icon-subgroup"
                                    [ngHide]="first"
                                    (click)="deleteDuplicated(assign, index)"
                                    matTooltip="{{'subcontainer-multiple.delete-entry' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above"></i>
                            </div>
                        </div>
                        <div class="concept-form__form-subcontainer-concept concept-form__form-subcontainer-concept--multiple subcontainer-multiple__correct-margin">
                            <field-type-contacts-multiple [field]="assign.responsibleField"
                                (sendContacts)="responsibleAssignSelected($event, assign)"
                                [listDisabled]="taskModal.usersAssign">
                            </field-type-contacts-multiple>
                            <field-type-list-multiple [field]="assign.fieldListData"
                                [listValues]="assign.listValues"
                                [preSelectItem]="assign.listValueId"
                                (itemSelected)="managerOptionsList($event, assign)">
                            </field-type-list-multiple>
                        </div>
                    </div>
                </div>
            </div>
            <div class="process-general-task__field-wrapper"
                *ngSwitchDefault>
                <field-type-contacts-multiple [field]="taskModal.responsibleField"
                    (sendContacts)="responsibleSelected($event, taskModal.task)">
                </field-type-contacts-multiple>
            </div>
        </div>
        <div class="process-general-task__separate-block">
            <field-type-switch class="process-general-task__separate-switch-element"
                    [label]="'process.general-task.daily-repeat-task-notification' | afTranslate"
                    [checked]="taskModal.task.dailyRepeatIdleTaskNotification"
                    (callback)="taskModal.task.dailyRepeatIdleTaskNotification = $event.value">
            </field-type-switch>
            <field-type-switch [label]="'process.general-task.establish-expiration-task'| afTranslate"
                    [checked]="taskModal.expirationTask"
                    (callback)="taskModal.expirationTask = $event.value">
            </field-type-switch>
        </div>

        <div *ngIf="taskModal.expirationTask"
            class="add-alert__periodicity-block ">
            <p class="establish-pattern__periodicity-block-title">
                <span [afTranslate]="'process.general-task.expiration-period'">_Plazo de vencimiento</span>
                <span class="common__text-required">*</span>
            </p>
            <div class="add-alert__options-notification-moment">
                <span class="add-alert__option-text"
                    [afTranslate]="'process.general-task.process-task-expire'">_La tarea de proceso vencerá por defecto </span>
                <input-number [preSelectValue]="taskModal.task.expirationUnits"
                    (selectedValue)="itemSelected($event, 'expirationUnits')">
                </input-number>
                <select-simple class="add-alert__select-simple-repeat"
                    [selectItem]="taskModal.task.expirationTimeUnit"
                    [listValues]="taskModal.frecuencyList"
                    (itemSelectedEmit)="taskModal.task.expirationTimeUnit = $event"
                    [widthUl]="130">
                </select-simple>
                <span class="add-alert__option-text"
                    [afTranslate]="'process.general-task.after-creation'">_después de su creación </span>
            </div>
        </div>
        <div class="process-general-task__field-wrapper process-general-task__field-wrapper--with-borders process-general-task__description">
            <span class="task-edit__text-description-title"
                    [afTranslate]="'common.description'">Descripción</span>
            <div>
            <rich-editor [tinyMceOptions]="taskModal.tinyMceOptions"
                [modelText]="taskModal.task.description"
                (emitValue)="taskModal.task.description = $event.model">
            </rich-editor>

            </div>
        </div>
        <div class="process-general-task__states-block">
            <span class="task-edit__text-description-title"
                [afTranslate]="'process.general-task.states-label'">
                Estados
            </span>
            <ul class="process-general-task__states-list">
                <li class="process-general-task__states-item"
                    *ngFor="let state of taskModal.states">{{state.name}}
                </li>
            </ul>
        </div>
    </div>
    <div class="process-general-task__footer">
        <button  type="reset"
            class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveTask()">{{taskModal.okButtonLabel}}</button>
    </div>
</div>
