import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'setTextColors'
})
export class SetTextColorsPipe implements PipeTransform {
  characters : any = {
    "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
    "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u",
    "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
    "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U" };
  regExpCharacters: any = /[áàéèíìóòúù]/ig;
  constructor(private sanitizer: DomSanitizer){}
  transform(item: any, searchText:string, classColor:string) {
      let textWords:any = this.uniqueSearchWords(searchText.replace(/[,.;\s]/g, '~').split('~'));
      let wordByWord = item.split(' ');
      textWords.forEach((textWord:string) => {
        let searchTextModify = this.plainWord(textWord);
        if(textWord){
          wordByWord.forEach((word: any, index: any) => {
            let wordToFind = this.plainWord(word);
            if(wordToFind.indexOf(searchTextModify) !== -1){
                wordByWord[index] = wordToFind.replace(wordToFind, this.replaceWord(searchTextModify, word))
            }
          });
        }
      });
      item = wordByWord.join(' ');
      return this.sanitizer.bypassSecurityTrustHtml(item.replace(new RegExp('<~>', 'g'), '<span class="' + classColor + '">').replace(new RegExp('</~>', 'g'), '</span>'));
  }

  plainWord(word: any){
      let that = this;
      return word.replace(this.regExpCharacters,function(e:any){ return that.characters[e]}).toLowerCase();
  }

  replaceWord(searchTextModify: any, word: any){
    var start = this.plainWord(word).toLowerCase().indexOf(searchTextModify);
    var wordLenght = searchTextModify.length
    return word.replace(word.substring(start,(wordLenght + start)), '<~>'+word.substring(start,(wordLenght + start))+'</~>')
  }

  uniqueSearchWords(list:any){
    let keys: any = [];
    let uniqueList: any = [];
    if(list){
      list.forEach((key:any) => {
        if (keys.indexOf(key) === -1) {
            keys.push(key);
            uniqueList.push(key);
        }
      });
    }
    return uniqueList;
  }
}
