import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBytes'
})
export class ConvertBytesPipe implements PipeTransform {

  transform(item: any,): string {
    let size = Math.round(item / 1024);
    return size > 1024? Math.round(item / 1048576) + ' MB' : Math.round(item / 1024) + ' KB';
  }

}
