import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptListParentService } from '../utils/concept-list-parent.service';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'concept-list-query',
  templateUrl: './concept-list-query.component.html'
})
export class ConceptListQueryComponent implements OnInit, OnChanges, OnDestroy {
  skin:string = this.globalCfg.skin;
  userPermissions:any;
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  folderId:any = this.route.snapshot.params['folderId'];
  conceptList:any = {
    textFilters: ['referencia', 'numColegiado', 'nombreColegiado'],
    titleWordFilter: '',
    order: false,
    openFocusTitle: false,
    openFocusAuthor: false,
    statePoll: false,
    showResultsReports: false,
    anySelect: false,
    dateFilter: 'creationDate',
    configuredTotal:{
      first: 1,
      last: 20
    },
    configPagination: {
      pageSize: 20,
      maxSize: 5
    },
    currentPage: 1,
    conceptList: [],
    countConceptSelect: [],
    conceptListFilter: [],
    creationDateFromDate: null,
    creationDateToDate: null,
    modificationDateFromDate: null,
    modificationDateToDate: null,
    selectedFilters: null,
    textSelectedFilters: null,
    referenciaQueryFilter: {
        name: this.translate.instant('common.reference'),
        width: '200px'
    },
    tipoFilter: { name: this.translate.instant('common.type') },
    stepFilter: { name: this.translate.instant('common.state') },
    managersFilter: { name: this.translate.instant('box-queries.manager') },
    userQueryFilter: {
        name: this.translate.instant('concept-list.user-number'),
        width: '200px'
    },
    nameQueryFilter: {
        name: this.translate.instant('common.name-surname'),
        width: '200px'
    },
    materiaFilter: { name: this.translate.instant('concept-list.matter') },
    esPrioritariaFilter: { name: this.translate.instant('concept-list.priority') },
    alertStateFilter: {
        name: this.translate.instant('common.alerts'),
        iconCircle: true
    },
    typeObjectId: 'conceptObjectId'
  };

  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private conceptListParent: ConceptListParentService
  ) {}

  @Input() conceptInfo:any;
  @Input() evaluation:any;
  @Input() toShow:any;
  @Input() modify:any;
  @Input() archivedAmount:any;
  @Output() evaluateConcept = new EventEmitter();
  @Output() launchAction = new EventEmitter();
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnInit(): void {
    this.conceptListParent.OnInit(this);
  }

  ngOnChanges(): void {
    this.conceptListParent.OnChanges(this);
  }

  filterComponentList(){
    this.conceptListParent.FilterComponentList(this)
  }

  selectTypeOperation(item:any, type:any){
    this.conceptListParent.SelectTypeOperation(item.source, type, this)
  }

  selectMultipleConcept(type:any, evaluatedConcept:any=null){
    this.conceptListParent.SelectMultipleConcept(type, evaluatedConcept, this)
  }

  launchActionOption(option:any, item:any){
    this.conceptListParent.LaunchActionOption(option, item, this)
  }

  openInNewTab(item:any){
    this.conceptListParent.OpenInNewTab(item, this);
  }

  openAssociatedEvaluation(concept:any){
    this.conceptListParent.OpenAssociatedEvaluation(concept, this);
  }

  openModalConfidential(instance:any){
    this.conceptListParent.OpenModalConfidential(instance, this)
  }

  orderBySearch(e:any, type:any){
    this.conceptListParent.OrderBySearch(e, type, this);
  }

  orderDate(type:any){
    this.conceptListParent.OrderDate(type, this);
  }

  openFocus(type:any){
    this.conceptList['openFocus'+type] = !this.conceptList['openFocus'+type];
  }

  archiveConcept(concept:any, archived:any){
    this.conceptListParent.ArchiveConcept(concept, archived, this)
  }

  viewArchived(){
    this.launchAction.emit({toShow:'archived', type: 'showElements'});
  }

  showReports(){
    this.launchAction.emit({toShow:'report', type: 'changeToShow'});
  }

  changeCalendar(dates:any, type:any){
    this.conceptListParent.ChangeCalendar(dates.dates, type, this);
    this.closeDropdown(type);
  }

  closeDropdown(type: any){
    this.conceptListParent.closeDropdown(type, this.dropdowns);
  }

  assignManager(concept:any){
    this.conceptListParent.AssignManager(concept, this)
  }

  changeState(evaluateConcept:any){
    this.conceptListParent.ChangeState(evaluateConcept, this)
  }

  ngOnDestroy(){
    this.conceptListParent.ngOnDestroy();
  }
}

