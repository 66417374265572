import { Component, Input, SimpleChanges, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import $ from 'jquery';

@Component({
  selector: 'field-type-logo',
  templateUrl: './field-type-logo.component.html'
})
export class FieldTypeLogoComponent implements OnChanges, OnInit {
  constructor(private commonUtilsService:CommonUtilsService,
    private modalService:ModalServiceService) { }

  @Input() field:any;
  @Input() preSelectItem:any;
  @Input() type:any;
  @Input() canAdmin:boolean;
  @Input() logoInfo:any;
  @Input() from:any;

  @Output() itemSelected = new EventEmitter();

  fieldTypeLogo:any = {};
  titleField:string;
  logo:any;
  logoName:string;
  showLogo:boolean;
  file:any;
  listLogos:any[];

  ngOnChanges(changes: SimpleChanges): void {
    let field = this.field;
    if(field){
      this.titleField = field.label;
      if(field.extendedLabel && field.extendedLabel !== ''){
        this.titleField = field.extendedLabel;
      }
    }
    this.configureLogoInfo();
    this.configureTypeFavicon();
  }

  configureLogoInfo(){
    let logoInfo = this.logoInfo;
    let preSelectItem = this.preSelectItem;
    if(logoInfo){
      this.canAdmin = logoInfo.canAdmin;
      if(preSelectItem && preSelectItem.logo){
        this.logo = this.commonUtilsService.setLogo(preSelectItem.logo, preSelectItem.logo)
        this.showLogo = true;
        this.logoName = 'Logo ' + logoInfo.name;
        if(logoInfo.name == null){
          this.logoName = 'Logo';
        }
      }else{
        this.logo = '';
        this.logoName = '';
        this.file = null;
      }
    }
  }

  configureTypeFavicon(){
    if(this.type ==='favicon'){
      if(this.preSelectItem){
        this.logo = this.commonUtilsService.setLogo(this.preSelectItem, this.preSelectItem)
        this.showLogo = true;
        this.logoName = 'Favicon ' + this.logoInfo.faviconName;
        if(this.logoInfo.faviconName == null){
          this.logoName = 'Favicon';
        }
      }else{
        this.logo = '';
        this.logoName = '';
        this.file = null;
      }
    }
  }

  ngOnInit(): void {
    if(this.field.evaluatedValue == null){
      this.field.evaluatedValue = {};
    }
  }

  openFileInput(){
    let that = this;
    if(this.type === 'favicon' || this.type === 'new' || (this.type === 'edit' && this.canAdmin)){
        setTimeout(function(){
            let item: any = $('#' + that.field.id);
            item.click();
        });
    }
  }

  uploadLogo(event:any) {
    if(event.target.files[0].type.indexOf('image') !== -1){
      let file = event.target.files[0];
      let fd = new FormData();
      fd.append('file', file);
      this.file = fd;
      this.convertToBase64(file);
    }
  }

  convertToBase64(file:any) {
    let that = this;
    let reader = new FileReader();
    this.logoName = file.name;
    reader.readAsDataURL(file);
    reader.onloadend = function () {
        let image:any = new Image();
        image.src = reader.result;
        that.logo = reader.result;
        that.preSelectItem = null;
        if(that.type !=='favicon'){
            that.openLogoModal();
        }else{
           that.configureFavicon(that.logo, 32, 32);
        }
    }
  }

  openLogoModal(){
    this.modalService.configureImage(this.logo, this.from, this.preSelectItem)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.itemSelected.emit({
            newValue:{
              files: this.setLogos(result.listLogos),
              listLogos: result.listLogos,
              img: this.logo,
              name: this.logoName
            }
          });
        }
      });
  }

  configureFavicon(src:any, width:any, height:any) {
    let canvas:any = document.createElement("canvas");
    let ctx:any = canvas.getContext("2d");
    let img:any = new Image();
    img.onload = () => {
        if((!width && !height)){
            width = img.width;
            height = img.height;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        let formData = new FormData();
        let blob = this.dataURIToBlob(canvas.toDataURL('image/png'))
        formData.append(this.logoName, blob, this.logoName+'.png');
        this.itemSelected.emit({
          newValue:{
            file: formData,
            img: canvas.toDataURL('image/png'),
            name: this.logoName
          }
        });
        this.logo = canvas.toDataURL('image/png');
    };
    img.src = src;
  }


  setLogos(listLogos:any){

    let formData = new FormData();
    this.listLogos = listLogos;
    for(let i = 0; i < this.listLogos.length; i++){
        let blob;
        let logo = this.listLogos[i];
        if(!(logo.width === 50 && logo.height === 50)){
            blob = this.dataURIToBlob(logo.html.toDataURL('image/png'))
            formData.append(this.logoName, blob, this.logoName+'-'+logo.width+'-'+logo.height+'.png');
        }
    }
    let blob;
    let logo = this.listLogos[1];
    if(logo.width === 50 && logo.height === 50){
        blob = this.dataURIToBlob(logo.html.toDataURL('image/png'))
        formData.append(this.logoName, blob, this.logoName+'-'+logo.width+'-'+logo.height+'.png');
    }
    return formData
  }

  dataURIToBlob(dataURI:any) {
    let splitDataURI = dataURI.split(',')
    let byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    let mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    let ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++){
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString })
  }
}
