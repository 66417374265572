<div class="folder"
    *ngIf="folder.showFolder">
    <ul class="folder__evaluation-folder-list "
        [ngShow]="folder.folder.children && folder.folder.children.length && productId !== 'taxwin'" >
        <li class="folder__evaluation-folder-item"
            *ngFor="let item of folder.folder.children"
            [ngHide]="!folder.showHidden && item.hidden"
            [ngClass]="{'folder__evaluation-folder-item--hidden':item.hidden}">
            <div class="common__table-display">
                <div class="common__table-cell-tooltip">
                    <i class="folder__evaluation-folder-icon"
                        [ngClass]="{'lf-icon-folder-user': item.isFront && !item.hidden,
                            'lf-icon-folder': !item.isFront && !item.hidden,
                            'lf-icon-invisible-folder folder__evaluation-folder-icon--hidden': item.hidden}"
                        (click)="launchAction($event, 'open', item)"></i>
                      <div [ngShow]="item.confidential"
                        class="folder__block-confident-folder"
                        ngbDropdown
                        [id]="'folder-confidential-'+item.folderId"
                        [autoClose]="'outside'">
                        <button class="evaluation-menu__confident-folder evaluation-menu__confident-folder--active-short"
                            [ngClass]="{'evaluation-menu__confident-folder--hidden': item.hidden}"
                            ngbDropdownToggle
                            matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"
                            (click)="$event.stopPropagation()">
                            <i class="lf-icon-lock"></i>
                        </button>
                        <list-in-dropdown *ngIf="item.confidentialList"
                            ngbDropdownMenu
                            role="menu"
                            class="dropdown-menu evaluation-menu__box-select-list"
                            [list]="item.confidentialList"
                            [type]="'confidential'"
                            (closeDropdownEmit)="closeDropdown('folder-confidential-'+item.folderId)"></list-in-dropdown>
                    </div>
                    <div class="folder__cell-block"
                        (click)="launchAction($event, 'open', item)">
                        <span-tooltip class="folder__col-text"
                          [text]="item.name"></span-tooltip>
                    </div>
                    <div class="evaluation-menu__dropdown-menu folder__folder-menu folder__folder-menu--folder-list"
                        [ngShow]="(folder.evaluationInfo.customizable || item.supportsConfidential)"
                        ngbDropdown
                        [id]="'folder-menulist-'+item.folderId"
                        (openChange)="openFolderMenu(item)">
                        <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--folder lf-icon-meatballs-menu"
                            ngbDropdownToggle>
                        </button>
                        <ul class="folder__concept-menu-list"
                            ngbDropdownMenu
                            role="menu"
                            [ngShow]="item.showMenu">
                            <li class="common__list-dropdown-option"
                                *ngFor="let option of item.menuList; let last = last">
                                <button class="common__button-option-list-dropdown"
                                    [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                    (click)="folderAction($event, option.action, item, true)">
                                    <i [class]="option.icon+' common__icon-option-list-dropdown'"></i>
                                    <span class="common__text-menu-option-dropdown"
                                      [afTranslate]="option.name"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="folder__block-bool-fold"
        [ngShow]="(folder.folder.concepts?.length && folder.folder.concepts?.length !== 1) && !folder.folder.channelView && skin !== 'steel-blue' && skin !== 'uk-steel-blue'">
        <field-type-switch [label]="'common.fold-unfold'|afTranslate"
                            [checked]="folder.openAll"
                            (callback)="toggleSwitchFoldUnfold($event.value)">
        </field-type-switch>
    </div>
    <div *ngIf="folder.folder.channelView">
        <div class="dashboard-panel__block-graph-reports-v2 dashboard-panel__block-graph-reports-v2--folder "
            *ngIf="skin != 'icam-red'">
            <dashboard-reports [evaluationList]="folder.filteredEvaluationList"
                [evaluationInfo]="folder.evaluationInfo"
                [productSelect]="productId"
                [userPermissions]="userPermissions"
                [from]="'folder'">
            </dashboard-reports>
        </div>
        <channel-configuration
            [concept]="folder.folder.concepts[0]"
            [evaluationInfo]="folder.evaluationInfo"
            (conceptAction)="conceptActionOn($event)">
        </channel-configuration>
    </div>
    <div class="folder__block-concept"
        *ngFor="let concept of folder.folder.concepts; let last = last"
        [ngHide]="!folder.showHidden && concept.hidden"
        [ngClass]="{'folder__block-concept--last-small': (concept.list && concept.list.length < 8 && concept.toShow==='evaluated' && last) || (concept.archivedAmount < 8 && concept.toShow==='archived' && last)}">

            <results-report *ngIf="concept.toShow ==='report'"
                [concept]="concept"
                (launchAction)="launchActionOn($event, concept)">
            </results-report>
            <div [ngHide]="concept.toShow ==='report'">
                <ul class="folder__evaluation-concept-list"
                    [ngClass]="{'folder__evaluation-concept-list--archived folder__evaluation-folder-list--archived':concept.toShow==='archived'}">
                    <li class="folder__evaluation-concept-item ">
                        <div class="common__table-display"
                            [ngHide]="concept.toShow==='archived'">
                            <div class="common__table-cell-tooltip">
                                <i class="folder__evaluation-concept-arrow float-start"
                                    [ngHide]="skin === 'steel-blue'"
                                    [ngClass]="{'lf-icon-angle-up':concept.open,
                                        'lf-icon-angle-down': !concept.open,
                                        'folder__evaluation-concept-arrow--hidden': concept.hidden}"
                                    (click)="concept.open = !concept.open"></i>
                                <i class="folder__icon-document"
                                    [ngClass]="{'lf-icon-template': !concept.isFront && !concept.hidden,
                                        'lf-icon-template-user': concept.isFront && !concept.hidden,
                                        'lf-icon-invisible-document folder__icon-document--hidden':concept.hidden}"></i>
                                <div class="folder__block-title-concept">
                                  <span-tooltip class="common__ellipsis folder__evaluation-concept-title"
                                                [ngClass]="{'folder__evaluation-concept-title--hidden': concept.hidden}"
                                                [text]="concept.title"></span-tooltip>
                                  <i *ngIf="skin === 'icam-red' && concept.namedKey === 'Consulta'"
                                     class="lf-icon-info-full folder__concept-title-icon"
                                     [ngbTooltip]="icamQueriesTooltip"
                                     placement="top"
                                     container="body"></i>
                                  <ng-template #icamQueriesTooltip>
                                    <p [innerHTML]="folder.textIcamIcon"></p>
                                  </ng-template>
                                </div>
                                <div class="evaluation-menu__dropdown-menu folder__folder-menu folder__folder-menu--concept-list"
                                    ngbDropdown
                                    [id]="'concept-menulist-'+concept.conceptId"
                                    [ngShow]="userPermissions === 'all'"
                                    (openChange)="openConceptMenu(concept)">
                                    <button class="lf-icon-meatballs-menu evaluation-menu__list-menu-button evaluation-menu__list-menu-button--folder"
                                        ngbDropdownToggle>
                                    </button>
                                    <ul class="folder__concept-menu-list dropdown-menu"
                                        ngbDropdownMenu
                                        role="menu"
                                        [ngShow]="concept.showMenu">
                                        <li class="common__list-dropdown-option"
                                            *ngFor="let option of concept.menuList; let last = last">
                                            <button class="common__button-option-list-dropdown"
                                                [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                                (click)="conceptMenuListAction(option.action, concept)">
                                                <i [class]="' '+option.icon+' common__icon-option-list-dropdown'"></i>
                                                <span class="common__text-menu-option-dropdown"
                                                    [afTranslate]="option.name"></span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <i class="folder__evaluation-concept-file float-end"
                                    [ngShow]="!concept.hidden && (userPermissions !== 'none' || folder.preview) && !concept.poll && !concept.singleInstance && concept.namedKey !=='Consulta'"
                                    [ngClass]="{'lf-icon-add-round': !folder.preview,
                                        'lf-icon-visible': folder.preview}"
                                    (click)="newEvaluatedConcept(concept)"
                                    matTooltip="{{folder.description}}"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"></i>
                                <i class="lf-icon-questionnaire folder__evaluation-concept-file folder__evaluation-concept-file--poll float-end"
                                    [ngShow]="concept.poll"></i>
                                <i class="lf-icon-refresh folder__evaluation-concept-file float-end"
                                    [ngShow]="concept.namedKey ==='Consulta'"
                                    (click)="reloadAll(concept)"></i>
                                <p class="folder__square-item  float-end"
                                    [ngClass]="{'folder__square-item--hidden': concept.hidden}">
                                    {{concept.amountEvaluatedConcept}}</p>
                                <p class="folder__title-evaluated float-end"
                                    [ngShow]="!concept.poll">{{skin==='icam-red'?concept.titleEvaluated:folder.titleEvaluated}}</p>
                                <div class="folder__title-right"
                                    [ngShow]="concept.poll">
                                    <p class="folder__title-evaluated folder__title-evaluated--surveyed float-end"
                                        [afTranslate]="'folder.num-respondents'">Nº DE ENCUESTADOS</p>
                                    <p class="folder__title-evaluated  folder__title-subtitle  folder__title-evaluated--surveyed float-end"
                                        [ngClass]="{'folder__title-subtitle--expi-date-valid' : concept.onTime,
                                            'folder__title-subtitle--expi-date-pass': !concept.onTime }"
                                        [ngShow]="concept.pollExpirationDate">
                                        <i class="lf-icon-calendar-cross folder__icon-calendar-cross"></i>
                                        <span class="folder__text-date-poll">{{concept.pollExpirationDate | convertDate:'without-time'}}</span>
                                    </p>
                                    <p class="folder__title-evaluated folder__title-subtitle folder__title-evaluated--surveyed folder__title-subtitle--no-expi-date float-end"
                                        [ngShow]="!concept.pollExpirationDate">
                                        <i class="lf-icon-calendar-cross folder__icon-calendar-cross"></i>
                                        <span class="folder__text-date-poll"
                                            [afTranslate]="'common.no-deadline'">sin fecha limite</span>
                                    </p>
                                </div>
                                <div [ngShow]="skin === 'icam-red' && concept.publicLite">
                                    <button class="folder__concept-header-button-icam float-end"
                                            (click)="togglePublicState(concept)"
                                            [id]="'icam-header-button-'+concept.conceptId"
                                            matTooltip="{{(concept.publicShared ? 'common.published' : 'common.unpublished') | afTranslate}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above"
                                            [matTooltipDisabled]="!concept.openTooltipPublished">
                                        <span>{{(concept.publicShared ? 'common.unpublish' : 'common.publish') | afTranslate}}</span>
                                        <i class="lf-icon-link"></i>
                                    </button>
                                </div>
                                <div *ngIf="skin === 'icam-red' && (concept.namedKey==='Usuario' || concept.namedKey==='Consulta')">
                                    <button class="folder__concept-header-button-icam pull-right"
                                        [ngClass]="{'folder__concept-header-button-icam--disabled':!folder.activeCleanFilters}"
                                        (click)="cleanFilters()"
                                        [disabled]="!folder.activeCleanFilters">
                                        <span [afTranslate]="'folder.clean-filters'"></span>
                                        <i class="lf-icon-filter-applied"></i>
                                    </button>
                                </div>
                                <i [ngShow]="!concept.hidden && (concept.allowPublicShare || concept.anonymousAccess) && userPermissions === 'all' && !concept.singleInstance && skin === 'dark-blue' && !folder.folder.channelView"
                                    (click)="conceptAction('publishConcept', concept)"
                                    [id]="'tooltip-link-'+concept.conceptId"
                                    class="lf-icon-link folder__evaluation-concept-publish float-end"
                                    [ngClass]="{'folder__evaluation-concept-publish--published': concept.publicShared}"
                                    ngbTooltip="{{getTooltipText(concept)}}"
                                    placement="top">
                                </i>
                                <i class="lf-icon-process-settings folder__evaluation-concept-management float-end"
                                    [ngClass]="{'folder__evaluation-concept-management--published': concept.hasProcessOps}"
                                    [ngShow]="userPermissions === 'all' && !concept.poll && (skin === 'dark-blue' || skin === 'gm-law') && !folder.folder.channelView"
                                    matTooltip="{{'common.manage-proces' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above"
                                    (click)="showProcessOpsModal(concept)"></i>
                                <!-- <i class="lf-icon-periodic-instance folder__evaluation-concept-publish folder__evaluation-concept-publish--published folder__evaluation-concept-publish--establish float-end"
                                    [ngShow]="concept.repeatPattern?.repeatPatternId"
                                    [id]="'periodic-instance-'+concept.conceptId"
                                    [ngbTooltip]="periodicContent"
                                    placement="top"
                                    (click)="setOrModifyPattern(concept)"></i> -->
                                <ng-template #periodicContent>
                                  <p [innerHTML]="extractTooltip(concept)"></p>
                                </ng-template>
                            </div>
                        </div>
                        <div [ngShow]="concept.toShow==='archived'">
                            <button class="folder__back-button float-start"
                                (click)="showElements('evaluated', concept)">
                                <i class="lf-icon-arrow-back folder__arrow-back-icon"></i>
                                <span class="folder__arrow-back-text"
                                    [afTranslate]="'concept-list.archive-exit'">
                                </span>
                            </button>
                            <button class="float-end lf-icon-archive-empty folder__back-button folder__back-button--archived"
                                (click)="deleteArchived(concept)"
                                [ngHide]="userPermissions ==='none' || skin === 'icam-red' || concept.restrictedDelete || !concept.archived?.length"
                                matTooltip="{{'folder.empty-file' | afTranslate}}"
                                matTooltipPosition="below"
                                matTooltipClass="below">
                            </button>
                            <p [ngShow]="concept.archived?.length"
                                class="folder__concept-archived-amount float-end">
                                <span [afTranslate]="concept.archived?.length > 1?'concept-list.there-are':'concept-list.there-is'"></span>
                                <span class="span__auto-ajust">{{concept.archived?.length}}</span>
                                <span class="span__auto-ajust" [afTranslate]="concept.archived?.length > 1?'concept-list.concepts-archives':'concept-list.concept-archive'"></span>
                            </p>
                        </div>
                    </li>
                </ul>

                <div class="concept-list__table-config "
                    [ngClass]="{'concept-list__table-config--archived': concept.toShow==='archived'}"
                    [ngShow]="concept.open">
                    <div [ngShow]="(concept.list?.length || concept.archivedAmount) && !concept.hidden"
                        [ngSwitch]="concept.namedKey">
                      <concept-list-query *ngSwitchCase="'Consulta'"
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-query>
                      <concept-list-publication *ngSwitchCase="'Publicacion'"
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-publication>
                      <concept-list-digitalizacion *ngSwitchCase="'Digitalizacion'"
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-digitalizacion>
                      <concept-list-collegiate *ngSwitchCase="'Usuario'"
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-collegiate>
                      <concept-list-poll *ngSwitchCase="'Encuesta'"
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-poll>
                      <concept-list-default *ngSwitchDefault
                        [conceptInfo]="concept"
                        [evaluation]="folder.evaluationInfo"
                        [toShow]="concept.toShow"
                        [modify]="concept.modify"
                        [archivedAmount]="concept.archivedAmount"
                        (launchAction)="launchActionOn($event, concept)"
                        (evaluateConcept)="evaluateConcept(concept, $event.evaluateConcept)"></concept-list-default>
                    </div>
                    <div class="folder__no-concept-block"
                        [ngClass]="{'folder__no-concept-block--with-archived':!concept.list?.length && concept.archivedAmount}"
                        [ngShow]="(!concept.list?.length && !concept.showArchived) || (!concept.archivedAmount && concept.showArchived)">
                        <p class="folder__no-concept-text"
                            [afTranslate]="concept.poll?'folder.no-completed-poll':concept.showArchived?'folder.no-archived-concepts':'folder.no-evaluated-concepts'">No hay conceptos evaluados</p>
                    </div>
                </div>
            </div>

    </div>
</div>
