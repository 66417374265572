import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-onefile',
  templateUrl: './field-type-onefile.component.html'
})
export class FieldTypeOnefileComponent implements OnChanges {
  fieldTypeOnefile:any = {}
  constructor(private commonUtilsService: CommonUtilsService) { }

  @Input()type: any = null;
  @Input()preSelectItem: any = null;
  @Input()fileInfo: any = null;
  @Input()field: any = null;

  @Output() itemSelected = new EventEmitter();
  @Output() renameEmit = new EventEmitter();

  ngOnChanges(){
    this.fieldTypeOnefile.fileInfo = this.fileInfo;
    this.fieldTypeOnefile.file = this.preSelectItem? this.preSelectItem: null;
    if(this.field) {
      this.fieldTypeOnefile.titleField = this.field.extendedLabel && this.field.extendedLabel !== ''? this.field.extendedLabel: this.field.label;
      if (this.field.id) {
        this.fieldTypeOnefile.fieldId = this.commonUtilsService.createId();
      }
      this.configureWidthField();
    }
  }

  configureWidthField(){
      let icons = 1;
      this.fieldTypeOnefile.classWidthField = 'common__label-block--one-icon';
      if(this.field.allowRename){
          icons++;
          this.fieldTypeOnefile.classWidthField = 'common__label-block--two-icon';
      }
      if(this.field.evaluatedValue?.hasReferences && !this.field.poll){
          icons++;
          this.fieldTypeOnefile.classWidthField = icons === 3?'common__label-block--three-icon' : 'common__label-block--two-icon';
      }
  }

  labelClicked(e:any) {
      if (e.target.tagName !== 'I' && e.target.tagName !== 'INPUT') {
          e.preventDefault();
      }
  }

  fileChanged(e:any) {
    let target = e.target as HTMLInputElement;
    let file:any = target.files as FileList;
    this.fileInfo.name = file[0].name;
    this.itemSelected.emit({
        newValue: file[0]
    });
  }

  rename(e:any){
    this.renameEmit.emit({event:e});
  }
}
