<div class="customize-field-text">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title">{{customizeFieldTemplate.titleDescription}}</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldTemplate.tinyMceOptions"
      [modelText]="customizeFieldTemplate.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldTemplate.fieldTemplates"
            [preSelectItem]="customizeFieldTemplate.field.fieldAttributes.documentId"
            [listValues]="templatesList"
            (itemSelected)="itemSelected($event, 'documentId')">
        </field-type-list-simple>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept?.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <div class="customize-field-list__error-block"
        [ngShow]="customizeFieldTemplate.showError">
        <i class="lf-icon-warning establish-pattern__icon-error"></i>
        <p class="establish-pattern__text-error"
            [afTranslate]="'customize-field.message-required-template'"></p>
        <i class="lf-icon-close customize-field-list__icon-close-header"
            (click)="customizeFieldTemplate.showError = !customizeFieldTemplate.showError"></i>
    </div>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.cancel'">Cancelar </button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.save'">Guardar </button>
    </div>
</div>
