import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'participants-list',
  templateUrl: './participants-list.component.html'
})

export class ParticipantsListComponent implements OnChanges {
  skin: any = this.globalCfg.skin;
  participantsList:any = {};

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService) { }

  @Input()  participants: any = null;
  @Input()  type: string = '';
  @Input()  notificated: any = null;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  ngOnChanges(): void {
    this.participantsList.idFirstParticipant = this.commonUtilsService.createId();
    if(this.participants && this.participants.length){
      this.participantsList.participants = this.participants;
      if(this.type === 'roles' || this.type === 'organizations'){
          this.configureList();
      }
    }
  }

  configureList(){
    this.participantsList.participants.forEach((participant:any) => {
      participant.fullName = participant.name;
    });
  }

  showTooltip(id:any):boolean{
    if(this.participantsList.participants[0]?.isDeleted){
      return true;
    }
    return this.commonUtilsService.showTooltip(id);
  }

  closeDropdown(){
    this.dropdown.close();
  }
}
