import { Component, Input, Output, EventEmitter, OnChanges, OnInit} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import moment from 'moment';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'input-date',
  templateUrl: './input-date.component.html'
})
export class InputDateComponent implements OnInit, OnChanges {
  isLanding: boolean = this.customLanding.isLanding();
  showTime: boolean = false;
  inputDate: any = {
    skin: this.globalCfg.skin,
    date: [],
    dotSeparation: true,
    newDate: '',
    inputId: this.commonUtilsService.createId()
  };
  @Input() input: any;
  @Input() active: any;
  @Input() disabled: any;
  @Input() field: any;
  @Input() eraseButton: any;

  @Output() output = new EventEmitter();

  constructor(private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private broadcast:BroadcastService,
    private commonUtilsService: CommonUtilsService
  ) { }

  ngOnChanges() {
    let that = this.inputDate;
    if (this.eraseButton === 'show') {
      that.eraseButtonShow = true;
    }

    if (this.input && moment(this.input).isValid()) {
      let date = moment(this.input).format('DD-MM-YYYY HH:mm').split(' ');
      let dateFirst = date[0].split('-');
      let dateSecond = date[1].split(':')
      that.day = dateFirst[0];
      that.month = dateFirst[1];
      that.year = dateFirst[2];
      that.hours = dateSecond[0];
      that.minutes = dateSecond[1];
      that.error = false;
    } else if (!this.input) {
      this.configureDefaultDate();
    }

    this.fitNumber('month');
  }

  ngOnInit() {
    let that = this.inputDate;
    that.sequentialView = this.field && this.field.poll && !this.field.noEditable && this.field.sequentialView && this.isLanding;
    this.showTime = this.field?.showTime?true:false;
    if (!this.input) {
      this.configureDefaultDate();
    }
    this.fitNumber('month');
    this.watchResetDate();
  }

  fitNumber(number:any) {
    let that = this.inputDate;
    if (that[number]) {
      if (that[number].length === 1) {
        that[number + 'OneDigit'] = true;
        that[number + 'TwoDigits'] = false;
      } else if (that[number].length === 2 && that[number] != this.translate.instant('input-date.' + number)) {
        that[number + 'OneDigit'] = false;
        that[number + 'TwoDigits'] = true;
      } else {
        that[number + 'OneDigit'] = false;
        that[number + 'TwoDigits'] = false;
      }
    }
    // that.yearDigits = (that.year != this.translate.instant('input-date.years'));
  }

  watchResetDate() {
    let that = this.inputDate;
    this.broadcast.broadcast('resetDate' + this.inputDate.inputId, (event:any, data:any) => {
      that.newDate = '';
      this.configureDefaultDate();
    });
  }

  // watchResetDate() {
  //   let that = this.inputDate;
  //   this.broadcast.broadcast('resetDate' + this.inputDate.inputId, (event:any, data:any) => {
  //     that.newDate = '';
  //     this.configureDefaultDate();
  //   });
  // }

  configureDefaultDate(){
    let that = this.inputDate;
    that.day = this.translate.instant('input-date.day');
    that.month = this.translate.instant('input-date.month');
    that.year = this.translate.instant('input-date.years');
    that.hours = '00';
    that.minutes = '00';
  }

  validateDate(type:string, e:any=null){
    let that = this.inputDate;
    e.stopPropagation();
    let validateDay = that.day.length === 2 && that.day !== this.translate.instant('input-date.day');
    let validateMonth = that.month.length === 2 && that.month !== this.translate.instant('input-date.month');
    let validateYear = that.year.length === 4 && that.year !== this.translate.instant('input-date.years');
    let isValid = this.checkIsValidDate();
    // let isValid = moment(that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes).isValid();
    switch (type){
        case 'day':
            if(that.day.length === 2){
                that.error = false;
                if(parseInt(that.day) > 31){
                    that.day = '31';
                }
                if(validateDay && validateMonth && validateYear){
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    that.error =  this.checkDate();
                    if(this.showTime && !that.error){
                        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
                    }
                    if(that.error){
                        this.output.emit({value:null, isValid:isValid, e:e});
                    }else{
                        if(this.showTime){
                            that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                        }
                        this.output.emit({value:that.newDate, isValid:isValid, e:e});
                    }
                }else if(!(validateDay && validateMonth && validateYear)){
                    this.output.emit({value:null, isValid:isValid, e:e})
                }
                if (that.day !== this.translate.instant('input-date.day')) {
                    this.fitNumber('day');
                    setTimeout(() => {
                        $('#' + this.inputDate.inputId + '-month').select().focus();
                    });
                } else {
                    that.dayOneDigit = false;
                    that.dayTwoDigits = false;
                }

                if((that.month === this.translate.instant('input-date.month')) || (that.year === this.translate.instant('input-date.years'))){
                    that.error = false;
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    if(this.showTime){
                        that.newDate = that.month+'/'+that.day+'/'+that.year+' '+that.hours+':'+that.minutes;
                    }
                }
            }
            break;
        case 'month':
            that.error = false;
            if(that.month.length === 2){
                if(parseInt(that.month) > 12){
                    that.month = '12';
                }
                if(validateDay && validateMonth && validateYear){
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    that.error = this.checkDate();
                    if(this.showTime && !that.error){
                        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
                    }
                    if(that.error){
                        this.output.emit({value:null, isValid:isValid, e:e});
                    }else{
                        if(this.showTime){
                            that.newDate = that.month+'/'+that.day+'/'+that.year+' '+that.hours+':'+that.minutes;
                        }
                        this.output.emit({value:that.newDate, isValid:isValid, e:e})
                    }

                }else if(!(validateDay && validateMonth && validateYear)){
                    this.output.emit({value:null, isValid:isValid, e:e})
                }
                if (that.month !== this.translate.instant('input-date.month')) {
                    this.fitNumber('month');
                    setTimeout(() => {
                        $('#' + this.inputDate.inputId + '-year').select().focus();
                    });
                } else {
                    that.monthOneDigit = false;
                    that.monthTwoDigits = false;
                }
                if((that.day === this.translate.instant('input-date.day')) || (that.year === this.translate.instant('input-date.years'))){
                    that.error = false;
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    if(this.showTime){
                        that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                    }
                }
            }
            break;
        case 'year':
            that.error = false;
            if(that.year.length === 4){
                if(that.year.length === 4 && parseInt(that.year) > 2100){
                    that.year = '2100';
                }else if(that.year.length === 4 && parseInt(that.year) < 1900){
                    that.year = '1900';
                }
                if(validateDay && validateMonth && validateYear){
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    that.error =  this.checkDate();
                    if(this.showTime && !that.error){
                        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
                    }
                    if(that.error){
                        this.output.emit({value:null, isValid:isValid, e:e});
                    }else{
                        if(this.showTime){
                            that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                        }
                        this.output.emit({value:that.newDate, isValid:isValid, e:e})
                    }
                    if((that.day === this.translate.instant('input-date.day')) || (that.month === this.translate.instant('input-date.month'))){
                        that.error = false;
                        that.newDate = that.month + '/' + that.day + '/' + that.year;
                        if(this.showTime){
                            that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                        }
                    }
                    if (that.year.length === 4 && that.year !== this.translate.instant('input-date.year') && this.showTime) {
                        setTimeout(() => {
                            $('#' + this.inputDate.inputId + '-hours').select().focus();
                        });
                    }
                }else if(!(validateDay && validateMonth && validateYear)){
                    this.output.emit({value:null, isValid:isValid, e:e})
                }
                // that.yearDigits = (that.year != this.translate.instant('input-date.years'));
            }
            break;
        case 'hours':
            if(that.hours.length === 2){
                if(parseInt(that.hours) > 23){
                    that.hours = '23';
                }else if(validateDay && validateMonth && validateYear && that.hours.length === 2 && that.minutes.length === 2){
                    that.newDate = that.month + '/' + that.day + '/' + that.year;
                    that.error = this.checkDate();
                    if(!that.error){
                        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
                    }
                    if(that.error){
                        this.output.emit({value:null, isValid:isValid, e:e});
                    }else{
                        if(this.showTime){
                            that.newDate = that.month+'/'+that.day+'/'+that.year+' '+that.hours+':'+that.minutes;
                        }
                        this.output.emit({value:that.newDate, isValid:isValid, e:e});
                    }
                    if((that.day === this.translate.instant('input-date.day')) || (that.month === this.translate.instant('input-date.month')) || (that.year === this.translate.instant('input-date.years'))){
                        that.error = false;
                        that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                    }
                }else if(!(validateDay && validateMonth && validateYear && that.hours.length === 2 && that.minutes.length === 2)){
                    this.output.emit({value:null, isValid:isValid, e:e});
                }
                setTimeout(() => {
                    $('#' + this.inputDate.inputId + '-minutes').select().focus();
                });
            }
            break;
        case 'minutes':
            if(that.minutes.length === 2){
                if(parseInt(that.minutes) > 59){
                    that.minutes = '59';
                }else if(validateDay && validateMonth && validateYear && that.hours.length === 2  && that.minutes.length === 2){
                    that.newDate = that.newDate = that.month + '/' + that.day + '/' + that.year;
                    that.error = this.checkDate();
                    if(!that.error){
                        that.error = !((parseInt(that.hours) >= 0 && parseInt(that.hours) <= 24) && (parseInt(that.minutes) >= 0 && parseInt(that.minutes) <= 59));
                    }
                    if(that.error){
                        this.output.emit({value:null, isValid:isValid, e:e});
                    }else{
                        if(this.showTime){
                            that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                        }
                        this.output.emit({value:that.newDate, isValid:isValid, e:e});
                    }
                    if((that.day === this.translate.instant('input-date.day')) || (that.month === this.translate.instant('input-date.month')) || (that.year === this.translate.instant('input-date.years'))){
                        that.error = false;
                        that.newDate = that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes;
                    }
                }else if(!(validateDay && validateMonth && validateYear && that.hours.length === 2 && that.minutes.length === 2)){
                    this.output.emit({value:null, isValid:isValid, e:e});
                }
            }
            break;
    }
  }

  checkIsValidDate() {
    let that = this.inputDate;
    let isValid = moment(that.month + '/' + that.day + '/' + that.year + ' ' + that.hours + ':' + that.minutes).isValid();
    let isValidDateLockType: boolean = true;
    if(isValid && this.field?.dateLockType){
      let todayDate = moment().format('MM-DD-YYYY');
      let selectedDate = that.month+'-'+that.day+'-'+that.year;
      switch (this.field.dateLockType) {
          case 1:
              isValidDateLockType = !(moment(selectedDate).isBefore(todayDate));
              break;
          case 2:
              isValidDateLockType = !(moment(selectedDate).isBefore(todayDate) || (moment(selectedDate).isSame(todayDate)));
              break;
          case 3:
              isValidDateLockType = !(moment(selectedDate).isAfter(todayDate));
              break;
          case 4:
              isValidDateLockType = !(moment(selectedDate).isAfter(todayDate) || (moment(selectedDate).isSame(todayDate)));
              break;
          default:
              isValidDateLockType = true;
              break;
      }
    }
    return isValid && isValidDateLockType;
  }

  checkDate(){
    let that = this.inputDate;
    if(that.month === '02' && that.day === '29'){
        return !(this.isLeap(that.year));
    }else{
        return !(/^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/.test(that.month + '/' + that.day + '/' + that.year) && moment(that.month + '/' + that.day + '/' + that.year).isValid() )
    }
  }

  isLeap(year:any){
    return (parseInt(year) % 4 === 0 && (parseInt(year) % 100 !== 0 || parseInt(year) % 400 === 0 ));
  }

  select(type:string) {
    this.fitNumber('month');
    setTimeout(() => {
        $('#' + this.inputDate.inputId + '-' + type).select().focus();
    });
  }

  eraseDate(){
    let that = this.inputDate;
    if(!this.disabled){
        that.day = this.translate.instant('input-date.day');
        that.month = this.translate.instant('input-date.month');
        that.year = this.translate.instant('input-date.years');
        that.hours = '00';
        that.minutes = '00';
        that.error = false;
        this.output.emit({ value: null, isValid:true, e:null });
    }
  }
}
