<div class="field-type-template">
  <div *ngIf="!isLanding"
      class="common__input-block common__input-block--fixed"
      [ngClass]="{'common__show-alert--fixed': fieldTypeTemplate.field.error,
          'common__input-no-editable':fieldTypeTemplate.field.noEditable}"
      [attr.disabled]="fieldTypeTemplate.field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeTemplate.field.error,
                      'common__input-text--no-editable':fieldTypeTemplate.field.noEditable}">{{fieldTypeTemplate.field.label}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeTemplate.field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="fieldTypeTemplate.field.references.length && !fieldTypeTemplate.field.noEditable">
              <tooltip-law [references]="fieldTypeTemplate.field.references"
                  [field]="fieldTypeTemplate.field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__container">
          <p class="field-type-template__text-description"
              [innerHTML]="fieldTypeTemplate.field.description"></p>
          <div class="field-type-template__block-button">
              <button class="common__button-border-none"
                  [ngClass]="{'common__button-border-none--error': fieldTypeTemplate.field.error}"
                  [disabled]="fieldTypeTemplate.field.noEditable"
                  (click)="generateDocument()">
                  <i class="lf-icon-textbooks common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'field-type-template.generate-document'">_Generar documento</span>
              </button>
          </div>
          <div class="field-type-template__block-list-document"
              [ngShow]="fieldTypeTemplate.field.evaluatedValue?.documents?.length">
              <div class="field-type-template__element-list-document"
                  *ngFor="let document of fieldTypeTemplate.field.evaluatedValue?.documents">
                  <div class="field-type-template__table-cell-document">
                      <span class="field-type-template__element-list-title">
                          <span [afTranslate]="'field-type-template.generated-document'">_Documento generado</span>:
                      </span>
                      <span>{{document.name}}</span>
                  </div>
                  <div class="field-type-template__table-cell-options"
                      [ngHide]="fieldTypeTemplate.field.noEditable">
                      <button class="lf-icon-download field-type-template__icon-list field-type-template__icon-list--first"
                          matTooltip="{{'expanded-menu.download' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [ngShow]="!fieldTypeTemplate.field.noEditable"
                          (click)="downloadDocument(document)"></button>
                      <button class="lf-icon-trash field-type-template__icon-list"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          (click)="removeDocument(document)"></button>
                  </div>
              </div>
          </div>
      </div>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeTemplate.field.error && fieldTypeTemplate.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </div>
  <div *ngIf="isLanding"
      class="common__input-block field-type-template__custom-input-block-field"
      [ngClass]="{'field-type-template__custom-input-block-field--required': fieldTypeTemplate.field.error,
          'field-type-template__custom-input-block-field--no-editable':fieldTypeTemplate.field.noEditable}">
      <div class="common__label-block">
          <i class="lf-icon-textbooks field-type-template__custom-icon-type-header"
              [ngClass]="{'field-type-template__custom-icon-type-header--required': fieldTypeTemplate.field.error,
                  'field-type-template__custom-icon-type-header--no-editable':fieldTypeTemplate.field.noEditable}"></i>
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeTemplate.field.error,
                      'common__input-text--no-editable':fieldTypeTemplate.field.noEditable}">{{fieldTypeTemplate.field.label}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeTemplate.field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="fieldTypeTemplate.field.references.length && !fieldTypeTemplate.field.noEditable">
              <tooltip-law [references]="fieldTypeTemplate.field.references"
                  [field]="fieldTypeTemplate.field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__custom-container">
          <p class="field-type-template__text-description"
              [ngClass]="{'field-type-template__text-description--required': fieldTypeTemplate.field.error,
                  'field-type-template__text-description--no-editable':fieldTypeTemplate.field.noEditable}"
              [innerHTML]="fieldTypeTemplate.field.description"></p>
          <div class="field-type-template__block-button">
              <button type="submit"
                      class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
                      [ngClass]="{'common__button-landing-custom--required': fieldTypeTemplate.field.error,
                          'common__button-landing-custom--disabled': fieldTypeTemplate.field.noEditable}"
                      [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                      [disabled]="fieldTypeTemplate.field.noEditable"
                      (click)="generateDocument()"
                      [afTranslate]="'field-type-template.generate-document'">_Generar documento</button>
          </div>
          <div class="field-type-template__block-list-document"
              [ngShow]="fieldTypeTemplate.field.evaluatedValue?.documents?.length">
              <div class="field-type-template__custom-element-list-document"
                  *ngFor="let document of fieldTypeTemplate.field.evaluatedValue?.documents">
                  <div class="field-type-template__custom-table-cell-document">
                      <span class="field-type-template__custom-element-list-title">
                          <span [afTranslate]="'field-type-template.generated-document'">_Documento generado</span>:
                      </span>
                      <span class="field-type-template__custom-element-list-value">{{document.name}}</span>
                  </div>
                  <div class="field-type-template__custom-table-cell-options"
                      [ngHide]="fieldTypeTemplate.field.noEditable">
                      <button class="lf-icon-download field-type-template__custom-icon-list field-type-template__custom-icon-list--download"
                          [ngClass]="{'field-type-template__custom-icon-list--first':document.canBeDeleted}"
                          matTooltip="{{'expanded-menu.download' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [ngShow]="!fieldTypeTemplate.field.noEditable"
                          (click)="downloadDocument(document)"></button>
                      <button class="lf-icon-trash field-type-template__custom-icon-list"
                          *ngIf="document.canBeDeleted"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          (click)="removeDocument(document)"></button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
