import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'users-admin-person-row',
  templateUrl: './users-admin-person-row.component.html'
})
export class UsersAdminPersonRowComponent implements OnInit {
  @Input() selectionEnabled: boolean = false;
  @Input() person: any = {};
  @Input() selectionCount: number;
  @Output() deletePersonRow = new EventEmitter();
  @Output() selectToExportPerson = new EventEmitter();

  usersAdminPersonRow: any = {
  }

  ngOnInit(): void {
    this.personTypeIcon();
  }

  personTypeIcon(){
    if(this.person.type==='group'){
      this.person.typeIcon = 'lf-icon-group';
    }else if(this.person.type==='user'){
      this.person.typeIcon = 'lf-icon-user-special';
    }else{
      this.person.typeIcon = 'lf-icon-user';
    }
  }

  selectToExport() {
    this.selectToExportPerson.emit();
  }

  deletePerson(person: any){
    this.deletePersonRow.emit(person);
  }

}
