<div class="menu-header-comments menu-header-comments--comment">
<div class="menu-header-user__header-nav">
    <i class="menu-header-comments__close-icon lf-icon-close"
         (click)="closeDropdown()"></i>
    <div class="menu-header-comments__title-header menu-header-comments__title-header--notification">
        <span [afTranslate]="'comments.comments'"></span>
    </div>
</div>
<div>
    <div class="menu-header-comments__menu-header">
        <span [afTranslate]="'menu-header.consult-last-comments'">Consulta los últimos 10 comentarios añadidos en los Expedientes.</span>
    </div>

    <div class="menu-header-comments__block-body-comments">
        <div class="menu-header-comments__block-line">
          <span>{{menuHeaderComments.filterList}}</span>
            <div class="menu-header-comments__block-list"
                *ngFor="let comment of menuHeaderComments.comments; let first = first; let last = last;">
                <div>
                    <div class="lf-icon-step-final menu-header-comments__circle-icon-comment"
                        [ngClass]="{'menu-header-comments__circle-icon-comment--first':first}"></div>
                        <div class="menu-header-comments__group-text">
                            <p class="menu-header-comments__text-name">{{comment.client}}</p>
                            <p class="menu-header-comments__text-type">{{comment.evaluation}}</p>
                            <div class="menu-header-comments__breadcrumb-block">
                                <p class="menu-header-comments__text-breadcrumb">{{comment.breadcrumb}}</p>
                            </div>
                            <i [class]="comment.icon+ 'menu-header-comments__icon-type-notification'"
                                *ngIf="option === 'notification'"></i>
                            <p class="menu-header-comments__text-date">{{comment.creationDate | relativeTime}}</p>
                        </div>
                        <div class="menu-header-comments__group-list">
                            <i class="lf-icon-conversation menu-header-comments__icon-notifications"
                                matTooltip="{{'menu-header.read-comments' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                (click)="goTo(comment)"></i>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</div>
