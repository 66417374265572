import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'form-configuration-modal',
  templateUrl: './form-configuration-modal.component.html'
})
export class FormConfigurationModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private dragulaService: DragulaService) {
      this.dragulaServiceFunction();
    }
  
  skin: string = this.globalCfg.skin;
  @Input() configuration:any;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  formConfiguration: any = {
    contentTypeList: [],
    faceview: 'group',
    fieldContainer: {},
    conceptInfo: {},
    disabledGroup: false,
    disabledReport: false,
    changesInConfiguration: false,
    reportId: '',
    fieldList: ExpandMenu['menuConfigurationFormChannelView'].menu,
    evaluationId: this.route.snapshot.firstChild?.params['evaluationId']
  }

  ngOnInit(): void {
    this.formConfiguration.fieldContainer = this.configuration.fieldContainer;
    this.formConfiguration.conceptInfo = this.configuration.conceptInfo;
    this.formConfiguration.infoAdvice = this.configuration.conceptInfo.hasInstances;
    this.getContentType();
    this.getReportId();
  }

  dragulaServiceFunction(){
    this.dragulaService.destroy('fieldListConfiguration');
    if(!this.dragulaService['groups']?.fieldList){
      this.dragulaService.createGroup('fieldListConfiguration', {
        moves: (element, container, handle, sibling) => {
          if(container?.id !== 'fieldType'){
            let move = 'handle'+container?.id === handle?.id;
            if(move){
              this.formConfiguration.dragulaMovedId = element?.id;
            }
            return move;
          }else{
            return true;
          }
        }
      });
    }
  }

  getContentType(){
    this.apiService.get('fields', 'contentTypes')
        .then((data:any)=>{
            this.formConfiguration.contentTypeList = this.commonUtilsService.editList(data, 'fieldContentTypeId', 'name');
        }, ()=>{})
  }

  getReportId(){
    this.apiService.get('reports/communicationchannel/', this.formConfiguration.conceptInfo.productId)
        .then((data:any)=>{
          this.formConfiguration.reportId = data;
          if(this.formConfiguration.fieldContainer.fields?.length){
            this.getCustomField();
            this.getReportsCount();
          }
        }, ()=>{})
  }

  getCustomField(){
    this.apiService.get('denunc/dashboard/getcustomfield/'+this.formConfiguration.reportId, this.formConfiguration.evaluationId)
        .then((data:any)=>{
          if(data){
            for(let i = 0; i < this.formConfiguration.fieldContainer.fields.length; i++){
                if(this.formConfiguration.fieldContainer.fields[i].fieldId === data){
                    this.formConfiguration.selectedChannelReportField = this.formConfiguration.fieldContainer.fields[i];
                    break;
                }
            }
          }
        }, ()=>{})
  }

  getReportsCount(){
    this.apiService.get('reportobjects/objectscount/' + this.formConfiguration.evaluationId, this.formConfiguration.reportId)
        .then((data:any)=>{
          if(data){
            this.formConfiguration.disabledReport = true;
          }
        }, ()=>{})
  }

  reorderFields(event: any, fieldContainer: any){
    let fieldSelected = event.filter((fieldContainer:any, index: any)=>{
      fieldContainer.prevNumOrder = fieldContainer.numOrder;
      fieldContainer.numOrder = index+1;
      return parseInt(this.formConfiguration.dragulaMovedId) === fieldContainer.fieldId;
    })[0];
    delete this.formConfiguration.dragulaMovedId;
    fieldContainer.fields = event;
    let indexOrder= fieldSelected.prevNumOrder<fieldSelected.numOrder?fieldSelected.numOrder:fieldSelected.numOrder-1;
    this.apiService.patch('fields/'+fieldSelected.fieldId, 'reorder/'+indexOrder).then((data:any)=>{}, (errorData:any)=>{});
  }

  addNewField(field: any){
    let newField: any = {
      fieldTypeId: field.action,
      fieldTypeIdPainted: field.action,
      icon: field.icon,
      name: this.translate.instant(field.name),
      fieldId: this.commonUtilsService.createId(),
      openFirstTime: true,
      new: true,
      label: this.translate.instant('customize-concept.new-field') + this.translate.instant(field.name).toLowerCase()
    }
    if(field.action === 'list'){
      newField.listValues = [];
    }
    this.selectEditField(newField);
  }

  selectEditField(configureField: any){
    configureField.open = true;
    this.formConfiguration.newField = configureField;
    let that: any = this;
    setTimeout(function(){
      let field: any = $('#customize-field-' + that.formConfiguration.newField.fieldId);
      field.focus().select();
    })
    this.formConfiguration.faceview = 'field';
  }

  fieldContainerNameChange(container:any){
    if(container.name !== container.oldName){
      this.patchModifiedGroup(container);
    }
  }

  patchModifiedGroup(fieldContainer:any){
    this.apiService.patch('fieldContainers', fieldContainer.fieldContainerId, fieldContainer)
      .then(()=>{
          fieldContainer.oldName = fieldContainer.name;
          this.formConfiguration.changesInConfiguration = true;
      }, ()=>{})
  }

  showField(e:any, group:any){
    if(e.action === 'edit'){
      this.selectEditField(cloneDeep(e.field));
    }else{
      let field: any = e.field;
      if(!field.open){
        group.fields.forEach((fieldGroup:any) => {
          fieldGroup.open = false;
        })
      }
      field.open = !field.open;
    }
  }

  launchAction(action:any, fieldContainer:any){
    switch(action.action){
      case 'save':
        this.loader.openLoader('customize-concept-save-field');
        if(action.field.new){
          delete action.field.new;
          this.createNewField(action.field, fieldContainer);
        }else{
          this.saveField(action.field, fieldContainer, false);
        }
        this.formConfiguration.faceview = 'group';
        break;
      case 'delete':
        this. deleteField(action.field, fieldContainer);
        break;
      default:
        this.formConfiguration.faceview = 'group';
        break;
    }
  }

  createNewField(field: any, container:any){
    let newField: any = {
      fieldTypeId: field.fieldTypeId,
      label: field.label,
      name: field.name,
      title: field.title,
      numOrder: container.fields?.length+1
    }
    delete field.fieldId;
    this.apiService.add('fields/'+container.fieldContainerId, newField)
      .then((data:any)=>{
        field = Object.assign({}, data, field);
        this.saveField(field, container, true);
      }, (errorData)=>{
        this.loader.closeError();
      });
  }

  saveField(field:any, container:any, isNew: boolean = false){
    if(!field.fieldAttributes){
      field.fieldAttributes = {};
    }
    field.fieldAttributes.private = field.private;
    field.fieldAttributes.fieldNotInForce = field.fieldNotInForce;
    field.fieldAttributes.hiddenInLandings = field.hiddenInLandings;
    field.fieldAttributes.isDisaggregated = field.isDisaggregated;
    field.fieldAttributes.nullsAsZero = field.nullsAsZero;
    if (field.fieldTypeId === 'date') {
        field.fieldAttributes.dateLockType = field.dateLockType;
    }
    if(field.fieldTypeId ==='text' && field.multiline){
      field.fieldAttributes.isVoiceRecord = field.isVoiceRecord;
      field.fieldAttributes.voiceRecordOkText = field.voiceRecordOkText;
    }
    this.apiService.patch('fields',field.fieldId, field)
        .then((data:any)=>{
          field.open = false;
          if(isNew){
            container.fields.splice(field.numOrder, 1, field);
          }else{
            container.fields = this.commonUtilsService.replaceElement(field, container.fields, 'fieldId', field);
          }
          this.formConfiguration.changesInConfiguration = true;
          this.loader.closeLoader('customize-concept-save-field');
        }, (errorData:any)=>{
          this.loader.closeError();
        })
  }

  deleteField(field:any, fieldContainer:any){
    this.modalService.adviceModal(this.translate.instant('customize-concept.delete-question') +"'"+ field.label + "'?", 'info:question:question')
    .subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loader.openLoader('customize-concept-delete-field');
        this.deletefieldSelected(field, fieldContainer);
      }
    })
  }

  deletefieldSelected(field:any, fieldContainer:any){
    this.apiService.delete('fields', field.fieldId)
      .then((data:any)=>{
          fieldContainer.fields = this.commonUtilsService.deleteElement( field, fieldContainer.fields, 'fieldId')
          this.loader.closeLoader(['customize-concept-delete-field']);
          let text : string = this.translate.instant('customize-concept.the-field') +"'"+ field.label +"'"+ this.translate.instant('customize-concept.deleted-text');
          this.modalService.adviceModal(text, 'info:accept:check-round')
      }, (errorData:any)=>{
          this.loader.closeError();
          let text: string  = '';
          if(errorData.status === 403){
            text = this.translate.instant('concept-list.delete-error-permissions');
          }
          if(errorData.status === 500 && errorData.description==="Field in formula"){
            text = this.translate.instant('customize-concept.field-in-formu').replace('field-in-formu',field.label);
          }
          if(text !==''){
            this.modalService.adviceModal(text, 'error:accept:warning')
          }
      })
  }

  selectChannelReport(field: any){
    this.formConfiguration.selectedChannelReportField = field;
    this.closeDropdown('dropdownChannelReport');
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  closeAdvice(){
    this.formConfiguration.infoAdvice = false;
  }

  saveConfiguration() {
    let fieldId = this.formConfiguration.selectedChannelReportField?.fieldId ? this.formConfiguration.selectedChannelReportField?.fieldId : 0;
    this.apiService.add('denunc/dashboard/setcustomfield/' + this.formConfiguration.reportId + '/' + fieldId + '/' + this.formConfiguration.evaluationId)
      .then((data: any) => {
        this.handleAccept();
      }, (errorData) => {
        this.loader.closeError();
      });
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      fieldContainer: this.formConfiguration.fieldContainer,
      changesInConfiguration: this.formConfiguration.changesInConfiguration
    });
  }

  handleDismiss(){
    this.activeModal.close({
      result: 'cancel',
      fieldContainer: this.formConfiguration.fieldContainer,
      changesInConfiguration: this.formConfiguration.changesInConfiguration
    });
  }
}
