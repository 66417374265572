<div class="customize-field-text">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [(ngModel)]="customizeFieldUser.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldUser.tinyMceOptions"
      [modelText]="customizeFieldUser.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldUser.contentReportInfo"
            [preSelectItem]="customizeFieldUser.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept?.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldUser.field.required"
        [disabled]="customizeFieldUser.field.noEditable"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <switch-option
        [model]="customizeFieldUser.field.multiple"
        [disabled]="customizeFieldUser.field.noEditable"
        [fieldText]="'customize.multiple'"
        (changeOptionEmit)="changeOptionOn($event, 'multiple')">
    </switch-option>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
                class="common__button common__button--clean"
                (click)="cancelFieldText()"
                [afTranslate]="'common.cancel'">
            Cancelar
        </button>
        <button type="submit"
                class="common__button common__button--active"
                (click)="saveFieldText()"
                [afTranslate]="'common.save'">
            Guardar
        </button>
    </div>
</div>
