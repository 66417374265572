import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy} from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';

@Component({
  selector: 'client-details',
  templateUrl: './client-details.component.html'
})
export class ClientDetailsComponent implements OnInit, OnChanges, OnDestroy {
  skin:string =  this.globalCfg.skin;
  subscribers: any = {};
  clientDetails:any = {
    dashboardVersion: parseInt(this.globalCfg.dashboardVersion)
  };

  constructor(private globalCfg:GlobalCfgFactory,
    private broadcast:BroadcastService) { }
  @Input() selectClient:any;
  @Output() launchAction = new EventEmitter();

  ngOnChanges() {
    this.configureDetails();
  }

  ngOnInit() {
    this.subscribers.changeInSelectedClientBroadcast = this.broadcast.subscribe('changeInSelectedClient', () => {
      this.configureDetails();
    });
  }

  configureDetails(){
    if(this.selectClient && this.selectClient.observations && this.clientDetails.dashboardVersion === 3){
        this.getContactsName();
    }
  }

  getContactsName(){
    let listContactsName : any  = this.selectClient.observations.split('-|-');
    for(let i = listContactsName.length-1; i >= 0; i--){
        let contactName : any = listContactsName[i];
        if(!contactName.length){
            listContactsName.splice(i, 1);
        }
    }
    this.selectClient.contactsName = listContactsName.join(', ');
  }

  editClientDetail(){
    this.launchAction.emit({action:'editClientDetail'});
  }

  deleteDetailClient(){
    this.launchAction.emit({
      action:'deleteClientDetail',
      client: this.selectClient
    });
  }
  ngOnDestroy(){
    for (let key in this.subscribers) {
      this.subscribers[key].unsubscribe();
    }
  }
}
