import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import $ from 'jquery';

@Component({
  selector: 'up-document',
  templateUrl: './up-document.component.html'
})
export class UpDocumentComponent implements OnInit {
  upDocument: any ={
    client: {},
    file: null
  }
  constructor(private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }
  @Input() type: any = null;

  ngOnInit(): void {
    this.upDocument.titleHeader = this.translate.instant('up-document.file');
    this.upDocument.titleLabel = this.translate.instant('up-document.file-upload');
    if(this.type === 'template-document'){
      this.upDocument.titleHeader = this.translate.instant('up-document.up-template');
      this.upDocument.titleLabel = this.translate.instant('templates-concept.upload-template');
    }
  }

  uploadLogo(event:any){
      let file = event.target.files[0];
      let element: any = $('.up-document__name-img')[0]
      if(file.name.length < 100){
          this.upDocument.errorName = false;
          this.upDocument.errorField = false;
          this.upDocument.file = null;
          let fd = new FormData();
          fd.append('file', file);
          this.upDocument.file = fd;
          this.upDocument.fileName = file.name;
      }else{
          this.upDocument.file = null;
          this.upDocument.errorName = true;
      }

      this.upDocument.fileName = file.name;
      element.textContent = file.name;

  }

  openFileSearch(){
    this.upDocument.showFileName = false;
      setTimeout(function(){
          let item: any = $('#up-document');
          item.click();
      })
  }

  upFile(){
      if(!this.upDocument.errorName && this.upDocument.file !== null){
          this.handleAccept();
      }else if(!this.upDocument.errorName && this.upDocument.file === null){
          this.upDocument.errorField = true;
      }
  }



  clearForm(){
      this.upDocument.file = null;
      this.upDocument.fileName = "";
      let element: any = $('.up-document__name-img')[0]
      element.textContent = "";
  }

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        document: this.upDocument.file,
        name: this.upDocument.fileName
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
