import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'field-type-role',
  templateUrl: './field-type-role.component.html'
})
export class FieldTypeRoleComponent implements OnChanges {
  skin:string = this.globalCfg.skin;
  userPermissions:any = this.userPermisions.getPermissions();
  fieldTypeRole:any={
    fullNameWordFilter: '',
    organizationsWordFilter: '',
    fullNameFilter: {
      name: this.translate.instant('common.user'),
      id: this.commonUtilsService.createId(),
      width: '200px'
    },
    organizationsFilter: {
      name: this.translate.instant('common.organizations'),
      id: this.commonUtilsService.createId(),
      width: '200px'
    },
    filterOrganizationList: []
  }
  constructor(private globalCfg: GlobalCfgFactory,
      private userPermisions: UserPermisionsFactory,
      private translate: AfTranslateFactory,
      private commonUtilsService: CommonUtilsService,
      private filterColumnService: FilterColumnService,
      private modalService: ModalServiceService,
      private loader: LoaderFactory,
      private apiService: ApiServiceService) { }

  @Input() field: any = null;
  @Input() productId: any = '';

  ngOnChanges(changes: SimpleChanges): void {
    if(this.field) {
        this.filterOrganizations();
    }
  }

  filterOrganizations(type:any=null){
      let searchFilter = this.fieldTypeRole.fullNameWordFilter?this.fieldTypeRole.fullNameWordFilter:'';
      let searchWord = 'fullName';
      if (type) {
          searchFilter = this.fieldTypeRole[type + 'WordFilter'];
          searchWord = type;
      }
      let filterList: any;
      if(type === 'organizations'){
          filterList = this.filterColumnService.filterByWordChildren(searchFilter, this.field.selectContacts, 'fullName', 'clients');
          if(this.fieldTypeRole.fullNameWordFilter){
            filterList = this.filterColumnService.filterByWord(this.fieldTypeRole.fullNameWordFilter, filterList, 'fullName');
          }
      }else{
          filterList = this.filterColumnService.filterByWord(searchFilter, this.field.selectContacts, searchWord);
          if(this.fieldTypeRole.organizationsWordFilter){
            filterList = this.filterColumnService.filterByWordChildren(this.fieldTypeRole.organizationsWordFilter, filterList, 'fullName', 'clients');
          }
      }
      this.fieldTypeRole.filterOrganizationList = filterList;
  }

  assignRole(editUser: any=null){
      let configurationModal = {
          field: this.field,
          productId: this.productId,
          user: editUser?editUser:null,
          clientsSelected: editUser?this.getUserOrganizations(editUser):null,
      }
      this.modalService.roleAssignmentModal(configurationModal).subscribe((result:any) => {
        if(result.result === 'ok'){
          this.assignRoleToUser(result.roleConfiguration, editUser);
        }
      });
  }

  getUserOrganizations(editUser: any){
      let organizations = '';
      editUser.clients.forEach((client:any) => {
        organizations += organizations?'|'+client.clientId:client.clientId;
      });
      return organizations;
  }

  assignRoleToUser(roleConfiguration: any, editUser: any){
      let userRole = {
          productRoleId: this.field.productRoleId,
          userId: roleConfiguration.user.userId,
          clientIds: roleConfiguration.clientIds,
          numOrder: editUser?editUser.numOrder:0,
          prevUserId: undefined
      }
      if(editUser && editUser.userId !== userRole.userId){
          userRole.prevUserId = editUser.userId;
      }
      this.loader.openLoader('assign-role-to-user');
      this.apiService.add('productroles/assign', userRole)
        .then((data:any)=>{
            let clientAll = {
                clientId: 'all',
                name: this.translate.instant('field-type-role.all-present-future')
            }
            let newUser = {
              clients: roleConfiguration.clientIds?roleConfiguration.clientsList:[clientAll],
              email: roleConfiguration.user.email,
              fullName: roleConfiguration.user.fullName,
              phoneNumber1: roleConfiguration.user.phoneNumber1,
              userId: roleConfiguration.user.userId,
              allClients: roleConfiguration.clientIds?false:true,
              numOrder: editUser?editUser.numOrder:data.numOrder+1
            }
            let text = this.translate.instant('field-type-role.assignment-ok');
            if(editUser){
                for(let i=0; i < this.field.selectContacts.length; i ++){
                    if(this.field.selectContacts[i].userId === editUser.userId){
                        this.field.selectContacts.splice(i, 1, newUser);
                        break;
                    }
                }
                text = this.translate.instant('field-type-role.assignment-ok-changes');
            }else{
                this.field.selectContacts.splice(0, 0, newUser);
            }
            this.filterOrganizations();
            this.loader.closeLoader(['assign-role-to-user']);
            this.openInfoModal(text);
        }, (errorData:any)=>{
            this.loader.closeError();
            if(errorData.status === 409){
                this.openRoleErrorModal(errorData.description, 'errorSaveUser');
            }else{
                this.errorModal(this.translate.instant('field-type-role.error-assignment'));
            }
        });
  }

  deleteUserRole(userRole: any){
      this.modalService.adviceModal(this.translate.instant('field-type-role.question-delete-role'), 'info:question:question').subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('delete-role-to-user');
          this.apiService.delete('productroles/role/'+this.field.productRoleId+'/user', userRole.userId)
            .then((data:any)=>{
                this.field.selectContacts = this.commonUtilsService.deleteElement(userRole, this.field.selectContacts, 'userId');
                this.filterOrganizations();
                this.loader.closeLoader(['delete-role-to-user']);
                this.openInfoModal(this.translate.instant('field-type-role.delete-assignment-ok'))
            }, (errorData:any)=>{
                this.loader.closeError();
                if(errorData.status === 409){
                    this.openRoleErrorModal(errorData.description, 'errorUnassignUser');
                }else{
                    this.errorModal(this.translate.instant('field-type-role.delete-assignment-error'));
                }
            });
        }
      });
  }

  openRoleErrorModal(list: any, type: any){
      let configuration = {
          list: list,
          role: this.field,
          type: type
      }
      this.modalService.roleErrorModal(configuration);
  }

  openInfoModal(text:any){
      this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  errorModal(text:any){
      this.modalService.adviceModal(text, 'error:accept:warning');
  }

  orderBySearch(e: any, type:any){
    let newValue: any = e.newValue;
    this.fieldTypeRole[type+'WordFilter'] = newValue;
    this.filterOrganizations(type);
  }
}
