import moment from 'moment';
import $ from 'jquery'

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';


@Component({
  selector: 'basic-data-modal',
  templateUrl: './basic-data-modal.component.html',
  styleUrls: ['./basic-data-modal.component.scss']
})
export class BasicDataModalComponent implements OnInit {
  skin: string = this.globalCfg.skin
  basicDataModal: any = {
    disabledSwitch: false,
    disabledConcept: false,
    sharedPoll: false,
    groupsAsListCopy: true,
    noEditablePoll: false,
    changedCommunicationChannel: false,
    message: '',
    nameInfo: {
        fieldId: 'name-info-basic',
        required:  true,
        description: '',
        label: this.translate.instant('common.title')
    },
    confirm: this.translate.instant('common.create'),
    conceptData: null,
    tinyMceOptions: {
      noEditable: false,
      toolbar: 'bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify undo redo bullist numlist outdent indent removeformat link',
      placeholder: this.translate.instant('basic-data-modal.placeholder')
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private route: ActivatedRoute,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal) { }

  @Input() concept:any = null;
  @Input() option:any = null;

  ngOnInit(): void {
    let concept = this.concept;
    this.basicDataModal.tinyMceOptions.noEditable = concept.poll && concept.disabled;
    if(this.option.type === 'edit'){
      this.basicDataModal.confirm = this.translate.instant('common.ok')
    }
    this.basicDataModal.conceptData={
      title: concept.title,
      allowPublicShare: concept.allowPublicShare,
      anonymousAccess: concept.anonymousAccess,
      lockedEdit: concept.lockedEdit || concept.stateLockedEdit,
      stateLockedEdit: concept.stateLockedEdit,
      description: concept.description,
      poll: concept.poll,
      sequentialView: concept.sequentialView,
      communicationChannel: concept.communicationChannel,
    }
    this.configureModal();
  }

  configureModal(){
    let basicDataModal = this.basicDataModal;
    let concept = this.concept;
    if(!concept.conceptId){
      basicDataModal.conceptData.groupsAsList = true;
      basicDataModal.disabledSwitch = false;
      basicDataModal.disabledConcept = false;
    }else{
      basicDataModal.conceptData.groupsAsList = concept.groupsAsList;
      basicDataModal.disabledSwitch = true;
      if(concept.poll){
        basicDataModal.disabledConcept = true;
      }
    }
    basicDataModal.sharedPoll = (concept.publicShared || concept.shared);
    basicDataModal.noEditablePoll = (concept && concept.noEditablePoll);

    basicDataModal.nameInfo.noEditable = (concept.poll && concept.disabled);
    basicDataModal.disabledPoll = (concept.poll && concept.disabled);
    basicDataModal.disabledAcceptButton = (concept.poll && concept.disabled);
    if(concept.pollExpirationDate && (concept.poll && concept.disabled)){
      basicDataModal.nameInfo.noEditable = !(moment(new Date).valueOf() > moment(concept.pollExpirationDate).valueOf());
      basicDataModal.disabledAcceptButton = !(moment(new Date).valueOf() > moment(concept.pollExpirationDate).valueOf());
    }
    setTimeout(function(){
      $('#field-item-text-name-info-basic').trigger('focus').trigger('select');
    },200)
  }

  itemSelected(e:any, item:string){
    this.basicDataModal.conceptData[item] = e.newValue;
  }

  validateFields(){
    let basicDataModal = this.basicDataModal;

    basicDataModal.conceptData.description = this.commonUtilsService.extractInnerHTML(basicDataModal.message);
    if(basicDataModal.conceptData.description === this.translate.instant('basic-data-modal.placeholder')){
        basicDataModal.conceptData.description = "";
    }

    if(basicDataModal.conceptData.viewFields){
        basicDataModal.conceptData.sequentialView = basicDataModal.conceptData.viewFields;
    }

    if(basicDataModal.conceptData.title === '' || basicDataModal.conceptData.title === undefined){
        basicDataModal.nameInfo.error = true;
    }else{
        basicDataModal.nameInfo.error = false;
        if (!basicDataModal.conceptData.allowPublicShare && !basicDataModal.conceptData.anonymousAccess) {
            basicDataModal.conceptData.lockedEdit = false;
        }
        if(this.option.type === 'edit'){
          this.handleAccept();
        }else{
            basicDataModal.conceptData.folderId = this.concept.folderId;
            basicDataModal.conceptData.isFront = true;
            this.apiService.add('Concepts/'+this.route.snapshot?.firstChild?.params['evaluationId'], basicDataModal.conceptData)
                .then((data:any)=>{
                    basicDataModal.conceptData = data;
                    this.handleAccept();
                })
        }
    }
  }

  restore(type:any, newValue:boolean){
    let basicDataModal = this.basicDataModal;
    basicDataModal.conceptData[type] = newValue;
    switch(type){
        case 'allowPublicShare':
            delete basicDataModal.changedAllowPublicShare;
            basicDataModal.conceptData.poll = false;
            basicDataModal.conceptData.anonymousAccess = false;
            basicDataModal.conceptData.sequentialView = false;
            if(basicDataModal.conceptData.allowPublicShare === false){
                basicDataModal.conceptData.lockedEdit = false;
                basicDataModal.conceptData.restrictedDelete = false;
                basicDataModal.conceptData.communicationChannel = false;
            } else {
                basicDataModal.changedAllowPublicShare = true;
                basicDataModal.conceptData.lockedEdit = this.concept.lockedEdit || this.concept.stateLockedEdit;
                basicDataModal.conceptData.restrictedDelete = this.concept.restrictedDelete;
            }
            break;
        case 'groupsAsList':
            if(basicDataModal.conceptData.groupsAsList === true){
                basicDataModal.conceptData.sequentialView = false;
            }
            break;
        case 'poll':
            basicDataModal.conceptData.allowPublicShare = false;
            basicDataModal.conceptData.lockedEdit = false;
            if(basicDataModal.conceptData.poll === false){
                basicDataModal.conceptData.anonymousAccess = false;
                basicDataModal.conceptData.sequentialView = false;
            }
            break;
        case 'sequentialView':
            if(basicDataModal.conceptData.sequentialView){
                basicDataModal.groupsAsListCopy = basicDataModal.conceptData.groupsAsList;
                basicDataModal.conceptData.groupsAsList = false;
            }else{
                basicDataModal.conceptData.groupsAsList = basicDataModal.groupsAsListCopy;
            }
            break;
        default:
            break;
    }
  }

  communicationChannel(){
      if(this.basicDataModal.conceptData.communicationChannel){
          this.basicDataModal.changedCommunicationChannel = false;
          this.basicDataModal.conceptData.communicationChannel = !this.basicDataModal.conceptData.communicationChannel
      }else{
          this.basicDataModal.changedCommunicationChannel = true;
          this.basicDataModal.conceptData.communicationChannel = true;
          this.basicDataModal.conceptData.lockedEdit = true;
          this.basicDataModal.conceptData.restrictedDelete = true;
      }
  }

  getEditorValue(event:any){
    this.basicDataModal.message = event.model;
  }

  handleAccept() {
    let basicDataModal = this.basicDataModal;
    if(!basicDataModal.conceptData.allowPublicShare && !basicDataModal.conceptData.anonymousAccess){
      basicDataModal.conceptData.lockedEdit = false;
    }
    if(basicDataModal.conceptData.stateLockedEdit && !basicDataModal.conceptData.lockedEdit){
      basicDataModal.conceptData.stateLockedEdit = false;
    }
    this.activeModal.close({
      result: 'ok',
      concept: basicDataModal.conceptData,
      changedCommunicationChannel: this.basicDataModal.changedCommunicationChannel,
      changedAllowPublicShare: this.basicDataModal.changedAllowPublicShare
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
