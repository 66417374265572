import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';
import { ConceptFormParentService } from '../utils/concept-form-parent.service';

@Component({
  selector: 'concept-form-default',
  templateUrl: './concept-form.component.html'
})
export class ConceptFormComponent implements OnChanges, OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  isLanding: boolean = false;
  params: any = this.route.snapshot.params
  currentComponent: string = this.route.snapshot.data['componentName'];
  locationName: any = this.route.snapshot.data['name'];
  fieldContainerId:any = this.route.snapshot.params['fieldContainerId'];
  conceptObjectId:any = this.route.snapshot.params['conceptObjectId'];
  conceptForm: any = this.conceptFormParent.configureConceptFormObject(this);


  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private rolesUtilsService: RolesUtilsService,
    private conceptFormParent: ConceptFormParentService) {
    }

  @Input() concept: any = null;
  @Input() conceptList: any = null;
  @Input() public: boolean = false;
  @Input() lockedDataEvaluation: any = null;
  @Input() evaluationId: any = null;
  @Input() footerConceptPublic: any = null;
  @Input() parentConcept: any = null;
  @Input() hasLinksToForms: any = null;
  @Input() disabledSave: any = null;
  @Input() formalitiesList: any = null;
  @Input() published: any = null;
  @Input() viewMode: boolean = false;

  @Output() launchAction = new EventEmitter();

  ngOnDestroy(){
    this.conceptFormParent.ngOnDestroy();
  }

  ngOnInit(){
    this.conceptFormParent.onInit(this)

  }

  ngOnChanges(): void {
    this.conceptFormParent.onChanges(this)
  }

  hasFullScreen(e:any){
    this.conceptFormParent.hasFullScreen(e.box, this)
  }

  changesInEvaluatedConcept(e:any){
    this.conceptFormParent.changesInEvaluatedConcept(e.field, this);
  }

  changesInEvaluatedConceptAdditional(e:any) {
    this.conceptFormParent.changesInEvaluatedConceptAdditional(e.field, this);
  }

  changesInMultipleConcept(e:any){
    this.conceptFormParent.changesInMultipleConcept(e.field, this);
  }

  clear(){
    this.conceptFormParent.clear(this)
  }

  saveCreateConcept(type:any){
    this.conceptFormParent.saveCreateConcept(type, this)
  }

  saveConcept(type:any ='') {
    this.conceptFormParent.saveConcept(type, this)
  }

  closeConceptUserProfile(){
    this.conceptFormParent.closeConceptUserProfile(this)
  }

  generateDocument(e:any){
    this.conceptFormParent.generateDocument(e.template, this)
  }

  launchActionGeneralConcept(action:any){
    this.conceptFormParent.launchActionGeneralConcept(action, this)
  }

  selectStep(container:any){
    if(this.conceptForm.viewConcept === 'steps'){
        this.sendChangeStep(container.index);
    }else{
        let anchor: any = $('#grupo-id-'+container.fieldContainerId)?.offset()?.top;
        let actualScroll: any = $('#compliance-view-body').scrollTop();
        $('#compliance-view-body').scrollTop(actualScroll+anchor);
        this.conceptForm.showFieldContainerNav = false;
    }
  }

  sendChangeStep(step:any){
    this.conceptFormParent.sendChangeStep(step, this)
  }

  goBack(){
    this.conceptFormParent.goBack(this)
  }

  openRelatedInfo(relatedInfo:any){
    this.conceptFormParent.openRelatedInfo(relatedInfo, this)
  }

  changeResponsible(responsible:any) {
    this.conceptFormParent.changeResponsible(responsible, this)
  }

  changeLockedEdit(lockedEdit:any){
    this.conceptFormParent.changeLockedEdit(lockedEdit, this)
  }

  changeConceptFromField(template:any){
    this.conceptFormParent.changeConceptFromField(template, this)
  }

  clearSaveConcept(){
    this.conceptFormParent.clearSaveConcept(this)
  }

  shareFieldContainer(fieldContainer:any){
    this.conceptFormParent.shareFieldContainer(fieldContainer, this)
  }

  createNewForm(form:any){
    this.conceptFormParent.createNewForm(form, this)
  }

  launchActionBlockedConcept(type:any){
    this.conceptFormParent.launchActionBlockedConcept(type, this)
  }

  launchActionOn(e:any){
    this.launchAction.emit(e.action)
  }

  launchActionSubcontainer(e: any){
    if(e.action === 'shareFieldContainer'){
      this.conceptFormParent.shareFieldContainer(e.fieldContainer, this);
    }
  }

  showTooltip(element:string = ''){
    return !(this.commonUtilsService.showTooltip(element));
  }

  openOptionBlockClient(open: any){
    open = !open;
    if(open){
      this.rolesUtilsService.openManageOptionBlock(this.conceptForm.menuBlockClientShow, this.conceptForm.rolesLevel, this.conceptForm.enabledButtonManageBlock, this.concept.clientStateId===2);
    }
  }
  trackContainer(index:any, container:any){
    return container.fieldContainerId? container.fieldContainerId + '-' + index : container.fieldId + '-' + index
  }
}

