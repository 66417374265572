<form id="personForm"
  class="users-admin-group-form">
  <div class="users-admin-edit__block-row">
      <div class="col-md-12">
          <field-type-text-simple [field]="usersAdminGroupForm.name"
            [preSelectItem]="group.name"
            (itemSelected)="group.name = $event.newValue"></field-type-text-simple>
      </div>
  </div>
  <div class="users-admin-edit__block-row">
      <div class="col-md-12">
          <field-type-text-multiline [field]="usersAdminGroupForm.description"
            [preSelectItem]="group.description"
            (itemSelected)="group.description = $event.newValue"></field-type-text-multiline>
      </div>
  </div>
  <div class="users-admin-edit__block-row">
      <field-type-contacts-multiple [field]="usersAdminGroupForm.contactsField"
          (sendContacts)="memberSelected($event.contacts)">
      </field-type-contacts-multiple>
  </div>
</form>
