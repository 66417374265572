<div class="documentation-report-list common__block-concept common__block-concept--border">
    <div class="documentation-report-list__table">
        <div class="common__table-header">
            <div class="documentation-report-list__table-col documentation-report-list__table-col--title documentation-report-list__table-col--top-1">
                <filter-by-search
                    [item]="documentationReportList.titleFilter"
                    [filterOrder]="documentationReportList.dateFilter"
                    [order]="documentationReportList.order"
                    (launchActionSearch)="orderBySearch($event, 'title')">
                </filter-by-search>
            </div>
            <div class="documentation-report-list__table-col documentation-report-list__table-col--author documentation-report-list__table-col--top-1">
                <filter-by-list [type]="'listFind'"
                    [item]="documentationReportList.authorFilter"
                    [widthClass]="'200px'"
                    [list]="documentationReportList.authorList"
                    (filterElementEmit)="selectTypeOperation($event, 'author')">
                </filter-by-list>
            </div>
            <div class="documentation-report-list__table-col documentation-report-list__table-col--date documentation-report-list__table-col--top-2">
                <div (click)="orderDate('dateExpiration')">
                    <p class="common__table-header-text"
                        [afTranslate]="'documentation-list.exportation-date'">Fecha Exportación</p>
                    <i class="lf-icon-arrow-down common__order-by"
                        [ngClass]="{'lf-icon-arrow-up': !documentationReportList.order && documentationReportList.dateFilter === 'dateExpiration',
                            'common__order-by--active': documentationReportList.dateFilter === 'dateExpiration'}"></i>
                </div>
            </div>
            <div class="documentation-report-list__table-col documentation-report-list__table-col--actions">
                <p class="common__table-header-text common__table-header-text--right">Acciones</p>
            </div>
        </div>
        <div class="documentation-report-list__table-body"
            [ngClass]="{'documentation-report-list__table-body--more-elements': documentationReportList.filterDocumentsList?.length > 11}">
            <div class="common__table-row"
                *ngFor="let document of documentationReportList.filterDocumentsList | afOrderBy:documentationReportList.dateFilter:documentationReportList.order">
                <div class="documentation-report-list__table-col documentation-report-list__table-col--title">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="document.title">
                        </parragraph-tooltip>
                    </div>
                </div>
                <div class="documentation-report-list__table-col documentation-report-list__table-col--author">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="document.author">
                        </parragraph-tooltip>
                    </div>
                </div>
                <div class="documentation-report-list__table-col documentation-report-list__table-col--date">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="document.dateExpiration | convertDate:'without-time'">
                        </parragraph-tooltip>
                    </div>
                </div>

                <div class="documentation-report-list__table-col documentation-report-list__table-col--actions">
                    <div class="documentation-report-list__options-block" ngbDropdown>
                        <button class="lf-icon-kebab-menu common__icon-ellipsis" ngbDropdownToggle></button>
                        <ul class="documentation-report-list__list-menu-block" ngbDropdownMenu>
                            <li class="common__list-dropdown-option"
                                *ngFor="let optionMenu of documentationReportList.menuDocument; let last = last"
                                ngbDropdownItem>
                                <button class="common__button-option-list-dropdown"
                                    [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                    (click)="sendAction(optionMenu.action, document)">
                                    <i [class]="'common__icon-option-list-dropdown ' + optionMenu.icon"></i>
                                    <span class="common__text-menu-option-dropdown" [afTranslate]="optionMenu.name"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
