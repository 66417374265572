import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DragulaService } from 'ng2-dragula';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-concept',
  templateUrl: './customize-concept.component.html'
})
export class CustomizeConceptComponent implements OnInit, OnChanges, OnDestroy {
  skin:any = this.globalCfg.skin;
  evaluationId: any = this.route.snapshot.params['evaluationId']
  customizeConcept:any ={
    showInfo: false,
    showHeaderInfo: false,
    noDraggable: false,
    reportList: [],
    templatesList: [],
    textHeaderInfo: this.translate.instant('customize-concept.restringed-edition')
  }
  subscribers : any = {};

  constructor(private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private conceptState: ConceptStateFactory,
    private dragulaService: DragulaService) {
      this.dragulaServiceFunction();
    }

  @Input() concept:any = null;
  @Input() pollObject:any = null;
  @Input() allowPublicShare:boolean = false;

  ngOnInit(): void {
    this.watchSavedPropertiesConcept();
    this.getReports();
    this.getTemplates();
    this.getConcepts();
    this.getContentType();
    this.setVisibilities();
    if(this.concept.poll){
      if(this.concept.publicShared || this.concept.shared){
          this.concept.disabled = true;
          this.customizeConcept.showHeaderInfo = true;
          this.customizeConcept.noDraggable = true;
      }
      this.customizeConcept.textHeaderInfo = this.translate.instant('customize-concept.restringed-edition-poll');
      this.getVisibilityItems();
      this.hasVisibilities();
    }
    this.subscribers.isOpenFieldBroadcast = this.broadcastService.subscribe('isOpenField', (open:any)=>{
      this.customizeConcept.noDraggable = open.open;
    });
  }

  ngOnChanges(): void {
    this.isInstantiated();
    if(this.allowPublicShare){
      this.concept.fieldContainers.forEach((fieldContainer:any) => {
        if(fieldContainer.hiddenInLandings){
            fieldContainer.hiddenInLandings = false;
            this.modifyGroup(fieldContainer);
        }
        fieldContainer.fields.forEach((field:any) => {
            if(field.hiddenInLandings){
                field.hiddenInLandings = false;
                this.saveField(field);
            }
        })
        fieldContainer.subContainers.forEach((subContainer:any) => {
            if(subContainer.hiddenInLandings){
                subContainer.hiddenInLandings = false;
                this.modifyGroup(subContainer);
            }
            subContainer.fields.forEach((subField:any) => {
              if(subField.hiddenInLandings){
                  subField.hiddenInLandings = false;
                  this.saveField(subField);
              }
            })
        })
      })
    }
  }

  dragulaServiceFunction(){
    this.dragulaService.destroy('container');
    this.dragulaService.destroy('handleContainer');
    this.dragulaService.destroy('subContainer');
    this.dragulaService.destroy('handleSubContainer');
    this.dragulaService.destroy('fieldList');
    this.dragulaService.destroy('customizeFieldListValue');
    this.dragulaService.destroy('customizeEncodingItem');
    if(!this.dragulaService['groups']?.container){
      this.dragulaService.createGroup('container', {
        revertOnSpill: false,
        copy: false,
        moves: (element, container, handle) => {
          this.customizeConcept.dragulaSelectedId = element?.id;
          return handle?.className.indexOf('handleContainer') !== -1;
        }
      });
    }
    if(!this.dragulaService['groups']?.subContainer){
      this.dragulaService.createGroup('subContainer', {
        revertOnSpill: false,
        copy: false,
        moves: (element, container, handle) => {
          this.customizeConcept.dragulaSelectedId = element?.id;
          return handle?.className.indexOf('handleSubContainer') !== -1;
        }
      });
    }
    if(!this.dragulaService['groups']?.fieldList){
      this.dragulaService.createGroup('fieldList', {
        moves: (element, container, handle, sibling) => {
          if(container?.id !== 'fieldType'){
            let move = 'handle'+container?.id === handle?.id;
            if(move){
              this.customizeConcept.dragulaMovedId = element?.id;
            }
            return move;
          }else{
            return true;
          }
        },
        copy: (element, source) => {
          if(source.id === 'fieldType'){
              this.customizeConcept.dragulaFieldFrom = source.id;
          }
          return source.id === 'fieldType';
        },
        copyItem: (newItem) => {
          this.customizeConcept.dragulaCopy = newItem;
          this.customizeConcept.dragulaCopy2 = newItem;
          return newItem;
        },
        accepts: (element, target, source, sibling) => {
          let fieldContainer = target?.id === 'fieldContainer' && source?.id === 'fieldContainer';
          let fieldSubContainer = target?.id === 'fieldSubContainer' && source?.id === 'fieldSubContainer';
          let fromfieldType = source?.id === 'fieldType' && (target?.id === 'fieldContainer' || target?.id === 'fieldSubContainer' || target?.id === 'fieldContainerInnerInfo' || target?.id === 'fieldSubContainerInnerInfo');
          return fromfieldType || fieldContainer || fieldSubContainer;
        }
      });
    }
  }

  watchSavedPropertiesConcept(){
      this.subscribers.savedPropertiesConceptBroadcast = this.broadcastService.subscribe('savedPropertiesConcept', (data:any)=>{
        if(data.changedCommunicationChannel){
          this.concept.fieldContainers.forEach((container: any) => {
            container.subContainers.forEach((subContainer: any) => {
              if(subContainer.multiple && !subContainer.private){
                subContainer.private = true;
                this.patchModifiedGroup(subContainer);
              }
            });
          });
        }
        if(data.changedAllowPublicShare){
          this.privateField(this.concept.fieldContainers);
        }
      })
  }

  privateField(listContainers: any){
      listContainers.forEach((group: any) => {
        group.fields.forEach((field: any) => {
          if(!field.private && (field.fieldTypeId === 'task' || field.fieldTypeId === 'alert')){
              field.private = true;
              this.saveField(field);
          }
        });
        if(group.subContainers?.length){
            this.privateField(group.subContainers);
        }
      });
  }

  getVisibilityItems(){
    let items:any = [];
    this.apiService.get('visibility', this.evaluationId + '/' + this.concept.conceptId)
      .then((data:any)=>{
        data.forEach((field:any) => {
          if(field.conceptId === this.concept.conceptId && field.dataType !== 'date'){
            items.push(field);
          }
        });
        this.customizeConcept.items = cloneDeep(items);
      }, (errorData:any)=>{})
  }

  hasVisibilities(){
    this.concept.fieldContainers.forEach((fieldContainer:any)=>{
      fieldContainer.visibility = !!(fieldContainer.fieldVisibleValues.length || fieldContainer.listVisibleValues.length);
      fieldContainer.subContainers.forEach((subContainer:any) => {
        subContainer.visibility = !!(fieldContainer.fieldVisibleValues.length || fieldContainer.listVisibleValues.length)
      });
    })
  }


  setVisibilities(){
    this.concept.fieldContainers.forEach((fieldContainer:any)=>{
      fieldContainer.visibility = !!(fieldContainer.listVisibleValues.length);
      fieldContainer.fields.forEach((fieldContainerFields:any) => {
        fieldContainerFields.visibility = !!(fieldContainerFields.listVisibleValues.length);
      });
      fieldContainer.subContainers.forEach((subContainer:any) => {
        subContainer.visibility = !!(subContainer.listVisibleValues.length);
        subContainer.fields.forEach((subContainerFields:any) => {
          subContainerFields.visibility = !!(subContainerFields.listVisibleValues.length)
        });
      });
    })
  }

  getReports(){
    this.apiService.get('reportfields', this.concept.productId)
      .then((data:any)=>{
          this.customizeConcept.reportList = this.commonUtilsService.editList(data, 'reportFieldId', 'name');
      }, ()=>{})
  }

  getTemplates(){
      this.apiService.get('documentobjects/compliancetemplates/byfrontconceptid', this.concept.conceptId)
        .then((data:any)=>{
          this.customizeConcept.templatesList = this.commonUtilsService.editList(data, 'id', 'name');
        }, ()=>{})
  }
  getConcepts(){
    this.apiService.get('concepts/'+ this.evaluationId )
        .then((data:any)=>{
          this.customizeConcept.fieldOriginList = data;
        }, ()=>{})
  }

  getContentType(){
    this.apiService.get('fields', 'contentTypes')
        .then((data:any)=>{
            this.customizeConcept.contentTypeList = this.commonUtilsService.editList(data, 'fieldContentTypeId', 'name')
        }, ()=>{})
  }

  isInstantiated(){
    let that: any = this.customizeConcept;
    this.concept.fieldContainers.forEach((fieldContainer:any)=>{
      fieldContainer.oldName = fieldContainer.name;
      if(fieldContainer.fields.length){
          // this.concept.locked = true;
          // fieldContainer.instantiated = fieldContainer.fields[0].instantiated;
          fieldContainer.instantiated = this.concept.hasInstances;
          let instantiatedFieldContainer: boolean = !!(this.concept && (this.concept.productTypeId === 2 || this.concept.poll) && fieldContainer.instantiated)
          that.showHeaderInfo = instantiatedFieldContainer;
          this.concept.instantiated = instantiatedFieldContainer;
      }
      fieldContainer.subContainers.forEach((subContainer:any) => {
        subContainer.oldName = subContainer.name;
        if(subContainer.fields.length){
            // subContainer.instantiated = subContainer.fields[0].instantiated;
            // fieldContainer.instantiated = subContainer.fields[0].instantiated;
            subContainer.instantiated = this.concept.hasInstances;
            fieldContainer.instantiated = this.concept.hasInstances;
            let instantiatedSubContainer: boolean = !!(this.concept && this.concept.productTypeId === 2 && fieldContainer.instantiated)
            that.showHeaderInfo = instantiatedSubContainer;
            this.concept.instantiated = instantiatedSubContainer;
        }
      })
    })
    let instantiatedConcept: boolean = !!(this.concept.poll && this.concept.instantiated)
    this.concept.disabled = instantiatedConcept;
  }

  addGroup(group:any, fieldContainer:any = null){
    if(group.length=== 13){
      this.modalService.adviceModal(this.translate.instant('concept-list.not-more'), 'error:accept:warning')
    }else{
      let newGroup:any = this.configureNewGroup(fieldContainer);
      this.apiService.add('fieldContainers/'+ this.concept.conceptId, newGroup)
          .then((data:any)=>{
              newGroup.fieldContainerId = data.fieldContainerId;
              newGroup.editable = true;
              newGroup.fields = [];
              newGroup.isHighlight = data.isHighlight;
              let groupSend: any = group;
              if(!fieldContainer){
                  newGroup.subContainers = [];
                  group.push(newGroup);
              }else{
                  newGroup.option='';
                  fieldContainer.subContainers.push(newGroup);
                  groupSend = group.subContainers;
              }
              this.showGroup(newGroup, groupSend);
          }, ()=>{})
    }
  }

  configureNewGroup(fieldContainer:any){
      this.customizeConcept.isNew = true;
      let group:any = {
          name: this.translate.instant('customize-concept.new-group'),
          propertyVisibleValues: [],
          listVisibleValues: [],
          fieldVisibleValues: [],
          references: []
      };
      if(fieldContainer){
          group.name= this.translate.instant('customize-concept.new-subgroup');
          group.hideContainer= false;
          group.parentId = fieldContainer.fieldContainerId;
      }
      return group;
  }



  resetConcept(){
      this.modalService.adviceModal(this.translate.instant('customize-concept.message-delete-instantitated'), 'error:question:warning').subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('concept-instantiated-delete')
          this.deleteInstantiatedConcept()
        }
      })
  }

  deleteInstantiatedConcept(){
    this.apiService.delete('conceptobjects/byconceptid', this.concept.conceptId)
      .then((data:any)=> {
          this.loader.closeLoader(['concept-instantiated-delete']);
          this.customizeConcept.showHeaderInfo = false;
          this.concept.instantiated = false;
          this.changeInstantied();
          this.getVisibilityItems()
      }, (errorData:any)=>{
          this.loader.closeError();
          let text:string = errorData.status === 403? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('concept-list.delete-error-concept');
          this.modalService.adviceModal(text, 'error:accept:warning')

      });
  }

  changeInstantied(){
    this.concept.fieldContainers.forEach((fieldContainer:any)=>{
      fieldContainer.instantiated = false;
      fieldContainer.fields.forEach((fieldContainerFields:any) => {
        fieldContainerFields.instantiated = false;
      })
      fieldContainer.subContainers.forEach((subContainer:any) => {
        subContainer.instantiated = false;
        subContainer.fields.forEach((subContainerFields:any) => {
          subContainerFields.instantiated = false;
        });
      });
    })
    this.loader.closeLoader(['concept-instantiated-delete']);
  }

  showGroup(item:any, groups:any){
    let that: any = this.customizeConcept;
    let openItem = !item.open;
    groups.forEach((group:any) => {
      group.open = false;
      group.fields.forEach((field:any) => {
        field.open = false;
      });
    });
    that.noDraggable = (this.concept.poll && (this.concept.disabled || this.concept.publicShared || this.concept.shared));
    item.open = openItem;
    if(item.open){
        setTimeout(function(){
          let field: any = $('#customize-concept-group-' + item.fieldContainerId)
            field.focus().select();
        })
    }
    if(item.openBlock){
        this.openVisibility(false, item);
    }
  }

  changeOption(subcontainer:any, option:any, newValue:boolean){
      subcontainer[option] = newValue;
      let hasIdentifier:any = false;
      if(option === 'multiple'){
          hasIdentifier = this.findIdentifier(subcontainer);
          if(this.concept.communicationChannel && subcontainer.multiple){
              subcontainer.private = true;
          }
      }else if(option === 'hideContainer'){
          subcontainer.multiple = false;
          subcontainer.isHighlight = false;
      }
      if(subcontainer.multiple || subcontainer.isHighlight){
          subcontainer.hideContainer = false;
      }
      if(hasIdentifier){
          subcontainer.multiple = false;
          this.modalService.adviceModal(this.translate.instant('customize-concept.error-multiple'), 'error:accept:warning')
      }else{
          this.saveChange(subcontainer);
      }
  }

  findIdentifier(subcontainer:any){
    subcontainer.fields.forEach((subField:any) => {
      if(subField.isIdentifier){
        return subField.isIdentifier
      }
    });
    return false;
  }

  fieldContainerNameChange(container:any){
      if(container.name !== container.oldName){
          this.saveChange(container, true);
      }
  }

  toggleSwitchHighlight(fieldContainer:any, newValue:boolean) {
    fieldContainer.isHighlight = newValue;
    this.saveChange(fieldContainer);
  }

  saveChange(fieldContainer:any, isBlur: any = false){
    if(fieldContainer.openBlock && !fieldContainer.visibility){
        this.openVisibility(false, fieldContainer);
    }
    this.patchModifiedGroup(fieldContainer, isBlur);
  }

  patchModifiedGroup(fieldContainer:any, isBlur: any = false){
    this.apiService.patch('fieldContainers', fieldContainer.fieldContainerId, fieldContainer)
    .then(()=>{
        if(isBlur){
            fieldContainer.oldName = fieldContainer.name;
        }
        this.getVisibilityItems()
    }, ()=>{})
  }

  deleteGroup(fieldContainer:any, fieldContainerGroup:any, type:any){
      if(this.checkGroup(fieldContainer)){
          this.modalService.adviceModal(this.translate.instant('customize-concept.group-in-formu').replace('group-in-formu',fieldContainer.name ), 'error:accept:warning')
      }else{
          this.modalService.adviceModal(this.translate.instant('concept-list.delete-question') + type + ' ' + fieldContainer.name + ' ?', 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.loader.openLoader('customize-concept-delete-group')
              this.deletefieldContainerSelected(fieldContainer, fieldContainerGroup, type);
              this.getVisibilityItems()
            }
          })
      }
  }

  checkGroup(fieldContainerGroup:any){
    fieldContainerGroup.fields.forEach((field:any) => {
      return !this.checkField(field);
    })
    if(fieldContainerGroup.subContainers?.length){
      fieldContainerGroup.subContainers.forEach((subContainer:any) => {
        subContainer.fields.forEach((subField:any) => {
          return !this.checkField(subField);
        });
      })
    }
    return false;
  }

  deletefieldContainerSelected(fieldContainer:any, fieldContainerGroup:any, type:any){
      this.apiService.delete('fieldContainers', fieldContainer.fieldContainerId)
      .then(()=>{
          fieldContainerGroup = this.commonUtilsService.deleteElement( fieldContainer, fieldContainerGroup, 'fieldContainerId');
          this.loader.closeLoader(['customize-concept-delete-group']);

          let text : string = this.translate.instant('customize-concept.text') +type+" '"+ fieldContainer.name+"'"+ this.translate.instant('customize-concept.deleted-text');
          this.modalService.adviceModal(text, 'info:accept:check-round')
          this.getVisibilityItems()
          this.conceptState.removeFieldContainerFields(fieldContainer.fieldContainerId);
      }, (errorData:any)=>{
          this.loader.closeError();
          if(errorData.status === 403){
            this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-permissions'), 'error:accept:warning');
          }
      })
  }

  reorderContainer(event: any, list: any){
      let fieldContainerSelected = event.filter((fieldContainer:any, index: any)=>{
        fieldContainer.prevNumOrder = fieldContainer.numOrder;
        fieldContainer.numOrder = index+1;
        return parseInt(this.customizeConcept.dragulaSelectedId) === fieldContainer.fieldContainerId;
      })[0];
      delete this.customizeConcept.dragulaSelectedId;
      let indexOrder= fieldContainerSelected.prevNumOrder<fieldContainerSelected.numOrder?fieldContainerSelected.numOrder:fieldContainerSelected.numOrder-1;
      this.apiService.patch('fieldContainers/'+fieldContainerSelected.fieldContainerId, 'reorder/'+indexOrder).then((data:any)=>{}, (errorData:any)=>{});
  }

  addFieldsInnerInfo(event: any, group: any){
      delete this.customizeConcept.dragulaFieldFrom;
      delete this.customizeConcept.dragulaMovedFrom;
      event.forEach((field:any) => {
        if(!field.fieldId){
          field.numOrder = group.fields.length+1;
        }
      })
      event = event.sort(this.orderNumOrder);
      group.fields = event;
      this.addNewField(group);
  }

  orderNumOrder(a: any, b: any) {
    if(a.numOrder > b.numOrder){
      return 1;
    }
    return a.numOrder < b.numOrder ? -1 : 0;
  }

  reorderFields(event: any, group: any){
      if(this.customizeConcept.dragulaFieldFrom === 'fieldType'){
          delete this.customizeConcept.dragulaFieldFrom;
          delete this.customizeConcept.dragulaMovedFrom;
          group.fields = event;
          this.addNewField(group);
      }else{
        let fieldSelected = event.filter((fieldContainer:any, index: any)=>{
          fieldContainer.prevNumOrder = fieldContainer.numOrder;
          fieldContainer.numOrder = index+1;
          return parseInt(this.customizeConcept.dragulaMovedId) === fieldContainer.fieldId;
        })[0];
        delete this.customizeConcept.dragulaMovedId;
        group.fields = event;
        let indexOrder= fieldSelected.prevNumOrder<fieldSelected.numOrder?fieldSelected.numOrder:fieldSelected.numOrder-1;
        this.apiService.patch('fields/'+fieldSelected.fieldId, 'reorder/'+indexOrder).then((data:any)=>{}, (errorData:any)=>{});
      }
  }


  addNewField(group:any){
    let item = cloneDeep(this.customizeConcept.dragulaCopy);
    delete this.customizeConcept.dragulaCopy;

    for(let i = 0; i < group.fields.length; i++){
        if(!group.fields[i].fieldId){
            item.numOrder = i;
            break;
        }
    }
    item.label = this.translate.instant('customize-concept.new-field') + item.name.toLowerCase();
    this.apiService.add('fields/'+group.fieldContainerId, item)
      .then((data:any)=>{
          data.openFirstTime = true;
          group.fields.splice(item.numOrder, 1, data);
          // this.customizeConcept.disabled = false;
          if(this.concept.allowPublicShare && (data.fieldTypeId === 'task' || data.fieldTypeId === 'alert')){
              data.private = true;
              this.saveField(data);
          }
          this.showField(data, group);
          this.customizeConcept.noDraggable =true;
          this.getVisibilityItems();
      }, (errorData)=>{});

  }

  showFieldOn(e:any, group:any){
    this.showField(e.field, group)
  }

  showField(item:any, group:any){
      if(!item.open){
        group.fields.forEach((field:any) => {
          field.open = false;
        })
      }
      item.open = !item.open;
      if(item.open){
          setTimeout(function(){
            let field: any = $('#customize-field-' + item.fieldId);
            field.focus().select();
          })
      }
  }

  launchActionOn(e:any, fieldContainer:any){
    this.launchAction(e.action, fieldContainer)
  }

  launchAction(action:any, fieldContainer:any){
    if(action.action ==='save'){
      this.saveField(action.field, true);
    }else{
      this.checkField(action.field)? this.deleteField(action.field, fieldContainer): this.modalService.adviceModal(this.translate.instant('customize-concept.field-in-formu').replace('field-in-formu',action.field.label), 'error:accept:warning');
    }
  }

  checkField(field:any){
      let container: any = this.concept.fieldContainers
      let check : any = this.searchFieldInFormu(container, field.fieldId);
      return !check;
  }

  searchFieldInFormu(containers:any, itemId:any){
    for(let containerSelected of containers){
      for(let field of containerSelected.fields){
        if(field.fieldTypeId === 'formu' && (field.formula && field.formula.indexOf(parseInt(itemId))!==-1)){
          return true;
        }
      }
      if(containerSelected.subContainers && containerSelected.subContainers.length){
        this.searchFieldInFormu(containerSelected.subContainers, itemId);
      }
    }
    return false;
  }

  saveField(field:any, modal:boolean=false){
      this.loader.openLoader('customize-concept-save-field')
      if(!field.fieldAttributes){
        field.fieldAttributes = {};
      }
      field.fieldAttributes.private = field.private;
      field.fieldAttributes.fieldNotInForce = field.fieldNotInForce;
      field.fieldAttributes.hiddenInLandings = field.hiddenInLandings;
      field.fieldAttributes.isDisaggregated = field.isDisaggregated;
      field.fieldAttributes.nullsAsZero = field.nullsAsZero;

      if((field.fieldTypeId ==='text' && field.multiline) || (field.fieldTypeId ==='rich' && field.isEditable)){
        field.fieldAttributes.isVoiceRecord = field.isVoiceRecord;
        field.fieldAttributes.voiceRecordOkText = field.voiceRecordOkText;
      }

      if (field.fieldTypeId === 'date') {
          field.fieldAttributes.dateLockType = field.dateLockType;
      }

      if (field.fieldTypeId === 'rich') {
          field.fieldAttributes.isEditable = field.isEditable;
      }

      this.apiService.patch('fields',field.fieldId, field)
          .then((data:any)=>{
              this.loader.closeLoader('customize-concept-save-field');
              this.getVisibilityItems();

              if(modal){
                let text:any = this.translate.instant('customize-concept.the-field') +"'"+ field.label +"'"+ this.translate.instant('customize-concept.save');
                this.modalService.adviceModal(text, 'info:accept:check-round');
              }

              this.conceptState.remove(field);
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = this.translate.instant('customize-concept.delete-error');
              if(errorData.description === 'IdentifierFieldAlreadyExists'){
                text = this.translate.instant('customize-concept.delete-error-exist');
              }else if(errorData.description.indexOf('IsManagerClassificator') !== -1){
                text = this.translate.instant('customize-field-list.manager-classificator-error');
              }
              this.modalService.adviceModal(text, 'error:accept:warning');
          })
  }

  saveVisibilityField(field:any){
      this.apiService.patch('fields',field.fieldId, field)
      .then((data:any)=>{
          this.conceptState.remove(field);
      }, (errorData:any)=>{})
  }

  deleteField(field:any, fieldContainer:any){
    this.modalService.adviceModal(this.translate.instant('customize-concept.delete-question') +"'"+ field.label + "'?", 'info:question:question')
    .subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loader.openLoader('customize-concept-delete-field')
        this.deletefieldSelected(field, fieldContainer);
      }
    })
  }

  deletefieldSelected(field:any, fieldContainer:any){
    this.apiService.delete('fields', field.fieldId)
      .then((data:any)=>{
          fieldContainer.fields = this.commonUtilsService.deleteElement( field, fieldContainer.fields, 'fieldId')
          this.loader.closeLoader(['customize-concept-delete-field']);
          let text : string = this.translate.instant('customize-concept.the-field') +"'"+ field.label +"'"+ this.translate.instant('customize-concept.deleted-text');
          this.modalService.adviceModal(text, 'info:accept:check-round')
          this.getVisibilityItems()
          this.conceptState.remove(field);
      }, (errorData:any)=>{
          this.loader.closeError();
          let text: string  = '';
          if(errorData.status === 403){
            text = this.translate.instant('concept-list.delete-error-permissions');
          }
          if(errorData.status === 500 && errorData.description==="Field in formula"){
            text = this.translate.instant('customize-concept.field-in-formu').replace('field-in-formu',field.label);
          }
          if(text !==''){
            this.modalService.adviceModal(text, 'error:accept:warning')
          }
      })
  }

  modifyGroup(group:any, newValue:any=undefined){
      group.hiddenInLandings = newValue;
      if( group.multiple || group.isHighlight){
          group.hideContainer = false;
      }

      if (group.name.match(/^ *$/) === null) {
          delete group.emptyName;
          this.apiService.patch('fieldcontainers', group.fieldContainerId, group)
              .then((data:any)=> {
              }, (errorData:any)=>{
                if (errorData.status === 401) {
                  this.modalService.adviceModal(this.translate.instant('concept-field.access-denied'), 'error:accept:warning');
                }
              })
      } else {
          group.emptyName = true;
      }
  }

  openVisibilityOn(e:any, group:any){
    group.visibilityOr = e.visibilityOr;
    this.openVisibility(e.openBlock, group)
  }

  openVisibility(openBlock:any, group:any){
      group.openBlock = openBlock;
      if(!openBlock){
          this.modifyGroup(group);
      }
  }

  changeVisibilities(group:any, newValue:boolean){
      group.visibility = newValue;
      if(!group.visibility){
          group.fieldVisibleValues = [];
          group.listVisibleValues = [];
          this.modifyGroup(group);
    }
  }

  changePrivate(group:any, newValue:boolean){
      group.private = newValue;
      if(!group.private){
          this.saveChange(group);
      }else{
          let fieldIdentifier:any = this.identifierField(group);
          if(fieldIdentifier !== null){
              this.modalService.adviceModal(this.translate.instant('customize-concept.identifier-field-error'), 'info:question:question')
                .subscribe((result:any)=>{
                  if(result.result === 'ok'){
                    fieldIdentifier.isIdentifier = false;
                    fieldIdentifier.fieldContentTypeId = '';
                    this.saveChange(group);
                  }else{
                    group.private = false;
                  }
                })
          }else{
            this.saveChange(group);
          }
      }
  }

  identifierField(group:any){
    group.fields.forEach((groupField:any) => {
      if(groupField.isIdentifier && groupField.fieldContentTypeId === 'emai'){
        return groupField;
      }
    });

    if(group.subContainers){
      group.subContainers.forEach((subContainer:any) => {
        subContainer.fields.forEach((subContainerField:any) => {
          if(subContainerField.isIdentifier && subContainerField.fieldContentTypeId === 'emai'){
            return subContainerField;
        }
        });
      });
    }
    return null;
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
