import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ReportsListParentService } from '../utils/reports-list-parent.service';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';

@Component({
  selector: 'reports-list',
  templateUrl: './reports-list.component.html'
})
export class ReportsListComponent implements OnChanges{
  skin: string = this.globalCfg.skin;
  adviceModalSubject: any;
  documentShareModalSubject: any;
  reportsList: any = this.reportListParent.configureItem()
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private commonUtilsService: CommonUtilsService,
    private router: Router,
    private documentActions: DocumentActionsService,
    private reportListParent: ReportsListParentService,
    private reportVersion: ReportVersionFactory) {

    }

    @Input() filterReportsList: any = [];
    @Input() productSelected: any = null;
    @Input() noHasTemplatesReports:boolean = false;
    @Input() userPermissions: string = 'none';
    @Output() launchAction = new EventEmitter;

  ngOnChanges(): void {
    this.reportListParent.onChanges(this);
  }

  orderListDate(a:any, b:any) {
    if( a.creationDate < b.creationDate){
      return 1;
    }
    return a.creationDate > b.creationDate ? -1 : 0;
  }

  filterReport(type:string = ''){
    this.reportListParent.filterReport(type, this);
  }


  orderDate(type:string){
    if(this.reportsList.dateOrder === type){
        this.reportsList.orderOperationDates = !this.reportsList.orderOperationDates;
    }else{
      this.reportsList.dateOrder = type;
        if(type === 'title'){
          this.reportsList.orderOperationDates = (type !== 'title');
        }

    }
}

  toggleSearch() {
   this.reportListParent.toggleSearch(this)
  }

  createNewReport(){
    this.reportListParent.createNewReport(this);
  }

  editReport(report:any){
    this.loader.openLoader('dashboard-panel-new-report')
    if(!report.type){
      this.router.navigate(["evaluation", report.evaluationId,"reports"])
    }else{
      this.commonUtilsService.registerEvent('open report', this.context.settings.defaultProduct,report.reportId, 'report')

      if(report.reportId === this.reportVersion.folderReportVersion){
        this.router.navigateByUrl('report-detail/'+report.reportObjectId);
      }else{
        this.router.navigate(["evaluation",report.evaluationId, "reports", report.reportId, "report-related",report.reportObjectId]);
      }
    }
  }

  openReport(report:any){
      this.documentActions.doAction('edit', report);
  }

  downloadReport(report:any){
      this.documentActions.doAction('download', report);
  }

  deleteReport(report:any, last: boolean = false){
    this.reportListParent.deleteReport(report, this, last)
  }

  changeView(){
      this.commonUtilsService.goToStateUrl();
  }

  openFocus(type:string){
      this.reportsList['openFocus'+type] = !this.reportsList['openFocus'+type];
  }

  resetFilter(e:any, type:any){
    this.reportListParent.resetFilter(e, type, this);
  }

  goToCustomReports() {
    this.router.navigate(['custom-reports']);
  }

  filterElementOn(event:any, type:string){
    this.reportListParent.selectTypeOperation(event.source, type, this)
  }

  // ngOnDestroy(){
  //   if(this.adviceModalSubject){
  //       this.adviceModalSubject.unsubscribe();
  //   }
  //   if(this.documentShareModalSubject){
  //     this.documentShareModalSubject.unsubscribe();
  //   }
  // }
}
