import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { FrontLoginParentService } from '../utils/front-login-parent.service';



@Component({
  selector: 'front-login-custom',
  templateUrl: './front-login-custom.component.html'
})
export class FrontLoginCustomComponent implements OnInit {
    skin:string = this.globalCfg.skin;
    showList:any = false;
    snapshot: any = this.route.snapshot;
    frontLogin:any = this.frontLoginParent.configureFrontLogin(this, this.route.snapshot);

  constructor(private translate: AfTranslateFactory,
      private apiService: ApiServiceService,
      private route: ActivatedRoute,
      private customLanding: CustomLandingFactory,
      private loader: LoaderFactory,
      private globalCfg: GlobalCfgFactory,
      private commonUtilsService: CommonUtilsService,
      private apiProperties: ApiPropertiesFactory,
      private registerService: RegisterMixtapeService,
      private frontLoginParent: FrontLoginParentService) {
    }

    ngOnInit(): void {
      this.frontLoginParent.onInit(this, this.route.snapshot);
      this.configureInitCustom();
    }

    clickAction(e: any=null){
      this.frontLoginParent.clickAction(this, e);
    }

    resetPassword(){
      this.frontLoginParent.resetPassword(this);
    }

    sendReset(){
      this.frontLoginParent.sendReset(this);
    }

    doLogin(loginForm: any) {
      this.frontLoginParent.doLogin(this, loginForm);
    }

    tryAgain(){
      this.frontLoginParent.tryAgain(this);
    }

    configureInitCustom(){
      if (this.frontLogin.public) {
          this.getPublicConfiguration();
      } else if(this.frontLogin.isLandingLogin){
          this.findGlobal();
      }
    }

    getPublicConfiguration() {
        this.apiService.get('concepts/publicconfiguration?access=' + encodeURIComponent(this.route.snapshot.queryParams['access']), null, true)
            .then((data: any)=> {
                if (data.anonymous) {
                    this.frontLogin.askingNref = true;
                    this.frontLogin.inputLabel = this.translate.instant('front-login.insert-nref');
                    this.frontLogin.inputIcon = 'lf-icon-code';
                    this.frontLogin.inputPlaceholder = this.translate.instant('front-login.nref-placeholder');
                    this.frontLogin.anonymous = data.anonymous;
                }
                this.getGlobalCustom('company/globalcustomization/byconceptobject', data.conceptObjectId);
            }, (errorData:any)=> {
                this.loader.closeError();
            });
    }

    findGlobal() {
        let targetSplit: any;
        let target = this.route.snapshot.queryParams['target'];
        if(target.indexOf('task') !== -1){
            targetSplit = target.split('task/')[1];
            this.getGlobalCustom('company/globalcustomization/bytask', targetSplit);
        }else if(target.indexOf('concept-evaluated') !== -1){
            let position1 = target.indexOf('concept-evaluated');
            let position = target.indexOf('/', position1)+1;
            let positionEnd = target.indexOf('/', position) !== -1?target.indexOf('/', position):target.length;
            let conceptObjectId = target.substring(position, positionEnd);
            this.getGlobalCustom('company/globalcustomization/byconceptobject', conceptObjectId);
        }else if(target.indexOf('meeting') !== -1){
            targetSplit = target.split('meeting/');
            let meetingSplit = targetSplit[1].split('/');
            this.getGlobalCustom('company/globalcustomization/bytask', meetingSplit[0]);
        }else{
            this.getGlobalCustom('company/globalcustomization', 'default');
        }
    }

    getGlobalCustom(url:string, id:any){
        this.loader.openLoader('front-login-initialize');
        this.apiService.getNoBearer(url, id)
            .then((data: any)=>{
                this.configureCustom(data);
            }, (errorData:any)=>{
                this.loader.closeError();
                this.configureCustom(null);
            })
    }

    configureCustom(data: any){
        this.frontLogin.templateHtml='./app/components/front-login/templates/front-login-custom.template.html';
        if(data){
            data = this.commonUtilsService.configureLogos(data);
            if(data.defaultCustomization){
                data.textColor = '#fff';
            }
            if(data.logo){
                this.frontLogin.logo = data.logoOther;
            }
            this.frontLogin.footerConceptPublic = {
                linkedin: data.linkedin,
                facebook: data.facebook,
                twitter: data.twitter,
                youtube: data.youtube,
                footer: data.footer,
                public: this.frontLogin.public,
                color: data.color
            };
        }
        this.frontLogin.custom = data;
        this.customLanding.setCustom(this.frontLogin.custom);
        this.loader.closeLoader(['front-login-initialize']);
    }
}
