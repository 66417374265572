import { Injectable } from '@angular/core';
import { CommonUtilsService } from '../common-utils/common-utils.service';
import { DateConverterService } from '../date-converter/date-converter.service';
import { get, cloneDeep } from "lodash";
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilterColumnService {

  constructor(private commonUtils: CommonUtilsService,
    private dateConverter: DateConverterService) { }

    extractList(type:string, list: any, withComma: boolean = false){
      let uniqueList: any = [];
      if(list !== null && list.length){
          list.map((item: any)=>{
              let isInArray = false;
              let name = item[type];
              if(typeof item[type] === 'string'){
                  name = item[type].trim()
              }
              if(name === null || name ==='' || (!name && type !=='esPrioritaria')){
                  isInArray = true;
              }
              for(let i = 0; i < uniqueList.length;i++){
                  if(name === uniqueList[i].name){
                      isInArray = true;
                      break;
                  }
              }
              if(!isInArray){
                  let configureItem: any = {
                      name: name,
                      index: uniqueList.length
                  }
                  if(type === 'createdBy' || type === 'author' || type === 'creator'){
                      configureItem.isDeleted = item[type+'IsDeleted'];
                  }
                  if(((type === 'materia' && item.namedKey === 'Consulta') || withComma) && name.indexOf(',') !== -1){
                      this.checkNameMateria(name, uniqueList);
                  }else{
                      uniqueList.push(configureItem);
                  }
              }
          })

      }
      return uniqueList;
  }

  extractChildrenList(type:string, typeChildren:string, list: any, withComma: boolean = false){
    let listChildren: any = [];
    list.forEach((elementList:any)=>{
      if(elementList[type]?.length){
        elementList[type].forEach((elementChild:any)=>{
          listChildren.push(elementChild);
        })
      }
    })
    return this.extractList(typeChildren, listChildren, withComma);
  }

  checkNameMateria(name: string, uniqueList:any){
    let listNames = name.indexOf(', ') === -1? name.split(',') : name.split(', ')
    listNames.forEach((newName:any)=>{
      if(!(uniqueList.filter((unique: any)=> unique.name === newName).length)){
        let configureItem = {
          name: newName
      }
      uniqueList.push(configureItem);
      }
    })
  }

  configureFilterList(list: any, item: any){
    if(item.selected){
        let index = list.findIndex((listItem:any)=>{return listItem.name === item.name});
        list.splice(index, 1);
    }else{
        list.push(item);
    }

    return list;
  }

  filterByWord(word:string, list: any, type:string){
    let filterList: any = [];
    if(word === ''){
        return list;
    }
    list.forEach((item:any)=>{
      let value = get(item,type,'');
      let characters: any={
          "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
          "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
          "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
          "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let regExpCharacters=/[áàéèíìóòúùñ]/ig;
      if(value !== '' && value !== null){
          let valueWithoutAccent = value.replace(regExpCharacters,(e:any)=>{return characters[e]});
          let wordWithoutAccent = word.replace(regExpCharacters,(e:any)=>{return characters[e]});
          if(valueWithoutAccent.toLowerCase().indexOf(wordWithoutAccent.toLowerCase()) !== -1){
              filterList.push(item);
          }
      }
    })
    return filterList;
  }

  filterByWordChildren(word: string, list: any, type: string, children: any){
    let filterList: any = [];
    if(word === ''){
        return list;
    }
    list.forEach((item:any)=>{
      let childrenList = item[children];
      let elementCopy = cloneDeep(item);
        elementCopy[children] = [];
        let addList = false;
        for(let j = 0; j < childrenList.length; j++){
            let item = childrenList[j];
            let value = get(item,type,'');
            let characters: any ={
                "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
                "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
                "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
                "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
            let regExpCharacters=/[áàéèíìóòúùñ]/ig;
            if(value !== '' && value !== null){
              let valueWithoutAccent = value.replace(regExpCharacters,(e:any)=>{return characters[e]});
              let wordWithoutAccent = word.replace(regExpCharacters,(e:any)=>{return characters[e]});
                if(valueWithoutAccent.toLowerCase().indexOf(wordWithoutAccent.toLowerCase()) !== -1){
                    elementCopy[children].push(item);
                    addList = true;
                }
            }
            if(addList && childrenList.length-1 === j){
                filterList.push(elementCopy);
            }
        }
    })
    return filterList;
  }

  filterBycolumn(list: any, listFilter: any, type: string, withComma: boolean = false){
    let filterList: any = [];
    if(!listFilter || !listFilter.length){
        return list;
    }
    listFilter.forEach((filter: any) => {
        let listFilterType = String(filter.name);
        list.forEach((item: any) => {
          let listType = String(item[type]);
          if(listFilterType && listType &&
              (listFilterType.trim() === listType.trim() || (this.checkColumnMateria(listFilterType, listType, type, filterList, withComma) &&
              (item.namedKey === 'Consulta' || withComma)))){
              filterList.push(item);
          }
        });
    });
    return filterList;
  }

  checkColumnMateria(listFilterType:string, listType:string, type:string, filterList:any, withComma:boolean){
    if((type === 'materia' || withComma) && listType.indexOf(',') !== -1 && this.checkUnique(filterList, listType, type)){
        let listNames = listType.indexOf(', ') === -1? listType.split(',') : listType.split(', ');
        return Boolean(listNames.filter((newName:any) => newName === listFilterType));
    }
    return false;
  }

  checkUnique(list: any, value:any, type:string){
    return Boolean(list.filter((item:any) => value === item[type]));
  }

  filterBycolumnChildren(list: any, listFilter: any, type:string, children: any){
    let filterList: any = [];
    if(!listFilter || !listFilter.length){
        return list;
    }
    list.forEach((item: any) => {
      let childrenList = item[children];
      let elementCopy = cloneDeep(item);
      elementCopy[children] = [];
      let addList = false;
      childrenList.forEach((childrenItem: any, index: any) => {
          listFilter.forEach((itemFilter: any) => {
              if(childrenItem[type].trim() === itemFilter.name.trim()){
                  elementCopy[children].push(childrenItem);
                  addList = true;
              }
          })
          if(addList && childrenList.length-1 === index){
              filterList.push(elementCopy);
          }
      })
    });
    return filterList;
  }

  filterBycolumnAllChildren(list: any, listFilter: any, type: string, children: any){
    let filterList: any = [];
    if(!listFilter || !listFilter.length){
        return list;
    }
    list.forEach((item: any) => {
      let childrenList = item[children];
      let elementCopy = cloneDeep(item);
      elementCopy[children] = [];
      let addList = false;
      if(childrenList){
        for(let j = 0; j < childrenList.length; j++){
            let childrenItem = childrenList[j];
            for(let k = 0; k < listFilter.length; k++){
                if(childrenItem[type].trim() === listFilter[k].name.trim()){
                    elementCopy[children] = childrenList;
                    addList = true;
                    break;
                }
            }
            if(addList && childrenList.length-1 === j){
                filterList.push(elementCopy);
            }
        }
      }
    })

    return filterList;
  }

  filterByCalendar(list: any, column: any){
    let filterList: any = [];
    if(column.apply === null){
        return list;
    }

    list.forEach((item: any) => {
      switch(column.apply){
        case 'all':
            if(new Date(item[column.id]).valueOf() >= column.from.valueOf() && new Date(item[column.id]).valueOf() <= column.until.valueOf()){
                filterList.push(item);
            }
            break;
        case 'from':
            if(new Date(item[column.id]).valueOf() >= column.from.valueOf()){
                filterList.push(item);
            }
            break;
        case 'until':
            if(new Date(item[column.id]).valueOf() <= column.until.valueOf()){
                filterList.push(item);
            }
            break;
      }

    });

    return filterList;
  }

  filterByCalendarFromTo(list: any, from: any, to: any, filter: string){
    let filterList: any = [];
    list.forEach((item: any) => {
      let dateToFind = item[filter]
      let date = moment(dateToFind.substring(0, dateToFind.indexOf('T')));
      if(date.isBetween(from, to)){
          filterList.push(item)
      }
    });

    return filterList;
  }
  filterByRangeDates(list: any, type: any, dates: any){
    let filterList: any = [];
    if(!dates.from && !dates.to){
        return list;
    }
    let from: any = new Date(dates.from);
    let to: any = new Date(dates.to);
    from = this.dateConverter.toIso(from);
    to = this.dateConverter.resetDateLastSecond(to);
    list.forEach((column:any) => {
      let toDate:any = column[type]
      if(toDate !== null && toDate.indexOf('/') !== -1){
        let date:any = (toDate).split('/');
        toDate = moment.utc(date[1]+'-'+date[0]+'-'+date[2]).format()
      }
      if(toDate >= from && toDate <= to){
        filterList.push(column);
      }
    });
    return filterList;
  }
}
