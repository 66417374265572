<div class="common__field-type" >
  <label class="common__input-block common__input-block--field"
      [ngClass]="{'common__input-block--show-references': fieldTypeFile.references && fieldTypeFile.field.evaluatedValue?.conceptDataId !== null,
          'common__show-alert': fieldTypeFile.field.error,
          'common__input-no-editable':fieldTypeFile.field.noEditable,
          'common__input-block--custom-field':isLanding}"
      [attr.disabled]="fieldTypeFile.field.noEditable?true:null">
      <div class="common__label-block"
          [ngClass]="fieldTypeFile.field.evaluatedValue?.hasReferences?'common__label-block--two-icon':'common__label-block--one-icon'">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeFile.field.error,
                      'common__input-text--no-editable':fieldTypeFile.field.noEditable}">{{fieldTypeFile.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeFile.field.required">*</span>
          <div class="common__info-icon-block"
              [ngHide]="fieldTypeFile.field.noEditable || !fieldTypeFile.field.description">
              <tooltip-info [description]="fieldTypeFile.field.description"
                  [field]="fieldTypeFile.field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeFile.field.references?.length && !fieldTypeFile.field.noEditable">
              <tooltip-law [references]="fieldTypeFile.field.references"
                  [field]="fieldTypeFile.field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeFile.field.relatedInfo?.length && !fieldTypeFile.field.poll && !fieldTypeFile.field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(fieldTypeFile.field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
          <div class="common__info-icon-block common__info-icon-block--in-report"
          *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
          <tooltip-in-report [inReport]="fieldTypeFile.inReport"></tooltip-in-report>
        </div>
      </div>
      <div class="float-end">
          <i class="lf-icon-connected-data field-type-text__icon-references"
              [ngShow]="fieldTypeFile.field.evaluatedValue?.hasReferences"
              (click)="showReference($event)"
              ngbTooltip="{{'common.see-dependencies' | afTranslate}}"
              placement="left"
              container="body"></i>
          <button class="lf-icon-up-file common__input-icon common__input-icon-active"
              [ngClass]="{'common__input-icon-active--disabled': fieldTypeFile.field.noEditable}"
              (click)="addDocuments()"
              [disabled]="fieldTypeFile.field.noEditable"> </button>
      </div>
      <ul class="field-item__file-list"
        *ngIf="fieldTypeFile.field.evaluatedValue?.documents && fieldTypeFile.field.evaluatedValue?.documents.length">
          <li class="field-item__file-item"
              *ngFor="let document of fieldTypeFile.field.evaluatedValue?.documents">
              <i class="lf-icon-link field-item__file-link"
                  [ngShow]="!(isLanding)"></i>
              <p class="field-item__file-text"
                  [ngClass]="{'field-item__file-text--custom': isLanding}">{{document.name}}</p>
              <i class="lf-icon-download field-item__file-icon field-item__file-icon--download"
                  [ngClass]="{'field-item__file-icon--custom-download': isLanding}"
                  [ngShow]="(concept?.conceptObjectId && !fieldTypeFile.field.noEditable) && !fieldTypeFile.lockedSavedConcept"
                  (click)="downloadFile(document)"></i>
              <i class="field-item__file-icon"
                  [ngShow]="document.canBeDeleted && !fieldTypeFile.field.noEditable"
                  [ngClass]="isLanding?'lf-icon-close-round field-item__file-icon--custom-delete':'lf-icon-close field-item__file-icon--delete'"
                  (click)="removeDocument(document)"></i>
          </li>
      </ul>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeFile.field.error && fieldTypeFile.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
      </div>
  </label>
</div>
