<div *ngFor="let listValue of treeListFilials.filterList | byWord:treeListFilials.findDropdown:treeListFilials.fieldToFind">
  <div class="common__select-item common__ellipsis"
      [ngClass]="{'common__select-item--tree':treeListFilials.field.isTree}"
      [ngStyle]="{'padding-left': treeListFilials.paddingTree+'px'}"
      (click)="launchAction($event, listValue, 'addOrDeleteSelectedValue')"
      [ngHide]="listValue.deleteError">
      <i class="field-type-list__icon-angle-filials"
          [ngClass]="{'field-type-list__icon-angle-filials--visible': listValue.children?.length,
            'lf-icon-angle-down':listValue.show,
            'lf-icon-angle-right':!listValue.show}"
          (click)="launchAction($event, listValue, 'showChildren')"></i>
      <i class="field-item__icon-check"
          [ngClass]="listValue.selected?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
      <span>{{listValue.text}}</span>
      <i class="lf-icon-check-round-full common__select-icon"
          [ngClass]="{'common__select-icon--select': !listValue.isUserValue}"></i>
      <i class="lf-icon-edit common__select-icon"
          [ngShow]="listValue.isUserValue"
          (click)="launchAction($event, listValue, 'editListValue')"></i>
      <i class="lf-icon-trash common__select-icon"
          [ngShow]="listValue.isUserValue"
          (click)="launchAction($event, listValue, 'deleteListValue')"></i>
  </div>
  <div *ngIf="listValue.children?.length">
      <tree-list-filials [ngShow]="listValue.show"
        [field]="treeListFilials.field"
        [findDropdown]="treeListFilials.findDropdown"
        [fieldToFind]="treeListFilials.fieldToFind"
        [filterList]="listValue.children"
        [orderTree]="treeListFilials.orderTree+1"
        (treeMultipleEmit)="treeMultipleOn($event)">
      </tree-list-filials>
  </div>
</div>

