<div class="dashboard-panel dashboard-panel--v2">
    <header-nav></header-nav>
    <div class="dashboard-panel__container-block-v3">
        <div class="dashboard-panel__container-v3-buttons">
            <button class="dashboard-panel__button-show-documentation"
              (click)="gotToAllDocuments(dashboardPanel.productSelected.productId)">
            <span [afTranslate]="'dashboard.show-all-documents'">_Ver todos los documentos</span>
            <i class="lf-icon-documentation dashboard-panel__icon-button-header"></i>
            </button>
        </div>
        <div class="dashboard-panel__container-v3">
            <div class="dashboard-panel__block-50-percent-v3">
                <clients-list-uk [clientList]="dashboardPanel.clientList"
                    (launchAction)="launchAction($event)"></clients-list-uk>
            </div>
            <div class="dashboard-panel__block-50-percent-v3 dashboard-panel__block-50-percent-v3--right">
                <client-details [selectClient]="dashboardPanel.selectedClient"
                                (launchAction)="launchAction($event)"></client-details>
            </div>
            <div class="dashboard-panel__block-50-percent-v3">
                <concept-instances-list-uk [filterInstancesList]="dashboardPanel.filterInstancesList"></concept-instances-list-uk>
            </div>
            <div class="dashboard-panel__block-50-percent-v3 dashboard-panel__block-50-percent-v3--right">
                <available-processes [processesList]="dashboardPanel.processesList"></available-processes>
            </div>
        </div>
    </div>
    <compliance-footer [ngShow]="dashboardPanel.allLoad"></compliance-footer>
</div>
