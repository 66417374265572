import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service/api-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ActivatedRoute } from '@angular/router';
import { DownloadService } from '../download/download.service';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ModalServiceService } from '../modal-service/modal-service.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Injectable({
  providedIn: 'root'
})
export class DocumentActionsService {
  viewExtensions: any = ['pdf', 'gif', 'png', 'txt', 'jpg', 'jpeg'];
  // user: any = this.context.user?this.context.user.login:null;
  constructor(private router: ActivatedRoute,
    private translate: AfTranslateFactory,
    private apiProperties: ApiPropertiesFactory,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private apiService:ApiServiceService,
    private downloadService: DownloadService,
    private modalService: ModalServiceService) { }

  doAction(action: any, file: any, type: any=''){
    if (action === 'open') {
        action = this.viewExtensions.includes(file.extension.toLowerCase()) ? 'view' : 'edit';
    }
    let url: string = '';
    switch (action) {
        case 'edit':
            this.editOfficeOnline(file, type);
            break;
        case 'view':
            this.viewDocument(file);
            break;
        case 'download':
            url = 'file='+ file.editPath;
            this.downloadDocument(file, url);
            break;
        case 'sibila':
            this.openSibila(file);
            break;
        case 'downloadField':
            url = 'file='+ file.editPath + '&conceptDataId=' + file.conceptDataId;
            this.downloadDocument(file, url);
            break;
    }
  }

  editOfficeOnline(file: any, typeDocument: any){
    let id = file.reportDocumentId && file.reportDocumentId !== -1?file.reportDocumentId:file.documentObjectId;
    let type = file.reportDocumentId && file.reportDocumentId !== -1?'report-document':'document';
    if(typeDocument === 'template'){
        id = file.documentId;
        type = 'template';
    }
    window.open('/office-online/'+type+'/'+id, '_blank');
  }

  downloadDocument(file: any, url: any){
    this.loader.openLoader('download-document-actions');
    this.apiService.getBlob('utils/file/download?' + url)
        .then((data:any) => {
            let fileName: any = data.fileName?data.fileName:file.name;
            this.downloadService.triggerDownload(data.blob, fileName);
            this.loader.closeLoader(['download-document-actions']);
        }, (error:any) => {
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('document-actions.error-download-document'),'error:accept:warning');
        });
  }

  viewDocument(file: any){
    let url = '';
    if(file.editPath){
      url += 'file='+file.editPath;
    }
    let conceptObjectId = file.conceptObjectId?file.conceptObjectId:this.router.snapshot.params['conceptObjectId'];
    if(conceptObjectId){
      url += url?'&':'';
      url += 'conceptObjectId='+conceptObjectId;
    }
    let clientid = file.clientid?file.clientid:this.router.snapshot.params['clientid'];
    if(clientid){
      url += url?'&':'';
      url += 'clientid='+clientid;
    }
    if(file.ispublic === 'true'){
      url += url?'&':'';
      url += 'ispublic='+file.ispublic;
    }
    if(file.viewform === 'true'){
      url += url?'&':'';
      url += 'viewform='+file.viewform;
    }
    this.apiService.getBlob('utils/file/view?'+ url)
        .then((data:any) => {
            window.open(URL.createObjectURL(data.blob), '_blank');
        }, (error:any) => {});
  }

  openSibila(file: any){
    let sibilaUrl: any = this.apiProperties.onlineSibilaUrl.replace(/\{0\}/g, this.context.user.externalAccessId);
    let formSibila = $('<form style="display:none" action="'+sibilaUrl+'" id="sibilaForm" target="_blank" method="post"></form>');
    if (file.text) {
        let inputText = $('<input/>');
        inputText.attr('type', 'hidden');
        inputText.attr('name', 'textoSibila');
        inputText.attr('value', file.text);
        formSibila.append(inputText);
    }
    $('body').append(formSibila);
    formSibila.submit();
    setTimeout(()=> {
      $('#sibilaForm').remove();
    }, 500)
  }
}
