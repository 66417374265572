import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'reportExtractIso'
})
export class ReportExtractIsoPipe implements PipeTransform {

  transform(value: string, columns:any): any {
    for(let i = 0; i < columns.length; i++){
      if(value && value !== null && columns[i].fieldTypeId === 'date'){
        return moment(value.substring(0, value.indexOf('T'))).format('DD/MM/YYYY');
      }
    }
    return value;
  }

}
