<div class="customize-field-text">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [ngModel]="customizeFieldMoney.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldMoney.tinyMceOptions"
      [modelText]="customizeFieldMoney.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldMoney.contentReportInfo"
            [preSelectItem]="customizeFieldMoney.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="customizeFieldMoney.concept"
            [selectedProperties]="customizeFieldMoney.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldMoney.field.listVisibleValues"
            [selectedFields]="customizeFieldMoney.field.fieldVisibleValues"
            [orCondition]="customizeFieldMoney.field.visibilityOr"
            [readonly]="customizeFieldMoney.field.noEditable || concept.disabled"
            [itemId]="customizeFieldMoney.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-list-simple
            [field]="customizeFieldMoney.moneyTypeInfo"
            [preSelectItem]="customizeFieldMoney.field.currencyId"
            [listValues]="customizeFieldMoney.moneyTypeList"
            (itemSelected)="itemSelected($event, 'currencyId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-number [field]="customizeFieldMoney.defaultValueInfo"
            [preSelectItem]="customizeFieldMoney.field.defaultValue"
            (itemSelected)="itemSelected($event, 'defaultValue')">
        </field-type-number>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldMoney.field.required"
        [disabled]="customizeFieldMoney.field.noEditable || concept.disabled"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldMoney()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.cancel'">Cancelar </button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldMoney()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.save'">Guardar </button>
    </div>
</div>
