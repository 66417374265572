import { Injectable } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReportsListParentService {
  constructor(private context: ContextFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private filterColumnService: FilterColumnService,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private registerService: RegisterMixtapeService) { }

  configureItem(){
    return {
      configuredTotal:{
        first: 1,
        last: 50
      },
      configPagination: {
        pageSize: 50,
        maxSize: 5
      },
      currentPage: 1,
      adminPermissions: this.context.user.isAdmin && this.context.user.companyId,
      orderOperationDates: true,
      openFocusTitle: false,
      dateOrder: 'modificationDate',
      searchReport: '',
      titleFilter: '',
      filters: this.globalCfg.skin==='icam-red'?['name']:['name', 'evaluationName'],
      filterReportsList:[],
      reports:[],
      lastReport: {},
      nameFilter: {
        name: this.translate.instant('common.type'),
        id: this.commonUtilsService.createId()
      },
      evaluationNameFilter: {
        name: this.globalCfg.dashboardVersion === '2'? this.translate.instant('common.channel') : this.translate.instant('common.implantation'),
        id: this.commonUtilsService.createId()
      },
      noGeneratedReports: false,

    }
  }
  onChanges(child:any){
    let that: any = child.reportsList;
    if(child.filterReportsList){
      that.filterReportsList = cloneDeep(child.filterReportsList);
      that.amountElements = this.commonUtilsService.findByWord(that.filterReportsList, that.searchReport, ['title', 'name', 'evaluationName']).length
      that.reports = cloneDeep(child.filterReportsList);
      that.noGeneratedReports = !(child.filterReportsList && child.filterReportsList.length);
      this.getFilters(child);
    }
    that.productSelected = child.productSelected;
    that.hasReports = Boolean(child.filterReportsList?.length)
  }

  selectLastReport(child:any){
    let that: any = child.reportsList;
    that.reports.sort(child.orderListDate);
    that.lastReport = that.reports[0];
    that.reports.splice(0, 1);
  }

  getFilters(child:any){
    let that: any = child.reportsList;
    that.filters.forEach((filter: any) => {
      that[filter+'List'] = this.filterColumnService.extractList(filter, that.filterReportsList)
      that[filter+'ListFilter'] = [];
    });
  }

  filterReport(type: string, child: any){
    let that: any = child.reportsList;
    let filterList:any = [];
    filterList = this.filterColumnService.filterByWord(that.titleFilter, that.reports , type);
    that.filters.forEach((filter:any) => {
      let filteredList = that[filter+'ListFilter'];
      filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    });
    that.filterReportsList = filterList;
    that.amountElements = this.commonUtilsService.findByWord(that.filterReportsList, that.searchReport, ['title', 'name', 'evaluationName']).length
  }

  selectTypeOperation(item:any, type:string, child:any){
    let that: any = child.reportsList;
    let listFilter:any = that[type+'ListFilter'];
    that[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    that[type+'Filter'].hasFilter = that[type+'ListFilter'].length;
    this.filterReport(type, child);
  }
  toggleSearch(child:any){
    let that: any = child.reportsList;
    that.search = !that.search;
    if (!that.search) {
        that.searchReport = '';
    }else{
      let resgisterItem:any = {
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'search reports',
        'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem)
    }
  }
  createNewReport(child:any){
    child.launchAction.emit({
      action:{
          action:'newReport'
      }
    })
  }
  deleteReport(report:any, child:any, last: boolean = false, ){
    this.modalService.adviceModal(this.translate.instant('reports-list.delete-report')+ report.title+'?', 'info:question:question').subscribe((result:any) => {
      if(result.result === 'ok'){
        this.loader.openLoader('report-list-delete-report');
        this.commonUtilsService.registerEvent('delete report', this.context.settings.defaultProduct, report.reportId, 'report')
        child.launchAction.emit({
          action:{
              action:'deleteReport',
              report: report,
              last
          }
        })
      }
    });
  }

  resetFilter(e:any, type:any, child:any){
    e.stopPropagation();
    e.preventDefault();
    child.reportsList.titleFilter = '';
    this.filterReport(type, child);
  }


}
