import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';

@Injectable({
  providedIn: 'root'
})
export class StatesProcessParentService {

  constructor(private userPermisions: UserPermisionsFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private registerService: RegisterMixtapeService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory) {
  }

  configureInit(child: any){
    if(this.context.user.userId){
      child.statesProcess.userId = (this.context.user.userId + 'U').toString();
    }else if(this.context.user.contactId){
      this.context.user.userId = this.context.user.contactId;
      child.statesProcess.userId = (this.context.user.contactId + 'C').toString();
    }
  }

  configureChanges(child: any){
    let that: any = child.statesProcess;
    that.item = child.item;
    if(child.concept){
      that.userPermissions = child.concept?.conceptObjectId === 'viewEdit'?'none': this.userPermisions.getPermissions();
      that.concept = child.concept;
    }
    if(child.type){
      this.configureByType(child.type, child);
    }
  }

  configureByType(type:string, child: any){
    let that: any = child.statesProcess;
    switch(type){
      case 'processConcept':
        that.titleHeader = this.translate.instant('public-link.process-management');
        this.configureVM(that.concept.processStates, child);
        break;
      case 'processTask':
        this.configureVM(that.concept.processStates, child);
        break;
      case 'normalTask':
          that.iconName = this.translate.instant('states-process.state-flow');
          this.configureStatesTaskInit(child);
          break;
    }
  }

  changeProcessQuestion(itemSelected:any, option:any, child:any){
    let that: any = child.statesProcess;
    let processTaskResponsibles: any = that.summary.current.processTaskResponsibles;
    if(child.typeView === 'changeStateModal'){
        child.changeStateModal.emit({
            state:{
                current:that.summary,
                itemSelected:itemSelected,
                option:option
            }
        })
    }else if(child.type !== 'normalTask' && that.summary && that.concept.shareUrl !== null && processTaskResponsibles.length && this.commonUtilsService.checkResponsibles( that.summary.current.processTaskResponsibles,  this.context.user.userId)){
        this.modalService.adviceModal(this.translate.instant('states-process.not-responsible'), 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result === 'ok'){
              this.changeProcess(itemSelected, option, child);
            }
          })
    }else{
      this.changeProcess(itemSelected, option, child);
    }
  }

  confirmChange(child:any){
    switch(child.type){
        case 'processConcept':
        case 'processTask':
            child.type === 'processConcept' && this.globalCfg.skin === 'icam-red' && child.concept.conceptNamedKey === 'Consulta'? this.checkShowInfoModal(child) : this.changeStateProcess(child);
            break;
        case 'normalTask':
            this.changeStates(child);
            break;
        default:
            break;
    }
  }

  showProcessModal(child:any){
    let that: any = child.statesProcess;
    if(child.type === 'normalTask'){
        this.modalService.statesTaskHelp();
    }else{
        let registerItem ={
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':'open process',
            'Concept Name': sessionStorage.getItem('breadcrumbConcept') + child.concept.title,
            'Process Name': child.concept.processStates.current.name,
            'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(registerItem);
        // let evaluationId = child.evaluationId && !child.item.evaluationId? child.evaluationId : child.item.evaluationId;
        let configuration = {
          evaluationId: child.evaluationId && !child.item.evaluationId? child.evaluationId : child.item.evaluationId,
          concept: child.concept,
          conceptObjectId: child.item.conceptObjectId,
          canChangeState: that.canChangeState,
          canAddAlerts: that.canAddAlerts,
          canAddTask: false,
          preview: !that.canChangeState,
          summary:  that.summary
        }
        this.modalService.processModal(configuration)
          .subscribe((result:any)=>{
              this.getProcess(child);
          })
    }
  }

  configureStatesTaskInit(child:any){
    child.statesProcess.taskSelectState = {
        taskStateId: child.item.taskStateId,
        state: child.item.state,
        stateActive: true
    }
    this.getTaskStates(false, child);
  }

  getTaskStates(saveForm:any = false, child:any){
    this.apiService.get('tasks/states', child.item.taskStateId)
        .then((data:any)=>{
            child.statesProcess.statesList = data;
            child.statesProcess.statesList.push(child.statesProcess.taskSelectState);
            child.statesProcess.statesList.sort(this.orderList);
            this.getState(child)
            this.configureColorStates(child);
            this.checkPermissions(child);
            if(saveForm){
              child.statesProcess.showQuestion = false;
              this.saveForm(child);
            }
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('states-process.error-states'), 'error:accept:warning')
        });
  }

  orderList(a:any, b:any) {
    return a.taskStateId - b.taskStateId;
  }

  getState(child:any){
    let stateIdSelected:number = 0;
    let statesList: any = child.statesProcess.statesList;
    statesList.forEach((state:any) => {
      state.nextState = false;
        state.prevState = false;
        if(state.stateActive){
            state.stateStep = this.translate.instant('states-process.actual-state');
            stateIdSelected = state.taskStateId;
        }else if(stateIdSelected && stateIdSelected < state.taskStateId){
            state.stateStep = this.translate.instant('states-process.next-state');
            state.nextState = true;
        }else{
            state.stateStep = this.translate.instant('states-process.prev-state');
            state.prevState = true;
        }
    });
  }

  configureColorStates(child:any){
    let colors:any = this.globalCfg.skin === 'icam-red' || this.globalCfg.skin === 'gm-law'?  ['#D2333E', '#FF914D', '#F0BF29', '#1AB349', '#ACB3C4', '#9D8880']:['#C43741', '#F1923E', '#F1BC3E', '#77D166', '#929B9C', '#9A7E74'];
    let statesList: any = child.statesProcess.statesList;
    statesList.forEach((state:any) => {
      switch(state.taskStateId){
        case 1:
            state.color = colors[state.taskStateId-1];// "#C43741"; 'abierta'
            state.stateColor = 'red';
            break;
        case 2:
            state.color = colors[state.taskStateId-1];//"#F1923E"; 'stand-by'
            state.stateColor = 'orange';
            break;
        case 3:
            state.color = colors[state.taskStateId-1];//"#F1BC3E"; 'en progreso'
            state.stateColor = 'yellow-car';
            break;
        case 4:
            state.color = colors[state.taskStateId-1];//"#77D166"; resuelta
            state.stateColor = 'green';
            break;
        case 5:
            state.color = colors[state.taskStateId-1]; //"#929B9C"; cerrada
            state.stateColor = 'light-grey';
            break;
        default:
            state.color = colors[state.taskStateId-1];// "#9A7E74"; cerrada sin resolver
            state.stateColor = 'brown';
            break;
      }
    })
  }

  checkPermissions(child:any){
    let taskTypeId: any = child.item.taskTypeId;
    let statesList: any = child.statesProcess.statesList;
    if (child.item && (taskTypeId === 1 || taskTypeId === 3 || taskTypeId === 6 || taskTypeId === 7 )){
        if(child.isLanding){
          statesList.forEach((state:any) => {
            this.disabledState(state, child)
          })
        } else{
            if(this.checkParticipants(child)){
              statesList.forEach((state:any) => {
                this.disabledState(state, child)
              })
            }else if(child.item.createdUserId === this.context.user.userId){
                  statesList.forEach((state:any) => {
                      if(child.statesProcess.userPermissions === 'none'){
                          state.disabledState = true;
                      }else if(state.taskStateId === 5 || state.taskStateId === 6){
                          state.disabledState = (state.taskStateId === 1 || state.taskStateId === 5 || state.taskStateId === 6) && state.taskStateId === child.statesProcess.taskSelectState.taskStateId;
                      }else{
                          state.disabledState = true;
                      }
                  })
            }else{
                statesList.forEach((state:any) => {
                    state.disabledState = true;
                })
            }
        }
        child.statesProcess.showStates = true;
    }
  }

  disabledState(state:any, child:any){
      if(child.statesProcess.userPermissions === 'none'){
        state.disabledState = true;
      }else if(state.taskStateId !== 5 && state.taskStateId !== 6){
        state.disabledState = Boolean((state.taskStateId === 1 || state.taskStateId === 4 ) && state.taskStateId === child.statesProcess.taskSelectState.taskStateId);
      }else if(!child.isLanding && child.item.createdUserId === this.context.user.userId && child.statesProcess.typeParticipant === 'U'){
        state.disabledState = false;
      }else{
        state.disabledState = true;
      }
  }

  saveForm(child: any){

    this.apiService.patch('tasks/savepdf', child.item.taskId, child.statesProcess.htmlContent)
    .then((data:any)=>{
        child.changeState.emit({task: child.item});
        this.loader.closeLoader(['task-state-save-form']);
    }, (errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('common.error-generate-pdf'), 'error:accept:warning')
    })
  }

  configureVM(data:any, child:any) {
    let that: any = child.statesProcess;
    that.summary = data;
    that.showStates = true;
    if(child.userPublic || (child.concept && child.concept.conceptObjectId === 'view')){
        that.canChangeState = false;
        that.canAddAlerts = false;
    }else if(child.type === 'processConcept'){
        that.canChangeState = that.userPermissions && that.userPermissions !== 'none';
        that.canAddAlerts = that.userPermissions !== 'none' && !!this.context.user.userId;
    }else if(child.type === 'processTask'){
        that.canChangeState = that.userPermissions !== 'none' && (child.item.createdUserId === this.context.user.userId || this.checkParticipants(child));
    }
    that.showQuestion = false;
    child.concept.processStates = that.summary;
  }

  addOrUndoTransition(apiCall:any, personzalizedNotification:any, child:any){
    let that: any = child.statesProcess
    apiCall.then((data:any)=>{
        if (data && data.current) {
            if (child.changeResponsible && (child.type === 'processTask' || child.type === 'processConcept') && that.itemSelected.responsible && this.commonUtilsService.checkResponsibles( that.summary.current.processTaskResponsibles,  that.itemSelected.responsible.responsibleId)) {
                child.changeResponsible.emit({ responsible: that.itemSelected.responsible });
            }
            this.changeLockedEdit(data.current.stateLockedEdit, child);
            this.configureVM(data, child);
            this.configureAlertsHours(child);
            this.closeLoaderTransition(personzalizedNotification, child);
        }
    }, (errorData:any)=>{
        this.apiError(errorData, child);
    });
  }

  changeLockedEdit(lockedEdit:any, child:any){
    if(!child.isLanding && child.type === 'processConcept' && lockedEdit !== child.concept.stateLockedEdit){
        child.changeLockedEdit.emit({ lockedEdit: lockedEdit });
    }
  }

  closeLoaderTransition(personzalizedNotification:any, child:any){
    this.loader.closeLoader(['add-undo-transition']);
    if(personzalizedNotification){
      this.modalService.adviceModal(this.translate.instant('states-process.personalized-notification-ok'), 'info:accept:check-round');
    }
  }

  configureAlertsHours(child:any){
    let concept: any = child.statesProcess.concept;
    if(concept.conceptNamedKey === 'Consulta'){
        if(concept.processStates.current.stateId0 > 3){
            concept.tooltipAlertHour = this.translate.instant('concept-tab.concept-finished');
            concept.textAlertHour = this.translate.instant('concept-instances-list.finished');
            concept.stepIsFinal = true;
        }else{
            concept.tooltipAlertHour = this.translate.instant('concept-tab.hours').replace('{hours}', concept.processingHours);
            concept.textAlertHour = concept.processingHours +' '+ this.translate.instant('add-alert.hours');
            concept.stepIsFinal = false;
        }
        this.commonUtilsService.configureAlertState(concept);
    }
   }

  apiError(err:any, child:any) {
    let that: any = child.statesProcess;
    that.view = 'result';
    that.error = true;
    let errorText:string = this.translate.instant('common.unexpected-error');
    if(this.globalCfg.skin === 'icam-red' && that.concept.conceptNamedKey === 'Consulta' && err && err.description && err.description.message && err.description.alertType && child.isLanding){
      err.description.alertType === 'Internal'? console.log(err.description.message) : errorText = err.description.message;
    }else if (err && err.status === 409) {
        errorText = this.translate.instant('process.conflict-error-text');
    }
    this.loader.closeError();
    this.modalService.adviceModal(errorText, 'error:accept:warning');
  }

  checkParticipants(child:any){
    let that: any = child.statesProcess
    let participants: any = that.item.participants;
    if(participants){
      for(let i : number = 0; i < participants.length; i++){
        let participant: any = participants[i];
        let idParticipant:any = !participant.id && participant.listValueId? participant.listValueId : participant.id;
        let id = (idParticipant + participant.type).toString();
        if(id === that.userId){
          that.typeParticipant = participant.type;
          return true
        }
      }
    }
    return false;
  }

  changeProcess(itemSelected:any, option:any, child:any){
    let that: any = child.statesProcess;
    let responsible: any = itemSelected.responsible;
    let processTaskResponsibles: any = that.summary.current.processTaskResponsibles;
    let text: string = this.translate.instant('states-process.question-task-process-start')
    if (child.type === 'processTask' || (child.type === 'processConcept' && processTaskResponsibles.length)) {
        text += responsible && responsible.responsibleId !== processTaskResponsibles.length? ' ' + this.translate.instant('states-process.question-task-process-responsible')+ ' ' : ' ';
        that.questionChangeProcess = text + this.translate.instant('states-process.question-task-process-end')
    } else if (child.type === 'processConcept') {
        that.titleHeader = this.translate.instant('public-link.process-management');
        that.questionChangeProcess = this.translate.instant('states-process.question-concept');
    } else if (child.type === 'normalTask') {
        that.questionChangeProcess = this.translate.instant('states-process.question-task');
    }
    that.showQuestion = true;
    if(option === 'task'){
        that.htmlContent = {
            taskId: that.item.taskId,
            htmlContent: $('.concept-form').html()
        };
    }else{
        that.direction = option;
    }
    that.itemSelected = itemSelected;
  }

  changeStates(child:any) {
    let that: any = child.statesProcess
    if (that.item.taskTypeId !== 2 && that.item.taskTypeId !== 7 && that.taskSelectState.taskStateId !== that.itemSelected.taskStateId){
      this.loader.openLoader('task-state-save-form')
      this.saveTaskState(that.itemSelected, child);
    }
  }

  saveTaskState(selectState:any, child:any){
    let that: any = child.statesProcess;
    that.item.taskStateId = selectState.taskStateId;
    that.item.state = selectState.state;
    that.taskNew =  {
        taskStateId: selectState.taskStateId,
        taskId: that.item.taskId
    };
    this.apiService.patch('tasks/changestate', that.taskNew.taskId, that.taskNew)
        .then((data:any) => {
            that.taskSelectState = selectState;
            that.taskSelectState.stateActive = true;
            this.getTaskStates(true, child);
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('states-process.error-change-states'), 'error:accept:warning')
        })
  }

  checkShowInfoModal(child:any){
    let that: any = child.statesProcess;
    let documentation: any = that.concept.documentation;
    let fieldsSearch:any = this.getFieldQuery(child);
    if(fieldsSearch.typeQuery === 1 && that.itemSelected.stateId0 === 10 && (!documentation || (documentation && documentation.totalDocuments === 0))){
        this.showInfoDocumentModal(child);
    }else if(fieldsSearch.typeQuery === 2 && that.itemSelected.stateId0 === 4 && fieldsSearch.digitizationDocument){
        this.checkDocuments(fieldsSearch.digitizationDocument, child);
    }else{
        this.changeStateProcess(child);
    }
  }

  getFieldQuery(child:any){
    let fieldsSearch:any = {
        typeQuery: null,
        digitizationDocument: null
    }

    child.statesProcess.concept.evaluatedFields.forEach((field:any) => {
      if(field.fieldId0 === 10){
        if(field.floatvalue){
          field.listValues.forEach((listValue:any) => {
              if(listValue.listValueId === field.floatvalue){
                  fieldsSearch.typeQuery = listValue.numOrder;
              }
          })
        }
      }else if(field.fieldId0 === 348){
          fieldsSearch.digitizationDocument = field.floatvalue;
      }
    });
    return fieldsSearch;
  }

  checkDocuments(conceptObjectId:any, child:any){
    this.apiService.get('documentobjects/'+ conceptObjectId +'/count')
        .then((data:any) => {
          data === 0? this.showInfoDocumentModal(child) : this.changeStateProcess(child);
        }, (errorData:any)=>{});
  }

  showInfoDocumentModal(child:any){
    this.modalService.adviceModal(this.translate.instant('states-process.no-associated-files'), 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.changeStateProcess(child);
        }
      })
  }

  changeStateProcess(child:any){
    let that: any = child.statesProcess;
    let state:any = {
        transitionId: that.itemSelected.transitionId
    }
    let omitAlertCustom = that.direction === 'previous' && this.globalCfg.skin === 'icam-red';
    if (that.itemSelected && that.itemSelected.hasAlertCustom && !omitAlertCustom) {
        this.modalService.personzalizedNotificationModal(that.concept, that.itemSelected)
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.loader.openLoader('add-undo-transition');
              state.customBody = result.customEmail.customBody;
              state.customSubject = result.customEmail.customSubject;
              this.configureTransition(state, true, that.itemSelected.stateName, child);
            }
          })
    }else{
      this.loader.openLoader('add-undo-transition');
      this.configureTransition(state, false, that.itemSelected.stateName, child);
    }
  }

  configureTransition(state:any, personzalizedNotification:any, stepName:any, child:any){
    let that: any = child.statesProcess;
    let callApi = that.direction === 'next'? this.apiService.add('processesdata/' + that.item.conceptObjectId,state) : this.apiService.delete('processesdata/' + that.item.conceptObjectId + '/last/' + state.transitionId, state);
    let type = that.direction === 'next'? "advance process" : "back process"
    let registerItem ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':type,
      "Step Name": stepName,
      'Concept Name': sessionStorage.getItem('breadcrumbConcept') + that.concept.title,
      'Process Name': that.concept.processStates.next.name,
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(registerItem);
    this.addOrUndoTransition(callApi, personzalizedNotification, child);
  }

  getProcess(child:any){
    let that: any = child.statesProcess;
    this.apiService.getWithTranslate('processesdata/'+ that.concept.conceptObjectId, 'summary')
        .then((data:any)=> {
            that.concept.processStates = data;
            this.configureByType(child.type, child);
        }, ()=>{});
  }
}
