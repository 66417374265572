<div class="customize-field-text">
  <div class="common__header-textarea"
      [ngClass]="{'common__input-no-editable': concept.disabled}">
      <span class="task-edit__text-description-title"
          [afTranslate]="'common.title'">Titulo</span>
  </div>
  <textarea class="common__textarea-simple"
      [ngClass]="{'common__input-no-editable': concept.disabled}"
      [(ngModel)]="customizeFieldTextRich.field.extendedLabel"
      [disabled]="concept.disabled"
      (blur)="modifyField()">
  </textarea>
  <div class="common__header-textarea"
      [ngClass]="{'common__input-no-editable': concept.disabled}">
      <span class="task-edit__text-description-title"
          [afTranslate]="'common.description'">Descripción</span>
  </div>
  <rich-editor [tinyMceOptions]="customizeFieldTextRich.tinyMceOptions"
    [modelText]="customizeFieldTextRich.field.description"
    (emitValue)="getEditorValue($event)">
  </rich-editor>
  <div class="customize-field-text__field-text">
      <field-type-list-simple [field]="customizeFieldTextRich.contentReportInfo"
                        [preSelectItem]="customizeFieldTextRich.field.reportFieldId"
                        [listValues]="reportList"
                        (itemSelected)="itemSelected($event, 'reportFieldId')">
      </field-type-list-simple>
  </div>
  <div class="customize-field-text__field-text">
      <visibility class="field-item__input"
                  *ngIf="concept.poll"
                  [items]="visibilityItems"
                  [type]="'field'"
                  [concept]="concept"
                  [selectedProperties]="customizeFieldTextRich.field.propertyVisibleValues"
                  [selectedListValues]="customizeFieldTextRich.field.listVisibleValues"
                  [selectedFields]="customizeFieldTextRich.field.fieldVisibleValues"
                  [orCondition]="customizeFieldTextRich.field.visibilityOr"
                  [readonly]="customizeFieldTextRich.field.noEditable || concept.disabled"
                  [itemId]="customizeFieldTextRich.field.fieldId"
                  (visibilityOpen)="openVisibility($event)">
      </visibility>
  </div>
  <switch-option [ngShow]="concept.allowPublicShare"
      [tooltipText]="'customize.private-help'"
      [model]="isPrivate?isPrivate:field.private"
      [disabled]="concept.disabled || isPrivate || customizeFieldTextRich.field.isIdentifier"
      [fieldText]="'customize.private'"
      (changeOptionEmit)="changeOptionOn($event, 'private')">
  </switch-option>
  <switch-option [ngHide]="concept.allowPublicShare"
      [tooltipText]="'customize.hidden-landing-help'"
      [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
      [disabled]="hiddenInLandings"
      [fieldText]="'customize.hidden-landing'"
      (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
  </switch-option>
  <switch-option
      [model]="customizeFieldTextRich.field.isHighlight"
      [disabled]="concept.disabled || customizeFieldTextRich.field.isEditable"
      [fieldText]="'customize.highlight'"
      (changeOptionEmit)="changeOptionOn($event, 'isHighlight')">
  </switch-option>
  <div class="customize-field-text__switch-block">
    <switch-option class="customize-field-text__switch-button-inline"
        [model]="customizeFieldTextRich.field.isEditable"
        [disabled]="concept.disabled"
        [fieldText]="'customize.editable'"
        (changeOptionEmit)="changeOptionOn($event, 'isEditable')">
    </switch-option>
    <switch-option class="customize-field-text__switch-button-inline"
        [ngShow]="customizeFieldTextRich.field.isEditable && concept.communicationChannel"
        [model]="customizeFieldTextRich.field.isVoiceRecord"
        [fieldText]="'customize.voice-record'"
        (changeOptionEmit)="changeOptionOn($event, 'isVoiceRecord')">
    </switch-option>
    <switch-option class="customize-field-text__switch-button-inline"
      [ngShow]="customizeFieldTextRich.field.isEditable && concept.communicationChannel && customizeFieldTextRich.field.isVoiceRecord"
      [model]="customizeFieldTextRich.field.voiceRecordOk"
      [fieldText]="'customize.voice-record-to-text'"
      (changeOptionEmit)="changeOptionOn($event, 'voiceRecordOk')">
    </switch-option>
  </div>
  <div class="customize-field-text__field-text">
    <field-type-text-simple *ngIf="customizeFieldTextRich.field.isEditable && customizeFieldTextRich.field.isVoiceRecord && customizeFieldTextRich.field.voiceRecordOk"
        [field]="customizeFieldTextRich.voiceRecordInput"
        [preSelectItem]="customizeFieldTextRich.field.voiceRecordOkText"
        (itemSelected)="changeOptionOn($event, 'voiceRecordOkText')">
    </field-type-text-simple>
  </div>
  <switch-option
    [model]="customizeFieldTextRich.field.required"
    [disabled]="concept.disabled || !customizeFieldTextRich.field.isEditable"
    [fieldText]="'customize.required'"
    (changeOptionEmit)="changeOptionOn($event, 'required')">
  </switch-option>
  <div class="customize-field-list__error-block"
    [ngShow]="customizeFieldTextRich.showError">
    <i class="lf-icon-warning establish-pattern__icon-error"></i>
    <p class="establish-pattern__text-error"
        [afTranslate]="'record-audio-modal.voice-record-text-ok-error'"></p>
    <i class="lf-icon-close customize-field-list__icon-close-header"
        (click)="customizeFieldTextRich.showError = !customizeFieldTextRich.showError"></i>
  </div>
  <div class="common__buttons-block common__buttons-block--customize">
      <button type="reset"
              class="common__button common__button--clean"
              (click)="cancelFieldText()"
              [disabled]="concept.disabled"
              [afTranslate]="'common.cancel'">
          Cancelar
      </button>
      <button type="submit"
              class="common__button common__button--active"
              (click)="saveFieldText()"
              [disabled]="concept.disabled"
              [afTranslate]="'common.save'">
          Guardar
      </button>
  </div>
</div>
