import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'favorites',
  templateUrl: './favorites.component.html'
})
export class FavoritesComponent implements OnInit {
  constructor(private loader:LoaderFactory,
    private broadcastService:BroadcastService,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private route:ActivatedRoute,
    private router:Router
  ) { }

  @Input() fullMenu:any;

  favorites:any = {
    order: 'accessDate',
    currentPage: 1,
    configPagination: {
      pageSize: 10
    },
    favoritesList: []
  };

  ngOnInit(): void {
    this.loader.closeLoader('folder-on-init');
    this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: {}, type: 'favorites'});
    this.getExpandedMenu();
    this.getFavorites();
  }

  getExpandedMenu(){
    this.apiService.getExpandedMenu().then(
      (data:any) => {
        this.favorites.menuFavoritesList = data['favoritesMenuList'].menu;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  getFavorites(){
    let that = this.favorites;
    this.apiService.get('conceptobjects/favoriteslist', this.route.snapshot.params['evaluationId']).then(
      (data:any) => {
        data.forEach((item:any) => {
         item.menuOption = cloneDeep(that.menuFavoritesList);
          if(!item.isVisible){
             item.menuOption[0] = {
                  name: 'Enlace no disponible',
                  icon: 'lf-icon-warning',
                  action: 'none'
              }
          }else{
             item.menuOption = cloneDeep(that.menuFavoritesList);
          }
          if(item.path.indexOf('/') !== -1){
              let routes =item.path.split('/');
             item.route=[];
              routes.forEach((route:any) => {
                item.route.push({name:route})
              });
          }else{
             item.route=[{
                  name:item.path
              }]
          }
        });
        that.favoritesList = data;
        that.chargedCall = true;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  orderBy(order:string){
    this.favorites.order = order;
  }

  openConcept(favorite:any){
    let folderIdPositionInit = favorite.editpath.indexOf("folder/") + 7;
    let folderIdPositionFinish = favorite.editpath.indexOf("/concept");
    let folderId = favorite.editpath.substring(folderIdPositionInit,folderIdPositionFinish);
    this.router.navigate(['evaluation', this.route.snapshot.params['evaluationId'], 'folder', folderId, 'concept', favorite.conceptId, 'concept-evaluated', favorite.conceptObjectId]);
  }

  launchAction(action:string, favorite:any){
    switch(action){
        case 'open-window':
            window.open(favorite.editpath, '_blank');
            break;
        case 'delete':
            this.removeFromFavorites(favorite);
            break;
    }
  }

  removeFromFavorites(favorite:any){
    this.apiService.patch('conceptobjects/favorite/' + favorite.conceptObjectId + '/0').then(
      (data:any) => {
        let that = this.favorites;
        for(let i = 0; i < that.favoritesList.length; i++){
            if(that.favoritesList[i].conceptObjectId === favorite.conceptObjectId ){
              that.favoritesList.splice(i, 1);
            }
        }
      },
      (error:any) => {
        console.error(error);
      }
    );
  }
}
