<div class="view-extended-report">
    <button class="view-extended-report__button-exit"
        (click)="handleDismiss()">
        <span>Cerrar vista ampliada</span>
        <i class="lf-icon-zoom-out view-extended-report__icon-zoom-out"></i>
    </button>
    <div class="view-extended-report__block-questions">
        <div class="results-report__header-question">
            <div class="results-report__name-group-poll">
                <span>{{selectedField.groupName}}</span>
            </div>
            <i class="lf-icon-angle-right results-report__icon-angle-separator"
                [ngShow]="selectedField.subGroupName !== ''"></i>
            <div class="results-report__name-group-poll">
                <span [ngShow]="selectedField.subGroupName !== ''">{{selectedField.subGroupName}}</span>
            </div>
        </div>
        <div class="view-extended-report__block-title-question">
            <span>{{selectedField.label}}</span>
        </div>
        <div class="results-report__block-option-more-selected">
            <span>{{selectedField.optionMoreSelected}}</span>
        </div>
    </div>
    <horizontal-poll-graph [graphData]="selectedField.graphicData"
        [isModal]="isModal">
    </horizontal-poll-graph>
    <div class="view-extended-report__bottom-info-block">
        <i class="lf-icon-questionnaire view-extended-report__poll-amount-icon"></i>
        <p class="view-extended-report__poll-amount-text view-extended-report__poll-amount-text--left">
            <span [afTranslate]="'results-report.answered'">_Respondido</span>
            <span>:</span>
            <span>{{selectedField.answered}}</span>
        </p>
        <p class="view-extended-report__poll-amount-text">
            <span [afTranslate]="'results-report.omitted'">_Omitido</span>
            <span>:</span>
            <span>{{selectedField.notAnswered}}</span>
        </p>
    </div>
</div>
