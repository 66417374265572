<div class="formula-settings-modal">
    <div class="common__modal-header-block common__modal-header-block--margin-bottom">
        <i class="lf-icon-calculator common__icon-header" ></i>
        <span class="common__text-header"
            [afTranslate]="'customize-field-formula.configuration-group'">Configuración de la fórmula</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>

    <!--#region PaneIizquierdo-->
    <div class="formula-settings-modal__panel-left">
        <div class="formula-settings-modal__panel">
            <div class="formula-settings-modal__block-header-right">
                <div class="formula-settings-modal__block-text">
                <span class="formula-settings-modal__subtitle-text"
                    [afTranslate]="'customize-field-formula.concept-field'">CAMPOS DEL CONCEPTO</span>
                </div>
                <div class="formula-settings-modal__block-search">
                    <input type="text"
                        class="formula-settings-modal__search-input"
                        [(ngModel)]="formulaSettingModal.searchFields"
                        [placeholder]="'common.search' | afTranslate"
                        [focusWhen]="formulaSettingModal.search">
                    <button class="common__icon-button"
                        (click)="toggleSearch()">
                        <i class="lf-icon-search formula-settings-modal__icon-search"
                            [ngClass]="{'lf-icon-close-search': formulaSettingModal.searchFields.length > 0}"></i>
                    </button>
                </div>
            </div>
            <div class="formula-settings-modal__block-field formula-settings-modal__block-field--left">
                <div class="formula-settings-modal__block-group"
                    *ngFor="let group of conceptFields">
                    <div class="formula-settings-modal__head-modal">
                        <i class="formula-settings-modal__icon-arrow"
                            [ngClass]="{'lf-icon-angle-down':group.show ,
                                'lf-icon-angle-right':!group.show , 'formula-settings-modal__icon-arrow--visibility':group.fields.length || group.subContainers.length}"
                            (click)="group.show = !group.show"></i>
                        <div class="formula-settings-modal__block-name-group">
                            <span class="formula-settings-modal__title-group"
                                [ngClass]="{'formula-settings-modal__title-selected':group.selected}">{{group.name}}</span>
                        </div>
                    </div>
                    <div class="formula-settings-modal__block-tree formula-settings-modal__block-tree--children"
                        [ngShow]="group.show && field.show"
                        *ngFor="let field of group.fields | byWord: formulaSettingModal.searchFields:['label']">
                        <div class="formula-settings-modal__block-name-fields">
                            <div class="formula-settings-modal__block-name-fields"
                                (click)="itemSelected('fields', field)">
                                <i [class]="'formula-settings-modal__icon-item '+field.icon"></i>
                                <span class="formula-settings-modal__title-children">
                                    {{field.label}}
                                </span>
                                <span class="formula-settings-modal__text-hidden">
                                    <span [afTranslate]="'common.add'">añadir</span>
                                    <i class="lf-icon-arrow-right formula-settings-modal__arrow-icon"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="formula-settings-modal__block-tree"
                        [ngShow]="group.show"
                        *ngFor="let subContainer of group.subContainers">
                        <i class="formula-settings-modal__icon-arrow formula-settings-modal__icon-arrow--field-list"
                            [ngClass]="{'lf-icon-angle-down':subContainer.show ,
                            'lf-icon-angle-right':!subContainer.show , 'formula-settings-modal__icon-arrow--visibility':subContainer.fields.length}"
                            (click)="subContainer.show = !subContainer.show"></i>
                        <div class="formula-settings-modal__block-name-group">
                            <span class="formula-settings-modal__title-group">{{subContainer.name}}</span>
                            <div class="formula-settings-modal__block-tree formula-settings-modal__block-tree--subContainer-subgroup"
                                [ngShow]="subContainer.show && fields.show"
                                *ngFor="let fields of subContainer.fields | byWord:formulaSettingModal.searchFields:['label']">
                                <div class="formula-settings-modal__block-name-fields"
                                    (click)="itemSelected('fields', fields)">
                                    <i [class]="'formula-settings-modal__icon-item '+ fields.icon"></i>
                                    <span class="formula-settings-modal__title-children">{{fields.label}}</span>
                                    <span class="formula-settings-modal__text-hidden">
                                        <span [afTranslate]="'common.add'">añadir</span>
                                        <i class="lf-icon-arrow-right formula-settings-modal__arrow-icon"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="formula-settings-modal__panel-input">
            <field-type-number [field]="formulaSettingModal.value1Field"
                [preSelectItem]="formulaSettingModal.valueNumber"
                (itemSelected)="encodingFieldChanged($event, 'fields-manual')">
            </field-type-number>
        </div>
    </div>
    <div class="formula-settings-modal__formula-col">
        <ul class="formula-settings-modal__symbol-list">
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol('+')">
                <i class="lf-icon-sum formula-settings-modal__symbol-icon"></i>
            </li>
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol('-')">
                <i class="lf-icon-less formula-settings-modal__symbol-icon"></i>
            </li>
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol('*')">
                <i class="lf-icon-close formula-settings-modal__symbol-icon"></i>
            </li>
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol('/')">
                <i class="lf-icon-division formula-settings-modal__symbol-icon"></i>
            </li>
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol('(')">
                <i class="lf-icon-open-bracket formula-settings-modal__symbol-icon"></i>
            </li>
            <li class="formula-settings-modal__symbol-item"
                (click)="addSymbol(')')">
                <i class="lf-icon-close-bracket formula-settings-modal__symbol-icon"></i>
            </li>
        </ul>
    </div>

    <div class="formula-settings-modal__panel-right">
        <div class="formula-settings-modal__panel"
            [ngStyle]="{'border-color': formulaSettingModal.errorFormula ? '#C43741' : ''}">
            <div class="formula-settings-modal__block-header-right">
                <div class="formula-settings-modal__block-text">
                    <span class="formula-settings-modal__subtitle-text"
                    [ngStyle]="{'color': formulaSettingModal.errorFormula ? '#C43741' : ''}"
                    [afTranslate]="'customize.result'">RESULTADO</span>
                </div>
                <div class="formula-settings-modal__block-icons-right">
                    <button class="formula-settings-modal__button-view"
                        (click)="formulaSettingModal.showFormulaSimple = !formulaSettingModal.showFormulaSimple">
                        <i class="formula-settings-modal__icon-view"
                            [ngClass]="formulaSettingModal.showFormulaSimple?'lf-icon-album-menu':'lf-icon-justify-align'"></i>
                        <span [ngShow]="!formulaSettingModal.showFormulaSimple"
                            [afTranslate]="'customize-field-formula.view-simple'">Vista simplificada</span>
                        <span [ngShow]="formulaSettingModal.showFormulaSimple"
                            [afTranslate]="'customize-field-formula.view-format'">Vista formateada</span>
                    </button>
                    <button class="lf-icon-arrow-back common__button-close span__auto-ajust"
                        (click)="deleteLastItem()"></button>
                    <button class="lf-icon-close common__button-close span__auto-ajust"
                        (click)="resetForm()"></button>
                </div>
            </div>
            <div class="formula-settings-modal__block-result-text formula-settings-modal__block-field formula-settings-modal__block-field--right">
                <!--#region vista formateada-->
                <div class="formula-settings-modal__item"
                    [ngShow]="!formulaSettingModal.showFormulaSimple"
                    [ngStyle]="{'margin-inline-start': item.marginOperator + 'px'}"
                    [ngClass]="{'formula-settings-modal__item--full': item.type==='symbol-full',  'formula-settings-modal__item--line-jump': item.lineJump}"
                    *ngFor="let item of formulaSettingModal.formulaItems" >
                    <div [ngShow]="item.type === 'fields'"
                        [class]="'formula-settings-modal__item--'+item.type"
                        [ngStyle]="{'background-color':item.color}">
                        <i [class]="'formula-settings-modal__icon-item '+( item.icon !== ''? item.icon:'')"></i>
                        <p class="formula-settings-modal__text-result">{{item.text}}</p>
                    </div>
                    <div [ngShow]="item.type !== 'fields'"
                        [class]="'formula-settings-modal__item--'+item.type"
                        [ngStyle]="{'background-color':item.color}">
                        <i [class]="'formula-settings-modal__icon formula-settings-modal__icon--operator '+item.icon"></i>
                    </div>
                </div>
                <div class="formula-settings-modal__result-block"
                    [ngShow]="formulaSettingModal.showFormulaSimple">
                    <div class="customize-field-formula__block-result-text">
                        <span class="customize-field-formula__result-text"
                            [ngClass]="{'customize-field-formula__result-text-symbol':item.type ==='symbol'} "
                            *ngFor="let item of formulaSettingModal.formulaItems">
                            <i [ngShow]="item.type !== 'fields'"
                                [class]="'formula-settings-modal__icon formula-settings-modal__icon--operator-simple '+item.icon"></i>
                            <span [ngShow]="item.type === 'fields'">
                                {{item.text}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div [ngShow]="formulaSettingModal.errorFormula"
                class="formula-settings-modal__error-formula">
                <i class="lf-icon-warning formula-settings-modal__error-formula--icon"></i>
                <p class="formula-settings-modal__error-formula--title-text"
                    [afTranslate]="'customize-field-formula.formula-error'">La configuración de la fórmula no es correcta. Por favor, revisa las operaciones</p>
                <button class="lf-icon-close formula-settings-modal__error-formula--close-icon"
                    (click)="closeError()">
                </button>
            </div>
        </div>
    </div>
    <div class="formula-settings-modal__footer">
        <div class="formula-settings-modal__group-body-help">
            <p class="customize-concept__help"
                [afTranslate]="'customize-field-formula.user-help'">
                Usa los operadores matemáticos para relacionar valores extraidos de los campos del concepto y otros valores que puedes introducir libremente.
            </p>
        </div>
        <div class="formula-settings-modal__sc-buttons">
            <div class="common__buttons-block common__buttons-block--customize">
                <button type="reset"
                        class="common__button common__button--clean"
                        (click)="handleDismiss()"
                        [afTranslate]="'common.cancel'">
                    Cancelar
                </button>
                <button type="submit"
                        class="common__button common__button--active"
                        (click)="handleAccept()"
                        [afTranslate]="'common.save'">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</div>
