<div class="migration-data" >
  <div class="common__modal-header-block">
      <i class="lf-icon-upload-download-folder common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="'migration-data.dialog-title'"></span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="finish-block-modal__body">
      <h2 class="migration-data__contacts-import-title migration-data__contacts-import-title--migration-title">
          {{migrationData.titleInfo}}
      </h2>
      <div class="migration-data__option-box">
          <div class="common__button-box-option migration-data__button-option-box"
            (click)="migrationData.optionSelect = 'template'">
            <i class="common__button-box-icon"
              [ngClass]="(migrationData.optionSelect === 'template') ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
            <span class="common__button-box-text"
              [afTranslate]="'migration-data.download-template'">
            </span>
          </div>
          <p class="finish-block-modal__box-text-info finish-block-modal__box-text-info--migration-data">
              {{migrationData.downloadTemplateInfo}}
          </p>
          <p class="common__contacts-import-icon">
              <i class="lf-icon-excel"></i>
          </p>
      </div>
      <div class="migration-data__option-box common__option-box--center">
          <div class="common__button-box-option migration-data__button-option-box"
              (click)="migrationData.optionSelect = 'export'">
              <i class="common__button-box-icon"
                [ngClass]="(migrationData.optionSelect === 'export') ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
              <span class="common__button-box-text">
                  {{migrationData.exportData}}
              </span>
            </div>
          <p class="finish-block-modal__box-text-info finish-block-modal__box-text-info--migration-data">
              {{migrationData.exportDataInfo}}
          </p>
          <p class="common__contacts-import-icon">
              <i class="lf-icon-download migration-data__box-icon migration-data__box-icon--down"></i>
          </p>
      </div>
      <div class="migration-data__option-box">
          <div class="common__button-box-option migration-data__button-option-box"
            (click)="migrationData.optionSelect = 'import'">
            <i class="common__button-box-icon"
              [ngClass]="(migrationData.optionSelect === 'import') ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
              <span class="common__button-box-text">
                  {{migrationData.importData}}
              </span>
            </div>
          <p class="finish-block-modal__box-text-info finish-block-modal__box-text-info--migration-data">
              {{migrationData.importDataInfo}}
          </p>
          <p class="common__contacts-import-icon">
              <i class="lf-icon-up-file migration-data__box-icon"></i>
          </p>
      </div>
  </div>
  <div class="finish-block-modal__footer">
      <button class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">
      </button>
      <button class="common__button common__button--active"
          (click)="handleAccept()"
          [afTranslate]="'common.execute'">
      </button>
  </div>
</div>
