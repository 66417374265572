import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class ConceptByIdResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
        private context: ContextFactory,
        private globalCfg: GlobalCfgFactory) { }
    resolve(route: ActivatedRouteSnapshot) {
        let conceptId = route.params['conceptId'];
        if(this.globalCfg.skin === 'icam-red' && this.context?.view?.extraData?.masterConceptId && !this.context?.view?.extraData?.masterConceptObjectId){
            conceptId = this.context.view.extraData.masterConceptId;
        }
        return this.apiService.getWithTranslate('concepts/byid', conceptId)
          .then((data:any)=>{
            return data;
          })
    }

}
