<div class="field-type-color"
  [ngClass]="{'field-type-color--open': fieldTypeColor.showPalette}">
  <label class="common__input-block field-type-color__input-block"
      [for]="'publish-concept-color-'+fieldTypeColor.fieldId"
      [ngClass]="{'common__input-block--color': !fieldTypeColor.showPalette,
          'common__input-block--fixed field-type-color__input-block--open': fieldTypeColor.showPalette,
          'common__show-alert': field.error}">
      <div class="common__label-block common__label-block--two-big-icon">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                      'common__input-text--no-editable':field.noEditable}">{{fieldTypeColor.titleField}}</span>
          <span class="common__text-required"
              [ngShow]=" field.required">*</span>
      </div>
      <div class="float-end"
          [ngShow]="!fieldTypeColor.showPalette">
          <span class="field-type-color__color-selected-palette"
              [ngShow]="fieldTypeColor.companyColorSelectedInput"
              [ngStyle]="{'background-color': fieldTypeColor.companyColorSelected}"></span>
          <i class="lf-icon-colour field-type-color__icon-palette"
              (click)="openPalette()"></i>
      </div>
      <div class="field-type-color__block-buttons"
          [ngShow]="fieldTypeColor.showPalette">
          <button class="lf-icon-close common__button-close"
              (click)="closePalette()">
          </button>
          <button class="lf-icon-check common__button-checked"
              (click)="catchColor()">
          </button>
      </div>
      <div [ngShow]="!fieldTypeColor.showPalette">
          <input [id]="'publish-concept-color-'+fieldTypeColor.fieldId"
              [name]="'publish-concept-color-'+fieldTypeColor.fieldId"
              class="common__input-content"
              type="text"
              [(ngModel)]="fieldTypeColor.companyColorSelectedInput"
              autocomplete="off"
              readonly>
      </div>
      <div class="field-type-color__palette-picker-block"
          [ngClass]="{'field-type-color__palette-picker-block--visible':fieldTypeColor.showPalette}"
          [ngShow]="fieldTypeColor.showPalette">
          <div class="field-type-color__palette-block">
              <div class="field-type-color__bright-saturation-block"
                  (mousedown)="boxDown($event)"
                  (mousemove)="boxMove($event)"
                  (mouseup)="boxColorPicker($event)"
                  (mouseleave)="boxMove($event, 'exit')"
                  [ngStyle]="{'background':fieldTypeColor.boxColor}">
                  <div class="field-type-color__bright-saturation-selector-block"
                      [ngShow]="fieldTypeColor.companyColorSelected && !fieldTypeColor.colorError"
                      [ngStyle]="{'top': (fieldTypeColor.point.y - 8)+'px', 'left': (fieldTypeColor.point.x - 8) +'px'}">
                      <button class="field-type-color__bright-saturation-selector"></button>
                  </div>

              </div>
              <div class="field-type-color__right-block">
                  <div class=""
                      [ngClass]="{'field-type-color__box-color-selected': fieldTypeColor.companyColorSelected,
                          'field-type-color__box-color-selected--error':fieldTypeColor.colorError}"
                      [ngStyle]="{'background-color': fieldTypeColor.colorError?'':fieldTypeColor.companyColorSelected}">
                      <span [ngShow]="fieldTypeColor.colorError"
                          class="field-type-color__text-error-selected"
                          [afTranslate]="'field-type-color.invalid-color'">_Color no válido</span>
                  </div>
                  <label class="field-type-color__hex-block">
                      <div class="field-type-color__title-block-color"
                          [afTranslate]="'field-type-color.hexadecimal'">Hexadecimal</div>
                      <input type="text"
                          class="field-type-color__input-text-color"
                          [(ngModel)]="fieldTypeColor.companyColorSelected"
                          (blur)="changeInputColorHex()"
                          maxlength="7">
                  </label>
                  <label class="field-type-color__rgb-box field-type-color__rgb-box--r">
                      <div class="field-type-color__title-block-color">R</div>
                      <input type="text"
                          class="field-type-color__input-text-color"
                          [ngShow]="fieldTypeColor.companyColorSelected"
                          [(ngModel)]="fieldTypeColor.rgb.r"
                          (blur)="changeInputColorRgb('r', fieldTypeColor.rgb.r)"
                          maxlength="3">
                  </label>
                  <label class="field-type-color__rgb-box ">
                      <div class="field-type-color__title-block-color">G</div>
                      <input type="text"
                          class="field-type-color__input-text-color"
                          [ngShow]="fieldTypeColor.companyColorSelected"
                          [(ngModel)]="fieldTypeColor.rgb.g"
                          (blur)="changeInputColorRgb('g', fieldTypeColor.rgb.g)"
                          maxlength="3">
                  </label>
                  <label class="field-type-color__rgb-box field-type-color__rgb-box--b">
                      <div class="field-type-color__title-block-color">B</div>
                      <input type="text"
                          class="field-type-color__input-text-color"
                          [ngShow]="fieldTypeColor.companyColorSelected"
                          [(ngModel)]="fieldTypeColor.rgb.b"
                          (blur)="changeInputColorRgb('b', fieldTypeColor.rgb.b)"
                          maxlength="3">
                  </label>
              </div>
              <div class="field-type-color__range-block">
                  <div class="field-type-color__range-line-color"></div>
                  <input class="field-type-color__range-input"
                      type="range"
                      [(ngModel)]="fieldTypeColor.colorRange"
                      (change)="pickRangeColor()"
                      min="0"
                      max="360">
              </div>
          </div>
      </div>
    <div class="field-item__blocker-box"
        *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </label>
</div>
