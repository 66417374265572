import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ReportTableParentService } from '../utils/report-table-parent.service';

@Component({
  selector: 'report-table-precio-medio-transaccion',
  templateUrl: './report-table-precio-medio-transaccion.component.html'
})
export class ReportTablePrecioMedioTransaccionComponent implements OnInit, OnChanges  {
  skin: string = this.globalCfg.skin;
  archived: boolean = this.route.snapshot.params['archived'] === 'archived'
  evaluationId: any = this.route.snapshot.params['evaluationId'];
  url = this.skin === 'icam-red'? 'icam/':''
  configureInit: boolean = false;

  reportTable: any = this.reportTableParent.configureReportTable(this);

  constructor(
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private reportTableParent: ReportTableParentService) {
     }

  @Input() report:any = null;
  @Input() configurateReport:any = null;

  @Output() updateListEmit = new EventEmitter();
  @Output() exportReportEmit = new EventEmitter();

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.reportTableParent.onChanges(this)
  }

  exportReport(onlyRename:Boolean = false){
    this.exportReportEmit.emit({onlyRename:onlyRename})
  }

  selectIcamYear(year:any){
    this.reportTableParent.selectIcamYear(year, this)
  }

  selectTypeOperation(e:any, column:any){
    this.reportTableParent.selectTypeOperation(e.source, column, this)
  }

  getTotalPrice(price:any) {
      let newPrice:number = price == null || isNaN(price)?0 : price;
      return newPrice.toFixed(2);
  }
}
