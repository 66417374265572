<div class="clients-list">
  <header-nav></header-nav>
  <div class="common__block-window-view">
      <div class="common__block-button-close-window">
          <button class="common__back-link" (click)="changeView()">
              <span [afTranslate]="'clients-list.close-organizations'"></span>
              <i class="lf-icon-close common__back-link-icon"></i>
          </button>
      </div>
      <div class="common__container-window-view">
          <div class="common__block-list-new-version">
              <div class="common__header-new-version">
                  <i class="lf-icon-label-user common__header-icon-new-version"></i>
                  <span class="common__header-title-new-version" [afTranslate]="'common.organizations'"></span>
                  <button class="lf-icon-add-round common__header-button-add-new-version"
                      [ngShow]="permissions.canCreateClients"
                      (click)="newClient()"
                      [disabled]="!permissions.canCreateClients"
                      matTooltip="{{'new-client.new-organization' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </button>
                   <button class="lf-icon-upload-download-folder common__header-button-add-new-version common__header-button-add-new-version--upload"
                      [ngShow]="permissions.canCreateClients"
                      (click)="openMigrationModal()"
                      [disabled]="!permissions.canCreateClients"
                      matTooltip="{{'expanded-menu.migration' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                  </button>
              </div>
              <div class="common__table-block">
                  <div class="common__table-new-version">
                      <div class="common__table-header">
                          <div class="clients-list__col-v2 clients-list__col-v2--name clients-list__col-v2--first">
                              <p class="common__table-header-text" [afTranslate]="'common.name'"></p>
                          </div>
                          <div class="clients-list__col-v2 clients-list__col-v2--holding">
                              <p class="common__table-header-text" [afTranslate]="'dashboard.holding'"></p>
                          </div>
                          <div class="clients-list__col-v2 clients-list__col-v2--cif">
                              <p class="common__table-header-text" [afTranslate]="'new-client.client-cif'"></p>
                          </div>
                          <div class="clients-list__col-v2 clients-list__col-v2--email">
                              <p class="common__table-header-text" [afTranslate]="'common.email'"></p>
                          </div>
                          <div class="clients-list__col-v2 clients-list__col-v2--actions">
                              <p class="common__table-header-text common__table-header-text--right" [afTranslate]="'common.actions'"></p>
                          </div>
                      </div>
                      <div class="common__table-body common__table-body--10-elements"
                          [ngClass]="{'common__table-body--more-elements': clientList.length > 10}">
                          <div class="clients-list__block-text-no-clients" [ngShow]="clientsList.noClientsList">
                              <p class="clients-list__text-no-clients" [afTranslate]="'clients-list.text-no-clients-1'"></p>
                              <p class="clients-list__text-no-clients" [afTranslate]="'clients-list.text-no-clients-2'"></p>
                              <p class="clients-list__text-no-clients" [afTranslate]="'clients-list.text-no-clients-3'"></p>
                          </div>
                          <div class="common__table-row"
                              *ngFor="let client of clientList | byWord:clientsList.searchClient:['name', 'cif'] | afOrderBy:['name']: clientsList.order | afLimitTo:clientsList.configPagination.pageSize:clientsList.configPagination.pageSize*(clientsList.currentPage - 1)">
                              <div class="clients-list__col-v2 clients-list__col-v2--name clients-list__col-v2--first clients-list__col-v2--tooltip common__cursor-pointer"
                                  [ngShow]="!clientsList.noClientsList"
                                  (click)="editClient(client)">
                                  <parragraph-tooltip [text]="client.name"></parragraph-tooltip>
                              </div>
                              <div class="clients-list__col-v2 clients-list__col-v2--holding clients-list__col-v2--tooltip">
                                  <parragraph-tooltip [text]="client.holding"></parragraph-tooltip>
                              </div>
                              <div class="clients-list__col-v2 clients-list__col-v2--cif clients-list__col-v2--tooltip">
                                  <parragraph-tooltip [text]="client.cif"></parragraph-tooltip>
                              </div>
                              <div class="clients-list__col-v2 clients-list__col-v2--email-body clients-list__col-v2--tooltip">
                                  <parragraph-tooltip [text]="client.email"></parragraph-tooltip>
                              </div>
                              <div class="clients-list__col-v2 clients-list__col-v2--actions-body">
                                  <ul class="common__options-row">
                                      <li class="common__menu-options-row"
                                          matTooltip="{{(client.canAdmin ? 'common.edit' : 'common.view') | afTranslate}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below">
                                          <i [ngClass]="client.canAdmin ? 'lf-icon-editable-form' : 'lf-icon-visible'"
                                              (click)="editClient(client)"></i>
                                      </li>
                                      <li class="common__menu-options-row"
                                          *ngIf="client.canAdmin"
                                          (click)="deleteClient(client)"
                                          matTooltip="{{'common.remove' | afTranslate}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below">
                                          <i class="lf-icon-trash"></i>
                                      </li>
                                  </ul>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="common__block-footer-table">
                  <div class="common__block-search">
                      <input class="common__search-input"
                          [ngShow]="clientsList.search"
                          [(ngModel)]="clientsList.searchClient"
                          placeholder="{{'common.search' | afTranslate}}"
                          [focusWhen]="clientsList.search">
                      <span class="common__text-search"
                          [ngShow]="!clientsList.search"
                          (click)="toggleSearch()"
                          [afTranslate]="'common.search'"></span>
                      <button class="lf-icon-search common__icon-button common__icon-button-search"
                          [ngClass]="{
                            'lf-icon-search': !clientsList.search,
                            'lf-icon-close-search common__icon-button-search--open': clientsList.search
                          }"
                          (click)="toggleSearch()">
                      </button>
                  </div>
                  <pagination-control class="common__pagination-block"
                      [ngShow]="clientList.length > clientsList.configPagination.pageSize"
                      [config]="clientsList.configPagination"
                      [collectionSize]="clientList.length"
                      [(currentPage)]="clientsList.currentPage"
                      (configuredTotalChange)="clientsList.configuredTotal = $event.configuredTotal"></pagination-control>
                  <div class="common__block-footer-total">
                      <span [ngShow]="clientList.length > clientsList.configPagination.pageSize">
                        <span class="notranslate">{{clientsList.configuredTotal.first}}-{{clientsList.configuredTotal.last}}</span>
                        <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                        <span class="notranslate">{{clientList.length}}</span>
                      </span>
                      <span [ngShow]="!(clientList.length > clientsList.configPagination.pageSize)">
                        <span [afTranslate]="'common.total'"></span>
                        <span class="common__total-number-element">{{clientList.length}}</span>
                      </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
