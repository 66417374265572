<div class="no-permissions">
  <div class="no-permissions__animate"
      [ngShow]="!noPermissions.showAdmins">
      <h1 class="no-permissions__title"
          [afTranslate]="'front-login.no-permissions.title'">Usuario sin permisos
      </h1>
      <div class="no-permissions__icon">
          <i class="lf-icon-key"></i>
      </div>
      <div>
          <span class="no-permissions__text"
              [afTranslate]="'front-login.no-permissions.help'">Por favor, contacta con un administrador para que gestione los permisos correspondientes.
          </span>
          <div class="no-permissions__first-admin">
              <div *ngIf="noPermissions.admins?.length">
                  <a class="no-permissions__link"
                      [href]="'mailto://'+noPermissions.admins[0]?.email">{{noPermissions.admins[0]?.email}}</a>
                  <button class="common__icon-button span__auto-ajust"
                      matTooltip="{{'front-login.no-permissions.show-all-admins-tooltip' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                          *ngIf="noPermissions.admins.length > 1"
                          (click)="noPermissions.showAdmins = true">
                      <i class="lf-icon-add-empty"></i>
                  </button>
              </div>
          </div>
      </div>
  </div>
  <div class="no-permissions__admins no-permissions__animate"
      [ngShow]="noPermissions.showAdmins">
      <h2 class="no-permissions__admins-title">
          <span [afTranslate]="'front-login.no-permissions.admins-title'">Administradores de la cuenta
          </span>
          <button class="common__icon-button no-permissions__close-admins-button"
                  (click)="noPermissions.showAdmins = false">
              <i class="lf-icon-close-empty"></i>
          </button>
      </h2>
      <ul class="no-permissions__admins-list">
          <li *ngFor="let admin of noPermissions.admins"
              class="no-permissions__admins-list-element">
              <a class="no-permissions__link"
                [href]="'mailto://'+admin.email">{{admin.email}}</a>
          </li>
      </ul>
  </div>
  <button class="no-permissions__button"
          (click)="continueClick()">{{continueText}}</button>
</div>
