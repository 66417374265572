
import moment from 'moment';

import { Injectable } from '@angular/core';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Injectable({
  providedIn: 'root'
})
export class BoxCommunicationChannelParentService {
  subscribers: any = {};
  constructor(private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private dateConverterService: DateConverterService,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private context: ContextFactory,
    private translate: AfTranslateFactory) { }

  onChanges(child:any){
      let that: any = child.boxCommunicationChannel;
      if(child.concept){
        that.concept = child.concept;
        that.conceptObjectId = that.concept.conceptObjectId;
        if(that.conceptObjectId &&  that.conceptObjectId !== 'view'){
          this.getComments(child)
        }
      }

      that.fullScreenBox = child.fullScreenBox;
      if(child.isLanding && child.boxCommunicationChannel.fullScreen && that.fullScreenBox !== '' && that.fullScreenBox !== 'box-comunication-channel-full'){
        this.maximizeScreen(true, child);
      }
  }


  getComments(child:any){
      this.apiService.get('comments/'+child.boxCommunicationChannel.conceptObjectId+'/1')
          .then((data:any)=>{
              this.getUnread(data, child)
              child.boxCommunicationChannel.commentList = data.reverse();
              this.showComments(child);
          }, (errorData:any)=>{});
  }

  getUnread(data:any, child:any){
    data.forEach((item:any) => {
      if(item.unread){
        if(item.managerMessage){
          child.boxCommunicationChannel.unreadAmountManager++;
        }else{
          child.boxCommunicationChannel.unreadAmountApplicant++;
        }
      }
    });
    if(child.boxCommunicationChannel.unreadAmountManager > 0 && child.isLanding && child.skinsAccepted){
      this.modalService.adviceModal(this.translate.instant('box-comunication-channel.new-unread'), 'info:accept:exclamation-full')
    }
  }

  showComments(child:any){
      let that: any = child.boxCommunicationChannel;
      if(that.commentList.length){
          that.commentListFilter = [];
          that.commentList.forEach((comment:any) => {
              comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
              let duration : any = moment.duration('00'+comment.audioDuration, 'seconds')
              if(comment.hasCommentAudio){
                comment.showAudioRecord = true;
                comment.noDelete = true;
                comment.name = comment.audioFileName;
                comment.maxTime =  comment.audioDuration;
                comment.secondsRecord =  duration / 1000
              }
          });
          that.commentListFilter = that.commentList;
      }else{
          that.commentListFilter = [];
      }
  }

  base64ToBlob(base64String:any, contentType = '') {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
  }

  newComment(child:any){
    child.boxCommunicationChannel.showCommentBox = true;
    if(!child.boxCommunicationChannel.fullScreen){
        child.boxCommunicationChannel.showCommentList = false;
    }
  }

  cancelComment(comment:any, child:any){
      comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
      child.boxCommunicationChannel.commentText = '';
      comment.edit = false;
      child.boxCommunicationChannel.edit = false;
      child.boxCommunicationChannel.showCommentBox = false;
      child.boxCommunicationChannel.showCommentList = true;
  }

  createNewComment(text:any, child:any){
    child.boxCommunicationChannel.disabledButtons = true;
    if(child.boxCommunicationChannel.hasVoiceRecord && !child.boxCommunicationChannel.arrayBufferLoaded){
      this.getArrayBuffer(text, child)
    }else{
      let textToAdd = !text? child.boxCommunicationChannel.commentText.replace(/(?:\r\n|\r|\n)/g, '<br>'): text;
      let comment: any = {
        commenttext: textToAdd,
        communicationChannel: true,
        managerMessage: !child.isLanding,
        commentAudio: child.boxCommunicationChannel.hasVoiceRecord? child.boxCommunicationChannel.arrayBuffer : null,
        audioFileName: child.boxCommunicationChannel.hasVoiceRecord? child.boxCommunicationChannel.voiceRecord.name: null,
        audioDuration: child.boxCommunicationChannel.hasVoiceRecord? child.boxCommunicationChannel.voiceRecord.maxTime: null
      }

      this.apiService.add('comments/'+child.boxCommunicationChannel.conceptObjectId, comment)
          .then((data:any)=>{
            data.author = this.context.user.fullName;
            this.configureNewComment(data, false, child);
            child.boxCommunicationChannel.disabledButtons = false;
          }, (errorData: any)=>{
            child.boxCommunicationChannel.disabledButtons = false;
          });
    }
  }

  getArrayBuffer(text:string, child:any){
    let fileReader = new FileReader();
    fileReader.onload = (event:any)=>{
      child.boxCommunicationChannel.arrayBuffer = [...new Uint8Array(event.target.result)];
      child.boxCommunicationChannel.arrayBufferLoaded = true;
      this.createNewComment(text, child)
    };
    fileReader.readAsArrayBuffer(child.boxCommunicationChannel.voiceRecord.file);
  }

  configureNewComment(data:any, keepList:any, child:any){
      child.boxCommunicationChannel.showCommentBox = false;
      child.boxCommunicationChannel.showCommentList = true;
      child.boxCommunicationChannel.commentText = '';
      child.boxCommunicationChannel.hasVoiceRecord = false;
      child.boxCommunicationChannel.voiceRecord = {}
      child.boxCommunicationChannel.arrayBufferLoaded = false;
      child.boxCommunicationChannel.arrayBuffer = null;
      if(!keepList){
          child.boxCommunicationChannel.commentList.splice(0, 0, data);
      }
      this.showComments(child);
  }


  maximizeScreen(keepClassBox:any, child:any){
      let that: any = child.boxCommunicationChannel;
      that.fullScreen = !that.fullScreen;
      this.showComments(child);
      that.showCommentBox = that.fullScreen;
      if(that.fullScreen){
          that.showCommentList=true;
      }
      if(!keepClassBox){
          child.catchFullScreen.emit({box:'box-comunication-channel-full'});
      }
  }

  deleteComment(comment:any, child:any){
      let type:any = child.isLanding?'info:question:question-full':'info:question:question';
      this.modalService.adviceModal(this.translate.instant('box-comunication-channel.delete-message'),  type)
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.loader.openLoader('box-comunication-channel-delete-message')
              if(child.boxCommunicationChannel.conceptObjectId){
                  this.apiService.delete('comments', comment.commentId)
                      .then((data:any)=>{
                          this.confirmDeleteComment(comment, child);
                      }, (error:any)=>{
                          this.loader.closeError();
                      })
              }else{
                  this.confirmDeleteComment(comment, child);
              }
            }else{
              this.loader.closeError();
            }
          })
  }

  confirmDeleteComment(comment:any, child:any){
      this.loader.closeLoader(['box-comunication-channel-delete-message']);
      child.boxCommunicationChannel.commentList = this.commonUtilsService.deleteElement( comment, child.boxCommunicationChannel.commentList , 'commentId');
      this.modalService.adviceModal(this.translate.instant('box-comunication-channel.delete-message-ok'), 'info:accept:check-round')
  }

  editComment(comment:any, child:any){
      child.boxCommunicationChannel.commentList.forEach((comment:any) => {
          comment.edit = false;
      });


      child.boxCommunicationChannel.showCommentBox = false;
      comment.edit = !comment.edit;

      if(!child.boxCommunicationChannel.fullScreen){
          child.boxCommunicationChannel.edit = !child.boxCommunicationChannel.edit;
      }
      setTimeout(function(){
          let element:any = $('#edit-comment-'+comment.commentId)
          element.select().focus();
      })

  }

  changeComment(comment:any, child:any){
      let element:any = $('#edit-comment-'+comment.commentId)[0];
      if(element.value.replace(/(?:\r\n|\r|\n)/g, '<br>') !== ''){
        this.modalService.adviceModal(this.translate.instant('box-comunication-channel.modify-message'), 'info:question:question')
            .subscribe((result:any)=>{
                if(result.result==='ok'){
                    this.loader.openLoader('box-comunication-channel-edit-message')
                    comment.commentText = element.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    if(child.boxCommunicationChannel.conceptObjectId){
                        this.apiService.patch('comments', comment.commentId, comment)
                            .then((data:any)=>{
                                this.refreshComment(data, comment, child);
                            }, (errorData:any)=>{
                                comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
                                child.boxCommunicationChannel.edit = false;
                                comment.edit = false;
                                this.loader.closeError();
                            })
                    }else{
                        this.refreshComment(comment, comment, child);
                    }
                }else{
                    this.loader.closeError();
                }
            })
      }else{
          comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
          child.boxCommunicationChannel.edit = false;
          comment.edit = false;
      }
  }

  refreshComment(data:any, comment:any, child:any){
    this.loader.closeLoader(['box-comunication-channel-edit-message']);
    child.boxCommunicationChannel.edit = false;
    comment.edit = false;
    this.configureNewComment(data, true, child);
    this.modalService.adviceModal(this.translate.instant('box-comunication-channel.modify-message-ok'), 'info:accept:check-round')
  }

  markAsRead(comment:any, child: any){
    this.apiService.patch('comments/', comment.commentId+'/set-unread/false').
    then((data:any)=>{
      comment.unread = false;
      if(comment.managerMessage){
        child.boxCommunicationChannel.unreadAmountManager--;
      }else{
        child.boxCommunicationChannel.unreadAmountApplicant--;
      }
    })
  }

  recordVoice(child:any){
    this.modalService.recordAudioModal({})
    .subscribe((result:any)=>{
      if(result.result ==='ok'){
        child.boxCommunicationChannel.hasVoiceRecord = true;
        child.boxCommunicationChannel.voiceRecord = {
          player: result.player,
          text: result.text,
          maxTime: result.maxTime,
          secondsRecord: result.secondsRecord,
          name: result.name,
          recordingFile: result.recordingFile,
          file: result.file
        }
        child.boxCommunicationChannel.commentText = result.text;
      }
    })
  }

  listenVoiceRecord(comment: any, isLanding:boolean, child:any){
    let voiceRecord: any = comment !== null? comment : child.boxCommunicationChannel.voiceRecord
    this.modalService.listenRecord(voiceRecord, isLanding).subscribe((result:any)=>{
      if(result.result ==='delete'){
        child.boxCommunicationChannel.hasVoiceRecord = false;
        child.boxCommunicationChannel.voiceRecord = {}
        child.boxCommunicationChannel.commentText = '';
      }
    },()=>{})
  }

  editTextArea(child: any){
    if(child.boxCommunicationChannel.commentText === ''){
      child.boxCommunicationChannel.hasVoiceRecord = false;
      child.boxCommunicationChannel.voiceRecord = {}
    }
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
