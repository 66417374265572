import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class reportColumnsResolve implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }

    resolve(route: ActivatedRouteSnapshot) {
      return this.apiService.get('reportobjects/fields', route.params['reportObjectId'])
        .then((data:any)=>{
          for(let column of data){
            column.columnType = String(column.filterType);
            column.textColumn = column.translatedName?column.translatedName:column.name;
          }
          return data
      })
    }



}
