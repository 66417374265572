import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'role-error-modal',
  templateUrl: './role-error-modal.component.html'
})
export class RoleErrorModalComponent implements OnInit {
  roleErrorModal: any = {
    roles: [],
    infoError: ''
  }
  constructor(private activeModal: NgbActiveModal,
    private translate: AfTranslateFactory) { }

  @Input() configuration: any = {};

  ngOnInit(): void {
    if(this.configuration.type === 'errorSaveUser' || this.configuration.type === 'errorUnassignUser'){
        let role = this.configuration.role;
        role.clients = this.configuration.list;
        this.roleErrorModal.roles.push(role);
    }else{
        this.roleErrorModal.roles = this.configuration.list;
    }
    switch(this.configuration.type){
        case 'errorSaveUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-save-user');
            break;
        case 'errorUnassignUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-delete-user');
            break;
        case 'errorDeleteUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-delete-user');
            break;
        case 'errorDeleteProductUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-delete-product-user');
            break;
        case 'errorDeleteOrganizationUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-delete-organization-user');
            break;
        case 'errorDeleteSomeOrganizationsUser':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.error-delete-some-organization-user');
            break;
        case 'notAssignedRoles':
            this.roleErrorModal.infoError = this.translate.instant('role-error-modal.not-assign-client-evaluation').replace('{clientName}', this.configuration.clientName);
            break;
    }
  }

  handleAccept() {
    this.activeModal.close({result: 'ok'});
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
