<ul class="concept-steps__custom-steps-block"
    [ngHide]="conceptSteps.filterContainers.length === 1">
    <li class="concept-steps__step-item"
        [ngStyle]="{'width': last? '3.5%':(100 - 3.5) / (filterContainers.length - 1) +'%'}"
        *ngFor="let fieldContainer of conceptSteps.filterContainers; let last = last"
        [ngClass]="{'concept-steps__step-item--last': last}">
        <div class="concept-steps__step-icon-block concept-steps__step-icon-block--custom"
            [ngStyle]="{'background':fieldContainer.active? custom.color:'#898989',
              'color': fieldContainer.active? custom.textColor:'#ffffff'}"
            [ngClass]="{'concept-steps__step-icon-block--active':fieldContainer.active}"
            (click)="selectStep(fieldContainer)"
            [matTooltip]="fieldContainer.name"
            matTooltipPosition="above"
            matTooltipClass="above">
            <p class="concept-steps__number">{{fieldContainer.index}}</p>
        </div>
        <hr class="concept-steps__line-step"
            [ngHide]="last">
    </li>
</ul>
