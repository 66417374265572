import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'help-modal',
  templateUrl: './help-modal.component.html'
})
export class HelpModalComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  headerLogoSrc:string;
  phoneAssist:string;
  phone:string;
  mailAssits:string;
  mailDirection:string;
  phone2:string;
  helpModal: any ={

  }
  constructor(
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService,
    private context:ContextFactory,
    private activeModal:NgbActiveModal,
    private translate:AfTranslateFactory
  ) { }

  ngOnInit(): void {
    this.configureTextModal();
  }

  configureTextModal(){
    switch(this.skin){
        case 'steel-blue':
            this.helpModal.phone = this.translate.instant('modal-help.tel-literal') + ':' + this.translate.instantSiteTerms('tel');
            this.helpModal.phoneAssist = this.translate.instant('modal-help.tel-assist-be');
            this.helpModal.phoneInfo = this.translate.instant('modal-help.info-be-an')+
                            '&nbsp<span class="modal-help__bold-text">'+this.translate.instant('modal-help.info-be-admin-question')+'</span>&nbsp'+
                            this.translate.instant('modal-help.info-be-admin-question-or')+
                            '&nbsp<span class="modal-help__bold-text">'+this.translate.instant('modal-help.info-be-admin-membership')+'</span>&nbsp'+
                            this.translate.instant('modal-help.info-be-our-customer');
            this.helpModal.mailAssits = this.translate.instant('modal-help.mail-assist-be')
            this.helpModal.mailInfo = this.translate.instant('modal-help.info-mail-be-do')+
                            '&nbsp<span class="modal-help__bold-text">'+this.translate.instant('modal-help.info-mail-be-technical-question')+'</span>&nbsp'+
                            this.translate.instant('modal-help.info-mail-be-question-about')+
                            '&nbsp<span class="modal-help__bold-text">'+this.translate.instant('modal-help.info-mail-be-product')+'</span>&nbsp'+
                            this.translate.instant('modal-help.info-mail-be-contact');
            this.helpModal.headerLogoSrc = '../assets/img/larcier-intersentia-color.png';
            this.helpModal.showHelpButton = false;
            this.helpModal.specialModal = true;
            break;
        case 'uk-steel-blue':
            this.helpModal.phone = this.translate.instant('modal-help.tel-literal') + ':' + this.translate.instantSiteTerms('tel');
            this.helpModal.phone2 = this.translate.instant('modal-help.info-uk-phone2');
            this.helpModal.phoneAssist = this.translate.instant('modal-help.header-phone-taxwin');
            this.helpModal.phoneInfo = this.translate.instant('modal-help.info-uk-general-assistance');
            this.helpModal.mailAssits = this.translate.instant('modal-help.header-mail-taxwin')
            this.helpModal.mailInfo = this.translate.instant('modal-help.info-uk-technical-support');
            this.helpModal.headerLogoSrc = '../assets/img/logo-TaxWin.png';//'../../assets/img/logo-indicator.png';
            this.helpModal.showHelpButton = false;
            this.helpModal.specialModal = true;
            break;
        case 'gm-law':
            this.helpModal.mailInfo = this.translate.instant('modal-help.info-mail');
            this.helpModal.phoneInfo = this.translate.instant('modal-help.info');
            this.helpModal.specialModal = true;
            this.helpModal.headerLogoSrc = '../assets/img/logo-segnala-header40.png';
            this.helpModal.showHelpButton = false;
            break;
        case 'com-fr':
            this.helpModal.phoneAssist = this.translate.instant('modal-help.tel-assist');
            this.helpModal.phoneInfo = this.translate.instant('modal-help.info');
            this.helpModal.mailAssits = this.translate.instant('modal-help.mail-assist');
            this.helpModal.mailInfo = this.translate.instant('modal-help.info-mail');
            this.helpModal.headerLogoSrc = '../assets/img/lefebvre_dalloz.png';
            this.helpModal.specialModal = false;
            this.helpModal.showHelpButton = true;
            break;
        default:// 'dark-blue'
            this.helpModal.phoneAssist = this.translate.instant('modal-help.tel-assist');
            this.helpModal.phoneInfo = this.translate.instant('modal-help.info');
            this.helpModal.mailAssits = this.translate.instant('modal-help.mail-assist');
            this.helpModal.mailInfo = this.translate.instant('modal-help.info-mail');
            this.helpModal.headerLogoSrc = '../assets/img/logo-lefebvre-ij.png';
            this.helpModal.showHelpButton = true;
            this.helpModal.specialModal = false;
            break;
    }
  }

  openURL(opcion:number){
    let url = '';
    let language = this.commonUtilsService.getLanguage().substring(0,2);
    switch(opcion){
        case 1:
            switch(this.skin){
                case 'steel-blue':
                    switch(language){
                      case 'nl':
                          url = 'https://www.gocomply.be/nl-support';
                          break;
                      case 'fr':
                          url ='https://www.gocomply.be/fr-support';
                          break;
                      default:
                          url ='https://www.gocomply.be/en-support';
                          break;
                    }
                    break;
                case 'uk-steel-blue':
                    url = 'https://download.lefebvre-sarrut.be/dms?id_=93a438d2-299b-49c6-bea7-eaac7d3c1546';
                    break;
                default:
                    url = 'https://centrodeayuda.lefebvre.es/preguntas-frecuentes/?idEntrada=' + this.context.user.externalAccessId;
                    break;
            }
            break;
        case 2:
            url = 'https://centrodeayuda.lefebvre.es/cursos/?idEntrada=' + this.context.user.externalAccessId;
            break;
        case 3:
            url = 'https://centrodeayuda.lefebvre.es/videos/?idEntrada=' + this.context.user.externalAccessId;
            break;
        case 4:
            switch(this.skin){
                case 'steel-blue':
                    switch(language){
                        case 'nl':
                            url = 'https://larcier.atlassian.net/wiki/spaces/GOC/pages/1702199462/Handleiding+GoComply';
                            break;
                        case 'fr':
                            url ='https://larcier.atlassian.net/wiki/spaces/GOC/pages/1702199455/Manuel+GoComply';
                            break;
                        default:
                            url='https://larcier.atlassian.net/wiki/spaces/GOC/pages/1759346810/Manual+GoComply';
                            break;
                    }
                    break;
                case 'uk-steel-blue':
                    url = 'https://download.lefebvre-sarrut.be/dms?id_=6bb90221-b901-4a38-81dc-7f43a3da4738';
                    break;
                case 'gm-law':
                  url = 'https://swift.it-mil2.entercloudsuite.com/v1/KEY_44f2d0920f254271a50851d48ed28f01/whistleblowing/Manuale_WB.html';
                  break;
                default:
                    url ='https://centrodeayuda.lefebvre.es/manuales/?idEntrada=' + this.context.user.externalAccessId;
                    break;
            }
            break;
        default:
            switch(this.skin){
              case 'com-fr':
                url = 'https://assistance.lefebvre-dalloz.fr/';
                break;
              default:
                url = 'https://centrodeayuda.lefebvre.es/?idEntrada=' + this.context.user.externalAccessId;
                break;
            }
            break;
    }
    window.open(url);
  }

  sendMail(){
    let subject = '';
    let email = this.translate.instantSiteTerms('mail');
    window.location.href = "mailto:" + email + "?" + subject;
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip('modal-help-'+id);
  }

  handleDismiss(){
    this.activeModal.close({result:'cancel'});
  }
}
