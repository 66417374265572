// import * as Tone from 'tone'
import { FieldTypeTextParentService } from './../utils/field-type-text-parent.service';
import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';


@Component({
  selector: 'field-type-text-multiline',
  templateUrl: './field-type-text-multiline.component.html'
})
export class FieldTypeTextMultilineComponent implements OnChanges, OnInit, OnDestroy{
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  globalSkin: string = this.globalCfg.skin;
  id: any = this.commonUtilsService.createId();
  interval: any;
  initInterval: number = 0;
  percentTime:number = 0;
  startAudio: boolean = false;
  subscribers: any = {}

  fieldTypeText: any = {
    openLaw: false,
    references: false,
    inputText: '',
    titleField: '',
    minHeight:'',
    acceptAceptation: false,
    hasVoiceRecord: false,
    voiceRecord:{}
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory,
    private documentActions: DocumentActionsService,
    private fieldTypeTextParent: FieldTypeTextParentService,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService) {}

  @Input() field : any ={};
  @Input() concept : any ={};
  @Input() preSelectItem : any ='';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();
  @Output() itemSelectedEnter = new EventEmitter();
  @Output() saveVoiceRecord = new EventEmitter();

  ngOnInit(): void {
    this.subscribers.saveConceptToEvaluationBroadcast = this.broadcastService.subscribe('closePlay', (data:any)=>{
      if(this.startAudio){
        this.stopInterval()
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fieldTypeTextParent.configureChanges(changes, this);

  }

  keyChangeInField(e: any){
    this.fieldTypeTextParent.keyChangeInField(e, this);
    if(this.fieldTypeText.inputText === ''){
      this.resetInput()
    }
  }

  changeInField(e:any={}){
    this.fieldTypeTextParent.changeInField(e, this)
    if(this.fieldTypeText.inputText === ''){
      this.resetInput()
    }
  }

  analyzeWithSibila() {
    if(this.fieldTypeText.inputText.trim() !== ''){
        this.documentActions.doAction('sibila', { text: this.commonUtilsService.jsonEncode(this.fieldTypeText.inputText) });
    }
  }

  openTextareaModal() {
    this.fieldTypeTextParent.openTextareaModal(this);
  }

  resetInput(){
    if(this.startAudio){
      this.stopInterval()
    }
    this.fieldTypeText.acceptAceptation = false;
    this.fieldTypeText.hasVoiceRecord = false
    this.fieldTypeText.voiceRecord = {};
    this.saveVoiceRecord.emit({
      acceptAceptation: this.fieldTypeText.acceptAceptation,
      voiceRecordFile: null,
      name: ''
    })
    this.fieldTypeTextParent.resetInput(this);
  }

  showReference(e: any){
    this.fieldTypeTextParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeTextParent.openModalRelatedInfo(e, fieldRelatedInfo, this)
  }

  openRecordVoice(){
    if(!this.field.noEditable){
      this.fieldTypeTextParent.openRecordVoice(this);
    }

  }

  playRecordVoice(){
    this.startAudio = !this.startAudio;
    if(!this.fieldTypeText.audioLoaded){
      this.getAudio()
    }else{
      if(this.startAudio){
        this.initInterval = 0;
        this.initStartAudio()
      }else{
        this.stopInterval();
      }
    }
  }

  getAudio(){
    let url:string = 'file='+ this.field.evaluatedValue.documents[0].editPath;
    this.apiService.getBlob('utils/file/download?' + url)
      .then((data:any) => {
        this.fieldTypeText.voiceRecord.recordingFile = data.blob;
        let blobUrl: string = URL.createObjectURL(data.blob);
        this.fieldTypeText.voiceRecord.player = new Audio();
        this.fieldTypeText.voiceRecord.player.src = blobUrl
        this.getDuration(blobUrl)
          .then((duration:any)=>{
            this.fieldTypeText.voiceRecord.secondsRecord = duration.toFixed(2);
            this.fieldTypeText.audioLoaded = true;
            this.fieldTypeText.hasVoiceRecord = true;
            this.fieldTypeText.loadFromBack = true;
            this.initInterval = 0;
            this.initStartAudio();
          })
        $(this.fieldTypeText.voiceRecord.player).on("loadedmetadata", ()=>{
          
        });




      }, (error:any) => {})
  }

  getDuration(src:any) {
    return new Promise(function(resolve) {
        var audio = new Audio();
        $(audio).on("loadedmetadata", function(){
            resolve(audio.duration);
        });
        audio.src = src;
    });
  }


  initStartAudio(){
    this.fieldTypeText.voiceRecord.player.play();
    this.interval = setInterval(() => {
      this.percentTime = this.initInterval * 100 / this.fieldTypeText.voiceRecord.secondsRecord;
      if(this.percentTime >= 100){
        this.stopInterval()
      }else{
        this.initInterval++
      }
    }, 1000);
    this.startAudio = true;
  }

  stopInterval(){
    this.startAudio = false;
    this.initInterval = 0;
    this.percentTime = 0;
    this.fieldTypeText.voiceRecord.player.pause();
    this.fieldTypeText.voiceRecord.player.currentTime = 0;
    clearInterval(this.interval)
  }


  audioDownLoad(){
    const url = URL.createObjectURL(this.fieldTypeText.voiceRecord.recordingFile);
	  const anchor = document.createElement("a");
	  anchor.download = this.fieldTypeText.voiceRecord.name;
	  anchor.href = url;
	  anchor.click();
  }

  acceptAceptationTerms(){
    if(!this.field.noEditable){
      this.fieldTypeText.acceptAceptation = !this.fieldTypeText.acceptAceptation;
      if(this.fieldTypeText.acceptAceptation){
        this.field.errorInAcceptAceptation = false;
      }

      this.saveVoiceRecord.emit({
        acceptAceptation: this.fieldTypeText.acceptAceptation,
        voiceRecordFile: this.fieldTypeText.voiceRecord.file,
        name: this.fieldTypeText.voiceRecord.name
      })
    }
  }
  ngOnDestroy(): void {
      this.commonUtilsService.OnDestroy(this.subscribers);
  }
}

