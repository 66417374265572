

import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit, } from '@angular/core';

import { CropperComponent } from 'angular-cropperjs';

import { AfTranslateFactory } from "@shared/modules/translate/factory/translate.factory";
import { ModalServiceService } from "@shared/services/modal-service/modal-service.service";


@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html'
})
export class ImageEditorComponent implements OnInit {
  public imageElement: HTMLImageElement;

  imageEditor:any = {
    xScale: 1,
    yScale: 1,
    minAspectRatio: 0,
    maxAspectRatio: 1.5,
    event: {},
    previewReady: false,
    cropperOptions: {}

  }

  constructor(
    private modalService:ModalServiceService,
    private translate:AfTranslateFactory
  ) { }

  @Input() selectedSize:any;
  @Input() imageUrl:any;

  @Output() canvasSelect = new EventEmitter();

  @ViewChild('angularCropper') public angularCropper: CropperComponent;


  ngOnInit(): void {
    this.imageEditor.cropperOptions = {

        aspectRatio : this.selectedSize.width/this.selectedSize.height,
        background : true,
        movable: true,
        rotatable : true,
        scalable: true,
        zoomable: true,
        viewMode: 0,
        checkImageOrigin : true,
        dragmode: 'none',
        checkCrossOrigin: true,
        cropBoxResizable: false,
        responsive: true,
        minContainerWidth: 764,
        minContainerHeight: 340,
        ready: (event:any) => this.ready(event),
        cropmove:()=> this.cropMove()
    }
  }

  zoom(type:any){
    let zoom:any = type=== 'more'?0.1 : -0.1;
    this.angularCropper.cropper.zoom(zoom);
    this.cropMoved();
  }

  moveTo(type:any){
    switch(type){
        case 'up':
            this.angularCropper.cropper.move(0, -4);
        break;
        case 'down':
            this.angularCropper.cropper.move(0, 4);
        break;
        case 'left':
            this.angularCropper.cropper.move(-4, 0);
        break;
        default :
            this.angularCropper.cropper.move(4, 0);
        break;
    }
    this.cropMoved();
  }

  rotate(type:any){
    this.angularCropper.cropper.rotate(parseInt(type+30));
    this.cropMoved();
  }

  flip(type:any){
    type==='x'? this.imageEditor.xScale *= -1 : this.imageEditor.yScale *= -1;
    this.angularCropper.cropper.scale(this.imageEditor.xScale, this.imageEditor.yScale);
    this.cropMoved();
  }

  closeEdit(){
      this.canvasSelect.emit({canvas: null})
  }

  saveAndCloseEdit(){
    this.modalService.adviceModal(this.translate.instant('configure-image.change-image'), 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.canvasSelect.emit({canvas: this.angularCropper.cropper.getCroppedCanvas({"width": this.selectedSize.width, "height": this.selectedSize.height})})
        }
      })
  }

  ready(event:any){
    let that = this.imageEditor;
    let selectedSize = this.selectedSize;
    let cropper: any = this.angularCropper.cropper;
    cropper.setCropBoxData({
        left: (765 - this.selectedSize.width) / 2,
        top: (340 / 2) - (this.selectedSize.height /2),
        width: this.selectedSize.width,
        height: this.selectedSize.height
    });

    let canvasData: any = cropper.getCanvasData()

    cropper.setCanvasData({
      height: canvasData.height > 340? 340 : canvasData.height
    })

    let canvasDataAfterResize: any = cropper.getCanvasData();

    cropper.setCanvasData({
        left: 765 / 2 - canvasDataAfterResize.width / 2

    })


    this.imageEditor.preview = cropper.getCroppedCanvas({
      height: selectedSize.height,
      width: selectedSize.width
    }).toDataURL();
  }


  cropMoved(data:any=undefined){
    this.imageEditor.preview = this.angularCropper.cropper.getCroppedCanvas({
      height: this.selectedSize.height,
      width: this.selectedSize.width
    }).toDataURL();
  }

  cropMove(){
    let cropper: any = this.angularCropper.cropper;
    let cropBoxData = cropper.getCropBoxData();
    let aspectRatio = cropBoxData.width / cropBoxData.height;

    if (aspectRatio < this.imageEditor.minAspectRatio) {
        cropper.setCropBoxData({
            width:  this.selectedSize.width,
            height: this.selectedSize.height
        });
    } else if (aspectRatio > this.imageEditor.maxAspectRatio) {
        cropper.setCropBoxData({
            width:  this.selectedSize.width,
            height: this.selectedSize.height
        });

     }
     this.cropMoved();
  }

}

















