import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  printContent(id: any, printTitle: any=''){
    let iframe = $('<iframe id="printFrame" style=""></iframe>');
    iframe.css({
        display: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        'z-index': 10000,
        'background-color': 'white'
    })
    $(document.body).append(iframe);
    let htmlSeletected: any = document.getElementById(id);
    let htmlContent = htmlSeletected.innerHTML;
    this.writeContent(iframe[0], htmlContent, printTitle);
  }

  writeContent(iframe: any, elementSelected: any, printTitle: any) {
      let iframeWindow = iframe.contentWindow;
      let doc = iframeWindow.document;
      doc.open();
      doc.write('<head>');
      let base = document.getElementsByTagName('base');
      if (base && base.length) {
          doc.write('<base href="');
          doc.write($(base[0]).attr('href'));
          doc.write('" >');
      }
      if (printTitle) {
          doc.write('<title>');
          doc.write(this.htmlEncode(printTitle));
          doc.write('</title>');
      }
      let stylesheets = document.getElementsByTagName('link');
      for (let i = 0; i < stylesheets.length; i++) {
          let $stylesheet = $(stylesheets[i]);
          if ($stylesheet.attr('rel') === 'stylesheet' && ($stylesheet.attr('type') === "text/css" || $stylesheet.attr('type') === undefined)) {
              let cssHref: any = $stylesheet.attr('href');
              if (cssHref.indexOf('bootstrap') === -1) {
                  doc.write('<link rel="');
                  doc.write($stylesheet.attr('rel'));
                  doc.write('"');
                  if ($stylesheet.attr('type') !== undefined) {
                      doc.write(' type="');
                      doc.write($stylesheet.attr('type'));
                      doc.write('"');
                  }
                  doc.write(' href="');
                  doc.write(cssHref);
                  doc.write('" media="all" />');
              }
          }
      }
      doc.write('</head>');
      doc.write('<body onload="window.print();">');
      doc.write(elementSelected);
      doc.write('</body>');
      doc.close();
  }

  htmlEncode(text: any) {
      let divElement = $('<div/>');
      divElement.text(text);
      return divElement.text();
  }
}
