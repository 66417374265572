import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyToShow'
})
export class OnlyToShowPipe implements PipeTransform {

  transform(items: any, type:string = '') {
    let filtered:any = [];
    for(let i:number = 0; i < items.length; i++){
      let item: any = items[i]
      if((type === 'conceptSteps' && item.show && !item.hidden && item.showWithProperty)
        || (type === 'wizardSteps' && !item.noEditable)){
          item.originalStep = i+1;
          item.index = filtered.length+1;
          filtered.push(item);
      }else{
          delete item.index;
      }
    }
    return filtered;
  }

}
