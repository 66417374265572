
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qr-modal',
  templateUrl: './qr-modal.component.html'
})
export class QrModalComponent {
  constructor(private activeModal: NgbActiveModal) { }

  @Input() url:string;

  triggerDownload (imgURI:any) {
      let a:HTMLAnchorElement = document.createElement('a');
      a.setAttribute('download', 'image.svg');
      a.setAttribute('href', imgURI);
      a.setAttribute('target', '_blank');
      a.click();
  }

  svgToImg(){
      let svg:any = document.querySelector('.qr-modal__body-inner');
      let data:string = new XMLSerializer().serializeToString(svg);
      let svgBlob:Blob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
      let url:string = URL.createObjectURL(svgBlob);
      this.triggerDownload(url);
  }

  handleAccept() {
      this.svgToImg();
      this.activeModal.close({result: 'ok'});
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
