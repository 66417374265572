<!-- Cabecera -->
<div class="common__table-header duplicate-documentation__table-header">
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan"></div>
    <div class="duplicate-documentation__table duplicate-documentation__table--title-digitization">
        <filter-by-search
            [item]="duplicateDocumentation.tituloFilter"
            [filterOrder]="duplicateDocumentation.dateOrder"
            [order]="duplicateDocumentation.orderOperationDates"
            (launchActionSearch)="orderBySearch($event, 'apellidos')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--isbn-publication">
        <filter-by-search
            [item]="duplicateDocumentation.referenciaFilter"
            (launchActionSearch)="orderBySearch($event, 'referencia')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-right">
    </div>
</div>
<!-- Contenido -->
<div class="duplicate-documentation__table-body"
    [ngClass]="{'duplicate-documentation__table-body--more-elements':filterConceptList?.length >= (pageSize*currentPage)-3}">
    <img [src]="duplicateDocumentation.loaderImg"
        class="duplicate-documentation__spinner-loader"
        [ngShow]="duplicateDocumentation.searchingInstances" alt="loader">
    <div class="duplicate-documentation__table-row"
        [ngShow]="!duplicateDocumentation.searchingInstances"
        *ngFor="let instance of filterConceptList
            | afOrderBy:duplicateDocumentation.dateOrder:duplicateDocumentation.orderOperationDates
            | afLimitTo:pageSize:pageSize*(currentPage - 1)"
        (click)="selectInstance(instance)">
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
            <i class="lf-icon-compliance duplicate-documentation__icon-product-scan"></i>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--title-digitization">
            <div class="common__cell-block">
                <parragraph-tooltip [text]="instance.titulo"></parragraph-tooltip>
            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--isbn-publication">
            <div class="duplicate-documentation__cell-block duplicate-documentation__cell-block--organization">
                <parragraph-tooltip [text]="instance.referencia"></parragraph-tooltip>
            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-right">
            <span class="duplicate-documentation__text-explore"
                [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
            <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
        </div>
    </div>
</div>
