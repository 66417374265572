import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportVersionFactory {
  dashboardReportVersion: number = 0;
  folderReportVersion: number = 0
  dashboardReportItsNewVersion: boolean = false;
  folderReportItsNewVersion: boolean = false;
  notCallReportService: boolean = true;
  constructor() {
    let reportVersion: any = sessionStorage.getItem('report-version');
    if(reportVersion){
      this.setReportVersion(JSON.parse(reportVersion))
    }
  }

  setReportVersion(data:any){
    this.dashboardReportVersion = data[0].reportid;
    this.folderReportVersion = data[1].reportid;
    this.dashboardReportItsNewVersion = data[0].isNewVersion
    this.folderReportItsNewVersion = data[1].isNewVersion
    this.notCallReportService = false;
    sessionStorage.setItem('report-version', JSON.stringify(data))
  }
  getReportVersion(){
    return this;
  }
}
