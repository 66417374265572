import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IcamPriceFormulaFactory {
  mapped:any = new Map();

  set(key:string, value:any){
    this.mapped.set(key, value);
  }

  get(key: string): any{
    return this.mapped.get(key);
  };

  getAll(): any{
    return this.mapped;
  }
}
