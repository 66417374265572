
<div class="associated-documentation">
  <div class="associated-documentation__block-context">
      <div class="common__modal-header-block">
          <i [class]="' '+associatedDocument.iconHeader+' common__icon-header'"></i>
          <span class="common__text-header">{{associatedDocument.titleHeader}}</span>
          <i class="lf-icon-close common__icon-close-modal process-modal__toolbar"
              (click)="handleDismiss()"></i>
      </div>

      <div class="associated-documentation__block-body"
          [ngClass]="{'associated-documentation__block-body--change-view': associatedDocument.showChangeAssociated && from === 'process-modal',
          'associated-documentation__block-body--second-step': associatedDocument.showChangeAssociated && from !== 'process-modal',
          'associated-documentation__block-body--preview-associated': from !== 'process-modal' && associatedDocument.previewAssociated}">
          <div class="associated-documentation__block-table"
              [ngHide]="associatedDocument.showChangeAssociated">
              <div class="associated-documentation__table-header-box">
                  <div class="associated-documentation__table-header associated-documentation__table-header--title"
                      [afTranslate]="'common.title'">_Título</div>
                  <div class="associated-documentation__table-header associated-documentation__table-header--format"
                      [afTranslate]="'common.format'">_Formato</div>
                  <div class="associated-documentation__table-header associated-documentation__table-header--creation"
                      (click)="orderDate('created')">
                      <span class="common__cursor-pointer"
                          [afTranslate]="'common.creation'">_Creación</span>
                      <i class="lf-icon-arrow-down common__order-by common__order-by--top-2"
                          [ngClass]="{'lf-icon-arrow-up': associatedDocument.order==='created' && !associatedDocument.orderCreation,
                          'common__order-by--active': associatedDocument.order==='created'}"></i>
                  </div>
                  <div class="associated-documentation__table-header associated-documentation__table-header--author"
                      [afTranslate]="'common.author'">_Autor</div>
                  <div class="associated-documentation__table-header associated-documentation__table-header--actions"
                      [afTranslate]="'common.actions'">_Acciones</div>
              </div>
              <div class="associated-documentation__table-body-box">
                  <img [src]="associatedDocument.loaderImg"
                      class="associated-documentation__spinner-loader"
                      [ngShow]="associatedDocument.searchingDocuments"
                      alt="spinner"/>
                  <div class="associated-documentation__table-body-element"
                      [ngShow]="!associatedDocument.searchingDocuments"
                      *ngFor="let document of associatedDocument.documentationList | afOrderBy:associatedDocument.order:associatedDocument.orderCreation; let index = index">
                      <div class="associated-documentation__line-table associated-documentation__line-table--title">
                          <div class="common__cell-block">
                            <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                          </div>
                      </div>
                      <div class="associated-documentation__line-table associated-documentation__line-table--format">
                          <i [class]="' '+(document.extension | extensionDocument)+' associated-documentation__table-icon'"></i>
                      </div>
                      <div class="associated-documentation__line-table associated-documentation__line-table--creation">
                          {{document.created | convertDate:'without-time'}}
                      </div>
                      <div class="associated-documentation__line-table associated-documentation__line-table--author">
                          <div [class]="'common__cell-block associated-documentation-title-'+index">
                            <parragraph-tooltip [text]="document.createdBy"></parragraph-tooltip>
                          </div>
                      </div>

                      <div class="associated-documentation__line-table associated-documentation__line-table--actions"
                          ngbDropdown>
                          <button class="lf-icon-kebab-menu associated-documentation__icon-ellipsis"
                            ngbDropdownToggle>
                          </button>
                          <ul class="associated-documentation__list-menu-block"
                            ngbDropdownMenu
                            role="menu">
                              <li class="common__list-dropdown-option"
                                  *ngFor="let optionMenu of associatedDocument.expandedMenu; let last = last">
                                  <button class="common__button-option-list-dropdown"
                                      [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                      (click)="launchAction(optionMenu.action, document)">
                                      <i [class]="' '+optionMenu.icon+' common__icon-option-list-dropdown'"></i>
                                      <span class="common__text-menu-option-dropdown"
                                        [afTranslate]="optionMenu.name"></span>
                                  </button>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="associated-documentation__text-step">
                  <span [afTranslate]="'process.step'">_Paso</span>:
                  <span>{{process?.name}}</span>
              </div>
          </div>
          <div [ngShow]="associatedDocument.showChangeAssociated">
              <div class="associated-documentation__block-document-selected"
                  [ngShow]="from === 'process-modal'">
                  <i [class]="' '+(associatedDocument.documentSelected.extension | extensionDocument)+' associated-documentation__icon-document-selected'"></i>
                  <span class="associated-documentation__name-document-selected">{{associatedDocument.documentSelected.name}}</span>
              </div>
              <div class="associated-documentation__block-pill"
                  [ngShow]="from !== 'process-modal' && associatedDocument.previewAssociated">
                  <field-type-switch [label]="'associated-documentation.desassociate-document-process'|afTranslate"
                                      [checked]="associatedDocument.desassociateDocument"
                                      (callback)="associatedDocument.desassociateDocument = $event.value">
                  </field-type-switch>
              </div>
              <field-type-list-simple
                  [ngHide]="associatedDocument.desassociateDocument"
                  [field]="associatedDocument.processSteps"
                  [listValues]="associatedDocument.listSelectShow"
                  [preSelectItem]="associatedDocument.stateId"
                  (itemSelected)="selectProcess($event)">
              </field-type-list-simple>
              <div class="common__buttons-block common__buttons-block--modal">
                  <button type="reset"
                      class="common__button common__button--clean"
                      [ngShow]="from === 'documentation'"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">_Cancelar</button>
                  <button type="reset"
                      class="common__button common__button--clean"
                      [ngShow]="from === 'process-modal'"
                      (click)="associatedDocument.showChangeAssociated = !associatedDocument.showChangeAssociated"
                      [afTranslate]="'common.return'">_Volver</button>
                  <button type="submit"
                      class="common__button common__button--active"
                      [ngClass]="{'common__button--disabled-opacity':associatedDocument.disabledSave && !associatedDocument.desassociateDocument}"
                      (click)="changeAssociate()"
                      [disabled]="associatedDocument.disabledSave && !associatedDocument.desassociateDocument">{{associatedDocument.acceptButton}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
