import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { FrontLoginParentService } from '../utils/front-login-parent.service';

@Component({
  selector: 'front-login',
  templateUrl: './front-login.component.html'
})
export class FrontLoginComponent  implements OnInit {
    skin:string = this.globalCfg.skin;
    showList:any = false;
    snapshot: any = this.route.snapshot;
    frontLogin:any = this.frontLoginParent.configureFrontLogin(this, this.route.snapshot);


  constructor(private translate: AfTranslateFactory,
      private apiService: ApiServiceService,
      private route: ActivatedRoute,
      private globalCfg: GlobalCfgFactory,
      private router: Router,
      private frontLoginParent: FrontLoginParentService,
      ) {
    }

  ngOnInit(): void {
    if(this.route.snapshot.data['name'] === 'dfa'){
      this.frontLoginParent.configureSpecificationsBySkin(this);
      this.frontLoginParent.configureDfaLogin(this, this.route.snapshot);
    }else{
      this.frontLoginParent.onInit(this, this.route.snapshot);
      this.configureTemplateApp();
    }
  }

  clickAction(e: any=null){
    this.frontLoginParent.clickAction(this, e);
  }

  resetPassword(){
    this.frontLoginParent.resetPassword(this);
  }

  sendReset(){
    this.frontLoginParent.sendReset(this);
  }

  doLogin(loginForm: any) {
    this.frontLoginParent.doLogin(this, loginForm);
  }

  tryAgain(){
    this.frontLoginParent.tryAgain(this);
  }

  changeAppLanguage(language:any){
      if(this.route.snapshot.params['err'] && (!this.route.snapshot.params['err'].description || (this.route.snapshot.params['err'].description && this.route.snapshot.params['err'].description !== 'regular-not-supported'))){
          sessionStorage.setItem('paramsError', this.route.snapshot.params['err']);
      }
      sessionStorage.setItem('selectLanguage', language.appLanguage);
      window.location.reload();
  }

  generateNewPassword(){
      if(this.validatePassword()){
          let changePassword = {
              newPassword: this.frontLogin.newPassword.password
          };
          this.frontLogin.working = true;
          this.apiService.patch('frontusers/changepassword','',changePassword)
              .then(()=> {
                  this.frontLogin.working = false;
                  this.frontLogin.generatedPassOk = true;
              }, ()=>{
                  this.frontLogin.working = false;
                  this.frontLogin.showViewGenerate = false;
                  this.frontLogin.unexpectedError = true;
                  this.frontLogin.icon = "lf-icon-close-round-full front-login__input-icon--error-"+this.frontLogin.skin;
                  this.frontLogin.resetPassTitle = this.translate.instant('login-block.error-pass-title');
                  this.frontLogin.resetPassText = this.translate.instant('login-block.error-pass-text');
              });
      };
  }

  validatePassword(){
      this.frontLogin.notSecurePassword = false;
      this.frontLogin.messageInfoError = '';
      if(!this.frontLogin.newPassword.password && !this.frontLogin.newPassword.repeatPassword){
          this.frontLogin.newPassword.repeatPasswordError = true;
          this.frontLogin.newPassword.passwordError = true;
          return false;
      }
      let errorValidate:boolean = false;
      let validateNewPassNumber = (/[0-9]/).test(this.frontLogin.newPassword.password);
      let validateNewPassLetter = (/[a-z]|[A-Z]/).test(this.frontLogin.newPassword.password);
      if(this.frontLogin.newPassword.password){
          this.frontLogin.newPassword.passwordError = false;
      }else{
          this.frontLogin.newPassword.passwordError = true;
          errorValidate = true;
      }
      if(this.frontLogin.newPassword.repeatPassword){
          this.frontLogin.newPassword.repeatPasswordError = false;
      }else{
          this.frontLogin.newPassword.repeatPasswordError = true;
          errorValidate = true;
      }
      if(this.frontLogin.newPassword.password.length && ((this.frontLogin.newPassword.password.length < 8 || this.frontLogin.newPassword.password.length > 32) || !validateNewPassNumber || !validateNewPassLetter)){
          this.frontLogin.newPassword.passwordError = true;
          this.frontLogin.newPassword.repeatPasswordError = true;
          this.frontLogin.notSecurePassword = true;
          this.frontLogin.messageInfoError = 'reset-password.not-secure-password';
          errorValidate = true;
      }else if(this.frontLogin.newPassword.password.length && this.frontLogin.newPassword.repeatPassword.length && this.frontLogin.newPassword.password !== this.frontLogin.newPassword.repeatPassword){
          this.frontLogin.newPassword.passwordError = true;
          this.frontLogin.newPassword.repeatPasswordError = true;
          this.frontLogin.noMatchPassword = true;
          this.frontLogin.messageInfoError = 'reset-password.password-no-match';
          errorValidate = true;
      }
      return !errorValidate;
  }

  goToLoginView(){
    this.router.navigate(['login']);
  }

  configureTemplateApp(){
      switch(this.skin){
          case 'steel-blue':
              this.frontLogin.logo = this.frontLogin.logoHeader;
              break;
          case 'uk-steel-blue':
              this.frontLogin.logo = '../assets/img/logo-TaxWin_211x60.png';
              break;
          case 'icam-red':
              this.frontLogin.logo = '../assets/img/logo-icam-login.png';
              break;
          case 'gm-law':
              this.frontLogin.logo = '../assets/img/logo-segnala-login.png';
              break;
          case 'com-fr':
              this.frontLogin.logo = '../assets/img/logo-go-comply-fr-262x60.png';
              break;
          default:
              this.frontLogin.logo = 'https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-centinela-248x60.png';
              break;
      }
      if (!this.globalCfg.caps?.credentialsLogin) {
          this.frontLogin.errorDescription = !this.route.snapshot.params['err']? 'regular-not-supported': !this.route.snapshot.params['err'];
      }
  }
}
