import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-password',
  templateUrl: './field-type-password.component.html'
})
export class FieldTypePasswordComponent implements OnInit, OnChanges {

  @Input() field : any ={};
  @Input() preSelectItem : any ='';
  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();

  fieldTypePassword:any = {
    openLaw: false,
    references: false,
    inputTextOriginal: ''
  }
  isLanding: boolean = this.customLanding.isLanding();
  skin = this.isLanding?'landing-custom':this.globalCfg.skin;
  constructor(
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory
    ) { }

  ngOnInit(): void {
    if(this.field) {
      this.fieldTypePassword.titleField = this.field.label;
        if(this.field.extendedLabel && this.field.extendedLabel !== ''){
          this.fieldTypePassword.titleField = this.field.extendedLabel;
        }
        if(!this.field.id || this.field.generateId){
            this.field.id = this.commonUtilsService.createId();
        }else if(this.field.fieldId){
          this.field.id = this.field.fieldId
        }
    }
    this.changePreselectValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changePreselectValue();
  }

  changePreselectValue(){
    if(this.preSelectItem===null || this.preSelectItem===undefined || this.preSelectItem===''){
      this.fieldTypePassword.inputText = "";
      this.fieldTypePassword.inputTextOriginal = '';
      this.fieldTypePassword.inputTextHide = '';
    }else if(this.preSelectItem && this.preSelectItem !== this.fieldTypePassword.inputTextOriginal){
      this.fieldTypePassword.inputTextOriginal = this.preSelectItem;
      this.fieldTypePassword.inputTextHide = this.preSelectItem.replace(/./g, "*")+'*';
      this.changeVisibility();
    }
  }

  changeVisible(){
    this.field.visible = !this.field.visible;
    this.changeVisibility();
  }

  changeVisibility(){
      if(this.field.visible){
          this.fieldTypePassword.inputText = this.fieldTypePassword.inputTextOriginal;
      }else{
          this.fieldTypePassword.inputText = this.fieldTypePassword.inputTextHide;
      }
  }

  deleteCharacters(e:any){
    if(e.keyCode === 8){
        this.fieldTypePassword.inputTextHide = e.srcElement.value.replace(/./g, "*");
        if(this.field.visible){
            this.fieldTypePassword.inputTextOriginal = e.srcElement.value;
            this.fieldTypePassword.inputText = this.fieldTypePassword.inputTextOriginal;
        }else{
            this.fieldTypePassword.inputText = this.fieldTypePassword.inputTextHide;
            this.fieldTypePassword.inputTextOriginal = this.fieldTypePassword.inputTextOriginal.substr(0, this.fieldTypePassword.inputTextHide.length)
        }
    }
  }
  changeCharacters(e:any){
    if(e.keyCode !== 8){
        if(e.target.selectionStart !== e.target.selectionEnd){
          this.fieldTypePassword.inputTextOriginal = this.fieldTypePassword.inputTextOriginal.substring(0, e.target.selectionStart) + e.key + this.fieldTypePassword.inputTextOriginal.substring(e.target.selectionEnd, this.fieldTypePassword.inputTextOriginal.length);
        }else{
          this.fieldTypePassword.inputTextOriginal = this.fieldTypePassword.inputTextOriginal + e.key;
        }
        this.changeVisibilityCharecters();
    }
  }

  changeVisibilityCharecters(){
    if(this.fieldTypePassword.inputText.length){
        if(!this.field.visible){
          this.fieldTypePassword.inputText = this.fieldTypePassword.inputText.replace(/./g, "*");
            setTimeout(() =>{
              this.fieldTypePassword.inputText = this.fieldTypePassword.inputText.replace(/./g, "*");
            },100)
        }
        this.fieldTypePassword.inputTextHide = this.fieldTypePassword.inputText.replace(/./g, "*")+'*';
    }
  }

  pasteTextInField(e:any){
    setTimeout(()=>{
      this.fieldTypePassword.inputTextOriginal = e.srcElement.defaultValue;
      this.changeVisibilityCharecters();
    });
  }

  changeInField(){
    this.itemSelected.emit({newValue:this.fieldTypePassword.inputTextOriginal});
  }

  resetInput(){
    this.fieldTypePassword.inputText = '';
    this.fieldTypePassword.inputTextOriginal = '';
    this.fieldTypePassword.inputTextHide = '';
    this.itemSelected.emit({newValue:this.fieldTypePassword.inputTextOriginal});
  }

  showReference(e:any){
    e.stopPropagation();
    this.fieldTypePassword.references = !this.fieldTypePassword.references;
    this.showFieldReference.emit();
  }
}
