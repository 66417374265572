import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';
import { VisibilityService } from '@shared/services/visibility/visibility.service';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Component({
  selector: 'templates-concept',
  templateUrl: './templates-concept.component.html'
})
export class TemplatesConceptComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  conceptId: any = this.router.snapshot.params['conceptId'];
  evaluationId: any = this.router.snapshot.params['evaluationId'];
  expandMenu:any = ExpandMenu;
  templateConcept: any = {
    listTemplatesBack: [],
    listTemplates: [],
    menuDocumentTemplates: this.expandMenu['menuDocumentTemplates'].menu,
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private router: ActivatedRoute,
    private visibilityService: VisibilityService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private validateDocumentService: ValidateDocumentService,
    private commonUtilsService: CommonUtilsService,
    private documentActions: DocumentActionsService) { }
  @Input() concept: any = null;

  ngOnInit(): void {
    this.loader.openLoader('documentation-list-on-init')
    this.getTemplatesConcept();
  }

  getTemplatesConcept(){
      this.apiService.get('documentobjects/compliancetemplates/byfrontconceptid', this.conceptId)
          .then((data:any)=>{
              data.forEach((item:any) => {
                item.documentId = item.id;
                this.getDocumentMenu(item);
                this.templateConcept.listTemplatesBack.push(item);
              });
              this.templateConcept.listTemplates = data;
              this.visibilityService.extractTemplatesVisibility(this.templateConcept.listTemplatesBack);
              this.loader.closeLoader(['documentation-list-on-init']);
          },()=>{
              this.loader.closeError();
          });
  }

  getDocumentMenu(document:any){
      document.menu = this.templateConcept.menuDocumentTemplates;
  }

  newDocument(){
      if(this.commonUtilsService.isIE()){
          this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
      }else{
          this.modalService.newDocument(this.templateConcept.listTemplatesBack, this.concept, 'template-document', this.templateConcept.listTemplates)
            .subscribe((result:any)=>{
              if(result.result ==='ok'){
                if(this.duplicatedDocument(result.name)){
                  this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+result.name+"'"+this.translate.instant('documentation-list.is-already-added'), 'error:accept:warning');
                }else{
                  this.loader.openLoader('documentation-list-up-or-create')
                  this.createNewFile(result);
                }
              }
            })
      }
  }

  duplicatedDocument(name:any){
      for(let i = 0; i < this.templateConcept.listTemplates.length; i++){
          if(name === this.templateConcept.listTemplates[i].name){
              return true;
          }
      }
      return false;
  }

  createNewFile(result:any){
      this.apiService.add(result.url, {name: result.name})
          .then((data:any)=>{
              this.configureNewDocument(data, 'create');
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-document') +"'"+ result.name + "'", 'error:accept:warning');
          });
  }

  configureNewDocument(document:any, type:any){
      this.getDocumentMenu(document);
      document.source = this.translate.instant('templates-concept.custom-template');
      document.type = "Front";
      this.templateConcept.listTemplates.push(document);
      this.loader.closeLoader(['documentation-list-up-or-create']);
      let textType: any = type==='create'? 'created':'add';
      this.documentActions.doAction('open', document, 'template');
      this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+ document.name+"'"+this.translate.instant('documentation-list.'+textType+'-ok'), 'info:accept:check-round')
  }

  upDocument(){
      this.modalService.upDocument('template-document').subscribe((result:any)=>{
        if(result.result ==='ok'){
          if(this.validateDocumentService.correctDocumentFormat(result.name)){
            if(this.validateDocumentService.correctCharacters(result.name)){
                if(this.duplicatedDocument(result.name)){
                    this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+result.name+"'"+this.translate.instant('documentation-list.is-already-added'), 'error:accept:warning');
                }else{
                    this.loader.openLoader('documentation-list-up-or-create')
                    this.addFile(result);
                }
            }else{
                this.modalService.adviceModal(this.translate.instant('common.the-document') +"'"+ result.name +"'"+ this.translate.instant('documentation-list.invalid-name'), 'error:accept:warning');
            }
          }else{
              this.modalService.adviceModal(this.translate.instant('common.the-document') +"'"+ result.name +"'"+ this.translate.instant('documentation-list.format-not-accepted'), 'error:accept:warning');
          }
        }
      })
  }

  addFile(document:any){
      this.apiService.attachFile('documents/' + this.evaluationId + '/upload/ConceptId/'+this.conceptId, document.document)
          .then((data:any)=>{
              this.configureNewDocument(data, 'add');
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-upload-document') +"'"+ document.name + "'", 'error:accept:warning');
          });
  }

  launchAction(type:string, document:any){
      switch(type){
          case 'edit':
              this.documentActions.doAction('open', document, 'template');
              break;
          case 'delete':
              this.openQuestionModal(document);
              break;
          default:
              break;
      }
  }

  openQuestionModal(document:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-remove-document') + "'" + document.name + ' ?', 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('documentation-list-delete-document')
          this.deleteDocumentSelected(document);
        }
      })
  }

  deleteDocumentSelected(document:any){
      this.apiService.delete('documents/'+ this.evaluationId, document.documentId)
          .then((data:any)=>{
              this.templateConcept.listTemplates = this.commonUtilsService.deleteElement( document, this.templateConcept.listTemplates, 'documentId');
              this.loader.closeLoader(['documentation-list-delete-document']);
              this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+ document.name+"'"+this.translate.instant('documentation-list.remove-document'), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
              let errorString: string = '';
              if(errorData.description.exceptionMessage === "File is locked"){
                  errorString = this.translate.instant('common.the-document')+"'"+ document.name + "'"+ this.translate.instant('documentation-list.file-lock');
              }else if(errorData.description.exceptionMessage === "Associated to file"){
                  errorString = this.translate.instant('templates-concept.error-delete-template');
              }else{
                  errorString = this.translate.instant('documentation-list.error-remove-document') +"'"+ document.name + "'";
              }
              this.modalService.adviceModal(errorString, 'error:accept:warning');
          });
  }
}
