import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'box-queries',
  templateUrl: './box-queries.component.html'
})
export class BoxQueriesComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  boxQueries: any = {}
  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService) {
  }

  @Input() concept:any = {};
  @Output() launchAction = new EventEmitter();

  ngOnInit(){
      this.GetQueries();
  }

  GetQueries() {
    this.apiService.get('icam/conceptobjects/consultas/byusuarioconceptobjectId/' + this.concept.conceptObjectId)
    .then((data:any)=>{
            this.boxQueries.queries = data;
        }, (errorData:any)=>{}
    );
  }

  newQuery(){
    this.loader.openLoader('folder-open-concept');
    this.apiService.get('icam/concepts/id-of-namedkey/Consulta/evaluation/' + this.concept.evaluationId)
        .then((queriesConcept:any)=>{
            this.apiService.get('conceptobjects/objectscount', queriesConcept.conceptId)
            .then((titleCount:any)=>{
                let titleCounter:string = titleCount > 0? ' (' + titleCount + ')' : '';
                this.loader.closeLoader(['folder-open-concept']);
                this.modalService.renameModal({
                    title: this.translate.instant('rename-modal.name-concept-new'),
                    titleConcept: queriesConcept.conceptTitle + titleCounter,
                    type: 'concept',
                    conceptId: queriesConcept.conceptId,
                    icon: 'lf-icon-compliance',
                    rename: false
                }).subscribe((result:any)=>{
                    if(result.result === 'ok'){
                        this.CreateQuery(queriesConcept, result.name)
                    };
                });

        }, ()=>{
            this.loader.closeError();
        });
    }, ()=>{
        this.loader.closeError();
    });
  }

  editQuery(query:any) {
    if(this.skin ==='icam-red'){
        sessionStorage.getItem('icamUser')? this.SetIcamUsers(query) : sessionStorage.setItem('icamUser', JSON.stringify([this.SetNewQuery(query)]));
    }
    this.launchAction.emit({
        action: {
            type: 'goToQuery',
            item: query
        }
    });
  }

  showQueryTooltip(id:string) {
   return this.commonUtilsService.showTooltipHeight(id);
  }

  GetEvaluatedConcepts(conceptId:any) {
    this.apiService.get('conceptobjects/objectscount', conceptId)
        .then((data:any)=>{
        this.boxQueries.amountEvaluatedConcept = data;
        this.boxQueries.name = this.boxQueries.newItem.titleConcept;
        if (this.boxQueries.amountEvaluatedConcept && !this.boxQueries.newItem.rename) {
            this.boxQueries.name = this.boxQueries.name + ' ' + '(' + this.boxQueries.amountEvaluatedConcept + ')';
        }
    });
  }

  CreateQuery(queriesConcept:any, name:any) {
    this.loader.openLoader('folder-open-concept')
    this.apiService.add('conceptobjects/' + queriesConcept.conceptId, {
        title: name,
        initialValues: [{
            fieldId0: 46,
            floatValue: this.concept.conceptObjectId
        }]
    }).then((data:any)=>{
            data.folderId = queriesConcept.folderId;
            this.editQuery(data);
            this.loader.closeLoader(['folder-open-concept']);
        }, (errorData:any)=>{
            console.error(errorData);
            this.loader.closeError();
        }
    );
  }

  SetNewQuery(data:any){
    let newQuery = {
        from:{
            conceptId: String(this.route.snapshot.params['conceptId']),
            conceptObjectId: String(this.route.snapshot.params['conceptObjectId']),
            evaluationId: String(this.route.snapshot.params['evaluationId']),
            folderId: String(this.route.snapshot.params['folderId'])
        },
        to:{
            conceptId: String(data.conceptId),
            conceptObjectId: String(data.conceptObjectId),
            evaluationId: String(this.concept.evaluationId),
            folderId: String(data.folderId)
        }
    }
    return newQuery
  }

  SetIcamUsers(data:any){
      let icamUsers:any = sessionStorage.getItem('icamUser');
      let users = JSON.parse(icamUsers)
      users.push(this.SetNewQuery(data));
      sessionStorage.setItem('icamUser', JSON.stringify(users))
  }


}
