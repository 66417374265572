<div class="role-assignment-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-lead common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'new-evaluation.role-assignment'">Asignación de roles</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <form class="role-assignment-modal__body-form">
      <p class="common__title-block-modal"
          [afTranslate]="'role-assignment-modal.info-header'">_Selecciona un usuario y decide si quieres que desempeñe el rol sobre todas las organizaciones a las que tiene permiso de acceso o solo sobre una selección de ellas.</p>
      <div class="role-assignment-modal__block-fields">
          <field-type-contacts-simple
              [field]="roleAssignmentModal.userRoleField"
              [valueId]="configuration.productId"
              (sendContacts)="receiveContacts($event.contacts)">
          </field-type-contacts-simple>
          <field-type-list-multiple [field]="roleAssignmentModal.organizationsField"
                      [listValues]="roleAssignmentModal.clientsList"
                      [preSelectItem]="roleAssignmentModal.clientsSelected"
                      [fieldToFind]="'text'"
                      [orderBy]="'numOrder'"
                      (itemSelected)="clientSelected($event)">
          </field-type-list-multiple>
      </div>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'">Cancelar</button>
          <button type="submit"
              class="common__button common__button--active"
              (click)="validateFields()"
              [afTranslate]="'add-assignment.assign'">Asignar</button>
      </div>
  </form>
</div>
