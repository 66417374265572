<div class="field-item"
  [ngHide]="fieldItem.fieldInfo.fieldNotInForce && !fieldItem.hasValue"
  [ngSwitch]="fieldItem.fieldInfo.fieldTypeFrontId">
  <div *ngSwitchCase="'text'">
      <field-type-text-simple *ngIf="!fieldItem.fieldInfo.multiline"
          [field]="fieldItem.fieldInfo"
          [preSelectItem]="fieldItem.preDefined"
          (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
          (showFieldReference)="showFieldReferences()"
          (openRelatedInfo)="openRelatedInfo($event)">
      </field-type-text-simple>
      <field-type-text-multiline *ngIf="fieldItem.fieldInfo.multiline"
          [field]="fieldItem.fieldInfo"
          [concept]="concept"
          [preSelectItem]="fieldItem.preDefined"
          (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
          (showFieldReference)="showFieldReferences()"
          (saveVoiceRecord)="saveVoiceRecord($event)"
          (openRelatedInfo)="openRelatedInfo($event)">
      </field-type-text-multiline>
  </div>
  <!-- list | listp | l-fo  normal-->
  <div *ngSwitchCase="'list'">
      <div *ngIf="!(isLanding && fieldItem.fieldInfo.poll && fieldItem.fieldInfo.sequentialView)">
        <field-type-list-simple *ngIf="!fieldItem.fieldInfo.multiple && !fieldItem.fieldInfo.numericalScale && !fieldItem.fieldInfo.isHighlight && !fieldItem.fieldInfo.isTypeButton"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </field-type-list-simple>
        <field-type-list-multiple *ngIf="fieldItem.fieldInfo.multiple && !fieldItem.fieldInfo.numericalScale && !fieldItem.fieldInfo.isHighlight && !fieldItem.fieldInfo.isTypeButton"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </field-type-list-multiple>
        <field-type-list-numerical *ngIf="fieldItem.fieldInfo.numericalScale"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </field-type-list-numerical>
        <field-type-list-button *ngIf="fieldItem.fieldInfo.isTypeButton"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </field-type-list-button>
        <field-type-list-highlight *ngIf="fieldItem.fieldInfo.isHighlight"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </field-type-list-highlight>
      </div>
      <div *ngIf="isLanding && fieldItem.fieldInfo.poll && fieldItem.fieldInfo.sequentialView">
        <sequential-list-simple *ngIf="!fieldItem.fieldInfo.multiple && !fieldItem.fieldInfo.numericalScale && !fieldItem.fieldInfo.isHighlight && !fieldItem.fieldInfo.isTypeButton"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </sequential-list-simple>
        <sequential-list-multiple *ngIf="fieldItem.fieldInfo.multiple && !fieldItem.fieldInfo.numericalScale && !fieldItem.fieldInfo.isHighlight && !fieldItem.fieldInfo.isTypeButton"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </sequential-list-multiple>
        <sequential-list-numerical *ngIf="fieldItem.fieldInfo.numericalScale"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </sequential-list-numerical>
        <sequential-list-highlight *ngIf="fieldItem.fieldInfo.isHighlight"
            [field]="fieldItem.fieldInfo"
            [preSelectItem]="fieldItem.preDefined"
            [listValues]="fieldItem.fieldInfo.listValues"
            (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReference)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)"
            [public]="public">
        </sequential-list-highlight>
      </div>
  </div>

  <!-- list | listp | l-fo extendida-->
  <field-type-list-extended *ngSwitchCase="'list-extended'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [listValues]="fieldItem.fieldInfo.listValues"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-list-extended>
  <!-- num | money -->
  <field-type-number *ngSwitchCase="'num'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [concept]="concept"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      [decimals]="fieldItem.fieldInfo.decimals"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-number>
  <field-type-date *ngSwitchCase="'date'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [concept]="concept"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (createAlert)="createAlert($event)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)"
      [public]="public">
  </field-type-date>
  <field-type-file *ngSwitchCase="'file'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [concept]="concept"
      [conceptListStates]="concept?.listStates"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-file>
  <field-type-other *ngSwitchCase="'l-co'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [concept]="concept"
      [listValues]="fieldItem.fieldInfo.listValues"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (itemSelectedAdditional)="itemSelectedAdditional($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-other>
  <field-type-other *ngSwitchCase="'l-sg'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      [concept]="concept"
      [listValues]="fieldItem.fieldInfo.listValues"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (itemSelectedAdditional)="itemSelectedAdditional($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-other>
  <field-type-email *ngSwitchCase="'emai'"
      [field]="fieldItem.fieldInfo"
      [concept]="concept"
      [preSelectItem]="fieldItem.preDefined"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-email>
  <field-type-link *ngSwitchCase="'link'"
      [field]="fieldItem.fieldInfo"
      [concept]="concept"
      [preSelectItem]="fieldItem.preDefined"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-link>
  <field-type-time *ngSwitchCase="'time'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (showFieldReference)="showFieldReferences()"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-time>
  <!-- formu 1 formp -->
  <div *ngSwitchCase="'formu'">
    <field-type-formu *ngIf="!(fieldItem.fieldInfo.fieldTypeId === 'formp' && fieldItem.fieldInfo.isHtml)"
        [field]="fieldItem.fieldInfo"
        [concept]="concept"
        [evaluatedConcepts]="evaluatedConcepts"
        [evaluatedFields]="fieldItem.evaluatedFields"
        (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
        (openRelatedInfo)="openRelatedInfo($event)">
    </field-type-formu>
    <field-type-form-rich *ngIf="fieldItem.fieldInfo.fieldTypeId === 'formp' && fieldItem.fieldInfo.isHtml"
        [field]="fieldItem.fieldInfo"
        [concept]="concept"
        [evaluatedConcepts]="evaluatedConcepts"
        [evaluatedFields]="fieldItem.evaluatedFields"
        (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
        (openRelatedInfo)="openRelatedInfo($event)">
    </field-type-form-rich>
  </div>

  <field-type-txchk *ngSwitchCase="'txchk'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-txchk>
  <!-- templ-->
  <field-type-template *ngSwitchCase="'templ'"
      [field]="fieldItem.fieldInfo"
      [concept]="concept"
      [preSelectItem]="fieldItem.preDefined"
      [public]="public"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (changeConcept)="changeConcept($event)">
  </field-type-template>
  <!-- pepl-->
  <field-type-consult-lists *ngSwitchCase="'pepl'"
      [field]="fieldItem.fieldInfo"
      [fieldContainer]="fieldContainer"
      [concept]="concept">
  </field-type-consult-lists>
  <!-- alert -->
  <field-type-alert *ngSwitchCase="'alert'"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]= "fieldItem.preDefined"
      (itemSelected)= "itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      [concept]="concept"
      (createAlert)="createAlert($event)">
  </field-type-alert>

  <!-- report -->
  <field-type-report *ngSwitchCase="'rpt'"
      [field]="fieldItem.fieldInfo"
      (launchActionEmit)="launchActionOn($event)">
  </field-type-report>
  <field-type-task *ngSwitchCase="'task'"
      [concept]="concept"
      [field]="fieldItem.fieldInfo"
      [preSelectItem]="fieldItem.preDefined"
      (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)">
  </field-type-task>
  <field-type-rich-text *ngSwitchCase="'rich'"
      [field]="fieldItem.fieldInfo"
      [concept]="concept"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-rich-text>
  <field-type-rich-text-editable *ngSwitchCase="'rich-editable'"
      [field]="fieldItem.fieldInfo"
      [concept]="concept"
      (changeInField)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
      (saveVoiceRecord)="saveVoiceRecord($event)"
      (openRelatedInfo)="openRelatedInfo($event)">
  </field-type-rich-text-editable>
  <div *ngIf="!isLanding">
      <!-- bool | boolp -->
      <field-type-bool *ngSwitchCase="'bool'"
        [field]="fieldItem.fieldInfo"
        [preSelectItem]="fieldItem.preDefined"
        (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
        (showFieldReferences)="showFieldReferences()"
        (openRelatedInfo)="openRelatedInfo($event)">
    </field-type-bool>
    <div *ngSwitchCase="'user'">
      <!-- user | user-multiple-->
      <field-type-contacts-multiple *ngIf="fieldItem.fieldInfo.multiple"
          [field]="fieldItem.fieldInfo"
          [concept]="concept"
          (sendContacts)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
          (showFieldReferences)="showFieldReferences()"
          (openRelatedInfo)="openRelatedInfo($event)">
      </field-type-contacts-multiple>
      <field-type-contacts-simple *ngIf="!fieldItem.fieldInfo.multiple"
            [field]="fieldItem.fieldInfo"
            [concept]="concept"
            (sendContacts)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReferences)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)">
        </field-type-contacts-simple>
    </div>
  </div>
  <div *ngIf="isLanding">
      <field-type-bool-custom *ngSwitchCase="'bool'"
          [field]="fieldItem.fieldInfo"
          [preSelectItem]="fieldItem.preDefined"
          (itemSelected)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
          (showFieldReferences)="showFieldReferences()"
          (openRelatedInfo)="openRelatedInfo($event)">
      </field-type-bool-custom>
      <div *ngSwitchCase="'user'">
        <!-- user | user-multiple-->
        <field-type-contacts-multiple-custom *ngIf="fieldItem.fieldInfo.multiple"
            [field]="fieldItem.fieldInfo"
            [concept]="concept"
            (sendContacts)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
            (showFieldReferences)="showFieldReferences()"
            (openRelatedInfo)="openRelatedInfo($event)">
        </field-type-contacts-multiple-custom>
        <field-type-contacts-simple-custom *ngIf="!fieldItem.fieldInfo.multiple"
              [field]="fieldItem.fieldInfo"
              [concept]="concept"
              (sendContacts)="itemSelected($event, fieldItem.fieldInfo.fieldTypeId)"
              (showFieldReferences)="showFieldReferences()"
              (openRelatedInfo)="openRelatedInfo($event)">
          </field-type-contacts-simple-custom>
      </div>
  </div>
  <field-references *ngIf="fieldItem.showReferences && fieldItem.fieldInfo.evaluatedValue.conceptDataId !== null && fieldItem.fieldInfo.evaluatedValue.hasReferences"
      [field]="fieldItem.fieldInfo">
  </field-references>
</div>
