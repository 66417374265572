import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from "lodash";
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'manage-confidential-modal',
  templateUrl: './manage-confidential-modal.component.html'
})
export class ManageConfidentialModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  currentWindow = this.route.snapshot.data['componentName'];
  confidentialList: any = [];
  responsibleUser: any;
  manageConfidentialModal:any ={
    showDeleteConfidential: false,
    showText: true,
    addContacts: false,
    confidentialOfUserConnected: false,
    description:'',
    showGroup: '',
    confidentContacts: {
      typeConfiguration: 'confident',
      fieldType: 'multiple',
      addContacts: false,
      required: false,
      typeCall: 'users',
      typeCallId: this.currentWindow==='dashboardPanel'?'productId':null,
      selectContacts: [],
      label: this.translate.instant('manage-confidential-modal.auth-users')
    },
    responsibleConfidential: {
      noEditable: true,
      label: this.translate.instant('common.responsible')
    },
    evaluationId:null,
    type: 'confident',
    check: false
  }

  constructor(private context: ContextFactory,
    private activeModal:NgbActiveModal,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory) { }
  @Input() itemToConfidential:any = null

  ngOnInit(): void {
    this.configureConfidentialListInit();
    this.manageConfidentialModal.confidentContacts.selectContacts = this.confidentialList;
    this.manageConfidentialModal.confidentContacts.confidential = this.itemToConfidential.confidential;
    this.manageConfidentialModal.confidentContacts.preSelectedResponsible = this.responsibleUser;
    this.manageConfidentialModal.showDeleteConfidential = this.itemToConfidential.confidential;
    this.manageConfidentialModal.evaluationId = this.itemToConfidential.evaluationId?this.itemToConfidential.evaluationId:this.route.snapshot.params['evaluationId'];
    this.manageConfidentialModal.valueIdContacts = this.currentWindow==='dashboardPanel'?null:this.manageConfidentialModal.evaluationId;
  }

  configureConfidentialListInit(){
    this.itemToConfidential.confidentialList = this.itemToConfidential.confidentialList?this.itemToConfidential.confidentialList:[];
    if(this.itemToConfidential.confidential){
        for(let i = 0; i < this.itemToConfidential.confidentialList.length; i++){
            let user = this.itemToConfidential.confidentialList[i];
            if(user.type && user.type.toUpperCase() === 'U' && !user.userId){
                user.userId = user.id;
            }else if(user.type && user.type.toUpperCase() === 'R' && !user.productRoleId){
                user.productRoleId = user.id;
            }
            if(user.responsible){
                this.responsibleUser = user;
            }else{
                this.confidentialList.push(user);
            }
        }
    }else{
        this.responsibleUser = cloneDeep(this.context.user);
        this.responsibleUser.responsible = true;
    }
  }

  receiveContacts(e:any){
    this.manageConfidentialModal.confidentContacts.selectContacts = e.contacts;
    this.manageConfidentialModal.confidentContacts.error = false;
  }

  contactsClosed() {
    this.manageConfidentialModal.showText = !this.manageConfidentialModal.showText;
  }

  selectCheck(){
    this.manageConfidentialModal.check = !this.manageConfidentialModal.check;
  }

  handleAccept() {
    let confidential = 1;
    let listaIds:any = [];
    if(this.manageConfidentialModal.check  ){
      this.manageConfidentialModal.confidentContacts.selectContacts = [];
        confidential = 0;
    }else{
      this.manageConfidentialModal.confidentContacts.selectContacts.forEach((item:any) => {
        listaIds.push({
          userId: item.userId,
          productRoleId: item.productRoleId

        })
        if(item.userId && item.userId === this.context.user.userId){
            this.manageConfidentialModal.confidentialOfUserConnected = true;
        }
      });
      this.itemToConfidential.confidentialList = this.manageConfidentialModal.confidentContacts.selectContacts;
      this.addResponsible();
    }

    this.activeModal.close({
      result: 'ok',
      //share: vm.confidentialList,
      confidentialOfUserConnected: this.manageConfidentialModal.confidentialOfUserConnected,
      share: listaIds,
      confidential: confidential
    })
  }

  addResponsible(){
    this.itemToConfidential.confidentialList.splice(0,0,this.manageConfidentialModal.confidentContacts.preSelectedResponsible);
    if(this.manageConfidentialModal.confidentContacts.preSelectedResponsible.userId === this.context.user.userId){
        this.manageConfidentialModal.confidentialOfUserConnected = true;
    }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
