<div class="field-assignments-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-arrow-exchange common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="'field-assignments.report-field-assignments'"></span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="field-assignments-modal__block-body">
      <div class="field-assignments-modal__form-block">
          <div class="field-assignments-modal__custom-field-name">
              <i class="lf-icon-localization field-assignments-modal__field-name-icon"></i>
              <span class="field-assignments-modal__custom-field-name-text">{{data.customFieldName}}</span>
          </div>
          <div class="field-assignments-modal__assignments-list">
              <div class="field-assignments-modal__assignment" *ngFor="let field of data.fieldAssignments">
                  <div class="field-assignments-modal__assignment-title">
                      <i class="lf-icon-text-field field-assignments-modal__assignment-title-icon"></i>
                      <span>{{field.fieldTitle}}</span>
                  </div>
                  <div class="field-assignments-modal__assignment-path">
                      <i class="lf-icon-map-marker field-assignments-modal__assignment-path-icon"></i>
                      <span>{{field.conceptPath}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="submit" class="common__button common__button--active"
                  (click)="handleAccept()"
                  [afTranslate]="'common.ok'"></button>
      </div>
  </div>
</div>
