<div class="upload-document">
  <!-- ng-class="{'upload-document--task': type === 'addNewDocumentsFromTasks', 'upload-document--process': type === 'addNewDocumentsFromProcessModal' || type === 'addNewDocuments'}"> -->
  <!-- <div class="row">
      <div class="col-md-12"> -->
          <div class="common__modal-header-block"
            [ngShow]="type!=='evaluationShare'">
            <i class="lf-icon-up-file common__icon-header" ></i>
              <span class="common__text-header"
                  [afTranslate]="'up-document.file'"></span>
              <i class="lf-icon-close common__icon-close-modal"
                  [ngShow]="uploadDocuments.canClose || uploadDocuments.viewOption === 'upload'"
                  (click)="handleDismiss()"></i>
          </div>
          <div [ngShow]="uploadDocuments.viewOption === 'upload'"
              class="upload-document__modal-content-block"
              [ngClass]="{'upload-document__modal-content-block--with-list': uploadDocuments.showProcess}">
              <field-type-list-simple class="upload-document__block-progress"
                  [ngShow]="uploadDocuments.showProcess && type !== 'dropFiles' && !uploadDocuments.updating"
                  [field]="uploadDocuments.processSteps"
                  [listValues]="listSelect"
                  [preSelectItem]="uploadDocuments.stateId"
                  (itemSelected)="itemSelectedList($event, 'stateId')">
              </field-type-list-simple>
              <div class="upload-document__upload-box"
                  [ngShow]="type !== 'dropFiles' && !uploadDocuments.updating"
                  dropZone
                  (fileDropped)="dropNewDocument($event)">
                  <div id="upload-document-dropzone">
                      <i class="lf-icon-drag-drop upload-document__drop-icon"></i>
                      <p class="upload-document__dropzone-text"
                          [afTranslate]="'documentation-list.dropzone-info-upload-modal'">Arrastra aquí tus documentos</p>

                      <button class="upload-document__drop-option-menu"
                          (click)="openFileSearch()">
                          <i class="lf-icon-search upload-document__drop-option-menu-icon"></i>
                          <span [afTranslate]="'documentation-list.explore-pc'"></span>
                      </button>
                      <input class="upload-document__file-upload" id="fileDropRef"
                          type="file"
                          multiple
                          (change)="loadFiles($event)">
                  </div>
              </div>
              <div class="upload-document__documentation-block"
                  [ngClass]="{'upload-document__documentation-block--reduce': type !== 'dropFiles' && !uploadDocuments.updating}">
                  <div class="upload-document__documentation-header">
                      <p class="upload-document__documentation-title"
                          [afTranslate]="'up-document.select-document'"></p>
                      <i class="upload-document__documentation-icon">{{uploadDocuments.upDocuments.length}}</i>
                  </div>
                  <ul class="upload-document__documentation-list">
                      <li *ngFor="let document of uploadDocuments.upDocuments; let index = index"
                          class="upload-document__documentation-item"
                              [ngClass]="{'upload-document__documentation-item--error upload-document__documentation-item--error': document.status==='error' || document.status==='validate-error',
                                  'upload-document__documentation-item--no-border': document.progress > 0}">
                          <i [class]="(document.source | extensionDocument) + ' upload-document__documentation-icon-type'"></i>
                          <dl class="upload-document__documentation-document-info">
                              <dt class="upload-document__documentation-document-name"
                                [ngClass]="{'upload-document__documentation-document-name--error':document.status ==='error' || document.status==='validate-error'}">
                                {{document.name}}
                              </dt>
                              <dd class="upload-document__documentation-document-size"
                                [ngClass]="{'upload-document__documentation-document-size--error':document.status ==='error' || document.status==='validate-error'}">
                                {{document.size | convertBytes}}
                              </dd>
                          </dl>
                          <div class="upload-document__documentation-menu"
                              [ngHide]="document.status==='complete'">
                              <button class="lf-icon-kebab-menu common__icon-ellipsis upload-document__documentation-menu-options-button upload-document__documentation-menu-options-button--more">
                              </button>
                              <div class="upload-document__documentation-menu-options"
                                  [ngShow]="document.status==='error' || !uploadDocuments.updating"
                                  [ngClass]="{'upload-document__documentation-menu-options--error': document.status==='error'}">
                                  <button class="lf-icon-rotate upload-document__documentation-menu-options-button"
                                      [ngShow]="document.status==='error'"
                                      (click)="addDocument(document, true)">
                                  </button>
                                  <button class="lf-icon-rename upload-document__documentation-menu-options-button"
                                      [ngClass]="document.status==='error'?'upload-document__documentation-menu-options-button--rename-error':'upload-document__documentation-menu-options-button--rename'"
                                      (click)="renameDocument(document)">
                                  </button>
                                  <button [ngShow]="document.status==='error' || !uploadDocuments.updating"
                                      class="lf-icon-trash upload-document__documentation-menu-options-button upload-document__documentation-menu-options-button--delete"
                                      (click)="deleteDocument(index)">
                                  </button>
                              </div>
                          </div>
                          <p class="upload-document__progress-upload-text upload-document__progress-upload-text--error"
                              [ngShow]="document.status==='validate-error'">
                              {{document.statusText}}
                          </p>
                          <div class="upload-document__progress-upload-block"
                              [ngStyle]="{'width': document.progress+'%'}"
                              [ngClass]="{'upload-document__progress-upload-block--complete': document.status==='complete',
                                'upload-document__progress-upload-block--upload-server': document.status==='upload',
                                'upload-document__progress-upload-block--error':document.status==='error' || document.status==='validate-error'}">
                              <div class="upload-document__progress-upload-line"
                                  [ngClass]="{'upload-document__progress-upload-line--complete': document.status==='complete',
                                  'upload-document__progress-upload-line--upload-server':document.status==='upload',
                                  'upload-document__progress-upload-line--error':document.status==='error' || document.status==='validate-error'}"
                                  [ngShow]="document.progress > 0"></div>
                              <p class="upload-document__progress-upload-text"
                                  [ngClass]="{'upload-document__progress-upload-text--error':document.status==='error' || document.status==='validate-error'}">
                                  <span [ngHide]="document.status==='error' || (document.progress=== 100 && document.status!== 'complete')">{{document.progress}}%</span>
                                  <span [ngShow]="document.status==='error'">{{document.statusText}}</span>
                              </p>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="upload-document__block-textarea"
                  [ngShow]="type === 'addNewDocumentsFromTasks' && !uploadDocuments.updating">
                  <div class="upload-document__block-textarea-head">
                      <span [afTranslate]="'attach-document.add-comment'">Añadir comentario</span>
                  </div>
                  <i class="lf-icon-expand common__input-icon common__input-icon-active"
                    (click)="openTextareaModal()"></i>
                  <textarea class="upload-document__add-comment"
                      [(ngModel)]="uploadDocuments.comments">
                  </textarea>
              </div>
          </div>

          <div class="common__buttons-block common__buttons-block--modal"
              [ngShow]="type!=='evaluationShare'">
              <button type="reset"
                  [ngShow]="uploadDocuments.complete"
                  class="common__button common__button--active"
                  (click)="handleAccept()"
                  [afTranslate]="'common.ok'">Aceptar</button>
              <button type="reset"
                  [ngHide]="uploadDocuments.updating"
                  class="common__button common__button--clean"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.discard'">Descartar</button>
              <button type="submit"
                  [ngHide]="uploadDocuments.updating"
                  class="common__button common__button--active"
                  [disabled]="!uploadDocuments.validateDocument"
                  (click)="uploadFiles()"
                  [afTranslate]="'common.add'">Añadir</button>
          </div>

</div>
