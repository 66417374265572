import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'exit-login',
  templateUrl: './exit-login.component.html'
})
export class ExitLoginComponent implements OnInit {
  custom: any = this.customLanding.getCustom();
  isLanding:any = this.customLanding.isLanding();
  settingsString: any = sessionStorage.getItem('exit-landing');
  settings: any = JSON.parse(this.settingsString);
  userEmailSettings: any = this.settings.userEmail;
  dataHeader: any = this.settings.dataHeader;
  exitLogin:any = {
    conceptObjectId: this.settings.conceptObjectId,
    footerConceptPublic: this.settings.footerConceptPublic,
    taskId: this.settings.taskId,
    show: this.settings.exitType,
    userEmail: false,
    public: false,
    // ngSwitch: false,
    emailList: [],
    meeting: {
        icon: '',
        title: '',
        message: '',
        footer:''
    }
  }
  constructor(private customLanding: CustomLandingFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private router: Router,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private exportService: ExportServiceService,
    private downloadService: DownloadService,
    private loader: LoaderFactory) { }

  ngOnInit(): void {
    sessionStorage.removeItem('exit-landing');
    let that: any = this.exitLogin
    if(this.exitLogin.taskId && this.exitLogin.taskId !== null){
      this.getTaskInfo();
    }
    if(this.exitLogin.conceptObjectId){
      this.getConceptInfo();
    }

    if(this.exitLogin.show === 'taskConcept-shared'){
        this.exitLogin.show = 'concept-shared';
        this.exitLogin.conceptTask = true;
    }
    that.prymaryText = this.translate.instant('exit-login.'+this.exitLogin.show);
    that.shareInfoText = this.translate.instant('exit-login.share-info');
    that.sharebottomText = this.translate.instant('exit-login.share-info-bottom');
    if(this.userEmailSettings !== null){
        that.userEmail = true;
        that.shareInfoText = this.translate.instant('exit-login.share-info-user');
        that.sharebottomText = this.translate.instant('exit-login.share-info-bottom-user');
    }
    this.configureFields();
  }


  configureFields(){
      let that: any = this.exitLogin;
      let meeting: any = that.meeting;
      switch(this.exitLogin.show){
          case 'free':
              that.icon ="lf-icon-task-validate";
              break;
          case 'dismiss-meeting':
              meeting.icon = this.isLanding? 'lf-icon-calendar-cross exit-login__custom-result-icon' : 'lf-icon-calendar-cross exit-login__calendar';
              break
          case 'confirm-meeting':
              meeting.icon = this.isLanding?'lf-icon-calendar-check exit-login__custom-result-icon' : 'lf-icon-calendar-check exit-login__calendar';
              break;
          case 'save-task':
              meeting.message = this.translate.instant('exit-login.thanks'); //'¡Gracias por la colaboración!'
              meeting.icon = this.isLanding? 'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__icon-check';
              break;
          case 'concept-shared':
              meeting.message = this.translate.instant('exit-login.thanks'); //'¡Gracias por la colaboración!'
              meeting.icon = this.isLanding? 'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__icon-check';
              that.prymaryText = that.conceptTask? this.translate.instant('exit-login.concept-shared') : this.translate.instant('exit-login.save-task');
              break;
          case 'concept-public':
              meeting.icon = this.isLanding?'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__calendar';
              that.prymaryText = this.translate.instant('exit-login.data-sent');
              that.secondaryText = this.translate.instant('exit-login.follow-link');
              if (this.userEmailSettings === null || !this.userEmailSettings) {
                  meeting.message = this.exitLogin.conceptObjectId;
                  that.secondaryText = this.translate.instant('exit-login.follow-link-2');
              }else{
                  meeting.message ='';
              }
              that.public = true;
              break;
          case 'concept-public-locked':
              meeting.icon = this.isLanding? 'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__calendar';
              that.secondaryText = this.translate.instant('exit-login.follow-link');
              if (this.userEmailSettings === null || !this.userEmailSettings) {
                  meeting.message = this.exitLogin.conceptObjectId;
                  that.secondaryText = this.translate.instant('exit-login.follow-link-2');
              }else{
                  meeting.message ='';
              }
              that.public = true;
              break;
          case 'poll-public':
              that.public = true;
              meeting.icon = this.isLanding? 'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__icon-check';
              that.prymaryText = this.translate.instant('exit-login.save-task');
              meeting.message = this.translate.instant('exit-login.thanks');
              break;
          case 'poll-shared':
              that.public = false;
              meeting.icon = this.isLanding?'lf-icon-check-round exit-login__custom-result-icon' : 'lf-icon-check-round exit-login__icon-check';
              that.prymaryText = this.translate.instant('exit-login.save-task');
              meeting.message = this.translate.instant('exit-login.thanks');
              break;
          case 'poll-expired-public':
          case 'poll-expired-shared':
              that.public = this.exitLogin.show !== 'poll-expired-shared';
              that.prymaryText= this.translate.instant('exit-login.poll-expiration-primary');
              that.secondaryText =this.translate.instant('exit-login.poll-expiration-secondary');
              meeting.icon = this.isLanding?'lf-icon-calendar-cross exit-login__custom-result-icon' : 'lf-icon-calendar-cross exit-login__icon-check';
              meeting.expirationDate = this.dataHeader?.pollExpirationDate;
              meeting.beginDate = this.dataHeader?.pollBeginDate;
              meeting.message = 'Titulo: ' + this.dataHeader?.title;
              that.logo = this.dataHeader?.logo? this.dataHeader?.logo : "https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-centinela-248x60.png";
              if(this.exitLogin.show !== 'poll-expired-shared'){
                that.dataHeader = {
                  title:  this.dataHeader?.title,
                  logo: this.dataHeader?.logo,
                  color: this.dataHeader?.color
                }
              }

              break;
          default:
              this.router.navigate(['login']);
              break;
      }
  }

  getTaskInfo(){
      this.apiService.get('tasks', this.exitLogin.taskId)
          .then((data:any)=>{
              if(this.exitLogin.show==='dismiss-meeting' || this.exitLogin.show==='confirm-meeting'){
                  this.exitLogin.meeting.footer = this.getConfigureFooter(data);
              }
          }, ()=>{})
  }

  getConfigureFooter(data:any){
      return {
          title: data.title,
          date: data.expirationDate,
          from: data.beginHour.substring(0, data.beginHour.lastIndexOf(':')),
          to: data.endHour.substring(0, data.endHour.lastIndexOf(':')),
          location: data.location
      }
  }

  getConceptInfo(){
      this.apiService.get('conceptobjects', this.exitLogin.conceptObjectId )
          .then((data:any)=> {
              this.exitLogin.publicUrl = data.shareUrl;
              this.configureShared(data);
              this.exitLogin.logo = this.exitLogin.show === 'concept-public' || this.exitLogin.show === 'poll-public' || this.exitLogin.show === 'concept-public-locked'? this.getLogoClient(data) : "https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-centinela-248x60.png";

          }, (errorData:any)=>{
          });
  }

  configureShared(concept:any){
      this.exitLogin.dataHeader = {
          productName: concept.productName,
          client: concept.clientName,
          evaluationName: concept.evaluationName,
          breadcrumbs: concept.breadCrumb,
          title: concept.conceptTitle,
          logo: concept.logo
      };
      if(this.exitLogin.show === 'poll-expired-shared' || this.exitLogin.show === 'poll-expired-public'){
          this.exitLogin.meeting.message = 'Titulo: ' + this.exitLogin.dataHeader.title;
          this.exitLogin.meeting.expirationDate = concept.pollExpirationDate;
          this.exitLogin.meeting.beginDate = concept.pollBeginDate;
      }
      if(this.exitLogin.footerConceptPublic?.color){
          this.exitLogin.dataHeader.color = this.exitLogin.footerConceptPublic.color;
      }
  }

  getLogoClient(concept:any){
    let logo:any = concept.logo
      if(!concept.logo){
          logo = 'https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-centinela-248x60.png';
      }else if(concept.logo.indexOf('data:image') === -1){
          logo = "data:image/png;base64," + concept.logo;
      }
      return logo;
  }

  copyText(tooltip:NgbTooltip) {
       tooltip.open();
       navigator.clipboard.writeText(this.exitLogin.publicUrl)
       .then(() => {
           setTimeout(() => {
             tooltip.close();
           },1000)
         }, () => {});
  }

  insertEmail(e:any){
    let key: any = e.keyCode;
    let that: any = this.exitLogin
      if((key === 188 && this.commonUtilsService.validateMail(that.emailRaw.substring(0, that.emailRaw.length - 1))) || ((key === 32 || key === 13) && this.commonUtilsService.validateMail(that.emailRaw))){
          that.emailList.push({text:key === 188?that.emailRaw.substring(0, that.emailRaw.length - 1):that.emailRaw, icon:'lf-icon-close-round'});
          that.emailRaw = '';
      }
  }

  backToMessage(){
      this.exitLogin.emailsList = '';
      this.exitLogin.showShareBlock = false;
      this.exitLogin.emailList = [];
  }

  sendShare(){
      let arrayContacts = [];
      this.exitLogin.emailList.forEach((email:any) => {
        arrayContacts.push(email.text);
      });

      if(this.commonUtilsService.validateMail(this.exitLogin.emailRaw)){
          arrayContacts.push(this.exitLogin.emailRaw);
      }
      this.apiService.add('conceptobjects/'+ this.exitLogin.conceptObjectId +'/sharepublic', arrayContacts)
          .then((data:any)=>{
              this.exitLogin.prymaryText = this.translate.instant('exit-login.send-share-confirm');
              this.exitLogin.secondaryText = '';
              this.exitLogin.emailsList = '';
              this.exitLogin.showShareBlock = false;
              this.exitLogin.emailList = [];
          }, (errorData:any)=>{})
  }

  download(){
      this.loader.openLoader('export-file');
      let bodyParams = {customizations: { thankYouPagePdf: true }};
      this.apiService.add('notifications/preview/34/conceptobject/'+ this.exitLogin.conceptObjectId, bodyParams)
        .then((data:any)=>{
          let config = {
              contentHTML: data,
              contentHTMLConfigurated: true,
              useOldVersion: true,
              browserWidth: 800,
              nameDocument: this.translate.instant('common.tracking')+'_'+this.exitLogin.dataHeader.title+'_'+ this.exitLogin.conceptObjectId,//#Asunto#Referencia,
              successCallback: (uploadResponse:any, blob:any) => {
                  this.downloadService.triggerDownload(blob, uploadResponse.fileName);
              }
          }
          this.exportService.toPdf(config);
        }, (errorData:any)=>{})
  }
  }
