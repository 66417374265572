<div class="generate-document">
    <div class="common__modal-header-block">
        <i class="lf-icon-textbooks common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'generate-document.export-data'"> Exportar datos a plantilla</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <form class="generate-document__template-select">
        <p class="generate-document__text">
            <span>{{generateDocument.textInfoTemplate}}</span>
        </p>
        <div>
            <field-type-text-simple [field]="generateDocument.documentName"
                [preSelectItem]="predefinedTemplate.predefinedName"
                (itemSelected)="itemSelected($event, 'name')">
            </field-type-text-simple>
        </div>
        <span class="rename-modal__error-name"
            [ngShow]="generateDocument.errorDocument">
            {{generateDocument.errorText}}</span>
    </form>

    <div class="common__buttons-block common__buttons-block--modal">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active common__button--separating-margin"
            (click)="handleAccept()"
            [afTranslate]="'common.export'">Exportar</button>
    </div>
</div>
