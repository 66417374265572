<div class="edition-comparator-form__block-form"
    [id]="'edition-comparator-form__block-form-'+editionComparatorForm.id">
    <div class="edition-comparator-form__block-group"
        [ngClass]="{'edition-comparator-form__hidden-block': container.visibilityHidden}"
        *ngFor="let container of editionComparatorForm.historyDataViewer.fieldContainers"
        [ngShow]="!container.hiddenContainer">
        <div class="edition-comparator-form__block-inner-group">
            <div class="edition-comparator-form__block-diff-forms"
                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': container.newBlock && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                    'edition-comparator-form__block-diff-forms--show-right-title': container.newBlock && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
            <div class="edition-comparator-form__block-header-group">
                <div class="edition-comparator-form__block-diff-forms"
                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !container.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                        'edition-comparator-form__block-diff-forms--show-right-title': !container.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                <span class="edition-comparator-form__text-header-group">
                    <span class="edition-comparator-form__search-text"
                        [ngClass]="{'edition-comparator-form__text-diff': !container.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{container.name}}</span>
                </span>
            </div>
            <div class="edition-comparator-form__block-form-group"
                [ngShow]="!container.hiddenView">
                <div class="edition-comparator-form__block-fields-group"
                    [ngClass]="{'edition-comparator-form__block-fields-group--big-padding': (field.fieldTypeFrontId === 'alert' || field.fieldTypeFrontId === 'task' || field.fieldTypeFrontId === 'l-co') && !last,
                            'edition-comparator-form__hidden-block': field.visibilityHidden}"
                    [id]="'block-field-'+field.iD_Version0+'-'+editionComparatorForm.historyDataViewer.comparatorPosition"
                    *ngFor="let field of container.fields; let last = last"
                    [ngShow]="!field.hiddenContainer">
                    <div class="edition-comparator-form__block-diff-forms"
                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                            'edition-comparator-form__block-diff-forms--show-right': !field.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                    <div class="edition-comparator-form__block-cell-label"
                        [ngClass]="{'edition-comparator-form__block-cell-label--padding-0': field.fieldTypeFrontId === 'rich'}">
                        <span class="edition-comparator-form__text-label">
                            <span class="edition-comparator-form__search-text"
                                [ngClass]="{'edition-comparator-form__text-diff': !field.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{field.label}}</span>
                        </span>
                    </div>
                    <div class="edition-comparator-form__block-cell-input">
                        <div class="edition-comparator-form__block-cell-inner-input-field"
                            [ngClass]="{'edition-comparator-form__block-cell-inner-input-field--block-group-cell': field.fieldTypeFrontId === 'l-co' || field.fieldTypeFrontId === 'task' || field.fieldTypeFrontId === 'alert'}"
                            [ngSwitch]="field.fieldTypeFrontId">
                            <div class="edition-comparator-form__text-value-italic"
                                *ngSwitchCase="'rich'">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                    [ngClass]="{'edition-comparator-form__text-diff': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                    [innerHTML]="field.value"></p>
                            </div>
                            <div class="edition-comparator-form__text-value"
                                [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                *ngSwitchCase="'rich-editable'">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-text">
                                    <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                        [innerHTML]="field.value"></p>
                                </div>
                            </div>
                            <div class="edition-comparator-form__text-value"
                                [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                *ngSwitchCase="'bool'">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-text">
                                    <div [class]="'edition-comparator-form__side-band-color edition-comparator-form__side-band-color--'+field.value?.color"
                                        [ngShow]="field.value?.color"></div>
                                      <span class="edition-comparator-form__search-text"
                                        [ngShow]="field.value?.valueText"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{field.value?.valueText}}</span>
                                      <span class="edition-comparator-form__search-text"
                                        [ngShow]="!field.value?.valueText && (field.value?.value === true || field.value?.value === false)"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                        [afTranslate]="field.value?.value === true?'common.yes':'common.no'"></span>
                                </div>
                            </div>
                            <div class="edition-comparator-form__text-value"
                                [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                *ngSwitchCase="'txchk'">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-text">
                                    <div [class]="'edition-comparator-form__side-band-color edition-comparator-form__side-band-color--'+field.value?.color"
                                        [ngShow]="field.value?.color"></div>
                                    <span class="edition-comparator-form__search-text"
                                      [ngShow]="field.value?.valueText"
                                      [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{field.value?.valueText}}</span>
                                    <span class="edition-comparator-form__search-text"
                                      [ngShow]="!field.value?.valueText && (field.value?.value === true || field.value?.value === false)"
                                      [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                      [afTranslate]="field.value?.value === true?'common.yes':'common.no'"></span>
                                </div>
                            </div>
                            <div *ngSwitchCase="'list'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable,
                                            'edition-comparator-form__text-value--list': !last && field.value?.length > 1}"
                                    *ngFor="let listValueField of field.value | afOrderBy:'id'; let last = last">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <div class="edition-comparator-form__side-band-color"
                                            [ngShow]="listValueField.color"
                                            [ngStyle]="{'background-color': listValueField.color}"></div>
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueField.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'list-extended'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable,
                                            'edition-comparator-form__text-value--list': !last && field.value?.length > 1}"
                                    *ngFor="let listValueField of field.value | afOrderBy:'id'; let last = last">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <div class="edition-comparator-form__side-band-color"
                                            [ngShow]="listValueField.color"
                                            [ngStyle]="{'background-color': listValueField.color}"></div>
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueField.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'file'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable,
                                            'edition-comparator-form__text-value--list': !last && field.value?.length > 1}"
                                    *ngFor="let listValueField of field.value | afOrderBy:'id'; let last = last">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <div class="edition-comparator-form__side-band-color"
                                            [ngShow]="listValueField.color"
                                            [ngStyle]="{'background-color': listValueField.color}"></div>
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueField.value}}</span>
                                    </div>
                                </div>
                            </div>
                           <div *ngSwitchCase="'templ'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable,
                                            'edition-comparator-form__text-value--list': !last && field.value?.length > 1}"
                                    *ngFor="let listValueField of field.value | afOrderBy:'id'; let last = last">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <div class="edition-comparator-form__side-band-color"
                                            [ngShow]="listValueField.color"
                                            [ngStyle]="{'background-color': listValueField.color}"></div>
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueField.value}}</span>
                                    </div>
                                </div>
                           </div>
                           <div *ngSwitchCase="'pepl'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                    *ngFor="let listValueField of field.value | afOrderBy:'id':true | afLimitTo:1">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <div [class]="'edition-comparator-form__side-band-color history-viewer__side-band-color--' + listValueField.color"
                                            [ngShow]="listValueField.color"></div>
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !listValueField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueField.value}}</span>
                                    </div>
                                </div>
                          </div>

                            <div *ngSwitchCase="'user'">
                                <div class="edition-comparator-form__text-value"
                                    [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable,
                                            'edition-comparator-form__text-value--list': !last && field.value?.length > 1}"
                                    *ngFor="let listUserField of field.value | afOrderBy:'id'; let last = last">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!listUserField.equalsName || !listUserField.equalsMail || !listUserField.equalsPhone) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right': (!listUserField.equalsName || !listUserField.equalsMail || !listUserField.equalsPhone) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-text">
                                        <span class="edition-comparator-form__text-value-user">
                                            <span class="edition-comparator-form__search-text"
                                                [ngClass]="{'edition-comparator-form__text-diff': !listUserField.equalsName && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserField.value?.name}}</span>
                                        </span>
                                        <span class="edition-comparator-form__text-value-user edition-comparator-form__text-value-user--mail">
                                            <span class="edition-comparator-form__search-text"
                                                [ngClass]="{'edition-comparator-form__text-diff': !listUserField.equalsMail && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserField.value?.mail}}</span>
                                        </span>
                                        <span class="edition-comparator-form__text-value-user">
                                            <span class="edition-comparator-form__search-text"
                                                [ngClass]="{'edition-comparator-form__text-diff': !listUserField.equalsPhone && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserField.value?.phone}}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="edition-comparator-form__text-value"
                                [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                *ngSwitchCase="'date'">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-text">
                                    <span class="edition-comparator-form__search-text"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                        [ngShow]="!field.showTime">{{field.value?.value | convertDate:'without-time'}}</span>
                                    <span class="edition-comparator-form__search-text"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                        [ngShow]="field.showTime">{{field.value?.value | convertDate:'without-time'}}</span>
                                </div>
                            </div>
                            <div *ngSwitchCase="'l-co'">
                                <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                    *ngFor="let relatedListField of field.value"
                                    [id]="'block-fieldValue-'+relatedListField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                    <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                        <span class="edition-comparator-form__text-header-group">
                                            <span class="edition-comparator-form__search-text"
                                                [ngClass]="{'edition-comparator-form__text-diff': !relatedListField.keyValue.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{relatedListField.keyValue.value}}</span>
                                        </span>
                                    </div>
                                    <div class="edition-comparator-form__block-form-field"
                                        [ngShow]="!relatedListField.hiddenView">
                                        <div class="edition-comparator-form__block-fields-group-lco"
                                            *ngFor="let relatedField of relatedListField.value">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!relatedListField.keyValue.equalsText || !relatedField.equalsText || !relatedField.equalsLabel) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right': (!relatedListField.keyValue.equalsText || !relatedField.equalsText || !relatedField.equalsLabel) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-lco">
                                                <div class="edition-comparator-form__block-cell-label-field">
                                                    <span class="edition-comparator-form__text-label ">
                                                        <span class="edition-comparator-form__search-text"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !relatedField.equalsLabel && editionComparatorForm.historyDataViewer.comparatorActivated}">{{relatedField.label}}</span>:
                                                    </span>
                                                </div>
                                                <div class="edition-comparator-form__block-cell-input-field">
                                                    <div class="edition-comparator-form__text-value-italic">
                                                        <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !relatedField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                            [innerHTML]="relatedField.value"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'task'">
                              <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                  *ngFor="let taskField of field.value"
                                  [id]="'block-fieldValue-'+taskField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                  <div class="edition-comparator-form__block-diff-forms"
                                      [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!taskField.value?.equalsTitle || !taskField.value?.equalsLabelType || !taskField.value?.equalsType || !taskField.value?.equalsLabelParticipants || !taskField.value?.equalsParticipants || !taskField.value?.equalsLabelExpirationDate || !taskField.value?.equalsExpirationDate || !taskField.value?.equalsLabelRepeat || !taskField.value?.equalsRepeat || (taskField.taskTypeId === 4 && (!taskField.value?.equalsHour || !taskField.value?.equalsLabelLocation || !taskField.value?.equalsVideoconference || !taskField.value?.equalsLocation))) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                          'edition-comparator-form__block-diff-forms--show-right':       (!taskField.value?.equalsTitle || !taskField.value?.equalsLabelType || !taskField.value?.equalsType || !taskField.value?.equalsLabelParticipants || !taskField.value?.equalsParticipants || !taskField.value?.equalsLabelExpirationDate || !taskField.value?.equalsExpirationDate || !taskField.value?.equalsLabelRepeat || !taskField.value?.equalsRepeat || (taskField.taskTypeId === 4 && (!taskField.value?.equalsHour || !taskField.value?.equalsLabelLocation || !taskField.value?.equalsVideoconference || !taskField.value?.equalsLocation))) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                      <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                          <span class="edition-comparator-form__text-header-group">
                                              <span class="edition-comparator-form__search-text"
                                                  [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsTitle && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskField.value?.title}}</span>
                                          </span>
                                      </div>
                                      <div class="edition-comparator-form__block-form-field"
                                          [ngShow]="!taskField.hiddenView">
                                          <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                              <div class="edition-comparator-form__block-cell-label-field">
                                                  <span class="edition-comparator-form__text-label">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLabelType && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="'common.task-type'">Tipo de tarea</span>:
                                                  </span>
                                              </div>
                                              <div class="edition-comparator-form__block-cell-input-field">
                                                  <div class="edition-comparator-form__text-value-italic">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsType && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskField.value?.type}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                              <div class="edition-comparator-form__block-cell-label-field">
                                                  <span class="edition-comparator-form__text-label">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLabelParticipants && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="taskField.taskTypeId === 4?'result-search.participants':'common.users-contacts'">Usuarios/Contactos</span>:
                                                  </span>
                                              </div>
                                              <div class="edition-comparator-form__block-cell-input-field">
                                                  <div class="edition-comparator-form__text-value-italic">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsParticipants && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskField.value?.participants}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                              <div class="edition-comparator-form__block-cell-label-field">
                                                  <span class="edition-comparator-form__text-label">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLabelExpirationDate && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="taskField.taskTypeId === 4?'task-list.date-and-hour':'task-edit.expiration-date'">Fecha de vencimiento</span>:
                                                  </span>
                                              </div>
                                              <div class="edition-comparator-form__block-cell-input-field"
                                                  [ngClass]="{'edition-comparator-form__block-fields-group--last-field': !taskField.value?.repeat && taskField.taskTypeId !== 4}">
                                                  <div class="edition-comparator-form__text-value-italic">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsExpirationDate && editionComparatorForm.historyDataViewer.comparatorActivated}">
                                                          {{taskField.value?.expirationDate | convertDate:'without-time'}}
                                                      </span>
                                                      <span class="edition-comparator-form__search-text"
                                                      [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsHour && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [ngShow]="taskField.taskTypeId === 4"> {{taskField.value?.beginHour}} - {{taskField.value?.endHour}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field"
                                              [ngShow]="taskField.taskTypeId === 4">
                                              <div class="edition-comparator-form__block-cell-label-field">
                                                  <span class="edition-comparator-form__text-label">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLabelLocation && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="'task-list.modality-and-location'">Modalidad y ubicación</span>:
                                                  </span>
                                              </div>
                                              <div class="edition-comparator-form__block-cell-input-field edition-comparator-form__block-fields-group--last-field">
                                                  <div class="edition-comparator-form__text-value-italic">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsVideoconference && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="taskField.videoconference?'task-form.videoconference':'task-form.face-to-face'">Presencial/Videoconferencia</span> /
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLocation && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskField.value?.location}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--last-field"
                                              [ngShow]="taskField.value?.repeat">
                                              <div class="edition-comparator-form__block-cell-label-field">
                                                  <span class="edition-comparator-form__text-label">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsLabelRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                          [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                                                  </span>
                                              </div>
                                              <div class="edition-comparator-form__block-cell-input-field">
                                                  <div class="edition-comparator-form__text-value-italic">
                                                      <span class="edition-comparator-form__search-text"
                                                          [ngClass]="{'edition-comparator-form__text-diff': !taskField.value?.equalsRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskField.value?.repeat}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                              </div>
                            </div>
                            <div *ngSwitchCase="'alert'">
                                <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                    *ngFor="let alertField of field.value"
                                    [id]="'block-fieldValue-'+alertField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                    <div class="edition-comparator-form__block-diff-forms"
                                        [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!alertField.value?.equalsTitle || !alertField.value?.equalsLabelDate || !alertField.value?.equalsDate || !alertField.value?.equalsLabelMomentAlert || !alertField.value?.equalsMomentAlert || !alertField.value?.equalsLabelRepeat || !alertField.value?.equalsRepeat) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                            'edition-comparator-form__block-diff-forms--show-right':       (!alertField.value?.equalsTitle || !alertField.value?.equalsLabelDate || !alertField.value?.equalsDate || !alertField.value?.equalsLabelMomentAlert || !alertField.value?.equalsMomentAlert || !alertField.value?.equalsLabelRepeat || !alertField.value?.equalsRepeat) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                    <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                        <span class="edition-comparator-form__text-header-group">
                                            <span class="edition-comparator-form__search-text"
                                                [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsTitle && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertField.value?.title}}</span>
                                        </span>
                                    </div>
                                    <div class="edition-comparator-form__block-form-field"
                                        [ngShow]="!alertField.hiddenView">
                                        <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                            <div class="edition-comparator-form__block-cell-label-field">
                                                <span class="edition-comparator-form__text-label">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsLabelDate && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                        [afTranslate]="'add-alert.reference-date'">Fecha de referencia</span>:
                                                </span>
                                            </div>
                                            <div class="edition-comparator-form__block-cell-input-field">
                                                <div class="edition-comparator-form__text-value-italic">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsDate && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertField.value?.date}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                            <div class="edition-comparator-form__block-cell-label-field">
                                                <span class="edition-comparator-form__text-label">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsLabelMomentAlert && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                        [afTranslate]="'add-alert.alert-moment'">Momento de la alerta</span>:
                                                </span>
                                            </div>
                                            <div class="edition-comparator-form__block-cell-input-field"
                                                [ngClass]="{'edition-comparator-form__block-fields-group--last-field': !alertField.value?.repeat}">
                                                <div class="edition-comparator-form__text-value-italic">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsMomentAlert && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertField.value?.momentAlert}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--last-field"
                                            [ngShow]="alertField.value?.repeat">
                                            <div class="edition-comparator-form__block-cell-label-field">
                                                <span class="edition-comparator-form__text-label">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsLabelRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                        [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                                                </span>
                                            </div>
                                            <div class="edition-comparator-form__block-cell-input-field">
                                                <div class="edition-comparator-form__text-value-italic">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertField.value?.equalsRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertField.value?.repeat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="edition-comparator-form__text-value"
                                [ngClass]="{'edition-comparator-form__text-value--disabled': field.noEditable}"
                                *ngSwitchDefault>
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-text">
                                    <div class="edition-comparator-form__side-band-color"
                                        [ngShow]="field.value?.color"
                                        [ngStyle]="{'background-color': field.value?.color}"></div>
                                    <span class="edition-comparator-form__search-text"
                                        [ngClass]="{'edition-comparator-form__text-diff': !field.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{field.value?.value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edition-comparator-form__block-group edition-comparator-form__block-group--subgroup"
                    [ngClass]="{'edition-comparator-form__block-group--first-subgroup': first,
                            'edition-comparator-form__hidden-block': subContainer.visibilityHidden}"
                    *ngFor="let subContainer of container.subContainers; let first = first"
                    [ngShow]="!subContainer.hiddenContainer">
                    <div class="edition-comparator-form__block-inner-group">
                        <div class="edition-comparator-form__block-diff-forms"
                            [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': subContainer.newBlock && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                'edition-comparator-form__block-diff-forms--show-right-title-subgroup': subContainer.newBlock && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                        <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--subgroup">
                            <div class="edition-comparator-form__block-diff-forms"
                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subContainer.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                    'edition-comparator-form__block-diff-forms--show-right-title-subgroup': !subContainer.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                            <span class="edition-comparator-form__text-header-group">
                                <span class="edition-comparator-form__search-text"
                                    [ngClass]="{'edition-comparator-form__text-diff': !subContainer.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{subContainer.name}}</span>
                            </span>
                        </div>
                        <div class="edition-comparator-form__block-form-group"
                            [ngShow]="!subContainer.hiddenView">
                            <div class="edition-comparator-form__block-fields-group"
                                [ngClass]="{'edition-comparator-form__block-fields-group--big-padding': (subField.fieldTypeFrontId === 'alert' || subField.fieldTypeFrontId === 'task' || subField.fieldTypeFrontId === 'l-co') && !last,
                                        'edition-comparator-form__hidden-block': subField.visibilityHidden}"
                                [id]="'block-subField-'+subField.iD_Version0+'-'+editionComparatorForm.historyDataViewer.comparatorPosition"
                                *ngFor="let subField of subContainer.fields; let last = last"
                                [ngShow]="!subField.hiddenContainer">
                                <div class="edition-comparator-form__block-diff-forms"
                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                <div class="edition-comparator-form__block-cell-label"
                                    [ngClass]="{'edition-comparator-form__block-cell-label--padding-0': subField.fieldTypeFrontId === 'rich'}">
                                    <span class="edition-comparator-form__text-label">
                                        <span class="edition-comparator-form__search-text"
                                            [ngClass]="{'edition-comparator-form__text-diff': !subField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{subField.label}}</span>
                                    </span>
                                </div>
                                <div class="edition-comparator-form__block-cell-input">
                                    <div class="edition-comparator-form__block-cell-inner-input-subfield"
                                        [ngClass]="{'edition-comparator-form__block-cell-inner-input-subfield--block-group-cell': subField.fieldTypeFrontId === 'l-co' || subField.fieldTypeFrontId === 'task' || subField.fieldTypeFrontId === 'alert'}"
                                        [ngSwitch]="subField.fieldTypeFrontId">
                                        <div class="edition-comparator-form__text-value-italic"
                                            *ngSwitchCase="'rich'">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                                [ngClass]="{'edition-comparator-form__text-diff': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                [innerHTML]="subField.value"></p>
                                        </div>
                                        <div class="edition-comparator-form__text-value"
                                            [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                            *ngSwitchCase="'rich-editable'">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                             <div class="edition-comparator-form__block-text">
                                                <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.equalsTextValue && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                    [innerHTML]="subField.value"></p>
                                             </div>
                                        </div>
                                        <div class="edition-comparator-form__text-value"
                                            [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                            *ngSwitchCase="'bool'">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-text">
                                                <div [class]="'edition-comparator-form__side-band-color edition-comparator-form__side-band-color--'+subField.value?.color"
                                                    [ngShow]="subField.value?.color"></div>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngShow]="subField.value?.valueText"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{subField.value?.valueText}}</span>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngShow]="!subField.value?.valueText && (subField.value?.value === true || subField.value?.value === false)"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                    [afTranslate]="subField.value?.value === true?'common.yes':'common.no'"></span>
                                            </div>
                                        </div>
                                        <div class="edition-comparator-form__text-value"
                                            [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                            *ngSwitchCase="'txchk'">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-text">
                                                <div [class]="'edition-comparator-form__side-band-color edition-comparator-form__side-band-color--'+subField.value?.color"
                                                    [ngShow]="subField.value?.color"></div>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngShow]="subField.value?.valueText"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{subField.value?.valueText}}</span>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngShow]="!subField.value?.valueText && (subField.value?.value === true || subField.value?.value === false)"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                    [afTranslate]="subField.value?.value === true?'common.yes':'common.no'"></span>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'list'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable,
                                                        'edition-comparator-form__text-value--list': !last && subField.value?.length > 1}"
                                                *ngFor="let listValueSubField of subField.value | afOrderBy:'id'; let last = last">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <div class="edition-comparator-form__side-band-color"
                                                        [ngShow]="listValueSubField.color"
                                                        [ngStyle]="{'background-color': listValueSubField.color}"></div>
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueSubField.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'list-extended'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable,
                                                        'edition-comparator-form__text-value--list': !last && subField.value?.length > 1}"
                                                *ngFor="let listValueSubField of subField.value | afOrderBy:'id'; let last = last">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <div class="edition-comparator-form__side-band-color"
                                                        [ngShow]="listValueSubField.color"
                                                        [ngStyle]="{'background-color': listValueSubField.color}"></div>
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueSubField.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'file'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable,
                                                        'edition-comparator-form__text-value--list': !last && subField.value?.length > 1}"
                                                *ngFor="let listValueSubField of subField.value | afOrderBy:'id'; let last = last">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <div class="edition-comparator-form__side-band-color"
                                                        [ngShow]="listValueSubField.color"
                                                        [ngStyle]="{'background-color': listValueSubField.color}"></div>
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueSubField.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'templ'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable,
                                                        'edition-comparator-form__text-value--list': !last && subField.value?.length > 1}"
                                                *ngFor="let listValueSubField of subField.value | afOrderBy:'id'; let last = last">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <div class="edition-comparator-form__side-band-color"
                                                        [ngShow]="listValueSubField.color"
                                                        [ngStyle]="{'background-color': listValueSubField.color}"></div>
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueSubField.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'pepl'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                                *ngFor="let listValueSubField of subField.value | afOrderBy:'id':true | afLimitTo:1">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <div [class]="'edition-comparator-form__side-band-color history-viewer__side-band-color--' + listValueSubField.color"
                                                        [ngShow]="listValueSubField.color"></div>
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !listValueSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listValueSubField.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'user'">
                                            <div class="edition-comparator-form__text-value"
                                                [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable,
                                                        'edition-comparator-form__text-value--list': !last && subField.value?.length > 1}"
                                                *ngFor="let listUserSubField of subField.value | afOrderBy:'id'; let last = last">
                                                <div class="edition-comparator-form__block-diff-forms"
                                                    [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!listUserSubField.equalsName || !listUserSubField.equalsMail || !listUserSubField.equalsPhone) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                        'edition-comparator-form__block-diff-forms--show-right-subgroup': (!listUserSubField.equalsName || !listUserSubField.equalsMail || !listUserSubField.equalsPhone) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                <div class="edition-comparator-form__block-text">
                                                    <span class="edition-comparator-form__text-value-user">
                                                        <span class="edition-comparator-form__search-text"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !listUserSubField.equalsName && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserSubField.value?.name}}</span>
                                                    </span>
                                                    <span class="edition-comparator-form__text-value-user edition-comparator-form__text-value-user--mail">
                                                        <span class="edition-comparator-form__search-text"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !listUserSubField.equalsMail && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserSubField.value?.mail}}</span>
                                                    </span>
                                                    <span class="edition-comparator-form__text-value-user">
                                                        <span class="edition-comparator-form__search-text"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !listUserSubField.equalsPhone && editionComparatorForm.historyDataViewer.comparatorActivated}">{{listUserSubField.value?.phone}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edition-comparator-form__text-value"
                                            [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                            *ngSwitchCase="'date'">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup-subgroup': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-text">
                                                <span class="edition-comparator-form__search-text"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                    [ngShow]="!subField.showTime">{{subField.value?.value | convertDate:'without-time'}}</span>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                    [ngShow]="subField.showTime">{{subField.value?.value | convertDate:'without-time'}}</span>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'l-co'">
                                            <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                                *ngFor="let relatedListSubField of subField.value"
                                                [id]="'block-subFieldValue-'+relatedListSubField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                                <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                                    <span class="edition-comparator-form__text-header-group">
                                                        <span class="edition-comparator-form__search-text"
                                                            [ngClass]="{'edition-comparator-form__text-diff': !relatedListSubField.keyValue.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{relatedListSubField.keyValue.value}}</span>
                                                    </span>
                                                </div>
                                                <div class="edition-comparator-form__block-form-field"
                                                    [ngShow]="!relatedListSubField.hiddenView">
                                                    <div class="edition-comparator-form__block-fields-group-lco"
                                                        *ngFor="let relatedSubField of relatedListSubField.value">
                                                        <div class="edition-comparator-form__block-diff-forms"
                                                            [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!relatedListSubField.keyValue.equalsText || !relatedSubField.equalsText || !relatedSubField.equalsLabel) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                                'edition-comparator-form__block-diff-forms--show-right-subgroup': (!relatedListSubField.keyValue.equalsText || !relatedSubField.equalsText || !relatedSubField.equalsLabel) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                                        <div class="edition-comparator-form__block-lco">
                                                            <div class="edition-comparator-form__block-cell-label-field">
                                                                <span class="edition-comparator-form__text-label">
                                                                    <span class="edition-comparator-form__search-text"
                                                                        [ngClass]="{'edition-comparator-form__text-diff': !relatedSubField.equalsLabel && editionComparatorForm.historyDataViewer.comparatorActivated}">{{relatedSubField.label}}</span>:
                                                                </span>
                                                            </div>
                                                            <div class="edition-comparator-form__block-cell-input-field">
                                                                <div class="edition-comparator-form__text-value-italic">
                                                                    <p class="edition-comparator-form__search-text edition-comparator-form__value-bind-html"
                                                                        [ngClass]="{'edition-comparator-form__text-diff': !relatedSubField.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                        [innerHTML]="relatedSubField.value"></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div  *ngSwitchCase="'task'">
                                        <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                            *ngFor="let taskSubField of subField.value"
                                            [id]="'block-subFieldValue-'+taskSubField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': (!taskSubField.value?.equalsTitle || !taskSubField.value?.equalsLabelType || !taskSubField.value?.equalsType || !taskSubField.value?.equalsLabelParticipants || !taskSubField.value?.equalsParticipants || !taskSubField.value?.equalsLabelExpirationDate || !taskSubField.value?.equalsExpirationDate || !taskSubField.value?.equalsLabelRepeat || !taskSubField.value?.equalsRepeat || (taskSubField.taskTypeId === 4 && (!taskSubField.value?.equalsHour || !taskSubField.value?.equalsLabelLocation || !taskSubField.value?.equalsVideoconference || !taskSubField.value?.equalsLocation))) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup-subgroup': (!taskSubField.value?.equalsTitle || !taskSubField.value?.equalsLabelType || !taskSubField.value?.equalsType || !taskSubField.value?.equalsLabelParticipants || !taskSubField.value?.equalsParticipants || !taskSubField.value?.equalsLabelExpirationDate || !taskSubField.value?.equalsExpirationDate || !taskSubField.value?.equalsLabelRepeat || !taskSubField.value?.equalsRepeat || (taskSubField.taskTypeId === 4 && (!taskSubField.value?.equalsHour || !taskSubField.value?.equalsLabelLocation || !taskSubField.value?.equalsVideoconference || !taskSubField.value?.equalsLocation))) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                                <span class="edition-comparator-form__text-header-group">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsTitle && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskSubField.value?.title}}</span>
                                                </span>
                                            </div>
                                            <div class="edition-comparator-form__block-form-field"
                                                [ngShow]="!taskSubField.hiddenView">
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLabelType && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'common.task-type'">Tipo de tarea</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsType && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskSubField.value?.type}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLabelParticipants && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="taskSubField.taskTypeId === 4?'result-search.participants':'common.users-contacts'">
                                                                Usuarios/Contactos
                                                            </span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsParticipants && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskSubField.value?.participants}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLabelExpirationDate && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="taskSubField.taskTypeId === 4?'task-list.date-and-hour':'task-edit.expiration-date'">Fecha de vencimiento</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field"
                                                        [ngClass]="{'edition-comparator-form__block-fields-group--last-field': !taskSubField.value?.repeat && taskSubField.taskTypeId !== 4}">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsExpirationDate && editionComparatorForm.historyDataViewer.comparatorActivated}">
                                                                {{taskSubField.value?.expirationDate | convertDate:'without-time'}}
                                                            </span>
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsHour && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [ngShow]="taskSubField.taskTypeId === 4"> {{taskSubField.value?.beginHour}} - {{taskSubField.value?.endHour}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field"
                                                    [ngShow]="taskSubField.taskTypeId === 4">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLabelLocation && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'task-list.modality-and-location'">Modalidad y ubicación</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field edition-comparator-form__block-fields-group--last-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsVideoconference && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="taskSubField.videoconference?'task-form.videoconference':'task-form.face-to-face'">Presencial/Videoconferencia</span> /
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLocation && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskSubField.value?.location}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--last-field"
                                                    [ngShow]="taskSubField.value?.repeat">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsLabelRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !taskSubField.value?.equalsRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}">{{taskSubField.value?.repeat}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'alert'">
                                        <div class="edition-comparator-form__block-group edition-comparator-form__block-group--field"
                                            *ngFor="let alertSubField of subField.value"
                                            [id]="'block-subFieldValue-'+alertSubField.id+'-'+editionComparatorForm.historyDataViewer.comparatorPosition">
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left':    (!alertSubField.value?.equalsTitle || !alertSubField.value?.equalsLabelDate || !alertSubField.value?.equalsDate || !alertSubField.value?.equalsLabelMomentAlert || !alertSubField.value?.equalsMomentAlert || !alertSubField.value?.equalsLabelRepeat || !alertSubField.value?.equalsRepeat) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': (!alertSubField.value?.equalsTitle || !alertSubField.value?.equalsLabelDate || !alertSubField.value?.equalsDate || !alertSubField.value?.equalsLabelMomentAlert || !alertSubField.value?.equalsMomentAlert || !alertSubField.value?.equalsLabelRepeat || !alertSubField.value?.equalsRepeat) && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-header-group edition-comparator-form__block-header-group--field">
                                                <span class="edition-comparator-form__text-header-group">
                                                    <span class="edition-comparator-form__search-text"
                                                        [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsTitle && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertSubField.value?.title}}</span>
                                                </span>
                                            </div>
                                            <div class="edition-comparator-form__block-form-field"
                                                [ngShow]="!alertSubField.hiddenView">
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsLabelDate && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'add-alert.reference-date'">Fecha de referencia</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsDate && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertSubField.value?.date}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--field">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsLabelMomentAlert && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'add-alert.alert-moment'">Momento de la alerta</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field"
                                                        [ngClass]="{'edition-comparator-form__block-fields-group--last-field': !alertSubField.value?.repeat}">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsMomentAlert && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertSubField.value?.momentAlert}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edition-comparator-form__block-fields-group edition-comparator-form__block-fields-group--last-field"
                                                    [ngShow]="alertSubField.value?.repeat">
                                                    <div class="edition-comparator-form__block-cell-label-field">
                                                        <span class="edition-comparator-form__text-label">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsLabelRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}"
                                                                [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                                                        </span>
                                                    </div>
                                                    <div class="edition-comparator-form__block-cell-input-field">
                                                        <div class="edition-comparator-form__text-value-italic">
                                                            <span class="edition-comparator-form__search-text"
                                                                [ngClass]="{'edition-comparator-form__text-diff': !alertSubField.value?.equalsRepeat && editionComparatorForm.historyDataViewer.comparatorActivated}">{{alertSubField.value?.repeat}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        <div class="edition-comparator-form__text-value"
                                            [ngClass]="{'edition-comparator-form__text-value--disabled': subField.noEditable}"
                                            *ngSwitchDefault>
                                            <div class="edition-comparator-form__block-diff-forms"
                                                [ngClass]="{'edition-comparator-form__block-diff-forms--show-left': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 1,
                                                    'edition-comparator-form__block-diff-forms--show-right-subgroup': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated && editionComparatorForm.historyDataViewer.comparatorPosition === 2}"></div>
                                            <div class="edition-comparator-form__block-text">
                                                <div class="edition-comparator-form__side-band-color"
                                                    [ngShow]="subField.value?.color"
                                                    [ngStyle]="{'background-color': subField.value?.color}"></div>
                                                <span class="edition-comparator-form__search-text"
                                                    [ngClass]="{'edition-comparator-form__text-diff': !subField.value?.equalsText && editionComparatorForm.historyDataViewer.comparatorActivated}">{{subField.value?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
