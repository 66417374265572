import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';

@Component({
  selector: 'export-result-report',
  templateUrl: './export-result-report.component.html'
})
export class ExportResultReportComponent implements OnInit {
  exportResultReport:any = {
    exportFieldsOpen: false,
    addFields: null,
    name: '',
    exportFields: new FormControl,
    nameDocument: {
        required: true,
        label: this.translate.instant('common.document-name')
    },
    fieldsExports: {
        multiple: true,
        label: this.translate.instant('export-result-report.only-results'),
        type: 'modal'
    },
    exportFieldsList: [],
    listSelected: []
  }
  constructor(private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private downloadService: DownloadService,
    private activeModal: NgbActiveModal) { }

  @Input()   exportFieldsList: any = null;
  @Input()   concept: any = null;

  ngOnInit(): void {
    let that: any = this.exportResultReport;
    that.concept = this.concept;
    if(that.concept.exportFieldsList && that.concept.exportFieldsList.length){
      that.exportFieldsList = that.concept.exportFieldsList
    }else{
      this.getExportFields()
    }
    this.cancelSelectionFields();
  }

  getExportFields(){
    this.apiService.get('polls/exportfields', this.exportResultReport.concept.conceptId)
      .then((data:any)=>{
          this.exportResultReport.concept.exportFieldsList = this.commonUtilsService.editList(data, 'fieldId', 'label');
          this.exportResultReport.exportFieldsList = this.exportResultReport.concept.exportFieldsList;
      }, (errorData:any)=>{})
  }

  itemSelected(e:any, type:string){
    this.exportResultReport[type] = e.newValue;
  }

  showExportReport(newValue: any){
      this.exportResultReport.exportFields = newValue;
      if(this.exportResultReport.exportFields){
        this.exportResultReport.exportFieldsOpen = true
      }else{
        this.cancelSelectionFields();
      }
  }

  cancelSelectionFields(){
    let that: any = this.exportResultReport
    that.exportFieldsOpen = false;
    that.exportFields = false;
    that.addFields = null;
    that.listSelected = [];
    that.exportFieldsList.forEach((question:any) => { question.selected = false});
  }

  addSelectionFields(){
    let that: any = this.exportResultReport
    that.exportFieldsOpen = false;
    that.listSelected = [];
    if(that.addFields !== null && that.addFields !== ''){
        if(that.addFields.indexOf('|')!==-1){
            that.listSelected = that.addFields.split('|');
            that.numberFields = that.listSelected.length + ' ' + this.translate.instant('common.fields');
        }else{
            that.listSelected = [that.addFields];
            that.numberFields = that.listSelected.length + ' ' + this.translate.instant('common.field');
        }
    }
    if(!that.listSelected.length){
        that.exportFields = false;
    }
  }

  exportPoll(){
    let that: any = this.exportResultReport
    if(that.name !== ''){
        that.nameDocument.error = false;
        this.loader.openLoader('export-excel-poll');
        this.callToExportPoll();
    }else{
        that.nameDocument.error = true;
    }
  }

  callToExportPoll(){
      let that: any = this.exportResultReport
      this.apiService.getBlob('polls/exportexcel', that.concept.conceptId, 'post', {filename:that.name, fieldids: that.listSelected})
          .then((data:any)=>{
              that.exportExcel = data;
              this.downloadService.triggerDownload(data.blob, that.name + '.xlsx');
              this.handleAccept();
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {
    let that: any = this.exportResultReport
    this.loader.closeLoader(['export-excel-poll']);

    this.activeModal.close({
      result: 'ok',
      infoExport: {
        name: that.name,
        listSelected: that.addFields,
        exportFields: that.exportFields,
        exportExcel: that.exportExcel
      }
    });
  };
}
