import moment from 'moment';
import { Component,Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'listen-record',
  templateUrl: './listen-record.component.html'
})
export class ListenRecordComponent implements OnInit {
  custom: any = this.customLanding.getCustom();
  startAudio: boolean = false;
  initInterval: number = 0;
  percentTime: number = 0;
  recordedTime: string = '00:00';
  interval: any;
  startTime: any;
  listenRecord: any = {}
  constructor(
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory,
    private apiService: ApiServiceService) { }

  @Input() voiceRecord:any;
  @Input() isLanding:boolean;

  ngOnInit(): void {
    this.listenRecord.voiceRecord = this.voiceRecord;
    if(this.listenRecord.voiceRecord.commentId){
      this.listenRecord.voiceRecord.player = new Audio()
    }
  }


  audioDownLoad(){
    const url = URL.createObjectURL(this.voiceRecord.recordingFile);
	  const anchor = document.createElement("a");
	  anchor.download = this.voiceRecord.name
	  anchor.href = url;
	  anchor.click();
  }

  playRecordVoice(){
    this.startAudio = !this.startAudio;
    if(!this.listenRecord.voiceRecord.audioLoaded && this.listenRecord.voiceRecord.commentId){
      this.getAudio()
    }else{
      if(this.startAudio){
        this.initInterval = 0;
        this.initStartAudio()
      }else{
        this.stopInterval();
      }
    }
  }

  getAudio(){
    this.apiService.getBlob('comments/download-audio/' + this.listenRecord.voiceRecord.commentId)
      .then((data:any) => {
        this.listenRecord.voiceRecord.recordingFile = data.blob;
        let blobUrl: string = URL.createObjectURL(data.blob);
        this.listenRecord.voiceRecord.player = new Audio();
        this.listenRecord.voiceRecord.player.src = blobUrl
        this.getDuration(blobUrl)
          .then((duration:any)=>{
            this.listenRecord.voiceRecord.secondsRecord = duration.toFixed(2);
            this.listenRecord.voiceRecord.audioLoaded = true;
            this.listenRecord.voiceRecord.hasVoiceRecord = true;
            this.listenRecord.voiceRecord.loadFromBack = true;
            this.initInterval = 0;
            this.initStartAudio();
          })
        $(this.listenRecord.voiceRecord.player).on("loadedmetadata", ()=>{

        });




      }, (error:any) => {})
  }

 getDuration(src:any) {
    return new Promise(function(resolve) {
        var audio = new Audio();
        $(audio).on("loadedmetadata", function(){
            resolve(audio.duration);
        });
        audio.src = src;
    });
  }


  initStartAudio(){
    this.startTime = moment();
    this.voiceRecord.player.play();
    this.interval = setInterval(() => {
      let currentTime:any = moment();
      let diffTime = moment.duration(currentTime.diff(this.startTime));
      this.recordedTime = this.toString(diffTime.minutes()) + ":" + this.toString(diffTime.seconds());
      this.percentTime = this.initInterval * 100 / this.voiceRecord.secondsRecord;
      if(this.percentTime >= 100){
        this.stopInterval()
      }else{
        this.initInterval++
      }
    }, 1000);
    this.startAudio = true;
  }

  stopInterval(){
    this.startAudio = false;
    this.initInterval = 0;
    this.voiceRecord.player.pause();
    this.voiceRecord.player.currentTime = 0;
    this.percentTime = 0;
    this.recordedTime = '00:00'
    clearInterval(this.interval)
  }

  private toString(value:any) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }


  handleAccept(optionSelect:string='ok') {
    this.stopInterval()
    this.activeModal.close({result: optionSelect})
  }
}
