<div class="field-type-list__text-tree-selected"
  [ngStyle]="{'padding-left': (16*level)+'px'}"
  [ngClass]="{'field-type-list__text-tree-selected--selected': !itemSelected.children?.length}"
  *ngFor="let itemSelected of list"
  [ngShow]="itemSelected.selectedTree || itemSelected.selected">
  <span class="field-type-list__tree-select-dot" [ngShow]="itemSelected.selected"></span>
  <span-tooltip [text]="itemSelected.text"></span-tooltip>
  <tree-selected-multiple *ngIf="itemSelected.children?.length"
    class="field-type-list__block-tree-selected-multiple"
    [list]="itemSelected.children"
    [level]="nextLevel"></tree-selected-multiple>
</div>
