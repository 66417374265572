import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';

@Component({
  selector: 'alert-list-window',
  templateUrl: './alert-list-window.component.html'
})

export class AlertListWindowComponent implements OnInit {
  productId:string = 'blanqueo';
  evaluationId: string = this.route.snapshot.params['evaluationId'];
  alertListWindow:any = {
    configPagination: {
      pageSize: 10
    },
    currentPage: 1,
    search: false,
    searchAlert: '',
    typeFilter: 'alertDate',
    orderOperationList: true,
    filters: ['origin', 'createdBy', 'destinationUnique'],
    alertList: [],
    filterAlertList: [],
    originFilter: {
      name: this.translate.instant('common.origin')
    },
    createdByFilter: {
        name: this.translate.instant('common.creator')
    },
    destinationUniqueFilter: {
        name: this.translate.instant('common.participant')
    },
    titleFilter: {
        name: this.translate.instant('common.title'),
        width: '200px'
    },
  }
  constructor(private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private filterColumnService: FilterColumnService,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts(){
    this.apiService.get('blanqueo/operationlockreviewalerts', this.evaluationId)
      .then((data:any)=>{
          data.forEach((alert: any, index: any) => {
              alert.orderItem = index;
              switch(alert.definitiveLock){
                case 1:
                  alert.origin = this.translate.instant('alert-list-window.manage-lock-permanently');
                  break;
                case 0:
                  alert.origin = this.translate.instant('alert-list-window.manage-lock-unlock');
                  break;
                default://null
                    if(alert.reviewReassign === 1){
                        alert.origin = this.translate.instant('alert-list-window.manage-lock-reassign');
                    }else{
                      alert.origin = alert.lockAuto===1?this.translate.instant('alert-list-window.lock-auto'):this.translate.instant('alert-list-window.lock-manual');
                    }
                  break;
              }

              if(alert.destination?.length){
                  let destinationMap = new Map();
                  alert.destinationUnique = [];
                  alert.destination.forEach((responsible:any, index:any) => {
                      let uniqueId = responsible.type+'-'+responsible.id;
                      if(!destinationMap.has(uniqueId)){
                          destinationMap.set(uniqueId, index);
                          alert.destinationUnique.push(responsible);
                      }
                  });
              }

          })
          this.alertListWindow.alertList = data;
          this.alertListWindow.filterAlertList = data;
          this.getFilters();
      }, (errorData:any)=>{
      })
  }

  getFilters(){
      this.alertListWindow.filters.forEach((filter:any) => {
          this.alertListWindow[filter+'List'] = filter === 'destinationUnique'?this.filterColumnService.extractChildrenList(filter, 'fullName', this.alertListWindow.alertList) : this.filterColumnService.extractList(filter, this.alertListWindow.alertList);
          this.alertListWindow[filter+'ListFilter'] = [];
      });
      this.loader.closeLoader(['open-alert-list', 'front-login-login']);
  }

  searchBy(type:any){
      if(this.alertListWindow.typeFilter === type){
          this.alertListWindow.orderOperationList = !this.alertListWindow.orderOperationList;
      }else{
          this.alertListWindow.typeFilter = type;
          this.alertListWindow.orderOperationList = true;
      }
  }

  selectTypeOperation(e:any, type:any){
      let item: any = e.source;
      let listFilter = this.alertListWindow[type+'ListFilter'];
      this.alertListWindow[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      this.alertListWindow[type+'Filter'].hasFilter = this.alertListWindow[type+'ListFilter'].length;
      item.selected = !item.selected;
      this.filterAlert();
  }

  filterAlert(type: any = ''){
      let searchFilter = this.alertListWindow.searchAlert;
      let searchWord = 'evaluation';
      if(type){
          searchFilter = this.alertListWindow[type + 'WordFilter'];
          searchWord = type;
      }
      let filterList:any = [];
      filterList = this.filterColumnService.filterByWord(searchFilter, this.alertListWindow.alertList, searchWord);
      this.alertListWindow.filters.forEach((filter:any) => {
          let filteredList = this.alertListWindow[filter+'ListFilter'];
          filterList = filter === 'destinationUnique'? this.filterColumnService.filterBycolumnChildren(filterList, filteredList, 'fullName', filter) : this.filterColumnService.filterBycolumn(filterList, filteredList, filter);

      });
      this.alertListWindow.filterAlertList = filterList;
  }

  orderBySearch(e:any, type:string){
      switch(e.action){
          case 'filter':
              this.alertListWindow[type+'WordFilter'] = e.newValue;
              this.filterAlert(type);
              break;
          case 'order':
              this.searchBy(type);
              break;
      }
  }

  changeView(){
    this.commonUtilsService.goToStateUrl();
  }

  toggleSearch() {
      this.alertListWindow.search = !this.alertListWindow.search;
      if (!this.alertListWindow.search) {
          this.alertListWindow.searchAlert = '';
      }
  }
}

