import { Component, Input, OnChanges, OnInit, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ConceptList } from '../utils/conceptListParent';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CurrentPageFactory } from '@shared/factories/current-page/current-page.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ConceptListParentService } from '../utils/concept-list-parent.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'concept-list-default',
  templateUrl: './concept-list-default.component.html',
})
export class ConceptListDefaultComponent implements OnInit, OnChanges, OnDestroy {

  skin:string = this.globalCfg.skin;
  userPermissions:any;
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  folderId:any = this.route.snapshot.params['folderId'];
  conceptList:any = {
    textFilters: ['title'],
    titleWordFilter: '',
    order: true,
    openFocusTitle: false,
    openFocusAuthor: false,
    statePoll: false,
    showResultsReports: false,
    anySelect: false,
    dateFilter: 'creationDate',
    configuredTotal:{
      first: 1,
      last: 20
    },
    configPagination: {
      pageSize: 20,
      maxSize: 5
    },
    currentPage: 1,
    conceptList: [],
    countConceptSelect: [],
    conceptListFilter: [],
    creationDateFromDate: null,
    creationDateToDate: null,
    modificationDateFromDate: null,
    modificationDateToDate: null,
    selectedFilters: null,
    textSelectedFilters: null,
    stateFilter: { name: this.translate.instant('common.state') },
    authorFilter: { name: this.translate.instant('common.author') },
    nameVersionFilter: { name: this.translate.instant('concept-list.name-version') },
    stepFilter: { name: this.translate.instant('common.state') },
    associatedEvaluationNameFilter: { name: this.translate.instant('new-report.asociated-implantation') },
    extensionFilter: { name: this.translate.instant('common.format') },
    statusFilter: { name: this.translate.instant('common.state') },
    managersFilter: { name: this.translate.instant('box-queries.manager') },
    titleFilter: {
      name: this.translate.instant('common.title'),
      orderCol: true,
      filterOrder: 'title',
      width: '200px'
    },
    typeObjectId: 'conceptObjectId'
  };

  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private conceptListParent: ConceptListParentService
  ) {}

  @Input() conceptInfo:any;
  @Input() evaluation:any;
  @Input() toShow:any;
  @Input() modify:any;
  @Input() archivedAmount:any;
  @Output() evaluateConcept = new EventEmitter();
  @Output() launchAction = new EventEmitter();
  @Output() showElements = new EventEmitter();

  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.conceptListParent.OnInit(this);
  }

  ngOnChanges(): void {
    this.conceptListParent.OnChanges( this);
  }

  filterComponentList(){
    this.conceptListParent.FilterComponentList(this)
  }

  selectTypeOperation(item:any, type:any){
    this.conceptListParent.SelectTypeOperation(item.source, type, this)
  }

  selectMultipleConcept(type:any, evaluatedConcept:any = null){
    this.conceptListParent.SelectMultipleConcept(type, evaluatedConcept, this)
  }

  launchActionOption(option:any, item:any){
    this.conceptListParent.LaunchActionOption(option, item, this)
  }

  openInNewTab(item:any){
    this.conceptListParent.OpenInNewTab(item, this);
  }

  openAssociatedEvaluation(concept:any){
    this.conceptListParent.OpenAssociatedEvaluation(concept, this);
  }

  openModalConfidential(instance:any){
    this.conceptListParent.OpenModalConfidential(instance, this)
  }

  orderBySearch(e:any, type:any){
    this.conceptListParent.OrderBySearch(e, type, this);
  }

  orderDate(type:any){
    this.conceptListParent.OrderDate(type, this);
  }

  openFocus(type:any){
    this.conceptList['openFocus'+type] = !this.conceptList['openFocus'+type];
  }

  archiveConcept(concept:any, archived:any){
    this.conceptListParent.ArchiveConcept(concept, archived, this)
  }

  viewArchived(){
    this.conceptInfo.reportId? this.showElements.emit({toShow:'archived'}) : this.launchAction.emit({toShow:'archived', type: 'showElements'});
  }

  // resetFilter(e:any){
  //   this.conceptListParent.ResetFilter(e, this)
  // }

  showReports(){
    this.launchAction.emit({toShow:'report', type: 'changeToShow'});
  }

  assignManager(concept:any){
    this.conceptListParent.AssignManager(concept, this)
  }

  changeState(evaluateConcept:any){
    this.conceptListParent.ChangeState(evaluateConcept, this)
  }
  showTooltip(id:string):boolean{
    return !this.commonUtilsService?.showTooltip(id);
  }

  changeToAnonymatum(item:any, type:any = ''){
    this.conceptListParent.ChangeToAnonymatum(item, type, this);
  }

  renameConcept(concept: any){
    this.conceptListParent.RenameConcept(concept);
  }

  closeDropdown(type: any){
    this.conceptListParent.closeDropdown(type, this.dropdowns);
  }

  ngOnDestroy(){
      this.conceptListParent.ngOnDestroy();
  }

}

