import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'custom-report-list',
  templateUrl: './custom-report-list.component.html'
})
export class CustomReportListComponent implements OnChanges {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService:CommonUtilsService,
    private filterColumnService:FilterColumnService,
    private modalService:ModalServiceService,
    private loader:LoaderFactory,
    private apiService:ApiServiceService
  ) { }

  @Input() reportList:any;

  @Output() changeView = new EventEmitter();
  @Output() viewPredefinedReport = new EventEmitter();
  @Output() duplicatePredefinedReport = new EventEmitter();
  @Output() editCustomReport = new EventEmitter();

  skin:string =  this.globalCfg.skin;
  customReportList:any = {
    productNameOrder: 'reportName',
    orderOperationProductName: false,
    filters: ['isCustom'],
    newCustomReportView: { code: 'newCustomReport' },
    currentPage: 1,
    showInfo: true,
    configPagination: {
      pageSize: 10
    },
    reportNameWordFilter: '',
    reportFieldsWordFilter: ''
  };

  ngOnChanges() {
    let that = this.customReportList;
    that.filterReportList = this.reportList;
    that.textInfo = this.translate.instant('custom-report-list.info-text');
    that.isCustomFilter = {
      name: this.translate.instant('custom-report-list.header-table.source'),
      id: this.commonUtilsService.createId()
    };
    that.isCustomList = [
      this.translate.instant('custom-report-list.custom-reports'),
      this.translate.instant('custom-report-list.predefined-reports')
    ];
    this.getFilters();
  }

  getFilters() {
    let that = this.customReportList;
    for (let filter of that.filters) {
        that[filter + 'List'] = this.filterColumnService.extractList(filter, this.reportList);
        that[filter + 'ListFilter'] = [];
        if (filter === 'isCustom') {
            for (let isCustomItem of that.isCustomList.length) {
                if (isCustomItem.name === 0) {
                    isCustomItem.nameTranslate = this.translate.instant('custom-report-list.predefined-reports');
                } else if (isCustomItem.name === 1) {
                    isCustomItem.nameTranslate = this.translate.instant('custom-report-list.custom-reports');
                }
            }
        }
    }
  }

  selectTypeOperation(event:any, type:string) {
    let item : any  = event.source;
    let that : any = this.customReportList;
    let listFilter : any = that[type + 'ListFilter'];
    that[type + 'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if (that[type + 'Filter']) {
        that[type + 'Filter'].hasFilter = that[type + 'ListFilter'].length;
    }
    this.filter();
  }

  orderProductName(type:string) {
    let that  = this.customReportList;
    if (that.productNameOrder === type) {
        that.orderOperationProductName = !that.orderOperationProductName ;
    } else {
        that.productNameOrder = type;
        that.orderOperationProductName  = false;
    }
  }

  filter(filterType:string='') {
    let that : any = this.customReportList;
    let filterList : any = this.reportList;
    if(filterType && that[filterType+'WordFilter']){
      filterList = this.filterColumnService.filterByWord(that[filterType+'WordFilter'], filterList, filterType);
    }

    for (let filter of that.filters) {
        let filteredList : any = that[filter + 'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    }

    that.filterReportList = filterList;
  }

  resetFilter(e:any, filterType:string) {
    e.stopPropagation();
    e.preventDefault();
    let that = this.customReportList;
    if (filterType === 'reportName') {
        that.reportNameWordFilter = '';
    } else if (filterType === 'reportFields') {
        that.reportFieldsWordFilter = '';
    }
    this.filter(filterType);
  }

  goToDelete(e:any, customReport:any) {
    let text : string = this.translate.instant('custom-report-list.question-delete-custom-report-one') + customReport.productName + this.translate.instant('custom-report-list.question-delete-custom-report-two');
    this.modalService.adviceModal(text, 'error:question:question')
      .subscribe((result:any) => {
          if(result.result !== 'cancel'){
            this.deleteCustomReport(e, customReport);
          }
      });
  }

  deleteCustomReport(e:any, customReport:any) {
    this.loader.openLoader('custom-report-list-delete');
    this.apiService.delete('customreports', customReport.reportId)
    .then(() => {
        this.deleteCustomReportInList(customReport);
        let text : string = this.translate.instant('custom-report-list.custom-report-deleted');
        this.resetFilter(e, 'reportName');
        this.resetFilter(e, 'reportFields');
        this.loader.closeLoader('custom-report-list-delete');
        this.modalService.adviceModal(text, 'info:accept:check-round')
      }, () => {
        this.loader.closeError()
          this.modalService.adviceModal(this.translate.instant('custom-report-list.deleted-error'), 'error:accept:warning');
      });
  }

  deleteCustomReportInList(customReport:any) {
    for(let [index, listElement] of this.reportList.entries()){
        if (customReport.reportId === listElement.reportId && customReport.isCustom === listElement.isCustom) {
            this.reportList.splice(index, 1);
            break;
        }
    }
  }

  showFieldAssignments(event:any) {
    let that = this;
    let url = 'customreportfields/getcustomreportfieldassignments/';
    if (!event.isCustom) {
        url = 'customreportfields/getreportfieldassignments/'
    }
    that.loader.openLoader('field-assignments');
    that.apiService.get(url + event.id)
    .then((data:any) => {
        this.loader.closeLoader('field-assignments');
        that.modalService.fieldAssignments(data);
      }, (error:any) => {
        this.loader.closeError()
      });
  }

}
