import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ContentTypeToNameFactory } from '@shared/factories/content-type-to-name/content-type-to-name.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'all-documentation',
  templateUrl: './all-documentation.component.html'
})
export class AllDocumentationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  productId: string = this.route.snapshot.params['productId'];
  filters: any = ['clientName','evaluationName','creador', 'origen', 'estado', 'contentType', 'tipoConsulta', 'usuario', 'gestor', 'relevancia', 'etiquetas', 'materia'];
  allDocumentation: any = {
    includeDeletedDocuments: false,
    someSelect: false,
    consultDate: '',
    searchText: '',
    //searchedText: '',
    openMenu: false,
    textCollapse: this.translate.instant('result-search.collapse-results'),
    orderType: 'creacion',
    orderDirection: true,
    chargedDocumentation: false,
    currentPage: 1,
    // pageSize: 10,
    configPagination: {
      pageSize: 10
    },
    showContent: true,
    includeContent: false,
    indexedSearch: this.context.user.indexedSearchs,
    showIncludeContentPill: this.skin === 'steel-blue'?false:true,
    menu: {},
    consultDateField: {
      label: this.translate.instant('all-documentation.consult-date'),
      id:"consult-date-field",
      fromTo:true
    },
    documents: [],
    filterDocumentationList: [],
    noDeletedDocuments: [],
  }
  constructor(private route: ActivatedRoute,
    private apiProperties: ApiPropertiesFactory,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private contentTypeToName: ContentTypeToNameFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private filterColumnService: FilterColumnService,
    private modalService: ModalServiceService,
    private documentActions: DocumentActionsService) { }

  ngOnInit(): void {
    this.getDocumentation();
  }

  getDocumentation(){
    this.loader.openLoader('front-login-login');
    let url = this.skin === 'icam-red'?'icam/alldocuments':'documentobjects/alldocuments/'+ this.productId;
    this.apiService.get(url, false)
      .then((data: any)=> {
          this.allDocumentation.documents = data?data:[];
          this.allDocumentation.noDeletedDocuments = this.extractNotDeleted();
          this.allDocumentation.filterDocumentationList =  this.allDocumentation.includeDeletedDocuments? this.allDocumentation.documents: this.allDocumentation.noDeletedDocuments;
          this.getExpandedMenu();
          this.setExtension();
          this.getFilters();
      });
  }

  extractNotDeleted(){
      let array: any = [];
      this.allDocumentation.documents.forEach((document: any) => {
        if(!document.isDeleted ){
            array.push(document);
        }
      });
      return array;
  }

  getExpandedMenu() {
    this.apiService.getExpandedMenu()
    .then((data: any)=> {
        this.allDocumentation.menuDocuments = data['menuAllDocument'].menu;
        this.loader.closeLoader(['front-login-login']);
    });
  }

  setExtension(){
    this.allDocumentation.documents.forEach((document: any) => {
      document.extension = document.name?document.name.substring(document.name.indexOf('.')+1, document.name.length): '';
    })
  }

  getFilters(){
    let documents = this.allDocumentation.includeDeletedDocuments? this.allDocumentation.documents: this.allDocumentation.noDeletedDocuments;
    this.allDocumentation.consultDateEvaluated = '';
    this.filters.forEach((filter: any) => {
        this.allDocumentation[filter+'Field']={
            id:filter+"-field",
            label: this.translate.instant('all-documentation.'+filter),
            required: false,
            multiple: true,
            hiddenPill: true
        }

        let withComma = (filter === 'etiquetas' || filter === 'materia')
        this.allDocumentation[filter+'List'] =  this.commonUtilsService.editList(this.filterColumnService.extractList(filter, documents, withComma), 'name', 'name');
        this.allDocumentation[filter+'ListFilter'] = [];
        this.allDocumentation[filter+'Evaluated'] = '';
        this.allDocumentation.someSelected = false;
        if(filter === 'contentType'){
            this.changeContentTypeToName(this.allDocumentation[filter+'List']);
        }
    });

    if(this.skin!=='icam-red'){
        this.allDocumentation.creadorField.label = this.translate.instant('result-search.authors');
        this.allDocumentation.contentTypeField.label  = this.translate.instant('result-search.formats');
        this.allDocumentation.estadoField.label = this.translate.instant('result-search.states');
        this.allDocumentation.origenField.label = this.translate.instant('result-search.origins');
        this.allDocumentation.clientNameField.label = this.translate.instant('common.organizations');
        this.allDocumentation.evaluationNameField.label = this.translate.instant('common.implantations');
    }
    this.allDocumentation.chargedDocumentation = true;
  }

  changeContentTypeToName(list: any){
    list.forEach((filterList: any) => {
      if (this.contentTypeToName.mapped.get(filterList.text) !== undefined) {
          filterList.text = this.translate.instant(this.contentTypeToName.mapped.get(filterList.text));
      } else {
          filterList.text = this.translate.instant('content-type.other') + ' (' + filterList.text + ')';
      }
    })
  }

  findSomeSelect(){
      for(let filter of this.filters){
          if(this.allDocumentation[filter+'Evaluated'] !== ''){
              return true
          }
      }
      return false;
  }

  filterOrReset(){
      if(this.allDocumentation.searchText !== '' && this.allDocumentation.searchText !== this.allDocumentation.searchedText){
          this.allDocumentation.searching = true;
          this.filterDocuments();
      }else{
          this.allDocumentation.searchText = '';
          this.allDocumentation.searching = false;
          this.filterDocuments();
      }
  }

  filterOrResetEnter(e: any){
      if(e.keyCode === 13 && this.allDocumentation.searchText !== '' && this.allDocumentation.searchText !== this.allDocumentation.searchedText){
          this.allDocumentation.searching = true;
          this.filterDocuments();
      }
  }

  filterDocuments() {
      this.allDocumentation.searchedText = cloneDeep(this.allDocumentation.searchText);
      let documents : any = this.allDocumentation.includeDeletedDocuments? this.allDocumentation.documents: this.allDocumentation.noDeletedDocuments;
      this.allDocumentation.includeContent && this.allDocumentation.searchText !== ''? this.getIndexesDocuments(documents): this.configureFilterDocuments(documents);
  }

  getIndexesDocuments(documents: any){
    this.loader.openLoader('all-documentation-get-indexes');
    this.apiService.get('search/indexed/' + this.productId + '?text=' + encodeURIComponent(this.allDocumentation.searchText))
      .then((data:any)=> {
          this.loader.closeLoader(['all-documentation-get-indexes']);
          this.allDocumentation.documentsIndexes = []
          documents.forEach((document: any) => {
            let type : any = document.reportObjectId != null? 'rep-'+document.reportObjectId : 'doc-'+document.documentObjectId;
            if(data[type] && data[type].highlights[0]){
                document.highlights = '...'+data[type].highlights[0]+'...';
                this.allDocumentation.documentsIndexes.push(document);
            }
          });
          this.configureFilterDocuments(documents);
      }, (errorData:any)=> {
          this.loader.closeError();
          this.configureFilterDocuments(documents);
          this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
      });
  }

  configureFilterDocuments(documents: any){
    let searchText: string = this.allDocumentation.searching?this.allDocumentation.searchText:'';
    let filterList: any = this.filterColumnService.filterByWord(searchText, documents, 'name');
    this.filters.forEach((filter: any) => {
      let filteredList : any = this.allDocumentation[filter+'ListFilter'];
      let withComma : any = (filter === 'etiquetas' || filter === 'materia')
      filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter, withComma);
    });
    if(this.allDocumentation.consultDateEvaluated && this.allDocumentation.consultDateEvaluated.indexOf(' - ')!== -1){
        let dates: any  = this.allDocumentation.consultDateEvaluated.split(' - ');
        let from : any = this.commonUtilsService.convertShortDate(dates[0]);
        let to : any = this.commonUtilsService.convertShortDate(dates[1]);
        filterList = this.filterColumnService.filterByCalendarFromTo(filterList, from, to, 'fechaConsulta');
    }

    this.allDocumentation.filterDocumentationList = this.allDocumentation.includeContent && this.allDocumentation.documentsIndexes? this.addIndexesDocuments(filterList) : filterList;
    this.getNumElements();
  }

  addIndexesDocuments(filterList: any){
    this.allDocumentation.documentsIndexes.forEach((document: any) => {
      let findIndexes : any = false;
      for(let filterItem of filterList){
          if((document.reportObjectId === filterItem.reportObjectId && document.documentObjectId === filterItem.documentObjectId)){
              findIndexes = true;
              break;
          }
      }
      if(!findIndexes){
          filterList.push(document);
      }
    });
    return filterList;
  }

  getNumElements() {
    this.filters.forEach((filter: any) => {
      let withComma : any = (filter === 'etiquetas' || filter === 'materia');
      this.allDocumentation[filter+'List'].forEach((item: any) => {
        if(item.selected){
            item.numElements = this.filterColumnService.filterBycolumn(this.allDocumentation.filterDocumentationList, [item], filter, withComma).length;
        }
        if(item.numElements === 0 || !item.selected){
            delete item.numElements;
        }
      });
    });
  }

  itemSelected(item: any, type: any){
    this.allDocumentation[type+'Evaluated'] = item;
    if(type !== 'consultDate'){
        this.allDocumentation[type+'ListFilter'] = [];
        this.allDocumentation[type+'List'].forEach((document: any) => {
          if(document.selected){
              this.allDocumentation[type+'ListFilter'].push(document);
          }
        });
    }
    this.filterDocuments();
    this.allDocumentation.someSelected = this.findSomeSelect();
  }

  buttonMenu(action: any, result: any) {
    switch (action) {
        case 'download':
            if(this.skin !== 'icam-red'){
                let path : any  =  (result.editPath).replace('centinela/ComplianceDESARROLLO/', '');
                result.editPath = path;
            }
            this.documentActions.doAction(action, result);
            break;
        case 'open':
            this.documentActions.doAction(action, result);
            break;
        case 'edit-local':
            this.editLocal(result);
            break;
        case 'origin':
            if(this.skin === 'icam-red'){
                this.goToOrigin(result);
            }else{
              this.goToOriginOthers(result);
            }
            break;
        case 'restore':
            this.restore(result);
            break;
    }
  }

  editLocal(document: any) {
    let protocol: any = null;
    let extension: any = document.extension;
    extension = extension.toUpperCase();
    switch (extension) {
        case 'DOC':
        case 'DOCX':
            protocol = 'ms-word';
            break;
        case 'XLS':
        case 'XLSX':
            protocol = 'ms-excel';
            break;
        case 'PPT':
        case 'PPTX':
            protocol = 'ms-powerpoint';
            break;
        default:
            protocol = '';
            break;
    }
    if (protocol) {
        let path : any = (document.editPath).replace("centinela/", "");
        let spSite : any = document.sharepointSiteUrl || this.apiProperties.documentDepotUri;
        let appUri : any = protocol + ':ofe|u|' + spSite + document.documentLibraryName + '/' + path;
        window.open(appUri);
        this.apiService.add('documentobjects/logedit/'+document.documentObjectId).then((data:any)=> {}, (errorData:any)=> {});
    } else {
        console.log("Local edit: Unknow extension: " + document.extension);
    }
  }

  goToOrigin(document: any) {
    let documentPath : any = document.editPath.split('/')
    let path : any  = 'evaluation/' + documentPath[1] +
      '/folder/' + documentPath[2] +
      '/concept/' + (documentPath[3].replace('C', '')) +
      '/concept-evaluated/' + documentPath[4] +'/documentation';
    window.open(path, '_blank');
  }

  goToOriginOthers(document: any) {
    document.conceptObjectId !== null?this.goToConcept(document):this.goToReport(document);
  }

  goToConcept(concept: any) {
    if(concept.ProductId === 'blanqueo' && concept.EvaluationId === -1){
        this.goToConceptWindow(concept);
    }else{
      window.open('/evaluation/' + concept.evaluationId +
          '/folder/' + concept.folderId +
          '/concept/' + concept.conceptId +
          '/concept-evaluated/' + concept.conceptObjectId, '_blank');
    }
  }

  goToConceptWindow(concept: any){
      if(concept.ClientId !== -1){
          window.open('/client/'+ concept.ClientId, '_blank');
      }else{
          window.open('/obligated-subject', '_blank');
      }
  }

  goToReport(report: any) {
    let url : any =  report.reportTypeId <= 0?'/evaluation/' + report.evaluationId+'/reports' : '/evaluation/' + report.evaluationId + '/reports/' + report.reportId + '/report-related/' + report.reportObjectId;
    window.open(url, '_blank');
  }

  restore(result: any) {
    this.apiService.patch('documentobjects/fromrecyclebin/' + result.documentObjectId)
      .then((data:any)=> {
          result.isDeleted = false;
          this.refreshCurrentResultList(result, 'documentObjectId', 'isDeleted');
      }, (errorData:any)=> {
      });
  }

  refreshCurrentResultList(result: any, typeId: string, type: string){
    for(let originalResult of this.allDocumentation.currentResults.Results){
        if(result[typeId] === originalResult[typeId]){
            originalResult[type] = result[type];
            break;
        }
    }
  }

  replaceToColor(text: any, highLights: any=false){
    let className : any = highLights? 'result-search__highlights-text--red': 'result-search__name-document--red'
    if(text){
        return text.toLowerCase().replaceAll(this.allDocumentation.searchText, '<span class="'+className+'">'+this.allDocumentation.searchText+'</span>')
    }
    return '';
  }

  collapseResult(){
    this.allDocumentation.showContent = !this.allDocumentation.showContent;
    this.allDocumentation.textCollapse = this.translate.instant('result-search.expand-results');
    this.allDocumentation.configPagination.pageSize = 25;
    if(this.allDocumentation.showContent){
        this.allDocumentation.textCollapse = this.translate.instant('result-search.collapse-results');
        this.allDocumentation.configPagination.pageSize = 10;
    }
  }

  deleteFilters() {
    this.getFilters();
    this.allDocumentation.searchText = '';
    this.filterDocuments();
  }

  changeOpen(result: any){
    result.openMenu = !result.openMenu;
    if(result.openMenu){
      this.getFileExtension(result);
    }
  }

  getFileExtension(result: any) {
    result.menuDocuments = [];
    this.allDocumentation.menuDocuments.forEach((menuDocument: any) => {
      if(this.showOptionMenu(result, menuDocument)){
        result.menuDocuments.push(menuDocument);
      }
    });
  }

  showOptionMenu(result: any, optionMenu: any){
    switch(optionMenu.action){
        case 'download':
        case 'open':
        case 'origin':
            return !result.isDeleted;
        case 'edit-local':
            optionMenu.icon = this.commonUtilsService.getFileExtension(result.extension);
            let showOption = !result.isDeleted && optionMenu.icon !== 'hide-icon' && this.skin === 'dark-blue';
            return showOption;
        case 'restore':
            return result.isDeleted && this.skin !== 'icam-red';
        default:
            return !result.isDeleted;
    }
  }

  changeContent(newValue: any) {
      this.allDocumentation.includeContent = newValue;
      this.allDocumentation.orderType = 'Relevance';
      if(this.allDocumentation.searchText === this.allDocumentation.searchedText && this.allDocumentation.searching){
          this.filterDocuments();
      }
  }

  changeDeleted(newValue: any) {
    this.allDocumentation.includeDeletedDocuments = newValue;
    this.filterDocuments();
    this.getFilters();
  }

  changeView(){
      this.commonUtilsService.goToStateUrl();
  }

  orderBy(type: any) {
    if (this.allDocumentation.orderType === type) {
        this.allDocumentation.orderDirection = !this.allDocumentation.orderDirection;
    } else {
        this.allDocumentation.orderType = type;
        this.allDocumentation.orderDirection = type==='creacion';
    }
  }
}
