import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidden'
})
export class HiddenPipe implements PipeTransform {

  transform(items: any, showHidden:any ): any {
    if(showHidden){
        return items;
    }else{
        return items.filter((item: any)=> !item.hidden);
    }
  }

}
