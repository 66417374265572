import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'wizard-steps',
  templateUrl: './wizard-steps.component.html'
})
export class WizardStepsComponent implements OnChanges {
  @Input() steps: any[] = [];
  @Input() state: number = 1;

  ngOnChanges(changes: SimpleChanges): void {
    if(this.steps?.length && this.state){
        let filtered= [];
        for(let i = 0; i < this.steps.length; i++){
            if(!this.steps[i]?.noEditable){
                this.steps[i].originalStep = i+1;
                this.steps[i].index = filtered.length+1;
                filtered.push(this.steps[i]);
            }else{
                delete this.steps[i].index;
            }
        }
    }
  }

}

