<div class="rename-modal">
  <div *ngIf="!isLanding">
    <div class="common__modal-header-block">
        <i [class]="renameModal.iconHeader + ' common__icon-header'"></i>
        <span class="common__text-header">{{newItem.title}}</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <p [ngShow]="renameModal.infoDescription"
        class="rename-modal__related-form-info">{{renameModal.infoDescription}}</p>
    <form class="rename-modal__form"
        [ngClass]="{'rename-modal__form--related':renameModal.infoDescription}">
        <div class="rename-modal__block-body">
            <field-type-text-simple [ngClass]="{'rename-modal__name-text': renameModal.suffix}"
                [field]="renameModal.nameInfo"
                [preSelectItem]="renameModal.name"
                (itemSelected)="itemSelected($event, 'name')"
                (itemSelectedEnter)="itemSelectedEnter($event, 'name')">
            </field-type-text-simple>
            <field-type-text-simple class="rename-modal__suffix-text"
                [ngClass]="{'common__hide': !renameModal.suffix}"
                [field]="renameModal.suffixInfo"
                [preSelectItem]="renameModal.suffix"
                (itemSelected)="itemSelected($event, 'suffix')">
            </field-type-text-simple>
            <div class="rename-modal__error-block">
                <span class="rename-modal__error-name"
                    [ngShow]="renameModal.errorName || renameModal.errorNameDocument">
                    {{renameModal.errorType}}
                </span>
            </div>
        </div>
    </form>
    <div class="common__buttons-block common__buttons-block--modal-2">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="validateFields()"
            [afTranslate]="'common.ok'">Aceptar</button>
    </div>
  </div>
  <div *ngIf="isLanding">
      <div class="common__block-icon-close-modal-custom">
          <button class="lf-icon-close-round common__icon-close-modal-custom"
              (click)="handleDismiss()"></button>
      </div>
      <div class="common__modal-header-block common__modal-header-block--custom-margin"
          [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}">
          <i [class]="renameModal.iconHeader+ ' common__icon-header common__icon-header--custom'"></i>
          <span class="common__text-header common__text-header--custom">{{newItem?.title}}</span>
      </div>
      <form class="rename-modal__custom-form"
          [ngClass]="{'rename-modal__custom-form--with-info': renameModal.showInfoText}">
          <div class="rename-modal__custom-block-body">
              <p [ngShow]="renameModal.showInfoText"
                  class="rename-modal__custom-text-info">
                  <span [afTranslate]="'rename-modal.info-add-document'"
                      [ngShow]="newItem?.subtype === 'generateTemplateFromFieldPublic'">
                      _Es necesario guardar la solicitud para exportar tus datos a una plantilla. Por favor, identifica el asunto y continúa con el proceso de generación del documento.
                  </span>
                  <span [afTranslate]="'rename-modal.info-add-subgroup'"
                      [ngShow]="newItem?.subtype === 'saveBeforeSubcontainer'">
                      _Es necesario guardar la solicitud para exportar tus datos a una plantilla. Por favor, identifica el asunto y continúa con el proceso de generación del documento.
                  </span>
                  <span [afTranslate]="'rename-modal.info-save-comunication-channel'"
                      [ngShow]="newItem?.subtype === 'saveComunicationChannel'">
                      _Añade un asunto para identificar tu comunicación y finaliza el envío pulsando el botón de Aceptar. Ten en cuenta que entonces abandonarás la página y ya no podrás realizar más cambios en el formulario.
                  </span>
              </p>
              <field-type-text-simple [ngClass]="{'rename-modal__name-text': renameModal.suffix}"
                  [field]="renameModal.nameInfo"
                  [preSelectItem]="renameModal.name"
                  (itemSelected)="itemSelected($event, 'name')"
                  (itemSelectedEnter)="itemSelectedEnter($event, 'name')">
              </field-type-text-simple>
              <field-type-text-simple class="rename-modal__suffix-text"
                  [ngClass]="{'common__hide': !renameModal.suffix}"
                  [field]="renameModal.suffixInfo"
                  [preSelectItem]="renameModal.suffix"
                  (itemSelected)="itemSelected($event, 'suffix')">
              </field-type-text-simple>
              <div class="rename-modal__error-block">
                  <span class="rename-modal__error-name"
                      [ngShow]="renameModal.errorName || renameModal.errorNameDocument">
                      {{renameModal.errorType}}
                  </span>
              </div>
          </div>
          <div class="common__buttons-block common__buttons-block--modal-custom rename-modal__buttons-block-custom">
              <button type="reset"
                  class="common__button-landing-custom common__button-landing-custom--cancel"
                  [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">Cancelar</button>
              <button type="submit"
                  class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
                  [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                  (click)="validateFields()">{{renameModal.buttonAccept}}</button>
          </div>
      </form>
  </div>

</div>
