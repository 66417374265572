import { cloneDeep } from 'lodash';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { UploadDocumentParentService } from '../utils/upload-document-parent.service';

@Component({
  selector: 'upload-documents',
  templateUrl: './upload-documents.component.html'
})

export class UploadDocumentsComponent implements OnInit {
  conceptObjectId: any = this.route.snapshot.params['conceptObjectId'];
  isLanding: boolean = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  uploadDocuments: any = this.uploadDocumentParent.configureUploadDocuments(this)

  constructor(private uploadDocumentParent: UploadDocumentParentService,
    private route: ActivatedRoute,
    private customLanding: CustomLandingFactory) {

    }
  @Input() component: any= {};
  @Input() type: string = '';
  @Input() files: any = [];
  @Input() documentationList: any = null;
  @Input() itemSelected: any = null;
  @Input() listSelect: any = null;
  @Output() uploadDocumentsEmit = new EventEmitter();
  ngOnInit(): void {
    this.uploadDocuments.newConceptLanding = this.isLanding && this.itemSelected && !this.itemSelected.conceptObjectId;
    this.uploadDocuments.showProcess = this.type === 'addNewDocumentsFromProcessModal' || ((this.type === 'addNewDocuments'  || this.type === 'addNewDocumentsToFields') && this.listSelect && this.listSelect.length);
    this.uploadDocuments.processSteps.required = this.type === 'addNewDocumentsFromProcessModal' && this.uploadDocuments.showProcess;
    this.uploadDocuments.component = cloneDeep(this.component);
    this.uploadDocuments.documentationList = cloneDeep(this.documentationList);

    if(this.files.length){
      this.uploadDocumentParent.dropNewDocument(this.files, this);
    }
  }

  dropNewDocument(e:any){
    this.uploadDocumentParent.dropNewDocument(e.files, this)
    this.uploadDocumentsEmit.emit({uploadDocuments:this.uploadDocuments.upDocuments})
  }

  uploadFiles(){
    this.uploadDocumentParent.uploadFiles(this)
  }

  addDocument(document:any, firstLoad:any){
    this.uploadDocumentParent.addDocument(document, firstLoad, this, false)
  }

  loadFiles(event:any){
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.uploadDocumentParent.loadFiles(files, this)
    this.uploadDocumentsEmit.emit({uploadDocuments:this.uploadDocuments.upDocuments})
  }

  itemSelectedList(e:any, item:string){
    this.uploadDocumentParent.itemSelectedList(e.value, item, this)
  }

  openFileSearch(){
    this.uploadDocumentParent.openFileSearch()
  }

  deleteDocument(index:any){
    this.uploadDocumentParent.deleteDocument(index, this)
  }

  openTextareaModal(){
    this.uploadDocumentParent.openTextareaModal(this)
  }

  renameDocument(document:any){
    this.uploadDocumentParent.renameDocument(document, this)
  }

  uploadFilesComponent(){
    this.uploadDocumentParent.uploadFilesComponent(this)
  }
}
