import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';

@Component({
  selector: 'task-concept-list',
  templateUrl: './task-concept-list.component.html'
})
export class TaskConceptListComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  currentComponent: string = this.route.snapshot.data['componentName'];
  userLogin:any = this.context.user;
  userPermissions:any = this.permissions.getPermissions();
  taskConceptList: any = {
    canCreate: this.userPermissions === 'all',
    filterCreatedBy: '',
    titleWordFilter: '',
    dateOrder: 'creationDate',
    orderOperationDates: true,
    warningCircle: false,
    openFocusTitle: false,
    openFocusAuthor: false,
    showHeader: true,
    filters: ['type', 'createdBy', 'state'],
    typeListFilter: [],
    createdByListFilter: [],
    participantsListFilter: [],
    stateListFilter: [],
    typeFilter: {
      name: this.translate.instant('task-concept.task-type')
    },
    stateFilter: {
        name: this.translate.instant('common.state')
    },
    createdByFilter: {
        name: this.translate.instant('common.author'),
        myOptionsTitle: 'alert-concept.my-task'
    },
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private context: ContextFactory,
    private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private filterColumnService: FilterColumnService,
    private modalService: ModalServiceService,
    private convertNumbToName: ConvertNumToNameFactory,
    private registerService: RegisterMixtapeService,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService) { }

  @Input() taskList: any = null;
  @Input() processTask: any = null;
  @Input() concept: any = null;

  @Output() changeView = new EventEmitter();
  @Output() modifyTaskList = new EventEmitter();

  ngOnChanges(): void {
    this.taskConceptList.taskList = this.taskList;
    this.taskConceptList.processTask = this.processTask;
    this.taskConceptList.filterTaskList = this.taskList;
    this.configureRecipients();
  }

  getFilters(){
    let that: any = this.taskConceptList;
    that.filters.forEach((filter:any) => {
      that[filter+'List'] = this.filterColumnService.extractList(filter, that.taskList);
      if(filter === 'createdBy'){
          for(let j: number = 0; j < that[filter+'List'].length; j++){
              let filterList:any = that[filter+'List'][j];
              if(filterList.name.trim().toLowerCase() === this.userLogin.fullName.trim().toLowerCase()){
                  that[filter+'Filter'].showMyOption = {
                      visible: true,
                      name: filterList.name,
                      selected: false
                  };
                  break;
              }
          }
      }
    });
  }

  configureRecipients(){
    let that: any = this.taskConceptList;
    that.taskList.forEach((item:any) => {
      if(item.videoconference){
        item.type = this.translate.instant('task-edit.meeting-online');
      }
      if(item.participants.length){
          item.participants.forEach((participant:any) => {
              item.firstDestination = participant.fullName;
          })
      }else{
          item.firstDestination = '';
      }
    });
    this.getFilters();
  }

  filterTask(){
    let that: any = this.taskConceptList;
    let filterList:any = [];
    filterList = this.filterColumnService.filterByWord(that.titleWordFilter, that.taskList, 'title');
    that.filters.forEach((filter:any) => {
        let filteredList = that[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    });
    that.filterTaskList = filterList;
  }

  resetSearch(){
      this.taskConceptList.search = !this.taskConceptList.search;
      this.taskConceptList.searchTable = "";
  }

  orderDate(type:any){
      if(this.taskConceptList.dateOrder === type){
          this.taskConceptList.orderOperationDates = !this.taskConceptList.orderOperationDates;
      }else{
          this.taskConceptList.dateOrder = type;
          this.taskConceptList.orderOperationDates = true;
      }
  }

  selectTypeOperation(event:any, type:any){
      let item = event.source;
      let listFilter:any = this.taskConceptList[type+'ListFilter'];
      this.taskConceptList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      if(this.taskConceptList[type+'Filter']){
          this.taskConceptList[type+'Filter'].hasFilter = this.taskConceptList[type+'ListFilter'].length;
      }
      this.filterTask();
  }


  editTask(action:any, task:any = null){
      this.changeView.emit({task:action === 'editar'? task: null});
  }


  deleteTask(task:any){
      let repeatPattern: boolean  = !task.repeatPatternReadonly && task.repeatPattern && task.repeatPattern.frecuencyId;
      let text = repeatPattern? this.translate.instant('task-concept-list.the-task')+' "'+task.title+'" '+this.translate.instant('task-concept-list.pattern-settings') : this.translate.instant('dashboard.question-delete-task') +' "'+ task.title+'" ?'

      this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
        if(result.result==='ok'){
            let resgisterItem ={
              'Distinct ID': this.context.user.externalId,
              'Client ID': this.context.user.companyExternalId,
              'event':'delete task',
              'Task Type': this.convertNumbToName.taskNumbToName(String(task.taskTypeId)),
              'Concept Name': sessionStorage.getItem('breadcrumbConcept') + this.concept.title,
              'Source': 'implantation',
              'Centinela product': this.context.settings.defaultProduct
            }
            this.registerService.register(resgisterItem);

            this.loader.openLoader('task-concept-list-delete-task')
            this.deleteThisTask(task);
        }
      })
  }

  deleteThisTask(task:any){
    let that: any = this.taskConceptList;
    this.apiService.delete('tasks', task.taskId)
        .then((data:any)=>{
            if(task.taskTypeId === 3){
                // vm.concept.hasOpenEvaluationTask = false;
                this.broadcastService.broadcast('hasOpenEvaluationTask', {evaluationTask: false})
            }
            task === that.processTask? that.processTask = null : this.commonUtilsService.deleteElement( task, that.taskList, 'taskId')
            this.loader.closeLoader('task-concept-list-delete-task');
            let text = this.translate.instant('task-edit.the-task')+"'"+ task.title+"'"+this.translate.instant('task-concept-list.removed-task');
            this.modalService.adviceModal(text, 'info:accept:check-round');
            this.filterTask();
            this.modifyTaskList.emit();
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('task-concept-list.removed-error') +" '"+ task.title + "'", 'error:accept:warning');
        })
  }

  openFocus(type:any){
      this.taskConceptList['openFocus'+type] = !this.taskConceptList['openFocus'+type];
  }

  showTooltip(id:any){
      return !(this.commonUtilsService.showTooltip(id));
  }

  resetFilter(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.taskConceptList.titleWordFilter = '';
      this.filterTask();
  }

  editOrView(task:any){
      return !this.taskConceptList.canCreate || task.taskStateId === 5 || task.taskStateId === 6? this.translate.instant('common.view') : this.translate.instant('common.edit');
  }

}
