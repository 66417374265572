import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tree-list-filials',
  templateUrl: './tree-list-filials.component.html'
})
export class TreeListFilialsComponent implements OnChanges {
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']

  constructor(private route: ActivatedRoute){

  }
  @Input() field: any = null;
  @Input() findDropdown: any = '';
  @Input() fieldToFind: any = '';
  @Input() filterList: any = [];
  @Input() orderTree: any = 0;

  treeListFilials: any ={
    field: {},
    findDropdown: '',
    fieldToFind: '',
    filterList: [],
    orderTree: 0,
    paddingTree: 28
  }

  @Output() treeMultipleEmit = new EventEmitter();

  ngOnChanges(): void {
    this.treeListFilials.field = this.field;
    this.treeListFilials.findDropdown = this.findDropdown;
    this.treeListFilials.fieldToFind = this.fieldToFind;
    this.treeListFilials.filterList = this.filterList;
    this.treeListFilials.orderTree = this.orderTree;
    let paddingTree = this.treeListFilials.orderTree*20;
    this.treeListFilials.paddingTree += paddingTree;
  }

  launchAction(e:any, listValue: any, type:string){
    let selectedItem={
      listValue: listValue,
      e: e,
      type: type
    }
    this.treeMultipleEmit.emit(selectedItem);
  }



  treeMultipleOn(event:any){
    let selectedItem={
      listValue: event.listValue,
      e: event.e,
      type: event.type
    }
    this.treeMultipleEmit.emit(selectedItem);
  }

}
