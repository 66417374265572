<div class="change-state-modal">
    <div class="common__modal-header-block">
        <i class="lf-icon-process common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'dashboard-reports.resolucion'">
        </span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <div class="change-state-modal__body-container">
        <states-process-default class="concept-form__block-states-process"
            *ngIf="changeStateModal.loaded"
            [type]="'processConcept'"
            [typeView]="'changeStateModal'"
            [concept]="changeStateModal.evaluateConcept"
            [item]="changeStateModal.evaluateConcept"
            [userPublic]="changeStateModal.userPublic"
            (changeStateModal)="handleAccept($event)">
        </states-process-default>
    </div>
</div>
