<div ngbDropdown
    [autoClose]="'outside'"
    (openChange)="changeOpen()">
    <div class="common__cell-block common__cell-block--padding-right pull-left">
        <p class="common__table-header-text common__table-header-text--ellipsis common__cursor-pointer"
          [ngClass]="{'common__table-header-text--custom': isLanding,
            'common__table-header-text--filter': !order,
            'common__table-header-text--filter-order': order}">
            <span-tooltip [text]="filterBySearch.title | afTranslate"
            ngbDropdownToggle></span-tooltip>
        </p>
        <i class="common__filter-icon lf-icon-filter-1 common__cursor-pointer"
            [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': filterBySearch.inputFilter}"
            ngbDropdownToggle>
        </i>
        <i class="common__order-by lf-icon-arrow-down"
            [ngShow]="filterOrder"
            [ngClass]="{'lf-icon-arrow-up': item.filterOrder === filterOrder && !order,
                'common__order-by--active': item.filterOrder === filterOrder}"
            (click)="orderColumn(filterOrder)"></i>
    </div>
    <div [class]="'common__select-list-with-input common__select-list-with-input--filter '
          +(top!==''? 'common__select-list-with-input--top-'+top:'')+' '+ adjustClass"
        [ngStyle]="{width: widthClass}"
        ngbDropdownMenu>
        <div class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
            <input type="text"
                class="common__dropdown-inner-input"
                [placeholder]="'common.look'| afTranslate"
                [(ngModel)]="filterBySearch.inputFilter"
                (keyup)="keyChangeInFind($event)"
                [focusWhen]="filterBySearch.openSearch">
            <i class="common__search-icon"
                [ngClass]="filterBySearch.inputFilter ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
                (click)="resetFilter($event)"></i>
        </div>
    </div>
</div>
