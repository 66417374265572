import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class ReportRecordDetailResolver implements Resolve<any> {
    url: string = 'reportobjects/byreportid'
    id: any;

    constructor(private apiService: ApiServiceService,
      private globalCfg: GlobalCfgFactory,
      private reportVersion: ReportVersionFactory) { }

    resolve(route: ActivatedRouteSnapshot) {
      if(sessionStorage.hasOwnProperty('report-version')){
        let dashboardReportsSession: any = sessionStorage.getItem('dashboardReportsSession');
        let dataSession = JSON.parse(dashboardReportsSession);
        this.id = dataSession.reportId;
        if(dataSession.from ==='folder'){
            this.url = 'reportobjects/byreportid/'+dataSession.reportId;
            this.id = dataSession.evaluationId;
        }
       return this.getData();
      }else{
        return this.getReportsVersion()
      }
    }
    
    
  
    getReportsVersion(){
      return this.apiService.get('denunc/dashboard/reports/', 'ids')
        .then((data:any)=>{
          this.reportVersion.setReportVersion(data);
          return this.getData();
        }, (errorData:any)=>{})
    }

    getData(){
      return this.apiService.get(this.url, this.id)
          .then((data:any)=>{
            return data;
          })
    }

}


