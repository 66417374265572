import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'advice-message',
  templateUrl: './advice-message.component.html'
})
export class AdviceMessageComponent {
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding? 'landing-custom' : this.globalCfg.skin;

  constructor(private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory
  ) { }

  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() typeClass: string = '';
  @Input() text:any = null;
  @Input() hideIconClose: boolean = false;
  @Output() closeAdvice = new EventEmitter();

  closeBox(){
    this.closeAdvice.emit();
  }

}
