import { IcamQueriesParentService } from './../utils/icam-queries-parent.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';


import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'icam-queries-default',
  templateUrl: './icam-queries-default.component.html'
})
export class IcamQueriesDefaultComponent implements OnInit, OnChanges {
  skin: string = this.globalCfg.skin;
  userPermissions: any = this.permissions.getPermissions();
  isLanding: any = this.customLanding.isLanding();
  // custom: any = this.customLanding.getCustom();
  icamQueries: any = {
    showTemplate: false,
    fullScreen: false,
    noPermissions: this.userPermissions==='none'
  }
  constructor(
    private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory,
    private customLanding: CustomLandingFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private icamQueriesParent: IcamQueriesParentService) {
  }

  @Input() concept:any = null;
  @Input() fullScreenBox:any = null;

  @Output() catchFullScreen = new EventEmitter();
  @Output() generateDocument = new EventEmitter();

  ngOnInit(): void {
    if(this.skin === 'dark-blue' || this.skin === 'steel-blue'){
      this.icamQueries.customHeaderTitle = this.translate.instant('icam-queries.notifications-to-requester');
      this.icamQueries.historicTableHeaderCustom = this.translate.instant('icam-queries.custom-alerts-registry');
    }else{
      this.icamQueries.customHeaderTitle = this.translate.instant('icam-queries.answer-to-query');
    }
    this.icamQueriesParent.getAnsweredQueries(this);
  }

  ngOnChanges(changes: SimpleChanges){
    this.icamQueriesParent.onChanges(changes, this)
  }

  maximizeScreen(keepClassBox:any= false){
    this.icamQueriesParent.maximizeScreen(keepClassBox, this)
  }

  showNotification(notification:any){
    this.icamQueriesParent.showNotification(notification, this);
  }

  reSendNotification(notification:any){
    this.icamQueriesParent.reSendNotification(notification, this)
  }

  showTooltip(id:any){
    return this.commonUtilsService.showTooltip(id)
  }
}
