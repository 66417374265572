<div class="available-processes">
  <div class="common__header-new-version">
      <span class="common__header-title-new-version"
            [ngShow]="!availableProcesses.search"
            [afTranslate]="'available-processes.title'"></span>
      <div class="available-processes__block-search-v3"
           [ngClass]="{'available-processes__block-search-v3--100' : availableProcesses.search}">
          <input type="text"
                 [ngShow]="availableProcesses.search"
                 class="common__search-input"
                 [(ngModel)]="availableProcesses.searchInstances"
                 placeholder="{{'common.search' | afTranslate}}"
                 [focusWhen]="availableProcesses.search">
          <button class="lf-icon-search common__icon-button common__icon-button-search"
                  [ngClass]="{'lf-icon-search': !availableProcesses.search,
                      'lf-icon-close-search': availableProcesses.search,
                      'common__icon-button-search--open': availableProcesses.search}"
                  (click)="toggleSearch()"></button>
      </div>
      <button class="common__button-border-none available-processes__tax-calendar"
              [ngShow]="!availableProcesses.search"
              (click)="downloadTaxCalendar()">
          <i class="lf-icon-calendar common__icon-button-underlined"></i>
          <span class="common__text-button-underlined"
              [afTranslate]="'available-processes.tax-calendar'"></span>
      </button>
  </div>
  <div class="common__table-block">
      <div class="common__table-new-version">
          <div class="common__table-header">
              <div class="available-processes__col">
                  <p class="common__table-header-text common__cursor-pointer"
                     [afTranslate]="'available-processes.process-header'"
                     (click)="orderTable()"></p>
                  <i class="common__order-by"
                     [ngClass]="{'lf-icon-arrow-down':(availableProcesses.colFilter === 'conceptTitle' && availableProcesses.order) || availableProcesses.colFilter !== 'conceptTitle',
                          'lf-icon-arrow-up':availableProcesses.colFilter === 'conceptTitle' && !availableProcesses.order,
                          'common__order-by--active': availableProcesses.colFilter === 'conceptTitle'}"
                     (click)="orderTable()"></i>
              </div>
          </div>
          <div class="common__table-body common__table-body--8-elements"
               [ngClass]="{'common__table-body--more-elements': processesList?.length > 8}">
              <div class="common__table-row available-processes__table-row"
                  *ngFor="let instance of processesList | byWord:availableProcesses.searchInstances:['conceptTitle'] | afOrderBy:availableProcesses.colFilter:availableProcesses.order">
                  <div class="available-processes__col available-processes__col--tooltip">
                      <div class="common__cell-block available-processes__process-cell-container">
                          <parragraph-tooltip [text]="instance.conceptTitle"></parragraph-tooltip>
                          <div ngbDropdown
                              class="available-processes__description-container"
                              *ngIf="instance.conceptDescription?.trim() !== ''">
                              <i ngbDropdownToggle
                                class="lf-icon-info-full available-processes__description-icon"></i>
                              <div ngbDropdownMenu
                                    class="common__tooltip-dropdown available-processes__description-tooltip">
                                  <span class="common__tooltip-inner-text available-processes__description-text"
                                    [innerHTML]="instance.conceptDescription"></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__block-footer-table">
      <div class="common__block-footer-total">
          <span [afTranslate]="'common.total'">_Total</span>
          <span class="common__total-number-element notranslate">{{processesList?.length}}</span>
      </div>
  </div>
</div>
