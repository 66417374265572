<div class="common__field-type">
  <label class="common__input-block common__input-no-editable"
      [for]="'field-item-formula-'+fieldTypeFormu.field.fieldId"
      [ngClass]="{'field-type-formu__input-block-formu': fieldTypeFormu.fieldEncodings && fieldTypeFormu.field.evaluatedValue?.nvarcharValue,
          'common__input-block--custom-field':isLanding}"
      [ngStyle]="{'border-color': fieldTypeFormu.field.color}"
      [attr.disabled]="fieldTypeFormu.field.noEditable?true:null">
      <div class="common__label-block common__label-block--one-icon"
          [ngShow]="!fieldTypeFormu.fieldEncodings">
          <span class="common__input-text "
              [ngClass]="{'common__input-text--required': fieldTypeFormu.field.error,
                      'common__input-text--no-editable':fieldTypeFormu.field.noEditable}">{{fieldTypeFormu.titleField}}</span>
          <div class="common__info-icon-block common__info-icon-block--visible"
              [ngHide]="fieldTypeFormu.field.noEditable || !fieldTypeFormu.field.description">
              <tooltip-info [description]="fieldTypeFormu.field.description"
                  [field]="fieldTypeFormu.field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
              [ngShow]="fieldTypeFormu.field.references.length && !fieldTypeFormu.field.noEditable">
              <tooltip-law [references]="fieldTypeFormu.field.references"
                  [field]="fieldTypeFormu.field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeFormu.field.relatedInfo.length && !fieldTypeFormu.field.poll && !fieldTypeFormu.field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(fieldTypeFormu.field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
          <div class="common__info-icon-block common__info-icon-block--in-report"
            *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
            <tooltip-in-report [inReport]="fieldTypeFormu.inReport"></tooltip-in-report>
          </div>
      </div>
      <div class="field-type-formu__formula"
          [ngShow]="fieldTypeFormu.fieldEncodings">
          <div class="field-type-formu__circle"
              [ngClass]="{'field-type-formu__circle--custom':isLanding}"
              [ngStyle]="{'background-color': fieldTypeFormu.field.color}"></div>
          <div class="field-type-formu__block-label-encodings"
              [ngClass]="{'field-type-formu__block-label-encodings--custom':isLanding}">
              <div class="field-type-formu__text-label-formula field-type-formu__text-label-formula--no-editable">
                  <span [ngClass]="{'field-type-formu__custom-label':isLanding}">
                    {{fieldTypeFormu.titleField}}:
                  </span>
                  <div [ngShow]="fieldTypeFormu.field.description !== ''"
                      class="common__info-icon-block common__info-icon-block--visible">
                      <tooltip-info [description]="fieldTypeFormu.field.description"
                          [field]="fieldTypeFormu.field"></tooltip-info>
                  </div>
                  <div class="common__tooltip-law"
                      [ngClass]="{'common__tooltip-law--custom':isLanding}"
                      [ngShow]="fieldTypeFormu.field.references.length">
                      <tooltip-law [references]="fieldTypeFormu.field.references"
                          [field]="fieldTypeFormu.field"
                          class="field-type-text__tooltip-block">
                      </tooltip-law>
                  </div>
                  <span [id]="'field-item-formula-'+fieldTypeFormu.field.fieldId"
                      class="common__input-content common__input-content-no-editable field-type-formu__result-text-formu"
                      [ngClass]="{'common__input-content--description-formu': fieldTypeFormu.field.description}">
                      <span *ngIf="fieldTypeFormu.field.showValueWithCode && fieldTypeFormu.field.evaluatedValue?.nvarcharValue">{{fieldTypeFormu.fieldEvaluatedValue}} - </span>
                      {{fieldTypeFormu.field.evaluatedValue?.nvarcharValue}}
                  </span>
              </div>
          </div>
      </div>
      <div [ngShow]="!fieldTypeFormu.field.fieldEncodings.length">
          <i class="lf-icon-calculator common__input-icon common__input-icon--color field-type-formu__input-icon-formu"
              [ngClass]="{'common__input-icon--custom-icon':isLanding}"></i>
          <input [ngShow]="fieldTypeFormu.fieldEvaluatedValue !== undefined"
              [id]="'field-item-formula-'+fieldTypeFormu.field.fieldId+'-'+fieldTypeFormu.field.numSubContainer"
              [name]="'field-item-formula-'+fieldTypeFormu.field.fieldId+'-'+fieldTypeFormu.field.numSubContainer"
              class="common__input-content common__input-content-no-editable"
              type="text"
              readonly
              [value]="fieldTypeFormu.fieldEvaluatedValue">
      </div>
  </label>
</div>
