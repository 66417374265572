import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'change-state-modal',
  templateUrl: './change-state-modal.component.html'
})
export class ChangeStateModalComponent implements OnInit {
  changeStateModal: any = {
    userPublic: (this.context.user && !this.context.user.email)
  }
  constructor(private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }

  @Input() evaluateConcept: any = null;

  ngOnInit(): void {
    this.changeStateModal.evaluateConcept = this.evaluateConcept;
    this.getProcess()
  }

  getProcess(){
    let that: any = this.changeStateModal
    let url = 'processesdata/'+ that.evaluateConcept.conceptObjectId + '/summary/'+this.commonUtilsService.getLanguage()
    this.apiService.get(url)
        .then((data:any)=> {
            that.evaluateConcept.processStates = data;
            if(data){
                if(that.evaluateConcept.conceptNamedKey === 'Consulta'){
                  that.evaluateConcept.stepIsFinal = that.evaluateConcept.processStates.current.stateId0 > 3;
                  that.evaluateConcept.tooltipAlertHour = that.evaluateConcept.stepIsFinal? this.translate.instant('concept-tab.concept-finished') : this.translate.instant('concept-tab.hours').replace('{hours}', that.evaluateConcept.processingHours);
                  that.evaluateConcept.textAlertHour = that.evaluateConcept.stepIsFinal? this.translate.instant('concept-instances-list.finished') :  that.evaluateConcept.processingHours +' '+ this.translate.instant('add-alert.hours');
                  this.commonUtilsService.configureAlertState(that.evaluateConcept);
                }
                that.loaded = true;
            }
        }, ()=>{});
  }

  handleAccept(event:any) {
    let state: any = event.state;
    this.activeModal.close({
        result: 'ok',
        state:state
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
