import { Injectable } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';


import { Message } from '@shared/interface/message';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private handler: Subject<Message> = new Subject<Message>();

  broadcast(type: string, payload: any = null) {
    this.handler.next({ type, payload });
  }

  subscribe(type: string, callback: (payload: any) => void): Subscription {
    return this.handler.pipe(
        filter((message: any) => message.type === type),
        map((message: any) => message.payload))
      .subscribe(callback);
  }
}
