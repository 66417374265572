<div class="customize-field-date">
  <div [ngClass]="{'customize-fields__body-field-container': typeView === 'channelViewEditable'}">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldDate.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': customizeFieldDate.disabledFormConfiguration}"
        [(ngModel)]="customizeFieldDate.field.extendedLabel"
        [disabled]="customizeFieldDate.disabledFormConfiguration"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': customizeFieldDate.disabledFormConfiguration}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldDate.tinyMceOptions"
      [modelText]="customizeFieldDate.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text"
        *ngIf="!customizeFieldDate.channelViewConfiguration">
        <field-type-list-simple [field]="customizeFieldDate.contentReportInfo"
            [preSelectItem]="customizeFieldDate.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldDate.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldDate.field.listVisibleValues"
            [selectedFields]="customizeFieldDate.field.fieldVisibleValues"
            [orCondition]="customizeFieldDate.field.visibilityOr"
            [readonly]="customizeFieldDate.field.noEditable || customizeFieldDate.disabledFormConfiguration"
            [itemId]="customizeFieldDate.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-date [field]="customizeFieldDate.defaultValueInfo"
            [preSelectItem]="customizeFieldDate.field.defaultValue"
            (itemSelected)="itemSelected($event, 'defaultValue')"></field-type-date>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-list-extended [field]="customizeFieldDate.dateLimitsConfig"
            [listValues]="customizeFieldDate.dateLimitsConfig.values"
            [preSelectItem]="customizeFieldDate.dateLimitsConfig.selectedLimitType"
            (itemSelected)="itemSelected($event, 'dateLockType')">
        </field-type-list-extended>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare && typeView !== 'channelViewEditable'"
      [tooltipText]="'customize.private-help'"
      [model]="isPrivate?isPrivate:field.private"
      [disabled]="customizeFieldDate.disabledFormConfiguration || isPrivate"
      [fieldText]="'customize.private'"
      (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
      [model]="customizeFieldDate.field.showTime"
      [disabled]="customizeFieldDate.field.noEditable || customizeFieldDate.disabledFormConfiguration"
      [fieldText]="'customize.show-time'"
      (changeOptionEmit)="changeOptionOn($event, 'showTime')">
    </switch-option>
    <switch-option
      [model]="customizeFieldDate.field.required"
      [disabled]="customizeFieldDate.field.noEditable || customizeFieldDate.disabledFormConfiguration"
      [fieldText]="'customize.required'"
      (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
  </div>
  <div class="common__buttons-block common__buttons-block--customize"
      *ngIf="typeView !== 'channelViewNoEditable'">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="cancelFieldText()"
          [disabled]="concept.disabled && typeView !== 'channelViewEditable'"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="saveFieldText()"
          [disabled]="concept.disabled"
          [afTranslate]="'common.save'">Guardar</button>
  </div>
</div>
