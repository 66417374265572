import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'draft-evaluations',
  templateUrl: './draft-evaluations.component.html'
})
export class DraftEvaluationsComponent implements OnInit {
  evaluationId: string = this.route.snapshot.firstChild?.params['evaluationId']
  draftEvaluations: any={
    draftList: []
  }
  constructor(private apiService: ApiServiceService,
      private route: ActivatedRoute,
      private activeModal: NgbActiveModal) { }

  @Input() toOther: any = false;
  @Input() draftList: any;

  ngOnInit(): void {
    if(!this.draftList.length){
      this.getDraft();
    }else{
      this.draftEvaluations.draftList = this.draftList;
    }
  }

  getDraft(){
    this.apiService.get('conceptobjects/drafts', this.evaluationId)
        .then((data:any)=>{
          this.draftEvaluations.draftList = data;
        }, (errorData:any)=>{})
  }

  launchDraft(draft:any){
    this.toOther? this.openInOther(draft) : this.handleAccept(draft);
  }

  openInOther(draft:any){
    window.open('/evaluation/' + this.evaluationId +
    '/folder/' + draft.folderId +
    '/concept/' + draft.conceptId +
    '/concept-evaluated/' + draft.conceptObjectId, '_blank');
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept(draft: any) {
    this.activeModal.close({
        result: 'ok',
        draftConcept: draft
    })
  };
}
