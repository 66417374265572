import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from "moment";

@Component({
  selector: 'calendar-month-year',
  templateUrl: './calendar-month-year.component.html'
})
export class CalendarMonthYearComponent implements OnInit {
  selectedDateHeader: any;
  calendarMonthYear: any = {
    type: 'month',
    convertDateMonth: 'month-calendar',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    years: [],
    actualMonth: moment().month()+1,
    actualYear: moment().year()
  }
  @Input() activedMonth: any;
  @Input() activedYear: any = moment().year();
  @Input() limitCount: any=12;
  @Input() type: string = 'normal';
  @Output() navigateSelection = new EventEmitter();

  ngOnInit(): void {
    this.calendarMonthYear.convertDateMonth = this.type === 'from-to-calendar'?'month-calendar-min':'month-calendar';
    this.selectedDateHeader = moment().year();
    this.calendarMonthYear.selectedDateHeaderMonth = this.selectedDateHeader;
    this.activedMonth = this.activedMonth?this.activedMonth:this.calendarMonthYear.actualMonth;
    let middleCount = this.limitCount/2;
    for(let i = middleCount; i >= 0; i--){
        this.calendarMonthYear.years.push(this.calendarMonthYear.actualYear-i);
    }
    for(let i = 1; i <= middleCount-1; i++){
      this.calendarMonthYear.years.push(this.calendarMonthYear.actualYear+i);
    }
    this.calendarMonthYear.selectedDateHeaderYear = this.calendarMonthYear.years[0] + ' - ' + this.calendarMonthYear.years[this.calendarMonthYear.years.length-1];
  }

  move(direction:any){
    if(this.calendarMonthYear.type === 'month'){
      this.selectedDateHeader = direction==='next'?this.selectedDateHeader+1:this.selectedDateHeader-1;
      this.calendarMonthYear.selectedDateHeaderMonth = this.selectedDateHeader;
      this.activedYear = this.selectedDateHeader;
    }else{
      let years: any = [];
      this.calendarMonthYear.years.forEach((year:any) => {
        if(direction==='next'){
          years.push(year+this.limitCount);
        }else{
          years.push(year-this.limitCount);
        }
      });
      this.calendarMonthYear.years = years;
      this.calendarMonthYear.selectedDateHeaderYear = this.calendarMonthYear.years[0] + ' - ' + this.calendarMonthYear.years[this.calendarMonthYear.years.length-1];
      this.selectedDateHeader = this.calendarMonthYear.selectedDateHeaderYear;
    }
  }

  toggleMode(){
    this.calendarMonthYear.type = 'year';
    this.selectedDateHeader = this.calendarMonthYear.selectedDateHeaderYear;
  }

  selectMonth(dateSelected: any){
    let newSelectedDate = {
        day: 1,
        month: dateSelected,
        year: this.activedYear
    }
    this.navigateSelection.emit(newSelectedDate);
  }

  selectYear(dateSelected: any){
    this.activedYear = dateSelected;
    this.calendarMonthYear.type = 'month';
    this.selectedDateHeader = dateSelected;
    this.calendarMonthYear.selectedDateHeaderMonth = this.selectedDateHeader;
  }

}
