import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { AfTranslateFactory } from '../factory/translate.factory';

@Directive({
  selector: '[afSiteTerms]'
})
export class AfSiteTermsDirective {
  @Input() afSiteTerms: string = ''
  constructor(private translate: AfTranslateFactory, private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['afSiteTerms']){
      this.elementRef.nativeElement.innerHTML = this.translate.instantSiteTerms(changes['afSiteTerms'].currentValue)
    }
  }

}
