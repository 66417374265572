import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class RegisterMixtapeService {
  isLanding: any = location.pathname.indexOf('public/') !== -1 || location.pathname.indexOf('shared/') !== -1 || location.pathname.indexOf('contact-login') !== -1;
  init(): void {
    if ((window.location.href.indexOf('localhost') === -1) && !this.isLanding) {
      try {
          mixpanel.init(window.location.href.indexOf('affin') !== -1 ? "4a9be5e776aeb1513401ee768d7a5eb4" : "ac2de1661c0bed65d35b808785d452b1");
      } catch (err) {
          console.error(err)
      }
    }
  }

  register(event:any){
    if(window.location.href.indexOf('localhost') === -1 && !this.isLanding){
      mixpanel.track(event.event, event);
    }
  }

}

