import { Pipe, PipeTransform } from '@angular/core';
import { AfTranslateFactory } from '../factory/translate.factory';

@Pipe({
  name: 'afTranslate'
})
export class AfTranslatePipe implements PipeTransform {
  constructor(private translate: AfTranslateFactory){}
  transform(value: any, language:string = ''): any {
    return language ===''? this.translate.instant(value) : this.translate.instantTo(value, language)
  }
}
