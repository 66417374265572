<div class="field-type-alert">
  <div *ngIf="!isLanding"
      class="common__input-block"
      [ngClass]="{'common__show-alert': field.error,
          'common__input-no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none',
          'common__input-block--custom-field':fieldTypeAlert.custom && fieldTypeAlert.custom !== null && !field.sequentialView}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                  'common__input-text--no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none'}">
                  {{field.label}}
          </span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngClass]="{'common__tooltip-law-block--landing-custom':fieldTypeAlert.custom && fieldTypeAlert.custom !== null}"
              [ngShow]="field.references?.length && !field.noEditable && fieldTypeAlert.userPermissions !=='none'">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__container">
          <p class="field-type-template__text-description"
              [innerHTML]="field.description"></p>
          <div class="field-type-template__block-button">
              <button class="common__button-border-none"
                  [disabled]="field.noEditable || fieldTypeAlert.userPermissions ==='none'"
                  (click)="newAlert()">
                  <i class="lf-icon-notification common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'field-list.create-alert'">_Crear alerta</span>
              </button>
          </div>
          <ul class="field-type-alert__alert-container "
              [ngShow]="fieldTypeAlert.alertList.length">
              <li *ngFor="let alert of fieldTypeAlert.alertList; let last = last"
                  class="field-type-task__task-item"
                  [ngClass]="{'field-type-task__task-item--border': !last}">
                  <div class="field-type-task__task-item-menu"
                      [ngShow]="fieldTypeAlert.userPermissions !== 'none' && !field.noEditable">
                      <i class="lf-icon-editable-form field-type-task__task-item-menu-icon field-type-task__task-item-menu-icon--left"
                          matTooltip="{{'common.edit' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [ngShow]="alert.active"
                          (click)="editAlert(alert)"></i>
                      <i class="lf-icon-trash field-type-task__task-item-menu-icon"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          (click)="deleteAlert(alert)"></i>
                  </div>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'add-alert.alert'"></span>:
                      <span class="field-type-task__task-item-text-info">{{alert.title}}</span>
                  </p>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'add-alert.reference-date'"></span>:
                      <span class="field-type-task__task-item-text-info ">{{alert.referenceDateText}}</span>
                  </p>
                  <p class="field-type-task__task-item-text">
                      <span [afTranslate]="'add-alert.alert-moment'"></span>:
                      <span class="field-type-task__task-item-text-info ">{{alert.momentText}}</span>
                  </p>
                  <p class="field-type-task__task-item-text" [ngHide]="!alert.repeat">
                      <span [afTranslate]="'alert-detail.repeat'"></span>:
                      <span class="field-type-task__task-item-text-info ">{{alert.repeatText}}</span>
                  </p>
              </li>
          </ul>

      </div>
    <div class="field-item__blocker-box"
        *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </div>
  <div *ngIf="isLanding"
    class="common__input-block field-type-template__custom-input-block-field"
      [ngClass]="{'field-type-template__custom-input-block-field--required': field.error,
          'field-type-template__custom-input-block-field--no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none'}">
      <div class="common__label-block">
          <i class="lf-icon-notification field-type-template__custom-icon-type-header"
              [ngClass]="{'field-type-template__custom-icon-type-header--required': field.error,
                  'field-type-template__custom-icon-type-header--no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none'}"></i>
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                      'common__input-text--no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none'}">{{field.label}}</span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngClass]="{'common__tooltip-law-block--landing-custom':fieldTypeAlert.custom && fieldTypeAlert.custom !== null}"
              [ngShow]="field.references?.length && !field.noEditable && fieldTypeAlert.userPermissions !=='none'">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
      </div>
      <div class="field-type-template__custom-container">
          <p class="field-type-template__text-description"
              [ngClass]="{'field-type-template__text-description--required': field.error,
                  'field-type-template__text-description--no-editable':field.noEditable || fieldTypeAlert.userPermissions ==='none'}"
              [innerHTML]="field.description"></p>
          <div class="field-type-template__block-button">
            <button type="submit"
                class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last common__button-landing-custom--disabled"
                disabled
                (click)="newAlert()"
                [afTranslate]="'field-list.create-alert'">_Crear alerta</button>
          </div>
          <ul class="field-type-alert__block-list-alert"
              [ngShow]="fieldTypeAlert.alertList.length">
              <li *ngFor="let alert of fieldTypeAlert.alertList"
                  class="field-type-alert__custom-element-list-alert">
                  <p class="field-type-template__custom-element-list-title">
                      <span [afTranslate]="'add-alert.alert'"></span>: <span class="field-type-template__custom-element-list-value">{{alert.title}}</span>
                  </p>
                  <p class="field-type-template__custom-element-list-title">
                      <span [afTranslate]="'add-alert.reference-date'"></span>: <span class="field-type-template__custom-element-list-value">{{alert.referenceDateText}}</span>
                  </p>
                  <p class="field-type-template__custom-element-list-title">
                      <span [afTranslate]="'add-alert.alert-moment'"></span>: <span class="field-type-template__custom-element-list-value">{{alert.momentText}}</span>
                  </p>
              </li>
          </ul>
      </div>
  </div>
</div>
