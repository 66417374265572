import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { FieldTypeBoolParentService } from '../utils/field-type-bool-parent.service';

@Component({
  selector: 'field-type-bool',
  templateUrl: './field-type-bool.component.html'
})

export class FieldTypeBoolComponent implements OnChanges {
  isLanding = false;
  fieldTypeBool: any = this.fieldTypeBoolParent.configureField();

  constructor(private fieldTypeBoolParent: FieldTypeBoolParentService) {}

  @Input() preSelectItem:any = '';
  @Input() field:any = null;

  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();


  ngOnChanges(): void {
    this.fieldTypeBoolParent.onChanges(this);
  }

  selectOption(option: any){
    this.fieldTypeBoolParent.selectOption(this, option);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeBoolParent.openModalRelatedInfo(this, fieldRelatedInfo, e);
  }

}
