<div class="move-modal">
    <div class="move-modal__block-context">
        <div class="common__modal-header-block">
            <i class="lf-icon-arrows common__icon-header"></i>
            <span class="common__text-header"
                [afTranslate]="'common.move'">_Mover</span>
            <i class="lf-icon-close move-modal__icon-close-modal"
                (click)="handleDismiss()"></i>
        </div>
        <div [ngShow]="moveModal.showBlockError"
            class="move-modal__block-error-create-folder">
            <i class="lf-icon-warning move-modal__icon-warning"></i>
            <span class="move-modal__text-error"
                [afTranslate]="'move-modal.error-create'">_No se ha podido crear la carpeta. Por favor intentalo de nuevo</span>
            <i class="lf-icon-close move-modal__icon-close-error"
                (click)="moveModal.showBlockError = !moveModal.showBlockError"></i>
        </div>
        <div class="move-modal__block-body"
            [ngClass]="{'move-modal__block-body--error': moveModal.showBlockError}">
            <div class="move-modal__block-button-create-folder"
                [ngClass]="{'move-modal__block-button-create-folder--new':moveModal.showNewFolder}"
                [ngHide]="moveModal.showBlockError">
                <div [ngShow]="moveModal.showNewFolder">
                    <input type="text"
                        class="move-modal__input-new-folder"
                        [ngClass]="{'move-modal__input-new-folder--error':moveModal.errorNameFolder}"
                        placeholder="'common.search-title' | afTranslate"
                        [(ngModel)]="moveModal.nameFolder"
                        [focusWhen]="moveModal.showNewFolder">
                    <button class="lf-icon-close common__button-close"
                        (click)="moveModal.showNewFolder = !moveModal.showNewFolder"></button>
                    <button class="lf-icon-check common__button-checked"
                        (click)="newFolder()"></button>
                </div>

                <button class="move-modal__button-new-folder"
                    [ngShow]="!moveModal.showNewFolder"
                    (click)="moveModal.showNewFolder = !moveModal.showNewFolder">
                    <i class="common__icon-button-underlined lf-icon-folder-new"></i>
                    <span class="common__text-button-underlined"
                        [afTranslate]="'expanded-menu.new-folder'">_Nueva carpeta</span>
                </button>
                <span class="rename-modal__error-name"
                    [ngShow]="moveModal.errorNameFolder"
                    [afTranslate]="'move-modal.error-name'">El nombre de la carpeta ya existe en esta ubicación</span>
            </div>
            <div class="move-modal__block-tree"
                [ngClass]="{'move-modal__block-tree--error': moveModal.showBlockError}">
                <p class="move-modal__title-tree"
                    [afTranslate]="'move-modal.select-folder'">_Selecciona carpeta de destino</p>
                <div class="move-modal__block-none-folder"
                    [ngShow]="!moveModal.treeFolder.length">
                    <i class="move-modal__icon-big-folder lf-icon-folder"></i>
                    <p class="move-modal__text-none-folder"
                        [afTranslate]="'move-modal.no-folders-found'">_No se han encontrado carpetas</p>
                </div>
                <div *ngIf="moveModal.treeFolder.length"
                    class="move-modal__block-tree-folders">
                    <div class="move-modal__block-title-product"
                        [ngClass]="{'duplicate-modal__block-title-product--disabled':moveModal.headerTree.disabled}"
                        (click)="documentationSelected()">
                        <i class="lf-icon-documentation"></i>
                        <span class="move-modal__title-product move-modal__title-product--move "
                            [ngClass]="{'duplicate-modal__title-product--selected':moveModal.headerTree.documentationCheked}">
                            {{moveModal.headerTree.name}}</span>
                    </div>
                    <div *ngFor="let folder of moveModal.treeFolder">
                        <div class="move-modal__block-first-level">
                            <i class="duplicate-modal__icon-arrow"
                                [ngClass]="{'lf-icon-angle-down':folder.show ,
                                    'lf-icon-angle-right':!folder.show ,
                                    'duplicate-modal__icon-arrow--visibility':folder.subFolders.length && !folder.disabledChildren}"
                                (click)="folder.show = !folder.show"></i>
                            <div class="duplicate-modal__block-name-evaluation"
                                [ngClass]="{'duplicate-modal__block-name-evaluation--disabled':folder.disabled }"
                                (click)="selectChildren(folder)">
                                <i class="duplicate-modal__icon duplicate-modal__icon--evaluation"
                                    [ngClass]="folder.show?'lf-icon-folder-open':'lf-icon-folder'"></i>
                                <span class="move-modal__title-folder"
                                    [ngClass]="{'move-modal__title-selected':folder.selected,
                                    'move-modal__title-folder--show':folder.show}">
                                    {{folder.name}}
                                </span>
                            </div>
                            <branch-tree [childrenList]="folder.subFolders"
                                [type]="'move'"
                                [level]="'first'"
                                (selectChildrenEmit)="selectChildrenOn($event)"
                                [ngShow]="folder.show">
                            </branch-tree>
                        </div>

                    </div>
                </div>
            </div>
            <div class="common__buttons-block common__buttons-block--modal">
                <button type="reset"
                    class="common__button common__button--clean"
                    (click)="handleDismiss()"
                    [afTranslate]="'common.cancel'">_Cancelar</button>
                <button type="submit"
                    class="common__button common__button--active"
                    (click)="moveItem()"
                    [disabled]="!moveModal.treeFolder.length || (itemSelected.sharepointFolderId && itemSelected.sharepointFolderId === moveModal.selectFolder?.folderId)
                    || (itemSelected.parentFolderId && itemSelected.parentFolderId === moveModal.selectFolder?.folderId)
                    || (moveModal.selectFolder === null && !itemSelected.sharepointFolderId && !itemSelected.parentId)"
                    [afTranslate]="'common.move'">_Mover</button>
            </div>
        </div>
    </div>
</div>
