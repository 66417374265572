import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tree-list-simple',
  templateUrl: './tree-list-simple.component.html'
})
export class TreeListSimpleComponent implements OnChanges  {
  conceptObjectId:string = this.route.snapshot.params['conceptObjectId']
  constructor(private route: ActivatedRoute){}
  treeListSimple: any ={
    field: null,
    findDropdown: '',
    fieldToFind: '',
    filterList: []
  }

  @Input() field: any = null;
  @Input() findDropdown: any = '';
  @Input() fieldToFind: any = '';
  @Input() filterList: any = [];

  @Output() treeSimpleEmit = new EventEmitter();

  ngOnChanges(){
      this.treeListSimple.field = this.field
      this.treeListSimple.findDropdown = this.findDropdown;
      this.treeListSimple.fieldToFind = this.fieldToFind;
      this.treeListSimple.filterList = this.filterList;
  }

  launchAction(listValue:any, e:any, type:string){
    let selectedItem={
      listValue: listValue,
      e: e,
      type: type
    }
    this.treeSimpleEmit.emit(selectedItem);
  }

  treeSimpleOn(event:any){
    let selectedItem={
      listValue: event.listValue,
      e: event.e,
      type: event.type
    }
    this.treeSimpleEmit.emit(selectedItem);
  }

}

