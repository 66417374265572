import { Directive, ElementRef, Input, Inject, OnChanges, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[focusWhen]'
})
export class FocusWhenDirective implements OnChanges{
  @Input() focusWhen: boolean;


  constructor(
      @Inject(ElementRef) private element: ElementRef,
      private changeDetectorRef: ChangeDetectorRef
  ) {}


  ngOnChanges() {
      if (this.focusWhen) {
          this.element.nativeElement.focus();
          this.changeDetectorRef.detectChanges();
      }
  }
}
