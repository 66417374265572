<div class="common__input-block expanded-textarea__input-block"
    [ngClass]="{'common__input-no-editable': field.noEditable,
      'field-type-rich-text__rich-editable-block': field.fieldTypeId === 'rich',
      'expanded-textarea__notification-personalized':field.fieldId ==='add-alert-notification-personalized',
      'common__input-block--custom-field': isLanding}"
    [attr.disabled]="field.noEditable?true:null">
    <div class="common__label-block common__label-block--one-icon">
      <span class="common__input-text"
          [ngClass]="{'common__input-text--required': field.error,
                  'common__input-text--no-editable':field.noEditable}">{{expandedTextarea.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="field.required">*</span>
      <div class="common__info-icon-block"
          [ngHide]="expandedTextarea.hideTooltipInfo">
           <tooltip-info [description]="field.description"
            [field]="field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="field.references && field.references.length && !field.noEditable">
           <tooltip-law [references]="field.references"
              [field]="field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate }}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
    </div>
    <div>
      <i class="common__input-icon common__input-icon-active expanded-textarea__rich-editable-icon lf-icon-collapse-panel"
        (click)="handleAccept($event)"></i>
      <textarea [id]="'field-item-text-'+field.id"
            class="common__input-textarea common__input-textarea--expanded"
            [ngClass]="{'common__input-content-no-editable': field.noEditable}"
            *ngIf="field.fieldTypeId !== 'rich'"
            [(ngModel)]="expandedTextarea.text"
            [readonly]="field.readonly || field.noEditable"
            [disabled]="field.noEditable">
      </textarea>
      <div class="expanded-textarea__rich-editable-editor"
        *ngIf="field.fieldTypeId === 'rich'">
        <rich-editor class="common__input-textarea-special"
            [tinyMceOptions]="expandedTextarea.tinyMceOptions"
            [modelText]="expandedTextarea.text"
            (emitValue)="expandedTextarea.text = $event.model">
        </rich-editor>
      </div>
      <i class="common__delete-content-icon expanded-textarea__delete-content-icon lf-icon-close"
          [ngHide]="expandedTextarea.text === '' || field.noEditable"
          (click)="resetInput()">
      </i>
  </div>
</div>

