import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-link',
  templateUrl: './field-type-link.component.html'
})
export class FieldTypeLinkComponent implements OnChanges {
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  custom: any = this.customLanding.getCustom();
  fieldTypeLink:any = {
    openLaw: false,
    references: false
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() maxLength: any = null;
  @Input() concept: any = null;

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();
  @Output() itemSelectedEnter = new EventEmitter();

  ngOnChanges(changes: SimpleChanges){
    let that: any = this.fieldTypeLink;
    if(changes['field'] && changes['field'].currentValue) {
      let field:any = changes['field'].currentValue
      that.field = field;
      that.titleField = field.extendedLabel && field.extendedLabel !== ''? field.extendedLabel : field.label;

      if(!field.id || field.generateId){
          field.id = this.commonUtilsService.createId();
      } else if(field.fieldId){
          field.id = field.fieldId;
      }
      let preSelectItem = changes['preSelectItem'].currentValue;
      if((preSelectItem === null || preSelectItem === undefined) && !field.defaultValue){
        that.inputText = '';
      }else if((preSelectItem === null || preSelectItem === undefined) && field.defaultValue){
        that.inputText = field.defaultValue;
        that.preSelectItem = field.defaultValue;
          this.itemSelected.emit({newValue: that.inputText});
      }else{
        that.inputText = preSelectItem;
      }
      this.validateLink();
      if(field.inReport !== null && field.inReport !== undefined){
        this.fieldTypeLink.inReport = field.inReport.indexOf('|') === -1? [field.inReport] : field.inReport.split('|')
      }
    }
    if(changes['concept'] && changes['concept'].currentValue !== null){
      that.concept = changes['concept'].currentValue;
    }
  }

  changeInField(){
    this.itemSelected.emit({ newValue: this.fieldTypeLink.inputText });
    this.validateLink();
  }

  validateLink() {
      let inputText : any = this.fieldTypeLink.inputText;
      let validateURL : any = this.commonUtilsService.validateURL(inputText);
      this.fieldTypeLink.canBrowse = (inputText != '' && validateURL);
      this.fieldTypeLink.field.error = (inputText != '' && !validateURL);
      if(this.fieldTypeLink.concept){
        this.configureErrorConcept();
      }
  }

  configureErrorConcept(){
    this.fieldTypeLink.field.errorInForm = this.fieldTypeLink.field.error;
    this.commonUtilsService.configureConceptErrorForm(this.fieldTypeLink.field, this.fieldTypeLink.concept);
  }

  browseLink() {
    if (this.fieldTypeLink.canBrowse) {
        let url : any = this.fieldTypeLink.inputText;
        if (!this.fieldTypeLink.inputText.match('^http')) {
            url = 'http://' + url;
        }
        window.open(url);
    }
  }

  resetInput(){
    this.fieldTypeLink.inputText = '';
    this.itemSelected.emit({ newValue: this.fieldTypeLink.inputText });
    this.validateLink();
  }

  showReference(e:any){
    e.stopPropagation();
    this.fieldTypeLink.references = !this.fieldTypeLink.references;
    this.fieldTypeLink.showFieldReference();
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    e.stopPropagation();
    e.preventDefault();
    this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
}
