<div *ngIf="complianceFooter.configured">
  <div class="compliance-footer"
      [ngClass]="{'compliance-footer--public': complianceFooter.public && skin !== 'icam-red'}"
      [ngStyle]="{'background-color': configureOptions.color}"
      *ngIf="skin !== 'steel-blue' && skin !== 'uk-steel-blue'">
      <div class="compliance-footer__top-block">
        <div class="col-md-6 compliance-footer__icon-logo"
          [ngShow]="!complianceFooter.public || skin === 'icam-red'">
          <img [src]="complianceFooter.imgLogo"
              alt="logo footer"
              class="compliance-footer__img-logo"/>
        </div>
        <div class="col-md-6 compliance-footer__footer-public">
            <div *ngIf="configureOptions.public && skin !== 'icam-red'"
                [innerHTML]="configureOptions.footer"></div>
            <p class="compliance-footer__last-connection"
              *ngIf="complianceFooter.hasLastConnection">
                <span [afTranslate]="'front-login.last-connect'"></span>
                {{ complianceFooter.lastLogin | convertDate:''}}
            </p>
        </div>
      </div>

      <div class="col-md-2 compliance-footer__rrss-block">
          <a class="compliance-footer__button-icon"
              [ngShow]="configureOptions?.facebook !== ''"
              [href]="configureOptions.facebook"
              target="_blank"><i class="lf-icon-facebook-round"></i>
          </a>
          <a class="compliance-footer__button-icon"
              [ngShow]="configureOptions?.linkedin !== ''"
              [href]="configureOptions.linkedin"
              target="_blank"><i class="lf-icon-linkedin-round"></i>
          </a>
          <a class="compliance-footer__button-icon"
              [ngShow]="configureOptions?.youtube !== '' && !showOtherRRSS"
              [href]="configureOptions.youtube"
              target="_blank"><i class="lf-icon-youtube-round"></i>
          </a>
          <a class="compliance-footer__button-icon"
              [ngShow]="configureOptions?.twitter !== '' && skin !=='gm-law'"
              [href]="configureOptions.twitter"
              target="_blank"><i class="lf-icon-twitter-round"></i>
          </a>
          <a class="compliance-footer__button-icon"
              [ngShow]="configureOptions?.instagram !== '' && showOtherRRSS"
              [href]="configureOptions.instagram"
              target="_blank"><i class="lf-icon-instagram-round"></i>
          </a>
      </div>

      <div [ngHide]="configureOptions.public">
          <div class="compliance-footer__footer-block">
              <div class="compliance-footer__footer"
                  [ngClass]="{'compliance-footer__footer--relative':complianceFooter.from === 'bpt'}">
                  <p class="compliance-footer__disclaimer-text"
                      [ngShow]="skin==='gm-law'">
                          <span>© Giuffrè Francis Lefebvre S.p.A. - Capitale Sociale € 2.000.000 i.v. - Sede legale: via Monte Rosa, 91 - 20149 Milano P.IVA 00829840156 | Società a socio unico. Società soggetta alla direzione e coordinamento di Lefebvre Sarrut Société Anonyme </span>
                      <span> | </span>
                      <a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"
                          href="https://swift.it-mil2.entercloudsuite.com/v1/KEY_44f2d0920f254271a50851d48ed28f01/whistleblowing/Informativa%20privacy_GFL_%20whistelblowing.htm"
                          target="_blank"
                          [afTranslate]="'compliance-footer.privacy-policy'">Política de privacidad</a>
                      <span> | </span>
                      <a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"
                          href="https://swift.it-mil2.entercloudsuite.com/v1/KEY_44f2d0920f254271a50851d48ed28f01/whistleblowing/Privacy%20Policy%20Segnala.htm"
                          target="_blank"
                          [afTranslate]="'compliance-footer.cookies-policy'">Política de cookies</a>
                  </p>
                  <div [innerHTML]="configureOptions.footer"></div>
              </div>
              <div *ngIf="complianceFooter.from === 'bpt'"
                  class="compliance-footer__block-img-ideo">
                  <img src="../assets/img/ideo_legal-logo-negativo-new.png"
                      alt="logo ideo footer"
                      class="compliance-footer__img-ideo"/>
              </div>
          </div>
      </div>
  </div>
  <div class="compliance-footer"
    *ngIf="skin === 'steel-blue'">
      <div class="customize-footer__logo-footer"
          [ngClass]="{'customize-footer__logo-footer--hidden-rrss': configureOptions.hiddenSocialMedia}">
          <div class="compliance-footer__block-logo">
              <img class="compliance-footer__img-logo"
                  [ngShow]="configureOptions.showLogoInFooter"
                  [src]="configureOptions.logo"
                  alt="logo footer"/>
          </div>
          <div class="compliance-footer__footer compliance-footer__footer--v2"
              [ngClass]="{'compliance-footer__footer--without-logo': !configureOptions.showLogoInFooter }">
              <div [innerHTML]="configureOptions.footer"></div>
          </div>
      </div>
  </div>
  <div class="compliance-footer"
    *ngIf="skin === 'uk-steel-blue'">
      <div class="customize-footer__logo-footer">
          <div class="compliance-footer__block-logo">
              <img class="compliance-footer__img-logo"
                  [src]="configureOptions.logo"
                  alt="logo footer"/>
          </div>
          <div class="compliance-footer__footer compliance-footer__footer--relative compliance-footer__footer--v3">
              <p class="compliance-footer__disclaimer-text">
                  <span [afTranslate]="'compliance-footer.title-footer-uk'">Tax Essentials for Advisors is a product of Indicator - FL Memo Ltd · ©</span><span>{{complianceFooter.year}} · </span>
                  <a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"
                      (click)="goTo('privacy')"
                      [afTranslate]="'compliance-footer.privacy-policy'">Politica de privacidad</a>
                  <span> · </span>
                  <a class="compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link"
                      (click)="goTo('terms')"
                      [afTranslate]="'compliance-footer.terms-conditions'">Terminos y condiciones</a>
              </p>
          </div>
      </div>
  </div>

</div>
