<div class="concept-window-tab">
    <div class="concept-window-tab__subtab-block"
        [ngClass]="{'concept-window-tab__subtab-block--general': activeConcept?.subTab==='general'}">
        <ul class="concept-window-tab__subtab-list-items">
            <li class="concept-window-tab__list"
                [ngClass]="{'concept-window-tab__list--active':activeConcept?.subTab==='general'}"
                (click)="selectTab('general')">
                <p class="concept-window-tab__list-text"
                    [afTranslate]="'historic.general'">General</p>
            </li>
            <li class="concept-window-tab__list"
                [ngClass]="{'concept-window-tab__list--active':activeConcept?.subTab==='documentation'}"
                (click)="selectTab('documentation')">
                <p class="concept-window-tab__list-text"
                    [afTranslate]="'common.documentation'">Documentación </p>
                <span class="concept-tab__amount-info"
                    [ngClass]="{'concept-tab__amount-info--active': activeConcept?.documentation?.totalDocuments > 0 && activeConcept?.subTab==='documentation'}"
                    [ngShow]="activeConcept?.documentation?.totalDocuments > 0">{{activeConcept?.documentation?.totalDocuments}}</span>
            </li>
            <li class="concept-window-tab__list"
                [ngClass]="{'concept-window-tab__list--active':activeConcept?.subTab==='task'}"
                (click)="selectTab('task')">
                <p class ="concept-window-tab__list-text"
                    [afTranslate]="'common.tasks'">Tareas </p>
                <span class="concept-tab__amount-info"
                    [ngClass]="{'concept-tab__amount-info--active': activeConcept?.tasks > 0 && activeConcept?.subTab==='task'}"
                    [ngShow]="activeConcept?.tasks">{{activeConcept?.tasks}}</span>
            </li>
            <li class="concept-window-tab__list"
                [ngClass]="{'concept-window-tab__list--active':activeConcept?.subTab==='alerts'}"
                (click)="selectTab('alerts')">
                <p class="concept-window-tab__list-text"
                    [afTranslate]="'common.alerts'">Alertas </p>
                <span class="concept-tab__amount-info"
                    [ngClass]="{'concept-tab__amount-info--active': activeConcept?.alerts > 0 && activeConcept?.subTab==='alerts'}"
                    [ngShow]="activeConcept?.alerts">{{activeConcept?.alerts}}</span>
            </li>
            <li class="concept-window-tab__list"
                [ngClass]="{'concept-window-tab__list--active':activeConcept?.subTab==='historic'}"
                (click)="selectTab('historic')">
                <p class="concept-window-tab__list-text"
                    [afTranslate]="'common.historic'">Histórico </p>
            </li>
        </ul>
        <div class="concept-window-tab__hours">
            <i class="lf-icon-alert concept-window-tab__icon-block-alert"
                [ngShow]="activeConcept?.clientStateId === 2"></i>
            <button class="concept-window-tab__button-hours concept-window-tab__button-hours--red"
                [ngShow]="activeConcept?.clientStateId === 3">
                <i class="lf-icon-close-round concept-window-tab__icon-hours"></i>
                <span class="concept-window-tab__text-hours"
                    [afTranslate]="'common.not-supported'">_No admitido</span>
            </button>
            <button class="concept-window-tab__button-hours concept-window-tab__button-hours--grey"
                [ngShow]="activeConcept?.clientStateId === 4">
                <i class="lf-icon-calendar-cross concept-window-tab__icon-hours"></i>
                <span class="concept-window-tab__text-hours"
                    [afTranslate]="'common.no-active'">_No activo</span>
            </button>
            <button class="concept-window-tab__button-hours"
                [ngStyle]="{'background-color': activeConcept?.addToScoring?.color}"
                [ngShow]="activeConcept?.addToScoring && activeConcept?.addToScoring?.riesgo">
                <i class="lf-icon-warning concept-window-tab__icon-hours"></i>
                <span class="concept-window-tab__text-hours">{{activeConcept?.addToScoring?.riesgo}}</span>
            </button>
        </div>
    </div>
  </div>
