import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-num',
  templateUrl: './customize-field-num.component.html'
})
export class CustomizeFieldNumComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldNum: any = {
    tinyMceOptions: {
    },
    contentReportInfo: {
      label: this.translate.instant('common.report-field'),
    },
    decimalsInfo: {
        label: this.translate.instant('customize-field-list.decimals'),
        positive: true
    },
    defaultValueInfo: {
        label: this.translate.instant('customize.default-value')
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }
  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;
  @Input() typeView: string = 'normal';
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldNum;
    that.disabledFormConfiguration = this.typeView === 'channelViewNoEditable' || this.concept?.disabled;
    that.channelViewConfiguration = this.typeView === 'channelViewNoEditable' || this.typeView === 'channelViewEditable';
    if(changes['concept'] && changes['concept'].currentValue){
      if(this.concept?.poll && this.concept?.disabled){
        that.contentReportInfo.noEditable = true;
        that.decimalsInfo.noEditable = true;
        that.defaultValueInfo.noEditable = true;
      }
    }
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      that.contentReportInfo.noEditable = that.field.noEditable;
      that.decimalsInfo.noEditable = that.field.noEditable;
      that.defaultValueInfo.noEditable = that.field.noEditable;
      that.fieldCopy = cloneDeep(that.field)
    }
    if(this.typeView === 'channelViewNoEditable'){
      that.decimalsInfo.noEditable = true;
      that.defaultValueInfo.noEditable = true;
    }
    that.tinyMceOptions.noEditable = (this.concept.poll && this.concept.disabled) || that.disabledFormConfiguration?true:false;
  }

  itemSelected(e:any, item:any){
      this.customizeFieldNum.field[item] = e.newValue;
      this.modifyField();
  }

  getEditorValue(event:any){
    this.customizeFieldNum.field.description = event.model;
  }

  cancelFieldText(){
      this.customizeFieldNum.field = cloneDeep(this.customizeFieldNum.fieldCopy);
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldNum.field
          }
      })
  }

  saveFieldText(){
      // JRSJ 26/02/2020  Se convierte el punto decimal, a coma decimal, para solucionar el problema en BBDD
      if(this.customizeFieldNum.field.defaultValue && this.customizeFieldNum.field.defaultValue !== null && typeof this.customizeFieldNum.field.defaultValue !== "number"){
          this.customizeFieldNum.field.defaultValue = this.customizeFieldNum.field.defaultValue.replace('.',',');
      }
      this.customizeFieldNum.fieldCopy = cloneDeep(this.customizeFieldNum.field);
      this.changeInField.emit({
          change:{
              type: 'save',
              field: this.customizeFieldNum.field
          }
      })
  }

  openVisibility(e:any){
    this.customizeFieldNum.field.visibilityOr = e.visibilityOr;
    this.customizeFieldNum.field.openBlock = e.openBlock;
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldNum.field);
  }

  changeOptionOn(e:any, type:string){
    switch(type){
      case 'private':
        this.customizeFieldNum.field.private = e.model;
        break;
      case 'required':
        this.customizeFieldNum.field.required = e.model;
        this.modifyField();
        break;
      default:
        this.customizeFieldNum.field[type] = e.model;
        this.modifyField();
        break;

    }

  }

}
