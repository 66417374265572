import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { VimeoService } from '@shared/services/vimeo-service/vimeo.service';

@Component({
  selector: 'video-slider',
  templateUrl: './video-slider.component.html'
})
export class VideoSliderComponent implements OnChanges {
  custom: any = this.customLanding.getCustom()
  videoSlider: any = {
    predef: true,
    fullScreen: false,
    videoSelected: null,
  }
  constructor(private customLanding: CustomLandingFactory,
    private modalService: ModalServiceService,
    private vimeoService: VimeoService) { }
  @Input() videos: any = null;

  ngOnChanges(changes:SimpleChanges){
    let that: any = this.videoSlider;
    if(this.videos && this.videos.length){
      that.videos = this.videos;
      that.videoSelected = this.videos[0];
      that.videoSelected.active = true;
      this.getImgUrl();
    }
  }
  getImgUrl(){
    if(this.videoSlider.videoSelected.videoType === 'vimeo'){
      this.vimeoService.getVimeoThumb(this.videoSlider.videoSelected.videoCode)
            .then((data:any)=>{
              this.videoSlider.imgUrl = data[0].thumbnail_large;
            }, (errorData:any)=>{})
    }else{
      this.videoSlider.imgUrl = "https://img.youtube.com/vi/"+ this.videoSlider.videoSelected.videoCode + "/default.jpg";
    }

  }

  videoChange(video:any){
    this.videoSlider.videos.forEach((video:any) => {
      video.active = false;
    });
    this.videoSlider.videoSelected = video;
    this.videoSlider.videoSelected.active = true;
    this.getImgUrl();
  }

  seeVideo(video:any){
    this.videoSlider.videos.forEach((video:any) => {
      if(video.active === true){
        this.videoSlider.videoSelected = video;
      }
    });
    let configuration = {
        videos: this.videoSlider.videos,
        videoSelected: this.videoSlider.videoSelected,
    }
    this.modalService.openVideo(configuration)
  }
}
