import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'distinctProperty'
})
export class DistinctPropertyPipe implements PipeTransform {

  transform(items: any, property: any) {
    let filtered: any = [];
    if (property && isArray(items) ) {
        let dictionary: any = {};
        items.forEach((item:any)=>{
          let value = item[property];
          if (value && dictionary[value] === undefined) {
              dictionary[value] = null;
              filtered.push(item);
          }
        })
    }
    return filtered;
  }

}
