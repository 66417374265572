import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EvaluationInfoFactory } from '@shared/factories/evaluation-info/evaluation-info.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class  EvaluationResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
      private evaluationInfo: EvaluationInfoFactory) { }

    resolve(route: ActivatedRouteSnapshot) {
      return this.apiService.get('evaluations', route.params['evaluationId'])
        .then((data:any)=>{
          this.evaluationInfo.setEvaluationInfo(data);
          return data;
        })
    }
}
