import { Injectable } from '@angular/core';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Injectable({
  providedIn: 'root'
})
export class ConceptLegalParentsService {

  constructor(private modalService: ModalServiceService,
    private context: ContextFactory,
    private custom: CustomLandingFactory) { }

  onChanges(child:any){
    let that: any = child.conceptLegalDocumentation;
    if(child.fullScreenBox){
      that.fullScreenBox = child.fullScreenBox
      if(this.custom.getCustom() && this.custom.getCustom() !== null && that.fullScreenBox !== '' && that.fullScreenBox !== 'concept-legal-documentation'){
        this.maximizeScreen(true, child);
      }
    }
  }

  maximizeScreen(keepClassBox:any, child:any){
      child.conceptLegalDocumentation.fullScreen = !child.conceptLegalDocumentation.fullScreen;
      if(!keepClassBox){
          child.catchFullScreen.emit({box:'concept-legal-documentation'});
      }
  }

  getReferencesLaws(type:any , child: any){
      let that: any = child.conceptLegalDocumentation;
      that.laws = [];
      child.referencesList.forEach((element:any) => {
          if(element.type ===  type){
              that.laws.push(element);
          }
      });
  }

  showLegalDocumentation(e:any, reference:any, child:any){
      let that: any = child.conceptLegalDocumentation;
      e.stopPropagation();
      e.preventDefault();
      that.openLaw = !that.openLaw;
      this.getReferencesLaws(reference.type, child);
      let registerItem ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':'open legal docs',
          'Concept Name': sessionStorage.getItem('breadcrumbConcept') + child.concept.title,
          'Centinela product': this.context.settings.defaultProduct
      }
      this.modalService.lawInfoModal(registerItem, child.referencesList, that.laws, reference)
          .subscribe((result:any)=>{
              if(result.result !=='ok'){
                  that.openLaw = !that.openLaw;
                  if(that.showInfo){
                      that.showInfo = !that.showInfo;
                  }
              }
          })
  }
}
