<div class="up-document">
    <div class="row">
        <div class="col-md-12">
            <div class="common__modal-header-block">
                <i class="lf-icon-add common__icon-header"></i>
                <span class="common__text-header">
                    {{attachDocumentModal.title}}
                </span>
                <i class="lf-icon-close common__icon-close-modal"
                    (click)="handleDismiss()"></i>
            </div>
            <div class="attach-document-modal__body">
                <div class="attach-document-modal__block-origin">
                    <field-type-list-simple [field]="attachDocumentModal.originAttach"
                        [listValues]="attachDocumentModal.originList"
                        [preSelectItem]="attachDocumentModal.origin"
                        (itemSelected)="itemSelected($event, 'origin')">
                    </field-type-list-simple>
                </div>

                    <div class="attach-document-modal__block-create-document"
                    *ngIf="attachDocumentModal.idValue === 2">
                        <field-type-list-simple [field]="attachDocumentModal.evaluationDocument"
                            [listValues]="attachDocumentModal.evaluationDocumentList"
                            (itemSelected)="itemSelected($event, 'associatedDocument')">
                        </field-type-list-simple>
                    </div>
                    <div class="attach-document-modal__block-create-document"
                        *ngIf="attachDocumentModal.idValue === 3">
                        <div class="attach-document-modal__block-origin">
                            <field-type-list-simple [field]="attachDocumentModal.typeFormat"
                                              [listValues]="attachDocumentModal.format"
                                              [preSelectItem]="attachDocumentModal.selectFormat"
                                              (itemSelected)="itemSelected($event, 'format')">
                            </field-type-list-simple>
                        </div>
                        <div class="attach-document-modal__block-origin--right">
                            <field-type-text-simple [field]="attachDocumentModal.documentName"
                                              [preSelectItem]="attachDocumentModal.document.name"
                                              (itemSelected)="itemSelected($event, 'name')">
                            </field-type-text-simple>
                            <span class="attach-document-modal__attach-error"
                                  [ngShow]="attachDocumentModal.documentName.errorMaxlength && attachDocumentModal.idValue === 3"
                                  [afTranslate]="'up-document.error-name'"></span>
                            <span class="attach-document-modal__attach-error"
                                  [ngShow]="attachDocumentModal.duplicateNameList"
                                  [afTranslate]="'documentation-list.duplicated-name-document'">
                                _Ya existe un documento con el mismo nombre
                            </span>
                        </div>
                    </div>

                <div class="attach-document-modal__block-create-document"
                      [ngShow]="(attachDocumentModal.idValue === 2 || attachDocumentModal.idValue === 3 || attachDocumentModal.origin===1) && currentComponent !== 'landingPage'">
                    <div class="common__input-block">
                        <div class="common__label-block common__label-block--one-icon">
                            <span class="common__input-text">{{attachDocumentModal.textareaLabel}}</span>
                        </div>
                        <i class="lf-icon-expand common__input-icon common__input-icon-active"
                            (click)="openTextareaModal()"></i>
                        <textarea class="common__input-textarea"
                        [(ngModel)]="attachDocumentModal.comments"></textarea>
                        <i class="lf-icon-close common__delete-content-icon"
                            [ngHide]="attachDocumentModal.comments === ''"
                            (click)="resetInput()">
                        </i>
                    </div>
                </div>
                <div class="common__buttons-block common__buttons-block--attach">
                    <button type="reset"
                            class="common__button common__button--clean"
                            (click)="clearForm()"
                            [afTranslate]="'common.clean'">Limpiar</button>
                    <button type="submit"
                            class="common__button common__button--active"
                            (click)="upFile()"
                            [afTranslate]="'attach-document.attach'">Adjuntar</button>
                </div>
            </div>
        </div>
    </div>
</div>
