import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges} from '@angular/core';
import { NgbDropdown} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html'
})
export class CustomSelectComponent implements OnInit, OnChanges {
  customSelect: any = {
    selection:{},
    options: []
  };
  @ViewChild(NgbDropdown)
  dropdowns: NgbDropdown;

  @Input() options: any = null;
  @Input() selection: any = null;

  @Output() selectOption = new EventEmitter();


  ngOnInit(): void {
    this.customSelect.options = this.options;
    this.customSelect.selection = this.selection;
  }

  ngOnChanges(): void {
    this.customSelect.selection = this.selection;
  }


  selectOpt(option:any){
    this.customSelect.selection = option;
    this.selectOption.emit({option})
    this.dropdowns.close();
  }
}
