import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ReportUtilsService } from '@shared/services/report-utils/report-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'report-info',
  templateUrl: './report-info.component.html'
})
export class ReportInfoComponent implements OnInit {
  constructor(private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private context:ContextFactory,
    private route: ActivatedRoute,
    private permissions: UserPermisionsFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private router: Router,
    private documentActions: DocumentActionsService,
    private exportService: ExportServiceService,
    private dateConverterService: DateConverterService,
    private downloadService: DownloadService,
    private reportUtilsService: ReportUtilsService
  ) { }

  report:any = this.route.snapshot.data['report'];
  skin: string = this.globalCfg.skin;
  fullMenu: boolean = window.innerWidth > 1367;
  userPermisions:any = this.permissions.getPermissions();
  reportInfo:any = {
    icamReportsMap: new Map(),
    reportList: [{}]
  };

  ngOnInit(): void {
    let that: any = this.reportInfo;
    let icamReportsMap = that.icamReportsMap;

    icamReportsMap.set(1, 'consultasconpago');
    icamReportsMap.set(2, 'preciomediotransaccion');
    icamReportsMap.set(3, 'tiempomediogestion');
    icamReportsMap.set(4, 'tiempomedioresolucion');
    icamReportsMap.set(5, 'perfildeusuario');
    icamReportsMap.set(6, 'porusuario');
    icamReportsMap.set(7, 'canalentrada');
    icamReportsMap.set(8, 'categoriaservicio');
    icamReportsMap.set(9, 'tipodocumento');
    icamReportsMap.set(10, 'materiasubmateria');
    icamReportsMap.set(11, 'resueltasporgestor');
    icamReportsMap.set(12, 'fragmentosdigitalizados');
    icamReportsMap.set(13, 'todaspublicaciones');

    let report = this.report;
    that.configurateReport = {
        excelCharts: report.excelCharts,
        type: report.type,
        subType: report.subType
    };

    if (this.skin === 'icam-red' && report.length) {
        this.emitReport(report[0]);
        this.getIcamReports();
    } else {
        this.emitReport(report);
        if (this.skin === 'icam-red') {
            report.reportId = report.reportObjectId;
        }
        this.getReportColumns();
    }

    this.emitFullMenu();
  }

  emitReport(report: any){
    let configuratedReport:any = cloneDeep(report);
    configuratedReport.title = configuratedReport.reportTitle?configuratedReport.reportTitle:configuratedReport.title;
    this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: configuratedReport});
  }

  getIcamReports() {
    let reportObject:any = this.report[0];
    if(reportObject.reportObjectId === 5 || reportObject.reportObjectId === 6){
      let itemSelected = {
          type: 'getIcamReport',
          item: reportObject,
          url: location.origin + '/evaluation/' + reportObject.evaluationId + '/reports'
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          if(result.result==='ok'){
            this.configureIcamReports(result.item, reportObject);
          }
        })
    }else{
      this.loader.openLoader('get-icam-reports');
      this.apiService.get('icam/reports', reportObject.reportObjectId)
          .then((data:any)=>{
            this.configureIcamReports(data, reportObject);
          }, (errorData)=>{});
    }

  }

  configureIcamReports(data: any, reportObject: any){
    let report:any = {
        subTab: 'reports',
        active: true,
        columns: [],
        title: reportObject.title,
        reportTitle: reportObject.reportTitle,
        rows: data,
        reportId: reportObject.reportObjectId,
        reportObjectId: this.reportInfo.icamReportsMap.get(reportObject.reportId),
        reportColors: [],
        evaluationBeginDate: reportObject.evaluationBeginDate
    }
    this.reportInfo.reportList = [report];
    this.getReportDocumentation(report.reportId);
    this.loader.closeLoader(['get-icam-reports']);
  }

  getReportColumns() {
      this.loader.openLoader('get-report-comlumns')
      //this.reportInfo.reportList= null;
      this.apiService.get('reportobjects/fields', this.report.reportObjectId)
          .then((data:any)=>{
              data.forEach((column: any) => {
                column.textColumn = column.translatedName?column.translatedName:column.name;
              });
              this.reportInfo.reportColumns = data;
              this.reportInfo.reportColumns.splice(0, 0, {
                  fieldTypeId: "conceptObjectId",
                  isHighLight: false,
                  isTasksField: false,
                  name: "conceptObjectId"
              })

              this.getReportColors(true);
              this.getReportDocumentation(null);
              this.loader.closeLoader(['get-report-comlumns']);
          }, (errorData:any) => {
              this.loader.closeError();
          });
  }

  getReportDocumentation(reportObjectId:any) {
      if (reportObjectId == null) {
          reportObjectId = this.report.reportObjectId;
      }
      this.apiService.get('reportdocuments', reportObjectId)
          .then((data:any)=>{
            this.reportInfo.reportDocuments = data;
              if(this.reportInfo.reportList && this.reportInfo.reportList.length){
                  this.reportInfo.reportList[0].documents = this.reportInfo.reportDocuments.length;
              }else if(this.reportInfo.reportList){
                  this.reportInfo.reportList[0].documents = this.reportInfo.reportDocuments.length;
              }
      }, (errorData:any)=>{
          this.loader.closeError();
      })
  }

  getReportColors(init:any){
      this.apiService.get('reportobjects/colors', this.report.reportObjectId)
          .then((data:any)=>{
            this.reportInfo.colorList = data;
            this.getReportData();

          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('front-login.error-connect')+" "+this.translate.instant('report-info.error-load'), 'error:accept:warning');

          })
  }

  getReportData(){
    let that: any = this.reportInfo
      this.apiService.get('reportobjects/data', this.report.reportObjectId)
          .then((data:any)=>{
              let report:any = {
                  subTab: 'reports',
                  active: true,
                  columns: that.reportColumns,
                  title: this.report.title,
                  reportTitle: this.report.reportTitle,
                  rows: data === null ? [] : data,
                  reportId: this.report.reportId,
                  reportObjectId: this.report.reportObjectId,
                  reportColors: that.colorList === null ? [] : that.colorList,
                  evaluationBeginDate: this.report.evaluationBeginDate
              }
              that.reportList = [report];
              this.loader.closeLoader(['report-open-report', 'report-info-refresh-report', 'report-open-concept']);
          }, (errorData:any) => {
              this.loader.closeError();
          });
  }

  emitFullMenu(){
      this.broadcastService.broadcast('changeFullMenu', {
          fullMenu: this.fullMenu
      });
  }
  launchActionOn(e:any){
    this.launchAction(e);
  }

  launchAction(action:any){
      switch(action.type){
          case 'closeConcept':
              this.goBack()
              break;
          case 'selectTab':
              this.reportInfo.reportList[0].subTab = action.item;
              break;
          case 'view':
              this.documentActions.doAction('open', action.document);
              break;
          case 'delete':
              this.deleteQuestionModal(action.document);
              break;
          case 'share':
              this.openModalShare(action.type, action.document);
              break;
          case 'download':
              this.documentActions.doAction('download', action.document);
              break;
      }
  }

  deleteQuestionModal(document:any){
      this.modalService.adviceModal(this.translate.instant('report-info.delete-document-question')+document.name+' ?', 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.deleteDocumentReport(document);
            }
          })
  }

  deleteDocumentReport(document:any) {
      this.loader.openLoader('report-info-delete-report-document')
      this.apiService.delete('reportdocuments', document.reportDocumentId)
          .then((data:any) => {
              this.reportInfo.reportDocuments = this.commonUtilsService.deleteElement(document, this.reportInfo.reportDocuments, 'reportDocumentId');
              this.reportInfo.reportList[0].documents = this.reportInfo.reportDocuments.length;
              this.modalService.adviceModal(this.translate.instant('report-info.delete-document').replace('{document}', document.name), 'info:accept:check-round');
              this.loader.closeLoader('report-info-delete-report-document');
          }, (errorData:any) => {
              this.loader.closeError()
          });
  }

  openModalShare(type:any, document:any){
      let configuration = {
          type: type,
          addContacts: false,
          document: document
      }
      this.modalService.documentShare(configuration)
        .subscribe((result:any)=>{
          if(result.result==='ok'){
            this.shareDocument(document, result.share);
          }
        })
  }

  shareDocument(document:any, share:any){
      this.loader.openLoader('report-info-share-document')
      this.apiService.add('reportdocuments/share/' + document.reportDocumentId, share)
          .then((data:any) => {
            this.loader.closeLoader('report-info-share-document');
            this.modalService.adviceModal(this.translate.instant('common.the-document') + " '" + document.name + "' " + this.translate.instant('documentation-list.shared-document'), 'info:accept:check-round')
          }, (errorData:any)=> {
              this.loader.closeError();
          });
  }

  updateReport(){
      let text = this.translate.instant('documentation-list.update-report-data').replace('{report}',  this.report.title);
      this.modalService.adviceModal(text, 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.refreshReport();
            }
          })
  }

  refreshReport(){
      let itemSelected = {
          type: 'refresh-report',
          item: this.report
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          if(result.result==='ok'){
            this.getReportColumns();
          }
        })
  }

  exportReport(e:any = false) {
      if (!e.onlyRename) {
          this.modalService.exportFormatModal(this.translate.instant('report-info.export-report'))
          .subscribe((result:any) => {
            if(result.result==='ok'){
              this.openRenameModal(result.selectedFormat, e.list);
            }
          });
      } else {
          this.openRenameModal();
      }
  }

  openRenameModal(formatResult:any = null, list: any = []){
    let configuration: any = {
      list: this.reportInfo.reportDocuments,
      hasPrevious: true,
      title: this.translate.instant('report-info.export-report'),
      extension: formatResult
    }
    this.modalService.exportModal(configuration)
      .subscribe((result:any) => {
        if(result.result ==='ok'){
          if (formatResult !== null) {
            result.format = formatResult;
          }
          this.generateReport(result, list);
        }else if(result.result==='previous'){
          this.exportReport();
        }
      })
  }

  generateReport(result: any, list: any){
    if((this.skin === 'icam-red' || this.report.excelCharts > 0) && this.report.reportId !== 195 && this.report.reportId !== 197){
      this.generateBackReport(result);
    }else{
      result.list = list;
      result.reportColumns = this.reportInfo.reportColumns;
      result.reportTitle = this.report.title;
      result.reportObjectId = this.report.reportObjectId;
      result.idPdf = 'pdf-report-table-list';
      this.generateFrontReport(result);
    }
  }

  generateFrontReport(result: any){
    let config: any = this.reportUtilsService.generateFrontReport(result);
    config.successCallback = (uploadResponse:any, blob:any) => {
              delete this.reportInfo.configurationPdf;
              this.modalService.adviceModal(this.translate.instant('report-info.export-report-ok') + '"' + result.filename + '". ' + this.translate.instant('report-info.info-export-documentation'), 'info:accept:check-round');
              this.reportInfo.reportDocuments.push(uploadResponse);
              this.reportInfo.reportList[0].documents = this.reportInfo.reportDocuments.length;
              if(result.downloadFile){
                  this.downloadService.triggerDownload(blob, config.nameDocument);
              }
              if(result.openFile){
                  result.format === 'xls'?this.documentActions.doAction('open', uploadResponse):window.open(URL.createObjectURL(blob), '_blank');
              }
          }
    switch (result.format) {
      case 'xls':
          this.exportService.toXls(config);
          break;
      case 'pdf':
          config.exportDate = this.dateConverterService.setTimezone(new Date());
          this.reportInfo.configurationPdf = config;
          let that = this;
          setTimeout(function () {
              let htmlPrint = $('#'+result.idPdf).html();
              config.htmlPrint = htmlPrint;
              that.exportService.toPdf(config);
          });
          break;
    }
  }

  generateBackReport(document:any) {
      this.loader.openLoader('report-info-report-excel');
      let newDocument:any = {
          name: document.filename
      }
      let reportObjectId:any = this.skin === 'icam-red'? this.report[0].reportObjectId : this.report.reportObjectId;
      this.apiService.add('reportobjects/excel/' + reportObjectId + (document.format == 'pdf' ? '/1' : ''), newDocument)
        .then((data:any)=>{
            this.loader.closeLoader('report-info-report-excel');
            this.modalService.adviceModal(this.translate.instant('report-info.export-report-ok') + '"' + document.filename + '". ' + this.translate.instant('report-info.info-export-documentation'), 'info:accept:check-round');
            if (document.downloadFile){
              this.documentActions.doAction('download', data);
            }
            if(document.openFile){
                setTimeout(()=> {
                  this.documentActions.doAction('open', data);
                }, 500);
            }
            this.reportInfo.reportDocuments.push(data);
            this.reportInfo.reportList[0].documents = this.reportInfo.reportDocuments.length;
      }, (errorData:any) => {
          this.loader.closeError();
      });
  }

  goBack(){
      this.router.navigate(['evaluation', this.route.snapshot.params['evaluationId'], 'reports']);
  }
}
