import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserPermisionsFactory {
  allowAdmin: boolean = false;
  allowManage: boolean = false;
  allowDesign: boolean = false;
  allowModify: boolean = false;
  isLanding: boolean = false;

  setUserPermisions(userPermisions: any){
    this.allowAdmin = userPermisions.allowAdmin; //Administración
    this.allowManage = userPermisions.allowManage; //Gestión
    this.allowDesign = userPermisions.allowDesign; //Personalización
    this.allowModify = userPermisions.allowModify; //Edición
    this.isLanding = userPermisions.isLanding;
  }

  getPermissions(){
    if(this.allowDesign){
        return 'all';
    }else if(this.allowModify){
        return 'modify';
    }else{
        return 'none';
    }
  }
  getTypePermissions(){
    if(this.allowAdmin){
        return 'admin';
    }else if(this.allowManage){
        return 'manage';
    }else if(this.allowDesign){
        return 'design';
    }else if(this.allowModify){
        return 'modify';
    }else{
        return 'read';
    }
  }
}
