import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'preview-customization',
  templateUrl: './preview-customization.component.html'
})
export class PreviewCustomizationComponent implements OnInit {
  company: any = {};
  constructor(private loader: LoaderFactory,
    private context: ContextFactory,
    private apiService: ApiServiceService,
    private router: Router) { }

  ngOnInit(): void {
    this.loader.openLoader('preview-customization-login');
    this.company = sessionStorage.getItem('companyPreviewCustomization');
    if(this.company){
        this.company = JSON.parse(this.company);
        this.configureCompany();
    }else{
        this.getInfoCompany();
    }
  }

  getInfoCompany(){
    this.apiService.get('company/globalcustomization', this.context.user.companyId)
    .then((data:any)=>{
        this.company = data;
        this.configureCompany();
    }, (errorData:any)=>{
      this.loader.closeError();
      this.router.navigate(['dashboard']);
    })
  }

  configureCompany(){
      this.company.logoFooter = this.configureLogo(this.company.logoFooter);
      this.company.logoHeader = this.configureLogo(this.company.logoHeader);
      if(!this.company.textColor){
          this.company.textColor = '#ffffff';
          if(this.company.color.toLowerCase() === '#fff201' || this.company.color.toLowerCase() === '#ffb001'){
              this.company.textColor = '#000000';
          }
      }
      this.loader.closeLoader(['preview-customization-login']);
  }

  configureLogo(logo: any){
      if(logo.indexOf('data:image') === -1){
          return logo = "data:image/png;base64," + logo;
      }
      return logo;
  }
}

