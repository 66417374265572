import { Component, OnInit } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'instant-videoconferences',
  templateUrl: './instant-videoconferences.component.html'
})
export class InstantVideoconferencesComponent implements OnInit {
  instantVideoconferences: any = {
    loaderImg: this.commonUtilsService.configureLoaderImg(),
    typeFilter: 'meetingDate',
    orderOperationDate: true,
    searchingList: true,
    titleWordFilter: '',
    allParticipants: [],
    listVideoConferences: [],
    filterListVideoconferences: [],
    filters: ['participants'],
    currentPage: 1,
    configPagination: {
      pageSize: 10,
    },
    participantsFilter: {
        name: this.translate.instant('instant-videoconferences.participants')
    },
    titleFilter: {
        name: this.translate.instant('common.title'),
        width: '200px'
    },
    locationFilter: {
        name: this.translate.instant('task-edit.place'),
        width: '200px'
    }
  }
  constructor(private context: ContextFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private filterColumnService: FilterColumnService,
    private dateConverterService: DateConverterService,
    private modalService: ModalServiceService) { }

  ngOnInit(): void {
    this.getListVideoconferences();
  }

  getListVideoconferences(){
    this.apiService.get('tasks/jitsimeet', 'videoconferences')
      .then((data:any)=>{
          this.instantVideoconferences.listVideoConferences = data;
          this.instantVideoconferences.filterListVideoconferences = this.instantVideoconferences.listVideoConferences;
          this.checkListVideoconferences();
          this.getFilters();
          this.instantVideoconferences.searchingList = false;
          this.loader.closeLoader(['front-login-login']);
      }, (error:any)=>{
          this.instantVideoconferences.searchingList = false;
          this.loader.closeError();
      })
  }

  checkListVideoconferences(){
    this.instantVideoconferences.filterListVideoconferences.forEach((elementList:any) => {
      this.configureElementList(elementList);
    });
  }

  configureElementList(elementList: any){
      elementList.open = true;
      elementList.copy = this.dateConverterService.todayDate(elementList.meetingDate);
      elementList.dateMeeting = elementList.meetingDate;
      if(elementList.participants && elementList.participants.length){
          this.instantVideoconferences.allParticipants = this.instantVideoconferences.allParticipants.concat(elementList.participants);
      }
  }

  getFilters(){
    this.instantVideoconferences.filters.forEach((filter:any) => {
      let list = this.instantVideoconferences.filterListVideoconferences;
      let filterType = filter;
      if(filter === 'participants'){
          list = this.instantVideoconferences.allParticipants;
          filterType = 'fullName';
      }
      this.instantVideoconferences[filter+'List'] = this.filterColumnService.extractList(filterType,  list);
      this.instantVideoconferences[filter+'ListFilter'] = [];
    });
  }

  openUrl(url: any, copy: any){
      if(copy){
          window.open(url, '_blank');
      }
  }

  changeViewWindow(){
    this.commonUtilsService.goToStateUrl();
  }

  orderDate(){
    this.instantVideoconferences.orderOperationDate = !this.instantVideoconferences.orderOperationDate;
  }

  openFocus(type: any){
    this.instantVideoconferences['openFocus'+type] = !this.instantVideoconferences['openFocus'+type];
  }

  orderBySearch(e: any, type: any){
    let newValue = e.newValue;
    this.instantVideoconferences[type+'WordFilter'] = newValue;
    this.filterVideoconfences(type);
  }

  resetFilter(e: any, type: any){
    e.stopPropagation();
    e.preventDefault();
    this.instantVideoconferences['filter'+type] = '';
    this.filterVideoconfences(type);
  }

  selectTypeOperation(item: any, type: any){
    let listFilter = this.instantVideoconferences[type+'ListFilter'];
    this.instantVideoconferences[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if(this.instantVideoconferences[type+'Filter']){
        this.instantVideoconferences[type+'Filter'].hasFilter = this.instantVideoconferences[type+'ListFilter'].length;
    }
    this.filterVideoconfences(type);
  }

  filterVideoconfences(type: any){
    let searchFilter = this.instantVideoconferences.titleWordFilter;
    let searchWord = 'title';
    if(type === 'location'){
        searchFilter =  this.instantVideoconferences[type + 'WordFilter'];
        searchWord = type;
    }
    let filterList = this.filterColumnService.filterByWord(searchFilter,  this.instantVideoconferences.listVideoConferences, searchWord);
    this.instantVideoconferences.filters.forEach((filter:any) => {
      let filteredList = this.instantVideoconferences[filter+'ListFilter'];
      let filterType = filter;
      if(filter === 'participants'){
          filterList = this.filterColumnService.filterBycolumnAllChildren(filterList, filteredList, 'fullName', 'participants');
      }else{
          filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filterType);
      }
    });
    this.instantVideoconferences.filterListVideoconferences = filterList;
  }

  addVideoconference(e: any){
      e.stopPropagation();
      e.preventDefault();
      this.modalService.newInstantVideoconference()
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
              let newVideoconference = result.videoconference;
              newVideoconference.security = newVideoconference.password;
              if(result.typeMeeting === 'open'){
                  newVideoconference.security = this.translate.instant('instant-videoconferences.open-access');
              }
              if(newVideoconference.title === ''){
                  newVideoconference.title = this.translate.instant('instant-videoconferences.instant-call');
              }
              if(newVideoconference.location === ''){
                  newVideoconference.location = this.translate.instant('instant-videoconferences.virtual-meeting-room');
              }
              let meetingDate = this.dateConverterService.setTimezone(newVideoconference.meetingDate);
              newVideoconference.meetingDate = meetingDate.substr(0, meetingDate.lastIndexOf('Z'));
              this.configureElementList(newVideoconference);
              this.instantVideoconferences.listVideoConferences.push(newVideoconference);
              this.instantVideoconferences.filterListVideoconferences = this.instantVideoconferences.listVideoConferences;
              this.getFilters();
        }
      })
  }

  copyText(copy: any) {
      this.commonUtilsService.clipboardText(copy);
  }

}
