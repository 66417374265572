<div class="users-admin-person-form">
  <form id="personForm"
    class="users-admin-person-form__form">
    <div class="users-admin-person-form__block-row"
      *ngIf="operation === 'convert'">
        <field-type-rich-text
            [field]="usersAdminPersonForm.identityInfo">
        </field-type-rich-text>
    </div>
    <div class="users-admin-person-form__block-row">
        <div class="users-admin-person-form__block-field-init"
            [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-50-percent':'users-admin-person-form__block-field-25-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.name"
              [preSelectItem]="person.name"
              (itemSelected)="itemSelected('name', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__block-field-50-percent"
            [ngClass]="{'users-admin-person-form__block-field-finish': skin === 'uk-steel-blue'}">
            <field-type-text-simple [field]="usersAdminPersonForm.surname"
              [preSelectItem]="person.surname"
              (itemSelected)="itemSelected('surname', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__block-field-25-percent users-admin-person-form__block-field-finish"
            *ngIf="skin !== 'uk-steel-blue'">
            <field-type-text-simple [field]="usersAdminPersonForm.nif"
              [preSelectItem]="person.nif"
              (itemSelected)="itemSelected('nif', $event.newValue)"></field-type-text-simple>
        </div>
    </div>
    <div class="users-admin-person-form__block-row">
        <div class="users-admin-person-form__block-field-50-percent users-admin-person-form__block-field-init">
            <field-type-text-simple [field]="usersAdminPersonForm.position"
              [preSelectItem]="person.position"
              (itemSelected)="itemSelected('position', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__block-field-50-percent users-admin-person-form__block-field-finish">
            <field-type-text-simple [field]="usersAdminPersonForm.companyName"
              [preSelectItem]="person.companyName"
              (itemSelected)="itemSelected('companyName', $event.newValue)"></field-type-text-simple>
        </div>
    </div>
    <div class="users-admin-person-form__block-row">
        <div class="users-admin-person-form__block-field-33-percent users-admin-person-form__block-field-init">
            <field-type-text-simple [field]="usersAdminPersonForm.address"
              [preSelectItem]="person.address"
              (itemSelected)="itemSelected('address', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__postal-code"
            [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-33-percent':'users-admin-person-form__block-field-17-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.postalCode"
              [preSelectItem]="person.postalCode"
              (itemSelected)="itemSelected('postalCode', $event.newValue)"></field-type-text-simple>
        </div>
        <div [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-33-percent users-admin-person-form__block-field-finish':'users-admin-person-form__block-field-25-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.city"
              [preSelectItem]="person.city"
              (itemSelected)="itemSelected('city', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__block-field-25-percent users-admin-person-form__block-field-finish"
            *ngIf="skin !== 'uk-steel-blue'">
            <field-type-text-simple [field]="usersAdminPersonForm.province"
              [preSelectItem]="person.province"
              (itemSelected)="itemSelected('province', $event.newValue)"></field-type-text-simple>
        </div>
    </div>
    <div class="users-admin-person-form__block-row">
        <div class="users-admin-person-form__block-field-25-percent users-admin-person-form__block-field-init"
            *ngIf="skin !== 'uk-steel-blue'">
            <field-type-text-simple [field]="usersAdminPersonForm.country"
              [preSelectItem]="person.country"
              (itemSelected)="itemSelected('country', $event.newValue)"></field-type-text-simple>
        </div>
        <div [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-50-percent users-admin-person-form__block-field-init':'users-admin-person-form__block-field-33-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.email"
              [preSelectItem]="person.email"
              (itemSelected)="itemSelected('email', $event.newValue)"></field-type-text-simple>
        </div>
        <div [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-25-percent':'users-admin-person-form__block-field-21-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.phoneNumber1"
              [preSelectItem]="person.phoneNumber1"
              (itemSelected)="itemSelected('phoneNumber1', $event.newValue)"></field-type-text-simple>
        </div>
        <div class="users-admin-person-form__block-field-finish"
          [ngClass]="skin === 'uk-steel-blue'?'users-admin-person-form__block-field-25-percent':'users-admin-person-form__block-field-21-percent'">
            <field-type-text-simple [field]="usersAdminPersonForm.phoneNumber2"
              [preSelectItem]="person.phoneNumber2"
              (itemSelected)="itemSelected('phoneNumber2', $event.newValue)"></field-type-text-simple>
        </div>
    </div>
    <div class="users-admin-person-form__block-row"
        *ngIf="usersAdminPersonForm.languages.length > 1">
        <div class="users-admin-person-form__block-field-40-percent">
            <field-type-list-simple [field]="usersAdminPersonForm.culture"
                [preSelectItem]="person.culture"
                [listValues]="usersAdminPersonForm.languages"
                (itemSelected)="itemSelected('culture', $event.newValue)">
            </field-type-list-simple>
        </div>
    </div>
    <div *ngIf="type==='contact' && usersAdminPersonForm.clientItems.length">
      <field-type-list-tree
        [field]="usersAdminPersonForm.clients"
        [listValues]="usersAdminPersonForm.clientItems"
        [preSelectItem]="person.clients"
        [orderBy]="'text'"
        (itemSelected)="clientsSelected($event.newValue)"></field-type-list-tree>
    </div>
    <div *ngIf="(type !== 'group' && operation !== 'edit-basic')">
        <field-type-switch class="users-admin-person-form__groups-row"
            [label]="'users-admin.person-form.addToGroups'| afTranslate"
            [checked]="person.addGroups"
            (callback)="itemSelected('addGroups', $event.value)">
        </field-type-switch>
    </div>
    <div [ngShow]="person.addGroups && (type !== 'group' && operation !== 'edit-basic')">
        <field-type-list-multiple [field]="usersAdminPersonForm.groups"
            [listValues]="usersAdminPersonForm.groupItems"
            [preSelectItem]="person.groups"
            [fieldToFind]="'name'"
            (itemSelected)="groupsSelected($event.newValue)"></field-type-list-multiple>
    </div>
  </form>
</div>

