<div class="law-info"
  *ngIf="!isLanding">
  <div class="common__modal-header-block">
      <i class="lf-icon-legal-reference common__icon-header"></i>
      <span class="common__text-header"
            [afTranslate]="'law-info.legal-references'">Referencias Legales</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="law-info__content">
      <legal-document class="law-info__content-body"
          [references]="references"
          [selected]="lawInfo.selected"
          [registerEvent]="registerEvent">
      </legal-document>
  </div>
  <div class="common__buttons-block common__buttons-block--modal"
        [ngShow]="lawInfo.selected">
        <button type="submit"
              class="common__button common__button--active"
              (click)="openDB()"
              [ngShow]="lawInfo.selected.type === 'form'">
          <span class="law-info__db-icon-holder">
            <i class="lf-icon-qmemento"></i>
          </span>
          <span [afTranslate]="'law-info.see-form-bd'">Ver formulario en B.D. Juridica</span>
      </button>
      <button type="submit"
              class="common__button common__button--active"
              (click)="printAction()"
              [afTranslate]="'common.print'">Imprimir
      </button>
  </div>
</div>

<div class="law-info"
  *ngIf="isLanding">
  <div class="common__block-icon-close-modal-custom">
      <button class="lf-icon-close-round common__icon-close-modal-custom"
          (click)="handleDismiss()"></button>
  </div>
  <div class="common__modal-header-block common__modal-header-block--custom-margin"
      [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}">
      <i class="lf-icon-legal-reference common__icon-header common__icon-header--custom" ></i>
      <span class="common__text-header common__text-header--custom"
          [afTranslate]="'law-info.legal-references'">Referencias Legales</span>
  </div>
  <div class="law-info__content law-info__content--custom">
      <legal-document [references]="references"
          [selected]="lawInfo.selected"
          [registerEvent]="registerEvent">
      </legal-document>
  </div>
  <div class="common__buttons-block law-info__buttons-block"
       [ngShow]="lawInfo.selected">
      <button type="submit"
          class="common__button-landing-custom common__button-landing-custom--send"
          [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
          (click)="openDB()"
          [ngShow]="lawInfo.selected?.type === 'form'">
          <span class="law-info__db-icon-holder-custom"><i class="lf-icon-qmemento"></i></span>
          <span [afTranslate]="'law-info.see-form-bd'">Ver formulario en B.D. Jurídica</span>
      </button>
      <button type="submit"
          class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
          [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
          (click)="printAction()"
          [afTranslate]="'common.print'">_Imprimir</button>
  </div>
</div>
