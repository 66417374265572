<div class="process-edit"
    [ngClass]="modal?'process-edit--modal':'process-edit--no-modal'">
    <!-- div class="process-edit concept-form" translate-namespace="process.edit" fill-height="-80"> -->
    <!--Columna izquierda-->
    <div class="process-edit__block-drawzone"
        [ngClass]="{'process-edit__block-drawzone--modal-process': modal}">
        <!--How tp-->
        <div class="process-edit__howto"
                [ngShow]="!modal">
                <i class="customize-concept__info-block-icon"
                    [ngClass]="processEdit.showInfo?'lf-icon-close-round':'lf-icon-information'"
                    (click)="processEdit.showInfo = !processEdit.showInfo"></i>
                <div class="customize-concept__triangle-box"
                        [ngShow]="processEdit.showInfo"></div>
                <div class="customize-concept__info-block"
                        [ngClass]="{'customize-concept__info-block--open':processEdit.showMoreInfo}"
                        [ngShow]="processEdit.showInfo">
                    <p class="customize-concept__info-block-title"
                        [afTranslate]="'process.edit.how-to'">¿Como diseñar un proceso?</p>
                    <ul class="customize-concept__info-steps-block">
                        <li class="customize-concept__info-step">
                            <i class="lf-icon-number-one customize-concept__icon-step"></i>
                            <span [afTranslate]="'process.edit.how-to-1-title'">Definir el proceso</span>
                        </li>
                        <li class="customize-concept__info-step">
                            <i class="lf-icon-number-two customize-concept__icon-step"></i>
                            <span [afTranslate]="'process.edit.how-to-2-title'">Definir estados</span>
                        </li>
                        <li class="customize-concept__info-step">
                            <i class="lf-icon-number-three customize-concept__icon-step"></i>
                            <span [afTranslate]="'process.edit.how-to-3-title'">Definir transiciones</span>
                        </li>
                        <li class="customize-concept__info-step">
                            <i class="lf-icon-number-four customize-concept__icon-step"></i>
                            <span [afTranslate]="'process.edit.how-to-4-title'">Finalizar</span>
                        </li>
                    </ul>
                </div>
        </div>
        <!--Zona dibujo-->
        <div class="process-edit__main"
            [ngClass]="{'process-edit__main--modal': modal,
                        'process-edit__main--show-help': processEdit.showHelp}">
            <rappid class="common__height-100"
                [component]="processEdit.rappid"
                (onAction)="onCellActionOn($event)"
                [designing]="processEdit.designing"
                [defaultStepName]="'process.edit.default-state-name'"
                [from]="'edit'"
                [model]="processEdit.model"
                (addStateEmit)="processEdit.showHelp = false">
            </rappid>
            <div class="process-edit__main-help" *ngIf="processEdit.showHelp">
                <div [afTranslate]="'process.edit.draw-zone-help-title'"
                    class="process-edit__text-strong">Zona de diagramación</div>
                <div [afTranslate]="'process.edit.draw-zone-help-line1'">
                    Usa la barra de herramientas lateral para añadir al menos dos estados y
                </div>
                <div [afTranslate]="'process.edit.draw-zone-help-line2'">
                    organizar el proceso. Todos los estados deben estar conectados.
                </div>
            </div>
            <div class="process-edit__alert"
                    [ngClass]="{'process-edit__alert--error': processEdit.alert?.isError}"
                    [ngShow]="processEdit.alert">
                <i class="process-edit__alert__icon"
                        [ngClass]="processEdit.alert?.isError ? 'lf-icon-warning' : 'lf-icon-check-round'"></i>
                <span class="process-edit__alert__text">{{processEdit.alert?.text}}</span>
                <button class="common__icon-button process-edit__alert__close"
                        (click)="processEdit.alert = null">
                    <i class="lf-icon-close"></i>
                </button>
            </div>
        </div>
    </div>
    <!--Columna derecha-->
    <div class="process-edit__process-properties common__height-100">
        <div class="process-edit__block-properties">
            <div class="concept-legal-documentation__border-box"
                [ngShow]="skin==='gm-law'"></div>
            <div class="predefined-templates__template-header">
                <i [class]="'predefined-templates__block-icon '+processEdit.itemTypes.icons[processEdit.selection.type]"></i>
                <p class="predefined-templates__block-text"
                    [afTranslate]="'process.title'">{{processEdit.itemTypes.titles[processEdit.selection.type]}}</p>
            </div>
            <div class="process-edit__properties">
                    <field-type-text-simple [field]="processEdit.titleField"
                        [preSelectItem]="processEdit.selection.title"
                        (itemSelected)="titleChanged($event.newValue)">
                    </field-type-text-simple>
                <div class="process-edit__description">
                    <rich-editor [tinyMceOptions]="processEdit.tinyMceOptions"
                        [modelText]="processEdit.selection.description"
                        (emitValue)="descriptionChanged($event.model)">
                    </rich-editor>
                </div>
                <field-type-switch *ngIf="processEdit.selection.type ==='app.State' && skin !=='icam-red'"
                    [label]="'process.edit.send-to-archive'| afTranslate"
                    [description]="'process.edit.send-to-archive-explain'| afTranslate"
                    [disabled]="processEdit.hasArchivedSelect && !processEdit.selection.cell.attributes.compliance.archiveConceptObject"
                    [checked]="processEdit.selection.cell.attributes.compliance.archiveConceptObject"
                    (callback)="changeArchiveConceptObject($event.value)">
                </field-type-switch>
            </div>
        </div>
        <div class="process-edit__buttons">
                <button type="submit"
                    [ngHide]="processEdit.isNew"
                    class="common__button common__button--clean common__button--litle"
                    (click)="deleteProcess()"
                    [afTranslate]="'process.edit.delete'">
                    Eliminar proceso
                </button>
            <button type="submit"
                    class="common__button common__button--clean common__button--litle"
                    (click)="cancel()"
                    [afTranslate]="'common.cancel'">
                    Cancelar
            </button>
            <button type="submit"
                    class="common__button common__button--active common__button--litle"
                    (click)="saveProcess()"
                    [afTranslate]="'common.save'">
                    Guardar
            </button>
        </div>
    </div>
</div>
