import moment from 'moment';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  isHidden(dt:any, current:any){
    return !(moment(dt.date).month() === moment(current).month())
  }

  between(dt:any, from: any, to: any){
    if(from && to){
        return (moment(dt.date).valueOf() >= moment(from).valueOf() && moment(dt.date).valueOf() <= moment(to).valueOf())
    }
    return
  }

  selectDate(dt:any, type:any){
    return (moment(dt.date).format('DD-MMMM-YYYY') === moment(type).format('DD-MMMM-YYYY'))
  }
}
