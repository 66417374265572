<label class="common__input-block"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--color field-type-list__input-block': !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight && !fieldTypeList.field.isTypeButton,
      'common__input-block--fixed field-type-list__input-block-numerical-scale':fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight || fieldTypeList.field.isTypeButton,
      'common__input-block--poll-view':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView,
      'common__input-block--custom-field':isLanding && !fieldTypeList.field.sequentialView && !fieldTypeList.field.isTypeButton,
      'common__input-block--custom-field-fixed':isLanding && !fieldTypeList.field.sequentialView && fieldTypeList.field.isTypeButton,
      'common__show-alert': fieldTypeList.field.error && !fieldTypeList.field.sequentialView,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-custom-numerical-scale':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      *ngIf="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': fieldTypeList.field.error,
        'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="fieldTypeList.field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView">
          </tooltip-law>
      </p>
  </div>
  <div [class]="'common__label-block common__'+fieldTypeList.classWidthField"
      [ngHide]="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable">
      <span class="common__input-text"
          [ngClass]="{'common__input-text--required': fieldTypeList.field.error,
                  'common__input-text--no-editable': fieldTypeList.field.noEditable}">{{fieldTypeList.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="fieldTypeList.field.required">*</span>
      <div class="common__info-icon-block"
          [ngHide]="fieldTypeList.field.noEditable || !fieldTypeList.field.description">
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
          [ngShow]="fieldTypeList.field.references && fieldTypeList.field.references.length && !fieldTypeList.field.noEditable">
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="fieldTypeList.field.relatedInfo && fieldTypeList.field.relatedInfo.length && !fieldTypeList.field.poll && !fieldTypeList.field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(fieldTypeList.field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
      <div class="common__info-icon-block common__info-icon-block--in-report"
        *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
        <tooltip-in-report [inReport]="fieldTypeList.inReport"></tooltip-in-report>
      </div>
  </div>
  <ul class="field-type-text__sequential-question-list">
      <div class="field-type-list__sequential-numerical-block"
          [ngStyle]="{'width': ((fieldTypeList.filterList.length * 89)-20)+'px'}">
          <li class="field-type-list__sequential-numerical"
              *ngFor="let listValue of fieldTypeList.filterList | byWord:fieldTypeList.findDropdown:[fieldToFind] | afOrderBy: fieldTypeList.orderList:fieldTypeList.orderAsc; let first = first;let index = index"
              (click)="selectItem(listValue, $event)"
              [ngHide]="listValue.type && listValue.type.toLowerCase()==='back' && !listValue.show"
              [ngClass]="{'common__show-alert': fieldTypeList.field.error && fieldTypeList.field.sequentialView}"
              [ngStyle]="{'background-color':listValue.selected?custom.color:'#D8D8D8', 'color': listValue.selected?custom.textColor:'#606060'}">
              <p class="text-center">{{listValue.text}}</p>
          </li>
      </div>
  </ul>
  <div class="field-item__blocker-box"
      *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
      <div class="field-item__blocker-triangle"></div>
      <i class="lf-icon-warning field-item__blocker-icon"></i>
      <p class="field-item__blocker-text"
        [afTranslate]="'field-items.blocker-field'">campo bloqueante
      </p>
  </div>
</label>
