import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { FieldTypeListParentService } from '../utils/field-type-list-parent.service';

@Component({
  selector: 'sequential-list-numerical',
  templateUrl: './sequential-list-numerical.component.html'
})

export class SequentialListNumericalComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = true;
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  custom: any = this.customLanding.getCustom();

  constructor(private fieldTypeListParent: FieldTypeListParentService,
    private customLanding: CustomLandingFactory) {}

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeListParent.openWatchers(this)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldTypeListParent.configureChanges(this);
    if(this.field){
      this.fieldTypeList.widthElementNumericalScale = (100 / this.fieldTypeList.filterList.length) +'%';
    }
  }

  selectItem(listValue: any, e:any){
    this.fieldTypeListParent.selectItem(listValue, this, e)
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeListParent.openModalRelatedInfo(fieldRelatedInfo, e, this);
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
