<div class="calendar-month-year__block-header-calendar">
  <div class="calendar-month-year__block-arrow">
    <button type="button"
      class="calendar-month-year__button-angle"
      (click)="move('prev')">
      <i class="lf-icon-angle-left"></i>
      <!-- <span class="sr-only">previous</span> -->
    </button>
  </div>
  <div class="calendar-month-year__header-text">
    <button id="header-calendar-title"
      type="button"
      class="calendar-datepicker__datepicker-title"
      [disabled]="calendarMonthYear.type === 'year'"
      (click)="toggleMode()">
      <!-- [disabled]="datepickerMode === maxMode"  -->
      <strong>{{selectedDateHeader}}</strong>
    </button>
  </div>
  <div class="calendar-month-year__block-arrow">
    <button type="button"
      class="calendar-month-year__button-angle calendar-month-year__next-button--right"
      (click)="move('next')">
      <i class="lf-icon-angle-right"></i>
      <!-- <span class="sr-only">next</span> -->
    </button>
  </div>
</div>

<div *ngFor="let month of calendarMonthYear.months"
      class="calendar-month-year__row"
      [ngShow]="calendarMonthYear.type === 'month'">
      <button [class]="'calendar-month-year__button calendar-month-year__button--'+type"
        [ngClass]="{'calendar-month-year__button--actual': calendarMonthYear.actualMonth === month,
          'calendar-month-year__button--active': activedMonth === month}"
        (click)="selectMonth(month)">
          {{month | convertDate: calendarMonthYear.convertDateMonth}}
      </button>
</div>
<div *ngFor="let year of calendarMonthYear.years"
      class="calendar-month-year__row"
      [ngShow]="calendarMonthYear.type === 'year'">
      <button [class]="'calendar-month-year__button calendar-month-year__button--'+type"
        [ngClass]="{'calendar-month-year__button--actual': calendarMonthYear.actualYear === year,
          'calendar-month-year__button--active': activedYear === year}"
        (click)="selectYear(year)">
          {{year}}
      </button>
</div>
