import { Component, OnChanges, SimpleChanges, Input} from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';

@Component({
  selector: 'dashboard-roles-obligated-subject',
  templateUrl: './dashboard-roles-obligated-subject.component.html'
})
export class DashboardRolesObligatedSubjectComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  dashboardRolesObligatedSubject: any = {
    productId: null,
    callsCharged: 0,
    obligatedSubject: {},
    roles: []
  }
  constructor(private router: Router,
      private apiService: ApiServiceService,
      private commonUtilsService: CommonUtilsService,
      private globalCfg: GlobalCfgFactory,
      private loader: LoaderFactory,
      private stateManagement: StateManagementFactory) { }

  @Input() product: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if(this.product.productId && this.product.productId !== this.dashboardRolesObligatedSubject.productId){
      this.dashboardRolesObligatedSubject.productId = this.product.productId;
      this.dashboardRolesObligatedSubject.callsCharged = 0;
      this.getProductRoles();
      if(this.product.productId === 'blanqueo'){
        this.getObligatedSubjectInfo();
      }
    }
  }

  getProductRoles(){
    this.apiService.get('productroles/product/'+this.dashboardRolesObligatedSubject.productId +'/assignments')
      .then((data: any)=> {
          if(data && data.length){
              data.forEach((productrole: any) => {
                  productrole.id = this.commonUtilsService.createId();
                  productrole.usersList = [];
                  productrole.users = productrole.users?productrole.users:[];
                  productrole.clientsUsers = productrole.clientsUsers?productrole.clientsUsers:[];
                  productrole.selectContacts = productrole.users.concat(productrole.clientsUsers).sort(this.orderListName);
                  productrole.selectContacts.forEach((user: any) => {
                      productrole.usersList.push(user.fullName);
                  })
              })
              this.dashboardRolesObligatedSubject.roles = data;
              this.dashboardRolesObligatedSubject.callsCharged++;
              if(this.dashboardRolesObligatedSubject.productId !== 'blanqueo'){
                  this.dashboardRolesObligatedSubject.callsCharged++;
              }
          }
      }, (errorData:any)=>{});
  }

  orderListName(a:any, b:any) {
    if(a.numOrder < b.numOrder){
      return 1;
    }
    return a.numOrder > b.numOrder ? -1 : 0;
  }

  getObligatedSubjectInfo(){
    this.apiService.get('blanqueo/detallessujetoobligado')
      .then((data: any)=> {
          this.dashboardRolesObligatedSubject.obligatedSubject = data;
          this.dashboardRolesObligatedSubject.callsCharged++;
      }, (errorData:any)=>{
      });
  }

  goTo(option: any){
    this.stateManagement.setStateUrl(window.location.pathname);
    let loaderOpen = 'open-new-concept-window';
    if(option === 'roles'){
        loaderOpen = 'open-roles-window';
        sessionStorage.setItem('productId', this.dashboardRolesObligatedSubject.productId);
        sessionStorage.setItem('productName', this.product.name);
    }
    this.loader.openLoader(loaderOpen);
    this.router.navigate([option]);
  }

}


