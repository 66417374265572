import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'branch-tree',
  templateUrl: './branch-tree.component.html'
})
export class BranchTreeComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  branchTree: any = {
    children: []
  }
  constructor(private globalCfg: GlobalCfgFactory) { }

  @Input() childrenList: any = null;
  @Input() product: any = null;
  @Input() type: any = null;
  @Input() level: any = null;
  @Output() selectChildrenEmit = new EventEmitter();
  @Output() filterClient = new EventEmitter();

  ngOnInit(): void {
    this.branchTree.typeChildren = this.type === 'move'? 'subFolders' : 'children';
  }

  selectItem(branch:any){
    if(this.type === 'move' || !(branch.concepts.length && this.product === 2)){
        this.selectChildrenEmit.emit({children: branch});
    }
  }

  changeShow(children: any){
    children.show = !children.show;
  }

  selectChildrenTree(e:any){
    this.selectChildrenEmit.emit({ children: e.children });
  }
}
