import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';

@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html'
})
export class TaskListComponent implements OnInit, OnChanges {
  skin: string = this.globalCfg.skin;
  taskList: any ={
    orderOperationDates: true,
    amountElements: 0,
    view: 'all',
    dateFilter: 'creationDate',
    filterTitle: '',
    elementValue: '',
    filters: this.skin === 'icam-red'? ['type', 'state'] : ['type', 'state', 'evaluation'],
    typeListFilter: [],
    stateListFilter: [],
    evaluationListFilter: [],
    filterTaskList: [],
    titleOrganization: '',
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50,
      maxSize: 5
    },
    currentPage: 1,
    typeFilter: {
      name: this.translate.instant('common.type'),
      id: this.commonUtilsService.createId()
    },
    evaluationFilter: {
      name: this.translate.instant('common.implantation'),
      id: this.commonUtilsService.createId()
    },
    stateFilter: {
      name: this.translate.instant('common.state'),
      id: this.commonUtilsService.createId()
    },
    taskView: [
      {
          name: this.translate.instant('task-list.all-tasks'),
          viewOption: 'all'
      },
      {
          name: this.translate.instant('task-list.my-tasks'),
          viewOption: 'mytask'
      },
      {
          name: this.translate.instant('task-list.process-tasks'),
          viewOption: 'process'
      }
    ]
  }

  constructor( private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private filterColumnService: FilterColumnService) { }

  @Input() tasksLists: any = [];
  @Input() product: any = null;
  @Input() userPermissions: string = 'none';
  @Output() changeTaskList = new EventEmitter();
  @Output() launchActionEmit = new EventEmitter();

  ngOnInit(): void {
    this.taskList.selectedView = this.taskList.taskView[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['tasksLists']?.currentValue?.length){
      this.tasksLists.forEach((task:any) => {
        if(task.videoconference){
          task.type = this.translate.instant('task-edit.meeting-online');
        }
        if (this.taskList.view === 'process' && task.processState){
            task.state = task.processState.name;
        }
      });
      this.taskList.filterTaskList = this.tasksLists;
      this.taskList.amountElements = this.commonUtilsService.findByWord(this.tasksLists, this.taskList.filterTitle, ['title', 'state', 'client', 'evaluation', 'product']).length
      this.getFilters();
    }else if(changes['tasksLists']?.previousValue?.length && !changes['tasksLists']?.currentValue?.length){
      this.taskList.filterTaskList = this.tasksLists;
      this.taskList.amountElements = 0;
    }
    if(changes['product']){
      this.taskList.titleOrganization = this.product.productId==='blanqueo'?'common.client':'common.organization';
      this.taskList.evaluationFilter.name = this.product.productId==='blanqueo'?this.translate.instant('task-list.opportunity-assessment'):this.translate.instant('common.implantation');
    }
  }

  getFilters(){
    this.taskList.filters.forEach((filter:any) => {
      this.taskList[filter+'List'] = this.filterColumnService.extractList(filter, this.tasksLists)
    });
  }

  newTask(){
    this.launchActionEmit.emit({
        action:{
            action:'openTask',
            task:{}
        }
    });
  }

  selectTypeOperation( item:any, type:string){
    let listFilter = this.taskList[type+'ListFilter'];
    this.taskList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    this.taskList[type+'Filter'].hasFilter = !!(this.taskList[type+'ListFilter'].length);
    item.selected = !item.selected;
    this.filterTask();
  }

  filterTask(){
    let taskFilter:any = [];
    taskFilter = this.filterColumnService.filterByWord(this.taskList.elementValue, this.tasksLists, 'evaluation');
    this.taskList.filters.forEach((filter:any) => {
      let filteredList = this.taskList[filter+'ListFilter'];
      taskFilter = this.filterColumnService.filterBycolumn(taskFilter, filteredList, filter);
    });
    this.taskList.amountElements = this.commonUtilsService.findByWord(this.tasksLists, this.taskList.filterTitle, ['title', 'state', 'client', 'evaluation', 'product']).length
    this.taskList.filterTaskList = taskFilter;
  }

  preventClick(e:any){
    e.stopPropagation();
  }

  searchBy(type:any){
    if(this.taskList.dateFilter === type){
      this.taskList.orderOperationDates = !this.taskList.orderOperationDates;
    }else{
      this.taskList.dateFilter = type;
      this.taskList.orderOperationDates = true;
    }
  }

  toggleSearch() {
    this.taskList.search = !this.taskList.search;
    if(!this.taskList.search){
      this.taskList.filterTitle = '';
    }
  }

  deleteOption(task:any){
    this.launchActionEmit.emit({
        action:{
            action:'deleteTask',
            task:task
        }
    })
  }

  changeView(type:any){
    this.taskList.view = type;
    this.taskList.filterTitle = '';
    this.taskList.elementValue = '';
    this.taskList.stateFilter.hasFilter = false;
    this.taskList.typeFilter.hasFilter = false;
    this.taskList.evaluationFilter.hasFilter = false;
    this.taskList.typeListFilter = [];
    this.taskList.stateListFilter = [];
    this.taskList.evaluationListFilter = [];
    this.changeTaskList.emit({view:type});
    this.taskList.taskView.forEach((view:any) => {
      if(view.viewOption === type){
        this.taskList.selectedView = view;
      }
    });
  }

  editTask(task:any){
    this.launchActionEmit.emit({
        action:{
            action:'openTask',
            task:task
        }
    });
  }

// function _changeTextTooltip(task){
//     task.textTooltipEdit = _literals['common.edit'];
//     if(!vm.canCreate || task.taskStateId ===5 || task.taskStateId ===6){
//         task.textTooltipEdit = _literals['common.view'];
//     }
//  }

  openFocus(){
    this.taskList.openFocusInput = !this.taskList.openFocusInput;
  }

  showTooltip(element:string = ''){
    return this.commonUtilsService.showTooltip(element);
  }

  filterElementOn(event:any, type:string){
    this.selectTypeOperation(event.source, type)
  }

  getColors(task:any){
    return this.commonUtilsService.getColors(task.taskStateId);

  }
}
