import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';

@Component({
  selector: 'legal-document',
  templateUrl: './legal-document.component.html'
})
export class LegalDocumentComponent implements OnInit {
  isLanding = this.route.snapshot.firstChild?.data['isLanding'];
  skin: string = this.globalCfg.skin;
  legalDocument: any = {
    referenceClass: null,
    error: null
  }
  constructor(private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private registerService: RegisterMixtapeService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    private commonUtilsService: CommonUtilsService) { }

  @Input() references: any = null;
  @Input() registerEvent: any = null;
  @Input() selected: any = null;

  ngOnInit(): void {
    if (this.selected) {
      this.selectReference(this.selected);
    }
  }

  selectReference(reference:any) {
    this.legalDocument.error = null;
    this.legalDocument.selected = reference;
    this.legalDocument.referenceClass = reference.type + ' ' + reference.target.split('|')[0];

    if (reference.target) {
        let params: string = '?target=' + encodeURIComponent(reference.target);
        let method: string = 'legaldocs/document/' + reference.type;
        this.apiService.get(method, params).then((data:any)=>{

          data = data === 'REPEALED'? '<div><span class="repealed"><i class="lf-icon-information"></i>' + this.translate.instant('legal-document.repealed') + '</div>': data;
          this.legalDocument.selected.document = this.sanitizer.bypassSecurityTrustHtml(data);
        }).catch((errorData:any)=>{
          this.legalDocument.error = errorData.status === 404? this.translate.instant('legal-document.not-found') : this.translate.instant('legal-document.unexpected-error');
        });
    }
    this.legalDocument.registerEvent = this.registerEvent
    this.legalDocument.registerEvent['Legal Doc Title'] = reference.text;
    this.legalDocument.registerEvent['Legal Doc Type'] = reference.type;
    this.registerService.register( this.legalDocument.registerEvent);
  }

  showTooltip(id:any){
      return !this.commonUtilsService.showTooltip(id);
  }

}

