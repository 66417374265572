import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ngHide]'
})
export class NgHideDirective implements OnChanges {
  @Input() ngHide: any;
  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['ngHide']){
      this.elementRef.nativeElement.classList.remove('common__hide');
      if(this.ngHide){
        this.elementRef.nativeElement.classList.add('common__hide');
      }
    }
  }
}
