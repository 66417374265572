<div class="request-tracking">
    <div class="request-tracking__block-context">
        <div class="common__modal-header-block">
            <i class="lf-icon-review common__icon-header"></i>
            <span class="common__text-header"
                [afTranslate]="'request-tracking.title'">_Seguimiento de solicitudes y descarga de auditorías</span>
            <i class="lf-icon-close common__icon-close-modal"
                (click)="handleDismiss()"></i>
        </div>
        <div class="request-tracking__block-body">
            <div class="request-tracking__block-table">
                <div class="request-tracking__block-header-table">
                    <div class="request-tracking__col request-tracking__col--request">
                        <filter-by-list [type]="'list'"
                            [item]="requestTracking.requestFilter"
                            [list]="requestTracking.requestList"
                            [topStyle]="'21'"
                            (filterElementEmit)="selectTypeOperation($event, 'request')">
                        </filter-by-list>
                    </div>
                    <div class="request-tracking__col request-tracking__col--header-date">
                        <div (click)="orderDate()">
                            <p class="common__table-header-text"
                                [afTranslate]="'common.date'">_Fecha</p>
                            <i class="lf-icon-arrow-down common__order-by  common__order-by--active"
                                [ngClass]="{'lf-icon-arrow-up': !requestTracking.orderOperationDate}"></i>
                        </div>
                    </div>
                    <div class="request-tracking__col request-tracking__col--user">
                        <div ngbDropdown
                            [open]="requestTracking.searchUsers"
                            [autoClose]="'outside'">
                            <div ngbDropdownToggle>
                                <p class="common__table-header-text common__cursor-pointer"
                                    [afTranslate]="'contacts.recipients'">_Destinatarios</p>
                                <i class="common__filter-icon lf-icon-filter-1"
                                    [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': requestTracking.filterValue.length}"></i>
                            </div>
                            <ul class="common__select-list-with-input common__select-list-with-input--top-23"
                                ngbDropdownMenu role="menu">
                                <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                    <input type="text"
                                        class="common__dropdown-inner-input"
                                        (keyup)="filterRequest('addressee')"
                                        [placeholder]="'common.search-name' | afTranslate"
                                        [(ngModel)]="requestTracking.filterValue"
                                        [focusWhen]="requestTracking.searchUsers">
                                    <i class="common__search-icon"
                                        [ngClass]="requestTracking.filterValue ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
                                        (click)="resetFilter($event)"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="request-tracking__col request-tracking__col--state">
                        <filter-by-list [type]="'list'"
                            [item]="requestTracking.stateFilter"
                            [list]="requestTracking.stateList"
                            [topStyle]="'21'"
                            (filterElementEmit)="selectTypeOperation($event, 'state')">
                        </filter-by-list>
                    </div>
                    <div class="request-tracking__col request-tracking__col--audit-header">
                        <p class="common__table-header-text"
                            [afTranslate]="'request-tracking.audit'">_Auditoría</p>
                    </div>
                    <div class="request-tracking__col request-tracking__col--action-header">
                        <p class="common__table-header-text common__table-header-text--right"
                            [afTranslate]="'common.actions'">_Acciones</p>
                    </div>
                </div>
                <div class="request-tracking__block-body-table"
                    [ngClass]="{'request-tracking__block-body-table--scroll': requestTracking.showScroll}">
                    <div class="request-tracking__block-img"
                        [ngShow]="requestTracking.searchingList">
                        <img [src]="requestTracking.loaderImg"
                            alt="spinner"
                            class="duplicate-documentation__spinner-loader"/>
                    </div>
                    <div class="request-tracking__row-body"
                        [ngClass]="{'request-tracking__row-body--hidden': requestTracking.searchingList,
                          'request-tracking__row-body--scroll': requestTracking.showScroll}"
                        *ngFor="let requestOption of requestTracking.filterListTracking | afOrderBy:'creationDate': requestTracking.orderOperationDate; let index = index"
                        [id]="'request-tracking-list-'+index">
                        <div class="request-tracking__col request-tracking__col--request"
                            [ngClass]="requestOption.users.length > 1? 'request-tracking__col--top':'request-tracking__col--top-one'">
                            <i [class]="'request-tracking__icon-type-request '+requestOption.icon"
                                [ngClass]="requestOption.users.length > 1? 'request-tracking__icon-type-request--more':'request-tracking__icon-type-request--one'"></i>
                            <span class="request-tracking__text-col-request">{{requestOption.request}}</span>
                        </div>
                        <div class="request-tracking__col request-tracking__col--date"
                            [ngClass]="requestOption.users.length > 1? 'request-tracking__col--top':'request-tracking__col--top-one'">
                            <span>{{requestOption.creationDate | convertDate:'without-time'}}</span>
                        </div>
                        <div class="request-tracking__col request-tracking__col--user"
                            [ngClass]="requestOption.users.length > 1? 'request-tracking__col--top':'request-tracking__col--top-one'">
                            <div *ngFor="let user of requestOption.users"
                                class="request-tracking__list-element-user">
                                <parragraph-tooltip [text]="user.addressee"></parragraph-tooltip>
                            </div>
                        </div>
                        <div class="request-tracking__col request-tracking__col--state"
                            [ngClass]="requestOption.onlyFirst || requestOption.users.length===1? 'request-tracking__col--only-first':'request-tracking__col--state-body'">
                            <div *ngFor="let userState of requestOption.users; let first = first"
                                class="request-tracking__block-state"
                                [ngShow]="!requestOption.onlyFirst || first">
                                <span-tooltip  [class]="'task-concept__task-state task-concept__task-state--' + (userState.stateId | colors)"
                                    [text]="userState.state"></span-tooltip>
                            </div>
                        </div>
                        <div class="request-tracking__col request-tracking__col--audit"
                            [ngClass]="requestOption.onlyFirst || requestOption.users.length===1? 'request-tracking__col--only-first-audit':'request-tracking__col--top'">
                            <div *ngFor="let userAudit of requestOption.users; let first = first"
                                [ngShow]="!requestOption.onlyFirst || first">
                                    <span [ngShow]="!userAudit.audit"
                                        class="request-tracking__text-not-available request-tracking__element-users"
                                        [afTranslate]="'request-tracking.not-available'">_No disponible</span>
                                    <button [ngShow]="userAudit.audit"
                                        class="request-tracking__button-download-audit"
                                        (click)="downloadAudit(requestOption, userAudit)">
                                        <span class="request-tracking__text-download-audit"
                                            [afTranslate]="'request-tracking.download-audit'">_Descargar auditoría</span>
                                        <i class="lf-icon-download request-tracking__icon-download-audit"></i>
                                    </button>
                            </div>
                        </div>
                        <div class="request-tracking__col request-tracking__col--action">
                            <div class="request-tracking__options-box">
                                <i class="lf-icon-visible request-tracking__icon-options"
                                    (click)="showTracking(requestOption)"
                                    matTooltip="{{'request-tracking.show-details' | afTranslate}}"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    ></i>
                            </div>
                            <i class="lf-icon-kebab-menu common__icon-ellipsis request-tracking__icon-ellipsis"></i>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>
