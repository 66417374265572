import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'office-online',
  templateUrl: './office-online.component.html',
})
export class OfficeOnlineComponent {
  dataOffice:any = this.router.snapshot.data['dataOffice'];
  documentObjectId:any = this.router.snapshot.params['id'];
  wopiSettingUpUrl: any = this.dataOffice.wopiSettingUpUrl;
  allowedOrigins: any = this.dataOffice.allowedOrigins;
  conceptId: any = this.dataOffice.conceptId;
  conceptObjectId: any = this.dataOffice.conceptObjectId;

  constructor(private context: ContextFactory,
    private router: ActivatedRoute) { }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
      if (this.originIsAllowed(event.origin)) {
          if (event.data && event.data.message == 'get-context') {
              let data = {
                  conceptId: this.conceptId,
                  conceptObjectId: this.conceptObjectId,
                  documentObjectId: this.documentObjectId,
                  jwtToken: this.context.jwtToken
              };
              event.source.postMessage({
                  message: 'context',
                  data: data
              }, event.origin, );
          }
      }
  }

  originIsAllowed(origin: any) {
      if (this.allowedOrigins) {
          let origins = this.allowedOrigins.split(';');
          for (let i = 0; i < origins.length; i++) {
              if (origin === origins[i]) {
                  return true;
              }
          }
      }
      return false;
  }

}
