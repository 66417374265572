import * as d3 from 'd3'
import $ from 'jquery';

import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'heat-map-graph',
  templateUrl: './heat-map-graph.component.html'
})
export class HeatMapGraphComponent implements OnInit, OnChanges {
  skin: string = this.globalCfg.skin;
  reportId: any = this.route.snapshot.params['reportId']
  graphId: any = this.commonUtilsService.createId();
  d3Service: any = d3;

  heatMapGraph: any = {
    heatPointSelect: null,
    specialGraphAD: false,
    id: this.commonUtilsService.createId()
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private container: ElementRef,
    private commonUtilsService: CommonUtilsService) { }

  @Input() graphData: any = null;
  @Input() id: any = '';

  ngOnInit(): void {
    this.heatMapGraph.specialGraphAD = window.location.host === 'adolfodominguez.lefebvre.es' && this.reportId === '164';
  }

  ngOnChanges(){
    let that: any = this.heatMapGraph;
    if(this.graphData){
      that.graphData = this.graphData.graph;
      that.graph ={};
      this.drawHeatMap();
      this.clickMenu();
    }
  }


      openRisk(risk:any){
        this.broadcastService.broadcast('openRisk', {risk: risk});
      }

      drawHeatMap(){
        let reportTableElement: any = $('.report-table')
        let that: any = this.heatMapGraph;
          if(reportTableElement.width() >= 1400){
              that.scale = 4;
          }else if( reportTableElement.width() >= 1250 && reportTableElement.width() < 1400){
              that.scale = 3.5;
          }else if( reportTableElement.width() >= 1127 && reportTableElement.width() < 1250){
              that.scale = 3;
          }else if( reportTableElement.width() >= 1052 && reportTableElement.width() < 1127){
              that.scale = 2.7;
          }else if( reportTableElement.width() >= 980 && reportTableElement.width() < 1052){//969
              that.scale = 2.5;
          }else if( reportTableElement.width() >= 890 && reportTableElement.width() < 980){//969
              that.scale = 2.2;
          }else{
              that.scale = 2;
          }

          this.configureGraph();
          this.generateAsisX();
          this.generateAxisY();
         // this.generateToolip();
          this.paintGraph();
          this.convertToImg();
      }

      configureGraph(){        
        let that: any = this.heatMapGraph
          let widthRectangle = 200 / that.graphData.axisX.length;
          let heightRectangle = 100 / that.graphData.axisY.length;

          that.graphData.squareWidth = widthRectangle * that.scale;
          that.graphData.squareHeight = heightRectangle * that.scale;
          that.graphData.width = 206 + (that.graphData.axisX.length * that.graphData.squareWidth);
          that.graphData.height = 58 + (that.graphData.axisY.length * that.graphData.squareHeight);

          that.graph.svg = d3.select(this.container.nativeElement)
              .append("svg")
              .attr('id', this.heatMapGraph.id)
              .attr("viewBox", `0 0 ${that.graphData.width} ${that.graphData.height}`)
              .append("g")
              .attr('class', 'heat-map-graph__heat-map-graph')
              .attr("transform","translate(120, 0)");
          if (this.reportId === "164") {
              that.graph.svg.style("shape-rendering", "crispEdges");
          }
      }

      generateAsisX(){
        let that: any = this.heatMapGraph
          that.graph.x = d3.scaleBand()
              .range([ 0, (that.graphData.squareWidth * that.graphData.axisX.length)  ])
              .domain(that.graphData.axisX);

          that.graph.svg.append("g")
              .attr("class", "heat-map-graph__axis-x")
              .attr("id", `axxis-x-${this.heatMapGraph.id}`)
              .attr("transform", "translate(-30," + (that.graphData.height-27) + ")")
              .call(d3.axisBottom(that.graph.x).tickSize(0));

          d3.selectAll('.heat-map-graph__axis-x path')
              .attr("stroke-width", 4)
              .attr("stroke", ()=>{
                return this.skin==='gm-law'? '#000000' : '#001978';
              })
              .attr("transform", "translate(30,0)");

          d3.selectAll('.heat-map-graph__axis-x .tick text')
            .attr("transform", "translate(30,10)")
            .attr('class', ()=>{
              return 'heat-map-graph__axis-tick-text heat-map-graph__axis-tick-text--'+ this.skin;
            })

          d3.selectAll(`#axxis-x-${this.heatMapGraph.id}`)
            .append('foreignObject')
            .attr("x", "30")
            .attr("width", "800")
            .attr("y", "-30")
            .attr("height", "30")
            .append('xhtml:div')
            .attr("class", `heat-map-graph__x-axis-title heat-map-graph__x-axis-title--${this.skin}`)
            .text(this.graphData.xAxxis);
      }

      generateAxisY(){
        let that: any = this.heatMapGraph
          that.graph.y = d3.scaleBand()
              .range([ (that.graphData.squareHeight * that.graphData.axisY.length), 0 ])
              .domain(that.graphData.axisY);

          that.graph.svg.append("g")
              .attr("class", "heat-map-graph__axis-y")
              .attr("id", `axxis-y-${this.heatMapGraph.id}`)
              .attr("transform", "translate(-30, 30)")
              .call(d3.axisLeft(that.graph.y).tickSize(0));

          d3.selectAll('.heat-map-graph__axis-y path')
              .attr("stroke-width", 4)
              .attr("stroke", ()=>{
                  return this.skin==='gm-law'?'#000000' : '#001978';
              })
              .attr("transform", "translate(0,-30)");

          d3.selectAll('.heat-map-graph__axis-y .tick text')
              .attr("transform", "translate(-10,-25)")
             .attr('class', ()=>{
                  return 'heat-map-graph__axis-tick-text heat-map-graph__axis-tick-text--'+ this.skin;
              })
              
          d3.selectAll(`#axxis-y-${this.heatMapGraph.id}`)
            .append('foreignObject')
            .attr("width", "30")
            .attr("height",  `${that.graphData.squareHeight *  that.graphData.axisY.length}`)
            .attr("y", "-30")
            .append('xhtml:div')
            .attr("class", `heat-map-graph__y-axis-title heat-map-graph__y-axis-title--${this.skin}`)
            .text(this.graphData.yAxxis);
      }

      generateToolip(){
          this.heatMapGraph.graph.tooltip = d3.select("#"+this.graphId)
            .style('visibility', 'none')
      }

      paintGraph(){
          let that: any = this.heatMapGraph;
          let heatPoint = that.graph.svg.selectAll()
              .data(that.graphData.data, (d:any)=> {return d.x+':'+d.y;})
              .enter()
              .append("g")

          heatPoint.append('rect')
              .attr("x", (d:any)=> {
                  return that.graph.x(d.x)
              })
              .attr("y", (d:any)=> {
                  return that.graph.y(d.y)
              })
              .attr("width", that.graphData.squareWidth )
              .attr("height", that.graphData.squareHeight )
              .style("fill", (d:any)=> {
                  return d.backgroundColor
              });

      heatPoint.append("text")
        .attr("x", (d:any)=> {
            let distance =  4;
            if( d.risks.length > 9){
                distance =  8;
            }else if(d.risks.length === 1){
                distance =  2
            }
            return that.graph.x(d.x)+((that.graphData.squareWidth/2)- distance);
        })
        .attr("y", (d:any)=> {
            return that.graph.y(d.y)+((that.graphData.squareHeight/2)+6)
        })
        .attr("class", (d:any)=>{
            return "heat-map-graph__risk-value heat-map-graph__risk-value--"+d.id})
        .text((d:any)=>{
          if(d.risks.length){
              return d.risks.length;
          }
        })

      heatPoint.append("rect")
        .attr("x", (d:any)=> {
            let xt = that.graph.x(d.x);
            return xt + ((that.graphData.squareWidth/2)-20)
        })
        .attr("y", (d:any) => {
            let yt = that.graph.y(d.y);
            return yt + ((that.graphData.squareHeight/2)-20);
        })
        .attr("width", 40 )
        .attr("height", 40 )
        .attr("class", (d:any)=>{
                return d.risks.length?'heat-map-graph__risk-box':'';
        })
        .attr('fill', 'transparent')
        .attr("rx", "150")
        .on("click", (d:any)=>{
          this.heatMapClick(d)
        });

      }

      convertToImg(){
         // chartToGraph.convert(vm.graph.svg.node())
      }

      heatMapClick(d:any) {
        let that: any = this.heatMapGraph
          if(d.risks.length){
              if(that.heatPointSelect && that.heatPointSelect.cellText === d.cellText){
                  this.closeTooltip()
              }else{
                this.d3Service.select("#"+this.graphId)
                  .style('visibility', 'visible')
                  .style("left", (that.graph.x(d.x) + ((that.graphData.squareWidth/2))-3)+"px")
                  .style("top", (that.graph.y(d.y) + ((that.graphData.squareHeight/2)+32))+ "px")
                  setTimeout(()=>{
                      that.heatPointSelect = d
                  })
              }

          }
      }

      closeTooltip(){
        let that: any = this.heatMapGraph
        this.d3Service.select("#"+this.graphId).style('visibility', 'hidden')
          that.heatPointSelect = null;
      }

      clickMenu(){
          $(document).off('click', (event)=>{this.closeMenu(event)});
          $(document).on('click', (event)=>{this.closeMenu(event)});
      }

      closeMenu(event:any){
        let that: any = this.heatMapGraph
          if( typeof event.target.className === 'string'){
              if ( that.heatPointSelect !== null && event.target.className !== undefined && event.target.className.indexOf('heat-map-graph') === -1){
                  this.closeTooltip()
              }
          }else if(event.target.className.animVal === '' && event.target.className.baseVal === ''){
              if(that.heatPointSelect !== null){
                  this.closeTooltip()
              }
          }

      }
  }
