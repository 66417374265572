import { Component, OnInit } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import $ from 'jquery';

@Component({
  selector: 'snippets-reusable',
  templateUrl: './snippets-reusable.component.html'
})
export class SnippetsReusableComponent implements OnInit {
  skin: any = this.globalCfg.skin;
  productId: any = this.context.settings.defaultProduct;
  snippetsReusable: any = {
    charged: false,
    searchSnippets: '',
    snippet: {
      shortId: '',
      title: ''
    },
    utilityInfo: {
      label: this.translate.instant('snippets-reusable.utilities'),
      description: this.translate.instant('snippets-reusable.utilities-description'),
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true
    },
    shortIdField: {
      label: this.translate.instant('snippets-reusable.short-id'),
      id: 'short-id',
      required: true,
      maxLength: 20
    },
    titleField: {
      label: this.translate.instant('snippets-reusable.descriptive-title'),
      id: 'descriptive-title',
      required: true,
      maxLength: 100
    },
    textField: {
      label: this.translate.instant('snippets-reusable.text-snippet'),
      fieldId: 'text-snippets',
      fieldTypeId: 'rich',
      required: true,
      isEditable: true,
      noEditable: false
    },
    snippetsList: []
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private context: ContextFactory) { }

  ngOnInit(): void {
    this.loader.openLoader('open-snippets-window-loader');
    this.getSnippetsList();
  }

  getSnippetsList(){
    this.apiService.get('snippets/product', this.productId)
      .then((data:any)=>{
          this.snippetsReusable.snippetsList = data;
          this.snippetsReusable.countSnippetsList = this.snippetsReusable.snippetsList.length;
          this.snippetsReusable.charged = true;
          this.closeLoader();
      }, (error:any)=>{
          this.snippetsReusable.charged = true;
          this.loader.closeError();
      })
  }

  closeLoader(){
    this.loader.closeLoader(['open-snippets-window-loader','front-login-login']);
  }

  itemSelected(type: any, newValue: any){
    this.snippetsReusable.snippet[type] = newValue;
    this.snippetsReusable[type+'Field'].error = false;
  }

  changeViewWindow(){
    this.commonUtilsService.goToStateUrl();
  }

  saveNewSnippet(){
      if(this.checkFields()){
        this.loader.openLoader('open-create-snippet');
          this.snippetsReusable.newSnippet = {
              shortId: this.snippetsReusable.snippet.shortId,
              title: this.snippetsReusable.snippet.title,
              text: this.snippetsReusable.textField.description
          }
        this.createSnippet(this.snippetsReusable.newSnippet);
      }
  }


  checkFields(){
    let validateFields = true;
    if(!this.snippetsReusable.snippet.shortId){
        this.snippetsReusable.shortIdField.error = true;
        validateFields = false;
    }
    if(!this.snippetsReusable.snippet.title){
        this.snippetsReusable.titleField.error = true;
        validateFields = false;
    }
    if(!this.snippetsReusable.textField.description){
        this.snippetsReusable.textField.error = true;
        validateFields = false;
    }else{
        this.snippetsReusable.textField.error = false;
    }

    return validateFields;
  }

  createSnippet(snippet:any){
    snippet.productId = this.productId;
    this.apiService.add('snippets', snippet)
      .then((data:any)=>{
          this.loader.closeLoader('open-create-snippet');
          this.snippetsReusable.snippetsList.push(snippet);
          this.snippetsReusable.countSnippetsList = this.snippetsReusable.snippetsList.length;
          this.openInfoModal(this.translate.instant('snippets-reusable.save-snippet-ok'));
      }, (error:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('snippets-reusable.error-create-snippet'));
      })
  }

  openInfoModal(text: any){
    this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  errorModal(text: any){
    this.modalService.adviceModal(text, 'error:accept:warning');
  }

  createNewSnippet(){
    this.snippetsReusable.snippet = {
          shortId: '',
          title: ''
      };
      let configuration = {
          product: this.context.settings.defaultProduct,
          snippet: this.snippetsReusable.snippet,
          type: 'new'
      }
      this.modalService.newSnippetModal(this.snippetsReusable.snippetsList, configuration)
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('open-create-snippet');
            this.createSnippet(result.snippet);
          }
        })
  }

  editSnippet(snippet: any){
      let configuration = {
          product: this.context.settings.defaultProduct,
          snippet: cloneDeep(snippet),
          type: 'edit'
      }
      this.modalService.newSnippetModal(this.snippetsReusable.snippetsList, configuration)
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('open-edit-snippet');
            this.updateSnippet(result.snippet);
          }
        })
  }

  updateSnippet(snippet:any){
    this.apiService.update('snippets', undefined, snippet)
      .then((data:any)=>{
          this.loader.closeLoader('open-edit-snippet');
          this.snippetsReusable.snippetsList = this.commonUtilsService.replaceElement(snippet, this.snippetsReusable.snippetsList, 'snippetId', snippet);
          this.openInfoModal(this.translate.instant('snippets-reusable.save-snippet-edit-ok'));
      }, (error:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('snippets-reusable.error-edit-snippet'));
      })
  }
  clipboardText(text: any=''){
    navigator.clipboard.writeText(text)
    .then(() => {
      }, () => {});
  }
  copySnippet(snippet: any) {
      let copyText = snippet.text.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&').replace(/<[^>]*>/ig, ' ')
                                  .replace(/<\/[^>]*>/ig, ' ')
                                  .replace(/&nbsp;|&#160;/gi, ' ')
                                  .replace(/\s+/ig, ' ')
                                  .trim();
      this.commonUtilsService.clipboardText(copyText);
      this.openInfoModal(this.translate.instant('snippets-reusable.copy-text-message'));
  }

  deleteSnippet(snippet: any){
    let text = this.translate.instant('snippets-reusable.delete-snippet-question').replace('{snippet}',  snippet.title);
    this.modalService.adviceModal(text, 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('open-delete-snippet');
          this.delete(snippet);
        }
      })
  }

  delete(snippet: any){
    this.apiService.delete('snippets/', snippet.snippetId)
      .then((data:any)=>{
          this.snippetsReusable.snippetsList = this.commonUtilsService.deleteElement(snippet, this.snippetsReusable.snippetsList, 'snippetId');
          this.snippetsReusable.countSnippetsList = this.snippetsReusable.snippetsList.length;
          this.openInfoModal(this.translate.instant('snippets-reusable.deleted-snippet-ok'));
          this.loader.closeLoader('open-delete-snippet');
      }, (error:any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('snippets-reusable.error-delete-snippet'));
      })
  }

  toggleSearch(){
      this.snippetsReusable.search = !this.snippetsReusable.search;
      if (!this.snippetsReusable.search) {
          this.snippetsReusable.searchSnippets = '';
          this.cleanSearch();
      }
  }

  searchTextfunction(e: any){
    if(e.keyCode !== 13 && this.snippetsReusable.searchSnippets !== this.snippetsReusable.searchSnippetsCopy){
        this.cleanSearch();
        let that = this;
        if (this.snippetsReusable.searchSnippets && this.snippetsReusable.searchSnippets.length !== 0) {
            $('.snippets-reusable__search-text').each(function (i, e) {
                let search = $(e).html();
                let searchInnerText = e.innerText;
                let characters:any={
                    "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
                    "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u",
                    "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
                    "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U" };
                let regExpCharacters=/[áàéèíìóòúù]/ig;
                let textSearchWithoutAccent = that.snippetsReusable.searchSnippets.replace(regExpCharacters,function(data: any){return characters[data]});
                let searchWithoutAccent = searchInnerText.replace(regExpCharacters,function(data: any){return characters[data]});
                if(searchWithoutAccent.toLowerCase().indexOf(textSearchWithoutAccent.toLowerCase()) !== -1){
                    let resultTagArray = search.split('');
                    let textModify = '';
                    let textHtml = '';
                    let tagHTML = false;
                    let idParentElement = e.parentElement?e.parentElement.id:'';
                    that.snippetsReusable.snippetsList[parseInt(idParentElement)].showSearch = true;
                    for(let i = 0; i < resultTagArray.length; i++){
                      let letterResult = resultTagArray[i];
                      let resultArrayWithoutAccent = letterResult.replace(regExpCharacters,function(data: any){return characters[data]});
                      if(resultTagArray[i] === '<' && e.className.indexOf('snippets-reusable__value-bind-html') !== -1){
                          tagHTML = true;
                      }else if(tagHTML && resultTagArray[i] === '>'){
                          tagHTML = false;
                      }
                      if(!tagHTML && textSearchWithoutAccent.toLowerCase().indexOf(resultArrayWithoutAccent.toLowerCase()) !== -1 && textModify.length < that.snippetsReusable.searchSnippets.length){
                          textModify += resultTagArray[i];
                          let textModifyWithoutAccent = textModify.replace(regExpCharacters,function(e){return characters[e]});
                          if(textModifyWithoutAccent.toLowerCase() === textSearchWithoutAccent.toLowerCase()){
                              textHtml += '<span class="snippets-reusable__text-searched">' + textModify + '</span>';
                              textModify = '';
                          }else if(i === resultTagArray.length-1){
                              textHtml += textModify;
                          }
                      }else{
                          textHtml += textModify + letterResult;
                          textModify = '';
                      }
                    }
                    $(e).html(textHtml);
                }
            });
            this.snippetsReusable.countSnippetsList = 0;
            this.snippetsReusable.snippetsList.forEach((snippet:any) => {
              if(!snippet.showSearch){
                snippet.hideSnippet = true;
              }else{
                this.snippetsReusable.countSnippetsList++;
              }
            });
        }
    }
  }

  cleanSearch(){
    this.snippetsReusable.searchSnippetsCopy = this.snippetsReusable.searchSnippets;
    this.snippetsReusable.snippetsList.forEach((snippet:any) => {
      snippet.hideSnippet = false;
      if(snippet.showSearch){
          delete snippet.showSearch;
      }
    });
    this.snippetsReusable.countSnippetsList = this.snippetsReusable.snippetsList.length;
    $(".snippets-reusable__search-text").each(function(i, e){
        let search = $(e).html();
        search = search.split('<span class="snippets-reusable__text-searched">').join('');
        search = search.split('</span>').join('');
        $(e).html(search);
    });
  }
}
