<div class="field-list__template-box"
  [ngClass]="{'field-list__template-box--poll': concept.poll}">
  <div class="concept-legal-documentation__border-box"
    [ngShow]="skin==='gm-law'">
  </div>
  <div class="predefined-templates__template-header">
      <i class="lf-icon-compliance predefined-templates__block-icon"></i>
      <p class="predefined-templates__block-text predefined-templates__block-text--attachment-title"
          [afTranslate]="'field-items.form-fields'">Campos de formulario</p>
  </div>
  <div class="field-list__advisor-text-block">
      <p class="field-list__advisor-inner-text"
          [afTranslate]="'field-items.drag-form-fields'">Arrastra a la zona de puntos los campos que quieres incluir en el formulario</p>
  </div>
  <ul class="field-list__field-list"
      [ngClass]="{'field-list__field-list--poll': concept.poll}"
      [dragula]="'fieldList'"
      [dragulaModel]="fieldList.fieldList"
      id="fieldType">
      <li class="field-list__list"
          *ngFor="let field of fieldList.fieldList; let index = index"
          [ngShow]="field.icon">
          <i [class]="'field-list__icons-file '+field.icon"></i>
          <span-tooltip class="field-list__parragraph-element"
            [text]="field.title"></span-tooltip>
      </li>
  </ul>
</div>
