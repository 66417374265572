<div id="canvas" 
    class="rappid" 
    [ngClass]="{'rappid__from-view':from==='view'}"
    #canvas>
</div>
<ul class="process-edit__toolbar"
    *ngIf="designing">
    <li class="process-edit__toolbar-separator">
        <button class="common__icon-button"
            (click)="addState()">
            <i class="lf-icon-process-step-new"></i>
        </button>
    </li>
    <li>
    <button class="common__icon-button"
            (click)="zoomMore()">
            <i class="lf-icon-zoom-in"></i>
    </button>
    </li>
    <li class="process-edit__toolbar-separator"
            (click)="zoomMinus()">
        <button class="common__icon-button">
            <i class="lf-icon-zoom-out"></i>
        </button>
    </li>
    <li>
        <button class="common__icon-button"
            (click)="centerSelected()">
            <i class="lf-icon-map-marker"></i>
        </button>
    </li>
    <li class="process-edit__toolbar-separator">
        <button class="common__icon-button"
            (click)="fit()">
            <i class="lf-icon-visible"></i>
        </button>
    </li>
    <li>
        <button class="common__icon-button"
            (click)="layout()">
            <i class="lf-icon-reorganize"></i>
        </button>
</li>
</ul>