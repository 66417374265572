<label class="common__input-block common__input-block--fixed field-type-list__input-block-numerical-scale"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--custom-field':isLanding,
      'common__show-alert': fieldTypeList.field.error,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-custom-numerical-scale': isLanding}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div [class]="'common__label-block common__'+fieldTypeList.classWidthField">
      <span class="common__input-text"
          [ngClass]="{'common__input-text--required': fieldTypeList.field.error,
                  'common__input-text--no-editable': fieldTypeList.field.noEditable}">{{fieldTypeList.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="fieldTypeList.field.required">*</span>
      <div class="common__info-icon-block"
          [ngHide]="fieldTypeList.field.noEditable || !fieldTypeList.field.description">
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
          [ngShow]="fieldTypeList.field.references && fieldTypeList.field.references.length && !fieldTypeList.field.noEditable">
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="fieldTypeList.field.relatedInfo?.length && !fieldTypeList.field.poll && !fieldTypeList.field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(fieldTypeList.field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
      <div class="common__info-icon-block common__info-icon-block--in-report"
        *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
        <tooltip-in-report [inReport]="fieldTypeList.inReport"></tooltip-in-report>
      </div>
  </div>
  <div [ngClass]="{'field-type-list__block-list-elements':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}">
      <div class="float-end">
          <i class="lf-icon-connected-data field-type-text__icon-references field-type-text__icon-references--less-top"
              [ngShow]="fieldTypeList.field.evaluatedValue?.hasReferences && !fieldTypeList.field.poll"
              (click)="showReference($event)"
              matTooltip="{{'common.see-dependencies' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left">
          </i>
      </div>
      <div class="field-type-list__block-template">
        <ul class="field-type-list__numerical-list-block"
          *ngIf="!isLanding">
            <li *ngFor="let listValue of fieldTypeList.filterList"
                class="field-type-list__numerical-list-item"
                [ngClass]="{'field-type-list__numerical-list-item--selected': listValue.selected && !fieldTypeList.field.noEditable,
                    'field-type-list__numerical-list-item--disabled':fieldTypeList.field.noEditable && !listValue.selected,
                    'field-type-list__numerical-list-item--selected-disabled': listValue.selected && fieldTypeList.field.noEditable,
                    'field-type-list__numerical-list-item--error': fieldTypeList.field.error &&  !listValue.selected}"
                (click)="selectItem(listValue, $event)"
                [ngStyle]="{'width': fieldTypeList.widthElementNumericalScale}">
                {{listValue.text}}
            </li>
        </ul>
        <ul class="field-type-list__numerical-list-block field-type-list__numerical-list-block--custom"
            *ngIf="isLanding">
            <li *ngFor="let listValue of fieldTypeList.filterList"
                [class]="'field-type-list__numerical-list-item field-type-list__numerical-list-item--custom field-type-list__numerical-list-item--custom-' +custom.colorClass"
                [ngClass]="{'field-type-list__numerical-list-item--disabled-landing-custom':fieldTypeList.field.noEditable && !listValue.selected,
                    'field-type-list__numerical-list-item--selected-disabled': listValue.selected && fieldTypeList.field.noEditable,
                    'field-type-list__numerical-list-item--error': fieldTypeList.field.error &&  !listValue.selected}"
                (click)="selectItem(listValue, $event)"
                [ngStyle]="{'background-color':listValue.selected?custom?.color:'#D8D8D8',
                    'color': listValue.selected?custom?.textColor:'#000000',
                    'border-color': listValue.selected?custom?.color:'#D8D8D8'}">
                {{listValue.text}}
            </li>
        </ul>
      </div>
  </div>
  <div class="field-item__blocker-box"
      *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
</label>
