<div class="report-table__header-icam"
    [ngClass]="{'report-table__header-icam--details': reportTable.detailsView}">
    <div class="report-table__header-left-icam">
        <div class="report-table__header-button-icam report-table__header-button-icam--left"
            [ngClass]="{'report-table__header-button-icam--details': reportTable.detailsView}"
            (click)="showFragments(null)"
            [ngShow]="reportTable.detailsView">
            <i class="lf-icon-arrow-back report-table__button-icon-icam report-table__button-icon-icam--left"></i>
            <span class="report-table__button-text-icam report-table__button-text-icam--right"
                [ngClass]="{'report-table__button-text-icam--details': reportTable.detailsView}"
                [afTranslate]="'report-table.icam.salir-detalle'">
            </span>
        </div>
    </div>
    <div class="report-table__header-center-icam">
        <span [ngShow]="reportTable.detailsView">{{reportTable.detailsName}}</span>
    </div>
    <div class="report-table__header-right-icam">
        <div class="report-table__header-button-icam"
            (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="reportTable.detailsView ? 'report-table.export-detail' : 'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngShow]="!reportTable.detailsView">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam">
                <filter-by-search [item]="reportTable.tituloFilter"
                    (launchActionSearch)="orderBySearch($event, 'titulo')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam">
                <filter-by-search [item]="reportTable.isbnFilter"
                    (launchActionSearch)="orderBySearch($event, 'isbn')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam"
                [afTranslate]="'report-table.icam.todas-publicaciones.fragmentos'">
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last"
                [afTranslate]="'report-table.icam.todas-publicaciones.digitalizacion'">
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam"
            [ngClass]="{'report-table__trow-icam--last': last}"
                *ngFor="let row of reportTable.reportsListFilter; let last = last">
            <div class="report-table__tcell-icam">{{row.titulo}}</div>
            <div class="report-table__tcell-icam">{{row.isbn}}</div>
            <div class="report-table__tcell-icam">
                <span class="report-table__tcell-icam--badge"
                    (click)="showFragments(row)"
                    [ngShow]="row.numeroFragmentos">{{row.numeroFragmentos}}</span>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                {{row.porcentajeDigitalizacion ? row.porcentajeDigitalizacion + '%' : ''}}
            </div>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngShow]="reportTable.detailsView">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam">
                <filter-by-search [item]="reportTable.tituloFragmentoFilter"
                    (launchActionSearch)="orderBySearch($event, 'tituloFragmento')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam">
                <filter-by-search [item]="reportTable.referenciaFragmentoFilter"
                    (launchActionSearch)="orderBySearch($event, 'referenciaFragmento')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last"
                [afTranslate]="'report-table.icam.todas-publicaciones.rango'">
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam report-table__trow-icam--hover"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let fragment of reportTable.detailsFilter; let last = last">
            <div class="report-table__tcell-icam">
                {{fragment.tituloFragmento}}
            </div>
            <div class="report-table__tcell-icam">
                {{fragment.referenciaFragmento}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                {{fragment.rangoPaginas}}
            </div>
        </div>
    </div>
</div>
