import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConvertNumToNameFactory {
  report: any =  this.createReportMap();
  task: any =  this.createTaskMap();

  createReportMap(){
    let report = new Map();
    report.set('1'	,'Riesgos Evaluación de Impacto')
        .set('2'	,'Plan de Acción')
        .set('3'    ,'Registro de actividades')
        .set('4'	,'Indicadores de formación')
        .set('5'	,'Gestión de brechas de seguridad')
        .set('6'	,'Riesgos Evaluación de Impacto')
        .set('7'	,'Plan de Acción')
        .set('8'	,'Registro de actividades')
        .set('9'	,'Indicadores de formación')
        .set('10'	,'Canal de comunicación')
        .set('11'	,'Riesgos del Análisis Básico')
        .set('12'	,'Riesgos del Análisis Básico')
        .set('13'	,'Mapa de riesgos')
        .set('15'	,'Formación')
        .set('16'	,'Registro de denuncias')
        .set('17'	,'Plan de Acción')
        .set('19'	,'Mapa de riesgos')
        .set('20'	,'Mapa de delitos por departamento')
        .set('21'	,'Informe global')
        .set('22'	,'Matriz de riesgos')
        .set('23'	,'Registro de actividades')
        .set('24'	,'Mapa de asuntos')
        .set('25'	,'Informe global')
        .set('26'	,'ENCARGADO')
        .set('27'	,'REXISTRO DE ACTIVIDADES')
        .set('28'	,'ANÁLISE BASICA')
        .set('29'	,'XESTIÓN E AVALIACIÓN DE RISCOS')
        .set('30'	,'RAT - ENCARGADO DO TRATAMENTO')
        .set('31'	,'MANUAL DE BUENAS PRÁCTICAS TRIBUTARIAS')
        .set('32'	,'MANUAL DE BUENAS PRÁCTICAS TRIBUTARIAS')
        .set('33'	,'MANUAL DE BUENAS PRÁCTICAS TRIBUTARIAS')
        .set('34'	,'MANUAL DE BUENAS PRÁCTICAS TRIBUTARIAS')
        .set('35'	,'Solicitud AEP Persona Natural')
        .set('36'	,'Solicitud reducida AEP Persona Natural')
        .set('37'	,'INFORME DE CUMPLIMIENTO TRIBUTARIO')
        .set('38'	,'INFORME DE CUMPLIMIENTO TRIBUTARIO')
        .set('39'	,'Formación')
        .set('40'	,'Registro de incidencias')
        .set('41'	,'Plan de Acción')
        .set('42'	,'Mapa de controles asociados al riesgo')
        .set('43'	,'Mapa de riesgos')
        .set('44'	,'Mapa de delitos por departamento')
        .set('45'	,'Informe global')
        .set('46'	,'Mapa de riesgos')
        .set('47'	,'Matriz de riesgos')
        .set('48'	,'Informe de Cumplimiento')
        .set('59'	,'Mapa de riesgos')
        .set('60'	,'Plan de acción')
        .set('61'	,'Canal de comunicación')
        .set('62'	,'Indicadores de formación ')
        .set('63'	,'Encargados de tratamiento')
        .set('66'	,'Preparación de reunión de acreedores')
        .set('67'	,'GESTIÓN MEDIDAS DE SEGURIDAD FRENTE AL COVID-19')
        .set('68'	,'DIAGNÓSTICO FRENTE AL COVID-19')
        .set('69'	,'INVENTARIO DE ACTIVOS')
        .set('70'	,'DIMENSIONES DE SEGURIDAD DE LOS ACTIVOS')
        .set('71'	,'Guía de implantación ')
        .set('72'	,'Registro de Actividades de Tratamiento (RAT)')
        .set('73'	,'Necesidad DPO')
        .set('74'	,'Necesidad EIPD')
        .set('106'	,'Registro de denuncias')
        .set('107'	,'Solicitud AEP Persona Jurídica')
        .set('108'	,'Solicitud reducida AEP Persona Jurídica')
        .set('109'	,'Samenvatting van de reacties')
        .set('110'	,'Samenvatting van de reacties (Beleid en organisatie)')
        .set('111'	,'Samenvatting van de reacties (Winkel)')
        .set('112'	,'Samenvatting van de reacties (Kantoor)')
        .set('113'	,'Rapport eerste opzet')
        .set('115'	,'Documentación')
        .set('116'	,'Informe de diagnóstico')
        .set('117'	,'Registro de denuncias')
        .set('118'	,'Plan de igualdad')
        .set('119'	,'Risico-inventarisatie')
        .set('123'	,'Informe de seguimiento')
        .set('124'	,'Informe de evaluación')
        .set('125'	,'Matriz de medidas')
        .set('128'	,'Registro de denuncias')
        .set('129'	,'Hoja estadística')
        .set('130'	,'Análisis de Riesgos')
        .set('131'	,'Mapa de controles')
        .set('132'	,'Mapa de delitos por departamento')
        .set('133'	,'Mapa de riesgos')
        .set('134'	,'Formación')
        .set('136'	,'Registro de incidencias')
        .set('137'	,'Plan de igualdad')
        .set('138'	,'Plan de acción')
        .set('139'	,'Informe de diagnóstico')
        .set('140'	,'Mapa de controles')
        .set('141'	,'Informe global')
        .set('142'	,'Informe de seguimiento')
        .set('143'	,'Informe de evaluación')
        .set('144'	,'Registro de denuncias')
        .set('145'	,'Hoja estadística')
        .set('146'	,'Calendario de Actuaciones')
        .set('147'	,'Calendario de Actuaciones')
        .set('148'	,'Plan de tratamiento de riesgos')
        .set('149'	,'Análisis de riesgos global')
        .set('150'	,'Evaluación de Impacto')
        .set('152'	,'Registro de actividades')
        .set('153'	,'Plan de Medidas Antifraude')
        .set('154'	,'Matriz de Riesgos Brutos')
        .set('155'	,'Matriz de Riesgos Netos')
        .set('156'	,'Matriz de Riesgos Objetivo')
        .set('157'	,'Informe de Riesgos');
        return report;
  }

  createTaskMap(){
      let task = new Map();
      task.set('1'	,'Solicitud de información')
          .set('2'	,'Firma de documentos')
          .set('3'	,'Evaluación')
          .set('4'	,'Reunión')
          .set('5'	,'Comunicación')
          .set('6'	,'Genérica')
          .set('7'	,'Proceso')
      return task
  }

  reportNumbToName(reportId:any){
    return this.report.get(reportId);
  }

  taskNumbToName(taskTypeId:any){
      return this.task.get(taskTypeId);
  }

}
