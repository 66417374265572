import { Injectable } from '@angular/core';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class FieldTypeBoolParentService {

  constructor(private commonUtilsService: CommonUtilsService) { }

  configureField(){
    let fieldTypeBool: any ={
        titleField: '',
        id: this.commonUtilsService.createId(),
        bool: true,
        preSelectItem:'',
        field:null
    }
    return fieldTypeBool;
  }

  onChanges(child:any): void {
    let that: any = child.fieldTypeBool;
    if(child.field) {
      that.titleField = child.field.extendedLabel && child.field.extendedLabel !== ''? child.field.extendedLabel : child.field.label;
      if(child.field.fieldId){
          that.id = child.field.fieldId
      }
      that.field = child.field;
      if(child.field.inReport !== null && child.field.inReport !== undefined){
        child.fieldTypeFormu.inReport = child.field.inReport.indexOf('|') === -1? [child.field.inReport] : child.field.inReport.split('|')
      }
    }
    if(child.preSelectItem === true || child.preSelectItem === false){
      that.preSelectItem = child.preSelectItem;
    }
  }

  selectOption(child: any, option: any){
      if(!child.field.noEditable){
        child.fieldTypeBool.preSelectItem = option === child.fieldTypeBool.preSelectItem? null : option;
        child.itemSelected.emit({newValue:child.fieldTypeBool.preSelectItem});
      }
  }

  openModalRelatedInfo(child: any, fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      child.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
}
