import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-task',
  templateUrl: './customize-field-task.component.html'
})
export class CustomizeFieldTaskComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldTask: any = {
    tinyMceOptions: {},
    titleDescription: this.transalte.instant('field-list.introduction') + ' ' + this.transalte.instant('field-list.button-create-task')
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private transalte: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() concept: any = {};
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldTask;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      if(!that.field.fieldAttributes){
          that.field.fieldAttributes = {};
      }
      that.field.fieldAttributes.documentId = that.field.documentTemplateId;
      that.fieldCopy = cloneDeep(that.field);
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  getEditorValue(event:any){
    this.customizeFieldTask.field.description = event.model;
  }

  itemSelected(e:any, item:any){
      if(e.newValue){
          this.customizeFieldTask.fieldTemplates.error = false;
          this.customizeFieldTask.showError = false;
      }
      this.customizeFieldTask.field.fieldAttributes[item] = e.newValue;
      this.modifyField();
  }

  cancelFieldText(){
      this.customizeFieldTask.field = cloneDeep(this.customizeFieldTask.fieldCopy);
      this.customizeFieldTask.color = false;
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldTask.field
          }
      })
  }

  saveFieldText(){
      this.customizeFieldTask.fieldCopy = cloneDeep(this.customizeFieldTask.field);
      this.changeInField.emit({
          change:{
              type: 'save',
              field: this.customizeFieldTask.field
          }
      })
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldTask.field);
  }
  changeOptionOn(e:any, type:string){
    this.customizeFieldTask.field[type] = e.model
    this.modifyField();
  }


}
