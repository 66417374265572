import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'duplicate-modal',
  templateUrl: './duplicate-modal.component.html'
})
export class DuplicateModalComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  duplicateModal: any={
    selected: null,
    copydata: null,
    copyreference: null,
    selectedEvaluation: false,
    lock: false,
    name: '',

    nameElement: {
        fieldId: 'name-element',
        required:  true,
        description: null,
        label: this.translate.instant('duplicate-modal.name-element')
    },
    product: '',
    productName:''
  }

  constructor(private globalCfg: GlobalCfgFactory,
      private translate: AfTranslateFactory,
      private apiService: ApiServiceService,
      private activeModal: NgbActiveModal) { }
      @Input() evaluationList: any;
      @Input() duplicated: any = null;
      @Input() evaluation: any = null;
      @Input() listFolders: any = null;

  ngOnInit(): void {
      this.duplicateModal.product = this.evaluation.productTypeId;
      this.duplicateModal.productName = this.evaluation.productName;
      this.duplicateModal.name = this.getNameDuplicated();
      this.openFolders(this.evaluationList);
  }

  openFolders(children:any){
    for(let child of children){
        if(!this.duplicated.parentId && !this.duplicated.conceptId){
            this.productSelected();
        }else if(child.folderId === this.duplicated.parentId){
            this.duplicateModal.selected = child;
            child.selected = true;
        }else{
            if(this.duplicated.conceptId){
                for(let concept of child.concepts){
                    if(concept.conceptId === this.duplicated.conceptId){
                        child.selected = true;
                        this.duplicateModal.selected = child;
                        break;
                    }
                }
            }
            if(this.duplicateModal.selected === null && child.children.length){
                this.openFolders(child.children);
            }
        }

        if(this.duplicateModal.selected !== null){
            child.show = true;
            break;
        }
    }

  }

  productSelected(){
    this.duplicateModal.selectedEvaluation = !this.duplicateModal.selectedEvaluation;
    this.deselectedEvaluation(this.evaluationList);
    this.duplicateModal.selected = this.evaluation;
  }

  deselectedEvaluation(children:any, show:string = ''){
    children.forEach((child: any) => {
      if(child){
        child.selected = false;
      }
      if(show === 'close'){
        child.show = false;
    }
    if(child.children.length){
        this.deselectedEvaluation(child.children, show);
    }
    });
  }

  getNameDuplicated(){
    let type: string = this.duplicated.conceptId? 'title': 'name';
    let name: string = this.duplicated[type];
    let nameDuplicated:string = this.duplicated[type];

    if (this.listFolders && this.listFolders.length) {
        let nameIdx : any = 0;
        let existingFolder: any;
        do {
            if (nameIdx) {
               name = nameDuplicated + ' - ' + '(' + this.translate.instant('duplicate-evaluation.copy') + ' ' + nameIdx + ')';
            }
            existingFolder =   this.listFolders.filter((item:any)=> {
                let nameItem: string = this.duplicated.conceptId? item.title: item.name;
                return nameItem.toLowerCase() === name.toLowerCase();
            })[0];
            nameIdx++;
        } while (existingFolder);
    }
    return name;
  }
  selectChildrenOn(e:any){
    this.selectChildren( e.children)
  }

  selectChildren(children:any){

    if(this.duplicateModal.selected !== null && children.folderId === this.duplicateModal.selected.folderId){
      this.duplicateModal.selected = null;
    }else{
      if(this.duplicateModal.selected !== null){
          this.deselectedEvaluation(this.evaluationList);
          this.duplicateModal.selected.selected = false;
      }
      this.duplicateModal.selected = children;
    }
    children.selected = !children.selected;
  }

  optionSelected(evaluation:any){
    this.duplicateModal.selectedEvaluation = false;
    this.selectChildren(evaluation);
  }

  itemSelected(e:any){
    this.duplicateModal.name = e.newValue;
  }

  checkName(){
    this.duplicateModal.selectedEvaluation? this.checkEvaluationListNames() : this.checkFoldersAndConcepts();
  }

  checkEvaluationListNames(){
    let count : any = 1;
    for(let item of this.evaluationList){
        let name : any = item.name;
        count++;
        if(name.toLowerCase() === this.duplicateModal.name.toLowerCase()){
            this.duplicateModal.nameElement.error =  true;
            break;
        }else{
            this.duplicateModal.nameElement.error =  false;
            if(count === this.evaluationList.length){
                this.handleAccept();
            }
        }
    }
  }

  checkFoldersAndConcepts(){
    let path: any = 'folders/existssubfolderinfolder/';
    let exist: any = {name: this.duplicateModal.name};

    if(!this.duplicated.folderId){
        path = 'concepts/existsconceptinfolder/';
        exist.title= this.duplicateModal.name;
        delete exist.name;
    }

    this.apiService.add(path + this.evaluation.evaluationId + '/'+ this.duplicateModal.selected.folderId, exist)
        .then((data:any)=>{
            if(data === false){
                this.duplicateModal.nameElement.error =  false;
                this.handleAccept();
            }else{
              this.duplicateModal.nameElement.error =  true;
            }

        }, function(errorData){

        })
  }
  handleDismiss() {
    this.deselectedEvaluation(this.evaluationList, 'close');
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {

    this.deselectedEvaluation(this.evaluationList, 'close');
    this.activeModal.close({
      result: 'ok',
      selectFolder: this.duplicateModal.selected,
      name: this.duplicateModal.name,
      copydata: this.duplicateModal.copydata,
      copyreference : this.duplicateModal.copyreference
    })
};

}
