<div *ngFor="let field of listField; let lastField = last">
  <div class="history-viewer__block-fields-group"
    [ngClass]="{'history-viewer__block-fields-group--big-padding': (field.fieldTypeFrontId === 'alert' || field.fieldTypeFrontId === 'task' || field.fieldTypeFrontId === 'l-co') && !lastField}"
    *ngIf="!field.hiddenContainer">
    <div class="history-viewer__block-cell-label"
        [ngClass]="{'history-viewer__block-cell-label--padding-0': field.fieldTypeFrontId === 'rich'}">
        <span class="history-viewer__text-label">
            <span class="history-viewer__search-text">{{field.label}}</span>
            <span class="history-viewer__search-text"
                *ngIf="!field.label && field.description && (field.fieldTypeFrontId === 'alert' || field.fieldTypeFrontId === 'task' || field.fieldTypeFrontId === 'templ' || field.fieldTypeFrontId === 'report' || field.fieldTypeFrontId === 'pepl')"
                [innerHtml]="field.description"></span>
        </span>
    </div>
    <div class="history-viewer__block-cell-input">
        <div class="history-viewer__block-cell-inner-input-field"
            [ngSwitch]="field.printTypeId">
            <div class="history-viewer__text-value-italic"
                *ngSwitchCase="'rich'">
                <span class="history-viewer__search-text history-viewer__value-bind-html"
                    [innerHtml]="field.value"></span>
            </div>
            <div class="history-viewer__text-value"
                [ngClass]="{'history-viewer__text-value--disabled': field.noEditable}"
                *ngSwitchCase="'rich-editable'">
                <span class="history-viewer__search-text history-viewer__value-bind-html"
                    [innerHtml]="field.value"></span>
            </div>
            <div class="history-viewer__text-value"
                [ngClass]="{'history-viewer__text-value--disabled': field.noEditable}"
                *ngSwitchCase="'bool'">
                <div [class]="'history-viewer__side-band-color history-viewer__side-band-color--'+field.value?.color"
                    [ngShow]="field.value?.color"></div>
                <span  class="history-viewer__search-text"
                    [ngShow]="field.value?.valueText">{{field.value?.valueText}}</span>
                <span class="history-viewer__search-text"
                    [ngShow]="!field.value?.valueText && (field.value?.value === true || field.value?.value === false)"
                    [afTranslate]="field.value?.value === true?'common.yes':'common.no'"></span>
            </div>
            <div *ngSwitchCase="'list'">
                <div *ngFor="let listValueField of field.value | afOrderBy:['id', 'value']:field.fieldTypeFrontId==='pepl'?true:false | afLimitTo:field.fieldTypeFrontId==='pepl'?1:undefined"
                    class="history-viewer__text-value"
                    [ngClass]="{'history-viewer__text-value--disabled': field.noEditable,
                      'history-viewer__text-value--list': !lastField && field.value?.length > 1}">
                  <div class="history-viewer__side-band-color"
                      [ngClass]="{'history-viewer__side-band-color--{{listValueField.color}}': field.fieldTypeFrontId==='pepl'}"
                      [ngShow]="listValueField.color"
                      [ngStyle]="{'background-color': field.fieldTypeFrontId!=='pepl'?listValueField.color:''}"></div>
                  <span class="history-viewer__search-text">{{listValueField.value}}</span>
                </div>

            </div>
            <div *ngSwitchCase="'user'">
                <div *ngFor="let listUserField of field.value"
                    class="history-viewer__text-value"
                    [ngClass]="{'history-viewer__text-value--disabled': field.noEditable,
                        'history-viewer__text-value--list': !lastField && field.value?.length > 1}">
                  <span class="history-viewer__text-value-user"><span class="history-viewer__search-text">{{listUserField.value?.name}}</span></span>
                  <span class="history-viewer__text-value-user history-viewer__text-value-user--mail"><span class="history-viewer__search-text">{{listUserField.value?.mail}}</span></span>
                  <span class="history-viewer__text-value-user"><span class="history-viewer__search-text">{{listUserField.value?.phone}}</span></span>
                </div>
            </div>
            <div class="history-viewer__text-value"
                [ngClass]="{'history-viewer__text-value--disabled': field.noEditable}"
                *ngSwitchCase="'date'">
                <span class="history-viewer__search-text"
                    [ngShow]="!field.showTime">{{field.value?.value | convertDate:'without-time'}}</span>
                <span class="history-viewer__search-text"
                    [ngShow]="field.showTime">{{field.value?.value | convertDate}}</span>
            </div>
            <div *ngSwitchCase="'l-co'">
                <div *ngFor="let relatedListField of field.value"
                    class="history-viewer__block-group history-viewer__block-group--field">
                  <i class="history-viewer__icon-arrow-round history-viewer__icon-arrow-round--field"
                      [ngClass]="relatedListField.hiddenView?'lf-icon-arrow-round-right':'lf-icon-arrow-round-down'"
                      [ngStyle]="{'color': historyDataViewer?.custom?.color}"
                      (click)="changeShow(relatedListField)"></i>
                  <div class="history-viewer__block-header-group history-viewer__block-header-group--field">
                      <span class="history-viewer__text-header-group"><span class="history-viewer__search-text">{{relatedListField.keyValue?.value}}</span></span>
                  </div>
                  <div class="history-viewer__block-form-field"
                      [ngShow]="!relatedListField.hiddenView">
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field"
                          *ngFor="let relatedField of relatedListField.value">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label"><span class="history-viewer__search-text">{{relatedField.label}}</span>:</span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text history-viewer__value-bind-html"
                                      [innerHtml]="relatedField.value"></span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>

            </div>
            <div *ngSwitchCase="'task'">
                <div *ngFor="let taskField of field.value"
                  class="history-viewer__block-group history-viewer__block-group--field">
                  <i class="history-viewer__icon-arrow-round history-viewer__icon-arrow-round--field history-viewer__icon-arrow-round--subgroup"
                      [ngClass]="taskField.hiddenView?'lf-icon-arrow-round-right':'lf-icon-arrow-round-down'"
                      [ngStyle]="{'color': historyDataViewer?.custom?.color}"
                      (click)="changeShow(taskField)"></i>
                  <div class="history-viewer__block-header-group history-viewer__block-header-group--field">
                      <span class="history-viewer__text-header-group"><span class="history-viewer__search-text">{{taskField.value?.title}}</span></span>
                  </div>
                  <div class="history-viewer__block-form-field"
                      [ngShow]="!taskField.hiddenView">
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'common.task-type'">Tipo de tarea</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{taskField.value?.type}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="taskField.taskTypeId === 4?'result-search.participants':'common.users-contacts'">Usuarios/Contactos</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{taskField.value?.participants}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="taskField.taskTypeId === 4?'task-list.date-and-hour':'task-edit.expiration-date'">Fecha de vencimiento</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field"
                              [ngClass]="{'history-viewer__block-fields-group--last-field': !taskField.value?.repeat && taskField.taskTypeId !== 4}">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{taskField.value?.expirationDate | convertDate:'without-time'}}</span>
                                  <span class="history-viewer__search-text"
                                      [ngShow]="taskField.taskTypeId === 4"> {{taskField.value?.beginHour}} - {{taskField.value?.endHour}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field"
                          [ngShow]="taskField.taskTypeId === 4">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'task-list.modality-and-location'">Modalidad y ubicación</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field history-viewer__block-fields-group--last-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="taskField.videoconference?'task-form.videoconference':'task-form.face-to-face'">Presencial/Videoconferencia</span> /
                                  <span class="history-viewer__search-text">{{taskField.value?.location}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--last-field"
                          [ngShow]="taskField.value?.repeat">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{taskField.value?.repeat}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div *ngSwitchCase="'alert'">
                <div *ngFor="let alertField of field.value"
                    class="history-viewer__block-group history-viewer__block-group--field">
                  <i class="history-viewer__icon-arrow-round history-viewer__icon-arrow-round--field history-viewer__icon-arrow-round--subgroup"
                      [ngClass]="alertField.hiddenView?'lf-icon-arrow-round-right':'lf-icon-arrow-round-down'"
                      [ngStyle]="{'color': historyDataViewer?.custom?.color}"
                      (click)="changeShow(alertField)"></i>
                  <div class="history-viewer__block-header-group history-viewer__block-header-group--field">
                      <span class="history-viewer__text-header-group"><span class="history-viewer__search-text">{{alertField.value?.title}}</span></span>
                  </div>
                  <div class="history-viewer__block-form-field"
                      [ngShow]="!alertField.hiddenView">
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'add-alert.reference-date'">Fecha de referencia</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{alertField.value?.date}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--field">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'add-alert.alert-moment'">Momento de la alerta</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field"
                              [ngClass]="{'history-viewer__block-fields-group--last-field': !alertField.value?.repeat}">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{alertField.value?.momentAlert}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="history-viewer__block-fields-group history-viewer__block-fields-group--last-field"
                          [ngShow]="alertField.value?.repeat">
                          <div class="history-viewer__block-cell-label-field">
                              <span class="history-viewer__text-label">
                                  <span class="history-viewer__search-text"
                                      [afTranslate]="'alert-detail.repeat'">Repetir</span>:
                              </span>
                          </div>
                          <div class="history-viewer__block-cell-input-field">
                              <div class="history-viewer__text-value-italic">
                                  <span class="history-viewer__search-text">{{alertField.value?.repeat}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="history-viewer__text-value"
                [ngClass]="{'history-viewer__text-value--disabled': field.noEditable}"
                *ngSwitchDefault>
                <div class="history-viewer__side-band-color"
                    [ngShow]="field.value?.color"
                    [ngStyle]="{'background-color': field.value?.color}"></div>
                <span class="history-viewer__search-text">{{field.value?.value}}</span>
            </div>
        </div>
    </div>
  </div>
</div>
