import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, AfterViewInit, ViewChildren } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { DatePickerService } from '@shared/services/date-picker/date-picker.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'from-to-calendar',
  templateUrl: './from-to-calendar.component.html'
})
export class FromToCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren(NgbDatepicker)
  ngbdatePickerList: NgbDatepicker;
  isLanding = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  custom: any = this.customLanding.getCustom();
  urlPart = this.globalCfg.skin ==='steel-blue'?'be/wh/': 'denunc/';
  current: any = new Date();
  fromToCalendar: any ={
    convertDateType: this.isLanding?'day-calendar-min':'day-calendar',
    days: [1, 2, 3, 4, 5, 6, 7],
    ready: true,
    showCalendarMobile: 'from',
    styleButtonCancel: this.isLanding?{'border-color': this.custom.color, 'color': this.custom.color}:'',
    styleButtonApply: this.isLanding?{'background-color': this.custom.color, 'color': this.custom.textColor}:'',
    selectedMonthFrom: this.current.getMonth() + 1,
    selectedYearFrom: this.current.getFullYear(),
    selectedMonthTo: this.current.getMonth() + 1,
    selectedYearTo: this.current.getFullYear(),
    calendarOptionList: [
      {
        id: 0,
        text: this.translate.instant('dashboard-reports.custom-range'),
      },
      {
        id: 1,
        text: this.translate.instant('dashboard-reports.last-twelve-months'),
      },
      {
        id: 2,
        text: this.translate.instant('dashboard-reports.last-thirty-days'),
      },
      {
        id: 3,
        text: this.translate.instant('dashboard-reports.last-fifth-days'),
      },
      {
        id: 4,
        text: this.translate.instant('dashboard-reports.last-seven-days'),
      },
      {
        id: 5,
        text: this.translate.instant('dashboard-reports.this-year'),
      },
      {
        id: 6,
        text: this.translate.instant('dashboard-reports.this-quarterly'),
      },
      {
        id: 7,
        text: this.translate.instant('dashboard-reports.this-monthly'),
      },
      {
        id: 8,
        text: this.translate.instant('dashboard-reports.this-weekly'),
      }
    ],
    from: null,
    fromSave: null,
    to: null,
    toSave:null,
    selectDays: null,
    selectCalendarOption: null,
    hasError: false
  }

  constructor(private route: ActivatedRoute,
    private globalCfg : GlobalCfgFactory,
    private datePicker: DatePickerService,
    private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private commonUtilsService: CommonUtilsService) { }

  @Input() selectedPreviewOption: string = '';
  @Input() fromSave: any = null;
  @Input() toSave: any = null;
  @Input() type: any = null;

  @Output() changeDays = new EventEmitter();
  @Output() changeCalendar = new EventEmitter();

  ngOnInit(): void {
    this.fromToCalendar.type = this.type;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fromToCalendar.from = this.fromSave;
    this.fromToCalendar.to = this.toSave;
    this.fromToCalendar.fromPicker = this.commonUtilsService.toNgbCalendar(this.fromSave);
    this.fromToCalendar.toPicker = this.commonUtilsService.toNgbCalendar(this.toSave);
    this.configureSelectDays();
    if(this.fromToCalendar.to !== null && this.fromToCalendar.from !== null){
      this.fromToCalendar.selectDays = moment(this.fromToCalendar.to).diff(moment(this.fromToCalendar.from), 'days')+1;
      if((moment(this.fromToCalendar.to).format('DD-MMMM-YYYY') === moment(this.fromToCalendar.from).format('DD-MMMM-YYYY'))){
          this.fromToCalendar.selectDays = 1;
      }
    }
  }

  ngAfterViewInit(): void {
    if(this.fromToCalendar.type === 'report-filter-as-icam'){
      this.resetRange()
    }else if(this.fromToCalendar.type === 'dashboard-reports'){
      this.selectOption(this.fromToCalendar.calendarOptionList[1]);
    }else{
      this.navigateToDatePicker('from', this.fromToCalendar.fromPicker);
      this.navigateToDatePicker('to', this.fromToCalendar.toPicker);
    }
  }

  navigateToDatePicker(type: any, dateNavigate: any){
    let ngbdatePickerList: any = this.ngbdatePickerList;
    let ngbdatePicker: any = ngbdatePickerList.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    ngbdatePicker.navigateTo(dateNavigate);
  }

  selectDate(dt:any, type:any){
    let selectedDate = this.datePicker.selectDate(dt, type);
    if(!this.fromToCalendar.changedCalendar && selectedDate && this.fromToCalendar.showCalendarMobile === 'from'){
      this.fromToCalendar.showCalendarMobile = 'to';
      this.fromToCalendar.changedCalendar = true;
    }
    return selectedDate;
  }

  selectOption(opt: any){
    this.fromToCalendar.selectCalendarOption = opt;
    switch(opt.id){
      case 0:
        this.resetRange();
        break;
      case 1:
        this.fromToCalendar.from = moment().subtract(1, 'year');
        this.fromToCalendar.to = moment();
        break;
      case 2:
        this.fromToCalendar.to = moment();
        this.fromToCalendar.from = moment().subtract(29, 'days');
        break;
      case 3:
        this.fromToCalendar.from = moment().subtract(14, 'days');
        this.fromToCalendar.to = moment();
        break;
      case 4:
        this.fromToCalendar.from = moment().subtract(6, 'days');
        this.fromToCalendar.to = moment();
        break;
      case 5:
        this.fromToCalendar.from = moment().startOf('year');
        this.fromToCalendar.to = moment().endOf('year');
        break;
      case 6:
        this.fromToCalendar.from = moment().startOf('quarter');
        this.fromToCalendar.to = moment().endOf('quarter');
        break;
      case 7:
        this.fromToCalendar.from = moment().startOf('month');
        this.fromToCalendar.to = moment().endOf('month');
        break;
      default:
        this.fromToCalendar.from = moment().startOf('week');
        this.fromToCalendar.to = moment().endOf('week');
        break;
    }
    if (opt.id !== 0) {
      this.fromToCalendar.selectDays = this.fromToCalendar.to.diff(this.fromToCalendar.from, 'days') + 1;
    }
    let days:any = {
      days: this.fromToCalendar.selectDays
    }

    this.changeDays.emit(days);
    this.fromToCalendar.fromPicker = this.commonUtilsService.toNgbCalendar(this.fromToCalendar.from);
    this.fromToCalendar.toPicker = this.commonUtilsService.toNgbCalendar(this.fromToCalendar.to);
    this.navigateToDatePicker('from', this.fromToCalendar.fromPicker);
    this.navigateToDatePicker('to', this.fromToCalendar.toPicker);
  }

  resetRange(){
    this.fromToCalendar.from = null;
    this.fromToCalendar.fromPicker = {};
    this.fromToCalendar.to = null;
    this.fromToCalendar.toPicker = {};
    this.fromToCalendar.selectDays = null;
    this.fromToCalendar.filteredCalendar = false;
    this.resetActualView();
  }

  stopEvent(e:any, type:string){
    this.fromToCalendar.hasError = false;
    this.fromToCalendar.to = this.commonUtilsService.ngbToMoment(this.fromToCalendar.toPicker);
    this.fromToCalendar.from = this.commonUtilsService.ngbToMoment(this.fromToCalendar.fromPicker);
    this.configureSelectDays();
    if(this.fromToCalendar.type === 'dashboard-reports'){
      this.fromToCalendar.selectCalendarOption = this.fromToCalendar.calendarOptionList[0];
    }
    let days:any = {
      days: this.fromToCalendar.selectDays
    }
    this.changeDays.emit(days);
  }

  configureSelectDays(){
    if(this.fromToCalendar.to && this.fromToCalendar.from){
        let selectDays = moment(this.fromToCalendar.to).diff(moment(this.fromToCalendar.from), 'days');
        this.fromToCalendar.selectDays = selectDays >= 0?selectDays+1:selectDays;
    }else{
        this.fromToCalendar.selectDays = 0;
    }
    // this.fromToCalendar.selectDays = moment(this.fromToCalendar.to).diff(moment(this.fromToCalendar.from), 'days') + 1;
    // if((moment(this.fromToCalendar.to).format('DD-MMMM-YYYY') === moment(this.fromToCalendar.from).format('DD-MMMM-YYYY'))){
    //     this.fromToCalendar.selectDays = 1;
    // }
  }

  resetActualView(){
    this.fromToCalendar.ready = false;
    setTimeout(()=>{
      this.fromToCalendar.ready = true;
    }, 100)
  }

  restore(){
    if(this.fromToCalendar.type === 'dashboard-reports'){
      this.selectOption(this.fromToCalendar.calendarOptionList[1]);
    }else{
      this.resetRange();
    }
    this.fromToCalendar.fromSave = this.fromToCalendar.from;
    this.fromToCalendar.toSave = this.fromToCalendar.to;
    this.fromToCalendar.hasError = false;
    this.fromToCalendar.showCalendarMobile = 'from';
    this.fromToCalendar.filteredCalendar = false;
    delete this.fromToCalendar.preventOptionCalendar;
    let dates: any = {
      dates: {
          type: 'restore',
          from: this.fromToCalendar.from,
          to: this.fromToCalendar.to,
      }
    }
      this.changeCalendar.emit(dates);
  }

  cancel() {
    this.fromToCalendar.from = this.fromToCalendar.fromSave;
    this.fromToCalendar.to = this.fromToCalendar.toSave;
    this.fromToCalendar.hasError = false;
    this.configureSelectDays();
    this.resetActualView();
    this.fromToCalendar.showCalendarMobile = 'from';
    if(this.fromToCalendar.type === 'dashboard-reports' && !this.fromToCalendar.filteredCalendar){
        this.selectOption(this.fromToCalendar.calendarOptionList[1]);
    }else{
        this.fromToCalendar.selectCalendarOption = this.fromToCalendar.preventOptionCalendar;
    }
    let dates: any = {
      dates: {
          type: 'cancel',
          from: this.fromToCalendar.fromSave,
          to: this.fromToCalendar.toSave,
      }
    }
      this.changeCalendar.emit(dates);
  }

  apply(){
    if(this.fromToCalendar.from?.valueOf() <= this.fromToCalendar.to?.valueOf()){
      this.fromToCalendar.hasError = false;
      this.fromToCalendar.showCalendarMobile = 'from';
      this.fromToCalendar.filteredCalendar = true;
      this.fromToCalendar.fromSave = this.fromToCalendar.from;
      this.fromToCalendar.toSave = this.fromToCalendar.to;
      this.fromToCalendar.preventOptionCalendar = this.fromToCalendar.selectCalendarOption;
      let dates: any = {
        dates: {
            type: 'apply',
            from: this.fromToCalendar.from,
            to: this.fromToCalendar.to,
        }
      }
        this.changeCalendar.emit(dates);
    }else{
      this.fromToCalendar.hasError = true;
    }
  }

  selectShowCalendar(type: any){
    this.fromToCalendar.showCalendarMobile = type;
  }

  getTranslateSelectDays(){
    let text = '';
    if(this.fromToCalendar.selectDays > 1){
      text = this.translate.instant('add-alert.days');
    }else if(this.fromToCalendar.selectDays === 1){
      text = this.translate.instant('add-alert.day');
    }else if(this.fromToCalendar.selectDays === null || this.fromToCalendar.selectDays === 0){
      text = this.translate.instant('dashboard-reports.select-days');
    }
    return text;
  }

  navigate(datepicker: NgbDatepicker, number: number){
    const { state, calendar } = datepicker;
		datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  selectedDate(date:any, type:string){
    let convertDate: any = this.commonUtilsService.ngbToMoment(date);
    let convert: any =  this.commonUtilsService.ngbToMoment(this.fromToCalendar[type+'Picker']);
    return convert.isSame(convertDate);
  }

  isBetWeen(date:any){
    let convertDate: any = this.commonUtilsService.ngbToMoment(date);
    return convertDate.isBetween(this.fromToCalendar.from, this.fromToCalendar.to) || this.fromToCalendar.from?.isSame(convertDate) || this.fromToCalendar.to?.isSame(convertDate)
  }

  isToday(date:any){
    let convertDate: any = this.commonUtilsService.ngbToMoment(date);
    let today: any = moment();
    return today.format('DD-MM-yyyy') === convertDate.format('DD-MM-yyyy');
  }

  navigateSelection(date: any, type: any){
    this.fromToCalendar['showMonths'+type] = false;
    this.fromToCalendar['selectedMonth'+type] = date.month;
    this.fromToCalendar['selectedYear'+type] = date.year;
    this.navigateToDatePicker(type.toLowerCase(), date);
  }

}
