<div class="new-contact__new-user-contact">
  <form autocomplete="off">
      <div class="new-contact__col-left"
          [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-2':'new-contact__col-4'">
          <field-type-text-simple [field]="newContact.name"
              [preSelectItem]="newContact.new.name"
              (itemSelected)="itemSelected('name', $event.newValue)" >
          </field-type-text-simple>
          <!-- <field-type-text field="newContact.name"
              pre-select-item="newContact.new.name"
              item-selected="newContact.itemSelected('name', newValue)">
          </field-type-text> -->
      </div>
      <div class="new-contact__col-2"
          [ngClass]="{'new-contact__col-left': skin !== 'uk-steel-blue'}">
          <field-type-text-simple [field]="newContact.surname"
              [preSelectItem]="newContact.new.surname"
              (itemSelected)="itemSelected('surname', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-4"
          *ngIf="skin !== 'uk-steel-blue'">
          <field-type-text-simple [field]="newContact.nif"
              [preSelectItem]="newContact.new.nif"
              (itemSelected)="itemSelected('nif', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-2 new-contact__col-left">
          <field-type-text-simple [field]="newContact.position"
              [preSelectItem]="newContact.new.position"
              (itemSelected)="itemSelected('position', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-2">
          <field-type-text-simple [field]="newContact.companyname"
              [preSelectItem]="newContact.new.companyname"
              (itemSelected)="itemSelected('companyname', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100':'new-contact__col-3'">
          <field-type-text-simple [field]="newContact.address"
              [preSelectItem]="newContact.new.address"
              (itemSelected)="itemSelected('address', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100':'new-contact__col-5'">
          <field-type-number [field]="newContact.postalCode"
              [preSelectItem]="newContact.new.postalCode"
              (itemSelected)="itemSelected('postalCode', $event.newValue)">
          </field-type-number>
      </div>
      <div [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100 new-contact__col-3-100--right':'new-contact__col-4 new-contact__col-left'">
          <field-type-text-simple [field]="newContact.city"
              [preSelectItem]="newContact.new.city"
              (itemSelected)="itemSelected('city', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-4 new-contact__col-4--right"
          *ngIf="skin !== 'uk-steel-blue'">
          <field-type-text-simple [field]="newContact.province"
              [preSelectItem]="newContact.new.province"
              (itemSelected)="itemSelected('province', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-4 new-contact__col-4--country"
          *ngIf="skin !== 'uk-steel-blue'">
          <field-type-text-simple [field]="newContact.country"
              [preSelectItem]="newContact.new.country"
              (itemSelected)="itemSelected('country', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100':'new-contact__col-3'">
          <field-type-text-simple [field]="newContact.email"
              [preSelectItem]="newContact.new.email"
              (itemSelected)="itemSelected('email', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div class="new-contact__col-left"
          [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100':'new-contact__col-6'">
          <field-type-text-simple [field]="newContact.phonenumber1"
              [preSelectItem]="newContact.new.phonenumber1"
              (itemSelected)="itemSelected('phonenumber1', $event.newValue)" >
          </field-type-text-simple>
      </div>
      <div [ngClass]="skin === 'uk-steel-blue'?'new-contact__col-3-100 new-contact__col-3-100--right':'new-contact__col-6'">
          <field-type-text-simple [field]="newContact.phonenumber2"
              [preSelectItem]="newContact.new.phonenumber2"
              (itemSelected)="itemSelected('phonenumber2', $event.newValue)" >
          </field-type-text-simple>
      </div>

  </form>
  <div class="common__buttons-block common__buttons-block--new-contact">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="changeState($event)"
          [afTranslate]="'common.cancel'">Cancelar</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="createNew()"
          [afTranslate]="'common.add'">Añadir</button>
  </div>
</div>
