import { Injectable } from '@angular/core';

import { ApiPropertiesFactory } from '../api-properties/api-properties.factory';
import { Router } from '@angular/router';
import { AppLanguageFactory } from '../app-language/app-language.factory';
import { GlobalCfgFactory } from '../global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { LoaderFactory } from '../loader/loader.factory';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ContextFactory {
  private LOGIN_KEY: string = 'loginInfo';
  private JWT_KEY: string = 'jwt';
  private LANGUAGE_KEY: string = 'selectLanguage';
  private VIEW_KEY: string = 'view';
  private SETTINGS_KEY_PREFIX: string = 'settings_';

  user: any = null;
  environment: any = null;
  settings: any = {};
  notCallAnonymatumService: boolean = true;
  jwtToken:any = sessionStorage[this.JWT_KEY] || localStorage[this.JWT_KEY];
  persistent: boolean = !!localStorage[this.JWT_KEY]
  view: any = {};
  areas: any = {};
  productTypeId:string = '';
  anonymatumService:boolean = false;
  storedLogin:any = this.getStoredLogin();
  key: any = null;

  constructor(
    private apiProperties:ApiPropertiesFactory,
    private router: Router,
    private appLanguage: AppLanguageFactory,
    private translate: AfTranslateFactory,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory) {
        this.view = this.getStoredView() || {};
        if (this.storedLogin) {
          this.storedLogin.jwtToken = this.jwtToken;
          this.setLogin(this.storedLogin);
        }
  }

  getStoredLogin() {
    let storedLoginString = sessionStorage[this.LOGIN_KEY];
    if (storedLoginString) {
        try {
            let storedLogin = JSON.parse(storedLoginString);
            if (storedLogin) {
                return storedLogin;
            }
        } catch (e) {
            this.user.clearStore();
            console.error('Exception deserializing login info object');
            console.error(e);
        }
    }
  }

  getStoredView(){
    let storedViewString = sessionStorage[this.VIEW_KEY];
    if (storedViewString) {
        try {
            let storedView = JSON.parse(storedViewString);
            return storedView || {};
        } catch (e) {
            sessionStorage.removeItem(this.VIEW_KEY);
            console.error('Exception deserializing login info object');
            console.error(e);
        }
    }
  }

  setLogin(loginInfo: any, persistent: boolean = false) {
        this.logout(true);
        this.user = this.setUser(loginInfo.user);
        this.setSettings(this.user);
        this.environment = loginInfo.user.environment;
        this.anonymatumService = loginInfo.anonymatumService;

        if (loginInfo.extraData) {
            this.view.extraData = loginInfo.extraData;
            this.storeView();
            delete loginInfo.extraData;
        }
        if(this.globalCfg.defaultProduct && !this.settings.defaultProduct){
            this.settings.defaultProduct = this.globalCfg.defaultProduct;
        }
        //Warning: Equivalent code on app.module.js (front) and app.js(back) and context.factory (back and front)
        let userLanguageSupported = false;
        let userLanguage:any = sessionStorage.getItem(this.LANGUAGE_KEY);
        let languageKey = userLanguage;
        if (!userLanguage || userLanguage === "null") {
            userLanguage = this.user.culture;
            if (!userLanguage) {
                userLanguage = (navigator.language /*|| navigator.userLanguage*/); //|| navigator.userLanguage
            }
            if(this.settings?.defaultProduct){
                languageKey = this.getLanguageKey(userLanguage, this.settings.defaultProduct);
            }
            if(languageKey === null){
                languageKey = userLanguage;
            }
            userLanguageSupported = this.apiProperties.availableLanguages.indexOf(userLanguage) !== -1;
            if (!userLanguageSupported) {
                userLanguage = userLanguage.split('-')[0];
                userLanguage = this.apiProperties.availableLanguages.find((language:any)=> language.indexOf(userLanguage) === 0 );
                userLanguageSupported = !!userLanguage;
                languageKey = userLanguage;
            }
        } else {
            if(this.settings?.defaultProduct){
                languageKey = this.getLanguageKey(userLanguage, this.settings.defaultProduct);
            }
            userLanguageSupported = true;
        }

        if (userLanguageSupported) {
            if(this.translate.hasLanguage(languageKey)){
              this.translate.setLanguageToTranslate(languageKey);
              sessionStorage.setItem(this.LANGUAGE_KEY, userLanguage);
            }else{
              this.translate.getTranslates(languageKey).then((data:any)=>{
                sessionStorage.setItem(this.LANGUAGE_KEY, userLanguage);
              }, (errorData:any)=>{})
            }
        } else {
            this.appLanguage.setLanguaje(this.translate.languageToUse);
            sessionStorage.setItem(this.LANGUAGE_KEY, this.apiProperties.defaultLanguage);
            console.log('User language ' + languageKey + ' not supported. Will keep using ' + this.translate.languageToUse);
        }
        this.view.dashboard = 'dashboard';

        //                               "Base datos"              ||            "Mementos"               ||         "Mi biblioteca"
        this.areas.juridical = this.user.areas?.indexOf('30') >= 0 || this.user.areas?.indexOf('31') >= 0 || this.user.areas?.indexOf('32') >= 0;
        this.areas.sibila = this.areas.juridical && this.user.areas?.indexOf('34') >= 0;
        this.areas.signaturit = this.user.areas?.indexOf('88') >= 0;

        this.jwtToken = loginInfo.jwtToken;
        delete loginInfo.jwtToken;
        if (persistent) {
            localStorage[this.JWT_KEY] = this.jwtToken;
        } else {
            sessionStorage[this.JWT_KEY] = this.jwtToken;
        }
        let loginInfoString = JSON.stringify(loginInfo);
        sessionStorage[this.LOGIN_KEY] = loginInfoString;
  };

  logout(keepInfo: boolean = false) {
      sessionStorage.removeItem(this.LOGIN_KEY);
      if (!keepInfo) {
          sessionStorage.removeItem(this.LANGUAGE_KEY);
      }
      sessionStorage.removeItem(this.JWT_KEY);
      localStorage.removeItem(this.JWT_KEY);
      this.clearViewStore();
      if (keepInfo && this.view.extraData) { //Para que no se pierda el extraData del icam
          sessionStorage[this.VIEW_KEY] = JSON.stringify({ extraData: this.view.extraData });
      }
      this.user = null;
      this.jwtToken = null;
      this.notCallAnonymatumService = true;
  };

  clearViewStore() {
    sessionStorage.removeItem(this.VIEW_KEY);
  }

  setUser(userInfo: any) {
    // return Object.assign(this, userInfo);
    return Object.assign(userInfo);
  }

  setSettings(user: any=undefined) {
      if (user) {
          this.settings.key = this.SETTINGS_KEY_PREFIX + '_' + user.userId;
          let storedSettingsString = localStorage[this.settings.key];
          if (storedSettingsString) {
              try {
                  let storedSettings =JSON.parse(storedSettingsString);
                  if (storedSettings) {
                      this.settings =  storedSettings;
                  }
              } catch (e) {
                  console.error('Exception deserializing settings object');
                  console.error(e);
              }
          }
      }
  }

  storeSettings() {
    let settings = cloneDeep(this.settings)
    localStorage.setItem(this.settings.key, JSON.stringify(settings));
  };

  clearStore() {
      localStorage.removeItem(this.key);
  };

  storeView() {
    let viewString = JSON.stringify(this.view);
    sessionStorage[this.VIEW_KEY] = viewString;
  }

  getLanguageKey(language:string, productId:string):string {
      if (productId === 'taxwin' && language === 'en-GB') {
          return 'taxwin_en-GB';
      }
      if (this.globalCfg.skin === 'steel-blue') {
        if (productId === 'bedrijfsst' || productId === 'blanqueo' || productId === 'whistle') {
            return productId + '_' + language;
        } else {
            // Por defecto, AML
            return 'blanqueo_' + language;
        }
      }
      if(this.globalCfg.skin === 'dark-blue' && productId === 'denunc' && language === 'fr-FR'){
          return 'denunc_fr-FR';
      }
      if(this.globalCfg.skin === 'gm-law' && productId === 'denunc' && language === 'it-IT'){
          return 'denunc_it-IT';
      }
      return language;
  }

  renewJwt(newJwt: any) {
      this.jwtToken = newJwt;
      this.persistent? localStorage[this.JWT_KEY] = newJwt : sessionStorage[this.JWT_KEY] = newJwt;
  };

  sessionExpired() {
      this.logout();
      this.router.navigate(['login']);
      this.loader.closeError();
  };

  setProductId(productTypeId: string) {
    this.productTypeId = productTypeId;
  }

  setAnonymatumService(state: boolean){
    this.anonymatumService = state;
    this.notCallAnonymatumService = false;
  }

}
