import moment from 'moment';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { sortBy } from 'lodash';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html'
})
export class ScheduleComponent implements OnInit {
  selectedLanguage: any = sessionStorage.getItem('selectLanguage');
  schedule: any = {
    showCalendar: false,
    start: moment(),
    end: moment(),
    calendarOptions: {
      plugins: [dayGridPlugin,
        timeGridPlugin, listPlugin, interactionPlugin],
      locales: [allLocales],
      // locale: this.context.user.culture.split('-')[0],
      locale: this.selectedLanguage.split('-')[0],
      initialView: 'timeGridDay',
      slotDuration: '01:00',
      height: 690,
      defaultTimedEventDuration: '00:38:00',
      displayEventTime: false,
      forceEventDuration: true,
      firstDay: 1,
      contentHeight: 200,
      allDayText: this.translate.instant('schedule.working-day'),
      // slotWidth: 63,
      headerToolbar: {
        left: '',
        right: 'prev,next'
      },
      events: [],
      // color: 'black',
      // textColor: 'yellow',
      slotLabelFormat: [
        {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        }
      ],
      views: {
        timeGridDay: {
          nowIndicator: true,
          titleFormat: {
            weekday: 'long'
          }
        },
        timeGridWeek: {
          dayHeaderFormat: { weekday: 'short', day: 'numeric' },
          displayEventTime: false,
          titleFormat: {
            year: 'numeric',
            month: 'long'
          }
        },
        dayGridMonth: {
          showNonCurrentDates: false,
          displayEventTime: false,
          titleFormat: {
            year: 'numeric',
            month: 'long'
          }
        }
      },
      dateClick: () => { },
      eventClick: (event: any, e: any = null, view: any = null) => { this.eventClickCallback(event, e, view) },
      navLinkDayClick: (date: any, jsEvent: any) => {
        console.log('day', date.toISOString());
        console.log('coords', jsEvent.pageX, jsEvent.pageY);
      },
      datesSet: (e: any) => {
        this.schedule.start = e.start;
        this.schedule.end = e.end;
      },

    },
    views: [{
      code: 'timeGridDay',
      filter: 'calendar-year',
      label: this.translate.instant('schedule.view-buttons.day'),
    }, {
      code: 'timeGridWeek',
      filter: 'calendar-week',
      label: this.translate.instant('schedule.view-buttons.week2')
    }, {
      code: 'dayGridMonth',
      label: this.translate.instant('schedule.view-buttons.month')
    }],
    search: false,
    searching: false,
    searchText: new FormControl(),
    eventsList: null,
    eventsListTitle: '',
    eventsListSubtitle: null,
    filterSelected: 3,
    subtitle: null,
    events: [],
    eventSources: [[]],
    error: false,
    optionsEvents: [
      {
        name: this.translate.instant('schedule.tracking-alerts'),
        option: 'alert',
        selected: true
      },
      {
        name: this.translate.instant('schedule.meeting'),
        option: 'meeting',
        selected: true
      },
      {
        name: this.translate.instant('schedule.tasks-due'),
        option: 'tasksDue',
        selected: true
      }
    ],
    calendar: null,
    uiCalendarConfig: {}
  }
  eventsCopy = [];
  @Input() typeView: any = 'normal';
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  constructor(
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private stateManagement: StateManagementFactory,
    private router: Router
  ) {}

  ngOnInit(): void {
    let that: any = this.schedule;
    that.calendarOptions.height = this.typeView === 'window' ? 930 : 690;
    that.view = that.views[0];
    that.selectedView = that.views[0];
    this.loadEvents();
  }

  changeView(view: any) {
    this.schedule.selectedView = view;
    this.schedule.calendarOptions.initialView = view.code;
    if (this.typeView === 'window') {
      if (view.code === 'timeGridDay') {
        this.schedule.calendarOptions.height = 930;
      } else if (view.code === 'dayGridMonth') {
        this.schedule.calendarOptions.height = 1102;
      } else {
        this.schedule.calendarOptions.height = 943;
      }
    }
    this.dropdown.close();
  }

  doSearch() {
    this.schedule.eventsListSubtitle = null;
    if (this.schedule.searchText.value) {
      this.schedule.eventsList = this.commonUtilsService.findByWord(this.schedule.calendarOptions.events, this.schedule.searchText.value, ['title']);
    }
    this.schedule.eventsListTitle = this.translate.instant('schedule.search-results');
    this.schedule.searching = true;
    this.schedule.showEventsList = true;
  }

  closeListView() {
    this.schedule.showEventsList = false;
    this.schedule.showCalendar = true;
    this.schedule.searching = false;
    this.schedule.searchText.value = '';
    this.schedule.showSearch = false;
    this.schedule.eventsList = this.schedule.calendarOptions.events;
    this.schedule.calendarOptions.events = [...this.schedule.calendarOptions.events];
  }

  loadEvents() {
    this.schedule.error = false;
    this.schedule.calendarOptions.events.length = 0;
    this.apiService.get('events', '').then((data: any) => {
      data.forEach((event: any) => {
        event.stick = true;
        event.className = ['schedule__event--' + event.type];
        if (event.hasRepeatPattern) {
          event.className += ' schedule__event--periodic';
        }
        if (event.videoconference) {
          event.className += ' schedule__event--videoconference';
        }
        event.timeText = this.translate.instant('schedule.time-text.' + event.type);
        this.schedule.calendarOptions.events.push(event);
      });
      this.schedule.showCalendar = true;
      this.schedule.eventsList = this.schedule.calendarOptions.events;
      this.eventsCopy = this.schedule.calendarOptions.events;
    }, (errorData: any) => {
      this.schedule.error = true;
    });
  }

  getEventTime(event: any) {
    let startDate = new Date(event.start);
    return event.start ? startDate.getTime() : 0;
  }


  eventClickCallback(event: any, e: any = null, view: any = null) {
    let intervalStart = moment(event.event.start).startOf('day');
    let intervalEnd = moment(event.event.start).endOf('day');
    let dayEvents = this.filterEventsBetween(intervalStart, intervalEnd);
    dayEvents = sortBy(dayEvents, 'start');
    this.schedule.eventsList = dayEvents;
    this.schedule.showEventsList = true;
  }

  eventLimitClick(info: any) {
    if (info.segs.length) {
      this.eventClickCallback(info.segs[0])
    }
  }

  filterEventsBetween(intervalStart: any, intervalEnd: any) {
    let events: any = this.schedule.calendarOptions.events;
    return events.filter((event: any) => moment(event.start).isSameOrAfter(intervalStart) && moment(event.start).isBefore(intervalEnd));
  }

  openBigSchedule() {
    this.stateManagement.setStateUrl(window.location.pathname);
    this.commonUtilsService.registerEvent('open agenda', this.context.settings.defaultProduct);
    this.router.navigate(['schedule']);
  }

  selectElement(e: any, event: any) {
    e.stopPropagation();
    e.preventDefault();
    this.schedule.eventsListTitle = this.translate.instant('schedule.search-results');
    event.selected = !event.selected;
    this.schedule.calendarOptions.events = [];
    this.schedule.eventsList = [];
    const filters = this.schedule.optionsEvents.filter((options: any) => options.selected).map((options: any) => options.option);
    for (const filter of filters) {
      if (filter === "tasksDue") {
        this.schedule.calendarOptions.events.push(...this.showCalendarEventByType());
        this.schedule.eventsList.push(...this.showCalendarEventByType());
      } else {
        this.schedule.calendarOptions.events.push(...this.showCalendarEventByType(filter));
        this.schedule.eventsList.push(...this.showCalendarEventByType(filter));
      }
    }
  }

  showCalendarEventByType(type?: string) {
    return this.eventsCopy.filter((item: any) => {
      if (type) {
        return item.type === type;
      } else {
        return item.type !== 'alert' && item.type !== 'meeting';
      }
    });
  }

  showTooltip(id: any) {
    return this.commonUtilsService.showTooltip(id);
  }

  openSearch() {
    this.schedule.showSearch = !this.schedule.showSearch;
  }

}
