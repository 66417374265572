import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'history-viewer',
  templateUrl: './history-viewer.component.html'
})
export class HistoryViewerComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  countElementSearch:number = 0;
  historyViewer:any = {
    historyViewerData: sessionStorage.getItem('historyViewerData'),
    historyViewerDataJson: sessionStorage.getItem('historyViewerDataJson'),
    textSearch: ''
  };
  constructor(
    private globalCfg:GlobalCfgFactory,
    private loader:LoaderFactory,
    private context:ContextFactory,
    private router:Router,
    private customLanding:CustomLandingFactory,
    private apiService:ApiServiceService,
    private modalService:ModalServiceService,
    private translate:AfTranslateFactory,
    private downloadService:DownloadService,
    private exportService:ExportServiceService,
    private commonUtilsService: CommonUtilsService
  ) { }

  @Input() historyViewerData:any = '';

  ngOnInit(): void {
    let that = this.historyViewer;
    that.historyViewerData = this.historyViewerData?this.historyViewerData:that.historyViewerData;
    that.administrationPermits = !!that.historyViewerData || !!that.historyViewerDataJson;
    if(that.administrationPermits){
      this.loader.openLoader('history-viewer-charge');
      if(that.historyViewerDataJson){
          this.configureJSON(that.historyViewerDataJson);
      }else{
          this.getHistoricViewer();
      }
    }else{
        this.router.navigate(this.context.view.dashboard);
    }
  }

  getHistoricViewer() {
    this.apiService.get('conceptobjects/historic', this.historyViewer.historyViewerData + '/json-snapshot').then(
      (data:any) => {
        this.configureJSON(data.jsonSnapshot);
      },
      (error:any) => {
        console.error(error);
        this.loader.closeError();
        this.router.navigate(this.context.view.dashboard);
      }
    );
  }

  configureJSON(dataJSON:any) {
    this.historyViewer.historyDataViewer = JSON.parse(dataJSON);
    this.configureLogoHeader();
    this.configureFields(this.historyViewer.historyDataViewer?.fieldContainers);
    this.loader.closeLoader('history-viewer-charge');
  }

  configureFields(containers: any){
    containers.forEach((container: any) => {
      if(container.fields?.length){
        container.fields.forEach((field: any) => {
          switch(field.fieldTypeFrontId){
            case 'bool':
            case 'txchk':
              field.printTypeId = 'bool';
              break;
            case 'list':
            case 'list-extended':
            case 'file':
            case 'templ':
            case 'pepl':
              field.printTypeId = 'list';
              break;
            default:
              field.printTypeId = field.fieldTypeFrontId;
              break;
          }
        });
      }

      if(container.subContainers?.length){
        this.configureFields(container.subContainers);
      }
    });
  }

  configureLogoHeader(){
    let that = this.historyViewer;
    if(that.historyDataViewer.custom){
        this.customLanding.setCustom(that.historyDataViewer.custom);
        document.body.classList.remove('compliance__'+this.skin);
        that.logoHeader = that.historyDataViewer.custom.logoFooter;
        this.skin = 'landing-custom';
        document.body.classList.add('compliance__landing-custom')
    }else{
        that.logoHeader =  this.commonUtilsService.configureLogoHeader();
    }
  }

  searchTextfunction(e:any){
    let that = this.historyViewer;
    if(e.keyCode === 13 && that.countElementSearch){
        this.changeElementSelected('down');
    }else if(that.textSearch !== that.textSearchCopy){
        this.cleanSearch();
        if (that.textSearch && that.textSearch.length !== 0) {
            $('.history-viewer__search-text').each((index:any, e:any) => {
                let search = $(e).html();
                let searchInnerText = e.innerText;
                let characters:any = {
                    "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
                    "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u",
                    "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
                    "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U" }; // "ñ":"n", "Ñ":"N",
                let regExpCharacters=/[áàéèíìóòúù]/ig; //[áàéèíìóòúùñ]/ig;
                let textSearchWithoutAccent = that.textSearch.replace(regExpCharacters, (e:any) => {return characters[e];});
                let searchWithoutAccent = searchInnerText.replace(regExpCharacters, (e:any) => {return characters[e];});
                if(searchWithoutAccent.toLowerCase().indexOf(textSearchWithoutAccent.toLowerCase()) !== -1){
                    let resultTagArray = search.split('');
                    let textModify = '';
                    let textHtml = '';
                    let tagHTML = false;
                    for(let i = 0; i < resultTagArray.length; i++){
                        let letterResult = resultTagArray[i];
                        let resultArrayWithoutAccent = letterResult.replace(regExpCharacters,(e:any) => {return characters[e];});
                        if(resultTagArray[i] === '<' && e.className.indexOf('history-viewer__value-bind-html') !== -1){
                            tagHTML = true;
                        }else if(tagHTML && resultTagArray[i] === '>'){
                            tagHTML = false;
                        }
                        if(!tagHTML && textSearchWithoutAccent.toLowerCase().indexOf(resultArrayWithoutAccent.toLowerCase()) !== -1 && textModify.length < that.textSearch.length){
                            textModify += resultTagArray[i];
                                let textModifyWithoutAccent = textModify.replace(regExpCharacters, (e:any) => {return characters[e];});
                                if(textModifyWithoutAccent.toLowerCase() === textSearchWithoutAccent.toLowerCase()){
                                    textHtml += '<span class="history-viewer__text-searched">' + textModify + '</span>';
                                    textModify = '';
                                }else if(i === resultTagArray.length-1){
                                    textHtml += textModify;
                                }

                        }else{
                            textHtml += textModify + letterResult;
                            textModify = '';
                        }
                    }
                    $(e).html(textHtml);
                }
            });
            let elementSearch = document.getElementsByClassName('history-viewer__text-searched');
            that.countElementSearch = elementSearch.length;
            if(elementSearch.length >= 1){
                that.elementSelected = 1;
            }
            for(let i = 0; i < elementSearch.length; i++){
                let elementTextSearched = elementSearch[i];
                elementTextSearched.setAttribute('id', 'text-searched-'+(i+1));
            }
            $('#text-searched-1').addClass('history-viewer__selected-search');
        }
    }
  }

  cleanSearch(){
    let that = this.historyViewer;
    that.textSearchCopy = that.textSearch;
    that.countElementSearch = 0;
    that.elementSelected = 0;
    let elementSearch = document.getElementsByClassName('history-viewer__text-searched');
    for(let i = 0; i < elementSearch.length; i++){
        let elementTextSearched = elementSearch[i];
        elementTextSearched.removeAttribute('id');
    }

    $('.history-viewer__search-text').each((index:any, e:any) => {
        let search = $(e).html();
        search = search.split(' history-viewer__selected-search').join('');
        search = search.split('<span class="history-viewer__text-searched">').join('');
        search = search.split('</span>').join('');
        $(e).html(search);
    });
  }

  toggleSearch(){
    let that = this.historyViewer;
    that.search = !that.search;
    if(that.search){
        this.collapseOrExpandAll(false);
    }else {
        $('#history-viewer-block-search').removeClass('history-viewer__block-search--floating');
        that.textSearch = '';
        this.cleanSearch();
    }
  }

  collapseOrExpandAll(hiddenView:any){
    this.recursiveCollapseOrExpandAll(this.historyViewer.historyDataViewer.fieldContainers, hiddenView);
  }

  recursiveCollapseOrExpandAll(containers:any, hiddenView:any){
    for(let i = 0; i < containers.length; i++){
        let container = containers[i];
        container.hiddenView = hiddenView;
        if(container.fields){
            for(let j = 0; j < container.fields.length; j++){
                let field = container.fields[j];
                if((field.styleOfField === 'cascade') && field.fixedValue){
                    for(let k = 0; k < field.fixedValue.length; k++){
                        field.fixedValue[k].hiddenView = hiddenView;
                    }
                }
            }
        }
        if(container.subContainers){
            this.recursiveCollapseOrExpandAll(container.subContainers, hiddenView);
        }
    }
  }

  changeElementSelected(action:string){
    let that = this.historyViewer;
    $('#compliance-view-body').scrollTop(0);
    $('#history-viewer-block-search').removeClass('history-viewer__block-search--floating');
    let previousElementSelected = '#text-searched-' + that.elementSelected;
    if(action === 'up'){
        if(that.elementSelected === 1){
            that.elementSelected = that.countElementSearch;
        }else{
            that.elementSelected--;
        }
    }else{
        if(that.elementSelected < that.countElementSearch){
            that.elementSelected++;
        }else{
            that.elementSelected = 1;
        }
    }
    let newElementSelected = '#text-searched-' + that.elementSelected;
    $(previousElementSelected).removeClass('history-viewer__selected-search');
    $(newElementSelected).addClass('history-viewer__selected-search');
    let heightWindow:any = window.innerHeight;//document.getElementById('compliance-view-body')?.getBoundingClientRect().height;
    let topElement:any = document.getElementById('text-searched-' + that.elementSelected)?.getBoundingClientRect().top;
    if(topElement > heightWindow){
        $('#history-viewer-block-search').addClass('history-viewer__block-search--floating');
        $('#compliance-view-body').scrollTop(topElement - 65);
    }
  }

  printDocument(){
    window.print();
  }

  exportEdition() {
    // this.toggleSearch();
    let that = this.historyViewer;
    let conceptObjectId: any = that.historyViewerData !== null ? that.historyViewerData.substring(0, that.historyViewerData.indexOf('/')) : that.historyDataViewer.conceptObjectId;
    this.apiService.get('documentobjects/onlydbfiles/' + conceptObjectId).then(
        (data:any) => {
            let namesList = [];
            for (let index in data) {
                namesList.push({name: data[index]});
            }
            let htmlPrint = $('#history-viewer').html().replace('history-viewer__block-header-body', 'common__hide');
            let configuration: any = {
              list: namesList,
              hasPrevious: false,
              title: this.translate.instant('historic-concept.export-edition'),
              extension: 'PDF'
            }
            this.modalService.exportModal(configuration)
              .subscribe((result:any) => {
                  if(result.result === 'ok'){
                    let config = {
                        htmlPrint: htmlPrint,
                        nameDocument: result.filename+'.pdf',
                        conceptObjectId: conceptObjectId,
                        uploadFile: true,
                        successCallback: (uploadResponse:any, blob:any) => {
                            this.modalService.adviceModal(this.translate.instant('historic.export-edition-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
                            if (result.downloadFile){
                                this.downloadService.triggerDownload(blob, uploadResponse.name);
                            }
                            if (result.openFile){
                                window.open(URL.createObjectURL(blob), '_blank');
                            }
                        }
                    }
                    this.exportService.toPdf(config);
                  }
              });
        },
        (error:any) => {
            console.error(error);
        }
    );
  }

  changeShow(item:any){
    item.hiddenView = !item.hiddenView;
  }
}
