import { AfterViewInit, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'new-version',
  templateUrl: './new-version.component.html'
})
export class NewVersionComponent implements AfterViewInit {
  skin: string = this.globalCfg.skin;
  newVersion: any = {
    fieldInfo: {
      id:"new-version-text",
      required: true,
      label: this.translate.instant('documentation-list.name-new-document')
    },
    version: {
        name: ''
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }

  ngAfterViewInit(): void {
      let element: any = $('#field-item-text-new-version-text')
      element.focus().select();
  }

  itemSelected(item:string, e:any){
      this.newVersion.version[item] = e.newValue;
  }

  validateForm(){
      if(this.newVersion.version.name === null || this.newVersion.version.name === ''){
          this.newVersion.fieldInfo.error = true;
      }else{
          this.newVersion.fieldInfo.error = false;
          this.handleAccept();
      }
  }

  reset(){
    this.newVersion.version={
          name: ''
      }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      name: this.newVersion.version.name
    });

  }
}


