import { AfterViewInit, Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { GlobalCfgFactory } from "@shared/factories/global-cfg/global-cfg.factory";

@Component({
  selector: 'export-format-modal',
  templateUrl: './export-format-modal.component.html'
})
export class ExportFormatModalComponent implements AfterViewInit{
  constructor(private globalCfg:GlobalCfgFactory,
    private translate:AfTranslateFactory,
    private activeModal:NgbActiveModal){}

  @Input() title:string;
  @Input() listType:string;

  skin:string = this.globalCfg.skin;
  selectedFormat:string;
  folderText:string='';

  ngAfterViewInit(): void {
    if (this.title == null || $.trim(this.title) == '') {
      this.title = this.translate.instant('common.export-excel');
    }

    // switch (this.listType) {
    //   case 'documentation':
    //       this.folderText = this.translate.instant('export-format-modal.format-texts.text-folder');
    //       break;
    // }
  }

  selectFormat(format:string) {
    this.selectedFormat = format;
  }

  handleAccept(){
      this.activeModal.close({
          result: 'ok',
          selectedFormat: this.selectedFormat
      });
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
