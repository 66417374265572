
import { Component, Input, OnInit, Output, OnChanges, EventEmitter } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { StatesProcessParentService } from './../utils/states-process-parent.service';


@Component({
  selector: 'states-process-custom',
  templateUrl: './states-process-custom.component.html'
})
export class StatesProcessCustomComponent implements OnInit, OnChanges {

  custom: any = this.customLanding.getCustom();
  isLanding: boolean = true;
  evaluationId: any = this.route.snapshot.params['evaluationId']
  statesProcess:any = {
    arrowsPrev: '<<',
    arrowsAfter: '>>',
    statesList: [],
    showStates: false,
    showQuestion: false,
    canChangeState: false,
    canAddAlerts: false,
    titleHeader: this.translate.instant('states-task-help.task-states'),
    iconName: this.translate.instant('states-process.view-proccess'),
    summary:{
      current:{}
    }
  }

  constructor(private customLanding: CustomLandingFactory,
    private translate: AfTranslateFactory,
    private route: ActivatedRoute,
    private statesProcessParent: StatesProcessParentService) {

  }

  @Input() type:any = null;
  @Input() typeView:any = null;
  @Input() item:any = null;
  @Input() concept:any = null;
  @Input() userPublic:any = null;

  @Output() changeResponsible:any = new EventEmitter();
  @Output() changeState:any = new EventEmitter();
  @Output() changeLockedEdit:any = new EventEmitter();
  @Output() changeStateModal:any = new EventEmitter();


  ngOnInit(): void{
    this.statesProcessParent.configureInit(this);
  }

  ngOnChanges(): void {
    this.statesProcessParent.configureChanges(this);
  }

  changeProcessQuestion(itemSelected:any, option:any) {
    this.statesProcessParent.changeProcessQuestion(itemSelected, option, this);
  }

  confirmChange(){
    this.statesProcessParent.confirmChange(this);
  }

  showProcessModal() {
    this.statesProcessParent.showProcessModal(this);
  }
}
