<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-legal-reference attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'law-info.legal-references'">DOCUMENTACIÓN JURÍDICA</p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            (click)="maximizeScreen()"
            matTooltip="{{(conceptLegalDocumentation.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [ngClass]="conceptLegalDocumentation.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'">
        </i>
    </div>

    <div class="predefined-templates__historic-table predefined-templates__historic-table--custom-legal"
        [ngClass]="{'predefined-templates__historic-table--expand': conceptLegalDocumentation.fullScreen}"
        [ngShow]="referencesList.length">
        <div class="task-historic__historic-table-header task-historic__historic-table-header--custom">
            <p class="concept-legal-documentation__title-legal-custom"
                [afTranslate]="'concept-form.title-legal-concept-documentation'">Consulta la información relacionada con el concepto</p>
        </div>
        <div class="task-historic__historic-block task-historic__historic-block--custom"
            [ngClass]="{'task-historic__historic-block--expand': conceptLegalDocumentation.fullScreen,
                'task-historic__historic-block--more-elements': referencesList.length > 3}">
            <div class="task-historic__documents-table"
                *ngFor="let reference of referencesList">
                <div class="common__table-cell-tooltip concept-legal-documentation__legal-item-custom">
                    <i [class]="'concept-legal-documentation__icons-reference-custom ' + (reference.type | taskTypeIcons:'reference')"></i>
                    <div class="concept-legal-documentation__cell-block">
                        <span-tooltip [text]="reference.text"></span-tooltip>
                    </div>
                </div>
                <div class="task-historic__header-col task-historic__header-col--2">
                    <div class="task-historic__options-block"
                        [ngHide]="conceptLegalDocumentation.userPermissions==='none'">
                        <ul class="attachments-template__documents-table-options-custom">
                            <li class="task-historic__menu-options">
                                <button class="historic-concept__button-eye historic-concept__button-eye--custom"
                                    (click)="showLegalDocumentation($event, reference)">
                                    <i class="lf-icon-visible task-historic__icon-eye"
                                        matTooltip="{{'common.view' | afTranslate}}"
                                        matTooltipPosition="left"
                                        matTooltipClass="left">
                                    </i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
