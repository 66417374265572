import $ from 'jquery';

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import ExpandMenu from '../../../../assets/data/expanded-menu.json';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';

@Component({
  selector: 'evaluation-menu',
  templateUrl: './evaluation-menu.component.html'
})
export class EvaluationMenuComponent implements OnInit, OnChanges, OnDestroy {
  skin: string = this.globalCfg.skin;
  userPermissions: any = this.route.snapshot.data['userPermissions'];
  subscribers : any = {};
  // routeData: any = this.route.firstChild?.data;
  esBBDD: boolean = (this.skin === 'dark-blue' || this.skin === 'gm-law')
  extBBDD: boolean = (this.skin ==='steel-blue' || this.skin === 'uk-steel-blue')
  expandMenu:any = ExpandMenu;
  folderId: string = this.route.snapshot.firstChild?this.route.snapshot.firstChild.params['folderId']:'';
  conceptObjectId:any = this.route.snapshot.firstChild?.params['conceptObjectId'];
  viewMode: boolean = window.location.search.indexOf('?preview=') !== -1 || this.conceptObjectId === 'view';
  evaluationMenu: any ={
    callsManageEvaluation: 0,
    showReportsFolder: false,
    changeHiddenFolder: false,
    openFloatMenu: false,
    preview: Boolean( this.context.user.companyId === null),
    openTreeDesign: false,
    loadedCallReports: false,
    showAnticorruption: false,
    disabledLink: false,
    folderAutoinstanciable: false,
    calledAlerts: false,
    evaluationId: this.route.snapshot.params['evaluationId'],
    folderListMenu: [],
    optionsEvaluationMenu: this.expandMenu['optionsEvaluationMenu'].menu,
    menuOperationLocked: this.expandMenu['menuManageBlock'].menu,
    reportsTypeList: [],
    rolesLevel: []
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private context: ContextFactory,
    private conceptState: ConceptStateFactory,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private stateManagement: StateManagementFactory,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private expandedMenuService: ExpandedMenuService,
    private modalService: ModalServiceService,
    private rolesUtilsService: RolesUtilsService,
    private reportVersion: ReportVersionFactory) { }

  @Input() evaluationTree: any = null;
  @Input() evaluationInfo: any = {};
  @Input() fullMenu: boolean = false;
  @Input() evaluatedConcept: any = null;
  @Input() heightMenuLateral: any = null;
  @Input() showHidden: any = false;
  @Input() showReport: any = null;
  @Input() folderSelect: any = null;
  @Input() rolesDesassignedResolve: any = false;
  @Output() sendAction = new EventEmitter();
  @Output() minimizeMenu = new EventEmitter();
  @Output() changeShow = new EventEmitter();
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnInit(): void {
    this.launchWatchers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.folderId = this.route.snapshot.firstChild?this.route.snapshot.firstChild.params['folderId']:'';
    this.evaluationMenu.openFloatMenu = this.fullMenu;
    if(changes['folderSelect'] && changes['folderSelect'].currentValue){
      this.evaluationMenu.folderSelect = changes['folderSelect'].currentValue;
    }
    if(this.evaluationInfo){
      if(this.evaluationInfo.productTypeId === 4){
        this.evaluationMenu.openTreeDesign = true;
      }
      if(this.evaluationInfo.evaluationId && !this.evaluationMenu.loadedCallReports){
          this.getReportList();
      }
      if(this.evaluationInfo.productId === 'blanqueo'){
          if(!this.evaluationMenu.calledAlerts){
              this.getAlerts();
              this.evaluationMenu.calledAlerts = true;
          }
          if(this.evaluationMenu.chargedRoles){
              this.configureRoles();
          }else{
              this.getRoles();
          }
      }
      this.evaluationMenu.folderListMenu = this.configureFolderListMenu();
    }
    this.evaluationMenu.stateFolder = this.route.snapshot.firstChild?.data['componentName'] === 'folder';
    this.evaluationMenu.folderAutoinstanciable = this.evaluationMenu.folderSelect && this.evaluationMenu.folderSelect.conceptObjectId && this.evaluationMenu.folderSelect.conceptObjectId !== 0 && this.evaluationMenu.folderSelect.concepts && this.evaluationMenu.folderSelect.concepts.length && !this.evaluationMenu.folderSelect.concepts[0].multipleInstancesInAuto;
  }

  launchWatchers(){
    this.subscribers.changeActiveMenuBroadcast = this.broadcastService.subscribe("changeActiveMenu", (data:any) => {
        if(this.evaluationTree.children?.length){
            // setTimeout(()=>{
              this.changeActiveMenu(data.folderId);
            // })
        }
        this.closeLoader();
    });
    this.subscribers.changeVisibilityMenuBroadcast = this.broadcastService.subscribe("changeVisibilityMenu", (data:any) => {
        this.evaluationMenu.changeHiddenFolder = false;
        this.changeHidden(this.evaluationTree.children, data.folder.folderId);
    });
    this.subscribers.exitConceptEvaluationMenuBroadcast = this.broadcastService.subscribe("exitConceptEvaluationMenu", (data:any) => {
      switch(data.configuration.action){
        case 'launchAction':
            this.checkLaunchAction(data.configuration.option, data.configuration.item);
            break;
        case 'manage-evaluation':
            this.configurationManageEvaluation();
            break;
        default:
            this.configureManageLockAction(data.configuration.action);
            break;
      }
    });
    this.subscribers.lockedOperationToEvaluationMenuBroadcast = this.broadcastService.subscribe("lockedOperationToEvaluationMenu", (data:any) => {
        this.getAlerts();
    });
  }

  getAlerts(){
    this.apiService.get('blanqueo/operationlockreviewalerts', this.evaluationInfo.evaluationId)
    .then((data:any)=>{
        if(data.length){
            data = data.sort(this.orderListDate);
            this.evaluationMenu.dateAlert = data[0];
            this.evaluationMenu.dateAlertsCount = data.length;
        }else{
          delete this.evaluationMenu.dateAlert;
          delete this.evaluationMenu.dateAlertsCount;
        }
    }, (errorData:any)=>{})
  }

  orderListDate(a: any, b: any) {
    if(a.creationDate < b.creationDate){
      return 1
    }
    return a.creationDate > b.creationDate ? -1 : 0;
  }

  configureFolderListMenu(){
    if(this.userPermissions === 'none'){
        return [];
    }else if(!this.evaluationInfo?.customizable){
        return this.expandMenu['confidentialOptionMenu'].menu;
    }else if(this.userPermissions !== 'all'){
        return this.expandMenu["folderMenuListNoPermissions"].menu;
    }
    return this.expandMenu['folderMenuList'].menu;
  }

  changeActiveMenu(folderId: any){
    this.resetChildrens(this.evaluationTree.children);
    this.folderId = folderId;
    this.configureMenu(this.evaluationTree.children, folderId, folderId);
    this.conceptState.reset();
    if(this.evaluationInfo && this.evaluationMenu.openTreeDesign){
        this.configureProductConsulta(this.evaluationTree.children);
    }
  }

  getRoles(){
    this.apiService.get('productroles/evaluation/' + this.evaluationInfo.evaluationId + '/assignments/notempty')
      .then((data:any)=>{
          this.evaluationMenu.rolesAssigneds = data;
          this.configureRoles();
      }, (errorData:any)=>{
      })
  }

  configureRoles(){
    let configureRolesLevel = this.rolesUtilsService.configureRolesLevel(this.evaluationMenu.rolesAssigneds);
    this.evaluationMenu.roleLevel2 = configureRolesLevel.roleLevel2;
    this.evaluationMenu.roleLevel3 = configureRolesLevel.roleLevel3;
    this.evaluationMenu.enabledButtonManageBlock = configureRolesLevel.enabledButtonManageBlock;
    this.evaluationMenu.rolesLevel = configureRolesLevel.rolesLevel;
    this.evaluationInfo.configureRolesLevel = configureRolesLevel;
    this.evaluationInfo.disabledCheckOperation = (!this.evaluationMenu.enabledButtonManageBlock && this.evaluationInfo.operationLocked)?true:this.evaluationInfo.disabledCheckOperation;
    this.evaluationMenu.chargedRoles = true;
  }

  closeLoader(){
    this.loader.closeLoader(['dashboard-panel-create-evaluation',
      'dashboard-panel-new-report',
      'evaluation-header-open-concept',
      'evaluation-modify-evaluation',
      'evaluation-menu-menu-action',
      'evaluation-change-name',
      'evaluation-deleteFolder',
      'front-login-login'])
  }

  resetChildrens(children:any){
    children.forEach((child: any) => {
      if(child){
            child.active = false;
            if(child.showHelpTooltip){
                delete child.showHelpTooltip;
            }

        if(child.level === 1){
            child.showFolders = false;
        }
        if(child.children){
            this.resetChildrens(child.children);
        }
      }
    });
  }

  configureMenu(children:any, folderSelected:any, lastFolderSelected:any){
    for(let [index, folder] of children.entries()){
        if(folder.folderId === folderSelected){
            if(!folder.parentId || !this.evaluationMenu.openTreeDesign || (this.evaluationMenu.openTreeDesign && folder.folderId === lastFolderSelected)){
                folder.active = true;
                let infoHelp:any = localStorage.getItem('hideHelpTooltip');
                let infoFolderHelp:any = localStorage.getItem('hideHelpTooltipToFolder-'+this.evaluationMenu.evaluationId+'-'+folder.iD_Version0);
                children[index].showHelpTooltip = !infoHelp && !infoFolderHelp && this.evaluationInfo.productId === 'blanqueo' && folder.folderId !== 'reports' && !this.rolesDesassignedResolve;

            }
            folder.openTree = true;
            if(folder.level === 1){
                folder.showFolders = true;
            }
            if(folder.parentId){
                this.configureMenu(this.evaluationTree.children, folder.parentId, lastFolderSelected);
            }
            break;
        }
        if(folder.children && folder.children.length){
            this.configureMenu(folder.children, folderSelected, lastFolderSelected);
        }
    }
  }

  configureProductConsulta(folder:any){
    folder.forEach((item:any) => {
      if(item.concepts && item.concepts.length){
        item.concepts[0].active = false;
      }
      if(item.children && item.children.length){
          this.configureProductConsulta(item.children)
      }
    });
  }

  changeHidden(children:any, folderId:any){
    for(let [index, child] of children.entries()){
      if(child.folderId === folderId){
          children[index].showFolders = true;
          this.evaluationMenu.changeHiddenFolder = true;
      }else{
          this.changeHidden(child.children, folderId)
      }
      if(this.evaluationMenu.changeHiddenFolder){
          break;
      }
    }
  }

  getReportList(){
    this.evaluationMenu.loadedCallReports = true;
    if(!this.evaluationMenu.preview){
      this.apiService.get('reportobjects/byevaluation', this.evaluationInfo.evaluationId)
      .then((data:any)=>{
          this.evaluationMenu.reportsTypeList = data;
          if ((this.skin === 'dark-blue' || this.skin === 'gm-law') && this.evaluationInfo.productId === 'denunc') {
            for(let item of data){
              if(!(item.predefined && item.reportId === this.reportVersion.folderReportVersion)){
                this.evaluationMenu.showReportsFolder = true;
                break;
              }
            }
          } else {
            this.evaluationMenu.showReportsFolder = Boolean(data.length > 0);
          }
      }, (errorData:any)=>{
          this.loader.closeError();
      })
    }

  }

  openFolderMenu(folder:any){
    folder.showMenu = !folder.showMenu;
    folder.menuList = folder.showMenu? this.expandedMenuService.menuFolder(this.evaluationMenu.folderListMenu, folder, this.evaluationInfo, this.folderId) : [];
  }

  openOptionsEvaluationMenu(evaluation:any){
    evaluation.showMenu = !evaluation.showMenu;
    evaluation.menuList = evaluation.showMenu? this.expandedMenuService.menuOptionsEvaluation(this.evaluationMenu.optionsEvaluationMenu, evaluation, this.evaluationMenu.preview) : [];
  }

  openEvaluationTreeMenu(evaluation:any){
    evaluation.showMenu = !evaluation.showMenu
    evaluation.menuList = evaluation.showMenu? this.expandedMenuService.menuSideEvaluation(this.evaluationMenu.folderListMenu, evaluation, this.evaluationInfo, this.userPermissions) : [];
  }

  openFolderInNewWindow(e: any, folder: any){
    e.stopPropagation();
    e.preventDefault();
    folder = folder.folderId === 'reports'?folder.folderId:'folder/'+folder.folderId;
    let path : any = 'evaluation/'+this.evaluationInfo.evaluationId+'/'+folder;
    window.open(path, '_blank');
  }

  openManageOptionBlock(open: any){
    if(open){
        this.rolesUtilsService.openManageOptionBlock(this.evaluationMenu.menuOperationLocked, this.evaluationInfo.rolesLevel, this.evaluationInfo.enabledButtonManageBlock, this.evaluationInfo.operationLocked);
    }
  }

  menuLaunchAction(option:string, item:any, type:any){
    this.closeDropdown(type);
    this.launchAction(option, item);
  }

  launchAction(option:string, item:any){
    let sameItem : any = (this.evaluationMenu.stateFolder || this.evaluationMenu.folderAutoinstanciable) && option === 'open' && this.folderSelect && this.folderSelect.folderId === item.folderId;
    if(!sameItem && !item.disabledFolder){
        if(this.conceptState.getAmount() || this.conceptState.getProcess()){
            if(this.route.snapshot.firstChild?.data['componentName'] === 'concept'){
                let text : any = this.conceptState.getConceptList().length > 1? 'concept.advertence-exit-all' :  'concept.advertence-exit';
                this.modalService.adviceModal(this.translate.instant(text), 'info:exit:question')
                  .subscribe((result:any) => {
                    if(result.result !== 'cancel'){
                      if(result.result === 'save-exit'){
                        this.broadcastService.broadcast('saveConceptToEvaluation', {from: 'evaluationMenu', action:'launchAction', option:option, item:item});
                      }else{
                        this.checkLaunchAction(option, item);
                      }
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(false);
                    }
                  });
            }else{
              this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:exit:question')
                .subscribe((result:any) => {
                  if(result.result === 'ok'){
                    this.conceptState.removeAll();
                    this.conceptState.setProcess(false)
                    this.checkLaunchAction(option, item);
                  }
                });
            }
        }else{
            this.checkLaunchAction(option, item);
        }
    }
  }

  checkLaunchAction(option:string, item:any){
    if(!item.active || option !== 'open' || (item.active && this.evaluatedConcept && this.evaluatedConcept.type)){
        $('#compliance-view-body').scrollTop(0);
        this.sendAction.emit({
          action:{
              type:option,
              folder:item
          }
        })

    }
    if((window.innerWidth < 1367 && this.evaluationMenu.openFloatMenu) || (window.innerWidth >= 1367 && !this.evaluationMenu.openFloatMenu) ){
        this.expandOrCloseMenu();
    }
  }

  launchActionTreeOn(e:any){
    this.launchAction('open', e.children);
  }

  showChildren(evaluation:any){
    if(evaluation.active){
        evaluation.showFolders = ! evaluation.showFolders;
    }
    if(!evaluation.active && evaluation.folderId !== this.folderId){
        this.launchAction('open', evaluation);
    }
  }

  modifyHidden(newValue:boolean){
    this.showHidden = newValue;
    this.sendAction.emit({
      action:{
        type: 'changeHidden',
        folder:{}
      }
    })
  }

  expandOrCloseMenu(){
    this.evaluationMenu.openFloatMenu = !this.evaluationMenu.openFloatMenu;
    this.minimizeMenu.emit();
  }

  openLinkUNE() {
    this.openLink('documentobjects/pdfviewerurl/external/une');
  }

  openPdf(e:any, productid:string){
    e.stopPropagation();
    this.evaluationMenu.disabledLink = true;
    switch(productid){
        case 'igualdad02':
            this.openLink('documentobjects/pdfviewerurl/external/lmia');
            break;
        case 'antifraude':
            this.openLink('documentobjects/pdfviewerurl/external/lmaf');
            break;
        default://'igualdad'
            this.openLink('documentobjects/pdfviewerurl/external/lmi');
            break;
    }
  }

  openLink(url:string) {
      this.apiService.get(url)
          .then((data:any)=> {
            data.error === "true"? this.modalService.adviceModal(data.errorMessage, 'error:accept:warning') : window.open(data.pdfUrl, "_blank");
            this.evaluationMenu.disabledLink = false;
          }, (errorData:any) => {
            this.evaluationMenu.disabledLink = false;
      });
  }

  filterMenuList(option:any) {
      return !(option.action === 'edit' && !this.evaluationInfo.hasProperties)
  }

  openTree(folder:any){
    if(this.evaluationMenu.openTreeDesign){
        folder.openTree = !folder.openTree;
        if(!folder.openTree && folder.children.length){
            this.closeChildren(folder.children);
        }
    }
  }

  closeChildren(children:any){
      for(let [index, child] of children.entries()){
          children[index].openTree = false;
          if(child.children && child.children.length){
              this.closeChildren(child.children);
          }
      }
  }

  manageEvaluation(){
    let conceptChanged : any = this.conceptState.getAmount() || this.conceptState.getProcess();
    if(conceptChanged){
      this.loader.openLoader('save-concept-to-manage-lock-evaluation');
      this.evaluationMenu.actionType = 'manage-evaluation';
      this.broadcastService.broadcast('saveConceptToEvaluation', {from: 'evaluationMenu', action:'manage-evaluation'});
    }else{
        this.configurationManageEvaluation();
    }
  }


  configurationManageEvaluation(){
    if(!this.evaluationInfo.disabledManageEvaluation){
        let configurationModal:any = {
          evaluationInfo: this.evaluationInfo,
          from: 'innerEvaluation'
        }

      this.modalService.finishBlockModal(configurationModal).subscribe((result:any)=>{
        if(result.result === 'ok'){
            result.configuration.withoutSaving? this.modalService.adviceModal(this.translate.instant('evaluation-menu.managed-evaluation-ok-no-saved'), 'info:accept:check-round'): this.executeManageEvaluation(result.configuration);
        }

      })
    }
  }

  executeManageEvaluation(configuration: any){
    let itemSelected : any = {
      type: 'manage-evaluation',
      item: this.evaluationInfo,
      evaluationList: this.evaluationTree.children,
      configuration: configuration
    }
    this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
      if(result.result === 'ok'){
        if(configuration.selectedOption.lockedData && !this.evaluationInfo.isFinished){
            this.evaluationInfo.isFinished = configuration.selectedOption.lockedData;
            this.evaluationInfo.lockedData = configuration.selectedOption.lockedData;
            this.broadcastService.broadcast('changeLockedEvaluation', {evaluationInfo: this.evaluationInfo});

        }
        if(configuration.selectedOption.generateReport){
          let configurationReport : any = {
              reportId: result.item.reportId,
              newReport: result.item.generateReport
          }
          this.broadcastService.broadcast('changeReportListEvaluation', {report: configurationReport});
        }
        this.modalService.adviceModal(this.translate.instant('evaluation-menu.managed-evaluation-ok'), 'info:accept:check-round')

      }else{
        this.modalService.adviceModal('error', 'error:accept:warning')
      }
    })
  }

  closeHelpTooltip(evaluation:any){
    evaluation.showHelpTooltip = !evaluation.showHelpTooltip;
  }

  openAlertList(){
    this.loader.openLoader('open-alert-list');
    this.stateManagement.setStateUrl(window.location.pathname);
    this.router.navigate(['alert-list', this.evaluationInfo.evaluationId]);
  }

  configureManageLock(action: any){
    if(action !== 'lock-operation'){
        this.closeDropdown('manage-lock-dropdown');
    }
    let conceptChanged = this.conceptState.getAmount() || this.conceptState.getProcess();
    if(conceptChanged){
        this.loader.openLoader('save-concept-to-manage-lock-evaluation');
        this.evaluationMenu.actionType = action;
        this.broadcastService.broadcast('saveConceptToEvaluation', {from: 'evaluationMenu', action: action});
    }else{
        this.configureManageLockAction(action);
    }
  }

  configureManageLockAction(action:any){
    let evaluationInfo:any = this.evaluationInfo;
    let enabledLockOperation = !this.evaluationInfo.disabledCheckOperation && !evaluationInfo.isFinished && !evaluationInfo.operationNotSupported;
    switch(action){
        case 'lock-operation':// Bloquear y revisar operación
            if(enabledLockOperation && !evaluationInfo.operationLocked){
                let text = this.translate.instant('evaluation-menu.question-lock-operation').replace('{role}',  this.evaluationMenu.roleLevel2.name);
                this.modalService.adviceModal(text, 'info:question:question')
                .subscribe((result:any)=>{
                  if(result.result ==='ok'){
                    this.lockUnlockOperation('lockoperation');
                  }
                })

            }
            break;
        case 'unlock':
            if(enabledLockOperation && evaluationInfo.operationLocked){
                this.modalService.adviceModal(this.translate.instant('evaluation-menu.question-unlock-operation'), 'info:question:question')
                .subscribe((result:any)=>{
                  if(result.result ==='ok'){
                    this.lockUnlockOperation('unlockoperation');
                  }
                })
            }
            break;
        case 'reassign':
            if(enabledLockOperation && evaluationInfo.operationLocked){
                let text = this.translate.instant('evaluation-menu.question-reassign-operation').replace('{role}',  this.evaluationMenu.roleLevel3.name);
                this.modalService.adviceModal(text, 'info:question:question')
                  .subscribe((result:any)=>{
                    if(result.result ==='ok'){
                      this.reassignManage();
                    }
                  })
            }
            break;
        case 'permanently-block':
            if(enabledLockOperation && evaluationInfo.operationLocked){
                this.modalService.adviceModal(this.translate.instant('evaluation-menu.question-permanently-block-operation'), 'info:question:question')
                .subscribe((result:any)=>{
                  if(result.result ==='ok'){
                    this.lockUnlockOperation('notsupportedoperation');
                  }
                })

            }
            break;
    }
  }

  reassignManage(){
      this.loader.openLoader('reassign-evaluation');
      this.apiService.add('blanqueo/reassignoperationreview/' + this.evaluationInfo.evaluationId)
      .then((data:any)=>{
          this.getAlerts();
          this.loader.closeLoader(['reassign-evaluation']);
          this.modalService.adviceModal(this.translate.instant('evaluation-menu.reassign-ok'), 'info:accept:check-round');
      }, (errorDate:any)=>{
          this.loader.closeError();
      })
  }

  lockUnlockOperation(typeOperation:any){
      let evaluationInfo: any = this.evaluationInfo;
      this.loader.openLoader('lock-operation-to-evaluation');

      this.apiService.patch(evaluationInfo.productId+'/'+typeOperation, evaluationInfo.evaluationId)
          .then((data:any)=>{
              this.getAlerts();
              evaluationInfo.operationLocked = typeOperation==='lockoperation'?true:false;
              evaluationInfo.disabledManageEvaluation = typeOperation==='lockoperation'?true:false;
              evaluationInfo.operationNotSupported  = typeOperation==='notsupportedoperation'?true:false;
              evaluationInfo.disabledCheckOperation = (!this.evaluationMenu.enabledButtonManageBlock && this.evaluationInfo.operationLocked)?true:this.evaluationInfo.disabledCheckOperation;
              let text: string = '';
              if(typeOperation==='lockoperation'){
                  this.loader.closeLoader(['lock-operation-to-evaluation']);
                  text = this.translate.instant('evaluation-menu.info-locked-operation').replace('{role}',  this.evaluationMenu.roleLevel2.name);
                  this.modalService.adviceModal(text, 'error:accept:alert');
              }else if(typeOperation==='notsupportedoperation'){
                  evaluationInfo.isFinished = true;
                  evaluationInfo.lockedData = true;
                  this.broadcastService.broadcast('changeLockedEvaluation', {evaluationInfo: this.evaluationInfo});
                  this.addCall('blanqueo/lockdata/'+this.evaluationInfo.evaluationId, this.evaluationInfo, 'lockData');
                  this.getManageReport();
              }else{
                  this.loader.closeLoader(['lock-operation-to-evaluation']);
                  this.modalService.adviceModal(this.translate.instant('evaluation-menu.info-unlocked-operation'), 'info:accept:check-round');
              }
          }, (errorDate:any)=>{
              this.loader.closeError();
          })
  }

  getManageReport(){
    this.apiService.get('blanqueo/auditreportid')
      .then((data:any)=>{
          if(data > 0){
            this.getReportObjectList(data);
          }else{
            this.evaluationMenu.callsManageEvaluation++;
            this.finishedCallsManage();
          }
      }, (errorData:any)=>{})
  }

  getReportObjectList(reportId:any){
    this.apiService.get('reportobjects/byreportid/'+reportId, this.evaluationInfo.evaluationId)
      .then((data:any)=>{
          let report : any = {
            title: data.reports.length?this.getNameReport(data.reports):this.evaluationInfo.name,
            reportId: reportId
          }
          this.addCall('reportobjects/' + reportId + '/' + this.evaluationInfo.evaluationId, report, 'generateReport');
      }, (errorData:any)=>{})
  }

  getNameReport(list: any){
    let name : any = this.evaluationInfo.name;
    let nameOrigin : any = this.evaluationInfo.name;
    let nameIdx : any = 0;
    let existingReport:any;
    do {
        if (nameIdx) {
            name = nameOrigin+' (' + nameIdx + ')';
        }
        existingReport = list.find((item:any)=> item.title.toLowerCase() === name.toLowerCase());
        nameIdx++;
    } while (existingReport);

    return name;
  }

  addCall(url:string, data:any, type: string = ''){
    this.apiService.add(url, data)
      .then((data:any)=>{
            if(type === 'generateReport'){
              this.evaluationMenu.configurationReport = {
                  reportId: data.reportId,
                  newReport: true
              }
            }
            this.evaluationMenu.callsManageEvaluation++;
            this.finishedCallsManage();
      },(errorData:any)=>{
        this.loader.closeError();
      })
  }

  finishedCallsManage(){
    if(this.evaluationMenu.callsManageEvaluation === 2){
      this.broadcastService.broadcast('changeReportListEvaluation', {report: this.evaluationMenu.configurationReport});
      delete this.evaluationMenu.configurationReport;
      this.evaluationMenu.callsManageEvaluation = 0;
      this.loader.closeLoader(['lock-operation-to-evaluation']);
      this.modalService.adviceModal(this.translate.instant('evaluation-menu.permanently-block-ok'), 'error:accept:alert')
        .subscribe((result:any) => {
          if(result.result === 'cancel'){
              this.configurationManageEvaluation();
          }
        });
    }
  }

  showTooltipHeight(element: any, id: any){
    return !(this.commonUtilsService.showTooltipHeight(element, id));
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
