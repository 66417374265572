<div class="up-document">
  <div class="common__modal-header-block">
      <i class="lf-icon-information common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'common.info-concept'">Información sobre el concepto</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="concept-info__content">
      <div class="concept-info__content-body concept-info__content-body--block"
          [innerHTML]="conceptInfo.description">
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="submit"
          class="common__button common__button--active"
          (click)="handleAccept()"
          [afTranslate]="'common.ok'">Aceptar</button>
  </div>
</div>
