import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';


@Injectable({
  providedIn: 'root'
})
export class FieldTypeContactsParentService {
  subscribers: any = {};
  constructor(private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private context: ContextFactory) { }

   configureField(child:any){
    let fieldTypeContacts: any = {
      canCreateContacts: this.configureUserPermisionsCreate(child),
      byWord: ['fullName'],
      hiddenButtonsHeader: false,
      markAll: false,
      inputText: '',
      openLaw: false,
      openUserList: false,
      option: '',
      references: false,
      searchContact: '',
      selectAssists: undefined,
      showInfoSMS: false,
      showSigned: false,
      showTitleContact: false,
      state: 'list',
      titleField: '',
      all: 0,
      pending: 0,
      confirm: 0,
      cancel: 0,
      selectedSign: {},
      viewItems: {},
      contactsList: [],
      contactListSelected: [],
      groupsList: [],
      listContact: [],
      participantList: [],
      // selectContacts: [],
      selectContactsCopy: [],
      stateContacts: [],
      usersAndContactsFilList: [],
      productRolesFilList: [],
      groupsFilList: [],
      usersList: [],
      productRolesList: [],
      evaluationTaskShares: [],
      originalButtonsList: [
          {
              type: 'productRole',
              icon: 'lf-icon-add-points',
              tooltip: 'common.roles',
              optionAction: 4,
              show: false
          },{
              type: 'group',
              icon: 'lf-icon-group',
              tooltip: 'users-admin.main.group',
              optionAction: 1,
              show: false
          },{
              type: 'user',
              icon: 'lf-icon-favorite',
              tooltip: 'users-admin.main.user',
              optionAction: 2,
              show: false
          },{
              type: 'contact',
              icon: 'lf-icon-user',
              tooltip: 'users-admin.main.contact',
              optionAction: 3,
              show: false
          }
      ]
    }
    return fieldTypeContacts;
  }

  configureUserPermisionsCreate(child: any){
    let userPermisions = child.userPermisions.getTypePermissions();
    if(userPermisions === 'admin'){
      return true;
    }
    return false;
  }

  configureChanges(changes: any, child:any){
    if(changes['valueId'] && changes['valueId'].previousValue && changes['valueId'].previousValue !== changes['valueId'].currentValue){
        this.resetCalls(child);
        child.fieldTypeContacts.originalValueId = changes['valueId'].currentValue;
    }
    if(child.preSelectItem && child.preSelectItem !== null){
        child.fieldTypeContacts.inputText = child.preSelectItem;
    }
    if(child.field.inReport !== null && child.field.inReport !== undefined){
      child.fieldTypeContacts.inReport = child.field.inReport.indexOf('|') === -1? [child.field.inReport] : child.field.inReport.split('|')
    }
    this.configureDisabledList(child);
    child.fieldTypeContacts.option =  child.field.optionType;
  }

  configureInit(child:any){
    this.launchWatchers(child);
    child.fieldTypeContacts.viewItems = {
      label: this.translate.instant('contacts.all'),
      id: 0
    }
    child.fieldTypeContacts.stateContacts = [
      {
          name: this.translate.instant('contacts.state-all'),
          id: 'all'
      },{
          name: this.translate.instant('contacts.state-confirm'),
          id: 'confirm'
      },{
          name: this.translate.instant('contacts.state-cancel'),
          id: 'cancel'
      },{
          name: this.translate.instant('contacts.state-pending'),
          id: 'pending'
      }
    ];
    if(child.field.selectContacts && (child.field.typeConfiguration === 'sign' || child.field.typeConfiguration === 'meeting')) {
      this.configureAssists(child);
    }
    this.configureSelectContacts(child);
    this.configureHeaderField(child);
    if(child.field.callEvaluationTaskShares){
      this.configureEvaluationTaskShares(child);
    }else{
      this.configureCalls(child);
    }
  }

  launchWatchers(child: any){
    this.subscribers.resetFieldContactBroadcast = this.broadcastService.subscribe('resetFieldContact', (data:any) => {
        child.fieldTypeContacts.contactListSelected = [];
        this.checkGroupList(child);
        child.sendContacts.emit({contacts:child.fieldTypeContacts.contactListSelected});
    });
    this.subscribers.resetFieldRoleBroadcast = this.broadcastService.subscribe('resetFieldRole', (data:any) => {
        if(data.field.fieldId === child.field.fieldId){
            this.resetLists(child);
        }
    });
  }

  configureDisabledList(child: any){
    if(child.listDisabled?.length && child.fieldTypeContacts.usersList?.length){
        this.setDisabledContacts(child.fieldTypeContacts.usersList, child);
    }
    if(child.listDisabled?.length && child.fieldTypeContacts.productRolesList?.length){
        this.setDisabledContacts(child.fieldTypeContacts.productRolesList, child);
    }
  }

  configureSelectContacts(child: any){
      if(child.field.selectContacts && child.field.selectContacts.length){
          child.fieldTypeContacts.selectContactsCopy = cloneDeep(child.field.selectContacts);
      }else{
          child.fieldTypeContacts.selectContactsCopy =[];
      }
  }

  configureHeaderField(child:any){
      if(child.field){
          child.fieldTypeContacts.titleField = child.field.label?child.field.label:this.translate.instant('common.users-contacts');
          if(child.field.extendedLabel && child.field.extendedLabel !== ''){
            child.fieldTypeContacts.titleField = child.field.extendedLabel && child.field.extendedLabel !== ''? child.field.extendedLabel : child.field.label;
          }
          child.field.noEditable = child.field.noEditable?child.field.noEditable:false;

      }
      child.fieldTypeContacts.hiddenButtonsHeader = false;
      if(child.field.typeCall === 'contacts-only' || child.field.typeConfiguration === 'roles' || child.field.typeConfiguration === 'user-role'){
          child.fieldTypeContacts.hiddenButtonsHeader = true;
      }
      if(child.field.typeConfiguration === 'send-sms-certificate'){
          child.fieldTypeContacts.showInfoSMS = true;
      }
  }

  resetCalls(child: any){
      child.fieldTypeContacts.groupsList = [];
      child.fieldTypeContacts.productRolesList = [];
      child.fieldTypeContacts.contactsList = [];
      child.fieldTypeContacts.usersList = [];
      child.fieldTypeContacts.contactListSelected = [];
      child.field.selectContacts = [];
      child.fieldTypeContacts.listContact = [];
      child.sendContacts.emit({contacts:child.fieldTypeContacts.contactListSelected});
      this.configureCalls(child);
  }

  resetLists(child: any){
      child.fieldTypeContacts.contactListSelected = child.field.selectContacts;
      child.fieldTypeContacts.listContact = [];
      child.fieldTypeContacts.usersAndContactsFilList = [];
      let typesList : any = ['usersList', 'contactsList', 'groupsList', 'productRolesList'];
      for(let i = 0; i < typesList.length; i++){
          let type : any = typesList[i];
          for(let j = 0; j < child.fieldTypeContacts[type].length; j++){
              child.fieldTypeContacts[type][j].active = false;
          }
      }
      this.checkSelectContact(child);
  }

  configureCalls(child:any){
    if(child.field.selectContacts && (child.field.typeConfiguration === 'sign' || child.field.typeConfiguration === 'meeting')){
        this.configureAssists(child);
    }
    if(!child.valueId && child.concept && child.concept.productId === 'blanqueo' && child.concept.conceptNamedKey === 'Cliente'){
      child.fieldTypeContacts.option = 'Client';
      child.valueId = child.concept.clientId;
    }else if(!child.valueId && child.concept && child.concept.evaluationId) {
        child.valueId = child.concept.evaluationId;
    }else if(!child.valueId && child.route.snapshot.params['evaluationId']) {
        child.valueId = child.route.snapshot.params['evaluationId'];
    }else if(!child.valueId && child.route.snapshot.firstChild?.params['evaluationId']) {
        child.valueId = child.route.snapshot.firstChild?.params['evaluationId'];
    }
    child.fieldTypeContacts.originalValueId = child.valueId;
    if (this.context.user.companyId || (this.context.user.email && (this.context.user.userId || this.context.user.contactId))) {
        if (child.field && child.field.typeCall.indexOf('contacts') !==-1) {
            this.getContacts(child);
        } else if (child.field && child.field.typeCall === 'users-only') {
            this.getUsers(child);
        } else if (child.field && child.field.typeCall === 'users') {
            this.getRolesUsers(child);
        } else if (child.field && child.field.typeCall === 'groups') {
            this.getGroups(child);
        }
    }
  }

  configureEvaluationTaskShares(child: any){
    this.apiService.get('conceptobjects/'+child.concept.conceptObjectId+'/evaluation-tasks-shares')
      .then((data: any)=> {
        child.fieldTypeContacts.evaluationTaskShares = data;
        if(child.field.typeConfiguration === 'shareFieldContainer' && child.field.parentId){
          this.configureContainerShare(child);
        }else{
          this.configureCalls(child);
        }
      }, (errorData:any)=>{});
  }

  configureContainerShare(child: any){
    this.apiService.get('fieldcontainers/' + child.field.parentId + '/conceptobject/' + child.concept.conceptObjectId + '/shares')
      .then((data: any)=> {
        child.fieldTypeContacts.evaluationTaskShares = child.fieldTypeContacts.evaluationTaskShares.concat(data);
        this.configureCalls(child);
      }, (errorData:any)=>{});
  }

  getGroups(child:any){
    let url = 'usersgroups';
    if(child.valueId){
        if(child.concept && child.concept.productId === 'blanqueo' && child.concept.conceptNamedKey === 'Cliente'){
            url = 'usersgroups/byproductclientId/' + child.concept.productId + '/' + child.valueId;
        }else if(child.fieldTypeContacts.option === 'Client') {
                url = 'usersgroups/byclientid/' + child.valueId;
        }else{
            url = 'usersgroups/byevaluationid/' + child.valueId;
        }
    }else if(child.concept && child.concept.productId === 'blanqueo' && child.concept.conceptNamedKey === 'SujetoObligado'){
        url = 'usersgroups/byproductclientId/' + child.concept.productId;
    }
    let that = this;
    this.apiService.get(url)
      .then((data: any)=> {
        data.map(function (obj: any) {
            obj.type = 'G';
            obj.uniqueValueId = 'G-'+obj.groupId;
            if(child.fieldTypeContacts.evaluationTaskShares?.length){
              that.configureItemDisabled(obj, 'groupId', child);
            }
            return obj;
        });

        child.fieldTypeContacts.groupsList = data;
        child.fieldTypeContacts.originalButtonsList[1].show = data.length?true:false;
        this.getContacts(child);
      }, (errorData:any)=>{});
  }

  configureItemDisabled(item: any, typeId: any, child: any){
    child.fieldTypeContacts.evaluationTaskShares.forEach((element: any) => {
        if(element[typeId] === item[typeId]){
          item.disabled = true;
        }
    });
  }

  getContacts(child:any){
    let url = 'contacts/access/read';
    let conceptSujetoObligado = child.field.typeConfiguration === 'field-user' && child.concept && child.concept.productId === 'blanqueo' && child.concept.conceptNamedKey === 'SujetoObligado';
    if (child.valueId && !conceptSujetoObligado) {
        if(child.fieldTypeContacts.option === 'Client' || (child.concept && child.concept.conceptNamedKey === 'Cliente')) {
            url = 'contacts/byclientid/' + child.valueId;
        }else{
            url = 'contacts/byevaluationid/' + child.valueId;
        }
    }
    let that = this;
    this.apiService.get(url, 'include-deleted')
      .then((data: any)=> {
        data.map(function (obj: any) {
            obj.type = 'C';
            obj.uniqueValueId = 'C-'+obj.contactId;
            if(child.fieldTypeContacts.evaluationTaskShares?.length){
              that.configureItemDisabled(obj, 'contactId', child);
            }
            return obj;
        });
        if(child.field.typeConfiguration === 'send-sms-certificate'){
          child.fieldTypeContacts.contactsList = this.commonUtilsService.getMobilePhoneList(data, 'phoneNumber1', 'phoneNumber2');
        }else{
          child.fieldTypeContacts.contactsList = this.onlyNotDeleted(data);
        }
        child.fieldTypeContacts.originalButtonsList[3].show = data.length?true:false;
        if(child.field.typeCall === 'contacts-only'){
          child.fieldTypeContacts.usersList = [];
          child.fieldTypeContacts.listContact = [...child.fieldTypeContacts.listContact, ...child.fieldTypeContacts.contactsList];
          child.fieldTypeContacts.usersAndContactsFilList = child.fieldTypeContacts.listContact;
          child.fieldTypeContacts.showTitleContact = true;
          this.checkSelectContact(child);
          this.showOnly(child, 3);
        }else if(child.isLanding || child.field.typeCall === 'contacts-users-only'){
          this.getUsers(child);
        }else{
          this.getRolesUsers(child);
        }

      }, (errorData:any)=>{
      });
  }

  onlyNotDeleted(data: any){
    return data.filter((item:any) => { return !item.isDeleted });
  }

  getRolesUsers(child: any) {
      let productId : any = child.fieldTypeContacts.concept && child.fieldTypeContacts.concept.productId?child.fieldTypeContacts.concept.productId:this.context.settings.defaultProduct;
      let url : any = 'productroles/product/' + productId + '/assignments/notempty';
      if (child.valueId) {
          if(child.field.optionType === 'Client' || (child.concept && child.concept.conceptNamedKey === 'Cliente')) {
              url = 'productroles/product/' + productId + '/client/' + child.valueId + '/assignments/notempty';
          }else{
              url = 'productroles/evaluation/' + child.valueId + '/assignments/notempty';
          }
      }
      let that = this;
      this.apiService.get(url)
        .then((data: any)=> {
          data.map(function (obj: any) {
            obj.type = 'R';
            obj.uniqueValueId = 'R-'+obj.productRoleId;
            obj.fullName = obj.name;
            obj.usersList = '';
            if(child.fieldTypeContacts.evaluationTaskShares?.length){
              that.configureItemDisabled(obj, 'productRoleId', child);
            }
            let usersList : any =  obj.users.concat(obj.clientsUsers).sort(child.orderListName);
            for(let i=0; i < usersList.length; i ++){
                let user : any = usersList[i];
                obj.usersList+= i===usersList.length-1?user.fullName:user.fullName+', ';
            }
            return obj;
          });
          child.fieldTypeContacts.productRolesList = data;
          child.fieldTypeContacts.originalButtonsList[0].show = data.length?true:false;
          this.getUsers(child);
        }, (errorData:any)=>{
        });
  }

  orderListName(a:any, b:any) {
    return a.numOrder < b.numOrder ? 1 : a.numOrder > b.numOrder ? -1 : 0;
  }

  getUsers(child:any){
    let promise : any;
    let conceptSujetoObligado : any = child.field.typeConfiguration === 'field-user' && child.concept && child.concept.productId === 'blanqueo' && child.concept.conceptNamedKey === 'SujetoObligado';
    if(conceptSujetoObligado){
        promise = this.apiService.get('frontusers/only-global-product/'+child.concept.productId, 'include-deleted');
    }else if (child.valueId) {
        if(child.field.typeConfiguration === 'user-role' || child.field.typeConfiguration === 'roles' || child.field.typeCallId === 'productId'){
            promise = this.apiService.get('frontusers/only-product', child.valueId);
        }else if(child.fieldTypeContacts.option === 'Client' || (child.concept && child.concept.conceptNamedKey === 'Cliente')) {
            promise = this.apiService.get('frontusers/byclientid/' + child.valueId, 'include-deleted');
        }else{
            // url = 'frontusers/byevaluationid/' + child.valueId;//'include-deleted'
            promise = this.apiService.get('frontusers/byevaluationid/' + child.valueId, 'include-deleted');
        }
    }else{
      promise = this.apiService.get('frontusers', 'include-deleted');
    }
    let that = this;
    promise.then((data: any)=> {
        data.map(function (obj: any) {
            obj.type = 'U';
            obj.uniqueValueId = 'U-'+obj.userId;
            if(child.fieldTypeContacts.evaluationTaskShares?.length){
              that.configureItemDisabled(obj, 'userId', child);
            }
            return obj;
        });
        if(child.field.typeConfiguration === 'confident'){
          for (let i= 0; i<data.length;i++){
              if(data[i].userId === this.context.user.userId){
                  data.splice(i,1);
              }
          }
        }else if(child.field.typeConfiguration === 'user-role' && child.field.usersWithRoles){
          this.filterUserRolesExists(data, child);
        }
        child.fieldTypeContacts.originalButtonsList[2].show = data.length?true:false;
        if(child.field.typeConfiguration === 'send-sms-certificate'){
          child.fieldTypeContacts.usersList = this.commonUtilsService.getMobilePhoneList(data, 'phoneNumber1', 'phoneNumber2');
        }else{
          child.fieldTypeContacts.usersList = this.onlyNotDeleted(data);
        }
        this.checkSelectContact(child);
        child.fieldTypeContacts.buttonsShow = this.configureButtonsShow(child);
        if (child.fieldTypeContacts.usersList.length && !child.fieldTypeContacts.contactsList.length && !child.fieldTypeContacts.productRolesList.length && !child.fieldTypeContacts.groupsList.length){
            this.showOnly(child, 2);
        }
        if(child.selectContextUser){
          this.findContextUser(child)
        }
      }, (errorData:any)=>{
      });
  }

  findContextUser(child:any){
    let list: any = child.fieldTypeContacts.usersList;
    for(var i = 0; i < list.length; i++){
      if(list[i].userId === this.context.user.userId){
        this.addOrDeleteMultipleItem(list[i], 'user', child);
        break;
      }
    }
  }

  filterUserRolesExists(usersList: any, child: any){
      let userIdSelected : any = child.field.selectContacts && child.field.selectContacts[0].userId?child.field.selectContacts[0].userId:undefined;
      for(let i = 0; i < child.field.usersWithRoles.length; i++){
          let userWithRole : any = child.field.usersWithRoles[i];
          for(let j = usersList.length-1; j >= 0; j--){
              let user : any = usersList[j];
              if(userWithRole.userId === user.userId && user.userId !== userIdSelected){
                  usersList.splice(j,1);
              }
          }
      }
  }

  configureButtonsShow(child: any){
      let buttonsShow : any = [];
      for(let i = 0; i < child.fieldTypeContacts.originalButtonsList.length; i++){
          let button : any = child.fieldTypeContacts.originalButtonsList[i];
          if(button.show){
              buttonsShow.push(button);
          }
      }
      return buttonsShow;
  }

  checkSelectContact(child:any) {
    if(child.field.selectContacts){
      child.fieldTypeContacts.contactListSelected = child.field.selectContacts;
    }else if(child.field.typeConfiguration === "field-user" && child.field.evaluatedValue && child.field.evaluatedValue.nvarcharValue){
        this.checkSelectFieldContact(child);
    }
    child.fieldTypeContacts.contactListSelected.forEach((participantSelected: any)=>{
        let type : any ='';
        if(participantSelected.type === 'U' || child.field.typeConfiguration === 'roles' || child.field.typeConfiguration === 'user-role'){
            type='user';
        }else if(participantSelected.type === 'C'){
            type='contact';
        }else if(participantSelected.type === 'G'){
            type='group';
        }else{
            type='productRole';
        }
        if(!participantSelected[type+'Id'] && participantSelected.type && participantSelected.id){
            participantSelected[type+'Id'] = participantSelected.id;
        }else if(participantSelected.taskParticipantId && !participantSelected[type+'Id']){
            participantSelected[type+'Id'] = participantSelected.taskParticipantId;
        }
        if(child.fieldTypeContacts[type+'sList']){
            child.fieldTypeContacts[type+'sList'].forEach((participantByTypeList: any)=>{
              if(participantSelected[type+'Id'] === participantByTypeList[type+'Id'] || participantSelected.id === participantByTypeList[type+'Id']){
                  participantByTypeList.active = true;
                  participantSelected.fullName = participantByTypeList.fullName;
                  participantSelected.listValueId = participantByTypeList[type+'Id'];
                  participantSelected.typeName = type;
                  participantSelected.isDeleted = participantByTypeList.isDeleted;
                  if(child.field.typeConfiguration === "field-user" || child.field.typeConfiguration === "roles"){
                      participantSelected.email = participantByTypeList.email;
                      participantSelected.phoneNumber1 = participantByTypeList.phoneNumber1;
                      if(child.field.typeConfiguration === "field-user"){
                          this.configureDisplayDataList(participantSelected, child);
                      }
                  }
                  participantSelected.active = true;
              }
            })
            child.fieldTypeContacts.selectContactsCopy = cloneDeep(child.fieldTypeContacts.contactListSelected);
        }else{
            child.fieldTypeContacts.contactListSelected= [];
        }
    })
    this.configureDisabledList(child);
    child.fieldTypeContacts.listContact = child.fieldTypeContacts.listContact.concat(child.fieldTypeContacts.contactsList);
    child.fieldTypeContacts.listContact = child.fieldTypeContacts.listContact.concat(child.fieldTypeContacts.usersList);
    child.fieldTypeContacts.usersAndContactsFilList = child.fieldTypeContacts.listContact;
  }

  checkSelectFieldContact(child:any){
    if (child.field.evaluatedValue.nvarcharValue.indexOf('|') !==-1) {
      child.fieldTypeContacts.selectedUsersFields = child.field.evaluatedValue.nvarcharValue.split('|');
      child.fieldTypeContacts.selectedUsersFields.forEach((selectedUser: any)=>{
        this.configurateUserFieldSelected(selectedUser, child);
      })
    } else{
      this.configurateUserFieldSelected(child.field.evaluatedValue.nvarcharValue, child);
    }
  }

  configurateUserFieldSelected(participantSelected: any, child: any){
      let typeOption : any = participantSelected.split('-');
      let typeId : any = typeOption[1]==='role'?'productRole':typeOption[1];
      let contactListSelected : any = {
          type: typeOption[1].substr(0,1).toUpperCase(),
          [typeId+'Id']: parseInt(typeOption[0])
      }
      child.fieldTypeContacts.contactListSelected.push(contactListSelected);
  }

  showOnly(child: any, type: any, e: any = undefined){
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      if(type === child.fieldTypeContacts.viewItems.id){
          child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.all');
          child.fieldTypeContacts.viewItems.id = 0;
      }else{
          child.fieldTypeContacts.viewItems.id = type;
          switch(type){
              case 1:
                  child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.groups');
                  break;
              case 2:
                  child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.users');
                  break;
              case 3:
                  child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.contacts');
                  break;
              case 4:
                  child.fieldTypeContacts.viewItems.label = this.translate.instant('common.roles');
                  break;
              default:
                  child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.all');
                  break;
          }
      }
      child.fieldTypeContacts.state = 'list';
  }

  openContactList(child:any){
    child.fieldTypeContacts.showSigned = false;
    if(!child.field.noEditable){
      child.fieldTypeContacts.selectContactsCopy = cloneDeep(child.fieldTypeContacts.contactListSelected);
      child.fieldTypeContacts.openUserList = !child.fieldTypeContacts.openUserList;
      child.fieldTypeContacts.state = 'list';
      child.contactsClosed.emit({type:'open'});
    }
    if(child.field.typeConfiguration === 'send-sms-certificate'){
      child.fieldTypeContacts.showInfoSMS = true;
    }
  }

  showReference(e:any, child:any){
    e.stopPropagation();
    child.fieldTypeContacts.references = !child.fieldTypeContacts.references;
    child.showFieldReference.emit();
  }

  openModalRelatedInfo(e:any, fieldRelatedInfo:any, child:any){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    child.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  resetSearch(e: any, child: any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeContacts.searchContact='';
  }

  changeState(e: any, state: any, child: any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeContacts.state = state;
    if(child.field.typeCall === 'contacts-only'){
        child.fieldTypeContacts.viewItems = {
            label: this.translate.instant('contacts.contacts'),
            id: 3
        }
    }else if(child.field.typeConfiguration  === 'roles' || child.field.typeConfiguration === 'user-role'){
        child.fieldTypeContacts.viewItems = {
            label: this.translate.instant('contacts.users'),
            id: 2
        }
    }else if(child.isLanding && child.fieldTypeContacts.state === 'selection'){
        child.fieldTypeContacts.viewItems.label = this.translate.instant('contacts.selected');
    }else{
        child.fieldTypeContacts.viewItems = {
            label: this.translate.instant('contacts.all'),
            id: 0
        }
    }
  }

  changeView(child: any){
      child.fieldTypeContacts.state = child.fieldTypeContacts.state==='list'?'addNew':'list';
      child.fieldTypeContacts.viewItems = {
          label: this.translate.instant('contacts.all'),
          id: 0
      }
  }

  addContact(item: any, child: any) {
      child.fieldTypeContacts.listContact.push(item);
      child.fieldTypeContacts.usersAndContactsFilList.push(item);
      child.fieldTypeContacts.contactsList.push(item);
      child.fieldTypeContacts.originalButtonsList[3].show = child.fieldTypeContacts.contactsList?.length?true:false;
      child.fieldTypeContacts.buttonsShow = this.configureButtonsShow(child);
  }

  resetContacts(child: any) {
    child.fieldTypeContacts.contactListSelected = child.fieldTypeContacts.selectContactsCopy;
    child.fieldTypeContacts.markAll = child.fieldTypeContacts.markAllCopy;
    this.checkGroupList(child);
    child.sendContacts.emit({contacts:child.fieldTypeContacts.contactListSelected});
    child.fieldTypeContacts.openUserList = !child.fieldTypeContacts.openUserList;
    child.contactsClosed.emit({type:'resetContacts'});
  }

  deleteContact(e: any, index: any, child: any) {
    this.confirmDeleteContact(e, index, child);
  }

  confirmDeleteContact(e: any, index: any, child: any){
    if(!child.field.noEditable){
        e.stopPropagation();
        e.preventDefault();
        child.fieldTypeContacts.contactListSelected[index].active = false;
        child.fieldTypeContacts.contactListSelected.splice(index, 1);
        if(child.field.typeConfiguration === "field-user"){
            this.configureListUsersSelected(child);
        }else{
            child.sendContacts.emit({contacts:child.fieldTypeContacts.contactListSelected});
        }
        if(child.field.selectContacts && (child.field.typeConfiguration === 'sign' || child.field.typeConfiguration === 'meeting')){
            this.configureAssists(child);
        }else if(child.field.typeConfiguration === "field-user"){
            if(child.field.fieldType === 'multiple'){
                child.field.evaluatedValue.displayData.splice(index, 1);
            }else{
                child.field.evaluatedValue.displayData = [];
            }
        }
        this.checkGroupList(child);
    }
  }

  configureListUsersSelected(child: any){
    let nvarcharValue : any ='';
    for(let i = 0; i < child.fieldTypeContacts.contactListSelected.length; i++){
        let contactSelected : any = child.fieldTypeContacts.contactListSelected[i];
        if(child.fieldTypeContacts.contactListSelected.length-1 === i){
            if(contactSelected.listValueId){
                nvarcharValue += contactSelected.listValueId+'-'+contactSelected.typeName;
            }
        }else{
            if(contactSelected.listValueId){
                nvarcharValue += contactSelected.listValueId+'-'+contactSelected.typeName+'|';
            }
        }
    }
    child.sendContacts.emit({newValue: nvarcharValue});
  }

  checkGroupList(child: any){
    if(child.field.fieldType === 'multiple'){
        let typeList : any = ['group', 'productRole', 'user', 'contact'];
        typeList.forEach((type: any)=>{
            if(type === 'group' && child.fieldTypeContacts.groupsList && child.fieldTypeContacts.groupsList.length){
                this.asignCheckValue(type, child.fieldTypeContacts.groupsList, child);
            }else if(type === 'productRole' && child.fieldTypeContacts.productRolesList  && child.fieldTypeContacts.productRolesList.length){
                this.asignCheckValue(type, child.fieldTypeContacts.productRolesList, child);
            }else if(type === 'user' || type === 'contact'){
                this.asignCheckValue(type, child.fieldTypeContacts.usersAndContactsFilList, child);
            }
        })
    }
  }

  asignCheckValue(type: any, typeList: any, child: any){
    for(let i = 0; i< typeList.length; i++){
        if((type === 'user' && typeList[i].userId) || (type === 'contact' && typeList[i].contactId) || type === 'group'){
            typeList[i].active = false;
        }
        for(let j = 0; j < child.fieldTypeContacts.contactListSelected.length; j++){
            if(typeList[i][type+'Id'] === child.fieldTypeContacts.contactListSelected[j].listValueId && type.substring(0,1) === typeList[i].type.toLowerCase()){
                typeList[i].active = true;
                break;
            }
        }
    }
  }

  configureAssists(child: any){
    child.fieldTypeContacts.all = 0;
    child.fieldTypeContacts.pending = 0;
    child.fieldTypeContacts.confirm = 0;
    child.fieldTypeContacts.cancel = 0;
    child.field.selectContacts.forEach((participant:any) => {
        if(participant.assists === null || participant.assists === undefined){
              child.fieldTypeContacts.pending++;
        }else if(participant.assists === true || participant.assists === 1){
              child.fieldTypeContacts.confirm++;
        }else if(participant.assists === false || participant.assists === 0){
              child.fieldTypeContacts.cancel++;
        }
    });
    child.fieldTypeContacts.all = child.field.selectContacts.length;
  }

  configureDisplayDataList(contact: any, child: any){
    if(child.field && child.field.fieldTypeId === 'user'){
        let newContact : any = {
            id: contact.listValueId,
            idType: contact.type,
            value:{
                name: contact.fullName,
                mail: contact.email,
                phone: contact.phoneNumber1,
            }
        };
        child.field.evaluatedValue.displayData.push(newContact);
    }
  }

  showTooltip(child: any, elementId: string = '', participant: any = {}){
    return participant.isDeleted? false : !this.commonUtilsService.showTooltip(elementId);
  }

  setDisabledContacts(list:any, child:any){
    list.forEach((itemSelected:any) => {
        itemSelected.disabled = false;
        child.listDisabled.forEach((listDisabled:any) => {
          let listDisabledId : any = listDisabled.uniqueValueId?listDisabled.uniqueValueId:listDisabled.type+'-'+listDisabled.id;
          if(!itemSelected.active && itemSelected.uniqueValueId === listDisabledId){
              itemSelected.disabled = true;
          }
        });
    });
  }

  addOrDeleteMultipleItem(participant: any, type: any, child: any, e: any=undefined){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    if(!participant.disabled){
      if(!child.fieldTypeContacts.contactListSelected){
        child.fieldTypeContacts.contactListSelected = [];
      }
      let addToList = true;
      let typeList = [];
      participant.listValueId = participant[type+'Id'];
      for(let i = 0; i < child.fieldTypeContacts.contactListSelected.length; i++){
        if(participant.listValueId === child.fieldTypeContacts.contactListSelected[i][type + 'Id']){
            child.fieldTypeContacts.markAll = false;
            participant.active = false;
            child.fieldTypeContacts.contactListSelected.splice(i, 1);
            typeList = child.fieldTypeContacts.usersAndContactsFilList;
            if(type === 'group'){
                typeList = child.fieldTypeContacts.groupsFilList;
            }else if(type === 'productRole'){
                typeList = child.fieldTypeContacts.productRolesFilList;
            }
            if(child.field.typeConfiguration === "field-user"){
                child.field.evaluatedValue.displayData.splice(i, 1);
            }
            typeList.forEach((typeItem: any)=>{
                if(typeItem[type+'Id'] === participant.listValueId){
                  child.fieldTypeContacts.markAll = false;
                  typeItem.active = false;
                }
            })
            addToList = false;
            break;
        }
      }
      if(addToList){
        participant.active = true;
        participant.typeName = type==='productRole'?'role':type;
        child.fieldTypeContacts.contactListSelected.push(participant);
        this.configureDisplayDataList(participant, child);
      }
      if(child.field.typeConfiguration === "field-user"){
        this.configureListUsersSelected(child);
      }else{
        child.sendContacts.emit({contacts:child.fieldTypeContacts.contactListSelected});
      }
      if(child.field){
        child.field.error = false;
      }
    }
  }

  toggleAll(event:any, child:any) {
    child.fieldTypeContacts.markAll = !child.fieldTypeContacts.markAll;
    child.fieldTypeContacts.productRolesFilList = this.commonUtilsService.findByWord(child.fieldTypeContacts.productRolesList, child.fieldTypeContacts.searchContact, ['fullName'])
    child.fieldTypeContacts.groupsFilList = this.commonUtilsService.findByWord(child.fieldTypeContacts.groupsList, child.fieldTypeContacts.searchContact, ['fullName'])

    if(child.fieldTypeContacts.viewItems.id === 4 || child.fieldTypeContacts.viewItems.id === 0){
      child.fieldTypeContacts.productRolesFilList.forEach((item: any)=>{
          if (item.active != child.fieldTypeContacts.markAll) {
              this.addOrDeleteMultipleItem(item, 'productRole', child, event);
          }
      })
    }
    if(child.fieldTypeContacts.viewItems.id === 1 || child.fieldTypeContacts.viewItems.id === 0){
      child.fieldTypeContacts.groupsFilList.forEach((item: any)=>{
          if (item.active != child.fieldTypeContacts.markAll) {
              this.addOrDeleteMultipleItem(item, 'group', child, event);
          }
      })
    }
    if(child.fieldTypeContacts.viewItems.id === 0 || child.fieldTypeContacts.viewItems.id === 2 || child.fieldTypeContacts.viewItems.id === 3){
      child.fieldTypeContacts.usersAndContactsFilList.forEach((item: any)=>{
          let itemView = child.fieldTypeContacts.viewItems.id === 0 || (item.userId && child.fieldTypeContacts.viewItems.id===2) || (item.contactId && child.fieldTypeContacts.viewItems.id === 3);
          if (itemView && item.active != child.fieldTypeContacts.markAll) {
              this.addOrDeleteMultipleItem(item, (item.userId ? 'user' : 'contact'), child, event);
          }
      })
    }
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
