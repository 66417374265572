<div class="concept-list">
  <div class="concept-list__table">
      <div class="common__table-header"
            [ngShow]="conceptList.conceptList?.length">
          <div class="concept-list__table-col concept-list__table-col--header-title">
              <div [ngShow]="conceptInfo.publicShared && conceptInfo.allowPublicShare && userPermissions !=='none'">
                  <label [for]="'multiple-selection-'+conceptInfo.conceptId+'-select'"
                      class="documentation-list__label-multiple documentation-list__label-multiple--icam-query">
                      <input class="documentation-list__input-multiple"
                        type="checkbox"
                        [id]="'multiple-selection-'+conceptInfo.conceptId+'-select'"
                        (click)="selectMultipleConcept('all')">
                        <i class="documentation-list__icon-multiple"
                          [ngClass]="conceptList.selectAll?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                    </label>
                </div>
                <filter-by-search
                    [item]="conceptList.titleFilter"
                    [filterOrder]="conceptList.dateFilter"
                    [order]="conceptList.order"
                    [filterText]="conceptList.titleWordFilter"
                    (launchActionSearch)="orderBySearch($event, 'title')">
                </filter-by-search>
            </div>
            <div class="concept-list__table-col concept-list__table-col--confidential"></div>
            <div class="concept-list__table-col concept-list__table-col--author">
                <filter-by-list [type]="'listFind'"
                      [item]="conceptList.authorFilter"
                      [list]="conceptList.authorList"
                      (filterElementEmit)="selectTypeOperation($event, 'author')">
                </filter-by-list>
            </div>
            <div class="concept-list__table-col concept-list__table-col--manager-query concept-list__table-col--top-1"
                [ngShow]="conceptInfo.publicShared && conceptInfo.allowPublicShare">
                <filter-by-list [type]="'listFind'"
                      [item]="conceptList.managersFilter"
                      [widthClass]="'200px'"
                      [list]="conceptList.managersList"
                      (filterElementEmit)="selectTypeOperation($event, 'managers')">
                </filter-by-list>
            </div>
            <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
                <div (click)="orderDate('creationDate')">
                    <p class="common__table-header-text"
                      [afTranslate]="'common.creation'">Creación</p>
                    <i class="common__order-by"
                      [ngClass]="{'lf-icon-arrow-down':(conceptList.dateFilter === 'creationDate' && conceptList.order) || conceptList.dateFilter !== 'creationDate',
                            'lf-icon-arrow-up':conceptList.dateFilter === 'creationDate' && !conceptList.order,
                            'common__order-by--active': conceptList.dateFilter === 'creationDate'}"></i>
                </div>
            </div>
            <div class="concept-list__table-col concept-list__table-col--header-creation-date common__cursor-pointer">
                <div (click)="orderDate('modificationDate')">
                    <p class="common__table-header-text"
                      [afTranslate]="'common.modification'">Modificación</p>
                    <i class="common__order-by"
                      [ngClass]="{'lf-icon-arrow-down':(conceptList.dateFilter === 'modificationDate' && conceptList.order) || conceptList.dateFilter !== 'modificationDate',
                            'lf-icon-arrow-up':conceptList.dateFilter === 'modificationDate' && !conceptList.order,
                            'common__order-by--active': conceptList.dateFilter === 'modificationDate'}"></i>
                </div>
            </div>
            <div class="concept-list__table-col"
                [ngClass]="skin === 'uk-steel-blue'?'concept-list__table-col--header-state-uk':'concept-list__table-col--header-state'">
                <filter-by-list *ngIf="!conceptInfo.publicShared && conceptList.statePoll && !conceptInfo.anonymousAccess && evaluation.productId !== 'forms'"
                                [type]="'list'"
                                [widthClass]="'200px'"
                                [item]="conceptList.stateFilter"
                                [list]="conceptList.stateList"
                                (filterElementEmit)="selectTypeOperation($event, 'state')">
                </filter-by-list>
                <filter-by-list *ngIf="conceptInfo?.list?.length && conceptInfo.list[0].step !== undefined && conceptInfo.list[0].step !== null && evaluation.productId !== 'forms'"
                                [type]="'listFind'"
                                [item]="conceptList.stepFilter"
                                [list]="conceptList.stepList"
                                (filterElementEmit)="selectTypeOperation($event, 'step')">
                </filter-by-list>
                <filter-by-list *ngIf="evaluation.productId === 'forms' && conceptList.showFilterAssociatedEvaluation"
                                [type]="'listFind'"
                                [item]="conceptList.associatedEvaluationNameFilter"
                                [list]="conceptList.associatedEvaluationNameList"
                                (filterElementEmit)="selectTypeOperation($event, 'associatedEvaluationName')">
                </filter-by-list>
            </div>
            <div class="concept-list__table-col concept-list__table-col--header-reference"
                [ngShow]="skin !== 'uk-steel-blue'">
                <p class="common__table-header-text common__cursor-pointer"
                  [ngShow]="conceptInfo.allowPublicShare && skin !== 'uk-steel-blue'"
                  [afTranslate]="'common.reference'">Referencia</p>
            </div>
            <div class="concept-list__table-col concept-list__table-col--actions-header">
                <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">Acciones</p>
            </div>
        </div>
        <div class="common__table-row"
            *ngFor="let evaluateConcept of conceptList.conceptListFilter | afOrderBy:[conceptList.dateFilter, 'title']:conceptList.order | afLimitTo:conceptList.configPagination.pageSize:conceptList.configPagination.pageSize*(conceptList.currentPage - 1)">
            <div class="concept-list__table-col concept-list__table-col--title common__cursor-pointer">
                <div [ngShow]="conceptInfo.publicShared && conceptInfo.allowPublicShare && userPermissions !=='none'">
                    <label [for]="'multiple-selection-'+evaluateConcept[conceptList.typeObjectId]+'-select'"
                        class="documentation-list__label-multiple documentation-list__label-multiple--icam-query">
                        <input class="documentation-list__input-multiple"
                            type="checkbox"
                            [id]="'multiple-selection-'+evaluateConcept[conceptList.typeObjectId]+'-select'"
                            (click)="selectMultipleConcept('one', evaluateConcept)">
                        <i class="documentation-list__icon-multiple"
                            [ngClass]="evaluateConcept.selectedConcept?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                    </label>
                </div>
                <p [id]="'evaluate-concept-title-'+evaluateConcept[conceptList.typeObjectId]"
                  class="common__col-text"
                  (click)="launchActionOption('open', evaluateConcept)"
                  matTooltip="{{evaluateConcept.title}}"
                  matTooltipPosition="below"
                  matTooltipClass="below"
                  [matTooltipDisabled]="showTooltip('evaluate-concept-title-'+evaluateConcept[conceptList.typeObjectId])">
                    <span [id]="'span-evaluate-concept-title-'+evaluateConcept[conceptList.typeObjectId]">{{evaluateConcept.title}}</span>
                </p>
            </div>
            <div class="concept-list__table-col concept-list__table-col--confidential">
                <div *ngIf="evaluateConcept.confidential"
                    ngbDropdown
                    [autoClose]="'outside'"
                    [id]="'concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId]">
                    <i class="lf-icon-lock concept-list__icon-confidential"
                        ngbDropdownToggle
                        matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </i>
                    <list-in-dropdown [ngShow]="evaluateConcept.confidentialList"
                        ngbDropdownMenu
                        class="dropdown-menu concept-list__box-select-list"
                        [list]="evaluateConcept.confidentialList"
                        [type]="'confidential'"
                        (closeDropdownEmit)="closeDropdown('concept-list-confidential-'+evaluateConcept[conceptList.typeObjectId])">
                    </list-in-dropdown>
                </div>
            </div>
            <div class="concept-list__table-col concept-list__table-col--author">
                <p [id]="'evaluate-concept-author-'+evaluateConcept[conceptList.typeObjectId]"
                  class="common__col-text"
                  [ngClass]="{'common__line-through':evaluateConcept.authorIsDeleted}">
                    <span [id]="'span-evaluate-concept-author-'+evaluateConcept[conceptList.typeObjectId]"
                          matTooltip="{{evaluateConcept.authorIsDeleted?evaluateConcept.author +' ('+ ('common.inactive-user' | afTranslate)+')':evaluateConcept.author}}"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          [matTooltipDisabled]="evaluateConcept.authorIsDeleted? true : showTooltip('evaluate-concept-author-'+evaluateConcept[conceptList.typeObjectId])">{{evaluateConcept.author}}
                    </span>
                </p>
            </div>
            <div class="concept-list__table-col concept-list__table-col--manager-query"
                [ngShow]="conceptInfo.publicShared && conceptInfo.allowPublicShare">
                <participants-list *ngIf="evaluateConcept.managers?.length"
                        [participants]="evaluateConcept.managers"
                        [type]="'manager-concept-list'">
                </participants-list>
            </div>
            <div class="concept-list__table-col concept-list__table-col--creation-date">
                <parragraph-tooltip [text]="evaluateConcept.creationDate | convertDate:''"></parragraph-tooltip>
            </div>
            <div class="concept-list__table-col concept-list__table-col--creation-date">
                <parragraph-tooltip [text]="evaluateConcept.modificationDate | convertDate:''"></parragraph-tooltip>
            </div>
            <div class="concept-list__table-col concept-list__table-col--state"
                [ngClass]="skin === 'uk-steel-blue'?'concept-list__table-col--state-uk':'concept-list__table-col--state'">
                <parragraph-tooltip *ngIf="!conceptInfo.poll && evaluation.productId !== 'forms'"
                  [text]="evaluateConcept.step"></parragraph-tooltip>
                <parragraph-tooltip *ngIf="evaluation.productId === 'forms'"
                  [text]="evaluateConcept.associatedEvaluationName"></parragraph-tooltip>
                <span-tooltip *ngIf="conceptInfo.poll && !conceptInfo.anonymousAccess && evaluation.productId !== 'forms'"
                  [class]="'task-concept__task-state task-concept__task-state--ellipsis task-concept__task-state--'+(evaluateConcept.stateId | colors)"
                  [text]="evaluateConcept.state"></span-tooltip>
            </div>
            <div class="concept-list__table-col concept-list__table-col--reference"
                [ngShow]="skin !== 'uk-steel-blue'">
                <parragraph-tooltip [text]="evaluateConcept.conceptObjectId"
                  [ngShow]="conceptInfo.allowPublicShare && skin !== 'uk-steel-blue'"></parragraph-tooltip>
            </div>
            <div class="concept-list__table-col concept-list__table-col--actions">
                <ul class="common__options-row"
                    [ngHide]="(conceptList.anySelect && !evaluateConcept.selectedConcept)">
                    <li class="common__menu-options-row"
                        [ngShow]="toShow !== 'archived' && !conceptList.anySelect">
                        <i [ngClass]="userPermissions === 'none' || conceptInfo.poll?'lf-icon-visible':'lf-icon-editable-form'"
                            (click)="launchActionOption('open', evaluateConcept)"
                            matTooltip="{{conceptList.descriptionEdit}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions === 'all' && conceptInfo.type === 0 && toShow !== 'archived' && !conceptList.anySelect">
                        <i class="lf-icon-download"
                            (click)="launchActionOption('download', evaluateConcept)"
                            matTooltip="{{conceptList.downloadReport}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions === 'all' && conceptInfo.type === 0 && toShow !== 'archived' && !conceptList.anySelect">
                        <i class="lf-icon-share"
                            (click)="launchActionOption('share', evaluateConcept)"
                            matTooltip="{{conceptList.shareReport}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="conceptInfo.type !== 0 && !conceptList.anySelect">
                        <i class="lf-icon-open-window"
                            (click)="openInNewTab(evaluateConcept)"
                            matTooltip="{{conceptList.description}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="evaluation.productId === 'forms' && evaluateConcept.associatedEvaluationName && !conceptInfo.reportId && toShow !== 'archived' && !conceptList.anySelect">
                        <i class="lf-icon-map-marker"
                            (click)="openAssociatedEvaluation(evaluateConcept)"
                            matTooltip="{{'expanded-menu.browse-to-the-origin' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions !== 'none' && conceptInfo.publicShared && conceptInfo.allowPublicShare && toShow !== 'archived' && (!conceptList.anySelect || (evaluateConcept.selectedConcept && conceptList.anySelect))">
                        <i class="lf-icon-consulting"
                            (click)="assignManager(evaluateConcept)"
                            matTooltip="{{'concept-list.assign-manager'| afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions !== 'none' && conceptList.showAnonymatum && !evaluateConcept.anonymized && skin==='dark-blue' && !conceptList.anySelect">
                        <i class="lf-icon-anonymous"
                            (click)="changeToAnonymatum(evaluateConcept)"
                            matTooltip="{{'expanded-menu.anonymatum-documents'| afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions !== 'none' && !conceptInfo.reportId && toShow !== 'archived' && !conceptList.anySelect">
                        <i class="lf-icon-lock"
                            (click)="openModalConfidential(evaluateConcept)"
                            matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                          </i>
                    </li>
                    <li class="common__menu-options-row"
                        *ngIf="userPermissions !== 'none' && conceptInfo.conceptId && !conceptList.anySelect && evaluation.productId !== 'icam' && evaluation.productId !== 'blanqueo'">
                        <i class="lf-icon-rename"
                            (click)="renameConcept(evaluateConcept)"
                            matTooltip="{{'rename-modal.name-concept-rename' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions === 'all' && !conceptInfo.poll && toShow !== 'archived' && conceptInfo.conceptId && !conceptList.anySelect && !conceptInfo.hasAutoArchive">
                        <i class="lf-icon-archive"
                            (click)="archiveConcept(evaluateConcept, '1')"
                            matTooltip="{{'concept-list.archive-concept' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
					              </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="toShow !== 'archived' && conceptInfo.publicShared && conceptInfo.allowPublicShare && !conceptInfo.poll && !conceptList.anySelect  && userPermissions === 'all' && conceptInfo.conceptId">
                        <i class="lf-icon-process"
                            (click)="changeState(evaluateConcept)"
                            matTooltip="{{'concept-list.manage-proccess'| afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="toShow === 'archived' && evaluateConcept.conceptObjectId && !conceptList.anySelect && userPermissions === 'all'">
                        <i class="lf-icon-rotate"
                            (click)="archiveConcept(evaluateConcept, '0')"
                            matTooltip="{{'concept-list.restore-concept' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row pp"
                        [ngShow]="toShow === 'archived' && evaluateConcept.reportObjectId && !conceptList.anySelect">
                        <i class="lf-icon-visible"
                            (click)="launchActionOption('open', evaluateConcept)"
                            matTooltip="{{'concept-list.view-report' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="userPermissions !== 'none' && !conceptInfo.poll && !conceptList.anySelect && !conceptInfo.restrictedDelete">
                        <i class="lf-icon-trash"
                            (click)="launchActionOption('delete', evaluateConcept)"
                            matTooltip="{{conceptList.deleteConcept}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </i>
                    </li>
                </ul>
                <i class="lf-icon-kebab-menu common__icon-ellipsis"
                    [ngHide]="userPermissions === 'none' && toShow === 'archived' && !conceptInfo.reportId"></i>
              <i class="lf-icon-anonymous common__icon-ellipsis concept-list__anonymized-concept-icon"
                    [ngShow]="evaluateConcept.anonymized"></i>
            </div>
        </div>
        <p class="concept-list__has-archived"
            [ngShow]="conceptInfo?.reportId && conceptList.archivedAmount && !conceptInfo?.reportObjects?.length && toShow !== 'archived'"
            [afTranslate]="'concept-list.reports-archived'">
        </p>
        <div class="concept-list__table-foot"
            [ngClass]="{'concept-list__table-foot--no-items': !conceptList.conceptList?.length,
                'concept-list__table-foot--has-archived':conceptInfo?.reportId && conceptList.archivedAmount && !conceptInfo?.reportObjects?.length && toShow !== 'archived'}">
            <div *ngIf="!conceptInfo?.poll && conceptList.archivedAmount && toShow !== 'archived'"
                class="concept-list__block-info-archive concept-list__block-info-archive--absolute">
                <button class="common__button-border-none concept-list__archive-button"
                    (click)="viewArchived()">
                    <i class="lf-icon-archive common__icon-button-underlined"></i>
                    <span class="common__text-button-underlined"
                          [afTranslate]="'concept-list.view-archive'">_Ver archivo</span>
                </button>
                <p class="concept-list__archived-evaluated-amount">{{conceptList.archivedParragraph}}</p>
            </div>
            <div *ngIf="conceptInfo?.poll && conceptList.showResultsReports"
                class="concept-list__block-info-archive concept-list__block-info-archive--absolute">
                <button class="common__button-border-none common__button-border-none--left"
                    (click)="showReports()">
                    <i class="lf-icon-analysis common____icon-button-underlined"></i>
                    <span class="common__text-button-underlined"
                          [afTranslate]="'concept-list.view-report-results'">_Ver informe de resultados</span>
                </button>
            </div>
            <div class="concept-list__pagination-block">
                <pagination-control [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize"
                    [config]="conceptList.configPagination"
                    [collectionSize]="conceptList.conceptListFilter?.length"
                    [(currentPage)]="conceptList.currentPage"
                    (configuredTotalChange)="conceptList.configuredTotal = $event.configuredTotal">
                </pagination-control>
                <div class="common__pagination-count" [ngShow]="conceptList.conceptListFilter?.length > conceptList.configPagination.pageSize">
                  <span class="notranslate">{{conceptList.configuredTotal.first}}-{{conceptList.configuredTotal.last}}</span>
                  <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
                  <span class="notranslate">{{conceptList.conceptListFilter?.length}}</span>
                </div>
                <div class="common__pagination-count"
                  [ngShow]="conceptList.configPagination.pageSize >= conceptList.conceptListFilter?.length">
                  <span>
                    <span [afTranslate]="'common.total'">_Total</span>
                    <span class="common__total-number-element notranslate">{{conceptList.conceptListFilter?.length}}</span>
                  </span>
                </div>
            </div>
        </div>
    </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="conceptList.configurationPdf"></pdf-table>
