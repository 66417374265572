<div class="edition-comparator common__panel"
    id="edition-comparator">
    <div class="edition-comparator__block-header">
        <div class="edition-comparator__block-logo">
            <img class="edition-comparator__logo-img"
                [src]="editionComparator.logoHeader" alt="loader">
            <p class="edition-comparator__block-text"
                [ngShow]="skin==='gm-law'"
                [afTranslate]="'front-login.powered-by'"></p>
        </div>
        <div class="edition-comparator__block-header-modified">
            <span [afTranslate]="'edition-comparator.comparison-of-editions'">_Comparación de ediciones</span>
        </div>
    </div>
    <div class="edition-comparator__block-breadcrumb">
        <div class="edition-comparator__breadcrumb-block">
            <div class="edition-comparator__breadcrumb-item"
                *ngFor="let breadcrumb of editionComparator.historyDataViewer?.breadCrumb; let last = last"
                [ngClass]="{'edition-comparator__breadcrumb-item--active':last}">
                <div class="edition-comparator__breadcrumb-text">{{breadcrumb}}</div>
                <i class="lf-icon-angle-right edition-comparator__icon-angle-right"
                    [ngShow]="breadcrumb && !last"></i>
            </div>
        </div>
    </div>
    <div class="edition-comparator__block-body">
        <div class="edition-comparator__block-header-body">
            <div class="edition-comparator__block-buttons-header-body">
                <button class="edition-comparator__button-print"
                    (click)="printDocument()">
                    <span class="edition-comparator__button-header-text"
                        [afTranslate]="'common.print'">Imprimir</span>
                    <i class="lf-icon-print edition-comparator__button-header-icon"></i>
                </button>
            </div>
        </div>
        <div class="edition-comparator__block-comparator">
            <div class="edition-comparator__block-body-form">
                <field-type-list-simple class="edition-comparator__block-field-historic-list"
                    [field]="editionComparator.selectedEdition"
                    [listValues]="editionComparator.historicSelectList"
                    [preSelectItem]="editionComparator.logOperationID">
                </field-type-list-simple>
                <edition-comparator-form [historyDataViewer]="editionComparator.historyDataViewer"></edition-comparator-form>
            </div>
            <div class="edition-comparator__block-body-form">
                <field-type-list-simple class="edition-comparator__block-field-historic-list"
                    [field]="editionComparator.otherEdition"
                    [listValues]="editionComparator.historicSelectList"
                    [preSelectItem]="editionComparator.otherLogOperationID"
                    [fieldToFind]="'text'"
                    [orderBy]="'reverseId'"
                    (itemSelected)="otherEditionSelected($event)">
                </field-type-list-simple>
                <button type="submit"
                    class="common__button common__button--litle common__button--active edition-comparator__button-compare"
                    (click)="compareEdition()"
                    [disabled]="!editionComparator.selectedOtherItem || editionComparator.selectedOtherItem === editionComparator.otherLogOperationID"
                    [afTranslate]="'edition-comparator.compare'">_Comparar</button>
                <edition-comparator-form [historyDataViewer]="editionComparator.otherHistoryDataViewer"></edition-comparator-form>
                <advice-message [ngShow]="editionComparator.hasErrorComparator"
                    [icon]="'warning'"
                    [type]="'error'"
                    [text]="editionComparator.warningText"
                    [typeClass]="'no-margin'"
                    (closeAdvice)="closeAdvice('hasErrorComparator')">
                </advice-message>
            </div>
        </div>

    </div>
    <compliance-footer *ngIf="!editionComparator.historyDataViewer?.landingPage"></compliance-footer>
    <customize-footer *ngIf="editionComparator.historyDataViewer?.landingPage"
        [configureOptions]="editionComparator.historyDataViewer?.custom"></customize-footer>
</div>
