import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskHistoricParentService {

  comparatorInfo(historic:any, child:any){
    child.taskHistoric.compareVersions.logID = historic.logOperationID;
    child.taskHistoric.compareVersions.taskId = child.task.taskId;
  }

  maximizeScreen(keepClassBox:boolean, child:any){
    child.taskHistoric.fullScreen = !child.taskHistoric.fullScreen;
    if(!keepClassBox){
        child.catchFullScreen.emit({box:'historic-templates'});
    }
  }
}
