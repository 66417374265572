import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AfTranslateFactory } from "@shared/modules/translate/factory/translate.factory";
import { ValidateDocumentService } from "@shared/services/validate-document/validate-document.service";

@Component({
  selector: 'export-modal',
  templateUrl: './export-modal.component.html'
})
export class ExportModalComponent implements OnInit{
  exportModal: any = {
    filenameInput: {
      required: true,
      label: this.translate.instant('export-modal.input-label')
    }
  }
  constructor(private translate:AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private validateDocumentService: ValidateDocumentService){}

  @Input() configuration: any;

  ngOnInit(): void {
    this.exportModal.title = !this.configuration.title || this.configuration.title.trim() === ''?this.translate.instant('common.export-excel') : this.configuration.title;
  }

  setFilename(e:any) {
    this.exportModal.filenameInput.value = e.newValue;
  }

  validateForm() {
    this.exportModal.errorMessage = null;
    let filenameInputValue:any = this.exportModal.filenameInput.value;
    if (filenameInputValue == null || filenameInputValue.trim() == '') {
      this.exportModal.errorMessage = this.translate.instant('export-modal.error-message.empty-name')
    } else if(!(this.validateDocumentService.correctCharacters(filenameInputValue))){
      this.exportModal.errorMessage = this.translate.instant('rename-modal.incorrect-characters');
    } else if(this.configuration.list?.length){
        for (const elementList of this.configuration.list) {
          let elementName = elementList.name;
          if (elementName.substring(0, elementName.lastIndexOf('.')).toLowerCase() === this.exportModal.filenameInput.value.toLowerCase() && elementList.extension.toLowerCase().indexOf(this.configuration.extension.toLowerCase()) !== -1) {
              this.exportModal.errorMessage = this.translate.instant('export-modal.error-message.used-name');
              break;
          }
        }
    }
    this.exportModal.filenameInput.error = this.exportModal.errorMessage? Boolean(this.exportModal.errorMessage): this.handleAccept();
  }

  handleAccept() {
    this.activeModal.close({
      result:'ok',
      filename: this.exportModal.filenameInput.value,
      openFile: this.exportModal.openFile,
      downloadFile: this.exportModal.downloadFile
    });
  }

  handleDismiss(type: string = 'cancel') {
    this.activeModal.close({result:type});
  }
}
