import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'parragraph-tooltip',
  templateUrl: './parragraph-tooltip.component.html'
})
export class ParragraphTooltipComponent implements OnInit {

  @Input() text:string = '';
  @Input() classType:string = '';
  @Input() cursor:boolean = false;
  @Input() inheritedClass:boolean = false;
  @Input() isDeleted:boolean = false;

  ngOnInit(){
    this.classType = this.classType?'common__cell-block '+this.classType:'common__cell-block';
  }
}
