<div class="concept">
  <concept-tab [list]="reportInfo.reportList"
      [subMenu]="'reports'"
      [fullMenu]="reportInfo.fullMenu"
      [activeConcept]="report"
      (launchAction)="launchActionOn($event)">
  </concept-tab>
  <div *ngIf="reportInfo.reportList[0].active" class="concept-tab__line">
      <div class="concept__content" [ngSwitch]="reportInfo.reportList[0].subTab">
        <report-table-default *ngSwitchCase="'reports'"
            [report]="reportInfo.reportList[0]"
            [configurateReport]="reportInfo.configurateReport"
            (updateList)="updateReport()"
            (exportReport)="exportReport($event)">
        </report-table-default>
        <documentation-report-list *ngSwitchDefault
            [documentationReport]="reportInfo.reportDocuments"
            (launchAction)="launchActionOn($event)">
        </documentation-report-list>
      </div>
      <div class="concept__go-back" *ngIf="reportInfo.reportList[0].active">
          <button class="concept__go-back-button">
              <i class="lf-icon-arrow-back concept__go-back-icon" (click)="goBack()"></i>
              <span class="concept__cursor-hover" [afTranslate]="'common.return-to'" (click)="goBack()"></span>
              <span class="concept__cursor-hover" [afTranslate]="'common.reports'" (click)="goBack()"></span>
          </button>
      </div>
  </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    *ngIf="(skin !== 'icam-red' && !report.excelCharts) || report.reportId === 195 || report.reportId === 197"
    [configuration]="reportInfo.configurationPdf"></pdf-table>
