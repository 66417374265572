<div class="visibility"
  [ngClass]="{'visibility--field':type === 'field'}">
  <label class="common__input-block common__input-block common__input-block--visibility"
      [ngShow]="!visibility.pickerVisible"
      [for]="'visibility-label-'+itemId"
      [ngClass]="{'common__input-block--open':visibility.showList, 'common__input-no-editable':visibility.noEditable}">
      <div class="common__label-block common__label-block--one-icon-bubble">
          <span class="common__input-text"
              [afTranslate]="'customize.visibility-conditions'">_Condiciones de visibilidad</span>
      </div>
      <div class="common__bubble-count common__bubble-count--top-7 float-end"
          [ngClass]="{'common__bubble-count--disabled': visibility.noEditable}"
          [ngShow]="visibility.selectedElements.length">{{visibility.selectedElements.length}}</div>
      <button class="lf-icon-products common__input-icon common__input-icon-active"
          [ngClass]="{'field-type-list__icon-link--disabled': visibility.noEditable}"
          (click)="showPicker()"
          [disabled]="visibility.noEditable">
      </button>
      <ul class="common__multiselect-block common__multiselect-block--left-10">
          <li class="common__multiselect-item"
              [ngStyle]="{width: visibility.widthElement }"
              *ngFor="let selectedElement of visibility.selectedElements">
              <p class="common__multiselect-text field-type-list__block-text">
                  {{selectedElement.text}}
              </p>
              <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple"
                  [ngHide]="visibility.noEditable"
                  (click)="deleteSelected(selectedElement)"></i>
          </li>
      </ul>
  </label>
  <picker-visibility [columns]="visibility.pickerColumns"
      [items]="items"
      [selectedItems]="visibility.selectedItems"
      [selectedCount]="visibility.selectedItemsCount"
      [readonly]="readonly"
      [orCondition]="orCondition"
      (onClose)="closePicker($event)"
      *ngIf="visibility.pickerVisible"
      [type]="type">
  </picker-visibility>
</div>
