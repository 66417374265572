<!-- Cabecera -->
<div class="common__table-header duplicate-documentation__table-header">
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan"></div>
    <div class="duplicate-documentation__table duplicate-documentation__table--title-concept" >
        <filter-by-search
            [item]="duplicateDocumentation.titleFilter"
            (launchActionSearch)="orderBySearch($event, 'title')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--author-concept">
        <filter-by-search
            [item]="duplicateDocumentation.createdByFilter"
            (launchActionSearch)="orderBySearch($event, 'author')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--creation-concept">
        <div class="common__cursor-pointer"
            (click)="orderDate('creationConcept')">
            <p class="common__table-header-text"
                [afTranslate]="'common.creation'">_Creación</p>
            <i class="common__order-by "
                [ngClass]="{'lf-icon-arrow-down':(duplicateDocumentation.dateOrder==='creationConcept' && duplicateDocumentation.orderOperationDates) || duplicateDocumentation.dateOrder !=='creationConcept',
                    'lf-icon-arrow-up':duplicateDocumentation.dateOrder==='creationConcept' && !duplicateDocumentation.orderOperationDates,
                    'common__order-by--active': duplicateDocumentation.dateOrder==='creationConcept'}"></i>
        </div>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--creation-concept">
        <div class="common__cursor-pointer"
            (click)="orderDate('modificationConcept')">
            <p class="common__table-header-text"
                [afTranslate]="'common.modification'">_Modificación</p>
            <i class="common__order-by"
                [ngClass]="{'lf-icon-arrow-down':(duplicateDocumentation.dateOrder==='modificationConcept' && duplicateDocumentation.orderOperationDates) || duplicateDocumentation.dateOrder !=='modificationConcept',
                    'lf-icon-arrow-up':duplicateDocumentation.dateOrder==='modificationConcept' && !duplicateDocumentation.orderOperationDates,
                    'common__order-by--active': duplicateDocumentation.dateOrder==='modificationConcept'}"></i>
        </div>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-right"></div>
  </div>
  <!-- Contenido -->
  <div class="duplicate-documentation__table-body"
    [ngClass]="{'duplicate-documentation__table-body--more-elements':filterConceptList?.length >= (pageSize*currentPage)-3}">
    <img [src]="duplicateDocumentation.loaderImg"
        class="duplicate-documentation__spinner-loader"
        [ngShow]="duplicateDocumentation.searchingInstances" alt="loader">
    <div class="duplicate-documentation__table-row"
        [ngShow]="!duplicateDocumentation.searchingInstances"
        *ngFor="let instance of filterConceptList
          | afOrderBy:duplicateDocumentation.dateOrder:duplicateDocumentation.orderOperationDates
          | afLimitTo:pageSize:pageSize*(currentPage - 1)"
        (click)="selectInstance(instance)">
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
            <i class="lf-icon-compliance duplicate-documentation__icon-product-scan"></i>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--title-concept">
            <div class="common__cell-block">
                <parragraph-tooltip [text]="instance.title"></parragraph-tooltip>
            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--author-concept">
            <div class="duplicate-documentation__cell-block duplicate-documentation__cell-block--organization">
                <parragraph-tooltip [text]="instance.author"></parragraph-tooltip>
            </div>

        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--creation-concept">
            {{instance.creationDate | convertDate:'without-time'}}
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--creation-concept">
            {{instance.modificationDate | convertDate:'without-time'}}
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-right">
            <span class="duplicate-documentation__text-explore"
                [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
            <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
        </div>
    </div>
  </div>
