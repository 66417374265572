<div class="report-table__header-icam"
    [ngClass]="{'report-table__header-icam--details': reportTable.detailsView}">
    <div class="report-table__header-left-icam">
        <div class="report-table__header-button-icam report-table__header-button-icam--left"
            [ngClass]="{'report-table__header-button-icam--details': reportTable.detailsView}"
            (click)="showUserQueries(null)"
            [ngShow]="reportTable.detailsView">
            <i class="lf-icon-arrow-back report-table__button-icon-icam report-table__button-icon-icam--left"></i>
            <span class="report-table__button-text-icam report-table__button-text-icam--right"
                [ngClass]="{'report-table__button-text-icam--details': reportTable.detailsView}"
                [afTranslate]="'report-table.icam.salir-detalle'"></span>
        </div>
    </div>
    <div class="report-table__header-center-icam">
        <span [ngShow]="reportTable.detailsView">
            {{reportTable.detailsName}}
        </span>
    </div>
    <div class="report-table__header-right-icam">

        <div class="report-table__header-button-icam"
            (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngShow]="!reportTable.detailsView">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                <filter-by-search [item]="reportTable.nombreUsuarioFilter"
                    (launchActionSearch)="orderBySearch($event, 'nombreUsuario')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam">Total consultas</div>
            <div class="report-table__tcell-icam report-table__tcell-icam--year report-table__tcell-icam--year-column"
                ngbDropdown>
                <span>{{reportTable.currentIcamYear}}</span>
                <i class="lf-icon-angle-down report-table__tcell-icon-icam"
                    ngbDropdownToggle></i>
                <ul class="report-table__dropdown-menu-icam"
                    ngbDropdownMenu>
                    <li class="report-table__dropdown-item-icam"
                        *ngFor="let year of reportTable.listYears"
                        (click)="selectIcamYear(year)">
                        <span>{{year}}</span>
                        <i class="lf-icon-check-round-full report-table__dropdown-icon-icam"></i>
                    </li>
                </ul>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[0]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[1]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[2]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[3]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[4]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[5]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[6]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[7]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[8]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[9]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper">
                {{reportTable.months[10]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--header-upper report-table__tcell-icam--last">
                {{reportTable.months[11]}}
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let row of reportTable.reportsListFilter; let last = last">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                {{row.nombreUsuario}}
            </div>
            <div class="report-table__tcell-icam">
                <span class="report-table__tcell-icam--badge"
                (click)="showUserQueries(row)">
                {{reportTable.totalConsultasUsuario[row.nombreUsuario]}}
            </span>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--year-column">
                {{row.totalConsultasAnyo}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[0]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[1]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[2]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[3]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[4]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[5]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[6]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[7]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[8]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[9]}}
            </div>
            <div class="report-table__tcell-icam">
                {{row.meses[10]}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                {{row.meses[11]}}
            </div>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngShow]="reportTable.detailsView">
    <div class="report-table__theader-icam">
        <div class="report-table__theader-lower-icam">
            <div class="report-table__tcell-icam"
                style="max-width:105px">
                <filter-by-search [item]="reportTable.referenciaFilter"
                    (launchActionSearch)="orderBySearch($event, 'referencia')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:80px">
                <filter-by-list class="report-table__filter-list-class"
                    [type]="'list'"
                    [item]="reportTable.tipoFilter"
                    [list]="reportTable.tipoList"
                    (filterElementEmit)="selectTypeOperation($event, {id:'tipo'})">
                </filter-by-list>
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:120px"
                ngbDropdown
                id="creationDate"
                [autoClose]="'outside'">
                <p class="common__table-header-text common__cursor-pointer"
                    [afTranslate]="'report-table.icam.por-usuario.entrada'"></p>
                <i class="lf-icon-filter-1 common__filter-icon"
                    [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': reportTable.creationDateFilter}"
                    ngbDropdownToggle></i>
                <i class="lf-icon-arrow-down common__order-by"
                    [ngClass]="{'lf-icon-arrow-up': !reportTable.orderAsc && reportTable.orderByColumn.id === 'creationDate',
                        'common__order-by--active': reportTable.orderByColumn.id === 'creationDate'}"
                    (click)="orderTable(reportTable.report.columns[3])"></i>
                <div class="dropdown-menu report-table__calendar-dropdown-icam common__block-option-list"
                    ngbDropdownMenu>
                    <from-to-calendar [fromSave]="reportTable.creationDateFromDate"
                        [toSave]="reportTable.creationDateToDate"
                        (changeCalendar)="changeCalendar($event, 'creationDate')"
                        [type]="'report-table'">
                    </from-to-calendar>
                </div>
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:150px">
                <filter-by-list class="report-table__filter-list-class"
                    [type]="'listFind'"
                    [item]="reportTable.stepFilter"
                    [list]="reportTable.stepList"
                    (filterElementEmit)="selectTypeOperation($event, {id:'step'})">
                </filter-by-list>
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:175px">
                <filter-by-list class="report-table__filter-list-class"
                    [type]="'listFind'"
                    [item]="reportTable.materiaFilter"
                    [list]="reportTable.materiaList"
                    (filterElementEmit)="selectTypeOperation($event, {id:'materia'})">
                </filter-by-list>
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:120px"
                ngbDropdown
                [autoClose]="'outside'">
                <p class="common__table-header-text common__cursor-pointer"
                   [afTranslate]="'common.modification'"></p>
                <i class="lf-icon-filter-1 common__filter-icon"
                   [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': reportTable.modificationDateFilter}"
                   ngbDropdownToggle>
                </i>
                <i class="lf-icon-arrow-down common__order-by"
                   [ngClass]="{'lf-icon-arrow-up': !reportTable.orderAsc && reportTable.orderByColumn.id === 'modificationDate',
                        'common__order-by--active': reportTable.orderByColumn.id === 'modificationDate'}"
                   (click)="orderTable(reportTable.report.columns[6])"></i>
                <div class="dropdown-menu report-table__calendar-dropdown-icam common__block-option-list"
                    ngbDropdownMenu
                    role="menu">
                    <from-to-calendar [fromSave]="reportTable.modificationDateFromDate"
                        [toSave]="reportTable.modificationDateToDate"
                        (changeCalendar)="changeCalendar($event, 'modificationDate')"
                        [type]="'report-table'">
                    </from-to-calendar>
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last">
                <filter-by-search [item]="reportTable.consultaFilter"
                    (launchActionSearch)="orderBySearch($event, 'consulta')">
                </filter-by-search>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--actions report-table__tcell-icam--actions-header report-table__tcell-icam--last"
                [afTranslate]="'common.actions'">
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam report-table__trow-icam--hover"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let query of reportTable.detailsFilter | afOrderBy:reportTable.orderByColumn.id:reportTable.orderAsc; let last = last">
            <div class="report-table__tcell-icam"
                style="max-width:105px">
                {{query.referencia}}
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:80px">
                {{query.tipo}}
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:120px">
                {{convertDate(query.creationDate)}}
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:150px">
                {{query.step}}
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:175px">
                {{query.materia}}
            </div>
            <div class="report-table__tcell-icam"
                style="max-width:120px">
                {{convertDate(query.modificationDate)}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--last"
                style="overflow:auto">
                <span class="common__ellipsis">{{query.consulta}}</span>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--actions report-table__tcell-icam--last">
                <i class="lf-icon-kebab-menu"></i>
                <i class="lf-icon-open-window report-table__tcell-icam-icon-open"
                    (click)="goToDetail(query)"></i>
            </div>
        </div>
    </div>
</div>
