import moment from "moment";
import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsTemplateParentService{
  subscribers: any = {};
  constructor(private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private modalService: ModalServiceService,
    private documentActions: DocumentActionsService) {}

  watchChangeListDocuments(child:any){
    this.subscribers.changeDocumentToListBroadcast = this.broadcastService.subscribe('changeDocumentToList', ( data:any)=>{
          if(data.newConcept &&child.isLanding){
              this.getAttachmentsDocument(child);
          }else if(child.attachmentsTemplate.attachmentList){
              child.attachmentsTemplate.attachmentList.forEach((attach:any) => {
                  if(attach.name === data.document.name){
                      attach.conceptObjectId = data.document.conceptObjectId;
                      attach.editPath = data.document.editPath;
                      attach.documentObjectId = data.document.documentObjectId;
                  }
              });
          }
      })
        this.subscribers.addAttachmentsDocumentBroadcast = this.broadcastService.subscribe('addAttachmentsDocument', (data:any)=>{
        if(!child.attachmentsTemplate.attachmentList){
            child.attachmentsTemplate.attachmentList = [];
        }
        data.document.extension = data.document.name.substr(data.document.name.lastIndexOf('.')+1, data.document.name.length);
        if(data.document.deleted){
          let index = child.attachmentsTemplate.attachmentList.findIndex((item: any)=>{ return item.documentObjectId === data.document.documentObjectId});
          child.attachmentsTemplate.attachmentList.splice(index,1);
        }else{
          let hasInArray =  child.attachmentsTemplate.attachmentList.filter((attachItem:any)=>{return attachItem.name === data.document.name}).length
          if(!hasInArray){
            child.attachmentsTemplate.attachmentList.splice(child.attachmentsTemplate.attachmentList.length, 0, data.document);
          }

        }

    })
        this.subscribers.deleteAttachmentsDocument =this.broadcastService.subscribe('deleteAttachmentsDocument', (data:any)=>{
        child.attachmentsTemplate.attachmentList = this.commonUtilsService.deleteElement(data.document, child.attachmentsTemplate.attachmentList, 'documentObjectId');
    })
        this.subscribers.deleteAllAttachmentsDocumentBroadcast = this.broadcastService.subscribe('deleteAllAttachmentsDocument', (data:any)=>{
        child.attachmentsTemplate.attachmentList = [];
    })
  }

  onChanges(child:any){
      let that: any = child.attachmentsTemplate;
      if(child.itemSelected){
          that.itemSelected = child.itemSelected;
          if(that.itemSelected?.taskId){
              that.hideAddDocuments = (that.itemSelected.taskStateId && (that.itemSelected.taskStateId === 5 || that.itemSelected.taskStateId === 6 ));
              if (that.itemSelected && that.itemSelected.taskTypeId === 2){
                that.titleHeader = 'attach-document.documents-to-sign';
              }
          }
          if(that.itemSelected && (that.itemSelected.conceptObjectId || that.itemSelected.taskId || (child.isLanding && !that.itemSelected.conceptObjectId && !that.itemSelected.taskId))){
              this.getAttachmentsDocument(child);
          }else{
              that.closeSpinner = true;
          }
      }
      if(child.isLanding && that.fullScreen && that.fullScreenBox !== '' && that.fullScreenBox !== 'attachments-templates'){
          this.maximizeScreen(true, child);
      }
      if(child.concept){
          that.concept = child.concept;
      }
  }


  getAttachmentsDocument(child:any, documentAdded:boolean = false) {
    let that: any = child.attachmentsTemplate;
    let url:string = 'documentobjects/' + that.itemSelected.conceptObjectId;
    that.documentsCanBeDeleted = false;
    if(child.isLanding && window.location.pathname.indexOf('/field-container/') !== -1){
        url = 'documentobjects/insharedfieldcontainer/'+ that.itemSelected.conceptObjectId + '/' + + child.route.snapshot.params['fieldContainerId'];
    }else if(child.isLanding && window.location.pathname.indexOf('/task-concept-evaluated/') !== -1){
        url = 'documentobjects/insharedevaluationtask/'+ that.itemSelected.conceptObjectId;
    }else if(child.isLanding && child.concept?.sharedReadOnly && that.itemSelected.conceptObjectId){
        url = 'documentobjects/insharedreadconceptobject/' + that.itemSelected.conceptObjectId;
    }else if(child.isLanding && !that.itemSelected.conceptObjectId && !that.itemSelected.taskId){
        url = 'publicconceptdoc/concept/' + that.itemSelected.conceptId;
        that.documentsCanBeDeleted = true;
    }else if (that.itemSelected && that.itemSelected.taskId) {
        url = 'documentobjects/task/' + that.itemSelected.taskId;
    }

    this.apiService.get(url)
        .then((data:any) => {
            if(child.skin === "icam-red" && child.isLanding && !data.documents.length){
                that.hiddenTemplate = true ;
            }
            if(child.isMonitoring){
              this.getReferenceDocument(data, child, documentAdded);
            }else{
              that.attachmentList = child.isLanding && that.itemSelected.conceptObjectId && !that.itemSelected.taskId?data.documents:data;
              this.configureGetDocuments(data, child, documentAdded);
            }
        }, (errorData:any) => {
            that.attachmentList = [];
            this.loader.closeError()
            that.closeSpinner = true;
        });
  }

  getReferenceDocument(documentsList:any = [], child:any, documentAdded:boolean = false){
    this.apiService.get('publicconceptdoc/concept/' + child.attachmentsTemplate.itemSelected.conceptId)
      .then((data:any) => {
          child.attachmentsTemplate.attachmentList = data.concat(documentsList.documents);
          this.configureGetDocuments(documentsList, child, documentAdded);
      }, (errorData:any) => {
          child.attachmentsTemplate.attachmentList = [];
          this.loader.closeError()
          child.attachmentsTemplate.closeSpinner = true;
      });
  }

  configureGetDocuments(data: any, child:any, documentAdded:boolean = false){
    let that: any = child.attachmentsTemplate;
    if(child.isLanding && !that.itemSelected.taskId){
        if(that.itemSelected.conceptObjectId){
            that.concept.documentation = data;
            let folders = data.subFolders;
            this.extractDocuments(that.attachmentList, folders, child);
        }
        that.attachmentList.forEach((document:any) => {
            document.disabledDelete = this.configureCanBeDeletedDocument(document, child);
            document.extension = document.name.substr(document.name.lastIndexOf('.')+1, document.name.length);
        });
    }
    if (documentAdded) {
        this.openQuestionModal(this.translate.instant('task-edit.question-add-documents'), child);
    }
    this.loader.closeLoader(['alert-condept-edit-alert']);
    that.closeSpinner = true;
  }

  configureCanBeDeletedDocument(document: any, child: any){
    let disabledDelete: boolean = (child.attachmentsTemplate.itemSelected.taskTypeId === 5 && (child.attachmentsTemplate.itemSelected.taskStateId === 5 || child.attachmentsTemplate.itemSelected.taskStateId === 6)) && !((child.attachmentsTemplate.itemSelected.taskTypeId !== 2 || child.attachmentsTemplate.itemSelected.conceptId) && document.canBeDeleted);
    if(child.isLanding){
      disabledDelete = (disabledDelete && (!document.anonymous || (document.anonymous && !child.attachmentsTemplate.concept.lockedEdit))) || (child.typeFrom === 'concept' && child.attachmentsTemplate.concept?.lockedEdit) || child.attachmentsTemplate.documentsCanBeDeleted || (child.isMonitoring && !document.conceptObjectId);
    }
    return disabledDelete;
  }

  extractDocuments(extension:any, subFolders:any, child:any){
      subFolders.forEach((subfolder:any) => {
          subfolder.documents.forEach((document:any) => {
              extension.push(document);
          });
          subfolder.subFolders.forEach((innerSubfolder:any) => {
              this.extractDocuments(extension, innerSubfolder, child);
          });
      });
  }

  openQuestionModal(text:string, child:any) {
      this.modalService.adviceModal(text, 'info:question:question')
          .subscribe((result:any)=>{
              result.result ==='ok'?  this.attachDocumentModal(child) : this.loader.closeError();
          });
  }

  launchActionDocuments(action:string, document:any, child:any){
      let itemSelected:any = child.attachmentsTemplate.itemSelected
      switch(action){
          case 'upDocument':
              this.upDocument(child);
              break;
          case 'delete':
              if(document.documentStatusId && (document.documentStatusId === 3 || document.documentStatusId === 4 || document.documentStatusId === 6)){
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-delete-document-signed') +"'"+ document.name + "'", 'error:accept:warning')
              }else{
                  let primaryText: any = this.translate.instant('documentation-list.question-remove-document') +"'"+ document.name +"'";
                  let secondaryText: string =  itemSelected && itemSelected.taskId? this.translate.instant('attachments.of-the-concept') + "'" + itemSelected.title+ "'?": this.translate.instant('task-edit.of-the-task');
                  this.deleteDocument(document, primaryText + secondaryText, child);
              }
              break;
          case 'download':
              this.documentActions.doAction('download', document);
              break;
          default:
              this.attachDocumentModal(child);
              break;
      }
  }

  upDocument(child:any){
      let that: any = child.attachmentsTemplate;
      let addDocument:string = 'addNewDocumentsFromTasks';
      let listDocuments: any = [];
      if(child.isLanding && that.itemSelected.conceptObjectId && !that.itemSelected.taskId){
          addDocument = 'addNewDocumentsFromLanding';
      }else if((child.typeFrom === 'task') && !that.itemSelected.taskId && !that.itemSelected.conceptObjectId){
          addDocument = 'addNewDocumentsFromTasksNew';
      }else if(child.isLanding && that.itemSelected.conceptId && !that.itemSelected.taskId){
          addDocument = 'addNewDocumentsFromConceptsNew';
      }

      if(child.currentComponent === 'genericTask'){
          listDocuments = that.attachmentList;
      }else if(child.isLanding){
          listDocuments = that.attachmentList;
      }else{
          listDocuments = [...that.concept.documentation.documents, ...that.attachmentList];
      }
      let listStates:any = [];
      if(that.concept && that.concept.listStates){
          listStates = that.concept.listStates;
      }
      this.modalService.uploadDocuments(addDocument, [], listDocuments, that.itemSelected, listStates)
          .subscribe((result:any)=>{
              if(result.result === 'ok'){
                  that.newDocuments = [...that.newDocuments, ...result.files];
                  if(child.isLanding){
                      if(addDocument === 'addNewDocumentsFromConceptsNew'){
                          result.files.forEach((file:any) => {
                              let modified = new Date();
                              file.modified = modified.getTimezoneOffset() < 0? moment(modified.setMinutes(Math.abs(modified.getTimezoneOffset()) + modified.getMinutes())).toISOString() : moment(modified).toISOString()
                              let newDocument = {
                                  contentType: file.contentType,
                                  fileSize: file.fileSize,
                                  name: file.originalFileName,
                                  extension: file.originalFileName.substr(file.originalFileName.lastIndexOf('.')+1, file.originalFileName.length),
                                  modified: file.modified,
                                  canBeDeleted: true,
                                  documentObjectId: this.commonUtilsService.createId()
                              }
                              that.concept.documentation.documents.push(newDocument);
                              that.concept.documentation.totalDocuments++;
                              that.attachmentList.push(newDocument);
                          });

                          let documents:any = {
                              type: 'addDocumentToNewConcept',
                              list: that.newDocuments
                          }
                          child.launchAction.emit(documents);
                      }else{
                          that.attachmentList = [...that.attachmentList, ...result.files];
                      }
                  }else if(addDocument === 'addNewDocumentsFromTasksNew'){
                      let newDocuments:any = cloneDeep(result.files);
                      result.files.forEach((fileAdded:any) => {
                          fileAdded.name = fileAdded.originalFileName;
                          fileAdded.extension = fileAdded.name.substr(fileAdded.name.lastIndexOf('.')+1, fileAdded.name.length);
                          fileAdded.canBeDeleted = true;
                          fileAdded.documentObjectId = this.commonUtilsService.createId();
                          let modified:any = new Date();
                          fileAdded.modified = modified.getTimezoneOffset() < 0? moment(modified.setMinutes(Math.abs(modified.getTimezoneOffset()) + modified.getMinutes())).toISOString() : moment(modified).toISOString();
                          if(that.concept){
                              fileAdded.sourceName = that.itemSelected.title;
                              fileAdded.source = 'Task';
                          }
                      })
                      that.attachmentList = [...that.attachmentList, ...result.files];
                      let documents:any = {
                          type: 'addDocumentToTaskNew',
                          list: newDocuments,
                          listModified: result.files
                      }
                      child.launchAction.emit(documents);
                  }else{
                      if(that.concept){
                          result.files.forEach((file:any) => {
                              file.sourceName = that.itemSelected.title;
                              file.source = 'Task';
                              that.attachmentList.push(file);
                              that.concept.documentation.documents.push(file);
                              that.concept.documentation.totalDocuments++;
                          })

                      }
                  }
                  if(that.itemSelected && (that.itemSelected.taskId) && result.comments !== ''){
                      let comment:any = {
                          type: 'addCommentToTask',
                          comments : result.comments
                      }
                      child.launchAction.emit(comment);
                  }
              }
          })
  }

  deleteDocument(document:any, text:string, child:any){
      let that: any = child.attachmentsTemplate;
      let type:string = child.isLanding? 'info:question:question-full' : 'info:question:question';
      this.modalService.adviceModal(text, type).subscribe((result:any)=>{
          if(result.result==='ok'){
              this.loader.openLoader('attachments-template-delete');
              if(that.itemSelected.conceptObjectId || that.itemSelected.taskId){
                  this.deleteDocumentSelected(document, child);
              }else if(that.itemSelected.conceptId || document.canBeDeleted){
                  this.deleteDocumentForList(document, child);
              }
          }
      })
  }

  deleteDocumentForList(documentDeleted:any, child:any){
      let that: any = child.attachmentsTemplate;
      that.attachmentList = this.commonUtilsService.deleteElement(documentDeleted, that.attachmentList, 'documentObjectId');
      that.newDocuments = this.commonUtilsService.deleteElement(documentDeleted, that.newDocuments, 'documentObjectId');
      let type:string = 'deleteDocumentToConceptNew';
      if(child.typeFrom === 'task'){
          type = 'deleteDocumentToTaskNew';
      }else{
          this.commonUtilsService.deleteDocumentToField(documentDeleted, that.concept);
          if(that.concept.fieldsWidthDocuments && that.concept.fieldsWidthDocuments.length){
              that.deleteDocumentForFieldsWidthDocuments(documentDeleted, child);
          }
      }
      let documents:any = {
          type: type,
          list: that.newDocuments,
          document: documentDeleted
      }
      child.launchAction.emit(documents);
  }

  deleteDocumentForFieldsWidthDocuments(documentDeleted:any, child:any){
      let that: any = child.attachmentsTemplate;
      for(let [index, container] of that.concept.fieldsWidthDocuments.entries()){
          for(let [indexJ, documentField] of container.documents.entries()){
              if(documentField.documentObjectId === documentDeleted.documentObjectId){
                  that.concept.fieldsWidthDocuments[index].documents.splice(indexJ, 1);
              }
          }
      }
  }

  deleteDocumentSelected(document:any, child:any) {
      let that: any = child.attachmentsTemplate;
      let url:string = 'documentobjects';
      let id:any = document.documentObjectId;
      if(that.itemSelected && that.itemSelected.taskId){
          url = 'tasks/' + that.itemSelected.taskId;
          id = document.documentObjectId + '/1';
          if (child.currentComponent === 'genericTask') {
              id = document.documentObjectId;
          }
      }
      this.apiService.delete(url, id)
          .then((data:any)=>{
              that.attachmentList = this.commonUtilsService.deleteElement( document, that.attachmentList, 'documentObjectId');
              if(that.concept && that.concept.documentation){
                  that.concept.documentation.documents = this.commonUtilsService.deleteElement( document,  that.concept.documentation.documents, 'documentObjectId');
                  that.concept.documentation.totalDocuments--;
              }
              if(!that.itemSelected.taskId){
                  this.commonUtilsService.deleteDocumentToField(document, that.concept);
              }
              this.loader.closeLoader(['attachments-template-delete']);
              this.modalService.adviceModal(this.translate.instant('common.the-document') +"'"+ document.name +"'"+ this.translate.instant('documentation-list.remove-document'), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-remove-document') +"'"+ document.name + "'", 'error:accept:warning')

          });
  }

  attachDocumentModal(child:any){
      this.modalService.attachDocumentModal(child.attachmentsTemplate.itemSelected, child.attachmentsTemplate.attachmentList)
          .subscribe((result:any)=>{
              if(result.result ==='ok'){
                  this.attachDocumentsToTask(result, child);
              }
          })
  }

  attachDocumentsToTask(result:any, child:any){
      this.loader.openLoader('attachment-document');
      child.attachmentsTemplate.comments = result.comments && result.comments !== ""? result.comments : '';
      result.action === 2? this.associatedDocuments(result.documents, child) : this.createNewDocument(result.documents, child);
  }

  associatedDocuments(documents:any, child:any) {
      this.apiService.add('tasks/associatedocuments/' + child.attachmentsTemplate.itemSelected.taskId, documents)
          .then((data:any)=>{
              this.getAttachmentsDocument(child, true);
              if(child.attachmentsTemplate.itemSelected && child.attachmentsTemplate.itemSelected.taskId && child.attachmentsTemplate.comments !== ''){
                  let comment:any = {
                      type: 'addCommentToTask',
                      comments : child.attachmentsTemplate.comments
                  }
                  child.launchAction.emit(comment);
              }
              this.loader.closeLoader(['attachment-document']);

          }, (errorData:any) => {
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('task-edit.add-documents-error')  +"'"+ child.attachmentsTemplate.itemSelected.title + "'", 'error:accept:warning')
          });
  }

  createNewDocument(documents:any, child:any) {
      child.attachmentsTemplate.url = documents[0].url;
      if (child.currentComponent === 'genericTask') {
          child.attachmentsTemplate.url = 'tasks/fromOfficeDocumentTemplate/' + documents[0].taskId + '/' + documents[0].fileId;
      }
      this.apiService.add(child.attachmentsTemplate.url, documents[0])
          .then((data:any) => {
              this.documentActions.doAction('open', data);
              this.documentUploaded(data, true, child);
          }, (errorData:any) => {
              this.loader.closeError()
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-document') +"'"+ documents[0].name + "'", 'error:accept:warning')
          });
  }

  documentUploaded(task:any, last:boolean, child:any) {
      let that: any = child.attachmentsTemplate;
      that.attachmentList.push(task);
      let text = that.itemSelected && that.itemSelected.taskId? this.translate.instant('task-edit.question-add-documents') : this.translate.instant('attach-document.add-more-documents');
      this.broadcastService.broadcast('addNew', {});
      this.openQuestionModal(text, child);
      if(that.itemSelected && that.itemSelected.taskId && that.comments !== ''){
          let comment = {
              type: 'addCommentToTask',
              comments : that.comments
          }
          child.launchAction.emit(comment);
      }
      this.loader.closeLoader(['attachment-document']);

  }


  maximizeScreen(keepClassBox:boolean, child:any){
      child.attachmentsTemplate.fullScreen = !child.attachmentsTemplate.fullScreen;
      if(!keepClassBox){
          child.catchFullScreen.emit({box:'attachments-templates'});
      }
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
