import { TaskEditParentService } from './../utils/task-edit-parent.service';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { cloneDeep } from 'lodash';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';

@Component({
  selector: 'task-edit-default',
  templateUrl: './task-edit-default.component.html'
})
export class TaskEditDefaultComponent implements OnChanges {

  currentComponent: string = this.route.snapshot.data['componentName'];
  isLanding:any = false;
  taskId:any = this.route.snapshot.params['taskId'];
  taskEdit: any = {
    fullScreenBox: '',
    typeModal: "confirm",
    typeStatesProcess: 'normalTask',
    taskSelect: {},
    temporalDocuments: [],
    attachmentListDocument: [],
    historicList: []
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private taskEditParent: TaskEditParentService) {

    }

  @Input() concept:any = {};
  @Input() taskSelect:any = {};
  @Input() shared:any = null;
  @Input() hasOpenEvaluationTask:any = null;
  @Input() field:any = null;
  @Input() taskTypeIdSelect:any = null;

  @Output() changeView = new EventEmitter();
  @Output() modifyTaskList = new EventEmitter();

  ngOnChanges(): void {
    this.taskEdit.concept = this.concept;
    if (this.taskSelect !== null && this.taskSelect !== undefined) {
      this.taskEdit.taskSelect = this.taskSelect;
      this.taskEdit.taskCopy = cloneDeep(this.taskSelect);
      this.taskEditParent.configureTaskSelect(this);
    }else{
      this.taskEditParent.configureNewTask(this);
    }
  }

  hasFullScreen(e:any){
    this.taskEdit.fullScreenBox = this.taskEdit.fullScreenBox === e.box? '' : e.box;
  }

  changeState(e:any){
    this.taskEditParent.changeState(e.task, this)
  }
  changeResponsible(e:any){
    this.taskEditParent.changeResponsible(e.responsible, this)
  }
  cancelCreateTask(){
    this.taskEditParent.cancelCreateTask(this)
  }
  createAlert(alert:any){
    this.taskEditParent.createAlert(alert, this)
  }

  launchAction(action:any){
    this.taskEditParent.launchAction(action, this)
  }
}
