import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep, map } from 'lodash';

@Component({
  selector: 'users-admin-new-wizard',
  templateUrl: './users-admin-new-wizard.component.html'
})
export class UsersAdminNewWizardComponent implements OnInit {
  type: string = this.route.snapshot.data['type'];
  id: string = this.route.snapshot.params['id'];
  operation: string = this.type === 'contact'?'convert':'new';
  usersAdminNewWizard: any = {
    controller: this.getControllerByType(this.type),
    icon: 'lf-icon-favorite',
    stepState: 1,
    title: '',
    model: {},
    wizardSteps: [
        { name: this.translate.instant('users-admin.basic-data') },
        { name: this.translate.instant('users-admin.permissions') }
    ],
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService) { }


  ngOnInit(): void {
    if(this.type === 'group'){
        this.usersAdminNewWizard.model = { members: [] };
    }else{
        if(this.operation === 'new'){
            this.usersAdminNewWizard.model = { groups: [] };
        }else{
            this.loadModel();
        }
    }
    this.usersAdminNewWizard.title = this.translate.instant('users-admin.'+ this.operation +'.title.' + this.type);
    if(this.operation !== 'convert'){
        this.usersAdminNewWizard.icon = this.type==='user'?'lf-icon-user-special':this.type==='contact'?'lf-icon-user':'lf-icon-group';
    }
  }

  loadModel() {
    this.apiService.get(this.usersAdminNewWizard.controller, this.id)
      .then((data:any)=>{
        this.usersAdminNewWizard.model = data;
      }, (error:any)=>{
        this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
      })
  }

  getControllerByType(type: any) {
    switch (type) {
        case 'user':
            return 'frontusers';
        case 'contact':
            return 'contacts';
        case 'group':
            return 'usersgroups';
        default:
            throw 'Unknow type ' + type;
    }
  }

  next(){
    if(this.type === 'group'){
      this.broadcastService.broadcast('checkGroupForm');
    }else{
      this.broadcastService.broadcast('checkPersonForm');
    }
  }

  changeStep(step: any){
    this.usersAdminNewWizard.stepState = step;
  }

  reset(){
    if(this.usersAdminNewWizard.stepState === 1){
      if(this.type === 'group'){
        this.broadcastService.broadcast('resetGroupForm');
      }else{
        this.broadcastService.broadcast('resetPersonForm');
      }
    }else if(this.usersAdminNewWizard.stepState === 2){
      this.broadcastService.broadcast('resetSecurityForm');
    }
  }

  saveController(){
    this.broadcastService.broadcast('savePerson');
  }

  save(security: any){
    if(security.any){
      let model = cloneDeep(this.usersAdminNewWizard.model);
      delete security.any;
      let controller = this.usersAdminNewWizard.controller;
      if(this.type !== 'group'){
          if(model.groups && model.groups.length){
              model.groups = map(this.usersAdminNewWizard.model.groups, function (groupId) {
                  return {
                      groupId: groupId
                  };
              });
          }
          model.userProducts = security.userProducts;
          model.userProductClients = security.userProductClients;
          if(this.operation === 'convert'){
              delete model.contactId;
              controller = 'frontusers/convert/contact/' + this.id;
          }
      }else{
          model.contacts = [];
          model.users = [];
          if(model.members && model.members.length){
              model.members.forEach((member:any) => {
                  if(member.userId !== undefined){
                      model.users.push(member);
                  }else{
                      model.contacts.push(member);
                  }
              });
          }
          delete model.members;
          model.usersGroupProducts = security.usersGroupProducts;
          model.usersGroupProductClients = security.usersGroupProductClients;
      }
      this.loader.openLoader('new-wizard-save');
      this.savePerson(controller, model);
    }else{
      this.modalService.adviceModal(this.translate.instant('users-admin.security-form.product-mandatory'), 'error:accept:warning');
    }
  }

  savePerson(controller: any, model: any){
    this.apiService.add(controller, model)
      .then((data:any)=>{
          this.loader.closeLoader(['new-wizard-save']);
          let messageLiteralKey = 'users-admin.' + this.operation + '.success.' + this.type;
          let message = this.translate.instant(messageLiteralKey);
          if(data && data.failedSubscriptions && data.failedSubscriptions.length){
              message += ' ' + this.translate.instant('users-admin.new.subscriptions-failed') + ' ' + data.failedSubscriptions.join();
          }
          this.modalService.adviceModal(message, 'info:accept:check-round')
            .subscribe((result:any)=>{
                this.router.navigate(['users']);
            })
      }, (error:any)=>{
        this.loader.closeError();
        let message : any = null;
        if(error.status === 409 && error.description === 'AlreadyExists'){
            message = this.translate.instant('users-admin.new.error-existing.' + (this.operation === 'new' ? this.type : 'user'));
        }else if(error.status === 409 && error.description === 'UserAnotherCompany'){
            message = this.translate.instant('users-admin.new.error-user-linked-another-company')
                .replace('{phone}', this.translate.instant('modal-help.tel-number-be'))
                .replace('{email}', this.translate.instantSiteTerms('mail'));
        }else if(error.status === 500 && error.description === 'NoLicenses'){
            message = this.translate.instant('users-admin.new.error-no-licenses');
        }else if(error.status === 400 && error.data && error.data.modelState && error.data.modelState['model.Email']){
            message = this.translate.instant('users-admin.import.email-invalid-error-field') + ' ("' + model.email + '"),' + this.translate.instant('users-admin.import.email-invalid-error-format');
        }else{
            message = this.translate.instant('common.unexpected-error');
        }
        this.modalService.adviceModal(message, 'error:accept:warning');
      })
  }
}
