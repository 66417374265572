import { Component, OnChanges, SimpleChanges, Output, Input, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-text-rich',
  templateUrl: './customize-field-text-rich.component.html'
})
export class CustomizeFieldTextRichComponent implements OnChanges {
  skin: any = this.globalCfg.skin;
  // tinymceOptions: any = null;
  customizeFieldTextRich: any = {
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    },
    voiceRecordInput:{
      label: this.translate.instant('customize.voice-record-accept-text')
    },
    tinyMceOptions: {
      toolbar: ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | blocks',
      height: 400
    },
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;

  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldTextRich;
    if(changes['field'] && changes['field'].currentValue){
      that.fieldCopy = cloneDeep(changes['field'].currentValue);
      that.field = changes['field'].currentValue;
      that.contentReportInfo.noEditable =  that.field.noEditable
      that.field.voiceRecordOk = that.field.isVoiceRecord
    }
    if(changes['concept'] && changes['concept'].currentValue){
      that.contentReportInfo.noEditable = this.concept.disabled;
      if(!changes['concept'].currentValue.communicationChannel){
        that.field.voiceRecordOk = false;
        that.field.voiceRecordOkText = '';
        that.field.isVoiceRecord = false;
      }
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  getEditorValue(event:any){
    this.customizeFieldTextRich.field.description = event.model;
  }

  itemSelected(e:any, item:any){
      this.customizeFieldTextRich.field[item] = e.newValue;
      this.modifyField();
  }

  cancelFieldText(){
    this.customizeFieldTextRich.field = cloneDeep(this.customizeFieldTextRich.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldTextRich.field
        }
    })
  }

  saveFieldText(){
    if(this.validateSave()){
      this.customizeFieldTextRich.fieldCopy = cloneDeep(this.customizeFieldTextRich.field);
      this.changeInField.emit({
          change:{
              type: 'save',
              field: this.customizeFieldTextRich.field
          }
      })
    }

  }
  validateSave(){
    if(this.customizeFieldTextRich.field.voiceRecordOk && (this.customizeFieldTextRich.field.voiceRecordOkText === null || this.customizeFieldTextRich.field.voiceRecordOkText === '')){
      this.customizeFieldTextRich.showError = true;
      this.customizeFieldTextRich.voiceRecordInput.error = true;
      return false;
    }else{
      this.customizeFieldTextRich.showError = false;
      this.customizeFieldTextRich.voiceRecordInput.error = false;
      return true;
    }
  }



  openVisibility(e:any){
    this.customizeFieldTextRich.field.visibilityOr = e.visibilityOr;
    this.customizeFieldTextRich.field.openBlock = e.openBlock;
  }

  modifyField() {
      let field : any = this.customizeFieldTextRich.field;
      let text: string = field.isEditable?'isHighlight':'required'
      field[text]= false
      this.conceptState.add(field);
  }

  changeOptionOn(e:any, type:string){
    switch(type){
      case 'isVoiceRecord':
        this.customizeFieldTextRich.field.voiceRecordOk = e.model;
        this.customizeFieldTextRich.field.voiceRecordOkText = e.model? this.translate.instant('record-audio-modal.check-transcription'): null;
        this.customizeFieldTextRich.field[type] = e.model
        break;
      case 'voiceRecordOk':
        this.customizeFieldTextRich.field.voiceRecordOkText = e.model? this.translate.instant('record-audio-modal.check-transcription'): null;
        this.customizeFieldTextRich.field[type] = e.model
        break;
      case 'voiceRecordOkText':
        this.customizeFieldTextRich.field.voiceRecordOkText = e.newValue ===''? null: e.newValue;
        break;
      default:
        this.customizeFieldTextRich.field[type] = e.model
        break;
    }
    this.modifyField();
  }
}
