import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'view-extended-report',
  templateUrl: './view-extended-report.component.html'
})

export class ViewExtendedReportComponent {
  isModal: boolean=true;
  constructor(private activeModal: NgbActiveModal) { }
  @Input() selectedField: any = null;

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {
    this.activeModal.close({
        result: 'ok'
      });
  };

}
