<div class="evaluations-list">
  <div class="common__header-new-version"
      [ngClass]="{'common__header-new-version--deleted': evaluationsList.view === 'deleted'}">
      <i class="lf-icon-label-validate common__header-icon-new-version"></i>
      <span class="common__header-title-new-version">{{evaluationsList.title}}</span>
      <div class="common__options-block"
          ngbDropdown
          isOpen="evaluationsList.showList">
          <button class="common__selected-option"
            ngbDropdownToggle>
              <span class="evaluations-list__select-language span__auto-ajust">{{evaluationsList.selectedView?.name}}</span>
              <i class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
          </button>
          <div class="common__menu-block-dropdown dropdown-menu"
              ngbDropdownMenu
              role="menu">
              <div class="common__block-option-list common__block-option-list">
                  <div class="common__language-item common__language-item"
                      *ngFor="let view of evaluationsList.evaluationView"
                      (click)="changeView(view.viewOption)">
                      <span-tooltip
                          [placement]="'above'"
                          [text]="view.name">
                        </span-tooltip>
                  </div>
              </div>
          </div>
      </div>
      <button class="lf-icon-add-round common__header-button-add-new-version"
          [ngShow]="canCreate"
          (click)="createNewEvaluation()"
          matTooltip="{{'new-evaluation.new-implantation' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left">
      </button>
      <button class="lf-icon-send common__header-button-add-new-version common__header-button-add-new-version--more-icons"
          [ngShow]="productSelected?.productId === 'blanqueo' && canCreate"
          (click)="exportList()"
          matTooltip="{{'evaluations-list.export-full-list' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left">
      </button>
  </div>

  <div class="evaluations-list__block-alert-remove"
    [ngShow]="evaluationsList.view === 'deleted'">
    <span [afTranslate]="'evaluations-list.message-alert-deleted'">_Las implantaciones que lleven más de 30 días en la papelera se eliminarán automáticamente.</span>
  </div>
  <div class="common__table-block">
    <div class="common__table-new-version"
      *ngIf="productSelected?.productId !== 'blanqueo'">
      <div class="common__table-header">
          <div class="evaluations-list__column evaluations-list__column--shared evaluations-list__column--first"
              [ngShow]="dashboardVersion === 1 && evaluationsList.view === 'shared'">
              <p class="common__table-header-text"
                  [afTranslate]="'common.participant'">_Destinatario</p>
          </div>
          <div class="evaluations-list__column evaluations-list__column--name"
              [ngClass]="{'evaluations-list__column--first': evaluationsList.view !== 'shared'}">
              <p class="common__table-header-text"
                  [afTranslate]="'common.identifier'">_Identificador</p>
          </div>
          <div class="evaluations-list__column evaluations-list__column--client">
              <p class="common__table-header-text"
                  [afTranslate]="'common.organization'">_Organización</p>
          </div>
          <div class="evaluations-list__column evaluations-list__column--complaints"
              *ngIf="dashboardVersion === 2 || productSelected?.productId ==='denunc'">
              <p class="common__table-header-text"
                  [afTranslate]="'evaluations-list.complaints-number'">_Nº de denuncias</p>
          </div>
          <div class="evaluations-list__column evaluations-list__column--complaints"
              [ngShow]="dashboardVersion === 1 && evaluationsList.view !== 'shared' && productSelected?.productId !=='denunc'">
              <p class="common__table-header-text"
                  [ngShow]="evaluationsList.view === 'all'"
                  [afTranslate]="'common.state'">_Estado</p>
              <p class="common__table-header-text"
                  [ngShow]="evaluationsList.view === 'shared'"
                  [afTranslate]="'common.participant'">_Destinatario</p>
              <p class="common__table-header-text"
                  [ngShow]="evaluationsList.view === 'deleted'"
                  [afTranslate]="'evaluations-list.delete-date'">_Fecha eliminación</p>
          </div>
          <div class="evaluations-list__column evaluations-list__column--actions">
              <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">_Acciones</p>
          </div>
      </div>
      <div class="common__table-body"
          [ngClass]="{'common__table-body--14-elements': skin !== 'com-fr',
            'common__table-body--more-elements': (evaluationsList.evaluationsListFilter?.length > 14 && skin !== 'com-fr') || (evaluationsList.evaluationsListFilter?.length > 5 && skin === 'com-fr')}">
          <div class="common__table-row"
              *ngFor="let evaluation of evaluationsList.evaluationsFilter | byWord:evaluationsList.searchEvaluation:['name', 'productName', 'clientName'] | afOrderBy:['productName','name']:false | afLimitTo:evaluationsList.configPagination.pageSize:evaluationsList.configPagination.pageSize*(evaluationsList.currentPage - 1); let i = index">
              <div class="evaluations-list__column evaluations-list__column--shared evaluations-list__column--first evaluations-list__column--tooltip"
                  [ngShow]="evaluationsList.view === 'shared' && dashboardVersion === 1">
                  <participants-list *ngIf="evaluation.shared && evaluation.shared.length"
                      [participants]="evaluation.shared"
                      [type]="'evaluation'">
                  </participants-list>
              </div>
              <div class="evaluations-list__column evaluations-list__column--name evaluations-list__column--tooltip"
                  [ngClass]="{'evaluations-list__column--first': evaluationsList.view !== 'shared',
                      'common__cursor-pointer': evaluationsList.view !== 'deleted'}"
                  (click)="editOption($event, evaluation)">
                  <parragraph-tooltip [text]="evaluation.name"></parragraph-tooltip>
              </div>
              <div class="evaluations-list__column evaluations-list__column--client evaluations-list__column--tooltip">
                  <parragraph-tooltip [text]="evaluation.clientName"></parragraph-tooltip>
              </div>
              <div class="evaluations-list__column evaluations-list__column--complaints-body evaluations-list__column--tooltip"
                  [ngShow]="dashboardVersion === 2 || productSelected?.productId === 'denunc'">
                  <parragraph-tooltip [text]="evaluation.complaints"></parragraph-tooltip>
              </div>
              <div class="evaluations-list__column evaluations-list__column--state"
                  [ngShow]="evaluationsList.view === 'all' && dashboardVersion === 1 && productSelected?.productId !=='denunc'">
                  <div class="evaluations-list__progress-complete">{{evaluation.progress}}%</div>
                  <div [class]="'evaluations-list__state-col evaluations-list__state-col--color-'+evaluation.status">
                      <i class="lf-icon-information evaluations-list__status-icon"
                          [ngShow]="evaluation.risk"></i>
                      <i class="lf-icon-bookmarks-active evaluations-list__status-icon"
                          [ngShow]="evaluation.normChange"></i>
                  </div>
              </div>
              <div class="evaluations-list__column evaluations-list__column--state"
                  [ngShow]="evaluationsList.view === 'deleted' && dashboardVersion === 1 && productSelected?.productId !=='denunc'">
                  <parragraph-tooltip [text]="evaluation.deletionDate | convertDate: 'without-time'"></parragraph-tooltip>
              </div>
              <div class="evaluations-list__column"
                [ngClass]="evaluationsList.view === 'shared'?'evaluations-list__column--actions':'evaluations-list__column--actions-body'">
                  <ul class="common__options-row">
                      <li class="common__menu-options-row"
                          [ngClass]="{'common__menu-options-row--view': !canCreate || (!evaluation.canModify && !evaluation.canManage)}"
                          *ngIf="evaluationsList.view !== 'deleted'"
                          matTooltip="{{(canCreate && (evaluation.canModify || evaluation.canManage)?'common.edit':'common.view') | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i [ngClass]="canCreate && (evaluation.canModify || evaluation.canManage)?'lf-icon-editable-form':'lf-icon-visible'"
                            (click)="editOption($event, evaluation)"></i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="dashboardVersion === 1 && evaluation.canManage && !isConcursalFormsModal && evaluationsList.view !== 'deleted' && evaluation.productId !== 'bpt002'"
                          (click)="shareEvaluation(evaluation)"
                          matTooltip="{{'common.share' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i class="lf-icon-share"></i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="evaluation.canManage && !isConcursalFormsModal && evaluationsList.view !== 'deleted' && evaluation.productId !== 'denunc'"
                          (click)="duplicateEvaluation(evaluation)"
                          matTooltip="{{'common.duplicate' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i class="lf-icon-paste"></i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="evaluation.canManage && evaluationsList.view !== 'deleted'"
                          (click)="rename(evaluation)"
                          matTooltip="{{'expanded-menu.rename' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i class="lf-icon-rename"></i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="evaluation.canManage && evaluationsList.view === 'deleted'"
                          (click)="restoreEvaluation(evaluation)"
                          matTooltip="{{'common.restore' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i class="lf-icon-rotate"></i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="evaluation.canManage"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                          <i class="lf-icon-trash"
                            (click)="deleteEvaluation(evaluation)"></i>
                      </li>
                  </ul>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
              </div>
          </div>
      </div>
    </div>
    <div class="common__table-new-version"
      *ngIf="productSelected?.productId === 'blanqueo'">
      <div class="common__table-header">
          <div class="evaluations-list__column evaluations-list__column--client-antiblanqueo evaluations-list__column--first evaluations-list__column--top-1">
              <filter-by-list [type]="'listFind'"
                  [item]="evaluationsList.clientNameFilter"
                  [widthClass]="'200px'"
                  [list]="evaluationsList.clientNameList"
                  (filterElementEmit)="selectTypeOperation($event, 'clientName')">
              </filter-by-list>
          </div>
          <div class="evaluations-list__column evaluations-list__column--name-antiblanqueo evaluations-list__column--top-1">
              <filter-by-search
                  [item]="evaluationsList.nameFilter"
                  (launchActionSearch)="filterSearch($event, 'name')">
              </filter-by-search>
          </div>
          <div class="evaluations-list__column evaluations-list__column--result-antiblanqueo evaluations-list__column--top-1">
              <filter-by-list [type]="'list'"
                  [item]="evaluationsList.resultFilter"
                  [widthClass]="'200px'"
                  [list]="evaluationsList.resultList"
                  (filterElementEmit)="selectTypeOperation($event, 'result')">
              </filter-by-list>
          </div>
          <div class="evaluations-list__column evaluations-list__column--icon-antiblanqueo"></div>
          <div class="evaluations-list__column evaluations-list__column--modification-antiblanqueo evaluations-list__column--top-1">
              <div class="common__cell-block">
                  <p id="evaluation-list-modification-header-0"
                      class="common__table-header-text common__table-header-text--ellipsis common__cursor-pointer"
                      matTooltip="{{'common.modification' | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipDisabled]="showTooltip('evaluation-list-modification-header-0')"
                      (click)="searchBy('modificationDate')">
                      <span id="span-evaluation-list-modification-header-0"
                          [afTranslate]="'common.modification'">_Modificación</span>
                  </p>
                  <i class="common__order-by"
                      [ngClass]="{'lf-icon-arrow-down':(evaluationsList.dateFilter==='modified' && evaluationsList.orderOperationDates) || evaluationsList.dateFilter!=='modified',
                          'lf-icon-arrow-up': evaluationsList.dateFilter==='modified' && !evaluationsList.orderOperationDates,
                          'common__order-by--active': evaluationsList.dateFilter==='modified'}"
                          (click)="searchBy('modified')"></i>
              </div>
          </div>
          <div class="evaluations-list__column evaluations-list__column--actions-antiblanqueo">
              <p class="common__table-header-text common__table-header-text--right"
                  [afTranslate]="'common.actions'">_Acciones</p>
          </div>
      </div>
      <div class="common__table-body common__table-body--14-elements"
          [ngClass]="{'common__table-body--more-elements': evaluationsList.evaluationsListFilter?.length > 14}">
          <div *ngIf="evaluationsList.view === 'groupClients'">
              <div class="evaluations-list__table-row-client-blanqueo"
                  *ngFor="let client of evaluationsList.clientsListFilter | byWordChildren:evaluationsList.searchEvaluation:evaluationsList.searchWord:'evaluations' | afOrderBy:['clientName']:false">
                  <div class="evaluations-list__row-client-blanqueo">
                      <div class="evaluations-list__column evaluations-list__column--client-list-antiblanqueo">
                          <button class="evaluations-list__icon-angle-button"
                              [ngClass]="client.expanded?'lf-icon-angle-up':'lf-icon-angle-down'"
                              (click)="client.expanded = !client.expanded">
                          </button>
                          <parragraph-tooltip [text]="client.clientName"
                              [cursor]="true"
                              class="common__cell-block evaluations-list__cell-block-blanqueo"></parragraph-tooltip>
                          <span class="evaluations-list__block-bubble">{{client.evaluations?.length}}</span>
                      </div>
                  </div>
                  <evaluation-blanqueo-row [ngShow]="client.expanded"
                    [evaluationsListFilter]="evaluationsList.evaluationsListFilter"
                    [view]="evaluationsList.view"
                    [client]="client"
                    [canCreate]="canCreate"
                    [configurationPdf]="evaluationsList.configurationPdf"
                    [searchEvaluation]="evaluationsList.searchEvaluation"
                    [searchWord]="evaluationsList.searchWord"
                    [dateFilter]="evaluationsList.dateFilter"
                    [orderOperationDates]="evaluationsList.orderOperationDates"
                    (emitEventRow)="emitEventFromRow($event)"></evaluation-blanqueo-row>
              </div>
          </div>
          <evaluation-blanqueo-row *ngIf="evaluationsList.view !== 'groupClients'"
              [evaluationsListFilter]="evaluationsList.evaluationsListFilter"
              [view]="evaluationsList.view"
              [canCreate]="canCreate"
              [configurationPdf]="evaluationsList.configurationPdf"
              [searchEvaluation]="evaluationsList.searchEvaluation"
              [searchWord]="evaluationsList.searchWord"
              [dateFilter]="evaluationsList.dateFilter"
              [orderOperationDates]="evaluationsList.orderOperationDates"
              (emitEventRow)="emitEventFromRow($event)"></evaluation-blanqueo-row>
      </div>
    </div>
  </div>
  <div class="common__block-footer-table">
      <div class="common__block-search">
          <input type="text"
              [ngShow]="evaluationsList.search"
              class="common__search-input"
              [(ngModel)]="evaluationsList.searchEvaluation"
              placeholder="{{'common.search' | afTranslate}}"
              [focusWhen]="evaluationsList.search">
          <span class="common__text-search"
              [ngShow]="!evaluationsList.search"
              (click)="toggleSearch()"
              [afTranslate]="'common.search'">_Buscar</span>
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !evaluationsList.search,
                  'lf-icon-close-search': evaluationsList.search,
                  'common__icon-button-search--open': evaluationsList.search}"
              (click)="toggleSearch()">
          </button>
      </div>
      <pagination-control class="common__pagination-block"
        [ngShow]="evaluationsList.evaluationsFilter?.length > evaluationsList.configPagination.pageSize && !evaluationsList.search"
        [config]="evaluationsList.configPagination"
        [collectionSize]="evaluationsList.evaluationsFilter?.length"
        [(currentPage)]="evaluationsList.currentPage"
        (configuredTotalChange)="evaluationsList.configuredTotal = $event.configuredTotal"></pagination-control>
      <div class="common__block-footer-total">
          <span [ngShow]="evaluationsList.evaluationsFilter?.length > evaluationsList.configPagination.pageSize && evaluationsList.view !== 'groupClients'">
            <span class="notranslate">{{evaluationsList.configuredTotal.first}}-{{evaluationsList.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{evaluationsList.evaluationsFilter?.length}}</span>
          </span>
          <span [ngShow]="evaluationsList.filterClientList?.length > evaluationsList.configPagination.pageSize && evaluationsList.view == 'groupClients'">
            <span class="notranslate">{{evaluationsList.configuredTotal.first}}-{{evaluationsList.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{evaluationsList.evaluationsFilter?.length}}</span>
          </span>
          <span [ngShow]="!(evaluationsList.evaluationsFilter?.length > evaluationsList.configPagination.pageSize && evaluationsList.view !== 'groupClients') && !(evaluationsList.filterClientList?.length >  evaluationsList.configPagination.pageSize && evaluationsList.view == 'groupClients')">
            <span [afTranslate]="'common.total'">_Total</span>
            <span class="common__total-number-element notranslate"
                *ngIf="evaluationsList.view !== 'groupClients'">{{evaluationsList.evaluationsFilter?.length}}</span>
            <span class="common__total-number-element notranslate"
                *ngIf="evaluationsList.view === 'groupClients'">{{evaluationsList.filterClientList?.length}}</span>
          </span>

        </div>
  </div>
</div>
