import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'switch-option',
  templateUrl: './switch-option.component.html'
})
export class SwitchOptionComponent implements OnChanges {
  switchOption: any = {
    id: this.commonUtilsService.createId(),
  }

  constructor(private commonUtilsService: CommonUtilsService) { }
  @Input() tooltipText: string = '';
  @Input() model: any = null;
  @Input() disabled: boolean = false;
  @Input() disabledClass: any = null;
  @Input() fieldText: string = '';
  @Input() checked: any = null;

  @Output() changeOptionEmit = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.switchOption.model !== this.model) {
      this.switchOption.model = this.model;
    }
    // if(changes['model'] && changes['model'].currentValue){
    //   this.switchOption.model = changes['model'].currentValue;
    // }
    if(this.checked === null){
      this.switchOption.checked = this.switchOption.model;
    }
    if(this.disabledClass === null){
      this.switchOption.disabledClass = this.disabled;
    }
  }
  changeModel(newValue:boolean){
    this.switchOption.model = newValue;
    this.changeOptionEmit.emit({model:this.switchOption.model})
  }
}
