
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { PrintService } from '@shared/services/print/print.service';

@Component({
  selector: 'notification-modal',
  templateUrl: './notification-modal.component.html'
})
export class NotificationModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  isLanding: boolean = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  notificationPreview: any = {
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal,
    private print: PrintService,
    private customLanding: CustomLandingFactory,
    private sanitizer: DomSanitizer) { }
  @Input() notification:any = null;

  ngOnInit(): void {
    this.getNotificationInfo()
  }

  getNotificationInfo(){
    this.apiService.get('notifications/public-alert-process/notification', this.notification.alertNotificationId)
    .then((data:any)=>{
        this.notificationPreview.notificationBody = this.sanitizer.bypassSecurityTrustHtml(data.customBody);
    }, ()=>{})
  }

  printAction(){
    this.print.printContent('print-notification-modal', this.notification.name);
  }

  handleAccept(newAlert:any) {
    this.activeModal.close({result: 'ok'});
  };

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };
}
