import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';

@Component({
  selector: 'document-historic',
  templateUrl: './document-historic.component.html'
})
export class DocumentHistoricComponent implements OnInit {
  documentHistoric: any = {
    orderOperationDates: true,
    elementValue: '',
    userLogin: this.context.user,
    filters: ['userName', 'action'],
    userNameFilter: {
      name: this.translate.instant('common.user')
    },
    actionFilter: {
      name: this.translate.instant('common.action')
    },
    historicList: []

  }
  constructor(private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private context: ContextFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private filterColumnService: FilterColumnService) { }
  @Input() document: any = null;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  ngOnInit(): void {
    this.getHistoric();
  }

  getHistoric(){
      this.apiService.get('documentobjects/historic', this.document.documentObjectId)
      .then((data:any)=>{
          if(data?.length){
            data.forEach((actionHistoric:any)=>{
              if(actionHistoric.participants){
                this.documentHistoric.type = 'share-document';
                if(actionHistoric.actionId === 8 || actionHistoric.actionId === 9){
                  this.documentHistoric.type = 'confidential-historic';
                }

                actionHistoric.participantsList = [];
                if(actionHistoric.participants.indexOf('|') !== -1){
                    let participantsList:any = actionHistoric.participants.split('|');
                    this.configureParticipantList(participantsList, actionHistoric)
                }else{
                    let participant:any = {
                        fullName: actionHistoric.participants,
                        id: this.commonUtilsService.createId()
                    }
                    actionHistoric.participantsList.push(participant);
                }
              }
            })
          }else{
            data = [];
          }

          this.documentHistoric.historicList = data;
          this.documentHistoric.filterHistoricList = data;
          this.getFilters();
      }, function (){

      });
  }

  configureParticipantList(list:any, actionHistoric:any){
    list.forEach((item:any) => {
      actionHistoric.participantsList.push({
        fullName: item,
        id: this.commonUtilsService.createId()
      });
    });
  }

  getFilters(){
    let that: any = this.documentHistoric;
    that.filters.forEach((filter:any) => {
      that[filter+'List'] = this.filterColumnService.extractList(filter,  that.historicList);
      that[filter+'ListFilter'] = [];
      if(filter === 'userName'){
          for(let filterList of that[filter+'List']){
              if(filterList.name.trim().toLowerCase() === that.userLogin.fullName.trim().toLowerCase()){
                  that.showMyOption = {
                      visible: true,
                      name: filterList.name,
                      selected: false
                  };
                  break;
              }
          }
      }
    });
  }

  selectTypeOperation(item:any, type:string){
      let that: any = this.documentHistoric;
      let listFilter = that[type+'ListFilter'];
      that[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      that[type+'Filter'].hasFilter = that[type+'ListFilter'].length;
      this.filterHistoric();
  }

  filterHistoric(){
      let that: any = this.documentHistoric;
      let filterList:any = [];
      filterList = this.filterColumnService.filterByWord(that.elementValue,  that.historicList, 'userName');
      that.filters.forEach((filter:any) => {
        let filteredList = that[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });

      that.filterHistoricList = filterList;
  }

  orderDate(){
      this.documentHistoric.orderOperationDates = !this.documentHistoric.orderOperationDates;
  }

  closeDropdown(){
    this.dropdown.close();
  }

  handleDismiss() {
      this.activeModal.close({
          result: 'ok'
      });
  };

}
