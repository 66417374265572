import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RappidComponent } from '@shared/components/rappid/rappid.component';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { defaultsDeep } from 'lodash';

@Component({
  selector: 'process-edit',
  templateUrl: './process-edit.component.html'
})
export class ProcessEditComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  evaluationIdRoute: any;
  processEdit: any = {
    description: '',
    showInfo: true,
    showMoreInfo: false,
    showHelp: true,
    designing: true,
    itemTypes: {
        icons: {
            "process": 'lf-icon-process',
            "app.State": 'lf-icon-process-step',
            "app.Link": 'lf-icon-transition'
        },
        titles: {},
        placeholders: {}
    },
    titleField: {
        label: null,
        id: 'title-property',
        required: false,
        description: null,
        multiline: false,
        maxLength: 50
    },
    tinyMceOptions: {
      toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
      placeholder:''
    },
    isNew: true,
    alert: null,
    process: {
      type: 'process',
      title: '',
      description:''
    },
    selection: {
      type: 'process'
    },
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory,
    private router: ActivatedRoute,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private modalService: ModalServiceService) { }
  @Input() concept: any = null;
  @Input() evaluationId: any = null;
  @Input() modal: any = null;

  @Output() onUpdate = new EventEmitter();
  @ViewChild(RappidComponent)
  rappid: RappidComponent;



  ngOnInit(): void {
    let that: any = this.processEdit;
    this.evaluationIdRoute = this.evaluationId? this.evaluationId: this.router.snapshot.params['evaluationId'];
    that.itemTypes.titles['process'] = this.translate.instant('process.edit.properties.process-title');
    that.itemTypes.titles['app.State'] = this.translate.instant('result-search.states');
    that.itemTypes.titles['app.Link'] = this.translate.instant('process.edit.properties.transition-title');
    that.itemTypes.placeholders['process'] = this.translate.instant('process.edit.properties.process-description-placeholder');
    that.itemTypes.placeholders['app.State'] = this.translate.instant('process.edit.properties.step-description-placeholder');
    that.itemTypes.placeholders['app.Link'] = this.translate.instant('process.edit.properties.transition-description-placeholder');
    that.titleField.label = this.translate.instant('process.edit.properties.title-label');
    this.processEdit.tinyMceOptions.placeholder = this.processEdit.itemTypes.placeholders['process'];
    this.loadProcess();
  }


  onCellActionOn(e:any) {
    if(e.null){
      this.checkChanges();
    }
    let cell: any = e.cell;
    let action: any = e.action
      if (action === 'selection') {
        if (cell && !cell.length) {
            let data:any = cell.attributes.compliance;
            if(data.archiveConceptObject === undefined){
              data.archiveConceptObject = false
            }
            this.processEdit.selection = {
                type: cell.get('type'),
                description: data ? data.description : '',
                cell: cell
            };
            switch (this.processEdit.selection.type){
                case 'app.State':
                  this.processEdit.selection.title = cell.attr('text/tspan/text');
                  this.processEdit.selection.description = cell.attributes.compliance.description;
                  break;
                case 'app.Link':
                  this.processEdit.selection.title = cell.attr('text/text');
                  this.processEdit.selection.description = cell.attributes.compliance.description;
                  break;
            }
        } else {
            this.processEdit.selection = this.processEdit.process;
        }
        this.checkPlacehoder();
      }
  }

  checkChanges(){
    this.titleChanged(this.processEdit.selection.title);
    this.descriptionChanged(this.processEdit.selection.description);
  }

  titleChanged(newValue:any) {
    let selection: any = this.processEdit.selection;
    switch (selection.type) {
        case 'process':
            if (selection.title !== newValue){
              this.processEdit.process.title = newValue;
            }
            break;
        case 'app.State':
            if (selection.cell.attr('text/tspan/text') !== newValue){
              selection.cell.attr('text/tspan/text', newValue);
              $('#'+selection.cell.attr('container/id')+' text tspan').html(newValue);
            }
            break;
        case 'app.Link':
            if (selection.cell.attr('text/text') !== newValue){
                selection.cell.attr('text/text', newValue);
            }
            break;
    }

    this.conceptState.setProcess(true)
  }

  descriptionChanged(value:any) {
    let selection: any = this.processEdit.selection;
    switch (selection.type) {
        case 'process':
            if (selection.description !== value){
              this.processEdit.process.description = value;
            }
            break;
        case 'app.State':
        case 'app.Link':
            if (selection.cell.attributes.compliance.description !== value){
              selection.cell.attributes.compliance.description = value;
            }
            break;
    }
    this.conceptState.setProcess(true)
  }

  loadProcess() {
      this.apiService.get('processes', this.evaluationIdRoute + '/' + this.concept.conceptId)
        .then((data:any)=>{
         this.loadProcessOk(data);
        }, (errorData:any)=>{
          this.processEdit.alert = {
            text: this.translate.instant('process.edit.load-error'),
            isError: true
          };
          this.loader.closeLoader(['charge-process-concept-list']);
        })
  }

  loadProcessOk(data:any){
    if (data && data.processId) {
      this.processEdit.process.processId = data.processId;
      this.processEdit.process.title = data.title;
      this.processEdit.process.description = data.description;
      this.processEdit.isNew = false;
      this.processEdit.showHelp = false;
      this.conceptState.setProcess(false)
    }
    if (Boolean(data.states.length)) {
      let elements = this.getElements(data.states);
      let links = this.getLinks(data.transitions);
      this.processEdit.model = { cells: [...elements, ...links] };
    }
    this.processEdit.selection =  this.processEdit.process;
    this.loader.closeLoader(['charge-process-concept-list']);
    this.checkPlacehoder();
  }

  getElements(states:any){
    let statesArray: any = [];
    states.forEach((element:any) => {
      statesArray.push(this.mapStateToCell(element))
    });
    return statesArray;
  }

  getLinks(links:any){
    let linksArray: any = [];
    links.forEach((link:any) => {
      linksArray.push(this.mapStateToLink(link))
    });
    return linksArray;
  }

  mapStateToCell(state:any) {
      let cell = JSON.parse(state.diagramModel);
      defaultsDeep(cell, {
          attrs: {
              text: {
                  tspan: { text: state.name }
              },
              ".": {
                  source: state.initial
              },
              archiveConceptObject: state.archiveConceptObject
          }
      });
      cell.compliance = {
          processId: state.processId,
          stateId: state.stateId,
          description: state.description,
          published: state.published,
          references: state.references,
          name: state.name,
          archiveConceptObject: state.archiveConceptObject
      };
      cell.cantRemove = state.published;
      if(cell.compliance.archiveConceptObject){
        this.processEdit.hasArchivedSelect = true;
      }

      return cell;
  }

  mapStateToLink(link:any) {
      let cell = JSON.parse(link.diagramModel);
      cell.cantRelink = cell.cantRemove = link.published;
      if (link.label) {
        defaultsDeep(cell, {
          attrs: { text: { text: link.label } },
          labels: [{ attrs: { text: { text: link.label } } }]
        });
      }
      cell.compliance = {
        transitionId: link.transitionId,
        description: link.description,
        published: link.published,
        references: link.references
      };
      return cell;
  }

  checkPlacehoder(value:any = null) {
      this.processEdit.titleField.defaultValue = (value || this.processEdit.selection.description) ? null : this.processEdit.itemTypes.placeholders[this.processEdit.selection.type];
  }

  saveProcess() {
    let that: any = this.processEdit
    this.loader.openLoader('process-edit-save');
    let model:any = this.rappid.toJSON();
    let mappedModel:any = this.mapModel(model);
    if (!mappedModel.error) {
        let process:any = {
            processId: that.process.processId,
            title: that.process.title,
            description: that.process.description,
            states: mappedModel.states,
            transitions: mappedModel.transitions
        };
        that.alert = null;
        if(that.process.processId){
          this.apiService.update('processes', this.evaluationIdRoute, process)
          .then((data:any)=>{
            this.loadProcessOk(data);
            that.alert = {
                text: this.translate.instant('process.edit.saved-ok')
            };
            this.loader.closeLoader(['process-edit-save']);
            this.onUpdate.emit({ data: data });
          }, (errorData:any)=>{
            that.alert = {
              text: this.translate.instant('process.edit.save-error'),
              isError: true
            };
            this.loader.closeLoader(['process-edit-save']);
          })
        }else{
          this.apiService.add('processes/' + this.evaluationIdRoute + '/' + this.concept.conceptId, process)
          .then((data:any)=>{
            this.loadProcessOk(data);
            that.alert = {
                text: this.translate.instant('process.edit.saved-ok')
            };
            this.loader.closeLoader(['process-edit-save']);
            this.onUpdate.emit({ data: data });
          }, (errorData:any)=>{
            that.alert = {
              text: this.translate.instant('process.edit.save-error'),
              isError: true
            };
            this.loader.closeLoader(['process-edit-save']);
          })
        }


    } else {
        that.alert = {
            text: mappedModel.error,
            isError: true
        };
        this.loader.closeLoader(['process-edit-save']);
    }

  }

  mapModel(model:any) {
    this.processEdit.newIdx = -100000;
    let result:any = {
        states: [],
        transitions: []
    };
    let statesById:any = {};
    let sourceState:any = null;
    let appStateCount:any = 0;
    for (let i = 0; i < model.cells.length; i++) {
      let cell:any = model.cells[i];
      if (cell.type === 'app.State') {
          appStateCount++;
          let state:any = this.mapCellToState(cell, statesById, i===0);
          if (state.initial) {
              if (sourceState) {
                  result.error = this.translate.instant('process.edit.roots-error');
                  break;
              } else {
                  sourceState = state;
              }
          }
          result.states.push(state);
      } else if (cell.type === 'app.Link' || cell.type === 'standard.Link') {
          if (!(cell.source && cell.source.id && cell.target && cell.target.id)) {
              result.error = this.translate.instant('process.edit.unconnected-link-error');
              break;
          }
          let transition:any = this.mapCellToTransition(cell, statesById);
          result.transitions.push(transition);
      } else {
          console.warn('Unknown cell type ' + cell.type);
      }
    }
    if (!result.error && !sourceState && result.states.length > 0) {
        result.error = this.translate.instant('process.edit.no-roots-error');
    }
    if(appStateCount < 2){
        result.error = this.translate.instant('process.edit.only-one-state');
    }
    return result;
  }

  mapCellToState(element:any, statesById:any, initial: boolean = false) {
    let state:any = {
        processId: element.compliance.processId,
        name: element.attrs.text.tspan.text,
        description: element.compliance.description,
        references: element.compliance.references,
        published: element.compliance.published,
        archiveConceptObject: element.compliance.archiveConceptObject
    };
    state.stateId = Boolean(element.compliance?.stateId)?element.compliance.stateId : this.processEdit.newIdx++;
    state.initial = initial;
    delete element.attrs;
    delete element.compliance;
    state.diagramModel = JSON.stringify(element);
    statesById[element.id] = state;
    return state;
  }

  mapCellToTransition(link:any, statesById:any) {
      let transition: any = {
          sourceId: statesById[link.source.id].stateId,
          targetId: statesById[link.target.id].stateId,
          description: link.compliance.description,
          references: link.compliance.references,
          published: link.compliance.published
      };
      if (link.labels && link.labels.length) {
          let label = link.labels[0];
          if (label && label.attrs && label.attrs.text) {
              transition.label = link.labels[0].attrs.text.text;
          }
          delete link.labels;
      }
      if (link.compliance && link.compliance.transitionId) {
          transition.transitionId = link.compliance.transitionId;
      } else {
          transition.transitionId = this.processEdit.newIdx++;
      }
      delete link.attrs.text;
      delete link.compliance;
      transition.diagramModel = JSON.stringify(link);
      return transition;
  }

  cancel() {
    this.modalService.adviceModal(this.translate.instant('process.edit.cancel-confirm'), 'info:question:question').subscribe((result:any)=>{
      if(result.result==='ok'){
        this.loadProcess();
      }
    });
  }

  deleteProcess() {
    let that: any = this.processEdit
      this.modalService.adviceModal(this.translate.instant('process.edit.delete-confirm'), 'info:question:question').subscribe((result:any)=>{
        if(result.result==='ok'){
          this.apiService.delete('processes', this.evaluationIdRoute + '/' + that.process.processId)
            .then((data:any)=>{
              this.onUpdate.emit({ data: null });
              that.process.title = '';
              that.process.description = '';
              that.selection = that.process;
              that.rappid.setModel({ cells: [] });
              that.alert = {
                  text: this.translate.instant('process.edit.delete-ok')
              };
              that.isNew = true;
            }, (errorData:any)=>{
              that.alert = {
                text: this.translate.instant('process.edit.delete-error'),
                isError: true
              };
            });
        }
      })
  }

  changeArchiveConceptObject(newValue:any){
    this.processEdit.hasArchivedSelect = newValue;
    this.processEdit.selection.cell.attributes.compliance.archiveConceptObject = newValue;
  }

}
