<div class="header-nav">
  <div class="header-nav__block-logo">
      <div class="header-nav__logo-compliance">
          <img class="header-nav__logo-compliance-img"
              [ngClass]="{'header-nav__logo-compliance-img--disabled' : headerNav.preview}"
              (click)="goToDashboard()"
              [src]="headerNav.logoHeader"
              alt="logo header"/>
      </div>
  </div>
  <div class="header-nav__block-central"
      *ngIf="!headerNav.preview">
      <div class="header-nav__block-central-align"
          [ngClass]="{'header-nav__block-central-align--without-one-element': !headerNav.videoconferences && !headerNav.hiddenLanguage,
              'header-nav__block-central-align--without-language-and-one': !headerNav.videoconferences && headerNav.hiddenLanguage}">
          <div class="header-nav__block-search"
              [ngClass]="{'header-nav__block-search--without-language': headerNav.hiddenLanguage}"
              *ngIf="!headerNav.preview">
              <input type="text"
                      class="header-nav__search-input"
                      [ngClass]="{'header-nav__search-input--with-text': headerNav.searchInDashboard}"
                      [(ngModel)]="headerNav.searchInDashboard"
                      (keypress)="goToPressEnter($event)"
                      [placeholder]="'common.search'|afTranslate"
                      (focus)="headerNav.search">
              <button class="header-nav__icon-button"
                      (click)="goToSearch()">
                  <i class="header-nav__icon-search"
                      [ngClass]="headerNav.searchInDashboard !== '' && headerNav.state === 'resultSearch' && headerNav.searchInDashboard === headerNav.text?'lf-icon-close-search':'lf-icon-search'"></i>
              </button>
          </div>
          <div class="header-nav__block-group-buttons"
              [ngClass]="{'header-nav__block-group-buttons--hidden-language': headerNav.hiddenLanguage}">
              <div class="header-nav__block-central-button"
                      *ngIf="!headerNav.preview">
                  <button class="header-nav__menu-button"
                      (click)="openOptionInWindow('schedule', 'openWindow')">
                      <i class="lf-icon-calendar header-nav__icon-header"
                          matTooltip="{{'schedule.widget-title' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">
                      </i>
                  </button>
              </div>
              <div class="header-nav__block-central-button"
                      *ngIf="!headerNav.preview && headerNav.videoconferences">
                  <button class="header-nav__menu-button span__auto-ajust"
                      (click)="openOptionInWindow('videoconferences', 'openWindow')">
                      <i class="lf-icon-videoconference header-nav__icon-header header-nav__icon-header--videoconference"
                          matTooltip="{{'header-nav.instant-videoconferences' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">
                      </i>
                  </button>
              </div>
              <div class="header-nav__block-central-button"
                      *ngIf="!headerNav.preview"
                      id="comments"
                      ngbDropdown
                      [autoClose]="'outside'">
                  <button class="header-nav__menu-button span__auto-ajust"
                          ngbDropdownToggle>
                      <i class="lf-icon-chat header-nav__icon-header"
                          matTooltip="{{'comments.comments' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">
                      </i>
                  </button>
                  <menu-header-comments
                      ngbDropdownMenu
                      class="header-nav__box-menus"
                      (closeMenu)="viewMenu('comments')"
                      (goToEmit)="goToEmit($event)">
                  </menu-header-comments>
              </div>
              <div class="header-nav__block-central-button"
                      *ngIf="!headerNav.preview"
                      id="notification"
                      ngbDropdown
                      [autoClose]="'outside'">
                  <button class="header-nav__menu-button header-nav__menu-button--notifications span__auto-ajust"
                      matTooltip="{{'header-nav.title-tooltip-notification' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left"
                      ngbDropdownToggle>
                      <i class="lf-icon-notification header-nav__icon-header"></i>
                      <span class="header-nav__icon-numeric-nav"
                          *ngIf="skin !== 'uk-steel-blue'">{{headerNav.amountNotifications}}</span>
                      <i class="lf-icon-step-final header-nav__icon-circle-notification"
                          *ngIf="skin === 'uk-steel-blue' && headerNav.notifications?.length"></i>
                  </button>
                  <menu-header-notifications ngbDropdownMenu
                    class="header-nav__box-menus"
                    (closeMenu)="viewMenu('notification')"
                    (changeAmount)="changeAmount()"
                    (goToEmit)="goToEmit($event)">
                  </menu-header-notifications>
              </div>
          </div>
          <div class="header-nav__translates-list-block"
              *ngIf="!headerNav.hiddenLanguage"
              ngbDropdown
              isOpen="headerNav.showList">
              <button class="header-nav__select-language-button"
                  ngbDropdownToggle>
                  <span class="header-nav__select-language"
                    [afTranslate]="headerNav.selectedLanguage.text"></span>
                  <span class="header-nav__select-language-short">{{headerNav.selectedLanguage.appLanguage}}</span>
                  <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
              </button>
              <div class="header-nav__menus-block dropdown-menu"
                  ngbDropdownMenu
                  role="menu">
                  <div class="common__block-option-list header-nav__block-option-list">
                      <div class="common__language-item"
                      *ngFor="let language of headerNav.languages"
                          (click)="changeAppLanguage(language)">
                          <span>{{language.text | afTranslate}}</span>
                          <span>({{language.specific | afTranslate}})</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="header-nav__block-menus"
      [ngClass]="{'header-nav__block-menus--view':headerNav.preview}">
      <div class="header-nav__preview"
          *ngIf="headerNav.preview"
          [afTranslate]="'header-nav.preview-title'">
          Modo vista previa
      </div>
      <div class="header-nav__icons">
          <div class="header-nav__block-button">
              <button class="header-nav__menu-button"
                  matTooltip="{{'header-nav.title-tooltip-help' | afTranslate}}"
                  matTooltipPosition="left"
                  matTooltipClass="left"
                  (click)="openSupport()">
                  <i class="lf-icon-help header-nav__icon-header"></i>
              </button>
          </div>
          <div class="header-nav__block-button header-nav__block-button--user"
                  id="user"
                  ngbDropdown
                  [autoClose]="'outside'">
              <button class="header-nav__menu-button-user span__auto-ajust"
                  [ngClass]="{'header-nav__menu-button-user--logo': headerNav.logoUser}"
                  matTooltip="{{'common.user' | afTranslate}}"
                  matTooltipPosition="left"
                  matTooltipClass="left"
                  ngbDropdownToggle>
                  <img class="header-nav__logo-user"
                      [ngShow]="headerNav.logoUser"
                      [src]="headerNav.logoUser"
                      alt="logo user"/>
                  <span class="notranslate header-nav__button-text"
                      [ngShow]="!headerNav.logoUser">
                      {{headerNav.initialsName}}
                  </span>
                  <i class="lf-icon-angle-down header-nav__icon-button-user"></i>
              </button>
              <menu-header-user ngbDropdownMenu
                class="header-nav__menus-block header-nav__box-menus"
                (closeMenu)="viewMenu('user')">
              </menu-header-user>

          </div>
          <div class="header-nav__block-button"
                  *ngIf="!headerNav.preview && productsHub"
                  id="products"
                  ngbDropdown
                  [autoClose]="'outside'">
              <button class="header-nav__menu-button span__auto-ajust"
                  matTooltip="{{'header-nav.title-tooltip-products' | afTranslate}}"
                  matTooltipPosition="left"
                  matTooltipClass="left"
                  ngbDropdownToggle>
                  <i class="lf-icon-products header-nav__icon-header"></i>
              </button>
              <menu-header-products ngbDropdownMenu
                class="header-nav__box-menus header-nav__menu-products"
                (closeMenu)="viewMenu('products')">
              </menu-header-products>
          </div>
          <div class="header-nav__block-button"
                  *ngIf="!headerNav.preview">
              <button class="header-nav__menu-button span__auto-ajust"
                  (click)="logOut()"
                  matTooltip="{{'common.log-out' | afTranslate}}"
                  matTooltipPosition="left"
                  matTooltipClass="left">
                  <i class="lf-icon-close-session header-nav__icon-header"></i>
              </button>
          </div>
      </div>
  </div>
</div>
