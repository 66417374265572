import { Injectable } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { CurrentPageFactory } from '@shared/factories/current-page/current-page.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { cloneDeep} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConceptListParentService {
  subscribers: any = {};
  constructor(
    private translate: AfTranslateFactory,
    private userPermisions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private context: ContextFactory,
    private broadcastService: BroadcastService,
    private currentPage: CurrentPageFactory,
    private registerService: RegisterMixtapeService,
    private convertNumbToName: ConvertNumToNameFactory,
    private dateConverterService: DateConverterService,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private filterColumnService:FilterColumnService,
    private exportService: ExportServiceService,
    private downloadService: DownloadService) { }

    OnChanges(child:any) {
      let that: any = child.conceptList;

      if (((child.toShow && child.conceptInfo) || child.modify) && child.conceptInfo.conceptId) {
        this.ConfigureEvaluatedConcepts(child);
      }

      if (child.modify) {
        child.launchAction.emit({type: 'updateConcept'});
      }

      this.RememberFilters(child);

      if (!child.conceptInfo.conceptId) {
        that.typeObjectId = 'reportObjectId';
        this.ConfigureReports(child);
      }

      that.archivedAmount = Boolean(!child.archivedAmount) ? 0 : child.archivedAmount;

      this.UpdateParragraph(that.archivedAmount, child);
      that.showAnonymatum = child.conceptInfo.communicationChannel;
      that.contextAnonymatumService = this.context.anonymatumService
    }

    OnInit(child: any) {
      let that:any = child.conceptList;
      child.userPermissions = this.userPermisions.getPermissions();

      this.subscribers.reloadConceptListBroadcast = this.broadcastService.subscribe('reloadConceptList', (data: any) => {
        if (data.concept.conceptId === child.conceptInfo.conceptId) {
          this.loader.openLoader('concept-list-charged');
          this.GetConceptsEvaluated(child);
        }
      });

      this.subscribers.changeReportListBroadcast = this.broadcastService.subscribe('changeReportList', (data:any)=> {
          if(child.conceptInfo.reportId === data.report.reportId){
            child.conceptInfo.reportObjects.push(data.report.newReport);
            this.ConfigureReportList(child);
            this.FilterComponentList(child);
          }
      });

      this.subscribers.changeGlobalReportListBroadcast = this.broadcastService.subscribe('changeGlobalReportList', (data:any)=> {
          if(child.conceptInfo.reportId === data.report.reportId){
            this.ConfigureReportList(child);
            this.FilterComponentList(child);
          }
      });

      this.subscribers.cleanFiltersActiontBroadcast = this.broadcastService.subscribe('cleanFiltersAction', (data:any)=> {
          that.currentPage = 1;
          that.selectedFilters = null;
          that.textSelectedFilters = null;
          that.modificationDateFromDate = null;
          that.modificationDateToDate = null;
          that.modificationDateFilter = null;
          that.creationDateFromDate = null;
          that.creationDateToDate = null;
          that.creationDateFilter = null;
          this.GetFilters(that.conceptList, child);
          this.FilterComponentList(child);
          that.filters.forEach((filter:any) => {
            that[filter+'Filter'].hasFilter = 0
          })
      });

      this.subscribers.exportInstancesListBroadcast = this.broadcastService.subscribe('exportInstancesList'+child.conceptInfo.conceptId, (data:any)=> {
          this.modalService.exportFormatModal().subscribe((result:any) => {
            switch (result.selectedFormat) {
                case 'xls':
                    this.ExportToExcel(child);
                    break;
                case 'pdf':
                    this.ExportToPdf(child);
                    break;
            }
        });
      });

      this.ConfigureConceptList(child);
    }

    ConfigureConceptList(child:any){
      let that:any = child.conceptList;
      if((child.toShow && child.conceptInfo) || that.modify){
          this.ConfigureState(child);
          if(child.conceptInfo.list){
            this.GetFilters(child.conceptInfo.list, child);
          }
      }
       that.descriptionEdit = child.userPermissions ==='none' || child.conceptInfo.poll? this.translate.instant('concept-list.description-view') : this.translate.instant('concept-list.description-edit');

      that.deleteConcept = this.translate.instant('concept-list.description-delete');
      that.description = this.translate.instant('concept-list.description-open');
      if(child.conceptInfo.reportId){
          that.deleteConcept = this.translate.instant('concept-list.delete-report');
          that.descriptionEdit = this.translate.instant('concept-list.edit-report');
          that.description = this.translate.instant('report-table.icon-tooltip');
      }
    }

    RememberFilters(child:any){
      let that:any = child.conceptList;
      let id = child.conceptInfo.conceptId ? 'con-' + child.conceptInfo.conceptId : 'rep-' + child.conceptInfo.reportId;
      let current = this.currentPage.get(id);
      if(current){
          that.currentPage = current.page;
          that.selectedFilters = current.filters;
          that.textSelectedFilters = current.textFilters;
          that.modificationDateFromDate = current.modificationDateFromDate;
          that.modificationDateToDate = current.modificationDateToDate;
          that.modificationDateFilter = current.modificationDateFilter;
          that.creationDateFromDate = current.creationDateFromDate;
          that.creationDateToDate = current.creationDateToDate;
          that.creationDateFilter = current.creationDateFilter;
      }
    }

    ConfigureReports(child:any){
      this.loader.openLoader('concept-list-charged');
      this.ConfigureReportList(child);
      this.FilterComponentList(child);
    }

    ConfigureReportList(child:any){
      let that: any = child.conceptList;
      let list : any = child.conceptInfo.reportObjects;
      that.archivedAmount = child.conceptInfo.archived?.length
      if(child.toShow === 'archived'){
          list = child.conceptInfo.archived;
          if(that.selectedFilters && !that.selectedFilters.nameVersionList){
              that.nameVersionList = this.filterColumnService.extractList('nameVersion', child.conceptInfo.archived);
              that.nameVersionListFilter = [];
              that.selectedFilters.nameVersionList =  that.nameVersionList;
              that.selectedFilters.nameVersionListFilter = that.nameVersionListFilter
          }
      }

      that.conceptList = cloneDeep(list);
      that.conceptList.sort(this.orderListDate);
      if(child.conceptInfo.type === 0){
        that.conceptList.splice(that.conceptList.length - 1);
      }
      this.GetFilters(that.conceptList, child);
    }

    orderListDate(a:any, b:any) {
      return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
    }

    GetFilters(data: any, child: any) {
      let that: any = child.conceptList;
      if (!that.filters) {
        that.filters = this.ConfigureFilters(child);
      }
      that.textFilters.forEach((text: any) => {
        that[text+'WordFilter'] = that.textSelectedFilters !== null? that.textSelectedFilters[text+'WordFilter'] : ''
      });
      that.filters.forEach((filter:any) => {
          let filterType = filter;
          let list = cloneDeep(data);
          if(filter === 'managers'){
              list = this.getAllManager(data);
              filterType = 'fullName';
          }
          that[filter+'List'] = this.filterColumnService.extractList(filterType, list);
          that[filter+'ListFilter'] = [];
          if(that.selectedFilters !== null){
              let selectedList:any = that.selectedFilters[filter+'List'];
              let newList : any = that[filter+'List']
              selectedList.forEach((selected:any)=>{
                newList.forEach((item:any) => {
                  if(selected.selected && selected.name === item.name){
                    item.selected = selected.selected;
                  }
                });
              })
              let selectedFilterList = that.selectedFilters[filter+'ListFilter'];
              selectedFilterList.forEach((selected:any) => {
                newList.forEach((item:any) => {
                  if(selected.name === item.name){
                    that[filter+'ListFilter'].push(selected)
                  }
                });
              });
          }

          if (filter === 'esPrioritaria') {
              setTimeout(() => {
                that.esPrioritariaList.forEach((priority:any) => {
                  priority.nameTranslate = priority.name? this.translate.instant('common.yes') : this.translate.instant('common.no');
                });
              });
          }
          if(filter === 'alertState'){
              setTimeout(() => {
                that.alertStateList.forEach((progress:any) => {
                  let text: string = 'finished';
                  if(progress.name === '1-last-day'){
                      text = 'last-day';
                  }else if(progress.name === '2-two-days'){
                      text = 'two-days';
                  }else if(progress.name === '3-more-time'){
                      text = 'more-time';
                  }
                  progress.nameTranslate = this.translate.instant('concept-instances-list.'+text);
                });
              })
          }
      });
      this.loader.closeLoader(['concept-list-charged']);
    }

    getAllManager(list: any){
        let managers: any = [];
        list.forEach((item:any) => {
          if(item.managers?.length){
            managers = managers.concat(item.managers);
          }
        });
        return managers;
    }

    GetConceptsEvaluated(child:any){
      let path = child.skin === 'icam-red' ? 'icam/conceptobjects/byconceptid' : 'conceptobjects/byconceptid';
      this.apiService.get(path, child.conceptInfo.conceptId+'/archived/false')
          .then((data:any)=>{
              child.conceptInfo.list = data;
              this.RememberFilters(child);
              this.ConfigureEvaluatedConcepts(child);
              child.launchAction.emit({type: 'updateConcept'});
          }, (errorData:any)=>{
              this.loader.closeError();
          });
    }

    FilterComponentList(child:any) {
      let that: any = child.conceptList;
      let filterList:any = that.conceptList;
      that.textFilters.forEach((searchWord:any) => {
        filterList = this.filterColumnService.filterByWord(that[searchWord+'WordFilter'], filterList, searchWord);
      });

      if(that.creationDateFilter){
          filterList = this.FilterDates('creationDate', filterList, child)
      }
      if(that.modificationDateFilter){
          filterList = this.FilterDates('modificationDate', filterList, child)
      }
      this.GetFiltratedColumns(filterList, child);
    }

    ConfigureState(child:any){
      let that: any = child.conceptList;
      if(child.conceptInfo.poll && !child.conceptInfo.publicShared){
          this.GetState(child.conceptInfo.list, child);
      }else if(child.conceptInfo.poll && child.conceptInfo.publicShared && child.conceptInfo.list?.length){
          that.showResultsReports = true;
      }
    }

    UpdateParragraph(archivedAmount:number, child:any){
      let that: any = child.conceptList;
      let archivedsText:string = child.conceptInfo.conceptId? 'concept-list.concept-archive' : 'concept-list.report-archive';
      let thereText: string = 'concept-list.there-is';
      if(archivedAmount > 1){
        archivedsText = child.conceptInfo.conceptId? 'concept-list.concept-archive' : 'concept-list.reports-archives';
        thereText = 'concept-list.there-are';
      }
      that.archivedParragraph = child.conceptInfo.conceptId? this.translate.instant(thereText)+' '+archivedAmount+' '+this.translate.instant(archivedsText) : archivedAmount+' '+this.translate.instant(archivedsText);
    }

    ExportToExcel(child:any) {
      let config = this.getExportConfig('xls', child);
      this.exportService.toXls(config);
    }

    ExportToPdf(child:any) {
        let config = this.getExportConfig('pdf', child);
        config.exportDate = this.dateConverterService.setTimezone(new Date());
        config.orderType = child.conceptList.dateFilter;
        config.orderAsc = child.conceptList.order;
        child.conceptList.configurationPdf = config;
        let that : any = this;
        setTimeout(function () {
            let htmlPrint : any = $('#pdf-concept-list-instances').html();
            config.htmlPrint = htmlPrint;
            that.exportService.toPdf(config);
        });
    }

    getExportConfig(formatDocument:any, child: any) {
        let that: any = child.conceptList;
        let config: any = {
            idPrint: 'pdf-concept-list-instances',
            nameDocument: child.conceptInfo.title+(formatDocument==='xls'?'.xlsx':'.pdf'),
            title: child.conceptInfo.title,
            list: cloneDeep(that.conceptListFilter),
            uploadFile: false,
            landscape: true,
            successCallback: (response:any, blob:any) => {
                delete that.configurationPdf;
                this.downloadService.triggerDownload(blob, config.nameDocument);
                this.modalService.adviceModal(this.translate.instant('common.export-excel-ok'), 'info:accept:check-round')
            }
        };
        config.list.forEach((instance:any) => {
            instance.creationDateIso = this.dateConverterService.convertDate(instance.creationDate);
            if(instance.modificationDate){
                instance.modificationDateIso = this.dateConverterService.convertDate(instance.modificationDate);
            }
            instance.managersNames = '';
            if(instance.managers){
              instance.managers.forEach((manager:any) => {
                  instance.managersNames += instance.managersNames?', '+ manager.fullName:manager.fullName;
              });
            }
        });
        if (child.skin != 'icam-red') {
            config.typeColumns = ['title', 'author', 'creationDateIso', 'modificationDateIso'];
            config.columns = {
                title: this.translate.instant('common.title'),
                author: this.translate.instant('common.author'),
                creationDateIso: this.translate.instant('common.creation'),
                modificationDateIso: this.translate.instant('common.modification')
            };
            if(child.conceptInfo.poll && !child.conceptInfo.anonymousAccess){
                config.typeColumns.push('state');
                config.columns.state = this.translate.instant('common.state');
            }else if(!(child.conceptInfo.poll && child.conceptInfo.anonymousAccess)){
                config.typeColumns.push('step');
                config.columns.step = this.translate.instant('common.state');
            }
            if(!child.conceptInfo.poll && child.conceptInfo.allowPublicShare){
                config.typeColumns.splice(2,0,'managersNames');
                config.typeColumns.push('conceptObjectId');
                config.columns.managersNames = this.translate.instant('box-queries.manager');
                config.columns.conceptObjectId = this.translate.instant('common.reference');
            }
        } else {
            switch (child.conceptInfo.namedKey) {
                case 'Consulta':
                    config.typeColumns = ['referencia', 'tipo', 'creationDateIso', 'step', 'managersNames', 'numColegiado', 'nombreColegiado', 'materia', 'modificationDateIso', 'esPrioritaria', 'processingHours'];
                    config.columns = {
                        referencia: this.translate.instant('common.reference'),
                        tipo: this.translate.instant('common.type'),
                        creationDateIso: this.translate.instant('concept-instances-list.entry'),
                        step: this.translate.instant('common.state'),
                        managersNames: this.translate.instant('box-queries.manager'),
                        numColegiado: this.translate.instant('concept-list.user-number'),
                        nombreColegiado: this.translate.instant('common.name-surname'),
                        materia: this.translate.instant('concept-list.matter'),
                        modificationDateIso: this.translate.instant('common.modification'),
                        esPrioritaria: this.translate.instant('concept-list.priority'),
                        processingHours: this.translate.instant('common.alerts')
                    };
                    break;
                case 'Digitalizacion':
                    config.typeColumns = ['titulo', 'referencia', 'pages', 'creationDateIso'];
                    config.columns = {
                        titulo: this.translate.instant('common.title'),
                        referencia: this.translate.instant('concept-list.fragment-reference'),
                        pages: this.translate.instant('concept-list.pages-range'),
                        creationDateIso: this.translate.instant('concept-list.registration-date')
                    };
                    break;
                case 'Publicacion':
                    config.typeColumns = ['tipo', 'titulo', 'isbn', 'urlCatalogo', 'author', 'year', 'creationDateIso', 'porcentajeDigitalizado'];
                    config.columns = {
                        tipo: this.translate.instant('common.type'),
                        titulo: this.translate.instant('common.title'),
                        isbn: this.translate.instant('concept-list.isbn-signature'),
                        urlCatalogo: this.translate.instant('concept-list.icam-catalog'),
                        author: this.translate.instant('common.author'),
                        year: this.translate.instant('concept-list.year-publication'),
                        creationDateIso: this.translate.instant('concept-list.registration-date'),
                        porcentajeDigitalizado: this.translate.instant('concept-list.percentage-digitized')
                    };
                    break;
                case 'Usuario':
                    config.typeColumns = ['apellidos', 'nombre', 'email', 'numero', 'estado', 'perfil', 'situacion', 'creationDateIso'];                    
                    config.columns = {
                        apellidos: this.translate.instant('common.surname'),
                        nombre: this.translate.instant('common.name'),
                        email: this.translate.instant('common.email'),
                        numero: this.translate.instant('concept-list.user-number'),
                        estado: this.translate.instant('common.state'),
                        perfil: this.translate.instant('concept-list.profile'),
                        situacion: this.translate.instant('concept-list.situation') ,
                        creationDateIso: this.translate.instant('concept-list.registration-date')
                    };
                    break;
                case 'Encuesta':
                    config.typeColumns = ['title', 'referencia', 'numColegiado', 'nombreColegiado', 'creationDateIso', 'modificationDateIso'];
                    config.columns = {
                        title: this.translate.instant('common.title'),
                        referencia: this.translate.instant('common.reference'),
                        numColegiado: this.translate.instant('concept-list.user-number'),
                        nombreColegiado: this.translate.instant('common.name-surname'),
                        creationDateIso: this.translate.instant('common.creation'),
                        modificationDateIso: this.translate.instant('common.modification')
                    };
                    break;
            }
        }

        return config;
    }

    SelectTypeOperation(item:any, type:any, child:any){
      let that: any = child.conceptList;
      if(that.selectAll){
          this.SelectMultipleConcept('all', null, child);
          that.filterSelectAll = true;
      }
      let listFilter:any = that[type+'ListFilter'];
      that[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      that[type+'Filter'].hasFilter = that[type+'ListFilter'].length;
      this.FilterComponentList(child);
    }

    SelectMultipleConcept(type: string, evaluatedConcept: any = null, child:any){
      let that: any = child.conceptList;
      that.countConceptSelect = [];
      if(type === 'all'){
          that.selectAll = !that.selectAll
      }
      that.conceptListFilter.forEach((concept:any) => {
        if(type === 'all'){
          concept.selectedConcept = that.selectAll;
        }else if(evaluatedConcept.conceptObjectId === concept.conceptObjectId){
            concept.selectedConcept = !concept.selectedConcept;
        }
        if(concept.selectedConcept){
            that.countConceptSelect.push(concept)
        }
      });
      that.anySelect = !!(that.countConceptSelect.length)
    }

    ConfigureFilters(child:any) {
      switch (child.skin) {
          case 'uk-steel-blue':
              return ['author', 'step', 'state', 'associatedEvaluationName'];
          case 'icam-red':
              switch (child.conceptInfo.namedKey) {
                  case 'Consulta':
                      return ['tipo', 'step', 'managers', 'materia', 'esPrioritaria', 'alertState'];
                  case 'Publicacion':
                      return ['tipo'];
                  case 'Digitalizacion':
                      return [];
                  case 'Usuario':
                      return ['estado', 'perfil', 'situacion'];
                  case 'Encuesta':
                      return [];
                  default:
                      return ['author', 'step', 'state', 'associatedEvaluationName'];
              }
          default:
              if(child.conceptInfo.reportId && child.conceptInfo.type === 0){
                  return ['author', 'status', 'extension'];
              }
              return ['author', 'step', 'managers', 'state', 'associatedEvaluationName', 'nameVersion'];
      }
    }

    ConfigureEvaluatedConcepts(child:any){
      this.loader.openLoader('concept-list-charged');
      if(child.toShow==='archived'){
        if(child.conceptList.stepFilter){
          child.conceptList.stepFilter.hasFilter = false;
        }
        if(child.conceptInfo.archivedNoCall){
          this.GetArchivedConcepts(child)
          child.conceptInfo.archivedNoCall = false;
        }else{
          this.ConfigureList(child.conceptInfo.archived, child);
        }
      }else if(child.conceptInfo.list){
          this.ConfigureList(child.conceptInfo.list, child);
      }
    }

    GetArchivedConcepts(child:any){
      let path : any = child.skin === 'icam-red' ? 'icam/conceptobjects/byconceptid' : 'conceptobjects/byconceptid';
      this.apiService.get(path, child.conceptInfo.conceptId+'/archived/true')
          .then((data:any)=>{
              child.conceptInfo.archived = data;
              child.conceptInfo.archivedAmount = data.length;
              this.ConfigureList(child.conceptInfo.archived, child);
              this.UpdateParragraph(child.conceptInfo.archivedAmount, child)
          }, (errorData:any)=>{})
    }

    ConfigureList(list:any, child:any){
      let that: any = child.conceptList;
      that.conceptList = list;

      that.showFilterAssociatedEvaluation = false;

      that.conceptList.forEach((instance:any) => {
        if(instance.associatedEvaluationName){
          that.showFilterAssociatedEvaluation = true;
        }
        if(instance.confidential){
          this.SetConfidential(instance, child);
        }
        if(child.skin === 'icam-red'){
          this.ConfigureEvaluatedConceptICAM(instance, child);
        }
      });
      if( child.conceptInfo && child.conceptInfo.poll){
          this.ConfigureState(child);
          this.GetFilters(child.conceptInfo.list, child);
      }else{
          this.GetFilters(that.conceptList, child);
          if(child.toShow!=='archived'){
            this.ConfigureListICAM(that, child);
          }
      }
      this.FilterComponentList(child);
    }

    ConfigureListICAM(that:any, child:any){
      if(child.conceptInfo.namedKey === 'Consulta' && that.selectedFilters === null && that.stepList){
        that.stepList.forEach((step:any) => {
          if(step.name === this.translate.instant('dashboard-reports.enProceso') || step.name === this.translate.instant('dashboard-reports.registrada')){
            this.SelectTypeOperation(step, 'step', child);
            this.RememberFilters(child);
          }
        });
      }
    }

    SetConfidential(instance:any, child:any){
      this.apiService.get('conceptobjects/confidentialaccess', instance.conceptObjectId)
          .then((data:any)=>{
              instance.confidentialList = data;
          },(errorData:any)=>{})
    }

    ConfigureEvaluatedConceptICAM(instance:any, child:any){
      let that: any = child.conceptList
      switch (child.conceptInfo.namedKey) {
          case 'Consulta':
              instance.namedKey = child.conceptInfo.namedKey;
              this.commonUtilsService.configureAlertState(instance);
              break;
          case 'Publicacion':
              instance.year = instance.anioPublicacionFin?instance.anioPublicacion+'-'+instance.anioPublicacionFin:instance.anioPublicacion;
              break;
          case 'Digitalizacion':
              instance.pages = '';
              if(instance.paginaInicio && instance.paginaFin){
                  instance.pages = instance.paginaInicio+'-'+instance.paginaFin;
              }else if(!instance.paginaInicio && instance.paginaFin){
                  instance.pages = '-'+instance.paginaFin;
              }else if(instance.paginaInicio && !instance.paginaFin){
                  instance.pages = instance.paginaInicio+'-';
              }
              break;
          case 'Usuario':
              break;
          default:
              break;
      }
    }

    GetState(list:any, child:any){
      let that: any = child.conceptList
      child.conceptInfo.answered = 0;
      child.conceptInfo.pending = 0;
      list.forEach((poll:any) => {
        poll.state = this.translate.instant('concept-list.poll-pending');
        poll.stateId = 1;
        if(poll.saved){
            poll.state = this.translate.instant('concept-list.poll-completed');
            poll.stateId = 0;
            that.showResultsReports = true;
            child.conceptInfo.answered++;
        }else{
            child.conceptInfo.pending++;
        }
      });
      that.statePoll = true;
    }

    FilterDates(type:any, list:any, child:any){
      let that: any = child.conceptList
      that[type+'Filter'] = true;
      let dates : any ={
          from: that[type+'FromDate'],
          to: that[type+'ToDate']
      }
      return this.filterColumnService.filterByRangeDates(list, type, dates);
    }

    GetFiltratedColumns(filterList:any, child:any){
      let that: any = child.conceptList;
      that.filters.forEach((filter:any) => {
        if(filter === 'managers'){
            filterList = this.filterColumnService.filterBycolumnAllChildren(filterList, that[filter+'ListFilter'], 'fullName', filter);
        }else{
            filterList = this.filterColumnService.filterBycolumn(filterList, that[filter+'ListFilter'], filter);
        }
      });
      if(child.skin === 'icam-red' && child.conceptInfo.namedKey === 'Consulta'){
          this.configureReferenceTooltip(filterList, child);
      }
      that.conceptListFilter = filterList;
      if(that.filterSelectAll){
          this.SelectMultipleConcept('all', null, child);
          that.filterSelectAll = false;
      }

      this.SaveFilter(child)
    }

    configureReferenceTooltip(filterList: any, child: any){
      filterList.forEach((concept:any) => {
        let titleConcept = concept.title && concept.title !== 'null'?' / ' +concept.title:'';
        let descriptionConsulta = concept.consulta?concept.consulta:'';
        let motivoPrioridad = concept.motivoPrioridad?concept.motivoPrioridad:'';
        let priority = concept.esPrioritaria?'<div class="concept-list__priority-block-tooltip"><p class="concept-list__subtitle-tooltip">'+this.translate.instant('concept-list.priority') + '</p><span>'+ motivoPrioridad+ '</span></div>':'';
        let textTooltip = '<div><p class="concept-list__subtitle-tooltip">'+concept.referencia + titleConcept + '</p>'
                           + '<span>' + descriptionConsulta + '</span></div>'
                           + priority
        // concept.conferencePriorityTooltip = $sce.trustAsHtml(textTooltip);
        concept.conferencePriorityTooltip = textTooltip;
      })
    }

    LaunchActionOption(option:any, item:any, child:any){
        switch(option){
            case 'open':
                if (!child.anySelect) {
                    this.OpenEvaluatedConcept(item, child);
                }
                break;
            case 'download':
            case 'share':
            case 'publish':
            case 'signature-certification':
            case 'signature':
            case 'send-mail-certificate':
            case 'send-sms-certificate':
            case 'certify-document':
            case 'request-tracking':
                child.evaluateConcept.emit({evaluateConcept:item, type: option});
                break;
            case 'delete':
                if(!item.reportObjectId){
                  let resgisterItem : any ={
                      'Distinct ID': this.context.user.externalId,
                      'Client ID': this.context.user.companyExternalId,
                      'event':option+' instance',
                      'Concept Name': sessionStorage.getItem('breadcrumbConcept') + item.title,
                      'Centinela product': this.context.settings.defaultProduct
                  }
                  this.registerService.register(resgisterItem);
                }
                this.DeleteEvaluatedConcept(item, child);
                break;
            default:
                break;
        }
    }

    OpenEvaluatedConcept(item:any, child:any){
        this.SaveFilter(child);
        let loaderName : any =item.reportId?'report-open-concept': 'folder-open-concept';

        if(!(child.conceptInfo.reportId && child.conceptInfo.type === 0)){
            this.loader.openLoader(loaderName);
        }
        if(item.reportObjectId){
            let resgisterItem : any ={
                'Distinct ID': this.context.user.externalId,
                'Client ID': this.context.user.companyExternalId,
                'event':'open report',
                'Report Type': this.convertNumbToName.reportNumbToName(String(item.reportId)),
                'Source': 'other',
                'Centinela product': this.context.settings.defaultProduct
              }
              this.registerService.register(resgisterItem)
        }
        child.evaluateConcept.emit({evaluateConcept:item, type: 'open'});
    }

    SaveFilter(child:any){
      let that: any = child.conceptList
        let current:any = {
            page: that.currentPage,
            filters: {},
            textFilters : {},
            conceptId: child.conceptInfo.conceptId,
            modificationDateFromDate: that.modificationDateFromDate,
            modificationDateToDate: that.modificationDateToDate,
            modificationDateFilter: that.modificationDateFilter,
            creationDateFromDate: that.creationDateFromDate,
            creationDateToDate: that.creationDateToDate,
            creationDateFilter: that.creationDateFilter,
            hasFilters: 0
        }
        that.filters.forEach((filter:any) => {
          current.filter = filter;
          current.filters[filter+'ListFilter'] = that[filter+'ListFilter'];
          current.filters[filter+'List'] = that[filter+'List'];
          if(that[filter+'ListFilter'].length){
              current.hasFilters++;
          }
        });
        that.textFilters.forEach((text:any) => {
          current.textfilter = text;
          current.textFilters[text+'WordFilter'] = that[text+'WordFilter'];
          if(that[text+'WordFilter'] !== ''){
              current.hasFilters++;
          }
        });
        if(that.creationDateFilter){
            current.hasFilters++;
        }
        if(that.modificationDateFilter){
            current.hasFilters++;
        }
        this.currentPage.set(current);
        if((child.conceptInfo.namedKey ==='Consulta' || child.conceptInfo.namedKey ==='Usuario')){
          this.broadcastService.broadcast('cleanFiltersProp', {
              hasFilters: current.hasFilters,
              id: child.conceptInfo.conceptId ? 'con-' + child.conceptInfo.conceptId : 'rep-' + child.conceptInfo.reportId
          })
      }
    }

    OpenInNewTab(item:any, child:any) {
        let url : any = '/evaluation/' +  child.evaluationId + '/reports/' +  item.reportId + '/report-related/' + item.reportObjectId;
        if(item.conceptObjectId){
            url = '/evaluation/' +  child.evaluationId + '/folder/' +  child.folderId + '/concept/' +  child.conceptInfo.conceptId + '/concept-evaluated/' +  item.conceptObjectId;
        }
        window.open(url, '_blank');
    }

    OpenAssociatedEvaluation(concept:any, child:any){
      this.apiService.get('conceptobjects/formsourcepath', concept.conceptObjectId)
            .then((data:any)=>{
                window.open(data, '_blank');
            },(errorData:any)=>{})
    }

    OpenModalConfidential(instance:any, child:any){
        this.modalService.manageConfidentialModal(instance).subscribe((result:any)=>{
          if(result.result ==='ok'){
            this.loader.openLoader('concept-list-confidential-instance');
            this.ConfidentDocument(instance, result, child);
          }
        })
    }

    ConfidentDocument(instance:any, resultModal:any, child:any) {
        let that: any = child.conceptList
        let list:any = resultModal.share;
        let confidential:any = resultModal.confidential.toString();
        let confidentialOfUserConnected:any = resultModal.confidentialOfUserConnected;
        this.apiService.patch('conceptobjects/setconfidential/' +instance.conceptObjectId, confidential, list)
            .then((data:any)=>{
                instance.confidential = !!resultModal.confidential;
                let text:any = instance.confidential? this.translate.instant('manage-confidential-modal.confident-ok') : this.translate.instant('manage-confidential-modal.confident-uncheck');
                if(!instance.confidential){
                    instance.confidentialList = [];
                }
                if(instance.confidential && !confidentialOfUserConnected){
                    that.conceptList = this.commonUtilsService.deleteElement(instance, that.conceptList, 'conceptObjectId');
                    this.FilterComponentList(child);
                    child.launchAction.emit({type: 'getEvaluatedConcepts'});
                }
                this.loader.closeLoader(['concept-list-confidential-instance']);
                this.modalService.adviceModal(text, 'info:accept:check-round');
            },(errorData:any)=>{
                this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning')
                this.loader.closeError();
            })
    }

    DeleteEvaluatedConcept(item:any, child:any){
      let word:any = item.reportObjectId ? this.translate.instant('concept-list.report') : this.translate.instant('concept-list.concept');

      child.conceptList.questionText = this.translate.instant('concept-list.delete-question') + word + " '" + item.title + "' ?";
      this.modalService.adviceModal(child.conceptList.questionText, 'info:question:question').subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('concept-list-concept-list');
          item.reportObjectId? this.DeleteReport(item, child) : this.DeleteEvalConcepts(item, child);

        }
      })
    }

    DeleteReport(report:any, child:any){
      let resgisterItem : any ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':'delete report',
          'Report Type': this.convertNumbToName.reportNumbToName(String(report.reportId)),
          'Source': 'other',
          'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(resgisterItem)
        this.apiService.delete('reportobjects', report.reportObjectId)
            .then((data:any) => {
                child.conceptInfo.reportObjects = this.commonUtilsService.deleteElement( report, child.conceptInfo.reportObjects, 'reportObjectId');
                // vm.conceptList = vm.conceptInfo.reportObjects;
                this.ConfigureReportList(child);
                this.FilterComponentList(child);
                this.loader.closeLoader(['concept-list-concept-list']);
                this.modalService.adviceModal(this.translate.instant('concept-list.report-delete'), 'info:accept:check-round');
            }, (errorData:any) => {
                this.loader.closeError();
                let text: string = errorData.status === 403? 'concept-list.delete-error-permissions':'concept-list.delete-error-concept'
                this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
            });
    }

    DeleteEvalConcepts(concept:any, child:any){
      this.apiService.delete('conceptobjects', concept.conceptObjectId)
      .then((data:any)=>{
          child.conceptList.conceptList = this.commonUtilsService.deleteElement( concept, child.conceptList.conceptList, 'conceptObjectId')
          this.loader.closeLoader(['concept-list-concept-list']);
          if(!child.conceptList.conceptList.length && child.toShow==='archived'){
              child.launchAction.emit({toShow:'evaluated', type: 'showElements'});
          }else{
              this.FilterComponentList(child);
              child.launchAction.emit({type: 'getEvaluatedConcepts'});
          }
          if(concept.isArchived){
            child.conceptInfo.archivedAmount--;
          }
          this.modalService.adviceModal(this.translate.instant('concept-list.delete-concept-ok'), 'info:accept:check-round');
          this.GetFilters(child.conceptList.conceptList, child)
      }, (errorData:any)=>{
          this.loader.closeError();
          let text:string = errorData.status === 403? 'concept-list.delete-error-permissions' : 'concept-list.delete-error-concept';
          this.modalService.adviceModal(this.translate.instant('concept-list.delete-error-concept'), 'error:accept:warning')
      });

    }


    OrderDate(type:any, child:any){
      if(child.conceptList.dateFilter === type){
        child.conceptList.order = !child.conceptList.order;
      }else{
        child.conceptList.dateFilter = type;
        child.conceptList.order = (type !== 'title');
      }
    }

    ArchiveConcept(concept:any, archived:any, child:any){
      let text : string = archived === '0' ? 'concept-list.restore-concept-question': 'concept-list.archive-concept-question';
      this.modalService.adviceModal(this.translate.instant(text)?.replace('{titleConcept}',  concept.title), 'info:question:question').subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('concept-list-archive-concept');
          this.ArchiveConceptService(concept, archived, child)
        }
      })
    }

    ArchiveConceptService(concept:any, archived:any, child:any){
      this.apiService.patch('conceptobjects/archived/'+concept.conceptObjectId, archived)
          .then((data:any)=>{
              concept.isArchived = !concept.isArchived;
              child.conceptList.conceptList = this.commonUtilsService.deleteElement( concept, child.conceptList.conceptList, 'conceptObjectId')
              child.toShow!=='archived'? child.conceptInfo.archived.push(concept) : child.conceptInfo.list.push(concept);
              this.UpdateParragraph(child.conceptInfo.archived.length, child);
              this.loader.closeLoader(['concept-list-archive-concept']);
              let text:any = archived === '0'? 'concept-list.restore-concept-success' : 'concept-list.archive-concept-success';
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
              child.launchAction.emit({type: 'updateConcept'});
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('concept-list.archive-concept-error'), 'error:accept:warning')
          });

  }

    ChangeCalendar(dates:any, type:any, child:any){
      let that: any = child.conceptList
      // that[type+'Open'] = false;
      that[type+'Filter'] = dates.type === 'cancel'? that[type+'Filter'] : dates.type === 'apply';
      if(dates.type !== 'cancel'){
        that[type+'FromDate'] = dates.from;
        that[type+'ToDate'] = dates.to;
        that[type+'OptionCalendar'] = 'select';
        let filterList:any;
        if(dates.type === 'apply' && ((type === 'creationDate' && that.modificationDateFilter) || (type === 'modificationDate' && that.creationDateFilter))){
            filterList = this.filterColumnService.filterByRangeDates(that.conceptListFilter, type, dates);
        }else if(dates.type === 'restore' && ((type === 'creationDate' && that.modificationDateFilter) || (type === 'modificationDate' && that.creationDateFilter))){
            let dateModification : any = {
                from: type === 'modificationDate' ? that.creationDateFromDate : that.modificationDateFromDate,
                to: type === 'modificationDate' ? that.creationDateToDate : that.modificationDateToDate
            }
            let typeFilter : any = type === 'creationDate'? 'modificationDate' : 'creationDate';
            filterList = this.filterColumnService.filterByRangeDates(that.conceptList, typeFilter, dateModification);
        }else{
            filterList = this.filterColumnService.filterByRangeDates(that.conceptList, type, dates);
        }
        this.GetFiltratedColumns(filterList, child);
      }
      this.SaveFilter(child);
    }

    AssignManager(concept:any, child:any){
      if(!child.conceptList.countConceptSelect.length){
        child.conceptList.countConceptSelect.push(concept)
      }
      this.modalService.assignManager(child.conceptList.countConceptSelect.length).subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('concept-list-assign-manager');
          this.SaveAssignManager(result.managers, child);
        }
      })
    }

    SaveAssignManager(managers:any, child:any){
      let that: any = child.conceptList
      this.apiService.patch('conceptobjects/concept', child.conceptInfo.conceptId+'/set-managers', this.ExtractManagersObjectsId(managers, child))
          .then((data:any)=>{
              that.conceptListFilter = [];
              that.countConceptSelect.forEach((countConceptSelect:any) => {
                  for(let i: number = 0; i < that.conceptList.length; i++){
                    let conceptList: any = that.conceptList[i]
                    if(countConceptSelect.conceptObjectId === conceptList.conceptObjectId){
                      conceptList.managers = managers;
                      conceptList.selectedConcept = false;
                      that.conceptList.splice(i, 1, conceptList)
                      break;
                    }
                  }
              });

              that.countConceptSelect = [];
              that.anySelect = false;
              this.loader.closeLoader(['concept-list-assign-manager']);
              this.modalService.adviceModal(this.translate.instant('concept-list.assign-manager-success'), 'info:accept:check-round')
              that.selectAll = false;
              this.FilterComponentList(child);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('concept-list.unexpected-error'), 'error:accept:warning')
          })
    }

    ExtractManagersObjectsId(managers: any, child:any){
      let objectBody: any = {
          conceptObjectIds: [],
          managers: []
      }
      child.conceptList.countConceptSelect.forEach((conceptSelect:any) => {
          objectBody.conceptObjectIds.push(conceptSelect.conceptObjectId);
      })
      managers.forEach((manager:any) => {
        if(manager.type==='U'){
            objectBody.managers.push({userId: manager.id});
        }else if(manager.type==='R'){
            objectBody.managers.push({productRoleId: manager.id});
        }
      })
      return objectBody;
    }

    ChangeState(evaluateConcept:any, child:any){
      this.modalService.changeState(evaluateConcept).subscribe((result:any)=>{
        if(result.result === 'ok'){
          let state : any = result.state
          if(!this.checkManagers(evaluateConcept.managers)){
              this.modalService.adviceModal(this.translate.instant('states-process.not-responsible'), 'info:question:question').subscribe((result: any)=>{
                if(result.result ==='ok'){
                  this.ChangeProcessAdvice(state.itemSelected, state.option, evaluateConcept, child)
                }
              })
          }else{
            this.ChangeProcessAdvice(state.itemSelected, state.option, evaluateConcept, child);
          }
        }
      })

    }

    checkManagers(managers:any){
      for(let i:number = 0; i < managers.length; i++){
        if(managers[i].id === this.context.user.userId){
          return true;
        }
      }
      return false
    }

    ChangeProcessAdvice(itemSelected:any, option:any, evaluateConcept:any, child:any){
      this.modalService.adviceModal(this.translate.instant('states-process.question-task-process-start')+' '+this.translate.instant('states-process.question-task-process-end'), 'info:question:question')
        .subscribe((result: any)=>{
          if(result.result ==='ok'){
            this.ChangeStateProcess(itemSelected, option, evaluateConcept, child)
          }
        })
    }

    ChangeStateProcess(itemSelected:any, option:any, evaluateConcept:any, child:any){
      let state:any = {
          transitionId: itemSelected.transitionId
      }
      if(itemSelected && itemSelected.hasAlertCustom && option !== "previous"){
          this.modalService.personzalizedNotificationModal(evaluateConcept, itemSelected).subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.loader.openLoader('add-undo-transition');
              state.customBody = result.customEmail.customBody;
              state.customSubject = result.customEmail.customSubject;
              this.ConfigureTransition(state, true, option, evaluateConcept, itemSelected.stateName, child);
            }
          })
      }else{
          this.loader.openLoader('add-undo-transition');
          this.ConfigureTransition(state, false, option, evaluateConcept, itemSelected.stateName, child);
      }
    }

    ConfigureTransition(state:any, personzalizedNotification:any, direction:any, evaluateConcept:any, stepName:any, child:any){
      let event : any = direction === 'next'? 'advance process' : 'back process';
      let callApi : any = direction === 'next'? this.apiService.add('processesdata/' +evaluateConcept.conceptObjectId, state) : this.apiService.delete('processesdata/' + evaluateConcept.conceptObjectId + '/last/' + state.transitionId, state);
      let registerItem : any ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event':event,
          "Step Name": stepName,
          'Concept Name': sessionStorage.getItem('breadcrumbConcept') + evaluateConcept.title,
          'Process Name': evaluateConcept.processStates.next.name,
          'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(registerItem);
      this.AddOrUndoTransition(callApi, evaluateConcept, personzalizedNotification,child);
  }

    AddOrUndoTransition(apiCall:any, evaluateConcept:any, personzalizedNotification:any, child:any){
      apiCall.then((data:any)=>{
          if (data && data.current) {
              let prevStep = evaluateConcept.step;
              this.rechargeFilterStep(data.current, prevStep, evaluateConcept, child);
          }
          this.loader.closeLoader(['add-undo-transition']);
          let text : string = personzalizedNotification? this.translate.instant('states-process.personalized-notification-ok') : this.translate.instant('states-process.not-personalized-ok');
          this.modalService.adviceModal(text, 'info:accept:check-round');
      }, (errorData:any)=>{});
    }

    rechargeFilterStep(processCurrent: any, prevState: any, evaluateConcept: any, child: any){
      let hasIn : any = false;
      let prevIndex : any;
      let item: any;
      child.conceptList.stepList.forEach((step:any, index: any) => {
        if(step.name === processCurrent.name){
            hasIn = true;
            step.selected = child.conceptList.stepListFilter.length?true:false;
            item = step;
        }
        if(step.name === prevState){
            prevIndex = index;
        }
      });
      if(!hasIn){
          item = {
              name: processCurrent.name,
              index: child.conceptList.stepList[child.conceptList.stepList.length-1].index+1,
              selected: child.conceptList.stepListFilter.length?true:false
          }
          child.conceptList.stepList.push(item);
          if(child.conceptList.stepListFilter.length){
              child.conceptList.stepListFilter.push(item);
          }
      }
      let existFilter : any = false;
      for(let i = child.conceptList.conceptList.length-1; i >= 0; i--){
          let concept : any = child.conceptList.conceptList[i];
          if(concept.conceptObjectId === evaluateConcept.conceptObjectId){
              evaluateConcept.step = processCurrent.name;
              child.conceptList.conceptList.splice(i,1, evaluateConcept);
          }
          if(concept.step === prevState){
              existFilter = true;
          }
      }
      if(!existFilter){
        child.conceptList.stepList.splice(prevIndex,1);
        child.conceptList.stepListFilter = [];
        child.conceptList.stepList.forEach((step:any) => {
          if(step.selected){
            child.conceptList.stepListFilter.push(step);
          }
        });
      }
      this.FilterComponentList(child);
    }

    OrderBySearch(e:any, type:any, child: any){
      let newValue: any = e.newValue;
      let action: string = e.action;
      if(action ==='filter'){
        child.conceptList[type+'WordFilter'] = newValue;
        this.FilterComponentList(child);
      }else if(action ==='order'){
        this.OrderDate(type, this);
      }
    }

    ChangeToAnonymatum(item:any, type:string, child:any){
      if(this.context.anonymatumService){
        let text:string = type==='report'? 'documentation-list.anonymatum-advise' : 'concept-list.anonymatum-important-advise';
        if(type==='report' && item.documentStatusId === 4 || item.documentStatusId === 6 ){
          this.modalService.adviceModal(this.translate.instant('concept-list.anonymatum-error-status'), 'error:accept:warning')
        }else{
          if(type==='report'){
            this.modalService.adviceModal(this.translate.instant(text), 'info:question:question')
            .subscribe((result:any)=>{
              if(result.result==='ok'){
                this.AnonymatumDocument(item, type, child)
              }
            })
          }else{
            this.modalService.adviceModal(this.translate.instant(text), 'info:question:question',  '', [], '', true)
              .subscribe((result:any)=>{
                if(result.result==='ok'){
                  this.AnonymatumDocument(item, type, child)
                }
            })
          }

        }
      }else{
        this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-adquired'), 'error:accept:warning',  '', [], '', true);
      }
    }

    AnonymatumDocument(concept:any, type:any, child:any){
      let itemSelected : any = {
          type: type==='report'?'anonymatum-report' : 'anonymatum-document',
          item: concept,
      }
      this.modalService.informativeLoadingModal(itemSelected)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              for(let i = 0; i < child.conceptList.conceptList.length; i++){
                let conceptItem: any = child.conceptList.conceptList[i]
                if(type === 'report' && result.item.reportDocumentId === conceptItem.reportDocumentId){
                    child.conceptList.conceptList.splice(i, 1, result.item)
                    break;
                }else{
                    if(conceptItem.conceptObjectId === result.item.conceptObjectId){
                        let conceptToModify:any = cloneDeep(conceptItem)
                        conceptToModify.author = result.item.createdBy;
                        conceptToModify.title = result.item.title;
                        conceptToModify.anonymized = true;
                        child.conceptList.conceptList.splice(i, 1, conceptToModify);
                        break;
                    }
                }
              }
              this.FilterComponentList(child);
              this.modalService.adviceModal(this.translate.instant('concept-list.anonymatum-concept-ok'), 'info:accept:check-round')
            }else if(result.result ==='cancel'){
              if(result?.error?.description ==="anonymize_no_credits"){
                this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-credits'), 'error:accept:warning',  '', [], '', true)
              }else{
                this.modalService.adviceModal(this.translate.instant('concept-list.anonymatum-concept-error'), 'error:accept:warning')
              }
            }
      })
    }

    RenameConcept(concept: any){
        let newItem : any = {
            title: this.translate.instant('rename-modal.name-instance-rename'),
            type: 'concept',
            titleConcept: concept.title,
            conceptId: concept.conceptId,
            conceptObjectId: concept.conceptObjectId,
            listFolders: [],
            rename: true
        }
        this.modalService.renameModal(newItem)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
                this.loader.openLoader('concept-tab-rename-modal');
                this.changeConceptName(result.name, concept);
            }
          })
    }

    changeConceptName(name: any, concept: any){
        this.apiService.patch('conceptobjects/' + concept.conceptObjectId, 0,{ title: name })
            .then((data:any)=>{
                concept.title = name;
                this.loader.closeLoader(['concept-tab-rename-modal']);
                this.modalService.adviceModal(this.translate.instant('concept-tab.change-name-ok'), 'info:accept:check-round');
            }, (errorData:any)=>{
                this.loader.closeError();
                let text : string = errorData.description==='UqConstraint'?this.translate.instant('concept-tab.error-same-name'):this.translate.instant('concept-tab.error-name');
                this.modalService.adviceModal(text, 'error:accept:warning')
            });
    }

    closeDropdown(type: any, dropdowns: any){
      let dropdown: any = dropdowns.find((x:any)=>{
        return x._elementRef.nativeElement.id == type;
      });
      dropdown.close();
    }

    ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
    }
}
