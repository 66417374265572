import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy} from '@angular/core';

import { FieldTypeFormuParentService } from './../utils/field-type-formu-parent.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'field-type-form-rich',
  templateUrl: './field-type-form-rich.component.html'
})
export class FieldTypeFormRichComponent implements OnInit, OnChanges, OnDestroy {
  isLanding: boolean = this.customLanding.isLanding();
  fieldTypeFormu:any = {};

  constructor(
    private fieldTypeFormuParent: FieldTypeFormuParentService,
    private customLanding: CustomLandingFactory
  ) {}

  @Input() field: any = null;
  @Input() evaluatedConcepts: any = null;
  @Input() evaluatedFields: any = null;
  @Input() concept: any = null;
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeFormuParent.configureInit(this);
  }

  ngOnChanges(){
    this.fieldTypeFormuParent.configureChanges(this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      this.fieldTypeFormuParent.openModalRelatedInfo(this, fieldRelatedInfo, e);
  }

  ngOnDestroy(){
      this.fieldTypeFormuParent.ngOnDestroy(this);
  }
}
