<div class="image-editor">
<angular-cropper #angularCropper
  [cropperOptions]="imageEditor.cropperOptions"
  [imageUrl]="imageUrl">
</angular-cropper>
<div class="image-editor__header-options">
  <div class="image-editor__specifications">
      <p class="configure-image__image-text configure-image__image-text--image-editor">
          <span [afTranslate]="'configure-image.max-size'"></span>
          <span>{{selectedSize.size}}</span>
      </p>
      <p class="image-editor__specifications-preview-text">
          <span [afTranslate]="'configure-image.preview'"></span>:
      </p>
  </div>
  <div class="image-editor__menu-options">
      <ul class="image-editor__menu-options-container">
          <li class="image-editor__menu-options-block">
              <button class="image-editor__menu-options-button image-editor__menu-options-button--zoom-in"
                  (click)="zoom('more')">
                  <i class="lf-icon-zoom-in"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--zoom-out"
                  (click)="zoom('minus')">
                  <i class="lf-icon-zoom-out"></i>
              </button>
          </li>
          <li  class="image-editor__menu-options-block  image-editor__menu-options-block--second">
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large "
                  (click)="moveTo('up')">
                  <i class="lf-icon-arrow-up"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--arrow-down"
                  (click)="moveTo('down')">
                  <i class="lf-icon-arrow-down"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--arrow-left"
                  (click)="moveTo('left')">
                  <i class="lf-icon-arrow-left"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--arrow-right"
                  (click)="moveTo('right')">
                  <i class="lf-icon-arrow-right"></i>
              </button>
          </li>
          <li  class="image-editor__menu-options-block image-editor__menu-options-block--third">
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--rotate-left"
                  (click)="rotate('-')">
                  <i class="lf-icon-rotate"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--rotate-right"
                  (click)="rotate('')">
                  <i class="lf-icon-rotate"></i>
              </button>

          </li>
          <li  class="image-editor__menu-options-block">
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--flip-x"
                  (click)="flip('x')">
                  <i class="lf-icon-version"></i>
              </button>
              <button class="image-editor__menu-options-button image-editor__menu-options-button--large image-editor__menu-options-button--flip-y"
                  (click)="flip('y')">
                  <i class="lf-icon-version"></i>
              </button>
          </li>
      </ul>
  </div>
  <img class="image-editor__preview-image"
    [src]="imageEditor.preview"
    alt="Preview view image"
    [ngStyle]="{'height': selectedSize.height, 'width': selectedSize.width}"/>
  <div class="common__buttons-block rename-modal__buttons-block">
    <button type="reset" class="common__button common__button--clean"
        (click)="closeEdit()"
        [afTranslate]="'common.discard'"></button>
    <button type="submit" class="common__button common__button--active"
        (click)="saveAndCloseEdit()"
        [afTranslate]="'common.save'"></button>
  </div>
</div>
