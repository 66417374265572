<div class="help-tooltip__info-advanced-block ">
  <button class="lf-icon-close help-tooltip__close-info-advanced-block "
      (click)="closeTooltip()"></button>
  <div>
      <i class="lf-icon-intellectual help-tooltip__icon-head-info-advanced"></i>
      <h4 class="help-tooltip__title-head-info-advanced">{{helpTooltip.title}}</h4>
      <p class="help-tooltip__text-head-info-advanced">{{helpTooltip.textInfo}}</p>
  </div>
  <div class="help-tooltip__block-buttons">
      <button class="help-tooltip__button-hide-all-info-advanced "
          (click)="hideHelpTooltip()"
          [afTranslate]="'help-tooltip.dont-show-help'">_No mostrar más ayudas</button>
      <button class="common__button-border-none  float-end"
          (click)="hideHelpTooltipToFolder()">
          <i class="lf-icon-especial common__icon-button-underlined"></i>
          <span class="common__text-button-underlined"
              [afTranslate]="'help-tooltip.understood'">_Entendido</span>
      </button>
  </div>
</div>
