<div class="blocked-edition-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-not-editable common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="'blocked-edition-modal.title-header'"></span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="blocked-edition-modal__body">
      <div class="blocked-edition-modal__body-inner">
          <p class="common__title-block-modal blocked-edition-modal__title-block-modal" [afTranslate]="'blocked-edition-modal.info-modal'"></p>
          <field-type-list-extended [field]="blockedEditionModal.processStates"
              [preSelectItem]="processStatesSelected"
              [listValues]="blockedEditionModal.processStatesList"
              (itemSelected)="processSelected($event)">
          </field-type-list-extended>
      </div>
      <div class="common__buttons-block common__buttons-block--modal float-end">
          <button type="submit" class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'"></button>
          <button type="submit" class="common__button common__button--active common__button--separating-margin"
              (click)="handleAccept()"
              [afTranslate]="'common.save'"></button>
      </div>
  </div>
</div>
