<div class="custom-report-list">
  <div class="custom-reports__add-report-button">
      <button class="lf-icon-sum common__button-checked"
          (click)="changeView.emit({view: customReportList.newCustomReportView})"
          matTooltip="{{'custom-report-list.new-report' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left">
      </button>
  </div>
  <div class="custom-reports__table">
      <div class="common__table-header-flex">
          <div class="custom-report-list__table-col custom-report-list__table-col--is-custom-report-header custom-report-list__table-col--top-1">
              <filter-by-list [type]="'list'"
                              [item]="customReportList.isCustomFilter"
                              [list]="customReportList.isCustomList"
                              (filterElementEmit)="selectTypeOperation($event, 'isCustom')">
              </filter-by-list>
          </div>
          <div ngbDropdown class="custom-report-list__table-col custom-report-list__table-col--report-name-report-header custom-report-list__table-col--top-2"
              [open]="customReportList.openFocusReportName">
              <p ngbDropdownToggle class="common__table-header-text common__table-header-text--without-cursor"
                  [afTranslate]="'custom-report-list.header-table.report-name'"></p>
              <i ngbDropdownToggle class="lf-icon-filter-1 common__filter-icon"
                  [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': customReportList.reportNameWordFilter.length}"></i>
              <i class="lf-icon-arrow-down common__order-by"
                  [ngClass]="{
                    'lf-icon-arrow-up': customReportList.productNameOrder === 'reportName' && customReportList.orderOperationProductName === false,
                    'common__order-by--active': customReportList.productNameOrder === 'reportName'
                  }"
                  (click)="orderProductName('reportName')"></i>
              <ul ngbDropdownMenu class="common__select-list-with-input common__select-list-with-input--top-26">
                  <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                      <input class="common__dropdown-inner-input"
                              (keyup)="filter('reportName')"
                              placeholder="{{'custom-report-list.search.report-name'|afTranslate}}"
                              [(ngModel)]="customReportList.reportNameWordFilter"
                              [focusWhen]="customReportList.openFocusReportName">
                      <i class="common__search-icon common__search-icon--reset"
                          [ngClass]="customReportList.reportNameWordFilter === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                          (click)="resetFilter($event,'reportName')"></i>
                  </li>
              </ul>
          </div>
          <div class="custom-report-list__table-col custom-report-list__table-col--report-fields-header custom-report-list__table-col--top-2">
              <div ngbDropdown [open]="customReportList.openFocusReportFields">
                  <div ngbDropdownToggle>
                      <p class="common__table-header-text common__table-header-text--without-cursor"
                         [afTranslate]="'custom-report-list.header-table.report-fields'"></p>
                      <i class="lf-icon-filter-1 common__filter-icon"
                         [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': customReportList.reportFieldsWordFilter.length}"></i>
                  </div>
                  <ul ngbDropdownMenu class="common__select-list-with-input common__select-list-with-input--top-26">
                      <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                          <input class="common__dropdown-inner-input"
                                 (keyup)="filter('reportFieldsText')"
                                 placeholder="{{'custom-report-list.search.report-fields'|afTranslate}}"
                                 [(ngModel)]="customReportList.reportFieldsWordFilter"
                                 [focusWhen]="customReportList.openFocusReportFields">
                          <i class="common__search-icon common__search-icon--reset"
                             [ngClass]="customReportList.reportFieldsWordFilter === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                             (click)="resetFilter($event, 'reportFields')"></i>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="custom-report-list__table-col custom-report-list__table-col--action-report-header">
              <p class="common__table-header-text common__table-header-text--right"
                 [afTranslate]="'common.actions'"></p>
          </div>
      </div>
      <div class="custom-reports__table-body">
          <div class="common__table-row-flex"
               *ngFor="let report of customReportList.filterReportList | afOrderBy:customReportList.productNameOrder:customReportList.orderOperationProductName | afLimitTo:customReportList.configPagination.pageSize:customReportList.configPagination.pageSize*(customReportList.currentPage - 1); let $index = index">
              <div class="custom-report-list__table-col custom-report-list__table-col--is-custom-report">
                  <div class="common__cell-block">
                      <i *ngIf="!report.isCustom" class="lf-icon-template task-list__icon-type"></i>
                      <i *ngIf="report.isCustom" class="lf-icon-template-user task-list__icon-type"></i>
                  </div>
              </div>
              <div class="custom-report-list__table-col custom-report-list__table-col--report-name-report">
                <parragraph-tooltip [text]="report.reportName"></parragraph-tooltip>
              </div>
              <div class="custom-report-list__table-col custom-report-list__table-col--report-fields">
                  <window-list [headerIcon]="'lf-icon-localization'"
                                [titleText]="'new-custom-report.report-fields' | afTranslate"
                                [items]="report.reportFields"
                                [itemIcon]="'lf-icon-arrow-exchange'"
                                (triggerIconEvent)="showFieldAssignments($event)"
                                [type]="'customReportField'"></window-list>
              </div>
              <div class="custom-report-list__table-col custom-report-list__table-col--action-report">
                  <ul class="common__options-row-flex">
                      <li class="common__menu-options-row"
                          *ngIf="!report.isCustom"
                          (click)="viewPredefinedReport.emit({ predefinedReport: report })">
                          <i class="lf-icon-visible"
                              matTooltip="{{'common.view' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="report.isCustom"
                          (click)="editCustomReport.emit({ customReport: report })">
                          <i class="lf-icon-editable-form"
                              matTooltip="{{'common.edit' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row"
                          *ngIf="!report.isCustom && report.isDuplicable"
                          (click)="duplicatePredefinedReport.emit({ predefinedReport: report })">
                          <i class="lf-icon-paste"
                              matTooltip="{{'common.duplicate' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row">
                          <i class="lf-icon-trash"
                              *ngIf="report.isCustom"
                              (click)="goToDelete($event, report)"
                              matTooltip="{{'common.remove' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                  </ul>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
              </div>
          </div>
      </div>
      <div class="custom-reports__pagination">
        <pagination-control [ngShow]="customReportList.filterReportList.length > customReportList.configPagination.pageSize"
          [config]="customReportList.configPagination"
          [collectionSize]="customReportList.filterReportList.length"
          [(currentPage)]="customReportList.currentPage"></pagination-control>
        <div class="common__block-footer-total common__block-footer-total--bottom-2">
          <span [afTranslate]="'common.total'"></span>
          <span class="common__total-number-element notranslate">{{customReportList.filterReportList.length}}</span>
        </div>
      </div>
      <advice-message class="custom-report-list__advice-block"
          [ngShow]="customReportList.showInfo"
          [icon]="'information'"
          [type]="'info'"
          [text]="customReportList.textInfo"
          (closeAdvice)="customReportList.showInfo = false">
      </advice-message>
  </div>
</div>
