<div class="client-details clients-list__block-list-v3">
  <div class="common__header-new-version">
      <p class="client-details__header-title" [afTranslate]="'client-details.title'"></p>
      <div class="client-details__header-menu" [ngShow]="selectClient">
          <i class="client-details__header-icon"
              [ngClass]="selectClient?.canAdmin ? 'lf-icon-editable-form' : 'lf-icon-visible'"
              matTooltip="{{(selectClient?.canAdmin ? 'new-client.edit-client' : 'common.view') | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left"
              (click)="editClientDetail()">
          </i>
          <i class="lf-icon-trash client-details__header-icon client-details__header-icon--trash"
              *ngIf="selectClient?.canAdmin"
              matTooltip="{{'common.remove' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left"
              (click)="deleteDetailClient()">
          </i>
      </div>
  </div>
  <div class="client-details__body"
      [ngShow]="selectClient">
      <div class="client-details__select-client-block">
          <p class="client-details__select-client-name">
              {{selectClient?.name}}
          </p>
      </div>
      <dl class="client-details__client-detail-block">
          <dt class="client-details__property">
              <span [afTranslate]="'client-details.client-type'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--left">
              {{selectClient?.type}}
          </dd>
      </dl>
      <dl class="client-details__client-detail-block">
          <dt class="client-details__property">
              <span [afTranslate]="'client-details.our-references'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--right">
              {{selectClient?.reference2}}
          </dd>
      </dl>
      <dl class="client-details__client-detail-block">
          <dt class="client-details__property">
              <span [afTranslate]="'common.address'"></span>:</dt>
          <dd class="client-details__response client-details__response--left">
              <p class="client-details__propertys-address">{{selectClient?.address}}</p>
              <p class="client-details__propertys-address">{{selectClient?.city}}</p>
              <p class="client-details__propertys-address">{{selectClient?.country}}</p>
              <p class="client-details__propertys-address">{{selectClient?.postalCode}}</p>
          </dd>
      </dl>
      <dl class="client-details__client-detail-block client-details__client-detail-block--height">
          <dt  class="client-details__property">
              <span [afTranslate]="'client-details.tax-references'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--right">{{selectClient?.cif}}</dd>
      </dl>
      <dl class="client-details__client-detail-block">
          <dt  class="client-details__property">
              <span [afTranslate]="'client-details.telephone'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--left">{{selectClient?.phoneNumber}}</dd>
      </dl>
      <dl class="client-details__client-detail-block">
          <dt class="client-details__property">
              <span [afTranslate]="'common.email'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--right">{{selectClient?.email}}</dd>
      </dl>
      <dl class="client-details__client-detail-block-all">
          <dt class="client-details__property">
              <span [afTranslate]="'contacts.contacts'"></span>:
          </dt>
          <dd class="client-details__response client-details__response--left">{{selectClient?.contactsName}}</dd>
      </dl>
  </div>
</div>
