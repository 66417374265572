import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import moment from 'moment';

@Component({
  selector: 'schedule-next-event',
  templateUrl: './schedule-next-event.component.html'
})
export class ScheduleNextEventComponent implements OnInit {
  scheduleNextEvent: any = {
    lastEvent: {},
  };
  constructor(private router: Router,
    private context: ContextFactory,
    private stateManagement: StateManagementFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private registerService: RegisterMixtapeService,
    private dateConverterService: DateConverterService) { }

  ngOnInit(): void {
    this.getLastEventSchedule();
  }

  getLastEventSchedule(){
    this.apiService.get('events?future=true&count=1')
      .then((data:any)=>{
          this.scheduleNextEvent.lastEvent = data[0];
          if(this.scheduleNextEvent.lastEvent){
            this.configureEvent();
          }
      });
  }

  configureEvent(){
    let dateMeeting = this.dateConverterService.convertDate(this.scheduleNextEvent.lastEvent.start, 'calendar-year');
    this.scheduleNextEvent.lastEvent.detailMeeting = dateMeeting;
    this.scheduleNextEvent.lastEvent.typeTitle = this.translate.instant('anti-money-laundering-panel.task-due');
    switch(this.scheduleNextEvent.lastEvent.type){
        case 'alert':
            this.scheduleNextEvent.lastEvent.typeTitle = this.translate.instant('anti-money-laundering-panel.tracking-alert');
            this.scheduleNextEvent.lastEvent.icon = 'lf-icon-notification';
            break;
        case 'meeting':
            this.scheduleNextEvent.lastEvent.typeTitle = this.translate.instant('anti-money-laundering-panel.meeting');
            this.scheduleNextEvent.lastEvent.icon = this.scheduleNextEvent.lastEvent.videoconference?'lf-icon-videoconference':'lf-icon-meeting';
            this.scheduleNextEvent.lastEvent.detailMeeting = this.scheduleNextEvent.lastEvent.location?this.scheduleNextEvent.lastEvent.location+', '+dateMeeting:dateMeeting;
            if (this.scheduleNextEvent.lastEvent.end) {
              let when = moment(this.scheduleNextEvent.lastEvent.start).format('HH:mm');
              when += ' - ' + moment(this.scheduleNextEvent.lastEvent.end).format('HH:mm');
              this.scheduleNextEvent.lastEvent.detailMeeting += ', ' + when;
            }
            break;
        case 'evaluation':
            this.scheduleNextEvent.lastEvent.icon = this.scheduleNextEvent.lastEvent.hasRepeatPattern?'lf-icon-periodic-evaluation':'lf-icon-step-done';
            break;
        case 'informationRequest':
            this.scheduleNextEvent.lastEvent.icon = this.scheduleNextEvent.lastEvent.hasRepeatPattern?'lf-icon-periodic-documentation':'lf-icon-document';
            break;
        case 'process':
            this.scheduleNextEvent.lastEvent.icon = 'lf-icon-process-settings';
            break;
        default:
            this.scheduleNextEvent.lastEvent.icon = this.scheduleNextEvent.lastEvent.hasRepeatPattern?'lf-icon-periodic-task':'lf-icon-task';
            break;
    }
  }

  goToSchedule(){
    let resgisterItem ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'open agenda',
        'Source':'Header',
        'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem);
    this.stateManagement.setStateUrl(window.location.pathname);
    this.router.navigate(['schedule']);
  }
}
