import { Component, OnInit, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'box-query-state',
  templateUrl: './box-query-state.component.html'
})
export class BoxQueryStateComponent implements OnInit {
  skin: string = this.globalCfg.skin
  boxQueryState: any = {
    actualState: {}
  }
  constructor(private globalCfg: GlobalCfgFactory) { }

  @Input() concept: any = null;

  ngOnInit(): void {
    let that: any = this.boxQueryState;
    that.actualState = {
      name: this.concept.processStates.current.alias? this.concept.processStates.current.alias : this.concept.processStates.current.name,
      state: this.configureProcessState()
    }
  }
  configureProcessState(){
    let processState: any = this.concept.processStates;
    if(!processState.previous){
        return 'initial';
    }else if(!processState.next || (processState.next && !processState.next.length)){
        return 'finished';
    }
    return 'in-process';
  }
}

