<div class="new-custom-report-field-assignment__block-body">
  <field-type-rich-text class="new-custom-report__block-info"
      [field]="newCustomReportFieldAssignment.identityInfo"
      *ngIf="newCustomReportFieldAssignment.identityInfo">
  </field-type-rich-text>
  <div class="new-custom-report-field-assignment__field-assignment-container">
      <div class="new-custom-report-field-assignment__table-container">
          <div class="new-custom-report-field-assignment__header-title-block">
              <i class="lf-icon-localization new-custom-report-field-assignment__header-icon"></i>
              <span class="new-custom-report-field-assignment__header-title">{{newCustomReportField.customReportFieldName}}</span>
              <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                  [disabled]="predefinedReportField"
                  (click)="showFieldAssignmentModal()"
                  matTooltip="{{'new-custom-report-field-assignment.new-assignment' | afTranslate}}"
                  matTooltipPosition="left"
                  matTooltipClass="left">
              </button>
          </div>
          <div class="new-custom-report-field-assignment__body">
              <div class="new-custom-report-field-assignment__body-empty-container"
                  [ngShow]="!newCustomReportField.selectedReportFields.length">
                  <span class="new-custom-report-field-assignment__body-empty-span" [afTranslate]="'new-custom-report-field-assignment.empty-fields'"></span>
              </div>
              <div class="new-custom-report-field-assignment__body-container"
                  [ngShow]="newCustomReportField.selectedReportFields.length">
                  <div class="new-custom-report-field-assignment__field-column" *ngFor="let newField of newCustomReportField.selectedReportFields">
                      <div class="new-custom-report-field-assignment__field-name-line">
                          <i class="{{newField.fieldType | fieldTypeIcons}} new-custom-report-field-assignment__icon-type"></i>
                          <div class="new-custom-report-field-assignment__cell-block">
                              <span-tooltip [text]="newField.fieldName"></span-tooltip>
                          </div>
                      </div>
                      <div class="new-custom-report-field-assignment__path-line">
                          <i class="lf-icon-map-marker new-custom-report-field-assignment__icon-marker-path"></i>
                          <div class="new-custom-report-field-assignment__cell-block new-custom-report-field-assignment__cell-block--path">
                              <span-tooltip [text]="newField.path"></span-tooltip>
                          </div>
                      </div>
                      <div class="new-custom-report-field-assignment__tongue-actions">
                          <div class="new-custom-report-field-assignment__options-box"
                               *ngIf="!predefinedReportField">
                              <i class="lf-icon-trash clients-list__icon-options"
                                  (click)="deleteOption($event, newField)"
                                  matTooltip="{{'common.remove' | afTranslate}}"
                                  matTooltipPosition="below"
                                  matTooltipClass="below"></i>
                          </div>
                          <i class="lf-icon-kebab-menu common__icon-ellipsis" [ngClass]="{'common__icon-ellipsis--disabled': predefinedReportField}"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <advice-message [ngShow]="validationErrors.assignment"
      [icon]="'warning'"
      [type]="'error'"
      [text]="newCustomReportFieldAssignment.infoError"
      [typeClass]="'no-margin'"
      (closeAdvice)="validationErrors.assignment = false;">
  </advice-message>
</div>
