<div class="heat-map-graph">
    <div class="heat-map-graph__tooltip-box"
        [id]="graphId">
        <div class="heat-map-graph__tooltip-block"
            *ngIf="heatMapGraph.heatPointSelect !== null">
                <div class="heat-map-graph__tooltip-triangle"></div>
                <i class="lf-icon-close heat-map-graph__tooltip-graph-close"
                    (click)="closeTooltip()"></i>
                <div class="heat-map-graph__tooltip-header-block"
                    [ngClass]="{'heat-map-graph__tooltip-header-block--unique':heatMapGraph.specialGraphAD}">
                    <i class="lf-icon-step-final heat-map-graph__icon"
                        [ngShow]="heatMapGraph.specialGraphAD"
                        [ngStyle]="{'color':heatMapGraph.heatPointSelect.backgroundColor}"></i>
                    <p class="heat-map-graph__tooltip-graph-title"
                        [ngClass]="{'heat-map-graph__tooltip-graph-title--unique':heatMapGraph.specialGraphAD}">
                        {{heatMapGraph.heatPointSelect.cellText}}
                    </p>
                </div>
                <div class="heat-map-graph__heatpoint-block"
                    [ngHide]="heatMapGraph.specialGraphAD">
                    <div class="heat-map-graph__heatpoint-dot"
                        [ngStyle]="{'background': heatMapGraph.heatPointSelect.yPointColor}"></div>
                    <p class="heat-map-graph__heatpoint-text">
                        {{'heat-map-graph.probability' | afTranslate}} {{heatMapGraph.heatPointSelect.y}}
                    </p>
                </div>
                <div class="heat-map-graph__heatpoint-block"
                    [ngHide]="heatMapGraph.specialGraphAD">
                    <div class="heat-map-graph__heatpoint-dot"
                            [ngStyle]="{'background': heatMapGraph.heatPointSelect.xPointColor} "></div>
                    <p class="heat-map-graph__heatpoint-text">
                        {{'heat-map-graph.impact' | afTranslate}} {{heatMapGraph.heatPointSelect.x}}
                    </p>
                </div>

                <ul class="heat-map-graph__risk-list"
                    [ngClass]="{'heat-map-graph__risk-list--unique':heatMapGraph.specialGraphAD}">
                    <li class="heat-map-graph__risk-item"
                        [ngClass]="{'heat-map-graph__risk-item--unique':heatMapGraph.specialGraphAD}"
                        *ngFor="let risk of heatMapGraph.heatPointSelect.risks">
                        <div [ngShow]="heatMapGraph.specialGraphAD">
                            <p class="heat-map-graph__risk-item-text"
                                (click)="openRisk(risk)">
                                {{risk.risk}}
                            </p>
                            <div class="heat-map-graph__heatpoint-block">
                                <p class="heat-map-graph__heatpoint-text"
                                    [ngClass]="{'heat-map-graph__heatpoint-text--unique':heatMapGraph.specialGraphAD}">
                                    {{'heat-map-graph.probability' | afTranslate}} {{heatMapGraph.heatPointSelect.y}}
                                </p>
                            </div>
                            <div class="heat-map-graph__heatpoint-block">
                                <p class="heat-map-graph__heatpoint-text">
                                    {{'heat-map-graph.impact' | afTranslate}} {{heatMapGraph.heatPointSelect.x}}
                                </p>
                            </div>
                        </div>
                        <div [ngHide]="heatMapGraph.specialGraphAD"
                            class="heat-map-graph__risk-item-block">
                            <i class="lf-icon-danger heat-map-graph__risk-item-icon"></i>
                            <p class="heat-map-graph__risk-item-text"
                                (click)="openRisk(risk)">{{risk.risk}}</p>
                        </div>
                    </li>
                </ul>
        </div>
    </div>
</div>
