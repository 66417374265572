<label [for]="'switch-' + id"
    class="field-type-switch"
    [ngClass]="{'field-type-switch--disabled': disabled}">
    <input type="checkbox" [id]="'switch-' + id" class="field-type-switch__input"
           [value]="value"
           [(ngModel)]="value"
           [disabled]="disabled"
           (change)="toggleSwitch()">
    <i class="field-type-switch__icon"
       [ngClass]="{'lf-icon-switch-right': checked,
            'lf-icon-switch-left field-type-switch__icon--unselected': !checked,
            'field-type-switch__icon--selected': checked && !negativeColor,
            'field-type-switch__icon--negative': checked && negativeColor}"></i>
    <span class="field-type-switch__label"
          [ngClass]="{'field-type-switch__label--negative': negativeColor}">{{label}}</span>
    <i [ngShow]="description"
          class="lf-icon-info-full field-type-switch__icon-info"
          matTooltip="{{description}}"
          matTooltipPosition="above"
          matTooltipClass="above">
    </i>
</label>
