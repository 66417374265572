<div class="duplicate-documentation">
    <div class="duplicate-documentation__block-context">
        <div class="common__modal-header-block">
            <i class="lf-icon-import-doc common__icon-header"></i>
            <span class="common__text-header"
                [afTranslate]="'duplicate-documentation.add-documentation'">
                    _Añadir documentos de otro concepto
                </span>
            <i class="lf-icon-close duplicate-documentation__icon-close-modal"
                (click)="handleDismiss()"></i>
        </div>
        <div class="duplicate-documentation__block-body">
            <div class="duplicate-documentation__block-search-init"
                [ngShow]="duplicateDocumentation.stepShow === 0">
                <p class="duplicate-documentation__text-search"
                    [afTranslate]="'duplicate-documentation.text-init-1'">_Usa el buscador para localizar directamente los archivos...</p>
                <div class="duplicate-documentation__block-input-search">
                    <input type="text"
                        class="header-nav__search-input header-nav__search-input--duplicate"
                        [ngClass]="{'header-nav__search-input--with-text': duplicateDocumentation.searchText}"
                        [(ngModel)]="duplicateDocumentation.searchText"
                        (keypress)="goToPressEnter($event)"
                        [placeholder]="'common.search' | afTranslate">
                    <button class="header-nav__icon-button header-nav__icon-button--duplicate"
                        (click)="goToSearch()">
                        <i class="header-nav__icon-search lf-icon-search"></i>
                    </button>
                </div>

                <p class="duplicate-documentation__text-search"
                    [afTranslate]="'duplicate-documentation.text-init-2'">_... o explora tus implantaciones paso a paso.</p>
                <button class="common__button-border-none"
                    (click)="changeView()">
                    <i class="lf-icon-review common__icon-button-underlined"></i>
                    <span class="common__text-button-underlined"
                        [afTranslate]="'duplicate-documentation.start-scan'">_Iniciar exploración</span>
                </button>
            </div>

            <!-- Pantalla con resultados del buscador -->
            <div class="duplicate-documentation__block-search-result"
                *ngIf="duplicateDocumentation.stepShow === 1">
                <p class="duplicate-documentation__text-search-header"
                    [afTranslate]="'result-search.search-result'">_Resultados de la búsqueda</p>
                <div class="duplicate-documentation__block-table-results">
                    <div class="duplicate-documentation__block-input-search-step-1">
                        <input type="text"
                            class="header-nav__search-input header-nav__search-input--duplicate"
                            [ngClass]="{'header-nav__search-input--with-text': duplicateDocumentation.searchText}"
                            [(ngModel)]="duplicateDocumentation.searchText"
                            (keypress)="goToPressEnter($event)"
                            [placeholder]="'common.search' | afTranslate">
                        <button class="header-nav__icon-button header-nav__icon-button--duplicate"
                                (click)="goToSearch()">
                            <i class="header-nav__icon-search"
                                [ngClass]="duplicateDocumentation.searchText !== '' && duplicateDocumentation.copySearchText === duplicateDocumentation.searchText?'lf-icon-close-search':'lf-icon-search'"></i>
                        </button>
                    </div>
                    <div class="duplicate-documentation__table-container">
                        <!-- Cabecera -->
                        <div class="common__table-header duplicate-documentation__table-header">
                            <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                <label for="multiple-selection-all-select-duplicate-search"
                                    class="documentation-list__label-multiple">
                                    <input class="documentation-list__input-multiple"
                                        type="checkbox"
                                        id="multiple-selection-all-select-duplicate-search"
                                        (click)="selectAllDocumentCheckDuplicate()"
                                        [checked]="duplicateDocumentation.selectAllDocument">
                                    <i class="documentation-list__icon-multiple"
                                        [ngClass]="duplicateDocumentation.selectAllDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                </label>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--format">
                                <filter-by-list [type]="'list'"
                                    [item]="duplicateDocumentation.extensionFilter"
                                    [list]="duplicateDocumentation.extensionList"
                                    (filterElementEmit)="selectTypeOperation($event, 'extension')">
                                </filter-by-list>
                            </div>
                            <div class="duplicate-documentation__table"
                                [ngClass]="skin === 'icam-red'?'duplicate-documentation__table--title-only':'duplicate-documentation__table--title'">
                                <filter-by-search
                                    [item]="duplicateDocumentation.titleFilter"
                                    (launchActionSearch)="orderBySearch($event, 'Name')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--implantation"
                                *ngIf="skin !== 'icam-red'">
                                <filter-by-search
                                    [item]="duplicateDocumentation.implantationFilter"
                                    (launchActionSearch)="orderBySearch($event, 'EvaluationName')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--organization"
                                *ngIf="skin !== 'icam-red'">
                                <filter-by-search
                                    [item]="duplicateDocumentation.organizationFilter"
                                    (launchActionSearch)="orderBySearch($event, 'ClientName')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--creation-date">
                                <div class="common__cursor-pointerr"
                                    (click)="orderDate('CreationDate')">
                                    <p class="common__table-header-text"
                                        [afTranslate]="'common.creation'">_Creación</p>
                                    <i class="common__order-by "
                                        [ngClass]="{'lf-icon-arrow-down':(duplicateDocumentation.dateOrder==='CreationDate' && duplicateDocumentation.orderOperationDates) || duplicateDocumentation.dateOrder !=='CreationDate',
                                            'lf-icon-arrow-up':duplicateDocumentation.dateOrder==='CreationDate' && !duplicateDocumentation.orderOperationDates,
                                            'common__order-by--active': duplicateDocumentation.dateOrder==='CreationDate'}"></i>
                                </div>
                            </div>
                        </div>
                        <!-- Contenido -->
                        <div class="duplicate-documentation__table-body"
                            [ngClass]="{'duplicate-documentation__table-body--more-elements':duplicateDocumentation.filterDocumentationList.length >= (duplicateDocumentation.configPagination.pageSize*duplicateDocumentation.currentPage)-3}">
                            <img [src]="duplicateDocumentation.loaderImg"
                                class="duplicate-documentation__spinner-loader"
                                [ngShow]="duplicateDocumentation.searchingDocuments" alt="loader">
                            <div class="duplicate-documentation__table-row"
                                [ngClass]="{'duplicate-documentation__table-row--selected': document.selectedDocument}"
                                [ngHide]="duplicateDocumentation.searchingDocuments"
                                (click)="selectMultipleDocument(document)"
                                *ngFor="let document of duplicateDocumentation.filterDocumentationList
                                    | afOrderBy:duplicateDocumentation.dateOrder:duplicateDocumentation.orderOperationDates
                                    | afLimitTo:duplicateDocumentation.configPagination.pageSize:duplicateDocumentation.configPagination.pageSize*(duplicateDocumentation.currentPage - 1); let index = index">
                                <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                    <label [for]="'multiple-selection-duplicate-'+index+'-select'"
                                        class="documentation-list__label-multiple">
                                        <input class="documentation-list__input-multiple"
                                            type="checkbox"
                                            [id]="'multiple-selection-duplicate-'+index+'-select'"
                                            (click)="selectMultipleDocument(document)"
                                            [checked]="document.selectedDocument">
                                        <i class="documentation-list__icon-multiple"
                                            [ngClass]="document.selectedDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                    </label>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--format">
                                    <i [class]="'duplicate-documentation__table-icon '+ (document.extension | extensionDocument)"></i>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--title"
                                    [ngClass]="skin === 'icam-red'?'duplicate-documentation__table--title-only':'duplicate-documentation__table--title'">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="document.Name"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--implantation"
                                    [ngShow]="skin !== 'icam-red'">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="document.EvaluationName"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--organization"
                                    [ngShow]="skin !== 'icam-red'">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="document.ClientName"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--creation-date">
                                    <span class="common__col-text">{{document.CreationDate | convertDate:'without-time'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Paginación -->
                    <div class="duplicate-documentation__pagination">
                        <pagination-control [ngShow]="duplicateDocumentation.filterDocumentationList?.length > duplicateDocumentation.configPagination.pageSize && !duplicateDocumentation.searchingDocuments"
                          [collectionSize]="duplicateDocumentation.filterDocumentationList?.length"
                          [config]="duplicateDocumentation.configPagination"
                          [(currentPage)]="duplicateDocumentation.currentPage"></pagination-control>
                        <div class="duplicate-documentation__count-documentation">
                            <span [afTranslate]="'common.total'">_Total:</span>
                            <span>{{duplicateDocumentation.filterDocumentationList.length}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pantalla de escaneo de implantaciones -->
            <div class="duplicate-documentation__block-search-result"
                *ngIf="duplicateDocumentation.stepShow === 2">
                <p class="duplicate-documentation__text-search-header"
                    [afTranslate]="'duplicate-documentation.title-scan'">_¿A qué implantación pertenecen los documentos que quieres importar?</p>
                <div class="duplicate-documentation__block-table-results">
                    <!-- Caja filtro producto -->
                    <div class="duplicate-documentation__input-block"
                        [ngShow]="skin !== 'uk-steel-blue'">
                        <i class="lf-icon-close filter-header__icon-selected filter-header__icon-selected--duplicate"
                            [ngShow]="duplicateDocumentation.selectedProduct"
                            (click)="resetFilterProduct()"></i>
                        <div class="duplicate-documentation__dropdown-filter"
                            ngbDropdown
                            ngbDropdownToggle
                            (openChange)="duplicateDocumentation.showProductList = !duplicateDocumentation.showProductList"
                            [ngClass]="{'duplicate-documentation__dropdown-filter--open': duplicateDocumentation.showProductList || duplicateDocumentation.selectedProduct}">
                            <div class="duplicate-documentation__title-block-filter">
                                <i class="lf-icon-filter-2 duplicate-documentation__icon-filter"></i>
                                <span class="duplicate-documentation__title-name-filter">{{duplicateDocumentation.nameProduct}}</span>
                            </div>
                            <ul class="dropdown-menu common__select-list duplicate-documentation__select-list"
                                ngbDropdownMenu
                                role="menu">
                                <li class="common__select-item duplicate-documentation__list-item"
                                    *ngFor="let product of duplicateDocumentation.productList | afOrderBy:['name']:false | byWord:duplicateDocumentation.searchProduct:['name']"
                                    (click)="selectProduct(product)">
                                    <div class="common__table-cell-tooltip">
                                        <div class="duplicate-documentation__cell-block">
                                            <span-tooltip [text]="product.name"></span-tooltip>
                                        </div>
                                        <i class="lf-icon-check-round-full duplicate-documentation__select-icon"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="duplicate-documentation__table-container">
                        <!-- Cabecera -->
                        <div class="common__table-header duplicate-documentation__table-header">
                            <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan"></div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--implantation-scan">
                                <filter-by-search
                                    [item]="duplicateDocumentation.implantationFilter"
                                    (launchActionSearch)="orderBySearch($event, 'name')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--organization-scan">
                                <filter-by-search
                                    [item]="duplicateDocumentation.organizationFilter"
                                    (launchActionSearch)="orderBySearch($event, 'clientName')">
                                </filter-by-search>
                            </div>
                        </div>
                        <!-- Contenido -->
                        <div class="duplicate-documentation__table-body duplicate-documentation__table-body--product"
                            [ngClass]="{'duplicate-documentation__table-body--more-elements':duplicateDocumentation.filterEvaluationList.length >= (duplicateDocumentation.configPagination.pageSize*duplicateDocumentation.currentPage)-3}">
                            <img [src]="duplicateDocumentation.loaderImg"
                                class="duplicate-documentation__spinner-loader"
                                [ngShow]="duplicateDocumentation.searchingEvaluations" alt="loader">
                            <div class="duplicate-documentation__table-row"
                                [ngShow]="!duplicateDocumentation.searchingEvaluations"
                                *ngFor="let documentEvaluation of duplicateDocumentation.filterEvaluationList
                                    | afLimitTo:duplicateDocumentation.configPagination.pageSize:duplicateDocumentation.configPagination.pageSize*(duplicateDocumentation.currentPage - 1)"
                                (click)="selectEvaluation(documentEvaluation)">
                                <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
                                    <i [class]="'duplicate-documentation__icon-product-scan '+documentEvaluation.icon"></i>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--implantation-scan">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="documentEvaluation.name"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--organization-scan">
                                    <div class="duplicate-documentation__cell-block duplicate-documentation__cell-block--organization">
                                        <parragraph-tooltip [text]="documentEvaluation.clientName"></parragraph-tooltip>
                                    </div>
                                    <span class="duplicate-documentation__text-explore"
                                        [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
                                    <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- Paginación -->
                    <div class="duplicate-documentation__pagination">
                        <pagination-control [ngShow]="duplicateDocumentation.filterEvaluationList?.length > duplicateDocumentation.configPagination.pageSize && !duplicateDocumentation.searchingEvaluations"
                          [collectionSize]="duplicateDocumentation.filterEvaluationList?.length"
                          [config]="duplicateDocumentation.configPagination"
                          [(currentPage)]="duplicateDocumentation.currentPage"></pagination-control>
                        <div class="duplicate-documentation__count-documentation">
                            <span [afTranslate]="'common.total'">Total:</span>
                            <span>{{duplicateDocumentation.filterEvaluationList.length}}</span>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Pantalla de exploración de la evaluación con migas de pan-->
            <div class="duplicate-documentation__block-search-result"
                *ngIf="duplicateDocumentation.stepShow === 3 || duplicateDocumentation.stepShow === 4 || duplicateDocumentation.stepShow === 5">
                <p class="duplicate-documentation__text-search-header"
                    [ngShow]="duplicateDocumentation.stepShow === 3"
                    [afTranslate]="'duplicate-documentation.title-explore-implantation'">_Explora las carpetas y conceptos de la implantación</p>
                <p class="duplicate-documentation__text-search-header"
                    [ngShow]="duplicateDocumentation.stepShow === 4"
                    [afTranslate]="'duplicate-documentation.select-instance'">_Selecciona la instancia del concepto que contiene los documentos</p>
                <p class="duplicate-documentation__text-search-header"
                    [ngShow]="duplicateDocumentation.stepShow === 5"
                    [afTranslate]="'duplicate-documentation.explore-documentation'">_Explora la documentación y selecciona los archivos que quieres importar</p>
                <div class="duplicate-documentation__block-table-results">
                    <!-- Migas de pan -->
                    <div class="duplicate-documentation__block-breadcrumbs">
                        <i class="lf-icon-map-marker duplicate-documentation__icon-map"></i>
                        <div class="duplicate-documentation__block-breadcrumbs-text">
                            <div class="duplicate-documentation__title-breadcrumb duplicate-documentation__title-breadcrumb--title"
                                (click)="selectFolder(null, 'menu')"
                                [ngShow]="skin !== 'icam-red'">
                                <span class="duplicate-documentation__text-breadcrumb"
                                    [ngClass]="duplicateDocumentation.breadcrumbList.length && skin ==='steel-blue'?'duplicate-documentation__title-breadcrumb--no-cursor':'duplicate-documentation__underlined-text'"
                                    [afTranslate]="'common.implantations'">_Implantaciones</span>
                            </div>
                            <button *ngFor="let breadcrumb of duplicateDocumentation.breadcrumbList; let last = last; let first = first; let index = index"
                                class="duplicate-documentation__title-breadcrumb"
                                (click)="selectFolder(breadcrumb, 'breadcrumb')"
                                [ngClass]="{'duplicate-documentation__title-breadcrumb--no-cursor': last}"
                                [disabled]="last">
                                <div class="common__cell-block">
                                    <span class="duplicate-documentation__symbol-minus"
                                        [ngShow]="!(skin === 'icam-red' && first)"> > </span>
                                    <span-tooltip class="duplicate-documentation__text-breadcrumb"
                                      [ngClass]="{'duplicate-documentation__underlined-text': !last && skin !== 'steel-blue' && skin !== 'uk-steel-blue',
                                          'duplicate-documentation__last':last}"
                                      [text]="breadcrumb.nameBreadcrumb"></span-tooltip>
                                </div>
                            </button>
                            <span id="finalBreadcrumbs"></span>
                        </div>
                    </div>
                    <div class="duplicate-documentation__table-container"
                        [ngClass]="{'duplicate-documentation__table-container--evaluation': duplicateDocumentation.stepShow === 3}">
                        <!-- Contenido carpetas-->
                        <div class="duplicate-documentation__table-body"
                            [ngShow]="duplicateDocumentation.stepShow === 3"
                            [ngClass]="{'duplicate-documentation__table-body--more-elements':duplicateDocumentation.filterEvaluationTreeList.length > 7 }">
                            <img [src]="duplicateDocumentation.loaderImg"
                                class="duplicate-documentation__spinner-loader"
                                [ngShow]="duplicateDocumentation.searchingTreeEvaluation" alt="loader">
                            <div class="duplicate-documentation__table-row"
                                [ngShow]="!duplicateDocumentation.searchingTreeEvaluation"
                                *ngFor="let item of duplicateDocumentation.filterEvaluationTreeList"
                                (click)="selectFolder(item)">
                                <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
                                    <i [ngShow]="item.folderId"
                                        [class]="'duplicate-documentation__icon-product-scan '+item.icon"></i>
                                    <i [ngShow]="item.conceptId"
                                        [ngClass]="item.isFront?'lf-icon-template-user':'lf-icon-template'"
                                        class="duplicate-documentation__icon-product-scan"></i>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--implantation-explore">
                                    <div class="duplicate-documentation__cell-block">
                                        <parragraph-tooltip [ngShow]="item.folderId"
                                            [text]="item.name">
                                        </parragraph-tooltip>
                                        <parragraph-tooltip [ngShow]="item.conceptId"
                                            [text]="item.title">
                                        </parragraph-tooltip>
                                    </div>
                                    <span class="duplicate-documentation__text-explore"
                                        [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
                                    <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <!-- Tabla conceptos  -->
                        <div *ngIf="duplicateDocumentation.stepShow === 4"
                            [ngSwitch]="duplicateDocumentation.viewTableDuplicated">
                            <duplicate-documentation-collegiate *ngSwitchCase="'collegiate'"
                                [filterConceptList]="duplicateDocumentation.filterConceptList"
                                [duplicateDocumentation]="duplicateDocumentation"
                                [currentPage]="duplicateDocumentation.currentPage"
                                (sendAction)="launchAction($event)">
                            </duplicate-documentation-collegiate>
                            <duplicate-documentation-digitization *ngSwitchCase="'digitization'"
                                [filterConceptList]="duplicateDocumentation.filterConceptList"
                                [duplicateDocumentation]="duplicateDocumentation"
                                [currentPage]="duplicateDocumentation.currentPage"
                                (sendAction)="launchAction($event)">
                            </duplicate-documentation-digitization>
                            <duplicate-documentation-publication *ngSwitchCase="'publication'"
                                [filterConceptList]="duplicateDocumentation.filterConceptList"
                                [duplicateDocumentation]="duplicateDocumentation"
                                [currentPage]="duplicateDocumentation.currentPage"
                                (sendAction)="launchAction($event)">
                            </duplicate-documentation-publication>
                            <duplicate-documentation-query *ngSwitchCase="'query'"
                                [filterConceptList]="duplicateDocumentation.filterConceptList"
                                [duplicateDocumentation]="duplicateDocumentation"
                                [currentPage]="duplicateDocumentation.currentPage"
                                (sendAction)="launchAction($event)">
                            </duplicate-documentation-query>
                            <duplicate-documentation-step *ngSwitchDefault
                                [filterConceptList]="duplicateDocumentation.filterConceptList"
                                [duplicateDocumentation]="duplicateDocumentation"
                                [currentPage]="duplicateDocumentation.currentPage"
                                (sendAction)="launchAction($event)">
                            </duplicate-documentation-step>
                        </div>
                        <!-- Tabla documentos del concepto -->
                        <!-- Cabecera -->
                        <div class="common__table-header duplicate-documentation__table-header"
                            *ngIf="duplicateDocumentation.stepShow === 5">
                            <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                <label for="multiple-selection-all-select-duplicate"
                                    class="documentation-list__label-multiple">
                                    <input class="documentation-list__input-multiple"
                                        type="checkbox"
                                        id="multiple-selection-all-select-duplicate"
                                        (click)="selectAllDocumentCheckDuplicate()"
                                        [checked]="duplicateDocumentation.selectAllDocument">
                                    <i class="documentation-list__icon-multiple"
                                        [ngClass]="duplicateDocumentation.selectAllDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                </label>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--format">
                                <filter-by-list [type]="'list'"
                                    [item]="duplicateDocumentation.extensionFilter"
                                    [list]="duplicateDocumentation.extensionList"
                                    (filterElementEmit)="selectTypeOperation($event, 'extension')">
                                </filter-by-list>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--title-documentation" >
                                <filter-by-search
                                    [item]="duplicateDocumentation.titleFilter"
                                    (launchActionSearch)="orderBySearch($event, 'title')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--author-documentation">
                                <filter-by-search
                                    [item]="duplicateDocumentation.createdByFilter"
                                    (launchActionSearch)="orderBySearch($event, 'createdBy')">
                                </filter-by-search>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--creation-date">
                                <div class="common__cursor-pointerr"
                                    (click)="orderDate('created')">
                                    <p class="common__table-header-text"
                                        [afTranslate]="'common.creation'">_Creación</p>
                                    <i class="common__order-by"
                                        [ngClass]="{'lf-icon-arrow-down':(duplicateDocumentation.dateOrder==='created' && duplicateDocumentation.orderOperationDates) || duplicateDocumentation.dateOrder !=='created',
                                            'lf-icon-arrow-up':duplicateDocumentation.dateOrder==='created' && !duplicateDocumentation.orderOperationDates,
                                            'common__order-by--active': duplicateDocumentation.dateOrder==='created'}"></i>
                                </div>
                            </div>
                            <div class="duplicate-documentation__table duplicate-documentation__table--icon-right-documentation"></div>
                        </div>
                        <!-- Contenido -->
                        <div class="duplicate-documentation__table-body"
                            *ngIf="duplicateDocumentation.stepShow === 5"
                            [ngClass]="{'duplicate-documentation__table-body--more-elements':duplicateDocumentation.filterDocumentationList.length >= (duplicateDocumentation.configPagination.pageSize*duplicateDocumentation.currentPage)-3}">
                            <img [src]="duplicateDocumentation.loaderImg"
                                class="duplicate-documentation__spinner-loader"
                                [ngShow]="duplicateDocumentation.searchingDocuments" alt="loader">
                            <div class="duplicate-documentation__table-row"
                                [ngClass]="{'duplicate-documentation__table-row--selected': document.selectedDocument}"
                                [ngShow]="!duplicateDocumentation.searchingDocuments"
                                (click)="selectMultipleDocument(document)"
                                *ngFor="let document of duplicateDocumentation.filterDocumentationList
                                    | afOrderBy:duplicateDocumentation.dateOrder:duplicateDocumentation.orderOperationDates
                                    | afLimitTo:duplicateDocumentation.configPagination.pageSize:duplicateDocumentation.configPagination.pageSize*(duplicateDocumentation.currentPage - 1); let index = index">
                                <div class="duplicate-documentation__table duplicate-documentation__table--multiple-selection">
                                    <label [for]="'multiple-selection-duplicate-'+index+'-select'"
                                        class="documentation-list__label-multiple"
                                        [ngShow]="!document.folderId">
                                        <input class="documentation-list__input-multiple"
                                            type="checkbox"
                                            [id]="'multiple-selection-duplicate-'+index+'-select'"
                                            (click)="selectMultipleDocument(document)"
                                            [checked]="document.selectedDocument">
                                        <i class="documentation-list__icon-multiple"
                                            [ngClass]="document.selectedDocument?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
                                    </label>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--format">
                                    <i class="duplicate-documentation__table-icon"
                                        [ngClass]="document.folderId?'lf-icon-folder duplicate-documentation__table-icon--folder':(document.extension | extensionDocument)"></i>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--title-documentation">
                                    <div class="common__cell-block">
                                        <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--author-documentation">
                                    <div class="common__cell-block"
                                        [ngClass]="{'duplicate-documentation__block-hidden-hover': document.folderId}">
                                        <parragraph-tooltip [text]="document.createdBy"></parragraph-tooltip>

                                    </div>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--creation-date"
                                    [ngClass]="{'duplicate-documentation__block-hidden-hover': document.folderId}">
                                    <span class="common__col-text">{{document.created | convertDate:'without-time'}}</span>
                                </div>
                                <div class="duplicate-documentation__table duplicate-documentation__table--icon-right-documentation">
                                    <div [ngShow]="document.folderId">
                                        <span class="duplicate-documentation__text-explore"
                                            [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
                                        <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Mensaje de que no hay elementos que mostrar -->
                    <p class="duplicate-documentation__text-search-header  duplicate-documentation__text-search-header--no-elements"
                        [ngShow]="duplicateDocumentation.noElements"
                        [afTranslate]="'duplicate-documentation.not-found-elements'">
                        _No se han encontrado elementos
                    </p>
                     <!-- Paginación -->
                    <div class="duplicate-documentation__pagination">
                        <pagination-control [ngShow]="(duplicateDocumentation.stepShow === 4 || duplicateDocumentation.stepShow === 5) && duplicateDocumentation.totalItemsPagination > duplicateDocumentation.configPagination.pageSize && !duplicateDocumentation.searchingInstances && !duplicateDocumentation.searchingDocuments"
                          [collectionSize]="duplicateDocumentation.totalItemsPagination"
                          [config]="duplicateDocumentation.configPagination"
                          [(currentPage)]="duplicateDocumentation.currentPage"></pagination-control>
                        <div class="duplicate-documentation__count-documentation">
                            <span [afTranslate]="'common.total'">Total:</span>
                            <span>{{duplicateDocumentation.totalItemsPagination}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common__buttons-block common__buttons-block--modal"
                [ngShow]="duplicateDocumentation.stepShow !== 0">
                <button type="reset"
                    class="common__button common__button--clean"
                    (click)="handleDismiss()"
                    [afTranslate]="'common.cancel'">_Cancelar</button>
                <button type="reset"
                    class="common__button common__button--clean"
                    (click)="backInit()"
                    [afTranslate]="'duplicate-documentation.back'">_Volver al inicio</button>
                <button type="submit"
                    class="common__button common__button--active"
                    (click)="importDocuments()"
                    [disabled]="duplicateDocumentation.documentChecked === 0"
                    [afTranslate]="'duplicate-documentation.import'">_Importar</button>
            </div>
        </div>
    </div>
  </div>
