import { Injectable } from '@angular/core';
import { CommonUtilsService } from '../common-utils/common-utils.service';

@Injectable({
  providedIn: 'root'
})

export class ValidateDocumentService {
  arrayFilesUnderscore: any = ['files', 'fichiers', 'bestanden', 'file', 'archivos', 'tiedostot', 'pliki', 'soubory', 'elemei', 'ficheiros', 'arquivos', 'dosyalar', 'datoteke', 'fitxers', 'failid', 'fails', 'bylos', 'fajlovi', 'fitxategiak'];
  arrayFilesMiddleDash: any = ['Dateien','filer'];
  constructor(private commonUtilsService: CommonUtilsService) {}

  validateDocuments(documents: any, documentsList: any){
    for(let i = 0; i < documents.length; i++){
        if(!this.correctFormat(documents[i]) || !this.correctCharacters(documents[i])){
            if(documentsList){
                if(!this.correctCharacters(documents[i])){
                    documentsList.incorrectCharacters = true;
                    if(documents.length === 1){
                        documentsList.nameIncorrectFile = documents[i];
                    }
                }
                documentsList.names.splice( i, 1 );
                documentsList.documents.splice( i, 1 );
            }
            return false;
        }
    }
    return true;
  }

  correctFormat(documentSelected: any){
    let extension = documentSelected.substring(documentSelected.lastIndexOf('.')+1, documentSelected.length).toLowerCase();
    switch(extension){
        //word
        case 'doc':
        case 'docx':
        case 'docm':
        case 'dot':
        case 'dotx':
        case 'dotm':
        case 'odt':
        case 'rtf':
        //excel
        case 'xls':
        case 'xlsx':
        case 'xlm':
        case 'xlsm':
        case 'xlt':
        case 'xltx':
        case 'xltm':
        case 'xlsb':
        case 'xlam':
        case 'xlv':
        case 'csv':
        case 'ods':
        //PowerPoint
        case 'ppt':
        case 'pptx':
        case 'pptm':
        case 'pot':
        case 'potx':
        case 'potm':
        case 'ppa':
        case 'ppam':
        case 'pps':
        case 'ppsx':
        case 'ppsm':
        case 'sldx':
        case 'sldm':
        case 'thmx':
        case 'odp':
        //imagen
        case 'bmp':
        case 'gif':
        case 'dib':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tga':
        case 'tif':
        case 'tiff':
        case 'pcx':
        case 'pic':
        case 'emf':
        case 'ico':
        //internet
        case 'htm':
        case 'html':
        case 'css':
        case 'url':
        case 'xml':
        case 'eml':
        case 'msg':
        //audio
        case 'wav':
        case 'mp3':
        //video
        case 'avi':
        case 'mp4':
        case 'mov':
        case 'mpeg':
        case 'mpg':
        case 'divx':
        case 'wmv':
        case 'flv':
        case 'mkv':
        //comprimidos
        case 'rar':
        case 'zip':
        case '7z':
        //access
        case 'mdb':
        //texto
        case 'txt':
        case 'pdf':
        case 'wri':
        case 'log':
        case 'xps':
            return true;
        default:
            return false;
    }
  }

  correctDocumentFormat(documentSelected: any){
    let extension = documentSelected.substring(documentSelected.lastIndexOf('.')+1, documentSelected.length).toLowerCase();
    switch(extension){
        //word
        case 'doc':
        case 'docx':
        case 'docm':
        case 'dot':
        case 'dotx':
        case 'dotm':
        case 'odt':
        case 'rtf':
        //excel
        case 'xls':
        case 'xlsx':
        case 'xlm':
        case 'xlsm':
        case 'xlt':
        case 'xltx':
        case 'xltm':
        case 'xlsb':
        case 'xlam':
        case 'xlv':
        case 'csv':
        case 'ods':
        //PowerPoint
        case 'ppt':
        case 'pptx':
        case 'pptm':
        case 'pot':
        case 'potx':
        case 'potm':
        case 'ppa':
        case 'ppam':
        case 'pps':
        case 'ppsx':
        case 'ppsm':
        case 'sldx':
        case 'sldm':
        case 'thmx':
        case 'odp':
        //imagen
        case 'bmp':
        case 'gif':
        case 'dib':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tga':
        case 'tif':
        case 'tiff':
        case 'pcx':
        case 'pic':
        case 'emf':
        case 'ico':
        //internet
        case 'htm':
        case 'html':
        case 'css':
        case 'url':
        case 'xml':
        case 'eml':
        case 'msg':
        //audio
        case 'wav':
        case 'mp3':
        //video
        case 'avi':
        case 'mp4':
        case 'mov':
        case 'mpeg':
        case 'mpg':
        case 'divx':
        case 'wmv':
        case 'flv':
        case 'mkv':
        //comprimidos
        case 'rar':
        case 'zip':
        case '7z':
        //access
        case 'mdb':
        //texto
        case 'txt':
        case 'pdf':
        case 'wri':
        case 'log':
        case 'xps':
            return true;
        default:
            return false;
    }
  }

  correctCharacters(documentSelected: any){
    let nameDocument:any = documentSelected.indexOf('.') !== -1?documentSelected.substring(0, documentSelected.lastIndexOf('.')).toLowerCase(): documentSelected;
    let validName: any = this.commonUtilsService.validateNoSpecialCharacters(nameDocument);
    let lastUnderscore: any = nameDocument.lastIndexOf('_');
    let lastMiddleDash: any = nameDocument.lastIndexOf('-');

    if(validName && nameDocument.indexOf('_vti_') !== 0){
        if((lastUnderscore !== -1 && lastMiddleDash !== -1 && lastUnderscore > lastMiddleDash) || (lastUnderscore !== -1 && lastMiddleDash === -1)){
            let nameUderscore: any = nameDocument.substring(nameDocument.lastIndexOf('_')+1, nameDocument.length).toLowerCase();
            return !(!!this.arrayFilesUnderscore.filter((filesUnderScore:any)=>filesUnderScore === nameUderscore).length)
        }else if((lastUnderscore !== -1 && lastMiddleDash !== -1 && lastMiddleDash > lastUnderscore) || (lastUnderscore === -1 && lastMiddleDash !== -1)){
            let nameMiddleDash: any = nameDocument.substring(nameDocument.lastIndexOf('-')+1, nameDocument.length).toLowerCase();
            return !(!!this.arrayFilesUnderscore.filter((filesMiddleDash:any)=>filesMiddleDash === nameMiddleDash).length)
        }
        let nameLastPoint: any = nameDocument.substring(nameDocument.lastIndexOf('.')+1, nameDocument.length).toLowerCase();
        if(nameLastPoint === 'files'){
            return false;
        }
        return true;
    }else{
        return false;
    }
  }

  duplicatedDocuments(documentSelected: any, list: any){
    if(list !== null){
      for(let i = 0; i <  list.length; i++){
          if(documentSelected ===  list[i].name){
              return true;
          }
      }
    }
    return false;
  }

  maxSize(size: number){
    return size < 314572800; //300MB
  }

  nameLength(documentSelected: string){
    return documentSelected.length < 100;
  }
  validateAll(documentSelected:any, list: any){
    if(!(this.correctCharacters(documentSelected))){
        return 'invalid-name';
    }
    if(!(this.correctDocumentFormat(documentSelected))){
        return 'format-not-accepted'
    }
    if(!(this.duplicatedDocuments(documentSelected, list))){
        return 'is-already-added'
    }
    return '';
  }
}
