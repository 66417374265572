import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class reportObjectsListResolve implements Resolve<any> {
  constructor(private apiService: ApiServiceService) { }

  resolve(route:ActivatedRouteSnapshot) {
    let url:string = 'reportobjects/byreportid';
    let id:string = route.params['reportId'];

    if(route.params['evaluationId']){
        url += '/' + id;
        id = route.params['evaluationId'];
    }

    return this.apiService.get(url, id)
      .then((data:any)=>{
        return data.reports;
      });
  }
}
