<div class="list-in-dropdown__block-participant">
  <i class="lf-icon-close list-in-dropdown__icon-close"
      (click)="closeDropdown($event)"></i>
  <div class="list-in-dropdown__title-box">
      <i [class]="'list-in-dropdown__icon-group '+listInDropdown.iconHeader"></i>
      <span class="list-in-dropdown__title-participants">{{listInDropdown.title}}</span>
  </div>
  <div class="list-in-dropdown__select-list-item">
      <div *ngFor="let participant of list; let index = index">
          <div class="list-in-dropdown__cell-block-list"
              [ngClass]="{'list-in-dropdown__cell-block-list--responsible': listInDropdown.type === 'confidential' && participant.responsible}">
              <parragraph-tooltip [text]="participant.textToTooltip"></parragraph-tooltip>
          </div>
      </div>
  </div>
</div>
