import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'predefined-templates',
  templateUrl: './predefined-templates.component.html'
})
export class PredefinedTemplatesComponent{
  userPermissions: any = this.permissions.getPermissions();
  skin: string = this.globalCfg.skin
  predefinedTemplates: any = {
    fullScreen: false
  }
  constructor(private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService) { }

  @Input() templateList: any = [];

  @Output() catchFullScreen = new EventEmitter();
  @Output() generateDocument = new EventEmitter()

  maximizeScreen(){
    this.predefinedTemplates.fullScreen = !this.predefinedTemplates.fullScreen;
    this.catchFullScreen.emit({box:'predefined-templates'})
  }

  createDocumentTemplate(template:any){
      if(this.commonUtilsService.isIE()){
          this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
      }else{
          this.generateDocument.emit({template: template});
      }
  }

}
