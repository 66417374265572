import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byWordChildren'
})
export class ByWordChildrenPipe implements PipeTransform {
  transform(items: any, find: any, words: any, children: any): any {
    let filtered: any = [];
    if(items){
      items.forEach((item: any)=> {
          if(find !=='') {
              if(children && item[children].length){
                  for(let i=0; i < item[children].length; i++){
                      let childrenItem = item[children][i];
                      let existChildren = this.searchWord(childrenItem, find, words);
                      if(existChildren){
                          filtered.push(item);
                          break;
                      }
                  }
              }else{
                  let existChildren = this.searchWord(item, find, words);
                  if(existChildren){
                      filtered.push(item);
                  }
              }
          }else{
              filtered.push(item);
          }
      })
    }
    return filtered;
  }

  searchWord(item: any, find: any, words: any){
    for(let i=0; i < words.length; i++){
        let word = words[i];
        if(item[word] === null || !item[word]){
            item[word] = '';
        }
        let characters: any={
            "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
            "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
            "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
            "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
        let regExpCharacters=/[áàéèíìóòúùñ]/ig;
        let itemWord = item[word].toString();
        let textWithoutAccent = itemWord.replace(regExpCharacters,(e:any)=>{return characters[e]});
        let findWithoutAccent=find.replace(regExpCharacters,(e: any)=>{return characters[e]});
        if(textWithoutAccent.toLowerCase().indexOf(findWithoutAccent.toLowerCase()) !== -1){
            return true;
        }
    }
    return false;
}
}
