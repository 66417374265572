<div class="new-report">
  <div class="common__modal-header-block">
      <i class="lf-icon-label-analysis common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'new-report.new-report'">Nuevo informe</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="new-report__block-body">
      <form class="new-report__report-form">
          <field-type-list-simple
              *ngIf="skin !== 'icam-red'"
              [field]="newReport.associatedClient"
              [preSelectItem]="newReport.report.associatedClient"
              [listValues]="newReport.clientListModify"
              (itemSelected)="itemSelected($event, 'client')">
          </field-type-list-simple>
          <field-type-list-simple
              *ngIf="skin !== 'icam-red'"
              [field]="newReport.associatedImplantation"
              [preSelectItem]="newReport.report.associatedImplantation"
              [listValues]="newReport.evaluationListModify"
              (itemSelected)="itemSelected($event, 'evaluation')">
          </field-type-list-simple>
          <field-type-list-simple
              [field]="newReport.typeReport"
              [preSelectItem]="newReport.report.typeReport"
              [listValues]="newReport.typeReportList"
              (itemSelected)="itemSelected($event, 'typeReport')">
          </field-type-list-simple>
          <field-type-text-simple [field]="newReport.nameReport"
              [preSelectItem]="newReport.report.name"
              (itemSelected)="itemSelected($event, 'name')">
          </field-type-text-simple>
      </form>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset"
              class="common__button common__button--clean"
              (click)="handleDismiss()"
              [afTranslate]="'common.cancel'">Cancelar</button>
          <button type="submit"
              class="common__button common__button--active common__button--separating-margin"
              (click)="generateReport()"
              [afTranslate]="'common.generate'">Generar</button>
      </div>
  </div>
</div>
