import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'task-generic',
  templateUrl: './task-generic.component.html'
})
export class TaskGenericComponent implements OnInit {
  taskGeneric: any = {
    allLoad: false
  }

  constructor(private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService){}

  ngOnInit(): void {
    if(this.route.snapshot.params['taskId']){
      this.taskGeneric.task = this.route.snapshot.data['task'];
    }
  }

  changeView(){
    this.commonUtilsService.goToStateUrl();
  }

}
