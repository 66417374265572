<div class="customize-footer"
    [ngClass]="{'customize-footer--type-white': customizeFooter.showBorder}"
    [ngStyle]="{'background-color': customizeFooter.backgroundColor, 'color': customizeFooter.textColor}">
    <div class="customize-footer__logo-footer"
        [ngShow]="configureOptions.showLogoInFooter"
        [ngClass]="{'customize-footer__logo-footer--hidden-rrss': configureOptions.defaultCustomization && skin === 'steel-blue'}">
        <div class="customize-footer__block-logo">
            <img class="customize-footer__img-logo"
                [src]="configureOptions.logoFooter"
                alt="logo footer"/>
        </div>
    </div>

    <div class="customize-footer__social-networks"
        [ngHide]="(configureOptions.defaultCustomization && skin === 'steel-blue') || customizeFooter.noRrss">
        <div class="customize-footer__phone-text">
            <span>{{configureOptions.phone}}</span>
        </div>
        <button class="customize-footer__button-icon customize-footer__button-icon--last lf-icon-twitter-round"
            [ngClass]="{'customize-footer__button-icon--first': configureOptions.facebook === '' && configureOptions.linkedin === '' && configureOptions.youtube === ''}"
            [ngShow]="configureOptions && configureOptions.twitter !== ''"
            (click)="goTo('1')">
        </button>
        <button class="customize-footer__button-icon lf-icon-youtube-round"
            [ngClass]="{'customize-footer__button-icon--first': configureOptions.linkedin === '' && configureOptions.facebook === '', 'customize-footer__button-icon--last': configureOptions.twitter === ''}"
            [ngShow]="configureOptions && configureOptions.youtube !== ''"
            (click)="goTo('2')">
        </button>
        <button class="customize-footer__button-icon lf-icon-linkedin-round"
            [ngClass]="{'customize-footer__button-icon--first': configureOptions.facebook === '', 'customize-footer__button-icon--last': configureOptions.youtube === '' && configureOptions.twitter === ''}"
            [ngShow]="configureOptions && configureOptions.linkedin !== ''"
            (click)="goTo('3')">
        </button>
        <button class="customize-footer__button-icon customize-footer__button-icon--first lf-icon-facebook-round"
            [ngClass]="{'customize-footer__button-icon--last': configureOptions.twitter === '' && configureOptions.youtube === '' && configureOptions.linkedin === ''}"
            [ngShow]="configureOptions && configureOptions.facebook !== ''"
            (click)="goTo('4')">
        </button>
    </div>
    <div class="customize-footer__footer">
        <div [innerHTML]="configureOptions.footerDescription"></div>
    </div>
</div>
