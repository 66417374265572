import { FieldTypeContactsParentService } from './../utils/field-type-contacts-parent.service';
import { cloneDeep } from 'lodash';

import { Component, Input, OnInit, OnChanges, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

@Component({
  selector: 'field-type-contacts-multiple-custom',
  templateUrl: './field-type-contacts-multiple-custom.component.html'
})

export class FieldTypeContactsMultipleCustomComponent implements OnInit, OnChanges, OnDestroy {
  isLanding = true;
  id: any = this.commonUtilsService.createId();
  fieldTypeContacts: any = this.fieldTypeContactsParent.configureField(this);

  constructor(private route: ActivatedRoute,
    private userPermisions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private fieldTypeContactsParent: FieldTypeContactsParentService) {}

  @Input() field : any ={};
  @Input() concept : any ={};
  @Input() valueId : any = null;

  @Output() openRelatedInfo = new EventEmitter();
  @Output() contactsClosed = new EventEmitter();
  @Output() sendContacts = new EventEmitter();
  @Output() showFieldReference = new EventEmitter();

  ngOnInit(): void {
    this.fieldTypeContactsParent.configureInit(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldTypeContactsParent.configureChanges(changes, this);
  }

  openContactList(){
    this.fieldTypeContactsParent.openContactList(this);
  }

  showReference(e: any){
    this.fieldTypeContactsParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
   this.fieldTypeContactsParent.openModalRelatedInfo(e, fieldRelatedInfo, this);
  }

  resetSearch(e: any){
    this.fieldTypeContactsParent.resetSearch(e, this);
  }

  toggleAll(e:any){
    this.fieldTypeContactsParent.toggleAll(e, this);
  }

  selectedContacts(){
    this.fieldTypeContacts.openUserList = !this.fieldTypeContacts.openUserList;
    this.fieldTypeContacts.selectContactsCopy = cloneDeep(this.fieldTypeContacts.contactListSelected);
    this.fieldTypeContacts.markAllCopy = this.fieldTypeContacts.markAll;
    this.contactsClosed.emit({type:'selectedContacts'});
    if(this.field.selectContacts && (this.field.typeConfiguration === 'sign' || this.field.typeConfiguration === 'meeting')){
        this.fieldTypeContactsParent.configureAssists(this);
    }
  }

  changeState(e: any, state: any){
    this.fieldTypeContactsParent.changeState(e, state, this);
  }

  changeStateCustom(e: any){
    let state = this.fieldTypeContacts.state === 'selection'?'list':'selection';
    this.fieldTypeContactsParent.changeState(e, state, this);
  }

  changeView(){
    this.fieldTypeContactsParent.changeView(this);
  }

  deleteContact(e: any, index: any){
    this.fieldTypeContactsParent.deleteContact(e, index, this);
  }

  resetContacts(){
    this.fieldTypeContactsParent.resetContacts(this);
  }

  showOnly(type: any, e: any){
    this.fieldTypeContactsParent.showOnly(this, type, e);
  }

  whoIs(item: any, e: any){
    let type ='group';
    if(item.userId){
        type ='user';
    }else if(item.contactId){
        type ='contact';
    }else if(item.productRoleId){
        type = 'productRole';
    }
    this.fieldTypeContactsParent.addOrDeleteMultipleItem(item, type, this, e);
  }

  addOrDeleteMultipleItem(item: any, type: any, e: any){
    this.fieldTypeContactsParent.addOrDeleteMultipleItem(item, type, this, e);
  }

  showTooltip(elementId: any, participant: any){
    return this.fieldTypeContactsParent.showTooltip(this, elementId, participant);
  }

  catchEvent(e: any){
      e.stopPropagation();
  }

  ngOnDestroy(){
    this.fieldTypeContactsParent.ngOnDestroy();
  }
}
