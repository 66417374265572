import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { map } from 'lodash';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { UploadDocumentsComponent } from '@shared/components/upload-document/upload-documents/upload-documents.component';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'evaluation-share',
  templateUrl: './evaluation-share.component.html'
})
export class EvaluationShareComponent implements OnInit, OnDestroy {
  evaluationShare:any = {
    confidentialWarning: this.translate.instant('evaluation-share.confidential-warning'),
    stepState: 1,
    showDocumentsWarning: true,
    documentationList: [],
    documents: [],
    filesUpdate: [],
    steps: [
      {name: this.translate.instant('evaluation-share.select-recipients')},
      {name: this.translate.instant('attach-document.attach-document')}
    ],
    uploadComponent: {},
    contactsField: {
      typeConfiguration: 'share-evaluation',
      fieldType: 'multiple',
      addContacts: true,
      required: true,
      typeCall: 'contacts-only',
      selectContacts: [],
      label: this.translate.instant('contacts.contacts')
    },
    tinyMceOptions: {
      noEditable: false,
      toolbar: 'bold italic underline forecolor backcolor'
    },
    comments: ''
  };
  subscribers: any = {};

  constructor(private activeModal: NgbActiveModal,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService) { }

  @Input() evaluation:any;
  @ViewChild('UploadDocument', {static: false}) UploadDocument: UploadDocumentsComponent;

  ngOnInit() {
    this.hasConfidential();
  }

  hasConfidential(){
    this.apiService.get('evaluations/hasconfidentialdata', this.evaluation.evaluationId).then(
      (data:any) => {
        this.evaluationShare.hasConfidential = data;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  cancel() {
    let that = this.evaluationShare;
    if (that.tmpDocs) {
        this.apiService.delete('upload', that.tmpDocs);
    }
    this.activeModal.close({result: 'cancel'});
  }

  finishOption(){
    let that = this.evaluationShare;
    if(that.stepState === 1){
        this.shareEvaluation();
    }else if(that.stepState === 2){
        this.uploadFiles();
    }
  }

  shareEvaluation() {
    if (this.validateData()) {
        this.postShareEvaluation();
    }
  }

  validateData() {
    let that = this.evaluationShare;
    that.contactsField.error = !that.contactsField.selectContacts.length;
    return !that.contactsField.error;
  }

  postShareEvaluation(docs?:any) {
    let that = this.evaluationShare;
    that.tmpDocs = docs;
    let shareData : any = {
      evaluationId: this.evaluation.evaluationId,
      contacts: map(that.contactsField.selectContacts, (contact:any) => {
        return {
          contactId: contact.contactId,
          fullName: contact.fullName
        };
      }),
      comments: that.comments || null,
      shareDocuments: that.shareDocuments,
      documents: docs
    };
    this.apiService.add('sharedevaluations', shareData).then(
      (data:any) => {
        data.documents = that.tmpDocs;
        that.tmpDocs = null;
        data.result ='ok'
        this.activeModal.close(data);
      },
      (error:any) => {
        console.error(error);
        this.modalService.adviceModal(this.translate.instant('evaluation-share.unexpected-error'), 'error:accept:warning');
      }
    );
  }

  uploadDocumentsOn(e:any){
    this.evaluationShare.upDocuments = e.uploadDocuments;
  }

  uploadFiles() {
    let that = this.evaluationShare;
    if (that.tmpDocs) {
        this.postShareEvaluation(that.tmpDocs);
    } else {
        if (this.validateData()) {
            that.tmpDocs = null;
            let badFiles = this.validateFiles();
            if (badFiles.length) {
              this.modalService.adviceModal(this.translate.instant('task-edit.add-correct-format'), 'error:question:warning')
                .subscribe((result:any)=>{
                  if(result.result==='ok'){
                    this.uploadAndShare()
                  }
                });
          } else {
              this.uploadAndShare()
          }
        }
    }
  }

  uploadAndShare(){
    this.evaluationShare.failedUploads = [];
    this.evaluationShare.uploadedFiles = [];
    this.evaluationShare.updating = true;
    this.evaluationShare.updateAmount = 0;
    this.evaluationShare.updateAccomplish = 0;
    this.checkDocuments();
  }

  checkDocuments(){
   this.evaluationShare.upDocuments.forEach((document:any) => {
      if(document.statusText === ''){
        this.evaluationShare.updateAmount ++;
        this.addDocument(document, true);
      }
    });
  }

  addDocument(document:any, firstLoad:any) {
    document.status = '';
    if (firstLoad) {
        this.subscribers.uploadFileBroadcast = this.broadcastService.subscribe('uploadFile' + document.name, (data:any)=> {
            document.progress = Math.floor(data.percent);
            if (data.percent === 100) {
                document.status = 'upload'
            }
        })
    }
    this.addDocumentGeneral(document, firstLoad);
  }

  addDocumentGeneral(document:any, firstLoad:any){
    this.apiService.attachFileWithProgress('upload', document)
      .then((data:any)=>{
        document.status = 'complete';
        this.evaluationShare.updateAccomplish ++;
        this.evaluationShare.filesUpdate.push(data);
        this.evaluationShare.uploadedFiles.push(data[0]);
        if(firstLoad){
            if(this.evaluationShare.updateAccomplish === this.evaluationShare.updateAmount){
              this.evaluationShare.complete = true;
              this.evaluationShare.canClose = true;
            }
        }
        this.postShareEvaluation(this.evaluationShare.uploadedFiles);

    }, (errorData:any)=>{
        document.status = 'error';
        this.evaluationShare.updateAccomplish ++;
        document.statusText = this.translate.instant('common.error');
        this.evaluationShare.failedUploads.push(document);
        if (this.evaluationShare.updateAccomplish === this.evaluationShare.updateAmount) {
            this.evaluationShare.complete = true;
            this.evaluationShare.canClose = true;
            this.evaluationShare.uploadDefered.reject({
                ok: this.evaluationShare.uploadedFiles,
                failed: this.evaluationShare.failedUploads
            });
        }
    });
  }

  validateFiles() {
    this.evaluationShare.canClose = this.evaluationShare.upDocuments.length <= 0;
    this.evaluationShare.validateDocument = false;
    let failedDocs:any = [];
    this.evaluationShare.upDocuments.forEach((document:any) => {
      if (document.statusText !== '') {
        if (document.status === 'validate-error') {
            failedDocs.push({
                name: document.name,
                status: document.statusText
            });
        } else {
            document.status = '';
            document.statusText = '';
        }
      }else{
        this.evaluationShare.validateDocument = false;
      }
    });
    return failedDocs;
  }

  goNext() {
    if (this.validateData()) {
        this.evaluationShare.stepState++;
    }
  }

  getSelectContacts(e:any){
    this.evaluationShare.contactsField.selectContacts = e.contacts
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
