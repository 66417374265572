import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';


@Component({
  selector: 'notification-preview',
  templateUrl: './notification-preview.component.html'
})
export class NotificationPreviewComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  notificationPreview:any = {
    html: null
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal) { }

  @Input() notificationTypeId:any = null;
  @Input() entityType:any = null;
  @Input() id:any = null;
  @Input() data:any = null;

  ngOnInit(): void {
    this.loadPreview();
  }

  loadPreview() {
    this.apiService.add('notifications/preview/' + this.notificationTypeId + '/' + this.entityType + '/' + this.id, this.data)
      .then((data: any)=> {
        this.notificationPreview.html = this.sanitizer.bypassSecurityTrustHtml(data);
      }, (errorData:any)=>{});
  }

  closeModal() {
    this.activeModal.close({
      result:{
        result: 'ok'
      }
    })
  }
}
