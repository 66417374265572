import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'related-information-modal',
  templateUrl: './related-information-modal.component.html'
})
export class RelatedInformationModalComponent implements OnInit {
  isLanding: any = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  relatedInformationModal:any = {
    infoRelatedSelected: {},
    relatedModalList: []
  }
  constructor(private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private downloadService:DownloadService,
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory,
    private context:ContextFactory) { }
  @Input() infoRelated:any = null;

  ngOnInit(): void {
    this.getRelatedInfo(this.infoRelated[0]);
  }

  extractRelatedOrReferences(type:any, description:any){
      if(description.indexOf('<a') !== -1){
          let aSplit = description.split('<a ')
          for(let i = 0; i < aSplit.length; i++){
              let aTag = aSplit[i];
              if(aTag.indexOf(type) !== -1){
                  aTag = aTag.replace('href="#"','');
                  aTag = aTag.replace('common__tiny-link','common__tiny-link common__tiny-link--no-hover');
                  aTag = aTag.replace('}">','"><span class="common__tiny-link-span">');
                  aTag = aTag.replace('style="color: #551a8b; text-decoration: underline;"', '')
                  aSplit[i] = aTag.replace('</a>','</span><i class="lf-icon-open-window common__tiny-link-icon"></i></a> ');
              }
          }
          return aSplit.join('<a ');
      }else{
          return description;
      }
  }

  getRelatedInfo(infoRelated:any){
      let id = infoRelated.relatedFieldId;
      if(infoRelated.relatedInfoFieldId && !infoRelated.relatedFieldId){
          id = infoRelated.relatedInfoFieldId;
      }
      this.apiService.get('fields/'+id,'relatedInfo')
          .then((data:any)=>{
              this.relatedInformationModal.infoRelatedSelected = data;
              this.relatedInformationModal.infoRelatedSelected.title = infoRelated.text;
              let description: any = this.extractRelatedOrReferences('data-related="', this.relatedInformationModal.infoRelatedSelected.description);
              this.relatedInformationModal.description = this.extractRelatedOrReferences('data-references="', description);
              this.getTree();
          }, (errorData:any)=>{
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
          })
  }

  getTree(){
      this.apiService.get('fields/richfieldstree', this.context.settings.defaultProduct)
          .then((data:any)=>{
              this.relatedInformationModal.allPaths = new Map();
              this.configureTree(data, 'product', '');
              this.relatedInformationModal.pathList = data;
              this.configureRelatedList();
          }, (errorData:any)=>{});
  }

  configureTree(children:any, type:string, path:any){
      for(let i = 0; i < children.length;i++){
        children.forEach((child:any) => {
          switch(type){
            case 'product':
                child.name = child.productName;
                this.configureTree(child.folders, 'folder', path+child.name+'/');
                break;
            case 'folder':
                child.name = child.folderName;
                this.configureTree(child.concepts, 'concept', path+child.name+'/');
                this.configureTree(child.subFolders, 'folder',  path+child.name+'/');
                break;
            case 'concept':
                child.name = child.conceptName;
                this.configureTree(child.fieldContainers, 'fieldContainer', path+child.name+'/');
                path = child.name;
                break;
            case 'fieldContainer':
                child.name = child.fieldContainerName;
                this.configureTree(child.fields, 'field', path+child.name);
                this.configureTree(child.subContainers, 'fieldContainer', path);
                break;
           default:
                this.relatedInformationModal.allPaths.set(child.fieldId, path);
                break;
          }
        });
      }
  }

  configureRelatedList(){
      this.relatedInformationModal.relatedModalList = [];
      this.relatedInformationModal.relatedModalList.push({
              text: this.relatedInformationModal.infoRelatedSelected.description,
              path: this.relatedInformationModal.allPaths.get(this.relatedInformationModal.infoRelatedSelected.fieldId),
              route: this.extractRoute(this.relatedInformationModal.allPaths.get(this.relatedInformationModal.infoRelatedSelected.fieldId))
          })
  }

  extractRoute(path:any){
      let list = [];
      if(path.indexOf('/') !== -1){
          let pathSplit = path.split('/')
          for(let j = 0; j < pathSplit.length; j++){
              list.push({
                  name: pathSplit[j]
              })
          }
      }else{
          list.push({name:path})
      }
      let aux = [];
      aux.push(list[list.length-2]);
      return aux;
  }

  selectRelatedInfo(selectRelated:any){
      this.getRelatedInfo(selectRelated);
  }

  openNewWindow(){
    let frontIds: any = this.relatedInformationModal.infoRelatedSelected.frontIds;
      let editPath: string = '/evaluation/' + frontIds.evaluationId +
        '/folder/' + frontIds.folderId +
        '/concept/' + frontIds.conceptId +
        '/concept-evaluated/' + frontIds.conceptObjectId;
      window.open(editPath, '_blank');
  }

  print(){
      this.loader.openLoader('print-consult-concept');
      this.savePdf();
  }

  savePdf(){
      let htmlText = $('.print-block').html();
      this.apiService.getBlob('utils', 'to-pdf/html-fragment', 'post', htmlText)
          .then((data:any)=>{
            this.loader.closeLoader(['print-consult-concept']);
            let fileName = this.relatedInformationModal.infoRelatedSelected.label + '.pdf';
            this.downloadService.triggerDownload(data.blob, fileName);
          })
          .catch((errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');

          });
  }

  handleAccept() {
    this.activeModal.close({result: 'ok'});
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
