import { Component, OnInit, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'new-custom-report-preview',
  templateUrl: './new-custom-report-preview.component.html'
})
export class NewCustomReportPreviewComponent implements OnInit {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private loader:LoaderFactory,
    private modalService:ModalServiceService
  ) { }

  @Input() newCustomReport:any;
  @Input() predefinedReport:any;
  @Input() duplicatedPredefinedReport:any;
  @Input() editedCustomReport:any;

  skin:string = this.globalCfg.skin;
  newCustomReportPreview:any = {
    reportConcepts: [],
    conceptHeaderLabel: '',
    loading: true,
    widthColumn: 250 // 250 es el tamaño de la columna
  };

  ngOnInit(): void {
    let that = this.newCustomReportPreview;
    that.numberOfReportFields = 0;
    that.numberOfSpecialFields = 0;
    if (!this.predefinedReport) {
        let reportFields = this.newCustomReport.selectedReportFields.filter((item:any) => { return !item.conceptName && item.reportFieldId });
        that.numberOfReportFields = reportFields.length;
    }

    that.numberOfSpecialFields = 0;

    if (this.duplicatedPredefinedReport || this.editedCustomReport) {
        if (this.newCustomReport.conceptsInFirstColumn) {
            let conceptNameFields = this.newCustomReport.selectedReportFields.filter((item:any) => { return item.conceptName === true && !item.reportFieldId });
            if (conceptNameFields.length > 0) {
                this.newCustomReport.selectedReportFields = this.newCustomReport.selectedReportFields.filter((item:any) => { return !item.conceptName });
                this.newCustomReport.conceptHeaderLabel = conceptNameFields[0].reportFieldName;
            }
        }
        if (this.newCustomReport.specialFields) {
            let reportFields  = this.newCustomReport.selectedReportFields.filter((item:any) => { return !item.conceptName &&  item.reportFieldId });
            let specialFields = this.newCustomReport.selectedReportFields.filter((item:any) => { return !item.conceptName && !item.reportFieldId });
            this.newCustomReport.selectedReportFields = reportFields.concat(specialFields);
            that.numberOfSpecialFields = specialFields.length;
        }
    }

    if (!this.predefinedReport) {
        that.reportFieldStyle = this.calculateReportFieldsStyle();
        if (this.newCustomReport.specialFields) {
            that.specialFieldStyle = this.calculateSpecialFieldsStyle();
        }
    }

    this.getConceptsFromReportFields();
  }

  calculateReportFieldsStyle() {
    let that = this.newCustomReportPreview;
    if (!this.newCustomReport.conceptsInFirstColumn && (!this.newCustomReport.specialFields || that.numberOfSpecialFields === 0)) {
        return {
            width: '100%'
        };
    } else {
        if (this.newCustomReport.specialFields && that.numberOfSpecialFields > 0) {
            let width = this.newCustomReport.widthColumn * that.numberOfReportFields;
            return {
                'width': width + 'px',
                'margin-left': '5px'
            };
        } else {
            return {
                'width': 'calc(100% - 250px)',
                'margin-left': '5px'
            };
        }
    }
  }

  calculateSpecialFieldsStyle() {
    let that = this.newCustomReportPreview;
    let num = 0;
    if (this.newCustomReport.conceptsInFirstColumn) {
        num++;
    }
    if (this.newCustomReport.specialFields && that.numberOfSpecialFields > 0) {
        num += that.numberOfReportFields;
    }
    let width = this.newCustomReport.widthColumn * num;
    return {
        'width': 'calc(100% - ' + width + 'px)',
        'margin-left': '5px'
    };
  }

  getConceptsFromReportFields() {
    let that = this.newCustomReportPreview;
    let data = "";
    let n = 0;
    this.newCustomReport.selectedReportFields.forEach((el:any, index:any) => {
        if (el.reportFieldId) {
            if (data != "") {
                data += "&";
            }
            data += "l[" + n + "][C]=" + el.isCustom + "&" + "l[" + n + "][F]=" + el.reportFieldId;
            n++;
        }
    })

    this.loader.openLoader('new-custom-report-preview');
    this.apiService.get('customreportfields/GetConceptsFromReportFields?' + data).then(
      (data:any) => {
          that.reportConcepts = data;
          that.tooltipValue = new Array(data.length).fill(false);

          if (this.predefinedReport) {
              this.newCustomReport.selectedReportFields.forEach((el:any, index:any) => {
                  if (!el.reportFieldId) {
                      if (!el.conceptName) { // se rellena reportConcepts con los de tipo especiales
                          let specialElement = (el.reportFieldName.toLowerCase() === "tareas") ? 'N� de Tareas' : el.reportFieldName;
                          for (let i = 0; i < that.reportConcepts.length; i++) {
                              let newElement = { isCustom: 0, fieldId: null, fieldName: specialElement, fieldTypeId: null, reportFieldId: null }
                              that.reportConcepts[i].fields.splice(index, 0, newElement)
                          }
                      } else { // se rellena reportConcepts con los de tipo conceptName
                          for (let i = 0; i < that.reportConcepts.length; i++) {
                              let newElement = { isCustom: 0, fieldId: null, fieldName: that.reportConcepts[i].conceptName, fieldTypeId: null, reportFieldId: null }
                              that.reportConcepts[i].fields.splice(index, 0, newElement)
                          }
                      }
                  }
              })
          }
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
      that.loading = false;
      this.loader.closeLoader('new-custom-report-preview');
    });
  }

  changeHeaderLabel(index:number) {
    let that = this.newCustomReportPreview;
    let fieldName = '';
    if (index === -1) {
        fieldName = this.newCustomReport.conceptHeaderLabel;
    } else {
        fieldName = this.newCustomReport.selectedReportFields[index].reportFieldName;
    }

    let newItem = {
        title: this.translate.instant('rename-modal.rename-title-column'),
        type: 'title',
        titleConcept: fieldName,
        icon: 'lf-icon-rename',
    }
    this.modalService.renameModal(newItem).subscribe((result:any) => {
        if (index === -1) {
            this.newCustomReport.conceptHeaderLabel = result.name;
        } else {
            this.newCustomReport.selectedReportFields[index].reportFieldName = result.name;
        }
    });
  }

  getIcon(field:any) {
    switch (field.fieldTypeId) {
        case 'text':
            return "lf-icon-text-field";
        case 'rich':
            return "lf-icon-text-edit";
        case 'list':
        case 'listp':
            return "lf-icon-dropdown";
        case 'l-co':
            return "lf-icon-connected-data";
        case 'num':
            return "lf-icon-hashtag";
        case 'date':
            return "lf-icon-calendar";
        case 'bool':
        case 'boolp':
            return "lf-icon-switch-right";
        case 'formu':
            return "lf-icon-calculator";
        case 'file':
            return "lf-icon-up-file";
        case 'money':
            return "lf-icon-coin";
        case 'user':
            return "lf-icon-user";
        case 'txchk':
            return "lf-icon-check-empty";
        default:
            return "lf-icon-text-field";
    }
  }
}
