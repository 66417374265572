import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutCustomizationGuard implements CanActivate {
  canCustomize: boolean = false;
  constructor(private context: ContextFactory,
      private globalCfg: GlobalCfgFactory,
      private router: Router,
      private apiService: ApiServiceService,
      private apiProperties: ApiPropertiesFactory) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.globalCfg?.loaded){
        return this.checkAccessCustomization();
      }else{
        return this.getProperties();
      }
  }

  checkAccessCustomization(){
    this.canCustomize = this.context && this.context.user && this.context.user.isAdmin && !!this.context.user.companyId && this.globalCfg.skin !== 'uk-steel-blue'?true:false;
    if(!this.canCustomize && this.context?.jwtToken){
      this.router.navigate(['dashboard']);
    }
    return this.canCustomize;
  }

  getProperties(){
    return this.apiService.getConfig().then((data)=>{
        this.apiProperties.setApiProperties(data);
        return this.getGlobalCfg();
    },(errorData:any)=>{
        return false;
    })
  }

  getGlobalCfg(){
    return this.apiService.getGlobal()
        .then((data:any)=>{
            this.globalCfg.setGlobalCfg(data.data);
            return this.checkAccessCustomization();
        },(errorData:any)=>{
            return false;
        })
  }

}
