<div class="field-type-radio-option__block-field"
    [ngClass]="{'common__input-no-editable': !fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable}"
    [attr.disabled]="(!fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable)?true:null">
    <div class="common__label-block common__label-block--one-icon common__label-block--margin-0">
        <span class="common__input-text"
            [ngClass]="{'common__input-text--required': fieldTypeRadioOption.field.error,
                    'common__input-text--no-editable':fieldTypeRadioOption.field.noEditable}">{{fieldTypeRadioOption.titleField}}</span>
        <span class="common__text-required"
            [ngShow]="fieldTypeRadioOption.field.required">*</span>
    </div>
    <i class="lf-icon-radio-buttons-list field-type-radio-option__icon-radio-buttons"></i>
    <div class="field-type-radio-option__block-label">
        <label class="field-type-radio-option__label"
            *ngFor="let option of listOptions"
            [for]="option.id"
            [ngClass]="{'field-type-radio-option__label--disabled': !fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable}">
            <input class="field-type-radio-option__input-radio"
                [id]="option.id"
                type="radio"
                [name]="fieldTypeRadioOption.field.type"
                [value]="option.value"
                [(ngModel)]="fieldTypeRadioOption.field.model"
                (change)="changeValue(option.value)"
                [disabled]="!fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable">
            <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                [ngClass]="{'lf-icon-radio-button-active': fieldTypeRadioOption.field.model === option.value,
                    'lf-icon-step-first': fieldTypeRadioOption.field.model !== option.value,
                    'field-type-list-extended__icon-container--disabled':!fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable}">
            </i>
            <p class="field-type-list-extended__text-element-list field-type-radio-option__span-text-associated"
                [ngClass]="{'field-type-list-extended__text-element-list--disabled': !fieldTypeRadioOption.field.canCreate || fieldTypeRadioOption.field.noEditable}">
                {{option.name}}
            </p>
        </label>
    </div>
</div>
