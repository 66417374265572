import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'public-link-poll',
  templateUrl: './public-link-poll.component.html'
})
export class PublicLinkPollComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  evaluationId: string = this.route.snapshot.params['evaluationId'];
  publicLinkPoll: any ={
    confirmPublish: "",
    stepState: 1,
    showInfoGlobal: false,
    isGlobalInfo: false,
    showInfo: true,
    showOtherFields: true,
    step: 1,
    message: '',
    // vm.corporativeInformationCopy: null,
    companyCopy: null,
    fileName: [],
    globalConfiguration: null,
    accept: this.translate.instant('common.ok'),
    company: {
      corporativeInformation: {
          color: '',
          youtube: '',
          twitter: '',
          facebook: '',
          linkedin: '',
          phone:'',
          footer: ''
      },
      logo: '',
      name: '',
      logoFile: null
  },
  tinyMceOptionsStep1: {
    noEditable: false,
    typeView: 'white',
    height: 288,
    toolbar: 'forecolor bold italic underline alignleft aligncenter alignright link unlink',//fontfamily | alignleft aligncenter alignright alignjustify | blocks | undo redo | bullist numlist outdent indent | removeformat | link
    placeholder: this.translate.instant('corporative-information-modal.placeholder')
  },
  tinyMceOptionsStep2: {
    noEditable: false,
    typeView: 'white',
    height: 220,
    toolbar: 'bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify undo redo bullist numlist outdent indent removeformat link',
    placeholder: this.translate.instant('common.add-comment')
  },
  clientFavicon: {
      id: 'favicon-public-link',
      label: this.translate.instant('corporative-information-modal.favicon-label'),
      description: this.translate.instant('corporative-information-modal.favicon-description'),
      accept:'.png,.ico,.jpg,.jpeg'
  },
  footerDesignType: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('global-customization.design-footer')
  },
  color: {
      id: "principal-color",
      label: this.translate.instant('global-customization.principal-color'),
      required: true,
      type: 'principal-color'
  },
  textColor: {
      id: 'text-color-customization',
      label: this.translate.instant('global-customization.foreground-color'),
      required: true
  },
  youtubeLink: {
      id: "youtube-link",
      label: 'Youtube',
      type: 'link'
  },
  twitterLink: {
      id: "twitter-link",
      label: 'Twitter',
      type: 'link'
  },
  facebookLink: {
      id: "facebook-link",
      label: 'Facebook',
      type: 'link'
  },
  linkedinLink: {
      id: "linkedin-link",
      label: 'Linkedin',
      type: 'link'
  },
  clientLogo:{
      id: '0',
      label: this.translate.instant('corporative-information-modal.logo'),
      description: null,
      required: true
  },
  phoneReference: {
      id: "phone-reference-customization",
      label: this.translate.instant('global-customization.reference-phone'),
      fieldTypeId: 'num'
  },
  contactsField: {
    typeConfiguration: 'publish-concept',
    fieldType: 'multiple',
    addContacts: true,
    required: false,
    typeCall: 'contacts',
    selectContacts: []
  },
  // colorList = ['#AF9F01', '#B87300', '#BC5901', '#C10F0B', '#830065', '#4A006F', '#001478', '#01417F', '#009598', '#00773B', '#008B07', '#000000', '#FFF201', '#FFB001', '#EB6F00', '#FF3734', '#D011A2', '#9470C0', '#003FAE', '#3B92D6', '#00D2D6', '#00B165', '#00CF2A', '#898989'];
  footerDesignTypeList: [
    {
        text: this.translate.instant('global-customization.footer-white'),//'_Fondo blanco con texto e iconos negros.',
        listValueId: 1
    },
    {
        text: this.translate.instant('global-customization.footer-black'),//'_Fondo negro con texto e iconos blancos.',
        listValueId: 2
    },
    {
        text: this.translate.instant('global-customization.footer-principal-color'),//'_Fondo del color principal con texto e iconos en blanco o negro.',
        listValueId: 3
    }
  ],
  steps: [
    {
        name:this.translate.instant('corporative-information-modal.wizard-step-1')  //"marca y aspecto grafico"
        //noEditable:true
    },{
        name: this.translate.instant('corporative-information-modal.wizard-step-2')  //"pie de pagina"
        //noEditable:true
    }
  ]
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private registerService: RegisterMixtapeService,
    private dateConverterService : DateConverterService,
    private modalService: ModalServiceService) { }
  @Input() poll: any = null;
  @Input() evaluationInfo: any = null;

  ngOnInit(): void {
    this.publicLinkPoll.published = this.poll.publicShared;
    // _getGlobalConfiguration();
    this.getCorporativeInformation();
    this.getUrlPublic();
    this.resizeWindow();
    this.changeShowInfo(true);

  }

  getCorporativeInformation(){
    this.apiService.get('publicconceptcfg', 'concept/' + this.poll.conceptId)
        .then((data:any)=>{
            if(data){
                data.footerDesignType = !data.footerDesignType? 3 : data.footerDesignType
                this.publicLinkPoll.isGlobalInfo = data.companyId?false:true;
                this.getGlobalConfiguration(!this.publicLinkPoll.isGlobalInfo);
                if(this.publicLinkPoll.isGlobalInfo){
                  this.fillSetCorporativeInformation(data);
                }
                // Al obtener los logos, los obtiene de la configuración que este aplicando
                this.publicLinkPoll.company.allLogos = this.configureLogos(data);
                this.publicLinkPoll.company.logo = this.publicLinkPoll.company.allLogos;
                this.publicLinkPoll.company.logoFile = this.convertToFormData(this.publicLinkPoll.company.allLogos);
               // this.publicLinkPoll.company.favIcon = commonUtilsService.setLogo(data.favIcon);
                this.publicLinkPoll.company.faviconImg = this.commonUtilsService.setLogo(data.favIcon, data.favIcon);
                this.publicLinkPoll.company.faviconFile = this.convertToFormDataFavicon(this.publicLinkPoll.company.favIcon);
                this.publicLinkPoll.companyCopy = cloneDeep(this.publicLinkPoll.company);
            }else{
                // es nuevo y no tiene ninguna configuración, ni global ni específica
                this.getLogo();
            }
        }, (errorData:any)=> {
            this.handleDismiss('closeError');
        });
  }

  getGlobalConfiguration(fill:any = null){
    this.apiService.get('company','globalcustomization/'+this.context.user.companyId)
        .then((dataglobal:any)=>{
            this.publicLinkPoll.globalConfiguration = dataglobal;
            if(fill){
                this.fillSetCorporativeInformation(dataglobal);
            }else{
                this.publicLinkPoll.showInfoGlobal = true;
                this.publicLinkPoll.titleAlert = this.translate.instant('public-link-poll.identity-specific');
            }
        },function(errorData){
            console.log(errorData);
        });
  }

  handleAccept(type:any = 'noUpdate') {
    this.activeModal.close({
      result: 'ok',
      type:type,
      // name:  this.setMessageForDestinations()
    })
  }

  handleDismiss(close:string = '') {
    this.activeModal.close({close: close});
  }

  fillSetCorporativeInformation(data:any){
    if(!data.textColor && data.color){
        data.textColor = '#ffffff';
        if(data.color.toLowerCase() === '#fff201' || data.color.toLowerCase() === '#ffb001'){
            data.textColor = '#000000';
        }
    }
    this.publicLinkPoll.company.corporativeInformation = {
        color: data.color,
        textColor: data.textColor,
        footerDesignType: data.footerDesignType,
        youtube: data.youtube,
        twitter: data.twitter,
        facebook: data.facebook,
        linkedin: data.linkedin,
        phone: data.phone,
        conceptId: this.poll.conceptId,
        footer: data.footer,
        showLogoInFooter: data.showLogoInFooter,
        sharedPublicConceptConfigurationId: data.sharedPublicConceptConfigurationId,
        global: (data.companyId !==null),
    };
    if(data.companyId !==null){
        this.publicLinkPoll.titleAlert = this.translate.instant('public-link-poll.identity-global');
    }
    this.publicLinkPoll.companyCopy = cloneDeep(this.publicLinkPoll.company);
    if(data.footer){
        this.publicLinkPoll.message= data.footer;
    }
  }

  configureLogos(data:any){
    return {
        logo: this.commonUtilsService.setLogo(data.logo, data.logo),
        logo320X55: this.commonUtilsService.setLogo(data.logoFooter, data.logo),
        logo260X45: this.commonUtilsService.setLogo(data.logoHeader, data.logo),
        logo290X50: this.commonUtilsService.setLogo(data.logoOther, data.logo)
    }
  }

  convertToFormData(logos:any) {
    let fd = new FormData();
    let logo = this.commonUtilsService.baseToFormdata(logos.logo);
    let logo320X55 = this.commonUtilsService.baseToFormdata(logos.logo320X55);
    let logo260X45 = this.commonUtilsService.baseToFormdata(logos.logo260X45);
    let logo290X50 = this.commonUtilsService.baseToFormdata(logos.logo290X50);
    fd.append('file', logo);
    fd.append('file', logo320X55);
    fd.append('file', logo260X45);
    fd.append('file', logo290X50);
    return fd;
  }

  convertToFormDataFavicon(favicon:any){
    let fd = new FormData();
    let newFavicon = this.commonUtilsService.baseToFormdata(favicon);
    fd.append('file', newFavicon);
    return fd;
  }

  getLogo(){
    this.apiService.get('clients', this.evaluationInfo.clientId)
        .then((data:any)=>{
            if(data.logo !==''){

                this.publicLinkPoll.company.allLogos ={
                    logo: this.commonUtilsService.setLogo(data.logo, data.logo),
                    logo320X55: this.commonUtilsService.setLogo(data.logo320X55,data.logo),
                    logo260X45: this.commonUtilsService.setLogo(data.logo260X45,data.logo),
                    logo290X50: this.commonUtilsService.setLogo(data.logo290X50,data.logo)
                }
                this.publicLinkPoll.company.logo = this.publicLinkPoll.company.allLogos.logo;
                this.publicLinkPoll.company.logoFile = this.convertToFormData(this.publicLinkPoll.company.allLogos);
                this.publicLinkPoll.company.name = '';
                this.publicLinkPoll.companyCopy = cloneDeep(this.publicLinkPoll.company);
            }
            this.fillSetCorporativeInformation(data);
        }, function(errorData){
        })
  }

  getUrlPublic(){
    this.apiService.get('concepts/publicurl', this.poll.conceptId)
        .then((data:any)=>{
            this.publicLinkPoll.url = data;
        }, (errorData:any)=>{
          this.publicLinkPoll.url = "";
          this.handleDismiss('closeError');
        })
  }

  resizeWindow(){
    window.addEventListener('resize', ()=>{
      setTimeout(()=>{
        this.changeShowInfo(true);
      });
    })
  }

  changeShowInfo(resize:boolean = false){
    let that = this.publicLinkPoll;
    if(!resize){
      that.resize = false;
      that.showInfo = !that.showInfo;
    }else{
      that.resize = true;
    }

    if(!that.showInfo){
        $('.modal-content').addClass('public-link-poll__modal-size');
        if(window.innerWidth >= 1500 && resize){
            $('.modal-content').removeClass('public-link-poll__modal-size');
            that.showInfo = true;
        }
    }else{
        $('.modal-content').removeClass('public-link-poll__modal-size');
        if(window.innerWidth < 1500 && resize){
            $('.modal-content').addClass('public-link-poll__modal-size');
            that.showInfo = false;
        }else if(window.innerWidth >= 1500 && resize){
            that.showInfo = true;
        }
    }

    if(that.step === 2){
      that.showInfo = true;
    }
  }

  setMessageForDestinations(){
    if(this.commonUtilsService.extractInnerHTML(this.publicLinkPoll.messageLink)){
      this.publicLinkPoll.contactsField.selectContacts.forEach((contact:any) => {
        contact.comments = this.publicLinkPoll.messageLink;
      });
    }
  }

  savePoll(){
    if(this.publicLinkPoll.modify){
        this.checkMessage();
        if(this.validateField()){
            this.configureCorporativeInformation();
        }
    }else{
        this.handleDismiss('');
    }
  }

  checkMessage(){
    let message = cloneDeep(this.commonUtilsService.extractInnerHTML(this.publicLinkPoll.message));
    let globalConfiguration: any = this.publicLinkPoll.globalConfiguration;
    let footer: string = globalConfiguration.footer;
    if(globalConfiguration && ((footer && footer !== message) || !footer)){
      this.publicLinkPoll.isGlobalInfo = false;
    }
  }

  validateField(){
    let company:any = this.publicLinkPoll.company;
    let validateColor: any = this.commonUtilsService.checkIsHexadecimalColor(company.corporativeInformation.color);//company.corporativeInformation.color;
    let validateTextColor: any = this.commonUtilsService.checkIsHexadecimalColor(company.corporativeInformation.textColor);//company.corporativeInformation.textColor;
    if(company.logo !== '' && validateColor && validateTextColor){
        this.publicLinkPoll.clientLogo.error = false;
        this.publicLinkPoll.color.error = false;
        this.publicLinkPoll.textColor.error = false;
        return true;
    }else {
        this.publicLinkPoll.clientLogo.error = false;
        this.publicLinkPoll.color.error = false;
        this.publicLinkPoll.textColor.error = false;
        if(this.publicLinkPoll.company.logo === ''){
          this.publicLinkPoll.clientLogo.error = true;
        }
        if(!validateColor){
          this.publicLinkPoll.color.error = true;
        }
        if(!validateTextColor){
          this.publicLinkPoll.textColor.error = true;
        }
        return false;
    }
  }

  configureCorporativeInformation(){
    this.loader.openLoader('folder-share-public-poll');
    let message = cloneDeep(this.commonUtilsService.extractInnerHTML(this.publicLinkPoll.message));
    let coporative: any = this.publicLinkPoll.company.corporativeInformation;
    if(message) {
        coporative.footer = this.publicLinkPoll.message;
    }
    this.publicLinkPoll.confirmPublish =this.publicLinkPoll.isGlobalInfo?this.translate.instant('public-link-poll.identity-global') : this.translate.instant('public-link-poll.identity-specific');
    if(this.changesInCorprativeInformation()){
      if(coporative.global){
          coporative.global = false;
          this.addCorporativeInfo();
        }else{
          coporative.global = false;
          coporative.sharedPublicConceptConfigurationId? this.updateCorporativeInfo() :this.addCorporativeInfo();
        }
    }else{
      this.changePublicShared(!(this.publicLinkPoll.published));
    }
  }

  changesInCorprativeInformation(){
    let companyCopy: any = this.publicLinkPoll.companyCopy;
    let company: any = this.publicLinkPoll.company
    if(companyCopy && company){
        for(let property in companyCopy.corporativeInformation){
            if(companyCopy.corporativeInformation[property] !== company.corporativeInformation[property]){
                return true;
            }
        }
        for(let property in companyCopy.allLogos){
            if(companyCopy.allLogos[property] !== company.allLogos[property]){
                return true;
            }
        }
        if(companyCopy.faviconImg !== company.faviconImg){
            return true;
        }
    }else if(!companyCopy){
        return true;
    }
    return false;
  }

  addCorporativeInfo(){
    this.apiService.add('publicconceptcfg', this.publicLinkPoll.company.corporativeInformation)
        .then((data:any)=>{
            if (data !== null && data !== '') {
                this.publicLinkPoll.company.corporativeInformation.sharedPublicConceptConfigurationId = data.sharedPublicConceptConfigurationId;
                this.addLogoToCorporativeInformation(!this.publicLinkPoll.published);
            }else{
                this.changePublicShared(false);
            }
            if(this.publicLinkPoll.company.faviconFile){
                this.addFaviconToCorporativeInformation();
            }
        },  ()=>{
            this.loader.closeError();
        })
  }

  addLogoToCorporativeInformation(newPoll:boolean = false){
    let sharedConfigurationId: any = this.publicLinkPoll.company.corporativeInformation.sharedPublicConceptConfigurationId
    this.apiService.attachFilePatch('publicconceptcfg/'+ sharedConfigurationId+'/logos', this.publicLinkPoll.company.logoFile)
        .then((data:any)=>{
            if(newPoll){
                this.changePublicShared(true);
            }else{
              this.loader.closeLoader('folder-share-public-poll');
              let text: string = this.publicLinkPoll.company.corporativeInformation.global? 'update-gl' : 'update';
              this.handleAccept(text);
            }
        }, (errorData:any)=>{
            this.loader.closeError();
            this.changePublicShared(false);
        });
  }

  changePublicShared(publicSharedStatus:boolean, unpublish:string = '') {
    this.loader.openLoader('folder-public-shared');
    this.apiService.patch('concepts/' + this.evaluationInfo.evaluationId + '/' + this.poll.conceptId + '/setpublic/' + publicSharedStatus)
        .then((data:any)=> {
            this.publicLinkPoll.published = publicSharedStatus;
            this.poll.publicShared = publicSharedStatus;
            if(this.publicLinkPoll.published){
                this.publicLinkPoll.step = 2;
                this.publicLinkPoll.showInfo = true;
            }

            this.loader.closeLoader(['folder-public-shared','folder-share-public-poll' ]);
            if(unpublish === 'unpublish'){
                this.publicLinkPoll.published = false;
                this.handleDismiss('unpublish');
            }
        },  ()=>{
            this.loader.closeError();
        })
  }

  addFaviconToCorporativeInformation(){
    let configurationId: any = this.publicLinkPoll.company.corporativeInformation.sharedPublicConceptConfigurationId
    this.apiService.attachFilePatch('publicconceptcfg/'+ configurationId+'/favicon', this.publicLinkPoll.company.faviconFile)
        .then(()=>{}, ()=>{});
  }

  updateCorporativeInfo(){
    this.apiService.update('publicconceptcfg', undefined, this.publicLinkPoll.company.corporativeInformation)
        .then((data:any)=> {
            data !== null && data !== ''? this.addLogoToCorporativeInformation(): this.changePublicShared(false);

            if( this.publicLinkPoll.company.faviconFile){
                this.addFaviconToCorporativeInformation();
            }
        }, ()=>{
            this.loader.closeError();
        })
  }

  unpublishPoll(){
    this.changePublicShared(false, 'unpublish');
  }

  publishPoll(){
    if(this.validateField()){
        this.configureCorporativeInformation();
        this.saveConcept();
        let resgisterItem ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
            'event':'publish public link',
            'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.poll.title,
            'Centinela product': this.context.settings.defaultProduct
        }
        this.registerService.register(resgisterItem);
    }
  }

  saveConcept(){
      this.poll.pollBeginDate = this.dateConverterService.toIso(new Date());
      this.apiService.patch('concepts/'+this.evaluationInfo.evaluationId, this.poll.conceptId, this.poll)
      .then((data:any)=>{
          this.loader.closeLoader(['folder-rename-modal']);
      }, (error:any)=>{
          this.loader.closeError();
      })
  }

  sharePublicPoll(){
      this.loader.openLoader('share-public-concept');
      this.setMessageForDestinations();
      let copyNotification = Number(this.publicLinkPoll.checkCopyNotification);
      if(isNaN(copyNotification)) copyNotification = 0;

      this.apiService.patch('concepts/' + this.evaluationInfo.evaluationId, this.poll.conceptId + '/sharepublic/' + copyNotification , this.publicLinkPoll.contactsField.selectContacts)
          .then((data:any)=> {
              this.loader.closeLoader('share-public-concept');
              let text: string = this.publicLinkPoll.company.corporativeInformation.global? 'update-gl' : 'update';
              this.handleAccept(text);
          },  ()=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('folder.error-shared-link'), 'error:accept:warning');
          })
  }

  receiveContacts(contacts:any){
      this.publicLinkPoll.contactsField.selectContacts = contacts;
  }

  itemSelectedOn(event:any, type:string){
    let newValue:any = event.newValue;
    this.itemSelected(type, newValue)
  }

  itemSelected(type:string, newValue:any){
      let corporativeInformation: any = this.publicLinkPoll.company.corporativeInformation;
      switch(type){
          case 'color':
          case 'textColor':
              corporativeInformation[type] = newValue.value.hex;
              if(corporativeInformation[type] !== '' || corporativeInformation[type] !== null){
                  this.publicLinkPoll[type].error = false;
              }
              break;
          case 'logo':
              this.commonUtilsService.setLogo(newValue.img, newValue.img);
              this.publicLinkPoll.company.listLogos = newValue.listLogos;
              this.publicLinkPoll.company.logo = newValue.img;
              this.publicLinkPoll.company.name =  newValue.name;
              this.publicLinkPoll.company.logoFile = newValue.files;
              this.publicLinkPoll.company.allLogos ={
                  logo:newValue.listLogos[0].html.toDataURL('image/png'),
                  logo320X55: newValue.listLogos[2].html.toDataURL('image/png'),
                  logo260X45: newValue.listLogos[3].html.toDataURL('image/png'),
                  logo290X50: newValue.listLogos[1].html.toDataURL('image/png'),
              }
              break;
          case 'favicon':
              this.publicLinkPoll.company.faviconName = newValue.name;
              this.publicLinkPoll.company.faviconImg = newValue.img;
              this.publicLinkPoll.company.faviconFile = newValue.file;
              break;
          case 'youtube':
          case 'twitter':
          case 'facebook':
          case 'linkedin':
          case 'phone':
          case 'showLogoInFooter':
              corporativeInformation[type] = newValue;
              this.publicLinkPoll.modify = this.changesInCorprativeInformation();
              this.publicLinkPoll.accept = this.publicLinkPoll.modify? this.translate.instant('common.save') : this.translate.instant('common.ok');
              break;
          case 'footer':
              let message = cloneDeep(this.commonUtilsService.extractInnerHTML(newValue));
              if(message) {
                  corporativeInformation.footer = newValue;
              }
              this.publicLinkPoll.modify = this.changesInCorprativeInformation();
              this.publicLinkPoll.accept = this.publicLinkPoll.modify? this.translate.instant('common.save') : this.translate.instant('common.ok');
              break;
          default:
            corporativeInformation[type] = newValue;
          break;
      }
      this.publicLinkPoll.isGlobalInfo = false;
  }

  glConfig(){
      this.modalService.adviceModal(this.translate.instant('public-link-poll.set-global-config-question'), 'info:question:question')
        .subscribe((result:any) => {
          if(result.result === 'ok'){
            this.deleteConfig();
          }
        });
  }

  deleteConfig(){
    let sharedConfiguration: any = this.publicLinkPoll.company.corporativeInformation.sharedPublicConceptConfigurationId
      this.apiService.delete('publicconceptcfg', sharedConfiguration)
      .then((data:any)=>{
          this.publicLinkPoll.confirmPublish = this.translate.instant('public-link-poll.identity-global');
          this.publicLinkPoll.showInfoGlobal = false;
          this.publicLinkPoll.isGlobalInfo = true;
          this.publicLinkPoll.published? this.handleAccept('update-gl'): this.getGlobalConfiguration();
      },(error:any)=>{})
  }

  nextStep(){
      if(this.validateFieldStep(this.publicLinkPoll.stepState)){
          if(this.publicLinkPoll.stepState === 1){
            this.publicLinkPoll.modify = this.changesInCorprativeInformation();
            this.publicLinkPoll.accept = this.publicLinkPoll.modify? this.translate.instant('common.save') : this.translate.instant('common.ok');

          }
          this.publicLinkPoll.stepState++;
      }
  }

  validateFieldStep(step:any){
    let corporative: any = this.publicLinkPoll.company.corporativeInformation;
    switch(step){
        case 1:
          this.publicLinkPoll.color.error = !(this.commonUtilsService.checkIsHexadecimalColor(corporative.color));//(corporative.color === null || corporative.color === "")
          this.publicLinkPoll.textColor.error = !(this.commonUtilsService.checkIsHexadecimalColor(corporative.textColor));//(corporative.textColor === null || corporative.textColor === '' || corporative.textColor === undefined);
          this.publicLinkPoll.footerDesignType.error = (!corporative.footerDesignType);
          this.publicLinkPoll.clientLogo.error = (this.publicLinkPoll.company.logo === '');
          return !(this.publicLinkPoll.color.error || this.publicLinkPoll.textColor.error || this.publicLinkPoll.footerDesignType.error || this.publicLinkPoll.clientLogo.error)
        default:
            return false;
    }
  }

  copyText(tooltip:NgbTooltip, type:string) {
      this.commonUtilsService.clipboardText(type);
      tooltip.open();
      setTimeout(() => {
        tooltip.close();
      },1000)
  }

  generateQr(url:any){
    this.modalService.qrModal(url)
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.modalService.adviceModal(this.translate.instant('channel-configuration.qr-succes'),'info:accept:check-round')
        }
    })
  }
}
