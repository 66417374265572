<div class="general-report common__window-footer-bottom">
    <header-nav></header-nav>
    <div class="general-report__upper-container">
        <div class="general-report__upper-container-button"
            (click)="changeView()">
            <span class="general-report__upper-button-text"
                [afTranslate]="'general-report.close-button'"></span>
            <i class="lf-icon-close general-report__upper-button-icon"></i>
        </div>
    </div>
    <div class="general-report__list-container">
        <div class="general-report__list-container-header">
            <div class="general-report__container-header-left">
                <div class="general-report__title">
                    <i class="lf-icon-label-analysis general-report__title-icon"></i>
                    <span class="general-report__title-text"
                        [afTranslate]="'general-report.title'"></span>
                </div>
                <div class="general-report__container-header-dropdown open"
                    ngbDropdown
                    ngbDropdownToggle>
                    <span class="general-report__header-dropdown-text"
                      [afTranslate]="generalReport.tab === 'detail' ?'general-report.report-detail': 'common.documentation'"></span>
                    <i class="lf-icon-angle-down general-report__header-dropdown-icon"></i>
                    <ul class="dropdown-menu common__block-option-list general-report__header-dropdown-list"
                        ngbDropdownMenu>
                        <li class="common__language-item"
                            (click)="generalReport.tab = 'detail'">
                            <span [afTranslate]="'general-report.report-detail'"></span>
                            <i class="lf-icon-check-round-full common__language-icon"></i>
                        </li>
                        <li class="common__language-item"
                            (click)="generalReport.tab = 'docs'">
                            <span [afTranslate]="'common.documentation'"></span>
                            <span class="general-report__dropdown-item-badge">
                                {{generalReport.reportDocuments.length}}
                            </span>
                            <i class="lf-icon-check-round-full common__language-icon"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="general-report__container-header-right">
                <div class="general-report__container-header-button"
                    [ngShow]="generalReport.tab === 'detail'"
                    (click)="exportReport(generalReport.reportObjects[0].reportId)">
                    <span class="general-report__header-button-text"
                        [afTranslate]="'general-report.export-button'"></span>
                    <i class="lf-icon-send-excel general-report__header-button-icon"></i>
                </div>
            </div>
        </div>
        <div class="general-report__table"
            [ngShow]="generalReport.tab === 'detail'">
            <div class="general-report__theader">
                <div class="general-report__theader-upper">
                    <div class="general-report__tcell general-report__tcell--first general-report__tcell--year"
                        ngbDropdown>
                        <span>{{generalReport.currentYear}}</span>
                        <i class="lf-icon-angle-down general-report__tcell-icon"
                            ngbDropdownToggle>
                        </i>
                        <ul class="general-report__dropdown-menu"
                            ngbDropdownMenu>
                            <li class="general-report__dropdown-item"
                            *ngFor="let item of generalReport.years | keyvalue "
                            (click)="selectYear(item.key)">
                                <span>{{item.key}}</span>
                                <i class="lf-icon-check-round-full general-report__dropdown-icon"></i>
                            </li>
                        </ul>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-upper"
                        style="width:14%"
                        [afTranslate]="'general-report.total-queries'">
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-upper"
                        style="width:26%"
                        [afTranslate]="'general-report.not-resolved'">
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-upper"
                        style="width:30%"
                        [afTranslate]="'general-report.pending'">
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-upper"
                        style="width:17%"
                        [afTranslate]="'general-report.resolved'">
                    </div>
                    <div class="general-report__tcell general-report__tcell--last general-report__tcell--header-upper"
                        style="width:13%"
                        [afTranslate]="'general-report.resolution'">
                    </div>
                </div>
                <div class="general-report__theader-lower">
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--first">
                        <filter-by-list [type]="'list'"
                                        [item]="generalReport.monthNameFilter"
                                        [list]="generalReport.monthNameList"
                                        (filterElementEmit)="selectTypeOperation($event, {id:'monthName'})">
                        </filter-by-list>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--subcells"
                        style="width:14%">
                        <div class="general-report__tsubcell"
                            style="width:50%"
                            [afTranslate]="'general-report.registered'"></div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%"
                            [afTranslate]="'general-report.accumulated-registered'"></div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--subcells"
                        style="width:26%">
                        <div class="general-report__tsubcell"
                            style="width:25%"
                            [afTranslate]="'general-report.canceled'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.accumulated-canceled'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.uninitiated'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.processing'">
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--subcells"
                        style="width:30%">
                        <div class="general-report__tsubcell"
                            style="width:25%"
                            [afTranslate]="'general-report.external'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.internal'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.collegiate'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%"
                            [afTranslate]="'general-report.payment'">
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--subcells"
                        style="width:17%">
                        <div class="general-report__tsubcell"
                            style="width:50%"
                            [afTranslate]="'general-report.documentation'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%"
                            [afTranslate]="'general-report.documentation-paid'">
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--header-lower general-report__tcell--subcells general-report__tcell--last"
                        style="width:13%">
                        <div class="general-report__tsubcell"
                            style="width:50%"
                            [afTranslate]="'general-report.%-month'">
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%"
                            [afTranslate]="'general-report.%-accumulated'">
                        </div>
                    </div>
                </div>
            </div>
            <div class="general-report__tbody">
                <div class="general-report__trow"
                    [ngClass]="{'general-report__trow--last': last}"
                    *ngFor="let row of generalReport.filteredRows; let last = last">
                    <div class="general-report__tcell general-report__tcell--first">
                        {{row.monthName}}
                    </div>
                    <div class="general-report__tcell general-report__tcell--subcells"
                        style="width:14%">
                        <div class="general-report__tsubcell"
                            style="width:50%">
                            {{row.registradasEnElMes}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%">
                            {{row.acumuladoTotalMesAMes}}
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--subcells"
                        style="width:26%">
                        <div class="general-report__tsubcell"
                            style="width:25%">
                            {{row.anulada}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.acumuladoAnuladasMesAMes}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.registrada}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.enProceso}}
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--subcells"
                        style="width:30%">
                        <div class="general-report__tsubcell"
                            style="width:25%">
                            {{row.finalizadaPendienteInformacionExterna}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.finalizadaPendienteInformacionInterna}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.finalizadaPendienteDelColegiado}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:25%">
                            {{row.finalizadaPendienteDePago}}
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--subcells"
                        style="width:17%">
                        <div class="general-report__tsubcell"
                            style="width:50%">
                            {{row.finalizadaDocumentacionEntregada}}
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%">
                            {{row.pagada}}
                        </div>
                    </div>
                    <div class="general-report__tcell general-report__tcell--subcells general-report__tcell--last"
                        style="width:13%">
                        <div class="general-report__tsubcell"
                            style="width:50%">
                            {{row.porcentajeConsultasResueltas}}%
                        </div>
                        <div class="general-report__tsubcell general-report__tsubcell--no-first"
                            style="width:50%">
                            {{row.porcentajeResueltasAcumulado}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <documentation-report-list [ngShow]="generalReport.tab === 'docs'"
            [documentationReport]="generalReport.reportDocuments"
            (launchAction)="launchAction($event)">
        </documentation-report-list>
    </div>
    <compliance-footer class="common__footer-bottom"></compliance-footer>
</div>
