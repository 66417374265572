import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ConceptStepsParentsService } from '../utils/concept-steps-parents.service';



@Component({
  selector: 'concept-steps-default',
  templateUrl: './concept-steps-default.component.html'
})
export class ConceptStepsDefaultComponent implements OnChanges, OnInit {
  conceptSteps: any = {
    catchTemplate: true,
    initialStep: 0,
    filterContainers: []
  }
  constructor( private conceptStepsParent: ConceptStepsParentsService) {}

  @Input() concept: any = null;
  @Input() filterContainers: any = null;
  @Input() changeStepsConcept: any = null;

  ngOnInit(): void {
    let that: any = this.conceptSteps;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.conceptStepsParent.onChanges(changes, this)
  }

  selectStep(fieldContainer:any){
    this.conceptStepsParent.selectStep(fieldContainer, this)
  }
}
