import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class reportRowsResolve implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }

    resolve(route: ActivatedRouteSnapshot) {
      return this.apiService.get('reportobjects/data', route.params['reportObjectId'])
        .then((data:any)=>{
          return data
        })
    }
}
