import { Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { ProcessRecordParentService } from '../utils/process-record-parent.service';

@Component({
  selector: 'process-record-custom',
  templateUrl: './process-record-custom.component.html'
})
export class ProcessRecordCustomComponent implements OnChanges {
  custom: any = this.customLanding.getCustom();
  isLanding: boolean = true;
  processRecord: any = {
    tooltipFullScreen: this.translate.instant('common.deploy'),
    limitToList: 2,
    transitionsList: [],
    fullScreen: false
  }
  constructor(private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private processRecordParent: ProcessRecordParentService) {
    }

  @Input() conceptObjectId: any = null;
  @Input() fullScreenBox: any = null;
  @Input() public: boolean = false;
  @Output() catchFullScreen = new EventEmitter()


  ngOnChanges(): void {
    this.processRecordParent.changesOn(this);
  }
  maximizeScreen(keepClassBox = false){
    this.processRecordParent.maximizeScreen(keepClassBox, this)
  }
}
