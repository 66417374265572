import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { AdviceModalParentService } from '../utils/advice-modal-parent.service';


@Component({
  selector: 'advice-modal-custom',
  templateUrl: './advice-modal-custom.component.html'
})
export class AdviceModalCustomComponent implements OnInit {
  constructor(
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory,
    private adviceModalParent: AdviceModalParentService) {

  }

  @Input() from:string = '';
  @Input() adviceType:string = '';
  @Input() questionText:string = '';
  @Input() list:any = [];

  modalMoreInfoSubject:any;
  isLanding: boolean = true;
  adviceModal:any ={
    confirmQuestionIcon: 'lf-icon-question',
    errorTranslate: '',
    typeModal: '',
    modalQuestion: '',
    typeText: 'normal',
    firstQuestionText: '',
    buttonLink: '',
    lastQuestionText: '',
    list: [],
    custom:  this.customLanding.getCustom()
  }

  ngOnInit(): void {
    this.adviceModal.errorTranslate = this.translate.instant('users-admin.import.error-'+this.from)
    this.adviceModalParent.configureModal(this);
  }

  openInfoModal(){
    this.adviceModalParent.openInfoModal(this);
  }

  handleAccept(optionSelect:string='ok') {
    this.activeModal.close({
        result: optionSelect
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
