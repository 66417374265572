<div class="common__pdf-block-view"
    [id]="configuration?.idPrint">
    <pdf-header
        [datePdf]="configuration?.exportDate"></pdf-header>
    <div class="common__pdf-body">
        <div class="common__pdf-title-block common__pdf-title-block--space-between">
            <span>{{configuration?.title}}</span>
            <span class="common__pdf-total"><span [afTranslate]="'common.total'">_Total</span>: {{configuration?.list?.length}}</span>
        </div>
        <div class="common__pdf-subtitle-block"
            [ngShow]="configuration?.subtitle">
            <span>{{configuration?.subtitle}}</span>
        </div>
        <table class="common__pdf-table">
            <thead class="common__pdf-group-table-header">
                <tr class="common__pdf-table-header">
                    <th class="common__pdf-col"
                        [ngStyle]="{'width': + 100/configuration?.typeColumns?.length+'%'}"
                        *ngFor="let headerItem of configuration?.typeColumns">
                        <p class="common__pdf-table-header-text">{{configuration?.columns[headerItem]}}</p>
                    </th>
                </tr>
            </thead>
            <tbody class="common__pdf-table-body">
                <tr class="common__pdf-table-row"
                    *ngFor="let item of configuration?.list | afOrderBy:configuration?.orderType:configuration?.orderAsc">
                    <td class="common__pdf-col"
                        [ngStyle]="{'width': + 100/configuration?.typeColumns.length+'%'}"
                        *ngFor="let headerItem of configuration?.typeColumns">
                        <p class="common__pdf-col-text">{{item[headerItem]}}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
