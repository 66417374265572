<div class="certify-document">
    <div class="certify-document__block-context">
        <div class="common__modal-header-block">
            <i class="lf-icon-certified-document common__icon-header"></i>
            <span class="common__text-header"
                [afTranslate]="'expanded-menu.certify-document'">_Certificar documento</span>
            <i class="lf-icon-close common__icon-close-modal"
                (click)="handleDismiss()"></i>
        </div>
        <div class="certify-document__block-body">
            <p class="common__title-block-modal certify-document__text-info">
                Para garantizar la validez legal del documento, el Servicio de Certificación de Lefebvre registrará el archivo ante un tercero de confianza. Se añadirá un sello a tu documento y podrás descargar el correspondiente informe de auditoría. Este trámite se ejecuta directamente al pulsar el botón de Certificar. 
            </p>
            <div class="common__buttons-block common__buttons-block--modal">
                <button type="reset" 
                    class="common__button common__button--clean"
                    (click)="handleDismiss()"
                    [afTranslate]="'common.cancel'">_Cancelar</button>
                <button type="submit" 
                    class="common__button common__button--active common__button--separating-margin"
                    (click)="handleAccept()"
                    [afTranslate]="'common.certify'">_Certificar</button>
            </div>
        </div>
    </div>
</div>