<div class="select-simple"
    [ngClass]="{
      'select-simple--disabled': disabled,
      'select-simple--open': selectSimple.showList
    }"
    ngbDropdown
    ngbDropdownToggle
    [open]="selectSimple.showList">
    <div class="select-simple__select-simple-box">
        <input class="select-simple__input-select" readonly
            [ngClass]="{'select-simple__input-select--disabled': selectSimple.disabled}"
            [value]="selectSimple.selectOption">
    <button class=" select-simple__select-simple-icon"
        [ngClass]="selectSimple.showList ? 'lf-icon-angle-up' : 'lf-icon-angle-down'"
        [disabled]="disabled"></button>
    </div>
    <div class="common__menu-block-dropdown dropdown-menu" ngbDropdownMenu>
        <div class="common__block-option-list select-simple__block-option-list"
            [style]="{'width': widthUl + 'px'}">
            <div class="common__language-item"
              *ngFor="let value of listValues"
              (click)="selectValue(value)">
                <span-tooltip
                [placement]="'above'"
                [text]="value.text">
                </span-tooltip>
            </div>
        </div>
    </div>
</div>
