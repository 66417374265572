import { Injectable } from '@angular/core';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Injectable({
  providedIn: 'root'
})
export class ProcessRecordParentService {

  constructor(private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory) { }

  changesOn(child:any){
    let that: any = child.processRecord;

    if(child.public){
      that.public = child.public
      this.getHistory(child);
    }

    if(child.fullScreenBox){
    that.fullScreenBox = child.fullScreenBox
    if(child.custom && child.custom !== null && that.fullScreen && that.fullScreenBox !== '' && that.fullScreenBox !== 'process-record'){
        this.maximizeScreen(true, child);
    }
    }
}
getHistory(child:any) {
    this.apiService.getWithTranslate('processesdata', child.conceptObjectId + '/history')
        .then((data:any)=> {
            child.processRecord.transitionsList = data;
        }, (errorData:any)=>{});
}

maximizeScreen(keepClassBox:any, child:any){
    child.processRecord.fullScreen = !child.processRecord.fullScreen;
    child.processRecord.limitToList = 2;
    child.processRecord.tooltipFullScreen = this.translate.instant('common.deploy');
    if(child.processRecord.fullScreen){
        child.processRecord.limitToList = child.processRecord.transitionsList.length;
        child.processRecord.tooltipFullScreen = this.translate.instant('common.close');
    }
    if(!keepClassBox){
        child.catchFullScreen.emit({box:'process-record'});
    }
}
}
