import { Injectable } from '@angular/core';
import { RelatedVisibilityFieldsFactory } from '@shared/factories/related-visibility-fields/related-visibility-fields.factory';
import { CommonUtilsService } from '../common-utils/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  constructor(private relatedVisibilityFields: RelatedVisibilityFieldsFactory,
    private commonUtilsService: CommonUtilsService) { }

  extractVisibility(concept: any){
    let fieldWithVisibility: any = [];
    concept.fieldContainers.forEach((fieldContainer: any) => {
      this.checkVisibilities(fieldContainer, fieldWithVisibility);
      fieldContainer.fields.forEach((field: any) => {
        this.checkVisibilities(field, fieldWithVisibility);
      });
      fieldContainer.subContainers.forEach((subContainer:any) => {
        if(subContainer.multiple){
          this.checkVisibilities(subContainer, fieldWithVisibility, subContainer);
          subContainer.fields.forEach((subField: any) => {
            this.checkVisibilities(subField, fieldWithVisibility, subContainer);
          });
        }else{
          this.checkVisibilities(subContainer, fieldWithVisibility);
          subContainer.fields.forEach((subFields: any) => {
            this.checkVisibilities(subFields, fieldWithVisibility);
          });
        }
      });

    });
    return fieldWithVisibility;
  }

  checkVisibilities(item: any, fieldWithVisibility: any, subContainer: any = undefined){
    item.show = true;
    item.visibility = [];
    if(item.listVisibleValues && item.listVisibleValues.length){
        this.setListVisibilityValues(item, subContainer);
    }
    if(item.fieldVisibleValues && item.fieldVisibleValues.length){
        this.setFieldVisibilityValues(item, subContainer);
    }
    if(item.visibility.length){
        fieldWithVisibility.push(item);
        item.show = false;
    }
  }

  setListVisibilityValues(field: any, subcontainer: any){
    field.listVisibleValues.forEach((listVisibleValues: any) => {
      if(field.visibility.length-1 >= 0 && field.visibility[field.visibility.length-1].fieldId === listVisibleValues.listValue.fieldId && field.visibilityOr){
          field.visibility[ field.visibility.length-1].valueList.push({
              listValueId:listVisibleValues.listValue.listValueId
          })
      }else{
          let visibility: any={
              fieldId: listVisibleValues.listValue.fieldId,
              numSubContainer: this.searchingSubContainer(listVisibleValues.listValue.fieldId, subcontainer),
              valueList: [{listValueId: listVisibleValues.listValue.listValueId}]
          }
          if(listVisibleValues.fromAdditionalField){
              visibility.fromAdditionalField = listVisibleValues.fromAdditionalField;
              visibility.additionalFieldId = listVisibleValues.additionalFieldId;
              visibility.lcoFieldId = listVisibleValues.lcoFieldId;
          }
          field.visibility.push(visibility)
      }
    });

  }

  setFieldVisibilityValues(field: any, subcontainer: any){
    field.fieldVisibleValues.forEach((fieldVisibleValues: any) => {
      if(field.visibility.length-1 >= 0 && field.visibility[field.visibility.length-1].fieldId === fieldVisibleValues.checkFieldId && field.visibilityOr){
          field.visibility[ field.visibility.length-1].valueField.push({
              operator: this.extractOperator(fieldVisibleValues)
          })
      }else{
          field.visibility.push({
              fieldId: fieldVisibleValues.checkFieldId,
              numSubContainer: this.searchingSubContainer(fieldVisibleValues.checkFieldId, subcontainer),
              valueField: [{
                  operator: this.extractOperator(fieldVisibleValues)
              }]
          })
      }
    });
  }

  extractVisibilityMultiple(subContainerDuplicated: any){
    let fieldWithVisibility: any = [];
    subContainerDuplicated.forEach((subContainer: any) => {
      this.checkVisibilities(subContainer, fieldWithVisibility, subContainer);
      subContainer.fields.forEach((subField: any) => {
        this.checkVisibilities(subField, fieldWithVisibility, subContainer);
      });

    });

    return fieldWithVisibility;
  }

  extractVisibilityProperties(list: any){
    let propertiesWithVisibility: any = [];
    let properties: any = [];
    list.forEach((groupProperty: any)=> {
      groupProperty.forEach((property: any) => {
        properties.push(property);
      });
    });
    properties.forEach((property: any) => {
      if(property.propertyVisibleValues && property.propertyVisibleValues.length){
        property.propertyVisibleValues.forEach((propertyVisibleValues: any) => {
          properties.forEach((propertyParent: any) => {
            for(let i = 0; i < propertyParent.listValues.length; i++){
              if(propertyVisibleValues.propertyValueId === propertyParent.listValues[i].propertyValueId){
                  propertyVisibleValues.propertyParentValueId = propertyParent.propertyId;
                  break;
              }
            }
          });
        });
      }
      this.findProperties(property, propertiesWithVisibility, true);
    });
    return propertiesWithVisibility;
  }

  extractTemplatesVisibility(list: any){
    let fieldWithVisibility: any = [];
    list.forEach((item: any)=>{
      if(item.listVisibleValues === null){
        item.listVisibleValues = [];
      }
      this.checkVisibilities(item, fieldWithVisibility);
    })
    return fieldWithVisibility;
  }

  extractOperator(field: any){
    if(field.value1 === null || field.value1 === ''){
        if(field.operator2.indexOf('=') !== -1 && field.operator2.indexOf('>=') === -1 && field.operator2.indexOf('<=') === -1){
            field.operator2 += '='
        }
        return '#'+ field.operator2 + this.isDateValue(field.value2, field.checkFieldTypeId)
    }else if(field.value2 === null  || field.value2 === ''){
        if(field.operator1.indexOf('=') !== -1 && field.operator1.indexOf('>=') === -1 && field.operator1.indexOf('<=') === -1){
            field.operator1 += '='
        }
        return '#'+ field.operator1 + this.isDateValue(field.value1, field.checkFieldTypeId)
    }else{
        return '#'+field.operator1 + this.isDateValue(field.value1, field.checkFieldTypeId) + ' && ' +'#'+ field.operator2+ this.isDateValue(field.value2, field.checkFieldTypeId);
    }
  }

  searchingSubContainer(id: any, subContainer: any){
    let numSubContainer = 0;
    if(subContainer && subContainer.multiple){
        for(let i = 0; i < subContainer.fields.length; i++){
            let subField = subContainer.fields[i];
            if(subField.fieldId === id){
                numSubContainer = subContainer.numSubContainer?subContainer.numSubContainer:1;
                break;
            }else if(subField.fieldTypeId === "l-co" && this.findInAdditionalFields(subField.additionalRelatedFields, id)){
                numSubContainer = subContainer.numSubContainer?subContainer.numSubContainer:1;
                break;
            }
        }
    }
    return numSubContainer;
  }

  findInAdditionalFields(additionalRelatedFields: any, id: any){
    return !!additionalRelatedFields.filter((additionalField: any)=> additionalField.additionalFieldId = id).length;
  }

  isDateValue(value: any, checkFieldTypeId:any){
    if(checkFieldTypeId ==='date'){
      return new Date(value).valueOf()
    }else{
      return value;
    }

  }

  evaluatedFieldsMap(concept: any){
    let evaluatedFieldMap: any = new Map();
    concept.evaluatedFields.forEach((fieldEvaluated: any)=>{
      if(fieldEvaluated.fieldTypeId==='list' || fieldEvaluated.fieldTypeId==='listp'){
        fieldEvaluated.multiple = this.IsMultiple(concept, fieldEvaluated.fieldId);
      }
      let numSubContainer = fieldEvaluated.numSubContainer
      if(numSubContainer === null){
          numSubContainer = 0;
      }
      evaluatedFieldMap.set(fieldEvaluated.fieldId+'-'+numSubContainer, fieldEvaluated)
    })

    return evaluatedFieldMap;
  }

  evaluatedPropertiesMap(list: any){
    let evaluatedPropertiesMap: any = new Map();
    list.forEach((properties: any)=>{
      properties.forEach((property: any)=>{
        evaluatedPropertiesMap.set(property.propertyId, property)
      })
    })
    return evaluatedPropertiesMap;
  }

  IsMultiple(concept: any, fieldId: any){
    for(let i = 0; i < concept.fieldContainers.length; i++){
      let fieldContainers = concept.fieldContainers[i];
      for(let j = 0; j < fieldContainers.fields.length; j++){
          let fields = fieldContainers.fields[j];
          if(fields.fieldId === fieldId){
              return fields.multiple;
          }

      }
      for(let k = 0; k < fieldContainers.subContainers.length; k++){
          let subContainers = fieldContainers.subContainers[k];
          for(let l = 0; l < subContainers.fields.length; l++){
              let fields = subContainers.fields[l];
              if(fields.fieldId === fieldId){
                  return fields.multiple;
              }
          }
      }
    }
  }

  configureListVisbleValue(fieldWithVisibility: any, evaluatedFieldMap: any, concept: any){
    for(let i = 0; i < fieldWithVisibility.length; i++){
      let field = fieldWithVisibility[i];
      let visibilityAmount = -1;
      for(let j = 0; j < field.visibility.length; j++){
          let visibility = field.visibility[j];
          let mapValue = visibility.fromAdditionalField? this.relatedVisibilityFields.find(visibility) : evaluatedFieldMap.get(visibility.fieldId+'-'+visibility.numSubContainer);
          if(mapValue && this.getParentGroup(concept, mapValue)){
              if(mapValue.fieldTypeId ==='bool' || mapValue.fieldTypeId ==='boolp'){
                if(visibility.valueField && visibility.valueField[0].operator === "#<>\"\"" && mapValue.bitValue !== null){
                  visibilityAmount++;
                }else if(visibility.valueField && visibility.valueField[0].operator === "#==\"\"" && (mapValue.bitValue === null)){
                  visibilityAmount++;
                }
                let amountNumber: any;
                if(mapValue.bitValue === false){
                    amountNumber = 1;
                }else if(mapValue.bitValue === true){
                    amountNumber = 0;
                }
                if(visibility.valueList && mapValue.bitValue !== null && mapValue.listValues[amountNumber]?.listValueId === visibility.valueList[0]?.listValueId){
                    visibilityAmount++;
                }
              }else if(mapValue.fieldTypeId ==='list' || mapValue.fieldTypeId ==='listp'){
                  if(visibility.valueField && visibility.valueField[0].operator === "#<>\"\""){
                    if(mapValue.multiple && !(mapValue.nvarcharValue === null || mapValue.nvarcharValue === '')){
                        visibilityAmount++;
                    }else if(mapValue.floatvalue !== null){
                        visibilityAmount++;
                    }
                  }else if(visibility.valueField && visibility.valueField[0].operator === "#==\"\""){
                      if(mapValue.multiple && (mapValue.nvarcharValue === null || mapValue.nvarcharValue === '')){
                          visibilityAmount++;
                      }else if(mapValue.floatvalue === null){
                          visibilityAmount++;
                      }
                  }
                  if(!mapValue.multiple){
                      if(this.checkFieldList(visibility.valueList, mapValue.floatvalue)){
                          visibilityAmount++;
                      };
                  }else{
                    if(mapValue.nvarcharValue){
                      let mapValues: any = mapValue.nvarcharValue.indexOf('|')!==-1? mapValue.nvarcharValue.split('|') : [mapValue.nvarcharValue]
                      for(let k = 0; k < mapValues.length;k++){
                        if(this.checkFieldList(visibility.valueList, parseInt(mapValues[k]))){
                          visibilityAmount++;
                          break;
                        };
                      }
                    }else{
                      field.show = false;
                      break;
                    }
                  }
              }

              if(mapValue.fieldTypeId ==='date'){
                  if(mapValue.datetimeValue !== null){
                      for(let k = 0; k < visibility.valueField.length; k++){
                          if(eval(visibility.valueField[k].operator.split('#').join(new Date(mapValue.datetimeValue).valueOf()))){
                              visibilityAmount++;
                              break;
                          }
                      }
                  }
              }else if(mapValue.fieldTypeId ==='text'){
                  let mapValueVarchar = mapValue.nvarcharValue === null || mapValue.nvarcharValue === ''? '""' : "'"+mapValue.nvarcharValue+"'" ;
                  for(let k = 0; k < visibility.valueField.length; k++){
                      let operator = visibility.valueField[k].operator.split('#').join(mapValueVarchar);
                      if(operator.indexOf('<>') !== -1){
                         operator = operator.replace('<>', '!==');
                      }
                      if(eval(operator)){
                          visibilityAmount++;
                          break;
                      }
                  }
              }else if (mapValue.fieldTypeId ==='l-co'){
                  if(this.checkFieldList(visibility.valueList, mapValue.additionalField.listValueId)){
                      visibilityAmount++;
                  };
                  break;
              }else if(!(mapValue.fieldTypeId ==='list' || mapValue.fieldTypeId ==='listp' || mapValue.fieldTypeId ==='bool' || mapValue.fieldTypeId ==='boolp')){
                  if(mapValue.floatvalue !== null && mapValue.floatvalue!==''){
                      for(let k = 0; k < visibility.valueField.length; k++){
                          if(eval(visibility.valueField[k].operator.split('#').join(mapValue.floatvalue))){
                              visibilityAmount++;
                              break;
                          }
                      }
                  }
              }
          }
      }

      if(visibilityAmount === field.visibility.length - 1){
          field.show = true;
      }else if(visibilityAmount >= 0 && field.visibilityOr){
          field.show = true;
      }else{
          let modifyField = field.show;
          field.show = false;
          if(!field.fieldTypeId && field.fieldContainerId){
              this.resetContainer(field, concept);
              concept.modifyFormu = modifyField;
          }else if(field.evaluatedValue && !field.itsFormu){
              this.commonUtilsService.cleanFieldEvaluatedValue(field, concept);
          }
      }
    }
  }

  resetContainer(container: any, concept: any){
    container.fields.forEach((field: any)=>{
        if(field.evaluatedValue && !field.itsFormu){
            this.commonUtilsService.cleanFieldEvaluatedValue(field, concept);
        }

    })
    container.subContainers.forEach((subContainer: any)=>{
        this.resetContainer(subContainer, concept);
    })
  }

  getParentGroup(concept: any, fieldEvaluated: any): any{
    if(fieldEvaluated){
        for(let i = 0; i < concept.fieldContainers.length; i++){
            let container = concept.fieldContainers[i];
              for(let j = 0; j < container.fields.length; j++){
                  let field = container.fields[j]
                if(field.fieldId === fieldEvaluated.fieldId){
                    fieldEvaluated.fieldContainerShow = container.show;
                    return fieldEvaluated.fieldContainerShow;
                }
              }
              for(let k = 0; k < container.subContainers.length; k++){
                  let subContainer = container.subContainers[k];
                  for(let l = 0; l < subContainer.fields.length; l++){
                    let subField = subContainer.fields[l];
                    if(subField.fieldId === fieldEvaluated.fieldId){
                        fieldEvaluated.fieldContainerShow = container.show;
                        fieldEvaluated.subContainerShow = subContainer.show;
                        return (fieldEvaluated.fieldContainerShow && fieldEvaluated.subContainerShow);
                    }
                  }
              }
        }
    }else{
        return false;
    }
  }

  checkFieldList(valueList: any, floatValue: any){
    return Boolean(valueList.filter((item: any)=>{
      return item.listValueId === floatValue
    }).length);
  }

  extractPropertiesVisibility(concept: any){
    let fieldWithProperties: any = [];
    concept.fieldContainers.forEach((fieldContainer: any) => {
      this.findProperties(fieldContainer, fieldWithProperties);
      fieldContainer.fields.forEach((field: any) => {
        this.findProperties(field, fieldWithProperties);
      });
      fieldContainer.subContainers.forEach((subContainer:any) => {
        this.findProperties(subContainer, fieldWithProperties);
        subContainer.fields.forEach((subFields: any) => {
          this.findProperties(subFields, fieldWithProperties);
        });
      });

    });
    return fieldWithProperties;
  }

  findProperties(item: any, fieldWithProperties: any, product: any = ''){
    if(item.propertyVisibleValues.length){
        if(product){
            item.showWithProperty = false;
        }
        fieldWithProperties.push(this.setPropertiesVisibilityValues(item, product));
    }else{
        item.showWithProperty = true;
    }
  }

  setPropertiesVisibilityValues(field: any, product: any){
      field.visibilityProperties = [];
      let propertyVisibleValues = field.propertyVisibleValues;
      propertyVisibleValues.forEach((propertyValue:any, index: any) => {
          if(product){
              if(index > 0 && field.visibilityProperties[field.visibilityProperties.length-1].propertyValueId === propertyValue.propertyValueId){
                  field.visibilityProperties[0].valueList.push({propertyValueId: propertyValue.propertyValueId})
              }else{
                  field.visibilityProperties.push({
                      propertyParentValueId: propertyValue.propertyParentValueId,
                      propertyId: propertyValue.propertyId,
                      // propertyValueId: propertyValue.propertyId,
                      valueList: [{propertyValueId: propertyValue.propertyValueId}]
                  })
              }
          }else{
              field.visibilityProperties.push({propertyId:propertyValue.propertyValueId})
          }
      })
      return field;
  }

  configureEvaluationProperties(fieldWithProperties: any, evaluationProperties: any){
    fieldWithProperties.forEach((field:any)=>{
      let visibilityProperties = field.visibilityProperties;
      let propertiesAccomplish = 0;
      visibilityProperties.forEach((visibilityProperty:any) => {
        for(let i = 0; i < evaluationProperties.length; i++){
          if(evaluationProperties[i].propertyValueId === visibilityProperty.propertyId){
            propertiesAccomplish++;
            break;
          }
        }
      })
      field.showWithProperty = (propertiesAccomplish === visibilityProperties.length)
    })
  }

  configureProperties(propertiesWithVisibility: any, evaluatedMap: any){
    propertiesWithVisibility.forEach((property:any) => {
        let visibilityAmount = -1;
        property.visibilityProperties.forEach((visibility:any) => {
            if(visibility.propertyParentValueId){
                let mapValue = evaluatedMap.get(visibility.propertyParentValueId);
                if(mapValue.isBoolean){
                    let amountNumber: any;
                    if(mapValue.preSelect === false){
                        amountNumber = 1;
                    }else if(mapValue.preSelect === true){
                        amountNumber = 0;
                    }
                    if(mapValue.preSelect !== undefined && mapValue.preSelect !== null && mapValue.listValues[amountNumber] && mapValue.listValues[amountNumber].listValueId === visibility.valueList[0].propertyValueId){
                        visibilityAmount++;
                    }
                }else{
                    if(mapValue.preSelect){
                      let mapValues = !Number.isInteger(mapValue.preSelect) && mapValue.preSelect.indexOf('|')!==-1? mapValue.preSelect.split('|') : [mapValue.preSelect];
                      mapValues.forEach((value:any)=>{
                        if(this.checkPropertyList(visibility.valueList, parseInt(value))){
                          visibilityAmount++;
                        };
                      })
                    }else{
                        property.showWithProperty = false;
                    }
                }
            }
        })
        if(visibilityAmount === property.visibilityProperties.length-1){
            property.showWithProperty = true;
        }else if(visibilityAmount >= 0 && property.visibilityOr){
            property.showWithProperty = true;
        }else{
            property.showWithProperty = false;
        }
    })

  }
  checkPropertyList(valueList: any, floatValue: any){
    return !!valueList.filter((item: any)=>item.propertyValueId === floatValue).length;
  }
}
