<div class="draft-evaluations">
  <div class="draft-evaluations__modal-body">
          <div class="advice-modal__modal-icon-block">
              <i class="advice-modal__modal-body-icon lf-icon-asterisk"></i>
          </div>
          <div class="advice-modal__modal-text-block">
              <p class="advice-modal__modal-body-text"
                  [afTranslate]="'draft-evaluations.form-saved-as-draft'">
                  Los siguientes formularios están guardados en modo borrador porque tienen campos obligatorios sin cumplimentar.</p>
          </div>
          <button class="new-evaluation__close-modal new-evaluation__close-modal--draft"
              [ngHide]="toOther"
              (click)="handleDismiss()">
              <i class="lf-icon-close draft-evaluations__icon-close-modal"></i>
          </button>
  </div>
  <div class="draft-evaluations__draft-box">
      <p class="draft-evaluations__draft-fields"
          [afTranslate]="'draft-evaluations.review-required-fields'">
          Revisa los siguientes formularios para validar los campos obligatorios pendientes:
      </p>
      <ul class="draft-evaluations__draft-concept-block">
          <li class="draft-evaluations__draft-concept-item"
              *ngFor="let draft of draftEvaluations.draftList"
              (click)="launchDraft(draft)">
              <div class="draft-evaluations__draft-concept-square"></div>
              {{draft.title}}
          </li>
      </ul>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button  type="submit"
          class="common__button common__button--active"
          (click)="handleDismiss()"
          [afTranslate]="'common.ok'">
          Aceptar
      </button>
  </div>
</div>
