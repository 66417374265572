<div class="templates-concept common__block-concept common__block-concept--border">
    <div class="templates-concept__block-header">
        <button type="submit"
            class="common__button common__button--active"
            (click)="newDocument()"
            [afTranslate]="'templates-concept.create-document'">_Crear nueva plantilla</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="upDocument()"
            [afTranslate]="'templates-concept.upload-template'">_Cargar plantilla desde archivo</button>
    </div>
    <div class="common__table-header">
        <div class="templates-concept__table templates-concept__table--format">
            <p class="common__table-header-text common__table-header-text--format-text text-left"
                [afTranslate]="'common.format'">_Formato</p>
        </div>
        <div class="templates-concept__table templates-concept__table--title">
            <p class="common__table-header-text"
                [afTranslate]="'common.title'">_Título</p>
        </div>
        <div class="templates-concept__table">
            <p class="common__table-header-text"
                [afTranslate]="'common.origin'">_Origen</p>
        </div>
        <div class="templates-concept__table">
            <p class="common__table-header-text"
                [afTranslate]="'common.creation'">_Creación</p>
        </div>
        <div class="templates-concept__table">
            <p class="common__table-header-text"
                [afTranslate]="'common.author'">_Autor</p>
        </div>
        <div class="templates-concept__table">
            <p class="common__table-header-text"
                [afTranslate]="'common.modification'">_Modificación</p>
        </div>
        <div class="templates-concept__table templates-concept__table--user-modification">
            <p class="common__table-header-text"
                [afTranslate]="'documentation-list.user-modification'">_Usuario Modificación</p>
        </div>
        <div class="templates-concept__table templates-concept__table--firm"></div>
        <div class="templates-concept__table templates-concept__table--action">
            <p class="common__table-header-text common__table-header-text--right"
                [afTranslate]="'common.actions'">_Acciones</p>
        </div>
    </div>
    <div class="templates-concept__block-list-template">
        <div *ngFor="let document of templateConcept.listTemplates; let index = index"
                class="common__table-row">
            <div class="templates-concept__table templates-concept__table--format-icon">
                <i [class]="'templates-concept__table-icon '+(document.extension | extensionDocument)"></i>
            </div>
            <div class="templates-concept__table templates-concept__table--title">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.source"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.created | convertDate:'without-time'"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.createdByIsDeleted?document.createdBy +' ('+ ('common.inactive-user' | afTranslate)+')':document.createdBy"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.modified | convertDate:'without-time'"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table templates-concept__table--user-modification">
                <div class="common__cell-block">
                    <parragraph-tooltip [text]="document.modifiedBy"></parragraph-tooltip>
                </div>
            </div>
            <div class="templates-concept__table templates-concept__table--firm"></div>
            <div class="templates-concept__table templates-concept__table--action"
                    ngbDropdown>
                <button class="lf-icon-kebab-menu common__icon-ellipsis"
                        [ngShow]="document.type === 'Front'"
                        ngbDropdownToggle></button>
                <ul class="templates-concept__list-menu-block"
                    ngbDropdownMenu
                    role="menu">
                    <li class="common__list-dropdown-option"
                        *ngFor="let optionMenu of document.menu; let last = last">
                        <button class="common__button-option-list-dropdown"
                            [ngClass]="{'common__button-option-list-dropdown--last': last}"
                            (click)="launchAction(optionMenu.action, document)">
                            <i [class]="'common__icon-option-list-dropdown '+optionMenu.icon"></i>
                            <span class="common__text-menu-option-dropdown"
                                [afTranslate]="optionMenu.name"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
