import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'users-admin-main',
  templateUrl: './users-admin-main.component.html'
})
export class UsersAdminMainComponent implements OnInit {
  filters: any = ['type', 'companyName', 'roles'];
  usersAdminMain: any = {
    searchGroupPersons: {
      members: ['fullName', 'position', 'companyName']
    },
    selectionEnabled: false,
    typeOptionFilter: undefined,
    clientSearch: '',
    elementValue: '',
    searchPerson: '',
    search: false,
    selectionCount: 0,
    countGroupsOpen: 0,
    configuredTotal:{
      first: 1,
      last: 50
    },
    currentPage: 1,
    // pageSize: 10,
    configPagination: {
      pageSize: 50
    },
    selectedClient: {},
    companyNameFilter: {
        name: this.translate.instant('common.company')
    },
    typeFilter: {
        name: this.translate.instant('common.type')
    },
    rolesFilter: {
        name: this.translate.instant('common.assigned-roles')
    },
    fullNameFilter: {
      name: this.translate.instant('common.name'),
      width: '200px',
      top: '28'
    },
    emailFilter: {
      name: this.translate.instant('users-admin.main.columns.email'),
      width: '200px',
      top: '28'
    },
    persons: [],
    users: [],
    contacts: [],
    groups: [],
    clients: [],
    // contactClients: [],
    allRoles: [],
    filterPersonsList: [],
    errorContacts: []
  }
  constructor(private router: Router,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private downloadService: DownloadService,
    private filterColumnService: FilterColumnService,
    private modalService: ModalServiceService) { }

  ngOnInit(): void {
    this.loader.openLoader('main-component');
    this.getUsers();
    this.getClients();
  }

  getUsers(){
    this.apiService.get('frontusers')
      .then((data:any)=>{
        data.forEach((user:any) => {
          user.type = 'user';
          user.id = user.userId;
          this.usersAdminMain.persons.push(user);
        });
        this.usersAdminMain.users = data;
        this.getContacts();
      }, (error:any)=>{})
  }

  getContacts(){
    this.apiService.get('contacts/access/admin')
      .then((data:any)=>{
        data.forEach((contact:any) => {
          contact.type = 'contact';
          contact.id = contact.contactId;
          this.usersAdminMain.persons.push(contact);
        });
        this.usersAdminMain.contacts = data;
        this.getGroups();
      }, (error:any)=>{})
  }

  getGroups(){
    this.apiService.get('usersgroups', 'withmembers')
      .then((data:any)=>{
        data.forEach((group:any) => {
          group.type = 'group';
          group.id = group.groupId;
          group.members = [];
          group.fullName = group.name;
          group.users.forEach((id:any) => {
            this.getPersonGroup('user', id, group);
          });
          group.contacts.forEach((id:any) => {
            this.getPersonGroup('contact', id, group);
          });
          delete group.users;
          delete group.contacts;
          this.usersAdminMain.persons.push(group);
          this.usersAdminMain.groups.push(group);
        });
        this.usersAdminMain.filterPersonsList = cloneDeep(this.usersAdminMain.persons);
        this.getListRoles();
      });
  }

  getPersonGroup(type: string, id:string|number, group:any){
    let list = this.usersAdminMain[type + 's'];
    let idType = type + 'Id';
    for (let i = 0; i < list.length; i++) {
      let person = list[i];
      if(person[idType] === id){
        person.type = type;
        group.members.push(person);
        break;
      }
    }
  }

  getClients(){
    this.apiService.get('clients/all')
      .then((data:any)=>{
        this.usersAdminMain.clients = data;
      }, (error:any)=>{})
  }

  getContactsClient(clientId: any=''){
    this.apiService.get('contacts/access/admin/client/' + clientId)
      .then((data:any)=>{
        data.forEach((contact:any) => {
          contact.type = 'contact';
        });
        this.usersAdminMain.filterPersonsList = data;
      }, (error:any)=>{})
  }

  getListRoles(){
    this.usersAdminMain.filterPersonsList.forEach((users:any) => {
      if(users.roles && users.roles.length){
          this.usersAdminMain.allRoles = this.usersAdminMain.allRoles.concat(users.roles);
      }
    });
    this.getFilters();
  }

  getFilters(){
    this.filters.forEach((filter:any) => {
      let filterType : any = filter;
      let list : any = this.usersAdminMain.persons;
      if(filter === 'roles'){
          list = this.usersAdminMain.allRoles;
          filterType = 'name';
      }
      this.usersAdminMain[filter+'List'] = this.filterColumnService.extractList(filterType, list);
      if(filter === 'type'){
        this.usersAdminMain.typeList.forEach((filterType:any) => {
          if(filterType.name === 'user' || filterType.name === 'contact' || filterType.name === 'group'){
              filterType.nameTranslate = this.translate.instant('users-admin.main.'+filterType.name);
          }
        })
      }
      this.usersAdminMain[filter+'ListFilter'] = [];
    });
    this.closeLoader();
  }

  closeLoader(){
    this.loader.closeLoader(['main-component', 'front-login-login']);
  }

  filterByType(type:string) {
    if (this.usersAdminMain.typeOptionFilter !== type) {
        this.usersAdminMain.typeOptionFilter = type;
        this.usersAdminMain.filterPersonsList = cloneDeep(this.usersAdminMain[type + 's']);
    } else {
        this.usersAdminMain.typeOptionFilter = undefined;
        this.usersAdminMain.filterPersonsList = this.usersAdminMain.persons;
    }
    if(this.usersAdminMain.typeOptionFilter !== 'contact'){
        this.usersAdminMain.selectionEnabled = false;
        this.usersAdminMain.filterPersonsList.forEach((person:any) => {
          person.selected = false;
        });
    }
  }

  selectAll(select: any=undefined, newValue: any=undefined) {
      if (select === undefined) {
          select = this.usersAdminMain.selectionCount < this.usersAdminMain.filterPersonsList.length;
      } else {
          this.usersAdminMain.selectionEnabled = newValue;
      }
      this.usersAdminMain.filterPersonsList.forEach((person:any) => {
        person.selected = select;
      })
      this.usersAdminMain.selectionCount = select ? this.usersAdminMain.filterPersonsList.length : 0;
  }

  resetFilterClient(e: any){
      e.stopPropagation();
      e.preventDefault();
      this.usersAdminMain.selectedClient = {};
      this.usersAdminMain.filterPersonsList = cloneDeep(this.usersAdminMain.contacts);
  }

  resetSearch(e: any){
      e.stopPropagation();
      e.preventDefault();
      if(this.usersAdminMain.clientSearch !== ''){
          this.usersAdminMain.clientSearch='';
      }
  }

  orderBySearch(e:any, type:any){
    this.usersAdminMain[type+'WordFilter'] = e.newValue;
    let filterList: any = [];
    filterList = this.filterColumnService.filterByWord(this.usersAdminMain[type+'WordFilter'],  this.usersAdminMain.persons, type);
    this.filterContacts(filterList);
  }

  filterByClient(e:any, client: any) {
      e.stopPropagation();
      e.preventDefault();
      this.usersAdminMain.selectedClient = this.usersAdminMain.selectedClient && this.usersAdminMain.selectedClient.clientId === client.clientId?null:client;
      this.getContactsClient(client.clientId);
  }

  selectToExport(contact: any) {
      contact.selected = !contact.selected;
      if (contact.selected) {
          this.usersAdminMain.selectionCount++;
      } else {
          this.usersAdminMain.selectionCount--;
      }
  }

  selectTypeOperation(item: any, type: any){
      let listFilter : any = this.usersAdminMain[type+'ListFilter'];
      this.usersAdminMain[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      this.usersAdminMain[type+'Filter'].hasFilter = this.usersAdminMain[type+'ListFilter'].length;
      this.filterContacts();
  }

  filterContacts(list: any=undefined){
      let filterList = list?list:cloneDeep(this.usersAdminMain.persons);
      this.filters.forEach((filter:any) => {
        let filteredList : any = this.usersAdminMain[filter+'ListFilter'];
        if(filter === 'roles'){
            filterList = this.filterColumnService.filterBycolumnAllChildren(filterList, filteredList, 'name', 'roles');
        }else{
            filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
        }
      })
      this.usersAdminMain.filterPersonsList = filterList;
  }

  exportContacts(contacts: boolean){
    this.loader.openLoader('main-export');
    let ids: string = '';
    if (contacts) {
        if (this.usersAdminMain.filterPersonsList.length) {
          this.usersAdminMain.filterPersonsList.forEach((person:any) => {
            if ((!this.usersAdminMain.selectionEnabled || person.selected)) {
                ids += ids === ''?person.contactId:',' + person.contactId;
            }
          })
        }
    } else {
        ids = 'template';
    }

    let idsQuery = '?ids=' + ids;
    this.apiService.getBlob('contacts/export-csv' + idsQuery)
      .then((data:any)=>{
          this.downloadService.triggerDownload(data.blob, data.fileName);
          this.loader.closeLoader(['main-export']);
          let messageTranslate : any = contacts?this.translate.instant('users-admin.main.export-success'):this.translate.instant('users-admin.main.export-template-success');
          this.modalService.adviceModal(messageTranslate, 'info:accept:check-round');
      })
      .catch((errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
      });
  }

  importContacts(){
    this.modalService.importModal('contacts').subscribe((result:any) => {
      if(result.result === 'ok'){
        this.uploadContacts(result);
      }
    });
  }

  uploadContacts(result: any){
    this.loader.openLoader('users-admin-import-upload-contacts');
    this.usersAdminMain.allOk = 0;
    this.usersAdminMain.validateContacts = result.validateFields;
    this.usersAdminMain.errorContacts = result.errorFields;
    this.usersAdminMain.validateContacts.forEach((contact:any, index: any) => {
        this.saveContact(contact, (this.usersAdminMain.validateContacts.length - 1 === index));
    })
  }

  saveContact(contact: any, last: any){
    this.apiService.add('contacts', contact)
      .then((data:any)=>{
          this.usersAdminMain.allOk++;
          this.usersAdminMain.persons.push(data);
          this.usersAdminMain.filterPersonsList.push(data);
          if(last){
              this.loader.closeLoader(['users-admin-import-upload-contacts']);
              if(this.usersAdminMain.allOk === this.usersAdminMain.validateContacts.length && !this.usersAdminMain.errorContacts.length){
                  this.modalService.adviceModal(this.translate.instant('users-admin.import.import-contacts-success'), 'info:accept:check-round');
              }else if(this.usersAdminMain.allOk === this.usersAdminMain.validateContacts.length && this.usersAdminMain.errorContacts.length){
                  this.modalService.adviceModal(this.translate.instant('users-admin.import.import-success')+' '+this.usersAdminMain.allOk+' '+this.translate.instant('users-admin.import.discard-contacts'), 'info:accept:warning', '', 'contacts', this.usersAdminMain.errorContacts);
              }
          }
      })
      .catch((errorData:any)=>{
        contact.errorList = [{
            errorType: this.translate.instant('common.error'),
            errorDescription: this.translate.instant('users-admin.import.few-columns')
        }]
        this.usersAdminMain.errorContacts.push(contact);
        if(last){
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('users-admin.import.import-error'), 'error:accept:warning');
        }
      });
  }

  deletePerson(person:any){
    this.modalService.adviceModal(this.translate.instant('users-admin.confirm-delete.' + person.type), 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.doDelete(person);
          }
      })
  }

  doDelete(person: any){
    this.loader.openLoader('main-delete');
    this.deleteByType(person)
      .then((data:any)=>{
          this.loader.closeLoader(['main-delete']);
          this.deletePersonForList(person, this.usersAdminMain[person.type+'s'], person.type+'Id');
          this.deletePersonForList(person, this.usersAdminMain.persons, person.type+'Id');
          this.deletePersonForList(person, this.usersAdminMain.filterPersonsList, person.type+'Id');
          this.usersAdminMain.filterPersonsList = cloneDeep(this.usersAdminMain.persons);
          this.getFilters();
          this.modalService.adviceModal(this.translate.instant('users-admin.delete-success.' + person.type), 'info:accept:check-round');
      }, (errorData:any)=>{
        if (errorData.status && errorData.status === 409 && errorData.description === 'GroupNotEmpty'){
          this.modalService.adviceModal(this.translate.instant('users-admin.delete-group-error'), 'error:accept:warning');
        }else if (errorData.status && errorData.status === 409 && Array.isArray(errorData.description)){
            this.openRoleErrorModal(errorData.description, 'errorDeleteUser');
        }else if (errorData.status && errorData.status === 409 && errorData.description !== 'Admins users cannot be deleted.') {
            this.modalService.adviceModal(this.translate.instant('users-admin.delete-licenses-error'), 'error:accept:warning');
        } else {
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
        }
        this.loader.closeError();
      })
  }

  deleteByType(person: any) {
    switch (person.type) {
        case 'user':
            return this.apiService.delete('frontusers', person.userId);
        case 'contact':
            return this.apiService.delete('contacts', person.contactId);
        case 'group':
            return this.apiService.delete('usersgroups',person.groupId);
        default:
            throw 'Unknow type ' + person.type;
    }
  }

  deletePersonForList(person: any, list: any, typeId: any){
    for(let i = list.length -1; i >= 0; i--){
      if(list[i][typeId] === person[typeId]){
          list.splice(i, 1);
      }else if(list[i].type === 'group'){
          this.deletePersonForList(person, list[i].members, typeId);
      }
    }
  }

  openRoleErrorModal(list: any, type: any){
      let configuration : any = {
          list: list,
          type: type
      }
      this.modalService.roleErrorModal(configuration);
  }

  expandedPerson(person: any){
    person.expanded = !person.expanded;
    if(person.expanded){
      this.usersAdminMain.countGroupsOpen += person.members.length;
    }else{
      this.usersAdminMain.countGroupsOpen -= person.members.length;
    }
  }

  toggleSearch(){
      this.usersAdminMain.search = !this.usersAdminMain.search;
      if (!this.usersAdminMain.search) {
          this.usersAdminMain.searchPerson = '';
      }
      // this.filterContacts();
  }

  stopPropagation(e: any){
    e.stopPropagation();
  }
}
