<div class="global-customization common__panel">
    <header-nav></header-nav>
    <div class="common__block-button-close-window">
        <button (click)="changeViewWindow()"
            class="common__back-link">
            <span [afTranslate]="'global-customization.close-customization'">_CERRAR PERSONALIZACIÓN</span>
            <i class="lf-icon-close common__back-link-icon"></i>
        </button>
    </div>
    <div class="global-customization__block-container">
        <div class="common__block-list-new-version global-customization__body">
            <div class="common__header-new-version">
                <i class="lf-icon-colour common__header-icon-new-version"
                    [ngShow]="skin !== 'uk-steel-blue'"></i>
                <span class="common__header-title-new-version"
                    [afTranslate]="'menu-header.global-customization'">_Personalización global</span>
            </div>
            <form class="global-customization__form-body">
                <field-type-rich-text
                    [field]="globalCustomization.identityInfo">
                </field-type-rich-text>
                <div class="global-customization__block-step">
                    <div class="global-customization__block-title-step">
                        <i class="lf-icon-number-one global-customization__icon-title-step"></i>
                        <span class="global-customization__title-step"
                            [afTranslate]="'global-customization.graphic-appearance'">_Marca y aspecto gráfico</span>
                    </div>
                    <field-type-logo
                        [field]="globalCustomization.clientLogo"
                        [type]="'new'"
                        [preSelectItem]="globalCustomization.company.allLogos"
                        [logoInfo]="globalCustomization.company"
                        (itemSelected)="itemSelected($event, 'logo')">
                    </field-type-logo>
                    <field-type-logo
                        [field]="globalCustomization.clientFavicon"
                        [type]="'favicon'"
                        [preSelectItem]="globalCustomization.company.favIcon"
                        [logoInfo]="globalCustomization.company"
                        (itemSelected)="itemSelected($event, 'favIcon')">
                    </field-type-logo>
                    <div class="global-customization__block-line-pickers-color">
                        <field-type-color class="global-customization__block-picker-color"
                            [field]="globalCustomization.mainColor"
                            [preSelectItem]="globalCustomization.company.mainColor"
                            (itemSelectedEmit)="itemSelected($event, 'mainColor')">
                        </field-type-color>
                        <field-type-color class="global-customization__block-picker-color"
                            [field]="globalCustomization.textColor"
                            [preSelectItem]="globalCustomization.company.textColor"
                            (itemSelectedEmit)="itemSelected($event, 'textColor')">
                        </field-type-color>
                    </div>

                    <field-type-list-extended
                        [field]="globalCustomization.designFooter"
                        [preSelectItem]="globalCustomization.company.footerDesignType"
                        [listValues]="globalCustomization.designFooterList"
                        (itemSelected)="itemSelected($event, 'footerDesignType')">
                    </field-type-list-extended>
                </div>
                <div class="global-customization__block-step">
                    <div class="global-customization__block-title-step">
                        <i class="lf-icon-number-two global-customization__icon-title-step"></i>
                        <span class="global-customization__title-step"
                            [afTranslate]="'global-customization.information-page'">_Información y redes sociales a pie de página</span>
                    </div>
                    <rich-editor class="global-customization__block-tiny"
                      [tinyMceOptions]="globalCustomization.tinyMceOptions"
                      [modelText]="globalCustomization.description"
                      (emitValue)="globalCustomization.description = $event.model">
                    </rich-editor>
                    <field-type-text-simple [field]="globalCustomization.youtubeLink"
                        [preSelectItem]="globalCustomization.company.youtube"
                        (itemSelected)="itemSelected($event, 'youtube')">
                    </field-type-text-simple>
                    <field-type-text-simple [field]="globalCustomization.twitterLink"
                        [preSelectItem]="globalCustomization.company.twitter"
                        (itemSelected)="itemSelected($event, 'twitter')">
                    </field-type-text-simple>
                    <field-type-text-simple [field]="globalCustomization.facebookLink"
                        [preSelectItem]="globalCustomization.company.facebook"
                        (itemSelected)="itemSelected($event, 'facebook')">
                    </field-type-text-simple>
                    <field-type-text-simple [field]="globalCustomization.linkedinLink"
                        [preSelectItem]="globalCustomization.company.linkedin"
                        (itemSelected)="itemSelected($event, 'linkedin')">
                    </field-type-text-simple>
                    <field-type-text-simple [field]="globalCustomization.phoneReference"
                        [preSelectItem]="globalCustomization.company.phone"
                        (itemSelected)="itemSelected($event, 'phone')">
                    </field-type-text-simple>
                    <field-type-switch [label]="'global-customization.show-logo-footer'| afTranslate"
                                       [checked]="globalCustomization.company.showLogoInFooter"
                                       (callback)="globalCustomization.company.showLogoInFooter = $event.value">
                    </field-type-switch>
                </div>
                <div class="global-customization__block-step">
                    <div class="global-customization__block-title-step">
                        <i class="lf-icon-number-three global-customization__icon-title-step"></i>
                        <span class="global-customization__title-step"
                            [afTranslate]="'global-customization.personalized-email'">_Correo personalizado para el envío de notificaciones</span>
                    </div>
                    <p class="global-customization__text-info-mail"
                        [afTranslate]="'global-customization.info-personalized-mail'">_Si quieres que todas las notificaciones generadas desde Centinela se remitan desde una cuenta de correo diferente a la del producto, activa la configuración personalizada y completa los campos solicitados.</p>
                    <field-type-switch class="global-customization__switch-email"
                                       [label]="'global-customization.activate-personalized-mail'| afTranslate"
                                       [checked]="globalCustomization.company.customEmail"
                                       (callback)="globalCustomization.company.customEmail = $event.value">
                    </field-type-switch>
                    <field-type-list-extended [ngShow]="globalCustomization.company.customEmail && skin==='dark-blue'"
                        [field]="globalCustomization.customEmailType"
                        [listValues]="globalCustomization.customEmailTypeOptions"
                        [preSelectItem]="globalCustomization.customTypeSelect"
                        (itemSelected)="selectcustomType($event.newValue)">
                    </field-type-list-extended>
                    <div class="global-customization__block-custom-mail"
                        [ngShow]="globalCustomization.company.customEmail && globalCustomization.customTypeSelect==='0'">
                        <div class="corporative-information-modal__block-line-data">
                            <div class="corporative-information-modal__block-fields-left">
                                <field-type-text-simple [field]="globalCustomization.sMTPServerField"
                                    [preSelectItem]="globalCustomization.company.smtpServer"
                                    (itemSelected)="itemSelected($event, 'smtpServer')">
                                </field-type-text-simple>
                                <span class="corporative-information-modal__error-name"
                                    [ngShow]="globalCustomization.errorServer"
                                    [afTranslate]="'corporative-information-modal.char-50-error'">
                                        _El servidor de salida no puede exceder de 50 caracteres</span>
                            </div>
                            <div class="corporative-information-modal__block-fields-right">
                                  <field-type-number [field]="globalCustomization.sMTPPortField"
                                    [preSelectItem]="globalCustomization.company.smtpPort"
                                    (itemSelected)="itemSelected($event, 'smtpPort')">
                                </field-type-number>
                                <span class="corporative-information-modal__error-name"
                                    [ngShow]="globalCustomization.errorPort"
                                    [afTranslate]="'corporative-information-modal.char-port-error'">
                                        _El puerto no puede exceder del valor 32767</span>
                            </div>
                        </div>
                        <div class="corporative-information-modal__block-line-data">
                            <div class="corporative-information-modal__block-fields-left">
                                <field-type-text-simple [field]="globalCustomization.sMTPUserField"
                                  [preSelectItem]="globalCustomization.company.smtpUser"
                                  (itemSelected)="itemSelected($event, 'smtpUser')">
                                </field-type-text-simple>
                                <span class="corporative-information-modal__error-name"
                                    [ngShow]="globalCustomization.errorUser"
                                    [afTranslate]="'corporative-information-modal.user-50-error'">
                                        _El usuario no puede exceder de 50 caracteres</span>
                            </div>
                            <div class="corporative-information-modal__block-fields-right">
                                <field-type-password [field]="globalCustomization.sMTPPasswordField"
                                    [preSelectItem]="globalCustomization.company.smtpPassword"
                                    (itemSelected)="itemSelected($event, 'smtpPassword')">
                                </field-type-password>
                                <span class="corporative-information-modal__error-name"
                                    [ngShow]="globalCustomization.errorPass">
                                    {{globalCustomization.errorLimitPassword}}</span>
                            </div>
                        </div>
                        <div class="corporative-information-modal__block-line-data"
                            [ngClass]="{'corporative-information-modal__block-line-data--some-errors': globalCustomization.errorFrom && globalCustomization.sMTPFromField.error}">
                            <div class="corporative-information-modal__block-fields-left">
                                <field-type-email [field]="globalCustomization.sMTPFromField"
                                    [preSelectItem]="globalCustomization.company.smtpFrom"
                                    (itemSelected)="itemSelected($event, 'smtpFrom')">
                                </field-type-email>
                                <span class="corporative-information-modal__error-name"
                                    [ngShow]="globalCustomization.errorFrom"
                                    [afTranslate]="'corporative-information-modal.mail-50-error'">_El correo electrónico no puede exceder de 50 caracteres</span>
                            </div>
                        </div>
                        <div class="corporative-information-modal__buttons-checked">
                            <field-type-switch [label]="'corporative-information-modal.secure-connection'| afTranslate"
                                               [checked]="globalCustomization.company.smtpssl"
                                               (callback)="globalCustomization.company.smtpssl = $event.value">
                            </field-type-switch>
                        </div>
                    </div>
                    <field-type-email [ngShow]="globalCustomization.company.customEmail && globalCustomization.customTypeSelect==='1' && !globalCustomization.itsConfigurate"
                        [field]="globalCustomization.authMailField"
                        [preSelectItem]="globalCustomization.mailAuth"
                        (itemSelected)="selectEmail($event)">
                    </field-type-email>
                    <div class="common__input-block common__input-block--fixed corporative-information-modal__bottom-box-especial"
                        [ngClass]="{'corporative-information-modal__bottom-box-especial--error':(globalCustomization.itsConfigurate && globalCustomization.mailAuth ==='' && globalCustomization.company.oauthUser ==='') || globalCustomization.authAccessTokenError || globalCustomization.company.oauthReconfigNeeded}"
                        [ngShow]="globalCustomization.company.customEmail && globalCustomization.customTypeSelect ==='1'">
                        <div class="common__label-block">
                            <span class="common__input-text"
                                [afTranslate]="'corporative-information-modal.associate-custom-account'"></span>
                            <span class="common__text-required">*</span>
                        </div>
                        <p class="corporative-information-modal__explain-text"
                            [afTranslate]="'corporative-information-modal.associate-custom-account-explain'"></p>
                        <button class="common__button-border-none public-link__button-copy-link-init"
                            disabled="{{globalCustomization.company.oauthUser && globalCustomization.company.oauthUser !==''}}"
                            [ngClass]="{'corporative-information-modal__button-bottom':globalCustomization.company.oauthUser && globalCustomization.company.oauthUser !==''}"
                            (click)="initAuth()">
                            <i class="common__icon-button-underlined lf-icon-office365"></i>
                            <span class="common__text-button-underlined"
                                [afTranslate]="'corporative-information-modal.microsoft-config'">__configurar en microsoft</span>
                        </button>
                        <div [ngShow]="globalCustomization.company.oauthUser && globalCustomization.company.oauthUser !==''"
                            class="corporative-information-modal__auth-user-box">
                            <p class="corporative-information-modal__auth-user-info">
                                <span [afTranslate]="'corporative-information-modal.associated-account'"></span>
                                <span class="corporative-information-modal__auth-user-email">{{globalCustomization.company.oauthUser}}</span>
                            </p>
                            <i class="lf-icon-trash corporative-information-modal__auth-user-delete"
                                (click)="deleteOauth()"></i>
                        </div>
                    </div>
                </div>
                <div class="global-customization__buttons-block">
                    <div class="global-customization__left"
                        [ngClass]="{'global-customization__left--previous': globalCustomization.previousCustomization}">
                        <button type="submit"
                            class="global-customization__button"
                            (click)="showPreviewNotification()"
                            [disabled]="!globalCustomization.showPreview">
                            <i class="lf-icon-visible global-customization__icon-button"></i>
                            <span [afTranslate]="'global-customization.notification-preview'">_Vista previa de notificación</span>
                        </button>
                        <button type="submit"
                            class="global-customization__button span__auto-ajust"
                            (click)="showPreviewCustomization()"
                            [disabled]="!globalCustomization.showPreview">
                            <i class="lf-icon-visible global-customization__icon-button"></i>
                            <span [afTranslate]="'global-customization.external-page-preview'">_Vista previa de página externa</span>
                        </button>
                    </div>
                    <button type="submit"
                        class="common__button common__button--clean"
                        [ngShow]="globalCustomization.previousCustomization"
                        (click)="deleteCustomization()"
                        [afTranslate]="'common.remove'">_Eliminar</button>
                    <button type="reset"
                        class="common__button common__button--clean"
                        [ngClass]="{'common__button--separating-margin': globalCustomization.previousCustomization}"
                        (click)="changeViewWindow()"
                        [afTranslate]="'common.cancel'">_Cancelar</button>
                    <button type="submit"
                        class="common__button common__button--clean common__button--separating-margin"
                        (click)="cleanCustomization()"
                        [afTranslate]="'common.clean'">_Limpiar</button>
                    <button type="submit"
                        class="common__button common__button--active common__button--separating-margin"
                        (click)="saveCustomization()"
                        [afTranslate]="'common.save'">_Guardar</button>
                </div>
            </form>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
