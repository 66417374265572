import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'modal-more-info',
  templateUrl: './modal-more-info.component.html'
})
export class ModalMoreInfoComponent{
  modalMoreInfo: any = {

  }

  constructor(private activeModal: NgbActiveModal,
    private translate: AfTranslateFactory) { }
  @Input() configuration:any = null;

  ngOnInit(): void {
    this.configureInfoModal();
  }

  configureInfoModal(){
    switch(this.configuration.fromInfo){
        case 'documentation':
            this.modalMoreInfo.title = this.translate.instant('documentation-list.delete-document-advise-title'),
            this.modalMoreInfo.optionsList = [this.translate.instant('documentation-list.delete-document-advise-locked'),
                this.translate.instant('documentation-list.delete-document-advise-no-permissions'),
                this.translate.instant('documentation-list.delete-document-advise-coincide')
            ]
            break;
        case 'consult-list':
            this.modalMoreInfo.title = this.translate.instant('modal-more-info.title-consult-list'),
            this.modalMoreInfo.optionsList = [this.translate.instant('modal-more-info.option-consult-list-1'),
                this.translate.instant('modal-more-info.option-consult-list-2'),
                this.translate.instant('modal-more-info.option-consult-list-3'),
                this.translate.instant('modal-more-info.option-consult-list-4')
            ]
            break;
        default:
            this.modalMoreInfo.title = this.translate.instant('documentation-list.anonymatum-locked-causes'),
            this.modalMoreInfo.optionsList = [this.translate.instant('documentation-list.anonymatum-locked-causes-format'),
                this.translate.instant('documentation-list.anonymatum-locked-causes-sign'),
                this.translate.instant('documentation-list.anonymatum-locked-causes-included'),
                this.translate.instant('documentation-list.anonymatum-locked-causes-max')
            ]
            break;
    }
  }

  handleDismiss(){
    this.activeModal.close({result:'cancel'});
  }
}
