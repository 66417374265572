<div class="users-admin-main__col users-admin-main__col--type">
  <div class="users-admin-main__table-icon">
      <i [ngHide]="selectionEnabled"
        [class]="person.typeIcon"></i>
      <i [ngShow]="selectionEnabled"
          class="users-admin-main__icon-check"
          [ngClass]="person.selected?'lf-icon-check-full':'lf-icon-box-inactive'"
          (click)="selectToExport()"></i>
  </div>
</div>
<div class="users-admin-main__col users-admin-main__col--name">
  <parragraph-tooltip [text]="person.fullName"></parragraph-tooltip>
</div>
<div class="users-admin-main__col users-admin-main__col--position">
  <parragraph-tooltip [text]="person.position"></parragraph-tooltip>
</div>
<div class="users-admin-main__col users-admin-main__col--company-name">
  <parragraph-tooltip [text]="person.companyName"></parragraph-tooltip>
</div>
<div class="users-admin-main__col users-admin-main__col--email">
  <parragraph-tooltip [text]="person.email"></parragraph-tooltip>
</div>
<div class="users-admin-main__col users-admin-main__col--phone1">
  <parragraph-tooltip [text]="person.phoneNumber1"></parragraph-tooltip>
</div>
<div class="users-admin-main__col users-admin-main__col--role">
  <participants-list *ngIf="person.roles && person.roles.length"
      [participants]="person.roles"
      [type]="'roles'">
  </participants-list>
</div>
<div class="users-admin-main__col users-admin-main__col--actions">
    <ul class="common__options-row">
      <li class="common__menu-options-row"
          [routerLink]="['edit', person.type, person.id]"
          routerLinkActive="active">
          <i class="lf-icon-editable-form"
              matTooltip="{{'common.edit' | afTranslate}}"
              matTooltipPosition="below"
              matTooltipClass="below"
              ></i>
      </li>
      <li class="common__menu-options-row"
          *ngIf="person.type === 'contact'"
          [routerLink]="['convert', person.type, person.id]"
          routerLinkActive="active">
          <i class="lf-icon-favorite"
              matTooltip="{{'common.convert' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above"></i>
      </li>
      <li class="common__menu-options-row"
          *ngIf="person.type === 'user'"
          [routerLink]="['security', person.type, person.id]"
          routerLinkActive="active">
          <i class="lf-icon-key"
              matTooltip="{{'users-admin.permissions' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above"></i>
      </li>
      <li class="common__menu-options-row"
          *ngIf="person.canBeDeleted"
          (click)="deletePerson(person)">
          <i class="lf-icon-trash"
              matTooltip="{{'common.remove' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above"></i>
      </li>
  </ul>
  <i class="lf-icon-kebab-menu common__icon-ellipsis"
    [ngClass]="{'common__icon-ellipsis--disabled': selectionEnabled}"></i>
</div>
