<div class="horizontal-poll-graph">
    <div [id]="'horizontal-poll-graph-'+horizontalPollGraph.graphData.graphicId"></div>
    <button [ngShow]="fromComponent==='resultReports'" #tooltip="matTooltip"
        class="horizontal-poll-graph__capture"
        (click)="saveAsPng(tooltip)"
        [disabled]="horizontalPollGraph.disabledSaveAsPng"
        [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
        matTooltipPosition="above"
        matTooltipClass="above"
        [matTooltipDisabled]="horizontalPollGraph.disableTooltip">
        <i class="lf-icon-all"></i>
    </button>
</div>
