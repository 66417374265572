<div class="line-graph"
  [ngStyle]="{'width': lineGraph.graphWidth +'px'}">
  <div class="line-graph__graph"></div>
  <div class="line-graph__tooltip-box"
    *ngIf="lineGraph.linePoint !== null">
      <div class="line-graph__tooltip-block">
          <div class="line-graph__tooltip-triangle"></div>
          <i class="lf-icon-close line-graph__tooltip-graph-close"
              (click)="closeTooltip()"></i>
          <div class="line-graph__tooltip-header-block">
              <p class="line-graph__tooltip-graph-title">
                  {{lineGraph.linePoint.monthConvert}}</p>
          </div>

          <div class="line-graph__tooltip-details-block">
              <div class="line-graph__tooltip-details-item"
                  *ngFor="let item of lineGraph.linePoint.detailsToShow | keyvalue"
                  [ngHide]="item.key ==='numberOfAnonymousComplaints'">
                  <div class="line-graph__tooltip-details-item-left">
                      <i class="lf-icon-step-final line-graph__tooltip-details-dot"></i>
                      <p class="line-graph__tooltip-details-title"
                          [afTranslate]="'dashboard-reports.'+item.key"></p>
                  </div>
                  <div
                      class="line-graph__tooltip-details-item-right">
                      <span></span>
                      <span>{{numberComplaintsPercent(item.value)}}</span>
                      <span class="line-graph__tooltip-details-text-right">{{item.value}}</span>
                  </div>

              </div>
          </div>
          <div class="line-graph__details-totals-block">
              <i class="lf-icon-news-communication line-graph__details-icon-communication"></i>
                <p class="line-graph__details-totals-text">
                    <span
                        class="line-graph__details-totals-text--uppercase"
                        [afTranslate]="'common.total'">
                    </span>
                    <span>: </span>
                    <span>{{lineGraph.linePoint.numberOfComplaints}} </span>
                    <span>{{lineGraph.tooltipText}}</span>
                </p>
          </div>
      </div>
  </div>
</div>
