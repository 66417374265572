import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService,
    private context: ContextFactory,
    private registerService: RegisterMixtapeService,
    private convertNumbToName: ConvertNumToNameFactory,
    private commonUtilsService: CommonUtilsService,
    private expandedMenuService: ExpandedMenuService,
    private router: Router,
    private documentActions: DocumentActionsService
  ) { }

  expandedMenu: any = ExpandMenu;
  skin: any = this.globalCfg.skin;
  userPermissions: any = this.permissions.getPermissions();
  fullMenu: boolean = window.innerWidth > 1367;
  evaluationId: any = this.route.snapshot.params['evaluationId']
  evaluationInfo:any = this.route.snapshot.data['evaluationInfo'];
  reports:any = {
    showList: false,
    showCheck:  false,
    openAll: true,
    reportsTypeList: [],
    menuDocumentSignature: this.expandedMenu['menuDocumentSignature'].menu,
    confidentialListMenu: this.skin==='dark-blue'?this.expandedMenu['confidentialOptionMenu'].menu:[]
  };
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    if(this.skin === 'icam-red'){
      document.title = this.translate.instant('common.reports');
    }
    this.getReportList();
    this.emitFullMenu();
    this.loader.openLoader('report-init-report');
  }

  getReportList(){
      this.apiService.get('reportobjects/byevaluation', this.evaluationId)
          .then((data:any)=>{
              this.reports.reportsTypeList = this.configureReports(data);
              this.openAllReports();
          }, (errorData:any)=>{
              this.loader.closeError();
              this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: [], type: 'reports'});
          })
  }

  configureReports(reports:any){
    reports.forEach((report:any) => {
      if(report.confidential){
        this.setConfidential(report);
      }

      if(report.oldVersionOfReport === null){
          reports.forEach((otherReport:any) => {
            if(otherReport.oldVersionOfReport === report.reportId){
              this.configureArchived( report, otherReport);
            }
          });
      }

      if(report.type === 0){
        report.lastReportObject = report.reportObjects.sort(this.orderedReportsList)[0];
      }

      report.archivedAmount = report.archived?.length?report.archived?.length:0;
    });

    for(let i = reports.length -1; i >= 0; i--){
        if(reports[i].oldVersionOfReport !== null){
            reports.splice(i, 1);
        }
    }

    return reports;
  }

  orderedReportsList(a: any, b: any) {
      return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
  }
  configureArchived(toReport:any, fromReport:any){
    toReport.archivedId = fromReport.reportId;
    if(!toReport.archived){
        toReport.archived = [];
    }
    fromReport.reportObjects.forEach((reportObject:any) => {
      reportObject.nameVersion = fromReport.name;
    });

    toReport.archived = [...toReport.archived, ...fromReport.reportObjects];
  }

  setConfidential(report:any) {
      this.apiService.get('reports/confidentialaccess/'+report.reportId+'/evaluation', this.evaluationId)
          .then((data:any)=>{
              report.confidentialList = data;
          }, (errorData:any)=>{ })
  }

  emitFullMenu(){
    this.broadcastService.broadcast('changeFullMenu', {
        fullMenu: this.fullMenu
    });
  }

  uploadReport(report:any) {
      this.modalService.uploadReport(report.reportObjects).subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('report-upload-report');
          let formData = new FormData();
          formData.append('file', result.file, result.fileName);
          this.apiService.attachFile('reportobjects/upload/' + report.reportId + '/' + this.evaluationId, formData)
              .then((data:any)=>{
                  report.reportObjects.splice(report.reportObjects.length, 0, data);
                  if (report.type !== 0) {
                      this.openReport(report, data);
                  } else {
                      data.author = this.context.user.fullName;
                      report.lastReportObject = report.reportObjects.sort(this.orderedReportsList)[0];
                      this.broadcastService.broadcast('changeGlobalReportList', {report: data});
                      this.loader.closeLoader(['report-upload-report']);
                      this.modalService.adviceModal(this.translate.instant('reports.upload-version-confirm'), 'info:accept:check-round');
                  }
              }, (errorData:any)=>{
                  this.loader.closeError();
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-upload-document'), 'error:accept:warning');
              });
        }
      })
  }

  newReport(report:any) {
      let isGeneratingReport:any = sessionStorage.getItem('isGeneratingReport');
      if (isGeneratingReport != true && isGeneratingReport != 'true') {
          let newItem:any = {
              title: this.translate.instant('new-report.new-report'),
              type: 'report',
              titleConcept: report.name,
              conceptId: report.reportId,
              conceptObjectId: report.reportObjectId,
              listFolders: report.reportObjects,
              icon: 'lf-icon-analysis',
              rename: false
          }

          this.modalService.renameModal(newItem).subscribe((result:any) => {
              if (result.result === 'ok') {
                  this.createNewReport(report, result.name);
              }
          });
      } else {
          this.modalService.adviceModal(this.translate.instant('reports.still-generating'), 'error:accept:warning');
      }
  }

  createNewReport(report:any, name:any){
      let itemSelected = {
          type: 'new-report',
          item: report,
          name: name
      }
      this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
        if(result.result==='ok'){
          this.registerEvent('create report', report)
          let data = result.item;
          report.reportObjects.splice(report.reportObjects.length, 0, data);
          if(report.type!== 0){
              this.openReport(report, data);
          }else{
              this.launchActionDocuments('open', result.item);
              data.author = this.context.user.fullName;
              report.lastReportObject = report.reportObjects.sort(this.orderedReportsList)[0];
              this.broadcastService.broadcast('changeGlobalReportList', {report: report});
              this.loader.closeLoader(['report-open-report']);
              this.modalService.adviceModal(this.translate.instant('reports-list.report-create') + " '" + name + "'" + this.translate.instant('dashboard.ok-created'), 'info:accept:check-round');
          }
        }else{
          this.modalService.adviceModal(this.translate.instant('dashboard.error-report-create'), 'error:accept:warning');
        }
      })
  }

  registerEvent(type:string, report:any){
    let resgisterItem ={
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':type,
      'Report Type': this.convertNumbToName.reportNumbToName(String(report.reportId)),
      'Source': 'other',
      'Centinela product': this.context.settings.defaultProduct
    }
    this.registerService.register(resgisterItem)
  }



  toggleSwitchFoldUnfold(newValue:any) {
      this.reports.openAll = newValue;
      this.openAllReports();
  }

  openAllReports() {
    this.reports.reportsTypeList.forEach((report:any) => {
        report.open = this.reports.openAll;
    });
    this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: this.reports.report, type: 'reports'});
    this.loader.closeLoader(['folder-on-init', 'report-init-report']);
  }

  showHideReport(report:any){
      report.open = !report.open;
  }

  deleteGlobalReport(item:any){
    this.modalService.adviceModal(this.translate.instant('concept-list.delete-question')+ this.translate.instant('concept-list.report') +" '"+item.title+"' ?", 'info:question:question')
      .subscribe((result:any)=>{
          if(result.result === 'ok'){
            this.loader.openLoader('concept-list-concept-list')
            this.deleteReport(item);
          }
      })
  }

  deleteReport(item:any){
    this.registerEvent('delete report', item);

    this.apiService.delete('reportobjects', item.reportObjectId)
      .then((data:any)=> {
          for(let i = 0; i< this.reports.reportsTypeList.length; i++){
              let reportType:any = this.reports.reportsTypeList[i];
              if(reportType.type === 0){
                  for(let j = 0; j< reportType.reportObjects.length; j++){
                      let reportObject: any = reportType.reportObjects[j]
                      if(item.reportObjectId === reportObject.reportObjectId){
                          reportType.reportObjects.splice(j,1);
                          reportType.lastReportObject = reportType.reportObjects.sort(this.orderedReportsList)[0];
                          this.broadcastService.broadcast('changeGlobalReportList', {report: reportType});
                          break;
                      }
                  }
              }
          }
          this.loader.closeLoader(['concept-list-concept-list']);
          this.modalService.adviceModal(this.translate.instant('concept-list.report-delete'), 'info:accept:check-round');
      }, (errorData:any)=>{
          this.loader.closeError();
          this.modalService.adviceModal(this.translate.instant('concept-list.delete-error'), 'error:accept:warning');
      });
  }
  openReportOn(e:any, report:any){
      this.openReport(report, e.evaluateConcept)
  }

  openReport(report:any, evaluateConcept:any){
      this.broadcastService.broadcast('openEvaluatedConcept', {
          conceptInfo:{
              evaluatedConcept: evaluateConcept,
              report: report,
              archived: report.toShow ? report.toShow : ''
          }
      });
  }

  launchActionDocumentsOn(e:any, report:any){
    this.launchActionDocuments(e.type, e.evaluateConcept, report);
  }

  openChangeSignature(opened:boolean, report:any){
    report.lastReportObject.openOption = opened;
    this.launchActionDocuments('signature-certification', report.lastReportObject, report);
  }

  launchActionDocuments(action:string, document:any, report:any = null){
      switch(action){
          case 'open':
              this.documentActions.doAction('open', document);
              break;
          case 'download':
              this.documentActions.doAction('download', document);
              break;
          case 'share':
              this.openModalShareOrSignCertificate('shareGlobalReport', document, report);
              break;
          case 'publish':
              this.publishReport(document, report);
              break;
          case 'signature-certification':
              document.openOption? this.signatureCertification(document) : document.showMenu = false;
              break;
          case 'signature':
          case 'send-mail-certificate':
          case 'send-sms-certificate':
              this.openModalShareOrSignCertificate(action, document, report);
              break;
          case 'certify-document':
              this.certifyDocumentModal(document, report);
              break;
          case 'request-tracking':
              this.openRequestTracking(document);
              break;
      }
  }

  publishReport(document:any, report:any){
      this.modalService.adviceModal(this.translate.instant('documentation-list.question-publish-document')+"'"+document.title+"' ?", 'info:question:question')
        .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.loader.openLoader('reports-publish-document');
              this.publishDocument(document, report);
            }
        })
  }

  publishDocument(document:any, report:any){
      this.apiService.add('reportdocuments/publish/'+document.reportDocumentId,{})
          .then((data:any)=>{
              document.extension = data.extension;
              document.status = data.status;
              document.documentStatusId = data.documentStatusId;
              document.reportDocumentId = data.reportDocumentId;
              document.editPath = data.editPath;
              document.modificationDate = data.modified;
              report.reportObjects = this.commonUtilsService.replaceElement( document, report.reportObjects, 'reportDocumentId', document);
              this.loader.closeLoader('reports-publish-document');
              this.modalService.adviceModal(this.translate.instant('common.the-document')+"'"+ document.title+"'"+this.translate.instant('documentation-list.publicated-document'), 'info:accept:check-round');
          }, ()=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.error-publish-document') +"'"+ document.title + "'", 'error:accept:warning');
          })
  }

  signatureCertification(document:any){
      let optionDocument:any = {
          sibila: this.context.areas.sibila,
          userPermissions: this.userPermissions,
          canSignDocuments: this.context.user.canSignDocuments
      }
      document.menuOption = this.expandedMenuService.documentationPrincipal(this.reports.menuDocumentSignature, document, optionDocument);
      document.showMenu = true;
  }

  openModalShareOrSignCertificate(type:any, document:any, report:any){
      let configuration:any = {
          type: type,
          addContacts: true,
          document: document
      }
      this.modalService.documentShare(configuration)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.loader.openLoader('reports-share-or-sign-global-document');
              type === 'shareGlobalReport'? this.shareDocument(document, result.share) : this.sendSignature(result.share, document, type, report);
            }
          })
  }

  shareDocument(document:any, share:any){
      this.apiService.add('reportdocuments/share/'+ document.reportDocumentId, share)
        .then(()=>{
            this.loader.closeLoader(['reports-share-or-sign-global-document']);
            this.modalService.adviceModal(this.translate.instant('common.the-report')+"'"+ document.title+"'"+this.translate.instant('documentation-list.shared-document'), 'info:accept:check-round');
        }, ()=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('share-document.share-error'), 'error:accept:warning');
        })
  }


  sendSignature(share:any, document:any, type:any, report:any){
      let recipientsid:any = [];
      share.forEach((item:any) => {
        let element: string = type === 'send-sms-certificate'? 'phoneNumberSMS' : 'email';
        recipientsid.push(item[element])
      });

      let DocumentToSign = {
          recipientsid: recipientsid
      }
      let url = 'reportdocuments/signreportdocument';
      if(type === 'send-mail-certificate'){
          url = 'reportdocuments/certifiedemailreport';
      }else if(type === 'send-sms-certificate'){
          url = 'reportdocuments/certifiedsmsreport';
      }
      this.apiService.update(url, document.reportDocumentId, DocumentToSign)
          .then((data:any)=>{
              window.open(data, '_blank');
              document.extension = 'PDF';
              document.editPath = document.editPath.substr(0, document.editPath.lastIndexOf('.')+1) + document.extension.toLowerCase();
              let text:string = type === 'send-sms-certificate'? 'documentation-list.document-sms-certificate' : 'documentation-list.document-mail-certificate';
              if(type === 'signature'){
                  text = 'documentation-list.document-signature';
                  document.status = this.translate.instant('documentation-list.signature-proccess');
                  document.documentStatusId = 3;
              }else{
                  if(type === 'send-mail-certificate'){
                      document.sendedAsCertifiedEmail = true;
                  }else if(type === 'send-sms-certificate'){
                      document.sendedAsCertifiedSMS = true;
                  }
                  if(document.documentStatusId === 1){
                      document.documentStatusId = 2;
                      document.status = this.translate.instant('common.published');
                  }
              }
              report.reportObjects = this.commonUtilsService.replaceElement( document, report.reportObjects, 'reportDocumentId', document);
              this.loader.closeLoader(['reports-share-or-sign-global-document']);
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let errorText = 'documentation-list.signature-error';
              let typeTranslate = 'normal';
              if(errorData.description==='no_signatures_available' || errorData.description.exceptionMessage==='no_signatures_available'){
                  errorText = 'signature-limit';
                  typeTranslate = 'siteTerms';
              }else if(type === 'send-mail-certificate'){
                  errorText = 'documentation-list.mail-certificate-error';
              }else if(type === 'send-sms-certificate'){
                  errorText = 'documentation-list.sms-certificate-error';
              }
              this.modalService.adviceModal(this.translates(errorText, typeTranslate), 'error:accept:warning');

          })
  }

  translates(text: string, type: any){
    return type === 'siteTerms' ? this.translate.instantSiteTerms(text) : this.translate.instant(text);
  }

  certifyDocumentModal(document:any, report:any){
      this.modalService.certifyDocumentModal()
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('reports-certify-document');
          this.certifyDocument(document, report);
        }
      });
  }

  certifyDocument(document:any, report:any){
      this.apiService.update('reportdocuments/certifyreport', document.reportDocumentId, null)
          .then((data:any)=>{
              document.documentStatusId = 6;
              document.status = this.translate.instant('documentation-list.certificate');
              report.reportObjects = this.commonUtilsService.replaceElement( document, report.reportObjects, 'reportDocumentId', document);
              this.loader.closeLoader(['reports-certify-document']);
              this.modalService.adviceModal(this.translate.instant('documentation-list.confirm-certify'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              errorData.description==='no_signatures_available' || errorData.description.exceptionMessage==='no_signatures_available' ?
              this.modalService.adviceModal(this.translate.instantSiteTerms('signature-limit'), 'error:accept:warning')  :
              this.modalService.adviceModal(this.translate.instant('documentation-list.certified-error'), 'error:accept:warning');
          })
  }

  openRequestTracking(document:any){
      this.modalService.requestTrackingModal(document);
  }

  changeShowList(report: any){
      report.showList = !report.showList;
  }

  displaySwitch(pos:any){
      if(this.reports.reportsTypeList[pos].type !==0 && (this.reports.showCheck === false || this.reports.showCheck === pos) && (this.skin !== 'icam-red')){
          this.reports.showCheck = pos;
          return true;
      }else{
          return false;
      }
  }

  updateReport(report:any){
      this.router.navigateByUrl('evaluation/'+this.evaluationId+'/reports/'+report.reportId);
      // this.router.navigate(['root.evaluation.reportInfo-icam', {reportId: report.reportId}])
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id);
  }

  showElements(e:any, report:any){
      report.showArchived = !report.showArchived;
      report.toShow = e.toShow;
  }

  deleteArchived(report:any){
    this.modalService.adviceModal(this.translate.instant("concept-list.delete-all-archive-reports"), 'info:question:question')
      .subscribe((result:any)=>{
        if(result.result==='ok'){
          this.loader.openLoader('folder-delete-archived')
          this.deleteAllArchived(report);
        }
      })
  }

  deleteAllArchived(report:any){
      this.apiService.delete('reportobjects/'+this.evaluationId, report.archivedId)
          .then((data:any)=>{
              report.archived = [];
              report.archivedAmount = report.archived.length;
              report.toShow = 'evaluated';
              this.loader.closeLoader(['folder-delete-archived']);
              let primaryText:string = report.archived.length > 1?'concept-list.delete-archive-reports':'concept-list.delete-archive-report';
              this.modalService.adviceModal(this.translate.instant(primaryText), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
              this.loader.closeError();
          })
  }

  reportsAction(action:string, report:any) {
      if(action === 'confidential'){
          this.openModalConfidential(report);
      }
  }

  openModalConfidential(report:any){
      this.modalService.manageConfidentialModal(report)
        .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.loader.openLoader('reports-evaluation-confidential');
              this.confidentReport(report, result);
            }
        })
  }

  confidentReport(report:any, resultModal:any){
      let list:any = resultModal.share;
      let confidential:string = resultModal.confidential.toString();
      let confidentialOfUserConnected:any = resultModal.confidentialOfUserConnected;
      this.apiService.patch('reports/setconfidential', report.reportId+'/evaluation/'+this.evaluationId + '/' + confidential, list)
          .then((data:any)=>{
              report.confidential = !!resultModal.confidential;
              let text = 'manage-confidential-modal.confident-uncheck';
              if(report.confidential){
                  text = 'manage-confidential-modal.confident-ok';
              }else{
                  report.confidentialList = [];
              }
              if(report.confidential && !confidentialOfUserConnected){
                  this.reports.reportsTypeList = this.commonUtilsService.deleteElement(report, this.reports.reportsTypeList, 'reportId');
              }
              this.loader.closeLoader('reports-evaluation-confidential');
              this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning');
          })
  }

  adviseAnonymatum(report:any){
    if(this.context.anonymatumService){
        if(report.documentStatusId === 4 || report.documentStatusId === 6 ){
            this.modalService.adviceModal(this.translate.instant('concept-list.anonymatum-error-status'), 'error:accept:warning')
        }else{
            this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-advise'), 'info:question:question').subscribe((result:any)=>{
              if(result.result==='ok'){
                this.anonymatumDocument(report);
              }
            })
        }
    }else{
        this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-adquired'), 'error:accept:warning',  '', [], '', true)
    }
  }

  anonymatumDocument(report:any){
      let itemSelected:any = {
          type: 'anonymatum-report',
          item: report,
      }
      this.modalService.informativeLoadingModal(itemSelected)
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              for(let i = 0; i < this.reports.reportsTypeList.length;i++){
                let reportsType:any = this.reports.reportsTypeList[i];
                let last:any = reportsType.reportObjects.length - 1;
                if(reportsType.reportObjects.length && reportsType.reportObjects[last].reportDocumentId === result.item.reportDocumentId){
                    let lastReportObject = reportsType.reportObjects[last];
                    lastReportObject.author = result.item.createdBy;
                    lastReportObject.documentStatusId = result.item.documentStatusId;
                    lastReportObject.extension = result.item.extension;
                    lastReportObject.anonymized = result.item.anonymized;
                    lastReportObject.status = result.item.status;
                    reportsType.reportObjects.splice(last, 1, lastReportObject)
                    break;
                }
            }
            this.modalService.adviceModal(this.translate.instant('documentation-list.anonymatum-document-ok'), 'info:accept:check-round')
        }else if(result.result ==='cancel'){
            if(report.documentStatusId === 3 || report.documentStatusId === 4 || report.documentStatusId === 6){
                this.modalService.adviceModal(this.translate.instant('concept-list.no-anonymize-process'), 'error:accept:warning')
            }else if(result?.error?.data?.message ==="anonymize_no_credits"){
                this.modalService.adviceModal(this.translate.instantSiteTerms('anonymatum-service-no-credits'), 'error:accept:warning',  '', [], '', true)
            }else{
                this.modalService.adviceModal([this.translate.instant('documentation-list.anonymatum-document-error'), this.translate.instant('documentation-list.anonymatum-document-causes')], 'error:error-link:warning')
            }


        }
      })
  }

  getExpresion(data:any){
    console.log(data);
    return true;
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }
}
