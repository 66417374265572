import { Component, OnChanges, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ClientsListParentService } from '../utils/clients-list-parent.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'clients-list-uk',
  templateUrl: './clients-list-uk.component.html'
})

export class ClientsListUkComponent implements OnInit, OnChanges {
  skin:string =  this.globalCfg.skin;
  permissions:any = this.route.snapshot.data['companyPermissions'];
  clientsList:any = {
    searchClient: '',
    colFilter: 'name',
    order: false,
    search: false,
    filter: []
  };

  constructor(private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private clientsListParent: ClientsListParentService,
  ) {}

  @Input() clientList: any;
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.clientsListParent.OnInit(this);
  }

  ngOnChanges(): void {
    if(this.clientList?.length){
        this.clientsListParent.ConfigureClientType(this);
    }else{
        this.clientsList.noClientsList = true;
    }
  }

  editClientDetail(client: any){
    this.clientsListParent.ShowModalClient(client, this);
  }

  newClient(){
    this.clientsListParent.NewClient(this);
  }

  openMigrationModal(){
    this.clientsListParent.OpenMigrationModal(this);
  }

  toggleSearch() {
    this.clientsListParent.ToggleSearch(this);
  }

  orderTable(){
      this.clientsList.order = !this.clientsList.order;
  }

  selectClient(clientSelected: any){
      this.clientList.forEach((client: any) => {
        client.selectedClient = client.clientId === clientSelected.clientId?true:false;
      })

      this.launchAction.emit({
          action: 'selectClient',
          client: clientSelected
      })
  }

  goToProcesses(client: any, e: any){
      e.stopPropagation();
      let actionProcess : string = 'generateProcessDatabase';
      if(client.evaluation?.evaluationId){
          this.loader.openLoader('dashboard-panel-create-evaluation');
          actionProcess = 'goToProcesses';
      }
      this.launchAction.emit({
          action: actionProcess,
          client: client
      })
  }
}
