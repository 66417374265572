<div class="dashboard-reports">
  <div class="dashboard-reports__graph-block">
      <div class="dashboard-reports__graph-header">
          <div class="dashboard-reports__graph-header-title">
              <i class="lf-icon-label-analysis dashboard-reports__header-title-icon"></i>
              <p class="dashboard-reports__header-title-text"
                [afTranslate]="'common.'+(skin === 'dark-blue' ? 'graphs' : 'reports')"></p>
          </div>
          <div class="dashboard-reports__header-complaints-filter dashboard-reports__header-complaints-filter--type"
              ngbDropdown
              ngbDropdownToggle>
              <span class="dashboard-reports__header-complaints-text">{{dashboardReports.selectComplaintOption?.text}}</span>
              <i class="lf-icon-angle-down dashboard-reports__header-complaints-icon"></i>
              <ul class="common__block-option-list dashboard-reports__dropdown-panel"
                  ngbDropdownMenu>
                  <li class="common__language-item"
                      ngbDropdownItem
                      *ngFor="let complaint of dashboardReports.complaintOptionList"
                      (click)="selectComplaint(complaint)">
                      <span-tooltip
                          [placement]="'above'"
                          [text]="complaint.text">
                        </span-tooltip>
                  </li>
              </ul>
          </div>
          <div class="dashboard-reports__header-complaints-filter dashboard-reports__header-complaints-filter--no-border"
                [ngHide]="dashboardReports.specialGraph"
                ngbDropdown
                ngbDropdownToggle>
              <span class="dashboard-reports__header-complaints-text">{{dashboardReports.selectedChannelName}}</span>
              <i class="dashboard-reports__header-complaints-icon dashboard-reports__header-complaints-icon-menu"
                  [ngClass]="dashboardReports.selectedChannel?'lf-icon-filter-delete':'lf-icon-filter-1'"
                  (click)="unSelectChannel($event)"></i>
              <ul class="common__block-option-list dashboard-reports__dropdown-panel"
                  ngbDropdownMenu>
                  <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                      <input type="text"
                              class="common__dropdown-inner-input"
                              (keyup)="filterChannels()"
                              (click)="prevent($event)"
                              placeholder="{{'common.search-name'| afTranslate}}"
                              [(ngModel)]="dashboardReports.elementWordFilter"
                              [ngModelOptions]="{standalone: true}">
                      <i class="lf-icon-search common__search-icon"
                          [ngClass]="dashboardReports.inputFilter ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"></i>
                  </li>
                  <li class="common__language-item"
                      ngbDropdownItem
                      *ngFor="let channelOption of dashboardReports.evaluationListFilter"
                      (click)="selectChannel(channelOption)">
                      <span-tooltip
                          [placement]="'above'"
                          [text]="channelOption.name">
                        </span-tooltip>
                  </li>
              </ul>
          </div>
          <div class="dashboard-reports__header-complaints-filter dashboard-reports__header-complaints-filter--no-border"
              ngbDropdown
              id="from-to-calendar"
              autoClose="'outside'">
              <div ngbDropdownToggle>
                  <p class="dashboard-reports__select-dates">
                      {{dashboardReports.fromDate | convertDate:'be-special'}} - {{dashboardReports.toDate | convertDate:'be-special'}}
                  </p>
                  <i class="lf-icon-calendar
                      dashboard-reports__header-complaints-icon
                      dashboard-reports__header-complaints-icon-menu"></i>
              </div>
              <ul class="dashboard-reports__calendar-dropdown common__block-option-list dashboard-reports__dropdown-panel"
                ngbDropdownMenu>
                    <from-to-calendar [fromSave]="dashboardReports.fromDate"
                        [toSave]="dashboardReports.toDate"
                        (changeDays)="changeDays($event.days)"
                        (changeCalendar)="changeCalendar($event.dates)"
                        [type]="type">
                    </from-to-calendar>
              </ul>
          </div>
          <div class="dashboard-reports__header-complaints-filter dashboard-reports__header-complaints-filter--no-border dashboard-reports__header-complaints-filter--right"
              ngbDropdown
              ngbDropdownToggle
              [ngShow]="dashboardReports.showDropdownGeneralReport">
              <span class="dashboard-reports__header-complaints-text"
                    [afTranslate]="'dashboard-reports.general-report'">
              </span>
              <i class="lf-icon-analysis dashboard-reports__header-complaints-icon dashboard-reports__header-complaints-icon-menu"></i>
              <div *ngIf="dashboardReports.reportConfidential.confidential"
                class="dashboard-reports__block-confident-report"
                ngbDropdown
                id="dashboard-reports-confidential"
                [autoClose]="'outside'">
                <button class="evaluation-menu__confident-folder evaluation-menu__confident-folder--active-short"
                    ngbDropdownToggle
                    matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above"
                    (click)="$event.stopPropagation()">
                    <i class="lf-icon-lock"></i>
                </button>
                <list-in-dropdown [ngShow]="dashboardReports.reportConfidential.confidentialList"
                    ngbDropdownMenu
                    class="dropdown-menu dashboard-reports__box-select-list"
                    [list]="dashboardReports.reportConfidential.confidentialList"
                    [type]="'confidential'"
                    (closeDropdownEmit)="closeDropdown('dashboard-reports-confidential')">
                </list-in-dropdown>
              </div>
              <ul class="common__block-option-list dashboard-reports__dropdown-panel"
                  ngbDropdownMenu>
                  <li class="common__language-item"
                      *ngFor="let reportOption of dashboardReports.reportOptionList"
                      (click)="launchActionList(reportOption)">
                      <span-tooltip
                          [placement]="'above'"
                          [text]="reportOption.text">
                        </span-tooltip>
                  </li>
              </ul>
          </div>
          <div [class]="'dashboard-reports__header-complaints-filter dashboard-reports__header-complaints-filter--no-border dashboard-reports__header-complaints-filter--right'"
                [ngShow]="skin === 'icam-red' && userPermissions !== 'none'"
                (click)="launchActionList({action:'viewList'})">
              <span class="dashboard-reports__header-complaints-text"
                    [afTranslate]="'dashboard-reports.general-report'">
              </span>
              <i class="lf-icon-analysis dashboard-reports__header-complaints-icon dashboard-reports__header-complaints-icon-menu"></i>
          </div>
      </div>
      <div class="dashboard-reports__graph-body"
          [ngClass]="{'dashboard-reports__graph-body--donut':(dashboardReports.selectComplaintOption?.id === 1 || dashboardReports.selectComplaintOption?.id === 2) && from==='folder'}"
          [ngSwitch]="dashboardReports.selectComplaintOption?.id">
          <div *ngSwitchCase="0" class="dashboard-reports__graph-box">
              <line-graph [graphData]="dashboardReports.reportsByDateData">
              </line-graph>
          </div>
          <div *ngSwitchCase="1" class="dashboard-reports__graph-box "
              [ngClass]="{'dashboard-reports__graph-box--donut': from==='folder',
                  'dashboard-reports__graph-box--no-data': dashboardReports.dateDetails?.numberOfComplaints === 0}">
              <div [ngShow]="dashboardReports.dateDetails?.numberOfComplaints !== 0">
                  <donut-graph class="dashboard-reports__donut-graph"
                    [graphData]="dashboardReports.reportsByAreaData"
                    [configurateReport]="dashboardReports.donutOptions">
                  </donut-graph>
                  <div class="donut-graph__legend"
                      [ngClass]="{'donut-graph__legend--show':dashboardReports.donutOptions.type ==='be'}">
                      <div class="donut-graph__dashboard-donut-header">
                          <p class="donut-graph__dashboard-donut-header-text">{{dashboardReports.areaTitle}}</p>
                          <p class="donut-graph__dashboard-donut-header-text float-end"> {{dashboardReports.tooltipText}}</p>
                      </div>
                      <ul class="donut-graph__dashboard-donut-body">
                          <li class="donut-graph__dashboard-donut-row"
                              *ngFor="let complaints of dashboardReports.reportsByAreaData.original">
                              <div class="donut-graph__row-left">
                                  <i class="lf-icon-step-final donut-graph__row-left-dot"
                                    [ngStyle]="{'color': complaints.color}"></i>
                                  <p class="donut-graph__row-left-text">{{complaints.label}}</p>
                              </div>
                              <div class="donut-graph__row-right">
                                  <p class="donut-graph__row-right-text donut-graph__row-right-text--no-percent">
                                      {{complaints.complaints}}</p>
                                  <p class="donut-graph__row-right-text donut-graph__row-right-text--right">
                                      {{complaints.complaintsPerc}} %</p>
                              </div>
                          </li>
                      </ul>
                      <div class="line-graph__details-totals-block">
                          <i class="lf-icon-tag line-graph__details-icon-communication"></i>
                          <p class="line-graph__details-totals-text line-graph__details-totals-text--top">
                                <span
                                    class="line-graph__details-totals-text--uppercase"
                                    [afTranslate]="'common.total'">
                                </span>
                                <span>: </span>
                                <span>{{dashboardReports.reportsByAreaData.original.length}} </span>
                                <span>{{dashboardReports.areaTitle}}</span>
                          </p>
                      </div>
                  </div>
              </div>
              <p class="dashboard-reports__no-data"
                  [afTranslate]="'dashboard-reports.not-enough-data'"
                  [ngHide]="dashboardReports.dateDetails?.numberOfComplaints !== 0"></p>
          </div>
          <div *ngSwitchCase="3" class="dashboard-reports__general-summary-container">
              <ul class="dashboard-reports__general-summary-list">
                  <li class="dashboard-reports__general-summary-item"
                      [ngStyle]="{'width': 100 / (dashboardReports.numBoxes - 1) +'%' }"
                      *ngFor="let summary of dashboardReports.summaryReportsList; let last = last"
                      [ngShow]="!(summary.key === 'month' || summary.key === 'numberOfAnonymousComplaints')">
                      <div class="dashboard-reports__general-summary-box"
                            [ngClass]="{'dashboard-reports__general-summary-box--total':summary.key === 'numberOfComplaints'}">
                          <span-tooltip class="dashboard-reports__general-summary-box-title dashboard-reports__general-summary-box-title--subtitle"
                            [text]="summary.key ==='numberOfComplaints'?('dashboard-reports.number-complaints' | afTranslate):('dashboard-reports.'+summary.key | afTranslate)"></span-tooltip>
                          <p class="dashboard-reports__general-summary-box-title dashboard-reports__general-summary-box-title--value">
                              {{summary.value}}</p>
                          <p class="dashboard-reports__general-summary-box-icon">
                              <i [class]="'lf-icon-'+getListIcon(summary.key)+' dashboard-reports__general-summary-inner-icon'"></i>
                          </p>

                      </div>
                      <i class="lf-icon-angle-right dashboard-reports__general-summary-next-icon"
                          [ngHide]="summary.key === 'numberOfComplaints' || last"></i>
                  </li>
              </ul>
              <div class="dashboard-reports__percentage-complete-block">
                  <i class="lf-icon-area dashboard-reports__percentage-complete-icon"></i>
                  <p class="dashboard-reports__percentage-complete-text">
                      <span [afTranslate]="'dashboard-reports.resolution-percentage'"></span>
                      <span class="dashboard-reports__percentage-text-value">
                        {{dashboardReports.resolutionPercentage}}%
                      </span>
                  </p>
              </div>
              <div class="dashboard-reports__news-and-complete-block">
                  <div class="dashboard-reports__today-block dashboard-reports__today-block--today">
                      <span class="dashboard-reports__today-block-text"
                          [afTranslate]="'dashboard-reports.solved-today'"></span>
                      <div class="dashboard-reports__today-block-value">
                          {{dashboardReports.todayEvolution?.solvedToday}}</div>
                  </div>
                  <div class="dashboard-reports__today-block dashboard-reports__today-block--solved">
                      <span class="dashboard-reports__today-block-text"
                          [afTranslate]="'dashboard-reports.new-today'"></span>
                      <div class="dashboard-reports__today-block-value">
                          {{dashboardReports.todayEvolution?.createdToday}}</div>
                  </div>
              </div>
          </div>
          <div *ngSwitchCase="4"
                class="dashboard-reports__general-summary-container">
              <div class="dashboard-reports__general-summary-list-es">
                  <div class="dashboard-reports__general-summary-list-element dashboard-reports__general-summary-list-element--header"
                      [ngShow]="dashboardReports.summaryReports.numberOfComplaints">
                      <i class="lf-icon-inbox dashboard-reports__general-summary-list-element-icon"></i>
                      <span class="dashboard-reports__general-summary-list-element-title"
                          [afTranslate]="'dashboard-reports.numberOfComplaints'"></span>
                      <span class="dashboard-reports__general-summary-list-element-value">
                        {{dashboardReports.summaryReports.numberOfComplaints}}
                      </span>
                  </div>
                  <div class="dashboard-reports__general-summary-list-steps">
                      <div class="dashboard-reports__general-summary-list-element dashboard-reports__general-summary-list-element--step dashboard-reports__general-summary-list-element--{{skin}}"
                          *ngFor="let summary of dashboardReports.summaryReportsList"
                          [ngShow]="!(summary.key === 'month' || summary.key === 'numberOfAnonymousComplaints' || summary.key === 'numberOfComplaints')">
                            <span class="dashboard-reports__general-summary-list-element-title dashboard-reports__general-summary-list-element-title--step"
                              [afTranslate]="'dashboard-reports.'+summary.key"></span>
                            <span class="dashboard-reports__general-summary-list-element-value"
                                [ngClass]="{'dashboard-reports__general-summary-list-element-value--single':summary.value.upValue == null}">
                                {{getValueUpIfObject(summary.value)}}
                            </span>
                            <div class="dashboard-reports__general-summary-list-double-down-container"
                                [ngShow]="summary.value.downValue != null">
                                <span class="dashboard-reports__general-summary-list-element-value">
                                    {{summary.value.downValue}}
                                </span>
                                <span class="dashboard-reports__general-summary-list-element-title dashboard-reports__general-summary-list-element-title--step"
                                    [afTranslate]="'dashboard-reports.'+summary.value.downName"></span>
                            </div>
                            <i [ngShow]="summary.value.down == null"
                              [class]="'lf-icon-'+getListIcon(summary.key)+' dashboard-reports__general-summary-list-element-icon'"></i>
                      </div>
                  </div>
              </div>
              <div class="dashboard-reports__percentage-complete-block">
                  <i class="lf-icon-area dashboard-reports__percentage-complete-icon"></i>
                  <p class="dashboard-reports__percentage-complete-text">
                      <span [afTranslate]="'dashboard-reports.archived-percentage'"></span>
                      <span class="dashboard-reports__percentage-text-value">
                        {{dashboardReports.resolutionPercentage}}%
                      </span>
                  </p>
              </div>
              <div class="dashboard-reports__news-and-complete-block">
                  <div class="dashboard-reports__today-block dashboard-reports__today-block--today">
                      <span class="dashboard-reports__today-block-text"
                            [afTranslate]="'dashboard-reports.solved-today'"></span>
                      <div class="dashboard-reports__today-block-value">
                        {{dashboardReports.todayEvolution?.solvedToday}}
                      </div>
                  </div>
                  <div class="dashboard-reports__today-block dashboard-reports__today-block--solved">
                      <span class="dashboard-reports__today-block-text"
                            [afTranslate]="'dashboard-reports.new-today'"></span>
                      <div class="dashboard-reports__today-block-value">
                        {{dashboardReports.todayEvolution?.createdToday}}
                      </div>
                  </div>
              </div>
          </div>
          <div *ngSwitchDefault
              class="dashboard-reports__graph-box"
              [ngClass]="{'dashboard-reports__graph-box--donut': from==='folder',
                  'dashboard-reports__graph-box--no-data': dashboardReports.dateDetails?.numberOfComplaints === 0}">
              <div [ngShow]="dashboardReports.dateDetails?.numberOfComplaints !== 0">
                  <donut-graph class="dashboard-reports__donut-graph"
                      [graphData]="dashboardReports.reportsByStatusData"
                      [configurateReport]="dashboardReports.donutOptions">
                  </donut-graph>
                  <div class="donut-graph__legend"
                      [ngClass]="{'donut-graph__legend--show':dashboardReports.donutOptions.type ==='be'}">
                      <div class="donut-graph__dashboard-donut-header">
                          <p class="donut-graph__dashboard-donut-header-text">
                              {{dashboardReports.stateTitle}}
                          </p>
                          <p class="donut-graph__dashboard-donut-header-text float-end">{{dashboardReports.tooltipText}}</p>
                      </div>
                      <ul class="donut-graph__dashboard-donut-body donut-graph__dashboard-donut-body--no-border">
                          <li class="donut-graph__dashboard-donut-row"
                              *ngFor="let complaints of dashboardReports.reportsByStatusData?.original">
                              <div class="donut-graph__row-left">
                                  <i class="lf-icon-step-final donut-graph__row-left-dot"
                                      [ngStyle]="{'color': complaints.color}"></i>
                                  <p class="donut-graph__row-left-text">
                                      {{complaints.label}}</p>
                              </div>
                              <div class="donut-graph__row-right">
                                  <p class="donut-graph__row-right-text donut-graph__row-right-text--no-percent">
                                      {{complaints.complaints}}</p>
                                  <p class="donut-graph__row-right-text donut-graph__row-right-text--right">
                                      {{complaints.complaintsPerc}} %</p>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
              <p class="dashboard-reports__no-data"
                  [afTranslate]="'dashboard-reports.not-enough-data'"
                  [ngHide]="dashboardReports.dateDetails?.numberOfComplaints !== 0">
              </p>
          </div>
      </div>
      <ul class="dashboard-reports__graph-time-block "
          [ngShow]="dashboardReports.selectComplaintOption?.id === 0">
          <li *ngFor="let option of dashboardReports.timeOptions"
              [ngShow]="!option.hidden"
              class="dashboard-reports__graph-time-option"
              [ngClass]="{'selected':option.selected,
                  'first':option.first}"
              (click)="selectTimeOption(option)">
              {{option.text}}
          </li>
      </ul>
  </div>
  <div class="dashboard-reports__product-info-block"
      [ngHide]="dashboardReports.selectComplaintOption?.id === 3 || dashboardReports.selectComplaintOption?.id === 4">
      <div class="dashboard-reports__product-info-header">
          <p class="dashboard-reports__detail-text">
              <span [afTranslate]="dashboardReports.textDate"></span>
              <span>
                  {{dashboardReports.fromDate | convertDate:'be-special'}}
                  <span [afTranslate]="'dashboard-reports.to'"> </span>
                  {{dashboardReports.toDate |convertDate:'be-special'}}
              </span>
          </p>
          <i [ngHide]="skin ==='steel-blue'"
              matTooltip="{{(skin !== 'icam-red'?'dashboard-reports.description-details': 'dashboard-reports.description-details-icam') | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above"
              class="lf-icon-info-full dashboard-reports__detail-description">
          </i>
      </div>
      <div class="dashboard-reports__product-info-header-be">
          <p [afTranslate]="'dashboard-reports.report-status'"></p>
      </div>
      <ul class="dashboard-reports__product-info-list">
          <li *ngFor="let select of dashboardReports.selectActive"
              class="dashboard-reports__product-info-item"
              [ngStyle]="{'width': (100 / dashboardReports.selectActive.length)+'%'}"
              [ngHide]="skin === 'icam-red' && select.name === 'numberOfAnonymousComplaints'">
              <div class="dashboard-reports__special-border"></div>
              <p class="dashboard-reports__product-info-column"
                  [afTranslate]="getTranslate(select.name)"></p>
              <div class="dashboard-reports__product-info-value">
                  {{select.value}}</div>
          </li>
      </ul>

  </div>

</div>
