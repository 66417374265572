import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';

@Component({
  selector: 'new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.scss']
})
export class NewDocumentComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  newDocument: any = {
    showProductList: false,
    showSelection: true,
    showFormat: false,
    stateId: null,
    formatId: null,
    templateId: null,
    configuratedFields: false,
    iconHeader: 'lf-icon-document',
    titleHeader: this.translate.instant('new-document.new-document'),
    templates: [],
    document: {
        properties:{},
        name:''
    },
    productFieldInfo: {
        fieldId:'format',
        label: this.translate.instant('common.format'),
        description: null,
        required: true
    },
    nameFieldInfo: {
        label: this.translate.instant('common.document-name'),
        required:  true,
        description: null
    },
    templateFieldInfo: {
        fieldId:'template',
        label: this.translate.instant('new-document.template'),
        description: null,
        required: true
    },
    // vm.fieldCheck:  {
    //     id: 'new-document-format-check',
    //     disabled: false
    // }
    processSteps: {
        label: this.translate.instant('common.associate-to-process'),
        required:  false
    },
    pillTemplate: this.translate.instant('new-document.use-template'),
  }

  constructor( private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private route: ActivatedRoute,
    private validateDocumentService: ValidateDocumentService,
    private activeModal: NgbActiveModal) { }
  @Input() type: any = null;
  @Input() concept: any = null;
  @Input() list: any = null;
  @Input() listDocuments: any = null;
  @Input() listSelect: any = null;
  ngOnInit(): void {
    if(this.type === 'template-document'){
      let location: any = window.location;
      this.newDocument.iconHeader = location.pathname.indexOf('template-front') !== - 1? 'lf-icon-template-user' : 'lf-icon-template';
      this.newDocument.titleHeader =location.pathname.indexOf('template-front') !== - 1? this.translate.instant('templates-concept.create-document-custom') : this.translate.instant('templates-concept.create-document')
    }
    this.getFormats();
    if(this.list?.length){
        this.newDocument.templateIndex = new Map();
        this.getTemplatesConcept();
    }
  }

  getFormats(){
    this.apiService.getDocumentsFormats()
    .then((data:any)=>{
      for(let i = 0; i < data.length; i++){
        data[i].text = this.translate.instant(data[i].translate);
      }
      this.newDocument.formats = data;
      this.configureLiterals()
    }, (errorData:any)=>{})
  }

  configureLiterals(){
    if(this.type === 'template-document'){
        this.newDocument.pillTemplate = this.translate.instant('new-document.use-preview-template');
        this.newDocument.nameFieldInfo.label = this.translate.instant('new-document.template-name');
    }else if(this.type === 'process-modal'){
      this.newDocument.processSteps.required = true;
    }
    this.newDocument.configuratedFields = true;
  }

  getTemplatesConcept(){
    this.list.forEach((formatProduct:any) => {
      formatProduct.text = formatProduct.title;
      formatProduct.listValueId = formatProduct.id;
      this.newDocument.templateIndex.set(formatProduct.id, formatProduct)
    });
    this.newDocument.templates = this.list;
  }

  itemSelectedOn(e: any, type:any){
    this.itemSelected(type, e.newValue)
  }
  itemSelected(item:any, newValue:any){
      if(this.newDocument.showFormat){
          this.newDocument.document.properties = this.newDocument.templateIndex.get(newValue);
      }else{
          if(newValue){
              this.newDocument.document.properties = this.newDocument.formats[newValue-1];
              this.newDocument.productFieldInfo.error = false;
          }else{
              this.newDocument.document.properties = {};
          }
      }
      this.newDocument[item] = newValue;
  }

  itemSelectedList(e:any, item:any){
    this.newDocument[item] = e.newValue;
      if(e.newValue){
          for(let i = 0; i <this.listSelect.length; i++){
              let item = this.listSelect[i]
              this.newDocument.nameState = '';
              if(item.stateId === e.newValue){
                  this.newDocument.nameState = item.name;
                  break;
              }
          }
          this.newDocument.processSteps.error = false;
      }else{
        this.newDocument.nameState = '';
      }
  }

  itemName(e:any){
    this.newDocument.document.name = e.newValue;
      if(e.newValue === ''){
        this.newDocument.nameFieldInfo.error= true;
      }
      if(this.newDocument.document.properties.extension){
          let validateText =  this.validateUploadFiles(this.newDocument.document.name+'.'+this.newDocument.document.properties.extension.toLowerCase(),  this.listDocuments);
          this.newDocument.errorNameList = validateText !== '';
          this.newDocument.nameFieldInfo.error= validateText !== '';
          if(validateText !== ''){
              this.newDocument.textError = this.translate.instant(validateText);
          }
      }
  }

  clearForm(){
      this.newDocument.document = {
          properties:{},
          name:''
      };
      this.newDocument.stateId = null;
      this.newDocument.formatId = null;
      this.newDocument.templateId = null;
      this.newDocument.errorNameList = false;
      this.newDocument.showFormat = false;
  }

  createUrl(){

      let evaluationId: any = this.concept && this.concept.evaluationId? this.concept.evaluationId : this.route.snapshot?.firstChild?.params['evaluationId'];
      let conceptId: any = this.concept.conceptId;
      let conceptObjectId: any = this.concept.conceptObjectId
      let documentPropertyId: any = this.newDocument.document.properties.id;
      let listValueId: any = this.newDocument.document.properties.listValueId
      if(this.type === 'template-document' && !this.newDocument.showFormat){
          return 'documents/'+ evaluationId + '/' + conceptId + '/fromTemplate/'+listValueId;
      }else  if(this.type === 'template-document' && this.newDocument.showFormat){
          return 'documents/'+ evaluationId  + '/createfromtemplate/' + conceptId + '/' + documentPropertyId;
      }else if(this.newDocument.showFormat){
          return 'documentobjects/fromComplianceDocumentTemplate/'+ conceptObjectId +'/'+documentPropertyId;
      }else{
          return 'documentobjects/fromOfficeDocumentTemplate/'+ conceptObjectId +'/'+listValueId;
      }
  }

  validateForm(){
      let document: any = this.newDocument.document
      this.newDocument.nameFieldInfo.errorMaxlength = false;
      this.newDocument.templateFieldInfo.error =false;
      this.newDocument.processSteps.error = false;
      if(document.properties){
        this.newDocument.productFieldInfo.error = (document.properties.extension === ''|| document.properties.extension === undefined);
        this.newDocument.nameFieldInfo.error = (document.name === '');
        if(document.name !== ''){
            let validateText =  this.validateUploadFiles(document.name+'.'+document.properties.extension.toLowerCase(),  this.listDocuments);
            this.newDocument.nameFieldInfo.error= document.name.length > 99 || validateText !== '';
            this.newDocument.nameFieldInfo.errorMaxlength = (document.name.length > 99);
            this.newDocument.errorNameList = this.newDocument.nameFieldInfo.error;
            if(validateText !== ''){
                this.newDocument.textError = this.translate.instant(validateText);
            }
        }
        this.newDocument.processSteps.error = (this.type === 'process-modal' && !this.newDocument.stateId);
        if(document.properties.extension && this.newDocument.nameFieldInfo.error === false && this.newDocument.processSteps.error === false){
            this.handleAccept();
        }
      }else{
        this.newDocument.productFieldInfo.error = (!this.newDocument.showFormat)
      }
  }

  validateUploadFiles(document: any, list: any){
      if(!(this.validateDocumentService.correctCharacters(document))){
        return 'rename-modal.incorrect-characters';
      }
      if(!(this.validateDocumentService.correctDocumentFormat(document))){
          return 'documentation-list.format-not-accepted';
      }
      if(this.validateDocumentService.duplicatedDocuments(document, list)){
          return 'documentation-list.duplicated-name-document';
      }
      if(!(this.validateDocumentService.nameLength(document))){
          return 'up-document.error-name';
      }
      return '';
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      name:  this.newDocument.document.name+'.'+(this.newDocument.document.properties.extension.toLowerCase()),
      url: this.createUrl(),
      stateId: this.newDocument.stateId,
      nameState: this.newDocument.nameState
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
