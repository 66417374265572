<div class="process-edit-modal">
    <div class="common__modal-header-block common__modal-header-block--margin-0">
        <i class="lf-icon-process common__icon-header"></i>
        <span class="common__text-header" [afTranslate]="'process.title'"></span>
        <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
    </div>
    <main class="process-edit-modal__main">
        <process-edit [evaluationId]="evaluationId" 
            [concept]="concept" 
            [modal]="true" 
            (onUpdate)="processUpdated($event)">
        </process-edit>
    </main>
  <footer class="process-edit-modal__footer">
      <div class="process-edit__howto pull-left">
        <div class="process-edit__howto"
            [ngShow]="!processEditModal.modal">
            <i class="customize-concept__info-block-icon"
                [ngClass]="processEditModal.showInfo?'lf-icon-close-round':'lf-icon-information'"
                (click)="processEditModal.showInfo = !processEditModal.showInfo"></i>
            <div class="customize-concept__triangle-box"
                    [ngShow]="processEditModal.showInfo"></div>
            <div class="customize-concept__info-block"
                    [ngClass]="{'customize-concept__info-block--open':processEditModal.showMoreInfo}"
                    [ngShow]="processEditModal.showInfo">
                <p class="customize-concept__info-block-title"
                    [afTranslate]="'process.edit.how-to'">¿Como diseñar un proceso?</p>
                <ul class="customize-concept__info-steps-block">
                    <li class="customize-concept__info-step">
                        <i class="lf-icon-number-one customize-concept__icon-step"></i>
                        <span [afTranslate]="'process.edit.how-to-1-title'">Definir el proceso</span>
                    </li>
                    <li class="customize-concept__info-step">
                        <i class="lf-icon-number-two customize-concept__icon-step"></i>
                        <span [afTranslate]="'process.edit.how-to-2-title'">Definir estados</span>
                    </li>
                    <li class="customize-concept__info-step">
                        <i class="lf-icon-number-three customize-concept__icon-step"></i>
                        <span [afTranslate]="'process.edit.how-to-3-title'">Definir transiciones</span>
                    </li>
                    <li class="customize-concept__info-step">
                        <i class="lf-icon-number-four customize-concept__icon-step"></i>
                        <span [afTranslate]="'process.edit.how-to-4-title'">Finalizar</span>
                    </li>
                </ul>
            </div>
        </div>
      </div>
      <button type="submit" class="common__button common__button--active float-end"
          (click)="handleDismiss()"
          [afTranslate]="'common.finish'"></button>
  </footer>
</div>
