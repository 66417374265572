<div class="duplicate-modal">
  <div class="row common__height-100">
      <div class="col-md-12 common__height-100">
          <div class="common__modal-header-block">
              <i class="lf-icon-paste common__icon-header"></i>
              <span class="common__text-header"
                  [afTranslate]="'duplicate-modal.duplicate-element'">Duplicar elemento</span>
              <i class="lf-icon-close common__icon-close-modal"
                  (click)="handleDismiss()"></i>
          </div>
          <div class="duplicate-modal__content">
              <div class="duplicate-modal__block-option">
                  <div class="duplicate-modal__block-field-text">
                      <field-type-text-simple [field]="duplicateModal.nameElement"
                          [preSelectItem]="duplicateModal.name"
                          (itemSelected)="itemSelected($event)">
                      </field-type-text-simple>
                  </div>
                  <div class="duplicate-modal__block-conservate-content">
                      <field-type-switch [label]="'duplicate-modal.preserve-content'|afTranslate"
                                          [checked]="duplicateModal.copydata"
                                          (callback)="duplicateModal.copydata = $event.value">
                      </field-type-switch>
                  </div>
                  <div class="duplicate-modal__block-conservate-content">
                      <field-type-switch [label]="'duplicate-modal.keep-reference'|afTranslate"
                                          [checked]="duplicateModal.copyreference"
                                          (callback)="duplicateModal.copyreference = $event.value">
                      </field-type-switch>
                  </div>
              </div>
              <div class="duplicate-modal__block-evaluation ">
                  <span class="duplicate-modal__subtitle-text"
                      [afTranslate]="'duplicate-modal.new-element-location'">UBICACIÓN DEL NUEVO ELEMENTO</span>
                  <div class="duplicate-modal__block-title-product"
                      (click)="productSelected()">
                      <i class="lf-icon-hamburger-menu"></i>
                      <span class="duplicate-modal__title-product"
                          [ngClass]="{'duplicate-modal__title-product--selected':duplicateModal.selectedEvaluation}">
                          {{duplicateModal.productName}}
                      </span>
                  </div>
                  <div *ngFor="let evaluation of evaluationList">
                      <div class="duplicate-modal__head-modal">
                          <i class="duplicate-modal__icon-arrow"
                              [ngClass]="{'lf-icon-angle-down':evaluation.show ,
                              'lf-icon-angle-right':!evaluation.show ,
                              'duplicate-modal__icon-arrow--visibility':evaluation.children.length}"
                              (click)="evaluation.show = !evaluation.show"></i>
                          <div class="duplicate-modal__block-name-evaluation"
                              (click)="optionSelected(evaluation)"
                              [ngClass]="{'duplicate-modal__block-name-evaluation--locked': duplicateModal.lock}">
                              <i [class]="'duplicate-modal__icon duplicate-modal__icon--evaluation '+evaluation.icon"></i>
                              <span class="duplicate-modal__title-evaluation"
                                  [ngClass]="{'duplicate-modal__title-selected':evaluation.selected}">
                                  {{evaluation.name}}
                              </span>
                          </div>
                      </div>
                      <branch-tree [childrenList]="evaluation.children"
                          [product]="duplicateModal.product"
                          [type]="'duplicate'"
                          [level]="'first'"
                          (selectChildrenEmit)="selectChildrenOn($event)"
                          [ngShow]="evaluation.show">
                      </branch-tree>
                  </div>
              </div>
          </div>

          <div class="common__buttons-block common__buttons-block--modal">
              <button type="reset"
                  class="common__button common__button--clean"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">Cancelar </button>
              <button type="submit"
                  class="common__button common__button--active"
                  (click)="checkName()"
                  [afTranslate]="'common.duplicate'">Duplicar</button>
          </div>
      </div>
  </div>
</div>
