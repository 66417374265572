import { Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';


@Component({
  selector: 'duplicate-documentation-step',
  templateUrl: './duplicate-documentation-step.component.html'
})
export class DuplicateDocumentationStepComponent implements OnChanges {
  pageSize:any = 10;
  @Input() filterConceptList: any = [];
  @Input() duplicateDocumentation: any;
  @Input() currentPage: any;
  @Output() sendAction =  new EventEmitter();

  ngOnChanges(): void {
   console.log(this)
  }

  selectInstance(instance:any){
    this.sendAction.emit({
          action: 'selectInstance',
          instance: instance
    })
  }

  orderBySearch(e:any, type:any){
    this.sendAction.emit({
          action: 'orderBySearch',
          type: type,
          event: e
    })
  }

  orderDate(type:any){
    this.sendAction.emit({
          action: 'orderDate',
          type: type
    })
  }

}
