<div class="box-queries">
    <div class="box-queries__block-body">
        <div class="box-queries__header">
            <div class="box-queries__header-upper">
                <div class="box-queries__title">
                    <i class="box-queries__title-icon lf-icon-news-communication"></i>
                    <span class="box-queries__title-text"
                        [afTranslate]="'box-queries.title'"></span>
                </div>
                <div class="box-queries__actions">
                    <i class="box-queries__new lf-icon-add-round"
                      matTooltip="{{'box-queries.new-query' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="newQuery()">
                    </i>
                    <span class="box-queries__count"
                        [ngShow]="boxQueries.queries?.length">
                        {{boxQueries.queries?.length}}
                    </span>
                </div>
            </div>
        </div>
        <p class="box-queries__no-queries"
            [ngShow]="!boxQueries.queries?.length"
            [afTranslate]="'box-queries.no-queries'">_No hay consultas</p>
        <div class="box-queries__body"
            [ngShow]="boxQueries.queries?.length">
            <div class="box-queries__header-lower">
                <span [afTranslate]="boxQueries.readMore?'box-queries.all-user-queries':'box-queries.subtitle'">
                    _Últimas 5 consultas del usuario
                </span>
            </div>
            <div class="box-queries__query"
                *ngFor="let query of boxQueries.queries | afOrderBy:'creationDate':true | afLimitTo:boxQueries.readMore?boxQueries.queries?.length:5; let index = index">
                <i class="box-queries__query-icon lf-icon-compliance"></i>
                <div class="box-queries__query-body">
                    <div class="box-queries__query-reference">{{query.referencia}}</div>
                    <div [id]="'query-'+index+'-title'"
                          class="box-queries__query-title"
                          [matTooltip]="query.title"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          [matTooltipDisabled]="showQueryTooltip('query-'+index+'-title')">
                          <span [id]="'span-query-'+index+'-title'">{{query.title}}</span>
                    </div>
                    <div [id]="'query-'+index+'-description'"
                          class="box-queries__query-description"
                          [matTooltip]="query.consulta"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          [matTooltipDisabled]="showQueryTooltip('query-'+index+'-description')">
                          <span id="'span-query-'+index+'-description-span'">{{query.consulta}}</span>
                    </div>
                </div>
                <ul class="box-queries__query-options">
                    <li class="attachments-template__menu-options">
                        <i class="lf-icon-editable-form"
                            [matTooltip]="'common.edit' | afTranslate"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            (click)="editQuery(query)">
                        </i>
                    </li>
                </ul>
            </div>
        </div>
        <div class="box-queries__footer">
            <div class="box-queries__more-button"
                [ngShow]="boxQueries.queries?.length > 5 && !boxQueries.readMore">
                <i class="box-queries__more-button-icon lf-icon-visible"></i>
                <span class="box-queries__more-button-text"
                    [afTranslate]="'box-queries.more-button'"
                    (click)="boxQueries.readMore = true">
                </span>
            </div>
        </div>
    </div>
</div>
