
<div class="attachments-template attachments-template--custom"
    [ngShow]="processRecord.transitionsList?.length">
    <div class="attachments-template__custom-header">
        <i class="lf-icon-track attachments-template__custom-add-icon"></i>
        <p class="attachments-template__custom-header-title"
            [afTranslate]="'process.summary.history-title'"></p>
        <i class="predefined-templates__change-view-icon attachments-template__custom-maximize-icon"
            (click)="maximizeScreen()"
            matTooltip="{{processRecord.tooltipFullScreen}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [ngClass]="processRecord.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"></i>
    </div>
    <ul class="process-record__custom-block-transitions">
        <li *ngFor="let transition of processRecord.transitionsList | afOrderBy: ['transitionDate']:false | afLimitTo:processRecord.limitToList; let index = index">
            <span class="process-record__state-element process-record__state-element--custom"
                [ngStyle]="{'background-color':custom.color,
                    'border-color':custom.color,
                    'color': custom.textColor}">{{transition.stateName}}</span>
            <div class="process-record__custom-date-block-position"
                [ngStyle]="{'border-color':custom.color}">
                <span class="process-record__custom-date-text">{{transition.transitionDate | convertDate:'without-time'}}</span>
                <span class="process-record__custom-time-text">{{transition.transitionDate | convertDate:'only-time'}}h</span>
            </div>
            <button class="box-comments__read-more-block process-record__custom-read-more-block"
                (click)="maximizeScreen()"
                [ngShow]="!processRecord.fullScreen && processRecord.transitionsList?.length > 2 && index === 1">
                <i class="lf-icon-visible box-comments__read-more-icon"></i>
                <span class="box-comments__read-more-text"
                    [afTranslate]="'comments.read-more'">Leer más</span>
            </button>
        </li>
    </ul>
</div>
