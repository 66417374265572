import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'users-admin-edit',
  templateUrl: './users-admin-edit.component.html'
})
export class UsersAdminEditComponent implements OnInit {
  id: any = this.route.snapshot.params['id'];
  operation: string = this.route.snapshot.data['operation'];
  type: string = this.id?this.route.snapshot.params['type']:this.route.snapshot.data['type'];
  usersAdminEdit: any = {
    isAdmin: false,
    readonly: this.operation === 'see-security',
    typeIcon: this.personTypeIcon(),
    title: this.translate.instant('users-admin.new.title.' + this.type),
    controller: this.getControllerByType(this.type),
    viewManage: 'permissions'
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService) { }

  ngOnInit(): void {
    if(this.operation === 'new'){
        this.usersAdminEdit.model = {
          clients: [],
          groups: []
        }
        this.closeLoader();
    } else {
        this.loadModel();
    }
  }

  personTypeIcon(){
    if(this.type==='group'){
      return 'lf-icon-group';
    }else if(this.type==='user'){
      return 'lf-icon-user-special';
    }else{
      return 'lf-icon-user';
    }
  }

  getControllerByType(type: any) {
    switch (type) {
        case 'user':
            return 'frontusers';
        case 'contact':
            return 'contacts';
        case 'group':
            return 'usersgroups';
        default:
            throw 'Unknow type ' + type;
    }
  }

  loadModel() {
      let url = this.id || 0;
      if(this.operation === 'security' || this.operation === 'see-security'){
          url += '/security';
      }
      this.apiService.get(this.usersAdminEdit.controller, url)
        .then((data:any)=>{
          this.usersAdminEdit.title = data.fullName;
          if(data.isAdmin){
              this.usersAdminEdit.readonly = true;
              this.usersAdminEdit.isAdmin = true;
          }
          let model = data;
          if(this.type === 'group' && this.operation === 'edit'){
              model.members = [];
              data.users.forEach((user:any) => {
                  model.members.push(user);
              });
              data.contacts.forEach((contact:any) => {
                  model.members.push(contact);
              });
              delete data.users;
              delete data.contacts;
          }
          this.usersAdminEdit.model = model;
          this.closeLoader();
        }, (error:any)=>{
          this.loader.closeError();
          this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
        })
  }

  changeViewManage(option:string){
      this.usersAdminEdit.viewManage = option;
  }

  save(){
      if(this.operation === 'security'){
          this.broadcastService.broadcast('savePerson');
      }else if(this.operation === 'new' || this.operation === 'edit' || this.operation === 'edit-basic'){
        if(this.type === 'group'){
          this.broadcastService.broadcast('checkGroupForm');
        }else{
          this.broadcastService.broadcast('checkPersonForm');
        }
      }
  }

  saveSecurity(security: any){
      if(security.any){
        delete security.any;
        this.loader.openLoader('edit-security');
        this.apiService.patch(this.usersAdminEdit.controller, this.id, { security: security })
          .then((data:any)=>{
              this.loader.closeLoader(['edit-security']);
              let messageLiteralKey = (this.id ? 'users-admin.edit.success.' : 'users-admin.new.success.') + this.type;
              let text = this.translate.instant(messageLiteralKey);
              this.modalService.adviceModal(text, 'info:accept:check-round')
                .subscribe((result:any)=>{
                    this.router.navigate(['users']);
                })
          }, (error:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
          })
      }else{
        this.modalService.adviceModal(this.translate.instant('users-admin.security-form.product-mandatory'), 'error:accept:warning')
      }
  }

  savePerson() {
      let model = cloneDeep(this.usersAdminEdit.model);
      let addGroups = model.addGroups;
      delete model.fullName;
      delete model.addGroups;
      if(this.type !== 'group'){
          if(this.type === 'contact'){
              model.clients = [];
              if(this.usersAdminEdit.model.clients && this.usersAdminEdit.model.clients.length){
                  this.usersAdminEdit.model.clients.forEach((clientId:any) => {
                      model.clients.push({ clientId: clientId });
                  });
              }
          }
          model.groups = [];
          if(addGroups && this.usersAdminEdit.model.groups && this.usersAdminEdit.model.groups.length){
              this.usersAdminEdit.model.groups.forEach((groupId:any) => {
                  model.groups.push({ groupId: groupId });
              });
          }
      }else{
          model.contacts = [];
          model.users = [];
          if(this.usersAdminEdit.model.members && this.usersAdminEdit.model.members.length){
              this.usersAdminEdit.model.members.forEach((member:any) => {
                  if(member.userId !== undefined){
                      model.users.push(member);
                  }else{
                      model.contacts.push(member);
                  }
              });
          }
          delete model.members;
      }
      this.loader.openLoader('save-person');
      let apiCallPromise : any = this.id ? this.apiService.update(this.usersAdminEdit.controller, '', model) : this.apiService.add(this.usersAdminEdit.controller, model);

      apiCallPromise.then((data:any)=>{
        this.loader.closeLoader(['save-person']);
        if(this.usersAdminEdit.model.groups && this.usersAdminEdit.model.groups.length && !this.usersAdminEdit.model.addGroups){
            this.broadcastService.broadcast('changeGroupsSaved');
        }
        if (this.type === 'user' && this.context.user.userId === this.usersAdminEdit.model.userId) {
            this.context.user.fullName = this.usersAdminEdit.model.name + ' ' + this.usersAdminEdit.model.surname;
            this.context.user.phoneNumber1 = this.usersAdminEdit.model.phoneNumber1;
            this.context.user.phoneNumber2 = this.usersAdminEdit.model.phoneNumber2;
            let newContext : any = {
                jwtToken: this.context.jwtToken,
                user: this.context.user
            }
            // Revisar el context porque falla en angular
            this.context.setLogin(newContext);
        }
        let text : any = null;
        let messageLiteralKey : any = (this.id ? 'users-admin.edit.success.' : 'users-admin.new.success.') + this.type;
        text = this.translate.instant(messageLiteralKey);
        this.modalService.adviceModal(text, 'info:accept:check-round')
          .subscribe((result:any)=>{
              if(this.operation === 'edit-basic'){
                  this.changeViewWindow();
              }else{
                  this.router.navigate(['users']);
              }
          })
      }, (error:any)=>{
        let errorLiteralKey : any = null;
        if(error.status === 409 && error.description === 'AlreadyExists'){
            errorLiteralKey = 'users-admin.new.error-existing.' + this.type;
        }else if(error.status === 500 && error.description === 'SendingCredentials'){
            errorLiteralKey = 'users-admin.new.error-credentials-email';
        }else{
            errorLiteralKey = 'common.unexpected-error';
        }
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant(errorLiteralKey), 'error:accept:warning');
      })
  }

  cancel(){
    if(this.operation === 'edit-basic'){
      this.router.navigate(['dashboard']);
    }else{
      this.router.navigate(['/users']);
    }
  }

  reset(){
    if(this.operation==='security' || this.operation==='see-security'){
      this.broadcastService.broadcast('resetSecurityForm');
    }else if(this.type === 'group'){
      this.broadcastService.broadcast('resetGroupForm');
    }else{
      this.broadcastService.broadcast('resetPersonForm');
    }
  }

  changeViewWindow() {
      this.commonUtilsService.goToStateUrl();
  }

  closeLoader(){
    this.loader.closeLoader(['menu-header-user-edit-user', 'front-login-login']);
  }
}
