import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'relationship-reason-modal',
  templateUrl: './relationship-reason-modal.component.html'
})
export class RelationshipReasonModalComponent implements OnInit {
  custom: any = this.customLanding.getCustom()
  relationshipReasonModal:any = {
    reasonInfo: {
      fieldId: 'reason-info',
      required:  true,
      description: null,
      multiline: true,
      label: this.translate.instant('common.description'),
      expanded: true
    }
  }

  constructor(private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private activeModal: NgbActiveModal) { }

  @Input() text: any = null;

  ngOnInit(): void {
    this.relationshipReasonModal.reason = this.text;
  }

  itemSelected(e:any, item:string){
    this.relationshipReasonModal[item] = e.newValue;
  }

  handleAccept() {
    this.activeModal.close({
        result:'ok',
        relationshipReason: this.relationshipReasonModal.reason
    })
  }

  handleDismiss() {
    this.activeModal.close({
        result: 'cancel',
        // relationshipReason: this.text
    })
  }
}
