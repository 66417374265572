import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalCfgFactory {
  CountryApi: string;
  documentLibraryName: string;
  AddinFooterText: string ;
  NotificationsPrimaryColor: string ;
  NotificationsHeaderLogoUri: string ;
  NotificationsHeaderLogoAlt: string ;
  NotificationsFooterLogoUri: string ;
  NotificationsFooterLogoAlt: string ;
  NotificationsFooterLogoLink: string ;
  NotificationsShowNetworksLinks: string ;
  NotificationsCopyright: string ;
  PdfViewerSiteId: string ;
  defaultProduct:string;
  skin: string;
  dashboardVersion: string;
  ssoLoginUrl: any;
  ssoLogoutUrl: any;
  ssoPasswordResetUrl: any;
  ssoErrorNoAccessUrl: any;
  ssoErrorUrl: any;
  externalPublicLoginUrl: any;
  externalPublicLogoutUrl: any;
  addinFooterText: string;
  caps: any = {};
  encuestaFrontConceptId: number;
  loaded: boolean = false;
  setGlobalCfg(data:any){
    this.CountryApi = data.countryApi;
    this.documentLibraryName = data.documentLibraryName;
    this.AddinFooterText  = data.addinFooterText;
    this.NotificationsPrimaryColor = data.notificationsPrimaryColor;
    this.NotificationsHeaderLogoUri = data.notificationsHeaderLogoUri;
    this.NotificationsHeaderLogoAlt = data.notificationsHeaderLogoAlt;
    this.NotificationsFooterLogoUri = data.notificationsFooterLogoUri;
    this.NotificationsFooterLogoAlt = data.notificationsFooterLogoAlt;
    this.NotificationsFooterLogoLink = data.notificationsFooterLogoLink;
    this.NotificationsShowNetworksLinks = data.notificationsShowNetworksLinks;
    this.NotificationsCopyright = data.notificationsCopyright;
    this.PdfViewerSiteId = data.pdfViewerSiteId;
    this.defaultProduct = data.defaultProduct;
    this.skin = data.skin;
    this.dashboardVersion = data.dashboardVersion;
    this.ssoLoginUrl = data.ssoLoginUrl;
    this.ssoLogoutUrl = data.ssoLogoutUrl;
    this.ssoPasswordResetUrl = data.ssoPasswordResetUrl;
    this.ssoErrorNoAccessUrl = data.ssoErrorNoAccessUrl;
    this.ssoErrorUrl = data.ssoErrorUrl;
    this.externalPublicLoginUrl = data.externalPublicLoginUrl;
    this.externalPublicLogoutUrl = data.externalPublicLogoutUrl;
    this.caps = data.caps;
    this.encuestaFrontConceptId = data.encuestaFrontConceptId;
    this.loaded = true;
  }
}
