<div class="historic-concept common__block-concept"
    [ngClass]="{'common__block-concept--border': currentComponent === 'concept'}">
   <div class="historic-concept__historic-header"
      [ngClass]="{'historic-concept__historic-header--access-historic': historicConcept.showAccessHistoric}">
      <div class="documentation-list__menu-block documentation-list__menu-block--right"
          [ngShow]="!historicConcept.showAccessHistoric">
          <button class="common__button-border-none historic-concept__button-border-none"
              [ngShow]="historicConcept.historicAccessList?.length"
              (click)="changeViewList()">
              <i class="lf-icon-transparency-report common__icon-button-underlined"></i>
              <span class="common__text-button-underlined"
                  [afTranslate]="'historic-concept.show-access'">_Ver registro de acceso</span>
          </button>
          <button class="lf-icon-send common__button-checked"
              (click)="exportList()"
              matTooltip="{{'historic-concept.export-historic' | afTranslate}}"
              matTooltipPosition="below"
              matTooltipClass="below">
          </button>
      </div>

      <div [ngShow]="historicConcept.showAccessHistoric"
          class="historic-concept__access-head-block">
          <button class="historic-concept__back-button"
              (click)="changeViewList()">
              <i class="lf-icon-arrow-back folder__arrow-back-icon"></i>
              <span class="folder__arrow-back-text"
                  [afTranslate]="'historic-concept.exit-access'">_Salir del registro de acceso a la instancia</span>
          </button>
          <p class="historic-concept__access-count">
              <span [afTranslate]="'historic-concept.number-access'">_Se ha accedido a esta instancia</span>
              {{historicConcept.historicAccessList?.length}}
              <span [afTranslate]="'historic-concept.times'"
                  [ngShow]="historicConcept.historicAccessList?.length > 1">veces</span>
              <span [afTranslate]="'historic-concept.time'"
                  [ngShow]="historicConcept.historicAccessList?.length === 1">vez</span>
          </p>
      </div>
  </div>
  <div class="historic-concept__table historic-concept__table--top"
      [ngClass]="{'historic-concept__table--show-title':historicConcept.showTable}"
      [ngShow]="!historicConcept.showAccessHistoric">
      <div class="common__table-header">
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--element historic-concept__table-col--top-1">
              <filter-by-search
                  [item]="historicConcept.elementFilter"
                  (launchActionSearch)="orderBySearch($event, 'element')">
              </filter-by-search>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--type-operation historic-concept__table-col--top-1">
              <filter-by-list [type]="'listFind'"
                  [item]="historicConcept.operationTypeFilter"
                  [list]="historicConcept.operationTypeList"
                  (filterElementEmit)="selectTypeOperation($event, 'operationType')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--doc historic-concept__table-col--top-1">
              <filter-by-list [type]="'list'"
                  [item]="historicConcept.sectionFilter"
                  [list]="historicConcept.sectionList"
                  (filterElementEmit)="selectTypeOperation($event, 'section')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--header-date-operation">
              <div (click)="orderDate()">
                  <p class="common__table-header-text"
                      [afTranslate]="'historic-concept.operation-date'">Fecha de operación</p>
                  <i class="common__order-by common__order-by--active"
                      [ngClass]="{'lf-icon-arrow-down': historicConcept.orderOperationDates,
                          'lf-icon-arrow-up': !historicConcept.orderOperationDates}"></i>
              </div>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--user historic-concept__table-col--top-1">
              <filter-by-list [type]="'specialListFind'"
                  [item]="historicConcept.userFilter"
                  [list]="historicConcept.userList"
                  [showMyOption]="historicConcept.userFilter.showMyOption"
                  (filterElementEmit)="selectTypeOperation($event, 'user')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--menu">
              <p class="common__table-header-text common__table-header-text--actions"
                  [afTranslate]="'common.actions'">Acciones</p>
          </div>
      </div>

      <div class="historic-concept__table-body"
          [ngClass]="{'historic-concept__table-body--more-11-elements': historicConcept.filterHistoricList?.length > 11}">
          <div class="common__table-row"
              *ngFor="let historic of historicConcept.filterHistoricList | afOrderBy:['date']:historicConcept.orderOperationDates; let index = index">
              <div class="historic-concept__table-col historic-concept__table-col--element">
                  <parragraph-tooltip [text]="historic.element"></parragraph-tooltip>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--type-operation">
                  <parragraph-tooltip [text]="historic.operationType"
                    [ngClass]="{'historic-concept__cell-block-icon':historic.operationTypeId === 24}"></parragraph-tooltip>
                  <div class="historic-concept__block-icon-eye"
                      ngbDropdown
                      autoClose="'outside'"
                      [open]="historic.showDropdown"
                      *ngIf="historic.operationTypeId === 24">
                      <i class="lf-icon-visible participants-list__icon-eye"
                        ngbDropdownToggle></i>
                      <list-comparator-in-dropdown ngbDropdownMenu role="menu"
                          class="historic-concept__box-comparator-participants"
                          [list]="historic.previousParticipantsComparator"
                          [listComparator]="historic.currentParticipantsComparator"
                          [type]="'participants'"
                          [itemSelected]="historic"></list-comparator-in-dropdown>
                  </div>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--doc">
                  <parragraph-tooltip [text]="historic.section"></parragraph-tooltip>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--date-operation">
                  <parragraph-tooltip [text]="historic.date | convertDate:'all-with-second'"></parragraph-tooltip>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--user">
                  <parragraph-tooltip [text]="historic.user"></parragraph-tooltip>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--menu">
                  <ul class="common__options-row"
                      [ngShow]="historic.showActions">
                      <li class="common__menu-options-row"
                         (click)="historyViewer(historic)">
                          <i class="lf-icon-visible"
                              matTooltip="{{'common.view' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row"
                          (click)="exportEdition(historic)">
                          <i class="lf-icon-send"
                              matTooltip="{{'historic-concept.export-edition' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                      <li class="common__menu-options-row"
                          (click)="editionComparator(historic)">
                          <i class="lf-icon-comparison"
                              matTooltip="{{'historic-concept.compare editions' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                      </li>
                  </ul>
                  <i class="lf-icon-kebab-menu common__icon-ellipsis"
                      [ngShow]="historic.showActions"></i>
              </div>
          </div>
      </div>
  </div>
  <div class="historic-concept__table"
      [ngClass]="{'historic-concept__table--show-title':historicConcept.showTable}"
      [ngShow]="historicConcept.showAccessHistoric">
      <div class="common__table-header">
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--type">
              <filter-by-list [type]="'list'"
                  [item]="historicConcept.userTypeFilter"
                  [list]="historicConcept.userTypeList"
                  (filterElementEmit)="selectTypeOperation($event, 'userType')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--name-access historic-concept__table-col--top-1">
              <filter-by-list [type]="'specialListFind'"
                  [item]="historicConcept.nameFilter"
                  [list]="historicConcept.nameList"
                  [showMyOption]="historicConcept.nameFilter.showMyOption"
                  (filterElementEmit)="selectTypeOperation($event, 'name')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--type-access">
              <filter-by-list [type]="'list'"
                  [item]="historicConcept.accessTypeFilter"
                  [list]="historicConcept.accessTypeList"
                  (filterElementEmit)="selectTypeOperation($event, 'accessType')">
              </filter-by-list>
          </div>
          <div class="historic-concept__table-col historic-concept__table-col--header historic-concept__table-col--header-date-access">
              <div (click)="orderDateAccess()">
                  <p class="common__table-header-text"
                      [afTranslate]="'historic-concept.access-date'">_Fecha de acceso</p>
                  <i class="common__order-by common__order-by--active"
                      [ngClass]="{'lf-icon-arrow-down': historicConcept.orderAccessDates,
                          'lf-icon-arrow-up': !historicConcept.orderAccessDates}"></i>
              </div>
          </div>
      </div>

      <div class="historic-concept__table-body"
          [ngClass]="{'historic-concept__table-body--more-11-elements': historicConcept.filterHistoricAccessList?.length > 11}">
          <div class="common__table-row"
              *ngFor="let userAccess of historicConcept.filterHistoricAccessList | afOrderBy:['accessDate']: historicConcept.orderAccessDates; let index = index">
              <div class="historic-concept__table-col historic-concept__table-col--type">
                  <i [class]="'historic-concept__table-icon ' + (userAccess.userTypeId | taskTypeIcons:'access')"
                      matTooltip="{{userAccess.userType}}"
                      matTooltipPosition="right"
                      matTooltipClass="right">
                  </i>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--name-access">
                  <parragraph-tooltip [text]="userAccess.name"></parragraph-tooltip>
              </div>
              <div class="historic-concept__table-col historic-concept__table-col--type-access">
                  <parragraph-tooltip [text]="userAccess.accessType"></parragraph-tooltip>

              </div>
              <div class="historic-concept__table-col historic-concept__table-col--date-access">
                  <parragraph-tooltip [text]="userAccess.accessDate | convertDate:''"></parragraph-tooltip>
              </div>
          </div>
      </div>
  </div>
</div>
<history-viewer *ngIf="historicConcept.historyViewerData"
    class="common__hide"
    [historyViewerData]="historicConcept.historyViewerData"></history-viewer>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="historicConcept.configurationPdf"></pdf-table>
<form action="historic.aspx" target="_blank" method="post" auto-data="historicConcept.compareVersions" submit="historicConcept.openCompareVersions"></form>
