import $ from 'jquery';
import { Injectable } from '@angular/core';
import { GlobalCfgFactory } from '../global-cfg/global-cfg.factory';

@Injectable({
  providedIn: 'root'
})
export class LoaderFactory {
  constructor(private globalCfg: GlobalCfgFactory) { }

  open:boolean = false;
  from:string = '';

  openLoader(from:string) {
    if(!this.open){
      this.from = from;
      this.open = true;
      this.openSpinner();
    }
  }

  closeLoader(loaders:any) {
    if(!Array.isArray(loaders)){
      loaders = [loaders];
    }
    loaders.forEach((from:any) => {
      if(this.open && this.from === from){
        this.closeSpinner();
      }
    });
  }

  closeError() {
    this.closeSpinner();
  }

  openFrom(from: string):boolean {
    return (this.from === from);
  }

  openSpinner(){
    let icon = this.globalCfg.skin === 'dark-blue' ? 'lf-icon-lefebvre' : 'lf-icon-spinner';
    $('body').append(
      '<div id="loader-spinner" class="modal-backdrop fade show" style="z-index:99999">' +
        '<i class="' + icon + ' common__preloader-icon common__preloader-icon--' + this.globalCfg.skin + '"></i>' +
      '</div>'
    );
  }

  closeSpinner(){
    this.from = '';
    this.open = false;
    $('#loader-spinner').remove();
  }
}
