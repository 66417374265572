<div class="new-custom-report-configuration__block-body">
  <field-type-rich-text class="new-custom-report-configuration__block-info"
      [field]="newCustomReportConfiguration.identityInfo"
      *ngIf="newCustomReportConfiguration.identityInfo">
  </field-type-rich-text>
  <div class="new-custom-report-configuration__report-fields-selection">
      <div class="new-custom-report-configuration__available-fields-container">
          <div class="new-custom-report-configuration__table-container-header">
              <i class="lf-icon-localization new-custom-report-configuration__table-container-header-icon"></i>
              <span class="new-custom-report-configuration__table-container-header-text" [afTranslate]="'new-custom-report.available-report-fields'"></span>
          </div>
          <div class="new-custom-report-configuration__table-container">
              <div class="new-custom-report-configuration__table new-custom-report-configuration__table--available">
                  <div class="new-custom-report-configuration__list">
                      <div class="common__table-header">
                          <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--origin-report new-custom-report-configuration__table-col--top-1">
                              <filter-by-list [type]="'list'"
                                              [item]="newCustomReportConfiguration.isCustomFilter"
                                              [list]="newCustomReportConfiguration.isCustomList"
                                              (filterElementEmit)="selectTypeOperation($event, 'isCustom')">
                              </filter-by-list>
                          </div>
                          <div ngbDropdown class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--name-report new-custom-report-configuration__table-col--top-2">
                              <p ngbDropdownToggle class="common__table-header-text common__table-header-text--without-cursor"
                                  [afTranslate]="'custom-report-list.header-table.report-name'"></p>
                              <i ngbDropdownToggle class="common__filter-icon lf-icon-filter-1"
                                  [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': newCustomReportConfiguration.reportFieldNameWordFilter.length}"></i>
                              <i class="lf-icon-arrow-down common__order-by"
                                  [ngClass]="{'lf-icon-arrow-up':newCustomReportConfiguration.productNameOrder === 'reportFieldName' && newCustomReportConfiguration.orderOperationProductName === false,
                                    'common__order-by--active': newCustomReportConfiguration.productNameOrder === 'reportFieldName'}"
                                  (click)="orderProductName('reportFieldName')"></i>
                              <ul ngbDropdownMenu class="common__select-list-with-input">
                                  <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                                      <input class="common__dropdown-inner-input"
                                              (keyup)="filter('reportFieldName')"
                                              [placeholder]="'custom-report-field-list.search.report-field-name'|afTranslate"
                                              [(ngModel)]="newCustomReportConfiguration.reportFieldNameWordFilter"
                                              [focusWhen]="newCustomReportConfiguration.openFocusTitle">
                                      <i class="lf-icon-search common__search-icon"
                                          [ngClass]="newCustomReportConfiguration.reportFieldNameWordFilter === '' ? 'lf-icon-search' : 'lf-icon-close-search'"
                                          (click)="resetFilter($event, 'reportFieldName')"></i>
                                  </li>
                              </ul>
                          </div>
                          <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--action-report">
                              <p class="common__table-header-text common__table-header-text--right" [afTranslate]="'common.actions'"></p>
                          </div>
                      </div>
                      <div class="new-custom-report-configuration__block-table"
                          [ngClass]="{'new-custom-report-configuration__block-table--more-elements': newCustomReportConfiguration.filterAvailableReportFieldList?.length > 10}">
                          <div class="common__table-row new-custom-report-configuration__available-field"
                              [ngClass]="{'new-custom-report-configuration__field-selected': availableReportField.selected}"
                              *ngFor="let availableReportField of newCustomReportConfiguration.filterAvailableReportFieldList | afOrderBy:newCustomReportConfiguration.productNameOrder:newCustomReportConfiguration.orderOperationProductName"
                              (click)="getSelectedItemsIncluding(newCustomReportConfiguration.availableReportFieldList, availableReportField)"
                              [id]="availableReportField.reportFieldId">
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--origin-report">
                                  <div class="common__cell-block">
                                      <i *ngIf="!availableReportField.isCustom"
                                        class="lf-icon-tag new-custom-report-configuration__icon-type"></i>
                                      <i *ngIf="availableReportField.isCustom"
                                        class="lf-icon-tag-user new-custom-report-configuration__icon-type"></i>
                                  </div>
                              </div>
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--name-report">
                                  <div class="common__cell-block">
                                    <span-tooltip [text]="availableReportField.reportFieldName"></span-tooltip>
                                  </div>
                              </div>
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--action-report">
                                  <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--actions">
                                      <ul class="common__options-row">
                                          <li class="common__menu-options-row"
                                              (click)="showFieldAssignments($event, availableReportField)">
                                              <i class="lf-icon-arrow-exchange"></i>
                                          </li>
                                      </ul>
                                  </div>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="new-custom-report-configuration__buttons-container">
          <button class="lf-icon-angle-right new-custom-report-configuration__field-button" (click)="addReportFields()"></button>
          <button class="lf-icon-angle-left new-custom-report-configuration__field-button new-custom-report-configuration__field-button--remove"
                  (click)="removeReportFields()"></button>
      </div>
      <div class="new-custom-report-configuration__fields-selected-container">
          <div class="new-custom-report-configuration__table-container-header">
              <i class="lf-icon-table-display new-custom-report-configuration__table-container-header-icon"></i>
              <span class="new-custom-report-configuration__table-container-header-text" [afTranslate]="'new-custom-report.new-report-fields'"></span>
          </div>
          <div class="new-custom-report-configuration__table-container">
              <div class="new-custom-report-configuration__table new-custom-report-configuration__table--selected"
                   [ngClass]="{'new-custom-report-configuration__table--empty': !newCustomReport.selectedReportFields.length}">
                  <div class="new-custom-report-configuration__list">
                      <div class="common__table-header"
                           [ngShow]="newCustomReport.selectedReportFields.length">
                          <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--origin-report">
                              <p class="common__table-header-text" [afTranslate]="'custom-report-list.header-table.source'"></p>
                          </div>
                          <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--name-report">
                              <p class="common__table-header-text" [afTranslate]="'custom-report-list.header-table.report-name'"></p>
                          </div>
                          <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--action-report">
                              <p class="common__table-header-text common__table-header-text--right" [afTranslate]="'common.actions'"></p>
                          </div>
                      </div>
                      <div class="new-custom-report-configuration__block-table"
                          [ngClass]="{'new-custom-report-configuration__block-table--more-elements': newCustomReport.selectedReportFields.length > 10}">
                          <div class="common__table-row new-custom-report-configuration__available-field"
                              [ngClass]="{'new-custom-report-configuration__field-selected': selectedReportField.selected}"
                              *ngFor="let selectedReportField of newCustomReport.selectedReportFields; let $index = index"
                              (click)="getSelectedItemsIncluding(newCustomReport.selectedReportFields, selectedReportField)"
                              [id]="selectedReportField.reportFieldId"
                              [ngShow]="selectedReportField.reportFieldId">
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--origin-report">
                                  <div class="common__cell-block">
                                      <i *ngIf="!selectedReportField.isCustom"
                                        class="lf-icon-tag new-custom-report-configuration__icon-type"></i>
                                      <i *ngIf="selectedReportField.isCustom"
                                        class="lf-icon-tag-user new-custom-report-configuration__icon-type"></i>
                                  </div>
                              </div>
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--name-report">
                                  <div class="common__cell-block">
                                    <span-tooltip [text]="selectedReportField.reportFieldName"></span-tooltip>
                                  </div>
                              </div>
                              <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--action-report">
                                  <div class="new-custom-report-configuration__table-col new-custom-report-configuration__table-col--actions">
                                      <ul class="common__options-row">
                                          <li class="common__menu-options-row"
                                              (click)="showFieldAssignments($event, selectedReportField)">
                                              <i class="lf-icon-arrow-exchange"></i>
                                          </li>
                                      </ul>
                                  </div>
                                  <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                                  <!-- <i class="lf-icon-arrows common__icon-ellipsis new-custom-report-configuration__move-icon"></i> -->
                              </div>
                          </div>
                      </div>
                      <!-- <div class="new-custom-report-configuration__empty-box"
                           [ngHide]="newCustomReport.selectedReportFields.length">
                          <p class="new-custom-report-configuration__empty-text" [afTranslate]="'new-custom-report.disabled-text'"></p>
                          <i class="lf-icon-drag-drop new-custom-report-configuration__empty-icon"></i>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="new-custom-report-configuration__labels-container">
      <field-type-switch [label]="'new-custom-report.column-concept-identification'|afTranslate"
                           [checked]="newCustomReport.conceptsInFirstColumn"
                           (callback)="newCustomReport.conceptsInFirstColumn = $event.newValue">
      </field-type-switch>
      <advice-message [ngShow]="validationErrors.configuration"
          [icon]="'warning'"
          [type]="'error'"
          [text]="newCustomReportConfiguration.infoError"
          [typeClass]="'no-margin'"
          (closeAdvice)="validationErrors.configuration = false">
      </advice-message>
  </div>
</div>
