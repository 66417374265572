import { Component, Input, OnInit } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintService } from '@shared/services/print/print.service';

@Component({
  selector: 'law-info',
  templateUrl: './law-info.component.html'
})
export class LawInfoComponent implements OnInit {
  isLanding: boolean = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  lawInfo: any = {
    selectedReference: null,

  }
  constructor(private context: ContextFactory,
    private customLanding: CustomLandingFactory,
    private apiProperties: ApiPropertiesFactory,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private print: PrintService) { }
  @Input() references: any = null;
  @Input() selectReferenceList: any = null;
  @Input() selected: any = null;
  @Input() registerEvent: any = null;
  ngOnInit(): void {
    this.lawInfo.selected =this.selected? this.selected : this.selectReferenceList[0];
  }

  printAction(){
    this.print.printContent('legal-document-print', this.lawInfo.selected.text);
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

  openDB() {
    let onlineUrl:any = this.apiProperties.onlineFormUrl.replace(/\{0\}/g, this.context.user.externalAccessId);
    let targetTokens = this.lawInfo.selected.target.split('|');
    onlineUrl = onlineUrl.replace(/\{1\}/g, targetTokens[2]);
    onlineUrl = onlineUrl.replace(/\{2\}/g, targetTokens[1]);
    if (this.route.snapshot.params['conceptObjectId']) {
        onlineUrl = onlineUrl.replace(/\{3\}/g, this.route.snapshot.params['conceptObjectId'].conceptObjectId);
    }
    window.open(onlineUrl);
  }


}
