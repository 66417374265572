import $ from 'jquery';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ActivatedRoute } from '@angular/router';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { EvaluationInfoFactory } from '@shared/factories/evaluation-info/evaluation-info.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'rename-modal',
  templateUrl: './rename-modal.component.html'
})
export class RenameModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  custom:any = this.customLanding.getCustom();
  isLanding:boolean = this.customLanding.isLanding();
  evaluationId:any = this.route.snapshot.params['evaluationId'] || this.route.snapshot.firstChild?.params['evaluationId'];
  limitExceeded: boolean = false;
  renameModal:any ={
    iconHeader: 'lf-icon-rename',
    infoDescription: '',
    showInfoText: false,
    whistleProduct: this.skin === 'steel-blue' && this.context.settings.defaultProduct === 'whistle',
    nameInfo: {
        fieldId: 'name-info',
        required:  true,
        description: null,
        addEnter:true
    },
    suffixInfo: {
        fieldId: 'suffix-info',
        noEditable: true,
        label: this.translate.instant('rename-modal.suffix-label')
    },
    errorName: false,
    nameList: [],
    buttonAccept: this.translate.instant('common.ok'),
    title: '',
    extension:'',
    errorType:'',
    suffix:'',
    errorNameDocument:false,
  }
  constructor( private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private route: ActivatedRoute,
    private validateDocumentService: ValidateDocumentService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory,
    private context: ContextFactory,
    private evaluationInfo: EvaluationInfoFactory) { }

  @Input() newItem: any = null;
  @Input() list: any = null;
  @Input() otherList: any = null;

  ngOnInit(): void {
    this.configureTranslates();
    this.configureCalls();
    if(this.newItem.type === 'related-forms'){
      this.renameModal.iconHeader = 'lf-icon-compliance'
    }else if(this.newItem && this.newItem.icon){
      this.renameModal.iconHeader = this.newItem.icon;
    }
    setTimeout(()=>{
        let element: any = $('#field-item-text-name-info');
        element.focus().select();
    })
  }

  configureTranslates(){
    switch(this.newItem.type){
      case 'public':
          this.renameModal.nameInfo.label = this.translate.instant('common.affair');
          break;
      case 'concept':
          this.renameModal.nameInfo.label = this.translate.instant('rename-modal.name-instance');
          if (this.skin === 'icam-red' && this.newItem.namedKey === 'Usuario') {
            this.renameModal.nameInfo.label = "Id Usuario"
            this.renameModal.name = 'C######'
          }
          break;
      case 'evaluation':
          this.renameModal.nameInfo.label = this.translate.instant('rename-modal.title-new');
          break;
      case 'title':
          this.renameModal.nameInfo.label = this.translate.instant('rename-modal.new-title');
          break;
      case 'upload':
          this.renameModal.nameInfo.label= this.translate.instant('rename-modal.name-document');
          this.newItem.title = this.translate.instant('documentation-list.rename-document');
          break;
      case 'related-forms':
          this.renameModal.nameInfo.label = this.translate.instant('rename-modal.name-instance');
          this.newItem.title = this.translate.instant('rename-modal.new-form-related');
          this.renameModal.infoDescription = this.translate.instant('rename-modal.info-new-report');
          break;
      case 'report':
          if(this.newItem.from === 'generateReport'){
              this.renameModal.infoDescription = this.translate.instant('rename-modal.info-new-report').replace('{title}',  this.newItem.titleConcept);
          }
          this.renameModal.nameInfo.label= this.translate.instant('rename-modal.name-'+this.newItem.type);
          break;
      default:
          this.renameModal.nameInfo.label= this.translate.instant('rename-modal.name-'+this.newItem.type);
          break;
    }
    if(this.newItem.subtype && (this.newItem.subtype === 'generateTemplateFromFieldPublic' || this.newItem.subtype === 'saveBeforeSubcontainer')){
        this.renameModal.showInfoText = true;
        this.renameModal.buttonAccept = this.translate.instant('common.continue');
    }else if(this.newItem.subtype && this.newItem.subtype === 'saveComunicationChannel'){
        this.renameModal.showInfoText = true;
    }
  }

  configureCalls(){
    if(this.newItem.conceptId && this.newItem.type !== 'public'){
      if(this.newItem.type === 'concept'){
          this.getListConcept();
      }else if(this.renameModal.whistleProduct){
          this.getListReportsWhistle();
      }else if(this.newItem.specialType === 'report-denunc'){
          this.getListReportsDenunc();
      }else{
          this.getListReports();
      }

    }else{
        if(this.newItem.type === 'document' || this.newItem.type === 'upload'){
            var lastPoint = this.newItem.titleConcept.lastIndexOf('.');
            if(lastPoint !== -1){
                this.renameModal.name = this.newItem.titleConcept.substring(0, lastPoint);
                this.renameModal.extension = this.newItem.titleConcept.substring(lastPoint, this.newItem.titleConcept.length);
            }else{
              this.renameModal.name = this.newItem.titleConcept;
            }
        }else{
          this.renameModal.name = this.newItem.titleConcept;
        }
    }
  }

  getListConcept(){
    this.apiService.get('conceptobjects/byconceptid', this.newItem.conceptId)
    .then((data:any)=>{
        this.renameModal.nameList = data;
        if(this.newItem.rename){
            if(this.newItem.titleConcept.indexOf('[') !== -1){
                this.renameModal.suffix = this.newItem.titleConcept.substring(this.newItem.titleConcept.indexOf('['), this.newItem.titleConcept.length);
                this.renameModal.name =  $.trim(this.newItem.titleConcept.substring(0, this.newItem.titleConcept.indexOf('[')))
            }else{
                this.renameModal.name = this.newItem.titleConcept;
            }

        }else if(this.skin !== 'icam-red' && this.newItem.namedKey !== 'Usuario'){
            this.renameModal.name = this.getNameNewConceptOrReport();
        }
    });
  }

  getListReportsWhistle(){
    this.apiService.get('be/wh/dashboard/reportobjects')
        .then((data:any)=>{
          this.renameModal.nameList = data;
          this.renameModal.name = !this.newItem.rename? this.getNameNewConceptOrReport() : this.newItem.titleConcept;
        });
  }

  getNameNewConceptOrReport(){
    var name = this.newItem.titleConcept;
    var nameConceptOrigin = this.newItem.titleConcept;
    if (this.newItem.listFolders && this.newItem.listFolders.length) {
        if(this.newItem.listFolders[0].parentconcepttitle && !this.newItem.listFolders[0].title){
            name = nameConceptOrigin+' (' + (this.newItem.listFolders.length+1) + ')';
        }else{
            var nameIdx = 0;
            do {
                if (nameIdx) {
                   name = nameConceptOrigin+' (' + nameIdx + ')';
                }
                var existingFolder = this.newItem.listFolders.filter((item:any)=>item.title.toLowerCase() === name.toLowerCase())[0]
                nameIdx++;
            } while (existingFolder);
        }
    }
    return name;
  }

  getListReportsDenunc(){
    let url:string = this.evaluationId?'reportobjects/byreportid/'+this.newItem.conceptId: 'reportobjects/byreportid';
    let id:any = this.evaluationId? this.evaluationId : this.newItem.conceptId;
    this.apiService.get(url, id)
        .then((data:any)=>{
            this.renameModal.nameList = data.reports;
            if(!this.newItem.rename){
                this.newItem.listFolders = data.reports;
                this.renameModal.name = this.getNameNewConceptOrReport();
            }else{
                this.renameModal.name = this.newItem.titleConcept;
            }
        });
  }

  getListReports(){
    this.apiService.get('reportobjects/byevaluation', this.evaluationId)
    .then((data:any)=>{
      this.renameModal.nameList = data;
      this.renameModal.name =!this.newItem.rename? this.getNameNewConceptOrReport(): this.newItem.titleConcept;
    });
  }

  itemSelected(e: any, item:string){
    this.renameModal[item] = e.newValue;
    if(this.limitExceeded){
        this.limitExceededCharacters();
    }
  }

  itemSelectedEnter(e:any, item:string){
    this.renameModal[item] = e.newValue;
    this.validateFields();
  }

  validateFields(){
    this.renameModal.errorName = false;
    this.renameModal.errorType = '';
    if(this.renameModal.name === '' || this.renameModal.name === undefined){
        this.renameModal.nameInfo.error = true;
    }else if(!this.limitExceededCharacters()){
        this.renameModal.nameInfo.error = false;
        if(this.newItem.conceptId && this.newItem.type !== 'public'){
          if(this.newItem.type === 'concept' && this.skin != 'icam-red'){
            this.validateConceptName(this.renameModal.name);
          }else if(this.renameModal.whistleProduct || this.newItem.specialType === 'report-denunc'){
              this.validateReportSpecialName(this.renameModal.name);
          } else {
              if (this.renameModal.nameInfo.error = this.renameModal.errorNameDocument = !this.validateDocumentService.correctCharacters(this.renameModal.name)) {
                this.renameModal.errorType = this.translate.instant('rename-modal.incorrect-characters');
              } else {
                  this.validateReportName(this.renameModal.name);
              }
          }
        }else if(this.newItem.type ==='folder'){
            this.newItem.titleConcept === this.renameModal.name? this.handleDismiss() : this.validateFolderName(this.renameModal.name);
        }else if(this.newItem.type ==='document' || this.newItem.type === 'upload'){
            let name = this.renameModal.name + this.renameModal.extension;
            if(this.validateDocumentService.correctCharacters(name)){
                if(this.validateDocumentService.duplicatedDocuments(name, this.list)){
                    this.renameModal.errorNameDocument = true;
                    this.renameModal.nameInfo.error = true;
                    this.renameModal.errorType = this.translate.instant('rename-modal.error-repeat-name');
                }else if(this.newItem.type === 'upload' && this.validateDocumentService.duplicatedDocuments(name, this.otherList)){
                    this.renameModal.errorNameDocument = true;
                    this.renameModal.nameInfo.error = true;
                    this.renameModal.errorType = this.translate.instant('documentation-list.duplicated-name-inner-list');
                }else{
                    this.renameModal.nameInfo.error = false;
                    this.renameModal.errorNameDocument = false;
                    this.handleAccept();
                }
            }else{
                this.renameModal.errorNameDocument = true;
                this.renameModal.nameInfo.error = true;
                this.renameModal.errorType = this.translate.instant('rename-modal.invalid-document');
            }
        }else if(this.newItem.type ==='related-forms'){
            var hasError = false;
            for(var i = 0; i < this.list.length; i++){
                if(this.renameModal.name === this.list[i].title){
                    this.renameModal.errorNameDocument = true;
                    this.renameModal.nameInfo.error = true;
                    this.renameModal.errorType = this.translate.instant('rename-modal.error-related-forms');
                    hasError = true;
                    break;
                }
            }
            if(!hasError){
                this.renameModal.nameInfo.error = false;
                this.renameModal.errorNameDocument = false;
                this.handleAccept();
            }



        }else{
            this.handleAccept();
        }
    }
  }

  limitExceededCharacters(){
      this.limitExceeded = false;
      let textLimit = '';
      switch(this.newItem.type){
          case 'concept':
          case 'public':
              this.limitExceeded = this.renameModal.name.length > 250;
              textLimit = this.translate.instant('rename-modal.exceeded-characters-concept');
              break;
          case 'document':
          case 'upload':
              this.limitExceeded = this.renameModal.name.length > 100;
              textLimit = this.translate.instant('rename-modal.exceeded-characters');
              break;
          default:
              break;
      }
      this.renameModal.nameInfo.error = this.limitExceeded;
      this.renameModal.errorNameDocument = this.limitExceeded;
      this.renameModal.errorType = textLimit;
      return this.limitExceeded;
  }

  validateConceptName(name:string){
    for(var i = 0; i < this.renameModal.nameList.length; i++){
        if(this.renameModal.nameList[i].title === name){
            if(this.renameModal.nameList[i].conceptObjectId !== parseInt(this.newItem.conceptObjectId)){
                this.renameModal.nameInfo.error =true;
                this.renameModal.errorName = true;
                this.renameModal.errorType = this.translate.instant('rename-modal.error-name')
                break;
            }else{
                this.handleDismiss();
                break;
            }
        }else{
            this.renameModal.nameInfo.error = false;
        }
    }
    if(!this.renameModal.nameInfo.error){
      this.handleAccept();
    // }else{
    //     // JRSJ 12/11/2020  WEB-31662 Error al nombrar una instancia con corchetes, se añade de nuevo la condición de que no tenga error, para evitar sobreescribir el errortype
    //     if(!this.renameModal.nameInfo.error){
    //       this.renameModal.nameInfo.error =true;
    //       this.renameModal.errorName = true;
    //       this.renameModal.errorType = this.translate.instant('rename-modal.error-reserved-name')
    //     }
    }
}

  validateReportName(name:any){
    loop1:
    for(var i = 0; i < this.renameModal.nameList.length; i++){
        if(this.renameModal.nameList[i].reportId === this.newItem.conceptId){
            for(var j = 0; j < this.renameModal.nameList[i].reportObjects.length; j++){
                var reportObjects = this.renameModal.nameList[i].reportObjects[j];
                if(reportObjects.title === name){
                    if(reportObjects.reportObjectId !== parseInt(this.newItem.conceptObjectId )){
                        this.renameModal.nameInfo.error =true;
                        this.renameModal.errorName = true;
                        this.renameModal.errorType = this.translate.instant('rename-modal.error-name-report');
                        break loop1;
                    }else{
                      this.handleDismiss();
                      break;
                    }
                }else{
                  this.renameModal.nameInfo.error = false;
                }

            }
        }
    }
    if(!this.renameModal.nameInfo.error){
        this.handleAccept();
    }
  }

  validateReportSpecialName(name:any){
    loop1:
    for(var i = 0; i < this.renameModal.nameList.length; i++){
        var reportObjects = this.renameModal.nameList[i];
        if(reportObjects.reportId === this.newItem.conceptId){
            if(reportObjects.title === name){
                if(reportObjects.reportObjectId !== parseInt(this.newItem.conceptObjectId )){
                    this.renameModal.nameInfo.error =true;
                    this.renameModal.errorName = true;
                    this.renameModal.errorType = this.translate.instant('rename-modal.error-name-report')
                    break loop1;
                }else{
                    this.handleDismiss();
                    break;
                }
            }else{
              this.renameModal.nameInfo.error = false;
            }
        }
    }
    if (!this.renameModal.nameInfo.error && !this.commonUtilsService.validateNoSpecialCharacters(this.renameModal.name)) {
        this.renameModal.nameInfo.error = true;
        this.renameModal.errorName = true;
        this.renameModal.errorType = this.translate.instant('rename-modal.error-name-report-special')
    }
    if(!this.renameModal.nameInfo.error){
        this.handleAccept();
    }
  }

  validateFolderName(name:any){
    for(var i = 0; i < this.newItem.listFolders.length; i++){
        if(this.newItem.listFolders[i].name === name){
            this.renameModal.nameInfo.error =true;
            this.renameModal.errorName = true;
            this.renameModal.errorType = this.translate.instant('rename-modal.error-folder')
            break;
        }else{
          this.renameModal.nameInfo.error = false;
        }
    }
    if(!this.renameModal.nameInfo.error){
        this.handleAccept();
    }
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      name: this.renameModal.suffix ? this.renameModal.name + ' ' + this.renameModal.suffix: this.renameModal.name
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
