import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';


@Component({
  selector: 'field-type-task',
  templateUrl: './field-type-task.component.html'
})
export class FieldTypeTaskComponent implements OnInit, OnChanges, OnDestroy {
  subscribers: any = {};
  isLanding: any = this.customLanding.isLanding();
  custom: any = this.customLanding.getCustom();
  skin: string = this.isLanding?'landing-custom' : this.globalCfg.skin;
  fieldTypeTask: any = {
    openLaw: false,
    refences: false,
    canCreate: this.userPermisions.getPermissions() ==='all'
  }
  constructor(private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private userPermisions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() concept: any = null;
  @Output() itemSelected = new EventEmitter();

  ngOnInit(): void {
    this.commonUtilsService.setMomentLanguage();
    this.openWatcherDeleteTask();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['field'] && changes['field'].currentValue) {
      let field: any = changes['field'].currentValue;
      field.id = !field.id? this.commonUtilsService.createId(): field.id;
      field.description = this.commonUtilsService.checkAndDeletePreTag(field.description)
      this.fieldTypeTask.field = field;
      if(field && field.evaluatedValue && field.evaluatedValue.conceptObjectId && field.evaluatedValue.conceptDataId){
        this.getPatterns();
      }
   
    }

    if(changes['preSelectItem'] && changes['preSelectItem'].currentValue === null ){
      this.fieldTypeTask.preSelectItem = false
    }
  }

  openWatcherDeleteTask(){
    this.subscribers.deleteTaskFieldBroadcast = this.broadcastService.subscribe('deleteTaskField', (data:any)=>{
      let field: any = this.fieldTypeTask.field;
        if(field.evaluatedValue.conceptDataId === data.field.evaluatedValue.conceptDataId){
            this.commonUtilsService.deleteElement(data.task, this.fieldTypeTask.taskList, 'taskId');
            data.task.id = data.task.taskId;
            field.evaluatedValue.displayData = this.commonUtilsService.deleteElement( data.task, field.evaluatedValue.displayData, 'id');
            field.selectTask = null;
        }
    })
  }

  getPatterns(){
    this.apiService.getPatterns()
        .then((data:any)=>{
            this.fieldTypeTask.patternList = data;
            this.getRelatedTasks();
        }, ()=>{})
  }

  getRelatedTasks(){
      this.apiService.get('tasks/byconceptdataid/'+this.fieldTypeTask.field.evaluatedValue.conceptObjectId, this.fieldTypeTask.field.evaluatedValue.conceptDataId)
          .then((data:any)=>{
              this.fieldTypeTask.field.evaluatedValue.displayData = [];
              data.forEach((task:any) => {
                if(task.repeatPattern !== null){
                  task.repeat = this.getRepetition(task.repeatPattern);
                }
                this.addTaskToDisplayData(task);
              });
              this.fieldTypeTask.taskList = data;
          }, ()=>{})
  }

  addTaskToDisplayData(task:any){
      task.participantsText = '';
      for(let i = 0; i < task.participants.length; i++){
        task.participantsText += task.participants[i].fullName;
        if(i < task.participants.length - 1){
            task.participantsText += ', ';
        }
      }

      let taskValue = {
          id: task.taskId,
          taskTypeId: task.taskTypeId,
          videoconference: task.videoconference,
          value: {
              title: task.title,
              type: task.type,
              participants: task.participantsText,
              expirationDate: task.expirationDate,
              beginHour: task.beginHour,
              endHour: task.endHour,
              location: task.location,
              repeat: task.repeat
          }
      }
      this.fieldTypeTask.field.evaluatedValue.displayData.push(taskValue);
  }

  getRepetition(repeatPattern:any){
      return this.setMomentCreation(repeatPattern) +' / '+ this.extractInterval(repeatPattern);
  }

  setMomentCreation(repeatPattern:any){
    let _extract: any = this.commonUtilsService.extractToArray
    let numeralTimeList: any = this.fieldTypeTask.patternList.numeralTimeList;
    let weekDayList: any = this.fieldTypeTask.patternList.weekDayList;
    let monthList: any = this.fieldTypeTask.patternList.monthList;
    let param1: any = repeatPattern.repeatParam1;
    let param2: any = repeatPattern.repeatParam2;
    let param3: any = repeatPattern.repeatParam3;
    let repeatOption: any = repeatPattern.repeatOption

      switch(repeatPattern.frecuencyId){
          case 1:
            return repeatOption !== 1?this.translate.instant('repeat-pattern.all-days-week'): this.translate.instant('add-alert.every')+" "+ param1 +" "+this.translate.instant('repeat-pattern.days');
          case 2:
            return this.translate.instant('repeat-pattern.repeat-every')+" "+ param1 +" "+ this.translate.instant('repeat-pattern.weeks-in-days') +" "+ this.extractWeekSelect(param2);
          case 3:
              if(repeatOption === 1){

                  return this.translate.instant('repeat-pattern.the-day') +" "+
                      param1 +" "+
                      this.translate.instant('repeat-pattern.kind') +" "+
                      param2 +" "+
                      this.translate.instant('repeat-pattern.month');
              }else{

                  return this.translate.instant('repeat-pattern.the') +" "+
                      _extract(param1, numeralTimeList, 'id').text +" "+
                      _extract(param2, weekDayList, 'id').text +" "+
                      this.translate.instant('repeat-pattern.kind') +" "+ param3 +" "+
                      this.translate.instant('repeat-pattern.month');
              }

          default:
              if(repeatOption === 1){
                  return this.translate.instant('repeat-pattern.the') +" "+
                      param1 +" "+
                      this.translate.instant('repeat-pattern.kind') +" "+
                      _extract(param2, monthList, 'id').text;
              }else{
                  return this.translate.instant('repeat-pattern.the') +" "+
                      _extract(param1, numeralTimeList, 'id').text +" "+
                      _extract(param2, weekDayList, 'id').text +" "+
                      this.translate.instant('repeat-pattern.kind') +" "+
                      _extract(param3, monthList, 'id').text;
              }

      }
  }
  extractWeekSelect(weekdayMaskEnum:any){
      let weekDays = '';
      this.fieldTypeTask.patternList.weekDays.forEach((weekdays:any) => {
        if((weekdays.bit && weekdayMaskEnum) === weekdays.bit){
          weekDays += moment().weekday(weekdays.value).format('dddd')+', '
      }
      });

      return weekDays.substring(0 , weekDays.length - 2);
  }

  extractInterval(repeatPattern:any){
      if(repeatPattern.repeatTimes !== null){
          return moment(repeatPattern.startInterval).format('DD/MM/YYYY') +' - '+ repeatPattern.repeatTimes +' '+ this.translate.instant('repeat-pattern.repetitions');
      }else if(repeatPattern.repeatUntil !== null){
          return moment(repeatPattern.startInterval).format('DD/MM/YYYY') +'-'+ moment(repeatPattern.finishInterval).format('DD/MM/YYYY')
      }else{
          return this.translate.instant('repeat-pattern.no-end-date');
      }
  }

  createTask(){
      this.emitTask(null, 'create');
  }

  editTask(task:any){
      let pathname: string = `evaluation/${this.concept.evaluationId}/folder/${this.concept.folderId}/concept/${this.concept.conceptId}/concept-evaluated/${this.concept.conceptObjectId}/tasks/${task.taskId}`;
      this.commonUtilsService.reloadNavigateUrlEvaluation(pathname, this.concept.evaluationId);
  }

  deleteTask(task:any){
      this.emitTask(task, 'delete');
  }

  emitTask(task:any, action:string){
      this.fieldTypeTask.field.selectTask = task;
      this.broadcastService.broadcast('actionFromField', {
          field:this.fieldTypeTask.field,
          action: action
      });
  }

  editOrView(task:any){
      return !this.fieldTypeTask.canCreate || task.taskStateId === 5 || task.taskStateId === 6? this.translate.instant('common.view') : this.translate.instant('common.edit');
  }
  getParticipants(task:any){
    return task.participants;
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }

    }
