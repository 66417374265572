<div class="field-type-access">
  <div class="common__input-block common__input-block--fixed"
      [ngClass]="{'common__show-alert--fixed': fieldTypeAccess.field.error,
          'common__input-no-editable':fieldTypeAccess.field.noEditable}"
      [attr.disabled]="fieldTypeAccess.field.noEditable?true:null">
      <div class="common__label-block">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': fieldTypeAccess.field.error,
                      'common__input-text--no-editable':fieldTypeAccess.field.noEditable}">{{fieldTypeAccess.field.label}}</span>
      </div>
      <div class="field-type-template__container">
          <p class="field-type-template__text-description"
            [innerHTML]="fieldTypeAccess.field.description"></p>
          <div class="field-type-template__block-button">
              <button class="common__button-border-none"
                  [ngClass]="{'common__button-border-none--error': fieldTypeAccess.field.error}"
                  [disabled]="fieldTypeAccess.field.noEditable"
                  (click)="accessRoute()">
                  <i class="lf-icon-arrow-right-box common__icon-button-underlined"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'front-login.access'">_Acceder</span>
              </button>
          </div>
      </div>
  </div>
</div>
