<div class="form-configuration-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-configuration common__icon-header"></i>
      <span class="common__text-header"
        [afTranslate]="'form-configuration-modal.form-configuration'">_Configuración del formulario</span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="form-configuration-modal__body-container-group"
    *ngIf="formConfiguration.faceview === 'group'">
    <div class="form-configuration-modal__container-group">
      <advice-message class="form-configuration-modal__container-advice"
          [ngShow]="formConfiguration.infoAdvice"
          [icon]="'warning'"
          [type]="'warning'"
          [typeClass]="'no-margin'"
          [text]="'form-configuration-modal.advice-message' | afTranslate"
          (closeAdvice)="closeAdvice()">
      </advice-message>
      <p class="common__title-block-modal"
        [afTranslate]="'form-configuration-modal.info-modal'">_Añade tus campos personalizados. Este grupo de campos se visualizará al principio del formulario.</p>
      <div class="form-configuration-modal__container-group-inner">
        <div class="form-configuration-modal__group-header">
            <input type="text"
                class="form-configuration-modal__input-group-name"
                [id]="'form-configuration-modal-group-'+formConfiguration.fieldContainer.fieldContainerId"
                [(ngModel)]="formConfiguration.fieldContainer.name"
                [disabled]="formConfiguration.disabledGroup"
                (blur)="fieldContainerNameChange(formConfiguration.fieldContainer)"
                maxlength="300">
            <div class="form-configuration-modal__block-button-add-group">
              <div ngbDropdown>
                <button class="form-configuration-modal__button-add-subgroup"
                  ngbDropdownToggle>
                  <i class="lf-icon-add-round customize-concept__add-subgroup-icon"
                      [ngClass]="{'customize-concept__add-subgroup-icon--disabled': formConfiguration.disabledGroup}"></i>
                  <span class="customize-concept__add-subgroup"
                      [ngClass]="{'customize-concept__add-subgroup--disabled': formConfiguration.disabledGroup}"
                      [afTranslate]="'form-configuration-modal.add-field'">_Añadir campo</span>
                </button>
                <ul class="form-configuration-modal__list-menu-block"
                    ngbDropdownMenu
                    role="menu">
                    <li class="common__list-dropdown-option"
                        *ngFor="let option of formConfiguration.fieldList; let last = last">
                        <button class="common__button-option-list-dropdown "
                            [ngClass]="{'common__button-option-list-dropdown--last': last}"
                            (click)="addNewField(option)">
                            <i [class]="' '+option.icon+' common__icon-option-list-dropdown'"></i>
                            <span class="common__text-menu-option-dropdown"
                              [afTranslate]="option.name"></span>
                        </button>
                    </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="form-configuration-modal__group-container"
          [dragula]="'fieldListConfiguration'"
          id="fieldContainer"
          [dragulaModel]="formConfiguration.fieldContainer.fields"
          (dragulaModelChange)="reorderFields($event, formConfiguration.fieldContainer)">
          <div class="customize-concept__field-item"
              *ngFor="let containerField of formConfiguration.fieldContainer.fields"
              [id]="containerField.fieldId">
              <customize-fields [field]="containerField"
                  [typeField]="'fieldContainer'"
                  [typeView]="'channelViewNoEditable'"
                  [contentTypeList]="formConfiguration.contentTypeList"
                  [disabled]="formConfiguration.disabledGroup"
                  [reportList]="formConfiguration.reportList"
                  [concept]="formConfiguration.conceptInfo"
                  (openField)="showField($event, formConfiguration.fieldContainer)"
                  (launchAction)="launchAction($event.action, formConfiguration.fieldContainer)">
              </customize-fields>
          </div>
        </div>
      </div>
    </div>
    <div class="form-configuration-modal__container-footer-group"
      [ngClass]="{'form-configuration-modal__container-footer-group--with-fields': formConfiguration.fieldContainer.fields?.length}">
      <div *ngIf="formConfiguration.fieldContainer.fields?.length"
        class="form-configuration-modal__container-report-channel">
        <span class="form-configuration-modal__label-report-channel"
          [afTranslate]="'form-configuration-modal.show-channel-report'">_Mostrar en el informe del canal</span>
        <div ngbDropdown
            id="dropdownChannelReport">
            <button class="common__dropdown-selector-button"
                ngbDropdownToggle
                [disabled]="formConfiguration.disabledReport">
                <span class="common__label-selector-button"
                  *ngIf="!formConfiguration.selectedChannelReportField"
                    [afTranslate]="'common.none'">_Ninguno</span>
                <span class="common__label-selector-button text-truncate"
                  *ngIf="formConfiguration.selectedChannelReportField">{{formConfiguration.selectedChannelReportField?.label}}</span>
                <i
                  [ngClass]="{
                    'common__select-option-icon-angle-down--box-disabled': formConfiguration.disabledReport
                  }"
                  class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
            </button>
            <div class="common__menu-block-dropdown common__menu-block-dropdown--less-margin dropdown-menu"
                ngbDropdownMenu
                role="menu">
                <div class="common__block-option-list common__block-option-list">
                    <div class="common__language-item common__language-item"
                      *ngIf="formConfiguration.selectedChannelReportField"
                        (click)="selectChannelReport(null)">
                        <span-tooltip
                          [placement]="'above'"
                          [text]="'common.none' | afTranslate">
                        </span-tooltip>
                    </div>
                    <div class="common__language-item common__language-item"
                        *ngFor="let field of formConfiguration.fieldContainer.fields"
                        (click)="selectChannelReport(field)">
                        <span-tooltip
                          [placement]="'above'"
                          [text]="field.label">
                        </span-tooltip>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <button type="submit"
        class="common__button common__button--active"
        (click)="saveConfiguration()"
        [afTranslate]="'common.finish'">_Finalizar</button>
      </div>
  </div>
  <div class="form-configuration-modal__body-container-field"
    *ngIf="formConfiguration.faceview === 'field'">
    <customize-fields class="form-configuration-modal__container-field"
        [field]="formConfiguration.newField"
        [typeField]="'none'"
        [typeView]="'channelViewEditable'"
        [contentTypeList]="formConfiguration.contentTypeList"
        [disabled]="formConfiguration.disabledGroup"
        [concept]="formConfiguration.conceptInfo"
        (launchAction)="launchAction($event.action, formConfiguration.fieldContainer)">
    </customize-fields>
  </div>
</div>
