import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppLanguageFactory {
  languaje: string = 'es';
  // languaje: string = 'es-ES';

  setLanguaje(languaje: string){
    this.languaje = languaje;
  }
  getLanguaje(): string{
    return this.languaje;
  }
}
