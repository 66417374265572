<div class="clients-tracking-dashboard">
  <div class="clients-tracking-dashboard__block-panel-query"
      [ngClass]="{'clients-tracking-dashboard__block-panel-query--disabled': !queryProduct}"
      *ngIf="skin === 'steel-blue'"
      (click)="viewQueryKnowledge()">
      <i class="lf-icon-arrow-right clients-tracking-dashboard__icon-arrow-right"></i>
      <div class="clients-tracking-dashboard__block-text-query">
          <span class="clients-tracking-dashboard__query-text-title">
              <span>{{product.name}}</span>
              <span [afTranslate]="'share-instance.query'"
                  [ngHide]="skin ==='steel-blue'">Consulta</span>
          </span>
          <span class="clients-tracking-dashboard__query-text-subtitle"
              [ngClass]="{'common__uppercase': skin ==='steel-blue'}"
              [afTranslate]="'dashboard.consultation-concursal-button.subtitle'">_Acceder a la base de conocimientos</span>
      </div>
  </div>

  <div class="clients-tracking-dashboard__block-panel">
      <div class="clients-tracking-dashboard__block-header-panel">
          <i class="lf-icon-build clients-tracking-dashboard__icon-header"></i>
          <h6 class="clients-tracking-dashboard__title-header-panel"
              [afTranslate]="'common.clients-tracking'">_Seguimiento de clientes</h6>
          <button class="lf-icon-open-window clients-tracking-dashboard__button-open-window"
              (click)="goTo('clients-tracking')"></button>
      </div>
      <div class="clients-tracking-dashboard__block-body-panel">
          <p class="clients-tracking-dashboard__evaluated-clients">
              <span class="clients-tracking-dashboard__evaluated-clients-count">{{clientsTracking.clientsCount}}</span>
              <span class="span__auto-ajust"
                [afTranslate]="'anti-money-laundering-panel.evaluated-clients'">_Clientes evaluados</span>
          </p>
          <div>
              <div class="clients-tracking-dashboard__bubble-count"
                *ngFor="let bubbleCount of clientsTracking.evaluatedClients"
                [ngStyle]="{'background-color': bubbleCount.colorRGBA}">{{bubbleCount.count}}</div>
          </div>
          <div class="clients-tracking-dashboard__block-clients-repeat">
              <p class="clients-tracking-dashboard__subtitle-clients"
                  [afTranslate]="'anti-money-laundering-panel.last-customer-evaluated'">_Último cliente evaluado</p>
              <p class="clients-tracking-dashboard__line-p">
                  <span class="clients-tracking-dashboard__fixed-text">{{clientsTracking.lastEvaluatedClient.clientName}}:</span>
                  <span class="clients-tracking-dashboard__final-result"
                      [ngStyle]="{'background-color': clientsTracking.lastEvaluatedClient.colorRGBARiesgo}">{{clientsTracking.lastEvaluatedClient.riesgo}}</span>
              </p>
          </div>
          <div class="clients-tracking-dashboard__block-clients-repeat">
              <p class="clients-tracking-dashboard__subtitle-clients"
                  [afTranslate]="'anti-money-laundering-panel.customer-recurrency'">_Cliente con mayor recurrencia</p>
              <p class="clients-tracking-dashboard__line-p"
                  *ngFor="let clientRecurrency of clientsTracking.listMoreRecurrency">
                  <span class="clients-tracking-dashboard__fixed-text">{{clientRecurrency.clientName}}:</span>
                  <span class="clients-tracking-dashboard__definition-text">{{clientRecurrency.countEvaluations}}
                      <span [afTranslate]="'anti-money-laundering-panel.operations'">_operaciones</span>
                  </span>
              </p>
          </div>
          <div class="clients-tracking-dashboard__block-clients-repeat">
              <p class="clients-tracking-dashboard__subtitle-clients"
                  [afTranslate]="'anti-money-laundering-panel.upcoming-follow'">_Próximos seguimientos</p>
              <p class="clients-tracking-dashboard__line-p"
                *ngFor="let clientTracing of clientsTracking.nextTracings | afOrderBy:['proximoSeguimiento', 'clientName']:false">
                <span class="clients-tracking-dashboard__fixed-text">{{clientTracing.clientName}}:</span>
                <span class="clients-tracking-dashboard__definition-text">{{clientTracing.proximoSeguimiento | convertDate:'without-time'}}</span>
              </p>
          </div>
      </div>
  </div>
</div>
