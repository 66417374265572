<div class="picker-data-simple__block-date-interval "
    [ngClass]="{'picker-data-simple__block-date-interval--disabled':disabled,
          'picker-data-simple__block-date-interval--open': pickerDataSimple.openDatePicker}"
    ngbDropdown
    [autoClose]="'outside'"
    (openChange)="changeOpenDataPicker()">
  <input-date class="common__input-content picker-data-simple__input-date-interval"
      [eraseButton]="'no-show'"
      [disabled]="disabled"
      [input]="finishIntervalDate"
      (output)="outputDate($event)"></input-date>
  <button class="lf-icon-calendar picker-data-simple__icon-calendar-interval"
      [id]="'open-calendar-picker-data-simple-'+ pickerDataSimple.id"
      [ngClass]="{'picker-data-simple__icon-calendar-interval--disabled':disabled}"
      [disabled]="disabled"
      ngbDropdownToggle></button>
  <calendar-datepicker ngbDropdownMenu
      class="picker-data-simple__datepicker-box"
      [selectedDate]="finishIntervalDate"
      [openDatePicker]="pickerDataSimple.openDatePicker"
      [dateLockType]="pickerDataSimple.dateLockType"
      [type]="'picker-data'"
      (selectDatepicker)="changeInDate($event.newValue)"></calendar-datepicker>
</div>
