<div class="share-document">
  <div class="common__modal-header-block">
      <i class="lf-icon-consulting common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'concept-list.assign-manager'"></span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <form class="assign-manager__form">
      <p class="common__title-block-modal assign-manager__info-text"
          [afTranslate]="'concept-list.assign-manager-description'">
      </p>
      <field-type-contacts-multiple
          [field]="assignManager.assignedManagerField"
          (sendContacts)="receiveContacts($event.contacts)"
          (contactsClosed)="contactsClosed()">
      </field-type-contacts-multiple>
  </form>
  <div class="assign-manager__query-box">
      <i class="assign-manager__query-count">{{assignManager.countToShow}}</i>
      <p class="assign-manager__query-text"
          [afTranslate]="'concept-list.query-selected'"></p>
  </div>
  <div class="assign-manager__block-buttons">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">_cancel</button>
      <button type="submit"
          class="common__button common__button--active common__button--separating-margin"
          (click)="verifyDestinationList()"
          [afTranslate]="'add-assignment.assign'">_asignar</button>
  </div>
</div>
