import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tooltip-in-report',
  templateUrl: './tooltip-in-report.component.html'
})
export class TooltipInReportComponent implements OnInit {
  tooltipInReport: any = {
    showInfo: false
  }
  constructor() { }

  @Input() inReport: any = []

  ngOnInit(): void {
  }
  close(){
    this.tooltipInReport.showInfo = !this.tooltipInReport.showInfo;
  }
}
