import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { LoaderFactory } from "@shared/factories/loader/loader.factory";
import { ApiServiceService } from "@shared/services/api-service/api-service.service";
import { ModalServiceService } from "@shared/services/modal-service/modal-service.service";
import { CommonUtilsService } from "@shared/services/common-utils/common-utils.service";

@Component({
  selector: 'import-modal',
  templateUrl: './import-modal.component.html'
})
export class ImportModalComponent {
  showWarningInfo:boolean;
  amountHeader:number;
  validateFields:any[] = [];
  errorFields:any[] = [];
  incompleteFields:any[] = [];
  csvFile:any=null;
  allConceptFields:any;
  importModal:any = {
    warningInfo: this.translate.instant('users-admin.import.warning-info-text'),
    filterBoxText: this.translate.instant('users-admin.import.all-contacts-card'),
    view: 'select-file',
    typeSelect: 'all',
    searchContact: '',
    fileField: {
      label: this.translate.instant('users-admin.import.csv-file-field-label'),
      id: 'csvFileField',
      required: true,
      accept: '.csv'
    },
    fileInfo: {},
    allFields: [],
    filterErrorFields: [],
    validateFields: [],
    errorFields: [],
    incompleteFields: [],
  };

  constructor(private activeModal:NgbActiveModal,
    private translate:AfTranslateFactory,
    private loader:LoaderFactory,
    private apiService:ApiServiceService,
    private modalService:ModalServiceService,
    private commonUtilsService: CommonUtilsService){}

  @Input() type:string;
  @Input() concept:any;

  filterToShow(type:string){
    this.importModal.typeSelect = type;
    this.amountHeader = this.importModal[type + 'Contacts'].length;
    this.importModal.filterBoxText = this.translate.instant('users-admin.import.' + type + '-contacts-card');
  }

  changeView(type:string){
    if(type === 'analized'){
        this.importModal.view = 'analized-' + this.type;
    }else{
        this.importModal.view = type;
        if(type === 'error-contacts' || type === 'error-concepts'){
            this.importModal.filterErrorFields = this.errorFields;
        }else{
            this.importModal.filterErrorFields = this.incompleteFields;
            this.showWarningInfo = true;
        }
    }
  }

  previous(){
    this.importModal.view = 'select-file';
  }

  close() {
    this.activeModal.close({
            result: 'ok',
            validateFields: this.importModal.validateFields,
            errorFields: this.errorFields,
            incompleteFields: this.incompleteFields
    });
  }

  analizeClick() {
    this.importModal.fileField.error = !this.csvFile;
    if (this.csvFile) {
        this.analizeCsvFile(this.csvFile);
    } else {
        this.importModal.fileField.error = true;
    }
  }

  analizeCsvFile(csvFile:any) {
    this.loader.openLoader('import-analize');
    let url = 'contacts/import/analize-csv';
    if(this.concept){
        url = 'concepts/import/analize-csv/' + this.concept.conceptId;
    }else if(this.type === 'clients'){
        url = 'clients/import/analize-csv';
    }
    let formData = new FormData();
    formData.append('file', csvFile);
    this.apiService.attachFile(url, formData).then((response) => {
      if(!this.concept){
          this.distributeContactsAnalized(response);
      }else{
          this.distributeConceptsAnalized(response);
      }
      this.importModal.view = 'analized-' + this.type;
      this.loader.closeLoader(['import-analize']);
    }, (errorData:any)=>{
      this.unexpectedError();
    });
  }

  distributeContactsAnalized(analizedFields:any){
    this.importModal.allFields = analizedFields;
    this.amountHeader = analizedFields.length;
    this.errorFields = [];
    this.importModal.validateFields = [];
    this.incompleteFields = [];
    analizedFields.forEach((field:any) => {
      field.nameComplete = this.translate.instant('clients-list.line') + ' ' + field.csvLine;
      let surname = field.surname ? field.surname : '';
      let name = field.name === null ? '' : field.name;
      if(!(name === '' && surname === '')){
          field.nameComplete += ' : ' + name + ' ' + surname;
      }
      field.errorList = [];
      if(field.errors === ''){
          field.type = 'validate';
          this.importModal.validateFields.push(field);
      }else{
          field.type='error';
          this.configureErrors(field);
          this.errorFields.push(field);
      }
    })
  }

  distributeConceptsAnalized(analized:any){
    this.allConceptFields = analized.conceptFields;
    this.importModal.allFields = analized.conceptData;
    this.amountHeader = analized.conceptData.length;
    this.errorFields = [];
    this.importModal.validateFields = [];
    this.incompleteFields = [];
    analized.conceptData.forEach((analizedConcept:any) => {
      analizedConcept.nameComplete = analizedConcept['titulo_Instancia'] === '' ? analized.conceptTitle:analizedConcept['titulo_Instancia'];
      analizedConcept.errorList=[];
      if(analizedConcept.errors === ''){
          analizedConcept.type='validate';
          this.importModal.validateFields.push(analizedConcept);
      }else{
          this.configureErrors(analizedConcept);
      }
    })
  }

  configureType(analizedConcept:any){
    let onlyRequired = true;
    for(let i = 0; i < analizedConcept.errorList.length; i++){
        let error = analizedConcept.errorList[i];
        if(error.fieldErrorType !== 'required'){
            onlyRequired = false;
            break;
        }
    }
    if(onlyRequired){
        analizedConcept.type = 'require';
        this.incompleteFields.push(analizedConcept)
    }else{
        analizedConcept.type='error';
        this.errorFields.push(analizedConcept)
    }
  }

  fileSelected(e:any) {
    this.csvFile = e.newValue;
    this.importModal.fileField.error = Boolean(!this.csvFile);
  }

  closeAdvice(type:any){
      this.importModal[type] = false;
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip('modal-help-'+id);
  }

  unexpectedError() {
    this.loader.closeError();
    this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
  }

  configureErrors(analized:any){
    let errorList;
    if(analized.errors.indexOf("\n") !== -1){
        errorList = analized.errors.split("\n");
    }else{
        errorList = [analized.errors];
    }
    for(let i = 0; i < errorList.length; i++){
        analized.errorList.push(this.extractError(errorList[i]));
    }
    if(this.concept){
        this.configureType(analized);
    }
  }

  extractError(error:any){
    let errorsplit = error.split('/');
    switch(errorsplit[0]){
        case 'cif-not-found':
            return {
                errorType: this.translate.instant('users-admin.import.column-list-4'),
                errorDescription: this.translate.instant('users-admin.import.the-cif') + ' ' + errorsplit[1] + ' ' + this.translate.instant('users-admin.import.not-register')
            }
        case 'client-not-unique':
            return {
                errorType: this.translate.instant('users-admin.import.column-list-4'),
                errorDescription: this.translate.instant('users-admin.import.the-cif') + ' ' + errorsplit[1] + ' ' + this.translate.instant('users-admin.import.several-clients')
            }
        case 'cif-required':
            return {
                errorType: this.translate.instant('users-admin.import.column-list-4'),
                errorDescription: this.translate.instant('users-admin.import.field-required')
            }
        case 'cif-duplicated':
            return{
                errorType: this.translate.instant('users-admin.import.column-list-4'),
                errorDescription: this.translate.instant('users-admin.import.the-cif') + ' ' + errorsplit[1] + ' ' + this.translate.instant('users-admin.import.duplicated-cif')
            }
        case 'max-length':
            if(!this.concept){
                return{
                    errorType: this.translate.instant('users-admin.import.column-list-' + errorsplit[2]),
                    errorDescription: this.translate.instant('users-admin.import.column-list-' + errorsplit[2]) + ' ' + this.translate.instant('users-admin.import.max-length')
                }
            }else{
                return{
                    errorType: this.translate.instant('users-admin.import.concept-card'),
                    errorDescription: this.allConceptFields[errorsplit[errorsplit.length-1]] + ' ' + this.translate.instant('users-admin.import.max-length'),
                    fieldErrorType: 'max-length'
                }
            }
        case 'few-columns':
            if(!this.concept){
                return{
                    errorType: this.translate.instant('users-admin.import.contact-card'),
                    errorDescription: this.translate.instant('users-admin.import.few-columns')
                }
            }else{
                return{
                    errorType: this.translate.instant('users-admin.import.concept-card'),
                    errorDescription: this.translate.instant('users-admin.import.few-columns'),
                    fieldErrorType: 'few-columns'
                }
            }
        case 'many-columns':
            if(!this.concept){
                return{
                    errorType: this.translate.instant('users-admin.import.contact-card'),
                    errorDescription: this.translate.instant('users-admin.import.many-columns')
                }
            }else{
                return{
                    errorType: this.translate.instant('users-admin.import.concept-card'),
                    errorDescription: this.translate.instant('users-admin.import.many-columns'),
                    fieldErrorType: 'many-columns'
                }
            }
        case 'email-exists':
            return{
                errorType: this.translate.instant('users-admin.import.duplicated'),
                errorDescription: this.translate.instant('users-admin.import.duplicated-contact-error')
            }
        case 'required':
            if(!this.concept){
                return{
                    errorType: this.translate.instant('users-admin.import.column-list-' + errorsplit[1]),
                    errorDescription: this.translate.instant('users-admin.import.field-required')
                }
            }else{
                return{
                    errorType: this.allConceptFields[errorsplit[errorsplit.length-1]].name,
                    errorDescription: this.translate.instant('users-admin.import.field-required'),
                    fieldErrorType:'required'
                }
            }
        case 'required-title':
            return{
                errorType: this.allConceptFields[errorsplit[errorsplit.length-1]].name,
                errorDescription: this.translate.instant('users-admin.import.field-required'),
                fieldErrorType: 'required-title'
            }
        case 'expect-date':
            return{
                errorType: this.allConceptFields[errorsplit[errorsplit.length-1]].name,
                errorDescription: this.translate.instant('users-admin.import.expected'),
                fieldErrorType: 'expected-date'
            }
        case 'expect-decimal':
            return{
                errorType: this.allConceptFields[errorsplit[errorsplit.length-1]].name,
                errorDescription: this.translate.instant('users-admin.import.expected'),
                fieldErrorType: 'expected-decimal'
            }
        case 'reference-exists':
            return {
                errorType: this.translate.instant('client-details.our-references'),
                errorDescription: this.translate.instant('users-admin.import.our-references-error')
            }
        case 'clientType-invalid':
            return {
                errorType: this.translate.instant('client-details.client-type'),
                errorDescription: this.translate.instant('users-admin.import.no-valid-value')
            }
        case 'reference-duplicate':
            return {
                errorType: this.translate.instant('users-admin.import.reference-duplicated'),
                errorDescription: this.translate.instant('users-admin.import.reference-duplicated-error')
            }
        case 'email-invalid':
        case 'email2-invalid':
            let fieldNum = errorsplit[0] === 'email-invalid' ? ' 1 ' : ' 2 ';
            return {
                errorType: this.translate.instant('users-admin.import.email-invalid'),
                errorDescription: this.translate.instant('users-admin.import.email-invalid-error-field') + fieldNum + this.translate.instant('users-admin.import.email-invalid-error-format')
            }
        default:
            return{
                errorType: '',
                errorDescription: ''
            }
    }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
