<div class="report-table__header-icam">
    <div class="report-table__header-left-icam"></div>
    <div class="report-table__header-center-icam"></div>
    <div class="report-table__header-right-icam">

        <div class="report-table__header-button-icam"
          (click)="exportReport(true)">
            <span class="report-table__button-text-icam"
                [afTranslate]="'report-table.export'"></span>
            <i class="lf-icon-send-excel report-table__button-icon-icam"></i>
        </div>
    </div>
</div>
<div class="report-table__table-icam"
    [ngHide]="reportTable.optionSelect === 'graph'">
    <div class="report-table__theader-icam report-table__theader-icam--type-2">
        <div class="report-table__tcell-icam report-table__tcell-icam--first">
            <filter-by-list class="report-table__filter-list-class"
                [type]="'list'"
                [item]="reportTable.perfilUsuarioFilter"
                [list]="reportTable.perfilUsuarioList"
                (filterElementEmit)="selectTypeOperation($event, {id:'perfilUsuario'})">
            </filter-by-list>
        </div>
        <div class="report-table__tsubheader-container-icam">
            <div class="report-table__theader-upper-icam">
                <div class="report-table__tcell-icam report-table__tcell-icam--totals-column report-table__tcell-icam--year"
                    ngbDropdown
                    [container]="'body'">
                    <span>{{reportTable.currentIcamYear}}</span>
                    <i class="lf-icon-angle-down report-table__tcell-icon-icam"
                        ngbDropdownToggle></i>
                    <ul class="report-table__dropdown-menu-icam report-table__dropdown-menu-icam--header-type-2"
                        ngbDropdownMenu>
                        <li class="report-table__dropdown-item-icam"
                            *ngFor="let year of reportTable.listYears"
                            (click)="selectIcamYear(year)">
                            <span>{{year}}</span>
                            <i class="lf-icon-check-round-full report-table__dropdown-icon-icam"></i>
                        </li>
                    </ul>
                </div>

                <div *ngFor="let month of reportTable.months; let last = last"
                  class="report-table__tcell-icam report-table__tcell-icam--header-upper"
                  [ngClass]="{'report-table__tcell-icam--last': last}">
                    {{month}}
                </div>

            </div>
            <div class="report-table__theader-lower-icam">
                <div class="report-table__tcell-icam report-table__tcell-icam--totals-column report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.total-consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.total-usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
                <div class="report-table__tcell-icam report-table__tcell-icam--last report-table__tcell-icam--subcells">
                    <div class="report-table__tsubcell-icam common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.consultas'">
                    </div>
                    <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first common__ellipsis"
                        style="width:50%"
                        [afTranslate]="'report-table.icam.perfil-usuario.usuarios'">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="report-table__tbody-icam">
        <div class="report-table__trow-icam"
            [ngClass]="{'report-table__trow-icam--last': last}"
            *ngFor="let row of reportTable.reportsListFilter; let last = last">
            <div class="report-table__tcell-icam report-table__tcell-icam--first">
                {{row.perfilUsuario}}
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--totals-column report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[0].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[0].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[1].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[1].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[2].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[2].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[3].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[3].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[4].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[4].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[5].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[5].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[6].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[6].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[7].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[7].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[8].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[8].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[9].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[9].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[10].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[10].totalUsuarios}}
                </div>
            </div>
            <div class="report-table__tcell-icam report-table__tcell-icam--subcells report-table__tcell-icam--last">
                <div class="report-table__tsubcell-icam"
                    style="width:50%">
                    {{row.meses[11].totalConsultas}}
                </div>
                <div class="report-table__tsubcell-icam report-table__tsubcell-icam--no-first"
                    style="width:50%">
                    {{row.meses[11].totalUsuarios}}
                </div>
            </div>
        </div>
    </div>
</div>
