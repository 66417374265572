<div class="concept-form__block-poll--custom"
    [ngClass]="{'concept-form__block-poll--custom-init':!conceptForm.startedPoll}"
    *ngIf="conceptForm.configuredVisibility">
    <div [ngShow]="!conceptForm.startedPoll"
        class="concept-form__block-front-poll">
        <div class="concept-form__custom-block-title-questionnaire">
            <div class="concept-form__block-information-questionnaire concept-form__block-information-questionnaire--custom">
                <span class="concept-form__custom-description-poll"
                    [innerHTML]="conceptForm.concept.description"></span>
                <img class="concept-form__custom-poll-img"
                    src="../assets/img/init-poll.png"
                    alt="image init poll"/>
            </div>
            <div class="concept-form__custom-block-buttom">
                <button type="submit"
                    class="common__button-landing-custom common__button-landing-custom--send concept-form__button-landing-custom-start"
                    [ngStyle]="{'background-color': conceptForm.custom.color, 'color': conceptForm.custom.textColor }"
                    (click)="nextQuestionPoll()"
                    [afTranslate]="'common.start'">
                    Comenzar
                </button>
            </div>
        </div>
    </div>
    <div [ngShow]="conceptForm.finishedPoll"
        class="concept-form__block-front-poll">
        <div class="concept-form__custom-block-title-questionnaire">
            <div class="concept-form__block-progress-poll-custom"
                [ngStyle]="{'border-color': conceptForm.custom.color, 'color': conceptForm.custom.color }">
                <span class="concept-form__text-poll-custom"
                    [afTranslate]="'customize.poll'">_Encuesta</span>
                <span class="concept-form__progress-poll-progress-custom">100%</span>
                <span class="concept-form__text-poll-completed-custom"
                    [afTranslate]="'concept-form.completed'">_completada</span>
            </div>
            <div class="concept-form__block-information-questionnaire concept-form__block-information-questionnaire--custom">
                <img class="concept-form__custom-poll-img"
                    src="../assets/img/finish-poll.png"
                    alt="image finish poll"/>
            </div>
        </div>
    </div>
    <div [ngShow]="conceptForm.startedPoll && conceptForm.fieldSelected && !conceptForm.finishedPoll"
        class="concept-form__block-title-group-poll"
            [ngClass]="{'concept-form__block-title-group-poll--subgroup': conceptForm.fieldSelected?.nameSubgroup}">
        <div class="concept-form__name-group-poll"
            [ngClass]="{'concept-form__is-hightlight': conceptForm.fieldSelected?.isHighlightGroup}">
            <span class="concept-form__number-group-poll">{{conceptForm.fieldSelected?.numberGroup}}</span>
            <span class="span__auto-ajust">{{conceptForm.fieldSelected?.nameGroup}}</span>
        </div>
        <i class="lf-icon-angle-right concept-form__icon-angle-separator"
            [ngShow]="conceptForm.fieldSelected?.nameSubgroup"></i>
        <div class="concept-form__name-group-poll concept-form__name-group-poll--subgroup"
            [ngClass]="{'concept-form__is-hightlight': conceptForm.fieldSelected?.isHighlightSubgroup}">
            <span [ngShow]="conceptForm.fieldSelected?.nameSubgroup">{{conceptForm.fieldSelected?.nameSubgroup}}</span>
        </div>
        <div class="concept-form__custom-block-expiration-date-poll">
            <i class="lf-icon-calendar-cross concept-form__icon-date-poll-custom"></i>
            <span class="concept-form__text-date-poll-custom"
                [afTranslate]="'expanded-menu.expiration-period'">__plazo de expiración</span>
            <span [ngShow]="conceptForm.concept.pollExpirationDate"
                class="concept-form__text-date-poll-custom span__auto-ajust">{{conceptForm.concept.pollExpirationDate | convertDate:'without-time'}}</span>
            <span [ngHide]="conceptForm.concept.pollExpirationDate"
                class="concept-form__text-date-poll-custom span__auto-ajust"
                [afTranslate]="'common.no-deadline'">_sin fecha limite</span>
        </div>
    </div>
    <div [ngShow]="conceptForm.startedPoll && !conceptForm.finishedPoll"
        class="concept-form__custom-block-fields-poll">
        <div *ngFor="let fieldSelected of conceptForm.sequentialFieldsVisibility"
            [ngShow]="fieldSelected.show && fieldSelected.showWithProperty && !fieldSelected.hidden && fieldSelected.selected"
            class="concept-form__custom-block-fields-element-poll"
            [ngClass]="{'concept-form__block-fields-element-poll--list-extended': fieldSelected.fieldTypeFrontId === 'list-extended'}">
            <field-item
                [fieldInfo]="fieldSelected"
                [evaluatedConcepts]="conceptForm.concept.evaluatedFields"
                (evaluatedConceptChange)="changesInEvaluatedConcept($event)"
                (changeConceptFromField)="changeConceptFromField($event.template)"
                [public]="conceptForm.public"
                [concept]="conceptForm.concept"
                [evaluationId]="conceptForm.evaluationId"
                [conceptLocked]="conceptForm.conceptLocked">
            </field-item>
        </div>
    </div>
    <div [ngShow]="conceptForm.startedPoll && !conceptForm.finishedPoll"
        class="concept-form__custom-button-start">
        <button class="common__button-landing-custom common__button-landing-custom--cancel pull-left"
            [ngStyle]="{'border-color': conceptForm.custom.color, 'color': conceptForm.custom.color }"
            (click)="previousQuestionPoll()"
            [ngShow]="conceptForm.fieldSelected?.countFieldVisible > 1"
            [afTranslate]="'common.previous'">
        </button>
        <button class="common__button-landing-custom"
            [ngStyle]="{'background-color': conceptForm.custom.color, 'color': conceptForm.custom.textColor }"
            (click)="nextQuestionPoll()"
            [ngHide]="conceptForm.fieldSelected?.lastField"
            [afTranslate]="'common.next'">
        </button>
        <button type="submit"
                class="common__button-landing-custom common__button-landing-custom--send"
                [ngStyle]="{'background-color': conceptForm.custom.color, 'color': conceptForm.custom.textColor }"
                (click)="savePoll()"
                [ngShow]="conceptForm.fieldSelected?.lastField"
                [disabled]="conceptForm.viewEditConcept"
                [afTranslate]="'common.finish'">_Finalizar
        </button>
    </div>
</div>
