import { Component, Input } from '@angular/core';

@Component({
  selector: 'list-comparator-in-dropdown',
  templateUrl: './list-comparator-in-dropdown.component.html'
})
export class ListComparatorInDropdownComponent {

  @Input() itemSelected: any = {};
  @Input() list: any = [];
  @Input() listComparator: any = [];
  @Input() type: string = '';

  closeDropdown(e:any){
    e.stopPropagation();
    this.itemSelected['showDropdown'] = false;
  }

}
