import { OnInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DownloadService } from '@shared/services/download/download.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'request-tracking',
  templateUrl: './request-tracking.component.html'
})
export class RequestTrackingComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  requestTracking:any ={
    orderOperationDate: true,
    searchingList: true,
    showScroll: false,
    filterValue: '',
    loaderImg: this.commonUtilsService.configureLoaderImg(),
    listTracking: [],
    filters: ['request', 'state'],
    typeSignature: {
        type: 'signature',
        icon: 'lf-icon-signature',
        request: this.translate.instant('request-tracking.signature'),
        creationDate: null,
        users: [],
        guid: ''
    },
    typeCertifiedEmail: {
        type: 'certifiedEmail',
        icon: 'lf-icon-certified-mail',
        request: this.translate.instant('request-tracking.certified-email'),
        creationDate: null,
        users: [],
        guid: ''
    },
    typeCertifiedSMS: {
        type: 'certifiedSms',
        icon: 'lf-icon-certified-sms',
        request: this.translate.instant('request-tracking.certified-sms'),
        creationDate: null,
        users: [],
        guid: ''
    },
    typeCertifiedDocument: {
        type: 'certifiedDocument',
        icon: 'lf-icon-certified-document',
        request: this.translate.instant('request-tracking.certified-document'),
        creationDate: null,
        users: [],
        guid: ''
    },
    userConfigure: {
        stateId: 'progress',
        state: this.translate.instant('task-edit.in-progress'),
        audit: false
    },
    requestFilter: {
      name: this.translate.instant('request-tracking.request')
    },
    stateFilter: {
        name: this.translate.instant('common.state')
    },
    statesListOption: []
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private filterColumnService: FilterColumnService,
    private loader: LoaderFactory,
    private downloadService: DownloadService,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal) { }

  @Input() document: any = null;

  ngOnInit(): void {
    this.configureCalls();
  }

  configureCalls(){
      let that: any = this.requestTracking;
      that.document = this.document;
      if(this.document && this.document.reportDocumentId){
          that.documentObjectId = this.document.reportDocumentId;
          that.callDestinations = 'reportdocuments/getdestinations';
          that.callSignature = 'reportdocuments/signaudit';
          that.callCertifiedAudit = 'reportdocuments/certifiedaudit';
          that.callCertifiedEmailAudit = 'reportdocuments/certifiedemailaudit/';
          that.callCertifiedSMSAudit = 'reportdocuments/certifiedsmsaudit/';
          that.callSignTracking = 'reportdocuments/signtracking/';
          that.document.nameWithoutExtension = this.document.title;
      }else{
          that.documentObjectId = this.document.documentObjectId;
          that.callDestinations = 'documentobjects/getdestinations';
          that.callSignature = 'documentobjects/signaudit';
          that.callCertifiedAudit = 'documentobjects/certifiedaudit';
          that.callCertifiedEmailAudit = 'documentobjects/certifiedemailaudit/';
          that.callCertifiedSMSAudit = 'documentobjects/certifiedsmsaudit/';
          that.callSignTracking = 'documentobjects/signtracking/';
          that.document.nameWithoutExtension = this.document.name.substr(0, this.document.name.lastIndexOf('.'));
      }
      this.getDestinations();
  }

  getDestinations(){
      this.apiService.get(this.requestTracking.callDestinations, this.requestTracking.documentObjectId)
          .then((data:any)=>{
              data.forEach((receiver:any) => {
                switch(receiver.service){
                  case 'signature':
                      this.getRowSignature(receiver);
                      break;
                  case 'certifiedEmail':
                  case 'certifiedSms':
                      this.getRowCertifiedEmail(receiver);
                      break;
                  case 'certifiedDocument':
                      this.getRowCertifiedDocument(receiver);
                      break
                  default:
                      break;
              }
              });

              this.requestTracking.filterListTracking = this.requestTracking.listTracking;
              this.getFilters();
              setTimeout(()=>{
                  let heightSum = 0;
                  for(let i = 0; i < this.requestTracking.filterListTracking.length; i++){
                    let element: any = $('#request-tracking-list-'+i);
                      heightSum += element.innerHeight();
                  }
                  if(heightSum > 330){
                      this.requestTracking.showScroll = true;
                  }
                  this.requestTracking.searchingList = false;
              })
          }, (errorData:any)=>{
            this.requestTracking.searchingList = false;
          })
  }


  getRowSignature(receiver:any){
      let rowNew:any = cloneDeep(this.requestTracking.typeSignature);
      let containerUsers:any = receiver.emails.indexOf(';')!==-1? receiver.emails.split(';') : [receiver.emails];
      containerUsers.forEach((mail:any) => {
        let userSign:any = cloneDeep(this.requestTracking.userConfigure);
        userSign.mail = mail;
        userSign.addressee = mail;
        userSign.audit = receiver.auditDocument;
        userSign.id = this.commonUtilsService.createId();
        if(receiver.finished){
            userSign.stateId = 'completed';
            userSign.state = this.translate.instant('common.completed');
        }
        rowNew.guid = receiver.guid;
        rowNew.users.push(userSign);
        this.requestTracking.statesListOption.push(userSign);
      });

      rowNew.onlyFirst = true;
      rowNew.creationDate = receiver.creationDate;
      this.requestTracking.listTracking.push(rowNew);
  }

  getRowCertifiedEmail(receiver:any){
      let rowNew:any = receiver.service === 'certifiedSms'? cloneDeep(this.requestTracking.typeCertifiedSMS) : cloneDeep(this.requestTracking.typeCertifiedEmail);
      let userCertificate = cloneDeep(this.requestTracking.userConfigure);
      userCertificate.mail = receiver.emails;
      userCertificate.phone = receiver.phones;
      userCertificate.audit = receiver.auditDocument;
      userCertificate.id = this.commonUtilsService.createId();
      userCertificate.addressee = receiver.service === 'certifiedSms'? receiver.phones : receiver.emails;
      if(receiver.finished){
          userCertificate.stateId = 'completed';
          userCertificate.state = this.translate.instant('common.completed');
      }
      let addedRow = false;
      for(let i = 0; i < this.requestTracking.listTracking.length; i++){
          let listElement = this.requestTracking.listTracking[i];
          if(listElement.guid === receiver.guid){
              addedRow = true;
              listElement.users.push(userCertificate);
              break;
          }
      }
      if(!addedRow){
          rowNew.guid = receiver.guid;
          rowNew.creationDate = receiver.creationDate;
          rowNew.onlyFirst = false;
          rowNew.users.push(userCertificate);
          this.requestTracking.listTracking.push(rowNew);
      }
      this.requestTracking.statesListOption.push(userCertificate);
  }

  getRowCertifiedDocument(receiver:any){
      let rowNew = cloneDeep(this.requestTracking.typeCertifiedDocument);
      let userCertificate = cloneDeep(this.requestTracking.userConfigure);
      userCertificate.audit = receiver.auditDocument;
      if(receiver.finished){
          userCertificate.stateId = 'completed';
          userCertificate.state = this.translate.instant('common.completed');
      }
      rowNew.guid = receiver.guid;
      rowNew.creationDate = receiver.creationDate;
      rowNew.onlyFirst = true;
      rowNew.users.push(userCertificate);
      this.requestTracking.listTracking.push(rowNew);
      this.requestTracking.statesListOption.push(userCertificate);
  }

  getFilters(){
    this.requestTracking.filters.forEach((filter:any) => {
      let list = filter === 'state'? this.requestTracking.statesListOption : this.requestTracking.listTracking;
      this.requestTracking[filter+'List'] = this.filterColumnService.extractList(filter,  list);
      this.requestTracking[filter+'ListFilter'] = [];
    });
  }

  downloadAudit(requestOption:any, user:any) {
      switch(requestOption.type){
          case 'signature':
              this.loader.openLoader('documentation-list-audit-document');
              this.downloadSignature();
              break;
          case 'certifiedEmail':
              this.loader.openLoader('documentation-list-audit-document');
              this.downloadCertifiedEmail(requestOption, user);
              break;
          case 'certifiedSms':
              this.loader.openLoader('documentation-list-audit-document');
              this.downloadCertifiedSMS(requestOption, user);
              break;
          case 'certifiedDocument':
              this.loader.openLoader('documentation-list-audit-document');
              this.downloadCertifiedDocument();
              break;
          default:
              break;
      }
  }

  downloadSignature(){
      this.apiService.getBlob(this.requestTracking.callSignature, this.requestTracking.documentObjectId)
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-audit-document']);
              let text:string = this.translate.instant('request-tracking.audit2') + '_' + this.translate.instant('request-tracking.signature').toLowerCase() + '_' + this.requestTracking.document.nameWithoutExtension
              this.downloadService.triggerDownload(data.blob, text);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.audit-document-error'), 'error:accept:warning');
          })
  }

  downloadCertifiedEmail(requestOption:any, user:any){
      this.apiService.getBlob(this.requestTracking.callCertifiedEmailAudit + this.requestTracking.documentObjectId, requestOption.guid, 'post', {email: user.mail})
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-audit-document']);
              let name :string = user.mail.indexOf('.') !== -1?user.mail.replace(/\./g, "_") : user.mail;
              this.downloadService.triggerDownload(data.blob, this.translate.instant('request-tracking.audit2') + '_' + name + '_' + this.requestTracking.document.nameWithoutExtension);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.audit-document-error'), 'error:accept:warning');
          })
  }

  downloadCertifiedSMS(requestOption:any, user:any){
      this.apiService.getBlob(this.requestTracking.callCertifiedSMSAudit + this.requestTracking.documentObjectId, requestOption.guid, 'post', {phone: user.phone})
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-audit-document']);
              this.downloadService.triggerDownload(data.blob, this.translate.instant('request-tracking.audit2') + '_' + user.phone + '_' + this.requestTracking.document.nameWithoutExtension);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.audit-document-error'), 'error:accept:warning');
          })
  }

  downloadCertifiedDocument(){
      this.apiService.getBlob(this.requestTracking.callCertifiedAudit, this.requestTracking.documentObjectId)
          .then((data:any)=>{

              this.loader.closeLoader(['documentation-list-audit-document']);
              let text: string = this.translate.instant('request-tracking.audit2') + '_' + this.translate.instant('request-tracking.certified-document').replace(/ /g, "-").toLowerCase() + '_' + this.requestTracking.document.nameWithoutExtension
              this.downloadService.triggerDownload(data.blob, text);
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('documentation-list.audit-document-error'), 'error:accept:warning');
          })
  }

  showTracking(requestOption:any){
      switch(requestOption.type){
          case 'signature':
          case 'certifiedEmail':
          case 'certifiedSms':
          case 'certifiedDocument':
              this.trackingSignature(requestOption);
              break;
          default:
              break;
      }
  }

  trackingSignature(requestOption:any){
      this.loader.openLoader('documentation-list-tracking-signature');
      this.apiService.get(this.requestTracking.callSignTracking + this.requestTracking.documentObjectId, requestOption.guid)
          .then((data:any)=>{
              this.loader.closeLoader(['documentation-list-tracking-signature']);
              window.open(data, '_blank');
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  selectTypeOperation(e:any, type:any){
      let item = e.source;
      let listFilter:any = this.requestTracking[type+'ListFilter'];
      this.requestTracking[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      this.requestTracking[type+'Filter'].hasFilter = this.requestTracking[type+'ListFilter'].length;
      this.filterRequest(type);
  }

  filterRequest(type:any = ''){
    let that: any = this.requestTracking
    let listTracking:any = [];
    listTracking = type === 'addressee' || type === 'state'? this.filterColumnService.filterByWordChildren(that.filterValue, that.listTracking, type, 'users') : this.filterColumnService.filterByWord(that.filterValue, that.listTracking, 'request');
    this.requestTracking.filters.forEach((filter:any) => {
      let filteredList = that[filter+'ListFilter'];
      listTracking = filter === 'state'? this.filterColumnService.filterBycolumnChildren(listTracking, filteredList, filter, 'users') : this.filterColumnService.filterBycolumn(listTracking, filteredList, filter);
    });
    that.filterListTracking = listTracking;
  }

  orderDate(){
      this.requestTracking.orderOperationDate = !this.requestTracking.orderOperationDate;
  }

  resetFilter(e:any){
      e.stopPropagation();
      e.preventDefault();
      if(this.requestTracking.searchUsers){
          this.requestTracking.searchUsers = false;
      }
      this.requestTracking.filterValue = '';
      this.filterRequest();
  }


  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id)
  }



  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

  handleAccept() {
    this.activeModal.close({
      result: 'ok'
    });
  };


}
