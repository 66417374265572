import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep, differenceBy, filter, find, keyBy, remove } from 'lodash';

@Component({
  selector: 'users-admin-security-form',
  templateUrl: './users-admin-security-form.component.html'
})
export class UsersAdminSecurityFormComponent implements OnInit, OnDestroy {
  id: any = this.route.snapshot.params['id'];
  type: string = this.id?this.route.snapshot.params['type']:this.route.snapshot.data['type'];
  subscribers : any = {};
  usersAdminSecurityForm: any = {
    securityLoaded: false,
    items: [],
    clientsById: null,
    itemsByProduct: {},
    checkAll: this.translate.instant('users-admin.security-form.all-clients'),
    checkUncheck: this.translate.instant('users-admin.security-form.check-uncheck-all'),
    permissions: [
        {
            info: this.translate.instant('users-admin.security-form.columns-info.administration'),
            caption: this.translate.instant('users-admin.security-form.columns.administration'),
            id: 'admin'
        },
        {
            info: this.translate.instant('users-admin.security-form.columns-info.manage'),
            caption: this.translate.instant('users-admin.security-form.columns.manage'),
            id: 'manage'
        },
        {
            info: this.translate.instant('users-admin.security-form.columns-info.customize'),
            caption: this.translate.instant('users-admin.security-form.columns.customize'),
            id: 'customize'
        },
        {
            info: this.translate.instant('users-admin.security-form.columns-info.edit'),
            caption: this.translate.instant('users-admin.security-form.columns.edit'),
            id: 'edit'
        }
    ],

    clientsList: {
        filter: {
            name: ''
        },
        openedItem: null,
        items: null,
        selectedCount: 0,
        none: true,
        all: false
    },
  }
  constructor(private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService) { }
  @Input() operation: any = '';
  @Input() model: any = {};
  @Output() saveSecurity = new EventEmitter();

  ngOnInit(): void {
    this.loader.openLoader('security-form');
    this.openWatchers();
    this.loadClients();
  }

  openWatchers(){
    this.subscribers.resetSecurityFormBroadcast = this.broadcastService.subscribe('resetSecurityForm', (data:any)=>{
      this.reset();
    });
    this.subscribers.savePersonBroadcast = this.broadcastService.subscribe('savePerson', (data:any)=>{
      let security = this.checkFields();
      if(security){
        this.saveSecurity.emit({security: security});
      }
    });
  }

  loadClients(){
    let access = this.operation === 'security'?'admin':'read';
    this.apiService.get('clients/root/false/' + access)
      .then((data:any)=>{
          this.usersAdminSecurityForm.clientsList.items = data;
          this.usersAdminSecurityForm.clientsById = keyBy(data, 'clientId');
          this.loadProducts();
      }, (error:any)=>{})
  }

  loadProducts(){
    let operation = '';
    if (this.operation === 'see-security') {
        operation = 'licensedToMe';
    } else {
        let userId = 0;
        if (this.type === 'user' && this.id) {
            userId = this.id;
        }
        operation = 'administrableFor/' + userId;
    }
    this.apiService.get('products', operation)
      .then((data:any)=>{
        data.forEach((product:any) => {
            let item = {
                selected: false,
                clientsList: false,
                product: {
                    productId: product.productId,
                    name: product.name
                },
                disabled: !product.administrable,
                clients: [],
                clientsCount: 0,
                groupsCount: 0,
                permissions: [false, false, false, false],
                groupsPermissions: [false, false, false, false],
                hasRoles: product.hasRoles
            };
            this.usersAdminSecurityForm.items.push(item);
            this.usersAdminSecurityForm.itemsByProduct[product.productId] = item;
        });
        if(this.operation === 'see-security' || this.operation === 'security'){
            this.loadSecurity();
        }else if(this.operation === 'new' || this.operation === 'convert'){
            this.closeLoader();
        }
      }, (error:any)=>{})
  }

  loadSecurity(){
    if(!this.usersAdminSecurityForm.securityLoaded){
      this.loadProductSecurity();
      this.loadClientSecurity();
      if(this.model && this.model.userGroups && this.model.userGroups.length){
          this.configureGroups();
      }
      this.closeLoader();
      this.usersAdminSecurityForm.securityLoaded = true;
    }
  }

  loadProductSecurity(){
    let products = this.type === 'user' ? this.model.security.userProducts : this.model.security.usersGroupProducts;
    products.forEach((product:any) => {
      let item = this.usersAdminSecurityForm.itemsByProduct[product.productId];
      if(item){
          item.selected = true;
          this.loadPermissions(item.permissions, product.accessMask);
          if(this.type === 'user'){
              this.loadPermissions(item.groupsPermissions, product.groupsAccessMask);
              if(product.groupsAccessMask !== 'none'){
                  item.group = true;
              }
              if(!this.globalCfg.caps.regularAdminsDeletion && this.context.user.userId.toString() === this.id){
                  item.disabled = true;
              }
          }
      }
    });
  }

  loadClientSecurity(){
    let productClients = this.type === 'user' ? this.model.security.userProductClients : this.model.security.usersGroupProductClients;
    productClients.forEach((productClient:any) => {
      let item = this.usersAdminSecurityForm.itemsByProduct[productClient.productId];
      if(this.usersAdminSecurityForm.clientsById !== null){
        let client = this.usersAdminSecurityForm.clientsById[productClient.clientId];
        if(item && client){
            item.selected = true;
            let itemClient = {
                clientId: client.clientId,
                name: client.name,
                group: false,
                permissions: [false, false, false, false],
                groupsPermissions: [false, false, false, false]
            };
            this.loadPermissions(itemClient.permissions, productClient.accessMask);
            item.clients.push(itemClient);
            if(productClient.groupsAccessMask !== 'none' && this.type === 'user'){
                item.group = true;
                itemClient.group = true;
                item.groupsCount++;
            } else {
                item.clientsCount++;
            }
            this.setItemPermissions(item, 'permissions', itemClient.permissions);
            if(this.type === 'user'){
                this.setGroupItemPermissions(item, 'groupsPermissions', itemClient.groupsPermissions);
            }
        }
      }
    });
  }

  loadPermissions(permissions: any, accessMask: any) {
      permissions[0] = accessMask === 'all' || accessMask.indexOf('admin') !== -1;
      permissions[1] = permissions[0] || accessMask.indexOf('manage') !== -1;
      permissions[2] = permissions[1] || accessMask.indexOf('design') !== -1;
      permissions[3] = permissions[2] || accessMask.indexOf('modify') !== -1;
  }

  setItemPermissions(item: any, type: any, itemClientPermissions: any) {
      let firstClient = item.clients.length === 1;
      itemClientPermissions.forEach((client:any, index: any) => {
        item[type][index] = (item[type][index] || firstClient) && client;
      })
  }

  setGroupItemPermissions(item: any, type: any, itemClientPermissions: any) {
      let firstClient = item.clients.length === 1;
      itemClientPermissions.forEach((client:any, index: any) => {
          item[type][index] = (item[type][index] || firstClient) && (client || (item[type][index] && item.group));
      })
  }

  configureGroups(){
    this.model.userGroups.forEach((group:any) => {
      group.usersGroupProductClients.forEach((productGroup:any) => {
        this.usersAdminSecurityForm.items.forEach((item:any) => {
          if(item.product && productGroup.productId === item.product.productId){
              let groupsPermissions = [false, false, false, false];
              groupsPermissions = this.configureClientsPermissions(productGroup, item);
              let permissions = groupsPermissions;
              item.disabledCheckProduct = true;
              let client = this.searchClient(productGroup.clientId);
              if(client){
                  let existClient = this.checkClient(item, client, permissions);
                  if(!existClient){
                      item.groupsCount++;
                      item.disabledSelectAll = true;
                      item.clients.push({
                          clientId: client.clientId,
                          name: client.name,
                          disabledRemove: true,
                          permissions: cloneDeep(item.permissions),
                          groupsPermissions: groupsPermissions
                      });
                  }
              }
          }
        })
      })
      if(group.userGroupProducts && group.userGroupProducts.length){
          this.configurePermissionsProduct(group);
      }
    })
  }

  configureClientsPermissions(productGroup: any, item: any){
      if(productGroup.accessMask.indexOf('all') !== -1 || productGroup.accessMask.indexOf('admin') !== -1){
          item.selected = true;
          return [true, true, true, true];
      }else if(productGroup.accessMask.indexOf('manage') !== -1){
          item.selected = true;
          return [false, true, true, true];
      }else if(productGroup.accessMask.indexOf('design') !== -1){
          item.selected = true;
          return [false, false, true, true];
      }else if(productGroup.accessMask.indexOf('modify') !== -1){
          item.selected = true;
          return [false, false, false, true];
      }else{
          return [false, false, false, false];
      }
  }

  searchClient(clientId: any){
    for(let i = 0; i < this.usersAdminSecurityForm.clientsList.items.length; i++){
        let client = this.usersAdminSecurityForm.clientsList.items[i];
        if(client.clientId === clientId){
            return client;
        }
    }
  }

  checkClient(item: any, clientSelected: any, permissions: any){
      for(let i = 0; i < item.clients.length; i++){
          let client = item.clients[i];
          if(clientSelected && client.clientId === clientSelected.clientId){
              item.disabledSelectAll = true;
              client.disabledRemove = true;
              client.groupsPermissions = permissions;
              return true;
          }
      }
      return false;
  }

  configurePermissionsProduct(group: any){
    group.userGroupProducts.forEach((product:any) => {
      this.usersAdminSecurityForm.items.forEach((item:any) => {
        if(item.product && product.productId === item.product.productId){
            item.selected = true;
            item.disabledCheckProduct = true;
            item.productAccessMask = product.accessMask;
            item.disabledAllClients = true;
            item.groupsPermissions = this.configureClientsPermissions(product, item);
        }
      })
    })
  }

  itemSelected(item: any, newValue: any) {
      item.selected = newValue;
      if (!item.selected) {
          item.selected = !item.selected;
          if(item.hasRoles && this.model && this.model.rolesCount){
            this.apiService.get('productroles/can-remove/product/'+item.product.productId+'/from-user', this.id)
              .then((data:any)=>{
                if(data){
                  this.openRoleErrorModal(data, 'errorDeleteProductUser');
                }else{
                  this.confirmSelectedProduct(item);
                }
              }, (error:any)=>{
                this.errorModal(this.translate.instant('common.unexpected-error'));
              })
          }else{
              this.confirmSelectedProduct(item);
          }
      }
  }

  confirmSelectedProduct(item:any){
      item.selected = !item.selected;
      this.resetItem(item);
      this.closeClientsList();
  }

  reset(){
    this.usersAdminSecurityForm.items.forEach((item:any) => {
      this.resetItem(item);
    })
  }

  resetItem(item: any){
      item.selected = false;
      item.clientsCount = 0;
      item.groupsCount = 0;
      item.clients.length = 0;
      item.permissions.forEach((itemPermission:any) => {
        itemPermission = false;
      })
  }

  closeClientsList(e: any=undefined){
      if(e){
          e.stopPropagation();
      }
      if (this.usersAdminSecurityForm.clientsList.openedItem) {
          this.usersAdminSecurityForm.clientsList.openedItem.clientsList = false;
          this.usersAdminSecurityForm.clientsList.openedItem = null;
      }
  }

  permissionChange(idxPermission: any, item: any, client: any=undefined){
      let permissions = item.permissions;
      if(client){
        client.permissions[idxPermission] = !client.permissions[idxPermission];
        permissions = client.permissions;
      }else{
        item.permissions[idxPermission] = !item.permissions[idxPermission];
      }
      if(permissions[idxPermission]){ //Permiso marcado
          //Hay que marcarlo y todos los permisos a su derecha
          for(let i = idxPermission; i < permissions.length; i++){
            permissions[i] = true;
            if(client){ //Estamos marcando a nivel de cliente
                //Si todos los clientes están marcados se marca el producto
                this.setParentPermissions(item);
            }else{ //Estamos marcando a nivel de producto
                //Marcamos en todos los clientes
                item.clients.forEach((client:any) => {
                    client.permissions[i] = true;
                });
            }
          }
      }else{ //Permiso desmarcado
          //Hay que desmarcaro y todos los permisos a su izquierda
          for(let j = 0; j <= idxPermission; j++){
              permissions[j] = false;
              if(client){ //Estamos desmarcando a nivel de cliente
                  //Desmarcamos el permiso del producto
                  item.permissions[j] = false;
              }else{ //Estamos marcando a nivel de producto
                  //Desmarcamos de todos los clientes
                  item.clients.forEach((client:any) => {
                      client.permissions[j] = false;
                  });
              }
          }
      }
  }

  setParentPermissions(item: any) {
      for(let i = 0; i < item.permissions.length; i++){
          let allClientsChecked = true;
          if(!item.clients.length){
              allClientsChecked = false;
              if(item.group && item.groupsPermissions.includes(true)){
                  item.disabledAllClients = true;
              }
          }
          item.clients.forEach((client:any) => {
              if(!client.permissions[i]){
                  allClientsChecked = false;
              }
          });
          item.permissions[i] = allClientsChecked;
      }
  }

  openClientsList(item: any) {
      if(item.selected && !item.disabled && !item.disabledAllClients && !item.clientsList){
          this.closeClientsList();
          let selectedCount = 0;
          this.usersAdminSecurityForm.clientsList.none = item.clients.length === 0;
          this.usersAdminSecurityForm.clientsList.items.forEach((client:any) => {
              let selectedClient = find(item.clients, ['clientId', client.clientId]);
              client.disabled = false;
              client.selected = false;
              if (selectedClient) {
                  client.selected = true;
                  client.group = selectedClient.group;
                  if(selectedClient.disabledRemove){
                      client.disabled = true;
                  }
                  selectedCount++;
              }
          });

          this.usersAdminSecurityForm.clientsList.selectedCount = selectedCount;
          this.usersAdminSecurityForm.clientsList.openedItem = item;
          this.usersAdminSecurityForm.clientsList.all = item.clients.length === this.usersAdminSecurityForm.clientsList;
          item.clientsList = true;
      }
  }

  clearClients(item: any){
      if (this.usersAdminSecurityForm.clientsList.none) {
          item.clients.length = 0;
          item.clientsCount = 0;
          this.usersAdminSecurityForm.clientsList.none = true;
          this.usersAdminSecurityForm.clientsList.all = false;
          this.usersAdminSecurityForm.clientsList.items.forEach((client:any) => {
              client.selected = false;
          });
          if(item.group && item.groupsPermissions.includes(true)){
              item.disabledAllClients = true;
          }
      }
  }

  selectClient(client: any, item: any) {
      if(client.selected){
          this.usersAdminSecurityForm.clientsList.selectedCount++;
      }else{
          this.usersAdminSecurityForm.clientsList.selectedCount--;
      }
      this.setupLinkChecks();
  }

  selectAllClients(item: any){
      let countDisabled = 0;
      this.usersAdminSecurityForm.clientsList.items.forEach((client:any) => {
          if(client.disabled){
              countDisabled++;
          }else{
              client.selected = !this.usersAdminSecurityForm.clientsList.all;
          }
      });
      if(this.usersAdminSecurityForm.clientsList.all){
          this.usersAdminSecurityForm.clientsList.selectedCount = this.usersAdminSecurityForm.clientsList.items.length;
      }else{
          this.usersAdminSecurityForm.clientsList.selectedCount = countDisabled;
      }
      this.setupLinkChecks();
  }

  setupLinkChecks(){
      this.usersAdminSecurityForm.clientsList.none = this.usersAdminSecurityForm.clientsList.selectedCount === 0;
      this.usersAdminSecurityForm.clientsList.all = this.usersAdminSecurityForm.clientsList.items.length === this.usersAdminSecurityForm.clientsList.selectedCount;
  }

  selectClientsOk(item: any, e: any){
      e.stopPropagation();
      let selectedClients = filter(this.usersAdminSecurityForm.clientsList.items, { selected: true });
      let clientsToRemove = differenceBy(item.clients, selectedClients, 'clientId');
      let clientsToAdd = differenceBy(selectedClients, item.clients, 'clientId');
      if(item.hasRoles && this.model && this.model.rolesCount && ((clientsToRemove && clientsToRemove.length) || (clientsToAdd && clientsToAdd.length))){
          let selectedClientsString = '?';
          selectedClients.forEach((client:any, index: any) => {
            selectedClientsString += index===selectedClients.length-1?'clientIds='+client.clientId:'clientIds='+client.clientId+'&';
          })
          this.apiService.get('productroles/can-reassign/product/'+item.product.productId+'/clients/from-user/'+this.id, selectedClientsString)
            .then((data:any)=>{
              if(data){
                this.openRoleErrorModal(data, 'errorDeleteSomeOrganizationsUser');
              }else{
                this.confirmateSelectedClient(item, clientsToRemove, clientsToAdd);
              }
            }, (error:any)=>{
              this.errorModal(this.translate.instant('common.unexpected-error'));
            })
      }else{
          this.confirmateSelectedClient(item, clientsToRemove, clientsToAdd);
      }
  }

  confirmateSelectedClient(item: any, clientsToRemove: any, clientsToAdd: any){
      clientsToAdd.forEach((client:any, index: any) => {
          item.clients.push({
              clientId: client.clientId,
              name: client.name,
              permissions: [false, false, false, false],
              groupsPermissions: [false, false, false, false]
          });
          item.disabledAllClients = false;
          if(client.group){
              item.groupsCount++;
          }else{
              item.clientsCount++;
          }
      });
      if(clientsToRemove.length){
            item.clients = item.clients.filter((client:any) => {
            let deleteClient = clientsToRemove.includes(client);
            if(deleteClient){
                if(client.group){
                    item.groupsCount--;
                }else{
                    item.clientsCount--;
                }
            }
            return !deleteClient;
          });
          this.setParentPermissions(item);
      }
      this.closeClientsList();
  }

  removeClient(item: any, client: any) {
      if(item.hasRoles && this.model && this.model.rolesCount){
          let selectedClientsString = '?';
          item.clients.forEach((itemClient:any, index: any) => {
              if(itemClient.clientId !== client.clientId){
                  selectedClientsString += index===item.clients.length-1?'clientIds='+itemClient.clientId:'clientIds='+itemClient.clientId+'&';
              }else if(index===item.clients.length-1 && selectedClientsString.lastIndexOf('&') === selectedClientsString.length-1){
                  selectedClientsString = selectedClientsString.slice(0, -1);
              }
          })
          this.apiService.get('productroles/can-reassign/product/'+item.product.productId+'/clients/from-user/'+this.id, selectedClientsString)
            .then((data:any)=>{
              if(data){
                  this.openRoleErrorModal(data, 'errorDeleteOrganizationUser');
              }else{
                  this.confirmateRemoveClient(item, client);
              }
            }, (error:any)=>{
                this.errorModal(this.translate.instant('common.unexpected-error'));
            })
      }else{
          this.confirmateRemoveClient(item, client);
      }
  }

  confirmateRemoveClient(item: any, client: any){
      remove(item.clients, { clientId: client.clientId });
      if(client.group){
          item.groupsCount--;
      }else{
          item.clientsCount--;
      }
      this.setParentPermissions(item);
  }

  toggleSearch(){
      this.usersAdminSecurityForm.clientsList.filter.name = '';
  }

  openRoleErrorModal(list: any, type: any){
    let configuration = {
        list: list,
        type: type
    }
    this.modalService.roleErrorModal(configuration);
  }

  errorModal(text: any){
      this.modalService.adviceModal(text, 'error:accept:warning');
  }

  closeLoader(){
      this.loader.closeLoader(['security-form', 'front-login-login']);
  }

  checkFields(){
      let products: any = [];
      let productClients: any = [];
      this.usersAdminSecurityForm.items.forEach((item:any) => {
          if(item.selected){
              let product: any = {
                  productId: item.product.productId
              };
              if(this.type !== 'group'){
                  product.userId = this.id;
              }else{
                  product.groupId = this.id;
              }
              if(item.clients && item.clients.length){
                  item.clients.forEach((client:any) => {
                      let productClient = cloneDeep(product);
                      productClient.clientId = client.clientId;
                      productClient.accessMask = this.getAccessLevel(client);
                      if(!client.group || (client.group && productClient.accessMask !== 'read')){
                          productClients.push(productClient);
                      }
                  });
              }else{
                  product.accessMask = this.getAccessLevel(item);
                  products.push(product);
              }
          }
      });

      let security;
      if(this.type !== 'group'){
          security = {
              any: !!(products.length || productClients.length),
              userProducts: products,
              userProductClients: productClients
          };
      }else{
          security = {
              any: true,
              usersGroupProducts: products,
              usersGroupProductClients: productClients
          };
      }

      return security;
  }

  getAccessLevel(item: any){
      let accessLevels = ['admin', 'manage', 'design', 'modify'];
      for(let i = 0; i < item.permissions.length; i++){
          if(item.permissions[i]){
              return accessLevels[i];
          }
      }
      return 'read';
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
