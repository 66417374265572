<div class="field-type-logo__form-block"
    [ngClass]="{'field-type-logo__form-block--disabled': type === 'edit' && !canAdmin,
                'field-type-logo__form-block--error': field.error,
                'field-type-logo__form-block--favicon': type === 'favicon'}">
    <label class="field-type-logo__input-text">
        <div class="common__label-block"
            [ngClass]="field.evaluatedValue?.hasReferences ? 'common__label-block--two-icon' : 'common__label-block--one-icon'">
            <span class="common__input-text common__input-text"
                [ngClass]="{'common__input-text--required': field.error,
                            'common__input-text--no-editable': type === 'edit' && !canAdmin}">{{titleField}}</span>
            <span class="common__text-required" [ngShow]="field.required">*</span>
            <div class="common__info-icon-block common__info-icon-block--visible" [ngHide]="field.noEditable || !field.description">
                <tooltip-info [description]="field.description" [field]="field"></tooltip-info>
            </div>
        </div>
    </label>
    <i class="lf-icon-up-file common__input-icon common__input-icon-active"
        (click)="openFileInput()"
        [ngClass]="{'common__input-icon-active--disabled': field.noEditable || (type === 'edit' && !canAdmin)}"> </i>
    <input type="file"
        class="field-type-logo__input-logo"
        [id]="field.id"
        [name]="field.id"
        accept="image/*"
        (change)="uploadLogo($event)"
        [disabled]="type === 'edit' && !canAdmin">
    <div class="field-type-logo__img-block">
        <div class="field-type-logo__img-text">
            <p class="field-type-logo__text-logo" [ngClass]="{'field-type-logo__text-logo--favicon': type === 'favicon'}">{{logoName}}</p>
        </div>
        <img class="field-type-logo__client-img img-responsive"
            [ngClass]="{'field-type-logo__logo-size': type === 'favicon'}"
            alt="Logo del cliente"
            *ngIf="logo !== ''"
            [src]="logo">
    </div>
    <button class="field-type-logo__modify-logo"
        *ngIf="logo !== '' && type !== 'favicon' && !field.noEditable"
        (click)="openLogoModal()">
        <i class="lf-icon-simulator-area field-type-logo__modify-icon"></i>
        <span [afTranslate]="'configure-image.adjust-size'"></span>
    </button>
</div>
