import { Component, Input, Output, EventEmitter,OnChanges, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'evaluation-tree',
  templateUrl: './evaluation-tree.component.html'
})
export class EvaluationTreeComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  evaluationTree: any ={
    level: 1
  }

  constructor(private globalCfg: GlobalCfgFactory) { }
  @Input() childrenList: any = [];
  @Input() level: number = 1;
  @Input() evaluatedConcept: any;
  @Output() launchActionTreeEmit = new EventEmitter();



  ngOnChanges(changes: SimpleChanges): void {
    if(changes['level'] && changes['level'].currentValue){
      this.evaluationTree.level = changes['level'].currentValue + 1;
    }
  }

  openTree(e:any, children:any){
    e.stopPropagation();
    if(this.evaluationTree.level < 9 && (children.children.length || children.concepts.length)){
        children.openTree = !children.openTree;
        if(!children.openTree && children.children.length){
            this.closeChildren(children.children);
        }
    }
  }

  closeChildren(children:any){
    children.forEach((child:any) => {
      child.openTree = false;
      if(child.children && child.children.length){
          this.closeChildren(child.children);
      }
    });
  }

  launchActionTreeOn(e:any){
    this.goTo(e.children)
  }

  goTo(children:any){
    if(!children.active){
        this.launchActionTreeEmit.emit({ children: children });
    }
  }

}
