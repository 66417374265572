import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConceptInfoParentService } from '../utils/concept-info-parent.service';

@Component({
  selector: 'concept-info-default',
  templateUrl: './concept-info-default.component.html'
})
export class ConceptInfoDefaultComponent implements OnInit {
  custom: any = false
  conceptInfo:any = {}
  constructor( private conceptInfoParent: ConceptInfoParentService,
    private activeModal: NgbActiveModal) {

  }

  @Input() concept: any = null;

  ngOnInit(): void {
    let description:any = this.conceptInfoParent.extractRelatedOrReferences('data-related="', this.concept.description);
    this.conceptInfo.description = this.conceptInfoParent.extractRelatedOrReferences('data-references="', description);
  }

  openRelated(item:any){
    item.modal==='related-information'? this.conceptInfoParent.openRelated(item) : this.conceptInfoParent.openReferences(item, this);
  }

  handleAccept() {
    this.activeModal.close({result: 'ok'})
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
