<div class="report-export">
  <div class="common__modal-header-block">
      <i class="lf-icon-send-excel common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'report-export.export-report'"></span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <div class="report-export__content">
      <field-type-text-simple [field]="reportExportModal.nameDocument"
          [preSelectItem]="reportExportModal.name"
          (itemSelected)="itemSelected($event.newValue)">
      </field-type-text-simple>
      <div class="report-export__block-select">
          <field-type-switch [label]="'report-export.open-file-after-exporting'| afTranslate"
                               [checked]="reportExportModal.openFile"
                               (callback)="reportExportModal.openFile = $event.value">
          </field-type-switch>
      </div>
      <div class="report-export__block-select">
          <field-type-switch [label]="'report-export.local-download'| afTranslate"
                               [checked]="reportExportModal.download"
                               (callback)="reportExportModal.download = $event.value">
          </field-type-switch>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'"></button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="handleAccept()"
          [afTranslate]="'common.export'"></button>
  </div>
</div>
