<div class="field-references">
  <div [ngShow]="fieldReferences.dependencies?.relatedConcept?.length || fieldReferences.dependencies?.length">
      <div class="field-references__left-block">
          <div class="field-references__text-block">
              <p class="field-references__field-text"
                  [afTranslate]="'field-items.related-concepts'">CONCEPTOS RELACIONADOS</p>
              <span>
                  <i class="field-references__icon-numeric">{{fieldReferences.numberDependencies}}</i>
                  <p class="field-references__text-dependences"
                      [afTranslate]="'field-items.dependencies'">DEPENDENCIAS</p>
              </span>
          </div>
          <span class="field-references__triangle-border"></span>
          <span class="field-references__triangle"></span>
      </div>

      <div class="field-references__rigth-block">
          <ul class="common__inner-list field-references__list">
              <li class="field-references__list-element"
                  *ngFor="let dependencies of fieldReferences.dependencies">
                  <p class="field-references__parent-folder"> {{dependencies.parentName}} <span> > </span>
                      <span *ngFor="let folder of dependencies.folders; let last = last"
                          class="field-references__folder">
                          {{folder.folderName}}
                          <span [ngHide]="last"> > </span>
                      </span>
                  </p>
                  <ul class="common__inner-list field-references__concept-list">
                      <li class="field-references__concept-name-list"
                          *ngFor="let concept of  dependencies.relatedConcept"
                          (click)="openConcept(concept)">
                          <i class="lf-icon-arrow-right field-references__icon-list"></i>
                          <parragraph-tooltip [text]="concept.relatedConceptName"></parragraph-tooltip>
                      </li>
                  </ul>
              </li>
          </ul>
      </div>
  </div>
  <div class="field-references__no-references-block"
      [ngShow]="!fieldReferences.dependencies?.relatedConcept?.length && !fieldReferences.dependencies?.length">
      <p class="field-references__no-references-text"
          [afTranslate]="'field-items.no-dependencies'">_No se han encontrado dependencias.</p>
  </div>
</div>
