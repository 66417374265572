<div class="subcontainer-multiple subcontainer-multiple--custom">
    <div class="subcontainer-multiple__table-duplicated"
        *ngFor="let subcontainer of subcontainerMultiple.subcontainerDuplicated; let index = index;let first = first">
        <div class="concept-form__container-border-custom">
            <div class="concept-form__subgroup-title-block-custom concept-form__subgroup-title-block-custom--subcontainer-multiple"
                [id]="'group-subcontainer-'+index">
                <div class="concept-form__block-icons concept-form__block-icons--multiple"
                    [ngShow]="(subcontainer.references.length && first) || subContainer.relatedInfo.length">
                    <tooltip-law
                        [concept]="subcontainerMultiple.concept"
                        class="concept-form__icon-subgroup concept-form__icon-subgroup--laws"
                        [references]="subcontainer.references">
                    </tooltip-law>
                      <i class="lf-icon-search-text concept-form__icons concept-form__icons--info-subcontainer"
                        (click)="openRelatedInfo(subContainer.relatedInfo)"
                        [ngShow]="subContainer.relatedInfo.length"
                        matTooltip="{{'common.related-info' | afTranslate }}"
                        matTooltipPosition="above"
                        matTooltipClass="above">
                    </i>
                </div>
                <div class="concept-form__tooltip-info concept-form__tooltip-info--subgroup"
                    [ngShow]="subcontainer.showInfo && !subcontainerMultiple.openLaw && first">
                    <p class="concept-form__tooltip-inner-text"
                        *ngFor="let subReference of subcontainer.references">
                        {{subReference.text}}
                    </p>
                </div>
                <div class="concept-list__cell-block"
                    [ngClass]="{'concept-list__cell-block--subcontainer-multiple': first,
                        'concept-list__cell-block--subcontainer-multiple-delete': !first,
                        'concept-list__cell-block--subcontainer-multiple-expand': first && subcontainerMultiple.expandMenu}">
                        <i class="lf-icon-important concept-form__icon-arrow"
                          [ngShow]="subcontainerMultiple.subContainer?.isHighlight && first"></i>
                        <span-tooltip class="concept-form__container-text-custom"
                            [ngClass]="{'concept-form__container-text-custom--no-highlight': !subcontainerMultiple.subContainer?.isHighlight}"
                            [text]="subcontainer.name"></span-tooltip>
                </div>
                <div class="subcontainer-multiple__clone-menu"
                    [ngShow]="first && !subcontainerMultiple.lockedSubcontainer">
                    <i class="lf-icon-add-round subcontainer-multiple__delete-icon"
                        (click)="duplicateSubcontainer()"
                        matTooltip="{{'subcontainer-multiple.new-entry' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </i>
                </div>
                <div class="subcontainer-multiple__clone-menu subcontainer-multiple__clone-menu--delete"
                    [ngShow]="!first && !subcontainerMultiple.lockedSubcontainer">
                    <i class="lf-icon-trash subcontainer-multiple__delete-icon"
                        (click)="deleteDuplicated(subcontainer)"
                        matTooltip="{{'subcontainer-multiple.delete-entry' | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below">
                    </i>
                </div>
            </div>
            <div class="concept-form__container-subgroup-fields-custom">
                <div *ngFor="let subField of subcontainer.fields">
                    <field-item *ngIf="subField.show && subField.showWithProperty && !subcontainer.hiddenInLandings && !subField.hiddenInLandings"
                        [ngHide]="subField.hidden"
                        [fieldInfo]="subField"
                        [fieldContainer]="subcontainer"
                        [evaluatedConcepts]="subcontainer.evaluatedFields"
                        [evaluatedFields]="subcontainerMultiple.concept.evaluatedFields"
                        [isPrivate]="subcontainerMultiple.isPrivate"
                        (evaluatedConceptChange)="changesInEvaluatedSubContainer(subField)"
                        [concept]="subcontainerMultiple.concept"
                        [conceptLocked]="subcontainerMultiple.conceptLocked">
                    </field-item>
                </div>
            </div>
        </div>
    </div>
</div>
