import { Component, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'field-assignments-modal',
  templateUrl: './field-assignments-modal.component.html'
})
export class FieldAssignmentsModalComponent {
  constructor(private globalCfg:GlobalCfgFactory, private activeModal:NgbActiveModal) { }

  @Input() data:any;

  skin: string = this.globalCfg.skin;

  handleAccept(){
    this.activeModal.close({result: 'ok'});
  }

  handleDismiss(){
    this.activeModal.close({result: 'cancel'});
  }
}
