import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ngShow]'
})
export class NgShowDirective implements OnChanges {
  @Input() ngShow: any;
  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['ngShow']){
      this.elementRef.nativeElement.classList.remove('common__hide');
      if(!this.ngShow){
        this.elementRef.nativeElement.classList.add('common__hide');
      }
    }
  }
}
