<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--expand': predefinedTemplates.fullScreen,
    'predefined-templates__template-box--min-height':!templateList.length,
    'predefined-templates__template-box--max-height':templateList.length}">

<div class="concept-legal-documentation__border-box"></div>
    <i class="predefined-templates__change-view-icon"
        (click)="maximizeScreen()"
        matTooltip="{{(predefinedTemplates.fullScreen? 'common.fold' : 'common.deploy') | afTranslate}}"
        matTooltipPosition="above"
        matTooltipClass="above"
        [ngClass]="predefinedTemplates.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"></i>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-textbooks box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text"
        [afTranslate]="'predefined-templates.templates'">Plantillas</p>
    <p class="predefined-templates__title-template"
        [afTranslate]="'predefined-templates.document-generate'">
        GENERA TUS DOCUMENTOS A PARTIR DE LAS PLANTILLAS PREDEFINIDAS
    </p>
    <ul class="predefined-templates__template-list"
        [ngClass]="{'predefined-templates__template-list--expand': predefinedTemplates.fullScreen,
                'predefined-templates__template-list--more-3-elements': (templateList.length > 3 && !predefinedTemplates.fullScreen) || (templateList.length > 11 && predefinedTemplates.fullScreen)}">
        <li class="predefined-templates__template-list-items"
            *ngFor="let template of templateList"
            [ngShow]="template.show">
            <i [class]="'predefined-templates__icon-col '+ (template.extension | extensionDocument)"></i>
            <span-tooltip class="predefined-templates__text-list"
              [text]="template.predefinedName"></span-tooltip>
            <div class="predefined-templates__template-menu"
                [ngHide]="predefinedTemplates.userPermissions === 'none'"
                (click)="createDocumentTemplate(template)">
                <i class="lf-icon-play predefined-templates__writing-icon"
                    matTooltip="{{'predefined-templates.new-document' | afTranslate}}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </i>
            </div>
        </li>
    </ul>

</div>
<!-- <form action="oos.aspx" target="_blank" method="post" auto-data="predefinedTemplates.editData" submit="predefinedTemplates.openEditPage"></form> -->
