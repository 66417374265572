import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'assign-manager',
  templateUrl: './assign-manager.component.html'
})
export class AssignManagerComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  assignManager: any = {
    showText: true,
    assignedManagerField: {
      typeConfiguration: 'assign-manager',
      fieldType: 'multiple',
      addContacts: false,
      required: true,
      typeCall: 'users',
      selectContacts: [],
      label: this.translate.instant('common.user')
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal) { }
  @Input() count: any = null;

  ngOnInit(): void {
    this.assignManager.countToShow = !this.count ? 1 : this.count;
  }

  receiveContacts(contacts:any){
    let that: any = this.assignManager
    that.assignedManagerField.selectContacts = contacts;
    that.assignedManagerField.error = false;
  }

  contactsClosed() {
    this.assignManager.showText = !this.assignManager.showText;
  }

  verifyDestinationList(){
    let that: any = this.assignManager
    that.assignedManagerField.error = false
    that.assignedManagerField.selectContacts.length? this.handleAccept() : that.assignedManagerField.error = true;
  }

  configureAssignManager(){
    let assigned: any = [];
    this.assignManager.assignedManagerField.selectContacts.forEach((selectedAssign:any) => {
        let assign = {
            type: selectedAssign.type,
            id: selectedAssign.listValueId,
            fullName: selectedAssign.fullName,
            isDeleted: selectedAssign.isDeleted
        }
        assigned.push(assign);
      });
    return assigned;
}

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        managers: this.configureAssignManager()//this.assignManager.assignedManagerField.selectContacts
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
