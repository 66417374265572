<div class="export-result-report">
    <div class="common__modal-header-block">
        <i class="lf-icon-send-excel common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'results-report.export-results-report'">Exportar resultados de la encuesta</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>

    <div class="export-result-report__block-body">
        <field-type-text-simple [field]="exportResultReport.nameDocument"
            [preSelectItem]="exportResultReport.name"
            (itemSelected)="itemSelected($event, 'name')">
        </field-type-text-simple>
        <div class="export-result-report__block-switch">
            <field-type-switch [label]="'export-result-report.export-field-selection' | afTranslate"
                        [checked]="exportResultReport.exportFields"
                        (callback)="showExportReport($event.value)">
            </field-type-switch>
            <div [ngShow]="exportResultReport.listSelected.length"
                class="export-result-report__block-selection-fields">
                <span>{{exportResultReport.numberFields}}</span>
                <i class="lf-icon-editable-form common__add-document__icon-edit add-alert__icon-edit"
                    (click)="exportResultReport.exportFieldsOpen = !exportResultReport.exportFieldsOpen"></i>
            </div>
        </div>
    </div>
    <div class="export-result-report__block-export-fields-open"
        [ngShow]="exportResultReport.exportFieldsOpen">
        <field-type-list-extended
            [field]="exportResultReport.fieldsExports"
            [preSelectItem]="exportResultReport.addFields"
            [listValues]="exportResultReport.exportFieldsList"
            (itemSelected)="itemSelected($event, 'addFields')">
        </field-type-list-extended>
        <div class="common__buttons-block common__buttons-block--modal">
            <button type="reset"
                class="common__button common__button--clean"
                (click)="cancelSelectionFields()"
                [afTranslate]="'export-result-report.cancel-selection'">_Cancelar Selección</button>
            <button type="submit"
                class="common__button common__button--active"
                (click)="addSelectionFields()"
                [afTranslate]="'export-result-report.add-selection'">_Añadir Selección</button>
        </div>
    </div>
    <div class="common__buttons-block common__buttons-block--modal">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">_Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="exportPoll()"
            [afTranslate]="'common.export'">_Exportar</button>
    </div>
</div>
