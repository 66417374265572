<div class="share-document">
  <div class="common__modal-header-block">
      <i [class]="'lf-icon-' + shareDocument.icon + ' common__icon-header'"></i>
      <span class="common__text-header">{{shareDocument.title}}</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
    <wizard-steps *ngIf="shareDocument.type === 'shareFieldContainer'"
      [steps]="shareDocument.steps"
      [state]="shareDocument.wizardState">
    </wizard-steps>
  <form class="share-document__form-share share-document__form-share--document"
      [ngSwitch]="shareDocument.wizardState"
      [ngClass]="{'share-document__form-share--share-fieldcontainer': shareDocument.type === 'shareFieldContainer'}">
      <p class="common__title-block-modal share-document__info-text"
          [ngShow]="shareDocument.infoShare != ''">{{shareDocument.infoShare}}</p>
      <div *ngSwitchCase="1">
        <field-type-contacts-multiple *ngIf="shareDocument.isReportFinished && shareDocument.sharedContacts"
              [field]="shareDocument.sharedContacts"
              [concept]="shareDocument.configuration.concept"
              [valueId]="shareDocument.valueId"
              (sendContacts)="receiveContacts($event)"
              (contactsClosed)="contactsClosed()">
        </field-type-contacts-multiple>
        <div [ngShow]="shareDocument.showText && !shareDocument.signatureCertificate"
            class="share-document__text-rich"
            [ngClass]="{'share-document__text-rich--poll': shareDocument.type === 'sharePoll'}">
            <rich-editor class="share-document__show-text-area"
                [tinyMceOptions]="shareDocument.tinyMceOptions"
                [modelText]="shareDocument.message"
                (emitValue)="getEditorValue($event)">
            </rich-editor>
        </div>
        <div class="share-document__check-block"
            [ngShow]="shareDocument.showAnswerSurvey">
            <field-type-switch [label]="shareDocument.textAnswerSurvey"
                                [checked]="shareDocument.answerSurvey"
                                (callback)="changeSurvey($event.value)">
            </field-type-switch>
        </div>
        <div class="share-document__block-info-message"
            [ngShow]="shareDocument.showInfoError">
            <i class="lf-icon-warning share-document__icon-alert"></i>
            <p class="share-document__text-alert"
                [afTranslate]="'share-document.info-error-sms'"></p>
            <i class="lf-icon-close field-type-contacts__icon-close-header"
                (click)="shareDocument.showInfoError = !shareDocument.showInfoError"></i>
        </div>
      </div>
      <div *ngSwitchDefault>
        <field-type-list-extended [field]="shareDocument.shareTypeDocumentField"
            [listValues]="shareDocument.typeShareDocument"
            [preSelectItem]="shareDocument.typeShareDocumentSelected"
            (itemSelected)="shareDocument.typeShareDocumentSelected = $event.newValue">
        </field-type-list-extended>
        <field-type-list-multiple *ngIf="shareDocument.typeShareDocumentSelected==='2'"
          [field]="shareDocument.shareDocumentationField"
          [listValues]="shareDocument.shareDocumentationList"
          [preSelectItem]="shareDocument.shareDocumentationListSelected"
          (itemSelected)="selectSharedDocumentation($event)">
        </field-type-list-multiple>
      </div>
  </form>
  <div class="common__buttons-block common__buttons-block--add-alert"
      [ngShow]="shareDocument.showText && shareDocument.type !== 'shareFieldContainer'">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="resetItems()">
          {{shareDocument.buttonCancel}}
      </button>
      <button type="submit"
          class="common__button common__button--active common__button--separating-margin"
          (click)="handleAccept()">
          {{shareDocument.buttonSend}}
      </button>
  </div>
  <div class="common__buttons-block common__buttons-block--add-alert"
      [ngShow]="shareDocument.showText && shareDocument.type === 'shareFieldContainer'">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="resetItems()">
          {{shareDocument.buttonCancel}}
      </button>
       <button *ngIf="shareDocument.wizardState === 2"
          type="submit"
          class="common__button common__button--clean common__button--separating-margin"
          (click)="backStep()"
          [afTranslate]="'common.previous'">
      </button>
       <button *ngIf="shareDocument.wizardState === 1"
          type="submit"
          class="common__button common__button--active common__button--separating-margin"
          (click)="nextStep()"
          [afTranslate]="'common.next'">
      </button>
      <button *ngIf="shareDocument.wizardState === 2"
          type="submit"
          class="common__button common__button--active common__button--separating-margin"
          (click)="handleAccept()">
          {{shareDocument.buttonSend}}
      </button>
  </div>

</div>
