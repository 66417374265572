import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EvaluatedConceptFactory {
  isNew:boolean = false;
  changeState(newState: boolean){
    this.isNew = newState;
  }
}
