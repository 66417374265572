import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LcoFactory {
  lco:any = new Map();

  set(id:string, lco:any){
    this.lco.set(id, lco);
  }

  get(id:string): any{
     return this.lco.get(id);
  }
}
