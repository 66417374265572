import { Component, OnChanges, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'customize-footer',
  templateUrl: './customize-footer.component.html'
})
export class CustomizeFooterComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFooter: any = {
    showBorder: false,
    backgroundColor: '',
    textColor: ''
  }
  constructor(private globalCfg: GlobalCfgFactory) { }
  @Input() configureOptions: any = {};

  ngOnChanges(): void {
    if(this.configureOptions){
      this.configurationCustomizeFooter();
    }
  }

  configurationCustomizeFooter(){
    this.customizeFooter.noRrss = this.checkRrss();
    if(this.configureOptions.linkedin && this.configureOptions.linkedin.indexOf('https://') === -1 && this.configureOptions.linkedin !== ""){
        this.configureOptions.linkedin = 'https://' + this.configureOptions.linkedin;
    }else if(!this.configureOptions.linkedin){
        this.configureOptions.linkedin = '';
    }
    if(this.configureOptions.facebook && this.configureOptions.facebook.indexOf('https://') === -1  && this.configureOptions.facebook !== ""){
        this.configureOptions.facebook= 'https://' + this.configureOptions.facebook;
    }else if(!this.configureOptions.facebook){
        this.configureOptions.facebook = '';
    }
    if(this.configureOptions.twitter && this.configureOptions.twitter.indexOf('https://' ) === -1 && this.configureOptions.twitter !== ""){
        this.configureOptions.twitter= 'https://' + this.configureOptions.twitter;
    }else if(!this.configureOptions.twitter){
        this.configureOptions.twitter = '';
    }
    if(this.configureOptions.youtube && this.configureOptions.youtube.indexOf('https://' ) === -1 && this.configureOptions.youtube !== ""){
        this.configureOptions.youtube= 'https://' + this.configureOptions.youtube;
    }else if(!this.configureOptions.youtube){
        this.configureOptions.youtube = '';
    }

    if(this.configureOptions.footerDescription){
        this.configureOptions.footerDescription = this.configureOptions.footerDescription.replace("\n","");
        if(this.configureOptions.footerDescription.indexOf("<a")!== -1){
            this.configureOptions.footerDescription = this.configureOptions.footerDescription.replace(/<a/g,"<a target='_blank' class='compliance-footer__disclaimer-text compliance-footer__disclaimer-text--link compliance-footer__disclaimer-text--public ng-scope' ");

        }
        if(this.configureOptions.footerDescription.indexOf("<p")!== -1){
            this.configureOptions.footerDescription = this.configureOptions.footerDescription.replace(/p>\n<p/g,"p><span> | </span><p")
            this.configureOptions.footerDescription = this.configureOptions.footerDescription.replace(/p><p/g,"p><span> | </span><p")
            this.configureOptions.footerDescription = this.configureOptions.footerDescription.replace(/<p/g,"<p class='compliance-footer__link-page-item'")
        }
    }

    switch(this.configureOptions.footerDesignType){
        case 1:
            this.customizeFooter.backgroundColor = '#ffffff';
            this.customizeFooter.textColor = '#000000';
            this.customizeFooter.showBorder = true;
            break;
        case 2:
            this.customizeFooter.backgroundColor = '#000000';
            this.customizeFooter.textColor = '#ffffff';
            break;
        case 3:
            this.customizeFooter.backgroundColor = this.configureOptions.color;
            this.customizeFooter.textColor = this.configureOptions.textColor;
            if(this.configureOptions.defaultCustomization && this.skin === 'steel-blue'){
                this.customizeFooter.textColor = '#F7F7F3';
            }
            if(this.customizeFooter.backgroundColor.toLowerCase() === '#ffffff' || this.customizeFooter.backgroundColor.toLowerCase() === '#fff' || this.customizeFooter.backgroundColor.toLowerCase() === '#f7f7f3' || this.customizeFooter.backgroundColor.toLowerCase() === '#fffafa' || this.customizeFooter.backgroundColor.toLowerCase() === '#f4f4f4'){
                this.customizeFooter.showBorder = true;
            }
            break;
        default:
            break;
    }
  }

  checkRrss(){
      let noRrss : any= true;
      let rrssList : any =['linkedin','facebook','twitter','youtube', 'phone']
      for(let i = 0; i < rrssList.length; i++){
          let rrss : any = this.configureOptions[rrssList[i]];
          if(rrss !==''){
              noRrss = false;
          }
      }
      return noRrss;
  }

  goTo(type:any){
      switch(type){
          case '1':
              window.open(this.configureOptions.twitter, '_blank');
              break;
          case '2':
              window.open(this.configureOptions.youtube, '_blank');
              break;
          case '3':
              window.open(this.configureOptions.linkedin, '_blank');
              break;
          default:
              window.open(this.configureOptions.facebook, '_blank');
              break;
      }
  }


}
