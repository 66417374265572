import { Component, EventEmitter, Input,  OnChanges,  Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-txchk',
  templateUrl: './field-type-txchk.component.html'
})
export class FieldTypeTxchkComponent implements OnChanges  {
  isLanding = this.route.snapshot.data['isLanding'];
  skin:string = this.isLanding?'landing-custom':this.globalCfg.skin;
  fieldTypeTxchk:any = {
    openLaw: false,
    references: false,
    preSelectItem: false
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private context: ContextFactory,
    private route: ActivatedRoute) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = false;
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(this.field) {
      let masterConceptTermsOkFieldId = this.field.publicLite && this.field.conceptObjectId && this.context && this.context.view && this.context.view.extraData && this.context.view.extraData.masterConceptTermsOkFieldValue && this.context.view.extraData.masterConceptTermsOkFieldId && this.context.view.extraData.masterConceptTermsOkFieldId === this.field.fieldId;
      this.field.noEditable = masterConceptTermsOkFieldId?masterConceptTermsOkFieldId:this.field.noEditable;
      this.fieldTypeTxchk.field = this.field;
      this.fieldTypeTxchk.field.id = this.fieldTypeTxchk.field.id?this.fieldTypeTxchk.field.id:this.commonUtilsService.createId();
      this.fieldTypeTxchk.field.description = this.commonUtilsService.checkAndDeletePreTag(this.fieldTypeTxchk.field.description)
      this.fieldTypeTxchk.preSelectItem = this.preSelectItem===null?false:this.preSelectItem;
      if(this.field.inReport !== null && this.field.inReport !== undefined){
        this.fieldTypeTxchk.inReport = this.field.inReport.indexOf('|') === -1? [this.field.inReport] : this.field.inReport.split('|')
      }
    }
  }

  changeInField(){
      if(!this.fieldTypeTxchk.field.noEditable){
          this.fieldTypeTxchk.preSelectItem = !this.fieldTypeTxchk.preSelectItem;
          this.itemSelected.emit({newValue:this.fieldTypeTxchk.preSelectItem});
      }
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
}
