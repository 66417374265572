import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'alert-detail',
  templateUrl: './alert-detail.component.html'
})
export class AlertDetailComponent implements OnInit {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal
  ) { }

  @Input() type:any;
  @Input() alert:any;
  @Input() defaultHour:any;

  skin:string = this.globalCfg.skin;
  alertDetail:any = {
    title: this.translate.instant('alert-detail.detail'),
    titleAlert: {
      label: this.translate.instant('common.title-alert'),
      required: true,
      noEditable: true
    },
    delayUnitsField: {
      label: this.translate.instant('alert-detail.unit'),
      fieldTypeId: 'num',
      defaultValue: 1,
      noEditable: true
    },
    delayTimeUnitField: {
      label: this.translate.instant('alert-detail.time'),
      noEditable: true
    },
    timeUnits: [
      {listValueId: 'h', text: this.capitalize(this.translate.instant('add-alert.hours'))},
      {listValueId: 'd', text: this.capitalize(this.translate.instant('add-alert.days'))},
      {listValueId: 'w', text: this.translate.instant('add-alert.weeks')},
      {listValueId: 'm', text: this.translate.instant('add-alert.months')}
    ]
  };

  ngOnInit(): void {
    let that = this.alertDetail;

    if(this.alert.delayUnits){
      that.delayUnitsField.defaultValue = this.alert.delayUnits;
    }

    if(this.alert.delayTimeUnit){
      that.delayTimeUnitField.delayTimeUnit = this.alert.delayTimeUnit;
    }else{
      that.notify = false;
    }

    if(this.alert.repeatDays){
        this.configureTexts();
    }
  }

  configureTexts(){
    let that = this.alertDetail;
    let textPrimary = '';
    let numberRepeat = '';

    if(this.alert.repeatDays > 0){
        textPrimary = this.translate.instant('add-alert.during') + ' ' + this.alert.repeatDays + this.translate.instant('alert-detail.days');
        if (parseInt(this.alert.repeatDays) === 1) {
            textPrimary = this.translate.instant('add-alert.during') + ' ' + this.alert.repeatDays + this.translate.instant('add-alert.day');
        }
        that.repeatText = textPrimary;
    }

    if(this.alert.repeatOccurrences){
        numberRepeat = this.translate.instant('add-alert.time');
        if(this.alert.repeatOccurrences > 1){
            numberRepeat = this.translate.instant('add-alert.times');
        }
        that.repeatText = textPrimary + '/ ' + this.alert.repeatOccurrences + numberRepeat;
    }
  }

  capitalize(text:string):string {
    let fixedText = text.trim();
    return fixedText.charAt(0).toUpperCase() + fixedText.slice(1);
  }

  handleDismiss(){
    this.activeModal.dismiss();
  }
}
