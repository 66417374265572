import Q from 'q';
import { Injectable } from '@angular/core';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import $ from 'jquery';
import { ApiServiceService } from '../api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class SharepointLogoutService {
  defered = Q.defer();
  constructor(private apiProperties: ApiPropertiesFactory) {}
  logout() {
    let iframe = $('<iframe style="display:none;" src="' + this.apiProperties.sharepointLogoutUri + '"/>');
    iframe.on('load', ()=>{
      console.log('sharepoint logout finished')
      setTimeout(function () {
        iframe.remove();
      }, 30000);
    });
    let body = $('body');
    body.append(iframe);
    let that = this;
    setTimeout(function () {
        console.log('sharepoint logout timed out');
        that.defered.reject('timeout');
    }, 30000);
    that.defered.promise.finally(function () {
        iframe.remove();
    });
    return this.defered;
  }
  iframeLoaded() {
    console.log('sharepoint logout finished');
    this.defered.resolve();
  }
}
