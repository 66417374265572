import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { VisibilityService } from '@shared/services/visibility/visibility.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Injectable({
  providedIn: 'root'
})

export class SubcontainerMultipleParentService {

  subscribers: any = {};
  constructor(private broadcastService: BroadcastService,
    private visibilityService: VisibilityService,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService) { }

  onInit(child:any){
    this.subscribers.changesInConceptBroadCast = this.broadcastService.subscribe('changes-in-concept', ()=>{
          let that: any =  child.subcontainerMultiple;
          if(that.fieldWithVisibility){
              this.visibilityService.configureListVisbleValue(that.fieldWithVisibility, that.evaluatedMap, that.concept);
          }
      })
  }

  onChanges(child: any){
      let that: any = child.subcontainerMultiple;
      that.subcontainerDuplicated = child.subcontainerDuplicated
      that.concept = child.concept
      that.conceptLocked = child.conceptLocked
      that.subContainer = child.subContainer
      that.isPrivate = child.isPrivate

      if(child.itsSaveConcept){
          this.loader.openLoader('subcontainer-multiple-generate-duplicated')
          this.generateDuplicate(child);
      }
      if(child.evaluatedMap){
          this.configureMultiple(child);
      }
      that.lockedSubcontainer = that.concept && that.concept.saved && that.conceptLocked && !that.subContainer.private && !that.isPrivate;
  }

  duplicateSubcontainer(child:any){
      if(child.isLanding && !child.subcontainerMultiple.concept.conceptObjectId){
          child.saveBeforeCreate.emit();
      }else{
          this.loader.openLoader('subcontainer-multiple-generate-duplicated');
          this.generateDuplicate(child);
      }
  }

  deleteDuplicated(subContainer: any, child:any){
      let text = this.translate.instant('subcontainer-multiple.delete-copy') +"'"+ subContainer.numSubContainer +"'"+ this.translate.instant('subcontainer-multiple.of-group') +"'"+ subContainer.name+"'?";
      this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
          if(result.result === 'ok'){
              this.loader.openLoader('subcontainer-multiple-delete-duplicated')
              this.deleteEntity(subContainer, child);
          }
      })
  }

  changesInEvaluatedSubContainer(field:any, child:any){
      child.evaluatedConceptChange.emit({field: field});
      this.visibilityService.configureListVisbleValue(child.subcontainerMultiple.fieldWithVisibility, child.subcontainerMultiple.evaluatedMap, child.subcontainerMultiple.concept);
  }

  configureDuplicated(index:any, child:any) {
      let newSubcontainer = cloneDeep(child.subcontainerMultiple.subContainer);
      newSubcontainer.myId = this.commonUtilsService.createId();
      if(newSubcontainer.numberOfCopys){
          delete newSubcontainer.numberOfCopys;
      }
      newSubcontainer.subcontainerDuplicated = [];
      newSubcontainer.evaluatedFields = [];
      newSubcontainer.numSubContainer = index;
      newSubcontainer.fields.forEach((field:any) => {
          field.generateId = true;
          child.subcontainerMultiple.evaluatedFieldsMultiple.forEach((evaluatedMultiple:any) => {
              if(field.fieldId === evaluatedMultiple.fieldId && evaluatedMultiple.numSubContainer === index){
                  field.evaluatedValue = evaluatedMultiple;
                  newSubcontainer.evaluatedFields.push(evaluatedMultiple);
              }
          });
      });
      child.subcontainerMultiple.subcontainerDuplicated.push(newSubcontainer);
  }


  deleteEntity(subContainer:any, child:any){
      let that: any = child.subcontainerMultiple;
      this.apiService.delete('conceptdata/removemultiplesubgroup/'+that.concept.conceptObjectId+'/'+subContainer.fieldContainerId, subContainer.numSubContainer)
          .then((data:any)=>{
              let deleteItem = null;
              let duplicated = that.subcontainerDuplicated
              for(let i = 0; i < duplicated.length; i++){
                  if(subContainer.myId === duplicated[i].myId){
                      this.deleteEvaluatedFields(subContainer.fields, child);
                      duplicated.splice(i, 1);
                      deleteItem = i;
                      break;
                  }
              }
              that.numSubContainerAmount--;
              for(let j:any = deleteItem; j < duplicated.length; j++){
                  duplicated[j].numSubContainer = j+1;
                  for(let k = 0; k < duplicated[j].evaluatedFields.length; k++){
                      duplicated[j].evaluatedFields[k].numSubContainer = duplicated[j].numSubContainer;
                      for( let v = 0; v < that.evaluatedFieldsMultiple.length; v++){
                          if(duplicated[j].evaluatedFields[k].conceptDataId === that.evaluatedFieldsMultiple[v].conceptDataId){
                              that.evaluatedFieldsMultiple[v].numSubContainer = duplicated[j].numSubContainer;
                          }
                      }
                  }
              }
              this.extractVisibilityMultiple(child);
              child.evaluatedConceptChange.emit({field: null});
              this.loader.closeLoader(['subcontainer-multiple-delete-duplicated']);
          }, (errorData:any)=>{})
  }

  deleteEvaluatedFields(fields:any, child:any){
      let concept: any = child.subcontainerMultiple.concept;
      for(let i = 0; i < fields.length; i++){
          for(let j = 0; j < concept.evaluatedFields.length; j++){
              if(fields[i].evaluatedValue.conceptDataId === concept.evaluatedFields[j].conceptDataId){
                  concept.evaluatedFields.splice(j, 1);
              }
          }
      }
  }

  generateDuplicate(child:any){
      let that: any = child.subcontainerMultiple;
      this.apiService.add('conceptdata/'+that.concept.conceptObjectId+'/'+ that.subContainer.fieldContainerId +'/'+ 1)
          .then((data:any)=>{
              that.evaluatedFieldsMultiple = [...that.evaluatedFieldsMultiple, ...data];
              that.concept.evaluatedFields = [...that.concept.evaluatedFields, ...data];
              this.configureDuplicated(that.numSubContainerAmount+1, child);
              this.extractVisibilityMultiple(child);
              child.subContainerMultiple.delete(that.subContainer.fieldContainerId );
              child.subContainerMultiple.set(that.subContainer.fieldContainerId, that.subcontainerDuplicated )
              that.numSubContainerAmount++;
              this.loader.closeLoader(['subcontainer-multiple-generate-duplicated']);
              that.subContainer.numberOfCopys = 1;
              if(that.itsSaveConcept){
                  this.configureMultiple(child);
                  that.clearSaveConcept.emit();
              }

          }, (errorData:any)=>{})
  }

  configureMultiple(child:any){
      let that: any = child.subcontainerMultiple;
      if(that.toFront){
          that.evaluatedFieldsMultiple = [];
          that.evaluatedFieldsNoMultiple = [];
          that.concept.evaluatedFields.forEach((evaluatedField:any) => {
              if(evaluatedField.numSubContainer !== null
                  && that.subContainer.fieldContainerId === evaluatedField.subContainerId){
                  that.evaluatedFieldsMultiple.push(evaluatedField);
              }
          });

          that.numSubContainerAmount = that.evaluatedFieldsMultiple.length? that.evaluatedFieldsMultiple[that.evaluatedFieldsMultiple.length-1].numSubContainer : 0;

          if(that.subcontainerDuplicated.length !== that.numSubContainerAmount){
              for(let i = 1; i <= that.numSubContainerAmount; i++){
                  this.configureDuplicated(i, child);
                  child.subContainerMultiple.set(that.subContainer.fieldContainerId, that.subcontainerDuplicated )
              }
          }
      }else{
          let newSubcontainer:any = cloneDeep(that.subContainer);
          newSubcontainer.myId = this.commonUtilsService.createId();
          newSubcontainer.evaluatedFields = [];
          newSubcontainer.numSubContainer = 0;
          that.subcontainerDuplicated.push(newSubcontainer);
      }
      this.extractVisibilityMultiple(child);
  }

  extractVisibilityMultiple(child:any){
      let that: any = child.subcontainerMultiple;
      that.fieldWithVisibility = this.visibilityService.extractVisibilityMultiple(that.subcontainerDuplicated);
      that.fieldWithProperties = this.visibilityService.extractPropertiesVisibility(that.concept);
      if(that.toFront){
          that.evaluatedMap = this.visibilityService.evaluatedFieldsMap(that.concept);
          this.visibilityService.configureListVisbleValue(that.fieldWithVisibility, that.evaluatedMap, that.concept);
          this.visibilityService.configureEvaluationProperties(that.fieldWithProperties, child.evaluationProperties);
      }
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
