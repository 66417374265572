import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'formula-setting-modal',
  templateUrl: './formula-setting-modal.component.html'
})
export class FormulaSettingModalComponent implements OnInit {
  skin:string = this.globalCfg.skin;
  const_dist_margin:number = 26;
  formulaSettingModal:any = {
    formulaItems: [],
    level: 0,
    marginOperator: 0,
    formula: "",
    symbol: false,
    operando: true,
    search: true,
    searchFields: '',
    formulaAuxiliar: '',
    errorFormula: false,
    value1Field: {
      id: "value1-field",
      label: this.translate.instant('customize-field-formula.other-value'),
      clean: true,
      noEditable: false,
      fieldTypeId: 'num',
      decimals: 2,
      disabled: false,
      origen: 'formula'
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private commonUtilsService: CommonUtilsService) { }
  @Input() conceptFields:any = [];
  @Input() formulaItems:any = null;
  @Input() originForm:any = null;

  ngOnInit(): void {
    let that: any = this.formulaSettingModal
    this.showFields();
    that.conceptFieldsCopy =cloneDeep(this.conceptFields);
    that.colores = this.originForm.colores;
    if(this.formulaItems){
        let formAux:any = this.formulaItems.split('#');
        formAux.forEach((item:any) => {
          that.formulaAuxiliar += item.length === 1? item : 1;
        });
        that.formula = this.formulaItems;
        that.formulaItems = cloneDeep(this.originForm.formulaItems);
        that.symbol = cloneDeep(this.originForm.symbol);
        that.marginOperator = cloneDeep(this.originForm.marginOperator);
    }
  }

  showFields(){
    this.conceptFields.forEach((container:any)=>{
      container.show = true;
      container.fields.forEach((field:any)=>{
        field.show = this.originForm.fieldTypeId !== 'agreg' && this.originForm.fieldTypeId !== 'agrep'? this.checkFieldContainer(field) : false;
      })
      container.subContainers.forEach((subContainer: any) => {
        subContainer.show = true;
        subContainer.fields.forEach((subField:any)=>{
          subField.show = this.checkFieldSubcontainer(subContainer, subField)
        });
      })
    })
  }

  checkFieldSubcontainer(subContainer:any, fieldSubcontainer:any){
      let fieldTypeId:string = fieldSubcontainer.fieldTypeId;
      let fieldNumber: boolean = (fieldTypeId === 'num' || fieldTypeId === 'money');
      let configurationFieldTypeId:string = this.originForm.fieldTypeId;
      let fieldWeight:boolean = configurationFieldTypeId === 'formp' || configurationFieldTypeId === 'score' || configurationFieldTypeId === 'agrep';
      let fieldNoWeight:boolean = configurationFieldTypeId === 'formu' || configurationFieldTypeId === 'agreg';
      let formuNoWeight:boolean = (fieldNumber || fieldTypeId === 'formu' || fieldTypeId === 'agreg');
      let formuWeight:boolean = (fieldNumber || fieldTypeId === 'formp' || fieldTypeId === 'agrep' || fieldTypeId === 'score' || fieldTypeId === 'listp' || fieldTypeId === 'boolp');
      let isAgregOrAgrep = configurationFieldTypeId === 'agreg' || configurationFieldTypeId === 'agrep';
      return (fieldTypeId === 'partialScore'
                || (formuNoWeight && fieldNoWeight && fieldSubcontainer.fieldId !== this.originForm.fieldId)
                || (formuWeight && fieldWeight && fieldSubcontainer.fieldId !== this.originForm.fieldId))
            && (!isAgregOrAgrep || ( isAgregOrAgrep && subContainer.multiple))
            && (!subContainer.multiple || ( isAgregOrAgrep && subContainer.multiple) || (fieldSubcontainer.fieldContainerId === this.originForm.fieldContainerId));
  }

  checkFieldContainer(field:any){
      let fieldTypeId:string = field.fieldTypeId;
      let fieldNumber: boolean = (fieldTypeId === 'num' || fieldTypeId === 'money');
      let configurationFieldTypeId:string = this.originForm.fieldTypeId;
      let fieldWeight:boolean = configurationFieldTypeId === 'formp' || configurationFieldTypeId === 'score';
      let formuNoWeight:boolean = (fieldNumber || fieldTypeId === 'formu' || fieldTypeId === 'agreg') && configurationFieldTypeId === 'formu';
      let formuWeight:boolean = (fieldNumber || fieldTypeId === 'formp' || fieldTypeId === 'agrep' || fieldTypeId === 'score' || fieldTypeId === 'listp' || fieldTypeId === 'boolp');
      return  (fieldTypeId === 'partialScore'
          || (formuNoWeight && field.fieldId !== this.originForm.fieldId)
          || (formuWeight && fieldWeight && field.fieldId !== this.originForm.fieldId));
  }

  toggleSearch(){
      this.formulaSettingModal.searchFields = '';
  }


  resetForm(){
    for(let i:number = this.formulaSettingModal.formulaItems.length -1; i >= 0; i--){
      this.deleteLastItem();
    }
  }

  deleteLastItem(){
    let that: any = this.formulaSettingModal;
    if(that.formulaItems.length){
      let formulaItem = that.formulaItems[that.formulaItems.length-1];
      that.formulaItems.splice(that.formulaItems.length-1, 1);
      that.formulaAuxiliar = that.formulaAuxiliar.substr(0,that.formulaAuxiliar.length-1);
      that.formula = that.formula.substr(0,that.formula.lastIndexOf('#'));
      if((formulaItem.type=== 'symbol' || formulaItem.type==='symbol-full') && (formulaItem.text !== ')' || formulaItem.text !== '(' )){
          that.symbol = true;
          if(formulaItem.text===')'){
              that.level++;
              that.marginOperator += that.const_dist_margin;
              that.symbol = that.formulaItems[that.formulaItems.length-1].type === 'fields';
          }
          if(formulaItem.text==='('){
              that.level--;
              if(that.marginOperator > 0){
                  that.marginOperator -= this.const_dist_margin;
              }
              that.symbol = false;
              if( that.formulaItems.length > 1 && that.formulaItems[that.formulaItems.length-1].type==='symbol-full'){
                  that.formulaItems[that.formulaItems.length-1].type= 'symbol';
              }
          }
      }else{
          this.originForm.selectValuesFormula.splice(this.originForm.selectValuesFormula.length-1, 1);
          that.symbol = false;
      }
    }
  }

  addSymbol(symbol:any){
    let that: any = this.formulaSettingModal;
      this.closeError();
      let lastItem = that.formulaItems[that.formulaItems.length - 1];
      let level = this.formulaSettingModal.level;
      let auxSymbol = this.formulaSettingModal.symbol;
      let openKeys = false;
      let iconSymbol = '';
      let typeSymbol ='symbol';

      switch(symbol){
          case '(':
              iconSymbol = 'lf-icon-open-bracket';
              if(that.formulaItems.length > 0){
                  auxSymbol = !(lastItem.type === 'fields' || that.level === 5 || lastItem.text ===')')
                  if(auxSymbol){
                    that.level++;
                    openKeys= true
                    level = that.level;
                    lastItem.type= 'symbol-full';
                    that.formulaItems[that.formulaItems.length - 1] = lastItem;
                  }
              }else{
                  that.level++;
                  level = that.level;
                  openKeys = true;
                  auxSymbol = true;
              }
              break;
          case ')':
              iconSymbol = 'lf-icon-close-bracket';
              if(that.formulaItems.length > 0){
                  if(lastItem.text === ')' || lastItem.type === 'fields'){
                      let count:any = (that.formula.match(/\)/g) || []).length;
                      if(count >=(that.formula.match(/\(/g) || []).length){
                          return;
                      }else{
                          that.level--;
                          auxSymbol = true;
                          typeSymbol = 'symbol-full';
                      }
                  }
              }
              break;
          case '+':
              iconSymbol = 'lf-icon-sum';
              auxSymbol = this.getAuxSymbol(lastItem);
              break;
          case '-':
              iconSymbol = 'lf-icon-less';
              auxSymbol = this.getAuxSymbol(lastItem);
              break;
          case '/':
              iconSymbol = 'lf-icon-division';
              auxSymbol = this.getAuxSymbol(lastItem);
              break;
          default:
              iconSymbol = 'lf-icon-close';
              auxSymbol = this.getAuxSymbol(lastItem);
              break;

      }

      if(auxSymbol){
          let aux = that.marginOperator;
          if(symbol === ')'){
              that.symbol = true;
              that.marginOperator -= that.const_dist_margin;
              aux = that.marginOperator;
          }else{
              that.symbol = false;
          }

          if(openKeys){
              that.marginOperator += that.const_dist_margin;
          }
          that.formulaAuxiliar += symbol;
          that.formulaItems.push({text:symbol, icon:iconSymbol, type:typeSymbol, level:level, marginOperator: aux,color:that.colores[level][0]});
          that.formula += that.formula === ''?symbol : '#'+symbol;
      }
  }

  getAuxSymbol(lastItem:any){
    let that: any = this.formulaSettingModal;
    let auxSymbol: boolean = this.formulaSettingModal.symbol;
    if(that.formulaItems.length > 0){
      if((lastItem.type === 'symbol' && lastItem.text ===')')){
          lastItem.type = 'symbol-full';
          auxSymbol = true;
      }
    }else{
      auxSymbol = false
    }
    return auxSymbol;
  }

  closeError(){
    this.formulaSettingModal.errorFormula = false;
  }

  itemSelected(type:any, newValue:any){
    let that: any = this.formulaSettingModal;
    this.closeError();
    if (!that.symbol){
        if(newValue.fieldId !== null){

            let selected:any = newValue;
            selected.level = that.level;
            that.formulaAuxiliar += '1';
            let marginOperatorItem:any = that.marginOperator;

            this.originForm.selectValuesFormula.push({
                text: newValue.label,
                type: type,
                icon: newValue.icon,
                level: that.level,
                marginOperator: marginOperatorItem,
                color: that.colores[that.level][1],
                lineJump: this.lineJump()
            });

            that.formulaItems.push({
                text: newValue.label,
                type: type,
                icon: newValue.icon,
                level: that.level,
                marginOperator: marginOperatorItem,
                color: that.colores[that.level][1],
                lineJump: this.lineJump()
            });
            if(that.formula===''){
              that.formula = '['+newValue.fieldId+']';
            }else{
              that.formula += '#[' + newValue.fieldId + ']';
            }


            that.symbol = true;
        }
    }
  }

  lineJump(){
      let lineJump: boolean = false;
      let levelCount: number = 0;
      let itemLevel: number = 0;
      let selectValuesFormula: any = this.originForm.selectValuesFormula
      this.formulaSettingModal.formulaItems.forEach((item:any) => {
        if(itemLevel != item.level){
          itemLevel = item.level;
          levelCount = 0
        }else if(item.type === 'fields'){
          levelCount  ++;
        }
      });
      if ((selectValuesFormula !== null && selectValuesFormula !== undefined && selectValuesFormula.length &&
          levelCount %2 == 0 &&
          selectValuesFormula[selectValuesFormula.length-1].level === this.formulaSettingModal.level)){
          lineJump = true;
      }
      return lineJump;
  }

  validateFormula(){
      try{
        return !isNaN(eval(this.formulaSettingModal.formulaAuxiliar));
      }catch(Ex){
          return false;
      }
  }

  encodingFieldChanged(e:any, type:any) {
      let that: any = this.formulaSettingModal
      let newValue: any = e.newValue
      if(!this.formulaSettingModal.symbol){
          if(newValue !== null  && newValue !== ""  && !isNaN(parseFloat(newValue))   ){
              if(type==='fields-manual'){
                  type= 'fields';
              }
              let newValueReplace = typeof newValue === 'string'?this.commonUtilsService.replaceDecimals(newValue):newValue;
              that.symbol = true;
              let lineJump:any = this.lineJump();
              this.originForm.selectValuesFormula.push({text: newValue, value:newValueReplace, type:type, icon:"lf-icon-hashtag",level:that.level, color: that.colores[that.level][1], lineJump: lineJump});
              that.formulaItems.push({text: newValue, value:newValueReplace, type:type, icon:"lf-icon-hashtag",level:that.level, color: that.colores[that.level][1], lineJump: lineJump});
              that.formulaAuxiliar += "1";
              that.value1Field.value = '';
              that.value1Field.clean = true;
              that.valueNumber = '';
              that.formula = that.formula===''?newValueReplace : that.formula + '#'+newValueReplace;
          }
      }else{
          that.value1Field.clean = false;
          that.value1Field.value = newValue;
      }
  }

  handleAccept(){
      if(this.validateFormula()){
        this.activeModal.close({
            result: 'ok',
            formula: this.formulaSettingModal.formula,
            items: this.formulaSettingModal.formulaItems
        })
      }else{
          this.formulaSettingModal.errorFormula = true;
      }
  }

  handleDismiss(){
    this.activeModal.close({result:'cancel'});
  }
}
