<div class="input-time"
    [ngClass]="{'input-time--disabled': disabled}">
    <div class="input-time__time-block"
        [ngClass]="{'input-time__time-block--error': inputTime.error}">
        <input type="text"
            [id]="'input-time-hours-'+inputTime.inputId"
            class="input-time__time"
            maxlength="2"
            validateNumber
            [(ngModel)]="inputTime.hours"
            [disabled]="disabled"
            (keyup)="validateDate('hours', $event)"
            (click)="select('hours')">
        <span class="input-time__time-separator">:</span>
        <input type="text"
            [id]="'input-time-minutes-'+inputTime.inputId"
            class="input-time__time"
            maxlength="2"
            validateNumber
            [noDot]="inputTime.dotSeparation"
            [(ngModel)]="inputTime.minutes"
            [disabled]="disabled"
            (keyup)="validateDate('minutes', $event)"
            (click)="select('minutes')">
    </div>
    <i class="lf-icon-clock input-time__icon-clock"></i>
</div>
