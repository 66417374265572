import axios from 'axios';

import { Injectable } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private context: ContextFactory) {}

  interceptorResponse(){
    axios.interceptors.response.use((response:any) => {
      let apiIdx = response.config.url.indexOf('/api/');
      if (apiIdx !== -1) {
          if(Boolean(response.headers['X-Renew-Authorization'])){
            this.context.renewJwt(response.headers['X-Renew-Authorization']);
          }
          if (response.data && response.data.data === '"SessionExpired"') {
            this.context.sessionExpired();
          }
      }
      return response;
    }, (errorResponse:any) => {
      let response: any = errorResponse.response;
      if (response.config.url.indexOf('/api/') !== -1 && response.status === 401 && response.data === 'SessionExpired') {
          this.context.sessionExpired();
      }
      return Promise.reject(response);
    });
  }
}
