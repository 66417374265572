import { Component, OnInit} from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

    resetPassword:any = {
      oldPass: '',
      newPass: '',
      repeatPass: ''
    };
    constructor(
        private translate: AfTranslateFactory,
        private activeModal: NgbActiveModal,
        private modalService: ModalServiceService,
        private context: ContextFactory,
        private apiService: ApiServiceService) { }

    ngOnInit(){
      this.resetPassword.oldPassword ={
        id: "old-pass",
        label: this.translate.instant('reset-password.actual-password'),
        required:  true,
        description: null,
        references:{},
        evaluatedValue:{},
        noEditable:false,
        visible:false
      }

      this.resetPassword.newPassword ={
        id: "new-pass",
        label: this.translate.instant('reset-password.new-password'),
        required:  true,
        description: null,
        references:{},
        evaluatedValue:{},
        noEditable:false,
        visible:false
      }

      this.resetPassword.repeatPassword = {
        id: "repeat-pass",
        label: this.translate.instant('reset-password.repeat-password'),
        required: true,
        description: null,
        references:{},
        evaluatedValue:{},
        noEditable:false,
        visible:false
      }

      this.resetPassword.passwordInfo = {
        type: 'no-margin',
        fieldTypeId: 'rich',
        isHighlight: false,
        label: this.translate.instant('reset-password.info-title'),
        description: this.translate.instant('reset-password.info-description')
      }

      this.resetPassword.user = this.context.user;
    }
    itemSelected(type:string, newValue:string){
        if(type === 'newPass'){
          this.resetPassword.newPass = newValue;
        }
        else if (type === 'repeatPass'){
          this.resetPassword.repeatPass = newValue;
        }
        else if (type === 'oldPass'){
          this.resetPassword.oldPass = newValue;
        }
    }

    savePassword(){
      if(this.validateRequiredFields()){
          this.resetPassword.changePassword = {
              password: this.resetPassword.oldPass,
              newPassword: this.resetPassword.newPass
          };

          this.apiService.patch('frontusers/changepassword','',this.resetPassword.changePassword)
            .then((data:any)=>{
              this.handleAccept('resetPassword');
            }, (errorData:any)=>{
              this.modalService.adviceModal(this.translate.instant('reset-password.error-reset-password'), 'error:accept:warning')
            });
      }
    }

    validateRequiredFields(){
      this.resetPassword.notSecurity = false;
      if(!this.resetPassword.newPass && !this.resetPassword.oldPass && !this.resetPassword.repeatPass){
        this.resetPassword.repeatPassword.error = true;
        this.resetPassword.newPassword.error = true;
        this.resetPassword.oldPassword.error = true;
        return false;
      }
      let errorValidate:boolean=false;
      let validateNewPassNumber = (/[0-9]/).test(this.resetPassword.newPass);
      let validateNewPassLetter = (/[a-z]|[A-Z]/).test(this.resetPassword.newPass);
      if(this.resetPassword.oldPass){
        this.resetPassword.oldPassword.error = false;
      }else{
        this.resetPassword.oldPassword.error = true;
        errorValidate = true;
      }
      if(this.resetPassword.newPass){
        this.resetPassword.newPassword.error = false;
      }else{
        this.resetPassword.newPassword.error = true;
        errorValidate = true;
      }
      if(this.resetPassword.repeatPass){
        this.resetPassword.repeatPassword.error = false;
      }else{
        this.resetPassword.repeatPassword.error = true;
        errorValidate = true;
      }
      if(this.resetPassword.newPass.length && ((this.resetPassword.newPass.length < 8 || this.resetPassword.newPass.length > 32) || !validateNewPassNumber || !validateNewPassLetter)){
        this.resetPassword.newPassword.error = true;
        this.resetPassword.repeatPassword.error = true;
        this.resetPassword.notSecurity = true;
        errorValidate = true;
      }else if(this.resetPassword.newPass !== this.resetPassword.repeatPass){
        this.resetPassword.newPassword.error = true;
        this.resetPassword.repeatPassword.error = true;
        errorValidate = true;
      }
      return !errorValidate;
    }
    cancel(){
    this.resetPassword.newPass = '';
    this.resetPassword.repeatPass = '';
    this.handleDismiss();
    }

    handleAccept(data: any) {
      this.activeModal.close({
          result:'ok',
          item: data
      })
    }

    handleDismiss() {
      this.activeModal.close({result:'cancel'});
    }
}
