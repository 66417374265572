<div class="duplicate-document">
    <div class="common__modal-header-block">
        <i class="lf-icon-paste common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'common.duplicate'">Duplicar</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>
    <form class="duplicate-document__form-version">
        <field-type-text-simple
            [field]="duplicateDocument.fieldInfo"
            [preSelectItem]="duplicateDocument.newDocument.name"
            (itemSelected)="itemSelected($event, 'name')">
        </field-type-text-simple>
        <div class="rename-modal__error-block">
            <span class="rename-modal__error-name"
                [ngShow]="duplicateDocument.errorName">{{duplicateDocument.errorType}}</span>
        </div>

        <div class="common__buttons-block common__buttons-block--modal">
            <button type="reset"
                class="common__button common__button--clean"
                (click)="cleanModal()"
                [afTranslate]="'common.clean'">Limpiar</button>
            <button type="submit"
                class="common__button common__button--active"
                (click)="validateForm()"
                [afTranslate]="'common.create'">Crear</button>
        </div>
    </form>
</div>
