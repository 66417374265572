import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import $ from 'jquery';

@Component({
  selector: 'field-type-color',
  templateUrl: './field-type-color.component.html'
})
export class FieldTypeColorComponent implements OnChanges {
  skin: string = this.globalCfg.skin
  fieldTypeColor: any = {
    showPalette:false,
    companyColorSelected: '',
    colorRange: 0,
    companyColorSelectedInput: null,
    companyColorSelectedOld: null,
    rgbOld: {},
    rgb: {},
    boxColor:"linear-gradient(to top, rgb(0, 0, 0), transparent), linear-gradient(to left, rgb(275,-22,-22), rgb(255, 255, 255))",
    point: {},
    inbox: false,
    noEditable: false
  }
  constructor(private globalCfg:GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService) { }
  @Input() preSelectItem: any = {};
  @Input() field: any = {};
  @Output() itemSelectedEmit = new EventEmitter();
  @Output() openField = new EventEmitter();

  ngOnChanges():void{
    if(this.field) {
      this.fieldTypeColor.field = this.field;
      this.fieldTypeColor.titleField = this.field.extendedLabel && this.field.extendedLabel !== ''? this.field.extendedLabel : this.field.label;
      this.fieldTypeColor.fieldId = this.field.fieldId? this.field.fieldId : this.commonUtilsService.createId();
      if(!this.preSelectItem || this.preSelectItem === null){
          this.fieldTypeColor.companyColorSelected = '';
          this.fieldTypeColor.colorRange = 0;
          this.fieldTypeColor.companyColorSelectedInput = null;
      }else{
          this.fieldTypeColor.companyColorSelected = this.preSelectItem;
          this.fieldTypeColor.companyColorSelectedInput = this.preSelectItem;
      }
      this.setColor();
      this.fieldTypeColor.companyColorSelectedOld = this.fieldTypeColor.companyColorSelectedInput;
      this.fieldTypeColor.rgbOld = this.fieldTypeColor.rgb;
    }
  }

  setColor(){
      let hsb:any = this.hex2hsb(this.fieldTypeColor.companyColorSelected);
      let x: any = Math.floor((hsb.s * 216) / 100)
      let y: any = Math.floor(216 - (hsb.b * (216 / 100)))
      this.fieldTypeColor.point = {
          x: parseInt(x),
          y: parseInt(y)
      }
      this.fieldTypeColor.colorRange = 360 - parseInt(hsb.h);
      this.fieldTypeColor.rgb = this.hsb2rgb({
          h: this.keepWithin(360 - parseInt(this.fieldTypeColor.colorRange), 0, 360),
          s: hsb.s,
          b: hsb.b
      })
      this.fieldTypeColor.boxColor = "linear-gradient(to top, rgb(0, 0, 0), transparent), linear-gradient(to left, rgb("+this.fieldTypeColor.rgb.r+","+this.fieldTypeColor.rgb.g+","+this.fieldTypeColor.rgb.b+"), rgb(255, 255, 255))";
  }

  hex2hsb(hex:any) {
    let hsb = this.rgb2hsb(this.hex2rgb(hex));
    if(hsb.s === 0) hsb.h = 360;
    return hsb;
  }

  rgb2hsb(rgb:any) {
    let hsb = { h: 0, s: 0, b: 0 };
    let min = Math.min(rgb.r, rgb.g, rgb.b);
    let max = Math.max(rgb.r, rgb.g, rgb.b);
    let delta = max - min;
    hsb.b = max;
    hsb.s = max !== 0 ? 255 * delta / max : 0;
    if(hsb.s !== 0) {
        if(rgb.r === max) {
            hsb.h = (rgb.g - rgb.b) / delta;
        } else if(rgb.g === max) {
            hsb.h = 2 + (rgb.b - rgb.r) / delta;
        } else {
            hsb.h = 4 + (rgb.r - rgb.g) / delta;
        }
    } else {
        hsb.h = -1;
    }
    hsb.h *= 60;
    if(hsb.h < 0) {
        hsb.h += 360;
    }
    hsb.s *= 100/255;
    hsb.b *= 100/255;
    return hsb;
  }

  hex2rgb(hex:any) {
    hex = parseInt(((hex.indexOf('#') > -1) ? hex.substring(1) : hex), 16);
    return {
        r: hex >> 16,
        g: (hex & 0x00FF00) >> 8,
        b: (hex & 0x0000FF)
    };
  }

  hsb2rgb(hsb:any) {
    let rgb:any = {};
    let h = Math.round(hsb.h);
    let s = Math.round(hsb.s * 255 / 100);
    let v = Math.round(hsb.b * 255 / 100);
    if(s === 0) {
        rgb.r = rgb.g = rgb.b = v;
    } else {
            let t1 = v;
            let t2 = (255 - s) * v / 255;
            let t3 = (t1 - t2) * (h % 60) / 60;
        if(h === 360){
            h = 0;
        }

        if(h < 60) {
            rgb.r = t1; rgb.b = t2; rgb.g = t2 + t3;
        } else if(h < 120) {
            rgb.g = t1; rgb.b = t2; rgb.r = t1 - t3;
        } else if(h < 180) {
            rgb.g = t1; rgb.r = t2; rgb.b = t2 + t3;
        } else if(h < 240) {
            rgb.b = t1; rgb.r = t2; rgb.g = t1 - t3;
        } else if(h < 300) {
            rgb.b = t1; rgb.g = t2; rgb.r = t2 + t3;
        } else if(h < 360) {
            rgb.r = t1; rgb.g = t2; rgb.b = t1 - t3;
        } else {
            rgb.r = 0; rgb.g = 0; rgb.b = 0;
        }
    }
    return {
        r: Math.round(rgb.r),
        g: Math.round(rgb.g),
        b: Math.round(rgb.b)
    };
  }

  keepWithin(value:any, min:any, max:any) {
    if(value < min) value = min;
    if(value > max) value = max;
    return value;
  }

  openPalette(){
    this.fieldTypeColor.showPalette = !this.fieldTypeColor.showPalette;
    this.setColor();
    this.openField.emit({open:this.fieldTypeColor.showPalette});
  }

  catchColor(){
    if(this.commonUtilsService.checkIsHexadecimalColor(this.fieldTypeColor.companyColorSelected)){
        this.field.error = false;
        this.fieldTypeColor.showPalette = !this.fieldTypeColor.showPalette;
        this.itemSelectedEmit.emit({
            newValue:{
                value:{
                    rgb: this.fieldTypeColor.rgb,
                    hex: this.fieldTypeColor.companyColorSelected
                }
            }
        })
    }else{
      this.field.error = true;
    }
  }

  closePalette(){
    this.fieldTypeColor.showPalette = !this.fieldTypeColor.showPalette;
    this.fieldTypeColor.companyColorSelected = this.fieldTypeColor.companyColorSelectedOld;
    this.fieldTypeColor.rgb = this.fieldTypeColor.rgbOld;
    this.fieldTypeColor.colorError = false;
    // this.fieldTypeColor.field.error = false;
    this.field.error = false;
    this.itemSelectedEmit.emit({
        newValue:{
            value:{
                rgb: this.fieldTypeColor.rgbOld,
                hex: this.fieldTypeColor.companyColorSelectedOld
            }
        }
    })
  }

  boxColorPicker(e:any){
    e.stopPropagation();
    this.fieldTypeColor.inBox = false;
  }

  pickRangeColor(){
    this.fieldTypeColor.rgb = this.hsb2rgb({
        h: this.keepWithin(360 - parseInt(this.fieldTypeColor.colorRange), 0, 360),
        s: 108,
        b: 108
        })
    this.fieldTypeColor.boxColor = "linear-gradient(to top, rgb(0, 0, 0), transparent), linear-gradient(to left, rgb("+this.fieldTypeColor.rgb.r+","+this.fieldTypeColor.rgb.g+","+this.fieldTypeColor.rgb.b+"), rgb(255, 255, 255))";
    if(this.fieldTypeColor.companyColorSelected){
        this.extractColorCompany();
    }
}

  extractColorCompany(){
    this.fieldTypeColor.rgb = this.hsb2rgb({
        h: this.keepWithin(360 - parseInt(this.fieldTypeColor.colorRange), 0, 360),
        s: this.keepWithin(Math.floor(this.fieldTypeColor.point.x * (100 / 216)), 0, 100),
        b: this.keepWithin(100 - Math.floor(this.fieldTypeColor.point.y * (100 / 216)), 0, 100)
      })

    this.fieldTypeColor.companyColorSelected = this.rgb2hex(this.fieldTypeColor.rgb);
    this.fieldTypeColor.colorError = false;
    this.field.error = false;
  }

  rgb2hex(rgb:any) {
    let hex = [
        rgb.r.toString(16),
        rgb.g.toString(16),
        rgb.b.toString(16)
    ];
    $.each(hex, function(nr, val) {
        if(val.length === 1) hex[nr] = '0' + val;
    });
    return '#' + hex.join('');
  }

  changeInputColorHex(){
    if(this.commonUtilsService.checkIsHexadecimalColor(this.fieldTypeColor.companyColorSelected)){
        this.fieldTypeColor.colorError = false;
        let rgb:any = this.fieldTypeColor.rgb;
        if(!(rgb.r === 0 && rgb.b === 0 && rgb.g === 0) && !(rgb.r === 255 && rgb.b === 255 && rgb.g === 255)){
          this.fieldTypeColor.rgbCopy = rgb;
        }
        this.setColor();
    }else{
        this.fieldTypeColor.colorError = true;
    }
  }

  changeInputColorRgb(type:any, value:any){
    let rgb:any = this.fieldTypeColor.rgb;
    this.fieldTypeColor.colorError = false;
    if(!(rgb.r === 0 && rgb.b === 0 && rgb.g === 0) && !(rgb.r === 255 && rgb.b === 255 && rgb.g === 255)){
        this.fieldTypeColor.rgbCopy = rgb;
    }
    rgb[type] = parseInt(value);
    if(rgb[type] >= 0 && rgb[type] < 256){
        this.fieldTypeColor.companyColorSelected = this.rgb2hex(rgb);
        this.setColor();
    }
  }

  boxDown(e:any){
    this.fieldTypeColor.inBox = true;
    this.boxMove(e);
  }

  boxMove(e:any, exit:string = ''){
    if(exit === 'exit'){
        e.stopPropagation();
        this.fieldTypeColor.inBox = false;
    }else
    if(e.target.className === 'field-type-color__bright-saturation-block' && this.fieldTypeColor.inBox){
        this.fieldTypeColor.point = {
            x: e.offsetX,
            y: e.offsetY
        }
        this.extractColorCompany();
    }
  }
}
