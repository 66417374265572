<div>
  <div class="common__modal-header-block">
    <i class="lf-icon-refresh common__icon-header"></i>
    <span class="common__text-header"
        [afTranslate]="'repeat-pattern.establish-pattern-title'"> Patron de repeticion</span>

    <i class="lf-icon-close common__icon-close-modal"
        (click)="handleDismiss('cancel')"></i>
  </div>
  <div class="establish-pattern__body">
      <div class="establish-pattern__periodicity-box">
          <p class="establish-pattern__periodicity-title">{{establishPatternModal.frecuencyTitle}}</p>
          <field-type-list-simple
              class="establish-pattern__periodicity-list"
              [field]="establishPatternModal.periodicityType"
              [preSelectItem]="establishPatternModal.periodicityTypeSelected.listValueId"
              [listValues]="establishPatternModal.patternList.periodicityList"
              (itemSelected)="selectFrecuencyOn($event)">
          </field-type-list-simple>
          <div class="establish-pattern__periodicity-block">
              <p class="establish-pattern__periodicity-block-title">
                  <span [afTranslate]="'repeat-pattern.repeat-moment'"></span>
                  <span class="establish-pattern__periodicity-asterisk">*</span>
              </p>
              <i class="lf-icon-radio-buttons-list establish-pattern__periodicity-list-icon"></i>
              <ul class="establish-pattern__list-option"
                  [ngSwitch]="establishPatternModal.periodicityTypeSelected.listValueId">
                  <li *ngSwitchCase="1">
                      <label for="daily-first-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionDaily === '1'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="daily-first-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="daily-options"
                              value="1"
                              [(ngModel)]="establishPatternModal.radioOptionDaily">
                          <span class="establish-pattern__option-text establish-pattern__option-text--cap"
                              [afTranslate]="'add-alert.every'">Cada</span>
                          <input-number [preSelectValue]="establishPatternModal.dailyEachDay"
                              (selectedValue)="setNumber('dailyEachDay', $event)"
                              [disabled]="establishPatternModal.radioOptionDaily !== '1'">
                          </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.days'"></span>
                      </label>
                      <label class="establish-pattern__option-item"
                          for="daily-second-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionDaily === '2'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="daily-second-option"
                              type="radio"
                              class="establish-pattern__input-radio"
                              name="daily-options"
                              value="2"
                              [(ngModel)]="establishPatternModal.radioOptionDaily">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.all-days-week'">
                          </span>
                      </label>
                  </li>
                  <li *ngSwitchCase="2">
                      <label for="weekly-first-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionWeekly === '1'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="weekly-first-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="weekly-options"
                              value="1"
                              [(ngModel)]="establishPatternModal.radioOptionWeekly">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.repeat-every'">Cada</span>
                          <input-number [preSelectValue]="establishPatternModal.weeklyEachWeek"
                              (selectedValue)="setNumber('weeklyEachWeek', $event)"
                              [disabled]="establishPatternModal.radioOptionWeekly !== '1'">
                          </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.weeks-in-days'"></span>
                          <ul class="establish-pattern__week-day-list">
                              <li class="establish-pattern__week-day"
                                  [ngClass]="{'establish-pattern__week-day--selected': dayWeek.selected}"
                                  *ngFor="let dayWeek of establishPatternModal.patternList.weekDays"
                                  (click)="addOrDeleteWeekDay(dayWeek)">
                                  <span>{{dayWeek.text}}</span>
                                  <i class="lf-icon-check establish-pattern__week-day-icon"
                                      [ngShow]="dayWeek.selected"></i>
                              </li>
                          </ul>
                      </label>
                  </li>
                  <li *ngSwitchCase="3">
                      <label for="monthly-first-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionMonthly === '1'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="monthly-first-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="monthly-options"
                              value="1"
                              [(ngModel)]="establishPatternModal.radioOptionMonthly">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.the-day'">el dia</span>
                          <input-number [preSelectValue]="establishPatternModal.monthlyEachDay"
                              (selectedValue)="setNumber('monthlyEachDay', $event)"
                              [disabled]="establishPatternModal.radioOptionMonthly !== '1'"
                              [from]="1"
                              [until]="31">
                          </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.kind'"> de cada</span>
                          <input-number [preSelectValue]="establishPatternModal.monthlyEachMonth"
                              (selectedValue)="setNumber('monthlyEachMonth', $event)"
                              [disabled]="establishPatternModal.radioOptionMonthly !== '1'">
                          </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.month'">mes</span>
                      </label>
                      <label for="monthly-second-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionMonthly === '2'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="monthly-second-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="monthly-options"
                              value="2"
                              [(ngModel)]="establishPatternModal.radioOptionMonthly">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.the'">El</span>
                          <select-simple class="establish-pattern__kind-moment-select"
                              [selectItem]="establishPatternModal.monthlyNumeralSelected"
                              [listValues]="establishPatternModal.patternList.numeralTimeList"
                              (itemSelectedEmit)="selectOptionSelectSimple('monthlyNumeralSelected', $event)"
                              [disabled]="establishPatternModal.radioOptionMonthly !== '2'">
                          </select-simple>
                          <select-simple class="establish-pattern__kind-moment-select establish-pattern__kind-moment-select--time"
                              [selectItem]="establishPatternModal.monthlyWeekdaySelected"
                              [listValues]="establishPatternModal.patternList.weekDayList"
                              (itemSelectedEmit)="selectOptionSelectSimple('monthlyWeekdaySelected', $event)"
                              [disabled]="establishPatternModal.radioOptionMonthly !== '2'"
                              [widthUl]="200">
                          </select-simple>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.kind'">de cada</span>
                              <input-number [preSelectValue]="establishPatternModal.monthlyEachMonthSecond"
                                  (selectedValue)="setNumber('monthlyEachMonthSecond', $event)"
                                  [disabled]="establishPatternModal.radioOptionMonthly !== '2'">
                              </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.month'"></span>
                      </label>
                  </li>
                  <li *ngSwitchCase="4">
                      <label for="annual-first-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionAnnual === '1'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="annual-first-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="annual-options"
                              value="1"
                              [(ngModel)]="establishPatternModal.radioOptionAnnual">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.the-day'">el dia</span>
                              <input-number [preSelectValue]="establishPatternModal.annualEachDay"
                                  (selectedValue)="setNumber('annualEachDay', $event)"
                                  [disabled]="establishPatternModal.radioOptionAnnual !== '1'"
                                  [from]="1"
                                  [until]="31">
                              </input-number>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.kind'"> de cada</span>
                              <select-simple class="establish-pattern__kind-moment-select "
                                  [selectItem]="establishPatternModal.annualMonthSelected"
                                  [listValues]="establishPatternModal.patternList.monthList"
                                  (itemSelectedEmit)="selectOptionSelectSimple('annualMonthSelected', $event)"
                                  [disabled]="establishPatternModal.radioOptionAnnual !== '1'">
                              </select-simple>
                      </label>
                      <label for="annual-second-option"
                          class="establish-pattern__option-item">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="establishPatternModal.radioOptionAnnual === '2'?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                          <input id="annual-second-option"
                              class="establish-pattern__input-radio"
                              type="radio"
                              name="annual-options"
                              value="2"
                              [(ngModel)]="establishPatternModal.radioOptionAnnual">
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.the'">El</span>
                          <select-simple class="establish-pattern__kind-moment-select"
                              [selectItem]="establishPatternModal.annualNumeralSelected"
                              [listValues]="establishPatternModal.patternList.numeralTimeList"
                              (itemSelectedEmit)="selectOptionSelectSimple('annualNumeralSelected', $event)"
                              [disabled]="establishPatternModal.radioOptionAnnual !== '2'">
                          </select-simple>
                          <select-simple class="establish-pattern__kind-moment-select establish-pattern__kind-moment-select--time"
                              [selectItem]="establishPatternModal.annualWeekdaySelected"
                              [listValues]="establishPatternModal.patternList.weekDayList"
                              (itemSelectedEmit)="selectOptionSelectSimple('annualWeekdaySelected', $event)"
                              [disabled]="establishPatternModal.radioOptionAnnual !== '2'"
                              [widthUl]="200">
                          </select-simple>
                          <span class="establish-pattern__option-text"
                              [afTranslate]="'repeat-pattern.kind'">de cada</span>
                          <select-simple class="establish-pattern__kind-moment-select "
                              [selectItem]="establishPatternModal.annualMonthSelectedSecond"
                              [listValues]="establishPatternModal.patternList.monthList"
                              (itemSelectedEmit)="selectOptionSelectSimple('annualMonthSelectedSecond', $event)"
                              [disabled]="establishPatternModal.radioOptionAnnual !== '2'">
                          </select-simple>
                      </label>
                  </li>
              </ul>
          </div>

      </div>
      <div class="establish-pattern__repeat-interval">
          <p class="establish-pattern__periodicity-title"
              [afTranslate]="'repeat-pattern.repeat-interval'"></p>
          <field-type-date class="establish-pattern__periodicity-list"
              [field]="establishPatternModal.repeatIntervalDate"
              [preSelectItem]="establishPatternModal.startInterval"
              (itemSelected)="selectIntervalDate($event, 'startInterval')"></field-type-date>
          <div class="establish-pattern__periodicity-block establish-pattern__periodicity-block--repeat-interval">
              <p class="establish-pattern__periodicity-block-title">
                  <span [afTranslate]="'repeat-pattern.end-interval'"></span>
                  <span class="establish-pattern__periodicity-asterisk">*</span>
              </p>
              <i class="lf-icon-radio-buttons-list establish-pattern__periodicity-list-icon"></i>
              <div class="establish-pattern__block-list-interval">
                  <label class="establish-pattern__list-option-interval"
                      for="no-end-date">
                      <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                          [ngClass]="{'lf-icon-radio-button-active':establishPatternModal.selectInterval === 'no-end-date',
                          'lf-icon-step-first':establishPatternModal.selectInterval !== 'no-end-date'}">
                      </i>
                      <input class="establish-pattern__input-radio"
                          id="no-end-date"
                          type="radio"
                          name="select-interval"
                          value="no-end-date"
                          [(ngModel)]="establishPatternModal.selectInterval">
                      <span class="establish-pattern__option-text"
                          [afTranslate]="'repeat-pattern.no-end-date'">_Sin fecha de finalización</span>
                  </label>
              </div>
              <div class="establish-pattern__block-list-interval establish-pattern__block-list-interval--middle ">
                  <label class="establish-pattern__list-option-interval"
                      for="finish-after">
                      <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                          [ngClass]="{'lf-icon-radio-button-active':establishPatternModal.selectInterval === 'finish-after',
                          'lf-icon-step-first':establishPatternModal.selectInterval !== 'finish-after'}">
                      </i>
                      <input class="establish-pattern__input-radio"
                          id="finish-after"
                          type="radio"
                          name="select-interval"
                          value="finish-after"
                          [(ngModel)]="establishPatternModal.selectInterval">
                      <span class="establish-pattern__option-text"
                          [afTranslate]="'repeat-pattern.finish-after'">_Finalizar después de</span>
                      <div class="float-start">
                          <input-number
                              [preSelectValue]="establishPatternModal.amountRepetitionsInterval"
                              (selectedValue)="setNumber('amountRepetitionsInterval', $event)"
                              [disabled]="establishPatternModal.selectInterval !== 'finish-after'">
                          </input-number>
                      </div>
                      <span class="establish-pattern__option-text"
                          [afTranslate]="'repeat-pattern.repetitions'">_repeticiones</span>
                  </label>
              </div>
              <div class="establish-pattern__block-list-interval">
                  <label class="establish-pattern__list-option-interval"
                      for="end-interval-day">
                      <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                          [ngClass]="{'lf-icon-radio-button-active':establishPatternModal.selectInterval === 'end-interval-day',
                          'lf-icon-step-first':establishPatternModal.selectInterval !== 'end-interval-day'}">
                      </i>
                      <input class="establish-pattern__input-radio"
                          id="end-interval-day"
                          type="radio"
                          name="select-interval"
                          value="end-interval-day"
                          [(ngModel)]="establishPatternModal.selectInterval">
                      <span class="establish-pattern__option-text"
                          [afTranslate]="'repeat-pattern.end-interval-day'">_Finalizar el intervalo el día</span>
                      <picker-data-simple
                          [finishIntervalDate]="establishPatternModal.finishIntervalDate"
                          (selectDate)="selectIntervalDate($event, 'finishIntervalDate')"
                          [disabled]="establishPatternModal.selectInterval !== 'end-interval-day'">
                      </picker-data-simple>
                  </label>
              </div>
          </div>

      </div>
      <div class="establish-pattern__repeat-interval establish-pattern__repeat-interval--instances"
          [ngShow]="item.conceptId">
          <p class="establish-pattern__periodicity-title"
              [afTranslate]="'repeat-pattern.instance-names'"></p>
          <div class="establish-pattern__instances-properties-block">
              <field-type-text-simple class="establish-pattern__field-name"
                  [field]="establishPatternModal.nameField"
                  [preSelectItem]="establishPatternModal.newInstanceName"
                  (itemSelected)="selectOption('newInstanceName', $event)">
              </field-type-text-simple>
              <field-type-list-simple class="establish-pattern__field-list"
                  [field]="establishPatternModal.suffixField"
                  [preSelectItem]="establishPatternModal.newInstanceNameSuffix"
                  [listValues]="establishPatternModal.patternList.suffixList"
                  (itemSelected)="selectOption('newInstanceNameSuffix', $event)">
              </field-type-list-simple>
          </div>
      </div>
      <advice-message [ngShow]="establishPatternModal.hasError"
          [icon]="'warning'"
          [type]="'error'"
          [text]="establishPatternModal.warningText"
          (closeAdvice)="closeAdvice('showInfoMessage')">
      </advice-message>
      <advice-message [ngShow]="establishPatternModal.modifyPattern"
          [icon]="'information'"
          [type]="'error'"
          [text]="establishPatternModal.infoText"
          (closeAdvice)="closeAdvice('modifyPattern')">
      </advice-message>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button [ngShow]="establishPatternModal.repeatPattern && establishPatternModal.repeatPattern.frecuencyId"
          class="common__button common__button--clean"
          (click)="handleDismiss('delete')"
          [afTranslate]="'repeat-pattern.delete-pattern'">_Eliminar patrón</button>
      <button type="reset"
          class="common__button common__button--clean"
          (click)="handleDismiss('cancel')"
          [afTranslate]="'common.cancel'">_Cancelar repetición</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="validateRepetition()"
          [afTranslate]="'repeat-pattern.add-pattern'">_Añadir patrón</button>
  </div>
</div>

