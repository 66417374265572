import { Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { ConceptInstancesListParentService } from '../utils/concept-instances-list-parent.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'concept-instances-list',
  templateUrl: './concept-instances-list.component.html'
})
export class ConceptInstancesListComponent  implements OnInit, OnChanges {
  userPermissions:any = this.permissions.getPermissions();
  adviceModal: any;
  conceptInstancesList: any = {
    search: false,
    order: true,
    filterOrder: 'creationDate',
    searchInstances:  '',
    channelFilter: '',
    view:  'last15',
    headerTitle: this.translate.instant('dashboard.title-complaints'),
    selectedView: null,
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50,
      maxSize: 5
    },
    currentPage: 1,
    authorFilter: {
      name: this.translate.instant('common.author')
    },
    stateFilter: {
      name: this.translate.instant('common.state')
    },
    instancesListFilter: [],
    instancesListOriginFilter: [],
    instancesList: [],
    filters:  ['author', 'state'],
    words: ['title', 'evaluationName', 'author', 'state'],
    conceptInstancesView: [
      {
          name: this.translate.instant('dashboard.last-week'),
          viewOption: 'lastWeek'
      },
      {
          name: this.translate.instant('dashboard.last-month'),
          viewOption: 'lastMonth'
      },
      {
          name: this.translate.instant('dashboard.last-15'),
          viewOption: 'last15'
      },
      {
          name: this.translate.instant('dashboard.last-30'),
          viewOption: 'last30'
      },
      {
          name: this.translate.instant('dashboard.all-complaints'),
          viewOption: 'all'
      }
    ]
  }

  constructor(private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private conceptInstancesListParent: ConceptInstancesListParentService) {}

  @Input() filterInstancesList:any = null;
  @Input() productId:any = null;
  @Input() elementsView:number = 5;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.conceptInstancesList.selectedView = this.conceptInstancesList.conceptInstancesView[2];
  }

  ngOnChanges(): void {
    if(this.filterInstancesList !== null){
      this.conceptInstancesList.instancesList = this.filterInstancesList;
      this.conceptInstancesListParent.changeView(this.conceptInstancesList.view, this);
    }
  }

  filterElementOn(e:any, type:string){
    this.conceptInstancesListParent.selectTypeOperation(e.source, type, this);
  }

  toggleSearch(){
    this.conceptInstancesListParent.toggleSearch(this);
  }

  searchBy(type: any){
    this.conceptInstancesListParent.searchBy(type, this);
  }

  launchActionInstance(item: any, option: any){
    this.conceptInstancesListParent.launchActionInstance(item, option, this);
  }

  openModalConfidential(instance: any){
    this.conceptInstancesListParent.openModalConfidential(instance, this);
  }

  changeView(type: any){
    this.conceptInstancesListParent.changeView(type, this);
  }

  filterConcept(type: any){
    this.conceptInstancesListParent.filterConcept(type, this);
  }

  resetFilter(e: any, type: any){
    this.conceptInstancesListParent.resetFilter(e, type, this);
  }

  closeDropdown(type: any){
    this.conceptInstancesListParent.closeDropdown(type, this.dropdowns);
  }

}
