<div class="from-to-calendar">
    <div class="from-to-calendar__dropdown-period"
        [ngShow]="fromToCalendar.type === 'dashboard-reports'">
        <custom-select [options]="fromToCalendar.calendarOptionList"
            [selection]="fromToCalendar.selectCalendarOption"
            (selectOption)="selectOption($event.option)">
        </custom-select>
    </div>
    <div class="from-to-calendar__header"
        [ngClass]="{'from-to-calendar__header--dropdown': fromToCalendar.type === 'dashboard-reports'}">
        <div [ngHide]="fromToCalendar.hasError">
            <div [ngClass]="{'from-to-calendar__block-select-calendar-normal': isLanding}">
                <p class="from-to-calendar__date">
                    <span [ngHide]="fromToCalendar.from === null">{{fromToCalendar.from | convertDate: 'be-calendar'}}</span>
                    <span [ngShow]="fromToCalendar.from === null"
                        [afTranslate]="'dashboard-reports.start-date'"></span>
                </p>
                <i class="lf-icon-arrow-right from-to-calendar__arrow-icon"></i>
                <p class="from-to-calendar__date">
                    <span [ngHide]="fromToCalendar.to === null">{{fromToCalendar.to | convertDate: 'be-calendar'}}</span>
                    <span [ngShow]="fromToCalendar.to === null"
                        [afTranslate]="'dashboard-reports.end-date'"></span>
                </p>
            </div>
            <div *ngIf="isLanding"
                class="from-to-calendar__block-select-calendar-smartphone">
                <button class="from-to-calendar__button-date"
                    [ngClass]="{'from-to-calendar__button-date--selected': fromToCalendar.showCalendarMobile === 'from'}"
                    (click)="selectShowCalendar('from')">
                    <span [ngHide]="fromToCalendar.from === null">{{fromToCalendar.from | convertDate: 'be-calendar'}}</span>
                    <span [ngShow]="fromToCalendar.from === null"
                        [afTranslate]="'dashboard-reports.start-date'"></span>
                </button>
                <button class="from-to-calendar__button-date"
                    [ngClass]="{'from-to-calendar__button-date--selected': fromToCalendar.showCalendarMobile === 'to'}"
                    (click)="selectShowCalendar('to')">
                    <span [ngHide]="fromToCalendar.to === null">{{fromToCalendar.to | convertDate: 'be-calendar'}}</span>
                    <span [ngShow]="fromToCalendar.to === null"
                        [afTranslate]="'dashboard-reports.end-date'"></span>
                </button>
            </div>
            <p class="from-to-calendar__days-left">
                <span [ngShow]="fromToCalendar.selectDays && fromToCalendar.selectDays > 0">{{fromToCalendar.selectDays}} </span>
                <span>{{getTranslateSelectDays()}}</span>
            </p>
        </div>
        <div [ngShow]="fromToCalendar.hasError"
          class="from-to-calendar__header-error"
          [ngClass]="{'from-to-calendar__header-error--dropdown': fromToCalendar.type === 'dashboard-reports'}">
            <p [afTranslate]="'dashboard-reports.dates-error'"></p>
        </div>

    </div>
    <div class="from-to-calendar__body"
        *ngIf="fromToCalendar.ready">
        <ngb-datepicker class="from-to-calendar__calendar"
            [ngClass]="{'from-to-calendar__calendar--hidden-mobile': isLanding && fromToCalendar.showCalendarMobile === 'to'}"
            #from
            id="from"
            [displayMonths]="1"
            outsideDays="hidden"
            navigation="none"
            [weekdays]="false"
            [(ngModel)]="fromToCalendar.fromPicker"
            (dateSelect)="stopEvent($event, 'from')"
            [dayTemplate]="fromDay">
            <ng-template ngbDatepickerContent>
                <div *ngIf="!fromToCalendar.showMonthsFrom">
                  <div *ngFor="let month of from.state.months">
                      <div class="field-type-date__header-date-picker">
                          <button class="from-to-calendar__calendar-title-button"
                              (click)="fromToCalendar.showMonthsFrom = !fromToCalendar.showMonthsFrom">
                              {{ month.month+"-01-2023" | convertDate:'only-month' }} {{ month.year }}
                          </button>
                          <div class="field-type-date__arrow-buttons field-type-date__arrow-buttons--from-to-calendar">
                              <button type="button"
                                  class="lf-icon-angle-left field-type-date__previous-button field-type-date__previous-button--from-to-calendar"
                                  (click)="navigate(from, -1);">
                              </button>
                              <button type="button"
                              class="lf-icon-angle-right field-type-date__next-button field-type-date__next-button--from-to-calendar"
                              (click)="navigate(from, 1)">
                          </button>
                          </div>
                      </div>
                      <div class="from-to-calendar__block-month-calendar">
                        <div class="ngb-dp-week ngb-dp-weekdays from-to-calendar__weekday">
                            <div class="ngb-dp-weekday small"
                                *ngFor="let day of fromToCalendar.days">
                                {{day | convertDate: fromToCalendar.convertDateType}}
                            </div>
                        </div>
                        <ngb-datepicker-month [month]="month"></ngb-datepicker-month>
                      </div>
                  </div>
                </div>
                <div *ngIf="fromToCalendar.showMonthsFrom">
                    <calendar-month-year [activedMonth]="fromToCalendar.selectedMonthFrom"
                      [activedYear]="fromToCalendar.selectedYearFrom"
                      [type]="'from-to-calendar'"
                      (navigateSelection)="navigateSelection($event, 'From')"></calendar-month-year>
                </div>
            </ng-template>
            <ng-template #fromDay
                let-date
                let-focused="focused">
                <div class="from-to-calendar__day-box"
                    [ngClass]="{'from-to-calendar__underline':isBetWeen(date),
                        'from-to-calendar__underline--from':selectedDate(date, 'from')}">
                    <div class="from-to-calendar__day"
                        [ngClass]="{'from-to-calendar__today':isToday(date),
                        'from-to-calendar__selected-day': selectedDate(date, 'from')}">
                        {{ date.day  | addZero }}
                    </div>
                </div>
            </ng-template>
        </ngb-datepicker>

        <ngb-datepicker class="from-to-calendar__calendar"
            [ngClass]="{'from-to-calendar__calendar--hidden-mobile': isLanding && fromToCalendar.showCalendarMobile === 'to'}"
            #to
            id="to"
            [displayMonths]="1"
            outsideDays="hidden"
            navigation="none"
            [weekdays]="false"
            [(ngModel)]="fromToCalendar.toPicker"
            [dayTemplate]="toDay"
            (dateSelect)="stopEvent($event, 'to')">
            <ng-template ngbDatepickerContent>
                <div *ngIf="!fromToCalendar.showMonthsTo">
                  <div *ngFor="let month of to.state.months">
                      <div class="field-type-date__header-date-picker">
                          <button class="from-to-calendar__calendar-title-button"
                              (click)="fromToCalendar.showMonthsTo = !fromToCalendar.showMonthsTo">
                              {{ month.month+"-01-2023" | convertDate:'only-month' }} {{ month.year }}
                          </button>
                          <div class="field-type-date__arrow-buttons field-type-date__arrow-buttons--from-to-calendar">
                              <button type="button"
                                  class="lf-icon-angle-left field-type-date__previous-button field-type-date__previous-button--from-to-calendar"
                                  (click)="navigate(to, -1);">
                              </button>
                              <button type="button"
                              class="lf-icon-angle-right field-type-date__next-button field-type-date__next-button--from-to-calendar"
                              (click)="navigate(to, 1)">
                          </button>
                          </div>
                      </div>
                      <div class="from-to-calendar__block-month-calendar">
                        <div class="ngb-dp-week ngb-dp-weekdays from-to-calendar__weekday" >
                            <div class="ngb-dp-weekday small"
                                *ngFor="let day of fromToCalendar.days">
                                {{day | convertDate: fromToCalendar.convertDateType}}
                            </div>
                        </div>
                        <ngb-datepicker-month [month]="month"></ngb-datepicker-month>
                      </div>
                  </div>
                </div>
                <div *ngIf="fromToCalendar.showMonthsTo">
                    <calendar-month-year [activedMonth]="fromToCalendar.selectedMonthTo"
                      [activedYear]="fromToCalendar.selectedYearTo"
                      [type]="'from-to-calendar'"
                      (navigateSelection)="navigateSelection($event, 'To')"></calendar-month-year>
                </div>
            </ng-template>
            <ng-template #toDay let-date let-focused="focused">
                <div class="from-to-calendar__day-box"
                    [ngClass]="{ 'from-to-calendar__underline':isBetWeen(date),
                    'from-to-calendar__underline--to':selectedDate(date, 'to')}">
                    <div class="from-to-calendar__day"
                        [ngClass]="{'from-to-calendar__today':isToday(date),
                            'from-to-calendar__selected-day': selectedDate(date, 'to')}">
                        {{ date.day  | addZero }}
                    </div>
                </div>
            </ng-template>
        </ngb-datepicker>
    </div>
    <div class="from-to-calendar__buttons-block">
        <button class="from-to-calendar__button-restore"
              (click)="restore()"
              *ngIf="isLanding && fromToCalendar.filteredCalendar">
              <i class="lf-icon-filter-delete"></i>
              <span [afTranslate]="'common.restore'">_Restore</span></button>
        <div class="from-to-calendar__block-buttons-right">
          <button class="common__button common__button--clean common__button--from-to"
                  (click)="restore()"
                  *ngIf="!isLanding"
                  [afTranslate]="'common.restore'">_Restore</button>
          <button [ngClass]="isLanding?'common__button-landing-custom common__button-landing-custom--little common__button-landing-custom--cancel':'common__button common__button--clean common__button--from-to'"
                  (click)="cancel()"
                  [ngStyle]="fromToCalendar.styleButtonCancel"
                  [afTranslate]="'common.cancel'">_cancel</button>
          <button [ngClass]="isLanding?'common__button-landing-custom common__button-landing-custom--little common__button-landing-custom--send common__button-landing-custom--last':'common__button common__button--active common__button--from-to'"
                  (click)="apply()"
                  [ngStyle]="fromToCalendar.styleButtonApply"
                  [disabled]="fromToCalendar.from === null && fromToCalendar.to === null"
                  [afTranslate]="'common.apply'">_apply</button>
        </div>
    </div>
</div>
