import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, Subject } from "rxjs";

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class AudioRecordingService{
  private stream:any = null;
  private mediaRecorder: any;
  private chunks: any ;
  private recorder:any;
  private interval:any;
  private startTime:any;
  private time: number;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording(child:any) {
    if (this.recorder) {
      return;
    }
    const time:any = {
      timeString: "00:00",
      timePercent: 0,
      secondsRecord: 0
    }
    this._recordingTime.next(time);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch(error => {
        this._recordingFailed.next('00:00');
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.chunks = [];
    this.mediaRecorder = new MediaRecorder(this.stream, {mimeType:"audio/webm"});
    this.mediaRecorder.start();
    this.mediaRecorder.ondataavailable = (e:any)=>{ this.chunks.push(e.data); };
    this.startTime = moment();
    this.time = 0;
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time:any = {
        timeString: this.timeToString(diffTime.minutes()) + ":" + this.timeToString(diffTime.seconds()),
        timePercent: (this.time * 100) / 179,
        secondsRecord: this.time
      }
      this._recordingTime.next(time);
      this.time++;
    }, 1000);
  }

  timeToString(value:any) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  stopRecording(child:any) {
    this.mediaRecorder.stop();
    if (this.startTime) {
      this.mediaRecorder.onstop = async ()=>{
        let blob = new Blob(this.chunks, { type: "audio/webm" });
        let audioURL = window.URL.createObjectURL(blob);
        let arrayBuffer = await (await fetch(audioURL)).arrayBuffer();
        try {
          child.recordAudioModal.globalAudioBuffer = await (new AudioContext()).decodeAudioData(arrayBuffer);
        } catch(e) {}
        let date: string = moment().format('DD-MM-YYYY HHmmss')+'.mp3'
        const mp3Name = encodeURIComponent(date);
        this._recorded.next({ blob: blob, title: mp3Name, name: date });
        this.stopMedia();
      }
    }
  }

  private stopMedia() {
    if(this.stream !== null){
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      this.stream.getAudioTracks().forEach((track:any) => track.stop());
      this.stream = null;
    }
  }
}
