<div class="common__table-row"
    *ngFor="let evaluation of evaluationsListFilter | byWord:searchEvaluation:searchWord | afOrderBy:dateFilter:orderOperationDates"
    [ngShow]="view !== 'groupClients' || (view === 'groupClients' && evaluation.clientId === client?.clientId)">
    <div class="evaluations-list__column evaluations-list__column--client-antiblanqueo evaluations-list__column--tooltip evaluations-list__column--first">
        <parragraph-tooltip *ngIf="view !== 'groupClients'"
          [text]="evaluation.clientName"></parragraph-tooltip>
    </div>
    <div class="evaluations-list__column evaluations-list__column--name-antiblanqueo evaluations-list__column--tooltip"
        (click)="emitEvent('editOption', evaluation, $event)">
        <parragraph-tooltip [text]="evaluation.name"
          [cursor]="true"></parragraph-tooltip>
    </div>
    <div class="evaluations-list__column evaluations-list__column--result-antiblanqueo evaluations-list__column--tooltip">
        <span-tooltip [text]="evaluation.result"
          class="evaluations-list__result-tag"
          [ngStyle]="{'background-color': evaluation.colorRGBAResult}"></span-tooltip>
    </div>
    <div class="evaluations-list__column evaluations-list__column--icon-antiblanqueo">
        <i class="evaluations-list__icon-blanqueo-alert"
            [ngClass]="evaluation.operationLocked?'lf-icon-alert':'lf-icon-close-round'"
            [ngShow]="evaluation.operationLocked || evaluation.operationNotSupported"
            matTooltip="{{(evaluation.operationLocked?'common.locked':'evaluation-header.not-supported') | afTranslate}}"
            matTooltipPosition="below"
            matTooltipClass="below"></i>
    </div>
    <div class="evaluations-list__column evaluations-list__column--modification-antiblanqueo">
        <parragraph-tooltip [text]="evaluation.modified | convertDate: 'without-time'"></parragraph-tooltip>
    </div>
    <div class="evaluations-list__column evaluations-list__column--actions-antiblanqueo">
        <ul class="common__options-row"
            *ngIf="evaluation.type !== 'client'">
            <li class="common__menu-options-row"
                [ngClass]="{'common__menu-options-row--view': !canCreate || (!evaluation.canModify && !evaluation.canManage)}"
                *ngIf="view !== 'deleted'"
                matTooltip="{{(canCreate && (evaluation.canModify || evaluation.canManage)?'common.edit': 'common.view') | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
                <i [ngClass]="{'lf-icon-editable-form': canCreate && (evaluation.canModify || evaluation.canManage), 'lf-icon-visible': !canCreate || (!evaluation.canModify && !evaluation.canManage)}"
                    (click)="emitEvent('editOption', evaluation, $event)"></i>
            </li>
            <li class="common__menu-options-row"
                *ngIf="evaluation.canManage && view !== 'deleted'"
                (click)="emitEvent('rename', evaluation)"
                matTooltip="{{'expanded-menu.rename' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
                <i class="lf-icon-rename"></i>
            </li>
            <li class="common__menu-options-row"
                *ngIf="evaluation.canManage && view === 'deleted'"
                (click)="emitEvent('restoreEvaluation', evaluation)"
                matTooltip="{{'common.restore' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
                <i class="lf-icon-rotate"></i>
            </li>
            <li class="common__menu-options-row"
                *ngIf="evaluation.canManage"
                matTooltip="{{'common.remove' | afTranslate}}"
                matTooltipPosition="below"
                matTooltipClass="below">
                <i class="lf-icon-trash"
                  (click)="emitEvent('deleteEvaluation', evaluation)"></i>
            </li>
        </ul>
        <i class="lf-icon-kebab-menu common__icon-ellipsis"
            *ngIf="evaluation.type !== 'client'"></i>
    </div>
</div>
<pdf-table class="common__pdf-hidde-element"
    [configuration]="configurationPdf"></pdf-table>
