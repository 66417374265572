import { Component, OnInit, Input } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsManagement } from '@shared/factories/clients-management/clients-management.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { VisibilityService } from '@shared/services/visibility/visibility.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RolesUtilsService } from '@shared/services/roles-utils/roles-utils.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { cloneDeep } from 'lodash';
import $ from 'jquery';

@Component({
  selector: 'new-evaluation',
  templateUrl: './new-evaluation.component.html',
})
export class NewEvaluationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  product: string;
  seeFilial: boolean = false;
  state:string = "uncreated";
  propertiesList:any = [];
  wizardBegin: number = 1;
  preSelectClientId: string;
  evaluationInfo: any = {};
  stepsLength: number = 0;
  propertiesWithVisibility:any = [];
  evaluatedMap:any = null;
  configureInit:boolean = true;
  newEvaluation:any = {
    configuredSteps:false,
    legalFormInfo: false,
    groupPropertiesList: [],
    roles: [],
    activeText: this.translate.instant('common.next'),
    headerIcon: 'icon-label-validate',
    title: '',
    steps:[],
    legalFormsList: [],
    createEvaluation:{},
    repeatedSelectedClient:false,
    productHasAnyRoles:false,
    productId: null,
    wizardState: 1,
    propertiesIndex: 3,
    clientSelector:{
      id: 'new-evaluation',
      from: 'new-evaluation',
      required:  true,
      withLabel: false
    },
    evaluationName: {
      label: this.translate.instant('common.identifier'),
      required:  true,
      description: this.translate.instant('new-evaluation.info-name-evaluation')
    },
    evaluationDescription: {
      label: this.translate.instant('common.description'),
      description: null,
      multiline:true
    },
  }

  constructor( private activeModal: NgbActiveModal,
    private context: ContextFactory,
    private clientsManagement: ClientsManagement,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private visibilityService: VisibilityService,
    private modalService: ModalServiceService,
    private rolesUtilsService: RolesUtilsService,
    private broadcastService: BroadcastService) { }

  @Input() configuration: any;

  ngOnInit(): void {
    this.newEvaluation.type = this.configuration.type==='new-blanqueo'?'new':this.configuration.type;
    this.newEvaluation.clients = this.configuration.clients;
    let title:string = '';
    if(this.newEvaluation.type === 'new'){
      this.loader.openLoader('new-implantation-from-dashboard');
      title = this.skin === 'steel-blue' || (this.configuration.selectedProduct && this.configuration.selectedProduct.productId === 'denunc')? 'new-evaluation.new-channel' : 'new-evaluation.new-implantation';
      this.createNewEvaluation();
    }else if(this.newEvaluation.type === 'edit'){
        this.editEvaluation();
        title = this.skin === 'steel-blue' || (this.configuration.selectedProduct && this.configuration.selectedProduct.productId === 'denunc')? 'new-evaluation.edit-channel' : 'new-evaluation.edit-implantation'
    }
    this.newEvaluation.title = this.translate.instant(title)
  }

  finishCreation(){
    this.clientsManagement.reset();
    let roles: any;
    if(this.newEvaluation.productHasAnyRoles){
      roles = this.newEvaluation.lastRoles
    }
    this.handleAccept(roles);
  }

  handleAccept(roles: any) {
    this.activeModal.close({
      result : 'ok',
      createEvaluation : this.newEvaluation.createEvaluation,
      roles: roles,
      configuration: {
        legalForm: this.newEvaluation.legalFormInfo,
        noEditable: this.newEvaluation.legalForm && this.newEvaluation.legalForm.noEditable
      }
    })

  }

  handleDismiss() {
    this.clientsManagement.reset();
    this.activeModal.close({result:'cancel'});
  }

  createNewEvaluation(){
    this.newEvaluation.steps = [
      {
        name:this.translate.instant('common.organization'),
        noEditable:false
      }
    ];
    let productId = this.configuration.selectedProduct.productId;
    this.getLegalForm(productId);
    this.selectProduct(productId);
  }

  getLegalForm(productId: any){
    this.newEvaluation.legalFormInfo = productId === 'blanqueo' && this.skin === 'dark-blue';
    if(this.newEvaluation.legalFormInfo){
        this.newEvaluation.legalForm = {
            label: this.translate.instant('new-client.client-legalform'),
            id: 'legalform-client-evaluation',
            required: true
        };
        $('.new-evaluation__modal-window').addClass('new-evaluation__modal-window-legal-form');

        this.apiService.get('clients/legalforms')
          .then((data:any)=>{
            this.newEvaluation.legalFormsList = this.commonUtilsService.editList(data, 'legalFormId', 'legalForm');
          }, (errorData:any)=>{})
    }
  }

  selectProduct(productId:string){
    this.newEvaluation.productId = productId;
    if(this.newEvaluation.type !== 'edit'){
        this.newEvaluation.steps.push({
            name: this.translate.instant('new-evaluation.evaluation')
        });
    }
    if(this.skin === 'steel-blue' || (this.configuration.selectedProduct && this.configuration.selectedProduct.productId === 'denunc')){
        this.newEvaluation.steps[1].name = this.translate.instant('common.channel');
    }
    this.configureForm();
    this.newEvaluation.createEvaluation.productId = productId;
    !this.newEvaluation.createEvaluation.productId? this.newEvaluation.createEvaluation.error = true : this.checkProductRoles();
  }

  configureForm(){
      if(this.newEvaluation.type ==='edit'){
        this.newEvaluation.wizardState = 2;
        this.newEvaluation.activeText = this.translate.instant('common.next');
        this.newEvaluation.createEvaluation.propertyValues= [];
        this.goNext();
      }else{
        this.newEvaluation.searchClient = '';
        this.wizardBegin = 1;
        this.newEvaluation.activeText = this.translate.instant('common.next');
        this.newEvaluation.createEvaluation = {
          // productName: null,
          productId: this.newEvaluation.createEvaluation.productId,
          propertyValues: []
        };
      }
      this.newEvaluation.groupPropertiesList.forEach((group:any) => {
        group.forEach((property:any) => {
          property.preSelect = null;
              if(!property.isBoolean){
                property.listValues.forEach((listValueProperty: any) => {
                  listValueProperty.selected = false;
                });
              }
        });
      });
      this.resetRoles();
  }

  reset(){
    switch(this.newEvaluation.wizardState){
        case 1:
            this.newEvaluation.repeatedSelectedClient = false;
            this.newEvaluation.clientSelector.noClientSelected = false;
            delete this.newEvaluation.createEvaluation.clientId;
            delete this.newEvaluation.createEvaluation.clientName;
            if(this.newEvaluation.legalFormInfo){
                delete this.newEvaluation.createEvaluation.legalFormId;
                this.newEvaluation.legalForm.noEditable = false;
                this.newEvaluation.legalForm.error = false;
                if(this.newEvaluation.legalFormsList?.length){
                    this.newEvaluation.legalFormsList.forEach((legalForm:any) => {
                      delete legalForm.selected;
                    })
                }
            }
            break;
        case 2:
            delete this.newEvaluation.createEvaluation.description;
            delete this.newEvaluation.createEvaluation.name;
            break;
        default:
            if(this.newEvaluation.productHasAnyRoles && this.newEvaluation.wizardState === this.newEvaluation.stepsLength){
                this.resetRoles(true);
            }else{
                this.newEvaluation.groupPropertiesList.forEach((groupProperty:any) => {
                  groupProperty.forEach((property:any) => {
                    property.preSelect = null;
                    if(!property.isBoolean){
                        property.listValues.forEach((listValueProperty:any) => {
                          listValueProperty.selected = false;
                        })
                    }
                  })
                })
                this.visibilityService.configureProperties(this.newEvaluation.propertiesWithVisibility, this.newEvaluation.evaluatedMap);
                this.newEvaluation.activeText = this.translate.instant('common.next');
                this.newEvaluation.wizardState = 4;
            }
            break;
    }
  }

  goNext(){
    if(this.validateField()){
      if(this.newEvaluation.wizardState === 1 && this.newEvaluation.type === 'new' && this.preSelectClientId !== this.newEvaluation.createEvaluation.clientId){
        this.preSelectClientId = this.newEvaluation.createEvaluation.clientId;
        if(this.newEvaluation.productHasAnyRoles){
          this.rolesUtilsService.getProductRoles(this.newEvaluation.createEvaluation.productId, this.newEvaluation.createEvaluation.clientId)
            .then((data:any)=>{
              this.newEvaluation.roles = data;
            }, (errorData:any)=>{
            })
        }
      }
      if(this.newEvaluation.wizardState === 3 && this.newEvaluation.productHasAnyRoles){
        let text = this.translate.instant('new-evaluation.question-create-with-role').replace('{clientName}', this.newEvaluation.createEvaluation.clientName);
        this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any)=>{
          if(result.result ==='ok'){
            if(this.newEvaluation.groupPropertiesList.length === 0){
              this.finishCreation();
            }else{
              this.newEvaluation.lastRoles = this.rolesUtilsService.getRolesConfigurated(this.newEvaluation.roles);
              this.newEvaluation.wizardState += 1;
              if(this.newEvaluation.wizardState === (this.newEvaluation.steps.length-1 + this.wizardBegin)){
                this.newEvaluation.type === 'new'? this.newEvaluation.activeText = this.translate.instant('common.create') : this.newEvaluation.activeText = this.translate.instant('common.save');
              }
            }
          }
        });
      }else if(this.newEvaluation.activeText === this.translate.instant('common.next')){
        this.newEvaluation.wizardState += 1;
        if(this.newEvaluation.wizardState === (this.newEvaluation.steps.length-1 + this.wizardBegin)){
          this.newEvaluation.type === 'new'? this.newEvaluation.activeText = this.translate.instant('common.create') : this.newEvaluation.activeText = this.translate.instant('common.save');
        }
      }else if(this.newEvaluation.activeText === this.translate.instant('common.create') || this.newEvaluation.activeText === this.translate.instant('common.save')){
        this.finishCreation();
      }else{
        this.state = 'created';
        this.newEvaluation.wizardState += 1;
      }
    }
  }

  validateField(){
    if(this.newEvaluation.productHasAnyRoles && this.newEvaluation.wizardState === this.newEvaluation.steps.length){
      return this.checkValidateRoles();
    }else{
      switch(this.newEvaluation.wizardState){
        case 1:
          this.newEvaluation.clientSelector.noClientSelected = false;
          let errors = 0;
          if (this.newEvaluation.createEvaluation.clientId) {

            if(this.configuration.selectedProduct && this.configuration.selectedProduct.productId === 'forms' && this.configuration.selectedProduct.evaluations.filter((formsEvaluation:any)=> formsEvaluation.clientId === this.newEvaluation.createEvaluation.clientId).length){
              this.newEvaluation.repeatedSelectedClient = true;
              errors++;
            }

          } else {
            errors++;
            this.newEvaluation.clientSelector.noClientSelected = true;
          }
          if(this.newEvaluation.legalFormInfo && !this.newEvaluation.createEvaluation.legalFormId){
            this.newEvaluation.legalForm.error = true;
            errors++;
          }
          return errors?false:true;
        case 2:
          this.newEvaluation.evaluationName.error = (!this.newEvaluation.createEvaluation.name);
          return !this.newEvaluation.evaluationName.error;
        default:
          return true;
      }
    }
  }

  checkValidateRoles(){
    let validate = true;
    this.newEvaluation.roles.forEach((rol:any) => {
      rol.error = false;
      if(!rol.selectContacts || (rol.selectContacts && !rol.selectContacts.length)){
        rol.error = true;
        validate = false;
      }
    });
    return validate;
  }


  resetSelect(list:any){
    list.forEach((item:any)=>{
      item.selected = false
    })
  }

  resetRoles(resetRoles: any=false){
    this.newEvaluation.roles.forEach((role:any) => {
      delete role.selectContacts;
      role.selectContacts = cloneDeep(role.preAssignedUsers);
      if(resetRoles){
        this.broadcastService.broadcast('resetFieldRole', {field: role});
      }
    });
  }

  editEvaluation(){
    this.newEvaluation.steps = [
      {
        name:this.translate.instant('common.implantation'),
        noEditable:true
      },{
        name:this.translate.instant('common.organization'),
        noEditable:true
      }
    ]
    this.getLegalForm(this.context.settings.defaultProduct);
    this.getEvaluationInfo();
  }

  getEvaluationInfo(){
    this.apiService.get('evaluations',  this.configuration.evaluationId)
      .then((data:any)=>{
        this.evaluationInfo = data;
        this.newEvaluation.createEvaluation={
          productId: data.productId,
          name: data.name,
          description: data.description,
          clientId: data.clientId,
          clientName: data.clientName
        }
        this.getEvaluationProperties(this.configuration.evaluationId);
      }, (errorData:any)=>{
      })
  }

  getEvaluationProperties(evaluationId:any){
    this.apiService.get('evaluations/'+ evaluationId, 'propertiesvalues')
      .then((data:any)=>{
        this.newEvaluation.createEvaluation.propertyValues = data;
        this.checkProductRoles();
      }, (errorData:any)=>{
      })
  }

  getProductProperties(){
    this.apiService.get('propertiesgroups', this.newEvaluation.createEvaluation.productId)
      .then((data:any)=> {
        data.forEach((item:any) => {
          this.newEvaluation.steps.push({name: item.name});
        });
        this.configurePropertiesList(data);
        this.configureWizardState();
      });
  }

  checkProductRoles(){
    this.apiService.get('productroles/product/' + this.newEvaluation.createEvaluation.productId, 'has-any')
      .then((data:any)=>{
        this.newEvaluation.productHasAnyRoles = data.hasAny;
        if(data.hasAny){
            if(this.newEvaluation.type !== 'new'){
                this.rolesUtilsService.getProductRoles(this.newEvaluation.createEvaluation.productId, this.newEvaluation.createEvaluation.clientId)
                  .then((data:any)=>{
                    this.newEvaluation.roles = data;
                  }, (errorData:any)=>{
                  })
            }
            this.newEvaluation.steps.push(
              {
                name: this.translate.instant('new-evaluation.role-assignment'),
                type: 'roles'
              }
            );
           this.newEvaluation.propertiesIndex = 4
        }
        this.getProductProperties();

      }, (errorData:any)=>{
      })
}

configureWizardState(){
  switch(this.configuration.type){
    case 'edit':
      this.newEvaluation.wizardState = 2;
      this.goNext();
      break;
    case 'new-blanqueo':
      if(this.configuration.clients && this.configuration.clients.length === 1){
        this.newEvaluation.createEvaluation.legalFormId = this.configuration.clients[0].idFormaJuridica;
        this.newEvaluation.createEvaluation.clientId = this.configuration.clients[0].clientId;
        this.newEvaluation.createEvaluation.clientName = this.configuration.clients[0].clientName;
        if(this.newEvaluation.legalFormInfo){
          this.newEvaluation.legalForm.noEditable = this.newEvaluation.createEvaluation.legalFormId?true:false;
        }
        this.newEvaluation.wizardState = 1;
        this.goNext();
      }
      break;
    default:
      this.newEvaluation.wizardState = 1;
      break;
  }

  this.newEvaluation.stepsLength = this.newEvaluation.steps.length;
  this.newEvaluation.configuredSteps = true;
}

configurePropertiesList(data:any){
  data.forEach((item:any) => {
    let propertiesGroup:any = [];
    this.newEvaluation.evaluationProperties = [];
    item.properties.forEach((property:any) => {
      let configureList:any = {
        listField:{
          fieldId: 'property-'+property.propertyId,
          fieldTypeId: property.isBoolean?'bool':'list',
          label:property.name,
          description:property.description,
          required: false,
          multiple: property.multiple,
          isOpen: false,
          error: false
        },
        listValues: this.configureListValuesProperty(property),
        propertyId:property.propertyId,
        isBoolean: property.isBoolean,
        isMultiple: property.multiple,
        isExtendedList: property.isExtendedList,
        showWithProperty: property.isBoolean?property.showWithProperty : 0,
        propertyVisibleValues: property.propertyVisibleValues,
        visibilityOr: property.visibilityOr
      }

      if(this.newEvaluation.type ==='edit'){
          this.setPropertiesValues(configureList, property)
      }
      this.propertiesList.push(configureList);
      propertiesGroup.push(configureList)
    });
    this.newEvaluation.groupPropertiesList.push(propertiesGroup);
  });


    this.propertiesWithVisibility = this.visibilityService.extractVisibilityProperties(this.newEvaluation.groupPropertiesList);
    this.evaluatedMap = this.visibilityService.evaluatedPropertiesMap(this.newEvaluation.groupPropertiesList);
    if(this.newEvaluation.type==='edit'){
        this.configureVisibilityList();
    }
  }
  configureListValuesProperty(property:any ){
    property.propertyValues.forEach((propertyValue:any) => {
      propertyValue.listValueId = propertyValue.propertyValueId;
    });
    return property.propertyValues;
  }

  setPropertiesValues(configureList:any, property:any){
    this.newEvaluation.createEvaluation.propertyValues.forEach((propertyValue:any) => {
      if(property.propertyId === propertyValue.propertyId){
        if(property.isBoolean){
            for(let k = 0; k < property.propertyValues.length; k++){
              let preSelect:any = undefined;
                if(k === 0){
                    preSelect = true;
                }else if(k === 1){
                    preSelect = false;
                }
                if(property.propertyValues[k].propertyValueId === propertyValue.propertyValueId){
                    configureList.preSelect = preSelect;
                    break;
                }
            }
        }else{
            if(!property.multiple){
                configureList.preSelect = propertyValue.propertyValueId
            }else{
              !configureList.preSelect? configureList.preSelect = ""+propertyValue.propertyValueId : configureList.preSelect += '|' + propertyValue.propertyValueId;
            }
        }
      }
    });
  }

  configureVisibilityList(){
    this.propertiesList.forEach((property:any) => {
      if (property.isBoolean) {
        if (property.preSelect != undefined) {
          this.selectBoolean(property.preSelect, property);
        }
      }else{
        this.propertySelected(property.preSelect, property);
      }
    });

    if(this.configureInit){
      this.configureInit = !this.configureInit;
    }
  }

  selectBoolean(newValue:any, property:any){
    if(newValue!==null){
        property.preSelect = newValue;
        let propertyValue = {
            propertyValueId: null,
            propertyId: null,
            listValueId: null
        };
        if(newValue=== false){
            propertyValue = property.listValues[1]
        }else if(newValue === true){
            propertyValue = property.listValues[0]
        }

        if(this.newEvaluation.type ==='edit'){
            this.changeInProperty(propertyValue.propertyValueId, property);
        }else{
            this.deleteOrModifyProperties(property.propertyId);
            this.addOrModifyProperty(propertyValue.propertyValueId, property);
        }
    }else{
        this.deleteOrModifyProperties(property.propertyId);
        property.preSelect = null;
    }
    this.visibilityService.configureProperties(this.propertiesWithVisibility, this.evaluatedMap);
    this.validateField();
  }

  changeInProperty(newValue:any, property:any){
    let modifyProperty =  false;
    for(let i =0; i < this.newEvaluation.createEvaluation.propertyValues.length; i++){
      let propertyValues = this.newEvaluation.createEvaluation.propertyValues[i];
      if(propertyValues.propertyId === property.propertyId){
        this.newEvaluation.createEvaluation.propertyValues.splice(i, 1, {
          propertyValueId: newValue,
          propertyId: property.propertyId,
          evaluationPropertyId: propertyValues.evaluationPropertyId
        })
        modifyProperty = !modifyProperty;
        break;
      }
    }
    if(!modifyProperty && newValue !== undefined){
      this.newEvaluation.createEvaluation.propertyValues.push({
        propertyValueId: newValue,
        propertyId: property.propertyId
      })
    }
  }

  deleteOrModifyProperties(id:string){
    for(let i = this.newEvaluation.createEvaluation.propertyValues.length-1; i >=0; i--){
        let propertyId = this.newEvaluation.createEvaluation.propertyValues[i].propertyId
        if(propertyId === id){
          this.newEvaluation.createEvaluation.propertyValues.splice(i, 1);
        }
    }
  }

  addOrModifyProperty(newValue:any, property:any){
    this.newEvaluation.createEvaluation.propertyValues.push({
        propertyValueId: newValue,
        propertyId: property.propertyId
    });
  }

  propertySelected(newValue:any, property:any){
    let lastValue = cloneDeep((property.preSelect));
    property.preSelect = newValue;
    if(newValue!==null){
      if(this.newEvaluation.type ==='new'){
        if(property.listField.multiple){
          if(newValue !== undefined){
            this.modifyMultipleProperties(newValue, property)
          }
        }else{
          this.addOrModifyProperty(newValue, property);
        }
      }else{
        if(property.listField.multiple){
          if(newValue !== undefined){
            this.modifyMultipleProperties(newValue, property);
          }
        }else{
          this.changeInProperty(newValue, property);
        }
      }
    }
    if(newValue !== lastValue || this.configureInit){
      this.visibilityService.configureProperties(this.propertiesWithVisibility, this.evaluatedMap);
    }
  }

  modifyMultipleProperties(newValue:any, property:any){
    this.deleteOrModifyProperties(property.propertyId);
    if(typeof newValue === 'string' && newValue.indexOf('|') !== -1){
      newValue.split('|').forEach((newValuesArray:any) => {
        this.addOrModifyProperty(newValuesArray, property);
      });
    }else if(newValue !== '' && newValue !== null){
      this.addOrModifyProperty(newValue, property)
    }
  }

  clientSelected(newValue: any){
    if(this.newEvaluation.legalFormInfo){
        this.newEvaluation.legalForm.noEditable = false;
        this.newEvaluation.createEvaluation.legalFormId = newValue.legalFormId;
        if(newValue.legalFormId){
            this.newEvaluation.legalForm.noEditable = true;
            this.newEvaluation.legalForm.error = false;
        }
    }
    this.newEvaluation.createEvaluation.clientName = newValue.name;
    if(this.newEvaluation.repeatedSelectedClient){
        this.newEvaluation.repeatedSelectedClient = false;
    }
    if(newValue.clientId){
        this.newEvaluation.clientSelector.noClientSelected = false;
    }
    this.itemSelected(newValue.clientId, 'clientId');
  }

  itemSelected(newValue: any, from: string){
    this.newEvaluation.createEvaluation[from] = newValue;
  }

  openSubclients(e:Event, client:any){
    e.stopPropagation();
    client.showSubclients = !client.showSubclients;
  }

  getTemplateUrl(){
    return './app/components/common-tree-list/common-tree-list.template.html';
  }

  marginTopSize(){
    if((this.newEvaluation.wizardState !== this.newEvaluation.stepsLength) && this.newEvaluation.wizardState - 3 >= 0){
      return this.newEvaluation.groupPropertiesList.lengt && this.newEvaluation.groupPropertiesList[this.newEvaluation.wizardState - 3].length < 6? 275 - (40 * this.newEvaluation.groupPropertiesList[this.newEvaluation.wizardState - 3].length) +'px' : '35px';
    }
    return '';
  }

  receiveContacts(contacts:any, role:any){
      role.selectContacts = contacts;
  }

  goBack(){
      if( this.newEvaluation.wizardState > 0){
          this.newEvaluation.wizardState -= 1;
          this.newEvaluation.activeText = this.translate.instant('common.next');

      }
  }

  propertySelectedOn(e:any, property:any){
    this.propertySelected(e.newValue, property);
  }

  selectBooleanOn(e:any, property:any){
    this.selectBoolean(e.newValue, property);
  }
}
