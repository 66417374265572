import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'tooltip-law',
  templateUrl: './tooltip-law.component.html'
})
export class TooltipLawComponent implements OnChanges {
  custom: any = this.customLanding.getCustom();
  isLanding: boolean = this.customLanding.isLanding();
  skin:string = this.globalCfg.skin;
  laws:any[];
  nameReference:string;
  openLaw:boolean = false;
  tooltipLaw: any = {
    referencesList: []
  }
  constructor(private route: ActivatedRoute,
    private globalCfg:GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService,
    private modalService:ModalServiceService,
    private apiService:ApiServiceService,
    private registerService:RegisterMixtapeService,
    private customLanding: CustomLandingFactory
  ) { }

  @Input() field:any;
  @Input() concept:any;
  @Input() references:any = [];
  @Input() type:string;
  @Input() item:any;
  @Input() visibility:boolean;
  @Input() isSequentialPoll:string;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['references'] && changes['references'].currentValue && changes['references'].currentValue.length){
        this.typeList();
    }
  }

  getConcept(){
    let evaluationId = this.field && this.field.evaluationId?this.field.evaluationId: this.route.snapshot.params['evaluationId'];
    this.apiService.get("fields/getconceptbyfieldid/" + evaluationId + "/", this.field.fieldId)
      .then((data:any) => {
          this.registerEvent(data);
      });
  }

  registerEvent(concept:any){
    let loginInfo = JSON.parse(sessionStorage['loginInfo']);
    let registerItem ={
        'Distinct ID': loginInfo.externalId,
        'Client ID': loginInfo.companyExternalId,
        'event':'open legal docs',
        'Concept Name': sessionStorage['breadcrumbConcept'] + concept.title,
        'Centinela product': loginInfo.defaultProduct
    }
    this.registerService.register(registerItem);
    this.modalService.lawInfoModal(registerItem, this.references, this.laws).subscribe({error: () => {
      this.openLaw = !this.openLaw;
    }});
  }

  typeList(){
    this.tooltipLaw.referencesList = cloneDeep(this.references);
    this.references.forEach((reference:any) => {
      this.nameReference = "fieldReference" + reference.type;
    })
    if(this.item){
      this.tooltipLaw.referencesList = [];
      this.tooltipLaw.referencesList.push(this.item);
    }
  }

  openModalLaw(e:any, reference:string){
    e.stopPropagation();
    e.preventDefault();
    this.openLaw = !this.openLaw;
    this.laws = this.getReferencesLaws(reference);
    if(this.concept){
        this.registerEvent(this.concept.title);
    }else if(this.field && this.field.conceptTitle){
        this.registerEvent(this.field.conceptTitle);
    }else{
        this.getConcept();
    }
  }

  showTooltipInfo(typeLaw:any){
    this.laws = this.getReferencesLaws(typeLaw);
    return this.drawTooltip()
  }

  getReferencesLaws(type:any){
    let laws: any = [];
    this.references.forEach((reference:any) => {
        if(reference.type === type){
           laws.push(reference);
        }
    })
    return laws;
  }

  drawTooltip(){
    let template = '';
    let typeClass= 'tooltip-law__tooltip-inner-text';
    if(this.isLanding){
        typeClass= 'tooltip-law__tooltip-inner-text-custom';
    }
    this.laws.forEach((law:any) => {
        template += '<p class="' + typeClass+'">' + law.text + '</p>';
    })
    return template;
  }
}
