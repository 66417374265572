<div class="evaluations-list">
  <div class="common__header-new-version">
      <i class="lf-icon-label-analysis common__header-icon-new-version"></i>
      <span class="common__header-title-new-version"
          [afTranslate]="'common.reports'">_Informes</span>
      <button class="lf-icon-add-round common__header-button-add-new-version"
          [ngShow]="userPermissions !== 'none' && !noHasTemplatesReports && skin !== 'icam-red'"
          (click)="createNewReport()"
          matTooltip="{{'new-report.new-report' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left">
      </button>
      <button class="lf-icon-configuration common__header-button-add-new-version common__header-button-add-new-version--color reports-list__header-button-config"
          (click)="goToCustomReports()"
          [ngShow]="reportsList.adminPermissions && (productSelected?.productId === 'personas' || productSelected?.productId === 'gestion')"
          matTooltip="{{'custom-reports.reports-configuration' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left">
      </button>

  </div>
  <p class="reports-list__no-reports reports-list__no-reports--no-has-templates"
      [ngShow]="noHasTemplatesReports && skin !== 'dark-blue'"
      [afTranslate]="'reports-list.no-generated-reports'">_No se han generado informes.</p>
  <div class="common__table-block"
      [ngShow]="!noHasTemplatesReports">
      <div class="common__table-new-version">
          <div class="common__table-header">
              <div class="reports-list__col-new-version reports-list__col-new-version--title reports-list__col-new-version--first">
                  <p class="common__table-header-text common__cursor-pointer"
                      [afTranslate]="'common.title'"
                      (click)="orderDate('title')">Título</p>
                  <i class="common__order-by lf-icon-arrow-down"
                      [ngClass]="{'lf-icon-arrow-up':reportsList.dateOrder === 'title' && !reportsList.orderOperationDates,
                          'common__order-by--active': reportsList.dateOrder === 'title'}"
                      (click)="orderDate('title')"></i>
              </div>
              <div class="reports-list__col-new-version reports-list__col-new-version--type">
                  <filter-by-list [type]="'list'"
                      [item]="reportsList.nameFilter"
                      [list]="reportsList.nameList"
                      (filterElementEmit)="filterElementOn( $event, 'name')">
                  </filter-by-list>
              </div>
              <div class="reports-list__col-new-version reports-list__col-new-version--evaluation"
                  [ngShow]="skin !== 'icam-red'">
                  <filter-by-list [type]="'listFind'"
                      [item]="reportsList.evaluationNameFilter"
                      [list]="reportsList.evaluationNameList"
                      (filterElementEmit)="filterElementOn($event, 'evaluationName')">
                  </filter-by-list>
              </div>
              <div class="reports-list__col-new-version reports-list__col-new-version--modification-date">
                  <div class="common__cell-block common__cell-block--padding-right">
                      <p class="common__table-header-text common__table-header-text--order common__cursor-pointer"
                          (click)="orderDate('modificationDate')">
                          <span-tooltip [text]="'common.modification' | afTranslate"></span-tooltip>
                      </p>
                      <i class="common__order-by"
                          [ngClass]="{'lf-icon-arrow-down':(reportsList.dateOrder === 'modificationDate' && reportsList.orderOperationDates) || reportsList.dateOrder !== 'modificationDate',
                              'lf-icon-arrow-up':reportsList.dateOrder === 'modificationDate' && !reportsList.orderOperationDates,
                              'common__order-by--active': reportsList.dateOrder === 'modificationDate'}"
                          (click)="orderDate('modificationDate')">
                      </i>
                  </div>
              </div>
              <div class="reports-list__col-new-version reports-list__col-new-version--actions-new-version">
                  <p class="common__table-header-text common__table-header-text--right "
                      [afTranslate]="'common.actions'">_Acciones</p>
              </div>
          </div>
          <div class="common__table-body common__table-body--11-elements"
              [ngClass]="{'common__table-body--more-elements': reportsList.amountElements  > 11}">
              <p class="reports-list__no-reports"
                  [ngShow]="reportsList.noGeneratedReports"
                  [afTranslate]="'reports-list.no-generated-reports'">_No se han generado informes.</p>
              <div class="common__table-row"
                  *ngFor="let report of reportsList.filterReportsList | byWord:reportsList.searchReport:['title', 'name', 'evaluationName'] | afOrderBy:reportsList.dateOrder:reportsList.orderOperationDates | afLimitTo:reportsList.configPagination.pageSize:reportsList.configPagination.pageSize*(reportsList.currentPage - 1); let i = index">
                  <div class="reports-list__col-new-version reports-list__col-new-version--title reports-list__col-new-version--first reports-list__col-new-version--tooltip common__cursor-pointer"
                      (click)="editReport(report)">
                      <parragraph-tooltip [text]="report.title"></parragraph-tooltip>
                  </div>
                  <div class="reports-list__col-new-version reports-list__col-new-version--type reports-list__col-new-version--tooltip">
                      <parragraph-tooltip [text]="report.name"></parragraph-tooltip>
                  </div>
                  <div class="reports-list__col-new-version reports-list__col-new-version--evaluation reports-list__col-new-version--tooltip"
                      [ngShow]="skin !== 'icam-red'">
                      <parragraph-tooltip [text]="report.evaluationName"></parragraph-tooltip>
                  </div>
                  <div class="reports-list__col-new-version reports-list__col-new-version--modification-date reports-list__col-new-version--tooltip">
                      <parragraph-tooltip [text]="report.modificationDate | convertDate:'without-time'"></parragraph-tooltip>
                  </div>
                  <div class="reports-list__col-new-version reports-list__col-new-version--actions-new-version">
                      <ul class="common__options-row">
                          <li class="common__menu-options-row"
                              (click)="editReport(report)"
                              matTooltip="{{(userPermissions !== 'none' && report.canManage?'common.edit':'common.view') | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i [ngClass]="userPermissions !== 'none' && report.canManage?'lf-icon-editable-form':'lf-icon-visible'"></i></li>
                          <li class="common__menu-options-row"
                              matTooltip="{{'common.remove' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-trash"
                                  [ngShow]="report.canManage && (skin != 'icam-red' || report.customReportId)"
                                  (click)="deleteReport(report)"></i>
                          </li>
                      </ul>
                      <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__block-footer-table"
      [ngShow]="!noHasTemplatesReports">
      <div class="common__block-search">
          <input type="text"
              [ngShow]="reportsList.search"
              class="common__search-input"
              [(ngModel)]="reportsList.searchReport"
              placeholder="{{'common.search' | afTranslate}}">
          <span class="common__text-search"
              [ngShow]="!reportsList.search"
              (click)="toggleSearch()"
              [afTranslate]="'common.search'">_Buscar</span>
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !reportsList.search,
                  'lf-icon-close-search': reportsList.search,
                  'common__icon-button-search--open': reportsList.search}"
              (click)="toggleSearch()">
          </button>
      </div>
      <pagination-control class="common__pagination-block"
        [ngShow]="reportsList.amountElements > reportsList.configPagination.pageSize"
        [config]="reportsList.configPagination"
        [collectionSize]="reportsList.amountElements"
        [(currentPage)]="reportsList.currentPage"
        (configuredTotalChange)="reportsList.configuredTotal = $event.configuredTotal"></pagination-control>
      <div class="common__block-footer-total">
          <span [ngShow]="reportsList.amountElements > reportsList.configPagination.pageSize">
            <span class="notranslate">{{reportsList.configuredTotal.first}}-{{reportsList.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{reportsList.amountElements}}</span>
          </span>
          <span [ngShow]="!(reportsList.amountElements > reportsList.configPagination.pageSize)">
            <span [afTranslate]="'common.total'">_Total</span>
            <span class="common__total-number-element notranslate">{{reportsList.amountElements}}</span>
          </span>
      </div>
  </div>
</div>
