import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()

export class AssistResolver implements Resolve<any> {
    resolve(route: ActivatedRouteSnapshot) {
      return route.params['assist'] === 'true';
    }

}
