import { Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { ConceptInstancesListParentService } from '../utils/concept-instances-list-parent.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'concept-instances-list-uk',
  templateUrl: './concept-instances-list-uk.component.html'
})
export class ConceptInstancesListUkComponent implements OnInit, OnChanges {
  userPermissions:any = this.permissions.getPermissions();
  adviceModal: any;
  conceptInstancesList: any = {
    search: false,
    order: false,
    filterOrder: 'clientName',
    searchInstances:  '',
    view:  'last15',
    instancesListFilter: [],
    instancesListOriginFilter: [],
    instancesList: [],
    filters:  ['clientName', 'conceptTitle'],
    words: ['title', 'evaluationName', 'author', 'state'],
    clientNameFilter: {
      name: this.translate.instant('common.client'),
      orderCol: true,
      filterOrder: 'clientName'
    },
    conceptTitleFilter: {
      name: this.translate.instant('dashboard.Process')
    }
  }

  constructor(private translate: AfTranslateFactory,
    private permissions: UserPermisionsFactory,
    private conceptInstancesListParent: ConceptInstancesListParentService) {

    }

  @Input() filterInstancesList:any = null;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  ngOnInit(): void {
    this.configureListUK();
  }

  ngOnChanges(): void {
    if(this.filterInstancesList !== null){
      this.conceptInstancesList.instancesList = this.filterInstancesList;
      this.configureListUK()
    }
  }

  configureListUK(){
    this.conceptInstancesList.instancesList.sort(this.orderListDate);
    this.conceptInstancesList.instancesListFilter = this.conceptInstancesList.instancesList;
    this.conceptInstancesList.instancesListOriginFilter = this.conceptInstancesList.instancesListFilter;
    this.conceptInstancesListParent.getFilters(this);
  }

  orderListDate(a:any, b:any) {
    if(a.creationDate < b.creationDate){
      return 1
    }
    return a.creationDate > b.creationDate ? -1 : 0;
  }

  filterElementOn(e:any, type:string){
    this.conceptInstancesListParent.selectTypeOperation(e.source, type, this);
  }

  toggleSearch(){
    this.conceptInstancesListParent.toggleSearch(this);
  }

  searchBy(type: any){
    this.conceptInstancesListParent.searchBy(type, this);
  }

  launchActionInstance(item: any, option: any){
    this.conceptInstancesListParent.launchActionInstance(item, option, this);
  }

  openModalConfidential(instance: any){
    this.conceptInstancesListParent.openModalConfidential(instance, this);
  }

  closeDropdown(type: any){
    this.conceptInstancesListParent.closeDropdown(type, this.dropdowns);
  }

}
