<div class="modal-more-info">
    <div class="modal-more-info__body">
        <i class="lf-icon-close modal-more-info__icon-close-modal"
            (click)="handleDismiss()"></i>
        <h3 class="modal-more-info__title">{{modalMoreInfo.title}}</h3>
        <ul class="modal-more-info__list">
            <li *ngFor="let option of modalMoreInfo.optionsList"
                class="modal-more-info__element-list">
                <i class="lf-icon-check-round modal-more-info__element-icon"></i>
                <span class="modal-more-info__element-text">{{option}}</span>
            </li>
        </ul>
    </div>
</div>
