import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChildren, QueryList } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ReportRecordDetailService } from '../utils/report-record-detail.service';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Component({
  selector: 'report-record-detail',
  templateUrl: './report-record-detail.component.html'
})

export class ReportRecordDetailComponent implements OnInit, OnChanges {
  reportRecordDetail: any = {
    openFocusDetail: false,
    orderOperationDates: true,
    searchDetail: '',
    searchMethod: '',
    searchName: '',
    dateOrder: '',
    filters: [],
    filterDocumentationList: [],
    findFilters: [],
    dateFilters: [],
    context: this.context
  }
  constructor(private reportRecordDetailService: ReportRecordDetailService,
    private context: ContextFactory) {}


  @Input() rows:any = null;
  @Input() columns:any = null;
  @Input() reportfields:any = null;
  @Input() report:any = null;
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnInit(): void {
    this.reportRecordDetailService.getProductPermissions(this.report.productId, this);
    this.reportRecordDetailService.configureColumns(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['rows']?.firstChange && changes['rows']?.currentValue){
      this.reportRecordDetailService.configureColumns(this);
    }
  }

  orderBySearch(e:any, type:any){
    this.reportRecordDetail['search'+type] = e.newValue;
    this.reportRecordDetailService.filterReport(this);
  }

  orderDate(type:any) {
    this.reportRecordDetailService.orderDate(type, this)
  }

  selectTypeOperation(e:any, column:any){
    this.reportRecordDetailService.selectTypeOperation(e.source, column, this)
  }

  relatedConcept(report:any){
    this.reportRecordDetailService.relatedConcept(report, this)
  }

  changeCalendar(e:any, column:any){
    column[column.id+'DateFilter'] = Boolean(e.dates.type === 'apply')
    if(e.dates.type !== 'cancel'){
      this.reportRecordDetail[column.id + 'FromDate'] = e.dates.type === 'apply'?e.dates.from : null;
      this.reportRecordDetail[column.id + 'ToDate'] = e.dates.type === 'apply'? e.dates.to : null;
      this.reportRecordDetailService.filterReport(this);
    }
    this.closeDropdown(column.id);

  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }
}



