<div class="users-admin-roles-list">
  <div class="common__table-new-version">
      <div class="common__table-header-flex">
          <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--role users-admin-roles-list__col-new-version--header-top">
              <filter-by-list [type]="'listFind'"
                  [widthClass]="'200px'"
                  [item]="usersAdminRolesList.nameFilter"
                  [list]="usersAdminRolesList.nameList"
                  [filterOrder]="usersAdminRolesList.filterOrder"
                  [order]="usersAdminRolesList.order"
                  (orderCol)="searchBy($event.type)"
                  (filterElementEmit)="selectTypeOperation($event.source, 'name')">
              </filter-by-list>
          </div>
          <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--product users-admin-roles-list__col-new-version--header-top">
              <filter-by-list [type]="'listFind'"
                  [item]="usersAdminRolesList.productFilter"
                  [widthClass]="'200px'"
                  [list]="usersAdminRolesList.productNameList"
                  (filterElementEmit)="selectTypeOperation($event.source, 'productName')">
              </filter-by-list>
          </div>
          <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--organization users-admin-roles-list__col-new-version--header-top">
              <filter-by-search
                  [item]="usersAdminRolesList.organizationsFilter"
                  [filterText]="usersAdminRolesList.organizationsWordFilter"
                  (launchActionSearch)="orderBySearch($event.newValue, 'organizations')">
              </filter-by-search>
          </div>
      </div>
      <div class="users-admin-roles-list__table-body"
          [ngClass]="{'common__table-body--more-elements': usersAdminRolesList.filterRolesList.length > 10}">
          <div *ngFor="let role of usersAdminRolesList.filterRolesList |afOrderBy:usersAdminRolesList.filterOrder:usersAdminRolesList.order"
              class="common__table-row-flex">
              <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--role">
                  <parragraph-tooltip [text]="role.name"></parragraph-tooltip>
              </div>
              <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--product">
                  <parragraph-tooltip [text]="role.productName"></parragraph-tooltip>
              </div>
              <div class="users-admin-roles-list__col-new-version users-admin-roles-list__col-new-version--organization">
                  <participants-list *ngIf="role.organizations && role.organizations.length"
                                      [participants]="role.organizations"
                                      [type]="'organizations'">
                  </participants-list>
              </div>
          </div>
      </div>
  </div>
</div>
