<div class="role-error-modal">
  <div class="role-error-modal__modal-body">
      <div class="role-error-modal__modal-icon-block">
          <i class="lf-icon-warning advice-modal__modal-body-icon "></i>
      </div>
      <div class="role-error-modal__modal-text-block">
          <p class="role-error-modal__modal-body-text">{{roleErrorModal.infoError}}</p>
      </div>
      <div class="role-error-modal__buttons-block">
          <div [ngShow]="configuration.type === 'notAssignedRoles'">
              <button class="common__button common__button--grey common__button--grey-red"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.exit'">Salir</button>
              <button class="common__button common__button--white-red common__button--separating-margin"
                      (click)="handleAccept()"
                      [afTranslate]="'role-error-modal.manage-roles'">Gestionar roles</button>
          </div>
          <button [ngShow]="configuration.type !== 'notAssignedRoles'"
                  class="common__button common__button--white-red"
                  (click)="handleAccept()"
                  [afTranslate]="'common.ok'">_Aceptar</button>
      </div>
  </div>
  <div class="role-error-modal__container-block">
      <div>
          <p class="role-error-modal__setting-title-box"
              [afTranslate]="'role-error-modal.review-guidelines'">_Pautas para la revisión</p>
          <p class="role-error-modal__setting-info"
              [afTranslate]="'role-error-modal.review-guidelines-info'">_Completa la asignación de los siguientes roles asociando otros usuarios a las organizaciones afectadas.</p>
      </div>
      <div class="role-error-modal__role-user-block"
          *ngFor="let role of roleErrorModal.roles | afOrderBy:['name']">
          <div class="role-error-modal__block-name-user">
              <i class="lf-icon-add-points role-error-modal__icon-lead"></i>
              <span class="role-error-modal__text-name-user">{{role.name}}</span>
              <span class="role-error-modal__text-name-product">({{role.productName}})</span>
          </div>
          <div class="role-error-modal__client-role-block">
              <div *ngFor="let client of role.clients | afOrderBy:['name']"
                  class="role-error-modal__client-role-repeat">
                  <i class="lf-icon-box-inactive role-error-modal__icon-client-list"></i>
                  <span class="role-error-modal__client-name-list">{{client.name}}</span>
              </div>
          </div>
      </div>
  </div>
</div>
