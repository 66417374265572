<label class="common__input-block field-type-list__input-block"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--poll-view':field.poll && !fieldTypeList.field.noEditable && field.sequentialView,
      'common__input-block--custom-field':isLanding && !field.sequentialView,
      'common__show-alert': field.error && !field.sequentialView,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-search':!fieldTypeList.showList && field.hiddenPill}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      *ngIf="field.sequentialView && !fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': field.error,
          'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView">
          </tooltip-law>
      </p>
  </div>
  <div [class]="'common__label-block common__'+fieldTypeList.classWidthField"
      *ngIf="!(field.sequentialView && !fieldTypeList.field.noEditable)">
      <span class="common__input-text common__input-text"
          [ngClass]="{'common__input-text--required': field.error,
                  'common__input-text--no-editable': fieldTypeList.field.noEditable}">{{fieldTypeList.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="field.required">*</span>
      <div class="common__info-icon-block common__info-icon-block"
          [ngHide]="fieldTypeList.field.noEditable || !field.description">
          <tooltip-info [description]="fieldTypeList.field.description"
            [field]="fieldTypeList.field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
          [ngShow]="field.references && field.references.length && !fieldTypeList.field.noEditable">
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="field.relatedInfo?.length && !field.poll && !fieldTypeList.field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
      <div class="common__info-icon-block common__info-icon-block--in-report"
        *ngIf="field.inReport !== null && field.inReport !== undefined && !isLanding">
        <tooltip-in-report [inReport]="fieldTypeList.inReport"></tooltip-in-report>
      </div>
  </div>
  <div [ngClass]="{'common__input-poll':field.poll && !fieldTypeList.field.noEditable && field.sequentialView,
          'common__show-alert': field.error && field.sequentialView}">
      <div class="float-end">
          <i class="lf-icon-connected-data field-type-text__icon-references field-type-text__icon-references--less-top"
              [ngShow]="field.evaluatedValue?.hasReferences && !field.poll"
              (click)="showReference($event)"
              matTooltip="{{'common.see-dependencies' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left">
          </i>
      </div>
      <div class="field-type-list__block-template">
        <div class="common__dropdown-initial">
            <i class="lf-icon-link field-type-list__icon-link field-type-list__icon-link"
                *ngIf="field.fieldTypeId ==='l-fo'">
            </i>
            <i class="common__select-arrow"
                [id]="'dropdown-field-list-'+fieldTypeList.id"
                [ngClass]="{'lf-icon-angle-up':fieldTypeList.showList,
                    'lf-icon-angle-down':!fieldTypeList.showList,
                    'common__select-arrow--no-editable': fieldTypeList.field.noEditable,
                    'common__select-arrow--l-co':field.fieldTypeId ==='l-fo'}"
                data-bs-toggle="dropdown"
                (click)="fieldTypeList.showList = !fieldTypeList.showList"></i>
            <tooltip-law
              [ngShow]="fieldTypeList.references?.length"
              [references]="fieldTypeList.references"
              [field]="field"
              class="field-type-text__tooltip-block field-type-text__tooltip-block--list-lco-simple"
              *ngIf="fieldTypeList.references[0]?.referenceId"></tooltip-law>
            <div class="field-type-list__text-selected-simple"
                [ngClass]="{'field-type-list__text-selected-simple--numelements': (fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements < 10) || fieldTypeList.selectedItemToList.numElements === 0,
                    'field-type-list__text-selected-simple--numelements-middle': fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements >= 10 && fieldTypeList.selectedItemToList.numElements < 100,
                    'field-type-list__text-selected-simple--numelements-big': fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements >= 100,
                    'common__input-content-alert': field.error,
                    'common__input-content-no-editable': fieldTypeList.field.noEditable}">
                <p class="common__ellipsis common__paragraph-0">
                    <span-tooltip [text]="fieldTypeList.selectItemName"></span-tooltip>
                </p>
            </div>
            <p class="field-type-list__block-number field-type-list__block-number--selected "
                [ngShow]="fieldTypeList.selectedItemToList.numElements || fieldTypeList.selectedItemToList.numElements === 0">({{fieldTypeList.selectedItemToList.numElements}})</p>
            <i class="common__delete-content-icon"
                [ngClass]="{'lf-icon-close-round': isLanding,
                  'lf-icon-close': !isLanding}"
                [ngShow]="fieldTypeList.selectItemName !=='' && !fieldTypeList.field.noEditable"
                (click)="resetItem()"></i>
            <ul class="dropdown-menu common__select-list field-type-list__list-block"
                [ngClass]="{'common__select-list--no-shadow':field.noShadow,
                    'field-type-list__list-block--tree-list':field.isTree,
                    'field-type-list__list-block--xs': field.xs,
                    'field-type-list__list-block--custom': isLanding}">
                <li class="common__find-dropdown-block"
                    [ngShow]="fieldToFind==='text'">
                    <div class="field-item__find-position"
                        [ngHide]="fieldTypeList.addNew">
                        <div class="field-type-other__block-input-search"
                            [ngClass]="{'field-type-other__block-input-search--block-100': !(field.isOpen && !fieldTypeList.public) || isLanding}">
                            <input class="common__find-in-dropdown"
                                [ngClass]="{'common__find-in-dropdown--custom-list':isLanding}"
                                [(ngModel)]="fieldTypeList.findDropdown"
                                [placeholder]="'clients-picker.search' | afTranslate"
                                (click)="catchEvent($event)"
                                (keyup)="changeList()">
                            <i class="common__find-dropdown-icon-find"
                                [ngClass]="{'lf-icon-search':fieldTypeList.findDropdown === '',
                                    'lf-icon-close-search': fieldTypeList.findDropdown !==''}"
                                (click)="resetSearch($event)"></i>
                        </div>
                        <button class="lf-icon-sum common__button-checked"
                            [ngShow]="field.isOpen && !fieldTypeList.public && !isLanding"
                            (click)="createNewValue($event)"></button>
                    </div>
                    <div class="field-type-list__add-new-block"
                        [ngShow]="fieldTypeList.addNew">
                        <div class="field-type-other__block-input-search field-type-other__block-input-search--multiple field-type-other__block-input-search--add-color">
                            <input class="common__find-in-dropdown common__find-in-dropdown--create-list"
                                [(ngModel)]="fieldTypeList.newValue"
                                [placeholder]="'field-type-list.new-element'| afTranslate"
                                (click)="catchEvent($event)">
                        </div>
                        <div class="field-type-list__block-buttons-addnew">
                          <button class="lf-icon-close common__button-close"
                              (click)="resetListValue($event)"></button>
                          <button class="lf-icon-check common__button-checked"
                              (click)="insertNewValue($event)"></button>
                        </div>
                    </div>
                </li>
                <li class="field-type-list__select-list"
                    [ngClass]="{'field-type-list__select-list--tree':field.isTree}">
                    <div *ngFor="let listValue of fieldTypeList.filterList | byWord:fieldTypeList.findDropdown:[fieldToFind] | afOrderBy:fieldTypeList.orderList:fieldTypeList.orderAsc; let first = first;let index = index"
                        class="field-type-list__block-element-list"
                        [ngHide]="checkListValue(listValue.type)">
                      <div class="common__select-item common__select-item--list"
                          [ngClass]="{'common__select-item--tree':field.isTree,
                          'common__select-item--tree-with-children':field.isTree && listValue.children?.length,
                          'common__select-item--disabled': listValue.disabled,
                          'common__select-item--custom-first':isLanding && first}"
                          [ngHide]="listValue.deleteError"
                          [attr.disabled]="listValue.disabled?true:null"
                          (click)="selectItem(listValue, $event)">
                          <i [ngShow]="field.isTree && listValue.children && listValue.children.length"
                              class="float-start"
                              [ngClass]="{'lf-icon-angle-down':listValue.show ,
                                'lf-icon-angle-right':!listValue.show}"
                              (click)="showChildren($event, listValue)"></i>
                          <i [class]="(listValue.extension | extensionDocument) + ' common__select-info-icon'"
                              *ngIf="listValue.extension"></i>
                          <i [class]="(listValue.type | taskTypeIcons:'reference') + ' field-type-list__law-icon'"
                              *ngIf="field.fieldTypeId ==='l-fo'"></i>
                          <div class="common__cell-block field-type-list__simple-text-list"
                              [ngClass]="{'field-type-list__simple-text-list--numelements': (listValue.numElements && listValue.numElements < 10) || listValue.numElements === 0,
                                  'field-type-list__simple-text-list--numelements-middle': listValue.numElements && listValue.numElements >= 10 && listValue.numElements < 100,
                                  'field-type-list__simple-text-list--numelements-big': listValue.numElements && listValue.numElements >= 100,
                                  'field-type-list__multiple-text-list--user-value': listValue.isUserValue}">
                              <p class="common__ellipsis common__paragraph-0"
                                  [ngClass]="{'field-type-list__item-text':field.fieldTypeId ==='l-fo',
                                      'common__line-through':listValue.isDeleted}">
                                  <span-tooltip [text]="listValue.text"
                                    [isDeleted]="listValue.isDeleted"></span-tooltip>
                              </p>
                          </div>
                          <p class="field-type-list__block-number field-type-list__block-number--list"
                              [ngShow]="listValue.numElements || listValue.numElements === 0">({{listValue.numElements}})</p>
                          <i class="lf-icon-configuration field-type-list__is-user-value"
                              [ngShow]="listValue.isUserValue"
                              [ngClass]="{'field-type-list__is-user-value--custom':isLanding}"></i>
                          <i class="common__select-icon common__select-icon--list"
                              [ngClass]="isLanding?'lf-icon-check-round':'lf-icon-check-round-full'"
                              [ngHide]="listValue.disabled ||(isLanding && listValue.isUserValue)"></i>
                          <i class="lf-icon-editable-form common__select-icon"
                              [ngShow]="listValue.isUserValue"
                              (click)="editListValueElement($event, listValue)"></i>
                          <i class="lf-icon-trash common__select-icon"
                              [ngShow]="listValue.isUserValue"
                              (click)="deleteListValue($event, listValue)"></i>

                      <div class="common__select-item common__select-item--error"
                          [ngShow]="listValue.deleteError">
                          <i class="lf-icon-lock common__select-info-icon"></i>
                              <span [afTranslate]="'field-type-list.message-info'">No es posible eliminar este valor porque ya se ha utilizado en otros formularios.</span>
                          <button class="common__error-button-list "
                              (click)="aceptNotDelete($event, listValue)"
                              data-[afTranslate]="'common.ok'">Aceptar</button>
                      </div>
                      </div>
                      <div class="field-type-list__tree-list"
                          *ngIf="listValue.children && listValue.children.length && listValue.show">
                          <tree-list-simple [field]="field"
                            [findDropdown]="fieldTypeList.findDropdown"
                            [fieldToFind]="fieldToFind"
                            [filterList]="listValue.children"
                            (treeSimpleEmit)="treeSimpleOn($event)">
                          </tree-list-simple>
                      </div>
                  </div>
                </li>
            </ul>
        </div>
      </div>
  </div>
  <div class="field-item__blocker-box"
      *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
</label>
<div class="field-type-list__block-tree-selected"
  [ngShow]="field.uniqueLastSelect && field.multiline && fieldTypeList.listTreeLastSelect?.length">
  <div class="field-type-list__text-tree-selected"
      [ngStyle]="{'padding-left': (16*index)+'px'}"
      [ngClass]="{'field-type-list__text-tree-selected--selected': last}"
      *ngFor="let itemSelected of fieldTypeList.listTreeLastSelect; let index = index; let last = last">
      <span class="field-type-list__tree-select-dot" [ngShow]="last"></span>
      <span-tooltip [text]="itemSelected.text"></span-tooltip>
  </div>
</div>
