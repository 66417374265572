import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'filter-by-search',
  templateUrl: './filter-by-search.component.html'
})
export class FilterBySearchComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  isLanding: any = this.route.snapshot.data['isLanding'];
  filterBySearch: any = {
    name: '',
    order: false,
    inputFilter: '',
    openSearch: false,
    title: ''
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private route: ActivatedRoute) { }
  @Input() item: any;
  @Input() filterOrder: string = '';
  @Input() filterText: string = '';
  @Input() top: string = '';
  @Input() widthClass : string = '';
  @Input() order: boolean = false;
  @Input() adjustClass: string = ''
  @Input() textColumn: any;

  @Output() launchActionSearch = new EventEmitter;

  ngOnChanges(): void {
    if(this.filterText && this.filterText !== '' || (this.filterText ==='' && this.skin ==='icam-red')){
      this.filterBySearch.inputFilter = this.filterText;
    }
    if(this.item){
      this.filterBySearch.title = this.textColumn?this.item[this.textColumn]:this.item.name;
    }
  }

  keyChangeInFind(e:any){
    e.preventDefault();
    this.launchActionSearch.emit({newValue: this.filterBySearch.inputFilter, action: 'filter'});
  }

  resetFilter(e:any){
    e.stopPropagation();
    e.preventDefault();
    this.filterBySearch.inputFilter = '';
    this.filterBySearch.openSearch = false;
    this.launchActionSearch.emit({newValue: this.filterBySearch.inputFilter, action: 'filter'});
  }

  orderColumn(type:any){
    this.launchActionSearch.emit({newValue: type, action: 'order'});
  }

  changeOpen(){
    setTimeout(() => {
      this.filterBySearch.openSearch = !this.filterBySearch.openSearch;
    })
  }
}




