import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable({
    providedIn: 'root'
  })

export class generalReportsWhResolve implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
        private stateManagement: StateManagementFactory) { }

    resolve() {
        let evaluationId = '';
        let state = this.stateManagement.getLastState();
        if (state.params?.evaluationId) {
            evaluationId = state.params.evaluationId;
        }
        return this.apiService.get('be/wh/dashboard/reportobjects', evaluationId)
            .then((data:any)=>{
                return data
            })
    }
}
