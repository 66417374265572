import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ActivatedRoute } from '@angular/router';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'expanded-textarea',
  templateUrl: './expanded-textarea.component.html'
})
export class ExpandedTextareaComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal,
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService,
    private route: ActivatedRoute,
    private modalService: ModalServiceService,
    private customLanding: CustomLandingFactory) { }

  @Input() text:string ='';
  @Input() field: any = {};

  isLanding: boolean = this.customLanding.isLanding();
  skin = this.isLanding?'landing-custom':this.globalCfg.skin;
  tinymceOptions:any;
  expandedTextarea:any = {
    text:'',
    hideTooltipInfo: false,
    titleField: '',
    tinyMceOptions: {
      noEditable: false,
      typeView: 'rich-text',
      height: 399
    }
  }

  ngOnInit(): void {
    this.expandedTextarea.tinyMceOptions.noEditable = this.field.noEditable;
    if (this.field.fieldId === 'custom-notification-field') {
      this.expandedTextarea.tinyMceOptions.toolbar = ' undo redo | bold italic underline | alignment | bullist numlist | removeformat | link';
    }
    this.commonUtilsService.configureCustomButtonsTiny(this.field, this.expandedTextarea.tinyMceOptions);
    this.expandedTextarea.text = this.text;
    this.expandedTextarea.hideTooltipInfo = this.field.noEditable || !this.field.description || (this.field.fieldTypeId === 'rich' && this.field.isEditable);
    this.expandedTextarea.titleField = this.field.extendedLabel && this.field.extendedLabel !== ''? this.field.extendedLabel : this.field.label;
  }

  resetInput() {
    this.expandedTextarea.text = '';
  }

  openModalRelatedInfo(relatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.modalService.relatedInformationModal(relatedInfo);
  }

  handleAccept(e:Event) {
    e.stopPropagation();
    this.activeModal.close({
      result:'ok',
      inputText: this.expandedTextarea.text
    })
  }
}
