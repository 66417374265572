import { Directive, EventEmitter, OnInit, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[resizeWindow]'
})
export class ResizeWindowDirective implements OnInit{

  @Output() hasResize = new EventEmitter();

  ngOnInit(): void {
    // window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeWindow();
  }

  resizeWindow(){
    // setTimeout(()=>{
        let widthWindow = window.innerWidth;
        let resize = false;
        if(widthWindow < 1367){
            resize = true;
        }
        this.hasResize.emit({resize: resize});
    // })
  }
}
