import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'expired'
})
export class ExpiredPipe implements PipeTransform {

  transform(item: any): boolean {
    let today = moment().set({hour:23,minute:59,second:58});
    return (today.diff(item, 'days') > 0);
  }

}
