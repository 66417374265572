import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { EvaluatedConceptFactory } from '@shared/factories/evaluated-concept/evaluated-concept.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptUtilsService } from '@shared/services/concept-utils/concept-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'concept-window',
  templateUrl: './concept-window.component.html'
})
export class ConceptWindowComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  from: any = this.route.snapshot.data['from'];
  clientId: any = this.route.snapshot.params['clientId']
  taskId: any = localStorage.getItem('taskId');
  conceptWindow: any = {
    taskTypeIdSelect: null,
    disabledLink: false,
    productId: 'blanqueo',
    to: {
      action: this.taskId?'task':'general',
      edit: this.taskId?true:false
    }
  };
  subscribers: any = {};
  constructor(private route: ActivatedRoute,
    private loader: LoaderFactory,
    private router: Router,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private evaluatedConcept: EvaluatedConceptFactory,
    private globalCfg: GlobalCfgFactory,
    private stateManagement: StateManagementFactory,
    private conceptUtilsService: ConceptUtilsService,
    private modalService: ModalServiceService,
    private conceptState: ConceptStateFactory,
    private commonUtilsService: CommonUtilsService,
    private documentActions: DocumentActionsService,
    private userPermisions: UserPermisionsFactory) { }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }

  ngOnInit(): void {
    if(this.taskId){
      this.conceptWindow.to.taskId = this.taskId;
      localStorage.removeItem('taskId');
    }
    this.watchSaveConcept();
    this.loader.openLoader('open-new-concept-window');
    this.configureConcept();
  }

  watchSaveConcept(){
      this.subscribers.saveConceptWindowBroadcast = this.broadcastService.subscribe('saveConceptWindow', ( data:any)=>{
        this.conceptWindow.fromExit = data;
        this.broadcastService.broadcast('saveConcept');
      });
      this.subscribers.savedConceptExitBroadcast = this.broadcastService.subscribe('savedConceptExit', ()=>{
          this.exitConcept();
      });
  }

  exitConcept(){
    let that:any = this.conceptWindow;
    this.from === 'menuHeaderUser'? this.broadcastService.broadcast('exitConceptMenuHeaderUser', {option:that.fromExit.option, item:that.fromExit.item}) : this.broadcastService.broadcast('exitConceptHeaderNav', {configuration: that.fromExit})
  }

  configureConcept(){
      let that: any = this.conceptWindow;
      if(this.from === 'client'){
          that.configurationView={
              icon: 'lf-icon-build',
              closeText: this.translate.instant('client-evaluation.close-client')//'Cerrar cliente'
          }
          that.url = 'byproductclientid/'+that.productId+'/'+this.clientId;
      }else if(this.from === 'subject'){
          that.configurationView={
              icon: 'lf-icon-health',
              title: this.translate.instant('common.obligated-subject'),
              closeText: this.translate.instant('concept-window.close-obligated-subject')//'Cerrar sujeto obligado'
          }
          that.url = 'byproductid/'+that.productId;
      }
      this.conceptWindow.finishAllCalls = 0;
      this.getConcept();
      this.getConceptObject();
      this.getConceptSubject();
      this.getFieldContainers();
  }

  getConcept(){
      this.apiService.get('concepts/'+this.conceptWindow.url)
          .then((data:any)=> {
              this.conceptWindow.public = data.allowPublicShare;
              this.conceptWindow.conceptInfo = data;
              this.getPermissions(data.conceptId);
          }, (errorData:any)=> {
              this.loader.closeError();
          });
  }

  getPermissions(conceptId: any){
    this.apiService.get('concepts/permissions/'+ conceptId)
    .then((data:any)=> {
        this.userPermisions.setUserPermisions(data)
        this.conceptWindow.finishAllCalls++;
        this.closeLoader();
    }, (errorData:any)=> {
        this.loader.closeError();
    });
  }

  getConceptObject(){
      this.apiService.get('conceptobjects/'+this.conceptWindow.url)
          .then((data:any)=> {
              this.conceptWindow.concept = data;
              this.conceptWindow.concept.subTab = this.conceptWindow.to.action;
              this.conceptWindow.concept.new = this.evaluatedConcept.isNew;
              if(this.from === 'client'){
                this.conceptWindow.configurationView.title = this.conceptWindow.concept.conceptTitle;
              }
              this.getConceptDocumentation();
              this.getConceptTasks();
              this.getConceptAlerts();
          }, (errorData:any) => {
              this.loader.closeError();
          });
  }

  getConceptSubject(){
      this.apiService.get('conceptdata/'+this.conceptWindow.url)
          .then((data:any)=> {
              this.conceptWindow.evaluatedFields = data;
              this.conceptWindow.finishAllCalls++;
              this.closeLoader();
          }, (errorData:any)=> {
              this.loader.closeError();
          });
  }

  getFieldContainers(){
      this.apiService.getWithTranslate('fieldcontainers/', this.conceptWindow.url)
          .then((data:any)=> {
            this.conceptWindow.fieldContainers = data;
              this.conceptWindow.finishAllCalls++;
              this.closeLoader();
          }, (errorData:any)=> {
              this.loader.closeError();
          });
  }

  getConceptDocumentation(){
      this.apiService.get('documentobjects/'+this.conceptWindow.concept.conceptObjectId)
          .then((data:any)=>{
              this.conceptWindow.concept.documentation = data;
              this.conceptWindow.finishAllCalls++;
              this.closeLoader();
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  getConceptTasks(){
      this.apiService.get('tasks/'+this.conceptWindow.concept.conceptObjectId, 'count')
          .then((data:any)=>{
              this.conceptWindow.concept.tasks = data;
              this.conceptWindow.finishAllCalls++;
              this.closeLoader();
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  getConceptAlerts(){
      let url : string = this.from === 'client'? 'blanqueo/datealertscountbyclient/'+this.clientId : 'conceptobjects/alerts/'+this.conceptWindow.concept.conceptObjectId+'/count';
      this.apiService.get(url)
          .then((data:any)=>{
              this.conceptWindow.concept.alerts = data;
              this.conceptWindow.finishAllCalls++;
              this.closeLoader();
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  closeLoader(){
      if(this.conceptWindow.finishAllCalls === 6){
          this.conceptWindow.concept.productTypeId = this.conceptWindow.conceptInfo.productTypeId;
          this.conceptWindow.concept.conceptTypeId = this.conceptWindow.conceptInfo.conceptTypeId;
          this.conceptWindow.concept.videos = this.conceptWindow.conceptInfo.videos;
          this.conceptWindow.concept.hasLinksToForms = this.conceptWindow.conceptInfo.hasLinksToForms;
          this.conceptWindow.concept.fieldContainers = this.commonUtilsService.searchFormu(this.conceptWindow.fieldContainers, this.conceptWindow.concept);
          this.conceptWindow.concept.evaluatedFields = this.conceptWindow.evaluatedFields;
          this.conceptWindow.concept.productId = this.conceptWindow.productId;
          this.conceptWindow.hasOpenEvaluationTask = this.conceptWindow.concept.hasOpenEvaluationTask;
          this.conceptWindow.finishLoader = true;
          let time = (this.conceptWindow.evaluatedFields.length/100)*1000;
          let that = this;
          setTimeout(function () {
            that.loader.closeLoader(['open-new-concept-window', 'front-login-login']);
          },time);
      }
  }

  modifyConceptTab(){
      this.getConceptDocumentation();
      this.getConceptTasks();
      this.getConceptAlerts();
  }

  changeView(){
      let state : any = this.stateManagement.getLastState();
      let configuration:any = {
          concept: this.conceptWindow.concept,
          textModal: this.translate.instant('concept.advertence-exit'),
          typeModal: 'info:exit:question',
          noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
          blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
          textValidateField: this.translate.instant('concept-form.error-validate-fields'),
          loader: state.url!=='' || this.from === 'client'?'save-exit-concept':'dashboard-panel-on-init'
      }
      this.conceptUtilsService.exitConcept(configuration)
          .then(()=>{
              this.closeWindow();
          }, ()=>{})
  }

  closeWindow(){
      if(this.from === 'client'){
          this.router.navigate(['clients-tracking']);
      }else{
          this.commonUtilsService.goToStateUrl();
      }
  }

  launchAction(e:any){
    let action: any = e.action;
      switch (action.type) {
          case 'generateTemplate':
              if(action.resolve === 'ok'){
                  this.documentActions.doAction('open', action.template);
                  this.modalService.adviceModal(this.translate.instant('concept-form.add-ok').replace('{document}',  action.template.name), 'info:accept:check-round');
              }else{
                  this.modalService.adviceModal(this.translate.instant('concept-form.error-export-template').replace('{document}',  action.template.name), 'error:accept:warning');
              }
              break;
          case 'selectTab':
              this.changeTab(action);
              break;
          default:
            this.conceptWindow.concept = action.item;
              break;
      }
  }


  changeTab(action:any) {
      let configuration:any = {
          concept: this.conceptWindow.concept,
          textModal: this.translate.instant('concept.advertence-change'),
          typeModal: 'info:saveContinue:question',
          noValidateFields: this.translate.instant('concept-form.no-validate-fields'),
          blockedFields: this.translate.instant('concept-form.has-blocked-fields'),
          textValidateField: this.translate.instant('concept-form.error-validate-fields'),
          loader: this.conceptUtilsService.configureLoader(action.item)
      }
      this.conceptUtilsService.exitConcept(configuration)
          .then((result:any)=>{
              if(result === 'without-save'){
                  this.conceptState.removeAll();
              }
              let configurationLocked : any = {
                  concept: this.conceptWindow.concept,
                  literalQuestion: this.translate.instant('concept-form.block-client-concept'),
                  loader: configuration.loader,
                  saveConcept: result === 'save-and-continue'
              }
              this.conceptUtilsService.checkModalLocked(configurationLocked)
                  .then((result:any)=>{
                      this.commonFunction(action.item);
                  }, ()=>{})
          }, ()=>{})
  }

  commonFunction(item:any){
      this.conceptWindow.concept.subTab = item;
      this.conceptWindow.taskTypeIdSelect = null;
  }

  openPdf(e: any) {
    e.stopPropagation();
    this.conceptWindow.disabledLink = true;
    this.apiService.get('documentobjects/pdfviewerurl/external/juris')
        .then((data:any)=> {
          data.error === 'true'? this.modalService.adviceModal(data.errorMessage, 'error:accept:warning') : window.open(data.pdfUrl, '_blank');
          this.conceptWindow.disabledLink = false;
        }, (errorData:any) => {
          this.conceptWindow.disabledLink = false;
    });
  }

  }
