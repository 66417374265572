<div class="up-document">
  <div class="common__block-icon-close-modal-custom">
      <button class="lf-icon-close-round common__icon-close-modal-custom"
          (click)="handleDismiss()"></button>
  </div>
  <div class="common__modal-header-block common__modal-header-block--custom-margin"
      [ngStyle]="{'background-color':custom?.color, 'color': custom?.textColor}">
      <i class="lf-icon-up-file common__icon-header common__icon-header--custom" ></i>
      <span class="common__text-header common__text-header--custom"
          [afTranslate]="'common.info-concept'">Información sobre el concepto</span>
  </div>
  <div class="concept-info__content">
      <div class="concept-info__content-body-custom"
          [innerHTML]="conceptInfo.description">
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="reset"
          class="common__button-landing-custom common__button-landing-custom--send"
          [ngStyle]="{'background-color': custom?.color, 'color': custom?.textColor}"
          (click)="handleAccept()"
          [afTranslate]="'common.ok'">Aceptar</button>
  </div>
</div>
