import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { Router } from '@angular/router';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Injectable({
  providedIn: 'root'
})
export class ConceptInstancesListParentService {

  constructor(private translate: AfTranslateFactory,
    private filterColumnService: FilterColumnService,
    private dateConverterService: DateConverterService,
    private commonUtilsService: CommonUtilsService,
    private loader: LoaderFactory,
    private router: Router,
    private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private globalCfg: GlobalCfgFactory) { }

  configureListView(child:any){
    if(this.globalCfg.skin === 'icam-red'){
        let optionView = {
            name: this.translate.instant('concept-instances-list.top-queries'),
            viewOption: 'esTop'
        };
        child.conceptInstancesList.conceptInstancesView.push(optionView);
    }
    child.conceptInstancesList.selectedView = child.conceptInstancesList.conceptInstancesView[2];
  }

  orderListDate(a:any, b:any) {
    return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
  }

  getFilters(child: any){
    child.conceptInstancesList.filters.forEach((filter:any) => {
      child.conceptInstancesList[filter+'List'] = this.filterColumnService.extractList(filter, child.conceptInstancesList.instancesListOriginFilter);
      child.conceptInstancesList[filter+'ListFilter'] = [];
      if(filter === 'alertState'){
        child.conceptInstancesList.alertStateList.forEach((progress: any) => {
          if(progress.name === '1-last-day'){
            progress.nameTranslate = this.translate.instant('concept-instances-list.last-day');
          }else if(progress.name === '2-two-days'){
              progress.nameTranslate = this.translate.instant('concept-instances-list.two-days');
          }else if(progress.name === '3-more-time'){
              progress.nameTranslate = this.translate.instant('concept-instances-list.more-time');
          }else{
              progress.nameTranslate = this.translate.instant('concept-instances-list.finished');
          }
        });
      }
    });
  }

  changeView(type:string, child: any){
    child.conceptInstancesList.view = type;
    let listFiltered : any = [];
    child.conceptInstancesList.instancesList.sort(this.orderListDate);
    switch(type){
        case 'all':
          child.conceptInstancesList.instancesListFilter = child.conceptInstancesList.instancesList;
            break;
        case 'last15':
            listFiltered = child.conceptInstancesList.instancesList.slice(0, 15);
            child.conceptInstancesList.instancesListFilter = listFiltered;
            break;
        case 'last30':
            listFiltered = child.conceptInstancesList.instancesList.slice(0, 30);
            child.conceptInstancesList.instancesListFilter = listFiltered;
            break;
        case 'lastWeek':
            listFiltered = this.getLastDates(7, child);
            child.conceptInstancesList.instancesListFilter = listFiltered;
            break;
        case 'lastMonth':
            listFiltered = this.getLastDates(30, child);
            child.conceptInstancesList.instancesListFilter = listFiltered;
            break;
        case 'esTop':
            listFiltered = this.getTopInstances(child);
            child.conceptInstancesList.instancesListFilter = listFiltered;
            break;
        default:
          child.conceptInstancesList.instancesListFilter = child.conceptInstancesList.instancesList;
            break;
    }
    child.conceptInstancesList.instancesListOriginFilter = child.conceptInstancesList.instancesListFilter;
    if(child.conceptInstancesList.conceptInstancesView){
      child.conceptInstancesList.conceptInstancesView.forEach((view:any) => {
        if(view.viewOption === type){
          child.conceptInstancesList.selectedView = view;
        }
      });
    }


    if(this.globalCfg.skin === 'icam-red'){
        this.addAlertState(child);
    }
    this.getFilters(child);
  }

  getLastDates(date:number, child: any){
    return child.conceptInstancesList.instancesList.filter((instance:any)=>{ this.dateConverterService.compareDates(instance.creationDate) < date})
  }

  getTopInstances(child: any){
    return child.conceptInstancesList.instancesList.filter((instance:any)=>{ instance.esTop})
  }

  addAlertState(child: any){
    child.conceptInstancesList.instancesList.forEach((instance:any) => {
      this.commonUtilsService.configureAlertState(instance);
    });
  }

  launchActionInstance(item:any, option:string, child: any){
    switch(option){
        case 'open':
            this.openConcept(item, child);
            break;
        case 'openNewTab':
            this.openInNewTab(item);
            break;
        case 'archive':
            this.confirmArchiveConcept(item, child);
            break;
        case 'delete':
            this.deleteEvaluatedConcept(item, child);
            break;
        default:
            break;
    }
  }

  openConcept(item:any, child: any){
    this.loader.openLoader('folder-open-concept');
    let url : string= 'evaluation/' +  item.evaluationId + '/folder/' +  item.folderId + '/concept/' +  item.conceptId + '/concept-evaluated/' +  item.conceptObjectId;
    this.router.navigateByUrl(url);
  }

  openInNewTab(item:any) {
    let url : string = '/evaluation/' +  item.evaluationId + '/folder/' +  item.folderId + '/concept/' +  item.conceptId + '/concept-evaluated/' +  item.conceptObjectId;
    window.open(url, '_blank');
  }

  confirmArchiveConcept(concept:any, child: any){
    let title:string = this.globalCfg.skin === 'uk-steel-blue'? concept.conceptObjectTitle : concept.title;
    let text : string  = this.translate.instant('concept-list.archive-concept-question').replace('{titleConcept}',  title);
    this.modalService.adviceModal(text, 'info:question:question')
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('concept-list-archive-concept')
          this.archiveConcept(concept, child)
        }
      });
  }

  archiveConcept(concept:any, child: any){
    this.apiService.patch('conceptobjects/archived/'+ concept.conceptObjectId, 1)
        .then((data:any)=>{
            concept.isArchived = !concept.isArchived;
            child.filterInstancesList = this.commonUtilsService.deleteElement(concept, child.filterInstancesList, 'conceptObjectId');
            this.changeView(child.conceptInstancesList.view, child);
            this.loader.closeLoader('concept-list-archive-concept');
            this.modalService.adviceModal(this.translate.instant('concept-list.archive-concept-success'), 'info:accept:check-round');
        }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('concept-list.archive-concept-error'), 'error:accept:warning');
        });
  }

  deleteEvaluatedConcept(item:any, child: any){
    let title : any = this.globalCfg.skin === 'uk-steel-blue'? item.conceptObjectTitle : item.title;
    let questionText : string = this.translate.instant('concept-list.delete-question')+this.translate.instant('concept-list.concept')+" '"+title+"' ?";
    this.modalService.adviceModal(questionText, 'info:question:question')
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('concept-list-concept-list');
          this.deleteEvalConcepts(item, child);
        }
      });
  }

  deleteEvalConcepts(concept:any, child: any){
    this.apiService.delete('conceptobjects', concept.conceptObjectId)
        .then((data:any)=>{
            child.filterInstancesList = this.commonUtilsService.deleteElement(concept, child.filterInstancesList, 'conceptObjectId');
            this.changeView(child.conceptInstancesList.view, child);
            this.loader.closeLoader('concept-list-concept-list');
            this.modalService.adviceModal(this.translate.instant('concept-list.delete-concept-ok'), 'info:accept:check-round');
        }, (errorData:any)=>{
            this.loader.closeError();
            let text : string = errorData.status === 403? 'concept-list.delete-error-permissions' : 'concept-list.delete-error-concept'
            this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
        });
  }

  filterConcept(type:string, child: any){
    let conceptInstantesFilter:any = [];
    let searchFilter:string = child.conceptInstancesList.searchInstances;
    if(type === 'evaluationName'){
        searchFilter = child.conceptInstancesList.channelFilter;
    }else if(type === 'referencia' && this.globalCfg.skin === 'icam-red'){
        searchFilter = child.conceptInstancesList[type+'Filter'];
    }
    conceptInstantesFilter = this.filterColumnService.filterByWord(searchFilter, child.conceptInstancesList.instancesListOriginFilter, type);
    child.conceptInstancesList.filters.forEach((filter:any) => {
        let filteredList = child.conceptInstancesList[filter+'ListFilter'];
        conceptInstantesFilter = this.filterColumnService.filterBycolumn(conceptInstantesFilter, filteredList, filter);
    });
    child.conceptInstancesList.instancesListFilter = conceptInstantesFilter;
  }


  selectTypeOperation(item:any, type:string, child: any){
    let listFilter = child.conceptInstancesList[type+'ListFilter'];
    child.conceptInstancesList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    child.conceptInstancesList[type+'Filter'].hasFilter = child.conceptInstancesList[type+'ListFilter'].length;
    item.selected = !item.selected;
    this.filterConcept(type, child);
  }

  toggleSearch(child: any){
    child.conceptInstancesList.search = !child.conceptInstancesList.search;
    if (!child.conceptInstancesList.search) {
        child.conceptInstancesList.searchInstances = '';
    }
  }

  searchBy(type:string, child: any){
    if(child.conceptInstancesList.filterOrder === type){
      child.conceptInstancesList.order = !child.conceptInstancesList.order;
    }else{
      child.conceptInstancesList.filterOrder = type;
      child.conceptInstancesList.order = false;
    }
  }

  resetFilter(e:any, type:string, child: any){
    e.stopPropagation();
    e.preventDefault();
    child.conceptInstancesList.channelFilter = '';
    this.filterConcept(type, child);
  }


  openModalConfidential(instance:any, child: any){
    if(!instance.confidentialList){
        instance.confidentialList = [];
    }
    this.modalService.manageConfidentialModal(instance).subscribe((result:any) => {
      if(result.result === 'ok'){
        this.loader.openLoader('concept-list-confidential-instance');
        this.confidentDocument(instance, result, child);
      }
    });
  }

  confidentDocument(instance:any, resultModal:any, child: any){
    let list : any = resultModal.share;
    let confidential : string = resultModal.confidential.toString();
    let confidentialOfUserConnected : any = resultModal.confidentialOfUserConnected;
    this.apiService.patch('conceptobjects/setconfidential/' +instance.conceptObjectId, confidential, list)
        .then((data:any)=>{
            instance.confidential = !!resultModal.confidential;
            let text : string = 'manage-confidential-modal.confident-uncheck'
            if(instance.confidential){
                text = 'manage-confidential-modal.confident-ok';
            }else{
                instance.confidentialList = [];
            }
            if(instance.confidential && !confidentialOfUserConnected){
              child.filterInstancesList = this.commonUtilsService.deleteElement(instance, child.filterInstancesList, 'conceptObjectId');
              this.changeView(child.conceptInstancesList.view, child)
            }
            this.loader.closeLoader(['concept-list-confidential-instance']);
            this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
        },(errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning');
        })
  }

  closeDropdown(type: any, dropdowns: any){
    let dropdown: any = dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }
}
