import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Injectable()
export class ClientsCompanyResolverResolver implements Resolve<any> {
  // clients: any = {
  //   clients: [],
  //   allClients: []
  // };
  // clientsParentCount: any = 0;
  // filialsCount: any = 0;
  // filialsResolvedCount: any = 0;
  // finished: boolean = false;
  constructor(private apiService: ApiServiceService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.getClients();
    // if(this.finished){
      // return this.getClients();
    // }
    return this.apiService.get('clients/root/true/read')
    .then((data:any) => {
      return data;
    });
  }

  // getClients() {
  //     return this.apiService.get('clients/root/true/read')
  //       .then((data:any) => {
  //         data.forEach((client:any, index: any) => {
  //             if (client.active) {
  //                 client.text = client.name;
  //                 client.listValueId = client.clientId;
  //                 // this.clientsParentCount++;
  //                 this.clients.allClients.push(client);
  //                 if(client.hasSubsidiaries){
  //                   this.filialsCount++;
  //                   return this.getFilials(client, data.length - 1 === index);
  //                 }else{
  //                   this.clients.clients.push(client);
  //                   if(data.length - 1 === index && this.filialsResolvedCount === this.filialsCount){
  //                     return this.clients;
  //                     // return this.resolve;

  //                   }
  //                 }
  //             }else if(data.length - 1 === index && this.filialsResolvedCount === this.filialsCount){
  //               return this.clients;
  //               // return this.resolve;
  //             }
  //         });
  //       },(errorData:any)=>{})
  // }

  // getFilials(client:any, last:boolean){
  //     return this.apiService.get('clients/affiliatedcompaniestree', client.clientId)
  //         .then((data:any) => {
  //             client.children = data[0].children;
  //             this.configureChildrenName(client.children);
  //             this.clients.clients.push(client);
  //             this.filialsResolvedCount++;
  //             if(last){
  //                 this.finished = true;
  //                 // return this.clients;
  //                 // return this.resolve;
  //             }
  //         },(errorData:any)=>{})
  // }

  // configureChildrenName(children:any){
  //     children.forEach((clientChild:any) => {
  //         clientChild.text = clientChild.name;
  //         clientChild.listValueId = clientChild.clientId;
  //         this.clients.allClients.push(clientChild);
  //         if(clientChild.children?.length){
  //             this.configureChildrenName(clientChild.children);
  //         }
  //     });
  // }
}
