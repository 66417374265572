import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { SharepointLogoutService } from '@shared/services/sharepoint-logout/sharepoint-logout.service';
import { TargetService } from '@shared/services/target/target.service';
import moment from 'moment';

@Component({
  selector: 'concept-list-landing',
  templateUrl: './concept-list-landing.component.html'
})
export class ConceptListLandingComponent implements OnChanges, OnInit {
  @Input() conceptList: any = null;
  @Input() searchText: any = null;
  @Input() showList: any = null;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown
  skin: any = this.globalCfg.skin;
  conceptListLanding: any = {
    titleWordFilter: '',
    creationDateFromDate: null,
    creationDateToDate: null,
    creationDateFilter: false,
    order: true,
    dateFilter: 'creationDate',
    targetState: null,
    isLandingLogin: this.route.snapshot.data['name'] === 'root.public.monitoring' || this.route.snapshot.data['name'] === 'root.public.concept'?true:false,
    currentPage: 1,
    configPagination: {
      pageSize: 10
    },
    titleFilter: {
        name: this.translate.instant('common.affair'),
        orderCol: true,
        filterOrder: 'title',
        width: '248px'
    },
    referenceFilter: {
        name: this.translate.instant('common.reference'),
        width: '248px'
    },
    stepFilter: {
        name: this.translate.instant('concept-list-landing.step-proccess')
    },
    textFilters: ['title', 'referenceId'],
    filters: ['step'],
    searchHeader: ['title', 'referenceId', 'creationDateFilter', 'modificationDateFilter', 'step']
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private filterColumnService: FilterColumnService,
    private targetService: TargetService,
    private commonUtilsService: CommonUtilsService,
    private sharepointLogoutService: SharepointLogoutService) { }

  ngOnInit(): void {
    this.configureEvaluatedConcepts();
  }

  ngOnChanges(): void {
      if(this.conceptListLanding.amountList && this.conceptList && this.conceptListLanding.amountList !== this.conceptList.length){
          this.configureEvaluatedConcepts();
      }
      if(!this.showList && this.conceptList){
          this.resetFilter();
      }
  }

  configureEvaluatedConcepts(){
    this.conceptList.forEach((concept:any) => {
      concept.referenceId = this.skin === 'icam-red'?concept.referencia:concept.conceptObjectId.toString();
      concept.creationDateFilter = moment(concept.creationDate).format('DD/MM/YYYY HH:mm');
      concept.modificationDateFilter = moment(concept.modificationDate).format('DD/MM/YYYY HH:mm');
    });
    this.loader.openLoader('concept-list-charged');
    this.conceptListLanding.amountList = this.conceptList.length;
    this.getFilters(this.conceptList);
    this.filterComponentList();
  }

  getFilters(data: any){
    this.conceptListLanding.textFilters.forEach((text:any) => {
      this.conceptListLanding[text+'WordFilter'] = '';
    })
    this.conceptListLanding.filters.forEach((filter:any) => {
      this.conceptListLanding[filter+'List'] = this.filterColumnService.extractList(filter, data);
      this.conceptListLanding[filter+'ListFilter'] = [];
    })
    this.loader.closeLoader(['concept-list-charged']);
  }

  filterComponentList() {
      let filterList = this.conceptList;
      this.conceptListLanding.textFilters.forEach((text:any) => {
        let searchFilter : any = this.conceptListLanding[text+'WordFilter'];
        let searchWord : any = text;
        filterList = this.filterColumnService.filterByWord(searchFilter, filterList, searchWord);
      })
      if(this.conceptListLanding.creationDateFilter){
          filterList = this.filterDates('creationDate', filterList);
      }
      this.getFiltratedColumns(filterList);
  }

  filterDates(type: any, list: any){
      this.conceptListLanding[type+'Filter'] = true;
      let dates ={
          from: this.conceptListLanding[type+'FromDate'],
          to: this.conceptListLanding[type+'ToDate']
      }
      return this.filterColumnService.filterByRangeDates(list, type, dates);
  }

  getFiltratedColumns(filterList: any){
    this.conceptListLanding.filters.forEach((filter:any) => {
      let filteredList = this.conceptListLanding[filter+'ListFilter'];
      filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    })
    this.conceptListLanding.conceptListFilter = filterList;
  }

  orderBySearch(e: any, type: any){
    let newValue: any = e.newValue;
    let action: string = e.action;
    if(type){
      switch(action){
        case 'filter':
            this.conceptListLanding[type+'WordFilter'] = newValue;
            this.filterComponentList();
            break;
        case 'order':
            this.orderDate(type);
            break;
        default:
            break;
      }
    }

  }

  selectTypeOperation(item: any, type: any){
      let listFilter : any = this.conceptListLanding[type+'ListFilter'];
      this.conceptListLanding[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      this.conceptListLanding[type+'Filter'].hasFilter = this.conceptListLanding[type+'ListFilter'].length;
      this.filterComponentList();
  }

  orderDate(type: any){
      if(this.conceptListLanding.dateFilter === type){
          this.conceptListLanding.order = !this.conceptListLanding.order;
      }else{
          this.conceptListLanding.dateFilter = type;
          if(type === 'title'){
              this.conceptListLanding.order = false;
          }else{
              this.conceptListLanding.order = true;
          }
      }
  }

  changeCalendar(e: any, type: any){
      let dates = e.dates;
      this.dropdown.close();
      this.conceptListLanding[type+'Filter'] = dates.type === 'cancel'?this.conceptListLanding[type+'Filter']:dates.type === 'apply';
      if(dates.type !== 'cancel'){
          this.conceptListLanding[type+'FromDate'] = dates.from;
          this.conceptListLanding[type+'ToDate'] = dates.to;
          let filterList = this.filterColumnService.filterByRangeDates(this.conceptList, type, dates);
          this.getFiltratedColumns(filterList);
      }
  }

  openConcept(item: any, newTab: boolean=false) {
      let target : string = 'root.public.monitoring%3FconceptObjectId%3D'+item.conceptObjectId;
      this.conceptListLanding.targetState = this.targetService.parseString(target);
      this.context.user.password = decodeURIComponent(item.publicUrl.substring(item.publicUrl.indexOf('=')+1));
      this.loginAndGo(item, newTab);
  }

  loginAndGo(item: any, newTab:boolean=false) {
      let queryString = this.route.snapshot.queryParams;
      if (this.skin === 'icam-red' && queryString['sso_token']) {
          let loginContext = {
              sso_token: queryString['sso_token'],
              sso_timestamp: queryString['sso_timestamp'],
              sso_hash: queryString['sso_hash'],
              sso_perfil: queryString['sso_perfil'],
              sso_username: queryString['sso_username'],
              sso_situacion: queryString['sso_situacion'],
              sso_estado: queryString['sso_estado']
          };
          this.context.user.context = loginContext;
      }
      this.apiService.login(this.context.user, this.conceptListLanding.isLandingLogin ? 'public' : 'front')
        .then((data:any)=> {
            if (this.conceptListLanding.isLandingLogin || data.user.hasPermissions || this.route.snapshot.data['name'] === 'import-evaluation') {
                this.continueLogin(data, item, newTab);
            } else {
                this.conceptListLanding.jwtToken = data.jwtToken;
                this.conceptListLanding.showNoPermissionsWarning = true;
                this.continueLogin(data, item, newTab);
                this.loader.closeLoader(['front-login-login']);
            }
        }, (errorData:any)=> {}
      );
  }

  continueLogin(data: any, item: any, newTab:boolean=false){
    this.sharepointLogoutService.logout();
    data.user = this.context.user;
    this.context.setLogin(data);
    if(newTab){
      let url = decodeURIComponent(item.publicUrl);
      window.open(url, '_blank');
    }else if(this.conceptListLanding.targetState){
      let url = decodeURIComponent(item.publicUrl);
      window.open(url, '_self');
    }else{
      this.router.navigate(['dashboard']);
    }
  }

  showTooltip(element:string = ''){
    return this.commonUtilsService.showTooltip(element);
  }

  resetFilter(){
      this.conceptListLanding.creationDateFromDate = null;
      this.conceptListLanding.creationDateToDate = null;
      this.conceptListLanding.creationDateFilter = false;
      this.getFilters(this.conceptList);
      this.filterComponentList();
  }

}


