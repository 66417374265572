import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { GenerateDocumentParentService } from '../utils/generate-document-parent.service';


@Component({
  selector: 'generate-document',
  templateUrl: './generate-document.component.html'
})
export class GenerateDocumentComponent implements OnInit {

  generateDocument: any = {
    document: {
      properties: {},
      name: '',
      templateName:''
    },
    documentName: {
        id:'1',
        required:  true,
        label: this.translate.instant('generate-document.name-document')
    }
  }

  constructor(
    private translate: AfTranslateFactory,
    private generateDocumentParent: GenerateDocumentParentService,
    private activeModal: NgbActiveModal) {
  }

  @Input() predefinedTemplate: any = null;
  @Input() concept: any = null;

  ngOnInit(): void {
    this.generateDocumentParent.onInit(this)
  }

  itemSelected(e:any, type:any){
    this.generateDocument.document[type] = e.newValue;
  }

  handleAccept(){
    this.generateDocumentParent.handleAccept(this);
  }

  handleDismiss(){
    this.generateDocumentParent.handleDismiss(this)
  }
}
