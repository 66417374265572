import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class CompanyPermissionsResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService) { }
    resolve(route: ActivatedRouteSnapshot) {
      let url: string = 'company/dashboard';
      let id: any = 'security';
      // let componentName = route.data['componentName'];
      return this.apiService.get(url,  id)
        .then((data:any)=> {
          return data;
        })
    }

}
