<div class="alert-detail">
  <div class="common__modal-header-block">
      <i class="lf-icon-notification common__icon-header"></i>
      <span class="common__text-header">{{alertDetail.title}}</span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <form class="alert-detail__form">
      <field-type-text-simple
          [field]="alertDetail.titleAlert"
          [preSelectItem]="alert.title"
          (itemSelected)="alert.title">
      </field-type-text-simple>
      <label for="alert-detail-interval-days" class="alert-detail__interval">
          <input type="radio" id="alert-detail-interval-days" class="alert-detail__check-interval" [(ngModel)]="alert.notified"/>
          <i class="alert-detail__interval-icon" [ngClass]="alert.alertTypeId === 1 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
          <span class="alert-detail__interval-text" [afTranslate]="'alert-detail.send-notify'"></span>
      </label>
      <div class="alert-detail__radio-button-block" [ngShow]="alert.alertTypeId === 1">
          <field-type-switch
            [label]="'add-alert.repeat' | afTranslate"
            [checked]="alert.repeat"
            [disabled]="true"
            (callback)="alert.repeat = $event.value">
          </field-type-switch>
      </div>
      <label class="alert-detail__interval" for="alert-detail-interval-frecuency">
          <input type="radio" id="alert-detail-interval-frecuency" class="alert-detail__check-interval" [(ngModel)]="alert.notified"/>
          <i class="alert-detail__interval-icon" [ngClass]="alert.alertTypeId === 2 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
          <span class="alert-detail__interval-text" [afTranslate]="'alert-detail.send-notify-after'"></span>
      </label>
      <div [ngShow]="alert.alertTypeId === 2">
          <div class="alert-detail__field-interval">
              <div class="alert-detail__units-block">
                  <field-type-number [field]="alertDetail.delayUnitsField"
                    [preSelectItem]="alert.delayUnits"></field-type-number>
              </div>
              <div class="alert-detail__time-block">
                  <field-type-list-simple
                    [field]="alertDetail.delayTimeUnitField"
                    [listValues]="alertDetail.timeUnits"
                    [preSelectItem]="alert.delayTimeUnit">
                  </field-type-list-simple>
              </div>
          </div>
          <div class="alert-detail__radio-button-block">
              <field-type-switch
                [label]="'add-alert.repeat' | afTranslate"
                [checked]="alert.repeat"
                (callback)="alert.repeat = $event.value">
              </field-type-switch>
          </div>
      </div>
  </form>
  <div class="common__buttons-block common__buttons-block--modal">
      <button class="common__button common__button--clean"
          (click)="handleDismiss()"
          [afTranslate]="'common.cancel'">
      </button>
  </div>
</div>
