import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-other',
  templateUrl: './customize-field-other.component.html'
})
export class CustomizeFieldOtherComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  evaluationId: any = this.router.snapshot.params['evaluationId']
  customizeFieldOther: any = {
    tinyMceOptions: {},
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    },
    fieldInfoOrigin: {
        label: this.translate.instant('customize-field.origin-concept'),
        required: true
    },
    fieldInfoSelect: {
        label: this.translate.instant('customize-field.selection-field'),
        noEditable: true,
        required: true
    },
    fieldInfoAdditional: {
        label: this.translate.instant('customize-field.aditionals-fields'),
        multiple: true,
        required: true,
        noEditable: true
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private router: ActivatedRoute,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory,
    private commonUtilsService: CommonUtilsService) { }

  @Input() field: any = null;
  @Input() reportList: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() concept: any = {};
  @Input() fieldOriginList: any = null;
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldOther;
    if(this.field){
      that.field = this.field;
      that.fieldCopy = cloneDeep(that.field);
      that.contentReportInfo.noEditable = that.field.noEditable
      that.fieldInfoOrigin.noEditable = that.field.noEditable
    }
    if(this.fieldOriginList){
      this.customizeFieldOther.fieldOriginList = this.convertToList(this.fieldOriginList);
    }
    this.customizeFieldOther.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  convertToList(data:any){
    data.forEach((item:any) => {
      item.text = item.title;
      item.listValueId = item.conceptId;
      if(this.customizeFieldOther.field.sourceConcept && item.conceptId === this.customizeFieldOther.field.sourceConcept.conceptId){
          this.fieldOriginSelect(item.conceptId);
      }
    });
    return data;
  }

  getEditorValue(event:any){
    this.customizeFieldOther.field.description = event.model;
  }

  itemSelected(e:any, item:string){
      this.customizeFieldOther.field[item] = e.newValue;
      this.modifyField();
  }

  fieldChangeSelect(e:any){
      this.resetValues();
      this.fieldOriginSelect(e.newValue);
      this.modifyField();
  }

  fieldOriginSelect(newValue:any){
      this.customizeFieldOther.fieldOrigin = newValue;
      this.customizeFieldOther.field.relatedConceptId = newValue;
      if(newValue !== null){
          this.customizeFieldOther.fieldInfoAdditional.error = false;
          this.customizeFieldOther.fieldInfoSelect.error = false;
          this.customizeFieldOther.fieldInfoOrigin.error = false;
          this.getRelatedFields(newValue);
      }
  }

  resetValues(){
    let that: any = this.customizeFieldOther;
      that.additionalFieldsSelectList = [];
      that.fieldSelectList = [];
      that.fieldPreselect = null;
      that.additionalPreselect = null;
      that.field.additionalRelatedFields = [];
      that.field.relatedFieldId = null;
      that.fieldInfoSelect.noEditable = true;
      that.fieldInfoAdditional.noEditable = true;
  }

  getRelatedFields(conceptId:any){
    let that: any = this.customizeFieldOther;
      let listElements:any = [];
      this.apiService.get('fields/concept/' + conceptId + '/type', 'text')
          .then((data:any)=>{
              that.additionalFieldsSelectList = this.commonUtilsService.editList(data, 'fieldId', 'label');
              that.fieldInfoSelect.noEditable = that.field.noEditable;
              that.fieldInfoAdditional.noEditable = that.field.noEditable;
              that.additionalFieldsSelectList.forEach((additionalRelated:any)=> {
                if(that.field.relatedFieldId && that.field.relatedFieldId === additionalRelated.fieldId){
                  this.fieldConceptSelect(additionalRelated.fieldId);
                  that.fieldPreselect = additionalRelated.fieldId;
                }
                if(additionalRelated.fieldTypeId ==='text'){
                    listElements.push(additionalRelated)
                }
              });
              that.fieldSelectList = listElements;
              if(that.field.additionalRelatedFields.length){
                if(that.field.container && that.field.relatedConceptId === that.field.container.conceptId || !that.field.container){
                  this.getAdditionalRelated()
                }else{
                  that.additionalPreselect = null;
                }
              }
          }, (errorData:any)=>{})
  }

  getAdditionalRelated(){
      this.customizeFieldOther.additionalPreselect = '';
      for(let i = 0; i < this.customizeFieldOther.field.additionalRelatedFields.length; i++){
          let additional : any = this.customizeFieldOther.field.additionalRelatedFields[i];
          this.customizeFieldOther.additionalPreselect += i === this.customizeFieldOther.field.additionalRelatedFields.length-1? additional.additionalFieldId : additional.additionalFieldId+'|';
      }
  }

  fieldConceptSelect(e:any){
      let newValue: any = e.newValue;
      this.customizeFieldOther.field.relatedFieldId = newValue;
      this.customizeFieldOther.fieldInfoSelect.error = false;
      this.modifyField();
  }

  additionalField(e:any){
      let newValue: any = e.newValue;
      this.customizeFieldOther.field.additionalRelatedFields=[];
      let additionalValues:any = newValue.indexOf('|') !== -1?newValue.split('|') : [newValue]
      additionalValues.forEach((additionalValue:any) => {
        this.customizeFieldOther.field.additionalRelatedFields.push({additionalFieldId:additionalValue});
      });
      this.customizeFieldOther.fieldInfoAdditional.error = false;
      this.modifyField();
  }


  cancelFieldText(){
      this.customizeFieldOther.field = cloneDeep(this.customizeFieldOther.fieldCopy);
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldOther.field
          }
      })
  }

  saveFieldText(){
      if(this.validateFields()){
          this.customizeFieldOther.fieldCopy = cloneDeep(this.customizeFieldOther.field);
          this.changeInField.emit({
              change:{
                  type: 'save',
                  field: this.customizeFieldOther.field
              }
          })
      }
  }

  validateFields(){
    let that: any = this.customizeFieldOther
    that.fieldInfoAdditional.error = !(that.field.additionalRelatedFields && that.field.additionalRelatedFields.length);
    that.fieldInfoSelect.error = that.field.relatedFieldId == null;
    that.fieldInfoOrigin.error = that.field.relatedConceptId === null && that.field.relatedConceptId === undefined
    return !(that.fieldInfoAdditional.error || that.fieldInfoSelect.error || that.fieldInfoOrigin.error);
  }

  modifyField(){
    let that: any = this.customizeFieldOther
    if(!that.field.multiple){
        that.field.lcoIsUngroupedView = false;
    }
    if(!that.field.fieldAttributes){
        that.field.fieldAttributes = {};
    }
    that.field.fieldAttributes.lcoIsUngroupedView = that.field.lcoIsUngroupedView;
    this.conceptState.add(that.field);
  }

  changeOptionOn(e:any, type:string){
    this.customizeFieldOther.field[type] = e.model
    this.modifyField();
  }

}
