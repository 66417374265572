<label class="common__input-block"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--color field-type-list__input-block': !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight && !fieldTypeList.field.isTypeButton,
      'common__input-block--fixed field-type-list__input-block-numerical-scale':fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight || fieldTypeList.field.isTypeButton,
      'common__input-block--poll-view':fieldTypeList.field.poll && !fieldTypeList.field.noEditable,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-custom-numerical-scale':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      [ngShow]="!fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': fieldTypeList.field.error,
      'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="fieldTypeList.field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable">
          </tooltip-law>
      </p>
  </div>
  <ul class="field-type-text__sequential-question-list">
      <li class="field-type-list__highlight-block-sequential">
          <ul class="field-type-list__list-ishighlight"
            [ngClass]="{'field-type-list__list-ishighlight--custom':isLanding}">
            <li class="field-type-list__element-list-ishighlight"
                    *ngFor="let listValue of listValues"
                    [ngClass]="{'field-type-list__element-list-ishighlight--custom':isLanding}">
                <button [class]="'field-type-list__input-content-highlight field-type-list__input-content-highlight--color-'+listValue.setColor"
                    [ngClass]="{'field-type-list__input-content-highlight--disabled': fieldTypeList.field.noEditable,
                        'field-type-list__input-content-highlight--poll':fieldTypeList.field.poll && !fieldTypeList.field.noEditable,
                        'field-type-list__input-content-highlight--sequential':isLanding && fieldTypeList.field.poll && !fieldTypeList.field.noEditable,
                        'common__show-alert': fieldTypeList.field.error && fieldTypeList.field.sequentialView,
                        'common__pill-button--poll-error':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.error && listValue.background ==='#fff'}"
                    [ngStyle]="{'border':isLanding?'': '2px solid '+ listValue.color,
                        'background':isLanding && !listValue.circle?listValue.customBackgroundColor:listValue.background,
                        'color': listValue.fontColor}"
                    readonly
                    (click)="selectedField(listValue)"
                    [disabled]="fieldTypeList.field.noEditable">
                    <span class="field-type-list__text-highlight">{{listValue.text}}</span>
                    <i class="lf-icon-step-final field-type-list__circle-button-text"
                      [ngShow]="listValue.circle && !(isLanding)"></i>
                </button>
            </li>
        </ul>
      </li>
  </ul>
  <div class="field-item__blocker-box"
      *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
  </div>
</label>
