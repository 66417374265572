<div class="alert-list-window">
    <header-nav></header-nav>
    <div class="alert-list-window__body">
        <div class="common__block-button-close-window">
            <button (click)="changeView()"
                class="common__back-link">
                <span [afTranslate]="'alert-list-window.close'">_Cerrar lista de alertas</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="alert-list-window__container">
            <div class="common__block-list-new-version alert-list-window__block-container">
                <div class="common__header-new-version">
                    <i class="lf-icon-notification common__header-icon-new-version"></i>
                    <span class="common__header-title-new-version"
                        [afTranslate]="'alert-list-window.block-review-alert'">_Alertas de revisión de bloqueo</span>
                </div>
                <div class="common__table-block">
                    <div class="common__table-new-version">
                        <div class="common__table-header">
                            <div class="alert-list-window__col alert-list-window__col--origin alert-list-window__col--first alert-list-window__col--top-1">
                                <filter-by-list [type]="'list'"
                                    [item]="alertListWindow.originFilter"
                                    [widthClass]="'200px'"
                                    [list]="alertListWindow.originList"
                                    (filterElementEmit)="selectTypeOperation($event, 'origin')">
                                </filter-by-list>
                            </div>
                            <div class="alert-list-window__col alert-list-window__col--title alert-list-window__col--top-1">
                                <filter-by-search
                                    [item]="alertListWindow.titleFilter"
                                    [filterText]="alertListWindow.titleWordFilter"
                                    (launchActionSearch)="orderBySearch($event, 'title')">
                                </filter-by-search>
                            </div>
                            <div class="alert-list-window__col alert-list-window__col--creator alert-list-window__col--top-1">
                                <filter-by-list [type]="'listFind'"
                                    [item]="alertListWindow.createdByFilter"
                                    [widthClass]="'200px'"
                                    [list]="alertListWindow.createdByList"
                                    (filterElementEmit)="selectTypeOperation($event, 'createdBy')">
                                </filter-by-list>
                            </div>
                            <div class="alert-list-window__col alert-list-window__col--destination alert-list-window__col--top-1">
                                <filter-by-list [type]="'list'"
                                    [item]="alertListWindow.destinationUniqueFilter"
                                    [widthClass]="'200px'"
                                    [list]="alertListWindow.destinationUniqueList"
                                    (filterElementEmit)="selectTypeOperation($event, 'destinationUnique')">
                                </filter-by-list>
                            </div>
                            <div class="alert-list-window__col alert-list-window__col--alert-date alert-list-window__col--top-1">
                                <span-tooltip class="common__table-header-text common__cursor-pointer common__table-header-text--order"
                                    [text]="'alert-concept.alert-date' | afTranslate"
                                    (click)="searchBy('alertDate')">
                                </span-tooltip>
                                <i class="common__order-by"
                                    [ngClass]="{'lf-icon-arrow-down':(alertListWindow.typeFilter==='alertDate' && alertListWindow.orderOperationList) || alertListWindow.typeFilter!=='alertDate',
                                        'lf-icon-arrow-up': alertListWindow.typeFilter==='alertDate' && !alertListWindow.orderOperationList,
                                        'common__order-by--active': alertListWindow.typeFilter==='alertDate'}"
                                    (click)="searchBy('alertDate')"></i>
                            </div>
                        </div>
                        <div class="common__table-body common__table-body--10-elements">
                            <div class="common__table-row "
                                *ngFor="let alert of alertListWindow.filterAlertList | byWord:alertListWindow.searchAlert:['origin', 'title', 'createdBy'] | afOrderBy:[alertListWindow.typeFilter, 'orderItem']:alertListWindow.orderOperationList | afLimitTo:alertListWindow.configPagination.pageSize:alertListWindow.configPagination.pageSize*(alertListWindow.currentPage - 1)">
                                <div class="alert-list-window__col alert-list-window__col--origin alert-list-window__col--first alert-list-window__col--tooltip">
                                    <i class="lf-icon-alert alert-list-window__icon-origin"></i>
                                    <parragraph-tooltip class="alert-list-window__parragrapht-with-icon"
                                        [text]="alert.origin">
                                    </parragraph-tooltip>
                                </div>
                                <div class="alert-list-window__col alert-list-window__col--title alert-list-window__col--tooltip">
                                      <parragraph-tooltip [text]="alert.title"></parragraph-tooltip>
                                </div>
                                <div class="alert-list-window__col alert-list-window__col--creator alert-list-window__col--tooltip">
                                      <parragraph-tooltip [text]="alert.createdBy"></parragraph-tooltip>
                                </div>
                                <div class="alert-list-window__col alert-list-window__col--destination alert-list-window__col--tooltip">
                                      <participants-list *ngIf="alert.destinationUnique?.length"
                                          [participants]="alert.destinationUnique"
                                          [type]="'evaluation'">
                                      </participants-list>
                                </div>
                                <div class="alert-list-window__col alert-list-window__col--alert-date alert-list-window__col--tooltip">
                                      <parragraph-tooltip [text]="alert.alertDate | convertDate:'without-time'"></parragraph-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common__block-footer-table">
                    <div class="common__block-search">
                        <input type="text"
                            [ngShow]="alertListWindow.search"
                            class="common__search-input"
                            [(ngModel)]="alertListWindow.searchAlert"
                            [placeholder]="'common.search' | afTranslate"
                            [focusWhen]="alertListWindow.search">
                        <span class="common__text-search"
                            [ngShow]="!alertListWindow.search"
                            [afTranslate]="'common.search'"
                            (click)="toggleSearch()">_Buscar</span>
                        <button class="lf-icon-search common__icon-button common__icon-button-search"
                            [ngClass]="{'lf-icon-search': !alertListWindow.search,
                                'lf-icon-close-search': alertListWindow.search,
                                'common__icon-button-search--open': alertListWindow.search}"
                            (click)="toggleSearch()">
                        </button>
                    </div>
                    <div class="alert-list-window__pagination-block"
                        [ngShow]="alertListWindow.filterAlertList.length > alertListWindow.configPagination.pageSize">
                        <pagination-control [config]="alertListWindow.configPagination"
                            [collectionSize]="(alertListWindow.filterAlertList | byWord:alertListWindow.searchAlert:['origin', 'title', 'createdBy']).length"
                            [(currentPage)]="alertListWindow.currentPage">
                        </pagination-control>
                    </div>
                    <div class="common__block-footer-total">
                        <span [afTranslate]="'common.total'">_Total</span>
                        <span class="common__total-number-element notranslate">{{ (alertListWindow.filterAlertList | byWord:alertListWindow.searchAlert:['origin', 'title', 'createdBy']).length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
