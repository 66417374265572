import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
@Injectable()

export class UserPermissionsResolver implements Resolve<any> {
    constructor(private apiService: ApiServiceService,
      private userPermissions: UserPermisionsFactory,
      private context: ContextFactory) { }

    resolve(route: ActivatedRouteSnapshot) {
      let url: string = '';
      let id: string = '';
      let componentName = route.data['componentName'];

      if(componentName === 'landingPage'){
        let landingPermissions = {
            allowAdmin: false,
            allowManage: false,
            allowDesign: false,
            allowModify: true,
            isLanding: true
        }
        this.userPermissions.setUserPermisions(landingPermissions);
        return this.userPermissions.getPermissions();
      }else if(componentName === 'evaluation'){
        url = 'evaluations/permissions';
        id = route.params['evaluationId'];
      }else {
        url = 'company/dashboard/security/product';
        id = this.context.settings.defaultProduct;
      }

      return this.apiService.get(url, id)
        .then((data:any)=> {
          this.userPermissions.setUserPermisions(data);
          return this.userPermissions.getPermissions();
        })
    }

}
