<div class="folder">
    <div *ngFor="let report of reports.reportsTypeList; let index = index"
         [ngShow]="skin != 'dark-blue' || evaluationInfo?.productId != 'denunc' || !report.predefined">
        <!-- *********************  INFORME GLOBAL  *************************-->
        <div *ngIf="report.type===0"
            class="reports__block-global">
            <div class="reports__global-header">
                <div class="reports__global-header-title">
                    <div class="reports__block-icon-template">
                        <i class="reports__global-header-title-icon lf-icon-label-analysis"></i>
                        <div [ngShow]="report.confidential"
                            class="reports__block-confident-report"
                            ngbDropdown
                            [id]="'report-global-confidential'+report.reportId"
                            [autoClose]="'outside'">
                            <button class="evaluation-menu__confident-folder evaluation-menu__confident-folder--active-report"
                                ngbDropdownToggle
                                matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above">
                                <i class="lf-icon-lock"></i>
                            </button>
                            <list-in-dropdown *ngIf="report.confidentialList"
                                ngbDropdownMenu
                                role="menu"
                                class="dropdown-menu evaluation-menu__box-select-list"
                                [list]="report.confidentialList"
                                [type]="'confidential'"
                                (closeDropdownEmit)="closeDropdown('report-global-confidential'+report.reportId)"></list-in-dropdown>
                        </div>
                    </div>
                    <div class="reports__global-header-title-text">
                        <span class="reports__global-header-title-name">{{report.name | uppercase}}</span>
                        <span class="reports__global-header-title-description">{{report.description}}</span>
                    </div>
                    <div class="evaluation-menu__dropdown-menu folder__folder-menu folder__folder-menu--report-global-list"
                        ngbDropdown
                        [ngShow]="userPermissions !== 'none' && reports.confidentialListMenu.length"
                        [open]="report.openOption">
                        <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--folder lf-icon-meatballs-menu"
                                ngbDropdownToggle>
                        </button>
                        <ul class="folder__concept-menu-list dropdown-menu"
                            ngbDropdownMenu
                            role="menu">
                            <li class="common__list-dropdown-option"
                                *ngFor="let option of reports.confidentialListMenu; let last = last">
                                <button class="common__button-option-list-dropdown"
                                    [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                    (click)="reportsAction(option.action, report)">
                                    <i [class]="'common__icon-option-list-dropdown '+option.icon"></i>
                                    <span class="common__text-menu-option-dropdown"
                                        [afTranslate]="option.name"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="reports__global-header-buttons-container"
                    [ngShow]="userPermissions !== 'none'">
                    <button *ngIf="report.canUploadVersion"
                        (click)="uploadReport(report)"
                        class="reports__global-header-button">
                        <i class="reports__global-header-button-icon lf-icon-up-file"></i>
                        <span class="reports__global-header-button-text"
                            [afTranslate]="'common.upload-version'"></span>
                    </button>
                    <button *ngIf="report.canGenerateNew"
                        (click)="newReport(report)"
                        class="reports__global-header-button reports__global-header-button--next">
                        <i class="reports__global-header-button-icon lf-icon-refresh"></i>
                        <span class="reports__global-header-button-text"
                            [afTranslate]="'common.generate'"></span>
                    </button>
                </div>
            </div>
            <div class="concept-list__table-config">
                <div [ngShow]="report.reportObjects.length">
                    <!-- Div caja informe fijo-->
                    <div class="reports__principal-report"
                        [ngClass]="{'concept-list__row-anonimyzed':report.lastReportObject?.anonymized}">
                        <div class="reports__principal-report-info">
                            <i [class]="'reports__icon-document '+(report.lastReportObject?.extension | extensionDocument)"></i>
                            <div class="reports__principal-report-info-text">
                                <p class="reports__principal-report-title">{{report.lastReportObject?.title}}</p>
                                <p class="reports__principal-report-name">[
                                    <span [ngClass]="{'common__line-through':report.lastReportObject?.authorIsDeleted}"
                                    matTooltip="{{report.lastReportObject?.author +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    [matTooltipDisabled]="!report.lastReportObject?.authorIsDeleted">{{report.lastReportObject?.author}}</span>,
                                    {{report.lastReportObject?.creationDate | convertDate:'without-time' }}]
                                </p>
                                <p class="reports__principal-report-status">
                                    <span>{{report.lastReportObject?.status}}</span>
                                    <i class="lf-icon-certificate reports__icon-certified"
                                        [ngShow]="report.lastReportObject?.sendedAsCertifiedEmail || report.lastReportObject?.sendedAsCertifiedSMS"></i>
                                    <i class="lf-icon-anonymous reports__icon-certified"
                                        [ngShow]="report.lastReportObject?.anonymized"></i>
                                </p>
                            </div>
                        </div>
                        <div class="reports__principal-report-options" >
                            <div class="reports__item"
                                [ngClass]="{'reports__item--none': userPermissions === 'none'}"
                                (click)="launchActionDocuments('open' , report.lastReportObject, report)">
                                <i class="reports__item-icon"
                                  [ngClass]="userPermissions === 'none' || report.lastReportObject?.documentStatusId !== 1 ? 'lf-icon-visible' : 'lf-icon-editable-form'"></i>
                                <p class="reports__item-text"
                                  [afTranslate]="userPermissions === 'none' || report.lastReportObject?.documentStatusId !== 1 ? 'common.view' : 'expanded-menu.edit'"></p>
                            </div>
                            <div class="reports__item"
                                [ngShow]="!report.lastReportObject?.anonymized && skin ==='dark-blue' && userPermissions !== 'none' "
                                (click)="adviseAnonymatum(report.lastReportObject)">
                                <i class="reports__item-icon lf-icon-anonymous"></i>
                                <p class="reports__item-text common__ellipsis reports__item-text--short"
                                    [afTranslate]="'expanded-menu.anonymatum-documents'">
                                    Anonimizar__
                                </p>
                            </div>
                            <div class="reports__item"
                                [ngShow]="userPermissions !== 'none'"
                                (click)="launchActionDocuments('download' , report.lastReportObject, report)">
                                <i class="reports__item-icon lf-icon-download"></i>
                                <p class="reports__item-text"
                                    [afTranslate]="'expanded-menu.download'">
                                    Descargar__
                                </p>
                            </div>
                            <div class="reports__item"
                                [ngShow]="userPermissions !== 'none'"
                                (click)="launchActionDocuments('share', report.lastReportObject, report)">
                                <i class="reports__item-icon lf-icon-share"></i>
                                <p class="reports__item-text"
                                    [afTranslate]="'common.share'">
                                    Compartir__
                                </p>
                            </div>
                            <div class="reports__item"
                                [ngShow]="report.lastReportObject?.documentStatusId === 1 && userPermissions !== 'none'"
                                (click)="launchActionDocuments('publish', report.lastReportObject, report)">
                                <i class="reports__item-icon lf-icon-document-validate"></i>
                                <p class="reports__item-text"
                                    [afTranslate]="'common.publish'">__Publicar</p>
                            </div>
                            <div *ngIf="report.canSign"
                                class="reports__item"
                                [ngShow]="userPermissions !== 'none'"
                                ngbDropdown
                                ngbDropdownToggle
                                (openChange)="openChangeSignature($event, report)">
                                <i class="reports__item-icon lf-icon-signature-certificate"></i>
                                <span class="reports__item-text common__ellipsis reports__item-text--short"
                                    matTooltip="{{'expanded-menu.signature-certification' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above">
                                    <span [afTranslate]="'expanded-menu.signature-certification'">__Firma</span>
                                </span>
                                <div class="common__menu-expanded-little dropdown-menu"
                                    ngbDropdownMenu
                                    [ngShow]="report.lastReportObject?.showMenu">
                                    <div ngbDropdownItem *ngFor="let option of report.lastReportObject?.menuOption; let last = last"
                                        class="common__menu-expanded-little-option"
                                        (click)="launchActionDocuments(option.action, report.lastReportObject, report)">
                                        <div class="common__menu-expanded-little-option-item"
                                            [ngClass]="{'common__menu-expanded-little-option-item--last': last}"
                                            [afTranslate]="option.name"></div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="report.canDelete"
                                class="reports__item"
                                [ngShow]="userPermissions !== 'none'"
                                (click)="deleteGlobalReport(report.lastReportObject)">
                                <i class="reports__item-icon lf-icon-trash"></i>
                                <span class="reports__item-text"
                                    [afTranslate]="'expanded-menu.delete'">
                                    Eliminar__
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="reports__down"
                        (click)="changeShowList(report)"
                        [ngShow]="report.reportObjects.length > 1">
                        <i class="reports__down--icon"
                            [ngClass]="report.showList ? 'lf-icon-less-full' : 'lf-icon-add-full' "></i>
                        <p class="reports__down-text"
                            [afTranslate]="report.showList?'reports.hide-versions':'reports.show-versions'">_Mostrar versiones anteriores
                        </p>
                    </div>
                </div>
                <!-- div caja informes posteriores-->
                <concept-list-global-report [ngShow]="report.reportObjects.length && report.showList"
                    [conceptInfo]="report"
                    [evaluation]="evaluationInfo"
                    (evaluateConcept)="launchActionDocumentsOn($event, report)">
                </concept-list-global-report>
                <div class="folder__no-concept-block"
                    [ngShow]="!report.reportObjects.length">
                    <p class="folder__no-concept-text"
                        [afTranslate]="'reports.no-generated-reports'">No hay informes generados</p>
                </div>
            </div>
        </div>
        <!-- *****************************************************************-->
        <div class="reports__block-bool-fold"
            [ngShow]="displaySwitch(index)">
            <field-type-switch [label]="'common.fold-unfold' | afTranslate"
                                 [checked]="reports.openAll"
                                 (callback)="toggleSwitchFoldUnfold($event.value)">
            </field-type-switch>
        </div>
        <div *ngIf="report.type !== 0"
            class="reports__box-shadow">
            <ul class="folder__evaluation-concept-list"
                [ngClass]="{'folder__evaluation-concept-list--archived folder__evaluation-folder-list--archived':report.toShow==='archived'}">
                <li class="folder__evaluation-concept-item">
                    <div [ngHide]="report.toShow==='archived'">
                        <i class="folder__evaluation-concept-arrow float-start"
                            [ngHide]="skin ==='icam-red' && report.predefined"
                            [ngClass]="report.open?'lf-icon-angle-up':'lf-icon-angle-down'"
                            (click)="showHideReport(report)"></i>
                        <div class="reports__block-icon-template">
                            <i class="folder__icon-document folder__icon-document--{{skin}}"
                                [ngClass]="{'lf-icon-template': report.predefined && !report.hidden,
                                    'lf-icon-template-user': !report.predefined && !report.hidden,
                                    'lf-icon-invisible-document folder__icon-document--hidden':report.hidden}"></i>
                            <div [ngShow]="report.confidential"
                                class="reports__block-confident-report"
                                ngbDropdown
                                [id]="'report-confidential-'+report.reportId"
                                [autoClose]="'outside'"
                                [open]="report.showDropdownReport">
                                <button class="evaluation-menu__confident-folder evaluation-menu__confident-folder--active-short"
                                    ngbDropdownToggle
                                    matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above">
                                    <i class="lf-icon-lock"></i>
                                </button>
                                <list-in-dropdown *ngIf="report.confidentialList"
                                    ngbDropdownMenu role="menu"
                                    class="dropdown-menu evaluation-menu__box-select-list"
                                    [list]="report.confidentialList"
                                    [type]="'confidential'"
                                    (closeDropdownEmit)="closeDropdown('report-confidential-'+report.reportId)"></list-in-dropdown>
                            </div>
                        </div>
                        <div class="reports__block-title-report">
                            <span-tooltip  class="folder__evaluation-concept-title float-start"
                              [ngClass]="{'folder__evaluation-concept-title--hidden': report.hidden}"
                              [text]="report.name"></span-tooltip>
                            <span class="reports__new-version"
                                [ngShow]="report.isNewVersion"
                                [afTranslate]="'reports.new-version'">_nueva version</span>
                        </div>
                        <div class="evaluation-menu__dropdown-menu folder__folder-menu folder__folder-menu--concept-list"
                            ngbDropdown
                            [ngShow]="userPermissions !== 'none' && reports.confidentialListMenu?.length"
                            [open]="report.openOption">
                            <button class="evaluation-menu__list-menu-button evaluation-menu__list-menu-button--folder lf-icon-meatballs-menu"
                                    ngbDropdownToggle>
                            </button>
                            <ul class="folder__concept-menu-list dropdown-menu"
                                ngbDropdownMenu
                                role="menu">
                                <li class="common__list-dropdown-option"
                                    *ngFor="let option of reports.confidentialListMenu; let last = last">
                                    <button class="common__button-option-list-dropdown"
                                        [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                        (click)="reportsAction(option.action, report)">
                                        <i [class]="'common__icon-option-list-dropdown '+option.icon"></i>
                                        <span class="common__text-menu-option-dropdown"
                                            [afTranslate]="option.name"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <i class="lf-icon-arrow-round-right reports__access-icon"
                            [ngShow]="skin==='icam-red' && report.predefined"
                            (click)="updateReport(report)"
                            matTooltip="{{'reports.access-report' | afTranslate}}"
                            matTooltipPosition="above"
                            matTooltipClass="above"></i>
                        <div [ngShow]="!((skin==='icam-red' || evaluationInfo?.productId === 'blanqueo') && report.predefined)">
                            <i class="lf-icon-add-round folder__evaluation-concept-file float-end"
                                [ngHide]="report.hidden || userPermissions === 'none'"
                                (click)="newReport(report)"
                                matTooltip="{{'reports.create-new-report' | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"></i>
                            <p class="folder__square-item float-end">{{report.reportObjects.length}}</p>
                            <p class="folder__title-evaluated float-end"
                                [afTranslate]="'reports.generated-reports'">INFORMES GENERADOS</p>
                        </div>
                    </div>
                    <div [ngShow]="report.toShow==='archived'">
                        <button class="folder__back-button float-start"
                            (click)="showElements('evaluated', report)">
                            <i class="lf-icon-arrow-back folder__arrow-back-icon"></i>
                            <span class="folder__arrow-back-text"
                                [afTranslate]="'concept-list.archive-exit'">
                            </span>
                        </button>
                        <button class="float-end lf-icon-archive-empty folder__back-button folder__back-button--archived"
                            (click)="deleteArchived(report)"
                            matTooltip="{{'folder.empty-file' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below">
                        </button>
                        <p [ngShow]="report.archivedAmount"
                            class="folder__concept-archived-amount float-end">
                            {{report.archivedAmount}} <span class="span__auto-ajust" [afTranslate]="report.archivedAmount===1?'concept-list.report-archive':'concept-list.reports-archives'"></span>
                        </p>
                    </div>
                </li>
            </ul>

            <div class="concept-list__table-config"
                [ngShow]="report.open && !(skin === 'icam-red' && report.predefined)"
                [ngClass]="{'concept-list__table-config--report-height':!report.reportObjects.length && report.archivedAmount && report.toShow!=='archived'}">
                <concept-list-default *ngIf="report.reportObjects.length || report.archivedAmount"
                    [conceptInfo]="report"
                    [evaluation]="evaluationInfo"
                    [toShow]="report.toShow"
                    [archivedAmount]="report.archivedAmount"
                    (showElements)="showElements($event, report)"
                    (evaluateConcept)="openReportOn($event, report)">
                </concept-list-default>
                <div class="folder__no-concept-block"
                    [ngShow]="!report.reportObjects.length && !report.archivedAmount">
                    <p class="folder__no-concept-text"
                        [afTranslate]="'reports.no-generated-reports'">No hay informes generados</p>
                </div>
            </div>
        </div>
    </div>
</div>
