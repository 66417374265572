import { Component, OnInit, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'new-custom-report-configuration',
  templateUrl: './new-custom-report-configuration.component.html'
})
export class NewCustomReportConfigurationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  newCustomReportConfiguration:any = {
    reportFieldNameWordFilter: '',
    newCustomReportState: 0,
    productNameOrder: 'reportFieldName',
    orderOperationProductName: false,
    filters: ['isCustom'],
    itemsSelected: [],
    availableReportFieldList: [],
    infoError: this.translate.instant('new-custom-report.two-columns-alert-text'),
    isCustomFilter: {
        name: this.translate.instant('custom-report-field-list.header-table.source'),
        id: this.commonUtilsService.createId()
    },
    customReportNameField: {
        label: this.translate.instant('new-custom-report.report-name'),
        id: 'customReportName',
        required: true
    },
    identityInfo: {
        type: 'no-margin',
        fieldTypeId: 'rich',
        isHighlight: true,
        label: this.translate.instant('new-custom-report.report-fields'),
        description: this.translate.instant('new-custom-report.report-fields-text')
    },
    productFieldInfo: {
        fieldId: 'productId',
        label: '',
        description: null,
        required: true,
        noEditable: false
    }
  };
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private broadcast:BroadcastService,
    private loader:LoaderFactory,
    private modalService:ModalServiceService,
    private filterColumnService:FilterColumnService,
    private commonUtilsService:CommonUtilsService,
  ) {
  }

  @Input() newCustomReport:any;
  @Input() validationErrors:any;

  ngOnInit(): void {
    this.watchClearForm();
    let that = this.newCustomReportConfiguration;
    that.productId = that.selectedProductId;
    that.productFieldInfo.noEditable = that.selectedProductId !== '' && that.selectedProductId !== null && that.selectedProductId !== undefined;
    this.getReportFields();
  }

  watchClearForm() {
    let that = this;
    that.broadcast.broadcast('clearForm', (event:any, data:any) => {
        that.newCustomReport.selectedReportFields.forEach((el:any) => { el.selected = true; });
        this.removeReportFields();
    });
  }

  removeReportFields() {
    let that = this.newCustomReportConfiguration;
    let elements = this.newCustomReport.selectedReportFields.filter((element:any) => { return element.selected === true });

    this.newCustomReport.selectedReportFields = this.newCustomReport.selectedReportFields.filter((element:any) => { return !element.selected });
    if (elements) {
        elements.forEach((el:any) => { el.selected = false; });
        that.availableReportFieldList = that.availableReportFieldList.concat(elements);
    }

    this.filter();
  }

  filter(filterType?:any) {
    let that = this.newCustomReportConfiguration;
    let filterList = that.availableReportFieldList;
    if (that.reportFieldNameWordFilter || filterType === 'reportFieldName') {
        filterList = this.filterColumnService.filterByWord(that.reportFieldNameWordFilter, filterList, 'reportFieldName');
    }

    for (let i = 0; i < that.filters.length; i++) {
        let filter = that.filters[i];
        let filteredList = that[filter + 'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
    }

    that.filterAvailableReportFieldList = filterList;
  }

  getReportFields() {
    let that = this.newCustomReportConfiguration;
    this.loader.openLoader('new-custom-report-configuration');
    this.apiService.get('customreportfields/simplified?productId=' + this.newCustomReport.productId).then(
      (data:any) => {
        // se quitan los seleccionados de la parte de los disponibles
        if (this.newCustomReport.selectedReportFields.length > 0) {
            this.newCustomReport.selectedReportFields.forEach((el:any) => {
                data = data.filter((dataElement:any) => {
                    return dataElement.reportFieldId !== el.reportFieldId;
                });
            });
        }
        that.filterAvailableReportFieldList = data;
        that.availableReportFieldList = data;
        this.getFilters();
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
      this.loader.closeLoader('new-custom-report-configuration');
    });
  }

  getFilters() {
    let that = this.newCustomReportConfiguration;
    for (let i = 0; i < that.filters.length; i++) {
        let filter = that.filters[i];
        that[filter + 'List'] = this.filterColumnService.extractList(filter, that.availableReportFieldList)
        that[filter + 'ListFilter'] = [];

        if (filter === 'isCustom') {
            for (let j = 0; j < that.isCustomList.length; j++) {
                if (that.isCustomList[j].name === 0) {
                    that.isCustomList[j].nameTranslate = this.translate.instant('custom-report-field-list.predefined-report-fields');
                } else if (that.isCustomList[j].name === 1) {
                    that.isCustomList[j].nameTranslate = this.translate.instant('custom-report-field-list.custom-report-fields');
                }
            }
        }
    }
  }

  selectTypeOperation(item:any, type:string) {
    let that = this.newCustomReportConfiguration;
    let listFilter = that[type + 'ListFilter'];
    that[type + 'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
    item.selected = !item.selected;
    if (that[type + 'Filter']) {
        that[type + 'Filter'].hasFilter = that[type + 'ListFilter'].length;
    }
    this.filter();
  }

  orderProductName(type:string) {
    let that = this.newCustomReportConfiguration;
    if (that.productNameOrder === type) {
        that.orderOperationProductName = !that.orderOperationProductName;
    } else {
        that.productNameOrder = type;
        that.orderOperationProductName = false;
    }
  }

  resetFilter(e:any, filterType:string) {
    e.stopPropagation();
    e.preventDefault();
    if (filterType === 'reportFieldName') {
        this.newCustomReportConfiguration.reportFieldNameWordFilter = '';
    }
    this.filter(filterType);
  }

  showFieldAssignments(event:any, reportField:any) {
    event.preventDefault();

    let url = 'customreportfields/getcustomreportfieldassignments/';
    if (!reportField.isCustom) {
        url = 'customreportfields/getreportfieldassignments/'
    }

    this.loader.openLoader('field-assignments');
    this.apiService.get(url + reportField.reportFieldId).then(
      (data:any) => {
          this.modalService.fieldAssignments(data);
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
      this.loader.closeLoader('field-assignments');
    });

    event.stopPropagation();
  }

  addReportFields() {
    let that = this.newCustomReportConfiguration;
    let elements = that.availableReportFieldList.filter((element:any) => { return element.selected === true });
    that.availableReportFieldList = that.availableReportFieldList.filter((element:any) => { return !element.selected });
    if (elements) {
        elements.forEach((el:any) => { el.selected = false })
        this.newCustomReport.selectedReportFields = this.newCustomReport.selectedReportFields.concat(elements);
    }

    this.filter();
  }

  getSelectedItemsIncluding(list: any, item: any) {
      item.selected = true;
      return list.filter((item:any) => { return item.selected === true });
  }
}
