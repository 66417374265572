import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'field-list',
  templateUrl: './field-list.component.html'
})
export class FieldListComponent implements OnInit, OnChanges {
  skin:any = this.globalCfg.skin;
  init: boolean = false;
  pollInit: boolean = false;
  fieldList:any = {
    originList: []
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory) {}
  @Input() public: boolean = false;
  @Input() concept: any = null;
  @Input() poll: any = null;

  ngOnInit(): void {
    this.getFields();
  }

  ngOnChanges(): void {
    if(this.pollInit !== this.poll && this.init){
      this.getList();
    }
  }

  getFields(){
    this.init = true;
    this.apiService.get('fieldtypes')
        .then((data:any)=>{
            this.fieldList.originList = data;
            this.getList();
        }, (errorData:any)=>{})
  }

  getList(){
    let list : any = cloneDeep(this.fieldList.originList);
    for(let i = list.length-1; i >= 0; i--){
      let fieldTypeId: any = list[i].fieldTypeId
        if(this.concept.poll && (
            fieldTypeId === 'l-co'
            || fieldTypeId === 'formu'
            || fieldTypeId === 'formp'
            || fieldTypeId === 'file'
            || fieldTypeId === 'user'
            || fieldTypeId === 'templ'
            || fieldTypeId === 'alert'
            || fieldTypeId === 'task'
        )){
            list.splice(i, 1);
        }
        if(list[i]){
            this.getIcons(list[i]);
        }

    }
    this.fieldList.fieldList = list;
    this.pollInit = this.poll;
  }

  getIcons(data:any){
    switch(data.fieldTypeId){
        case 'text':
            data.icon = "lf-icon-text-field";
            data.title = this.translate.instant('field-list.text')
            break;
        case 'rich':
            data.icon = "lf-icon-text-edit";
            data.title = this.translate.instant('field-list.rich');
            break;
        case 'list':
            data.icon = "lf-icon-dropdown";
            data.title =  this.translate.instant('field-list.list');
            break;
        case 'listp':
            data.icon = "lf-icon-dropdown-weight";
            data.title =  this.translate.instant('field-list.listp');
            break;
        case 'l-co':
            data.icon = "lf-icon-connected-data";
            data.title = this.translate.instant('field-list.list-relationship-otherform');
            break;
        case 'num':
            data.icon = "lf-icon-hashtag";
            data.title = this.translate.instant('field-list.num');
            break;
        case 'date':
            data.icon = "lf-icon-calendar";
            data.title = this.translate.instant('common.date');
            break;
        case 'bool':
            data.icon = "lf-icon-switch-right";
            data.title = this.translate.instant('field-list.question-yes-no');
            break;
        case 'boolp':
            data.icon = "lf-icon-switch-right-weight";
            data.title = this.translate.instant('field-list.question-boolp');
            break;
        case 'formu':
            data.icon = "lf-icon-calculator";
            data.title = this.translate.instant('field-list.formu');
            break;
        case 'formp':
            data.icon = "lf-icon-calculator-weight";
            data.title = this.translate.instant('field-list.formp');
            break;
        case 'file':
            data.icon = "lf-icon-up-file";
            data.title = this.translate.instant('field-list.up-file');
            break;
        case 'money':
            data.icon = "lf-icon-coin";
            data.title = this.translate.instant('field-list.money');
            break;
        case 'user':
            data.icon = "lf-icon-user";
            data.title =  this.translate.instant('common.users-contacts');
            break;
        case 'txchk':
            data.icon = "lf-icon-check-empty";
            data.title =this.translate.instant('common.text-check');
            break;
        case 'templ':
            data.icon = "lf-icon-textbooks";
            data.title = this.translate.instant('field-list.button-generate-document');
            break;
        case 'alert':
            data.icon = "lf-icon-notification"
            data.title = this.translate.instant('field-list.button-create-alert');
            break;
        case 'task':
            data.icon = "lf-icon-task";
            data.title = this.translate.instant('field-list.button-create-task');
            break;
    }
  }


}
