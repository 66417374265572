import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RelatedVisibilityFieldsFactory {
  additionalFields:any = [];
  add(listFields: any, lcoFieldId:any, numSubContainer: any){
    listFields.forEach((list: any) => {
        list.lcoFieldId = lcoFieldId
        list.numSubContainer = numSubContainer
        this.additionalFields.push(list)
    });

  }

  remove(fromFieldId: string){
    for(let i = this.additionalFields.length -1; i >= 0; i--){
        let additionalField = this.additionalFields[i];
        if(additionalField.fromFieldId === fromFieldId){
            this.additionalFields.splice(i, 1);
        }
    }
  }

  find(field: any){
    let additionalField = this.additionalFields.filter((item:any)=>item.fieldId === field.additionalFieldId && item.lcoFieldId === field.lcoFieldId && item.numSubContainer === field.numSubContainer)[0];
    return {
      fieldId: additionalField.lcoFieldId,
      fieldTypeId: 'l-co',
      additionalField: additionalField
    }
  }

  clear(field: any, numSubContainer: any) {
    let index = this.additionalFields.findIndex((item: any)=>{return item.lcoFieldId === field.fieldId  && item.numSubContainer === numSubContainer})
    this.additionalFields.splice(index, 1);
  }

}
