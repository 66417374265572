import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { map } from 'lodash';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';

@Component({
  selector: 'share-instance',
  templateUrl: './share-instance.component.html'
})
export class ShareInstanceComponent implements OnInit {
  skin:any = this.globalCfg.skin;
  shareInstance: any = {
    buttonAccept: this.translate.instant('common.continue'),
    shareTitle: this.translate.instant('share-instance.question-title'),
    title: this.translate.instant('concept-tab.share-concept'),
    contactsType: "share-instance",
    message: '',
    step: 1,
    showText: true,
    tinyMceOptions: {
        height: 187,
        placeholder: this.translate.instant('common.add-comment'),
        toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | bullist numlist outdent indent | removeformat | link'
    },
     shareTypeDocumentField: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('share-document.type-share-label')
    },
    shareDocumentationField: {
      label: this.translate.instant('share-document.restricted-select'),
      multiple: true,
      required: true,

    }
  }
  constructor(private translate: AfTranslateFactory,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService) { }

  @Input() concept: any = null;
  @Input() existingTask: any = null;
  @Input() from: any = null;


  ngOnInit(): void {
    this.shareInstance.optionSelect = this.existingTask? 'query' : 'evaluation';
    this.configureShareFieldContainer();
    this.shareInstance.queryProduct = Boolean(this.concept.productId === "aml002")
    this.shareInstance.shareInstanceContact = {
      label: this.from==='knowledge'?this.translate.instant('common.users-contacts'):this.translate.instant('contacts.contacts'),
      id:"share-instance-contact",
      typeConfiguration: this.from==='knowledge'?'share-field':'share-instance',
      fieldType: 'multiple',
      addContacts: Boolean(this.concept.productId !== "aml002"),
      required: this.from==='knowledge'?false:true,
      typeCall: this.from==='knowledge'?'contacts-users-only':'contacts-only',
      callEvaluationTaskShares: this.from==='knowledge'?false:true,
      selectContacts: []
    }
    if(this.from==='knowledge'){
        this.configureShareKnowledgeField();
    }
    if(this.concept && this.existingTask){
        this.hasEvaluationTaskRepeat();
    }
  }

  hasEvaluationTaskRepeat(){
      this.apiService.get('conceptobjects/'+this.concept.conceptObjectId+'/has-active-repeat-pattern/task-type','evaluation')
      .then((data:any)=>{

        let text:string = data.hasActiveRepeatPattern? 'share-instance.has-repeat' : 'concept-tab.share-concept-no-create'
        this.shareInstance.evaluationErrorMessage = this.translate.instant(text)

      }, (errorData:any)=>{})
  }

  configureShareKnowledgeField(){
      this.shareInstance.step = 2;
      this.shareInstance.shareTitle = this.translate.instant('share-instance.share-field-info');
      this.shareInstance.title = this.translate.instant('share-instance.share-field-title');
      this.shareInstance.shareInstanceContact.selectContactsType = "share-field";
  }

  continueOptions(){
      if(this.shareInstance.optionSelect === 'evaluation'){
          this.handleAccept();
      }else if(this.shareInstance.step === 1){
        this.shareInstance.step = 2;
        this.shareInstance.shareTitle = this.translate.instant('share-instance.share-concept-info');
      }else{
        this.shareInstance.shareInstanceContact.selectContacts && this.shareInstance.shareInstanceContact.selectContacts.length? this.handleAccept() : this.shareInstance.shareInstanceContact.error = true
      }
  }

  receiveContacts(contacts:any){
    this.shareInstance.shareInstanceContact.selectContacts = contacts;
    this.shareInstance.shareInstanceContact.error = !!(contacts.length);
  }

  contactsClosed() {
      this.shareInstance.showText = !this.shareInstance.showText;
  }

  getdestinations(){
      if(this.shareInstance.shareInstanceContact.selectContacts){
          let contacts:any = [];
          this.shareInstance.shareInstanceContact.selectContacts.forEach((contact:any) => {
            let id: string = contact.contactId? 'contactId' : 'userId';
            contacts.push({
              [id]: contact[id]
            });
          });
          let shared:any = {
              persons: contacts,
              comments: this.shareInstance.message
          }
          return shared;
      }
  }

  knowledgeDestination(){
      let contacts:any = [];
      this.shareInstance.shareInstanceContact.selectContacts.forEach((contact:any) => {
        let id: string = contact.contactId? 'contactId' : 'userId';
        contacts.push({
          [id]: contact[id],
          comment: this.shareInstance.message
        });
      });
      return contacts;
  }





  configureShared(){
    if(this.shareInstance.optionSelect==='query'){
      return this.getConceptShares()
    }else{
      return this.from ==='knowledge'? this.knowledgeDestination() : this.getdestinations()
    }

  }


  nextStep(){
    if(this.shareInstance.shareInstanceContact.selectContacts.length){
      this.shareInstance.wizardState = 2;
      this.shareInstance.shareTitle = this.translate.instant('share-document.share-info-document')
    }else{
      this.shareInstance.shareInstanceContact.error = true;
    }
  }

  backStep(){
     this.shareInstance.wizardState = 1;
     this.shareInstance.shareTitle = this.translate.instant('share-instance.share-concept-info');
  }

  resetItems(){
    if(this.shareInstance.wizardState === 1){
      this.broadcastService.broadcast('resetFieldContact', {});
    }else{
      this.shareInstance.shareInstanceContact.selectContacts = [];
      this.shareInstance.wizardState = 1;
    }
    this.shareInstance.message = '';
    this.shareInstance.shareDocumentationListSelected = '';
    this.shareInstance.typeShareDocumentSelected='0';
  }

  configureShareFieldContainer(){
    this.shareInstance.wizardState = 1;
    this.shareInstance.shareDocumentationListSelected = '';
    this.shareInstance.typeShareDocumentSelected='0'
    this.shareInstance.steps = [{
        name:this.translate.instant('task-list.addressee'),
        noEditable:false
      },{
        name:this.translate.instant('common.documentation'),
        noEditable:false
    }]

    this.shareInstance.typeShareDocument = [
      {
        listValueId:'0',
        text: this.translate.instant('share-document.no-share-documents')
      },{
        listValueId:'1',
        text: this.translate.instant('share-document.share-all-documents')
      },{
        listValueId:'2',
        text: this.translate.instant('share-document.share-selected-documents')
      }
    ]

    this.apiService.get('documentobjects/allfilesids', this.concept.conceptObjectId)
      .then((data:any)=>{
        this.shareInstance.shareDocumentationList = this.commonUtilsService.editList(data, 'documentObjectId', 'name');
      })
  }

  selectSharedDocumentation(e:any){
    this.shareInstance.shareDocumentationListSelected = e.newValue;
  }

  getConceptShares() {
    let shares:any = {
        comments:  this.shareInstance.message,
        persons: map( this.shareInstance.shareInstanceContact.selectContacts, this.mapDestination)
    };

    shares.documentsShareType = parseInt(this.shareInstance.typeShareDocumentSelected);
    if(shares.documentsShareType === 2){
      shares.documentObjects = this.extractDocumentObjects()
    }

    return shares;
  }

  mapDestination(destination:any) {
    let mapped:any = {};
    if (destination.groupId) {
      mapped.groupId = destination.groupId;
    } else if (destination.userId){
      mapped.userId = destination.userId;
    } else if (destination.contactId){
      mapped.contactId = destination.contactId;
    } else if (destination.productRoleId){
      mapped.productRoleId = destination.productRoleId;
    }
    return mapped;
  }

  checkOptions(){
    this.shareInstance.shareDocumentationField.error = false;
    if(this.shareInstance.typeShareDocumentSelected !== '2'  || (this.shareInstance.typeShareDocumentSelected === '2' && this.shareInstance.shareDocumentationListSelected !== '')){
      this.handleAccept()
    }else{
      if(this.shareInstance.shareDocumentationListSelected === ''){
        this.shareInstance.shareDocumentationField.error = true;
      }
    }
  }

  extractDocumentObjects(){
    let arrayDocuments:any = [];
    let documentsSelected: any = this.shareInstance.shareDocumentationListSelected.indexOf('|') === -1? [this.shareInstance.shareDocumentationListSelected] : this.shareInstance.shareDocumentationListSelected.split('|');
    documentsSelected.forEach((document:any)=>{
      arrayDocuments.push(parseInt(document))
    })
    return arrayDocuments;
  }

  handleAccept() {
    this.activeModal.close({
        result: 'ok',
        option: this.shareInstance.optionSelect,
        shared: this.configureShared()
    })
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
