import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'field-type-consult-lists',
  templateUrl: './field-type-consult-lists.component.html'
})
export class FieldTypeConsultListsComponent implements OnChanges {
  fieldTypeConsultLists: any = {}
  constructor(private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private documentActions: DocumentActionsService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory) { }
  @Input() field: any = null;
  @Input() concept: any = null;
  @Input() fieldContainer: any = null;
  @Output() itemSelected = new EventEmitter();

  ngOnChanges(): void {
    if(this.field?.description){
      this.field.description = this.commonUtilsService.checkAndDeletePreTag(this.field.description);
    }
  }

  generateReportPdf(){
      this.loader.openLoader('check-field-container-report-list');
      this.apiService.get('utils/pem/mappings/fieldcontainer/'+this.fieldContainer.iD_Version0)
        .then((data:any)=> {
            let configuration: any = {
                personData: {},
                concept: this.concept,
                fieldContainer: this.fieldContainer,
                field: this.field,
                typePerson: data[this.field.iD_Version0]
            }
            let errorLegalForm = false;
            for(let i = 0; i< this.fieldContainer.fields.length; i++){
                let field = this.fieldContainer.fields[i];
                if(data[field.iD_Version0]){
                  if(data[field.iD_Version0] === 'LegalForm'){
                    if(field.evaluatedValue.floatvalue){
                            for(let j = 0; j< field.listValues.length; j++){
                                if(field.listValues[j].listValueId === field.evaluatedValue.floatvalue){
                                    configuration.typePerson = field.listValues[j].numOrder===1?'natural':'artifitial';
                                    break;
                                }
                            }
                        }else{
                            field.error = true;
                            errorLegalForm = true;
                            break;
                        }
                    }else {
                      if(!configuration.typePerson){
                          if(data[field.iD_Version0] === 'socialReason'){
                              configuration.typePerson = 'artifitial';
                          }else if(data[field.iD_Version0] === 'name'){
                              configuration.typePerson = 'natural';
                          }
                      }
                      if(field.error && !field.required && !field.blocker && field.evaluatedValue?.default){
                          field.error = false;
                      }
                      switch(field.fieldTypeId){
                          case 'list':
                          case 'listp':
                              if(field.evaluatedValue?.listValues && field.evaluatedValue?.floatvalue){
                                  configuration.personData[data[field.iD_Version0]] = null;
                                  for(let j = 0; j< field.evaluatedValue.listValues.length; j++){
                                      let listValue : any = field.evaluatedValue.listValues[j];
                                      if(listValue.listValueId === field.evaluatedValue.floatvalue){
                                          configuration.personData[data[field.iD_Version0]] = listValue.text;
                                          break;
                                      }
                                  }
                              }else if(!configuration.personData[data[field.iD_Version0]]){
                                  configuration.personData[data[field.iD_Version0]] = null;
                              }
                              break;
                          case 'date':
                              configuration.personData[data[field.iD_Version0]] = field.evaluatedValue?.datetimeValue?field.evaluatedValue?.datetimeValue:null;
                              break;
                          default:
                              configuration.personData[data[field.iD_Version0]] = field.evaluatedValue?.nvarcharValue?field.evaluatedValue?.nvarcharValue:null;
                              break;
                      }
                    }
                  }
            };
            if(!errorLegalForm){
                let mappingContainer = Object.keys(data).map((key) => [key, data[key]]);
                this.addDataPerson(configuration, mappingContainer);
            }else{
                this.loader.closeLoader(['check-field-container-report-list']);
                this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-specific'), 'error:accept-link:warning:consult-list');
            }

        }, (errorData:any)=> {
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-unexpected'), 'error:accept:warning');
        });
  }

  addDataPerson(configuration: any, mappingContainer: any){
    this.apiService.add('utils/pem/check-sanctions/'+ configuration.typePerson+'/fieldcontainer/'+this.fieldContainer.iD_Version0+'?responseType=FULL', configuration.personData)
      .then((data:any)=> {
          configuration.infoModal = data[0];
          configuration.infoModal.detections = data[0].detections?data[0].detections:[];
          configuration.infoModal.provider = 'FACTIVA';
          this.loader.closeLoader(['check-field-container-report-list']);
          this.modalService.consultListsModal(configuration)
            .subscribe((result:any)=>{
              if(result.result==='ok'){
                this.generatePdf(configuration);
              }
            })
      }, (errorData:any)=> {
          this.loader.closeError();
          if(errorData.status === 403 && errorData.description === 'NoPemAccess'){
            // Caso si no tiene el área 57 asociada
            this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-area-57'), 'error:accept:warning');
          }else if(errorData.status === 409 && errorData.description === 'NoPBCCredits'){
            // Caso se han agotado las consultas contratadas
            this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-contracted-consultations'), 'error:accept:warning');
          }else{
            let endErrorPosition = errorData.description.indexOf('\r\n');
            let errorDataDescription = errorData.description.substring(0, endErrorPosition);
            let errorsList = errorDataDescription.split(',');
            let listFieldsError = [];
            errorsList.forEach((listError: any) => {
              listError = Number(listError.substring(0, 4));
              if(listError){
                  let errorFrom : any = this.errorsType(listError);
                  listFieldsError.push(errorFrom);
                  this.getIdFieldsError(mappingContainer, errorFrom);
              }
            });
            if(listFieldsError.length){
                // Validación que hace el servicio
                this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-specific'), 'error:accept-link:warning:consult-list');
            }else{
                // Otros errores
                this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.error-unexpected'), 'error:accept:warning');
            }
          }
      });
  }

  errorsType(error:any){
      switch(error){
          case 2000:
              return 'personCode';
          case 2001:
              return 'documentType';
          case 2002:
          case 2102:
          case 2202:
              return 'documentNumber';
          case 2003:
          case 2203:
              return 'documentCountry';
          case 2004:
          case 2104:
          case 2204:
              return 'name';
          case 2005:
          case 2105:
          case 2205:
              return 'surname1';
          case 2006:
          case 2206:
              return 'nationality';
          case 2007:
          case 2207:
              return 'bornDate';
          case 2008:
          case 2108:
              return 'socialReason';
          case 2010:
          case 2210:
              return 'fundationDate';
          case 2011:
          case 2211:
              return 'socialCountry';
          case 2112:
          case 2212:
              return 'surname2';
          default:
            return '';
      }
  }

  getIdFieldsError(mappingContainer: any, errorFrom: any){
      mappingContainer.forEach((container: any) => {
          if(container[1] === errorFrom){
              this.addErrorToField(Number(container[0]))
          }
      });
  }

  addErrorToField(idVersion0: any){
      for(let i = 0; i< this.fieldContainer.fields.length; i++){
          let field : any = this.fieldContainer.fields[i];
          if(field.iD_Version0 === idVersion0){
              field.error = true;
              break;
          }
      }
  }

  generatePdf(configuration: any){
      let itemSelected : any = {
          type: 'generate-consult-list-pdf',
          item: configuration,
          conceptObjectId: this.concept.conceptObjectId,
          fieldId: this.field.fieldId
      }
      this.modalService.informativeLoadingModal(itemSelected)
        .subscribe((result:any)=>{
          if(result.result !== 'cancel'){
            this.concept.documentation.documents.push(result.item);
            this.concept.documentation.totalDocuments++;
            this.field.evaluatedValue.documents.splice(this.field.evaluatedValue.documents.length, 0, result.item);
            this.commonUtilsService.addDisplayDataToField(this.field, result.item);
            this.modalService.adviceModal(this.translate.instant('field-type-consult-lists.generate-report-ok'), 'info:accept:check-round');
          }
        })
  }

  downloadDocument(documentSelected:any){
      this.documentActions.doAction('download', documentSelected);
  }

  removeDocument(documentSelected:any){
      if(documentSelected.documentStatusId && documentSelected.documentStatusId === 3){
        this.modalService.adviceModal(this.translate.instant('documentation-list.pending-signature-error'), 'error:accept:warning');
      }else{
        this.openQuestionDeletedItem(documentSelected);
      }
  }


  openQuestionDeletedItem(documentSelected:any){
      let type : any = 'info:deleteOption:question';
      let signatureOrCertification : any = documentSelected.documentStatusId === 3 || documentSelected.documentStatusId === 4 || documentSelected.documentStatusId === 6 || documentSelected.sendedAsCertifiedEmail || documentSelected.sendedAsCertifiedSMS;
      let text : any;
      if(signatureOrCertification){
          text = this.translate.instant('documentation-list.delete-signature-or-certifcation');
      }else if(documentSelected.publishedForApplicant){
          text = this.translate.instant('documentation-list.delete-published-for-applicant');
      }else{
          text = this.translate.instant('documentation-list.question-remove-document-definitely').replace('{document}', documentSelected.name);
      }

      this.modalService.adviceModal(text, type).subscribe((result:any)=>{
        if(result.result !== 'cancel'){
          this.loader.openLoader('field-type-template-delete-document');
          if(result.result === 'permanently-delete'){
              this.deleteDocumentSelected(documentSelected, 'delete');
          }else if(result.result === 'send-to-trash'){
              this.getDeletedDocuments(documentSelected);
          }
        }
      })
  }

  getDeletedDocuments(documentSelected:any){
      this.apiService.get('documentobjects/'+ this.concept.conceptObjectId +'/recyclebin')
          .then((data:any)=>{
              if(this.checkedName(data.documents, documentSelected, 'documentObjectId')){
                  this.deleteDocumentSelected(documentSelected, 'torecyclebin');
              }else{
                  this.loader.closeLoader('field-type-template-delete-document');
                  this.modalService.adviceModal(this.translate.instant('documentation-list.error-move-document').replace('{name}', documentSelected.name), 'error:accept:warning');
              }
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('common.delete-document-error').replace('{document}',  documentSelected.name);
              this.modalService.adviceModal(text, 'error:accept:warning');
      })
  }

  checkedName(list:any, item:any, type:any){
      for(let i = 0; i< list.length; i++){
          if(list[i][type] && list[i].name === item.name){
              return false;
          }
      }
      return true;
  }

  deleteDocumentSelected(documentSelected:any, type:string){
    let field: any = this.field;
      let recycle:string = type === 'torecyclebin'? type : '' ;
      this.apiService.delete('documentobjects', documentSelected.documentObjectId, recycle)
          .then((data:any)=>{
              let text: string = this.translate.instant('documentation-list.definited-deleted-ok').replace('definited-deleted-ok',documentSelected.name)
              if(type === 'torecyclebin'){
                  text = this.translate.instant('documentation-list.document-trash').replace('{document}',documentSelected.name);
              }
              documentSelected.id = documentSelected.documentObjectId;
              field.evaluatedValue.documents = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.documents, 'documentObjectId');
              field.evaluatedValue.displayData = this.commonUtilsService.deleteElement( documentSelected, field.evaluatedValue.displayData, 'id');
              let folder:any = this.getFolder(this.concept.documentation.subFolders, documentSelected.documentObjectId);
              if(folder){
                  folder.documents = this.commonUtilsService.deleteElement(documentSelected, folder.documents, 'documentObjectId');
              }else{
                  this.concept.documentation.documents = this.commonUtilsService.deleteElement( documentSelected, this.concept.documentation.documents, 'documentObjectId');
              }
              this.concept.documentation.totalDocuments--;
              let documentDeleted:any = documentSelected;
              documentDeleted.deleted = true;
              this.itemSelected.emit({newValue:documentDeleted});
              this.loader.closeLoader('field-type-template-delete-document');
              this.modalService.adviceModal(text, 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              let text: string = errorData.status === 403 && errorData.description === "delete-error-permissions"? this.translate.instant('concept-list.delete-error-permissions') : this.translate.instant('common.delete-document-error').replace('{document}',  documentSelected.name);
              this.modalService.adviceModal(text, 'error:accept:warning');
          })
  }

  getFolder(subFolders:any, documentObjectIdSelected:any){
      for(let i = 0; i< subFolders.length; i++){
          let folder:any = subFolders[i];
          for(let j = 0; j< folder.documents.length; j++){
              let documentFolder:any = folder.documents[j];
              if(documentFolder.documentObjectId === documentObjectIdSelected){
                  return folder;
              }
          }
          if(folder.subFolders){
              this.getFolder(folder.subFolders, documentObjectIdSelected);
          }
      }
  }
}
