import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'onboarding',
  templateUrl: './onboarding.component.html'
})
export class OnboardingComponent implements OnInit {
  skin: any = this.globalCfg.skin;
  typeUserPermissions : any = this.userPermisions.getTypePermissions();
  hasPermissions: boolean = this.typeUserPermissions === 'manage' || this.typeUserPermissions === 'admin'?true:false;
  onboarding: any = {
    clients: [],
    basicConfigurationList: [
        {
            type: 'organizations',
            order: 1,
            iconOrder: 'lf-icon-number-one',
            title: this.translate.instant('common.organizations'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-organizations'),
            icon: 'lf-icon-label-user',
            disabled: !this.hasPermissions
        },{
            type: 'users',
            order: 2,
            iconOrder: 'lf-icon-number-two',
            title: this.translate.instant('common.users-and-contacts'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-users'),
            icon: 'lf-icon-user-settings',
            disabled: true
        },{
            type: 'roles',
            order: 3,
            iconOrder: 'lf-icon-number-three',
            title: this.translate.instant('common.roles'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-roles'),
            icon: 'lf-icon-add-points',
            disabled: true
        }
    ],
    firstImplantationList: [
        {
            type: 'subject',
            order: 4,
            iconOrder: 'lf-icon-number-four',
            title: this.translate.instant('common.obligated-subject'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-obligated-subject'),
            icon: 'lf-icon-health',
            disabled: true
        },{
            type: 'client',
            order: 5,
            iconOrder: 'lf-icon-number-five',
            title: this.translate.instant('common.client'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-client'),
            icon: 'lf-icon-build',
            disabled: true,
            alertIcon: false,
            showHelpTooltip: false,
            keyTooltip: 'client-onboarding'
        },{
            type: 'operation',
            order: 6,
            iconOrder: 'lf-icon-number-six',
            title: this.translate.instant('anti-money-laundering-onboarding.operation'),
            description: this.translate.instant('anti-money-laundering-onboarding.description-operation'),
            icon: 'lf-icon-label-validate',
            disabled: true
        }
    ]
  }
  constructor(private router: Router,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private context: ContextFactory,
    private loader: LoaderFactory,
    private stateManagement: StateManagementFactory,
    private userPermisions: UserPermisionsFactory,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService) { }

  @Input() product: any = {};
  @Input() clientList: any = {};
  @Output() launchAction = new EventEmitter();
  ngOnInit(): void {
    this.configureList();
  }

  configureList(){
      if(this.hasPermissions && this.clientList && this.clientList.length){
          this.onboarding.basicConfigurationList[0].active = true;
          this.onboarding.basicConfigurationList[1].disabled = false;
          this.loadContacts();
      }else{
          this.onboarding.charged = true;
      }
  }

  loadContacts(){
    this.apiService.get('contacts/access/admin')
      .then((data: any)=> {
        let contacts = data;
        if(contacts.length){
            this.getProductRoles();
        }else{
            this.loadUsers();
        }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  loadUsers() {
    this.apiService.get('frontusers')
      .then((data: any)=> {
        if(this.isNoAdminUser(data)){
          this.onboarding.basicConfigurationList[1].active = true;
          this.onboarding.basicConfigurationList[2].disabled = false;
          this.getProductRoles();
        }else{
            this.onboarding.charged = true;
        }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  isNoAdminUser(usersList: any){
      for(let i = usersList.length-1; i >= 0; i--){
          let user = usersList[i];
          if (!user.isAdmin) {
              return true;
          }
      }
      return false;
  }

  getProductRoles(){
    this.onboarding.basicConfigurationList[1].active = true;
    this.onboarding.basicConfigurationList[2].disabled = false;
    this.apiService.get('productroles/product/'+this.context.settings.defaultProduct+'/assignments')
      .then((data: any)=> {
        if(data && data.length){
            let roleConfigurated = 0;
            data.forEach((user: any) => {
              if((user.users && user.users.length) || (user.clientsUsers && user.clientsUsers.length)){
                  roleConfigurated++;
              }
            });
            if(roleConfigurated === data.length){
                this.onboarding.basicConfigurationList[2].active = true;
                this.onboarding.firstImplantationList[0].disabled = false;
                this.getConceptObject();
            }else{
                this.onboarding.charged = true;
            }
        }else{
            this.onboarding.charged = true;
        }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  getConceptObject(){
    this.apiService.get('conceptobjects/byproductid/'+this.context.settings.defaultProduct)
      .then((data: any)=> {
        if(data.saved){
                this.onboarding.firstImplantationList[0].active = true;
                this.onboarding.firstImplantationList[1].disabled = false;
                this.getClients();
        }else{
            this.onboarding.charged = true;
        }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  getClients(){
    this.apiService.get(this.context.settings.defaultProduct + '/clients')
      .then((data: any)=> {
          this.onboarding.clients = data;
          let validateClients = this.checkClients(this.onboarding.clients);
          if(validateClients){
              this.onboarding.firstImplantationList[1].active = true;
              this.onboarding.firstImplantationList[2].disabled = false;
              this.onboarding.charged = true;
          }else{
              this.onboarding.charged = true;
          }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  checkClients(data: any){
      for(let i = 0; i < data.length; i++){
          let client = data[i];
          if(client.idEstado === 1){
              return true;
          }
      }
      return false;
  }

  configureOption(optionSelected: any){
      switch(optionSelected.type){
          case 'organizations':
              this.goTo('organizations', 'open-list-organizations');
              break;
          case 'users':
              this.goTo('users', 'main-component');
              break;
          case 'roles':
              this.goTo('roles', 'open-roles-window');
              break;
          case 'subject':
              this.goTo('obligated-subject', 'open-new-concept-window');
              break;
          case 'client':
              if(this.onboarding.clients && this.onboarding.clients.length){
                  this.goTo('clients-tracking', 'open-clients-tracking-window');
              }else{
                  this.goTo('client-evaluation', 'new-client-evaluation');
              }
              break;
          case 'operation':
              this.createNewEvaluation();
              break;
          default:
              break;
      }
  }

  goTo(url: any, loaderOpen: any){
      this.loader.openLoader(loaderOpen);
      this.stateManagement.setStateUrl(window.location.pathname);
      this.router.navigate([url]);
  }

  createNewEvaluation(){
    let configuration = {
        type: 'new-blanqueo',
        selectedProduct: this.product,
        evaluation: {},
        clients: this.onboarding.clients
    }
    this.modalService.createNewEvaluation(configuration).subscribe((result:any)=>{
      if(result.result === 'ok'){
          this.loader.openLoader('dashboard-panel-create-evaluation');
          this.launchAction.emit({
            action: 'createEvaluation',
            result: result
          });
      }
    });
  }

  closeHelpTooltip(option: any){
      option.showHelpTooltip = !option.showHelpTooltip;
  }

}
