import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'role-assignment-modal',
  templateUrl: './role-assignment-modal.component.html'
})
export class RoleAssignmentModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  roleAssignmentModal: any = {
    clientsSelected: null,
    clientsListSelected: [],
    clientsList: []
    // organizationsField: {
    //     id: 'select-clients-roles',
    //     typeList: 'checkAll',
    //     label: this.translate.instant('role-assignment-modal.label-organizations'),
    //     noEditable: true,
    //     required: true,
    //     multiple: true,
    //     hiddenPill: true
    // }
  }
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal) { }
  @Input() configuration: any;

  ngOnInit(): void {
    this.roleAssignmentModal.clientsSelected = this.configuration.clientsSelected?this.configuration.clientsSelected:null;
    this.configureFields();
  }

  configureFields(){
    this.roleAssignmentModal.userRoleField = {
        typeConfiguration: 'user-role',
        label: this.configuration.field.label,
        description: this.configuration.field.description,
        addContacts: false,
        required: true,
        typeCall: 'users-only',
        usersWithRoles: this.configuration.field && this.configuration.field.selectContacts?this.configuration.field.selectContacts:null
    };
    this.roleAssignmentModal.organizationsField = {
        id: 'select-clients-roles',
        typeList: 'checkAll',
        label: this.translate.instant('role-assignment-modal.label-organizations'),
        noEditable: true,
        required: true,
        multiple: true,
        hiddenPill: true
    };
    if(this.configuration.user && this.configuration.user.userId){
        let userSelected = this.configuration.user;
        this.receiveContacts([userSelected]);
    }
  }

  receiveContacts(contacts: any){
      if(this.roleAssignmentModal.clientsSelected && (!contacts || (contacts && !contacts.length) || (contacts && this.roleAssignmentModal.userRoleField.selectContacts && this.roleAssignmentModal.userRoleField.selectContacts.length && (contacts[0].userId !== this.roleAssignmentModal.userRoleField.selectContacts[0].userId)))){
          this.broadcastService.broadcast('resetFieldTypeList');
      }
      this.roleAssignmentModal.userRoleField.selectContacts = contacts;
      this.roleAssignmentModal.clientsList = [];
      this.roleAssignmentModal.noEditable = true;
      if(contacts && contacts.length){
          this.roleAssignmentModal.userRoleField.error = false;
          this.loadOrganizations(contacts[0].userId);
      }
  }

  loadOrganizations(userId: any) {
    this.apiService.get('productroles/product/'+this.configuration.productId+'/user', userId + '/eligible-clients')
      .then((data:any)=>{
        if(data.clients.length){
            data.clients = data.clients.sort(this.orderListName);
        }
        if(data.allClients){
            let allOrganizations = {
                clientId: 'all',
                name: this.translate.instant('roles-list.all-organizations'),
            }
            data.clients.splice(0, 0, allOrganizations);
        }
        data.clients.forEach((client:any, index:any) => {
          client.numOrder = index+1;
        })
        this.roleAssignmentModal.clientsList = this.commonUtilsService.editList(data.clients, 'clientId', 'name');
        this.roleAssignmentModal.organizationsField.noEditable = false;
      }, (errorData:any)=>{})
  }

  orderListName(a:any, b:any) {
      if(a.name > b.name){
        return 1;
      }
      return a.name < b.name ? -1 : 0;
  }

  clientSelected(e:any){
      this.roleAssignmentModal.clientsSelected = e.newValue;
      this.roleAssignmentModal.clientsListSelected = e.listSelected;
      if(e.newValue){
          this.roleAssignmentModal.organizationsField.error = false;
      }
  }

  validateFields(){
      let errors = 0;
      if(!(this.roleAssignmentModal.userRoleField.selectContacts && this.roleAssignmentModal.userRoleField.selectContacts.length)){
          this.roleAssignmentModal.userRoleField.error = true;
          errors++;
      }
      if(!this.roleAssignmentModal.organizationsField.noEditable && !(this.roleAssignmentModal.clientsSelected && this.roleAssignmentModal.clientsSelected.length)){
          this.roleAssignmentModal.organizationsField.error = true;
          errors++;
      }
      if(!errors){
          this.handleAccept();
      }
  }

  handleAccept() {
    this.activeModal.close({
      result: 'ok',
      roleConfiguration: this.roleConfiguration()
    })
  };

  roleConfiguration(){
      return {
          user: this.roleAssignmentModal.userRoleField.selectContacts[0],
          clientIds: this.createListClientsIds(),
          clientsList: this.roleAssignmentModal.clientsListSelected
      }
  }

  createListClientsIds(){
      if(this.roleAssignmentModal.clientsSelected === 'all'){
          return null;
      }else if(this.roleAssignmentModal.clientsSelected.toString().indexOf('|') !==-1){
          return this.roleAssignmentModal.clientsSelected.split('|');
      }else{
          return [this.roleAssignmentModal.clientsSelected];
      }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  };

}
