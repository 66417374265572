<div class="advice-modal">
  <div [class]="'advice-modal__modal-body advice-modal__modal-body--'+adviceModal.typeModal">
      <div class="advice-modal__modal-icon-block">
          <i [class]="'advice-modal__modal-body-icon '+adviceModal.confirmQuestionIcon"></i>
      </div>
      <div class="advice-modal__modal-text-block"
        [ngClass]="{'advice-modal__modal-text-block--expand': expand}">
          <p class="advice-modal__modal-body-text"
            [ngClass]="{'advice-modal__modal-body-text--expand': expand}">
            <span *ngIf="adviceModal.typeText === 'normal'" [innerHTML]="questionText"></span>
            <span *ngIf="adviceModal.typeText === 'link'">
                <span [innerHTML]="adviceModal.firstQuestionText"></span>
                <span class="advice-modal__modal-body-text-link"
                  (click)="openInfoModal()">{{adviceModal.buttonLink}}</span>
                <span [innerHTML]="adviceModal.lastQuestionText"></span>
            </span>
          </p>
      </div>
      <div [ngSwitch]="adviceModal.modalQuestion">
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'question'">
              <button class="common__button common__button--grey"
                      [ngClass]="{'common__button--grey-red':adviceModal.typeModal==='error'}"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.no'">No</button>
              <button class="common__button common__button--white common__button--separating-margin"
                      [ngClass]="{'common__button--white-red common__button--white-red':adviceModal.typeModal==='error' }"
                      (click)="handleAccept()"
                      [afTranslate]="'common.yes'">Si</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'questionRecord'">
              <button class="common__button common__button--grey"
                      [ngClass]="{'common__button--grey-red':adviceModal.typeModal==='error'}"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">_cancelar</button>
              <button class="common__button common__button--white common__button--separating-margin"
                      [ngClass]="{'common__button--white-red common__button--white-red':adviceModal.typeModal==='error' }"
                      (click)="handleAccept()"
                      [afTranslate]="'record-audio-modal.new-record'">_nueva grabación</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'continue'">
              <button class="common__button common__button--white"
                  [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm'}"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.continue'">Continuar</button>
              <button class="common__button common__button--white common__button--separating-margin"
                  [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm'}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.exit'">Salir</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'finish'">
              <button class="common__button common__button--grey"
                  (click)="handleDismiss()"
                  [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm'}"
                  [afTranslate]="'common.return'">Volver</button>
              <button class="common__button common__button--white common__button--separating-margin"
                  [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm'}"
                  (click)="handleAccept()"
                  [afTranslate]="'common.finish'">Finalizar</button>
          </div>

          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'exitAll'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancel</button>
              <button class="common__button common__button--grey"
                      (click)="handleAccept('without-save')"
                      [afTranslate]="'common.discard-all-and-exit'">Descartar todo y salir</button>
              <button class="common__button common__button--white"
                      (click)="handleAccept('save-all')"
                      [afTranslate]="'common.save-all-and-exit'">Guardar todo y salir</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'exitUniqueConcept'">
              <button class="common__button common__button--grey"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">Cancelar</button>
              <button class="common__button common__button--grey"
                  (click)="handleAccept('discard-navegate')"
                  [afTranslate]="'common.discard-navegate'">Descartar y navegar</button>
              <button class="common__button common__button--white"
                  (click)="handleAccept('save-navegate')"
                  [afTranslate]="'common.save-navegate'">Guardar y navegar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'accept'">
              <button class="common__button common__button--white"
                      [ngClass]="{'advice-modal__button-green-confirm': adviceModal.typeModal==='confirm',
                        'common__button--white-red common__button--white-red':adviceModal.typeModal==='error'}"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.ok'">Aceptar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'cancelContinue'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancel</button>
              <button class="common__button common__button--white common__button--separating-margin"
                  (click)="handleAccept('continue')"
                  [afTranslate]="'common.continue'">Continuar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'acceptContinue'">
              <button class="common__button common__button--green"
                  (click)="handleAccept('continue')"
                  [afTranslate]="'common.ok'">Aceptar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'saveContinue'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancelar</button>
              <button class="common__button common__button--grey"
                      (click)="handleAccept('without-save')"
                      [afTranslate]="'common.continue-without-save'">Continuar sin guardar</button>
              <button class="common__button common__button--white"
                      (click)="handleAccept('save-and-continue')"
                      [afTranslate]="'common.save-and-continue'">Guardar y continuar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'onlySaveContinueAndCancel'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancelar</button>
              <button class="common__button common__button--white common__button--separating-margin"
                      (click)="handleAccept('save-and-continue')"
                      [afTranslate]="'common.save-and-continue'">Guardar y continuar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'deleteOption'">
              <button class="common__button common__button--grey"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">Cancelar</button>
              <button class="common__button common__button--white"
                  (click)="handleAccept('send-to-trash')"
                  [afTranslate]="'common.send-to-trash'">Enviar a papelera</button>
              <button class="common__button common__button--white"
                  (click)="handleAccept('permanently-delete')"
                  [afTranslate]="'common.remove'">Eliminar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'cancelRemove'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancel</button>
              <button class="common__button common__button--white common__button--separating-margin"
                  (click)="handleAccept('permanently-delete')"
                  [afTranslate]="'common.remove'">Eliminar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'questionDeleteClient'">
              <button class="common__button common__button--grey"
                  (click)="handleDismiss()"
                  [afTranslate]="'common.cancel'">Cancelar</button>
              <button class="common__button common__button--white"
                  (click)="handleAccept('discard-processes')"
                  [afTranslate]="'common.discard-processes'">Descartar proceso</button>
              <button class="common__button common__button--white"
                  (click)="handleAccept('remove')"
                  [afTranslate]="'common.remove'">Eliminar</button>
          </div>
          <div class="advice-modal__buttons-block"
              *ngSwitchCase="'reset'">
              <button class="common__button common__button--grey"
                      (click)="handleDismiss()"
                      [afTranslate]="'common.cancel'">Cancel</button>
              <button class="common__button common__button--white common__button--separating-margin"
                  (click)="handleAccept('continue')"
                  [afTranslate]="'common.reset'">Resetear</button>
          </div>
          <div *ngSwitchDefault>
              <div class="advice-modal__buttons-block"
                      *ngIf="adviceModal.modalQuestion === 'exit' || adviceModal.modalQuestion === 'exitTab'">
                      <button class="common__button common__button--grey"
                              (click)="handleDismiss()"
                              [afTranslate]="'common.cancel'">Cancelar</button>
                      <button class="common__button common__button--grey"
                              (click)="handleAccept('discard-exit')"
                              [afTranslate]="'common.discard-exit'">Descartar y salir</button>
                      <button class="common__button common__button--white"
                              [ngShow]="adviceModal.modalQuestion === 'exit'"
                              (click)="handleAccept('save-exit')"
                              [afTranslate]="'common.save-exit'">Guardar y salir</button>
                      <button class="common__button common__button--white"
                              [ngShow]="adviceModal.modalQuestion === 'exitTab'"
                              (click)="handleAccept('save-exit-tab')"
                              [afTranslate]="'common.save-exit'">Guardar y salir</button>
              </div>

              <div class="advice-modal__buttons-block"
                  *ngIf="adviceModal.modalQuestion === 'exitTemplate' || adviceModal.modalQuestion === 'exitTask'">
                  <button class="common__button common__button--grey"
                          (click)="handleDismiss()"
                          [afTranslate]="'common.cancel'">Cancelar</button>
                  <button class="common__button common__button--grey"
                          (click)="handleAccept('without-save')"
                          [afTranslate]="'common.continue-without-save'">Continuar sin guardar</button>
                  <button class="common__button common__button--white"
                      [ngShow]="adviceModal.modalQuestion === 'exitTemplate'"
                      (click)="handleAccept('generate-and-save')"
                      [afTranslate]="'common.save-and-generate'">Guardar y generar</button>
                  <button class="common__button common__button--white"
                      [ngShow]="adviceModal.modalQuestion === 'exitTask'"
                      (click)="handleAccept('save-and-share')"
                      [afTranslate]="'common.save-and-share'">Guardar y compartir</button>
              </div>
          </div>
      </div>
  </div>
  <div class="advice-modal__document-block"
      *ngIf="adviceModal.from==='file'">
      <p class="advice-modal__document-block-title"
          [afTranslate]="'common.fail-files-info'"></p>
          <ul class="upload-document__documentation-list upload-document__documentation-list--advice-modal">
              <li *ngFor="let document in list"
                  class="upload-document__documentation-item upload-document__documentation-item--advice">
                  <i [class]="(document.source | extensionDocument)+' upload-document__documentation-icon-type'"></i>
                  <dl class="upload-document__documentation-document-info">
                      <dt class="upload-document__documentation-document-name">{{document.name}}</dt>
                      <dd class="upload-document__documentation-document-size">{{document.size | convertBytes}}</dd>
                      <dd></dd>
                  </dl>
                  <p class="upload-document__progress-upload-text upload-document__progress-upload-text--error"
                      [ngShow]="document.status==='validate-error'">{{document.statusText}}</p>
                  <div class="upload-document__progress-upload-block upload-document__progress-upload-block--error"
                      [ngStyle]="{'width': '100%'}">
                      <div class="upload-document__progress-upload-line upload-document__progress-upload-line upload-document__progress-upload-line--error">
                      </div>
                      <p class="upload-document__progress-upload-text upload-document__progress-upload-text--error">
                          <span>{{document.statusText}}</span>
                      </p>
                  </div>
              </li>
          </ul>
  </div>
  <div class="users-admin-import__errors-block"
      *ngIf="(adviceModal.from==='contacts' || adviceModal.from==='concepts') && list.length">
      <div class="users-admin-import__errors-header">
          <p class="users-admin-import__errors-title">{{adviceModal.errorTranslate}}</p>
          <span [class]="'header-nav__icon-numeric-nav'">{{list.length}}</span>
      </div>
      <ul class="users-admin-import__error-list users-admin-import__error-list--modal">
          <li *ngFor="let contact in list; let i = index"
              [class]="'users-admin-import__error-item'">
              <p class="users-admin-import__contact-error-name">
                  <i class="users-admin-import__contact-icon users-admin-import__contact-icon--list-error"
                      [ngClass]="adviceModal.from === 'contacts'?'lf-icon-user':'lf-icon-compliance'"></i>
                  <span-tooltip [text]="contact.nameComplete"></span-tooltip>
              </p>
              <ul class="users-admin-import__contact-error-list">
                  <li *ngFor="let error in contact.errorList"
                      class="users-admin-import__contact-error-item">
                      <span class="users-admin-import__contact-error-item users-admin-import__contact-error-item--error"> {{error.errorType}}: </span>{{error.errorDescription}}
                  </li>
              </ul>
          </li>
      </ul>
  </div>
</div>

