import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';

@Injectable({
  providedIn: 'root'
})
export class TargetService {

  constructor(private context: ContextFactory, private route: ActivatedRoute) { }

  parseString(targetString: any) {
    let targetState: any;
    if (targetString) {
      targetString = decodeURIComponent(targetString);
      let targetTokens = targetString.split('?');
      targetState = {
          name: targetTokens[0]
      };
      if (targetTokens.length > 1 && targetTokens[1]) {
          let queryTokens = targetTokens[1].split('&');
          let params: any = {};
          queryTokens.forEach((queryToken: any) => {
            let paramItemTokens = queryToken.split(/=(.+)/);
            if (paramItemTokens.length > 1) {
                params[paramItemTokens[0]] = paramItemTokens[1];
            }
          });
          targetState.params = params;
      }
    }
    return targetState;
  }

  // getString(targetState: any) {
  //   let targetString: any;
  //   let stateName = this.route.snapshot.data['name'];
  //   if (stateName !== this.context.view.dashboard) {
  //       let params = this.route.snapshot.params;
  //       targetString = stateName;
  //       if (params !== null) {
  //           let paramsString = '';
  //           for (let k in params) {
  //               if (params.hasOwnProperty(k)) {
  //                   if (paramsString !== '') {
  //                       paramsString += '&';
  //                   }
  //                   paramsString += k + '=' + params[k];
  //               }
  //           }
  //           if (paramsString !== '') {
  //               targetString += '?' + paramsString;
  //           }
  //       }
  //       targetString = encodeURIComponent(targetString);
  //   }

  //   return targetString;
  // }
}
