<div class="menu-header-user menu-header-user">
  <div class="menu-header-user__header-nav">
      <i class="menu-header-comments__close-icon lf-icon-close"
              (click)="closeDropdown('closeMenu')"></i>
      <div class="menu-header-comments__title-header menu-header-comments__title-header menu-header-comments__title-header--notification">
          <span [afTranslate]="'common.user'">Usuario</span>
      </div>
  </div>

  <div class="menu-header-user__body-generic">
      <div class="menu-header-user__block-name-user">
          <p class="menu-header-user__name-user">{{menuHeaderUser.name}}</p>
          <p *ngIf="menuHeaderUser.company" class="menu-header-user__company-user">{{menuHeaderUser.company['name']}}</p>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="frontUser"
          (click)="goToOption('edit-basic')">
          <i class="menu-header-user__icon-list menu-header-user__icon-list- lf-icon-user-settings"></i>
          <span class="menu-header-user__text-list"
              [afTranslate]="'common.edit-user'">Editar datos básicos</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="menuHeaderUser.showResetPassword"
          (click)="resetPassword()">
          <i class="menu-header-user__icon-list lf-icon-lock"></i>
          <span class="menu-header-user__text-list"
              [afTranslate]="'common.reset-pass'">Resetear contraseña</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="frontUser && !administrationPermits"
          (click)="goToOption('see-security')">
          <i class="menu-header-user__icon-list lf-icon-key"></i>
          <span class="menu-header-user__text-list"
                [afTranslate]="'common.see-permissions'">Consultar Permisos</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="administrationPermits"
          (click)="goToOption('main')">
          <i class="menu-header-user__icon-list lf-icon-configuration"></i>
          <span class="menu-header-user__text-list"
              *ngIf="skin !== 'uk-steel-blue'"
              [afTranslate]="'common.management-users'">Gestión de usuarios</span>
          <span class="menu-header-user__text-list"
            *ngIf="skin === 'uk-steel-blue'"
            [afTranslate]="'common.management-user'">Gestión de usuarios</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="canCustomize"
          (click)="goToOption('global-customization')">
          <i class="menu-header-user__icon-list lf-icon-colour"></i>
          <span class="menu-header-user__text-list"
                [afTranslate]="'menu-header.global-customization'">Personalización global</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="frontUser && menuHeaderUser.goToMyAccount && skin != 'gm-law'"
          (click)="goMyAccount()">
          <i class="menu-header-user__icon-list lf-icon-tag-user"></i>
          <span class="menu-header-user__text-list"
                [afTranslate]="'menu-header.my-account'">Ir a mi cuenta</span>
      </div>
      <div class="menu-header-user__block-list"
          *ngIf="contextProperties.settings.productWithSnippets"
          (click)="goToOption('snippets')">
          <i class="menu-header-user__icon-list lf-icon-model"></i>
          <span class="menu-header-user__text-list"
              [afTranslate]="'menu-header.snippets-reusable'">Fragmentos reutilizables</span>
      </div>
  </div>
</div>
