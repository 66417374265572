<div class="input-number__days-box"
  [ngClass]="{'input-number__days-box--disabled':disabled}">
  <input type="text"
      [id]="'input-number-value-'+id"
      class="input-number__input-days"
      [ngModelOptions]="{standalone: true}"
      [(ngModel)]="inputNumber.value"
      validateNumber
      [from]="inputNumber.from"
      [until]="inputNumber.until"
      (change)="changeNumber()"
      [disabled]="disabled">
  <button class="input-number__amount-arrow input-number__amount-arrow--up "
      [ngClass]="{'input-number__amount-arrow--disabled': inputNumber.value === inputNumber.until || disabled}"
      (click)="addOrRemoveAmount('add')">
      <i class="lf-icon-angle-up"></i>
  </button>
  <button class="input-number__amount-arrow input-number__amount-arrow--down "
      [ngClass]="{'input-number__amount-arrow--disabled': inputNumber.value === inputNumber.from || disabled}"
      (click)="addOrRemoveAmount('remove')">
      <i class="lf-icon-angle-down"></i>
  </button>
</div>
