import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'report-consult-lists-pdf',
  templateUrl: './report-consult-lists-pdf.component.html'
})
export class ReportConsultListsPdfComponent implements OnInit {
  constructor(){

  }

  @Input() configuration: any;

  ngOnInit(): void {
    console.log(this.configuration)
  }
}
