import { Component, OnInit, Input, Output, OnChanges, EventEmitter, OnDestroy} from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { BoxCommentsParentService } from '../utils/box-comments-parent.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'box-comments-default',
  templateUrl: './box-comments-default.component.html'
})
export class BoxCommentsDefaultComponent implements OnInit, OnChanges, OnDestroy {
    subscribers: any = {};
    skin: string = this.globalCfg.skin
    userPermissions: any = this.permissions.getPermissions();
    boxComments: any = {
        showCommentBox: false,
        showCommentList: true,
        readMoreText: false,
        noComments: true,
        taskStateNoEditable: false,
        commentList: [],
        commentListFilter: [],
        fullScreen: false,
        hasPermissions: this.userPermissions !== 'none'
    }
  constructor(private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory,
    private broadcastService: BroadcastService,
    private boxCommentsParent: BoxCommentsParentService,
    private commonUtilsService: CommonUtilsService) {}

  @Input() conceptObjectId: any = null;
  @Input() taskId: any = null;
  @Input() taskState: any = null;
  @Input() fullScreenBox: any = null;
  @Input() blockClass: any = null;

  @Output() catchFullScreen = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.subscribers.addNewCommentBroadcast = this.broadcastService.subscribe('addNewComment', (data:any)=>{
      this.boxCommentsParent.createNewComment(data.commentText, this);
    })
  }

  ngOnChanges(){
    this.boxCommentsParent.onChanges(this);
  }

  newComment(){
    this.boxCommentsParent.newComment(this);
  }

  cancelComment(comment:any){
    this.boxCommentsParent.cancelComment(comment, this)
  }
  createNewComment(text:any){
    this.boxCommentsParent.createNewComment(text, this)
  }

  closeComment(comment:any = null){
    this.boxComments.showCommentBox = false;
    this.boxComments.showCommentList = true;
    if(comment !== null){
      comment.edit = false;
    }

  }
  maximizeScreen(keepClassBox:boolean = false){
    this.boxCommentsParent.maximizeScreen(keepClassBox, this)
  }

  deleteComment(comment:any){
    this.boxCommentsParent.deleteComment(comment, this)
  }

  editComment(comment:any){
    this.boxCommentsParent.editComment(comment, this)
  }

  changeComment(comment:any){
    this.boxCommentsParent.changeComment(comment, this)
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
