<div class="common__pdf-block-header"
  *ngIf="!newDesign">
  <div class="common__pdf-block-logo">
      <img class="common__pdf-logo-img"
          [src]="pdfHeader.logoHeader"
          alt="Logo header"/>
  </div>
  <div class="common__pdf-block-header-modified">
      <span [ngShow]="pdfInfo?.typePdf === 'report-consult-list'"
          class="common__pdf-header-more-info">
          <span [afTranslate]="'consult-lists-modal.proceedings'"></span>
          <span class="common__pdf-header-text-modified">{{pdfInfo.dossierId}}  </span>
      </span>
      <span>{{pdfHeader.headerModifiedText}}</span>
      <span class="common__pdf-header-text-modified">{{datePdf | convertDate:'all-with-second'}}</span>
  </div>
</div>
<div class="common__pdf-block-header-figma"
  *ngIf="newDesign">
  <div class="common__pdf-block-logo-figma">
      <img class="common__pdf-logo-img-figma"
          [src]="pdfHeader.logoHeader"
          alt="Logo header"/>
  </div>
  <div class="common__pdf-block-header-modified-figma">
      <!-- <span [ngShow]="pdfInfo?.typePdf === 'report-consult-list'"
          class="common__pdf-header-more-info">
          <span [afTranslate]="'consult-lists-modal.proceedings'"></span>
          <span class="common__pdf-header-text-modified">{{pdfInfo.dossierId}}  </span>
      </span> -->
      <span>{{pdfHeader.headerModifiedText}}</span>
      <span>{{datePdf | convertDate:'all-with-second'}}</span>
  </div>
</div>
