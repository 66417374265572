import { cloneDeep } from 'lodash';

import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';

import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';



@Component({
  selector: 'evaluations-list',
  templateUrl: './evaluations-list.component.html'
})
export class EvaluationsListComponent implements OnChanges, OnDestroy {

  skin:string = this.globalCfg.skin;
  dashboardVersion:any = parseInt(this.globalCfg.dashboardVersion);
  informativeModalSubject: any;
  renameModalSubject: any;
  evaluationShareSubject: any;
  duplicateEvaluationModalSubject: any;
  evaluationsList:any ={
    configuredTotal:{
      first: 1,
      last: 50
    },
    configPagination: {
      pageSize: 50,
      maxSize: 5
    },
    currentPage: 1,
    productId: '',
    search: false,
    searchEvaluation: '',
    showList: false,
    orderOperationDates: false,
    dateFilter: 'modified',
    title: this.translate.instant('common.implantations'),
    view: 'all',
    searchWord: ['name', 'clientName', 'result'],
    evaluations: [],
    evaluationView: [],
    sharedEvaluations: [],
    evaluationsListFilter: []
  }
  constructor(private router: Router,
    private translate: AfTranslateFactory,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private filterColumnService: FilterColumnService,
    private context: ContextFactory,
    private downloadService: DownloadService,
    private exportService: ExportServiceService,
    private dateConverterService: DateConverterService,
    private registerService: RegisterMixtapeService) { }

  @Input() isConcursalFormsModal: boolean = false;
  @Input() canCreate: boolean = false;
  @Input() canModify: boolean = false;
  @Input() productSelected: any;
  @Input() filteredEvaluations: any = [];
  @Input() deletedEvaluations: any = [];
  @Output() launchAction = new EventEmitter();
  @Output() copyEvaluation = new EventEmitter();
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown

  ngOnChanges(changes: SimpleChanges) {
    this.evaluationsList.evaluations = this.filteredEvaluations;
    if(this.productSelected?.productId){
        this.evaluationsList.productId = this.productSelected.productId;
        if(this.context.settings.defaultProduct === 'blanqueo'){
          this.evaluationsList.title = this.translate.instant('evaluations-list.opportunity-evaluations');
          for(let i = 0; i < this.evaluationsList.evaluations?.length; i++){
              let evaluation : any = this.evaluationsList.evaluations[i];
              evaluation.stateName = '';
              if(evaluation.operationLocked || evaluation.operationNotSupported){
                  evaluation.stateName = evaluation.operationLocked?this.translate.instant('common.locked'):this.translate.instant('evaluation-header.not-supported');
              }
          }
          this.configureViewBlanqueo();
        }else {
          this.configEvaluationView();
          if(this.dashboardVersion === 2 || this.evaluationsList.productId ==='denunc'){
            this.evaluationsList.title = this.translate.instant('common.channels');
          }else{
            this.evaluationsList.title = this.translate.instant('common.implantations');
          }
        }
    }
    if(this.evaluationsList.evaluations && this.evaluationsList.evaluations.length && this.deletedEvaluations && !this.isConcursalFormsModal) {
      this.getSharedEvaluations();
    }else{
      this.changeView(this.evaluationsList.view);
    }
  }

  configEvaluationView(){
    this.evaluationsList.evaluationView = [
      {
          name: this.translate.instant('common.active'),
          viewOption: 'all'
      },
      {
          name: this.translate.instant('common.removed'),
          viewOption: 'deleted'
      }
    ];
    if (!this.isConcursalFormsModal && this.dashboardVersion === 1){
        let shared : any = {
            name: this.translate.instant('common.shared'),
            viewOption: 'shared'
        };
        this.evaluationsList.evaluationView.splice(1,0,shared);
    }
    this.evaluationsList.selectedView = this.evaluationsList.evaluationView[0];
  }

  configureViewBlanqueo(){
    this.evaluationsList.clientNameFilter = {
      name: this.translate.instant('common.client'),
      id: this.commonUtilsService.createId()
    };
    this.evaluationsList.resultFilter = {
      name: this.translate.instant('evaluations-list.finish-result'),
      id: this.commonUtilsService.createId()
    };
    this.evaluationsList.nameFilter = {
      name: this.translate.instant('common.identifier'),
      id: this.commonUtilsService.createId()
    };

    this.evaluationsList.evaluationView = [
      {
          name: this.translate.instant('evaluations-list.recent-activity'),
          viewOption: 'last15'
      },
      {
          name: this.translate.instant('common.active'),
          viewOption: 'active'
      },
      {
          name: this.translate.instant('evaluations-list.grouping-by-client'),
          viewOption: 'groupClients'
      },
      {
          name: this.translate.instant('evaluations-list.all-evaluations'),
          viewOption: 'all'
      },
      {
          name: this.translate.instant('common.removed'),
          viewOption: 'deleted'
      }
    ];
    this.evaluationsList.view ='last15';
    this.evaluationsList.selectedView = this.evaluationsList.evaluationView[0];
  }

  getSharedEvaluations(){
    this.apiService.get('company', 'dashboard/evaluations/sharings')
    .then((data: any)=> {
      let newData : any = [];
      Object.entries(data).forEach(([key, value]) => {
        let info : any = {
          key: parseInt(key),
          shared: value
        }
        newData.push(info);
      })
      newData.forEach((item:any) => {
        for(let j = 0; j < this.evaluationsList.evaluations.length; j++){
          if(item.key === this.evaluationsList.evaluations[j].evaluationId){
              this.evaluationsList.evaluations[j].shared = item.shared;
              this.evaluationsList.sharedEvaluations.push(this.evaluationsList.evaluations[j]);
              break;
          }
        }
      });

      this.changeView(this.evaluationsList.view);
    }, (errorData:any)=>{
    });
  }

  changeView(type: any){
    if(this.dropdown?.isOpen()){
      this.dropdown.close();
    }
    this.evaluationsList.view = type;
    this.evaluationsList.evaluationsFilter = [];
    this.evaluationsList.searchWord = ['name', 'clientName', 'result'];
    if(this.evaluationsList.search){
        this.toggleSearch();
    }

    let list : any =[];
    switch(this.evaluationsList.view){
      case 'shared':
          this.evaluationsList.evaluationsFilter = this.evaluationsList.sharedEvaluations;
          break;
      case 'deleted':
          this.evaluationsList.evaluationsFilter = this.deletedEvaluations;
          break;
      case 'last15':
          list = this.evaluationsList.evaluations?this.evaluationsList.evaluations.sort(this.orderListDate):[];
          this.evaluationsList.evaluationsFilter = list.slice(0, 15);
          break;
      case 'active':
        list = this.evaluationsList.evaluations?this.takeActivesEvaluation(this.evaluationsList.evaluations):[];
        this.evaluationsList.evaluationsFilter = list;
        break;
    case 'groupClients':
        this.evaluationsList.searchWord = ['name', 'result'];
        list = this.evaluationsList.evaluations?this.takeClientsList(this.evaluationsList.evaluations):[];
        this.evaluationsList.clientsList = list;
        this.evaluationsList.clientsListFilter = this.evaluationsList.clientsList;
        this.evaluationsList.evaluationsFilter = this.evaluationsList.evaluations;
        break;
      default:
          this.evaluationsList.evaluationsFilter = this.evaluationsList.evaluations;
          break;
    }
    this.evaluationsList.evaluationsListFilter = this.evaluationsList.evaluationsFilter;
    this.evaluationsList.evaluationView.forEach((view:any) => {
      if(view.viewOption === type){
        this.evaluationsList.selectedView = view;
      }
    });
    this.evaluationsList.showList = false;
    if(this.evaluationsList.productId ==='blanqueo' && this.evaluationsList.evaluationsFilter?.length){
        this.evaluationsList.filters = ['clientName', 'result'];
        if(type === 'groupClients'){
          this.evaluationsList.orderOperationDates = false;
        }else{
          this.evaluationsList.orderOperationDates = true;
        }
        this.configureListBlanqueo();
    }
  }

  orderListDate(a: any, b: any) {
    if(a.modified < b.modified){
      return 1
    }
    return a.modified > b.modified ? -1 : 0;
  }

  takeActivesEvaluation(list: any){
    let finishedEvaluation : any = [];
    list.forEach((evaluation:any) => {
      if(!evaluation.finished){
        finishedEvaluation.push(evaluation);
      }
    });
    return finishedEvaluation;
  }

  takeClientsList(list: any){
    let clientsList : any = [];
    let order : any = 0;
    list.forEach((evaluation:any) => {
      let existClient : any = this.checkExistClient(clientsList, evaluation.clientId);
      if(!existClient){
          order++;
          let client : any = {
              type: 'client',
              order: order,
              evaluationId: this.commonUtilsService.createId(),
              clientId: evaluation.clientId,
              clientName: evaluation.clientName,
              evaluations: this.takeEvaluationsClient(list, evaluation.clientId)
          }
          clientsList.push(client);
      }
    });

    return clientsList;
  }

  checkExistClient(list: any, clientId: any){
      for(let evaluation of list){
          if(evaluation.clientId === clientId){
              return true;
          }
      }
      return false;
  }

  takeEvaluationsClient(list: any, clientId: any){
    let evaluationsClient : any = [];
    list.forEach((evaluation:any) => {
      if(evaluation.clientId === clientId){
        evaluationsClient.push(evaluation);
      }
    });
    return evaluationsClient;
  }

  configureListBlanqueo(){
    this.evaluationsList.evaluationsFilter.forEach((evaluation:any) => {
      if(evaluation.colorResult){
        evaluation.colorRGBAResult = 'rgba('+this.commonUtilsService.convertHexToRGB(evaluation.colorResult)+',0.36)';
      }
    });
    this.getFilters();
  }

  getFilters(){
    this.evaluationsList.filters.forEach((filter:any) => {
      this.evaluationsList[filter+'List'] = this.filterColumnService.extractList(filter, this.evaluationsList.evaluationsFilter)
      this.evaluationsList[filter+'ListFilter'] = [];
    });
  }

  filterSearch(e: any, type: any){
    let newValue: any = e.newValue;
    this.evaluationsList[type+'WordFilter'] = newValue;
    this.filterEvaluation(type);
  }

  selectTypeOperation(e: any, type: any){
      let item = e.source;
      let listFilter = this.evaluationsList[type+'ListFilter'];
      this.evaluationsList[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      this.evaluationsList[type+'Filter'].hasFilter = this.evaluationsList[type+'ListFilter'].length;
      item.selected = !item.selected;
      this.filterEvaluation(type);
  }

  filterEvaluation(type: any = undefined){
    let serchText = type === 'name'?this.evaluationsList[type+'WordFilter']:this.evaluationsList.searchEvaluation;
    let filterList = this.filterColumnService.filterByWord(serchText, this.evaluationsList.evaluationsFilter, 'name');
    if(this.evaluationsList.productId ==='blanqueo' && this.evaluationsList.evaluationsFilter?.length){
      this.evaluationsList.filters.forEach((filter:any) => {
        let filteredList = this.evaluationsList[filter+'ListFilter'];
        filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });
    }
    this.evaluationsList.evaluationsListFilter = filterList;
    if(this.evaluationsList.view === 'groupClients' && (type === 'result' || type === 'name' || type === 'clientName')){
      this.configureGroupClientList();
    }
  }

  configureGroupClientList(){
      let clients : any = [];
      this.evaluationsList.clientsList.forEach((client:any) => {
        for(let evaluation of client.evaluations){
          let evaluationShow : any = this.searchEvaluationClient(evaluation);
          if(evaluationShow){
              clients.push(client);
              break;
          }
        }
      });
      this.evaluationsList.clientsListFilter = clients;
  }

  searchEvaluationClient(evaluation: any){
      for(let evaluationFilter of this.evaluationsList.evaluationsListFilter){
          if(evaluationFilter.evaluationId === evaluation.evaluationId){
              return true;
          }
      }
      return false;
  }

  searchBy(type:any){
      if(this.evaluationsList.dateFilter === type){
          this.evaluationsList.orderOperationDates = !this.evaluationsList.orderOperationDates;
      }else{
          this.evaluationsList.dateFilter = type;
          this.evaluationsList.orderOperationDates = true;
      }
  }

  createNewEvaluation(){
    if(this.canCreate){
      this.commonUtilsService.registerEvent('create implantation', this.context.settings.defaultProduct)
      this.launchAction.emit({ action: 'newEvaluation'});
    }
  }

  editOption(e: any, evaluation: any){
    if(this.evaluationsList.view !== 'deleted'){
      let resgisterItem:any ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'open implantation',
        'Source': 'implantation',
        'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem)
      e.stopPropagation();
      e.preventDefault();
      evaluation.popoverIsOpen = false;
      this.loader.openLoader('dashboard-panel-create-evaluation');

      if(this.canCreate || this.canModify){
        this.isUpgradeable(evaluation);
      }else{
        this.router.navigate(['evaluation',evaluation.evaluationId]);
      }

      if (evaluation.productId === 'forms') {
        this.launchAction.emit({ action: 'edit'});
      }
    }
  }

  isUpgradeable(evaluation: any){

      this.apiService.get('evaluations/needupdateversion', evaluation.evaluationId)
      .then((data: any)=> {
        if(data === 1){
          this.loader.closeLoader(['dashboard-panel-create-evaluation']);
          this.modalService.adviceModal(this.translate.instant('evaluations-list.update-question'), 'info:question:question').subscribe((result:any) => {
            if(result.result === 'ok'){
              this.updateEvaluation(evaluation);
            }else{
              this.router.navigate(['evaluation',evaluation.evaluationId]);
            }
          });
        }else{
          this.router.navigate(['evaluation',evaluation.evaluationId]);
        }
      }, (errorData:any)=>{
        this.router.navigate(['evaluation',evaluation.evaluationId]);
      });

  }

  updateEvaluation(evaluation: any){
    let itemSelected : any = {
      type: 'update-evaluation',
      item: evaluation
    }

    this.informativeModalSubject = this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
      if(result.result === 'ok'){
        this.loader.openLoader('dashboard-panel-create-evaluation');
        this.router.navigate(['evaluation',evaluation.evaluationId]);
      }else{
        this.modalService.adviceModal(this.translate.instant('evaluations-list.error-update'), 'error:accept:warning').subscribe((result:any) => {
          if(result.result === 'ok'){
            this.loader.openLoader('dashboard-panel-create-evaluation');
            this.router.navigate(['evaluation',evaluation.evaluationId]);
          }
        });
      }
    });
  }

  shareEvaluation(evaluation: any) {
    this.commonUtilsService.registerEvent('share implantation', this.context.settings.defaultProduct)
    this.evaluationShareSubject = this.modalService.evaluationShare(evaluation).subscribe((result:any)=>{
      if(result.result === 'ok'){
        this.evaluationsList.sharedEvaluations = [];
        this.getSharedEvaluations();

        let secondaryText : any = ' ';
        if(result.documents && result.documents !== null && result.documents.length){
            secondaryText = this.translate.instant('evaluation-share.attached-ok');
        }
        this.openInfoModal(this.translate.instant('evaluation-share.shared-ok') + secondaryText);
      }
    });
  }

  duplicateEvaluation(evaluation: any){
    this.commonUtilsService.registerEvent('duplicate implantation', this.context.settings.defaultProduct)
    // Desactivando esta opción se vería la modal de duplicar con formato especial
    // evaluation.specialModal = evaluation.productId === 'blanqueo';
    this.duplicateEvaluationModalSubject = this.modalService.duplicateEvaluationModal(evaluation).subscribe((result:any)=>{
      if(result.result === 'ok'){
        this.copyEvaluation.emit({
          newEvaluation:{
            evaluation: evaluation,
            copyInfo: result.evaluationInfo
          }
        });
      }
    });
  }

  rename(evaluation: any){
    let newItem : any = {
        title: this.translate.instant('dashboard.rename-evaluation'),
        type: 'evaluation',
        titleConcept: evaluation.name,
        evaluationId: evaluation.evaluationId,
        listFolders: [],
        rename: true
    }
    this.renameModalSubject = this.modalService.renameModal(newItem).subscribe((result:any) => {
        if(result.result === 'ok'){
          this.loader.openLoader('concept-tab-rename-modal');
          evaluation.name = result.name;
          newItem.titleConcept = result.name;
          this.saveRename(newItem);
        }
      })
  }

  saveRename(newItem: any){
    let url : any = "evaluations/rename/"+newItem.evaluationId;

    this.apiService.patch(url, 0, { Name: newItem.titleConcept }).then((data) => {
      this.loader.closeLoader(['concept-tab-rename-modal']);
      let text : string = this.translate.instant('dashboard.rename-evaluation-ok');
      this.openInfoModal(text);
    }).catch((errorData:any) => {
      this.loader.closeError();
      let text : string = this.translate.instant('concept-tab.error-name');
      if(errorData.description==='UqConstraint'){
          text = this.translate.instant('concept-tab.error-same-name');
      }
      this.errorModal(text);
    });
  }

  restoreEvaluation(evaluation: any){
    let repeatedSelectedClient = false;
    if (this.isConcursalFormsModal) {
      for (let index in this.evaluationsList.evaluations) {
        let formsEvaluation = this.evaluationsList.evaluations[index];
        if (formsEvaluation.clientId === evaluation.clientId) {
            repeatedSelectedClient = true;
            break;
        }
      }
    }

    if (repeatedSelectedClient) {
      this.errorModal(this.translate.instant('evaluations-list.restore-repeated-client-error'));
    } else {
        let text = this.translate.instant('dashboard.restore-implantation-question').replace('{0}', evaluation.name);
        this.modalService.adviceModal(text, 'info:question:question').subscribe((result:any) => {
          if(result.result === 'ok'){
            this.loader.openLoader('restore-evaluation');
            this.launchAction.emit(
              {
                action: 'restoreEvaluation',
                evaluation: evaluation
              });
          }
        });
    }
  }

  deleteEvaluation(evaluation: any){
    let actionDelete = 'deleteEvaluation';
    if(this.evaluationsList.view  === 'deleted'){
      this.commonUtilsService.registerEvent('duplicate implantation', this.context.settings.defaultProduct)
      actionDelete = 'deleteEvaluationDefinitely';
    }
    this.launchAction.emit({
        action: actionDelete,
        evaluation: evaluation
      });
  }

  exportList(){
    this.modalService.exportFormatModal()
      .subscribe((result:any) => {
          if(result.result === 'ok'){
            let config: any = {
                idPrint: 'evaluation-list-report-pdf',
                list: cloneDeep(this.evaluationsList.evaluations),
                typeColumns: ['clientName', 'name', 'result', 'stateName', 'modifiedIso'],
                nameDocument: this.evaluationsList.title+(result.selectedFormat==='xls'?'.xlsx':'.pdf'),
                title: this.evaluationsList.title,
                columns: {
                    clientName: this.translate.instant('common.client'),
                    name: this.translate.instant('common.identifier'),
                    result: this.translate.instant('evaluations-list.finish-result'),
                    stateName: this.translate.instant('common.state'),
                    modifiedIso: this.translate.instant('evaluations-list.modification-date'),
                },
                uploadFile: false,
                landscape: true,
                successCallback: (response:any, blob:any) => {
                    delete this.evaluationsList.configurationPdf;
                    this.downloadService.triggerDownload(blob, config.nameDocument);
                    this.modalService.adviceModal(this.translate.instant('common.export-excel-ok'), 'info:accept:check-round')
                }
            };
            config.list.forEach((evaluation:any) => {
                evaluation.modifiedIso = this.dateConverterService.convertDate(evaluation.modified, 'without-time');
            })
            switch (result.selectedFormat) {
              case 'xls':
                  this.exportService.toXls(config);
                  break;
              case 'pdf':
                  config.exportDate = this.dateConverterService.setTimezone(new Date());
                  config.orderType = 'modified';
                  config.orderAsc = true;
                  this.evaluationsList.configurationPdf = config;
                  let that : any = this;
                  setTimeout(function () {
                    let htmlPrint : any = $('#evaluation-list-report-pdf').html();
                    config.htmlPrint = htmlPrint;
                    that.exportService.toPdf(config);
                  })
                  break;
            }
          }
      });

  }

  toggleSearch(){
      this.evaluationsList.search = !this.evaluationsList.search;
      if (!this.evaluationsList.search) {
          this.evaluationsList.searchEvaluation = '';
      }
      this.filterEvaluation();
  }

  openInfoModal(text:string){
    this.modalService.adviceModal(text, 'info:accept:check-round');
  }

  errorModal(text:string){
    this.modalService.adviceModal(text, 'error:accept:warning');
  }

  emitEventFromRow(e:any){
    switch(e.type){
      case 'editOption':
        this.editOption(e.event, e.evaluation);
        break;
      case 'rename':
        this.rename(e.evaluation);
        break;
      case 'restoreEvaluation':
        this.restoreEvaluation(e.evaluation);
        break;
      case 'deleteEvaluation':
        this.deleteEvaluation(e.evaluation);
        break;
    }
  }

  showTooltip(id:any){
      return this.commonUtilsService.showTooltip(id)
  }

  ngOnDestroy(){
    if(this.informativeModalSubject){
        this.informativeModalSubject.unsubscribe();
    }
    if(this.renameModalSubject){
      this.renameModalSubject.unsubscribe();
    }
    if(this.evaluationShareSubject){
      this.evaluationShareSubject.unsubscribe();
    }
    if(this.duplicateEvaluationModalSubject){
      this.duplicateEvaluationModalSubject.unsubscribe()
    }
  }
}
