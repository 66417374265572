import { Component, Input, OnInit } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
  selector: 'available-processes',
  templateUrl: './available-processes.component.html'
})
export class AvailableProcessesComponent{
  availableProcesses: any = {
    search: false,
    searchInstances: '',
    colFilter: 'conceptTitle',
    order: false
  }
  constructor(private translate: AfTranslateFactory,
    private modalService: ModalServiceService) { }
  @Input() processesList: any;


  toggleSearch() {
      this.availableProcesses.search = !this.availableProcesses.search;
      if (!this.availableProcesses.search) {
          this.availableProcesses.searchInstances = '';
      }
  }

  downloadTaxCalendar() {
      let a = document.createElement('a');
      a.href = 'https://download.lefebvre-sarrut.be/dms?id_=7378ff12-6bef-4ab9-99c5-4cd6b75f0f03';
      a.click();
      this.modalService.adviceModal(this.translate.instant('available-processes.download-ok'), 'info:accept:check-round')
  }

  orderTable() {
      this.availableProcesses.order = !this.availableProcesses.order;
  }
}
