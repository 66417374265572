import moment from "moment";

import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { AudioRecordingService } from "@shared/services/audio-recorder/audio-recorder.service";
import { ApiServiceService } from "@shared/services/api-service/api-service.service";
import { GlobalCfgFactory } from "@shared/factories/global-cfg/global-cfg.factory";
import { CustomLandingFactory } from "@shared/factories/custom-landing/custom-landing.factory";

@Injectable({
  providedIn: 'root'
})
export class RecordAudioParentService {
  skin: string = this.globalCfg.skin;
  isLanding: boolean = this.customLanding.isLanding()

  constructor(private translate: AfTranslateFactory,
    private audioRecordService: AudioRecordingService,
    private apiService: ApiServiceService,
    private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory) {
    }
  configureInit(){
    return {
      acceptDistorsion: true,
      applyChanges: false,
      isRecording: false,
      processText: this.translate.instant('record-audio-modal.generating-audio'),
      recordedTime: '00:00',
      playDemo: false,
      audioToText: '',
      percentTime:  0,
      secondsRecord:  0,
      player: null,
      showPlay: false,
      blobName: '',
      initRec:false,
      play: false,
      file: null,
      recordingFile: null,
      recorded: false,
      showDiscard: false,
      textSpecificToRecButton: this.translate.instant('record-audio-modal.press-to-talk'),
      maxTime: this.translate.instant('record-audio-modal.max-time'),
      moment: moment
    }
  }

  initSubscribers(child:any){
    child.recordAudioModal.recordingFailed = this.audioRecordService.recordingFailed()
      .subscribe(() => {
        child.recordAudioModal.isRecording = false
      });

      child.recordAudioModal.getRecordedTime = this.audioRecordService.getRecordedTime()
      .subscribe((time:any) => {
          child.recordAudioModal.recordedTime = time.timeString;
          child.recordAudioModal.percentTime = time.timePercent;
          child.recordAudioModal.secondsRecord = time.secondsRecord;
          if(time.secondsRecord === 179){
            this.stopRecording(child, true);
          }
      });

    child.recordAudioModal.getRecordedBlob = this.audioRecordService.getRecordedBlob()
      .subscribe((data:any) => {
          child.recordAudioModal.maxTime = child.recordAudioModal.recordedTime;
          child.recordAudioModal.recordedTime = '00:00';
          child.recordAudioModal.percentTime = 0;
          child.recordAudioModal.blobName = data.name;
          if(!child.recordAudioModal.recorded){
            child.recordAudioModal.recorded = true;
            child.recordAudioModal.acceptDistorsion? this.createDistorsion(data, child) : this.sendAudio(data, child, data.blob, false)
          }
      });
  }

  createDistorsion(data:any, child:any){
    let fd:any = new FormData();
    fd.append('file', data.blob);
    this.apiService.postBlob('utils/change-audio-pitch', fd)
      .then((dataAudio:any)=>{
        child.recordAudioModal.player = new Audio();
        $(child.recordAudioModal.player).on("loadedmetadata", ()=>{
          this.sendAudio(data, child, dataAudio.blob, true);
        })
        child.recordAudioModal.player.src = URL.createObjectURL(dataAudio.blob);

    })
  }

  sendAudio(file:any, child:any, blob:any, hasLoadedAudio: boolean = false){
    child.recordAudioModal.recordingFile = blob;
    child.recordAudioModal.file = blob;
    if(!hasLoadedAudio){
      child.recordAudioModal.player = new Audio();
      child.recordAudioModal.player.src = URL.createObjectURL(blob);
    }
    let fd:any = new FormData();
    fd.append('file', file.blob);
    this.apiService.attachFile('blanqueo/transcribe-audio', fd)
    .then((data:any)=>{
      child.recordAudioModal.audioToText = data.text;
      child.recordAudioModal.play = false;
      child.recordAudioModal.showPlay = true;
      child.recordAudioModal.showDiscard = true;
      setTimeout(()=>{
        child.recordAudioModal.applyChanges = false;
        let skin = this.isLanding?'landing-custom':this.globalCfg.skin;
        let element: any = $(`.compliance__${skin}`);
        element.click()
      })

    })
  }

  startRecording(child:any) {
    if (!child.recordAudioModal.isRecording) {
      child.recordAudioModal.recorded = false;
      child.recordAudioModal.isRecording = true;
      this.audioRecordService.startRecording(child);
    }
  }

  acceptDistorsionTerms(child:any){
    if(!(child.recordAudioModal.isRecording || child.recordAudioModal.applyChanges || child.recordAudioModal.showPlay)){
      child.recordAudioModal.acceptDistorsion = !child.recordAudioModal.acceptDistorsion;
      if(child.recordAudioModal.acceptDistorsion){
        if(child.audio !== null){
          child.audio.pause();
          child.audio.currentTime = 0;
        }
      }
    }
  }

  abortRecording(child:any) {
    if (child.recordAudioModal.isRecording) {
      child.recordAudioModal.isRecording = false;
      child.recordAudioModal.recorded = false;
      this.audioRecordService.abortRecording();
    }
  }

  stopRecording(child:any, exceeded: boolean = false) {
    if (child.recordAudioModal.isRecording && child.recordAudioModal.secondsRecord > 10) {
      this.audioRecordService.stopRecording(child);
      child.recordAudioModal.applyChanges = true;
      child.recordAudioModal.isRecording = false;
      if(exceeded){
        child.recordAudioModal.exceededTime = true;
      }
    }
  }

  playAudio(child:any){
    child.recordAudioModal.play = !child.recordAudioModal.play;
    if(child.recordAudioModal.play){
      child.recordAudioModal.player.play()
      this.initIntervalPlay(child);
    }else{
      child.recordAudioModal.player.pause();
      child.recordAudioModal.player.currentTime = 0;
      this.stopInterval(child)
    }
  }

  discardVoiceRecord(child:any){
    if( child.recordAudioModal.player !== null){
      child.recordAudioModal.player.pause();
      child.recordAudioModal.player.currentTime = 0;
    }
    child.recordAudioModal.player = null;
    child.recordAudioModal.showPlay = false;
    child.recordAudioModal.maxTime = this.translate.instant('record-audio-modal.max-time');
    child.recordAudioModal.recordingFile = null;
    child.recordAudioModal.blobName = '';
    child.recordAudioModal.showDiscard = false;
    this.stopInterval(child)
  }


  initIntervalPlay(child:any){
    child.recordAudioModal.startTime = moment();
    child.recordAudioModal.interval = setInterval(() => {
      let currentTime:any = moment();
      let diffTime = moment.duration(currentTime.diff(child.recordAudioModal.startTime));
      child.recordAudioModal.recordedTime = this.toString(diffTime.minutes()) + ":" + this.toString(diffTime.seconds());
      child.recordAudioModal.percentTime = diffTime.seconds() * 100 / child.recordAudioModal.secondsRecord;
      if(child.recordAudioModal.percentTime >= 100){
        child.recordAudioModal.play = false;
        child.recordAudioModal.player.pause();
        child.recordAudioModal.player.currentTime = 0;
        this.stopInterval(child);
      }
    }, 1000);
  }

  stopInterval(child:any){
    clearInterval(child.recordAudioModal.interval);
    child.recordAudioModal.recordedTime = '00:00';
    child.recordAudioModal.percentTime = 0;
  }

  private toString(value:any) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  handleDismiss(child:any){
    if( child.recordAudioModal.player !== null){
      child.recordAudioModal.player.pause();
      child.recordAudioModal.player.currentTime = 0;
    }
    this.discardVoiceRecord(child);
    this.stopInterval(child);
    this.audioRecordService.abortRecording();
    child.activeModal.close({result:'cancel'});
  }
}
