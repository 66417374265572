<div class="schedule-big common__panel">
    <header-nav></header-nav>
    <div class="common__block-button-close-window">
        <button (click)="changeViewWindow()"
            class="common__back-link">
            <span [afTranslate]="'schedule.close-schedule'">_CERRAR AGENDA</span>
            <i class="lf-icon-close common__back-link-icon"></i>
        </button>
    </div>
    <div class="schedule-big__block-container">
        <div class="common__block-list-new-version common__block-list-new-version--height-auto schedule-big__no-padding">
        <schedule [typeView]="'window'"></schedule>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
