<div class="task-edit__fields-block">
    <div class="task-edit__group-title-block-custom">
        <div class="concept-form__cell-block-custom">
            <span-tooltip [text]="taskForm.task.titleSharedConcept"></span-tooltip>
        </div>
    </div>
    <div class="task-edit__block-form-task">
        <div class="task-edit__input-form">
            <div class="task-edit__input">
                <field-type-text-simple [field]="taskForm.taskTitle"
                    [preSelectItem]="taskForm.task.title"
                    (itemSelected)="itemSelected($event, 'title')">
                </field-type-text-simple>
            </div>
            <div class="task-edit__input"
                *ngIf="taskForm.task.taskTypeId !== 7">
                <field-type-list-simple [field]="taskForm.typeOfTask"
                    [preSelectItem]="taskForm.task.taskTypeId"
                    [listValues]="taskForm.typeList"
                    (itemSelected)="itemSelected($event, 'taskTypeId')">
                </field-type-list-simple>
            </div>
            <div class="task-edit__input"
                *ngIf="taskForm.task.taskTypeId === 1 || taskForm.task.taskTypeId == 3 || taskForm.task.taskTypeId === 6 || taskForm.task.taskTypeId === 7">
                <field-type-contacts-simple-custom *ngIf="taskForm.taskContactSimple?.fieldType==='simple'"
                    [field]="taskForm.taskContactSimple"
                    [valueId]="taskForm.newValueId"
                    [concept]="taskForm.concept"
                    (sendContacts)="receiveContacts($event)">
                </field-type-contacts-simple-custom>
                <field-type-contacts-multiple-custom *ngIf="taskForm.taskContactSimple?.fieldType==='multiple'"
                  [field]="taskForm.taskContactSimple"
                  [valueId]="taskForm.newValueId"
                  [concept]="taskForm.concept"
                  (sendContacts)="receiveContacts($event)">
              </field-type-contacts-multiple-custom>
            </div>
            <div class="task-edit__input"
                *ngIf="taskForm.task.taskTypeId === 4 || taskForm.task.taskTypeId === 5 || taskForm.task.taskTypeId === 2">
                <field-type-contacts-multiple-custom
                    [field]="taskForm.evaluationParticipant"
                    [valueId]="taskForm.newValueId"
                    [concept]="taskForm.concept"
                    (sendContacts)="receiveContacts($event)">
                </field-type-contacts-multiple-custom>
            </div>
            <div class="task-edit__input">
                <field-type-date [field]="taskForm.taskExpirationDate"
                    [concept]="taskForm.concept"
                    [preSelectItem]="taskForm.taskDate"
                    [alertHour]="taskForm.task.taskTypeId === 4 ? taskForm.task.beginHour : null"
                    (itemSelected)="itemSelected($event, 'expirationDate')"
                    (createAlert)="createTaskAlert($event)">
                </field-type-date>
            </div>
            <div [ngShow]="taskForm.task.taskTypeId === 4">
                <div class="task-edit__input-hour">
                    <field-type-time [field]="taskForm.stateStartTime"
                                    [preSelectItem]="taskForm.task.beginHour"
                                    (itemSelected)="itemSelected($event, 'beginHour')">
                    </field-type-time>
                </div>
                <div class="task-edit__input-hour task-edit__input-hour--right">
                    <field-type-time [field]="taskForm.stateEndTime"
                                    [preSelectItem]="taskForm.task.endHour"
                                    (itemSelected)="itemSelected($event, 'endHour')">
                    </field-type-time>
                </div>
                <div class="task-edit__input"
                    [ngShow]="taskForm.videoconferences">
                    <field-type-radio-option
                        [field]="taskForm.taskModality"
                        [listOptions]="taskForm.listOptionsTaskModality"
                        (changeValueOption)="changeValueOption($event)">
                    </field-type-radio-option>
                </div>
                <div class="task-edit__block-info task-edit__block-info--meeting"
                    [ngShow]="taskForm.task.taskId && taskForm.taskModality.model === 'Videoconference' && taskForm.task.taskStateId !== 5">
                    <div class="task-edit__block-connection-data">
                        <i class="lf-icon-connect task-edit__icon-refresh-repetition"></i>
                        <span class="task-edit__text-repetition-subtitle-custom task-edit__text-repetition-subtitle-custom--bold"
                            [afTranslate]="'task-form.connection-data'">_Datos de conexión</span>
                    </div>
                    <p class="task-edit__text-meeting-private">
                        <span class="task-edit__text-repetition-subtitle-custom">URL:</span>
                        <span class="task-edit__text-connection-data task-edit__text-connection-data--url"
                            (click)="openUrl(taskForm.task.url)">{{taskForm.task.url}}</span>
                        <button class="task-edit__button-copy-text lf-icon-paste"
                            (click)="copyText(taskForm.task.url)"
                            matTooltip="{{'concept-share.copy' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"></button>
                    </p>
                    <p class="task-edit__text-meeting-private"
                        *ngIf="taskForm.securitySettings.model ==='Private'">
                        <span class="task-edit__text-repetition-subtitle-custom"
                            [afTranslate]="'login-block.password'">_Contraseña</span>
                        <span class="task-edit__text-repetition-subtitle-custom" >:</span>
                        <span class="task-edit__text-connection-data">{{taskForm.task.password}}</span>
                        <button class="task-edit__button-copy-text lf-icon-paste"
                            (click)="copyText(taskForm.task.password)"
                            matTooltip="{{'concept-share.copy' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"></button>
                    </p>
                    <p class="task-edit__text-meeting-private"
                        [ngShow]="taskForm.securitySettings.model === 'Open'">
                        <span class="task-edit__text-connection-data"
                            [afTranslate]="'instant-videoconferences.open-access'">
                            _Acceso abierto</span>
                    </p>
                </div>
                <div class="task-edit__input">
                    <field-type-text-simple [field]="taskForm.taskPlace"
                                    [preSelectItem]="taskForm.task.location"
                                    (itemSelected)="itemSelected($event, 'location')">
                    </field-type-text-simple>
                </div>
            </div>
            <field-type-switch class="task-edit__field-switch-block"
                *ngIf="taskForm.task.taskTypeId === 6 || taskForm.task.taskTypeId === 3 || taskForm.task.taskTypeId === 1 || taskForm.task.taskTypeId === 5"
                [label]="taskForm.repeatPeriod.text"
                [checked]="taskForm.repetitionActived"
                [disabled]="taskForm.repeatPeriod.disabled"
                (callback)="setPattern($event.value)">
            </field-type-switch>
            <div class="task-edit__block-info"
                *ngIf="taskForm.repetitionActived">
                <button class="task-edit__icon-editable-pattern lf-icon-editable-form task-edit__icon-editable-pattern--off-custom"
                    (click)="modifyPattern()"></button>
                <p class="task-edit__text-repetition">
                    <span class="task-edit__text-repetition-subtitle-custom"
                        [afTranslate]="'repeat-pattern.repetition-rate'">_Frecuencia de repetición:</span>
                    <span class="task-edit__text-repetition-data-custom">{{taskForm.repeatPattern.frecuency}}</span>
                </p>
                <p class="task-edit__text-repetition">
                    <span class="task-edit__text-repetition-subtitle-custom"
                        [afTranslate]="'repeat-pattern.moment-creation'">_Momento de la creación:</span>
                    <span class="task-edit__text-repetition-data-custom">{{taskForm.repeatPattern.momentOfCreation}}</span>
                </p>
                <p  class="task-edit__text-repetition">
                    <span class="task-edit__text-repetition-subtitle-custom"
                        [afTranslate]="'repeat-pattern.repeat-interval'">_Intervalo de repetición:</span>
                    <span class="task-edit__text-repetition-data-custom">
                        <span [ngShow]="taskForm.repeatPattern.selectInterval === 'no-end-date'"
                            [afTranslate]="'repeat-pattern.no-end-date'"></span>
                        <span [ngShow]="taskForm.repeatPattern.selectInterval !== 'no-end-date'">{{taskForm.repeatPattern.startInterval | convertDate:'without-time'}}</span>
                        <span [ngShow]="taskForm.repeatPattern.selectInterval !== 'no-end-date'">-</span>
                        <span [ngShow]="taskForm.repeatPattern.selectInterval === 'finish-after'">{{taskForm.repeatPattern.finishInterval}}</span>
                        <span [ngShow]="taskForm.repeatPattern.selectInterval === 'end-interval-day'">{{taskForm.repeatPattern.finishInterval | convertDate:'without-time'}}</span>
                    </span>
                </p>
            </div>
            <div class="task-edit__block-info"
                [ngClass]="!taskForm.repetitionActived && taskForm.task.taskTypeId === 5 && taskForm.originalSkin !== 'uk-steel-blue'?'task-edit__block-info--communication':'task-edit__block-info--none'">
                <i class="lf-icon-information task-edit__icon-communication-info"></i>
                <p class="task-edit__text-communication-info"
                    [afTranslate]="'task-edit.communication-info-1'"></p>
                <p class="task-edit__text-communication-info"
                    [afTranslate]="'task-edit.communication-info-2'"></p>
                <p class="task-edit__text-communication-info"
                    [afTranslate]="'task-edit.communication-info-3'"></p>
                <p class="task-edit__text-communication-info"
                    [afTranslate]="'task-edit.communication-info-4'"></p>
            </div>
            <div class="task-edit__text-description"
                [ngClass]="{'task-edit__text-description--no-editable': !taskForm.showAccept }">
                <span class="task-edit__text-description-title-custom"
                    [afTranslate]="'common.description'"
                    [ngClass]="{'common__input-content-no-editable': !taskForm.tinyMceOptions.toolbar}">
                    Descripción
                </span>
                <rich-editor [tinyMceOptions]="taskForm.tinyMceOptions"
                    [modelText]="taskForm.task.description"
                    (emitValue)="getEditorValue($event)">
                </rich-editor>
            </div>
            <div class="task-edit__error-message"
                [ngShow]="taskForm.showErrorHourOrDate">
                <i class="lf-icon-warning task-edit__icon-alert"></i>
                <span class="task-edit__text-alert">{{taskForm.errorHourOrDate}}</span>
                <i class="lf-icon-close task-edit__icon-close-header"
                    (click)="taskForm.showErrorHourOrDate = !taskForm.showErrorHourOrDate"></i>
            </div>
            <div [ngShow]="taskForm.canCreate && (taskForm.task.taskStateId !== 5 || taskForm.task.taskStateId !== 6)">
                <div class="common__buttons-block">
                    <div [ngShow]="taskForm.task.taskTypeId === 4 && taskForm.task.taskId && taskForm.task.taskStateId !== 5 "
                        class="task-edit__left">
                        <button type="submit"
                            class="common__button-landing-custom common__button-landing-custom--send"
                            [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                            [ngShow]="taskForm.showAccept"
                            (click)="sendNotify()">
                            <i class="lf-icon-notification task-edit__icon-button"></i>
                            <span [afTranslate]="'expanded-menu.send-reminder'"></span>
                        </button>
                    </div>
                    <button type="submit"
                        class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
                        [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
                        [ngShow]="taskForm.showAccept"
                        (click)="addOrEditTask()"
                        [afTranslate]="taskForm.task.taskId?'common.save':'common.create'">_Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
