import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateLockFactory {
  errors:any = new Map();
  addErrors(field: any){
    this.errors.set(field.fieldId, field);
  }

  deleteError(field:any){
    this.errors.delete(field.fieldId);
  }

  hasErrors(){
      return !!(this.errors.size)
  }
}
