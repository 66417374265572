<div class="customize-field-other">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [(ngModel)]="customizeFieldOther.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="customizeFieldOther.modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldOther.tinyMceOptions"
      [modelText]="customizeFieldOther.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldOther.contentReportInfo"
            [preSelectItem]="customizeFieldOther.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldOther.fieldInfoOrigin"
            [listValues]="customizeFieldOther.fieldOriginList"
            [preSelectItem]="customizeFieldOther.fieldOrigin"
            (itemSelected)="fieldChangeSelect($event)">
        </field-type-list-simple>
    </div>
    <field-type-list-simple [field]="customizeFieldOther.fieldInfoSelect"
        [listValues]="customizeFieldOther.fieldSelectList"
        [preSelectItem]="customizeFieldOther.fieldPreselect"
        (itemSelected)="fieldConceptSelect($event)">
    </field-type-list-simple>
    <field-type-list-multiple [field]="customizeFieldOther.fieldInfoAdditional"
        [listValues]="customizeFieldOther.additionalFieldsSelectList"
        [preSelectItem]="customizeFieldOther.additionalPreselect"
        (itemSelected)="additionalField($event)">
    </field-type-list-multiple>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option
        [model]="customizeFieldOther.field.multiple"
        [disabled]="customizeFieldOther.field.noEditable"
        [fieldText]="'customize.multiple'"
        (changeOptionEmit)="changeOptionOn($event, 'multiple')">
    </switch-option>
    <switch-option [ngShow]="customizeFieldOther.field.multiple"
        [model]="customizeFieldOther.field.lcoIsUngroupedView"
        [disabled]="customizeFieldOther.field.noEditable"
        [fieldText]="'customize.un-group-view'"
        (changeOptionEmit)="changeOptionOn($event, 'lcoIsUngroupedView')">
    </switch-option>
    <switch-option
        [model]="customizeFieldOther.field.required"
        [disabled]="customizeFieldOther.field.noEditable"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldText()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldText()"
            [afTranslate]="'common.save'">Guardar</button>
    </div>
</div>
