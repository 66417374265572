<div class="schedule-event" 
    [ngClass]="scheduleEvent.originalEvent.className">
    <h4 class="schedule-event__title" 
        highlight="scheduleEvent.searchText">
        {{scheduleEvent.originalEvent.title}}
    </h4>
    <div class="fc-event-main">
        <span class="schedule-event__when ">
            <!-- <i class="schedule-event__icon" ng-class="'event-' + scheduleEvent.originalEvent.type"></i> -->
            <span [innerHTML]="scheduleEvent.originalEvent.when"></span>
            <span [innerHTML]="scheduleEvent.originalEvent.timeText"></span>
        </span>
    </div>
    <p class="schedule-event__description" 
        highlight="scheduleEvent.searchText" 
        [innerHTML]="scheduleEvent.originalEvent.description" 
        [ngShow]="scheduleEvent.originalEvent.description"></p>
    <span class="schedule-event__location" 
        [ngShow]="scheduleEvent.originalEvent.location">
        <span [afTranslate]="'schedule.place'">Lugar:</span>
        <span highlight="scheduleEvent.searchText" 
            [innerHTML]="scheduleEvent.originalEvent.location">
        </span>
    </span>
</div>
