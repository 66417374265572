import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AfTranslateFactory } from '../factory/translate.factory';

@Directive({
  selector: '[afTranslate]'
})
export class AfTranslateDirective implements OnChanges {
  @Input() afTranslate: string = '';
  constructor(private translate: AfTranslateFactory, private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['afTranslate']){
      this.elementRef.nativeElement.innerHTML = this.translate.instant(changes['afTranslate'].currentValue)
    }
  }

}
