<!-- Cabecera -->
<div class="common__table-header duplicate-documentation__table-header">
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan"></div>
    <div class="duplicate-documentation__table duplicate-documentation__table--surname-collegiate">
        <filter-by-search
            [item]="duplicateDocumentation.surnameFilter"
            [filterOrder]="duplicateDocumentation.dateFilter"
            [order]="duplicateDocumentation.order"
            (launchActionSearch)="orderBySearch($event, 'apellidos')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--name-collegiate">
        <filter-by-search
            [item]="duplicateDocumentation.nameFilter"
            (launchActionSearch)="orderBySearch($event, 'nombre')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--number-collegiate">
        <filter-by-search
            [item]="duplicateDocumentation.userNumberFilter"
            (launchActionSearch)="orderBySearch($event, 'numero')">
        </filter-by-search>
    </div>
    <div class="duplicate-documentation__table duplicate-documentation__table--icon-right"></div>
</div>
<!-- Contenido -->
<div class="duplicate-documentation__table-body"
    [ngClass]="{'duplicate-documentation__table-body--more-elements':filterConceptList?.length >= (pageSize*currentPage)-3}">
    <img [src]="duplicateDocumentation.loaderImg"
        class="duplicate-documentation__spinner-loader"
        [ngShow]="duplicateDocumentation.searchingInstances" alt="loader">
    <div class="duplicate-documentation__table-row"
        [ngShow]="!duplicateDocumentation.searchingInstances"
        *ngFor="let instance of filterConceptList
            | afOrderBy:duplicateDocumentation.dateOrder:duplicateDocumentation.orderOperationDates
            | afLimitTo:pageSize:pageSize*(currentPage - 1)"
        (click)="selectInstance(instance)">
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-scan">
            <i class="lf-icon-compliance duplicate-documentation__icon-product-scan"></i>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--surname-collegiate">
            <div class="common__cell-block">
                <parragraph-tooltip [text]="instance.apellidos"></parragraph-tooltip>
            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--name-collegiate">
            <div class="duplicate-documentation__cell-block duplicate-documentation__cell-block--organization">
                <parragraph-tooltip [text]="instance.nombre"></parragraph-tooltip>

            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--number-collegiate">
            <div class="duplicate-documentation__cell-block duplicate-documentation__cell-block--organization">
                <parragraph-tooltip [text]="instance.numero"></parragraph-tooltip>
            </div>
        </div>
        <div class="duplicate-documentation__table duplicate-documentation__table--icon-right">
            <span class="duplicate-documentation__text-explore"
                [afTranslate]="'duplicate-documentation.explore'">Explorar</span>
            <i class="lf-icon-angle-right duplicate-documentation__icon-angle-right"></i>
        </div>
    </div>
</div>
