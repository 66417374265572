<!-- <div> -->
  <div class="donut-graph"
    [ngClass]="{'donut-graph--center': configurateReport.type === 1 && configurateReport.subType === 2,
      'donut-graph--poll': configurateReport.type === 'poll',
      'donut-graph--be':configurateReport.type ==='be'}">
      <div class="donut-graph__graph"
        [id]="idGraph"></div>
      <button [ngShow]="from==='resultReports'" #tooltip="matTooltip"
        class="horizontal-poll-graph__capture"
        (click)="saveAsPng(tooltip)"
        [disabled]="donutGraph.disabledSaveAsPng"
        [matTooltipDisabled]="donutGraph.disableTooltip"
        [matTooltip]="'results-report.capture-image' | afTranslate" matTooltipPosition="above"
        matTooltipPosition="above"
        matTooltipClass="above">
        <i class="lf-icon-all"></i>
      </button>
  </div>
  <div class="donut-graph__block-list-legend"
      [ngShow]="configurateReport.type === 1 && configurateReport.subType === 2">
      <ul class="donut-graph__list-legend">
          <li *ngFor="let legend of donutGraph.graphData?.original"
            class="donut-graph__element-list-legend"
            [ngStyle]="{'color': legend.color}">
              <span class="donut-graph__text-element-legend">{{legend.dataX}}</span>
          </li>
      </ul>
  </div>
<!-- </div> -->
