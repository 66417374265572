import { Component, OnInit, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
    selector: 'menu-header-notifications',
    templateUrl: './menu-header-notifications.component.html'
})
export class MenuHeaderNotificationsComponent implements OnInit, AfterViewInit {
    menuHeaderNotifications:any = {
    };

    constructor(
        private translate: AfTranslateFactory,
        private loader:LoaderFactory,
        private modalService: ModalServiceService,
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiServiceService,
        private stateManagement: StateManagementFactory) { }

    @Output() changeAmount = new EventEmitter();
    @Output() closeMenu = new EventEmitter();
    @Output() goToEmit = new EventEmitter();

    ngOnInit(){
      this.getNotifications();

    }

    ngAfterViewInit(){
        this.loader.closeLoader(['open-list-organizations']);
    }

    deleteNotification(notification:any, e:any){
        e.stopPropagation();
        e.preventDefault();
        this.dismissNotification(notification);
    }

    dismissNotification(notification:any){
        this.modalService.adviceModal(this.translate.instant('header-nav.delete-notice'), 'info:question:question:headerNav')
          .subscribe((result: any) => {
              if(result.result !== 'cancel'){
                this.permanentlyDeleteNotification(notification);
              }
          });
    }

    permanentlyDeleteNotification(notification:any){
        this.apiService.patch('notifications/dismiss', notification.alertNotificationId)
            .then((data:any) => {
                for(let i = 0; i < this.menuHeaderNotifications.notifications.length; i++){
                    if(this.menuHeaderNotifications.notifications[i].alertNotificationId === notification.alertNotificationId){
                        this.menuHeaderNotifications.notifications.splice(i, 1);
                        this.changeAmount.emit();
                        break;
                    }
                }
            },(errorData:any)=>{});
    }

    goToNotification(notification:any){
        this.closeDropdown();
        switch(notification.source){
            case 'Document':
                this.goToConcept(notification, '/documentation');
                break;
            case 'Date':
            case 'Process':
                this.goToConcept(notification, '');
                break;
            case 'Meeting':
            case 'Task':
                if(notification.conceptObjectId){
                    this.goToTaskConcept(notification);
                }else{
                    this.goToTask(notification);
                }
                break;
            default:
                break;
        }
    }

    goToConcept(notification:any, toTab:any){
        let configuration: any = {
            evaluationId: notification.evaluationId,
            loader: 'folder-open-concept',
            url: 'evaluation/' + notification.evaluationId + '/folder/' + notification.folderId + '/concept/' + notification.conceptId + '/concept-evaluated/' + notification.conceptObjectId + toTab
        }
        if(!notification.folderId){
            configuration.type = 'openWindow';
            configuration.url = 'alert-list/' + notification.evaluationId;
        }
        this.goToParent(configuration);
    }

    goToTaskConcept(notification:any){
        let configuration: any = {
            evaluationId: notification.evaluationId,
            loader: 'dashboard-panel-concept-task',
            url: 'evaluation/' + notification.evaluationId + '/folder/' + notification.folderId + '/concept/' + notification.conceptId + '/concept-evaluated/' + notification.conceptObjectId + '/tasks/' + notification.taskId
        }
        this.goToParent(configuration);
    }

    goToTask(notification:any){
        let configuration: any = {
            loader: 'dashboard-panel-generic-task',
            type: 'openWindow',
            url: 'task/' + notification.taskId
        }
        this.goToParent(configuration);
    }

    goToParent(configuration: any){
        this.goToEmit.emit(configuration);
    }

    closeDropdown(){
        this.closeMenu.emit();
    }

  getNotifications() {
    // this.apiService.get('notifications', 'notifiedtome-test')//Para hacer pruebas con notificaciones
    this.apiService.get('notifications', 'notifiedtome')
        .then((data:any) => {
            this.menuHeaderNotifications.notifications = this.showLastNotificacions(data);
        },(errorData:any)=>{console.log(errorData)});
  }

  showLastNotificacions(notifications:any){
    this.menuHeaderNotifications.amountNotifications = 0;
    let now:any = new Date();
    for(let i = 0; i < notifications.length; i++){
        let notification:any = notifications[i];
        let date:any = new Date(new Date(notification.date).getFullYear(),new Date(notification.date).getMonth(), new Date(notification.date).getDate(), 0, 0, 0);
        let delta = Math.round(Math.abs(now - date) / 1000);
        notification.showNotification = (delta <= (60 * 60 * 24 * 7));
        notification.breadcrumb = notification.title;
        this.setTypeIcon(notification);
        if(notification.showNotification){
            this.menuHeaderNotifications.amountNotifications++;
        }
    }
    return notifications;
  }

  setTypeIcon(notification:any){
    switch(notification.source){
        case 'Meeting':
            notification.icon ="lf-icon-meeting";
            break;
        case 'Document':
            notification.icon = "lf-icon-documentation";
            break;
        case 'Date':
            notification.icon = "lf-icon-calendar-check";
            break;
        case 'Task':
            notification.icon ="lf-icon-task-validate";
            break;
        case 'Process':
            notification.icon = "lf-icon-process";
            break;
        default:
            break;
    }
  }
}
