<div class="duplicate-modal__block-tree duplicate-modal__block-tree--children"
  [ngClass]="{'duplicate-modal__block-tree--next-level': branchTree.level === 'nofirst'}"
  *ngFor="let children of childrenList"
  [ngShow]="!children.hidden">
  <i class="duplicate-modal__icon-arrow duplicate-modal__icon-arrow--branch"
      [ngClass]="{'lf-icon-angle-down':children.show ,
        'lf-icon-angle-right':!children.show,
        'duplicate-modal__icon-arrow--visibility':(children.concepts?.length || children.children?.length || children.subFolders?.length) && !children.disabledChildren}"
      (click)="changeShow(children)"></i>
  <div class="duplicate-modal__block-name-evaluation"
      [ngClass]="{'duplicate-modal__block-name-evaluation--locked': children.concepts?.length && branchTree.product === 2,
      'duplicate-modal__block-name-evaluation--disabled':children.disabled}"
      (click)="selectItem(children)">
      <i class="duplicate-modal__icon duplicate-modal__icon--children"
          [ngClass]="{'lf-icon-folder': !children.isFront && !children.hidden && !children.show,
          'lf-icon-folder-user': children.isFront && !children.hidden && !children.show,
          'lf-icon-folder-open':children.show}">
      </i>
      <span class="duplicate-modal__title-children"
          [ngClass]="{'duplicate-modal__title-children--show':children.show ,
          'duplicate-modal__title-selected': children.selected}">
          {{children.name}}
      </span>
  </div>
  <div class="duplicate-modal__block-tree duplicate-modal__block-tree--concept"
      *ngFor="let concept of children.concepts"
      [ngShow]="!concept.hidden && children.show">
      <div class="duplicate-modal__block-name-evaluation"
          [ngClass]="{'duplicate-modal__block-name-evaluation--locked': children.concepts?.length && branchTree.product === 2}"
          (click)="selectItem(children)">
          <i class="duplicate-modal__icon duplicate-modal__icon--concept"
              [ngClass]="{'lf-icon-template duplicate-modal__icon--template': !concept.isFront,
              'lf-icon-template-user': concept.isFront}">
          </i>
          <span class="duplicate-modal__title-children">
            {{concept.title}}
          </span>
      </div>
  </div>
  <branch-tree *ngIf="children.show"
      [childrenList]="children[branchTree.typeChildren]"
      [product]="branchTree.product"
      [type]="type"
      [level]="'nofirst'"
      (selectChildrenEmit)="selectChildrenTree($event)">
  </branch-tree>

</div>
