<div class="new-custom-report new-custom-report__custom-report-list--no-border">
  <div class="common__task-header new-custom-report__header">
      <div class="new-custom-report__new-report-header"
           *ngIf="!predefinedReportField">
          <i class="icon-tag-user new-custom-report__predefined-report-icon-header"></i>
          <span class="new-custom-report__predefined-report-label-header" [afTranslate]="'new-custom-report-field.new-report-field'"></span>
      </div>
      <div class="new-custom-report__predefined-report-header" *ngIf="predefinedReportField">
          <i class="lf-icon-tag new-custom-report__predefined-report-icon-header"></i>
          <div class="new-custom-report__predefined-report-header-labels">
              <span class="new-custom-report__predefined-report-label-header">{{predefinedReportField.customReportFieldName}}</span>
              <span class="new-custom-report__predefined-report-label-header-subtitle" [afTranslate]="'new-custom-report-field.predefined-field-report'"></span>
          </div>
      </div>
      <div class="new-custom-report__wizzard-header" *ngIf="!predefinedReportField">
          <wizard-steps [steps]="newCustomReportField.steps"
                        [state]="newCustomReportField.stepState">
          </wizard-steps>
      </div>
      <div class="new-custom-report__close-header">
          <i class="lf-icon-close common__icon-close-modal new-custom-report__close-icon-header" (click)="closeWindow.emit()"></i>
      </div>
  </div>
  <div class="new-custom-report__block-body" [ngSwitch]="newCustomReportField.stepState">
      <new-custom-report-field-identification *ngSwitchCase="1"
                                        [newCustomReportField]="newCustomReportField.report"
                                        [customReportFieldName]="newCustomReportField.customReportFieldName"
                                        [productFieldInfo]="newCustomReportField.productFieldInfo"
                                        [productList]="productList"></new-custom-report-field-identification>
      <new-custom-report-field-assignment *ngSwitchCase="2"
                                       [newCustomReportField]="newCustomReportField.report"
                                       [validationErrors]="newCustomReportField.formErrors"
                                       [editedCustomReportField]="editedCustomReportField"
                                       [predefinedReportField]="predefinedReportField"></new-custom-report-field-assignment>
  </div>
  <div class="new-custom-report__body-block">
      <div class="new-custom-report__buttons-block">
          <div class="common__buttons-block">
              <button type="reset" class="common__button common__button--clean"
                      (click)="closeWindow.emit()"
                      [afTranslate]="'common.exit'"
                      *ngIf="predefinedReportField"></button>
              <button type="reset" class="common__button common__button--clean"
                      (click)="closeWindow.emit()"
                      [afTranslate]="'common.cancel'"
                      *ngIf="!predefinedReportField"></button>
              <button type="submit" class="common__button common__button--clean"
                      (click)="previousPage()"
                      [afTranslate]="'common.previous'"
                      *ngIf="newCustomReportField.stepState === 2 && !predefinedReportField"></button>
              <button class="common__button common__button--clean"
                      (click)="clearForm()"
                      [afTranslate]="'common.clean'"
                      *ngIf="newCustomReportField.stepState === 1 && !predefinedReportField"></button>
              <button type="submit" class="common__button common__button--active"
                      (click)="nextPage()"
                      [afTranslate]="'common.next'"
                      *ngIf="newCustomReportField.stepState === 1 && !predefinedReportField"></button>
              <button type="submit" class="common__button common__button--active"
                      (click)="editOrCreateCustomReportField()"
                      [afTranslate]="'common.save'"
                      *ngIf="newCustomReportField.stepState === 2 && !predefinedReportField"></button>
          </div>
      </div>
  </div>
</div>
