import html2canvas from 'html2canvas';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ReportRecordDetailService } from '../utils/report-record-detail.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ReportRecordDetailComponent } from '../report-record-detail/report-record-detail.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'report-record-default',
  templateUrl: './report-record-default.component.html'
})

export class ReportRecordDefaultComponent implements OnInit {
  archived: boolean = this.route.snapshot.data['archived']==='archived'
  configurateReport:any={};
  reportRecordDetail: any = {
    viewTableHtml: 'detail',
    openFocusDetail: false,
    orderOperationDates: true,
    searchDetail: '',
    searchMethod: '',
    searchName: '',
    dateOrder: '',
    filter: [],
    filterReportRecordDetailList: [],
    filterDocumentationList: [],
    findFilters: [],
    
    reporDetailView: [
      {
          name: this.translate.instant('report-record-detail.detail-report'),
          viewOption: 'detail'
      },
      {
          name: this.translate.instant('common.documentation'),
          viewOption: 'documentation'
      }
    ]
  };
  constructor(private route:ActivatedRoute,
    private reportRecordDetailService: ReportRecordDetailService,
    private translate: AfTranslateFactory) {}

  @Input() rows:any = this.route.snapshot.data['rows'];
  @Input() columns:any = this.route.snapshot.data['columns'];
  @Input() report:any = this.route.snapshot.data['report'];
  @Input() reportfields:any;
  @ViewChild(ReportRecordDetailComponent) reportRecordDetailComponent: ReportRecordDetailComponent;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  ngOnInit(): void {
    $('body').addClass('report-record-detail-body');
    this.reportRecordDetailService.getDocumentationList(this);
    let dataSession: any = sessionStorage.getItem('dashboardReportsSession');
    dataSession = JSON.parse(dataSession);
    if(dataSession){
      this.reportRecordDetail.from = dataSession.reportId;
    }
    this.reportRecordDetail.selectedView = this.reportRecordDetail.reporDetailView[0],
    this.configurateReport = {
      excelCharts: this.report.excelCharts,
      type: 13,
      subType: this.report.subType
  };

  }

  changeViewTable(view:any){
    this.dropdown.close();
    this.reportRecordDetail.selectedView = view;
    this.reportRecordDetail.viewTableHtml = this.reportRecordDetail.selectedView.viewOption
  }

  changeViewWindow(){
    this.reportRecordDetailService.changeViewWindow(this)
  }

  updateReport(){
    this.reportRecordDetailService.updateReport(this);
  }

  changeSubView(type:string){
    this.reportRecordDetail.subTabSelected = type;
  }

  exportReport(){
    let filterReportRecordDetailList: any = this.reportRecordDetail.filterReportRecordDetailList;
    this.reportRecordDetailService.exportReport(this, filterReportRecordDetailList)
  }

  changeView(){
    if(this.reportRecordDetail.selectedView.viewOption !== 'graph'){
      this.reportRecordDetail.selectedView = {
        name: this.translate.instant('report-table.view-graph'),
        viewOption: 'graph'
      }
    }else{
      this.reportRecordDetail.selectedView = {
        name: this.translate.instant('report-table.view-list'),
        viewOption: 'detail'
      }
    }
    this.reportRecordDetail.viewTableHtml = this.reportRecordDetail.selectedView.viewOption
  }

  saveAsPng(tooltip: MatTooltip) {
    let documentObject: any = document;
    let that = this;   
    that.reportRecordDetail.disabledSaveAsPng = true;     
    setTimeout(() => {
      html2canvas(documentObject.querySelector(".report-graphs"))
      .then(function (canvas) {
        canvas.toBlob((blob: any) => {
          let data = [new ClipboardItem({ [blob.type]: blob })];
          navigator.clipboard.write(data)
            .then(() => {}, (err) => console.log(err));
        });
      }).then(() => {
        that.reportRecordDetail.disabledSaveAsPng = false;
        tooltip.message = this.translate.instant('results-report.image-captured');
        tooltip.show();
        setTimeout(() => {
          tooltip.message = this.translate.instant('results-report.capture-image');
          tooltip.hide();
        }, 2000);
      });
    }, 100);
  }

}
