
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GenerateDocumentParentService } from '../utils/generate-document-parent.service';


@Component({
  selector: 'generate-document-custom',
  templateUrl: './generate-document-custom.component.html'
})

export class GenerateDocumentCustomComponent implements OnInit {

  custom: any = this.customLanding.getCustom();
  generateDocument: any = {
    document: {
      properties: {},
      name: '',
      templateName:''
    },
    documentName: {
        id:'1',
        required:  true,
        label: this.translate.instant('generate-document.name-document')
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private generateDocumentParent: GenerateDocumentParentService,
    private activeModal: NgbActiveModal) {

  }

  @Input() predefinedTemplate: any = null;
  @Input() concept: any = null;

  ngOnInit(): void {
    this.generateDocumentParent.onInit(this)
  }

  itemSelected(e:any, type:any){
    this.generateDocument.document[type] = e.newValue;
  }

  handleAccept(){
    this.generateDocumentParent.handleAccept(this);
  }

  handleDismiss(){
    this.generateDocumentParent.handleDismiss(this)
  }
}
