<div class="float-start"
    [ngShow]="tooltipInfo.descriptionTooltip"
    *ngIf="!isLanding"
    ngbDropdown
    [autoClose]="'outside'"
    (openChange)="close()">
    <div ngbDropdownToggle>
        <button class="common__tooltip-alert-icon"
          [ngClass]="{'lf-icon-close-round': tooltipInfo.showInfo,
              'lf-icon-info-full': !tooltipInfo.showInfo}"></button>
    </div>
    <div class="common__tooltip-dropdown"
        ngbDropdownMenu role="menu">
        <div class="historic-concept__list-user historic-concept__list-user--tooltip-info">
            <div [id]="'inner-text-' + id"
                class="common__tooltip-inner-text"
                [innerHTML]="tooltipInfo.descriptionTooltip">
            </div>
        </div>
    </div>
</div>
<div class="float-start"
    *ngIf="isLanding && !isSequentialPoll"
    ngbDropdown
    [autoClose]="'outside'"
    (openChange)="close()">
    <div ngbDropdownToggle>
        <button class="lf-icon-info-full common__tooltip-alert-icon common__tooltip-alert-icon--custom"></button>
    </div>
    <div class="common__tooltip-dropdown-custom"
        ngbDropdownMenu role="menu">
        <button class="lf-icon-close-round common__icon-close-round-custom-info"
            (click)="close()"></button>
        <div class="common__description-tooltip-info-custom">
            <div [id]="'inner-text-' + id"
                class="common__block-html-info"
                [innerHTML]="tooltipInfo.descriptionTooltip">
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLanding && isSequentialPoll"
    ngbDropdown
    [ngShow]="tooltipInfo.descriptionTooltip"
    [autoClose]="'outside'"
    (openChange)="close()">
    <div class="common__tooltip-info-custom-icon-poll"
        ngbDropdownToggle>
        <button class="lf-icon-info-full common__tooltip-alert-icon common__tooltip-alert-icon--custom common__tooltip-alert-icon--poll"></button>
    </div>
    <div class="common__tooltip-dropdown-custom common__tooltip-dropdown-custom--poll"
        ngbDropdownMenu role="menu">
        <button class="lf-icon-close-round common__icon-close-round-custom-info"
            (click)="close()"></button>
        <div class="common__description-tooltip-info-custom">
            <div [id]="'inner-text-' + id"
                class="common__block-html-info"
                [innerHTML]="tooltipInfo.descriptionTooltip">
            </div>
        </div>
    </div>
</div>
