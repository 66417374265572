import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'evaluation-import',
  templateUrl: './evaluation-import.component.html'
})
export class EvaluationImportComponent implements OnInit {
  access:any = this.route.snapshot.queryParams['access'];

  evaluationImport: any = {
    ready: false,
    showNoPermissionsWarning: false,
    noPermissionsContinueText: this.translate.instant('evaluation-import.buttons.continue'),
    dialog: {},
    toDashboardButton: {
        id: "continue",
        text: this.translate.instant('evaluation-import.buttons.continue'),
        active: true,
        clickAction: 'toDashboard'
    },
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService) { }

  ngOnInit(): void {
    this.getSharedEvaluation(this.access);
  }

  getSharedEvaluation(access: any) {
      this.loader.openLoader('front-login-login');
      this.apiService.get('sharedevaluations?access=' + encodeURIComponent(this.access))
        .then((data:any)=> {
            this.evaluationImport.sharedEvaluation = data;
            this.setConfirmImportDialog();
            this.loader.closeLoader(['front-login-login']);
            this.evaluationImport.ready = true;
        }, (errorData:any)=> {
            this.loader.closeError();
            if(errorData.status === 404){
                this.setNotSharedText();
            }else if(errorData.status === 403){
                this.setNoSubscriptionDialog();
            }else{
                this.setErrorDialog(this.translate.instant('evaluation-import.unexpected-error-loading'));
            }
            this.evaluationImport.ready = true;
        });
  }

  setConfirmImportDialog() {
      this.evaluationImport.dialog = {
          heading: this.translate.instant('evaluation-import.heading'),
          text: this.translate.instant('evaluation-import.confirm-text'),
          icon: 'lf-icon-label-validate',
          buttons: [
              {
                  text: this.translate.instant('evaluation-import.buttons.cancel'),
                  active: false,
                  clickAction: 'toDashboard'
              },
              {
                  text: this.translate.instant('evaluation-import.buttons.import'),
                  active: true,
                  clickAction: 'doImport'
              }
          ]
      };
  }

  setNotSharedText() {
    this.evaluationImport.dialog = {
        heading: this.translate.instant('evaluation-import.not-shared-heading'),
        text: this.translate.instant('evaluation-import.not-shared-text'),
        icon: 'lf-icon-face-sad',
        buttons: [this.evaluationImport.toDashboardButton]
    };
  }

  setNoSubscriptionDialog() {
    this.evaluationImport.dialog = {
        heading: this.translate.instant('evaluation-import.no-subscription-heading'),
        text: this.translate.instant('evaluation-import.no-subscription-text'),
        icon: 'lf-icon-key',
        buttons: [this.evaluationImport.toDashboardButton]
    };
  }

  setErrorDialog(text: any) {
    this.evaluationImport.dialog = {
        heading: this.translate.instant('evaluation-import.error-heading'),
        text: text,
        icon: 'lf-icon-face-sad',
        buttons: [this.evaluationImport.toDashboardButton]
    };
  }

  setEvaluationsLimitDialog() {
    this.evaluationImport.dialog = {
        heading: this.translate.instant('evaluation-import.limit-heading'),
        text: this.translate.instant('evaluation-import.limit-text'),
        icon: 'lf-icon-warning',
        buttons: [this.evaluationImport.toDashboardButton]
    };
  }

  clickAction(action: any){
    switch(action){
      case 'toDashboard':
        this.toDasboard();
        break;
      case 'doImport':
        this.doImport();
        break;
      case 'toEvaluation':
        this.toEvaluation();
        break;
      case 'noPermissions':
        this.noPermissions();
        break;
    }
  }

  toDasboard(){
    this.router.navigate(['dashboard']);
  }

  doImport() {
    this.evaluationImport.showNoPermissionsWarning = false;
    this.loader.openLoader('evaluation-import');
    this.apiService.patch('sharedevaluations', this.evaluationImport.sharedEvaluation.sharedEvaluationId + '/import')
      .then((data:any)=> {
          this.evaluationImport.newEvaluation = data;
          let text : string = this.translate.instant('evaluation-import.import-ok-text').replace('{0}', data.evaluationName);
          if(data.newClientName){
              text += ' ' + this.translate.instant('evaluation-import.import-ok-new-client-text').replace('{0}', data.newClientName);
          }
          this.evaluationImport.dialog = {
              heading: this.translate.instant('evaluation-import.heading'),
              text: text,
              icon: 'lf-icon-label-validate',
              buttons: [
                  {
                      text: this.translate.instant('evaluation-import.buttons.access'),
                      active: true,
                      clickAction: data.hasAccess?'toEvaluation':'noPermissions'
                  }
              ]
          };
          this.loader.closeLoader(['evaluation-import']);
      }, (errorData:any)=> {
          this.loader.closeError();
          if(errorData.status === 409){
              this.setEvaluationsLimitDialog();
          }else{
              this.setErrorDialog(this.translate.instant('evaluation-import.unexpected-error-importing'));//.replace('{0}', _sharedEvaluation.name));
          }
      });
  }

  noPermissions(){
    this.evaluationImport.showNoPermissionsWarning = true;
  }

  toEvaluation(){
    this.router.navigate(['evaluation', this.evaluationImport.newEvaluation.evaluationId]);
  }

}


