import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colors'
})
export class ColorsPipe implements PipeTransform {

  transform(item: any, type:string=''): unknown {
    switch(type){
      case 'icon-filter-list':
          return _progressColors(item);
      default:
          return _defaultColors(item);
    }
  }

}

  function _defaultColors(item: any){
      switch(item){
          case 3: //'En progreso'
          case 'En proceso':
          case 'progress':
              return "yellow";

          case 2: //'Stand by'
          case 'Descartada':
          case 'Atrasado':
              return "orange";

          case 1: //'Abierta'
          case 'Sin empezar':
              return "red";

          case 6://'Cerrada sin resolver'
              return "brown";

          case 5://'Cerrada'
              return "grey";

          default:
              return "green";
      }
  }

  function _progressColors(item:any){
      switch(item){
          case 'started':
          case '3-more-time':
              return 'red';
          case 'in-progress':
          case '2-two-days':
              return 'yellow';
          case 'advanced':
          case '1-last-day':
              return 'green';
          default:
              return 'grey';
      }
  }

