import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'field-type-list-extended',
  templateUrl: './field-type-list-extended.component.html'
})
export class FieldTypeListExtendedComponent implements OnChanges {
  isLanding: any = this.route.snapshot.data['isLanding'];
  findItem: any = null;
 
  fieldTypeListExtended: any={
    markAll: false,
    inReport: [],
    titleField: '',
    listElementSelect: [],
    simpleList:'',
    findDropdown: '',
    orderList: ['numOrder'],
    // orderAsc: false
  }
  constructor(private route: ActivatedRoute,
    private commonUtilsService: CommonUtilsService) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() listValues: any = [];
  @Input() fieldToFind: string = 'text';

  @Output() itemSelected = new EventEmitter();
  @Output() showFieldReference = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnChanges(): void {
    if(this.field){
      this.fieldTypeListExtended.titleField = (this.field.extendedLabel && this.field.extendedLabel !== '')? this.field.extendedLabel : this.field.label;
      if(this.preSelectItem && this.preSelectItem !== null && this.listValues && this.field.multiple){
        this.extractMultilineList();
      }else if(this.preSelectItem === null && this.fieldTypeListExtended.listElementSelect && this.fieldTypeListExtended.listElementSelect.length && this.field.multiple){
          this.fieldTypeListExtended.listElementSelect = [];
      }
      if(!this.field.multiple && this.listValues && this.listValues.length){
          this.extractSimpleValue();
      }
      if(this.field.inReport !== null && this.field.inReport !== undefined){
        this.fieldTypeListExtended.inReport = this.field.inReport.indexOf('|') === -1? [this.field.inReport] : this.field.inReport.split('|')
      }
    }
  }

  toggleAll() {
    let that = this.fieldTypeListExtended;
    that.markAll = !that.markAll;
    let filterListSearch: any = this.commonUtilsService.findByWord(this.listValues, that.findDropdown, [this.fieldToFind] );
    for (let i = 0; i < filterListSearch.length; i++) {
        let element = filterListSearch[i];
        if (element.selected != that.markAll) {
            this.changeMultipleSelected(element);
        }
    }
  }

  calculeWidthElements(){
    this.fieldTypeListExtended.widthElement = this.fieldTypeListExtended.listElementSelect.length >= 5? 'calc('+(100/5) + '% - 2px)':'auto';
  }

  extractSimpleValue(){
    this.listValues.forEach((value:any) => {
      value.selected = value.listValueId === this.preSelectItem;
      if(value.selected){
        this.fieldTypeListExtended.simpleList = value.text
      }
    });
  }

  extractMultilineList(){
    this.fieldTypeListExtended.listElementSelect = [];
    // let items:any;
    // if (!Number.isInteger(this.preSelectItem) && this.preSelectItem.indexOf('|') !==-1) {
    //     items = this.preSelectItem.split('|');
    // } else {
    //     items = [this.preSelectItem];
    // }
    let items:any = !Number.isInteger(this.preSelectItem) && this.preSelectItem.indexOf('|') !==-1? this.preSelectItem.split('|') : [this.preSelectItem];
    items.forEach((item:any) => {
      this.findItem = null;
      if(this.listValues){
          item = item !== 'all'? parseInt(item) : item;
          this.getListValueSelected(this.listValues, item);
          if(this.findItem !== null){
            this.fieldTypeListExtended.listElementSelect.push(this.findItem);
          }
      }
    });
    if(this.listValues.length > 10){
      this.calculeWidthElements();
    }
  }

  getListValueSelected(listValues:any, preSelectItem:any){
    for(let i = 0; i < listValues.length; i++){
        let value:any = listValues[i];
        if(value.listValueId && (value.listValueId === preSelectItem)){
            value.selected = true;
            this.findItem = value;
        }
        if(this.findItem !== null){
            break;
        }
    }
  }
  changeSelected(element:any){
    if(!this.field.noEditable){
        this.preSelectItem = element.listValueId;
        this.itemSelected.emit({newValue:element.listValueId});
        this.extractSimpleValue();
    }
  }

  changeMultipleSelected(element:any){
    if(!this.field.noEditable){
        let exist:boolean = true;
        let listElement:any = this.fieldTypeListExtended.listElementSelect;
        for(let i = listElement.length-1; i >= 0; i--){
          let elementSelect: any = listElement[i]
            if((element.listValueId && element.listValueId === elementSelect.listValueId) || (element.referenceId && element.referenceId === elementSelect.referenceId)){
                this.fieldTypeListExtended.markAll = false;
                elementSelect.selected = false;
                listElement.splice(i, 1);
                exist = false;
                if(!listElement.length){
                    break;
                }
            }
        }
        if(exist){
            element.selected = true;
            listElement.push(element);
        }
        this.configureListVarchar();
    }
  }

  configureListVarchar(){
    let listElement:any = this.fieldTypeListExtended.listElementSelect;
    let nvarcharValue:string ='';
    for(let i = 0; i < listElement.length; i++){
      let elementSelect: any = listElement[i]
      nvarcharValue += elementSelect.listValueId + (listElement.length-1 === i?'':'|');
      if(this.field.multiple && this.listValues.length > 10){
          this.calculeWidthElements();
      }
    }
    this.itemSelected.emit({ newValue: nvarcharValue, listSelected: this.fieldTypeListExtended.listElementSelect});
  }

  addOrDeleteSelectedValue(e:any, listElement:any = null){
    if(!this.field.noEditable){
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if(this.field.isTree && this.field.multiple){
            if(!listElement.children.length){
                this.catchItem(listElement);
            }
        }else{
            this.field.multiple? this.catchItem(listElement) : this.catchItemSingle();
        }
    }

}

  catchItemSingle(){
    this.fieldTypeListExtended.simpleList = '';
    this.preSelectItem = null;
    this.itemSelected.emit({newValue:null});
    for(let i = 0; i < this.listValues.length; i++){
        this.listValues[i].selected = false;
    }
  }

  resetSearch(e:any){
    e.stopPropagation();
    e.preventDefault();
    this.fieldTypeListExtended.findDropdown = '';
  }

  catchEvent(e:any){
    e.stopPropagation();
  }

  catchItem(element:any){
    let exist:boolean = true;
    let listElement:any = this.fieldTypeListExtended.listElementSelect;
    for(let i = 0; i < listElement.length; i++){
        if((element.listValueId && element.listValueId === listElement[i].listValueId) || (element.referenceId && element.referenceId === listElement[i].referenceId)){
            listElement[i].selected = false;
            listElement.splice(i, 1);
            exist = false;
            break;
        }
    }
    if(exist){
      element.selected = true;
      listElement.push(element);
    }
    if(this.field.multiple && this.listValues.length > 10){
        this.calculeWidthElements();
    }
    this.configureListVarchar();
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    e.stopPropagation();
    e.preventDefault();
    this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
}

