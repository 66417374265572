import Q from 'q';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Injectable({
  providedIn: 'root'
})
export class AfTranslateFactory {
  availableLanguages: any = [];
  siteTerms: any = {};
  languagesTranslated: any = {};
  languageDefault:string = '';
  languageToUse: string = '';
  languageToTranslate: any = null;
  languageBaseToUse: string = '';
  preferLanguage: string = '';
  constructor(private http: HttpClient,
              private globalCfg : GlobalCfgFactory) { }


  setDefaultLang(language:string){
    let defer: any = Q.defer();
    this.languageDefault = cloneDeep(language);
    return axios.get('assets/i18n/'+language+'.json')
      .then((res:any)=>{
      this.languagesTranslated[language] = res.data;
      this.languageToTranslate = res.data;
        return defer.resolve();
      }, (errorData)=>{
        return defer.reject()
      })
  }

  setSiteTerms(){
    let defer: any = Q.defer();
    return axios.get('assets/data/siteTerms.json')
      .then( (res) => {
        this.siteTerms = res.data;
          return defer.resolve();
      }, () =>{
        return defer.reject()
      })
  }


  registerAvailableLanguageKeys(languages: any){
    this.availableLanguages = cloneDeep(languages)
  }

  preferredLanguage(language:string){
    this.preferLanguage = cloneDeep(language);
  }

  languagesUseBase(language: string) {
    this.languageBaseToUse = language;
  }

  getTranslates(language: string){
    let defer: any = Q.defer();
    return axios.get('assets/i18n/'+language+'.json')
      .then((res:any)=>{
      this.languageToUse = language;
      this.languagesTranslated[language] = res.data;
      this.languageToTranslate = res.data;
      return defer.resolve();
      }, (errorData)=>{
        return defer.reject()
      })
  }

  instant(text:string): string{
    if(this.languageToTranslate){
      let obj:any = this.languageToTranslate;
      text = text?text:'';
      text.split('.').forEach((key:string)=>{
        obj = obj[key]?obj[key]:text;
      })
      return obj;
    }
    return text;
  }

  instantSiteTerms(text: string) {
    return this.siteTerms[this.globalCfg.skin][this.languageBaseToUse][text]
  }

  instantTo(text:string, language:string){
    if(Boolean(this.languagesTranslated[language])){
      let obj:any = this.languagesTranslated[language];
      text = text?text:'';
      text.split('.').forEach((key:string)=>{
        obj = obj[key];
      })
      return obj;
    }else{
      this.http.get('assets/i18n/'+language+'.json').subscribe((res:any) => {
        this.languagesTranslated[language] = res;
        text = text?text:'';
        text.split('.').forEach((key:string)=>{
          res = res[key];
        })
        return res;
      });
    }
  }

  hasLanguage(language:any){
    return Boolean(this.languagesTranslated[language])
  }

  setLanguageToTranslate(language:any){
    this.languageToUse = language;
    this.languageToTranslate = this.languagesTranslated[language];
  }
}
