<div [class]="'compliance'+skin">
  <div class="notification-preview">
    <header class="notification-preview__header">
        <button class="common__icon-button notification-preview__close"
            (click)="closeModal()">
            <span [afTranslate]="'notification-preview.close'">Cerrar vista previa</span>
            <i class="lf-icon-close"></i>
        </button>
    </header>
    <main class="notification-preview__main">
        <div class="process-modal__html" [innerHTML]="notificationPreview.html"></div>
    </main>
  </div>
</div>

