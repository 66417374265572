import { Component, Input, Output, OnChanges, EventEmitter} from '@angular/core';


import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ConceptLegalParentsService } from '../utils/concept-legal-parents.service';


@Component({
  selector: 'concept-legal-documentation-default',
  templateUrl: './concept-legal-documentation-default.component.html'
})
export class ConceptLegalDocumentationDefaultComponent implements OnChanges {
  userPermissions: any = this.permissions.getPermissions();


  conceptLegalDocumentation:any = {
    fullScreenBox:'',
    fullScreen: false,
  }

  constructor(private permissions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private conceptLegalParent: ConceptLegalParentsService) {
  }

  @Input() referencesList: any = [];
  @Input() fullScreenBox: any = null;
  @Input() concept: any = null;

  @Output() catchFullScreen = new EventEmitter();
  @Output() generateDocument = new EventEmitter();

  ngOnChanges(){
    this.conceptLegalParent.onChanges(this)
  }

  maximizeScreen(keepClassBox:any = ''){
    this.conceptLegalParent.maximizeScreen(keepClassBox, this)
  }

  createDocumentTemplate(template:any){
    this.generateDocument.emit({template: template})
  }

  showLegalDocumentation(e:any, reference:any){
    this.conceptLegalParent.showLegalDocumentation(e, reference, this)
  }

  ShowTooltip(id:any){
    return this.commonUtilsService.showTooltip(id)
  }
}
