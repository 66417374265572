import { Injectable } from '@angular/core';

import axios from 'axios';

import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { CommonUtilsService } from '../common-utils/common-utils.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { BroadcastService } from '../broadcast/broadcast.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  constructor(
    private apiProperties: ApiPropertiesFactory,
    private commonUtils: CommonUtilsService,
    private context: ContextFactory,
    private broadcastService: BroadcastService,
    private translate: AfTranslateFactory,
    private httpInterceptor: HttpInterceptorService
  ) {
    this.httpInterceptor.interceptorResponse();
  }

  getConfig(){
    return axios.get('assets/config/clientConfig.json')
      .then((response:any)=>{
        return response.data;
      }, (errorData:any)=>{
        return this.wsError(errorData);
      });
  }

  getGlobal(){
    return axios.get(this.apiProperties.apiURL+'globalcfg')
      .then((response:any)=>{
        return response.data;
      }, (errorData:any)=>{
        return this.wsError(errorData);
      });
  }

  get(path:string, id: any = '', jwtToken: any = this.context.jwtToken) {
    let url = this.apiProperties.apiURL + path;
    if (id) url += '/' + id;
    return this.execute('get', url, undefined, jwtToken);
  }


  getWithTranslate(path: string, id: any = '', jwtToken: any = this.context.jwtToken){
      let url = this.apiProperties.apiURL + path;
      if (id) url += '/' + id;
      url+= '/' + this.commonUtils.getLanguage();
      return this.execute('GET', url, undefined, jwtToken);
  }

  getBlob(path:any, id: any=undefined, method: any='GET', data: any={}) {
    let url = this.apiProperties.apiURL + path;
    if (id) url += '/' + id;

    url = this.addLanguageToQuery(url);
    return axios({
        method: method || 'GET',
        url: url,
        data: data,
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + this.context.jwtToken
        }
    }).then((response:any)=>{
      return this.downloadOk(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
  }

  downloadOk(response: any) {
    let contentType = response.headers['content-type'];
    let contentDisposition = response.headers['content-disposition'];
    let fileName = undefined;
    if (contentDisposition) {
        let matches = /; filename="(.+)"/.exec(contentDisposition);
        let matchesAccentMark = /filename\*=UTF-8''"(.+?)"/.exec(contentDisposition);
        if(matchesAccentMark?.length === 2){
          fileName = decodeURIComponent(matchesAccentMark[1]);
        }else if (matches?.length === 2) {
            fileName = matches[1];
        }else{
            matches = /; filename=(.+)/.exec(contentDisposition);
            if (matches?.length === 2) {
                fileName = matches[1];
            }
        }
    }

    return {
        contentType: contentType,
        fileName: fileName,
        blob: response.data
    };
  }

  postBlob(path:any, data: any={}){
    let url = this.apiProperties.apiURL + path;
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'POST',
        url: url,
        data: data,
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + this.context.jwtToken
        }
    }).then((response:any)=>{
      return this.downloadOk(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
  }

  // externalPostBlob(path: any, data: any){
  //     let url = path;
  //     return axios({
  //         method: 'POST',
  //         url: url,
  //         data: data,
  //         responseType: 'blob',
  //         headers: {
  //             Authorization: 'Bearer ' + this.context.jwtToken,
  //             // ContentType: "application/x-www-form-urlencoded"
  //         }
  //     }).then((response:any)=>{
  //       // let contentType = response.headers('content-type');
  //       return {
  //           contentType: response.data.type,
  //           fileName: data.nameDocument,
  //           blob: response.data
  //       };
  //     }, (errorData:any)=>{
  //       return this.wsError(errorData);
  //     });
  // }

  getNoBearer(path: string, id: any){
    let url = this.apiProperties.apiURL + path;
    if (id) url += '/' + id;

    url = this.addLanguageToQuery(url);
    return axios({
        method: 'GET',
        url: url

    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });

  }

  add(path: string, data: any = null) {
    let url = this.apiProperties.apiURL + path;
    return this.execute('POST', url, data);
  }

  update(path: string, id: any, data: any) {
    let url = this.apiProperties.apiURL + path;
    if (id) {
        url += '/' + id;
    }
    return this.execute('PUT', url, data);
  }
  patch(path:string, id: any='', data: any = {}) {
    let url = this.apiProperties.apiURL + path;
    if (id) {
        url += '/' + id;
    }
    return this.execute('PATCH', url, data);
  }

  patchAsPost(path: string, data: any) {
    let url = this.apiProperties.apiURL + path;
    return this.execute('PATCH', url, data);
  }

  patchInt(path: string, data: any){
    let url = this.apiProperties.apiURL + path + '/' +data
    return this.execute('PATCH', url, data);
  }

  delete(path: string, id: any, type: any = '') {
    let url;
    if (typeof id !== 'object') {
        url = this.apiProperties.apiURL + path + '/' + id;
        if(type){
            url += '/' + type;
        }
        return this.execute('DELETE', url, null);
    } else {
        url = this.apiProperties.apiURL + path;
        return this.execute('DELETE', url, id);
    }
  }

  login(params: any, action: any, gu: boolean = false) {
    let url = this.apiProperties.apiURL + 'login/' + action;
    if (action === 'sso' && gu) {
      url += '?gu=true'
    }
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'POST',
        url: url,
        data:params
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });

  }

  loginDoubleOptIn(params: any) {
    let url = this.apiProperties.apiURL + 'login/doubleoptin';
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'POST',
        url: url,
        data: params
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });

  }

  resetPassword(params:any) {
    let action = 'login/front/resetpassword';
    let url = this.apiProperties.apiURL + action;

    url = this.addLanguageToQuery(url);
    return axios({
        method: 'PATCH',
        url: url,
        data: params
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });

  }

  attachFilePatch(url:string, file:any) {
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'PATCH',
        url: this.apiProperties.apiURL + url,
        data: file,
        headers: {
            Authorization: 'Bearer ' + this.context.jwtToken,
            accept: 'application/json;odata=verbose'
        }
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
    // .then(this.unwrapResponse).catch(this.wsError);
  }

attachFile(url: any, file: any){
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'POST',
        url: this.apiProperties.apiURL + url,
        headers: {
          Authorization: 'Bearer ' + this.context.jwtToken,
          accept: 'application/json;odata=verbose'
        },
        data: file,
        //transformRequest: angular.identity
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
}

attachFileWithProgress(url:any, file:any){
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'POST',
        url: this.apiProperties.apiURL + url,
        headers: {
            "Authorization": 'Bearer ' + this.context.jwtToken,
            "accept": "application/json;odata=verbose"
        },
        onUploadProgress: (progressEvent:any)=> {
          this.broadcastService.broadcast('uploadFile'+file.name, {percent:(progressEvent.loaded * 100 / progressEvent.total)});
        },
        data: file.document,
    }).then(this.unwrapResponse);
}

// attachFilePatch(url, file) {
//     url = this.addLanguageToQuery(url);
//     return axios({
//         method: 'PATCH',
//         url: this.apiProperties.apiURL + url,
//         headers: {
//             "Authorization": 'Bearer ' + context.jwtToken,
//             "accept": "application/json;odata=verbose",
//             "Content-Type": undefined
//         },
//         processData: false,
//         data: file,
//         //transformRequest: angular.identity
//     }).then(this.unwrapResponse);
// }
// addLogo(url, file){
//   url = this.addLanguageToQuery(url);
//   return axios({
//       method: 'POST',
//       url: this.apiProperties.apiURL + url,
//       headers: {
//           "Authorization": 'Bearer ' + context.jwtToken,
//           "accept": "application/json;odata=verbose",
//           "Content-Type": undefined
//       },
//       processData: false,
//       data: file,
//       //transformRequest: angular.identity
//   }).then(this.unwrapResponse);
// }

  rename(path: string, data: any){
    let url = this.apiProperties.apiURL + path;
    url = this.addLanguageToQuery(url);
    return axios({
        method: 'PATCH',
        url: url,
        headers: {
            'Authorization': 'Bearer ' + this.context.jwtToken
        },
        data: data
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
    // .then(this.unwrapResponse).catch(this.wsError);

  }

  moveOrCopy(path: string, method:any){
    let url = this.apiProperties.apiURL + path;
    url = this.addLanguageToQuery(url);
    return axios({
        method: method,
        url: url,
        headers: {
            'Authorization': 'Bearer ' +this.context.jwtToken
        }
    }).then((response:any)=>{
      return this.unwrapResponse(response);
    }, (errorData:any)=>{
      return this.wsError(errorData);
    });
    // .then(this.unwrapResponse).catch(this.wsError);
  }

  execute(method:any, url:string, data:any, jwtToken:any = this.context.jwtToken) {
      url = this.addLanguageToQuery(url);
      let headers: any = {};
      let anonymous = jwtToken === true;
      if (!anonymous) {
          headers['Authorization'] = 'Bearer ' + jwtToken ;
      }
      if (method === 'DELETE' && typeof data === 'object') {
          headers['Content-Type'] = 'application/json';
      }

      return axios({
          method: method,
          url: url,
          headers: headers,
          data: data
      }).then((response:any)=>{
        return this.unwrapResponse(response);
      }).catch((errorData:any)=>{
        return this.wsError(errorData);
      });
  }

  addLanguageToQuery(url: string) {
      let uiLanguage =  sessionStorage.getItem('selectLanguage') || this.apiProperties.defaultLanguage;
      if (uiLanguage) {
          if (url.lastIndexOf("?") == -1) {
              url += "?language=" + uiLanguage;
          } else {
              url += "&language=" + uiLanguage;
          }
      }
      return url;
  }
  getUrl(url:any){
    return axios.get(url)
      .then((response:any)=>{
        return response.data;
      }, (errorData:any)=>{
        return this.wsError(errorData);
      });
  }

  

  unwrapResponse(response: any) {
      let wrap = response.data;
      if (wrap.status >= 200 && wrap.status < 300) {
          return wrap.data;
      } else {
          response.status = wrap.status;
          response.data = wrap.data;
          throw response;
      }
  }


  wsError(response: any) {
    let url = response.config && response.config.url ? response.config.url : 'unknow';
    let logFunc = this.apiProperties.development ? console.error : console.log;
    let errorMessage = response.data?.exceptionMessage? response.data.exceptionMessage : response.data?response.data:'no body';
    logFunc('Status ' + response.status + ' ' + response.config.method + ' to url "' + url + '": ' + errorMessage);
    console.log(response.data);
    let exception: any = {};
    exception.status = response.status;
    if (response.status === 400) {
        if (response.data && typeof (response.data) === 'object') {
            if (typeof (response.data) === 'object') {
                let errors: any = [];
                for (let k in response.data) {
                    if (response.data.hasOwnProperty(k)) {
                        let error = response.data[k];
                        if (error) {
                            errors.push(error);
                        }
                    }
                }
                if (errors.length) {
                    exception.description = errors.join(', ');
                }
            } else {
                exception.description = response.data.toString();
            }
        }
        if (!exception.description) {
            if (typeof (response.data) === 'string') {
                exception.description = response.data;
            } else {
                exception.description = this.translate.instant('api.bad-response');

            }
        }
    } else {
        if (response.data) {
            try {
                exception.description = JSON.parse(response.data);
            } catch(error) {
                exception.description = response.data;
            }
        } else {
            exception.description = this.translate.instant('api.other-error') + ' ' + response.status;
        }
    }/* else if (response.status === 403) {
        exception.description = response.data || _literals['api.forbidden'];
    } else if (response.status === 404) {
        exception.description = response.data || _literals['api.not-found'];
    } else if (response.status === 500) {
        exception.description = response.data || _literals['api.server-error'];
    } else {
        exception.description = _literals['api.other-error'] + ' ' + response.status;
    }*/

    throw exception;
  }


  getExpandedMenu(){
    return axios.get('/assets/data/expanded-menu.json')
    .then(function(response){
        return response.data;
    });
  }

  getDocumentsFormats(){
    return axios.get('/assets/data/document-types.json')
    .then(function(response){
        return response.data;
    });
  }

  getPatterns(){
    return axios.get('/assets/data/pattern.json')
    .then(function(response){
        return response.data;
    });
  }
  getDates(){
    return axios.get('/assets/data/date-languajes.json')
    .then(function(response){
        return response.data;
    });
  }

  getMenuIcons(){
    return axios.get('/assets/data/menu-icons.json')
    .then(function(response){
        return response.data;
    });
  }
}
