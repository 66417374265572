import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'concept-window-tab',
  templateUrl: './concept-window-tab.component.html'
})
export class ConceptWindowTabComponent {

  @Input() activeConcept: any = null;
  @Output() launchAction = new EventEmitter();

  selectTab(selected:any) {
      if (selected !== this.activeConcept.subTab) {
          this.doSelectTab(selected);
      }
  }

  doSelectTab(selected:any) {
      this.launchAction.emit({
          action: {
              type: 'selectTab',
              item: selected
          }
      });
  }
}
