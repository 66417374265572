<div class="customize-field-text">
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.title'">Titulo</span>
    </div>
    <textarea class="common__textarea-simple"
        [ngClass]="{'common__input-no-editable': concept.disabled}"
        [(ngModel)]="customizeFieldBool.field.extendedLabel"
        [disabled]="concept.disabled"
        (blur)="modifyField()">
    </textarea>
    <div class="common__header-textarea"
        [ngClass]="{'common__input-no-editable': concept.disabled}">
        <span class="task-edit__text-description-title"
            [afTranslate]="'common.description'">Descripción</span>
    </div>
    <rich-editor [tinyMceOptions]="customizeFieldBool.tinyMceOptions"
      [modelText]="customizeFieldBool.field.description"
      (emitValue)="getEditorValue($event)">
    </rich-editor>
    <div class="customize-field-text__field-text">
        <field-type-list-simple [field]="customizeFieldBool.contentReportInfo"
            [preSelectItem]="customizeFieldBool.field.reportFieldId"
            [listValues]="reportList"
            (itemSelected)="itemSelected($event, 'reportFieldId')">
        </field-type-list-simple>
    </div>
    <div class="customize-field-text__field-text">
        <visibility class="field-item__input"
            *ngIf="concept.poll"
            [items]="visibilityItems"
            [type]="'field'"
            [concept]="concept"
            [selectedProperties]="customizeFieldBool.field.propertyVisibleValues"
            [selectedListValues]="customizeFieldBool.field.listVisibleValues"
            [selectedFields]="customizeFieldBool.field.fieldVisibleValues"
            [orCondition]="customizeFieldBool.field.visibilityOr"
            [readonly]="customizeFieldBool.field.noEditable || concept.disabled"
            [itemId]="customizeFieldBool.field.fieldId"
            (visibilityOpen)="openVisibility($event)">
        </visibility>
    </div>
    <div class="customize-field-text__field-text"
        *ngIf="customizeFieldBool.field.fieldTypeId === 'boolp'">
        <field-type-number class="customize-field-bool__field-text-weight"
            [field]="customizeFieldBool.weightYesInfo"
            [preSelectItem]="customizeFieldBool.field.listValues[0]?.weight"
            (itemSelected)="itemSelected($event, 'weightYes')">
        </field-type-number>
        <field-type-number class="customize-field-bool__field-text-weight customize-field-bool__field-text-weight--right"
            [field]="customizeFieldBool.weightNoInfo"
            [preSelectItem]="customizeFieldBool.field.listValues[1]?.weight"
            (itemSelected)="itemSelected($event, 'weightNo')">
        </field-type-number>
    </div>
    <switch-option [ngShow]="concept.allowPublicShare"
        [tooltipText]="'customize.private-help'"
        [model]="isPrivate?isPrivate:field.private"
        [disabled]="concept.disabled || isPrivate"
        [fieldText]="'customize.private'"
        (changeOptionEmit)="changeOptionOn($event, 'private')">
    </switch-option>
    <switch-option [ngHide]="concept.allowPublicShare"
        [tooltipText]="'customize.hidden-landing-help'"
        [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
        [disabled]="hiddenInLandings"
        [fieldText]="'customize.hidden-landing'"
        (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
    </switch-option>
    <switch-option [model]="customizeFieldBool.field.isHighlight"
        [disabled]="concept.disabled"
        [fieldText]="'customize.highlight'"
        (changeOptionEmit)="changeOptionOn($event, 'isHighlight')">
    </switch-option>
    <switch-option
        [model]="customizeFieldBool.field.required"
        [disabled]="customizeFieldBool.field.noEditable || concept.disabled"
        [fieldText]="'customize.required'"
        (changeOptionEmit)="changeOptionOn($event, 'required')">
    </switch-option>
    <switch-option [ngShow]="concept.poll"
        [model]="customizeFieldBool.field.isDisaggregated"
        [disabled]="customizeFieldBool.field.noEditable || concept.disabled || !customizeFieldBool.field.required"
        [fieldText]="'customize-field-list.dissgregated-data'"
        (changeOptionEmit)="changeOptionOn($event, 'isDisaggregated')">
    </switch-option>
    <div class="customize-field-text__field-bool">
        <switch-option class="customize-field-bool__block-button-inline"
            [model]="customizeFieldBool.field.color"
            [disabled]="concept.disabled"
            [fieldText]="'customize.associate-color'"
            (changeOptionEmit)="changeOptionOn($event, 'color')">
        </switch-option>
        <div class="customize-field-bool__block-associate-color"
            [ngClass]="{'customize-field-bool__block-associate-color--disabled': concept.disabled}"
            [ngShow]="customizeFieldBool.field.color">
            <span class="common__pill-button"
                [ngClass]="customizeFieldBool.field.withColor === 1? 'common__pill-button--green common__pill-button--selected':'common__pill-button--red common__pill-button--selected'"
                [afTranslate]="'common.yes'">
                Si
            </span>
            <button class="lf-icon-arrow-exchange common__button-generic customize-field-bool__switch"
                (click)="changeAssociatedColor()"
                [disabled]="concept.disabled"></button>
            <span class="common__pill-button"
                [ngClass]="customizeFieldBool.field.withColor === 2? 'common__pill-button--green common__pill-button--selected':'common__pill-button--red common__pill-button--selected'"
                [afTranslate]="'common.no'">
                No
            </span>
        </div>
    </div>
    <div class="customize-field-list__error-block"
        [ngShow]="customizeFieldBool.showError">
        <i class="lf-icon-warning establish-pattern__icon-error"></i>
        <p class="establish-pattern__text-error"
            [afTranslate]="'customize-field-bool.info-error-weight'"></p>
        <i class="lf-icon-close customize-field-list__icon-close-header"
            (click)="customizeFieldBool.showError = !customizeFieldBool.showError"></i>
    </div>
    <div class="common__buttons-block common__buttons-block--customize">
        <button type="reset"
            class="common__button common__button--clean"
            (click)="cancelFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.cancel'">Cancelar </button>
        <button type="submit"
            class="common__button common__button--active"
            (click)="saveFieldText()"
            [disabled]="concept.disabled"
            [afTranslate]="'common.save'">Guardar </button>
    </div>
</div>
