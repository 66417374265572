import moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { cloneDeep } from 'lodash';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'edition-comparator',
  templateUrl: './edition-comparator.component.html'
})
export class EditionComparatorComponent implements OnInit {
  comparatorViewerData:any = sessionStorage.getItem('comparatorViewerData');
  skin: any = this.globalCfg.skin;

  editionComparator:any = {
    historyDataViewer: {},
    selectedEdition: {
      id: 'selected-edition-comparator',
      noEditable: true,
      label: this.translate.instant('edition-comparator.selected-edition')
    },
    otherEdition: {
      id: 'other-edition-comparator',
      required: true,
      label: this.translate.instant('edition-comparator.other-edition')
    },
    warningText: this.translate.instant('edition-comparator.error-comparation')
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private router: Router,
    private context: ContextFactory,
    private translate:AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService) { }

  ngOnInit(): void {
    this.editionComparator.administrationPermits = !!this.comparatorViewerData;
    if(this.editionComparator.administrationPermits){
        this.loader.openLoader('edition-comparator-charge');
        let comparatorViewerDataArray:any = this.comparatorViewerData.split('/');
        this.editionComparator.conceptObjectId = comparatorViewerDataArray[0];
        this.editionComparator.logOperationID = parseInt(comparatorViewerDataArray[1]);
        this.getHistoricViewer(this.editionComparator.logOperationID, true);
        this.getHistoricList();
    }else{
        this.router.navigate([this.context.view.dashboard]);
    }
  }

  configureLogoHeader(){
    let that: any = this.editionComparator;
      if(that.historyDataViewer.custom){
          this.customLanding.setCustom(that.historyDataViewer.custom);
          let classBody:string = 'color-' + this.skin;
          $('body').removeClass(classBody);
          that.logoHeader = that.historyDataViewer.custom.logoFooter;
          this.skin = 'landing-custom';
          $('body').addClass(classBody);
      }else{
          that.logoHeader =  this.commonUtilsService.configureLogoHeader();
      }
  }

  getHistoricViewer(logOperationID:any, selectedEdition:any){
      this.apiService.get('conceptobjects/historic', this.editionComparator.conceptObjectId + '/' + logOperationID + '/json-snapshot')
          .then((data:any)=> {
              if(data.jsonSnapshot){
                this.editionComparator.hasErrorComparator = false;
                this.configureJSON(data.jsonSnapshot, selectedEdition);
              }else{
                this.resetComparator(selectedEdition);
              }
          }, (errordata:any)=>{
              this.loader.closeError();
          });
  }

  resetComparator(selectedEdition:any){
      if(!selectedEdition){
          this.editionComparator.historyDataViewer = JSON.parse(this.editionComparator.originalHistoryDataViewer);
          delete this.editionComparator.otherHistoryDataViewer;
          delete this.editionComparator.originalOtherHistoryDataViewer;
          this.editionComparator.hasErrorComparator = true;
      }
      this.loader.closeLoader(['edition-comparator-charge']);
  }

  configureJSON(dataJSON:any, selectedEdition:any){
      let that: any = this.editionComparator;
      if(selectedEdition){
          that.historyDataViewer = JSON.parse(dataJSON);
          that.historyDataViewer.comparatorPosition = 1;
          that.originalHistoryDataViewer = dataJSON;
          this.configureLogoHeader();
      }else{
          that.otherHistoryDataViewer = JSON.parse(dataJSON);
          that.originalOtherHistoryDataViewer = dataJSON;
          that.historyDataViewer = JSON.parse(that.originalHistoryDataViewer);
          that.historyDataViewer.comparatorActivated = true;
          that.otherHistoryDataViewer.comparatorActivated = true;
          that.historyDataViewer.comparatorPosition = 1;
          that.otherHistoryDataViewer.comparatorPosition = 2;
          this.compareJSONView(that.historyDataViewer.fieldContainers, that.otherHistoryDataViewer.fieldContainers);
          if(that.otherHistoryDataViewer.modifiedBy.modificationDate > that.historyDataViewer.modifiedBy.modificationDate){
              this.configureConcept(cloneDeep(that.otherHistoryDataViewer.fieldContainers), that.historyDataViewer.fieldContainers);
              that.historyDataViewer.fieldContainers = cloneDeep(that.otherHistoryDataViewer.fieldContainers);
              this.configureConcept(cloneDeep(that.historyDataViewer.fieldContainers), that.otherHistoryDataViewer.fieldContainers);
              that.otherHistoryDataViewer.fieldContainers = cloneDeep(that.historyDataViewer.fieldContainers);
          }else if(that.historyDataViewer.modifiedBy.modificationDate > that.otherHistoryDataViewer.modifiedBy.modificationDate){
              let newList = cloneDeep(that.historyDataViewer.fieldContainers);
              this.configureConcept(newList, that.otherHistoryDataViewer.fieldContainers);
              that.otherHistoryDataViewer.fieldContainers = newList;
              let otherNewList = cloneDeep(that.otherHistoryDataViewer.fieldContainers);
              this.configureConcept(otherNewList, that.historyDataViewer.fieldContainers);
              that.historyDataViewer.fieldContainers = otherNewList;
          }
      }

      this.loader.closeLoader(['edition-comparator-charge']);
  }

  configureConcept(baseList:any, compareList:any, parentIdBase:any ='', parentIdCompare:any=''){
      for(let i = 0; i < baseList.length; i++){
          let container:any = baseList[i];
          container.parentId = container.parentId? container.parentId : parentIdBase;
          container.iD_Version0 = container.iD_Version0? container.iD_Version0 : container.fieldContainerId;
          if(!compareList || (compareList && !compareList.length)){
              container.visibilityHidden = true;
          }else{
              for(let j = 0; j < compareList.length; j++){
                  let compareContainer : any = compareList[j];
                  compareContainer.parentId = compareContainer.parentId? compareContainer.parentId : parentIdCompare;
                  compareContainer.iD_Version0 = compareContainer.iD_Version0? compareContainer.iD_Version0 : compareContainer.fieldContainerId;
                  if(container.iD_Version0 === compareContainer.iD_Version0 && (!container.numSubContainer || container.numSubContainer === compareContainer.numSubContainer)){
                      container.name = compareContainer.name;
                      container.equalsText = compareContainer.equalsText;
                      container.visibilityHidden = compareContainer.hiddenContainer;
                      if(container.hiddenContainer && !compareContainer.hiddenContainer){
                          container.hiddenContainer = compareContainer.hiddenContainer;
                      }
                      //Llamar a función para que busque los subcontenedores y fields
                      if(container.subContainers && container.subContainers.length){
                          if(compareContainer.subContainers && compareContainer.subContainers.length){
                              let oldContainers = this.configureSubcontainer(container.subContainers, compareContainer.subContainers);
                              let containers = this.configureSubcontainer(oldContainers, container.subContainers);
                              container.subContainers = containers;
                              this.configureConcept(container.subContainers, compareContainer.subContainers, container.fieldContainerId, compareContainer.fieldContainerId);
                          }else{
                              //añadimos todos los contenedores pero con el campo visibilityHidden
                              this.configureHidden(container.subContainers);
                          }
                      }
                      if(container.fields && container.fields.length){
                          compareContainer.fields && compareContainer.fields.length? this.configureFields(container.fields, compareContainer.fields) : this.configureHidden(container.fields);
                      }
                      break;
                  }else if(j === compareList.length - 1){
                      //Dejamos el container pero con el campo visibilityHidden
                      container.visibilityHidden = true;
                      container.newBlock = true;
                  }
              }
          }
      }
  }

  configureSubcontainer(baseList:any, oldList:any){
      let newList : any = [];
      for(let i = 0; i < oldList.length; i++){
          let container : any = oldList[i];
          for(let j = 0; j < baseList.length; j++){
              let newContainer : any = baseList[j];
              if(container.iD_Version0 === newContainer.iD_Version0 && (!container.numSubContainer || container.numSubContainer === newContainer.numSubContainer)){
                  newList.push(newContainer);
                  break;
              }else if(j === baseList.length - 1){
                  newContainer = cloneDeep(container);
                  newContainer.newBlock = true;
                  newList.push(newContainer);
              }
          }
      }
      return newList;
  }

  configureHidden(list:any){
    list.forEach((item:any) => {
      item.visibilityHidden = true;
    });
  }

  configureFields(baseList:any, compareList:any){
      for(let i = 0; i < baseList.length; i++){
          let field : any = baseList[i];
          for(let j = 0; j < compareList.length; j++){
              let compareField : any = compareList[j];
              field.iD_Version0 = field.iD_Version0?field.iD_Version0:field.fieldId;
              compareField.iD_Version0 = compareField.iD_Version0?compareField.iD_Version0:compareField.fieldId;
              if(field.iD_Version0 === compareField.iD_Version0){
                  //Aqui habría que comprobar si acaso el tamaño de los campos que son multiples
                  field.label = compareField.label;
                  field.value = compareField.value;
                  field.noEditable = compareField.noEditable;
                  field.visibilityHidden = compareField.hiddenContainer;
                  if(field.hiddenContainer && !compareField.hiddenContainer){
                      field.hiddenContainer = compareField.hiddenContainer;
                  }
                  baseList.splice(i, 1, field);
                  break;
              }else if(j === compareList.length - 1){
                  //Dejamos el field pero con el campo visibilityHidden
                  field.visibilityHidden = true;
              }
          }
      }
  }

  getHistoricList(){
      this.apiService.get('conceptobjects/historic', this.editionComparator.conceptObjectId)
          .then((data:any)=>{
              this.editionComparator.historicSelectList = [];
              data.forEach((historicElement:any) => {
                if(historicElement.operationTypeId === 2 && historicElement.sectionId !== 3){
                    historicElement.textList = moment.utc(historicElement.date).format('DD/MM/YYYY HH:mm:ss') + ' ' + this.translate.instant('history-viewer.by') + ' ' + historicElement.user;
                    if(historicElement.logOperationID === this.editionComparator.logOperationID){
                        historicElement.disabled = true;
                    }
                    this.editionComparator.historicSelectList.push(historicElement);
                }
              });
              this.editionComparator.historicSelectList = this.commonUtilsService.editList(this.editionComparator.historicSelectList, 'logOperationID', 'textList');
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  printDocument(){
    window.print();
  }

  otherEditionSelected(e:any){
    this.editionComparator.selectedOtherItem = e.newValue;
  }

  closeAdvice(type:any){
    this.editionComparator[type] = false;
  }

  compareEdition(){
      this.loader.openLoader('edition-comparator-charge');
      this.editionComparator.otherLogOperationID = this.editionComparator.selectedOtherItem;
      this.getHistoricViewer(this.editionComparator.otherLogOperationID, false);
  }

  compareJSONView(listHistory1:any, listHistory2:any){
    listHistory1.forEach((containerSelected:any) => {
      listHistory2.forEach((otherSelected:any) => {
        containerSelected.iD_Version0 = containerSelected.iD_Version0? containerSelected.iD_Version0 : containerSelected.fieldContainerId;
        otherSelected.iD_Version0 = otherSelected.iD_Version0? otherSelected.iD_Version0 : otherSelected.fieldContainerId;
        otherSelected.hiddenContainer = otherSelected.hiddenContainer? otherSelected.hiddenContainer : false;
        containerSelected.hiddenContainer = containerSelected.hiddenContainer? containerSelected.hiddenContainer : false;
        if(containerSelected.iD_Version0 === otherSelected.iD_Version0
            && containerSelected.hiddenContainer === otherSelected.hiddenContainer
            && (!containerSelected.numSubContainer || containerSelected.numSubContainer === otherSelected.numSubContainer)){

            containerSelected.equalsText = containerSelected.name === otherSelected.name;
            otherSelected.equalsText = containerSelected.name === otherSelected.name;

            if(containerSelected.fields && containerSelected.fields.length && otherSelected.fields && otherSelected.fields.length){
                this.compareJSONViewFields(containerSelected, otherSelected);
            }
        }
        if(containerSelected.subContainers && containerSelected.subContainers.length && otherSelected.subContainers && otherSelected.subContainers.length){
            this.compareJSONView(containerSelected.subContainers, otherSelected.subContainers)
        }
      });
    });
  }

  compareJSONViewFields(container1:any, container2:any){
    container1.fields.forEach((fieldSelected:any) => {
      container2.fields.forEach((otherFieldSelected:any) => {
        fieldSelected.iD_Version0 = fieldSelected.iD_Version0? fieldSelected.iD_Version0 : fieldSelected.fieldId;
        otherFieldSelected.iD_Version0 = otherFieldSelected.iD_Version0? otherFieldSelected.iD_Version0 : otherFieldSelected.fieldId;
        fieldSelected.hiddenContainer = fieldSelected.hiddenContainer? fieldSelected.hiddenContainer : false;
        otherFieldSelected.hiddenContainer = otherFieldSelected.hiddenContainer? otherFieldSelected.hiddenContainer : false;
        if(fieldSelected.iD_Version0 === otherFieldSelected.iD_Version0 && (!container1.numSubContainer || container1.numSubContainer === container2.numSubContainer)){
            fieldSelected.equalsText = fieldSelected.label === otherFieldSelected.label && fieldSelected.hiddenContainer === otherFieldSelected.hiddenContainer;
            otherFieldSelected.equalsText = fieldSelected.label === otherFieldSelected.label && fieldSelected.hiddenContainer === otherFieldSelected.hiddenContainer;
            if(fieldSelected.fieldTypeFrontId === 'l-co'){
                fieldSelected.value = fieldSelected.value? fieldSelected.value : [];
                otherFieldSelected.value = otherFieldSelected.value? otherFieldSelected.value : [];
                this.compareJSONViewLco(fieldSelected.value, otherFieldSelected.value);
            }else if(fieldSelected.fieldTypeFrontId === 'list' || fieldSelected.fieldTypeFrontId === 'list-extended' || fieldSelected.fieldTypeFrontId === 'file' || fieldSelected.fieldTypeFrontId === 'templ'){
                fieldSelected.value = fieldSelected.value? fieldSelected.value : [];
                otherFieldSelected.value = otherFieldSelected.value? otherFieldSelected.value : [];
                this.compareJSONViewList(fieldSelected.value, otherFieldSelected.value);
            }else if(fieldSelected.fieldTypeFrontId === 'rich' || fieldSelected.fieldTypeFrontId === 'rich-editable'){
                fieldSelected.equalsTextValue = fieldSelected.value === otherFieldSelected.value;
                otherFieldSelected.equalsTextValue = fieldSelected.value === otherFieldSelected.value;
            }else if(fieldSelected.fieldTypeFrontId === 'user'){
                this.compareJSONViewUser(fieldSelected.value, otherFieldSelected.value);
            }else if(fieldSelected.fieldTypeFrontId === 'alert'){
                this.compareJSONViewAlert(fieldSelected.value, otherFieldSelected.value);
            }else if(fieldSelected.fieldTypeFrontId === 'task'){
                this.compareJSONViewTask(fieldSelected.value, otherFieldSelected.value);
            }else if(fieldSelected.value && otherFieldSelected.value && fieldSelected.fieldTypeFrontId === 'date'){
                this.compareJSONViewDate(fieldSelected, otherFieldSelected);
            }else if(fieldSelected.fieldTypeFrontId === 'pepl'){
                let fieldSelectedValue = fieldSelected.value?.length?fieldSelected.value[fieldSelected.value.length-1]:undefined;
                let otherFieldSelectedValue = otherFieldSelected.value?.length?otherFieldSelected.value[fieldSelected.value.length-1]:undefined;
                if(fieldSelectedValue && otherFieldSelectedValue && fieldSelectedValue.id === otherFieldSelectedValue.id && fieldSelectedValue.value === otherFieldSelectedValue.value){
                    fieldSelectedValue.equalsText = true;
                    otherFieldSelectedValue.equalsText = true;
                }
            }else if(fieldSelected.value && otherFieldSelected.value && fieldSelected.value.value === otherFieldSelected.value.value){
                fieldSelected.value.equalsText = true;
                otherFieldSelected.value.equalsText = true;
            }else if(!fieldSelected.value && !otherFieldSelected.value) {
                fieldSelected.value = {};
                otherFieldSelected.value = {};
                fieldSelected.value.equalsText = true;
                otherFieldSelected.value.equalsText = true;
            }
        }
      });
    });
  }

  compareJSONViewLco(list1:any, list2:any){
    for(let i = 0; i < list1.length; i++){
        let listSelected : any = list1[i];
        for(let j = 0; j < list2.length; j++){
            let otherListSelected = list2[j];
            if(listSelected.id === otherListSelected.id){
                listSelected.keyValue.equalsText = listSelected.keyValue.value === otherListSelected.keyValue.value;
                otherListSelected.keyValue.equalsText = listSelected.keyValue.value === otherListSelected.keyValue.value;
                this.compareJSONViewLcoValue(listSelected.value, otherListSelected.value);
                break;
            }
        }
    }
  }

  compareJSONViewLcoValue(list1:any, list2:any){
    list1.forEach((listSelected:any) => {
      list2.forEach((otherListSelected:any) => {
        if(listSelected.value === otherListSelected.value){
            listSelected.equalsText = true;
            otherListSelected.equalsText = true;
        }
        if(listSelected.label === otherListSelected.label){
            listSelected.equalsLabel = true;
            otherListSelected.equalsLabel = true;
        }
      });
    });
  }

  compareJSONViewList(list1:any, list2:any){
    list1.forEach((listSelected:any) => {
      list2.forEach((otherListSelected:any) => {
          if(listSelected.value === otherListSelected.value){
              listSelected.equalsText = true;
              otherListSelected.equalsText = true;
          }
      })
    })
  }

  compareJSONViewUser(list1:any, list2:any){
    list1.forEach((listSelected:any) => {
        for(let i: number = 0; i < list2.length; i++){
            let otherListSelected:any = list2[i];
            if(listSelected.id === otherListSelected.id && listSelected.idType === otherListSelected.idType){
                listSelected.equalsMail = listSelected.mail === otherListSelected.mail;
                otherListSelected.equalsMail = listSelected.mail === otherListSelected.mail;
                listSelected.equalsName = listSelected.name === otherListSelected.name;
                otherListSelected.equalsName = listSelected.name === otherListSelected.name;
                listSelected.equalsPhone = listSelected.phone === otherListSelected.phone;
                otherListSelected.equalsPhone = listSelected.phone === otherListSelected.phone;
                break;
            }
        }
    })
  }

  compareJSONViewAlert(list1:any, list2:any){
    list1.forEach((listSelected:any) => {
        for(let i: number = 0; i < list2.length; i++){
            let otherListSelected:any = list2[i];
            if(listSelected.id === otherListSelected.id){
                if(listSelected.value && otherListSelected.value){
                    listSelected.value.equalsLabelDate = true;
                    otherListSelected.value.equalsLabelDate = true;
                    listSelected.value.equalsLabelMomentAlert = true;
                    otherListSelected.value.equalsLabelMomentAlert = true;
                    listSelected.value.equalsLabelRepeat = true;
                    otherListSelected.value.equalsLabelRepeat = true;
                    listSelected.value.equalsDate = listSelected.value.date === otherListSelected.value.date;
                    otherListSelected.value.equalsDate = listSelected.value.date === otherListSelected.value.date;
                    listSelected.value.equalsTitle = listSelected.value.title === otherListSelected.value.title;
                    otherListSelected.value.equalsTitle = listSelected.value.title === otherListSelected.value.title;
                    listSelected.value.equalsMomentAlert = listSelected.value.momentAlert === otherListSelected.value.momentAlert;
                    otherListSelected.value.equalsMomentAlert = listSelected.value.momentAlert === otherListSelected.value.momentAlert;
                    listSelected.value.equalsRepeat = listSelected.value.repeat === otherListSelected.value.repeat;
                    otherListSelected.value.equalsRepeat = listSelected.value.repeat === otherListSelected.value.repeat;
                }
                break;
            }
        }
      })
  }

  compareJSONViewTask(list1:any, list2:any){
    list1.forEach((listSelected:any) => {
        for(let i: number = 0; i < list2.length; i++){
            let otherListSelected:any = list2[i];
              if(listSelected.id === otherListSelected.id){
                  if(listSelected.value && otherListSelected.value){
                      listSelected.value.equalsLabelType = true;
                      otherListSelected.value.equalsLabelType = true;
                      listSelected.value.equalsLabelParticipants = true;
                      otherListSelected.value.equalsLabelParticipants = true;
                      listSelected.value.equalsLabelExpirationDate = true;
                      otherListSelected.value.equalsLabelExpirationDate = true;
                      listSelected.value.equalsLabelLocation = true;
                      otherListSelected.value.equalsLabelLocation = true;
                      listSelected.value.equalsLabelRepeat = true;
                      otherListSelected.value.equalsLabelRepeat = true;
                      listSelected.value.equalsTitle = listSelected.value.title === otherListSelected.value.title;
                      otherListSelected.value.equalsTitle = listSelected.value.title === otherListSelected.value.title;
                      listSelected.value.equalsType = listSelected.value.type === otherListSelected.value.type;
                      otherListSelected.value.equalsType = listSelected.value.type === otherListSelected.value.type;
                      listSelected.value.equalsParticipants = listSelected.value.participants === otherListSelected.value.participants;
                      otherListSelected.value.equalsParticipants = listSelected.value.participants === otherListSelected.value.participants;
                      listSelected.value.equalsExpirationDate = listSelected.value.expirationDate === otherListSelected.value.expirationDate;
                      otherListSelected.value.equalsExpirationDate = listSelected.value.expirationDate === otherListSelected.value.expirationDate;
                      listSelected.value.equalsRepeat = listSelected.value.repeat === otherListSelected.value.repeat;
                      otherListSelected.value.equalsRepeat = listSelected.value.repeat === otherListSelected.value.repeat;
                      if(listSelected.taskTypeId === 4){
                        listSelected.value.equalsHour = (listSelected.value.beginHour +' - ' + listSelected.value.endHour) === otherListSelected.value.beginHour +' - ' + otherListSelected.value.endHour;
                        otherListSelected.value.equalsHour = (listSelected.value.beginHour +' - ' + listSelected.value.endHour) === otherListSelected.value.beginHour +' - ' + otherListSelected.value.endHour;
                        listSelected.value.equalsLocation = listSelected.value.location === otherListSelected.value.location;
                        otherListSelected.value.equalsLocation = listSelected.value.location === otherListSelected.value.location;
                        listSelected.value.equalsVideoconference = listSelected.videconference === otherListSelected.videconference;
                        otherListSelected.value.equalsVideoconference = listSelected.videconference === otherListSelected.videconference;
                      }
                  }
                  break;
              }
          }
      })
  }

  compareJSONViewDate(fieldSelected:any, otherFieldSelected:any){
      if(fieldSelected.value && otherFieldSelected.value){

          let dateField : any  = moment.utc(fieldSelected.value.value).format('DD/MM/YYYY');
          let dateOtherField : any  = moment.utc(otherFieldSelected.value.value).format('DD/MM/YYYY');

          if(fieldSelected.showTime && otherFieldSelected.showTime){
             dateField = moment.utc(fieldSelected.value.value).format('DD/MM/YYYY HH:mm');
             dateOtherField = moment.utc(otherFieldSelected.value.value).format('DD/MM/YYYY HH:mm');
          }
          fieldSelected.value.equalsText = dateField === dateOtherField;
          otherFieldSelected.value.equalsText = dateField === dateOtherField;
      }
  }
}
