import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesProductResolver implements Resolve<boolean> {
  productId: any = sessionStorage.getItem('productId');
  productName: any = sessionStorage.getItem('productName');
  constructor(private apiService: ApiServiceService,
    private context: ContextFactory,
    private router: Router) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let productId: any = this.productId?this.productId:this.context.settings.defaultProduct;
    return this.apiService.get('productroles/product/'+productId+'/assignments')
      .then((data:any)=> {
        if(data?.length){
          return data;
        }else{
          this.router.navigate(['dashboard']);
        }
      })
  }
}
