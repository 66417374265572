<div class="reference-document-modal">
  <div class="common__modal-header-block">
      <i class="lf-icon-document common__icon-header"></i>
      <span class="common__text-header" [afTranslate]="referenceDocument.dataProtectionView ? 'public-link.data-protection-documentation-new' : 'public-link.reference-documents'"></span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="reference-document-modal__body">
      <div class="reference-document-modal__block-form"
          [ngClass]="{'reference-document-modal__block-form--data-protection-view': referenceDocument.dataProtectionView}">
          <div class="field-type-role__container">
              <div class="field-type-role__block-header">
                  <i class="lf-icon-documentation reference-document-modal__icon-header-table"></i>
                  <span class="field-type-role__title-rol"
                      [afTranslate]="referenceDocument.dataProtectionView ? 'public-link.reference-documents' : 'public-link.associated-documentation'"></span>
                  <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                      (click)="addDocument()"
                      matTooltip="{{'documentation-list.add-documents' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
              </div>
              <div class="common__table-new-version"
                  [ngShow]="configuration.documentationList.length || !referenceDocument.dataProtectionView">
                  <div class="reference-document-modal__table-header-box">
                      <div class="reference-document-modal__col-table reference-document-modal__col-table--title reference-document-modal__col-table--first">
                          <p class="common__table-header-text" [afTranslate]="'common.title'"></p>
                      </div>
                      <div class="reference-document-modal__col-table reference-document-modal__col-table--format">
                          <p class="common__table-header-text" [afTranslate]="'common.format'"></p>
                      </div>
                      <div class="reference-document-modal__col-table reference-document-modal__col-table--creation">
                          <p class="common__table-header-text" [afTranslate]="'common.creation'"></p>
                      </div>
                      <div class="reference-document-modal__col-table reference-document-modal__col-table--author">
                          <p class="common__table-header-text" [afTranslate]="'common.author'"></p>
                      </div>
                      <div class="reference-document-modal__col-table reference-document-modal__col-table--actions">
                          <p class="common__table-header-text common__table-header-text--right"
                              [ngShow]="referenceDocument.dataProtectionView"
                              [afTranslate]="'common.actions'"></p>
                      </div>
                  </div>
                  <div class="reference-document-modal__table-body-box"
                      [ngClass]="{
                        'reference-document-modal__table-body-box--data-protection-view': referenceDocument.dataProtectionView,
                        'reference-document-modal__table-body-box--more-elements': (referenceDocument.dataProtectionView && configuration.documentationList.length > 3) || (!referenceDocument.dataProtectionView && configuration.documentationList.length > 10)
                      }">
                      <div class="field-type-role__table-row common__table-row" *ngFor="let document of configuration.documentationList | afOrderBy:['name']:false; let $index = index">
                          <div class="reference-document-modal__col-table reference-document-modal__col-table--title reference-document-modal__col-table--first">
                              <parragraph-tooltip [text]="document.name"></parragraph-tooltip>
                          </div>
                          <div class="reference-document-modal__col-table reference-document-modal__col-table--format">
                              <i class="{{document.extension | extensionDocument}} reference-document-modal__table-icon"></i>
                          </div>
                          <div class="reference-document-modal__col-table reference-document-modal__col-table--creation">
                              <parragraph-tooltip [text]="document.creationDate | convertDate:'without-time'"></parragraph-tooltip>
                          </div>
                          <div class="reference-document-modal__col-table reference-document-modal__col-table--author">
                              <parragraph-tooltip [text]="document.creator"></parragraph-tooltip>
                          </div>
                          <div class="reference-document-modal__col-table reference-document-modal__col-table--actions">
                              <div class="common__options-row field-type-role__options-row">
                                  <i class="lf-icon-download common__menu-options-row"
                                      (click)="launchActionDocument(document, 'download')"
                                      matTooltip="{{'expanded-menu.download' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"></i>
                                  <i class="lf-icon-trash common__menu-options-row"
                                      (click)="launchActionDocument(document, 'delete')"
                                      matTooltip="{{'common.remove' | afTranslate}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"></i>
                              </div>
                              <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="field-type-role__container"
            *ngIf="referenceDocument.dataProtectionView">
            <div class="field-type-role__block-header">
                <i class="lf-icon-legal-reference reference-document-modal__icon-header-table"></i>
                <span class="field-type-role__title-rol"
                    [afTranslate]="'channel-configuration.legal-info'"></span>
                <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                    (click)="configureData('legal')"
                    [ngShow]="!configuration.legalData?.length && referenceDocument.chargedLiteralDefault"
                    matTooltip="{{'reference-document-modal.add-aditional-information' | afTranslate}}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </button>
                <div [ngShow]="configuration.legalData?.length" class="reference-document-modal__block-header-language">
                    <div ngbDropdown class="reference-document-modal__block-dropdown-language"
                        [ngShow]="configuration.legalData?.length > 1">
                        <button class="header-nav__select-language-button" ngbDropdownToggle>
                            <span>{{referenceDocument.additionalLanguageSelected?.text}}</span>
                            <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                        </button>
                        <div class="reference-document-modal__menu-block-language dropdown-menu" ngbDropdownMenu>
                            <div class="common__block-option-list">
                                <div class="common__language-item" *ngFor="let language of referenceDocument.legalSelectedLanguages"
                                    (click)="changeSelectedLanguage('legal', language)">
                                    <span>{{language.text}}</span>
                                    <span>({{language.specific}})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="lf-icon-editable-form common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                        (click)="editDataProtectionLanguages('legal')"
                        matTooltip="{{'common.edit' | afTranslate}}"
                        matTooltipPosition="left"
                        matTooltipClass="left">

                    </button>
                    <button class="lf-icon-trash common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                        (click)="deleteDataProtectionLanguages('legal')"
                        matTooltip="{{'common.remove' | afTranslate}}"
                        matTooltipPosition="left"
                        matTooltipClass="left"
                        >
                    </button>
                </div>
            </div>
            <div [ngShow]="configuration.legalData?.length" class="reference-document-modal__block-info-language">
                <div class="reference-document-modal__title-info-language">
                    <span [afTranslate]="'channel-configuration.legal-info-new'"></span>
                </div>
                <div class="reference-document-modal__text-info-language"
                    [innerHTML]="referenceDocument.legalLanguageSelected?.textDescription">
                </div>
            </div>
          </div>
          <div class="field-type-role__container"
              *ngIf="referenceDocument.dataProtectionView">
              <div class="field-type-role__block-header">
                  <i class="lf-icon-shield reference-document-modal__icon-header-table"></i>
                  <span class="field-type-role__title-rol"
                      [afTranslate]="'protection-data-modal.data-protection-information'"></span>
                  <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                      (click)="configureData('protection')"
                      [ngShow]="!configuration.protectionData.length && referenceDocument.chargedLiteralDefault"
                      matTooltip="{{'reference-document-modal.add-data-protection-information' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
                  <div [ngShow]="configuration.protectionData.length" class="reference-document-modal__block-header-language">
                      <div ngbDropdown class="reference-document-modal__block-dropdown-language"
                          [ngShow]="configuration.protectionData.length > 1">
                          <button class="header-nav__select-language-button" ngbDropdownToggle>
                              <span>{{referenceDocument.protectionLanguageSelected?.text}}</span>
                              <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                          </button>
                          <div class="reference-document-modal__menu-block-language dropdown-menu" ngbDropdownMenu>
                              <div class="common__block-option-list">
                                  <div class="common__language-item"
                                      *ngFor="let language of referenceDocument.protectionSelectedLanguages"
                                      (click)="changeSelectedLanguage('protection', language)">
                                      <span>{{language.text}}</span>
                                      <span>({{language.specific}})</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <button class="lf-icon-editable-form common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                          (click)="editDataProtectionLanguages('protection')"
                          matTooltip="{{'common.edit' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">
                      </button>
                      <button class="lf-icon-trash common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                          (click)="deleteDataProtectionLanguages('protection')"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">
                      </button>
                  </div>
              </div>
              <div [ngShow]="configuration.protectionData.length" class="reference-document-modal__block-info-language">
                  <div class="reference-document-modal__title-info-language">
                      <span [afTranslate]="'protection-data-modal.processing-personal-data'"></span>
                  </div>
                  <div [innerHTML]="referenceDocument.protectionLanguageSelected?.textDescription" class="reference-document-modal__text-info-language"></div>
              </div>
          </div>
          <div class="field-type-role__container"
            *ngIf="referenceDocument.dataProtectionView">
              <div class="field-type-role__block-header">
                  <i class="lf-icon-information reference-document-modal__icon-header-table"></i>
                  <span class="field-type-role__title-rol"
                      [afTranslate]="'channel-configuration.additional-info'"></span>
                  <button class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                      (click)="configureData('additional')"
                      [ngShow]="!configuration.additionalData?.length && referenceDocument.chargedLiteralDefault"
                      matTooltip="{{'reference-document-modal.add-aditional-information' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
                  <div [ngShow]="configuration.additionalData?.length" class="reference-document-modal__block-header-language">
                      <div ngbDropdown class="reference-document-modal__block-dropdown-language"
                          [ngShow]="configuration.additionalData?.length > 1">
                          <button class="header-nav__select-language-button" ngbDropdownToggle>
                              <span>{{referenceDocument.additionalLanguageSelected?.text}}</span>
                              <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                          </button>
                          <div class="reference-document-modal__menu-block-language dropdown-menu" ngbDropdownMenu>
                              <div class="common__block-option-list">
                                  <div class="common__language-item" *ngFor="let language of referenceDocument.additionalSelectedLanguages"
                                      (click)="changeSelectedLanguage('additional', language)">
                                      <span>{{language.text}}</span>
                                      <span>({{language.specific}})</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <button class="lf-icon-editable-form common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                          (click)="editDataProtectionLanguages('additional')"
                          matTooltip="{{'common.edit' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left">

                      </button>
                      <button class="lf-icon-trash common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                          (click)="deleteDataProtectionLanguages('additional')"
                          matTooltip="{{'common.remove' | afTranslate}}"
                          matTooltipPosition="left"
                          matTooltipClass="left"
                          >
                      </button>
                  </div>
              </div>
              <div [ngShow]="configuration.additionalData?.length" class="reference-document-modal__block-info-language">
                  <div class="reference-document-modal__title-info-language">
                      <span [afTranslate]="'protection-data-modal.want-know'"></span>
                  </div>
                  <div [innerHTML]="referenceDocument.additionalLanguageSelected?.textDescription" class="reference-document-modal__text-info-language"></div>
              </div>
          </div>
          <div class="field-type-role__container"
            *ngIf="skin !== 'steel-blue' && !configuration.concept.isFront && configuration.concept.communicationChannel">
            <div class="field-type-role__block-header">
                <i class="lf-icon-configuration reference-document-modal__icon-header-table"></i>
                <span class="field-type-role__title-rol"
                  [afTranslate]="'form-configuration-modal.form-configuration'">_Configuración del formulario</span>
                <button *ngIf="!referenceDocument.fieldContainerConfigurated"
                    class="lf-icon-add-round common__header-button-add-new-version common__header-button-add-new-version--process"
                    (click)="configureAdditionalGroup()"
                    matTooltip="{{'anti-money-laundering-onboarding.configure' | afTranslate}}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </button>
                <button *ngIf="referenceDocument.fieldContainerConfigurated"
                    class="lf-icon-editable-form common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                    (click)="configureAdditionalGroup()"
                    matTooltip="{{'common.edit' | afTranslate}}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </button>
                <button *ngIf="referenceDocument.fieldContainerConfigurated && !referenceDocument.conceptInfo?.disabled && !referenceDocument.conceptInfo?.hasInstances"
                    class="lf-icon-trash common__header-button-add-new-version common__header-button-add-new-version--process reference-document-modal__header-button-add-new-version"
                    (click)="deleteAditionalGroupManually(referenceDocument.fieldContainerConfigurated)"
                    matTooltip="{{'common.remove' | afTranslate}}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </button>
            </div>
            <div class="reference-document-modal__body-container-form-configuration"
              *ngIf="referenceDocument.fieldContainerConfigurated?.fields?.length">
              <div class="common__table-header process-ops-modal__table-header">
                  <div class="process-ops-modal__table-col">
                      <p class="common__table-header-text"
                        [afTranslate]="'reference-document-modal.info-form-configuration'">_Se ha personalizado el formulario añadiendo los siguientes campos:</p>
                  </div>
              </div>
              <span class="reference-document-modal__tag-info">{{referenceDocument.fieldContainerConfigurated.name}}</span>
              <div class="reference-document-modal__field-form-container">
                <field-item *ngFor="let field of referenceDocument.fieldContainerConfigurated?.fields"
                  [fieldInfo]="field"
                  [concept]="configuration.concept"
                  [evaluationId]="referenceDocument.evaluationId"
                  [viewMode]="true"
                  [fieldContainer]="referenceDocument.fieldContainerConfigurated">
                </field-item>
              </div>
            </div>
          </div>
      </div>
  </div>
  <div class="common__buttons-block common__buttons-block--modal">
      <button type="submit"
          class="common__button common__button--active"
          (click)="handleAccept()"
          [afTranslate]="'common.finish'"></button>
  </div>
</div>
