import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { cloneDeep } from "lodash";

@Component({
  selector: 'duplicate-documentation',
  templateUrl: './duplicate-documentation.component.html'
})
export class DuplicateDocumentationComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  duplicateDocumentation:any = {
    stepShow: 0,
    configPagination: {
      pageSize: 10,
    },
    currentPage: 1,
    documentChecked: 0,
    searchText: '',
    copySearchText: '',
    search: '',
    titleValue: '',
    authorValue: '',
    searchProduct: '',
    dateOrder: 'CreationDate',
    orderOperationDates: true,
    searchingEvaluations: true,
    showProductList: false,
    noElements: false,
    menuIcons: {
        icons: []
    },
    breadcrumbList: [],
    documentsAdded: [],
    filterEvaluationTreeList: [],
    filterDocumentationList: [],
    conceptList: [],
    filters: ['Name', 'ClientName', 'name', 'clientName', 'EvaluationName', 'extension', 'title', 'author', 'createdBy'],
    extensionFilter: {
      name: this.translate.instant('common.format')
    },
    titleFilter: {
        name: this.translate.instant('common.title')
    },
    createdByFilter: {
        name: this.skin === 'icam-red'? this.translate.instant('common.creator') : this.translate.instant('common.author')
    },
    implantationFilter: {
        name: this.translate.instant('common.implantation')
    },
    organizationFilter: {
        name: this.translate.instant('common.organization')
    }
  }

  constructor(private commonUtilsService: CommonUtilsService,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal,
    private translate: AfTranslateFactory,
    private filterColumnService: FilterColumnService,
    private context: ContextFactory,
    private dateConverterService: DateConverterService,
    private loader: LoaderFactory) {}
  @Input() concept: any = null;
  @Input() listOriginDocuments: any = null;
  @Input() folderId: any = null;

  ngOnInit(): void {
    this.duplicateDocumentation.loaderImg = this.commonUtilsService.configureLoaderImg();
    this.skin === 'icam-red'? this.getEvaluations() : this.getProducts();
    this.duplicateDocumentation.nameProduct = this.translate.instant('dashboard.product-filter');
  }

  getEvaluations(){
    this.apiService.get('company', 'dashboard/evaluations')
        .then((data:any)=> {
            data.forEach((item:any) => {
                item.icon = 'lf-icon-label-validate'
            });
            this.duplicateDocumentation.evaluationList = data;
            this.duplicateDocumentation.filterEvaluationList = this.duplicateDocumentation.evaluationList;
            this.duplicateDocumentation.searchingEvaluations = false;
        }, (errordata:any)=>{
            this.duplicateDocumentation.searchingEvaluations = false;
        });
  }

  getProducts(){
    this.apiService.get('products')
        .then((data:any)=>{
            this.duplicateDocumentation.productList = data;
            this.duplicateDocumentation.currentPage = 1;
            this.getEvaluations();
        }, (errorData:any)=>{});
  }

  changeView(){
      if(this.skin === 'icam-red'){
        this.selectEvaluation(this.duplicateDocumentation.evaluationList[0])
      }else{
        this.duplicateDocumentation.stepShow = 2;
      }
  }

  selectFolder(folder:any, type:any=''){
      let that: any = this.duplicateDocumentation;
      that.currentPage = 1;
      that.noElements = false;
      if(type === 'menu'){
          if(that.breadcrumbList.length){
              that.breadcrumbList = [];
              that.stepShow = 2;
          }
      }else{
          if(type === 'breadcrumb'){
              let breadcrumbList:any = cloneDeep(that.breadcrumbList);
              that.breadcrumbList = [];
              for(let breadcrumbItem of breadcrumbList){
                  folder.nameBreadcrumb = breadcrumbItem.name;
                  that.breadcrumbList.push(breadcrumbItem);
                  if(breadcrumbItem.id === folder.id){
                      that.stepShow = folder.stepShow;
                      break;
                  }
              }
          }
          if((folder.folderId || folder.evaluationId) && !folder.conceptObjectId){
              if(folder.children.length || folder.concepts.length){
                  if(type !== 'breadcrumb'){
                      folder.children.forEach((child:any) => {
                          child.icon = child.isFront?'lf-icon-folder-user' : 'lf-icon-folder';
                      });
                      folder.stepShow = that.stepShow;
                      folder.nameBreadcrumb = folder.name;
                      folder.id = folder.folderId;
                      that.breadcrumbList.push(folder);
                  }
                  that.filterEvaluationTreeList = folder.concepts?folder.children.concat(folder.concepts): folder.children;
                }else{
                  that.filterEvaluationTreeList = [];
                  that.noElements = true;
                  folder.stepShow = that.stepShow;
                  folder.nameBreadcrumb = folder.name;
                  folder.id = folder.folderId;
                  that.breadcrumbList.push(folder);
              }
              that.stepShow = 3;
              that.totalItemsPagination = that.filterEvaluationTreeList.length;
          }else if(folder.folderId && folder.conceptObjectId && folder.conceptObjectId !== 0 && folder.concepts && folder.concepts.length && !folder.concepts[0].multipleInstancesInAuto){
              that.searchingDocuments = true;
              that.dateOrder = 'created';
              that.orderOperationDates = true;
              that.stepShow = 5;
              let conceptAutoinstanciable : any = folder.concepts[0];
              conceptAutoinstanciable.nameBreadcrumb = folder.name + ' (' +conceptAutoinstanciable.title+')';
              this.selectConcept(conceptAutoinstanciable, true);
          }else if(type !== 'breadcrumb'){
              folder.nameBreadcrumb = folder.title;
              that.stepShow = 4;
              this.configureViewTable(folder);
              folder.stepShow = that.stepShow;
              that.searchingInstances = true;
              this.selectConcept(folder);
          }else if(type === 'breadcrumb' && folder.conceptObjectId){
              that.orderOperationDates = true;
              that.documentChecked = 0;
              that.filterDocumentationList = [];
              that.currentPage = 1;
              that.documentList = folder.children;
              that.filterDocumentationList = that.documentList;
              that.extensionList = this.filterColumnService.extractList('extension',  that.filterDocumentationList);
              that.totalItemsPagination = that.filterDocumentationList.length;
              that.selectAllDocument = true;
              this.selectAllDocumentCheckDuplicate();
          }else if(folder.conceptId){
              that.totalItemsPagination = that.filterConceptList.length;
              that.dateOrder = 'creationConcept';
              that.orderOperationDates = true;
          }
          this.animateScroll();
      }
  }

  configureViewTable(concept:any){
      let that:any = this.duplicateDocumentation;
      if(this.skin === 'icam-red'){
          switch (concept.namedKey) {
              case 'Consulta':
                  that.referenciaFilter = {
                      name: this.translate.instant('common.reference'),
                      width: '200px'
                  };
                  that.tipoFilter = {
                      name: this.translate.instant('common.type')
                  };
                  that.nameQueryFilter = {
                      name: this.translate.instant('common.name-surname'),
                      width: '200px'
                  };
                  that.materiaFilter = {
                      name: this.translate.instant('concept-list.matter'),
                  };
                  that.dateOrder = 'creationDate';
                  that.filters = ['tipo', 'materia'];
                  that.viewTableDuplicated = 'query';
                  break;
              case 'Publicacion':
                  that.tituloFilter = {
                      name: this.translate.instant('common.title'),
                      top: 26,
                      orderCol: true,
                      filterOrder: 'titulo',
                      width: '200px'
                  };
                  that.isbnFilter = {
                      name: this.translate.instant('concept-list.isbn-signature'),
                      top: 26,
                      width: '200px'
                  };
                  that.tipoFilter = {
                      name: this.translate.instant('common.type'),
                  }
                  that.dateOrder = 'titulo';
                  that.orderOperationDates = false;
                  that.filters = ['tipo'];
                  that.viewTableDuplicated = 'publication';
                  break;
              case 'Digitalizacion':
                  that.tituloFilter = {
                      name: this.translate.instant('common.title'),
                      top: 26,
                      orderCol: true,
                      filterOrder: 'titulo',
                      width: '200px'
                  };
                  that.referenciaFilter = {
                      name: this.translate.instant('concept-list.fragment-reference'),
                      width: '220px'
                  };
                  that.dateOrder = 'titulo';
                  that.orderOperationDates = false;
                  that.filters = [];
                  that.viewTableDuplicated = 'digitization';
                  break;
              case 'Usuario':
                  that.surnameFilter = {
                      name: this.translate.instant('common.surname'),
                      orderCol: true,
                      filterOrder: 'apellidos',
                      width: '200px'
                  };
                  that.nameFilter = {
                      name: this.translate.instant('common.name'),
                      width: '200px'
                  };
                  that.userNumberFilter = {
                      name: this.translate.instant('concept-list.user-number'),
                      width: '200px'
                  };
                  that.dateOrder = 'apellidos';
                  that.orderOperationDates = false;
                  that.filters = [];
                  that.viewTableDuplicated = 'collegiate';
                  break;
          }
      }else{
          that.viewTableDuplicated = 'steps';
      }
  }

  selectConcept(concept:any, autoinstanciable:boolean = false){
      let that: any = this.duplicateDocumentation;
      let path = this.skin === 'icam-red' ? 'icam/conceptobjects/byconceptid' : 'conceptobjects/byconceptid';
      this.apiService.get(path, concept.conceptId)
          .then((data:any)=>{
                that.conceptList = [];
                that.noElements = Boolean(!data.length);
                data.forEach((item:any) => {
                    if(this.skin === 'icam-red'){
                        item.namedKey = concept.namedKey;
                    }
                    if(item.conceptObjectId !== this.concept.conceptObjectId || autoinstanciable){
                        that.conceptList.push(item);
                    }
                });

                concept.children = that.conceptList;
                concept.id = concept.conceptId;
                if(autoinstanciable){
                    concept.children[0].title = concept.nameBreadcrumb;
                    this.selectInstance(concept.children[0]);
                }else{
                    that.filterConceptList = that.conceptList;
                    that.searchingInstances = false;
                    that.totalItemsPagination = that.filterConceptList.length;
                    that.breadcrumbList.push(concept);
                    if(this.skin !== 'icam-red'){
                        that.dateOrder = 'creationConcept';
                    }
                }
                if(this.skin === 'icam-red'){
                    this.getFilters(that.conceptList);
                }
          }, (errorData:any)=>{
              that.searchingInstances = false;
          });
  }

  getFilters(list:any) {
      this.duplicateDocumentation.filters.forEach((filter:any) => {
          this.duplicateDocumentation[filter+'List'] = this.filterColumnService.extractList(filter, list);
          this.duplicateDocumentation[filter+'ListFilter'] = [];
      });
  }

  animateScroll(){
      setTimeout(function(){
          let height : any = $('.duplicate-documentation__block-breadcrumbs-text').height();
          $('.duplicate-documentation__block-breadcrumbs').animate({ scrollTop: height }, 'slow');
      })
  }

  goToPressEnter(event:any){
    if(event.which === 13 && this.duplicateDocumentation.searchText !== ''){
        this.goToSearchFunction();
    }
  }

  goToSearch(){
    if(this.duplicateDocumentation.searchText !== '' && this.duplicateDocumentation.searchText !== this.duplicateDocumentation.copySearchText){
      this.goToSearchFunction();
    }else{
      this.duplicateDocumentation.searchText = '';
    }
  }

  goToSearchFunction(){
      let that: any = this.duplicateDocumentation
      that.searchingDocuments = true;
      that.stepShow = 1;
      that.copySearchText = that.searchText;
      that.dateOrder = 'CreationDate';
      this.apiService.get('search/type/Documents?text=' + that.searchText + '&onlyResults=true', undefined)
          .then((data:any)=>{
              that.documentList = [];
              data.Documents.Results.forEach((document:any) => {
                  if(document.ConceptObjectId !== this.concept.conceptObjectId){
                      document.extension = document.Name.slice(document.Name.lastIndexOf(".")+1, document.Name.length);
                      that.documentList.push(document);
                  }
              });

              that.currentPage = 1;
              that.filterDocumentationList = that.documentList;
              that.searchingDocuments = false;
              that.extensionList = this.filterColumnService.extractList('extension',  that.filterDocumentationList);
              that.extensionListFilter = [];
          }, (errorData:any)=>{
              that.searchingDocuments = false;
          })
  }

  orderBySearch(e:any, type:any){
      switch(e.action){
          case 'filter':
              this.duplicateDocumentation[type+'Value'] = e.newValue;
              this.filterDocumentation(type);
              break;
          case 'order':
              this.orderDate(type);
              break;
      }
  }

  selectTypeOperation(e:any, type:any){
      let item: any = e.source;
      let listFilter : any = this.duplicateDocumentation[type+'ListFilter'];
      this.duplicateDocumentation[type+'ListFilter'] = this.filterColumnService.configureFilterList(listFilter, item);
      item.selected = !item.selected;
      this.duplicateDocumentation[type+'Filter'].hasFilter = this.duplicateDocumentation[type+'ListFilter'].length;
      this.filterDocumentation('');
  }

  filterDocumentation(type:any){
      let filterList:any = [];
      let list = this.duplicateDocumentation.documentList;
      let searchFilter:any = this.duplicateDocumentation.titleValue;
      let searchWord:string = 'title';
      if(this.duplicateDocumentation.stepShow === 2){
          list = this.duplicateDocumentation.evaluationList;
      }else if(this.duplicateDocumentation.stepShow === 4){
          list = this.duplicateDocumentation.conceptList;
      }
      if (type!=='') {
          searchFilter = this.duplicateDocumentation[type + 'Value'];
          searchWord = type;
      }
      filterList = this.filterColumnService.filterByWord(searchFilter,  list, searchWord);
      this.duplicateDocumentation.filters.forEach((filter:any) => {
          let filteredList:any = this.duplicateDocumentation[filter+'ListFilter'];
          filterList = this.filterColumnService.filterBycolumn(filterList, filteredList, filter);
      });

      if(this.duplicateDocumentation.stepShow === 2){
          this.duplicateDocumentation.filterEvaluationList = filterList;
      }else if(this.duplicateDocumentation.stepShow === 4){
          this.duplicateDocumentation.filterConceptList = filterList;
      }else{
          this.duplicateDocumentation.filterDocumentationList = filterList;
      }
      this.duplicateDocumentation.totalItemsPagination = filterList.length;
  }

  selectAllDocumentCheckDuplicate(){
      this.duplicateDocumentation.selectAllDocument = !this.duplicateDocumentation.selectAllDocument;
      this.duplicateDocumentation.filterDocumentationList.forEach((document:any) => {
          if(!document.folderId && document.selectedDocument !== this.duplicateDocumentation.selectAllDocument){
              document.selectedDocument = this.duplicateDocumentation.selectAllDocument;
              if(document.selectedDocument){
                  this.duplicateDocumentation.documentChecked++;
              }else{
                  if(this.duplicateDocumentation.documentChecked > 0){
                      this.duplicateDocumentation.documentChecked--;
                  }
              }
          }
      });
  }

  selectMultipleDocument(document:any){
      if(document.folderId){
          this.duplicateDocumentation.searchingDocuments = true;
          this.changeFolder(document);
      }else{
          document.selectedDocument = !document.selectedDocument;
          this.duplicateDocumentation.selectedFromOption = false;
          if(document.selectedDocument){
              this.duplicateDocumentation.documentChecked++;
          }else{
              this.duplicateDocumentation.selectAllDocument = false;
              this.duplicateDocumentation.documentChecked--;
          }
      }
  }

  changeFolder(folder:any){
      let that: any = this.duplicateDocumentation;
      that.documentList = [];
      that.documentChecked = 0;
      that.filterDocumentationList = [];
      that.currentPage = 1;
      that.documentList = folder.documents;
      that.documentList = [...that.documentList, ...folder.subFolders];
      that.filterDocumentationList = that.documentList;
      folder.children = that.filterDocumentationList;
      folder.id = folder.folderId;
      folder.nameBreadcrumb = folder.name;
      folder.conceptObjectId = that.conceptObjectIdFromInstance;
      folder.stepShow = 5;
      that.breadcrumbList.push(folder);
      that.totalItemsPagination = that.filterDocumentationList.length;
      if(!folder.children.length){
          that.noElements = true;
      }
      this.animateScroll();
      that.searchingDocuments = false;
      that.extensionList = this.filterColumnService.extractList('extension',  that.filterDocumentationList);
  }

  backInit(){
      let that: any = this.duplicateDocumentation
      that.searchText = '';
      that.copySearchText = '';
      that.stepShow = 0;
      that.filterEvaluationList = that.evaluationList;
      that.nameProduct = this.translate.instant('dashboard.product-filter');
      that.selectedProduct = false;
      that.breadcrumbList = [];
      that.selectAllDocument = true;
      this.selectAllDocumentCheckDuplicate();
  }

  orderDate(type:any){
      if(this.duplicateDocumentation.dateOrder === type){
          this.duplicateDocumentation.orderOperationDates = !this.duplicateDocumentation.orderOperationDates;
      }else{
          this.duplicateDocumentation.dateOrder = type;
          this.duplicateDocumentation.orderOperationDates = true;
      }
  }

  selectProduct(product:any){
      this.duplicateDocumentation.nameProduct = product.name.toUpperCase();
      this.duplicateDocumentation.filterEvaluationList = this.duplicateDocumentation.evaluationList;
      this.duplicateDocumentation.filterEvaluationList = this.commonUtilsService.filterEvaluationByProduct(this.duplicateDocumentation.filterEvaluationList, product);
      this.duplicateDocumentation.selectedProduct = this.duplicateDocumentation.nameProduct !== this.translate.instant('dashboard.product-filter');
  }

  selectInstance(instance:any){
      let that: any = this.duplicateDocumentation
      that.searchingDocuments = true;
      that.dateOrder = 'created';
      that.orderOperationDates = true;
      that.stepShow = 5;
      instance.stepShow = that.stepShow;
      that.filterDocumentationList = [];
      that.currentPage = 1;
      that.noElements = false;
      this.apiService.get('documentobjects/'+instance.conceptObjectId)
          .then((data:any)=>{
              this.addExtensionFolder(data.subFolders);
              that.documentList = data.documents;
              that.documentList = [...that.documentList, ...data.subFolders];

              that.filterDocumentationList = that.documentList;
              that.extensionList = this.filterColumnService.extractList('extension',  that.filterDocumentationList);
              that.extensionListFilter = [];
              that.totalItemsPagination = that.filterDocumentationList.length;
              instance.nameBreadcrumb = instance.title;
              instance.id = instance.conceptObjectId;
              instance.children = that.filterDocumentationList;
              that.conceptObjectIdFromInstance = instance.conceptObjectId;
              that.breadcrumbList.push(instance);
              this.animateScroll();
              that.searchingDocuments = false;
          }, (errorData:any)=>{
              that.searchingDocuments = false;
          });
  }

  addExtensionFolder(list:any){
      list.forEach((item:any) => {
          if(item.folderId){
              item.extension = this.translate.instant('duplicate-documentation.folder');
          }
          if(item.subFolders && item.subFolders.length){
              this.addExtensionFolder(item.subFolders);
          }
      });
  }

  resetFilterProduct(){
      this.duplicateDocumentation.filterEvaluationList = this.duplicateDocumentation.evaluationList;
      this.duplicateDocumentation.nameProduct = this.translate.instant('dashboard.product-filter');
      this.duplicateDocumentation.selectedProduct = false;
  }

  selectEvaluation(evaluation:any){
      let that: any = this.duplicateDocumentation;
      evaluation.stepShow = that.stepShow;
      that.stepShow = 3;
      that.searchingTreeEvaluation = true;
      that.treeList = [];
      this.apiService.getWithTranslate('tree/translated', evaluation.evaluationId)
          .then((data:any)=> {
              if(data.length){
                  this.getEvaluationMenuIcons(evaluation, data);
              }
              that.treeList = data;
              that.filterEvaluationTreeList = that.treeList;
              that.totalItemsPagination = that.filterEvaluationTreeList.length;
              evaluation.children = that.treeList;
              evaluation.nameBreadcrumb = that.skin === 'icam-red'? evaluation.productName : evaluation.name;
              evaluation.id = evaluation.evaluationId;
              that.breadcrumbList.push(evaluation);
          }, (errorData:any)=>{
              that.searchingTreeEvaluation = false;
          })
  }

  getEvaluationMenuIcons(evaluation:any, list:any){
      this.apiService.getMenuIcons()
          .then((data:any)=>{
              for(let item of data){
                  if(item.productId === evaluation.productId){
                      this.duplicateDocumentation.menuIcons = item;
                      this.duplicateDocumentation.product = item.productId;
                      break;
                  }
              }
              this.configureIcons(list);
          }, ()=>{})
  }

  configureIcons(list:any){
      list.forEach((children:any) => {
          children.icon = this.getIcon(children);
      });
  }

  getIcon(children:any){
      this.duplicateDocumentation.searchingTreeEvaluation = false;
      for(let icon of this.duplicateDocumentation.menuIcons.icons){
          if(icon['iD_Version0'+this.context.environment] === children['iD_Version0']){
              return  icon.icon;
          }
      }
      return children.isFront? "lf-icon-folder-user" : "lf-icon-folder";
  }

  // showTooltip(id:any){
  //   return this.commonUtilsService.showTooltip(id);
  // }

  importDocuments(){
      let that: any = this.duplicateDocumentation
      let count : any= 0;
      let countMilisecond : any = 100;
      that.errors = [];
      that.callsFinish = 0;
      that.filterDocumentationList.sort(this.orderListDate);
      for(let document of that.filterDocumentationList){
          if(document.selectedDocument){
              count++;
              if(document.DocumentObjectId){
                  document.name = document.Name;
                  document.documentObjectId = document.DocumentObjectId;
              }
              if(this.checkedName(this.listOriginDocuments, document, 'documentObjectId')){
                  countMilisecond+=5;
                  this.copyDocument(document, countMilisecond);
              }else{
                  that.errors.push(document);
                  that.callsFinish++;
              }
              if(count === that.documentChecked){
                  if(count === that.errors.length){
                      let message: string = count === 1? 'error-in-document': 'error-all-documents';
                      this.handleAccept(message);
                  }
                  break;
              }
          }
      }
  }

  orderListDate(a: any, b: any) {
      if(a.CreationDate > b.CreationDate){
        return 1
      }
      return a.CreationDate < b.CreationDate ? -1 : 0;
  }

  checkedName(list:any, item:any, type:any){
      for(let listItem of list){
        if(listItem[type] && listItem.name === item.name){
            return false;
        }
      }
      return true;
  }

  copyDocument(document:any, countMilisecond:any){
      this.loader.openLoader('copy-documents');
      let creationDate: any;
      let url:string = 'documentobjects/copy/'+ document.documentObjectId + '/' + this.concept.conceptObjectId;
      if(this.folderId){
        url = url + '/' + this.folderId;
      }else{
        creationDate = this.dateConverterService.toIso(new Date(), countMilisecond);
        url = url + '?creationDate=' + creationDate;
      }
      this.apiService.add(url)
          .then((data:any)=>{
              this.duplicateDocumentation.callsFinish++;
              data.sourceName = this.translate.instant('common.documentation');
              data.creationDate = creationDate;
              this.duplicateDocumentation.documentsAdded.push(data);
              if(this.duplicateDocumentation.callsFinish === this.duplicateDocumentation.documentChecked){
                  let message : string = 'ok';
                  if(this.duplicateDocumentation.errors?.length){
                      message = 'ok-with-errors';
                  }
                  this.handleAccept(message);
              }
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  launchAction(e: any){
    switch(e.action){
      case 'selectInstance':
        this.selectInstance(e.instance);
        break;
      case 'orderDate':
        this.orderDate(e.type);
        break;
      case 'orderBySearch':
        this.orderBySearch(e.event, e.type);
        break;
      case 'selectTypeOperation':
        this.selectTypeOperation(e.event, e.type);
        break;
      default:
        break;
    }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

  handleAccept(message:any) {
    this.activeModal.close({
        result: message,
        documentErrors: this.duplicateDocumentation.errors,
        documentsAdded: this.duplicateDocumentation.documentsAdded.sort(this.orderListDateReverse)
    });
  }

  orderListDateReverse(a: any, b: any) {
    if(a.CreationDate < b.CreationDate){
      return 1;
    }
    return a.CreationDate > b.CreationDate ? -1 : 0;
  }

}
