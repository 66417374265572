import { Directive,  Output,  EventEmitter,  HostBinding,  HostListener } from '@angular/core';

@Directive({
  selector: '[dropZone]'
})
export class DropZoneDirective {
  @HostBinding('class.dz-drag-hover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() dragOverElement = new EventEmitter<any>();
  @Output() dragLeaveElement = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
    this.dragOverElement.emit({drag: this.fileOver})
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event:any) {
    this.fileOver = false;
    this.dragLeaveElement.emit({drag: this.fileOver})
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit({files: files, drag: this.fileOver});
    }
  }
}
