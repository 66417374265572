import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep, remove, sortBy } from 'lodash';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'customize-field-formula',
  templateUrl: './customize-field-formula.component.html'
})
export class CustomizeFieldFormulaComponent implements OnInit, OnChanges {
  skin: string = this.globalCfg.skin;
  colorsGm: any = [['#858E94','rgba(146, 155, 156, 0.15)'], ['#000000','rgba(0, 25, 120, 0.15)'], ['#56D5F1','rgba(118, 131, 181, 0.15)'], ['#8597DC','rgba(94, 198, 221, 0.15)'], ['#BB50C8','rgba(122, 88, 172, 0.15)'], ['#F18BA4','rgba(229, 134, 157, 0.15)']];
  colors: any = [['#929B9C','rgba(146, 155, 156, 0.15)'], ['#001978','rgba(0, 25, 120, 0.15)'], ['#7683B5','rgba(118, 131, 181, 0.15)'], ['#5EC6DD','rgba(94, 198, 221, 0.15)'], ['#7A58AC','rgba(122, 88, 172, 0.15)'], ['#E5869D','rgba(229, 134, 157, 0.15)']];
  customizeFieldFormula: any ={
    tinyMceOptions: {},
    marginOperator: 0,
    symbolOrigin: false,
    preDefined: null,
    fieldValue: null,
    editEncodingOkDisabled: false,
    editingEncodingItem: {},
    operator1Field: {
        label: this.translate.instant('customize-field-formula.encoding-operator'),
        clean: true
    },

    operator2Field: {
        label: this.translate.instant('customize-field-formula.encoding-operator'),
        clean: true,
        noEditable: true
    },
    colors: this.skin === 'gm-law'?this.colorsGm : this.colors,
    value1Field: {
        label: this.translate.instant('customize-field-formula.encoding-value'),
        clean: true,
        noEditable: true,
        fieldTypeId: 'num',
        decimals: 2,
        disabled: true,
        positive: true
    },
    value2Field: {
        label: this.translate.instant('customize-field-formula.encoding-value'),
        clean: true,
        noEditable: true,
        fieldTypeId: 'num',
        decimals: 2,
        disabled: true,
        positive: true
    },
    textField: {
        label: this.translate.instant('customize-field-formula.encoding-text'),
        clean: true,
    },
    originForm: {
        colores: this.colors,
        formulaItems: [],
        symbol: false,
        marginOperator: 0,
        selectValuesFormula: []
    },
    listValueColors: [],
    formulaItems: [],
    formulaFieldsConcept: [],
    selectValuesFormula: [],
    operators1: [
        { listValueId: '', text: '' },
        { listValueId: '=', text: '=' },
        { listValueId: '<', text: '<' },
        { listValueId: '>', text: '>' },
        { listValueId: '<=', text: '<=' },
        { listValueId: '>=', text: '>=' },
        { listValueId: '<>', text: '<>' }
    ],
    operators2: [
        { listValueId: '', text: '' },
        { listValueId: '<', text: '<' },
        { listValueId: '>', text: '>' },
        { listValueId: '<=', text: '<=' },
        { listValueId: '>=', text: '>=' }
    ],
    contentReportInfo:{
      label: this.translate.instant('common.report-field')
    },
    decimalsInfo: {
      label: this.translate.instant('customize-field-list.decimals'),
      positive: true
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private conceptState: ConceptStateFactory,
    private modalService: ModalServiceService,
    private commonUtilsService: CommonUtilsService,
    private dragulaService: DragulaService) {
      this.dragulaServiceFunction();
    }

  @Input() field: any = null;
  @Input() fieldContainers: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = {};
  @Output() changeInField = new EventEmitter();

  ngOnInit(){
    this.getListValueColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldFormula;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      that.fieldCopy = cloneDeep(that.field);
      that.contentReportInfo.noEditable = this.field.noEditable;
      that.decimalsInfo.noEditable = this.field.noEditable;

      if (that.field.fieldEncodings) {
        that.field.fieldEncodings.forEach((encoding:any) => {
          this.setEncodingDisplayText(encoding)
        });
        that.field.fieldEncodings = sortBy(that.field.fieldEncodings, 'numOrder');
      }
      if(that.field.fieldId){
        this.getFieldsRelated();
      }
    }
    this.customizeFieldFormula.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  dragulaServiceFunction(){
    if(!this.dragulaService['groups']?.customizeEncodingItem){
      this.dragulaService.createGroup('customizeEncodingItem', {
        revertOnSpill: false,
        copy: false,
        moves: (element, container, handle) => {
          return handle?.className.indexOf('handleEncodingItem') !== -1;
        }
      });
    }
  }

  getListValueColors() {
    let that: any = this.customizeFieldFormula;
    this.apiService.get('listValues', 'colors')
      .then((data:any)=> {
          that.listValueColors = data;
          if (that.field.fieldEncodings && that.field.fieldEncodings.length === 0 && !that.field.noEditable) {
              this.addEncoding();
              that.showNewEncoding = true;
          }
      }, ()=>{});
  }


  getFieldsRelated(){
      this.apiService.get('fields/byrelatedfield', this.customizeFieldFormula.field.fieldId)
      .then((data:any)=>{
          data.forEach((item:any) => {
            if(item.fieldTypeId === 'money' || item.fieldTypeId === 'num' || item.fieldTypeId === 'boolp' || item.fieldTypeId === 'listp'){
              this.customizeFieldFormula.formulaFieldsConcept.push(item);
            }
          });
          if(this.customizeFieldFormula.formulaFieldsConcept.length){
              this.extractFormula();
          }
      }, ()=>{});
  }

  extractFormula(){
      let formula:any = this.customizeFieldFormula.field.formula;
      this.customizeFieldFormula.formulaFieldsConcept.forEach((item:any) => {
          item.text = item.label;
          item.listValueId = item.fieldId;
          this.customizeFieldFormula.field.formula = formula === null? '' : formula.split('['+item.fieldId+']').join(item.label);
      });

      let formulaArray:any = this.customizeFieldFormula.field.formula.split('#');
      if(formulaArray.length !== this.customizeFieldFormula.formulaItems.length){
        formulaArray.forEach((formulatItem:any) => {
          if(formulatItem !== '' && formulatItem !== null){
            this.getIconFromSymbol(formulatItem)
          }
        });
      }

      this.customizeFieldFormula.originForm = {
          formulaItems: this.customizeFieldFormula.formulaItems,
          symbol: this.customizeFieldFormula.symbolOrigin,
          marginOperator: this.customizeFieldFormula.marginOperator,
          colores: this.customizeFieldFormula.colors,
          selectValuesFormula : this.customizeFieldFormula.selectValuesFormula
      }

      this.customizeFieldFormula.formulaItemsCopy = cloneDeep(this.customizeFieldFormula.formulaItems);
      this.customizeFieldFormula.field.formula = formula;
  }

  getIconFromSymbol(item:any){
      let const_dist_margin:number = 26;
      let levelOrigin:number = 0;
      let iconSymbol:any;
      let type: string = 'symbol';
      let level:any = levelOrigin;
      let auxOperator = this.customizeFieldFormula.marginOperator;
      if(item.indexOf('[')===-1){
        this.customizeFieldFormula.symbolOrigin = false;
          switch(item){
              case')':
                  iconSymbol = 'lf-icon-close-bracket';
                  levelOrigin--;
                  type = 'symbol-full';
                  this.customizeFieldFormula.marginOperator -= const_dist_margin;
                  this.customizeFieldFormula.symbolOrigin = true;
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              case'(':
                  levelOrigin++;
                  iconSymbol = 'lf-icon-open-bracket';
                  level= levelOrigin;
                  this.customizeFieldFormula.marginOperator += const_dist_margin;
                  auxOperator = this.customizeFieldFormula.marginOperator;
                  type= 'symbol-full';
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              case '+':
                  iconSymbol = 'lf-icon-sum';
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              case '-':
                  iconSymbol = 'lf-icon-less';
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              case '/':
                  iconSymbol = 'lf-icon-division';
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              case '*':
                  iconSymbol = 'lf-icon-close';
                  this.customizeFieldFormula.formulaItems.push({text:item,icon:iconSymbol, type:type,level:level, marginOperator: auxOperator ,color:this.colors[level][0]});
                  break;
              default:
                  this.customizeFieldFormula.symbolOrigin =true;
                  if(!isNaN(parseFloat(item))){
                    let textLabel =  this.commonUtilsService.setLocaleNumberFormat(item);
                    this.customizeFieldFormula.formulaItems.push({text:textLabel, value:item, type:'fields', icon:"lf-icon-hashtag", marginOperator: auxOperator, level:levelOrigin, color:this.colors[levelOrigin][1]});
                  }else{
                    this.customizeFieldFormula.formulaItems.push({text:item, type:'fields', icon:"lf-icon-hashtag", marginOperator: auxOperator, level:levelOrigin, color:this.colors[levelOrigin][1]});
                  }
                  this.customizeFieldFormula.selectValuesFormula.push({text:item, type:'fields',icon:"lf-icon-hashtag" ,marginOperator: auxOperator,level:levelOrigin,color:this.customizeFieldFormula.colors[levelOrigin][1]});
        }
      }else{
        this.customizeFieldFormula.symbolOrigin = true;
        let fieldId:any = item.slice(1,-1);
        let itemOther:any = this.searchItem(this.concept.fieldContainers, fieldId);
        let label : any;
        if (itemOther) {
          label = itemOther.label;
          iconSymbol = itemOther.icon;
        } else {
          console.error("fieldId " + fieldId +" referenced in formula was not found. was it deleted?");
          label = "field #" + fieldId;
          iconSymbol = 'lf-icon-question';
        }
        this.customizeFieldFormula.formulaItems.push({text:label, type:'fields',icon:iconSymbol, level:levelOrigin,marginOperator: auxOperator, color: this.colors[levelOrigin][1]});
      }
  }

  searchItem(containers:any, itemId:any){
      itemId = parseInt(itemId);
      for(let container of containers){
          for(let field of container.fields){
            if(field.fieldId === itemId){
                return field;
            }
          }
          if(container.subContainers){
              for(let subContainer of container.subContainers){
                for(let subField of subContainer.fields){
                  if(subField.fieldId === itemId){
                    return subField;
                }
                }
              }
          }
      }
  }

  getEditorValue(event:any){
    this.customizeFieldFormula.field.description = event.model;
  }

  itemSelected(e:any, type:any){
      this.customizeFieldFormula.field[type] = e.newValue;
      this.modifyField();
  }

  addEncoding() {
    this.customizeFieldFormula.showNewEncoding = true;
    this.customizeFieldFormula.editingEncodingItem = {
        operator1: null,
        value1: null,
        operator2: null,
        value2: null,
        text: null,
        color: this.customizeFieldFormula.listValueColors[0].color
    };
    this.customizeFieldFormula.operator2Field.noEditable = true;
    this.customizeFieldFormula.editEncodingOkDisabled = true;
  }

  editEncodingItem(encodingItem:any) {
      if (!this.customizeFieldFormula.field.noEditable) {
          this.customizeFieldFormula.originalEncodingItem = encodingItem;
          this.customizeFieldFormula.editingEncodingItem = cloneDeep(encodingItem);
          this.customizeFieldFormula.showNewEncoding = !this.customizeFieldFormula.showNewEncoding;
      }
  }

  encodingFieldChanged(e:any, field:any) {
      let newValue: any = e.newValue;
      let that: any = this.customizeFieldFormula;
      that.editingEncodingItem[field] = newValue;
      let value1NoEditable: any = that.field.noEditable || !that.editingEncodingItem.operator1;
      that.value1Field.noEditable = value1NoEditable;
      that.operator2Field.noEditable = value1NoEditable || !that.editingEncodingItem.value1 || that.editingEncodingItem.operator1 === '=' || that.editingEncodingItem.operator1 === '<>';
      that.value2Field.noEditable = value1NoEditable || !that.editingEncodingItem.value1 || !that.editingEncodingItem.operator2;
      that.editEncodingOkDisabled = value1NoEditable || !that.editingEncodingItem.value1 || that.editingEncodingItem.operator2 && !that.editingEncodingItem.value2 || !that.editingEncodingItem.text || !that.editingEncodingItem.color;
  }

  editEncodingOk() {
      let that: any = this.customizeFieldFormula
      if (that.editingEncodingItem) {
          this.setEncodingDisplayText(that.editingEncodingItem);
          if (that.editingEncodingItem.fieldEncodingId === undefined) {
              that.editingEncodingItem.fieldEncodingId = 0;
              that.field.fieldEncodings.push(that.editingEncodingItem);
              that.editingEncodingItem.numOrder = that.field.fieldEncodings.length;
          } else {
              that.originalEncodingItem.operator1 = that.editingEncodingItem.operator1;
              that.originalEncodingItem.value1 = that.editingEncodingItem.value1;
              that.originalEncodingItem.operator2 = that.editingEncodingItem.operator2;
              that.originalEncodingItem.value2 = that.editingEncodingItem.value2;
              that.originalEncodingItem.text = that.editingEncodingItem.text;
              that.originalEncodingItem.color = that.editingEncodingItem.color;
              that.originalEncodingItem.displayText = that.editingEncodingItem.displayText;
          }
          that.originalEncodingItem = null;
          that.showNewEncoding = !that.showNewEncoding;
          this.modifyField();
      }
  }

  deleteEncodingItem(encodingItem:any) {
      remove(this.customizeFieldFormula.field.fieldEncodings, encodingItem);
  }

  encodingItemInserted(event:any) {
      event.filter((fieldEncoding:any, index: any)=>{
        fieldEncoding.numOrder = index+1;
      });
      this.customizeFieldFormula.field.fieldEncodings = event;
  }

  setEncodingDisplayText(encodingItem:any) {
      let displayText : any = null;
      if (encodingItem.operator1) {
          displayText = encodingItem.operator1 + ' ' + encodingItem.value1;
          if (encodingItem.operator2) {
              displayText += ' ' + this.translate.instant('common.and') + ' ' + encodingItem.operator2 + ' ' + encodingItem.value2;
          }
          displayText += ' = ' + encodingItem.text;
      }
      encodingItem.displayText = displayText;
  }

  cancelFieldText(){
      this.customizeFieldFormula.field = cloneDeep(this.customizeFieldFormula.fieldCopy);
      this.customizeFieldFormula.formulaItems = cloneDeep(this.customizeFieldFormula.formulaItemsCopy);
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldFormula.field
          }
      })
  }

  saveFieldText(){
      if(this.customizeFieldFormula.formulaItems.length){
          this.customizeFieldFormula.fieldCopy = cloneDeep(this.customizeFieldFormula.field);
          this.customizeFieldFormula.formulaError = false;
          this.customizeFieldFormula.formulaItemsCopy = cloneDeep(this.customizeFieldFormula.formulaItems);
          this.changeInField.emit({
              change:{
                  type: 'save',
                  field: this.customizeFieldFormula.field
              }
          })
      }else{
          this.customizeFieldFormula.formulaError = true;
      }
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldFormula.field);
  }

  modifyFieldAtributes(type:any, value:any) {
      if(!this.customizeFieldFormula.field.fieldAttributes){
          this.customizeFieldFormula.field.fieldAttributes={};
      }
      this.customizeFieldFormula.field.fieldAttributes[type] = value;
  }

  launchFormulaSettings(){
    let that:any = this.customizeFieldFormula;
      if(!that.field.noEditable){
          that.originForm.fieldId = that.field.fieldId;
          that.originForm.fieldTypeId = that.field.fieldTypeId;
          that.originForm.fieldContainerId = that.field.fieldContainerId;
          that.originForm.type = that.field.fieldTypeId === 'agreg' || that.field.fieldTypeId === 'agrep'?that.field.fieldTypeId:'normal';
          that.originForm.withWeights = that.field.fieldTypeId === 'formp';
          this.modalService.modalFormulaSettings(this.fieldContainers, that.fieldCopy.formula, that.originForm).subscribe((result:any)=>{
            if(result.result === 'ok'){
              that.fieldCopy.formula = result.formula;
              that.field.formula = result.formula;
              that.formulaItems = result.items;
              that.originForm.formulaItems = result.items;
              that.originForm.symbol = true;
              that.formulaError = false;
              this.modifyField();
            }else{
              that.formulaItems = that.formulaItemsCopy;
              that.originForm.formulaItems = that.formulaItems;
              this.modifyField();
            }
          })
      }
  }

  changeOptionOn(e:any, type:string){
    switch(type){
      case 'private':
        this.customizeFieldFormula.field.private = e.model;
        this.modifyField();
        break;
      case 'hidden':
        this.customizeFieldFormula.field.hidden = e.model;
        this.modifyField();
        break;
      case 'nullsAsZero':
        this.customizeFieldFormula.field.nullsAsZero = e.model;
        this.modifyFieldAtributes('nullsAsZero', e.model)
        break;
      default:
        this.customizeFieldFormula.field[type] = e.model;
        this.modifyField();
        break;
    }
  }
}
