<div class="new-document">
  <div class="row">
      <div class="col-md-12">
          <div class="common__modal-header-block">
              <i [class]="' '+newDocument.iconHeader+' common__icon-header'"></i>
              <span class="common__text-header">{{newDocument.titleHeader}}</span>
              <i class="lf-icon-close new-document__icon-close-modal"
                  (click)="handleDismiss()"></i>
          </div>
          <form class="new-document__block-form"
              *ngIf="newDocument.configuratedFields">
              <div class="new-document__radio-button-block">
                  <field-type-switch [label]="newDocument.pillTemplate"
                                      [checked]="newDocument.showFormat"
                                      [disabled]="!newDocument.templates.length"
                                      (callback)="newDocument.showFormat = $event.value">
                  </field-type-switch>
              </div>
              <div [ngShow]="!newDocument.showFormat"
                  class="new-document__input-form">
                  <field-type-list-simple [field]="newDocument.productFieldInfo"
                      [listValues]="newDocument.formats"
                      [preSelectItem]="newDocument.formatId"
                      (itemSelected)="itemSelectedOn($event, 'formatId')">
                  </field-type-list-simple>
              </div>
              <div [ngShow]="newDocument.showFormat"
                  class="new-document__input-form">
                  <field-type-list-simple [field]="newDocument.templateFieldInfo"
                      [listValues]="newDocument.templates"
                      [preSelectItem]="newDocument.templateId"
                      [orderBy]="'text'"
                      (itemSelected)="itemSelectedOn($event, 'templateId')">
                  </field-type-list-simple>
              </div>
              <div class="new-document__name-document">
                  <field-type-text-simple [field]="newDocument.nameFieldInfo"
                      [preSelectItem]="newDocument.document.name"
                      (itemSelected)="itemName($event)">
                  </field-type-text-simple>
                  <span class="new-document__error-name new-document__error-name--relative"
                      [ngShow]="newDocument.errorNameList">
                      {{newDocument.textError}}</span>
              </div>
              <div class="new-document__name-document"
                  *ngIf="type === 'process-modal' || (type === 'documentation-list' && listSelect.length)">
                  <field-type-list-simple
                      [field]="newDocument.processSteps"
                      [listValues]="listSelect"
                      [preSelectItem]="newDocument.stateId"
                      (itemSelected)="itemSelectedList($event, 'stateId')">
                  </field-type-list-simple>
              </div>
              <div class="common__buttons-block common__buttons-block--modal">
                  <button type="reset"
                      class="common__button common__button--clean"
                      (click)="clearForm()"
                      [afTranslate]="'common.clean'">Limpiar</button>
                  <button type="submit"
                      class="common__button common__button--active"
                      (click)="validateForm()"
                      [afTranslate]="'common.create'">Crear</button>
              </div>
          </form>
      </div>
  </div>
</div>
