import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'field-type-radio-option',
  templateUrl: './field-type-radio-option.component.html'
})

export class FieldTypeRadioOptionComponent implements OnChanges {
  isLanding = this.route.snapshot.data['isLanding'];
  skin = this.isLanding?'landing-custom':this.globalCfg.skin;
  fieldTypeRadioOption: any ={}

  constructor(private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory) { }
  @Input() field:any = null;
  @Input() listOptions:any = null;

  @Output() changeValueOption = new EventEmitter

  ngOnChanges(): void {
    let that:any = this.fieldTypeRadioOption;
    if(this.field){
      that.field = this.field;
      that.titleField = that.field.extendedLabel && that.field.extendedLabel !== ''? that.field.extendedLabel : this.field.label;
    }
  }

  changeValue(option:any){
    let optionChange:any = {
        option: option,
        type: this.fieldTypeRadioOption.field.type
    }
    this.changeValueOption.emit({option: optionChange});
  }
}
