import { Component, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'new-custom-report-identification',
  templateUrl: './new-custom-report-identification.component.html'
})
export class NewCustomReportIdentificationComponent{
  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory
  ) { }

  @Input() newCustomReport:any;
  @Input() productList:any;
  @Input() customReportNameField:any;
  @Input() productFieldInfo:any;

  skin: string = this.globalCfg.skin;
  newCustomReportIdentification:any = {
    tinyMceOptions: {
      typeView: 'white',
      noEditable: false,
      toolbar: 'fontfamily bold italic underline styles bullist numlist outdent indent alignleft aligncenter alignright link unlink superscript subscript strikethrough',
      height: '236px',
      placeholder: this.translate.instant('new-custom-report.tinymce-placeholder')
    },
    identityInfo: {
      type: 'no-margin',
      fieldTypeId: 'rich',
      isHighlight: true,
      label: this.translate.instant('new-custom-report.product-association'),
      description: this.translate.instant('new-custom-report.product-association-text')
    }
  };

  getEditorValue(event:any){
    this.newCustomReport.description = event.model;
  }

  itemSelected(item:any, event:any) {
    let newValue = event.newValue;
    if(item === 'productId'){
        if(newValue !== this.newCustomReport[item]){
            this.newCustomReport.selectedReportFields = [];
        }
    }
    this.newCustomReport[item] = newValue;
    if(newValue){
        switch(item){
            case 'productId':
                this.productFieldInfo.error = false;
                break;
            case 'customReportName':
                this.customReportNameField.error = false;
                break;
        }
    }
  }
}
