import moment from 'moment';


import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { Router } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';


@Injectable({
  providedIn: 'root'
})

export class FrontLoginParentService {

  constructor(private translate: AfTranslateFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private loader: LoaderFactory,
    private router: Router,
    private apiProperties: ApiPropertiesFactory,
    private registerService: RegisterMixtapeService,
    private modalService: ModalServiceService,
    private reportVersion: ReportVersionFactory) { }

  configureFrontLogin(child: any, snapshot:any){
    return {
      loginFormSubmitted: false,
      anonymous:false,
      isLandingLogin: false,
      public: snapshot.queryParams['target'] === '/public/monitoring',
      logo: null,
      askingNref: false,
      buttonText: '',
      showNoPermissionsWarning: false,
      jwtToken: null,
      showButtonExternalWeb: false,
      errorConect: false,
      continueLogin: null,
      // emailError: false,
      working: false,
      errorLogin: false,
      unexpectedError: false,
      forgotUser: null,
      credentials: {
          login: '',
          password: ''
      },
      newPassword: {
          password: '',
          repeatPassword: '',
          passwordError: false,
          repeatPasswordError: false
      },
      languages: [],
      paramsTarget: '',
      noPermissionsContinueText: this.translate.instant('front-login.no-permissions.continue-button'),
      textInfoGeneratedPass: this.translate.instant('front-login.message-ok-generated-pass'),
      iconGeneratedPass: 'lf-icon-face-happy',
      icon:'',
      userText: this.translate.instant('common.user'),
      errorType: this.translate.instant('login-block.invalid-user'),
      inputLabel: this.translate.instant('front-login.insert-email'),
      inputPlaceholder: this.translate.instant('common.email'),
      inputIcon: 'lf-icon-mail',
      selectedLanguage:'',
      paramAccess:'',
      paramsErr:'',
      footerConceptPublic:{},
      custom:{},
      errorDescription:'',
      retryCount:0,
      errorText:'',
      showBackButton:false,
      externalWebUrl:'',
      resetPass:false,
      showTemplate: false,
      landingLoginForm: {
          login:''
      }
    }
  }

  onInit(child:any, snapshot: any){
      child.frontLogin.showTemplate = true;
      this.configureViewTemplate(child, snapshot);
  }

  configureViewTemplate(child:any, snapshot:any){
      let that: any = child.frontLogin;
      let name: any = snapshot.data['name'];
      let err: any = snapshot.params['err'];
      that.isLandingLogin = name === 'contact-login';
      that.generateNewPasswordView = name === 'change-password';
      that.generateNewPasswordExpired = name === 'change-password-error';
      if(child.skin === 'icam-red' && !that.isLandingLogin){
        document.title = 'Gestión del Conocimiento';
    }
      if(that.generateNewPasswordExpired){
          that.textInfoGeneratedPass = this.translate.instant('front-login.message-expired-pass');
          that.iconGeneratedPass = 'lf-icon-face-sad';
      }
      that.showViewGenerate = that.generateNewPasswordView || that.generateNewPasswordExpired;
      this.configureSpecificationsBySkin(child);

      let stateParamserror = sessionStorage.getItem('paramsError');
      sessionStorage.removeItem('paramsError');
      if(stateParamserror){
        that.errorDescription = stateParamserror;
      }
      that.buttonText = this.translate.instant('front-login.access');
      if(err){
          this.configureSSOErrorView(err, child);
      }
  }

  configureSSOErrorView(err: any, child: any){
    //SSO login of user without permissions
    let that: any = child.frontLogin;
    if (err === '*no-permissions-warning*') {
      this.doLoginOk(child, {
          jwtToken: this.context.jwtToken,
          user: this.context.user
      });
    } else {
      that.isLandingLogin = true;
      that.public = true;
      let errLiteral = this.translate.instant('front-login.ssoLogin.' + that.errorDescription);
      this.loginError(child, err);
      if ((err.status == 401 || err.status == 403 || err.status == undefined) && that.errorDescription) {
        that.error = errLiteral || that.errorDescription;
        that.showbackButton = false;
      }
      if(err && that.errorDescription && that.errorDescription === 'regular-not-supported'){
        that.showButtonExternalWeb = true;
        this.context.logout();
        that.externalWebUrl = child.globalCfg.ssoLoginUrl;
      }
    }
    if(!err.description || (err.description && err.description !== 'regular-not-supported')){
        sessionStorage.setItem('paramsError', err);
    }
  }

  configureSpecificationsBySkin(child:any){
    let that: any = child.frontLogin;
    that.phoneContact = this.translate.instantSiteTerms('tel');
    that.mailContact = this.translate.instantSiteTerms('mail');
    switch(child.skin){
        case 'steel-blue':
            that.logoHeader = '../assets/img/logo-GoComply.png';
            this.getLanguages(child);
            break;
        case 'uk-steel-blue':
            that.logoHeader = '../assets/img/logo-TaxWin.png';
            break;
        case 'icam-red':
            that.logoHeader = '../assets/img/logo-icam-header.png';
            break;
        case 'gm-law':
            that.logoHeader = '../assets/img/logo-giuffre-header40.png';
            break;
        case 'com-fr':
            that.logoHeader = '../assets/img/lefebvre_dalloz.png';
            break;
        case 'com-de':
          that.logoHeader = '../assets/img/logo-giuffre-header40.png';
          break;
        default:
            that.logoHeader = 'https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-bl-120x24.png';
            break;
    }
  }

  getLanguages(child:any){
    let that: any = child.frontLogin
      that.languages = this.commonUtilsService.configureAvailableLanguages(child.apiProperties.availableLanguages);
      let language:any = sessionStorage.getItem('selectLanguage');
      if(language){
          that.selectedLanguage = this.catchDefaultLanguaje(child, language);
      }else if(this.context.user && this.context.user.culture){
          that.selectedLanguage = this.catchDefaultLanguaje(child, this.context.user.culture);
      }else{
        that.selectedLanguage = this.catchDefaultLanguaje(child);
      }
  }

  catchDefaultLanguaje(child:any, culture:string = ''){
      let language: any = culture && culture !== ''?culture : child.apiProperties.defaultLanguage;
      return child.frontLogin.languages.filter((languajeItem: any)=> language === languajeItem.listValueId)[0]
  }


  doLoginOk(child:any, data:any){
      let that: any = child.frontLogin;
      if (that.isLandingLogin || data.user.hasPermissions || child.snapshot.queryParams['target'] === '/import-evaluation') {
          this.continueLogin(child, data);
      } else {
          that.showTemplate = true;
          that.jwtToken = data.jwtToken;
          that.showNoPermissionsWarning = true;
          that.continueLoginData = data;
          this.loader.closeLoader(['front-login-login']);
      }
  }

  continueLogin(child:any, data:any) {
      let that: any = child.frontLogin;
      that.working = false;
      if(that.public){
          that.credentials.isLogged = this.isEmail(child);
          data.user = that.credentials;
      }
      this.context.setLogin(data);
      let resgisterItem ={
        'Distinct ID': this.context.user.externalId,
        'Client ID': this.context.user.companyExternalId,
        'event':'log in',
        'source': that.isLandingLogin?'landing':'app',
        'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem);

      sessionStorage.removeItem('paramsError');
      if(that.public){
          this.router.navigate(['public/monitoring']);
      }else if(child.snapshot.data['name'] === 'dfa' && sessionStorage.getItem('urlLoginDfa')){
            let url: any = sessionStorage.getItem('urlLoginDfa');
            sessionStorage.removeItem('urlLoginDfa');
            this.router.navigateByUrl(url);
      }else if (child.snapshot.queryParams['target']) {
          if(child.snapshot.queryParams['access']){
            this.router.navigate([child.snapshot.queryParams['target']], {queryParams:{access: child.snapshot.queryParams['access']}});
          }else{
            this.router.navigate([child.snapshot.queryParams['target']]);
          }
      } else {
          this.router.navigate(['dashboard']);
      }
  }

  isEmail(child:any) {
      return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(child.frontLogin.credentials.login);
  }

  loginError(child:any, err:any) {
      let that: any = child.frontLogin;
      that.working = false;
      this.loader.closeError();
      that.errorConect = true;
      that.errorText = this.translate.instant('login-block.sorry');
      that.showNoPermissionsWarning = false;
      if(!that.isLandingLogin){
          switch(err.status){
            case 401:
                that.errorLoginMessage = this.translate.instant('front-login.error-user')
                break;
            case 403:
                that.errorLoginMessage = this.translate.instant('front-login.error-product');
                break;
            default:
                that.errorLoginMessage = this.translate.instant('front-login.error-connect');
                break;
          }
      }else if(err){
          switch(err.status){
              case 401:
                  that.error = this.translate.instant('login-block.invalid');

                  if(that.askingNref){
                      that.error = this.translate.instant('login-block.invalid-ref');
                  }else if(that.isLandingLogin && that.errorConect){
                      that.error = this.translate.instant('field-type-email.invalid-email');
                  }
                  that.icon = 'lf-icon-key';
                  that.showbackButton =  true;
                  break;
              case 403:
                  that.error = this.translate.instant('login-block.file-not-found');
                  that.icon = 'lf-icon-face-sad';
                  break;
              case 410:
                  that.errorText = this.translate.instant('login-block.close-task');
                  that.error = this.translate.instant('login-block.no-content');
                  that.icon = 'lf-icon-lock';
                  break;
              case 400:
                  that.error =this.translate.instant('login-block.file-not-found');
                  that.icon = 'lf-icon-key';
                  break;
              default:
                  that.icon = 'lf-icon-face-sad';
                  that.error = this.translate.instant('login-block.error-generic');
                  if (typeof (err) == 'string') {
                      console.error('Reason: ' + err);
                  }
                  break;
          }
      }
  }

  resetPassword(child: any){
    let that: any = child.frontLogin;
    that.errorConect = false;
    that.resetPass = !that.resetPass;
    that.error = '';
    that.errorType = '';
  }

  clickAction(child: any, e: any=null){
    e.type === 'onError'? this.loginError(child, e.error) : this.continueLogin(child, child.frontLogin.continueLoginData);
  }

  sendReset(child: any){
    let that: any = child.frontLogin
    that.resetPass = true;
    that.errorType = '';
    this.loader.openLoader('front-login-send-reset');
    this.apiService.resetPassword({email: that.forgotUser})
        .then((data:any)=>{
            this.loader.closeLoader(['front-login-send-reset']);
            that.unexpectedError = true;
            that.icon = 'lf-icon-check-round-full front-login__input-icon--ok';
            that.resetPassTitle = this.translate.instant('front-login.review-email');
            that.resetPassText = this.translate.instant('front-login.message-email');
        }, (errorData:any)=>{
            this.loader.closeError();
            switch(errorData.status){
                case 400:
                case 404:
                    that.unexpectedError = false;
                    that.userText = this.translate.instant('login-block.invalid-user');
                    that.errorType = this.translate.instant('login-block.invalid-user');
                    break;
                case 409:
                    that.unexpectedError = true;
                    that.icon = 'lf-icon-close-round-full front-login__input-icon--error';
                    that.resetPassTitle = this.translate.instant('front-login.administrator-error-title');
                    that.resetPassText = this.translate.instant('front-login.administrator-error-message');
                    break;
                default:
                    that.unexpectedError = true;
                    that.icon = 'lf-icon-close-round-full front-login__input-icon--error';
                    that.resetPassTitle = this.translate.instant('login-block.error-pass-title');
                    that.resetPassText = this.translate.instant('login-block.error-pass-text');
                    break;
            }
        })
  }

  doLogin(child: any, loginForm: any) {
    let that: any = child.frontLogin;
      that.error = '';
      that.errorConect = false;
      if (that.isLandingLogin) {
          that.credentials.password = child.snapshot.queryParams['access'];
      } else {
        that.emptyLogin = that.credentials.login === '';
        that.emptyPassword = that.credentials.password === '';
        if (that.emptyLogin || that.emptyPassword) {
          return;
        }
      }
      this.loader.openLoader('front-login-login');
      this.apiService.login( that.credentials,  that.isLandingLogin ? 'public' : 'front')
        .then((data: any)=> {
          let time: any = '';
          if(data.user !== null && data.user?.lastLoginTime !== null){
            time = String(data.user.lastLoginTime)
          }
          sessionStorage.setItem('lastLogin', time);
          if(data.dfaUrl) {
              if(child.snapshot.queryParams['target']){
                sessionStorage.setItem('urlLoginDfa', child.snapshot.queryParams['target']);
              }
              window.open(data.dfaUrl, '_self');
          }else{
            this.doLoginOk(child, data);
          }
        }, (errorData:any)=> {
            this.loginError(child, errorData);
        });
      that.working = true;
  }

  configureDfaLogin(child: any, snapshot:any){
    this.loader.openLoader('front-login-login');
    let dfaData: any = {
      sessionId: snapshot.queryParams['sessionId']
    };
    this.apiService.login(dfaData, 'dfa')
      .then((data:any)=>{
        let time: any = '';
        if(data.user !== null && data.user?.lastLoginTime !== null){
          time = String(data.user.lastLoginTime);
        }
        sessionStorage.setItem('lastLogin', time);
        this.doLoginOk(child, data);
      }, (errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('front-login.error-verification'), 'error:accept:warning')
          .subscribe((result:any) => {
            sessionStorage.removeItem('urlLoginDfa');
            this.router.navigateByUrl('login');
          })
      })
  }

  tryAgain(child: any){
    let that: any = child.frontLogin;
    that.showbackButton = false;
    that.error = '';
    that.errorConect = false;
    that.credentials.login = '';
  }
}
