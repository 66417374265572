import { Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'concursal-forms-modal',
  templateUrl: './concursal-forms-modal.component.html'
})
export class ConcursalFormsModalComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private apiService:ApiServiceService,
    private translate:AfTranslateFactory
  ) { }

  @Input() configuration:any;

  skin:string = this.globalCfg.skin;
  concursalFormsModal:any = {
    title: this.translate.instant('concursal-forms.header-title'),
    description: this.translate.instant('concursal-forms.content-description'),
    headerIcon: 'lf-icon-forms'
  };

  ngOnInit(){
    this.getProductPermissions(this.configuration.product.productId);
  }

  getProductPermissions(productId:any){
    this.apiService.get('company/dashboard/security/product', productId).then(
      (data:any) => {
          this.concursalFormsModal.managePermissions = data.allowManage;
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  launchActionFormsModal(action:any) {
    switch (action.action) {
        case 'edit':
            this.handleDismiss();
            break;
        case 'newEvaluation':
            action.product = this.configuration.product;
            this.activeModal.close({
              result: 'newEvaluation',
              action: action
            });
            break;
        default:
            this.activeModal.close({
              result: action,
              action: action
            });
            break;
    }
  }

  handleDismiss(){
    this.activeModal.close({result: 'cancel'});
  }
}
