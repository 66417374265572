import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-bool',
  templateUrl: './customize-field-bool.component.html'
})
export class CustomizeFieldBoolComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFieldBool: any = {
    tinyMceOptions: {},
    init: true,
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    }
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private conceptState: ConceptStateFactory) { }
  @Input() field:any = null;
  @Input() visibilityItems:any = null;
  @Input() isPrivate:any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList:any = null;
  @Input() concept:any = null;
  @Input() pollObject:any = null;
  @Output() changeInField = new EventEmitter()

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldBool;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue
      that.color = that.field.withColor >= 1;
      that.fieldCopy = cloneDeep(that.field);
      that.contentReportInfo.noEditable = that.field.noEditable;
      if(that.field.fieldTypeId === 'boolp'){
          that.weightYesInfo={
            label: this.translate.instant('customize-field-bool.weight-yes'),
            fieldTypeId: 'num',
            noEditable: that.field.noEditable
          }
          that.weightNoInfo={
            label: this.translate.instant('customize-field-bool.weight-no'),
            fieldTypeId: 'num',
            noEditable: that.field.noEditable
          }
      }
    }
    if(this.concept?.poll && this.concept?.disabled){
      that.contentReportInfo.noEditable = this.concept.disabled;
    }
    that.init = false;
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
    if(this.pollObject?.pollstate !== 'init' && !this.pollObject?.pollValue){
      that.field.isDisaggregated = false;
      this.apiService.patch('fields',that.field.fieldId, that.field)
          .then((data:any)=>{}, (errorData:any)=>{})
    }
  }

  itemSelected(e:any, item:any){
    let that: any = this.customizeFieldBool
    switch(item){
        case 'weightYes':
            that.field.listValues[0].weight = e.newValue;
            that.weightYesInfo.error = false;
            this.hiddenError();
            break;
        case 'weightNo':
            that.field.listValues[1].weight = e.newValue;
            that.weightNoInfo.error = false;
            this.hiddenError();
            break;
        default:
            that.field[item] = e.newValue;
            break;
    }
    this.modifyField();
  }

  hiddenError(){
      if(this.customizeFieldBool.field.listValues[0].weight !== null && this.customizeFieldBool.field.listValues[1].weight !== null){
          this.customizeFieldBool.showError = false;
      }
  }

  getEditorValue(event:any){
    this.customizeFieldBool.field.description = event.model;
  }

  changeAssociatedColor(){
    this.customizeFieldBool.field.withColor = this.customizeFieldBool.field.withColor === 1? 2 : 1;
    this.customizeFieldBool.modifyField();
  }

  cancelFieldText(){
      this.customizeFieldBool.field = cloneDeep(this.customizeFieldBool.fieldCopy);
      this.customizeFieldBool.color = false;
      this.changeInField.emit({
          change:{
              type: 'cancel',
              field: this.customizeFieldBool.field
          }
      })
  }

  saveFieldText(){
      if(this.checkValues()){
          this.customizeFieldBool.fieldCopy = cloneDeep(this.customizeFieldBool.field);
          this.changeInField.emit({
              change:{
                  type: 'save',
                  field: this.customizeFieldBool.field
              }
          })
      }
  }

  checkValues(){
      let errors : any = 0;
      if(this.customizeFieldBool.field.fieldTypeId === 'boolp'){
          this.customizeFieldBool.weightYesInfo.error = false;
          this.customizeFieldBool.weightNoInfo.error = false;
          if(this.customizeFieldBool.field.listValues[0].weight === null){
              errors++;
              this.customizeFieldBool.weightYesInfo.error = true;
          }
          if(this.customizeFieldBool.field.listValues[1].weight === null){
              errors++;
              this.customizeFieldBool.weightNoInfo.error = true;
          }
      }
      this.customizeFieldBool.showError = errors?true:false;
      return errors?false:true;
  }

  openVisibility(e:any){
    this.customizeFieldBool.field.visibilityOr = e.visibilityOr;
    this.customizeFieldBool.field.openBlock = e.openBlock;
  }

  modifyField(){
      if(!this.customizeFieldBool.field.required){
          this.customizeFieldBool.field.isDisaggregated = this.customizeFieldBool.field.required
      }
      this.conceptState.add(this.customizeFieldBool.field);
  }

  changeOptionOn(e:any, type:string){
    this.customizeFieldBool.field[type] = e.model;
    switch(type){
      case 'isHighlight':
          if(this.customizeFieldBool.field.isHighlight){
              this.customizeFieldBool.color = false;
              this.customizeFieldBool.field.withColor = 0;
          }
          break;
      case 'color':
          if(this.customizeFieldBool.field.color){
              this.customizeFieldBool.field.isHighlight = false;
              this.customizeFieldBool.field.withColor = 1;
          }else{
              this.customizeFieldBool.field.withColor = 0;
          }
          break;
      default:
        break;
    }
    this.modifyField()
  }
}
