<div class="states-process"
  [ngClass]="{'states-process--task': type === 'normalTask',
  'states-process--question':statesProcess.showQuestion}">
  <div class="states-process__block-header"
      [ngClass]="{'states-process__block-header--question':statesProcess.showQuestion}"
      [ngHide]="typeView==='changeStateModal'">
      <p class="states-process__creation-date"
          [ngShow]="skin==='icam-red' && !statesProcess.showQuestion">
          <span>Entrada:</span>
          <span>{{statesProcess.concept.creationDate | convertDate:''}}</span>
      </p>
      <i class="lf-icon-process states-process__icon-process"
        [ngShow]="skin==='dark-blue'"></i>
      <span class="states-process__title-process">{{statesProcess.titleHeader}}</span>

      <i class="states-process__icon-header-right"
          [ngClass]="type === 'normalTask'?'lf-icon-information':'lf-icon-open-window'"
          (click)="showProcessModal()"></i>
      <span class="states-process__states-proccess-text"
        [ngShow]="skin!=='dark-blue'">{{statesProcess.iconName}}</span>
  </div>
  <div class="states-process__block-body"
      [ngHide]="statesProcess.showQuestion"
      *ngIf="type !== 'normalTask' && statesProcess.showStates">
      <div class="states-process__block-state"
          [ngShow]="statesProcess.summary?.previous">
          <div class="states-process__block-line-state">
              <button class="states-process__block-repeat-states"
                  [ngClass]="{'states-process__block-repeat-states--previous': true,
                      'states-process__block-repeat-states--disabled': !statesProcess.canChangeState}"
                  [disabled]="!statesProcess.canChangeState"
                  (click)="changeProcessQuestion(statesProcess.summary?.previous, 'previous')">
                  <i class="states-process__icon-arrow-left"
                      [ngClass]="skin === 'dark-blue'?'lf-icon-arrow-left':'lf-icon-arrow-round-left'"></i>
                      <div class="states-process__text-process-inactive">
                          <span-tooltip [text]="statesProcess.summary?.previous?.stateName">
                          </span-tooltip>
                      </div>
              </button>
              <i class="lf-icon-documentation states-process__icon-briefcase"
                  [ngShow]="statesProcess.summary?.previous?.hasDocuments"></i>
          </div>
          <div class="states-process__bar-progress states-process__bar-progress--prev"></div>
          <div class="states-process__title-process-state"
              [ngShow]="skin!=='dark-blue'">
              <!-- <span>&lt&lt</span> -->
              <span>{{statesProcess.arrowsPrev}}</span>
              <span [afTranslate]="'task-concept-list.process-task.prev-state'"> Estado anterior</span>

          </div>

      </div>
      <div class="states-process__block-state states-process__block-state--principal">
          <i class="states-process__icon-button-active"
              [ngClass]="skin === 'dark-blue'?'lf-icon-radio-button-active':'lf-icon-map-marker'"></i>
          <span class="states-process__text-process-active">{{statesProcess.summary?.current?.name}}</span>
          <i class="lf-icon-documentation states-process__icon-briefcase"
              [ngShow]="statesProcess.summary?.current?.hasDocuments"></i>
          <div class="states-process__bar-progress"></div>
          <div class="states-process__title-process-state"
              [ngShow]="skin!=='dark-blue'">
              <span [afTranslate]="'task-concept-list.process-task.current-state'">_Estado actual</span>
          </div>
      </div>
      <div class="states-process__block-state"
          [ngShow]="statesProcess.summary && statesProcess.summary?.next?.length">
          <div class="states-process__block-line-state"
              *ngFor="let nextItem of statesProcess.summary?.next">
              <button class="states-process__block-repeat-states"
                      [ngClass]="{'states-process__block-repeat-states--disabled': !statesProcess.canChangeState}"
                      [disabled]="!statesProcess.canChangeState"
                      (click)="changeProcessQuestion(nextItem, 'next')">
                      <div class="states-process__text-process-inactive">
                          <span-tooltip [text]="nextItem.stateName">
                          </span-tooltip>
                      </div>
                  <!-- <span>{{nextItem.stateName}}</span> -->
                  <i class="states-process__icon-arrow-right states-process__icon-arrow-left"
                      [ngClass]="skin === 'dark-blue'?'lf-icon-arrow-right':'lf-icon-arrow-round-right'"></i>
              </button>
              <i class="lf-icon-documentation states-process__icon-briefcase"
                  [ngShow]="nextItem.hasDocuments"></i>
          </div>
          <div class="states-process__bar-progress states-process__bar-progress--next"></div>
          <div class="states-process__title-process-state"
              [ngShow]="skin!=='dark-blue'">
              <span [afTranslate]="'task-concept-list.process-task.next-state'">_Estado siguiente</span>
              <!-- <span>&gt&gt</span> -->
              <span>{{statesProcess.arrowsAfter}}</span>
          </div>
      </div>
  </div>
  <div class="states-process__block-body-task"
      [ngHide]="statesProcess.showQuestion"
      *ngIf="type === 'normalTask'">
      <div class="states-process__block-state-task"
          [ngShow]="statesProcess.statesList.length"
          *ngFor="let state of statesProcess.statesList; let first = first; let last = last">
          <div class="states-process__block-line-state">
              <div class="states-process__block-state-active"
                  [ngShow]="state.stateActive">
                  <i class="states-process__icon-button-active"
                      [ngClass]="skin === 'dark-blue'?'lf-icon-radio-button-active':'lf-icon-map-marker'"
                      [ngStyle]="{'color': state.color}"></i>
                  <span class="states-process__text-process-active">{{state.state}}</span>
              </div>
              <button [class]="'states-process__block-repeat-states states-process__block-repeat-states--'+state.stateColor"
                  [ngClass]="{'states-process__block-repeat-states--disabled': state.disabledState,
                      'states-process__block-repeat-states--previous': state.prevState,
                      'states-process__block-repeat-states--active-color': skin !== 'dark-blue' && !state.stateActive}"
                  [disabled]="state.disabledState"
                  (click)="changeProcessQuestion(state, 'task')"
                  [ngShow]="!state.stateActive">
                  <i class="states-process__icon-arrow-left"
                      [ngShow]="state.prevState"
                      [ngStyle]="{'color':skin !=='dark-blue'?state.color:'' }"
                      [ngClass]="skin === 'dark-blue'?'lf-icon-arrow-left':'lf-icon-arrow-round-left'"></i>
                  <div [ngClass]="state.stateActive?'states-process__text-process-active states-process__text-process-active':'states-process__text-process-inactive states-process__text-process-inactive'">
                      <span-tooltip [text]="state.state">
                      </span-tooltip>
                  </div>
                  <i class="states-process__icon-arrow-right"
                      [ngShow]="state.nextState"
                      [ngStyle]="{'color':skin !=='dark-blue'?state.color:'' }"
                      [ngClass]="skin === 'dark-blue'?'lf-icon-arrow-right':'lf-icon-arrow-round-right'"></i>
              </button>
          </div>
          <div class="states-process__bar-progress states-process__bar-progress--task"
              [ngClass]="{'states-process__bar-progress--first':first && skin === 'steel-blue',
                'states-process__bar-progress--last':last && skin === 'steel-blue'}"
              [ngStyle]="{'background-color': state.color}"></div>
          <div class="states-process__title-process-state"
              [ngShow]="skin!=='dark-blue'">
              <span>{{state.stateStep}}</span>
          </div>
      </div>
  </div>
  <div [ngShow]="statesProcess.showQuestion"
      class="states-process__block-question">
      <i class="lf-icon-question states-process__icon-help"></i>
      <p class="states-process__text-question">{{statesProcess.questionChangeProcess}}</p>
      <div class="states-process__buttons-block">
          <button type="reset"
              class="common__button common__button--clean common__button--litle-question"
              (click)="statesProcess.showQuestion = !statesProcess.showQuestion"
              [afTranslate]="'common.no'">_No</button>
          <button type="submit"
              class="common__button common__button--active common__button--litle-question"
              (click)="confirmChange()"
              [afTranslate]="'common.yes'">_Si</button>
      </div>
  </div>
</div>
