import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Directive({
  selector: '[validateNumber]'
})
export class ValidateNumberDirective {
  nativeElement:any = this.element.nativeElement;
  unit: any = {
    decimals: (1000000.1).toLocaleString().substr((1000000.1).toLocaleString().lastIndexOf('1')-1,1),
    millar: (1000000.1).toLocaleString().substr((1000000.1).toLocaleString().indexOf('1')+1,1)
  }
  constructor(private element:ElementRef,
    private commonUtilsService: CommonUtilsService) {}

  @Input() noDot:boolean = false;//Sin punto
  @Input() noNegatives:boolean = false;//Solo positivos
  @Input() isDate:boolean = false;//Solo positivos
  @Input() maxDecimal:number|null = 0;//Num decimales
  @Input() from:any;//Desde
  @Input() until:any;//Hasta

  @HostListener('input', ['$event'])
  onInput(event:InputEvent){
    this.setInputValue(event);
  }

  setInputValue(event: any){
    let sign: any ='';
    let value: any = this.nativeElement.value;
    if(value === '-' && this.noNegatives){
        this.nativeElement.value = '';
        event.preventDefault();
        event.target.dispatchEvent(new KeyboardEvent('input'));
    }else{
      let maxDecimal: any = this.maxDecimal === null?6:0;
      if(this.maxDecimal){
          maxDecimal = this.maxDecimal;
      }
      // Se crea array eliminando valores no numéricos y se comprueba si puede introducirse un signo negativo
      let digitsArray = this.noNegatives?value.match(/(\d)/g):value.match(/(^-|\d)/g);
      let digits = '';
      if(digitsArray){
          digits = digitsArray.join('');
      }
      if(digits==='-' || parseInt(digits)<0){
          sign= '-';
      }
      if(maxDecimal !== 0 ){
          digits = value.replace(new RegExp('[^0-9'+this.unit.decimals+']', 'g'), '');
      }
      if (this.from && this.from !=='' && this.until && this.until !== '' && ((parseInt(digits) < parseInt(this.from) || parseInt(digits) > parseInt(this.until)) || digits.length > this.from.length)){
          // verificacion para poner un limite minimo y maximo al numero
          if(parseInt(digits) < this.from){
              digits = this.from;
          }else if(parseInt(digits) >= this.until){
              digits = this.until;
          }
      }

      // comprobación de si tiene mas de una 'coma' decimal
      if (digits.split(this.unit.decimals).length > 2) {
          digits = digits.substring(0, digits.length - 1);
      }
      // comprobación si tiene mas decimales de los permitidos
      if(digits.split(this.unit.decimals,2)[1]){
          if (digits.split(this.unit.decimals,2)[1].length> maxDecimal){
              let decimals = digits.split(this.unit.decimals,2)[1];
              decimals = decimals.substring(0, decimals.length - 1);
              digits = digits.split(this.unit.decimals)[0] + this.unit.decimals + decimals;
          }
      }
      // Se añaden las separaciones de millar cuando son necesarias
      let decimalSign = digits && digits.length && digits.lastIndexOf(this.unit.decimals) === (digits.length - 1);
      if(digits && !decimalSign && !this.noDot && !this.isDate){
          digits = this.commonUtilsService.setLocaleNumberFormat(digits);
      }
      if(sign+digits !== value){
          this.nativeElement.value = sign+digits;
          event.preventDefault();
          event.target.dispatchEvent(new KeyboardEvent('input'));
      }
    }
  }
}
