import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'users-admin-person-form',
  templateUrl: './users-admin-person-form.component.html'
})
export class UsersAdminPersonFormComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  type: string = this.route.snapshot.params['type']? this.route.snapshot.params['type'] : this.route.snapshot.data['type'];
  subscribers : any = {};
  usersAdminPersonForm: any = {
    lockedEditFields: false,
    clientItems: [],
    groupItems: [],
    languages: this.commonUtilsService.configureAvailableLanguages(this.apiProperties.availableLanguages),
    name: {
        label: this.translate.instant('users-admin.person-form.name'),
        required: true,
        maxLength: 25
    },
    surname: {
        label: this.translate.instant('users-admin.person-form.surname'),
        required: true,
        maxLength: 50
    },
    nif: {
        label: this.translate.instant('users-admin.person-form.nif'),
        maxLength: 50
    },
    position: {
        label: this.translate.instant('users-admin.person-form.position'),
        maxLength: 50
    },
    companyName: {
        label: this.translate.instant('users-admin.person-form.company'),
        maxLength: 50
    },
    address: {
        label: this.translate.instant('users-admin.person-form.address'),
        maxLength: 250
    },
    postalCode: {
        label: this.translate.instant('users-admin.person-form.postalCode'),
        maxLength: 10
    },
    city: {
        label: this.translate.instant('users-admin.person-form.city'),
        maxLength: 25
    },
    province: {
        label: this.translate.instant('users-admin.person-form.province'),
        maxLength: 25
    },
    country: {
        label: this.translate.instant('users-admin.person-form.country'),
        maxLength: 25
    },
    email: {
        label: this.translate.instant('users-admin.person-form.email'),
        required: true,
        maxLength: 100
    },
    phoneNumber1: {
        label: this.translate.instant('users-admin.person-form.phone1'),
        maxLength: 20
    },
    phoneNumber2: {
        label: this.translate.instant('users-admin.person-form.phone2'),
        maxLength: 20
    },
    clients: {
        label: this.translate.instant('users-admin.person-form.clients'),
        required: true,
        multiple: true,
        filials: true
    },
    groups: {
        label: this.translate.instant('users-admin.person-form.groups'),
        required: true,
        multiple: true
    },
    culture: {
        label: this.translate.instant('users-admin.person-form.languaje-preferences'),
        fieldId: 'user-languages',
        required: true
    },
    identityInfo: {
        type: 'no-margin',
        isHighlight: true,
        label: this.translate.instant('users-admin.person-form.title-changes'),
        description: this.translate.instant('users-admin.person-form.info-changes')
    }
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private translate: AfTranslateFactory,
    private apiProperties: ApiPropertiesFactory,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService) { }
  @Input() person: any = {};
  @Input() operation: any = '';
  @Output() validateForm = new EventEmitter();

  ngOnInit(): void {
      this.usersAdminPersonForm.email.noEditable = (this.operation === 'edit' || this.operation === 'edit-basic') && this.type !== 'contact';
      this.openWatchers();
      this.person.addGroups = !!(this.person.groups && this.person.groups.length);
      this.loadClients();
      this.loadGroups();
      if((!this.person.culture || this.person.culture === null) && this.usersAdminPersonForm.languages.length){
          if(this.context.user.culture){
              this.person.culture = this.catchDefaultLanguaje(this.context.user.culture).listValueId;
          }else{
              this.person.culture = this.catchDefaultLanguaje().listValueId;
          }
      }
      if(this.skin === 'uk-steel-blue' && this.type === 'user'){ //Uk
        this.broadcastService.broadcast('person-loaded',{person:this.person});
      }
      if(this.operation === 'edit-basic' && !this.globalCfg.caps.userCanEditOwnPersonalData){
          this.usersAdminPersonForm.lockedEditFields = true;
          this.lockFields();
      }
  }

  openWatchers(){
    this.subscribers.changeGroupsSavedBroadcast = this.broadcastService.subscribe('changeGroupsSaved', (data:any)=>{
      this.resetGroup();
    });
    this.subscribers.checkPersonFormBroadcast = this.broadcastService.subscribe('checkPersonForm', (data:any)=>{
      if(this.checkFields()){
          this.validateForm.emit();
      }
    });
    this.subscribers.resetPersonFormBroadcast = this.broadcastService.subscribe('resetPersonForm', (data:any)=>{
        this.reset();
    });
  }

  catchDefaultLanguaje(culture:any=undefined){
      let language = this.apiProperties.defaultLanguage;
      if(culture){
          language = culture;
      }
      for(let i = 0; i < this.usersAdminPersonForm.languages.length; i++){
          if(language === this.usersAdminPersonForm.languages[i].listValueId){
              return this.usersAdminPersonForm.languages[i];
          }
      }
  }

  lockFields(){
      let fieldsLocked = ['name', 'surname', 'nif', 'position', 'companyName', 'address', 'postalCode', 'city', 'province', 'country', 'email', 'phoneNumber1', 'phoneNumber2', 'clients', 'groups'];
      fieldsLocked.forEach((field:any) => {
        this.usersAdminPersonForm[field].noEditable = true;
      })
  }

  loadClients(){
      this.apiService.get('clients/all')
        .then((data:any)=>{
          data.forEach((client:any) => {
            if(!client.parentId){
              let newClient = {
                id: client.clientId,
                listValueId: client.clientId,
                text: client.name,
                children: this.searchClientChildren(client.clientId, data)
              }
              this.usersAdminPersonForm.clientItems.push(newClient);
            }
          });
        }, (error:any)=>{})
  }

  searchClientChildren(parentId: any, clientsList: any){
      let children: any = [];
      clientsList.forEach((client:any) => {
        if(client.parentId == parentId){
            children.push({
                id: client.clientId,
                listValueId: client.clientId,
                text: client.name,
                children: this.searchClientChildren(client.clientId, clientsList)
            });
        }
      });
      return children;
  }

  loadGroups(){
      this.apiService.get('usersgroups')
        .then((data:any)=>{
          this.usersAdminPersonForm.groupItems = this.commonUtilsService.editList(data, 'groupId', 'name');
        }, (error:any)=>{})
  }

  itemSelected(type: string='', newValue: any){
      this.person[type] = newValue;
  }

  groupsSelected(groups: any){
      this.person.groups = groups;
  }

  clientsSelected(clients: any){
      this.person.clients = clients;
  }

  reset(){
      if(!this.usersAdminPersonForm.lockedEditFields){
          this.person.name = '';
          this.person.surname = '';
          this.person.position = '';
          this.person.companyName = '';
          this.person.address = '';
          this.person.postalCode = '';
          this.person.city = '';
          this.person.province = '';
          this.person.town = '';
          this.person.country = '';
          this.person.phoneNumber1 = '';
          this.person.phoneNumber2 = '';
          if(!this.usersAdminPersonForm.email.noEditable){
            this.person.email = '';
          }
          if(this.type === 'contact'){
            this.person.clients = [];
            this.resetClients(this.usersAdminPersonForm.clientItems);
          }
          if(this.type !== 'group') {
            this.person.addGroups = false;
            this.resetGroup();
          }
      }

      if(this.context.user.culture){
          this.person.culture = this.catchDefaultLanguaje(this.context.user.culture).listValueId;
      }else{
          this.person.culture = this.catchDefaultLanguaje().listValueId;
      }
  }

  resetClients(clientsList: any){
      clientsList.forEach((client:any) => {
        client.selected = false;
        client.open = false;
        if(client.children && client.children.length){
            this.resetClients(client.children);
        }
      })
  }

  resetGroup(){
      if(!this.person.addGroups){
          this.usersAdminPersonForm.groupItems.forEach((group:any) => {
            group.selected = false;
          })
          this.person.groups = [];
      }
  }

  checkFields(){
      let errors = false;
      errors = (this.usersAdminPersonForm.name.error = !this.person.name) || errors;
      errors = (this.usersAdminPersonForm.surname.error = !this.person.surname) || errors;
      errors = (this.usersAdminPersonForm.email.error = !this.person.email || !(this.commonUtilsService.validateMail(this.person.email))) || errors;
      if(this.type !== 'group'){
          errors = (this.usersAdminPersonForm.groups.error = this.person.addGroups && !this.person.groups.length) || errors;
      }
      if(this.type === 'contact'){
          errors = (this.usersAdminPersonForm.clients.error = !this.person.clients.length) || errors;
      }

      return !errors;
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
