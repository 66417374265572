<div class="generate-document">
    <div class="common__block-icon-close-modal-custom">
        <button class="lf-icon-close-round common__icon-close-modal-custom"
            (click)="handleDismiss()"></button>
    </div>
    <div class="common__modal-header-block common__modal-header-block--custom-margin"
        [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}">
        <i class="lf-icon-textbooks common__icon-header common__icon-header--custom" ></i>
        <span class="common__text-header common__text-header--custom"
            [afTranslate]="'generate-document.export-data'"> Exportar datos a plantilla</span>
    </div>

    <form class="generate-document__custom-template-select">
        <p class="generate-document__custom-text">
            <span>{{generateDocument.textInfoTemplate}}</span>
        </p>
        <div>
            <field-type-text-simple [field]="generateDocument.documentName"
                [preSelectItem]="predefinedTemplate.predefinedName"
                (itemSelected)="itemSelected($event, 'name')">
            </field-type-text-simple>
        </div>
        <span class="rename-modal__error-name"
            [ngShow]="generateDocument.errorDocument">{{generateDocument.errorText}}</span>
    </form>

    <div class="common__buttons-block common__buttons-block--modal">
        <button type="reset"
            class="common__button-landing-custom common__button-landing-custom--cancel"
            [ngStyle]="{'border-color': custom.color, 'color': custom.color}"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">Cancelar</button>
        <button type="reset"
            class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
            [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
            (click)="handleAccept()"
            [afTranslate]="'common.export'">Exportar</button>
    </div>
</div>
