
import { Component, Input, OnInit, OnChanges, Output, SimpleChanges, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FieldTypeContactsParentService } from './../utils/field-type-contacts-parent.service';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

import { cloneDeep } from 'lodash';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

@Component({
  selector: 'field-type-contacts-multiple',
  templateUrl: './field-type-contacts-multiple.component.html'
})
export class FieldTypeContactsMultipleComponent implements OnInit, OnChanges, OnDestroy {
  isLanding = false;
  id: any = this.commonUtilsService.createId();
  fieldTypeContacts: any = this.fieldTypeContactsParent.configureField(this);

  constructor(private route: ActivatedRoute,
    private userPermisions: UserPermisionsFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private fieldTypeContactsParent: FieldTypeContactsParentService) {}

  @Input() field : any ={};
  @Input() concept : any ={};
  @Input() valueId : any = null;
  @Input() listDisabled : any ={};
  @Input() selectContextUser: boolean = false;
  @Output() openRelatedInfo = new EventEmitter();
  @Output() contactsClosed = new EventEmitter();
  @Output() sendContacts = new EventEmitter();
  @Output() showFieldReference = new EventEmitter();
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown

  ngOnInit(): void {
    this.fieldTypeContactsParent.configureInit(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fieldTypeContactsParent.configureChanges(changes, this);
  }

  openContactList(){
    this.fieldTypeContactsParent.openContactList(this);
  }

  showReference(e: any){
    this.fieldTypeContactsParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e: any){
    this.fieldTypeContactsParent.openModalRelatedInfo(e, fieldRelatedInfo, this);
  }

  resetSearch(e: any){
    this.fieldTypeContactsParent.resetSearch(e, this);
  }

  toggleAll(e:any){
    this.fieldTypeContactsParent.toggleAll(e, this);
  }

  selectSign(type: any, option: any = ''){
    this.field.selectContacts.forEach((listAssists: any)=>{
        if(listAssists.assists === null || listAssists.assists === undefined){
            listAssists.color = 'grey';
        }else if(listAssists.assists === true || listAssists.assists === 1){
            listAssists.color = 'green';
        }else if(listAssists.assists === false || listAssists.assists === 0){
            listAssists.color = 'red';
        }
    });

    if(option !== 'dropdown'){
        this.fieldTypeContacts.showSigned = !this.fieldTypeContacts.showSigned;
    }
    this.fieldTypeContacts.participantList = [];
    switch(type){
        case 'all':
            this.fieldTypeContacts.selectedSign = {
                title: this.fieldTypeContacts.stateContacts[0].name,//'Todas'
            }
            this.fieldTypeContacts.participantList = this.field.selectContacts;
            break;
        case 'confirm':
            this.fieldTypeContacts.selectedSign = {
                title: this.translate.instant('contacts.received'),
                color: 'green'
            }
            if(this.field.typeConfiguration === 'meeting'){
                this.fieldTypeContacts.selectedSign.title = this.fieldTypeContacts.stateContacts[1].name//Confirmadas'
            }
            this.fieldTypeContacts.participantList = [];
            for(let i = 0; i < this.field.selectContacts.length; i++){
                if(this.field.selectContacts[i].assists === 1 || this.field.selectContacts[i].assists === true){
                    this.fieldTypeContacts.participantList.push(this.field.selectContacts[i]);
                }
            }
            break;
        case 'cancel':
            this.fieldTypeContacts.selectedSign = {
                title: this.translate.instant('contacts.state-pending'),
                color: 'red'
            }
            if(this.field.typeConfiguration === 'meeting'){
                this.fieldTypeContacts.selectedSign.title = this.fieldTypeContacts.stateContacts[2].name//'Rechazadas'
            }
            this.fieldTypeContacts.participantList = [];
            for(let i = 0; i < this.field.selectContacts.length; i++){
                if(this.field.selectContacts[i].assists === 0 || this.field.selectContacts[i].assists === false){
                    this.fieldTypeContacts.participantList.push(this.field.selectContacts[i]);
                }
            }
            break;
        case 'pending':
            this.fieldTypeContacts.selectedSign = {
                title: this.fieldTypeContacts.stateContacts[3].name,//'Pendientes',
                color: 'grey'
            }
            this.fieldTypeContacts.participantList = [];
            for(let i = 0; i < this.field.selectContacts.length; i++){
                if(this.field.selectContacts[i].assists === null || this.field.selectContacts[i].assists === undefined){
                    this.fieldTypeContacts.participantList.push(this.field.selectContacts[i]);
                }
            }
            break;
        default:
            this.fieldTypeContacts.participantList = this.field.selectContacts;
            break;
    }
    this.fieldTypeContacts.selectAssists = this.fieldTypeContacts.participantList.length;
    this.dropdown.close();
  }

  selectedContacts(){
    this.fieldTypeContacts.openUserList = !this.fieldTypeContacts.openUserList;
    this.fieldTypeContacts.selectContactsCopy = cloneDeep(this.fieldTypeContacts.contactListSelected);
    this.fieldTypeContacts.markAllCopy = this.fieldTypeContacts.markAll;
    this.contactsClosed.emit({type:'selectedContacts'});
    if(this.field.selectContacts && (this.field.typeConfiguration === 'sign' || this.field.typeConfiguration === 'meeting')){
        this.fieldTypeContactsParent.configureAssists(this);
    }
  }

  changeState(e: any, state: any){
    this.fieldTypeContactsParent.changeState(e, state, this);
  }

  changeStateCustom(e: any){
    let state = this.fieldTypeContacts.state === 'selection'?'list':'selection';
    this.fieldTypeContactsParent.changeState(e, state, this);
  }

  changeView(){
    this.fieldTypeContactsParent.changeView(this);
  }

  addContact(type: any, e: any) {
    this.fieldTypeContactsParent.addContact(e.participant, this);
    this.fieldTypeContactsParent.addOrDeleteMultipleItem(e.participant, type, this);
  }

  deleteContact(e: any, index: any){
    this.fieldTypeContactsParent.deleteContact(e, index, this);
  }

  resetContacts(){
    this.fieldTypeContactsParent.resetContacts(this);
  }

  showOnly(type: any, e: any){
    this.fieldTypeContactsParent.showOnly(this, type, e);
  }

  whoIs(item: any, e: any){
    let type ='group';
    if(item.userId){
        type ='user';
    }else if(item.contactId){
        type ='contact';
    }else if(item.productRoleId){
        type = 'productRole';
    }
    this.fieldTypeContactsParent.addOrDeleteMultipleItem(item, type, this, e);
  }

  addOrDeleteMultipleItem(item: any, type: any, e: any){
    this.fieldTypeContactsParent.addOrDeleteMultipleItem(item, type, this, e);
  }

  showTooltip(elementId: any, participant: any){
    return this.fieldTypeContactsParent.showTooltip(this, elementId, participant);
  }

  ngOnDestroy(){
    this.fieldTypeContactsParent.ngOnDestroy();
  }
}
