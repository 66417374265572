<div class="preview-customization">
  <div class="preview-customization__header">
      <img class="preview-customization__logo-img"
          alt="Logo"
          [src]="company.logoHeader" />
  </div>
  <div class="preview-customization__title-concept"
      [ngStyle]="{'background-color': company.color, 'color': company.textColor}">
      <span class="preview-customization__text-title-concept"
          [afTranslate]="'preview-customization.title-concept'">_Título del concepto</span>
  </div>
  <div class="preview-customization__body">
      <div class="preview-customization__breadcrumb-concept">
          <div>
              <span class="preview-customization__text-breadcrumb"
                  [afTranslate]="'preview-customization.breadcrumb-folder'">_Miga de pan carpeta</span>
              <i class="lf-icon-important preview-customization__icon-breadcrumb"></i>
              <span class="preview-customization__text-breadcrumb"
                  [afTranslate]="'preview-customization.breadcrumb-subfolder'">_Miga de pan subcarpeta</span>
              <i class="lf-icon-important preview-customization__icon-breadcrumb"></i>
              <span class="preview-customization__text-breadcrumb preview-customization__text-breadcrumb--last"
                  [afTranslate]="'preview-customization.title-concept'">_Título del concepto</span>
          </div>
      </div>
      <div class="preview-customization__state-concept">
          <div class="preview-customization__block-states-concept">
              <div class="preview-customization__block-state">
                  <div class="preview-customization__name-state">
                      <i class="lf-icon-arrow-left preview-customization__icon-name-state"></i>
                      <span class="preview-customization__name-state-text span__auto-ajust"
                        [afTranslate]="'preview-customization.state-prev'">_Elaboración del compromiso</span>
                  </div>
                  <div class="preview-customization__block-state-bar preview-customization__block-state-bar--left"></div>
                  <div class="preview-customization__text-state">
                      <span [afTranslate]="'task-concept-list.process-task.prev-state'">_Estado anterior</span>
                  </div>
              </div>
              <div class="preview-customization__block-state preview-customization__block-state--active">
                  <div class="preview-customization__name-state preview-customization__name-state--active">
                      <i class="lf-icon-radio-button-active preview-customization__icon-name-state preview-customization__icon-name-state--active"></i>
                      <span class="preview-customization__name-state-text preview-customization__name-state-text--active"
                        [afTranslate]="'preview-customization.state-actual'">_Revisión del compromiso</span>
                  </div>
                  <div class="preview-customization__block-state-bar"></div>
                  <div class="preview-customization__text-state">
                      <span [afTranslate]="'task-concept-list.process-task.current-state'">_Estado actual</span>
                  </div>
              </div>
              <div class="preview-customization__block-state">
                  <div class="preview-customization__name-state">
                      <span class="preview-customization__name-state-text"
                        [afTranslate]="'preview-customization.state-next'">_Firma y archivo del compromiso</span>
                      <i class="lf-icon-arrow-right preview-customization__icon-name-state span__auto-ajust"></i>
                  </div>
                  <div class="preview-customization__block-state-bar preview-customization__block-state-bar--right"></div>
                  <div class="preview-customization__text-state">
                      <span [afTranslate]="'task-concept-list.process-task.next-state'">_Estado siguiente</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="preview-customization__concept">
          <div class="preview-customization__left-concept">
              <!-- Documentación -->
              <div class="preview-customization__side-box">
                  <div class="preview-customization__header-side-box">
                      <i class="lf-icon-add preview-customization__icon-type-side-box"></i>
                      <span class="preview-customization__title-side-box"
                          [afTranslate]="'common.documents'">_Documentos</span>
                      <button class="lf-icon-open-window preview-customization__icon-options-side-box"></button>
                      <button class="lf-icon-up-file preview-customization__icon-options-side-box"></button>
                  </div>
                  <div class="preview-customization__body-side-box">
                      <span class="preview-customization__text-empty"
                          [afTranslate]="'attachments.without-documents'">_No hay documentos adjuntos</span>
                  </div>
              </div>
              <!-- Plantillas -->
              <div class="preview-customization__side-box">
                  <div class="preview-customization__header-side-box">
                      <i class="lf-icon-textbooks preview-customization__icon-type-side-box"></i>
                      <span class="preview-customization__title-side-box"
                          [afTranslate]="'predefined-templates.templates'">_Plantillas</span>
                      <button class="lf-icon-open-window preview-customization__icon-options-side-box"></button>
                  </div>
                  <div class="preview-customization__body-side-box">
                      <ul class="preview-customization__list-templates">
                          <li class="preview-customization__element-list-templates">
                              <i class="lf-icon-word preview-customization__icon-template"></i>
                              <span class="preview-customization__text-list-template"
                                [afTranslate]="'preview-customization.template-1'">_Órgano de Gobierno</span>
                          </li>
                      </ul>
                  </div>
              </div>
              <!-- Comentarios -->
              <div class="preview-customization__side-box">
                  <div class="preview-customization__header-side-box">
                      <i class="lf-icon-conversation preview-customization__icon-type-side-box"></i>
                      <span class="preview-customization__title-side-box"
                          [afTranslate]="'comments.comments'">_Comentarios</span>
                      <button class="lf-icon-open-window preview-customization__icon-options-side-box"></button>
                      <button class="lf-icon-add-round preview-customization__icon-options-side-box"></button>
                  </div>
                  <div class="preview-customization__body-side-box">
                      <span class="preview-customization__text-empty"
                          [afTranslate]="'attachments.without-documents'">_No hay documentos adjuntos</span>
                  </div>
              </div>
          </div>
          <div class="preview-customization__right-concept">
              <div class="preview-customization__block-steps">
                  <div class="preview-customization__list-steps">
                      <div class="preview-customization__step">
                          <div class="preview-customization__step-circle"
                              [ngStyle]="{'background-color': company.color, 'color': company.textColor}">
                              <span>01.</span>
                          </div>
                          <div>
                              <span class="preview-customization__text-step preview-customization__text-step--selected"
                                [afTranslate]="'preview-customization.step-1'">_Datos del cliente</span>
                          </div>
                          <hr class="preview-customization__line-step"/>
                      </div>
                      <div class="preview-customization__step preview-customization__step--right">
                          <div class="preview-customization__step-circle">
                              <span>02.</span>
                          </div>
                          <div>
                              <span class="preview-customization__text-step"
                                [afTranslate]="'preview-customization.step-2'">_Identificación del Órgano de Gobierno</span>
                          </div>
                      </div>
                  </div>
                  <div class="preview-customization__custom-steps-map">
                      <div class="preview-customization__custom-actual-step-map"
                          [ngStyle]="{'background-color':company.color, 'color': company.textColor}">
                          1
                      </div>
                      <span class="preview-customization__custom-total-steps-map">/</span>
                      <span class="preview-customization__custom-total-steps-map span__auto-ajust">2</span>
                  </div>
              </div>
              <div class="preview-customization__block-group-concept">
                  <div class="preview-customization__header-group-concept">
                      <span class="preview-customization__title-header-group"
                        [afTranslate]="'preview-customization.step-1'">_Datos del cliente</span>
                  </div>
                  <div class="preview-customization__body-group-concept">
                      <div class="preview-customization__info-client">
                          <div class="preview-customization__info-client-left">
                              <span class="preview-customization__info-client-text"
                                  [afTranslate]="'preview-customization.info'">_Información</span>
                              <span class="preview-customization__info-client-text preview-customization__info-client-text--principal"
                                [afTranslate]="'preview-customization.data-title'">_Empresa prueba</span>
                          </div>
                          <div class="preview-customization__info-client-right">
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                      [afTranslate]="'preview-customization.data-cif'">_CIF</span>:
                                  <span class="preview-customization__data-client-text"
                                      [afTranslate]="'preview-customization.info-cif'">_B00000000</span>
                              </div>
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                      [afTranslate]="'preview-customization.data-direction'">_DIRECCIÓN</span>:
                                  <span class="preview-customization__data-client-text"
                                      [afTranslate]="'preview-customization.info-direction'">_Calle Real 1</span>
                              </div>
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                    [afTranslate]="'preview-customization.data-cp'">_CÓDIGO POSTAL</span>:
                                  <span class="preview-customization__data-client-text"
                                    [afTranslate]="'preview-customization.info-cp'">_28000</span>
                              </div>
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                    [afTranslate]="'preview-customization.data-village'">_MUNICIPIO</span>:
                                  <span class="preview-customization__data-client-text"
                                    [afTranslate]="'preview-customization.info-village'">_Madrid</span>
                              </div>
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                    [afTranslate]="'preview-customization.data-city'">_PROVINCIA</span>:
                                  <span class="preview-customization__data-client-text"
                                    [afTranslate]="'preview-customization.info-city'">_Madrid</span>
                              </div>
                              <div class="preview-customization__data-client">
                                  <span class="preview-customization__data-client-text preview-customization__data-client-text--principal"
                                    [afTranslate]="'preview-customization.data-country'">_PAÍS</span>:
                                  <span class="preview-customization__data-client-text"
                                    [afTranslate]="'preview-customization.info-country'">_España</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="common__buttons-block float-end preview-customization__custom-buttons-block">
                  <button type="reset"
                          class="common__button-landing-custom common__button-landing-custom--cancel"
                          [ngStyle]="{'border-color': company.color, 'color': company.color}"
                          [afTranslate]="'common.clean'">_Limpiar</button>
                  <button type="submit"
                          class="common__button-landing-custom common__button-landing-custom--send"
                          [ngStyle]="{'background-color': company.color, 'color': company.textColor}"
                          [afTranslate]="'common.save'">_Guardar</button>
              </div>
              <div class="common__buttons-block pull-left preview-customization__custom-steps-buttons-block">
                  <button class="concept-form__custom-step-button">
                      <span [afTranslate]="'common.next'"
                          class="concept-form__custom-step-button-text"></span>
                      <i class="lf-icon-arrow-round-right concept-form__custom-step-button-icon span__auto-ajust"
                          [ngStyle]="{'color':company.color, 'background-color': company.textColor}"></i>
                  </button>
              </div>
          </div>
      </div>
  </div>
  <customize-footer [configureOptions]="company"></customize-footer>
</div>
