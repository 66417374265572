import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
})
export class TimePickerComponent implements OnChanges {
  constructor(private route: ActivatedRoute) { }

  @Input() hours: any = null;
  @Input() minutes: any = null;
  @Input() open: any = null;
  @Input() field: any = null;

  @Output() changeTime = new EventEmitter();

  isLanding: any = this.route.snapshot.data['isLanding'];
  timePicker: any = {
    type: 'date',
    showButton: false,
    references: false,
    hoursPicker:'00',
    minutesPicker: '00',
  }

  ngOnChanges(): void {
    let that = this.timePicker;
    if (parseInt(that.hoursPicker) !== parseInt(this.hours)) {
      that.hoursPicker = this.toTime(this.hours);
    }
    if (parseInt(that.minutesPicker) !== parseInt(this.minutes)) {
      that.minutesPicker = this.toTime(this.minutes);
    }
  }

  toTime(item:any){
    return parseInt(item)<10 && item.length !== 2? '0'+item : item;
  }

  changeInField(type: any, e:any = null) {
    this.validateTime(type);
    let validTime = this.timePicker.hoursPicker?.length === 2 && this.timePicker.minutesPicker?.length === 2;
    if(validTime && e && !(e.keyCode === 46 || e.keyCode === 8)){
        this.timePicker.hasChanges = true;
        this.changeTime.emit({hours: this.timePicker.hoursPicker, minutes:this.timePicker.minutesPicker})
    }
  }

  validateTime(type: any = 'hours'){
      if(type === 'hours' && parseInt(this.timePicker.hoursPicker) > 23){
        this.timePicker.hoursPicker = '23';
      }else if(type === 'minutes' && parseInt(this.timePicker.minutesPicker) > 59){
        this.timePicker.minutesPicker = '59';
      }
  }

  // noPropagation(e:any){
  //     e.stopPropagation();
  //     e.preventDefault();
  // }

  increment(type:any, e:any){
      if(type === 'hours'){
          if(parseInt(this.timePicker[type+'Picker']) === 23){
              this.timePicker[type+'Picker'] = 0;
          }else if(!this.timePicker[type+'Picker'] || this.timePicker[type+'Picker'] ===''){
              this.timePicker[type+'Picker'] = 0;
          }else {
              this.timePicker[type+'Picker']++;
          }
      }else{
          if(parseInt(this.timePicker[type+'Picker']) === 59){
              this.timePicker[type+'Picker'] = 0;
          }else if(!this.timePicker[type+'Picker'] || this.timePicker[type+'Picker'] ===''){
              this.timePicker[type+'Picker'] = 0;
          }else{
              this.timePicker[type+'Picker']++;
          }
      }

      this.timePicker[type+'Picker'] = this.toTime(this.timePicker[type+'Picker'])
      this.timePicker.hasChanges = true;
      this.changeTime.emit({hours: this.timePicker.hoursPicker, minutes: this.timePicker.minutesPicker});
  }

  decrement(type:any, e:any){
      if(type === 'hours'){
          if(parseInt(this.timePicker[type+'Picker']) === 0){
              this.timePicker[type+'Picker'] = '23'
          }else if(!this.timePicker[type+'Picker'] || this.timePicker[type+'Picker'] ===''){
              this.timePicker[type+'Picker'] = 0
          }else{
              this.timePicker[type+'Picker']--;
          }
      }else{
          if(parseInt(this.timePicker[type+'Picker']) === 0){
              this.timePicker[type+'Picker'] = '59';
          }else if(!this.timePicker[type+'Picker'] || this.timePicker[type+'Picker'] ===''){
              this.timePicker[type+'Picker'] = 0;
          }else{
              this.timePicker[type+'Picker']--;
          }
      }
      this.timePicker[type+'Picker'] = this.toTime(this.timePicker[type+'Picker'])
      this.timePicker.hasChanges = true;
      this.changeTime.emit({hours: this.timePicker.hoursPicker, minutes: this.timePicker.minutesPicker});
  }
}
