import { Component, Input } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'report-export-modal',
  templateUrl: './report-export-modal.component.html'
})
export class ReportExportModalComponent {
  constructor( private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal
  ) { }

  @Input() typeReport:any;

  skin:string = this.globalCfg.skin;
  reportExportModal:any = {
    nameDocument: {
      label: this.translate.instant('report-export.name-document'),
      fieldId: 'name-document',
      required:  true
    }
  };

  exportDocument(){
    let that = this.reportExportModal;
    if(!that.name){
        that.nameDocument.error = true;
        return false;
    }else{
        return true;
    }
  }

  itemSelected(newValue:any){
    this.reportExportModal.name = newValue;
  }

  handleAccept() {
    let that = this.reportExportModal;
    let document:any = {
      name: that.name,
      download: that.download,
      openFile: that.openFile
    };

    if(this.typeReport === 1){
      document.includeList = that.includeList;
      document.graphicData = that.graphicData;
    }

    if(this.exportDocument()){
      this.activeModal.close({
        result: 'ok',
        document: document
      });
    }
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
