<div class="common__task-header report-table__reports-header report-table__reports-header--dynamic">
  <div class="report-table__residual-option">
    <!-- <switch-option *ngIf="reportTable.optionSelect ==='graph' && reportTable.showResidualOption"
        [model]="reportTable.residualOption"
        [fieldText]="reportTable.residualOption?'report-table.hide-residual':'report-table.show-residual'"
        (changeOptionEmit)="reportTable.residualOption = $event.model">
    </switch-option> -->
  </div>
  <div>
    <button class="common__button-border-none"
            [ngClass]="{'report-table__disabled-button-graph': archived}"
            [ngShow]="(skin !=='gm-law' && configurateReport.excelCharts > 0) || (report.reportId === 197 && configurateReport.excelCharts > 0)"
            (click)="changeView()"
            [disabled]="archived">
        <i class="common__icon-button-underlined"
          [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
        <span class="common__text-button-underlined">{{reportTable.optionText}}</span>
    </button>
    <button class="common__button-generic"
            [ngShow]="skin==='gm-law' && configurateReport.excelCharts > 0"
            (click)="changeView()">
        <span class="common__text-button-underlined">{{reportTable.optionText}}</span>
        <i class="common__icon-button"
          [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
    </button>
    <button class="lf-icon-all common__button-checked"
            [ngClass]="{'report-table__disabled-button-graph': archived}"
            (click)="saveAsPng(tooltip)"
            #tooltip="matTooltip"
            [disabled]="reportTable.disabledSaveAsPng"
            [ngShow]="skin!=='gm-law' && configurateReport.excelCharts > 0 && reportTable.optionSelect === 'graph'"
            [matTooltip]="'results-report.capture-image' | afTranslate"
            matTooltipPosition="above"
            matTooltipClass="above">
    </button>
    <button class="lf-icon-refresh common__button-checked"
            [ngClass]="{'report-table__disabled-button': archived}"
            [ngShow]="hasPermision && skin!=='gm-law'"
            (click)="refreshTable()"
            matTooltip="{{'report-table.refresh' | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [disabled]="archived">
    </button>
    <button class=" common__button-generic"
            [ngShow]="hasPermision && skin==='gm-law'"
            (click)="refreshTable()">
        <span class="common__text-button-underlined--mrspan"
          [afTranslate]="'report-table.refresh'"></span>
            <i class="lf-icon-refresh common__icon-button"
                [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
    </button>
    <button class="lf-icon-send common__button-checked"
            [ngShow]="skin!=='gm-law' && hasPermision"
            (click)="exportReport()"
            matTooltip="{{'report-table.export'| afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
    </button>
    <button class="common__button-generic"
            [ngShow]="hasPermision && skin==='gm-law'"
            (click)="exportReport()">
        <span class="common__text-button-underlined--mrspan "
          [afTranslate]="'report-table.export'"></span>
        <i class="lf-icon-send common__icon-button"></i>
    </button>
  </div>

</div>
<div class="report-table__table report-table__table--reports"
    horizontal-scroll
    move="moveScroll(scrollX)"
    [ngClass]="{'report-table__table--responsive':configurateReport.type !== 4}"
    [ngHide]="reportTable.optionSelect === 'graph'">
    <!-- <div class="report-table__filter-block"
        [ngShow]="reportTable.listFilter.length">
        <div class="report-table__filter-title-block">
            <i class="lf-icon-settings report-table__filter-title-icon"></i>
            <p class="report-table__filter-title-text"
                [afTranslate]="'common.filters-apply'"></p>
        </div>
        <ul class="report-table__filter-list-items">
            <li class="report-table__filter-item"
                *ngFor="let filter of reportTable.listFilter">
                {{filter.column.name}}: {{filter.text}}
                <i class="lf-icon-close-round-full report-table__filter-item-icon"
                    (click)="deleteFilter(filter)"></i>
            </li>
        </ul>
        <button class="common__button common__button--grey-white"
            (click)="clearAllFilters($event)"
            [afTranslate]="'common.clean'">
            Limpiar
        </button>
    </div> -->
    <!-- Tabla -->
    <div class="report-table__table-container"
        [ngStyle]="{'width': reportTable.newTableWidth}">
        <!-- Cabecera -->
        <div class="report-table__table-header" >
            <div *ngFor="let column of reportTable.report.columns; let first = first">
                <div *ngIf="!(first || column.fieldTypeId === 'options') || (!first && column.fieldTypeId ==='options')"
                    class="report-table__table-col"
                    [ngClass]="{'report-table__action-fixed report-table__action-fixed--header':!first && column.fieldTypeId ==='options'}"
                    [ngStyle]="!first && column.fieldTypeId ==='options' ? {} : {'width':column.displayWidth, 'margin':column.margin}">
                    <div class="report-table__title-column"
                        [ngSwitch]="column.columnType">
                            <filter-by-search *ngSwitchCase="'1'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                (launchActionSearch)="orderBySearch($event, column)">
                            </filter-by-search>
                            <div class="concept-list__table-col concept-list__table-col--modification-query concept-list__table-col--top-2"
                                *ngSwitchCase="'2'"
                                ngbDropdown
                                [id]="column.id"
                                [autoClose]="'outside'">
                                <p class="common__table-header-text common__cursor-pointer report-record-detail__date-text"
                                    matTooltip="{{column.textColumn}}"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    ngbDropdownToggle>{{column.textColumn}}</p>
                                <i class="common__filter-icon lf-icon-filter-1"
                                    [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': column[column.id+'DateFilter']}"
                                    ngbDropdownToggle></i>
                                <div class="concept-list__calendar-dropdown common__block-option-list"
                                    ngbDropdownMenu role="menu">
                                    <from-to-calendar [fromSave]="column[column.id+'DateFromDate']"
                                        [toSave]="column[column.id+'DateToDate']"
                                        (changeCalendar)="changeCalendar($event, column)"
                                        [type]="'report-filter-as-icam'">
                                    </from-to-calendar>
                                </div>
                            </div>
                            <filter-by-list *ngSwitchCase="'3'"
                                [type]="'list'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                [list]="reportTable[column.id+'List']"
                                (filterElementEmit)="selectTypeOperation($event, column)">
                            </filter-by-list>
                            <filter-by-list *ngSwitchCase="'4'"
                                [type]="'listFind'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                [list]="reportTable[column.id+'List']"
                                (filterElementEmit)="selectTypeOperation($event, column)">
                            </filter-by-list>
                            <div *ngSwitchDefault style="display: flex;">
                                <span-tooltip class="common__related-field-item common__table-header-text"
                                [ngClass]="{
                                    'common__table-header-text--right': column.fieldTypeId === 'options',
                                    'common__table-header-text--has-icon': column.canOrder || column.canFilter
                                }"
                                [text]="column.textColumn"></span-tooltip>
                                <i class="common__filter-icon lf-icon-arrow-down"
                                    [ngShow]="column.canOrder"
                                    [ngClass]="{'common__filter-icon--active': reportTable.orderByColumn.name === column.textColumn,
                                        'lf-icon-arrow-up': reportTable.orderByColumn.name === column.textColumn && !reportTable.orderAsc}"
                                    (click)="orderTable(column)">
                                </i>
                            </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="report-table__table-body">
            <div [id]="'row'+index"
                *ngFor="let row of reportTable.reportsListFilter | afOrderBy:reportTable.orderByColumn.id:reportTable.orderAsc; let index = index">
                <div class="report-table__rows-container"
                    [ngClass]="{'report-table__rows-container--disabled': archived}">
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield1.key !== 'OFAFMVJ-42' && row.OFAFMVJfield1.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield1.displayWidth, 'margin': row.OFAFMVJfield1.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield1"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield2.key !== 'OFAFMVJ-42' && row.OFAFMVJfield2.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield2.displayWidth, 'margin': row.OFAFMVJfield2.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield2"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield3.key !== 'OFAFMVJ-42' && row.OFAFMVJfield3.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield3.displayWidth, 'margin': row.OFAFMVJfield3.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield3"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield4.key !== 'OFAFMVJ-42' && row.OFAFMVJfield4.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield4.displayWidth, 'margin': row.OFAFMVJfield4.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield4"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield5.key !== 'OFAFMVJ-42' && row.OFAFMVJfield5.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield5.displayWidth, 'margin': row.OFAFMVJfield5.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield5"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield6.key !== 'OFAFMVJ-42' && row.OFAFMVJfield6.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield6.displayWidth, 'margin': row.OFAFMVJfield6.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield6"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield7.key !== 'OFAFMVJ-42' && row.OFAFMVJfield7.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield7.displayWidth, 'margin': row.OFAFMVJfield7.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield7"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield8.key !== 'OFAFMVJ-42' && row.OFAFMVJfield8.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield8.displayWidth, 'margin': row.OFAFMVJfield8.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield8"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield9.key !== 'OFAFMVJ-42' && row.OFAFMVJfield9.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield9.displayWidth, 'margin': row.OFAFMVJfield9.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield9"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield10.key !== 'OFAFMVJ-42' && row.OFAFMVJfield10.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield10.displayWidth, 'margin': row.OFAFMVJfield10.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield10"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield11.key !== 'OFAFMVJ-42' && row.OFAFMVJfield11.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield11.displayWidth, 'margin': row.OFAFMVJfield11.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield11"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield12.key !== 'OFAFMVJ-42' && row.OFAFMVJfield12.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield12.displayWidth, 'margin': row.OFAFMVJfield12.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield12"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield13.key !== 'OFAFMVJ-42' && row.OFAFMVJfield13.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield13.displayWidth, 'margin': row.OFAFMVJfield13.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield13"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield14.key !== 'OFAFMVJ-42' && row.OFAFMVJfield14.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield14.displayWidth, 'margin': row.OFAFMVJfield14.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield14"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield15.key !== 'OFAFMVJ-42' && row.OFAFMVJfield15.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield15.displayWidth, 'margin': row.OFAFMVJfield15.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield15"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield16.key !== 'OFAFMVJ-42' && row.OFAFMVJfield16.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield16.displayWidth, 'margin': row.OFAFMVJfield16.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield16"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield17.key !== 'OFAFMVJ-42' && row.OFAFMVJfield17.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield17.displayWidth, 'margin': row.OFAFMVJfield17.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield17"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield18.key !== 'OFAFMVJ-42' && row.OFAFMVJfield18.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield18.displayWidth, 'margin': row.OFAFMVJfield18.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield18"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield19.key !== 'OFAFMVJ-42' && row.OFAFMVJfield19.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield19.displayWidth, 'margin': row.OFAFMVJfield19.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield19"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield20.key !== 'OFAFMVJ-42' && row.OFAFMVJfield20.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield20.displayWidth, 'margin': row.OFAFMVJfield20.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield20"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield21.key !== 'OFAFMVJ-42' && row.OFAFMVJfield21.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield21.displayWidth, 'margin': row.OFAFMVJfield21.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield21"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield22.key !== 'OFAFMVJ-42' && row.OFAFMVJfield22.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield22.displayWidth, 'margin': row.OFAFMVJfield22.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield22"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield23.key !== 'OFAFMVJ-42' && row.OFAFMVJfield23.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield23.displayWidth, 'margin': row.OFAFMVJfield23.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield23"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield24.key !== 'OFAFMVJ-42' && row.OFAFMVJfield24.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield24.displayWidth, 'margin': row.OFAFMVJfield24.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield24"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield25.key !== 'OFAFMVJ-42' && row.OFAFMVJfield25.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield25.displayWidth, 'margin': row.OFAFMVJfield25.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield25"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <report-table-column class="report-table__table-col"
                      *ngIf="row.OFAFMVJfield26.key !== 'OFAFMVJ-42' && row.OFAFMVJfield26.value !== 'OFAFMVJ-42'"
                      [ngStyle]="{'width': row.OFAFMVJfield26.displayWidth, 'margin': row.OFAFMVJfield26.margin}"
                      [row]="row"
                      [rowType]="row.OFAFMVJfield26"
                      [column]="reportTable.report.columns"
                      (launchActionColumn)="relatedConcept(row, 'task')"></report-table-column>
                    <div class="report-table__action-fixed"
                        [ngStyle]="{ 'height':initHeightColumn('row' + index) + 'px'}">
                        <div class="report-table__text-options report-table__menu-action">
                            <div class="report-table__options-block">
                                <ul class="report-table__options report-table__options--reports">
                                    <li class="report-table__menu-icons report-table__menu-icons--edit"
                                        (click)="relatedConcept(row, 'concept')">
                                        <i class="lf-icon-open-window"
                                            matTooltip="{{reportTable.openTooltip}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above">
                                        </i>
                                    </li>
                                </ul>
                            </div>
                            <span class="lf-icon-kebab-menu report-table__ellipsis-icon"
                                [ngClass]="{'report-table__ellipsis-icon--disabled':archived}"></span>
                        </div>
                    </div>
                  </div>
            </div>

            <div [ngShow]="reportTable.openTasks"
                class="report-table__tasks">
                <div class="report-table__title-task">{{reportTable.titleTask}}
                    <i class="lf-icon-close report-table__close-icon"
                        (click)="reportTable.openTasks = !reportTable.openTasks"></i>
                </div>
                <div class="report-table__subtitle-task"
                    [afTranslate]="'report-table.relationed-tasks'">TAREAS RELACIONADAS CON LA IMPLEMENTACIÓN DE ESTA MEDIDA</div>
                <!-- <task-report></task-report> -->
            </div>
        </div>
    </div>
</div>
<report-graph *ngIf="reportTable.optionSelect === 'graph'"
    [report]="reportTable.report"
    [showResidualOption]="reportTable.showResidualOption"
    [configurateReport]="configurateReport">
</report-graph>
