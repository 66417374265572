import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'field-type-time',
  templateUrl: './field-type-time.component.html'
})
export class FieldTypeTimeComponent implements OnInit, OnChanges {
  constructor(
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory,
    private route: ActivatedRoute
  ) { }

  @Input() field: any;
  @Input() preSelectItem: any;

  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  isLanding = this.route.snapshot.data['isLanding'];
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;
  custom: any = this.customLanding.getCustom();
  fieldTypeTime: any = {
    id: this.commonUtilsService.createId(),
    openLaw: false,
    showBlockHour: false,
    inputHour: '00',
    inputMinute: '00'
  }

  ngOnChanges() {
    let that = this.fieldTypeTime;
    let field = this.field;

    if (field) {
      that.titleField = field.label;
      if (field.extendedLabel && field.extendedLabel !== '') {
        that.titleField = field.extendedLabel;
      }
      if (!field.id || field.generateId) {
        field.id = this.commonUtilsService.createId();
      } else if (field.fieldId) {
        field.id = field.fieldId;
      }
      if(field.inReport !== null && field.inReport !== undefined){
        this.fieldTypeTime.inReport = this.field.inReport.indexOf('|') === -1? [this.field.inReport] : this.field.inReport.split('|')
      }
    }

    this.configureTime(this.preSelectItem);
  }

  ngOnInit(): void {
    this.clickMenu();

    setTimeout(() => {
      let that = this.fieldTypeTime;
      that.selectedItem = cloneDeep(this.preSelectItem);
      this.createTime(that.inputHour, that.inputMinute);
      this.itemSelected.emit({ newValue: that.time });
    });
  }

  configureTime(item: any) {
    let that = this.fieldTypeTime;
    if (item !== undefined && item !== null) {
      let time = item.split(':');
      that.hour = time[0].substr(0, 2);
      that.minute = time[1].substr(0, 2);
      that.inputHour = that.hour;
      that.inputMinute = that.minute;
    }
  }

  clickMenu() {
    setTimeout(() => {
      $(document).off('click', (e:any)=>this.clickOffHour(e, this.fieldTypeTime));
      $(document).on('click', (e:any)=>this.clickOffHour(e, this.fieldTypeTime));
    });
  }

  clickOffHour(event: any, that: any) {
    let target = event.target;
    if ((typeof target.className === 'string'
      && (target.className !== 'field-type-time__block-time-hover--menu'
      && target.className !== 'field-type-time__separated-time'
      && target.className.indexOf('field-type-time__input-content field-type-time__time-hover-position') === -1
      && target.className.indexOf('lf-icon-angle-up field-type-time__time-hover-position field-type-time__icon-arrow') === -1
      && target.className.indexOf('lf-icon-angle-down field-type-time__time-hover-position field-type-time__icon-arrow') === -1
      && target.className.indexOf('common__input-content field-type-time__input-content-time-hour') === -1
      && target.className.indexOf('common__input-content field-type-time__input-content-time-minute') === -1))
      || target.className === undefined) {
      that.showBlockHour = false;
    } else if (target.id === this.field.id) {
      that.showBlockHour = true;
    }
  }

  createTime(inputHour:any, inputMinute:any) {
    return this.fieldTypeTime.time = (inputHour + ':' + inputMinute + ':' + '00');
  }

  wheelMouse(e: any, type:any){
      if(type === 'hour'){
          if(e.action === 'up'){
            this.upHour(e.event);
          }else{
            this.downHour(e.event);
          }
      }else{
          if(e.action === 'up'){
            this.upMinute(e.event);
          }else{
            this.downMinute(e.event);
          }
      }

  }

  changeInField(){
      this.validateTime();
      this.itemSelected.emit({newValue:this.createTime(this.fieldTypeTime.inputHour, this.fieldTypeTime.inputMinute)})
  }

  validateTime(){
      if(this.fieldTypeTime.inputHour === undefined){
          this.fieldTypeTime.inputHour ='00';
      }
      if(this.fieldTypeTime.inputMinute === undefined){
          this.fieldTypeTime.inputMinute = '00';
      }
      if(this.fieldTypeTime.inputHour === '' || this.fieldTypeTime.inputHour > 23){
          this.fieldTypeTime.inputHour = '23';
      }
      if(this.fieldTypeTime.inputMinute === ' ' || this.fieldTypeTime.inputMinute > 59){
          this.fieldTypeTime.inputMinute = '59';
      }
      if(this.fieldTypeTime.inputHour.length === 1){
        this.fieldTypeTime.inputHour = '0'+this.fieldTypeTime.inputHour
      }
      if(this.fieldTypeTime.inputMinute.length === 1){
        this.fieldTypeTime.inputMinute = '0'+this.fieldTypeTime.inputMinute
      }
  }

  upHour(e:any = null){
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      if(this.fieldTypeTime.inputHour < 9){
          this.fieldTypeTime.inputHour = '0' + (parseFloat(this.fieldTypeTime.inputHour) + 1);
      }else if(this.fieldTypeTime.inputHour >= 9 && this.fieldTypeTime.inputHour < 23){
          this.fieldTypeTime.inputHour = parseFloat(this.fieldTypeTime.inputHour) + 1;
      }else if(this.fieldTypeTime.inputHour === 23){
          this.fieldTypeTime.inputHour = '00';
      }
      this.changeInField();
  }

  downHour(e:any = null){
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      if(this.fieldTypeTime.inputHour <= 10 && this.fieldTypeTime.inputHour !== '00'){
          this.fieldTypeTime.inputHour = '0' + (parseFloat(this.fieldTypeTime.inputHour) - 1);
      }else if(this.fieldTypeTime.inputHour > 10 && this.fieldTypeTime.inputHour <= 23){
          this.fieldTypeTime.inputHour = parseFloat(this.fieldTypeTime.inputHour) - 1;
      }else if(this.fieldTypeTime.inputHour === '00'){
          this.fieldTypeTime.inputHour = 23;
      }
      this.changeInField();
  }

  upMinute(e:any){
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      if(this.fieldTypeTime.inputMinute < 9){
          this.fieldTypeTime.inputMinute = '0' + (parseFloat(this.fieldTypeTime.inputMinute) + 1);
      }else if(this.fieldTypeTime.inputMinute >= 9 && this.fieldTypeTime.inputMinute < 59){
          this.fieldTypeTime.inputMinute = parseFloat(this.fieldTypeTime.inputMinute) + 1;
      }else if(this.fieldTypeTime.inputMinute == 59){
          this.fieldTypeTime.inputMinute = '00';
          this.upHour();
      }
      this.changeInField();
  }

  downMinute(e:any = null){
      if(e){
          e.stopPropagation();
          e.preventDefault();
      }
      if(this.fieldTypeTime.inputMinute <= 10 && this.fieldTypeTime.inputMinute !== '00'){
          this.fieldTypeTime.inputMinute = '0' + (parseFloat(this.fieldTypeTime.inputMinute) - 1);
      }else if(this.fieldTypeTime.inputMinute > 10 && this.fieldTypeTime.inputMinute <= 59){
          this.fieldTypeTime.inputMinute = parseFloat(this.fieldTypeTime.inputMinute) - 1;
      }else if(this.fieldTypeTime.inputMinute === '00'){
          this.fieldTypeTime.inputMinute = 59;
          this.downHour();
      }
      this.changeInField();
  }

  resetInput(){
      if(this.fieldTypeTime.selectedItem===null || this.fieldTypeTime.selectedItem===undefined){
          this.fieldTypeTime.inputText = '';
          this.fieldTypeTime.inputHour ='00';
          this.fieldTypeTime.inputMinute = '00';
      }else{
          this.configureTime(this.fieldTypeTime.selectedItem);
      }
      this.changeInField();
  }

  showTooltipInfo(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.fieldTypeTime.showInfo = !this.fieldTypeTime.showInfo;
  }


  showHour(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.fieldTypeTime.showBlockHour = !this.fieldTypeTime.showBlockHour;
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
      e.stopPropagation();
      e.preventDefault();
      this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  catchEvent(e:any){
      e.stopPropagation();
  }
}
