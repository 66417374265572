<div class="common__table-display window-list__cell-block">
  <div class="common__table-cell-tooltip">
      <div class="common__cell-block">
          <div ngbDropdown class="window-list__dropdown-button"
              [open]="windowList.openWindowList"
              [ngShow]="clickText > 0">
              <div ngbDropdownToggle class="common__bubble-count window-list__bubble-count">{{clickText}}</div>
              <div ngbDropdownMenu role="menu"
                class="common__select-list window-list__select-list">
                  <i class="lf-icon-close window-list__icon-close" (click)="windowList.openWindowList = !windowList.openWindowList"></i>
                  <div class="window-list__title-box">
                      <i class="{{headerIcon}} window-list__icon-header"></i>
                      <span class="window-list__title-participants">{{titleText}}</span>
                  </div>
                  <div class="window-list__select-list-item">
                      <div class="window-list__select-item"
                          *ngFor="let item of windowList.itemList">
                          <p class="common__ellipsis">{{item.name}}</p>
                          <button class="window-list__button" (click)="iconClicked(item)">
                              <i class="{{itemIcon}} window-list__icon-click"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
          <parragraph-tooltip class="window-list__parraghraph-block"
            [text]="text"></parragraph-tooltip>
      </div>
  </div>
</div>
