import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'edition-comparator-form',
  templateUrl: './edition-comparator-form.component.html'
})
export class EditionComparatorFormComponent implements OnInit, OnChanges {
  editionComparatorForm:any = {
    historyDataViewer: [],
    id: this.commonUtilsService.createId()
  }
  constructor(private commonUtilsService: CommonUtilsService) { }

  @Input() historyDataViewer:any = null;

  ngOnInit(): void {
    window.addEventListener('resize', ()=>{
      setTimeout(()=>{
        if(this.editionComparatorForm.historyDataViewer && this.editionComparatorForm.historyDataViewer.comparatorActivated){
            this.configureHeightBlock(this.editionComparatorForm.historyDataViewer.fieldContainers, false);
        }
      });
    })

  }

  ngOnChanges(){
    if(this.historyDataViewer){
      this.editionComparatorForm.historyDataViewer = this.historyDataViewer;
      if(this.historyDataViewer.comparatorActivated){
        this.configureHeightBlock(this.editionComparatorForm.historyDataViewer.fieldContainers, false);
      }
    }
  }

  configureHeightBlock(fieldContainers:any, subContainer:any){
      fieldContainers.forEach((containerSelected:any) => {
        if(containerSelected.fields && containerSelected.fields.length){
          setTimeout(()=>{
            this.configureHeightFields(containerSelected, subContainer);
          });
      }
      if(containerSelected.subContainers && containerSelected.subContainers.length){
          this.configureHeightBlock(containerSelected.subContainers, true);
      }
      });
  }

  configureHeightFields(container:any, subContainer:any){
      container.fields.forEach((fieldSelected:any) => {
        let elementId:any = subContainer? 'block-subField-' : 'block-field-';
        fieldSelected.iD_Version0 = fieldSelected.iD_Version0?fieldSelected.iD_Version0:fieldSelected.fieldId;
        let heightBlockCompare1:any = $('#'+elementId + fieldSelected.iD_Version0+'-1').outerHeight();
        let heightBlockCompare2:any = $('#'+elementId + fieldSelected.iD_Version0+'-2').outerHeight();
        if(heightBlockCompare1 > heightBlockCompare2 && heightBlockCompare2){
          let element:any = document.getElementById(elementId + fieldSelected.iD_Version0+'-2');
          element.style.height = heightBlockCompare1+'px';
        }else if(heightBlockCompare2 > heightBlockCompare1 && heightBlockCompare1){
          let element:any = document.getElementById(elementId + fieldSelected.iD_Version0+'-1');
          element.style.height = heightBlockCompare2+'px';
        }

        if(fieldSelected.fieldTypeFrontId === 'l-co' || fieldSelected.fieldTypeFrontId === 'alert' || fieldSelected.fieldTypeFrontId === 'task'){
            this.configureValuesField(fieldSelected.value, subContainer);
        }
      });
  }

  configureValuesField(container:any, subContainer:any){
    container.forEach((value:any) => {
          let elementId:any = subContainer? 'block-subField-' : 'block-field-';
          if(document.getElementById(elementId + value.id+'-2')){
            let element:any = document.getElementById(elementId + value.id+'-2');
            element.style.height = 'auto';
          }
          if(document.getElementById(elementId + value.id+'-1')){
            let element:any = document.getElementById(elementId + value.id+'-1');
            element.style.height = 'auto';
          }
          let heightBlockCompare1:any = $('#'+elementId + value.id+'-1').outerHeight();
          let heightBlockCompare2:any = $('#'+elementId + value.id+'-2').outerHeight();
          if(heightBlockCompare1 > heightBlockCompare2 && heightBlockCompare2){
            let element:any = document.getElementById(elementId + value.id+'-2');
            element.style.height = heightBlockCompare1+'px';

          }else if(heightBlockCompare2 > heightBlockCompare1 && heightBlockCompare1){
            let element:any = document.getElementById(elementId + value.id+'-1');
            element.style.height = heightBlockCompare2+'px';
          }
    })
  }
}
