<div class="task-edit">
    <div [ngClass]="{'task-edit--concept common__block-concept common__block-concept--border': currentComponent === 'concept'}">
        <!-- Cabecera nueva en tareas -->
        <div class="task-edit__block-header">
            <div class="task-edit__sublock-header-first">
                <div class="task-edit__title-task-block">
                    <span [ngShow]="taskEdit.isNew && !taskEdit.shared"
                        [afTranslate]="'task-edit.new-task'">Nueva tarea</span>
                    <span [ngHide]="taskEdit.isNew && !taskEdit.shared">
                        <i [ngShow]="!taskEdit.task.videoconference && currentComponent !== 'genericTask'"
                            [class]="'task-edit__type-icon ' + (taskEdit.task.taskTypeId | taskTypeIcons:'task':taskEdit.task.repeatPattern)"></i>
                        <i [ngShow]="taskEdit.task.videoconference && currentComponent !== 'genericTask'"
                            class="task-edit__type-icon lf-icon-videoconference"></i>
                        <span>{{taskEdit.task.typeText}}</span>
                    </span>
                </div>
                <div class="task-edit__block-date-expirated">
                    <p [ngShow]="taskEdit.taskExpirated >= 1 ">
                        <i [ngShow]="taskEdit.task.taskStateId !== 5 && taskEdit.task.taskStateId !== 4 && taskEdit.task.taskStateId !== 6"
                            class="lf-icon-step-final task-edit__icon-circle-expirated"></i>
                        <span [afTranslate]="'task-edit.task-has-expired'">La tarea ha vencido hace </span> {{taskEdit.taskExpirated}} {{taskEdit.dayText}}
                    </p>
                </div>
                <div class="task-edit__block-user">
                    <button class="lf-icon-close task-edit__button-close-edit"
                            [ngShow]="currentComponent !== 'genericTask'"
                            (click)="cancelCreateTask()"></button>
                    <div class="task-edit__data-user"
                        [ngClass]="{'task-edit__right' : currentComponent === 'genericTask'}">
                        <p class="task-edit__user-edited"
                            [ngClass]="{'common__line-through':taskEdit.taskSelect.createdByIsDeleted}">
                            <span matTooltip="{{taskEdit.userLogin +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                [matTooltipDisabled]="taskEdit.taskSelect.createdByIsDeleted">
                                {{taskEdit.userLogin}}
                            </span>
                        </p>
                        <p class="task-edit__date-creation-edited">
                            {{taskEdit.creationDate | convertDate:'without-time'}}
                        </p>
                    </div>
                </div>
            </div>
            <states-process-default
                *ngIf="taskEdit.concept.processStates && taskEdit.task.taskTypeId === 7"
                [type]="taskEdit.typeStatesProcess"
                [typeView]="'normal'"
                [concept]="taskEdit.concept"
                [item]="taskEdit.task"
                (changeState)="changeState($event)"
                (changeResponsible)="changeResponsible($event)">
            </states-process-default>
            <states-process-default
                *ngIf="!(taskEdit.task.taskTypeId === 4 || taskEdit.task.taskTypeId === 5 || taskEdit.isNew) && !(taskEdit.concept.processStates && taskEdit.task.taskTypeId === 7)"
                [type]="taskEdit.typeStatesProcess"
                [typeView]="'normal'"
                [concept]="taskEdit.concept"
                [item]="taskEdit.task"
                (changeState)="changeState($event)"
                (changeResponsible)="changeResponsible($event)">
            </states-process-default>
        </div>
        <task-form-default [concept]="taskEdit.concept"
            [task]="taskEdit.task"
            [isNew]="taskEdit.isNew"
            [shared]="taskEdit.shared"
            [field]="field"
            [hasOpenEvaluationTask]="hasOpenEvaluationTask"
            [disabledTaskEvaluation]="taskEdit.disabledTaskEvaluation"
            (cancelTask)="cancelCreateTask()"
            (createAlert)="createAlert($event.alert)"
            (launchAction)="launchAction($event.action)">
        </task-form-default>
        <div class="task-edit__task-box-right">
            <attachments-template-default [ngShow]="taskEdit.fullScreenBox==='' || taskEdit.fullScreenBox==='attachments-templates'"
                [typeFrom]="'task'"
                [itemSelected]="taskEdit.task"
                [concept]="taskEdit.concept"
                [attachmentList]="taskEdit.attachmentListDocument"
                (catchFullScreen)="hasFullScreen($event)"
                (launchAction)="launchAction($event)">
            </attachments-template-default>
            <box-comments-default [ngShow]="taskEdit.fullScreenBox==='' || taskEdit.fullScreenBox==='box-comments'"
                (catchFullScreen)="hasFullScreen($event)"
                [taskId]="taskEdit.task.taskId"
                (launchAction)="launchAction($event.action)"
                [taskState]="taskEdit.task.taskStateId">
            </box-comments-default>
            <task-historic-default [ngShow]="taskEdit.fullScreenBox==='' || taskEdit.fullScreenBox==='historic-templates'"
                [historicList]="taskEdit.historicList"
                (catchFullScreen)="hasFullScreen($event)"
                [task]="taskEdit.task">
            </task-historic-default>
        </div>
    </div>
</div>
