<div class="new-custom-report new-custom-report__custom-report-list--no-border">
  <div class="common__task-header new-custom-report__header">
      <div class="new-custom-report__new-report-header"
           *ngIf="!predefinedReport">
          <i class="lf-icon-template-user new-custom-report__predefined-report-icon-header"></i>
          <span class="new-custom-report__predefined-report-label-header new-custom-report__predefined-report-label-header--alone">{{newCustomReport.textHeaderTitle}}</span>
      </div>
      <div class="new-custom-report__predefined-report-header"
           *ngIf="predefinedReport">
          <i class="lf-icon-template new-custom-report__predefined-report-icon-header"></i>
          <div class="new-custom-report__predefined-report-header-labels">
              <span class="new-custom-report__predefined-report-label-header">{{predefinedReport.customReportName}}</span>
              <span class="new-custom-report__predefined-report-label-header-subtitle"
                    [afTranslate]="'new-custom-report.predefined-report'"></span>
          </div>
      </div>
      <div class="new-custom-report__wizzard-header"
           *ngIf="!predefinedReport">
          <wizard-steps [steps]="newCustomReport.steps"
                        [state]="newCustomReport.stepState">
          </wizard-steps>
      </div>
      <div class="new-custom-report__close-header">
          <i class="lf-icon-close common__icon-close-modal new-custom-report__close-icon-header"
             (click)="close()"></i>
      </div>
  </div>
  <div class="new-custom-report__block-body"
       [ngSwitch]="newCustomReport.stepState">
      <new-custom-report-identification *ngSwitchCase="1"
            [newCustomReport]="newCustomReport.report"
            [productList]="productList"
            [customReportNameField]="newCustomReport.customReportNameField"
            [productFieldInfo]="newCustomReport.productFieldInfo"></new-custom-report-identification>
      <new-custom-report-configuration *ngSwitchCase="2"
            [newCustomReport]="newCustomReport.report"
            [validationErrors]="newCustomReport.formErrors"></new-custom-report-configuration>
      <new-custom-report-preview *ngSwitchCase="3"
            [newCustomReport]="newCustomReport.report"
            [duplicatedPredefinedReport]="duplicatedPredefinedReport"
            [predefinedReport]="predefinedReport"
            [editedCustomReport]="editedCustomReport"></new-custom-report-preview>
  </div>
  <div class="new-custom-report__body-block">
      <div class="new-custom-report__buttons-block">
          <div class="common__buttons-block">
              <button type="reset"
                      class="common__button common__button--clean"
                      (click)="closeWindow.emit()"
                      [afTranslate]="'common.exit'"
                      *ngIf="predefinedReport">
              </button>
              <button type="reset"
                      class="common__button common__button--clean"
                      (click)="closeWindow.emit()"
                      [afTranslate]="'common.cancel'"
                      *ngIf="!predefinedReport">
              </button>
              <button type="submit"
                      class="common__button common__button--clean"
                      (click)="previousPage()"
                      [afTranslate]="'common.previous'"
                      *ngIf="newCustomReport.stepState > 1 && !predefinedReport">
              </button>
              <button class="common__button common__button--clean"
                      (click)="clearForm()"
                      [afTranslate]="'common.clean'"
                      *ngIf="newCustomReport.stepState < 3 && !predefinedReport">
              </button>
              <button type="submit"
                      class="common__button common__button--active"
                      (click)="nextPage()"
                      [afTranslate]="'common.next'"
                      *ngIf="newCustomReport.stepState < 3 && !predefinedReport">
              </button>
              <button type="submit"
                      class="common__button common__button--active"
                      (click)="editOrCreateCustomReport()"
                      [afTranslate]="'common.save'"
                      *ngIf="newCustomReport.stepState === 3 && !predefinedReport">
              </button>
          </div>
      </div>
  </div>
</div>
