import { Injectable } from '@angular/core';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Injectable({
  providedIn: 'root'
})
export class BoxCommentsParentService {

  constructor(private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private loader: LoaderFactory,
    private context: ContextFactory,
    private translateFactory: AfTranslateFactory,
) { }

  onChanges(child:any){
    let that: any = child.boxComments;

    if( child.conceptObjectId){
        that.conceptObjectId = child.conceptObjectId
    }

    if(child.taskId){
      that.taskId = child.taskId
    }

    child.boxComments.url = that.conceptObjectId && that.conceptObjectId !== 'view'?'comments/'+that.conceptObjectId: 'comments/task/'+that.taskId;

    if ((that.taskId || that.conceptObjectId) && that.conceptObjectId !== 'view'){
        this.getComments(child);
    }
    if(child.fullScreenBox){
      that.fullScreenBox = child.fullScreenBox
      if(that.isLanding && that.fullScreen && that.fullScreenBox !== '' && that.fullScreenBox !== 'box-comments'){
        this.maximizeScreen(true, child);
      }
    }
    if(child.taskState){
        that.taskState = child.taskState;
        that.taskStateNoEditable = (that.taskState === 5 || that.taskState === 6);
    }
  }

  getComments(child:any){
      this.apiService.get(child.boxComments.url)
          .then((data:any)=>{
              child.boxComments.commentList = data.reverse();
              this.showComments(child);
          }, function (){
              child.boxComments.commentList = [];
          });
  }

  showComments(child:any){
      let that: any = child.boxComments;
      if(that.commentList.length){
          that.commentListFilter = [];
          that.commentList.forEach((comment:any) => {
              comment.showOptions = comment.createdBy === this.context.user.userId;
              if(comment.createdByContact){
                  comment.showOptions = comment.createdByContact === this.context.user.contactId;
              }
              comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
          });
          that.commentListFilter = that.commentList
      }else{
          that.commentListFilter = [];
      }
  }

  newComment(child:any){
      child.boxComments.showCommentBox = true;
      if(!child.boxComments.fullScreen){
          child.boxComments.showCommentList = false;
      }
  }

  cancelComment(comment:any, child:any){
      comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
      child.boxComments.commentText = '';
      comment.edit = false;
      child.boxComments.edit = false;
      child.boxComments.showCommentBox = false;
      child.boxComments.showCommentList = true;
  }

  createNewComment(text:any, child:any){
      let textToAdd =!text? child.boxComments.commentText.replace(/(?:\r\n|\r|\n)/g, '<br>') : text;
      this.apiService.add(child.boxComments.url, {commenttext: textToAdd})
          .then((data:any)=>{
              data.author = this.context.user.fullName;
              child.boxComments.commentList.splice(0, 0, data);
              child.boxComments.showCommentBox = false;
              child.boxComments.showCommentList = true;
              child.boxComments.commentText = '';
              this.showComments(child);
          });
  }

  maximizeScreen(keepClassBox:boolean, child:any){
      let that: any = child.boxComments
      that.fullScreen = !that.fullScreen;
      this.showComments(child)
      that.showCommentBox = that.fullScreen;
      if(that.fullScreen){
          that.showCommentList=true;
      }
      if(!keepClassBox){
          child.catchFullScreen.emit({box:'box-comments'});
      }
  }

  deleteComment(comment:any, child:any){
      let type = child.boxComments.isLanding? 'info:question:question-full' : 'info:question:question';
      this.modalService.adviceModal(this.translateFactory.instant('comments.delete-comment'),  type).subscribe((result:any)=>{
          if(result.result ==='ok'){
              this.loader.openLoader('comment-template-delete-comment')
              this.apiService.delete('comments', comment.commentId)
                  .then((data:any)=>{
                    this.loader.closeLoader(['comment-template-delete-comment']);
                    child.boxComments.commentList = child.commonUtilsService.deleteElement( comment, child.boxComments.commentList , 'commentId');
                    this.modalService.adviceModal(this.translateFactory.instant('comments.delete-comment-ok'), 'info:accept:check-round')
              }, ()=>{
                  this.loader.closeError();
              })
          }else{
              this.loader.closeError();
          }
      })
  }

  editComment(comment:any, child:any){
    child.boxComments.commentList.forEach((comment:any) => {
        comment.edit = false;
    });
    child.boxComments.showCommentBox = false;
    comment.edit = !comment.edit;

    if(!child.boxComments.fullScreen){
        child.boxComments.edit = !child.boxComments.edit;
    }
    setTimeout(function(){
        let element: any = $( '#edit-comment-'+comment.commentId);
        element.select().focus()
    })

  }

  changeComment(comment:any, child:any){
    let element: any = $('#edit-comment-'+comment.commentId)[0];
    if(element.value.replace(/(?:\r\n|\r|\n)/g, '<br>') !== ''){
        let type = child.boxComments.isLanding? 'info:question:question-full' : 'info:question:question';
        this.modalService.adviceModal(this.translateFactory.instant('comments.modify-comment'), type)
            .subscribe((result:any)=>{
                if(result.result==='ok'){
                    this.loader.openLoader('comment-template-edit-comment')
                    comment.commentText = element.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    this.apiService.patch('comments', comment.commentId, comment)
                        .then((data:any)=>{
                            this.loader.closeLoader('comment-template-edit-comment');
                            child.boxComments.edit = false;
                            comment.edit = false;
                            comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
                            child.boxComments.showCommentBox = false;
                            child.boxComments.showCommentList = true;
                            child.boxComments.commentText = '';
                            this.showComments(child);
                            this.modalService.adviceModal(this.translateFactory.instant('comments.modify-comment-ok'), 'info:accept:check-round')
                        }, (errorData:any)=>{
                            comment.commentTextarea = comment.commentText.split('<br>').join('\r\n');
                            child.boxComments.edit = false;
                            comment.edit = false;
                            this.loader.closeError();
                        })
                }else{
                    this.loader.closeError();
                }
            });

    }else{
        let oldcomment = comment.commentText;
        comment.commentTextarea = oldcomment.split('<br>').join('\r\n');
        child.boxComments.edit = false;
        comment.edit = false;
    }
  }
}
