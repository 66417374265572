import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { filter } from 'lodash';

@Component({
  selector: 'no-permissions',
  templateUrl: './no-permissions.component.html'
})
export class NoPermissionsComponent implements OnInit {
  @Input() jwtToken: string = '';
  @Input() continueText: string = '';
  @Output() launchAction = new EventEmitter();
  noPermissions: any = {
    showAdmins: false
  }
  constructor(private apiService: ApiServiceService) { }

  ngOnInit(): void {
    this.getUsersAdmin();
  }

  getUsersAdmin(){
    this.apiService.get('users', 'admins', this.jwtToken)
      .then((data:any)=> {
        this.noPermissions.admins = filter(data, function (item) { return item.email; });
      }, (errorData:any)=> {
        this.launchAction.emit({type: 'onError', error:errorData});
      });
  }

  continueClick(){
    this.launchAction.emit({type:'continueClick'});
  }

}


// (function (angular, app) {
//   'use strict';

//   NoPermissionsController.$inject = ['apiService', 'globalCfg'];
//   function NoPermissionsController(apiService, globalCfg) {
//       var vm = this;

//       vm.$onInit = onInit;

//       function onInit(){
//           apiService.get('users', 'admins', vm.jwtToken).then(getAdminsOk).catch(getAdminsError);
//           function getAdminsOk(data) {
//               vm.admins = _.filter(data, function (item) { return item.email; });
//           }

//           function getAdminsError(err) {
//               if (vm.onError) {
//                   vm.onError(err);
//               }
//           }
//       }
//   }

//   app.component('noPermissions', {
//       templateUrl: './app/components/front-login/no-permissions/no-permissions.template.html',
//       controller: NoPermissionsController,
//       controllerAs: 'vm',
//       bindings: {
//           jwtToken: '<',
//           continueClick: '=',
//           continueText: '<',
//           onError: '='
//       }
//   });
// })(angular, app);
