<div class="help-modal">
  <div class="help-modal__header">
      <i (click)="handleDismiss()"
          class="help-modal__header-icon"
          [ngClass]="skin === 'steel-blue'?'lf-icon-close':'lf-icon-close-round'"></i>
      <img class="help-modal__header-image"
        [src]="helpModal.headerLogoSrc"
        alt="Logo"/>
      <p class="help-modal__header-title"
          [afTranslate]="'modal-help.service-support'">Servicio de soporte</p>
  </div>
  <div class="help-modal__container">
      <div class="help-modal__box">
          <div class="help-modal__header-block">
              <i class="lf-icon-call help-modal__header-block-icon"></i>
              <p class="help-modal__header-block-text">{{helpModal.phoneAssist}}</p>
          </div>
          <div class="help-modal__info">
              <p [innerHTML]="helpModal.phoneInfo"></p>
          </div>
          <div class="help-modal__header-block">
              <p class="help-modal__text-contact" [afSiteTerms]="'tel'"></p>
              <p class="help-modal__text-contact"
                  [ngShow]="!helpModal.specialModal"
                  [afSiteTerms]="'fax'">Fax: 91 578 16 17 / 91 210 81 01</p>
          </div>
          <div class="help-modal__position-button">
              <div class="help-modal__info-hours"
                  [ngShow]="!helpModal.specialModal">
                  <p [afSiteTerms]="'info-hours'">Lunes a Viernes de 8:30 a 20 hrs</p>
              </div>
          </div>
      </div>
      <div class="help-modal__box">
          <div class="help-modal__header-block">
              <i class="lf-icon-newsletter help-modal__header-block-icon"></i>
              <p class="help-modal__header-block-text">{{helpModal.mailAssits}}</p>
          </div>
          <div class="help-modal__info">
              <p [innerHTML]="helpModal.mailInfo"></p>
          </div>
          <div class="help-modal__text-contact">
              <p [afSiteTerms]="'mail'"></p>
          </div>
          <div class="help-modal__text-contact"
                [ngShow]="skin !== 'dark-blue'">
              <p>{{helpModal.phone2}}</p>
          </div>
          <div class="help-modal__position-button help-modal__position-button--mail">
              <button type="submit"
                      [ngClass]="{'help-modal__btn-send-mail': skin != 'steel-blue' && skin != 'uk-steel-blue',
                          'common__button common__button--active': skin === 'steel-blue' || skin === 'uk-steel-blue'}"
                      (click)="sendMail()"
                      [afTranslate]="'modal-help.send-mail'">Enviar mail</button>
          </div>
      </div>
      <div class="help-modal__box help-modal__box--specific">
          <div class="help-modal__header-block help-modal__header-block--painted">
              <i class="lf-icon-support help-modal__header-block-icon"></i>
              <p class="help-modal__header-block-text"
                  [afTranslate]="'modal-help.help-center'">Centro de Ayuda</p>
          </div>
          <div class="help-modal__block-buttons-help">
              <button type="submit"
                      class="help-modal__mini-box help-modal__mini-box--text-faqs" [ngShow]="skin !== 'gm-law'"
                      (click)="openURL(1)">
                  <i class="lf-icon-question help-modal__mini-box-icon"></i>
                  <p id="modal-help-faq"
                      matTooltip="{{'modal-help.faq' | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipDisabled]="showTooltip('faq')"
                      class="help-modal__mini-box-text">
                      <span id="modal-help-faq-span"
                        [afTranslate]="'modal-help.faq'">Preguntas frecuentes</span>
                  </p>
              </button>
              <button type="submit"
                      class="help-modal__mini-box"
                      [ngShow]="!helpModal.specialModal"
                      (click)="openURL(2)">
                  <i class="lf-icon-learn help-modal__mini-box-icon"></i>
                  <p id="modal-help-curso"
                      matTooltip="{{'modal-help.curso' | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipDisabled]="showTooltip('curso')"
                      class="help-modal__mini-box-text">
                      <span id="modal-help-curso-span"
                        [afTranslate]="'modal-help.curso'">Cursos</span>
                  </p>
              </button>
              <button type="submit"
                      class="help-modal__mini-box"
                      [ngShow]="!helpModal.specialModal"
                      (click)="openURL(3)">
                  <i class="lf-icon-video help-modal__mini-box-icon"></i>
                  <p id="modal-help-video"
                      matTooltip="{{'modal-help.video' | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipDisabled]="showTooltip('video')"
                      class="help-modal__mini-box-text">
                      <span id="modal-help-video-span"
                        [afTranslate]="'modal-help.video'">Videos</span>
                  </p>
              </button>
              <button type="submit"
                      class="help-modal__mini-box"
                      (click)="openURL(4)">
                  <i class="lf-icon-document help-modal__mini-box-icon"></i>
                  <p id="modal-help-manual"
                      matTooltip="{{'modal-help.manual' | afTranslate}}"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipDisabled]="showTooltip('manual')"
                      class="help-modal__mini-box-text">
                      <span id="modal-help-manual-span"
                        [afTranslate]="'modal-help.manual'">Manuales</span>
                  </p>
              </button>
          </div>
      </div>
      <div class="help-modal__button-container" *ngIf="helpModal.showHelpButton">
          <button class="help-modal__help-button"
              (click)="openURL(5)"
              [afTranslate]="'modal-help.help-button'">Ir al centro de ayuda</button>
      </div>
  </div>
</div>
