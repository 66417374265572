import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Pipe({name: 'unique'})
export class UniquePipe implements PipeTransform {
  constructor(private commonUtilsService: CommonUtilsService){}
  transform(list:any, type:string):any {
    let uniqueList: any = this.commonUtilsService.uniqueValuesList(list, type);
    // let keys: any = [];
    // let uniqueList: any = [];
    // if(list){
    //   list.forEach((item:any) => {
    //     let key:any = item[type];
    //     if (keys.indexOf(key) === -1) {
    //         keys.push(key);
    //         uniqueList.push(item);
    //     }
    //   });
    // }
    return uniqueList;
  }
}
