  <div class="rename-modal"
    *ngIf="!isLanding">
    <div class="common__modal-header-block">
        <i class="lf-icon-news common__icon-header"></i>
        <span class="common__text-header">{{notification.name}}</span>
        <i class="lf-icon-close common__icon-close-modal"
            (click)="handleDismiss()"></i>
    </div>

    <div id="print-notification-modal">
        <div class="notification-modal__body"
          [innerHTML]="notificationPreview.notificationBody"></div>
    </div>

    <div class="common__buttons-block rename-modal__buttons-block">
        <button type="button"
            class="common__button common__button--active"
            (click)="printAction()"
            [afTranslate]="'common.print'">_imprimir</button>
    </div>
  </div>


  <div class="rename-modal"
    *ngIf="isLanding">
    <div class="common__block-icon-close-modal-custom notification-modal__close-modal-custom">
        <button class="lf-icon-close-round common__icon-close-modal-custom"
            (click)="handleDismiss()"></button>
    </div>
    <div class="common__modal-header-block common__modal-header-block--custom-margin"
        [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}">
        <i class="lf-icon-news common__icon-header common__icon-header--custom"></i>
        <span class="common__text-header common__text-header--custom">{{notification.name}}</span>
    </div>

    <div id="print-notification-modal">
        <div class="notification-modal__body"
          [innerHTML]="notificationPreview.notificationBody"></div>
    </div>

    <div class="common__buttons-block rename-modal__buttons-block">
        <button type="submit"
            class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last notification-modal__button-print--custom"
            [ngStyle]="{'background-color': custom.color, 'color': custom.textColor}"
            (click)="printAction()"
            [afTranslate]="'common.print'">_imprimir</button>
    </div>
</div>
