import { Component, EventEmitter, Input, Output, OnChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'picker-data-simple',
  templateUrl: './picker-data-simple.component.html'
})
export class PickerDataSimpleComponent implements OnChanges {
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;
  custom: any = this.customLanding.getCustom();
  skin: string = this.custom?'landing-custom' : this.globalCfg.skin;
  pickerDataSimple: any = {
    id: this.commonUtilsService.createId(),
    dateLockType: 2
  }
  constructor(private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService) { }
  @Input() finishIntervalDate:any = null;
  @Input() disabled: boolean = false;
  @Output() selectDate = new EventEmitter();


  ngOnChanges(): void {
    console.log(this.finishIntervalDate)
  }

  outputDate(e:any){
    let value = e.value
    this.finishIntervalDate =  value !==null? new Date(value) : value;
    this.changeInDate(this.finishIntervalDate);
  }

  changeInDate(newValue: any){
      this.finishIntervalDate = newValue;
      this.selectDate.emit({date: this.finishIntervalDate});
      this.dropdown.close();
      this.pickerDataSimple.openDatePicker = false;
  }

  changeOpenDataPicker(){
      if(!this.disabled){
          this.pickerDataSimple.openDatePicker = !this.pickerDataSimple.openDatePicker;
          setTimeout(()=>{
              if(this.pickerDataSimple.openDatePicker){
                  $('.uib-datepicker-popup').addClass('picker-data-simple__calendar-block');
              }
          });
      }
  }
}
