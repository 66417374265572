import { Component, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ExpandedMenuService } from '@shared/services/expanded-menu/expanded-menu.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import ExpandMenu from '../../../../assets/data/expanded-menu.json'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'evaluation-header',
  templateUrl: './evaluation-header.component.html'
})
export class EvaluationHeaderComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  esBBDD: boolean = (this.skin === 'dark-blue' || this.skin === 'gm-law');
  expandMenu:any = ExpandMenu;
  evaluationHeader: any ={
    breadcrumbListMenu: this.expandMenu['folderMenuList'].menu,
    showMenuOption: false,
    breadcrumbList: [],
    userPermissions: this.route.snapshot.data['userPermissions'],//sessionStorage.getItem('evalUser'),
    folderId:this.route.snapshot.firstChild?this.route.snapshot.firstChild.params['folderId']:'',
    evaluatedConceptInfo: {}
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private expandedMenuService: ExpandedMenuService,
    private conceptState: ConceptStateFactory,
    private modalService: ModalServiceService,
    private broadcastService: BroadcastService,
    private router: Router,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService) { }

  @Input() evaluationInfo: any = {};
  @Input() evaluationTree: any = {};
  @Input() evaluatedConceptInfo: any = {};
  @Input() draftEvaluations: boolean = false;
  @Input() folderSelect: any = null;
  @Input() changeBreadcrumb: boolean = false;
  @Output() sendAction =  new EventEmitter();
  @Output() returnBreadcrumbList =  new EventEmitter();
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;



  ngOnChanges(): void {
    if(this.evaluationInfo){
      this.evaluationHeader.title = this.evaluationInfo.productTypeId === 4? this.evaluationInfo.productName : this.evaluationInfo.name;
    }
    let breadcrumbListMenu : any = !this.evaluationInfo?.customizable?'confidentialOptionMenu':'folderMenuList';
    this.evaluationHeader.breadcrumbListMenu = this.expandMenu[breadcrumbListMenu].menu;

    this.evaluationHeader.evaluatedConceptInfo = this.evaluatedConceptInfo?this.evaluatedConceptInfo:{}

    if(this.skin !== 'uk-steel-blue'){
      this.changeBreadcrumbs();
    }
  }

  changeBreadcrumbs(){
    if(this.evaluationTree && this.evaluationTree.children && this.folderSelect && this.changeBreadcrumb){
        this.evaluationHeader.showMenuOption = false;
        this.sendAction.emit({
            action:{
                type: 'changeBreadcrumb',
                change: false
            }
        })
        this.evaluationHeader.breadcrumbList = [];
        this.evaluationHeader.folderId = this.route.snapshot.firstChild?this.route.snapshot.firstChild.params['folderId']:'';
        let folderId: any = this.folderSelect && (this.folderSelect.folderId === 'reports' || this.folderSelect.folderId === 'favorites')? this.folderSelect.folderId : parseInt(this.evaluationHeader.folderId);
        this.getBreadcrumbList(this.evaluationTree.children, folderId);
        this.returnBreadcrumbList.emit({list: this.evaluationHeader.breadcrumbList});
    }
}

getBreadcrumbList(children:any, folderSelected:any){
    for(let folder of children){
        if(folder.folderId === folderSelected){
            let multipleInstancesInAuto : boolean = false;
            if(folder.conceptObjectId && folder.concepts && folder.concepts.length){
                multipleInstancesInAuto = folder.concepts[0].multipleInstancesInAuto;
            }
            if (!(this.evaluationInfo && this.evaluationInfo.productTypeId === 2 && this.evaluationHeader.evaluatedConceptInfo && this.evaluationHeader.evaluatedConceptInfo.type == 'concept' && this.evaluationHeader.evaluatedConceptInfo.folderId === folder.folderId && !multipleInstancesInAuto)){
                this.evaluationHeader.breadcrumbList.splice(0, 0, folder);
            }

            if(folder.parentId){
                this.getBreadcrumbList(this.evaluationTree.children, folder.parentId);
            }else{
                let breadcrumbLength : any = this.evaluationHeader.breadcrumbList.length;
                if(this.evaluationHeader.evaluatedConceptInfo && this.evaluationHeader.evaluatedConceptInfo.title){
                    breadcrumbLength++;
                }
                if(this.skin ==='gm-law' || this.skin === 'icam-red' || (this.skin === 'dark-blue' && this.evaluationHeader.productId !== 'forms' && this.evaluationInfo.productTypeId !== 4)){
                    let dashboard : any = {
                        folderId: 'dashboard-breadcrumb',
                        name: this.translate.instant('evaluation-header.dashboard'),
                        level: 0
                    }
                    this.evaluationHeader.breadcrumbList.splice(0, 0, dashboard);
                    breadcrumbLength++;
                }
                this.evaluationHeader.breadcrumbMaxWidth = 100/breadcrumbLength + '%';
            }

            if (((this.evaluationInfo && this.evaluationInfo.customizable) || folder.supportsConfidential) && folder.folderId !== 'reports' && this.evaluationHeader.userPermisions !== 'none' && this.globalCfg.dashboardVersion === '1'){
                this.evaluationHeader.showMenuOption = true;
            }
            break;
        }
        if(folder.children && folder.children.length){
            this.getBreadcrumbList(folder.children, folderSelected);
        }
    }
  }

  openFolderMenu( breadcrumb:any){
    breadcrumb.showMenuHeader = !breadcrumb.showMenuHeader
    breadcrumb.menuListHeader = breadcrumb.showMenuHeader? this.expandedMenuService.menuFolderEvaluationHeader(this.evaluationHeader.breadcrumbListMenu, breadcrumb, this.evaluationInfo): [];
  }

  launchAction(e:any, option:any, item:any, index:any = undefined){
    e.preventDefault();
    if(this.route.snapshot.firstChild?.data['componentName'] !== 'folder' && this.conceptState.getAmount() || this.conceptState.getProcess()){
        if(this.route.snapshot.firstChild?.data['componentName'] === 'concept'){
            this.modalService.adviceModal(this.translate.instant('concept.advertence-exit'), 'info:exit:question')
              .subscribe((result:any) => {
                  if(result.result !== 'cancel'){
                    if(result.result === 'save-exit'){
                      this.broadcastService.broadcast('saveConceptToEvaluation', {from:'evaluationHeader', option: option, item: item, goBack: true});
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(false);
                      this.configureSendAction();
                    }else{
                      this.emitAction(option, item, true, true);
                    }
                  }
              });
        }else{
          this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question')
            .subscribe((result: any) => {
              if(result.result !== 'cancel'){
                this.emitAction(option, item, true, true);
              }
            });
        }
    }else if(!(option === 'open' && !(this.evaluationHeader.evaluatedConceptInfo?.conceptId || this.evaluationHeader.evaluatedConceptInfo?.reportId) && (index === this.evaluationHeader.breadcrumbList.length-1))){
        this.emitAction(option, item, true, false);
    }

  }

  emitAction(option:any, item:any, goBack:boolean, conceptState: boolean = false){
    if(conceptState){
      this.conceptState.removeAll();
      this.conceptState.setProcess(false);
    }

    if(item.folderId === 'dashboard-breadcrumb'){
      this.router.navigate(['dashboard']);
    }else{
        this.sendAction.emit({
            action:{
                type: option,
                folder: item,
                goBack: goBack
            }
        })
    }
    this.configureSendAction();
  }

  configureSendAction(){
    this.evaluationHeader.showRoutes = false;
  }

  showDraftEvaluations(){
    this.modalService.draftEvaluationsModal(false, [])
      .subscribe((result:any)=>{
          if(result.result ==='ok'){
            this.loader.openLoader('evaluation-header-draft-evaluation');
            this.getDraftInfo(result.draftConcept);
          }
      })
  }

  getDraftInfo(draft:any){
    this.apiService.get('conceptobjects', draft.conceptObjectId )
    .then((data:any)=> {
      let urlNavigate: any = '/evaluation/'+data.evaluationId+'/folder/'+data.folderId+'/concept/'+data.conceptId+'/concept-evaluated/'+data.conceptObjectId;
      if(window.location.pathname !== urlNavigate){
        this.commonUtilsService.reloadNavigateUrlEvaluation(urlNavigate, data.evaluationId);
      }else{
        this.loader.closeLoader(['evaluation-header-draft-evaluation']);
      }
    })
  }

  showTooltip(element:string = ''){
    return this.commonUtilsService.showTooltip(element);
  }

  saveNewEvaluation(result:any){
    result.roles && result.roles.length? this.saveRoles(result) : this.createEvaluation(result);
  }

  createEvaluation(result:any){
    let createEvaluation : any = result.createEvaluation;
    this.apiService.add('evaluations/'+createEvaluation.productId+'/'+ createEvaluation.clientId, createEvaluation)
        .then((data:any)=>{
            window.open('/evaluation/' +  data.evaluationId, '_blank');
            this.loader.closeLoader(['dashboard-panel-create-evaluation']);
         }, (errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('dashboard.error-implantation-create')+'"'+createEvaluation.name+'"', 'error:accept:warning');
         });
  }

  saveRoles(result:any){
    console.log(result);
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }
}
