import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';

@Component({
  selector: 'window-list',
  templateUrl: './window-list.component.html'
})
export class WindowListComponent implements OnInit, OnChanges {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private commonUtilsService:CommonUtilsService
  ) { }

  @Input() items:any;
  @Input() itemIcon:string;
  @Input() headerIcon:string;
  @Input() titleText:string;
  @Input() type:string;
  @Input() text:string;
  @Input() clickText:number;

  @Output() triggerIconEvent = new EventEmitter();

  skin:string = this.globalCfg.skin;
  windowList:any = {itemList: []};

  ngOnChanges() {
    let that = this.windowList;
    if (Array.isArray(this.items)) {
      switch (this.type) {
          case 'customReportField':
              that.itemList = this.items.map((item:any) => {
                  return { id: item.reportFieldId, name: item.reportFieldName, isCustom: item.isCustom };
              }).filter(i => i.id !== null);
              break;
          case 'customReport':
              that.itemList = this.items.map((item:any) => {
                  return { id: item.reportId, name: item.reportName, isCustom: item.isCustom };
              }).filter(i => i.id !== null);
              break;
      }

      this.text = that.itemList.map((x:any) => { return x.name }).join(', ');
      this.clickText = that.itemList.length;
    }
  }

  ngOnInit() {
    this.windowList.idCell = this.commonUtilsService.createId();
  }

  iconClicked(item:any) {
    if (this.type === 'customReportField') {
      this.triggerIconEvent.emit({ id: item.id, isCustom: item.isCustom });
    }
  }
}
