import { Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConvertNumToNameFactory } from '@shared/factories/convert-num-to-name/convert-num-to-name.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'task-concept',
  templateUrl: './task-concept.component.html'
})
export class TaskConceptComponent implements OnChanges  {
  userPermissions:any = this.permissions.getPermissions();
  canCreate:boolean = this.userPermissions === 'all';
  currentComponent: string = this.route.snapshot.data['componentName'];
  taskConcept:any = {
    state: 'list',
    taskSelect: null
  }
  constructor(private permissions: UserPermisionsFactory,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private context: ContextFactory,
    private convertNumbToName: ConvertNumToNameFactory,
    private registerService: RegisterMixtapeService,
    private route: ActivatedRoute) { }

  @Input() concept:any = null;
  @Input() to:any = null;
  @Input() shared:any = null;
  @Input() hasOpenEvaluationTask:boolean = false;
  @Input() field:any = null;
  @Input() taskTypeIdSelect:any = null;

  @Output() modifyConceptTab = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnChanges(): void {
    let that: any = this.taskConcept;
    if(this.field && this.field.selectTask){
      that.taskSelect = this.field.selectTask;
      that.state = 'created';
    }

    if(Boolean(this.shared && this.to.edit || this.to.edit && that.state === 'created' && !(this.field && this.field?.selectTask) && that.taskSelect === null)){
      that.taskSelect = null;
      that.state = 'created';
    }

    that.concept = this.concept;
    if(!that.concept.taskList){
      that.concept.taskList=[];
      this.getTaskByConcept();
    }

    that.to = this.to;
    if(!that.to.edit){
      that.state = 'list';
      that.taskSelect = null;
    }
  }

  getTaskByConcept(){
      this.apiService.get('tasks/byconceptobjectid', this.taskConcept.concept.conceptObjectId)
          .then((data:any)=> {
              this.taskConcept.concept.taskList = data;
              this.taskConcept.concept.initializedtask = true;
              data.forEach((item:any) => {
                item.type = this.getTitleText(item)
              });
              let taskId: any = this.taskConcept.to?.taskId?this.taskConcept.to?.taskId:undefined;
              this.configureState(taskId);
          });
  }

  getTitleText(task:any){
      switch(task.taskTypeId){
          case 1:
            return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-info-request') : this.translate.instant('task-edit.periodic-request')
          case 3:
            return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-evaluation') : this.translate.instant('common.evaluation');
          case 5:
            return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-comunication') : this.translate.instant('law-info.comm')
          case 6:
            return task.repeatPattern && task.repeatPattern.frecuencyId? this.translate.instant('task-edit.periodic-management') : this.translate.instant('task-edit.punctual-management')
          default:
              return task.type;

      }
  }

  configureState(taskId: any){
      let that: any = this.taskConcept;
      for(let i = 0; i < that.concept.taskList.length;i++){
          if(that.concept.taskList[i].taskId === parseInt(taskId)){
              that.taskSelect = that.concept.taskList[i];
              that.state = 'created';
              if(this.currentComponent === 'conceptWindow'){
                  delete that.to.taskId;
              }
              break;
          }
      }
      if (that.concept.taskList.length > 0 && that.concept.taskList[0].taskTypeId === 7) {
          that.concept.processTask = that.concept.taskList.shift();
      }
      this.loader.closeLoader(['charge-task-concept-list']);
  }


  changeView(e:any){
      let to:any = e.to;
      let taskSelected:any = e.task;
      let action:any = e.action;
      let that: any = this.taskConcept;
      this.taskTypeIdSelect = null;
      if(taskSelected.taskTypeId === 7){
        that.concept.processTask = taskSelected;
      }else{
        for(let i = 0; i < that.concept.taskList.length; i++){
            let task = that.concept.taskList[i];
            if(taskSelected && taskSelected.taskId === task.taskId){
                that.concept.taskList.splice(i, 1, taskSelected);
                break;
            }
        }
      }

      if(to ==='save-and-exit-field'){
          this.launchAction.emit({
                type:'oldTab',
                from: 'field',
                action: action
          });
      }else if(to ==='cancel' && this.shared){
          this.launchAction.emit({
                type:'oldTab',
                from: 'task',
                cancel: true
          });
      }else{
          that.state = 'list';
          that.to.edit = false;
      }
  }
  modifyTaskListOn(){
    this.modifyTaskList({action:'', task: null})
  }

  modifyTaskList(e:any){
    let action:any= e.action;
    let task:any = e.task;
    if(action === 'reloadList'){
        this.taskConcept.concept.taskList.push(task);
        this.configureState(task.taskId);
    }else{
        this.taskConcept.oldTask = cloneDeep(task);
        this.modifyConceptTab.emit();
    }
  }

  openOrCreatedTask(e:any){
      let task: any = e.task;
      if(task && task.taskTypeId){
          let resgisterItem ={
              'Distinct ID': this.context.user.externalId,
              'Client ID': this.context.user.companyExternalId,
              'event':'open task',
              'Task Type': this.convertNumbToName.taskNumbToName(String(task.taskTypeId)),
              'Concept Name':sessionStorage.getItem('breadcrumbConcept') + this.taskConcept.concept.title,
              'Source': 'implantation',
              'Centinela product': this.context.settings.defaultProduct
            }
            this.registerService.register(resgisterItem)
      }
      this.taskConcept.taskSelect = task;
      this.taskConcept.oldTask = cloneDeep(task);
      this.taskConcept.state = 'created';
  }
}
