import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Component({
    selector: 'menu-header-products',
    templateUrl: './menu-header-products.component.html'
})
export class MenuHeaderProductsComponent implements OnInit {
    skin:string = this.globalCfg.skin;
    menuHeaderProducts:any = {};
    constructor(
        private globalCfg:GlobalCfgFactory,
        private translate: AfTranslateFactory,
        private loader:LoaderFactory,
        private modalService: ModalServiceService,
        private context: ContextFactory,
        private apiService: ApiServiceService) { }

    @Output() closeMenu = new EventEmitter();

    ngOnInit(){
      this.getProducts();
    }

    getProducts() {
      if (this.context.user.companyId) {
        this.apiService.get('company/externaltools').then((data:any) => {
          this.menuHeaderProducts.listProduct = data;
        }, (error:any) => {
          console.error(error);
        });
      }
    }

    openService(product:any) {
      switch (product.id) {
          case 9:
              this.openLexon();
              break;
          case 37:
            this.apiService.getUrl(product.url)
              .then((data:any)=>{
               window.open(data.sResultado)
              })
            break;
          default:
              window.open(product.url);
      }
    }

    openLexon() {
      this.loader.openLoader('get-lexon-url');
      this.apiService.get('company/externaltools/9/geturl').then(
          (data:any) => {
              window.open(data.url);
          }, (error:any) => {
              console.error(error);
              this.modalService.adviceModal(this.translate.instant('common.unexpected-error'), 'error:accept:warning');
          }
      ).finally(() => {
        this.loader.closeLoader('get-lexon-url');
      });
    }

    openMail(){
      this.loader.openLoader('get-mail-url');
      this.apiService.get('company/lefebvreemail/geturl').then(
        (data:any) => {
          window.open(data, '_blank');
          this.loader.closeLoader('get-mail-url');
        }, (error:any) => {
          console.error(error);
          this.loader.closeError();
        }
      );
    }

    closeDropdown(){
      this.closeMenu.emit();
    }
}
