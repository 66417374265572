import moment from 'moment';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';

@Component({
  selector: 'field-type-alert',
  templateUrl: './field-type-alert.component.html'
})
export class FieldTypeAlertComponent implements OnInit, OnChanges, OnDestroy {
  subscribers: any = {};
  skin: string;
  custom: any = this.customLanding.getCustom();
  isLanding: boolean = this.customLanding.isLanding();
  fieldTypeAlert: any = {
    openLaw: false,
    references: false,
    alertList: [],
    userPermissions:  this.userPermisions.getPermissions()
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private customLanding: CustomLandingFactory,
    private route: ActivatedRoute,
    private userPermisions: UserPermisionsFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private translate: AfTranslateFactory,
    private modalService: ModalServiceService,
    private dateConverterService: DateConverterService,
    private broadcastService: BroadcastService,
    private loader: LoaderFactory,
    private registerService: RegisterMixtapeService) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() concept: any = null;
  @Output() itemSelected = new EventEmitter();
  @Output() createAlert = new EventEmitter();

  ngOnInit(): void {
    this.skin = this.custom !== null? 'landing-custom': this.globalCfg.skin;
    this.watchAddOrDeleteAlertFromConcept();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['field'] && changes['field'].currentValue) {
      this.fieldTypeAlert.field = changes['field'].currentValue;
      this.fieldTypeAlert.field.id = !this.fieldTypeAlert.field.id? this.commonUtilsService.createId(): this.fieldTypeAlert.field.id;
      this.fieldTypeAlert.field.description = this.commonUtilsService.checkAndDeletePreTag(this.fieldTypeAlert.field.description)

      if(this.fieldTypeAlert.field.evaluatedValue){
        this.getAlerts();
      }
      if(this.isLanding && this.context.user.contactId){
        this.fieldTypeAlert.field.noEditable = true;
      }
    }
  }

  watchAddOrDeleteAlertFromConcept(){
    this.subscribers.addOrDeleteAlertFromConceptBroadcast = this.broadcastService.subscribe("addOrDeleteAlertFromConcept", (data:any) => {
      if(data.field.fieldId === this.field.fieldId){
        if(data.action === 'add'){
          this.configText(data.newAlert);
          this.addAlertToDisplayData(data.newAlert);
          this.fieldTypeAlert.alertList.push(data.newAlert);
        }else if(data.action === 'delete'){
          this.fieldTypeAlert.alertList = [];
        }
      }
    });
  }

  getAlerts(){
      this.apiService.get('conceptobjects/fieldalerts/' + this.fieldTypeAlert.field.evaluatedValue.conceptObjectId, + this.fieldTypeAlert.field.fieldId)
      .then((data:any)=>{
          this.fieldTypeAlert.field.evaluatedValue.displayData = [];
          data.forEach((alert:any) => {
            this.configText(alert);
            this.addAlertToDisplayData(alert);
          });
          this.fieldTypeAlert.alertList = data;
      },  (errorData:any)=>{
          console.log(errorData);
      })
  }

  addAlertToDisplayData(alert:any){
      let alertValue = {
          id: alert.id,
          value: {
              title: alert.title,
              date: alert.referenceDateText,
              momentAlert: alert.momentText,
              repeat: alert.repeatText
          }
      }
      this.fieldTypeAlert.field.evaluatedValue.displayData.push(alertValue);
  }

  configText(alert:any){
      if(alert.alertDate){
          alert.referenceDate = new Date('"'+alert.alertDate.substring(6,10)+'/'+alert.alertDate.substring(3,5)+'/'+alert.alertDate.substring(0,2)+'"' );
          alert.referenceDateText = alert.alertDate;
      }else{
          alert.referenceDateText = moment(alert.referenceDate).format('DD/MM/YYYY').valueOf()
      }
      let hour = alert.hour?alert.hour:'00:00';
      let textHour = hour.split(':')[0]+':'+hour.split(':')[1];
      let timeUnit = alert.timeUnit?alert.timeUnit.toLowerCase():'d';
      let textTimeUnit: string = '';
      switch(alert.alertTypeId){
          case 1:
              alert.momentText = this.translate.instant('add-alert.warning-exact-day') + textHour;
              break;
          case 2:
              textTimeUnit = this.getTimeUnit(timeUnit);
              alert.momentText = this.translate.instant('add-alert.warn') + ' ' + alert.numDays + ' ' + textTimeUnit + ' ' + this.translate.instant('add-alert.at-before') + textHour;
              if(alert.alertDate){
                  //alert.referenceDate.setDate(alert.referenceDate.getDate() + alert.numDays);
                  alert.referenceDate = moment(alert.referenceDate).add(alert.numDays,'days');
                  alert.referenceDateText = moment(alert.referenceDate).format('DD/MM/YYYY').valueOf();
              }
              break;
          case 3:
              textTimeUnit = this.getTimeUnit(timeUnit);
              alert.momentText = this.translate.instant('add-alert.warn') + ' ' + alert.numDays + ' ' + textTimeUnit + ' ' + this.translate.instant('add-alert.at-later') + textHour;
              if(alert.alertDate){
                  //alert.referenceDate.setDate(alert.referenceDate.getDate() - alert.numDays);
                  alert.referenceDate = moment(alert.referenceDate).add(-alert.numDays,'days');
                  alert.referenceDateText = moment(alert.referenceDate).format('DD/MM/YYYY').valueOf();
              }
              break;
      }
      alert.momentText = alert.momentText?alert.momentText.toLowerCase():'';

      if(alert.repeat){
          let repeatTimeUnit = alert.repeatTimeUnit?alert.repeatTimeUnit.toLowerCase():'d';
          textTimeUnit = this.getTimeUnit(repeatTimeUnit);
          if(alert.repeatType){
              let repeatOccurrencesText = alert.repeatOccurrences?this.translate.instant('add-alert.repeat-time-'+alert.repeatOccurrences) : this.translate.instant('add-alert.repeat-time-0');
              alert.repeatText = this.translate.instant('add-alert.every') + ' ' + alert.repeatDays + ' ' + textTimeUnit + ' ' + repeatOccurrencesText;
          }else{
              alert.repeatText = this.translate.instant('add-alert.during') + ' ' + alert.repeatDays + ' ' +textTimeUnit;
          }
          alert.repeatText = alert.repeatText.toLowerCase();
      }

      if(this.isLanding && this.context.user.userId && this.context.user.userId === alert.createdBy){
          alert.edit = true;
      }
  }

  getTimeUnit(type:string){
      switch(type){
          case 'h':
              return this.translate.instant('repeat-pattern.hour-s');
          case 'w':
              return this.translate.instant('repeat-pattern.week-s');
          case 'm':
              return this.translate.instant('repeat-pattern.month');
          case 'y':
              return this.translate.instant('repeat-pattern.year-s');
          default:
              return this.translate.instant('repeat-pattern.days');
      }
  }

  newAlert(){
      let configuration = {
          from: 'fieldTypeAlert',
          type: 'date',
          concept: this.fieldTypeAlert.field,
          custom: this.custom
      }
      this.modalService.addAlert(configuration).subscribe((result: any)=>{
        if(result.result==='ok'){
          result.alert.alertTypeId = result.alert.dateAlertTypeId;
          result.alert.referenceDate = this.dateConverterService.toIso(result.alert.referenceDate);
          this.addAlert(result, "date");
        }
      })
  }

  addAlert(result:any, type:any){

      this.fieldTypeAlert.concept? this.registerEvent('create alert', this.fieldTypeAlert.concept.title) : this.getConcept('create alert');

      this.apiService.add('datealerts/' + this.fieldTypeAlert.field.evaluatedValue.conceptDataId, result.alert)
          .then((data:any)=> {
              let newAlert = data;
              newAlert.id = data.dateAlertId;
              if (this.isLanding && this.context.user.userId) {
                  newAlert.createdBy = this.context.user.userId;
              }
              this.configText(newAlert);
              this.fieldTypeAlert.alertList.push(newAlert);
              this.addAlertToDisplayData(newAlert);

              if (result.share && result.share.length) {
                  let text = this.translate.instant('task-edit.generate-alert') + "'" + newAlert.title + "'";
                  this.sendShare(newAlert, result.share, text);
              } else {
                  let text = this.translate.instant('task-edit.generate-alert') + "'" + newAlert.title + "'";
                  this.modalService.adviceModal(text, 'info:accept:check-round');
              }
              this.fieldTypeAlert.concept.alerts++;
          }, function (errorData) {
              console.log(errorData);
          });
  }

  sendShare(alert:any, share:any, text:any) {
      this.apiService.add('Datealerts/sharealert/' + alert.id + '/omit-notify-creator/' + alert.omitNotifyToCreator, share)
          .then( (data:any)=> {
              alert.destination = share;
              alert.notified = true;
              this.loader.closeLoader(['alert-condept-edit-alert']);
              this.modalService.adviceModal(text, 'info:accept:check-round');
          },  ()=>{
              this.loader.closeError();
          });
  }

  editAlert(alert:any){
      let configuration = {
          from: 'fieldTypeAlert',
          type: 'date',
          alert: alert,
          concept: this.fieldTypeAlert.field,
          custom: this.custom
      }
      this.modalService.addAlert(configuration).subscribe((result: any)=>{
        if(result.result==='ok'){
          if(result.alert.referenceDate instanceof Date){
            result.alert.referenceDate = this.dateConverterService.toIso(result.alert.referenceDate);
          }
          this.fieldTypeAlert.concept? this.registerEvent('create alert', this.fieldTypeAlert.concept.title) : this.getConcept('create alert');
          this.saveDateAlert(alert, result);

      }
      });
  }

  saveDateAlert(alert: any, result: any){
    this.apiService.patch('datealerts', alert.id, result.alert)
      .then((data:any)=>{
        alert.alertTypeId = result.alert.dateAlertTypeId;
        alert.hour = result.alert.hour;
        alert.numDays = result.alert.numDays;
        alert.referenceDate = result.alert.referenceDate;
        alert.repeat = result.alert.repeat;
        alert.repeatDays = result.alert.repeatDays;
        alert.repeatOccurrences = result.alert.repeatOccurrences;
        alert.repeatType = result.alert.repeatType;
        alert.title = result.alert.title;
        alert.alertDate = null;
        this.configText(alert);
        let alertValue = {
            id: alert.id,
            value: {
                title: alert.title,
                date: alert.referenceDateText,
                momentAlert: alert.momentText,
                repeat: alert.repeatText
            }
        }
        this.fieldTypeAlert.field.evaluatedValue.displayData = this.commonUtilsService.replaceElement(alert, this.fieldTypeAlert.field.evaluatedValue.displayData, 'id', alertValue);
        if(result.share){
            let text = this.translate.instant('alert-concept.the-alert')+"'"+ alert.title+"'"+ this.translate.instant('alert-concept.alert-edit');
            this.sendShare(alert, result.share, text);
        }else{
            this.loader.closeLoader('alert-condept-edit-alert');
            let text = this.translate.instant('alert-concept.the-alert')+"'"+ alert.title+"'"+ this.translate.instant('alert-concept.alert-edit');
            this.modalService.adviceModal(text, 'info:accept:check-round');
        }
    }, (errorData: any)=>{
      this.loader.closeError();
      this.modalService.adviceModal(this.translate.instant('alert-concept.error-edit-alert'), 'error:accept:warning');
    })
  }

  deleteAlert(alert:any){
      this.modalService.adviceModal(this.translate.instant('alert-concept.question-delete-alert') +"'"+ alert.title + "' ?", 'info:question:question')
        .subscribe((result:any)=>{
          result.result ==='ok'?  this.doDeleteAlert(alert) : this.loader.closeLoader('alert-concept-delete-alert');
        })
  }

  doDeleteAlert(alert:any) {
      this.loader.openLoader('delete-alert-loader');
      this.fieldTypeAlert.concept? this.registerEvent('delete alert', this.fieldTypeAlert.concept.title) : this.getConcept('delete alert');

      this.apiService.delete('Datealerts', alert.id)
          .then((result:any)=>{
              this.fieldTypeAlert.alertList = this.commonUtilsService.deleteElement( alert, this.fieldTypeAlert.alertList, 'id');
              this.fieldTypeAlert.field.evaluatedValue.displayData = this.commonUtilsService.deleteElement( alert, this.fieldTypeAlert.field.evaluatedValue.displayData, 'id');
              this.broadcastService.broadcast('getHeaderNotifications');
              this.loader.closeLoader(['delete-alert-loader']);
              let text = this.translate.instant('alert-concept.the-alert')+"'"+ alert.title +"'"+ this.translate.instant('alert-concept.alert-has-delete');
              this.modalService.adviceModal(text, 'info:accept:check-round');
              this.fieldTypeAlert.concept.alerts--;
          },(error:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('alert-concept.error-delete-alert'), 'error:accept:warning');
          });
  }

  getConcept(type:any){
    this.apiService.get("fields/getconceptbyfieldid/"+this.route.snapshot.params['evaluationId'], this.fieldTypeAlert.field.fieldId)
        .then((data:any)=>{
            this.fieldTypeAlert.concept = data;
            this.registerEvent(type, data.title);
        }, (errorData:any)=>{})
  }

  registerEvent(type:any, title:string){
      let resgisterItem ={
          'Distinct ID': this.context.user.externalId,
          'Client ID': this.context.user.companyExternalId,
          'event': type,
          'Alert Type':'date',
          'Concept Name':sessionStorage.getItem('breadcrumbConcept') + title,
          'Centinela product': this.context.settings.defaultProduct
      }
      this.registerService.register(resgisterItem);
  }

  changeInField(){
      if(!this.fieldTypeAlert.field.noEditable){
          this.itemSelected.emit({newValue:this.fieldTypeAlert.alertList});
      }
  }
  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}

