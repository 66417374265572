import moment from 'moment';

import { defaultsDeep } from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ValidateDocumentService } from '@shared/services/validate-document/validate-document.service';
import { PrintService } from '@shared/services/print/print.service';
import { ActivatedRoute } from '@angular/router';
import { RappidComponent } from '../rappid/rappid.component';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Component({
  selector: 'process-modal',
  templateUrl: './process-modal.component.html'
})
export class ProcessModalComponent implements OnInit {
  isLanding = this.route.snapshot.firstChild?.data['isLanding'] || this.route.snapshot.data['isLanding']
  skin:string = this.globalCfg.skin;
  processModal:any = {
    nextStateFieldInfo: {
      label: this.translate.instant('process.modal.nextStateLabel'),
      required: true
    },
    process: {references: []},
    transitionsBySource: {},
    statesById: {},
    nextStateFieldList: []
  };

  constructor(private route: ActivatedRoute,
    private globalCfg:GlobalCfgFactory,
    private loader:LoaderFactory,
    private translate:AfTranslateFactory,
    private apiService:ApiServiceService,
    private activeModal:NgbActiveModal,
    private modalService:ModalServiceService,
    private dateConverterService:DateConverterService,
    private downloadService:DownloadService,
    private exportService:ExportServiceService,
    private context:ContextFactory,
    private registerService:RegisterMixtapeService,
    private validateDocumentService:ValidateDocumentService,
    private print: PrintService,
    private commonUtilsService: CommonUtilsService,
    private documentActions: DocumentActionsService
  ) { }

  @Input() configuration:any;
  @Output() updateOwner = new EventEmitter();
  @ViewChild(RappidComponent)
  rappid: RappidComponent;

  ngOnInit(): void {
    let that = this.processModal;
    that.config = this.configuration;
    if( that.config.concept){
       that.config.conceptId =  that.config.concept.conceptId;
       that.config.conceptTitle =  that.config.concept.title;
    }
    if( that.config.conceptObjectId !== 'view'){
        that.isConceptObject = !! that.config.conceptObjectId;
    }
    this.loadProcess();
  }

  createNewFile(result:any){
    let that = this.processModal;
    this.loader.openLoader('create-new-file');
    this.apiService.add(result.url, {name: result.name, stateId: result.stateId})
    .then((data:any) => {
          data.sourceName = this.translate.instant('dashboard.Process');
          data.stateProcess = result.nameState;
          data.state = {
              name: result.nameState,
              stateId: result.stateId
          }
          that.config.concept.documentation.documents.push(data);
          that.config.concept.documentation.totalDocuments += 1;
          this.setHasDocumentsByStateId(result.stateId, true);
          this.documentActions.doAction('open', data);
          this.modalService.adviceModal(this.translate.instant('common.the-document') + "'" + data.name + "'" + this.translate.instant('documentation-list.created-ok'), 'info:accept:check-round');
          this.loader.closeLoader('create-new-file');
      }, (error:any) => {
        console.error(error);
        this.loader.closeLoader('create-new-file');
        this.modalService.adviceModal(this.translate.instant('documentation-list.error-document') + "'" + result.name + "'", 'error:accept:warning');
      }
    );
  }

  loadProcess() {
    let that = this.processModal;
    this.loader.openLoader('process-modal-component');
    if (that.isConceptObject) {
        this.apiService.getWithTranslate('processesdata', that.config.conceptObjectId)
          .then((data:any)=>{
            this.successCallback(data)
          }, (errorData:any)=>{
            this.loader.closeLoader('process-modal-component');
          });
    } else {
        this.apiService.get('processes', that.config.evaluationId + "/" + that.config.conceptId)
          .then((data:any)=>{
            this.successCallback(data)
          }, (errorData:any)=>{
            this.loader.closeLoader('process-modal-component');
          });
    }
  }

  successCallback(data:any){
    let that: any = this.processModal;
    if (data) {
        that.process = {
            processId: data.processId,
            title: data.title || this.translate.instant('process.title'),
            description: data.description,
            references: data.references,
            transitions: data.transitions,
            notifyStep: data.notifyStep,
            hasTask: !!(data.processGeneralTask && data.processGeneralTask.length)
        };
        that.transitions = data.transitions;
        that.states = data.states;

        //primero los links porque así cargamos transitionsBySource y lastTransition, que hacen falta para los estados
        let links = this.getLinks(data.transitions);
        let elements = this.getElements(data.states);
        that.model = { cells: [...elements, ...links]};
        if (that.isConceptObject) {
            if (that.current === that.initialState) {
                that.lastTransition = null;
            } else {
                that.lastTransition =  that.process.transitions.find((item:any) => { return item.targetId === that.current.stateId && item.data; });
            }
            that.nextTransitions = that.transitionsBySource[that.current.stateId];
        }
        this.loader.closeLoader('process-modal-component');
    } else {
        throw 'Get to processesdata did not returned anything';
    }
  };

  getLinks(transitions:any){
    let transitionsArray:any = [];
    transitions.forEach((transition:any) => {
      transitionsArray.push(this.mapTransitionToLink(transition))
    });
    return transitionsArray;
  }

  getElements(states:any){
    let statesArray:any = [];
    states.forEach((state:any) => {
      statesArray.push(this.mapStateToCell(state))
    });
    return statesArray;
  }

  mapTransitionToLink(transition:any){
    let that = this.processModal;
    let link = JSON.parse(transition.diagramModel);
    let label = (transition.label || '');
    if (label) {
        defaultsDeep(link, {
            attrs: { text: { text: label } },
            labels: [{ attrs: { text: { text: label } } }]
        });
    }

    link.compliance = {
        transitionId: transition.transitionId,
        label: transition.label,
        description: transition.description,
        references: transition.references
    };

    if (transition.data) {
        link.compliance.creationDate = this.getTransitionDateString(transition.data.creationDate);
        if (!that.astTransition || transition.data.creationDate > that.lastTransition.data.creationDate || (transition.data.creationDate === that.lastTransition.data.creationDate && transition.data.transitionDataId > that.lastTransition.data.transitionDataId)) {
            that.lastTransition = transition;
        }
    }
    transition.linkId = link.id;
    if (that.transitionsBySource[transition.sourceId] === undefined) {
        that.transitionsBySource[transition.sourceId] = [];
    }
    that.transitionsBySource[transition.sourceId].push(transition);
    return link;
  };

  mapStateToCell(state:any){
    let that = this.processModal;
    let cell = JSON.parse(state.diagramModel);
    let current = false;
    cell.compliance = {};
    if (that.lastTransition) {
        current = that.lastTransition.targetId === state.stateId;
    } else {
        current = state.initial;
    }
    let selected = false;
    let cellProperties:any = { attrs: null };
    if (that.isConceptObject) {
      if (current) {
          that.current = state;
          selected = true;
      } else if (that.transitionsBySource[state.stateId]) {
          selected = !!that.transitionsBySource[state.stateId].find((transition:any) => { return transition.data; });
      }
      cellProperties.attrs = {
        text: {
          textWrap: { text: state.name }
        },
      };
      cell.compliance.current= current;
      cell.compliance.selected= selected;
    } else {
        cellProperties.attrs = {
            text: {
                textWrap: { text: state.name }
            }
        };
    }
    defaultsDeep(cell, cellProperties);
    cell.compliance.stateId= state.stateId,
    cell.compliance.initial= state.initial,
    cell.compliance.name= state.name,
    cell.compliance.description= state.description,
    cell.compliance.references= state.references,
    cell.compliance.alerts= state.alerts,
    cell.compliance.hasDocuments= state.hasDocuments,
    cell.compliance.hasPublishAuto= state.hasPublishAuto,
    cell.compliance.archiveConceptObject= state.archiveConceptObject,
    cell.compliance.onlyView= true
    state.cellId = cell.id;
    if (that.isConceptObject) {
        if (state.initial) {
          that.initialState = state;
        }
    }
    that.statesById[state.stateId] = state;
    return cell;
  };

  getTransitionDateString(date:any) {
    return moment(date).format('DD MMM YYYY, HH:mm [h]');
  }

  processDescriptionView() {
    let that = this.processModal;
    that.title = that.process.title? that.process.title : this.translate.instant('process.title');
    that.description = that.process.description;
    that.view = 'description';
  }

  processReferencesView() {
    let that = this.processModal;
    that.references = that.process.references;
    that.selectedReference = that.process.references[0];
    that.view = 'references';
  }

  exportProcess() {
    let that = this.processModal;
    let configuration: any = {
      list: that.config.concept.documentation.documents,
      hasPrevious: false,
      title: this.translate.instant('common.export-process'),
      extension: 'PDF'
    }
    this.modalService.exportModal(configuration)
      .subscribe((result:any) => {
        if(result.result === 'ok'){
          that.exportDate = this.dateConverterService.setTimezone(new Date());
          setTimeout(() => {
              let rappidPrint = $('.paper-scroller-background').html().replace(/rappid__tooltip-date-text/g, 'rappid__tooltip-date-text rappid__tooltip-date-text--show');
              let htmlPrint: any = $('#pdf-process-modal').html().replace('<div id="rappid-pdf"></div>', rappidPrint);
              let classJoint: any = 'class="joint-paper joint-theme-default';
              htmlPrint = htmlPrint.replace(classJoint, classJoint + ' process-modal__joint-paper');
              let config:any = {
                  htmlPrint: htmlPrint,
                  nameDocument: result.filename+'.pdf',
                  conceptObjectId: that.config.conceptObjectId,
                  uploadFile: true,
                  landscape: true,
                  successCallback: (uploadResponse:any, blob:any) => {
                      that.config.concept.documentation.documents.push(uploadResponse);
                      that.config.concept.documentation.totalDocuments++;
                      if(result.openFile){
                          window.open(URL.createObjectURL(blob), '_blank');
                      }
                      if(result.downloadFile){
                          this.downloadService.triggerDownload(blob, uploadResponse.name);
                      }
                      delete that.exportDate;
                      this.modalService.adviceModal(this.translate.instant('common.export-process-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
                  }
              }
              this.exportService.toPdf(config);
          });
        }
      });
  }

  onCellAction(e:any) {
    let cell:any = e.cell;
    let action:string = e.action;
    let that = this.processModal;
    if (action === 'alert') {
        this.addAlertModal();
    } else {
        let data = cell.prop('compliance');
        if (data) {
            if (cell.isElement()) {
                that.title = data.name? data.name : this.translate.instant('process.step');
            } else {
                that.title = data.label? data.label : this.translate.instant('process.transition');
            }
            if (action === 'description') {
                that.description = data.description;
                that.view = 'description';
            } else if (action === 'references') {
                that.references = data.references;
                that.selectedReference = data.references[0];
                that.view = 'references';
            } else if (action === 'documents') {
                this.modalService.associatedDocumentation(data, that.config.concept, that.config.concept.listStates, 'process-modal').subscribe((result:any) => {
                    if (result.documents === 0) {
                        this.setHasDocumentsByCell(cell, false);
                    }
                    if (result.statesChanged && result.statesChanged.length) {
                      result.statesChanged.forEach((stateIdChanged:any) => {
                          this.setHasDocumentsByStateId(stateIdChanged, true);
                      });
                    }
                });
            }
        }
    }
  }

  addAlertModal() {
    let that = this.processModal;
    that.errorText = null;
    let configuration = {
        from: 'processModal',
        type: 'ProcessGeneral',
        concept: that.config.concept
    }
    this.modalService.addAlert(configuration)
    .subscribe((result:any) => {
        if(result.result === 'ok'){
          this.registerService.register({
            'Distinct ID': this.context.user.externalId,
            'Client ID': this.context.user.companyExternalId,
            'event':'create alert',
            'Alert Type':'ProcessGeneral',
            'Concept Name': sessionStorage.getItem('breadcrumbConcept') + that.config.concept.title,
            'Centinela product': this.context.settings.defaultProduct
          });
          let alert:any = result.alert;
          let state:any = that.statesById[alert.stateId];
          let cell = this.rappid.rappid.graph.getCell(state.cellId);
          alert.stateId = cell.prop('compliance/stateId');
          alert.conceptObjectId = that.config.conceptObjectId;
          let controller:any = that.isConceptObject ? 'processalerts' : 'processgeneralalerts';
          this.loader.openLoader('add-alert');
          this.apiService.add(controller, alert).then(
            (newAlert:any) => {
              that.config.concept.alerts++;
              cell.prop('compliance/alerts', true);
              this.rappid.addIcon(cell, 'alert');
              if (result.share) {
                  let id = that.isConceptObject ? newAlert.processAlertId : newAlert.processGeneralAlertId;
                  this.apiService.add(controller + '/sharealert/' + id + '/omit-notify-creator/' + newAlert.omitNotifyToCreator, result.share)
                    .then((data:any)=>{
                      this.showSuccessModal()
                    }, (errorData:any)=>{
                      this.apiError(errorData)
                    });
              } else {
                  this.showSuccessModal();
              }
            },(errorData:any)=>{
              this.apiError(errorData)
            }
          );
        }

    });
  }

  showSuccessModal(){
    let that = this.processModal;
    if (!that.isConceptObject) {
        if (this.updateOwner) this.updateOwner.emit({ data: { hasOps: true } });
    }

    let primaryText = this.translate.instant(that.isConceptObject ? 'process.modal.add-alert-success-text1' : 'process.modal.add-general-alert-success-text1');
    let secondaryText = ' ' + this.translate.instant(that.isConceptObject ? 'process.modal.add-alert-success-text2' : '');
    this.modalService.adviceModal(primaryText + secondaryText, 'info:accept:check-round');
    this.loader.closeLoader('add-alert');
  }


  apiError(error:any) {
    let that = this.processModal;
    that.errorText = this.translate.instant('common.unexpected-error');
    if(this.skin === 'icam-red' && this.configuration.concept.conceptNamedKey === 'Consulta' && error && error.description && error.description.message && error.description.alertType && this.isLanding){

      error.description.alertType === 'Internal'? console.log(error.description.message) : that.errorText = error.description.message;

    }else if (error && error.status === 409) {
        that.errorText = this.translate.instant('process.conflict-error-text');
    }
    this.loader.closeError();
  }

  setHasDocumentsByStateId(stateId:any, value:any) {
    let that = this.processModal;
    let state = that.statesById[stateId];
    state.hasDocuments = value;
    let cell = this.rappid.rappid.graph.getCell(state.cellId);
    cell.prop('compliance/hasDocuments', value);
    this.rappid.addIcon(cell, 'documents');
  }

  setHasDocumentsByCell(cell:any, value:any){
      let stateId = cell.prop('compliance/stateId');
      let state = this.processModal.statesById[stateId];
      state.hasDocuments = value;
      cell.prop('compliance/hasDocuments', value);
      this.rappid.deleteDocumentIcon(cell);
  }

  uploadDocument() {
   let that: any = this.processModal
    this.modalService.uploadDocuments("addNewDocumentsFromProcessModal", [], that.config.concept.documentation.documents, that.config.concept, that.config.concept.listStates)
      .subscribe((result:any) => {
        if (result.files.length > 0) {
            for (let i = 0; i < result.files.length; i++) {
                result.files[i].sourceName = this.translate.instant('dashboard.Process');
                result.files[i].stateProcess = result.nameState;
                result.files[i].state = {
                    name: result.nameState,
                    stateId: result.stateId
                }
            }
            that.config.concept.documentation.documents = [...that.config.concept.documentation.documents, ...result.files];
            that.config.concept.documentation.totalDocuments += result.files.length;
            this.setHasDocumentsByStateId(result.stateId, true);
        }
    });
  }

  addDocument() {
    let that: any = this.processModal
    if(this.commonUtilsService.isIE()){
        this.modalService.adviceModal(this.translate.instant('common.error-ie-compatibility'), 'error:accept:warning');
    }else{
        this.modalService.newDocument(that.config.concept.templateList, that.config.concept, 'process-modal', that.config.concept.documentation.documents, that.config.concept.listStates)
        .subscribe((result:any) => {
            if(result.result !== 'cancel'){
              if(this.validateDocumentService.duplicatedDocuments(result.name, that.config.concept.documentation.documents)){
                this.modalService.adviceModal(this.translate.instant('dashboard.the-document') + "'" + result.name + "'" + this.translate.instant('documentation-list.is-already-added'), 'error:accept:warning');
              }else{
                this.createNewFile(result);
              }
            }
        });
    }
  }

  undoTransition() {
    let that = this.processModal;
    that.errorText = null;
    if (that.lastTransition) {
        let processTransition:any = this.searchStateTransition(that.lastTransition);
        let transitionData:any = {transitionId: that.lastTransition.transitionId};
        if(this.commonUtilsService.checkResponsibles( that.config.summary.current.processTaskResponsibles,  this.context.user.userId)){
          this.modalService.adviceModal(this.translate.instant('states-process.not-responsible'), 'info:question:question')
          .subscribe((result:any)=>{
            if(result.result==='ok'){
              this.notResponsibleAcceptChangeUndo(processTransition, transitionData);
            }
          });
       }else{
           this.notResponsibleAcceptChangeUndo(processTransition, transitionData);
       }
    }
  }

  notResponsibleAcceptChangeUndo(processTransition:any, transitionData:any){
    if(this.checkArchiveConcept('previous')){
        this.modalService.adviceModal(this.translate.instant('states-process.question-concept-archived'), 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result ==='ok'){
            if(processTransition && processTransition.hasAlertCustom){
              this.configureTransition(processTransition, transitionData, 'undo');
            }else{
                this.callDeleteTransition(transitionData);
            }
          }
        });
    }else{
        this.modalService.adviceModal(this.translate.instant('states-process.question-task-process-start') +' '+ this.translate.instant('states-process.question-task-process-end'), 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
            if(processTransition && processTransition.hasAlertCustom){
              this.configureTransition(processTransition, transitionData, 'undo');
            }else{
              this.callDeleteTransition(transitionData);
            }
          }
        });

    }
  }

  searchStateTransition(transition:any){
    let that = this.processModal;
    return that.states.filter((state:any)=>{ return transition.sourceId === state.stateId })[0];
  }

  configureTransition(processTransition:any, state:any, direction:string){
    this.modalService.personzalizedNotificationModal(this.configuration.concept, processTransition).subscribe((result:any) => {
        state.customBody = result.customEmail.customBody;
        state.customSubject = result.customEmail.customSubject;
        direction === 'add'? this.callAddTransition(state, true) : this.callDeleteTransition(state, true);
    });
  }

  callAddTransition(transitionData:any, personzalizedNotification:boolean = false){
    let that = this.processModal;

    this.loader.openLoader('add-undo-transition-process-modal');
    this.apiService.add('processesdata/' + this.configuration.conceptObjectId, transitionData).then(
      (data:any) => {
        that.config.summary = data;
          if (data && data.current) {
              that.view = null;
              //El diagrama debe volver a ser visible antes de actualizarlo o se descoloca.
              setTimeout(() => {
                  if (this.updateOwner) this.updateOwner.emit({ data: data });
                  let transition = that.transitions.find((transition:any) => { return transition.transitionId === that.nextStateSelected; });
                  this.setDiagramState(that.current, false, true);
                  that.current = that.statesById[transition.targetId];
                  this.setDiagramState(that.current, true, true);
                  transition.data = {
                      transitionDataId: data.current.transitionDataId,
                      createdBy: data.current.createdBy,
                      creationDate: data.current.creationDate
                  };
                  this.setDiagramDate(transition);
                  that.lastTransition = transition;
                  that.nextTransitions = that.transitionsBySource[that.current.stateId];
                  this.closeLoaderTransition(personzalizedNotification);
              });
          } else {
              this.loader.closeLoader('add-undo-transition-process-modal');
              throw 'AdvanceTo method did not return any data.';
          }
      },(errorData:any)=>{
        this.apiError(errorData);
      }
    );

  }

  setDiagramState(state:any, current:any, selected:any) {
    let cell = this.rappid.rappid.graph.getCell(state.cellId);
    cell.attributes.compliance.current = current;
    cell.attributes.compliance.selected = selected;
    this.rappid.changeColor(cell.attributes.compliance)
  }

  setDiagramDate(transition:any) {
    let link:any = this.rappid.rappid.graph.getCell(transition.linkId);
    link.attributes.compliance.creationDate = transition.data && transition.data.creationDate?this.getTransitionDateString(transition.data.creationDate):null;
    let type: any = link.attributes.compliance.creationDate? 'add': 'remove';
    this.rappid.addStepDone(transition.linkId, type, link.attributes.compliance.creationDate);
  }

  closeLoaderTransition(personzalizedNotification:any){
    this.loader.closeLoader('add-undo-transition-process-modal');
    if(personzalizedNotification){
        this.modalService.adviceModal(this.translate.instant('states-process.personalized-notification-ok'), 'info:accept:check-round');
    }else{
        this.modalService.adviceModal(this.translate.instant('states-process.not-personalized-ok'), 'info:accept:check-round');
    }
  }

  callDeleteTransition(state:any, personzalizedNotification:boolean = false){
    let that = this.processModal;
    this.loader.openLoader('add-undo-transition-process-modal');
    this.apiService.delete('processesdata/' + that.config.conceptObjectId + '/last/' + that.lastTransition.transitionId, state).then(
      (data:any) => {
        that.config.current = data
        if (data && data.current) {
            if (this.updateOwner){
              this.updateOwner.emit({ data: data });
            }
            this.setDiagramState(that.current, false, false);
            delete that.lastTransition.data;
            this.rappid.addStepDone(that.lastTransition.linkId, 'remove');
            if (that.lastTransition.sourceId !== that.initialState.stateId) {
                that.current = that.statesById[that.lastTransition.sourceId];
                that.lastTransition = that.process.transitions.find((transition:any) => { return transition.targetId === that.current.stateId && transition.data; });
            } else {
                that.current = that.initialState;
                that.lastTransition = null;
            }
            if (that.current) {
                that.nextTransitions = that.transitionsBySource[that.current.stateId];
                this.setDiagramState(that.current, true, true);
            }
            this.closeLoaderTransition(personzalizedNotification);
        } else {
            this.loader.closeLoader('add-undo-transition-process-modal');
            throw 'DeleteLast method did not return any data.';
        }
      },(errorData:any)=>{
        this.apiError(errorData)
      }
    );
  }

  next() {
    let that = this.processModal;
    that.nextStateFieldList = [];
    that.nextTransitions.forEach((transition:any) => {
      that.nextStateFieldList.push({
        listValueId: transition.transitionId,
        text: that.statesById[transition.targetId].name
      })
    });
    that.nextStateSelected = that.nextStateFieldList.length === 1?that.nextStateFieldList[0].listValueId : null;
    that.view = 'next';
  }

  addTask() {
    let task: any = {
      processId: this.processModal.process.processId,
      title: this.translate.instant('process.general-task.default-title-prefix') + ' ' + this.configuration.conceptTitle,
      description: '',
      expirationUnits: null,
      expirationTimeUnit: 'D'
    }
    this.modalService.taskModal(task, this.configuration.concept).subscribe((result:any)=>{
      if (result && result.result === 'ok') {
        this.processModal.process.hasTask = !!result.task.processGeneralTaskId;
        if (this.updateOwner) this.updateOwner.emit({ data: { hasOps: true } });
      }
    });
  }

  addTransition() {
    let that = this.processModal;
    that.errorText = null;
    that.nextStateFieldInfo.error = null;

    if (!that.nextStateSelected) {
        that.nextStateFieldInfo.error = this.translate.instant('common.error-required');
    }
    if (!that.nextStateFieldInfo.error) {
        let transitionData:any = {
            transitionId: that.nextStateSelected,
        };
        let processTransition:any = this.searchProcessSelected(that.nextStateSelected, that.nextTransitions);

        if(this.commonUtilsService.checkResponsibles( that.config.summary.current.processTaskResponsibles,  this.context.user.userId)){
          this.modalService.adviceModal(this.translate.instant('states-process.not-responsible'), 'info:question:question')
            .subscribe((result:any) => {
              if(result.result==='ok'){
                this.notResponsibleAcceptChange(processTransition, transitionData);
              }
            });
       }else{
           this.notResponsibleAcceptChange(processTransition, transitionData);
       }
    }

  }

  notResponsibleAcceptChange(processTransition:any, transitionData:any){
    if(this.checkArchiveConcept('next')){
        this.modalService.adviceModal(this.translate.instant('states-process.question-concept-archived'), 'info:question:question')
        .subscribe((result:any)=>{
          if(result.result === 'ok'){
           this.processTransition(processTransition, transitionData);
          }
        });
    }else{
        this.modalService.adviceModal(this.translate.instant('states-process.question-task-process-start') +' '+ this.translate.instant('states-process.question-task-process-end'), 'info:question:question')
        .subscribe((result:any)=>{
           if(result.result === 'ok'){
            this.processTransition(processTransition, transitionData);
           }
        });
    }
  }

  processTransition(processTransition:any, transitionData:any){
    if(processTransition && processTransition.hasAlertCustom){
      this.configureTransition(processTransition, transitionData, 'add');
    }else{
      this.callAddTransition(transitionData);
    }
  }

  checkArchiveConcept(type:any){
    let that = this.processModal;
    for(let i = 0; i < that.config.summary[type].length; i++){
        let item = that.config.summary[type][i];
        if(item.transitionId === that.nextStateSelected && item.archiveConceptObject){
            return true;
        }
    }
    return false;
  }

  searchProcessSelected(transitionId:any, list:any){
    let that = this.processModal;
    for(let i = 0; i < list.length; i++){
        if(list[i].transitionId === transitionId){
            for(let j = 0; j < that.states.length; j++){
                if(list[i].targetId === that.states[j].stateId){
                    return that.states[j];
                }
            }
        }
    }
  }

  printAction(){
    this.print.printContent('legal-document-print', this.processModal.selectedReference.text);
  }

  handleDismiss(){
    this.activeModal.close({result:'cancel'});
  }
}
