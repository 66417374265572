import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckValidProductIdGuard implements CanActivate {
  constructor(private router: Router,
    private context: ContextFactory) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let productId: any = localStorage.getItem('productId');
        productId = productId?productId:this.context.settings.defaultProduct;
        let canCreateClient = productId && productId === 'blanqueo'?true:false;
        if(!canCreateClient){
          this.router.navigate(['dashboard']);
        }else{
          localStorage.setItem('productId', productId);
        }
        return canCreateClient;
    }

}
