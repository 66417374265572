import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import moment from 'moment';
import XLSX from 'xlsx';
import { ApiServiceService } from '../api-service/api-service.service';
import { DateConverterService } from '../date-converter/date-converter.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';

@Injectable({
  providedIn: 'root'
})
export class ExportServiceService {
  constructor(private apiProperties: ApiPropertiesFactory,
    private loader:LoaderFactory,
    private apiService:ApiServiceService,
    private dateConverterService:DateConverterService,
    private translate:AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private globalCfg: GlobalCfgFactory
  ) { }

  toPdf(config: any) {
      if(!config.noOpenLoader){
        this.loader.openLoader('export-file');
      }
      config.contentHTML = config.contentHTMLConfigurated?config.contentHTML:this.configureBodyPdf(config.htmlPrint);
      let formData = {
          nameDocument: config.nameDocument,
          contentHTML: config.contentHTML,
          useChromium: config.useOldVersion?false:true, //Para que coja la versión nueva de la herramienta del pdf
          hideMediaPrint: config.showMediaPrint?false:true, //Para que no coja las clases de @media print de los css
          pageOrientation: config.landscape?'landscape':'',//Orientación de la pagina landscape = horizontal/apaisajada
          browserWidth: config.browserWidth //Configurar ancho de página, si no se envía nada por defecto será 1200px
      }
      this.apiService.postBlob('utils/get-pdf', formData)
        .then((data:any) => {
            data.fileName = config.nameDocument?config.nameDocument:data.fileName;
            if(config.uploadFile){
                this.uploadFile(config, data.blob, config.nameDocument);
            }else{
                this.loader.closeLoader(['export-file']);
                data.name = data.fileName;
                config.successCallback(data, data.blob);
            }
        }, (error:any) => {
            console.log(error);
            this.loader.closeLoader(['export-file']);
        });
  }

  configureBodyPdf(bodyPrint: string){
      bodyPrint = bodyPrint.replace(/ng-hide/g, 'common__hide');
      let locationHost = window.location?.origin?.indexOf('localhost') !== -1?'https://centinelappd.lefebvre.es':window.location.origin;
      let stylesCss: any = document.querySelector('link[rel=stylesheet][href^=styles][href*=css]');
      let stylesCssHref: any = window.location?.origin?.indexOf('localhost') !== -1?'https://centinelappd.lefebvre.es/styles.eaff2c58a50480c7.css':stylesCss.href;
      // let stylesCssHref: any = window.location?.origin?.indexOf('localhost') !== -1?'https://centinela-pruebas.000webhostapp.com/css/styles.css':stylesCss.href;
      // let stylesCssHref: any = window.location?.origin?.indexOf('localhost') !== -1?stylesCss.href.substring(window.location.origin.length, stylesCss.href.length):stylesCss.href;
      let bodySkin = this.customLanding.isLanding()?'landing-custom':this.globalCfg.skin;
      let htmlToPDF = '<!DOCTYPE html>'
                      +    '<html>'
                      +        '<head>'
                      +            '<meta charset="UTF-8">'
                      +            '<meta name="viewport" content="width=device-width, initial-scale=1.0">'
                      +            '<meta http-equiv="X-UA-Compatible" content="ie=edge">'
                      +            '<base href="/" />'
                      +            '<link rel="stylesheet" href="'+locationHost+'/assets/css/normalize.css">'
                      +            '<link rel="stylesheet" href="'+locationHost+'/assets/css/fonts.css">'
                      +            '<link rel="stylesheet" href="'+locationHost+'/assets/css/bootstrap.css">'
                      +            '<link rel="stylesheet" type="text/css" href="https://assets.lefebvre.es/media/lf-font/lf-font.css">'
                      +            '<link rel="stylesheet" type="text/css" href="https://assets.lefebvre.es/media/css/fonts-mttmilano.css">'
                      +            '<link rel="stylesheet" type="text/css" href="'+stylesCssHref+'" />'
                      // +            '<link rel="stylesheet" type="text/css" href="'+locationHost+'/styles.css" />'
                      +        '</head>'
                      +        '<body class="compliance__'+bodySkin+'">'
                      +               bodyPrint
                      +        '</body>'
                      +    '</html>';
      return htmlToPDF;
  }

  toXls(config:any) {
      this.loader.openLoader('export-file');
      let workbook = XLSX.utils.book_new();
      let sheetName = config.title;
      if(config.title.length > 31){
          sheetName = config.title.substring(0, 31);
      }
      workbook.SheetNames.push(sheetName);
      workbook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(this.buildExcelTable(config.columns, config.list));

      let blob = new Blob(
          [new Uint8Array(XLSX.write(workbook, { bookType: "xlsx", type: "array" }))],
          { type: "application/octet-stream" }
      );

      if(config.uploadFile){
      // if (config.listType != 'concept' && config.listType != 'tracking') {
          this.uploadFile(config, blob, config.fileName + '.xlsx');
      }else{
          config.successCallback(config, blob);
          this.loader.closeLoader(['export-file']);
      }
  }

  buildExcelTable(columns:any, list:any) {
      let headerRow = [];
      for (let key in columns) {
          headerRow.push(columns[key]);
      }

      let table = [headerRow];
      for (let i = 0; i < list.length; i++) {
          let item = list[i];
          let row = [];
          for (let key in columns) {
              let itemValue = item[key];
              switch (typeof itemValue) {
                  case 'string':
                      if (moment(itemValue, moment.ISO_8601, true).isValid() && key != "reference") {
                          itemValue = this.dateConverterService.convertDate(new Date(itemValue));
                      }
                      break;
                  case 'boolean':
                      itemValue = (itemValue ? this.translate.instant('common.yes') : this.translate.instant('common.no'));
                      break;
              }
              row.push(itemValue);
          }
          table.push(row);
      }

      return table;
  }

  uploadFile(config:any, blob:any, fileName:string) {
      let formData = new FormData();
      formData.append('file', blob, fileName);
      let url: any = config.apiUrl?config.apiUrl:'documentobjects/upload/' + config.conceptObjectId;
      this.apiService.attachFile(url, formData).then((data:any) => {
          if (config.successCallback) config.successCallback(data, blob);
      }, (error:any) => {
          if (config.errorCallback) config.errorCallback(error, blob);
      }).finally(() => {
          this.loader.closeLoader(['export-file']);
      });
  }
}
