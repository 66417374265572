import { Injectable } from '@angular/core';

import $ from 'jquery';
// import moment from 'moment';
// import { cloneDeep } from "lodash";
import { defer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { SnapShotsService } from '@shared/services/snap-shots/snap-shots.service';
import { ApiServiceService } from '../api-service/api-service.service';


@Injectable({
  providedIn: 'root'
})
export class ConceptUtilsService {
  fieldContainerId: any = this.route.snapshot.params['fieldContainerId']

  constructor(private route: ActivatedRoute,
    public context: ContextFactory,
    private conceptState: ConceptStateFactory,
    private apiProperties: ApiPropertiesFactory,
    private loader: LoaderFactory,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private snapshotsService: SnapShotsService,
    private apiService: ApiServiceService) { }

  exitConcept(configuration:any): any {
    let userPublic:boolean = configuration.userPublic?configuration.userPublic:false;
    return new Promise((resolve:any, reject:any)=>{
        if(!((this.conceptState.getAmount() || this.conceptState.getProcess()))){
            resolve();
        }else{
            this.modalService.adviceModal(configuration.textModal, configuration.typeModal).subscribe(
                (result:any) => {
                  switch (result.result) {
                      case 'cancel':
                          reject();
                          break;
                      case 'save-navegate':
                      case 'save-and-continue':
                      case 'generate-and-save':
                      case 'save-and-share':
                      case 'save-exit-tab':
                      case 'save-exit':
                          let errors = this.commonUtilsService.checkRequiredFields(configuration.concept, userPublic)
                          if(errors.validateErrors){
                              this.modalService.adviceModal(configuration.noValidateFields, 'error:accept:warning');
                          }else if (errors.commonErrors && errors.blockedErrors) {
                              this.modalService.adviceModal(configuration.blockedFields, 'error:accept:warning');
                          }else if(errors.commonErrors){
                              this.modalService.adviceModal(configuration.textValidateField, 'error:question:mandatory-field')
                                .subscribe((result:any)=>{
                                  if(result.result==='ok'){
                                    this.acceptChanges(configuration).then(function(){
                                      resolve(result.result);
                                    })
                                  }
                                });
                          } else {
                              this.acceptChanges(configuration).then(function(){
                                  resolve(result.result);
                              })
                          }
                          break;
                      case 'discard-navegate':
                      case 'without-save':
                      case 'discard-and-share':
                      case 'discard-exit':
                          resolve(result.result);
                          break;
                  }
                })
        }
    })
  }

  configureLoader(selected: any){
      switch(selected){
          case 'general':
              return 'landing-page-on-init';
          case 'task':
              return 'charge-' + selected + '-concept-list';
          case 'historic':
          case 'alerts':
              return 'charge-' + selected + '-concept-list';
          default:
              return 'documentation-list-on-init';
      }
  }

  acceptChanges(configuration:any) {
      return new Promise((resolveAcceptChanges:any)=>{
          let typeLoader:string = configuration.loader? configuration.loader : 'concept-utils-loader'

          this.loader.openLoader(typeLoader);

          this.saveConceptFields(configuration.concept).
            then(()=>{
              this.saveSnapshot(configuration.concept);
              this.loader.closeLoader(['concept-utils-loader']);

              resolveAcceptChanges();
          });
      });
  }

  saveConceptFields(concept:any){
      let url = 'conceptdata';
      let id = concept.conceptObjectId;
      if(this.fieldContainerId){
          url = 'conceptdata/'+concept.conceptObjectId;
          id = this.fieldContainerId;
      }
      return this.apiService.patch(url, id, concept.evaluatedFields)
          .then((data:any)=> {
              this.conceptState.removeAll();
              this.loader.closeLoader(['save-exit-concept']);
              return data;
          }, (errorData:any)=>{})
  }

  saveSnapshot(concept:any) {
      if (this.fieldContainerId) {
          let historicJson = this.snapshotsService.getConceptJson(concept);
          this.snapshotsService.saveForConcept(concept.conceptObjectId, historicJson) //, historicHtml
      }
  }

  addProtectionData(selectedLanguages:any){
      let protectionData = this.getProtectionDataLanguage(selectedLanguages, sessionStorage.getItem('selectLanguage'));
      if(!protectionData){
          protectionData = this.getProtectionDataLanguage(selectedLanguages, this.apiProperties.defaultLanguage);
      }
      return protectionData;
  }

  getProtectionDataLanguage(selectedLanguages:any, appLanguage:any){
      for(let i = 0; i < selectedLanguages.length; i++){
          let language = selectedLanguages[i];
          if(language.language === appLanguage){
              return language;
          }
      }
  }

  checkModalLocked(configuration: any){
    return new Promise((resolve:any, reject:any)=>{
      if(configuration.saveConcept && configuration.concept.conceptNamedKey === 'Cliente' && configuration.concept.productId === 'blanqueo' && configuration.concept.addToScoring.valueRisk === 4 && configuration.concept.clientStateId === 1){
          configuration.concept.clientStateId = 2;
          this.loader.closeLoader([configuration.loader]);
          this.modalService.adviceModal(configuration.literalQuestion, 'error:accept:alert')
            .subscribe((result:any)=>{
              if(result.result==='ok'){
                this.loader.openLoader(configuration.loader);
                resolve();
              }

            });
      }else{
        resolve();
      }
    })
  }
}
