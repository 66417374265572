import * as d3 from 'd3'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Component({
  selector: 'organization-chart',
  templateUrl: './organization-chart.component.html'
})
export class OrganizationChartComponent implements OnChanges, OnInit {
  skin: string = this.globalCfg.skin;
  organizationChart:any = {
    graph:{
      width: 1900,
      height: 1000,
      scale: 1,
      margin: {
          top: 20,
          right: 90,
          bottom: 30,
          left: 90
      },
      nodeW: 95,
      nodeH: 25,
      spaceX: 125,
      spaceY: 20,
      i: 0,
      x: 1900/2,
      y: 1000/2,
      zoomScale: 1
    }

  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private documentActions: DocumentActionsService) { }
  @Input() client: any = null;
  @Input() filials: any = null;
  @Output() goBack = new EventEmitter();

  ngOnInit(): void {
    // let that: any = this.organizationChart;
    // that.graph.x = that.graph.width / 2;
    // that.graph.y = that.graph.height / 2
    // that.graph.zoomScale = that.graph.scale;
  }

  ngOnChanges(): void {
    if(this.filials){
      this.initGraph();
    }
  }

  initGraph(){
    let graph:any = this.organizationChart.graph
    graph.svg = d3.select(".organization-chart__block").append("svg")
        .attr("width", graph.width)
        .attr("height", graph.height)

    graph.g = graph.svg.append('g')
        .attr('class', 'organization-chart__graph-container')
        .attr('transform',"translate("+(graph.x)+","+(graph.y)+"), scale("+graph.zoomScale+")");

    graph.zoom = d3.zoom()
        .scaleExtent([0.3, 3])
        .on("zoom", this.zoomWithButton);


    graph.svg.call(graph.zoom)
        .on("wheel.zoom", null)
        .on("mousedown.zoom", null)
        .on("dblclick.zoom", null)
    // declares a tree layout and assigns the size
    graph.treemap = d3.tree().nodeSize([graph.nodeW + graph.spaceX,graph.nodeH + graph.spaceY]);

    // Assigns parent, children, height, depth
    graph.root = d3.hierarchy(this.filials, (d:any)=> { return d.children; });
    graph.root.x0 = graph.height / 2;
    graph.root.y0 = 0;

    // Collapse after the second level
    // graph.root.children.forEach(_collapse);

    this.update(graph.root, false, true);
    setTimeout(()=>{
        this.centerGraph();
    })

  }

  zoomWithButton(type:any) {
    let graph:any = this.organizationChart.graph
    if(type === 'increment'){
        graph.width += 400
        graph.height += 400

        if(graph.zoomScale < 3){
            graph.zoomScale += 0.3
        }
    }else{
        if(graph.width <= 1900){
            graph.width -= 400
            graph.height -= 400
        }
        if(graph.zoomScale > 0.6){
            graph.zoomScale -= 0.3
        }else if(graph.zoomScale < 0.6){
            graph.zoomScale = 0.6
        }
    }
    graph.svg.attr('width', graph.width).attr('height', graph.height)
    graph.x = graph.width / 2;
    graph.y = graph.height * .16
    graph.g.attr("transform","translate("+graph.x+","+graph.y+"), scale("+graph.zoomScale+")" ).transition().duration(750);
    this.update(graph.nodes, false, false);
  }

  update(source:any, centerMySelf:any = false, init:any = false) {
      let that: any = this.organizationChart
      let treeData : any = that.graph.treemap(that.graph.root);
      that.graph.nodes = treeData.descendants(),
      that.graph.links = treeData.descendants().slice(1);
      that.graph.nodes.forEach((d:any)=>{ d.y = d.depth * 120});
      let node : any = that.graph.g.selectAll('.node')
          .data(that.graph.nodes, (d:any)=> {return d.id || (d.id = ++that.graph.i); });
      let nodeEnter : any = node.enter()
          .append('g')
          .attr('class','node')
          .attr('transform',(d:any)=>{
              return "translate("+(d.x - that.graph.nodeW) +","+(d.y - that.graph.nodeH)+")";
          })
          .attr('fill', '#F4F4F4')
          .on('click',(d:any)=>{
              this.clickNode(d, this)
          })
          // .on('click',this.clickNode)
          .append('foreignObject')
          .on('mouseover', this.showTooltip)
          .on('mouseout', this.hideTooltip)
          .attr('class','organization-chart__node-block')
          .append('xhtml:div')
          .attr('class', (d:any)=>{
              let classStyle : string = "organization-chart__node-item";
              if(d.data.clientId === this.client.clientId){
                  classStyle+= " organization-chart__node-item--parent organization-chart__node-item--active"
              }
              return classStyle;
          })
          .html((d:any)=>{
              let html : any = '';
              if(d.data.children.length){
                  let icon:any = d.collapse?'lf-icon-add-full' : 'lf-icon-less-full';
                  html+='<i class="'+icon+' organization-chart__node-collapse-icon node-icon-'+d.data.clientId+'"></i>';
              }
              let percentage : any = d.data.percentage
              if(percentage && percentage !== null){
                  percentage = ''+percentage;
                  percentage = this.commonUtilsService.setLocaleNumberFormat(percentage)
              }

              return  html +  '<p class="organization-chart__node-inner-text">'+
                                  '<span id="node-text'+d.data.clientId+'">'+d.data.name+'</span>'+
                              '</p>'+
                              '<p class="organization-chart__node-inner-percentage '+(percentage === null?'organization-chart__node-inner-percentage--none':'')+'">'+
                                  '<span id="node-text'+d.data.clientId+'">'+percentage+' %</span>'+
                              '</p>'+
                              '<div class="organization-chart__node-tooltip tooltip fade bottom" id="node-tooltip-'+d.data.clientId+'">'+
                                  '<div class="tooltip-arrow"></div>'+
                                  '<div class="tooltip-inner">'+d.data.name+'</div>'+
                              '</div>';

          });

      let nodeUpdate : any = nodeEnter.merge(node);
      nodeUpdate.transition()
          .duration(750)
          .attr("transform", (d:any)=> {
              return "translate(" + (d.x - that.graph.nodeW) +","+(d.y - that.graph.nodeH)+ ")";
          });

      node.exit().transition()
          .duration(750)
          .attr("transform", (d:any)=> {
              return "translate(" + (source.x - that.graph.nodeW) + "," + (source.y - that.graph.nodeH) + ")";
          })
          .remove();

      let link : any = that.graph.g.selectAll('path.link')
        .data(that.graph.links, (d:any)=> { return d.id; });


      let linkEnter : any = link.enter().insert('path', "g")
          .attr("class", "link")
          .attr('class','link')
          .attr("fill", "none")
          .attr("stroke-width", 2)
          .attr('stroke-dasharray', 1.5)
          .attr('stroke', (d:any)=>{
              return this.skin ==='dark-blue'? '#001978' : '#27344E';
          })
          .attr('d', (d:any)=>{
              let o : any = {x: source.x0, y: source.y0}
              return this.diagonal(o, o)
          });

      let linkUpdate : any = linkEnter.merge(link);
      linkUpdate.transition()
          .duration(750)
          .attr('d', (d:any)=>{ return this.diagonal(d, d.parent) });

      link.exit().transition()
          .duration(750)
          .attr('d', (d:any)=> {
              let o : any =   {x: source.x, y: source.y}
              return this.diagonal(o, o)
          })
          .remove();


      that.graph.nodes.forEach((d:any)=>{
          d.x0 = d.x;
          d.y0 = d.y;
      });

      if(centerMySelf) {
          let x:any;
          let y:any;

          that.graph.nodes.forEach((d:any)=> {
              if (d.data.clientId === this.client.clientId) {
                  x = d.x;
                  y = d.y;
              }

          });

          // normalize for width/height
          let new_x:any = (-x + ( that.graph.x));
          let new_y:any = (-y + ( that.graph.height * .26));

          // move the main container g

          that.graph.zoomScale = 0.6;
          that.graph.g.attr("transform", "translate(" + new_x + "," + new_y + "), scale("+ that.graph.zoomScale+")")
          setTimeout(function(){
              $('.organization-chart__block')[0].scrollTop = 0;
              $('.organization-chart__block')[0].scrollLeft = 0
          })
      }
      if(init){
          this.reduceGraphZoom();
      }
  }

  reduceGraphZoom(){
      let d3Select: any = d3.select('.organization-chart__block')
      if(d3Select.select('svg').node().getBBox().width > 878 || d3Select.select('svg').node().getBBox().height > 459){
          this.organizationChart.graph.scale -= 0.1;
          this.organizationChart.graph.zoomScale = this.organizationChart.graph.scale;
          this.organizationChart.graph.g.attr("transform","translate("+this.organizationChart.graph.x+","+this.organizationChart.graph.y+"), scale("+this.organizationChart.graph.zoomScale+")" );
          this.reduceGraphZoom();
      }

  }

  diagonal(s:any, d:any) {
      return 'M '+ s.x +' '+ s.y +' '+
                  s.x +' '+ ((s.y + d.y) / 2) +' '+
                  d.x +' '+ ((s.y + d.y) / 2)+' '+
                  d.x+' '+d.y;
  }

  clickNode(d:any, thisComponent: any) {
      if (d.children) {
          d._children = d.children;
          d.collapse = true;
          d.children = null;
      } else {
          d.children = d._children;
          d.collapse = false;
          d._children = null;
      }
      if(d.collapse === true){
          $('.node-icon-'+d.data.clientId).removeClass('lf-icon-less-full');
          $('.node-icon-'+d.data.clientId).addClass('lf-icon-add-full');
      }else if(d.collapse === false){
          $('.node-icon-'+d.data.clientId).addClass('lf-icon-less-full');
          $('.node-icon-'+d.data.clientId).removeClass('lf-icon-add-full');
      }
      thisComponent.update(d);
  }

  centerGraph(){
    let that: any = this.organizationChart
      that.graph.width = 1900;
      that.graph.height = 1000;
      that.graph.x = that.graph.width / 3;
      that.graph.y = that.graph.height * .16
      that.graph.zoomScale = that.graph.scale;
      that.graph.g.attr("transform","translate("+that.graph.x+","+that.graph.y+"), scale("+that.graph.zoomScale+")" ).transition().duration(750);
      this.update(that.graph.root);
      setTimeout(function(){
          $('.organization-chart__block')[0].scrollTop = 0;
          $('.organization-chart__block')[0].scrollLeft = 0
      })
  }

  showMySelf() {
      this.organizationChart.graph.root.children.forEach((child:any)=>{
        this.expand(child, this)
      });
      this.update(this.organizationChart.graph.root, true);
  }

  expandAll(){
    let that: any = this.organizationChart
    if(that.graph.root._children) {
        that.graph.root.children = that.graph.root._children
        that.graph.root.collapse = false;
        that.graph.root._children = null
        if($('.node-icon-'+that.graph.root.data.clientId).hasClass('lf-icon-add-full')){
            $('.node-icon-'+that.graph.root.data.clientId).removeClass('lf-icon-add-full');
            $('.node-icon-'+that.graph.root.data.clientId).addClass('lf-icon-less-full');
        }
    }

    if(that.graph.root.children){
        that.graph.root.children.forEach((child:any)=>{
          this.expand(child, this)
        })
        this.update(that.graph.root);
    }
  }

  expand(d:any, that:any){
    if(d._children) {
        d.children = d._children
        d.collapse = false;
        d._children = null
        if($('.node-icon-'+d.data.clientId).hasClass('lf-icon-add-full')){
            $('.node-icon-'+d.data.clientId).removeClass('lf-icon-add-full');
            $('.node-icon-'+d.data.clientId).addClass('lf-icon-less-full');
        }
    }
    if(d.children){
        d.children.forEach((child:any)=>{
          that.expand(child, that)
        })
    }
  }


  showTooltip(d:any){
      let element: any = $('#node-text'+d.data.clientId)
      if(element.width() > 185){
          $('#node-tooltip-'+d.data.clientId).removeClass('out');
          $('#node-tooltip-'+d.data.clientId).addClass('in');
      }
  }

  hideTooltip(d:any){
      $('#node-tooltip-'+d.data.clientId).removeClass('in');
      $('#node-tooltip-'+d.data.clientId).addClass('out');
  }

  print(){
      let that: any = this.organizationChart
      let svgElement : any = this.getSVGString(that.graph.svg.node());
      let imgsrc : any = 'data:image/svg+xml;base64,'+ btoa( unescape( encodeURIComponent( svgElement ) ) ); // Convert SVG string to data URL
      let canvas : any = document.createElement("canvas");
      let context:any = canvas.getContext("2d");
      canvas.width = that.graph.width/2.5;
      canvas.height = that.graph.height/2.5;
      let image : any = new Image();
      image.onload = ()=> {
          context.clearRect(0, 0, that.graph.width / 1.1, that.graph.height / 1.2);
          context.drawImage(image, 0, 0, that.graph.width / 1.1, that.graph.height / 1.2);
          canvas.toBlob((blob:any)=>{
              let formData : any = new FormData();
              formData.append('my-file', blob, 'filename.png');
              this.apiService.attachFile('clients/treeimagepdf/'+this.client.clientId, formData )
              .then((data:any)=>{
                  let printHtmlConcept : any = {
                      viewform: true,
                      extension: 'PDF'
                  };
                  that.clientIdPdf = data
                  setTimeout(()=>{
                      this.documentActions.doAction('open', printHtmlConcept);
                  })
              }, ()=>{})
          });
      };
      image.src = imgsrc;
  }

  getSVGString( svgNode:any ) {
      svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
      let cssStyleText : any = this.getCSSStyles(svgNode);
      this.appendCSS( cssStyleText, svgNode );
      let serializer : any = new XMLSerializer();
      let svgString : any = serializer.serializeToString(svgNode);
      svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
      svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
      return svgString;
  }

  getCSSStyles(parentElement:any) {
    let selectorTextArr : any = [];

    // Add Parent element Id and Classes to the list
    selectorTextArr.push( '#'+parentElement.id );
    for (let c = 0; c < parentElement.classList.length; c++)
            if ( !this.contains('.'+parentElement.classList[c], selectorTextArr) )
                selectorTextArr.push( '.'+parentElement.classList[c] );

    // Add Children element Ids and Classes to the list
    let nodes : any = parentElement.getElementsByTagName("*");
    for (let i = 0; i < nodes.length; i++) {
        let id : any = nodes[i].id;
        if ( !this.contains('#'+id, selectorTextArr) )
            selectorTextArr.push( '#'+id );

        let classes : any = nodes[i].classList;
        for (let c = 0; c < classes.length; c++)
            if ( !this.contains('.'+classes[c], selectorTextArr) )
                selectorTextArr.push( '.'+classes[c] );
    }

    // Extract CSS Rules
    let extractedCSSText : any = "";
    for (let i = 0; i < document.styleSheets.length; i++) {
        let s : any = document.styleSheets[i];

        try {
            if(!s.cssRules) continue;
        } catch( e:any ) {
                if(e.name !== 'SecurityError') throw e; // for Firefox
                continue;
            }

        let cssRules:any = s.cssRules;
        for (let r = 0; r < cssRules.length; r++) {
            if ( this.contains( cssRules[r].selectorText, selectorTextArr ) )
                extractedCSSText += cssRules[r].cssText;
        }
    }
    return extractedCSSText;
  }

  contains(str:any,arr:any) {
      return arr.indexOf( str ) === -1 ? false : true;
  }

  appendCSS( cssText:any, element:any ) {
    let styleElement : any = document.createElement("style");
    styleElement.setAttribute("type","text/css");
    styleElement.innerHTML = cssText;
    let refNode : any = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore( styleElement, refNode );
  }
}
