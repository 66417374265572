<div class="new-evaluation">
  <div class="common__modal-header-block">
      <i [class]="'lf-'+newEvaluation.headerIcon+' common__icon-header'" ></i>
      <span class="common__text-header">{{newEvaluation.title}}</span>
      <i class="lf-icon-close common__icon-close-modal"
          (click)="handleDismiss()"></i>
  </div>
  <wizard-steps *ngIf="newEvaluation.configuredSteps && newEvaluation.steps.length !== 1"
    [steps]="newEvaluation.steps"
    [state]="newEvaluation.wizardState">
  </wizard-steps>
  <p class="corporative-information-modal__information-mail corporative-information-modal__information-mail--new-channel"
    *ngIf="skin==='steel-blue' && newEvaluation.wizardState >= 4 && configuration.selectedProduct.productId ==='blanqueo'"
    [afTranslate]="'new-evaluation.transaction-not-verify'"></p>
  <form class="new-evaluation__evaluation-form"
      [ngClass]="{'new-evaluation__evaluation-form--product-state': newEvaluation.wizardState === 0,
          'new-evaluation__evaluation-form--second-state':newEvaluation.wizardState===1,
          'new-evaluation__evaluation-form--form-state': newEvaluation.wizardState >= 3,
          'new-evaluation__evaluation-form--roles': newEvaluation.productHasAnyRoles && newEvaluation.wizardState === 3}"
      [ngSwitch]="newEvaluation.wizardState"
      [ngShow]="newEvaluation.configuredSteps">
      <div *ngSwitchCase="'1'"
        [ngClass]="{'new-evaluation__block-clients': (newEvaluation.repeatedSelectedClient || newEvaluation.clientSelector.noClientSelected) && newEvaluation.legalFormInfo}">
          <field-type-clients-selector
              [field]="newEvaluation.clientSelector"
              [productId]="newEvaluation.productId"
              [preSelectItem]="newEvaluation.createEvaluation.clientId"
              [chargedList]="newEvaluation.clients"
              (itemSelected)="clientSelected($event.value)">
          </field-type-clients-selector>
          <field-type-list-simple *ngIf="newEvaluation.legalFormInfo"
              [field]="newEvaluation.legalForm"
              [preSelectItem]="newEvaluation.createEvaluation.legalFormId"
              [listValues]="newEvaluation.legalFormsList"
              (itemSelected)="itemSelected($event.newValue, 'legalFormId')">
          </field-type-list-simple>
          <div class="establish-pattern__error-block"
              [ngShow]="newEvaluation.repeatedSelectedClient">
              <i class="lf-icon-warning establish-pattern__icon-error"></i>
              <p class="establish-pattern__text-error"
                  [afTranslate]="'new-evaluation.alert-repeated-client'"></p>
              <i class="lf-icon-close establish-pattern__icon-close"
                  (click)="newEvaluation.repeatedSelectedClient = !newEvaluation.repeatedSelectedClient"></i>
          </div>
          <div class="establish-pattern__error-block"
              [ngShow]="newEvaluation.clientSelector.noClientSelected">
              <i class="lf-icon-warning establish-pattern__icon-error"></i>
              <p class="establish-pattern__text-error"
                  [afTranslate]="'new-evaluation.assign-organization'">_Es necesario asignar la nueva implantación a un organización en concreto</p>
              <i class="lf-icon-close establish-pattern__icon-close"
                  (click)="newEvaluation.clientSelector.noClientSelected = !newEvaluation.clientSelector.noClientSelected"></i>
          </div>
      </div>
      <div *ngSwitchCase="'2'"
          [ngHide]="newEvaluation.type === 'edit'">
          <field-type-text-simple [field]="newEvaluation.evaluationName"
              [preSelectItem]="newEvaluation.createEvaluation.name"
              (itemSelected)="itemSelected($event.newValue, 'name')">
          </field-type-text-simple>
          <field-type-text-multiline [field]="newEvaluation.evaluationDescription"
          [preSelectItem]="newEvaluation.createEvaluation.description"
          (itemSelected)="itemSelected($event.newValue, 'description')">
        </field-type-text-multiline>
      </div>
      <div *ngSwitchDefault>
        <div *ngIf="newEvaluation.productHasAnyRoles && newEvaluation.wizardState === 3">
          <field-type-contacts-multiple *ngFor="let role of newEvaluation.roles"
              [field]="role"
              [valueId]="newEvaluation.createEvaluation.productId"
              (sendContacts)="receiveContacts($event.contacts, role)">
          </field-type-contacts-multiple>
        </div>
        <div *ngIf="!(newEvaluation.productHasAnyRoles && newEvaluation.wizardState === 3)">
          <div *ngFor="let groupProperty of newEvaluation.groupPropertiesList; let i = index">
            <div *ngIf="newEvaluation.wizardState - newEvaluation.propertiesIndex  === i"
                class="new-evaluation__scroll-form">
                <div class="new-evaluation__block-properties"
                    *ngFor="let property of groupProperty">
                    <field-type-bool *ngIf="property.isBoolean && property.showWithProperty"
                        [preSelectItem]="property.preSelect"
                        [field]="property.listField"
                        (itemSelected)="selectBooleanOn($event, property)">
                    </field-type-bool>
                    <div *ngIf="!property.isText && !property.isBoolean && property.showWithProperty">
                      <field-type-list-multiple *ngIf="!property.isExtendedList && property.isMultiple"
                          [field]="property.listField"
                          [preSelectItem]="property.preSelect"
                          [listValues]="property.listValues"
                          (itemSelected)="propertySelectedOn($event, property)">
                      </field-type-list-multiple>
                      <field-type-list-simple *ngIf="!property.isExtendedList && !property.isMultiple"
                          [field]="property.listField"
                          [preSelectItem]="property.preSelect"
                          [listValues]="property.listValues"
                          (itemSelected)="propertySelectedOn($event, property)">
                      </field-type-list-simple>
                      <field-type-list-extended *ngIf="property.isExtendedList"
                          [field]="property.listField"
                          [preSelectItem]="property.preSelect"
                          [listValues]="property.listValues"
                          (itemSelected)="propertySelectedOn($event, property)">
                      </field-type-list-extended>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
  </form>
  <div class="new-evaluation__buttons-block"
      [style.margin-top]="marginTopSize()">
      <button type="reset"
          class="common__button common__button--clean"
          [ngShow]="newEvaluation.wizardState > 0"
          (click)="reset()"
          [afTranslate]="'common.clean'">Limpiar</button>
      <button  class="common__button common__button--clean common__button--separating-margin"
          [ngShow]="(newEvaluation.wizardState > 1 && newEvaluation.type==='new') || (newEvaluation.wizardState > 3 && newEvaluation.type==='edit')"
          (click)="goBack()"
          [afTranslate]="'common.previous'">Anterior</button>
      <button type="submit"
          class="common__button common__button--active common__button--separating-margin"
          [ngClass]="{'common__button--disabled':newEvaluation.disabledNext }"
          (click)="goNext()">{{newEvaluation.activeText}}</button>
  </div>
</div>
