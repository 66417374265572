<div class="upload-report">
    <div class="common__modal-header-block">
        <i class="lf-icon-up-file common__icon-header"></i>
        <span class="common__text-header"
            [afTranslate]="'upload-report.title'"></span>
        <i class="lf-icon-close common__icon-close-modal"
           (click)="onDismiss()"></i>
    </div>
    <form class="upload-report__form">
        <div class="upload-report__block-body">
            <div class="upload-report__description"
                [afTranslate]="'upload-report.description'"></div>
            <field-type-onefile
                [field]="uploadReport.fieldInfo"
                (itemSelected)="itemSelected($event)"
                (renameEmit)="renameOn($event)"
                [fileInfo]="uploadReport.fileInfo">
            </field-type-onefile>
            <div class="upload-report__error-panel">
                {{uploadReport.error}}
            </div>
        </div>
        <div class="common__buttons-block upload-report__buttons-block">
            <button type="reset"
                    class="common__button common__button--clean"
                    (click)="onDismiss()"
                    [afTranslate]="'common.discard'"></button>
            <button type="submit"
                    class="common__button common__button--active"
                    [disabled]="uploadReport.error || !uploadReport.file"
                    (click)="handleAccept()"
                    [afTranslate]="'common.add'"></button>
        </div>
    </form>
</div>
