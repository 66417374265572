import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { FieldTypeListParentService } from '../utils/field-type-list-parent.service';

@Component({
  selector: 'sequential-list-multiple',
  templateUrl: './sequential-list-multiple.component.html'
})

export class SequentialListMultipleComponent implements OnChanges, OnInit, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding: boolean = true;
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);

  custom: any = this.customLanding.getCustom();
  constructor( private fieldTypeListParent: FieldTypeListParentService,
    private customLanding: CustomLandingFactory) {}

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(): void {
     this.fieldTypeListParent.openWatchers(this)
  }

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
  }

  addOrDeleteSelectedValue(e:any, listValue:any){
    this.fieldTypeListParent.addOrDeleteSelectedValue(e, listValue, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeListParent.openModalRelatedInfo(fieldRelatedInfo, e, this);
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
