<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--expand': taskHistoric.fullScreen,
    'predefined-templates__template-box--min-height':!historicList.length,
    'predefined-templates__template-box--max-height':historicList.length}">
    <div class="concept-legal-documentation__border-box"></div>
    <i class="predefined-templates__change-view-icon"
        (click)="maximizeScreen()"
        matTooltip="{{(taskHistoric.fullScreen? 'common.fold':'common.deploy') | afTranslate}}"
        matTooltipPosition="below"
        matTooltipClass="below"
        [ngClass]="taskHistoric.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"></i>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-track box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text"
        [afTranslate]="'common.historic'">Histórico</p>
    <p class="predefined-templates__block-empty"
        [ngShow]="!historicList.length"
        [afTranslate]="'task-historic.task-no-change'">La tarea no ha sufrido cambios</p>
    <div class="predefined-templates__historic-table "
        [ngClass]="{'predefined-templates__historic-table--expand': taskHistoric.fullScreen}"
        [ngShow]="historicList.length">
        <div class="task-historic__historic-table-header">
            <div class="task-historic__header-col task-historic__header-col--4">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.type'">Tipo</p>
            </div>
            <div class="task-historic__header-col task-historic__header-col--4">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.user'">Usuario</p>
            </div>
            <div class="task-historic__header-col task-historic__header-col--2">
                <p class="task-historic__header-col task-historic__header-col--title"
                    [afTranslate]="'common.date'">Fecha</p>
            </div>
        </div>
        <div class="task-historic__historic-block">
            <div class="task-historic__documents-table"
                *ngFor="let historic of historicList | afOrderBy: 'date':true">
                <div class="task-historic__header-col task-historic__header-col--4">
                    <span class="task-historic__documents-table-text">
                        {{historic.operationType}}
                    </span>
                </div>
                <div class="task-historic__header-col task-historic__header-col--4">
                    <span class="task-historic__documents-table-text">
                            {{historic.user}}
                    </span>
                </div>
                <div class="task-historic__header-col task-historic__header-col--2">
                    <span class="task-historic__documents-table-text">
                        {{historic.date | convertDate: 'without-time'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<form action="historic.aspx" target="_blank" method="post" auto-data="taskHistoric.compareVersions" submit="taskHistoric.openCompareVersions"></form>
