<div *ngFor="let listValue of treeListMultiple.filterList | byWord:treeListMultiple.findDropdown:treeListMultiple.fieldToFind">
  <div class="common__select-item common__ellipsis"
      [ngClass]="{'common__select-item--tree':treeListMultiple.field.isTree}"
      [ngHide]="listValue.deleteError"
      (click)="launchAction(listValue, $event, 'addOrDeleteSelectedValue')">
      <i *ngIf="listValue.children.length"
          [ngClass]="{'lf-icon-angle-down':listValue.show ,
            'lf-icon-angle-right':!listValue.show}"
          (click)="launchAction(listValue, $event, 'showChildren')"></i>
      <i class="field-item__icon-check"
          [ngHide]="listValue.children.length"
          [ngClass]="listValue.selected?'lf-icon-check-full':'lf-icon-box-inactive'"></i>
      {{listValue.text}}
      <i class="lf-icon-check-round-full common__select-icon"
          [ngClass]="{'common__select-icon--select': !listValue.isUserValue}"></i>
      <i class="lf-icon-edit common__select-icon"
          [ngShow]="listValue.isUserValue"
          (click)="launchAction(listValue, $event, 'editListValue')"></i>
      <i class="lf-icon-trash common__select-icon"
          [ngShow]="listValue.isUserValue"
          (click)="launchAction(listValue, $event, 'deleteListValue')"></i>
  </div>
  <div class="common__select-item"
      [ngShow]="listValue.deleteError"
      [ngClass]="{'common__select-item--error':listValue.deleteError}">
      <i class="lf-icon-lock common__select-info-icon"
          [ngShow]="listValue.deleteError"></i>
          No es posible eliminar este valor porque ya se ha utilizado en otros formularios.
      <button class="common__error-button-list"
          [ngShow]="listValue.deleteError"
          (click)="launchAction(listValue, $event, 'aceptNotDelete')">
          Aceptar
      </button>
  </div>
  <div class="field-type-list__tree-list"
      *ngIf="listValue.children.length && listValue.show">
      <tree-list-multiple [field]="treeListMultiple.field"
        [findDropdown]="treeListMultiple.findDropdown"
        [fieldToFind]="treeListMultiple.fieldToFind"
        [filterList]="listValue.children"
        (treeMultipleEmit)="treeMultipleOn($event)">
      </tree-list-multiple>
  </div>
</div>

