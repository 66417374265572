import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';

@Component({
  selector: 'send-reminder',
  templateUrl: './send-reminder.component.html'
})
export class SendReminderComponent {
  skin:any = this.globalCfg.skin
  sendReminder: any ={
    optionSelect: '0'
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private activeModal: NgbActiveModal) { }

  @Input() task:any = null;

  send(){
    this.apiService.add('tasks/meetingreminder/' + this.task.taskId + '/' + this.sendReminder.optionSelect)
        .then(()=>{
            this.handleAccept();
        });
  }

  handleAccept(){
    let text:string = this.translate.instant('send-reminder.send-ok');
    text += this.sendReminder.optionSelect ==='0'? this.translate.instant('send-reminder.ok-pending') : this.translate.instant('send-reminder.ok-all')
    this.activeModal.close({
      result: 'ok',
      text: text
    })
  }

  handleDismiss(){
    this.activeModal.close({result:'cancel'});
  }
}
