
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { TaskFormParentService } from '../utils/task-form-parent.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';



@Component({
  selector: 'task-form-default',
  templateUrl: './task-form-default.component.html'
})
export class TaskFormDefaultComponent implements OnInit, OnChanges, OnDestroy {
  currentComponent = this.route.snapshot.data['componentName'];
  isLanding: boolean = false;
  skin:string = this.globalCfg.skin;
  userPermissions:any = this.permissions.getPermissions();
  taskForm:any = this.taskFormParent.configureTaskForm(this);
  taskInit: boolean = false;

  constructor(private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private taskFormParent: TaskFormParentService,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService) {

    }

  @Input() concept: any = null;
  @Input() task: any = null;
  @Input() isNew: any = null;
  @Input() shared: any = null;
  @Input() hasOpenEvaluationTask: boolean = false;
  @Input() field: any = null;
  @Input() disabledTaskEvaluation: any = null;

  @Output() cancelTask = new EventEmitter();
  @Output() createAlert = new EventEmitter();
  @Output() launchAction = new EventEmitter();

  ngOnInit(){
    if(!this.context.user.userId){
      this.context.user.userId = this.context.user.contactId;
    }
    this.taskFormParent.initWatchers(this);
    this.taskFormParent.configureTaskInit(this);
    this.taskFormParent.getPatterns(this);
  }

  ngOnChanges(): void {
    let that: any = this.taskForm;
    that.task = this.task;
    that.field = this.field;
    that.shared = this.shared;
    that.concept = this.concept;
    if(this.task && this.task.taskTypeId === 3){
      this.configureShareDocuments()
    }
    if(that.concept.conceptObjectId){
      this.apiService.get('documentobjects/allfilesids', this.concept.conceptObjectId)
      .then((data:any)=>{
        this.taskForm.shareDocumentationList = this.commonUtilsService.editList(data, 'documentObjectId', 'name');
      })
    }
  }

  configureShareDocuments(){
    let that: any = this.taskForm;
    that.hasContacts = Boolean(that.task.participants.filter((contact:any)=>{
      return contact.type ==='C'
    }).length)
    if(that.task.sharedDocumentObjectsInEvaluationTask){
      that.shareDocumentationListSelected = this.extractSharedDocumentsIds()
    }
    if(that.task.documentsShareType){
      that.typeShareDocumentSelected = String(that.task.documentsShareType);
    }
  }

  extractSharedDocumentsIds(){
    let that: any = this.taskForm;
    let list: string = '';
    that.task.sharedDocumentObjectsInEvaluationTask.forEach((element:any) => {
      list += '|'+ element.documentObjectId
    });
    return list.substring(1, list.length)
  }

  cancelCreateTask(){
    this.cancelTask.emit();
  }

  receiveContacts(e:any) {
    this.taskForm.task.participants = e.contacts;
    this.taskForm.hasContacts = Boolean(e.contacts.filter((contact:any)=>{
      return contact.type ==='C'
    }).length);
  }

  itemSelected(e:any, type:any ) {
    this.taskFormParent.itemSelected(e.newValue, type, this);
  }

  addOrEditTask(){
    this.taskFormParent.addOrEditTask(this)
  }

  changeValueOption(e:any){
    this.taskFormParent.changeValueOption(e.option, this)
  }

  createTaskAlert(e:any){
    e.alert.nameField = this.taskForm.taskExpirationDate.label;
    this.createAlert.emit({alert:e.alert});
  }

  setPattern(newValue:boolean){
    this.taskFormParent.setPattern(this, newValue);
  }

  modifyPattern(){
    this.taskFormParent.modifyPattern(this)
  }

  cancelMeeting(){
    this.taskFormParent.cancelMeeting(this)
  }

  copyText(type:any){
    this.taskFormParent.copyText(type, this)
  }

  openUrl(url:string){
    window.open(url, '_blank');
  }

  sendNotify(){
    this.taskFormParent.sendNotify(this)
  }

  showTooltip(id:any){
    return this.commonUtilsService.showTooltip(id);
  }

  getEditorValue(event:any){
    this.taskForm.task.description = event.model;
  }

  selectSharedDocumentation(e:any){
    this.taskForm.shareDocumentationListSelected = e.newValue;
  }

  ngOnDestroy(){
    this.taskFormParent.ngOnDestroy();
  }
}
