import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConceptStepsParentsService {

  onChanges(changes:any, child:any){
    let that: any =  child.conceptSteps;
    that.concept = child.concept;
    if(child.concept?.conceptNamedKey === 'Cliente'){
        let idVersionGroup = sessionStorage.getItem('idVersionGroup');
        that.initialStep = idVersionGroup?this.getGroup(parseInt(idVersionGroup), child):1;
    }else{
        that.initialStep = 1;
        sessionStorage.removeItem('idVersionGroup');
    }
    this.configureStepsArray(child);
    switch(child.changeStepsConcept?.action){
        case 'configureSteps':
          this.configureSteps(child);
            break;
        case 'changeEvaluatedConcept':
            this.configureActualStep(child);
            break;
        case 'changeStep':
            this.changeStep(child.changeStepsConcept.step, child);
            break;
    }
  }

  configureStepsArray(child:any){
    child.conceptSteps.filterContainers = [];
    child.filterContainers.forEach((fieldContainer: any, index: any) => {
      if(fieldContainer.show && !fieldContainer.hidden && fieldContainer.showWithProperty){
        fieldContainer.index = child.conceptSteps.filterContainers.length+1;
        child.conceptSteps.filterContainers.push(fieldContainer);
      }
    });
  }

  configureSteps(child:any){
    let that: any = child.conceptSteps;
    if(child.filterContainers.length > 1){
        let step: number = child.conceptSteps.initialStep;
        if(that.concept.actualStep && that.concept.actualStep > 1){
            step = that.concept.actualStep;
        }
        this.changeStep(step, child);
    }else{
        that.concept.actualStep = 1;
    }
  }

  configureActualStep(child: any){
    for(let i = 0; i < child.filterContainers.length; i++){
        let group = child.filterContainers[i];
        if(group.active){
            if(group.index !== child.conceptSteps.concept.actualStep){
                this.changeStep(group.index, child);
            }else{
                child.conceptSteps.concept.actualStepIsLast = this.getLastStep(group.index, child);
            }
            break;
        }
    }
  }

  getGroup(idVersionGroup: any, child: any){
    for(let i = 0; i < child.filterContainers.length; i++){
        let group = child.filterContainers[i];
        if(group.fieldContainerId === idVersionGroup){
            sessionStorage.removeItem('idVersionGroup');
            return group.index?group.index:i+1;
        }
    }
  }

  changeStep(step:any, child:any){
      let filteredSteps: any = [];
      for(let i = 0; i < child.filterContainers.length; i++){
          child.filterContainers[i].active = false;
          if(!child.filterContainers[i].index){
            this.getIndexShow(child.filterContainers[i], i, filteredSteps);
          }
          if(step === child.filterContainers[i].index){
              child.filterContainers[i].active = true;
          }
      }
      child.conceptSteps.concept.actualStep = step;
      child.conceptSteps.concept.actualStepIsLast = this.getLastStep(step, child);
      let fieldsBlock:any = $('.concept-form__fields-block');
      let evaluation:any = $('#compliance-view-body')
      if(child.changeStepsConcept?.action === 'changeStep' && fieldsBlock.height() > evaluation.scrollTop()){
          evaluation.scrollTop(0);
      }
  }

  getIndexShow(item: any, i: any, filtered: any){
      if(item.show && !item.hidden && item.showWithProperty){
          item.index = filtered.length+1;
          filtered.push(item);
      }else{
          delete item.index;
      }
  }

  getLastStep(actualStep: any, child: any){
      let containersShow = 0;
      for (const group of child.filterContainers) {
          if(group.show && !group.hidden && group.showWithProperty){
              containersShow++;
          }
      }
      return containersShow===actualStep?true:false;
  }

  selectStep(fieldContainer:any, child:any){
    this.changeStep(fieldContainer.index, child);
  }
}
